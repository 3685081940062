import React, { useContext, useMemo, useState } from 'react';
import Modal, {
  ModalPane,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalHeaderSubtitle,
  ModalHeaderIcon,
  ModalHeaderContent,
  ModalHeaderTitle,
} from '@gi/modal';
import Plan, { PlantNotesUtils } from '@gi/plan';
import PlannerIcon from '@gi/planner-icon';
import ResourceContext from '@gi/resource-provider/source/components/resource-context';
import LoadingButton from '@gi/loading-button';
import { FormSection, InputContainer } from '@gi/form-responsive';

import ErrorPlantNotFoundModal from './error-plant-not-found-modal';

import './edit-plant-notes-modal.scss';

interface iProps {
  closeModal: () => void;
  onSave: (plan: Plan) => void;
  plan: Plan;
  plantCode: string;
  varietyName: string;
  loading: boolean;
}

const EditPlantNotesModal = ({ closeModal, onSave, plan, plantCode, varietyName, loading }: iProps): JSX.Element => {
  const { getPlant } = useContext(ResourceContext);
  const originalText = PlantNotesUtils.getPlantNoteText(plan.plantNotes, plantCode, varietyName);

  const [noteText, setNoteText] = useState<string>(originalText);

  const plant = useMemo(() => {
    return getPlant(plantCode);
  }, [plantCode]);

  if (!plant) {
    return <ErrorPlantNotFoundModal plantCode={plantCode} closeModal={closeModal} />;
  }

  const onDone = () => {
    // Update note in plan
    // TODO: noteText validation?
    // TODO: remove note if text is empty?
    if (originalText === noteText) {
      closeModal();
      return;
    }

    const updatedPlan: Plan = {
      ...plan,
      plantNotes: PlantNotesUtils.setPlantNote(plan.plantNotes, plantCode, varietyName, noteText),
    };

    onSave(updatedPlan);
  };

  const onClose = () => {
    if (originalText === noteText) {
      closeModal();
    }
  };

  return (
    <Modal closeRequest={onClose} className='edit-plant-notes-modal'>
      <ModalContent>
        <ModalCloseButton onClick={closeModal} />
        <ModalHeader>
          <ModalHeaderIcon>
            <PlannerIcon code={plant.code} />
          </ModalHeaderIcon>
          <ModalHeaderContent>
            <ModalHeaderTitle>Edit Plant Note</ModalHeaderTitle>
            <ModalHeaderSubtitle>
              {plant.name}/{varietyName}
            </ModalHeaderSubtitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane className='edit-plant-notes-edit-pane'>
            <FormSection heading='Plant Notes' padding={0} gap={6} margin={0} className='edit-plant-notes'>
              <InputContainer size='full' autoFocus>
                <textarea
                  rows={6}
                  className='edit-plant-notes-textarea'
                  value={noteText}
                  onChange={(e) => {
                    setNoteText(e.target.value);
                  }}
                />
              </InputContainer>
              <p className='edit-plant-notes-text'>Plant notes are shared by all plants of this type and variety on this plan.</p>
            </FormSection>
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={closeModal}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <LoadingButton loading={loading} className='button button-primary' onClick={onDone}>
                Done
              </LoadingButton>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditPlantNotesModal;
