import React, { ReactNode, useContext } from 'react';

import GuruSection from '../../guru-section/guru-section';
import GuruSectionTitle from '../../guru-section/guru-section-title';
import VirtualizedSmallPreviewRowContent from './virtualized-small-preview-row-content';
import { DirectoryPageSectionContext } from '../../directory-page/directory-page-section-provider';

interface iProps {
  title: ReactNode;
  minItemHeight?: number; // Needs default? (default is 150 within SmallPreviewRowMobile. undefined here uses that default)
  itemAspectRatio?: number;
  itemTextHeight?: number;
  gap?: number;
  padding?: number;
  className?: string;
  hideWhenEmpty?: boolean;
}

const VirtualizedSmallPreviewRow = ({
  title,
  minItemHeight,
  itemAspectRatio,
  itemTextHeight,
  gap,
  padding,
  className,
  hideWhenEmpty = false,
}: iProps): JSX.Element | null => {
  const { allItems, loading } = useContext(DirectoryPageSectionContext);

  if (hideWhenEmpty && !loading && allItems.length === 0) {
    return null;
  }

  return (
    <GuruSection className={className}>
      <GuruSectionTitle>{title}</GuruSectionTitle>
      <VirtualizedSmallPreviewRowContent
        gap={gap}
        itemAspectRatio={itemAspectRatio}
        itemTextHeight={itemTextHeight}
        minItemHeight={minItemHeight}
        padding={padding}
      />
    </GuruSection>
  );
};

export default VirtualizedSmallPreviewRow;
