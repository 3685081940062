import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingButton from '@gi/loading-button';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { RequestKeyCreators, RequestSelectors, SupportRequestActionCreators } from '@gi/react-requests';
import { AppSupportActionCreators } from '@gi/app-help-slice';
import { SessionSelectors } from '@gi/react-session';
import FormField, { FormLayout, FormSection, InputContainer, FORM_LAYOUT_PRESETS } from '@gi/form-responsive';
import { AppContext } from '@gi/app-provider';
import { usePrevious } from '@gi/react-utils';

interface iProps {
  formID: string;
}

const SupportForm = ({ formID }: iProps): JSX.Element => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const user = useSelector(SessionSelectors.getUser)!;
  const requests = useSelector(RequestSelectors.getRequests);

  const status = useMemo(() => RequestsUtils.getStatus(requests, RequestKeyCreators.createSupportEmailRequestKey(formID)), [requests]);
  const prevStatus = usePrevious(status);

  const { runtimeConfig } = useContext(AppContext);

  const dispatch = useDispatch();

  useEffect(() => {
    // Request is now complete, we can reset the message state
    if (status === RequestStatus.COMPLETE && prevStatus !== RequestStatus.COMPLETE) {
      setName('');
      setMessage('');
    }
  }, [status, prevStatus]);

  const submit = useCallback(() => {
    dispatch(
      AppSupportActionCreators.submitSupportEmail({
        user,
        name,
        message,
        formID,
        clientID: runtimeConfig.clientID,
      })
    );
  }, [user, name, message, formID, runtimeConfig]);

  const reset = useCallback(() => {
    dispatch(SupportRequestActionCreators.clearSupportEmailRequest(formID));
  }, [formID]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const errorMessage = useMemo(() => {
    const error = RequestsUtils.getError(requests, RequestKeyCreators.createSupportEmailRequestKey(formID));
    if (!error || !('clientMessage' in error) || typeof error.clientMessage !== 'string') {
      return <p>Error Details: an unknown error occurred</p>;
    }

    return <p>Error Details: {error.clientMessage}</p>;
  }, [requests, formID]);

  if (status === RequestStatus.FAIL) {
    return (
      <div className='support-form support-form-complete'>
        <p>There was an error when attempting to submit your support ticket.</p>
        {errorMessage}
        <p>
          You can retry, or send us a{' '}
          <a href={`{mailto:${runtimeConfig.supportEmail}`} target='_blank' rel='noreferrer'>
            support email
          </a>{' '}
          and we&apos;ll get back to you as soon as possible.
        </p>
        <button type='button' className='button' onClick={reset}>
          Retry
        </button>
      </div>
    );
  }

  if (status === RequestStatus.COMPLETE) {
    return (
      <div className='support-form support-form-complete'>
        <p>Your support ticket has been submitted, we&apos;ll be in touch as soon as possible.</p>
        <button type='button' className='button' onClick={reset}>
          Submit Another Ticket
        </button>
      </div>
    );
  }

  return (
    <form className='support-form'>
      <FormSection padding={0}>
        <FormField label='Your Name' htmlFor='support-form:name' desktopLayout={{ inputSize: 300, labelSize: 140 }}>
          <InputContainer>
            <input type='text' disabled={status === RequestStatus.IN_PROGRESS} value={name} onChange={(e) => setName(e.target.value)} id='support-form:name' />
          </InputContainer>
        </FormField>
        <FormField label='Message' htmlFor='support-form:message' desktopLayout={{ inputSize: 'full', labelSize: 140 }}>
          <InputContainer>
            <textarea
              rows={10}
              disabled={status === RequestStatus.IN_PROGRESS}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              id='support-form:message'
            />
          </InputContainer>
        </FormField>
        <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.ButtonRight}>
          <LoadingButton
            disabled={message.trim() === '' || status === RequestStatus.IN_PROGRESS}
            loading={status === RequestStatus.IN_PROGRESS}
            onClick={submit}
            className='button button-primary-light'
          >
            Submit
          </LoadingButton>
        </FormLayout>
      </FormSection>
    </form>
  );
};

export default SupportForm;
