import React from 'react';

import LockToolbarButton from './buttons/lock-toolbar-button';
import OverlayToolbar, { ToolbarAnchor, ToolbarOrientation } from '../overlay-toolbar';

const BottomRightOverlayToolbar = (): JSX.Element => {
  return (
    <OverlayToolbar anchor={ToolbarAnchor.BOTTOM_RIGHT} orientation={ToolbarOrientation.VERTICAL}>
      <LockToolbarButton />
    </OverlayToolbar>
  );
};

export default BottomRightOverlayToolbar;
