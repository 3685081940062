import React from 'react';
import IconContainer from './icon-container';

import styles from './menu-button.module.css';

interface iProps {
  loading?: boolean;
  icon: string;
  overlayIcon?: string | null;
}

const MenuIcon = ({ loading, icon, overlayIcon }: iProps): JSX.Element => {
  const icons: JSX.Element[] = [];

  if (loading) {
    icons.push(<span key='loading-icon' className='icon-spinner animate-pulse' />);
  } else {
    icons.push(<span key='icon' className={icon} />);
  }
  // icons.push(<span key='loading-icon' className='icon-spinner animate-pulse' />);

  if (overlayIcon) {
    icons.push(<span key='overlay-icon' className={`${styles.overlayIcon} ${overlayIcon}`} />);
  }

  return <IconContainer>{icons}</IconContainer>;
};

export default MenuIcon;
