import React from 'react';

interface iProps {
  className?: string;
}

const GuruSpinner = ({ className = '' }: iProps): JSX.Element => {
  return (
    <div className={className}>
      <i className='icon-spinner animate-pulse' />
    </div>
  );
};

export default GuruSpinner;
