import { ClassOf } from '../../types';
import type Node from '../../node';
import type NodeComponent from '../node-component';

/**
 * Helper class to store a 1-1 mapping from NodeComponent.constructor -> NodeComponent.
 */
class NodeComponentMap<T extends NodeComponent> implements Iterable<T> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  #items: Map<Function, T> = new Map();
  #parent: Node | null = null;
  #bound: boolean = false;

  has<U extends T>(item: ClassOf<U>): boolean {
    return this.#items.get(item.constructor) === undefined;
  }

  get<U extends T>(item: ClassOf<U>): U | null {
    const foundItem = this.#items.get(item);
    return foundItem === undefined ? null : (foundItem as U);
  }

  add<U extends T>(item: U): U {
    if (this.#items.get(item.constructor)) {
      throw new Error(`Mapping already has an instance of ${item.type}`);
    }
    this.#items.set(item.constructor, item);
    if (this.parent) {
      item.setOwner(this.parent);
    }
    return item;
  }

  remove<U extends T>(item: ClassOf<U>): void {
    const toRemove = this.#items.get(item.constructor);
    if (!toRemove || !this.#items.delete(item.constructor)) {
      throw new Error('Mapping has no instance of given item to remove');
    }
    toRemove.removeComponent();
  }

  /** Returns the node that owns this collection */
  get parent(): Node | null {
    return this.#parent;
  }

  set parent(parent: Node | null) {
    this.#parent = parent;
    this.#items.forEach((item) => {
      item.removeComponent();
      if (parent) {
        item.setOwner(parent);
      }
    });
  }

  /** Returns true if this map is bound to an engine via its parent */
  get bound(): boolean {
    return this.#bound;
  }

  set bound(bound: boolean) {
    this.#bound = bound;
    this.#items.forEach((item) => {
      item.setBound(bound);
    });
  }

  *[Symbol.iterator](): Iterator<T> {
    const keys = [...this.#items.keys()];
    for (let i = 0; i < keys.length; i++) {
      const item = this.#items.get(keys[i]);
      if (item !== undefined) {
        yield item;
      }
    }
  }
}

export default NodeComponentMap;
