import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal, ModalType } from '@gi/modal';

const DeleteVarietyModal = (props) => {
  return (
    <ConfirmModal type={ModalType.Warning} onConfirm={props.onConfirm} onCancel={props.onCancel} headerText={`Delete Variety ${props.varietyName}?`}>
      <p>Deleting the variety will stop it appearing in any lists, it will not automatically remove plant notes, or remove this variety from any plants.</p>
    </ConfirmModal>
  );
};

DeleteVarietyModal.propTypes = {
  varietyName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default DeleteVarietyModal;
