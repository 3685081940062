import { ShapeType } from '@gi/constants';

import { SimulatedShape } from '../simulation/simulated-shape';
import CanvasItem from './canvas-item';
import CanvasLayers from './canvas-layers';
import RectangleShapeNode from './nodes/shapes/rectangle-shape-node';
import { SimulatedGardenItemEvent } from '../simulation/simulated-garden-item';
import EllipseShapeNode from './nodes/shapes/ellipse-shape-node';
import TriangleShapeNode from './nodes/shapes/triangle-shape-node';
import LineShapeNode from './nodes/shapes/line-shape-node';
import ShapeNode from './nodes/shapes/shape-node';

class CanvasShape extends CanvasItem {
  readonly #shapeNode: ShapeNode;
  readonly #simulatedShape: SimulatedShape;
  readonly #canvasLayers: CanvasLayers;

  constructor(simulatedShape: SimulatedShape, canvasLayers: CanvasLayers) {
    super();

    switch (simulatedShape.shapeType) {
      case ShapeType.RECTANGLE: {
        this.#shapeNode = new RectangleShapeNode(simulatedShape.id, {
          point1: simulatedShape.point1,
          point2: null,
          point3: simulatedShape.point3,
          rotation: simulatedShape.rotation,
          fill: simulatedShape.fill,
          texture: simulatedShape.texture,
          stroke: simulatedShape.stroke,
          strokeWidth: simulatedShape.strokeWidth,
          locked: simulatedShape.locked,
          zIndex: simulatedShape.zIndex,
        }) as ShapeNode;
        break;
      }
      case ShapeType.ELLIPSE: {
        this.#shapeNode = new EllipseShapeNode(simulatedShape.id, {
          point1: simulatedShape.point1,
          point2: null,
          point3: simulatedShape.point3,
          rotation: simulatedShape.rotation,
          fill: simulatedShape.fill,
          texture: simulatedShape.texture,
          stroke: simulatedShape.stroke,
          strokeWidth: simulatedShape.strokeWidth,
          locked: simulatedShape.locked,
          zIndex: simulatedShape.zIndex,
        }) as ShapeNode;
        break;
      }
      case ShapeType.TRIANGLE: {
        this.#shapeNode = new TriangleShapeNode(simulatedShape.id, {
          point1: simulatedShape.point1,
          point2: simulatedShape.point2!,
          point3: simulatedShape.point3,
          rotation: simulatedShape.rotation,
          fill: simulatedShape.fill,
          texture: simulatedShape.texture,
          stroke: simulatedShape.stroke,
          strokeWidth: simulatedShape.strokeWidth,
          locked: simulatedShape.locked,
          zIndex: simulatedShape.zIndex,
        }) as ShapeNode;
        break;
      }
      case ShapeType.LINE: {
        this.#shapeNode = new LineShapeNode(simulatedShape.id, {
          point1: simulatedShape.point1,
          point2: simulatedShape.point2!,
          point3: simulatedShape.point3,
          rotation: simulatedShape.rotation,
          fill: simulatedShape.fill,
          texture: simulatedShape.texture,
          stroke: simulatedShape.stroke,
          strokeWidth: simulatedShape.strokeWidth,
          locked: simulatedShape.locked,
          zIndex: simulatedShape.zIndex,
        }) as ShapeNode;
        break;
      }
      default: {
        return;
      }
    }

    this.nodes.push(this.#shapeNode);

    this.#canvasLayers = canvasLayers;
    this.#simulatedShape = simulatedShape;

    this.#simulatedShape.on(SimulatedGardenItemEvent.DidUpdate, this.#onSimulatedShapeUpdated);

    this.#canvasLayers.layoutLayer.addChildren(this.#shapeNode);

    this.registerEffectHandler(this.#simulatedShape, this.#shapeNode);
  }

  #onSimulatedShapeUpdated = () => {
    this.#shapeNode.setPoints(this.#simulatedShape.point1, this.#simulatedShape.point2, this.#simulatedShape.point3, this.#simulatedShape.rotation);
    this.#shapeNode.setStyle(this.#simulatedShape.fill, this.#simulatedShape.texture, this.#simulatedShape.stroke, this.#simulatedShape.strokeWidth);
    this.#shapeNode.setLocked(this.#simulatedShape.locked);
    this.#shapeNode.setZIndex(this.#simulatedShape.zIndex);
  };
}

export default CanvasShape;
