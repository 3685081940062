import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DisplayModeActions, DisplayModeSelectors } from '@gi/display-mode-provider';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { DeviceDisplayMode } from '@gi/constants';

import Banner from '../banner';
import BannerButtons from '../banner-buttons';
import BannerContent from '../banner-content';
import BannerTitle from '../banner-title';

const DeviceDisplayModeToDisplayName = {
  [DeviceDisplayMode.DESKTOP]: 'Desktop Mode',
  [DeviceDisplayMode.MOBILE]: 'Mobile Mode',
};

const DisplayModeSuggestionBanner = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);
  const bestDeviceDisplayMode = useSelector(DisplayModeSelectors.getBestDisplayMode);
  const acknowledgedBestDeviceDisplayMode = useSelector(DisplayModeSelectors.getHasAcknowledgedBestDisplayMode);
  const userSelectedDisplayMode = useSelector(LocalSettingsSelectors.getDeviceDisplayMode);

  const useBestDeviceDisplayMode = useCallback((useBest: boolean) => {
    dispatch(useBest ? DisplayModeActions.useBestDisplayMode() : DisplayModeActions.acknowledgeBestDisplayMode());
  }, []);

  /**
   * Render the alert if:
   *  - We're not currently using the best display mode
   *  - The user hasn't acknowledged this in any way AND
   *  - The user is using SUGGEST mode
   */
  const shouldRender = useMemo(() => {
    if (userSelectedDisplayMode !== DeviceDisplayMode.SUGGEST) {
      return false;
    }
    return deviceDisplayMode !== bestDeviceDisplayMode && !acknowledgedBestDeviceDisplayMode;
  }, [userSelectedDisplayMode, deviceDisplayMode, bestDeviceDisplayMode, acknowledgedBestDeviceDisplayMode]);

  const bannerTitle = useMemo(() => {
    switch (bestDeviceDisplayMode) {
      case DeviceDisplayMode.DESKTOP:
        return 'Switch to Dekstop Mode?';
      case DeviceDisplayMode.MOBILE:
        return 'Switch to Mobile Mode?';
      default:
        return 'Switch display modes?';
    }
  }, [bestDeviceDisplayMode]);

  const bannerContent = useMemo(() => {
    switch (bestDeviceDisplayMode) {
      case DeviceDisplayMode.DESKTOP:
        return 'Desktop mode is best for large screens, with a mouse attached.';
      case DeviceDisplayMode.MOBILE:
        return 'Mobile mode is best for touch-screens and small devices.';
      default:
        return 'Switching display modes may give a better experience on your device.';
    }
  }, [bestDeviceDisplayMode]);

  if (!shouldRender) {
    return null;
  }

  return (
    <Banner
      type='default'
      dismissable
      onDismiss={() => {
        useBestDeviceDisplayMode(false);
        return true;
      }}
    >
      <BannerContent>
        <BannerTitle>{bannerTitle}</BannerTitle>
        <p>{bannerContent}</p>
        <p>
          You can always change this setting under <i className='icon icon-cog' /> Device Settings.
        </p>
      </BannerContent>
      <BannerButtons>
        <button className='button button-primary' type='button' onClick={() => useBestDeviceDisplayMode(true)}>
          Switch to {DeviceDisplayModeToDisplayName[bestDeviceDisplayMode]}
        </button>
        <button className='button button-inline' type='button' onClick={() => useBestDeviceDisplayMode(false)}>
          Keep using {DeviceDisplayModeToDisplayName[deviceDisplayMode]}
        </button>
      </BannerButtons>
    </Banner>
  );
};

export default DisplayModeSuggestionBanner;
