import React, { useContext, useEffect } from 'react';

import {
  ModalPane,
  ModalPaneContent,
  ModalHeader,
  ModalHeaderIcon,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalHeaderSubtitle,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalPaneSection,
  ModalPaneSectionContent,
  AttemptToLeaveCallback,
  ConfirmLeaveContextResult,
  ModalContext,
} from '@gi/modal';
import PlannerIcon from '@gi/planner-icon';

import EditInGroundDates from './edit-section/edit-in-ground-dates';
import { EditPlantContext } from './context/edit-plant-context';
import PlanPlantCalendar from './information-section/plan-plant-calendar';
import PlanPlantNotes from './information-section/plan-plant-notes';
import PlanPlantSpacing from './information-section/plan-plant-spacing';
import MoreInformation from './information-section/more-information';
import EditPlantLabel from './edit-section/edit-plant-label';
import PositionInputs from './edit-section/position-inputs';
import PlanPlantCompanions from './information-section/plan-plant-companions';

const EditPlantModalContentInner = (): JSX.Element => {
  const { setCloseContextParams, attemptClose, close } = useContext(ModalContext);
  const { plant, onDone, editPlantValues, plantNotesTextChanged } = useContext(EditPlantContext);

  /**
   * Prevent navigating away with unsaved changes
   */
  useEffect(() => {
    const createLeaveCallback = (): AttemptToLeaveCallback => {
      return (cb) => {
        return cb(editPlantValues.hasDifferences || plantNotesTextChanged).then((result) => {
          if (result === ConfirmLeaveContextResult.SaveAndClose) {
            onDone();
          }

          return result !== ConfirmLeaveContextResult.Cancel;
        });
      };
    };

    const invalidMessage = editPlantValues.isValid ? undefined : 'Some inputs have invalid values, please correct them before saving';

    setCloseContextParams({
      title: 'Save Changes?',
      text: 'Do you wish to save your changes to this Plant?',
      callback: createLeaveCallback(),
      invalidMessage,
    });

    return () => {
      setCloseContextParams(null);
    };
  }, [editPlantValues.hasDifferences, editPlantValues.isValid, plantNotesTextChanged, onDone, setCloseContextParams]);

  return (
    <ModalContent>
      <ModalCloseButton onClick={attemptClose} />
      <ModalHeader>
        <ModalHeaderIcon>
          <PlannerIcon code={plant.code} />
        </ModalHeaderIcon>
        <ModalHeaderContent>
          <ModalHeaderTitle>Edit Plant</ModalHeaderTitle>
          <ModalHeaderSubtitle>{plant.name}</ModalHeaderSubtitle>
        </ModalHeaderContent>
      </ModalHeader>
      <ModalPaneContainer>
        <ModalPane className='edit-plant-edit-pane'>
          <ModalPaneContent>
            <ModalPaneSection>
              <ModalPaneSectionContent>
                <EditPlantLabel />
                <EditInGroundDates />
                <PositionInputs />
              </ModalPaneSectionContent>
            </ModalPaneSection>
          </ModalPaneContent>
        </ModalPane>
        <ModalPane className='edit-plant-info-pane'>
          <ModalPaneContent>
            <MoreInformation />
            <ModalPaneSection>
              <ModalPaneSectionContent>
                <PlanPlantCalendar />
                <PlanPlantCompanions />
                <PlanPlantSpacing />
                <PlanPlantNotes />
              </ModalPaneSectionContent>
            </ModalPaneSection>
          </ModalPaneContent>
        </ModalPane>
      </ModalPaneContainer>
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-secondary' onClick={close}>
              Cancel
            </button>
          </ModalFooterButtonsSection>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-primary' onClick={onDone}>
              Done
            </button>
          </ModalFooterButtonsSection>
        </ModalFooterButtons>
      </ModalFooter>
    </ModalContent>
  );
};

export default EditPlantModalContentInner;
