import { Store } from 'redux';

import LocalSession from '@gi/local-session';
import { User } from '@gi/user';

import * as SessionSelectors from './session-selectors';
import { restoreSessionData } from './session-action-creators';
import { SessionRootState } from './session-selectors';

const authDetailsChanged = (user1: User | null, user2: User | null): boolean => {
  if (user1 === null && user2 === null) {
    return false;
  }

  if (user1 === null || user2 === null) {
    // They're not both null, so if one is and the other isn't there is a change
    return true;
  }

  return user1.email !== user2.email || user1.ID !== user2.ID || user1.postAuthTicket !== user2.postAuthTicket;
};

class LocalSessionController {
  localSession: LocalSession;
  currentState: SessionRootState | null;
  store: Store<SessionRootState>;

  constructor() {
    this.localSession = new LocalSession();
    this.currentState = null;
  }

  init(store: Store<SessionRootState>) {
    this.store = store;

    console.debug('Local session controller initialised');
    this.localSession.onChange(this.restore);

    this.store.subscribe(this.handleStoreChange);
    this.restore(this.localSession.get());
  }

  restore = (sessionData: Record<string, any> | null) => {
    const currentUser = SessionSelectors.getUser(this.store.getState());

    if (sessionData === null && currentUser === null) {
      // Do Nothing, user is not set
      this.store.dispatch(restoreSessionData(null));
    } else if (sessionData === null && currentUser !== null) {
      // Remove user inside session context
      this.store.dispatch(restoreSessionData(null));
    } else if (sessionData !== null && currentUser === null) {
      // Restore session, load user
      this.store.dispatch(restoreSessionData(sessionData));
    } else if (sessionData !== null && currentUser !== null) {
      // Check session users are the same, if not, restore session with new user

      if (sessionData.userID === currentUser.ID) {
        // Do nothing, session change is the same
        console.log('Session data changed but user is the same');
        return;
      }

      // Restore session, load user with the new user data
      // In practice this should never happen as a user should not be able to log in without logging out first
      // but it's safer just to handle this possibility
      this.store.dispatch(restoreSessionData(sessionData));
    } else {
      throw new Error('Reached invalid state');
    }
  };

  handleStoreChange = () => {
    const previousState = this.currentState;
    this.currentState = this.store.getState();

    const previousUser = previousState === null ? null : SessionSelectors.getUser(previousState);
    const currentUser = SessionSelectors.getUser(this.currentState);

    if (authDetailsChanged(previousUser, currentUser)) {
      if (currentUser === null) {
        // logout
        this.localSession.logout();
      } else {
        this.localSession.login(currentUser.email, currentUser.ID, currentUser.postAuthTicket);
      }
    }
  };
}

export default LocalSessionController;
