/**
 * Enum for the different modes of plant display
 *
 * @readonly
 * @enum {number}
 */
export const RepeatingGraphicDisplayModes = {
  CORNER: 0,
  EDGE: 1,
  BLOCK: 2,
} as const;

export type RepeatingGraphicDisplayModesType = (typeof RepeatingGraphicDisplayModes)[keyof typeof RepeatingGraphicDisplayModes];
