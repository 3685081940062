import React from 'react';
import { WebGardenPlannerUpdate } from '@gi/update-data';

interface iProps {
  updateData: WebGardenPlannerUpdate;
}

const UpdateSection = ({ updateData }: iProps): JSX.Element => {
  return (
    <section className='update-section'>
      {updateData.title ? <h4>{updateData.title}</h4> : null}
      <p>
        <span className='version'>{updateData.version}</span> <span className='date'>{updateData.date}</span>
      </p>
      <ul>
        {updateData.notes.map((note, i) => (
          <li key={i}>{note}</li>
        ))}
      </ul>
    </section>
  );
};

export default UpdateSection;
