import { RequestError } from '@gi/gi-network';
import getArticleErrorCodes from './get-article-error-codes';

export const attachGetArticleErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && getArticleErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = getArticleErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when loading articles - ${responseError.statusCode}`;
  }
};
