import React, { useContext, useRef } from 'react';
import { AutoSizer, List } from 'react-virtualized';

import GardenObjectListItem from './garden-object-list-item';
import GardenObjectGroupListItem from './garden-object-group-list-item';
import { DrawingToolsContext } from '../drawing-tools-context';
import GardenObjectFilterInputs from './filters/garden-object-filter-inputs';
import DragToDrawControlledList from '../drag-to-draw/drag-to-draw-controlled-list';

import './garden-object-panel.scss';

const GardenObjectList = () => {
  const { filteredGardenObjects, resetGardenObjectFilters, groupedFilteredGardenObjects: betterGroupedFilteredGardenObjects } = useContext(DrawingToolsContext);

  const ref = useRef<List>(null);

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) => {
    const item = betterGroupedFilteredGardenObjects[index];

    switch (item.type) {
      case 'garden-object':
        return <GardenObjectListItem key={key} style={style} gardenObject={item.gardenObject} />;
      case 'group':
        return <GardenObjectGroupListItem key={key} style={style} gardenObjectGroup={item.group} gardenObjects={item.gardenObjects} expanded={item.expanded} />;
      case 'grouped-garden-object':
        return <GardenObjectListItem key={key} style={style} gardenObject={item.gardenObject} isInGroup isFirst={item.isFirst} isLast={item.isLast} />;
      default:
        console.error(`Unknown garden object list item type`);
        return null;
    }
  };

  const renderEmpty = () => {
    return (
      <div className='empty-drawing-tools-list-warning'>
        <p>
          No items found with your current filters, you can{' '}
          <button type='button' className='button button-inline' onClick={resetGardenObjectFilters}>
            reset
          </button>{' '}
          your filters to try again.
        </p>
      </div>
    );
  };

  const renderList = () => {
    return (
      <AutoSizer>
        {({ width, height }) => (
          <ol className='drawing-tools-item-list'>
            <DragToDrawControlledList
              // scrollToIndex={0}
              ref={ref}
              width={width}
              height={height}
              rowCount={betterGroupedFilteredGardenObjects.length}
              rowHeight={60}
              rowRenderer={rowRenderer}
              // This component doesn't use these props but we need to force a render on change
              // selectedGardenObjectCode={this.props.selectedGardenObjectCode}
              // filters={this.props.filters}
              // gardenObjects={this.props.gardenObjects}
              dependantOnGardenObjects={betterGroupedFilteredGardenObjects}
            />
          </ol>
        )}
      </AutoSizer>
    );
  };

  return (
    <div className='garden-object-panel drawing-tools-panel'>
      <header className='drawing-tools-panel-title'>
        <h1>Garden Objects</h1>
      </header>
      <div className='drawing-tools-panel-content scrollable-drawing-tools-panel-content'>
        <GardenObjectFilterInputs />
        <div className='item-list-container'>{filteredGardenObjects.length === 0 ? renderEmpty() : renderList()}</div>
      </div>
    </div>
  );
};

export default GardenObjectList;
