import React from 'react';

interface iProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const ModalTabList = ({ children, style }: iProps): JSX.Element => {
  return (
    <div className='modal-tab-list' style={style}>
      {children}
    </div>
  );
};

export default ModalTabList;
