import React from 'react';
import PropTypes from 'prop-types';

import './form.scss';

/**
 * @param {{
 *  className?: string,
 *  children?: import('react').ReactNode
 * }}
 */
const FormRowGroupContent = ({ className = '', children = null }) => {
  return <div className={`form-row-group-content ${className}`}>{children}</div>;
};

FormRowGroupContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default FormRowGroupContent;
