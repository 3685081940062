import { Geometry, MathUtils } from '@gi/math';
import GardenObject, { GardenObjectScalingMode, GardenObjectUtils } from '@gi/garden-object';

import {
  PlantTypes,
  ShapeType,
  GardenObjectType,
  MAX_PLAN_CM_WHEN_METRIC,
  MIN_PLAN_CM_WHEN_METRIC,
  MIN_PLAN_CM_WHEN_IMPERIAL,
  MAX_PLAN_CM_WHEN_IMPERIAL,
  PlantType,
  FGP_MAX,
} from '@gi/constants';
import { UserPlantVariety } from '@gi/user';
import Plant, { PlantUtils } from '@gi/plant';

import { APIPlanDocument, APIPlanGardenObject, APIPlanPlant, APIPlanShape, APIPlanText } from './plan-api-types';

/**
 * @param {number} rotation - rotation in radians
 */
export function validateRotation(rotation: number): number {
  return MathUtils.degToRad(Math.round(MathUtils.radToDeg(rotation)));
}

export function validateFixedSizeObjectProperties(center: Vector2, rotation: number): { start: Vector2; mid: null | Vector2; end: Vector2; rotation: number } {
  return {
    start: {
      x: Math.round(center.x),
      y: Math.round(center.y),
    },
    mid: null,
    end: {
      x: Math.round(center.x),
      y: Math.round(center.y),
    },
    rotation: validateRotation(rotation),
  };
}

export function validatePathGardenObjectProperties(
  start: Vector2,
  mid: Vector2 | null,
  end: Vector2,
  curved: boolean
): { start: Vector2; mid: null | Vector2; end: Vector2; rotation: number } {
  if (curved && mid !== null) {
    return {
      start: {
        x: Math.round(start.x),
        y: Math.round(start.y),
      },
      mid: {
        x: Math.round(mid.x),
        y: Math.round(mid.y),
      },
      end: {
        x: Math.round(end.x),
        y: Math.round(end.y),
      },
      rotation: 0,
    };
  }

  return {
    start: {
      x: Math.round(start.x),
      y: Math.round(start.y),
    },
    mid: null,
    end: {
      x: Math.round(end.x),
      y: Math.round(end.y),
    },
    rotation: 0,
  };
}

export function validateScalingGardenObjectProperties(
  center: Vector2,
  rotation: number,
  _width: number,
  _height: number,
  gardenObject: GardenObject
): { start: Vector2; mid: null | Vector2; end: Vector2; rotation: number } {
  let width = _width;
  let height = _height;

  // Assert garden object shape is correct type
  // TODO - Give GardenObjects proper types/structure and remove this
  if (gardenObject.shape.type === GardenObjectType.PATH) {
    throw new Error('Expected scaling garden object, but given path');
  }

  if (width < gardenObject.shape.minWidth) {
    width = gardenObject.shape.minWidth;
  } else if (gardenObject.shape.maxWidth !== 0 && width > gardenObject.shape.maxWidth) {
    width = gardenObject.shape.maxWidth;
  }

  if (height < gardenObject.shape.minHeight) {
    height = gardenObject.shape.minHeight;
  } else if (gardenObject.shape.maxHeight !== 0 && height > gardenObject.shape.maxHeight) {
    height = gardenObject.shape.maxHeight;
  }

  return {
    start: {
      x: Math.round(center.x - width / 2),
      y: Math.round(center.y - height / 2),
    },
    mid: null,
    end: {
      x: Math.round(center.x + width / 2),
      y: Math.round(center.y + height / 2),
    },
    rotation: validateRotation(rotation),
  };
}

export function validatePresetSizeObjectProperties(
  center: Vector2,
  rotation: number,
  _width: number,
  _height: number,
  gardenObject: GardenObject
): { start: Vector2; mid: null | Vector2; end: Vector2; rotation: number } {
  if (gardenObject.shape.type !== GardenObjectType.BLOCK) {
    throw new Error('Expected block garden object, but given path');
  }
  if (gardenObject.shape.scalingMode !== GardenObjectScalingMode.PRESETS || !gardenObject.shape.presets) {
    throw new Error('Cannot validate block object using presets: presets are undefined');
  }

  const result = GardenObjectUtils.getClosestPreset(_width, _height, gardenObject.shape.presets, Infinity);

  if (!result) {
    // We've failed validation somehow... fall back to scaling
    return validateScalingGardenObjectProperties(center, rotation, _width, _height, gardenObject);
  }

  const presetWidth = result.preset.width;
  const presetHeight = result.preset.height;

  return {
    start: {
      x: Math.round(center.x - presetWidth / 2),
      y: Math.round(center.y - presetHeight / 2),
    },
    mid: null,
    end: {
      x: Math.round(center.x + presetWidth / 2),
      y: Math.round(center.y + presetHeight / 2),
    },
    rotation: validateRotation(rotation + (result.transposed ? Math.PI / 2 : 0)),
  };
}

export function validateGardenObjectProperties(
  start: Vector2,
  mid: Vector2 | null,
  end: Vector2,
  center: Vector2,
  rotation: number,
  width: number,
  height: number,
  curved: boolean,
  gardenObject: GardenObject
): { start: Vector2; mid: null | Vector2; end: Vector2; rotation: number } {
  if (gardenObject.shape.type === GardenObjectType.PATH) {
    return validatePathGardenObjectProperties(start, mid, end, curved);
  }

  if (gardenObject.shape.scalingMode === GardenObjectScalingMode.FIXED) {
    return validateFixedSizeObjectProperties(center, rotation);
  }

  if (gardenObject.shape.scalingMode === GardenObjectScalingMode.PRESETS) {
    return validatePresetSizeObjectProperties(center, rotation, width, height, gardenObject);
  }

  return validateScalingGardenObjectProperties(center, rotation, width, height, gardenObject);
}

/**
 * @param {Point} start
 * @param {Point} mid
 * @param {Point} end
 * @param {number} rotation - rotation in radians
 * @param {GardenObject} gardenObject
 */
export const validateInputObjectProperties = (start: Vector2, mid: Vector2 | null, end: Vector2, rotation: number, gardenObject: GardenObject) => {
  if (gardenObject.shape.type === GardenObjectType.PATH) {
    return validatePathGardenObjectProperties(start, mid, end, mid !== null);
  }

  const center = Geometry.midpoint(start, end);

  if (gardenObject.shape.scalingMode === GardenObjectScalingMode.FIXED) {
    return validateFixedSizeObjectProperties(center, rotation);
  }

  const width = Math.abs(end.x - start.x);
  const height = Math.abs(end.y - start.y);

  return validateScalingGardenObjectProperties(center, rotation, width, height, gardenObject);
};

export function validatePlant(
  isSquareFoot: boolean,
  rowStart: Vector2,
  rowEnd: Vector2,
  height: number,
  plant: Plant,
  userPlantVariety: UserPlantVariety | null
): { type: PlantType; rowStart: Vector2; rowEnd: Vector2; height: number } {
  const roundedRowStart = {
    x: Math.round(rowStart.x),
    y: Math.round(rowStart.y),
  };

  const roundedRowEnd = {
    x: Math.round(rowEnd.x),
    y: Math.round(rowEnd.y),
  };

  const roundedHeight = Math.round(height);

  const width = Geometry.dist(roundedRowStart, roundedRowEnd);

  const spacings = PlantUtils.getSpacings(plant, userPlantVariety);

  if (isSquareFoot) {
    return {
      type: PlantTypes.PLANT_SQUARE_FOOT,
      rowStart: roundedRowStart,
      rowEnd: roundedRowStart,
      height: 0,
    };
  }

  if (roundedRowStart.x === roundedRowEnd.x && roundedRowStart.y === roundedRowEnd.y) {
    // Start and end are equal, which means we have no way of getting a direction for height, so height is meaningless
    return {
      type: PlantTypes.PLANT_SINGLE,
      rowStart: roundedRowStart,
      rowEnd: roundedRowStart,
      height: 0,
    };
  }

  if (roundedHeight < spacings.spacing) {
    // Row or single plant
    if (width < spacings.inRowSpacing) {
      return {
        type: PlantTypes.PLANT_SINGLE,
        rowStart: roundedRowStart,
        rowEnd: roundedRowStart,
        height: 0,
      };
    }

    return {
      type: PlantTypes.PLANT_ROW,
      rowStart: roundedRowStart,
      rowEnd: roundedRowEnd,
      height: 0,
    };
  }

  if (width < spacings.spacing) {
    // Width is not enough for plant block, attempt to convert to row
    const ang = Math.atan2(rowEnd.y - rowStart.y, rowEnd.x - rowStart.x) + Math.PI / 2;
    const adjustedRowEnd = {
      x: rowStart.x + Math.round(height * Math.cos(ang)),
      y: rowStart.y + Math.round(height * Math.sin(ang)),
    };

    const adjustedWidth = Geometry.dist(rowStart, adjustedRowEnd);

    if (adjustedWidth < spacings.inRowSpacing) {
      // Not a row
      if (width < spacings.inRowSpacing) {
        return {
          type: PlantTypes.PLANT_SINGLE,
          rowStart: roundedRowStart,
          rowEnd: roundedRowStart,
          height: 0,
        };
      }
    }

    return {
      type: PlantTypes.PLANT_ROW,
      rowStart: roundedRowStart,
      rowEnd: adjustedRowEnd,
      height: 0,
    };
  }

  return {
    type: PlantTypes.PLANT_BLOCK,
    rowStart: roundedRowStart,
    rowEnd: roundedRowEnd,
    height: roundedHeight,
  };
}

export function validateShape(
  type: ShapeType,
  point1: Vector2,
  point2: Vector2 | null,
  point3: Vector2,
  center: Vector2,
  rotation: number,
  width: number,
  height: number,
  curved: boolean,
  filled: boolean,
  color: number,
  texture: string | null,
  strokeWidth: number
) {
  if (type === ShapeType.RECTANGLE || type === ShapeType.ELLIPSE) {
    return {
      point1: Geometry.roundPoint({
        x: center.x - width / 2,
        y: center.y - height / 2,
      }),
      point2: null,
      point3: Geometry.roundPoint({
        x: center.x + width / 2,
        y: center.y + height / 2,
      }),
      texture,
      rotation: validateRotation(rotation),
      fill: filled ? color : null,
      stroke: color,
      strokeWidth,
    };
  }

  if (type === ShapeType.TRIANGLE) {
    if (point2 === null) {
      throw new Error('Triangle given but point 2 is null');
    }

    return {
      point1: Geometry.roundPoint(point1),
      point2: Geometry.roundPoint(point2),
      point3: Geometry.roundPoint(point3),
      rotation: 0,
      texture,
      fill: filled ? color : null,
      stroke: color,
      strokeWidth,
    };
  }

  if (type === ShapeType.LINE) {
    if (curved) {
      if (point2 === null) {
        throw new Error('Curved line given but point 2 is null');
      }

      return {
        point1: Geometry.roundPoint(point1),
        point2: Geometry.roundPoint(point2),
        point3: Geometry.roundPoint(point3),
        rotation: 0,
        texture: null,
        fill: filled ? color : null,
        stroke: color,
        strokeWidth,
      };
    }

    return {
      point1: Geometry.roundPoint(point1),
      point2: null,
      point3: Geometry.roundPoint(point3),
      rotation: 0,
      texture,
      fill: filled ? color : null,
      stroke: color,
      strokeWidth,
    };
  }

  throw new Error('Invalid shape type');
}

export function validateText(
  center: Vector2,
  width: number,
  height: number,
  rotation: number,
  color: number,
  fontSize: number
): { start: Vector2; end: Vector2; rotation: number; color: number; fontSize: number } {
  return {
    start: Geometry.roundPoint({
      x: center.x - width / 2,
      y: center.y - height / 2,
    }),
    end: Geometry.roundPoint({
      x: center.x + width / 2,
      y: center.y + height / 2,
    }),
    rotation: validateRotation(rotation),
    color,
    fontSize,
  };
}

export function validateRect(center: Vector2, width: number, height: number, rotation: number): { start: Vector2; end: Vector2; rotation: number } {
  return {
    start: Geometry.roundPoint({
      x: center.x - width / 2,
      y: center.y - height / 2,
    }),
    end: Geometry.roundPoint({
      x: center.x + width / 2,
      y: center.y + height / 2,
    }),
    rotation: validateRotation(rotation),
  };
}

function getMinAndMaxCM(isMetric): { minCM: number; maxCM: number } {
  const minCM = isMetric ? MIN_PLAN_CM_WHEN_METRIC : MIN_PLAN_CM_WHEN_IMPERIAL;
  const maxCM = isMetric ? MAX_PLAN_CM_WHEN_METRIC : MAX_PLAN_CM_WHEN_IMPERIAL;

  return {
    minCM,
    maxCM,
  };
}

export function validPlanDimension(dimension: number, isMetric: boolean): boolean {
  const { minCM, maxCM } = getMinAndMaxCM(isMetric);
  return dimension >= minCM && dimension <= maxCM;
}

function clampDimension(dimension: number, isMetric: boolean): number {
  const { minCM, maxCM } = getMinAndMaxCM(isMetric);

  if (dimension > maxCM) {
    return maxCM;
  }

  if (dimension < minCM) {
    return minCM;
  }

  return dimension;
}

export function validatePlanDimensions(width: number, height: number, isMetric: boolean): Dimensions {
  const _width = width === 0 ? 0 : clampDimension(width, isMetric);
  const _height = height === 0 ? 0 : clampDimension(height, isMetric);

  return {
    width: _width,
    height: _height,
  };
}

const plantProperties = ['startX', 'startY', 'endX', 'endY', 'rotation', 'labelXOffset', 'labelYOffset'] as const;
const gardenObjectProperties = ['startX', 'startY', 'midX', 'midY', 'endX', 'endY', 'rotation'] as const;
const shapeProperties = ['startX', 'startY', 'midX', 'midY', 'endX', 'endY', 'rotation'] as const;
const textProperties = ['startX', 'startY', 'endX', 'endY', 'rotation'] as const;

export type APIPlanDocumentInvalidProperty<T> = {
  property: keyof T;
  previousValue: T[keyof T];
  newValue: T[keyof T];
};

export type APIPlanDocumentInvalidProperties = {
  plants: { id: number; properties: APIPlanDocumentInvalidProperty<APIPlanPlant>[] }[];
  gardenObjects: {
    id: number;
    properties: APIPlanDocumentInvalidProperty<APIPlanGardenObject>[];
  }[];
  shapes: { id: number; properties: APIPlanDocumentInvalidProperty<APIPlanShape>[] }[];
  text: { id: number; properties: APIPlanDocumentInvalidProperty<APIPlanText>[] }[];
};

function validateNumber(number: number): { valid: true } | { valid: false; replacement: number } {
  if (!Number.isFinite(number)) {
    return { valid: false, replacement: 0 };
  }
  if (number > FGP_MAX) {
    return { valid: false, replacement: FGP_MAX };
  }
  if (number < -FGP_MAX) {
    return { valid: false, replacement: -FGP_MAX };
  }
  return { valid: true };
}

/**
 * Iterates over all items in a plan, making sure they seems valid.
 * If an item appears invalid, its values will be changed in-place to something valid, and the property will be returned.
 * @param plan The plan to "fix"
 * @returns A collection of all the properties that were modified to make the plan seemingly valid
 */
export function validatePlanItemsInPlace(plan: APIPlanDocument) {
  const invalidProperties: APIPlanDocumentInvalidProperties = {
    plants: [],
    gardenObjects: [],
    shapes: [],
    text: [],
  };

  plan.planPlants.forEach((plant) => {
    const properties: APIPlanDocumentInvalidProperty<APIPlanPlant>[] = [];
    plantProperties.forEach((property) => {
      const validation = validateNumber(plant[property]);
      if (!validation.valid) {
        properties.push({
          property,
          previousValue: plant[property],
          newValue: validation.replacement,
        });
        plant[property] = validation.replacement;
      }
    });
    if (properties.length > 0) {
      invalidProperties.plants.push({ id: plant.objectID, properties });
    }
  });

  plan.planGardenObjects.forEach((gardenObject) => {
    const properties: APIPlanDocumentInvalidProperty<APIPlanGardenObject>[] = [];
    gardenObjectProperties.forEach((property) => {
      const validation = validateNumber(gardenObject[property]);
      if (!validation.valid) {
        properties.push({
          property,
          previousValue: gardenObject[property],
          newValue: validation.replacement,
        });
        gardenObject[property] = validation.replacement;
      }
    });
    if (properties.length > 0) {
      invalidProperties.gardenObjects.push({ id: gardenObject.objectID, properties });
    }
  });

  plan.planShapes.forEach((shape) => {
    const properties: APIPlanDocumentInvalidProperty<APIPlanShape>[] = [];
    shapeProperties.forEach((property) => {
      const validation = validateNumber(shape[property]);
      if (!validation.valid) {
        properties.push({
          property,
          previousValue: shape[property],
          newValue: validation.replacement,
        });
        shape[property] = validation.replacement;
      }
    });
    if (properties.length > 0) {
      invalidProperties.shapes.push({ id: shape.objectID, properties });
    }
  });

  plan.planText.forEach((text) => {
    const properties: APIPlanDocumentInvalidProperty<APIPlanText>[] = [];
    textProperties.forEach((property) => {
      const validation = validateNumber(text[property]);
      if (!validation.valid) {
        properties.push({
          property,
          previousValue: text[property],
          newValue: validation.replacement,
        });
        text[property] = validation.replacement;
      }
    });
    if (properties.length > 0) {
      invalidProperties.text.push({ id: text.objectID, properties });
    }
  });

  return invalidProperties;
}
