import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { MathUtils } from '@gi/math';
import { PlanBackgroundImage } from '@gi/plan';
import LengthInput, { DegreesInput, VectorInput } from '@gi/length-input';
import FormField, { FormSection, FormValues, InputContainer, createFormValues } from '@gi/form-responsive';

import LinkedFormLabel from '../../components/linked-form-label';
import PlanBackgroundImageFitDropdown from '../../components/plan-background-image-fit-dropdown';
import { FieldPreset, FieldPresetFull, VectorFieldPreset } from '../layout';
import { PlanBackgroundImageEditorContext } from '../../plan-background-image-editor-context';

import styles from '../styles.module.css';

function formValuesToPlanBackground(formValues: PlanBackgroundImage): PlanBackgroundImage {
  return {
    src: formValues.src,
    position: formValues.position,
    dimensions: formValues.dimensions,
    rotation: MathUtils.degToRad(formValues.rotation),
  };
}

interface iProps {
  backgroundImage: PlanBackgroundImage;
  onViewPlan?: () => void;
}

const BackgroundPropertiesForm = ({ backgroundImage, onViewPlan }: iProps): JSX.Element => {
  const { plan, distanceUnits, updateBackgroundImage, settings, setSettings, isEditing, setIsEditing } = useContext(PlanBackgroundImageEditorContext);

  const planDimensions = useMemo<Dimensions>(
    () => ({
      width: plan.width,
      height: plan.height,
    }),
    [plan.width, plan.height]
  );

  const [values, setValues] = useState(
    createFormValues<PlanBackgroundImage>({
      src: { value: backgroundImage.src },
      position: { value: backgroundImage.position },
      dimensions: { value: backgroundImage.dimensions },
      rotation: { value: MathUtils.radToDeg(backgroundImage.rotation) },
    })
  );

  const handleValuesChange = useCallback(
    (newValues: FormValues<PlanBackgroundImage>) => {
      setValues(newValues);
      if (newValues.isValid) {
        updateBackgroundImage(formValuesToPlanBackground(newValues.values));
      }
    },
    [updateBackgroundImage]
  );

  const createSetter = useCallback(
    <K extends keyof PlanBackgroundImage>(setting: K) =>
      (val: PlanBackgroundImage[K]) => {
        handleValuesChange(values.setValue(setting, { value: val }));
      },
    [values, handleValuesChange]
  );

  const createDimensionsSetter = useCallback(
    <K extends keyof PlanBackgroundImage['dimensions']>(setting: K) =>
      (val: PlanBackgroundImage['dimensions'][K]) => {
        const { dimensions } = values.values;
        let { width, height } = dimensions;
        const aspect = width / height;

        if (setting === 'width') {
          width = val;
          if (settings.maintainAspectRatio) {
            height = val / aspect;
          }
        } else {
          height = val;
          if (settings.maintainAspectRatio) {
            width = val * aspect;
          }
        }
        handleValuesChange(values.setValue('dimensions', { value: { width, height } }));
      },
    [values, handleValuesChange, settings.maintainAspectRatio]
  );

  const createDimensionLabel = useCallback(
    (label: 'width' | 'height') => {
      const isWidth = label === 'width';

      const onClick = () => {
        setSettings({ maintainAspectRatio: !settings.maintainAspectRatio });
      };

      return (
        <LinkedFormLabel
          orientation={isWidth ? 'top' : 'bottom'}
          isLinked={settings.maintainAspectRatio}
          onLinkClick={isWidth ? onClick : undefined}
          buttonDisabled={!isEditing}
        >
          {isWidth ? 'Width' : 'Height'}
        </LinkedFormLabel>
      );
    },
    [values, setSettings, settings.maintainAspectRatio, isEditing]
  );

  const handlePresetChange = useCallback(
    (newValues: PlanBackgroundImage) => {
      handleValuesChange(
        values.setValues(
          ['dimensions', { value: newValues.dimensions }],
          ['position', { value: newValues.position }],
          ['rotation', { value: newValues.rotation }],
          ['src', { value: newValues.src }]
        )
      );
    },
    [values, handleValuesChange]
  );

  /** Slightly terrible: Syncs the form to the background image being changed */
  useEffect(() => {
    setValues(
      values.setValues(
        ['dimensions', { value: backgroundImage.dimensions }],
        ['position', { value: backgroundImage.position }],
        ['rotation', { value: MathUtils.radToDeg(backgroundImage.rotation) }],
        ['src', { value: backgroundImage.src }]
      )
    );
  }, [backgroundImage]);

  return (
    <FormSection heading='Layout' padding={6} gap={6} className='form-section-background'>
      <FormField label='Preset' htmlFor='bg-properties:preset' layoutPreset={FieldPresetFull} disabled={!isEditing} desktopLayout={{ inputSize: 'full' }}>
        <InputContainer size='full'>
          <PlanBackgroundImageFitDropdown
            backgroundImage={values.values}
            onChange={handlePresetChange}
            planDimensions={planDimensions}
            disabled={!isEditing}
            id='bg-properties:preset'
          />
        </InputContainer>
      </FormField>
      <FormField label='Position' htmlFor='bg-properties:position-x' layoutPreset={VectorFieldPreset} disabled={!isEditing}>
        <VectorInput
          id='bg-properties:position'
          value={values.values.position}
          onChange={createSetter('position')}
          distanceUnits={distanceUnits}
          disabled={!isEditing}
          disabledAppliesToField={false}
        />
      </FormField>
      <FormField label={createDimensionLabel('width')} htmlFor='bg-properties:width' layoutPreset={FieldPreset} disabled={!isEditing}>
        <LengthInput
          id='bg-properties:width'
          value={values.values.dimensions.width}
          onChange={createDimensionsSetter('width')}
          distanceUnits={distanceUnits}
          disabled={!isEditing}
        />
      </FormField>
      <FormField label={createDimensionLabel('height')} htmlFor='bg-properties:height' layoutPreset={FieldPreset} disabled={!isEditing}>
        <LengthInput
          id='bg-properties:height'
          value={values.values.dimensions.height}
          onChange={createDimensionsSetter('height')}
          distanceUnits={distanceUnits}
          disabled={!isEditing}
        />
      </FormField>
      <FormField label='Rotation' htmlFor='bg-properties:rotation' layoutPreset={FieldPreset} disabled={!isEditing}>
        <DegreesInput id='bg-properties:rotation' value={values.values.rotation} onChange={createSetter('rotation')} disabled={!isEditing} decimals={2} />
      </FormField>
      <div className={styles.imageEditButtons}>
        {onViewPlan ? (
          <button
            type='button'
            onClick={() => {
              setIsEditing(true);
              onViewPlan();
            }}
            className='button button-secondary'
          >
            Edit on Plan
          </button>
        ) : null}
        <button type='button' onClick={() => setIsEditing(!isEditing)} className='button button-primary'>
          {!isEditing ? 'Edit' : 'Done'}
        </button>
      </div>
    </FormSection>
  );
};

export default BackgroundPropertiesForm;
