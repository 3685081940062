import React from 'react';

import { HelpModal } from '@gi/modal';

import ItemLayersHelp from './sections/item-layers-help';
import ItemLockingHelp from './sections/item-locking-help';

interface iProps {
  closeModal: () => void;
}

const LayoutControlsInfoModal = ({ closeModal }: iProps): JSX.Element => {
  return (
    <HelpModal headerText='Extra layout functions in the Garden Planner' closeModal={closeModal} style={{ maxWidth: 520 }}>
      <ItemLockingHelp />
      <ItemLayersHelp />
    </HelpModal>
  );
};

export default LayoutControlsInfoModal;
