import React from 'react';

import FormField, { FORM_FIELD_PRESETS, FormLayout, FormSection, FormValuesField, FORM_LAYOUT_PRESETS } from '@gi/form-responsive';

interface iProps {
  showTouchIntroductionModalOnStart: FormValuesField<boolean>;
  setShowIntroductionModalOnStart: (showTouchIntroductionModalOnStart: boolean) => void;
  showSFGHelpOnToggle: FormValuesField<boolean>;
  setShowSFGHelpOnToggle: (showSFGHelpOnToggle: boolean) => void;
}

const ModalSettings = ({
  showTouchIntroductionModalOnStart,
  setShowIntroductionModalOnStart,
  showSFGHelpOnToggle,
  setShowSFGHelpOnToggle,
}: iProps): JSX.Element => {
  return (
    <FormSection heading='Modals' padding={12} gap={6} className='form-section-background'>
      <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.CheckboxStack}>
        <FormField
          label='Show touch introduction modal at startup'
          helpText='This only applies if you are using a touch-capable device'
          htmlFor='modal-settings:show-touch-modal'
          layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
        >
          <input
            type='checkbox'
            checked={showTouchIntroductionModalOnStart.value}
            onChange={(e) => {
              setShowIntroductionModalOnStart(e.target.checked);
            }}
            id='modal-settings:show-touch-modal'
          />
        </FormField>
        <FormField
          label='Show SFG help modal when SFG mode is first turned on'
          htmlFor='modal-settings:show-sfg-modal-on-toggle'
          layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
        >
          <input
            type='checkbox'
            checked={showSFGHelpOnToggle.value}
            onChange={(e) => {
              setShowSFGHelpOnToggle(e.target.checked);
            }}
            id='modal-settings:show-sfg-modal-on-toggle'
          />
        </FormField>
      </FormLayout>
    </FormSection>
  );
};

export default ModalSettings;
