import React, { ReactNode } from 'react';

import './column.scss';

/**
 * Adds a class to a classlist if a valid size is given
 * @param classList The classlist to append to
 * @param name The name of the breakpoint
 * @param size The column size to use
 */
function addBreakpointClass(classList: string[], breakpoint: string, size?: number) {
  if (size) {
    classList.push(`column-${breakpoint}-${size}`);
  }
}

interface iProps {
  className?: string;
  children?: ReactNode;
  mobileSize?: number;
  desktopSize?: number;
}

const Column = ({ className, children, mobileSize, desktopSize }: iProps): JSX.Element => {
  const classNames = ['page-column'];
  addBreakpointClass(classNames, 'mobile', mobileSize);
  addBreakpointClass(classNames, 'desktop', desktopSize);
  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default Column;
