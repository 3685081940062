import React, { ReactNode } from 'react';

import styles from './menu-dropdown.module.css';

interface iProps {
  children?: ReactNode;
}

const MenuDropdownSection = ({ children }: iProps): JSX.Element => {
  return <div className={styles.section}>{children}</div>;
};

export default MenuDropdownSection;
