import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { CanvasSelectors } from '@gi/react-garden-canvas';
import { ResourceContext } from '@gi/resource-provider';
import { getDistanceUnitsFromIsMetric } from '@gi/units';

import LocalPlantListContext from '../local-provider/local-plant-list-context';
import AreaTableRow from './area-table-row';

const AreaTableRows = (): JSX.Element | null => {
  const { filteredAreaGroupData } = useContext(LocalPlantListContext);
  const { userDistanceUnits } = useContext(ResourceContext);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  const distanceUnits = activePlan ? getDistanceUnitsFromIsMetric(activePlan.plannerSettings.metric) : userDistanceUnits;

  return (
    <>
      {filteredAreaGroupData.map((areaGroup, index) => (
        <AreaTableRow key={areaGroup.planPlant.id} areaGroup={areaGroup} plant={areaGroup.plant} evenRow={index % 2 === 0} distanceUnits={distanceUnits} />
      ))}
    </>
  );
};

export default AreaTableRows;
