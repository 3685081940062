import React, { useCallback, useMemo } from 'react';
import ReactSelect from 'react-select';

import { GardenObjectPreset, GardenObjectUtils } from '@gi/garden-object';
import { DegreesInput, VectorInput } from '@gi/length-input';
import FormField, { FormSection, FORM_FIELD_PRESETS, InputContainer } from '@gi/form-responsive';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import { EditGardenObjectModalFormProps, GardenObjectState } from '../types';

interface PresetDropdownOption {
  label: string;
  value: GardenObjectPreset | null;
}

interface iProps extends EditGardenObjectModalFormProps {
  presets: GardenObjectPreset[];
}

const EditGardenObjectModalFormPresets = ({ values, setValues, distanceUnits, presets }: iProps): JSX.Element => {
  const createSetter = useCallback(
    <K extends keyof GardenObjectState>(setting: K) =>
      (value: GardenObjectState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  const presetOptions = useMemo(() => {
    return presets.map((preset) => ({
      label: preset.displayName,
      value: preset,
    }));
  }, [presets]);

  const selectedOption = useMemo(() => {
    const { width, height } = values.values;
    const result = GardenObjectUtils.getClosestPreset(width, height, presets);
    if (!result) {
      return { label: 'None', value: null };
    }
    return presetOptions.find((option) => option.value === result.preset) ?? { label: 'None', value: null };
  }, [presetOptions, values.values.width, values.values.height]);

  const onPresetSelect = useCallback(
    (preset: GardenObjectPreset) => {
      setValues(values.setValues(['width', { value: preset.width }], ['height', { value: preset.height }]));
    },
    [setValues]
  );

  return (
    <FormSection heading='Position &amp; Rotation' padding={12} gap={6} className='form-section-background'>
      <FormField label='Preset' htmlFor='edit-garden-object:preset' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <InputContainer size='full'>
          <ReactSelect<PresetDropdownOption>
            inputId='edit-garden-object:preset'
            isDisabled={presets.length === 0}
            isSearchable={false}
            styles={DEFAULT_SELECT_STYLES}
            options={presetOptions}
            value={selectedOption}
            onChange={(val) => {
              if (val !== null && val.value !== null) {
                onPresetSelect(val.value);
              }
            }}
            menuPortalTarget={document.body}
          />
        </InputContainer>
      </FormField>
      <FormField label='Center' htmlFor='edit-garden-object:center-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.center} onChange={createSetter('center')} distanceUnits={distanceUnits} id='edit-garden-object:center' />
      </FormField>
      <FormField label='Rotation' htmlFor='edit-garden-object:rotation' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <DegreesInput value={values.values.rotation} onChange={createSetter('rotation')} id='edit-garden-object:rotation' />
      </FormField>
    </FormSection>
  );
};

export default EditGardenObjectModalFormPresets;
