import React from 'react';
import styles from './components.module.css';

interface iProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
}

const ListItemDetailsColumn = ({ children, className = '', ...props }: iProps): JSX.Element => {
  return (
    <div className={`${styles.listItemDetailsColumn} ${className}`} {...props}>
      {children}
    </div>
  );
};

export default ListItemDetailsColumn;
