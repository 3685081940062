import React, { CSSProperties, useCallback, useContext, useMemo } from 'react';

import { ResourceContext } from '@gi/resource-provider';
import { DrawingToolsContext } from '@gi/drawing-tools';
import GardenObject, { GardenObjectGroup } from '@gi/garden-object';

import ItemGroupHeading from '../item-group-heading';
import GardenObjectIcon from './garden-object-icon';
import { iSelectableItemTag } from '../selectable-item';

interface iProps {
  gardenObjects: GardenObject[];
  gardenObjectGroup: GardenObjectGroup;
  expanded?: boolean;
  style?: CSSProperties;
}

const GardenObjectGroupHeadingRow = ({ gardenObjectGroup, gardenObjects, expanded, style }: iProps): JSX.Element => {
  const { toggleGardenObjectGroup } = useContext(DrawingToolsContext);
  const { getGardenObject, store } = useContext(ResourceContext);

  /**
   * When clicked, select the garden object and let our parent know.
   */
  const handleOnClick = useCallback(() => {
    toggleGardenObjectGroup(gardenObjectGroup.id);
  }, [toggleGardenObjectGroup, gardenObjectGroup]);

  /**
   * Create the list of tags related to the garden object.
   */
  const tags = useMemo(() => {
    const allObjects = gardenObjectGroup.objectCodes.map(getGardenObject);

    const isSeasonExtender = allObjects.every((object) => object !== null && object.plantModifier !== null);
    const isPurchasable = allObjects.every((object) => object && store && store.products.productsByCode[object.code]);

    const finalTags: iSelectableItemTag[] = [];
    if (isSeasonExtender) {
      finalTags.push({ name: 'Season Extender' });
    }
    if (isPurchasable) {
      finalTags.push({ name: 'In-Store', className: 'in-store-tag' });
    }

    return finalTags;
  }, [gardenObjectGroup.objectCodes]);

  return (
    <ItemGroupHeading
      className='garden-object-group'
      icon={<GardenObjectIcon code={gardenObjectGroup.iconCode ?? gardenObjectGroup.objectCodes[0]} />}
      name={gardenObjectGroup.displayName}
      amountText={`${gardenObjects.length} ${gardenObjectGroup.variantText ?? 'variants'}`}
      expanded={expanded}
      tags={tags}
      onClick={handleOnClick}
      style={style}
      intercomIdentifier='selectable-garden-object'
    />
  );
};

export default GardenObjectGroupHeadingRow;
