import React from 'react';

interface iProps {
  children: React.ReactNode;
  className?: string;
}

const TableHead = ({ children, className = '' }: iProps): JSX.Element => {
  return <thead className={`table-head ${className}`}>{children}</thead>;
};

export default TableHead;
