import Node from './node';
import { InspectableClassData } from './types';

// const xtransforms = [0, 0, 40, 0, 0];
// const ytransforms = [0, 0, 0, 0, 0];
// const ztransforms = [0, 0, 0, 0, 0];

// const translationMatricies = [[x, 0, 0, y, 0, 1], [x, 0, 0, y, 0, 1], [x, 0, 0, y, 0, 1], [x, 0, 0, y, 0, 1]]
// const scalingMatricies = [[x, 0, 0, y, 0, 1], [x, 0, 0, y, 0, 1], null, [x, 0, 0, y, 0, 1]]
// const rotationMatricies = [[x, 0, 0, y, 0, 1], [x, 0, 0, y, 0, 1], [x, 0, 0, y, 0, 1], [x, 0, 0, y, 0, 1]]

/**
 * This currently doesn't do anything, but may be needed in the future if non-graphic nodes get
 *  added properly.
 */
class GraphicNode extends Node {
  type = 'GraphicNode';

  inspectorData: InspectableClassData<this> = this.inspectorData;
}

export default GraphicNode;
