import { errorReporterInstance } from '@gi/errors';

import RequestActionTypes from './request-action-types';

class GenericRequestError extends Error {
  constructor(key) {
    super(`Request '${key}' failed, and no error reason was provided`);
  }
}

const requestStart = (key) => {
  return {
    type: RequestActionTypes.START_REQUEST,
    key,
  };
};

const requestComplete = (key) => {
  return {
    type: RequestActionTypes.REQUEST_COMPLETE,
    key,
  };
};

const requestFail = (key, error) => {
  if (errorReporterInstance) {
    const err = error ?? new GenericRequestError(key); // Fallback in case no error supplied
    errorReporterInstance.notify(err, (event) => {
      if (event) {
        event.addMetadata('request', key);
      }
    });
  }

  return {
    type: RequestActionTypes.REQUEST_FAIL,
    key,
    error,
  };
};

const clearRequest = (key) => {
  return {
    type: RequestActionTypes.CLEAR_REQUEST,
    key,
  };
};

export { requestStart, requestComplete, requestFail, clearRequest };
