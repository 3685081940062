import { Point, Rectangle, Texture, TextureMatrix, Transform, Sprite, Matrix, utils, canvasUtils } from 'pixi.js-new';

import type { IBaseTextureOptions, IPoint, IPointData, ISize, ObservablePoint, Renderer, TextureSource, IDestroyOptions, CanvasRenderer } from 'pixi.js-new';

import { errorReporterInstance } from '@gi/errors';

const tempPoint = new Point();
const worldMatrix = new Matrix();
const patternMatrix = new Matrix();
const patternRect = [new Point(), new Point(), new Point(), new Point()];

/**
 * A tiling sprite is a fast way of rendering a tiling image.
 */
export class TilingSprite extends Sprite {
  /** Tile transform */
  public tileTransform: Transform;

  /** Matrix that is applied to UV to get the coords in Texture normalized space to coords in BaseTexture space. */
  public uvMatrix: TextureMatrix;

  /**
   * Flags whether the tiling pattern should originate from the origin instead of the top-left corner in
   * local space.
   *
   * This will make the texture coordinates assigned to each vertex dependent on the value of the anchor. Without
   * this, the top-left corner always gets the (0, 0) texture coordinate.
   * @default false
   */
  public uvRespectAnchor: boolean;
  private _canvasPattern: CanvasPattern | null;

  /**
   * Note: The wrap mode of the texture is forced to REPEAT on render if the size of the texture
   * is a power of two, the texture's wrap mode is CLAMP, and the texture hasn't been bound yet.
   * @param texture - The texture of the tiling sprite.
   * @param width - The width of the tiling sprite.
   * @param height - The height of the tiling sprite.
   */
  constructor(texture: Texture, width = 100, height = 100) {
    super(texture);

    this.tileTransform = new Transform();

    // The width of the tiling sprite
    this._width = width;

    // The height of the tiling sprite
    this._height = height;

    this.uvMatrix = this.texture.uvMatrix || new TextureMatrix(texture);

    /**
     * Plugin that is responsible for rendering this element.
     * Allows to customize the rendering process without overriding '_render' method.
     * @default 'tilingSprite'
     */
    this.pluginName = 'customTilingSprite';

    this.uvRespectAnchor = false;
  }
  /**
   * Changes frame clamping in corresponding textureTransform, shortcut
   * Change to -0.5 to add a pixel to the edge, recommended for transparent trimmed textures in atlas
   * @default 0.5
   * @member {number}
   */
  get clampMargin(): number {
    return this.uvMatrix.clampMargin;
  }

  set clampMargin(value: number) {
    this.uvMatrix.clampMargin = value;
    this.uvMatrix.update(true);
  }

  /** The scaling of the image that is being tiled. */
  get tileScale(): ObservablePoint {
    return this.tileTransform.scale;
  }

  set tileScale(value: IPointData) {
    this.tileTransform.scale.copyFrom(value as IPoint);
  }

  /** The offset of the image that is being tiled. */
  get tilePosition(): ObservablePoint {
    return this.tileTransform.position;
  }

  set tilePosition(value: ObservablePoint) {
    this.tileTransform.position.copyFrom(value as IPoint);
  }

  protected _onTextureUpdate(): void {
    if (this.uvMatrix) {
      this.uvMatrix.texture = this._texture;
    }
    this._cachedTint = 0xffffff;
  }

  /**
   * Renders the object using the WebGL renderer
   * @param renderer - The renderer
   */
  protected _render(renderer: Renderer): void {
    // tweak our texture temporarily..
    const texture = this._texture;

    if (!texture || !texture.valid) {
      return;
    }

    this.tileTransform.updateLocalTransform();
    this.uvMatrix.update();

    renderer.batch.setObjectRenderer(renderer.plugins[this.pluginName]);
    renderer.plugins[this.pluginName].render(this);
  }

  /** Updates the bounds of the tiling sprite. */
  protected _calculateBounds(): void {
    const minX = this._width * -this._anchor._x;
    const minY = this._height * -this._anchor._y;
    const maxX = this._width * (1 - this._anchor._x);
    const maxY = this._height * (1 - this._anchor._y);

    this._bounds.addFrame(this.transform, minX, minY, maxX, maxY);
  }

  /**
   * Gets the local bounds of the sprite object.
   * @param rect - Optional output rectangle.
   * @returns The bounds.
   */
  public getLocalBounds(rect?: Rectangle): Rectangle {
    // we can do a fast local bounds if the sprite has no children!
    if (this.children.length === 0) {
      this._bounds.minX = this._width * -this._anchor._x;
      this._bounds.minY = this._height * -this._anchor._y;
      this._bounds.maxX = this._width * (1 - this._anchor._x);
      this._bounds.maxY = this._height * (1 - this._anchor._y);

      if (!rect) {
        if (!this._localBoundsRect) {
          this._localBoundsRect = new Rectangle();
        }

        rect = this._localBoundsRect;
      }

      return this._bounds.getRectangle(rect);
    }

    return super.getLocalBounds.call(this, rect);
  }

  /**
   * Checks if a point is inside this tiling sprite.
   * @param point - The point to check.
   * @returns Whether or not the sprite contains the point.
   */
  public containsPoint(point: IPointData): boolean {
    this.worldTransform.applyInverse(point, tempPoint);

    const width = this._width;
    const height = this._height;
    const x1 = -width * this.anchor._x;

    if (tempPoint.x >= x1 && tempPoint.x < x1 + width) {
      const y1 = -height * this.anchor._y;

      if (tempPoint.y >= y1 && tempPoint.y < y1 + height) {
        return true;
      }
    }

    return false;
  }

  /**
   * Destroys this sprite and optionally its texture and children
   * @param {object|boolean} [options] - Options parameter. A boolean will act as if all options
   *  have been set to that value
   * @param {boolean} [options.children=false] - if set to true, all the children will have their destroy
   *      method called as well. 'options' will be passed on to those calls.
   * @param {boolean} [options.texture=false] - Should it destroy the current texture of the sprite as well
   * @param {boolean} [options.baseTexture=false] - Should it destroy the base texture of the sprite as well
   */
  public destroy(options?: IDestroyOptions | boolean): void {
    super.destroy(options);

    // this.tileTransform = null;
    // this.uvMatrix = null;
  }

  /**
   * Helper function that creates a new tiling sprite based on the source you provide.
   * The source can be - frame id, image url, video url, canvas element, video element, base texture
   * @static
   * @param {string|PIXI.Texture|HTMLCanvasElement|HTMLVideoElement} source - Source to create texture from
   * @param {object} options - See {@link PIXI.BaseTexture}'s constructor for options.
   * @param {number} options.width - required width of the tiling sprite
   * @param {number} options.height - required height of the tiling sprite
   * @returns {PIXI.TilingSprite} The newly created texture
   */
  static from(source: TextureSource | Texture, options: ISize & IBaseTextureOptions): TilingSprite {
    const texture = source instanceof Texture ? source : Texture.from(source, options);

    return new TilingSprite(texture, options.width, options.height);
  }

  /** The width of the sprite, setting this will actually modify the scale to achieve the value set. */
  get width(): number {
    return this._width;
  }

  set width(value: number) {
    this._width = value;
  }

  /** The height of the TilingSprite, setting this will actually modify the scale to achieve the value set. */
  get height(): number {
    return this._height;
  }

  set height(value: number) {
    this._height = value;
  }

  /**
   * Renders the object using the Canvas renderer
   * @function _renderCanvas
   * @memberof PIXI.TilingSprite#
   * @param {PIXI.CanvasRenderer} renderer - a reference to the canvas renderer
   */
  public _renderCanvas(renderer: CanvasRenderer): void {
    const texture = this._texture;

    if (!texture.baseTexture.valid) {
      return;
    }

    const context = renderer.canvasContext.activeContext;
    const transform = this.worldTransform;
    const { baseTexture } = texture;
    const source = baseTexture.getDrawableSource!();
    const baseTextureResolution = baseTexture.resolution;

    // create a nice shiny pattern!
    if (this._textureID !== this._texture._updateID || this._cachedTint !== this.tintValue) {
      this._textureID = this._texture._updateID;
      // cut an object from a spritesheet..
      const tempCanvas = new utils.CanvasRenderTarget(texture._frame.width, texture._frame.height, baseTextureResolution);

      // Tint the tiling sprite
      if (this.tintValue !== 0xffffff) {
        this._tintedCanvas = canvasUtils.getTintedCanvas(this, this.tintValue);
        tempCanvas.context.drawImage(this._tintedCanvas, 0, 0);
      } else {
        tempCanvas.context.drawImage(
          source,
          -texture._frame.x * baseTextureResolution,

          -texture._frame.y * baseTextureResolution
        );
      }
      this._cachedTint = this.tintValue;
      try {
        this._canvasPattern = tempCanvas.context.createPattern(tempCanvas.canvas, 'repeat');
      } catch (e) {
        /**
         * [07/01/2025]
         * The above `createPattern` call seems to occasionally fail on iOS, giving:
         *  InvalidStateError The object is in an invalid state.
         * I'm not sure why, but it's likely because the canvas is invalid, either due to garbage collection or some other reason.
         *
         * Most of the users reporting issues with plant labels disappearing are getting this error.
         * I think this is correlation not causation, but it can't hurt to get some more information about why this happens.
         */
        console.error(
          `Error while creating pattern...
Context: ${tempCanvas.context}
Canvas: ${tempCanvas.canvas}
Width: ${tempCanvas.canvas?.width}
Height: ${tempCanvas.canvas?.height}`
        );
        console.error(e);
        errorReporterInstance.notify(e);
      }
    }

    // set context state..
    context.globalAlpha = this.worldAlpha;
    renderer.canvasContext.setBlendMode(this.blendMode);

    this.tileTransform.updateLocalTransform();
    const lt = this.tileTransform.localTransform;
    const W = this._width;
    const H = this._height;

    /*
     * # Implementation Notes
     *
     * The tiling transform is not simply a transform on the tiling sprite's local space. If that
     * were, the bounds of the tiling sprite would change. Rather the tile transform is a transform
     * on the "pattern" coordinates each vertex is assigned.
     *
     * To implement the `tileTransform`, we issue drawing commands in the pattern's own space, which
     * is defined as:
     *
     * Pattern_Space = Local_Space x inverse(tileTransform)
     *
     * In other words,
     * Local_Space = Pattern_Space x tileTransform
     *
     * We draw the pattern in pattern space, because the space we draw in defines the pattern's coordinates.
     * In other words, the pattern will always "originate" from (0, 0) in the space we draw in.
     *
     * This technique is equivalent to drawing a pattern texture, and then finding a quadrilateral that becomes
     * the tiling sprite's local bounds under the tileTransform and mapping that onto the screen.
     *
     * ## uvRespectAnchor
     *
     * The preceding paragraph discusses the case without considering `uvRespectAnchor`. The `uvRespectAnchor` flags
     * where the origin of the pattern space is. Assuming the tileTransform includes no translation, without
     * loss of generality: If uvRespectAnchor = true, then
     *
     * Local Space (0, 0) <--> Pattern Space (0, 0) (where <--> means "maps to")
     *
     * Here the mapping is provided by trivially by the tileTransform (note tileTransform includes no translation. That
     * means the invariant under all other transforms are the origins)
     *
     * Otherwise,
     *
     * Local Space (-localBounds.x, -localBounds.y) <--> Pattern Space (0, 0)
     *
     * Here the mapping is provided by the tileTransform PLUS some "shift". This shift is done POST-tileTransform. The shift
     * is equal to the position of the top-left corner of the tiling sprite in its local space.
     *
     * Hence,
     *
     * Local_Space = Pattern_Space x tileTransform x shiftTransform
     */

    // worldMatrix is used to convert from pattern space to world space.
    //
    // worldMatrix = tileTransform x shiftTransform x worldTransform
    //             = patternMatrix x worldTransform
    worldMatrix.identity();

    // patternMatrix is used to convert from pattern space to local space. The drawing commands are issued in pattern space
    // and this matrix is used to inverse-map the local space vertices into it.
    //
    // patternMatrix = tileTransform x shiftTransform
    patternMatrix.copyFrom(lt);

    // Apply shiftTransform into patternMatrix. See $1.1
    if (!this.uvRespectAnchor) {
      patternMatrix.translate(-this.anchor.x * W, -this.anchor.y * H);
    }

    patternMatrix.scale(1 / baseTextureResolution, 1 / baseTextureResolution);
    worldMatrix.prepend(patternMatrix);
    worldMatrix.prepend(transform);

    renderer.canvasContext.setContextTransform(worldMatrix);

    // Fill the pattern!
    if (this._canvasPattern) {
      context.fillStyle = this._canvasPattern;
    }

    // The position in local space we are drawing the rectangle: (lx, ly, lx + W, ly + H)
    const lx = this.anchor.x * -W;
    const ly = this.anchor.y * -H;

    // Set pattern rect in local space first.
    patternRect[0].set(lx, ly);
    patternRect[1].set(lx + W, ly);
    patternRect[2].set(lx + W, ly + H);
    patternRect[3].set(lx, ly + H);

    // Map patternRect into pattern space.
    for (let i = 0; i < 4; i++) {
      patternMatrix.applyInverse(patternRect[i], patternRect[i]);
    }

    /*
     * # Note about verification of theory
     *
     * As discussed in the implementation notes, you can verify that `patternRect[0]` will always be (0, 0) in case of
     * `uvRespectAnchor` false and tileTransform having no translation. Indeed, because the pattern origin should map
     * to the top-left corner of the tiling sprite in its local space.
     */

    context.beginPath();
    context.moveTo(patternRect[0].x, patternRect[0].y);

    for (let i = 1; i < 4; i++) {
      context.lineTo(patternRect[i].x, patternRect[i].y);
    }

    context.closePath();
    context.fill();
  }
}
