import React, { ReactNode, useEffect, useRef, useState } from 'react';
import VerticalNavItem from './vertical-nav-item';
import './vertical-nav-section.scss';

interface iProps {
  children?: ReactNode;
  title: ReturnType<typeof VerticalNavItem>;
  className?: string;
  collapsible?: boolean;
  topmost?: boolean;
}

const VerticalNavSection = ({ children, className, title, collapsible = false, topmost = false }: iProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(true);
  const [containerHeight, setContainerHeight] = useState(0);
  const itemContainer = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setContainerHeight(itemContainer.current?.scrollHeight ?? 0);
  };

  useEffect(() => {
    setContainerHeight(itemContainer.current?.scrollHeight ?? 0);
  });

  const classNames = ['vertical-nav-section'];
  if (!isOpen) {
    classNames.push('closed');
  }
  if (topmost) {
    classNames.push('topmost');
  }
  if (className) {
    classNames.push(className);
  }

  const styles: Record<string, string> = {
    '--nav-height': `${containerHeight}px`,
  };

  return (
    <div className={classNames.join(' ')}>
      <div className='vertical-nav-section-header'>
        {title}
        {collapsible && (
          <button className='vertical-nav-section-toggler' aria-label='Toggle subnavigation visibility' onClick={toggleOpen} type='button'>
            <i className='icon icon-up-open-1' />
          </button>
        )}
      </div>
      <div className='vertical-nav-section-items' style={styles} ref={itemContainer}>
        {children}
      </div>
    </div>
  );
};

export default VerticalNavSection;
