import React, { useContext, useState, useEffect, ImgHTMLAttributes } from 'react';

import LazyImageContext from './lazy-image-context';

import styles from './lazy-image.module.scss';

interface iProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt: string;
}

const LazyImage = ({ src, alt, className, onLoad, ...remainingProps }: iProps): JSX.Element => {
  const { registerImage, unregisterImage } = useContext(LazyImageContext);
  const [shouldShow, setShouldShow] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (registerImage && ref) {
      registerImage(ref, () => {
        setShouldShow(true);
      });
      return () => {
        unregisterImage(ref);
      };
    }
    return () => {};
  }, [ref]);

  // Fallback - set the image as having loaded after a few seconds.
  // User is probably on a slow connection, it's more useful to show the image loading than showing nothing until it does.
  useEffect(() => {
    if (shouldShow || !registerImage) {
      const callback = () => setHasLoaded(true);
      const timeout = window.setTimeout(callback, 3000);

      return () => {
        window.clearTimeout(timeout);
      };
    }
    return () => {};
  }, [shouldShow]);

  if (shouldShow || !registerImage) {
    const classNames = [styles.lazyImage];
    if (hasLoaded) {
      classNames.push(styles.loaded);
    }
    if (className) {
      classNames.push(className);
    }

    return (
      <img
        loading='lazy'
        src={src}
        alt={alt}
        className={classNames.join(' ')}
        onLoad={(e) => {
          setHasLoaded(true);
          if (onLoad) {
            onLoad(e);
          }
        }}
        {...remainingProps}
      />
    );
  }

  return <div className={styles.lazyImagePlaceholder} ref={setRef} />;
};

export default LazyImage;
