/**
 * @module @gi/user
 */

import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import Plan from '@gi/plan';
import { getSetDiff } from '@gi/utils';

import { UserPlantVarietySet, UserPlantVarietySetShape } from './user-plant-variety-set';
import { UserPlanSettings, UserPlanSettingsShape } from './user-plan-settings';
import { UserPlanSet, UserPlanSetShape, UserPlanSetUtils } from './user-plan-set';
import { UserSubscription, UserSubscriptionShape } from './user-subscription';
import { UserSettings } from './user-settings';
import { UserMisc } from './user-misc';

// The minimum amount of frost-free days needed in a growing season for it to count as a tropical climate
const TROPICAL_CLIMATE_SEASON_LENGTH = 250;

export type User = {
  authToken: string;
  ID: number;
  hash: string;
  email: string;
  emailConfirmed: boolean;
  password: string;
  isTemporaryPassword: boolean;
  firstName: string;
  postAuthTicket: string;
  countryCode: string;
  accountCreated: moment.Moment;
  lastLogin: moment.Moment;
  accountEnabled: boolean;
  isMasterAccount: boolean;
  settings: UserSettings;
  tags: readonly string[];
  plans: UserPlanSet;
  plantVarieties: UserPlantVarietySet;
  planSettings: UserPlanSettings;
  subscription: UserSubscription;
  favouritePlants: Set<string>;
  misc: UserMisc;
};

/**
 * @deprecated
 * Avoid using PropTypes
 */
export const UserShape = PropTypes.shape({
  ID: PropTypes.number.isRequired,
  hash: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  emailConfirmed: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  isTemporaryPassword: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
  postAuthTicket: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
  accountCreated: PropTypes.object.isRequired,
  lastLogin: PropTypes.object.isRequired,
  accountEnabled: PropTypes.bool.isRequired,
  isMasterAccount: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  plans: UserPlanSetShape,
  plantVarieties: UserPlantVarietySetShape,
  planSettings: UserPlanSettingsShape,
  subscription: UserSubscriptionShape,
  favouritePlants: PropTypes.instanceOf(Set),
});

/**
 * Returns true if the user has the given tag
 * @param user The user object
 * @param tag The tag to find
 * @returns True if the user has the given tag
 */
const hasTag = (user: User, tag: string) => {
  return user.tags.includes(tag);
};

/**
 * Returns true if a users location is set
 * @param user The user object
 * @returns True if the user has set their location
 */
const hasLocation = (user: User) => {
  return !(
    user.settings.location.latitude === null ||
    user.settings.location.longitude === null ||
    (user.settings.location.latitude === 0 && user.settings.location.longitude === 0)
  );
};

/**
 * Adds or updates a plan for the given user
 * @param user The user object
 * @param plan The plan to add or update
 * @returns An updated UserPlanSet
 */
const updateUserPlansFromPlan = (user: User, plan: Plan) => {
  console.debug('Updating user plan from plan', plan.id);
  const updatedPlans = UserPlanSetUtils.updateUserPlansFromPlan(user.plans, plan);
  return {
    ...user,
    plans: updatedPlans,
  };
};

/**
 * Removes a plan from the given user
 * @param user The user object
 * @param planID The ID of the plan to remove
 * @returns An updated UserPlanSet
 */
const removeUserPlanFromPlans = (user: User, planID: number) => {
  const updatedPlans = UserPlanSetUtils.removeUserPlanFromPlans(user.plans, planID);
  return {
    ...user,
    plans: updatedPlans,
  };
};

/**
 * Checks if the given user is in the northern hemisphere
 * @param user The user object
 * @returns True if the user is in the northern hemisphere
 */
const isNorthernHemisphere = (user: User) => {
  return user.settings.location.northernHemisphere;
};

/**
 * Checks if the user is located in a tropical climate.
 * This is currently calculated based on the length of the growing season.
 * @param user The user object
 * @returns True if the user is located in a tropical climate
 */
const isTropicalClimate = (user: User) => {
  const { noFrost, first: firstFrost, last: lastFrost } = user.settings.frostDates;
  return noFrost || firstFrost - lastFrost >= TROPICAL_CLIMATE_SEASON_LENGTH;
};

/**
 * Merges the new and old Users. Used for when an API doesn't return the current user plans/plants/varieties.
 * @param user The user object
 * @param newUser The new user object
 * @returns A new, merged user object
 */
const updateFromUser = (user: User, newUser: User) => {
  if (newUser.ID !== user.ID) {
    throw new Error('Attempted to update user with values from a different user');
  }

  // Plants, plans and user varieties are not always updated from the API
  // so update them here from the current user
  let updatedUser: User = newUser;

  if (UserPlanSetUtils.planCount(newUser.plans) === 0 && UserPlanSetUtils.planCount(user.plans) !== 0) {
    updatedUser = {
      ...updatedUser,
      plans: user.plans,
    };
  }

  if (newUser.plantVarieties.userPlantVarieties.length === 0 && user.plantVarieties.userPlantVarieties.length !== 0) {
    updatedUser = {
      ...updatedUser,
      plantVarieties: user.plantVarieties,
    };
  }

  return updatedUser;
};

/**
 * Returns the status of user favouritePlants between two versions of user
 *
 * Designed to return the difference in the sets, so we can work out which
 * plants are newly favourited
 *
 * @param {User} user
 * @param {User} savingUser
 */
const getFavouritePlantData = (user: User, savingUser: User | null) => {
  if (savingUser === null || user === savingUser || user.favouritePlants === savingUser.favouritePlants) {
    return {
      favouritePlants: user.favouritePlants,
      adding: [],
      removing: [],
      changes: false,
      saving: false,
    };
  }

  const diff = getSetDiff(user.favouritePlants, savingUser.favouritePlants);

  return {
    favouritePlants: user.favouritePlants,
    adding: diff.added,
    removing: diff.removed,
    changes: diff.added.length > 0 || diff.removed.length > 0,
    saving: true,
  };
};

/**
 * React shape for FavouritePlantData
 */
export const favouritePlantDataShape = PropTypes.shape({
  favouritePlants: PropTypes.instanceOf(Set),
  adding: PropTypes.arrayOf(PropTypes.string),
  removing: PropTypes.arrayOf(PropTypes.string),
  changes: PropTypes.bool,
  saving: PropTypes.bool,
});

/**
 * React default for FavouritePlantData
 */
export const defaultFavouritePlantData = Object.freeze({
  favouritePlants: new Set(),
  adding: [],
  removing: [],
  changes: false,
  saving: false,
});

export const UserUtils = {
  hasTag,
  hasLocation,
  updateUserPlansFromPlan,
  removeUserPlanFromPlans,
  isNorthernHemisphere,
  isTropicalClimate,
  updateFromUser,
  getFavouritePlantData,
};

export default User;
