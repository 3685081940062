export interface GoogleLocation {
  latitude: number;
  longitude: number;
}

class GoogleLocationService {
  /**
   * Attempts to convert an address to a latitude and longitude.
   * @param address The address to find
   * @param geocoder The geocoder to use
   * @returns A latitude and longitude of the first result, if any
   */
  static async getLocationFromAddress(address: string, geocoder: google.maps.Geocoder) {
    const promise = new Promise<GoogleLocation>((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status !== 'OK') {
          return reject(new Error('Response not OK'));
        }
        if (!results || results.length <= 0) {
          return reject(new Error('No results for that address'));
        }
        const firstResult = results[0];
        if (!firstResult.geometry) {
          return reject(new Error('No results for that address'));
        }
        return resolve({
          latitude: firstResult.geometry.location.lat(),
          longitude: firstResult.geometry.location.lng(),
        });
      });
    });
    return promise;
  }
}

export default GoogleLocationService;
