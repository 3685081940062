import { BatchGeometry, TYPES } from 'pixi.js-new';

/**
 * Batch Geometry that also supports a mask and mask colours.
 * Used by the ShadedBatchRenderer to efficiently render masked sprites.
 */
class ShadedBatchGeometry extends BatchGeometry {
  constructor(_static = false) {
    super(_static);

    // We have 2 setsa of UV coors, the base texture and the mask. Store them both in a vec4 (lazy)
    // TODO: Is this more efficient? If not, split into seperate attributes to make code less horrible
    this.getAttribute('aTextureCoord').size = 4;

    // We'll have a base texture and mask texture, so increase attribute size to hold 2 texture IDs.
    // TODO: Is this more efficient? If not, split into seperate attributes to make code less horrible
    this.getAttribute('aTextureId').size = 2;

    this.addAttribute('aMaskColour1', this._buffer, 4, true, TYPES.UNSIGNED_BYTE);
    this.addAttribute('aMaskColour2', this._buffer, 4, true, TYPES.UNSIGNED_BYTE);
    this.addAttribute('aMaskColour3', this._buffer, 4, true, TYPES.UNSIGNED_BYTE);
    this.addAttribute('aMaskColour4', this._buffer, 4, true, TYPES.UNSIGNED_BYTE);
  }
}

export default ShadedBatchGeometry;
