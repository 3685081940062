import { networkErrorReporter, networkService } from '@gi/gi-network';
import { networkConfig } from '@gi/config';
import { UserReferralCode } from '../types';

class ReferralCodeService {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  getReferralCode() {
    const url = `${this.endpoint}/users/referralcode`;

    return networkService
      .get<UserReferralCode | null>(url)
      .then((response) => response.body)
      .catch(networkErrorReporter('GET', 'user referral code'));
  }
}

export const referralCodeService = new ReferralCodeService(networkConfig.newApiEndpoint);
