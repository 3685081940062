import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IS_TOUCH_DEVICE, PlannerTabTypes } from '@gi/constants';
import { CanvasActionTypes } from '@gi/react-garden-canvas';

import { DEFAULT_OPEN_PLAN_STATES, OpenPlanStateUtils, OpenPlanStates } from './open-plan-states';

export type PlannerState = {
  openPlanStates: OpenPlanStates;
  showTouchIntroductionModal: boolean;
  showTouchDragHelpModal: boolean;
  showItemLockingHelpModal: boolean;
};

const DEFAULT_STATE: PlannerState = {
  openPlanStates: DEFAULT_OPEN_PLAN_STATES,
  showTouchIntroductionModal: IS_TOUCH_DEVICE,
  showTouchDragHelpModal: false,
  showItemLockingHelpModal: false,
};

export interface SetActivePlannerTabPayload {
  planID: number;
  tabID: PlannerTabTypes;
}

export const plannerSlice = createSlice({
  name: 'planner',
  initialState: DEFAULT_STATE,
  reducers: {
    setActivePlannerTab: (state, action: PayloadAction<SetActivePlannerTabPayload>) => {
      if (!OpenPlanStateUtils.hasPlanState(state.openPlanStates, action.payload.planID)) {
        console.warn("Attempted to set active planner tab on plan which doesn't have an OpenPlanState", action.payload.planID);
        return state;
      }

      OpenPlanStateUtils.updatePlanState(state.openPlanStates, action.payload.planID, {
        activePlannerTab: action.payload.tabID,
      });
      return state;
    },
    dismissTouchIntroductionModal: (state) => {
      return {
        ...state,
        showTouchIntroductionModal: false,
      };
    },
    setShowTouchDragHelpModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showTouchDragHelpModal: action.payload,
      };
    },
    setShowItemLockingHelpModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showItemLockingHelpModal: action.payload,
      };
    },
  },
  extraReducers: {
    [CanvasActionTypes.OPEN_PLAN]: (state, action) => {
      if (OpenPlanStateUtils.hasPlanState(state.openPlanStates, action.planID)) {
        // Plan is already open
        return state;
      }

      OpenPlanStateUtils.addPlanState(state.openPlanStates, action.planID);
      return state;
    },
    [CanvasActionTypes.CLOSE_PLAN]: (state, action: PayloadAction<number>) => {
      if (!OpenPlanStateUtils.hasPlanState(state.openPlanStates, action.payload)) {
        // Plan is not already open
        return state;
      }

      OpenPlanStateUtils.removePlanState(state.openPlanStates, action.payload);
      return state;
    },
    [CanvasActionTypes.CREATE_PLAN_SUCCESS]: (state, action) => {
      if (OpenPlanStateUtils.hasPlanState(state.openPlanStates, action.plan.id)) {
        // Plan is already open
        return state;
      }

      OpenPlanStateUtils.addPlanState(state.openPlanStates, action.plan.id);
      return state;
    },
  },
});

export const plannerReducer = plannerSlice.reducer;
export const PlannerActionCreators = plannerSlice.actions;
