import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

export const getTopbarSelectStyles = (width: number) => ({
  ...DEFAULT_SELECT_STYLES,
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    borderRadius: '3px',
    minHeight: '32px',
    maxHeight: '32px',
    minWidth: `${width}px`,
    border: 'var(--button-border)',
    boxSizing: 'border-box',
    zIndex: 1, // Stops buttons overlapping the select box outline
    fontSize: '14px',
  }),
  dropdownIndicator: (provided) => ({
    ...DEFAULT_SELECT_STYLES.dropdownIndicator(provided),
    padding: '3px',
  }),
  valueContainer: (provided) => ({
    ...DEFAULT_SELECT_STYLES.valueContainer(provided),
    padding: '0 5px',
    minHeight: '30px',
  }),
});

export const getInlineTopbarSelectStyles = (width: number) => {
  const baseStyles = getTopbarSelectStyles(width);
  return {
    ...baseStyles,
    control: (provided, state) => ({
      ...baseStyles.control(provided, state),
      borderRadius: 0,
    }),
  };
};

export const getTopbarSelectLockedStyles = (width: number) => {
  const baseStyles = getTopbarSelectStyles(width);
  return {
    ...baseStyles,
    control: (provided, state) => ({
      ...baseStyles.control(provided, state),
      borderColor: '#60491d',
      backgroundColor: '#ffd485',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#60491d',
    }),
    dropdownIndicator: (provided) => ({
      ...baseStyles.dropdownIndicator(provided),
      color: '#60491d',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: '#60491d',
    }),
  };
};
