import React from 'react';

import styles from './totals.module.css';
import { formatPrice } from '../../../utils';

interface iProps {
  title: string;
  currency: string;
  price: number;
}

const TotalsRow = ({ title, currency, price }: iProps): JSX.Element | null => {
  return (
    <div className={styles.totalsRow}>
      <div>{title}</div>
      <div>{formatPrice(price, currency)}</div>
    </div>
  );
};

export default TotalsRow;
