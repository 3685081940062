import React from 'react';
import { Filters, updateFilters } from '@gi/filters';

import GardenObject from '@gi/garden-object';

import FilterRow from '../../../filters/filter-row';
import TypeFilterDropdown from './garden-object-type-filter-dropdown';

interface iProps {
  filters: Filters<GardenObject, any>;
  onFilterChange: (filters: Filters<GardenObject, any>) => void;
}

const GardenObjectTypeFilterView = ({ filters, onFilterChange }: iProps): JSX.Element => {
  const onTypeChange = (type: string) => {
    onFilterChange(updateFilters(filters, { type: { type } }));
  };

  return (
    <FilterRow padHelp>
      <label className='filter-row-label filter-row-label-small'>
        <span className='filter-row-label-text'>Type</span>
        <span className='filter-row-label-content'>
          <TypeFilterDropdown type={filters.filters.type.inputs.type} onTypeChange={onTypeChange} />
        </span>
      </label>
    </FilterRow>
  );
};

export default GardenObjectTypeFilterView;
