/**
 * Enum for the different Garden Planner layers
 */
const LayerTypes = {
  PLANTS: 'PLANTS',
  STRUCTURES: 'STRUCTURES',
  IRRIGATION: 'IRRIGATION',
  TEXT: 'TEXT',
  LAYOUT: 'LAYOUT',
  PLANT_LABELS: 'PLANT_LABELS',
  BACKGROUND: 'BACKGROUND',
} as const;

export type LayerType = (typeof LayerTypes)[keyof typeof LayerTypes];

export default LayerTypes;
