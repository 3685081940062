import React from 'react';
import Plant from '@gi/plant';

import { ModalPaneContainer, ModalPane, ModalPaneContent, ModalTabContent, ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';
import { Plan, PlanPlantNotes, PlantNotesUtils } from '@gi/plan';
import { DEFAULT_VARIETY } from '@gi/constants';

interface iEmptyPlantNotesProps {
  plant: Plant;
}

const EmptyPlantNotes = ({ plant }: iEmptyPlantNotesProps): JSX.Element => {
  return (
    <div className='empty-plant-notes'>
      <p>You currently have no plant notes set for {plant.name}. They can be added on the plant list or when editing a plant on the plan.</p>
    </div>
  );
};

interface iPlantNotesListEntryProps {
  variety: string;
  text: string;
}

const PlantNotesListEntry = ({ variety, text }: iPlantNotesListEntryProps): JSX.Element => {
  let title = variety;

  if (title === DEFAULT_VARIETY) {
    title = '- (Default Variety)';
  }

  return (
    <div className='plant-notes-list-entry'>
      <h3 className='variety'>{title}</h3>
      <pre>{text}</pre>
    </div>
  );
};

interface iPlantNotesListProps {
  plant: Plant;
  planPlantNotes: PlanPlantNotes;
}

const PlantNotesList = ({ plant, planPlantNotes }: iPlantNotesListProps): JSX.Element => {
  const varieties = PlantNotesUtils.getPlantNoteVarieties(planPlantNotes, plant.code);

  return (
    <div className='plan-plant-notes'>
      {[...varieties]
        .sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        })
        .map((v, i) => (
          <PlantNotesListEntry key={i} variety={v} text={PlantNotesUtils.getPlantNoteText(planPlantNotes, plant.code, v)} />
        ))}
    </div>
  );
};

interface iPlantNotesTabProps {
  plan?: Plan | null;
  plant: Plant;
}

const PlantNotesTab = ({ plan = null, plant }: iPlantNotesTabProps): JSX.Element | null => {
  if (plan === null) {
    return null;
  }

  let content: JSX.Element | null = null;
  if (!PlantNotesUtils.plantNotesHasPlant(plan.plantNotes, plant.code)) {
    content = <EmptyPlantNotes plant={plant} />;
  } else {
    content = <PlantNotesList plant={plant} planPlantNotes={plan.plantNotes} />;
  }

  return (
    <ModalTabContent tabID='plant-notes'>
      <ModalPaneContainer>
        <ModalPane className='plant-information-pane'>
          <ModalPaneContent>
            <ModalPaneSection className='plant-information-section'>
              <ModalPaneSectionContent>
                <div className='plant-notes-introduction'>Plant notes can be added and edited in the plant list or while editing a plant on your plan</div>
                {content}
              </ModalPaneSectionContent>
            </ModalPaneSection>
          </ModalPaneContent>
        </ModalPane>
      </ModalPaneContainer>
    </ModalTabContent>
  );
};

export default PlantNotesTab;
