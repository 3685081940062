import React from 'react';
import { useSelector } from 'react-redux';
import { OpenPlanModalSelectors } from './open-plan-modal-slice';
import OpenPlanModal from './open-plan-modal';

const OpenPlanModalRenderer = () => {
  const { open } = useSelector(OpenPlanModalSelectors.getOpenPlanModal);

  if (!open) {
    return null;
  }

  return <OpenPlanModal />;
};

export default OpenPlanModalRenderer;
