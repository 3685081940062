import React, { useContext } from 'react';

import { updateFilters } from '@gi/filters';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import PlantListOptionFilter from './plant-list-option-filter';
import { DISPLAY_TYPE } from '../../constants';

const HideInGroundAllFilterInput = () => {
  const { areaGroupFilters, setAreaGroupFilters, displayType } = useContext(GlobalPlantListContext);

  function set(enabled: boolean) {
    setAreaGroupFilters(updateFilters(areaGroupFilters, { inGround: { enabled } }));
  }

  return (
    <PlantListOptionFilter
      label='Hide plants without in-ground dates set'
      id='plant-list-option:hide-in-ground-dates'
      value={areaGroupFilters.filters.inGround.inputs.enabled}
      setValue={(checked) => {
        set(checked);
      }}
      disabled={displayType !== DISPLAY_TYPE.Area}
    />
  );
};

export default HideInGroundAllFilterInput;
