export const TAB_CATEGORIES = {
  PLANTS: 'PLANTS',
  GARDEN_OBJECTS: 'GARDEN_OBJECTS',
  SHAPES: 'SHAPES',
  TEXT: 'TEXT',
} as const;

export const TAB_ICONS = {
  [TAB_CATEGORIES.PLANTS]: 'icon-plants-black',
  [TAB_CATEGORIES.GARDEN_OBJECTS]: 'icon-garden-objects-black',
  [TAB_CATEGORIES.SHAPES]: 'icon-shapes-black',
  [TAB_CATEGORIES.TEXT]: 'icon-text-black',
} as const;

// RegEx to find and remove all unnecessary punctuation from search terms
export const PUNCTUATION_REGEX = /["'“”]+/g;
