import { UserPlantVariety, UserPlantVarietySet, UserPlantVarietySetUtils } from '@gi/user';
import Plant, { PlantUtils } from '@gi/plant';
import Collection from '@gi/collection';
import GardenObject from '@gi/garden-object';
import plantFamilies from '@gi/plant-families';
import { DEFAULT_VARIETY, GardenObjectType, ShapeType } from '@gi/constants';
import Plan, { PlanGardenObject, PlanPlant, PlanShape, PlanText } from '@gi/plan';
import { Geometry } from '@gi/math';

import { SimulatedPlant } from '../simulation/simulated-plant';
import { SimulatedPlan } from '../simulation/simulated-plan';
import { SimulatedText } from '../simulation/simulated-text';
import { SimulatedShape } from '../simulation/simulated-shape';
import { SimulatedGardenObject } from '../simulation/simulated-garden-object';
import { SimulatedHistoricalPlan } from '../simulation/simulated-historical-plan';
import { SimulatedPlanBackgroundImage } from '../simulation/simulated-plan-background-image';

/**
 * Class containing data required to create simulated plans from plans such as the current
 * set of Plants, GardenObjects and UserPlantVarieties
 */
export class SimulationFactory {
  #plants: Collection<Plant>;
  get plants() {
    return this.#plants;
  }

  #gardenObjects: Collection<GardenObject>;
  get gardenObjects() {
    return this.#gardenObjects;
  }

  #userPlantVarieties: UserPlantVarietySet;
  get userPlantVarieties() {
    return this.#userPlantVarieties;
  }

  constructor(plants: Collection<Plant>, gardenObjects: Collection<GardenObject>, userPlantVarieties: UserPlantVarietySet) {
    this.#plants = plants;
    this.#gardenObjects = gardenObjects;
    this.#userPlantVarieties = userPlantVarieties;
  }

  /**
   * Updates the user plant variety set used by the simulation factory.
   * @param userPlantVarieties The new plant variety set
   * @returns A diff of the variety set
   */
  setUserPlantVarieties(userPlantVarieties: UserPlantVarietySet) {
    const diff = UserPlantVarietySetUtils.getDiff(userPlantVarieties, this.userPlantVarieties);
    this.#userPlantVarieties = userPlantVarieties;
    return diff;
  }

  getUserPlantVariety(plantCode: string, varietyName: string): UserPlantVariety | null {
    return UserPlantVarietySetUtils.getByPlantCodeAndName(this.userPlantVarieties, plantCode, varietyName);
  }

  /**
   * Creates a SimulatedPlan from a Plan using the current plants, garden objects and user plant varieties
   */
  createSimulatedPlan(plan: Plan, availableHistoricalPlans: Record<number, Plan> = {}): SimulatedPlan {
    const simulatedPlan = new SimulatedPlan(plan.id, plan.width, plan.height);
    simulatedPlan.setShowGrid(plan.plannerSettings.showGrid);
    simulatedPlan.setShowRulers(plan.plannerSettings.showRulers);
    simulatedPlan.setMetricDistanceUnits(plan.plannerSettings.metric);
    simulatedPlan.setShowBackgroundImages(plan.plannerSettings.showBackgroundImages);
    simulatedPlan.setBackgroundImageOpacity(plan.plannerSettings.backgroundImageOpacity);
    simulatedPlan.setMaintainBackgroundImageAspectRatio(plan.plannerSettings.maintainBackgroundImageAspectRatio);

    if (plan.backgroundImage) {
      simulatedPlan.setBackgroundImage(
        new SimulatedPlanBackgroundImage(
          plan.backgroundImage.src,
          plan.backgroundImage.position,
          plan.backgroundImage.dimensions,
          plan.backgroundImage.rotation
        )
      );
    }

    for (let i = 0; i < plan.plantIds.length; i++) {
      try {
        simulatedPlan.addPlant(this.createSimulatedPlant(plan.plants[plan.plantIds[i]]));
      } catch (e) {
        console.warn(e);
      }
    }

    for (let i = 0; i < plan.gardenObjectIds.length; i++) {
      try {
        simulatedPlan.addGardenObject(this.createSimulatedGardenObject(plan.gardenObjects[plan.gardenObjectIds[i]]));
      } catch (e) {
        console.warn(e);
      }
    }

    for (let i = 0; i < plan.shapeIds.length; i++) {
      try {
        simulatedPlan.addShape(this.createSimulatedShape(plan.shapes[plan.shapeIds[i]]));
      } catch (e) {
        console.warn(e);
      }
    }

    for (let i = 0; i < plan.textIds.length; i++) {
      try {
        simulatedPlan.addText(this.createSimulatedText(plan.text[plan.textIds[i]]));
      } catch (e) {
        console.warn(e);
      }
    }

    for (let i = 0; i < plan.history.length; i++) {
      const id = plan.history[i];
      if (id) {
        if (availableHistoricalPlans[id]) {
          simulatedPlan.addHistoricalPlan(this.createSimulatedPlanHistory(availableHistoricalPlans[id], i + 1));
        } else {
          console.warn(`Couldn't add historical plan: No historical plan available with ID ${id}`);
        }
      }
    }

    simulatedPlan.updateCollisions();

    return simulatedPlan;
  }

  createSimulatedPlant(planPlant: PlanPlant): SimulatedPlant {
    const plant = this.plants.get(planPlant.plantCode);

    if (plant === null) {
      throw new Error(`Plant with code ${planPlant.plantCode}`);
    }

    const plantFamily = plantFamilies.get(plant.familyID);

    if (plantFamily === null) {
      throw new Error(`Plant with code ${planPlant.plantCode}, plant family not found: ${plant.familyID}`);
    }

    const userPlantVariety = this.getUserPlantVariety(plant.code, planPlant.variety);

    return new SimulatedPlant(
      planPlant.id,
      planPlant.isSquareFoot,
      { ...planPlant.rowStart },
      { ...planPlant.rowEnd },
      planPlant.height,
      plant,
      plantFamily,
      planPlant.variety,
      userPlantVariety,
      planPlant.labelText,
      { ...planPlant.labelOffset },
      planPlant.showLabel,
      planPlant.inGroundStart,
      planPlant.inGroundEnd,
      planPlant.inGroundAll,
      planPlant.locked,
      planPlant.zIndex
    );
  }

  createSimulatedGardenObject(planGardenObject: PlanGardenObject) {
    const gardenObject = this.gardenObjects.get(planGardenObject.code);

    if (gardenObject === null) {
      throw new Error(`Garden Object with code ${planGardenObject.code}`);
    }

    return new SimulatedGardenObject(
      planGardenObject.id,
      { ...planGardenObject.start },
      planGardenObject.mid !== null ? { ...planGardenObject.mid } : null,
      { ...planGardenObject.end },
      planGardenObject.rotation,
      gardenObject,
      planGardenObject.locked,
      planGardenObject.zIndex
    );
  }

  // eslint-disable-next-line class-methods-use-this
  createSimulatedShape(planShape: PlanShape): SimulatedShape {
    return new SimulatedShape(
      planShape.id,
      planShape.type,
      { ...planShape.point1 },
      planShape.point2 !== null ? { ...planShape.point2 } : null,
      { ...planShape.point3 },
      planShape.rotation,
      planShape.fill,
      planShape.stroke,
      planShape.strokeWidth,
      planShape.texture,
      planShape.closed,
      planShape.locked,
      planShape.zIndex
    );
  }

  // eslint-disable-next-line class-methods-use-this
  createSimulatedText(planText: PlanText): SimulatedText {
    return new SimulatedText(
      planText.id,
      planText.text,
      planText.fontSize,
      planText.fill,
      { ...planText.start },
      { ...planText.end },
      planText.rotation,
      planText.locked,
      planText.zIndex
    );
  }

  createSimulatedPlanHistory(plan: Plan, age: number): SimulatedHistoricalPlan {
    const simulatedPlanHistory = new SimulatedHistoricalPlan(plan.id, age);

    for (let i = 0; i < plan.plantIds.length; i++) {
      try {
        simulatedPlanHistory.addPlant(this.createSimulatedPlant(plan.plants[plan.plantIds[i]]));
      } catch (e) {
        console.warn(e);
      }
    }

    return simulatedPlanHistory;
  }

  createSimulatedPlantFromDraw(id: number, plant: Plant, isSquareFoot: boolean, rowStart: Vector2, rowEnd: Vector2, showLabel: boolean): SimulatedPlant {
    const plantFamily = plantFamilies.get(plant.familyID);

    if (plantFamily === null) {
      throw new Error(`Plant with code ${plant.code}, plant family not found: ${plant.familyID}`);
    }

    const userPlantVariety = UserPlantVarietySetUtils.getByPlantCodeAndName(this.userPlantVarieties, plant.code, DEFAULT_VARIETY);
    const spacings = PlantUtils.getSpacings(plant, userPlantVariety);

    return new SimulatedPlant(
      id,
      isSquareFoot,
      rowStart,
      rowEnd,
      0,
      plant,
      plantFamily,
      DEFAULT_VARIETY,
      userPlantVariety,
      plant.name,
      { x: 0, y: isSquareFoot ? 24 : Math.round(spacings.spacing / 3) + 10 },
      showLabel,
      0,
      11,
      true,
      false,
      0
    );
  }

  // eslint-disable-next-line class-methods-use-this
  createSimulatedGardenObjectFromDraw(id: number, gardenObject: GardenObject, position: Vector2): SimulatedGardenObject {
    let startPos = position;
    let endPos = position;
    if (gardenObject.shape.type === GardenObjectType.BLOCK) {
      startPos = {
        x: position.x - gardenObject.shape.initialWidth / 2,
        y: position.y - gardenObject.shape.initialHeight / 2,
      };
      endPos = {
        x: position.x + gardenObject.shape.initialWidth / 2,
        y: position.y + gardenObject.shape.initialHeight / 2,
      };
    }

    return new SimulatedGardenObject(id, startPos, null, endPos, 0, gardenObject, false, 0);
  }

  // eslint-disable-next-line class-methods-use-this
  createSimulatedShapeFromDraw(
    id: number,
    shapeType: ShapeType,
    position: Vector2,
    fill: number | null,
    stroke: number | null,
    strokeWidth: number,
    texture: string | null
  ) {
    let point1: Vector2 = position;
    let point2: Vector2 | null = null;
    let point3: Vector2 = position;

    if (shapeType === ShapeType.TRIANGLE) {
      const points = Geometry.equilateralTrianglePoints(position, 50);
      point1 = points.p1;
      point2 = points.p2;
      point3 = points.p3;
    }

    const simulatedShape = new SimulatedShape(id, shapeType, point1, point2, point3, 0, fill, stroke, strokeWidth, texture, false, false, 0);
    return simulatedShape;
  }

  // eslint-disable-next-line class-methods-use-this
  createSimulatedTextFromDraw(id: number, position: Vector2, fontSize: number, color: number) {
    return new SimulatedText(id, '', fontSize, color, position, position, 0, false, 0);
  }
}
