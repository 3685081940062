import { PlanUtils, PlanSetUtils, PlanSet } from '@gi/plan';

const planHasUnsavedChanges = (lastSavePlans: PlanSet, plans: PlanSet, planID: number): boolean => {
  const lastSavedPlan = PlanSetUtils.planSetGetPlan(lastSavePlans, planID);
  const plan = PlanSetUtils.planSetGetPlan(plans, planID);

  if (plan === null || lastSavedPlan === null) {
    return false;
  }

  return !PlanUtils.plansShallowEqual(plan, lastSavedPlan);
};

export { planHasUnsavedChanges };
