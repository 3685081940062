import { State, StateDef } from '@gi/state';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { CameraNodeState } from '../../nodes/camera/camera-node';
import { InspectableClassData } from '../../types';
import { hasEngine } from '../../utils/asserts';
import { createActiveCameraConnector } from '../../utils/camera-utils';
import { bindState } from '../../utils/state-utils';

export type PointerTrackerComponentState = StateDef<
  {
    screenPosition: Vector2 | null;
    worldPosition: Vector2 | null;
  },
  [],
  {
    camera: CameraNodeState;
  }
>;

// TODO: Revisit this, it's ugly. Especially the 'globalpointermove' event listener attaching.

/**
 * PointerTracker Component
 *  Tracks the screen and world coordinate of the latest pointer interaction.
 *  Ignores touch events.
 */
class PointerTrackerComponent extends NodeComponent {
  type = 'PointerTrackerComponent';

  readonly state: State<PointerTrackerComponentState>;

  onPointerMove?: (data: PointerTrackerComponentState['state']) => void;

  constructor() {
    super();

    this.state = new State({
      screenPosition: null,
      worldPosition: null,
    });
    bindState(this.state, this);

    this.state.addUpdater(
      (state) => {
        const camera = state.otherStates.camera?.owner;
        const { screenPosition } = state.values;

        if (screenPosition && camera) {
          this.state.values.worldPosition = camera.getWorldPos(this.#getLocalPos(screenPosition));
        } else {
          this.state.values.worldPosition = null;
        }
      },
      {
        properties: ['screenPosition'],
        otherStates: { camera: { properties: ['magnification', 'position', 'viewportSize'] } },
      },
      false
    );

    this.state.addWatcher(
      ({ values }) => {
        if (this.onPointerMove && values.worldPosition) {
          this.onPointerMove(values);
        }
      },
      { properties: ['screenPosition', 'worldPosition'] },
      false
    );

    createActiveCameraConnector(this as NodeComponent, this.state, 'camera');

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #getLocalPos(screenPos: Vector2) {
    if (this.owner && this.owner.engine) {
      return this.owner.engine.convertToLocalPos(screenPos);
    }
    return screenPos;
  }

  #onBind = () => {
    hasEngine(this);
    this.owner.engine.getContentRoot()?.ownGraphics.on('globalpointermove', this.#onPointerMove);
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    this.owner.engine.getContentRoot()?.ownGraphics.off('globalpointermove', this.#onPointerMove);
  };

  #onPointerMove = (event: PointerEvent) => {
    if (event.pointerType === 'touch') {
      this.state.values.screenPosition = null;
    } else {
      this.state.values.screenPosition = { x: event.clientX, y: event.clientY };
    }
  };

  inspectorData: InspectableClassData<this> = [];
}

export default PointerTrackerComponent;
