import Collection from '@gi/collection';
import { Filter } from '@gi/filters';
import GardenObject, { GardenObjectGroup } from '@gi/garden-object';

type SortFilterState = {
  enabled: boolean;
  groups: Collection<GardenObjectGroup>;
};

const sortFilter: Filter<GardenObject, SortFilterState> = {
  name: 'Lexographic Sort',
  inputs: { enabled: true, groups: new Collection() },
  createListFilter: ({ enabled, groups }) => {
    return (gardenObjectList) => {
      if (!enabled) {
        return gardenObjectList;
      }
      return gardenObjectList.sort((a, b) => {
        // Sort items by their group name first.
        const hasGroup = a.groupId !== undefined || b !== undefined;
        if (hasGroup) {
          const groupA = a.groupId ? groups.get(a.groupId) : null;
          const groupB = b.groupId ? groups.get(b.groupId) : null;
          const result = (groupA?.displayName ?? a.sortName).localeCompare(groupB?.displayName ?? b.sortName);
          // If the result isn't 0 (same), use that ordering, else fall back to name ordering
          if (result !== 0) {
            return result;
          }
        }
        return a.sortName.localeCompare(b.sortName);
      });
    };
  },
};

export default sortFilter;
