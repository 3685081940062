import { createSetBookmarkRequestKey, GuruAsyncActionCreators, GuruSelectors } from '@gi/app-guru-slice';
import { LoadingState } from '@gi/constants';
import LoadingButton from '@gi/loading-button';
import { RequestSelectors } from '@gi/react-requests';
import { RequestStatus, RequestsUtils } from '@gi/request';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './controls-banner.module.css';

interface iProps {
  contentUUID: string;
}

const BookmarkButton = ({ contentUUID }: iProps): JSX.Element | null => {
  const allContent = useSelector(GuruSelectors.getContent);
  const dispatch = useDispatch();
  const requests = useSelector(RequestSelectors.getRequests);
  const content = allContent[contentUUID];

  const onClick = useCallback((_contentId: number, _bookmark: boolean) => {
    dispatch(GuruAsyncActionCreators.setBookmark(_contentId, _bookmark));
  }, []);

  if (!content) {
    return null;
  }

  const { contentId } = content;

  if (content.status === LoadingState.LOADING || content.status === LoadingState.NONE || content.status === LoadingState.ERROR) {
    // TODO, handle
    return null;
  }

  if ('value' in content) {
    const request = RequestsUtils.getRequest(requests, createSetBookmarkRequestKey(contentId));
    const loading = request?.status === RequestStatus.IN_PROGRESS;

    const { bookmarked } = content.value;

    return (
      <LoadingButton
        type='button'
        className={styles.controlsButton}
        onClick={() => {
          onClick(contentId, bookmarked === null);
        }}
        loading={loading}
        buttonIcon={bookmarked ? 'icon-bookmark' : 'icon-bookmark-empty'}
        alwaysShowIcon
        iconContainerClassName={bookmarked ? styles.bookmarkBanner : styles.bookmarkBannerOutline}
        disabled={loading}
      >
        {bookmarked ? 'In Bookmarks' : 'Bookmark'}
      </LoadingButton>
    );
  }

  return null;
};

export default BookmarkButton;
