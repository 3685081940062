import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalActionCreators } from '@gi/garden-platform-modal-renderer';
import { GardenPlatformActionCreators } from '@gi/garden-platform-slice';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { Application, getAbsoluteApplicationLink, useNavigate } from '@gi/garden-platform-navigation';
import { OpenPlanModalActionCreators } from '@gi/open-plan-modal';

import './planner-overlay.scss';

const PlannerOverlay = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openPlanIDs = useSelector(CanvasSelectors.getOpenPlanIDs);
  const activePlanID = useSelector(CanvasSelectors.getActivePlanID);
  const activeModal = useSelector((state: any) => state.modal.activeModal);

  if (openPlanIDs.length !== 0 && activePlanID !== null) {
    return null;
  }

  if (activeModal !== null) {
    return null;
  }

  return (
    <div className='planner-overlay'>
      <div className='planner-start-window'>
        <div className='planner-start-title'>Garden Planner</div>
        <div className='planner-start-introduction'>Please open one of your plans or create a new plan to get started</div>
        <div className='planner-start-buttons'>
          <div className='planner-start-button'>
            <button
              type='button'
              className='button button-primary-light'
              onClick={() => {
                dispatch(ModalActionCreators.openNewPlanModal());
              }}
            >
              Create New Plan
            </button>
          </div>
          <div className='planner-start-button'>
            <button
              type='button'
              className='button button-primary-light'
              onClick={() => {
                dispatch(OpenPlanModalActionCreators.open());
              }}
            >
              Open Plan
            </button>
          </div>
        </div>
        <div className='planner-intro-button'>
          <button
            style={{ paddingTop: 7 }}
            type='button'
            className='button button-primary-alt button-small'
            onClick={() => {
              dispatch(GardenPlatformActionCreators.setShowIntroVideo(true));
            }}
          >
            <i className='icon-play-circled' /> Show Introduction Video
          </button>
        </div>
        <div className='planner-start-dashboard-container'>
          <button
            type='button'
            className='button button-inline'
            onClick={() => {
              navigate(getAbsoluteApplicationLink(Application.Overview));
            }}
          >
            <i className='icon-left-open-1' /> Back to Overview
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlannerOverlay;
