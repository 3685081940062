import { FormLayoutOptions, FormLayoutOptionsPreset } from './form-layout';
import { unresponsivePreset } from './types';

/** Always displays in a column, regardless of display mode */
const Column = unresponsivePreset<FormLayoutOptions>({
  layout: 'column',
});

/** Always displays in a row, regardless of display mode */
const Row = unresponsivePreset<FormLayoutOptions>({
  layout: 'row',
});

/** Vertical stack of radio options */
const RadioStack = unresponsivePreset<FormLayoutOptions>({
  layout: 'column',
  gap: 0,
});

/** Vertical stack of checkbox options */
const CheckboxStack = unresponsivePreset<FormLayoutOptions>({
  layout: 'column',
  gap: 0,
});

/** For displaying radio options in a row */
const RadioInline: FormLayoutOptionsPreset = {
  desktop: {
    layout: 'row',
    gap: 18,
  },
  mobile: {
    layout: 'column',
    gap: 0,
  },
};

/** Places a button on the right, or full-width on mobile */
const ButtonRight: FormLayoutOptionsPreset = {
  desktop: {
    layout: 'row',
    xAlign: 'right',
    yAlign: 'stretch',
  },
  mobile: {
    layout: 'column',
    xAlign: 'stretch',
  },
};

/** Always displays in a column, regardless of display mode */
const FormSectionContent = unresponsivePreset<FormLayoutOptions>({
  layout: 'column',
  size: 'full',
  gap: 6,
});

export const FORM_LAYOUT_PRESETS = {
  Column,
  Row,
  RadioStack,
  CheckboxStack,
  RadioInline,
  ButtonRight,
  FormSectionContent,
};
