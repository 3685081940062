import { Geometry } from '@gi/math';
import { PlantSpacings } from '@gi/plant';

// The amount of points to use for the outline of an ellipse
// TODO: Check this number. I believe the existing ellipse use 20 segs per besier curve, 4 curves per ellipse
const ELLIPSE_STEPS: number = 80;
const LINE_STEPS: number = 20;

/**
 * Creates a vector path to outline a plant
 * @param width The width of the plant
 * @param height The height of the plant
 * @param spacings The plant spacings between plants
 * @returns A list of points to outline the given plant
 */
export const createPlantOutline = (width: number, height: number, spacings: Omit<PlantSpacings, 'sfgCount'>): Vector2[] => {
  const { spacing, inRowSpacing, rowSpacing } = spacings;

  let start: Vector2;
  let end: Vector2;

  if (width === 0 && height === 0) {
    // Single plant
    start = { x: -spacing / 2, y: -spacing / 2 };
    end = { x: spacing / 2, y: spacing / 2 };
  } else if (height === 0) {
    // Row
    start = { x: -inRowSpacing / 2, y: -rowSpacing / 2 };
    end = { x: width + inRowSpacing / 2, y: rowSpacing / 2 };
  } else {
    // Block
    start = { x: -spacing / 2, y: -spacing / 2 };
    end = { x: width + spacing / 2, y: height + spacing / 2 };
  }

  return [
    { x: start.x, y: start.y },
    { x: end.x, y: start.y },
    { x: end.x, y: end.y },
    { x: start.x, y: end.y },
  ];
};

/**
 * Creates a vector path to outline a rectangle
 * @param width The width of the rectangle
 * @param height The height of the rectangle
 * @returns A list of points to outline the rectangle
 */
export const createRectOutline = (width: number, height: number): Vector2[] => {
  return [
    { x: -width / 2, y: -height / 2 },
    { x: width / 2, y: -height / 2 },
    { x: width / 2, y: height / 2 },
    { x: -width / 2, y: height / 2 },
  ];
};

/**
 * Creates a vector path to outline an ellpise
 * @param width The wdith of the ellipse
 * @param height The height of the ellipse
 * @returns A list of points to outline the ellipse
 */
export const createEllipseOutline = (width: number, height: number): Vector2[] => {
  const points: Vector2[] = [];
  for (let i = 0; i < ELLIPSE_STEPS; i++) {
    const angle = Math.PI * 2 * (i / ELLIPSE_STEPS);
    points.push({
      x: Math.cos(angle) * (width / 2),
      y: Math.sin(angle) * (height / 2),
    });
  }
  return points;
};

/**
 * Creates a vector path to outline a triangle
 * @param point1 Point 1 of the triangle
 * @param point2 Point 2 of the triangle
 * @param point3 Point 3 of the triangle
 * @returns A list of points to outline the triangle
 */
export const createTriangleOutline = (point1: Vector2, point2: Vector2, point3: Vector2): Vector2[] => {
  const { center } = Geometry.getBoundingBox(point1, point2, point3);
  return [Geometry.minusPoint(point1, center), Geometry.minusPoint(point2, center), Geometry.minusPoint(point3, center)];
};

/**
 * Creates a vector path to outline a line
 * @param start The start point of the line
 * @param end The end point of the line
 * @param controlPoint The optional control point of the line
 * @param steps How many steps the line is made up of
 * @returns A list of points to outline the line
 */
export const createLineOutline = (start: Vector2, end: Vector2, controlPoint: Vector2 | null = null, steps: number = LINE_STEPS): Vector2[] => {
  const center = controlPoint !== null ? Geometry.getBoundingBox(start, end, controlPoint).center : Geometry.midpoint(start, end);

  if (controlPoint === null) {
    return [Geometry.minusPoint(start, center), Geometry.minusPoint(end, center)];
  }

  const points: Vector2[] = [];
  const interp = Geometry.interpolateQuadraticBezier(
    Geometry.minusPoint(start, center),
    Geometry.minusPoint(controlPoint, center),
    Geometry.minusPoint(end, center)
  );

  for (let i = 0; i < steps; i++) {
    const t = i / (steps - 1);
    const point = interp(t);
    points.push({ x: point[0], y: point[1] });
  }

  return points;
};

/**
 * Creates a vector path to outline a path
 * @param start The start point of the line
 * @param end The end point of the line
 * @param controlPoint The optional control point of the line
 * @param segmentSize How large each segment of the path is
 * @returns A list of points to outline the line
 */
export const createPathOutline = (start: Vector2, end: Vector2, controlPoint: Vector2 | null, segmentSize: number): Vector2[] => {
  const center = controlPoint !== null ? Geometry.getBoundingBox(start, end, controlPoint).center : Geometry.midpoint(start, end);

  if (controlPoint === null) {
    return [Geometry.minusPoint(start, center), Geometry.minusPoint(end, center)];
  }

  return Geometry.bezierSegments(
    Geometry.minusPoint(start, center),
    Geometry.minusPoint(controlPoint, center),
    Geometry.minusPoint(end, center),
    segmentSize,
    10
  );
};

/**
 * Creates a vector path to form a rectangular outline around the given points
 * @param points The poaint to outline
 * @returns A list of points to outline the given points
 */
export const createRectContainerOutline = (...points: Vector2[]): Vector2[] => {
  const { min, max } = Geometry.getBoundingBox(...points);
  return [min, { x: max.x, y: min.y }, max, { x: min.x, y: max.y }];
};
