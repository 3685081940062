import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { temperatureConversion } from '@gi/conversion';
import { getDayName } from '@gi/utils';

import WindIcon from './wind-icon';
import { getColourFromTemperature } from './utils';
import Forecast from '../forecast';
import { getForcastIconURL } from '../forecast-icon';

interface iProps {
  forecast: Forecast | null;
  metric: boolean;
}

const ForecastDay = ({ forecast, metric }: iProps): JSX.Element => {
  const [temperatureColor, setTemperatureColor] = useState<string>('#ccc');

  const maxTemp = forecast === null ? 0 : forecast.maxTemp;

  useEffect(() => {
    if (forecast !== null) {
      getColourFromTemperature(maxTemp).then((rgba) => {
        setTemperatureColor(`rgb(${rgba[0]}, ${rgba[1]}, ${rgba[2]})`);
      });
    }
  }, [maxTemp, forecast]);

  let dayName = 'Loading';
  let date = '';
  let icon: React.ReactNode = (
    <div className='forecast-icon'>
      <i className='icon-spinner animate-pulse' />
    </div>
  );
  let windSpeed: number | null = null;
  let windBearing: number = 0;

  if (forecast !== null) {
    const mo = moment(forecast.datetime);
    dayName = getDayName(mo);
    date = mo.format('Do MMM');
    icon = (
      <div className='forecast-icon'>
        <img src={getForcastIconURL(forecast.icon)} alt={forecast.summary} title={forecast.summary} />
      </div>
    );
    windSpeed = forecast.windSpeed;
    windBearing = forecast.windBearing;
  }

  const tempLow = forecast === null ? '' : temperatureConversion.formatTemperature(forecast.minTemp, metric);
  const tempHigh = forecast === null ? '' : temperatureConversion.formatTemperature(forecast.maxTemp, metric, true);

  const tempComponent = (
    <div className='temp-container'>
      <span className='temp-group'>
        <span className='temp-key-outer'>
          <span className='temp-key'>High</span>
        </span>
        <span className='temp-val'>{tempHigh}</span>
      </span>
      <span className='temp-group'>
        <span className='temp-key-outer'>
          <span className='temp-key'>Low</span>
        </span>
        <span className='temp-val'>{tempLow}</span>
      </span>
    </div>
  );

  return (
    <div className='forecast-day'>
      <div className='forecast-details'>
        <div className='forecast-title'>{dayName}</div>
        <div className='forecast-date'>{date}</div>
      </div>
      <div className='icon-band'>
        {icon}
        <WindIcon windspeed={windSpeed} bearing={windBearing} color='#333333' metric={metric} />
      </div>
      <div className={`temperature-band ${maxTemp < -7 ? 'dark' : ''}`} style={{ backgroundColor: temperatureColor }}>
        {tempComponent}
      </div>
    </div>
  );
};

export default ForecastDay;
