import { Filter } from '@gi/filters';
import Plant from '@gi/plant';

type TropicalFilterState = {
  enabled: boolean;
};

const tropicalFilter: Filter<Plant, TropicalFilterState> = {
  name: 'Tropical',
  inputs: { enabled: true },
  createListFilter: ({ enabled }) => {
    return (plantList) => {
      if (enabled) {
        return plantList;
      }

      return plantList.filter((plant) => !plant.hasTag('tropical'));
    };
  },
};

export default tropicalFilter;
