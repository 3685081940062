import { AnyAction, ListenerMiddlewareInstance, ThunkDispatch } from '@reduxjs/toolkit';

import { CropRotationModes, GardenItemType, LayerDisplayModes, PlannerTabTypes, ShapeType } from '@gi/constants';
import { DisplayModeState } from '@gi/display-mode-provider';
import { LocalSettings } from '@gi/local-settings';
import { CanvasReducerState } from '@gi/react-garden-canvas';
import { SettingsTabType } from '@gi/react-planner-settings';
import { SessionReducerState } from '@gi/react-session';
import { Application } from '@gi/garden-platform-navigation';
import { PartialPlantFilters } from '@gi/drawing-tools/source/plants/filters/plant-filter-utils';
import type { VideoAnalytics } from '@gi/tutorial';

export enum GardenPlatformEvent {
  DrawPlant = 'Draw Plant',
  DrawGardenObject = 'Draw Garden Object',
  DrawShape = 'Draw Shape',
  DrawText = 'Draw Text',

  OpenApplication = 'Open Application',

  OpenPlan = 'Open Plan',
  OpenPlannerTab = 'Open Planner Tab',
  OpenPlannerSettings = 'Open Planner Settings',

  ChangeSFGMode = 'Change SFG Mode',
  ChangeCropRotationMode = 'Change Crop Rotation Mode',
  ChangeLayerMode = 'Change Layer Mode',
  ChangeMonthView = 'Change Month View',

  UseSelectionTool = 'Use Selection Tool',

  ChangeItemLocked = 'Change Item Locked',
  ChangeItemZIndex = 'Change Item Z-Index',

  ViewPlantInformationModal = 'View Plant Information Modal',
  ViewEditGardenItemModal = 'View Edit Garden Item Modal',
  ViewHelpModal = 'View Help Modal',
  ViewCompanions = 'View Companions',
  ViewLink = 'View Link',

  ToggleFavouritePlant = 'Toggle Favourite Plant',
  ChosePlantToDraw = 'Chose Plant To Draw',

  SetPlantInGroundDate = 'Set Plant In-Ground Dates',
  SetPlantVariety = 'Set Plant Variety',
  AddPlantVariety = 'Add Plant Variety',
  ModifyPlantVariety = 'Modify Plant Variety',

  ExportPlan = 'Export Plan',
  ExportPlanImage = 'Export Plan Image',
  ExportPlantsList = 'Export Plants List',
  ExportPartsList = 'Export Parts List',

  SearchPlantsNoResults = 'Search Plants No Results',
  SearchGardenObjectsNoResults = 'SearchGardenObjectsNoResults',

  CloseMobileIntroductionVideo = 'Close Mobile Introduction Video',
  OpenIntercomWindow = 'Open Intercom Window',
  EndTutorial = 'End Tutorial',
  WatchTutorialVideo = 'Watch Tutorial Video',

  Logout = 'Logout',
}

type NoParameters = Record<never, never>;

export type GardenPlatformEventParameters = {
  [GardenPlatformEvent.DrawPlant]: { plantCode: string; sfg: boolean; isDragToDraw: boolean };
  [GardenPlatformEvent.DrawGardenObject]: { objectCode: string; isDragToDraw: boolean; isSeasonExtender: boolean };
  [GardenPlatformEvent.DrawShape]: { shapeType: ShapeType; filled: boolean; isDragToDraw: boolean };
  [GardenPlatformEvent.DrawText]: { isDragToDraw: boolean };

  [GardenPlatformEvent.OpenApplication]: { application: Application };

  [GardenPlatformEvent.OpenPlan]: { planId: number };
  [GardenPlatformEvent.OpenPlannerTab]: { tab: PlannerTabTypes; planId: number };
  [GardenPlatformEvent.OpenPlannerSettings]: { tab?: SettingsTabType };

  [GardenPlatformEvent.ChangeSFGMode]: { enabled: boolean };
  [GardenPlatformEvent.ChangeCropRotationMode]: { mode: CropRotationModes };
  [GardenPlatformEvent.ChangeLayerMode]: { mode: LayerDisplayModes };
  [GardenPlatformEvent.ChangeMonthView]: { month: number | null };

  [GardenPlatformEvent.UseSelectionTool]: NoParameters;
  [GardenPlatformEvent.ChangeItemLocked]: { locked: boolean };
  [GardenPlatformEvent.ChangeItemZIndex]: { zIndex: number; operation: 'send to back' | 'bring to front' | 'reset' };

  [GardenPlatformEvent.ViewPlantInformationModal]: { tab: string; plantCode: string; justOpened: boolean };
  [GardenPlatformEvent.ViewEditGardenItemModal]: { itemType: GardenItemType; planId: number; itemId: number };
  [GardenPlatformEvent.ViewHelpModal]: { modal: string; opener: string };
  [GardenPlatformEvent.ViewCompanions]: { plantCodes: string[] };
  [GardenPlatformEvent.ViewLink]: { name: string; opener: string };

  [GardenPlatformEvent.ToggleFavouritePlant]: { plantCode: string; isFavourite: boolean };
  [GardenPlatformEvent.ChosePlantToDraw]: { plantCode: string; filters: PartialPlantFilters };

  [GardenPlatformEvent.SetPlantInGroundDate]: {
    plantCode: string;
    plantId: number;
    planId: number;
    inGroundStart: number;
    inGroundEnd: number;
    inGroundAll: boolean;
  };
  [GardenPlatformEvent.SetPlantVariety]: { plantCode: string; plantId: number; planId: number; varietyName: string };
  [GardenPlatformEvent.AddPlantVariety]: { plantCode: string; plantId: number; planId: number; varietyName: string };
  [GardenPlatformEvent.ModifyPlantVariety]: { plantCode: string; plantId: number; planId: number; varietyName: string };

  [GardenPlatformEvent.ExportPlan]: { planId: number };
  [GardenPlatformEvent.ExportPlanImage]: { planId: number };
  [GardenPlatformEvent.ExportPlantsList]: { planId: number };
  [GardenPlatformEvent.ExportPartsList]: { planId: number };

  [GardenPlatformEvent.SearchPlantsNoResults]: { searchTerm: string };
  [GardenPlatformEvent.SearchGardenObjectsNoResults]: { searchTerm: string };

  [GardenPlatformEvent.CloseMobileIntroductionVideo]: { secondsOpen: number; pageIndex: number };
  [GardenPlatformEvent.OpenIntercomWindow]: { opener: string };
  [GardenPlatformEvent.EndTutorial]: {
    tutorialId: string;
    tutorialName: string;
    tutorialSteps: number;
    completed: boolean;
    endedAtStep: number;
    maxStep: number;
  };
  [GardenPlatformEvent.WatchTutorialVideo]: VideoAnalytics;

  [GardenPlatformEvent.Logout]: NoParameters;
};

export type RootState = {
  // Should have something here
  displayMode: DisplayModeState;
  session: SessionReducerState;
  localSettings: LocalSettings;
  canvas: CanvasReducerState;
};
export type MiddlewareEnhanceFunction = (middleware: ListenerMiddlewareInstance<RootState, ThunkDispatch<RootState, unknown, AnyAction>, unknown>) => void;
