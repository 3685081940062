/**
 * @typedef {number} AppType
 */
/**
 * Enum for the different apps on the garden platform
 */
enum AppTypes {
  DASHBOARD = 'DASHBOARD',
  PLANNER = 'PLANNER',
  JOURNAL = 'JOURNAL',
  GURU = 'GURU',
  ACCOUNT = 'ACCOUNT',
  HELP = 'HELP',
}

export default AppTypes;
