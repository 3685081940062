import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SessionSelectors } from '@gi/react-session';
import { AppContext } from '@gi/app-provider';

import { FeaturesContext } from './features-context';

declare global {
  interface WGPGlobal {
    features: string;
  }
}

const USER_FEATURE_IDS: Record<string, string | string[]> = {
  // user tag -> FeatureFlagId
  VIDEO_RECORDER: 'HIDE_NEW_TAGS',
  TUTORIALS_EDIT: 'TUTORIAL_EDITOR',
};

const MASTER_ACCOUNT_FEATURES = ['TRANSFER_PLAN'];

const USER_TAGS = Object.keys(USER_FEATURE_IDS);

interface iProps {
  children: React.ReactNode;
}

export const FeaturesProvider = ({ children }: iProps): JSX.Element => {
  const user = useSelector(SessionSelectors.getUser);

  const { runtimeConfig } = useContext(AppContext);

  /** Feature flags taken from the user object */
  const userFeatures = useMemo(() => {
    if (user === null) {
      return [];
    }

    const masterFeatures = user.isMasterAccount ? MASTER_ACCOUNT_FEATURES : [];

    return [...masterFeatures, ...new Set(USER_TAGS.filter((userTag) => user.tags.includes(userTag)).flatMap((userTag) => USER_FEATURE_IDS[userTag]))];
  }, [user?.tags]);

  /** Feature flags taken from the client config */
  const clientFeatures = useMemo(() => {
    const _runtimeFeatures = runtimeConfig.shoppingEnabled ? ['SHOPPING'] : [];

    if (!runtimeConfig.featureFlags) {
      return _runtimeFeatures;
    }

    return [..._runtimeFeatures, ...new Set(runtimeConfig.featureFlags)];
  }, [runtimeConfig.featureFlags]);

  // In future this space will also be used for loading A/B testing
  const featureIds = useMemo(() => [...new Set([...userFeatures, ...clientFeatures])], [userFeatures, clientFeatures]);

  const featureEnabled = useCallback(
    (featureId: string) => {
      return featureIds.includes(featureId);
    },
    [featureIds]
  );

  useEffect(() => {
    window.WGP = window.WGP ?? {};
    window.WGP = {
      ...window.WGP,
      features: featureIds.join(', '),
    };
  }, [featureIds]);

  const value = useMemo(
    () => ({
      featureIds,
      featureEnabled,
    }),
    [featureIds, featureEnabled]
  );

  return <FeaturesContext.Provider value={value}>{children}</FeaturesContext.Provider>;
};
