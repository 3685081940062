import React, { ReactNode } from 'react';

import MenuDropdownOption from './menu-dropdown-option';

import styles from './menu-dropdown-option.module.css';

interface iProps {
  children?: ReactNode;
  value: boolean;
  onChange: (value: boolean) => void;
  hidden?: boolean;
}

const MenuDropdownCheckboxOption = ({ children, value, onChange, hidden = false }: iProps): JSX.Element | null => {
  if (hidden) {
    return null;
  }
  return (
    <MenuDropdownOption icon={value ? `${styles.checkbox} icon-ok` : styles.checkbox} onClick={() => onChange?.(!value)}>
      {children}
    </MenuDropdownOption>
  );
};

export default MenuDropdownCheckboxOption;
