import React from 'react';

import SuggestedArticleDisplay from './suggested-article-display';
import SuggestedArticle from '../suggested-article';

import './suggested-articles.scss';

interface iProps {
  articles: SuggestedArticle[];
}

const SuggestedArticles = ({ articles }: iProps): JSX.Element => {
  return (
    <ol className='suggested-articles'>
      {articles.map((article, index) => (
        <SuggestedArticleDisplay article={article} key={index} />
      ))}
    </ol>
  );
};

export default SuggestedArticles;
