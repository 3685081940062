class NetworkError extends Error {
  errorCode: number = 0;
  errorMessage: string = 'Unknown Network Error';
  clientMessage: string = '';
  originalError: null | Error = null;

  constructor(errorCode: number | null, errorMessage: string | null, clientMessage: string | null, originalError: Error | null) {
    super(`NetworkError: ${errorCode} - ${errorMessage} (${clientMessage})`);

    if (errorCode !== null) {
      this.errorCode = errorCode;
    }

    if (errorMessage !== null) {
      this.errorMessage = errorMessage;
    }

    if (clientMessage !== null) {
      this.clientMessage = clientMessage;
    }

    if (originalError !== null) {
      this.originalError = originalError;
    }
  }
}

export default NetworkError;
