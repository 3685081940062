import React, { useContext } from 'react';
import { useOutlet } from 'react-router-dom';

import GuruHeader from './header/guru-header';
import GardenGuruContext from './context/garden-guru-context';
import GardenGuruLoadGate from './context/garden-guru-load-gate';

import styles from './app-garden-guru.module.css';
import './app-garden-guru.css';

const AppGuruRootPane = () => {
  const outlet = useOutlet();
  const { scrollPane } = useContext(GardenGuruContext);

  return (
    <div ref={scrollPane} className={styles.rootPane}>
      <div className={styles.guruContent}>
        <GuruHeader />
        <GardenGuruLoadGate>{outlet}</GardenGuruLoadGate>
      </div>
    </div>
  );
};

export default AppGuruRootPane;
