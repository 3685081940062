import React from 'react';
import { HelpButton } from '@gi/base-components';

import './filter-row.scss';

interface iProps {
  children?: React.ReactNode;
  onHelp?: null | (() => void);
  multiple?: boolean;
  narrow?: boolean;
  padHelp?: boolean;
  className?: string;
}

const FilterRow = ({ children = null, onHelp = null, multiple = false, narrow = false, className = '', padHelp = false }: iProps): JSX.Element => {
  const finClassName = `filter-row ${multiple ? 'filter-row-multi' : ''} ${narrow ? 'filter-row-narrow' : ''} ${padHelp ? 'filter-row-pad-help' : ''} ${className}`;

  return (
    <div className={finClassName}>
      <div className='filter-row-content'>{children}</div>
      {onHelp !== null ? (
        <div className='filter-row-help'>
          <HelpButton onClick={onHelp} />
        </div>
      ) : null}
    </div>
  );
};

export default FilterRow;
