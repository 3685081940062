import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ObjectivesSelectors } from '../slice/objectives-slice';
import { ObjectiveGroup } from '../objective-types';

const useObjectiveGroups = () => {
  const groupsMap = useSelector(ObjectivesSelectors.getGroups);
  const groupsOrder = useSelector(ObjectivesSelectors.getGroupsOrder);

  const groups = useMemo(() => {
    return groupsOrder.map((groupId) => groupsMap[groupId]).filter((group) => group !== undefined);
  }, [groupsOrder, groupsMap]);

  const basicGroups = useMemo<ObjectiveGroup[]>(() => {
    return groups.filter((group) => !group.isAdvanced);
  }, [groups]);

  const advancedGroups = useMemo<ObjectiveGroup[]>(() => {
    return groups.filter((group) => group.isAdvanced);
  }, [groups]);

  return useMemo(
    () => ({
      basicGroups,
      advancedGroups,
    }),
    [basicGroups, advancedGroups]
  );
};

export default useObjectiveGroups;
