import { UnitType, UnitTypes } from '@gi/constants';
import { NumberedPlantingCalendar } from '@gi/planting-calendar';
import { PlantSpacings } from '@gi/plant';

const METRIC_SPACING_MIN = 1;
const METRIC_SPACING_MAX = 9999;

const IMPERIAL_SPACING_MIN = 1;
const IMPERIAL_SPACING_MAX = 3936;

const SFG_COUNT_MIN = 1;
const SFG_COUNT_MAX = 99;

export type UserPlantVariety = {
  plantCode: string;
  name: string;
  customSpacings: boolean;
  spacing: number;
  inRowSpacing: number;
  rowSpacing: number;
  squareFootPlantCount: number;
  customCalendar: boolean;
  recordID: number;
  catalogueItems: null;
  plantingCalendar: NumberedPlantingCalendar;
};

export const DEFAULT_USER_PLANT_VARIETY: UserPlantVariety = {
  plantCode: '',
  name: '',
  customSpacings: false,
  spacing: 0,
  inRowSpacing: 0,
  rowSpacing: 0,
  squareFootPlantCount: 0,
  customCalendar: false,
  recordID: 0,
  catalogueItems: null,
  plantingCalendar: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
};

const spacingWithinLimits = (spacing: number, unitType: UnitType) => {
  if (typeof spacing !== 'number') {
    return false;
  }

  if (unitType === UnitTypes.METRIC) {
    return spacing >= METRIC_SPACING_MIN && spacing <= METRIC_SPACING_MAX;
  }

  if (unitType === UnitTypes.IMPERIAL) {
    return spacing >= IMPERIAL_SPACING_MIN && spacing <= IMPERIAL_SPACING_MAX;
  }

  console.error('Invalid number passed to spacing check');
  return false;
};

const getSpacingErrorText = (unitType: UnitType = UnitTypes.METRIC) => {
  if (unitType === UnitTypes.METRIC) {
    return `must be between ${METRIC_SPACING_MIN} and ${METRIC_SPACING_MAX}`;
  }
  return `must be between ${IMPERIAL_SPACING_MIN} and ${IMPERIAL_SPACING_MAX}`;
};

/**
 * Returns true if both planting calendar arrays match, else returns false
 * @param plantingCalendarA
 * @param plantingCalendarB
 */
const plantingCalendarsMatch = (plantingCalendarA: NumberedPlantingCalendar, plantingCalendarB: NumberedPlantingCalendar) => {
  return (
    plantingCalendarA[0] === plantingCalendarB[0] &&
    plantingCalendarA[1] === plantingCalendarB[1] &&
    plantingCalendarA[2] === plantingCalendarB[2] &&
    plantingCalendarA[3] === plantingCalendarB[3] &&
    plantingCalendarA[4] === plantingCalendarB[4] &&
    plantingCalendarA[5] === plantingCalendarB[5] &&
    plantingCalendarA[6] === plantingCalendarB[6] &&
    plantingCalendarA[7] === plantingCalendarB[7] &&
    plantingCalendarA[8] === plantingCalendarB[8] &&
    plantingCalendarA[9] === plantingCalendarB[9] &&
    plantingCalendarA[10] === plantingCalendarB[10] &&
    plantingCalendarA[11] === plantingCalendarB[11]
  );
};

const spacings = (userPlantVariety: UserPlantVariety): PlantSpacings => {
  return {
    spacing: userPlantVariety.spacing,
    rowSpacing: userPlantVariety.rowSpacing,
    inRowSpacing: userPlantVariety.inRowSpacing,
    sfgCount: userPlantVariety.squareFootPlantCount,
  };
};

const validSpacing = (userPlantVariety: UserPlantVariety, unitType: UnitType = UnitTypes.METRIC) => {
  return spacingWithinLimits(userPlantVariety.spacing, unitType);
};

const validInRowSpacing = (userPlantVariety: UserPlantVariety, unitType: UnitType = UnitTypes.METRIC) => {
  return spacingWithinLimits(userPlantVariety.inRowSpacing, unitType);
};

const validRowSpacing = (userPlantVariety: UserPlantVariety, unitType: UnitType = UnitTypes.METRIC) => {
  return spacingWithinLimits(userPlantVariety.rowSpacing, unitType);
};

const validSquareFootPlantingCount = (userPlantVariety: UserPlantVariety) => {
  if (typeof userPlantVariety.squareFootPlantCount !== 'number') {
    return false;
  }

  return userPlantVariety.squareFootPlantCount >= SFG_COUNT_MIN && userPlantVariety.squareFootPlantCount <= SFG_COUNT_MAX;
};

const equals = (userPlantVariety: UserPlantVariety, compare: UserPlantVariety) => {
  return (
    this === userPlantVariety ||
    (userPlantVariety.plantCode === compare.plantCode &&
      userPlantVariety.name === compare.name &&
      userPlantVariety.customSpacings === compare.customSpacings &&
      userPlantVariety.spacing === compare.spacing &&
      userPlantVariety.inRowSpacing === compare.inRowSpacing &&
      userPlantVariety.rowSpacing === compare.rowSpacing &&
      userPlantVariety.squareFootPlantCount === compare.squareFootPlantCount &&
      userPlantVariety.customCalendar === compare.customCalendar &&
      userPlantVariety.recordID === compare.recordID &&
      userPlantVariety.catalogueItems === compare.catalogueItems &&
      plantingCalendarsMatch(userPlantVariety.plantingCalendar, compare.plantingCalendar))
  );
};

const hasEmptyCalendar = (userPlantVariety: UserPlantVariety) => {
  return (
    userPlantVariety.plantingCalendar[0] === 0 &&
    userPlantVariety.plantingCalendar[1] === 0 &&
    userPlantVariety.plantingCalendar[2] === 0 &&
    userPlantVariety.plantingCalendar[3] === 0 &&
    userPlantVariety.plantingCalendar[4] === 0 &&
    userPlantVariety.plantingCalendar[5] === 0 &&
    userPlantVariety.plantingCalendar[6] === 0 &&
    userPlantVariety.plantingCalendar[7] === 0 &&
    userPlantVariety.plantingCalendar[8] === 0 &&
    userPlantVariety.plantingCalendar[9] === 0 &&
    userPlantVariety.plantingCalendar[10] === 0 &&
    userPlantVariety.plantingCalendar[11] === 0
  );
};

const isDisabled = (userPlantVariety: UserPlantVariety) => {
  return !userPlantVariety.customSpacings && !userPlantVariety.customCalendar;
};

export const UserPlantVarietyUtils = {
  spacings,
  validSpacing,
  validInRowSpacing,
  validRowSpacing,
  validSquareFootPlantingCount,
  equals,
  hasEmptyCalendar,
  isDisabled,
  getSpacingErrorText,
};
