/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Children, ReactElement, ReactNode, forwardRef, useCallback, useMemo, useRef } from 'react';
import { convertSizeToCSS, ResizeType, SizeType } from './types';
import './input-container.scss';

interface iProps {
  children?: ReactNode;
  leftText?: ReactNode;
  rightText?: ReactNode;
  inputPrefix?: ReactNode;
  inputSuffix?: ReactNode;
  inputPrefixInline?: boolean;
  inputSuffixInline?: boolean;
  size?: SizeType | number;
  desktopSize?: SizeType | number;
  mobileSize?: SizeType | number;
  invalid?: boolean;
  className?: string;
  resize?: ResizeType;
  autoFocus?: boolean;
}

/**
 * Creates a list of styles for this element.
 * Should mainly be used to generate CSS variables.
 * @param size The size of the input container
 * @returns A list of css styles
 */
function getStyles(size: iProps['size'], desktopSize: iProps['size'], mobileSize: iProps['size']) {
  return {
    '--input-container-size': size ? convertSizeToCSS(size) : undefined,
    '--desktop-input-container-size': desktopSize ? convertSizeToCSS(desktopSize) : undefined,
    '--mobile-input-container-size': mobileSize ? convertSizeToCSS(mobileSize) : undefined,
  } as Record<string, string>;
}

/**
 * Usage notes:
 * To get a ref to the input contained within the InputContainer, pass the ref to the InputContainer,
 *  NOT the input itself. This is because the InputContainer overwrites the ref, and there's no way
 *  of getting that original ref it seems.
 */
const InputContainer = forwardRef(
  (
    {
      leftText,
      rightText,
      inputPrefix,
      inputSuffix,
      inputPrefixInline = false,
      inputSuffixInline = false,
      size,
      desktopSize,
      mobileSize,
      invalid = false,
      className,
      children,
      resize = 'vertical',
      autoFocus = false,
    }: iProps,
    ref
  ): JSX.Element => {
    const internalRef = useRef<HTMLElement | null>(null);
    const hasAutoFocused = useRef<boolean>(false);

    /**
     * ref middleman so we get a copy and can forward it outside.
     */
    const setRef = useCallback(
      (element: HTMLElement | null) => {
        internalRef.current = element;

        // Auto-focus if desired and is first time
        if (element && autoFocus && !hasAutoFocused.current) {
          // Wait a frame because this doesn't seem to work immediately?
          requestAnimationFrame(() => {
            element.focus();
          });
          hasAutoFocused.current = true;
        }

        // Forward the ref to the input element
        if (ref) {
          if ('current' in ref) {
            ref.current = element;
          } else {
            ref(element);
          }
        }
      },
      [autoFocus]
    );

    /**
     * Add a ref to the first input element found, if possible.
     */
    const childrenWithRef = useMemo(() => {
      let found = false;
      return Children.map(children, (_child) => {
        const child = _child as ReactElement;
        if (!React.isValidElement(_child) || !child || !child.type || found) {
          return child;
        }
        if (child.type === 'input' || child.type === 'textarea') {
          found = true;
          return React.cloneElement(child, { ref: setRef });
        }
        return child;
      });
    }, [children]);

    /**
     * Function to pass clicks on text around the input element onto the input element.
     */
    const forwardClick = useCallback(() => {
      if (internalRef.current) {
        internalRef.current.focus();
      }
    }, []);

    const classNames = ['input-container', `resize-${resize}`];
    if (invalid) {
      classNames.push('error');
    }
    if (className) {
      classNames.push(className);
    }

    const prefixClassNames = ['input-container-input-prefix'];
    if (inputPrefixInline) {
      prefixClassNames.push('inline');
    }

    const suffixClassNames = ['input-container-input-suffix'];
    if (inputSuffixInline) {
      suffixClassNames.push('inline');
    }

    const styles = getStyles(size, desktopSize, mobileSize);

    return (
      <label className={classNames.join(' ')} style={styles}>
        {leftText && (
          <span className='input-container-lefttext' onClick={forwardClick}>
            {leftText}
          </span>
        )}
        <div className='input-container-input'>
          {inputPrefix && (
            <span className={prefixClassNames.join(' ')} onClick={forwardClick}>
              {inputPrefix}
            </span>
          )}
          {childrenWithRef}
          {inputSuffix && (
            <span className={suffixClassNames.join(' ')} onClick={forwardClick}>
              {inputSuffix}
            </span>
          )}
        </div>
        {rightText && (
          <span className='input-container-righttext' onClick={forwardClick}>
            {rightText}
          </span>
        )}
      </label>
    );
  }
);
InputContainer.displayName = 'InputContainer';

export default InputContainer;
