import React, { useContext, useMemo } from 'react';

import { Direction } from '@gi/constants';

import { SidebarContext } from './sidebar-context';

import styles from './sidebar-styles.module.css';

const ICON_MAPPING: Record<Direction.LEFT | Direction.RIGHT, Record<'minimise' | 'maximise', string>> = {
  [Direction.LEFT]: {
    minimise: 'icon-left-open-1',
    maximise: 'icon-right-open',
  },
  [Direction.RIGHT]: {
    minimise: 'icon-right-open',
    maximise: 'icon-left-open-1',
  },
};

interface iProps {
  className?: string;
}

const SidebarHeaderToggleButton = ({ className }: iProps): JSX.Element => {
  const { minimised, setMinimised, orientation } = useContext(SidebarContext);

  const icon = useMemo(() => ICON_MAPPING[orientation][minimised ? 'maximise' : 'minimise'], [orientation, minimised]);
  const ariaLabel = useMemo(() => (minimised ? 'Open side panel' : 'Close side panel'), [minimised]);

  const classNames: string[] = [minimised ? styles.expandButton : styles.collapseButton];
  classNames.push(orientation === Direction.LEFT ? styles.attachRight : styles.attachLeft);
  if (className) {
    classNames.push(className);
  }

  return (
    <button type='button' className={classNames.join(' ')} aria-label={ariaLabel} onClick={() => setMinimised(!minimised)}>
      <i className={icon} />
    </button>
  );
};

export default SidebarHeaderToggleButton;
