import React, { useCallback, useContext } from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';

import { ResourceContext } from '@gi/resource-provider';
import { getInlineTopbarSelectStyles } from '../../common/select-styles';
import MenuButton from '../../common/menu-button';
import { PlannerCommandContext } from '../../../../planner-command-provider';
import MenuIcon from '../../common/menu-icon';

import styles from './month-selector.module.css';

type MonthSelectorOption = {
  value: null | number;
  label: string;
};

const MONTHS: MonthSelectorOption[] = [
  { label: 'All Months', value: null },
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];

const SH_MONTHS: MonthSelectorOption[] = [
  { label: 'All Months', value: null },
  { label: 'July', value: 0 },
  { label: 'August', value: 1 },
  { label: 'September', value: 2 },
  { label: 'October', value: 3 },
  { label: 'November', value: 4 },
  { label: 'December', value: 5 },
  { label: 'January', value: 6 },
  { label: 'February', value: 7 },
  { label: 'March', value: 8 },
  { label: 'April', value: 9 },
  { label: 'May', value: 10 },
  { label: 'June', value: 11 },
];

const getMonthFromValue = (val, northernHemisphere) => {
  if (val === null) {
    return MONTHS[0].label;
  }

  if (northernHemisphere) {
    return MONTHS[val + 1].label;
  }

  return SH_MONTHS[val + 1].label;
};

const SELECT_STYLES = getInlineTopbarSelectStyles(134);
const FULLWIDTH_SELECT_STYLES = {
  ...SELECT_STYLES,
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
};

interface iProps {
  disabled?: boolean;
  fullWidth?: boolean;
  menuPlacement?: MenuPlacement;
  attachToBody?: boolean;
  id?: string;
}

const MonthSelector = ({ disabled = false, fullWidth, menuPlacement, attachToBody = false, id }: iProps): JSX.Element => {
  const { month, setMonth } = useContext(PlannerCommandContext);
  const { userNorthernHemisphere } = useContext(ResourceContext);

  const onNext = useCallback(() => {
    if (setMonth) {
      let nextMonth: number | null = month === null ? 0 : month + 1;
      if (nextMonth >= 12) {
        nextMonth = null;
      }
      setMonth(nextMonth);
    }
  }, [setMonth, month]);

  const onPrevious = useCallback(() => {
    if (setMonth) {
      let nextMonth: number | null = month === null ? 11 : month - 1;
      if (nextMonth < 0) {
        nextMonth = null;
      }
      setMonth(nextMonth);
    }
  }, [setMonth, month]);

  const monthValue = { label: getMonthFromValue(month, userNorthernHemisphere), value: month };
  const months = userNorthernHemisphere ? MONTHS : SH_MONTHS;
  const classNames = [styles.monthSelector];
  if (fullWidth) {
    classNames.push(styles.fullWidth);
  }

  return (
    <div className={classNames.join(' ')}>
      <MenuButton type='button' disabled={disabled} className={styles.monthButton} onClick={onPrevious}>
        <MenuIcon icon='icon-angle-left' />
      </MenuButton>
      <ReactSelect<MonthSelectorOption>
        isDisabled={disabled}
        styles={fullWidth ? FULLWIDTH_SELECT_STYLES : SELECT_STYLES}
        options={months}
        value={monthValue}
        onChange={(e) => {
          if (e !== null && setMonth) {
            setMonth(e.value);
          }
        }}
        menuPortalTarget={attachToBody ? document.body : undefined}
        menuPlacement={menuPlacement}
        inputId={id}
        isSearchable={false}
      />
      <MenuButton type='button' disabled={disabled} className={styles.monthButton} onClick={onNext}>
        <MenuIcon icon='icon-angle-right' />
      </MenuButton>
    </div>
  );
};

export default MonthSelector;
