import React from 'react';

import YouTubeEmbed from '@gi/youtube-embed';
import { PageSection, PageSectionTitle } from '@gi/page-layout';
import { networkConfig } from '@gi/config';
import { useViewLinkEventHook } from '@gi/garden-platform-events';

import journalIntroThumb from './journal-intro-thumb.png';

const JournalAbout = (): JSX.Element => {
  const sendLinkViewedEvent = useViewLinkEventHook('app-help-journal-about');

  return (
    <>
      <PageSection>
        <PageSectionTitle>
          <h2>About the Garden Journal</h2>
        </PageSectionTitle>
        <p>
          The best education in gardening is to learn through experience. Keeping a record avoids repeating mistakes and frustration over wasted time and space.
          With the Journal you&apos;ll learn more about your garden with every observation and photo you add. The Journal makes keeping track of your progress
          quick and easy. Take photos outside, then upload along with written notes right from your mobile or tablet device. Record when you planted or last
          watered, diagnose problems and see how much you&apos;ve harvested.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionTitle>
          <h2>Garden Journal FAQ & Help Pages</h2>
        </PageSectionTitle>
        <p>
          The Garden Journal FAQ and help pages can be found{' '}
          <a href={networkConfig.helpURL} target='_blank' rel='noreferrer' onClick={() => sendLinkViewedEvent('help-pages')}>
            here
          </a>
          .
        </p>
      </PageSection>
      <PageSection>
        <PageSectionTitle>
          <h2>Garden Journal Introduction Video</h2>
        </PageSectionTitle>
        <div>
          <YouTubeEmbed youTubeID='mLj9caW5knE' thumbnail={journalIntroThumb} />
        </div>
      </PageSection>
    </>
  );
};

export default JournalAbout;
