import React from 'react';
import './companion-icon.scss';

interface iProps {
  caption?: string;
  isHelped?: boolean;
  isHelper?: boolean;
  disabled?: boolean;
}

const CompanionIcon = ({ isHelped = false, isHelper = false, caption = 'Companion Planting', disabled = false }: iProps): JSX.Element => {
  let icon = 'icon-heart';

  if (isHelped && isHelper) {
    icon = 'icon-heart-double';
  } else if (isHelped) {
    icon = 'icon-heart-left';
  } else if (isHelper) {
    icon = 'icon-heart-right';
  }

  const className = `companion-icon ${disabled ? 'disabled' : ''}`;

  return (
    <div className={className} title={caption}>
      <i className={icon} />
    </div>
  );
};

export default CompanionIcon;
