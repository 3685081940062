import { generateNotificationID } from './notification-id-generator';
import { Notification, DEFAULT_NOTIFICATION_OPTIONS, NotificationTypes, NotificationAction } from './notification';
import { DEFAULT_VISIBLE_DURATION } from './notification-constants';
import { notificationActions } from './notification-slice';

export interface DefaultNotificationOptions {
  title: string;
  text?: string;
  type?: NotificationTypes;
  icon?: string;
  canTimeout?: boolean;
  inProgress?: boolean;
  ID?: number;
  actions?: NotificationAction[];
}

interface NotificationOptions {
  title?: string;
  text?: string;
  type?: NotificationTypes;
  icon?: string | null;
  canTimeout?: boolean;
  visibleDuration?: number;
  ID?: number;
  actions?: NotificationAction[];
}

/**
 * Creates a default notification
 * @param options The options for the notification
 * @returns A store action
 */
export const createDefaultNotification = ({
  title,
  text = '',
  icon = '',
  ID = generateNotificationID(),
  type = NotificationTypes.INFO,
  canTimeout = false,
  inProgress = false,
  actions,
}: DefaultNotificationOptions) => {
  const timeoutTime = canTimeout ? Date.now() + DEFAULT_VISIBLE_DURATION : null;

  const notification: Notification = {
    ...DEFAULT_NOTIFICATION_OPTIONS,
    ID,
    title,
    text,
    type,
    icon,
    timeoutTime,
    canTimeout,
    isTimingOut: canTimeout,
    creationTime: Date.now(),
    inProgress,
    actions,
  };

  return notificationActions.addNotification(notification);
};

/**
 * Creates a notification
 * @param options The options for the notification
 * @returns A store action
 */
export const createNotification = ({
  title = '',
  text = '',
  type = NotificationTypes.INFO,
  icon = null,
  canTimeout = true,
  visibleDuration = DEFAULT_VISIBLE_DURATION,
  ID = generateNotificationID(),
  actions,
}: NotificationOptions) => {
  const timeoutTime = canTimeout ? Date.now() + visibleDuration : null;

  const notification: Notification = {
    ...DEFAULT_NOTIFICATION_OPTIONS,
    ID,
    title,
    text,
    type,
    icon,
    visibleDuration,
    timeoutTime,
    canTimeout,
    actions,
    isTimingOut: canTimeout,
    creationTime: Date.now(),
  };

  return notificationActions.addNotification(notification);
};

/**
 * Updates a notification
 * @param notification The updated notification
 * @returns A store action
 */
export const updateNotification = (notification: Notification) => {
  return notificationActions.updateNotification(notification);
};

/**
 * Updates a notification with the given ID, only changing the parts specified.
 * @returns A store action
 */
export const updateNotificationByID = notificationActions.updateNotificationById;

/**
 * Removes a notification by its ID
 * @param notificationID The ID of the notification to remove
 * @returns A store action
 */
export const removeNotification = (notificationID: number) => {
  return notificationActions.removeNotification(notificationID);
};

/**
 * Stops a notification from timing out.
 * @param notification The notification
 * @returns A store action
 */
export const stopNotificationTimeout = (notification: Notification) => {
  return updateNotification({
    ...notification,
    isTimingOut: false,
  });
};

/**
 * Starts a notification's timeout
 * @param notification The notification
 * @param visibleDuration Optional new timeout duration
 * @returns A store action
 */
export const startNotificationTimeout = (notification: Notification, visibleDuration = notification.visibleDuration) => {
  const timeoutTime = Date.now() + visibleDuration;
  return updateNotification({
    ...notification,
    isTimingOut: true,
    visibleDuration,
    timeoutTime,
  });
};

/**
 * Hides a notification
 * @param notification The notification
 * @returns A store action
 */
export const hideNotification = (notification: Notification) => {
  return updateNotification({
    ...notification,
    timeoutTime: null,
    visible: false,
    isTimingOut: false,
  });
};

/**
 * Creates an in-progress notification.
 * @param title The title text
 * @param ID Optional ID of the notification
 * @returns A store action
 */
export const createDefaultInProgressNotification = (title: string, ID?: number) => {
  return createNotification({
    title,
    ID,
    canTimeout: false,
  });
};

/**
 * Changes a notification to a success notificaiton, that will time out.
 * @param notification The notification
 * @param title The new title
 * @param visibleDuration The new visible duration
 * @returns A store action
 */
export const changeToSuccessNotification = (notification: Notification, title: string, visibleDuration: number) => {
  return updateNotification({
    ...notification,
    title,
    canTimeout: true,
    visibleDuration,
    type: NotificationTypes.SUCCESS,
  });
};

/**
 * Changes a notification to an error notification, that must be dismissed by the user.
 * @param notification The notification
 * @param title The new title
 * @returns A store action
 */
export const changeToErrorNotification = (notification: Notification, title: string) => {
  return updateNotification({
    ...notification,
    title,
    canTimeout: false,
    type: NotificationTypes.ERROR,
  });
};
