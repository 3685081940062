import { State, StateDef } from '@gi/state';
import Node from '../../node';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { bindState } from '../../utils/state-utils';
import HoverableComponentContext, { HoverFreezeFlag } from '../hoverable/hoverable-component-context';
import SelectableComponentContext from '../selectable/selectable-component-context';
import ManipulatableComponent from './manipulatable-component';

export type ManipulatableComponentContextState = StateDef<{
  manipulating: boolean;
}>;

class ManipulatableComponentContext extends NodeComponent {
  type = 'ManipulatableComponentContext';

  readonly state: State<ManipulatableComponentContextState>;

  #manipulatingNodes: Node[] = [];
  #hoverContext: HoverableComponentContext | null = null;

  constructor() {
    super();

    this.state = new State({ manipulating: false });
    bindState(this.state, this);

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    this.#hoverContext = this.owner?.tryGetContext(HoverableComponentContext) ?? null;
  };

  #onBeforeUnbind = () => {
    this.#hoverContext = null;
  };

  /**
   * Marks all selected nodes as being manipulated.
   */
  startManipulating(handleNode?: Node) {
    this.state.values.manipulating = true;

    const selection = this.owner?.tryGetContext(SelectableComponentContext);

    if (selection) {
      this.#manipulatingNodes = [...selection.selection];
      this.#manipulatingNodes.forEach((node) => {
        const manipulating = node.components.get(ManipulatableComponent);
        if (manipulating) {
          manipulating.setIsManipulating(true);
        }
      });
    }

    if (this.#hoverContext) {
      if (handleNode) {
        this.#hoverContext.hover(handleNode);
      }
      this.#hoverContext.addFreezeFlag(HoverFreezeFlag.MANIPULATING);
    }
  }

  /**
   * Marks all currently-being-manipulated nodes as no-long being manipulated (wording)
   */
  endManipulating() {
    this.#manipulatingNodes.forEach((node) => {
      const manipulating = node.components.get(ManipulatableComponent);
      if (manipulating) {
        manipulating.setIsManipulating(false);
      }
    });

    this.#manipulatingNodes = [];
    this.state.values.manipulating = false;

    if (this.#hoverContext) {
      this.#hoverContext.removeFreezeFlag(HoverFreezeFlag.MANIPULATING);
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default ManipulatableComponentContext;
