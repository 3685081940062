import React, { useContext } from 'react';
import { useOutlet } from 'react-router-dom';

import { AppContext } from '@gi/app-provider';
import MobileNavigation from '@gi/mobile-garden-platform-nav';
import { MobileNotificationProvider, MobileNotifcationContainer } from '@gi/notifications';
import { networkConfig } from '@gi/config';
import { Banners } from '@gi/banners';
import { MobileIntroductionTutorialRenderer } from '@gi/mobile-introduction-modal';

import './garden-platform-mobile.scss';

const MobileGardenPlatform = (): JSX.Element => {
  const outlet = useOutlet();
  const { runtimeConfig } = useContext(AppContext);

  const style = {
    backgroundImage: `url(${networkConfig.assetBaseURL}header-images/${runtimeConfig.backgroundImage.blurSrc})`,
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    overflow: 'hidden',
  };

  return (
    <MobileNotificationProvider>
      <div className='mobile-garden-planner' style={style}>
        <MobileNavigation />
        <Banners />
        <div className='mobile-garden-planner-content'>{outlet}</div>
        <MobileNotifcationContainer />
        <MobileIntroductionTutorialRenderer />
      </div>
    </MobileNotificationProvider>
  );
};

export default MobileGardenPlatform;
