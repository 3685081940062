import React, { useContext, useMemo } from 'react';
import ReactSelect from 'react-select';
import FormField, { FormSection, FormValues, InputContainer } from '@gi/form-responsive';
import { ResourceContext } from '@gi/resource-provider';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';
import type { ProfileSettings } from './profile-settings-tab';

type OverrideArtifactCodeOption = {
  label: string;
  value: string;
};

const overrideArtifactCodesByCountry: Record<string, OverrideArtifactCodeOption> = {
  gb: { label: 'English (gb)', value: 'gb' },
  us: { label: 'English (us)', value: 'us' },
  za: { label: 'English (za)', value: 'za' },
  au: { label: 'English (au)', value: 'au' },
  nl: { label: 'Dutch (nl)', value: 'nl' },
};

function getAvailableArtifactCodeOptions(availableArtifactCodes: string[]): OverrideArtifactCodeOption[] {
  return availableArtifactCodes.map((code) => overrideArtifactCodesByCountry[code]).filter((value) => value !== undefined);
}

const getOverrideArtifactCodeOptionValue = (options: OverrideArtifactCodeOption[], value: string, userCountryDefaultArtifactCode: string) => {
  for (let i = 0; i < options.length; i++) {
    if (options[i].value === value) {
      return options[i];
    }
  }

  const countryDefault = overrideArtifactCodesByCountry[userCountryDefaultArtifactCode];
  if (countryDefault) {
    return countryDefault;
  }

  // This shouldn't happen, the country default should be one of 'gb', 'us', 'za', 'au' or 'nl'
  return { label: 'US (en)', value: 'us' };
};

interface iProps {
  profileSettings: FormValues<ProfileSettings>;
  setProfileSettings: (profileSettings: FormValues<ProfileSettings>) => void;
}

/**
 * Resource settings form component, adds a form field which displays a ReactSelect input with options for
 * 'GB/Engish' and 'NL/Dutch' options
 *
 * Currently it will only display if your country is set to NL as that is the only country with more than 1
 * `availableArtifactCodes`
 */
const ResourceLanguageSettings = ({ profileSettings, setProfileSettings }: iProps): JSX.Element | null => {
  const { userCountry } = useContext(ResourceContext);

  if (userCountry === null) {
    return null;
  }

  if (userCountry.availableArtifactCodes.length < 2) {
    // This users country can only has a single artifact code available
    return null;
  }

  const options = useMemo(() => {
    return getAvailableArtifactCodeOptions(userCountry.availableArtifactCodes);
  }, [userCountry]);

  const overrideDataValue = useMemo(() => {
    return getOverrideArtifactCodeOptionValue(options, profileSettings.values.userArtifactCode, userCountry.artifactCode);
  }, [options, profileSettings.values.userArtifactCode, userCountry.artifactCode]);

  return (
    <FormSection padding={12} gap={6} heading='Resource Language' className='form-section-background'>
      <p>Change the plant and garden object data language. English language files have new plant data which has not yet been translated to Dutch</p>
      <p>Changing language requires a restart of the garden planner</p>
      <FormField label='Resource Language' htmlFor='profile-settings:resource-language' desktopLayout={{ labelSize: 180, inputSize: 280 }}>
        <InputContainer size='full'>
          <ReactSelect<OverrideArtifactCodeOption>
            styles={DEFAULT_SELECT_STYLES}
            options={options}
            value={overrideDataValue}
            onChange={(opt) => {
              if (opt !== null) {
                const { value } = opt;
                if (value !== undefined) {
                  setProfileSettings(profileSettings.setValue('userArtifactCode', { value }));
                }
              }
            }}
            isSearchable={false}
            inputId='profile-settings:resource-language'
          />
        </InputContainer>
      </FormField>
    </FormSection>
  );
};

export default ResourceLanguageSettings;
