import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';

import { networkConfig } from '@gi/config';
import { ResourceContext } from '@gi/resource-provider';
import { SessionSelectors } from '@gi/react-session';
import { DeviceDisplayMode } from '@gi/constants';
import { DisplayModeSelectors } from '@gi/display-mode-provider';
import {
  EmptyPlantingCalendarDisplay,
  PlantingCalendar,
  PlantingCalendarDisplay,
  PlantingCalendarKey,
  TextPlantingCalendarDisplay,
  plantingCalendarIsEmpty,
} from '@gi/planting-calendar';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

import './plant-list-card-calendar.scss';

interface iProps {
  plantingCalendar: PlantingCalendar | null;
}

const PlantListCardCalendar = ({ plantingCalendar }: iProps): JSX.Element | null => {
  const { columns, options } = useContext(GlobalPlantListContext);
  const { userArtifactCode } = useContext(ResourceContext);

  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);
  const northernHemisphere = useSelector(SessionSelectors.getIsNorthernHemisphere);

  const [showKey, setShowKey] = useState<boolean>(false);

  const calendarStartMonth = northernHemisphere ? 1 : 7;

  const calendar = useMemo(() => {
    if (plantingCalendar === null || (plantingCalendarIsEmpty(plantingCalendar) && !plantingCalendar.isCustom)) {
      return <EmptyPlantingCalendarDisplay />;
    }

    if (options.textPlantingCalendars) {
      const halfMonthOffset = (calendarStartMonth - 1) * 2;
      return <TextPlantingCalendarDisplay plantingCalendar={plantingCalendar} halfMonthOffset={halfMonthOffset} />;
    }

    return (
      <div onClick={() => setShowKey(!showKey)} role='button'>
        <PlantingCalendarDisplay
          plantingCalendar={plantingCalendar}
          startMonth={calendarStartMonth}
          calendarBarURL={networkConfig.plantingCalendarImageURL}
          highlightOnHover
          artifactCode={userArtifactCode === null ? 'us' : userArtifactCode}
          compact={deviceDisplayMode === DeviceDisplayMode.MOBILE}
          noMargin
        />
        <AnimateHeight duration={200} height={showKey ? 'auto' : 0}>
          <div className='plant-list-card-calendar-key'>
            <div>
              <PlantingCalendarKey />
            </div>
          </div>
        </AnimateHeight>
      </div>
    );
  }, [plantingCalendar, options, showKey]);

  if (!columns.calendar) {
    return null;
  }

  return <div className='plant-list-card-calendar'>{calendar}</div>;
};

export default PlantListCardCalendar;
