import React, { useContext, useEffect, useRef } from 'react';

import Table from '../common/table';
import TableBody from '../common/table-body';
import TableHead from '../common/table-head';
import GlobalPlantListContext from '../global-provider/global-plant-list-context';

import { DISPLAY_TYPE } from '../constants';

import AreaTableHead from './area-table-head';
import SummaryTableHead from './summary-table-head';
import VarietyTableHead from './variety-table-head';

import './plant-list-table.scss';
import SummaryTableRows from './summary-table-rows';
import VarietyTableRows from './variety-table-rows';
import AreaTableRows from './area-table-rows';
import LocalPlantListContext from '../local-provider/local-plant-list-context';

const PlantListTable = (): JSX.Element => {
  const { displayType, options } = useContext(GlobalPlantListContext);
  const { plantListData, setPlantTableElement } = useContext(LocalPlantListContext);
  const plantListTableRef = useRef(null);

  useEffect(() => {
    setPlantTableElement(plantListTableRef.current);
  }, [plantListTableRef.current]);

  if (plantListData === null) {
    return <div>Loading plant list...</div>;
  }

  if (displayType === DISPLAY_TYPE.Summary && plantListData.plants.length === 0) {
    return <div className='empty-plant-list-warning'>Add plants to your plan to have them show up here.</div>;
  }

  if (displayType === DISPLAY_TYPE.Variety && plantListData.varieties.length === 0) {
    return <div className='empty-plant-list-warning'>Add plants to your plan to have them show up here.</div>;
  }

  if (displayType === DISPLAY_TYPE.Area && plantListData.areas.length === 0) {
    return <div className='empty-plant-list-warning'>Add plants to your plan to have them show up here.</div>;
  }

  return (
    <div className={`plant-list-print-container ${options.printBackgroundColors ? 'print-background-colors' : ''}`} ref={plantListTableRef}>
      <Table className='plant-list-table'>
        <TableHead className='table-headers main-headers'>
          {displayType === DISPLAY_TYPE.Summary ? <SummaryTableHead /> : displayType === DISPLAY_TYPE.Variety ? <VarietyTableHead /> : <AreaTableHead />}
        </TableHead>
        <TableBody>
          {displayType === DISPLAY_TYPE.Summary ? <SummaryTableRows /> : displayType === DISPLAY_TYPE.Variety ? <VarietyTableRows /> : <AreaTableRows />}
        </TableBody>
      </Table>
    </div>
  );
};

export default PlantListTable;
