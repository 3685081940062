import React from 'react';
import { useSelector } from 'react-redux';

import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';

import AppAccountDesktop from '@gi/app-account-desktop';
import AppAccountMobile from '@gi/app-account-mobile';

const AppAccount = (): JSX.Element => {
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  if (deviceDisplayMode === DeviceDisplayMode.DESKTOP) {
    return <AppAccountDesktop />;
  }

  return <AppAccountMobile />;
};

export default AppAccount;
