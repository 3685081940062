import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';
import { User } from '@gi/user';

import { attachUserEventErrorClientMessage, attachUserVideoEventErrorClientMessage } from './event-service-errors';
import type { AnalyticsVideoWatches } from './video-analytics';

type AnalyticsEvent = {
  userID: number;
  eventType: string;
  eventData?: string;
};

/**
 * Provides methods for sending events to the API
 *
 * @class EventService
 */
class EventService {
  endpoint: string;

  /**
   *Creates an instance of EventService.
   * @param {object}
   * @property {string} endpoint
   * @memberof UserService
   */
  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  sendVideoEvent(user: User, event: AnalyticsVideoWatches) {
    const endPoint = '/uservideoevent/';
    const identifier = user.ID;
    const url = `${this.endpoint}${endPoint}${identifier}`;

    return networkService
      .post(url, {}, event, RequestAuthMode.TicketAndBearer, {
        includeRequestBodyInLogs: true,
        keepAlive: true,
      })
      .catch(networkErrorReporter('POST', 'video analytics'))
      .catch((requestError) => {
        attachUserVideoEventErrorClientMessage(requestError);
        throw requestError;
      });
  }

  sendEvent(user: User, eventType: string, eventData?: string) {
    const endPoint = '/userevent/';
    const identifier = user.ID;
    const url = `${this.endpoint}${endPoint}${identifier}`;

    const eventObject: AnalyticsEvent = {
      userID: user.ID,
      eventType,
    };

    if (eventData) {
      eventObject.eventData = eventData;
    }

    return networkService
      .post(url, {}, eventObject, RequestAuthMode.TicketAndBearer, {
        includeRequestBodyInLogs: true,
        keepAlive: true,
      })
      .catch(networkErrorReporter('POST', 'analytics'))
      .catch((requestError) => {
        attachUserEventErrorClientMessage(requestError);
        throw requestError;
      });
  }
}

export default EventService;
