import React from 'react';
import { NavLink } from 'react-router-dom';
import { GuruPreviewArticle } from '@gi/app-guru-types';

import HeroPreviewThumbnail from '../components/hero-preview-thumbnail';
import HeroPreviewTitle from '../components/hero-preview-title';
import HeroPreviewDescription from '../components/hero-preview-description';
import {
  HeroPreviewContent,
  HeroPreviewDescriptionContainer,
  HeroPreviewLinkContainer,
  HeroPreviewThumbnailContainer,
} from '../components/hero-preview-content';

import styles from '../hero-preview.module.css';

interface iProps {
  previewArticle: GuruPreviewArticle;
  link: string;
}

const HeroPreviewArticle = ({ previewArticle, link }: iProps): JSX.Element => {
  return (
    <HeroPreviewContent>
      <HeroPreviewThumbnailContainer>
        <NavLink to={link}>
          <HeroPreviewThumbnail src={previewArticle.thumbnailUrl} alt={`${previewArticle.title} - thumbnail`} />
        </NavLink>
      </HeroPreviewThumbnailContainer>
      <HeroPreviewDescriptionContainer>
        <HeroPreviewTitle>{previewArticle.title}</HeroPreviewTitle>
        <HeroPreviewDescription>{previewArticle.description}</HeroPreviewDescription>
        <HeroPreviewLinkContainer>
          <NavLink className={styles.heroLink} to={link}>
            Read Article
          </NavLink>
        </HeroPreviewLinkContainer>
      </HeroPreviewDescriptionContainer>
    </HeroPreviewContent>
  );
};

export default HeroPreviewArticle;
