import React, { ChangeEvent, DragEvent, ReactNode, useCallback, useState } from 'react';

import styles from './styles.module.css';

interface iProps {
  children?: ReactNode;
  className?: string;
  onChange: (file: File) => void;
  accept?: string;
}

const FileDropzone = ({ children, className, onChange, accept }: iProps): JSX.Element => {
  const [hovering, setHovering] = useState<boolean>(false);

  const handleDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    setHovering(true);
  }, []);

  const handleDragOut = useCallback(() => {
    setHovering(false);
  }, []);

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      setHovering(false);
      if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
        onChange(event.dataTransfer.files[0]);
      }
    },
    [onChange]
  );

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        onChange(event.target.files[0]);
      }
    },
    [onChange]
  );

  const classNames: string[] = [styles.fileDropzone];
  if (className) {
    classNames.push(className);
  }
  if (hovering) {
    classNames.push(styles.hovering);
  }

  return (
    <label className={classNames.join(' ')} onDragEnter={handleDragOver} onDragOver={handleDragOver} onDragLeave={handleDragOut} onDrop={handleDrop}>
      {children ? (
        children
      ) : (
        <p className={styles.fileDropzonePlaceholder}>
          <span className='button button-inline'>
            <i className='icon-folder-open-empty' /> Choose a file to use
          </span>
          <span> or drop a file here</span>
        </p>
      )}
      <input type='file' className={styles.fileDropzoneInput} onChange={handleOnChange} accept={accept} />
    </label>
  );
};

export default FileDropzone;
