import { PropTypes } from 'prop-types';

const weekTotalsValuesShape = PropTypes.shape({
  planting: PropTypes.number.isRequired,
  harvesting: PropTypes.number.isRequired,
  care: PropTypes.number.isRequired,
  watering: PropTypes.number.isRequired,
  entries: PropTypes.number.isRequired,
});

const weeklyTotalsShape = PropTypes.shape({
  ymd: PropTypes.string.isRequired,
  values: weekTotalsValuesShape.isRequired,
  weekNo: PropTypes.number.isRequired,
  dateCaption: PropTypes.string.isRequired,
});

export default weeklyTotalsShape;
export { weeklyTotalsShape, weekTotalsValuesShape };
