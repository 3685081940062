import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormField, {
  createFormValues,
  FormLayout,
  FormManagerErrorText,
  FormSection,
  InputContainer,
  FORM_LAYOUT_PRESETS,
  unresponsivePreset,
} from '@gi/form-responsive';
import LoadingButton from '@gi/loading-button';
import { SessionSelectors } from '@gi/react-session';
import { StringValidators, Validator } from '@gi/validators';
import { AppAccountSelectors, AppAccountActionCreators } from '@gi/app-account-slice';
import type { UserPasswordChange } from '@gi/app-account-types';
import { LoadingState } from '@gi/constants';

const mustMatch = (match: string): Validator<string> => {
  return (value) => {
    return value === match ? [] : ['must match'];
  };
};

const UserChangePasswordForm = (): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);

  if (!user) {
    console.warn('Attempting to render UserChangePasswordForm without a User');
    return null;
  }

  const isSavingUser = useSelector(AppAccountSelectors.getIsSavingUser);
  const saveRequest = useSelector(AppAccountSelectors.getChangingPassword);
  const isSubmitting = saveRequest.status === LoadingState.LOADING;
  const dispatch = useDispatch();

  const [manager, setManager] = useState(
    createFormValues<UserPasswordChange>({
      password: { value: '', validators: [StringValidators.minLength(6)], disabled: user.misc.educationalAccount && !user.isTemporaryPassword },
      repeatPassword: { value: '', validators: [mustMatch('')], disabled: user.misc.educationalAccount && !user.isTemporaryPassword },
    })
  );

  const setField = useCallback(
    <K extends keyof UserPasswordChange>(setting: K, value: UserPasswordChange[K]) => {
      if (!manager.fields[setting].disabled) {
        setManager(manager.setValue(setting, { value }));
      }
    },
    [manager]
  );

  const submit = useCallback(() => {
    console.debug('Submtting Plan Settings: ', manager.values);
    const { values } = manager;

    dispatch(AppAccountActionCreators.changeUserPassword(user, values.password));
  }, [manager, user]);

  useEffect(() => {
    if (saveRequest.status === LoadingState.SUCCESS) {
      setManager(manager.reset());
    }
  }, [saveRequest]);

  return (
    <form>
      <FormSection className='form-section-background'>
        <h2>Change Password</h2>
        {user.isTemporaryPassword && <p>You are currently using a temporary password, please set a new password below.</p>}
        <input type='email' value={user.email} autoComplete='username' readOnly hidden />
        <FormField label='New Password' htmlFor='password-new' disabled={manager.fields.password.disabled} desktopLayout={{ inputSize: 'full' }}>
          <InputContainer>
            <input
              type='password'
              id='password-new'
              autoComplete='new-password'
              value={manager.values.password}
              onChange={(e) =>
                setManager(manager.setValues(['password', { value: e.target.value }], ['repeatPassword', { validators: [mustMatch(e.target.value)] }]))
              }
              disabled={manager.fields.password.disabled || isSubmitting}
            />
          </InputContainer>
        </FormField>
        <FormField
          label='Retype Password'
          htmlFor='password-repeat'
          disabled={manager.fields.repeatPassword.disabled}
          helpText={
            user.misc.educationalAccount && manager.fields.repeatPassword.disabled
              ? 'To update the password of educational accounts, please contact support'
              : undefined
          }
          desktopLayout={{ inputSize: 'full' }}
        >
          <InputContainer>
            <input
              type='password'
              id='password-repeat'
              autoComplete='repeat-password'
              value={manager.values.repeatPassword}
              onChange={(e) => setField('repeatPassword', e.target.value)}
              disabled={manager.fields.repeatPassword.disabled || isSubmitting}
            />
          </InputContainer>
        </FormField>
        <FormLayout layoutPreset={unresponsivePreset({ layout: 'column', xAlign: 'right', gap: 0 })}>
          <FormManagerErrorText<UserPasswordChange> formManager={manager} field='password' show='onDifference' prefix='Password ' />
          <FormManagerErrorText<UserPasswordChange> formManager={manager} field='repeatPassword' show='always' prefix='Passwords ' />
        </FormLayout>
        <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.ButtonRight}>
          <LoadingButton
            type='button'
            className='button button-primary'
            loading={isSubmitting}
            disabled={isSavingUser || !manager.hasDifferences || !manager.isValid}
            onClick={submit}
          >
            Change Password
          </LoadingButton>
        </FormLayout>
      </FormSection>
    </form>
  );
};

export default UserChangePasswordForm;
