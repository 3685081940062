import React, { ReactNode, useMemo, useState } from 'react';

import { FormLayout } from '@gi/form-responsive';
import { PageRow } from '@gi/page-layout';

import './banner.scss';

const BannerTypes = ['default', 'warning', 'danger'] as const;
type BannerType = (typeof BannerTypes)[number];

const BannerClassNames: { [key in BannerType]: string } = {
  default: 'banner-default',
  warning: 'banner-warning',
  danger: 'banner-danger',
};

interface iProps {
  children: ReactNode;
  dismissable?: boolean;
  type?: BannerType;
  className?: string;
  onDismiss?: () => boolean | undefined;
}

const Banner = ({ children, dismissable = false, type = 'default', className, onDismiss }: iProps): JSX.Element | null => {
  const [dismissed, setDismissed] = useState(false);

  /**
   * Handles the click of the dismiss button.
   * if an onDismiss callback exists, and it returns true, the banner will not be dismissed internally.
   */
  const handleDismiss = () => {
    if (onDismiss) {
      if (!onDismiss()) {
        setDismissed(true);
      }
    } else {
      setDismissed(true);
    }
  };

  const closeButton = useMemo(() => {
    return !dismissable ? null : (
      <button type='button' aria-label='Close' className='banner-close' onClick={handleDismiss}>
        <i className='icon-cancel' />
      </button>
    );
  }, [dismissable]);

  if (dismissed) {
    return null;
  }

  const classNames: string[] = ['banner'];
  if (BannerClassNames[type]) {
    classNames.push(BannerClassNames[type]);
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      <PageRow>
        <FormLayout desktopLayout={{ layout: 'row', yAlign: 'center', size: 'full' }} mobileLayout={{ layout: 'row', yAlign: 'center', size: 'full' }}>
          <FormLayout desktopLayout={{ xAlign: 'stretch', yAlign: 'center', gap: 20, size: 'full' }} mobileLayout={{ size: 'full' }}>
            {children}
          </FormLayout>
          {closeButton}
        </FormLayout>
      </PageRow>
    </div>
  );
};

export default Banner;
