import { RefObject, useEffect } from 'react';

/**
 * Watches for when a click happens outside of the given element(s).
 * @param ref The element to watch for a click outside of
 * @param callback The callback to run when clicked outside of
 */
const useOnClickOutside = <T extends HTMLElement>(refs: RefObject<T> | RefObject<T>[], callback: () => void) => {
  useEffect(() => {
    const eventHandler = (e: MouseEvent) => {
      const iterable = refs instanceof Array ? refs : [refs];
      const clickIsContained = iterable.some((ref) => {
        if (ref.current && e.target instanceof Node && ref.current.contains(e.target)) {
          return true;
        }
        return false;
      });
      if (!clickIsContained) {
        callback();
      }
    };

    document.addEventListener('pointerdown', eventHandler);

    return () => {
      document.removeEventListener('pointerdown', eventHandler);
    };
  }, [refs, callback]);
};

export default useOnClickOutside;
