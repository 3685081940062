import React, { useMemo } from 'react';
import { ModifierNames, ModifierType } from '@gi/constants';
import { PrintablePlannerIcon } from '@gi/planner-icon';

import PlantingCalendarMonth from './planting-calendar-month';

import { NumberedPlantingCalendar } from '../planting-calendar-types';

import './planting-calendar-display.scss';

const LONG_MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const SHORT_MONTH_NAMES = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];

const MONTH_TITLES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const modifierIcons = {
  [ModifierType.Cloche]: 'CL03',
  [ModifierType.ColdFrame]: 'CF01',
  [ModifierType.Greenhouse]: 'GH01',
  [ModifierType.HeatedGreenhouse]: 'GH21',
  [ModifierType.HeatedPolytunnel]: 'PT21',
  [ModifierType.Polytunnel]: 'PT01',
  [ModifierType.RowCover]: 'RC01',
};

interface iProps {
  calendarBarURL: string;
  startMonth: number;
  numberedCalendar: NumberedPlantingCalendar;
  compact?: boolean;
  highlightOnHover?: boolean;
  modifier?: null | string;
  isCustom: boolean;
  artifactCode: string;
  noMargin?: boolean;
}

const NumberedPlantingCalendarDisplay = ({
  numberedCalendar,
  calendarBarURL,
  startMonth,
  compact,
  highlightOnHover,
  modifier = null,
  isCustom = false,
  artifactCode,
  noMargin,
}: iProps): JSX.Element => {
  const months: JSX.Element[] = [];

  for (let i = 0; i < 12; i++) {
    const monthNumber = (i + startMonth - 1) % 12;
    months.push(
      <PlantingCalendarMonth
        key={`${i}_${numberedCalendar[i]}`}
        imageName={numberedCalendar[i]}
        calendarBarURL={calendarBarURL}
        monthName={compact ? SHORT_MONTH_NAMES[monthNumber] : LONG_MONTH_NAMES[monthNumber]}
        monthTitle={MONTH_TITLES[monthNumber]}
      />
    );
  }

  const modifierComponent = useMemo(() => {
    if (isCustom) {
      return (
        <div className='calendar-modifier'>
          <span className='modifier-text'>Custom</span>
        </div>
      );
    }

    if (modifier === null) {
      return null;
    }

    return (
      <div className='calendar-modifier'>
        <span className='modifier-text'>{ModifierNames[modifier]}</span>
        <span>
          <PrintablePlannerIcon artifactCode={artifactCode} code={modifierIcons[modifier]} />
        </span>
      </div>
    );
  }, [modifier, isCustom]);

  const classNames: string[] = ['planting-calendar'];
  if (highlightOnHover) {
    classNames.push('highlight-on-hover');
  }
  if (noMargin) {
    classNames.push('no-margin');
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='planting-calendar-months'>{months}</div>
      {modifierComponent}
    </div>
  );
};

export default NumberedPlantingCalendarDisplay;
