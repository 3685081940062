import { Record } from 'immutable';

const _JournalEntry = {
  ID: null,
  dateYMD: null,
  dayID: null,
  note: '',
  relatedPlants: [],
  dismissed: false,
  isTask: false,
  taskCompleted: false,
  images: [],
};

class JournalEntry extends Record(_JournalEntry) {}

export default JournalEntry;
