import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

export type WindowPortalContextType = {
  currentWindow: Window | null;
};

export const WindowPortalContext = createContext<WindowPortalContextType>({
  currentWindow: null,
});

interface WindowPortalProviderProps {
  children: React.ReactNode;
  currentWindow: Window | null;
}

const WindowPortalProvider = ({ children, currentWindow }: WindowPortalProviderProps): JSX.Element => {
  const value = useMemo<WindowPortalContextType>(() => {
    return {
      currentWindow,
    };
  }, [currentWindow]);

  return <WindowPortalContext.Provider value={value}>{children}</WindowPortalContext.Provider>;
};

/**
 * Copys a style/link element to the passed window by creating
 * a new tag and adding attributes to it
 */
function copyStyle(to: Window, element): void {
  const newStyle = to.document.createElement(element.tagName);
  if (element.textContent) {
    newStyle.textContent = element.textContent;
  } else if (element.innerText) {
    newStyle.innerText = element.innerText;
  }
  newStyle.type = element.type;
  newStyle.src = element.src;
  newStyle.href = element.href;
  newStyle.rel = element.rel;
  to.document.getElementsByTagName('head')[0].appendChild(newStyle);
}

interface iProps {
  title?: string;
  copyStyles?: boolean;
  children: JSX.Element;
  width?: number;
  height?: number;
  onWindowClose?: () => void;
  onWindowResize?: (width: number, height: number) => void;
  target?: string;
}

const WindowPortal = ({
  children,
  title = '',
  copyStyles = true,
  width = 600,
  height = 400,
  onWindowClose = () => {},
  onWindowResize,
  target = '',
}: iProps) => {
  const [container, setContainer] = useState<null | HTMLDivElement>(null);
  const externalWindow = useRef<Window | null>(null);

  useEffect(() => {
    const div = document.createElement('div');
    div.style.minHeight = '100%';
    setContainer(div);
  }, []);

  useEffect(() => {
    if (container === null) {
      return () => {};
    }

    externalWindow.current = window.open('', target, `width=${width},height=${height},left=200,top=200`);
    if (externalWindow.current !== null) {
      externalWindow.current.document.title = title;
      externalWindow.current.document.body.innerHTML = '';
      externalWindow.current.document.body.appendChild(container);
      externalWindow.current.onbeforeunload = () => {
        onWindowClose();
      };
      externalWindow.current.onresize = (event) => {
        if (event.target && onWindowResize) {
          const window = event.target as Window;
          onWindowResize(window.innerWidth, window.innerHeight);
        }
      };

      if (copyStyles) {
        // Get all style elements and copy them to the new document
        const stylesheets = Array.from(document.querySelectorAll('style, link[rel="stylesheet"]'));
        stylesheets.forEach((stylesheet) => {
          if (externalWindow.current !== null) {
            copyStyle(externalWindow.current, stylesheet);
          }
        });
      }
    } else {
      console.warn('External window failed to open');
    }

    return () => {
      if (externalWindow.current !== null) {
        externalWindow.current.close();
      }
    };
  }, [container]);

  if (container === null) {
    return null;
  }

  return createPortal(<WindowPortalProvider currentWindow={externalWindow.current}>{children}</WindowPortalProvider>, container);
};

export default WindowPortal;
