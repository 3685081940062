import { FormFieldOptionsPreset } from '@gi/form-responsive';

export const FieldPreset: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
    labelSize: 80,
    labelAlignY: 'center',
  },
  mobile: {
    layout: 'row',
    labelSize: 95,
    labelAlignY: 'center',
  },
};

export const FieldPresetFull: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
    labelSize: 80,
    labelAlignY: 'center',
    inputSize: 'full',
  },
  mobile: {
    layout: 'row',
    labelSize: 95,
    labelAlignY: 'center',
    inputSize: 'full',
  },
};

export const FieldPresetLabelTop: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
    labelSize: 80,
    labelAlignY: 'top',
    inputSize: 'full',
  },
  mobile: {
    layout: 'row',
    labelSize: 95,
    labelAlignY: 'top',
    inputSize: 'full',
  },
};

export const VectorFieldPreset: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
    labelSize: 80 - 16,
    labelAlignY: 'center',
  },
  mobile: {
    layout: 'row',
    labelSize: 95 - 16,
    labelAlignY: 'center',
  },
};
