const defaultSupportEmail: SupportEmail = {
  userID: 0,
  clientID: -1,
  name: '',
  emailAddress: '',
  message: '',
  platform: '',
};

export type SupportEmail = {
  userID: number;
  clientID: number;
  name: string;
  emailAddress: string;
  message: string;
  platform: string;
};

export function createSupportEmail(supportEmail: Partial<SupportEmail>): SupportEmail {
  return {
    ...defaultSupportEmail,
    ...supportEmail,
  };
}

export default SupportEmail;
