import React from 'react';

import Plant from '@gi/plant';
import { Companions, CompanionType } from '@gi/companion-plants';
import ItemContentCell from '../../../components/item-content-table/item-content-cell';
import ItemContentRow from '../../../components/item-content-table/item-content-row';
import ItemContentCellHeader from '../../../components/item-content-table/item-content-cell-header';

import styles from '../plant-content-display.module.css';

interface iCompanionsSectionProps {
  companionPlants: Plant[];
  companionType: CompanionType;
  plant: Plant;
}

const PlantCompanionsRow = ({ companionPlants, companionType, plant }: iCompanionsSectionProps): JSX.Element | null => {
  if (companionPlants.length === 0) {
    return null;
  }

  let title = '';
  if (companionType === CompanionType.Mutual) {
    title = 'Mutually beneficial';
  } else if (companionType === CompanionType.HelpedBy) {
    title = 'Benefit this plant';
  } else if (companionType === CompanionType.Helps) {
    title = 'This plant benefits';
  }

  return (
    <ItemContentRow>
      <ItemContentCellHeader className={styles.companionHeaderCell}>{title}</ItemContentCellHeader>
      <ItemContentCell>
        <Companions companionPlants={companionPlants} companionType={companionType} plant={plant} />
      </ItemContentCell>
    </ItemContentRow>
  );
};

export default PlantCompanionsRow;
