import React from 'react';
import { useSelector } from 'react-redux';

import { DefaultMobileTopbar } from '@gi/mobile-garden-platform-nav';
import { SessionSelectors } from '@gi/react-session';
import AppAccountDesktop from '@gi/app-account-desktop';

const MobileAccount = (): JSX.Element => {
  const user = useSelector(SessionSelectors.getUser);

  return (
    <>
      <DefaultMobileTopbar title='Account' subtitle={user?.email} />
      <AppAccountDesktop />
    </>
  );
};

export default MobileAccount;
