import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { VerticalNav, VerticalNavItem } from '@gi/page-layout';

import { AppAccountSelectors, AppAccountActionCreators } from '@gi/app-account-slice';
import { SessionSelectors } from '@gi/react-session';
import { UserSubscriptionUtils } from '@gi/user';

const AccountPageNavigation = (): JSX.Element => {
  const dispatch = useDispatch();
  const activePage = useSelector(AppAccountSelectors.getActivePage);
  const user = useSelector(SessionSelectors.getUser);

  const isSubscribed = useMemo(() => {
    if (!user) {
      return false;
    }
    return UserSubscriptionUtils.hasSubscription(user.subscription);
  }, [user]);

  return (
    <VerticalNav title='Account Settings Navigation'>
      <VerticalNavItem topmost onClick={() => dispatch(AppAccountActionCreators.setActivePage('account'))} active={activePage === 'account'}>
        Your Account
      </VerticalNavItem>
      <VerticalNavItem topmost onClick={() => dispatch(AppAccountActionCreators.setActivePage('settings'))} active={activePage === 'settings'}>
        Settings
      </VerticalNavItem>
      <VerticalNavItem topmost onClick={() => dispatch(AppAccountActionCreators.setActivePage('location'))} active={activePage === 'location'}>
        Location and Frost Dates
      </VerticalNavItem>
      <VerticalNavItem topmost onClick={() => dispatch(AppAccountActionCreators.setActivePage('emails'))} active={activePage === 'emails'}>
        Emails
      </VerticalNavItem>
      {isSubscribed ? (
        <VerticalNavItem topmost onClick={() => dispatch(AppAccountActionCreators.setActivePage('refer'))} active={activePage === 'refer'}>
          Friends & Family Discount
        </VerticalNavItem>
      ) : null}
    </VerticalNav>
  );
};

export default AccountPageNavigation;
