import React, { useContext } from 'react';

import { DistanceUnits } from '@gi/units';

import GlobalPlantListContext from '../global-provider/global-plant-list-context';
import { PlantListVarietyGroup } from '../types/plant-list-types';
import PlantListCardHeader from './card/plant-list-card-header';
import PlantListCardCalendar from './card/plant-list-card-calendar';
import PlantListCard from './card/plant-list-card';
import PlantListCardSpacings from './card/plant-list-card-spacings';
import PlantListCardCount from './card/plant-list-card-count';
import PlantListCardCellContainer from './card/plant-list-card-cell-container';
import PlantListCardNotes from './card/plant-list-card-notes';
import PlantListCardIndividualDetails from './card/details-table/plant-list-card-individual-details';

interface iProps {
  plantListRowGroup: PlantListVarietyGroup;
  distanceUnits: DistanceUnits;
}

const PlantListByVarietyCard = ({ plantListRowGroup, distanceUnits }: iProps): JSX.Element => {
  const { columns } = useContext(GlobalPlantListContext);

  return (
    <PlantListCard>
      <PlantListCardHeader plant={plantListRowGroup.plant} varietyName={plantListRowGroup.variety} />
      <PlantListCardCalendar plantingCalendar={plantListRowGroup.plantingCalendar} />
      <PlantListCardCellContainer>
        <PlantListCardSpacings spacings={plantListRowGroup.spacing} countsByType={plantListRowGroup.countsByType} distanceUnits={distanceUnits} />
        <PlantListCardCount count={plantListRowGroup.count} />
      </PlantListCardCellContainer>
      <PlantListCardIndividualDetails areaGroups={plantListRowGroup.areas} expandButtonText='Notes & Details' expandButtonTextOnlyChildren='Notes'>
        {columns.notes ? (
          <PlantListCardNotes plantNote={plantListRowGroup.plantNote} plant={plantListRowGroup.plant} varietyName={plantListRowGroup.variety} />
        ) : null}
      </PlantListCardIndividualDetails>
    </PlantListCard>
  );
};

export default PlantListByVarietyCard;
