import { SpriteSourceType } from './constants';

export type PathSpriteSource = {
  type: SpriteSourceType.PATH;
  width: number;
  height: number;
  names: string[];
};

export function getPathSpriteSourceCount(pathSpriteSource: PathSpriteSource): number {
  return pathSpriteSource.names.length;
}

export function createPathSpriteSource(pathSpriteSource: Omit<PathSpriteSource, 'type'>): PathSpriteSource {
  return {
    ...pathSpriteSource,
    type: SpriteSourceType.PATH,
  };
}
