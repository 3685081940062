import Bitmask, { BitmaskType } from '@gi/bitmask';
import { State, StateDef } from '@gi/state';

import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import DraggableComponent, { DraggableComponentState } from '../draggable/draggable-component';
import ManipulatableComponent, { ManipulatableComponentState } from '../manipulatable/manipulatable-component';
import { bindState } from '../../utils/state-utils';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { hasEngine } from '../../utils/asserts';
import VisibilityComponentContext from './visibility-component-context';
import SelectableComponent, { UnselectableFlag } from '../selectable/selectable-component';

export enum HiddenFlag {
  VISIBILITY = 1, // Hidden because visible is false
  PLANT_LABEL = 2, // Hidden because showLabel is off
  DRAWING_PREVIEW = 3, // Hidden because it's a drawing preview, and a draw is taking place
}

export type VisibilityComponentState = StateDef<
  {
    isInViewport: boolean;
    hiddenFlags: BitmaskType<HiddenFlag>;
  },
  [],
  {
    draggable: DraggableComponentState;
    manipulatable: ManipulatableComponentState;
  }
>;

const DEFAULT_STATE: VisibilityComponentState['state'] = {
  isInViewport: true,
  hiddenFlags: Bitmask.Create(),
};

/**
 * Visibility Component
 *  Controls the visibility of the node it's attached to.
 *  Watches for changes in visible area and dragging/manipulating state to only render when needed.
 *  Can be hidden by adding a hidden flag. Can only render if there are no hidden flags.
 */
class VisibilityComponent extends NodeComponent {
  type = 'VisibilityComponent';

  readonly state: State<VisibilityComponentState>;

  #selectableComponent: SelectableComponent | null = null;

  constructor() {
    super();

    this.state = new State({ ...DEFAULT_STATE });
    bindState(this.state, this);

    this.state.addWatcher(this.updateVisibility, {
      properties: ['hiddenFlags', 'isInViewport'],
      otherStates: {
        draggable: { properties: ['dragging'] },
        manipulatable: { properties: ['manipulating'] },
      },
    });

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);

    this.owner.getContext(VisibilityComponentContext).register(this as VisibilityComponent);

    this.#selectableComponent = this.owner.components.get(SelectableComponent);

    const draggable = this.owner?.components.get(DraggableComponent);
    if (draggable) {
      this.state.connectState('draggable', draggable.state);
    }

    const manipulatable = this.owner?.components.get(ManipulatableComponent);
    if (manipulatable) {
      this.state.connectState('manipulatable', manipulatable.state);
    }
  };

  #onBeforeUnbind = () => {
    hasEngine(this);

    this.owner.getContext(VisibilityComponentContext).unregister(this as VisibilityComponent);

    this.#selectableComponent = null;
  };

  /**
   * Returns true if this component has any hidden flags.
   * May still not render if returns false, as may not be in viewport.
   */
  get shouldHide() {
    return Bitmask.ContainsAny(this.state.values.hiddenFlags, Bitmask.ALL.value);
  }

  /**
   * Returns true if this component is being rendered.
   */
  get isVisible() {
    const visible = this.state.values.isInViewport;
    const dragging = this.state.get('draggable', 'dragging');
    const manipulating = this.state.get('manipulatable', 'manipulating');

    const shouldShow = !!visible || !!manipulating || !!dragging;

    return shouldShow && !this.shouldHide;
  }

  updateVisibility = () => {
    if (!this.owner) {
      return;
    }

    this.owner.visible = this.isVisible;
    if (this.#selectableComponent) {
      this.#selectableComponent.state.values.unselectableFlags = Bitmask.Set(
        this.#selectableComponent.state.values.unselectableFlags,
        this.shouldHide,
        UnselectableFlag.VISIBILITY
      );
    }
  };

  /**
   * Adds a flag saying this node should be hidden.
   * @param flagName The name of the flag
   */
  addHiddenFlag(flagName: HiddenFlag) {
    this.state.values.hiddenFlags = Bitmask.Add(this.state.values.hiddenFlags, flagName);
  }

  /**
   * Removes a flag that hides this node.
   * @param flagName The name of the flag
   */
  removeHiddenFlag(flagName: HiddenFlag) {
    this.state.values.hiddenFlags = Bitmask.Remove(this.state.values.hiddenFlags, flagName);
  }

  /**
   * Sets a hidden flag as on or off.
   * @param flagName The name of the flag
   * @param enabled If the flag should be added (enabled) or not
   */
  setHiddenFlag(flagName: HiddenFlag, enabled: boolean) {
    if (enabled) {
      this.addHiddenFlag(flagName);
    } else {
      this.removeHiddenFlag(flagName);
    }
  }

  /**
   * Checks if a flag with the given name has been set on this component.
   * @param flagName The flag name to find
   * @returns True if that flag name is set
   */
  hasHiddenFlag(flagName: HiddenFlag): boolean {
    return Bitmask.ContainsFlag(this.state.values.hiddenFlags, flagName);
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default VisibilityComponent;
