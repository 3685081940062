import React, { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import ImageEditorModal, { ImageUploadModal, InputRemoteImage, OutputAsyncImage, OutputImage, OutputRemoteImage, isImageDef } from '@gi/image-editor';
import { LayerDisplayModes, LoadingState } from '@gi/constants';
import { CanvasActionCreators } from '@gi/react-garden-canvas';
import Plan, { PlanBackgroundImage, PlanBackgroundImageUtils } from '@gi/plan';
import { DistanceUnits, getDistanceUnitsFromIsMetric } from '@gi/units';

import { PlanBackgroundSettings } from './types';
import { imageToDataURL } from './utils';
import { ImageFitMode, getImageDimensionsForFitMode } from './components/plan-background-image-fit-utils';
import planBackgroundImageService from './plan-background-image-service';

type PlanBackgroundImageEditorContextType = {
  plan: Plan;
  distanceUnits: DistanceUnits;
  backgroundImage: PlanBackgroundImage | null;
  updateBackgroundImage: (backgroundImage: PlanBackgroundImage | null) => void;
  removeBackgroundImage: () => void;
  setShowEditImageModal: (show: boolean) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  settings: PlanBackgroundSettings;
  setSettings: (settings: Partial<PlanBackgroundSettings>) => void;
};

export const PlanBackgroundImageEditorContext = createContext<PlanBackgroundImageEditorContextType>({} as PlanBackgroundImageEditorContextType);

interface iProps {
  plan: Plan;
  children?: ReactNode;
}

export const PlanBackgroundImageEditorProvider = ({ plan, children }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const [lastLayerMode, setLastLayerMode] = useState<LayerDisplayModes>(
    plan.plannerSettings.layer !== LayerDisplayModes.BACKGROUND_IMAGES ? plan.plannerSettings.layer : LayerDisplayModes.ALL
  );
  const [showEditImageModal, setShowEditImageModal] = useState<boolean>(false);
  const [uploadState, setUploadState] = useState<LoadingState>(LoadingState.NONE);

  const backgroundImage = useMemo(() => plan.backgroundImage ?? null, [plan.backgroundImage]);
  const distanceUnits = useMemo(() => getDistanceUnitsFromIsMetric(plan.plannerSettings.metric), [plan.plannerSettings.metric]);
  const isEditing = useMemo(() => plan.plannerSettings.layer === LayerDisplayModes.BACKGROUND_IMAGES, [plan.plannerSettings.layer]);
  const settings = useMemo<PlanBackgroundSettings>(() => {
    return {
      opacity: plan.plannerSettings.backgroundImageOpacity,
      visible: plan.plannerSettings.showBackgroundImages,
      maintainAspectRatio: plan.plannerSettings.maintainBackgroundImageAspectRatio,
    };
  }, [plan]);

  /** Set some (or all) of the plan background image settings for the current plan */
  const setSettings = useCallback(
    ({ opacity, visible, maintainAspectRatio }: Partial<PlanBackgroundSettings>) => {
      dispatch(
        CanvasActionCreators.setBackgroundImageSettings(
          plan,
          visible ?? plan.plannerSettings.showBackgroundImages,
          opacity ?? plan.plannerSettings.backgroundImageOpacity,
          maintainAspectRatio ?? plan.plannerSettings.maintainBackgroundImageAspectRatio
        )
      );
    },
    [plan]
  );

  /** Changes the plan layer mode to either allow background editing or revert to what it was before */
  const setIsEditing = useCallback(
    (_isEditing: boolean) => {
      dispatch(CanvasActionCreators.setLayer(plan, _isEditing ? LayerDisplayModes.BACKGROUND_IMAGES : lastLayerMode));
    },
    [plan, lastLayerMode]
  );

  /** Update the background image for this plan (or remove it by giving null) */
  const updateBackgroundImage = useCallback(
    (_backgroundImage: PlanBackgroundImage | null) => {
      dispatch(
        CanvasActionCreators.updatePlan({
          ...plan,
          backgroundImage: _backgroundImage ?? undefined,
        })
      );
    },
    [plan]
  );

  /** Remove the current background image for the plan */
  const removeBackgroundImage = useCallback(() => {
    updateBackgroundImage(null);
  }, [updateBackgroundImage]);

  /** Store the last plan layer mode so we can switch back to it like a toggle */
  useEffect(() => {
    if (plan.plannerSettings.layer !== LayerDisplayModes.BACKGROUND_IMAGES) {
      setLastLayerMode(plan.plannerSettings.layer);
    }
  }, [plan.plannerSettings.layer]);

  /**
   * Callback to handle the user successfully choosing an image from the image editor modal
   * TODO: This will likely handle image uploading too.
   */
  const onImageEditorModalClose = useCallback(
    (image: OutputImage | OutputRemoteImage | OutputAsyncImage) => {
      if (isImageDef(image)) {
        // We've been given a local image back from the modal, upload it and update the background
        setUploadState(LoadingState.LOADING);

        const imageAsDataURL = imageToDataURL(image.image, 'image/png');
        if (imageAsDataURL === null) {
          setUploadState(LoadingState.ERROR);
          return;
        }

        const size = getImageDimensionsForFitMode({ width: plan.width, height: plan.height }, ImageFitMode.CONTAIN, image.image.width / image.image.height);

        planBackgroundImageService
          .uploadImage(plan.id, imageAsDataURL)
          .then((src) => {
            setUploadState(LoadingState.SUCCESS);
            setShowEditImageModal(false);
            updateBackgroundImage({
              src: {
                ...src,
                crop: image.crop,
              },
              position: { x: plan.width / 2, y: plan.height / 2 },
              dimensions: size,
              rotation: 0,
            });
          })
          .catch(() => {
            setUploadState(LoadingState.ERROR);
          });
      } else {
        // We've been given back the image we put in.
        setShowEditImageModal(false);
        if (backgroundImage !== null) {
          updateBackgroundImage({
            ...backgroundImage,
            src: {
              ...backgroundImage.src,
              crop: image.crop,
            },
          });
        }
      }
    },
    [updateBackgroundImage, plan.width, plan.height]
  );

  const value = useMemo<PlanBackgroundImageEditorContextType>(
    () => ({
      plan,
      distanceUnits,
      backgroundImage,
      updateBackgroundImage,
      removeBackgroundImage,
      setShowEditImageModal,
      isEditing,
      setIsEditing,
      settings,
      setSettings,
    }),
    [plan, distanceUnits, backgroundImage, updateBackgroundImage, removeBackgroundImage, isEditing, setIsEditing, settings, setSettings]
  );

  /** Image getter used by the edit image modal to handle images with auth headers */
  const imageGetter = useMemo<InputRemoteImage | undefined>(() => {
    if (!backgroundImage) {
      return undefined;
    }
    return { imageSrc: PlanBackgroundImageUtils.getImageURL(backgroundImage.src.id) };
  }, [backgroundImage?.src]);

  /** Modal to allow the user to upload new images or modify the existing image */
  const imageEditModal = useMemo(() => {
    if (!showEditImageModal) {
      return null;
    }

    return (
      <ImageEditorModal
        image={imageGetter}
        manipulationData={{ crop: backgroundImage ? backgroundImage.src.crop : null }}
        onCancel={() => setShowEditImageModal(false)}
        onComplete={onImageEditorModalClose}
      />
    );
  }, [showEditImageModal, onImageEditorModalClose, imageGetter]);

  /** Modal that shows when an image is uploading. Automatically closes on success */
  const imageUploadModal = useMemo(() => {
    const isError = uploadState === LoadingState.ERROR;
    const onCancel = isError ? () => setUploadState(LoadingState.NONE) : undefined;

    switch (uploadState) {
      case LoadingState.LOADING:
      case LoadingState.ERROR:
        return <ImageUploadModal status={uploadState} onComplete={() => setUploadState(LoadingState.NONE)} onCancel={onCancel} />;
      case LoadingState.NONE:
      case LoadingState.SUCCESS:
      default:
        return null;
    }
  }, [uploadState]);

  return (
    <PlanBackgroundImageEditorContext.Provider value={value}>
      {children}
      {imageEditModal}
      {imageUploadModal}
    </PlanBackgroundImageEditorContext.Provider>
  );
};
