import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CanvasSelectors } from '@gi/react-garden-canvas';
import { ModalActionCreators } from '@gi/garden-platform-modal-renderer';
import { useMobileNotificationPortal } from '@gi/notifications';
import { OpenPlanModalActionCreators } from '@gi/open-plan-modal';
import { MobileNavActionCreators } from '@gi/mobile-garden-platform-nav';
import IntercomIcon from '@gi/intercom-icon';

import './mobile-planner-launcher.scss';

const MobilePlannerLauncher = (): JSX.Element | null => {
  const activePlan = useSelector(CanvasSelectors.getActivePlanID);
  const notificationTarget = useMobileNotificationPortal<HTMLDivElement>();
  const dispatch = useDispatch();

  const openOpenPlanModal = () => {
    dispatch(OpenPlanModalActionCreators.open());
  };

  const openNewPlanModal = () => {
    dispatch(ModalActionCreators.openNewPlanModal());
  };

  const openHelpDropdown = () => {
    dispatch(MobileNavActionCreators.setHelpDropdownOpen(true));
  };

  if (activePlan) {
    return null;
  }

  return (
    <div className='mobile-planner-launcher-container' ref={notificationTarget}>
      <div className='mobile-planner-launcher'>
        <h2>Garden Planner</h2>
        <p>To get started, open one of your plans, or create a new one.</p>
        <button className='button button-primary-light' type='button' onClick={openOpenPlanModal}>
          Open Plan
        </button>
        <button className='button button-primary-light' type='button' onClick={openNewPlanModal}>
          Create New Plan
        </button>
        <button className='button button-intercom' type='button' onClick={openHelpDropdown}>
          <IntercomIcon noBackground useCurrentColour />
          <span>Live Chat</span>
        </button>
      </div>
    </div>
  );
};

export default MobilePlannerLauncher;
