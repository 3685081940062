import React, { useContext } from 'react';
import ModalTabContext from './modal-tab-context';

interface iProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  tabID: string;
}

const ModalTabContent = ({ children, style = {}, className = '', tabID }: iProps): JSX.Element | null => {
  const { activeTab } = useContext(ModalTabContext);

  if (activeTab !== tabID) {
    return null;
  }

  return (
    <div className={`modal-tab-content ${className}`} style={style}>
      {children}
    </div>
  );
};

export default ModalTabContent;
