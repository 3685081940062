import React from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import Plan from '@gi/plan';
import { LayerDisplayModes } from '@gi/constants';

import * as CanvasActionCreators from '../../redux-components/canvas-action-creators';
import * as CanvasSelectors from '../../redux-components/canvas-selectors';
import { getTopbarSelectLockedStyles, getTopbarSelectStyles } from '../select-styles';

import './layer-selector.scss';

type LayerOption = {
  label: string;
  value: LayerDisplayModes;
  hidden?: boolean;
};

const SELECT_STYLES = getTopbarSelectStyles(134);
const LOCKED_SELECT_STYLES = getTopbarSelectLockedStyles(134);

const LAYER_OPTIONS: LayerOption[] = [
  { label: 'Edit All', value: LayerDisplayModes.ALL },
  { label: 'View all', value: LayerDisplayModes.VIEW_ONLY },
  { label: 'Structures', value: LayerDisplayModes.STRUCTURES },
  { label: 'Plants', value: LayerDisplayModes.PLANTS },
  { label: 'Text', value: LayerDisplayModes.TEXT },
  { label: 'Irrigation', value: LayerDisplayModes.IRRIGATION },
  { label: 'Layout', value: LayerDisplayModes.LAYOUT },
  { label: 'Locked Items', value: LayerDisplayModes.LOCKED_ITEMS },
  { label: 'Background Image', value: LayerDisplayModes.BACKGROUND_IMAGES, hidden: true },
];

function getLabelForValue(value: LayerDisplayModes): string {
  for (let i = 0; i < LAYER_OPTIONS.length; i++) {
    if (LAYER_OPTIONS[i].value === value) {
      return LAYER_OPTIONS[i].label;
    }
  }

  console.warn('Text for invalid label value requested');

  return '';
}

function getLayout(plan: Plan | null): LayerOption {
  if (plan === null) {
    return { label: getLabelForValue(LAYER_OPTIONS[0].value), value: LAYER_OPTIONS[0].value };
  }

  if (plan.plannerSettings.layer) {
    return {
      label: getLabelForValue(plan.plannerSettings.layer),
      value: plan.plannerSettings.layer,
    };
  }

  return { label: getLabelForValue(LAYER_OPTIONS[0].value), value: LAYER_OPTIONS[0].value };
}

interface iFormatLayerMenuOptionLabelProps {
  label: string;
  value: string;
}

const FormatLayerMenuOptionLabel = ({ label, value }: iFormatLayerMenuOptionLabelProps): JSX.Element | string => {
  if (value === LayerDisplayModes.VIEW_ONLY) {
    return (
      <>
        <i className='icon-lock' /> {label}
      </>
    );
  }

  if (value === LayerDisplayModes.ALL) {
    return (
      <>
        <i className='icon-lock-open' /> {label}
      </>
    );
  }

  return label;
};

interface iProps {
  hideLabel?: boolean;
  menuPlacement?: MenuPlacement;
  attachToBody?: boolean;
  id?: string;
}

const LayerSelector = ({ hideLabel, menuPlacement, attachToBody = false, id }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const plan = useSelector(CanvasSelectors.getActivePlan);

  const onChange = (v) => {
    if (plan !== null) {
      dispatch(CanvasActionCreators.setLayer(plan, v.value));
    }
  };

  let styles = SELECT_STYLES;

  if (plan !== null && plan.plannerSettings.layer === LayerDisplayModes.VIEW_ONLY) {
    styles = LOCKED_SELECT_STYLES;
  }

  const selectComponent = (
    <ReactSelect<iFormatLayerMenuOptionLabelProps>
      isDisabled={plan === null}
      styles={styles}
      options={LAYER_OPTIONS.filter((option) => !option.hidden)}
      value={getLayout(plan)}
      onChange={onChange}
      formatOptionLabel={FormatLayerMenuOptionLabel}
      menuPortalTarget={attachToBody ? document.body : undefined}
      menuPlacement={menuPlacement}
      inputId={id}
      isSearchable={false}
    />
  );

  if (hideLabel) {
    return selectComponent;
  }

  return (
    <div className='layer-selector'>
      <span>Layer </span>
      <div>{selectComponent}</div>
    </div>
  );
};

export default LayerSelector;
