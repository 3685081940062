import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingButton from '@gi/loading-button';
import { AppContext } from '@gi/app-provider';
import { PageSection } from '@gi/page-layout';
import { ErrorScreen } from '@gi/loading-screen';
import { networkConfig } from '@gi/config';
import { SessionSelectors } from '@gi/react-session';
import { RequestSelectors } from '@gi/react-requests';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { PlanUtils, PlanSetUtils } from '@gi/plan';
import { CanvasActionCreators, CanvasSelectors } from '@gi/react-garden-canvas';

import styles from './error-view.module.scss';

interface iErrorPlanProps {
  planID: number;
}

const ErrorPlan = ({ planID }: iErrorPlanProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const requests = useSelector(RequestSelectors.getRequests);
  const plans = useSelector(CanvasSelectors.getPlans);
  const lastSavePlans = useSelector(CanvasSelectors.getLastSavePlans);

  const plan = PlanSetUtils.planSetGetPlan(plans, planID);
  const lastSavedPlan = PlanSetUtils.planSetGetPlan(lastSavePlans, planID);

  const hasUnsavedChanges = useMemo(() => {
    if (plan !== null && lastSavedPlan !== null) {
      return !PlanUtils.plansShallowEqual(plan, lastSavedPlan);
    }
    return false;
  }, [plan, lastSavedPlan]);

  const saving = useMemo(() => {
    return RequestsUtils.getStatus(requests, `SAVE_PLAN_${planID}`) === RequestStatus.IN_PROGRESS;
  }, [requests, planID]);

  const save = useCallback(() => {
    if (plan !== null) {
      dispatch(CanvasActionCreators.savePlan(plan));
    }
  }, [plan]);

  const planAction = useMemo(() => {
    if (!hasUnsavedChanges) {
      return (
        <span>
          <i className='icon-ok' /> No unsaved changes
        </span>
      );
    }
    return (
      <LoadingButton buttonIcon='icon-floppy' className='button-primary' loading={saving} onClick={save}>
        Save Changes
      </LoadingButton>
    );
  }, [saving, hasUnsavedChanges]);

  if (plan === null || lastSavedPlan === null) {
    return null;
  }

  return (
    <div className={styles.planRow}>
      <div className={styles.details}>
        <span>
          <span className={styles.name}>{plan.name}</span>
          <span className={styles.year}> - {plan.year}</span>
        </span>
        <span className={styles.dots} />
      </div>
      <div className={styles.action}>{planAction}</div>
    </div>
  );
};

const ErrorView = (): JSX.Element => {
  const { runtimeConfig } = useContext(AppContext);

  const openPlanIds = useSelector(CanvasSelectors.getOpenPlanIDs);
  const user = useSelector(SessionSelectors.getUser);

  const userId = user ? user.ID : '(no user)';

  return (
    <ErrorScreen centerContent={false}>
      <PageSection>
        <div className={styles.errorViewContent}>
          <h1>Something went wrong!</h1>
          <p className={styles.errorInfoText}>
            We&apos;re sorry for the inconvenience. Please be assured that our Technical Team do actively look at these reports and work to make the Garden
            Planner as reliable as possible. If you have additional information to share with us, please{' '}
            <a href={`mailto:${runtimeConfig.supportEmail}?subject=Garden Planner Crash Report ${userId} - ${VERSION}`} target='_blank' rel='noreferrer'>
              contact us
            </a>{' '}
            or fill in our{' '}
            <a target='_blank' rel='noreferrer' href={networkConfig.diagnosticPageLink}>
              diagnostics page
            </a>{' '}
            after you have saved any changes below.
          </p>
        </div>
        <div className={styles.errorViewContent}>
          <div className={styles.errorViewPlans}>
            {openPlanIds.map((planID) => (
              <ErrorPlan key={planID} planID={planID} />
            ))}
          </div>
        </div>
      </PageSection>
    </ErrorScreen>
  );
};

export default ErrorView;
