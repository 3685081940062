/**
 * @typedef {number} GardenType
 */
/**
 * Enum for the different garden types
 *
 * @readonly
 * @enum {GardenType}
 */
const GardenTypes = {
  DEFAULT: 0,
  BACK_GARDEN: 1,
  FRONT_GARDEN: 2,
  HOME_GARDEN: 3,
  COMMUNITY_GARDEN: 4,
  ALLOTMENT: 5,
  CSA: 6,
  WORK_GARDEN: 7,
  FARM: 8,
  OTHER: 9,
  SCHOOL: 10,
};

export default GardenTypes;
