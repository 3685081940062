import React, { useContext } from 'react';
import { GuruDirectoryPageId } from '@gi/app-guru-types';
import { getComposedPageId } from '@gi/app-guru-common';

import DirectoryPageContainer from '../../components/directory-page/directory-page-container';
import DirectoryPageSections from '../../components/directory-page/directory-page-sections';
import DirectoryPageHeader from '../../components/directory-page/directory-page-header';
import DirectoryPageContentSection from '../../components/directory-page/directory-page-content-section';
import DirectoryPage from '../../components/directory-page/directory-page';
import { GardenGuruDataContext } from '../../context/garden-guru-data-context';

interface iProps {
  guruPageId: GuruDirectoryPageId;
  usePaginatedGrids?: boolean;
  usePaginatedRows?: boolean;
}

const DefaultDirectoryPage = ({ guruPageId, usePaginatedGrids = true, usePaginatedRows = true }: iProps): JSX.Element => {
  const { guruPages } = useContext(GardenGuruDataContext);
  const composedPageId = getComposedPageId(guruPages, guruPageId);

  return (
    <DirectoryPageContainer>
      <DirectoryPageHeader composedPageId={composedPageId} />
      <DirectoryPage>
        <DirectoryPageContentSection>
          <DirectoryPageSections composedPageId={composedPageId} usePaginatedGrids={usePaginatedGrids} usePaginatedRows={usePaginatedRows} />
        </DirectoryPageContentSection>
      </DirectoryPage>
    </DirectoryPageContainer>
  );
};

export default DefaultDirectoryPage;
