import React, { ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { GuruActionCreators } from '@gi/app-guru-slice';

import GuruSection from '../guru-section/guru-section';
import GuruSectionTitle from '../guru-section/guru-section-title';
import GuruButton from '../buttons/guru-button';

import styles from './section-error.module.css';

interface iProps {
  className?: string;
  title?: ReactNode;
  sectionId: string;
}

const SectionError = ({ title, className, sectionId }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const retry = useCallback(() => {
    dispatch(GuruActionCreators.loadSection([sectionId, true]));
  }, [sectionId]);

  return (
    <GuruSection className={className}>
      {title ? <GuruSectionTitle>{title}</GuruSectionTitle> : null}
      <div className={styles.sectionErrorContent}>
        <div>There was an error when trying to load this section, if the problem persists please contact our support</div>
        <div>
          <GuruButton onClick={retry}>Retry</GuruButton>
        </div>
      </div>
    </GuruSection>
  );
};

export default SectionError;
