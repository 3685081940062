import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SessionSelectors } from '@gi/react-session';
import { usePrevious } from '@gi/react-utils';
import { FlagRestartActions } from './flag-restart-slice';

const FlagRestartDetector = (): null => {
  const dispatch = useDispatch();
  const user = useSelector(SessionSelectors.getUser);
  const previous = usePrevious(user);

  useEffect(() => {
    if (!user || !previous) {
      return;
    }

    // Data override
    if (previous?.settings.data.userArtifactCode !== user?.settings.data.userArtifactCode) {
      dispatch(FlagRestartActions.flagRestart());
    }
  }, [user]);

  return null;
};

export default FlagRestartDetector;
