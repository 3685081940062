import React, { ReactNode } from 'react';

import styles from './icon-styles.module.css';

interface iProps {
  src?: string;
  className?: string;
  children?: ReactNode;
  size?: number;
}

const BaseObjectiveIcon = ({ src, children, className, size = 50 }: iProps): JSX.Element => {
  const classNames: string[] = [styles.iconContainer];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')} style={{ '--icon-size': `${size}px` } as React.CSSProperties}>
      {src ? <img src={src} alt='' /> : null}
      {children}
    </div>
  );
};

export default BaseObjectiveIcon;
