import React, { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';

import Plant from '@gi/plant';
import { User } from '@gi/user';
import Collection from '@gi/collection';
import TemplatePlan from '@gi/template-plan';
import GardenObject from '@gi/garden-object';
import { AppContext } from '@gi/app-provider';
import { Catalogue, Suppliers } from '@gi/supplier';
import { CountryRegionCollection } from '@gi/country-region';
import { CountryCollection, getCountryData } from '@gi/country';
import { AsyncResource, useAsyncResource } from '@gi/react-utils';
import { isLoadingStateSuccess, LoadingState } from '@gi/constants';
import { UserRegionalPlantingCalendar } from '@gi/planting-calendar';
import { Pest } from '@gi/pest';
import { GuruPages } from '@gi/app-guru-types';
import { TutorialContext, Tutorials } from '@gi/tutorial';
import { ObjectiveDataFromAPI } from '@gi/objectives';
import { ParsedTooltips, TooltipContext } from '@gi/tooltip';
import { networkConfig } from '@gi/config';
import { Store } from '@gi/products';
import { FeaturesContext } from '@gi/features';

import ResourceProvider from './resource-provider';
import RegionZeroErrorScreen from './region-zero-error-screen';
import { mergeDataLocationOverrides } from './utils/modify-data-config';
import ResourceLoaderLoadingScreen from './resource-loader-loading-screen';
import { getOverallLoadingScreenResourceState, LoadingScreenResource } from './utilities';
import {
  loadCatalogueData,
  loadCountryData,
  loadCountryRegionData,
  loadGardenObjectData,
  loadGuruPages,
  loadObjectives,
  loadPestData,
  loadPlantData,
  loadProducts,
  loadRegionalPlantingDatesData,
  loadSpritesheets,
  loadSuppliersData,
  loadTemplatePlans,
  loadTooltips,
  loadTutorials,
  ParsedGardenObjectsData,
  SpritesheetURLs,
} from '../resource-loaders';

function getArtifactCode(defaultArtifactCode: string, userArtifactCode: string | null, availableArtifactCodes: string[]) {
  if (userArtifactCode === null) {
    return defaultArtifactCode;
  }

  if (availableArtifactCodes.includes(userArtifactCode)) {
    return userArtifactCode;
  }

  return defaultArtifactCode;
}

type ResourceLoaderContextType = {
  plants: AsyncResource<Collection<Plant>, Error | ClientError>;
  gardenObjects: AsyncResource<ParsedGardenObjectsData, Error | ClientError>;
  pests: AsyncResource<Collection<Pest>, Error | ClientError>;
  countries: AsyncResource<CountryCollection, Error | ClientError>;
  countryRegions: AsyncResource<CountryRegionCollection, Error | ClientError>;
  regionalPlantingCalendar: AsyncResource<UserRegionalPlantingCalendar, Error | ClientError>;
  catalogue: AsyncResource<Catalogue, Error | ClientError>;
  suppliers: AsyncResource<Suppliers, Error | ClientError>;
  templatePlans: AsyncResource<TemplatePlan[], Error | ClientError>;
  spritesheets: AsyncResource<SpritesheetURLs, Error | ClientError>;
  guruPages: AsyncResource<GuruPages, Error | ClientError>;
  objectives: AsyncResource<ObjectiveDataFromAPI, Error | ClientError>;
  tooltips: AsyncResource<ParsedTooltips, Error | ClientError>;
  storeResource: AsyncResource<Store, Error | ClientError> | null;
};

export const ResourceLoaderContext = createContext<ResourceLoaderContextType>({} as ResourceLoaderContextType);

export interface ResourceLoaderProps {
  user: User;
  initialPlants?: Collection<Plant>;
  initialGardenObjects?: Collection<GardenObject>;
  initialCountries?: CountryCollection;
  initialCountryRegions?: CountryRegionCollection;
  initialRegionalPlantingCalendar?: UserRegionalPlantingCalendar;
  initialCatalogue?: Catalogue;
  initialSuppliers?: Suppliers;
  initialTemplatePlans?: TemplatePlan[];
  children: ReactNode;
}

const ResourceLoader = ({
  user,
  initialPlants,
  initialGardenObjects,
  initialCountries,
  initialCountryRegions,
  initialRegionalPlantingCalendar,
  initialCatalogue,
  initialSuppliers,
  initialTemplatePlans,
  children,
}: ResourceLoaderProps): JSX.Element => {
  const { runtimeConfig } = useContext(AppContext);
  const { featureEnabled } = useContext(FeaturesContext);

  const networkDataLocations = useMemo(() => {
    return mergeDataLocationOverrides(networkConfig, runtimeConfig.dataURLOverrides);
  }, [runtimeConfig]);

  const shoppingEnabled = useMemo(() => featureEnabled('SHOPPING'), []);

  const { setTutorials } = useContext(TutorialContext);
  const { setTooltipContent } = useContext(TooltipContext);

  const { defaultArtifactCode, useRegionalPlanting, availableArtifactCodes } = getCountryData(user.countryCode);

  const userArtifactCode = useMemo(() => {
    return getArtifactCode(defaultArtifactCode, user.settings.data.userArtifactCode, availableArtifactCodes);
  }, [defaultArtifactCode, user.settings.data.userArtifactCode, availableArtifactCodes]);

  const plants = useAsyncResource<Collection<Plant>, Error | ClientError>(() => loadPlantData(networkDataLocations, userArtifactCode), {
    initialValue: initialPlants,
  });
  const gardenObjects = useAsyncResource<ParsedGardenObjectsData, Error | ClientError>(() => loadGardenObjectData(networkDataLocations, userArtifactCode), {
    initialValue: initialGardenObjects ? { gardenObjects: initialGardenObjects, gardenObjectGroups: new Collection() } : undefined,
  });
  const countries = useAsyncResource<CountryCollection, Error | ClientError>(() => loadCountryData(networkDataLocations), {
    initialValue: initialCountries,
  });
  const countryRegions = useAsyncResource<CountryRegionCollection, Error | ClientError>(() => loadCountryRegionData(networkDataLocations), {
    initialValue: initialCountryRegions,
  });
  const regionalPlantingCalendar = useAsyncResource<UserRegionalPlantingCalendar, Error | ClientError>(
    () => loadRegionalPlantingDatesData(networkDataLocations, defaultArtifactCode, user.settings.location.regionID),
    {
      initialValue: initialRegionalPlantingCalendar,
      loadOnStart: useRegionalPlanting,
    }
  );
  const catalogue = useAsyncResource<Catalogue, Error | ClientError>(() => loadCatalogueData(networkDataLocations, runtimeConfig.catalogueFile), {
    initialValue: initialCatalogue,
  });
  const suppliers = useAsyncResource<Suppliers, Error | ClientError>(() => loadSuppliersData(networkDataLocations, runtimeConfig.supplierFile), {
    initialValue: initialSuppliers,
  });
  const templatePlans = useAsyncResource<TemplatePlan[], Error | ClientError>(() => loadTemplatePlans(), { initialValue: initialTemplatePlans });
  const spritesheets = useAsyncResource<SpritesheetURLs, Error | ClientError>(() => loadSpritesheets(defaultArtifactCode));
  const pests = useAsyncResource<Collection<Pest>, Error | ClientError>(() =>
    loadPestData(networkDataLocations, user.settings.data.pestArtifactCode ? user.settings.data.pestArtifactCode : 'us')
  );
  const guruPages = useAsyncResource<GuruPages, Error | ClientError>(() => loadGuruPages());
  const tutorials = useAsyncResource<Tutorials, Error | ClientError>(() => loadTutorials(networkDataLocations));
  const objectives = useAsyncResource<ObjectiveDataFromAPI, Error | ClientError>(() => loadObjectives(networkDataLocations));
  const tooltips = useAsyncResource<ParsedTooltips, Error | ClientError>(() => loadTooltips(networkDataLocations));
  const storeResource = useAsyncResource<Store, Error | ClientError>(() => loadProducts(networkDataLocations, runtimeConfig.clientID), {
    loadOnStart: shoppingEnabled,
  });

  /**
   * Reload all artifact-specific resources.
   * Currently, the user can't change country without a reload, so this isn't needed.
   */
  // useEffect(() => {
  //   plants.load();
  //   gardenObjects.load();
  //   if (useRegionalPlanting) {
  //     regionalPlantingCalendar.load();
  //   }
  //   spritesheets.load();
  // }, [artifactCode]);

  /**
   * True if the user should use regional planting but hasn't got a valid region ID.
   */
  const invalidRegionID = useMemo(() => {
    const { regionID } = user.settings.location;
    return useRegionalPlanting && (regionID === 0 || regionID === null);
  }, [user, useRegionalPlanting]);

  /**
   * A list of all the required resources, with a label attached for the loading screen.
   */
  const allResources = useMemo<LoadingScreenResource[]>(() => {
    const list: LoadingScreenResource[] = [
      { label: 'Plants', resource: plants },
      { label: 'Garden Objects', resource: gardenObjects },
      { label: 'Countries', resource: countries },
    ];
    if (useRegionalPlanting) {
      list.push({ label: 'Regional Planting Dates', resource: regionalPlantingCalendar });
    }
    list.push(
      { label: 'Catalog', resource: catalogue },
      { label: 'Suppliers', resource: suppliers },
      { label: 'Pests', resource: pests },
      { label: 'Tutorials', resource: tutorials },
      { label: 'Objectives', resource: objectives },
      { label: 'Tooltips', resource: tooltips }
    );

    if (shoppingEnabled) {
      list.push({ label: 'Products', resource: storeResource });
    }

    return list;
  }, [
    plants,
    gardenObjects,
    countries,
    useRegionalPlanting,
    regionalPlantingCalendar,
    catalogue,
    suppliers,
    pests,
    tutorials,
    objectives,
    tooltips,
    shoppingEnabled,
    storeResource,
  ]);

  useEffect(() => {
    if (tutorials.status === LoadingState.SUCCESS) {
      setTutorials(tutorials.value);
    }
  }, [tutorials]);

  useEffect(() => {
    if (tooltips.status === LoadingState.SUCCESS) {
      setTooltipContent(tooltips.value);
    }
  }, [tooltips]);

  /**
   * The current status of all the resources. Will be SUCCESS once all have loaded.
   */
  const overallStatus = useMemo(() => {
    return getOverallLoadingScreenResourceState(allResources);
  }, [allResources]);

  /**
   * If everything is successful, populates the resource provider and renders the children.
   * Else, renders the loading screen.
   */
  const content = useMemo(() => {
    if (invalidRegionID) {
      return <RegionZeroErrorScreen />;
    }
    if (overallStatus === LoadingState.SUCCESS) {
      // These are already SUCCESS, as asserted by the condition above. However, TS doesn't know that yet.
      isLoadingStateSuccess(plants.status);
      isLoadingStateSuccess(gardenObjects.status);
      isLoadingStateSuccess(countries.status);
      isLoadingStateSuccess(catalogue.status);
      isLoadingStateSuccess(suppliers.status);
      isLoadingStateSuccess(pests.status);
      isLoadingStateSuccess(tooltips.status);

      let regionalPlanting: UserRegionalPlantingCalendar | null = null;
      if (useRegionalPlanting) {
        isLoadingStateSuccess(regionalPlantingCalendar.status);
        regionalPlanting = regionalPlantingCalendar.value;
      }

      let store: Store | null = null;
      if (shoppingEnabled) {
        isLoadingStateSuccess(storeResource.status);
        store = storeResource.value;
      }

      return (
        <ResourceProvider
          user={user}
          pests={pests.value}
          plants={plants.value}
          gardenObjects={gardenObjects.value.gardenObjects}
          gardenObjectGroups={gardenObjects.value.gardenObjectGroups}
          countries={countries.value}
          regionalPlantingCalendar={regionalPlanting}
          catalogue={catalogue.value}
          suppliers={suppliers.value}
          userArtifactCode={userArtifactCode}
          guruPages={guruPages.status === LoadingState.SUCCESS ? guruPages.value : null}
          store={store}
        >
          {children}
        </ResourceProvider>
      );
    }
    return <ResourceLoaderLoadingScreen resources={allResources} />;
  }, [
    allResources,
    user,
    invalidRegionID,
    overallStatus,
    plants,
    gardenObjects,
    countries,
    pests,
    useRegionalPlanting,
    regionalPlantingCalendar,
    catalogue,
    suppliers,
    userArtifactCode,
    guruPages,
    storeResource,
  ]);

  const value = useMemo<ResourceLoaderContextType>(
    () => ({
      pests,
      plants,
      gardenObjects,
      countries,
      countryRegions,
      regionalPlantingCalendar,
      catalogue,
      suppliers,
      templatePlans,
      spritesheets,
      guruPages,
      objectives,
      tooltips,
      storeResource,
    }),
    [
      pests,
      plants,
      gardenObjects,
      countries,
      countryRegions,
      regionalPlantingCalendar,
      catalogue,
      suppliers,
      templatePlans,
      spritesheets,
      guruPages,
      objectives,
      tooltips,
      storeResource,
    ]
  );

  return <ResourceLoaderContext.Provider value={value}>{content}</ResourceLoaderContext.Provider>;
};

export default ResourceLoader;
