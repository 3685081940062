import Bitmask from '@gi/bitmask';
import { SimulatedGardenItem } from '../../simulated-garden-item';
import CollisionSet from '../collision-set';
import type CollisionWorld from '../collision-world';

/**
 * A list of potential collision groups a collider might be a part of.
 * Used for masking collisions.
 */
export enum CollisionGroupFlag {
  PLANT = 1,
  GARDEN_OBJECT = 2,
  SEASON_EXTENDER = 3,
}

/**
 * A list of potential collision checks to perform.
 * This is unlikely to ever grow beyond contains and contained by, but if we ever just wanted a
 *  "touching" check, it could be added here.
 */
export enum CollisionCheckFlag {
  CONTAINS = 1,
  CONTAINED_BY = 2,
}

// Colliders need a unique ID. We could re-use the simulated garden items ID?
let COLLIDER_ID: number = 0;

/**
 * Generic collider class
 * Should be extended to create actual colliders.
 *
 * TODO: Simplify this
 * I've over-complicated the collision masks system to allow for complicated one-way collisions
 *  and expansion into other types of collisions. In practice, we're never going to use this and its
 *  just going to cause confusion.
 */
abstract class Collider {
  readonly id: number = COLLIDER_ID++;
  readonly owner: SimulatedGardenItem;

  // A set of all the colliders this collider contains
  readonly contains: CollisionSet<Collider> = new CollisionSet((collider) => collider.id);
  // A set of all the colliders this collider is contained within
  readonly containedBy: CollisionSet<Collider> = new CollisionSet((collider) => collider.id);

  #world: CollisionWorld | null = null;
  // Returns the collision world this collider is a part of
  get world() {
    return this.#world;
  }

  constructor(owner: SimulatedGardenItem) {
    this.owner = owner;
    this.contains.onUpdate = this.hasUpdated;
    this.containedBy.onUpdate = this.hasUpdated;
  }

  /**
   * Sets the world this collider belongs to
   * @param world The world this collider is a part of
   */
  setWorld(world: CollisionWorld | null) {
    this.#world = world;
  }

  /**
   * A mask representing which collision groups this collider belongs to
   */
  collisionGroup: Bitmask<CollisionGroupFlag> = Bitmask.From(Bitmask.ALL);

  /**
   * A mask representing which collision groups this collider can collide with.
   */
  collisionTargets: Bitmask<CollisionGroupFlag> = Bitmask.From(Bitmask.ALL);

  /**
   * A mask representing all the collision checks that should be done for this collider when it updates.
   * @example
   * // Makes this collider check what it contains and what it is contained by whenever it updates
   * collider.collisionChecks = CollisionCheck.CONTAINS & CollisionCheck.CONTAINED_BY;
   */
  collisionChecks: Bitmask<CollisionCheckFlag> = Bitmask.From(Bitmask.ALL);

  /**
   * Return the minimum axis-aligned bounding box containing this collider (for broad-phase collision detection)
   */
  abstract getBoundingBox(): { min: Vector2; max: Vector2; center: Vector2 };

  /**
   * Return a list of points representing the edges of the collision box (for narrow-phase collision detection)
   */
  abstract getCollisionPoints(): Vector2[];

  /**
   * Checks if the given collider is fully contained within this collider
   * @param collider The collider to check
   */
  abstract doesContain(collider: Collider): boolean;

  /**
   * Checks if this collider can collide with the other collider.
   * Done by checking the collisionGroup of the target, and ensuring it is part of our collisionTarget
   * @param target The collider to check
   * @returns True if this collider can collide with the other collider
   */
  canCollideWith(target: Collider): boolean {
    return target.collisionGroup.containsAny(this.collisionTargets);
  }

  /**
   * Updates the collisions for this collider.
   * Will only update the collision checks present in `this.collisionChecks`.
   */
  updateCollisions() {
    if (this.world === null) {
      return;
    }
    if (this.collisionChecks.equals(Bitmask.NONE)) {
      return;
    }

    const collisions = this.world.getCollisions(this);

    const checkContains = this.collisionChecks.contains(CollisionCheckFlag.CONTAINS);
    const checkContainedBy = this.collisionChecks.contains(CollisionCheckFlag.CONTAINED_BY);

    const newContains: Collider[] = [];
    const newContainedBy: Collider[] = [];

    for (let i = 0; i < collisions.length; i++) {
      const collider = collisions[i];

      if (checkContains && this.canCollideWith(collider)) {
        if (this.doesContain(collider)) {
          newContains.push(collider);
          collider.containedBy.add(this);
        }
      }
      if (checkContainedBy && collider.canCollideWith(this)) {
        if (collider.doesContain(this)) {
          newContainedBy.push(collider);
          collider.contains.add(this);
        }
      }
    }

    if (checkContains) {
      if (newContains.length > 0) {
        console.debug(`\t🍏 Collider ${this.id} is covering ${newContains.length} collider${newContains.length === 1 ? '' : 's'}`);
      }
      const { removed } = this.contains.set(newContains);
      for (let i = 0; i < removed.length; i++) {
        removed[i].containedBy.remove(this);
      }
    }
    if (checkContainedBy) {
      if (newContainedBy.length > 0) {
        console.debug(`\t🍏 Collider ${this.id} is covered by ${newContainedBy.length} collider${newContainedBy.length === 1 ? '' : 's'}`);
      }
      const { removed } = this.containedBy.set(newContainedBy);
      for (let i = 0; i < removed.length; i++) {
        removed[i].contains.remove(this);
      }
    }

    this.hasUpdated();
  }

  /**
   * Tells the world that this collider has been updated (usually by another collider updating)
   */
  protected hasUpdated = () => {
    this.world?.notifyHasUpdated(this.id);
  };

  /**
   * Tells the world that this collider has updated and needs its collision checks recalculated (usually due to changing position)
   */
  protected needsUpdate = () => {
    this.world?.notifyNeedsUpdate(this.id);
  };
}

export default Collider;
