const HorizontalAlignTypes = ['left', 'center', 'right', 'stretch'] as const;
export type HorizontalAlign = (typeof HorizontalAlignTypes)[number];

const VerticalAlignTypes = ['top', 'center', 'bottom', 'stretch'] as const;
export type VerticalAlign = (typeof VerticalAlignTypes)[number];

const SizeTypes = ['auto', 'full'] as const;
export type SizeType = (typeof SizeTypes)[number];

const ResizeTypes = ['vertical', 'horizontal', 'both'];
export type ResizeType = (typeof ResizeTypes)[number];

export interface LayoutPreset<T> {
  mobile: Partial<T>;
  desktop: Partial<T>;
}

// https://stackoverflow.com/a/72790170
// Omit loses union typing, this will not.
export type DOmit<T, K extends string> = T extends any ? Omit<T, K> : never;

/**
 * Converts the size to a CSS string
 * @param size The size
 * @returns A css size
 */
export function convertSizeToCSS(size: SizeType | number) {
  if (typeof size === 'number') {
    return `${size}px`;
  }
  if (size === 'full') {
    return '100%';
  }
  return size;
}

/**
 * Generates a layout preset that uses the same options for both mobile and desktop.
 * @param options The layout options
 * @returns A layout preset
 */
export function unresponsivePreset<T>(options: Partial<T>): LayoutPreset<T> {
  return {
    mobile: options,
    desktop: options,
  };
}
