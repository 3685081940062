import React from 'react';
import PropTypes from 'prop-types';

import './form.scss';

/**
 * @param {{
 *  className?: string,
 *  children?: import('react').ReactNode,
 *  disabled?: boolean,
 *  icon?: string | null,
 * }}
 */
const TextFormRow = ({ className = '', disabled = false, icon = null, children = null }) => {
  return (
    <div className={`form-row-text-new ${className} ${disabled ? 'disabled-form-row' : ''}`}>
      {icon ? (
        <div className='form-row-text-icon'>
          <i className={icon} />
        </div>
      ) : null}
      <div className='form-row-text-text'>{children}</div>
    </div>
  );
};

TextFormRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
};

export default TextFormRow;
