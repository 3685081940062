import { Geometry } from '@gi/math';
import Collection from '@gi/collection';
import GardenObject from '@gi/garden-object';
import { Anchor, GardenObjectType, ShapeType, anchorAsVector } from '@gi/constants';
import { Plan } from './plan';
import { PlanPlant } from './plan-plant';
import { PlanGardenObject } from './plan-garden-object';
import { PlanShape } from './plan-shape';
import { PlanText } from './plan-text';

export enum PlanClockwiseRotationAmount {
  Rotate90 = '90',
  Rotate180 = '180',
  Rotate270 = '270',
}

/**
 * Rotates the given position 90 degrees, as if the plan has rotated.
 * @param position The position to rotate
 * @param container The width/height of the plan
 * @returns A rotated position
 */
function rotate90(position: Vector2, container: Dimensions): Vector2 {
  return {
    x: container.height - position.y,
    y: position.x,
  };
}

/**
 * Rotates the given position 180 degrees, as if the plan has rotated.
 * @param position The position to rotate
 * @param container The width/height of the plan
 * @returns A rotated position
 */
function rotate180(position: Vector2, container: Dimensions): Vector2 {
  return {
    x: container.width - position.x,
    y: container.height - position.y,
  };
}

/**
 * Rotates the given position 270 degrees, as if the plan has rotated.
 * @param position The position to rotate
 * @param container The width/height of the plan
 * @returns A rotated position
 */
function rotate270(position: Vector2, container: Dimensions): Vector2 {
  return {
    x: position.y,
    y: container.width - position.x,
  };
}

/**
 * Rotates a position as if a plan has been rotated
 * @param rotationType The amount of rotation to apply
 * @param position The position to rotate
 * @param container The size of the plan
 * @returns A rotated position
 */
function rotatePoint(rotationType: PlanClockwiseRotationAmount, position: Vector2, container: Dimensions): Vector2 {
  switch (rotationType) {
    case PlanClockwiseRotationAmount.Rotate90:
      return rotate90(position, container);
    case PlanClockwiseRotationAmount.Rotate180:
      return rotate180(position, container);
    case PlanClockwiseRotationAmount.Rotate270:
      return rotate270(position, container);
    default:
      throw new Error(`Unrecognised rotation type: ${rotationType}`);
  }
}

/**
 * Rotates a relate position/offset (e.g. plant label offset).
 * @param rotationType The amount of rotation to apply
 * @param position The relative position to rotate
 * @returns A rotated position
 */
function rotateRelativePoint(rotationType: PlanClockwiseRotationAmount, position: Vector2): Vector2 {
  switch (rotationType) {
    case PlanClockwiseRotationAmount.Rotate90:
      return { x: -position.y, y: position.x };
    case PlanClockwiseRotationAmount.Rotate180:
      return { x: -position.x, y: -position.y };
    case PlanClockwiseRotationAmount.Rotate270:
      return { x: position.y, y: -position.x };
    default:
      throw new Error(`Unrecognised rotation type: ${rotationType}`);
  }
}

/**
 * Rotates a rotation
 * @param rotationType The amount of rotation to apply
 * @param rotation The rotation to modify
 * @returns A new rotation
 */
function rotateRotation(rotationType: PlanClockwiseRotationAmount, rotation: number): number {
  switch (rotationType) {
    case PlanClockwiseRotationAmount.Rotate90:
      return (rotation + Math.PI / 2) % (Math.PI * 2);
    case PlanClockwiseRotationAmount.Rotate180:
      return (rotation + Math.PI) % (Math.PI * 2);
    case PlanClockwiseRotationAmount.Rotate270:
      return (rotation + (Math.PI * 3) / 2) % (Math.PI * 2);
    default:
      throw new Error(`Unrecognised rotation type: ${rotationType}`);
  }
}

/**
 * Unique logic for box-based objects, as they're stored weird.
 *
 * We need to rotate the center point, but maintain the distance between start/end, as this defines the
 *  width/height of the box. We then use the rotation field down the road to actually rotate the object.
 * @param rotationType The amount of rotation to apply
 * @param start The start position of the box
 * @param end The end position of the box
 * @param container The size of the plan
 * @returns
 */
function rotateBox(rotationType: PlanClockwiseRotationAmount, start: Vector2, end: Vector2, container: Dimensions): { start: Vector2; end: Vector2 } {
  // Ensure whole number on midpoint so start/end don't need rounding.
  const mid = Geometry.roundPoint(Geometry.midpoint(start, end));
  const startOffset = Geometry.getPointDelta(mid, start);
  const endOffset = Geometry.getPointDelta(mid, end);
  const rotatedMid = rotatePoint(rotationType, mid, container);
  return {
    start: Geometry.addPoint(rotatedMid, startOffset),
    end: Geometry.addPoint(rotatedMid, endOffset),
  };
}

export class PlanTransformUtils {
  /**
   * Immutably rotates a PlanPlant for a plan rotation
   * @param plant The PlanPlant to rotate
   * @param rotationType The rotation amount (enum)
   * @param planDimensions The width and height of the plan (before rotation applied)
   * @returns An updated copy of the PlanPlant
   */
  private static rotatePlant(plant: PlanPlant, rotationType: PlanClockwiseRotationAmount, planDimensions: Dimensions): PlanPlant {
    return {
      ...plant,
      rowStart: rotatePoint(rotationType, plant.rowStart, planDimensions),
      rowEnd: rotatePoint(rotationType, plant.rowEnd, planDimensions),
      labelOffset: rotateRelativePoint(rotationType, plant.labelOffset),
    };
  }

  /**
   * Immutably rotates a PlanGardenObject for a plan rotation
   * @param gardenObject The PlanGardenObject to rotate
   * @param isBlock Is the object a BlockGardenObject? Different rotation needed if true due to how block objects are stored.
   * @param rotationType The rotation amount (enum)
   * @param planDimensions The width and height of the plan (before rotation applied)
   * @returns An updated copy of the PlanGardenObject
   */
  private static rotateGardenObject(
    gardenObject: PlanGardenObject,
    isBlock: boolean,
    rotationType: PlanClockwiseRotationAmount,
    planDimensions: Dimensions
  ): PlanGardenObject {
    const mid = gardenObject.mid !== null ? rotatePoint(rotationType, gardenObject.mid, planDimensions) : null;

    if (isBlock) {
      const { start, end } = rotateBox(rotationType, gardenObject.start, gardenObject.end, planDimensions);

      return {
        ...gardenObject,
        start,
        mid,
        end,
        rotation: rotateRotation(rotationType, gardenObject.rotation),
      };
    }
    return {
      ...gardenObject,
      start: rotatePoint(rotationType, gardenObject.start, planDimensions),
      mid,
      end: rotatePoint(rotationType, gardenObject.end, planDimensions),
      // Path garden objects aren't affected by rotation
    };
  }

  /**
   * Immutably rotates a PlanShape for a plan rotation
   * @param shape The PlanShape to rotate
   * @param rotationType The rotation amount (enum)
   * @param planDimensions The width and height of the plan (before rotation applied)
   * @returns An updated copy of the PlanShape
   */
  private static rotateShape(shape: PlanShape, rotationType: PlanClockwiseRotationAmount, planDimensions: Dimensions): PlanShape {
    const isBlock = shape.type === ShapeType.RECTANGLE || shape.type === ShapeType.ELLIPSE;
    const point2 = shape.point2 !== null ? rotatePoint(rotationType, shape.point2, planDimensions) : null;

    if (isBlock) {
      const { start, end } = rotateBox(rotationType, shape.point1, shape.point3, planDimensions);

      return {
        ...shape,
        point1: start,
        point2,
        point3: end,
        rotation: rotateRotation(rotationType, shape.rotation),
      };
    }
    return {
      ...shape,
      point1: rotatePoint(rotationType, shape.point1, planDimensions),
      point2,
      point3: rotatePoint(rotationType, shape.point3, planDimensions),
      // Rotation has no effect on these shape types
    };
  }

  /**
   * Immutably rotates a PlanText for a plan rotation
   * @param text The PlanText to rotate
   * @param rotationType The rotation amount (enum)
   * @param planDimensions The width and height of the plan (before rotation applied)
   * @returns An updated copy of the PlanText
   */
  private static rotateText(text: PlanText, rotationType: PlanClockwiseRotationAmount, planDimensions: Dimensions): PlanText {
    const { start, end } = rotateBox(rotationType, text.start, text.end, planDimensions);

    return {
      ...text,
      start,
      end,
      rotation: rotateRotation(rotationType, text.rotation),
    };
  }

  /**
   * Immutably translates a PlanPlant for a plan resize
   * @param plant The PlanPlant to translate
   * @param offset The amount to translate the item by
   * @returns An updated copy of the PlanPlant
   */
  private static translatePlant(plant: PlanPlant, offset: Vector2): PlanPlant {
    return {
      ...plant,
      rowStart: Geometry.addPoint(plant.rowStart, offset),
      rowEnd: Geometry.addPoint(plant.rowEnd, offset),
    };
  }

  /**
   * Immutably translates a PlanGardenObject for a plan resize
   * @param plant The PlanGardenObject to translate
   * @param offset The amount to translate the item by
   * @returns An updated copy of the PlanGardenObject
   */
  private static translateGardenObject(gardenObject: PlanGardenObject, offset: Vector2): PlanGardenObject {
    return {
      ...gardenObject,
      start: Geometry.addPoint(gardenObject.start, offset),
      mid: gardenObject.mid !== null ? Geometry.addPoint(gardenObject.mid, offset) : null,
      end: Geometry.addPoint(gardenObject.end, offset),
    };
  }

  /**
   * Immutably translates a PlanShape for a plan resize
   * @param plant The PlanShape to translate
   * @param offset The amount to translate the item by
   * @returns An updated copy of the PlanShape
   */
  private static translateShape(shape: PlanShape, offset: Vector2): PlanShape {
    return {
      ...shape,
      point1: Geometry.addPoint(shape.point1, offset),
      point2: shape.point2 !== null ? Geometry.addPoint(shape.point2, offset) : null,
      point3: Geometry.addPoint(shape.point3, offset),
    };
  }

  /**
   * Immutably translates a PlanText for a plan resize
   * @param plant The PlanText to translate
   * @param offset The amount to translate the item by
   * @returns An updated copy of the PlanText
   */
  private static translateText(text: PlanText, offset: Vector2): PlanText {
    return {
      ...text,
      start: Geometry.addPoint(text.start, offset),
      end: Geometry.addPoint(text.end, offset),
    };
  }

  /**
   * Immutably rotates a plan and all items within the plan
   * @param plan The Plan to rotate
   * @param rotationType The rotation amount (enum)
   * @param gardenObjects The collection of garden objects (needed to identify block/path garden objects)
   * @returns An updated copy of the Plan
   */
  static rotatePlan(plan: Plan, rotationType: PlanClockwiseRotationAmount, gardenObjects: Collection<GardenObject>): Plan {
    const updatedPlan = { ...plan };

    const planDimensions: Dimensions = {
      width: plan.width,
      height: plan.height,
    };

    updatedPlan.width = rotationType !== PlanClockwiseRotationAmount.Rotate180 ? plan.height : plan.width;
    updatedPlan.height = rotationType !== PlanClockwiseRotationAmount.Rotate180 ? plan.width : plan.height;

    // Rotate plants
    updatedPlan.plants = { ...plan.plants };
    for (let i = 0; i < updatedPlan.plantIds.length; i++) {
      const plant = updatedPlan.plants[updatedPlan.plantIds[i]];

      updatedPlan.plants[updatedPlan.plantIds[i]] = PlanTransformUtils.rotatePlant(plant, rotationType, planDimensions);
    }

    // Rotate garden objects
    updatedPlan.gardenObjects = { ...plan.gardenObjects };
    for (let i = 0; i < updatedPlan.gardenObjectIds.length; i++) {
      const gardenObject = updatedPlan.gardenObjects[updatedPlan.gardenObjectIds[i]];
      const gardenObjectDefinition = gardenObjects.get(gardenObject.code);
      const isBlock = gardenObjectDefinition !== null && gardenObjectDefinition.shape.type === GardenObjectType.BLOCK;

      updatedPlan.gardenObjects[updatedPlan.gardenObjectIds[i]] = PlanTransformUtils.rotateGardenObject(gardenObject, isBlock, rotationType, planDimensions);
    }

    // Rotate shapes
    updatedPlan.shapes = { ...plan.shapes };
    for (let i = 0; i < updatedPlan.shapeIds.length; i++) {
      const shape = updatedPlan.shapes[updatedPlan.shapeIds[i]];

      updatedPlan.shapes[updatedPlan.shapeIds[i]] = PlanTransformUtils.rotateShape(shape, rotationType, planDimensions);
    }

    // Rotate text
    updatedPlan.text = { ...plan.text };
    for (let i = 0; i < updatedPlan.textIds.length; i++) {
      const text = updatedPlan.text[updatedPlan.textIds[i]];

      updatedPlan.text[updatedPlan.textIds[i]] = PlanTransformUtils.rotateText(text, rotationType, planDimensions);
    }

    return updatedPlan;
  }

  /**
   * Resizes the given plan, applying the resize from the given anchor location
   * @param plan The plan to resize
   * @param width The new width of the plan
   * @param height The new height of the plan
   * @param anchor The anchor position from where to apply the width/height change
   * @returns An altered copy of the plan
   */
  static resizePlan(plan: Plan, width: number, height: number, anchor: Anchor): Plan {
    const updatedPlan = { ...plan };
    updatedPlan.width = width;
    updatedPlan.height = height;

    if (anchor === Anchor.TopLeft) {
      return updatedPlan;
    }

    const widthDiff = width - plan.width;
    const heightDiff = height - plan.height;

    const direction = anchorAsVector(anchor);
    const xWeight = (direction.x + 1) / 2;
    const yWeight = (direction.y + 1) / 2;

    const offset: Vector2 = { x: Math.round(xWeight * widthDiff), y: Math.round(yWeight * heightDiff) };

    // Rotate plants
    updatedPlan.plants = { ...plan.plants };
    for (let i = 0; i < updatedPlan.plantIds.length; i++) {
      const plant = updatedPlan.plants[updatedPlan.plantIds[i]];

      updatedPlan.plants[updatedPlan.plantIds[i]] = PlanTransformUtils.translatePlant(plant, offset);
    }

    // Rotate garden objects
    updatedPlan.gardenObjects = { ...plan.gardenObjects };
    for (let i = 0; i < updatedPlan.gardenObjectIds.length; i++) {
      const gardenObject = updatedPlan.gardenObjects[updatedPlan.gardenObjectIds[i]];

      updatedPlan.gardenObjects[updatedPlan.gardenObjectIds[i]] = PlanTransformUtils.translateGardenObject(gardenObject, offset);
    }

    // Rotate shapes
    updatedPlan.shapes = { ...plan.shapes };
    for (let i = 0; i < updatedPlan.shapeIds.length; i++) {
      const shape = updatedPlan.shapes[updatedPlan.shapeIds[i]];

      updatedPlan.shapes[updatedPlan.shapeIds[i]] = PlanTransformUtils.translateShape(shape, offset);
    }

    // Rotate text
    updatedPlan.text = { ...plan.text };
    for (let i = 0; i < updatedPlan.textIds.length; i++) {
      const text = updatedPlan.text[updatedPlan.textIds[i]];

      updatedPlan.text[updatedPlan.textIds[i]] = PlanTransformUtils.translateText(text, offset);
    }

    return updatedPlan;
  }

  /**
   * Rotates and resizes the given plan.
   * If rotating by 90 or 270, the new width and height are swapped so that width and height always apply
   *  to the same original axis in the editor
   * @param plan The plan to alter
   * @param width The new width of the plan
   * @param height
   * @param anchor
   * @param rotation
   * @param gardenObjects
   * @returns
   */
  static rotateAndResizePlan(
    plan: Plan,
    width: number,
    height: number,
    anchor: Anchor,
    rotation: PlanClockwiseRotationAmount | false,
    gardenObjects: Collection<GardenObject>
  ): Plan {
    let updatedPlan = { ...plan };

    // Rotate plan first, so the anchor applies after and matches the diagram
    if (rotation !== false) {
      updatedPlan = PlanTransformUtils.rotatePlan(updatedPlan, rotation, gardenObjects);
    }

    // As we apply rotation first, the new width/height need to be swapped if we've rotated by 90/270 to match new orientation.
    const dimensions = { width, height };
    if (rotation === PlanClockwiseRotationAmount.Rotate90 || rotation === PlanClockwiseRotationAmount.Rotate270) {
      dimensions.width = height;
      dimensions.height = width;
    }
    updatedPlan = PlanTransformUtils.resizePlan(updatedPlan, dimensions.width, dimensions.height, anchor);

    return updatedPlan;
  }
}
