import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { Application, NavigationActionCreators } from '@gi/garden-platform-navigation';
import { SessionSelectors } from '@gi/react-session';
import { AppAccountActionCreators } from '@gi/app-account-slice';
import { UserUtils } from '@gi/user';

import Banner from '../banner';
import BannerTitle from '../banner-title';
import BannerButtons from '../banner-buttons';
import BannerContent from '../banner-content';

const LocationUnsetBanner = (): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);
  const dispatch = useDispatch();

  if (!user || (user.countryCode && user.countryCode !== '' && UserUtils.hasLocation(user))) {
    return null;
  }

  const goToLocationSettings = () => {
    dispatch(batchActions([NavigationActionCreators.navigateTo({ application: Application.Account }), AppAccountActionCreators.setActivePage('location')]));
  };

  return (
    <Banner type='warning' dismissable>
      <BannerContent>
        <BannerTitle>Please Set Your Garden&apos;s Location</BannerTitle>
        <p>
          In order to provide planting dates and other localised information, you need to give your garden&apos;s location. This enables the Garden Planner to
          locate your nearest weather station or climate area.
        </p>
      </BannerContent>
      <BannerButtons>
        <button className='button button-secondary' type='button' onClick={goToLocationSettings}>
          Go to Location Settings
        </button>
      </BannerButtons>
    </Banner>
  );
};

export default LocationUnsetBanner;
