import React, { useCallback, useEffect, useState } from 'react';

import FormField, { FormCheckboxField, FormLayout, FormSection, InputContainer, FORM_LAYOUT_PRESETS, createFormValues } from '@gi/form-responsive';
import LoadingButton from '@gi/loading-button';
import { useDispatch, useSelector } from 'react-redux';
import { SessionSelectors } from '@gi/react-session';
import { StringValidators } from '@gi/validators';
import { AppAccountSelectors, AppAccountActionCreators } from '@gi/app-account-slice';
import type { UserEmailSettings } from '@gi/app-account-types';
import { LoadingState } from '@gi/constants';

import ConfirmChangeEmailModal from './modals/confirm-change-email-modal';

const UserContactSettingsForm = (): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);

  if (!user) {
    console.warn('Attempting to render UserContactSettingsForm without a User');
    return null;
  }

  const isSavingUser = useSelector(AppAccountSelectors.getIsSavingUser);
  const saveRequest = useSelector(AppAccountSelectors.getSavingEmailSettings);
  const isSubmitting = saveRequest.status === LoadingState.LOADING;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const [manager, setManager] = useState(
    createFormValues<UserEmailSettings>({
      firstName: { value: user.firstName ?? '' },
      email: { value: user.email, validators: [StringValidators.isEmail()], disabled: user.misc.educationalAccount },
      reminderEmails: { value: user.misc.reminderEmails ?? true },
      includePerennialReminders: { value: user.misc.includePerennialReminders ?? true },
      tutorialEmails: { value: user.misc.tutorialEmails ?? true },
      offerEmails: { value: user.misc.offerEmails ?? true },
    })
  );

  const setField = useCallback(
    <K extends keyof UserEmailSettings>(setting: K, value: UserEmailSettings[K]) => {
      if (!manager.fields[setting].disabled) {
        setManager(manager.setValue(setting, { value }));
      }
    },
    [manager]
  );

  const submit = useCallback(() => {
    console.debug('Submtting Email Settings: ', manager.values);
    const { values } = manager;

    dispatch(
      AppAccountActionCreators.saveUserEmailSettings(user, {
        ...values,
      })
    );
  }, [manager, user]);

  const trySubmit = useCallback(() => {
    if (manager.fields.email.isDifferent) {
      // Show a modal to the user warning them
      setShowModal(true);
    } else {
      submit();
    }
  }, [manager, submit]);

  useEffect(() => {
    if (saveRequest.status === LoadingState.SUCCESS) {
      setManager(manager.reset(manager.values));
      setShowModal(false);
    }
    if (saveRequest.status === LoadingState.ERROR) {
      setShowModal(false);
    }
  }, [saveRequest]);

  return (
    <form>
      {showModal && (
        <ConfirmChangeEmailModal email={manager.values.email} loading={isSavingUser} onCancel={() => setShowModal(false)} onConfirm={() => submit()} />
      )}
      <FormSection className='form-section-background'>
        <h2>Contact Settings</h2>
        <FormField label='First Name' htmlFor='first-name' desktopLayout={{ inputSize: 'full' }}>
          <InputContainer>
            <input
              type='text'
              id='first-name'
              value={manager.values.firstName}
              onChange={(e) => setField('firstName', e.target.value)}
              disabled={isSubmitting}
            />
          </InputContainer>
        </FormField>
        <FormField
          label='Email'
          htmlFor='email'
          desktopLayout={{ inputSize: 'full' }}
          invalid={!manager.fields.email.valid}
          errorText={manager.fields.email.errors.join(', ')}
          helpText={user.misc.educationalAccount ? 'To update the email of educational accounts, please contact support' : undefined}
          disabled={manager.fields.email.disabled}
        >
          <InputContainer>
            <input
              type='text'
              id='email'
              value={manager.values.email}
              onChange={(e) => setField('email', e.target.value)}
              disabled={manager.fields.email.disabled || isSubmitting}
            />
          </InputContainer>
        </FormField>
        <FormCheckboxField layout='reverse' label='Send me emails to remind me when to sow and plant out plants from my plans' htmlFor='plant-reminder-emails'>
          <input
            type='checkbox'
            id='plant-reminder-emails'
            checked={manager.values.reminderEmails}
            onChange={(e) => setField('reminderEmails', e.target.checked)}
            disabled={isSubmitting}
          />
        </FormCheckboxField>
        <FormCheckboxField
          layout='reverse'
          label='Include perennial plants when sending email reminders of when to plant (useful for new gardens)'
          htmlFor='include-perennials'
        >
          <input
            type='checkbox'
            id='include-perennials'
            checked={manager.values.includePerennialReminders}
            onChange={(e) => setField('includePerennialReminders', e.target.checked)}
            disabled={isSubmitting}
          />
        </FormCheckboxField>
        <FormCheckboxField layout='reverse' label='Send me emails with gardening videos and tips (once a week)' htmlFor='tips-emails'>
          <input
            type='checkbox'
            id='tips-emails'
            checked={manager.values.tutorialEmails}
            onChange={(e) => setField('tutorialEmails', e.target.checked)}
            disabled={isSubmitting}
          />
        </FormCheckboxField>
        <FormCheckboxField layout='reverse' label='Send me occasional emails with special subscription offers or other gardening deals' htmlFor='offer-emails'>
          <input
            type='checkbox'
            id='offer-emails'
            checked={manager.values.offerEmails}
            onChange={(e) => setField('offerEmails', e.target.checked)}
            disabled={isSubmitting}
          />
        </FormCheckboxField>
        <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.ButtonRight}>
          <LoadingButton
            type='button'
            className='button button-primary'
            loading={isSubmitting}
            disabled={isSavingUser || !manager.hasDifferences || !manager.isValid}
            onClick={trySubmit}
          >
            Save Contact Settings
          </LoadingButton>
        </FormLayout>
      </FormSection>
    </form>
  );
};

export default UserContactSettingsForm;
