import { randomUUID } from '@gi/utils';
import { ProductType, ProductVariant } from './shopping-product';

export type ShoppingListAnalytics = ShoppingListEntryAnalytics[];

export type ShoppingListEntryAnalytics = {
  code: string;
  varieties: string;
  product: number;
  variant: number;
  count: number;
  inCart: boolean;
};

export type ShoppingListPlant = {
  plantCode: string;
  variety: string;
  count: number;
};

export type ShoppingListGardenObject = {
  code: string;
  count: number;
};

export type ShoppingListInputs = {
  planId: number;
  plants: ShoppingListPlant[];
  gardenObjects: ShoppingListGardenObject[];
};

export type BaseShoppingListEntry<T> = {
  uuid: string;
  variantId: number;
  productId: number;
  type: ProductType;
  matchData: T;
  inputQuantity: number;
  cartQuantity: number;
  inCart: boolean;
};

export type PlantEntry = {
  plantCode: string;
  varieties: { name: string; count: number }[];
};

export type GardenObjectEntry = {
  code: string;
};

export type PlantShoppingEntry = BaseShoppingListEntry<PlantEntry>;
export type GardenObjectShoppingEntry = BaseShoppingListEntry<GardenObjectEntry>;

export class ShoppingEntryUtils {
  static createEntry<T>(
    productId: number,
    variantId: number,
    type: ProductType,
    matchData: T,
    inputQuantity: number,
    cartQuantity: number
  ): BaseShoppingListEntry<T> {
    return {
      uuid: randomUUID(),
      variantId,
      productId,
      type,
      matchData,
      inputQuantity,
      cartQuantity,
      inCart: false,
    };
  }

  static createPlantEntry(productId: number, variantId: number, plantCode: string): PlantShoppingEntry {
    return ShoppingEntryUtils.createEntry(productId, variantId, ProductType.Plant, { plantCode, varieties: [], plantCount: 0 }, 0, 0);
  }

  static addVarietyToPlantEntry(entry: PlantShoppingEntry, varietyName: string, plantCount: number) {
    entry.inputQuantity += plantCount;
    entry.matchData.varieties.push({ name: varietyName, count: plantCount });
  }

  static getRecommendedQuantity<T>(entry: BaseShoppingListEntry<T>, variant: ProductVariant<T>): number {
    if (variant.quantity <= 0) {
      return 1;
    }
    return Math.ceil((entry.inputQuantity * variant.recommendedQuantityMultiplier) / variant.quantity);
  }

  static setRecommendedQuantity<T>(entry: BaseShoppingListEntry<T>, variant: ProductVariant<T>) {
    entry.cartQuantity = ShoppingEntryUtils.getRecommendedQuantity(entry, variant);
  }

  static getAnalytics(entry: PlantShoppingEntry | GardenObjectShoppingEntry): ShoppingListEntryAnalytics {
    let code: string = '';
    let varieties: string = '';

    if ('code' in entry.matchData) {
      code = entry.matchData.code;
    } else {
      code = entry.matchData.plantCode;
      varieties = entry.matchData.varieties.map((variety) => variety.name).join(', ');
    }
    return {
      code,
      varieties,
      product: entry.productId,
      variant: entry.variantId,
      count: entry.cartQuantity,
      inCart: entry.inCart,
    };
  }
}
