import React, { useContext } from 'react';
import plantFamilies from '@gi/plant-families';
import { DistanceUnits } from '@gi/units';
import { SessionSelectors } from '@gi/react-session';

import { ModalPaneContainer, ModalPane, ModalPaneContent, ModalTabContent, ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';
import { collectionNotNull, ResourceContext } from '@gi/resource-provider';
import { DEFAULT_VARIETY } from '@gi/constants';
import { useSelector } from 'react-redux';
import PlantCompanions from './plant-companions';
import PlantingCalendarSection from './planting-calendar-section';
import PlantInformationSection from './plant-information-section';
import PlantOtherNamesSection from './plant-other-names-section';
import PlantSpacingSection from './plant-spacing-section';
import PlantSquareFootSection from './plant-square-foot-section';

interface iPlantInformationTabProps {
  plant;
  distanceUnits: DistanceUnits;
}

const PlantInformationTab = ({ plant, distanceUnits }: iPlantInformationTabProps): JSX.Element => {
  const { getPlantingCalendar, userPlants, userArtifactCode } = useContext(ResourceContext);
  const notNullPlants = collectionNotNull(userPlants);

  const isNorthernHemisphere = useSelector(SessionSelectors.getIsNorthernHemisphere);

  const plantFamily = plantFamilies.get(plant.familyID);
  const plantFamilyStyle = {
    backgroundColor: `#${plantFamily?.color}`,
  };

  const plantingCalendar = getPlantingCalendar(plant.code, null, DEFAULT_VARIETY);

  return (
    <ModalTabContent tabID='plant-information'>
      <ModalPaneContainer>
        <ModalPane className='plant-information-pane'>
          <ModalPaneContent>
            <ModalPaneSection className='plant-information-section'>
              <ModalPaneSectionContent>
                <table className='plant-information-table'>
                  <tbody>
                    <tr className='plan-table-row'>
                      <th className='plan-table-row-title'>Crop Rotation Family</th>
                      <td>
                        <span className='plant-info-family-outer'>
                          <span className='plant-info-family' style={plantFamilyStyle} />
                        </span>
                        {plantFamily?.name}
                      </td>
                    </tr>
                    <PlantOtherNamesSection plant={plant} />
                    <PlantInformationSection plant={plant} property='soil' title='Soil' />
                    <PlantInformationSection plant={plant} property='position' title='Position' />
                    <PlantInformationSection plant={plant} property='frostTolerant' title='Frost Tolerant' />
                    <tr className='plan-table-row'>
                      <th className='plan-table-row-title'>Perennial</th>
                      <td>{plant.perennial ? 'Yes.' : 'No.'}</td>
                    </tr>
                    <PlantInformationSection plant={plant} property='feeding' title='Feeding' />
                    <PlantInformationSection plant={plant} property='sowPlant' title='Sow and Plant' />
                    <PlantSpacingSection plant={plant} distanceUnits={distanceUnits} />
                    <PlantSquareFootSection plant={plant} />
                    <PlantCompanions plant={plant} plants={notNullPlants} />
                    <PlantInformationSection plant={plant} property='companions' title='Companion Notes' />
                    <PlantInformationSection plant={plant} property='notes' title='Notes' />
                    <PlantInformationSection plant={plant} property='harvesting' title='Harvesting' />
                    <PlantInformationSection plant={plant} property='troubleshooting' title='Troubleshooting' />
                  </tbody>
                </table>
              </ModalPaneSectionContent>
            </ModalPaneSection>
            <PlantingCalendarSection plantingCalendar={plantingCalendar} isNorthernHemisphere={isNorthernHemisphere} artifactCode={userArtifactCode} />
          </ModalPaneContent>
        </ModalPane>
      </ModalPaneContainer>
      <ModalPaneContainer style={{ padding: 0 }}>
        <ModalPane style={{ padding: 0 }}>
          <ModalPaneContent>
            <ModalPaneSection className='plant-image-section'>
              <div className='plant-image-container'>
                <img alt={plant.name} src={plant.images.portrait} className='plant-image' />
              </div>
            </ModalPaneSection>
          </ModalPaneContent>
        </ModalPane>
      </ModalPaneContainer>
    </ModalTabContent>
  );
};

export default PlantInformationTab;
