import {
  InspectableClassData,
  InspectableClassDataType,
  InspectableClassPropertyType,
  InteractableComponent,
  InteractableComponentCallbacks,
  KeybindComponent,
  KeyboardEventData,
  Node,
  OutlineComponent,
  SelectableComponent,
  SelectableComponentContext,
  ShapeComponent,
  bindState,
  ShapeFlag,
} from '@gi/core-renderer';
import Bitmask from '@gi/bitmask';
import { InteractionStateType } from '@gi/constants';
import { State, StateDef } from '@gi/state';

import PlantLabelNode from '../plant/plant-label-node';
import ToolNode, { ToolState } from './tool-node';
import { createRectContainerOutline } from '../utils';

/**
 * Selection Box Tool Node
 *
 * Capturs all mouse inputs and draws a rectangle to the screen to select thigns on the plan.
 *
 * Rather than re-calculate the selection in the onDragMove callback, we instead use a state and defer.
 * This saves performance, as we usually get numerous move events per frame. If we calculate the
 *  selection every time the event fires, we end up doing it numerous times a frame, causing massive
 *  lag on large plans. By using a state, we only calculate it once per frame.
 * The SelectionBoxTool has an internal Mode, which indicates where in the process it is.
 */

enum SelectionBoxToolMode {
  INACTIVE = 'INACTIVE', // Tool is not in use.
  ACTIVE = 'ACTIVE', // Tool is actively in use, being dragged
  FINISHED = 'FINISHED', // Tool has just been released, final selection should be calculated and committed. Should move to inactive next frame.
}

type SelectionBoxToolNodeState = {
  startPos: Vector2 | null;
  endPos: Vector2 | null;
  shouldRepeat: boolean;
  shouldAdd: boolean;
  hasRepeated: boolean;
  mode: SelectionBoxToolMode;
};

export interface SelectionBoxToolState extends ToolState {
  type: InteractionStateType.SELECTION_BOX;
  shouldRepeat: boolean;
}

const DEFAULT_STATE: SelectionBoxToolNodeState = {
  startPos: null,
  endPos: null,
  shouldRepeat: false,
  shouldAdd: false,
  hasRepeated: false,
  mode: SelectionBoxToolMode.INACTIVE,
};

class SelectionBoxToolNode extends ToolNode<SelectionBoxToolState> {
  type = 'SelectionBoxToolNode';

  #allSelectableNodes: Node[] = [];
  #dragging: boolean = false;

  readonly state: State<StateDef<SelectionBoxToolNodeState>>;
  readonly interaction: InteractableComponent;
  readonly keybind: KeybindComponent;
  readonly shape: ShapeComponent;
  readonly outline: OutlineComponent;

  constructor() {
    super({ type: InteractionStateType.SELECTION_BOX, shouldRepeat: false });

    this.state = new State({ ...DEFAULT_STATE });
    bindState(this.state, this);

    this.interaction = this.components.add(new InteractableComponent());
    this.keybind = this.components.add(new KeybindComponent(['shift']));
    this.shape = this.components.add(new ShapeComponent({ flags: Bitmask.Create(ShapeFlag.UI) }));
    this.outline = this.components.add(
      new OutlineComponent({
        thickness: 1,
        backgroundColour: '#3477f00f',
        colour: '#3477f0',
        disablePointerEvents: true,
        generateHitbox: false,
      })
    );

    // Whenever the start/endPos changes, update the shape so the outline changes.
    this.state.addUpdater(
      (state) => {
        const { startPos, endPos } = state.values;
        if (startPos !== null && endPos !== null) {
          this.shape.state.values.points = createRectContainerOutline(startPos, endPos);
        }
      },
      { properties: ['startPos', 'endPos'] }
    );

    this.state.addWatcher(
      (state) => {
        const { mode, shouldAdd, shouldRepeat } = state.values;
        if (mode === SelectionBoxToolMode.FINISHED) {
          this.#updateSelection();
          this.#commitSelection(shouldAdd);
          if (!shouldRepeat) {
            this.destroy();
          }
          state.values.mode = SelectionBoxToolMode.INACTIVE;
          state.values.hasRepeated = true;
        } else if (mode === SelectionBoxToolMode.ACTIVE) {
          this.#updateSelection();
        }
      },
      { properties: ['startPos', 'endPos', 'mode'] }
    );

    this.state.addWatcher(
      (state) => {
        this.externalState.values.shouldRepeat = state.values.shouldRepeat;
      },
      { properties: ['shouldRepeat'] }
    );

    this.ownGraphics.hitArea = { contains: () => true };

    this.interaction.addListener('onDragStart', this.#onDragStart);
    this.interaction.addListener('onDragMove', this.#onDragMove);
    this.interaction.addListener('onDragEnd', this.#onDragEnd);

    this.keybind.onKeyChange = this.#onKeyChange;
  }

  #onDragStart: InteractableComponentCallbacks['onDragStart'] = (data, interaction, controls) => {
    if (data.button !== 0) {
      return;
    }

    this.#dragging = true;
    controls.stopPropagation();

    this.#allSelectableNodes = this.getContext(SelectableComponentContext).allSelectableNodes;

    this.state.values.mode = SelectionBoxToolMode.ACTIVE;
    this.state.values.startPos = data.worldPosition;
    this.state.values.endPos = data.worldPosition;
    this.state.values.shouldRepeat = data.shiftKey;
    this.state.values.shouldAdd = data.shiftKey;
    this.outline.state.values.visible = true;
  };

  #onDragMove: InteractableComponentCallbacks['onDragMove'] = (data, interaction, controls) => {
    if (!this.#dragging) {
      return;
    }

    controls.stopPropagation();

    this.state.values.endPos = data.worldPosition;
    this.state.values.shouldRepeat = data.shiftKey;
    this.state.values.shouldAdd = data.shiftKey;
  };

  #onDragEnd: InteractableComponentCallbacks['onDragEnd'] = (data, interaction, controls) => {
    if (!this.#dragging) {
      return;
    }

    this.#dragging = false;
    controls.stopPropagation();

    this.state.values.endPos = data.worldPosition;
    this.state.values.shouldRepeat = data.shiftKey;
    this.state.values.shouldAdd = data.shiftKey;
    this.state.values.mode = SelectionBoxToolMode.FINISHED;
    this.outline.state.values.visible = false;
  };

  #onKeyChange = (data: KeyboardEventData) => {
    if (this.state.values.hasRepeated && !data.isKeyDown) {
      // If we've repeated and released the repeat key, immediately end.
      this.interaction.cancel();
      this.state.values.mode = SelectionBoxToolMode.FINISHED;
      this.state.values.shouldRepeat = false;
      this.outline.state.values.visible = false;
    } else {
      // We're either not repeating or the key is down, updaet the sate with such
      this.state.values.shouldRepeat = data.isKeyDown;
      this.state.values.shouldAdd = data.isKeyDown;
    }
  };

  #updateSelection() {
    const { startPos, endPos } = this.state.values;

    if (startPos === null || endPos == null) {
      return;
    }

    const topLeft = { x: Math.min(startPos.x, endPos.x), y: Math.min(startPos.y, endPos.y) };
    const bottomRight = { x: Math.max(startPos.x, endPos.x), y: Math.max(startPos.y, endPos.y) };
    const inHitArea: Node[] = [];

    for (let i = 0; i < this.#allSelectableNodes.length; i++) {
      const node = this.#allSelectableNodes[i];
      const selectable = node.components.get(SelectableComponent);
      if (selectable?.isSelectable && this.#canSelect(node)) {
        const shape = node.components.get(ShapeComponent);
        if (shape && shape.isTouchingArea(topLeft, bottomRight)) {
          inHitArea.push(node);
        }
      }
    }

    const selectContext = this.getContext(SelectableComponentContext);
    selectContext.setPreSelection(inHitArea);
  }

  #commitSelection(shouldAdd: boolean) {
    const selectContext = this.getContext(SelectableComponentContext);
    selectContext.selectPreSelected(shouldAdd);
  }

  /**
   * Checks if the given node is the right type to be selected.
   * Use to exclude certain nodes from selection, even if they are selectable with click
   * @param node The node potentially being selected
   * @returns True if the node type can be selected
   */
  // eslint-disable-next-line class-methods-use-this
  #canSelect(node: Node): boolean {
    return !(node instanceof PlantLabelNode);
  }

  // eslint-disable-next-line class-methods-use-this
  cancel(): void {
    // Do nothing
  }

  inspectorData: InspectableClassData<this> = [
    ...this.inspectorData,
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default SelectionBoxToolNode;
