import React from 'react';

import styles from './directory-page.module.css';

interface iProps {
  children: React.ReactNode;
}

const DirectoryPage = ({ children }: iProps): JSX.Element => {
  return <div className={styles.directoryPage}>{children}</div>;
};

export default DirectoryPage;
