import React, { useContext, useMemo } from 'react';

import { UserPlan } from '@gi/user';
import { LoadingState } from '@gi/constants';

import { PlannerCommandContext } from '../../planner-command-provider';

import styles from './open-plan-list.module.css';

interface iProps {
  plan: UserPlan;
  closeMenu: () => void;
  status?: LoadingState;
  active?: boolean;
}

const OpenPlanListItem = ({ plan, closeMenu, active, status }: iProps): JSX.Element => {
  const { closePlan, setActivePlan } = useContext(PlannerCommandContext);

  const icon = useMemo(() => {
    switch (status) {
      case LoadingState.LOADING:
        return <i className='icon-spinner animate-pulse' />;
      case LoadingState.ERROR:
        return <i className={`icon-warning ${styles.error}`} title='Plan failed to load' />;
      default:
        return null;
    }
  }, [status]);

  return (
    <div className={styles.openPlanListItem}>
      <button
        type='button'
        onClick={() => {
          setActivePlan(plan.ID);
          closeMenu();
        }}
        disabled={active}
        className={`${styles.itemSelectButton} ${active ? styles.activeSelectButton : ''}`}
      >
        {icon}
        <div className={styles.itemPlanName}>{plan.name}</div>
        {active ? <span className={styles.activePlanPill}>Current Plan</span> : null}
        <div className={styles.itemPlanYear}>{plan.year}</div>
      </button>
      <button
        type='button'
        onClick={() => {
          closePlan(plan.ID);
          closeMenu();
        }}
        className={styles.itemCloseButton}
      >
        <i className='icon-cancel' />
      </button>
    </div>
  );
};

export default OpenPlanListItem;
