import React from 'react';

import Plant from '@gi/plant';
import { CompanionIcon } from '@gi/plant-icon';
import PlannerIcon from '@gi/planner-icon';

import { CompanionType } from './companion-types';
import CompanionPlantTag from './companion-plant-tag';

import styles from './companions.module.css';

interface iCompanionsSectionProps {
  companionPlants: Plant[];
  companionType: CompanionType;
  plant: Plant;
  className?: string;
}

const Companions = ({ companionPlants, companionType, plant, className = '' }: iCompanionsSectionProps): JSX.Element | null => {
  if (companionPlants.length === 0) {
    return null;
  }

  const companions = companionPlants.map((companionPlant) => <CompanionPlantTag key={companionPlant.code} plant={companionPlant} />);

  const isHelper = companionType === CompanionType.Mutual || companionType === CompanionType.HelpedBy;
  const isHelped = companionType === CompanionType.Mutual || companionType === CompanionType.Helps;

  let title = '';
  if (companionType === CompanionType.Mutual) {
    title = 'Mutually beneficial';
  } else if (companionType === CompanionType.HelpedBy) {
    title = 'Benefit this plant';
  } else if (companionType === CompanionType.Helps) {
    title = 'This plant benefits';
  }

  return (
    <div className={`${styles.companions} ${className}`}>
      <div className={styles.companionsSection}>{companions}</div>
      <div className={styles.companionsSectionIcon}>
        <CompanionIcon isHelper={isHelper} isHelped={isHelped} caption={`Companion Planting: ${title}`} />
        <PlannerIcon code={plant.code} className='plant-icon' />
      </div>
    </div>
  );
};

export default Companions;
