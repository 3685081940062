import { Store } from 'redux';
import memoizeOne from 'memoize-one';
import { batchActions } from 'redux-batched-actions';

import { RequestActionCreators } from '@gi/react-requests';
import { MAX_LOAD_PLAN_RETRIES } from '@gi/constants';
import { NotificationActionCreators, NotificationTypes } from '@gi/notifications';
import Plan, { PlanService, PlanSetUtils } from '@gi/plan';
import { User } from '@gi/user';
import { SessionReducerState } from '@gi/react-session';

import { plansLoadStart, planLoadEnd, planLoadFailed, addPlan, removePlans } from '../redux-components/canvas-action-creators';
import { CanvasReducerState } from '../redux-components/canvas-reducer';

const filterNotNull = <T>(val: T | null): val is T => {
  return val !== null;
};

interface BaseStoreState {
  canvas: CanvasReducerState;
  session: SessionReducerState;
}

/**
 * A state change handler to handle loading of plans and removal of plan data when it is no longer required
 *
 * @param {Store} store
 * @param {PlanService} planService
 */
const planLoader = (store: Store<BaseStoreState>, planService: PlanService) => {
  let currentState = store.getState();

  const addPlanData = (plan: Plan) => {
    store.dispatch(batchActions([RequestActionCreators.requestComplete(`LOAD_PLAN_${plan.id}`), addPlan(plan)]));
  };

  const onPlanLoadFail = (planID: number) => {
    return (err: Error) => {
      store.dispatch(
        batchActions([
          planLoadFailed(planID, err),
          RequestActionCreators.requestFail(`LOAD_PLAN_${planID}`, err),
          NotificationActionCreators.createDefaultNotification({
            title: 'Error Loading Plan',
            type: NotificationTypes.ERROR,
            icon: 'icon-attention-alt',
            canTimeout: true,
          }),
        ])
      );
    };
  };

  const onPlanLoadEnd = (planID: number) => {
    return () => {
      store.dispatch(planLoadEnd(planID));
    };
  };

  const loadPlans = (user: User, planIDs: number[]) => {
    store.dispatch(plansLoadStart(planIDs));

    for (let i = 0; i < planIDs.length; i++) {
      const planID = planIDs[i];
      store.dispatch(RequestActionCreators.requestStart(`LOAD_PLAN_${planID}`));
      planService.loadPlan(planID).then(addPlanData).catch(onPlanLoadFail(planID)).finally(onPlanLoadEnd(planID));
    }
  };

  const getAllRequiredPlans = memoizeOne((canvasState: CanvasReducerState) => {
    const requiredThroughHistory = canvasState.openPlanIDs
      .map((planID) => PlanSetUtils.planSetGetPlan(canvasState.plans, planID))
      .filter(filterNotNull)
      .map((plan) => plan.history)
      .flat();

    return new Set([...canvasState.openPlanIDs, ...requiredThroughHistory]);
  });

  const getFailedToLoadPlanIDs = memoizeOne((failedToLoadPlans, maxLoadPlanRetries) => {
    const planIDs: number[] = [];

    const failedToLoadPlanIDs = Object.keys(failedToLoadPlans).map((id) => Number(id));

    for (let i = 0; i < failedToLoadPlanIDs.length; i++) {
      if (failedToLoadPlans[failedToLoadPlanIDs[i]] >= maxLoadPlanRetries) {
        planIDs.push(failedToLoadPlanIDs[i]);
      }
    }

    return planIDs;
  });

  const getRequiredPlanIDs = (canvasState: CanvasReducerState) => {
    // Get a list of all required plan IDs
    const allRequired = getAllRequiredPlans(canvasState);

    // Get plans which failed to load too many times
    const failedToLoadPlanIDs = getFailedToLoadPlanIDs(canvasState.failedToLoadPlans, MAX_LOAD_PLAN_RETRIES);

    // Find which plans are already loaded, or loading
    const allLoadedLoadingAndFailed = new Set([...canvasState.loadingPlanIDs, ...canvasState.plans.planIds, ...failedToLoadPlanIDs]);

    // Use diff to find out which plans still need loading
    return [...allRequired].filter((x) => !allLoadedLoadingAndFailed.has(x));
  };

  const getNotRequiredPlanIDs = (canvasState: CanvasReducerState) => {
    // Get a list of all required plan IDs
    const allRequired = getAllRequiredPlans(canvasState);

    // Get a list of all plan IDs loaded
    const allLoaded = canvasState.plans.planIds;

    return allLoaded.filter((x) => !allRequired.has(x));
  };

  const loadRequiredPlans = () => {
    const requiredPlans = getRequiredPlanIDs(currentState.canvas);

    if (requiredPlans.length > 0) {
      console.debug('Plan loader loading required plans', requiredPlans);
      const { user } = currentState.session;
      if (user) {
        loadPlans(user, requiredPlans);
      } else {
        console.error('Cannot load required plans - session has no user');
      }
    }
  };

  const removeUnrequiredPlans = () => {
    const unrequiredPlans = getNotRequiredPlanIDs(currentState.canvas);

    if (unrequiredPlans.length > 0) {
      console.debug('Plan loader removing unrequired plans', unrequiredPlans);
      store.dispatch(removePlans(unrequiredPlans));
    }
  };

  const handleChange = () => {
    const previousState = currentState;
    currentState = store.getState();

    if (previousState === currentState) {
      // No change
      return;
    }

    const openPlansChanged = previousState.canvas.openPlanIDs !== currentState.canvas.openPlanIDs;
    const loadingPlansChanged = previousState.canvas.loadingPlanIDs !== currentState.canvas.loadingPlanIDs;
    const failedToLoadPlansChanged = previousState.canvas.failedToLoadPlans !== currentState.canvas.failedToLoadPlans;
    const planDataChanged = previousState.canvas.plans !== currentState.canvas.plans;

    if (openPlansChanged || loadingPlansChanged || failedToLoadPlansChanged || planDataChanged) {
      loadRequiredPlans();
    }

    if (openPlansChanged || planDataChanged) {
      removeUnrequiredPlans();
    }
  };

  store.subscribe(handleChange);
};

export default planLoader;
