import { RequestError } from '@gi/gi-network';

import getReminderErrorCodes from './get-reminder-error-codes';
import saveReminderErrorCodes from './save-reminder-error-codes';
import createReminderErrorCodes from './create-reminder-error-codes';
import deleteReminderErrorCodes from './delete-reminder-error-codes';

/**
 * Attaches a client message to a Network error from a deleteReminder request based on the status code.
 * Attaches a default message when there isn't a specific message for the given status code.
 */
const attachDeleteReminderErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && deleteReminderErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = deleteReminderErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when deleting reminder - ${responseError.statusCode}`;
  }
};
/**
 * Attaches a client message to a Network error from a getReminders request based on the status code. Attaches a default message
 * when there isn't a specific message for the given status code.
 */
const attachGetRemindersErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && getReminderErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = getReminderErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when loading reminders - ${responseError.statusCode}`;
  }
};

/**
 * Attaches a client message to a Network error from a saveReminder request based on the status code. Attaches a default message
 * when there isn't a specific message for the given status code.
 */
const attachSaveReminderErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && saveReminderErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = saveReminderErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when saving reminder - ${responseError.statusCode}`;
  }
};

/**
 * Attaches a client message to a Network error from a createReminder request based on the status code. Attaches a default message
 * when there isn't a specific message for the given status code.
 */
const attachCreateReminderErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && createReminderErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = createReminderErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when creating reminder - ${responseError.statusCode}`;
  }
};

export {
  attachGetRemindersErrorClientMessage,
  attachSaveReminderErrorClientMessage,
  attachCreateReminderErrorClientMessage,
  attachDeleteReminderErrorClientMessage,
};
