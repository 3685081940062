import React from 'react';
import { FormValues } from './form-values';
import FormSubtext, { FormSubtextType } from '../form-subtext';

interface iProps<T> {
  formManager: FormValues<T>;
  field: keyof T;
  prefix?: string;
  suffix?: string;
  show?: 'always' | 'onEdit' | 'onDifference';
  showWhenDisabled?: boolean;
}

const FormManagerErrorText = <T,>({
  formManager,
  field,
  show = 'always',
  showWhenDisabled = false,
  prefix = '',
  suffix = '',
}: iProps<T>): JSX.Element | null => {
  // Don't display if invalid
  if (formManager.fields[field].valid) {
    return null;
  }
  const finalElem =
    formManager.fields[field].disabled && !showWhenDisabled ? null : (
      <FormSubtext type={FormSubtextType.Error}>
        {prefix}
        {formManager.fields[field].errors.join(', ')}
        {suffix}
      </FormSubtext>
    );
  switch (show) {
    case 'always': {
      return finalElem;
    }
    case 'onEdit': {
      return formManager.fields[field].hasBeenEdited ? finalElem : null;
    }
    case 'onDifference': {
      return formManager.fields[field].isDifferent ? finalElem : null;
    }
    default: {
      return finalElem;
    }
  }
};

export default FormManagerErrorText;
