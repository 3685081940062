import { GardenObjectType } from '@gi/constants';
import CanvasItem from './canvas-item';
import CanvasLayers from './canvas-layers';
import { SimulatedGardenObject } from '../simulation/simulated-garden-object';
import { SimulatedGardenItemEvent } from '../simulation/simulated-garden-item';
import GardenObjectNode from './nodes/garden-objects/garden-object-node';
import BlockGardenObjectNode from './nodes/garden-objects/block-garden-object';
import PathGardenObjectNode from './nodes/garden-objects/path-garden-object-node';

class CanvasGardenObject extends CanvasItem {
  readonly #gardenObjectNode: GardenObjectNode;
  readonly #simulatedGardenObject: SimulatedGardenObject;
  readonly #canvasLayers: CanvasLayers;

  constructor(simulatedGardenObject: SimulatedGardenObject, canvasLayers: CanvasLayers) {
    super();

    switch (simulatedGardenObject.gardenObject.shape.type) {
      case GardenObjectType.BLOCK: {
        this.#gardenObjectNode = new BlockGardenObjectNode(simulatedGardenObject.id, simulatedGardenObject.gardenObject, {
          start: simulatedGardenObject.start,
          mid: simulatedGardenObject.mid,
          end: simulatedGardenObject.end,
          rotation: simulatedGardenObject.rotation,
          locked: simulatedGardenObject.locked,
          zIndex: simulatedGardenObject.zIndex,
        });
        break;
      }
      case GardenObjectType.PATH: {
        this.#gardenObjectNode = new PathGardenObjectNode(simulatedGardenObject.id, simulatedGardenObject.gardenObject, {
          start: simulatedGardenObject.start,
          mid: simulatedGardenObject.mid,
          end: simulatedGardenObject.end,
          rotation: simulatedGardenObject.rotation,
          locked: simulatedGardenObject.locked,
          zIndex: simulatedGardenObject.zIndex,
        });
        break;
      }
      default: {
        return;
      }
    }

    this.nodes.push(this.#gardenObjectNode);

    this.#simulatedGardenObject = simulatedGardenObject;
    this.#simulatedGardenObject.on(SimulatedGardenItemEvent.DidUpdate, this.#onSimulatedGardenObjectUpdate);

    this.#canvasLayers = canvasLayers;
    this.#canvasLayers.getLayer(simulatedGardenObject.gardenObject.layerType).addChildren(this.#gardenObjectNode);

    this.registerEffectHandler(this.#simulatedGardenObject, this.#gardenObjectNode);
  }

  #onSimulatedGardenObjectUpdate = () => {
    this.#gardenObjectNode.setPosition(
      this.#simulatedGardenObject.start,
      this.#simulatedGardenObject.mid,
      this.#simulatedGardenObject.end,
      this.#simulatedGardenObject.rotation
    );
    this.#gardenObjectNode.setLocked(this.#simulatedGardenObject.locked);
    this.#gardenObjectNode.setZIndex(this.#simulatedGardenObject.zIndex);
  };
}

export default CanvasGardenObject;
