import React from 'react';
import { PropTypes } from 'prop-types';

/**
 * @param {{
 *  barData: { values: number[], heading: string, subHeading: string }[],
 *  onClick?: () => void,
 *  onHover?: () => void,
 *  isHighlighted?: boolean,
 *  isAdjacentToHighlight?: boolean,
 *  isAdjacentToHover?: boolean,
 * }}
 */
const TimelineBar = ({ barData, onClick = () => {}, onHover = () => {}, isHighlighted = false, isAdjacentToHighlight = false, isAdjacentToHover = false }) => {
  let className = 'bc_column scaling_full';
  if (isAdjacentToHover) {
    className += ' adjacentHover';
  }
  if (isAdjacentToHighlight) {
    className += ' adjacentHighlight';
  }
  if (isHighlighted) {
    className += ' highlighted';
  }

  return (
    <div role='button' onMouseEnter={onHover} onClick={onClick} className={className}>
      <div className='bc_inner'>
        {barData.values.map((value, i) => {
          const key = `segment${i}`;
          return (
            <div
              key={key}
              className='bc_segment'
              style={{
                height: `${value}%`,
              }}
            />
          );
        })}
        <div className='bc_heading'>
          {barData.heading}
          <br />
          <span className='bc_subHeading'>{barData.subHeading}</span>
        </div>
      </div>
    </div>
  );
};

TimelineBar.propTypes = {
  barData: PropTypes.shape({
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
  }).isRequired,
  isAdjacentToHover: PropTypes.bool,
  isAdjacentToHighlight: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
};

export default TimelineBar;
