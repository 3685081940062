import { State, StateDef } from '@gi/state';
import Bitmask, { BitmaskType } from '@gi/bitmask';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { hasOwner } from '../../utils/asserts';
// eslint-disable-next-line import/no-cycle
import SelectableComponentContext from './selectable-component-context';
import GraphicNode from '../../graphics-node';
import InteractableComponent, { InteractableComponentCallbacks } from '../interactable/interactable-component';
import { bindState } from '../../utils/state-utils';

/** Flags to disable selecting an item */
export enum UnselectableFlag {
  /** Unselectable due to visibility */
  VISIBILITY = 1,
  /** Unselectable due to display mode */
  DISPLAY_MODE = 2,
}

export type SelectableComponentState = StateDef<{
  /** Reasons this node is unselectable. Item is selectable when this = 0 */
  unselectableFlags: BitmaskType<UnselectableFlag>;
  selected: boolean;
  preSelected: boolean;
}>;

const DEFAULT_STATE: SelectableComponentState['state'] = {
  unselectableFlags: Bitmask.NONE.value,
  selected: false,
  preSelected: false,
};

/**
 * Selectable Componenbt
 * Allows this node to be selected.
 */
class SelectableComponent extends NodeComponent<GraphicNode> {
  type = 'SelectableComponent';

  readonly state: State<SelectableComponentState>;

  #cleanUpListeners: (() => void) | null;

  constructor() {
    super();

    this.state = new State(DEFAULT_STATE);
    bindState(this.state, this);

    // If we transition from being selectable -> not selectable, and we're currently selected, remove ourselves from the selection.
    this.state.addWatcher(
      (state) => {
        if (state.values.unselectableFlags > 0 && state.values.selected) {
          this.owner?.getContext(SelectableComponentContext).removeFromSelection(this.owner);
        }
      },
      { properties: ['unselectableFlags'] },
      false
    );

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  /**
   * Can this item be selected
   */
  get isSelectable() {
    return this.state.values.unselectableFlags === 0;
  }

  // Called just after this component is attached to the engine
  #onBind = () => {
    hasOwner(this);

    const interactableComponent = this.owner.components.get(InteractableComponent);
    if (!interactableComponent) {
      console.error('SelectableComponent requires an InteractableComponent be attached to the Node');
      return;
    }
    interactableComponent.addListener('onClick', this.#onClick);

    this.#cleanUpListeners = () => {
      interactableComponent.removeListener('onClick', this.#onClick);
    };

    this.owner.getContext(SelectableComponentContext).registerAsSelectable(this.owner);
  };

  // Called just before this component is detached from the engine
  #onBeforeUnbind = () => {
    hasOwner(this);

    this.owner.getContext(SelectableComponentContext).unregisterAsSelectable(this.owner);

    this.#cleanUpListeners?.();
    this.#cleanUpListeners = null;
  };

  // Called when the owner of this component is clicked.
  #onClick: InteractableComponentCallbacks['onClick'] = ({ button, shiftKey, timeElapsed }, _, controls) => {
    if (button !== 0) {
      return;
    }
    controls.stopPropagation();
    if (!this.isSelectable) {
      return;
    }
    if (this.owner) {
      const context = this.owner.getContext(SelectableComponentContext);
      // Don't modify selection if it's a long-click and we're already part of the selection
      if (!timeElapsed || (timeElapsed && !context.isInSelection(this.owner))) {
        if (shiftKey) {
          context.toggleInSelection(this.owner);
        } else {
          context.setSelection([this.owner]);
        }
      }
    }
  };

  /**
   * Sets if this node is currently selected.
   * @param isSelected Is this node selected?
   */
  setIsSelected(isSelected: boolean) {
    this.state.values.selected = isSelected;
  }

  /**
   * Sets if this node is currently part of a selection box, likely to be selected soon.
   * @param isPreSelected Is the node soon to be selected
   */
  setIsPreSelected(isPreSelected: boolean) {
    this.state.values.preSelected = isPreSelected;
  }

  /**
   * Adds a reason preventing this item from being selected
   * @param flagName The name of the flag to add
   */
  addUnselectableFlag(flagName: UnselectableFlag) {
    this.state.values.unselectableFlags = Bitmask.Add(this.state.values.unselectableFlags, flagName);
  }

  /**
   * Removes a reason preventing this item from being selected
   * @param flagName The name of the flag to remove
   */
  removeUnselectableFlag(flagName: UnselectableFlag) {
    this.state.values.unselectableFlags = Bitmask.Remove(this.state.values.unselectableFlags, flagName);
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default SelectableComponent;
