import React from 'react';

import './modal-header.scss';

interface iProps {
  children: React.ReactNode;
}

const ModalHeaderTitle = ({ children }: iProps): JSX.Element => {
  return <div className='modal-header-title'>{children}</div>;
};

export default ModalHeaderTitle;
