/**
 * @typedef {int} SunType
 */
/**
 * Enum for the different sun types
 *
 * @readonly
 * @enum {LayerType}
 */
const LayerTypes = {
  DEFAULT: 0,
  SUNNY: 1,
  PARTIAL_SHADE: 2,
  SHADY: 3,
};

export default LayerTypes;
