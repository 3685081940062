import PropTypes from 'prop-types';

import { UserPlantVariety, UserPlantVarietyUtils, DEFAULT_USER_PLANT_VARIETY } from './user-plant-variety';

export type UserPlantVarietySet = {
  userPlantVarieties: UserPlantVariety[];
  userPlantVarietiesByPlantCode: Record<string, UserPlantVariety[]>;
  userPlantVarietiesByName: Record<string, Record<string, UserPlantVariety>>;
};

export type UserPlantVarietySetDiff = {
  added: UserPlantVariety[];
  updated: UserPlantVariety[];
  removed: UserPlantVariety[];
};

const createEmpty = (): UserPlantVarietySet => {
  return {
    userPlantVarieties: [],
    userPlantVarietiesByPlantCode: {},
    userPlantVarietiesByName: {},
  };
};

const create = (userPlantVarieties: UserPlantVariety[]): UserPlantVarietySet => {
  const newVarietySet: UserPlantVarietySet = {
    userPlantVarieties: [],
    userPlantVarietiesByPlantCode: {},
    userPlantVarietiesByName: {},
  };

  for (let i = 0; i < userPlantVarieties.length; i++) {
    const userPlantVariety = userPlantVarieties[i];

    newVarietySet.userPlantVarieties.push(userPlantVariety);

    if (!newVarietySet.userPlantVarietiesByPlantCode[userPlantVariety.plantCode]) {
      newVarietySet.userPlantVarietiesByPlantCode[userPlantVariety.plantCode] = [];
      newVarietySet.userPlantVarietiesByName[userPlantVariety.plantCode] = {};
    }

    newVarietySet.userPlantVarietiesByPlantCode[userPlantVariety.plantCode].push(userPlantVariety);
    newVarietySet.userPlantVarietiesByName[userPlantVariety.plantCode][userPlantVariety.name] = userPlantVariety;
  }

  return newVarietySet;
};

/**
 * Returns true if provided plant code and name is a variety in this set, else returns false
 * @param userPlantVarietySet
 * @param plantCode
 * @param name
 * @param useDbLogic Use the same logic as the database for checking (L/Rtrim all values before checking)
 * @returns
 */
const hasVariety = (userPlantVarietySet: UserPlantVarietySet, plantCode: string, name: string, useDbLogic: boolean = false): boolean => {
  if (!useDbLogic) {
    return !!(userPlantVarietySet.userPlantVarietiesByName[plantCode] && userPlantVarietySet.userPlantVarietiesByName[plantCode][name]);
  }
  // The database stored procedure evaluates 2 varieties as the same if their trimmed names are the same.
  // As we've already got untrimmed varieties in the db, and we can't go back and trim them,
  //  we need to try and make sure not to add any clashes going forward, to prevent errors.
  if (!userPlantVarietySet.userPlantVarietiesByPlantCode[plantCode]) {
    return false;
  }
  const trimmedName = name.trim();
  return userPlantVarietySet.userPlantVarietiesByPlantCode[plantCode].some(({ name: nameToCheck }) => nameToCheck.trim() === trimmedName);
};

/**
 * Returns an obect which is a map of user variety name to user variety within this plant code
 */
const getByPlantCode = (userPlantVarietySet: UserPlantVarietySet, plantCode: string) => {
  if (userPlantVarietySet.userPlantVarietiesByPlantCode[plantCode]) {
    return [...userPlantVarietySet.userPlantVarietiesByPlantCode[plantCode]];
  }

  return [];
};

/**
 * Returns a UserPlantVarity with the given plant code and name if available, else returns null;
 */
const getByPlantCodeAndName = (userPlantVarietySet: UserPlantVarietySet, plantCode: string, name: string) => {
  if (userPlantVarietySet.userPlantVarietiesByName[plantCode] && userPlantVarietySet.userPlantVarietiesByName[plantCode][name]) {
    return userPlantVarietySet.userPlantVarietiesByName[plantCode][name];
  }

  return null;
};

const addVariety = (userPlantVarietySet: UserPlantVarietySet, userPlantVariety: UserPlantVariety): UserPlantVarietySet => {
  if (hasVariety(userPlantVarietySet, userPlantVariety.plantCode, userPlantVariety.name)) {
    throw new Error('User variety already added');
  }

  const newVarietyList = [...userPlantVarietySet.userPlantVarieties, userPlantVariety];
  const newVarietiesByPlantCode = { ...userPlantVarietySet.userPlantVarietiesByPlantCode };
  const newVarietiesByName = { ...userPlantVarietySet.userPlantVarietiesByName };

  if (!userPlantVarietySet.userPlantVarietiesByPlantCode[userPlantVariety.plantCode]) {
    newVarietiesByPlantCode[userPlantVariety.plantCode] = [];
    newVarietiesByName[userPlantVariety.plantCode] = {};
  }

  newVarietiesByPlantCode[userPlantVariety.plantCode] = [...newVarietiesByPlantCode[userPlantVariety.plantCode], userPlantVariety];
  newVarietiesByName[userPlantVariety.plantCode] = {
    ...newVarietiesByName[userPlantVariety.plantCode],
    [userPlantVariety.name]: userPlantVariety,
  };

  return {
    userPlantVarieties: newVarietyList,
    userPlantVarietiesByPlantCode: newVarietiesByPlantCode,
    userPlantVarietiesByName: newVarietiesByName,
  };
};

const addVarieties = (userPlantVarietySet: UserPlantVarietySet, userPlantVarieties: UserPlantVariety[]): UserPlantVarietySet => {
  const newVarietyList = [...userPlantVarietySet.userPlantVarieties];
  const newVarietiesByPlantCode = { ...userPlantVarietySet.userPlantVarietiesByPlantCode };
  const newVarietiesByName = { ...userPlantVarietySet.userPlantVarietiesByName };

  for (let i = 0; i < userPlantVarieties.length; i++) {
    const userPlantVariety = userPlantVarieties[i];
    newVarietyList.push(userPlantVariety);

    if (!userPlantVarietySet.userPlantVarietiesByPlantCode[userPlantVariety.plantCode]) {
      newVarietiesByPlantCode[userPlantVariety.plantCode] = [];
      newVarietiesByName[userPlantVariety.plantCode] = {};
    }

    newVarietiesByPlantCode[userPlantVariety.plantCode].push(userPlantVariety);
    newVarietiesByName[userPlantVariety.plantCode][userPlantVariety.name] = userPlantVariety;
  }

  return {
    userPlantVarieties: newVarietyList,
    userPlantVarietiesByPlantCode: newVarietiesByPlantCode,
    userPlantVarietiesByName: newVarietiesByName,
  };
};

const removeVariety = (userPlantVarietySet: UserPlantVarietySet, plantCode: string, name: string): UserPlantVarietySet => {
  if (!hasVariety(userPlantVarietySet, plantCode, name)) {
    throw new Error('User variety not added');
  }

  const userPlantVariety = getByPlantCodeAndName(userPlantVarietySet, plantCode, name);

  let newVarietyList = [...userPlantVarietySet.userPlantVarieties];
  const newVarietiesByPlantCode = { ...userPlantVarietySet.userPlantVarietiesByPlantCode };
  const newVarietiesByName = { ...userPlantVarietySet.userPlantVarietiesByName };

  if (!userPlantVariety) {
    return {
      userPlantVarieties: newVarietyList,
      userPlantVarietiesByPlantCode: newVarietiesByPlantCode,
      userPlantVarietiesByName: newVarietiesByName,
    };
  }

  // Remove from main array
  {
    const index = userPlantVarietySet.userPlantVarieties.indexOf(userPlantVariety);
    newVarietyList = [...newVarietyList.slice(0, index), ...newVarietyList.slice(index + 1)];
  }

  // Remove from varieties by plant code
  {
    const index = newVarietiesByPlantCode[userPlantVariety.plantCode].indexOf(userPlantVariety);

    newVarietiesByPlantCode[userPlantVariety.plantCode] = [
      ...newVarietiesByPlantCode[userPlantVariety.plantCode].slice(0, index),
      ...newVarietiesByPlantCode[userPlantVariety.plantCode].slice(index + 1),
    ];
  }

  // Remove from varieties by name
  delete newVarietiesByName[userPlantVariety.plantCode][userPlantVariety.name];

  return {
    userPlantVarieties: newVarietyList,
    userPlantVarietiesByPlantCode: newVarietiesByPlantCode,
    userPlantVarietiesByName: newVarietiesByName,
  };
};

const updateVariety = (userPlantVarietySet: UserPlantVarietySet, userPlantVariety: UserPlantVariety) => {
  return addVariety(removeVariety(userPlantVarietySet, userPlantVariety.plantCode, userPlantVariety.name), userPlantVariety);
};

const getDiff = (userPlantVarietySet: UserPlantVarietySet, previousUserPlantVarietySet: UserPlantVarietySet): UserPlantVarietySetDiff => {
  const result = {
    added: [] as UserPlantVariety[],
    updated: [] as UserPlantVariety[],
    removed: [] as UserPlantVariety[],
  };

  if (userPlantVarietySet === previousUserPlantVarietySet) {
    // Both are equal
    return result;
  }

  for (let i = 0; i < userPlantVarietySet.userPlantVarieties.length; i++) {
    const curr = userPlantVarietySet.userPlantVarieties[i];
    const prev = getByPlantCodeAndName(previousUserPlantVarietySet, curr.plantCode, curr.name);

    if (prev === null) {
      result.added.push(curr);
    } else if (curr !== prev && !UserPlantVarietyUtils.equals(curr, prev)) {
      result.updated.push(curr);
    }
  }

  for (let i = 0; i < previousUserPlantVarietySet.userPlantVarieties.length; i++) {
    const prev = previousUserPlantVarietySet.userPlantVarieties[i];
    const curr = getByPlantCodeAndName(userPlantVarietySet, prev.plantCode, prev.name);

    if (curr === null) {
      result.removed.push(prev);
    }
  }

  return result;
};

const addVarietyName = (userPlantVarietySet: UserPlantVarietySet, plantCode: string, varietyName: string) => {
  return addVariety(userPlantVarietySet, {
    ...DEFAULT_USER_PLANT_VARIETY,
    plantCode,
    name: varietyName,
  });
};

export const UserPlantVarietySetUtils = {
  createEmpty,
  create,
  hasVariety,
  getByPlantCode,
  getByPlantCodeAndName,
  addVariety,
  addVarieties,
  removeVariety,
  updateVariety,
  getDiff,
  addVarietyName,
};

/**
 * @deprecated
 * Avoid using PropTypes
 */
export const UserPlantVarietySetShape = PropTypes.shape({
  userPlantVarieties: PropTypes.arrayOf(PropTypes.object).isRequired,
  userPlantVarietiesByPlantCode: PropTypes.object.isRequired,
  userPlantVarietiesByName: PropTypes.object.isRequired,
});
