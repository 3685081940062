import { RequestError, RequestAuthMode, networkService, networkErrorReporter } from '@gi/gi-network';
import { APIShoppingList } from './api-types';
import { parseStore } from './shopping-parser';
import { Store } from '../store-details';

class ProductService {
  private endpoint: string;
  private clientId: number;

  constructor(endpoint: string, clientId: number) {
    this.endpoint = endpoint;
    this.clientId = clientId;
    console.debug(`Created product service with endpoint ${this.endpoint}`);
  }

  getProducts(): Promise<Store> {
    const url = `${this.endpoint}`;
    return networkService
      .get<APIShoppingList>(url, {}, RequestAuthMode.Bearer)
      .then((response) => parseStore(response.body))
      .catch(networkErrorReporter('GET', 'shopping products'))
      .catch((err: RequestError) => {
        throw err;
      });
  }
}

export default ProductService;
