import React from 'react';
import PropTypes from 'prop-types';

import './form.scss';

/**
 * @param {{
 *  className?: string,
 *  children?: import('react').ReactNode
 * }}
 */
const VerticalFormRow = ({ className = '', children = null }) => {
  return <div className={`vertical-form-row ${className}`}>{children}</div>;
};

VerticalFormRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default VerticalFormRow;
