import React, { createContext, useMemo, useRef, useCallback, useEffect, ReactNode } from 'react';

type LazyImageContextType = {
  registerImage: (container: HTMLElement, callback: () => void) => void;
  unregisterImage: (container: HTMLElement) => void;
};

type LazyImageData = {
  element: HTMLElement;
  callback: () => void;
};

const LazyImageContext = createContext<LazyImageContextType>({} as LazyImageContextType);

interface iProps {
  children: ReactNode;
  containerRef: HTMLElement | null;
}

export const LazyImageProvider = ({ children, containerRef }: iProps): JSX.Element => {
  const images = useRef<LazyImageData[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);

  const removeObserver = useCallback((item: LazyImageData) => {
    observer.current?.unobserve(item.element);
    const index = images.current.indexOf(item);
    if (index !== -1) {
      images.current.splice(index, 1);
    }
  }, []);

  const onIntersection = useCallback<IntersectionObserverCallback>((events) => {
    events.forEach((event) => {
      if (event.isIntersecting) {
        const match = images.current.find(({ element }) => element === event.target);
        if (match) {
          match.callback();
          removeObserver(match);
        }
      }
    });
  }, []);

  useEffect(() => {
    const newObserver = new IntersectionObserver(onIntersection, {
      // root: containerRef,
      threshold: 0,
    });
    images.current.forEach(({ element }) => newObserver.observe(element));

    observer.current = newObserver;

    return () => {
      newObserver.disconnect();
      observer.current = null;
    };
  }, [containerRef]);

  const registerImage = useCallback((element: HTMLElement, callback: () => void) => {
    const match = images.current.find((image) => image.element === element);
    if (!match) {
      images.current.push({ element, callback });
      observer.current?.observe(element);
    }
  }, []);

  const unregisterImage = useCallback((element: HTMLElement) => {
    const match = images.current.find((image) => image.element === element);
    if (match) {
      removeObserver(match);
    }
  }, []);

  const value = useMemo<LazyImageContextType>(
    () => ({
      registerImage,
      unregisterImage,
    }),
    [registerImage, unregisterImage]
  );

  return <LazyImageContext.Provider value={value}>{children}</LazyImageContext.Provider>;
};

export default LazyImageContext;
