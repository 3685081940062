import { KeyboardEventData, KeyboardEventType } from '../../managers/interaction/keyboard-tracker';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { hasEngine } from '../../utils/asserts';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';

interface KeyboardInteractionCallbacks {
  onKeyDown: (event: KeyboardEventData) => void;
  onKeyUp: (event: KeyboardEventData) => void;
  onKeyChange: (event: KeyboardEventData) => void;
}

class KeybindComponent extends NodeComponent {
  type = 'KeybindComponent';

  #keybind: (string | string[])[];
  #requireFocus: boolean;
  #eventListenerIds: number[] = [];
  #isKeybindDown: boolean;

  #onKeyDown: KeyboardInteractionCallbacks['onKeyDown'] | null = null;
  get onKeyDown() {
    return this.#onKeyDown;
  }
  set onKeyDown(onKeyDown: KeyboardInteractionCallbacks['onKeyDown'] | null) {
    this.#onKeyDown = onKeyDown;
  }

  #onKeyUp: KeyboardInteractionCallbacks['onKeyUp'] | null = null;
  get onKeyUp() {
    return this.#onKeyUp;
  }
  set onKeyUp(onKeyUp: KeyboardInteractionCallbacks['onKeyUp'] | null) {
    this.#onKeyUp = onKeyUp;
  }

  #onKeyChange: KeyboardInteractionCallbacks['onKeyChange'] | null = null;
  get onKeyChange() {
    return this.#onKeyChange;
  }
  set onKeyChange(onKeyChange: KeyboardInteractionCallbacks['onKeyChange'] | null) {
    this.#onKeyChange = onKeyChange;
  }

  /**
   * Sets up listeners for when a key or combination of keys is pressed.
   * Syntax for keybind:
   *
   *  Inner groups are OR'd, outer groups are AND'd. Groups are treated as outer groups unless double-nested.
   *
   * `[['shift', 'control'], 'a']` = `('shift' | 'control') & 'a'`
   *
   * `['delete', 'backspace']` = `'delete' & 'backspace'`
   *
   * `[['delete', 'backspace']]` = `('delete') | ('backspace')`
   * @param keybind Which keybinds to listen to
   */
  constructor(keybind: (string | string[])[], requireFocus: boolean = false) {
    super();

    this.#keybind = keybind;
    this.#requireFocus = requireFocus;
    this.#isKeybindDown = false;

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);
    this.#setUpListeners();
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    this.#tearDownListeners();
  };

  #setUpListeners = () => {
    if (this.owner && this.owner.engine) {
      const keyDownId = this.owner.engine.interactionManager.keyboardTracker.addEventListener(KeyboardEventType.KeyDown, this.#keybind, this.#handleKeyDown);
      const keyUpId = this.owner.engine.interactionManager.keyboardTracker.addEventListener(KeyboardEventType.KeyUp, this.#keybind, this.#handleKeyUp);
      const keyChangeId = this.owner.engine.interactionManager.keyboardTracker.addEventListener(
        KeyboardEventType.KeyChange,
        this.#keybind,
        this.#handleKeyChange
      );
      this.#eventListenerIds = [keyDownId, keyUpId, keyChangeId];
    }
  };

  #tearDownListeners = () => {
    if (this.owner && this.owner.engine) {
      this.#eventListenerIds.forEach((id) => {
        this.owner!.engine!.interactionManager.keyboardTracker.removeEventListener(id);
      });
    }
  };

  setKeybind(keybind: (string | string[])[]) {
    this.#tearDownListeners();
    this.#keybind = keybind;
    this.#setUpListeners();
  }

  isKeybindDown() {
    hasEngine(this);
    return this.owner.engine.interactionManager.keyboardTracker.isKeyComboDown(this.#keybind);
  }

  #handleKeyDown = (event: KeyboardEventData) => {
    if (this.#requireFocus) {
      // If we require focus to run, make sure the target is the canvas from the engine.
      if (event.target && event.target === this.owner?.engine?.container) {
        this.#isKeybindDown = true;
        this.#onKeyDown?.(event);
      }
    } else {
      this.#isKeybindDown = true;
      this.#onKeyDown?.(event);
    }
  };

  #handleKeyUp = (event: KeyboardEventData) => {
    if (this.#requireFocus) {
      // Trigger keyups regardless of focus, as long as they were previously pushed down with focus
      if (this.#isKeybindDown) {
        this.#isKeybindDown = false;
        this.#onKeyUp?.(event);
      }
    } else {
      this.#isKeybindDown = false;
      this.#onKeyUp?.(event);
    }
  };

  #handleKeyChange = (event: KeyboardEventData) => {
    if (this.#requireFocus) {
      // Register keychanges only if we have focus or the keys are already down
      if (this.#isKeybindDown || (event.target && event.target === this.owner?.engine?.container)) {
        this.#onKeyChange?.(event);
      }
    } else {
      this.#onKeyChange?.(event);
    }
  };

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.CustomProperty,
      propertyType: InspectableClassPropertyType.String,
      displayName: 'keybind',
      value: () => {
        return this.#keybind.map((group) => (typeof group === 'string' ? group : `(${group.join(' | ')})`)).join(' & ');
      },
    },
  ];
}

export default KeybindComponent;
