/**
 * @typedef {string} RequestActionType
 */
/**
 * Enum for the types of request action creators
 *
 * @readonly
 * @enum {RequestActionType}
 */
const RequestActionTypes = {
  START_REQUEST: 'START_REQUEST',
  REQUEST_COMPLETE: 'REQUEST_COMPLETE',
  REQUEST_FAIL: 'REQUEST_FAIL',
  CLEAR_REQUEST: 'CLEAR_REQUEST',
};

export default RequestActionTypes;
