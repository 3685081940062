import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Plan from '@gi/plan';
import { AppContext } from '@gi/app-provider';
import { SessionSelectors } from '@gi/react-session';

import { ModalPane, ModalPaneSectionGroup, ModalPaneContainer, ModalPaneSection, ModalPaneSectionContent, ModalPaneSectionHeader } from '@gi/modal';

import PublishPlanSharePlansWarning from './publish-plan-share-plans-warning';
import { sharePlans } from '../publish-action-creators';

interface iProps {
  plan: Plan;
}

const PublishPlanInfo = ({ plan }: iProps): JSX.Element | null => {
  const { runtimeConfig } = useContext(AppContext);
  const user = useSelector(SessionSelectors.getUser);
  const dispatch = useDispatch();

  const onSharePlans = () => {
    dispatch(sharePlans());
  };

  if (!user || !plan.published) {
    return null;
  }

  return (
    <ModalPaneContainer className='publish-plan-info'>
      <ModalPane>
        <ModalPaneSectionGroup>
          <ModalPaneSection>
            <ModalPaneSectionHeader>Previously Published Plan</ModalPaneSectionHeader>
            <ModalPaneSectionContent>
              <p>
                Your plan is available on the following webpage:{' '}
                <a target='published_plan' rel='noreferrer' href={`${runtimeConfig.publishPlanURL}${plan.id}`}>{`${runtimeConfig.publishPlanURL}${plan.id}`}</a>
              </p>
            </ModalPaneSectionContent>
          </ModalPaneSection>
          <PublishPlanSharePlansWarning onSharePlans={onSharePlans} />
        </ModalPaneSectionGroup>
      </ModalPane>
    </ModalPaneContainer>
  );
};

export default PublishPlanInfo;
