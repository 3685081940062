import { RequestError } from '@gi/gi-network';
import getTutorialsErrorCodes from './error-codes/get-tutorials-error-codes.json';

export function attachGetTutorialsErrorClientMessage(responseError: RequestError) {
  if (responseError.statusCode && getTutorialsErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = getTutorialsErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown get tutorials error - ${responseError.statusCode}`;
  }
}
