import { TutorialContext, TutorialUtils, Tutorials, type Tutorial } from '@gi/tutorial';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TutorialParseError } from './tutorial-editor-types';
import { validateTutorialString } from './tutorial-json-validator';

export type TutorialEditorContextType = {
  editingTutorials: Tutorials;
  selectedTutorial: Tutorial | null;
  setSelectedTutorial: (tutorial: Tutorial | null) => void;
  editingTutorial: Tutorial | null;
  setEditingTutorial: (tutorial: Tutorial | null) => void;
  setEditingTutorials: (tutorials: Tutorials) => void;
  editingErrors: TutorialParseError[] | null;
  setEditingErrors: (tutorial: null | TutorialParseError[]) => void;
  saveTutorial: (objString: string) => void;
};

export const TutorialEditorContext = createContext<TutorialEditorContextType>({} as TutorialEditorContextType);

interface iProps {
  children: React.ReactNode;
}

export const TutorialEditorProvider = ({ children }: iProps): JSX.Element => {
  const { tutorials } = useContext(TutorialContext);
  const [editingTutorials, setEditingTutorials] = useState<Tutorials>(tutorials ? tutorials : TutorialUtils.createEmptyTutorials());
  const [selectedTutorial, setSelectedTutorial] = useState<Tutorial | null>(null);
  const [editingTutorial, setEditingTutorial] = useState<Tutorial | null>(null);
  const [editingErrors, setEditingErrors] = useState<null | TutorialParseError[]>(null);

  // Update tutorials when they're first loaded
  useEffect(() => {
    if (editingTutorials.tutorials.length === 0 && tutorials !== null && tutorials.tutorials.length > 0) {
      setEditingTutorials(tutorials);
    }
  }, [tutorials]);

  const saveTutorial = useCallback(
    (objString: string) => {
      const result = validateTutorialString(objString);

      if (result === true) {
        setEditingErrors(null);
        const tutorial = JSON.parse(objString);
        setEditingTutorials(TutorialUtils.updateTutorial(editingTutorials, tutorial));
        setEditingTutorial(tutorial);
        return;
      }

      setEditingErrors(result);
    },
    [editingTutorials]
  );

  const value = useMemo(
    () => ({
      editingTutorials,
      setEditingTutorials,
      selectedTutorial,
      setSelectedTutorial,
      editingTutorial,
      setEditingTutorial,
      editingErrors,
      setEditingErrors,
      saveTutorial,
    }),
    [editingTutorials, selectedTutorial, editingTutorial, editingErrors, saveTutorial]
  );

  return <TutorialEditorContext.Provider value={value}>{children}</TutorialEditorContext.Provider>;
};
