import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreator } from 'redux';

import { UserPlan, UserPlanSetUtils, UserPlanUtils, UserUtils } from '@gi/user';
import { FollowOnPlanOptions, MAX_PLAN_YEAR, createNewPlan, PlanUtils } from '@gi/plan';

import { RequestStatus, RequestsUtils } from '@gi/request';
import LoadingButton from '@gi/loading-button';
import { RequestKeyCreators, RequestSelectors } from '@gi/react-requests';
import { createFormValues } from '@gi/form-responsive';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { SessionSelectors } from '@gi/react-session';
import { ResourceContext } from '@gi/resource-provider';
import { StringValidators, PlanValidators, Validators } from '@gi/validators';

import {
  Modal,
  ModalPane,
  ModalPaneContent,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalTabs,
  ModalTabContentContainer,
  ModalTabContent,
  ModalTabList,
  ModalTab,
} from '@gi/modal';

import BlankPlanTab, { BlankPlanFormValues } from './blank-plan-tab';

import './new-plan-modal.scss';
import TemplatePlanTab, { TemplatePlanFormValues } from './template-plan-tab';
import { createPlan, createFollowOnPlan, createPlanFromTemplatePlan } from './new-plan-modal-action-creators';

const DEFAULT_PLAN_WIDTH = 1000;
const DEFAULT_PLAN_HEIGHT = 1000;

interface iInitalStateValues {
  initialName: string;
  initialSamplePlanName: string;
  initialYear: number;
  initialSamplePlanYear: number;
  initialWidth: number;
  initialHeight: number;
  initialFollowOnPlanEnabled: boolean;
}

function getInitialValues(defaultFollowOnPlan: null | UserPlan, northernHemisphere: boolean): iInitalStateValues {
  const defaultYear = PlanUtils.calculateNewDefaultPlanYear(northernHemisphere);

  return {
    initialName: defaultFollowOnPlan === null ? '' : defaultFollowOnPlan.name,
    initialSamplePlanName: '',
    initialYear: defaultFollowOnPlan === null ? defaultYear : Math.min(Math.max(defaultYear, defaultFollowOnPlan.year + 1), MAX_PLAN_YEAR),
    initialSamplePlanYear: defaultYear,
    initialWidth: defaultFollowOnPlan === null ? DEFAULT_PLAN_WIDTH : defaultFollowOnPlan.width,
    initialHeight: defaultFollowOnPlan === null ? DEFAULT_PLAN_HEIGHT : defaultFollowOnPlan.height,
    initialFollowOnPlanEnabled: defaultFollowOnPlan !== null,
  };
}

const DEFAULT_FOLLOW_ON_PLAN_OPTIONS: FollowOnPlanOptions = {
  copyLayout: true,
  copyStructures: true,
  copyIrrigation: true,
  copyText: true,
  copyPlants: true,
  allPlants: false,
  copyNotes: false,
};

interface iProps {
  closeNewPlanModal: ActionCreator<any>;
}

const NewPlanModal = ({ closeNewPlanModal }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const user = useSelector(SessionSelectors.getUser);

  // User should never be null here so it's safe to return before all hooks have run
  if (user === null) {
    return null;
  }

  const { userPlants, userGardenObjects } = useContext(ResourceContext);

  const plans = useSelector(CanvasSelectors.getPlans);
  const lastSavePlans = useSelector(CanvasSelectors.getLastSavePlans);
  const requests = useSelector(RequestSelectors.getRequests);
  const defaultFollowOnPlanID: number | null = useSelector((state: any) => state.modal.newPlanModalActivePlan);
  const defaultFollowOnUserPlan: UserPlan | null = useMemo(() => {
    if (defaultFollowOnPlanID === null) {
      return null;
    }

    return UserPlanSetUtils.getPlan(user.plans, defaultFollowOnPlanID);
  }, []);

  const sortedUserPlans: UserPlan[] = useMemo(() => {
    return [...user.plans.list].sort(UserPlanUtils.defaultSort);
  }, [user.plans]);

  const latestUserPlanID: number | null = useMemo(() => {
    if (sortedUserPlans.length === 0) {
      return null;
    }

    return sortedUserPlans[0].ID;
  }, [sortedUserPlans]);

  const [activeTab, setActiveTab] = useState<string>('blank-plan');

  const { initialName, initialSamplePlanName, initialYear, initialSamplePlanYear, initialWidth, initialHeight, initialFollowOnPlanEnabled } = useMemo(
    () => getInitialValues(defaultFollowOnUserPlan, UserUtils.isNorthernHemisphere(user)),
    []
  );

  const [blankPlanFormValues, setBlankPlanFormValues] = useState(
    createFormValues<BlankPlanFormValues>({
      name: {
        value: initialName,
        validators: [StringValidators.minLength(1), StringValidators.maxLength(200), PlanValidators.isUniquePlanName(sortedUserPlans, initialYear)],
      },
      year: {
        value: initialYear,
        validators: [PlanValidators.notReachedPlanLimit(sortedUserPlans, user.settings.maxPlansPerYear)],
      },
      metricDimensions: { value: user.settings.units.metric },
      width: {
        value: initialWidth,
        validators: [PlanValidators.isValidDimension(user.settings.units.metric)],
      },
      height: {
        value: initialHeight,
        validators: [PlanValidators.isValidDimension(user.settings.units.metric)],
      },
      followOnPlanEnabled: { value: initialFollowOnPlanEnabled }, // TODO: Check initial value
      selectedFollowOnPlanID: {
        value: initialFollowOnPlanEnabled ? defaultFollowOnPlanID : latestUserPlanID,
      },
      followOnPlanOptions: { value: DEFAULT_FOLLOW_ON_PLAN_OPTIONS },
    })
  );

  const [templatePlanFormValues, setTemplatePlanFormValues] = useState(
    createFormValues<TemplatePlanFormValues>({
      name: {
        value: initialSamplePlanName,
        validators: [StringValidators.minLength(1), StringValidators.maxLength(200), PlanValidators.isUniquePlanName(sortedUserPlans, initialSamplePlanYear)],
      },
      year: {
        value: initialSamplePlanYear,
        validators: [PlanValidators.notReachedPlanLimit(sortedUserPlans, user.settings.maxPlansPerYear)],
      },
      metricDimensions: { value: user.settings.units.metric },
      selectedTemplatePlan: {
        value: null,
        validators: [Validators.isNotNull('A template plan must be selected')],
      },
    })
  );

  // Destructure values to make them easier to access
  const { name, year, metricDimensions, width, height, followOnPlanEnabled, selectedFollowOnPlanID, followOnPlanOptions } = blankPlanFormValues.values;
  const { year: templatePlanYear } = templatePlanFormValues.values;

  // Update validators
  useEffect(() => {
    setBlankPlanFormValues((newBlankPlanFormValues) =>
      newBlankPlanFormValues.setValues([
        'name',
        {
          validators: [StringValidators.minLength(1), StringValidators.maxLength(200), PlanValidators.isUniquePlanName(sortedUserPlans, year)],
        },
      ])
    );
  }, [sortedUserPlans, year]);

  useEffect(() => {
    setTemplatePlanFormValues((newTemplatePlanFormValues) =>
      newTemplatePlanFormValues.setValues([
        'name',
        {
          validators: [StringValidators.minLength(1), StringValidators.maxLength(200), PlanValidators.isUniquePlanName(sortedUserPlans, templatePlanYear)],
        },
      ])
    );
  }, [sortedUserPlans, templatePlanYear]);

  useEffect(() => {
    setBlankPlanFormValues((newBlankPlanFormValues) =>
      newBlankPlanFormValues.setValues([
        'year',
        {
          validators: [PlanValidators.notReachedPlanLimit(sortedUserPlans, user.settings.maxPlansPerYear)],
        },
      ])
    );
    setTemplatePlanFormValues((newTemplatePlanFormValues) =>
      newTemplatePlanFormValues.setValues([
        'year',
        {
          validators: [PlanValidators.notReachedPlanLimit(sortedUserPlans, user.settings.maxPlansPerYear)],
        },
      ])
    );
  }, [sortedUserPlans, user.settings.maxPlansPerYear]);

  useEffect(() => {
    setBlankPlanFormValues((newBlankPlanFormValues) =>
      newBlankPlanFormValues.setValues(
        ['width', { validators: [PlanValidators.isValidDimension(metricDimensions)] }],
        ['height', { validators: [PlanValidators.isValidDimension(metricDimensions)] }]
      )
    );
  }, [metricDimensions]);

  const onCreatePlan = () => {
    if (activeTab === 'blank-plan') {
      if (followOnPlanEnabled && selectedFollowOnPlanID) {
        // Follow on plan
        const plan = createNewPlan(user.ID, name, year, width, height, metricDimensions);
        dispatch(createFollowOnPlan(plan, selectedFollowOnPlanID, userPlants, userGardenObjects, followOnPlanOptions, closeNewPlanModal));
      } else {
        // Blank Plan
        const plan = createNewPlan(user.ID, name, year, width, height, metricDimensions);

        dispatch(createPlan(plan, closeNewPlanModal));
      }
    } else {
      // From sample plan
      // eslint-disable-next-line no-lonely-if
      if (templatePlanFormValues.values.selectedTemplatePlan !== null) {
        dispatch(
          createPlanFromTemplatePlan(
            user.ID,
            templatePlanFormValues.values.name,
            templatePlanFormValues.values.year,
            templatePlanFormValues.values.metricDimensions,
            templatePlanFormValues.values.selectedTemplatePlan,
            closeNewPlanModal,
            userPlants,
            userGardenObjects
          )
        );
      }
    }
  };

  const planCreating = RequestsUtils.getStatus(requests, `CREATE_PLAN_${user.ID}`) === RequestStatus.IN_PROGRESS;

  const isSavingFollowOnPlan = useMemo(() => {
    if (!followOnPlanEnabled || selectedFollowOnPlanID === null) {
      return false;
    }

    return RequestsUtils.getStatus(requests, RequestKeyCreators.createSavePlanRequestKey(selectedFollowOnPlanID)) === RequestStatus.IN_PROGRESS;
  }, [followOnPlanEnabled, selectedFollowOnPlanID, requests]);

  const valid = activeTab === 'blank-plan' ? blankPlanFormValues.isValid : templatePlanFormValues.isValid;

  return (
    <Modal className='new-plan-modal' closeRequest={closeNewPlanModal}>
      <ModalContent>
        <ModalCloseButton onClick={closeNewPlanModal} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Create a New Plan</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalTabs defaultActiveTab={activeTab} onTabChange={setActiveTab}>
          <ModalTabList>
            <ModalTab tabID='blank-plan'>Blank Plan</ModalTab>
            <ModalTab tabID='sample-plan'>Sample Plan</ModalTab>
          </ModalTabList>
          <ModalTabContentContainer>
            <ModalTabContent tabID='blank-plan'>
              <ModalPaneContainer>
                <ModalPane className='open-plan-modal-pane'>
                  <ModalPaneContent>
                    <BlankPlanTab
                      blankPlanFormValues={blankPlanFormValues}
                      setBlankPlanFormValues={setBlankPlanFormValues}
                      user={user}
                      plans={plans}
                      lastSavePlans={lastSavePlans}
                      sortedUserPlans={sortedUserPlans}
                    />
                  </ModalPaneContent>
                </ModalPane>
              </ModalPaneContainer>
            </ModalTabContent>
            <ModalTabContent tabID='sample-plan'>
              <ModalPaneContainer>
                <ModalPane className='open-plan-modal-pane'>
                  <ModalPaneContent>
                    <TemplatePlanTab templatePlanFormValues={templatePlanFormValues} setTemplatePlanFormValues={setTemplatePlanFormValues} user={user} />
                  </ModalPaneContent>
                </ModalPane>
              </ModalPaneContainer>
            </ModalTabContent>
          </ModalTabContentContainer>
        </ModalTabs>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={closeNewPlanModal}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <LoadingButton
                buttonIcon='icon-doc'
                className='button-primary'
                onClick={onCreatePlan}
                loading={planCreating}
                disabled={planCreating || !valid || isSavingFollowOnPlan}
              >
                Create Plan
              </LoadingButton>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewPlanModal;
