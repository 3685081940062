import { Filter } from '@gi/filters';
import Plant from '@gi/plant';
import { PERENNIAL } from '@gi/constants';

type PerennialFilterState = {
  enabled: boolean;
};

const perennialFilter: Filter<Plant, PerennialFilterState> = {
  name: 'Perennial',
  inputs: { enabled: true },
  createListFilter: ({ enabled }, getInputs) => {
    // Get the type filter value and check if it is set to perennial
    const perennialTypeFilterSelected = getInputs().type.type === PERENNIAL;
    return (plantList) => {
      if (enabled) {
        return plantList;
      }

      if (perennialTypeFilterSelected) {
        return plantList;
      }

      return plantList.filter((plant) => !plant.perennial);
    };
  },
};

export default perennialFilter;
