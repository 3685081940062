import React, { useContext, useState } from 'react';

import { DistanceUnits } from '@gi/units';

import TableRow from '../common/table-row';
import { PlantListSummaryGroup } from '../types/plant-list-types';
import CalendarCell from './cells/calendar-cell';
import FavouriteCell from './cells/favourite-cell';
import IconCell from './cells/icon-cell';
import InformationCell from './cells/information-cell';
import SpacingsCell from './cells/spacings-cell';
import PlantNameCell from './cells/plant-name-cell';
import CountsCell from './cells/counts-cell';
import InsetAreaTableRows from './inset-area-table-rows';
import ShowAreaTableCell from './cells/show-area-table-cell';
import GlobalPlantListContext from '../global-provider/global-plant-list-context';

interface iProps {
  plantListRowGroup: PlantListSummaryGroup;
  evenRow?: boolean;
  distanceUnits: DistanceUnits;
}

const SummaryTableRow = ({ plantListRowGroup, evenRow = true, distanceUnits }: iProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const { plantGroupFilters, columns } = useContext(GlobalPlantListContext);

  const { plantSearchResults } = plantGroupFilters.filters.search.inputs;

  return (
    <>
      <TableRow className={`plant-tr main-tr  ${evenRow ? 'tr-even' : 'tr-odd'}`}>
        <ShowAreaTableCell expanded={expanded} setExpanded={setExpanded} />
        <InformationCell plant={plantListRowGroup.plant} />
        <FavouriteCell plant={plantListRowGroup.plant} />
        <IconCell plantCode={plantListRowGroup.plant.code} familyID={plantListRowGroup.plant.familyID} />
        <PlantNameCell plant={plantListRowGroup.plant} showVariety={false} plantSearchResults={plantSearchResults} />
        <SpacingsCell spacings={plantListRowGroup.spacing} countsByType={plantListRowGroup.countsByType} distanceUnits={distanceUnits} />
        <CountsCell count={plantListRowGroup.count} />
        <CalendarCell plantingCalendar={plantListRowGroup.plantingCalendar} />
      </TableRow>
      {expanded && columns.area ? <InsetAreaTableRows className={evenRow ? 'tr-even' : 'tr-odd'} areaGroups={plantListRowGroup.areas} showVariety /> : null}
    </>
  );
};

export default SummaryTableRow;
