/**
 * Enum for the different types of modals handled by the modal-renderer and modal-reducer
 * @readonly
 * @enum {string}
 */
const ModalTypes = {
  OPEN_PLAN: 'OPEN_PLAN',
  EDIT_ITEM: 'EDIT_ITEM',
  PUBLISH_PLAN_MODAL: 'PUBLISH_PLAN_MODAL',
};

export default ModalTypes;
