import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Modal, {
  ModalPane,
  ModalHeader,
  ModalHeaderContent,
  ModalPaneContainer,
  ModalContent,
  ModalCloseButton,
  ModalPaneSection,
  ModalPaneSectionContent,
  ModalHeaderTitle,
} from '@gi/modal';

import './customise-varieties-help-modal.scss';

class CustomiseVarietiesHelpModal extends PureComponent {
  render() {
    return (
      <Modal className='customise-varieties-help-modal' closeRequest={this.props.closeModal}>
        <ModalContent>
          <ModalCloseButton onClick={this.props.closeModal} />
          <ModalHeader>
            <ModalHeaderContent>
              <ModalHeaderTitle>Customize Varieties Help</ModalHeaderTitle>
            </ModalHeaderContent>
          </ModalHeader>
          <ModalPaneContainer>
            <ModalPane>
              <ModalPaneSection>
                <ModalPaneSectionContent>
                  <p>Customize varieties with custom spacings and sow/plant/harvest times.</p>
                  <p>
                    Customize varieties with custom spacings and sow/plant/harvest times. Select a variety from the varieties drop-down menu to view its current
                    spacing and planting dates. You can filter varieties available in the dropdown menu to show only a specific seed supplier&apos;s catalog or
                    to only show your custom varieties.
                  </p>
                  <p>
                    The variety dropdown menu has icons to indicate whether it is a variety from a catalog or a custom variety you created yourself. Both icons
                    mean it is a catalog variety that you have customized.
                  </p>
                  <h3>Editing</h3>
                  <p>
                    To edit a variety, click the Edit button under the variety name. All other actions are disabled when editing, so click Done when finished.
                    Cancel will reset all values of the current variety to what they were before you started editing.
                  </p>
                  <h4>Spacings &amp; SFG Plant count</h4>
                  <p>
                    To customize spacing check &apos;Use Custom Spacings&apos; and change the values in the input boxes. Plants that are too large for Square
                    Foot Gardening cannot have a custom SFG plant count set.
                  </p>
                  <h4>Sow, Plant and Harvest Times</h4>
                  <p>To customize dates, check &apos;Use Custom Times&apos; and click on a half-month to toggle.</p>
                  <p>
                    All other actions are disabled when editing, so click done or cancel when finished. Cancel will reset all values of the current variety to
                    what they were before you started editing.
                  </p>
                  <h4>Importing</h4>
                  <p>
                    Importing allows you to copy spacings and planting times from another variety into this one. If you have other customized varieties of the
                    same plant it will show you those plants, otherwise it will show all of your custom varieties.
                  </p>
                  <p>
                    Simply check the values you want to import (spacings and planting times) and click &apos;Import Properties&apos;. Those values will be
                    copied into the current variety you&apos;re editing.
                  </p>
                  <h4>Reset</h4>
                  <p>
                    Reset will remove any custom spacings and planting times from the current variety. It is only available if you have custom spacings or
                    planting times set.
                  </p>
                  <h4>Delete</h4>
                  <p>
                    Delete will remove the current custom variety, this is only available for varieties you&apos;ve created yourself. To clear other varieties
                    of custom values, use the reset button instead.
                  </p>
                </ModalPaneSectionContent>
              </ModalPaneSection>
            </ModalPane>
          </ModalPaneContainer>
        </ModalContent>
      </Modal>
    );
  }
}

CustomiseVarietiesHelpModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default CustomiseVarietiesHelpModal;
