import { GardenObjectType } from '@gi/constants';
import { RichTextDefinition } from '@gi/core-renderer';
import GardenObject from '@gi/garden-object';
import { Geometry, MathUtils } from '@gi/math';
import { DistanceUnits } from '@gi/units';

const getInnerBlockTooltipText = (gardenObject: GardenObject, dimensions: Dimensions, distanceUnits: DistanceUnits): string | null => {
  if (gardenObject.shape.type !== GardenObjectType.BLOCK) {
    return null;
  }

  if (gardenObject.shape.internalWidthMargin === 0 && gardenObject.shape.internalHeightMargin === 0) {
    return null;
  }

  const width = Math.max(0, dimensions.width - gardenObject.shape.internalWidthMargin * 2);
  const height = Math.max(0, dimensions.height - gardenObject.shape.internalHeightMargin * 2);
  return `${distanceUnits.getUnitString(width)} × ${distanceUnits.getUnitString(height)}`;
};

/**
 * Creates tooltip text for a block garden object
 * @param gardenObject The garden object
 * @param dimensions The width and height of the object
 * @param distanceUnits The distance units to use
 * @returns A formatted string
 */
export const getBlockTooltipText = (gardenObject: GardenObject, dimensions: Dimensions, distanceUnits: DistanceUnits): RichTextDefinition => {
  const outerSizeTooltip = `${distanceUnits.getUnitString(dimensions.width)} × ${distanceUnits.getUnitString(dimensions.height)}`;
  const innerSizeTooltip = getInnerBlockTooltipText(gardenObject, dimensions, distanceUnits);

  const tooltip: RichTextDefinition = [{ text: `${gardenObject.name}`, bold: true }];

  if (innerSizeTooltip === null) {
    tooltip.push({ text: `\n${outerSizeTooltip}` });
  } else {
    tooltip.push({ text: `\nInside: ${innerSizeTooltip}` });
    tooltip.push({ text: `\nOutside: ${outerSizeTooltip}` });
  }
  return tooltip;
};

/**
 * Creates tooltip text representing a garden object path
 * @param gardenObject The path garden object
 * @param length The length of the path
 * @param distanceUnits The distance units to use
 * @param start The start point of the path
 * @param mid The control point (if applicable)
 * @param end The end point
 * @returns A formatted string
 */
export const getPathTooltipText = (
  gardenObject: GardenObject,
  length: number,
  distanceUnits: DistanceUnits,
  start: Vector2,
  mid: Vector2 | null,
  end: Vector2
): RichTextDefinition => {
  let angle = '';
  if (mid === null) {
    angle = ` · ${((MathUtils.radToDeg(Geometry.angleBetweenPoints(start, end)) + 360) % 360).toFixed(0)}°`;
  }

  return [{ text: `${gardenObject.name}`, bold: true }, { text: `\n${distanceUnits.getUnitString(length)}${angle}` }];
};
