import { LayerTypes, LayerType } from '@gi/constants';
import PlanNode from './nodes/plan-node';
import PlannerLayerNode from './nodes/planner-layer-node';

const LayerZIndexes = {
  BACKGROUND: 0,
  LAYOUT: 1,
  // The old renderer effectively placed the crop rotation history layer here.
  // It had a Z-index of 0, but was added after the layout layer, so appeared on top of it.
  CROP_ROTATION_HISTORY: 2,
  IRRIGATION: 3,
  PLANTS: 4,
  STRUCTURES: 5,
  TEXT: 6,
  PLANT_LABEL: 7,
  // Works as a modifier, getting added to the original z-index for selected layers.
  // Ensure this is larger than any of the above z-indexes for sleetced layers to appear on top.
  SELECTED: 10,
  // Drawing layer z-index. Ensure this is bigger than the SELECTED z-index + the largest layer
  // z-index to ensure objects being drawn appear on top of everything.
  DRAWING: 20,
} as const;

/**
 * Simple class to contain layers for the garden planner
 */
class CanvasLayers {
  backgroundLayer: PlannerLayerNode;
  selectedBackgroundLayer: PlannerLayerNode;
  layoutLayer: PlannerLayerNode;
  selectedLayoutLayer: PlannerLayerNode;
  irrigationLayer: PlannerLayerNode;
  selectedIrrigationLayer: PlannerLayerNode;
  plantsLayer: PlannerLayerNode;
  selectedPlantsLayer: PlannerLayerNode;
  structuresLayer: PlannerLayerNode;
  selectedStructuresLayer: PlannerLayerNode;
  textLayer: PlannerLayerNode;
  selectedTextLayer: PlannerLayerNode;
  plantLabelLayer: PlannerLayerNode;
  selectedPlantLabelLayer: PlannerLayerNode;
  cropRotationHistoryLayer: PlannerLayerNode;
  drawingPreviewLayer: PlannerLayerNode;

  setup(planNode: PlanNode) {
    this.backgroundLayer = new PlannerLayerNode('Background', LayerZIndexes.BACKGROUND);
    this.selectedBackgroundLayer = new PlannerLayerNode('Selected Background', LayerZIndexes.BACKGROUND);
    this.layoutLayer = new PlannerLayerNode('Layout', LayerZIndexes.LAYOUT);
    this.selectedLayoutLayer = new PlannerLayerNode('Selected Layout', LayerZIndexes.LAYOUT + LayerZIndexes.SELECTED);
    this.irrigationLayer = new PlannerLayerNode('Irrigation', LayerZIndexes.IRRIGATION);
    this.selectedIrrigationLayer = new PlannerLayerNode('Selected Irrigation', LayerZIndexes.IRRIGATION + LayerZIndexes.SELECTED);
    this.plantsLayer = new PlannerLayerNode('Plants', LayerZIndexes.PLANTS);
    this.selectedPlantsLayer = new PlannerLayerNode('Selected Plants', LayerZIndexes.PLANTS + LayerZIndexes.SELECTED);
    this.structuresLayer = new PlannerLayerNode('Structures', LayerZIndexes.STRUCTURES);
    this.selectedStructuresLayer = new PlannerLayerNode('Selected Structures', LayerZIndexes.STRUCTURES + LayerZIndexes.SELECTED);
    this.textLayer = new PlannerLayerNode('Text', LayerZIndexes.TEXT);
    this.selectedTextLayer = new PlannerLayerNode('Selected Text', LayerZIndexes.TEXT + LayerZIndexes.SELECTED);
    this.plantLabelLayer = new PlannerLayerNode('Plant Labels', LayerZIndexes.PLANT_LABEL);
    this.selectedPlantLabelLayer = new PlannerLayerNode('Selected Plant Labels', LayerZIndexes.PLANT_LABEL + LayerZIndexes.SELECTED);
    this.cropRotationHistoryLayer = new PlannerLayerNode('Plan History', LayerZIndexes.CROP_ROTATION_HISTORY);
    this.drawingPreviewLayer = new PlannerLayerNode('Drawing Preview', LayerZIndexes.DRAWING);

    planNode.addChildren(
      this.backgroundLayer,
      this.selectedBackgroundLayer, // Keep background images behind everything still
      this.layoutLayer,
      this.irrigationLayer,
      this.plantsLayer,
      this.structuresLayer,
      this.textLayer,
      this.plantLabelLayer,
      this.cropRotationHistoryLayer,
      this.selectedLayoutLayer,
      this.selectedIrrigationLayer,
      this.selectedPlantsLayer,
      this.selectedStructuresLayer,
      this.selectedTextLayer,
      this.selectedPlantLabelLayer,
      this.drawingPreviewLayer
    );
  }

  /**
   * Returns the appropriate layer node for the given layer type, taking into account selected status
   * @param layerType The layer type of the node
   * @param selected Whether the node is currently selcted or not
   * @returns The layer the node should be attached to
   */
  getLayer(layerType: LayerType, selected: boolean = false): PlannerLayerNode {
    switch (layerType) {
      case LayerTypes.IRRIGATION:
        return selected ? this.selectedIrrigationLayer : this.irrigationLayer;
      case LayerTypes.LAYOUT:
        return selected ? this.selectedLayoutLayer : this.layoutLayer;
      case LayerTypes.PLANTS:
        return selected ? this.selectedPlantsLayer : this.plantsLayer;
      case LayerTypes.STRUCTURES:
        return selected ? this.selectedStructuresLayer : this.structuresLayer;
      case LayerTypes.TEXT:
        return selected ? this.selectedTextLayer : this.textLayer;
      case LayerTypes.PLANT_LABELS:
        return selected ? this.selectedPlantLabelLayer : this.plantLabelLayer;
      case LayerTypes.BACKGROUND:
        return selected ? this.selectedBackgroundLayer : this.backgroundLayer;
      default:
        throw new Error(`Unknown layer type: ${layerType}`);
    }
  }

  teardown() {
    this.backgroundLayer.destroy();
    this.selectedBackgroundLayer.destroy();
    this.layoutLayer.destroy();
    this.selectedLayoutLayer.destroy();
    this.irrigationLayer.destroy();
    this.selectedIrrigationLayer.destroy();
    this.plantsLayer.destroy();
    this.selectedPlantsLayer.destroy();
    this.structuresLayer.destroy();
    this.selectedStructuresLayer.destroy();
    this.textLayer.destroy();
    this.selectedTextLayer.destroy();
    this.plantLabelLayer.destroy();
    this.selectedPlantLabelLayer.destroy();
    this.cropRotationHistoryLayer.destroy();
    this.drawingPreviewLayer.destroy();
  }
}

export default CanvasLayers;
