import { AppAccountActions, appAccountReducer, AppAccountSelectors } from './source/account-slice';
import * as ActionCreators from './source/account-action-creators';
import AccountActionKeyGenerators from './source/account-action-key-generators';

const AppAccountActionCreators = {
  ...AppAccountActions, // Slice action creators
  ...ActionCreators, // Slice
};

export { AppAccountSelectors, AppAccountActionCreators, appAccountReducer, AccountActionKeyGenerators };
