/**
 * @typedef {string} PlantTag
 */

/**
 * Enum for the different plant tags
 *
 * @readonly
 * @enum {PlantTag}
 */
enum PlantTags {
  VEGETABLE = 'vegetable',
  HERB = 'herb',
  EASY_TO_GROW = 'easytogrow',
  GREEN_MANURE = 'greenmanure',
  SHADE_TOLERANT = 'shadetolerant',
  AUTUMN_PLANTING = 'autumnplanting',
  FLOWER = 'flower',
  FROST_TOLERANT = 'frosttolerant',
  FRUIT = 'fruit',
  WINTER_PLANTING = 'winterplanting',
}

export default PlantTags;
