import React, { useContext, useState } from 'react';

import FormField, { FormFieldOptionsPreset, FormSection, InputContainer } from '@gi/form-responsive';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import SortSelect from '../../filters/sort-select';
import MobilePlantListFiltersDrawer from './mobile-plant-list-filters-drawer';
import MobilePlantListDisplayTypeFilter from './mobile-plant-list-display-type-filter';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

import './mobile-plant-list-filters.scss';

const FORM_FIELD_LAYOUT: FormFieldOptionsPreset = {
  desktop: {
    labelSize: 60,
    inputSize: 'full',
  },
  mobile: {
    inputSize: 'full',
  },
};

const SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  container: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    maxWidth: 'unset',
    minWidth: 'unset',
    height: '40px',
  }),
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    minWidth: 'unset',
    height: '100%',
    background: '#f2f2f2',
    border: 'none',
  }),
};

const MobilePlantListFilters = (): JSX.Element => {
  const { resetFilters } = useContext(GlobalPlantListContext);

  const [showOptions, setShowOptions] = useState<boolean>(false);

  return (
    <div className='mobile-plant-list-filters'>
      <FormSection
        className='mobile-plant-list-filters-container'
        heading={
          <div className='mobile-plant-list-filters-heading'>
            <span>Filters and Options</span>
            <button type='button' className='button button-inline' onClick={resetFilters}>
              <i className='icon-close' /> Reset All
            </button>
          </div>
        }
        margin={0}
        gap={9}
      >
        <FormField label='Display' fakeLabel layoutPreset={FORM_FIELD_LAYOUT}>
          <MobilePlantListDisplayTypeFilter />
        </FormField>
        <FormField label='Sort By' fakeLabel layoutPreset={FORM_FIELD_LAYOUT}>
          <div className='mobile-plant-list-sort-inner'>
            <InputContainer className='dropdown'>
              <SortSelect styles={SELECT_STYLES} />
            </InputContainer>
            <button type='button' className='button button-mobile' onClick={() => setShowOptions(true)}>
              More Options <i className='icon-angle-double-down rotate-90' />
            </button>
          </div>
        </FormField>
      </FormSection>
      <MobilePlantListFiltersDrawer open={showOptions} onClose={() => setShowOptions(false)} />
    </div>
  );
};

export default MobilePlantListFilters;
