import React from 'react';
import { DrawingTools } from '@gi/drawing-tools';
import { GardenCanvasOverlay, GardenCanvasStateOverlay, ReactGardenCanvas } from '@gi/react-garden-canvas';

import PlannerContentContainer from './planner-content-container';
import DesktopPlannerContextMenu from '../desktop-planner-context-menu/desktop-planner-context-menu';

import styles from './planner-content.module.css';

interface iProps {
  children: React.ReactNode;
}

const CanvasContent = ({ children }: iProps): JSX.Element => {
  return (
    <PlannerContentContainer>
      <div className={styles.sidepanelControls}>
        <DrawingTools />
      </div>
      <div className={styles.canvasContainer} id='canvas-container-outer'>
        <ReactGardenCanvas className={styles.canvasContainerInner} />
        <GardenCanvasStateOverlay />
        <DesktopPlannerContextMenu />
      </div>
      {children}
      <GardenCanvasOverlay />
    </PlannerContentContainer>
  );
};

export default CanvasContent;
