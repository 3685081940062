import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { UserUtils } from '@gi/user';
import { SessionSelectors } from '@gi/react-session';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { PlannerCommandContext } from '@gi/app-planner-desktop';
import { NotesEditor, NotesPrintView, usePlanNotes } from '@gi/notes';

import PlannerAppScrollPane from '../planner-app-scroll-pane';
import PlannerAppContainer from '../planner-app-container';

import './mobile-notes.scss';

const MobileNotes = (): JSX.Element | null => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const user = useSelector(SessionSelectors.getUser);
  const { notesRef } = useContext(PlannerCommandContext);
  const { notes, setNotes, syncNotes } = usePlanNotes(activePlan);

  if (!activePlan || !user) {
    return null;
  }

  const isNorthernHemisphere = UserUtils.isNorthernHemisphere(user);

  return (
    <PlannerAppContainer className='garden-planner-notes-mobile'>
      <PlannerAppScrollPane className='garden-planner-notes-scroll-pane'>
        <div className='planner-notes'>
          <p>Use this space to store notes for your plan</p>
          <NotesEditor value={notes} onChange={setNotes} className='planner-notes-editor' onSyncRequest={syncNotes} />
        </div>
        <NotesPrintView ref={notesRef as React.MutableRefObject<HTMLDivElement>} plan={activePlan} isNorthernHemisphere={isNorthernHemisphere} notes={notes} />
      </PlannerAppScrollPane>
    </PlannerAppContainer>
  );
};

export default MobileNotes;
