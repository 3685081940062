import React, { ChangeEvent, useContext, useEffect, useMemo } from 'react';

import FormField, { InputContainer } from '@gi/form-responsive';
import { ResourceLoaderContext } from '@gi/resource-provider';
import { LoadingState } from '@gi/constants';

import InfoBanner from '../components/info-banner';
import StatusIcon from '../components/status-icon';

interface iProps {
  postcode: string;
  isValid: boolean;
  onChange: (postcode: string) => void;
  hasRegionSet: boolean;
  hasChangedLocation: boolean;
}

const RegionalPlantingForm = ({ postcode, isValid, onChange, hasRegionSet, hasChangedLocation }: iProps): JSX.Element => {
  const { countryRegions } = useContext(ResourceLoaderContext);

  // Load the regions data if we haven't already.
  // This isn't loaded by default, as it's not critical.
  useEffect(() => {
    countryRegions.loadIfNeeded();
  }, []);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(value);
  };

  const formBody = useMemo(() => {
    if (countryRegions.status === LoadingState.LOADING) {
      return (
        <InfoBanner>
          Loading regions <i className='icon-spinner animate-pulse' />
        </InfoBanner>
      );
    }
    if (countryRegions.status === LoadingState.ERROR) {
      return <InfoBanner type='warning'>There was an error while trying to load regions data.</InfoBanner>;
    }
    if (hasRegionSet && !hasChangedLocation) {
      return (
        <InfoBanner type='success'>
          <p>Your planting region is set. To change your planting region, update your location above first.</p>
        </InfoBanner>
      );
    }
    return (
      <FormField
        label='Postcode'
        htmlFor='regional-postcode'
        errorText={postcode.length > 0 && !isValid ? 'Unrecognised Postcode' : undefined}
        invalid={!isValid}
        desktopLayout={{ inputSize: 'full' }}
      >
        <InputContainer size='full' inputSuffix={<StatusIcon type={isValid ? 'success' : 'warn'} />}>
          <input type='text' id='regional-postcode' value={postcode} onChange={handleInput} />
        </InputContainer>
      </FormField>
    );
  }, [countryRegions, isValid, postcode, hasRegionSet, hasChangedLocation]);

  return (
    <>
      <h2>Find Your Planting Region</h2>
      <p>In your area we use your postcode to provide planting, harvesting and weather advice.</p>
      {formBody}
    </>
  );
};

export default RegionalPlantingForm;
