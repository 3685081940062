import React from 'react';

import { TooltipStringContentDefinition } from '../../types';

interface iProps {
  content: TooltipStringContentDefinition;
}

const TooltipStringContent = ({ content }: iProps): JSX.Element => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{content.content}</>;
};

export default TooltipStringContent;
