import { errorReporterInstance } from '@gi/errors';

import { NetworkRequestError } from './request-error';

const networkErrorReporter = (method: string, url: string) => {
  return (error: Error | NetworkRequestError) => {
    let status: string | number = 'Unknown';
    if (error instanceof NetworkRequestError) {
      if (error.statusCode !== null) {
        status = error.timedOut ? `Timeout/${error.statusCode}` : error.statusCode;
      } else if (error.timedOut) {
        status = 'Timeout';
      } else {
        status = 'None';
      }
    }
    errorReporterInstance.notify(error, (event) => {
      // TODO: Fix these types when the error reporter supports exposing the bugsnag types.
      if (event) {
        event.addMetadata('network', {
          method,
          url,
        });
        event.groupingHash = `${method}:${url}|${status}`;
      }
    });
    throw error;
  };
};

export default networkErrorReporter;
