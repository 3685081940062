import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { PlanUtils } from '@gi/plan';
import { getDistanceUnitsFromIsMetric } from '@gi/units';
import PartList from '@gi/part-list';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { ResourceContext } from '@gi/resource-provider';
import { SessionSelectors } from '@gi/react-session';
import { PlanHooks } from '@gi/react-utils';

import styles from './planner-content.module.css';
import PlannerContentContainer from './planner-content-container';
import { PlannerCommandContext } from '../planner-command-provider';

interface iProps {
  children: React.ReactNode;
}

const PartsListContent = ({ children }: iProps): JSX.Element | null => {
  const { userGardenObjects, userCountry } = useContext(ResourceContext);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const user = useSelector(SessionSelectors.getUser);

  const { partsListRef } = useContext(PlannerCommandContext);

  if (activePlan === null || userGardenObjects === null || user === null || userCountry === null) {
    return null;
  }

  const distanceUnits = getDistanceUnitsFromIsMetric(activePlan.plannerSettings.metric);

  return (
    <PlannerContentContainer className={styles.partsListContainer}>
      <div className={styles.contentScrollPane}>
        <PartList
          ref={partsListRef}
          gardenObjects={userGardenObjects}
          planGardenObjects={PlanHooks.usePlanGardenObjects(activePlan)}
          distanceUnits={distanceUnits}
          title={`Part List - ${activePlan.name} - ${PlanUtils.getYearDisplayString(activePlan.year, user.settings.location.northernHemisphere)}`}
          artifactCode={userCountry.artifactCode}
        />
      </div>
      {children}
    </PlannerContentContainer>
  );
};

export default PartsListContent;
