import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { oxfordCommaJoin, upperFirst } from '@gi/utils';
import { UserShape } from '@gi/user';
import { DEFAULT_VARIETY } from '@gi/constants';
import { JournalActionShapes } from '@gi/journal-days';
import { weightConversion } from '@gi/conversion';

const ACTION_TYPES = {
  PLANTING: 'PLANTING',
  WATERING: 'WATERING',
  CARE: 'CARE',
  HARVESTING: 'HARVESTING',
};

const ACTION_TYPES_TO_PAST_TENSE = {
  PLANTING: 'planted',
  WATERING: 'watered',
  CARE: 'care',
  HARVESTING: 'harvested',
};

const ACTION_TYPES_TO_ICONS = {
  PLANTING: 'icon-planting',
  WATERING: 'icon-watering',
  CARE: 'icon-care',
  HARVESTING: 'icon-harvesting',
  ENTRIES: 'icon-edit',
};

const CARE_ACTIONS_TO_PAST_TENSE = {
  transplanting: 'transplanted',
  fertilizing: 'fertilized',
  mulching: 'mulched',
  pruning: 'pruned',
  weeding: 'weeded',
  pestControl: 'did pest control on',
};

/**
 *
 * @param {{
 *  user: import('@gi/user').User,
 *  expandable?: boolean,
 *  expandIntoTable?: boolean,
 *  actionType: string,
 *  actions: JournalActions
 * }}
 */
const ActionSummary = ({ expandable = false, expandIntoTable = false, user, actionType, actions }) => {
  if (actions.length === 0) {
    return null;
  }

  const [expanded, setExpanded] = useState(false);

  const actionsByVariety = {};
  actions.forEach((action) => {
    actionsByVariety[action.varietyName] = actionsByVariety[action.varietyName] || [];
    actionsByVariety[action.varietyName].push(action);
  });
  const varietyNames = Object.keys(actionsByVariety);

  const getQty = (action) => {
    return action.noOfPlants || action.quantity || 1;
  };

  // quantity has no relevance to care so we fudge it to zero so it's never shown.
  const totalQty =
    actionType === ACTION_TYPES.CARE
      ? 0
      : actions.reduce((total, action) => {
          return total + getQty(action);
        }, 0);

  const parenthesise = (str, shouldPerenthesise) => {
    return shouldPerenthesise ? ` (${str})` : ` ${str}`;
  };

  const getActionDetails = (action, isInTable = false) => {
    switch (action.actionType) {
      case ACTION_TYPES.PLANTING:
        return action.isPlant ? ' plants' : ' seeds';
      case ACTION_TYPES.HARVESTING: {
        const quantity = getQty(action);
        const shouldParenthesise = !isInTable && quantity > 1;
        return action.weight ? parenthesise(weightConversion.getWeightString(action.weight, user.settings.units.metricWeightUnits), shouldParenthesise) : '';
      }
      default:
        return '';
    }
  };

  const renderDetails = () => {
    if (expandable && !expanded) {
      return null;
    }

    const deets = [];
    let details = varietyNames
      .map((varietyName) => {
        return actionsByVariety[varietyName].map((action) => {
          let displayVariety = varietyName;
          if (displayVariety === DEFAULT_VARIETY) {
            if (action.actionType === ACTION_TYPES.WATERING) {
              // There are no other details to show for watering.
              return '';
            }
            displayVariety = 'unspecified variety';
          }
          if (action.actionType === ACTION_TYPES.CARE) {
            deets.push({
              qty: 0,
              actionName: CARE_ACTIONS_TO_PAST_TENSE[action.actionPerformed],
              varietyName: displayVariety,
            });
            return ` ${CARE_ACTIONS_TO_PAST_TENSE[action.actionPerformed]} ${displayVariety}`;
          }
          const qty = getQty(action);
          const actionDetails = getActionDetails(action, true);
          deets.push({ qty, varietyName: displayVariety, actionDetails });
          return ` ${qty > 1 ? qty : ''} ${displayVariety}${actionDetails}`;
        });
      })
      .flat()
      .filter((detail) => detail.length);

    if (expandable) {
      if (expandIntoTable) {
        details = (
          <table className='action-summary-table'>
            <thead>
              <tr>
                <th>Qty</th>
                <th>Variety</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody>
              {deets.map((deet, i) => (
                <tr key={i}>
                  <td>{deet.qty}</td>
                  <td>{deet.varietyName}</td>
                  <td>{deet.actionDetails}</td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      } else {
        details = (
          <div className='expanded-details'>
            {details.map((detail, i) => (
              <div key={i}>{detail}</div>
            ))}
          </div>
        );
      }
    } else {
      details = oxfordCommaJoin(details);
    }

    if (!details) {
      return '';
    }

    return (
      <>
        {expandable ? <br /> : ', including'}
        {details}
      </>
    );
  };

  const renderExpandButton = () => {
    if (!expandable) {
      return null;
    }
    return (
      <>
        .{' '}
        <span role='button' className='button button-inline' onClick={() => setExpanded(!expanded)}>
          {expanded ? 'Hide Harvesting Details' : 'Show Harvesting by Variety'}
        </span>
        .
      </>
    );
  };

  const renderVarietyDetails = () => {
    const useMetric = user.settings.units.metricWeightUnits;
    const weights = actions.map((action) => action.weight || 0);
    const totalWeight = weightConversion.getTotalWeight(weights, useMetric);
    const totalWeightPositive = weightConversion.isWeightPositive(totalWeight, useMetric);
    const totalWeightStr = totalWeightPositive ? ` (${weightConversion.formatWeight(totalWeight, useMetric)}) in total` : '';

    return (
      <>
        {totalWeightStr}
        {renderExpandButton()}
        {renderDetails()}
      </>
    );
  };

  const isSingleAction = varietyNames.length === 1 && actions.length === 1;
  const isSingleCareAction = isSingleAction && actionType === ACTION_TYPES.CARE;
  const isSingleNonDefaultVarietyAction = isSingleAction && varietyNames[0] !== DEFAULT_VARIETY;
  const variety = isSingleNonDefaultVarietyAction ? ` ${varietyNames[0]}` : '';
  const actionTypeString = isSingleCareAction ? CARE_ACTIONS_TO_PAST_TENSE[actions[0].actionPerformed] : ACTION_TYPES_TO_PAST_TENSE[actionType];

  return (
    <div className='journal-action-summary'>
      <i className={`action-icon ${ACTION_TYPES_TO_ICONS[actionType]}`} />
      {upperFirst(actionTypeString)}
      {totalQty > 1 ? ` ${totalQty}` : ''}
      {variety}
      {isSingleAction ? getActionDetails(actions[0]) : renderVarietyDetails()}
    </div>
  );
};

ActionSummary.propTypes = {
  user: UserShape.isRequired,
  expandable: PropTypes.bool,
  expandIntoTable: PropTypes.bool,
  actionType: PropTypes.string.isRequired,
  actions: JournalActionShapes.JournalActionsShape.isRequired,
};

export default ActionSummary;

export { ActionSummary, ACTION_TYPES, ACTION_TYPES_TO_ICONS, ACTION_TYPES_TO_PAST_TENSE, CARE_ACTIONS_TO_PAST_TENSE };
