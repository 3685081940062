import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigationContext, Application, NavigationContextType, getAbsoluteApplicationLink, getActiveApplicationLink } from './navigation-context';

interface iProps {
  children: React.ReactNode;
}

const NavigationProvider = ({ children }: iProps): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const activeApplication = getActiveApplicationLink(pathname);

  const openApplication = useCallback(
    (application: Application) => {
      navigate(getAbsoluteApplicationLink(application));
    },
    [navigate]
  );

  const value = useMemo<NavigationContextType>(
    () => ({
      activeApplication,
      openApplication,
    }),
    [activeApplication, openApplication]
  );

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};

export default NavigationProvider;
