import React, { useContext } from 'react';
import GlobalPlantListContext from '../global-provider/global-plant-list-context';

import { PlantListAreaGroup } from '../types/plant-list-types';
import InsetVarietyCell from './cells/inset-variety-cell';
import InsetPlantLabelCell from './cells/inset-plant-label-cell';
import InsetCountsCell from './cells/inset-counts-cell';
import InsetInGroundDatesCell from './cells/inset-in-ground-dates-cell';

interface iAreaTableCellRowProps {
  areaGroup: PlantListAreaGroup;
  showVariety?: boolean;
}

const InsetAreaTableCellRow = ({ areaGroup, showVariety = false }: iAreaTableCellRowProps) => {
  return (
    <tr>
      {showVariety ? <InsetVarietyCell varietyName={areaGroup.variety} /> : null}
      <InsetPlantLabelCell planPlant={areaGroup.planPlant} />
      <InsetCountsCell count={areaGroup.count} />
      <InsetInGroundDatesCell planPlant={areaGroup.planPlant} />
    </tr>
  );
};

interface iProps {
  areaGroups: PlantListAreaGroup[];
  className?: string;
  showVariety?: boolean;
}

const InsetAreaTableRows = ({ areaGroups, className = '', showVariety = false }: iProps): JSX.Element | null => {
  const { columns } = useContext(GlobalPlantListContext);

  if (!columns.area) {
    return null;
  }

  return (
    <tr className={`table-row inset-table-row main-tr ${className}`}>
      <td />
      <td />
      <td />
      <td />
      <td className='area-td' colSpan={100}>
        <table className='inset-area-table'>
          <thead className='table-headers'>
            <tr>
              {showVariety ? (
                <th className='header-cell left-align-cell'>
                  <div className='header-cell-content'>
                    <div className='header-row'>Variety</div>
                  </div>
                </th>
              ) : null}
              <th className='header-cell left-align-cell'>
                <div className='header-cell-content'>
                  <div className='header-row'>Plant Label</div>
                </div>
              </th>
              <th className='header-cell left-align-cell'>
                <div className='header-cell-content'>
                  <div className='header-row'>Quantity</div>
                </div>
              </th>
              <th className='header-cell left-align-cell'>
                <div className='header-cell-content'>
                  <div className='header-row'>In-Ground Dates</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {areaGroups.map((areaGroup) => (
              <InsetAreaTableCellRow key={areaGroup.planPlant.id} areaGroup={areaGroup} showVariety={showVariety} />
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
};

export default InsetAreaTableRows;
