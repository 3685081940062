import React, { CSSProperties, UIEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import { GuruPreviewItem } from '@gi/app-guru-types';

import SmallPreviewItem from '../items/small-preview-item';
import { LazyImageProvider } from '../../lazy-image/lazy-image-context';
import SmallPreviewLoading from '../items/small-preview-loading';

import styles from './small-preview-row-mobile.module.css';

// The amount to round horizontal scrolls by (0-1) to account for imprecision and browser pixel rounding.
const IMPRECISION_CORRECTION = 0.05;

interface iProps {
  items?: GuruPreviewItem[];
  minItemHeight?: number;
  itemAspectRatio?: number;
  gap?: number;
  padding?: number;
  loading?: boolean;
}

const SmallPreviewRowMobile = ({ items = [], minItemHeight = 150, itemAspectRatio = 9 / 16, gap = 18, padding = 12, loading = false }: iProps): JSX.Element => {
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(window.innerWidth);
  const [currentItem, setCurrentItem] = useState<number>(0);

  useEffect(() => {
    const callback = () => {
      setContainerWidth(ref?.clientWidth ?? window.innerWidth);
    };
    callback();
    if (ref) {
      window.addEventListener('resize', callback);

      return () => {
        window.removeEventListener('resize', callback);
      };
    }
    return () => {};
  }, [ref]);

  const usableWidth = useMemo(() => {
    return containerWidth - padding * 2;
  }, [padding, containerWidth]);

  const maxVisibleItems = useMemo(() => {
    // TODO: Gap isn't accounted for in this >:/
    const count = Math.max(Math.floor(usableWidth / (minItemHeight / itemAspectRatio)), 1);
    if (count === 1) {
      return 1.2;
    }
    return count;
  }, [minItemHeight, itemAspectRatio, usableWidth]);

  const itemWidth = useMemo(() => {
    return (usableWidth - (maxVisibleItems - 1) * gap) / maxVisibleItems;
  }, [maxVisibleItems, usableWidth, gap]);

  const itemsWithGaps = useMemo(() => {
    const elements: JSX.Element[] = [];
    items.forEach((item, i) => {
      elements.push(
        <div className={styles.item} key={`${item.id}`}>
          <SmallPreviewItem item={item} />
        </div>
      );
      if (i !== items.length - 1) {
        elements.push(<span key={`gap-${i}`} className={styles.gap} aria-hidden />);
      }
    });
    return elements;
  }, [items]);

  const loadingPlaceholders = useMemo(() => {
    if (!loading) {
      return null;
    }
    const elements: JSX.Element[] = [];
    for (let i = 0; i < maxVisibleItems; i++) {
      elements.push(
        <div className={styles.item} key={`placeholder-${i}`}>
          <SmallPreviewLoading />
        </div>
      );
      if (i !== items.length - 1) {
        elements.push(<span key={`gap-${i}`} className={styles.gap} aria-hidden />);
      }
    }
    return elements;
  }, [loading, maxVisibleItems]);

  const onScroll = useCallback<UIEventHandler>(
    (event) => {
      const target = event.target as HTMLDivElement;
      let probableTarget = target.scrollLeft / (itemWidth + gap);
      // Round if close to whole number to avoid imprecision errors.
      if (probableTarget % 1 < IMPRECISION_CORRECTION || probableTarget % 1 > 1 - IMPRECISION_CORRECTION) {
        probableTarget = Math.round(probableTarget);
      }
      setCurrentItem(probableTarget);
    },
    [itemWidth, gap, padding]
  );

  const hasPrevious = useMemo(() => {
    return currentItem > IMPRECISION_CORRECTION;
  }, [currentItem, items, maxVisibleItems]);

  const hasNext = useMemo(() => {
    return currentItem < items.length - maxVisibleItems - IMPRECISION_CORRECTION;
  }, [currentItem, items, maxVisibleItems]);

  const next = useCallback(() => {
    if (!scrollRef) {
      return;
    }
    const nextItemNumber = Math.min(Math.ceil(currentItem) + maxVisibleItems, items.length - maxVisibleItems);
    scrollRef.scrollTo({
      left: nextItemNumber * itemWidth + gap * nextItemNumber,
      behavior: 'smooth',
    });
  }, [scrollRef, currentItem, maxVisibleItems, itemWidth, gap, items]);

  const previous = useCallback(() => {
    if (!scrollRef) {
      return;
    }
    const previousItemNumber = Math.max(Math.ceil(currentItem) - maxVisibleItems, 0);
    scrollRef.scrollTo({
      left: previousItemNumber * itemWidth + gap * Math.max(previousItemNumber - 1, 0),
      behavior: 'smooth',
    });
  }, [scrollRef, currentItem, maxVisibleItems, itemWidth, gap, items]);

  const cssVariables = useMemo<CSSProperties>(() => {
    return {
      '--items': items.length,
      '--gap': `${gap}px`,
      '--padding': `${padding}px`,
      '--item-width': `${itemWidth}px`,
      '--item-height': `${itemWidth * itemAspectRatio}px`,
    } as CSSProperties;
  }, [items, padding, gap, itemWidth, itemAspectRatio]);

  return (
    <LazyImageProvider containerRef={scrollRef}>
      <div className={styles.previewRowContainer} style={cssVariables} ref={setRef}>
        <button className={`${styles.cycleButton} ${styles.previous}`} type='button' disabled={!hasPrevious} onClick={previous}>
          <i className='icon-left-dir' />
        </button>
        <div className={styles.previewRow} onScroll={onScroll} ref={setScrollRef}>
          <span className={styles.padder} aria-hidden />
          {loading ? loadingPlaceholders : itemsWithGaps}
          <span className={styles.padder} aria-hidden />
        </div>
        <button className={`${styles.cycleButton} ${styles.next}`} type='button' disabled={!hasNext} onClick={next}>
          <i className='icon-right-dir' />
        </button>
      </div>
    </LazyImageProvider>
  );
};

export default SmallPreviewRowMobile;
