import React, { useState } from 'react';

import Plant from '@gi/plant';
import { DistanceUnits } from '@gi/units';
import { PlantSpacingHelpModal } from '@gi/help-modals';
import { HelpButton } from '@gi/base-components';
import { useHelpModalEventHook } from '@gi/garden-platform-events';

interface iSpacingProps {
  plant: Plant;
  distanceUnits: DistanceUnits;
}

const PlantSpacingSection = ({ plant, distanceUnits }: iSpacingProps): JSX.Element => {
  const [showHelpModal, setShowHelpModal] = useState(false);

  useHelpModalEventHook('plant spacing', showHelpModal, 'plant-information-modal');

  return (
    <tr className='plan-table-row'>
      {showHelpModal ? <PlantSpacingHelpModal closeModal={() => setShowHelpModal(false)} /> : null}
      <th className='plan-table-row-title'>
        <HelpButton onClick={() => setShowHelpModal(true)} /> Spacing
      </th>
      <td>
        <p>
          <strong>Single Plants:</strong> {distanceUnits.getUnitString(plant.spacing)} each way (minimum).
        </p>
        <p>
          <strong>Row Spacing:</strong> {distanceUnits.getUnitString(plant.inRowSpacing)} with {distanceUnits.getUnitString(plant.rowSpacing)} row gap
          (minimum).
        </p>
      </td>
    </tr>
  );
};

export default PlantSpacingSection;
