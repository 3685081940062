import React, { useContext } from 'react';

import { PlanSize } from '../utils';
import { PlanDiagramSVGContext } from '../plan-diagram-svg';

import styles from './svg-element-styles.module.css';

interface iProps {
  originalPlan: PlanSize;
  newPlan: PlanSize;
}

/**
 * Creates an area that highlights the part of the original plan dimensions that will be removed by
 *  the new dimensions.
 *
 * Inner x/y values are clamped such that they never go beyond the original plan dimensions, so if
 *  the plan expands, nothing will display.
 *
 *                                       x1   x3
 *  x1   x2         x3   x4              x2   x4
 *   +----+----------+----+ y1            +----+     y1
 *   | original plan      |               |orig|
 *   |    +----------+    | y2        +---+----+---+ y2
 *   |    | new      |    |           |new|    |   |
 *   |    +----------+    | y3        +---+----+---+ y3
 *   |                    |               |    |
 *   +----+----------+----+ y4            +----+     y4
 */
const RemovedAreaSVGElement = ({ originalPlan, newPlan }: iProps): JSX.Element => {
  const { scale, offset } = useContext(PlanDiagramSVGContext);

  const x1 = originalPlan.x;
  const x2 = Math.max(originalPlan.x, newPlan.x);
  const x3 = Math.min(originalPlan.x + originalPlan.width, newPlan.x + newPlan.width);
  const x4 = originalPlan.x + originalPlan.width;

  const y1 = originalPlan.y;
  const y2 = Math.max(originalPlan.y, newPlan.y);
  const y3 = Math.min(originalPlan.y + originalPlan.height, newPlan.y + newPlan.height);
  const y4 = originalPlan.y + originalPlan.height;

  const points: Vector2[] = [
    { x: x1, y: y1 }, // TL old (start)
    { x: x4, y: y1 }, // TR old
    { x: x4, y: y4 }, // BR old
    { x: x1, y: y4 }, // BL old
    { x: x1, y: y1 }, // TL old (back to start)
    { x: x2, y: y2 }, // TL new (inner start)
    { x: x2, y: y3 }, // TR new
    { x: x3, y: y3 }, // BR new
    { x: x3, y: y2 }, // BL new
    { x: x2, y: y2 }, // TL new (back to inner start)
    { x: x1, y: y1 }, // TL old (back to start)
  ].map((point) => ({
    x: point.x * scale + offset.x,
    y: point.y * scale + offset.y,
  }));

  const path = `M ${points.map((point) => `${point.x} ${point.y}`).join(' L ')}`;

  return <path d={path} className={styles.planRemovedArea} />;
};

export default RemovedAreaSVGElement;
