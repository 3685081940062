import React from 'react';

import FormField, { FORM_FIELD_PRESETS } from '@gi/form-responsive';

import { PlantListColumns } from '../../types/plant-list-types';

const filterNames: { [filterName in keyof PlantListColumns]: string } = {
  area: 'Individual Plantings',
  calendar: 'Calendar',
  counts: 'Quantity',
  notes: 'Plant Notes',
  plantLabel: 'Plant Label',
  spacings: 'Spacings',
  inGroundDates: 'In-Ground Dates',
};

interface iProps {
  field: keyof PlantListColumns;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  disabled?: boolean;
}

const PlantListColumnFilter = ({ field, visible, setVisible, disabled = false }: iProps): JSX.Element | null => {
  if (disabled) {
    return null;
  }

  const id = `plant-list-columns:${filterNames[field].toLowerCase().replaceAll(' ', '-')}`;

  return (
    <FormField label={filterNames[field]} htmlFor={id} layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft} disabled={disabled}>
      <input
        type='checkbox'
        id={id}
        disabled={disabled}
        checked={visible}
        onChange={(e) => {
          setVisible(e.target.checked);
        }}
      />
    </FormField>
  );
};

export default PlantListColumnFilter;
