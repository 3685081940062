import { ReactNode, useContext, useEffect } from 'react';

import { SidebarContext } from './sidebar-context';

interface iProps {
  children: ReactNode;
  tabId?: string;
}

const SidebarHeaderContent = ({ children, tabId }: iProps): null => {
  const { registerHeaderContent, unregisterHeaderContent } = useContext(SidebarContext);

  useEffect(() => {
    registerHeaderContent(tabId ?? null, children);

    return () => {
      unregisterHeaderContent(tabId ?? null);
    };
  }, [children, tabId]);

  return null;
};

export default SidebarHeaderContent;
