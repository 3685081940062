import React from 'react';

import SmallPreviewRow from '../small-preview/small-preview-row/small-preview-row';
import PlaceholderText from '../placeholder/placeholder-text';

const DirectoryPageSectionsLoading = (): JSX.Element => {
  return <SmallPreviewRow title={<PlaceholderText minWidth={10} maxWidth={15} />} loading />;
};

export default DirectoryPageSectionsLoading;
