import { Filter } from '@gi/filters';

import { PlantListAreaGroup, PlantListSummaryGroup, PlantListVarietyGroup } from '../../types/plant-list-types';

type ShowPerennialsFilterState = {
  showPerennials: boolean;
};

const makePerennialsFilter = <T extends PlantListSummaryGroup | PlantListVarietyGroup | PlantListAreaGroup>(): Filter<T, ShowPerennialsFilterState> => ({
  name: 'Show Perennials',
  inputs: {
    showPerennials: true,
  },
  createListFilter: ({ showPerennials }) => {
    return (plantListPlantGroups) => {
      if (showPerennials) {
        return plantListPlantGroups;
      }

      return plantListPlantGroups.filter((plantListItem) => !plantListItem.plant.perennial);
    };
  },
});

export default makePerennialsFilter;
