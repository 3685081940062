import React, { useContext } from 'react';
import { AppContext } from '@gi/app-provider';
import { networkConfig } from '@gi/config';

import './dashboard-logo.scss';

const DashboardLogo = (): JSX.Element => {
  const { runtimeConfig } = useContext(AppContext);
  const src = `${networkConfig.assetBaseURL}${runtimeConfig.logo.src}`;

  return (
    <div className='dashboard-logo'>
      <div className='dashboard-logo-inner'>
        <img className='dashboard-logo-image' src={src} alt='logo' />
      </div>
    </div>
  );
};

export default DashboardLogo;
