import React from 'react';

import styles from './shopping-list-content.module.css';

interface iProps {
  children: React.ReactNode;
}

const ShoppingListItemsList = ({ children }: iProps): JSX.Element => {
  return <div className={styles.shoppingListItemsList}>{children}</div>;
};

export default ShoppingListItemsList;
