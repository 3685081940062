import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import GardenGuruContext from './context/garden-guru-context';

// https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition

const ScrollTop = (): null => {
  const { pathname } = useLocation();
  const { scrollTop } = useContext(GardenGuruContext);

  useEffect(() => {
    scrollTop();
  }, [pathname]);

  return null;
};

export default ScrollTop;
