import React from 'react';

import FileDropzone from '../components/file-dropzone';
import { IMAGE_FILE_TYPES } from '../utils';

interface iProps {
  onImageChange: (file: File) => void;
}

const NoImageSelected = ({ onImageChange }: iProps): JSX.Element => {
  return <FileDropzone onChange={onImageChange} accept={IMAGE_FILE_TYPES} />;
};

export default NoImageSelected;
