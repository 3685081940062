import { Filter } from '@gi/filters';
import { SearchResults } from '@gi/search-service';
import Plant from '@gi/plant';

type SearchFilterState = {
  searchTerm: string;
  searchResults: SearchResults<Plant>;
};

const searchFilter: Filter<Plant, SearchFilterState> = {
  name: 'Search',
  inputs: { searchTerm: '', searchResults: new SearchResults([]) },
  createListFilter:
    ({ searchTerm, searchResults }) =>
    (plantList) => {
      const results = searchResults.getResults();

      // Return early if no results or empty query
      if (searchTerm.trim() === '') {
        return plantList;
      }
      if (Object.keys(results).length === 0) {
        return [];
      }

      // Filter against the subset we've been passed to allow us to combine with other filters
      // Also re-sort by search position
      return plantList
        .filter((plant) => results[plant.code])
        .sort((plantA, plantB) => {
          const scoreA = results[plantA.code].score;
          const scoreB = results[plantB.code].score;
          if (scoreA === scoreB) {
            return 0;
          }
          return scoreA > scoreB ? -1 : 1;
        });
    },
};

export default searchFilter;
