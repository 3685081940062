import React, { useEffect, useMemo, useState } from 'react';

import styles from './placeholder.module.css';

interface iProps {
  // Minimum width of the text. Between 0-1 work as a percentage. >1 works as em.
  minWidth?: number;
  // Maximum width of the text. Between 0-1 work as a percentage. >1 works as em.
  maxWidth?: number;
}

/**
 * Represents loading text
 */
const PlaceholderText = ({ minWidth = 0.5, maxWidth = 0.9 }: iProps): JSX.Element => {
  const [width, setWidth] = useState<number>(-1);

  useEffect(() => {
    if (width < minWidth || width > maxWidth) {
      setWidth(minWidth + (maxWidth - minWidth) * Math.random());
    }
  }, [minWidth, maxWidth]);

  const widthStyle = useMemo(() => {
    const w = width > 1 ? `${width}em` : `${width * 100}%`;
    return `min(${w}, 100%)`;
  }, [width]);

  return <span className={styles.placeholderText} style={{ width: widthStyle }} />;
};

export default PlaceholderText;
