import React from 'react';
import PropTypes from 'prop-types';

import './form.scss';

/**
 * @param {{
 *  className?: string,
 *  children?: import('react').ReactNode,
 *  style?: import('react').CSSProperties,
 *  disabled?: boolean,
 * }}
 */
const CustomFormRow = ({ style = {}, className = '', disabled = false, children = null }) => {
  return (
    <div style={style} className={`custom-form-row-new ${className} ${disabled ? 'disabled-form-row' : ''}`}>
      {children}
    </div>
  );
};

CustomFormRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default CustomFormRow;
