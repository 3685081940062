import React from 'react';
import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';

import AppDashboardDesktop from '@gi/app-dashboard-desktop';
import AppDashboardMobile from '@gi/app-dashboard-mobile';
import { useSelector } from 'react-redux';

const AppDashboard = (): JSX.Element => {
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  if (deviceDisplayMode === DeviceDisplayMode.DESKTOP) {
    return <AppDashboardDesktop />;
  }

  return <AppDashboardMobile />;
};

export default AppDashboard;
