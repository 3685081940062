import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { PlanPlant } from '@gi/plan';
import { MONTHS } from '@gi/constants';
import { SessionSelectors } from '@gi/react-session';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

export function getInGroundString(startMonth: number, endMonth: number, northernHemisphere: boolean): JSX.Element {
  const adjustedStartMonth = northernHemisphere ? startMonth : (startMonth + 6) % 12;
  const adjustedEndMonth = northernHemisphere ? endMonth : (endMonth + 6) % 12;

  return (
    <span className='in-ground-string'>
      <span className='in-ground-string-start'>
        {MONTHS[adjustedStartMonth]}
        <i className='icon-right' />
      </span>
      <span className='in-ground-string-end'>
        <span className='in-ground-string-end-month'>{MONTHS[adjustedEndMonth]}</span>
        {adjustedEndMonth < adjustedStartMonth ? <span className='in-ground-string-end-small'>Following Year</span> : null}
      </span>
    </span>
  );
}

interface iProps {
  planPlant: PlanPlant;
}

const InGroundDatesCell = ({ planPlant }: iProps): JSX.Element | null => {
  const { columns } = useContext(GlobalPlantListContext);
  const northernHemisphere = useSelector(SessionSelectors.getIsNorthernHemisphere);

  if (!columns.inGroundDates) {
    return null;
  }

  return (
    <td className='in-ground-td cell-with-label'>
      <div className='cell-label'>In-Ground</div>
      <div className='cell-content'>
        {planPlant.inGroundAll ? 'All Year Round' : getInGroundString(planPlant.inGroundStart, planPlant.inGroundEnd, northernHemisphere)}
      </div>
    </td>
  );
};

export default InGroundDatesCell;
