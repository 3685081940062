/**
 * Determines which layout of the Garden Planner will be rendered
 */
enum DeviceDisplayMode {
  AUTO = 'AUTO',
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
  SUGGEST = 'SUGGEST',
}

export default DeviceDisplayMode;
