import { Filter } from '@gi/filters';
import Plant from '@gi/plant';

type SortFilterState = {
  enabled: boolean;
};

const sortFilter: Filter<Plant, SortFilterState> = {
  name: 'Lexographic Sort',
  inputs: {
    enabled: true,
  },
  createListFilter: ({ enabled }) => {
    return (plantList) => {
      if (!enabled) {
        return plantList;
      }
      return plantList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    };
  },
};

export default sortFilter;
