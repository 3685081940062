import type { CountryRegion, CountryRegionCollection, CountryRegionFromAPI } from './types';

/**
 * Finds a region based on a country code and postcode.
 * @param collection The collection of country regions
 * @param countryCode The country code
 * @param postcode The postcode
 * @returns The region the postcode belongs to, if any
 */
export const findRegionByPostcode = (collection: CountryRegionCollection, countryCode: string, postcode: number) => {
  const regionCollection = collection.get(countryCode);
  if (!regionCollection) {
    return null;
  }
  return regionCollection.getList().find((region) => postcode >= region.postcodeRangeFrom && postcode <= region.postcodeRangeTo) ?? null;
};

/**
 * Converts a CountryRegion from the JSON file to a CountryRegion
 * @param obj The JSON object
 * @returns A CountryRegion
 */
export const fromObject = (obj: CountryRegionFromAPI): CountryRegion => {
  return {
    countryCode: obj.countryCode,
    regionID: obj.regionID,
    postcodeRangeFrom: obj.postcodeExactMatch ?? obj.postcodeRangeFrom!,
    postcodeRangeTo: obj.postcodeExactMatch ?? obj.postcodeRangeTo!,
  };
};
