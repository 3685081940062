import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { AnalyticsActionCreators, AnalyticsEventIdentifiers } from '@gi/analytics';
import { ShoppingList, ShoppingListItems, ShoppingListUtils } from '@gi/products';
import { ResourceContext } from '@gi/resource-provider';
import { formatString } from '@gi/utils';
import ShoppingListButton from '../buttons/shopping-list-button';

import styles from './shopping-list-controls.module.css';

function createShoppingListString(items: ShoppingListItems<unknown>): string {
  return items.uuids
    .map((uuid) => items.entriesByUuid[uuid])
    .filter((entry) => entry.inCart)
    .map((entry) => `${entry.variantId}:${entry.cartQuantity}`)
    .join(',');
}

function getTotalProductCount(items: ShoppingListItems<unknown>): number {
  return items.uuids.map((uuid) => items.entriesByUuid[uuid]).filter((entry) => entry.inCart).length;
}

function sendAnalytics(dispatch: Dispatch<AnyAction>, shoppingList: ShoppingList) {
  dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.SHOPPING, ShoppingListUtils.getAnalytics(shoppingList)));
}

interface iProps {
  shoppingList: ShoppingList;
}

const ShoppingListControls = ({ shoppingList }: iProps): JSX.Element | null => {
  const { store } = useContext(ResourceContext);
  const dispatch = useDispatch();

  if (!store) {
    return null;
  }

  return (
    <div className={styles.shoppingListControls}>
      <div className={`${styles.shoppingListControls} ${styles.right}`}>
        <ShoppingListButton
          type='button'
          disabled={getTotalProductCount(shoppingList.plants) + getTotalProductCount(shoppingList.gardenObjects) === 0}
          onClick={() => {
            sendAnalytics(dispatch, shoppingList);
            const productUrlString = [createShoppingListString(shoppingList.plants), createShoppingListString(shoppingList.gardenObjects)]
              .filter((str) => str !== '')
              .join(',');

            const URL = formatString(store.details.storePath, { productString: productUrlString });
            console.log(`Store path ${URL}`);
            window.open(URL, '_blank');
          }}
        >
          Continue to {store.details.storeName} Cart
        </ShoppingListButton>
      </div>
      <div className={styles.warning}>
        <p>
          After you continue into the {store.details.storeName} some products may not be added to your basket if they are out of stock or are no longer
          available. Any items already in your basket in the store may not be removed.
        </p>
        <p>Please check the basket carefully before you checkout.</p>
      </div>
    </div>
  );
};

export default ShoppingListControls;
