// TODO: Eventually to be added to user.ts ?
export type UserSeasonExtenderSettings = {
  coldFrameFirstFrostAdjust: number;
  coldFrameLastFrostAdjust: number;
  clocheFirstFrostAdjust: number;
  clocheLastFrostAdjust: number;
  rowCoverFirstFrostAdjust: number;
  rowCoverLastFrostAdjust: number;
  greenhouseFirstFrostAdjust: number;
  greenhouseLastFrostAdjust: number;
  heatedGreenhouseFirstFrostAdjust: number;
  heatedGreenhouseLastFrostAdjust: number;
  polytunnelFirstFrostAdjust: number;
  polytunnelLastFrostAdjust: number;
  heatedPolytunnelFirstFrostAdjust: number;
  heatedPolytunnelLastFrostAdjust: number;
};

export type UserLocationSettings = {
  northernHemisphere: boolean;
  latitude: number;
  longitude: number;
  regionID: number;
  splitSeason: boolean;
};

export type UserFrostDateSettings = {
  first: number;
  last: number;
  noFrost: boolean;
  set: boolean;
};

export type UserUnitsSettings = {
  metric: boolean;
  metricTemperatureUnits: boolean;
  metricWeightUnits: boolean;
};

export type UserGeneralPlanSettings = {
  plansPublic: boolean;
};

export type UserSettings = UserGeneralPlanSettings & {
  location: UserLocationSettings;
  frostDates: UserFrostDateSettings;
  units: UserUnitsSettings;
  maxPlansPerYear: number;
  plansPublic: boolean;
  seasonExtenders: UserSeasonExtenderSettings;
  data: {
    articlesCountryCode: string | null;
    pestPredictionCountryCode: string | null;
    userArtifactCode: string | null; // plantDataCountryCode
    pestArtifactCode: string | null;
  };
};

/**
 * Default values for season extenders
 */
export const DEFAULT_USER_SEASON_EXTENDER_SETTINGS: UserSeasonExtenderSettings = {
  coldFrameFirstFrostAdjust: 3,
  coldFrameLastFrostAdjust: -2,
  clocheFirstFrostAdjust: 3,
  clocheLastFrostAdjust: -2,
  rowCoverFirstFrostAdjust: 4,
  rowCoverLastFrostAdjust: -3,
  greenhouseFirstFrostAdjust: 5,
  greenhouseLastFrostAdjust: -4,
  heatedGreenhouseFirstFrostAdjust: 10,
  heatedGreenhouseLastFrostAdjust: -8,
  polytunnelFirstFrostAdjust: 6,
  polytunnelLastFrostAdjust: -4,
  heatedPolytunnelFirstFrostAdjust: 10,
  heatedPolytunnelLastFrostAdjust: -8,
};
