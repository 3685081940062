/* eslint-disable import/no-cycle */
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import type TooltipComponent from './tooltip-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { hasEngine } from '../../utils/asserts';
import TooltipNode from './tooltip-node';

/**
 * Tooltip Component Context
 *  Manages showing a single tooltip at any time.
 *  Also allows for freezing and complete hiding of all tooltips.
 */
class TooltipComponentContext extends NodeComponent {
  type = 'TooltipComponentContext';

  #tooltipNode: TooltipNode | null = null;
  #activeTooltip: TooltipComponent | null = null;

  #disableTooltipsFlags: Set<string> = new Set();
  get tooltipsDisabled() {
    return this.#disableTooltipsFlags.size > 0;
  }

  #freezeTooltipsFlags: Set<string> = new Set();
  get tooltipsFrozen() {
    return this.#freezeTooltipsFlags.size > 0;
  }

  constructor() {
    super();

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);

    this.#tooltipNode = new TooltipNode();
    this.owner.addChildren(this.#tooltipNode);
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    if (this.#tooltipNode) {
      this.#tooltipNode.destroy();
      this.#tooltipNode = null;
    }
  };

  /**
   * Shows the given tooltip, if not already active.
   * @param tooltip The tooltip to show
   */
  showTooltip(tooltip: TooltipComponent) {
    if (this.#activeTooltip === tooltip) {
      return;
    }
    this.#activeTooltip = tooltip;
    if (tooltip.owner && !this.tooltipsDisabled) {
      this.#tooltipNode?.attachTo(tooltip.owner);
    }
  }

  /**
   * Hides the given tooltip, if currently active.
   * @param tooltip The tooltip to hide
   */
  hideTooltip(tooltip: TooltipComponent) {
    if (this.#activeTooltip !== tooltip) {
      return;
    }

    if (this.#activeTooltip.owner) {
      this.#tooltipNode?.detachFrom(this.#activeTooltip.owner);
    }
    this.#activeTooltip = null;
  }

  /**
   * Freezes tooltips. This will cause any active tooltips to remain stuck open (but will still update).
   *  To unfreeze tooltips, all freezing flags must be cleared.
   * @param flagId The unique flag of whatever piece of code is running this
   */
  freezeTooltips(flagId: string) {
    this.#freezeTooltipsFlags.add(flagId);
  }

  /**
   * Unfreezes tooltips.
   *  If the given flag is the only disabling flag, tooltips will unfrozen.
   *  If there are still other flags freezing tooltips, then tooltips will remain forzen until all flags are cleared.
   * @param flagId The unique flag of whatever piece of code is running this
   */
  unfreezeTooltips(flagId: string) {
    this.#freezeTooltipsFlags.delete(flagId);
  }

  /**
   * Disables tooltips. This will hide any active tooltips and prevent new ones from appearing.
   *  To re-enable tooltips, all disabling flags must be cleared.
   * @param flagId The unique flag of whatever piece of code is running this
   */
  disableTooltips(flagId: string) {
    this.#disableTooltipsFlags.add(flagId);
    this.#tooltipNode?.detach();
  }

  /**
   * Enables tooltips.
   *  If the given flag is the only disabling flag, tooltips will be re-enabled.
   *  If there are still other flags disabling tooltips, then tooltips will remain disabled until all flags are cleared.
   * @param flagId The unique flag of whatever piece of code is running this
   */
  enableTooltips(flagId: string) {
    if (!this.tooltipsDisabled) {
      return;
    }
    this.#disableTooltipsFlags.delete(flagId);
    if (!this.tooltipsDisabled && this.#activeTooltip?.owner) {
      this.#tooltipNode?.attachTo(this.#activeTooltip.owner);
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'tooltipsDisabled',
      propertyType: InspectableClassPropertyType.Boolean,
    },
    {
      type: InspectableClassDataType.Property,
      property: 'tooltipsFrozen',
      propertyType: InspectableClassPropertyType.Boolean,
    },
  ];
}

export default TooltipComponentContext;
