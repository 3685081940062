import React, { useContext, useEffect, useRef, useState } from 'react';
import { updateFilters } from '@gi/filters';

import { ResourceContext } from '@gi/resource-provider';

import GardenObjectTypeFilterView from './garden-object-type-filter/garden-object-type-filter-view';
import GardenObjectSearchFilterView from './garden-object-search-filter/garden-object-search-filter-view';

import { DrawingToolsContext } from '../../drawing-tools-context';
import { PUNCTUATION_REGEX } from '../../constants';

const TIMEOUT_TIME = 150;

const GardenObjectFilterInputs = () => {
  const { gardenObjectSearchService } = useContext(ResourceContext);
  const { gardenObjectFilters, setGardenObjectFilters, resetGardenObjectFilters } = useContext(DrawingToolsContext);

  const [searchTerm, setSearchTerm] = useState<string>(gardenObjectFilters.filters.search.inputs.searchTerm);
  const [lastCommittedSearchTerm, setLastCommittedSearchTerm] = useState<string>(gardenObjectFilters.filters.search.inputs.searchTerm);

  const searchTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();

  useEffect(() => {
    // Check if the search term in the filters has updated without us asking to.
    // If so, update our copy of the search term to match (the filter was probably reset).
    const searchTermFromFilter = gardenObjectFilters.filters.search.inputs.searchTerm;
    if (searchTermFromFilter !== lastCommittedSearchTerm) {
      setSearchTerm(searchTermFromFilter);
      setLastCommittedSearchTerm(searchTermFromFilter);
    }
  }, [gardenObjectFilters.filters.search.inputs.searchTerm]);

  useEffect(() => {
    const searchUpdateTimeout = () => {
      setLastCommittedSearchTerm(searchTerm);
      setGardenObjectFilters(
        updateFilters(gardenObjectFilters, {
          search: {
            searchTerm,
            searchResults: gardenObjectSearchService.search(searchTerm.replaceAll(PUNCTUATION_REGEX, '')),
          },
          sort: { enabled: searchTerm.length <= 1 },
        })
      );
    };

    clearTimeout(searchTimeout.current!);
    searchTimeout.current = setTimeout(searchUpdateTimeout, TIMEOUT_TIME);

    return () => {
      clearTimeout(searchTimeout.current!);
    };
  }, [searchTerm]);

  return (
    <div className='filter-options'>
      <div className='filters-title-bar'>
        <h3 className='filters-title'>Filters</h3>
        <button type='button' onClick={resetGardenObjectFilters} className='button button-borderless filters-title-button'>
          Reset
        </button>
      </div>
      <div className='filters-scrollpane'>
        <GardenObjectSearchFilterView searchTerm={searchTerm} onSearchTermChange={setSearchTerm} />
        <GardenObjectTypeFilterView filters={gardenObjectFilters} onFilterChange={setGardenObjectFilters} />
      </div>
    </div>
  );
};

export default GardenObjectFilterInputs;
