import { DEFAULT_PLANNER_SETTINGS } from '@gi/plan';
import { GardenItemType, InteractionStateType } from '@gi/constants';
import {
  HoverableComponent,
  InspectableClassData,
  InteractableComponent,
  InteractableComponentCallbacks,
  KeybindComponent,
  KeyboardEventData,
  PointerDataWithDelta,
  PointerTrackerComponent,
  PointerTrackerComponentState,
  SelectableComponentContext,
  bindToLifecycle,
} from '@gi/core-renderer';

import { SnapUtils } from '../../../simulation/snap-utils';
import CanvasLayers from '../../canvas-layers';
import SettingsContext from '../settings-context';
import PlanSettingsContext from '../plan-settings-context';
import ToolNode, { ToolState } from './tool-node';
import CanvasInteractionInterface from '../../../canvas-interface/canvas-interaction-interface';
import { DEFAULT_GARDEN_CANVAS_SETTINGS } from '../../../garden-canvas-settings';

/** Minimum distance between start/end to be considered a drag and not a click */
export const MIN_DRAW_DISTANCE = 2.5;

export interface DrawToolState extends ToolState {
  type: InteractionStateType.ITEM_DRAW;
  itemType: GardenItemType;
  shouldRepeat?: boolean;
  isDragToDraw?: boolean;
}

/**
 * Base draw tool, which more specific draw-tools should be based off.
 *  This doesn't do loads, but does keep consistent repeat functionality, and force a semi-standard external state format.
 */
abstract class DrawTool<T extends DrawToolState> extends ToolNode<T> {
  abstract type: string;

  readonly interactionInterface: CanvasInteractionInterface;
  readonly canvasLayers: CanvasLayers;

  readonly interaction: InteractableComponent;
  readonly keybind: KeybindComponent;
  readonly pointerTracker: PointerTrackerComponent;

  #snapToGrid: boolean = DEFAULT_GARDEN_CANVAS_SETTINGS.snapToGrid;
  get snapToGrid() {
    return this.#snapToGrid;
  }

  #snapToGridDistance: number = SnapUtils.getSnapDistanceFromIsMetric(DEFAULT_PLANNER_SETTINGS.metric);
  get snapToGridDistance() {
    return this.#snapToGridDistance;
  }

  get isDragToDraw() {
    return this.externalState.values.isDragToDraw;
  }
  #repeatCount: number = 0;
  #isDragging: boolean = false;

  #ignoreClick: boolean = true;
  get ignoreClick() {
    return this.#ignoreClick;
  }
  set ignoreClick(ignoreClick: boolean) {
    this.#ignoreClick = ignoreClick;
    if (!this.isDragToDraw) {
      this.interaction.setOption('ignoreClick', ignoreClick);
    }
  }

  constructor(
    interactionInterface: CanvasInteractionInterface,
    canvasLayers: CanvasLayers,
    initialState: Omit<T, 'shouldRepeat' | 'type'>,
    dragToDrawEvent?: PointerEvent
  ) {
    super({
      type: InteractionStateType.ITEM_DRAW,
      shouldRepeat: false,
      isDragToDraw: dragToDrawEvent !== undefined,
      ...initialState,
    } as T); // Why TS :(

    this.interactionInterface = interactionInterface;
    this.canvasLayers = canvasLayers;

    this.components.add(new HoverableComponent()); // Make this hoverable so when drawing other things don't get hovered

    this.interaction = this.components.add(new InteractableComponent());
    this.interaction.addListener('onDragStart', this.#onDragStart);
    this.interaction.addListener('onDragMove', this.#onDragMove);
    this.interaction.addListener('onDragEnd', this.#onDragEnd);
    this.interaction.addListener('onClick', this.#onClick);

    this.pointerTracker = this.components.add(new PointerTrackerComponent());
    this.pointerTracker.onPointerMove = this.#onPointerMove;

    // Repeat keybind: if ctrl or meta key is down.
    // Make sure to update the onDragEnd/onClick handlers if changing this, as they perform their own checks to ensure accuracy.
    this.keybind = this.components.add(new KeybindComponent([['control', 'meta']]));
    this.keybind.onKeyChange = this.#onKeybindChange;

    this.ownGraphics.hitArea = { contains: () => true };
    this.ownGraphics.eventMode = 'static';
    this.ownGraphics.interactiveChildren = false;

    this.zIndex = 1000;

    bindToLifecycle(this, () => {
      const settingsContext = this.getContext(SettingsContext);
      const settingsWatcher = settingsContext.state.addUpdater((state) => {
        this.#snapToGrid = state.values.snapToGrid;
      });
      const planSettingsContext = this.getContext(PlanSettingsContext);
      const planSettingsWatcher = planSettingsContext.state.addUpdater((state) => {
        this.#snapToGridDistance = SnapUtils.getSnapDistanceFromIsMetric(state.values.metric);
      });

      if (dragToDrawEvent && this.isDragToDraw) {
        // Immediately start a drag if we're drag-to-draw mode
        this.interaction.setOption('shouldAutoPan', false);
        this.interaction.setOption('ignoreClick', true);
        this.interaction.handlePointerDown(dragToDrawEvent);
      }

      return () => {
        settingsWatcher.destroy();
        planSettingsWatcher.destroy();
      };
    });
  }

  protected setWillRepeat(willRepeat: boolean) {
    this.externalState.values.shouldRepeat = willRepeat;
  }

  protected repeat() {
    this.#repeatCount++;
  }

  protected end() {
    this.interaction.cancel();
    this.destroy();
  }

  protected snapPointIfNeeded(point: Vector2): Vector2 {
    if (!this.snapToGrid) {
      return point;
    }
    return SnapUtils.snapPoint(point, this.snapToGridDistance);
  }

  #onKeybindChange = (data: KeyboardEventData) => {
    this.setWillRepeat(data.isKeyDown);
    // If we've repeated at least once and the key gets released, end now.
    if (this.#repeatCount > 0 && !data.isKeyDown && !this.#isDragging) {
      this.end();
    }
  };

  onPointerMove?: (data: PointerTrackerComponentState['state']) => void;
  onDragStart?: InteractableComponentCallbacks['onDragStart'];
  onDragMove?: InteractableComponentCallbacks['onDragMove'];
  onDragEnd?: InteractableComponentCallbacks['onDragEnd'];
  onClick?: InteractableComponentCallbacks['onClick'];

  #onDragStart: InteractableComponentCallbacks['onDragStart'] = (data, interaction, controls) => {
    if (data.button !== 0) {
      return;
    }

    controls.stopPropagation();
    this.#isDragging = true;

    if (this.isDragToDraw) {
      // Fake pointermove if we're doing drag-to-draw
      this.#onPointerMove(data);
      return;
    }
    this.ownGraphics.hitArea = { contains: () => false };
    this.onDragStart?.(data, interaction, controls);
  };

  #onDragMove: InteractableComponentCallbacks['onDragMove'] = (data, interaction, controls) => {
    if (!this.#isDragging) {
      return;
    }

    controls.stopPropagation();

    if (this.isDragToDraw) {
      // Fake pointermove if we're doing drag-to-draw
      this.#onPointerMove(data);
      return;
    }
    this.onDragMove?.(data, interaction, controls);
  };

  #onDragEnd: InteractableComponentCallbacks['onDragEnd'] = (data, interaction, controls) => {
    if (!this.#isDragging) {
      return;
    }

    controls.stopPropagation();
    this.#isDragging = false;

    if (this.isDragToDraw) {
      /**
       * Check that the drag ended over the canvas. Otherwise discard, as the user probably isn't
       *  wanting to drag a plant where they can't see.
       * I'd prefer to do this by comparing document.elementFromPoint with the canvas, but that
       *  function seems to return <body /> in some cases, so is unreliable.
       */
      if (
        data.screenPosition.x >= 0 &&
        data.screenPosition.y >= 0 &&
        data.screenPosition.x <= (this.engine?.width ?? Infinity) &&
        data.screenPosition.y <= (this.engine?.height ?? Infinity)
      ) {
        const modifiedData: PointerDataWithDelta = {
          ...data,
          screenPositionDelta: { x: 0, y: 0 },
          screenPositionTotalDelta: { x: 0, y: 0 },
          worldPositionDelta: { x: 0, y: 0 },
          worldPositionTotalDelta: { x: 0, y: 0 },
        };
        this.onDragStart?.(modifiedData, interaction, controls);
        this.onDragEnd?.(modifiedData, interaction, controls);
      }
    } else {
      this.onDragEnd?.(data, interaction, controls);
    }

    if (this.isDragToDraw) {
      this.interaction.setOption('ignoreClick', this.ignoreClick);
    }
    this.externalState.values.isDragToDraw = false;

    // Item may of bypassed filters to allow manipulation. Re-apply selection filters to apply layer mode.
    this.tryGetContext(SelectableComponentContext)?.reapplyFilters();

    if (data.ctrlKey || data.metaKey) {
      this.repeat();
      this.ownGraphics.hitArea = { contains: () => true };
    } else {
      this.destroy();
    }
  };

  #onClick: InteractableComponentCallbacks['onClick'] = (data, interaction, controls) => {
    if (data.button !== 0) {
      return;
    }

    controls.stopPropagation();
    this.onClick?.(data, interaction, controls);

    if (data.ctrlKey || data.metaKey) {
      this.repeat();
      this.ownGraphics.hitArea = { contains: () => true };
    } else {
      this.destroy();
    }
  };

  #onPointerMove = (data: PointerTrackerComponentState['state']) => {
    this.onPointerMove?.(data);
  };

  // eslint-disable-next-line class-methods-use-this
  cancel() {
    // Do nothing rn.
  }

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default DrawTool;
