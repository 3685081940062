import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DeviceDisplayMode, LayerDisplayModes } from '@gi/constants';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { DisplayModeSelectors } from '@gi/display-mode-provider';

import * as CanvasSelectors from '../redux-components/canvas-selectors';

import './garden-canvas-state-overlay.scss';

const GardenPlannerStateOverlay = () => {
  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);
  const snapToGrid = useSelector(LocalSettingsSelectors.getSnapToGrid);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  const locked = useMemo(
    () => (activePlan === null ? false : activePlan.plannerSettings.layer === LayerDisplayModes.VIEW_ONLY),
    [activePlan?.plannerSettings.layer]
  );

  const editingBackground = useMemo(
    () => (activePlan === null ? false : activePlan.plannerSettings.layer === LayerDisplayModes.BACKGROUND_IMAGES),
    [activePlan?.plannerSettings.layer]
  );

  return (
    <div className='garden-planner-state-overlay'>
      {sfgMode && !editingBackground ? <div className='garden-planner-state-overlay-item'>SFG Mode Enabled</div> : null}
      {snapToGrid && !editingBackground ? <div className='garden-planner-state-overlay-item'>Snap To Grid Enabled</div> : null}
      {locked && deviceDisplayMode !== DeviceDisplayMode.MOBILE ? (
        <div className='garden-planner-state-overlay-item warning-item'>
          <i className='icon-lock' /> View-only Mode (editing locked)
        </div>
      ) : null}
    </div>
  );
};

export default GardenPlannerStateOverlay;
