import AddedAreaSVGElement from './added-area';
import DirectionIndicatorSVGElement from './direction-indicator';
import PlanOutlineSVGElement from './plan-outline';
import RemovedAreaSVGElement from './removed-area';
import UnchangedAreaSVGElement from './unchanged-area';

export const PlanDiagramSVGElements = {
  AddedArea: AddedAreaSVGElement,
  RemovedArea: RemovedAreaSVGElement,
  UnchangedArea: UnchangedAreaSVGElement,
  PlanOutline: PlanOutlineSVGElement,
  DirectionIndicator: DirectionIndicatorSVGElement,
};
