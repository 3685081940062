import { NineSliceSpriteLocation, SpriteSourceType } from './constants';

export type NineSliceSpriteNames = Record<NineSliceSpriteLocation, string | null>;

export type NineSliceSpriteSource = {
  type: SpriteSourceType.NINE_SLICE;
  marginWidth: number;
  marginHeight: number;
  spriteWidth: number;
  spriteHeight: number;
  names: NineSliceSpriteNames;
};

export type NineSliceTileSegment = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type RepeatingNineSliceTileSegment = {
  x: number;
  y: number;
  tileWidth: number;
  tileHeight: number;
  width: number;
  height: number;
};

export type NineSliceTileSegments = {
  [NineSliceSpriteLocation.M]: null | RepeatingNineSliceTileSegment;
  [NineSliceSpriteLocation.B]: RepeatingNineSliceTileSegment;
  [NineSliceSpriteLocation.BL]: NineSliceTileSegment;
  [NineSliceSpriteLocation.BR]: NineSliceTileSegment;
  [NineSliceSpriteLocation.L]: RepeatingNineSliceTileSegment;
  [NineSliceSpriteLocation.R]: RepeatingNineSliceTileSegment;
  [NineSliceSpriteLocation.T]: RepeatingNineSliceTileSegment;
  [NineSliceSpriteLocation.TL]: NineSliceTileSegment;
  [NineSliceSpriteLocation.TR]: NineSliceTileSegment;
};

export function nineSliceHasMiddle(nineSliceSpriteSource: NineSliceSpriteSource): boolean {
  return nineSliceSpriteSource !== null;
}

export function getNineSliceSegments(nineSliceSpriteSource: NineSliceSpriteSource, width: number, height: number): NineSliceTileSegments {
  const segments: NineSliceTileSegments = {
    [NineSliceSpriteLocation.M]: null,
    [NineSliceSpriteLocation.B]: {
      x: nineSliceSpriteSource.marginWidth,
      y: height - nineSliceSpriteSource.marginHeight,
      tileWidth: nineSliceSpriteSource.spriteWidth,
      tileHeight: nineSliceSpriteSource.marginHeight,
      width: width - nineSliceSpriteSource.marginWidth * 2,
      height: nineSliceSpriteSource.marginHeight,
    },
    [NineSliceSpriteLocation.BL]: {
      x: 0,
      y: height - nineSliceSpriteSource.marginHeight,
      width: nineSliceSpriteSource.marginWidth,
      height: nineSliceSpriteSource.marginHeight,
    },
    [NineSliceSpriteLocation.BR]: {
      x: width - nineSliceSpriteSource.marginWidth,
      y: height - nineSliceSpriteSource.marginHeight,
      width: nineSliceSpriteSource.marginWidth,
      height: nineSliceSpriteSource.marginHeight,
    },
    [NineSliceSpriteLocation.L]: {
      x: 0,
      y: nineSliceSpriteSource.marginHeight,
      tileWidth: nineSliceSpriteSource.marginWidth,
      tileHeight: nineSliceSpriteSource.spriteHeight,
      width: nineSliceSpriteSource.marginWidth,
      height: height - nineSliceSpriteSource.marginHeight * 2,
    },
    [NineSliceSpriteLocation.R]: {
      x: width - nineSliceSpriteSource.marginWidth,
      y: nineSliceSpriteSource.marginHeight,
      tileWidth: nineSliceSpriteSource.marginWidth,
      tileHeight: nineSliceSpriteSource.spriteHeight,
      width: nineSliceSpriteSource.marginWidth,
      height: height - nineSliceSpriteSource.marginHeight * 2,
    },
    [NineSliceSpriteLocation.T]: {
      x: nineSliceSpriteSource.marginWidth,
      y: 0,
      tileWidth: nineSliceSpriteSource.spriteWidth,
      tileHeight: nineSliceSpriteSource.marginHeight,
      width: width - nineSliceSpriteSource.marginWidth * 2,
      height: nineSliceSpriteSource.marginHeight,
    },
    [NineSliceSpriteLocation.TL]: {
      x: 0,
      y: 0,
      width: nineSliceSpriteSource.marginWidth,
      height: nineSliceSpriteSource.marginHeight,
    },
    [NineSliceSpriteLocation.TR]: {
      x: width - nineSliceSpriteSource.marginWidth,
      y: 0,
      width: nineSliceSpriteSource.marginWidth,
      height: nineSliceSpriteSource.marginHeight,
    },
  };

  // Segment size should not be provided if this graphic has no middle
  if (nineSliceSpriteSource.names[NineSliceSpriteLocation.M] !== null) {
    segments[NineSliceSpriteLocation.M] = {
      x: nineSliceSpriteSource.marginWidth,
      y: nineSliceSpriteSource.marginHeight,
      tileWidth: nineSliceSpriteSource.spriteWidth,
      tileHeight: nineSliceSpriteSource.spriteHeight,
      width: width - nineSliceSpriteSource.marginWidth * 2,
      height: height - nineSliceSpriteSource.marginHeight * 2,
    };
  }

  return segments;
}

export function getNineSliceBaseSegments(nineSliceSpriteSource: NineSliceSpriteSource): NineSliceTileSegments {
  return getNineSliceSegments(
    nineSliceSpriteSource,
    nineSliceSpriteSource.spriteWidth + nineSliceSpriteSource.marginWidth * 2,
    nineSliceSpriteSource.spriteHeight + nineSliceSpriteSource.marginHeight * 2
  );
}

export function createNineSliceSpriteSource(nineSliceSpriteSource: Omit<NineSliceSpriteSource, 'type'>): NineSliceSpriteSource {
  return {
    ...nineSliceSpriteSource,
    type: SpriteSourceType.NINE_SLICE,
  };
}
