import React from 'react';

interface iProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const ModalTabContentContainer = ({ children, style = {} }: iProps) => {
  return (
    <div className='modal-tab-content-container' style={style}>
      {children}
    </div>
  );
};

export default ModalTabContentContainer;
