import { useCallback, useContext, useMemo } from 'react';
import { GlobalPlantListContext, DISPLAY_TYPE, PlantListColumns, PlantListOptions } from '@gi/plant-list';

/** Utility hooks for interacting with plant list options */
const usePlantListOptions = () => {
  const { columns, setColumnVisibility, options, setOptions, displayType } = useContext(GlobalPlantListContext);

  const columnEnabled = useCallback(
    (column: keyof PlantListColumns) => {
      return columns[column];
    },
    [columns]
  );

  const setColumnEnabled = useCallback(
    (column: keyof PlantListColumns) => {
      return (value: boolean) => {
        setColumnVisibility(column, value);
      };
    },
    [setColumnVisibility]
  );

  const isColumnRelevant = useCallback(
    (column: keyof PlantListColumns) => {
      switch (column) {
        case 'area':
          return displayType !== DISPLAY_TYPE.Area;
        case 'inGroundDates':
          return displayType === DISPLAY_TYPE.Area;
        case 'notes':
          return displayType === DISPLAY_TYPE.Variety;
        case 'plantLabel':
          return displayType === DISPLAY_TYPE.Area;
        case 'calendar':
        case 'counts':
        case 'spacings':
        default:
          return true;
      }
    },
    [displayType]
  );

  const getOption = useCallback(
    <T extends keyof PlantListOptions>(option: T): PlantListOptions[T] => {
      return options[option];
    },
    [options]
  );

  const setOption = useCallback(
    <T extends keyof PlantListOptions>(option: T) => {
      return (value: PlantListOptions[T]) => {
        setOptions({ [option]: value });
      };
    },
    [setOptions]
  );

  return useMemo(
    () => ({
      columnEnabled,
      setColumnEnabled,
      isColumnRelevant,
      getOption,
      setOption,
    }),
    [columnEnabled, setColumnEnabled, isColumnRelevant, getOption, setOption]
  );
};

export default usePlantListOptions;
