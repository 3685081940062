import React, { ReactNode, useMemo } from 'react';

import styles from './image-caption.module.css';

interface iProps {
  source?: ReactNode;
  sourceUrl?: string;
  attribution?: ReactNode;
  caption?: ReactNode;
  className?: string;
}

const ImageCaption = ({ source, sourceUrl, attribution, caption, className }: iProps): JSX.Element | null => {
  const sourcePart = useMemo(() => {
    if (!source) {
      return null;
    }
    return sourceUrl ? (
      <a href={sourceUrl} target='_blank' rel='noreferrer' className={styles.source}>
        {source}
      </a>
    ) : (
      source
    );
  }, [source, sourceUrl]);

  const attributionPart = useMemo(() => {
    if (!attribution && !source) {
      return null;
    }
    if (attribution) {
      return (
        <p className={styles.attribution}>
          <span>Attribution: </span> {attribution}
          {source ? <> - {sourcePart}</> : null}
        </p>
      );
    }
    return (
      <p className={styles.attribution}>
        <span>Source: </span>
        {sourcePart}
      </p>
    );
  }, [attribution, source, sourcePart]);

  if (!caption && !attributionPart) {
    return null;
  }

  return (
    <figcaption className={className}>
      {caption ? <p className={styles.caption}>{caption}</p> : null}
      {attributionPart}
    </figcaption>
  );
};

export default ImageCaption;
