import React from 'react';

import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';

import AppHelpDesktop from '@gi/app-help-desktop';
import AppHelpMobile from '@gi/app-help-mobile';
import { useSelector } from 'react-redux';

const AppHelp = (): JSX.Element => {
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  if (deviceDisplayMode === DeviceDisplayMode.DESKTOP) {
    return <AppHelpDesktop />;
  }

  return <AppHelpMobile />;
};

export default AppHelp;
