import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { metricDistanceUnits, imperialDistanceUnits } from '@gi/units';
import { ResourceContext } from '@gi/resource-provider';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { SessionSelectors } from '@gi/react-session';

import { PlantInformationActionCreators, ReducerStateWithPlantInformation } from './plant-information-modal-slice';
import PlantInformationModal from './plant-information-modal';

const PlantInformationModalRenderer = () => {
  const { plant, defaultTab } = useSelector((state: ReducerStateWithPlantInformation) => state.plantInformationModal);
  const { supplierCatalogue } = useContext(ResourceContext);
  const user = useSelector(SessionSelectors.getUser);
  const plan = useSelector(CanvasSelectors.getActivePlan);
  const dispatch = useDispatch();

  if (plant === null || user === null) {
    return null;
  }

  let distanceUnits = user.settings.units.metric ? metricDistanceUnits : imperialDistanceUnits;

  if (plan !== null) {
    // Overwrite distance units with plan-specific value
    distanceUnits = plan.plannerSettings.metric ? metricDistanceUnits : imperialDistanceUnits;
  }

  return (
    <PlantInformationModal
      plant={plant}
      distanceUnits={distanceUnits}
      closeModal={() => dispatch(PlantInformationActionCreators.closePlantInformationModal())}
      user={user}
      plan={plan}
      supplierCatalogue={supplierCatalogue}
      defaultTab={defaultTab}
    />
  );
};

export default PlantInformationModalRenderer;
