import React from 'react';
import '@gi/styles/fonts/fontello';

import './loading-button.scss';

interface IProps {
  children: null | string | React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  loading?: boolean;
  buttonIcon?: string;
  type?: 'button' | 'submit' | 'reset';
  alwaysShowIcon?: boolean;
  iconContainerClassName?: string;
}

const LoadingButton = ({
  className = '',
  disabled = false,
  onClick,
  loading = false,
  buttonIcon = '',
  type = 'button',
  children = null,
  alwaysShowIcon = false,
  iconContainerClassName = '',
}: IProps): JSX.Element => {
  const extraClassName = `${loading ? 'loading' : ''} ${buttonIcon === '' || alwaysShowIcon ? '' : 'has-icon'} ${alwaysShowIcon ? 'always-show-icon' : 'animate-icon'}`;

  const iconContainerClass = `loading-icon ${iconContainerClassName} ${alwaysShowIcon ? '' : 'button-icon'}`;

  const renderIcon = () => {
    if (!loading && buttonIcon) {
      return (
        <>
          <span className={iconContainerClass}>
            <i className={`${buttonIcon}`} />
          </span>
          {alwaysShowIcon ? ' ' : ''}
        </>
      );
    }

    return (
      <>
        <span className={iconContainerClass}>
          <i className='icon-spinner animate-pulse' />
        </span>
        {alwaysShowIcon ? ' ' : ''}
      </>
    );
  };

  return (
    <button
      className={`${className} button loading-button ${extraClassName}`}
      disabled={disabled}
      onClick={onClick}
      type={type} // eslint-disable-line react/button-has-type
    >
      {renderIcon()}
      <span className='button-content'>{children}</span>
    </button>
  );
};

export default LoadingButton;
