import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatusBar, StatusBarLeftContainer, StatusBarRightContainer, StatusBarSection, FullscreenButton } from '@gi/status-bar';
import { SidebarActionCreators, SidebarPosition, SidebarSelectors } from '@gi/garden-platform-sidebar';
import { useRenderMode } from '@gi/react-garden-canvas';
import GardenPlatformVersionsModal from '@gi/garden-platform-versions';

import IntercomButton from './intercom-button';

import './platform-status-bar.scss';

const SidebarStatusbarButton = (): JSX.Element | null => {
  const sidebarState = useSelector(SidebarSelectors.getSidebarPosition);
  const dispatch = useDispatch();

  if (sidebarState === SidebarPosition.Closed) {
    return (
      <div className='sidebar-open-button'>
        <button
          type='button'
          onClick={() => {
            dispatch(SidebarActionCreators.setSidebarPosition(SidebarPosition.Open));
          }}
          aria-label='Open Menu'
        >
          <i className='icon-menu' />
        </button>
      </div>
    );
  }

  return null;
};

const PlatformStatusBar = (): JSX.Element => {
  const renderMode = useRenderMode();
  const [showAttributionModal, setShowAttributionModal] = useState(false);

  return (
    <StatusBar className='platform-status-bar'>
      {showAttributionModal ? <GardenPlatformVersionsModal closeModal={() => setShowAttributionModal(false)} /> : null}
      <SidebarStatusbarButton />
      <StatusBarLeftContainer>
        <StatusBarSection className='copyright-container'>
          <div className='copyrght-block'>&copy;</div>
          <div className='copyrght-block'>
            <span>Growing Interactive Ltd</span> <span>2007-2025</span>
          </div>
        </StatusBarSection>
      </StatusBarLeftContainer>
      <StatusBarRightContainer>
        <StatusBarSection className='version-number'>
          <button type='button' className='version-number-button' onClick={() => setShowAttributionModal(true)}>
            <div className='version'>Version: {VERSION}</div>
            <div className='render-mode'>Mode: {renderMode}</div>
          </button>
        </StatusBarSection>
        <StatusBarSection className='intercom-container'>
          <IntercomButton />
        </StatusBarSection>
        <FullscreenButton />
      </StatusBarRightContainer>
    </StatusBar>
  );
};

export default PlatformStatusBar;
