import { Graphics } from 'pixi.js-new';

import {
  DoubleClickableComponent,
  DraggableComponent,
  HoverableComponent,
  InteractableComponent,
  LongPressableComponent,
  ManipulatableComponent,
  NodeEvent,
  OutlineComponent,
  RightClickableComponent,
  SelectableComponent,
  ShapeComponent,
  ShapeFlag,
  TooltipComponent,
  VisibilityComponent,
} from '@gi/core-renderer';
import { GardenObjectType, LayerType } from '@gi/constants';
import Bitmask from '@gi/bitmask';
import GardenObject from '@gi/garden-object';
import { Geometry } from '@gi/math';
import { metricDistanceUnits } from '@gi/units';
import { StateDef } from '@gi/state';

import { getBlockTooltipText, getPathTooltipText } from './tooltip-utils';
import PlanSettingsContext, { PlanSettingsContextState } from '../plan-settings-context';
import SettingsContext, { SettingsContextState } from '../settings-context';
import GardenItemNode, { GardenItemNodeState } from '../garden-item-node';

// eslint-disable-next-line @typescript-eslint/ban-types
export type GardenObjectNodeState<T extends Record<string, any> = {}> = StateDef<
  {
    start: Vector2;
    mid: Vector2 | null;
    end: Vector2;
    rotation: number;
  } & GardenItemNodeState &
    T,
  [],
  {
    settings: SettingsContextState;
    planSettings: PlanSettingsContextState;
  }
>;

class GardenObjectNode extends GardenItemNode<GardenObjectNodeState> {
  type = 'GardenObjectNode';

  readonly gardenObject: GardenObject;

  readonly shape: ShapeComponent;
  readonly outline: OutlineComponent;
  readonly tooltip: TooltipComponent;
  readonly visibility: VisibilityComponent;

  #planSettings: PlanSettingsContext | null = null;

  graphics: Graphics | null = null;

  constructor(id: number, gardenObject: GardenObject, initialState: GardenObjectNodeState['state']) {
    super(id, initialState, gardenObject.layerType);

    this.name = `${id} - ${gardenObject.name} - ${gardenObject.code}`;
    this.gardenObject = gardenObject;

    // Update our transform to always match the center point of the shape
    this.state.addUpdater(
      (state) => {
        if (state.values.mid) {
          this.transform.state.values.position = Geometry.getBoundingBox(state.values.start, state.values.mid, state.values.end).center;
        } else {
          this.transform.state.values.position = Geometry.midpoint(state.values.start, state.values.end);
        }
        this.transform.state.values.rotation = state.values.rotation;
      },
      { properties: ['start', 'mid', 'end', 'rotation'] }
    );

    // Add required components
    this.components.add(new InteractableComponent());
    this.components.add(new HoverableComponent());
    this.components.add(new SelectableComponent());
    this.components.add(new ManipulatableComponent());
    this.components.add(new DraggableComponent());
    this.components.add(new DoubleClickableComponent());
    this.components.add(new RightClickableComponent());
    this.components.add(new LongPressableComponent());
    this.visibility = this.components.add(new VisibilityComponent());
    const flags =
      gardenObject.plantModifier !== null
        ? Bitmask.Create(ShapeFlag.CULLABLE, ShapeFlag.GARDEN_OBJECT, ShapeFlag.SEASON_EXTENDER)
        : Bitmask.Create(ShapeFlag.CULLABLE, ShapeFlag.GARDEN_OBJECT);
    this.shape = this.components.add(new ShapeComponent({ flags }));
    this.outline = this.components.add(
      new OutlineComponent({
        padding: OutlineComponent.PADDING_SMALL,
        generateHitbox: false,
      })
    );
    this.tooltip = this.components.add(new TooltipComponent());

    this.state.addWatcher(
      () => {
        this.updateTooltip();
      },
      {
        properties: ['start', 'mid', 'end'],
        otherStates: { planSettings: { properties: ['metric'] } },
      }
    );

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    this.graphics = new Graphics();
    this.ownGraphics.addChild(this.graphics);

    this.#planSettings = this.getContext(PlanSettingsContext);
    this.state.connectState('planSettings', this.#planSettings.state);

    const settings = this.getContext(SettingsContext);
    this.state.connectState('settings', settings.state);
  };

  #onBeforeUnbind = () => {
    if (this.graphics) {
      this.graphics.destroy();
      this.graphics = null;
    }
  };

  /**
   * Calculates and returns the dimensions of the shape.
   *  If point2 is null, it will be the bounding box of point1 and point3.
   *  If point2 is not null, it will be the bounding box contianing all 3 points.
   * @returns The dimensions of the shape
   */
  getDimensions(): Dimensions {
    const { start, mid, end } = this.state.values;

    // If we have 3 points, find the bounding box and use it as the size
    if (mid !== null) {
      const { min, max } = Geometry.getBoundingBox(start, mid, end);
      return {
        width: Math.abs(max.x - min.x),
        height: Math.abs(max.y - min.y),
      };
    }

    // We're only 2 points, find the bounding box of the line created
    const diff = Geometry.getPointDelta(start, end);
    return {
      width: Math.abs(diff.x),
      height: Math.abs(diff.y),
    };
  }

  /**
   * Calculates and returns the center of the shape.
   *  If point2 is null, it will be the midpoint of point1 and point3.
   *  If point2 is not null, it will be the center of the bounding box containing all 3 points.
   * @returns The center of the shape
   */
  getCenter(): Vector2 {
    const { start, mid, end } = this.state.values;

    if (mid !== null) {
      return Geometry.getBoundingBox(start, mid, end).center;
    }

    return Geometry.midpoint(start, end);
  }

  /**
   * Returns the layer type that this garden object should belong to.
   * @returns The layer name this belongs to
   */
  getType(): LayerType {
    return this.gardenObject.layerType;
  }

  /**
   * Returns the general display type of this garden object (block/path).
   * @returns The shape type
   */
  getShapeType(): GardenObjectType {
    return this.gardenObject.shape.type;
  }

  /**
   * Sets the points and rotation of the shape.
   * @param start The start point. The start point for rectangle-like shapes and lines.
   * @param mid The mid point. Null for rectangle-like shapes. Used as the control point for lines.
   * @param end The end point. The end point for rectangle-like shapes and lines.
   * @param rotation The rotation of the shape
   */
  setPosition(start: Vector2, mid: Vector2 | null, end: Vector2, rotation: number) {
    this.state.values.start = start;
    this.state.values.mid = mid;
    this.state.values.end = end;
    this.state.values.rotation = rotation;
  }

  /**
   * Updates the tooltip text.
   * Should be overwritten in derived classes to give more accurate tooltip information.
   */
  protected updateTooltip() {
    const { start, mid, end } = this.state.values;
    const distanceUnits = this.#planSettings?.getDistanceUnits() ?? metricDistanceUnits;
    this.tooltip.state.values.text =
      this.getShapeType() === GardenObjectType.BLOCK
        ? getBlockTooltipText(this.gardenObject, this.getDimensions(), distanceUnits)
        : getPathTooltipText(this.gardenObject, Geometry.bezierSegmentsLength(start, mid, end, 0), distanceUnits, start, mid, end);
  }
}

export default GardenObjectNode;
