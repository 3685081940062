/**
 * Formats a string based on provided arguments
 * Either as indexed strings from an array:-
 *   `formatString("this is a {0} string {1}", "formatted", "after formatting")`
 *   `formatString("this is a {a} string {b}", { a: "formatted", b: "after formatting" })`
 * "this is a formatted string after formatting" -> "this is a {0} string {1}"
 *
 * Modified from: https://stackoverflow.com/a/18234317
 */
export function formatString(str: string, ...inputArgs: string[] | number[] | Record<string, string>[]): string {
  let newStr = str;
  if (inputArgs.length) {
    const t = typeof inputArgs[0];
    const args = t === 'string' || t === 'number' ? inputArgs : inputArgs[0];

    const keys = Object.keys(args);
    keys.forEach((key) => {
      newStr = newStr.replace(new RegExp(`\\{${key}\\}`, 'gi'), args[key]);
    });
  }

  return newStr;
}
