import { EventBus, HandleNode, HandleType, InspectableClassData, NodeEventActions, PointerDataWithDelta, bindToLifecycle } from '@gi/core-renderer';
import { Geometry } from '@gi/math';

import LineShapeNode from '../shapes/line-shape-node';
import BaseHandleSetNode from './base-handle-set-node';
import PathGardenObjectNode from '../garden-objects/path-garden-object-node';

// The squared distance from the midpoint the control point must be before being considered active
const MIDPOINT_DISTANCE_SQUARED = 10;

export enum LineHandleSetNodeEvent {
  Start = 'Start',
  Manipulate = 'Manipulate',
  End = 'End',
}

interface iManipulateData {
  start: Vector2;
  end: Vector2;
  controlPoint: Vector2 | null;
}

export type LineHandleSetNodeEventActions = NodeEventActions & {
  [LineHandleSetNodeEvent.Start]: () => void;
  [LineHandleSetNodeEvent.Manipulate]: (data: iManipulateData) => void;
  [LineHandleSetNodeEvent.End]: () => void;
};

/**
 * LineHandleSetNode
 *  Handles used for manipulating a line-like object.
 *  To add support for a different object type, create a new `attachTo...()` function to set up the watcher
 */
class LineHandleSetNode extends BaseHandleSetNode {
  type = 'LineHandleSetNode';
  eventBus: EventBus<LineHandleSetNodeEventActions> = new EventBus(this.eventBus);
  draggingHandle: HandleNode | null = null;

  P1: HandleNode = new HandleNode('Start');
  P2: HandleNode = new HandleNode('End');
  C: HandleNode = new HandleNode('ControlPoint', HandleType.CIRCLE);

  hasControlPoint: boolean = false;

  constructor() {
    super();

    this.shape.state.values.closed = false;
    // Hide the outline, as a line doesn't have any area to outline.
    this.outline.state.values.closed = false;

    this.P1.onDragStart = () => this.#startManipulate(this.P1);
    this.P1.onDrag = (data) => this.#onDragHandle(this.P1, data);
    this.P1.onDragEnd = () => this.#endManipulate();

    this.P2.onDragStart = () => this.#startManipulate(this.P2);
    this.P2.onDrag = (data) => this.#onDragHandle(this.P2, data);
    this.P2.onDragEnd = () => this.#endManipulate();

    this.C.onDragStart = () => this.#startManipulate(this.C);
    this.C.onDrag = (data) => this.#onDragHandle(this.C, data);
    this.C.onDragEnd = () => this.#endManipulate();
    this.C.onDoubleClick = () => this.#clearCurve();

    this.addHandles(this.P1, this.P2, this.C);
  }

  /**
   * Sets the positions of the handles.
   * @param start The start position of the line
   * @param end The end position of the line
   * @param controlPoint The control point of the line. If null, a straight line will be created.
   */
  setFromPoints(start: Vector2, end: Vector2, controlPoint: Vector2 | null = null) {
    this.P1.setPosition(start);
    this.P2.setPosition(end);
    this.C.setPosition(controlPoint ?? Geometry.midpoint(start, end));
    this.hasControlPoint = controlPoint !== null;
    this.#updateOutline();
  }

  /**
   * Emits a `start` event
   */
  emitStart() {
    this.eventBus.emit(LineHandleSetNodeEvent.Start);
  }

  /**
   * Emits an `end` event
   */
  emitEnd() {
    this.eventBus.emit(LineHandleSetNodeEvent.End);
  }

  #startManipulate(target: HandleNode) {
    this.draggingHandle = target;
    this.emitStart();
  }

  #onManipulate(start: Vector2, end: Vector2, controlPoint: Vector2 | null) {
    this.eventBus.emit(LineHandleSetNodeEvent.Manipulate, { start, end, controlPoint });
  }

  #endManipulate() {
    this.draggingHandle = null;
    this.emitEnd();
  }

  #onDragHandle(target: HandleNode, data: PointerDataWithDelta) {
    if (target === this.C) {
      this.#onDragControlPoint(data);
      return;
    }

    const otherHandle = target === this.P1 ? this.P2 : this.P1;
    const snappedPosition = this.snapVector(data.worldPosition, otherHandle.getPosition(), data.shiftKey);
    target.setPosition(snappedPosition);

    if (!this.hasControlPoint) {
      this.C.setPosition(Geometry.midpoint(this.P1.getPosition(), this.P2.getPosition()));
    }

    this.#updateOutline();
    this.#onManipulate(this.P1.getPosition(), this.P2.getPosition(), this.hasControlPoint ? this.C.getPosition() : null);
  }

  #onDragControlPoint(data: PointerDataWithDelta) {
    const center = Geometry.midpoint(this.P1.getPosition(), this.P2.getPosition());
    const dist = Geometry.distSquared(center, data.worldPosition);
    this.hasControlPoint = dist > MIDPOINT_DISTANCE_SQUARED; // Ignore control point if distance is too small

    const position = this.hasControlPoint ? data.worldPosition : center;
    this.C.setPosition(position);

    this.#updateOutline();
    this.#onManipulate(this.P1.getPosition(), this.P2.getPosition(), this.hasControlPoint ? this.C.getPosition() : null);
  }

  #clearCurve() {
    this.hasControlPoint = false;
    this.#updateHandlePos(this.C, Geometry.midpoint(this.P1.getPosition(), this.P2.getPosition()));
    this.#onManipulate(this.P1.getPosition(), this.P2.getPosition(), null);
  }

  #updateHandlePos(handle: HandleNode, position: Vector2) {
    handle.setPosition(position);
    this.#updateOutline();
  }

  #updateOutline() {
    this.shape.state.values.points = [this.P1.getPosition(), this.C.getPosition(), this.P2.getPosition()];
  }

  /**
   * Attaches this handle set to the given garden object.
   * This attachment cannot be undone without destroying the handleSet.
   * @param gardenObject The PathGardenObjectNode to attach to
   */
  attachToGardenObject(gardenObject: PathGardenObjectNode) {
    this.targets = [gardenObject];
    bindToLifecycle(this, () => {
      const watcher = gardenObject.state.addWatcher(
        (state) => {
          if (this.draggingHandle === null) {
            this.setFromPoints(state.values.start, state.values.end, state.values.mid);
          }
        },
        { properties: ['start', 'mid', 'end'] }
      );
      return () => watcher.destroy();
    });
  }

  /**
   * Attaches this handle set to the given line shape.
   * This attachment cannot be undone without destroying the handleSet.
   * @param line The LineShapeNode to attach to
   */
  attachToLine(line: LineShapeNode) {
    this.targets = [line];
    bindToLifecycle(this, () => {
      const watcher = line.state.addWatcher(
        (state) => {
          if (this.draggingHandle === null) {
            this.setFromPoints(state.values.point1, state.values.point3, state.values.point2);
          }
        },
        { properties: ['point1', 'point2', 'point3'] }
      );
      return () => watcher.destroy();
    });
  }

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default LineHandleSetNode;
