import { networkConfig } from '@gi/config';

export type PlanBackgroundImageSrcCrop = {
  x: number;
  y: number;
  width: number;
  height: number;
};

/** Plan background images are saved against a plan ID and a date string */
export type PlanBackgroundImageSrc = {
  planId: number;
  id: string;
  crop: PlanBackgroundImageSrcCrop | null;
};

export type PlanBackgroundImage = {
  src: PlanBackgroundImageSrc;
  position: Vector2;
  dimensions: Dimensions;
  rotation: number;
};

function getImageTransformParams(crop: PlanBackgroundImageSrcCrop | null): string | null {
  if (crop) {
    return [
      'c_inset',
      `x1_${Math.round(crop.x - crop.width / 2)}`,
      `y1_${Math.round(crop.y - crop.height / 2)}`,
      `x2_${Math.round(crop.x + crop.width / 2)}`,
      `y2_${Math.round(crop.y + crop.height / 2)}`,
    ].join(',');
  }
  return null;
}

function getImageURL(id: string, crop: PlanBackgroundImageSrcCrop | null = null): string {
  const base = `${networkConfig.newApiEndpoint}/gardenplanner/api/v1.1/PlanBackground/${id}`;
  const transforms = getImageTransformParams(crop);
  if (transforms) {
    return `${base}?transforms=${transforms}`;
  }
  return base;
}

export const PlanBackgroundImageUtils = {
  getImageURL,
};
