import React, { ReactNode } from 'react';

import LoadingScreen from './loading-screen';

import './error-screen.scss';

interface iProps {
  children: ReactNode;
  hideVersion?: boolean;
  className?: string;
  centerContent?: boolean;
}
const ErrorScreen = ({ children, hideVersion, className, centerContent = true }: iProps): JSX.Element => {
  const classNames = ['error-screen'];
  if (className) {
    classNames.push(className);
  }

  return (
    <LoadingScreen hideVersion={hideVersion} className={classNames.join(' ')} centerContent={centerContent}>
      {children}
    </LoadingScreen>
  );
};

export default ErrorScreen;
