import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';
import { RunningTutorial, Tutorial, Tutorials, VideoAnalytics } from './tutorial';
import TutorialUtils from './tutorial-utils';

type TutorialContextType = {
  startTutorial: (tutorial: Tutorial) => void;
  stopTutorial: (() => void) | null;
  runningTutorial: RunningTutorial | null;
  next: (() => void) | null;
  previous: (() => void) | null;
  tutorials: Tutorials | null;
  setTutorials: (tutorials: Tutorials) => void;
  showEditor: boolean;
  setShowEditor: (showEditor: boolean) => void;
  sendVideoAnalytics: (videoAnalytics: VideoAnalytics) => void;
};

export const TutorialContext = createContext<TutorialContextType>({} as TutorialContextType);

interface iProps {
  children: React.ReactNode;
}

export const TutorialProvider = ({ children }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const [tutorials, setTutorials] = useState<Tutorials | null>(null);
  const [runningTutorial, setRunningTutorial] = useState<RunningTutorial | null>(null);
  const [showEditor, setShowEditor] = useState(false);

  const triggerAnalyticsEvent = useCallback((tutorial: RunningTutorial) => {
    dispatch(
      GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.EndTutorial, {
        tutorialId: tutorial.tutorial.uuid,
        tutorialName: tutorial.tutorial.name,
        tutorialSteps: tutorial.tutorial.steps.length,
        completed: tutorial.next === null,
        endedAtStep: tutorial.currentIndex + 1,
        maxStep: tutorial.analytics.maxStep + 1,
      })
    );
  }, []);

  const sendVideoAnalytics = useCallback((videoAnalytics: VideoAnalytics) => {
    dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.WatchTutorialVideo, videoAnalytics));
  }, []);

  const startTutorial = useCallback((tutorial: Tutorial) => {
    setRunningTutorial(TutorialUtils.createRunningTutorial(tutorial));
  }, []);

  const stopTutorial = useMemo(() => {
    return runningTutorial === null
      ? null
      : () => {
          triggerAnalyticsEvent(runningTutorial);
          setRunningTutorial(null);
        };
  }, [runningTutorial]);

  const next = useMemo(() => {
    if (!runningTutorial) {
      return null;
    }

    return runningTutorial.next ? () => setRunningTutorial(TutorialUtils.advanceStep(runningTutorial)) : null;
  }, [runningTutorial]);

  const previous = useMemo(() => {
    if (!runningTutorial) {
      return null;
    }

    return runningTutorial.previous ? () => setRunningTutorial(TutorialUtils.backStep(runningTutorial)) : null;
  }, [runningTutorial]);

  const value = useMemo(
    () => ({
      startTutorial,
      stopTutorial,
      runningTutorial,
      next,
      previous,
      tutorials,
      setTutorials,
      showEditor,
      setShowEditor,
      sendVideoAnalytics,
    }),
    [runningTutorial, tutorials, showEditor]
  );

  return <TutorialContext.Provider value={value}>{children}</TutorialContext.Provider>;
};
