import React, { ReactNode, useEffect, useState } from 'react';

interface iProps {
  children: ReactNode;
  isMounted: boolean;
  unmountDelay?: number;
}

/**
 * Only renders the children of this component if `isMounted` is set to true.
 * Will stop rendering the children after a delay of `unmountDelay`ms once `isMounted`
 *  is set to `false`
 */
const DelayedUnmount = ({ children, isMounted, unmountDelay = 200 }: iProps): JSX.Element | null => {
  const [unmountTimer, setUnmountTimer] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [shouldRender, setShouldRender] = useState<boolean>(isMounted);

  useEffect(() => {
    if (isMounted) {
      if (unmountTimer !== null) {
        clearTimeout(unmountTimer);
        setUnmountTimer(null);
      }
      if (!shouldRender) {
        setShouldRender(true);
      }
    } else if (unmountTimer === null) {
      setUnmountTimer(
        setTimeout(() => {
          setShouldRender(false);
        }, unmountDelay)
      );
    }
  }, [isMounted]);

  return shouldRender ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : null;
};

export default DelayedUnmount;
