import React, { useContext, useState } from 'react';
import { WindowPortal } from '@gi/react-utils';
import { generateImagePromise } from '@gi/core-renderer';
import { RulersMode } from '@gi/plan-simulation';

import { GardenCanvasContext } from '../../garden-canvas-context/garden-canvas-context';

import './plan-image-generator.scss';

const PlanImageGenerator = () => {
  const { gardenCanvas } = useContext(GardenCanvasContext);
  const [image, setImage] = useState<null | HTMLImageElement>(null);
  const [imageWindowOpen, setImageWindowOpen] = useState(false);

  if (gardenCanvas === null) {
    return null;
  }

  const onGenerateImage = () => {
    setImage(null);
    const activePlan = gardenCanvas.getActivePlan();
    if (!activePlan) {
      throw new Error('Cannot get dimensions of plan: No active plan from the GardenCanvas.');
    }

    const { dimensions, showRulers } = activePlan.simulatedPlan;
    const rulerSize = activePlan.canvasPlan.rulersNode.state.values.thickness;

    activePlan.canvasPlan.rulersNode.setMode(showRulers ? RulersMode.SHOW_ALL : RulersMode.HIDDEN);

    generateImagePromise(gardenCanvas.engine, {
      canvasWidth: 1000,
      canvasHeight: 1000,
      frame: { top: 0, left: 0, right: dimensions.width, bottom: dimensions.height },
      padding: showRulers ? rulerSize - 1 : 0,
    })
      .then((generatedCanvas) => {
        if (generatedCanvas !== null) {
          generatedCanvas.toBlob((blob) => {
            if (blob !== null) {
              const newImage = new Image();
              newImage.src = URL.createObjectURL(blob);
              setImage(newImage);
              setImageWindowOpen(true);
            }
          });
        }
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        activePlan.canvasPlan.rulersNode.setMode(RulersMode.DEFAULT);
      });
  };

  return (
    <>
      {imageWindowOpen && image !== null ? (
        <WindowPortal
          onWindowClose={() => {
            setImageWindowOpen(false);
          }}
        >
          <div className='plan-image-viewer'>
            <img src={image.src} alt='' />
          </div>
        </WindowPortal>
      ) : null}
      <div className='plan-image-generator'>
        <button type='button' className='button button-secondary' onClick={onGenerateImage}>
          Generate Image
        </button>
      </div>
    </>
  );
};

export default PlanImageGenerator;
