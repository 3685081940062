import { Graphics } from 'pixi.js-new';

import { State, StateDef, StateObserver } from '@gi/state';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import ContentRootContext, { ViewportContextState } from '../../nodes/content-root/content-root-context';
import { hasEngine } from '../../utils/asserts';
import { createOutlinePath } from '../../utils/outline-utils';
import HoverableComponent, { HoverableComponentState } from '../hoverable/hoverable-component';
import SelectableComponent, { SelectableComponentState } from '../selectable/selectable-component';
import ShapeComponent, { ShapeComponentState } from '../shape/shape-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { bindState } from '../../utils/state-utils';
import ManipulatableComponent, { ManipulatableComponentState } from '../manipulatable/manipulatable-component';
import { CameraNodeState } from '../../nodes/camera/camera-node';

export type OutlineComponentOptionsState = StateDef<{
  // Is the outline visible. Defaults to AUTO, based on hover/selection state.
  visible: boolean | 'auto';
  // The colour of the outline. Defaults to AUO, based on hover/selection state.
  colour: string | 'auto';
  // The background of the outline. Defaults to AUO, based on hover/selection state.
  backgroundColour: string | 'auto';
  // How much to inflate the border by from the path.
  padding: number;
  // How thick the outline is.
  thickness: number;
  // Is the outline around a hollow subject?
  hollow: boolean;
  // Should a hitbox matching the outline also be generated and attached?
  generateHitbox: boolean;
  // Prevent the outline from taking hover/pointer events.
  disablePointerEvents: boolean;
  // Is the path closed. Setting to false will disable background colour and the closing line.
  closed: boolean;
}>;

const DEFAULT_OPTIONS: OutlineComponentOptionsState['state'] = {
  visible: 'auto',
  colour: 'auto',
  backgroundColour: 'auto',
  padding: 0,
  thickness: 2,
  hollow: false,
  generateHitbox: true,
  disablePointerEvents: false,
  closed: true,
};

type OutlineComponentInternalState = StateDef<
  {
    visible: boolean;
    colour: string;
    backgroundColour: string;
    path: number[];
    holePath: number[] | null;
  },
  [],
  {
    outline: OutlineComponentOptionsState;
    hoverable: HoverableComponentState;
    selectable: SelectableComponentState;
    shape: ShapeComponentState;
    manipulatable: ManipulatableComponentState;
    viewport: ViewportContextState;
    camera: CameraNodeState;
  }
>;

const DEFAULT_INTERNAL_STATE: OutlineComponentInternalState['state'] = {
  visible: false,
  colour: '#00000088',
  backgroundColour: '#00000044',
  path: [],
  holePath: null,
};

class OutlineComponent extends NodeComponent {
  type = 'OutlineComponent';

  public static PADDING_SMALL = 2;
  public static PADDING_LARGE = 10;

  state: State<OutlineComponentOptionsState>;
  internalState: State<OutlineComponentInternalState>;

  #hitGraphics: Graphics | null = null;
  #graphics: Graphics | null = null;

  #activeCameraWatcher: StateObserver<any> | null = null;

  constructor(options: Partial<OutlineComponentOptionsState['state']> = {}) {
    super();

    this.state = new State<OutlineComponentOptionsState>({
      ...DEFAULT_OPTIONS,
      ...options,
    });
    bindState(this.state, this);

    this.internalState = new State<OutlineComponentInternalState>({
      ...DEFAULT_INTERNAL_STATE,
    });
    bindState(this.internalState, this);

    this.internalState.connectState('outline', this.state);

    // Re-calculate the colours whenever hovered/selected changes
    this.internalState.addUpdater(
      () => {
        this.#updateAutoValues();
      },
      {
        properties: ['visible'],
        otherStates: {
          hoverable: { properties: ['hovered'] },
          selectable: { properties: ['selected', 'preSelected'] },
          manipulatable: { properties: ['manipulating', 'hoveringHandles'] },
          viewport: { properties: ['isPrinting'] },
        },
      }
    );

    // Add/remove the camera watcher whenever our visibility state changes
    // This saves a huge amount of state time by default.
    this.internalState.addValidator(
      (internalState) => {
        if (internalState.values.visible) {
          this.#setUpCameraStateWatcher();
        } else {
          this.#destroyCameraStateWatcher();
        }
      },
      { properties: ['visible'] }
    );

    this.#setUpStateCallbacks();

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);
    const { disablePointerEvents } = this.state.values;

    this.#graphics = new Graphics();
    this.#graphics.eventMode = disablePointerEvents ? 'none' : 'auto';
    this.owner.getOwnGraphics().addChildAt(this.#graphics, 0);

    this.#hitGraphics = new Graphics();
    this.owner.getOwnGraphics().addChild(this.#hitGraphics);

    this.#connectExternalStates();
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    if (this.#graphics) {
      this.#graphics.destroy();
      this.#graphics = null;
    }
    if (this.#hitGraphics) {
      this.#hitGraphics.destroy();
      this.#hitGraphics = null;
    }
    this.#disconnectExternalStates();
    this.#destroyCameraStateWatcher();
  };

  /**
   * Recalculate the colours/visibility of this outline.
   * Will use colour/backgroundColour of state if not on AUTO
   */
  #updateAutoValues() {
    const { visible, colour, backgroundColour } = this.state.values;
    const { hovered } = this.internalState.otherStates.hoverable?.values ?? {};
    const { selected, preSelected } = this.internalState.otherStates.selectable?.values ?? {};
    const { manipulating, hoveringHandles } = this.internalState.otherStates.manipulatable?.values ?? {};
    const { isPrinting } = this.internalState.otherStates.viewport?.values ?? {};

    let _visible: boolean;
    let _colour: string;
    let _backgroundColour: string;

    if (isPrinting) {
      _visible = false;
      _colour = '#00000000';
      _backgroundColour = '#00000000';
    } else if (manipulating) {
      _visible = true;
      _colour = '#52bd64ff';
      _backgroundColour = '#52bd6433';
    } else if (selected && (hovered || hoveringHandles)) {
      _visible = true;
      _colour = '#00dcffff';
      _backgroundColour = '#00dcff33';
    } else if (hovered || hoveringHandles) {
      _visible = true;
      _colour = '#ff972bff';
      _backgroundColour = '#ff972b33';
    } else if (preSelected) {
      _visible = true;
      _colour = '#333333ff';
      _backgroundColour = '#33333333';
    } else if (selected) {
      _visible = true;
      _colour = '#1281b6ff';
      _backgroundColour = '#12b2ff33';
    } else {
      _visible = false;
      _colour = '#00000000';
      _backgroundColour = '#00000000';
    }

    // Only use the generated values if the appropriate setting is set to AUTO
    this.internalState.values.visible = visible === 'auto' || isPrinting ? _visible : visible;
    this.internalState.values.colour = colour === 'auto' || isPrinting ? _colour : colour;
    this.internalState.values.backgroundColour = backgroundColour === 'auto' || isPrinting ? _backgroundColour : backgroundColour;
  }

  /**
   * Function to use instead of the default path rendering method for the outline
   * Has access to all the same data the normal call does.
   * Can be used to change the way outlines appear (such as adding rounded corners)
   */
  customDrawFunction: ((graphics: Graphics, state: State<OutlineComponentOptionsState>, internalState: State<OutlineComponentInternalState>) => void) | null =
    null;

  /**
   * Draw the outline
   * Should be called after any state changes.
   */
  #draw() {
    const { thickness, closed } = this.state.values;
    const { visible, colour, backgroundColour, path, holePath } = this.internalState.values;
    const cameraMagnification = this.internalState.get('camera', 'magnification', 1);

    if (this.#graphics === null) {
      return;
    }

    if (!visible || path.length <= 1) {
      this.#graphics.visible = false;
      return;
    }

    this.#graphics.visible = true;

    if (this.customDrawFunction !== null) {
      this.customDrawFunction(this.#graphics, this.state, this.internalState);
      return;
    }

    this.#graphics.clear().lineStyle({ width: thickness / cameraMagnification, color: colour });

    if (!closed) {
      if (path.length <= 3) {
        return;
      }
      this.#graphics.moveTo(path[0], path[1]);
      for (let i = 2; i < path.length; i += 2) {
        this.#graphics.lineTo(path[i], path[i + 1]);
      }
      return;
    }

    this.#graphics.beginFill(backgroundColour).drawPolygon(path).endFill();
    if (holePath && holePath.length > 0) {
      this.#graphics.beginHole().drawPolygon(holePath).endHole();
    }
  }

  /**
   * Draws a hit area representing the outline.
   */
  #drawHitArea() {
    if (this.#hitGraphics === null) {
      return;
    }

    const { generateHitbox } = this.state.values;
    const { path, holePath } = this.internalState.values;

    if (!generateHitbox) {
      this.#hitGraphics.visible = false;
      return;
    }

    this.#hitGraphics.visible = true;
    this.#hitGraphics.clear().beginFill(0xffffff, 0.00001).drawPolygon(path).endFill();
    if (holePath && holePath.length > 0) {
      this.#hitGraphics.beginHole().drawPolygon(holePath).endHole();
    }
  }

  /**
   * Sets up all the various state watchers needed to make this component work autonomously.
   * [state]                -> [internalState] to copy input settings over.
   * [hoverableState]       -> [internalState] to copy hover state.
   * [selectableState]      -> [internalState] to copy selected state.
   * [shapeState]           -> [internalState] to copy outline path.
   * [internalState]        -> (validator) to attach camera listeners only when visible (performance reasons)
   *    [contentRootState]  -> (watcher) to attach watchers to the active camera when it changes
   *    [cameraState]       -> [internalState] to copy camera magnification over.
   * [internalState]        -> (validator) to re-calculate visibility/colours based on selected/hover
   * [state, internalState] -> (watcher) to redraw the outline whenever something changes.
   *
   * Ideally we wouldn't need an internalState to cache these values, but with selectable/hoverable being optional,
   *  and camera changing, the different combinations of watchers to do it properly would be ♾️
   */
  #setUpStateCallbacks() {
    // Update the internal state wityh values from the options state whenever they change.
    this.internalState.addUpdater(
      (state) => {
        const { visible, colour, backgroundColour } = state.otherStates.outline!.values;

        if (visible !== 'auto') {
          state.values.visible = visible;
        }

        if (colour !== 'auto') {
          state.values.colour = colour;
        }

        if (backgroundColour !== 'auto') {
          state.values.backgroundColour = backgroundColour;
        }
      },
      {
        otherStates: {
          outline: { properties: ['visible', 'colour', 'backgroundColour'] },
        },
      }
    );

    this.internalState.addUpdater(
      (state) => {
        const shapeState = state.otherStates.shape?.values;
        const outlineState = state.otherStates.outline?.values;
        if (!shapeState || !outlineState) {
          return;
        } // These should always be defined
        state.values.path = createOutlinePath(shapeState.points, outlineState.padding, shapeState.closed);
        state.values.holePath = outlineState.hollow ? createOutlinePath(shapeState.points, -outlineState.padding, shapeState.closed) : null;
      },
      {
        otherStates: {
          outline: { properties: ['padding', 'hollow'] },
          shape: { properties: ['points', 'closed'] },
        },
      },
      false
    );

    // Re-draw whenever anything dependant changes
    this.internalState.addWatcher(
      () => this.#draw(),
      {
        properties: ['visible', 'colour', 'backgroundColour', 'path'],
        otherStates: {
          outline: { properties: ['thickness', 'closed'] },
          camera: { properties: ['magnification'] },
        },
      },
      false
    );

    // Re-draw hit area as well
    this.internalState.addWatcher(
      () => this.#drawHitArea(),
      {
        properties: ['path', 'holePath'],
        otherStates: {
          outline: { properties: ['generateHitbox'] },
        },
      },
      false
    );

    // Change event mode on outline whenever hittest flag changes
    this.state.addWatcher(
      ({ values }) => {
        if (this.#graphics) {
          this.#graphics.eventMode = values.disablePointerEvents ? 'none' : 'auto';
        }
      },
      { properties: ['disablePointerEvents'] }
    );
  }

  #connectExternalStates = () => {
    // Set up watcher on the hoverable component
    const hoverable = this.owner?.components.get(HoverableComponent);
    if (hoverable) {
      this.internalState.connectState('hoverable', hoverable.state);
    }

    // Set up watcher on the selectable component
    const selectable = this.owner?.components.get(SelectableComponent);
    if (selectable) {
      this.internalState.connectState('selectable', selectable.state);
    }

    // Set up watcher on the manipulatable component
    const manipulatable = this.owner?.components.get(ManipulatableComponent);
    if (manipulatable) {
      this.internalState.connectState('manipulatable', manipulatable.state);
    }

    // Set up watcher on the shape component to regenerate our outline
    const shape = this.owner?.components.get(ShapeComponent);
    if (!shape) {
      throw new Error('OutlineComponent requries a ShapeComponent to be present on its owner');
    }
    this.internalState.connectState('shape', shape.state);

    // Set up watcher on the content root for when print mode is entered
    const contentRoot = this.owner?.getContext(ContentRootContext);
    if (contentRoot) {
      this.internalState.connectState('viewport', contentRoot.state);
    }

    // Set up camera watchers on bind if they don't already exist
    if (this.internalState.values.visible) {
      this.#setUpCameraStateWatcher();
    }
  };

  #disconnectExternalStates = () => {
    this.internalState.disconnectState('hoverable');
    this.internalState.disconnectState('selectable');
    this.internalState.disconnectState('manipulatable');
    this.internalState.disconnectState('shape');
    this.internalState.disconnectState('viewport');
  };

  /**
   * Watches for active camera changes and attaches a watcher to it to steal the magnification from.
   */
  #setUpCameraStateWatcher = () => {
    if (!this.owner) {
      return;
    }

    if (this.#activeCameraWatcher) {
      return;
    }

    const context = this.owner.getContext(ContentRootContext);

    this.#activeCameraWatcher = context.state.addUpdater((state) => {
      if (state.otherStates.activeCamera) {
        this.internalState.connectState('camera', state.otherStates.activeCamera);
      } else {
        this.internalState.disconnectState('camera');
      }
    });
  };

  /**
   * Destroys any camera-related state watching things.
   */
  #destroyCameraStateWatcher = () => {
    if (this.#activeCameraWatcher) {
      this.#activeCameraWatcher.destroy();
      this.#activeCameraWatcher = null;
    }
    this.internalState.disconnectState('camera');
  };

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
    {
      type: InspectableClassDataType.Property,
      property: 'internalState',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default OutlineComponent;
