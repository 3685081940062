import React from 'react';
import { SORT_ORDER } from '../../types/plant-list-types';

import './column-sort-icon.scss';

interface iProps {
  upSortType: SORT_ORDER;
  downSortType: SORT_ORDER;
  currentSortType: SORT_ORDER;
  setSortOrder: (sortOrder: SORT_ORDER) => void;
}

const ColumnSortIcon = ({ upSortType, downSortType, currentSortType, setSortOrder }: iProps): JSX.Element => {
  const onSortUp = () => {
    if (currentSortType === upSortType) {
      setSortOrder(downSortType);
    } else {
      setSortOrder(upSortType);
    }
  };

  const onSortDown = () => {
    if (currentSortType === downSortType) {
      setSortOrder(upSortType);
    } else {
      setSortOrder(downSortType);
    }
  };

  return (
    <div className='column-sort-icon no-print'>
      <button className={`button-up ${currentSortType === upSortType ? 'active' : ''} `} aria-label='Sort up' type='button' onClick={onSortUp}>
        <i className='icon-sort-up' />
      </button>
      <button className={`button-down ${currentSortType === downSortType ? 'active' : ''} `} aria-label='Sort down' type='button' onClick={onSortDown}>
        <i className='icon-sort-down' />
      </button>
    </div>
  );
};

export default ColumnSortIcon;
