import { Node, SelectableComponentContext } from '@gi/core-renderer';
import { SimulatedGardenItem, SimulatedGardenItemEffect, SimulatedGardenItemEvent } from '../simulation/simulated-garden-item';

class CanvasItem {
  destroyed = false;

  nodes: Node[] = [];

  destroy() {
    if (this.destroyed) {
      console.error('Attempted to destroy already destroyed CanvasItem');
      return;
    }
    this.destroyed = true;
    this.nodes.forEach((node) => {
      node.destroy();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  registerEffectHandler(simulatedItem: SimulatedGardenItem, target: Node) {
    const handler = (added: SimulatedGardenItemEffect[]) => {
      const toRemove: SimulatedGardenItemEffect[] = [];
      added.forEach((effect) => {
        switch (effect) {
          case SimulatedGardenItemEffect.JustCreated: {
            toRemove.push(SimulatedGardenItemEffect.JustCreated);
            const selectable = target.tryGetContext(SelectableComponentContext);
            if (selectable) {
              selectable.bypassFilters(() => selectable.setSelection([target]));
            }
            break;
          }
          case SimulatedGardenItemEffect.JustPasted: {
            toRemove.push(SimulatedGardenItemEffect.JustPasted);
            target.tryGetContext(SelectableComponentContext)?.addToSelection(target);
            break;
          }
          default: {
            // Nothing
          }
        }
      });

      if (toRemove.length > 0) {
        simulatedItem.removeEffect(...toRemove);
      }
    };
    simulatedItem.on(SimulatedGardenItemEvent.EffectsChanged, handler);
    handler([...simulatedItem.effects.values()]);
  }
}

export default CanvasItem;
