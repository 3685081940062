import React, { CSSProperties, MouseEvent, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlannerIconWithFamily } from '@gi/planner-icon';
import { DrawingToolsContext } from '@gi/drawing-tools';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { PlantInformationActionCreators } from '@gi/plant-information-modal';
import Plant from '@gi/plant';
import { SearchDisplayMode } from '@gi/search-service';
import { CompanionIcon } from '@gi/plant-icon';

import SelectableItem, { iSelectableItemTag } from '../selectable-item';

interface iProps {
  plant: Plant;
  style?: CSSProperties;
  onClick: () => void;
}

const SelectablePlantRow = ({ plant, style, onClick }: iProps): JSX.Element => {
  const { onPlantSelected, cancelDraw, selectedPlantCode, favouritePlants, toggleFavouritePlant, userLoading, plantFilters } = useContext(DrawingToolsContext);
  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);
  const dispatch = useDispatch();

  const [isTogglingFavourite, setIsTogglingFavourite] = useState(false);
  const [userWasLoading, setUserWasLoading] = useState<boolean>(false);

  const { searchResults } = plantFilters.filters.search.inputs;
  const searchResult = searchResults.getResult(plant.code);

  const companionsMode = useMemo(() => plantFilters.filters.companion?.inputs?.enabled as boolean, [plantFilters]);

  /**
   * Keep track of if this plant is selected
   */
  const isSelected = useMemo(() => {
    return selectedPlantCode === plant.code;
  }, [selectedPlantCode, plant]);

  /**
   * When clicked, select the plant and let our parent know.
   */
  const handleOnClick = useCallback(() => {
    if (isSelected) {
      cancelDraw();
    } else {
      onPlantSelected(plant);
    }
    onClick();
  }, [onPlantSelected, onClick, plant, isSelected]);

  /**
   * Keep track of if this plant is favourite-d by the user
   */
  const isFavourite = useMemo(() => {
    return favouritePlants.has(plant.code);
  }, [favouritePlants, plant]);

  /**
   * Create the list of tags related to the plant.
   */
  const tags = useMemo(() => {
    const finalTags: iSelectableItemTag[] = [];
    if (plant.perennial) {
      finalTags.push({ name: 'Perennial' });
    }
    if (plant.canBeSquareFootPlant && sfgMode) {
      finalTags.push({ name: 'SFG' });
    }
    if (plant.isNew) {
      finalTags.push({ name: 'New', className: 'new-tag' });
    }
    return finalTags;
  }, [plant, sfgMode]);

  /**
   * Create the small book icon for opening the plant information modal
   */
  const infoButton = useMemo(() => {
    const handleInfoClick = (e: MouseEvent) => {
      e.preventDefault();
      dispatch(PlantInformationActionCreators.openPlantInformationModal({ plant }));
    };

    return (
      <button key='infoButton' type='button' onClick={handleInfoClick}>
        <i className='icon-info-book' />
      </button>
    );
  }, [plant]);

  /**
   * Create the companions icon if we're in companion mode
   */
  const companionIcon = useMemo(() => {
    if (!companionsMode) {
      return null;
    }

    const { companionPlantCodes, plantCodes } = plantFilters.filters.companion.inputs;
    const isHelper = companionPlantCodes.has(plant.code);
    const isHelped = plant.companionPlantCodes.some((code) => plantCodes.has(code));

    return <CompanionIcon key='companionIcon' isHelped={isHelped} isHelper={isHelper} />;
  }, [companionsMode, plantFilters.filters.companion.inputs, plant]);

  /**
   * Create the small favourite icon
   */
  const faveButton = useMemo(() => {
    let className = 'icon-star-empty';
    if (isFavourite) {
      className = 'icon-star';
    }
    if (isTogglingFavourite) {
      className = 'icon-spinner animate-pulse';
    }
    if (userLoading) {
      className += ' disabled';
    }

    const onFaveClick = () => {
      if (!userLoading) {
        setIsTogglingFavourite(true);
        toggleFavouritePlant(plant);
      }
    };

    return (
      <button key='faveButton' type='button' onClick={onFaveClick} disabled={userLoading || isTogglingFavourite}>
        <i className={className} />
      </button>
    );
  }, [plant, isFavourite, isTogglingFavourite, userLoading, toggleFavouritePlant]);

  /**
   * Generate the plant name and otherName if a search result exists.
   */
  const plantName: { name: ReactNode; otherName?: ReactNode } = useMemo(() => {
    if (!searchResult) {
      return { name: plant.name };
    }
    switch (searchResult.displayMode) {
      case SearchDisplayMode.Primary:
        return {
          name: searchResult.getHtml(),
        };
      case SearchDisplayMode.Secondary:
        return {
          name: plant.name,
          otherName: <em title={searchResult.getText()}>{searchResult.getHtml()}</em>,
        };
      default:
        return { name: plant.name };
    }
  }, [searchResult, plant]);

  /**
   * Watch for userLoading changes and reset the isTogglingFavourite flag if the user is loaded.
   */
  useEffect(() => {
    if (userWasLoading && !userLoading && isTogglingFavourite) {
      setIsTogglingFavourite(false);
    }

    if (userWasLoading !== userLoading) {
      setUserWasLoading(userLoading);
    }
  }, [userLoading]);

  return (
    <SelectableItem
      icon={<PlannerIconWithFamily code={plant.code} familyID={plant.familyID} />}
      name={plantName.name}
      otherName={plantName.otherName}
      selected={isSelected}
      tags={tags}
      onClick={handleOnClick}
      buttons={[faveButton, infoButton]}
      statusIcons={[companionIcon]}
      style={style}
      intercomIdentifier='selectable-plant'
    />
  );
};

export default SelectablePlantRow;
