import { createSlice } from '@reduxjs/toolkit';

export type FlagRestartState = {
  flagRestart: boolean;
};

const DEFAULT_STATE: FlagRestartState = {
  flagRestart: false,
};

export const flagRestartSlice = createSlice({
  name: 'flag-restart',
  initialState: DEFAULT_STATE,
  reducers: {
    flagRestart: (state) => {
      state.flagRestart = true;
    },
    clearFlagRestart: (state) => {
      state.flagRestart = false;
    },
  },
});

export const flagRestartReducer = flagRestartSlice.reducer;
export const FlagRestartActions = flagRestartSlice.actions;

interface RootState {
  flagRestart: FlagRestartState;
}

export const FlagRestartSelectors = {
  getFlagRestart: (state: RootState) => state.flagRestart,
};
