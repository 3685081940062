import React from 'react';

interface iProps {
  children: React.ReactNode;
}

const FilterRowGroupTitle = ({ children }: iProps): JSX.Element => {
  return <div className='filter-row-group-title'>{children}</div>;
};

export default FilterRowGroupTitle;
