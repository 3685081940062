/**
 * Converts an image to a data url for transmission/storage
 * @param image The image element to convert
 * @param mimeType The mime-type to aim for. Browsers are required to support 'image/png'
 *  {@see https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL}
 * @returns A data URL representation of the image, or null if the context fails.
 */
export function imageToDataURL(image: HTMLImageElement, mimeType: string | 'image/png' = 'image/png'): string | null {
  const canvas = document.createElement('canvas');
  canvas.width = image.naturalWidth ?? image.width;
  canvas.height = image.naturalHeight ?? image.height;

  const context = canvas.getContext('2d');
  if (!context) {
    return null;
  }

  context.drawImage(image, 0, 0);
  return canvas.toDataURL(mimeType);
}

/**
 * Horrible regex to hopefully extract mime type and extra information from the data URL header part
 * See {@link https://developer.mozilla.org/en-US/docs/Web/URI/Reference/Schemes/data#syntax}
 */
const DataURLHeaderRegex = /^data:(([a-z]+\/[a-zA-Z0-9-]+)(;[a-z-]+=[a-zA-Z0-9-]+)?)(;base64)?$/;

/**
 * Splits a data URL into its mime-type and content. Includes a flag if the URL specifies base64
 * @param dataUrl The full data URL to split
 * @returns The relevant parts of the data URL
 */
export function splitDataURL(dataUrl: string): { mimeType: string; base64: boolean; data: string } | null {
  const parts = dataUrl.split(',');
  if (parts.length !== 2) {
    console.error(`Expected data URL to have 1 , but found ${parts.length - 1}`);
    return null;
  }
  const [header, data] = parts;
  const result = DataURLHeaderRegex.exec(header);

  if (result === null) {
    console.error(`Data URL didn't match expected format`);
    return null;
  }

  // Full MIME-type contains the parameters such as charset=UTF-8 as well.
  // Can get just the base MIME-type from the 3rd value.
  const [, /* fullMatch */ fullMimeType /* mimeType */ /* parameters */, , , base64] = result;

  return {
    mimeType: fullMimeType,
    base64: base64 !== undefined,
    data,
  };
}
