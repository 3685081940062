import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { TransferPlanModalRenderer, TransferPlanActionCreators } from '@gi/react-transfer-plan';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    open: state.transferPlan.open,
    errorMessage: state.transferPlan.errorMessage,
    inProgress: state.transferPlan.inProgress,
    planID: CanvasSelectors.getActivePlanID(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      transferPlan: TransferPlanActionCreators.transferPlan,
      closeModal: TransferPlanActionCreators.closeTransferPlanModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferPlanModalRenderer);
