import { type State } from './state';
import { type StateObserver } from './state-observer';

/**
 * Keeps track of all the states and observers by their ID.
 * Used to remove hard references between states and observers, allowing for better garbage collection.
 */
class StateRegister {
  private maxId: number = 1;

  private stateIds: number[] = [];
  private statesById: Record<number, State<any>> = {};

  private stateObserverIds: number[] = [];
  private stateObserversById: Record<number, StateObserver<any>> = {};

  /** Returns the next new unique ID for an item related o the state system */
  getNewId(): number {
    return this.maxId++;
  }

  /** Checks if a state with the given ID exists */
  hasState(id: number): boolean {
    return this.statesById[id] !== undefined;
  }

  /** Checks if a state observer with the given ID exists */
  hasStateObserver(id: number): boolean {
    return this.stateObserversById[id] !== undefined;
  }

  /** Returns a state by its ID, or null if not found */
  getState(id: number): State<any> | null {
    return this.statesById[id] ?? null;
  }

  /** Returns a state observer by its ID, or null if not found */
  getStateObserver(id: number): StateObserver<any> | null {
    return this.stateObserversById[id] ?? null;
  }

  /** Registers the given state with the store, returning the ID that should be assigned to the state */
  registerState(state: State<any>) {
    const id = this.getNewId();
    this.statesById[id] = state;
    this.stateIds.push(id);
    return id;
  }

  /** Removes the given state from the store (probably because it's been destroyed) */
  unregisterState(state: State<any>) {
    if (this.hasState(state.id)) {
      delete this.statesById[state.id];
      this.stateIds.splice(this.stateIds.indexOf(state.id), 1);
    }
  }

  /** Registers the given state observer with the store, returning the ID that should be assigned to the observer */
  registerStateObserver(state: StateObserver<any>) {
    const id = this.getNewId();
    this.stateObserversById[id] = state;
    this.stateObserverIds.push(id);
    return id;
  }

  /** Removes the given state observer from the store (probably because it's been destroyed) */
  unregisterStateObserver(state: StateObserver<any>) {
    if (this.hasStateObserver(state.id)) {
      delete this.stateObserversById[state.id];
      this.stateObserverIds.splice(this.stateObserverIds.indexOf(state.id), 1);
    }
  }
}

const stateRegister = new StateRegister();
export default stateRegister;
