import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

// import plantFamilies from '@gi/plant-families';
import { ResourceContext } from '@gi/resource-provider';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { GardenItemType, IS_MAC, IS_SAFARI, InteractionStateType, ShapeType } from '@gi/constants';

import { AsyncTask } from './async-task';
import { getInteractionState } from '../redux-components/canvas-selectors';
import { makeItemCursor, loadIcon, makeEmptyItemCursor, makeShapeCursor } from './cursor-utils';

import './garden-canvas-svg-cursor.scss';

interface iProps {
  container: HTMLDivElement | null;
}

const GardenCanvasSVGCursor = ({ container }: iProps): null => {
  const currentIcon = useRef<AsyncTask<string>>(new AsyncTask());

  const cursorScale = useSelector(LocalSettingsSelectors.getCustomCursorScale);
  const useCustomCursor = useSelector(LocalSettingsSelectors.getUseCustomCursors);
  const interactionState = useSelector(getInteractionState);
  const { userArtifactCode } = useContext(ResourceContext);

  // On Safari, manually adjust for DPR in the output SVG.
  const dpr = useMemo<number>(() => (IS_SAFARI ? window.devicePixelRatio : 1), [IS_SAFARI]);

  // On Mac, move the cursor hotspot
  const hotspot = useMemo<Vector2>(() => (IS_MAC ? { x: 1, y: 3 } : { x: 0, y: 0 }), [IS_MAC]);

  /**
   * Sets the cursor to the given data URL. Resets the cursor if passed null.
   */
  const setCursor = useCallback(
    (url: string | null, fallbackCursor: string = 'pointer') => {
      if (!container) {
        return;
      }
      if (url === null) {
        container.style.removeProperty('cursor');
      } else {
        // Chrome doesn't work with 'image-set' so we have to use -webkit-image-set, Chrome won't even
        // Attempt to work if you use 'image-set' and then '-webkit-image-set' as a fallback but I don't know if that's a react issue
        container.style.cursor = IS_SAFARI
          ? `-webkit-image-set(url("${url}") ${window.devicePixelRatio}x) ${hotspot.x} ${hotspot.y}, ${fallbackCursor}`
          : `url("${url}") ${hotspot.x} ${hotspot.y}, ${fallbackCursor}`;
      }
    },
    [container, cursorScale, hotspot]
  );

  /**
   * Creates the blank cursor, with a plus icon and repeat if held down.
   */
  const displayGenericCursor = useCallback(
    (repeat: boolean = false) => {
      setCursor(makeEmptyItemCursor(cursorScale, dpr, repeat));
    },
    [setCursor, cursorScale, dpr]
  );

  /**
   * Creates and displays a cursor with an item icon on it.
   */
  const displayItemCursor = useCallback(
    (itemCode: string, borderColour: string = '#dddddd', repeat: boolean = false) => {
      if (currentIcon.current.id === itemCode) {
        if (currentIcon.current.completed) {
          setCursor(makeItemCursor(cursorScale, dpr, currentIcon.current.result!, borderColour, repeat), 'pointer');
        } else {
          setCursor(makeEmptyItemCursor(cursorScale, dpr, repeat));
          currentIcon.current.onComplete = (result) => {
            setCursor(makeItemCursor(cursorScale, dpr, result, borderColour, repeat), 'pointer');
          };
        }
      } else {
        setCursor(makeEmptyItemCursor(cursorScale, dpr, repeat));
        currentIcon.current.cancel();
        currentIcon.current = new AsyncTask<string>(itemCode);
        currentIcon.current.onComplete = (result) => {
          setCursor(makeItemCursor(cursorScale, dpr, result, borderColour, repeat), 'pointer');
        };
        loadIcon(userArtifactCode, itemCode)
          .then((result) => currentIcon.current.complete(result))
          .catch(() => {
            /* We failed to load the icon */
          });
      }
    },
    [userArtifactCode, setCursor, cursorScale, dpr]
  );

  /**
   * Creates and displays a cursor with a shape/text on it.
   */
  const displayShapeCursor = useCallback(
    (shape: ShapeType | 'TEXT', filled: boolean, repeat: boolean = false) => {
      setCursor(makeShapeCursor(cursorScale, dpr, shape, filled, repeat));
    },
    [setCursor, cursorScale, dpr]
  );

  /**
   * Re-render the cursor every time the interaction state changes.
   * Also re-render on settings change.
   */
  useEffect(() => {
    // If we're not using custom cursors, return.
    if (!useCustomCursor) {
      setCursor(null);
      return;
    }
    // We only have custom cursors for draw operations currently.
    if (interactionState.type !== InteractionStateType.ITEM_DRAW) {
      currentIcon.current.cancel();
      setCursor(null);
      return;
    }
    switch (interactionState.itemType) {
      case GardenItemType.Plant:
        // Render plant icon
        // const familyColour = `#${plantFamilies.get(interactionState.plantFamily)?.color ?? 'dddddd'}`;
        // displayItemCursor(interactionState.plantCode, familyColour, interactionState.shouldRepeat);
        displayGenericCursor(interactionState.shouldRepeat);
        return;
      case GardenItemType.GardenObject:
        // Render garden object cursor
        if (interactionState.path) {
          displayItemCursor(interactionState.gardenObjectCode, '#dddddd', interactionState.shouldRepeat);
        } else {
          displayGenericCursor(interactionState.shouldRepeat);
        }
        return;
      case GardenItemType.Shape:
        // Render shape cursor
        displayShapeCursor(interactionState.subtype, interactionState.filled, interactionState.shouldRepeat);
        return;
      case GardenItemType.Text:
        // Render text cursor
        displayShapeCursor('TEXT', true, interactionState.shouldRepeat);
        return;
      default:
        // Shouldn't get here
        displayGenericCursor(false);
    }
  }, [setCursor, interactionState, useCustomCursor, cursorScale, dpr]);

  return null;
};

export default GardenCanvasSVGCursor;
