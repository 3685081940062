import React, { useCallback, useContext, useMemo } from 'react';

import { AccordionContext } from './accordion-provider';

import styles from './accordion-styles.module.css';

interface iProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'type'> {
  accordionId: string;
}

const AccordionButton = ({ accordionId, children, onClick, className, ...remainingProps }: iProps): JSX.Element => {
  const { setExpanded, isExpanded, unstyled } = useContext(AccordionContext);

  const expanded = useMemo(() => {
    return isExpanded(accordionId);
  }, [accordionId, isExpanded]);

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setExpanded(accordionId, !expanded);
      if (onClick) {
        onClick(e);
      }
    },
    [expanded]
  );

  const classNameString = useMemo(() => {
    const classNames: string[] = [];
    if (!unstyled) {
      classNames.push(styles.accordionButton);
    }
    if (className) {
      classNames.push(className);
    }
    return classNames.length > 0 ? classNames.join(' ') : undefined;
  }, [className, unstyled]);

  return (
    <button
      {...remainingProps}
      type='button'
      className={classNameString}
      onClick={handleOnClick}
      aria-controls={`${accordionId}-content`}
      data-expanded={expanded}
    >
      {children}
    </button>
  );
};

export default AccordionButton;
