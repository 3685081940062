import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GuruDirectoryPage } from '@gi/app-guru-types/pages';
import { GuruDisplayType } from '@gi/app-guru-types';
import { AsyncOperation } from '@gi/utils';
import { GuruActionCreators, GuruSelectors } from '@gi/app-guru-slice/source/garden-guru-slice';
import { LoadingState } from '@gi/constants';

export function useDirectoryPage(composedPageId: string): [AsyncOperation<GuruDirectoryPage> | undefined, () => void] {
  const dispatch = useDispatch();
  const pages = useSelector(GuruSelectors.getPages);

  const page: AsyncOperation<GuruDirectoryPage> | undefined = useMemo(() => {
    return pages[composedPageId];
  }, [pages, composedPageId]);

  const loadPage = useCallback(() => {
    dispatch(GuruActionCreators.loadPage([composedPageId, true]));
  }, [composedPageId]);

  useEffect(() => {
    if (page === undefined) {
      // This page hasn't yet been requested for loading
      dispatch(GuruActionCreators.loadPage([composedPageId, false]));
    }
  }, [page, composedPageId]);

  return useMemo(() => [page, loadPage], [page, loadPage]);
}

export const useGuruHero = (composedPageId: string) => {
  const [page] = useDirectoryPage(composedPageId);
  const sections = useSelector(GuruSelectors.getSections);

  if (!page || page.status === LoadingState.NONE || page.status === LoadingState.LOADING) {
    return null;
  }

  if (page.status === LoadingState.ERROR) {
    return null;
  }

  if (page.status === LoadingState.SUCCESS) {
    const heroIds = page.value.sectionIds.filter((sectionId) => page.value.sections[sectionId].displayType === GuruDisplayType.Hero);

    // No hero section
    if (heroIds.length === 0) {
      return null;
    }

    const heroId = heroIds[0];

    if (!sections || !sections[heroId]) {
      return null;
    }

    const sectionOperation = sections[heroId];

    if (sectionOperation.status === LoadingState.ERROR) {
      return null;
    }

    if (sectionOperation.status !== LoadingState.SUCCESS) {
      return null;
    }

    if (sectionOperation.status === LoadingState.SUCCESS) {
      if (sectionOperation.value.items.length === 0) {
        return null;
      }

      return sectionOperation.value;
    }
  }

  return null;
};
