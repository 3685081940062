import { GardenItemType } from '@gi/constants/types/garden-item-type';
import type { Plan } from './plan';
import { getFreshPlanItemId, getUpdatedZIndexLimits } from './plan-item-utils';

export type PlanGardenObject = {
  id: number;
  code: string;
  start: Vector2;
  mid: Vector2 | null;
  end: Vector2;
  rotation: number;
  locked: boolean;
  zIndex: number;
};

export function createNewPlanGardenObject(
  code: string,
  start: Vector2,
  mid: Vector2 | null,
  end: Vector2,
  rotation: number,
  locked: boolean,
  zIndex: number,
  id: number = -1
): PlanGardenObject {
  return {
    id, // Set initial ID to -1 so when it's added to a plan it's automatically set to the correct ID
    code,
    start,
    mid,
    end,
    rotation,
    locked,
    zIndex,
  };
}

/**
 * Immutably adds a new garden object to a plan and returns the updated plan
 */
export function addGardenObjectToPlan(plan: Plan, planGardenObject: PlanGardenObject): Plan {
  if (plan.itemTypes[planGardenObject.id]) {
    throw new Error('Attempting to add garden object to plan which already has an item with that id');
  }

  const [planWithUpdatedMaxItemId, validatedId] = getFreshPlanItemId(plan, planGardenObject.id);

  const updatedGardenObject = {
    ...planGardenObject,
    id: validatedId,
  };

  const updatedPlan = {
    ...planWithUpdatedMaxItemId,
    ...getUpdatedZIndexLimits(plan, planGardenObject.zIndex),
    itemTypes: {
      ...planWithUpdatedMaxItemId.itemTypes,
      [validatedId]: GardenItemType.GardenObject,
    },
    gardenObjects: {
      ...planWithUpdatedMaxItemId.gardenObjects,
      [validatedId]: updatedGardenObject,
    },
    gardenObjectIds: [...planWithUpdatedMaxItemId.gardenObjectIds, validatedId],
  };

  return updatedPlan;
}

/**
 * Immutably removes a garden object from a plan and returns the updated plan
 */
export function removeGardenObjectFromPlan(plan: Plan, gardenObjectId: number): Plan {
  if (!plan.itemTypes[gardenObjectId] || !plan.gardenObjects[gardenObjectId]) {
    console.error("Attempted to remove garden object from plan which doesn't exist", gardenObjectId);
    return plan;
  }

  const newItemTypes = { ...plan.itemTypes };
  delete newItemTypes[gardenObjectId];

  const newGardenObjects = { ...plan.gardenObjects };
  delete newGardenObjects[gardenObjectId];

  const newGardenObjectIds = [...plan.gardenObjectIds];
  newGardenObjectIds.splice(newGardenObjectIds.indexOf(gardenObjectId), 1);

  const updatedPlan = {
    ...plan,
    itemTypes: newItemTypes,
    gardenObjects: newGardenObjects,
    gardenObjectIds: newGardenObjectIds,
  };

  return updatedPlan;
}

export function updatePlanGardenObject(plan: Plan, planGardenObject: PlanGardenObject): Plan {
  if (!plan.itemTypes[planGardenObject.id] || plan.itemTypes[planGardenObject.id] !== GardenItemType.GardenObject) {
    throw new Error("Attempting to updated a garden object in a plan which doesn't have that garden object already");
  }

  return {
    ...plan,
    ...getUpdatedZIndexLimits(plan, planGardenObject.zIndex),
    gardenObjects: {
      ...plan.gardenObjects,
      [planGardenObject.id]: planGardenObject,
    },
  };
}
