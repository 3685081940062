import React from 'react';

import TemplatePlan from '@gi/template-plan';
import Modal, { ModalCloseButton, ModalContent, ModalHeader, ModalHeaderContent, ModalHeaderTitle, ModalPane, ModalPaneContainer } from '@gi/modal';

import './template-plan-preview-modal.scss';

// TODO: Move to modal defaults somewhere
export const DEFAULT_MODAL_FORM_SECTION_PADDING = 12;
export const DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT = { labelSize: 120 };

export type YearOption = {
  label: string;
  value: number;
};

interface iProps {
  templatePlan: TemplatePlan;
  onClose: () => void;
}

export const TemplatePlanPreviewModal = ({ templatePlan, onClose }: iProps): JSX.Element => {
  return (
    <Modal className='template-plan-preview-modal' closeRequest={onClose}>
      <ModalContent>
        <ModalCloseButton onClick={onClose} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Template Plan Preview: {templatePlan.planName}</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            <div className='template-plan-preview-modal-image'>
              <img src={templatePlan.planPreviewURL} alt={`Preview of ${templatePlan.planName}`} />
            </div>
          </ModalPane>
        </ModalPaneContainer>
      </ModalContent>
    </Modal>
  );
};
