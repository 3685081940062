import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {{
 *  active?: boolean
 * }}
 */
const GardenPlannerIcon = ({ active = false }) => {
  return <i className={`icon-garden-planner sidebar-icon ${active ? 'sidebar-icon-active' : ''}`} />;
};

GardenPlannerIcon.propTypes = {
  active: PropTypes.bool,
};

export default GardenPlannerIcon;
