import React from 'react';

import { DEFAULT_VARIETY } from '@gi/constants';

interface iProps {
  varietyName?: string;
}

const InsetVarietyCell = ({ varietyName = '' }: iProps): JSX.Element | null => {
  const varietyNameDisplayString = varietyName === DEFAULT_VARIETY ? '- (default variety)' : varietyName;

  return <td className='inset-td plant-name-td'>{varietyNameDisplayString}</td>;
};

export default InsetVarietyCell;
