import React from 'react';

import { GardenCanvasContext } from './garden-canvas-context';

export default function withGardenCanvas(Component) {
  return function GardenCanvasComponent(props) {
    return (
      <GardenCanvasContext.Consumer>
        {({ gardenCanvas, createInstance, destroyInstance }) => {
          return <Component {...props} gardenCanvas={gardenCanvas} createInstance={createInstance} destroyInstance={destroyInstance} />;
        }}
      </GardenCanvasContext.Consumer>
    );
  };
}
