import React from 'react';
import PropTypes from 'prop-types';

import './iframe.scss';

const Iframe = (props) => {
  return (
    <div className='iframe-container'>
      <iframe title='iframe' src={props.src} height='100%' width='100%' />
    </div>
  );
};

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Iframe;
