import React from 'react';

import styles from './product-gallery.module.css';

interface iProps {
  index: number;
  count: number;
  setIndex: (index: number) => void;
  className?: string;
  magnify?: () => void;
}

const GalleryNav = ({ index, count, setIndex, className = '', magnify }: iProps): JSX.Element => {
  return (
    <div className={`${styles.galleryNav} ${className}`}>
      <div className={styles.galleryNavInner}>
        <div className={styles.galleryNavGroup}>
          <button
            type='button'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIndex((index + count - 1) % count);
            }}
            className={styles.galleryNavButton}
          >
            <i className='icon-left-dir' />
          </button>
          <div>
            [{index + 1}/{count}]
          </div>
          <button
            type='button'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIndex((index + 1) % count);
            }}
            className={styles.galleryNavButton}
          >
            <i className='icon-right-dir' />
          </button>
        </div>
        {magnify ? (
          <div className={styles.galleryNavGroup}>
            <button
              type='button'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                magnify();
              }}
              className={styles.galleryNavButton}
            >
              <i className='icon-zoom-in' />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GalleryNav;
