import { State, StateDef } from '@gi/state';
import Bitmask, { BitmaskType } from '@gi/bitmask';

import { hasEngine } from '../../utils/asserts';
import { bindState } from '../../utils/state-utils';
import HoverableComponent from '../hoverable/hoverable-component';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import SelectableComponent, { UnselectableFlag } from '../selectable/selectable-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import DisplayModeComponentContext, { DisplayModeComponentContextState, DisplayModeFlag } from './display-mode-component-context';

/** Alpha value to use for disabled nodes */
const DISABLED_ALPHA = 0.2;

type DisplayModeComponentState = StateDef<
  {
    flags: BitmaskType<DisplayModeFlag>;
  },
  [],
  { context: DisplayModeComponentContextState }
>;

const DEFAULT_STATE: DisplayModeComponentState['state'] = {
  flags: Bitmask.NONE.value,
};

/**
 * Component for controlling how a node will display (e.g. disabled/view-only status)
 */
class DisplayModeComponent extends NodeComponent {
  type = 'DisplayModeComponentContext';

  readonly state: State<DisplayModeComponentState>;

  #selectable: SelectableComponent | null = null;
  #hoverable: HoverableComponent | null = null;

  constructor(initialState: Partial<DisplayModeComponentState['state']> = {}) {
    super();

    this.state = new State({ ...DEFAULT_STATE, ...initialState });
    bindState(this.state, this);

    this.state.addWatcher(
      (state) => {
        const contextState = state.otherStates.context;
        if (!contextState) {
          return;
        }
        const { disabledFlags, disabledFlagsComparisonMode, viewOnlyFlags, viewOnlyFlagsComparisonMode } = contextState.values;
        if (disabledFlags !== undefined && viewOnlyFlags !== undefined && this.owner) {
          const disabled = Bitmask.Compare(state.values.flags, disabledFlags, disabledFlagsComparisonMode);
          const viewOnly = Bitmask.Compare(state.values.flags, viewOnlyFlags, viewOnlyFlagsComparisonMode);

          const container = this.owner.getContainer();
          container.interactiveChildren = !disabled && !viewOnly;
          container.eventMode = !disabled && !viewOnly ? 'static' : 'none';
          container.alpha = disabled ? DISABLED_ALPHA : 1;

          if (disabled || viewOnly) {
            this.#selectable?.addUnselectableFlag(UnselectableFlag.DISPLAY_MODE);
            this.#hoverable?.forceUnhover();
          } else {
            this.#selectable?.removeUnselectableFlag(UnselectableFlag.DISPLAY_MODE);
          }
        }
      },
      {
        properties: ['flags'],
        otherStates: { context: { properties: ['disabledFlags', 'disabledFlagsComparisonMode', 'viewOnlyFlags', 'viewOnlyFlagsComparisonMode'] } },
      },
      false
    );

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);

    const context = this.owner.getContext(DisplayModeComponentContext);
    this.state.connectState('context', context.state);

    this.#selectable = this.owner.components.get(SelectableComponent);
    this.#hoverable = this.owner.components.get(HoverableComponent);
  };

  #onBeforeUnbind = () => {
    hasEngine(this);

    this.state.disconnectState('context');

    this.#selectable = null;
    this.#hoverable = null;
  };

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default DisplayModeComponent;
