import React from 'react';
import { UserPlan } from '@gi/user';

interface iProps {
  plan: UserPlan;
  open: () => void;
}

const RecentPlansListItem = ({ plan, open }: iProps): JSX.Element => {
  return (
    <button type='button' className='recent-plans-list-item' onClick={open}>
      <div className='list-item-top-row'>
        <div className='plan-name'>{plan.name}</div>
        <div className='plan-year'>{plan.year}</div>
      </div>
      <div className='list-item-mid-row'>
        <div className='plan-last-modified'>Updated: {plan.modified.fromNow()}</div>
        <div className='plan-controls'>Open</div>
      </div>
    </button>
  );
};

export default RecentPlansListItem;
