/**
 * Enum for the types of transfer plan actions
 *
 * @readonly
 * @enum {string}
 */
const TransferPlanActionTypes = {
  OPEN_TRANSFER_PLAN_MODAL: 'OPEN_TRANSFER_PLAN_MODAL',
  CLOSE_TRANSFER_PLAN_MODAL: 'CLOSE_TRANSFER_PLAN_MODAL',
  TRANSFER_PLAN_START: 'TRANSFER_PLAN_START',
  TRANSFER_PLAN_SUCCESS: 'TRANSFER_PLAN_SUCCESS',
  TRANSFER_PLAN_FAIL: 'TRANSFER_PLAN_FAIL',
};

export default TransferPlanActionTypes;
