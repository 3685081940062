import React from 'react';

interface iProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  inset?: boolean;
}

const ModalPaneSection = ({ className = '', style = {}, children, inset = false }: iProps) => {
  return (
    <div className={`modal-pane-section ${className} ${inset ? 'inset-modal-section' : ''}`} style={style}>
      {children}
    </div>
  );
};

export default ModalPaneSection;
