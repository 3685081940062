import { MD5 } from 'crypto-js';
import PreAuthTicket from './pre-auth-ticket';

const SHARED_KEY = 'A73BE920C62B1867D5DE17620B2A758F';

function addDashes(ticket) {
  const DASH = '-';
  return (
    ticket.substring(0, 8) +
    DASH +
    ticket.substring(8, 12) +
    DASH +
    ticket.substring(12, 16) +
    DASH +
    ticket.substring(16, 20) +
    DASH +
    ticket.substring(20, 32)
  );
}

const preAuthTicketFromLogin = (email, password) => {
  const key = email + MD5(password).toString();

  // Sum charcodes across the whole key to create a fudge offset
  let offset = 0;
  for (let i = 0; i < key.length; i++) {
    offset += key.charCodeAt(i);
  }

  // Create ticket as a string that looks like hex
  // algorithm: ticket[i] = (key[i] + shared[i] + offset % 16).toHex();
  // We %16 to make sure it only results in a single character being added
  let ticket = '';
  for (let j = 0; j < 32; j += 1) {
    ticket += ((key.charCodeAt(j) + SHARED_KEY.charCodeAt(j) + offset) % 16).toString(16);
  }

  ticket = addDashes(ticket);

  return new PreAuthTicket({ ticket });
};

export { preAuthTicketFromLogin };
