import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ResourceContext } from '@gi/resource-provider';
import { usePlantShoppingEntry, useShoppingList } from '../../hooks';

import styles from './plant-entry.module.css';
import { ShoppingActionCreators } from '../../slice/shopping-slice';

interface iProps {
  planId: number;
  uuid: string;
}

const PlantVarietiesSelector = ({ planId, uuid }: iProps): JSX.Element | null => {
  const { store } = useContext(ResourceContext);
  const shoppingList = useShoppingList(planId);
  const [entry] = usePlantShoppingEntry(planId, uuid);
  const dispatch = useDispatch();

  const splitEntry = useCallback(
    (variety: string) => {
      if (entry && store) {
        dispatch(ShoppingActionCreators.splitPlantEntry({ planId, uuid: entry?.uuid, variety, products: store.products }));
      }
    },
    [planId, entry]
  );
  const mergeEntry = useCallback(() => {
    if (entry && store) {
      dispatch(ShoppingActionCreators.mergePlantEntry({ planId, productId: entry.productId, variantId: entry.variantId, products: store.products }));
    }
  }, [planId, entry]);

  if (entry === null) {
    return null;
  }

  const canMerge =
    shoppingList?.plants.productCounts[entry.productId][entry.variantId] && shoppingList?.plants.productCounts[entry.productId][entry.variantId] > 1;
  const canSplit = entry.matchData.varieties.length > 1;

  return (
    <div className={styles.plantVarietiesSelector}>
      {entry?.matchData.varieties.map((variety) => (
        <div className={styles.varietyEntry} key={variety.name}>
          <span className={styles.varietyName}>{variety.name === '-' ? '- (Default)' : variety.name}</span>
          {canSplit ? (
            <button className={styles.removeVarieties} type='button' onClick={() => splitEntry(variety.name)}>
              Split
            </button>
          ) : null}
          {canMerge ? (
            <button className={styles.removeVarieties} type='button' onClick={() => mergeEntry()}>
              Group
            </button>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default PlantVarietiesSelector;
