import React from 'react';

import './styles.scss';

/**
 * Error message for a Google Map
 */
const GoogleMapError = (): JSX.Element => {
  return (
    <div className='google-map-placeholder'>
      <i className='google-map-placeholder-icon icon-attention-circled' />
      <p className='google-map-placeholder-title'>Failed to load Map</p>
      <p>Please check your connection and try again.</p>
    </div>
  );
};

export default GoogleMapError;
