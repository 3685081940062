import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';

import bugsnag from '@gi/bugsnag-instance';
import { SessionSelectors } from '@gi/react-session';
import { BugsnagErrorBoundary } from '@gi/bugsnag';

import CatchAllErrorView from './catch-all-error-view';

let ErrorBoundary: BugsnagErrorBoundary | null = null;

interface iProps {
  children: ReactNode;
}

/**
 * Provides the base error boundary to encapsulate most of the app.
 * Also makes sure bugsnag has the most up-to-date copy of user data.
 */
const BugsnagController = ({ children }: iProps): JSX.Element => {
  const user = useSelector(SessionSelectors.getUser);

  if (ErrorBoundary === null) {
    const plugin = bugsnag.getPlugin('react');
    if (plugin) {
      ErrorBoundary = plugin.createErrorBoundary(React);
    }
  }

  useEffect(() => {
    bugsnag.setUser(user ? user.ID.toString() : undefined, user ? user.email : undefined);
  }, [user]);

  if (ErrorBoundary) {
    return <ErrorBoundary FallbackComponent={CatchAllErrorView}>{children}</ErrorBoundary>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default BugsnagController;
