import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { LoadingState } from '@gi/constants';

import { InputAsyncImage, InputImage, InputRemoteImage, isAsyncImageDef, isImageDef } from '../../types';

import styles from './styles.module.css';

interface iProps {
  image: InputImage | InputRemoteImage | InputAsyncImage;
  alt: string;
  className?: string;
}

const ImageWithPlaceholder = ({ image, alt, className }: iProps): JSX.Element => {
  const imageRef = useRef<HTMLImageElement>(null);
  const isAsyncLoading = useRef<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);

  useEffect(() => {
    let cancelled: boolean = false;
    setLoadingState(LoadingState.LOADING);

    const handleImage = (_image: InputImage | InputRemoteImage) => {
      // Set the source this way instead of with a prop, otherwise the src gets updated before this
      //  useEffect runs, allowing the onLoad callback to fire before loading is set to LOADING, getting the image stuck loading.
      if (imageRef.current) {
        imageRef.current.src = isImageDef(_image) ? _image.image.src : _image.imageSrc;
      }
    };

    if (isAsyncImageDef(image)) {
      isAsyncLoading.current = true;
      if (imageRef.current) {
        imageRef.current.src = '';
      }

      image.objectURL
        .getObjectURL()
        .then((_image) => {
          if (!cancelled) {
            isAsyncLoading.current = false;
            handleImage({ imageSrc: _image });
          }
        })
        .catch(() => {
          isAsyncLoading.current = false;
          setLoadingState(LoadingState.ERROR);
        });
    } else {
      handleImage(image);
    }

    return () => {
      isAsyncLoading.current = false;
      cancelled = true;
      if (isAsyncImageDef(image)) {
        image.objectURL.revokeObjectURL();
      }
    };
  }, [image]);

  const placeholder = useMemo(() => {
    switch (loadingState) {
      case LoadingState.LOADING:
        return (
          <div className={styles.imagePlaceholder}>
            <i className='icon-spinner animate-pulse' />
          </div>
        );
      case LoadingState.ERROR:
        return (
          <div className={styles.imagePlaceholder}>
            <i className='icon-warning' /> Failed to load image
          </div>
        );
      default:
        return null;
    }
  }, [loadingState]);

  const handleOnLoad = useCallback(() => {
    setLoadingState(LoadingState.SUCCESS);
  }, []);

  const handleOnError = useCallback(() => {
    if (!isAsyncLoading.current) {
      setLoadingState(LoadingState.ERROR);
    }
  }, []);

  const classNames = useMemo(() => {
    if (className) {
      return `${styles.imagePreview} ${className}`;
    }
    return styles.imagePreview;
  }, [className]);

  return (
    <div className={classNames} data-loaded={loadingState === LoadingState.SUCCESS}>
      {placeholder}
      <img ref={imageRef} alt={alt} onLoad={handleOnLoad} onError={handleOnError} />
    </div>
  );
};

export default ImageWithPlaceholder;
