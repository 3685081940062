import React from 'react';
import { config } from '@gi/config';
import attribution from '@gi/styles/fonts/fontello/LICENSE.txt';

import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalPaneContainer, ModalPane } from '@gi/modal';

import styles from './styles.module.css';

interface iProps {
  closeModal: () => void;
}

const GardenPlatformVersionsModal = ({ closeModal }: iProps): JSX.Element => {
  return (
    <Modal closeRequest={closeModal}>
      <ModalContent>
        <ModalCloseButton onClick={closeModal} />
        <ModalHeader>Versions, Licenses and Attributions</ModalHeader>
        <ModalPaneContainer className={styles.versionModal}>
          <ModalPane>
            <p>
              Version: <strong>{VERSION}</strong>
            </p>
            <p>
              The Garden Planner is built with and made possible by many open source projects. Please find the licenses and attributions in the files below.
            </p>
            <p>
              <a href={`${config.basename}/assets/LICENSE.txt`} target='_blank' rel='noreferrer'>
                License.txt
              </a>
            </p>
            <p>
              <a href={attribution} target='_blank' rel='noreferrer'>
                Attributions.txt
              </a>
            </p>
          </ModalPane>
        </ModalPaneContainer>
      </ModalContent>
    </Modal>
  );
};

export default GardenPlatformVersionsModal;
