import React from 'react';
import MenuBar from '../common/menu-bar';
import MenuBarGroup from '../common/menu-bar-group';

import PartsListControls from './notes-controls';

const DesktopNotesMenu = (): JSX.Element => {
  return (
    <MenuBar>
      <MenuBarGroup title='Notes'>
        <PartsListControls />
      </MenuBarGroup>
    </MenuBar>
  );
};

export default DesktopNotesMenu;
