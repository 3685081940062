import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, Dispatch, ListenerEffect, addListener, removeListener } from '@reduxjs/toolkit';

import { LocalSettingsActionCreators } from '@gi/local-settings';
import { DrawingToolsContext, TAB_CATEGORIES } from '@gi/drawing-tools';
import { Application, NavigationActionCreators } from '@gi/garden-platform-navigation';
import { PlannerActionCreators } from '@gi/app-planner-slice';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { DeviceDisplayMode, PlannerTabTypes } from '@gi/constants';
import { useAsRef } from '@gi/react-utils';
import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { TutorialContext as OldTutorialContext } from '@gi/tutorials';
import { TutorialContext, TutorialUtils } from '@gi/tutorial';

import { INTERCOM_ACTION, INTERCOM_COMMAND_WHITELIST } from './constants';

import { iIntercomCommandAction } from './intercom-slice';
import { IntercomCommandSideEffect } from './intercom-commands';
import { IntercomCommandSideEffectInstruction } from './shortcuts/intercom-shortcut-types';

/**
 * IntercomMiddlewareComponent
 *
 * Acts as a redux middleware, listening for Intercom related actions and applying required effects
 *
 * Note: Update intercom methods from `IntercomController` do not use ReduxActions so do not pass through
 * this component
 */
const IntercomMiddlewareComponent = (): null => {
  const dispatch = useDispatch();
  const { setActiveTab, setOpen, resetPlantFilters } = useContext(DrawingToolsContext);
  const { setMobileIntroductionModalVisible } = useContext(OldTutorialContext);
  const { tutorials, startTutorial } = useContext(TutorialContext);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  /**
   * For a given side effect type, will apply all required effects
   * Store this function as a ref, as we need to update it outside of callIntercomAction, which would require recreating the listener if done reactively
   */
  const applySideEffectCallback = useAsRef(
    (sideEffects: IntercomCommandSideEffectInstruction[] | null) => {
      if (sideEffects === null) {
        return;
      }

      for (let i = 0; i < sideEffects.length; i++) {
        const sideEffect = sideEffects[i];
        switch (sideEffect.type) {
          case IntercomCommandSideEffect.ResetPlanner: {
            // Reset the garden planner state
            if (deviceDisplayMode === DeviceDisplayMode.DESKTOP) {
              setActiveTab(TAB_CATEGORIES.PLANTS);
              resetPlantFilters();
              setOpen(true);
              dispatch(LocalSettingsActionCreators.setSFGMode(false));
            } else {
              // Mobile display mode
              dispatch(NavigationActionCreators.navigateTo({ application: Application.GardenPlanner }));
              resetPlantFilters();
              dispatch(LocalSettingsActionCreators.setSFGMode(false));
              if (activePlan) {
                dispatch(
                  PlannerActionCreators.setActivePlannerTab({
                    planID: activePlan.id,
                    tabID: PlannerTabTypes.PLANNER,
                  })
                );
              }
            }
            break;
          }
          case IntercomCommandSideEffect.LaunchMobileIntroduction: {
            setMobileIntroductionModalVisible(true);
            break;
          }
          case IntercomCommandSideEffect.StartTutorial: {
            if (tutorials && sideEffect.arguments && typeof sideEffect.arguments === 'string') {
              const tutorial = TutorialUtils.getNamedTutorial(tutorials, sideEffect.arguments, deviceDisplayMode);
              if (tutorial) {
                startTutorial(tutorial);
              }
            }
            break;
          }
          default: {
            console.warn(`Unknown Intercom side effect type: ${sideEffect.type}`);
          }
        }
      }
    },
    [deviceDisplayMode, setActiveTab, resetPlantFilters, setOpen, activePlan, tutorials, startTutorial, setMobileIntroductionModalVisible]
  );

  const callIntercomAction = useCallback((action: iIntercomCommandAction) => {
    // Check whitelist
    if (!INTERCOM_COMMAND_WHITELIST.includes(action.payload.command)) {
      console.error(`Invalid intercom action ${action.payload.command}`);
      return;
    }

    if (!window.Intercom && action.payload.command !== 'none') {
      console.error('Intercom not available');
      return;
    }

    // Some product tours and checklists may require side effects such as opening panes
    // or changing settings
    applySideEffectCallback.current(action.payload.sideEffects);

    if (action.payload.command === 'none') {
      return;
    }

    if (action.payload.id !== null) {
      window.Intercom(action.payload.command, action.payload.id);
    } else {
      window.Intercom(action.payload.command);
    }
  }, []);

  /**
   * Creates/destroys a store listener, so we can watch the store for events.
   */
  useEffect(() => {
    const predicate = () => true;
    const effect: ListenerEffect<AnyAction, unknown, Dispatch<AnyAction>> = (action) => {
      switch (action.type) {
        case INTERCOM_ACTION:
          callIntercomAction(action as iIntercomCommandAction);
          break;
        default:
        // Nothing
      }
    };

    const listener = { predicate, effect };

    dispatch(addListener(listener));

    return () => {
      dispatch(removeListener(listener));
    };
  }, [callIntercomAction]);
  return null;
};

export default IntercomMiddlewareComponent;
