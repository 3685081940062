import React, { useMemo } from 'react';

import { IS_IOS, IS_MAC } from '@gi/constants';

import { TooltipKeyContentDefinition } from '../../types';

import styles from './tooltip-content-styles.module.css';

interface iProps {
  content: TooltipKeyContentDefinition;
}

const TooltipKeyContent = ({ content }: iProps): JSX.Element => {
  const key = useMemo(() => {
    switch (content.key.toLowerCase()) {
      case 'ctrl':
        return IS_MAC || IS_IOS ? '⌘' : 'Ctrl';
      case 'shift':
      case 'shft':
        return 'Shift';
      case 'alt':
        return 'Alt';
      default:
        return content.key;
    }
  }, [content.key]);

  return <kbd className={styles.key}>{key}</kbd>;
};

export default TooltipKeyContent;
