import moment from 'moment-timezone';

/**
 * Takes a unix timestamp and returns a formatted date
 *
 * @param timestamp unix timestamp of the date
 * @param format format which to display the date
 * @param utc if true will display the date in UTC, else will display in the current timezone
 * @returns formatted date string
 */
export function getFormattedDate(timestamp: number, format: string, utc: boolean = true): string {
  if (utc) {
    return moment
      .unix(timestamp / 1000)
      .utc()
      .format(format);
  }
  return moment.unix(timestamp / 1000).format(format);
}
