import React from 'react';

import Plant from '@gi/plant';
import { PlannerIconWithFamily } from '@gi/planner-icon';

import HeaderContainer from '../../header/header-components/header-container';
import HeaderTitle from '../../header/header-components/header-title';
import PlantContentHeaderSubtitle from './plant-content-header-subtitle';

import styles from './plant-content-header.module.css';

interface iProps {
  plant: Plant;
}

const PlantContentHeader = ({ plant }: iProps): JSX.Element => {
  return (
    <HeaderContainer className={styles.plantHeader}>
      <HeaderTitle className={styles.plantHeaderTitle}>
        <PlannerIconWithFamily code={plant.code} familyID={plant.familyID} /> <span className={styles.plantHeaderTitleText}>{plant.name}</span>
      </HeaderTitle>
      <PlantContentHeaderSubtitle plant={plant} />
    </HeaderContainer>
  );
};

export default PlantContentHeader;
