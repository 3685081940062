import Plan from '@gi/plan';

import CanvasActionTypes from './canvas-action-types';

export type OpenPlanActionType = { type: CanvasActionTypes.OPEN_PLAN; planID: number };

export interface ResizeHistoricalPlansResult {
  successfulPlanIds: number[];
  failedPlanIds: number[];
  failedPlans: Record<number, Plan>;
  skippedPlanIds: number[];
}

export interface RetryPlanUploadResult {
  successfulPlanIds: number[];
  failedPlanIds: number[];
  failedPlans: Record<number, Plan>;
}

export enum SavePlanType {
  AUTOSAVE = 'AUTOSAVE',
  SAVE = 'SAVE',
  UPDATE_HISTORIC = 'UPDATE_HISTORIC',
}
