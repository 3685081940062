import React, { ReactNode, useMemo } from 'react';

import BaseObjectiveIcon from './base-objective-icon';

import styles from './icon-styles.module.css';

interface iProps {
  src?: string;
  className?: string;
  children?: ReactNode;
  label?: string;
  percent: number;
  size?: number;
}

const ProgressObjectiveIcon = ({ src, className, children, label, percent, size }: iProps): JSX.Element => {
  const strokeWidth = 5;

  const radius = useMemo(() => 25 - strokeWidth / 2, [strokeWidth]);
  const circumference = useMemo(() => 2 * Math.PI * radius, [radius]);

  const style = useMemo<React.CSSProperties>(() => {
    return {
      strokeWidth,
      strokeDasharray: `${circumference * percent} ${circumference}`,
      strokeLinecap: percent <= 0 ? 'butt' : 'round',
    };
  }, [strokeWidth, circumference, percent]);

  return (
    <BaseObjectiveIcon className={className} src={src} size={size}>
      <div className={styles.progressDoughnut}>
        <svg style={style} viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg'>
          <circle cx={25} cy={25} r={25 - strokeWidth / 2} transform='rotate(-90, 25, 25)' />
        </svg>
      </div>
      {label ? <span className={styles.progressLabel}>{label}</span> : null}
      {children}
    </BaseObjectiveIcon>
  );
};

export default ProgressObjectiveIcon;
