import React, { ReactNode } from 'react';

import './form-layout.scss';
import { HorizontalAlign, LayoutPreset, SizeType, VerticalAlign } from './types';

const LayoutTypes = ['column', 'row', 'row-wrap', 'row-reverse', 'left-right'] as const;
type LayoutType = (typeof LayoutTypes)[number];

export interface FormLayoutOptions {
  layout?: LayoutType;
  xAlign?: HorizontalAlign;
  yAlign?: VerticalAlign;
  size?: SizeType;
  gap?: number;
}

export interface FormLayoutOptionsPreset extends Partial<LayoutPreset<FormLayoutOptions>> {}

interface iProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  layoutPreset?: FormLayoutOptionsPreset;
  mobileLayout?: FormLayoutOptions;
  desktopLayout?: FormLayoutOptions;
}

/**
 * Converts the vertial alignment mode to a className
 * @param layout The layout to use
 * @param vAlign The vAlign mode
 * @returns A className to use
 */
function yAlignToClass(layout: LayoutType, vAlign: VerticalAlign) {
  const align = vAlign === 'top' ? 'start' : vAlign === 'bottom' ? 'end' : vAlign;
  if (['column'].includes(layout)) {
    return `justify-${align}`;
  }
  if (['row', 'row-wrap', 'row-reverse', 'left-right'].includes(layout)) {
    return `align-${align}`;
  }
  return '';
}

/**
 * Converts the horizontal alignment mode to a className
 * @param layout The layout to use
 * @param hAlign The hAlign mode
 * @returns A className to use
 */
function xAlignToClass(layout: LayoutType, hAlign: HorizontalAlign) {
  const align = hAlign === 'left' ? 'start' : hAlign === 'right' ? 'end' : hAlign;
  if (['column'].includes(layout)) {
    return `align-${align}`;
  }
  if (['row', 'row-wrap'].includes(layout)) {
    return `justify-${align}`;
  }
  if (['row-reverse'].includes('layout')) {
    // With row-reverse, the start is now on the right, so flip start and end
    const newAlign = align === 'start' ? 'end' : align === 'end' ? 'start' : align;
    return `justify-${newAlign}`;
  }
  // left-right has it's own justify
  return '';
}

/**
 * Creates a list of classes that will style the element.
 * @param preset The preset to start from
 * @param mobileOverrides Any settings to override the preset on mobile
 * @param desktopOverrides Any settings to override the preset on desktop
 * @returns A list of classes
 */
function getLayoutClasses(preset: iProps['layoutPreset'], mobileOverrides: iProps['mobileLayout'], desktopOverrides: iProps['desktopLayout']) {
  const desktopLayout = desktopOverrides?.layout ?? preset?.desktop?.layout ?? 'row';
  const desktopAlignX = desktopOverrides?.xAlign ?? preset?.desktop?.xAlign ?? 'left';
  const desktopAlignY = desktopOverrides?.yAlign ?? preset?.desktop?.yAlign ?? 'top';
  const desktopSize = desktopOverrides?.size ?? preset?.desktop?.size ?? 'auto';
  const mobileLayout = mobileOverrides?.layout ?? preset?.mobile?.layout ?? 'column';
  const mobileAlignX = mobileOverrides?.xAlign ?? preset?.mobile?.xAlign ?? 'left';
  const mobileAlignY = mobileOverrides?.yAlign ?? preset?.mobile?.yAlign ?? 'top';
  const mobileSize = mobileOverrides?.size ?? preset?.mobile?.size ?? 'auto';
  return [
    `desktop-layout-${desktopLayout}`,
    `desktop-${xAlignToClass(desktopLayout, desktopAlignX)}`,
    `desktop-${yAlignToClass(desktopLayout, desktopAlignY)}`,
    `desktop-size-${desktopSize}`,
    `mobile-layout-${mobileLayout}`,
    `mobile-${xAlignToClass(mobileLayout, mobileAlignX)}`,
    `mobile-${yAlignToClass(mobileLayout, mobileAlignY)}`,
    `mobile-size-${mobileSize}`,
  ];
}

/**
 * Creates a list of styles that will style the element.
 * Should mainly be used for adding CSS variables.
 * @param preset The preset to start from
 * @param mobileOverrides Any settings to override the preset on mobile
 * @param desktopOverrides Any settings to override the preset on desktop
 * @returns A list of styles
 */
function getLayoutStyles(preset: iProps['layoutPreset'], mobileOverrides: iProps['mobileLayout'], desktopOverrides: iProps['desktopLayout']) {
  const desktopGap = desktopOverrides?.gap ?? preset?.desktop?.gap ?? 9;
  const mobileGap = mobileOverrides?.gap ?? preset?.mobile?.gap ?? 9;
  return {
    '--desktop-gap': `${desktopGap}px`,
    '--mobile-gap': `${mobileGap}px`,
  } as Record<string, string>;
}

const FormLayout = ({ children, className, disabled = false, layoutPreset, mobileLayout, desktopLayout }: iProps): JSX.Element => {
  const classNames = ['rforms-layout', ...getLayoutClasses(layoutPreset, mobileLayout, desktopLayout)];
  if (disabled) {
    classNames.push('rforms-layout-disabled');
  }
  if (className) {
    classNames.push(className);
  }

  const styles = getLayoutStyles(layoutPreset, mobileLayout, desktopLayout);

  return (
    <div className={classNames.join(' ')} style={styles}>
      {children}
    </div>
  );
};

export default FormLayout;
