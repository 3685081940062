import React, { useState, createContext, useMemo, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { FormValues, createFormValues } from '@gi/form-responsive';
import Plan, { PlanValidation, PlanPlant, PlantNotesUtils, PlanUtils } from '@gi/plan';
import Plant from '@gi/plant';
import { calculateUsersPlantingCalendar } from '@gi/planting-calendar';
import { RequestSelectors } from '@gi/react-requests';
import { SessionActionCreators, SessionSelectors } from '@gi/react-session';
import { usePrevious } from '@gi/react-utils';
import { ResourceContext } from '@gi/resource-provider';
import { DistanceUnits, getDistanceUnitsFromIsMetric } from '@gi/units';
import { UserPlantVarietySet, DEFAULT_USER_PLANT_VARIETY, UserPlantVarietySetUtils, UserPlantVariety } from '@gi/user';
import { Requests, RequestsUtils } from '@gi/request';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';
import { updatePlantLabel } from '../utils';

type EditPlantInputValues = {
  labelText: string;
  showLabel: boolean;
  rowStart: Vector2;
  rowEnd: Vector2;
  height: number;
  variety: string;
  inGroundAll: boolean;
  inGroundStart: number;
  inGroundEnd: number;
};

function createInitialPlantInputValues(planPlant: PlanPlant): FormValues<EditPlantInputValues> {
  return createFormValues<EditPlantInputValues>({
    labelText: { value: planPlant.labelText },
    showLabel: { value: planPlant.showLabel },
    rowStart: { value: planPlant.rowStart },
    rowEnd: { value: planPlant.rowEnd },
    height: { value: planPlant.height },
    variety: { value: planPlant.variety },
    inGroundAll: { value: planPlant.inGroundAll },
    inGroundStart: { value: planPlant.inGroundStart },
    inGroundEnd: { value: planPlant.inGroundEnd },
  });
}

function checkForAnalytics(dispatch: Dispatch<AnyAction>, oldPlant: PlanPlant, newPlant: PlanPlant, planId: number): void {
  if (oldPlant.inGroundAll !== newPlant.inGroundAll || oldPlant.inGroundEnd !== newPlant.inGroundEnd || oldPlant.inGroundStart !== newPlant.inGroundStart) {
    dispatch(
      GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.SetPlantInGroundDate, {
        plantCode: newPlant.plantCode,
        planId,
        plantId: newPlant.id,
        inGroundStart: newPlant.inGroundStart,
        inGroundEnd: newPlant.inGroundEnd,
        inGroundAll: newPlant.inGroundAll,
      })
    );
  }
  if (oldPlant.variety !== newPlant.variety) {
    dispatch(
      GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.SetPlantVariety, {
        plantCode: newPlant.plantCode,
        planId,
        plantId: newPlant.id,
        varietyName: newPlant.variety,
      })
    );
  }
}

type EditPlantContextType = {
  distanceUnits: DistanceUnits;
  plan: Plan;
  plantItemID: number;
  planPlant: PlanPlant;
  closeModal: () => void;
  plant: Plant;
  userPlantVariety: UserPlantVariety | null;
  editPlantValues: FormValues<EditPlantInputValues>;
  setEditPlantValues: (formValues: FormValues<EditPlantInputValues>) => void;
  setEditPlantValue: <T extends keyof EditPlantInputValues>(field: T, value: EditPlantInputValues[T]) => void;
  plantNotesText: string;
  plantNotesTextChanged: boolean;
  setPlantNotesText: (text: string) => void;
  addingVariety: boolean;
  addingVarietyName: string;
  addVarietySaving: boolean;
  openAddVariety: () => void;
  closeAddVariety: () => void;
  onAddVariety: (varietyName: string) => void;
  customisingVarieties: boolean;
  customisingVarietiesSaving: boolean;
  lastCustomisedVariety: string;
  openCustomiseVarieties: () => void;
  closeCustomiseVarieties: () => void;
  onCustomiseVarietiesSave: (userPlantVarieties: UserPlantVarietySet, variety: string) => void;
  onDone: () => void;
};

export const EditPlantContext = createContext<EditPlantContextType>({} as EditPlantContextType);

interface iProps {
  children: React.ReactNode;
  plantItemID: number;
  plan: Plan;
  closeModal: () => void;
  editItemModalComplete: (plan: Plan) => void;
}

export const EditPlantProvider = ({ children, plan, plantItemID, closeModal, editItemModalComplete }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const user = useSelector(SessionSelectors.getUser);

  const { userPlants, userRegionalPlantingCalendar } = useContext(ResourceContext);

  if (userPlants === null || user === null) {
    return null;
  }

  const editedPlanPlant = useMemo(() => {
    return plan.plants[plantItemID];
  }, [plan, plantItemID]);

  if (editedPlanPlant === null) {
    return null;
  }

  const plant = userPlants.get(editedPlanPlant.plantCode);
  if (plant === null) {
    return null;
  }

  const { varietyForDefaultLabel } = user.planSettings;

  const [editPlantValues, setEditPlantValues] = useState<FormValues<EditPlantInputValues>>(createInitialPlantInputValues(editedPlanPlant));
  const [addingVariety, setAddingVariety] = useState<boolean>(false);
  const [addingVarietyName, setAddingVarietyName] = useState<string>('');
  const [addVarietySaving, setAddVarietySaving] = useState<boolean>(false);
  const [customisingVarieties, setCustomisingVarieties] = useState<boolean>(false);
  const [customisingVarietiesSaving, setCustomisingVarietiesSaving] = useState<boolean>(false);
  const [lastCustomisedVariety, setLastCustomisedVariety] = useState<string>('');
  const [plantNotesText, setPlantNotesText] = useState<string>(PlantNotesUtils.getPlantNoteText(plan.plantNotes, plant.code, editedPlanPlant.variety));

  const setEditPlantValue = <T extends keyof EditPlantInputValues>(field: T, value: EditPlantInputValues[T]): void => {
    setEditPlantValues(editPlantValues.setValue(field, { value }));
  };

  useEffect(() => {
    // Update plant notes text which variety changes
    setPlantNotesText(PlantNotesUtils.getPlantNoteText(plan.plantNotes, plant.code, editPlantValues.values.variety));
  }, [editPlantValues.values.variety]);

  const userPlantVariety = useMemo(() => {
    return UserPlantVarietySetUtils.getByPlantCodeAndName(user.plantVarieties, editedPlanPlant.plantCode, editedPlanPlant.variety);
  }, [user.plantVarieties, editedPlanPlant.plantCode, editedPlanPlant.variety]);

  const defaultPlantingCalendar = useMemo(() => {
    return calculateUsersPlantingCalendar(plant, user, userRegionalPlantingCalendar).numbered;
  }, [plant, user, userRegionalPlantingCalendar]);

  const closeCustomiseVarieties = () => {
    setCustomisingVarieties(false);
  };

  const openCustomiseVarieties = () => {
    setCustomisingVarieties(true);
  };

  const onCustomiseVarietiesSave = (userPlantVarieties: UserPlantVarietySet, varietyName: string) => {
    setCustomisingVarietiesSaving(true);
    setLastCustomisedVariety(varietyName);
    dispatch(SessionActionCreators.saveUserPlantVarietes(user, userPlantVarieties));
    dispatch(
      GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ModifyPlantVariety, {
        plantCode: plant.code,
        planId: plan.id,
        plantId: plant.ID,
        varietyName,
      })
    );
  };

  const onAddVariety = (varietyName: string) => {
    setAddingVarietyName(varietyName);
    setAddVarietySaving(true);

    const newUserVariety = {
      ...DEFAULT_USER_PLANT_VARIETY,
      plantCode: plant.code,
      name: varietyName,
      spacing: plant.spacing,
      inRowSpacing: plant.inRowSpacing,
      rowSpacing: plant.rowSpacing,
      squareFootPlantCount: plant.squareFootPlantCount,
      plantingCalendar: defaultPlantingCalendar,
    };

    const userPlantVarieties = UserPlantVarietySetUtils.addVariety(user.plantVarieties, newUserVariety);
    dispatch(SessionActionCreators.saveUserPlantVarietes(user, userPlantVarieties));
    dispatch(
      GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.AddPlantVariety, {
        plantCode: plant.code,
        planId: plan.id,
        plantId: plant.ID,
        varietyName,
      })
    );
  };

  const openAddVariety = () => {
    setAddingVariety(true);
  };

  const closeAddVariety = () => {
    setAddingVariety(false);
  };

  const requests = useSelector(RequestSelectors.getRequests);
  const previousRequests = usePrevious<Requests>(requests);

  const saveUserRequestID = `SAVE_USER_${user.ID}`;

  useEffect(() => {
    // Effect for setting values and closing the add variety modal when the request completes
    if (
      previousRequests &&
      RequestsUtils.requestInProgress(previousRequests, saveUserRequestID) &&
      RequestsUtils.requestCompleted(requests, saveUserRequestID) &&
      addingVariety &&
      addVarietySaving
    ) {
      const newLabel = updatePlantLabel(editPlantValues.values.labelText, varietyForDefaultLabel, plant, editPlantValues.values.variety, addingVarietyName);

      setEditPlantValues(editPlantValues.setValues(['variety', { value: addingVarietyName }], ['labelText', { value: newLabel }]));

      setPlantNotesText(PlantNotesUtils.getPlantNoteText(plan.plantNotes, plant.code, addingVarietyName));
      setAddingVariety(false);
      setAddVarietySaving(false);
      setAddingVarietyName('');
    }
  }, [requests, addingVariety, addVarietySaving]);

  useEffect(() => {
    // Effect for setting values and closing the customise variety modal when the request completes
    if (
      previousRequests &&
      RequestsUtils.requestInProgress(previousRequests, saveUserRequestID) &&
      RequestsUtils.requestCompleted(requests, saveUserRequestID) &&
      customisingVarieties &&
      customisingVarietiesSaving
    ) {
      const newLabel = updatePlantLabel(editPlantValues.values.labelText, varietyForDefaultLabel, plant, editPlantValues.values.variety, lastCustomisedVariety);

      setEditPlantValues(editPlantValues.setValues(['variety', { value: lastCustomisedVariety }], ['labelText', { value: newLabel }]));
      setPlantNotesText(PlantNotesUtils.getPlantNoteText(plan.plantNotes, plant.code, lastCustomisedVariety));
      setCustomisingVarieties(false);
      setCustomisingVarietiesSaving(false);
      setLastCustomisedVariety('');
    }
  }, [requests, customisingVarieties, customisingVarietiesSaving]);

  const plantNotesTextChanged = useMemo(() => {
    return plantNotesText !== PlantNotesUtils.getPlantNoteText(plan.plantNotes, plant.code, editPlantValues.values.variety);
  }, [plantNotesText]);

  // Base distance units used in form fields based off plan grid units
  const distanceUnits = useMemo(() => {
    return getDistanceUnitsFromIsMetric(plan.plannerSettings.metric);
  }, [plan.plannerSettings.metric]);

  const onDone = () => {
    const validatedPlantData = PlanValidation.validatePlant(
      editedPlanPlant.isSquareFoot,
      editPlantValues.values.rowStart,
      editPlantValues.values.rowEnd,
      editPlantValues.values.height,
      plant,
      userPlantVariety
    );

    const updatedPlanPlant: PlanPlant = {
      ...editedPlanPlant,
      rowStart: validatedPlantData.rowStart,
      rowEnd: validatedPlantData.rowEnd,
      height: validatedPlantData.height,
      showLabel: editPlantValues.values.showLabel,
      labelText: editPlantValues.values.labelText,
      variety: editPlantValues.values.variety,
      inGroundAll: editPlantValues.values.inGroundAll,
      inGroundStart: editPlantValues.values.inGroundStart,
      inGroundEnd: editPlantValues.values.inGroundEnd,
    };

    let updatedPlan = plan;

    if (plantNotesTextChanged) {
      updatedPlan = {
        ...updatedPlan,
        plantNotes: PlantNotesUtils.setPlantNote(updatedPlan.plantNotes, plant.code, editPlantValues.values.variety, plantNotesText),
      };
    }

    updatedPlan = PlanUtils.updatePlanPlant(updatedPlan, updatedPlanPlant);

    checkForAnalytics(dispatch, editedPlanPlant, updatedPlanPlant, updatedPlan.id);
    editItemModalComplete(updatedPlan);
  };

  const value = useMemo<EditPlantContextType>(
    () => ({
      distanceUnits,
      plan,
      plantItemID,
      planPlant: editedPlanPlant,
      closeModal,
      plant,
      userPlantVariety,
      editPlantValues,
      setEditPlantValues,
      setEditPlantValue,
      plantNotesText,
      setPlantNotesText,
      plantNotesTextChanged,
      addingVariety,
      addingVarietyName,
      addVarietySaving,
      openAddVariety,
      closeAddVariety,
      onAddVariety,
      customisingVarieties,
      customisingVarietiesSaving,
      lastCustomisedVariety,
      openCustomiseVarieties,
      closeCustomiseVarieties,
      onCustomiseVarietiesSave,
      onDone,
    }),
    [
      distanceUnits,
      plan,
      plantItemID,
      editedPlanPlant,
      closeModal,
      plant,
      userPlantVariety,
      editPlantValues,
      setEditPlantValues,
      setEditPlantValue,
      plantNotesText,
      setPlantNotesText,
      plantNotesTextChanged,
      addingVariety,
      addingVarietyName,
      addVarietySaving,
      openAddVariety,
      closeAddVariety,
      onAddVariety,
      customisingVarieties,
      customisingVarietiesSaving,
      lastCustomisedVariety,
      openCustomiseVarieties,
      closeCustomiseVarieties,
      onCustomiseVarietiesSave,
      onDone,
    ]
  );

  return <EditPlantContext.Provider value={value}>{children}</EditPlantContext.Provider>;
};
