import React, { useEffect, useRef, useState } from 'react';
import styles from './components.module.css';

const DESCRIPTION_PREVIEW_HEIGHT = 38;

interface iProps {
  description: string;
}

const ProductDescription = ({ description }: iProps): JSX.Element | null => {
  const [expanded, setExpanded] = useState(false);
  const [canExpand, setCanExpand] = useState(false);

  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const outerHeight = outerRef.current?.clientHeight;
    const innerHeight = innerRef.current?.clientHeight;

    if (outerHeight && innerHeight) {
      setCanExpand(outerHeight < innerHeight);
    } else {
      setCanExpand(false);
    }
  }, []);

  if (description === '') {
    return null;
  }

  const maxHeight = expanded ? 'none' : DESCRIPTION_PREVIEW_HEIGHT;

  return (
    <div className={styles.descriptionContainer}>
      <div className={styles.descriptionTitle}>
        <div className={styles.titleText}>Description</div>
        {canExpand || expanded ? (
          <div className={styles.expandDescriptionContainer}>
            <button type='button' onClick={() => setExpanded(!expanded)} className={styles.expandDescription}>
              {expanded ? (
                <>
                  Minimise Description <i className='icon-angle-double-down' />
                </>
              ) : (
                <>
                  Expand Description <i className='icon-angle-double-up' />
                </>
              )}
            </button>
          </div>
        ) : null}
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={`${styles.productDescription} ${canExpand ? styles.expandableDescription : ''}`}
        ref={outerRef}
        style={{ maxHeight }}
        onClick={() => setExpanded(!expanded)}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <div className={styles.descriptionHtml} dangerouslySetInnerHTML={{ __html: description }} ref={innerRef} />
      </div>
    </div>
  );
};

export default ProductDescription;
