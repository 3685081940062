import React, { useContext } from 'react';

import { DistanceUnits } from '@gi/units';

import { PlanSize } from '../utils';
import { PlanDiagramSVGContext } from '../plan-diagram-svg';

import styles from './svg-element-styles.module.css';

interface iProps {
  plan: PlanSize;
  showDistances?: boolean;
  distanceUnits?: DistanceUnits;
  variant: 'solid' | 'dashed';
}

/**
 * Displays a solid/dashed rectangle around the plan defined by x/y/width/height.
 * Includes distance text if `showDistances` is true and valid `distanceUnits` are provided.
 */
const PlanOutlineSVGElement = ({ plan, showDistances = false, distanceUnits, variant = 'solid' }: iProps): JSX.Element => {
  const { scale, offset } = useContext(PlanDiagramSVGContext);

  const className = variant === 'solid' ? styles.planSolid : styles.planDashed;

  return (
    <>
      {showDistances && distanceUnits !== undefined ? (
        <>
          <text x={offset.x + (plan.x + plan.width / 2) * scale} y={offset.y + plan.y * scale - 10} className={styles.planText}>
            {distanceUnits.getUnitString(plan.width)}
          </text>
          <text x={offset.x + plan.x * scale - 10} y={offset.y + (plan.y + plan.height / 2) * scale} className={styles.planText} transform='rotate(-90)'>
            {distanceUnits.getUnitString(plan.height)}
          </text>
        </>
      ) : null}
      <rect x={offset.x + plan.x * scale} y={offset.y + plan.y * scale} width={plan.width * scale} height={plan.height * scale} className={className} />
    </>
  );
};

export default PlanOutlineSVGElement;
