import React from 'react';

import './filter-row-group.scss';

interface iProps {
  children: React.ReactNode;
  className?: string;
}

const FilterRowGroup = ({ children, className = '' }: iProps) => {
  return <div className={`filter-row-group ${className}`}>{children}</div>;
};

export default FilterRowGroup;
