import { networkConfig } from '@gi/config';
import { networkErrorReporter, networkService, RequestAuthMode, RequestError } from '@gi/gi-network';
import { JournalImageTransform } from './journal-image-transform';

class JournalImageService {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
    console.debug(`Created Journal Image Server with endpoint ${this.endpoint}`);
  }

  private getBaseURL(imageName: string): string {
    return `${this.endpoint}/journal/images/${imageName}`;
  }

  getJournalImage(imageId: string, transforms?: JournalImageTransform): Promise<Blob> {
    const url = this.getBaseURL(imageId);
    return networkService
      .getBlob(url, transforms, RequestAuthMode.Bearer)
      .then((response) => response.body)
      .catch(networkErrorReporter('GET', 'Journal Image'))
      .catch((err: RequestError) => {
        console.error(err);
        throw err;
      });
  }
}

// At the moment we'll keep this as a singleton
export default new JournalImageService(networkConfig.newApiEndpoint);
