import React from 'react';

import { ModalPane, ModalPaneSection, ModalPaneSectionContent, ModalPaneSectionHeader } from '@gi/modal';

import { TextFormRow, VerticalFormRow } from '@gi/form';
import FormField, { FORM_FIELD_PRESETS, InputContainer } from '@gi/form-responsive';

interface iProps {
  location: string;
  setLocation: (location: string) => void;
  description: string;
  setDescription: (description: string) => void;
}

const PublishPlanPageInformation = ({ location, setLocation, description, setDescription }: iProps): JSX.Element => {
  return (
    <ModalPane>
      <ModalPaneSection>
        <ModalPaneSectionHeader>Published Page Information</ModalPaneSectionHeader>
        <ModalPaneSectionContent>
          <VerticalFormRow>
            <FormField label='Location' htmlFor='publish-plan:location' layoutPreset={FORM_FIELD_PRESETS.Column} required>
              <InputContainer size='full'>
                <input type='text' value={location} onChange={(e) => setLocation(e.target.value)} id='publish-plan:location' />
              </InputContainer>
            </FormField>
          </VerticalFormRow>
          <TextFormRow className='advisory-text'>
            Please include the area your garden is located in. This can be very broad (e.g. your county, state or country).
          </TextFormRow>
          <VerticalFormRow>
            <FormField label='Description' htmlFor='publish-plan:description' layoutPreset={FORM_FIELD_PRESETS.Column} required>
              <InputContainer size='full'>
                <textarea rows={6} value={description} onChange={(e) => setDescription(e.target.value)} id='publish-plan:description' />
              </InputContainer>
            </FormField>
          </VerticalFormRow>
          <TextFormRow className='advisory-text'>Please add a description of your garden to help people understand your plan.</TextFormRow>
        </ModalPaneSectionContent>
      </ModalPaneSection>
    </ModalPane>
  );
};

export default PublishPlanPageInformation;
