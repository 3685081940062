import React, { ReactNode, useContext } from 'react';

import { SidebarContext } from './sidebar-context';

import styles from './sidebar-styles.module.css';

interface iProps {
  className?: string;
  children?: ReactNode;
}

const SidebarHeader = ({ className, children }: iProps): JSX.Element => {
  const { headerContent, minimised } = useContext(SidebarContext);

  const classNames: string[] = [styles.header];
  if (!minimised) {
    classNames.push(styles.expanded);
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      {!minimised ? headerContent : null}
      {children}
    </div>
  );
};

export default SidebarHeader;
