import Plant from '@gi/plant';

import { SimulatedPlant } from '../simulation/simulated-plant';
import CanvasLayers from './canvas-layers';
import PlantNode from './nodes/plant/plant-node';
import { SimulatedGardenItemEvent } from '../simulation/simulated-garden-item';
import PlantLabelNode from './nodes/plant/plant-label-node';
import CanvasItem from './canvas-item';
import SFGPlantNode from './nodes/plant/sfg-plant-node';

class CanvasPlant extends CanvasItem {
  readonly #plantNode: PlantNode | SFGPlantNode;
  readonly #plantLabelNode: PlantLabelNode;
  readonly #simulatedPlant: SimulatedPlant;
  readonly #canvasLayers: CanvasLayers;

  constructor(simulatedPlant: SimulatedPlant, canvasLayers: CanvasLayers) {
    super();

    if (simulatedPlant.isSquareFoot) {
      this.#plantNode = new SFGPlantNode(simulatedPlant.id, simulatedPlant.plant, {
        position: { ...simulatedPlant.rowStart },
        plantCount: simulatedPlant.plantCount.total,
        rotation: simulatedPlant.rotation,
        variety: simulatedPlant.variety,
        visible: true,
        locked: simulatedPlant.locked,
        zIndex: simulatedPlant.zIndex,
      });
    } else {
      this.#plantNode = new PlantNode(simulatedPlant.id, simulatedPlant.plant, {
        rowStart: { ...simulatedPlant.rowStart },
        rowEnd: { ...simulatedPlant.rowEnd },
        width: simulatedPlant.width,
        height: simulatedPlant.height,
        rotation: simulatedPlant.rotation,
        spacing: simulatedPlant.spacings.spacing,
        inRowSpacing: simulatedPlant.spacings.inRowSpacing,
        rowSpacing: simulatedPlant.spacings.rowSpacing,
        plantCount: { ...simulatedPlant.plantCount },
        variety: simulatedPlant.variety,
        visible: true,
        locked: simulatedPlant.locked,
        zIndex: simulatedPlant.zIndex,
      });
    }

    this.#plantLabelNode = new PlantLabelNode(simulatedPlant.id, {
      plantCenter: Plant.calculateCenterOfPlant(simulatedPlant.rowStart, simulatedPlant.rowEnd, simulatedPlant.height),
      labelOffset: simulatedPlant.labelOffset,
      labelText: simulatedPlant.labelText,
      showLabel: simulatedPlant.showLabel,
      visible: true,
      locked: simulatedPlant.locked,
      zIndex: simulatedPlant.zIndex,
    });

    this.nodes.push(this.#plantNode);
    this.nodes.push(this.#plantLabelNode);

    this.#canvasLayers = canvasLayers;
    this.#simulatedPlant = simulatedPlant;

    // this.plantNode.on('INPUT_UPDATE', ({ rowStart, rowEnd, height }) => {
    //   // Update simulated plant
    //   this.simulatedPlant.setPositions(rowStart, rowEnd, height);
    // });

    this.#simulatedPlant.on(SimulatedGardenItemEvent.DidUpdate, this.#onSimulatedPlantUpdate);

    // SimulatedPlant.on('DELETE', () => {
    //   // Delete plant node ?  that might not happen here..
    // });

    this.#canvasLayers.plantsLayer.addChildren(this.#plantNode);
    this.#canvasLayers.plantLabelLayer.addChildren(this.#plantLabelNode);

    this.registerEffectHandler(this.#simulatedPlant, this.#plantNode);
  }

  updateVisibility(month: number | null) {
    const visible = this.#simulatedPlant.isVisibleDuringMonth(month);
    this.#plantNode.state.values.visible = visible;
    this.#plantLabelNode.state.values.visible = visible;
  }

  #onSimulatedPlantUpdate = () => {
    this.#plantLabelNode.setPlantCenter(Plant.calculateCenterOfPlant(this.#simulatedPlant.rowStart, this.#simulatedPlant.rowEnd, this.#simulatedPlant.height));
    this.#plantLabelNode.setLabelText(this.#simulatedPlant.labelText);
    this.#plantLabelNode.setLabelOffset(this.#simulatedPlant.labelOffset);
    this.#plantLabelNode.setShowLabel(this.#simulatedPlant.showLabel);
    this.#plantLabelNode.setLocked(this.#simulatedPlant.locked);
    this.#plantLabelNode.setZIndex(this.#simulatedPlant.zIndex);

    if (this.#plantNode instanceof PlantNode) {
      this.#plantNode.setPositions(
        this.#simulatedPlant.rowStart,
        this.#simulatedPlant.rowEnd,
        this.#simulatedPlant.width,
        this.#simulatedPlant.height,
        this.#simulatedPlant.rotation
      );
      this.#plantNode.setSpacings(this.#simulatedPlant.spacings.spacing, this.#simulatedPlant.spacings.inRowSpacing, this.#simulatedPlant.spacings.rowSpacing);
      this.#plantNode.setPlantCount(this.#simulatedPlant.plantCount);
      this.#plantNode.setVariety(this.#simulatedPlant.variety);
      this.#plantNode.setLocked(this.#simulatedPlant.locked);
      this.#plantNode.setZIndex(this.#simulatedPlant.zIndex);
    } else {
      this.#plantNode.setPosition(this.#simulatedPlant.rowStart, this.#simulatedPlant.rotation);
      this.#plantNode.setPlantCount(this.#simulatedPlant.plantCount.total);
      this.#plantNode.setVariety(this.#simulatedPlant.variety);
      this.#plantNode.setLocked(this.#simulatedPlant.locked);
      this.#plantNode.setZIndex(this.#simulatedPlant.zIndex);
    }
  };
}

export default CanvasPlant;
