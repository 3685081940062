import { MD5 } from 'crypto-js';

import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';
import { preAuthTicketFromLogin } from '@gi/pre-auth-ticket';

import type { User } from '../user';
import { userFromAPI, userToAPI } from '../parsers/user-parser';
import { attachGetUserErrorClientMessage, attachPutUserErrorClientMessage } from './user-service-errors';
import { UserFromAPI } from '../parsers/user-api-types';

/**
 * Provides methods for saving and loading users from the API
 *
 * @class UserService
 */
class UserService {
  endpoint: string;

  /**
   *Creates an instance of UserService.
   */
  constructor({ endpoint }: { endpoint: string }) {
    this.endpoint = endpoint;
    console.debug(`Created user service with endpoint ${this.endpoint}`);
  }

  getPreAuthUser(email: string, password: string) {
    const preAuthTicket = preAuthTicketFromLogin(email, password);
    const params = {
      e: email,
      p: MD5(password).toString(),
      t: preAuthTicket.toString(),
    };

    const endPoint = '/users/';
    const identifier = encodeURIComponent(email.replace(/\./g, '!').replace(/\+/g, ','));
    const url = `${this.endpoint}${endPoint}${identifier}`;

    return networkService
      .get<UserFromAPI>(url, params, RequestAuthMode.None)
      .then((response) => userFromAPI(response.body))
      .catch((requestError) => {
        attachGetUserErrorClientMessage(requestError);
        throw requestError;
      });
  }

  getPostAuthUser(userID: number, postAuthTicket: string) {
    const params = {
      u: userID,
      t: postAuthTicket,
    };

    const endPoint = '/users/';
    const identifier = userID;
    const url = `${this.endpoint}${endPoint}${identifier}`;

    return networkService
      .get<UserFromAPI>(url, params, RequestAuthMode.None)
      .then((response) => userFromAPI(response.body))
      .catch(networkErrorReporter('GET', 'user'))
      .catch((requestError) => {
        attachGetUserErrorClientMessage(requestError);
        throw requestError;
      });
  }

  saveUser(user: User) {
    const endPoint = '/users/';
    const identifier = user.ID;
    const url = `${this.endpoint}${endPoint}${identifier}`;

    return networkService
      .put<UserFromAPI>(url, {}, userToAPI(user))
      .then((response) => {
        const newUser = userFromAPI(response.body);
        /**
         * 2024/04/04 - Temp bandaid fix for the server returning null if it encounters an error when returning userVarieties.
         * The server returns null for this field if it hits an error, rather than failing the request.
         * For now, use the previous set of varieties and assume the response was bad.
         */
        if (response.body.userVarieties === null) {
          newUser.plantVarieties = user.plantVarieties;
        }
        return newUser;
      })
      .catch(networkErrorReporter('PUT', 'user'))
      .catch((requestError) => {
        attachPutUserErrorClientMessage(requestError);
        throw requestError;
      });
  }
}

export default UserService;
