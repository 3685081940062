import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Reminder, ReminderNetworkActionCreators } from '@gi/reminders';
import { FormLayout } from '@gi/form-responsive';
import { RequestSelectors } from '@gi/react-requests';
import { RequestStatus, RequestsUtils } from '@gi/request';

import './dashboard-reminder-tile.scss';

interface iControlButtonProps {
  loading: boolean;
  onClick: () => void;
  text: string;
}

const ControlButton = ({ loading, onClick, text }: iControlButtonProps): JSX.Element => {
  if (loading) {
    return (
      <button type='button' className='dashboard-button reminder-button' onClick={() => {}} disabled aria-label={`Loading: ${text}`}>
        <i className='animate-pulse icon-spinner' />
      </button>
    );
  }

  return (
    <button type='button' className='dashboard-button reminder-button' onClick={onClick}>
      {text}
    </button>
  );
};

interface iProps {
  restore: () => void;
  dismiss: () => void;
  edit: () => void;
  reminder: Reminder;
}
const DashboardReminderTile = ({ restore, dismiss, edit, reminder }: iProps): JSX.Element => {
  const className = `dashboard-reminder-tile ${reminder.dismissed ? 'dashboard-reminder-tile-dismissed' : ''}`;
  const requests = useSelector(RequestSelectors.getRequests);

  const loading =
    RequestsUtils.getStatus(requests, ReminderNetworkActionCreators.ReminderRequestNames.UpdateReminder(reminder.ID)) === RequestStatus.IN_PROGRESS;

  const isOverdue = useMemo<boolean>(() => {
    if (reminder.dismissed) {
      return false;
    }
    const date = moment(reminder.dateYMD);
    const today = moment().startOf('day');
    return date.isBefore(today);
  }, [reminder]);

  const noteComponent = useMemo<JSX.Element | null>(() => {
    if (reminder.dismissed) {
      return <span className='dismissed-note'>dismissed</span>;
    }
    if (isOverdue) {
      return (
        <span className='overdue-note'>
          <i className='icon-attention-circled' />
          overdue
        </span>
      );
    }
    return null;
  }, [reminder, isOverdue]);

  return (
    <div className={className}>
      <FormLayout desktopLayout={{ layout: 'left-right', size: 'full' }} mobileLayout={{ layout: 'column', gap: 3 }} className='dashboard-reminders-container'>
        <div className='dashboard-reminder-content'>
          <pre className='dashboard-reminder-text'>{reminder.note}</pre>
        </div>
        <div className='dashboard-reminder-controls'>
          <button type='button' className='dashboard-button reminder-button' onClick={edit}>
            Edit
          </button>
          <ControlButton loading={loading} onClick={reminder.dismissed ? restore : dismiss} text={reminder.dismissed ? 'Restore' : 'Dismiss'} />
        </div>
        {noteComponent}
      </FormLayout>
    </div>
  );
};

export default DashboardReminderTile;
