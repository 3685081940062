import React, { useEffect, useRef, useState } from 'react';

import { GalleryItem } from './gallery-types';
import PlaceholderBackground from '../placeholder/placeholder-background';

import styles from './gallery.module.css';

interface iProps {
  item: GalleryItem;
  active: boolean;
  onClick: () => void;
}

const GalleryThumbnail = ({ item, active, onClick }: iProps): JSX.Element => {
  const isFirstRender = useRef<boolean>(true);
  const ref = useRef<HTMLButtonElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (active && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'nearest' });
    }
  }, [active]);

  return (
    <button className={`${styles.galleryThumbnail} ${active ? styles.active : ''}`} type='button' ref={ref} onClick={onClick}>
      <PlaceholderBackground loaded={loaded} className={!loaded ? styles.galleryThumbnailLoading : ''}>
        <img onLoad={() => setLoaded(true)} src={item.thumbnailSrc} alt={item.caption ?? ''} />
      </PlaceholderBackground>
    </button>
  );
};

export default GalleryThumbnail;
