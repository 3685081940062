import Ajv, { JSONSchemaType, ValidateFunction } from 'ajv';
import { DeviceDisplayMode } from '@gi/constants';
import { APITutorials, Tutorial, TutorialStep } from '@gi/tutorial';
import { HTMLStepContent, ImageStepContent, TextStepContent, TutorialAttachment, VideoStepContent } from '@gi/tutorial/source/tutorial';
import { TutorialParseError } from './tutorial-editor-types';

type ObjectValidator = (object: any) => true | TutorialParseError[];

function createObjectValidator<T>(validator: ValidateFunction<T>): ObjectValidator {
  return (object: any): true | TutorialParseError[] => {
    const valid = validator(object);

    if (valid) {
      return true;
    }

    if (Array.isArray(validator.errors)) {
      return validator.errors.map(({ message, instancePath }) => {
        const err: TutorialParseError = {
          message: message ? message : 'Unknown error',
        };

        if (instancePath) {
          err.path = instancePath;
        }

        return err;
      });
    }

    return [];
  };
}

function createStringValidator(objectValidator: ObjectValidator): (objectStr: string) => true | TutorialParseError[] {
  return (objectStr: string) => {
    let object: unknown;
    try {
      object = JSON.parse(objectStr);
    } catch (e) {
      return [
        {
          message: 'Invalid JSON',
        },
      ];
    }

    return objectValidator(object);
  };
}

const ajv = new Ajv();

const TutorialContentHTMLSchema: JSONSchemaType<HTMLStepContent> = {
  type: 'object',
  properties: {
    html: { type: 'string' },
    width: { type: 'number', nullable: true },
    ref: { type: 'string', nullable: true },
  },
  required: ['html'],
  additionalProperties: false,
};

const TutorialContentTextSchema: JSONSchemaType<TextStepContent> = {
  type: 'object',
  properties: {
    text: { type: 'string' },
    width: { type: 'number', nullable: true },
    ref: { type: 'string', nullable: true },
  },
  required: ['text'],
  additionalProperties: false,
};

const TutorialContentVideoSchema: JSONSchemaType<VideoStepContent> = {
  type: 'object',
  properties: {
    video: { type: 'string' },
    autoplay: { type: 'boolean', nullable: true },
    mimeType: { type: 'string', nullable: true },
    width: { type: 'number', nullable: true },
    ref: { type: 'string', nullable: true },
  },
  required: ['video'],
  additionalProperties: false,
};

const TutorialContentImageSchema: JSONSchemaType<ImageStepContent> = {
  type: 'object',
  properties: {
    image: { type: 'string' },
    alt: { type: 'string', nullable: true },
    width: { type: 'number', nullable: true },
    ref: { type: 'string', nullable: true },
  },
  required: ['image'],
  additionalProperties: false,
};

const TutorialAttachmentSchema: JSONSchemaType<TutorialAttachment> = {
  type: 'object',
  properties: {
    locator: { type: 'string' },
    placement: { type: 'string' },
  },
  required: ['locator', 'placement'],
  additionalProperties: false,
};

const TutorialStepSchema: JSONSchemaType<TutorialStep> = {
  type: 'object',
  properties: {
    attachment: { ...TutorialAttachmentSchema, nullable: true },
    content: {
      type: 'array',
      items: {
        anyOf: [{ type: 'string' }, TutorialContentHTMLSchema, TutorialContentTextSchema, TutorialContentVideoSchema, TutorialContentImageSchema],
      },
    },
    width: { type: 'number', nullable: true },
  },
  required: ['content'],
  additionalProperties: false,
};

const TutorialStepsSchema: JSONSchemaType<TutorialStep[]> = {
  type: 'array',
  items: TutorialStepSchema,
};

const TutorialsSchema: JSONSchemaType<Tutorial> = {
  type: 'object',
  properties: {
    uuid: { type: 'string' },
    name: { type: 'string' },
    displayMode: { type: 'string', enum: [DeviceDisplayMode.DESKTOP, DeviceDisplayMode.MOBILE], nullable: true },
    steps: TutorialStepsSchema,
  },
  required: ['uuid', 'name', 'steps'],
  additionalProperties: false,
};

const APITutorialsSchema: JSONSchemaType<APITutorials> = {
  type: 'array',
  items: TutorialsSchema,
};

const _validateTutorialsObject = ajv.compile(APITutorialsSchema);
const _validateTutorialObject = ajv.compile(TutorialsSchema);

export const validateTutorialsObject = createObjectValidator(_validateTutorialsObject);
export const validateTutorialObject = createObjectValidator(_validateTutorialObject);

export const validateTutorialsString = createStringValidator(validateTutorialsObject);
export const validateTutorialString = createStringValidator(validateTutorialObject);
