import React, { ReactNode } from 'react';

import './form-tabs.scss';

interface iProps {
  children: ReactNode;
}

const FormTabs = ({ children }: iProps): JSX.Element => {
  return <div className='rforms-tabs'>{children}</div>;
};

export default FormTabs;
