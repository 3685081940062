import { Application, NavigationActionCreators } from '@gi/garden-platform-navigation';
import Collection from '@gi/collection';
import GardenObject from '@gi/garden-object';
import { SidebarActionCreators, SidebarPosition } from '@gi/garden-platform-sidebar';
import Plan, { FollowOnPlanOptions } from '@gi/plan';
import Plant from '@gi/plant';

import { CanvasActionCreators } from '@gi/react-garden-canvas';
import TemplatePlan, { createPlanFromTemplate } from '@gi/template-plan';
import { ActionCreator } from 'redux';
import { batchActions } from 'redux-batched-actions';

export const createPlan = (plan: Plan, closeNewPlanModal: ActionCreator<any>) => {
  return (dispatch) => {
    dispatch(CanvasActionCreators.createPlan(plan)).then(() => {
      dispatch(
        batchActions([
          NavigationActionCreators.navigateTo({ application: Application.GardenPlanner }),
          SidebarActionCreators.setSidebarPosition(SidebarPosition.Minimised),
          closeNewPlanModal(),
        ])
      );
    });
  };
};

export const createFollowOnPlan = (
  newPlan: Plan,
  previousPlanID: number,
  plants: Collection<Plant>,
  gardenObjects: Collection<GardenObject>,
  options: FollowOnPlanOptions,
  closeNewPlanModal: () => void
) => {
  return (dispatch) => {
    dispatch(CanvasActionCreators.createFollowOnPlan(newPlan, previousPlanID, plants, gardenObjects, options)).then(() => {
      dispatch(
        batchActions([
          NavigationActionCreators.navigateTo({ application: Application.GardenPlanner }),
          SidebarActionCreators.setSidebarPosition(SidebarPosition.Minimised),
          closeNewPlanModal(),
        ])
      );
    });
  };
};

export const createPlanFromTemplatePlan = (
  userID: number,
  planName: string,
  planYear: number,
  metric: boolean,
  templatePlan: TemplatePlan,
  closeNewPlanModal: () => void,
  userPlants: Collection<Plant>,
  userGardenObjects: Collection<GardenObject>
) => {
  return (dispatch) => {
    const newPlan = createPlanFromTemplate(userID, planName, planYear, metric, templatePlan, userPlants, userGardenObjects);
    dispatch(CanvasActionCreators.createPlan(newPlan)).then(() => {
      dispatch(
        batchActions([
          NavigationActionCreators.navigateTo({ application: Application.GardenPlanner }),
          SidebarActionCreators.setSidebarPosition(SidebarPosition.Minimised),
          closeNewPlanModal(),
        ])
      );
    });
  };
};
