import React from 'react';

import networkConfig from '@gi/config/network-config';
import { Swatch, SwatchTypes } from '@gi/palette';

import './display-swatch.scss';

interface iProps {
  swatch: null | Swatch;
}

const SwatchDisplay = ({ swatch }: iProps): JSX.Element => {
  const styles: React.CSSProperties = {};

  if (swatch === null) {
    styles.backgroundColor = 'transparent';
  } else if (swatch.type === SwatchTypes.COLOR) {
    styles.backgroundColor = `#${swatch.value}`;
  } else {
    styles.backgroundImage = `url(${networkConfig.assetBaseURL}${swatch.src})`;
  }

  return <div className='swatch-display' style={styles} />;
};

export default SwatchDisplay;
