import countryArtifactsData from './country-artifacts-data.json';

export type CountryArtifactData = {
  countryCode: string;
  pestArtifactCode: string;
  defaultArtifactCode: string;
  useRegionalPlantData: boolean;
  availableArtifactCodes: string[];
};

const data: CountryArtifactData[] = countryArtifactsData.countries;

export default data;
