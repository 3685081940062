import React, { useContext } from 'react';

import { Accordion } from '@gi/accordion';

import ObjectiveGroupDisplay from './objective-group-display';
import { ObjectivesContext } from '../objectives-context';

import styles from './objectives-display.module.css';

interface iProps {
  groupIds: string[];
  minimised?: boolean;
  onLaunchHelp?: () => void;
}

/**
 * Displays a list of objective groups, with objectives listed underneath each group
 */
const ObjectiveGroupListDisplay = ({ groupIds, minimised = false, onLaunchHelp }: iProps): JSX.Element => {
  const { expandedGroups, setExpandedGroups } = useContext(ObjectivesContext);

  return (
    <Accordion className={styles.objectivesOverview} animationDuration={500} unstyled expandedIds={expandedGroups} setExpandedIds={setExpandedGroups}>
      {groupIds.map((groupId) => (
        <ObjectiveGroupDisplay groupId={groupId} minimised={minimised} onLaunchHelp={onLaunchHelp} key={groupId} />
      ))}
    </Accordion>
  );
};

export default ObjectiveGroupListDisplay;
