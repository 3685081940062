import { Country } from './country';

class CountryCollection {
  countries: Record<string, Country>;
  countryList: Country[];

  constructor() {
    this.countries = {};
    this.countryList = [];
  }

  addCountries(countries: Country[]) {
    countries.forEach((country) => this.add(country));
  }

  add(country: Country) {
    this.countries[country.code] = country;
    this.countryList.push(country);
  }

  has(code: string): boolean {
    if (!this.countries[code]) {
      return false;
    }

    return true;
  }

  get(code: string): Country | null {
    if (!this.has(code)) {
      return null;
    }

    return this.countries[code];
  }

  asArray(): Country[] {
    return this.countryList.slice(0);
  }
}

export default CountryCollection;
