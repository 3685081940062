import React from 'react';

import { DistanceUnits } from '@gi/units';

import { PlantListSummaryGroup } from '../types/plant-list-types';
import PlantListCardHeader from './card/plant-list-card-header';
import PlantListCardCalendar from './card/plant-list-card-calendar';
import PlantListCard from './card/plant-list-card';
import PlantListCardSpacings from './card/plant-list-card-spacings';
import PlantListCardCount from './card/plant-list-card-count';
import PlantListCardCellContainer from './card/plant-list-card-cell-container';
import PlantListCardIndividualDetails from './card/details-table/plant-list-card-individual-details';

interface iProps {
  plantListRowGroup: PlantListSummaryGroup;
  distanceUnits: DistanceUnits;
}

const PlantListByPlantCard = ({ plantListRowGroup, distanceUnits }: iProps): JSX.Element => {
  return (
    <PlantListCard>
      <PlantListCardHeader plant={plantListRowGroup.plant} />
      <PlantListCardCalendar plantingCalendar={plantListRowGroup.plantingCalendar} />
      <PlantListCardCellContainer>
        <PlantListCardSpacings spacings={plantListRowGroup.spacing} countsByType={plantListRowGroup.countsByType} distanceUnits={distanceUnits} />
        <PlantListCardCount count={plantListRowGroup.count} />
      </PlantListCardCellContainer>
      <PlantListCardIndividualDetails areaGroups={plantListRowGroup.areas} showVariety />
    </PlantListCard>
  );
};

export default PlantListByPlantCard;
