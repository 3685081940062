import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { GardenObjectEntry, GardenObjectShoppingEntry, ShoppingListItems } from '@gi/products';
import GardenObject from '@gi/garden-object';
import { ResourceContext } from '@gi/resource-provider';

import GardenObjectEntryDisplay from './garden-objects/garden-object-entry-display';
import ShoppingListItemsList from './shopping-list-items-list';
import { ShoppingActionCreators } from '../slice/shopping-slice';

import styles from './shopping-list-content.module.css';
import ShoppingListButton from './components/buttons/shopping-list-button';

interface iProps {
  currency: string;
  planId: number;
  gardenObjectEntries: ShoppingListItems<GardenObjectEntry>;
}

const GardenObjectShoppingList = ({ currency, gardenObjectEntries, planId }: iProps): JSX.Element => {
  const { getGardenObject } = useContext(ResourceContext);
  const dispatch = useDispatch();

  const entries: [GardenObjectShoppingEntry, GardenObject][] = useMemo(() => {
    return gardenObjectEntries.uuids
      .map<GardenObjectShoppingEntry>((uuid) => gardenObjectEntries.entriesByUuid[uuid])
      .map<[GardenObjectShoppingEntry, GardenObject]>((entry) => {
        const gardenObject = getGardenObject(entry.matchData.code);
        return [entry, gardenObject] as [GardenObjectShoppingEntry, GardenObject];
      })
      .filter(([, gardenObject]) => gardenObject !== null)
      .sort((a, b) => a[1].name.localeCompare(b[1].name));
  }, [gardenObjectEntries]);

  const allInCart = useMemo(() => {
    return entries.every(([entry]) => entry.inCart);
  }, [entries]);

  const setInCart = useCallback(
    (inCart: boolean) => {
      const updatedEntries = entries.map(([entry]) => ({ ...entry, inCart }));
      dispatch(ShoppingActionCreators.setEntries({ planId, entries: updatedEntries }));
    },
    [entries]
  );

  if (gardenObjectEntries.uuids.length === 0) {
    return <div className={styles.highlightedText}>No Available Matched Garden Objects</div>;
  }

  return (
    <div className={styles.shoppingListContents}>
      <div className={styles.shoppingListControls}>
        <ShoppingListButton type='button' onClick={() => setInCart(!allInCart)}>
          {allInCart ? 'Remove all Garden Objects' : 'Add all Garden Objects'}
        </ShoppingListButton>
      </div>
      <ShoppingListItemsList>
        {entries.map(([entry]) => {
          return <GardenObjectEntryDisplay currency={currency} key={entry.uuid} planId={planId} uuid={entry.uuid} />;
        })}
      </ShoppingListItemsList>
    </div>
  );
};

export default GardenObjectShoppingList;
