import React from 'react';

import { Tooltip, TooltipContent, TooltipKey, TooltipTrigger } from '@gi/tooltip';

import './sidebar-icon-container.scss';

interface iProps {
  tooltipText?: string;
  showTooltip: boolean;
  tooltipKey: TooltipKey;
  className?: string;
  children: React.ReactNode;
}

const SidebarIconContainer = ({ className = '', children, showTooltip, tooltipText, tooltipKey }: iProps) => {
  return (
    <Tooltip placement='right' disabled={!showTooltip}>
      <TooltipTrigger>
        <div className={`${className} sidebar-icon-container`}>{children}</div>
      </TooltipTrigger>
      <TooltipContent id={tooltipKey}>{tooltipText}</TooltipContent>
    </Tooltip>
  );
};

export default SidebarIconContainer;
