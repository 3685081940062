import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HelpModal } from '@gi/modal';
import FormField, { FORM_FIELD_PRESETS, createFormValues } from '@gi/form-responsive';
import { LocalSettingsActionCreators, LocalSettingsSelectors } from '@gi/local-settings';

import ItemLockingHelp from './sections/item-locking-help';

type ItemLockingHelpModalFormState = {
  hideItemLockingHelpNotifications: boolean;
};

interface iProps {
  closeModal: () => void;
}

const ItemLockingHelpModal = ({ closeModal }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const hideItemLockingHelpNotifications = useSelector(LocalSettingsSelectors.getHideItemLockingHelpNotifications);

  const [formValues, setFormValues] = useState(
    createFormValues<ItemLockingHelpModalFormState>({
      hideItemLockingHelpNotifications: { value: hideItemLockingHelpNotifications },
    })
  );

  const handleClose = useCallback(() => {
    if (formValues.values.hideItemLockingHelpNotifications !== hideItemLockingHelpNotifications) {
      dispatch(LocalSettingsActionCreators.setHideItemLockingHelpNotifications(formValues.values.hideItemLockingHelpNotifications));
    }
    closeModal();
  }, [closeModal, formValues, hideItemLockingHelpNotifications]);

  return (
    <HelpModal headerText='Locking Items' closeModal={handleClose} style={{ maxWidth: 520 }}>
      <ItemLockingHelp hideHeader />
      <FormField htmlFor='item-lock-help:hide-notification' label="Don't show again" layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
        <input
          type='checkbox'
          id='item-lock-help:hide-notification'
          checked={formValues.values.hideItemLockingHelpNotifications}
          onChange={(e) =>
            setFormValues(
              formValues.setValue('hideItemLockingHelpNotifications', {
                value: e.target.checked,
              })
            )
          }
        />
      </FormField>
    </HelpModal>
  );
};

export default ItemLockingHelpModal;
