import {
  AssetType,
  AssetsComponent,
  AssetsComponentEvents,
  NodeEvent,
  OutlineComponent,
  PathSpriteComponent,
  ShapeCollisionCheckFunctions,
} from '@gi/core-renderer';
import { Geometry } from '@gi/math';
import { SpriteSourceType, assertSpriteType } from '@gi/sprite-source';
import GardenObject from '@gi/garden-object';
import { GardenObjectType } from '@gi/constants';

import GardenObjectNode, { GardenObjectNodeState } from './garden-object-node';
import { CachedPathOutline } from '../outline-utils';

class PathGardenObjectNode extends GardenObjectNode {
  type = 'PathGardenObjectNode';

  #sprite: PathSpriteComponent;
  #texture: AssetsComponent<AssetType.TEXTURE>;

  #cachedOutline?: CachedPathOutline;

  constructor(id: number, gardenObject: GardenObject, initialState: GardenObjectNodeState['state']) {
    super(id, gardenObject, initialState);

    if (this.gardenObject.shape.type !== GardenObjectType.PATH) {
      throw new Error(`PathGardenObjectNode expects a PATH garden object, given ${this.gardenObject.shape.type}`);
    }

    this.outline.state.values.generateHitbox = true;
    this.outline.state.values.padding = this.gardenObject.shape.sprite.height / 2 + 2;
    this.tooltip.state.values.offset = { x: 0, y: 20 };

    this.state.addWatcher(
      () => {
        this.#update();
      },
      { properties: ['start', 'mid', 'end', 'rotation'] },
      false
    );

    this.shape.state.values.closed = false;

    this.#sprite = this.components.add(
      new PathSpriteComponent({
        start: this.state.values.start,
        end: this.state.values.end,
        control: this.state.values.mid,
      })
    );

    this.state.addUpdater(
      (state) => {
        assertSpriteType(this.gardenObject.shape.sprite, SpriteSourceType.PATH);

        const center = this.getCenter();
        this.#sprite.state.values.start = Geometry.minusPoint(state.values.start, center);
        this.#sprite.state.values.end = Geometry.minusPoint(state.values.end, center);
        this.#sprite.state.values.control = state.values.mid !== null ? Geometry.minusPoint(state.values.mid, center) : null;
        this.#sprite.state.values.segmentSize = this.gardenObject.shape.sprite.width;
        this.#sprite.state.values.thickness = this.gardenObject.shape.sprite.height;
      },
      { properties: ['start', 'mid', 'end'] }
    );

    // Update the outline thickness whenever touch mode or the stroke changes.
    this.state.addUpdater(
      (state) => {
        const touchMode = state.otherStates.settings?.values.touchMode ?? false;
        const padding = touchMode ? OutlineComponent.PADDING_LARGE : OutlineComponent.PADDING_SMALL;

        assertSpriteType(this.gardenObject.shape.sprite, SpriteSourceType.PATH);
        this.outline.state.values.padding = this.gardenObject.shape.sprite.height / 2 + padding;
      },
      {
        otherStates: { settings: { properties: ['touchMode'] } },
      }
    );

    this.#texture = this.components.add(new AssetsComponent(AssetType.TEXTURE));
    this.#texture.eventBus.on(AssetsComponentEvents.Loaded, (asset) => {
      this.#sprite.textures = asset;
    });

    this.shape.collisionCheckFunction = ShapeCollisionCheckFunctions.Path(this.gardenObject.shape.sprite.height / 2);

    this.eventBus.on(NodeEvent.DidBind, this.#didBind);
  }

  #didBind = () => {
    assertSpriteType(this.gardenObject.shape.sprite, SpriteSourceType.PATH);

    this.#texture.loadAssets(this.gardenObject.shape.sprite.names);

    const container = this.#sprite.getContainer();
    this.ownGraphics.addChild(container);
    container.x = 0;
    container.y = 0;

    this.#update();
  };

  #updateShape() {
    const { start, mid, end } = this.state.values;
    assertSpriteType(this.gardenObject.shape.sprite, SpriteSourceType.PATH);
    const segmentSize = this.gardenObject.shape.sprite.width;

    if (!this.#cachedOutline) {
      this.#cachedOutline = new CachedPathOutline(start, end, mid, segmentSize);
      this.shape.setPoints(this.#cachedOutline.path);
    } else if (this.#cachedOutline.update(start, end, mid, segmentSize)) {
      this.shape.setPoints(this.#cachedOutline.path);
    }

    this.outline.state.values.hollow = false;
  }

  #update() {
    this.#updateShape();
  }
}

export default PathGardenObjectNode;
