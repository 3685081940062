import React from 'react';
import PlantingCalendarKeyHarvest from './planting-calendar-key-harvest';
import PlantingCalendarKeyIndoor from './planting-calendar-key-indoor';
import PlantingCalendarKeyOutdoor from './planting-calendar-key-outdoor';

import './planting-calendar-key.scss';

const PlantingCalendarKey = () => {
  return (
    <span className='plant-list-key-group'>
      <PlantingCalendarKeyIndoor />
      <PlantingCalendarKeyOutdoor />
      <PlantingCalendarKeyHarvest />
    </span>
  );
};

export default PlantingCalendarKey;
