import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestSelectors } from '@gi/react-requests';
import * as SessionActionCreators from './session-action-creators';
import * as SessionSelectors from './session-selectors';
import { getTimeUntilExpiry } from './session-utils';

// const MIN_EXPIRY_TIME = 3600; // 1 hour, in seconds
const MIN_EXPIRY_TIME = 6 * 24 * 3600; // 6 days, in seconds
const EXPIRY_CHECK_INTERVAL = 60 * 60 * 1000; // 1 hour, in milliseconds

const SessionRefresher = (): null => {
  const user = useSelector(SessionSelectors.getUser);
  const userSavingOrLoading = useSelector(RequestSelectors.userSaveOrLoad(user ? user.ID : -1));
  const dispatch = useDispatch();

  /**
   * Check the users auth token and reload the user
   * if the time until expiry is close enough to our current time
   *
   * Will not refresh the user if it is currently saving or loading, which should refresh the token anyway
   */
  const expiryCheck = useCallback(() => {
    if (user && !userSavingOrLoading) {
      const timeUntilExpiry = getTimeUntilExpiry(user.authToken);

      if (timeUntilExpiry < MIN_EXPIRY_TIME) {
        // refresh user
        console.debug('Session expired, refreshing user');
        if (!userSavingOrLoading) {
          dispatch(SessionActionCreators.loadUser(user.ID, user.postAuthTicket));
        }
      } else {
        console.debug('Session not expired');
      }
    }
  }, [user?.authToken, userSavingOrLoading]);

  useEffect(() => {
    const callback = () => {
      if (!document.hidden) {
        console.debug('Document visible, checking for auth expiry');
        expiryCheck();
      }
    };

    window.addEventListener('focus', callback, false);
    return () => {
      window.removeEventListener('focus', callback, false);
    };
  }, [expiryCheck]);

  useEffect(() => {
    const onInterval = setInterval(expiryCheck, EXPIRY_CHECK_INTERVAL);

    return () => {
      clearInterval(onInterval);
    };
  }, [expiryCheck]);

  return null;
};

export default SessionRefresher;
