import React from 'react';

import BottomRightOverlayToolbar from './overlay-toolbars/bottom-right-overlay-toolbar';
import TopRightOverlayToolbar from './overlay-toolbars/top-right-overlay-toolbar';

const OverlayToolbars = (): JSX.Element => {
  return (
    <>
      <TopRightOverlayToolbar />
      <BottomRightOverlayToolbar />
    </>
  );
};

export default OverlayToolbars;
