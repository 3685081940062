import React from 'react';
import Modal, {
  ModalPane,
  ModalPaneContent,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalHeaderContent,
  ModalHeaderTitle,
} from '@gi/modal';

interface iProps {
  closeModal: () => void;
  plantCode: string;
}

const ErrorPlantNotFoundModal = ({ closeModal, plantCode }: iProps): JSX.Element => {
  return (
    <Modal closeRequest={closeModal} className='edit-plant-modal'>
      <ModalContent>
        <ModalCloseButton onClick={closeModal} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Error</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane className='edit-plant-notes-edit-pane'>
            <ModalPaneContent>
              <p>Error: Plant not found: {plantCode}</p>
              <p>If this continues to be a problem please contact support</p>
            </ModalPaneContent>
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-primary' onClick={closeModal}>
                Close &amp; Continue
              </button>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ErrorPlantNotFoundModal;
