import React from 'react';
import { getSowingVal, getPlantingVal, getHarvestVal } from '../planting-calendar';
import { PlantingCalendarRow } from '../constants';

import './planting-calendar-input.scss';
import { NumberedPlantingCalendar } from '../planting-calendar-types';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const MonthHalfs = {
  FIRST: 1,
  SECOND: 2,
};

/**
 * Returns the planting calendar value for the given row
 *
 * Sow - 0's row
 * Plant - 10's row
 * Harvest - 100's row
 *
 * @param {PlantingCalendarRow} type
 * @param {number} monthValue
 * @returns {number}
 * @throws {Error} if an invalid PlantingCalendarRow type is passed
 */
const getValue = (type: PlantingCalendarRow, monthValue: number): number => {
  if (type === PlantingCalendarRow.SOW) {
    return getSowingVal(monthValue);
  }

  if (type === PlantingCalendarRow.PLANT) {
    return getPlantingVal(monthValue);
  }

  if (type === PlantingCalendarRow.HARVEST) {
    return getHarvestVal(monthValue);
  }

  throw new Error('Invalid planting calendar row type');
};

/**
 * Returns a new planting calendar month value when one of the month halves is toggled
 *
 * @param {PlantingCalendarRow} type
 * @param {number} originalMonthValue
 * @param {number} monthHalf
 * @returns {number}
 * @throws {Error} if an invalid PlantingCalendarRow type is passed
 */
const getUpdatedValue = (type: PlantingCalendarRow, originalMonthValue: number, monthHalf: number): number => {
  if (type === PlantingCalendarRow.SOW) {
    const orig = getSowingVal(originalMonthValue);
    const newVal = (orig ^ monthHalf) % 4; // eslint-disable-line
    return originalMonthValue - orig + newVal;
  }

  if (type === PlantingCalendarRow.PLANT) {
    const orig = getPlantingVal(originalMonthValue);
    const newVal = ((orig ^ monthHalf) % 4) * 10; // eslint-disable-line
    return originalMonthValue - orig * 10 + newVal;
  }

  if (type === PlantingCalendarRow.HARVEST) {
    const orig = getHarvestVal(originalMonthValue);
    const newVal = ((orig ^ monthHalf) % 4) * 100; // eslint-disable-line
    return originalMonthValue - orig * 100 + newVal;
  }

  throw new Error('Invalid planting calendar row type');
};

interface iInputMonthRowProps {
  monthIndex: number;
  monthValue: number;
  type: PlantingCalendarRow;
  onMonthValueChange: (monthIndex: number, newValue: number) => void;
  disabled: boolean;
}

const InputMonthRow = ({ monthIndex, monthValue, type, onMonthValueChange, disabled }: iInputMonthRowProps): JSX.Element => {
  const value = getValue(type, monthValue);
  const firstHalfActive = value === 1 || value === 3;
  const secondHalfActive = value === 2 || value === 3;

  const onLeftSideClick = () => {
    onMonthValueChange(monthIndex, getUpdatedValue(type, monthValue, MonthHalfs.FIRST));
  };

  const onRightSideClick = () => {
    onMonthValueChange(monthIndex, getUpdatedValue(type, monthValue, MonthHalfs.SECOND));
  };

  return (
    <div className='input-month'>
      <button
        type='button'
        aria-label='First half month'
        disabled={disabled}
        className={`input-month-button input-month-button-${type} ${firstHalfActive ? 'active' : ''}`}
        onClick={onLeftSideClick}
      />
      <button
        type='button'
        aria-label='Second half month'
        disabled={disabled}
        className={`input-month-button input-month-button-${type} ${secondHalfActive ? 'active' : ''}`}
        onClick={onRightSideClick}
      />
    </div>
  );
};

interface iInputMonthProps {
  index: number;
  monthValue: number;
  disabled: boolean;
  onMonthValueChange: (monthIndex: number, newValue: number) => void;
}

const InputMonth = ({ index, monthValue, disabled, onMonthValueChange }: iInputMonthProps): JSX.Element => {
  return (
    <>
      <div className='month-name'>{monthNames[index]}</div>
      <div className='planting-times-month'>
        <InputMonthRow monthValue={monthValue} type={PlantingCalendarRow.SOW} monthIndex={index} onMonthValueChange={onMonthValueChange} disabled={disabled} />
      </div>
      <div className='planting-times-month'>
        <InputMonthRow
          monthValue={monthValue}
          type={PlantingCalendarRow.PLANT}
          monthIndex={index}
          onMonthValueChange={onMonthValueChange}
          disabled={disabled}
        />
      </div>
      <div className='planting-times-month'>
        <InputMonthRow
          monthValue={monthValue}
          type={PlantingCalendarRow.HARVEST}
          monthIndex={index}
          onMonthValueChange={onMonthValueChange}
          disabled={disabled}
        />
      </div>
    </>
  );
};

interface iProps {
  plantingCalendarNumbers: NumberedPlantingCalendar;
  northernHemisphere?: boolean;
  onChange: (plantingCalendarNumbers: NumberedPlantingCalendar) => void;
  disabled?: boolean;
}

const PlantingCalendarInput = ({ plantingCalendarNumbers, northernHemisphere = true, onChange, disabled = false }: iProps): JSX.Element => {
  const onMonthValueChange = (monthIndex: number, newValue: number): void => {
    const newValues: Mutable<NumberedPlantingCalendar> = [...plantingCalendarNumbers];

    if (northernHemisphere) {
      newValues[monthIndex] = newValue;
    } else {
      newValues[(monthIndex + 6) % 12] = newValue;
    }

    onChange(newValues);
  };

  const columns: JSX.Element[] = [];

  if (northernHemisphere) {
    for (let i = 0; i < 12; i++) {
      columns[i] = <InputMonth key={i} index={i} monthValue={plantingCalendarNumbers[i]} disabled={disabled} onMonthValueChange={onMonthValueChange} />;
    }
  } else {
    for (let i = 0; i < 12; i++) {
      const j = (i + 6) % 12;
      columns[i] = <InputMonth key={j} index={j} monthValue={plantingCalendarNumbers[i]} disabled={disabled} onMonthValueChange={onMonthValueChange} />;
    }
  }

  return (
    <div className={`planting-times-input-calendar ${disabled ? 'disabled' : ''}`}>
      <div />
      <div className='planting-times-row-title'>Sow</div>
      <div className='planting-times-row-title'>Plant</div>
      <div className='planting-times-row-title'>Harvest</div>
      {columns}
    </div>
  );
};

export default PlantingCalendarInput;
