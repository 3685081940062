import React from 'react';

import GuruSpinner from '../../components/guru-spinner/guru-spinner';
import HeaderContainer from './header-container';
import HeaderTitle from './header-title';

import styles from './header.module.css';

interface iProps {
  className?: string;
  children?: React.ReactNode;
}

const EmptyLoadingHeader = ({ className = '', children = null }: iProps) => {
  return (
    <HeaderContainer className={className}>
      <HeaderTitle>
        <GuruSpinner className={styles.headerSpinner} />
      </HeaderTitle>
      {children}
    </HeaderContainer>
  );
};

export default EmptyLoadingHeader;
