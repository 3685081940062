import { CanvasActionTypes } from '@gi/react-garden-canvas';
import ModalActionTypes from './modal-action-types';
import ModalTypes from './modal-types';

/**
 * @typedef ModalReducerState
 * @type {object}
 * @property {ModalType|null} activeModal
 * @property {string|null} plantInfoModalPlant
 * @property {string|null} imageDisplayModalImage
 * @property {string|null} gardenObjectInfoModalGardenObject
 * @property {object|null} editItemData
 */

/**
 * Initial state for the modal reducer
 *
 * @private
 * @readonly
 * @type {ModalReducerState}
 */
const DEFAULT_STATE = {
  newPlanModalOpen: false,
  newPlanModalActivePlan: null, // Plan ID from which the new plan modal was opened, used to pre-fill the 'create follow on plan' plan selection
  activeModal: null,
  plantInfoModalPlant: null,
  plantInfoModalTab: null,
  imageDisplayModalImage: null,
  gardenObjectInfoModalGardenObject: null,
  editItemData: null,
};

/**
 * Reducer function for opening the edit item modal
 *
 * @param {ModalReducerState} state
 * @param {EditItemAction} action
 */
const editItem = (state, action) => {
  return {
    ...state,
    activeModal: ModalTypes.EDIT_ITEM,
    editItemData: {
      itemID: action.itemID,
      itemType: action.itemType,
      planID: action.planID,
    },
  };
};

/**
 * Reducer function which closes all modals and sets all modal meta data to null
 *
 * @param {ModalReducerState} state
 */
const closeModal = (state) => {
  return {
    ...state,
    activeModal: null,
    editItemData: null,
  };
};

/**
 * Reducer function which closes the delete plan modal once a plan has been successfully deleted
 *
 * @param {ModalReducerState} state
 */
const deletePlanSuccess = (state) => {
  if (state.activeModal === ModalTypes.DELETE_PLAN) {
    return closeModal(state);
  }

  return state;
};

/**
 * Reducer function which closes the create plan modal once a plan has been successfully created
 *
 * @param {ModalReducerState} state
 */
const createPlanSuccess = (state) => {
  if (state.activeModal === ModalTypes.CREATE_PLAN) {
    return closeModal(state);
  }

  return state;
};

/**
 * Main reducer for handling actions which affect modal state
 *
 * @param {ModalReducerState} state
 * @param {object} action
 */
const modalReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ModalActionTypes.OPEN_GARDEN_OBJECT_INFO_MODAL:
      return {
        ...state,
        gardenObjectInfoModalGardenObject: action.gardenObject,
      };
    case ModalActionTypes.CLOSE_GARDEN_OBJECT_INFO_MODAL:
      return {
        ...state,
        gardenObjectInfoModalGardenObject: null,
      };
    case ModalActionTypes.OPEN_PLANT_INFO_MODAL:
      return {
        ...state,
        plantInfoModalPlant: action.plant,
        plantInfoModalTab: action.tab,
      };
    case ModalActionTypes.CLOSE_PLANT_INFO_MODAL:
      return {
        ...state,
        plantInfoModalPlant: null,
      };
    case ModalActionTypes.OPEN_MODAL:
      return {
        ...state,
        activeModal: action.modalType,
      };
    case ModalActionTypes.CLOSE_MODAL:
      return closeModal(state);
    case CanvasActionTypes.DELETE_PLAN_SUCCESS:
      return deletePlanSuccess(state);
    case CanvasActionTypes.CREATE_PLAN_SUCCESS:
      return createPlanSuccess(state);
    case CanvasActionTypes.EDIT_ITEM:
      return editItem(state, action);
    case ModalActionTypes.OPEN_NEW_PLAN_MODAL:
      return {
        ...state,
        newPlanModalOpen: true,
        newPlanModalActivePlan: action.newPlanModalActivePlan,
        activeModal: null,
      };
    case ModalActionTypes.CLOSE_NEW_PLAN_MODAL:
      return {
        ...state,
        newPlanModalOpen: false,
        newPlanModalActivePlan: null,
      };
    default:
      return state;
  }
};

export default modalReducer;
