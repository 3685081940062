import React from 'react';
import { useDispatch } from 'react-redux';

import Plant from '@gi/plant';
import { PlantInformationActionCreators } from '@gi/plant-information-modal';

import './information-cell.scss';

interface iProps {
  plant: Plant;
}

const InformationCell = ({ plant }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();

  return (
    <td className='no-print information-td center-align-cell top-cell'>
      <button
        type='button'
        className='button row-button button-borderless'
        onClick={() => {
          dispatch(PlantInformationActionCreators.openPlantInformationModal({ plant }));
        }}
      >
        <i className='icon-info-book' />
      </button>
    </td>
  );
};

export default InformationCell;
