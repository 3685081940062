import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ObjectivesSelectors } from '../../slice/objectives-slice';
import ObjectivesOverallProgress from '../cards/objectives-overall-progress';
import { Objective, ObjectiveGroup } from '../../objective-types';

import styles from './objectives-pane.module.css';

interface iProps {
  groups: ObjectiveGroup[];
  title?: string;
  description?: string;
}

const ObjectivesPaneOverallProgress = ({ groups, title, description }: iProps): JSX.Element => {
  const objectivesMap = useSelector(ObjectivesSelectors.getObjectives);
  const completedObjectivesMap = useSelector(ObjectivesSelectors.getObjectiveCompletionData);

  // Calculate overall progress through all the basic objectives
  const { objectiveCount, completedObjectiveCount } = useMemo(() => {
    const objectives = groups
      .flatMap((group) => group.objectiveIds)
      .map((id) => objectivesMap[id])
      .filter((objective): objective is Objective => objective !== null);

    const completedObjectives = objectives.filter(({ id }) => completedObjectivesMap[id]);

    return {
      objectiveCount: objectives.length,
      completedObjectiveCount: completedObjectives.length,
    };
  }, [groups, objectivesMap, completedObjectivesMap]);

  return (
    <div className={styles.overallProgress}>
      <ObjectivesOverallProgress title={title} description={description} completedObjectiveCount={completedObjectiveCount} objectiveCount={objectiveCount} />
    </div>
  );
};

export default ObjectivesPaneOverallProgress;
