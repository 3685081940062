import { Filters } from '@gi/filters';
import Plant from '@gi/plant';
import { INITIAL_PLANT_FILTERS, PlantFiltersType } from './create-plant-filters';

const getObjectDiff = <T extends object>(toCheck: T, against: T): Partial<T> => {
  const diff: Partial<T> = {};
  const keys = Object.keys(toCheck);
  for (let i = 0; i < keys.length; i++) {
    if (toCheck[keys[i]] !== against[keys[i]]) {
      diff[keys[i]] = toCheck[keys[i]];
    }
  }
  return diff;
};

/**
 * A partial of the return type of the getInputs of the given filters.
 * The partial is 2 layers deep, with each section being optional, and each input in each section also being optional.
 */
type PartialFilterInputs<T extends Filters<any, any>> = {
  -readonly [K in keyof ReturnType<T['getInputs']>]?: Partial<ReturnType<T['getInputs']>[K]>;
};

export type PartialPlantFilters = PartialFilterInputs<Filters<Plant, PlantFiltersType>>;

/**
 * Returns a diff of the 2 filters inputs.
 * @param filters The current filters
 * @param originalFilters The original filters
 * @param removeUnserializable Removes any fields that cannot be serialized.
 * @returns A partial diff of all the inputs that are different
 */
export const getPlantFilterInputDiff = (
  filters: Filters<Plant, PlantFiltersType>,
  originalFilters: Filters<Plant, PlantFiltersType> = INITIAL_PLANT_FILTERS,
  removeUnserializable: boolean = false
): PartialPlantFilters => {
  const inputs = filters.getInputs();
  const defaultInputs = originalFilters.getInputs();
  const diff: PartialPlantFilters = {};

  const keys = Object.keys(inputs);
  for (let i = 0; i < keys.length; i++) {
    const inputDiff = getObjectDiff(inputs[keys[i]], defaultInputs[keys[i]]);
    if (Object.keys(inputDiff).length > 0) {
      diff[keys[i]] = inputDiff;
    }
  }

  if (!removeUnserializable) {
    return diff;
  }

  // Remove anything that can't be serialized or is irrelevant for analytics.
  if (diff.plantingDates?.userPlantingCalendars !== undefined) {
    delete diff.plantingDates.userPlantingCalendars;
  }
  if (diff.search?.searchResults !== undefined) {
    delete diff.search.searchResults;
  }
  if (diff.companion?.companionPlantCodes !== undefined) {
    delete diff.companion.companionPlantCodes;
  }
  if (diff.companion?.plantCodes !== undefined) {
    delete diff.companion.plantCodes;
  }
  if (diff.favourites?.favouritePlants !== undefined) {
    delete diff.favourites.favouritePlants;
  }
  if (diff.sort !== undefined) {
    delete diff.sort;
  }

  return diff;
};
