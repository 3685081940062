import React, { useState } from 'react';

import './colour-input.scss';

interface iProps {
  onSubmit: () => void;
  onChange: (value: string) => void;
  onOpenSystemDialog?: () => void;
  onCloseSystemDialog?: () => void;
  value: string;
}

const ColourInput = ({ onSubmit, onChange, onOpenSystemDialog = () => {}, onCloseSystemDialog = () => {}, value }: iProps): JSX.Element => {
  const [isInvalid, setInvalid] = useState<boolean>(false);
  const [inputValue, setInput] = useState<string>(value);

  /**
   * Sets the text input value, invalid/valid styles, and triggers onChanges from props
   * @param {string} inputValue a colour value with or without a leading #
   */
  const handleChange = (newValue) => {
    newValue = newValue.trim().toUpperCase();
    setInput(newValue);
    if (newValue.substring(0, 1) === '#') {
      newValue = newValue.substring(1);
    }

    if (!/^[0-9A-F]{6}$/.test(newValue)) {
      setInvalid(true);
      return;
    }

    setInvalid(false);
    onChange(newValue);
    onCloseSystemDialog();
  };

  /**
   * Strips the hash from the colour input's value and passes to our colour change handler
   * @param {event} e The change event
   */
  const handleColorChange = (e: React.FormEvent<HTMLInputElement>) => {
    handleChange(e.currentTarget.value.substring(1));
  };

  /**
   * Passes the text input value directly to our change handler
   * @param {event} e The change event
   */
  const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    handleChange(e.currentTarget.value);
  };

  /**
   * Fires props.onSubmit if the key pressed is enter
   * @param {event} e The keypress event
   */
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.charCode === 13) {
      onSubmit();
    }
  };

  const className = `colour-input-text ${isInvalid ? 'invalid-colour-input' : ''}`;
  const hashVal = `#${value}`;
  return (
    <div className='colour-input'>
      <label className='colour-input-label' style={{ backgroundColor: `#${value}` }}>
        <input
          type='color'
          className='colour-input-swatch'
          value={hashVal}
          onClick={onOpenSystemDialog}
          onBlur={onCloseSystemDialog}
          onChange={handleColorChange}
        />
      </label>
      <input type='text' className={className} value={inputValue} onKeyPress={handleKeyPress} onChange={handleTextChange} />
      <button type='button' disabled={isInvalid} className='colour-input-button' onClick={onSubmit}>
        OK
      </button>
    </div>
  );
};

export default ColourInput;
