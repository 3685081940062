import React, { useMemo } from 'react';

import { Direction } from '@gi/constants';

import SidebarProvider, { SidebarProviderProps } from './sidebar-provider';

import styles from './sidebar-styles.module.css';

interface iProps extends SidebarProviderProps {
  className?: string;
  width?: number;
}

const Sidebar = ({ children, className, width, orientation = Direction.LEFT, ...providerProps }: iProps): JSX.Element => {
  const classNames: string[] = [styles.sidebar];
  classNames.push(orientation === Direction.LEFT ? styles.left : styles.right);
  if (className) {
    classNames.push(className);
  }

  const variables = useMemo<React.CSSProperties>(
    () =>
      ({
        '--sidebar-width': width !== undefined ? `${width}px` : undefined,
      }) as React.CSSProperties,
    [width]
  );

  return (
    <SidebarProvider orientation={orientation} {...providerProps}>
      <div className={classNames.join(' ')} style={variables}>
        {children}
      </div>
    </SidebarProvider>
  );
};

export default Sidebar;
