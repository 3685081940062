import { Store } from 'redux';

import { PlanService } from '@gi/plan';

import planLoader from './plan-loader';
import planOpener from './plan-opener';
import settingsSynchroniser from './settings-synchroniser';
import gardenCanvasEventHanlder from './garden-canvas-event-handler';
import selectedNodesEventHandler from './selected-node-event-handler';
import canvasFocuser from './canvas-focuser';
import setupAutosaver from './auto-saver';
import setupWindowCloseListener from './window-close-listener';
import setupClipboardEventsHandler from './clipboard-event-handler';
import GardenCanvasController from '../garden-canvas-controller/garden-canvas-controller';

const attachStoreListeners = (store: Store, services: { planService: PlanService }, gardenCanvasController: GardenCanvasController) => {
  planLoader(store, services.planService);
  planOpener(store, gardenCanvasController);
  settingsSynchroniser(store, gardenCanvasController);
  gardenCanvasEventHanlder(store, gardenCanvasController);
  selectedNodesEventHandler(store, gardenCanvasController);
  canvasFocuser(store, gardenCanvasController);
  setupAutosaver(store);
  setupWindowCloseListener(store);
  setupClipboardEventsHandler(store, gardenCanvasController);
};

export default attachStoreListeners;
