import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConnectedNewPlanModal from '../connected-modals/connected-new-plan-modal';

const NewPlanModalRenderer = ({ newPlanModalOpen }) => {
  if (!newPlanModalOpen) {
    return null;
  }

  return <ConnectedNewPlanModal />;
};

NewPlanModalRenderer.propTypes = {
  newPlanModalOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    newPlanModalOpen: state.modal.newPlanModalOpen,
  };
};

export default connect(mapStateToProps)(NewPlanModalRenderer);
