import React from 'react';
import Modal, { ModalContent, ModalPane, ModalPaneContainer, ModalPaneContent } from '@gi/modal';

import styles from './json-input.module.css';
import JSONInputError from './json-input-error';
import { TutorialParseError } from '../tutorial-editor-types';

interface iProps {
  errors: TutorialParseError[];
  onClose: () => void;
}

const ImportErrorsModal = ({ errors, onClose }: iProps): JSX.Element => {
  return (
    <Modal closeRequest={onClose} style={{ minWidth: 'min(480px, 100%)' }}>
      <ModalContent>
        <ModalPaneContainer>
          <ModalPane>
            <ModalPaneContent>
              <div className={styles.editorErrorsScrollPane}>
                {errors.map((err, i) => (
                  <JSONInputError key={i} err={err} />
                ))}
              </div>
            </ModalPaneContent>
          </ModalPane>
        </ModalPaneContainer>
      </ModalContent>
    </Modal>
  );
};

export default ImportErrorsModal;
