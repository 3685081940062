import React from 'react';
import { useSelector } from 'react-redux';

import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';

import AppGuruDesktop from '@gi/app-guru-desktop';
import AppGuruMobile from '@gi/app-guru-mobile';

const GuruRoot = (): JSX.Element => {
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  if (deviceDisplayMode === DeviceDisplayMode.DESKTOP) {
    return <AppGuruDesktop />;
  }

  return <AppGuruMobile />;
};

export default GuruRoot;
