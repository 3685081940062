import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';
import { forecastFromAPI } from '../parsers/forecast-parser';
import { attachForecastErrorClientMessage } from './forecast-service-errors';
import Forecast from '../forecast';

class ForecastService {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
    console.debug(`Created forecast service with endpoint ${this.endpoint}`);
  }

  loadWeeksWeatherForecast = (date: Date, lat: number, lon: number): Promise<Forecast[]> => {
    return this.getForecastsFromDate(date, 7, lat, lon);
  };

  /**
   * @return {Promise}
   */
  getForecastsForDates(dates: Date[], lat: number, long: number): Promise<Forecast[]> {
    return Promise.all(dates.map((date) => this.getForecast(date, lat, long)));
  }

  /**
   * @return {Promise}
   */
  getForecastsFromDate(date: Date, numDays: number, lat: number, long: number): Promise<Forecast[]> {
    const dates: Date[] = [];
    date = new Date(date); // Prevent side effects
    if (Number.isFinite(numDays) && numDays > 0) {
      while (numDays--) {
        dates.push(new Date(date)); // new Date otherwise the setDate affects the one pushed.
        date.setDate(date.getDate() + 1);
      }
    }
    return this.getForecastsForDates(dates, lat, long);
  }

  /**
   * @param {Date} date
   * @param {number} lat
   * @param {number} long
   * @return {Promise}
   */
  getForecast(date: Date, lat: number, long: number): Promise<Forecast> {
    const nonAuthTicket = 'c635499c-50f8-4af5-9efc-0d0bad1ecc44';

    const params = {
      t: nonAuthTicket,
      lat,
      long,
      date: date.toISOString(),
      lang: 'en',
      units: 'si',
    };

    const url = `${this.endpoint}/weather`;
    return networkService
      .get<string>(url, params, RequestAuthMode.None)
      .then((response) => {
        // The weather API returns a string that contains encoded JSON, rather than just JSON.
        return forecastFromAPI(JSON.parse(response.body));
      })
      .catch(networkErrorReporter('GET', 'weather'))
      .catch((requestError) => {
        attachForecastErrorClientMessage(requestError);
        throw requestError;
      });
  }
}

export default ForecastService;
