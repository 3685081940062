import React, { ChangeEvent, useCallback } from 'react';

import { Geometry } from '@gi/math';
import { VectorInput } from '@gi/length-input';
import FormField, { FORM_FIELD_PRESETS } from '@gi/form-responsive';

import { EditShapeModalFormProps, ShapeState } from '../../types';

/**
 * Displays the positional inputs for a line (start, mid and end positions)
 */
const LinePropertiesFormSection = ({ values, setValues, distanceUnits }: EditShapeModalFormProps): JSX.Element => {
  const createSetter = useCallback(
    <K extends keyof ShapeState>(setting: K) =>
      (value: ShapeState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  const onCurvedChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      setValues(
        values.setValues(
          ['curved', { value: checked }],
          [
            'point2',
            {
              value: checked ? Geometry.midpoint(values.values.point1, values.values.point3) : null,
            },
          ]
        )
      );
    },
    [values, setValues]
  );

  return (
    <>
      <FormField label='Start' htmlFor='edit-shape:point1-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.point1} onChange={createSetter('point1')} distanceUnits={distanceUnits} id='edit-shape:point1' />
      </FormField>
      <FormField label='End' htmlFor='edit-shape:point3-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.point3} onChange={createSetter('point3')} distanceUnits={distanceUnits} id='edit-shape:point3' />
      </FormField>
      <FormField label='Curved' htmlFor='edit-shape:curved' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <input type='checkbox' checked={values.values.curved} onChange={onCurvedChange} id='edit-shape:curved' />
      </FormField>
      <FormField label='Curve Point' htmlFor='edit-shape:point2-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector} disabled={!values.values.curved}>
        <VectorInput
          value={values.values.point2 ?? { x: 0, y: 0 }}
          onChange={createSetter('point2')}
          distanceUnits={distanceUnits}
          disabled={!values.values.curved}
          id='edit-shape:point2'
        />
      </FormField>
    </>
  );
};

export default LinePropertiesFormSection;
