import React from 'react';

interface iProps {
  children: React.ReactNode;
  className?: string;
}

const Table = ({ children, className = '' }: iProps): JSX.Element => {
  return <table className={`table-container ${className}`}>{children}</table>;
};

export default Table;
