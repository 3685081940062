import { createFilters, updateFilters } from '@gi/filters';
import { UserPlantingCalendars } from '@gi/planting-calendar';
import Plant from '@gi/plant';

import search from './search-filter/search-filter';
import type from './type-filter/type-filter';
import easyToGrow from './easy-to-grow-filter/easy-to-grow-filter';
import frostTolerant from './frost-tolerant-filter';
import autumn from './suitable-for-autumn-filter';
import partialShade from './partial-shade-filter';
import cropFamily from './crop-family-filter/crop-family-filter';
import sort from './sort-filter';
import sfg from './sfg-filter';
import favourites from './favourites-filter/favourites-filter';
import perennial from './perennial-filter/perennial-filter';
import companion from './companion-filter/companion-filter';
import plantingDates from './planting-dates-filter/planting-dates-filter';
import tropical from './tropical-filter/tropical-filter';

const PlantFiltersType = {
  type,
  easyToGrow,
  frostTolerant,
  autumn,
  partialShade,
  cropFamily,
  sfg,
  sort,
  favourites,
  perennial,
  companion,
  search,
  plantingDates,
  tropical,
} as const;
export type PlantFiltersType = typeof PlantFiltersType;

export const INITIAL_PLANT_FILTERS = createFilters<Plant, PlantFiltersType>({
  type,
  easyToGrow,
  frostTolerant,
  autumn,
  partialShade,
  cropFamily,
  sfg,
  sort,
  favourites,
  perennial,
  companion,
  search,
  plantingDates,
  tropical,
});

const createPlantFilters = (sfgMode: boolean, userPlantingCalendars: UserPlantingCalendars, tropicalClimate: boolean) => {
  return updateFilters<Plant, PlantFiltersType>(INITIAL_PLANT_FILTERS, {
    sfg: {
      sfgModeEnabled: sfgMode,
      enabled: false,
    },
    plantingDates: {
      userPlantingCalendars,
    },
    tropical: {
      enabled: tropicalClimate,
    },
  });
};

export default createPlantFilters;
