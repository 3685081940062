import { RequestError, networkErrorReporter, networkService } from '@gi/gi-network';

import sendSupportEmailErrorCodes from './send-support-email-errors';
import { SupportEmail } from '../support-email';

const ENDPOINT = '/emails/support';

/**
 * Attaches a `clientMessage` to the request error to display to the user.
 * @param responseError The error to attach data to
 */
const attachSupportErrorClientMessage = (responseError: RequestError): void => {
  if (responseError.statusCode && sendSupportEmailErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = sendSupportEmailErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when making support request - ${responseError.statusCode}`;
  }
};

class SupportEmailService {
  endpoint: string;

  constructor({ endpoint }: { endpoint: string }) {
    this.endpoint = endpoint;
    console.debug(`Support email service created with endpoint ${this.endpoint}`);
  }

  /**
   * Sends a support email
   *
   * @param supportEmail Support email to be sent
   */
  sendSupportEmail(supportEmail: SupportEmail) {
    const url = `${this.endpoint}${ENDPOINT}`;

    return networkService
      .post(url, {}, supportEmail)
      .catch(networkErrorReporter('POST', 'support email'))
      .catch((requestError) => {
        attachSupportErrorClientMessage(requestError);
        throw requestError;
      });
  }
}

export default SupportEmailService;
