import React, { useCallback, useRef } from 'react';
import Plant from '@gi/plant';
import { metricDistanceUnits, DistanceUnits } from '@gi/units';
import { User } from '@gi/user';
import { Plan } from '@gi/plan';
import { PlannerIconWithFamily } from '@gi/planner-icon';
import { SupplierCatalogue, SupplierCatalogueUtils } from '@gi/supplier';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';

import { Modal, ModalCloseButton, ModalContent, ModalTabs, ModalTabContentContainer, ModalTabListMobile } from '@gi/modal';

import { useDispatch } from 'react-redux';
import PlantInformationTab from './plant-information-tab/plant-information-tab';
import PlantNotesTab from './plant-notes-tab';
import PlantVarietiesTab from './plant-varieties-tab';
import PlantJournalTab from './plant-journal-tab';

import './plant-information-modal.scss';

const renderScientificName = (plant: Plant) => {
  if (plant.scientificName === '') {
    return null;
  }

  if (plant.otherScientificNames === '') {
    return <div className='plant-title-scientific'>{plant.scientificName}</div>;
  }

  return (
    <div className='plant-title-scientific'>
      {plant.scientificName}, <span className='plant-title-scientific-other'>{plant.otherScientificNames}</span>
    </div>
  );
};

interface iProps {
  closeModal: () => void;
  plant: Plant;
  distanceUnits?: DistanceUnits;
  user: User;
  plan: null | Plan;
  supplierCatalogue: null | SupplierCatalogue;
  defaultTab?: string | null;
}

const PlantInformationModal = ({
  defaultTab = null,
  closeModal,
  plant,
  distanceUnits = metricDistanceUnits,
  user,
  plan = null,
  supplierCatalogue = null,
}: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement | null>(null);

  const backToTop = () => {
    if (ref.current !== null) {
      ref.current.scrollIntoView();
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(${plant.images.landscape})`,
  };

  const onTabChange = useCallback((tabName: string) => {
    dispatch(
      GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ViewPlantInformationModal, {
        plantCode: plant.code,
        tab: tabName,
        justOpened: false,
      })
    );
  }, []);

  return (
    <Modal closeRequest={closeModal} className='plant-info-modal top-attached'>
      <ModalContent>
        <ModalCloseButton onClick={closeModal} light large />
        <ModalTabs defaultActiveTab={defaultTab === null ? 'plant-information' : defaultTab} onTabChange={onTabChange}>
          <>
            <div ref={ref} className='plant-title-pane' style={backgroundStyle}>
              <div className='plant-title-background-effect' />
              <div className='plant-title-pane-inner'>
                <h2 className='plant-title-header'>
                  <PlannerIconWithFamily code={plant.code} familyID={plant.familyID} />
                  <span className='title-text'>{plant.name}</span>
                </h2>
                {renderScientificName(plant)}
              </div>
              <ModalTabListMobile
                tabs={[
                  { tabID: 'plant-information', label: 'Information' },
                  { tabID: 'plant-varieties', label: 'Varieties & Catalog' },
                  { tabID: 'plant-journal', label: 'Journal History' },
                  { tabID: 'plant-notes', label: 'Your Notes' },
                ]}
              />
            </div>
            <ModalTabContentContainer>
              <PlantInformationTab plant={plant} distanceUnits={distanceUnits} />
              <PlantNotesTab plant={plant} plan={plan} />
              <PlantJournalTab backToTop={backToTop} plant={plant} plan={plan} user={user} />
              <PlantVarietiesTab
                plant={plant}
                supplierCatalogue={supplierCatalogue ?? SupplierCatalogueUtils.init()}
                userPlantVarieties={user !== null ? user.plantVarieties : null}
              />
            </ModalTabContentContainer>
          </>
        </ModalTabs>
      </ModalContent>
    </Modal>
  );
};

export default PlantInformationModal;
