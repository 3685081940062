import React, { useContext } from 'react';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import PlantListOptionFilter from './plant-list-option-filter';

const ShowPlantFamilyOption = () => {
  const { options, setOptions } = useContext(GlobalPlantListContext);

  return (
    <PlantListOptionFilter
      label='Show Plant Family'
      id='plant-list-option:show-family'
      value={options.showPlantFamily}
      setValue={(checked) => {
        setOptions({ showPlantFamily: checked });
      }}
    />
  );
};

export default ShowPlantFamilyOption;
