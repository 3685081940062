import React from 'react';

import HeaderContainer from './header-container';
import HeaderSubtitle from './header-subtitle';
import HeaderTitle from './header-title';

interface iProps {
  className?: string;
  titleClassName?: string;
  children: React.ReactNode;
  subtitle?: string | null;
}

const DefaultContentHeader = ({ className = '', titleClassName = '', children, subtitle = null }: iProps) => {
  return (
    <HeaderContainer className={className}>
      {/* <Breadcrumbs /> */}
      <HeaderTitle className={titleClassName}>{children}</HeaderTitle>
      {subtitle ? <HeaderSubtitle>{subtitle}</HeaderSubtitle> : null}
    </HeaderContainer>
  );
};

export default DefaultContentHeader;
