import { InteractionStateType } from '@gi/constants';
import { InteractionManagerState } from '@gi/core-renderer';

export interface Null extends InteractionManagerState {
  type: null;
}

export interface NotNull extends InteractionManagerState {
  type: InteractionStateType;
  inProgress: boolean;
}

/**
 * SELECT_BOX and ITEM_DRAG appear to have no extra information
 */
export interface Other extends NotNull {
  type: InteractionStateType.SELECTION_BOX | InteractionStateType.ITEM_DRAG;
}

/**
 * Interaction state for when an item is being resized
 */
export interface Resize extends NotNull {
  type: InteractionStateType.ITEM_RESIZE;
  subjectNodeInfo: Record<string, any>;
}
