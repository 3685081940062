import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';

import { FadedScrollContainer } from '@gi/mobile-components';

import { PlantListAreaGroup } from '../../../types/plant-list-types';
import PlantListCardIndividualDetailsEntry from './plant-list-card-individual-details-entry';
import GlobalPlantListContext from '../../../global-provider/global-plant-list-context';

import './plant-list-card-individual-details.scss';

interface iProps {
  areaGroups: PlantListAreaGroup[];
  showVariety?: boolean;
  children?: ReactNode;
  /** Button text to use normally, when both individual plantings and children are visible */
  expandButtonText?: string;
  /** Button text to use when individual plantings section is hidden, but we still have children */
  expandButtonTextOnlyChildren?: string;
  /** Button text to use when individual plantings section is shown, and we have no children */
  expandButtonTextNoChildren?: string;
}

const PlantListCardIndividualDetails = ({
  areaGroups,
  showVariety,
  children,
  expandButtonText = 'Details',
  expandButtonTextOnlyChildren = expandButtonText,
  expandButtonTextNoChildren = 'Details',
}: iProps): JSX.Element | null => {
  const { columns, options } = useContext(GlobalPlantListContext);

  const [expanded, setExpanded] = useState<boolean>(options.expandPlantAreas);

  useEffect(() => {
    setExpanded(options.expandPlantAreas);
  }, [options.expandPlantAreas]);

  const expandText = useMemo(() => {
    if (children && columns.area) {
      return expandButtonText;
    }
    if (children) {
      return expandButtonTextOnlyChildren;
    }
    return expandButtonTextNoChildren;
  }, [children, columns.area, expandButtonText, expandButtonTextOnlyChildren, expandButtonTextNoChildren]);

  if (!children && !columns.area) {
    return null;
  }

  const classNames = ['plant-list-card-details'];
  if (expanded) {
    classNames.push('expanded');
  }

  return (
    <div className={classNames.join(' ')}>
      <button className='plant-list-card-details-expand' type='button' onClick={() => setExpanded(!expanded)}>
        <span className='expand-text'>
          {expanded ? 'Hide' : expandText}
          <i className='icon-angle-double-down' />
        </span>
      </button>
      <AnimateHeight duration={200} height={expanded ? 'auto' : 0}>
        {children}
        {columns.area ? (
          <>
            <span className='title'>Individual Plantings</span>
            <FadedScrollContainer isHorizontal paused={!expanded}>
              <div className={`plant-list-card-details-list ${showVariety ? 'show-variety' : ''}`}>
                {showVariety ? <span className='heading'>Variety</span> : null}
                <span className='heading'>Plant Label</span>
                <span className='heading'>Qty.</span>
                <span className='heading'>In-Ground Dates</span>
                {areaGroups.map((areaGroup) => {
                  return <PlantListCardIndividualDetailsEntry key={areaGroup.planPlant.id} areaGroup={areaGroup} showVariety={showVariety} />;
                })}
              </div>
            </FadedScrollContainer>
          </>
        ) : null}
      </AnimateHeight>
    </div>
  );
};

export default PlantListCardIndividualDetails;
