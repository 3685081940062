import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Plant from '@gi/plant';
import { DrawingToolsContext } from '@gi/drawing-tools';
import { Anchor, ButtonStrip } from '@gi/mobile-components';
import { Tooltip, TooltipContent, TooltipTrigger } from '@gi/tooltip';
import { collectionNotNull, ResourceContext } from '@gi/resource-provider';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { PlannerIconWithFamily } from '@gi/planner-icon';
import { SessionSelectors } from '@gi/react-session';
import { UserUtils } from '@gi/user';

import FiltersButton from '../filters-button';
import { getActiveFilterCount } from './utils';
import PlantsFiltersDrawer from './plants-filters-drawer';

import './plants-drawer-companions.scss';

interface iProps {
  anchor?: Anchor;
}

const PlantsDrawerCompanions = ({ anchor = 'BOTTOM' }: iProps): JSX.Element | null => {
  const { plantFilters, resetPlantFilters } = useContext(DrawingToolsContext);
  const { userPlants } = useContext(ResourceContext);
  const notNullPlants = collectionNotNull(userPlants);
  const user = useSelector(SessionSelectors.getUser);
  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);

  const companionsMode = plantFilters.filters.companion?.inputs?.enabled;

  /**
   * Convert the plant code list from the filters to a usable list of plants
   */
  const chosenPlants = useMemo(() => {
    const { plantCodes } = plantFilters.filters.companion.inputs;
    const plants = ([...plantCodes] as string[])
      .map((plantCode) => notNullPlants.get(plantCode))
      .filter((plant): plant is Plant => plant !== null)
      .sort((a, b) => a.name.localeCompare(b.name));
    return plants;
  }, [plantFilters.filters.companion]);

  /**
   * Convert the plants to icons to display
   */
  const chosenPlantIcons = useMemo(() => {
    return chosenPlants.map((plant) => (
      <Tooltip placement='top' key={plant.ID}>
        <TooltipTrigger asWrapper>
          <PlannerIconWithFamily code={plant.code} familyID={plant.familyID} />
        </TooltipTrigger>
        <TooltipContent>{plant.name}</TooltipContent>
      </Tooltip>
    ));
  }, [chosenPlants]);

  /**
   * Generate display text for how many plants are chosen
   */
  const chosenPlantNames = useMemo(() => {
    if (chosenPlants.length > 2) {
      return `Showing companions for ${chosenPlants.length} plants`;
    }
    if (chosenPlants.length === 2) {
      return `Showing companions for ${chosenPlants[0].name} or ${chosenPlants[1].name}`;
    }
    if (chosenPlants.length === 1) {
      return `Showing companions for ${chosenPlants[0].name}`;
    }
    return 'Showing companions for no plants';
  }, [chosenPlants]);

  // We shouldn't exist if not in companions mode anyway
  if (!companionsMode) {
    return null;
  }

  return (
    <>
      <div className='plants-drawer-companions-header'>
        <p>
          <span>{chosenPlantNames}</span>
          <button className='button button-inline' type='button' onClick={resetPlantFilters}>
            Clear
          </button>
        </p>
        <div className='plants-drawer-companions-icons'>
          <ButtonStrip hideScrollbar gap={12} className='plants-drawer-companions-list' shouldAlignCenter>
            {chosenPlantIcons}
          </ButtonStrip>
          <FiltersButton
            onClick={() => setShowFiltersDrawer(true)}
            activeFilterCount={getActiveFilterCount(plantFilters, sfgMode, user ? UserUtils.isTropicalClimate(user) : true)}
          />
        </div>
      </div>
      <PlantsFiltersDrawer open={showFiltersDrawer} onClose={() => setShowFiltersDrawer(false)} anchor={anchor} />
    </>
  );
};

export default PlantsDrawerCompanions;
