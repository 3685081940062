import Node from '../../node';
import EventBus from '../../event-bus';
import { InspectableClassData } from '../../types';
import NodeComponent, { NodeComponentEventActions } from '../../node-component/node-component';

export enum LongPressEvent {
  OnLongPress = 'OnLongPress',
}

type LongPressEventActions = {
  [LongPressEvent.OnLongPress]: (node: Node, worldPosition: Vector2) => void;
};

/**
 * Long-pressable component context
 *  Handles firing events when any node is long-pressed.
 */
class LongPressableComponentContext extends NodeComponent {
  type = 'DoubleClickableComponentContext';

  readonly eventBus: EventBus<NodeComponentEventActions & LongPressEventActions>;

  constructor() {
    super();

    this.eventBus = new EventBus(this.eventBus);
  }

  wasLongPressed<T extends Node>(node: T, worldPosition: Vector2) {
    this.eventBus.emit(LongPressEvent.OnLongPress, node, worldPosition);
  }

  inspectorData: InspectableClassData<this> = [];
}

export default LongPressableComponentContext;
