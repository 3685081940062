import React from 'react';
import { useSelector } from 'react-redux';

import { SessionSelectors } from '@gi/react-session';
import { ErrorScreen } from '@gi/loading-screen';

import ResourceLoader, { ResourceLoaderProps } from './resource-loader';

const ConnectedResourceLoader = ({ children, ...remainingProps }: Omit<ResourceLoaderProps, 'user'>): JSX.Element => {
  const user = useSelector(SessionSelectors.getUser);

  // User should always be set, but just in case
  if (!user) {
    return (
      <ErrorScreen>
        <h2>Something went wrong!</h2>
        <p>
          Sorry, something went wrong when trying to load the Garden Planner. Please check your network connecting and try again. If the problem persists,
          please <a href='/contact.aspx'>contact support</a>.
        </p>
        <ul>
          <li>
            <strong>Error:</strong> Can&apos;t load resources - No User
          </li>
        </ul>
      </ErrorScreen>
    );
  }

  return (
    <ResourceLoader user={user} {...remainingProps}>
      {children}
    </ResourceLoader>
  );
};

export default ConnectedResourceLoader;
