import React from 'react';

import UserContactSettingsForm from '../forms/user-contact-settings-form';

import './settings-pages.scss';

const EmailSettingsPage = (): JSX.Element => {
  return (
    <div className='settings-page'>
      <UserContactSettingsForm />
    </div>
  );
};

export default EmailSettingsPage;
