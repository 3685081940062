import { Container, RenderTexture, Sprite } from 'pixi.js-new';

/**
 * Stores a bunch of identical sprites that use the same texture.
 * If you're rendering something that re-draws a lot (lots of rects each frame),
 *  consider using this instead of drawRect, as it's has much better memory usage.
 * @example
 * const factory = new ReusableSpriteFactory(container);
 *
 * const tick = new Graphics()
 *     .beginFill('#ff0000')
 *     .drawRect(0, 0, 1, 1);
 *
 * engine.renderer.render(tick, factory.renderTexture)
 *
 * function draw() {
 *     factory.beginReusing();
 *     for (let i = 0; i < 100; i++) {
 *         const sprite = factory.getSprite();
 *         ...
 *     }
 *     factory.destroyUnused()
 * }
 */
export class ReusableSpriteFactory {
  readonly parent: Container;
  readonly renderTexture: RenderTexture;
  #sprites: Sprite[] = [];
  #used: number = 0;

  constructor(parent: Container, width: number = 1, height: number = 1) {
    this.parent = parent;
    this.renderTexture = RenderTexture.create({ width, height });
  }

  beginReusing() {
    this.#used = 0;
  }

  getSprite() {
    const i = this.#used;
    this.#used++;
    let sprite = this.#sprites[i];

    if (!sprite) {
      sprite = new Sprite(this.renderTexture);
      this.parent.addChild(sprite);
      this.#sprites[i] = sprite;
    }

    return sprite;
  }

  destroyUnused() {
    const unused = this.#sprites.splice(this.#used);
    unused.forEach((sprite) => sprite.destroy());
  }

  destroyAll() {
    this.#sprites.forEach((sprite) => sprite.destroy());
    this.#sprites = [];
    this.#used = 0;
  }
}
