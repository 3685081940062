import React, { useRef, useState } from 'react';

import PlantShoppingList from './plant-shopping-list';
import { useShoppingList } from '../hooks';
import Totals from './components/totals/totals';
import ShoppingListControls from './components/controls/shopping-list-controls';
import GardenObjectShoppingList from './garden-object-shopping-list';
import ShoppingListIntroduction from './shopping-list-introduction';

import styles from './shopping-list-content.module.css';

interface iProps {
  planId: number;
  currency: string;
}
const ShoppingListContent = ({ planId, currency }: iProps): JSX.Element | null => {
  const shoppingList = useShoppingList(planId);
  const listScrollPane = useRef<HTMLDivElement>(null);
  const [showPlants, setShowPlants] = useState(true);
  const [showGardenObjects, setShowGardenObjects] = useState(true);

  if (shoppingList === null) {
    return (
      <div className={styles.shoppingList} ref={listScrollPane}>
        <div className={styles.highlightedText}>Shopping System Unavailable</div>
      </div>
    );
  }

  return (
    <div className={styles.shoppingList} ref={listScrollPane}>
      <div className={styles.shoppingListContainer}>
        <ShoppingListIntroduction />
        <div className={styles.shoppingListItems}>
          <section className={styles.shoppingSection}>
            <div className={styles.sectionTitle}>
              <h1>Plants</h1>
              {shoppingList.plants.uuids.length > 0 ? (
                <div className={styles.sectionTitleControls}>
                  <button type='button' className={styles.sectionTitleButton} onClick={() => setShowPlants(!showPlants)}>
                    {showPlants ? (
                      <>
                        Hide Plants <i className='icon-angle-double-down' />
                      </>
                    ) : (
                      <>
                        Show Plants <i className='icon-angle-double-up' />
                      </>
                    )}
                  </button>
                </div>
              ) : null}
            </div>
            {showPlants ? (
              <PlantShoppingList plantEntries={shoppingList.plants} planId={shoppingList.planId} />
            ) : (
              <div className={styles.highlightedText}>Hidden</div>
            )}
          </section>
          <section className={styles.shoppingSection}>
            <div className={styles.sectionTitle}>
              <h1>Garden Objects</h1>
              {shoppingList.gardenObjects.uuids.length > 0 ? (
                <div className={styles.sectionTitleControls}>
                  <button type='button' className={styles.sectionTitleButton} onClick={() => setShowGardenObjects(!showGardenObjects)}>
                    {showGardenObjects ? (
                      <>
                        Hide Garden Objects <i className='icon-angle-double-down' />
                      </>
                    ) : (
                      <>
                        Show Garden Objects <i className='icon-angle-double-up' />
                      </>
                    )}
                  </button>
                </div>
              ) : null}
            </div>
            {showGardenObjects ? (
              <GardenObjectShoppingList currency={currency} gardenObjectEntries={shoppingList.gardenObjects} planId={shoppingList.planId} />
            ) : (
              <div className={styles.highlightedText}>Hidden</div>
            )}
          </section>
        </div>
        <Totals shoppingList={shoppingList} />
        <ShoppingListControls shoppingList={shoppingList} />
      </div>
    </div>
  );
};

export default ShoppingListContent;
