import deepmerge from 'deepmerge';

// @ts-expect-error: Import based on 'APP'' environment variable
import _appConfig from './app-config.APP.json'; // eslint-disable-line
import appConfigDefaults from './app-config.defaults.json';

type AppConfig = {
  allowPlanSaving: boolean;
  useAnalytics: boolean;
};

const overwriteMerge = (_, sourceArray) => sourceArray;

const appConfig = deepmerge(appConfigDefaults, _appConfig, {
  arrayMerge: overwriteMerge,
}) as AppConfig;
export default appConfig;
