import React, { ReactNode } from 'react';

import { Page, PageSection } from '@gi/page-layout';
import LoadingLogo from '@gi/loading-logos';

import './loading-screen.scss';

interface iProps {
  hideVersion?: boolean;
  children: ReactNode;
  className?: string;
  centerContent?: boolean;
}

const LoadingScreen = ({ hideVersion = false, children, className, centerContent = true }: iProps): JSX.Element => {
  const classNames = ['loading-screen'];
  if (className) {
    classNames.push(className);
  }

  return (
    <Page centerContent={centerContent} className={classNames.join(' ')}>
      <PageSection>
        <LoadingLogo />
        {hideVersion ? null : <p className='loading-screen-version'>Version: {VERSION}</p>}
        {children}
      </PageSection>
    </Page>
  );
};

export default LoadingScreen;
