import Collection from '@gi/collection';
import GardenObject from '@gi/garden-object';
import { LocalSettings } from '@gi/local-settings';
import Plan, { PlanSet, PlanSetUtils } from '@gi/plan';
import { GardenCanvas, ToolState } from '@gi/plan-simulation';
import Plant from '@gi/plant';
import { User } from '@gi/user';
import { getArrayDiff } from '@gi/utils';
import { EventBus } from '@gi/core-renderer';
import { networkConfig } from '@gi/config';

function getTextureDefinitionsURL(artifactCode: string): string {
  if (!networkConfig.textureDefinitions[artifactCode]) {
    throw new Error(`Texture definitions not available for artifact code '${artifactCode}'`);
  }

  return networkConfig.textureDefinitions[artifactCode];
}

export enum GardenCanvasControllerEvent {
  STATE_CHANGE = 'STATE_CHANGE',
}

type GardenCanvasControllerEventActions = {
  [GardenCanvasControllerEvent.STATE_CHANGE]: (gardenCanvas: GardenCanvas | null) => void;
};

class GardenCanvasController extends EventBus<GardenCanvasControllerEventActions> {
  #gardenCanvas: GardenCanvas | null = null;
  get gardenCanvas() {
    return this.#gardenCanvas;
  }

  #latestPlanData: PlanSet;
  get latestPlanData() {
    return this.#latestPlanData;
  }

  #latestLoadedPlanData: PlanSet;
  get latestLoadedPlanData() {
    return this.#latestLoadedPlanData;
  }

  #latestActivePlanID: number | null;
  get latestActivePlanID() {
    return this.#latestActivePlanID;
  }

  #latestOpenPlanIDs: number[];
  get latestOpenPlanIDs() {
    return this.#latestOpenPlanIDs;
  }

  constructor() {
    super();

    this.#gardenCanvas = null;

    this.#latestLoadedPlanData = PlanSetUtils.createPlanSet();
    this.#latestPlanData = PlanSetUtils.createPlanSet();
    this.#latestActivePlanID = null;
    this.#latestOpenPlanIDs = [];
  }

  hasInstance() {
    return this.gardenCanvas !== null;
  }

  getInstance() {
    return {
      gardenCanvas: this.gardenCanvas,
      gardenCanvasDataSynchroniser: null,
    };
  }

  createInstance(
    plants: Collection<Plant>,
    gardenObjects: Collection<GardenObject>,
    user: User,
    localSettings: LocalSettings,
    touchMode: boolean,
    artifactCode: string
  ) {
    if (this.gardenCanvas !== null) {
      throw new Error('Asked to create GardenCanvas instance when one already exists.');
    }

    this.#gardenCanvas = new GardenCanvas(plants, gardenObjects, user.plantVarieties, this.#onPlanUpdate, this.#onToolUpdate, {
      ...localSettings,
      touchMode,
      textureDefinitionsURL: getTextureDefinitionsURL(artifactCode),
    });

    for (let i = 0; i < this.#latestOpenPlanIDs.length; i++) {
      const id = this.#latestOpenPlanIDs[i];
      const plan = PlanSetUtils.planSetGetPlan(this.#latestLoadedPlanData, id);
      if (plan) {
        this.#gardenCanvas.openPlan(plan, this.#latestLoadedPlanData.plans);
      }
    }
    this.#gardenCanvas.setActivePlan(this.#latestActivePlanID);

    this.emit(GardenCanvasControllerEvent.STATE_CHANGE, this.#gardenCanvas);
    // TODO: Data Sync

    return this.#gardenCanvas;
  }

  destroyInstance() {
    if (!this.gardenCanvas !== null) {
      throw new Error('Asked to destroy GardenCanvas instance when none exists.');
    }

    // TODO: Do we need to destroy?
    this.#gardenCanvas = null;

    this.emit(GardenCanvasControllerEvent.STATE_CHANGE, this.#gardenCanvas);
  }

  /**
   * Updates this class with the most recent version of all the plan data.
   * This is the live data, used internally.
   * @param planSet The set of all plans
   */
  updateInstancePlanData(planSet: PlanSet) {
    const { added, updated } = PlanSetUtils.getPlanSetDiff(planSet, this.#latestPlanData);
    this.#latestPlanData = planSet;

    for (let i = 0; i < added.length; i++) {
      const plan = added[i];
      if (this.#latestOpenPlanIDs.includes(plan.id)) {
        this.gardenCanvas?.openPlan(plan, this.latestLoadedPlanData.plans);
      }
    }

    for (let i = 0; i < updated.length; i++) {
      const plan = updated[i];
      this.gardenCanvas?.updatePlan(plan);
    }
  }

  /**
   * Updates the list of loaded plans (plans directly from the API, that haven't been modified)
   * @param planSet The set of all loaded plans from the API
   */
  updateInstanceLoadedPlanData(planSet: PlanSet) {
    const { added, updated } = PlanSetUtils.getPlanSetDiff(planSet, this.#latestLoadedPlanData);
    this.#latestLoadedPlanData = planSet;

    for (let i = 0; i < added.length; i++) {
      const plan = added[i];
      this.gardenCanvas?.updateHistoricalPlan(plan);
    }

    for (let i = 0; i < updated.length; i++) {
      const plan = updated[i];
      this.gardenCanvas?.updateHistoricalPlan(plan);
    }
  }

  /**
   * Sets the list of open plan IDs. These should line up with this.latestPlanDana
   * @param openPlanIDs The list of currently open plan IDs
   */
  updateInstanceOpenPlanIDs(openPlanIDs: number[]) {
    const { added, removed } = getArrayDiff(this.latestOpenPlanIDs, openPlanIDs);
    this.#latestOpenPlanIDs = openPlanIDs;

    for (let i = 0; i < added.length; i++) {
      const plan = this.latestPlanData.plans[added[i]];
      if (plan) {
        this.gardenCanvas?.openPlan(plan, this.latestLoadedPlanData.plans);
      }
    }

    for (let i = 0; i < removed.length; i++) {
      const id = removed[i];
      if (this.gardenCanvas?.isPlanOpen(id)) {
        this.gardenCanvas.closePlan(id);
      }
    }
  }

  /**
   * Sets the active plan ID. This plan will be shown by the renderer.
   * @param activePlanId The plan ID
   */
  setInstanceActivePlanID(activePlanId: number | null) {
    this.#latestActivePlanID = activePlanId;

    this.gardenCanvas?.setActivePlan(activePlanId);
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  #onPlanUpdate = (plan: Plan) => {};

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  #onToolUpdate = (tool: ToolState) => {};
}

export default GardenCanvasController;
