import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SidebarPosition from './sidebar-position';

export type SidebarState = {
  position: SidebarPosition;
};

const DEFAULT_STATE: SidebarState = {
  position: SidebarPosition.Minimised,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: DEFAULT_STATE,
  reducers: {
    minimise: (state) => {
      state.position = state.position === SidebarPosition.Open ? SidebarPosition.Minimised : state.position;
    },
    setSidebarPosition: (state, action: PayloadAction<SidebarPosition>) => {
      state.position = action.payload;
    },
  },
});

export const sidebarReducer = sidebarSlice.reducer;
export const SidebarActionCreators = sidebarSlice.actions;

interface RootState {
  sidebar: SidebarState;
}

export const SidebarSelectors = {
  getSidebarPosition: (state: RootState) => state.sidebar.position,
};
