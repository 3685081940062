import countryArtifactData, { CountryArtifactData } from '@gi/country-artifact-data';

export type CountryArtifact = {
  defaultArtifactCode: string;
  availableArtifactCodes: string[];
  useRegionalPlanting: boolean;
  pestArtifactCode: string;
};

export type CountryArtifactMap = { [key: string]: CountryArtifact };

const createArtifactMap = (data: CountryArtifactData[]): CountryArtifactMap => {
  const map = {};

  for (let i = 0; i < data.length; i++) {
    map[data[i].countryCode] = {
      defaultArtifactCode: data[i].defaultArtifactCode,
      useRegionalPlanting: data[i].useRegionalPlantData,
      availableArtifactCodes: data[i].availableArtifactCodes,
      pestArtifactCode: data[i].pestArtifactCode,
    };
  }

  return map;
};

const artifactMap = createArtifactMap(countryArtifactData);

const getCountryData = (countryCode: string): CountryArtifact => {
  if (Object.hasOwnProperty.call(artifactMap, countryCode)) {
    return artifactMap[countryCode];
  }

  console.error("Not found country code '", countryCode, "' in artifact map");

  // No information available for this country, hopefully this will never happen
  // but if it does the user will be defaulted to US settings
  return {
    defaultArtifactCode: 'us',
    availableArtifactCodes: ['us'],
    useRegionalPlanting: false,
    pestArtifactCode: 'us',
  };
};

export default getCountryData;
