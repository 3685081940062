import React, { CSSProperties, ReactNode, useContext } from 'react';
import { AppContext } from '@gi/app-provider';

import { networkConfig } from '@gi/config';
import { Tooltip, TooltipContent, TooltipKey, TooltipTrigger } from '@gi/tooltip';

import SidebarState from '../../sidebar-position';
import { FullwidthSidebarItemContent } from '../sidebar-item';

import './logo-container.scss';

const BurgerMenu = ({ openSidebar }: { openSidebar: () => void }): JSX.Element => {
  return (
    <Tooltip placement='right'>
      <TooltipContent id={TooltipKey.OpenSidebar} />
      <TooltipTrigger>
        <button type='button' className='button expand-button button-borderless' onClick={openSidebar}>
          <i className='icon-menu' />
        </button>
      </TooltipTrigger>
    </Tooltip>
  );
};

interface iProps {
  children?: ReactNode;
  className?: string;
  openSidebar: () => void;
  closeSidebar: () => void;
  sidebarState: SidebarState;
}

const ClientLogoContainer = ({ children, className = '', openSidebar, closeSidebar, sidebarState }: iProps): JSX.Element => {
  const { runtimeConfig } = useContext(AppContext);
  const logoStyle: CSSProperties = sidebarState !== SidebarState.Open ? { display: 'none' } : {};

  const backgroundStyle: CSSProperties = {
    backgroundImage: `url(${networkConfig.assetBaseURL}header-images/${runtimeConfig.backgroundImage.blurSrc})`,
  };

  return (
    <>
      <FullwidthSidebarItemContent style={backgroundStyle} className={`logo-container ${className}`} sidebarState={sidebarState}>
        <div className='logo-bar-background-cover'>
          <span className='logo-image-container' style={logoStyle}>
            {children}
          </span>
          {sidebarState === SidebarState.Open ? null : <BurgerMenu openSidebar={openSidebar} />}
        </div>
      </FullwidthSidebarItemContent>
      <div className={`close-button ${sidebarState === SidebarState.Open ? '' : 'hide'} ${className}`}>
        <button type='button' onClick={closeSidebar} aria-label='close'>
          <i className='icon-left-open-1' />
        </button>
      </div>
    </>
  );
};

export default ClientLogoContainer;
