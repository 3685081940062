import React, { useContext } from 'react';
import ClearableInput from '@gi/clearable-input';

import FilterRow from '../../../filters/filter-row';

import './garden-object-search-filter-view.scss';
import { DrawingToolsContext } from '../../../drawing-tools-context';

interface iProps {
  searchTerm: string;
  onSearchTermChange: (searchTerm: string) => void;
}

const GardenObjectSearchFilterView = ({ searchTerm, onSearchTermChange }: iProps): JSX.Element => {
  const { setSearchHelpModalOpen } = useContext(DrawingToolsContext);

  return (
    <FilterRow
      className='garden-object-search-filter-view'
      onHelp={() => {
        setSearchHelpModalOpen(true);
      }}
    >
      <label className='filter-row-label filter-row-label-small'>
        <span className='filter-row-label-text'>Name</span>
        <span className='filter-row-label-content'>
          <ClearableInput
            type='text'
            className='filter-input'
            onChange={onSearchTermChange}
            value={searchTerm}
            autoComplete='off'
            name='garden-object-search'
          />
        </span>
      </label>
    </FilterRow>
  );
};

export default GardenObjectSearchFilterView;
