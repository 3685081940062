export const centigradeToFarenheit = (tempC: number): number => {
  return tempC * 1.8 + 32;
};

/**
 * Converts centigrade to imperial if need be and adds degrees sign if required
 *
 * @param {number} temp
 * @param {boolean} useMetic - default true. convert to metric
 * @param {boolean} includeDegreeSign - default true.
 * @returns {string} formatted temperature
 */
export const formatTemperature = (temp: number, useMetric: boolean = true, includeDegreeSign: boolean = true): string => {
  let formatted = '';
  let unit = 'C';
  if (!useMetric) {
    unit = 'F';
    temp = centigradeToFarenheit(temp);
  }
  temp = Math.round(temp);
  if (includeDegreeSign) {
    formatted = `${temp}${String.fromCharCode(176)}${unit}`;
  } else {
    formatted = temp.toString();
  }
  return formatted;
};
