import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { PlantInformationActionCreators } from '@gi/plant-information-modal';

import { ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';

import { EditPlantContext } from '../context/edit-plant-context';

import './more-information.scss';

const PlanPlantSpacing = (): JSX.Element => {
  const dispatch = useDispatch();
  const { plant } = useContext(EditPlantContext);

  return (
    <ModalPaneSection>
      <ModalPaneSectionContent className='more-information-container'>
        <button
          type='button'
          className='button more-info-button'
          onClick={() => {
            dispatch(PlantInformationActionCreators.openPlantInformationModal({ plant }));
          }}
        >
          <i className='icon-info-book' />
          <span className='line'>More information &amp; Journal history for</span> <span className='line'>{plant.name}</span>
        </button>
      </ModalPaneSectionContent>
    </ModalPaneSection>
  );
};

export default PlanPlantSpacing;
