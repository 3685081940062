import React from 'react';
import { useDispatch } from 'react-redux';

import Plant from '@gi/plant';
import { PlantInformationActionCreators } from '@gi/plant-information-modal';

import './styles.scss';

interface iProps {
  plant: Plant;
}

const InformationButton = ({ plant }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(PlantInformationActionCreators.openPlantInformationModal({ plant }));
  };

  return (
    <button className='plant-list-card-button' type='button' onClick={onClick}>
      <i className='icon-info-book' />
    </button>
  );
};

export default InformationButton;
