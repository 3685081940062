import React from 'react';

import styles from './tutorial-display.module.css';

interface iProps {
  children: React.ReactNode;
}

const FixedTutorialWindow = ({ children }: iProps): JSX.Element => {
  return <div className={styles.baseTutorialWindow}>{children}</div>;
};

export default FixedTutorialWindow;
