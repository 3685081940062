import React, { ReactNode } from 'react';

import { TooltipContentDefinition } from '../../types';
import TooltipStringContent from './tooltip-string-content';
import TooltipKeyContent from './tooltip-key-content';
import TooltipParameterContent from './tooltip-parameter-content';

interface iProps {
  content: TooltipContentDefinition;
  parameters?: ReactNode[];
}

const TooltipContentRenderer = ({ content, parameters }: iProps): JSX.Element | null => {
  if (content.length === 0) {
    return null;
  }

  return (
    <>
      {content.map((contentItem, i) => {
        switch (contentItem.type) {
          case 'string':
            return <TooltipStringContent content={contentItem} key={i} />;
          case 'key':
            return <TooltipKeyContent content={contentItem} key={i} />;
          case 'parameter':
            return <TooltipParameterContent content={contentItem} parameters={parameters} key={i} />;
          default:
            return null;
        }
      })}
    </>
  );
};

export default TooltipContentRenderer;
