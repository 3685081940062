import { RuntimeConfig } from '@gi/config';
import { createContext } from 'react';

export type AppContextType = {
  runtimeConfig: RuntimeConfig;
};

const AppContext = createContext<AppContextType>({} as AppContextType); // Defaults will be set by the provider

export default AppContext;
