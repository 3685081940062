import { Store } from 'redux';
import { batchActions } from 'redux-batched-actions';

import { CanvasActionCreators } from '@gi/react-garden-canvas';
import { GardenPlatformActionCreators } from '@gi/garden-platform-slice';
import { Application, NavigationActionCreators } from '@gi/garden-platform-navigation';
import { IntercomActionCreators } from '@gi/intercom';

import { InitParamsActionCreators } from '../init-params-slice';

/**
 * List of get params to not clear from the URL
 */
const KEEP_PARAMS: string[] = ['q', 'r', 's', 'client'];

const APPLICATION_BY_NAME = {
  overview: Application.Overview,
  planner: Application.GardenPlanner,
  journal: Application.Journal,
  help: Application.Help,
  account: Application.Account,
} as const;

/**
 * Only items in this whitelist are valid intercom action types to be called, commands are from here:
 * https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 *
 * Note: this is different to the constants set in the @gi/intercom package
 */
const STARTUP_INTERCOM_ACTION_WHITELIST = ['startChecklist', 'startTour', 'showArticle', 'showNews', 'startSurvey'] as const;

function parseIntParameter(value: string | null): null | number {
  if (value === null) {
    return null;
  }

  const int = parseInt(value, 10);

  if (Number.isNaN(int)) {
    return null;
  }

  return int;
}

function parseStringParameter(value: string | null): null | string {
  if (value === null) {
    return null;
  }

  return value;
}

function parseBooleanParamter(value: string | null): null | boolean {
  if (value === null) {
    return null;
  }

  const int = parseInt(value, 10);

  if (Number.isNaN(int)) {
    return null;
  }

  return int === 1;
}

function setBooleanUrlParameter(value: boolean) {
  return value ? '1' : '0';
}

function setDefaultUrlParameter<T>(value: T): T {
  return value;
}

function createWhitelistValidator(whitelist: Readonly<string[]>) {
  return (value: string): string | null => {
    if (whitelist.includes(value)) {
      return value;
    }

    return null;
  };
}

function createApplicationValidator() {
  return (value: string): Application | null => {
    if (APPLICATION_BY_NAME[value]) {
      return APPLICATION_BY_NAME[value];
    }

    return null;
  };
}

function createIntValidator() {
  return (value: number) => {
    return Math.round(value);
  };
}

function createDefaultValidator<T>() {
  return (value: T): T => {
    return value;
  };
}

export enum ParameterType {
  String = 'string',
  Int = 'int',
  Boolean = 'boolean',
}

export const InitTypeParsers = {
  string: parseStringParameter,
  int: parseIntParameter,
  boolean: parseBooleanParamter,
} as const;

export const InitTypeSetters = {
  string: setDefaultUrlParameter,
  int: setDefaultUrlParameter,
  boolean: setBooleanUrlParameter,
} as const;

export const InitParams = [
  'openApp',
  'openPlan',
  'showPlannerIntro',
  'intercomAction',
  'intercomApp',
  'intercomDesktopActionId',
  'intercomMobileActionId',
  'intercomShortcut',
] as const;
export type InitParam = (typeof InitParams)[number];

const InitParamParserTypes = {
  openApp: ParameterType.String,
  openPlan: ParameterType.Int,
  showPlannerIntro: ParameterType.Boolean,
  intercomAction: ParameterType.String,
  intercomApp: ParameterType.String,
  intercomDesktopActionId: ParameterType.Int,
  intercomMobileActionId: ParameterType.Int,
  intercomShortcut: ParameterType.String,
} as const;

type InitParams = {
  openApp: Application | null;
  openPlan: number;
  showPlannerIntro: boolean;
  intercomAction: string;
  intercomApp: Application;
  intercomDesktopActionId: number;
  intercomMobileActionId: number;
  intercomShortcut: string;
};

// TODO, add typings if possible
const InitParamValidators: Record<InitParam, (value: any) => any> = {
  openApp: createApplicationValidator(),
  openPlan: createIntValidator(),
  showPlannerIntro: createDefaultValidator<boolean>(),
  intercomApp: createApplicationValidator(),
  intercomAction: createWhitelistValidator(STARTUP_INTERCOM_ACTION_WHITELIST),
  intercomDesktopActionId: createIntValidator(),
  intercomMobileActionId: createIntValidator(),
  intercomShortcut: createDefaultValidator<string>(),
};

export function getLocalStorageInitParameterKey(userId: number) {
  return `startup-params-${userId}`;
}

/**
 * Finds a single parameter value in a URL and returns
 * the value string if present (after being passed through `decodeURIComponent`), else returns null
 */
function findURLParameter(parameterName: (typeof InitParams)[number]): null | string {
  const items = window.location.search.substring(1).split('&');

  for (let i = 0; i < items.length; i++) {
    const splitParam = items[i].split('=');
    if (splitParam[0] === parameterName) {
      return decodeURIComponent(splitParam[1]);
    }
  }

  return null;
}

function getLocalStorageInputParamLoader(key: string) {
  const startupParmas = localStorage.getItem(key);

  let localStorageResult: Partial<InitParams> | null = null;

  if (startupParmas !== null) {
    try {
      localStorageResult = JSON.parse(startupParmas);
    } catch (e) {
      console.error('Failed to parse startup params');
    }
  }

  return <T extends (typeof InitParams)[number]>(parameterName: T): null | InitParams[T] => {
    if (localStorageResult === null) {
      return null;
    }

    const value = localStorageResult[parameterName];

    if (value === undefined) {
      return null;
    }

    return value;
  };
}

export function findInputParams(userId: number): Partial<InitParams> {
  const getParams = {};
  const localStorageParams = {};
  const localStorageKey = getLocalStorageInitParameterKey(userId);
  const localStorageLoader = getLocalStorageInputParamLoader(localStorageKey);

  InitParams.forEach((param) => {
    if (!InitParamParserTypes[param] || !InitTypeParsers[InitParamParserTypes[param]] || !InitParamValidators[param]) {
      // Param is invalid
      return;
    }

    const getParamValue = InitTypeParsers[InitParamParserTypes[param]](findURLParameter(param));
    if (getParamValue !== null) {
      const validatedParam = InitParamValidators[param](getParamValue);
      if (validatedParam !== null) {
        getParams[param] = validatedParam;
      }
    }

    const localStorageValue = localStorageLoader(param);
    if (localStorageValue !== null) {
      const validatedParam = InitParamValidators[param](localStorageValue);
      if (validatedParam !== null) {
        localStorageParams[param] = validatedParam;
      }
    }
  });

  // Clear localstorage input params
  localStorage.removeItem(localStorageKey);

  return {
    ...localStorageParams,
    ...getParams,
  };
}

export function processGetParams(store: Store, userId: number): void {
  const initParams = findInputParams(userId);

  const searchParams = new URLSearchParams(window.location.search);
  const keptSearchParams = new URLSearchParams();

  // Iterate through the list of params we should keep and preserve them in the new path
  KEEP_PARAMS.forEach((paramName: string) => {
    if (searchParams.has(paramName)) {
      const sp = searchParams.get(paramName);
      if (sp) {
        keptSearchParams.set(paramName, sp);
      }
    }
  });

  const urlPath = keptSearchParams.size === 0 ? window.location.pathname : `${window.location.pathname}?${keptSearchParams.toString()}`;

  // Remove get arguments from URL
  window.history.replaceState({}, document.title, urlPath);

  if (Object.keys(initParams).length > 0) {
    console.debug('Removing get params');
  }

  // Closes the sidebar and navigates to the given app if an application is given
  if (initParams.openApp !== undefined && initParams.openApp !== null) {
    store.dispatch(batchActions([NavigationActionCreators.navigateTo({ application: initParams.openApp }), { type: 'SIDEBAR/MINIMISE' }]));
  }

  // Opens the plan with the given Id
  if (initParams.openPlan !== undefined && initParams.openPlan !== null) {
    store.dispatch(CanvasActionCreators.openPlan(initParams.openPlan));
  }

  // Shows the garden planner introduction video
  if (initParams.openPlan !== undefined && initParams.showPlannerIntro) {
    store.dispatch(GardenPlatformActionCreators.setShowIntroVideo(true));
  }

  // Adds provided intercom shortcut to queue for execution
  if (initParams.intercomShortcut !== undefined && initParams.intercomShortcut) {
    store.dispatch(IntercomActionCreators.queueIntercomShortcuts([initParams.intercomShortcut]));
  }

  store.dispatch(
    InitParamsActionCreators.setInitParams({
      intercomAction: initParams.intercomAction === undefined ? null : initParams.intercomAction,
      intercomDesktopActionId: initParams.intercomDesktopActionId === undefined ? null : initParams.intercomDesktopActionId,
      intercomMobileActionId: initParams.intercomMobileActionId === undefined ? null : initParams.intercomMobileActionId,
      intercomApp: initParams.intercomApp === undefined ? null : initParams.intercomApp,
    })
  );
}
