import { Plan } from './plan';

export type PlanDiff = {
  changedProps: (keyof Plan)[];
  addedItems: number[];
  removedItems: number[];
  updatedItems: number[];
};

// List of props we care about and want to update in a Simulation
const diffableProps: (keyof Plan)[] = ['height', 'width', 'plannerSettings', 'backgroundImage'];

function idArrayDiff(oldIdArray: number[], newIdArray: number[]): [number[], number[]] {
  const added = newIdArray.filter((x) => !oldIdArray.includes(x));
  const removed = oldIdArray.filter((x) => !newIdArray.includes(x));
  return [added, removed];
}

function diffedItems<T extends string | number | symbol>(keys: T[], oldRecord: Record<T, any>, newRecord: Record<T, any>) {
  const updated: T[] = [];
  for (let i = 0; i < keys.length; i++) {
    if (oldRecord[keys[i]] && newRecord[keys[i]]) {
      if (oldRecord[keys[i]] !== newRecord[keys[i]]) {
        // console.log('not the same');
        updated.push(keys[i]);
      }
    }
  }

  return updated;
}

export function createPlanDiff(oldPlan: Plan, newPlan: Plan): PlanDiff {
  const diff: PlanDiff = {
    changedProps: [],
    addedItems: [],
    removedItems: [],
    updatedItems: [],
  };

  if (oldPlan === newPlan) {
    return diff;
  }

  // Check props
  for (let i = 0; i < diffableProps.length; i++) {
    const prop = diffableProps[i];
    if (oldPlan[prop] !== newPlan[prop]) {
      diff.changedProps.push(prop);
    }
  }

  // Check history
  if (newPlan.history.length !== oldPlan.history.length) {
    diff.changedProps.push('history');
  } else {
    for (let i = 0; i < newPlan.history.length; i++) {
      if (newPlan.history[i] !== oldPlan.history[i]) {
        diff.changedProps.push('history');
        break;
      }
    }
  }

  // Check plants
  const [addedPlants, removedPlants] = idArrayDiff(oldPlan.plantIds, newPlan.plantIds);
  const updatedPlants = diffedItems(newPlan.plantIds, oldPlan.plants, newPlan.plants);

  // Check Garden Objects
  const [addedGardenObjects, removedGardenObjects] = idArrayDiff(oldPlan.gardenObjectIds, newPlan.gardenObjectIds);
  const updatedGardenObjects = diffedItems(newPlan.gardenObjectIds, oldPlan.gardenObjects, newPlan.gardenObjects);

  // Check Shapes
  const [addedShapes, removedShapes] = idArrayDiff(oldPlan.shapeIds, newPlan.shapeIds);
  const updatedShapes = diffedItems(newPlan.shapeIds, oldPlan.shapes, newPlan.shapes);

  // Check Text
  const [addedText, removedText] = idArrayDiff(oldPlan.textIds, newPlan.textIds);
  const updatedText = diffedItems(newPlan.textIds, oldPlan.text, newPlan.text);

  // Merge all together
  diff.addedItems = diff.addedItems.concat(addedPlants, addedGardenObjects, addedShapes, addedText);
  diff.removedItems = diff.removedItems.concat(removedPlants, removedGardenObjects, removedShapes, removedText);
  diff.updatedItems = diff.updatedItems.concat(updatedPlants, updatedGardenObjects, updatedShapes, updatedText);

  return diff;
}
