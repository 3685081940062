/**
 * Gets the best position for an item relative to a starting top and left, given a constraining width
 * Such that top > 0 and left > 0 and left < containerWidth - width
 *
 * @param {number} containerWidth - e.g. window.innerWidth
 * @param {number} top - the starting top position, e.g. of the trigger
 * @param {number} left - the starting left position, e.g. of the trigger
 * @param {number} width - the width of the item being positioned
 * @param {number} height - the height of the item being positioned
 * @returns {object} shape: {top: {number}, left: {number}}
 */
const getPositionWithoutOverlapping = (containerWidth: number, top: number, left: number, width: number, height: number): React.CSSProperties => {
  // Start with the assumption that we'll position it in the top-right corner relative to {top, left}
  const position: React.CSSProperties = {
    top: top - height,
    left,
  };

  if (position.top && typeof position.top === 'number' && position.top < 0) {
    // Overlaps top edge of viewport; correct it
    position.top = 0;
  }
  if (left + width > containerWidth) {
    // Overlaps right edge of viewport; correct it
    position.right = 0;
    delete position.left;
  }
  // The initial position is top-right of the button so if the button
  // is visible then it can't overlap bottom or left edge of screen.
  // So no need to account for those cases.

  return position;
};

export default getPositionWithoutOverlapping;
