import Plan from '@gi/plan';
import { SerialisedCanvasInteractionGroup } from '@gi/plan-simulation';

import GardenCanvasEventActionTypes from './garden-canvas-event-action-types';
import { CanvasInteractionState } from './canvas-reducer';

export const updatePlan = (plan: Plan) => {
  return {
    type: GardenCanvasEventActionTypes.UPDATE_PLAN,
    plan,
  };
};

export const updateInteractionState = (interactionState: CanvasInteractionState) => {
  return {
    type: GardenCanvasEventActionTypes.UPDATE_INTERACTION_STATE,
    interactionState,
  };
};

export const updateSelectedNodes = (selectedNodes: SerialisedCanvasInteractionGroup) => {
  return {
    type: GardenCanvasEventActionTypes.UPDATE_SELECTED_NODES,
    selectedNodes,
  };
};

export const updateClipboardState = (hasClipboard: boolean) => {
  return {
    type: GardenCanvasEventActionTypes.UPDATE_CLIPBOARD_STATE,
    hasClipboard,
  };
};
