import { getDurationText } from '@gi/utils';

import { AssetType } from './types';

export class AssetLoadError extends Error {
  assetType: AssetType;
  assetName: string;
  assetSource: string;
  originalError: Error;
  duration: number | null = null;

  constructor(assetType: AssetType, assetName: string, assetSource: string, originalError: Error, duration?: number) {
    super(`📦❌ Asset Load Error:
\t- URL:    ${assetSource}
\t- Type:   ${assetType}
\t- Name:   ${assetName}
\t- Time:   ${duration !== undefined ? getDurationText(duration) : 'None'}
\t- Error:  ${originalError}`);
    this.assetType = assetType;
    this.assetName = assetName;
    this.assetSource = assetSource;
    this.originalError = originalError;
    this.duration = duration ?? null;
  }
}
