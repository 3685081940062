import { Store } from 'redux';

import { createGuruStoreListener } from '@gi/app-guru-slice';
import { LocalSettingsStoreListener } from '@gi/local-settings';
import { LocalSessionController } from '@gi/react-session';
import { ThunkServices } from '@gi/garden-platform-services';
import { createObjectivesStoreListener } from '@gi/objectives';

import { RootState } from './store';

const localSessionController = new LocalSessionController();

const attachStoreListeners = (store: Store<RootState>, services: ThunkServices) => {
  const localSettingsStoreListener = new LocalSettingsStoreListener(store);
  localSettingsStoreListener.startListening();
  localSessionController.init(store);

  const guruStoreListener = createGuruStoreListener(services);
  guruStoreListener.startListening(store);

  const objectivesStoreListener = createObjectivesStoreListener();
  objectivesStoreListener.startListening(store);
};

export default attachStoreListeners;
