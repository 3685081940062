import { NodeEvent, ShapeCollisionCheckFunctions } from '@gi/core-renderer';
import { Geometry } from '@gi/math';
import { metricDistanceUnits } from '@gi/units';
import { ShapeType } from '@gi/constants';

import ShapeNode, { ShapeNodeState } from './shape-node';
import { getLineTooltipText } from './tooltip-utils';
import { CachedLineOutline } from '../outline-utils';

// eslint-disable-next-line @typescript-eslint/ban-types
class LineShapeNode extends ShapeNode<{}, ShapeType.LINE> {
  type = 'LineShapeNode';

  #cachedOutline?: CachedLineOutline;

  constructor(id: number, state: ShapeNodeState['state']) {
    super(id, ShapeType.LINE, state);

    this.tooltip.state.values.offset = { x: 0, y: 20 };

    this.state.addWatcher(
      () => {
        this.#update();
      },
      { properties: ['point1', 'point2', 'point3', 'fill', 'stroke', 'strokeWidth'] },
      false
    );

    this.state.addWatcher(
      (_state) => {
        this.shape.collisionCheckFunction = ShapeCollisionCheckFunctions.Path(_state.values.strokeWidth / 2);
      },
      { properties: ['strokeWidth'] }
    );

    this.shape.state.values.closed = false;

    this.eventBus.on(NodeEvent.DidBind, this.#didBind);
  }

  #didBind = () => {
    this.#update();
  };

  #updateLine() {
    if (!this.graphics) {
      return;
    }

    const center = this.getCenter();
    const { point1, point2, point3, stroke, strokeWidth } = this.state.values;

    const finalStartPoint = Geometry.minusPoint(point1, center);
    const finalEndPoint = Geometry.minusPoint(point3, center);
    const finalControlPoint = point2 ? Geometry.minusPoint(point2, center) : null;

    this.graphics
      .clear()
      .lineStyle(strokeWidth, stroke ?? 0x000000, 1)
      .moveTo(finalStartPoint.x, finalStartPoint.y);

    if (finalControlPoint) {
      this.graphics.quadraticCurveTo(finalControlPoint.x, finalControlPoint.y, finalEndPoint.x, finalEndPoint.y);
    } else {
      this.graphics.lineTo(finalEndPoint.x, finalEndPoint.y);
    }
  }

  #updateShape() {
    const { point1, point2, point3 } = this.state.values;

    if (!this.#cachedOutline) {
      this.#cachedOutline = new CachedLineOutline(point1, point3, point2);
      this.shape.setPoints(this.#cachedOutline.path);
    } else if (this.#cachedOutline.update(point1, point3, point2)) {
      this.shape.setPoints(this.#cachedOutline.path);
    }

    this.outline.state.values.hollow = false;
  }

  #update() {
    this.#updateLine();
    this.#updateShape();
  }

  protected updateTooltip(): void {
    const { point1, point2, point3 } = this.state.values;
    const distanceUnits = this.planSettings?.getDistanceUnits() ?? metricDistanceUnits;
    this.tooltip.state.values.text = getLineTooltipText(point1, point2, point3, distanceUnits);
  }
}

export default LineShapeNode;
