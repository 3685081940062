import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { RuntimeConfig, loadRuntimeConfig as _loadRuntimeConfig } from '@gi/config';
import { LoadingState } from '@gi/constants';
import { AsyncOperation, getOverallLoadingState } from '@gi/utils';
import { errorReporterInstance } from '@gi/errors';

import { AppLoadingScreen } from './app-loading-screen';
import AppProvider from './app-provider';

interface iProps {
  children: ReactNode;
}

/**
 * This component is responsible for loading anything the app needs to begin functioning.
 * This includes the runtime config, which defines which client the app is for.
 *
 * The rest of the app will not render until everythign has been loaded.
 */
const AppLoader = ({ children }: iProps): JSX.Element => {
  const [runtimeConfigState, setRuntimeConfigState] = useState<AsyncOperation<RuntimeConfig>>({
    status: LoadingState.NONE,
  });

  /**
   * Loads the client-specific config file.
   */
  const loadRuntimeConfig = useCallback(() => {
    setRuntimeConfigState({ status: LoadingState.LOADING });
    _loadRuntimeConfig()
      .then((config) => {
        console.debug('✅ Successfully loaded runtime configuration');
        setRuntimeConfigState({ status: LoadingState.SUCCESS, value: { ...config } });
        errorReporterInstance.setClient({ ...config });
      })
      .catch((e) => {
        console.log('❌ Failed to load runtime configuration', e);
        setRuntimeConfigState({ status: LoadingState.ERROR, error: e });
      });
  }, []);

  /**
   * Loads everythign thew app needs.
   */
  const load = useCallback(() => {
    console.debug('⏳ Loading app requirements');
    loadRuntimeConfig();
  }, [loadRuntimeConfig]);

  /**
   * Keeps track of if the "app" is loading or not.
   * Does this by checking all required async operations and making sure they're all SUCCESS.
   * There was more, but there's only the runtimeConfig currently.
   */
  const loading = useMemo<LoadingState>(() => {
    const requiredAsyncOps = [runtimeConfigState.status];
    return getOverallLoadingState(requiredAsyncOps);
  }, [runtimeConfigState]);

  /**
   * Keeps track of all the failed async calls and returns them as a list of errors.
   */
  const errors = useMemo(() => {
    const newErrors: string[] = [];
    if (runtimeConfigState.status === LoadingState.ERROR) {
      newErrors.push('Failed to load runtime configuration file');
    }
    return newErrors;
  }, [runtimeConfigState]);

  /**
   * On first load, load everything.
   */
  useEffect(() => {
    if (loading === LoadingState.NONE) {
      load();
    }
  }, []);

  /**
   * If everything we need is loaded, we can render the provider.
   * Sadly, we have to manually check runtimeConfigState, as TS won't infer it as SUCCESS from `loading`
   */
  if (loading === LoadingState.SUCCESS && runtimeConfigState.status === LoadingState.SUCCESS) {
    return <AppProvider runtimeConfig={runtimeConfigState.value}>{children}</AppProvider>;
  }

  return <AppLoadingScreen loading={loading} errors={errors} />;
};

export default AppLoader;
