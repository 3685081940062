import { combineReducers } from 'redux';
import requestsReducer from '@gi/react-requests';
import { canvasReducer } from '@gi/react-garden-canvas';
import { notificationReducer } from '@gi/notifications';
import { localSettingsReducer, LocalSettings } from '@gi/local-settings';
import { publishReducer } from '@gi/react-plan-publisher';
import { transferPlanReducer } from '@gi/react-transfer-plan';
import { plannerSettingsReducer } from '@gi/react-planner-settings';
import { sessionReducer, SessionReducerState } from '@gi/react-session';
import { appSupportReducer } from '@gi/app-help-slice';
import { plannerReducer } from '@gi/app-planner-slice';
import { journalReducer } from '@gi/journal';
import { editPlantNotesReducer } from '@gi/edit-plant-notes-modal';
import { forecastReducer } from '@gi/forecast';
import { reminderReducer } from '@gi/reminders';
import { suggestedArticlesReducer } from '@gi/suggested-articles';
import { modalReducer } from '@gi/garden-platform-modal-renderer';
import { gardenPlatformReducer, GardenPlatformState } from '@gi/garden-platform-slice';
import { sidebarReducer } from '@gi/garden-platform-sidebar';
import { appAccountReducer } from '@gi/app-account-slice';
import { plantInformationModalReducer } from '@gi/plant-information-modal';
import { planImageReducer } from '@gi/react-plan-image';
import { mobileNavReducer } from '@gi/mobile-garden-platform-nav';
import { initParamsReducer } from '@gi/init-params';
import { flagRestartReducer } from '@gi/flag-restart';
import { navigationReducer } from '@gi/garden-platform-navigation';
import { openPlanModalReducer } from '@gi/open-plan-modal';
import { guruReducer } from '@gi/app-guru-slice';
import { displayModeReducer } from '@gi/display-mode-provider';
import { analyticsReducer } from '@gi/analytics';
import { intercomReducer } from '@gi/intercom';
import { ShoppingState, shoppingReducer } from '@gi/shopping-list';
import { ObjectivesState, objectivesReducer } from '@gi/objectives';

const reducer = combineReducers({
  session: sessionReducer,
  platform: gardenPlatformReducer,
  planner: plannerReducer,
  requests: requestsReducer,
  canvas: canvasReducer,
  sidebar: sidebarReducer,
  modal: modalReducer,
  forecast: forecastReducer,
  reminders: reminderReducer,
  journal: journalReducer,
  suggestedArticles: suggestedArticlesReducer,
  support: appSupportReducer,
  notifications: notificationReducer,
  localSettings: localSettingsReducer,
  publish: publishReducer,
  transferPlan: transferPlanReducer,
  plannerSettings: plannerSettingsReducer,
  editPlantNotes: editPlantNotesReducer,
  appAccount: appAccountReducer,
  plantInformationModal: plantInformationModalReducer,
  planImage: planImageReducer,
  mobileNav: mobileNavReducer,
  initParams: initParamsReducer,
  flagRestart: flagRestartReducer,
  navigation: navigationReducer,
  openPlanModal: openPlanModalReducer,
  guru: guruReducer,
  displayMode: displayModeReducer,
  analytics: analyticsReducer,
  intercom: intercomReducer,
  shopping: shoppingReducer,
  objectives: objectivesReducer,
});

export type FrontEndStore = {
  session: SessionReducerState;
  platform: GardenPlatformState;
  planner: any;
  requests: any;
  canvas: any;
  sidebar: any;
  modal: any;
  weather: any;
  reminders: any;
  journal: any;
  suggestedArticles: any;
  news: any;
  dashboard: any;
  support: any;
  notifications: any;
  localSettings: LocalSettings;
  publish: any;
  transferPlan: any;
  plannerSettings: any;
  shopping: ShoppingState;
  objectives: ObjectivesState;
};

export default reducer;
