import React, { ReactNode } from 'react';

import './mobile-nav-link-group.scss';

interface iProps {
  children: ReactNode;
  toBottom?: boolean;
}

const MobileNavigationLinkGroup = ({ children, toBottom = false }: iProps): JSX.Element => {
  const classNames = ['mobile-navigation-links'];
  if (toBottom) {
    classNames.push('to-bottom');
  }
  return <ul className={classNames.join(' ')}>{children}</ul>;
};

export default MobileNavigationLinkGroup;
