import { Container, Texture } from 'pixi.js-new';

import { State, StateDef } from '@gi/state';

import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { bindState } from '../../utils/state-utils';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { TilingSprite } from '../../extensions/tiling-sprite/TilingSprite';

type NineSliceData<T> = {
  TL: T;
  T: T;
  TR: T;
  L: T;
  M: T;
  R: T;
  BL: T;
  B: T;
  BR: T;
};

const NINE_SLICE_PARTS: Readonly<(keyof NineSliceData<any>)[]> = ['TL', 'T', 'TR', 'L', 'M', 'R', 'BL', 'B', 'BR'] as const;

type NineSliceTextures = NineSliceData<Texture>;

type NineSliceSpriteComponentState = StateDef<{
  width: number;
  height: number;
  rotation: number;
  marginWidth: number;
  marginHeight: number;
  spriteWidth: number;
  spriteHeight: number;
}>;

const DEFAULT_STATE: NineSliceSpriteComponentState['state'] = {
  width: 100,
  height: 100,
  rotation: 0,
  marginWidth: 10,
  marginHeight: 10,
  spriteWidth: 100,
  spriteHeight: 100,
};

class NineSliceSpriteComponent extends NodeComponent {
  type = 'NineSliceSpriteComponent';

  state: State<NineSliceSpriteComponentState>;
  #container: Container;

  #textures: Partial<NineSliceTextures> = {};
  #sprites: NineSliceData<TilingSprite> | null = null;

  constructor(state?: Partial<NineSliceSpriteComponentState['state']>) {
    super();

    this.#container = new Container();
    this.#container.pivot.set(0.5, 0.5);

    this.state = new State<NineSliceSpriteComponentState>({
      ...DEFAULT_STATE,
      ...state,
    });
    bindState(this.state, this);
    this.state.addWatcher(() => this.#update());

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
    this.eventBus.on(NodeComponentEvent.Destroyed, this.#onDestroy);
  }

  #onBind = () => {
    this.#sprites = {
      TL: new TilingSprite(this.textures.TL ?? Texture.EMPTY),
      T: new TilingSprite(this.textures.T ?? Texture.EMPTY),
      TR: new TilingSprite(this.textures.TR ?? Texture.EMPTY),
      L: new TilingSprite(this.textures.L ?? Texture.EMPTY),
      M: new TilingSprite(this.textures.M ?? Texture.EMPTY),
      R: new TilingSprite(this.textures.R ?? Texture.EMPTY),
      BL: new TilingSprite(this.textures.BL ?? Texture.EMPTY),
      B: new TilingSprite(this.textures.B ?? Texture.EMPTY),
      BR: new TilingSprite(this.textures.BR ?? Texture.EMPTY),
    };
    this.#container.addChild(
      this.#sprites.TL,
      this.#sprites.T,
      this.#sprites.TR,
      this.#sprites.L,
      this.#sprites.M,
      this.#sprites.R,
      this.#sprites.BL,
      this.#sprites.B,
      this.#sprites.BR
    );
    this.#update();
  };

  #onBeforeUnbind = () => {
    const sprites = this.#sprites;
    if (!sprites) {
      return;
    }
    NINE_SLICE_PARTS.forEach((part) => sprites[part].destroy());
    this.#sprites = null;
  };

  #onDestroy = () => {
    this.#container.destroy();
  };

  get textures() {
    return this.#textures;
  }
  set textures(textures: Partial<NineSliceTextures>) {
    this.#textures = textures;
    this.#updateTextures();
  }

  getContainer() {
    return this.#container;
  }

  #updateTextures() {
    const sprites = this.#sprites;
    if (!sprites) {
      return;
    }

    NINE_SLICE_PARTS.forEach((part) => {
      const sprite = sprites[part];
      const texture = this.textures[part];
      sprite.texture = texture ?? Texture.EMPTY;
      sprite.visible = texture !== undefined;
    });

    // We need to update fully, as tileScale is based off the texture width/height
    this.#update();
  }

  #update() {
    const sprites = this.#sprites;
    if (!sprites) {
      return;
    }

    const { width, height, rotation, marginWidth, marginHeight, spriteWidth, spriteHeight } = this.state.values;

    NINE_SLICE_PARTS.forEach((part, i) => {
      const sprite = sprites[part];

      const column = i % 3;
      const row = Math.floor(i / 3);

      let x = -width / 2;
      let y = -height / 2;
      let w = marginWidth;
      let h = marginHeight;
      let tileScaleX = marginWidth / sprite.texture.width;
      let tileScaleY = marginHeight / sprite.texture.height;

      if (column === 1) {
        // Horizontally centered segments
        x = -width / 2 + marginWidth;
        w = width - marginWidth * 2;
        tileScaleX = spriteWidth / sprite.texture.width;
      } else if (column === 2) {
        // Rightmost segments
        x = width / 2 - marginWidth;
      }

      if (row === 1) {
        // Vertically centered segments
        y = -height / 2 + marginHeight;
        h = height - marginHeight * 2;
        tileScaleY = spriteHeight / sprite.texture.height;
      } else if (row === 2) {
        // Bottom segments
        y = height / 2 - marginHeight;
      }

      sprite.position = { x, y };
      sprite.width = w;
      sprite.height = h;
      sprite.tileScale = { x: tileScaleX, y: tileScaleY };
      sprite.clampMargin = column === 1 || row === 1 ? 0.5 : 0;
    });

    this.#container.rotation = rotation;
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default NineSliceSpriteComponent;
