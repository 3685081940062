import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { ResourceContext } from '@gi/resource-provider';
import { RequestSelectors } from '@gi/react-requests';

import { ReminderActionCreators, ReminderSelectors } from './reminder-slice';
import ReminderModal, { ReminderModalMode } from './reminder-modal';
import { ReminderData } from './reminder';
import * as ReminderNetworkActionCreators from './reminder-network-action-creators';
import * as ReminderUtils from './reminder-utils';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

const ReminderModalRenderer = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const { editingReminder, addingReminder } = useSelector(ReminderSelectors.getReminderState);
  const { userCountry } = useContext(ResourceContext);
  const requests = useSelector(RequestSelectors.getRequests);

  const requestInProgress =
    (editingReminder &&
      RequestsUtils.getStatus(requests, ReminderNetworkActionCreators.ReminderRequestNames.UpdateReminder(editingReminder.ID)) === RequestStatus.IN_PROGRESS) ||
    RequestsUtils.getStatus(requests, ReminderNetworkActionCreators.ReminderRequestNames.CreateReminder()) === RequestStatus.IN_PROGRESS ||
    RequestsUtils.getStatus(requests, ReminderNetworkActionCreators.ReminderRequestNames.LoadDashboardReminders()) === RequestStatus.IN_PROGRESS;

  useEffect(() => {
    // If request is no longer in progress but editing modal is still open, close editing modal
    if (savingInProgress && !requestInProgress && editingReminder) {
      dispatch(ReminderActionCreators.closeEditReminder());
      setSavingInProgress(false);
    }

    // If request is no longer in progress but adding modal is still open, close editing modal
    if (savingInProgress && !requestInProgress && addingReminder) {
      dispatch(ReminderActionCreators.closeAddReminder());
      setSavingInProgress(false);
    }
  }, [requestInProgress, savingInProgress, editingReminder, addingReminder]);

  const newReminderData = useMemo(() => {
    // Recreate new reminder data when the 'adding reminder' modal is opened
    return ReminderUtils.createReminderData();
  }, [addingReminder]);

  if (editingReminder) {
    return (
      <ReminderModal
        reminder={editingReminder}
        close={() => {
          dispatch(ReminderActionCreators.closeEditReminder());
        }}
        save={(updatedReminder: ReminderData) => {
          setSavingInProgress(true);
          // The API doesn't support editing dates, so date changes have to be handled with a recreation
          if (editingReminder.dateYMD !== updatedReminder.dateYMD) {
            dispatch(
              ReminderNetworkActionCreators.recreateReminder({
                ...editingReminder,
                ...updatedReminder,
              })
            );
          } else {
            dispatch(
              ReminderNetworkActionCreators.updateReminder({
                ...editingReminder,
                ...updatedReminder,
              })
            );
          }
        }}
        saving={requestInProgress}
        mode={ReminderModalMode.Edit}
        longDateFormat={userCountry ? userCountry.longDateFormat : DEFAULT_DATE_FORMAT}
      />
    );
  }

  if (addingReminder) {
    return (
      <ReminderModal
        reminder={newReminderData}
        close={() => {
          dispatch(ReminderActionCreators.closeAddReminder());
        }}
        save={(newReminder: ReminderData) => {
          setSavingInProgress(true);
          dispatch(ReminderNetworkActionCreators.createReminder(newReminder));
        }}
        saving={requestInProgress}
        mode={ReminderModalMode.Add}
        longDateFormat={userCountry ? userCountry.longDateFormat : DEFAULT_DATE_FORMAT}
      />
    );
  }

  return null;
};

export default ReminderModalRenderer;
