import React from 'react';

import styles from './guru-content-container.module.css';

interface iProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
}

/**
 * A Wrapper for all content used in Garden Guru
 *
 * This should include all guides, articles and most list pages.
 */
const GuruContentContainer = ({ children, className, ...props }: iProps): JSX.Element => {
  return (
    <article className={`${styles.guruContentContainer} ${className}`} {...props}>
      {children}
    </article>
  );
};

export default GuruContentContainer;
