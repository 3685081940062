import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import { SessionSelectors } from '@gi/react-session';
import { CanvasActionCreators } from '@gi/react-garden-canvas';
import { Application, NavigationActionCreators } from '@gi/garden-platform-navigation';

import RecentPlansListItem from './recent-plans-list-item';

import './recent-plans-section.scss';

const RECENT_PLAN_COUNT = 3;

const RecentPlansSection = (): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(SessionSelectors.getUser);
  const usePlans = user === null ? [] : user.plans.list;

  const openPlan = (planID: number) => {
    dispatch(batchActions([CanvasActionCreators.openPlan(planID), NavigationActionCreators.navigateTo({ application: Application.GardenPlanner })]));
  };

  const recentPlans = useMemo(() => {
    return usePlans
      .sort((a, b) => {
        return -a.modified.diff(b.modified);
      })
      .slice(0, RECENT_PLAN_COUNT);
  }, [usePlans]);

  let recentPlansMessage: JSX.Element | null = null;

  if (recentPlans.length === 0) {
    recentPlansMessage = (
      <div className='dashboard-block padded background'>
        <div>
          You have not yet created any plans,{' '}
          <button type='button' className='button button-inline'>
            create a new plan
          </button>
          .
        </div>
      </div>
    );
  }

  return (
    <div className='recent-plans-section'>
      <div className='recent-plans-list'>
        {recentPlans.map((userPlan) => (
          <RecentPlansListItem
            key={userPlan.ID}
            plan={userPlan}
            open={() => {
              openPlan(userPlan.ID);
            }}
          />
        ))}
        {recentPlansMessage}
      </div>
    </div>
  );
};

export default RecentPlansSection;
