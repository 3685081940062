import React from 'react';
import Plant from '@gi/plant';
import { useCompanions, CompanionType } from '@gi/companion-plants';

import PlantCompanionsRow from './plant-companions-row';
import ItemContentCell from '../../../components/item-content-table/item-content-cell';
import ItemContentRow from '../../../components/item-content-table/item-content-row';
import ItemContentCellHeader from '../../../components/item-content-table/item-content-cell-header';

interface iCompanionsProps {
  plant: Plant;
}

const PlantCompanionsSection = ({ plant }: iCompanionsProps): JSX.Element | null => {
  const { companionOfPlants, companionPlants, mutualBenefitPlants } = useCompanions(plant);

  if (companionPlants.length === 0 && companionOfPlants.length === 0 && mutualBenefitPlants.length === 0) {
    return null;
  }

  return (
    <>
      <ItemContentRow>
        <ItemContentCellHeader>Companion Planting</ItemContentCellHeader>
        <ItemContentCell>{null}</ItemContentCell>
      </ItemContentRow>
      <PlantCompanionsRow plant={plant} companionPlants={mutualBenefitPlants} companionType={CompanionType.Mutual} />
      <PlantCompanionsRow plant={plant} companionPlants={companionPlants} companionType={CompanionType.HelpedBy} />
      <PlantCompanionsRow plant={plant} companionPlants={companionOfPlants} companionType={CompanionType.Helps} />
    </>
  );
};

export default PlantCompanionsSection;
