import { GraphicNode } from '@gi/core-renderer';

const LAYER_DISABLED_ALPHA = 0.2;

export enum PlannerLayerDisplayMode {
  EDITABLE = 'EDITABLE',
  VIEW_ONLY = 'VIEW_ONLY',
  DISABLED = 'DISABLED',
}

class PlannerLayerNode extends GraphicNode {
  type = 'PlannerLayerNode';

  #displayMode: PlannerLayerDisplayMode = PlannerLayerDisplayMode.EDITABLE;
  get displayMode() {
    return this.#displayMode;
  }

  constructor(layerName: string, zIndex: number) {
    super();
    this.name = layerName;
    this.zIndex = zIndex;
  }

  setDisplayMode(displayMode: PlannerLayerDisplayMode) {
    this.#displayMode = displayMode;
    const container = this.getContainer();
    container.interactiveChildren = displayMode === PlannerLayerDisplayMode.EDITABLE;
    container.eventMode = displayMode === PlannerLayerDisplayMode.EDITABLE ? 'auto' : 'none';
    container.alpha = displayMode === PlannerLayerDisplayMode.DISABLED ? LAYER_DISABLED_ALPHA : 1;
  }
}

export default PlannerLayerNode;
