import { Container, DisplayObject, MaskData, Texture } from 'pixi.js-new';

import GraphicNode from '../../graphics-node';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { InspectableClassData } from '../../types';
import { hasEngine } from '../../utils/asserts';
import { TilingSprite } from '../../extensions/tiling-sprite/TilingSprite';

class TilingSpriteComponent extends NodeComponent<GraphicNode> {
  type = 'TilingSpriteComponent';

  constructor(texture?: Texture) {
    super();
    this.#texture = texture ?? null;

    // Attach sprite when we have a parent
    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);
    this.#syncSprite();
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    if (this.#sprite) {
      this.#sprite.destroy();
      this.#sprite = null;
    }
  };

  #syncSprite() {
    if (this.texture === null) {
      if (this.#sprite !== null) {
        this.#sprite.destroy();
        this.#sprite = null;
      }
    } else {
      if (this.#sprite === null) {
        this.#sprite = new TilingSprite(this.texture, this.width, this.height);
        this.owner?.ownGraphics.addChild(this.#sprite);
      }
      this.#sprite.texture = this.texture;
      this.#sprite.anchor.set(0.5, 0.5);
      this.#sprite.width = this.width;
      this.#sprite.height = this.height;
      this.#sprite.rotation = this.rotation;
      this.#sprite.tileScale.x = this.tileWidth / this.texture.width;
      this.#sprite.tileScale.y = this.tileHeight / this.texture.height;
      this.#sprite.mask = this.mask;
      this.#sprite.visible = this.visible;
    }
  }

  #sprite: TilingSprite | null = null;
  get sprite() {
    return this.#sprite;
  }

  #width: number = 10;
  get width() {
    return this.#width;
  }
  set width(width: number) {
    this.#width = width;
    if (this.#sprite) {
      this.#sprite.width = width;
    }
  }

  #height: number = 10;
  get height() {
    return this.#height;
  }
  set height(height: number) {
    this.#height = height;
    if (this.#sprite) {
      this.#sprite.height = height;
    }
  }

  #rotation: number = 0;
  get rotation() {
    return this.#rotation;
  }
  set rotation(rotation: number) {
    this.#rotation = rotation;
    if (this.#sprite) {
      this.#sprite.rotation = rotation;
    }
  }

  #texture: Texture | null = null;
  get texture() {
    return this.#texture;
  }
  set texture(texture: Texture | null) {
    this.#texture = texture;
    this.#syncSprite();
  }

  #tileWidth: number = 50;
  get tileWidth() {
    return this.#tileWidth;
  }
  set tileWidth(width: number) {
    this.#tileWidth = width;
    if (this.#sprite && this.#texture) {
      this.#sprite.tileScale.x = width / this.#texture.width;
    }
  }

  #tileHeight: number = 50;
  get tileHeight() {
    return this.#tileHeight;
  }
  set tileHeight(height: number) {
    this.#tileHeight = height;
    if (this.#sprite && this.#texture) {
      this.#sprite.tileScale.y = height / this.#texture.height;
    }
  }

  #mask: Container<DisplayObject> | MaskData | null = null;
  get mask() {
    return this.#mask;
  }
  set mask(mask: Container<DisplayObject> | MaskData | null) {
    this.#mask = mask;
    if (this.#sprite) {
      this.#sprite.mask = mask;
    }
  }

  #visible: boolean = true;
  get visible() {
    return this.#visible;
  }
  set visible(visible: boolean) {
    this.#visible = visible;
    if (this.#sprite) {
      this.#sprite.visible = visible;
    }
  }

  inspectorData: InspectableClassData<this> = [];
}

export default TilingSpriteComponent;
