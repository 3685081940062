const userEventErrorCodes = {
  400: 'Error saving analytics: server error (400)',
  401: 'Error saving analytics: server error (401)',
  403: 'Error saving analytics: server error (403)',
  404: 'Error saving analytics: server error (404)',
};

const userVideoEventErrorCodes = {
  400: 'Error saving video analytics: server error (400)',
  401: 'Error saving video analytics: server error (401)',
  403: 'Error saving video analytics: server error (403)',
  404: 'Error saving video analytics: server error (404)',
};

const attachUserEventErrorClientMessage = (responseError) => {
  if (userEventErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = userEventErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown user analytics error - ${responseError.statusCode}`;
  }
};

const attachUserVideoEventErrorClientMessage = (responseError) => {
  if (userVideoEventErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = userVideoEventErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown user video analytics error - ${responseError.statusCode}`;
  }
};

export { attachUserEventErrorClientMessage, attachUserVideoEventErrorClientMessage };
