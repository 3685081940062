import { EventBus } from '@gi/core-renderer';
import { PlanUtils } from '@gi/plan';
import { GardenItemType } from '@gi/constants';

import { CanvasPlan } from './canvas-plan/canvas-plan';
import { SimulationFactory } from './synced-plan/simulation-factory';
import CanvasInteractionGroup from './canvas-interface/canvas-interaction-group';
import { SimulatedTextClipboardData } from './simulation/simulated-text';
import { SimulatedPlantClipboardData } from './simulation/simulated-plant';
import { SimulatedShapeClipboardData } from './simulation/simulated-shape';
import { SimulatedGardenObjectClipboardData } from './simulation/simulated-garden-object';
import { SimulatedGardenItem, SimulatedGardenItemClipboardData, SimulatedGardenItemEffect } from './simulation/simulated-garden-item';

const PASTE_OFFSET: Vector2 = { x: 10, y: 10 };

export enum GardenCanvasClipboardControllerEvent {
  ClipboardUpdate = 'ClipboardUpdate',
}

type GardenCanvasClipboardEventActions = {
  [GardenCanvasClipboardControllerEvent.ClipboardUpdate]: (clipboardData: SimulatedGardenItemClipboardData[]) => void;
};

class GardenCanvasClipboardController extends EventBus<GardenCanvasClipboardEventActions> {
  #simulationFactory: SimulationFactory;

  #pasteCount: number = 0;
  #clipboardData: SimulatedGardenItemClipboardData[] = [];
  get clipboardData() {
    return this.#clipboardData;
  }

  get hasClipboard() {
    return this.#clipboardData.length > 0;
  }

  constructor(simulationFactory: SimulationFactory) {
    super();
    this.#simulationFactory = simulationFactory;
  }

  // eslint-disable-next-line class-methods-use-this
  #getClipboardData(canvasPlan: CanvasPlan, perItemCallback?: (itemId: number, item: SimulatedGardenItem | null) => void): SimulatedGardenItemClipboardData[] {
    const { simulatedPlan } = canvasPlan;
    const { selection } = canvasPlan.selectionContext;

    // If there's nothing in the selection, fail early.
    if (!selection || selection.length === 0) {
      return [];
    }

    const selectionGroup = new CanvasInteractionGroup([...selection]);

    // If there's no item IDs in the selection (somehow), fail early.
    if (selectionGroup.itemIds.size === 0) {
      return [];
    }

    const itemIds = Array.from(selectionGroup.itemIds);
    const itemData: SimulatedGardenItemClipboardData[] = [];

    for (let i = 0; i < itemIds.length; i++) {
      const itemId = itemIds[i];
      const itemType = simulatedPlan.itemTypes[itemId];
      const item = simulatedPlan.getItem(itemId, itemType);

      if (item) {
        itemData.push(item.getClipboardData());
      }

      if (perItemCallback) {
        perItemCallback(itemId, item);
      }
    }

    return itemData;
  }

  #emitUpdate() {
    this.emit(GardenCanvasClipboardControllerEvent.ClipboardUpdate, this.clipboardData);
  }

  #offsetVector(vector: Vector2): Vector2;
  #offsetVector(vector: Vector2 | null): Vector2 | null;
  #offsetVector(vector: Vector2 | null): Vector2 | null {
    if (vector === null) {
      return null;
    }

    return {
      x: vector.x + PASTE_OFFSET.x * (this.#pasteCount + 1),
      y: vector.y + PASTE_OFFSET.y * (this.#pasteCount + 1),
    };
  }

  cut(canvasPlan: CanvasPlan) {
    const { simulatedPlan } = canvasPlan;
    const clipboardData = this.#getClipboardData(canvasPlan, (itemId) => simulatedPlan.removeItem(itemId));

    if (clipboardData.length === 0) {
      return;
    }

    this.#clipboardData = clipboardData;
    this.#pasteCount = 0;

    canvasPlan.simulatedPlan.signalPushUpdates();
    this.#emitUpdate();
  }

  copy(canvasPlan: CanvasPlan) {
    const clipboardData = this.#getClipboardData(canvasPlan);

    if (clipboardData.length === 0) {
      return;
    }

    this.#clipboardData = clipboardData;
    this.#pasteCount = 0;

    this.#emitUpdate();
  }

  paste(canvasPlan: CanvasPlan) {
    if (!this.hasClipboard) {
      return;
    }

    const { simulatedPlan } = canvasPlan;

    canvasPlan.selectionContext.clearSelection();

    for (let i = 0; i < this.clipboardData.length; i++) {
      const item = this.clipboardData[i];

      switch (item.type) {
        case GardenItemType.Plant: {
          const data = item.data as SimulatedPlantClipboardData;
          const plant = this.#simulationFactory.createSimulatedPlant(
            PlanUtils.createNewPlanPlant(
              data.plant.code,
              this.#offsetVector(data.rowStart),
              this.#offsetVector(data.rowEnd),
              data.height,
              data.showLabel,
              data.labelOffset,
              data.labelText,
              data.isSquareFoot,
              data.variety,
              data.inGroundStart,
              data.inGroundEnd,
              data.inGroundAll,
              [],
              data.locked,
              data.zIndex,
              simulatedPlan.nextItemId
            )
          );
          plant.addEffect(SimulatedGardenItemEffect.JustPasted);
          simulatedPlan.addPlant(plant);
          break;
        }
        case GardenItemType.GardenObject: {
          const data = item.data as SimulatedGardenObjectClipboardData;
          const gardenObject = this.#simulationFactory.createSimulatedGardenObject(
            PlanUtils.createNewPlanGardenObject(
              data.gardenObject.code,
              this.#offsetVector(data.start),
              this.#offsetVector(data.mid),
              this.#offsetVector(data.end),
              data.rotation,
              data.locked,
              data.zIndex,
              simulatedPlan.nextItemId
            )
          );
          gardenObject.addEffect(SimulatedGardenItemEffect.JustPasted);
          simulatedPlan.addGardenObject(gardenObject);
          break;
        }
        case GardenItemType.Shape: {
          const data = item.data as SimulatedShapeClipboardData;
          const shape = this.#simulationFactory.createSimulatedShape(
            PlanUtils.createNewPlanShape(
              data.shapeType,
              this.#offsetVector(data.point1),
              this.#offsetVector(data.point2),
              this.#offsetVector(data.point3),
              data.rotation,
              data.fill,
              data.texture,
              data.stroke,
              data.strokeWidth,
              data.closed,
              data.locked,
              data.zIndex,
              simulatedPlan.nextItemId
            )
          );
          shape.addEffect(SimulatedGardenItemEffect.JustPasted);
          simulatedPlan.addShape(shape);
          break;
        }
        case GardenItemType.Text: {
          const data = item.data as SimulatedTextClipboardData;
          const text = this.#simulationFactory.createSimulatedText(
            PlanUtils.createNewPlanText(
              data.text,
              data.fontSize,
              data.colour,
              this.#offsetVector(data.start),
              this.#offsetVector(data.end),
              data.rotation,
              data.locked,
              data.zIndex,
              simulatedPlan.nextItemId
            )
          );
          text.addEffect(SimulatedGardenItemEffect.JustPasted);
          simulatedPlan.addText(text);
          break;
        }
        default: {
          // Do nothing
        }
      }
    }

    simulatedPlan.signalPushUpdates();
    this.#pasteCount++;
  }
}

export default GardenCanvasClipboardController;
