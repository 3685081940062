import { Filter } from '@gi/filters';
import GardenObject, { GardenObjectUtils } from '@gi/garden-object';

type GardenObjectTypeFilterState = {
  clientId: number | null;
};

const gardenObjectClientRestrictionFilter: Filter<GardenObject, GardenObjectTypeFilterState> = {
  name: 'Client Restrictions',
  inputs: { clientId: null },
  createListFilter: ({ clientId }) => {
    return (objectList) => {
      if (clientId === null) {
        return objectList;
      }
      return objectList.filter((gardenObject) => {
        return !GardenObjectUtils.isRestrictedFromClient(gardenObject, clientId);
      });
    };
  },
};

export default gardenObjectClientRestrictionFilter;
