import GardenObject, { GardenObjectGroup } from '@gi/garden-object';

/**
 * Converts a string from the Texture/Swatch Picker to a number to be used within GardenCanvas
 */
export const colorFromString = (colorString: null | string): number | null => {
  if (colorString === null) {
    return null;
  }

  if (colorString.length !== 6) {
    throw new Error('invalid color');
  }

  return parseInt(colorString, 16);
};

export type DrawingToolsListGardenObjectEntry = {
  type: 'garden-object';
  gardenObject: GardenObject;
};

export type DrawingToolsListGardenObjectGroupEntry = {
  type: 'group';
  group: GardenObjectGroup;
  expanded: boolean;
  gardenObjects: GardenObject[];
};

export type DrawingToolsListGardenObjectGroupItemEntry = {
  type: 'grouped-garden-object';
  gardenObject: GardenObject;
  isFirst: boolean;
  isLast: boolean;
};

export type DrawingToolsGardenObjectListEntry =
  | DrawingToolsListGardenObjectEntry
  | DrawingToolsListGardenObjectGroupEntry
  | DrawingToolsListGardenObjectGroupItemEntry;
