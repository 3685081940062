import React, { useMemo } from 'react';

import { getLoadingStateIcon } from '@gi/utils';
import { LoadingState } from '@gi/constants';

import styles from './garden-canvas-loading-entry.module.css';

interface iProps {
  label: string;
  status: LoadingState;
  progress?: number;
  retry?: () => void;
}

const GardenCanvasLoadingEntry = ({ label, status, progress, retry }: iProps): JSX.Element | null => {
  const retryButton = useMemo(() => {
    if (!retry || (status !== LoadingState.ERROR && status !== LoadingState.NONE)) {
      return null;
    }
    return (
      <span className={styles.retry}>
        <button type='button' className='button button-inline' onClick={retry}>
          Retry
        </button>
      </span>
    );
  }, [retry, status]);

  const progressBar = useMemo(() => {
    if (progress === undefined || status !== LoadingState.LOADING) {
      return null;
    }
    return (
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar}>
          <div className={styles.progressBarInner} style={{ width: `${progress * 100}%` }} />
        </div>
        <span className={styles.progressPercent}>{Math.floor(progress * 100)}%</span>
      </div>
    );
  }, [status, progress]);

  if (status === LoadingState.NONE || status === LoadingState.SUCCESS) {
    return null;
  }

  return (
    <div className={styles.loadingEntry}>
      <div className={styles.top}>
        <span className={styles.label}>{label}</span>
        <span className={styles.dots} />
        {retryButton}
        <span className={styles.icon}>{getLoadingStateIcon(status)}</span>
      </div>
      <div className={styles.bottom}>{progressBar}</div>
      {status === LoadingState.ERROR ? <div className={styles.error}>Failed to load</div> : null}
    </div>
  );
};

export default GardenCanvasLoadingEntry;
