import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';

import { CompletedObjectiveFromAPI, ObjectiveDataFromAPI } from './objective-types';

export class ObjectivesService {
  private jsonEndpoint: string;
  private apiEndpoint: string;

  /**
   * Creates a new objectives service, suing the 2 given URLs as endpoints.
   *
   * Ideally, these endpoints would be the same. But during development at least, these differ.
   * @param jsonEndpoint Endpoint where the json defining the objectives is
   * @param apiEndpoint Endpoint for setting completed objectives
   */
  constructor(jsonEndpoint: string, apiEndpoint: string) {
    this.jsonEndpoint = jsonEndpoint;
    this.apiEndpoint = apiEndpoint;
  }

  getObjectives(): Promise<ObjectiveDataFromAPI> {
    const url = this.jsonEndpoint;
    return networkService
      .get<ObjectiveDataFromAPI>(url, {}, RequestAuthMode.None)
      .then((response) => response.body)
      .catch(networkErrorReporter('GET', 'objectives'));
  }

  getCompletedObjectives(): Promise<CompletedObjectiveFromAPI[]> {
    if (!networkService.userAuth) {
      throw new Error('User is not authenticated with network service');
    }

    const url = `${this.apiEndpoint}/gardenplanner/api/v1/User/${networkService.userAuth.id}/objectives`;

    return networkService
      .get<CompletedObjectiveFromAPI[]>(url, {}, RequestAuthMode.Bearer)
      .then((response) => response.body)
      .catch(networkErrorReporter('GET', 'completed-objectives'));
  }

  postCompletedObjectives(objectives: CompletedObjectiveFromAPI[]): Promise<CompletedObjectiveFromAPI[]> {
    if (!networkService.userAuth) {
      throw new Error('User is not authenticated with network service');
    }

    const url = `${this.apiEndpoint}/gardenplanner/api/v1/User/${networkService.userAuth.id}/objectives`;

    return networkService
      .post<CompletedObjectiveFromAPI[]>(url, {}, objectives, RequestAuthMode.Bearer)
      .then((response) => response.body)
      .catch(networkErrorReporter('POST', 'completed-objectives'));
  }
}
