import React, { useContext } from 'react';
import { TutorialEditorContext } from '../tutorial-editor-context';
import Item from './item';

import styles from './tutorial-selection-list.module.css';

const TutorialSelectionList = (): JSX.Element | null => {
  const { editingTutorials, selectedTutorial, setSelectedTutorial } = useContext(TutorialEditorContext);

  if (editingTutorials.tutorials.length === 0) {
    return null;
  }

  return (
    <ul className={styles.tutorialSelectionList}>
      {editingTutorials.tutorials.map((tutorial) => (
        <Item key={tutorial.uuid} tutorial={tutorial} selected={tutorial === selectedTutorial} onSelect={setSelectedTutorial} />
      ))}
    </ul>
  );
};

export default TutorialSelectionList;
