import React, { ReactNode } from 'react';

import './form-tab-button.scss';

interface iProps {
  children: ReactNode;
  active?: boolean;
  className?: string;
  onClick: () => void;
}

const FormTabButton = ({ children, active = false, className, onClick }: iProps): JSX.Element => {
  const classNames = ['rforms-tab-button'];
  if (className) {
    classNames.push(className);
  }
  if (active) {
    classNames.push('active');
  }

  return (
    <button className={classNames.join(' ')} type='button' onClick={() => onClick()}>
      {children}
    </button>
  );
};

export default FormTabButton;
