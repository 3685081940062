/**
 * @typedef {string} PlantingCalendarRow
 */

/**
 * Enum for the different types of planting calendar row
 *
 * @readonly
 * @enum {PlantingCalendarRow}
 */
export enum PlantingCalendarRow {
  SOW = 'SOW',
  PLANT = 'PLANT',
  HARVEST = 'HARVEST',
}
