import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { NavigationContext } from '@gi/garden-platform-navigation';

import { GardenPlatformEventsActionCreators } from '../garden-platform-events-middleware';
import { GardenPlatformEvent } from '../types';

const useNavigationChangeEventHook = () => {
  const dispatch = useDispatch();
  const { activeApplication } = useContext(NavigationContext);

  useEffect(() => {
    if (activeApplication !== null) {
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.OpenApplication, { application: activeApplication }));
    }
  }, [activeApplication]);
};

export default useNavigationChangeEventHook;
