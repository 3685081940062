import { AssetComponent, AssetComponentEvents, AssetType, NodeEvent, ShapeCollisionCheckFunctions, TilingSpriteComponent } from '@gi/core-renderer';
import { metricDistanceUnits } from '@gi/units';
import { ShapeType } from '@gi/constants';

import ShapeNode, { ShapeNodeState } from './shape-node';
import { getEllipseTooltipText } from './tooltip-utils';
import { CachedEllipseOutline } from '../outline-utils';

// eslint-disable-next-line @typescript-eslint/ban-types
class EllipseShapeNode extends ShapeNode<{}, ShapeType.ELLIPSE> {
  type = 'EllipseShapeNode';

  #texture: AssetComponent<AssetType.TEXTURE>;
  #sprite: TilingSpriteComponent;

  #cachedOutline?: CachedEllipseOutline;

  constructor(id: number, state: ShapeNodeState['state']) {
    super(id, ShapeType.ELLIPSE, state);

    this.state.addWatcher(
      () => {
        this.#update();
      },
      { properties: ['point1', 'point3', 'fill', 'stroke', 'strokeWidth'] },
      false
    );

    this.#sprite = this.components.add(new TilingSpriteComponent());

    this.#texture = this.components.add(new AssetComponent(AssetType.TEXTURE));
    this.#texture.eventBus.on(AssetComponentEvents.Loaded, (asset) => {
      this.#sprite.texture = asset;
    });

    this.state.addWatcher(
      (_state) => {
        this.#texture.assetName = _state.values.texture;
      },
      { properties: ['texture'] }
    );

    this.state.addWatcher(
      (_state) => {
        this.shape.collisionCheckFunction =
          _state.values.stroke !== null
            ? ShapeCollisionCheckFunctions.HollowConvexHull(_state.values.strokeWidth / 2)
            : ShapeCollisionCheckFunctions.ConvexHull;
      },
      { properties: ['stroke', 'strokeWidth'] }
    );

    this.eventBus.on(NodeEvent.DidBind, this.#didBind);
  }

  #didBind = () => {
    if (this.state.values.texture !== null) {
      this.#texture.assetName = this.state.values.texture;
    }
    this.#update();
  };

  setPosition(center: Vector2, dimensions: Dimensions, rotation: number) {
    this.state.values.point1 = {
      x: center.x - dimensions.width / 2,
      y: center.y - dimensions.height / 2,
    };
    this.state.values.point3 = {
      x: center.x + dimensions.width / 2,
      y: center.y + dimensions.height / 2,
    };
    this.state.values.rotation = rotation;
  }

  #updateEllipse() {
    if (!this.graphics) {
      return;
    }

    const dimensions = this.getDimensions();
    const { fill, stroke, strokeWidth, texture } = this.state.values;

    if (fill !== null || texture !== null) {
      this.graphics
        .clear()
        .beginFill(fill ?? 0xffffff, 1)
        .lineStyle(0)
        .drawEllipse(0, 0, dimensions.width / 2, dimensions.height / 2)
        .endFill();
    } else {
      this.graphics
        .clear()
        .beginFill(0x000000, 0)
        .lineStyle(strokeWidth, stroke ?? 0x000000, 1)
        .drawEllipse(0, 0, dimensions.width / 2, dimensions.height / 2)
        .endFill();
    }
  }

  #updateSprite() {
    const { texture } = this.state.values;
    const { width, height } = this.getDimensions();
    this.#sprite.width = width;
    this.#sprite.height = height;
    this.#sprite.mask = texture === null ? null : this.graphics;
    this.#sprite.visible = texture !== null;
  }

  #updateShape() {
    const { width, height } = this.getDimensions();
    const { stroke } = this.state.values;

    if (!this.#cachedOutline) {
      this.#cachedOutline = new CachedEllipseOutline(width, height);
      this.shape.setPoints(this.#cachedOutline.path);
    } else if (this.#cachedOutline.update(width, height)) {
      this.shape.setPoints(this.#cachedOutline.path);
    }

    this.outline.state.values.hollow = stroke !== null;
  }

  #update() {
    this.#updateEllipse();
    this.#updateSprite();
    this.#updateShape();
  }

  protected updateTooltip(): void {
    const distanceUnits = this.planSettings?.getDistanceUnits() ?? metricDistanceUnits;
    this.tooltip.state.values.text = getEllipseTooltipText(this.getDimensions(), distanceUnits);
  }
}

export default EllipseShapeNode;
