let currID = 0;

/**
 * Returns a new unique ID to use for a new notitification
 *
 * Can be supplied manually to the action creator if a reference is needed, else will be
 * called by the action creator.
 *
 * @returns {number}
 */
export const generateNotificationID = () => {
  return currID++;
};
