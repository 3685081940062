import React, { ReactNode } from 'react';

import styles from './mobile-topbar-container.module.css';

interface iProps {
  className?: string;
  children?: ReactNode;
  noBackground?: boolean;
}

/**
 * Main container to wrap the topbar (give it its background, drop shadow and z-index)
 * Pair with MobileTopbarContentWithButtons to create a blank default topbar
 */
const MobileTopbarContainer = ({ className, children, noBackground = false }: iProps): JSX.Element => {
  const classNames = [styles.container];
  if (noBackground) {
    classNames.push(styles.noBackground);
  }
  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default MobileTopbarContainer;
