import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LoadingState } from '@gi/constants';
import { PlanSetUtils } from '@gi/plan';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { RequestSelectors } from '@gi/react-requests';
import { SessionSelectors } from '@gi/react-session';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { UserPlan, UserPlanSetUtils, UserPlanUtils } from '@gi/user';

export interface PlanWithStatus {
  plan: UserPlan;
  status: LoadingState;
}

/**
 * Utility hook to return a function that returns standardised information about a plan.
 *
 * Will always return a UserPlan, even if the plan is fully loaded.
 *
 * - Plans that are currently loading have a status of LOADING
 * - Plans that failed to load have a status of ERROR
 * - Plans that aren't in the plans object and have no loading request have a status of NONE
 * - Plans that have fully loaded have a status of SUCCESS
 */
export const usePlanStatusGetter = () => {
  const plans = useSelector(CanvasSelectors.getPlans);
  const requests = useSelector(RequestSelectors.getRequests);
  const user = useSelector(SessionSelectors.getUser);

  const getPlan = useCallback(
    (planId: number) => {
      const plan = PlanSetUtils.planSetGetPlan(plans, planId);
      if (plan) {
        return UserPlanUtils.createFromPlan(plan);
      }
      if (!user) {
        return null;
      }
      return UserPlanSetUtils.getPlan(user.plans, planId);
    },
    [plans, user?.plans]
  );

  const getPlanStatus = useCallback(
    (planId: number) => {
      const request = RequestsUtils.getStatus(requests, `LOAD_PLAN_${planId}`);
      if (request === RequestStatus.IN_PROGRESS) {
        return LoadingState.LOADING;
      }
      if (request === RequestStatus.FAIL) {
        return LoadingState.ERROR;
      }
      return PlanSetUtils.planSetGetPlan(plans, planId) !== null ? LoadingState.SUCCESS : LoadingState.NONE;
    },
    [requests, plans]
  );

  const getPlanAndStatus = useCallback(
    (planId: number): PlanWithStatus | null => {
      const plan = getPlan(planId);
      if (plan === null) {
        return null;
      }
      const status = getPlanStatus(planId);
      return {
        plan,
        status,
      };
    },
    [getPlan, getPlanStatus]
  );

  return getPlanAndStatus;
};
