import React, { useContext } from 'react';

import styles from './tutorial-editor.module.css';
import { TutorialEditorContext } from './tutorial-editor-context';
import EditorEditor from './editor-editor';
import EditorOverview from './editor-overview';

interface iProps {
  className?: string;
}

const TutorialEditorContent = ({ className = '' }: iProps): JSX.Element => {
  const { editingTutorial } = useContext(TutorialEditorContext);

  return <div className={`${styles.tutorialEditor} ${className}`}>{editingTutorial ? <EditorEditor /> : <EditorOverview />}</div>;
};

export default TutorialEditorContent;
