import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { CanvasSelectors } from '@gi/react-garden-canvas';
import {
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalTabs,
  ModalTabList,
  ModalTab,
  ModalTabContentContainer,
  ModalTabContent,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalContext,
} from '@gi/modal';

import DeviceSettingsTab from './device-settings/device-settings-tab';
import ProfileSettingsTab from './profile-settings/profile-settings-tab';
import PlanSettingsTab from './plan-settings/plan-settings-tab';
import { SettingsTabType } from './planner-settings-reducer';
import * as PlannerSettingsSelectors from './planner-settings-selectors';

const PlannerSettingsModalContent = (): JSX.Element | null => {
  const { attemptClose } = useContext(ModalContext);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const defaultTab = useSelector(PlannerSettingsSelectors.getDefaultTab);

  let tab = defaultTab === null ? SettingsTabType.PlanSettings : defaultTab;

  // Don't show plan settings tab if there is no active plan
  if (activePlan === null && tab === SettingsTabType.PlanSettings) {
    tab = SettingsTabType.ProfileSettings;
  }

  return (
    <ModalContent>
      <ModalCloseButton onClick={attemptClose} />
      <ModalHeader>
        <ModalHeaderContent>
          <ModalHeaderTitle>Settings</ModalHeaderTitle>
        </ModalHeaderContent>
      </ModalHeader>
      <ModalTabs defaultActiveTab={tab}>
        <ModalTabList>
          {activePlan === null ? null : <ModalTab tabID={SettingsTabType.PlanSettings}>This Plan</ModalTab>}
          <ModalTab tabID={SettingsTabType.ProfileSettings}>Profile</ModalTab>
          <ModalTab tabID={SettingsTabType.DeviceSettings}>Device</ModalTab>
        </ModalTabList>
        <ModalTabContentContainer>
          <ModalTabContent tabID={SettingsTabType.PlanSettings}>
            <PlanSettingsTab />
          </ModalTabContent>
          <ModalTabContent tabID={SettingsTabType.ProfileSettings}>
            <ProfileSettingsTab />
          </ModalTabContent>
          <ModalTabContent tabID={SettingsTabType.DeviceSettings}>
            <DeviceSettingsTab />
          </ModalTabContent>
        </ModalTabContentContainer>
      </ModalTabs>
    </ModalContent>
  );
};

export default PlannerSettingsModalContent;
