import React, { useContext, useState } from 'react';
import Plant from '@gi/plant';
import { PlantSpacingHelpModal } from '@gi/help-modals';
import { HelpButton } from '@gi/base-components';
import { ResourceContext } from '@gi/resource-provider';
import ItemContentCell from '../../../components/item-content-table/item-content-cell';
import ItemContentRow from '../../../components/item-content-table/item-content-row';
import ItemContentCellHeader from '../../../components/item-content-table/item-content-cell-header';

interface iSpacingProps {
  plant: Plant;
}

const PlantSpacingSection = ({ plant }: iSpacingProps): JSX.Element => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const { userDistanceUnits } = useContext(ResourceContext);

  return (
    <ItemContentRow>
      {showHelpModal ? <PlantSpacingHelpModal closeModal={() => setShowHelpModal(false)} /> : null}
      <ItemContentCellHeader>
        <HelpButton onClick={() => setShowHelpModal(true)} /> Spacing
      </ItemContentCellHeader>
      <ItemContentCell>
        <p>
          <strong>Single Plants:</strong> {userDistanceUnits.getUnitString(plant.spacing)} each way (minimum).
        </p>
        <p>
          <strong>Row Spacing:</strong> {userDistanceUnits.getUnitString(plant.inRowSpacing)} with {userDistanceUnits.getUnitString(plant.rowSpacing)} row gap
          (minimum).
        </p>
      </ItemContentCell>
    </ItemContentRow>
  );
};

export default PlantSpacingSection;
