import { PlannerTabTypes } from '@gi/constants';

export type OpenPlanState = {
  activePlannerTab: PlannerTabTypes;
};

export type OpenPlanStates = {
  planStates: { [key: number]: OpenPlanState };
  planStateIDs: number[];
};

export const DEFAULT_OPEN_PLAN_STATES: OpenPlanStates = {
  planStates: {},
  planStateIDs: [],
};

export const DEFAULT_OPEN_PLAN_STATE: OpenPlanState = {
  activePlannerTab: PlannerTabTypes.PLANNER,
};

export const OpenPlanStateUtils = {
  hasPlanState(openPlanStates: OpenPlanStates, planID: number): boolean {
    return openPlanStates.planStateIDs.includes(planID);
  },
  getPlanState(openPlanStates: OpenPlanStates, planID: number): OpenPlanState {
    return openPlanStates.planStates[planID];
  },
  addPlanState(openPlanStates: OpenPlanStates, planID: number, planState: OpenPlanState = { ...DEFAULT_OPEN_PLAN_STATE }): void {
    openPlanStates.planStates[planID] = planState;
    openPlanStates.planStateIDs.push(planID);
  },
  removePlanState(openPlanStates: OpenPlanStates, planID: number): void {
    delete openPlanStates.planStates[planID];
    openPlanStates.planStateIDs = openPlanStates.planStateIDs.filter((v) => v !== planID);
  },
  updatePlanState(openPlanStates: OpenPlanStates, planID: number, updatedPlanState: OpenPlanState): void {
    if (!OpenPlanStateUtils.hasPlanState(openPlanStates, planID)) {
      OpenPlanStateUtils.addPlanState(openPlanStates, planID, updatedPlanState);
    }

    openPlanStates.planStates[planID] = updatedPlanState;
  },
};
