import { Store } from 'redux';

import Plan from '@gi/plan';
import { GardenItemType, ItemNodeType, LoadingState } from '@gi/constants';
import { GardenCanvas, GardenCanvasEvent, GardenCanvasEventActions } from '@gi/plan-simulation';
import { AssetGroup, InteractionManagerEvent, InteractionManagerState } from '@gi/core-renderer';
import { PlannerActionCreators } from '@gi/app-planner-slice';
import { LocalSettings, LocalSettingsActionCreators } from '@gi/local-settings';
import { NotificationActionCreators, NotificationTypes, NotificationStoreState, NotificationActionType } from '@gi/notifications';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';

import * as CanvasActionCreators from '../redux-components/canvas-action-creators';
import { updatePlan, updateInteractionState } from '../redux-components/garden-canvas-event-action-creators';
import GardenCanvasController, { GardenCanvasControllerEvent } from '../garden-canvas-controller/garden-canvas-controller';
import { CanvasInteractionState } from '../redux-components/canvas-reducer';

/** Permanent ID for help notification, so we never have more than 1. Negative numbers are never used automatically. */
const TOUCH_DRAG_HELP_NOTIFICATION_ID = -Math.round(1 + Math.random() * 10000);

const touchHelpNotificationText: Record<ItemNodeType, string> = {
  [ItemNodeType.Plant]: 'Trying to move a plant?',
  [ItemNodeType.SquareFootPlant]: 'Trying to move a plant?',
  [ItemNodeType.PlantLabel]: 'Trying to move a label?',
  [ItemNodeType.GardenObject]: 'Trying to move an object?',
  [ItemNodeType.Shape]: 'Trying to move a shape?',
  [ItemNodeType.Text]: 'Trying to move text?',
};

const setupGardenCanvasEventHandler = (
  store: Store<{ localSettings: LocalSettings; notifications: NotificationStoreState }>,
  gardenCanvasController: GardenCanvasController
) => {
  let { gardenCanvas } = gardenCanvasController.getInstance();

  const onGardenCanvasInteractionStateUpdate = (state: InteractionManagerState) => {
    store.dispatch(updateInteractionState(state as CanvasInteractionState));
  };

  const onPlanUpdate = (plan: Plan) => {
    store.dispatch(updatePlan(plan));
  };

  const onGardenCanvasEditItem = (itemType: GardenItemType, itemId: number, planId: number) => {
    store.dispatch(
      CanvasActionCreators.editItem({
        itemType,
        itemID: itemId,
        planID: planId,
      })
    );
  };

  const onGardenCanvasContextMenu = (worldPosition: Vector2) => {
    store.dispatch(CanvasActionCreators.showContextMenu({ worldPosition }));
  };

  const onGardenCanvasShowTouchDragHelp = (itemType: ItemNodeType /* itemId: number, planId: number */) => {
    const state = store.getState();
    if (!state.localSettings.hideTouchDragHelpNotifications) {
      store.dispatch(
        NotificationActionCreators.createNotification({
          title: touchHelpNotificationText[itemType],
          icon: 'icon-help-circled',
          type: NotificationTypes.HELP,
          ID: TOUCH_DRAG_HELP_NOTIFICATION_ID,
          canTimeout: true,
          visibleDuration: 10000, // 10secs
          actions: [
            {
              title: 'Show Help',
              type: NotificationActionType.HELP,
              action: PlannerActionCreators.setShowTouchDragHelpModal(true),
            },
            {
              title: "Don't Show Again",
              type: NotificationActionType.HELP,
              action: LocalSettingsActionCreators.setHideTouchDragHelpNotifications(true),
            },
          ],
        })
      );
    }
  };

  const onGardenCanvasHideTouchDragHelp = () => {
    const state = store.getState();
    if (state.notifications.notificationIDs.includes(TOUCH_DRAG_HELP_NOTIFICATION_ID)) {
      store.dispatch(NotificationActionCreators.removeNotification(TOUCH_DRAG_HELP_NOTIFICATION_ID));
    }
  };

  const onDrawPlant: GardenCanvasEventActions[GardenCanvasEvent.ON_DRAW_PLANT] = (plant, sfg, isDragToDraw) => {
    store.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.DrawPlant, { plantCode: plant.code, sfg, isDragToDraw }));
  };

  const onDrawGardenObject: GardenCanvasEventActions[GardenCanvasEvent.ON_DRAW_GARDEN_OBJECT] = (gardenObject, isDragToDraw) => {
    store.dispatch(
      GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.DrawGardenObject, {
        objectCode: gardenObject.code,
        isDragToDraw,
        isSeasonExtender: gardenObject.plantModifier !== null,
      })
    );
  };

  const onDrawShape: GardenCanvasEventActions[GardenCanvasEvent.ON_DRAW_SHAPE] = (shapeType, filled, isDragToDraw) => {
    store.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.DrawShape, { shapeType, filled, isDragToDraw }));
  };

  const onDrawText: GardenCanvasEventActions[GardenCanvasEvent.ON_DRAW_TEXT] = (isDragToDraw) => {
    store.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.DrawText, { isDragToDraw }));
  };

  const onGardenCanvasTextureStateChange = (assetGroup: AssetGroup) => {
    if (assetGroup.status === LoadingState.ERROR) {
      store.dispatch(
        CanvasActionCreators.setTextureStatus({
          status: LoadingState.ERROR,
          error: assetGroup.error ?? new Error('Unknown Error'),
        })
      );
    } else if (assetGroup.status === LoadingState.SUCCESS) {
      store.dispatch(
        CanvasActionCreators.setTextureStatus({
          status: LoadingState.SUCCESS,
          value: undefined,
        })
      );
    } else if (assetGroup.status === LoadingState.LOADING) {
      store.dispatch(
        CanvasActionCreators.setTextureStatus({
          status: LoadingState.LOADING,
          progress: assetGroup.progress,
        })
      );
    }
  };

  const setup = () => {
    if (gardenCanvas) {
      gardenCanvas.on(GardenCanvasEvent.ASSETS_STATE_CHANGE, onGardenCanvasTextureStateChange);
      gardenCanvas.on(GardenCanvasEvent.PLAN_UPDATE, onPlanUpdate);
      gardenCanvas.on(GardenCanvasEvent.EDIT_ITEM, onGardenCanvasEditItem);
      gardenCanvas.on(GardenCanvasEvent.CONTEXT_MENU, onGardenCanvasContextMenu);
      gardenCanvas.on(GardenCanvasEvent.SHOW_TOUCH_DRAG_HELP, onGardenCanvasShowTouchDragHelp);
      gardenCanvas.on(GardenCanvasEvent.HIDE_TOUCH_DRAG_HELP, onGardenCanvasHideTouchDragHelp);
      gardenCanvas.on(GardenCanvasEvent.ON_DRAW_PLANT, onDrawPlant);
      gardenCanvas.on(GardenCanvasEvent.ON_DRAW_GARDEN_OBJECT, onDrawGardenObject);
      gardenCanvas.on(GardenCanvasEvent.ON_DRAW_SHAPE, onDrawShape);
      gardenCanvas.on(GardenCanvasEvent.ON_DRAW_TEXT, onDrawText);

      gardenCanvas.engine.interactionManager.eventBus.on(InteractionManagerEvent.StateChange, onGardenCanvasInteractionStateUpdate);

      // Immediately update texture loading state, as they're probably already loading.
      onGardenCanvasTextureStateChange(gardenCanvas.assets);
    }
    // store.dispatch(updateInteractionState(gardenCanvas.getInteractionStateService().interactionState));

    // gardenCanvas.getInteractionStateService().on(InteractionStateEvents.UPDATE, onGardenCanvasInteractionStateUpdate);
    // gardenCanvas.on(GardenCanvasEvents.TEXTURE_CHANGE, onGardenCanvasTextureStateChange);
  };

  const teardown = () => {
    if (gardenCanvas) {
      gardenCanvas.off(GardenCanvasEvent.ASSETS_STATE_CHANGE, onGardenCanvasTextureStateChange);
      gardenCanvas.off(GardenCanvasEvent.PLAN_UPDATE, onPlanUpdate);
      gardenCanvas.off(GardenCanvasEvent.EDIT_ITEM, onGardenCanvasEditItem);
      gardenCanvas.off(GardenCanvasEvent.CONTEXT_MENU, onGardenCanvasContextMenu);
      gardenCanvas.off(GardenCanvasEvent.SHOW_TOUCH_DRAG_HELP, onGardenCanvasShowTouchDragHelp);
      gardenCanvas.off(GardenCanvasEvent.HIDE_TOUCH_DRAG_HELP, onGardenCanvasHideTouchDragHelp);
      gardenCanvas.off(GardenCanvasEvent.ON_DRAW_PLANT, onDrawPlant);
      gardenCanvas.off(GardenCanvasEvent.ON_DRAW_GARDEN_OBJECT, onDrawGardenObject);
      gardenCanvas.off(GardenCanvasEvent.ON_DRAW_SHAPE, onDrawShape);
      gardenCanvas.off(GardenCanvasEvent.ON_DRAW_TEXT, onDrawText);

      gardenCanvas.engine.interactionManager.eventBus.off(InteractionManagerEvent.StateChange, onGardenCanvasInteractionStateUpdate);
    }
    // Remove all listeners we added in setup
    // gardenCanvasDataSynchroniser.off(GardenCanvasEvents.PLAN_UPDATE, onPlanUpdate);
    // gardenCanvas.getInteractionStateService().off(InteractionStateEvents.UPDATE, onGardenCanvasInteractionStateUpdate);
    // gardenCanvas.off(GardenCanvasEvents.EDIT_ITEM, onGardenCanvasEditItem);
    // gardenCanvas.off(GardenCanvasEvents.TEXTURE_CHANGE, onGardenCanvasTextureStateChange);

    store.dispatch(updateInteractionState({ type: null }));
  };

  const onGardenCanvasControllerStateUpdate = (newGardenCanvas: GardenCanvas | null) => {
    if (gardenCanvas !== null) {
      teardown();
    }

    // See: https://hacks.mozilla.org/2015/05/es6-in-depth-destructuring/ for why the parenthese are needed
    gardenCanvas = newGardenCanvas;

    if (gardenCanvas !== null) {
      setup();
    }
  };

  // Note: we cannot remove this event as we don't have an easy way to have a reference to the event callback
  // once this setup function exits, so we must make sure `gardenCanvasController.destroy()` is called to remove
  // all listeners
  gardenCanvasController.on(GardenCanvasControllerEvent.STATE_CHANGE, onGardenCanvasControllerStateUpdate);

  if (gardenCanvas !== null) {
    // Set initial values, this will probably never be called as garden canvas will likely be null when this store listener is added
    // store.dispatch(updateInteractionState(gardenCanvas.getInteractionStateService().interactionState));
  }
};

export default setupGardenCanvasEventHandler;
