import React, { useMemo } from 'react';

import { PlannerTabTypes } from '@gi/constants';
import { DesktopPlannerControls } from '@gi/planner-controls';

import CanvasContent from './canvas-content';
import PartsListContent from './parts-list-content';
import NotesContent from './notes-content';
import PlantListContent from './plant-list-content';
import ShoppingContent from './shopping-content';
import PlannerOverlay from '../overlay/planner-overlay';

import styles from './planner-content.module.css';

interface iProps {
  activePlannerTab: PlannerTabTypes;
  children?: React.ReactNode;
}

const DesktopPlannerContent = ({ activePlannerTab, children }: iProps): JSX.Element | null => {
  const content = useMemo(() => {
    switch (activePlannerTab) {
      case PlannerTabTypes.PLANNER:
        return <CanvasContent>{children}</CanvasContent>;
      case PlannerTabTypes.PLANT_LIST:
        return <PlantListContent>{children}</PlantListContent>;
      case PlannerTabTypes.PARTS_LIST:
        return <PartsListContent>{children}</PartsListContent>;
      case PlannerTabTypes.NOTES:
        return <NotesContent>{children}</NotesContent>;
      case PlannerTabTypes.SHOPPING:
        return <ShoppingContent>{children}</ShoppingContent>;
      default:
        return null;
    }
  }, [activePlannerTab, children]);

  return (
    <div className={styles.plannerContentContainer}>
      {content}
      <DesktopPlannerControls />
      <PlannerOverlay />
    </div>
  );
};

export default DesktopPlannerContent;
