import React, { useState, useMemo } from 'react';
import ModalTabContext from './modal-tab-context';

import './modal-tabs.scss';
import { useLeaveContextConfirmation } from '../modal-context/use-leave-context-confirmation';

interface iProps {
  children: React.ReactNode;
  defaultActiveTab: string;
  onTabChange?: (activeTab: string) => void;
}

const ModalTabs = ({ children, defaultActiveTab, onTabChange = () => {} }: iProps): JSX.Element => {
  const [currentActiveTab, _setActiveTab] = useState(defaultActiveTab);

  const { attemptLeave, modal, setLeaveContextParams } = useLeaveContextConfirmation();

  const setActiveTab = (tab: string) => {
    attemptLeave(() => {
      _setActiveTab(tab);
      onTabChange(tab);
    });
  };

  const state = useMemo(
    () => ({
      activeTab: currentActiveTab,
      setActiveTab,
      setLeaveContextParams,
    }),
    [currentActiveTab, setActiveTab, setLeaveContextParams]
  );

  return (
    <ModalTabContext.Provider value={state}>
      {modal}
      <div className='modal-tabs'>{children}</div>
    </ModalTabContext.Provider>
  );
};

export default ModalTabs;
