import React, { useContext } from 'react';

import { PlanSize } from '../utils';
import { PlanDiagramSVGContext } from '../plan-diagram-svg';

import styles from './svg-element-styles.module.css';

interface iProps {
  originalPlan: PlanSize;
  newPlan: PlanSize;
}

/**
 * Creates an area that highlights the part of the new plan dimensions that aren't contained within
 *  the old plan dimensions
 *
 * Inner x/y values are clamped such that they never go beyond the new plan dimensions, so if the plan shrinks,
 *  nothing will display.
 *
 *  x1   x2         x3   x4          x1  x2    x3  x4
 *   +----+----------+----+ y1            +----+
 *   | new plan           |               |orig|
 *   |    +----------+    | y2        +---+----+---+ y1, y2
 *   |    | original |    |           |new|    |   |
 *   |    +----------+    | y3        +---+----+---+ y3, y4
 *   |                    |               |    |
 *   +----+----------+----+ y4            +----+
 */
const AddedAreaSVGElement = ({ originalPlan, newPlan }: iProps): JSX.Element => {
  const { scale, offset } = useContext(PlanDiagramSVGContext);

  const x1 = newPlan.x;
  const x2 = Math.max(originalPlan.x, newPlan.x);
  const x3 = Math.min(originalPlan.x + originalPlan.width, newPlan.x + newPlan.width);
  const x4 = newPlan.x + newPlan.width;

  const y1 = newPlan.y;
  const y2 = Math.max(originalPlan.y, newPlan.y);
  const y3 = Math.min(originalPlan.y + originalPlan.height, newPlan.y + newPlan.height);
  const y4 = newPlan.y + newPlan.height;

  const points: Vector2[] = [
    { x: x1, y: y1 }, // TL new (start)
    { x: x4, y: y1 }, // TR new
    { x: x4, y: y4 }, // BR new
    { x: x1, y: y4 }, // BL new
    { x: x1, y: y1 }, // TL new (back to start)
    { x: x2, y: y2 }, // TL old (inner start)
    { x: x2, y: y3 }, // TR old
    { x: x3, y: y3 }, // BR old
    { x: x3, y: y2 }, // BL old
    { x: x2, y: y2 }, // TL old (back to inner start)
    { x: x1, y: y1 }, // TL new (back to start)
  ].map((point) => ({
    x: point.x * scale + offset.x,
    y: point.y * scale + offset.y,
  }));

  const path = `M ${points.map((point) => `${point.x} ${point.y}`).join(' L ')}`;

  return <path d={path} className={styles.planAddedArea} />;
};

export default AddedAreaSVGElement;
