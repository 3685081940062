import React, { useContext, useEffect } from 'react';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

interface iProps {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
}

const ShowAreaTableCell = ({ expanded, setExpanded }: iProps): JSX.Element | null => {
  const { options, columns } = useContext(GlobalPlantListContext);

  const isExpanded = options.expandPlantAreas || expanded;
  const icon = isExpanded ? 'icon-down-dir' : 'icon-right-dir';
  const ariaLabel = expanded ? 'Hide Area Rows' : 'Show Area Rows';

  const onClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (options.expandPlantAreas && !expanded) {
      setExpanded(true);
    } else if (!options.expandPlantAreas && expanded) {
      setExpanded(false);
    }
  }, [options.expandPlantAreas]);

  if (!columns.area) {
    return null;
  }

  return (
    <td className='no-print show-area-td top-cell'>
      <button className='button button-borderless show-area-button row-button' type='button' aria-label={ariaLabel} onClick={onClick}>
        <i className={icon} />
      </button>
    </td>
  );
};

export default ShowAreaTableCell;
