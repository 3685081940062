import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';

import Modal, {
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalHeader,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalPane,
  ModalPaneContainer,
  ModalPaneContent,
} from '@gi/modal';
import FormField, {
  createFormValues,
  FORM_FIELD_PRESETS,
  FormSubtext,
  FormSubtextType,
  FormLayout,
  FormSection,
  InputContainer,
  FORM_LAYOUT_PRESETS,
  unresponsivePreset,
} from '@gi/form-responsive';
import { A1, A2, A3, A4, GovernmentLetter, JuniorLegal, Legal, Letter, Paper } from '@gi/paper';
import { CanvasSelectors, GardenCanvasContext } from '@gi/react-garden-canvas';
import { SessionSelectors } from '@gi/react-session';
import { DecimalInput } from '@gi/length-input';
import { distanceConversion } from '@gi/conversion';
import { IS_IOS, SUPPORTS_ARRAY_BUFFER, LoadingState } from '@gi/constants';
import LoadingButton from '@gi/loading-button';
import { RequestSelectors } from '@gi/react-requests';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { NumberValidators } from '@gi/validators';
import { canvasBackgroundUsageCompatibility } from '@gi/core-renderer';
import { IntercomActionCreators } from '@gi/intercom';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import { DimensionCount, Orientation, PrintFitMode, PrintSettings } from '../../types';
import { calculateTotalPages } from '../../utils';
import PrintPreview from './print-layout-preview';
import PrintProgress from './print-progress';
import { forceIntBetween } from './utils';
import PrintPlanContext from '../print-provider/print-context';

import './print-modal.scss';

const MIN_PAGE_COUNT = 1;
const MAX_PAGE_COUNT = IS_IOS ? 3 : 5;

const MIN_CUSTOM_PAPER_DIMENSION_MM = 100;
const MIN_CUSTOM_PAPER_DIMENSION_CM = MIN_CUSTOM_PAPER_DIMENSION_MM / 10;
const MIN_CUSTOM_PAPER_DIMENSION_INCH = 4;
const MIN_CUSTOM_PAPER_DIMENSION_INCH_CM = 10.15;
const MAX_CUSTOM_PAPER_DIMENSION_MM = 1000;
const MAX_CUSTOM_PAPER_DIMENSION_CM = MAX_CUSTOM_PAPER_DIMENSION_MM / 10;
const MAX_CUSTOM_PAPER_DIMENSION_INCH = 40;
const MAX_CUSTOM_PAPER_DIMENSION_INCH_CM = 101.7;

const IOS_MINIMUM_GENERATE_TIME = 2000;

const DEFAULT_PRINT_SETTINGS: Pick<PrintSettings, 'margins' | 'dpi' | 'imageFormat' | 'imageQuality'> = {
  margins: { x: 10, y: 10 },
  dpi: 250,
  imageFormat: 'image/jpeg',
  imageQuality: 0.95,
} as const;

interface InternalValues {
  paperSize: Paper;
  orientation: Orientation;
  useCustomPaper: boolean;
  customPaperWidth: number;
  customPaperHeight: number;
  fitMode: PrintFitMode;
  fitPagesAcross: number;
  fitPagesDown: number;
  showBackgroundImage: boolean;
}

const paperSizes = IS_IOS
  ? [
      { label: A4.name, value: A4 },
      { label: A3.name, value: A3 },
      { label: Letter.name, value: Letter },
      { label: GovernmentLetter.name, value: GovernmentLetter },
      { label: Legal.name, value: Legal },
      { label: JuniorLegal.name, value: JuniorLegal },
    ]
  : [
      { label: A4.name, value: A4 },
      { label: A3.name, value: A3 },
      { label: A2.name, value: A2 },
      { label: A1.name, value: A1 },
      { label: Letter.name, value: Letter },
      { label: GovernmentLetter.name, value: GovernmentLetter },
      { label: Legal.name, value: Legal },
      { label: JuniorLegal.name, value: JuniorLegal },
    ];

interface iProps {
  onClose: () => void;
}

const PrintModal = ({ onClose }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const user = useSelector(SessionSelectors.getUser);
  const requests = useSelector(RequestSelectors.getRequests);
  const plan = useSelector(CanvasSelectors.getActivePlan);

  if (!user || !plan) {
    return null;
  }

  const { pdf, generatePDF, downloadCurrentPDF, viewCurrentPDF } = useContext(PrintPlanContext);
  const { gardenCanvas } = useContext(GardenCanvasContext);

  const [isStale, setIsStale] = useState(true);
  const [iosMinimumGenerationTimePassed, setIosMinimumGenerationTimePassed] = useState(true);

  const engine = gardenCanvas !== null ? gardenCanvas.engine : null;
  const metric = user.settings.units.metric ?? true;
  const defaultPaperSize = user.countryCode === 'us' ? Letter : A4;
  const defaultCustomPaperWidth = metric
    ? defaultPaperSize.width / 10
    : distanceConversion.cmFromInches(Math.round(distanceConversion.inchesFromCM(defaultPaperSize.width)) / 10);
  const defaultCustomPaperHeight = metric
    ? defaultPaperSize.height / 10
    : distanceConversion.cmFromInches(Math.round(distanceConversion.inchesFromCM(defaultPaperSize.height)) / 10);

  const isSavingPlan = useMemo(() => {
    return RequestsUtils.getStatus(requests, `SAVE_PLAN_${plan.id}`) === RequestStatus.IN_PROGRESS;
  }, [requests, plan]);

  const [formValues, setFormValues] = useState(
    createFormValues<InternalValues>({
      paperSize: { value: defaultPaperSize },
      orientation: { value: 'portrait' },
      useCustomPaper: { value: false },
      customPaperWidth: {
        value: defaultCustomPaperWidth,
        disabled: true,
        validators: [
          metric
            ? NumberValidators.isBetween(MIN_CUSTOM_PAPER_DIMENSION_CM, MAX_CUSTOM_PAPER_DIMENSION_CM)
            : NumberValidators.isBetween(MIN_CUSTOM_PAPER_DIMENSION_INCH_CM, MAX_CUSTOM_PAPER_DIMENSION_INCH_CM),
        ],
      },
      customPaperHeight: {
        value: defaultCustomPaperHeight,
        disabled: true,
        validators: [
          metric
            ? NumberValidators.isBetween(MIN_CUSTOM_PAPER_DIMENSION_CM, MAX_CUSTOM_PAPER_DIMENSION_CM)
            : NumberValidators.isBetween(MIN_CUSTOM_PAPER_DIMENSION_INCH_CM, MAX_CUSTOM_PAPER_DIMENSION_INCH_CM),
        ],
      },
      fitMode: { value: 'both' },
      fitPagesAcross: {
        value: 1,
        validators: [NumberValidators.isIntBetween(MIN_PAGE_COUNT, MAX_PAGE_COUNT)],
      },
      fitPagesDown: {
        value: 1,
        validators: [NumberValidators.isIntBetween(MIN_PAGE_COUNT, MAX_PAGE_COUNT)],
      },
      showBackgroundImage: {
        value: false,
        disabled: plan.backgroundImage === undefined,
      },
    })
  );

  /**
   * Store the current valid print settings
   * This should only be updated by the useEffect below, to ensure this is always valid.
   */
  const [printSettings, setPrintSettings] = useState<PrintSettings>({
    ...DEFAULT_PRINT_SETTINGS,
    paperSize: formValues.values.useCustomPaper
      ? {
          width: formValues.values.customPaperWidth * 10,
          height: formValues.values.customPaperHeight * 10,
        }
      : formValues.values.paperSize,
    orientation: formValues.values.orientation,
    fitMode: formValues.values.fitMode,
    fitPageCount:
      formValues.values.fitMode === 'across' ? formValues.values.fitPagesAcross : formValues.values.fitMode === 'down' ? formValues.values.fitPagesDown : 1,
    showBackgroundImage: formValues.values.showBackgroundImage,
  });

  /**
   * Works as a useMemo for printSettings, but only updates if the form values are valid.
   * This way we don't have an invalid preview.
   */
  useEffect(() => {
    setIsStale(true);
    if (formValues.isValid) {
      const v = formValues.values;
      setPrintSettings({
        ...DEFAULT_PRINT_SETTINGS,
        paperSize: v.useCustomPaper ? { width: v.customPaperWidth * 10, height: v.customPaperHeight * 10 } : v.paperSize,
        orientation: v.orientation,
        fitMode: v.fitMode,
        fitPageCount: v.fitMode === 'across' ? v.fitPagesAcross : v.fitMode === 'down' ? v.fitPagesDown : 1,
        showBackgroundImage: v.showBackgroundImage,
      });
    }
  }, [formValues]);

  /**
   * Returns the active plan's dimensions.
   * Returns null if there's no active plan.
   */
  const getPlanDimensions = useCallback(() => {
    if (!gardenCanvas) {
      return null;
    }
    const activePlan = gardenCanvas.getActivePlan();
    if (!activePlan) {
      return null;
    }
    return activePlan.simulatedPlan.dimensions;
  }, [gardenCanvas]);

  /**
   * Recalculate the pages across and down every time the print settings update.
   */
  const pages = useMemo<DimensionCount>(() => {
    const dimensions = getPlanDimensions();
    if (!engine || !dimensions) {
      return { across: 0, down: 0 };
    }
    return calculateTotalPages(engine, dimensions, printSettings);
  }, [printSettings]);

  /**
   * Generate a filename for the PDF based on plan details.
   */
  const filename = useMemo(() => {
    return `${plan?.name} - ${plan?.year}`;
  }, [plan]);

  /**
   * Starts the generation delay timer.
   * This makes printing appear to take longer if it's very quick, preventing it from looking like nothing's happened.
   */
  const doDelayOniOS = useCallback(() => {
    if (IS_IOS) {
      setIosMinimumGenerationTimePassed(false);
      setTimeout(() => setIosMinimumGenerationTimePassed(true), IOS_MINIMUM_GENERATE_TIME);
    } else {
      setIosMinimumGenerationTimePassed(true);
    }
  }, [IS_IOS]);

  /**
   * Tries to open an embedded PDF of the current plan.
   * Will use the most recently-generated PDF if print settings haven't changed.
   */
  const tryViewPDF = useCallback(() => {
    if (pdf.status === LoadingState.LOADING) {
      return;
    }
    doDelayOniOS();
    if (!isStale && pdf.status === LoadingState.SUCCESS) {
      viewCurrentPDF();
    } else {
      if (!engine) {
        return;
      }
      const activePlan = gardenCanvas?.getActivePlan();
      if (!activePlan) {
        return;
      }
      generatePDF(engine, activePlan, printSettings, 'VIEW').catch((e) => console.error(e));
      setIsStale(false);
    }
    dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ExportPlan, { planId: plan.id }));
  }, [pdf, isStale, printSettings, filename, viewCurrentPDF, generatePDF]);

  /**
   * Tries to download a PDF of the current plan.
   * Will use the most recently-generated PDF if print settings haven't changed.
   */
  const tryDownloadPDF = useCallback(() => {
    if (pdf.status === LoadingState.LOADING) {
      return;
    }
    doDelayOniOS();
    if (!isStale && pdf.status === LoadingState.SUCCESS) {
      downloadCurrentPDF(filename);
    } else {
      if (!engine) {
        return;
      }
      const activePlan = gardenCanvas?.getActivePlan();
      if (!activePlan) {
        return;
      }
      generatePDF(engine, activePlan, printSettings, IS_IOS ? 'NONE' : 'DOWNLOAD', filename).catch((e) => console.error(e));
      setIsStale(false);
    }
    dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ExportPlan, { planId: plan.id }));
  }, [pdf, isStale, printSettings, filename, downloadCurrentPDF, generatePDF]);

  /**
   * Tries to close this modal.
   * Cannot be done if a PDF is being generated, as the canvas is held hostage.
   */
  const tryClose = useCallback(() => {
    if (pdf.status !== LoadingState.LOADING) {
      onClose();
    }
  }, [onClose, pdf]);

  // Store the last seen copy of the status message element, so we can use it in the AnimateHeight component when it's being hidden.
  const [previousPrintProgressSection, setPreviousPrintProgressSection] = useState<JSX.Element | null>(null);
  /**
   * Displays a status message to the user, showing the progress/errors from generating the PDF.
   */
  const printProgressSection = useMemo(() => {
    let element: JSX.Element | null = null;

    if (pdf.status === LoadingState.LOADING) {
      element = (
        <FormSection className='form-section-background' padding={12}>
          <PrintProgress progress={pdf.progress} text={pdf.message} />
        </FormSection>
      );
    } else if (pdf.status === LoadingState.ERROR && !isStale) {
      element = (
        <FormSection className='form-section-background print-modal-error' padding={12} gap={6}>
          <p>
            <strong>An error occured while trying to generate the PDF.</strong>
          </p>
          {!SUPPORTS_ARRAY_BUFFER ? (
            <p>Your browser doesn&apos;t support some features needed for printing. Please try a different browser/device, or contact support.</p>
          ) : null}
        </FormSection>
      );
    } else if (pdf.status === LoadingState.SUCCESS && !isStale) {
      element = (
        <FormSection className='form-section-background' padding={12}>
          <PrintProgress progress={1} text='Finished' />
        </FormSection>
      );
    }

    if (element) {
      setPreviousPrintProgressSection(element);
    }

    // AnimateHeight can't accept null
    if (!element && !previousPrintProgressSection) {
      return null;
    }

    return (
      <AnimateHeight duration={200} height={element === null ? 0 : 'auto'}>
        {element ?? previousPrintProgressSection}
      </AnimateHeight>
    );
  }, [pdf, isStale]);

  /**
   * Footer buttons should behave differently on iOS.
   * On iOS: Press Generate button -then-> Press Open button
   * On other: Press Generate OR Open button
   */
  const footerButtons = useMemo(() => {
    if (isSavingPlan) {
      return (
        <p className='saving-plans-text'>
          Saving plans, please wait... <i className='icon-spinner animate-pulse' />
        </p>
      );
    }
    const isLoading = pdf.status === LoadingState.LOADING || !iosMinimumGenerationTimePassed;
    const isDisabled = isLoading || !formValues.isValid;
    if (IS_IOS) {
      return (
        <>
          <LoadingButton
            type='button'
            className='button button-primary'
            loading={isLoading}
            disabled={isDisabled || (!isStale && pdf.status !== LoadingState.ERROR)}
            onClick={tryDownloadPDF}
          >
            Generate PDF
          </LoadingButton>
          <LoadingButton
            type='button'
            className='button button-primary'
            loading={isLoading}
            disabled={isDisabled || isStale || pdf.status !== LoadingState.SUCCESS}
            onClick={tryViewPDF}
          >
            Open
          </LoadingButton>
        </>
      );
    }
    return (
      <>
        <LoadingButton type='button' className='button button-secondary' loading={isLoading} disabled={isDisabled} onClick={tryDownloadPDF}>
          Download PDF
        </LoadingButton>
        <LoadingButton type='button' className='button button-primary' loading={isLoading} disabled={isDisabled} onClick={tryViewPDF}>
          Open PDF
        </LoadingButton>
      </>
    );
  }, [pdf, IS_IOS, isStale, isSavingPlan, iosMinimumGenerationTimePassed, tryDownloadPDF, tryViewPDF, formValues]);

  const canvasImageDataWarning = useMemo(() => {
    // Update the check each time we load, in case the user changes their settings
    const supportsImageData = canvasBackgroundUsageCompatibility.check();
    if (supportsImageData) {
      return null;
    }
    return (
      <div className='print-modal-warning'>
        <p>Your browser may be blocking certain canvas features needed to generate a PDF of your plan.</p>
        <p>
          If the final PDF appears broken or incorrect, check your browser privacy settings, or{' '}
          <button
            className='button button-inline intercom-button'
            type='button'
            onClick={() => dispatch(IntercomActionCreators.openIntercomWithAnalytics('print-modal-missing-features'))}
          >
            contact support
          </button>{' '}
          for assistance.
        </p>
      </div>
    );
  }, []);

  const showPlanDisplaySettingsSection = useMemo(() => {
    return !formValues.fields.showBackgroundImage.disabled;
  }, [formValues.fields.showBackgroundImage.disabled]);

  const isLoading = pdf.status === LoadingState.LOADING;

  return (
    <Modal closeRequest={tryClose} style={{ maxWidth: '520px' }}>
      <ModalContent>
        <ModalCloseButton onClick={tryClose} />
        {/* Header */}
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Print Preview</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        {/* Content */}
        <ModalPaneContainer>
          <ModalPane>
            <ModalPaneContent>
              {/* Explanation text */}
              <FormSection padding={0}>
                <p>Generate a PDF of your plan to print. Larger plans and larger print sizes will take longer to generate.</p>
                {canvasImageDataWarning}
              </FormSection>
              {/* Paper Size section */}
              <FormSection className='form-section-background' padding={12}>
                {/* Paper Size dropdown */}
                <FormField label='Paper Size' htmlFor='paper-size' desktopLayout={{ labelSize: 120 }} disabled={isLoading}>
                  <ReactSelect<(typeof paperSizes)[number]>
                    styles={DEFAULT_SELECT_STYLES}
                    isDisabled={formValues.fields.paperSize.disabled || isLoading}
                    inputId='paper-size'
                    options={paperSizes}
                    value={paperSizes.find((option) => option.value === formValues.values.paperSize)}
                    onChange={(option) => {
                      if (option) {
                        setFormValues(formValues.setValue('paperSize', { value: option.value }));
                      }
                    }}
                  />
                </FormField>
                {/* Orientation radio buttons */}
                <FormField fakeLabel label='Orientation' desktopLayout={{ labelSize: 120 }} disabled={isLoading}>
                  <FormLayout layoutPreset={unresponsivePreset({ layout: 'row', gap: 18 })}>
                    <FormField label='Portrait' htmlFor='orientation-portrait' layoutPreset={FORM_FIELD_PRESETS.Radio}>
                      <input
                        type='radio'
                        name='orientation'
                        id='orientation-portrait'
                        disabled={isLoading}
                        checked={formValues.values.orientation === 'portrait'}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormValues(formValues.setValue('orientation', { value: 'portrait' }));
                          }
                        }}
                      />
                    </FormField>
                    <FormField label='Landscape' htmlFor='orientation-landscape' layoutPreset={FORM_FIELD_PRESETS.Radio}>
                      <input
                        type='radio'
                        name='orientation'
                        id='orientation-landscape'
                        disabled={isLoading}
                        checked={formValues.values.orientation === 'landscape'}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormValues(formValues.setValue('orientation', { value: 'landscape' }));
                          }
                        }}
                      />
                    </FormField>
                  </FormLayout>
                </FormField>
                {/* Custom paper size area */}
                <FormLayout mobileLayout={{ gap: 0 }}>
                  {/* Custom checkobx */}
                  <FormField
                    label='Custom'
                    htmlFor='custom-paper-size'
                    layoutPreset={FORM_FIELD_PRESETS.Checkbox}
                    desktopLayout={{ labelSize: 120 }}
                    disabled={isLoading}
                  >
                    <input
                      id='custom-paper-size'
                      type='checkbox'
                      disabled={isLoading}
                      onChange={(event) =>
                        setFormValues(
                          formValues.setValues(
                            ['useCustomPaper', { value: event.target.checked }],
                            ['paperSize', { disabled: event.target.checked }],
                            ['customPaperWidth', { disabled: !event.target.checked }],
                            ['customPaperHeight', { disabled: !event.target.checked }]
                          )
                        )
                      }
                    />
                  </FormField>
                  {/* Paper width */}
                  <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.Column} disabled={!formValues.values.useCustomPaper}>
                    <FormField
                      label='Paper Width'
                      htmlFor='custom-paper-width'
                      disabled={isLoading}
                      layoutPreset={unresponsivePreset({ layout: 'row', labelSize: 120 })}
                      invalid={!formValues.fields.customPaperWidth.valid}
                    >
                      <DecimalInput
                        id='custom-paper-width'
                        valid={formValues.fields.customPaperWidth.valid}
                        disabled={formValues.fields.customPaperWidth.disabled || isLoading}
                        value={formValues.values.customPaperWidth}
                        onChange={(value) => {
                          setFormValues(formValues.setValue('customPaperWidth', { value }));
                        }}
                        metric={metric}
                      />
                    </FormField>
                    {/* Paper height */}
                    <FormField
                      htmlFor='custom-paper-height'
                      label='Paper Height'
                      disabled={isLoading}
                      layoutPreset={unresponsivePreset({ layout: 'row', labelSize: 120 })}
                      invalid={!formValues.fields.customPaperHeight.valid}
                    >
                      <DecimalInput
                        id='custom-paper-height'
                        valid={formValues.fields.customPaperHeight.valid}
                        disabled={formValues.fields.customPaperHeight.disabled || isLoading}
                        value={formValues.values.customPaperHeight}
                        onChange={(value) => {
                          setFormValues(formValues.setValue('customPaperHeight', { value }));
                        }}
                        metric={metric}
                      />
                    </FormField>
                    <FormSubtext type={FormSubtextType.Help}>
                      {metric
                        ? `Custom paper size must be between ${MIN_CUSTOM_PAPER_DIMENSION_MM} and ${MAX_CUSTOM_PAPER_DIMENSION_MM} mm`
                        : `Custom paper size must be between ${MIN_CUSTOM_PAPER_DIMENSION_INCH} and ${MAX_CUSTOM_PAPER_DIMENSION_INCH} inches`}
                    </FormSubtext>
                  </FormLayout>
                </FormLayout>
              </FormSection>
              {/* Page fit & print layout preview section */}
              <FormSection className='form-section-background' padding={12}>
                <FormLayout
                  desktopLayout={{ layout: 'row', xAlign: 'stretch', yAlign: 'stretch', gap: 12 }}
                  mobileLayout={{ xAlign: 'stretch', yAlign: 'stretch', gap: 12 }}
                >
                  {/* Fit section */}
                  <FormField fakeLabel legend={`Fit to (up to ${MAX_PAGE_COUNT})`} desktopLayout={{ layout: 'column' }} disabled={isLoading}>
                    <FormLayout desktopLayout={{ layout: 'column', gap: 10 }}>
                      {/* Single page option */}
                      <FormField htmlFor='single-page-radio' label='Single Page' layoutPreset={FORM_FIELD_PRESETS.Radio}>
                        <input
                          type='radio'
                          name='fit-to'
                          id='single-page-radio'
                          disabled={isLoading}
                          checked={formValues.values.fitMode === 'both'}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFormValues(formValues.setValue('fitMode', { value: 'both' }));
                            }
                          }}
                        />
                      </FormField>
                      {/* Pages across option */}
                      <FormField
                        label={
                          <FormField
                            fakeLabel
                            label='Pages Across'
                            layoutPreset={unresponsivePreset({
                              layout: 'row',
                              labelAlignX: 'left',
                              labelSize: 120,
                            })}
                            invalid={!formValues.fields.fitPagesAcross.valid}
                          >
                            <InputContainer size={75}>
                              <input
                                type='number'
                                value={formValues.values.fitPagesAcross}
                                disabled={isLoading}
                                min={MIN_PAGE_COUNT}
                                max={MAX_PAGE_COUNT}
                                step={1}
                                onChange={(e) => {
                                  setFormValues(
                                    formValues.setValue('fitPagesAcross', {
                                      value: parseInt(e.target.value, 10),
                                    })
                                  );
                                }}
                                onFocus={() => setFormValues(formValues.setValue('fitMode', { value: 'across' }))}
                                onBlur={() =>
                                  setFormValues(
                                    formValues.setValue('fitPagesAcross', {
                                      value: forceIntBetween(formValues.values.fitPagesAcross, MIN_PAGE_COUNT, MAX_PAGE_COUNT),
                                    })
                                  )
                                }
                              />
                            </InputContainer>
                          </FormField>
                        }
                        htmlFor='pages-across-radio'
                        noLabelPadding
                        layoutPreset={FORM_FIELD_PRESETS.Radio}
                      >
                        <input
                          type='radio'
                          name='fit-to'
                          id='pages-across-radio'
                          disabled={isLoading}
                          checked={formValues.values.fitMode === 'across'}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFormValues(formValues.setValue('fitMode', { value: 'across' }));
                            }
                          }}
                        />
                      </FormField>
                      {/* Pages Down option */}
                      <FormField
                        label={
                          <FormField
                            fakeLabel
                            label='Pages Down'
                            layoutPreset={unresponsivePreset({
                              layout: 'row',
                              labelAlignX: 'left',
                              labelSize: 120,
                            })}
                            invalid={!formValues.fields.fitPagesDown.valid}
                          >
                            <InputContainer size={75}>
                              <input
                                type='number'
                                value={formValues.values.fitPagesDown}
                                min={MIN_PAGE_COUNT}
                                max={MAX_PAGE_COUNT}
                                step={1}
                                disabled={isLoading}
                                onChange={(e) => {
                                  setFormValues(
                                    formValues.setValue('fitPagesDown', {
                                      value: parseInt(e.target.value, 10),
                                    })
                                  );
                                }}
                                onFocus={() => setFormValues(formValues.setValue('fitMode', { value: 'down' }))}
                                onBlur={() =>
                                  setFormValues(
                                    formValues.setValue('fitPagesDown', {
                                      value: forceIntBetween(formValues.values.fitPagesDown, MIN_PAGE_COUNT, MAX_PAGE_COUNT),
                                    })
                                  )
                                }
                              />
                            </InputContainer>
                          </FormField>
                        }
                        htmlFor='pages-down-radio'
                        noLabelPadding
                        layoutPreset={FORM_FIELD_PRESETS.Radio}
                      >
                        <input
                          type='radio'
                          name='fit-to'
                          id='pages-down-radio'
                          disabled={isLoading}
                          checked={formValues.values.fitMode === 'down'}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFormValues(formValues.setValue('fitMode', { value: 'down' }));
                            }
                          }}
                        />
                      </FormField>
                    </FormLayout>
                  </FormField>
                  {/* Print preview */}
                  <PrintPreview pagesAcross={pages.across} pagesDown={pages.down} paperSize={printSettings.paperSize} orientation={printSettings.orientation} />
                </FormLayout>
              </FormSection>
              {/* Plan Display Settings (show background image) */}
              {showPlanDisplaySettingsSection ? (
                <FormSection className='form-section-background' padding={12}>
                  <FormField
                    label='Show Background Image'
                    htmlFor='print-plan:background-image'
                    layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
                    disabled={formValues.fields.showBackgroundImage.disabled}
                  >
                    <input
                      type='checkbox'
                      id='print-plan:background-image'
                      checked={formValues.values.showBackgroundImage}
                      disabled={formValues.fields.showBackgroundImage.disabled}
                      onChange={(e) => setFormValues(formValues.setValue('showBackgroundImage', { value: e.target.checked }))}
                    />
                  </FormField>
                </FormSection>
              ) : null}
              {/* Download explanation */}
              {!IS_IOS ? (
                <FormSection padding={0}>
                  <p>
                    Downloading the PDF will automatically save it to your downloads folder on your machine or device, please check your downloads folder once
                    completed.
                  </p>
                </FormSection>
              ) : null}
              {/* Print progress/errors */}
              {printProgressSection}
            </ModalPaneContent>
          </ModalPane>
        </ModalPaneContainer>
        {/* Footer buttons */}
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' disabled={pdf.status === LoadingState.LOADING} onClick={tryClose}>
                Close
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>{footerButtons}</ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PrintModal;
