import React from 'react';

import { Filters, updateFilters } from '@gi/filters';
import { HelpButton } from '@gi/base-components';

interface iProps {
  onFilterChange: (filters: Filters<any, any>) => void;
  filters: Filters<any, any>;
  className?: string;
  filterKey: string;
  labelText: string;
  inverted?: boolean;
  onHelpClick?: () => void;
}

const SimpleCheckboxFilter = ({ filters, onFilterChange, className = '', filterKey, labelText, inverted, onHelpClick }: iProps): JSX.Element => {
  const onChange = (e) => {
    onFilterChange(
      updateFilters(filters, {
        [filterKey]: {
          enabled: inverted ? !e.target.checked : e.target.checked,
        },
      })
    );
  };

  const value = filters.filters[filterKey].inputs.enabled;

  return (
    <label className={`filter-row-label filter-row-checkbox ${className}`}>
      <span className='filter-row-label-content'>
        <input className='filter-box' type='checkbox' onChange={onChange} checked={inverted ? !value : value} />
      </span>
      <span className='filter-row-label-text'>
        {labelText}
        {onHelpClick ? <HelpButton onClick={onHelpClick} className='filter-row-label-help' /> : null}
      </span>
    </label>
  );
};

export default SimpleCheckboxFilter;
