import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalActionCreators } from '@gi/garden-platform-modal-renderer';
import { OpenPlanModalActionCreators } from '@gi/open-plan-modal';

import UpdatesModal from '../updates-modal/updates-modal';

import './get-started-section.scss';

const GetStartedSection = (): JSX.Element => {
  const dispatch = useDispatch();
  const [updatesModalOpen, setUpdatesModalOpen] = useState(false);

  return (
    <>
      {updatesModalOpen ? <UpdatesModal close={() => setUpdatesModalOpen(false)} /> : null}
      <div className='get-started-section'>
        <div className='dashboard-block'>
          <div className='start-buttons'>
            <div className='start-button'>
              <button type='button' className='dashboard-button planner-dashboard-button' onClick={() => dispatch(OpenPlanModalActionCreators.open())}>
                Open Plan
              </button>
              <p>Open an existing plan in the Garden Planner</p>
            </div>
            <div className='start-button'>
              <button type='button' className='dashboard-button planner-dashboard-button' onClick={() => dispatch(ModalActionCreators.openNewPlanModal())}>
                Create New Plan
              </button>
              <p>Create a new plan in the Garden Planner</p>
            </div>
          </div>
        </div>
        <div className='dashboard-block padded background'>
          <div>
            Find out about the latest updates{' '}
            <button type='button' className='button button-inline' onClick={() => setUpdatesModalOpen(true)}>
              here
            </button>
            .
          </div>
        </div>
      </div>
    </>
  );
};

export default GetStartedSection;
