import React from 'react';
import { createPortal } from 'react-dom';

// Use the tutorial root, the system is the same
const root = document.getElementById('tutorial-root');

interface iProps {
  children: React.ReactNode;
}

const ProductWindowPortal = ({ children }: iProps): JSX.Element | null => {
  if (!root) {
    console.error('Non tutorial root element found');
    return null;
  }

  return createPortal(children, root);
};

export default ProductWindowPortal;
