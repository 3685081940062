import React from 'react';
import { HelpModal, ModalPaneSection } from '@gi/modal';

import './companion-modal.scss';

interface iProps {
  closeModal: () => void;
}

const CompanionModal = ({ closeModal }: iProps): JSX.Element => {
  return (
    <HelpModal headerText='Companion Planting in the Garden Planner' closeModal={closeModal} style={{ maxWidth: 520 }}>
      <ModalPaneSection>
        <p>
          Click on a plant in your plan to select it, then click on the Show Companions button at the top of the plant menu. All suitable companion plants will
          then be displayed in the plant menu.
        </p>
        <p>To find companions for multiple plants, hold down your Shift key when selecting another plant.</p>
        <p>Click Clear to return to viewing all plants.</p>
      </ModalPaneSection>
    </HelpModal>
  );
};

export default CompanionModal;
