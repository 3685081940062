import React from 'react';

import { PlantListFilterInputs } from '@gi/plant-list';

import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';
import { getTopbarSelectStyles } from '../common/select-styles';

const SELECT_STYLES = getTopbarSelectStyles(190);

const PlantListSortControls = (): JSX.Element => {
  return (
    <ControlGroup>
      <ControlRow>
        <PlantListFilterInputs.SortSelect styles={SELECT_STYLES} />
      </ControlRow>
    </ControlGroup>
  );
};

export default PlantListSortControls;
