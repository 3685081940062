import React, { useContext } from 'react';

import { PlannerCommandContext } from '@gi/app-planner-desktop';
import { ElementPrinterContext } from '@gi/element-printer';

import MobilePlanMenuButton from '../mobile-plan-menu-button';
import MobileSharedActiveControls from './mobile-shared-active-controls';

interface iProps {
  callAndClose: (callback: null | (() => void)) => () => void;
}

const MobilePlantListControls = ({ callAndClose }: iProps): JSX.Element => {
  const { printPlantList } = useContext(PlannerCommandContext);
  const { isPrinting } = useContext(ElementPrinterContext);

  return (
    <>
      <MobileSharedActiveControls callAndClose={callAndClose} />
      <MobilePlanMenuButton leftIcon='icon-print' onClick={callAndClose(printPlantList)} loading={isPrinting} disabled={isPrinting}>
        Print Plant List
      </MobilePlanMenuButton>
    </>
  );
};

export default MobilePlantListControls;
