import React, { ReactNode, forwardRef, useContext, useMemo } from 'react';

import { Direction } from '@gi/constants';
import { Tooltip, TooltipContent, TooltipKey, TooltipTrigger } from '@gi/tooltip';

import { SidebarContext } from './sidebar-context';

import styles from './sidebar-styles.module.css';

type iProps = {
  tabId: string;
  className?: string;
  children?: ReactNode;
  tooltipKey?: TooltipKey;
};

const SidebarButton = forwardRef<HTMLButtonElement, iProps>(({ tabId, className, children, tooltipKey, ...remainingProps }, refProp): JSX.Element => {
  const { toggleTab, activeTab, minimised, orientation } = useContext(SidebarContext);

  const isActive = useMemo(() => activeTab === tabId && !minimised, [activeTab, tabId, minimised]);

  const classNames: string[] = [styles.button];
  if (isActive) {
    classNames.push(styles.active);
  }
  if (className) {
    classNames.push(className);
  }

  const buttonContent = (
    <button ref={refProp} type='button' className={classNames.join(' ')} onClick={() => toggleTab(tabId)} data-active={isActive} {...remainingProps}>
      {children}
    </button>
  );

  if (tooltipKey) {
    return (
      <Tooltip placement={orientation === Direction.LEFT ? 'right' : 'left'}>
        <TooltipTrigger>{buttonContent}</TooltipTrigger>
        <TooltipContent id={tooltipKey} />
      </Tooltip>
    );
  }

  return buttonContent;
});
SidebarButton.displayName = 'SidebarButton';

export default SidebarButton;
