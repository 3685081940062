import React from 'react';
import type { Tutorial } from '@gi/tutorial';

import styles from './tutorial-selection-list.module.css';

interface iProps {
  tutorial: Tutorial;
  onSelect: (tutorial: Tutorial) => void;
  selected: boolean;
}

const Item = ({ tutorial, onSelect, selected }: iProps): JSX.Element => {
  return (
    <li className={`${styles.item} ${selected ? styles.selectedItem : ''}`}>
      <button type='button' onClick={() => onSelect(tutorial)} className={styles.itemButton}>
        {tutorial.name}
      </button>
    </li>
  );
};

export default Item;
