import { FormFieldOptions, FormFieldOptionsPreset } from './form-field';
import { unresponsivePreset } from './types';

const Blank = unresponsivePreset<FormFieldOptions>({
  layout: 'blank',
});

/** Label always appears above the input */
const Column = unresponsivePreset<FormFieldOptions>({
  layout: 'column',
});

/** Label always appears to the side of the input */
const AlwaysDesktop = unresponsivePreset<FormFieldOptions>({
  layout: 'row',
  labelSize: 'default',
});

/** Radio input field, with radio button on the left */
const Radio = unresponsivePreset<FormFieldOptions>({
  layout: 'row-reverse',
  labelSize: 'full',
});

/** Checkbox on the right, label on the left with a fixed width */
const Checkbox: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
  },
  mobile: {
    layout: 'row-reverse',
  },
};

/** Checkbox on the right, with the label taking up as much room as necessary */
const CheckboxLong: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
    labelSize: 'auto',
  },
  mobile: {
    layout: 'row-reverse',
    labelSize: 'auto',
  },
};

/** Checkbox on the left, label on the right */
const CheckboxLeft = unresponsivePreset<FormFieldOptions>({
  layout: 'row-reverse',
  labelSize: 'auto',
});

/** Small labels for inputs on a modal */
const ModalInput: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
    labelSize: 90,
  },
  mobile: {
    layout: 'row',
    labelSize: 90,
  },
};

/** Any inputs in an edit modal. Slightly smaller labels on mobile */
const EditModal: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
    labelSize: 120,
    labelAlignY: 'center',
  },
  mobile: {
    layout: 'row',
    labelSize: 95,
    labelAlignY: 'center',
  },
};

/** Vector inputs in an edit modal. Accounts for the x/y labels of the vector inputs so everything lines up */
const EditModalVector: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
    labelSize: 120 - 16,
    labelAlignY: 'center',
  },
  mobile: {
    layout: 'row',
    labelSize: 95 - 16,
    labelAlignY: 'center',
  },
};

/** Layout presets for FormFields. Use in the `layoutPreset` prop */
export const FORM_FIELD_PRESETS = {
  Blank,
  Column,
  AlwaysDesktop,
  Radio,
  Checkbox,
  CheckboxLong,
  CheckboxLeft,
  ModalInput,
  EditModal,
  EditModalVector,
};
