import { BatchShaderGenerator } from 'pixi.js-new';

/**
 * Generates shader code for the ShadedSpriteRenderer.
 * Will extract the colour of both the base texture and the mask to `color` and `mask` respectively.
 */
class ShadedSpriteShaderGenerator extends BatchShaderGenerator {
  // eslint-disable-next-line class-methods-use-this
  generateSampleSrc(maxTextures: number): string {
    let src = '';

    src += '\n';
    src += '\n';

    for (let i = 0; i < maxTextures; i++) {
      if (i > 0) {
        src += '\nelse ';
      }

      if (i < maxTextures - 1) {
        src += `if(vTextureId.x < ${i}.5)`;
      }

      src += '\n{';
      src += `\n\tcolor = texture2D(uSamplers[${i}], baseTextureCoord);`;
      src += '\n}';
    }

    src += '\n';
    src += '\n';

    for (let i = 0; i < maxTextures; i++) {
      if (i > 0) {
        src += '\nelse ';
      }

      if (i < maxTextures - 1) {
        src += `if(vTextureId.y < ${i}.5)`;
      }

      src += '\n{';
      src += `\n\tmask = texture2D(uSamplers[${i}], maskTextureCoord);`;
      src += '\n}';
    }

    src += '\n';
    src += '\n';

    return src;
  }
}

export default ShadedSpriteShaderGenerator;
