import { Plan } from './plan';

const MAX_UNDO_STAGES = 100;

export type PlanUndoStage = Pick<
  Plan,
  | 'width'
  | 'height'
  | 'maxItemId'
  | 'itemTypes'
  | 'plants'
  | 'plantIds'
  | 'gardenObjects'
  | 'gardenObjectIds'
  | 'shapes'
  | 'shapeIds'
  | 'text'
  | 'textIds'
  | 'backgroundImage'
>;

export type PlanUndoStack = {
  position: number;
  stages: PlanUndoStage[];
};

export function createUndoStage(plan: Plan): PlanUndoStage {
  return {
    width: plan.width,
    height: plan.height,
    maxItemId: plan.maxItemId,
    itemTypes: plan.itemTypes,
    plants: plan.plants,
    plantIds: plan.plantIds,
    gardenObjects: plan.gardenObjects,
    gardenObjectIds: plan.gardenObjectIds,
    shapes: plan.shapes,
    shapeIds: plan.shapeIds,
    text: plan.text,
    textIds: plan.textIds,
    backgroundImage: plan.backgroundImage,
  };
}

export function createUndoStack(plan: Plan): PlanUndoStack {
  return {
    stages: [createUndoStage(plan)],
    position: 0,
  };
}

function undoStagesEqual(a: PlanUndoStage, b: PlanUndoStage): boolean {
  return (
    a.width === b.width &&
    a.height === b.height &&
    a.maxItemId === b.maxItemId &&
    a.itemTypes === b.itemTypes &&
    a.plants === b.plants &&
    a.plantIds === b.plantIds &&
    a.gardenObjects === b.gardenObjects &&
    a.gardenObjectIds === b.gardenObjectIds &&
    a.shapes === b.shapes &&
    a.shapeIds === b.shapeIds &&
    a.text === b.text &&
    a.textIds === b.textIds &&
    a.backgroundImage === b.backgroundImage
  );
}

export function planEqualsUndoStage(plan: Plan, undoStage: PlanUndoStage) {
  return (
    plan.width === undoStage.width &&
    plan.height === undoStage.height &&
    plan.maxItemId === undoStage.maxItemId &&
    plan.itemTypes === undoStage.itemTypes &&
    plan.plants === undoStage.plants &&
    plan.plantIds === undoStage.plantIds &&
    plan.gardenObjects === undoStage.gardenObjects &&
    plan.gardenObjectIds === undoStage.gardenObjectIds &&
    plan.shapes === undoStage.shapes &&
    plan.shapeIds === undoStage.shapeIds &&
    plan.text === undoStage.text &&
    plan.textIds === undoStage.textIds &&
    plan.backgroundImage === undoStage.backgroundImage
  );
}

export function undo(plan: Plan, undoStack: PlanUndoStack): [Plan, PlanUndoStack] {
  if (undoStack.position >= undoStack.stages.length - 1) {
    console.warn('Attempted to undo but no more undo stages available');
    return [plan, undoStack];
  }

  const updatedUndoStack = {
    ...undoStack,
    position: undoStack.position + 1,
  };

  const undoStage = undoStack.stages[updatedUndoStack.position];

  const updatedPlan: Plan = {
    ...plan,
    width: undoStage.width,
    height: undoStage.height,
    maxItemId: undoStage.maxItemId,
    itemTypes: undoStage.itemTypes,
    plants: undoStage.plants,
    plantIds: undoStage.plantIds,
    gardenObjects: undoStage.gardenObjects,
    gardenObjectIds: undoStage.gardenObjectIds,
    shapes: undoStage.shapes,
    shapeIds: undoStage.shapeIds,
    text: undoStage.text,
    textIds: undoStage.textIds,
    backgroundImage: undoStage.backgroundImage,
  };

  return [updatedPlan, updatedUndoStack];
}

export function redo(plan: Plan, undoStack: PlanUndoStack): [Plan, PlanUndoStack] {
  if (undoStack.position <= 0) {
    console.warn('Attempted to redo but no more redo stages available');
    return [plan, undoStack];
  }

  const updatedUndoStack = {
    ...undoStack,
    position: undoStack.position - 1,
  };

  const undoStage = undoStack.stages[updatedUndoStack.position];

  const updatedPlan: Plan = {
    ...plan,
    width: undoStage.width,
    height: undoStage.height,
    maxItemId: undoStage.maxItemId,
    itemTypes: undoStage.itemTypes,
    plants: undoStage.plants,
    plantIds: undoStage.plantIds,
    gardenObjects: undoStage.gardenObjects,
    gardenObjectIds: undoStage.gardenObjectIds,
    shapes: undoStage.shapes,
    shapeIds: undoStage.shapeIds,
    text: undoStage.text,
    textIds: undoStage.textIds,
    backgroundImage: undoStage.backgroundImage,
  };

  return [updatedPlan, updatedUndoStack];
}

export function addStackUndoStage(undoStack: PlanUndoStack, plan: Plan, maxUndoStages: number = MAX_UNDO_STAGES): PlanUndoStack {
  // Create new undo stack; whenever adding a new undo stack the position will always become 0 so we only want to keep
  // the stages after what the previous position was set to
  const newUndoStack = {
    ...undoStack,
    stages: undoStack.stages.slice(undoStack.position),
    position: 0,
  };

  const newUndoStage = createUndoStage(plan);

  if (newUndoStack.stages.length > 0 && undoStagesEqual(newUndoStage, newUndoStack.stages[0])) {
    // This change is the same as the previous change, we don't need to add a new change
    return undoStack;
  }

  newUndoStack.stages.unshift(newUndoStage);

  // Bounds check for undo stages going over the stage limit
  if (newUndoStack.stages.length > maxUndoStages) {
    newUndoStack.stages.length = maxUndoStages;
  }

  // Bounds check for the undo position
  if (newUndoStack.position >= newUndoStack.stages.length) {
    newUndoStack.position = newUndoStack.stages.length - 1;
  }

  return newUndoStack;
}
