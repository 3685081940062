import moment from 'moment-timezone';
import { UserAuthToken } from '@gi/user';

export function getExpiryTime(authToken: string): number {
  const token = JSON.parse(atob(authToken.split('.')[1])) as UserAuthToken;
  return token.exp;
}

export function getTimeUntilExpiry(authToken: string): number {
  const expiryTime = moment.unix(getExpiryTime(authToken));
  const remainingTime = expiryTime.diff(moment(), 'second');
  return remainingTime;
}
