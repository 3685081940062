import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import ReactSelect from 'react-select';

import { DistanceUnitsShape, metricDistanceUnits } from '@gi/units';
import Plant from '@gi/plant';
import { SupplierCatalogueUtils, SuppliersUtils } from '@gi/supplier';
import { lexographicSort } from '@gi/utils';
import { UserUtils, UserShape, DEFAULT_USER_PLANT_VARIETY, UserPlantVarietyUtils, UserPlantVarietySetUtils } from '@gi/user';
import { DEFAULT_VARIETY } from '@gi/constants';
import LengthInput from '@gi/length-input';
import { calculateUsersPlantingCalendar, PlantingCalendarInput } from '@gi/planting-calendar';
import { ResourceContext } from '@gi/resource-provider';

import PlannerIcon from '@gi/planner-icon';
import Collection from '@gi/collection';
import LoadingButton from '@gi/loading-button';
import { HelpButton } from '@gi/base-components';
import { PlantSpacingHelpModal } from '@gi/help-modals';

import Modal, {
  ModalPane,
  ModalPaneHeader,
  ModalPaneContent,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalPaneSection,
  ModalPaneSectionContent,
  ModalPaneSectionHeader,
  ModalHelpButton,
  ModalPaneSectionGroup,
  ModalHeaderTitle,
  ModalHeaderIcon,
  ModalHeaderContent,
} from '@gi/modal';

import { CustomFormRow } from '@gi/form';
import AddVarietyModal from '@gi/add-variety-modal';
import FormField, { FORM_FIELD_PRESETS, FormLayout, FormSection, InputContainer } from '@gi/form-responsive';

import './customise-varieties-modal.scss';

import CustomiseVarietiesHelpModal from './customise-varieties-help-modal';
import ImportVarietySettingsModal from './import-variety-settings-modal';
import ResetVarietyModal from './reset-variety-modal';
import DeleteVarietyModal from './delete-variety-modal';
import CustomiseVarietiesConfirmCloseModal from './customise-varieties-confirm-close-modal';
import FormatVarietyOptionLabel from './format-variety-option-label';

// Small function which auto-highlights text on an input upon focus
const handleFocus = (event) => event.target.select();

const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '34px',
    minWidth: 'unset',
    maxWidth: '100%',
    width: '100%',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px',
  }),
  input: (provided) => ({
    ...provided,
    paddingBottom: '0',
    paddingTop: '0',
    margin: '0',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  singleValue: (provided) => ({
    ...provided,
    width: '100%',
    paddingRight: '8px',
  }),
};

const SPACING_FIELD_PRESET = {
  desktop: {
    labelSize: 160,
  },
};

const SQUARE_FOOT_PLANT_MIN = 1;
const SQUARE_FOOT_PLANT_MAX = 99;

const FilterTypes = {
  ALL: 'ALL',
  USER: 'USER',
  SUPPLIER: 'SUPPLIER',
};

const getFilterOption = (supplier) => {
  return { type: FilterTypes.SUPPLIER, value: supplier.ID, label: `${supplier.name}` };
};

const lexographicSupplierSort = (a, b) => {
  return lexographicSort(a.name, b.name);
};

class CustomiseVarietiesModal extends PureComponent {
  constructor(props) {
    super(props);

    // TODO - Potentially add any plant modifiers to this (such as a greenhouse, cloche, etc)
    this.defaultPlantingCalendar = calculateUsersPlantingCalendar(props.plant, props.user, props.userRegionalPlantingCalendar).numbered;

    this.state = {
      variety: this.createOption(props.defaultVariety, props.plant.code, props.supplierCatalogue, props.user.plantVarieties),
      userPlantVarieties: props.user.plantVarieties,
      addVarietyOpen: false,
      importVarietyOpen: false,
      deleteModalOpen: false,
      confirmCloseModalOpen: false,
      editing: false,
      filter: { type: FilterTypes.ALL, value: FilterTypes.ALL, label: 'All Sources' },
      editingVariety: this.getUserVariety(props.defaultVariety, props.user.plantVarieties),
      showSpacingHelpModal: false,
    };
  }

  componentDidMount() {
    const filterOptions = this.getFilterOptions(this.props.plant.code, this.props.supplierCatalogue, this.context.suppliers, this.props.user.plantVarieties);

    // Auto select 'All sources' as default filter
    const filter = filterOptions[1];

    this.setState({ filter });
  }

  onEditingStart = () => {
    this.setState({
      editing: true,
    });
  };

  onEditingDone = () => {
    // Check inputs for being valid
    const validSpacing = UserPlantVarietyUtils.validSpacing(this.state.editingVariety) || !this.state.editingVariety.customSpacings;
    const validInRowSpacing = UserPlantVarietyUtils.validInRowSpacing(this.state.editingVariety) || !this.state.editingVariety.customSpacings;
    const validRowSpacing = UserPlantVarietyUtils.validRowSpacing(this.state.editingVariety) || !this.state.editingVariety.customSpacings;
    const validSquareFootCount =
      UserPlantVarietyUtils.validSquareFootPlantingCount(this.state.editingVariety) ||
      !this.props.plant.canBeSquareFootPlant ||
      !this.state.editingVariety.customSpacings;

    const allValid = validSpacing && validInRowSpacing && validRowSpacing && validSquareFootCount;

    if (!allValid) {
      // Button should be disabled if not valid
      console.error('Editing done called with invalid inputs');
      return;
    }

    this.updateVariety(this.state.editingVariety, {
      editing: false,
    });
  };

  onEditingCancel = () => {
    // Revert inputs back to their pre-editing state
    const currentVariety = this.getCurrentUserPlantVariety();

    this.setState({
      editing: false,
      editingVariety: currentVariety,
    });
  };

  onOpenHelpModal = () => {
    this.setState({
      helpModalOpen: true,
    });
  };

  onCloseHelpModal = () => {
    this.setState({
      helpModalOpen: false,
    });
  };

  onClose = () => {
    if (this.state.userPlantVarieties === this.props.user.plantVarieties) {
      this.props.closeModal();
    } else {
      this.setState({
        confirmCloseModalOpen: true,
      });
    }
  };

  onConfirmClose = () => {
    this.props.closeModal();
  };

  onConfirmCancel = () => {
    this.setState({
      confirmCloseModalOpen: false,
    });
  };

  onOpenAddVariety = () => {
    this.setState({
      addVarietyOpen: true,
    });
  };

  onCloseAddVarietyModal = () => {
    this.setState({
      addVarietyOpen: false,
    });
  };

  onAddVarietyDone = (name) => {
    const newUserVariety = {
      ...DEFAULT_USER_PLANT_VARIETY,
      plantCode: this.props.plant.code,
      name,
      spacing: this.props.plant.spacing,
      inRowSpacing: this.props.plant.inRowSpacing,
      rowSpacing: this.props.plant.rowSpacing,
      squareFootPlantCount: this.props.plant.squareFootPlantCount,
      plantingCalendar: this.defaultPlantingCalendar,
    };

    this.setState((state) => {
      const newUserVarieties = UserPlantVarietySetUtils.addVariety(state.userPlantVarieties, newUserVariety);
      const newSelectedVariety = this.createOption(name, this.props.plant.code, this.props.supplierCatalogue, newUserVarieties);

      return {
        userPlantVarieties: newUserVarieties,
        addVarietyOpen: false,
        variety: newSelectedVariety,
        editingVariety: this.getUserVariety(name, newUserVarieties),
      };
    });
  };

  onFilterChange = (obj) => {
    this.setState((state) => {
      const varietyFilter = this.getVarietyFilter(obj, this.props.plant.code, this.props.supplierCatalogue, state.userPlantVarieties);
      const varieties = this.getPlantVarietyOptions(this.props.plant.code, this.props.supplierCatalogue, state.userPlantVarieties, varietyFilter);

      return {
        filter: obj,
        variety: varieties[0],
      };
    });
  };

  onVarietyChange = (obj) => {
    this.setState({
      variety: obj,
      editingVariety: this.getUserVariety(obj.value),
    });
  };

  onImportVarietyOpen = () => {
    this.setState({
      importVarietyOpen: true,
    });
  };

  onImportVarietyCancel = () => {
    this.setState({
      importVarietyOpen: false,
    });
  };

  onImportVariety = ({ variety, copyPlantingCalendar, copySpacing, sourcePlant }) => {
    if (!copyPlantingCalendar && !copySpacing) {
      this.setState({
        importVarietyOpen: false,
      });
    }

    let varietyToUpdate = this.getCurrentUserPlantVariety();

    if (copySpacing) {
      varietyToUpdate = {
        ...varietyToUpdate,
        customSpacings: true,
        spacing: variety.spacing,
        inRowSpacing: variety.inRowSpacing,
        rowSpacing: variety.rowSpacing,
      };

      if (sourcePlant.canBeSquareFootPlant) {
        varietyToUpdate = {
          ...varietyToUpdate,
          squareFootPlantCount: variety.squareFootPlantCount,
        };
      }
    }

    if (copyPlantingCalendar) {
      varietyToUpdate = {
        ...varietyToUpdate,
        customCalendar: true,
        plantingCalendar: variety.plantingCalendar,
      };
    }

    this.updateVariety(varietyToUpdate, {
      importVarietyOpen: false,
    });
  };

  onResetVarietyOpen = () => {
    this.setState({
      resetVarietyOpen: true,
    });
  };

  onResetVarietyCancel = () => {
    this.setState({
      resetVarietyOpen: false,
    });
  };

  onResetVariety = () => {
    let varietyToUpdate = this.getCurrentUserPlantVariety();

    // TODO: regional planting times
    const plantingCalendar = calculateUsersPlantingCalendar(this.props.plant, this.props.user, this.props.userRegionalPlantingCalendar);

    varietyToUpdate = {
      ...varietyToUpdate,
      customSpacings: false,
      spacing: this.props.plant.spacing,
      inRowSpacing: this.props.plant.inRowSpacing,
      rowSpacing: this.props.plant.rowSpacing,
      customCalendar: false,
      plantingCalendar: plantingCalendar.numbered,
    };

    this.updateVariety(varietyToUpdate, {
      resetVarietyOpen: false,
    });
  };

  onDeleteVarietyOpen = () => {
    this.setState({
      deleteModalOpen: true,
    });
  };

  onDeleteVarietyCancel = () => {
    this.setState({
      deleteModalOpen: false,
    });
  };

  onDeleteVariety = () => {
    this.setState((state) => {
      const updatedUserPlantVarieties = UserPlantVarietySetUtils.removeVariety(state.userPlantVarieties, this.props.plant.code, state.variety.value);

      return {
        userPlantVarieties: updatedUserPlantVarieties,
        deleteModalOpen: false,
        variety: this.createOption('-', this.props.plant.code, this.props.supplierCatalogue, updatedUserPlantVarieties),
        editingVariety: this.getUserVariety('-'),
      };
    });
  };

  onUseCustomSpacingsChange = (e) => {
    this.setEditingVarietyProperty('customSpacings', e.target.checked);
  };

  onSpacingChange = (value) => {
    this.setEditingVarietyProperty('spacing', value);
  };

  onInRowSpacingChange = (value) => {
    this.setEditingVarietyProperty('inRowSpacing', value);
  };

  onRowSpacingChange = (value) => {
    this.setEditingVarietyProperty('rowSpacing', value);
  };

  onSquareFootCountChange = (e) => {
    let val = parseInt(e.target.value, 10);

    if (Number.isNaN(val)) {
      val = 0;
    }

    this.setEditingVarietyProperty('squareFootPlantCount', val);
  };

  onCustomPlantingCalendarChange = (e) => {
    this.setEditingVarietyProperty('customCalendar', e.target.checked);
  };

  onCalendarChange = (plantingCalendar) => {
    this.setEditingVarietyProperty('plantingCalendar', plantingCalendar);
  };

  onSave = () => {
    this.props.onSave(this.state.userPlantVarieties, this.state.variety.value);
  };

  /**
   * Note: this is called in the constructor when no state is available.
   * Must be called with userPlantVarieties when no state is available.
   *
   * @param {string} varietyName
   * @returns {UserPlantVariety}
   * @memberof CustomiseVarietiesModal
   */
  getUserVariety(varietyName, userPlantVarieties = null) {
    let _userPlantVarieties = null;

    if (userPlantVarieties !== null) {
      _userPlantVarieties = userPlantVarieties;
    } else {
      _userPlantVarieties = this.state.userPlantVarieties;
    }

    if (UserPlantVarietySetUtils.hasVariety(_userPlantVarieties, this.props.plant.code, varietyName)) {
      return UserPlantVarietySetUtils.getByPlantCodeAndName(_userPlantVarieties, this.props.plant.code, varietyName);
    }

    return {
      ...DEFAULT_USER_PLANT_VARIETY,
      name: varietyName,
      plantCode: this.props.plant.code,
      spacing: this.props.plant.spacing,
      inRowSpacing: this.props.plant.inRowSpacing,
      rowSpacing: this.props.plant.rowSpacing,
      squareFootPlantCount: this.props.plant.squareFootPlantCount,
      plantingCalendar: this.defaultPlantingCalendar,
    };
  }

  /**
   * Returns a UserPlantVariety for the currently selected variety
   *
   * If a user variety does not exist for this variety then one is created
   *
   * @returns {UserPlantVariety}
   * @memberof CustomiseVarietiesModal
   */
  getCurrentUserPlantVariety() {
    return this.getUserVariety(this.state.variety.value);
  }

  getPlantVarietyOptions = memoizeOne((plantCode, supplierCatalogue, userPlantVarieties, varietyFilter) => {
    // Create a sorted list of all variety names in both the catalogue and
    const varietyNameSet = new Set([
      ...SupplierCatalogueUtils.getAllVarietiesForPlant(supplierCatalogue, plantCode),
      ...UserPlantVarietySetUtils.getByPlantCode(userPlantVarieties, plantCode).map((userPlantVariety) => userPlantVariety.name),
    ]);
    if (!varietyNameSet.has(DEFAULT_VARIETY)) {
      varietyNameSet.add(DEFAULT_VARIETY);
    }

    const varietyNames = [...varietyNameSet].sort(lexographicSort);

    const options = [];

    for (let i = 0; i < varietyNames.length; i++) {
      options.push(this.createOption(varietyNames[i], plantCode, supplierCatalogue, userPlantVarieties));
    }

    return options.filter(varietyFilter);
  });

  getFilterOptions = memoizeOne((plantCode, supplierCatalogue, allSuppliers, userVarieties) => {
    return [
      {
        type: FilterTypes.USER,
        value: FilterTypes.USER,
        label: 'Your Customized Varieties',
        disabled: UserPlantVarietySetUtils.getByPlantCode(userVarieties, plantCode).length === 0,
      },
      { type: FilterTypes.ALL, value: FilterTypes.ALL, label: 'All Sources' },
      ...allSuppliers.IDs.map((id) => allSuppliers.byID[id])
        .filter((supplier) => SupplierCatalogueUtils.supplierHasPlant(supplierCatalogue, supplier.ID, plantCode))
        .sort(lexographicSupplierSort)
        .map(getFilterOption),
    ];
  });

  getVarietyFilter = memoizeOne((filter, plantCode, supplierCatalogue, userPlantVarieties) => {
    if (filter.type === FilterTypes.ALL) {
      return () => true;
    }

    if (filter.type === FilterTypes.USER) {
      return (varietyOption) => UserPlantVarietySetUtils.hasVariety(userPlantVarieties, plantCode, varietyOption.value);
    }

    return (varietyOption) => SupplierCatalogueUtils.hasCatalogueVariety(supplierCatalogue, filter.value, plantCode, varietyOption.value);
  });

  getVarietyName() {
    if (this.state.variety.value === DEFAULT_VARIETY) {
      return (
        <>
          - <span className='default-variety'>(Default Variety)</span>
        </>
      );
    }

    return this.state.variety.label;
  }

  getDisplayedSpacing() {
    if (this.state.editingVariety.customSpacings) {
      return this.state.editingVariety.spacing;
    }

    return this.props.plant.spacing;
  }

  getInRowSpacing() {
    if (this.state.editingVariety.customSpacings) {
      return this.state.editingVariety.inRowSpacing;
    }

    return this.props.plant.inRowSpacing;
  }

  getRowSpacing() {
    if (this.state.editingVariety.customSpacings) {
      return this.state.editingVariety.rowSpacing;
    }

    return this.props.plant.rowSpacing;
  }

  getDisplayedSquareFootPlantCount() {
    if (this.state.editingVariety.customSpacings) {
      return this.state.editingVariety.squareFootPlantCount;
    }

    return this.props.plant.squareFootPlantCount;
  }

  getDisplayedPlantingCalendar() {
    if (this.state.editingVariety.customCalendar) {
      return this.state.editingVariety.plantingCalendar;
    }

    return this.defaultPlantingCalendar;
  }

  setEditingVarietyProperty(property, value) {
    this.setState((state) => {
      return {
        editingVariety: { ...state.editingVariety, [property]: value },
      };
    });
  }

  currentVarietyCanReset() {
    if (this.state.editing) {
      return false;
    }

    // A variety can be reset if it has a current user plant variety which isn't empty
    if (!UserPlantVarietySetUtils.hasVariety(this.state.userPlantVarieties, this.props.plant.code, this.state.variety.value)) {
      return false;
    }

    return !UserPlantVarietyUtils.isDisabled(this.state.editingVariety);
  }

  currentVarietyCanDelete() {
    if (this.state.editing) {
      return false;
    }

    // A variety can be deleted if it is a custom variety and it is not present in the catalogue
    if (!UserPlantVarietySetUtils.hasVariety(this.state.userPlantVarieties, this.props.plant.code, this.state.variety.value)) {
      return false;
    }

    return !SupplierCatalogueUtils.hasInAnyCatalogue(this.props.supplierCatalogue, this.props.plant.code, this.state.variety.value);
  }

  currentVarietyCanImport() {
    if (this.state.editing) {
      return false;
    }

    return true;
  }

  createOption(varietyName, plantCode, supplierCatalogue, userPlantVarieties) {
    const hasSupplier = SupplierCatalogueUtils.hasInAnyCatalogue(supplierCatalogue, plantCode, varietyName);
    let hasCustom = UserPlantVarietySetUtils.hasVariety(userPlantVarieties, plantCode, varietyName);
    let userPlantVariety = null;

    if (hasCustom) {
      userPlantVariety = UserPlantVarietySetUtils.getByPlantCodeAndName(userPlantVarieties, plantCode, varietyName);
    }

    if (hasSupplier && hasCustom) {
      // If there is a supplier for the variety and the custom entry is empty then we won't show it as having
      // custom spacings/planting times in the list
      if (UserPlantVarietyUtils.isDisabled(userPlantVariety)) {
        hasCustom = false;
        console.debug('Custom variety', varietyName, 'is empty, not adding');
      }
    }

    return {
      label: varietyName,
      value: varietyName,
      hasCustom,
      hasSupplier,
      userPlantVariety,
    };
  }

  /**
   * Updates the current 'variety', 'editingVariety' and 'userPlantVarieties' properties in state with the provided UserPlantVariety
   *
   * Other state properties passed to 'otherState' will also be updated at the same time
   *
   * @param {UserPlantVariety} userPlantVariety
   * @param {object} [otherState={}]
   * @memberof CustomiseVarietiesModal
   */
  updateVariety(userPlantVariety, otherState = {}) {
    this.setState((state) => {
      if (UserPlantVarietySetUtils.hasVariety(state.userPlantVarieties, userPlantVariety.plantCode, userPlantVariety.name)) {
        const updatedUserPlantVarieties = UserPlantVarietySetUtils.updateVariety(state.userPlantVarieties, userPlantVariety);

        return {
          ...otherState,
          userPlantVarieties: updatedUserPlantVarieties,
          variety: this.createOption(userPlantVariety.name, userPlantVariety.plantCode, this.props.supplierCatalogue, updatedUserPlantVarieties),
          editingVariety: this.getUserVariety(userPlantVariety.name, updatedUserPlantVarieties),
        };
      }

      const updatedUserPlantVarieties = UserPlantVarietySetUtils.addVariety(state.userPlantVarieties, userPlantVariety);

      return {
        ...otherState,
        userPlantVarieties: updatedUserPlantVarieties,
        variety: this.createOption(userPlantVariety.name, userPlantVariety.plantCode, this.props.supplierCatalogue, updatedUserPlantVarieties),
        editingVariety: this.getUserVariety(userPlantVariety.name, updatedUserPlantVarieties),
      };
    });
  }

  renderSpacingError() {
    return <span className='form-row-item invalid-text-inline'>{UserPlantVarietyUtils.getSpacingErrorText(this.props.distanceUnits.type)}</span>;
  }

  renderSFGCountError() {
    return <span className='form-row-item invalid-text-inline'>must be between 1 and 99</span>;
  }

  renderSuppliers() {
    const supplierCatalogues = SupplierCatalogueUtils.getAllCatalogues(this.props.supplierCatalogue, this.props.plant.code, this.state.variety.value);
    let content = null;

    if (this.state.variety.value === DEFAULT_VARIETY) {
      content = 'Select a variety to see suppliers';
    } else if (supplierCatalogues.length === 0) {
      content = 'This variety is not available from any known suppliers';
    } else {
      content = supplierCatalogues.map((v) => this.renderCatalogue(v));
    }

    return (
      <FormField label='Suppliers' fakeLabel desktopLayout={{ labelSize: 160 }}>
        <span className='suppliers-list'>{content}</span>
      </FormField>
    );
  }

  renderCatalogue(catalogueVariety, i) {
    return (
      <Fragment key={catalogueVariety.supplierID}>
        {i > 0 && ', '}
        <span className='supplier-name'>{SuppliersUtils.getSupplier(this.context.suppliers, catalogueVariety.supplierID).name}</span>
      </Fragment>
    );
  }

  renderConfirmCloseModal() {
    if (!this.state.confirmCloseModalOpen) {
      return null;
    }

    return <CustomiseVarietiesConfirmCloseModal onConfirm={this.onConfirmClose} onCancel={this.onConfirmCancel} />;
  }

  renderAddVarietyModal() {
    if (!this.state.addVarietyOpen) {
      return null;
    }

    return (
      <AddVarietyModal
        plant={this.props.plant}
        closeModal={this.onCloseAddVarietyModal}
        userPlantVarieties={this.state.userPlantVarieties}
        supplierCatalogue={this.props.supplierCatalogue}
        onDone={this.onAddVarietyDone}
      />
    );
  }

  renderHelpModal() {
    if (!this.state.helpModalOpen) {
      return null;
    }

    return <CustomiseVarietiesHelpModal closeModal={this.onCloseHelpModal} />;
  }

  renderImportModal() {
    if (!this.state.importVarietyOpen) {
      return null;
    }

    return (
      <ImportVarietySettingsModal
        plant={this.props.plant}
        plants={this.props.plants}
        userPlantVarieties={this.state.userPlantVarieties}
        closeModal={this.onImportVarietyCancel}
        distanceUnits={this.props.distanceUnits}
        currentVarietyName={this.state.variety.value}
        onImport={this.onImportVariety}
      />
    );
  }

  renderResetModal() {
    if (!this.state.resetVarietyOpen) {
      return null;
    }

    return <ResetVarietyModal onConfirm={this.onResetVariety} onCancel={this.onResetVarietyCancel} varietyName={this.state.variety.label} />;
  }

  renderDeleteModal() {
    if (!this.state.deleteModalOpen) {
      return null;
    }

    return <DeleteVarietyModal onConfirm={this.onDeleteVariety} onCancel={this.onDeleteVarietyCancel} varietyName={this.state.variety.label} />;
  }

  renderSquareFoot() {
    if (!this.props.plant.canBeSquareFootPlant) {
      return null;
    }

    return (
      <FormField
        label='Square Foot Plant Count'
        htmlFor='sfg-plant-count'
        invalid={!this.renderData.validSquareFootCount}
        errorText={this.renderData.validSquareFootCount ? null : this.renderSFGCountError()}
        layoutPreset={SPACING_FIELD_PRESET}
        desktopLayout={{ inputSize: 64 }}
        mobileLayout={{ inputSize: 64 }}
      >
        <InputContainer>
          <input
            disabled={!this.state.editing || !this.state.editingVariety.customSpacings}
            // eslint-disable-next-line max-len
            className={`${this.renderData.validSquareFootCount ? '' : 'invalid'} ${!this.state.editing || !this.state.editingVariety.customSpacings ? 'inline' : ''}`}
            type='number'
            max={SQUARE_FOOT_PLANT_MAX}
            min={SQUARE_FOOT_PLANT_MIN}
            value={this.renderData.squareFootPlantCount}
            onChange={this.onSquareFootCountChange}
            onFocus={handleFocus}
            id='sfg-plant-count'
          />
        </InputContainer>
      </FormField>
    );
  }

  renderEditingButtons() {
    if (this.state.editing) {
      return (
        <div className='customise-editing-buttons'>
          <button type='button' disabled={!this.renderData.allValid} onClick={this.onEditingDone}>
            <i className='icon-ok' /> Done
          </button>
          <button type='button' aria-label='Cancel' onClick={this.onEditingCancel}>
            <i className='icon-cancel' />
          </button>
        </div>
      );
    }

    return null;
  }

  renderSpacingHelpModal() {
    return this.state.showSpacingHelpModal ? <PlantSpacingHelpModal closeModal={() => this.setState({ showSpacingHelpModal: false })} /> : null;
  }

  render() {
    const varietyFilter = this.getVarietyFilter(this.state.filter, this.props.plant.code, this.props.supplierCatalogue, this.state.userPlantVarieties);
    const varieties = this.getPlantVarietyOptions(this.props.plant.code, this.props.supplierCatalogue, this.state.userPlantVarieties, varietyFilter);
    const sourceFilter = this.getFilterOptions(this.props.plant.code, this.props.supplierCatalogue, this.context.suppliers, this.state.userPlantVarieties);

    const validSpacing = UserPlantVarietyUtils.validSpacing(this.state.editingVariety) || !this.state.editingVariety.customSpacings;
    const validInRowSpacing = UserPlantVarietyUtils.validInRowSpacing(this.state.editingVariety) || !this.state.editingVariety.customSpacings;
    const validRowSpacing = UserPlantVarietyUtils.validRowSpacing(this.state.editingVariety) || !this.state.editingVariety.customSpacings;
    const validSquareFootCount =
      UserPlantVarietyUtils.validSquareFootPlantingCount(this.state.editingVariety) ||
      !this.props.plant.canBeSquareFootPlant ||
      !this.state.editingVariety.customSpacings;

    this.renderData = {
      spacing: this.getDisplayedSpacing(),
      inRowSpacing: this.getInRowSpacing(),
      rowSpacing: this.getRowSpacing(),
      squareFootPlantCount: this.getDisplayedSquareFootPlantCount(),
      plantingCalendar: this.getDisplayedPlantingCalendar(),
      validSpacing,
      validInRowSpacing,
      validRowSpacing,
      validSquareFootCount,
      allValid: validSpacing && validInRowSpacing && validRowSpacing && validSquareFootCount,
    };

    return (
      <>
        {this.renderConfirmCloseModal()}
        {this.renderAddVarietyModal()}
        {this.renderHelpModal()}
        {this.renderImportModal()}
        {this.renderResetModal()}
        {this.renderDeleteModal()}
        <Modal closeRequest={this.onClose} className='customise-varieties-modal'>
          <ModalContent>
            <ModalCloseButton onClick={this.onClose} />
            <ModalHelpButton onClick={this.onOpenHelpModal} />
            <ModalHeader>
              <ModalHeaderIcon>
                <PlannerIcon code={`${this.props.plant.code}.svg`} />
              </ModalHeaderIcon>
              <ModalHeaderContent>
                <ModalHeaderTitle>Customize {this.props.plant.name} Varieties</ModalHeaderTitle>
              </ModalHeaderContent>
            </ModalHeader>
            <ModalPaneContainer>
              <ModalPane>
                <ModalPaneSection>
                  <ModalPaneSectionContent>
                    <FormSection padding={0} margin={0}>
                      <FormField
                        label='Show Varieties From'
                        htmlFor='show-varieties-from-dropdown'
                        disabled={this.state.editing}
                        desktopLayout={{ labelSize: 160, inputSize: 220 }}
                      >
                        <InputContainer size='full'>
                          <ReactSelect
                            isDisabled={this.state.editing}
                            styles={SELECT_STYLES}
                            options={sourceFilter}
                            value={this.state.filter}
                            onChange={this.onFilterChange}
                            menuPortalTarget={document.body}
                            isOptionDisabled={(option) => option.disabled}
                            isSearchable={false}
                            inputId='show-varieties-from-dropdown'
                          />
                        </InputContainer>
                      </FormField>
                      <FormField
                        label='Variety'
                        htmlFor='varieties-dropdown'
                        disabled={this.state.editing}
                        desktopLayout={{ labelSize: 160, inputSize: 'full' }}
                      >
                        <FormLayout desktopLayout={{ yAlign: 'stretch', size: 'full' }} mobileLayout={{ layout: 'row', size: 'full', yAlign: 'stretch' }}>
                          <InputContainer desktopSize={220} mobileSize='full'>
                            <ReactSelect
                              isDisabled={this.state.editing}
                              styles={SELECT_STYLES}
                              options={varieties}
                              value={this.state.variety}
                              onChange={this.onVarietyChange}
                              menuPortalTarget={document.body}
                              formatOptionLabel={FormatVarietyOptionLabel}
                              isSearchable={varieties.length > 10}
                              inputId='varieties-dropdown'
                            />
                          </InputContainer>
                          <button type='button' disabled={this.state.editing} className='button form-button add-new-button' onClick={this.onOpenAddVariety}>
                            Add New
                          </button>
                        </FormLayout>
                      </FormField>
                      {this.renderSuppliers()}
                    </FormSection>
                  </ModalPaneSectionContent>
                </ModalPaneSection>
              </ModalPane>
              <ModalPane emphasised>
                <ModalPaneHeader centered>
                  <span className='title-variety-name'>{this.getVarietyName()}</span>
                </ModalPaneHeader>
                <ModalPaneContent>
                  <ModalPaneSection>
                    <ModalPaneSectionContent>
                      <CustomFormRow>
                        <div className='customize-variety-action-buttons'>
                          <button type='button' className='button form-button' disabled={this.state.editing} onClick={this.onEditingStart}>
                            <i className='icon-edit' /> Edit
                          </button>
                          <button type='button' className='button form-button' disabled={!this.currentVarietyCanImport()} onClick={this.onImportVarietyOpen}>
                            <i className='icon-docs' /> Copy From
                          </button>
                          <button type='button' className='button form-button' disabled={!this.currentVarietyCanReset()} onClick={this.onResetVarietyOpen}>
                            <i className='icon-cw' /> Reset
                          </button>
                          <button type='button' className='button form-button' disabled={!this.currentVarietyCanDelete()} onClick={this.onDeleteVarietyOpen}>
                            <i className='icon-trash' /> Delete
                          </button>
                        </div>
                      </CustomFormRow>
                    </ModalPaneSectionContent>
                  </ModalPaneSection>
                  <ModalPaneSectionGroup className={`customise-editing-group ${this.state.editing ? 'editing' : ''}`}>
                    {this.renderEditingButtons()}
                    <ModalPaneSection>
                      {this.renderSpacingHelpModal()}
                      <ModalPaneSectionHeader>
                        Spacing <HelpButton onClick={() => this.setState({ showSpacingHelpModal: true })} />
                      </ModalPaneSectionHeader>
                      <ModalPaneSectionContent>
                        <FormSection padding={0}>
                          <FormField label='Use Custom Spacings' htmlFor='use-custop-spacings' layoutPreset={FORM_FIELD_PRESETS.CheckboxLong}>
                            <input
                              type='checkbox'
                              disabled={!this.state.editing}
                              checked={this.state.editingVariety.customSpacings}
                              onChange={this.onUseCustomSpacingsChange}
                              id='use-custop-spacings'
                            />
                          </FormField>
                          <FormField
                            label='Between plants'
                            htmlFor='spacing-between-plants'
                            invalid={!this.renderData.validSpacing}
                            errorText={this.renderData.validSpacing ? null : this.renderSpacingError()}
                            layoutPreset={SPACING_FIELD_PRESET}
                          >
                            <LengthInput
                              inline={!this.state.editing || !this.state.editingVariety.customSpacings}
                              disabled={!this.state.editing || !this.state.editingVariety.customSpacings}
                              value={this.renderData.spacing}
                              onChange={this.onSpacingChange}
                              distanceUnits={this.props.distanceUnits}
                              showMinor
                              valid={this.renderData.validSpacing}
                              id='spacing-between-plants'
                            />
                          </FormField>
                          <FormField
                            label='Between plants in rows'
                            htmlFor='spacing-between-plants-in-row'
                            invalid={!this.renderData.validInRowSpacing}
                            errorText={this.renderData.validInRowSpacing ? null : this.renderSpacingError()}
                            layoutPreset={SPACING_FIELD_PRESET}
                          >
                            <LengthInput
                              inline={!this.state.editing || !this.state.editingVariety.customSpacings}
                              disabled={!this.state.editing || !this.state.editingVariety.customSpacings}
                              value={this.renderData.inRowSpacing}
                              onChange={this.onInRowSpacingChange}
                              distanceUnits={this.props.distanceUnits}
                              showMinor
                              valid={this.renderData.validInRowSpacing}
                              id='spacing-between-plants-in-row'
                            />
                          </FormField>
                          <FormField
                            label='Between rows'
                            htmlFor='spacing-between-rows'
                            invalid={!this.renderData.validRowSpacing}
                            errorText={this.renderData.validRowSpacing ? null : this.renderSpacingError()}
                            layoutPreset={SPACING_FIELD_PRESET}
                          >
                            <LengthInput
                              inline={!this.state.editing || !this.state.editingVariety.customSpacings}
                              disabled={!this.state.editing || !this.state.editingVariety.customSpacings}
                              value={this.renderData.rowSpacing}
                              onChange={this.onRowSpacingChange}
                              distanceUnits={this.props.distanceUnits}
                              showMinor
                              valid={this.renderData.validRowSpacing}
                              id='spacing-between-rows'
                            />
                          </FormField>
                          {this.renderSquareFoot()}
                        </FormSection>
                      </ModalPaneSectionContent>
                    </ModalPaneSection>
                    <ModalPaneSection>
                      <ModalPaneSectionHeader>Sow, Plant and Harvest Times</ModalPaneSectionHeader>
                      <ModalPaneSectionContent>
                        <FormField label='Use Custom Times' htmlFor='use-custom-times' layoutPreset={FORM_FIELD_PRESETS.CheckboxLong}>
                          <input
                            type='checkbox'
                            disabled={!this.state.editing}
                            checked={this.state.editingVariety.customCalendar}
                            onChange={this.onCustomPlantingCalendarChange}
                            id='use-custom-times'
                          />
                        </FormField>
                        <CustomFormRow className='scroll-x'>
                          <PlantingCalendarInput
                            northernHemisphere={UserUtils.isNorthernHemisphere(this.props.user)}
                            disabled={!this.state.editing || !this.state.editingVariety.customCalendar}
                            plantingCalendarNumbers={this.renderData.plantingCalendar}
                            onChange={this.onCalendarChange}
                          />
                        </CustomFormRow>
                      </ModalPaneSectionContent>
                    </ModalPaneSection>
                  </ModalPaneSectionGroup>
                </ModalPaneContent>
              </ModalPane>
            </ModalPaneContainer>
            <ModalFooter>
              <ModalFooterButtons>
                <ModalFooterButtonsSection>
                  <button type='button' className='button button-secondary' onClick={this.onClose}>
                    Cancel
                  </button>
                </ModalFooterButtonsSection>
                <ModalFooterButtonsSection>
                  <LoadingButton disabled={this.state.editing} loading={this.props.savingUser} className='button button-primary' onClick={this.onSave}>
                    Save All Changes
                  </LoadingButton>
                </ModalFooterButtonsSection>
              </ModalFooterButtons>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
}

CustomiseVarietiesModal.contextType = ResourceContext;

CustomiseVarietiesModal.propTypes = {
  defaultVariety: PropTypes.string,
  plants: PropTypes.instanceOf(Collection).isRequired,
  plant: PropTypes.instanceOf(Plant).isRequired,
  closeModal: PropTypes.func.isRequired,
  supplierCatalogue: PropTypes.object.isRequired,
  distanceUnits: DistanceUnitsShape,
  user: UserShape.isRequired,
  savingUser: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  userRegionalPlantingCalendar: PropTypes.any,
};

CustomiseVarietiesModal.defaultProps = {
  defaultVariety: DEFAULT_VARIETY,
  distanceUnits: metricDistanceUnits,
  savingUser: false,
  userRegionalPlantingCalendar: null,
};

export default CustomiseVarietiesModal;
