import React from 'react';
import { ShoppingListDisplay } from '@gi/shopping-list';

import PlannerAppContainer from '../planner-app-container';
import PlannerAppScrollPane from '../planner-app-scroll-pane';

const MobileShoppingList = (): JSX.Element => {
  return (
    <PlannerAppContainer>
      <PlannerAppScrollPane>
        <ShoppingListDisplay />
      </PlannerAppScrollPane>
    </PlannerAppContainer>
  );
};

export default MobileShoppingList;
