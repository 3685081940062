import React, { useContext, useMemo } from 'react';
import { TooltipKey } from '@gi/tooltip';

import { PlannerCommandContext } from '../../../planner-command-provider';
import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';
import ControlButtonGroup from '../common/control-button-group';
import MenuButton from '../common/menu-button';
import MenuIcon from '../common/menu-icon';

const LayoutControls = (): JSX.Element => {
  const { lockNodes, unlockNodes, sendToBack, bringToFront } = useContext(PlannerCommandContext);

  // Show lock icon by default. Only show unlock if unlock is available and lock isn't.
  const showLock = useMemo(() => {
    return lockNodes !== null || unlockNodes === null;
  }, [lockNodes, unlockNodes]);

  return (
    <ControlGroup>
      <ControlRow>
        {/* Lock/Unlock button */}
        <ControlButtonGroup>
          <MenuButton
            id='lock'
            disabled={!lockNodes && !unlockNodes}
            onClick={() => {
              if (lockNodes) {
                lockNodes();
              } else if (unlockNodes) {
                unlockNodes();
              }
            }}
            tooltipKey={showLock ? TooltipKey.LockItems : TooltipKey.UnlockItems}
          >
            <MenuIcon icon={showLock ? 'icon-lock' : 'icon-lock-open'} />
          </MenuButton>
        </ControlButtonGroup>
        {/* Layer Order buttons */}
        <ControlButtonGroup>
          <MenuButton
            id='send-to-back'
            disabled={!sendToBack}
            onClick={() => {
              if (sendToBack) sendToBack();
            }}
            tooltipKey={TooltipKey.SendToBack}
          >
            <MenuIcon icon='icon-order-back' />
          </MenuButton>
          <MenuButton
            id='bring-to-front'
            disabled={!bringToFront}
            onClick={() => {
              if (bringToFront) bringToFront();
            }}
            tooltipKey={TooltipKey.BringToFront}
          >
            <MenuIcon icon='icon-order-front' />
          </MenuButton>
        </ControlButtonGroup>
      </ControlRow>
    </ControlGroup>
  );
};

export default LayoutControls;
