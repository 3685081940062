import React from 'react';

const PartListTableHeaders = (): JSX.Element => {
  return (
    <thead>
      <tr>
        <th aria-label='Icon' />
        <th className='part-name-cell left-aligned-cell'>Part</th>
        <th className='left-aligned-cell'>Quantity</th>
      </tr>
    </thead>
  );
};

export default PartListTableHeaders;
