import { GardenObjectType } from '@gi/constants';
import { Geometry } from '@gi/math';
import { SpriteSourceType } from '@gi/sprite-source';

import { GardenObject } from './garden-object';
import { GardenObjectBlock } from './garden-object-block';
import { GardenObjectSegment } from './garden-object-segment';
import { GardenObjectPreset } from './garden-object-preset';

function getDisplayImageName(gardenObject: GardenObject): string {
  if (
    (gardenObject.shape.type === GardenObjectType.BLOCK && gardenObject.shape.sprite.type === SpriteSourceType.NINE_SLICE) ||
    gardenObject.shape.type === GardenObjectType.PATH
  ) {
    return `${gardenObject.code.toUpperCase()}_I`;
  }

  return gardenObject.code.toUpperCase();
}

function calculateLength(gardenObject: GardenObject, start: Vector2, mid: Vector2 | null, end: Vector2): number {
  if (gardenObject.shape.type !== GardenObjectType.PATH) {
    return 0;
  }

  return Geometry.bezierSegmentsLength(start, mid, end, gardenObject.shape.sprite.width, 10);
}

function calculateArea(gardenObject: GardenObject, start: Vector2, end: Vector2): number {
  const width = Math.abs(end.x - start.x);
  const height = Math.abs(end.y - start.y);
  return width * height;
}

type ShapeTypes = {
  [GardenObjectType.BLOCK]: GardenObjectBlock;
  [GardenObjectType.PATH]: GardenObjectSegment;
};

/**
 * Asserts that the given shape matches the expected type.
 *
 * Has to be exported as a standalone function, because TS asserts are weird.
 * @param shape The shape of the garden object
 * @param type The type to assert it as
 */
export function assertShapeType<T extends GardenObjectType>(shape: GardenObjectBlock | GardenObjectSegment, type: T): asserts shape is ShapeTypes[T] {
  if (shape.type !== type) {
    throw new Error(`Unexpected shape type (expected ${type}, given ${shape.type})`);
  }
}

interface GardenObjectPresetMatch {
  preset: GardenObjectPreset;
  transposed: boolean;
}

function getClosestPreset(width: number, height: number, presets: GardenObjectPreset[], maxAxisDifference: number = Infinity): GardenObjectPresetMatch | null {
  let smallestDifference: number = Infinity;
  let closestOption: GardenObjectPresetMatch | null = null;

  const checkDimensions = (preset: GardenObjectPreset, transpose: boolean = false) => {
    const presetWidth = transpose ? preset.height : preset.width;
    const presetHeight = transpose ? preset.width : preset.height;

    const xDiff = Math.abs(width - presetWidth);
    const yDiff = Math.abs(height - presetHeight);

    if (xDiff < maxAxisDifference && yDiff < maxAxisDifference) {
      const diff = xDiff ** 2 + yDiff ** 2;

      if (diff < smallestDifference) {
        smallestDifference = diff;
        closestOption = { preset, transposed: transpose };
      }
    }
  };

  presets.forEach((preset) => {
    checkDimensions(preset);
    if (preset.transposable) {
      checkDimensions(preset, true);
    }
  });

  return closestOption;
}

/**
 * Checks if the given garden object can be shown to the given client ID.
 * @param gardenObject The garden object to check
 * @param clientId The ID of the current client
 * @returns True if the object should not be shown
 */
function isRestrictedFromClient(gardenObject: GardenObject, clientId: number): boolean {
  if (gardenObject.clientRestrictions === undefined) {
    return false;
  }
  if (gardenObject.clientRestrictions.asDenyList) {
    return gardenObject.clientRestrictions.clientIds.includes(clientId);
  }
  return !gardenObject.clientRestrictions.clientIds.includes(clientId);
}

const GardenObjectUtils = {
  getDisplayImageName,
  calculateLength,
  calculateArea,
  getClosestPreset,
  isRestrictedFromClient,
};

export default GardenObjectUtils;
