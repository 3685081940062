export enum LogActionTypes {
  Log = 'log/LOG',
}

export type LogMessageAction = {
  type: LogActionTypes.Log;
  payload: string;
};

export function createLogMessage(message: string): LogMessageAction {
  return {
    type: LogActionTypes.Log,
    payload: message,
  };
}
