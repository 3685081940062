import React, { ReactNode } from 'react';

import Modal, {
  ModalContent,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalHeader,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalPane,
  ModalPaneContainer,
  ModalType,
} from '@gi/modal';
import LoadingButton from '@gi/loading-button';

import UnsavedPlansWarning from './unsaved-plans-warning';

const ModalButtonMapping = {
  [ModalType.Default]: 'button-primary',
  [ModalType.Warning]: 'button-warning',
  [ModalType.Error]: 'button-error',
};

interface iProps {
  type?: ModalType;
  title: string;
  children: ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  loading?: boolean;
}
/**
 * A confirmation modal that also displays a list of the user's plans with unsaved changes.
 * Could do with a better name.
 */
const ConfirmModalWithUnsavedPlans = ({
  type = ModalType.Default,
  title,
  children,
  onCancel,
  onConfirm,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  loading,
}: iProps): JSX.Element => {
  return (
    <Modal type={type} closeRequest={onCancel}>
      <ModalContent>
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>{title}</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            {children}
            <UnsavedPlansWarning />
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={onCancel} disabled={loading}>
                {cancelButtonText}
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <LoadingButton className={`button ${ModalButtonMapping[type]}`} disabled={loading} loading={loading} onClick={onConfirm}>
                {confirmButtonText}
              </LoadingButton>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModalWithUnsavedPlans;
