import React, { useContext } from 'react';
import ReactSelect from 'react-select';

import { Anchor, Drawer, DrawerContent, DrawerHeader } from '@gi/mobile-components';
import FormField, { FormFieldOptionsPreset, FormSection, InputContainer } from '@gi/form-responsive';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';
import { PickerButton } from '@gi/texture-picker';
import { presetPalettes, Swatch } from '@gi/palette';
import { DrawingToolsContext, TextIcon } from '@gi/drawing-tools';

import SelectableButton from '../selectable-button';
import SelectableButtonGroup from '../selectable-button-group';

// Styles for the small text size dropdown
const SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    minWidth: 'unset',
    width: '80px',
  }),
};

// Layout preset for all form fields so they line up
const FIELD_PRESET = (labelSize: number = 100): FormFieldOptionsPreset => ({
  desktop: {
    layout: 'row',
    labelSize,
  },
  mobile: {
    layout: 'row',
    labelSize,
  },
});

// Create font size options
const fontSizes = [10, 11, 12, 13, 14, 16, 18, 20, 24, 36];
const fontSizeOptions = fontSizes.map((i) => ({ value: i, label: `${i}` }));

interface iProps {
  open: boolean;
  onClose: () => void;
  anchor?: Anchor;
}

const TextDrawer = ({ open, onClose, anchor = 'BOTTOM' }: iProps): JSX.Element => {
  const { textFontSize, setTextFontSize, textSwatch, setTextSwatch, textSelected, onTextSelected, cancelDraw } = useContext(DrawingToolsContext);

  /**
   * Handles updating the font colour selection.
   * @param swatch The chosen colour swatch
   */
  const onTextSwatchChange = (swatch: Swatch) => {
    setTextSwatch(swatch);
    if (textSelected) {
      onTextSelected(textFontSize.value, swatch.value);
    }
  };

  /**
   * Handles updating the font size selecttion.
   * @param option The chosen text size option
   */
  const onFontSizeChange = (option: { value: number; label: string }) => {
    setTextFontSize(option);
    if (textSelected) {
      onTextSelected(option.value, textSwatch === null ? '000000' : textSwatch.value);
    }
  };

  /**
   * Handles starting/cancelling a text draw when the button is clicked.
   */
  const onToggle = () => {
    if (textSelected) {
      cancelDraw();
    } else {
      onTextSelected(textFontSize.value, textSwatch === null ? '000000' : textSwatch.value);
      onClose();
    }
  };

  return (
    <Drawer open={open} anchor={anchor} onClose={onClose} width={500}>
      <DrawerHeader as='h2' onClose={onClose}>
        Add Text
      </DrawerHeader>
      <DrawerContent padX padY className='drawing-tools-shapes-mobile'>
        <FormSection padding={0}>
          <FormField label='Font Size' htmlFor='fontSize' layoutPreset={FIELD_PRESET(100)}>
            <InputContainer>
              <ReactSelect
                styles={SELECT_STYLES}
                isSearchable={false}
                id='fontSize'
                options={fontSizeOptions}
                value={textFontSize}
                onChange={onFontSizeChange}
                menuPlacement='auto'
                menuPortalTarget={document.body}
              />
            </InputContainer>
          </FormField>
          <FormField label='Color' htmlFor='strokeColor' layoutPreset={FIELD_PRESET(100)}>
            <PickerButton palettes={presetPalettes.colorPalettes} selectedSwatch={textSwatch} onSwatchSelect={onTextSwatchChange} />
          </FormField>
          <SelectableButtonGroup>
            <SelectableButton
              aria-label='Outline Rectangle'
              active={textSelected}
              onClick={onToggle}
              src={TextIcon}
              alt='rectange'
              text='Add Text Area'
              iconScale={0.7}
            />
          </SelectableButtonGroup>
        </FormSection>
      </DrawerContent>
    </Drawer>
  );
};

export default TextDrawer;
