/* eslint-disable camelcase */
import { Matrix, Geometry } from '@gi/math';

/**
 * Maximum "valid" aspect ratio, before being considered flat. This is effectively Infinity, but accounts for floating point imprecision.
 * The reciprocal of this is used as the minimum "valid" aspect ratio.
 */
export const MAX_VALID_ASPECT_RATIO = 1e8;

type TransformedHandlePositions = {
  newAntiClockwisePosition: Vector2;
  newClockwisePosition: Vector2;
  newPosition: Vector2;
};

/**
 * Calculates the new positions of the corners of a rectangle.
 * @param activeHandlePosition The position of the handle being dragged
 * @param oppositeHandlePosition The position of the opposite corner
 * @param rotation The rotation of the rectangle
 * @param flip Set true if top-left or bottom-right
 * @returns The new positions of the corners of the rectangle
 */
export const getRectScaleTransformHandlePositions = (
  activeHandlePosition: Vector2,
  oppositeHandlePosition: Vector2,
  rotation: number,
  flip: boolean = false
): TransformedHandlePositions => {
  const centerPosition = Geometry.midpoint(activeHandlePosition, oppositeHandlePosition);

  const activeHandle_M = Matrix.createPositionMatrix(activeHandlePosition.x, activeHandlePosition.y);
  const oppositeHandle_M = Matrix.createPositionMatrix(oppositeHandlePosition.x, oppositeHandlePosition.y);
  const center_M = Matrix.createTranslationMatrix(centerPosition.x, centerPosition.y);
  const center_Mi = Matrix.createTranslationMatrix(-centerPosition.x, -centerPosition.y);
  const rotation_M = Matrix.createRotationMatrix(rotation);
  const rotation_Mi = Matrix.createRotationMatrix(-rotation);

  // Move active translation matrix to be relative to the origin, by translating back to the center (CMi) and doing the reverse rotation
  const activeHandleRelativeToOrigin_M = Matrix.multiplyAll([center_Mi, rotation_Mi, activeHandle_M]);
  // Move opposite translation matrix to be relative to the origin, as above
  const oppositeHandleRelativeToOrigin_M = Matrix.multiplyAll([center_Mi, rotation_Mi, oppositeHandle_M]);

  const next_M = Matrix.multiply(
    Matrix.multiply(rotation_M, center_M),
    Matrix.createPositionMatrix(oppositeHandleRelativeToOrigin_M[0][0], activeHandleRelativeToOrigin_M[1][0])
  );
  const previous_M = Matrix.multiply(
    Matrix.multiply(rotation_M, center_M),
    Matrix.createPositionMatrix(activeHandleRelativeToOrigin_M[0][0], oppositeHandleRelativeToOrigin_M[1][0])
  );

  if (flip) {
    return {
      newAntiClockwisePosition: { x: previous_M[0][0], y: previous_M[1][0] },
      newClockwisePosition: { x: next_M[0][0], y: next_M[1][0] },
      newPosition: activeHandlePosition,
    };
  }

  return {
    newAntiClockwisePosition: { x: next_M[0][0], y: next_M[1][0] },
    newClockwisePosition: { x: previous_M[0][0], y: previous_M[1][0] },
    newPosition: activeHandlePosition,
  };
};

/**
 * Calculates the new positions of the corners of a rectangle, maintaining its aspect ratio.
 * @param activeHandlePosition The position of the handle being dragged
 * @param previousActiveHandlePosition The previous position of the handle being dragged
 * @param oppositeHandlePosition The position of the opposite corner
 * @param rotation The rotation of the rectangle
 * @param flip Set true if top-left or bottom-right
 * @returns The new positions of the corners of the rectangle.
 */
export const getRectScaleTransformHandlePositionsKeepAspect = (
  activeHandlePosition: Vector2,
  previousActiveHandlePosition: Vector2,
  oppositeHandlePosition: Vector2,
  rotation: number,
  flip: boolean = false
): TransformedHandlePositions => {
  // First, convert our activeHandle positions to be axis-aligned and relative to the opposite handle
  const inverseOrigin_M = Matrix.createTranslationMatrix(-oppositeHandlePosition.x, -oppositeHandlePosition.y);
  const inverseRotation_M = Matrix.createRotationMatrix(-rotation);

  const activeHandlePos_M = Matrix.createPositionMatrix(activeHandlePosition.x, activeHandlePosition.y);
  const previousActiveHandlePos_M = Matrix.createPositionMatrix(previousActiveHandlePosition.x, previousActiveHandlePosition.y);

  const relativeActiveHandlePos_M = Matrix.multiplyAll([inverseRotation_M, inverseOrigin_M, activeHandlePos_M]);
  const relativePreviousActiveHandlePos_M = Matrix.multiplyAll([inverseRotation_M, inverseOrigin_M, previousActiveHandlePos_M]);

  const relativeActiveHandlePos = {
    x: relativeActiveHandlePos_M[0][0],
    y: relativeActiveHandlePos_M[1][0],
  };
  const relativePreviousActiveHandlePos = {
    x: relativePreviousActiveHandlePos_M[0][0],
    y: relativePreviousActiveHandlePos_M[1][0],
  };

  // Calculate the aspect ratio of the shape and the drag
  const previousAspectRatio = Math.abs(relativePreviousActiveHandlePos.x / relativePreviousActiveHandlePos.y);
  const newAspectRatio = Math.abs(relativeActiveHandlePos.x / relativeActiveHandlePos.y);

  // Calculate the new position of the active handle.
  let scale_M: number[][];
  if (previousAspectRatio > MAX_VALID_ASPECT_RATIO) {
    // Item has no height
    const scale = relativeActiveHandlePos.x / relativePreviousActiveHandlePos.x;
    scale_M = Matrix.createScalingMatrix(scale, 1);
  } else if (previousAspectRatio < 1 / MAX_VALID_ASPECT_RATIO) {
    // Item has no width
    const scale = relativeActiveHandlePos.y / relativePreviousActiveHandlePos.y;
    scale_M = Matrix.createScalingMatrix(1, scale);
  } else {
    const scale =
      newAspectRatio > previousAspectRatio
        ? relativeActiveHandlePos.x / relativePreviousActiveHandlePos.x
        : relativeActiveHandlePos.y / relativePreviousActiveHandlePos.y;
    scale_M = Matrix.createScalingMatrix(scale, scale);
  }

  const position_M = Matrix.createPositionMatrix(relativePreviousActiveHandlePos.x, relativePreviousActiveHandlePos.y);
  const relativeNewActiveHandlePos_M = Matrix.multiply(scale_M, position_M);

  const relativeNewActiveHandlePos = {
    x: relativeNewActiveHandlePos_M[0][0],
    y: relativeNewActiveHandlePos_M[1][0],
  };

  // Pre-calculate matrix to convert relative coords to screen-space
  const origin_M = Matrix.createTranslationMatrix(oppositeHandlePosition.x, oppositeHandlePosition.y);
  const rotation_M = Matrix.createRotationMatrix(rotation);
  const toWorld_M = Matrix.multiply(origin_M, rotation_M);

  // Calculate the positions of the active handle and the adjacent handles.
  const active_M = Matrix.multiply(toWorld_M, Matrix.createPositionMatrix(relativeNewActiveHandlePos.x, relativeNewActiveHandlePos.y));
  const previous_M = Matrix.multiply(toWorld_M, Matrix.createPositionMatrix(relativeNewActiveHandlePos.x, 0));
  const next_M = Matrix.multiply(toWorld_M, Matrix.createPositionMatrix(0, relativeNewActiveHandlePos.y));

  if (flip) {
    return {
      newPosition: { x: active_M[0][0], y: active_M[1][0] },
      newAntiClockwisePosition: { x: previous_M[0][0], y: previous_M[1][0] },
      newClockwisePosition: { x: next_M[0][0], y: next_M[1][0] },
    };
  }

  return {
    newPosition: { x: active_M[0][0], y: active_M[1][0] },
    newAntiClockwisePosition: { x: next_M[0][0], y: next_M[1][0] },
    newClockwisePosition: { x: previous_M[0][0], y: previous_M[1][0] },
  };
};
