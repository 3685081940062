import React, { useContext } from 'react';
import { updateFilters } from '@gi/filters';

import FilterRow from '../../../filters/filter-row';
import TypeFilterDropdown from './type-filter-dropdown';
import { DrawingToolsContext } from '../../../drawing-tools-context';

const TypeFilterView = (): JSX.Element => {
  const { plantFilters, setPlantFilters } = useContext(DrawingToolsContext);

  const onTypeChange = (type) => {
    setPlantFilters(updateFilters(plantFilters, { type: { type } }));
  };

  return (
    <FilterRow padHelp>
      <label className='filter-row-label filter-row-label-small'>
        <span className='filter-row-label-text'>Type</span>
        <span className='filter-row-label-content'>
          <TypeFilterDropdown type={plantFilters.filters.type.inputs.type} onTypeChange={onTypeChange} />
        </span>
      </label>
    </FilterRow>
  );
};

export default TypeFilterView;
