import React from 'react';
import { useSelector } from 'react-redux';

import { User } from '@gi/user';
import LoadingButton from '@gi/loading-button';
import { SessionSelectors } from '@gi/react-session';

import { ModalPaneSection, ModalPaneSectionContent, ModalPaneSectionHeader } from '@gi/modal';

interface iProps {
  onSharePlans: (user: User) => void;
}

const PublishPlanSharePlansWarning = ({ onSharePlans }: iProps): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);
  const savingUser = useSelector(SessionSelectors.getIsSavingUser);

  if (!user || user.settings.plansPublic) {
    // Users plans are already public
    return null;
  }

  return (
    <ModalPaneSection inset>
      <ModalPaneSectionHeader>Sharing Published Plans</ModalPaneSectionHeader>
      <ModalPaneSectionContent>
        <div className='share-plan-warning'>
          <div className='share-plan-warning-info'>
            <p>Would you like your published plans to be listed in our index of garden plans? This allows others to view your plans as examples.</p>
          </div>
          <div className='share-plan-warning-button'>
            <LoadingButton
              className='button-primary-light'
              loading={savingUser}
              onClick={() => {
                onSharePlans(user);
              }}
            >
              Yes, share my plans
            </LoadingButton>
          </div>
        </div>
      </ModalPaneSectionContent>
    </ModalPaneSection>
  );
};

export default PublishPlanSharePlansWarning;
