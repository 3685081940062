import React from 'react';
import Collection from '@gi/collection';
import Plant from '@gi/plant';
import { unique } from '@gi/utils';
import PlantCompanionsSection, { COMPANION_TYPES } from './plant-companions-section';

interface iCompanionsProps {
  plant: Plant;
  plants: Collection<Plant>;
}

const PlantCompanions = ({ plant, plants }: iCompanionsProps): JSX.Element | null => {
  const codesToUniqueSortedPlants = (codes) =>
    codes
      .filter(unique)
      .map((plantCode) => plants.get(plantCode))
      .filter((companionPlant) => companionPlant !== null)
      .sort((plantA, plantB) => plantA.name.localeCompare(plantB.name));

  const companionOfCodes: string[] = plants.itemList.filter((p) => p.companionPlantCodes.indexOf(plant.code) > -1).map((p) => p.code);
  const mutualBenefitCodes: string[] = plant.companionPlantCodes.filter((code) => companionOfCodes.includes(code));
  const companionOfPlants: Plant[] = codesToUniqueSortedPlants(companionOfCodes.filter((code) => !mutualBenefitCodes.includes(code)));
  const companionPlants: Plant[] = codesToUniqueSortedPlants(plant.companionPlantCodes.filter((code) => !mutualBenefitCodes.includes(code)));

  if (companionPlants.length === 0 && companionOfPlants.length === 0 && mutualBenefitCodes.length === 0) {
    return null;
  }

  return (
    <>
      <tr className='plan-table-row'>
        <th className='plan-table-row-title'>Companion Planting</th>
        <td />
      </tr>
      <PlantCompanionsSection plant={plant} companionPlants={codesToUniqueSortedPlants(mutualBenefitCodes)} companionType={COMPANION_TYPES.MUTUAL} />
      <PlantCompanionsSection plant={plant} companionPlants={companionPlants} companionType={COMPANION_TYPES.HELPED} />
      <PlantCompanionsSection plant={plant} companionPlants={companionOfPlants} companionType={COMPANION_TYPES.HELPS} />
    </>
  );
};

export default PlantCompanions;
