import deepmerge from 'deepmerge';

import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';

import type { RuntimeConfig, ThemeVariableName } from './types';
import { ThemeVariableNames } from './types';
import defaultRuntimeConfig from './defaults';
// import networkConfig from '../network-config';
// @ts-expect-error Environment-specific import
// eslint-disable-next-line import/no-unresolved
import getConfigPath from './config.BUILD_ENV';

/**
 * Loads the runtime config, including the client config and theme variables.
 */
export const loadRuntimeConfig = () => {
  return networkService
    .get<Partial<RuntimeConfig>>(getConfigPath(), {}, RequestAuthMode.None)
    .then((response) => {
      if (!response.success) {
        throw new Error(`Failed to load config - Response not OK (${response.status})`);
      }
      return response.body;
    })
    .then((config: Partial<RuntimeConfig>) => {
      return deepmerge(defaultRuntimeConfig, config, { arrayMerge: (a, b) => b }) as RuntimeConfig;
    })
    .catch(networkErrorReporter('GET', 'runtime config'));
};

/**
 * Parses the CSS variables from a runtime config and returns a CSS rule as a string.
 * @param variables The css variable names and values
 * @returns A CSS string
 */
export const parseThemeVariables = (variables: Record<string, string>) => {
  const rejectedVariables = new Set();
  const acceptedVariables = new Set();
  const unknownVariables = new Set();
  const cssVariables = Object.keys(variables).reduce((css, variable) => {
    // Check the variable is kebab-case.
    if (!/^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/.test(variable)) {
      rejectedVariables.add(variable);
      return css;
    }
    // Check the value doesn't contain any ; that will break the CSS.
    if (variables[variable].includes(';')) {
      rejectedVariables.add(variable);
      return css;
    }
    // Check if the variable name is allowed
    if (ThemeVariableNames.includes(variable as ThemeVariableName)) {
      acceptedVariables.add(variable);
    } else {
      unknownVariables.add(variable);
    }
    // Valid variable name, append it to the CSS.
    css += `--${variable}: ${variables[variable]};\n`;
    return css;
  }, '');
  // Check that all variables are defined, and no unknown variables were set
  let errorText: string = '';
  let noticeText: string = '';
  const undefinedVariables = ThemeVariableNames.filter((name) => !acceptedVariables.has(name));
  if (undefinedVariables.length > 0) {
    errorText += `The following CSS variables were not defined: \n${undefinedVariables.map((v) => ` - ${v}`).join('\n')}\n`;
  }
  if (rejectedVariables.size > 0) {
    errorText += `The following CSS variables were rejected/invalid: \n${Array.from(rejectedVariables)
      .map((v) => ` - ${v}`)
      .join('\n')}\n`;
  }
  if (unknownVariables.size > 0) {
    noticeText += `The following unknown CSS variables were defined (likely non-issue): \n${Array.from(unknownVariables)
      .map((v) => ` - ${v}`)
      .join('\n')}\n`;
  }
  if (errorText !== '') {
    console.error(`Errors in theme CSS:\n${errorText}`);
  }
  if (noticeText !== '') {
    console.debug(`Notices in theme CSS:\n${noticeText}`);
  }
  // Finally return the CSS rule
  return `:root {\n${cssVariables}}`;
};

export { ThemeVariableNames, ThemeVariableName, RuntimeConfig, defaultRuntimeConfig };
