import React, { HTMLAttributes, forwardRef, useContext } from 'react';

import { useMergeRefs } from '@floating-ui/react';

import { LocalContextMenuContext } from './context-menu';

import styles from './context-menu-styles.module.css';

interface iProps extends HTMLAttributes<HTMLDivElement> {
  position: Vector2;
  strategy: 'absolute' | 'fixed';
  padding?: number;
  width?: number;
  height?: number;
}

/**
 * Used as a reference to where a user right-clicked, to position a context menu
 */
const ContextMenuPointTarget = forwardRef<HTMLDivElement, iProps>(({ position, strategy: placement, padding = 0, width = 0, height = 0 }, propRef) => {
  const { getReferenceProps, refs } = useContext(LocalContextMenuContext);

  const ref = useMergeRefs([refs.setReference, propRef]);

  return (
    <div
      {...getReferenceProps({
        ref,
        className: styles.pointTarget,
        style: {
          top: position.y - padding,
          left: position.x - padding,
          position: placement,
          width: width + padding * 2,
          height: height + padding * 2,
        },
      })}
    />
  );
});
ContextMenuPointTarget.displayName = 'ContextMenuPointTarget';

export default ContextMenuPointTarget;
