import { createListenerMiddleware } from '@reduxjs/toolkit';

import { RootState } from './types';
import plannerEventEmitter from './event-emitters/planner-event-emitter';
import appEventEmitter from './event-emitters/app-event-emitter';

const middleware = createListenerMiddleware<RootState>();
plannerEventEmitter(middleware);
appEventEmitter(middleware);

export default middleware.middleware;
