import { createContext } from 'react';

import { Engine } from '@gi/core-renderer';

import { AsyncPrintOperation, CurrentPlanData, OnPDFCompleteOption, PrintSettings } from '../../types';

export type PrintPlanContextType = {
  generatePDF: {
    (engine: Engine, plan: CurrentPlanData, printSettings: PrintSettings, onComplete: OnPDFCompleteOption, fileName?: string): Promise<Uint8Array>;
    (engine: Engine, plan: CurrentPlanData, printSettings: PrintSettings, onComplete: 'NONE' | 'VIEW'): Promise<Uint8Array>;
    (engine: Engine, plan: CurrentPlanData, printSettings: PrintSettings, onComplete: 'DOWNLOAD', fileName: string): Promise<Uint8Array>;
  };
  pdf: AsyncPrintOperation;
  openPrintModal: () => void;
  closePrintModal: () => void;
  closePdfViewer: () => void;
  downloadCurrentPDF: (fileName?: string) => void;
  viewCurrentPDF: () => void;
};

const PrintPlanContext = createContext<PrintPlanContextType>({} as PrintPlanContextType);

export default PrintPlanContext;
