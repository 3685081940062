import Collection from './collection';

class CountryMappedCollection<T> {
  items: { [countryCode: string]: { [code: string]: T } };
  itemCollections: { [countryCode: string]: Collection<T> };
  codes: Set<string>;

  constructor() {
    this.items = {};
    this.itemCollections = {};
    this.codes = new Set();
  }

  add(item: T, code: string, countryCode: string) {
    if (!this.items[countryCode]) {
      this.items[countryCode] = {};
    }

    if (!this.itemCollections[countryCode]) {
      this.itemCollections[countryCode] = new Collection<T>();
    }

    this.items[countryCode][code] = item;
    this.itemCollections[countryCode].add(item, code);
    this.codes.add(code);
  }

  has(code, countryCode = 'gb') {
    if (!this.items[countryCode]) {
      return false;
    }

    if (!this.items[countryCode][code]) {
      return false;
    }

    return true;
  }

  get(code, countryCode = 'gb') {
    if (!this.has(code, countryCode)) {
      return null;
    }

    return this.items[countryCode][code];
  }

  hasCollection(countryCode) {
    if (!this.itemCollections[countryCode]) {
      return false;
    }

    return true;
  }

  getCollection(countryCode) {
    if (!this.hasCollection(countryCode)) {
      return null;
    }

    return this.itemCollections[countryCode];
  }

  get size() {
    const keys = Object.keys(this.itemCollections);
    return keys
      .map((key) => this.itemCollections[key].size)
      .reduce((prevVal, size) => {
        return prevVal + size;
      }, 0);
  }
}

export default CountryMappedCollection;
