import React from 'react';

import { DistanceUnits } from '@gi/units';

import { PlantListAreaGroup } from '../types/plant-list-types';
import PlantListCardHeader from './card/plant-list-card-header';
import PlantListCardCalendar from './card/plant-list-card-calendar';
import PlantListCard from './card/plant-list-card';
import PlantListCardSpacings from './card/plant-list-card-spacings';
import PlantListCardCount from './card/plant-list-card-count';
import PlantListCardCellContainer from './card/plant-list-card-cell-container';
import PlantListCardInGroundDates from './card/plant-list-card-in-ground-dates';
import PlantListCardLabel from './card/plant-list-card-label';

interface iProps {
  plantListRowGroup: PlantListAreaGroup;
  distanceUnits: DistanceUnits;
}

const PlantListByAreaCard = ({ plantListRowGroup, distanceUnits }: iProps): JSX.Element => {
  return (
    <PlantListCard>
      <PlantListCardHeader plant={plantListRowGroup.plant} isSFG={plantListRowGroup.planPlant.isSquareFoot} varietyName={plantListRowGroup.variety} />
      <PlantListCardCalendar plantingCalendar={plantListRowGroup.plantingCalendar} />
      <PlantListCardCellContainer>
        <PlantListCardSpacings spacings={plantListRowGroup.spacing} countsByType={plantListRowGroup.countsByType} distanceUnits={distanceUnits} />
        <PlantListCardCount count={plantListRowGroup.count} />
        <PlantListCardInGroundDates planPlant={plantListRowGroup.planPlant} />
        <PlantListCardLabel planPlant={plantListRowGroup.planPlant} />
      </PlantListCardCellContainer>
    </PlantListCard>
  );
};

export default PlantListByAreaCard;
