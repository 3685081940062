import { MathUtils } from '@gi/math';
import { PlanShape } from '@gi/plan';
import { Swatch, SwatchTypes } from '@gi/palette';

export function getSelectedSwatchFromShape(planShape: PlanShape): Swatch {
  if (planShape.fill !== null) {
    if (planShape.texture !== null) {
      // type is fill + texture
      return {
        type: SwatchTypes.IMAGE,
        value: planShape.texture,
      };
    }

    // type is fill + color
    return {
      type: SwatchTypes.COLOR,
      value: MathUtils.padHexString(planShape.fill.toString(16)),
    };
  }

  // type is stroke
  return {
    type: SwatchTypes.COLOR,
    value: MathUtils.padHexString((planShape.stroke ?? 0).toString(16)),
  };
}
