import { updateFilters } from '@gi/filters';
import React, { useContext } from 'react';
import ColumnSortButton from '../../common/column-sort-buttons/column-sort-button';
import ColumnSortIcon from '../../common/column-sort-buttons/column-sort-icon';
import { DISPLAY_TYPE } from '../../constants';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import { SORT_ORDER } from '../../types/plant-list-types';

const InGroundHeaderCell = () => {
  const { columns, areaGroupFilters, setAreaGroupFilters } = useContext(GlobalPlantListContext);

  if (!columns.inGroundDates) {
    return null;
  }

  return (
    <th className='header-cell left-align-cell'>
      In-Ground
      <div className='in-ground-dates-row'>
        <span>Start</span>
        <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Area}>
          <ColumnSortIcon
            upSortType={SORT_ORDER.InGroundStartAsc}
            downSortType={SORT_ORDER.InGroundStartDesc}
            currentSortType={areaGroupFilters.filters.sort.inputs.sortOrder}
            setSortOrder={(sortOrder: SORT_ORDER) => {
              setAreaGroupFilters(updateFilters(areaGroupFilters, { sort: { sortOrder } }));
            }}
          />
        </ColumnSortButton>
        <span>End</span>
        <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Area}>
          <ColumnSortIcon
            upSortType={SORT_ORDER.InGroundEndAsc}
            downSortType={SORT_ORDER.InGroundEndDesc}
            currentSortType={areaGroupFilters.filters.sort.inputs.sortOrder}
            setSortOrder={(sortOrder: SORT_ORDER) => {
              setAreaGroupFilters(updateFilters(areaGroupFilters, { sort: { sortOrder } }));
            }}
          />
        </ColumnSortButton>
      </div>
    </th>
  );
};

export default InGroundHeaderCell;
