import React from 'react';

import { FormSection } from '@gi/form-responsive';

import { EditShapeModalFormProps } from '../types';
import StrokePropertiesFormSection from './sections/edit-shape-modal-stroke-properties-section';
import LinePropertiesFormSection from './sections/edit-shape-modal-line-properties-section';

const EditShapeModalFormLine = ({ values, setValues, distanceUnits }: EditShapeModalFormProps): JSX.Element => {
  return (
    <>
      <FormSection heading='Line Properties' padding={12} gap={6} className='form-section-background'>
        <StrokePropertiesFormSection values={values} setValues={setValues} />
      </FormSection>
      <FormSection heading='Line Points' padding={12} gap={6} className='form-section-background'>
        <LinePropertiesFormSection values={values} setValues={setValues} distanceUnits={distanceUnits} />
      </FormSection>
    </>
  );
};

export default EditShapeModalFormLine;
