import React from 'react';
import ReactSelect, { MenuPlacement, StylesConfig } from 'react-select';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';
import { LayerTypes, SEASON_EXTENDERS } from '@gi/constants';

const SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  control: (provided) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, {}),
    minWidth: '150px',
  }),
};

const options = [
  { value: null, label: 'All Objects' },
  { value: LayerTypes.IRRIGATION, label: 'Irrigation' },
  { value: LayerTypes.STRUCTURES, label: 'Structure' },
  { value: LayerTypes.LAYOUT, label: 'Layout' },
  { value: SEASON_EXTENDERS, label: 'Season Extenders' },
];

const getOptionFromValue = (val) => {
  switch (val) {
    case null:
      return options[0];
    case LayerTypes.IRRIGATION:
      return options[1];
    case LayerTypes.STRUCTURES:
      return options[2];
    case LayerTypes.LAYOUT:
      return options[3];
    case SEASON_EXTENDERS:
      return options[4];
    default:
      console.warn('Unrecognised value passed in type filter');
      return options[0];
  }
};

interface iProps {
  type?: null | string;
  onTypeChange: (type: string) => void;
  menuPlacement?: MenuPlacement;
  id?: string;
  styles?: StylesConfig;
}

const TypeFilterDropdown = ({ type = null, onTypeChange, menuPlacement, id, styles }: iProps): JSX.Element => {
  const onChange = (opt) => {
    onTypeChange(opt.value);
  };

  return (
    <ReactSelect
      styles={styles ?? SELECT_STYLES}
      options={options}
      value={getOptionFromValue(type)}
      onChange={onChange}
      menuPortalTarget={document.body}
      menuPlacement={menuPlacement}
      id={id}
      isSearchable={false}
    />
  );
};

export default TypeFilterDropdown;
