import React, { CSSProperties, useMemo } from 'react';

import './icon.scss';

interface IIconProps {
  children: JSX.Element;
  className?: string;
  loading?: boolean;
  size?: number;
  faded?: boolean;
}

const Icon = ({ children, className = '', loading = false, size, faded = false }: IIconProps): JSX.Element => {
  const classNames = ['icon'];
  if (loading) {
    classNames.push('loading-icon');
  }
  if (faded) {
    classNames.push('faded');
  }
  if (className) {
    classNames.push(className);
  }

  const styles = useMemo(() => {
    const variables: CSSProperties = {};
    if (size !== undefined) {
      variables['--icon-size'] = `${size}px`;
    }
    return variables;
  }, [size]);

  return (
    <span className={classNames.join(' ')} style={styles}>
      {children}
    </span>
  );
};

export default Icon;
