const THUMBNAIL_WIDTH = 200;
const THUMBNAIL_HEIGHT = 150;

type Margins = {
  top: number;
  left: number;
  bottom: number;
  right: number;
};

const canvasToThumbnail = (
  canvas: HTMLCanvasElement,
  souceMargins: Margins = { top: 0, left: 0, bottom: 0, right: 0 },
  conversionArgs: Parameters<HTMLCanvasElement['toDataURL']>
) => {
  const sourceWidth = canvas.width - souceMargins.left - souceMargins.right;
  const sourceHeight = canvas.height - souceMargins.top - souceMargins.bottom;

  const scaleBy = Math.max(THUMBNAIL_WIDTH / sourceWidth, THUMBNAIL_HEIGHT / sourceHeight);

  const croppedSourceWidth = (THUMBNAIL_WIDTH / (sourceWidth * scaleBy)) * sourceWidth;
  const croppedSourceHeight = (THUMBNAIL_HEIGHT / (sourceHeight * scaleBy)) * sourceHeight;

  const destCanvas = document.createElement('canvas');

  destCanvas.width = THUMBNAIL_WIDTH;
  destCanvas.height = THUMBNAIL_HEIGHT;
  const ctx = destCanvas.getContext('2d');
  if (!ctx) {
    throw new Error('Faield to create 2D canvas context');
  }
  ctx.drawImage(canvas, souceMargins.left, souceMargins.top, croppedSourceWidth, croppedSourceHeight, 0, 0, THUMBNAIL_WIDTH, THUMBNAIL_HEIGHT);
  return destCanvas.toDataURL(...conversionArgs);
};

const PREVIEW_MAX_WIDTH = 700;

const canvasToPreview = (canvas: HTMLCanvasElement, conversionArgs: Parameters<HTMLCanvasElement['toDataURL']>) => {
  const sourceWidth = canvas.width;
  const sourceHeight = canvas.height;

  let destWidth = sourceWidth;
  let destHeight = sourceHeight;

  if (sourceWidth > PREVIEW_MAX_WIDTH) {
    destWidth = PREVIEW_MAX_WIDTH;
    destHeight *= PREVIEW_MAX_WIDTH / sourceWidth;
  }

  const destCanvas = document.createElement('canvas');

  destCanvas.width = destWidth;
  destCanvas.height = destHeight;
  const ctx = destCanvas.getContext('2d');
  if (!ctx) {
    throw new Error('Faield to create 2D canvas context');
  }
  ctx.drawImage(canvas, 0, 0, sourceWidth, sourceHeight, 0, 0, destWidth, destHeight);
  return destCanvas.toDataURL(...conversionArgs);
};

export { canvasToThumbnail, canvasToPreview };
