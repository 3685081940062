import { IRenderer, autoDetectRenderer } from 'pixi.js-new';

import { RenderMode } from '@gi/constants';

import Engine from '../engine';
import { Bounds } from '../types';
import { LimitsMode } from '../nodes/camera/camera-node';
import ContentRootContext from '../nodes/content-root/content-root-context';
import SelectableComponentContext from '../node-components/selectable/selectable-component-context';

type CanvasBrowserLimit = {
  dimensions: Dimensions;
  area: number;
};

/**
 * List of the limits of each browser for a single canvas.
 * Exceeding any of these limits leads to the canvas dying :(
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/canvas#maximum_canvas_size}
 */
const CANVAS_BROWSER_LIMITS: Record<string, CanvasBrowserLimit> = {
  chrome: {
    dimensions: { width: 32_767, height: 32_767 },
    area: 268_435_456,
  },
  firefox: {
    dimensions: { width: 32_767, height: 32_767 },
    area: 472_907_776,
  },
  safari: {
    dimensions: { width: 32_767, height: 32_767 },
    area: 268_435_456,
  },
  ie: {
    dimensions: { width: 8_192, height: 8_192 },
    area: 67_108_864, // Unknown, this is the area of the max width and height
  },
} as const;

/**
 * Returns the theoretical limits for canvases on this browser.
 * TODO: Implement this based on browser
 */
export const getBrowserLimits = () => {
  return CANVAS_BROWSER_LIMITS.chrome;
};

/**
 * Converts the given canvas to an image and automatically downloads it to the user's computer.
 * @param canvas The canvas to download
 * @param filename The filename of the image
 * @param type The image mime-type ('image/png' or 'image/jpeg')
 * @param quality The quality (if using lossy compression mime-type)
 */
export const downloadCanvasAsImage = (canvas: HTMLCanvasElement, filename: string, type?: string, quality?: number) => {
  const url = canvas.toDataURL(type, quality);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  window.URL.revokeObjectURL(url);
};

type ImageGeneratorContextOptions = {
  canvasWidth: number;
  canvasHeight: number;
  resolution: number;
  magnification: number;
};

const DEFAULT_CONTEXT_OPTIONS: ImageGeneratorContextOptions = {
  canvasWidth: 1000,
  canvasHeight: 1000,
  resolution: 1,
  magnification: 1,
} as const;

type ImageGenerationOptions = {
  frame: Bounds /** The area of the plan to print, in world coordinates. */;
  padding?: Bounds | number /** The padding to add to each edge of the image (in px). Can be used to allow for rulers. */;
  magnification?: number /* The magnification level to use. */;
  onProgress?: (percent: number) => void /** Callback to track progress */;
};

const DEFAULT_IMAGE_OPTIONS: Required<Omit<ImageGenerationOptions, 'frame'>> = {
  padding: { top: 0, left: 0, right: 0, bottom: 0 },
  magnification: 1,
  onProgress: () => {},
} as const;

interface ImageGeneratorContext {
  /**
   * Generates an image using the current engine at the given location.
   * @param options The options to use when egenrating the image. Must provide a `frame` to define which area to print.
   */
  generateImage(options: ImageGenerationOptions): HTMLCanvasElement;
  /**
   * Cleans up the context, resetting camera settings and returning control to the user.
   */
  cleanUp(): void;
}

/**
 * Creates an image generation "context". The engine will be put in print mode until the context is
 *  cleaned up using `context.cleanUp()`. This can be used to more efficiently bulk-generate images,
 *  rather than using `generateImage` each time, which always cleans up after itself.
 * @param engine The engine to generate images from
 * @param _options Options for image generation, that apply to every image.
 * @returns A set of functions to generate images with.
 */
export const getImageGeneratorContext = (engine: Engine, _options: Partial<ImageGeneratorContextOptions> = {}): ImageGeneratorContext => {
  const options: ImageGeneratorContextOptions = {
    ...DEFAULT_CONTEXT_OPTIONS,
    ..._options,
  };

  const contentRoot = engine.getContentRoot();
  if (!contentRoot) {
    throw new Error('No content root found.');
  }

  const contentRootContext = contentRoot.tryGetContext(ContentRootContext);
  if (!contentRootContext) {
    throw new Error('No content root context found. Camera state cannot be manipulated.');
  }

  const camera = contentRootContext.activeCamera;
  if (!camera) {
    throw new Error('No active camera found. Camera state cannot be manipulated.');
  }

  const originalRenderer = engine.renderer;
  const originalCameraState = { ...camera.state.values };
  const originalAnimationState = engine.animating;
  let imageGenerationRenderer: IRenderer | null = null;

  const canvas = document.createElement('canvas');

  // Render to a new canvas
  imageGenerationRenderer = autoDetectRenderer({
    view: canvas,
    width: options.canvasWidth,
    height: options.canvasHeight,
    resolution: options.resolution,
    antialias: true,
    preserveDrawingBuffer: true,
    backgroundAlpha: 0,
    forceCanvas: engine.renderMode === RenderMode.CANVAS,
  });

  engine.stop();
  engine.renderer = imageGenerationRenderer;

  contentRootContext.state.values.isPrinting = true;

  // Save selection for later and clear current selection
  const selectionContext = contentRoot.tryGetContext(SelectableComponentContext);
  const selection = selectionContext ? [...selectionContext.selection] : null;
  selectionContext?.clearSelection();

  // Mimic old renderer behavior by rounding to nearest half PX. TODO: Make this optional.
  camera.state.values.roundPosition = true;
  camera.state.values.viewportSize = { width: options.canvasWidth, height: options.canvasHeight };
  // Unlock the camera so we can go wherever we want
  camera.state.values.limitsMode = LimitsMode.None;
  camera.state.values.minMagnification = 0.00001;
  camera.state.values.maxMagnification = 1000000;
  camera.state.values.magnification = options.magnification;

  const _generateImage = (imageOptions: ImageGenerationOptions) => {
    const { frame, magnification, padding: _padding, onProgress } = { ...DEFAULT_IMAGE_OPTIONS, ...imageOptions };
    const padding: Bounds = typeof _padding === 'number' ? { top: _padding, left: _padding, bottom: _padding, right: _padding } : _padding;

    const imageWidth = (frame.right - frame.left) * magnification + (padding.left + padding.right);
    const imageHeight = (frame.bottom - frame.top) * magnification + (padding.top + padding.bottom);

    camera.state.values.magnification = magnification;

    // TODO: Validate image width/height to fit within acceptable limits.

    // The amount of segment needed should be the final image size divided by the render canvas size.
    const xSegmentsRequired = Math.ceil(imageWidth / options.canvasWidth);
    const ySegmentsRequired = Math.ceil(imageHeight / options.canvasHeight);

    // The canvas to hold the final stitched image
    const outputCanvas = document.createElement('canvas');
    outputCanvas.width = imageWidth * options.resolution;
    outputCanvas.height = imageHeight * options.resolution;

    const context = outputCanvas.getContext('2d');
    if (context === null) {
      throw new Error('Unable to get 2d context from canvas');
    }

    onProgress(0);

    // Generate each segment and draw it to our output canvas.
    for (let x = 0; x < xSegmentsRequired; x++) {
      for (let y = 0; y < ySegmentsRequired; y++) {
        onProgress((x * ySegmentsRequired + y) / (xSegmentsRequired * ySegmentsRequired));
        const offsetX = frame.left - padding.left / magnification + (options.canvasWidth / magnification) * x;
        const offsetY = frame.top - padding.top / magnification + (options.canvasHeight / magnification) * y;

        camera.alignWorldPosWithScreenPos({ x: offsetX, y: offsetY }, { x: 0, y: 0 }, true);
        engine.renderToCanvas(context, options.canvasWidth * options.resolution * x, options.canvasHeight * options.resolution * y);
      }
    }

    onProgress(1);

    return outputCanvas;
  };

  const _cleanUp = () => {
    contentRootContext.state.values.isPrinting = false;

    // Restore camera settings
    Object.keys(originalCameraState).forEach((key) => {
      camera.state.values[key] = originalCameraState[key];
    });

    // Restore selection
    if (selectionContext && selection !== null) {
      selectionContext.setSelection(selection);
    }

    // Restore the renderer
    engine.renderer = originalRenderer;
    engine.start(originalAnimationState);

    if (imageGenerationRenderer) {
      imageGenerationRenderer.clear();
      imageGenerationRenderer.destroy();
    }
  };

  return {
    generateImage: _generateImage,
    cleanUp: _cleanUp,
  };
};

/**
 * Generates an image of the given engine at the given coordinates.
 * @param engine The engine to generate the image from
 * @param options The options for the image
 * @returns A HTMLCanvas element with the generated image in the top-left
 */
export const generateImage = (engine: Engine, options: ImageGenerationOptions & Partial<Exclude<ImageGeneratorContextOptions, 'magnification'>>) => {
  const { frame, padding, magnification, canvasWidth, canvasHeight, resolution } = {
    ...DEFAULT_IMAGE_OPTIONS,
    ...DEFAULT_CONTEXT_OPTIONS,
    ...options,
  };

  const context = getImageGeneratorContext(engine, {
    canvasWidth,
    canvasHeight,
    magnification,
    resolution,
  });

  const image = context.generateImage({ frame, padding });
  context.cleanUp();

  return image;
};

/**
 * Promisified version of `generateImage`. Use `generateImage` where possible.
 *  Using this for compatibility with WGP and the old core-renderer, until all systems can be converted.
 * @deprecated
 * @param args Generate image arguments
 * @returns A promise that "instantly" resolves with a HTMLCanvasElements
 */
export const generateImagePromise = (...args: Parameters<typeof generateImage>): Promise<ReturnType<typeof generateImage>> => {
  return new Promise((resolve) => {
    resolve(generateImage(...args));
  });
};

export default generateImage;
