import React, { useContext } from 'react';

import networkConfig from '@gi/config/network-config';
import Plant from '@gi/plant';
import { PlantingCalendarDisplay, PlantingCalendarKey } from '@gi/planting-calendar';
import { DEFAULT_VARIETY } from '@gi/constants';
import { ResourceContext } from '@gi/resource-provider';

import ItemContentCell from '../../../components/item-content-table/item-content-cell';
import ItemContentRow from '../../../components/item-content-table/item-content-row';
import ItemContentCellHeader from '../../../components/item-content-table/item-content-cell-header';

interface iPlantingCalendarProps {
  plant: Plant;
}

const PlantingCalendarSection = ({ plant }: iPlantingCalendarProps): JSX.Element => {
  const { getPlantingCalendar, userArtifactCode, userNorthernHemisphere } = useContext(ResourceContext);

  const plantingCalendar = getPlantingCalendar(plant.code, null, DEFAULT_VARIETY);

  return (
    <ItemContentRow>
      <ItemContentCellHeader>
        Planting Times <PlantingCalendarKey />
      </ItemContentCellHeader>
      <ItemContentCell>
        <PlantingCalendarDisplay
          plantingCalendar={plantingCalendar}
          startMonth={userNorthernHemisphere ? 1 : 7}
          calendarBarURL={networkConfig.plantingCalendarImageURL}
          artifactCode={userArtifactCode}
        />
      </ItemContentCell>
    </ItemContentRow>
  );
};

export default PlantingCalendarSection;
