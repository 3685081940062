import React, { ReactNode } from 'react';

import { FormLayout } from '@gi/form-responsive';

import './banner.scss';

interface iProps {
  children: ReactNode;
  className?: string;
}

const BannerButtons = ({ children, className }: iProps): JSX.Element => {
  const classNames = ['banner-buttons'];
  if (className) {
    classNames.push(className);
  }

  return (
    <FormLayout
      className={classNames.join(' ')}
      desktopLayout={{ layout: 'column', yAlign: 'center', xAlign: 'stretch', gap: 6 }}
      mobileLayout={{ layout: 'row-wrap', xAlign: 'stretch', yAlign: 'center', gap: 6 }}
    >
      {children}
    </FormLayout>
  );
};

export default BannerButtons;
