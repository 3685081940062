import { AssetComponent, AssetComponentEvents, AssetType, NodeEvent, ShapeCollisionCheckFunctions, TilingSpriteComponent } from '@gi/core-renderer';
import { Geometry } from '@gi/math';
import { metricDistanceUnits } from '@gi/units';
import { ShapeType } from '@gi/constants';

import ShapeNode, { ShapeNodeState } from './shape-node';
import { getTriangleTooltipText } from './tooltip-utils';
import { CachedTriangleOutline } from '../outline-utils';

function isClockwiseWinding(point1: Vector2, point2: Vector2, point3: Vector2): boolean {
  return Geometry.crossProduct(Geometry.getPointDelta(point1, point2), Geometry.getPointDelta(point1, point3)) >= 0;
}

interface TriangleShapeNodeState {
  point2: Vector2;
}

class TriangleShapeNode extends ShapeNode<TriangleShapeNodeState, ShapeType.TRIANGLE> {
  type = 'TriangleShapeNode';

  #sprite: TilingSpriteComponent;
  #texture: AssetComponent<AssetType.TEXTURE>;

  #cachedOutline: CachedTriangleOutline;

  constructor(id: number, initialState: ShapeNodeState<TriangleShapeNodeState>['state']) {
    super(id, ShapeType.TRIANGLE, initialState);

    this.state.addUpdater(
      (state) => {
        this.transform.state.values.position = Geometry.getBoundingBox(state.values.point1, state.values.point2, state.values.point3).center;
        this.transform.state.values.rotation = state.values.rotation;
      },
      { properties: ['point1', 'point2', 'point3', 'rotation'] }
    );

    this.state.addWatcher(
      () => {
        this.#update();
      },
      { properties: ['point1', 'point2', 'point3', 'fill', 'stroke', 'strokeWidth'] },
      false
    );

    this.#sprite = this.components.add(new TilingSpriteComponent());

    this.#texture = this.components.add(new AssetComponent(AssetType.TEXTURE));
    this.#texture.eventBus.on(AssetComponentEvents.Loaded, (asset) => {
      this.#sprite.texture = asset;
    });

    this.state.addWatcher(
      (state) => {
        this.#texture.assetName = state.values.texture;
      },
      { properties: ['texture'] }
    );

    this.state.addWatcher(
      (_state) => {
        this.shape.collisionCheckFunction =
          _state.values.stroke !== null
            ? ShapeCollisionCheckFunctions.HollowConvexHull(_state.values.strokeWidth / 2)
            : ShapeCollisionCheckFunctions.ConvexHull;
      },
      { properties: ['stroke', 'strokeWidth'] }
    );

    this.eventBus.on(NodeEvent.DidBind, this.#didBind);
  }

  #didBind = () => {
    if (this.state.values.texture !== null) {
      this.#texture.assetName = this.state.values.texture;
    }
    this.#update();
  };

  getDimensions(): Dimensions {
    const { min, max } = Geometry.getBoundingBox(this.state.values.point1, this.state.values.point2, this.state.values.point3);
    return {
      width: Math.abs(max.x - min.x),
      height: Math.abs(max.y - min.y),
    };
  }

  getCenter(): Vector2 {
    const { center } = Geometry.getBoundingBox(this.state.values.point1, this.state.values.point2, this.state.values.point3);
    return center;
  }

  setPosition(center: Vector2, dimensions: Dimensions, rotation: number) {
    this.state.values.point1 = {
      x: center.x - dimensions.width / 2,
      y: center.y - dimensions.height / 2,
    };
    this.state.values.point2 = {
      x: center.x + dimensions.width / 2,
      y: center.y + dimensions.height / 2,
    };
    this.state.values.rotation = rotation;
  }

  #updateSprite() {
    const { texture } = this.state.values;
    const { width, height } = this.getDimensions();
    this.#sprite.width = width;
    this.#sprite.height = height;
    this.#sprite.mask = texture === null ? null : this.graphics;
    this.#sprite.visible = texture !== null;
  }

  #updateTriangle() {
    if (!this.graphics) {
      return;
    }

    const center = this.getCenter();
    const { point1, point2, point3, fill, stroke, strokeWidth, texture } = this.state.values;

    const finalPoint1 = Geometry.minusPoint(point1, center);
    const finalPoint2 = Geometry.minusPoint(point2, center);
    const finalPoint3 = Geometry.minusPoint(point3, center);

    this.graphics.clear();

    if (fill !== null || texture !== null) {
      this.graphics.beginFill(fill ?? 0xffffff, 1).lineStyle(0);
    } else {
      this.graphics.beginFill(0x000000, 0).lineStyle(strokeWidth, stroke ?? 0x000000, 1);
    }

    this.graphics.moveTo(finalPoint1.x, finalPoint1.y).lineTo(finalPoint2.x, finalPoint2.y).lineTo(finalPoint3.x, finalPoint3.y).closePath().endFill();
  }

  #updateShape() {
    const { point1, point2, point3, stroke } = this.state.values;

    // Shape relies on clockwise point winding for collision checks
    const points: [Vector2, Vector2, Vector2] = isClockwiseWinding(point1, point2, point3) ? [point1, point2, point3] : [point3, point2, point1];

    if (!this.#cachedOutline) {
      this.#cachedOutline = new CachedTriangleOutline(...points);
      this.shape.setPoints(this.#cachedOutline.path);
    } else if (this.#cachedOutline.update(...points)) {
      this.shape.setPoints(this.#cachedOutline.path);
    }

    this.outline.state.values.hollow = stroke !== null;
  }

  #update() {
    this.#updateTriangle();
    this.#updateSprite();
    this.#updateShape();
  }

  protected updateTooltip(): void {
    const { point1, point2, point3 } = this.state.values;
    const distanceUnits = this.planSettings?.getDistanceUnits() ?? metricDistanceUnits;
    this.tooltip.state.values.text = getTriangleTooltipText(point1, point2!, point3, distanceUnits);
  }
}

export default TriangleShapeNode;
