import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GardenPlatformState = {
  showIntroVideo: boolean;
};

const DEFAULT_STATE: GardenPlatformState = {
  showIntroVideo: false,
};

export const gardenPlatformSlice = createSlice({
  name: 'platform',
  initialState: DEFAULT_STATE,
  reducers: {
    setShowIntroVideo: (state, action: PayloadAction<boolean>) => {
      state.showIntroVideo = action.payload;
      return state;
    },
  },
});

export const gardenPlatformReducer = gardenPlatformSlice.reducer;
export const GardenPlatformActionCreators = gardenPlatformSlice.actions;

interface RootState {
  platform: GardenPlatformState;
}

export const GardenPlatformSelectors = {
  getShowIntroVideo: (state: RootState) => state.platform.showIntroVideo,
};
