import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { LocalSettingsActionCreators } from '@gi/local-settings';
import { PlannerActionCreators } from '@gi/app-planner-slice';
import { TutorialContext } from '@gi/tutorials';

import TouchIntroductionModal from './touch-introduction-modal';

const TouchIntroductionModalRenderer = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const { touchModeIntroductionModalVisible } = useContext(TutorialContext);

  const onTouchIntroductionModalClose = (touchMode: boolean) => {
    dispatch(
      LocalSettingsActionCreators.saveLocalSettings({
        showTouchIntroductionModalOnStart: false,
        touchMode,
      })
    );
    dispatch(PlannerActionCreators.dismissTouchIntroductionModal());
  };

  if (!touchModeIntroductionModalVisible) {
    return null;
  }

  return <TouchIntroductionModal closeModal={onTouchIntroductionModalClose} />;
};

export default TouchIntroductionModalRenderer;
