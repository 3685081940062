import React from 'react';
import type { RouteObject } from 'react-router-dom';
import Account from '@gi/app-account';
import { Application, ApplicationLinks } from '@gi/garden-platform-navigation';

const appAccountRoutes: RouteObject = {
  path: ApplicationLinks[Application.Account],
  element: <Account />,
};

export default appAccountRoutes;
