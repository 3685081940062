import React, { ReactNode, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';
import { useOnClickOutside } from '@gi/react-utils';

import ModalTab from './modal-tab';
import ModalTabList from './modal-tab-list';
import ModalTabContext from './modal-tab-context';

import './modal-tab-list-mobile.scss';

interface ModalTabListDefinition {
  label: ReactNode;
  tabID: string;
}

interface iProps {
  tabs: ModalTabListDefinition[];
}

const ModalTabListMobile = ({ tabs }: iProps): JSX.Element => {
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);
  const { activeTab, setActiveTab } = useContext(ModalTabContext);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  useOnClickOutside<HTMLElement>([dropdownRef, buttonRef], () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  /**
   * If we're in desktop mode, generate a normal tab list.
   */
  if (deviceDisplayMode === DeviceDisplayMode.DESKTOP) {
    return (
      <ModalTabList>
        {tabs.map((tab) => (
          <ModalTab tabID={tab.tabID} key={tab.tabID}>
            {tab.label}
          </ModalTab>
        ))}
      </ModalTabList>
    );
  }

  const activeTabDefinitionID = tabs.findIndex((tab) => tab.tabID === activeTab);
  const activeTabDefinition = tabs[activeTabDefinitionID];

  const previousDisabled = activeTabDefinitionID === -1 || activeTabDefinitionID === 0;
  const nextDisabled = activeTabDefinitionID === -1 || activeTabDefinitionID === tabs.length - 1;

  const openPreviousTab = () => {
    if (activeTabDefinitionID > 0) {
      setActiveTab(tabs[activeTabDefinitionID - 1].tabID);
    }
  };

  const openNextTab = () => {
    if (activeTabDefinitionID < tabs.length - 1 && activeTabDefinitionID !== -1) {
      setActiveTab(tabs[activeTabDefinitionID + 1].tabID);
    }
  };

  const handleDropdownClick = (tabID: string) => {
    setActiveTab(tabID);
    setShowDropdown(false);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className='modal-tab-list-mobile'>
      <button className='previous-tab' type='button' onClick={openPreviousTab} disabled={previousDisabled}>
        <i className='icon-previous' />
      </button>
      <button className='active-tab' type='button' onClick={toggleDropdown} ref={buttonRef}>
        {activeTabDefinition?.label}
      </button>
      <button className='next-tab' type='button' onClick={openNextTab} disabled={nextDisabled}>
        <i className='icon-next' />
      </button>
      <div className={`tabs-dropdown ${showDropdown ? 'open' : ''}`} ref={dropdownRef}>
        <div className='tabs-dropdown-inner'>
          {tabs.map((tab) => (
            <button
              className={`tabs-dropdown-button ${activeTab === tab.tabID ? 'active' : ''}`}
              type='button'
              key={tab.tabID}
              onClick={() => handleDropdownClick(tab.tabID)}
            >
              {tab.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModalTabListMobile;
