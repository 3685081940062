import React, { useContext } from 'react';

import { GuruPestContentPage } from '@gi/app-guru-types/pages';
import { ResourceContext } from '@gi/resource-provider';

import PageContainer from '../page-container';
import PestInformationSection from './pest-information-section';
import ItemContentTable from '../../components/item-content-table/item-content-table';
import LeadImage from '../../components/lead-image/lead-image';
import Gallery from '../../components/gallery/gallery';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import RelatedContentSection from '../../components/related-content/related-content-section';
import GuruHeaderContentPortal from '../../header/guru-header-content-portal';
import ControlsBanner from '../../components/controls-banner/controls-banner';
import BookmarkButton from '../../components/controls-banner/bookmark-button';
import ContentPageContentColumn from '../../components/content-page/content-page-content-column';
import GuruContentContainer from '../../components/content/guru-content-container';
import ControlsBannerSection from '../../components/controls-banner/controls-banner-section';
import PestContentHeader from './pest-content-header';

import styles from './pest-content-display.module.css';

interface iProps {
  guruPest: GuruPestContentPage;
}

const PestContentDisplay = ({ guruPest }: iProps): JSX.Element | null => {
  const { getPest } = useContext(ResourceContext);
  const pest = getPest(guruPest.content.pestCode);

  if (pest === null) {
    // TODO - redirect? 404? Break?
    return null;
  }

  return (
    <PageContainer className={`${styles.plantContentDisplay}`}>
      <GuruHeaderContentPortal background={guruPest.backgroundImage}>
        <PestContentHeader title={guruPest.title} pest={pest} />
      </GuruHeaderContentPortal>
      <div className='guru-section-breadcrumbs'>
        <div className='guru-narrow-content-width'>
          <Breadcrumbs />
        </div>
      </div>
      <div className='guru-section'>
        <GuruContentContainer>
          <ContentPageContentColumn className='guru-narrow-content-width'>
            <ControlsBanner style={{ justifyContent: 'flex-end' }}>
              <ControlsBannerSection>
                <BookmarkButton contentUUID={guruPest.contentUUID} />
              </ControlsBannerSection>
            </ControlsBanner>
            <LeadImage className='' alt='' src={pest.images[0].imageUrl} attribution={pest.images[0].attribution} />
            <ItemContentTable className={styles.itemTable}>
              <PestInformationSection pest={pest} property='location' title='Location' />
              <PestInformationSection pest={pest} property='description' title='Description' />
              <PestInformationSection pest={pest} property='hostPlantsGarden' title='Garden Host Plants' />
              <PestInformationSection pest={pest} property='hostPlantsWild' title='Wild Host Plants' />
              <PestInformationSection pest={pest} property='hostCrops' title='Host Crops' />
              <PestInformationSection pest={pest} property='damage' title='Damage' />
              <PestInformationSection pest={pest} property='prevention' title='Prevention' />
              <PestInformationSection pest={pest} property='managing' title='Managing' />
              <PestInformationSection pest={pest} property='benefits' title='Benefits' />
              <PestInformationSection pest={pest} property='habitat' title='Habitat' />
              <PestInformationSection pest={pest} property='attracting' title='Attracting' />
              <PestInformationSection pest={pest} property='tips' title='Tips' />
            </ItemContentTable>
          </ContentPageContentColumn>
        </GuruContentContainer>
      </div>
      <div className='guru-section-alt'>
        <div className='guru-content-width guru-pad-content'>
          <Gallery
            items={pest.images.map((pestImage) => ({
              thumbnailSrc: pestImage.imageUrl,
              content: pestImage.imageUrl,
              attribution: pestImage.attribution,
              caption: pestImage.caption,
            }))}
          />
          {/* {pest.images.map((pestImage, i) => {
            return (
              <div key={i}>
                <figure>
                  <img src={pestImage.imageUrl} alt='' />
                  {pestImage.attribution ? <figcaption>Attribution: {pestImage.attribution}</figcaption> : null}
                </figure>
              </div>
            );
          })} */}
          {/* <img src={pest.images.portrait} alt={pest.name} />
          <img src={pest.images.landscape} alt={pest.name} /> */}
        </div>
      </div>
      <RelatedContentSection url={guruPest.relatedContentUrl} outerClassName='guru-section' innerClassName='guru-narrow-content-width guru-pad-content' />
    </PageContainer>
  );
};

export default PestContentDisplay;
