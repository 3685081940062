import { Geometry } from '@gi/math';

import Collider from './collider';
import { SimulatedGardenItem } from '../../simulated-garden-item';

/**
 * Simple box variant of a collider. Can be rotated.
 */
class BoxCollider extends Collider {
  #position: Vector2;
  get position() {
    return this.#position;
  }

  #width: number;
  get width() {
    return this.#width;
  }

  #height: number;
  get height() {
    return this.#height;
  }

  #rotation: number;
  get rotation() {
    return this.#rotation;
  }

  #cachedCollisionPoints: null | Vector2[] = null;
  #cachedBoundingBox: null | { min: Vector2; max: Vector2; center: Vector2 } = null;
  #cachedHitbox: null | { min: Vector2; max: Vector2 } = null;

  constructor(owner: SimulatedGardenItem, position: Vector2, width: number, height: number, rotation: number = 0) {
    super(owner);

    this.#position = position;
    this.#width = width;
    this.#height = height;
    this.#rotation = rotation;
  }

  setPosition(position: Vector2) {
    this.#position = position;
    this.#invalidateCache();
  }

  setSize(width: number, height: number) {
    this.#width = width;
    this.#height = height;
    this.#invalidateCache();
  }

  setRotation(rotation: number) {
    this.#rotation = rotation;
    this.#invalidateCache();
  }

  getCollisionPoints(): Vector2[] {
    if (this.#cachedCollisionPoints !== null) {
      return this.#cachedCollisionPoints;
    }

    this.#cachedCollisionPoints = [
      { x: this.#position.x - this.#width / 2, y: this.#position.y - this.#height / 2 },
      { x: this.#position.x + this.#width / 2, y: this.#position.y - this.#height / 2 },
      { x: this.#position.x + this.#width / 2, y: this.#position.y + this.#height / 2 },
      { x: this.#position.x - this.#width / 2, y: this.#position.y + this.#height / 2 },
    ].map((point) => Geometry.rotateAroundPoint(point, this.#position, this.#rotation));

    return this.#cachedCollisionPoints;
  }

  getBoundingBox(): { min: Vector2; max: Vector2; center: Vector2 } {
    if (this.#cachedBoundingBox !== null) {
      return this.#cachedBoundingBox;
    }

    this.#cachedBoundingBox = Geometry.getBoundingBox(...this.getCollisionPoints());

    return this.#cachedBoundingBox;
  }

  /**
   * Returns the axis-aligned bounding box of this collider if its rotation was removed.
   * This can be used to check collisions, by rotating anything to check by -rotation and then
   *   doing a simple "does box contain point" check
   */
  #getAxisAlignedHitbox(): { min: Vector2; max: Vector2 } {
    if (this.#cachedHitbox) {
      return this.#cachedHitbox;
    }

    this.#cachedHitbox = Geometry.getBoundingBox(...this.getCollisionPoints().map((point) => Geometry.rotateAroundOrigin(point, -this.#rotation)));

    return this.#cachedHitbox;
  }

  doesContain(collider: Collider): boolean {
    const hitbox = this.#getAxisAlignedHitbox();
    const collisionPoints = collider.getCollisionPoints();
    for (let i = 0; i < collisionPoints.length; i++) {
      const point = Geometry.rotateAroundOrigin(collisionPoints[i], -this.#rotation);
      if (point.x < hitbox.min.x || point.x > hitbox.max.x || point.y < hitbox.min.y || point.y > hitbox.max.y) {
        return false;
      }
    }
    return true;
  }

  /**
   * Deletes any cached values, usually because they've been invalidated by a property change.
   */
  #invalidateCache() {
    this.#cachedHitbox = null;
    this.#cachedBoundingBox = null;
    this.#cachedCollisionPoints = null;
    this.needsUpdate();
  }
}

export default BoxCollider;
