/* eslint-disable no-bitwise */
/**
 * Returns a random UUID.
 * Falls back to a less-secure-random UUID if the crypto library is unavailable.
 * @returns A random UUID
 */
export const randomUUID = (): string => {
  try {
    return crypto.randomUUID();
  } catch (e) {
    // Insecure context, on crypto lib not available, fall back to something else.
    // Adapted from https://stackoverflow.com/a/2117523
    return `${1e7}${-1e3}${-4e3}${-8e3}${-1e11}`.replace(/[018]/g, (c) =>
      (Number(c) ^ ((crypto.getRandomValues(new Uint8Array(1))[0] & 15) >> (Number(c) / 4))).toString(16)
    );
  }
};
