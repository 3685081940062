import React, { useMemo, useState } from 'react';

import GalleryNav from './gallery-nav';
import ProductImage from './product-image';

import styles from './product-gallery.module.css';

interface iProps {
  className?: string;
  galleryImageUrls: string[];
  currentImageIndex: number;
  setCurrentImageIndex: (index: number) => void;
  magnify?: () => void;
}

const ProductGallery = ({ className = '', galleryImageUrls, currentImageIndex, setCurrentImageIndex, magnify }: iProps): JSX.Element => {
  const [minDimensions, setMinDimensions] = useState<{ width: number; height: number } | null>(null);

  const style: React.CSSProperties = useMemo(() => {
    if (minDimensions === null) {
      return {};
    }

    return { minWidth: minDimensions.width, minHeight: minDimensions.height };
  }, [minDimensions]);

  const onLoad: React.ReactEventHandler<HTMLImageElement> = (e) => {
    const target = e.target as HTMLImageElement; // Need to cast, for some reason TS/React doesn't add types to this target
    setMinDimensions({ width: target.width, height: target.height });
  };

  return (
    <div className={`${styles.productGallery} ${className}`} style={style}>
      <ProductImage src={galleryImageUrls[currentImageIndex]} key={galleryImageUrls[currentImageIndex]} onLoad={onLoad} />
      <GalleryNav
        className={styles.galleryNavPos}
        count={galleryImageUrls.length}
        index={currentImageIndex}
        setIndex={setCurrentImageIndex}
        magnify={magnify}
      />
    </div>
  );
};

export default ProductGallery;
