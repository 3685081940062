import { User } from '@gi/user';
import eventServiceIntsance from './event-service-instance';

export type AnalyticsVideoWatch = {
  videoStartedAt: number;
  videoPlayDuration: number;
};

export type AnalyticsVideoWatches = {
  videoName: string;
  videoLength: number;
  videoWatches: AnalyticsVideoWatch[];
};

export const sendVideoAnalytics = (user: User, event: AnalyticsVideoWatches) => {
  return eventServiceIntsance.sendVideoEvent(user, event);
};

export const createVideoAnalytics = (videoName: string, videoLength: number, videoWatches: [number, number][]): AnalyticsVideoWatches => {
  return {
    videoName,
    videoLength,
    videoWatches: videoWatches.map(([videoStartedAt, videoPlayDuration]) => ({
      videoStartedAt,
      videoPlayDuration,
    })),
  };
};
