import { batchActions } from 'redux-batched-actions';
import { generateNotificationID, NotificationActionCreators, PremadeNotifications } from '@gi/notifications';

import * as RequestActionCreators from '../request-action-creators';
import { createLoadUserRequestKey, createSaveUserRequestKey } from './request-key-creators';

export const logoutUser = () => {
  // Doesn't have to return a thunk but we will for patterns sake
  return (dispatch) => {
    const notificationID = generateNotificationID();

    dispatch(NotificationActionCreators.createDefaultNotification(PremadeNotifications.logoutSuccess(notificationID)));
  };
};

export const loadUser = (userID, postAuthTicket) => {
  return (dispatch, getState, { services }) => {
    const notificationID = generateNotificationID();
    const requestKey = createLoadUserRequestKey(userID);

    dispatch(
      batchActions([
        RequestActionCreators.requestStart(requestKey),
        NotificationActionCreators.createDefaultNotification(PremadeNotifications.loadingProfileStart(notificationID)),
      ])
    );

    return services.userService
      .getPostAuthUser(userID, postAuthTicket)
      .then((savedUser) => {
        dispatch(
          batchActions([
            RequestActionCreators.requestComplete(requestKey),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: PremadeNotifications.loadingProfileSuccessUpdate,
            }),
          ])
        );

        return savedUser;
      })
      .catch((err) => {
        console.error(err);
        if (err && err.clientMessage) {
          console.error(err.clientMessage);
        }

        dispatch(
          batchActions([
            RequestActionCreators.requestFail(requestKey, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: PremadeNotifications.loadingProfileFailUpdate,
            }),
          ])
        );

        throw err;
      });
  };
};

export const saveUser = (user) => {
  return (dispatch, getState, { services }) => {
    const notificationID = generateNotificationID();
    const requestKey = createSaveUserRequestKey(user.ID);

    dispatch(
      batchActions([
        RequestActionCreators.requestStart(requestKey),
        NotificationActionCreators.createDefaultNotification(PremadeNotifications.savingProfileStart(notificationID)),
      ])
    );

    return services.userService
      .saveUser(user)
      .then((savedUser) => {
        dispatch(
          batchActions([
            RequestActionCreators.requestComplete(requestKey),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: PremadeNotifications.savingProfileSuccessUpdate,
            }),
          ])
        );

        return savedUser;
      })
      .catch((err) => {
        console.error(err);
        if (err && err.clientMessage) {
          console.error(err.clientMessage);
        }

        dispatch(
          batchActions([
            RequestActionCreators.requestFail(requestKey, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: PremadeNotifications.savingProfileFailUpdate,
            }),
          ])
        );

        throw err;
      });
  };
};

export const loginUser = (email, password) => {
  return (dispatch, getState, { services }) => {
    const notificationID = generateNotificationID();
    const requestKey = createLoadUserRequestKey('LOGIN');

    dispatch(
      batchActions([
        RequestActionCreators.requestStart(requestKey),
        NotificationActionCreators.createDefaultNotification(PremadeNotifications.loginStart(notificationID)),
      ])
    );

    return services.userService
      .getPreAuthUser(email, password)
      .then((savedUser) => {
        dispatch(
          batchActions([
            RequestActionCreators.requestComplete(requestKey),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: PremadeNotifications.loginSuccessUpdate,
            }),
          ])
        );

        return savedUser;
      })
      .catch((err) => {
        console.error(err);
        if (err && err.clientMessage) {
          console.error(err.clientMessage);
        }

        dispatch(
          batchActions([
            RequestActionCreators.requestFail(requestKey, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: PremadeNotifications.loginFailUpdate,
            }),
          ])
        );

        throw err;
      });
  };
};
