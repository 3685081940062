import React from 'react';

import { FormSection } from '@gi/form-responsive';

import UserChangePasswordForm from '../forms/user-change-password-form';
import UserLogoutForm from '../forms/user-logout-form';

import './settings-pages.scss';
import UserSubscriptionOverview from '../forms/user-subscription-overview';

const YourAccountPage = (): JSX.Element => {
  return (
    <div className='settings-page'>
      <UserSubscriptionOverview />
      <UserChangePasswordForm />
      <UserLogoutForm />
      <FormSection className='form-section-background'>
        <h2>Disable Account</h2>
        <p>
          You can disable your account from the{' '}
          <a target='_blank' rel='noreferrer' href='/account/settings/account'>
            Your Account page
          </a>
          .
        </p>
      </FormSection>
    </div>
  );
};

export default YourAccountPage;
