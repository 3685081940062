import React from 'react';

import { useGuruHero } from '../../components/directory-page/directory-page-hooks';
import HeroPreview from '../../components/hero-preview/hero-preview';

interface iProps {
  composedPageId: string;
}

const HeaderHero = ({ composedPageId }: iProps): JSX.Element | null => {
  const hero = useGuruHero(composedPageId);

  if (hero === null) {
    return null;
  }

  return <HeroPreview items={hero.items} />;
};

export default HeaderHero;
