import Country, { CountryCollection } from '@gi/country';

/**
 * Returns a country from a country collection with the given country code, else returns null
 */
const getUserCountry = (userCountryCode: string | null, countries: CountryCollection | null): Country | null => {
  if (userCountryCode === null) {
    return null;
  }

  if (countries === null) {
    return null;
  }

  return countries.get(userCountryCode);
};

export default getUserCountry;
