import React from 'react';

import LocationUnsetBanner from './banners/location-unset-banner';
import DisplayModeSuggestionBanner from './banners/display-mode-suggestion-banner';

const Banners = (): JSX.Element => {
  return (
    <>
      <LocationUnsetBanner />
      <DisplayModeSuggestionBanner />
    </>
  );
};

export default Banners;
