import React from 'react';
import { Link, Navigate, RouteObject } from 'react-router-dom';
import { Application, getAbsoluteApplicationLink, getRelativeApplicationLink } from '@gi/garden-platform-navigation';

import { DefaultRoot, HomeDirectoryPage, DefaultDirectoryPage, BookmarksDirectoryPage, Error404Page, ContentPage, SearchDirectoryPage } from '@gi/app-guru';

import { GuruDirectoryPageId } from '@gi/app-guru-types';
import { createDirectoryPageURL, createDirectoryPagePath, directoryPageRoutes } from '@gi/app-guru-common';

import GuruRoot from './guru-root';

function createDirectoryPageHandle(directoryId: GuruDirectoryPageId) {
  return {
    crumb: () => <Link to={createDirectoryPageURL(directoryId)}>{directoryPageRoutes[directoryId].desktopContent}</Link>,
  };
}

const appGuruRoutes: RouteObject = {
  path: getRelativeApplicationLink(Application.Guru),
  element: <GuruRoot />,
  handle: {
    crumb: () => <Link to={getAbsoluteApplicationLink(Application.Guru)}>Garden Guru</Link>,
  },
  children: [
    {
      path: '*',
      element: <Error404Page />,
    },
    {
      path: '',
      element: <Navigate to={createDirectoryPageURL(GuruDirectoryPageId.RootDirectory)} replace />,
    },
    {
      path: createDirectoryPagePath(GuruDirectoryPageId.RootDirectory),
      // index: true,
      element: <DefaultRoot />,
      children: [
        {
          path: '',
          element: <HomeDirectoryPage guruPageId={GuruDirectoryPageId.RootDirectory} />,
          index: true,
        },
      ],
    },
    {
      path: createDirectoryPagePath(GuruDirectoryPageId.VideoDirectory),
      element: <DefaultRoot />,
      handle: createDirectoryPageHandle(GuruDirectoryPageId.VideoDirectory),
      children: [
        {
          index: true,
          path: '',
          element: <DefaultDirectoryPage guruPageId={GuruDirectoryPageId.VideoDirectory} />,
        },
        {
          path: ':slug',
          element: <ContentPage />,
        },
      ],
    },
    {
      path: createDirectoryPagePath(GuruDirectoryPageId.PlantDirectory),
      element: <DefaultRoot />,
      handle: createDirectoryPageHandle(GuruDirectoryPageId.PlantDirectory),
      children: [
        {
          index: true,
          path: '',
          element: <DefaultDirectoryPage guruPageId={GuruDirectoryPageId.PlantDirectory} usePaginatedGrids={false} />,
        },
        {
          path: ':slug',
          element: <ContentPage />,
        },
      ],
    },
    {
      path: createDirectoryPagePath(GuruDirectoryPageId.PestDirectory),
      element: <DefaultRoot />,
      handle: createDirectoryPageHandle(GuruDirectoryPageId.PestDirectory),
      children: [
        {
          index: true,
          path: '',
          element: <DefaultDirectoryPage guruPageId={GuruDirectoryPageId.PestDirectory} usePaginatedGrids={false} />,
        },
        {
          path: ':slug',
          element: <ContentPage />,
        },
      ],
    },
    {
      path: createDirectoryPagePath(GuruDirectoryPageId.ArticleDirectory),
      element: <DefaultRoot />,
      handle: createDirectoryPageHandle(GuruDirectoryPageId.ArticleDirectory),
      children: [
        {
          index: true,
          path: '',
          element: <DefaultDirectoryPage guruPageId={GuruDirectoryPageId.ArticleDirectory} />,
        },
        {
          path: ':slug',
          element: <ContentPage />,
        },
      ],
    },
    {
      path: createDirectoryPagePath(GuruDirectoryPageId.BookmarkDirectory),
      element: <DefaultRoot />,
      handle: createDirectoryPageHandle(GuruDirectoryPageId.BookmarkDirectory),
      children: [
        {
          index: true,
          path: '',
          element: <BookmarksDirectoryPage guruPageId={GuruDirectoryPageId.BookmarkDirectory} />,
        },
      ],
    },
    {
      path: createDirectoryPagePath(GuruDirectoryPageId.SearchDirectory),
      element: <DefaultRoot />,
      handle: createDirectoryPageHandle(GuruDirectoryPageId.SearchDirectory),
      children: [
        {
          index: true,
          path: '',
          element: <SearchDirectoryPage guruPageId={GuruDirectoryPageId.SearchDirectory} />,
        },
      ],
    },
  ],
};

export default appGuruRoutes;
