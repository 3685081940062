import { RequestError } from '@gi/gi-network';
import getForecastErrorCodes from './get-forecast-error-codes';

/**
 * Attaches a client message to a Network error from a getForecast request based on the status code. Attaches a default message
 * when there isn't a specific message for the given status code.
 *
 * @param {NetworkError} responseError
 */
const attachForecastErrorClientMessage = (responseError: RequestError): void => {
  if (responseError.statusCode && getForecastErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = getForecastErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when loading forecast - ${responseError.statusCode}`;
  }
};

export { attachForecastErrorClientMessage };
