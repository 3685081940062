import React from 'react';
import styles from './menu-button.module.css';

interface iProps {
  children: React.ReactNode;
}

const IconContainer = ({ children }: iProps): JSX.Element => {
  return <span className={styles.iconContainer}>{children}</span>;
};

export default IconContainer;
