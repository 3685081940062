import React, { useCallback } from 'react';

import { GuruPreviewPest } from '@gi/app-guru-types';
import { PestTag, PestType } from '@gi/pest';
import ClearableInput from '@gi/clearable-input';

import { useGuruPestFilters } from '../use-pest-filters';
import ContentGridInner from '../content-grid-inner';
import SmallPreviewItem from '../../small-preview/items/small-preview-item';
import ContentGridNoResults from './content-grid-no-results';

import pestImage from './images/pests.jpg';
import diseasesImage from './images/diseases.jpg';
import beneficialInsect from './images/beneficial-insects.jpg';

import styles from './content-plant-grid.module.css';

interface iProps {
  id: string;
  items?: GuruPreviewPest[];
  minItemHeight?: number;
  itemAspectRatio?: number;
  gap?: number;
}

// Maps a pest type to a verb describing what it does to plants
const PestTypeVerb = {
  [PestType.Pest]: 'Attacks',
  [PestType.Disease]: 'Affects',
  [PestType.Beneficial]: 'Benefits',
};

// Maps a pest type to an icon
const PestTypeIcon = {
  [PestType.Pest]: pestImage,
  [PestType.Disease]: diseasesImage,
  [PestType.Beneficial]: beneficialInsect,
};

const PestTypeLabels = {
  [PestType.Pest]: 'Pests',
  [PestType.Disease]: 'Diseases',
  [PestType.Beneficial]: 'Beneficial Insects',
};

// Maps a pest type to the list of tags it can be filtered by
const PestTagsByType = {
  [PestType.Beneficial]: [PestTag.Pollinator, PestTag.Predator, PestTag.Composter],
  [PestType.Pest]: [
    PestTag.AffectsLeaves,
    PestTag.AffectsFruit,
    PestTag.AffectsSeeds,
    PestTag.AffectsBark,
    PestTag.AffectsSeedlings,
    PestTag.AffectsFlowers,
    PestTag.AffectsStems,
    PestTag.AffectsRoots,
  ],
  [PestType.Disease]: [
    PestTag.AffectsLeaves,
    PestTag.AffectsFruit,
    PestTag.AffectsSeeds,
    PestTag.AffectsSeedlings,
    PestTag.AffectsFlowers,
    PestTag.AffectsStems,
    PestTag.AffectsRoots,
  ],
};

// Labels for every pest tag
const PestTagLabels = {
  [PestTag.Disease]: 'Plant Disease',
  [PestTag.Pest]: 'Pest',
  [PestTag.Beneficial]: 'Beneficial Insects',
  [PestTag.AffectsBark]: 'Bark',
  [PestTag.AffectsFlowers]: 'Flowers',
  [PestTag.AffectsFruit]: 'Fruit',
  [PestTag.AffectsLeaves]: 'Leaves',
  [PestTag.AffectsRoots]: 'Roots',
  [PestTag.AffectsSeedlings]: 'Seedlings',
  [PestTag.AffectsSeeds]: 'Seeds',
  [PestTag.AffectsStems]: 'Stems',
  [PestTag.CommonInGreenhouse]: 'Greenhouses/Tunnels',
  [PestTag.SoilBorne]: 'Soil',
  [PestTag.Composter]: 'Composter (help soil)',
  [PestTag.Predator]: 'Predator (eats pests)',
  [PestTag.Pollinator]: 'Pollinator',
};

const ContentPestGrid = ({ id, items, minItemHeight, itemAspectRatio, gap }: iProps): JSX.Element => {
  const { filteredPests, toggleTag, setType, setSearchTerm, getSearchResultMatchText, filters, reset } = useGuruPestFilters(id, items ?? []);

  const createCheckboxFilter = useCallback(
    (tag: PestTag, name: string) => {
      const checked = filters.tags.includes(tag);
      return (
        <label
          key={`guru-pest-filter:${tag ?? 'none'}`}
          className={`${styles.guruFilterCheckboxLabel} ${checked ? styles.guruFilterCheckboxLabelChecked : ''}`}
        >
          <input
            type='checkbox'
            checked={checked}
            onChange={() => toggleTag(tag)}
            id={`guru-pest-filter:${tag ?? 'none'}`}
            className={styles.guruFilterCheckbox}
          />
          <span>{name}</span>
        </label>
      );
    },
    [filters]
  );

  const createTypeFilter = useCallback(
    (type: PestType) => {
      const checked = filters.type === type;

      return (
        <label key={`guru-pest-filter:${type ?? 'none'}`} className={`${styles.iconCheckboxLabel} ${checked ? styles.iconCheckboxLabelChecked : ''}`}>
          <input
            type='checkbox'
            checked={checked}
            onChange={() => setType(type)}
            id={`guru-pest-filter:${type ?? 'none'}`}
            className={styles.guruFilterCheckbox}
          />
          <span className={styles.iconCheckboxContent}>
            <img src={PestTypeIcon[type]} alt={type} />
            <span className={styles.iconCheckboxContentName}>{PestTypeLabels[type]}</span>
          </span>
        </label>
      );
    },
    [filters]
  );

  return (
    <div className={styles.plantGrid}>
      <div className={styles.plantGridFiltersContainer}>
        <div className={`${styles.plantGridFilters} ${styles.plantGridFiltersCentered}`}>
          <div className={styles.filterCollection}>
            <div className={styles.filterCollectionItems}>
              {createTypeFilter(PestType.Pest)}
              {createTypeFilter(PestType.Beneficial)}
              {createTypeFilter(PestType.Disease)}
            </div>
          </div>
        </div>
        <div className={styles.plantGridFilters}>
          <div className={`${styles.filterCollection}`}>
            <div className={styles.filterCollectionTitle}>Search:</div>
            <ClearableInput value={filters.searchTerm} onChange={(value) => setSearchTerm(value)} />
          </div>
          <div className={styles.filterCollection}>
            <div className={styles.filterCollectionTitle}>{PestTypeVerb[filters.type]}:</div>
            <div className={styles.filterCollectionItems}>{PestTagsByType[filters.type].map((tag) => createCheckboxFilter(tag, PestTagLabels[tag]))}</div>
          </div>
          <div className={styles.filterCollection}>
            <div className={styles.filterCollectionTitle}>Commonly found in:</div>
            <div className={styles.filterCollectionItems}>
              {createCheckboxFilter(PestTag.SoilBorne, PestTagLabels[PestTag.SoilBorne])}
              {createCheckboxFilter(PestTag.CommonInGreenhouse, PestTagLabels[PestTag.CommonInGreenhouse])}
            </div>
          </div>
        </div>
      </div>
      {filteredPests.length > 0 ? (
        <ContentGridInner minItemHeight={minItemHeight} itemAspectRatio={itemAspectRatio} gap={gap}>
          {filteredPests.map((item) => (
            <SmallPreviewItem key={item.content.pestCode} item={item}>
              {getSearchResultMatchText(item.content.pestCode)}
            </SmallPreviewItem>
          ))}
        </ContentGridInner>
      ) : (
        <ContentGridNoResults onResetClick={reset} />
      )}
    </div>
  );
};

export default ContentPestGrid;
