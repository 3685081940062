import { batchActions } from 'redux-batched-actions';
import { RequestActionCreators } from '@gi/react-requests';
import { generateNotificationID, NotificationTypes, NotificationActionCreators } from '@gi/notifications';

import { createTransferPlanRequestKey } from './request-key-creators';

const transferPlanRequest = (planID, destinationEmail, copy) => {
  const requestKey = createTransferPlanRequestKey(planID);
  const notificationID = generateNotificationID();
  const notificationTitle = copy ? 'Copying Plan' : 'Transferring Plan';
  const notificationSuccessTitle = copy ? 'Plan Copied' : 'Plan Transferred';
  const notificationErrorTitle = copy ? 'Copy Plan Failed' : 'Transfer Plan Failed';

  return (dispatch, getState, { services }) => {
    const user = getState().session.user;

    if (user === null) {
      // fail
      dispatch(
        NotificationActionCreators.createDefaultNotification({
          title: notificationErrorTitle,
          icon: 'icon-attention-alt',
          ID: notificationID,
          type: NotificationTypes.ERROR,
          canTimeout: false,
          inProgress: false,
        })
      );
      return new Promise((resolve, reject) => {
        reject(new Error('Unauthorised'));
      }); // TODO - better error
    }

    // Upload plan
    dispatch(
      batchActions([
        RequestActionCreators.requestStart(requestKey),
        NotificationActionCreators.createDefaultNotification({
          title: notificationTitle,
          icon: 'icon-floppy',
          ID: notificationID,
          type: NotificationTypes.INFO,
          inProgress: true,
        }),
      ])
    );

    return services.planService
      .transferPlan(planID, destinationEmail, copy)
      .then(() => {
        dispatch(
          batchActions([
            RequestActionCreators.requestComplete(requestKey),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: {
                title: notificationSuccessTitle,
                icon: 'icon-ok',
                type: NotificationTypes.SUCCESS,
                canTimeout: true,
              },
            }),
          ])
        );
      })
      .catch((e) => {
        dispatch(
          batchActions([
            RequestActionCreators.requestFail(requestKey, e),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: {
                title: notificationErrorTitle,
                icon: 'icon-attention-alt',
                type: NotificationTypes.ERROR,
                canTimeout: false,
                inProgress: false,
              },
            }),
          ])
        );
        throw e;
      });
  };
};

export { transferPlanRequest };
