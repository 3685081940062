import React from 'react';

import styles from './controls-banner.module.css';

interface iProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
}

const ControlsBanner = ({ children, ...props }: iProps): JSX.Element => {
  return (
    <div {...props} className={`${styles.controlsBanner} ${props.className ? props.className : ''}`}>
      {children}
    </div>
  );
};

export default ControlsBanner;
