import { EventBus, HandleNode, HandleType, InspectableClassData, NodeEventActions, PointerDataWithDelta, bindToLifecycle } from '@gi/core-renderer';
import TriangleShapeNode from '../shapes/triangle-shape-node';
import BaseHandleSetNode from './base-handle-set-node';

export enum PolygonHandleSetNodeEvent {
  Start = 'Start',
  Manipulate = 'Manipulate',
  End = 'End',
}

interface iManipulateData {
  points: Vector2[];
}

export type PolygonHandleSetNodeEventActions = NodeEventActions & {
  [PolygonHandleSetNodeEvent.Start]: () => void;
  [PolygonHandleSetNodeEvent.Manipulate]: (data: iManipulateData) => void;
  [PolygonHandleSetNodeEvent.End]: () => void;
};

/**
 * PolygonHandleSetNode
 *  Used for generically-modifyable polygons. Currently just triangles.
 *  This could be expanded in the future for polygons.
 */
class PolygonHandleSetNode extends BaseHandleSetNode {
  type = 'PolygonLikeHandleSetNode';

  eventBus: EventBus<PolygonHandleSetNodeEventActions> = new EventBus(this.eventBus);
  draggingHandle: HandleNode | null = null;

  #vertexCount: number;

  constructor(vertexCount: number, points?: Vector2[]) {
    super();

    this.#vertexCount = vertexCount;

    this.#syncHandles(points ?? []);
  }

  /**
   * Sets the positions of the handles.
   * @param points The positions to move to
   */
  setFromPoints(...points: Vector2[]) {
    if (points.length < this.#vertexCount) {
      throw new Error(`Not enough points supplied to handle set. Expected ${this.#vertexCount}, got ${points.length}`);
    }
    for (let i = 0; i < points.length; i++) {
      const handle = this.handleList[i];
      if (handle) {
        handle.setPosition(points[i]);
      }
    }
    this.#updateOutline();
  }

  /**
   * Returns the points of all the handles in order, forming a polygon
   * @returns The points of the polygon
   */
  getPoints() {
    return this.handleList.map((handle) => handle.getPosition());
  }

  /**
   * Emits a `start` event
   */
  emitStart() {
    this.eventBus.emit(PolygonHandleSetNodeEvent.Start);
  }

  /**
   * Emits an `end` event
   */
  emitEnd() {
    this.eventBus.emit(PolygonHandleSetNodeEvent.End);
  }

  #startManipulate(target: HandleNode) {
    this.draggingHandle = target;
    this.emitStart();
  }

  #onManipulate(...points: Vector2[]) {
    this.eventBus.emit(PolygonHandleSetNodeEvent.Manipulate, {
      points,
    });
  }

  #endManipulate() {
    this.draggingHandle = null;
    this.emitEnd();
  }

  #onDragHandle(target: HandleNode, data: PointerDataWithDelta) {
    this.#updateHandlePos(target, data.worldPosition);
    this.#onManipulate(...this.getPoints());
  }

  #updateHandlePos(handle: HandleNode, position: Vector2) {
    handle.setPosition(position);
    this.#updateOutline();
  }

  #syncHandles(points: Vector2[]) {
    for (let i = 0; i < this.#vertexCount; i++) {
      let handle = this.handles[i];

      if (!handle || handle.destroyed) {
        handle = new HandleNode(`Vertex${i}`, HandleType.SQUARE);
        handle.onDragStart = () => this.#startManipulate(handle);
        handle.onDrag = (data) => this.#onDragHandle(handle, data);
        handle.onDragEnd = () => this.#endManipulate();

        this.addHandles(handle);
      }

      handle.index = i;
      if (points[i]) {
        handle.setPosition(points[i]);
      }
    }

    const extras = this.handleList.splice(this.#vertexCount);
    extras.forEach((extra) => extra.destroy());

    this.#updateOutline();
  }

  #updateOutline() {
    this.shape.state.values.points = this.getPoints();
  }

  /**
   * Attaches this handleSet to the given triangle shape.
   * This attachment cannot be undone without destroying the handleSet.
   * @param triangle The triangle to attach to
   */
  attachToTriangle(triangle: TriangleShapeNode) {
    this.targets = [triangle];
    bindToLifecycle(this, () => {
      const watcher = triangle.state.addWatcher(
        (state) => {
          if (this.draggingHandle === null) {
            this.setFromPoints(state.values.point1, state.values.point2, state.values.point3);
          }
        },
        { properties: ['point1', 'point2', 'point3'] }
      );
      return () => watcher.destroy();
    });
  }

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default PolygonHandleSetNode;
