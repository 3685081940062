import React, { useMemo } from 'react';

import { Orientation } from '../../types';

import './print-layout-preview.scss';

const getRow = (count: number, paperSize: Dimensions, gap: number): JSX.Element[] => {
  const elements: JSX.Element[] = [];

  const style = {
    width: paperSize.width,
    height: paperSize.height,
    minWidth: paperSize.width,
    minHeight: paperSize.height,
    maxWidth: paperSize.width,
    maxHeight: paperSize.height,
  };

  for (let i = 0; i < count; i++) {
    elements.push(
      <div className='page' key={i} style={{ width: `calc(${1 / count} * (100% - ${gap * (count - 1)}px))` }}>
        <div className='page-inner' style={style} />
      </div>
    );
  }

  return elements;
};

interface iProps {
  pagesAcross: number;
  pagesDown: number;
  paperSize: Dimensions;
  orientation: Orientation;
  layoutDimensions?: Dimensions;
  layoutPadding?: number;
  layoutPaperGap?: number;
}

const PrintLayoutPreview = ({
  pagesAcross,
  pagesDown,
  paperSize,
  orientation,
  layoutDimensions = { width: 100, height: 100 },
  layoutPadding = 3,
  layoutPaperGap = 3,
}: iProps): JSX.Element => {
  const orientedPaperWidth = orientation === 'landscape' ? paperSize.height : paperSize.width;
  const orientedPaperHeight = orientation === 'landscape' ? paperSize.width : paperSize.height;

  const orientedPagesAcross = pagesAcross;
  const orientedPagesDown = pagesDown;

  const aspectRatio = useMemo(() => {
    return orientedPaperWidth / orientedPaperHeight;
  }, [orientedPaperWidth, orientedPaperHeight]);

  // Maximum dimensions that the layout paper can be
  const paperBounds = {
    width: (layoutDimensions.width - (layoutPadding * 2 + (layoutPaperGap * orientedPagesAcross - 1))) / orientedPagesAcross,
    height: (layoutDimensions.height - (layoutPadding * 2 + (layoutPaperGap * orientedPagesDown - 1))) / orientedPagesDown,
  };

  // Actual dimensions of layout paper given the bounds
  const layoutPaperSize = {
    width: Math.min(paperBounds.height * aspectRatio, paperBounds.width),
    height: Math.min(paperBounds.width / aspectRatio, paperBounds.height),
  };

  const elements: JSX.Element[] = [];
  for (let i = 0; i < orientedPagesDown; i++) {
    elements.push(
      <div className='page-row' key={i} style={{ height: `${100 / orientedPagesDown}%`, gap: layoutPaperGap }}>
        {getRow(orientedPagesAcross, layoutPaperSize, layoutPaperGap)}
      </div>
    );
  }

  return (
    <div className='page-layout-container'>
      <div className='page-layout-title'>Print Layout</div>
      <div className='page-layout-body'>
        <div className='page-layout' style={{ gap: layoutPaperGap, padding: layoutPadding }}>
          {elements}
        </div>
      </div>
      <div className='page-layout-count'>
        {orientedPagesAcross} &times; {orientedPagesDown}
      </div>
    </div>
  );
};

export default PrintLayoutPreview;
