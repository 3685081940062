import React from 'react';
import type { RouteObject } from 'react-router-dom';
import Journal from '@gi/app-journal';
import { Application, ApplicationLinks } from '@gi/garden-platform-navigation';

const appJournalRoutes: RouteObject = {
  path: ApplicationLinks[Application.Journal],
  element: <Journal />,
};

export default appJournalRoutes;
