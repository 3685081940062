import React from 'react';

import { Journal } from '@gi/app-journal-desktop';
import { DefaultMobileTopbar } from '@gi/mobile-garden-platform-nav';

import './mobile-journal.scss';

const MobileJournal = (): JSX.Element => {
  return (
    <>
      <DefaultMobileTopbar title='Garden Journal' />
      <div className='mobile-journal'>
        <Journal />
      </div>
    </>
  );
};

export default MobileJournal;
