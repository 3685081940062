import { useCallback, useContext, useMemo } from 'react';

import {
  GlobalPlantListContext,
  DISPLAY_TYPE,
  PlantListAreaGroupFiltersType,
  PlantListSummaryGroupFiltersType,
  PlantListVarietyGroupFiltersType,
  PlantListAreaGroup,
  PlantListSummaryGroup,
  PlantListVarietyGroup,
} from '@gi/plant-list';
import { FiltersUpdate, updateFilters } from '@gi/filters';

type FiltersTarget = PlantListAreaGroup | PlantListVarietyGroup | PlantListSummaryGroup;
type FiltersInputs = PlantListAreaGroupFiltersType | PlantListVarietyGroupFiltersType | PlantListSummaryGroupFiltersType;

/** Utility hook to help with updating all plant filters in a single call */
const usePlantListFilters = () => {
  const { setAreaGroupFilters, setPlantGroupFilters, setVarietyGroupFilters, areaGroupFilters, plantGroupFilters, varietyGroupFilters, displayType } =
    useContext(GlobalPlantListContext);

  const updateAllFilters = useCallback(
    (value: FiltersUpdate<FiltersTarget, FiltersInputs>) => {
      setAreaGroupFilters(updateFilters(areaGroupFilters, value));
      setPlantGroupFilters(updateFilters(plantGroupFilters, value));
      setVarietyGroupFilters(updateFilters(varietyGroupFilters, value));
    },
    [setAreaGroupFilters, setPlantGroupFilters, setVarietyGroupFilters, areaGroupFilters, plantGroupFilters, varietyGroupFilters]
  );

  const activeFilters = useMemo(() => {
    switch (displayType) {
      case DISPLAY_TYPE.Area:
        return areaGroupFilters;
      case DISPLAY_TYPE.Summary:
        return plantGroupFilters;
      case DISPLAY_TYPE.Variety:
      default:
        return varietyGroupFilters;
    }
  }, [displayType, areaGroupFilters, plantGroupFilters, varietyGroupFilters]);

  return useMemo(
    () => ({
      updateAllFilters,
      activeFilters,
    }),
    [updateAllFilters, activeFilters]
  );
};

export default usePlantListFilters;
