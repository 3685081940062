import React, { Ref, useRef, useState } from 'react';
import { useOnClickOutside } from '@gi/react-utils';
import { GardenObjectShoppingEntry, PlantShoppingEntry, ShoppingListProduct } from '@gi/products';

import ProductsSelector from './products-selector';

import styles from './products-selector.module.css';
import ProductSelectorButton from './product-selector-button';

interface iProps {
  currency: string;
  product: ShoppingListProduct;
  entry: PlantShoppingEntry | GardenObjectShoppingEntry;
  setEntry: (entry: PlantShoppingEntry | GardenObjectShoppingEntry) => void;
  productOptions: ShoppingListProduct[];
}

const ProductSelectorContent = ({ currency, product, entry, setEntry, productOptions }: iProps): JSX.Element => {
  const [productSelectorOpen, setProductSelectorOpen] = useState(false);
  const buttonRef = useRef<HTMLElement>(null);
  const windowRef = useRef<HTMLElement>(null);

  useOnClickOutside<HTMLElement>([buttonRef, windowRef], () => setProductSelectorOpen(false));

  const optionsCount = productOptions.length - 1;
  let optionsText: null | JSX.Element;

  if (optionsCount === 0) {
    optionsText = null;
  } else if (optionsCount === 1) {
    optionsText = (
      <div className={styles.listItemOtherProductDetails}>
        <span className={styles.optionsDetails}>1 Other Matched Product</span>
      </div>
    );
  } else {
    optionsText = (
      <div className={styles.listItemOtherProductDetails}>
        <span className={styles.optionsDetails}>{`${optionsCount} Other Matched Products`}</span>
      </div>
    );
  }

  let icon: JSX.Element | null = null;
  if (productOptions.length > 1) {
    icon = productSelectorOpen ? <i className={`icon-down-dir ${styles.selectorIndicator}`} /> : <i className={`icon-right-dir ${styles.selectorIndicator}`} />;
  }

  return (
    <>
      <ProductSelectorButton
        ref={buttonRef as Ref<HTMLButtonElement>}
        productOptions={productOptions}
        productSelectorOpen={productSelectorOpen}
        setProductSelectorOpen={setProductSelectorOpen}
      >
        <div className={styles.listItemProductTitles}>
          <div className={styles.listItemProductTitle}>
            {icon} {product.title}
          </div>
          <div className={styles.listItemProductSubtitle}>
            <div className={styles.productTags}>
              {product.tags.map((tag) => (
                <div className={styles.productTag} key={tag}>
                  {tag}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.listItemProductOptions}>
          <span>{optionsText}</span>
        </div>
      </ProductSelectorButton>
      {productSelectorOpen ? (
        <ProductsSelector
          ref={windowRef}
          currency={currency}
          productOptions={productOptions}
          target={buttonRef.current!}
          onSelect={(productId: number, variantId: number) => {
            setEntry({
              ...entry,
              productId,
              variantId,
            });
            setProductSelectorOpen(false);
          }}
        />
      ) : null}
    </>
  );
};

export default ProductSelectorContent;
