import React, { ReactNode } from 'react';

import styles from './menu-dropdown.module.css';

interface iProps {
  children?: ReactNode;
}

const MenuDropdownSectionTitle = ({ children }: iProps): JSX.Element => {
  return <span className={styles.sectionTitle}>{children}</span>;
};

export default MenuDropdownSectionTitle;
