import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ForecastDisplay, ForecastNetworkActionCreators, ForecastSelectors, LOAD_WEATHER_REQUEST_ID } from '@gi/forecast';
import LoadingButton from '@gi/loading-button';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { RequestSelectors } from '@gi/react-requests';
import { SessionSelectors } from '@gi/react-session';

import DashboardItem from '../../dashboard-item/dashboard-item';

const emptyForecast = [null, null, null, null, null, null, null];

const WeatherDashboardItem = (): JSX.Element => {
  const dispatch = useDispatch();
  const forecast = useSelector(ForecastSelectors.getForecast);
  const failed = useSelector(ForecastSelectors.getForecastLoadFailed);
  const requests = useSelector(RequestSelectors.getRequests);
  const user = useSelector(SessionSelectors.getUser);

  const metric = user ? user.settings.units.metricTemperatureUnits : true;

  const loading = RequestsUtils.getStatus(requests, LOAD_WEATHER_REQUEST_ID) === RequestStatus.IN_PROGRESS;

  useEffect(() => {
    dispatch(ForecastNetworkActionCreators.loadForecast());
  }, []);

  return (
    <DashboardItem title='Weather'>
      {failed ? (
        <div className='dashboard-block col-3 failed-forecast-display'>
          Failed to load weather information{' '}
          <LoadingButton loading={loading} onClick={() => dispatch(ForecastNetworkActionCreators.loadForecast())}>
            Retry
          </LoadingButton>
        </div>
      ) : (
        <ForecastDisplay metric={metric} forecasts={forecast.length === 0 ? emptyForecast : forecast} />
      )}
    </DashboardItem>
  );
};

export default WeatherDashboardItem;
