import React, { useMemo } from 'react';

import ProgressObjectiveIcon from '../icon/progress-objective-icon';

import awardSVG from '../../assets/icons/award.svg';

import styles from './overview-styles.module.css';

interface iProps {
  title?: string;
  description?: string;
  objectiveCount?: number;
  completedObjectiveCount?: number;
  minimised?: boolean;
}

const ObjectivesOverallProgress = ({ title, description, objectiveCount, completedObjectiveCount, minimised = false }: iProps): JSX.Element => {
  const percent = useMemo(() => {
    if (objectiveCount !== undefined && completedObjectiveCount !== undefined) {
      return completedObjectiveCount / objectiveCount;
    }
    return 0;
  }, [objectiveCount, completedObjectiveCount]);

  const label = useMemo(() => {
    if (objectiveCount !== undefined && completedObjectiveCount !== undefined) {
      return `${completedObjectiveCount}/${objectiveCount}`;
    }
    return undefined;
  }, [objectiveCount, completedObjectiveCount]);

  return (
    <div className={`${styles.overviewCard} ${styles.overallProgressCard}`}>
      <div className={styles.overviewCardIcon}>
        <ProgressObjectiveIcon src={awardSVG} percent={percent} label={label} />
      </div>
      <div className={styles.overviewCardContent} hidden={minimised}>
        {title ? <p className={styles.overviewCardTitle}>{title}</p> : null}
        {description ? <p className={styles.overviewCardDescription}>{description}</p> : null}
      </div>
    </div>
  );
};

export default ObjectivesOverallProgress;
