import Plant from '@gi/plant';
import React from 'react';

interface iOtherNamesProps {
  plant: Plant;
}

const PlantOtherNamesSection = ({ plant }: iOtherNamesProps): JSX.Element | null => {
  if (plant.otherName === '') {
    return null;
  }

  return (
    <tr className='plan-table-row'>
      <th className='plan-table-row-title'>Other names</th>
      <td>{plant.otherName}</td>
    </tr>
  );
};

export default PlantOtherNamesSection;
