import { Graphics } from 'pixi.js-new';

import {
  AssetComponent,
  AssetComponentEvents,
  AssetType,
  DraggableComponent,
  HoverableComponent,
  InspectableClassData,
  InteractableComponent,
  NodeEvent,
  OutlineComponent,
  RepeatingSpriteComponent,
  SelectableComponent,
  ShapeComponent,
  TooltipComponent,
  ManipulatableComponent,
  VisibilityComponent,
  ShapeFlag,
  DoubleClickableComponent,
  LongPressableComponent,
  HiddenFlag,
  ShapeCollisionCheckFunctions,
  RightClickableComponent,
} from '@gi/core-renderer';
import plantFamilies from '@gi/plant-families';
import Plant from '@gi/plant';
import Bitmask from '@gi/bitmask';
import { metricDistanceUnits } from '@gi/units';
import { StateDef } from '@gi/state';
import { LayerTypes } from '@gi/constants';

import { PlantCount } from '../../../simulation/plant-utils';
import { getPlantCountText } from './tooltip-utils';
import { drawRoots } from './draw-utils';
import SettingsContext, { SettingsContextState } from '../settings-context';
import PlanSettingsContext, { PlanSettingsContextState } from '../plan-settings-context';
import { CachedPlantOutline } from '../outline-utils';
import GardenItemNode, { GardenItemNodeState } from '../garden-item-node';

const ROOT_OPACITY = 0.5;

type PlantNodeState = StateDef<
  {
    rowStart: Vector2;
    rowEnd: Vector2;
    width: number;
    height: number;
    rotation: number;
    spacing: number;
    inRowSpacing: number;
    rowSpacing: number;
    plantCount: PlantCount;
    variety: string;
    visible: boolean;
  } & GardenItemNodeState,
  [],
  {
    settings: SettingsContextState;
    planSettings: PlanSettingsContextState;
  }
>;

class PlantNode extends GardenItemNode<PlantNodeState> {
  type = 'PlantNode';

  readonly plant: Plant;
  readonly sfg: false;

  #rootBackground: Graphics | null = null;
  #spriteHitGraphic: Graphics | null = null;

  #sprite: RepeatingSpriteComponent;
  #tooltip: TooltipComponent;
  #outline: OutlineComponent;
  #shape: ShapeComponent;
  #settings: SettingsContext | null = null;
  #planSettings: PlanSettingsContext | null = null;
  visibility: VisibilityComponent;

  #cachedOutline?: CachedPlantOutline;

  constructor(id: number, plant: Plant, initialState: PlantNodeState['state']) {
    super(id, initialState, LayerTypes.PLANTS);

    this.name = `${id} - ${plant.name} - ${plant.code}`;
    this.plant = plant;

    this.transform.state.values.position = this.state.values.rowStart;
    this.state.addUpdater(
      (state) => {
        this.transform.state.values.position = state.values.rowStart;
        this.transform.state.values.rotation = state.values.rotation;
      },
      { properties: ['rowStart', 'rowEnd'] }
    );

    this.#sprite = this.components.add(
      new RepeatingSpriteComponent({
        spriteWidth: this.plant.sprite.width,
        spriteHeight: this.plant.sprite.height,
        width: this.state.values.width,
        height: this.state.values.height,
        spritesDown: this.state.values.plantCount.y,
        spritesAcross: this.state.values.plantCount.x,
        spriteRotation: -this.state.values.rotation,
      })
    );

    const textureComponent = this.components.add(new AssetComponent(AssetType.TEXTURE, plant.sprite.name));
    textureComponent.eventBus.on(AssetComponentEvents.Loaded, (texture) => {
      this.#sprite.texture = texture;
    });

    this.components.add(new InteractableComponent());
    this.components.add(new HoverableComponent());
    this.components.add(new SelectableComponent());
    this.components.add(new ManipulatableComponent());
    this.components.add(new DraggableComponent());
    this.components.add(new DoubleClickableComponent());
    this.components.add(new RightClickableComponent());
    this.components.add(new LongPressableComponent());
    this.visibility = this.components.add(new VisibilityComponent());
    this.#tooltip = this.components.add(new TooltipComponent({ text: plant.name }));
    this.#shape = this.components.add(new ShapeComponent({ flags: Bitmask.Create(ShapeFlag.CULLABLE, ShapeFlag.PLANT) }));
    this.#outline = this.components.add(new OutlineComponent());

    // Update sprite positions + count
    this.state.addUpdater(
      (state) => {
        this.#sprite.state.values.width = state.values.width;
        this.#sprite.state.values.height = state.values.height;
        this.#sprite.state.values.spritesDown = state.values.plantCount.y;
        this.#sprite.state.values.spritesAcross = state.values.plantCount.x;
        this.#sprite.state.values.spriteRotation = -state.values.rotation;
      },
      { properties: ['width', 'height', 'plantCount', 'rotation'] }
    );

    // Update sprite display settings from settings context
    this.state.addUpdater(
      (state) => {
        if (state.otherStates.settings) {
          this.#sprite.state.values.maximumSprites = state.get('settings', 'plantSpriteCountLimit')!;
          this.#sprite.state.values.spriteDisplayMode = state.get('settings', 'plantDisplayMode')!;
        }
      },
      { otherStates: { settings: { properties: ['plantSpriteCountLimit', 'plantDisplayMode'] } } }
    );

    // Update the tooltip position when height changes to not cover the middle of the plant
    this.state.addUpdater(
      (state) => {
        // TODO: Copied from old renderer. Maybe instead take plant size into consideration for placement
        this.#tooltip.state.values.offset = { x: 0, y: state.values.height === 0 ? -40 : 0 };
      },
      { properties: ['height'] }
    );

    // Redraw the roots whenever something dependent changes
    this.state.addWatcher(
      () => {
        this.#updateRoots();
      },
      {
        properties: ['width', 'height', 'spacing', 'rowSpacing', 'inRowSpacing'],
        otherStates: { settings: { properties: ['showPlantRoots'] } },
      },
      false
    );

    // Update the hit graphic whenever we manipulate/rotate
    this.state.addWatcher(
      () => {
        this.#updateHitGraphic();
      },
      {
        properties: ['width', 'height', 'rotation'],
      },
      false
    );

    // Update the tooltip whenever dependent text properties change
    this.state.addWatcher(
      () => {
        this.#updateTooltip();
      },
      {
        properties: ['width', 'height', 'spacing', 'rowSpacing', 'inRowSpacing', 'plantCount', 'variety'],
        otherStates: { planSettings: { properties: ['metric'] } },
      }
    );

    // Update own visibility based on flags
    this.state.addWatcher(
      (state) => {
        this.visibility.setHiddenFlag(HiddenFlag.VISIBILITY, !state.values.visible);
      },
      { properties: ['visible'] }
    );

    this.#shape.collisionCheckFunction = ShapeCollisionCheckFunctions.ConvexHull;

    this.eventBus.on(NodeEvent.DidBind, this.#didBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#beforeUnbind);
  }

  #didBind = () => {
    this.#setupSettingsWatchers();

    this.#spriteHitGraphic = new Graphics();
    this.ownGraphics.addChild(this.#spriteHitGraphic);
    this.#updateHitGraphic();
    this.#rootBackground = new Graphics();
    this.ownGraphics.addChild(this.#rootBackground);
    this.#updateRoots();
    this.ownGraphics.addChild(this.#sprite.getContainer());

    this.#updateShape();
  };

  #setupSettingsWatchers = () => {
    const settings = this.getContext(SettingsContext);
    this.#planSettings = this.getContext(PlanSettingsContext);
    this.state.connectState('settings', settings.state);
    this.state.connectState('planSettings', this.#planSettings.state);
  };

  #beforeUnbind = () => {
    this.#settings = null;
    this.#spriteHitGraphic?.destroy();
    this.#spriteHitGraphic = null;
    this.#rootBackground?.destroy();
    this.#rootBackground = null;
  };

  /**
   * Sets the positions of this plant, altering its size.
   * @param rowStart The start position of the plant/row/block
   * @param rowEnd The end position of the plant/row/block
   * @param width The width of the row/block
   * @param height The height of the block
   * @param rotation The rotation of the plant
   */
  setPositions(rowStart: Vector2, rowEnd: Vector2, width: number, height: number, rotation: number) {
    this.state.values.rowStart = { ...rowStart };
    this.state.values.rowEnd = { ...rowEnd };
    this.state.values.width = width;
    this.state.values.height = height;
    this.state.values.rotation = rotation;
    this.#updateShape();
  }

  /**
   * Sets the different spacings of this plant.
   * @param spacing The spacing of a single plant
   * @param inRowSpacing The spacing of plants when in a row
   * @param rowSpacing The spacing of idividual rows of plants
   */
  setSpacings(spacing: number, inRowSpacing: number, rowSpacing: number) {
    this.state.values.spacing = spacing;
    this.state.values.inRowSpacing = inRowSpacing;
    this.state.values.rowSpacing = rowSpacing;
    this.#updateShape();
  }

  /**
   * Sets the amount of plants in both x and y (and total)
   * @param plantCount The counts of the plant
   */
  setPlantCount(plantCount: PlantCount) {
    this.state.values.plantCount = { ...plantCount };
  }

  /**
   * Sets the variety of the plant
   * @param variety The variety of the plant
   */
  setVariety(variety: string) {
    this.state.values.variety = variety;
  }

  /**
   * Draws an invisible hit graphic behind the sprites, as sprites have hit checking turned off.
   */
  #updateHitGraphic() {
    if (!this.#spriteHitGraphic) {
      return;
    }

    const { width, height, rotation } = this.state.values;
    const { width: spriteWidth, height: spriteHeight } = this.plant.sprite;

    const absCosR = Math.abs(Math.cos(rotation));
    const absSinR = Math.abs(Math.sin(rotation));

    // Attempt to draw the minimum box around the sprites by accounting for rotation and differing sprite w/h
    const x = absCosR * (spriteWidth / 2) + absSinR * (spriteHeight / 2);
    const y = absSinR * (spriteWidth / 2) + absCosR * (spriteHeight / 2);

    this.#spriteHitGraphic
      .clear()
      .beginFill(0xffffff, 0.00001)
      .drawRoundedRect(-x, -y, width + x * 2, height + y * 2, Math.min(x, y))
      .endFill();
  }

  /**
   * Draws the roots to the roots background graphic
   */
  #updateRoots() {
    if (!this.#rootBackground) {
      return;
    }

    const { width, height, spacing, rowSpacing, inRowSpacing } = this.state.values;
    const shouldDrawRoots = this.state.get('settings', 'showPlantRoots', true);

    if (shouldDrawRoots) {
      this.#rootBackground.visible = true;
      drawRoots({
        graphics: this.#rootBackground,
        width,
        height,
        spacing,
        rowSpacing,
        inRowSpacing,
        colour: plantFamilies.get(this.plant.familyID)!.color,
        alpha: ROOT_OPACITY,
      });
    } else {
      this.#rootBackground.visible = false;
      this.#rootBackground.clear();
    }
  }

  /**
   * Updates the internal shape of this plant
   */
  #updateShape() {
    const { width, height, spacing, rowSpacing, inRowSpacing } = this.state.values;

    if (!this.#cachedOutline) {
      this.#cachedOutline = new CachedPlantOutline(width, height, spacing, rowSpacing, inRowSpacing);
      this.#shape.setPoints(this.#cachedOutline.path);
    } else if (this.#cachedOutline.update(width, height, spacing, rowSpacing, inRowSpacing)) {
      this.#shape.setPoints(this.#cachedOutline.path);
    }
  }

  /**
   * Updates the tooltip text of this plant.
   */
  #updateTooltip() {
    const { plantCount, width, height, spacing, rowSpacing, inRowSpacing, variety } = this.state.values;

    this.#tooltip.state.values.text = getPlantCountText(
      this.plant.name,
      variety,
      plantCount.x,
      plantCount.y,
      width,
      height,
      this.#planSettings?.getDistanceUnits() ?? metricDistanceUnits,
      { spacing, rowSpacing, inRowSpacing, sfgCount: null }
    );
  }

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default PlantNode;
