import { Container, Graphics } from 'pixi.js-new';

import {
  DoubleClickableComponent,
  DraggableComponent,
  HoverableComponent,
  InteractableComponent,
  LongPressableComponent,
  ManipulatableComponent,
  NodeEvent,
  OutlineComponent,
  RightClickableComponent,
  SelectableComponent,
  ShapeCollisionCheckFunctions,
  ShapeComponent,
  ShapeFlag,
  TextComponent,
  TextComponentState,
  TextContainer,
  VisibilityComponent,
  bindState,
  canvasBackgroundUsageCompatibility,
} from '@gi/core-renderer';
import Bitmask from '@gi/bitmask';
import { Geometry } from '@gi/math';
import { State, StateDef } from '@gi/state';
import { LayerTypes } from '@gi/constants';

import { CachedRectOutline } from '../outline-utils';
import GardenItemNode, { GardenItemNodeState } from '../garden-item-node';
import SettingsContext, { SettingsContextState } from '../settings-context';

const DEFAULT_TEXT_FONT_SCALE = 4;
const PLACEHOLDER_TEXT = 'This textarea is empty. Double click to add text.';

function isEmpty(text: string): boolean {
  return text.trim() === '';
}

type TextNodeState = StateDef<
  {
    start: Vector2;
    end: Vector2;
    rotation: number;
    text: string;
    colour: number;
    fontSize: number;
  } & GardenItemNodeState
>;

type TextNodeInternalState = StateDef<
  {
    width: number;
    height: number;
    isEmpty: boolean;
  },
  [],
  {
    state: TextNodeState;
    settings: SettingsContextState;
  }
>;

class TextNode extends GardenItemNode<TextNodeState> {
  type = 'TextNode';

  readonly shape: ShapeComponent;
  readonly outline: OutlineComponent;
  readonly visibility: VisibilityComponent;

  #backgroundGraphics: Graphics | null = null;
  #textGraphics: Container | null = null;
  #text: TextContainer | null = null;

  #cachedOutline?: CachedRectOutline;
  #textComponent: TextComponent;

  #internalState: State<TextNodeInternalState>;

  get isEmpty() {
    return this.state.values.text.trim() === '';
  }

  constructor(id: number, initialState: TextNodeState['state']) {
    super(id, initialState, LayerTypes.TEXT);

    this.name = this.state.values.text.length > 30 ? `${id} - ${this.state.values.text.substring(0, 30)}...` : `${id} - ${this.state.values.text}`;

    this.#internalState = new State({
      width: Math.abs(initialState.end.x - initialState.start.x),
      height: Math.abs(initialState.end.y - initialState.start.y),
      isEmpty: isEmpty(initialState.text),
    });
    this.#internalState.connectState('state', this.state);
    bindState(this.#internalState, this);

    // Computed values. We can avoid doing expensive re-renders if these don't change (such as when dragging)
    this.#internalState.addUpdater(
      (state) => {
        const start = state.get('state', 'start');
        const end = state.get('state', 'end');
        const text = state.get('state', 'text');
        if (start !== undefined && end !== undefined) {
          state.values.width = Math.abs(end.x - start.x);
          state.values.height = Math.abs(end.y - start.y);
        }
        if (text !== undefined) {
          state.values.isEmpty = isEmpty(text);
        }
      },
      { otherStates: { state: { properties: ['start', 'end', 'text'] } } }
    );

    this.state.addUpdater(
      (state) => {
        this.transform.state.values.position = Geometry.midpoint(state.values.start, state.values.end);
        this.transform.state.values.rotation = state.values.rotation;
      },
      { properties: ['start', 'end', 'rotation'] }
    );

    this.state.addWatcher(
      (state) => {
        this.name = state.values.text.length > 30 ? `${id} - ${state.values.text.substring(0, 30)}...` : `${id} - ${state.values.text}`;
      },
      { properties: ['text'] }
    );

    this.#internalState.addWatcher(
      () => {
        this.#updateBackground();
        this.#updateText();
      },
      { properties: ['width', 'height'], otherStates: { state: { properties: ['text'] } } },
      false
    );

    this.components.add(new InteractableComponent());
    this.components.add(new HoverableComponent());
    this.components.add(new SelectableComponent());
    this.components.add(new ManipulatableComponent());
    this.components.add(new DraggableComponent());
    this.components.add(new DoubleClickableComponent());
    this.components.add(new RightClickableComponent());
    this.components.add(new LongPressableComponent());
    this.shape = this.components.add(new ShapeComponent({ flags: Bitmask.Create(ShapeFlag.CULLABLE) }));
    this.outline = this.components.add(new OutlineComponent({ padding: OutlineComponent.PADDING_SMALL }));
    this.visibility = this.components.add(new VisibilityComponent());

    this.#textComponent = this.components.add(new TextComponent(this.getFontStyle()));

    this.#internalState.addUpdater(
      (state) => {
        const style = state.values.isEmpty ? this.getPlaceholderFontStyle() : this.getFontStyle();
        Object.entries(style).forEach(([key, value]) => {
          this.#textComponent.state.values[key] = value;
        });
      },
      {
        properties: ['isEmpty', 'width', 'height'],
        otherStates: {
          state: { properties: ['colour', 'fontSize'] },
          settings: { properties: ['textQuality'] },
        },
      }
    );

    this.shape.collisionCheckFunction = ShapeCollisionCheckFunctions.ConvexHull;

    this.eventBus.on(NodeEvent.DidBind, this.#didBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#beforeUnbind);
  }

  #didBind = () => {
    this.#backgroundGraphics = new Graphics();
    this.ownGraphics.addChild(this.#backgroundGraphics);

    this.#textGraphics = new Container();
    this.ownGraphics.addChild(this.#textGraphics);

    this.#text = this.#textComponent.createText(isEmpty(this.state.values.text) ? PLACEHOLDER_TEXT : this.state.values.text);
    this.ownGraphics.addChild(this.#text);

    const settings = this.getContext(SettingsContext);
    this.#internalState.connectState('settings', settings.state);

    this.#updateBackground();
    this.#updateText();
  };

  #beforeUnbind = () => {
    this.#backgroundGraphics?.destroy();
    this.#backgroundGraphics = null;
    this.#textGraphics?.destroy();
    this.#textGraphics = null;
    this.#text?.destroy();
    this.#text = null;
  };

  setPosition(center: Vector2, dimensions: Dimensions, rotation: number) {
    this.state.values.start = {
      x: center.x - dimensions.width / 2,
      y: center.y - dimensions.height / 2,
    };
    this.state.values.end = {
      x: center.x + dimensions.width / 2,
      y: center.y + dimensions.height / 2,
    };
    this.state.values.rotation = rotation;
  }

  setText(text: string, colour?: number, fontSize?: number) {
    this.state.values.text = text;
    if (colour !== undefined) {
      this.state.values.colour = colour;
    }
    if (fontSize !== undefined) {
      this.state.values.fontSize = fontSize;
    }
  }

  getFontStyle(): Partial<TextComponentState> {
    const dimensions = this.getDimensions();
    return {
      fontFamily: ['Verdana', 'Helvetica', 'Arial'],
      fontSize: this.state.values.fontSize,
      fontScale: this.#internalState.get('settings', 'textQuality', DEFAULT_TEXT_FONT_SCALE),
      color: this.state.values.colour,
      baseCameraMagnification: 4,
      useMipmaps: false,
      style: {
        fontWeight: '600',
        align: 'left',
        wordWrap: true,
        wordWrapWidth: dimensions.width,
        // Auto line-height seems to differ wildly per-browser, so set it for consistency
        lineHeight: this.state.values.fontSize,
        // Different browsers add different padding around text. This mostly standardises the top-left corner
        // Trim uses an off-screen canvas, which privacy-centric browsers don't like.
        trim: canvasBackgroundUsageCompatibility.supported,
      },
    };
  }

  getPlaceholderFontStyle(): Partial<TextComponentState> {
    const dimensions = this.getDimensions();
    return {
      fontFamily: ['Verdana', 'Helvetica', 'Arial'],
      fontSize: 16,
      fontScale: this.#internalState.get('settings', 'textQuality', DEFAULT_TEXT_FONT_SCALE),
      color: 0x000000,
      baseCameraMagnification: 4,
      useMipmaps: false,
      style: {
        fontWeight: '400',
        align: 'center',
        wordWrap: true,
        wordWrapWidth: dimensions.width,
        // Auto line-height seems to differ wildly per-browser, so set it for consistency
        lineHeight: 16,
        // Different browsers add different padding around text. This mostly standardises the top-left corner
        // Trim uses an off-screen canvas, which privacy-centric browsers don't like.
        trim: canvasBackgroundUsageCompatibility.supported,
      },
    };
  }

  getDimensions(): Dimensions {
    const diff = Geometry.getPointDelta(this.state.values.start, this.state.values.end);
    return {
      width: Math.abs(diff.x),
      height: Math.abs(diff.y),
    };
  }

  getCenter(): Vector2 {
    return Geometry.midpoint(this.state.values.start, this.state.values.end);
  }

  #updateBackground() {
    const dimensions = this.getDimensions();

    if (this.#backgroundGraphics) {
      const colour = this.isEmpty ? [0xf8f8f8, 1] : [0xffffff, 0.0001];
      this.#backgroundGraphics
        .clear()
        .beginFill(colour[0], colour[1])
        .drawRect(-dimensions.width / 2, -dimensions.height / 2, dimensions.width, dimensions.height)
        .endFill();
    }

    this.#updateShape();
  }

  #updateText() {
    if (!this.#text) {
      return;
    }

    const dimensions = this.getDimensions();

    if (this.isEmpty) {
      this.#text.text = PLACEHOLDER_TEXT;
      this.#text.anchor = { x: 0.5, y: 0.5 };
      this.#text.position = { x: 0, y: 0 };
      this.#text.scale = { x: 1, y: 1 };
      // Scale down the text if it's going to overflow to try and make textboxes look slightly less gross when drawing.
      // Remove this if masks are ever re-added.
      if (this.#text.textWidth > dimensions.width || this.#text.textHeight > dimensions.height) {
        const scaleFactor = Math.min(dimensions.width / this.#text.textWidth, dimensions.height / this.#text.textHeight);
        this.#text.scale.set(scaleFactor);
      }
    } else {
      this.#text.text = this.state.values.text;
      this.#text.anchor = { x: 0, y: 0 };
      this.#text.position = { x: -dimensions.width / 2, y: -dimensions.height / 2 };
      this.#text.scale = { x: 1, y: 1 };
    }
  }

  #updateShape() {
    const { width, height } = this.getDimensions();

    if (!this.#cachedOutline) {
      this.#cachedOutline = new CachedRectOutline(width, height);
      this.shape.setPoints(this.#cachedOutline.path);
    } else if (this.#cachedOutline.update(width, height)) {
      this.shape.setPoints(this.#cachedOutline.path);
    }
  }
}

export default TextNode;
