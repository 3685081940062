import { useEffect, useState } from 'react';

import { LoadingState } from '@gi/constants';
import { AsyncOperation } from '@gi/utils';

import { InputAsyncImage, InputImage, InputRemoteImage, isAsyncImageDef, isImageDef } from '../types';

interface UseImageProps {
  image: InputImage | InputRemoteImage | InputAsyncImage;
}

/**
 * Utility hook to load an image asynchronously to be able to get properties about the image.
 */
const useImage = ({ image }: UseImageProps): AsyncOperation<HTMLImageElement> => {
  const [state, setState] = useState<AsyncOperation<HTMLImageElement>>({ status: LoadingState.NONE });

  useEffect(() => {
    let cancelled: boolean = false;
    setState({ status: LoadingState.LOADING });

    const handleImage = (_image: InputImage | InputRemoteImage) => {
      const imageElement = new Image();
      const src = isImageDef(_image) ? _image.image.src : _image.imageSrc;

      imageElement.onload = () => {
        if (cancelled) {
          return;
        }
        setState({ status: LoadingState.SUCCESS, value: imageElement });
      };

      imageElement.onerror = () => {
        if (cancelled) {
          return;
        }
        setState({ status: LoadingState.ERROR, error: new Error(`Failed to load image at ${src}`) });
      };

      imageElement.src = src;
    };

    if (isAsyncImageDef(image)) {
      image.objectURL
        .getObjectURL()
        .then((_image) => {
          if (!cancelled) {
            handleImage({ imageSrc: _image });
          }
        })
        .catch(() => {
          setState({ status: LoadingState.ERROR, error: new Error(`Failed to load async image`) });
        });
    } else {
      handleImage(image);
    }

    return () => {
      cancelled = true;
      if (isAsyncImageDef(image)) {
        image.objectURL.revokeObjectURL();
      }
    };
  }, [image]);

  return state;
};

export default useImage;
