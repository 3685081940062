import { batchActions } from 'redux-batched-actions';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk/src';

import { RequestActionCreators } from '@gi/react-requests';
import { generateNotificationID, NotificationTypes, NotificationActionCreators } from '@gi/notifications';
import Plan, {
  PlanSetUtils,
  PlanUtils,
  PlanParserUtils,
  PlanDataError,
  FollowOnPlanOptions,
  PlantNotesUtils,
  PlanClockwiseRotationAmount,
  PlanTransformUtils,
} from '@gi/plan';
import { Anchor, CropRotationModes, GardenItemType, LayerDisplayModes, UnitType, UnitTypes } from '@gi/constants';
import { createLogMessage } from '@gi/js-log';
import type { ThunkExtraArgs } from '@gi/garden-platform-services';
import type { SessionReducerState } from '@gi/react-session';
import type Collection from '@gi/collection';
import type GardenObject from '@gi/garden-object';
import type Plant from '@gi/plant';
import type { User } from '@gi/user';

import CanvasActionTypes from './canvas-action-types';
import { CanvasReducerState, TexturesAsyncOperation, iPlanDataError } from './canvas-reducer';
import { OpenPlanActionType, ResizeHistoricalPlansResult, RetryPlanUploadResult, SavePlanType } from './canvas-action-creator-types';

interface BaseStoreState {
  canvas: CanvasReducerState;
  session: SessionReducerState;
}

export const editItem = ({ itemID, itemType, planID }: { itemID: number; itemType: GardenItemType; planID: number }) => {
  return {
    type: CanvasActionTypes.EDIT_ITEM,
    itemID,
    itemType,
    planID,
  };
};

export const showContextMenu = ({ worldPosition }: { worldPosition: Vector2 }) => {
  return {
    type: CanvasActionTypes.SHOW_CONTEXT_MENU,
    worldPosition,
  };
};

export const hideContextMenu = () => {
  return {
    type: CanvasActionTypes.HIDE_CONTEXT_MENU,
  };
};

const setActivePlan = (planID: number) => {
  return {
    type: CanvasActionTypes.SET_ACTIVE_PLAN,
    planID,
  };
};

const updatePlan = (plan: Plan, skipNewUndoStack: boolean = false) => {
  return {
    type: CanvasActionTypes.UPDATE_PLAN,
    plan,
    skipNewUndoStack,
  };
};

const addPlan = (plan: Plan) => {
  return {
    type: CanvasActionTypes.ADD_PLAN,
    plan,
  };
};

const openPlan = (planID: number) => {
  return (dispatch) => {
    const action: OpenPlanActionType = { type: CanvasActionTypes.OPEN_PLAN, planID };
    dispatch(batchActions([action, setActivePlan(planID)]));
  };
};

/**
 * Adds a plan to the list of plans requiring close confirmation
 */
export const addToCloseConfirmation = (planID: number) => {
  return {
    type: CanvasActionTypes.ADD_TO_CLOSE_CONFIRMATION,
    planID,
  };
};

/**
 * Removes a plan from the list of plans requiring close confirmation
 */
export const removeFromCloseConfirmation = (planID: number) => {
  return {
    type: CanvasActionTypes.REMOVE_FROM_CLOSE_CONFIRMATION,
    planID,
  };
};

const closePlan = (planID: number) => {
  return {
    type: CanvasActionTypes.CLOSE_PLAN,
    planID,
  };
};

const plansLoadStart = (planIDs: number[]) => {
  return {
    type: CanvasActionTypes.PLANS_LOAD_START,
    planIDs,
  };
};

const planLoadEnd = (planID: number) => {
  return {
    type: CanvasActionTypes.PLAN_LOAD_END,
    planID,
  };
};

const planLoadFailed = (planID: number, err: Error) => {
  return {
    type: CanvasActionTypes.PLAN_LOAD_FAILED,
    planID,
    err,
  };
};

const removePlans = (planIDs: number[]) => {
  return {
    type: CanvasActionTypes.REMOVE_PLANS,
    planIDs,
  };
};

const setGridVisibility = (plan: Plan, showGrid: boolean) => {
  const updatedPlan = {
    ...plan,
    plannerSettings: {
      ...plan.plannerSettings,
      showGrid,
    },
  };
  return updatePlan(updatedPlan);
};

const setRulerVisibility = (plan: Plan, showRulers: boolean) => {
  const updatedPlan = {
    ...plan,
    plannerSettings: {
      ...plan.plannerSettings,
      showRulers,
    },
  };

  return updatePlan(updatedPlan);
};

const setBackgroundImageSettings = (plan: Plan, showBackgroundImages: boolean, backgroundImageOpacity: number, maintainBackgroundImageAspectRatio: boolean) => {
  const updatedPlan: Plan = {
    ...plan,
    plannerSettings: {
      ...plan.plannerSettings,
      showBackgroundImages,
      backgroundImageOpacity,
      maintainBackgroundImageAspectRatio,
    },
  };
  return updatePlan(updatedPlan);
};

const setBackgroundImageVisibility = (plan: Plan, showBackgroundImages: boolean) => {
  const updatedPlan: Plan = {
    ...plan,
    plannerSettings: {
      ...plan.plannerSettings,
      showBackgroundImages,
    },
  };
  return updatePlan(updatedPlan);
};

const setBackgroundImageOpacity = (plan: Plan, backgroundImageOpacity: number) => {
  const updatedPlan: Plan = {
    ...plan,
    plannerSettings: {
      ...plan.plannerSettings,
      backgroundImageOpacity,
    },
  };
  return updatePlan(updatedPlan);
};

const setBackgroundImageMaintainAspectRatio = (plan: Plan, maintainBackgroundImageAspectRatio: boolean) => {
  const updatedPlan: Plan = {
    ...plan,
    plannerSettings: {
      ...plan.plannerSettings,
      maintainBackgroundImageAspectRatio,
    },
  };
  return updatePlan(updatedPlan);
};

const undo = () => {
  return {
    type: CanvasActionTypes.UNDO,
  };
};

const redo = () => {
  return {
    type: CanvasActionTypes.REDO,
  };
};

const clearUndoStack = (planId: number) => {
  return {
    type: CanvasActionTypes.CLEAR_UNDO_STACK,
    planId,
  };
};

const createPlanSuccess = (plan: Plan) => {
  return {
    type: CanvasActionTypes.CREATE_PLAN_SUCCESS,
    plan,
  };
};

const savePlanSuccess = (plan: Plan, responsePlan: Plan) => {
  return {
    type: CanvasActionTypes.SAVE_PLAN_SUCCESS,
    plan,
    responsePlan,
  };
};

const deletePlanSuccess = (planID: number) => {
  return {
    type: CanvasActionTypes.DELETE_PLAN_SUCCESS,
    planID,
  };
};

const noChangesToSave = () => {
  return NotificationActionCreators.createNotification({
    title: 'All changes already saved',
    type: NotificationTypes.INFO,
    canTimeout: true,
    icon: 'icon-ok',
  });
};

/**
 * Call when a plan data issue happens. Will show a modal for the user.
 * @param  failedPlanSave Data about the failed plan save
 * @returns An event
 */
export const onPlanDataErrorFound = (failedPlanSave: iPlanDataError) => {
  return {
    type: CanvasActionTypes.PLAN_DATA_ERROR,
    failedPlanSave,
  };
};

/**
 * Clears the list of plan data errors
 */
export const clearPlanDataErrors = () => {
  return { type: CanvasActionTypes.CLEAR_PLAN_DATA_ERRORS };
};

const SavePlanNotificationTitles = {
  [SavePlanType.SAVE]: {
    default: 'Saving Plan',
    success: 'Plan Saved',
    error: 'Error Saving Plan',
  },
  [SavePlanType.AUTOSAVE]: {
    default: 'Autosaving Plan',
    success: 'Autosave Successful',
    error: 'Autosave Failed',
  },
  [SavePlanType.UPDATE_HISTORIC]: {
    default: 'Updating Historic Plan',
    success: 'Historic Plan Updated',
    error: 'Error Updating Historic Plan',
  },
};

const savePlan = (plan, saveType: SavePlanType = SavePlanType.SAVE): ThunkAction<Promise<Plan | null>, BaseStoreState, ThunkExtraArgs, AnyAction> => {
  return (dispatch, getState, { services }) => {
    return new Promise((resolve) => {
      const { allowSave } = getState().canvas;

      if (!allowSave) {
        dispatch(
          NotificationActionCreators.createDefaultNotification({
            title: 'Save not Allowed',
            icon: 'icon-floppy',
            ID: generateNotificationID(),
            type: NotificationTypes.ERROR,
            inProgress: false,
          })
        );
        resolve(null);
        return;
      }

      const condensedPlan = PlanUtils.condenseZIndexes(plan);
      if (condensedPlan !== plan) {
        dispatch(updatePlan(condensedPlan, true));
      }

      const notificationID = generateNotificationID();
      const notificationTitle = SavePlanNotificationTitles[saveType].default; // autosave ? 'Autosaving Plan' : 'Saving Plan';
      const notificationSuccessTitle = SavePlanNotificationTitles[saveType].success; // autosave ? 'Autosave Successful' : 'Plan Saved';
      const notificationErrorTitle = SavePlanNotificationTitles[saveType].error; // autosave ? 'Autosave Failed' : 'Error Saving Plan';

      dispatch(
        batchActions([
          RequestActionCreators.requestStart(`SAVE_PLAN_${plan.id}`),
          NotificationActionCreators.createDefaultNotification({
            title: notificationTitle,
            icon: 'icon-floppy',
            ID: notificationID,
            type: NotificationTypes.INFO,
            inProgress: true,
          }),
        ])
      );

      const onSuccess = (responsePlan, ...otherActions) => {
        dispatch(
          batchActions([
            savePlanSuccess(condensedPlan, responsePlan),
            RequestActionCreators.requestComplete(`SAVE_PLAN_${plan.id}`),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: {
                title: notificationSuccessTitle,
                icon: 'icon-ok',
                type: NotificationTypes.SUCCESS,
                canTimeout: true,
              },
            }),
            ...otherActions,
          ])
        );
      };

      const onError = (err, ...otherActions) => {
        dispatch(
          batchActions([
            RequestActionCreators.requestFail(`SAVE_PLAN_${plan.id}`, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: {
                title: notificationErrorTitle,
                icon: 'icon-attention-alt',
                type: NotificationTypes.ERROR,
                canTimeout: false,
                inProgress: false,
              },
            }),
            ...otherActions,
          ])
        );
      };

      const log = (message) => {
        // Send copy of failed plan to JS log service
        try {
          const planJSONStr = JSON.stringify(PlanParserUtils.planToAPI(condensedPlan));
          dispatch(createLogMessage(`${message} - ${planJSONStr}`));
        } catch (e) {
          console.error('Failed to log plan-save to server');
          console.log(e);
        }
      };

      services.planService
        .savePlan(condensedPlan)
        .then((responsePlan) => {
          onSuccess(responsePlan);
          resolve(responsePlan);
        })
        .catch((err) => {
          console.error(err);
          if (err && err.clientMessage) {
            console.error(err.clientMessage);
          }

          // Temporary error handling for plans with bad data
          if (err instanceof PlanDataError) {
            if (err.uploaded) {
              onSuccess(
                err.plan,
                updatePlan(err.plan),
                onPlanDataErrorFound({
                  id: plan.id,
                  invalidProperties: err.invalidProperties,
                  plan: condensedPlan,
                  uploaded: true,
                })
              );
              log('Saved plan after fixing invalid properties');
              resolve(err.plan);
            } else {
              onError(err);
              log('Failed to save plan after fixing invalid properties');
            }
            resolve(null);
            return;
          }

          onError(err);
          resolve(null);
        });
    });
  };
};

/**
 * Saves all open plans
 *
 * Uses the current state from Redux-Thunk so takes no arguments
 */
export const saveOpenPlans = (): ThunkAction<void, BaseStoreState, ThunkExtraArgs, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const { openPlanIDs, plans, lastSavePlans } = state.canvas;

    const actions: ThunkAction<void, any, any, AnyAction>[] = [];

    // Find all openPlans which their openPlan doesn't match the lastSavePlan
    for (let i = 0; i < openPlanIDs.length; i++) {
      const openPlanID = openPlanIDs[i];
      const plan = PlanSetUtils.planSetGetPlan(plans, openPlanID);
      const lastSavePlan = PlanSetUtils.planSetGetPlan(lastSavePlans, openPlanID);

      let hasUnsavedChanges = false;
      if (plan !== null && lastSavePlan !== null) {
        hasUnsavedChanges = !PlanUtils.plansShallowEqual(plan, lastSavePlan);
      }

      if (hasUnsavedChanges) {
        actions.push(savePlan(plan));
      }
    }

    dispatch(batchActions(actions));
  };
};

const createPlan = (newPlan: Plan): ThunkAction<void, BaseStoreState, ThunkExtraArgs, AnyAction> => {
  return (dispatch, getState, { services }) => {
    const { user } = getState().session;
    const userId = user?.ID ?? -1;

    dispatch(RequestActionCreators.requestStart(`CREATE_PLAN_${userId}`));
    return services.planService
      .saveNewPlan(newPlan)
      .then((plan) => {
        dispatch(batchActions([createPlanSuccess(plan), RequestActionCreators.requestComplete(`CREATE_PLAN_${userId}`)]));
      })
      .catch((err) => {
        console.error(err);
        dispatch(RequestActionCreators.requestFail(`CREATE_PLAN_${userId}`, err));
      });
  };
};

const createFollowOnPlan = (
  newPlan: Plan,
  previousPlanID: number,
  plants: Collection<Plant>,
  gardenObjects: Collection<GardenObject>,
  options: FollowOnPlanOptions
): ThunkAction<void, BaseStoreState, ThunkExtraArgs, AnyAction> => {
  return (dispatch, getState, { services }) => {
    const { user } = getState().session;
    const userId = user?.ID ?? -1;

    dispatch(RequestActionCreators.requestStart(`LOAD_PREVIOUS_PLAN_${previousPlanID}`));
    dispatch(RequestActionCreators.requestStart(`CREATE_PLAN_${userId}`));
    return services.planService
      .loadPlan(previousPlanID)
      .then((previousPlan) => {
        RequestActionCreators.requestComplete(`LOAD_PREVIOUS_PLAN_${previousPlanID}`);
        // Copy the items from the previous plan onto the follow on plan
        const copiedPlan = PlanUtils.createFollowOnPlan(previousPlan, newPlan, plants, gardenObjects, options);
        services.planService
          .saveNewPlan(copiedPlan)
          .then((plan) => {
            dispatch(
              batchActions([
                createPlanSuccess(plan),
                // savePlan(user, plan),
                RequestActionCreators.requestComplete(`CREATE_PLAN_${userId}`),
              ])
            );
          })
          .catch((err) => {
            console.error(err);
            dispatch(RequestActionCreators.requestFail(`CREATE_PLAN_${userId}`, err));
          });
      })
      .catch((err) => {
        console.error(err);
        dispatch(RequestActionCreators.requestFail(`LOAD_PREVIOUS_PLAN_${previousPlanID}`, err));
      });
  };
};

const deletePlan = (plan: Plan): ThunkAction<void, BaseStoreState, ThunkExtraArgs, AnyAction> => {
  return (dispatch, getState, { services }) => {
    const notificationID = generateNotificationID();

    dispatch(
      batchActions([
        RequestActionCreators.requestStart(`DELETE_PLAN_${plan.id}`),
        NotificationActionCreators.createDefaultNotification({
          title: 'Deleting Plan',
          icon: 'icon-floppy',
          ID: notificationID,
          type: NotificationTypes.INFO,
          inProgress: true,
        }),
      ])
    );

    return services.planService
      .deletePlan(plan.id)
      .then(() => {
        dispatch(
          batchActions([
            closePlan(plan.id),
            deletePlanSuccess(plan.id),
            RequestActionCreators.requestComplete(`DELETE_PLAN_${plan.id}`),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: {
                title: 'Plan Deleted',
                icon: 'icon-ok',
                type: NotificationTypes.SUCCESS,
                canTimeout: true,
              },
            }),
          ])
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          batchActions([
            RequestActionCreators.requestFail(`DELETE_PLAN_${plan.id}`, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: {
                title: 'Plan Deletion Failed',
                icon: 'icon-attention-alt',
                type: NotificationTypes.ERROR,
                canTimeout: false,
                inProgress: false,
              },
            }),
          ])
        );
      });
  };
};

const resizeHistoricalPlan = (
  planId: number,
  originalSize: Dimensions,
  transform: {
    width: number;
    height: number;
    anchor: Anchor;
    rotation: PlanClockwiseRotationAmount | false;
  },
  gardenObjects: Collection<GardenObject>
): ThunkAction<Promise<{ plan: Plan; didSave: boolean }>, BaseStoreState, ThunkExtraArgs, AnyAction> => {
  return (dispatch, getState, { services }) => {
    return new Promise((resolve, reject) => {
      const applyToPlan = (plan: Plan) => {
        // Don't apply to plans with differing sizes.
        if (plan.width !== originalSize.width || plan.height !== originalSize.height) {
          const error = new Error('Plan does not match original dimensions');
          dispatch(RequestActionCreators.requestFail(`RESIZE_HISTORICAL_PLAN_${planId}`, error));
          reject(error);
          return;
        }

        const updatedPlan = PlanTransformUtils.rotateAndResizePlan(
          plan,
          transform.width,
          transform.height,
          transform.anchor,
          transform.rotation,
          gardenObjects
        );
        dispatch(updatePlan(updatedPlan));
        dispatch(clearUndoStack(updatedPlan.id));
        dispatch(savePlan(updatedPlan, SavePlanType.UPDATE_HISTORIC))
          .then((returnedPlan) => {
            if (returnedPlan !== null) {
              dispatch(RequestActionCreators.requestComplete(`RESIZE_HISTORICAL_PLAN_${planId}`));
              resolve({ plan: returnedPlan, didSave: true });
            } else {
              dispatch(RequestActionCreators.requestFail(`RESIZE_HISTORICAL_PLAN_${planId}`, new Error('Plan save returned null')));
              resolve({ plan, didSave: false });
            }
          })
          .catch((e) => {
            dispatch(RequestActionCreators.requestFail(`RESIZE_HISTORICAL_PLAN_${planId}`, e));
            resolve({ plan, didSave: false });
          });
      };

      dispatch(RequestActionCreators.requestStart(`RESIZE_HISTORICAL_PLAN_${planId}`));
      const state = getState().canvas;
      if (state.plans.plans[planId]) {
        // Plan is already open
        applyToPlan(state.plans.plans[planId]);
      } else if (state.loadedPlans.plans[planId]) {
        // Plan has already been loaded
        applyToPlan(state.loadedPlans.plans[planId]);
      } else {
        // Plan hasn't been loaded
        services.planService.loadPlan(planId).then(applyToPlan);
      }
    });
  };
};

const resizeHistoricalPlans = (
  planIds: number[],
  originalSize: Dimensions,
  transform: {
    width: number;
    height: number;
    anchor: Anchor;
    rotation: PlanClockwiseRotationAmount | false;
  },
  gardenObjects: Collection<GardenObject>
): ThunkAction<Promise<ResizeHistoricalPlansResult>, BaseStoreState, ThunkExtraArgs, AnyAction> => {
  return (dispatch) => {
    return new Promise((resolve) => {
      const successfulPlanIds: number[] = [];
      const skippedPlanIds: number[] = [];
      const failedPlanIds: number[] = [];
      const failedPlans: Record<number, Plan> = {};
      /**
       * Run the historic plan resizes sequentially, to minimise chance of memory/network issues.
       * Also ensures save order is maintained.
       */
      const processPlan = (i: number = 0) => {
        if (i < planIds.length) {
          // Reverse plan order to save oldest->newest
          const planId = planIds[planIds.length - 1 - i];
          dispatch(resizeHistoricalPlan(planId, originalSize, transform, gardenObjects))
            .then(({ plan, didSave }) => {
              if (didSave) {
                successfulPlanIds.push(planId);
              } else {
                failedPlanIds.push(plan.id);
                failedPlans[plan.id] = plan;
              }
            })
            .catch((e) => {
              // TODO: Use custom error class
              if (e instanceof Error && e.message === 'Plan does not match original dimensions') {
                skippedPlanIds.push(planId);
              } else {
                failedPlanIds.push(planId);
              }
            })
            .finally(() => {
              processPlan(i + 1);
            });
        } else {
          resolve({ successfulPlanIds, failedPlanIds, failedPlans, skippedPlanIds });
        }
      };
      processPlan(0);
    });
  };
};

const retryPlanUpload = (
  plans: Plan[],
  shouldClearUndoStack: boolean,
  saveType?: SavePlanType
): ThunkAction<Promise<RetryPlanUploadResult>, BaseStoreState, ThunkExtraArgs, AnyAction> => {
  return (dispatch) => {
    return new Promise((resolve) => {
      const successfulPlanIds: number[] = [];
      const failedPlanIds: number[] = [];
      const failedPlans: Record<number, Plan> = {};
      /**
       * Re-attempt to save all the given plans, sequentially.
       */
      const processPlan = (i: number = 0) => {
        if (i < plans.length) {
          dispatch(savePlan(plans[i], saveType))
            .then((returnedPlan) => {
              if (returnedPlan !== null) {
                successfulPlanIds.push(returnedPlan.id);
                if (shouldClearUndoStack) {
                  dispatch(clearUndoStack(plans[i].id));
                }
              } else {
                failedPlanIds.push(plans[i].id);
                failedPlans[plans[i].id] = plans[i];
              }
            })
            .catch(() => {
              failedPlanIds.push(plans[i].id);
            })
            .finally(() => {
              processPlan(i + 1);
            });
        } else {
          resolve({ successfulPlanIds, failedPlanIds, failedPlans });
        }
      };
      processPlan(0);
    });
  };
};

const updatePlanSettings = (
  user: User,
  plan: Plan,
  name: string,
  year: number,
  width: number,
  height: number,
  gridUnits: UnitType,
  history: number[],
  showGrid: boolean,
  showRulers: boolean,
  shouldClearUndoStack?: boolean
): ThunkAction<Promise<Plan | null>, BaseStoreState, ThunkExtraArgs, AnyAction> => {
  return (dispatch) => {
    const updatedPlan: Plan = {
      ...plan,
      name,
      year,
      width,
      height,
      history,
      plannerSettings: {
        ...plan.plannerSettings,
        metric: gridUnits === UnitTypes.METRIC,
        showGrid,
        showRulers,
      },
    };

    dispatch(updatePlan(updatedPlan));
    if (shouldClearUndoStack) {
      dispatch(clearUndoStack(updatedPlan.id));
    }
    return dispatch(savePlan(updatedPlan));
  };
};

const setCropRotationMode = (plan: Plan, cropRotationMode: CropRotationModes) => {
  const updatedPlan: Plan = {
    ...plan,
    plannerSettings: {
      ...plan.plannerSettings,
      cropRotationMode,
    },
  };

  return updatePlan(updatedPlan);
};

const setLayer = (plan: Plan, layer: LayerDisplayModes) => {
  const updatedPlan: Plan = { ...plan, plannerSettings: { ...plan.plannerSettings, layer } };
  return updatePlan(updatedPlan);
};

const setMonth = (plan: Plan, month: number | null) => {
  const updatedPlan: Plan = { ...plan, plannerSettings: { ...plan.plannerSettings, month } };
  return updatePlan(updatedPlan);
};

// TODO: Remove? possibly not used anymore
const updatePlantNote = (plan: Plan, plantCode: string, variety: string, text: string) => {
  const newPlantNotes = PlantNotesUtils.setPlantNote(plan.plantNotes, plantCode, variety, text);
  const updatedPlan: Plan = { ...plan, plantNotes: newPlantNotes };
  return updatePlan(updatedPlan);
};

export const setTextureStatus = (status: TexturesAsyncOperation) => {
  return {
    type: CanvasActionTypes.SET_TEXTURE_STATUS,
    texturesStatus: status,
  };
};

/**
 * Will close a plan if it has no unsaved changes, otherwise will add
 * to a list of plansIds to be shown a confirmation modal for closing
 *
 * The confirmation modal is manager by the ClosePlanModalRenderer
 */
export function attemptClosePlan(planId: number) {
  return (dispatch, getState) => {
    const state = getState();

    const plan = PlanSetUtils.planSetGetPlan(state.canvas.plans, planId);
    const lastSavedPlan = PlanSetUtils.planSetGetPlan(state.canvas.lastSavePlans, planId);

    let hasUnsavedChanges = false;
    if (plan !== null && lastSavedPlan !== null) {
      hasUnsavedChanges = !PlanUtils.plansShallowEqual(plan, lastSavedPlan);
    }

    if (hasUnsavedChanges) {
      dispatch(addToCloseConfirmation(planId));
    } else {
      dispatch(closePlan(planId));
    }
  };
}

/**
 * Action to clear some/all of the load failure counts for a given plan
 * @param planID The plan ID to clear the load failures of
 * @param reduceBy The amount of failures to remove from the count (defaults to all)
 */
const clearPlanLoadFailures = (planID: number, reduceBy: number = Infinity) => {
  return {
    type: CanvasActionTypes.CLEAR_PLAN_LOAD_FAILURES,
    planID,
    reduceBy,
  };
};

export {
  openPlan,
  closePlan,
  updatePlan,
  addPlan,
  plansLoadStart,
  planLoadEnd,
  planLoadFailed,
  removePlans,
  setActivePlan,
  setGridVisibility,
  setRulerVisibility,
  setBackgroundImageSettings,
  setBackgroundImageVisibility,
  setBackgroundImageOpacity,
  setBackgroundImageMaintainAspectRatio,
  createPlan,
  createFollowOnPlan,
  noChangesToSave,
  savePlan,
  deletePlan,
  updatePlanSettings,
  undo,
  redo,
  setCropRotationMode,
  setLayer,
  setMonth,
  updatePlantNote,
  resizeHistoricalPlan,
  resizeHistoricalPlans,
  retryPlanUpload,
  clearPlanLoadFailures,
};
