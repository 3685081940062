/* eslint-disable import/no-extraneous-dependencies */
import Collection from '@gi/collection';
import { PlantTypes } from '@gi/constants';
import { PlanPlant } from '@gi/plan';
import { UserPlantVariety } from '@gi/user';
import Plant, { PlantSpacings, PlantAreaCount } from '@gi/plant';

export type PlantCount = {
  x: number;
  y: number;
  total: number;
};

/**
 * Returns true if the given dimensions are a single plant (if height is 0 and width is 0), else false
 */
export function isSingle(width: number, height: number): boolean {
  return height === 0 && width === 0;
}

/**
 * Returns true if the given dimensions could be a plant row (if height is 0 and width is not), else false
 */
export function isRow(width: number, height: number): boolean {
  return height === 0 && width !== 0;
}

/**
 * Returns the spacing between plants, spacing is different when planted in a row (height is 0)
 */
export function getSpacing(width: number, height: number, spacing: number, inRowSpacing: number): number {
  if (isRow(width, height)) {
    return inRowSpacing;
  }

  return spacing;
}

/**
 * returns the number of plants on the x and y axis of a plant instance of the given size
 */
export function getBlockPlantCount(width: number, height: number, spacing: number, inRowSpacing: number): Vector2 {
  const blockSpacing = getSpacing(width, height, spacing, inRowSpacing);
  const x = Math.floor(width / blockSpacing) + 1;
  const y = Math.floor(height / blockSpacing) + 1;

  return {
    x,
    y,
  };
}

/**
 * returns the number of plants on the x and y axis of a plant instance of the given size
 */
export function getPlantCount(
  isSquareFoot: boolean,
  squareFootPlantCount: null | number,
  width: number,
  height: number,
  spacing: number,
  inRowSpacing: number
): PlantAreaCount {
  if (isSquareFoot) {
    if (squareFootPlantCount === null) {
      console.error('Square foot plant but sfg plant count is null');
      return {
        x: 0,
        y: 0,
        total: 0,
      };
    }

    return {
      x: 0,
      y: 0,
      total: squareFootPlantCount,
    };
  }

  const counts = getBlockPlantCount(width, height, spacing, inRowSpacing);

  return {
    x: counts.x,
    y: counts.y,
    total: counts.x * counts.y,
  };
}

/**
 * Returns the correct x, y spacing for the given height and width
 */
export function getPlantSpacing(width: number, height: number, spacing: number, rowSpacing: number, inRowSpacing: number): Vector2 {
  if (isRow(width, height)) {
    return {
      x: inRowSpacing,
      y: rowSpacing,
    };
  }

  return {
    x: spacing,
    y: spacing,
  };
}

/**
 * Returns PLANT_BLOCK, PLANT_ROW or PLANT_SINGLE depending on the size of the provided width and height with the given spacings
 */
export function getType(width: number, height: number, spacing: number, inRowSpacing: number): string {
  let longerSide = height;
  let shorterSide = width;

  if (width >= height) {
    longerSide = width;
    shorterSide = height;
  }

  if (longerSide >= spacing && shorterSide >= spacing) {
    return PlantTypes.PLANT_BLOCK;
  }

  if (longerSide >= inRowSpacing) {
    return PlantTypes.PLANT_ROW;
  }

  return PlantTypes.PLANT_SINGLE;
}

/**
 * Returns spacings for a given plant and optional UserPlantVariety combination
 */
export function getSpacings(plant: Plant, userPlantVariety: UserPlantVariety | null): PlantSpacings {
  return {
    spacing: userPlantVariety !== null && userPlantVariety.customSpacings ? userPlantVariety.spacing : plant.spacing,
    inRowSpacing: userPlantVariety !== null && userPlantVariety.customSpacings ? userPlantVariety.inRowSpacing : plant.inRowSpacing,
    rowSpacing: userPlantVariety !== null && userPlantVariety.customSpacings ? userPlantVariety.rowSpacing : plant.rowSpacing,
    sfgCount: plant.canBeSquareFootPlant
      ? userPlantVariety !== null && userPlantVariety.customSpacings
        ? userPlantVariety.squareFootPlantCount
        : plant.squareFootPlantCount
      : null,
  };
}

/**
 * Returns the square foot plant count for a given Plant and UserPlantVariety combination
 */
export function getSquareFootPlantCount(plant: Plant, userVariety: UserPlantVariety | null): number {
  if (userVariety === null || !userVariety.customSpacings) {
    return plant.squareFootPlantCount;
  }

  return userVariety.squareFootPlantCount;
}

/**
 * Returns whether the given PlanPlant is a perennial by finding the plant in the plants collection
 */
export function isPerennial(planPlant: PlanPlant, plants: Collection<Plant>): boolean {
  const plant = plants.get(planPlant.plantCode);

  if (plant === null) {
    console.warn('Plan plant not found in collection when checking if perennial', planPlant.plantCode);
    return false;
  }

  return plant.perennial;
}

/**
 * Over winter is true if the plant is not 'inGroundAll' (i.e. in the ground the whole year, probably because in-ground dates have not been set)
 * and the in-ground start happens after the in-ground end (thus, end is the next year)
 */
export function isOverWinter(inGroundAll: boolean, inGroundStart: number, inGroundEnd: number): boolean {
  return !inGroundAll && inGroundStart > inGroundEnd;
}
