import React, { createRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { UserShape } from '@gi/user';
import { JournalEntries, JournalActions } from '@gi/journal-days';
import JournalDay from './journal-day';

/**
 * @param {{
 *  selectedWeek?: number,
 *  dates?: string[],
 *  weekNo: number,
 *  journalEntries?: JournalEntries,
 *  journalActions?: JournalActions,
 *  user: import('@gi/user').User
 * }}
 */
const JournalWeek = ({ selectedWeek = 1, dates = [], weekNo, journalEntries = new JournalEntries(), journalActions = new JournalActions(), user }) => {
  const elm = createRef();
  useEffect(() => {
    if (weekNo === selectedWeek) {
      elm.current.scrollIntoView();
    }
  }, [weekNo, selectedWeek]);

  return (
    <div ref={elm}>
      {dates.map((dateYMD) => {
        const entries = journalEntries.getEntriesForDate(dateYMD);
        const actions = journalActions.getActionsForDate(dateYMD);
        return <JournalDay user={user} dateYMD={dateYMD} journalEntries={entries} journalActions={actions} key={dateYMD} />;
      })}
    </div>
  );
};

JournalWeek.propTypes = {
  selectedWeek: PropTypes.number,
  dates: PropTypes.arrayOf(PropTypes.string),
  weekNo: PropTypes.number.isRequired,
  journalEntries: PropTypes.instanceOf(JournalEntries),
  journalActions: PropTypes.instanceOf(JournalActions),
  user: UserShape.isRequired,
};

export default JournalWeek;
