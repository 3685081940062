import { RequestAuthMode, RequestError, networkErrorReporter, networkService } from '@gi/gi-network';
import { errorReporterInstance } from '@gi/errors';

import { planFromAPI, planToAPI } from '../plan-parser';
import { Plan } from '../plan';
import { APIPlanDocument } from '../plan-api-types';
import { PublishPlanProperties } from '../publish-plan';

import {
  attachLoadPlanErrorClientMessage,
  attachCreatePlanErrorClientMessage,
  attachSavePlanErrorClientMessage,
  attachDeletePlanErrorClientMessage,
  attachPublishPlanErrorClientMessage,
  attachTransferPlanErrorClientMessage,
} from './plan-service-errors';
import { validatePlanItemsInPlace } from '../plan-validation';
import PlanDataError from './plan-data-error';

/** Request timeout time for plan uploads */
const PUBLISH_PLAN_UPLOAD_TIMEOUT = 3 * 60 * 1000; // 3 minutes

/**
 * Collection of methods for API requests involving plans
 *
 * @class PlanService
 */
class PlanService {
  endpoint: string;
  publishPlanEndpoint: string;

  constructor(endpoint: string, publishPlanEndpoint: string) {
    this.endpoint = endpoint;
    this.publishPlanEndpoint = publishPlanEndpoint;
    console.debug(`Plan service created with endpoint ${this.endpoint}`);
  }

  /**
   * Loads a plan from the given endpoint
   */
  loadPlan(planID: number): Promise<Plan> {
    return this.loadPlanJSON(planID).then((planJson) => planFromAPI(planJson));
  }

  /**
   * Loads a plan from the given endpoint and returns the JSON string
   */
  loadPlanJSON(planID: number): Promise<APIPlanDocument> {
    const endPoint = '/plans/';
    const url = `${this.endpoint}${endPoint}${planID}`;

    return networkService
      .get<APIPlanDocument>(url)
      .then((response) => response.body)
      .catch(networkErrorReporter('GET', 'plan'))
      .catch((requestError) => {
        attachLoadPlanErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Sends a POST request to the API to save a new plan
   */
  saveNewPlan(newPlan: Plan): Promise<Plan> {
    const planData = planToAPI(newPlan);

    const endPoint = '/plans';
    const url = `${this.endpoint}${endPoint}`;

    return networkService
      .post<APIPlanDocument>(url, {}, planData)
      .then((response) => response.body)
      .then((body) => planFromAPI(body))
      .catch(networkErrorReporter('POST', 'plan'))
      .catch((requestError) => {
        attachCreatePlanErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Sends a PUT request to the API to save a plan, returns a promise which resolves to the
   * plan returned in the response
   */
  savePlan(plan: Plan): Promise<Plan> {
    const planData = planToAPI(plan);

    const invalidProperties = validatePlanItemsInPlace(planData);

    const endPoint = '/plans/';
    const url = `${this.endpoint}${endPoint}${plan.id}`;

    return networkService
      .put<APIPlanDocument>(url, {}, planData)
      .then((response) => planFromAPI(response.body))
      .then((responsePlan) => {
        if (
          invalidProperties.plants.length > 0 ||
          invalidProperties.gardenObjects.length > 0 ||
          invalidProperties.shapes.length > 0 ||
          invalidProperties.text.length > 0
        ) {
          throw new PlanDataError(true, responsePlan, invalidProperties);
        }
        return responsePlan;
      })
      .catch((error) => {
        if (error instanceof PlanDataError) {
          errorReporterInstance.notify(error);
        } else {
          networkErrorReporter('PUT', 'plan')(error);
        }
        throw error;
      })
      .catch((requestError) => {
        attachSavePlanErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Sends a delete request to the API to delete a plan
   */
  deletePlan(planId: number): Promise<unknown> {
    const endPoint = '/plans/';
    const url = `${this.endpoint}${endPoint}${planId}`;

    return networkService
      .delete(url)
      .catch(networkErrorReporter('DELETE', 'plan'))
      .catch((requestError) => {
        attachDeletePlanErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Publishes a plan to the publish API
   */
  publishPlan(planID: number, image: string, previewImage: string, thumbnail: string, properties: PublishPlanProperties): Promise<string> {
    if (!networkService.userAuth) {
      throw new Error('User is not authenticated by network service');
    }

    const data = {
      u: networkService.userAuth.id,
      t: networkService.userAuth.ticket,
      p: planID,
      findOnMap: properties.findOnMap,
      planImageData: image,
      planPreviewData: previewImage,
      planThumbnailData: thumbnail,
      planDescription: properties.description,
      planSoil: properties.soil,
      planSun: properties.sun,
      planLayout: properties.layout,
      planLocation: properties.location,
      planType: properties.type,
      publishMap: properties.publishMap,
      publishNotes: properties.publishNotes,
      publishPlantList: properties.publishPlantList,
    };

    const url = this.publishPlanEndpoint;

    return networkService
      .postFormData(url, {}, data, RequestAuthMode.None, { timeout: PUBLISH_PLAN_UPLOAD_TIMEOUT })
      .then((response) => {
        if (response.body !== 'ok=True') {
          throw new RequestError({
            method: 'POST',
            url,
            statusCode: response.status,
            response: response.response,
            error: new Error('Response body was not OK'),
          });
        }

        return response.body;
      })
      .catch(networkErrorReporter('POST', 'publish plan'))
      .catch((requestError) => {
        attachPublishPlanErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Transfers or copies a plan
   */
  transferPlan(planID: number, destinationEmail: string, copy: boolean): Promise<unknown> {
    const params = {
      copy,
    };

    const url = `${this.endpoint}/plans/${planID}/transfer`;

    return networkService
      .put(url, params, destinationEmail)
      .catch(networkErrorReporter('PUT', 'transfer plan'))
      .catch((requestError) => {
        attachTransferPlanErrorClientMessage(requestError);
        throw requestError;
      });
  }
}

export default PlanService;
