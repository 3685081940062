import React from 'react';

import './modal-close-button.scss';

interface iProps {
  onClick: () => void;
  light?: boolean;
  large?: boolean;
}

const ModalCloseButton = ({ onClick, light = false, large = false }: iProps): JSX.Element => {
  return (
    <div className={`modal-close-button ${light ? 'light' : ''} ${large ? 'large' : ''}`}>
      <button type='button' aria-label='Close' className='button' onClick={onClick}>
        <i className='icon-cancel' />
      </button>
    </div>
  );
};

export default ModalCloseButton;
