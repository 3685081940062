import React from 'react';

import './dashboard-item-section.scss';

interface iProps {
  className?: string;
  children: React.ReactNode;
}

const DashboardItemSection = ({ className = '', children }: iProps): JSX.Element => {
  return <div className={`dashboard-item-section ${className}`}>{children}</div>;
};

export default DashboardItemSection;
