import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';

import Country, { CountryCollection } from '@gi/country';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

interface Option {
  value: string;
  label: string;
}

function countryToSelectOption(country: Country): Option {
  return {
    value: country.code ?? '',
    label: country.name ?? '',
  };
}

interface iProps {
  countries: CountryCollection;
  value: Country | null;
  onChange: (value: Country) => void;
  id?: string;
  name?: string;
}

const CountrySelect = ({ countries, value, onChange, id, name }: iProps): JSX.Element => {
  const [options, setOptions] = useState<Option[]>([]);

  // Only update the options when the countries prop is updated, as sorting is expensive.
  useEffect(() => {
    setOptions(
      countries
        .asArray()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((country) => countryToSelectOption(country))
    );
  }, [countries]);

  const onSelected = (newValue: Option) => {
    const newCountry = countries.get(newValue.value);
    if (newCountry !== null) {
      onChange(newCountry);
    }
  };

  return (
    <ReactSelect
      inputId={id}
      name={name}
      value={options.find((option) => option.value === value?.code)}
      options={options}
      onChange={onSelected}
      styles={DEFAULT_SELECT_STYLES}
      isClearable={false}
      isSearchable
    />
  );
};

export default CountrySelect;
