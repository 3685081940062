import { AnyAction, Dispatch, PayloadAction, createListenerMiddleware } from '@reduxjs/toolkit';
import { EventsSystem } from './events-system';

const FIRE_EVENT_EVENT_NAME = 'events/fireEvent';

export function setupReduxEventsSystem<TKey extends string, TParams extends Record<string, Record<string, any>>>() {
  type EventAction<T extends TKey> = PayloadAction<{ eventName: T; parameters: TParams[T] }, typeof FIRE_EVENT_EVENT_NAME>;

  /**
   * Dispatches an event via redux
   * @param eventName The name of the event
   * @param parameters The parameters required for the event
   * @returns A Redux Dispatchable Action
   */
  function fireEvent<T extends TKey>(eventName: T, parameters: TParams[T]): EventAction<T> {
    return {
      type: FIRE_EVENT_EVENT_NAME,
      payload: {
        eventName,
        parameters,
      },
    };
  }

  /**
   * Creates a middleware to handle intercepting events dispatched via redux and fire them through the events system.
   * @param services The services argument for redux, containing the eventsSystem
   * @returns A middleware
   */
  const createMiddleware = (services: { eventsSystem: EventsSystem<TKey, TParams> }) => {
    const eventsMiddleware = createListenerMiddleware<unknown, Dispatch<AnyAction>, { eventsSystem: EventsSystem<TKey, TParams> }>({ extra: services });

    eventsMiddleware.startListening({
      type: FIRE_EVENT_EVENT_NAME,
      effect: (event, api) => {
        const { payload } = event as EventAction<TKey>;
        if (api.extra && api.extra.eventsSystem) {
          api.extra.eventsSystem.fireEvent(payload.eventName, payload.parameters);
        } else {
          console.warn("Couldn't trigger event via Redux Middlewarre: Events System missing from Thunk services.");
        }
      },
    });

    return eventsMiddleware.middleware;
  };

  /**
   * Checks if the given action is a 'fireEvent' action, and acts as a typeguard
   * @param action The redux action to check
   * @returns True if the action is a 'fireEvent' action
   */
  const isFireEventAction = (action: AnyAction): action is EventAction<TKey> => {
    return action.type === FIRE_EVENT_EVENT_NAME;
  };

  return {
    createMiddleware,
    actionCreators: {
      fireEvent,
    },
    isFireEventAction,
  };
}
