import React from 'react';
import { useSelector } from 'react-redux';
import Plan from '@gi/plan';
import { CanvasSelectors } from '@gi/react-garden-canvas';

import { EditPlantProvider } from './context/edit-plant-context';
import EditPlantModalContent from './edit-plant-modal-content';

import './edit-plant-modal.scss';

interface iProps {
  plantItemID: number;
  closeModal: () => void;
  editItemModalComplete: (plan: Plan) => void;
}

const EditPlantModal = ({ plantItemID, closeModal, editItemModalComplete }: iProps): JSX.Element | null => {
  const plan = useSelector(CanvasSelectors.getActivePlan);

  if (plan === null) {
    return null;
  }

  return (
    <EditPlantProvider plantItemID={plantItemID} plan={plan} closeModal={closeModal} editItemModalComplete={editItemModalComplete}>
      <EditPlantModalContent />
    </EditPlantProvider>
  );
};

export default EditPlantModal;
