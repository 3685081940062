import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GardenCanvasContext } from '@gi/react-garden-canvas';
import { ResourceContext } from '@gi/resource-provider';
import {
  GardenObjectShoppingEntry,
  PlantShoppingEntry,
  ShoppingList,
  GardenObjectProduct,
  PlantProduct,
  ProductType,
  ProductVariant,
  ShoppingListProduct,
  ShoppingListProductsUtils,
  createShoppingList,
  createShoppingListInputs,
} from '@gi/products';

import { ShoppingActionCreators, ShoppingSelectors } from './slice/shopping-slice';

export function useProduct(productId: number): null | ShoppingListProduct {
  const { store } = useContext(ResourceContext);
  if (store !== null && store.products.byProductId[productId]) {
    return store.products.byProductId[productId];
  }

  return null;
}

export function useVariant<T>(variantId: number): null | ProductVariant<T> {
  const { store } = useContext(ResourceContext);

  if (store === null) {
    return null;
  }

  return ShoppingListProductsUtils.getVariant<T>(store.products, variantId);
}

export function usePlantProduct(productId: number): null | PlantProduct {
  const product = useProduct(productId);

  if (!product) {
    return null;
  }

  if (product.type === ProductType.Plant) {
    return product;
  }

  return null;
}

export function usePlantProducts(plantCode: string): PlantProduct[] {
  const { store } = useContext(ResourceContext);
  if (store === null) {
    return [];
  }

  return useMemo(() => {
    const productIds = store.products.productsByCode[plantCode];

    if (!productIds) {
      return [];
    }

    return productIds.map((productId) => store.products.byProductId[productId]) as PlantProduct[];
  }, [plantCode, store]);
}

export function useGardenObjectProduct(productId: number): null | GardenObjectProduct {
  const product = useProduct(productId);

  if (!product) {
    return null;
  }

  if (product.type === ProductType.GardenObject) {
    return product;
  }

  return null;
}

export function useGardenObjectProducts(code: string): GardenObjectProduct[] {
  const { store } = useContext(ResourceContext);

  if (store === null) {
    return [];
  }

  return useMemo(() => {
    const productIds = store.products.productsByCode[code];

    if (!productIds) {
      return [];
    }

    return productIds.map((productId) => store.products.byProductId[productId]) as GardenObjectProduct[];
  }, [code, store]);
}

export function usePlantShoppingEntry(planId: number, entryId: string): [PlantShoppingEntry | null, (entry: PlantShoppingEntry) => void] {
  const dispatch = useDispatch();
  const { lists } = useSelector(ShoppingSelectors.getShoppingState);
  let entry: PlantShoppingEntry | null = null;

  if (lists && lists[planId] && lists[planId].plants.entriesByUuid[entryId]) {
    entry = lists[planId].plants.entriesByUuid[entryId];
  }

  const setEntry = useCallback(
    (_entry: PlantShoppingEntry) => {
      dispatch(ShoppingActionCreators.setEntry({ planId, entry: _entry }));
    },
    [planId]
  );

  return useMemo(() => [entry, setEntry], [entry, setEntry]);
}

export function useGardenObjectShoppingEntry(planId: number, entryId: string): [GardenObjectShoppingEntry | null, (entry: GardenObjectShoppingEntry) => void] {
  const dispatch = useDispatch();
  const { lists } = useSelector(ShoppingSelectors.getShoppingState);
  let entry: GardenObjectShoppingEntry | null = null;

  if (lists && lists[planId] && lists[planId].gardenObjects.entriesByUuid[entryId]) {
    entry = lists[planId].gardenObjects.entriesByUuid[entryId];
  }

  const setEntry = useCallback(
    (_entry: GardenObjectShoppingEntry) => {
      dispatch(ShoppingActionCreators.setEntry({ planId, entry: _entry }));
    },
    [planId]
  );

  return useMemo(() => [entry, setEntry], [entry, setEntry]);
}

/**
 * A hook to use once at the top-level of a shopping cart. This component makes sure that when a plan as out of date
 * by the 'latestChanges' property of the shopping slice, the shopping list is re-made from its simulated plan contents
 */
export function keepShoppingListUpdated(planId: number | null): void {
  const dispatch = useDispatch();
  const { lists, listUpToDate } = useSelector(ShoppingSelectors.getShoppingState);
  const { store } = useContext(ResourceContext);
  const { gardenCanvas } = useContext(GardenCanvasContext);

  useEffect(() => {
    if (planId !== null && store && gardenCanvas && !listUpToDate[planId]) {
      const simulatedPlan = gardenCanvas.syncedPlans.getSimulatedPlan(planId);
      if (simulatedPlan) {
        const shoppingListInputs = createShoppingListInputs(simulatedPlan);
        const list = createShoppingList(shoppingListInputs, store.products);
        dispatch(ShoppingActionCreators.updateListFromPlan(list));
      }
    }
  }, [planId, store, gardenCanvas, lists, listUpToDate]);
}

export function useShoppingList(planId: number): ShoppingList | null {
  const { lists } = useSelector(ShoppingSelectors.getShoppingState);

  if (lists[planId]) {
    return lists[planId];
  }

  return null;
}

/**
 * A hook which changes vertical mouse scrolling to horizontal on the given element
 */
export function useHorizontalScroll(ref: React.MutableRefObject<HTMLElement>) {
  useEffect(() => {
    const el = ref.current;
    if (el) {
      const onWheel = (e) => {
        if (e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({
          left: el.scrollLeft + e.deltaY,
          behavior: 'auto',
        });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
    return () => {};
  }, []);
}
