import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShoppingList, ShoppingListUtils, GardenObjectShoppingEntry, PlantShoppingEntry, ShoppingListProducts } from '@gi/products';

export type ShoppingState = {
  lists: Record<number, ShoppingList>; // Record planId => ShoppingList
  listUpToDate: Record<number, boolean>; // A boolean which keeps track of whether a given garden plan is up-to-date with a given shopping list
};

const DEFAULT_STATE: ShoppingState = {
  lists: {},
  listUpToDate: {},
};

export const shoppingSlice = createSlice({
  name: 'shopping',
  initialState: DEFAULT_STATE,
  reducers: {
    updateListFromPlan: (state: ShoppingState, action: PayloadAction<ShoppingList>) => {
      state.lists[action.payload.planId] = action.payload;
      state.listUpToDate[action.payload.planId] = true;
    },
    setList: (state: ShoppingState, action: PayloadAction<ShoppingList>) => {
      state.lists[action.payload.planId] = action.payload;
    },
    clearList: (state: ShoppingState, action: PayloadAction<number>) => {
      delete state.lists[action.payload];
    },
    setEntry: (state: ShoppingState, action: PayloadAction<{ planId: number; entry: PlantShoppingEntry | GardenObjectShoppingEntry }>) => {
      ShoppingListUtils.setEntry(state.lists[action.payload.planId], action.payload.entry);
    },
    setEntries: (state: ShoppingState, action: PayloadAction<{ planId: number; entries: PlantShoppingEntry[] | GardenObjectShoppingEntry[] }>) => {
      for (let i = 0; i < action.payload.entries.length; i++) {
        ShoppingListUtils.setEntry(state.lists[action.payload.planId], action.payload.entries[i]);
      }
    },
    splitPlantEntry: (state: ShoppingState, action: PayloadAction<{ planId: number; uuid: string; variety: string; products: ShoppingListProducts }>) => {
      const list = state.lists[action.payload.planId];
      const { uuid, variety, products } = action.payload;
      ShoppingListUtils.splitPlantEntry(list, uuid, variety, products);
    },
    mergePlantEntry: (
      state: ShoppingState,
      action: PayloadAction<{ planId: number; productId: number; variantId: number; products: ShoppingListProducts }>
    ) => {
      const list = state.lists[action.payload.planId];
      const { productId, variantId, products } = action.payload;
      ShoppingListUtils.mergeDuplicatePlantEntries(list, productId, variantId, products);
    },
    setListUpToDate: (state: ShoppingState, action: PayloadAction<{ planId: number; upToDate: boolean }>) => {
      const { planId, upToDate } = action.payload;
      state.listUpToDate[planId] = upToDate;
    },
  },
});

export const shoppingReducer = shoppingSlice.reducer;
export const ShoppingActionCreators = shoppingSlice.actions;

interface RootState {
  shopping: ShoppingState;
}

export const ShoppingSelectors = {
  getShoppingState: (state: RootState) => state.shopping,
};
