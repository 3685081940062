import { plantFamilyFromObject, PlantFamilyCollection } from '@gi/plant-family';
import plantFamilyData from '@gi/plant-family-data';

import PlantFamilyType from './plant-family-types';

const plantFamilies = new PlantFamilyCollection();
plantFamilyData.forEach((pfd) => plantFamilies.add(plantFamilyFromObject(pfd)));

export default plantFamilies;

export { plantFamilies, PlantFamilyType as PlantFamilyTypes };
