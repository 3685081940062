import { updateFilters } from '@gi/filters';
import React, { useContext } from 'react';
import ColumnSortButton from '../../common/column-sort-buttons/column-sort-button';
import ColumnSortIcon from '../../common/column-sort-buttons/column-sort-icon';
import { DISPLAY_TYPE } from '../../constants';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import { SORT_ORDER } from '../../types/plant-list-types';

const PlantLabelHeaderCell = () => {
  const { columns, setAreaGroupFilters, areaGroupFilters } = useContext(GlobalPlantListContext);

  if (!columns.plantLabel) {
    return null;
  }

  return (
    <th className='header-cell left-align-cell'>
      <div className='header-cell-content'>
        <div className='header-row'>
          Plant Label
          <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Area}>
            <ColumnSortIcon
              upSortType={SORT_ORDER.PlantLabelAsc}
              downSortType={SORT_ORDER.PlantLabelDesc}
              currentSortType={areaGroupFilters.filters.sort.inputs.sortOrder}
              setSortOrder={(sortOrder: SORT_ORDER) => {
                setAreaGroupFilters(updateFilters(areaGroupFilters, { sort: { sortOrder } }));
              }}
            />
          </ColumnSortButton>
        </div>
      </div>
    </th>
  );
};

export default PlantLabelHeaderCell;
