import { batchActions } from 'redux-batched-actions';
import { RequestActionCreators } from '@gi/react-requests';
import { RequestsUtils } from '@gi/request/index.ts';

import JournalActionTypes from './journal-action-types';

const getLoadJournalRequestName = (year, plantCode) => {
  return `LOAD_JOURNAL_${year}_${plantCode}`;
};

const getWeeklyTotalsRequestName = (year, plantCode) => {
  return `LOAD_JOURNAL_WEEKLY_TOTALS_${year}_${plantCode}`;
};

const journalWeeklyTotalsLoaded = (year, plantCode, weeklyTotals) => {
  return {
    type: JournalActionTypes.LOAD_JOURNAL_DAY_ENTRY_COUNTS_SUCCESS,
    year,
    plantCode,
    weeklyTotals,
  };
};

/**
 * @param {number} year
 * @param {string} plantCode
 */
export const loadJournalWeeklyTotals = (year = new Date().getFullYear(), plantCode = '') => {
  return (dispatch, getState, { services }) => {
    const state = getState();

    const REQUEST_NAME = getWeeklyTotalsRequestName(year, plantCode);

    if (RequestsUtils.hasRequest(state.requests, REQUEST_NAME)) {
      return;
    }

    dispatch(RequestActionCreators.requestStart(REQUEST_NAME));

    const user = state.session.user;

    services.journalService
      .getWeeklyTotals(user.ID, year, plantCode)
      .then((weeklyTotals) => {
        dispatch(batchActions([journalWeeklyTotalsLoaded(year, plantCode, weeklyTotals), RequestActionCreators.requestComplete(REQUEST_NAME)]));
      })
      .catch((err) => {
        dispatch(RequestActionCreators.requestFail(REQUEST_NAME, err));
      });
  };
};

const journalLoaded = (year, journalData, plantCode) => {
  return {
    type: JournalActionTypes.LOAD_JOURNAL_SUCCESS,
    journalEntries: journalData.journalEntries,
    journalActions: journalData.journalActions,
    year,
    plantCode,
  };
};

/**
 * @param {number} year
 * @param {string} plantCode
 */
export const loadJournal = (year = new Date().getFullYear(), plantCode = '') => {
  return (dispatch, getState, { services }) => {
    const state = getState();

    const REQUEST_NAME = getLoadJournalRequestName(year, plantCode);

    if (RequestsUtils.hasRequest(state.requests, REQUEST_NAME)) {
      return;
    }

    dispatch(RequestActionCreators.requestStart(REQUEST_NAME));

    const user = state.session.user;

    services.journalService
      .getJournalByYearAndPlantCode(user.ID, year, plantCode)
      .then((journalData) => {
        dispatch(batchActions([journalLoaded(year, journalData, plantCode), RequestActionCreators.requestComplete(REQUEST_NAME)]));
      })
      .catch((err) => {
        dispatch(RequestActionCreators.requestFail(REQUEST_NAME, err));
      });
  };
};

const journalActiveYearsLoaded = (plantCode, activeYears) => {
  return {
    type: JournalActionTypes.LOAD_JOURNAL_ACTIVE_YEARS_SUCCESS,
    plantCode,
    activeYears,
  };
};

export const loadJournalActiveYears = (plantCode = null) => {
  return (dispatch, getState, { services }) => {
    const state = getState();
    const REQUEST_NAME = plantCode === null ? 'LOAD_JOURNAL_ACTIVE_YEARS' : `LOAD_JOURNAL_ACTIVE_YEARS_${plantCode}`;

    if (RequestsUtils.hasRequest(state.requests, REQUEST_NAME)) {
      return;
    }

    dispatch(RequestActionCreators.requestStart(REQUEST_NAME));

    const user = state.session.user;

    services.journalService
      .getJournalActiveYears(user.ID, plantCode)
      .then((activeYears) => {
        dispatch(batchActions([journalActiveYearsLoaded(plantCode, activeYears), RequestActionCreators.requestComplete(REQUEST_NAME)]));
      })
      .catch((err) => {
        console.error(err);
        dispatch(RequestActionCreators.requestFail(REQUEST_NAME, err));
      });
  };
};
