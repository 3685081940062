import { createStore, applyMiddleware, compose } from 'redux';
import { batchDispatchMiddleware } from 'redux-batched-actions';
import thunk from 'redux-thunk';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import config from '@gi/config/config';
import { analyticsMiddleware } from '@gi/analytics';
import { logMiddleware } from '@gi/js-log';
import { services } from '@gi/garden-platform-services';
import { errorReporterInstance, errorReporterReduxMiddleware } from '@gi/errors';
import { createGardenPlatformEventsMiddleware, gardenPlatformEventEmitterMiddleware } from '@gi/garden-platform-events';

import reducers from './reducers';

let composeEnhancers = compose;

if (config.enabledReduxDevTools) {
  composeEnhancers = (window !== undefined && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
}

const store = createStore(
  reducers,
  undefined,
  composeEnhancers(
    applyMiddleware(
      batchDispatchMiddleware,
      createGardenPlatformEventsMiddleware(services),
      gardenPlatformEventEmitterMiddleware,
      analyticsMiddleware,
      logMiddleware.middleware,
      createListenerMiddleware().middleware,
      thunk.withExtraArgument({ services }),
      errorReporterReduxMiddleware(errorReporterInstance)
    )
  )
);

export type RootState = ReturnType<typeof store.getState>;

export default store;
