import React, { useContext, useMemo } from 'react';
import ReactSelect, { SingleValue } from 'react-select';
import { useSelector } from 'react-redux';

import { UserPlantVarietySet, UserPlantVarietySetUtils } from '@gi/user';
import Plant from '@gi/plant';

import { lexographicSort } from '@gi/utils';
import { DEFAULT_VARIETY } from '@gi/constants';
import { SupplierCatalogue, SupplierCatalogueUtils } from '@gi/supplier';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';
import { ResourceContext } from '@gi/resource-provider';
import { SessionSelectors } from '@gi/react-session';
import FormField, { FORM_FIELD_PRESETS, FormLayout, FormSection, InputContainer } from '@gi/form-responsive';

import { EditPlantContext } from '../context/edit-plant-context';
import { getNextPlantLabel, updatePlantLabel } from '../utils';

import './edit-plant-label.scss';

function getPlantVarietyOptions(
  supplierCatalogue: SupplierCatalogue,
  plantCode: string,
  userPlantVarieties: UserPlantVarietySet
): { label: string; value: string }[] {
  const varietyNameSet = new Set<string>([
    ...SupplierCatalogueUtils.getAllVarietiesForPlant(supplierCatalogue, plantCode),
    ...UserPlantVarietySetUtils.getByPlantCode(userPlantVarieties, plantCode).map((userPlantVariety) => userPlantVariety.name),
  ]);

  if (!varietyNameSet.has(DEFAULT_VARIETY)) {
    varietyNameSet.add(DEFAULT_VARIETY);
  }

  const varietyNames: string[] = Array.from(varietyNameSet).sort(lexographicSort);

  return varietyNames.map((varietyName) => (varietyName === DEFAULT_VARIETY ? { label: '-', value: varietyName } : { label: varietyName, value: varietyName }));
}

/**
 * Returns the seleceted option value
 */
function getVarietySelectValue(variety: string): { label: string; value: string } {
  if (variety === DEFAULT_VARIETY) {
    return { label: '-', value: DEFAULT_VARIETY };
  }

  return { label: variety, value: variety };
}

/**
 * If the variety is the default variety and the label is the plant name then there are no
 * more label variations available
 */
function sampleLabelDisabled(currentLabel: string, plant: Plant, variety: string): boolean {
  return variety === DEFAULT_VARIETY && currentLabel === plant.name;
}

const EditPlantLabel = (): JSX.Element | null => {
  const { editPlantValues, setEditPlantValue, setEditPlantValues, plant, plantNotesTextChanged, openAddVariety, openCustomiseVarieties } =
    useContext(EditPlantContext);
  const { supplierCatalogue } = useContext(ResourceContext);

  const user = useSelector(SessionSelectors.getUser);

  if (user === null || supplierCatalogue === null) {
    return null;
  }

  const userPlantVarieties = user.plantVarieties;
  const { varietyForDefaultLabel } = user.planSettings;

  let warningText: null | JSX.Element = null;
  if (plantNotesTextChanged) {
    warningText = (
      <>
        <i className='icon-warning' style={{ fontSize: '14px' }} /> Changing the variety now will undo changes to plant notes for the current variety
      </>
    );
  }

  const plantVarietyOptions = useMemo(() => {
    return getPlantVarietyOptions(supplierCatalogue, plant.code, userPlantVarieties);
  }, [supplierCatalogue, plant.code, userPlantVarieties]);

  const varietySelectValue = useMemo(() => {
    return getVarietySelectValue(editPlantValues.values.variety);
  }, [editPlantValues.values.variety]);

  const onVarietySelectChange = (option: SingleValue<{ label: string; value: string }>) => {
    if (option !== null) {
      const newLabel = updatePlantLabel(editPlantValues.values.labelText, varietyForDefaultLabel, plant, editPlantValues.values.variety, option.value);

      setEditPlantValues(editPlantValues.setValues(['variety', { value: option.value }], ['labelText', { value: newLabel }]));
    }
  };

  return (
    <FormSection padding={12} gap={6} className='form-section-background edit-plant-label' heading='Label and Variety'>
      <FormField label='Label' htmlFor='label-input' layoutPreset={FORM_FIELD_PRESETS.ModalInput}>
        <InputContainer>
          <div className='label-controls'>
            <input
              id='label-input'
              className='label-input'
              type='text'
              value={editPlantValues.values.labelText}
              onChange={(e) => {
                setEditPlantValue('labelText', e.target.value);
              }}
            />
            <button
              disabled={sampleLabelDisabled(editPlantValues.values.labelText, plant, editPlantValues.values.variety)}
              type='button'
              aria-label='Choose Label'
              className='button button-inline cycle-label'
              title='Cycle Labels'
              onClick={() => {
                setEditPlantValue('labelText', getNextPlantLabel(editPlantValues.values.labelText, plant, editPlantValues.values.variety));
              }}
            >
              <i className='icon-arrows-cw' />
            </button>
          </div>
        </InputContainer>
      </FormField>
      <FormField label='Show Label' htmlFor='show-label-input' layoutPreset={FORM_FIELD_PRESETS.ModalInput}>
        <input
          id='show-label-input'
          type='checkbox'
          checked={editPlantValues.values.showLabel}
          onChange={(e) => {
            setEditPlantValue('showLabel', e.target.checked);
          }}
        />
      </FormField>
      <FormField label='Variety' htmlFor='variety-input' layoutPreset={FORM_FIELD_PRESETS.ModalInput} warningText={warningText}>
        <InputContainer>
          <ReactSelect
            id='variety-input'
            styles={DEFAULT_SELECT_STYLES}
            options={plantVarietyOptions}
            value={varietySelectValue}
            onChange={onVarietySelectChange}
            menuPortalTarget={document.body}
            isSearchable={plantVarietyOptions.length > 10}
          />
        </InputContainer>
      </FormField>
      <FormField layoutPreset={FORM_FIELD_PRESETS.ModalInput} mobileLayout={{ layout: 'row' }} label='' fakeLabel>
        <FormLayout>
          <button type='button' className='button button-borderless variety-button' onClick={openAddVariety}>
            Add Variety
          </button>
        </FormLayout>
        <FormLayout>
          <button type='button' className='button button-borderless variety-button' onClick={openCustomiseVarieties}>
            Customize Varieties
          </button>
        </FormLayout>
      </FormField>
    </FormSection>
  );
};

export default EditPlantLabel;
