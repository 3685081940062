import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PlannerTabTypes } from '@gi/constants';
import { TouchDragHelpModalRenderer } from '@gi/touch-introduction-modal';
import { PlannerCommandProvider } from '@gi/app-planner-desktop';
import { LocalPlantListProvider } from '@gi/plant-list';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { MobilePlannerControls } from '@gi/planner-controls';
import { ItemLockingHelpModalRenderer } from '@gi/help-modals';

import useActivePlannerTab from './util/useActivePlannerTab';
import MobilePlanMenu from './components/garden-planner/plan-menu/mobile-plan-menu';
import MobilePlanner from './components/planner-apps/planner/mobile-planner';
import MobilePlantsList from './components/planner-apps/plants-list/mobile-plants-list';
import MobilePartsList from './components/planner-apps/parts-list/mobile-parts-list';
import MobileNotes from './components/planner-apps/notes/mobile-notes';
import MobileShoppingList from './components/planner-apps/shopping/mobile-shopping-list';

import './app-planner-mobile.scss';

const AppPlannerMobile = (): JSX.Element => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const activePlannerTab = useActivePlannerTab();

  const tabContent = useMemo(() => {
    switch (activePlannerTab) {
      case PlannerTabTypes.PLANT_LIST:
        return <MobilePlantsList />;
      case PlannerTabTypes.PARTS_LIST:
        return <MobilePartsList />;
      case PlannerTabTypes.NOTES:
        return <MobileNotes />;
      case PlannerTabTypes.SHOPPING:
        return <MobileShoppingList />;
      case PlannerTabTypes.PLANNER:
      default:
        return <MobilePlanner />;
    }
  }, [activePlannerTab]);

  return (
    <LocalPlantListProvider plan={activePlan}>
      <PlannerCommandProvider>
        <TouchDragHelpModalRenderer />
        <ItemLockingHelpModalRenderer />
        <MobilePlannerControls />
        <div className='mobile-planner'>
          <MobilePlanMenu />
          {tabContent}
        </div>
      </PlannerCommandProvider>
    </LocalPlantListProvider>
  );
};

export default AppPlannerMobile;
