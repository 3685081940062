import { createContext } from 'react';

export type ElementPrinterContextType = {
  printElement: (element: HTMLElement) => void;
  isPrinting: boolean;
  openPrintWindow: () => void;
  closePrintWindow: () => void;
  printWindowOpen: boolean;
};

export const ElementPrinterContext = createContext<ElementPrinterContextType>({} as ElementPrinterContextType);
