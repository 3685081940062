import React, { forwardRef, useRef } from 'react';
import { ShoppingListProduct } from '@gi/products';

import ProductsItem from './products-item';
import ProductWindowContainer from './product-window/product-window-container';
import { useHorizontalScroll } from '../../../hooks';

import styles from './products-selector.module.css';

interface iProps {
  target: HTMLElement;
  productOptions: ShoppingListProduct[];
  onSelect: (productId: number, variantId: number) => void;
  currency: string;
}

const ProductsSelector = ({ target, productOptions, onSelect, currency }: iProps, ref): JSX.Element => {
  const scrollRef = useRef<HTMLDivElement>(null);
  useHorizontalScroll(scrollRef as React.MutableRefObject<HTMLElement>);
  return (
    <ProductWindowContainer target={target} ref={ref}>
      <div className={styles.productScrollPane} ref={scrollRef}>
        <div className={styles.productsList}>
          {productOptions.map((product) => (
            <ProductsItem key={product.productId} product={product} onClick={onSelect} currency={currency} />
          ))}
        </div>
      </div>
    </ProductWindowContainer>
  );
};

export default forwardRef(ProductsSelector);
