import PropTypes from 'prop-types';

const plantNodeInfoShape = PropTypes.shape({
  ID: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  plantCode: PropTypes.string.isRequired,
  familyID: PropTypes.number.isRequired,
  squareFoot: PropTypes.bool.isRequired,
  layerType: PropTypes.string.isRequired,
});

const plantLabelNodeInfoShape = PropTypes.shape({
  ID: PropTypes.number.isRequired,
  plantCode: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  layerType: PropTypes.string.isRequired,
});

const gardenObjectNodeInfoShape = PropTypes.shape({
  ID: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  subtype: PropTypes.string.isRequired,
  layerType: PropTypes.string.isRequired,
});

const shapeNodeInfoShape = PropTypes.shape({
  ID: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  subtype: PropTypes.string.isRequired,
  layerType: PropTypes.string.isRequired,
});

const textNodeInfoShape = PropTypes.shape({
  ID: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  layerType: PropTypes.string.isRequired,
});

const selectedItemsShape = PropTypes.arrayOf(
  PropTypes.oneOfType([plantNodeInfoShape, plantLabelNodeInfoShape, gardenObjectNodeInfoShape, shapeNodeInfoShape, textNodeInfoShape])
);

export { plantNodeInfoShape, plantLabelNodeInfoShape, gardenObjectNodeInfoShape, shapeNodeInfoShape, textNodeInfoShape, selectedItemsShape };
