import { GardenItemType } from '@gi/constants';
import { PlanValidation } from '@gi/plan';
import { Geometry } from '@gi/math';

import { SimulatedGardenItem, SimulatedGardenItemClipboardData } from './simulated-garden-item';

export type SimulatedTextClipboardData = {
  text: string;
  fontSize: number;
  colour: number;
  start: Vector2;
  end: Vector2;
  rotation: number;
  locked: boolean;
  zIndex: number;
};

export class SimulatedText extends SimulatedGardenItem {
  readonly type = GardenItemType.Text;

  #text: string;
  get text() {
    return this.#text;
  }

  #fontSize: number;
  get fontSize() {
    return this.#fontSize;
  }

  #colour: number;
  get colour() {
    return this.#colour;
  }

  #start: Vector2;
  get start() {
    return this.#start;
  }

  #end: Vector2;
  get end() {
    return this.#end;
  }

  #rotation: number;
  get rotation() {
    return this.#rotation;
  }

  get center(): Vector2 {
    return Geometry.midpoint(this.start, this.end);
  }

  get dimensions(): Dimensions {
    const diff = Geometry.getPointDelta(this.start, this.end);
    return {
      width: Math.abs(diff.x),
      height: Math.abs(diff.y),
    };
  }

  constructor(id: number, text: string, fontSize: number, color: number, start: Vector2, end: Vector2, rotation: number, locked: boolean, zIndex: number) {
    super(id, locked, zIndex);
    this.#text = text;
    this.#fontSize = fontSize;
    this.#colour = color;
    this.#start = start;
    this.#end = end;
    this.#rotation = rotation;

    this.#validate();
  }

  #setPosition(center: Vector2, dimensions: Dimensions, rotation: number) {
    this.#start = {
      x: center.x - dimensions.width / 2,
      y: center.y - dimensions.height / 2,
    };
    this.#end = {
      x: center.x + dimensions.width / 2,
      y: center.y + dimensions.height / 2,
    };
    this.#rotation = rotation;
    this.#validate();
  }

  setPositions(start: Vector2, end: Vector2, rotation: number) {
    this.#start = start;
    this.#end = end;
    this.#rotation = rotation;
    this.#validate();
  }

  setPosition(center: Vector2, dimensions: Dimensions, rotation: number) {
    this.#setPosition(center, dimensions, rotation);
    this.emitUpdates();
  }

  #setText(text: string, color: number, fontSize: number) {
    this.#text = text;
    this.#colour = color;
    this.#fontSize = fontSize;
    this.#validate();
  }

  setText(text: string, color: number, fontSize: number) {
    this.#setText(text, color, fontSize);
    this.emitUpdates();
  }

  getClipboardData(): SimulatedGardenItemClipboardData<GardenItemType.Text, SimulatedTextClipboardData> {
    return {
      type: GardenItemType.Text,
      data: {
        text: this.text,
        fontSize: this.fontSize,
        colour: this.colour,
        start: { ...this.start },
        end: { ...this.end },
        rotation: this.rotation,
        locked: this.locked,
        zIndex: this.zIndex,
      },
    };
  }

  #validate() {
    const { dimensions: size } = this;
    const { start, end, rotation, color, fontSize } = PlanValidation.validateText(
      this.center,
      size.width,
      size.height,
      this.rotation,
      this.colour,
      this.fontSize
    );

    this.#start = start;
    this.#end = end;
    this.#rotation = rotation;
    this.#colour = color;
    this.#fontSize = fontSize;
  }
}
