import React, { useCallback, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LocalSettingsActionCreators } from '@gi/local-settings';
import IntercomIcon from '@gi/intercom-icon';
import { IntercomActionCreators } from '@gi/intercom';
import { useOnClickOutside, DelayedUnmount } from '@gi/react-utils';
import { TutorialContext } from '@gi/tutorials';
import { networkConfig } from '@gi/config';
import { PlannerControlsTab } from '@gi/constants';
import { PlannerControlsContext } from '@gi/planner-controls';
import { Application, NavigationContext } from '@gi/garden-platform-navigation';

import { MobileNavActionCreators, MobileNavSelectors } from '../../store/mobile-nav-slice';

import './mobile-topbar-intercom-button.scss';

const MobileTopbarIntercomButton = (): JSX.Element => {
  const dispatch = useDispatch();
  const isHelpDropdownOpen = useSelector(MobileNavSelectors.getIsHelpDropdownOpen);
  const { activeApplication } = useContext(NavigationContext);
  const { intercomHelpButtonTutorialVisible } = useContext(TutorialContext);
  const { setActiveDrawer } = useContext(PlannerControlsContext);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const setShowDropdown = useCallback((showDropdown: boolean) => {
    dispatch(MobileNavActionCreators.setHelpDropdownOpen(showDropdown));
  }, []);

  useOnClickOutside<HTMLElement>([dropdownRef, buttonRef], () => {
    setShowDropdown(false);
  });

  const classNames = ['mobile-topbar-intercom'];
  if (!intercomHelpButtonTutorialVisible) {
    classNames.push('dismissed');
  }

  const dismiss = useCallback(() => {
    dispatch(LocalSettingsActionCreators.setHideIntercomIntroduction(true));
  }, []);

  const toggleDropdown = useCallback(() => {
    dismiss();
    setShowDropdown(!isHelpDropdownOpen);
  }, [isHelpDropdownOpen]);

  const openIntercom = useCallback(() => {
    setShowDropdown(false);
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('mobile-topbar-dropdown'));
  }, []);

  const openObjectives = useCallback(() => {
    setActiveDrawer(PlannerControlsTab.OBJECTIVES);
    setShowDropdown(false);
  }, [setActiveDrawer]);

  return (
    <div className={classNames.join(' ')}>
      <button className='mobile-topbar-intercom-button' type='button' onClick={toggleDropdown} ref={buttonRef}>
        <div>
          <IntercomIcon noBackground backgroundPadding='0.5em' />
          <span>
            Help
            <i className={`icon-down-dir ${isHelpDropdownOpen ? 'open' : ''}`} />
          </span>
        </div>
      </button>
      <DelayedUnmount isMounted={isHelpDropdownOpen} unmountDelay={200}>
        <div className={`mobile-topbar-intercom-dropdown ${isHelpDropdownOpen ? 'open' : ''}`}>
          <div className='mobile-topbar-intercom-dropdown-inner' ref={dropdownRef}>
            <div className='mobile-topbar-intercom-dropdown-inner-scroll'>
              <button type='button' onClick={openIntercom}>
                <div className='left-icon'>
                  <i className='icon-intercom' />
                </div>
                <div className='content'>
                  <span className='title'>Live Chat</span>
                  <span className='description'>Speak with our gardening experts!</span>
                </div>
              </button>
              {activeApplication === Application.GardenPlanner ? (
                <button type='button' onClick={openObjectives}>
                  <div className='left-icon'>
                    <i className='icon-mortar-board' />
                  </div>
                  <div className='content'>
                    <span className='title'>Learn to Use</span>
                    <span className='description'>Tutorials for help using the Garden Planner.</span>
                  </div>
                </button>
              ) : null}
              <a href={networkConfig.helpURL} target='_blank' rel='noreferrer'>
                <div className='left-icon'>
                  <i className='icon-help-circled' />
                </div>
                <div className='content'>
                  <span className='title'>Help Pages</span>
                  <span className='description'>Articles for help using the Garden Planner.</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </DelayedUnmount>
      <div className='intercom-intro-popup-container'>
        <div className='intercom-intro-popup'>
          <p>
            <strong>Need gardening advice, technical help or just have a question?</strong>
          </p>
          <p>Our gardening experts are here to help!</p>
          <button className='button button-primary' type='button' onClick={dismiss}>
            Got it!
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileTopbarIntercomButton;
