import React, { useContext } from 'react';

import { PlanBackgroundImageEditorContext } from '../plan-background-image-editor-context';

import styles from './styles.module.css';

const CreateBackgroundPropertiesForm = (): JSX.Element => {
  const { setShowEditImageModal } = useContext(PlanBackgroundImageEditorContext);

  return (
    <div className={styles.container}>
      <button type='button' className='button button-primary' onClick={() => setShowEditImageModal(true)}>
        Add Background Image
      </button>
    </div>
  );
};

export default CreateBackgroundPropertiesForm;
