import React from 'react';
import FormField from '../form-field';
import { FORM_FIELD_PRESETS } from '../form-field-presets';
import { DOmit } from '../types';

type iProps = DOmit<Parameters<typeof FormField>[0], 'layoutPreset'>;

/**
 * A form field that holds a single radio button.
 * For a fieldset, use FormRadioFieldset
 */
const FormRadioField = ({ ...remainingProps }: iProps): JSX.Element => {
  return <FormField layoutPreset={FORM_FIELD_PRESETS.Radio} {...remainingProps} />;
};

export default FormRadioField;
