import React from 'react';

import { ModalType } from '@gi/modal';
import ConfirmModalWithUnsavedPlans from '@gi/confirm-modal-with-unsaved-plans';

interface iProps {
  email: string;
  onCancel: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

const ConfirmChangeEmailModal = ({ email, onCancel, onConfirm, loading }: iProps): JSX.Element => {
  return (
    <ConfirmModalWithUnsavedPlans
      type={ModalType.Default}
      title='Change Email'
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmButtonText='Change Email'
      loading={loading}
    >
      <p>
        You are about to change your email to <strong>{email}</strong>.
      </p>
      <p>This will require you to verify your email address before you can continue using the Garden Planner.</p>
      <p>Do you wish to continue?</p>
    </ConfirmModalWithUnsavedPlans>
  );
};

export default ConfirmChangeEmailModal;
