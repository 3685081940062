import React, { useCallback } from 'react';

import { PlantTags } from '@gi/constants';
import { GuruPreviewPlant } from '@gi/app-guru-types';
import ClearableInput from '@gi/clearable-input';

import { useGuruPlantFilters } from '../use-plant-filters';
import ContentGridInner from '../content-grid-inner';
import SmallPreviewItem from '../../small-preview/items/small-preview-item';
import ContentGridNoResults from './content-grid-no-results';

import styles from './content-plant-grid.module.css';

interface iProps {
  id: string;
  items?: GuruPreviewPlant[];
  minItemHeight?: number;
  itemAspectRatio?: number;
  gap?: number;
}

const ContentPlantGrid = ({ id, items, minItemHeight, itemAspectRatio, gap }: iProps): JSX.Element => {
  const { filteredPlants, toggleTag, setType, filters, reset, setSearchTerm, getSearchResultMatchText } = useGuruPlantFilters(id, items ?? []);

  const createFilterInput = useCallback(
    (type: 'radio' | 'checkbox', tag: PlantTags | null, text: string, checked: boolean, onChange: (tag: PlantTags | null) => void) => {
      let classNames = '';
      if (type === 'checkbox') {
        classNames = `${styles.guruFilterCheckboxLabel} ${checked ? styles.guruFilterCheckboxLabelChecked : ''}`;
      } else {
        classNames = `${styles.guruFilterCheckboxLabel} ${checked ? styles.guruFilterCheckboxLabelChecked : ''}`;
      }

      return (
        <label className={classNames}>
          <input type={type} checked={checked} onChange={() => onChange(tag)} id={`guru-plant-filter:${tag ?? 'none'}`} className={styles.guruFilterCheckbox} />
          <span>{text}</span>
        </label>
      );
    },
    [toggleTag]
  );

  const createRadioFilter = useCallback(
    (type: PlantTags | null, name: string) => {
      return createFilterInput('radio', type, name, filters.type === type, () => setType(type));
    },
    [createFilterInput, filters]
  );

  const createCheckboxFilter = useCallback(
    (tag: PlantTags, name: string) => {
      return createFilterInput('checkbox', tag, name, filters.tags.includes(tag), () => toggleTag(tag));
    },
    [createFilterInput, filters]
  );

  return (
    <div className={styles.plantGrid}>
      <div className={styles.plantGridFiltersContainer}>
        <div className={styles.plantGridFilters}>
          <div className={`${styles.filterCollection}`}>
            <div className={styles.filterCollectionTitle}>Search:</div>
            <ClearableInput value={filters.searchTerm} onChange={(value) => setSearchTerm(value)} />
          </div>
          <div className={styles.filterCollection}>
            <div className={styles.filterCollectionTitle}>Plants that are:</div>
            <div className={styles.filterCollectionItems}>
              {createCheckboxFilter(PlantTags.FROST_TOLERANT, 'Frost Tolerant')}
              {createCheckboxFilter(PlantTags.SHADE_TOLERANT, 'Shade Tolerant')}
              {createCheckboxFilter(PlantTags.AUTUMN_PLANTING, 'Autumn Tolerant')}
              {createCheckboxFilter(PlantTags.EASY_TO_GROW, 'Easy to Grow')}
            </div>
          </div>
          <div>
            <div className={styles.filterCollection}>
              <div className={styles.filterCollectionTitle}>Plant Types:</div>
              <fieldset className={styles.filterCollectionItems}>
                {createRadioFilter(null, 'Any')}
                {createRadioFilter(PlantTags.FRUIT, 'Fruit')}
                {createRadioFilter(PlantTags.VEGETABLE, 'Vegetables')}
                {createRadioFilter(PlantTags.HERB, 'Herb')}
                {createRadioFilter(PlantTags.FLOWER, 'Flower')}
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      {filteredPlants.length > 0 ? (
        <ContentGridInner minItemHeight={minItemHeight} itemAspectRatio={itemAspectRatio} gap={gap}>
          {filteredPlants.map((item) => (
            <SmallPreviewItem key={item.content.plantCode} item={item}>
              {getSearchResultMatchText(item.content.plantCode)}
            </SmallPreviewItem>
          ))}
        </ContentGridInner>
      ) : (
        <ContentGridNoResults onResetClick={reset} />
      )}
    </div>
  );
};

export default ContentPlantGrid;
