import Plant from '@gi/plant';
import React from 'react';
import ItemContentCell from '../../../components/item-content-table/item-content-cell';
import ItemContentRow from '../../../components/item-content-table/item-content-row';
import ItemContentCellHeader from '../../../components/item-content-table/item-content-cell-header';

interface iProps {
  plant: Plant;
}

const PlantPerennialSection = ({ plant }: iProps): JSX.Element | null => {
  if (plant.otherName === '') {
    return null;
  }

  return (
    <ItemContentRow>
      <ItemContentCellHeader>Perennial</ItemContentCellHeader>
      <ItemContentCell>
        <p>{plant.perennial ? 'Yes.' : 'No.'}</p>
      </ItemContentCell>
    </ItemContentRow>
  );
};

export default PlantPerennialSection;
