import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CountrySelect from '@gi/country-select';
import FormField, { createFormValues, FormLayout, InputContainer, unresponsivePreset } from '@gi/form-responsive';
import Country, { CountryCollection } from '@gi/country';
import { ResourceContext } from '@gi/resource-provider';
import { GoogleMap, GoogleMapElement, GoogleMapMarker } from '@gi/google-map';
import { AppAccountSelectors, AppAccountActionCreators } from '@gi/app-account-slice';
import { LoadingState } from '@gi/constants';

import InfoBanner from '../components/info-banner';
import AddressLookup from './location/address-lookup';

import FindLocation from './location/find-location';

import icon from '../assets/location-marker.png';
import './user-location-form.scss';

export interface UserLocationFormValues {
  country: Country;
  longitude: number;
  latitude: number;
}

interface UserLocationFormState {
  draggingMarker: boolean;
  mapCenter: google.maps.LatLngLiteral;
  address: string;
  geocoder: any | null;
  zoom: number;
}

interface iProps {
  values: UserLocationFormValues;
  mustSetLocation: boolean;
  onCountryChange: (country: Country) => void;
  onPositionChange: (latitude: number, longitude: number) => void;
  onAddressChange?: (address: string) => void;
}

const UserLocationForm = ({ values, mustSetLocation = false, onCountryChange, onPositionChange, onAddressChange = () => {} }: iProps): JSX.Element => {
  const { countries } = useContext(ResourceContext);
  const dispatch = useDispatch();
  const addressLookup = useSelector(AppAccountSelectors.getAddressLookup);
  const map = useRef<GoogleMapElement>(null);

  const [manager, setManager] = useState(
    createFormValues<UserLocationFormState>({
      draggingMarker: { value: false },
      mapCenter: { value: { lat: values.latitude, lng: values.longitude } },
      address: { value: '' },
      geocoder: { value: null },
      zoom: { value: mustSetLocation ? values.country.googleMapsZoomLevel : 10 },
    })
  );

  const setField = <K extends keyof UserLocationFormState>(setting: K, value: UserLocationFormState[K]) => {
    setManager(manager.setValue(setting, { value }));
  };

  const setCountry = (country: Country) => {
    setManager(
      manager.setValues(
        ['mapCenter', { value: { lat: country.latitude, lng: country.longitude } }],
        ['zoom', { value: country.googleMapsZoomLevel }],
        ['address', { value: '' }]
      )
    );
    onCountryChange(country);
  };

  const lookupAddress = () => {
    dispatch(AppAccountActionCreators.lookupLocationFromAddress(`${manager.values.address} ${values.country.name}`, map.current?.geocoder));
  };

  // Callback for when the user's location is found with the Find Location button
  const handleLocationFound = (latitude: number, longitude: number) => {
    setManager(manager.setValues(['mapCenter', { value: { lat: latitude, lng: longitude } }], ['zoom', { value: 10 }]));
    onPositionChange(latitude, longitude);
  };

  // Update if there's a successful adressLookup state change
  useEffect(() => {
    if (addressLookup.status === LoadingState.SUCCESS) {
      setManager(
        manager.setValues(['mapCenter', { value: { lat: addressLookup.value.latitude, lng: addressLookup.value.longitude } }], ['zoom', { value: 10 }])
      );
      onPositionChange(addressLookup.value.latitude, addressLookup.value.longitude);
    }
  }, [addressLookup]);

  return (
    <>
      <h2>Location</h2>
      {/* Country Dropdown */}
      <FormField label='Country' desktopLayout={{ inputSize: 'full' }} htmlFor='user-country'>
        <InputContainer size='full'>
          <CountrySelect countries={countries ?? new CountryCollection()} value={values.country} onChange={(value) => setCountry(value)} id='user-country' />
        </InputContainer>
      </FormField>
      <p>Set your location by searching for an address, moving the map marker or clicking &apos;Find Location&apos; on the map.</p>
      {/* Map and map search */}
      <FormLayout layoutPreset={unresponsivePreset({ layout: 'column', xAlign: 'stretch' })} className='user-location'>
        <div className='address-lookup-field'>
          <AddressLookup
            value={manager.values.address}
            onChange={(value) => {
              setField('address', value);
              onAddressChange(value);
            }}
            onSearch={() => lookupAddress()}
            loading={addressLookup.status === LoadingState.LOADING}
            errorText={addressLookup.status === LoadingState.ERROR ? 'Could not find address' : undefined}
          />
        </div>
        <div className='user-location-map-container'>
          <GoogleMap
            ref={map}
            cameraPos={manager.values.mapCenter}
            cameraZoom={manager.values.zoom}
            onCameraChange={(center, zoom) => {
              setManager(manager.setValues(['mapCenter', { value: center }], ['zoom', { value: zoom }]));
            }}
            captureTouchEvents
          >
            <GoogleMapMarker
              position={{ lat: values.latitude, lng: values.longitude }}
              onPositionChanged={(pos) => onPositionChange(pos.lat, pos.lng)}
              icon={icon}
              draggable
            />
          </GoogleMap>
          <FindLocation onLocationFound={handleLocationFound} />
        </div>
      </FormLayout>
      {mustSetLocation && <InfoBanner type='warning'>Please set your location before continuing</InfoBanner>}
    </>
  );
};

export default UserLocationForm;
