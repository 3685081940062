/**
 * Represents a side/corner/center of something. Equivalent of ordinal compass directions (and center)
 */
export enum Anchor {
  TopLeft = 'TopLeft',
  Top = 'Top',
  TopRight = 'TopRight',
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
  BottomLeft = 'BottomLeft',
  Bottom = 'Bottom',
  BottomRight = 'BottomRight',
}

export const AnchorValues = {
  [Anchor.TopLeft]: 0,
  [Anchor.Top]: 1,
  [Anchor.TopRight]: 2,
  [Anchor.Left]: 3,
  [Anchor.Center]: 4,
  [Anchor.Right]: 5,
  [Anchor.BottomLeft]: 6,
  [Anchor.Bottom]: 7,
  [Anchor.BottomRight]: 8,
};

/**
 * Returns a vector representation of the Anchor, pointing in the direction of the anchor.
 * Values of x and y are -1, 0 or 1.
 * Vector is not normalised.
 * @param anchor The anchor to convert
 * @returns A vector
 */
export function anchorAsVector(anchor: Anchor): Vector2 {
  const anchorValue = AnchorValues[anchor];
  const x = (anchorValue % 3) - 1;
  const y = Math.floor(anchorValue / 3) - 1;
  return { x, y };
}
