import React, { useEffect, useState } from 'react';
import { LoadingState } from '@gi/constants';
import { AsyncOperation } from '@gi/utils';

import journalImageService from './journal-image-service';
import { JournalImageTransform } from './journal-image-transform';

import styles from './journal-user-image.module.css';

interface iProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  transforms?: JournalImageTransform;
}

/**
 * Makes a request for a Journal user image and displays the resulting image
 *
 * The load image request will use the current session users bearer token
 */
const JournalUserImage = ({ src, transforms, alt, ...props }: iProps): JSX.Element | null => {
  const [objURL, setObjURL] = useState<AsyncOperation<string>>({ status: LoadingState.NONE });

  useEffect(() => {
    let _objURL = '';

    setObjURL({ status: LoadingState.LOADING });
    journalImageService
      .getJournalImage(src, transforms)
      .then((blob) => {
        _objURL = window.URL.createObjectURL(blob);
        setObjURL({ status: LoadingState.SUCCESS, value: _objURL });
      })
      .catch((e) => {
        setObjURL({ status: LoadingState.ERROR, error: e });
        console.error('Error loading Journal User image');
        console.error(e);
      });

    return () => {
      console.log('Revoking ', _objURL);
      window.URL.revokeObjectURL(_objURL);
    };
  }, []);

  if (objURL.status === LoadingState.ERROR) {
    return <span className={styles.failedToLoadMessage}>Error Loading Image</span>;
  }

  if (objURL.status === LoadingState.LOADING || objURL.status === LoadingState.NONE) {
    return (
      <span className={styles.loadingSpinner}>
        <i className='icon-spinner animate-pulse' />
      </span>
    );
  }

  if (objURL.status === LoadingState.SUCCESS) {
    return <img src={objURL.value} alt={alt} {...props} />;
  }

  return null;
};

export default JournalUserImage;
