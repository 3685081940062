import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { GuruPageHeader, GuruPreviewItem } from '@gi/app-guru-types';
import { AsyncOperation } from '@gi/utils';
import { GuruDirectoryPage, GuruContentPage } from '@gi/app-guru-types/pages';
import { GuruSection } from '@gi/app-guru-types/types';
import { LoadingState } from '@gi/constants';

// Properties describing the url of a particular piece of content
export type GuruContentUrlProperties = {
  contentUUID: string;
  contentId: number;
  queryParams: Record<string, string>;
};

export type PreloadHeaderData = { header: GuruPageHeader | null };

export type AsyncPage = AsyncOperation<GuruDirectoryPage> & PreloadHeaderData;

export type AsyncGuruContent = AsyncOperation<GuruContentPage> & GuruContentUrlProperties;

export type GuruState = {
  previewItems: Record<string, AsyncOperation<GuruPreviewItem>>; // id -> AsyncOperation<GuruPreviewItem>
  pages: Record<string, AsyncPage>; // slug/id -> AsyncOperation<GuruVideoPage>
  content: Record<string, AsyncGuruContent>; // string -> AsyncOperation<GuruContentPage>
  contentIds: Record<number, string[]>; // number -> content ids
  sections: Record<string, AsyncOperation<GuruSection>>;
  relatedContentSections: Record<string, AsyncOperation<GuruSection>>;
};

const DEFAULT_STATE: GuruState = {
  previewItems: {},
  pages: {},
  content: {},
  contentIds: {},
  sections: {},
  relatedContentSections: {},
};

export const guruSlice = createSlice({
  name: 'guru',
  initialState: DEFAULT_STATE,
  reducers: {
    setBookmark: (state, action: PayloadAction<[contentId: number, bookmark: null | string]>) => {
      const [contentId, bookmark] = action.payload;
      console.log(`Set bookmark: ${contentId} - ${bookmark}`);
      if (!state.contentIds[contentId]) {
        console.error(`Received bookmark update but content with given id not found ${contentId}`);
        return;
      }

      // Update all loaded bookmarks with that content id
      for (let i = 0; i < state.contentIds[contentId].length; i++) {
        const content = state.content[state.contentIds[contentId][i]];
        if (content.status === LoadingState.SUCCESS) {
          content.value.bookmarked = bookmark;
        }
      }
    },
    setPreviewItem: (state, action: PayloadAction<[id: number, content: AsyncOperation<GuruPreviewItem>]>) => {
      const [id, previewContent] = action.payload;
      state.previewItems[id] = previewContent;
    },
    setPreviewItems: (state, action: PayloadAction<[id: number, content: AsyncOperation<GuruPreviewItem>][]>) => {
      const items = action.payload;
      for (let i = 0; i < items.length; i++) {
        const [id, previewContent] = items[i];
        state.previewItems[id] = previewContent;
      }
    },
    setPage: (state, action: PayloadAction<[composedPageId: string, page: AsyncOperation<GuruDirectoryPage>, header?: GuruPageHeader]>) => {
      const [composedPageId, page, header] = action.payload;
      state.pages[composedPageId] = {
        ...page,
        header: header ?? null,
      };
    },
    loadPage: (state, action: PayloadAction<[composedPageId: string, force: boolean, header?: GuruPageHeader]>) => {
      const [composedPageId, force, header] = action.payload;
      if (!state.pages[composedPageId] || state.pages[composedPageId].status === LoadingState.ERROR || force) {
        state.pages[composedPageId] = { status: LoadingState.NONE, header: header ?? null };
      }
    },
    updateContent: (state, action: PayloadAction<[contentId: string, content: AsyncOperation<GuruContentPage>]>) => {
      const [contentId, content] = action.payload;
      state.content[contentId].status = content.status;
      if (content.status === LoadingState.SUCCESS && state.content[contentId].status === LoadingState.SUCCESS) {
        // Ignore error generated by using TS4, no error in TS5
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.content[contentId].value = content.value;
      }
      if (content.status === LoadingState.ERROR && state.content[contentId].status === LoadingState.ERROR) {
        // Ignore error generated by using TS4, no error in TS5
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state.content[contentId].error = content.error;
      }
    },
    loadContent: (state, action: PayloadAction<[contentUUID: string, contentId: number, queryParams: Record<string, string>, force: boolean]>) => {
      const [contentUUID, contentId, queryParams, force] = action.payload;

      if (!state.content[contentUUID] || state.content[contentUUID].status === LoadingState.ERROR || force) {
        state.content[contentUUID] = {
          status: LoadingState.NONE,
          contentId,
          queryParams,
          contentUUID,
        };

        if (!state.contentIds[contentId]) {
          state.contentIds[contentId] = [];
        }
        state.contentIds[contentId].push(contentUUID);
      }
    },
    setSection: (state, action: PayloadAction<[sectionId: string, content: AsyncOperation<GuruSection>]>) => {
      const [sectionId, content] = action.payload;
      state.sections[sectionId] = content;
    },
    loadSection: (state, action: PayloadAction<[sectionId: string, force: boolean]>) => {
      const [sectionId, force] = action.payload;

      if (!state.sections[sectionId] || state.sections[sectionId].status === LoadingState.ERROR || force) {
        state.sections[sectionId] = { status: LoadingState.NONE };
      }
    },
    setSections: (state, action: PayloadAction<[sectionId: string, content: AsyncOperation<GuruSection>][]>) => {
      const items = action.payload;
      for (let i = 0; i < items.length; i++) {
        const [sectionId, content] = items[i];
        state.sections[sectionId] = content;
      }
    },
    loadSections: (state, action: PayloadAction<[sectionId: string, force: boolean][]>) => {
      const items = action.payload;
      for (let i = 0; i < items.length; i++) {
        const [sectionId, force] = items[i];

        if (!state.sections[sectionId] || state.sections[sectionId].status === LoadingState.ERROR || force) {
          state.sections[sectionId] = { status: LoadingState.NONE };
        }
      }
    },
    setRelatedContentSection: (state, action: PayloadAction<[url: string, content: AsyncOperation<GuruSection>]>) => {
      const [url, content] = action.payload;
      state.relatedContentSections[url] = content;
    },
    loadRelatedContentSection: (state, action: PayloadAction<[url: string, force: boolean]>) => {
      const [url, force] = action.payload;
      if (!state.relatedContentSections[url] || state.relatedContentSections[url].status === LoadingState.ERROR || force) {
        state.relatedContentSections[url] = { status: LoadingState.NONE };
      }
    },
    doSearch: (state, action: PayloadAction<[searchPageId: string, searchStr: string]>) => {
      const [searchPageId, searchStr] = action.payload;
      if (!state.pages[searchPageId]) {
        // Guru search page is not open
        return;
      }

      const searchDirectory = state.pages[searchPageId];

      if (searchDirectory.status !== LoadingState.SUCCESS) {
        // Guru search page has not yet loaded or loaded with an error
        return;
      }

      if (searchDirectory.value.sectionIds.length === 0) {
        // Guru search page has no content
        return;
      }

      if (searchDirectory.value.sectionIds.length > 1) {
        // Guru search page has more than one section, unknown behaviour
        console.warn('Unknown Guru behaviour, search page has more than one section');
      }

      const currentSectionIds = [...searchDirectory.value.sectionIds];
      const newSectionIds: string[] = [];

      for (let i = 0; i < currentSectionIds.length; i++) {
        const sectionId = currentSectionIds[i];
        const searchSection = searchDirectory.value.sections[sectionId];

        if (!searchSection) {
          // Search section with expected id is not present
          console.error('Search section not found');
          return;
        }

        const searchIdSplit = searchSection.id.split('?');
        let queryParams = new URLSearchParams();

        if (searchIdSplit.length === 0) {
          console.error('Invalid search section id');
          return;
        }

        if (searchIdSplit.length >= 2) {
          queryParams = new URLSearchParams(searchIdSplit[1]);
        }

        // Remove the old section
        delete searchDirectory.value.sections[sectionId];

        queryParams.set('q', searchStr);

        const newUuid = `${searchIdSplit[0]}?${queryParams.toString()}`;
        searchSection.id = newUuid;
        searchDirectory.value.sections[newUuid] = searchSection;
        newSectionIds.push(newUuid);
      }

      searchDirectory.value.sectionIds = newSectionIds;
    },
  },
});

export const guruReducer = guruSlice.reducer;
export const GuruActionCreators = guruSlice.actions;

export interface GuruRootState {
  guru: GuruState;
}

export const GuruSelectors = {
  getPreviewItems: (state: GuruRootState) => state.guru.previewItems,
  getPages: (state: GuruRootState) => state.guru.pages,
  getContent: (state: GuruRootState) => state.guru.content,
  getSections: (state: GuruRootState) => state.guru.sections,
  getRelatedContentSections: (state: GuruRootState) => state.guru.relatedContentSections,
};
