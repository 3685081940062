import React, { ReactNode, useMemo } from 'react';

import { AccordionProvider, AccordionProviderProps } from './accordion-provider';

import styles from './accordion-styles.module.css';

interface iProps
  extends Pick<AccordionProviderProps, 'animationDuration' | 'singleItem' | 'keepExpanded' | 'initiallyExpandedIds'>,
    React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
  unstyled?: boolean;
  expandedIds?: string[];
  setExpandedIds?: React.Dispatch<React.SetStateAction<string[]>>;
}

const Accordion = ({
  children,
  className,
  unstyled = false,
  animationDuration,
  singleItem,
  keepExpanded,
  initiallyExpandedIds,
  expandedIds,
  setExpandedIds,
  ...remainingProps
}: iProps): JSX.Element => {
  const classNameString = useMemo(() => {
    const classNames: string[] = [];
    if (!unstyled) {
      classNames.push(styles.accordion);
    }
    if (className) {
      classNames.push(className);
    }
    return classNames.length > 0 ? classNames.join(' ') : undefined;
  }, [className, unstyled]);

  return (
    <AccordionProvider
      animationDuration={animationDuration}
      singleItem={singleItem}
      keepExpanded={keepExpanded}
      initiallyExpandedIds={initiallyExpandedIds}
      unstyled={unstyled}
      expandedIds={expandedIds}
      setExpandedIds={setExpandedIds}
    >
      <div className={classNameString} {...remainingProps}>
        {children}
      </div>
    </AccordionProvider>
  );
};

export default Accordion;
