import { LayerType } from '@gi/constants';

/**
 * Scaling modes for Garden Objects. Controls how the user is able to manipulate a garden object.
 */
export enum GardenObjectScalingMode {
  /** Garden Object is a fixed width and height, and cannot be resized by the user */
  FIXED = 'FIXED',
  /** Garden Object can be resized in at least 1 axis */
  SCALING = 'SCALING',
  /** Garden Object can only be resized to one of its presets */
  PRESETS = 'PRESETS',
}

/**
 * Subset of the layers a Garden Object can be assigned to
 * TODO: Update this to use enums properly when LayerType gets moved to an enum
 */
export type GardenObjectLayerType = Extract<LayerType, 'STRUCTURES' | 'IRRIGATION' | 'LAYOUT'>;
