import { GardenItemType } from '@gi/constants/types/garden-item-type';
import { Plan } from './plan';
import { getFreshPlanItemId, getUpdatedZIndexLimits } from './plan-item-utils';

export type PlanText = {
  id: number;
  text: string;
  fontSize: number;
  fill: number;
  start: Vector2;
  end: Vector2;
  rotation: number;
  locked: boolean;
  zIndex: number;
};

export function createNewPlanText(
  text: string,
  fontSize: number,
  fill: number,
  start: Vector2,
  end: Vector2,
  rotation: number,
  locked: boolean,
  zIndex: number,
  id: number = -1
): PlanText {
  return {
    id, // Set initial ID to -1 so when it's added to a plan it's automatically set to the correct ID
    text,
    fontSize,
    fill,
    start,
    end,
    rotation,
    locked,
    zIndex,
  };
}

/**
 * Immutably adds new text to a plan and returns the updated plan
 */
export function addTextToPlan(plan: Plan, planText: PlanText): Plan {
  if (plan.itemTypes[planText.id]) {
    throw new Error('Attempting to add text to plan which already has an item with that id');
  }

  const [planWithUpdatedMaxItemId, validatedId] = getFreshPlanItemId(plan, planText.id);

  const updatedText = {
    ...planText,
    id: validatedId,
  };

  const updatedPlan: Plan = {
    ...planWithUpdatedMaxItemId,
    ...getUpdatedZIndexLimits(plan, planText.zIndex),
    itemTypes: {
      ...planWithUpdatedMaxItemId.itemTypes,
      [validatedId]: GardenItemType.Text,
    },
    text: {
      ...planWithUpdatedMaxItemId.text,
      [validatedId]: updatedText,
    },
    textIds: [...planWithUpdatedMaxItemId.textIds, validatedId],
  };

  return updatedPlan;
}

/**
 * Immutably removes text from a plan and returns the updated plan
 */
export function removeTextFromPlan(plan: Plan, planTextId: number): Plan {
  if (!plan.itemTypes[planTextId] || !plan.text[planTextId]) {
    console.error("Attempted to remove text from plan which doesn't exist", planTextId);
    return plan;
  }

  const newItemTypes = { ...plan.itemTypes };
  delete newItemTypes[planTextId];

  const newText = { ...plan.text };
  delete newText[planTextId];

  const newTextIds = [...plan.textIds];
  newTextIds.splice(newTextIds.indexOf(planTextId), 1);

  const updatedPlan: Plan = {
    ...plan,
    itemTypes: newItemTypes,
    text: newText,
    textIds: newTextIds,
  };

  return updatedPlan;
}

export function updatePlanText(plan: Plan, planText: PlanText): Plan {
  if (!plan.itemTypes[planText.id] || plan.itemTypes[planText.id] !== GardenItemType.Text) {
    throw new Error("Attempting to updated a text in a plan which doesn't have that text already");
  }

  return {
    ...plan,
    ...getUpdatedZIndexLimits(plan, planText.zIndex),
    text: {
      ...plan.text,
      [planText.id]: planText,
    },
  };
}
