import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import './year-picker.scss';

/**
 * @param {{
 *  year: number,
 *  activeYears?: number[],
 *  onPickYear: () => void,
 * }}
 */
const YearPicker = ({ year, activeYears = [], onPickYear }) => {
  const handleChange = (option) => {
    onPickYear(option.value);
  };

  const years = [year - 2];
  for (let i = 0; i < 4; i += 1) {
    years.push(years[i] + 1);
  }

  const options = years.map((_year) => {
    const hasActivity = activeYears.indexOf(_year) > -1;
    const className = hasActivity ? 'has-activity' : ''; // this doesn't work in react-select but oh well.
    return { label: _year, value: _year, className };
  });

  const selectedOption = options.filter((option) => option.value === year);

  return (
    <ReactSelect isSearchable={false} className='year-picker' placeholder='Select a year' options={options} value={selectedOption} onChange={handleChange} />
  );

  /*
  return (
    <div className='year-picker' onMouseLeave={() => setOpen(false)}>
      <button className='year-button' onClick={() => setOpen(!isOpen)}>{props.year}</button>
      {isOpen ? (
        <span className='year-options'>
          {years.map(year => {
            const hasActivity = props.activeYears.indexOf(year) > -1;
            const isSelectedYear = props.year === year;
            const className = `year-option ${hasActivity ? 'has-activity' : ''} ${isSelectedYear ? 'selected-year' : ''}`;
            let title = `${hasActivity ? 'There is' : 'No'} Journal activity for this plant in ${year}.`;
            if (isSelectedYear) {
              title += '\nYou are viewing this year.';
            }
            if (year === new Date().getFullYear()) {
              title += `\nIt is currently ${year}.`;
            }
            return (
              <div
                key={year}
                role='button'
                title={title}
                className={className}
                onClick={() => pickYear(year)}
              >
                <div>{year}</div>
                {year === new Date().getFullYear() ? (
                  <div className='actual-year' />
                ) : null}
              </div>
            );
          })}
        </span>
      ) : null}
    </div>
  );
  */
};

YearPicker.propTypes = {
  onPickYear: PropTypes.func.isRequired,
  activeYears: PropTypes.arrayOf(PropTypes.number),
  year: PropTypes.number.isRequired,
};

export default YearPicker;
