import React from 'react';
import { ShoppingListDisplay } from '@gi/shopping-list';
import PlannerContentContainer from './planner-content-container';

import styles from './planner-content.module.css';

interface iProps {
  children: React.ReactNode;
}
const ShoppingContent = ({ children }: iProps): JSX.Element => {
  return (
    <PlannerContentContainer className={styles.plantListContainer}>
      <div className={styles.contentScrollPane}>
        <ShoppingListDisplay />
      </div>
      {children}
    </PlannerContentContainer>
  );
};

export default ShoppingContent;
