import { createContext } from 'react';
import { LeaveContextParams } from '../modal-context/use-leave-context-confirmation';

export type ModalTabContextType = {
  activeTab: null | string;
  setActiveTab: (activeTab: string) => void;
  setLeaveContextParams: (params: LeaveContextParams | null) => void;
};

const ModalTabContext = createContext<ModalTabContextType>({} as ModalTabContextType);

export default ModalTabContext;
