import {
  RepeatingGraphicDisplayModes,
  DEFAULT_PLANT_DISPLAY_MODE,
  MAX_PLANT_SPRITE_DISPLAY_LIMIT,
  MIN_PLANT_SPRITE_DISPLAY_LIMIT,
  DEFAULT_PLANT_SPRITE_DISPLAY_LIMIT,
  RenderMode,
  MIN_DPR,
  MAX_DPR,
  DeviceDisplayMode,
  RepeatingGraphicDisplayModesType,
  MAX_CURSOR_SCALE,
  MIN_CURSOR_SCALE,
  WheelModes,
  WheelModesType,
  PlannerControlsTab,
} from '@gi/constants';

const renderModeOptions = [RenderMode.AUTO, RenderMode.CANVAS, RenderMode.WEBGL_1, RenderMode.WEBGL_2, RenderMode.WEBGL_LEGACY] as const;

export const validateWheelMode = (wheelMode: any): WheelModesType => {
  if (wheelMode === WheelModes.ZOOM || wheelMode === WheelModes.PAN || wheelMode === WheelModes.DISABLED) {
    return wheelMode;
  }

  console.error('Wheel mode set to invalid value, setting to default wheel mode:', WheelModes.ZOOM);
  return WheelModes.ZOOM;
};

export const validatePlantDisplayMode = (plantDisplayMode: any): RepeatingGraphicDisplayModesType => {
  if (
    plantDisplayMode === RepeatingGraphicDisplayModes.BLOCK ||
    plantDisplayMode === RepeatingGraphicDisplayModes.EDGE ||
    plantDisplayMode === RepeatingGraphicDisplayModes.CORNER
  ) {
    return plantDisplayMode;
  }

  console.error('Plant display mode set to invalid value, setting to default plant display mode:', DEFAULT_PLANT_DISPLAY_MODE);
  return DEFAULT_PLANT_DISPLAY_MODE;
};

export const validatePlantSpriteCountLimit = (plantSpriteCountLimit: any): number => {
  if (typeof plantSpriteCountLimit !== 'number') {
    console.error('Plant sprite count set to invalid type, setting to', DEFAULT_PLANT_SPRITE_DISPLAY_LIMIT);
    return DEFAULT_PLANT_SPRITE_DISPLAY_LIMIT;
  }

  if (Number.isNaN(plantSpriteCountLimit)) {
    console.error('Plant sprite count is NaN, setting to', DEFAULT_PLANT_SPRITE_DISPLAY_LIMIT);
    return DEFAULT_PLANT_SPRITE_DISPLAY_LIMIT;
  }

  if (plantSpriteCountLimit > MAX_PLANT_SPRITE_DISPLAY_LIMIT) {
    console.error('Plant sprite count set above maximum, setting to', DEFAULT_PLANT_SPRITE_DISPLAY_LIMIT);
    return MAX_PLANT_SPRITE_DISPLAY_LIMIT;
  }

  if (plantSpriteCountLimit < MIN_PLANT_SPRITE_DISPLAY_LIMIT) {
    console.error('Plant sprite count set below minimum, setting to', MIN_PLANT_SPRITE_DISPLAY_LIMIT);
    return MIN_PLANT_SPRITE_DISPLAY_LIMIT;
  }

  return Math.round(plantSpriteCountLimit);
};

export const validateRenderMode = (renderMode: any): RenderMode => {
  if (renderModeOptions.includes(renderMode)) {
    return renderMode;
  }

  return RenderMode.AUTO;
};

export const validateBool = (bool: any): boolean => {
  return !!bool;
};

export const validateDevicePixelRatio = (dpr: any): number => {
  if (typeof dpr !== 'number') {
    return 1;
  }

  if (Number.isNaN(dpr)) {
    return 1;
  }

  return Math.max(Math.min(dpr, MAX_DPR), MIN_DPR);
};

export const validateDeviceDisplayMode = (mode: string): DeviceDisplayMode => {
  if (Object.values(DeviceDisplayMode).includes(mode as DeviceDisplayMode)) {
    return DeviceDisplayMode[mode];
  }
  return DeviceDisplayMode.AUTO;
};

export const validateCustomCursorScale = (scale: number): number => {
  if (Number.isNaN(scale)) {
    return 1;
  }

  return Math.max(Math.min(scale, MAX_CURSOR_SCALE), MIN_CURSOR_SCALE);
};

export const validatePlannerControlsTab = (mode: string): PlannerControlsTab => {
  if (Object.values(PlannerControlsTab).includes(mode as PlannerControlsTab)) {
    return PlannerControlsTab[mode];
  }
  return PlannerControlsTab.OBJECTIVES;
};

export const validateTextQuality = (quality: number): number => {
  if (Number.isNaN(quality)) {
    return 4;
  }
  return Math.max(quality, 0.5);
};
