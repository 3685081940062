import React from 'react';

interface iProps {
  isFullscreen?: boolean;
  className?: string;
}

const FullscreenIcon = ({ isFullscreen, className }: iProps): JSX.Element => {
  const classNames = [isFullscreen ? 'icon-compress' : 'icon-expand'];
  if (className) {
    classNames.push(className);
  }

  return <i className={classNames.join(' ')} />;
};

export default FullscreenIcon;
