import React from 'react';

import GuruSpinner from '../guru-spinner/guru-spinner';
import GuruHeaderContentPortal from '../../header/guru-header-content-portal';
import EmptyLoadingHeader from '../../header/header-components/empty-loading-header';

import styles from './content-page.module.css';

const ContentPageLoading = (): JSX.Element => {
  return (
    <div className={styles.loadingContainer}>
      <GuruHeaderContentPortal>
        <EmptyLoadingHeader />
      </GuruHeaderContentPortal>
      <GuruSpinner />
    </div>
  );
};

export default ContentPageLoading;
