import React from 'react';

import SidebarState from '../../sidebar-position';
import { SidebarItemGroup, SidebarItem } from '../sidebar-item';
import NavbarLogo from './navbar-logo';

import './logo-bar.scss';

interface iProps {
  openSidebar: () => void;
  closeSidebar: () => void;
  sidebarState: SidebarState;
}

const LogoBar = ({ openSidebar, closeSidebar, sidebarState }: iProps) => {
  return (
    <SidebarItemGroup>
      <SidebarItem className='logo-bar'>
        <NavbarLogo sidebarState={sidebarState} openSidebar={openSidebar} closeSidebar={closeSidebar} />
      </SidebarItem>
    </SidebarItemGroup>
  );
};

export default LogoBar;
