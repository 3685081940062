import React from 'react';

import styles from './buttons.module.css';

interface iProps extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  type: 'button' | 'submit' | 'reset';
  className?: string;
}

const ShoppingListButton = ({ children, type, className = '', ...props }: iProps): JSX.Element => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button className={`${styles.shoppingListButton} ${className}`} {...props} type={type}>
      {children}
    </button>
  );
};

export default ShoppingListButton;
