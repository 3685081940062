import { createContext } from 'react';

export type MobileNotificationContextType = {
  registerNotificationPortal: (element: HTMLElement) => () => void;
  currentPortal: HTMLElement | null;
};

const MobileNotificationContext = createContext<MobileNotificationContextType>({} as MobileNotificationContextType);

export default MobileNotificationContext;
