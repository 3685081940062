import { PERENNIAL } from '@gi/constants';
import { Filters } from '@gi/filters';
import Plant from '@gi/plant';

import { CompanionRelationType } from './companion-type-dropdown';

/**
 * Calculates how many plant filters are currently active
 * @param filters The currently active plant filters
 * @param sfgMode If SFG mode is enabled
 * @param tropicalClimate If the user is in a tropical climate
 * @returns The amount of filters that are currently active
 */
export const getActiveFilterCount = (filters: Filters<Plant, any>, sfgMode: boolean, tropicalClimate: boolean) => {
  let activeFilters = 0;

  activeFilters += filters.filters.favourites.inputs.enabled ? 1 : 0;
  activeFilters += filters.filters.type.inputs.type !== null ? 1 : 0;
  if (filters.filters.type.inputs.type !== PERENNIAL) {
    activeFilters += filters.filters.perennial.inputs.enabled ? 0 : 1;
  }

  activeFilters += filters.filters.cropFamily.inputs.familyID !== null ? 1 : 0;
  activeFilters += sfgMode && filters.filters.sfg.inputs.enabled ? 1 : 0;

  const plantingCalendarInputs = filters.filters.plantingDates.inputs;
  activeFilters += plantingCalendarInputs.sowMonth !== null ? 1 : 0;
  activeFilters += plantingCalendarInputs.plantMonth !== null ? 1 : 0;
  activeFilters += plantingCalendarInputs.harvestMonth !== null ? 1 : 0;

  activeFilters += filters.filters.easyToGrow.inputs.enabled ? 1 : 0;
  activeFilters += filters.filters.frostTolerant.inputs.enabled ? 1 : 0;
  activeFilters += filters.filters.partialShade.inputs.enabled ? 1 : 0;
  activeFilters += filters.filters.autumn.inputs.enabled ? 1 : 0;

  activeFilters += filters.filters.tropical.inputs.enabled !== tropicalClimate ? 1 : 0;

  if (filters.filters.companion.inputs.enabled) {
    if (filters.filters.companion.inputs.onlyHelped || filters.filters.companion.inputs.onlyHelper) {
      activeFilters += 1;
    }
  }

  return activeFilters;
};

/**
 * Converts the inputs of the comapnion filter to an enum.
 * @param inputs The inputs from the companion filter
 * @returns An enum representing its state
 */
export const convertCompanionFilterToEnum = (inputs): CompanionRelationType => {
  if (inputs.onlyHelper && inputs.onlyHelped) {
    return CompanionRelationType.ONLY_MUTUAL;
  }
  if (inputs.onlyHelper) {
    return CompanionRelationType.ONLY_HELPED;
  }
  if (inputs.onlyHelped) {
    return CompanionRelationType.ONLY_HELPER;
  }
  return CompanionRelationType.ALL;
};
