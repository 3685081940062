import React, { useContext } from 'react';
import { networkConfig } from '@gi/config';
import { AppContext } from '@gi/app-provider';

import DashboardLogo from './dashboard-items/logo/dashboard-logo';
import GardenPlannerDashboardItem from './dashboard-items/garden-planner/garden-planner-dashboard-item';
import JournalDashboardItem from './dashboard-items/journal/journal-dashboard-item';
import WeatherDashboardItem from './dashboard-items/weather/weather-dashboard-item';
import ArticleDashboardItem from './dashboard-items/articles/article-dashboard-item';
import SupportDashboardItem from './dashboard-items/support/support-dashboard-item';
import AccountDashboardItem from './dashboard-items/account/account-dashboard-item';

import './app-dashboard-desktop.scss';

const AppDashboardDesktop = (): JSX.Element => {
  const { runtimeConfig } = useContext(AppContext);
  const style = {
    backgroundImage: `url(${networkConfig.assetBaseURL}header-images/${runtimeConfig.backgroundImage.src})`,
  };

  return (
    <div className='dashboard' style={style}>
      <div className='dashboard-inner'>
        <DashboardLogo />
        <div className='dashboard-items'>
          <GardenPlannerDashboardItem />
          <JournalDashboardItem />
          <WeatherDashboardItem />
          {runtimeConfig.showDashboardArticles ? <ArticleDashboardItem /> : null}
          <SupportDashboardItem />
          <AccountDashboardItem />
        </div>
      </div>
    </div>
  );
};

export default AppDashboardDesktop;
