import React, { CSSProperties, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { AppContext } from '@gi/app-provider';
import { networkConfig } from '@gi/config';

import { MobileNavActionCreators } from '../../store/mobile-nav-slice';

import './mobile-nav-logo.scss';

const MobileNavigationLogo = (): JSX.Element => {
  const { runtimeConfig } = useContext(AppContext);
  const dispatch = useDispatch();

  const closeMenu = () => {
    dispatch(MobileNavActionCreators.setIsOpen(false));
  };

  const logoSrc = `${networkConfig.assetBaseURL}${runtimeConfig.logo.src}`;
  const backgroundSrc = `${networkConfig.assetBaseURL}header-images/${runtimeConfig.backgroundImage.blurSrc}`;

  // TODO: Fix background image positioning
  const backgroundStyle: CSSProperties = {
    backgroundImage: `url(${backgroundSrc})`,
  };

  return (
    <div className='mobile-navigation-logo' style={backgroundStyle}>
      <div className='mobile-navigation-logo-cover'>
        <img className='logo-image' src={logoSrc} style={{ height: runtimeConfig.logo.height }} alt='logo' />
        <div className='mobile-navigation-close'>
          <button type='button' onClick={closeMenu} aria-label='Close Menu'>
            <i className='icon-left-open-1' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileNavigationLogo;
