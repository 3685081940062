import React, { useContext } from 'react';
import { FeatureGate } from '@gi/features';
import { TooltipKey } from '@gi/tooltip';

import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';
import ControlButtonGroup from '../common/control-button-group';
import MenuButton from '../common/menu-button';
import { PlannerCommandContext } from '../../../planner-command-provider';
import SaveMenuButton from '../common/save-menu-button';
import MenuIcon from '../common/menu-icon';

const PlanControls = (): JSX.Element => {
  const { newPlan, openPlan, printPlan, publishPlan, generatePlanImage, transferPlan } = useContext(PlannerCommandContext);
  return (
    <ControlGroup>
      <ControlRow>
        <ControlButtonGroup>
          <MenuButton id='new-plan' onClick={newPlan} tooltipKey={TooltipKey.NewPlan}>
            <MenuIcon icon='icon-doc' />
          </MenuButton>
          <MenuButton id='open-plan' onClick={openPlan} tooltipKey={TooltipKey.OpenPlan}>
            <MenuIcon icon='icon-folder-open-empty' />
          </MenuButton>
          <SaveMenuButton />
        </ControlButtonGroup>
        <ControlButtonGroup>
          <MenuButton
            id='print-plan'
            disabled={printPlan === null}
            onClick={() => {
              if (printPlan) printPlan();
            }}
            tooltipKey={TooltipKey.PrintPlan}
          >
            <MenuIcon icon='icon-print' />
          </MenuButton>
          <MenuButton
            id='publish-plan'
            disabled={publishPlan === null}
            onClick={() => {
              if (publishPlan) publishPlan();
            }}
            tooltipKey={TooltipKey.PublishPlan}
          >
            <MenuIcon icon='icon-publish' />
          </MenuButton>
          <FeatureGate featureId='TRANSFER_PLAN'>
            <MenuButton
              id='transfer-plan'
              disabled={transferPlan === null}
              onClick={() => {
                if (transferPlan) transferPlan();
              }}
              tooltipKey={TooltipKey.TransferPlan}
            >
              <MenuIcon icon='icon-mail' />
            </MenuButton>
          </FeatureGate>
          <MenuButton
            id='generateImage'
            disabled={generatePlanImage === null}
            onClick={() => {
              if (generatePlanImage) generatePlanImage();
            }}
            tooltipKey={TooltipKey.PlanImage}
          >
            <MenuIcon icon='icon-picture' />
          </MenuButton>
        </ControlButtonGroup>
      </ControlRow>
    </ControlGroup>
  );
};

export default PlanControls;
