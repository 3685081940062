import React, { useMemo } from 'react';

import { AsyncResource } from '@gi/react-utils';
import { LoadingState } from '@gi/constants';
import { getLoadingStateIcon } from '@gi/utils';

import './loading-screen-async-resource.scss';

interface iProps {
  resource: AsyncResource<any, any>;
  label: string;
}

const LoadingScreenAsyncResource = ({ resource, label }: iProps): JSX.Element => {
  const retryButton = useMemo(() => {
    if (resource.status !== LoadingState.ERROR && resource.status !== LoadingState.NONE) {
      return null;
    }

    return (
      <span className='retry'>
        <button className='button button-inline' type='button' onClick={() => resource.load()}>
          Retry
        </button>
      </span>
    );
  }, [resource]);

  const errorMessage = useMemo(() => {
    if (resource.status !== LoadingState.ERROR) {
      return null;
    }

    return (
      <div className='loading-screen-async-resource-error'>
        {'Error while loading: '}
        {'clientMessage' in resource.error && resource.error.clientMessage ? (resource.error.clientMessage as string) : 'Unknown error'}
      </div>
    );
  }, [resource]);

  return (
    <div className='loading-screen-async-resource'>
      <div className='loading-screen-async-resource-info'>
        <span className='label'>{label}</span>
        <span className='dots' />
        {retryButton}
        <span className='status'>{getLoadingStateIcon(resource.status)}</span>
      </div>
      {errorMessage}
    </div>
  );
};

export default LoadingScreenAsyncResource;
