import React, { useMemo } from 'react';
import { TutorialStepContent } from '../../tutorial';
import BaseContent from './base-content';
import HTMLContent from './html-content';
import TutorialVideo from '../video/tutorial-video';
import ImageContent from './image-content';

import styles from '../tutorial-display.module.css';

interface iProps {
  content: TutorialStepContent | TutorialStepContent[];
  tutorialUuid: string;
  tutorialStepIndex: number;
  tutorialContentIndex: number;
}

const TutorialContent = ({ content, tutorialUuid, tutorialStepIndex, tutorialContentIndex }: iProps): JSX.Element | JSX.Element[] | null => {
  const style = useMemo(() => {
    const computedStyles: React.CSSProperties = {};

    if (typeof content === 'string') {
      return {};
    }

    if ('width' in content) {
      computedStyles.width = content.width;
    }

    return computedStyles;
  }, [content]);

  if (Array.isArray(content)) {
    return content.map((_content, i) => (
      <TutorialContent key={i} content={_content} tutorialUuid={tutorialUuid} tutorialStepIndex={tutorialStepIndex} tutorialContentIndex={i} />
    ));
  }

  if (typeof content === 'string') {
    return (
      <div className={styles.tutorialContent} style={style}>
        <BaseContent content={content} />
      </div>
    );
  }

  if ('text' in content) {
    return (
      <div className={styles.tutorialContent} style={style}>
        <BaseContent content={content.text} />
      </div>
    );
  }

  if ('html' in content) {
    return (
      <div className={styles.tutorialContent} style={style}>
        <HTMLContent content={content.html} />
      </div>
    );
  }

  if ('video' in content) {
    return (
      <div className={styles.tutorialContent} style={style}>
        <TutorialVideo
          key={content.video}
          video={content.video}
          autoplay={content.autoplay}
          mimeType={content.mimeType}
          tutorialUuid={tutorialUuid}
          tutorialStepIndex={tutorialStepIndex}
          tutorialContentIndex={tutorialContentIndex}
        />
      </div>
    );
  }

  if ('image' in content) {
    return (
      <div className={styles.tutorialContent} style={style}>
        <ImageContent key={content.image} src={content.image} alt={content.alt} />
      </div>
    );
  }

  return null;
};

export default TutorialContent;
