import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModalWithUnsavedPlans from '@gi/confirm-modal-with-unsaved-plans';

import { FlagRestartActions, FlagRestartSelectors } from './flag-restart-slice';

const FlagRestartDisplay = (): JSX.Element | null => {
  const { flagRestart } = useSelector(FlagRestartSelectors.getFlagRestart);
  const dispatch = useDispatch();

  if (!flagRestart) {
    return null;
  }

  return (
    <ConfirmModalWithUnsavedPlans
      title='Refresh Required'
      onCancel={() => {
        dispatch(FlagRestartActions.clearFlagRestart());
      }}
      cancelButtonText='Later'
      onConfirm={() => {
        window.location.reload();
      }}
      confirmButtonText='Refresh Now'
    >
      <p>In order for some changes to take effect, the garden glanner must be refreshed.</p>
      <p>This will close all your open plans, and reload the page.</p>
      <p>If you choose not to refresh, your changes will take effect next time you load the garden planner.</p>
    </ConfirmModalWithUnsavedPlans>
  );
};

export default FlagRestartDisplay;
