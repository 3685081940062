import PlantFamily from './plant-family';

class PlantFamilyCollection {
  plantFamilies: Record<number, PlantFamily>;

  constructor() {
    this.plantFamilies = {};
  }

  add(plantFamily: PlantFamily): void {
    this.plantFamilies[plantFamily.ID] = plantFamily;
  }

  has(plantFamilyID: number): boolean {
    if (this.plantFamilies[plantFamilyID]) {
      return true;
    }

    return false;
  }

  get(plantFamilyID: number): PlantFamily | null {
    if (!this.has(plantFamilyID)) {
      return null;
    }

    return this.plantFamilies[plantFamilyID];
  }

  asArray(): PlantFamily[] {
    return Object.values(this.plantFamilies);
  }

  *[Symbol.iterator](): Iterator<PlantFamily> {
    const keys = Object.keys(this.plantFamilies);
    for (let i = 0; i < keys.length; i++) {
      yield this.plantFamilies[keys[i]];
    }
  }
}

export default PlantFamilyCollection;
