import React, { ReactNode } from 'react';
import ImageCaption from '../image/image-caption';

import styles from './lead-image.module.css';

interface iProps {
  src: string;
  alt: string;
  className?: string;
  minHeight?: number;
  source?: ReactNode;
  sourceUrl?: string;
  attribution?: ReactNode;
  caption?: ReactNode;
}

const LeadImage = ({ src, alt, className = '', minHeight = 0, source, sourceUrl, attribution, caption }: iProps) => {
  return (
    <div style={{ minHeight }}>
      <figure className={styles.leadImageFigure}>
        <img className={`${styles.leadImage} ${className}`} alt={alt} src={src} />
        <ImageCaption caption={caption} attribution={attribution} source={source} sourceUrl={sourceUrl} />
      </figure>
    </div>
  );
};

export default LeadImage;
