import { SimulatedPlan } from '../simulation/simulated-plan';
import { SimulationFactory } from '../synced-plan/simulation-factory';
import { CanvasPlan } from './canvas-plan';

class CanvasPlans {
  readonly #canvasPlans: Record<number, CanvasPlan>;
  readonly #simulationFactory: SimulationFactory;

  constructor(simulationFactory: SimulationFactory) {
    this.#canvasPlans = {};
    this.#simulationFactory = simulationFactory;
  }

  getOpenPlans(): number[] {
    return Object.keys(this.#canvasPlans).map((stringKey) => parseInt(stringKey, 10));
  }

  openCanvasPlan(simulatedPlan: SimulatedPlan) {
    this.#canvasPlans[simulatedPlan.id] = this.#canvasPlans[simulatedPlan.id] ?? new CanvasPlan(simulatedPlan, this.#simulationFactory);
    return this.#canvasPlans[simulatedPlan.id];
  }

  closePlan(id: number) {
    this.#canvasPlans[id].destroy();
    delete this.#canvasPlans[id];
  }

  hasCanvasPlan(id: number): boolean {
    return !!this.#canvasPlans[id];
  }

  getCanvasPlan(id: number): CanvasPlan {
    return this.#canvasPlans[id];
  }
}

export default CanvasPlans;
