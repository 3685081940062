import React from 'react';
import { HelpModal, ModalPaneSection } from '@gi/modal';

interface iProps {
  closeModal: () => void;
}

const MonthSelectorInfoModal = ({ closeModal }: iProps): JSX.Element => {
  return (
    <HelpModal headerText='Viewing Planting Gaps in the Garden Planner' closeModal={closeModal} style={{ maxWidth: 520 }}>
      <ModalPaneSection>
        <p>
          The month selector allows you to view how your plan will look month by month, so you can see where gaps will appear and plan to fill them with another
          crop.
        </p>
        <p>First set the In-Ground Dates each plant will be growing in the ground:</p>
        <ol>
          <li>Double-click the plant on your plan to pull up the Edit Plant box.</li>
          <li>Select the &apos;Specify in-ground dates&apos; box.</li>
          <li>Choose the months the plant will be growing in the ground using the drop-down menus.</li>
          <li>Click Done.</li>
        </ol>
        <p>
          You can then use the month selector to view your plan month by month. When you select a month, only plants that are marked as being in the ground for
          that month or for all of the growing season will be shown on the plan. Any plants that are marked as being in the ground over winter will be
          automatically copied over when you create a Follow-on Plan for next year.
        </p>
      </ModalPaneSection>
    </HelpModal>
  );
};

export default MonthSelectorInfoModal;
