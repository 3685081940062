import React, { useEffect, useMemo, useState } from 'react';

import { GuruPreviewItem } from '@gi/app-guru-types';
import { createContentURL } from '@gi/app-guru-common';

import Pips from './components/hero-preview-pips';
import HeroPreviewItem from './hero-preview-item';
import GuruHeaderContentPortal from '../../header/guru-header-content-portal';

import styles from './hero-preview.module.css';

const CYCLE_TIMEOUT_TIME = 10000;

interface iProps {
  items: GuruPreviewItem[];
}

const HeroPreview = ({ items }: iProps): JSX.Element => {
  const [index, setIndex] = useState<number>(0);

  const i = index % items.length;

  const link = useMemo(() => {
    return createContentURL(items[i].type, items[i].slug);
  }, [items[i].type, items[i].slug]);

  // Automatic cycling, timer will reset when manually cycled
  useEffect(() => {
    const cycleNext = window.setTimeout(() => {
      setIndex(index + 1);
    }, CYCLE_TIMEOUT_TIME);

    return () => {
      window.clearTimeout(cycleNext);
    };
  }, [items, index]);

  const { secondaryThumbnailUrl } = items[i];

  return (
    <div className={styles.heroContent}>
      <GuruHeaderContentPortal background={secondaryThumbnailUrl ? secondaryThumbnailUrl : 0} />
      <div className={styles.heroContentInner}>
        <HeroPreviewItem previewItem={items[i]} link={link} />
        <Pips index={i} setIndex={setIndex} count={items.length} />
      </div>
    </div>
  );
};

export default HeroPreview;
