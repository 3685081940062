import React from 'react';
import { Tooltip, TooltipContent, TooltipTrigger } from '@gi/tooltip';
import styles from './quantity-selector.module.css';

interface iProps {
  unit: string;
  variantQuantity: number;
  inputQuantity: number;
  recommendedQuantityMultiplier: number;
}

const RecommendedQuantityTooltip = ({ unit, variantQuantity, inputQuantity, recommendedQuantityMultiplier }: iProps): null | JSX.Element => {
  const recommendedQuantity = (inputQuantity * recommendedQuantityMultiplier) / variantQuantity;

  let content;

  if (variantQuantity === 0) {
    content = (
      <>
        <div>
          We don&apos;t have the quantity of <span className={styles.lowercaseUnit}>{unit}</span> contained in this product so we are unable to set a default
          purchase quantity.
        </div>
        <div>If this seems to be in error please contact our customer support.</div>
      </>
    );
  } else {
    content = (
      <>
        <div>
          Our recommended quantity accounts for variables such as:
          <ul>
            <li>Non-germinating seeds</li>
            <li>Thinning of plants</li>
            <li>Variable number of seeds in a packet, especially for smaller seeds.</li>
          </ul>
        </div>
        <div>This is how the quantity is calculated</div>
        <div className={styles.sumDisplay}>
          {recommendedQuantityMultiplier !== 1 ? (
            <>
              <div className={styles.sum}>
                <div className={styles.sumValues}>
                  <div>
                    <span className={styles.sumDescriptor}>Number of Plants to Grow</span> <span className={styles.value}>{inputQuantity}</span>
                  </div>
                  <div>
                    <span className={styles.sumDescriptor}>Extra-{unit} Multiplier</span> <span className={styles.value}>{recommendedQuantityMultiplier}</span>
                  </div>
                </div>
                <div className={styles.operator}>&times;</div>
              </div>
              <div className={styles.divider}>
                <i className='icon-angle-down' />
              </div>
            </>
          ) : null}
          <div className={styles.sum}>
            <div className={styles.sumValues}>
              <div>
                <span className={styles.sumDescriptor}>Recommended Number of {unit}</span>{' '}
                <span className={styles.value}>
                  <strong>{inputQuantity * recommendedQuantityMultiplier}</strong>
                </span>
              </div>
              <div>
                <span className={styles.sumDescriptor}>Number of {unit} in Packet</span> <span className={styles.value}>{variantQuantity}</span>
              </div>
            </div>
            <div className={styles.operator}>&divide;</div>
          </div>
          <div className={styles.divider}>
            <i className='icon-angle-down' />
          </div>
          <div className={`${styles.sum} ${styles.solution}`}>
            <div className={styles.sumValues}>
              <div>
                Number of Packets Required{' '}
                <span className={styles.value}>
                  <strong>{Math.ceil(recommendedQuantity)}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <Tooltip placement='top'>
      <TooltipTrigger>
        <i className='icon-help-circled' />
      </TooltipTrigger>
      <TooltipContent className={styles.tooltip}>{content}</TooltipContent>
    </Tooltip>
  );
};

export default RecommendedQuantityTooltip;
