import React, { ReactNode, createContext, useMemo } from 'react';

interface PlanDiagramSVGContextType {
  scale: number;
  offset: Vector2;
}

export const PlanDiagramSVGContext = createContext<PlanDiagramSVGContextType>({} as PlanDiagramSVGContextType);

interface iProps {
  bounds: { minX: number; minY: number; maxX: number; maxY: number };
  padding?: number;
  displayWidth?: number;
  displayHeight?: number;
  children?: ReactNode;
}

const PlanDiagramSVG = ({ bounds, padding = 16, displayWidth = 300, displayHeight = 300, children }: iProps): JSX.Element => {
  // Calculate a scale value such that the bounds fit perfectly within the display area (accounting for padding)
  const scale = useMemo(() => {
    // Space of the bounds
    const width = bounds.maxX - bounds.minX;
    const height = bounds.maxY - bounds.minY;

    // Usable visible space
    const outerWidth = displayWidth - padding * 2;
    const outerHeight = displayHeight - padding * 2;

    return Math.min(outerWidth / width, outerHeight / height);
  }, [bounds, displayWidth, displayHeight, padding]);

  // Calculate an offset such that the scaled bounds appear centred in the SVG
  const offset = useMemo(() => {
    // Scaled bounds, such that the bounds fit within the display area
    const boundsWidth = (bounds.maxX - bounds.minX) * scale;
    const boundsHeight = (bounds.maxY - bounds.minY) * scale;

    // Difference between display area and scaled bounds
    const spareSpaceX = displayWidth - padding * 2 - boundsWidth;
    const spareSpaceY = displayHeight - padding * 2 - boundsHeight;

    // Set offset such that minX, minY are at the top left of the display area,
    //  adding the space space to center the content
    const offsetX = padding - bounds.minX * scale + spareSpaceX / 2;
    const offsetY = padding - bounds.minY * scale + spareSpaceY / 2;

    return { x: offsetX, y: offsetY };
  }, [bounds, scale, displayWidth, displayHeight, padding]);

  const value = useMemo<PlanDiagramSVGContextType>(
    () => ({
      scale,
      offset,
    }),
    [scale, offset]
  );

  return (
    <PlanDiagramSVGContext.Provider value={value}>
      <svg viewBox={`0 0 ${displayWidth} ${displayHeight}`} width={displayWidth} height={displayHeight}>
        {children}
      </svg>
    </PlanDiagramSVGContext.Provider>
  );
};

export default PlanDiagramSVG;
