import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingState } from '@gi/constants';

import { ObjectivesActionCreators, ObjectivesSelectors } from '../../slice/objectives-slice';

import styles from './overview-styles.module.css';

const ObjectiveSyncStatusCard = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const completedObjectivesLoadState = useSelector(ObjectivesSelectors.getCompletedObjectivesLoadState);
  const completedObjectives = useSelector(ObjectivesSelectors.getObjectiveCompletionData);

  const [hasSeenError, setHasSeenError] = useState<boolean>(false);

  // Get a list of all completed objectives that haven't yet saved (loading/error)
  const unsavedObjectives = useMemo(() => {
    const ids = Object.keys(completedObjectives);
    return ids.map((id) => completedObjectives[id]).filter((objective) => objective.syncState !== LoadingState.SUCCESS);
  }, [completedObjectives]);

  // Get a list of just those which have errored
  const erroredObjectives = useMemo(() => {
    return unsavedObjectives.filter((objective) => objective.syncState === LoadingState.ERROR);
  }, [unsavedObjectives]);

  // Keep track of if we've seen an error message, as we can't determine that based on loading state after clicking retry
  useEffect(() => {
    if (completedObjectivesLoadState === LoadingState.ERROR || erroredObjectives.length > 0) {
      setHasSeenError(true);
    } else if (completedObjectivesLoadState === LoadingState.SUCCESS && unsavedObjectives.length === 0) {
      setHasSeenError(false);
    }
  }, [completedObjectivesLoadState, unsavedObjectives]);

  // Show loading spinner if there are any active requests
  const loading = useMemo(() => {
    return completedObjectivesLoadState === LoadingState.LOADING || unsavedObjectives.some((obj) => obj.syncState === LoadingState.LOADING);
  }, [completedObjectivesLoadState, unsavedObjectives]);

  // Retry loading the completed objectives if failed, and sync any unsaved completed objectives back to server
  const retry = useCallback(() => {
    if (completedObjectivesLoadState === LoadingState.ERROR) {
      dispatch(ObjectivesActionCreators.loadCompletedObjectives());
    }
    dispatch(ObjectivesActionCreators.saveUnsavedCompletedObjectives());
  }, [completedObjectivesLoadState]);

  if (!hasSeenError) {
    return null;
  }

  return (
    <div className={styles.syncCard}>
      <p>Failed to synchronise your progress.</p>
      <div className={styles.syncCardButtons}>
        <button type='button' className={`button button-secondary ${styles.retryButton}`} onClick={retry} disabled={loading}>
          {loading ? <i className='icon-spinner animate-pulse' /> : null} Retry
        </button>
      </div>
    </div>
  );
};

export default ObjectiveSyncStatusCard;
