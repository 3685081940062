import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import MobileNotificationContext from './notification-context';

interface NotificationPortalDefinition {
  id: number;
  element: HTMLElement;
}

interface iProps {
  children: ReactNode;
}

const MobileNotificationProvider = ({ children }: iProps): JSX.Element => {
  const [notificationPortals, setNotificationPortals] = useState<NotificationPortalDefinition[]>([]);
  const counter = useRef(0);

  /**
   * Registers an element as a portal for notifications.
   * Returns an unregister function, which removes the element as a potential portal.
   */
  const registerNotificationPortal = useCallback((element: HTMLElement) => {
    const newNotificationPortal: NotificationPortalDefinition = {
      id: counter.current,
      element,
    };

    setNotificationPortals((portals) => [...portals, newNotificationPortal]);
    counter.current += 1;

    const unregister = () => {
      setNotificationPortals((portals) => portals.filter((portal) => portal.id !== newNotificationPortal.id));
    };

    return unregister;
  }, []);

  /**
   * Works out which portal should be used.
   * Prefers the most recently-registered portal, like a stack.
   */
  const currentPortal = useMemo(() => {
    if (notificationPortals.length === 0) {
      return null;
    }

    for (let i = notificationPortals.length - 1; i >= 0; i--) {
      const notificationPortal = notificationPortals[i];
      if (notificationPortal && notificationPortal.element) {
        return notificationPortal.element;
      }
    }

    return null;
  }, [notificationPortals]);

  /**
   * Memoize everything
   */
  const value = useMemo(
    () => ({
      registerNotificationPortal,
      currentPortal,
    }),
    [registerNotificationPortal, currentPortal]
  );

  return <MobileNotificationContext.Provider value={value}>{children}</MobileNotificationContext.Provider>;
};

export default MobileNotificationProvider;
