/**
 * Represents the possible sides of a rectangle.
 * Propbably useful for anchoring.
 */
enum Side {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export default Side;
