import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { MD5 } from 'crypto-js';

import { User, UserGeneralPlanSettings, UserUnitsSettings } from '@gi/user';
import { StringValidators } from '@gi/validators';
import { SessionActionCreators } from '@gi/react-session';
import { LoadingState } from '@gi/constants';

import FrostDates, { FrostDateSettings } from './services/frost-dates-service';
import GoogleLocationService from './services/google-location-service';

import { AppAccountActions } from './account-slice';
import { DisplayableError, UserEmailSettings, UserLocationFrostDatesSettings } from './types';
import { referralCodeService } from './services/referral-code-service';

export const saveUserUnitsSettings = (user: User, newSettings: UserUnitsSettings) => {
  const action: ThunkAction<void, any, unknown, AnyAction> = (dispatch) => {
    dispatch(AppAccountActions.setSavingGeneralSettings({ status: LoadingState.LOADING }));
    const updatedUser: User = {
      ...user,
      settings: {
        ...user.settings,
        units: { ...newSettings },
      },
    };

    dispatch(SessionActionCreators.saveUser(updatedUser));
  };
  return action;
};

export const saveUserPlanSettings = (user: User, newSettings: UserGeneralPlanSettings) => {
  const action: ThunkAction<void, any, unknown, AnyAction> = (dispatch) => {
    dispatch(AppAccountActions.setSavingPlanSettings({ status: LoadingState.LOADING }));
    const updatedUser: User = {
      ...user,
      settings: {
        ...user.settings,
        ...newSettings,
      },
    };

    dispatch(SessionActionCreators.saveUser(updatedUser));
  };
  return action;
};

export const changeUserPassword = (user: User, newPassword: string) => {
  const action: ThunkAction<void, any, unknown, AnyAction> = (dispatch) => {
    dispatch(AppAccountActions.setChangingPassword({ status: LoadingState.LOADING }));

    const updatedUser: User = {
      ...user,
      password: MD5(newPassword).toString(),
      isTemporaryPassword: false,
    };

    dispatch(SessionActionCreators.saveUser(updatedUser));
  };
  return action;
};

export const saveUserEmailSettings = (user: User, newSettings: UserEmailSettings) => {
  const action: ThunkAction<void, any, unknown, AnyAction> = (dispatch) => {
    const { firstName, email, ...remainging } = newSettings;
    const changingEmail = email !== user.email;

    dispatch(
      AppAccountActions.setSavingEmailSettings({
        status: LoadingState.LOADING,
        value: { shouldRedirect: changingEmail },
      })
    );

    let updatedUser: User = {
      ...user,
      firstName,
      misc: {
        ...user.misc,
        ...remainging,
      },
    };

    if (changingEmail) {
      if (!StringValidators.isEmail()(email)) {
        throw new Error('Invalid email address');
      }
      updatedUser = {
        ...updatedUser,
        subscription: {
          ...updatedUser.subscription,
          emailConfirmed: false,
        },
        emailConfirmed: false,
        email,
      };
    }

    dispatch(SessionActionCreators.saveUser(updatedUser));
  };
  return action;
};

export const lookupLocationFromAddress = (address: string, geocoder?: google.maps.Geocoder) => {
  const action: ThunkAction<void, any, unknown, AnyAction> = (dispatch) => {
    if (!geocoder) {
      dispatch(
        AppAccountActions.setAddressLookup({
          status: LoadingState.ERROR,
          error: new Error('No geocoder supplied'),
        })
      );
      return;
    }
    dispatch(AppAccountActions.setAddressLookup({ status: LoadingState.LOADING }));
    GoogleLocationService.getLocationFromAddress(address, geocoder)
      .then((location) => {
        dispatch(
          AppAccountActions.setAddressLookup({
            status: LoadingState.SUCCESS,
            value: location,
          })
        );
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          AppAccountActions.setAddressLookup({
            status: LoadingState.ERROR,
            error: e,
          })
        );
      });
  };
  return action;
};

export const saveLocationFrostDateSettings = (user: User, newSettings: UserLocationFrostDatesSettings, autoFrostDates: boolean) => {
  const action: ThunkAction<void, any, unknown, AnyAction> = (dispatch) => {
    const isChangingCountry = user.countryCode !== newSettings.country.code;

    dispatch(AppAccountActions.setSavingLocationFrostDates({ status: LoadingState.LOADING }));

    new Promise<FrostDateSettings>((resolve, reject) => {
      if (newSettings.country.useRegionalPlantData) {
        if (!newSettings.region) {
          reject(new DisplayableError('A valid region has not been set.'));
          return;
        }
        FrostDates.getRegionalFrostSettings(newSettings.country, newSettings.latitude, newSettings.longitude, newSettings.region)
          .then((frostSettings) => resolve(frostSettings))
          .catch((e) => {
            reject(e);
          });
      } else if (autoFrostDates) {
        if (newSettings.country.canLookupFrostDates) {
          FrostDates.getAutomaticFrostSettings(newSettings.country, newSettings.latitude, newSettings.longitude)
            .then((frostSettings) => {
              resolve(frostSettings);
            })
            .catch((e) => {
              reject(e);
            });
        } else {
          resolve(FrostDates.getCountryDefaults(newSettings.country));
        }
      } else {
        if (newSettings.firstFrost === null) {
          reject(new DisplayableError('First frost date not set'));
          return;
        }
        if (newSettings.lastFrost === null) {
          reject(new DisplayableError('Last frost date not set'));
          return;
        }
        resolve(FrostDates.getManualFrostSettings(newSettings.noFrost, newSettings.firstFrost, newSettings.lastFrost, newSettings.splitSeason));
      }
    })
      .then((frostSettings) => {
        if (newSettings.firstFrost === null) {
          throw new DisplayableError('First frost date not set');
        }
        if (newSettings.lastFrost === null) {
          throw new DisplayableError('Last frost date not set');
        }
        if (newSettings.firstFrost < newSettings.lastFrost) {
          throw new DisplayableError('Invalid frost date');
        }
        if (newSettings.firstFrost < 0 || newSettings.firstFrost > 366) {
          throw new DisplayableError('First frost date must be between 0 and 366');
        }
        if (newSettings.lastFrost < 0 || newSettings.lastFrost > 366) {
          throw new DisplayableError('Last frost date must be between 0 and 366');
        }
        dispatch(
          AppAccountActions.setSavingLocationFrostDates({
            status: LoadingState.LOADING,
            value: {
              frostSettings,
              shouldReload: isChangingCountry,
            },
          })
        );
        const updatedUser: User = {
          ...user,
          countryCode: newSettings.country.code ?? '',
          settings: {
            ...user.settings,
            location: {
              ...user.settings.location,
              latitude: newSettings.latitude,
              longitude: newSettings.longitude,
              northernHemisphere: newSettings.country.northernHemisphere,
              regionID: newSettings.region?.regionID ?? 0,
              splitSeason: frostSettings.splitSeason,
            },
            frostDates: {
              ...user.settings.frostDates,
              first: frostSettings.firstFrostDay === 366 ? 365 : (frostSettings.firstFrostDay ?? 365),
              last: frostSettings.lastFrostDay ?? 0,
              noFrost: frostSettings.noFrost,
              set: true,
            },
            data: {
              // Set country codes to null when we change country, the response from the server will
              // Populate the field with the default value again but the resource handles providing the default
              // value while this is set to null
              articlesCountryCode: isChangingCountry ? null : user.settings.data.articlesCountryCode,
              pestArtifactCode: isChangingCountry ? null : user.settings.data.pestArtifactCode,
              pestPredictionCountryCode: isChangingCountry ? null : user.settings.data.pestPredictionCountryCode,
              userArtifactCode: isChangingCountry ? null : user.settings.data.userArtifactCode,
            },
          },
        };

        dispatch(SessionActionCreators.saveUser(updatedUser));
      })
      .catch((e) => {
        dispatch(AppAccountActions.setSavingLocationFrostDates({ status: LoadingState.ERROR, error: e }));
      });
  };
  return action;
};

export const loadUserReferralCode = () => {
  const action: ThunkAction<void, any, unknown, AnyAction> = (dispatch) => {
    dispatch(AppAccountActions.setReferralCode({ status: LoadingState.LOADING }));
    referralCodeService
      .getReferralCode()
      .then((referralCode) => {
        dispatch(AppAccountActions.setReferralCode({ status: LoadingState.SUCCESS, value: referralCode }));
      })
      .catch((error) => {
        dispatch(AppAccountActions.setReferralCode({ status: LoadingState.ERROR, error }));
      });
  };
  return action;
};
