import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import { Palettes, SwatchTypes } from '@gi/palette';
import TextureSwatch from './texture-swatch.tsx';
import getPositionWithoutOverlapping from './utils.ts';
import ColourInput from './colour-input.tsx';
import './texture-styles.scss';

const swatchesEqual = (swatchA, swatchB) => {
  if (swatchA === null || swatchB === null) {
    return false;
  }

  return swatchA.type === swatchB.type && swatchA.value === swatchB.value;
};

class TexturePicker extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      styles: { visibility: 'hidden' },
      highlightedSwatch: null,
      canHighlight: true,
    };

    this.picker = React.createRef();
  }

  componentDidMount() {
    window.requestAnimationFrame(this.positionPickerRelativeToButton.bind(this));
  }

  onColourChange = (colour) => {
    this.props.onLiveColourChange({
      type: SwatchTypes.COLOR,
      value: colour,
    });
  };

  getCaption() {
    if (this.state.highlightedSwatch !== null) {
      return this.state.highlightedSwatch.type === SwatchTypes.COLOR ? this.state.highlightedSwatch.value : '';
    }

    if (this.props.selected !== null) {
      return this.props.selected.type === SwatchTypes.COLOR ? this.props.selected.value : '';
    }

    return '';
  }

  setHighlight = (highlightedSwatch) => {
    if (this.state.canHighlight) {
      this.setState({ highlightedSwatch });
    }
  };

  getSelectedSwatch() {
    if (this.props.selected === null || !this.props.palettes.hasSwatch(this.props.selected.type, this.props.selected.value)) {
      return null;
    }

    return this.props.palettes.getSwatch(this.props.selected.type, this.props.selected.value);
  }

  disableHighlighting = () => {
    this.setState({
      canHighlight: false,
    });
  };

  enableHighlighting = () => {
    this.setState({
      canHighlight: true,
    });
  };

  clearHighlight = () => {
    this.setHighlight(null);
  };

  /**
   * Sets state with a top and left position such that the picker will fit on screen nicely
   * relative to the button and taking into account screen width.
   * Called from componentDidMount.
   */
  positionPickerRelativeToButton() {
    this.setState((state) => {
      const styles = { ...state.styles };
      const buttonRect = this.props.buttonRect;
      const pickerRect = this.picker.current.getBoundingClientRect();

      const { top, left, right } = getPositionWithoutOverlapping(
        window.innerWidth,
        buttonRect.top + this.props.topOffset,
        buttonRect.left + this.props.leftOffset,
        pickerRect.width,
        pickerRect.height
      );

      styles.top = top === undefined ? 'auto' : `${top}px`;
      styles.left = left === undefined ? 'auto' : `${left}px`;
      styles.right = right === undefined ? 'auto' : `${right}px`;
      styles.visibility = 'visible';

      return { styles };
    });
  }

  renderSwatchGroup = (swatchGroup, palette, index) => {
    return (
      <div key={index} className='swatch-group'>
        {swatchGroup.map((swatch, index2) => (
          <TextureSwatch
            key={index2}
            swatch={swatch}
            onClick={this.props.onSwatchSelect}
            onHighlight={this.setHighlight}
            highlighted={swatch === this.state.highlightedSwatch}
            selected={swatchesEqual(swatch, this.getSelectedSwatch())}
            swatchesPerRow={palette.swatchesPerRow}
          />
        ))}
      </div>
    );
  };

  renderPalette = (palette) => {
    return (
      <div key={palette.name} className='palette'>
        <h2>{palette.name}</h2>
        <div onMouseLeave={this.clearHighlight}>{palette.swatches.map((swatchGroup, index) => this.renderSwatchGroup(swatchGroup, palette, index))}</div>
      </div>
    );
  };

  render() {
    return (
      <div className='texture-picker' style={this.state.styles} ref={this.picker}>
        <div className='choice-display'>
          <ColourInput
            onSubmit={this.props.onClosePicker}
            onChange={this.onColourChange}
            onOpenSystemDialog={this.disableHighlighting}
            onCloseSystemDialog={this.enableHighlighting}
            value={this.getCaption()}
          />
        </div>
        {this.props.palettes.getPalettes().map(this.renderPalette)}
      </div>
    );
  }
}

TexturePicker.propTypes = {
  palettes: PropTypes.instanceOf(Palettes).isRequired,
  buttonRect: PropTypes.shape({
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
  }).isRequired,
  topOffset: PropTypes.number,
  leftOffset: PropTypes.number,
  onClosePicker: PropTypes.func.isRequired,
  onSwatchSelect: PropTypes.func,
  onLiveColourChange: PropTypes.func,
  selected: PropTypes.shape({
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

TexturePicker.defaultProps = {
  topOffset: 20,
  leftOffset: 20,
  onSwatchSelect: () => {},
  onLiveColourChange: () => {},
  selected: null,
};

export default TexturePicker;
