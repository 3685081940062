import React from 'react';

import { MobilePlantList } from '@gi/plant-list';

import PlannerAppContainer from '../planner-app-container';
import PlannerAppScrollPane from '../planner-app-scroll-pane';

/**
 * NOTE: Reliant on LocalPlantListProvider, which has been moved outside to allow interfacing with PlannerCommandProvider
 */
const MobilePlantsList = (): JSX.Element => {
  return (
    <PlannerAppContainer>
      <PlannerAppScrollPane>
        <MobilePlantList />
      </PlannerAppScrollPane>
    </PlannerAppContainer>
  );
};

export default MobilePlantsList;
