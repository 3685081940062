import { RequestStatus, Requests, RequestsUtils } from '@gi/request';

interface IRequestsSelectorsState {
  requests: Requests;
}

export const getRequests = (state: IRequestsSelectorsState) => state.requests;

export const userSaveOrLoad = (userID: number) => (state: IRequestsSelectorsState) => {
  return (
    RequestsUtils.getStatus(state.requests, `SAVE_USER_${userID}`) === RequestStatus.IN_PROGRESS ||
    RequestsUtils.getStatus(state.requests, `LOAD_USER_${userID}`) === RequestStatus.IN_PROGRESS
  );
};
