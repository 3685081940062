import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { InspectableClassData } from '../../types';
import { hasOwner } from '../../utils/asserts';
import InteractableComponent, { InteractableComponentCallbacks } from '../interactable/interactable-component';
import DoubleClickableComponentContext from './double-clickable-component-context';

/**
 * Double Clickable Component
 *  Handles double-click events via the InteractableComponent. Events can be generically listened
 *  for from within the context.
 */
class DoubleClickableComponent extends NodeComponent {
  type = 'DoubleClickableComponent';

  /**
   * Set to true to prevent double-clicks being passed up to the context.
   */
  preventEmittingEvent: boolean = false;
  /**
   * Callback to run when the node is double-clicked.
   * By default, the event will be passed up to the context, where an event can be listened for.
   */
  onDoubleClick?: () => void = undefined;

  #cleanUpListeners: (() => void) | null;

  constructor() {
    super();

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasOwner(this);
    const interactableComponent = this.owner.components.get(InteractableComponent);

    if (!interactableComponent) {
      console.error('DoubleClickableComponent requires an InteractableComponent be attached to the Node');
      return;
    }

    interactableComponent.addListener('onDoubleClick', this.#onDoubleClick);

    this.#cleanUpListeners = () => {
      interactableComponent.removeListener('onDoubleClick', this.#onDoubleClick);
    };
  };

  #onBeforeUnbind = () => {
    this.#cleanUpListeners?.();
    this.#cleanUpListeners = null;
  };

  #onDoubleClick: InteractableComponentCallbacks['onDoubleClick'] = (event, interaction, controls) => {
    hasOwner(this);

    if (event.button !== 0) {
      return;
    }

    controls.stopPropagation();

    if (this.onDoubleClick) {
      this.onDoubleClick();
    }

    if (!this.preventEmittingEvent) {
      this.owner.getContext(DoubleClickableComponentContext).wasDoubleClicked(this.owner);
    }
  };

  inspectorData: InspectableClassData<this> = [];
}

export default DoubleClickableComponent;
