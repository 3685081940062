export enum KeyboardEventType {
  KeyDown = 'KeyDown',
  KeyChange = 'KeyChange',
  KeyUp = 'KeyUp',
}

export type KeyboardEventData = {
  type: KeyboardEventType;
  key: string;
  isKeyDown: boolean;
  keysDown: Readonly<Record<string, boolean>>;
  target: HTMLElement | null;
};

type KeyboardEventListener = {
  id: number;
  type: KeyboardEventType;
  keys: (string | string[])[];
  callback: (event: KeyboardEventData) => void;
  isDown: boolean;
};

class KeyboardTracker {
  #EVENT_LISTENER_ID: number = 0;
  #keysDown: Record<string, boolean> = {};
  #eventListeners: Record<number, KeyboardEventListener> = {};
  #eventListenersByKey: Record<string, Record<number, KeyboardEventListener>> = {};

  constructor() {
    window.addEventListener('keydown', this.#onKeyDown);
    window.addEventListener('keyup', this.#onKeyUp);
  }

  #onKeyDown = (event: KeyboardEvent) => {
    if (typeof event.key !== 'string' || event.key === '') {
      return;
    }
    const key = event.key.toLowerCase();
    const target = event.target instanceof HTMLElement ? event.target : null;
    this.#trackKeyDown(key, target);
  };

  #onKeyUp = (event: KeyboardEvent) => {
    if (typeof event.key !== 'string' || event.key === '') {
      return;
    }
    const key = event.key.toLowerCase();
    const target = event.target instanceof HTMLElement ? event.target : null;
    this.#trackKeyUp(key, target);
  };

  #trackKeyDown(key: string, target: HTMLElement | null) {
    // If the key is already registered as down, do nothing.
    if (this.#keysDown[key]) {
      return;
    }
    this.#keysDown[key] = true;
    this.#runKeyChangeEvent(key, true, target);
    this.#runKeyDownEvent(key, target);
  }

  #trackKeyUp(key: string, target: HTMLElement | null) {
    // If the key is already registered as up, do nothing.
    if (!this.#keysDown[key]) {
      return;
    }
    delete this.#keysDown[key];
    this.#runKeyChangeEvent(key, false, target);
    this.#runKeyUpEvents(key, target);
  }

  #getListenersForKey(key: string) {
    const listeners = this.#eventListenersByKey[key];
    if (listeners) {
      return Object.values(listeners);
    }
    return [];
  }

  #runKeyDownEvent(key: string, target: HTMLElement | null) {
    const listeners = this.#getListenersForKey(key);
    listeners.forEach((listener) => {
      // If key combo is already registered as down, we don't need to fire events.
      if (listener.isDown) {
        return;
      }
      listener.isDown = this.isKeyComboDown(listener.keys);
      if (listener.isDown && listener.type === KeyboardEventType.KeyDown) {
        listener.callback({
          type: KeyboardEventType.KeyDown,
          key,
          isKeyDown: true,
          keysDown: this.#keysDown,
          target,
        });
      }
    });
  }

  #runKeyUpEvents(key: string, target: HTMLElement | null) {
    const listeners = this.#getListenersForKey(key);
    listeners.forEach((listener) => {
      // If key combo is already registered as up, we don't need to fire events.
      if (!listener.isDown) {
        return;
      }
      listener.isDown = this.isKeyComboDown(listener.keys);
      if (!listener.isDown && listener.type === KeyboardEventType.KeyUp) {
        listener.callback({
          type: KeyboardEventType.KeyUp,
          key,
          isKeyDown: false,
          keysDown: this.#keysDown,
          target,
        });
      }
    });
  }

  #runKeyChangeEvent(key: string, isKeyDown: boolean, target: HTMLElement | null) {
    const listeners = this.#getListenersForKey(key);
    listeners.forEach((listener) => {
      if (listener.type !== KeyboardEventType.KeyChange) {
        return;
      }
      listener.callback({
        type: KeyboardEventType.KeyDown,
        key,
        isKeyDown,
        keysDown: this.#keysDown,
        target,
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  #getUniqueKeys(keys: (string | string[])[]): string[] {
    const uniqueKeys = new Set<string>();
    keys.forEach((keyGroup) => {
      if (typeof keyGroup === 'string') {
        uniqueKeys.add(keyGroup);
      } else {
        keyGroup.forEach((key) => uniqueKeys.add(key));
      }
    });
    return [...uniqueKeys.values()];
  }

  /**
   * Checks if the given key is down.
   * @param key The name of the key
   * @returns True if the key is down
   */
  isKeyDown(key: string): boolean {
    return this.#keysDown[key.toLocaleLowerCase()];
  }

  /**
   * Checks if at least 1 combination of the given keys is currently down.
   * @param keys The key combination to check. Inner groups are OR'd, outer group is AND'd. e.g `[['shift', 'control'], 'a']` = `('shift' | 'control') & 'a'`
   * @returns True if a valid subset of the keys is down.
   */
  isKeyComboDown(keys: (string | string[])[]): boolean {
    const isMissingKey = keys.some((keyGroup) => {
      if (typeof keyGroup === 'string') {
        if (!this.isKeyDown(keyGroup)) {
          return true;
        }
      } else if (!keyGroup.some((key) => this.isKeyDown(key))) {
        return true;
      }

      return false;
    });

    return !isMissingKey;
  }

  /**
   * Adds an event listener for a given combination of keys.
   * @param type The keyboard event to listen for
   * @param keys The keys to trigger it. Inner groups are OR'd, outer group is AND'd. e.g `[['shift', 'control'], 'a']` = `('shift' | 'control') & 'a'`
   * @param callback The callback to run when the event fires
   * @returns The id of the listener. Use with `removeEventListener`.
   */
  addEventListener(type: KeyboardEventType, keys: (string | string[])[], callback: (event: KeyboardEventData) => void): number {
    const listener: KeyboardEventListener = {
      keys,
      type,
      callback,
      id: this.#EVENT_LISTENER_ID++,
      isDown: this.isKeyComboDown(keys),
    };

    this.#eventListeners[listener.id] = listener;
    this.#getUniqueKeys(keys).forEach((key) => {
      this.#eventListenersByKey[key] = this.#eventListenersByKey[key] ?? {};
      this.#eventListenersByKey[key][listener.id] = listener;
    });

    // Run KeyDown/KeyChange events immediately if the key is already down.
    if (listener.isDown && (listener.type === KeyboardEventType.KeyDown || listener.type === KeyboardEventType.KeyChange)) {
      listener.callback({
        type: KeyboardEventType.KeyDown,
        key: '',
        isKeyDown: true,
        keysDown: this.#keysDown,
        target: null,
      });
    }

    return listener.id;
  }

  /**
   * Removes an event listener by it's ID.
   * @param id The ID of the event listener to remove
   */
  removeEventListener(id: number): void {
    const listener = this.#eventListeners[id];

    if (listener) {
      delete this.#eventListeners[id];

      const uniqueKeys = this.#getUniqueKeys(listener.keys);
      uniqueKeys.forEach((key) => {
        const keyListeners = this.#eventListenersByKey[key];

        if (keyListeners && keyListeners[id]) {
          delete keyListeners[id];
        }
      });
    }
  }

  destroy() {
    window.removeEventListener('keydown', this.#onKeyDown);
    window.removeEventListener('keyup', this.#onKeyUp);
  }
}

export default KeyboardTracker;
