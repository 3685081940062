import React, { useContext, useMemo } from 'react';

import { PlanBackgroundImage, PlanBackgroundImageUtils } from '@gi/plan';
import { ImageWithPlaceholder, InputRemoteImage } from '@gi/image-editor';
import { FormSection } from '@gi/form-responsive';

import { PlanBackgroundImageEditorContext } from '../../plan-background-image-editor-context';

import styles from '../styles.module.css';

interface iProps {
  backgroundImage: PlanBackgroundImage;
}

const ImagePreviewForm = ({ backgroundImage }: iProps): JSX.Element => {
  const { removeBackgroundImage, setShowEditImageModal } = useContext(PlanBackgroundImageEditorContext);

  const image = useMemo<InputRemoteImage>(() => {
    return { imageSrc: PlanBackgroundImageUtils.getImageURL(backgroundImage.src.id, backgroundImage.src.crop) };
  }, [backgroundImage.src]);

  return (
    <FormSection heading='Image' padding={6} gap={6} className='form-section-background'>
      <ImageWithPlaceholder image={image} alt='Preview of background image' />
      <div className={styles.imagePreviewButtons}>
        <button type='button' onClick={() => setShowEditImageModal(true)} className='button button-secondary'>
          Change
        </button>
        <button type='button' onClick={removeBackgroundImage} className='button button-warning'>
          Remove
        </button>
      </div>
    </FormSection>
  );
};

export default ImagePreviewForm;
