export interface PlanSize {
  width: number;
  height: number;
  x: number;
  y: number;
}

/** Calculates a bounding box that contains all of the plan definitions given. Used to provide the min/max props to PlanDiagramSVG */
export function getBoundingBoxOfPlans(...plans: PlanSize[]): { minX: number; minY: number; maxX: number; maxY: number } {
  let minX = 0;
  let minY = 0;
  let maxX = 0;
  let maxY = 0;
  for (let i = 0; i < plans.length; i++) {
    const { x, y, width, height } = plans[i];
    if (x < minX) {
      minX = x;
    }
    if (y < minY) {
      minY = y;
    }
    if (x + width > maxX) {
      maxX = x + width;
    }
    if (y + height > maxY) {
      maxY = y + height;
    }
  }
  return { minX, minY, maxX, maxY };
}
