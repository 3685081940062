import React, { ReactNode } from 'react';

import './mobile-topbar-title.scss';

interface iProps {
  children: ReactNode;
  className?: string;
}

const MobileTopbarTitle = ({ children, className }: iProps): JSX.Element => {
  const classNames = ['mobile-topbar-title'];
  if (className) {
    classNames.push(className);
  }

  return <span className={classNames.join(' ')}>{children}</span>;
};

export default MobileTopbarTitle;
