/**
 * @typedef {string} GardenObjectSubtype
 */
/**
 * Enum for the different subtypes of garden obejcts
 *
 * @readonly
 * @enum {GardenObjectSubtype}
 */
const GardenObjectSubtypes = {
  IRRIGATION: 'IRRIGATION',
  STRUCTURE: 'STRUCTURE',
  LAYOUT: 'LAYOUT',
};

export default GardenObjectSubtypes;
