import React from 'react';
import { useSelector } from 'react-redux';

import ObjectivesPaneLoadingGate from './objectives-pane-loading-gate';
import ObjectiveGroupListDisplay from '../objective-group-list-display';
import ObjectivesPaneAdvancedSection from './objectives-pane-advanced-section';
import ObjectiveHelpCard from '../cards/objective-help-card';
import ObjectiveSyncStatusCard from '../cards/objective-sync-status-card';
import ObjectivesPaneOverallProgress from './objectives-pane-overall-progress';
import useObjectiveGroups from '../../hooks/use-objective-groups';
import { ObjectivesSelectors } from '../../slice/objectives-slice';

import styles from './objectives-pane.module.css';

interface iProps {
  className?: string;
  onLaunchHelp?: () => void; // Callback to call whenever any help-related button is clicked
}

const ObjectivesPane = ({ className, onLaunchHelp }: iProps): JSX.Element => {
  const settings = useSelector(ObjectivesSelectors.getSettings);

  const { basicGroups, advancedGroups } = useObjectiveGroups();

  const classNames: string[] = [styles.objectivesPane];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      <ObjectivesPaneLoadingGate>
        <div className={styles.objectivesPaneScroll}>
          {settings.showHelpSection ? (
            <ObjectiveHelpCard
              title={settings.helpSectionTitle}
              description={settings.helpSectionDescription}
              hideChatButton={!settings.showHelpSectionLiveChatButton}
              hideVideoGuideButton={!settings.showHelpSectionVideoGuideButton}
              videoGuideButtonText={settings.videoGuideButtonText}
              videoGuideTutorialName={settings.videoGuideButtonTutorialName}
              onLaunchHelp={onLaunchHelp}
            />
          ) : null}
          <ObjectiveSyncStatusCard />
          <ObjectiveGroupListDisplay groupIds={basicGroups.map((group) => group.id)} onLaunchHelp={onLaunchHelp} minimised={false} />
          <ObjectivesPaneAdvancedSection groupIds={advancedGroups.map((group) => group.id)} onLaunchHelp={onLaunchHelp} minimised={false} />
        </div>
        {settings.showOverallProgress ? (
          <ObjectivesPaneOverallProgress groups={basicGroups} title={settings.overallProgressTitle} description={settings.overallProgressDescription} />
        ) : null}
      </ObjectivesPaneLoadingGate>
    </div>
  );
};

export default ObjectivesPane;
