import React from 'react';
import Plant from '@gi/plant';

interface iSquareFootProps {
  plant: Plant;
}

const PlantSquareFootSection = ({ plant }: iSquareFootProps): JSX.Element => {
  return (
    <tr className='plan-table-row'>
      <th className='plan-table-row-title'>Square Foot Planting</th>
      <td>
        {plant.canBeSquareFootPlant ? (
          <p>
            {plant.squareFootPlantCount} plant{plant.squareFootPlantCount > 1 ? 's' : ''} per square foot.
          </p>
        ) : (
          <p>Not recommended for square foot gardening.</p>
        )}
      </td>
    </tr>
  );
};

export default PlantSquareFootSection;
