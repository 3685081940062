import { AnyAction } from 'redux';
import PublishActionType from './publish-action-types';
import PublishPlanStage from './publish-plan-stages';

export interface PublishPlanStoreState {
  stage: PublishPlanStage;
  errorReason: string | null;
  progress: number;
}

const INITIAL_STATE: PublishPlanStoreState = {
  stage: PublishPlanStage.NOT_IN_PROGRESS,
  errorReason: null,
  progress: 0,
};

const publishReducer = (state: PublishPlanStoreState = INITIAL_STATE, action: AnyAction): PublishPlanStoreState => {
  switch (action.type) {
    case PublishActionType.SET_STAGE:
      return {
        ...state,
        stage: action.stage,
      };
    case PublishActionType.SET_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };
    case PublishActionType.SET_ERROR_REASON:
      return {
        ...state,
        errorReason: action.errorReason,
      };
    case PublishActionType.PUBLISH_FAILURE:
      return {
        ...state,
        errorReason: action.reason,
      };
    case PublishActionType.PUBLISH_SUCCESS:
      return {
        ...state,
        errorReason: null,
      };
    default:
      return state;
  }
};

export default publishReducer;
