import React, { ReactNode } from 'react';
import './page-row.scss';

interface iProps {
  children: ReactNode;
  className?: string;
  noContainer?: boolean;
  centerContent?: boolean;
}

const PageRow = ({ children, className, noContainer = false, centerContent = false }: iProps): JSX.Element => {
  const classNames = ['page-row'];
  if (centerContent) {
    classNames.push('center-content');
  }
  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{noContainer ? children : <div className='page-row-container'>{children}</div>}</div>;
};

export default PageRow;
