import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import * as CanvasSelectors from '../redux-components/canvas-selectors';
import * as CanvasActionCreators from '../redux-components/canvas-action-creators';
import ClosePlanModal from './close-plan-modal';

const PlanCloseModalRenderer = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const confirmClosePlans = useSelector(CanvasSelectors.getConfirmClosePlans);

  if (confirmClosePlans.length === 0) {
    return null;
  }

  return (
    <>
      {confirmClosePlans.map((planId) => {
        return (
          <ClosePlanModal
            key={planId}
            closeModal={() => dispatch(CanvasActionCreators.removeFromCloseConfirmation(planId))}
            onConfirm={() => dispatch(batchActions([CanvasActionCreators.closePlan(planId), CanvasActionCreators.removeFromCloseConfirmation(planId)]))}
          />
        );
      })}
    </>
  );
};

export default PlanCloseModalRenderer;
