/**
 * @namespace web-garden-planner/@gi-react-garden-canvas
 * @module @gi/react-garden-canvas
 */
import ReactGardenCanvas from './source/react-garden-canvas';

import CanvasActionTypes from './source/redux-components/canvas-action-types';
import canvasReducer from './source/redux-components/canvas-reducer';
import type { CanvasInteractionState, iPlanDataError, CanvasReducerState } from './source/redux-components/canvas-reducer';

import attachStoreListeners from './source/store-listeners/attach-store-listeners';

import GardenCanvasProvider, { GardenCanvasContext } from './source/garden-canvas-context/garden-canvas-context';
import withGardenCanvas from './source/garden-canvas-context/with-garden-canvas';

import GardenCanvasOverlay from './source/garden-canvas-overlay/garden-canvas-loading-screen';
import GardenCanvasStateOverlay from './source/garden-canvas-state-overlay/garden-canvas-state-overlay';
import CropRotationSelector from './source/garden-canvas-controls/crop-rotation-selector/crop-rotation-selector';
import PlanImageGenerator from './source/garden-canvas-controls/plan-image-generator/plan-image-generator';
import MonthSelector from './source/garden-canvas-controls/month-selector/month-selector';
import LayerSelector from './source/garden-canvas-controls/layer-selector/layer-selector';

import GardenCanvasController from './source/garden-canvas-controller/garden-canvas-controller';
import GardenCanvasEventActionTypes from './source/redux-components/garden-canvas-event-action-types';

import * as NodeInfoShapes from './source/utils/node-info-shapes';

import * as CanvasActionCreators from './source/redux-components/canvas-action-creators';
import * as CanvasReducerUtils from './source/redux-components/canvas-reducer-utils';
import * as CanvasSelectors from './source/redux-components/canvas-selectors';
import * as CanvasInteractionStates from './source/redux-components/canvas-interaction-states';

import useRenderMode from './source/utils/use-render-mode';

export type { OpenPlanActionType, ResizeHistoricalPlansResult, RetryPlanUploadResult } from './source/redux-components/canvas-action-creator-types';
export { SavePlanType } from './source/redux-components/canvas-action-creator-types';

export {
  ReactGardenCanvas,
  CanvasActionCreators,
  CanvasActionTypes,
  canvasReducer,
  attachStoreListeners,
  GardenCanvasProvider,
  withGardenCanvas,
  MonthSelector,
  GardenCanvasContext,
  CropRotationSelector,
  PlanImageGenerator,
  LayerSelector,
  GardenCanvasController,
  GardenCanvasEventActionTypes,
  GardenCanvasOverlay,
  GardenCanvasStateOverlay,
  NodeInfoShapes,
  CanvasReducerUtils,
  CanvasSelectors,
  useRenderMode,
  CanvasInteractionStates,
  CanvasInteractionState,
  iPlanDataError as PlanDataError,
  CanvasReducerState,
};

export default ReactGardenCanvas;
