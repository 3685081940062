import React from 'react';

import styles from './no-search-results.module.css';

interface iProps {
  children: React.ReactNode;
}

const NoSearchResults = ({ children }: iProps): JSX.Element | null => {
  return <div className={styles.noSearchResults}>{children}</div>;
};

export default NoSearchResults;
