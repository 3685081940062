import React, { ReactNode } from 'react';

import './form-tab-content.scss';

interface iProps {
  children: ReactNode;
  className?: string;
  expandIntoPadding?: boolean;
  active?: boolean;
}

const FormTabContent = ({ children, className, expandIntoPadding = false, active }: iProps): JSX.Element => {
  const classNames = ['rforms-tab-content'];
  if (className) {
    classNames.push(className);
  }
  if (expandIntoPadding) {
    classNames.push('expand-into-section');
  }
  if (!active) {
    classNames.push('hidden');
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default FormTabContent;
