import React, { useEffect } from 'react';

import { GuruDisplayType } from '@gi/app-guru-types';
import { LoadingState } from '@gi/constants';

import DirectoryPageSectionsLoading from './directory-page-sections-loading';
import DirectoryPageSectionsError from './directory-page-sections-error';
import DirectoryPageSection from './directory-page-section';
import { useDirectoryPage } from './directory-page-hooks';

interface iProps {
  composedPageId: string;
  noSectionsComponent?: React.ReactNode;
  showLoadingTitle?: boolean;
  emptySectionComponent?: React.ReactNode;
  usePaginatedGrids?: boolean;
  usePaginatedRows?: boolean;
}

const DirectoryPageSections = ({
  composedPageId,
  noSectionsComponent = 'No content for this page',
  emptySectionComponent,
  showLoadingTitle = true,
  usePaginatedGrids = true,
  usePaginatedRows = true,
}: iProps): JSX.Element | null => {
  const [page, reloadPage] = useDirectoryPage(composedPageId);

  useEffect(() => {
    if (page && (page.status === LoadingState.ERROR || page.status === LoadingState.SUCCESS)) {
      // If this directory page has already been loaded when the page is mounted, reload it
      reloadPage();
    }
  }, [composedPageId]);

  if (!page || page.status === LoadingState.NONE || page.status === LoadingState.LOADING) {
    return <DirectoryPageSectionsLoading />;
  }

  if (page.status === LoadingState.ERROR) {
    return <DirectoryPageSectionsError composedPageId={composedPageId} />;
  }

  if (page.status === LoadingState.SUCCESS) {
    // Hero sections are displayed separately
    const directoryPageSectionIds = page.value.sectionIds.filter((sectionId) => page.value.sections[sectionId].displayType !== GuruDisplayType.Hero);

    if (directoryPageSectionIds.length === 0) {
      return <div>{noSectionsComponent}</div>;
    }

    return (
      <>
        {directoryPageSectionIds.map((sectionId) => {
          return (
            <DirectoryPageSection
              key={`${page.value.id}-${sectionId}`}
              sectionId={sectionId}
              preview={page.value.sections[sectionId]}
              emptySectionComponent={emptySectionComponent}
              showLoadingTitle={showLoadingTitle}
              usePaginatedGrids={usePaginatedGrids}
              usePaginatedRows={usePaginatedRows}
            />
          );
        })}
      </>
    );
  }

  return null;
};

export default DirectoryPageSections;
