import React from 'react';

import styles from './item-content-table.module.css';

interface iProps {
  children: React.ReactNode;
  className?: string;
}

const ItemContentTable = ({ children, className = '' }: iProps): JSX.Element | null => {
  return <div className={`${styles.itemContentTable} ${className}`}>{children}</div>;
};

export default ItemContentTable;
