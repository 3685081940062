import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LayerDisplayModes, PlannerControlsTab } from '@gi/constants';
import { CanvasActionCreators, CanvasSelectors } from '@gi/react-garden-canvas';
import { DelayedUnmount } from '@gi/react-utils';
import { FadedScrollContainer, IconButton, IconButtonType } from '@gi/mobile-components';
import { PlannerControlsContext } from '@gi/planner-controls';

import styles from './editing-background-image-overlay.module.css';

interface iProps {
  isVertical: boolean;
}

const EditingBackgroundImageOverlay = ({ isVertical }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const { setActiveDrawer } = useContext(PlannerControlsContext);

  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  const open = useMemo(() => {
    if (activePlan === null) {
      return false;
    }
    return activePlan.plannerSettings.layer === LayerDisplayModes.BACKGROUND_IMAGES;
  }, [activePlan?.plannerSettings.layer]);

  const onDone = useCallback(() => {
    if (activePlan) {
      dispatch(CanvasActionCreators.setLayer(activePlan, LayerDisplayModes.ALL));
    }
  }, [activePlan]);

  const classNames = useMemo(() => {
    const classes: string[] = [styles.scrollContainer];
    if (isVertical) {
      classes.push(styles.vertical);
    }
    if (open) {
      classes.push(styles.open);
    }
    return classes.join(' ');
  }, [open, isVertical]);

  return (
    <DelayedUnmount isMounted={open} unmountDelay={500}>
      <FadedScrollContainer
        className={classNames}
        innerClassName={styles.scrollContainerInner}
        isHorizontal={!isVertical}
        tolerance={5}
        hideScrollbars
        captureScroll
      >
        <div className={styles.container}>
          <div className={styles.titleTag}>
            <i className='icon-picture' />
            <span>
              Editing
              <br />
              Background
              <br />
              Image
            </span>
          </div>
          <IconButton
            shareSpace
            className={styles.button}
            type={IconButtonType.SMALL}
            ariaLabel='Open background image properties editor'
            icon='icon-edit'
            onClick={() => setActiveDrawer(PlannerControlsTab.BACKGROUND_IMAGE)}
          >
            Edit
          </IconButton>
          <IconButton
            shareSpace
            className={styles.button}
            type={IconButtonType.SMALL}
            ariaLabel='Finish editing background image'
            icon='icon-ok'
            onClick={onDone}
          >
            Done
          </IconButton>
        </div>
      </FadedScrollContainer>
    </DelayedUnmount>
  );
};

export default EditingBackgroundImageOverlay;
