import moment from 'moment-timezone';
import { RequestError, networkErrorReporter, networkService } from '@gi/gi-network';
import { User } from '@gi/user';

import { SuggestedArticleAPIObject, suggestedArticlesFromAPI } from '../parsers/suggested-articles-parser';
import { attachGetArticleErrorClientMessage } from './article-service-errors';

class ArticleService {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
    console.debug(`Created article service with endpoint ${this.endpoint}`);
  }

  /**
   * @param {User} user
   * @param {Date} startDate
   * @param {number} maxItems
   * @return {Promise}
   */
  getArticles(user: User, startDate: Date, maxItems: number) {
    const params = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      maxItems,
    };

    const endPoint = `/journal/${user.ID}/weekcontent`;
    const url = `${this.endpoint}${endPoint}`;
    return networkService
      .get<SuggestedArticleAPIObject[]>(url, params)
      .then((response) => suggestedArticlesFromAPI(response.body))
      .catch(networkErrorReporter('GET', 'articles'))
      .catch((err: RequestError) => {
        attachGetArticleErrorClientMessage(err);
        throw err;
      });
  }
}

export default ArticleService;
