import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import { Tutorial, TutorialContext } from '@gi/tutorial';
import { getTopbarSelectStyles } from '../../common/select-styles';

const SELECT_STYLES = getTopbarSelectStyles(134);

type TutorialOption = {
  label: string;
  value: Tutorial;
};
interface iProps {
  attachToBody?: boolean;
  id?: string;
  tutorial: Tutorial | null;
  setTutorial: (tutorial: Tutorial) => void;
}

const TutorialDropdown = ({ attachToBody, id, tutorial, setTutorial }: iProps): JSX.Element => {
  const { tutorials } = useContext(TutorialContext);

  const options = useMemo<TutorialOption[]>(() => {
    if (tutorials === null) {
      return [];
    }

    return tutorials.tutorials.map((_tutorial) => ({
      label: _tutorial.name,
      value: _tutorial,
    }));
  }, [tutorials]);

  // Update selected option when options change
  useEffect(() => {
    const found = options.find((opt) => opt.value === tutorial);
    if (!found && options.length > 0) {
      setTutorial(options[0].value);
    }
  }, [options]);

  const getLabelForValue = useCallback(
    (value: Tutorial): string => {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === value) {
          return options[i].label;
        }
      }

      return '';
    },
    [options]
  );

  const getOption = useCallback(
    (_tutorial: Tutorial | null): TutorialOption | undefined => {
      if (_tutorial === null) {
        if (options.length === 0) {
          return undefined;
        }
        return { label: getLabelForValue(options[0].value), value: options[0].value };
      }

      return { label: getLabelForValue(_tutorial), value: _tutorial };
    },
    [options]
  );

  const onChange = useCallback((option: TutorialOption) => {
    if (option !== null) {
      setTutorial(option.value);
    }
  }, []);

  return (
    <div>
      <ReactSelect<TutorialOption>
        styles={SELECT_STYLES}
        options={options}
        value={getOption(tutorial)}
        onChange={onChange}
        menuPortalTarget={attachToBody ? document.body : undefined}
        inputId={id}
        isSearchable={false}
      />
    </div>
  );
};

export default TutorialDropdown;
