import React from 'react';

import './progress-bar.scss';

interface iProps {
  progress: number;
}

const ProgressBar = ({ progress }: iProps): JSX.Element => {
  return (
    <div className='progress-bar'>
      <div className='progress-bar-progress' style={{ width: `${100 * progress}%` }} />
    </div>
  );
};

export default ProgressBar;
