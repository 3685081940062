import { Texture } from 'pixi.js-new';

import {
  AssetType,
  AssetsComponent,
  AssetsComponentEvents,
  NineSliceSpriteComponent,
  NodeEvent,
  ScalingSpriteComponent,
  ShapeCollisionCheckFunctions,
} from '@gi/core-renderer';
import { GardenObjectType } from '@gi/constants';
import { SpriteSourceType, assertSpriteType } from '@gi/sprite-source';
import GardenObject, { GardenObjectScalingMode, assertShapeType } from '@gi/garden-object';

import GardenObjectNode, { GardenObjectNodeState } from './garden-object-node';
import { CachedRectOutline } from '../outline-utils';

class BlockGardenObjectNode extends GardenObjectNode {
  type = 'BlockGardenObjectNode';

  #scalingSprite: ScalingSpriteComponent | null;
  #nineSliceSprite: NineSliceSpriteComponent | null;
  #texture: AssetsComponent<AssetType.TEXTURE>;

  #cachedOutline?: CachedRectOutline;

  constructor(id: number, gardenObject: GardenObject, initialState: GardenObjectNodeState['state']) {
    super(id, gardenObject, initialState);

    this.state.addWatcher(
      () => {
        this.#update();
      },
      { properties: ['start', 'end', 'rotation'] },
      false
    );

    // this.outline.state.values.generateHitbox = false;

    if (this.gardenObject.shape.type !== GardenObjectType.BLOCK) {
      throw new Error(`BlockGardenObjectNode expects a BLOCK garden object, given ${this.gardenObject.shape.type}`);
    }

    this.shape.collisionCheckFunction = ShapeCollisionCheckFunctions.ConvexHull;

    // Garden objects can't currently change, but if they can in the future, this will need changing.
    if (this.gardenObject.shape.sprite.type === SpriteSourceType.NINE_SLICE) {
      this.#nineSliceSprite = this.components.add(new NineSliceSpriteComponent());
      this.#nineSliceSprite.state.values.spriteWidth = this.gardenObject.shape.sprite.spriteWidth;
      this.#nineSliceSprite.state.values.spriteHeight = this.gardenObject.shape.sprite.spriteHeight;
      this.#nineSliceSprite.state.values.marginWidth = this.gardenObject.shape.sprite.marginWidth;
      this.#nineSliceSprite.state.values.marginHeight = this.gardenObject.shape.sprite.marginHeight;

      this.ownGraphics.addChild(this.#nineSliceSprite.getContainer());

      // If we've got no center texture, we're a hollow object (like a raised bed), use hollow collision detection
      if (this.gardenObject.shape.sprite.names.M === null) {
        const marginSize = Math.max(this.gardenObject.shape.sprite.marginWidth, this.gardenObject.shape.sprite.marginHeight);
        this.shape.collisionCheckFunction = ShapeCollisionCheckFunctions.HollowConvexHull(marginSize);
      }
    } else {
      this.#scalingSprite = this.components.add(new ScalingSpriteComponent());
      this.ownGraphics.addChild(this.#scalingSprite.getContainer());
    }

    this.#texture = this.components.add(new AssetsComponent(AssetType.TEXTURE));
    this.#texture.eventBus.on(AssetsComponentEvents.Loaded, this.#onTexturesReady);

    this.eventBus.on(NodeEvent.DidBind, this.#didBind);
  }

  #didBind = () => {
    this.#loadTextures();
    this.#update();
  };

  #onTexturesReady = ([asset]: Texture[], assetDict: Record<string, Texture>) => {
    if (this.gardenObject.shape.sprite.type === SpriteSourceType.NINE_SLICE) {
      if (!this.#nineSliceSprite) {
        return;
      }

      assertSpriteType(this.gardenObject.shape.sprite, SpriteSourceType.NINE_SLICE);
      const spriteNames = this.gardenObject.shape.sprite.names;

      this.#nineSliceSprite.textures = {
        TL: spriteNames.TL ? assetDict[spriteNames.TL] : undefined,
        T: spriteNames.T ? assetDict[spriteNames.T] : undefined,
        TR: spriteNames.TR ? assetDict[spriteNames.TR] : undefined,
        L: spriteNames.L ? assetDict[spriteNames.L] : undefined,
        M: spriteNames.M ? assetDict[spriteNames.M] : undefined,
        R: spriteNames.R ? assetDict[spriteNames.R] : undefined,
        BL: spriteNames.BL ? assetDict[spriteNames.BL] : undefined,
        B: spriteNames.B ? assetDict[spriteNames.B] : undefined,
        BR: spriteNames.BR ? assetDict[spriteNames.BR] : undefined,
      };
    } else {
      if (!this.#scalingSprite) {
        return;
      }

      this.#scalingSprite.texture = asset;
    }
  };

  getDimensions(): Dimensions {
    if (this.gardenObject.shape.type === GardenObjectType.BLOCK && this.gardenObject.shape.scalingMode === GardenObjectScalingMode.FIXED) {
      return {
        width: this.gardenObject.shape.initialWidth,
        height: this.gardenObject.shape.initialHeight,
      };
    }

    return super.getDimensions();
  }

  #loadTextures() {
    if (this.gardenObject.shape.sprite.type === SpriteSourceType.NINE_SLICE) {
      this.#texture.loadAssets(Object.values(this.gardenObject.shape.sprite.names).filter((asset): asset is string => asset !== null));
    } else if (this.gardenObject.shape.sprite.type === SpriteSourceType.SINGLE) {
      this.#texture.loadAssets([this.gardenObject.shape.sprite.name]);
    }
  }

  #updateSprite() {
    assertShapeType(this.gardenObject.shape, GardenObjectType.BLOCK);

    switch (this.gardenObject.shape.sprite.type) {
      case SpriteSourceType.SINGLE: {
        if (!this.#scalingSprite) {
          return;
        }

        if (this.gardenObject.shape.scalingMode === GardenObjectScalingMode.FIXED) {
          this.#scalingSprite.state.values.width = this.gardenObject.shape.initialWidth;
          this.#scalingSprite.state.values.height = this.gardenObject.shape.initialHeight;
        } else {
          const dimensions = this.getDimensions();
          this.#scalingSprite.state.values.width = dimensions.width;
          this.#scalingSprite.state.values.height = dimensions.height;
        }

        break;
      }
      case SpriteSourceType.NINE_SLICE: {
        if (!this.#nineSliceSprite) {
          return;
        }

        if (this.gardenObject.shape.scalingMode === GardenObjectScalingMode.FIXED) {
          this.#nineSliceSprite.state.values.width = this.gardenObject.shape.initialWidth;
          this.#nineSliceSprite.state.values.height = this.gardenObject.shape.initialHeight;
        } else {
          const dimensions = this.getDimensions();
          this.#nineSliceSprite.state.values.width = dimensions.width;
          this.#nineSliceSprite.state.values.height = dimensions.height;
        }

        break;
      }
      default: {
        console.error(`Unknown sprite type: ${(this.gardenObject.shape.sprite as any).type}`);
      }
    }
  }

  #updateShape() {
    const { width, height } = this.getDimensions();

    if (!this.#cachedOutline) {
      this.#cachedOutline = new CachedRectOutline(width, height);
      this.shape.setPoints(this.#cachedOutline.path);
    } else if (this.#cachedOutline.update(width, height)) {
      this.shape.setPoints(this.#cachedOutline.path);
    }
  }

  #update() {
    this.#updateSprite();
    this.#updateShape();
  }
}

export default BlockGardenObjectNode;
