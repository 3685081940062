import React from 'react';
import { createRoot } from 'react-dom/client';

import '@gi/styles';

import App from '../source/app';

import './entry.scss';

// Remove loading message from the DOM
document.getElementById('loading-container')?.remove();

const root = createRoot(document.getElementById('root')!);
root.render(<App />);
