import React, { ReactNode } from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';

import { CompanionIcon } from '@gi/plant-icon';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import './companion-type-dropdown.scss';

export enum CompanionRelationType {
  ALL,
  ONLY_HELPER,
  ONLY_HELPED,
  ONLY_MUTUAL,
}

interface CompanionTypeDropdownOption {
  label: string;
  icon?: ReactNode;
  value: CompanionRelationType;
}

const OPTIONS: Readonly<CompanionTypeDropdownOption>[] = [
  {
    label: 'Show All Companions',
    icon: <CompanionIcon />,
    value: CompanionRelationType.ALL,
  },
  {
    label: 'Show only plants that help selected',
    icon: <CompanionIcon isHelper />,
    value: CompanionRelationType.ONLY_HELPER,
  },
  {
    label: 'Show only plants helped by selected',
    icon: <CompanionIcon isHelped />,
    value: CompanionRelationType.ONLY_HELPED,
  },
  {
    label: 'Show only plants that help and are helped',
    icon: <CompanionIcon isHelper isHelped />,
    value: CompanionRelationType.ONLY_MUTUAL,
  },
];

interface iCompanionTypeDropdownOptionLabelProps {
  label: string;
  icon?: ReactNode;
}

const CompanionTypeDropdownOptionLabel = ({ label, icon }: iCompanionTypeDropdownOptionLabelProps) => {
  return (
    <div className='companion-type-dropdown-option'>
      <div className='icon'>{icon}</div>
      <div className='label'>{label}</div>
    </div>
  );
};

interface iProps {
  value: CompanionRelationType;
  onChange: (value: CompanionRelationType) => void;
  menuPlacement?: MenuPlacement;
  id?: string;
}

const CompanionTypeDropdown = ({ value, onChange, menuPlacement, id }: iProps): JSX.Element => {
  const handleOnChange = (option: CompanionTypeDropdownOption) => {
    onChange(option.value);
  };

  return (
    <ReactSelect<CompanionTypeDropdownOption>
      options={OPTIONS}
      value={OPTIONS.find((option) => option.value === value)}
      onChange={handleOnChange}
      formatOptionLabel={CompanionTypeDropdownOptionLabel}
      styles={DEFAULT_SELECT_STYLES}
      menuPlacement={menuPlacement}
      menuPortalTarget={document.body}
      inputId={id}
      isSearchable={false}
    />
  );
};

export default CompanionTypeDropdown;
