/* eslint-disable react/no-unused-class-component-methods */

import React from 'react';
import { useDispatch } from 'react-redux';

import { User, UserPlan, UserPlanSet, UserPlanSetUtils, UserUtils } from '@gi/user';
import { PlanValidation, PlanUtils, PlanSet, FollowOnPlanOptions, MAX_PLAN_YEAR, PlanSetUtils } from '@gi/plan';

import LengthInput from '@gi/length-input';
import { metricDistanceUnits, imperialDistanceUnits } from '@gi/units';
import { CanvasActionCreators, CanvasReducerUtils } from '@gi/react-garden-canvas';
import FormField, { FormLayout, FormRadioField, FormRadioFieldset, FormSection, FormValues, InputContainer } from '@gi/form-responsive';

import { ModalPaneSectionContent, ModalPaneSection } from '@gi/modal';

import FollowOnPlanInput from './follow-on-plan-input';
import { DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT, DEFAULT_MODAL_FORM_SECTION_PADDING, PlanYearInput, UNITS_INPUT_LAYOUT, YearOption } from './common';

function getUserPlanName(planID: number | null, userPlans: UserPlanSet): string {
  if (planID && UserPlanSetUtils.hasPlan(userPlans, planID)) {
    return UserPlanSetUtils.getPlan(userPlans, planID)!.name;
  }

  return '';
}

function getUserPlanWidth(planID: number | null, userPlans: UserPlanSet, currentWidth: number): number {
  if (planID && UserPlanSetUtils.hasPlan(userPlans, planID)) {
    return UserPlanSetUtils.getPlan(userPlans, planID)!.width;
  }

  return currentWidth;
}

function getUserPlanHeight(planID: number | null, userPlans: UserPlanSet, currentHeight: number): number {
  if (planID && UserPlanSetUtils.hasPlan(userPlans, planID)) {
    return UserPlanSetUtils.getPlan(userPlans, planID)!.height;
  }

  return currentHeight;
}

function getUserPlanYear(planID: number | null, userPlans: UserPlanSet, selectedYear: number): number {
  if (planID && UserPlanSetUtils.hasPlan(userPlans, planID)) {
    return UserPlanSetUtils.getPlan(userPlans, planID)!.year;
  }

  return selectedYear;
}

export type BlankPlanFormValues = {
  name: string;
  year: number;
  metricDimensions: boolean;
  width: number;
  height: number;
  followOnPlanEnabled: boolean;
  selectedFollowOnPlanID: null | number;
  followOnPlanOptions: FollowOnPlanOptions;
};

interface iProps {
  blankPlanFormValues: FormValues<BlankPlanFormValues>;
  setBlankPlanFormValues: (blankPlanFormValues: FormValues<BlankPlanFormValues>) => void;
  user: User;
  plans: PlanSet;
  lastSavePlans: PlanSet;
  sortedUserPlans: UserPlan[];
}

const BlankPlanTab = ({ blankPlanFormValues, setBlankPlanFormValues, user, plans, lastSavePlans, sortedUserPlans }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const { name, year, metricDimensions, width, height, followOnPlanEnabled, selectedFollowOnPlanID, followOnPlanOptions } = blankPlanFormValues.values;

  const onSelectedFollowOnPlanChange = (newFollowOnPlanID: number | null) => {
    // Save selected plan if it's unsaved
    if (newFollowOnPlanID !== null && CanvasReducerUtils.planHasUnsavedChanges(lastSavePlans, plans, newFollowOnPlanID)) {
      const plan = PlanSetUtils.planSetGetPlan(plans, newFollowOnPlanID);
      if (plan !== null) {
        dispatch(CanvasActionCreators.savePlan(plan));
      }
    }

    setBlankPlanFormValues(
      blankPlanFormValues.setValues(
        ['selectedFollowOnPlanID', { value: newFollowOnPlanID }],
        ['name', { value: getUserPlanName(newFollowOnPlanID, user.plans) }],
        ['width', { value: getUserPlanWidth(newFollowOnPlanID, user.plans, width) }],
        ['height', { value: getUserPlanHeight(newFollowOnPlanID, user.plans, height) }],
        [
          'year',
          {
            value: Math.min(getUserPlanYear(newFollowOnPlanID, user.plans, year) + 1, MAX_PLAN_YEAR),
          },
        ]
      )
    );
  };

  // Handles changing metric/imperial
  const onDimensionUnitsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMetricDimensions = e.target.value === 'metric';
    const newDimensions = PlanValidation.validatePlanDimensions(width, height, newMetricDimensions);

    setBlankPlanFormValues(
      blankPlanFormValues.setValues(
        ['metricDimensions', { value: newMetricDimensions }],
        ['width', { value: newDimensions.width }],
        ['height', { value: newDimensions.height }]
      )
    );
  };

  // Handles enabling/disabling of follow on plan
  const onFollowOnPlanChange = (enabled) => {
    let newName = '';
    let newWidth = width;
    let newHeight = height;
    let newYear = PlanUtils.calculateNewDefaultPlanYear(UserUtils.isNorthernHemisphere(user));

    if (enabled) {
      newName = getUserPlanName(selectedFollowOnPlanID, user.plans);
      newWidth = getUserPlanWidth(selectedFollowOnPlanID, user.plans, width);
      newHeight = getUserPlanHeight(selectedFollowOnPlanID, user.plans, height);
      newYear = Math.min(getUserPlanYear(selectedFollowOnPlanID, user.plans, newYear) + 1, MAX_PLAN_YEAR);
    }

    // Save selected plan if it's unsaved
    if (selectedFollowOnPlanID !== null) {
      if (CanvasReducerUtils.planHasUnsavedChanges(lastSavePlans, plans, selectedFollowOnPlanID)) {
        const plan = PlanSetUtils.planSetGetPlan(plans, selectedFollowOnPlanID);
        if (plan !== null) {
          dispatch(CanvasActionCreators.savePlan(plan));
        }
      }
    }

    setBlankPlanFormValues(
      blankPlanFormValues.setValues(
        ['followOnPlanEnabled', { value: enabled }],
        ['name', { value: newName }],
        ['width', { value: newWidth }],
        ['height', { value: newHeight }],
        ['year', { value: newYear }]
      )
    );
  };

  const onWidthChange = (newWidth: number) => {
    setBlankPlanFormValues(blankPlanFormValues.setValue('width', { value: newWidth }));
  };

  const onHeightChange = (newHeight: number) => {
    setBlankPlanFormValues(blankPlanFormValues.setValue('height', { value: newHeight }));
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBlankPlanFormValues(blankPlanFormValues.setValue('name', { value: e.target.value }));
  };

  const onYearChange = (option: YearOption) => {
    setBlankPlanFormValues(blankPlanFormValues.setValue('year', { value: option.value }));
  };

  return (
    <>
      <FollowOnPlanInput
        sortedUserPlans={sortedUserPlans}
        enabled={followOnPlanEnabled}
        setEnabled={onFollowOnPlanChange}
        followOnPlan={selectedFollowOnPlanID}
        setFollowOnPlan={onSelectedFollowOnPlanChange}
        followOnPlanOptions={followOnPlanOptions}
        setFollowOnPlanOptions={(newFollowOnPlanOptions) => {
          setBlankPlanFormValues(blankPlanFormValues.setValue('followOnPlanOptions', { value: newFollowOnPlanOptions }));
        }}
      />
      <ModalPaneSection>
        <ModalPaneSectionContent>
          <FormSection className='form-section-background' padding={DEFAULT_MODAL_FORM_SECTION_PADDING}>
            <FormField
              required
              htmlFor='name'
              label='Name'
              errorText={blankPlanFormValues.hasBeenEdited ? blankPlanFormValues.fields.name.errors.join(', ') : ''}
              invalid={blankPlanFormValues.hasBeenEdited ? !blankPlanFormValues.fields.name.valid : false}
              desktopLayout={DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT}
            >
              <InputContainer>
                <input type='text' value={name} onChange={onNameChange} maxLength={100} minLength={1} />
              </InputContainer>
            </FormField>
            <PlanYearInput
              errors={blankPlanFormValues.fields.year.errors}
              northernHemisphere={UserUtils.isNorthernHemisphere(user)}
              year={year}
              onYearChange={onYearChange}
            />
            <FormRadioFieldset label='Length Units' layout='inline' fakeLabel desktopLayout={DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT}>
              <FormLayout layoutPreset={UNITS_INPUT_LAYOUT}>
                <FormRadioField htmlFor='metric-blank' label='Metric'>
                  <input id='metric-blank' type='radio' value='metric' checked={metricDimensions} onChange={onDimensionUnitsChange} />
                </FormRadioField>
                <FormRadioField htmlFor='imperial-blank' label='Imperial'>
                  <input id='imperial-blank' type='radio' value='imperial' checked={!metricDimensions} onChange={onDimensionUnitsChange} />
                </FormRadioField>
              </FormLayout>
            </FormRadioFieldset>
            <FormField
              label='Width'
              htmlFor='width'
              invalid={blankPlanFormValues.hasBeenEdited ? !blankPlanFormValues.fields.width.valid : false}
              desktopLayout={DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT}
              errorText={blankPlanFormValues.hasBeenEdited ? blankPlanFormValues.fields.width.errors.join(', ') : ''}
            >
              <LengthInput
                disabled={followOnPlanEnabled}
                value={width}
                onChange={onWidthChange}
                distanceUnits={metricDimensions ? metricDistanceUnits : imperialDistanceUnits}
              />
            </FormField>
            <FormField
              label='Height'
              htmlFor='height'
              invalid={blankPlanFormValues.hasBeenEdited ? !blankPlanFormValues.fields.height.valid : false}
              desktopLayout={DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT}
              errorText={blankPlanFormValues.hasBeenEdited ? blankPlanFormValues.fields.height.errors.join(', ') : ''}
            >
              <LengthInput
                disabled={followOnPlanEnabled}
                value={height}
                onChange={onHeightChange}
                distanceUnits={metricDimensions ? metricDistanceUnits : imperialDistanceUnits}
              />
            </FormField>
          </FormSection>
        </ModalPaneSectionContent>
        <ModalPaneSectionContent>
          <p className='advisory-text'>This is the size of the canvas on which you will draw your vegetable beds.</p>
          <p className='advisory-text'>{metricDimensions ? 'Sizes must be between 1m and 300m.' : "Sizes must be between 3' 3'' and 1000'."}</p>
          <p className='advisory-text'>
            We use width and height to represent the dimensions of your plan on screen, rather than width and length, to avoid ambiguity about direction.
          </p>
        </ModalPaneSectionContent>
      </ModalPaneSection>
    </>
  );
};

export default BlankPlanTab;
