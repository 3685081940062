import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PlannerTabTypes } from '@gi/constants';

import { PlannerSelectors } from '@gi/app-planner-slice';
import { CanvasSelectors } from '@gi/react-garden-canvas';

/**
 * React hook that returns the current tab the active garden plan is on.
 * @returns The currently active plan's currently active tab
 */
const useActivePlannerTab = (): PlannerTabTypes => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const openPlanStates = useSelector(PlannerSelectors.getOpenPlanStates);

  const activePlannerTab = useMemo(() => {
    if (activePlan === null) {
      return PlannerTabTypes.PLANNER;
    }

    if (!openPlanStates.planStateIDs.includes(activePlan.id)) {
      return PlannerTabTypes.PLANNER;
    }

    return openPlanStates.planStates[activePlan.id].activePlannerTab as PlannerTabTypes;
  }, [activePlan?.id, openPlanStates]);

  return activePlannerTab;
};

export default useActivePlannerTab;
