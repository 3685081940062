import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocalSettingsActionCreators, LocalSettingsSelectors } from '@gi/local-settings';

import { HelpButton } from '@gi/base-components';
import AnimateHeight from 'react-animate-height';

import PlantFilterInputs from './filters/plant-filter-inputs';
import PlantList from './plant-list';

import './filter-inputs.scss';
import './plant-list.scss';

import { DrawingToolsContext } from '../drawing-tools-context';

const PlantPanel = (): JSX.Element => {
  const dispatch = useDispatch();

  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);
  const snapToGrid = useSelector(LocalSettingsSelectors.getSnapToGrid);
  const autoSetSnapToGrid = useSelector(LocalSettingsSelectors.getEnableSnapOnSFGMode);

  const { setSfgHelpModalOpen } = useContext(DrawingToolsContext);

  const onSFGModeChange = (e) => {
    dispatch(LocalSettingsActionCreators.setSFGMode(e.target.checked, autoSetSnapToGrid ? e.target.checked : snapToGrid));
  };

  const setSnapToGrid = (_snapToGrid) => {
    dispatch(LocalSettingsActionCreators.setSnapToGrid(_snapToGrid));
  };

  const renderSnapMode = () => {
    return (
      <AnimateHeight duration={200} height={sfgMode ? 'auto' : 0}>
        <div className='snap-mode-switch'>
          <label>
            <span className='label-text'>Snap to Grid</span>
            <input
              type='checkbox'
              className='toggle-input'
              checked={snapToGrid}
              onChange={() => {
                setSnapToGrid(!snapToGrid);
              }}
            />
            <span className='toggle' />
          </label>
        </div>
      </AnimateHeight>
    );
  };

  const renderSFGMode = () => {
    return (
      <div className='sfg-mode-switch'>
        <HelpButton
          onClick={() => {
            setSfgHelpModalOpen(true);
          }}
        />
        <label>
          <span className='label-text'>SFG Mode</span>
          <input type='checkbox' className='toggle-input' checked={sfgMode} onChange={onSFGModeChange} />
          <span className='toggle' />
        </label>
      </div>
    );
  };

  return (
    <div className='plant-panel drawing-tools-panel'>
      <header className='drawing-tools-panel-title'>
        <h1>Plants</h1>
        <div className='drawing-tools-panel-toggles'>
          {renderSFGMode()}
          {renderSnapMode()}
        </div>
      </header>
      <div className='drawing-tools-panel-content'>
        <PlantFilterInputs />
        <PlantList />
      </div>
    </div>
  );
};

export default PlantPanel;
