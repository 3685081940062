import { TransferRequestActionCreators } from '@gi/react-requests';

import TransferPlanActionTypes from './transfer-plan-action-types';

const openTransferPlanModal = () => {
  return {
    type: TransferPlanActionTypes.OPEN_TRANSFER_PLAN_MODAL,
  };
};

const closeTransferPlanModal = () => {
  return {
    type: TransferPlanActionTypes.CLOSE_TRANSFER_PLAN_MODAL,
  };
};

const transferPlanStart = () => {
  return {
    type: TransferPlanActionTypes.TRANSFER_PLAN_START,
  };
};

const transferPlanSuccess = (planID, copy) => {
  return {
    type: TransferPlanActionTypes.TRANSFER_PLAN_SUCCESS,
    planID,
    copy,
  };
};

const transferPlanFail = (errorMessage) => {
  return {
    type: TransferPlanActionTypes.TRANSFER_PLAN_FAIL,
    errorMessage,
  };
};

const transferPlan = (planID, destinationEmail, copy) => {
  return (dispatch) => {
    // Upload plan
    dispatch(transferPlanStart());

    dispatch(TransferRequestActionCreators.transferPlanRequest(planID, destinationEmail, copy))
      .then(() => {
        dispatch(transferPlanSuccess(planID, copy));
      })
      .catch((e) => {
        dispatch(transferPlanFail(e.clientMessage ? e.clientMessage : 'An unknown error occurred'));
      });
  };
};

export { openTransferPlanModal, closeTransferPlanModal, transferPlan };
