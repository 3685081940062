import { RequestAuthMode, defaultNetworkErrorParser, networkErrorReporter, networkService } from '@gi/gi-network';

import suppliersParser from '../parsers/suppliers-parser';

const TIMEOUT_DURATION = 1000 * 60 * 4; // 4 minutes

class SupplierService {
  endpoint: string;
  timeoutDuration: number;

  constructor({ endpoint, timeoutDuration = TIMEOUT_DURATION }) {
    this.endpoint = endpoint;
    this.timeoutDuration = timeoutDuration;

    console.debug(`Created suppliers service with endpoint ${this.endpoint}`);
  }

  getSuppliers() {
    return networkService
      .get<unknown>(this.endpoint, {}, RequestAuthMode.None, { timeout: this.timeoutDuration })
      .then((suppliersJSON) => {
        return suppliersParser(suppliersJSON.body);
      })
      .catch(networkErrorReporter('GET', 'suppliers'))
      .catch((err) => {
        console.log('Error loading suppliers data');
        console.log(err);
        throw defaultNetworkErrorParser(err);
      });
  }
}

export default SupplierService;
