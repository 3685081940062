import { InspectableClassData, bindToLifecycle } from '@gi/core-renderer';
import { Geometry } from '@gi/math';
import { GardenObjectType } from '@gi/constants';
import { GardenObjectScalingMode } from '@gi/garden-object';

import GenericHandleSetNode from './generic-handle-set-node';
import TextNode from '../text/text-node';
import ShapeNode from '../shapes/shape-node';
import GardenObjectNode from '../garden-objects/garden-object-node';
import BackgroundImageNode from '../background/background-image-node';

/**
 * RectangleHandleSetNode
 *  Effectively the same as `BaseHandleSetNode`, but can be attached directly to text or shapes.
 */
class RectangleHandleSetNode extends GenericHandleSetNode {
  type = 'RectangleHandleSetNode';

  /**
   * Attaches this handleSet to the given garden object.
   * This attachment cannot be undone without destroying the handleSet.
   * @param gardenObjectNode The garden object to attach to
   */
  attachToGardenObject(gardenObjectNode: GardenObjectNode) {
    this.targets = [gardenObjectNode];
    this.setAllowManipulation(
      gardenObjectNode.gardenObject.shape.type === GardenObjectType.BLOCK
        ? gardenObjectNode.gardenObject.shape.scalingMode !== GardenObjectScalingMode.FIXED
        : true
    );
    this.setAllowMaintainAspectRatio(
      gardenObjectNode.gardenObject.shape.type === GardenObjectType.BLOCK
        ? !(gardenObjectNode.gardenObject.shape.constrainWidth || gardenObjectNode.gardenObject.shape.constrainHeight)
        : true
    );
    if (gardenObjectNode.gardenObject.shape.type === GardenObjectType.BLOCK) {
      this.defaultAspectRatio = gardenObjectNode.gardenObject.shape.initialWidth / gardenObjectNode.gardenObject.shape.initialHeight;
    }
    bindToLifecycle(this, () => {
      const watcher = gardenObjectNode.state.addWatcher(
        (state) => {
          if (this.draggingHandle === null) {
            const center = Geometry.midpoint(state.values.start, state.values.end);
            const dimensions = gardenObjectNode.getDimensions();
            this.setFromRectangle(center, dimensions, state.values.rotation);
          }
        },
        { properties: ['start', 'end', 'rotation'] }
      );
      return () => watcher.destroy();
    });
  }

  /**
   * Attaches this handleSet to the given text node.
   * This attachment cannot be undone without destroying the handleSet.
   * @param text The text to attach to
   */
  attachToText(text: TextNode) {
    this.targets = [text];
    this.setAllowMaintainAspectRatio(true);
    bindToLifecycle(this, () => {
      const watcher = text.state.addWatcher(
        (state) => {
          if (this.draggingHandle === null) {
            const center = Geometry.midpoint(state.values.start, state.values.end);
            const dimensions: Dimensions = {
              width: state.values.end.x - state.values.start.x,
              height: state.values.end.y - state.values.start.y,
            };
            this.setFromRectangle(center, dimensions, state.values.rotation);
          }
        },
        { properties: ['start', 'end', 'rotation'] }
      );
      return () => watcher.destroy();
    });
  }

  /**
   * Attaches this handleSet to the given shape node.
   * This attachment cannot be undone without destroying the handleSet.
   * @param shape The shape to attach to
   */
  attachToShape(shape: ShapeNode) {
    this.targets = [shape];
    this.setAllowMaintainAspectRatio(true);
    bindToLifecycle(this, () => {
      const watcher = shape.state.addWatcher(
        (state) => {
          if (this.draggingHandle === null) {
            const center = Geometry.midpoint(state.values.point1, state.values.point3);
            const dimensions: Dimensions = {
              width: state.values.point3.x - state.values.point1.x,
              height: state.values.point3.y - state.values.point1.y,
            };
            this.setFromRectangle(center, dimensions, state.values.rotation);
          }
        },
        { properties: ['point1', 'point3', 'rotation'] }
      );
      return () => watcher.destroy();
    });
  }

  /**
   * Attaches this handleSet to the given text node.
   * This attachment cannot be undone without destroying the handleSet.
   * @param text The text to attach to
   */
  attachToBackgroundImage(backgroundImage: BackgroundImageNode) {
    this.targets = [backgroundImage];
    this.setAllowMaintainAspectRatio(true);
    bindToLifecycle(this, () => {
      const watcher = backgroundImage.state.addWatcher(
        (state) => {
          if (this.draggingHandle === null) {
            this.setFromRectangle(state.values.position, state.values.dimensions, state.values.rotation);
          }
          const maintainAspect = state.get('planSettings', 'maintainBackgroundImageAspectRatio');
          if (maintainAspect !== undefined) {
            this.setForceMaintainAspectRatio(maintainAspect);
          }
        },
        { properties: ['position', 'dimensions', 'rotation'], otherStates: { planSettings: { properties: ['maintainBackgroundImageAspectRatio'] } } }
      );
      return () => watcher.destroy();
    });
  }

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default RectangleHandleSetNode;
