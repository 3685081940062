import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { FormLayout, FormSection, FORM_LAYOUT_PRESETS } from '@gi/form-responsive';
import LoadingButton from '@gi/loading-button';
import { SessionActionCreators } from '@gi/react-session';

const UserLogoutForm = (): JSX.Element => {
  const [loggingOut, setIsLoggingOut] = useState(false);
  const dispatch = useDispatch();

  const logOut = () => {
    setIsLoggingOut(true);
    dispatch(SessionActionCreators.logout());
  };

  return (
    <FormSection className='form-section-background'>
      <h2>Log Out</h2>
      <p>
        We recommend you only log out if you are using a public or shared computer, otherwise it is fine to stay logged in. If you still want to log out, you
        may do so here.
      </p>
      <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.ButtonRight}>
        <LoadingButton type='button' className='button button-primary' loading={loggingOut} disabled={loggingOut} onClick={logOut}>
          Log out now
        </LoadingButton>
      </FormLayout>
    </FormSection>
  );
};

export default UserLogoutForm;
