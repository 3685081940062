import React from 'react';

import UserPlanSettingsForm from '../forms/user-plan-settings-form';
import UserUnitsSettingsForm from '../forms/user-units-settings-form';

import './settings-pages.scss';

const GeneralSettingsPage = (): JSX.Element => {
  return (
    <div className='settings-page'>
      <UserUnitsSettingsForm />
      <UserPlanSettingsForm />
    </div>
  );
};

export default GeneralSettingsPage;
