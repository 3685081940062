import React from 'react';

import styles from './breadcrumb.module.css';

interface iProps {
  children: React.ReactNode;
}

const Breadcrumb = ({ children }: iProps): JSX.Element => {
  return (
    <>
      <div className={styles.breadcrumb}>{children}</div>
      <span>
        <i className='icon-angle-right' />
      </span>
    </>
  );
};

export default Breadcrumb;
