import React, { useContext } from 'react';

import { Accordion, AccordionButton, AccordionContent, AccordionDropdownIcon } from '@gi/accordion';

import { ObjectivesContext } from '../../objectives-context';
import { ADVANCED_ACCORDION_ID } from '../../objective-types';
import ObjectiveGroupListDisplay from '../objective-group-list-display';

import styles from './objectives-pane.module.css';

interface iProps {
  groupIds: string[];
  minimised?: boolean;
  onLaunchHelp?: () => void;
}

const ObjectivesPaneAdvancedSection = ({ groupIds, minimised, onLaunchHelp }: iProps): JSX.Element | null => {
  const { expandedGroups, setExpandedGroups } = useContext(ObjectivesContext);

  if (groupIds.length === 0) {
    return null;
  }

  return (
    <Accordion unstyled className={styles.advancedSectionContainer} animationDuration={500} expandedIds={expandedGroups} setExpandedIds={setExpandedGroups}>
      <AccordionButton accordionId={ADVANCED_ACCORDION_ID} className={styles.advancedSectionButton}>
        Advanced Milestones <AccordionDropdownIcon accordionId={ADVANCED_ACCORDION_ID} className={styles.advancedSectionButtonIcon} />
      </AccordionButton>
      <AccordionContent accordionId={ADVANCED_ACCORDION_ID}>
        <ObjectiveGroupListDisplay groupIds={groupIds} onLaunchHelp={onLaunchHelp} minimised={minimised} />
      </AccordionContent>
    </Accordion>
  );
};

export default ObjectivesPaneAdvancedSection;
