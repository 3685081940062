import { temperatureConversion } from '@gi/conversion';

import heatGradient from './assets/heatGradient.png';

/**
 * Converts a centigrade temperature to an RGB triple
 * @param {CanvasRenderingContext2D} context - a canvas context with a temperature gradient drawn on
 */
const centigradeToRGB = (context: CanvasRenderingContext2D, tempC: number): [number, number, number, number] => {
  let tempF = Math.round(temperatureConversion.centigradeToFarenheit(tempC));
  // Clamp temp to bounds of image
  tempF = Math.min(Math.max(0, tempF), 169);
  // Return the colour at x=temp in the gradient
  return context.getImageData(tempF, 0, 1, 1).data as unknown as [number, number, number, number];
};

/**
 * Gets temperature colour based on the temperature degrees
 * @param {number} tempC - the temperature in centigrade
 * @return {Promise} resolves with rgba array
 */
const getColourFromTemperature = (tempC: number): Promise<[number, number, number, number]> => {
  return new Promise((resolve, reject) => {
    // Load the gradient image and pick the colour from it
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) {
      throw new Error('Unable to get context');
    }

    const gradientImage = new Image();
    gradientImage.addEventListener('error', reject);
    gradientImage.addEventListener('load', () => {
      context.drawImage(gradientImage, 0, 0);
      resolve(centigradeToRGB(context, tempC));
    });
    gradientImage.src = heatGradient;
  });
};

export { getColourFromTemperature, centigradeToRGB };
