import React, { useContext, useMemo } from 'react';

import { PlantSpacings } from '@gi/plant';
import { DistanceUnits } from '@gi/units';

import { TypeCounts } from '../../types/plant-list-types';
import PlantListCardCell from './plant-list-card-cell';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

import './plant-list-card-spacings.scss';

interface iProps {
  spacings: PlantSpacings;
  countsByType: TypeCounts;
  distanceUnits: DistanceUnits;
}

const PlantListCardSpacings = ({ spacings, countsByType, distanceUnits }: iProps): JSX.Element | null => {
  const { columns } = useContext(GlobalPlantListContext);

  const single = useMemo(() => {
    if (countsByType.area === 0) {
      return null;
    }
    return <PlantListCardCell title='Spacings (Single)'>{distanceUnits.getUnitString(spacings.spacing)}</PlantListCardCell>;
  }, [spacings, distanceUnits]);

  const inRow = useMemo(() => {
    if (countsByType.area === 0) {
      return null;
    }
    return (
      <PlantListCardCell title='Spacings (In-row)'>
        {distanceUnits.getUnitString(spacings.inRowSpacing)}, {distanceUnits.getUnitString(spacings.rowSpacing)} row gap
      </PlantListCardCell>
    );
  }, [spacings, distanceUnits]);

  const sfgCount = useMemo(() => {
    if (spacings.sfgCount === null || countsByType.sfg <= 0) {
      return null;
    }
    return (
      <PlantListCardCell title='Spacings (SFG)'>
        {spacings.sfgCount} {spacings.sfgCount === 1 ? 'plant' : 'plants'}/sqft
      </PlantListCardCell>
    );
  }, [spacings]);

  if (!columns.spacings) {
    return null;
  }

  return (
    <>
      {single}
      {inRow}
      {sfgCount}
    </>
  );
};

export default PlantListCardSpacings;
