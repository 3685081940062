import { ExtendedAsyncOperation } from '@gi/utils';
import { BitmapFont, Spritesheet, Texture } from 'pixi.js-new';

export enum AssetType {
  TEXTURE = 'TEXTURE',
  SPRITESHEET = 'SPRITESHEET',
  BUNDLE = 'BUNDLE',
  BITMAP_FONT = 'BITMAP_FONT',
}

/** How should assets with the same name be handled by the asset manager */
export enum AssetNameCollisionMode {
  /** Throw an error if asset name in use (default) */
  ERROR = 'ERROR',
  /** Skip the new asset if the name is in use */
  SKIP = 'SKIP',
  /** Replace the asset with the new asset regardless */
  REPLACE = 'REPLACE',
}

// How textures are defined in the definitions file generated by TexturePacker
type TextureDefinitionJSON = {
  name: string;
} & (
  | {
      atlasName: string;
      type: 'atlas';
    }
  | {
      location: string;
      type: 'image';
    }
);

// How resources are defined in the definitions file generated by TexturePacker
type ResourceDefinitionJSON = {
  name: string;
  location: string;
  type: 'atlas' | 'image';
  mipmaps: boolean;
};

// The structure of a definitions file generated by TexturePacker
export type DefinitionsJSON = {
  textures: {
    [key: string]: TextureDefinitionJSON;
  };
  resources: ResourceDefinitionJSON[];
};

// Maps an asset type to its expected value type
export type AssetTypeMapping = {
  [AssetType.TEXTURE]: Texture;
  [AssetType.SPRITESHEET]: Spritesheet;
  [AssetType.BUNDLE]: Record<string, Texture | Spritesheet>;
  [AssetType.BITMAP_FONT]: BitmapFont;
};

// Extra metadata that may be stored about an asset
type AssetTypeMetadata = {
  [AssetType.TEXTURE]: { mipmaps?: boolean };
  [AssetType.SPRITESHEET]: { mipmaps?: boolean };
  [AssetType.BUNDLE]: Record<string, never>;
  [AssetType.BITMAP_FONT]: Record<string, never>;
};

// Data common to all types of assets
type AssetMetadata<T extends AssetType> = {
  name: string;
  src?: string;
  type: T;
} & AssetTypeMetadata[T];

export type AsyncAsset<T extends AssetType> = ExtendedAsyncOperation<AssetTypeMapping[T], Error> & AssetMetadata<T>;

export type AssetLoadProgressCallback = (progress: number) => void;

export type AssetGroupAsyncAssetStatus = ExtendedAsyncOperation<never, Error, { progress: number }> & { name: string };

export type AssetGroupProgressCallback = (progress: number, individualProgress: Record<string, AssetGroupAsyncAssetStatus>) => void;
export type AssetGroupSuccessCallback = () => void;
export type AssetGroupErrorCallback = (error: Error, individualProgress: Record<string, AssetGroupAsyncAssetStatus>) => void;

export type AssetLoadCallback<T extends AssetType> = {
  onLoad: (asset: AssetTypeMapping[T]) => void;
  onError: (error: Error) => void;
  ignoreErrors: boolean;
};
