import React from 'react';
import SectionHeader from '../section-header';
import ListItemDetailsColumn from '../list-item-details-column';

import styles from './quantity-selector.module.css';
import QuantityNumberInput from './quantity-number-input';
import RecommendedQuantityDescriptor from './recommended-quantity-descriptor';

interface iProps {
  inputQuantity: number;
  quantity: number;
  variantQuantity: number;
  recommendedQuantityMultiplier: number;
  setQuantity: (quantity: number) => void;
  unit: string;
}

const QuantitySelector = ({ inputQuantity, quantity, variantQuantity, recommendedQuantityMultiplier, setQuantity, unit }: iProps): JSX.Element => {
  return (
    <ListItemDetailsColumn className={styles.quantitySelectorColumn}>
      <SectionHeader>Quantity</SectionHeader>
      <div className={styles.quantityRow}>
        <div className={styles.quantitySelector}>
          <button
            className={`${styles.quantitySelectorButton} ${styles.quantitySelectorButtonLeft}`}
            disabled={quantity <= 1}
            type='button'
            onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}
          >
            -
          </button>
          <QuantityNumberInput value={quantity} onChange={setQuantity} />
          <button className={`${styles.quantitySelectorButton} ${styles.quantitySelectorButtonRight}`} type='button' onClick={() => setQuantity(quantity + 1)}>
            +
          </button>
        </div>
        <RecommendedQuantityDescriptor
          unit={unit}
          variantQuantity={variantQuantity}
          quantity={quantity}
          inputQuantity={inputQuantity}
          recommendedQuantityMultiplier={recommendedQuantityMultiplier}
        />
      </div>
    </ListItemDetailsColumn>
  );
};

export default QuantitySelector;
