import React from 'react';

import ModalType from './modal-types';
import { ModalProvider } from './modal-context/modal-context';
import ModalCloseHandler from './modal-close-handler';

interface iProps {
  children: React.ReactNode;
  type?: ModalType;
  className?: string;
  closeRequest: () => void;
  style?: React.CSSProperties;
}

/**
 * A wrapper for 'ModalWithoutContext' which just adds the modal context and an inner component to pass
 * the 'attemptClose' function from the context to the 'closeRequest' of the 'ModalWithoutContext'
 */
const Modal = ({ type = ModalType.Default, className = '', children, closeRequest, style = {} }: iProps): JSX.Element => {
  return (
    <ModalProvider close={closeRequest}>
      <ModalCloseHandler type={type} className={className} style={style}>
        {children}
      </ModalCloseHandler>
    </ModalProvider>
  );
};

export default Modal;
