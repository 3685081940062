import React from 'react';
import type { RouteObject } from 'react-router-dom';
import Planner from '@gi/app-planner';
import { Application, ApplicationLinks } from '@gi/garden-platform-navigation';

const appPlannerRoutes: RouteObject = {
  path: ApplicationLinks[Application.GardenPlanner],
  element: <Planner />,
};

export default appPlannerRoutes;
