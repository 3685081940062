import React from 'react';

interface iProps {
  borderColor?: string;
}

const CheckboxEmpty = ({ borderColor = '#888' }: iProps): JSX.Element => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 -960 960 960' width='24px' fill={borderColor}>
      <path d='M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Z' />
    </svg>
  );
};

export default CheckboxEmpty;
