import React, { MutableRefObject, ReactNode, createContext, useMemo, useRef, useState } from 'react';
import {
  Placement,
  autoUpdate,
  useFloating,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useInteractions,
  arrow,
  UseInteractionsReturn,
  UseFloatingReturn,
} from '@floating-ui/react';

type LocalContextMenuContextType = {
  open: boolean;
  setOpen: (open: boolean) => void;
  getReferenceProps: UseInteractionsReturn['getReferenceProps'];
  getContextMenuProps: UseInteractionsReturn['getFloatingProps'];
  contextMenuStyles: UseFloatingReturn['floatingStyles'];
  refs: UseFloatingReturn['refs'] & {
    arrow: MutableRefObject<SVGSVGElement | null>;
    setArrow: (arrow: SVGSVGElement | null) => void;
  };
  context: UseFloatingReturn['context'];
};

export const LocalContextMenuContext = createContext<LocalContextMenuContextType>({} as LocalContextMenuContextType);

interface iProps {
  placement?: Placement;
  children?: ReactNode;
  disabled?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

/**
 * Base ContextMenu component
 *
 * To use, a `ContextMenuPointTarget`/`ContextMenuTarget` and a `ContextMenuContent` must be present as children of this component.
 *
 * @example
 * const [open, setOpen] = useState(false);
 *
 * <ContextMenu open={open} onOpenChange={setOpen}>
 *   <ContextMenuPointTarget position={{ x: 100, y: 100 }} strategy='fixed' />
 *   <ContextMenuContent>
 *    <ContextMenuButton icon='icon-order-front'>Button</ContextMenuButton>
 *   </ContextMenuContent>
 * </ContextMenu>
 */
const ContextMenu = ({ placement = 'right', children, disabled, open: externalIsOpen, onOpenChange: externalSetIsOpen }: iProps): JSX.Element => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const [arrowElem, setArrowElem] = useState<SVGSVGElement | null>(null);
  const arrowRef = useRef<SVGSVGElement | null>(null);

  const open = disabled ? false : (externalIsOpen ?? internalIsOpen);
  const setOpen = externalSetIsOpen ?? setInternalIsOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: (...args) => autoUpdate(...args, { animationFrame: true }),
    middleware: [offset(12), shift(), flip(), arrow({ padding: 6, element: arrowElem })],
  });

  const { context } = data;

  /** Make the context menu disappear when the user presses ESC */
  const dismiss = useDismiss(context);

  /** ARIA properties */
  const role = useRole(context, { role: 'menu' });

  /** Merge all the interaction methods into 1 */
  const interactions = useInteractions([dismiss, role]);

  /** Add support for defining the arrow ref externally */
  const refs = useMemo<LocalContextMenuContextType['refs']>(
    () => ({
      ...data.refs,
      arrow: arrowRef,
      setArrow: (_arrow) => {
        setArrowElem(_arrow);
        arrowRef.current = _arrow;
      },
    }),
    [data.refs, arrowRef]
  );

  const value = useMemo<LocalContextMenuContextType>(
    () => ({
      open,
      setOpen,
      getReferenceProps: interactions.getReferenceProps,
      getContextMenuProps: interactions.getFloatingProps,
      contextMenuStyles: data.floatingStyles,
      context: data.context,
      refs,
    }),
    [open, setOpen, interactions, data]
  );

  return <LocalContextMenuContext.Provider value={value}>{children}</LocalContextMenuContext.Provider>;
};

export default ContextMenu;
