import React, { ReactNode, useEffect, useReducer } from 'react';
import FullscreenIcon from './fullscreen-icon';

const getFullscreenNames = () => {
  const webkit = 'webkitExitFullscreen' in document && !('exitFullscreen' in document);
  return {
    webkit,
    requestFullscreen: webkit ? 'webkitRequestFullscreen' : 'requestFullscreen',
    exitFullscreen: webkit ? 'webkitExitFullscreen' : 'exitFullscreen',
    fullscreenChange: webkit ? 'webkitfullscreenchange' : 'fullscreenchange',
    fullscreenElement: webkit ? 'webkitCurrentFullScreenElement' : 'fullscreenElement',
  };
};

const toggleFullscreen = () => {
  const { webkit, requestFullscreen, exitFullscreen, fullscreenElement } = getFullscreenNames();

  if (!document[fullscreenElement]) {
    document.documentElement[requestFullscreen]();
  } else {
    if (document[exitFullscreen]) {
      document[exitFullscreen]();
    }
    if (webkit) {
      // Fix safari incorrectly calculating height after exiting fullscreen
      document.getElementById('root')!.style.minHeight = '100vh';
    }
  }
};

const isIOS = (() => {
  const iosQuirkPresent = () => {
    const audio = new Audio();

    audio.volume = 0.5;
    return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
  };

  const _isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes('Macintosh');
  const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

  return _isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
})();

interface iProps {
  className?: string;
  iconClassName?: string;
  textClassName?: string;
  enterFullscreenText?: ReactNode;
  exitFullscreenText?: ReactNode;
}

const FullscreenButton = ({
  className,
  iconClassName,
  textClassName,
  enterFullscreenText = 'Fullscreen',
  exitFullscreenText = 'Exit Fullscreen',
}: iProps): JSX.Element | null => {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line

  useEffect(() => {
    const firstForceUpdate = forceUpdate;
    const { fullscreenChange } = getFullscreenNames();
    document.addEventListener(fullscreenChange, firstForceUpdate);

    return () => {
      document.removeEventListener(fullscreenChange, firstForceUpdate);
    };
  }, []);

  if (isIOS || navigator.standalone) {
    // navigator.standalone tells us that user has opened a standalone browser version
    return null;
  }

  const { fullscreenElement } = getFullscreenNames();
  const isFullscreen = !!document[fullscreenElement];

  const classNames: string[] = ['fullscreen-button'];
  if (className) {
    classNames.push(className);
  }

  const iconClassNames: string[] = ['fullscreen-button-icon'];
  if (iconClassName) {
    iconClassNames.push(iconClassName);
  }

  const textClassNames: string[] = ['fullscreen-button-text'];
  if (textClassName) {
    textClassNames.push(textClassName);
  }

  return (
    <button className={classNames.join(' ')} type='button' onClick={toggleFullscreen}>
      <span className={iconClassNames.join(' ')}>
        <FullscreenIcon isFullscreen={isFullscreen} />
      </span>
      <span className={textClassNames.join(' ')}>{isFullscreen ? exitFullscreenText : enterFullscreenText}</span>
    </button>
  );
};

export default FullscreenButton;
