import type { StateManager } from './state-manager';
import { StateObserver } from './state-observer';
import { StateProperties } from './state-properties';
import stateRegister from './state-register';
import { AnyStateDef, Nullable, StateChangeSet, StatePropertiesDef } from './state-types';
import { StateValidator, StateValidatorCallback } from './state-validator';

type OtherStates<T extends Record<string, AnyStateDef>> = {
  [K in keyof T]: State<T[K]>;
};

export class State<TState extends AnyStateDef> {
  readonly id: number;
  owner: TState['owner'];

  #manager: StateManager | null = null;
  get manager() {
    return this.#manager;
  }
  set manager(manager: StateManager | null) {
    this.#manager = manager;
    if (this.#hasUpdatesWaiting) {
      this.#manager?.notifyStateHasUpdates(this);
    }
  }

  #values: TState['state'];
  #valuesProxy: TState['state'];
  /** The most recent raw values. Will always be up-to-date. Set these to update the state. */
  get values() {
    return this.#valuesProxy;
  }

  #currentValues: TState['state'];
  /** The most recent set of committed values. These were the values when the last update loop finished. */
  get currentValues(): Readonly<TState['state']> {
    return this.#currentValues;
  }

  #otherStates: Partial<OtherStates<TState['otherStates']>>;
  #otherStatesProxy: Nullable<OtherStates<TState['otherStates']>>;
  /** All the other states that have potential been connected to this state. */
  get otherStates() {
    return this.#otherStatesProxy;
  }

  /** Object containing the things that have changed since the last update loop. */
  #nextChanged: StateChangeSet<TState> = {
    properties: {},
    signals: {},
    otherStates: {},
  };
  get nextChanged(): Readonly<StateChangeSet<TState>> {
    return this.#nextChanged;
  }

  /** Object containing the things that have changed since the last full update finished.
   * Used during the late observer loop to fake all the previous updates happening at once. */
  #batchedNextChanged: StateChangeSet<TState> = {
    properties: {},
    signals: {},
    otherStates: {},
  };

  #changed: StateChangeSet<TState> = {
    properties: {},
    signals: {},
    otherStates: {},
  };
  /** Returns an object containing the things about this state that have changed this update */
  get changed(): Readonly<StateChangeSet<TState>> {
    return this.#changed;
  }

  #dependentObservers: Set<number> = new Set();
  /** Returns a list of observers that should be run immediately when this state updates */
  get dependentObservers() {
    return this.#dependentObservers;
  }

  #dependentLateObservers: Set<number> = new Set();
  /** Returns a list of observers that should be run at the end of the update loop (after all data changes have completed) */
  get dependentLateObserver() {
    return this.#dependentLateObservers;
  }

  #validators: StateValidator<TState>[] = [];
  /** Returns a list of validator functions for this state, which will be run every update */
  get validators() {
    return this.#validators;
  }

  #hasUpdatesWaiting: boolean = false;
  /** Returns true if this state has changed since the last StateManager update, but hasn't been committed yet */
  get hasUpdatesWaiting() {
    return this.#hasUpdatesWaiting;
  }

  #lastUpdatedOperationId: number | null = null;
  /** Returns true if this state was committed or batch-committed this update loop */
  get hasUpdated() {
    return this.#lastUpdatedOperationId !== null && this.#lastUpdatedOperationId === this.manager?.currentOperationId;
  }

  #destroyed: boolean = false;
  get destroyed() {
    return this.#destroyed;
  }

  /** Internal flag to prevent state changes emitting updates (during validators for example) */
  #preventEmittingUpdates: boolean = false;

  constructor(initialValues: TState['state'], otherStates: Partial<OtherStates<TState['otherStates']>> = {}) {
    this.id = stateRegister.registerState(this);
    this.#values = { ...initialValues };
    this.#currentValues = { ...initialValues };
    this.#otherStates = { ...otherStates };

    this.#valuesProxy = new Proxy(this.#values, this.stateProxyHandler);
    this.#otherStatesProxy = new Proxy(this.#otherStates as Nullable<OtherStates<TState['otherStates']>>, this.otherStatesProxyHandler);
  }

  /** Helper function to get a value from an otherState. Can be provided a fallback to use when the state isn't connected */
  get<T extends keyof TState['otherStates'], K extends keyof TState['otherStates'][T]['state']>(
    otherState: T,
    property: K
  ): TState['otherStates'][T]['state'][K] | undefined;
  get<T extends keyof TState['otherStates'], K extends keyof TState['otherStates'][T]['state']>(
    otherState: T,
    property: K,
    fallback: TState['otherStates'][T]['state'][K]
  ): TState['otherStates'][T]['state'][K];
  get<T extends keyof TState['otherStates'], K extends keyof TState['otherStates'][T]['state']>(
    otherState: T,
    property: K,
    fallback?: TState['otherStates'][T]['state'][K]
  ): TState['otherStates'][T]['state'][K] | undefined {
    if (!this.otherStates[otherState]) {
      return fallback;
    }
    return this.otherStates[otherState]?.values[property];
  }

  private get stateProxyHandler(): ProxyHandler<TState['state']> {
    return {
      set: (target, property, newValue) => {
        if (typeof property === 'symbol') {
          return false;
        }
        if (Object.is(newValue, Reflect.get(target, property))) {
          return true; // Do nothing if the current and new values are the same
        }
        const result = Reflect.set(target, property, newValue, this.#values);
        if (!this.#nextChanged.properties[property]) {
          this.#nextChanged.properties[property as keyof TState['state']] = true;
          this.#batchedNextChanged.properties[property as keyof TState['state']] = true;
        }
        this.onUpdate();
        return result;
      },
      get: (target, property) => {
        return Reflect.get(target, property, this.#values);
      },
    };
  }

  private get otherStatesProxyHandler(): ProxyHandler<Nullable<OtherStates<TState['otherStates']>>> {
    return {
      get: (target, property) => {
        const result = Reflect.get(target, property, this.#otherStates);
        return result ?? null;
      },
    };
  }

  private onUpdate() {
    if (this.#hasUpdatesWaiting || this.#preventEmittingUpdates) {
      return;
    }
    this.#hasUpdatesWaiting = true;
    if (this.manager) {
      this.manager.notifyStateHasUpdates(this);
    }
  }

  /**
   * Connects the given state to this state, in the named slot.
   * @param name The name of the state slot to fill
   * @param state The state to put in that slot
   */
  connectState<T extends keyof TState['otherStates']>(name: T, state: State<TState['otherStates'][T]>) {
    if (Object.is(this.#otherStates[name], state)) {
      return; // Do nothing if already connected
    }
    const oldState = this.#otherStates[name];
    this.#otherStates[name] = state;
    this.#nextChanged.otherStates[name] = true;
    this.#batchedNextChanged.otherStates[name] = true;

    const attemptToLinkObserver = (observerId: number) => {
      const observer = stateRegister.getStateObserver(observerId);
      if (!observer) {
        return;
      }
      observer.dependencies.forEach((dependency) => {
        if (dependency.state === this && dependency.otherStatesAsArray.some(({ name: otherStateName }) => otherStateName === name)) {
          if (oldState) {
            oldState.unregisterDependentObserver(observer); // TODO: Can we do this?
          }
          state.registerDependantObserver(observer);
        }
      });
    };

    this.#dependentObservers.forEach(attemptToLinkObserver);
    this.#dependentLateObservers.forEach(attemptToLinkObserver);

    this.onUpdate();
  }

  /**
   * Disconnects the state in the named slot.
   * @param name The name of the state to disconnect
   * @param state Optional, the state to disconnect. State will not be disconnected if the state in the slot doesn't match this state.
   */
  disconnectState<T extends keyof TState['otherStates']>(name: T, state?: State<TState['otherStates'][T]>) {
    if (!this.#otherStates[name]) {
      return; // Do nothing if already disconnected
    }
    if (state && !Object.is(this.#otherStates, state)) {
      return; // Do nothing if the given state isn't the same as the one in this slot currently.
    }
    const stateToRemove = this.otherStates[name];
    delete this.#otherStates[name];
    this.#nextChanged.otherStates[name] = true;
    this.#batchedNextChanged.otherStates[name] = true;

    const attemptToUnlinkObserver = (observerId: number) => {
      const observer = stateRegister.getStateObserver(observerId);
      if (!observer) {
        return;
      }
      observer.dependencies.forEach((dependency) => {
        if (dependency.state === this && dependency.otherStatesAsArray.some(({ name: otherStateName }) => otherStateName === name)) {
          stateToRemove?.unregisterDependentObserver(observer);
        }
      });
    };

    this.#dependentObservers.forEach(attemptToUnlinkObserver);
    this.#dependentLateObservers.forEach(attemptToUnlinkObserver);

    this.onUpdate();
  }

  /**
   * Triggers a signal on this state (basically emit an event)
   * @param signals The signal name to fire
   */
  triggerSignal<T extends TState['signals'][number]>(...signals: T[]) {
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      if (this.#nextChanged.signals[signal]) {
        // eslint-disable-next-line no-continue
        continue;
      }
      this.#nextChanged.signals[signal] = true;
      this.#batchedNextChanged.signals[signal] = true;
    }
    this.onUpdate();
  }

  /**
   * Cancel triggering a signal on this state (basically cancel emitting an event)
   * @param signals The signal name to cancel
   */
  cancelSignal<T extends TState['signals'][number]>(...signals: T[]) {
    for (let i = 0; i < signals.length; i++) {
      const signal = signals[i];
      delete this.#nextChanged.signals[signal];
      delete this.#batchedNextChanged.signals[signal];
    }
    this.onUpdate();
  }

  /**
   * Internal: Informs this state that the given observer is observing this state.
   * @param observer The observer to register
   */
  registerDependantObserver<T extends StateObserver<Readonly<StateProperties<any>[]>>>(observer: T) {
    observer.dependencies.forEach((dependency) => {
      if (dependency.state === this) {
        dependency.otherStatesAsArray.forEach(({ name }) => {
          if (this.otherStates[name] !== null) {
            this.otherStates[name]?.registerDependantObserver(observer);
          }
        });
      }
    });
    if (observer.isLate) {
      this.#dependentLateObservers.add(observer.id);
    } else {
      this.#dependentObservers.add(observer.id);
    }
  }

  /**
   * Internal: Informs this state that the given observer is no longer observing this state (probably destroyed)
   * @param observer The observer to register
   */
  unregisterDependentObserver<T extends StateObserver<Readonly<StateProperties<any>[]>>>(observer: T) {
    observer.dependencies.forEach((dependency) => {
      if (dependency.state === this) {
        dependency.otherStatesAsArray.forEach(({ name }) => {
          if (this.otherStates[name] !== null) {
            this.otherStates[name]?.unregisterDependentObserver(observer);
          }
        });
      }
    });
    if (observer.isLate) {
      this.#dependentLateObservers.delete(observer.id);
    } else {
      this.#dependentObservers.delete(observer.id);
    }
  }

  /**
   * Creates a validator on this state, which runs whenever the state updates to keep data valid.
   * @param callback The callback to run
   * @param properties The properties that should change before running. If blank, runs for any update.
   * @param runImmediately Should this validator be run now as well.
   * @returns The created validator
   */
  addValidator(callback: StateValidatorCallback<TState>, properties?: Omit<StatePropertiesDef<TState>, 'otherStates'>, runImmediately: boolean = true) {
    const validator = new StateValidator(this, callback, properties, runImmediately);
    this.#validators.push(validator);
    return validator;
  }

  /**
   * Removes the given validator
   * @param validator The validator to remove
   * @returns True if the validator was removed, false otherwise
   */
  removeValidator(validator: StateValidator<TState>) {
    const index = this.#validators.indexOf(validator);
    if (index !== -1) {
      this.#validators.splice(index, 1);
      return true;
    }
    return false;
  }

  /**
   * Creates a StateObserver (updater) on this state.
   * @param callback The callback to run
   * @param properties The properties to watch for changes before running
   * @param runImmediately Should this updater run now?
   * @returns The created updater
   */
  addUpdater(callback: (state: this) => void, properties: StatePropertiesDef<TState> = {}, runImmediately?: boolean) {
    const observer = new StateObserver([new StateProperties(this, properties)] as const, false, () => callback(this), runImmediately);
    return observer;
  }

  /**
   * Creates a late StateObserver (watcher) on this state.
   * @param callback The callback to run
   * @param properties The properties to watch for changes before running
   * @param runImmediately Should this watcher run now?
   * @returns The created updater
   */
  addWatcher(callback: (state: this) => void, properties: StatePropertiesDef<TState> = {}, runImmediately?: boolean) {
    const observer = new StateObserver([new StateProperties(this, properties)] as const, true, () => callback(this), runImmediately);
    return observer;
  }

  /**
   * Utility function to check if a property from another state has changed.
   * @param otherState The name of the other state to check
   * @param target The property to check. To check a signal, start it with @
   * @returns True if the property is different from last update
   */
  hasChanged<K extends keyof TState['otherStates']>(otherState: K, target: keyof TState['otherStates'][K]['state']) {
    if (this.changed.otherStates[otherState]) {
      return true;
    }
    if (this.otherStates[otherState] === null) {
      return false;
    }
    return this.otherStates[otherState]?.changed.properties[target];
  }

  /** Internal: Commits the waiting changes */
  commit() {
    this.#changed = this.#nextChanged;

    this.#preventEmittingUpdates = true;
    for (let i = 0; i < this.validators.length; i++) {
      this.validators[i].onStateUpdate();
    }
    this.#preventEmittingUpdates = false;

    Object.assign(this.#currentValues, this.#values);

    this.#nextChanged = { properties: {}, signals: {}, otherStates: {} };
    this.#hasUpdatesWaiting = false;
    this.#lastUpdatedOperationId = this.manager?.currentOperationId ?? null;
  }

  /** Internal: Commits everything at the end of an update cycle, allowing watchers to run. */
  commitBatch() {
    this.#changed = this.#batchedNextChanged;
    this.#batchedNextChanged = { properties: {}, signals: {}, otherStates: {} };
    this.#lastUpdatedOperationId = this.manager?.currentOperationId ?? null;
  }

  /** Destroys this state */
  destroy() {
    stateRegister.unregisterState(this);
    this.#destroyed = true;
    this.dependentObservers.forEach((observerId) => {
      const observer = stateRegister.getStateObserver(observerId);
      observer?.destroy();
    });
    this.dependentLateObserver.forEach((observerId) => {
      const observer = stateRegister.getStateObserver(observerId);
      observer?.destroy();
    });
  }
}
