import React, { useState } from 'react';
import { ConfirmLeaveContextResult } from './constants';
import ConfirmCloseModal from './confirm-close-modal';

export type LeaveContextParams = {
  title: string;
  text: string;
  callback: AttemptToLeaveCallback;
  withoutSavingButtonText?: string;
  withSavingButtonText?: string;
  invalidMessage?: string;
};

export type AttemptToLeaveCallback = (shouldConfirmLeaveCb: (confirmBeforeLeave: boolean) => Promise<ConfirmLeaveContextResult>) => Promise<boolean>;

export interface iLeaveContextConformation {
  attemptLeave: (performLeave: () => void) => void;
  modal: React.ReactNode | null;
  setLeaveContextParams: (params: LeaveContextParams | null) => void;
}

export function useLeaveContextConfirmation(): iLeaveContextConformation {
  const [leaveContextParams, setLeaveContextParams] = useState<LeaveContextParams | null>(null);
  const [confirmLeaveCallback, setConfirmLeaveCallback] = useState<((res: ConfirmLeaveContextResult) => void) | null>(null);

  const attemptLeave = (performLeave: () => void) => {
    if (leaveContextParams === null) {
      // No confirmation callback set so just leave
      performLeave();
      return;
    }

    leaveContextParams
      .callback((confirmBeforeLeaveCb) => {
        return new Promise<ConfirmLeaveContextResult>((resolve) => {
          if (!confirmBeforeLeaveCb) {
            // Don't need to confirm with confirmation modal
            resolve(ConfirmLeaveContextResult.NoChanges);
            return;
          }

          // Set the confirmation modal callback, this will cause the confirmation modal to appear
          setConfirmLeaveCallback(() => (result: ConfirmLeaveContextResult) => {
            // When the confirmation modal executes this
            // callback with a result, then we resolve the promise
            // and close the modal by setting 'confirmLeaveCallback' back to null
            resolve(result);
            setConfirmLeaveCallback(null);
          });
        });
      })
      .then((shouldLeave) => {
        if (shouldLeave) {
          performLeave();
        }
      });
  };

  const onConfirmLeaveModalClose = (result: ConfirmLeaveContextResult) => {
    if (confirmLeaveCallback !== null) {
      confirmLeaveCallback(result);
    }
  };

  let modal: React.ReactNode | null = null;
  if (confirmLeaveCallback !== null && leaveContextParams !== null) {
    modal = (
      <ConfirmCloseModal
        title={leaveContextParams.title}
        text={leaveContextParams.text}
        closeModal={onConfirmLeaveModalClose}
        withoutSavingButtonText={leaveContextParams.withoutSavingButtonText}
        withSavingButtonText={leaveContextParams.withSavingButtonText}
        invalidMessage={leaveContextParams.invalidMessage}
      />
    );
  }

  return { attemptLeave, modal, setLeaveContextParams };
}
