import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ResourceContext, userPlantingCalendarsNotNull } from '@gi/resource-provider';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { updateFilters } from '@gi/filters';

import { DrawingToolsContext } from '../drawing-tools-context';
import createPlantFilters from './filters/create-plant-filters';

const ADJECTIVES_REGEX = /\b(red|orange|yellow|green|purple|white)\b/gi;

// A list of regex -> garden object name, to be used when no search results are found, to check if
//  the user is accidentally searching for garden objects in the wrong place.
const GARDEN_OBJECTS_REGEX: [RegExp, string][] = [
  [/^raised( | b| be| bed| beds)?$/i, 'Raised Beds'],
  [/^paths?$/i, 'Paths'],
  [/^fences?$/i, 'Fences'],
];

const PlantListNoResults = (): JSX.Element | null => {
  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);
  const { plantSearchService, userPlantingCalendars } = useContext(ResourceContext);
  const { filteredPlants, plantFilters, setPlantFilters, resetPlantFilters } = useContext(DrawingToolsContext);
  const notNullUserPlantingCalendars = userPlantingCalendarsNotNull(userPlantingCalendars);

  if (filteredPlants.length > 0) {
    return null;
  }

  /**
   * Renders a message when there are no results, and there are no suggestions.
   */
  const renderNoResults = (): JSX.Element => {
    return (
      <p>
        No plants found with your current filters, you can{' '}
        <button type='button' className='button button-inline' onClick={resetPlantFilters}>
          reset
        </button>{' '}
        your filters to try again.
      </p>
    );
  };

  /**
   * Renders a message saying there are no results. Also checks the search term for common garden
   * objects, and adds a message if the user seems to be searching for garden objects.
   */
  const renderNoResultsCheckObjects = (): JSX.Element => {
    const { searchTerm } = plantFilters.filters.search.inputs;
    const matched = GARDEN_OBJECTS_REGEX.find(([regex]) => regex.test(searchTerm));

    if (!matched) {
      return renderNoResults();
    }

    return (
      <>
        {renderNoResults()}
        <p>
          Looking for {matched[1]}? These can be found in the <i className='icon-garden-objects-black' />
          Garden Objects list.
        </p>
      </>
    );
  };

  /**
   * Renders a message when a search did return results, but they were all hidden by other filters.
   * @param resultsCount The amount of results that are currently hidden
   */
  const renderResultsHidden = (resultsCount: number): JSX.Element => {
    const clearOtherFilters = () => {
      // Create blank filters (but enable tropical), then copy over the search filter settings.
      const defaultFilters = createPlantFilters(sfgMode, notNullUserPlantingCalendars, true);
      const newFilters = updateFilters(defaultFilters, {
        search: {
          searchTerm: plantFilters.filters.search.inputs.searchTerm,
          searchResults: plantFilters.filters.search.inputs.searchResults,
        },
      });
      setPlantFilters(newFilters);
    };

    return (
      <>
        <p>
          Your search returned {resultsCount} result{resultsCount === 1 ? '' : 's'}, but {resultsCount === 1 ? 'it was ' : 'they were'} hidden due to your
          current filters.
        </p>
        <p>
          You can{' '}
          <button type='button' className='button button-inline' onClick={clearOtherFilters}>
            show all results
          </button>
          {' or '}
          <button type='button' className='button button-inline' onClick={resetPlantFilters}>
            reset
          </button>{' '}
          your filters to try again.
        </p>
      </>
    );
  };

  const { searchTerm, searchResults } = plantFilters.filters.search.inputs;

  // Make no suggestions if search is empty/short
  if (!searchTerm || searchTerm.length <= 1) {
    return renderNoResults();
  }

  // Search had results, but all were hidden
  if (searchResults.count > 0) {
    return renderResultsHidden(searchResults.count);
  }

  const modifiedSearchTerm = searchTerm.replaceAll(ADJECTIVES_REGEX, '');
  const suggestedSearchTerm = modifiedSearchTerm.replaceAll(/\s\s+/g, ' ').trim();

  // If suggestion is unchanged/blank, just return no results.
  if (modifiedSearchTerm === searchTerm || suggestedSearchTerm.length === 0) {
    return renderNoResultsCheckObjects();
  }

  const results = plantSearchService.search(suggestedSearchTerm);

  // If the new suggestion has no results, there's no point in suggesting it
  if (results.count === 0) {
    return renderNoResultsCheckObjects();
  }

  const useSuggestion = () => {
    setPlantFilters(
      updateFilters(plantFilters, {
        search: {
          searchTerm: suggestedSearchTerm,
          searchResults: results,
        },
        sort: {
          enabled: suggestedSearchTerm.length <= 1,
        },
      })
    );
  };

  return (
    <>
      {renderNoResults()}
      <p>
        Try searching for &quot;
        <button type='button' className='button button-inline' onClick={useSuggestion}>
          {suggestedSearchTerm}
        </button>
        &quot; instead?
      </p>
    </>
  );
};

export default PlantListNoResults;
