import React, { useContext } from 'react';
import { PERENNIAL } from '@gi/constants';
import { updateFilters } from '@gi/filters';

import { DrawingToolsContext } from '../../../drawing-tools-context';

const PerennialCheckboxFilter = (): JSX.Element => {
  const { plantFilters, setPlantFilters } = useContext(DrawingToolsContext);

  const onChange = (e) => {
    setPlantFilters(
      updateFilters(plantFilters, {
        perennial: {
          enabled: e.target.checked,
        },
      })
    );
  };

  const perennialTypeSelected = plantFilters.filters.type.inputs.type === PERENNIAL;

  return (
    <label className={`filter-row-label filter-row-checkbox filter-row-multi-item ${perennialTypeSelected ? 'is-disabled' : ''}`}>
      <span className='filter-row-label-content'>
        <input
          className='filter-box'
          type='checkbox'
          onChange={onChange}
          checked={plantFilters.filters.perennial.inputs.enabled || perennialTypeSelected}
          disabled={perennialTypeSelected}
        />
      </span>
      <span className='filter-row-label-text'>Include Perennials</span>
    </label>
  );
};

export default PerennialCheckboxFilter;
