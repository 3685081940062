import React, { useMemo } from 'react';
import TutorialButton from './tutorial-button';
import { RunningTutorial } from '../tutorial';

import styles from './tutorial-display.module.css';
import TutorialContent from './content/tutorial-content';

interface iProps {
  runningTutorial: RunningTutorial | null;
  stopTutorial: (() => void) | null;
  next: (() => void) | null;
  previous: (() => void) | null;
}

const TutorialPane = ({ runningTutorial, stopTutorial, next, previous }: iProps) => {
  const style = useMemo(() => {
    const computedStyles: React.CSSProperties = {
      width: 400,
    };

    if (!runningTutorial) {
      return computedStyles;
    }

    if (runningTutorial.current.width) {
      computedStyles.width = runningTutorial.current.width;
    }

    return computedStyles;
  }, [runningTutorial]);

  if (!runningTutorial) {
    return null;
  }

  return (
    <div className={styles.tutorialPane}>
      <div className={styles.tutorialTopControls}>
        {stopTutorial ? (
          <button type='button' className={styles.tutorialCancelButton} onClick={stopTutorial}>
            <i className='icon-cancel' />
          </button>
        ) : null}
      </div>
      <div className={styles.tutorialContentContainer} style={style}>
        <TutorialContent
          content={runningTutorial.current.content}
          tutorialUuid={runningTutorial.tutorial.uuid}
          tutorialStepIndex={runningTutorial.currentIndex}
          tutorialContentIndex={0}
        />
      </div>
      <div className={styles.tutorialWindowControls}>
        <div className={`${styles.leftControl}`}>{previous ? <TutorialButton onClick={previous}>Back</TutorialButton> : null}</div>
        <div className={`${styles.counterContainer} ${styles.middleControl}`}>
          {runningTutorial.currentIndex + 1} / {runningTutorial.tutorial.steps.length}
        </div>
        <div className={`${styles.rightControl}`}>
          {next ? <TutorialButton onClick={next}>Next</TutorialButton> : stopTutorial ? <TutorialButton onClick={stopTutorial}>Complete</TutorialButton> : null}
        </div>
      </div>
    </div>
  );
};

export default TutorialPane;
