import { NetworkConfig, NetworkDataLocationConfig } from '@gi/config';

function tryMakeURL(...args: ConstructorParameters<typeof URL>): URL | null {
  try {
    return new URL(...args);
  } catch {
    return null;
  }
}

/**
 * Merges a set of URL overrides into the given network config, returning the new set of data locations.
 * @param networkConfig The network config to use
 * @param overrides Any URL overrides to use
 * @returns A modified copy of the configs "data" property, with the overwritten URLs inserted
 */
export function mergeDataLocationOverrides(networkConfig: NetworkConfig, overrides?: Partial<NetworkDataLocationConfig>): NetworkDataLocationConfig {
  const urls: NetworkDataLocationConfig = { ...networkConfig.data };

  if (!overrides) {
    return urls;
  }

  const baseUrl = tryMakeURL(networkConfig.dataBaseURL, window.location.origin);
  if (!baseUrl) {
    console.warn(`Failed to generate base URL using '${networkConfig.dataBaseURL}'`);
    return urls;
  }

  const keys = Object.keys(overrides) as (keyof NetworkDataLocationConfig)[];

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    // Check if the override is an absolute URL. If so, use that
    const absoluteUrl = tryMakeURL(overrides[key]!);
    if (absoluteUrl) {
      urls[key] = absoluteUrl.href;
      // eslint-disable-next-line no-continue
      continue;
    }

    // Try to construct a relative URL from the override and the base URL.
    const relativeUrl = tryMakeURL(overrides[key]!, baseUrl);
    if (relativeUrl) {
      urls[key] = relativeUrl.href;
      // eslint-disable-next-line no-continue
      continue;
    }

    // We've failed to construct the URL, do nothing.
    console.warn(`Failed to generate valid URL override for data source '${key}': ${overrides[key]}`);
  }

  return urls;
}
