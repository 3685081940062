import EventEmitter from 'eventemitter3';

class EventBus<TEventDefinitions extends { [key: string]: (...args: any[]) => void }> {
  eventEmitter: EventEmitter;

  constructor(oldEventBus?: EventBus<any>) {
    this.eventEmitter = oldEventBus ? oldEventBus.eventEmitter : new EventEmitter();
  }

  once<T extends keyof TEventDefinitions>(eventName: T, callback: TEventDefinitions[T]) {
    // Fixed :)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.eventEmitter.once(eventName, callback);
  }

  on<T extends keyof TEventDefinitions>(eventName: T, callback: TEventDefinitions[T]) {
    // Fixed :)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.eventEmitter.on(eventName, callback);
  }

  off<T extends keyof TEventDefinitions>(eventName: T, callback: TEventDefinitions[T]) {
    // Fixed :)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.eventEmitter.off(eventName, callback);
  }

  // off<K extends keyof T>(eventName: K, callback: T[K]) {
  //   this.eventEmitter.off(eventName, callback);
  // }

  emit<T extends keyof TEventDefinitions>(eventName: T, ...params: Parameters<TEventDefinitions[T]>) {
    // console.log(`Emitting ${String(eventName)}`);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.eventEmitter.emit(eventName, ...params);
  }
}

export default EventBus;
