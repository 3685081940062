import { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const root = document.getElementById('modal-root');

class TexturePickerPortal extends PureComponent {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    root.appendChild(this.el);
  }

  componentWillUnmount() {
    root.removeChild(this.el);
  }

  renderContents() {
    return this.props.children;
  }

  render() {
    return createPortal(this.renderContents(), this.el);
  }
}

TexturePickerPortal.propTypes = {
  children: PropTypes.node,
};

TexturePickerPortal.defaultProps = {
  children: null,
};

export default TexturePickerPortal;
