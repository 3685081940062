import React from 'react';

import ConfirmModalWithUnsavedPlans from '@gi/confirm-modal-with-unsaved-plans';

interface iProps {
  onCancel: () => void;
}

const RenderModeChangedModal = ({ onCancel }: iProps): JSX.Element => {
  // Can't display a loading state here, as the user may cancel the reload via dialog box if plans have unsaved changes.

  const refresh = () => {
    window.location.reload();
  };

  return (
    <ConfirmModalWithUnsavedPlans title='Render Mode Changed' onCancel={onCancel} cancelButtonText='Later' onConfirm={refresh} confirmButtonText='Refresh Now'>
      <p>In order for some changes to take effect, the garden glanner must be refreshed.</p>
      <p>This will close all your open plans, and reload the page.</p>
      <p>If you choose not to refresh, your changes will take effect next time you load the garden planner.</p>
    </ConfirmModalWithUnsavedPlans>
  );
};

export default RenderModeChangedModal;
