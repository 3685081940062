import React from 'react';

import { createEventsContext, createEventsProvider } from '@gi/events';

import useNavigationChangeEventHook from './utils/use-navigation-change-event-hook';
import { GardenPlatformEvent, GardenPlatformEventParameters } from './types';

export const GardenPlatformEventsContext = createEventsContext<GardenPlatformEvent, GardenPlatformEventParameters>();
export const GardenPlatformEventsProviderInner = createEventsProvider(GardenPlatformEventsContext);

export const GardenPlatformEventsProvider = (props: Parameters<typeof GardenPlatformEventsProviderInner>[0]): JSX.Element => {
  useNavigationChangeEventHook();

  return <GardenPlatformEventsProviderInner {...props} />;
};
