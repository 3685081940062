import { Store } from 'redux';
import Plan, { PlanSet, PlanSetUtils, PlanUtils } from '@gi/plan';
import { CanvasReducerState } from '../redux-components/canvas-reducer';

interface BaseStoreState {
  canvas: CanvasReducerState;
}

/**
 * Returns true if the plan has unsaved changes (when compared to the last saved version of that plan)
 *
 * Performs a shallow equals, so values may be the same but if they reference different objects the
 * function will still return false
 */
const planHasUnsavedChanges = (plan: Plan, lastSavedPlan: Plan): boolean => {
  if (plan === null || lastSavedPlan === null) {
    // One or both plans are not loaded, we can't do a comparison
    return false;
  }

  return !PlanUtils.plansShallowEqual(plan, lastSavedPlan);
};

/**
 * Returns true if the given open plans have unsaved changes
 */
const hasAnyUnsavedChanges = (openPlanIDs: number[], plans: PlanSet, lastSavePlans: PlanSet): boolean => {
  for (let i = 0; i < openPlanIDs.length; i++) {
    const plan = PlanSetUtils.planSetGetPlan(plans, openPlanIDs[i]);
    const lastSavedPlan = PlanSetUtils.planSetGetPlan(lastSavePlans, openPlanIDs[i]);

    if (plan === null || lastSavedPlan === null) {
      // One or both plans are not loaded, we can't do a comparison
      // eslint-disable-next-line no-continue
      continue;
    }

    if (planHasUnsavedChanges(plan, lastSavedPlan)) {
      return true;
    }
  }

  return false;
};

/**
 * Adds a window listener to 'beforeunload' to add a warning before users close the tab
 * if there are unsaved changes
 *
 * @param {Store} store
 */
const setupWindowCloseListener = (store: Store<BaseStoreState>): void => {
  window.addEventListener('beforeunload', (e) => {
    const state = store.getState();

    if (!hasAnyUnsavedChanges(state.canvas.openPlanIDs, state.canvas.plans, state.canvas.lastSavePlans)) {
      return false;
    }

    const confirmationMessage = 'You have unsaved changes in the Garden Planner, are you sure you want to close?';

    (e || window.event).returnValue = confirmationMessage; // Gecko + IE
    return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
  });
};

export default setupWindowCloseListener;
