import { Container, Sprite, Texture } from 'pixi.js-new';

import { Geometry } from '@gi/math';
import { State, StateDef } from '@gi/state';

import GraphicNode from '../../graphics-node';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { bindState } from '../../utils/state-utils';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';

export type PathSpriteComponentState = StateDef<{
  start: Vector2;
  end: Vector2;
  control: Vector2 | null;
  thickness: number;
  segmentSize: number;
}>;

const DEFAULT_STATE: PathSpriteComponentState['state'] = {
  start: { x: 0, y: 0 },
  end: { x: 0, y: 0 },
  control: null,
  thickness: 30,
  segmentSize: 30,
};

class PathSpriteComponent extends NodeComponent<GraphicNode> {
  type = 'PathSpriteComponent';

  state: State<PathSpriteComponentState>;

  #sprites: Sprite[];
  #container: Container;

  #textures: Texture[] = [];
  #endTextures: Texture[] = [];

  constructor(state?: Partial<PathSpriteComponentState['state']>) {
    super();

    this.#container = new Container();
    this.#container.interactiveChildren = false;
    this.#sprites = [];

    this.state = new State({
      ...DEFAULT_STATE,
      ...state,
    });
    bindState(this.state, this);
    this.state.addWatcher(() => this.#update(), {}, false);

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
    this.eventBus.on(NodeComponentEvent.Destroyed, this.#onDestroy);
  }

  #onBind = () => {
    this.#update();
  };

  #onBeforeUnbind = () => {
    this.#sprites.forEach((sprite) => sprite.destroy());
    this.#sprites = [];
  };

  #onDestroy = () => {
    this.#container.destroy();
  };

  // Returns the container that contains all the sprites to display.
  getContainer() {
    return this.#container;
  }

  get textures() {
    return this.#textures;
  }
  set textures(textures: Texture[]) {
    this.#textures = textures;
    this.#endTextures = textures.map((texture) => texture.clone());
    this.#update();
  }

  // Returns the sprite at the given index, or creates it if not exists
  #getOrCreateSprite(index: number): Sprite {
    if (this.#sprites.length > index && this.#sprites[index]) {
      return this.#sprites[index];
    }
    const sprite = new Sprite(this.textures[index % this.textures.length] ?? Texture.WHITE);
    this.#sprites[index] = sprite;
    this.#container.addChild(sprite);
    return sprite;
  }

  // Returns the sprite at the given index, or creates it if not exists
  #getOrCreateTexture(index: number): Texture {
    return this.textures[index % this.textures.length] ?? Texture.WHITE;
  }

  // Updates the sprites to match the state
  #update() {
    if (!this.bound) {
      return;
    }

    const { start, end, control, thickness, segmentSize } = this.state.values;
    const controlPoint = control === null ? Geometry.midpoint(start, end) : control;
    const { segments } = Geometry.getBezierSegmentsAndLength(start, controlPoint, end, segmentSize, 10);

    for (let i = 0; i < segments.length - 1; i++) {
      const isFinalSegment = i === segments.length - 2;

      const segmentStart = segments[i];
      const segmentEnd = segments[i + 1];

      const rotation = Geometry.segmentRotation({ x: segmentStart.x, y: segmentStart.y }, { x: segmentEnd.x, y: segmentEnd.y });

      const sprite = this.#getOrCreateSprite(i);
      sprite.anchor.set(0, 0.5);
      sprite.visible = true;
      sprite.x = segmentStart.x;
      sprite.y = segmentStart.y;
      sprite.rotation = rotation;
      sprite.width = segmentSize;
      sprite.height = thickness;
      sprite.texture = this.#getOrCreateTexture(i);

      // Clip the end segment
      if (isFinalSegment) {
        const width = Geometry.dist(segmentStart, segmentEnd);
        const endTexture = this.#endTextures[i % this.#endTextures.length] ?? Texture.WHITE;

        if (endTexture !== Texture.WHITE) {
          endTexture.frame.width = width * 2;
          endTexture.updateUvs();
        }
        sprite.width = width;
        sprite.texture = endTexture;
      }
    }

    // Delete unused sprites
    if (this.#sprites.length > segments.length - 2) {
      const unusedSprites = this.#sprites.splice(segments.length - 1, this.#sprites.length - (segments.length - 2));
      unusedSprites.forEach((sprite) => {
        sprite.destroy();
      });
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default PathSpriteComponent;
