import React, { useMemo } from 'react';

import { IS_MAC } from '@gi/constants';

import squareHandleSrc from './square-handle.png';
import roundHandleSrc from './round-handle.png';

import styles from './interaction-mode-preview.module.scss';

const WINDOWS_CURSOR = (
  <polygon
    style={{ fill: 'rgb(255, 255, 254)', stroke: 'rgb(0, 0, 1)', strokeMiterlimit: 10 }}
    points='11.973 12.746 7.038 12.746 9.065 16.869 6.12 18.362 3.86 13.728 0.5 17.004 0.5 1.212'
  />
);

const MAC_CURSOR = (
  <>
    <path d='M 6.137 18.066 L 8 17.063 L 9.615 16.224 L 7.047 11.408 L 11.379 11.408 L 0 0 L 0 16.015 L 3.316 12.794 L 6.137 18.066 Z' fill='#fff' />
    <path d='M 6.42 16.593 L 8.185 15.652 L 5.41 10.45 L 9.014 10.45 L 0.989 2.407 L 0.989 13.595 L 3.519 11.153 L 6.42 16.593 Z' fill='#000' />
  </>
);

const FINGER = (
  <>
    <ellipse
      style={{ stroke: '#ffffff33', fill: '#ffffff4d', strokeWidth: '8px', visibility: 'hidden' }}
      cx='16.801'
      cy='20.934'
      rx='17'
      ry='17'
      data-elem='touchpoint'
    />
    <path
      style={{ stroke: '#ffffff', fill: '#a1a1a154', strokeWidth: '2px' }}
      d='M 1.815 67.934 L 1.815 22.934 C 1.815 -0.106 31.815 -0.377 31.815 22.934 L 31.815 67.934'
    />
    <path
      style={{ stroke: '#ffffff', fill: 'none', strokeWidth: '2px' }}
      d='M 16.801 9.7 H 16.801 A 11.025 10.939 0 0 1 27.825 20.639 V 31.577 H 5.776 V 20.639 A 11.025 10.939 0 0 1 16.801 9.7 Z'
    />
  </>
);

const CURSOR = IS_MAC ? MAC_CURSOR : WINDOWS_CURSOR;

interface iProps {
  touchMode?: boolean;
  useFinger?: boolean;
  className?: string;
}

const InteractionModePreview = ({ touchMode = false, useFinger = touchMode, className }: iProps): JSX.Element => {
  const handles = useMemo(() => {
    if (touchMode) {
      return (
        <div className={styles.handles} data-elem='handles'>
          <img src={squareHandleSrc} width={38} height={38} className={styles.handle} alt='' />
          <img src={squareHandleSrc} width={38} height={38} className={styles.handle} alt='' />
          <img src={squareHandleSrc} width={38} height={38} className={styles.handle} alt='' />
          <img src={squareHandleSrc} width={38} height={38} className={styles.handle} alt='' />
          <img src={roundHandleSrc} width={38} height={38} className={styles.handle} alt='' />
          <img src={roundHandleSrc} width={38} height={38} className={styles.handle} alt='' />
        </div>
      );
    }
    return (
      <div className={styles.handles} data-elem='handles'>
        <div className={styles.handle} />
        <div className={styles.handle} />
        <div className={styles.handle} />
        <div className={styles.handle} />
        <div className={styles.handle} />
        <div className={styles.handle} />
      </div>
    );
  }, [touchMode]);

  const cursor = useMemo(() => {
    if (useFinger) {
      return (
        <svg className={styles.finger} data-elem='cursor' width={60} viewBox='-4.801 0 41.996 91.439' xmlns='http://www.w3.org/2000/svg'>
          {FINGER}
        </svg>
      );
    }

    return (
      <svg className={styles.cursor} data-elem='cursor' viewBox='0 0 32 36' xmlns='http://www.w3.org/2000/svg' width={38} height={36}>
        {CURSOR}
      </svg>
    );
  }, [useFinger]);

  const classNames = [styles.interactionModePreview];
  if (touchMode) {
    classNames.push(styles.touchMode);
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      <div className={styles.objectOutline} data-elem='object-outline'>
        <div className={styles.object} data-elem='object' />
        {handles}
        {cursor}
      </div>
    </div>
  );
};

export default InteractionModePreview;
