import React from 'react';

import { ModalPaneSection, ModalPaneSectionHeader } from '@gi/modal';

import HelpModalAction from '../components/help-modal-action';

interface iProps {
  hideHeader?: boolean;
}

const ItemLayersHelp = ({ hideHeader = false }: iProps): JSX.Element => {
  return (
    <ModalPaneSection>
      {hideHeader ? null : <ModalPaneSectionHeader>Ordering Items</ModalPaneSectionHeader>}
      <p>
        Items can be made to display in-front of, or behind other items of the same type using the{' '}
        <HelpModalAction>
          <i className='icon-order-back' /> Send to Back
        </HelpModalAction>{' '}
        and{' '}
        <HelpModalAction>
          <i className='icon-order-front' /> Bring to Front
        </HelpModalAction>{' '}
        buttons.
      </p>
      <p>
        Items can only be made to display in-front-of or behind other items of the same type. For example, bringing a plant to the front will make it display in
        front of all other plants, but it will still display behind any structures.
      </p>
    </ModalPaneSection>
  );
};

export default ItemLayersHelp;
