import { updateFilters } from '@gi/filters';
import React, { useContext } from 'react';
import ColumnSortIcon from '../../common/column-sort-buttons/column-sort-icon';
import { DISPLAY_TYPE } from '../../constants';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import { SORT_ORDER } from '../../types/plant-list-types';

const PlantNameHeaderCell = () => {
  const { displayType, plantGroupFilters, setPlantGroupFilters, varietyGroupFilters, setVarietyGroupFilters, areaGroupFilters, setAreaGroupFilters } =
    useContext(GlobalPlantListContext);

  let currentSortType = plantGroupFilters.filters.sort.inputs.sortOrder;
  if (displayType === DISPLAY_TYPE.Variety) {
    currentSortType = varietyGroupFilters.filters.sort.inputs.sortOrder;
  } else if (displayType === DISPLAY_TYPE.Area) {
    currentSortType = areaGroupFilters.filters.sort.inputs.sortOrder;
  }

  return (
    <th className='header-cell left-align-cell'>
      <div className='header-cell-content'>
        <div className='header-row'>
          Plant
          <ColumnSortIcon
            upSortType={SORT_ORDER.PlantNameAsc}
            downSortType={SORT_ORDER.PlantNameDesc}
            currentSortType={currentSortType}
            setSortOrder={(sortOrder: SORT_ORDER) => {
              setPlantGroupFilters(updateFilters(plantGroupFilters, { sort: { sortOrder } }));
              setVarietyGroupFilters(updateFilters(varietyGroupFilters, { sort: { sortOrder } }));
              setAreaGroupFilters(updateFilters(areaGroupFilters, { sort: { sortOrder } }));
            }}
          />
        </div>
      </div>
    </th>
  );
};

export default PlantNameHeaderCell;
