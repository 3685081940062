/**
 * Attempts to download a given data URL to a users machine
 *
 * May require user permissions
 */
export function fileDownload(dataURL: string, filename: string) {
  const link = document.createElement('a');
  link.href = dataURL;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function fileContentsDownload(data: any, type: string, filename: string) {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  fileDownload(url, filename);
  window.URL.revokeObjectURL(url);
}
