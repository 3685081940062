import React from 'react';

import styles from './guru-section-title.module.css';

interface iProps {
  children: React.ReactNode;
}

const GuruSectionTitle = ({ children }: iProps): JSX.Element => {
  return <h2 className={styles.guruSectionTitle}>{children}</h2>;
};

export default GuruSectionTitle;
