import React, { ReactNode, useContext } from 'react';

import { LoadingState } from '@gi/constants';
import { ResourceLoaderContext } from '@gi/resource-provider';

import styles from './objectives-pane.module.css';

interface iProps {
  children?: ReactNode;
}

const ObjectivesPaneLoadingGate = ({ children }: iProps): JSX.Element => {
  const { objectives } = useContext(ResourceLoaderContext);

  switch (objectives.status) {
    case LoadingState.SUCCESS: {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{children}</>;
    }
    case LoadingState.NONE: {
      return (
        <div className={styles.loadingGate}>
          <span className={styles.loadingIcon}>
            <i className='icon-warning' />
          </span>
          <p>Milestones have not been loaded yet...</p>
          <button type='button' className='button button-borderless' onClick={objectives.load}>
            Load Milestones
          </button>
        </div>
      );
    }
    case LoadingState.ERROR: {
      return (
        <div className={styles.loadingGate}>
          <span className={styles.loadingIcon}>
            <i className='icon-warning' />
          </span>
          <p>There was an error while trying to load Milestones</p>
          <p className={styles.loadingError}>
            Error: {'clientMessage' in objectives.error && objectives.error.clientMessage ? (objectives.error.clientMessage as string) : 'Unknown error'}
          </p>
          <button type='button' className='button button-borderless' onClick={objectives.load}>
            Load Milestones
          </button>
        </div>
      );
    }
    case LoadingState.LOADING:
    default: {
      return (
        <div className={styles.loadingGate}>
          <span className={styles.loadingIcon}>
            <i className='icon-spinner animate-pulse' />
          </span>
          <p>Loading Milestones...</p>
        </div>
      );
    }
  }
};

export default ObjectivesPaneLoadingGate;
