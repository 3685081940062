import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Drawer, DrawerContent, DrawerHeader } from '@gi/mobile-components';
import FormField, { FORM_FIELD_PRESETS, FormFieldOptionsPreset, FormLayout, FormSection, InputContainer } from '@gi/form-responsive';
import { CanvasActionCreators, CanvasSelectors, CropRotationSelector, LayerSelector, MonthSelector } from '@gi/react-garden-canvas';
import { SessionSelectors } from '@gi/react-session';
import { LocalSettingsActionCreators, LocalSettingsSelectors } from '@gi/local-settings';
import { DrawingToolsContext } from '@gi/drawing-tools';
import { HelpButton } from '@gi/base-components';
import { CropRotationHelpModal, LayerSelectorInfoModal, MonthSelectorInfoModal } from '@gi/help-modals';
import { useHelpModalEventHook } from '@gi/garden-platform-events';
import { PlannerControlsContext } from '@gi/planner-controls';
import { PlannerControlsTab } from '@gi/constants';

const DROPDOWN_INPUT_LAYOUT: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
    labelSize: 130,
    labelAlignY: 'center',
    inputSize: 'full',
  },
  mobile: {
    layout: 'column',
    labelAlignY: 'center',
  },
};

interface iProps {
  open: boolean;
  onClose: () => void;
}

const PlannerSettingsDrawer = ({ open, onClose }: iProps): JSX.Element => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const isNorthernHemisphere = useSelector(SessionSelectors.getIsNorthernHemisphere);
  const snapToGrid = useSelector(LocalSettingsSelectors.getSnapToGrid);
  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);

  const { setSfgHelpModalOpen } = useContext(DrawingToolsContext);
  const { setActiveDrawer } = useContext(PlannerControlsContext);

  const [showMonthSelectorHelpModal, setShowMonthSelectorHelpModal] = useState(false);
  const [showCropRotationHelpModal, setShowCropRotationHelpModal] = useState(false);
  const [showLayerSelectorHelpModal, setShowLayerSelectorHelpModal] = useState(false);

  const dispatch = useDispatch();

  useHelpModalEventHook('month selector', showMonthSelectorHelpModal, 'mobile-planner-settings-drawer');
  useHelpModalEventHook('crop rotation', showCropRotationHelpModal, 'mobile-planner-settings-drawer');
  useHelpModalEventHook('layers', showLayerSelectorHelpModal, 'desktop-planner-menu-help-button');

  const toggleRulerVisibility = () => {
    if (activePlan) {
      dispatch(CanvasActionCreators.setRulerVisibility(activePlan, !activePlan.plannerSettings.showRulers));
    }
  };

  const toggleGridVisibility = () => {
    if (activePlan) {
      dispatch(CanvasActionCreators.setGridVisibility(activePlan, !activePlan.plannerSettings.showGrid));
    }
  };

  const toggleSFGMode = () => {
    dispatch(LocalSettingsActionCreators.setSFGMode(!sfgMode));
  };

  const toggleSnapToGrid = () => {
    dispatch(LocalSettingsActionCreators.setSnapToGrid(!snapToGrid));
  };

  const showBackgroundImageDrawer = useCallback(() => {
    setActiveDrawer(PlannerControlsTab.BACKGROUND_IMAGE);
    onClose();
  }, [onClose, setActiveDrawer]);

  const labelWithHelpButton = useCallback((text: string, onClick: () => void) => {
    return (
      <FormLayout desktopLayout={{ layout: 'row', yAlign: 'center', gap: 3 }} mobileLayout={{ layout: 'row-reverse', yAlign: 'center', gap: 3 }}>
        <HelpButton onClick={onClick} />
        <span>{text}</span>
      </FormLayout>
    );
  }, []);

  const showCropRotation = activePlan && activePlan.history.length > 0;

  return (
    <>
      <Drawer open={open} onClose={onClose} width={500} anchor='RIGHT'>
        <DrawerHeader as='h2' onClose={onClose}>
          Planner Options
        </DrawerHeader>
        <DrawerContent padX padY>
          <FormSection padding={0} gap={12}>
            <FormLayout mobileLayout={{ layout: 'column', xAlign: 'stretch', gap: 0 }} desktopLayout={{ layout: 'column', xAlign: 'stretch', gap: 0 }}>
              <FormField label='Show Rulers' htmlFor='show-rulers' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
                <input
                  type='checkbox'
                  id='show-rulers'
                  disabled={!activePlan}
                  checked={activePlan?.plannerSettings.showRulers}
                  onChange={toggleRulerVisibility}
                />
              </FormField>
              <FormField label='Show Grid' htmlFor='show-grid' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
                <input type='checkbox' id='show-grid' disabled={!activePlan} checked={activePlan?.plannerSettings.showGrid} onChange={toggleGridVisibility} />
              </FormField>
              <FormField label='SFG Mode' htmlFor='sfg-mode' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft} onHelpClick={() => setSfgHelpModalOpen(true)}>
                <input type='checkbox' id='sfg-mode' disabled={!activePlan} checked={sfgMode} onChange={toggleSFGMode} />
              </FormField>
              <FormField label='Snap to Grid' htmlFor='snap-to-grid' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
                <input type='checkbox' id='snap-to-grid' disabled={!activePlan} checked={snapToGrid} onChange={toggleSnapToGrid} />
              </FormField>
            </FormLayout>
            <FormField label={labelWithHelpButton('Layer', () => setShowLayerSelectorHelpModal(true))} htmlFor='layer' layoutPreset={DROPDOWN_INPUT_LAYOUT}>
              <InputContainer size='full'>
                <LayerSelector hideLabel attachToBody menuPlacement='auto' id='layer' />
              </InputContainer>
            </FormField>
            <FormField label={labelWithHelpButton('Month', () => setShowMonthSelectorHelpModal(true))} htmlFor='month' layoutPreset={DROPDOWN_INPUT_LAYOUT}>
              <MonthSelector northernHemisphere={isNorthernHemisphere} fullWidth attachToBody menuPlacement='auto' id='month' />
            </FormField>
            {showCropRotation ? (
              <FormField
                label={labelWithHelpButton('Crop Rotation', () => setShowCropRotationHelpModal(true))}
                htmlFor='crop-rotation'
                layoutPreset={DROPDOWN_INPUT_LAYOUT}
              >
                <InputContainer size='full'>
                  <CropRotationSelector hideLabel attachToBody menuPlacement='auto' id='crop-rotation' />
                </InputContainer>
              </FormField>
            ) : null}
            <FormField fakeLabel label='Background Image' layoutPreset={FORM_FIELD_PRESETS.Column}>
              <button type='button' className='button button-secondary' onClick={showBackgroundImageDrawer}>
                Background Image Settings
              </button>
            </FormField>
          </FormSection>
        </DrawerContent>
      </Drawer>
      {showMonthSelectorHelpModal ? <MonthSelectorInfoModal closeModal={() => setShowMonthSelectorHelpModal(false)} /> : null}
      {showCropRotationHelpModal ? <CropRotationHelpModal closeModal={() => setShowCropRotationHelpModal(false)} /> : null}
      {showLayerSelectorHelpModal ? <LayerSelectorInfoModal closeModal={() => setShowLayerSelectorHelpModal(false)} /> : null}
    </>
  );
};

export default PlannerSettingsDrawer;
