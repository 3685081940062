import React from 'react';

import { webGardenPlannerUpdates } from '@gi/update-data';
import Modal, { ModalCloseButton, ModalContent, ModalHeader, ModalHeaderContent, ModalHeaderTitle, ModalPane, ModalPaneContainer, ModalType } from '@gi/modal';

import UpdateSection from './update-section';

import './updates-modal.scss';

interface iProps {
  close: () => void;
}

const UpdatesModal = ({ close }: iProps): JSX.Element => {
  return (
    <Modal type={ModalType.Default} closeRequest={close} className='updates-modal'>
      <ModalContent>
        <ModalCloseButton onClick={close} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Garden Planner Updates</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            {webGardenPlannerUpdates.map((update, i) => (
              <UpdateSection key={i} updateData={update} />
            ))}
          </ModalPane>
        </ModalPaneContainer>
      </ModalContent>
    </Modal>
  );
};

export default UpdatesModal;
