import { SimulatedGardenItemEvent } from '../simulation/simulated-garden-item';
import { SimulatedText } from '../simulation/simulated-text';
import CanvasItem from './canvas-item';
import CanvasLayers from './canvas-layers';
import TextNode from './nodes/text/text-node';

class CanvasText extends CanvasItem {
  readonly #textNode: TextNode;
  readonly #simulatedText: SimulatedText;
  readonly #canvasLayers: CanvasLayers;

  constructor(simulatedText: SimulatedText, canvasLayers: CanvasLayers) {
    super();

    this.#textNode = new TextNode(simulatedText.id, {
      text: simulatedText.text,
      colour: simulatedText.colour,
      fontSize: simulatedText.fontSize,
      start: { ...simulatedText.start },
      end: { ...simulatedText.end },
      rotation: simulatedText.rotation,
      locked: simulatedText.locked,
      zIndex: simulatedText.zIndex,
    });

    this.nodes.push(this.#textNode);

    this.#canvasLayers = canvasLayers;
    this.#simulatedText = simulatedText;

    this.#simulatedText.on(SimulatedGardenItemEvent.DidUpdate, this.#onSimulatedTextUpdate);

    this.#canvasLayers.textLayer.addChildren(this.#textNode);

    this.registerEffectHandler(this.#simulatedText, this.#textNode);
  }

  #onSimulatedTextUpdate = () => {
    this.#textNode.setPosition(this.#simulatedText.center, this.#simulatedText.dimensions, this.#simulatedText.rotation);
    this.#textNode.setText(this.#simulatedText.text, this.#simulatedText.colour, this.#simulatedText.fontSize);
    this.#textNode.setLocked(this.#simulatedText.locked);
    this.#textNode.setZIndex(this.#simulatedText.zIndex);
  };
}

export default CanvasText;
