import React from 'react';

import { FormSection } from '@gi/form-responsive';

import { EditShapeModalFormProps } from '../types';
import FillPropertiesFormSection from './sections/edit-shape-modal-fill-properties-section';
import StrokePropertiesFormSection from './sections/edit-shape-modal-stroke-properties-section';
import PositionPropertiesFormSection from './sections/edit-shape-modal-position-properties-section';

const EditShapeModalFormRectangle = ({ values, setValues, distanceUnits }: EditShapeModalFormProps): JSX.Element => {
  return (
    <>
      <FormSection heading='Shape Properties' padding={12} gap={6} className='form-section-background'>
        <FillPropertiesFormSection values={values} setValues={setValues} />
        {!values.values.filled ? <StrokePropertiesFormSection values={values} setValues={setValues} /> : null}
      </FormSection>
      <FormSection heading='Position &amp; Dimensions' padding={12} gap={6} className='form-section-background'>
        <PositionPropertiesFormSection values={values} setValues={setValues} distanceUnits={distanceUnits} />
      </FormSection>
    </>
  );
};

export default EditShapeModalFormRectangle;
