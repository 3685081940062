import React, { useContext } from 'react';
import ReactSelect, { SingleValue, StylesConfig } from 'react-select';
import { updateFilters } from '@gi/filters';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import { SortOrderLabels, SORT_ORDER } from '../types/plant-list-types';
import GlobalPlantListContext from '../global-provider/global-plant-list-context';
import { DISPLAY_TYPE } from '../constants';

import './sort-select.scss';

const SORT_ASC_ICON_CLASS = 'icon-sort-up';
const SORT_DESC_ICON_CLASS = 'icon-sort-down';

interface iPlantSortOptionLabelProps {
  label: string;
  iconClass: string;
}

const PlantSortOptionLabel = ({ label, iconClass }: iPlantSortOptionLabelProps): JSX.Element => {
  return (
    <div className='plant-sort-option'>
      <span className='plant-sort-option-text'>{label}</span>
      <span className='plant-sort-option-icon'>
        <i className={iconClass} />
      </span>
    </div>
  );
};

type PlantSortOption = {
  value: SORT_ORDER;
  label: string;
};

const plantSortOptions = [
  {
    value: SORT_ORDER.PlantNameDesc,
    label: SortOrderLabels[SORT_ORDER.PlantNameDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantNameAsc,
    label: SortOrderLabels[SORT_ORDER.PlantNameAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.QuantityDesc,
    label: SortOrderLabels[SORT_ORDER.QuantityDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.QuantityAsc,
    label: SortOrderLabels[SORT_ORDER.QuantityAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.SowStartDesc,
    label: SortOrderLabels[SORT_ORDER.SowStartDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.SowStartAsc,
    label: SortOrderLabels[SORT_ORDER.SowStartAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantStartDesc,
    label: SortOrderLabels[SORT_ORDER.PlantStartDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantStartAsc,
    label: SortOrderLabels[SORT_ORDER.PlantStartAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.HarvestStartDesc,
    label: SortOrderLabels[SORT_ORDER.HarvestStartDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.HarvestStartAsc,
    label: SortOrderLabels[SORT_ORDER.HarvestStartAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
];

const plantSortOptionsByValue = plantSortOptions.reduce((acc, next) => {
  acc[next.value] = next;
  return acc;
}, {});

const varietySortOptions = [
  {
    value: SORT_ORDER.PlantNameDesc,
    label: SortOrderLabels[SORT_ORDER.PlantNameDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantNameAsc,
    label: SortOrderLabels[SORT_ORDER.PlantNameAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.QuantityDesc,
    label: SortOrderLabels[SORT_ORDER.QuantityDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.QuantityAsc,
    label: SortOrderLabels[SORT_ORDER.QuantityAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.SowStartDesc,
    label: SortOrderLabels[SORT_ORDER.SowStartDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.SowStartAsc,
    label: SortOrderLabels[SORT_ORDER.SowStartAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantStartDesc,
    label: SortOrderLabels[SORT_ORDER.PlantStartDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantStartAsc,
    label: SortOrderLabels[SORT_ORDER.PlantStartAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.HarvestStartDesc,
    label: SortOrderLabels[SORT_ORDER.HarvestStartDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.HarvestStartAsc,
    label: SortOrderLabels[SORT_ORDER.HarvestStartAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
];

const varietySortOptionsByValue = varietySortOptions.reduce((acc, next) => {
  acc[next.value] = next;
  return acc;
}, {});

const areaSortOptions = [
  {
    value: SORT_ORDER.PlantNameDesc,
    label: SortOrderLabels[SORT_ORDER.PlantNameDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantNameAsc,
    label: SortOrderLabels[SORT_ORDER.PlantNameAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.QuantityDesc,
    label: SortOrderLabels[SORT_ORDER.QuantityDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.QuantityAsc,
    label: SortOrderLabels[SORT_ORDER.QuantityAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantLabelDesc,
    label: SortOrderLabels[SORT_ORDER.PlantLabelDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantLabelAsc,
    label: SortOrderLabels[SORT_ORDER.PlantLabelAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.InGroundStartDesc,
    label: SortOrderLabels[SORT_ORDER.InGroundStartDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.InGroundStartAsc,
    label: SortOrderLabels[SORT_ORDER.InGroundStartAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.SowStartDesc,
    label: SortOrderLabels[SORT_ORDER.SowStartDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.SowStartAsc,
    label: SortOrderLabels[SORT_ORDER.SowStartAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantStartDesc,
    label: SortOrderLabels[SORT_ORDER.PlantStartDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.PlantStartAsc,
    label: SortOrderLabels[SORT_ORDER.PlantStartAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.HarvestStartDesc,
    label: SortOrderLabels[SORT_ORDER.HarvestStartDesc],
    iconClass: SORT_DESC_ICON_CLASS,
  },
  {
    value: SORT_ORDER.HarvestStartAsc,
    label: SortOrderLabels[SORT_ORDER.HarvestStartAsc],
    iconClass: SORT_ASC_ICON_CLASS,
  },
];

const areaSortOptionsByValue = areaSortOptions.reduce((acc, next) => {
  acc[next.value] = next;
  return acc;
}, {});

interface iProps {
  styles?: StylesConfig;
}

const SortSelect = ({ styles }: iProps): JSX.Element => {
  const { displayType, plantGroupFilters, setPlantGroupFilters, varietyGroupFilters, setVarietyGroupFilters, areaGroupFilters, setAreaGroupFilters } =
    useContext(GlobalPlantListContext);

  const plantSortValue = plantGroupFilters.filters.sort.inputs.sortOrder;
  const varietySortValue = varietyGroupFilters.filters.sort.inputs.sortOrder;
  const areaSortValue = areaGroupFilters.filters.sort.inputs.sortOrder;

  const [options, value] = (() => {
    if (displayType === DISPLAY_TYPE.Summary) {
      return [plantSortOptions, plantSortOptionsByValue[plantSortValue]];
    }
    if (displayType === DISPLAY_TYPE.Variety) {
      return [varietySortOptions, varietySortOptionsByValue[varietySortValue]];
    }
    return [areaSortOptions, areaSortOptionsByValue[areaSortValue]];
  })();

  const onSelect = (newSortOrder: SingleValue<PlantSortOption>) => {
    if (newSortOrder === null) {
      return;
    }

    if (plantSortOptionsByValue[newSortOrder.value]) {
      setPlantGroupFilters(updateFilters(plantGroupFilters, { sort: { sortOrder: newSortOrder.value } }));
    }

    if (varietySortOptionsByValue[newSortOrder.value]) {
      setVarietyGroupFilters(updateFilters(varietyGroupFilters, { sort: { sortOrder: newSortOrder.value } }));
    }

    if (areaSortOptionsByValue[newSortOrder.value]) {
      setAreaGroupFilters(updateFilters(areaGroupFilters, { sort: { sortOrder: newSortOrder.value } }));
    }
  };

  return (
    <ReactSelect
      className='select-input sort-select'
      styles={styles ?? DEFAULT_SELECT_STYLES}
      options={options}
      value={value}
      onChange={onSelect}
      isSearchable={false}
      menuPortalTarget={document.body}
      formatOptionLabel={PlantSortOptionLabel}
    />
  );
};

export default SortSelect;
