import React from 'react';

interface iProps {
  imageName: number;
  calendarBarURL: string;
  monthName: string;
  monthTitle: string;
}

const PlantingCalendarMonth = ({ imageName, calendarBarURL, monthName, monthTitle }: iProps): JSX.Element => {
  return (
    <div className='planting-calendar-month'>
      <div className='month-title' title={monthTitle}>
        {monthName}
      </div>
      <div className='month-image'>
        <img className='calendar-image' src={`${calendarBarURL}${imageName}.svg`} alt='' />
      </div>
    </div>
  );
};

export default PlantingCalendarMonth;
