import React, { ReactNode } from 'react';

import './plant-list-card-cell-container.scss';

interface iProps {
  children: ReactNode;
}

const PlantListCardCellContainer = ({ children }: iProps): JSX.Element => {
  return <div className='plant-list-card-cell-container'>{children}</div>;
};

export default PlantListCardCellContainer;
