import { Filter } from '@gi/filters';
import Plant from '@gi/plant';
import { PERENNIAL } from '@gi/constants';

type TypeFilterState = {
  type: null | string;
};

const typeFilter: Filter<Plant, TypeFilterState> = {
  name: 'Plant Type',
  inputs: { type: null },
  createListFilter: ({ type }) => {
    return (plantList) => {
      if (type === null) {
        return plantList;
      }

      if (type === PERENNIAL) {
        return plantList.filter((plant) => {
          return plant.perennial;
        });
      }

      return plantList.filter((plant) => {
        return plant.hasTag(type);
      });
    };
  },
};

export default typeFilter;
