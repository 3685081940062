import React, { MouseEvent, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';

import { Drawer } from '@gi/mobile-components';

import './vertical-nav.scss';

interface iProps {
  className?: string;
  children?: ReactNode;
  title: ReactNode;
  buttonText?: ReactNode;
}

const VerticalNav = ({ className, children, title, buttonText = 'Menu' }: iProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  /**
   * This checks if the user clicked on an item in the nav (a link). If so, close the nav.
   */
  const handlePotentialItemClick = (e: MouseEvent<HTMLDivElement>) => {
    let currentElem: Element | null = e.target as Element;
    while (currentElem !== e.currentTarget && currentElem !== null) {
      if (currentElem.classList.contains('vertical-nav-item')) {
        // Check if the item is actually clickable.
        const tagName = currentElem.tagName.toLowerCase();
        if (['a', 'button'].includes(tagName)) {
          setIsOpen(false);
        }
        break;
      }
      currentElem = currentElem.parentElement;
    }
  };

  const classNames = ['vertical-nav'];
  if (isOpen) {
    classNames.push('open');
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <nav className={classNames.join(' ')}>
      {/* Mobile Nav Toggle Button */}
      <button className='button button-primary vertical-nav-mobile-control' type='button' onClick={() => toggleOpen()}>
        <span>{buttonText}</span>
        <i className='icon-menu' />
      </button>
      {/* Desktop Nav */}
      <div className='vertical-nav-content' role='navigation'>
        {children}
      </div>
      {/* Mobile copy to go to the Portal */}
      {createPortal(
        <Drawer className='vertical-nav-drawer' anchor='RIGHT' borderRadius={0} open={isOpen} onClose={() => setIsOpen(false)}>
          <div className='vertical-nav-content-mobile' role='navigation'>
            <div onClick={handlePotentialItemClick} role='none'>
              <div className='vertical-nav-mobile-header'>
                <p>{title}</p>
                <button className='vertical-nav-close' type='button' aria-label='Close Navigation Menu' onClick={() => setIsOpen(false)}>
                  <i className='icon-cancel' />
                </button>
              </div>
              {children}
            </div>
          </div>
        </Drawer>,
        document.getElementById('menu-root')!
      )}
    </nav>
  );
};

export default VerticalNav;
