import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DeviceDisplayMode, OrientationTypes } from '@gi/constants';
import { LocalSettings } from '@gi/local-settings';

import { getBestDisplayMode, isLandscape } from './util';

export type DisplayModeState = {
  deviceDisplayMode: DeviceDisplayMode.DESKTOP | DeviceDisplayMode.MOBILE;
  bestDeviceDisplayMode: DeviceDisplayMode.DESKTOP | DeviceDisplayMode.MOBILE;
  acknowledgedBestDeviceDisplayMode: boolean;
  deviceOrientation: OrientationTypes;
};

const initialBestDisplayMode = getBestDisplayMode();

const initialState: DisplayModeState = {
  deviceDisplayMode: initialBestDisplayMode,
  bestDeviceDisplayMode: initialBestDisplayMode,
  acknowledgedBestDeviceDisplayMode: false,
  deviceOrientation: isLandscape() ? OrientationTypes.LANDSCAPE : OrientationTypes.PORTRAIT,
};

const displayModeSlice = createSlice({
  name: 'displayMode',
  initialState,
  reducers: {
    setDisplayMode: (state, action: PayloadAction<DeviceDisplayMode.DESKTOP | DeviceDisplayMode.MOBILE>) => {
      state.deviceDisplayMode = action.payload;
    },
    setBestDisplayMode: (state, action: PayloadAction<DeviceDisplayMode.DESKTOP | DeviceDisplayMode.MOBILE>) => {
      if (state.bestDeviceDisplayMode !== action.payload) {
        state.bestDeviceDisplayMode = action.payload;
        state.acknowledgedBestDeviceDisplayMode = false;
      }
    },
    acknowledgeBestDisplayMode: (state) => {
      state.acknowledgedBestDeviceDisplayMode = true;
    },
    useBestDisplayMode: (state) => {
      state.acknowledgedBestDeviceDisplayMode = true;
      state.deviceDisplayMode = state.bestDeviceDisplayMode;
    },
    setDeviceOrientation: (state, action: PayloadAction<OrientationTypes>) => {
      state.deviceOrientation = action.payload;
    },
  },
});

interface RootState {
  displayMode: DisplayModeState;
  localSettings: LocalSettings;
}

export const displayModeReducer = displayModeSlice.reducer;
export const DisplayModeActions = { ...displayModeSlice.actions };
export const DisplayModeSelectors = {
  // Returns which display mode the app should currently be using
  getDisplayMode: (state: RootState) => state.displayMode.deviceDisplayMode,
  // Returns the best display mode for the user. Isn't necesserily the active display mode, just the reocmmended.
  getBestDisplayMode: (state: RootState) => state.displayMode.bestDeviceDisplayMode,
  // Returns true when the user has dismissed/accepted the recommended display mode when using DisplayMode AUTO
  getHasAcknowledgedBestDisplayMode: (state: RootState) => state.displayMode.acknowledgedBestDeviceDisplayMode,
  // Returns the device orientation
  getDeviceOrientation: (state: RootState) => state.displayMode.deviceOrientation,
  // Returns the computed touch mode (true if mobile, else taken from local settings)
  getTouchMode: (state: RootState) => {
    return state.displayMode.deviceDisplayMode === DeviceDisplayMode.MOBILE ? true : state.localSettings.touchMode;
  },
};
