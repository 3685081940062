import Node from '../node';
import NodeComponent from '../node-component/node-component';

type NodeWithParent<T extends Node> = {
  parent: NonNullable<T['parent']>;
};

type NodeWithEngine<T extends Node> = {
  engine: NonNullable<T['engine']>;
};

type NodeComponentWithOwner<T extends NodeComponent> = {
  owner: NonNullable<T['owner']>;
};

type NodeComponentWithOwnerAndEngine<T extends NodeComponent> = {
  owner: NodeWithEngine<NonNullable<T['owner']>>;
};

/**
 * Asserts that the given node has a parent. Throws an error otherwise.
 * Useful for onAttach callbacks.
 * @param node The node to check
 */
export function hasParent<T extends Node>(node: T): asserts node is T & NodeWithParent<T> {
  if (node.parent === null) {
    throw new Error('Node parent not set');
  }
}

/**
 * Assets that the given node component has an owner. Thros an error otherwise.
 * Useful for onAttach callbacks.
 * @param attachment The component to check
 */
export function hasOwner<T extends NodeComponent>(attachment: T): asserts attachment is T & NodeComponentWithOwner<T> {
  if (attachment.owner === null) {
    throw new Error('Node Component owner not set');
  }
}

/**
 * Asserts that the given node/component is attached to an engine. Throws an error otherwise.
 * Useful for onBind callbacks.
 * @param node The node to check
 */
export function hasEngine<T extends NodeComponent>(component: T): asserts component is T & NodeComponentWithOwnerAndEngine<T>;
export function hasEngine<T extends Node>(node: T): asserts node is T & NodeWithEngine<T>;
export function hasEngine(node: any) {
  if (node instanceof NodeComponent) {
    hasOwner(node);
    if (node.owner.engine === null) {
      throw new Error('Node engine not set of component');
    }
  } else if (node.engine === null) {
    throw new Error('Node engine not set');
  }
}
