import {
  EventBus,
  InspectableClassData,
  InspectableClassDataType,
  InspectableClassPropertyType,
  NodeComponent,
  NodeComponentEventActions,
  NodeEvent,
} from '@gi/core-renderer';

import CanvasLayers from '../../canvas-layers';
import ToolNode, { ToolState } from './tool-node';

export enum ToolContextEvent {
  ToolUpdate = 'ToolUpdate',
}

export type ToolContextEventActions = {
  [ToolContextEvent.ToolUpdate]: (state: ToolState | null) => void;
};

class ToolContext extends NodeComponent {
  type = 'ToolContext';

  readonly eventBus: EventBus<NodeComponentEventActions & ToolContextEventActions> = new EventBus(this.eventBus);
  readonly #canvasLayers: CanvasLayers;

  #activeTool: ToolNode<any> | null = null;
  #activeToolState: ToolState | null = null;
  get activeToolState() {
    return this.#activeToolState;
  }
  set activeToolState(state: ToolState | null) {
    this.#activeToolState = state;
    this.eventBus.emit(ToolContextEvent.ToolUpdate, state);
  }

  constructor(canvasLayers: CanvasLayers) {
    super();

    this.#canvasLayers = canvasLayers;
  }

  get activeTool() {
    return this.#activeTool;
  }

  setActiveTool<T extends ToolNode<any>>(tool: T) {
    if (this.activeTool) {
      this.cancelActiveTool();
    }
    this.#activeTool = tool;
    this.owner?.addChildren(tool);

    // This will self-clean when the tool is destroyed.
    tool.externalState.addWatcher((state) => {
      this.activeToolState = state.values;
    });

    // When the tool is done, remove reference to it.
    tool.eventBus.on(NodeEvent.Destroyed, () => {
      if (this.#activeTool === tool) {
        this.#activeTool = null;
        this.activeToolState = null;
      }
    });
  }

  cancelActiveTool() {
    if (this.#activeTool) {
      this.#activeTool.cancel();
      this.#activeTool.destroy();
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'activeToolState',
      propertyType: InspectableClassPropertyType.String,
    },
  ];
}

export default ToolContext;
