import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

import styles from './placeholder.module.css';

interface iProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  loaded?: boolean;
}

const PlaceholderBackground = ({ loaded, className, children, ...remainingProps }: iProps): JSX.Element => {
  const classNames: string[] = [];
  if (!loaded) {
    classNames.push(styles.placeholderBackground);
  }
  if (className !== undefined) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} {...remainingProps}>
      {children}
    </div>
  );
};

export default PlaceholderBackground;
