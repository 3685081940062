import React from 'react';
import PlayButton from './play-button';

import './youtube-embed.scss';

interface iProps {
  onClick: () => void;
  thumbnailURL: string;
  className?: string;
}

const YoutubeButton = ({ className = '', onClick, thumbnailURL }: iProps) => {
  return (
    <button type='button' className={`yt-thumbnail-button ${className}`} onClick={onClick}>
      <div className='yt-button-content'>
        <div className='center-contents'>
          <img alt='Video Thumbnail' className='yt-thumb' src={thumbnailURL} />
        </div>
        <div className='center-contents'>
          <PlayButton />
        </div>
      </div>
    </button>
  );
};

export default YoutubeButton;
