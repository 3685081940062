import { DefaultNotificationOptions } from './notification-action-creators';
import { NotificationTypes } from './notification';

// TODO - Move these to some planner requests module

export const savingProfileStart = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Saving Profile',
  icon: 'icon-floppy',
  ID: notificationID,
  type: NotificationTypes.INFO,
  inProgress: true,
});
export const savingProfileSuccessUpdate: DefaultNotificationOptions = {
  title: 'Profile Saved',
  icon: 'icon-ok',
  type: NotificationTypes.SUCCESS,
  canTimeout: true,
};
export const savingProfileFailUpdate: DefaultNotificationOptions = {
  title: 'Error Saving Profile',
  icon: 'icon-attention-alt',
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
};

export const loadingProfileStart = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Loading Profile',
  icon: 'icon-spinner animate-pulse',
  ID: notificationID,
  type: NotificationTypes.INFO,
  inProgress: true,
});
export const loadingProfileSuccessUpdate: DefaultNotificationOptions = {
  title: 'Profile Loaded',
  icon: 'icon-ok',
  type: NotificationTypes.SUCCESS,
  canTimeout: true,
};
export const loadingProfileFailUpdate: DefaultNotificationOptions = {
  title: 'Error Loading Profile',
  icon: 'icon-attention-alt',
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
};

export const loginStart = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Logging In',
  icon: 'icon-spinner animate-pulse',
  ID: notificationID,
  type: NotificationTypes.INFO,
  inProgress: true,
});
export const loginSuccessUpdate: DefaultNotificationOptions = {
  title: 'Login Successful',
  icon: 'icon-ok',
  type: NotificationTypes.SUCCESS,
  canTimeout: true,
};
export const loginFailUpdate: DefaultNotificationOptions = {
  title: 'Login Error',
  icon: 'icon-attention-alt',
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
};

export const loadingPlanStart = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Loading Plan',
  icon: 'icon-spinner animate-pulse',
  ID: notificationID,
  type: NotificationTypes.INFO,
  inProgress: true,
});
export const loadingPlanSuccessUpdate: DefaultNotificationOptions = {
  title: 'Plan Loaded',
  icon: 'icon-ok',
  type: NotificationTypes.SUCCESS,
  canTimeout: true,
};
export const loadingPlanFailUpdate: DefaultNotificationOptions = {
  title: 'Error Loading Plan',
  icon: 'icon-attention-alt',
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
};
export const loadingPlanFailStart = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Error Loading Plan',
  text: 'Unauthorised',
  icon: 'icon-attention-alt',
  ID: notificationID,
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
});

export const savePlanStart = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Saving Plan',
  icon: 'icon-spinner animate-pulse',
  ID: notificationID,
  type: NotificationTypes.INFO,
  inProgress: true,
});
export const savePlanSuccessUpdate: DefaultNotificationOptions = {
  title: 'Plan Saved',
  icon: 'icon-ok',
  type: NotificationTypes.SUCCESS,
  canTimeout: true,
};
export const savePlanFailUpdate: DefaultNotificationOptions = {
  title: 'Error Saving Plan',
  icon: 'icon-attention-alt',
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
};
export const savePlanFailStart = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Error Saving Plan',
  text: 'Unauthorised',
  icon: 'icon-attention-alt',
  ID: notificationID,
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
});

export const logoutSuccess = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Logout Successful',
  icon: 'icon-ok',
  ID: notificationID,
  type: NotificationTypes.INFO,
  inProgress: true,
});
