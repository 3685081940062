import React from 'react';
import { PropTypes } from 'prop-types';
import { lexographicSort, getWeekNumberFromDate, unique } from '@gi/utils';
import { UserShape } from '@gi/user';
import { JournalEntries, JournalActions } from '@gi/journal-days';
import JournalWeek from './journal-week';
import './journal.scss';

/**
 * @param {{
 *  selectedWeek?: number,
 *  user: import('@gi/user').User,
 *  journalEntries: JournalEntries,
 *  journalActions: JournalActions,
 * }}
 */
const JournalWeeks = ({
  selectedWeek = getWeekNumberFromDate(new Date()),
  user,
  journalEntries = new JournalEntries(),
  journalActions = new JournalActions(),
}) => {
  const allDates = [...journalEntries.getDatesArray(), ...journalActions.getDatesArray()].filter(unique);

  const datesGroupedByWeek = {};
  const weekNos = [];
  allDates
    .sort((a, b) => lexographicSort(b, a))
    .forEach((dateYMD) => {
      const weekNo = getWeekNumberFromDate(new Date(dateYMD));
      if (weekNos.indexOf(weekNo) === -1) {
        weekNos.push(weekNo);
      }
      datesGroupedByWeek[weekNo] = datesGroupedByWeek[weekNo] || [];
      datesGroupedByWeek[weekNo].push(dateYMD);
    });

  return weekNos.map((weekNo) => {
    return (
      <JournalWeek
        selectedWeek={selectedWeek}
        weekNo={parseInt(weekNo, 10)}
        dates={datesGroupedByWeek[weekNo]}
        journalEntries={journalEntries}
        journalActions={journalActions}
        user={user}
        key={weekNo}
      />
    );
  });
};

JournalWeeks.propTypes = {
  selectedWeek: PropTypes.number,
  user: UserShape.isRequired,
  journalEntries: PropTypes.instanceOf(JournalEntries),
  journalActions: PropTypes.instanceOf(JournalActions),
};

export default JournalWeeks;
