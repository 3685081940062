import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ResourceContext } from '@gi/resource-provider';
import { getDistanceUnitsFromIsMetric } from '@gi/units';
import { CanvasSelectors } from '@gi/react-garden-canvas';

import LocalPlantListContext from '../local-provider/local-plant-list-context';
import PlantListByVarietyCard from './plant-list-by-variety-card';

const PlantListByVariety = (): JSX.Element => {
  const { filteredVarietyGroupData } = useContext(LocalPlantListContext);
  const { userDistanceUnits } = useContext(ResourceContext);

  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  const distanceUnits = activePlan ? getDistanceUnitsFromIsMetric(activePlan.plannerSettings.metric) : userDistanceUnits;

  return (
    <>
      {filteredVarietyGroupData.map((plantListRowGroup) => (
        <PlantListByVarietyCard
          key={`${plantListRowGroup.plant.code}_${plantListRowGroup.variety}_${plantListRowGroup.modifier ?? 'default'}`}
          plantListRowGroup={plantListRowGroup}
          distanceUnits={distanceUnits}
        />
      ))}
    </>
  );
};

export default PlantListByVariety;
