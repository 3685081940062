import { DisplayObject } from 'pixi.js-new';

import { InteractionStateType } from '@gi/constants';

import EventBus from '../../event-bus';
import KeyboardTracker from './keyboard-tracker';
import PointerInteraction, { PointerInteractionRepeatInfo } from './interaction';

export type InteractionManagerState = {
  type: null | InteractionStateType;
};

export enum InteractionManagerEvent {
  PointerDown = 'pointerdown',
  PointerMove = 'pointermove',
  PointerCancel = 'pointercancel',
  PointerUp = 'pointerup',
  Wheel = 'wheel',
  StateChange = 'statechange',
}

export type InteractionManagerEventActions = {
  [InteractionManagerEvent.PointerDown]: (e: PointerEvent) => void;
  [InteractionManagerEvent.PointerMove]: (e: PointerEvent) => void;
  [InteractionManagerEvent.PointerCancel]: (e: PointerEvent) => void;
  [InteractionManagerEvent.PointerUp]: (e: PointerEvent) => void;
  [InteractionManagerEvent.Wheel]: (e: WheelEvent) => void;
  [InteractionManagerEvent.StateChange]: (e: InteractionManagerState) => void;
};

class InteractionManager {
  readonly eventBus: EventBus<InteractionManagerEventActions> = new EventBus();

  rootDisplayObject: DisplayObject | null = null;

  keyboardTracker: KeyboardTracker = new KeyboardTracker();

  #currentInteraction: PointerInteraction | null = null;

  // TODO: Import interaction state type. This is in react-garden-canvas ucrrently though, which would bne cyclicar dependancy.
  #interactionState: InteractionManagerState = { type: null };
  get interactionState() {
    return this.#interactionState;
  }

  #container: HTMLElement | null = null;
  get container() {
    return this.#container;
  }
  set container(container: HTMLElement | null) {
    if (this.#container) {
      this.#container.removeEventListener('wheel', this.#onWheel);
    }

    this.#container = container;

    if (this.#container) {
      this.#container.addEventListener('wheel', this.#onWheel);
      // this.#container.addEventListener('pointerdown', (event) => this.#onPointerDown(event));
    }
  }

  /**
   * Tracks a pointerdown event.
   * Use this if your object has event listeners, but doesn't want to start an interaction right now.
   * @param event The pointer event
   */
  trackPointerDown(event: PointerEvent) {
    if (this.#currentInteraction && !this.#currentInteraction.hasEnded) {
      this.#currentInteraction.trackPointerDown(event);
    }
  }

  /**
   * Attempts to start an interaction.
   * If successful, this will return a new interaction, and the onStart callback will be called.
   * If unsuccessful (because another interaction is ongoing), null will be returned.
   */
  startInteraction(...[event, callbacks, options]: ConstructorParameters<typeof PointerInteraction>): PointerInteraction | null {
    this.eventBus.emit(InteractionManagerEvent.PointerDown, event);
    event.stopPropagation();
    if (this.#currentInteraction && !this.#currentInteraction.hasEnded) {
      this.#currentInteraction.trackPointerDown(event);
      return null;
    }
    // Check if this is a repeat of the last interaction
    let repeatInfo: PointerInteractionRepeatInfo | undefined;
    if (this.#currentInteraction) {
      if (this.#currentInteraction.target === options?.target && !this.#currentInteraction.didDrag && !this.#currentInteraction.didMultiTouch) {
        repeatInfo = {
          count: this.#currentInteraction.repeatCount + 1,
          lastAt: this.#currentInteraction.endedAt ?? this.#currentInteraction.startedAt,
        };
      }
    }
    // Start the new interaction
    this.#currentInteraction = new PointerInteraction(event, callbacks, {
      parent: this.container ?? undefined,
      eventParent: this.rootDisplayObject ?? undefined,
      repeatInfo,
      ...options,
    });
    return this.#currentInteraction;
  }

  /**
   * Handles mouse wheel events done over the canvas
   * @param event The WheelEvent
   */
  #onWheel = (event: WheelEvent) => {
    this.eventBus.emit(InteractionManagerEvent.Wheel, event);
  };

  /**
   * Sets information about the current interaction. Not necessarily related to the actual current interaction.
   * It's up to whatever's listening on the other end to make sense of it.
   * It's up to whatever calls this to also clean up when it's finished.
   * @param state Any information about the current interaction.
   */
  setInteractionState(state: InteractionManagerState) {
    this.#interactionState = state;
    this.eventBus.emit(InteractionManagerEvent.StateChange, state);
  }

  /**
   * Clears the interaction state information.
   */
  clearInteractionState() {
    this.setInteractionState({ type: null });
  }
}

export default InteractionManager;
