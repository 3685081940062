import React from 'react';

import { TutorialEditorProvider } from './tutorial-editor-context';
import TutorialEditorContent from './tutorial-editor-content';

interface iProps {
  className?: string;
}

const TutorialEditor = ({ className = '' }: iProps): JSX.Element => {
  return (
    <TutorialEditorProvider>
      <TutorialEditorContent className={className} />
    </TutorialEditorProvider>
  );
};

export default TutorialEditor;
