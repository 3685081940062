import React from 'react';

import { VerticalNav } from '@gi/page-layout';

import PageNavigationMainLink from './page-navigation-main-link';

const PageNavigation = (): JSX.Element => {
  return (
    <VerticalNav title='Help and Support Navigation'>
      <PageNavigationMainLink page={{ page: 'SUPPORT', subPage: 'MAIN' }} text='Support' />
      <PageNavigationMainLink page={{ page: 'GARDEN_PLANNER', subPage: 'ABOUT' }} text='Garden Planner' />
      <PageNavigationMainLink page={{ page: 'GARDEN_JOURNAL', subPage: 'ABOUT' }} text='Garden Journal' />
      <PageNavigationMainLink page={{ page: 'UPDATE_HISTORY', subPage: 'MAIN' }} text='Release History' />
    </VerticalNav>
  );
};

export default PageNavigation;
