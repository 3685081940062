import { DeviceDisplayMode } from '@gi/constants';
import { RunningTutorial, Tutorial, TutorialStep, Tutorials } from './tutorial';

/**
 * Set of tutorial utility functions wrapped in a class just for organisation
 */
class TutorialUtils {
  /**
   * Creates a new RunningTutorial from a Tutorial, setting the current step as the first
   * step in the tutorial and assigning next/previous and currentIndex as required
   */
  static createRunningTutorial(tutorial: Tutorial): RunningTutorial | null {
    // Handle malformed tutorials
    if (tutorial.steps.length === 0) {
      return null;
    }

    return {
      tutorial,
      previous: null,
      current: tutorial.steps[0],
      currentIndex: 0,
      next: tutorial.steps.length > 1 ? tutorial.steps[1] : null,
      analytics: {
        maxStep: 0,
      },
    };
  }

  /**
   * Immutably advances a RunningTutorial, returning a new RunningTutorial
   * instance if it is able to advance to the next step, otherwise returns the
   * provided RunningTutorial
   */
  static advanceStep(runningTutorial: RunningTutorial): RunningTutorial {
    if (!runningTutorial.next) {
      console.warn('Attempted to go to next tutorial step but not present');
      return runningTutorial;
    }

    const newRunningTutorial = {
      ...runningTutorial,
      analytics: {
        ...runningTutorial.analytics,
      },
    };

    newRunningTutorial.current = runningTutorial.next;
    newRunningTutorial.currentIndex = runningTutorial.currentIndex + 1;
    newRunningTutorial.previous = runningTutorial.current;
    newRunningTutorial.next = TutorialUtils.findNextStep(runningTutorial.tutorial, newRunningTutorial.current);
    newRunningTutorial.analytics.maxStep = Math.max(newRunningTutorial.currentIndex, newRunningTutorial.analytics.maxStep);
    return newRunningTutorial;
  }

  /**
   * Immutably reverts a RunningTutorial to its previous step, returning a new RunningTutorial
   * instance if a previous step is available, otherwise returns the
   * provided RunningTutorial
   */
  static backStep(runningTutorial: RunningTutorial): RunningTutorial {
    if (!runningTutorial.previous) {
      console.warn('Attempted to go to previous tutorial step but not present');
      return runningTutorial;
    }

    const newRunningTutorial = {
      ...runningTutorial,
    };

    newRunningTutorial.current = runningTutorial.previous;
    newRunningTutorial.currentIndex = runningTutorial.currentIndex - 1;
    newRunningTutorial.next = runningTutorial.current;
    newRunningTutorial.previous = TutorialUtils.findPreviousStep(runningTutorial.tutorial, newRunningTutorial.current);

    return newRunningTutorial;
  }

  private static findNextStep(tutorial: Tutorial, step: TutorialStep): TutorialStep | null {
    const index = tutorial.steps.indexOf(step);

    if (index === -1) {
      console.error('Attempted to find next step in tutorial but current step not present');
      return null;
    }

    if (index === tutorial.steps.length - 1) {
      // No next step
      return null;
    }

    return tutorial.steps[index + 1];
  }

  private static findPreviousStep(tutorial: Tutorial, step: TutorialStep): TutorialStep | null {
    const index = tutorial.steps.indexOf(step);

    if (index === -1) {
      console.error('Attempted to find next step in tutorial but current step not present');
      return null;
    }

    if (index === 0) {
      // No next step
      return null;
    }

    return tutorial.steps[index - 1];
  }

  static hasTutorial(tutorials: Tutorials, uuid: string): boolean {
    return this.getTutorialIndex(tutorials, uuid) !== -1;
  }

  static getTutorialIndex(tutorials: Tutorials, uuid: string): number {
    for (let i = 0; i < tutorials.tutorials.length; i++) {
      if (tutorials.tutorials[i].uuid === uuid) {
        return i;
      }
    }

    return -1;
  }

  static updateTutorial(tutorials: Tutorials, tutorial: Tutorial): Tutorials {
    const index = TutorialUtils.getTutorialIndex(tutorials, tutorial.uuid);

    if (index === -1) {
      return TutorialUtils.addTutorial(tutorials, tutorial);
    }

    const updatedTutorialsList = [...tutorials.tutorials];
    updatedTutorialsList[index] = tutorial;

    return {
      ...tutorials,
      tutorials: updatedTutorialsList,
    };
  }

  static addTutorial(tutorials: Tutorials, tutorial: Tutorial): Tutorials {
    if (TutorialUtils.hasTutorial(tutorials, tutorial.uuid)) {
      return this.updateTutorial(tutorials, tutorial);
    }

    return {
      ...tutorials,
      tutorials: [...tutorials.tutorials, tutorial],
    };
  }

  static createNewTutorial(): Tutorial {
    return {
      uuid: crypto.randomUUID(),
      name: '',
      steps: [],
    };
  }

  static createEmptyTutorials(): Tutorials {
    return {
      tutorials: [],
    };
  }

  static getNamedTutorial(tutorials: Tutorials, name: string, displayMode: DeviceDisplayMode.DESKTOP | DeviceDisplayMode.MOBILE): Tutorial | null {
    const filteredTutorials = tutorials.tutorials
      .filter((tutorial) => {
        // Find tutorials with the same name and relevant display mode
        return tutorial.name === name && (!tutorial.displayMode || tutorial.displayMode === displayMode);
      })
      .sort((tutorialA, tutorialB) => {
        // Sort tutorials with the more specific display mode to the top
        const a = tutorialA.displayMode ? 1 : -1;
        const b = tutorialB.displayMode ? 1 : -1;
        return b - a;
      });
    return filteredTutorials[0] ?? null;
  }
}

export default TutorialUtils;
