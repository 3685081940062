import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SidebarActionCreators, SidebarSelectors } from '../sidebar-slice';
import SidebarPosition from '../sidebar-position';

import './content-container.scss';

interface iProps {
  children: React.ReactNode;
}

const ContentContainer = ({ children }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const sidebarPosition = useSelector(SidebarSelectors.getSidebarPosition);

  const overlayStyle = {
    display: sidebarPosition === SidebarPosition.Open ? 'block' : 'none',
  };

  return (
    <div className='content-container'>
      <div className='content' style={{ left: sidebarPosition === SidebarPosition.Closed ? 0 : 64 }}>
        {children}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className='content-overlay' style={overlayStyle} onClick={() => dispatch(SidebarActionCreators.setSidebarPosition(SidebarPosition.Minimised))} />
      </div>
    </div>
  );
};

export default ContentContainer;
