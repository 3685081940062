import React from 'react';
import { useSelector } from 'react-redux';

import NotificationDisplay from './notification-display';
import { getNotifications } from '../notification-selectors';

import './notification-display-container.scss';

interface iProps {
  isMobile?: boolean;
}

const NotificationDisplayContainer = ({ isMobile = false }: iProps) => {
  const notifications = useSelector(getNotifications);

  const reversedNotifications = [...notifications].reverse();

  return (
    <div className='notification-display-container'>
      {reversedNotifications.map((notification) => (
        <div key={notification.ID}>
          <NotificationDisplay notification={notification} isMobile={isMobile} />
        </div>
      ))}
    </div>
  );
};

export default NotificationDisplayContainer;
