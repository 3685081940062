import { getDurationText } from '@gi/utils';

/**
 * HTTP status codes and their meanings.
 * Lazily scraped from {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Status | MDN Web Docs}
 */
const HTTP_STATUS_CODES = {
  100: 'Continue',
  101: 'Switching Protocols',
  102: 'Processing',
  103: 'Early Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status',
  208: 'Already Reported',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'unused',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot",
  421: 'Misdirected Request',
  422: 'Unprocessable Content',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
};

export interface NetworkRequestDetails {
  method: string;
  statusCode: number | null;
  timedOut?: boolean;
  duration?: number;
  url?: string;
  error?: Error;
  offline?: boolean;
  response?: Response;
  customProperties?: Record<string, { toString(): string }>;
}

/**
 * Appends status code text to a status code
 * @param statusCode The response status code
 * @param timedOut Did the request time out
 * @returns A string representation of the status code
 */
function getStatusCodeText(statusCode: number | null, timedOut?: boolean): string {
  if (statusCode === null) {
    return timedOut ? 'Timeout' : 'None';
  }
  const statusText = HTTP_STATUS_CODES[statusCode];
  const status = statusText ? `${statusCode} (${statusText})` : `${statusCode}`;
  // Can both get a response and time out if reading the body times out
  return timedOut ? `Timeout - ${status}` : status;
}

export function getRequestDetailsString({
  method,
  statusCode,
  timedOut,
  duration,
  url,
  error,
  customProperties,
  offline,
  title,
}: NetworkRequestDetails & { title?: string }) {
  let errorMessage = error ? '🌐❌ Network Request Error:\n' : '🌐⚠️ Network Request Failed:\n';

  if (title !== undefined) {
    errorMessage = `${title}\n`;
  }

  errorMessage += `\t- URL:     ${url ?? 'Unknown'}
\t- Method:  ${method}
\t- Status:  ${getStatusCodeText(statusCode, timedOut)}
\t- Time:    ${duration !== undefined ? getDurationText(duration) : 'Unknown'}
\t- Offline: ${offline ?? 'Unknown'}`;

  if (error) {
    errorMessage += `
\t- Error:   ${error.name} - ${error.message}`;
  }

  if (customProperties) {
    const keys = Object.keys(customProperties);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      errorMessage += `
\t- ${`${keys[i]}:`.padEnd(8, ' ')} ${customProperties[key]}`;
    }
  }

  return errorMessage;
}

export class NetworkRequestError extends Error {
  method: string;
  statusCode: number | null;
  timedOut: boolean;
  duration?: number;
  url?: string;
  error?: Error;
  offline?: boolean;
  response?: Response;
  customProperties?: Record<string, { toString(): string }>;
  clientMessage?: string;

  get statusCodeText() {
    if (this.statusCode === null) {
      return this.timedOut ? 'Timeout' : 'None';
    }
    if (HTTP_STATUS_CODES[this.statusCode]) {
      return HTTP_STATUS_CODES[this.statusCode];
    }
    return 'Unknown';
  }

  constructor({
    method,
    statusCode,
    timedOut,
    duration,
    url,
    error,
    response,
    customProperties,
    offline = window.navigator.onLine !== undefined ? !window.navigator.onLine : undefined,
  }: NetworkRequestDetails) {
    super(
      getRequestDetailsString({
        method,
        statusCode,
        timedOut,
        duration,
        url,
        error,
        customProperties,
        offline,
      })
    );
    this.name = 'NetworkRequestError';
    this.method = method;
    this.statusCode = statusCode;
    this.timedOut = timedOut ?? false;
    this.duration = duration;
    this.url = url;
    this.error = error;
    this.response = response;
    this.offline = offline;
    this.customProperties = customProperties;
  }

  toString() {
    return getRequestDetailsString({
      url: this.url,
      method: this.method,
      statusCode: this.statusCode,
      timedOut: this.timedOut,
      duration: this.duration,
      offline: this.offline,
      error: this.error,
      customProperties: this.customProperties,
    });
  }
}

export default NetworkRequestError;
