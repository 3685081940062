import moment from 'moment-timezone';
import { FGP_MAX, ModifierType } from '@gi/constants';

import { Geometry, MathUtils } from '@gi/math';
import type { APIPlanShape } from './plan-api-types';

// Maps the API string to the enum string (at the moment these match)
export const ModifierMap = {
  'COLD-FRAME': ModifierType.ColdFrame,
  CLOCHE: ModifierType.Cloche,
  'ROW-COVER': ModifierType.RowCover,
  GREENHOUSE: ModifierType.Greenhouse,
  'HEATED-GREENHOUSE': ModifierType.HeatedGreenhouse,
  POLYTUNNEL: ModifierType.Polytunnel,
  'HEATED-POLYTUNNEL': ModifierType.HeatedPolytunnel,
};

// Maps the enum string to the API string (at the moment these match)
export const ReverseModifierMap = {
  [ModifierType.ColdFrame]: 'COLD-FRAME',
  [ModifierType.Cloche]: 'CLOCHE',
  [ModifierType.RowCover]: 'ROW-COVER',
  [ModifierType.Greenhouse]: 'GREENHOUSE',
  [ModifierType.HeatedGreenhouse]: 'HEATED-GREENHOUSE',
  [ModifierType.Polytunnel]: 'POLYTUNNEL',
  [ModifierType.HeatedPolytunnel]: 'HEATED-POLYTUNNEL',
};

export enum ShapeTypeFromAPI {
  RECTANGLE = 'R',
  ELLIPSE = 'C',
  TRIANGLE = 'G',
  LINE = 'L',
  FILL = 'F',
  PLAN_DIMENSIONS = 'V',
}

/**
 * Converts a 'midpoint' from the API. The API sometimes uses 'FGP_MAX' (32767, the highest value the flash planner used for ints) to
 * represent a null value
 *
 * We sould also snap the mid value if it is approximately in the centre of the two items as shapes
 * and line garden objects do not save their 'curved' state. A midpoint of null means the line is straight. Else the midpoint is
 * used a bezier cuve control point
 */
export function midpointFromAPI(
  startX: number,
  startY: number,
  midX: number,
  midY: number,
  endX: number,
  endY: number,
  forceNotNull: boolean = false
): Vector2 | null {
  const mid: Vector2 = {
    x: midX,
    y: midY,
  };

  if (forceNotNull) {
    return mid;
  }

  if (midX === FGP_MAX || midY === FGP_MAX) {
    return null;
  }

  const midpoint = Geometry.midpoint({ x: startX, y: startY }, { x: endX, y: endY });
  const closeToXMid = MathUtils.isWithin(midpoint.x, mid.x, 1);
  const closeToYMid = MathUtils.isWithin(midpoint.y, mid.y, 1);

  if (closeToXMid && closeToYMid) {
    return null;
  }

  return mid;
}

export function midpointToAPI(start: Vector2, mid: Vector2 | null, end: Vector2): [number, number] {
  if (mid === null) {
    const midpoint = Geometry.roundPoint(Geometry.midpoint(start, end));
    return [midpoint.x, midpoint.y];
  }

  return [mid.x, mid.y];
}

export function rotationFromAPI(apiRotation: number): number {
  return apiRotation * (Math.PI / 180);
}

export function rotationToAPI(rotation: number): number {
  return Math.round(rotation * (180 / Math.PI));
}

/**
 * Older plans had their dimensions set inside garden plan shapes
 *
 * If the plan dimensions are stored in a shape, this function returns an object with
 * the width and height properties for the plan dimensions.
 *
 * If the plan dimensions are not stored in a shape this function returns null
 */
export const checkShapesForPlanDimensions = (gardenPlanShapes: APIPlanShape[]): Dimensions | null => {
  // Copy array so we're not modifying it if we splice it

  for (let i = 0; i < gardenPlanShapes.length; i++) {
    if (gardenPlanShapes[i].objectType === ShapeTypeFromAPI.PLAN_DIMENSIONS) {
      return {
        width: gardenPlanShapes[i].endX,
        height: gardenPlanShapes[i].endY,
      };
    }
  }

  return null;
};

/**
 * Dimension and fill shapes are no longer used so we remove them
 */
export function filterShapesForPlanFillsAndDimensions(gardenPlanShapes: APIPlanShape[]): APIPlanShape[] {
  return gardenPlanShapes.filter(
    (gardenPlanShape) => gardenPlanShape.objectType !== ShapeTypeFromAPI.FILL && gardenPlanShape.objectType !== ShapeTypeFromAPI.PLAN_DIMENSIONS
  );
}

export function timeStringToUnix(time: string): number {
  return moment.tz(time, 'DD/MM/YYYY HH:mm:ss', 'UTC').unix();
}

export function dateToAPI(date: number | null): string {
  if (date === null) {
    return '';
  }

  return moment.unix(date).utc().format('DD/MM/YYYY HH:mm:ss').trim();
}
