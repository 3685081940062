import { createPlantingCalendarFromNumbers, PlantingCalendar } from './planting-calendar';
import { NumberedPlantingCalendar } from './planting-calendar-types';

/**
 * Format of file from api, [plantCode] => [0, 0, 0, 0, 1, 33, 333, 20, 0, 0, 0, 0]
 */
export type APIRegionalPlantingCalendars = { [plantCode: string]: NumberedPlantingCalendar };

export type RegionalPlantingCalendar = {
  // plantingNumbers: number[],
  plantingCalendar: PlantingCalendar; // TODO: Remove, add just numbers
  countryCode: string;
  regionID: number;
  plantCode: string;
};

export type UserRegionalPlantingCalendar = { [plantCode: string]: RegionalPlantingCalendar };

/**
 * Helper method for getting regional planting dates
 */
export const getPlantRegionalPlantingCalendars = (
  plantCode: string,
  userRegionalPlantingData: UserRegionalPlantingCalendar
): RegionalPlantingCalendar | null => {
  if (userRegionalPlantingData[plantCode]) {
    return userRegionalPlantingData[plantCode];
  }

  return null;
};

export const parseRegionalPlantingCalendarsFromAPI = (
  apiResponse: APIRegionalPlantingCalendars,
  countryCode: string,
  regionID: number
): UserRegionalPlantingCalendar => {
  const userRegionalPlantingCalendar = {};

  const plantCodes = Object.keys(apiResponse);

  for (let i = 0; i < plantCodes.length; i++) {
    userRegionalPlantingCalendar[plantCodes[i]] = {
      plantingCalendar: createPlantingCalendarFromNumbers(apiResponse[plantCodes[i]]),
      countryCode,
      regionID,
      plantCode: plantCodes[i],
    };
  }

  return userRegionalPlantingCalendar;
};

export type APIRegionalPlantingData = {
  countryCode: string;
  regionID: number;
  plantCode: string;
  values: NumberedPlantingCalendar;
};

export const parseAllRegionalPlantingCalendars = (
  regionalPlantingData: APIRegionalPlantingData[]
): { [countryCode: string]: { [regionID: number]: UserRegionalPlantingCalendar } } => {
  const parsed = {};

  for (let i = 0; i < regionalPlantingData.length; i++) {
    const data = regionalPlantingData[i];

    if (!parsed[data.countryCode]) {
      parsed[data.countryCode] = {};
    }

    if (!parsed[data.countryCode][data.regionID]) {
      parsed[data.countryCode][data.regionID] = {};
    }

    parsed[data.countryCode][data.regionID][data.plantCode] = {
      plantingCalendar: createPlantingCalendarFromNumbers(data.values),
      countryCode: data.countryCode,
      regionID: data.regionID,
      plantCode: data.plantCode,
    };
  }

  return parsed;
};
