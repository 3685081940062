import { SimulatedPlan } from '@gi/plan-simulation';
import { randomUUID } from '@gi/utils';
import { GardenObjectShoppingEntry, PlantShoppingEntry, ShoppingEntryUtils, ShoppingListInputs } from './shopping-entry';
import { ProductType, ShoppingListProducts, ShoppingListProductsUtils } from './shopping-product';
import { ShoppingList, ShoppingListUtils } from './shopping-list';

/**
 * Creates an object representing the type and quantities of Plants and Garden objects of a plan
 * from a SimulatedPlan
 *
 * The resulting object is a simplified summary of the items on a plan
 */
export function createShoppingListInputs(simulatedPlan: SimulatedPlan): ShoppingListInputs {
  const byPlant: Record<string, Record<string, number>> = {};
  const byGardenObject: Record<string, number> = {};

  simulatedPlan.plantIds.forEach((id) => {
    const { code } = simulatedPlan.plants[id].plant;
    const { variety } = simulatedPlan.plants[id];

    if (!byPlant[code]) {
      byPlant[code] = {};
    }

    if (!byPlant[code][variety]) {
      byPlant[code][variety] = 0;
    }

    byPlant[code][variety] += simulatedPlan.plants[id].plantCount.total;
  });

  simulatedPlan.gardenObjectIds.forEach((id) => {
    const { code } = simulatedPlan.gardenObjects[id].gardenObject;

    if (!byGardenObject[code]) {
      byGardenObject[code] = 0;
    }

    byGardenObject[code] += 1;
  });

  const inputs: ShoppingListInputs = {
    planId: simulatedPlan.id,
    gardenObjects: [],
    plants: [],
  };

  Object.keys(byPlant).forEach((plantCode) => {
    Object.keys(byPlant[plantCode]).forEach((variety) => {
      inputs.plants.push({
        plantCode,
        variety,
        count: byPlant[plantCode][variety],
      });
    });
  });

  Object.keys(byGardenObject).forEach((code) => {
    inputs.gardenObjects.push({
      code,
      count: byGardenObject[code],
    });
  });

  return inputs;
}

/**
 * Creates a ShoppingList from data provided by ShoppingListInputs (derived directly from a SimulatedPlan) and ShoppingListProducts
 */
export function createShoppingList(shoppingListInputs: ShoppingListInputs, shoppingListProducts: ShoppingListProducts): ShoppingList {
  const plantsByVariant: Record<number, PlantShoppingEntry> = {};
  const gardenObjectsByVariant: Record<number, GardenObjectShoppingEntry> = {};

  shoppingListInputs.plants.forEach((plantInput) => {
    if (!shoppingListProducts.variantsByCode[plantInput.plantCode]) {
      return;
    }

    // General variant for plant
    const defaultPlantVariant = shoppingListProducts.variantsByCode[plantInput.plantCode][0];
    const defaultPlantProduct = shoppingListProducts.byVariantId[defaultPlantVariant];
    // Specific variant for variety
    const varietyPlantVariant = shoppingListProducts.variantsByPlantCodeAndVariety[plantInput.plantCode][plantInput.variety];
    const varietyPlantProduct = shoppingListProducts.byVariantId[varietyPlantVariant];

    if (varietyPlantVariant) {
      if (!plantsByVariant[varietyPlantVariant]) {
        plantsByVariant[varietyPlantVariant] = {
          uuid: randomUUID(),
          variantId: varietyPlantVariant,
          productId: varietyPlantProduct,
          type: ProductType.Plant,
          cartQuantity: 1,
          inputQuantity: 0,
          matchData: {
            plantCode: plantInput.plantCode,
            varieties: [],
          },
          inCart: false,
        };
      }

      plantsByVariant[varietyPlantVariant].matchData.varieties.push({ name: plantInput.variety, count: plantInput.count });
      plantsByVariant[varietyPlantVariant].inputQuantity += plantInput.count;
    } else if (defaultPlantVariant) {
      if (!plantsByVariant[defaultPlantVariant]) {
        plantsByVariant[defaultPlantVariant] = {
          uuid: randomUUID(),
          variantId: defaultPlantVariant,
          productId: defaultPlantProduct,
          type: ProductType.Plant,
          inputQuantity: 0,
          cartQuantity: 1,
          matchData: {
            plantCode: plantInput.plantCode,
            varieties: [],
          },
          inCart: false,
        };
      }

      plantsByVariant[defaultPlantVariant].matchData.varieties.push({ name: plantInput.variety, count: plantInput.count });
      plantsByVariant[defaultPlantVariant].inputQuantity += plantInput.count;
    } else {
      console.warn('No product match', plantInput.plantCode);
    }
  });

  shoppingListInputs.gardenObjects.forEach((gardenObjectInput) => {
    if (!shoppingListProducts.variantsByCode[gardenObjectInput.code]) {
      return;
    }

    const variantId = shoppingListProducts.variantsByCode[gardenObjectInput.code][0];
    const productId = shoppingListProducts.byVariantId[variantId];

    if (productId) {
      if (!gardenObjectsByVariant[productId]) {
        gardenObjectsByVariant[productId] = {
          uuid: randomUUID(),
          variantId,
          productId,
          type: ProductType.GardenObject,
          cartQuantity: 1,
          inputQuantity: 0,
          matchData: {
            code: gardenObjectInput.code,
          },
          inCart: false,
        };
      }

      gardenObjectsByVariant[productId].inputQuantity += gardenObjectInput.count;
    } else {
      console.warn('No product match', gardenObjectInput.code);
    }
  });

  const shoppingList: ShoppingList = ShoppingListUtils.create(shoppingListInputs.planId);

  Object.keys(plantsByVariant).forEach((key) => {
    const entry = plantsByVariant[key] as PlantShoppingEntry;
    const variant = ShoppingListProductsUtils.getVariant(shoppingListProducts, entry.variantId);
    ShoppingEntryUtils.setRecommendedQuantity(entry, variant);
    ShoppingListUtils.setEntry(shoppingList, entry);
  });

  Object.keys(gardenObjectsByVariant).forEach((key) => {
    const entry = gardenObjectsByVariant[key] as GardenObjectShoppingEntry;
    const variant = ShoppingListProductsUtils.getVariant(shoppingListProducts, entry.variantId);
    ShoppingEntryUtils.setRecommendedQuantity(entry, variant);
    ShoppingListUtils.setEntry(shoppingList, entry);
  });

  return shoppingList;
}
