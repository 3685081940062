import React, { useState, useMemo } from 'react';

import { ElementPrinterContext, ElementPrinterContextType } from './element-printer-context';
import { printElement } from './element-printer';
import ElementPrinterModalRender from './element-printer-modal-renderer';

import './element-printer-provider.scss';

interface iProps {
  children: React.ReactNode;
}

export const ElementPrinterProvider = ({ children }: iProps): JSX.Element => {
  const [isPrinting, setIsPrinting] = useState(false);
  const [printWindowOpen, setPrintWindowOpen] = useState(false);
  const [tearDown, setTearDown] = useState<(() => void) | null>(null);

  const openPrintWindow = () => {
    setPrintWindowOpen(true);
  };

  const closePrintWindow = () => {
    setPrintWindowOpen(false);
    if (tearDown) {
      setTimeout(tearDown, 0);
      setTearDown(null);
    }
  };

  const tryPrintElement = (element: HTMLElement) => {
    setIsPrinting(true);
    setPrintWindowOpen(true);
    printElement(element).then((cleanUp) => {
      setIsPrinting(false);
      setTearDown(() => cleanUp);
    });
  };

  const value = useMemo<ElementPrinterContextType>(() => {
    return {
      printElement: tryPrintElement,
      isPrinting,
      openPrintWindow,
      closePrintWindow,
      printWindowOpen,
    };
  }, [tryPrintElement, isPrinting, printWindowOpen, openPrintWindow, closePrintWindow]);

  return (
    <ElementPrinterContext.Provider value={value}>
      <ElementPrinterModalRender />
      {children}
    </ElementPrinterContext.Provider>
  );
};
