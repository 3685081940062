import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { Column, Page, PageContent, PageHeading, PageHeadingTitle, PageRow } from '@gi/page-layout';
import { AppSupportSelectors } from '@gi/app-help-slice';

import Support from './support-support/support';
import GardenPlannerAbout from './garden-planner-support/garden-planner-about';
import GardenJournalAbout from './journal-support/journal-about';
import UpdateHistory from './update-history-support/update-history';
import PageNavigation from './page-navigation';

const AppHelpDesktop = (): JSX.Element => {
  const activePage = useSelector(AppSupportSelectors.getActivePage);

  let pageContent: ReactNode = null;

  switch (activePage.page) {
    case 'SUPPORT': {
      pageContent = <Support />;
      break;
    }
    case 'GARDEN_PLANNER': {
      pageContent = <GardenPlannerAbout />;
      break;
    }
    case 'GARDEN_JOURNAL': {
      pageContent = <GardenJournalAbout />;
      break;
    }
    case 'UPDATE_HISTORY': {
      pageContent = <UpdateHistory />;
      break;
    }
    default: {
      pageContent = null;
    }
  }

  return (
    <Page>
      <PageHeading matchNavbarHeight noBackground>
        <PageHeadingTitle>
          <h1>Help & Support</h1>
        </PageHeadingTitle>
      </PageHeading>
      <PageContent>
        <PageRow>
          <Column desktopSize={3}>
            <PageNavigation />
          </Column>
          <Column desktopSize={9} className='page-pad-x page-pad-y'>
            {pageContent}
          </Column>
        </PageRow>
      </PageContent>
    </Page>
  );
};

export default AppHelpDesktop;
