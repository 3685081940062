import { batchActions } from 'redux-batched-actions';
import { RequestActionCreators } from '@gi/react-requests';
import { NotificationActionCreators, generateNotificationID } from '@gi/notifications';
import { RequestsUtils } from '@gi/request';

import { Reminder, ReminderData } from './reminder';
import { ReminderActionCreators } from './reminder-slice';
import ReminderService from './service/reminder-service';
import { addReminders, createInitialReminders } from './reminder-utils';
import * as ReminderNotifications from './reminder-notifications';

export const ReminderRequestNames = {
  LoadDashboardReminders: () => 'LoadDashboardReminders',
  UpdateReminder: (reminderID: number) => `UpdateReminder${reminderID}`,
  CreateReminder: () => 'CreateReminder',
};

export const updateReminder = (reminder: Reminder) => {
  return (dispatch, getState, { services }) => {
    const notificationID = generateNotificationID();
    const requestID = ReminderRequestNames.UpdateReminder(reminder.ID);

    dispatch(
      batchActions([
        RequestActionCreators.requestStart(requestID),
        NotificationActionCreators.createDefaultNotification(ReminderNotifications.updateReminderStart(notificationID)),
      ])
    );

    const state = getState();
    const { user } = state.session;

    const reminderService = services.reminderService as ReminderService;
    reminderService
      .saveReminder(user.ID, reminder)
      .then((updatedReminder) => {
        dispatch(
          batchActions([
            ReminderActionCreators.updateReminder(updatedReminder),
            RequestActionCreators.requestComplete(requestID),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: ReminderNotifications.updateReminderSuccess,
            }),
          ])
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          batchActions([
            RequestActionCreators.requestFail(requestID, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: ReminderNotifications.updateReminderFail,
            }),
          ])
        );
      });
  };
};

/**
 * The API doesn't support changing the date of a reminder, so we have to destroy it and re-create it.
 * Not a great solution.
 * @param reminder The reminder toi re-create
 * @returns A new reminder, with the same data as the old reminder
 */
export const recreateReminder = (reminder: Reminder) => {
  return (dispatch, getState, { services }) => {
    const notificationID = generateNotificationID();
    const requestID = ReminderRequestNames.UpdateReminder(reminder.ID);

    dispatch(
      batchActions([
        RequestActionCreators.requestStart(requestID),
        NotificationActionCreators.createDefaultNotification(ReminderNotifications.updateReminderStart(notificationID)),
      ])
    );

    const state = getState();
    const { user } = state.session;

    const reminderService = services.reminderService as ReminderService;
    reminderService
      .deleteReminder(user.ID, reminder)
      .then(() => {
        const reminderData: ReminderData = {
          dateYMD: reminder.dateYMD,
          dismissed: reminder.dismissed,
          note: reminder.note,
          relatedPlants: reminder.relatedPlants,
        };
        reminderService
          .createReminder(user.ID, reminderData)
          .then((newReminder) => {
            dispatch(
              batchActions([
                ReminderActionCreators.removeReminder(reminder.ID),
                ReminderActionCreators.addReminder(newReminder),
                RequestActionCreators.requestComplete(requestID),
                NotificationActionCreators.updateNotificationByID({
                  notificationID,
                  update: ReminderNotifications.updateReminderSuccess,
                }),
              ])
            );
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          batchActions([
            RequestActionCreators.requestFail(requestID, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: ReminderNotifications.updateReminderFail,
            }),
          ])
        );
      });
  };
};

export const createReminder = (reminder: ReminderData) => {
  return (dispatch, getState, { services }) => {
    const notificationID = generateNotificationID();
    const requestID = ReminderRequestNames.CreateReminder();

    dispatch(
      batchActions([
        RequestActionCreators.requestStart(requestID),
        NotificationActionCreators.createDefaultNotification(ReminderNotifications.createReminderStart(notificationID)),
      ])
    );

    const state = getState();
    const { user } = state.session;

    const reminderService = services.reminderService as ReminderService;

    reminderService
      .createReminder(user.ID, reminder)
      .then((createdReminder) => {
        dispatch(
          batchActions([
            ReminderActionCreators.addReminder(createdReminder),
            RequestActionCreators.requestComplete(requestID),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: ReminderNotifications.createReminderSuccess,
            }),
          ])
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          batchActions([
            RequestActionCreators.requestFail(requestID, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: ReminderNotifications.createReminderFail,
            }),
          ])
        );
      });
  };
};

export const loadReminders = (startDate = new Date(), numDays = 14) => {
  return (dispatch, getState, { services }) => {
    const requestID = ReminderRequestNames.LoadDashboardReminders();

    const state = getState();
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + numDays);

    const { user } = state.session;

    if (RequestsUtils.hasRequest(state.requests, requestID) && !RequestsUtils.requestFailed(state.requests, requestID)) {
      // Already loading, do nothing
      return;
    }

    dispatch(RequestActionCreators.requestStart(requestID));

    const reminderService = services.reminderService as ReminderService;
    reminderService
      .getReminders(user.ID, startDate, endDate)
      .then((reminderList) => {
        let reminders = createInitialReminders();
        reminders = addReminders(reminders, reminderList);

        dispatch(batchActions([ReminderActionCreators.setReminders(reminders), RequestActionCreators.requestComplete(requestID)]));
      })
      .catch((err) => {
        dispatch(RequestActionCreators.requestFail(requestID, err));
      });
  };
};
