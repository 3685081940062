import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CanvasPlan } from '@gi/plan-simulation';
import { ContextMenu, ContextMenuContent, ContextMenuPointTarget, ContextMenuUtils } from '@gi/context-menu';
import { CanvasActionCreators, CanvasSelectors, GardenCanvasContext } from '@gi/react-garden-canvas';

import { PlannerCommandContext } from '../planner-command-provider';

const DesktopPlannerContextMenu = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const [options, setOptions] = useState<JSX.Element[] | null>(null);
  const [position, setPosition] = useState<Vector2 | null>(null);

  const { gardenCanvas } = useContext(GardenCanvasContext);
  const { getContextMenuOptions } = useContext(PlannerCommandContext);

  const contextMenuWorldPosition = useSelector(CanvasSelectors.getContextMenuWorldPosition);

  // Callback for the context menu to call when it wants to close. Forward the change to the state.
  const onOpenChange = useCallback((open: boolean) => {
    if (!open) {
      dispatch(CanvasActionCreators.hideContextMenu());
    }
  }, []);

  // Watch the active plan for camera movements and close the menu. Could move the menu instead, but it feels wrong.
  const closeOnCameraMove = useCallback((canvasPlan: CanvasPlan) => {
    const onCameraMove = (/* screenPos: Vector2 */) => {
      setPosition(null);
      canvasPlan.camera.untrackWorldPositionOnScreen(onCameraMove);
    };
    canvasPlan.camera.trackWorldPositionOnScreen({ x: 0, y: 0 }, onCameraMove);

    return () => {
      canvasPlan.camera.untrackWorldPositionOnScreen(onCameraMove);
    };
  }, []);

  // When the `getContextMenuOptions` function changes, the selection has probably changed, so kill the context menu
  useEffect(() => {
    if (options !== null) {
      setOptions(null);
    }
  }, [getContextMenuOptions]);

  // When `shouldShowContextMenu` becomes a position, convert it to a screen position and open the context menu
  useEffect(() => {
    const activePlan = gardenCanvas?.getActivePlan();
    const newOptions = getContextMenuOptions();

    if (!gardenCanvas || !contextMenuWorldPosition || !activePlan || !newOptions) {
      setOptions(null);
      setPosition(null);
      return () => {};
    }

    setOptions(newOptions ? ContextMenuUtils.makeContextMenuOptions(newOptions, () => onOpenChange(false)) : null);
    setPosition(activePlan.canvasPlan.camera.getScreenPos(contextMenuWorldPosition));

    // Track the camera for position changes. We could update the context menu position, but it feels better to just destroy it.
    return closeOnCameraMove(activePlan.canvasPlan);
  }, [contextMenuWorldPosition]);

  // Don't show if we're missing a position or options
  if (!position || !options) {
    return null;
  }

  return (
    <ContextMenu placement='right-start' open={contextMenuWorldPosition !== false} onOpenChange={onOpenChange}>
      <ContextMenuPointTarget position={position} strategy='absolute' />
      <ContextMenuContent>{options}</ContextMenuContent>
    </ContextMenu>
  );
};

export default DesktopPlannerContextMenu;
