import React, { useState } from 'react';

import { FullscreenButton } from '@gi/fullscreen-button';
import { useRenderMode } from '@gi/react-garden-canvas';
import GardenPlatformVersionsModal from '@gi/garden-platform-versions';

import './mobile-nav-footer.scss';

const MobileNavigationFooter = (): JSX.Element => {
  const renderMode = useRenderMode();
  const [showAttributionModal, setShowAttributionModal] = useState(false);

  return (
    <div className='mobile-navigation-footer'>
      {showAttributionModal ? <GardenPlatformVersionsModal closeModal={() => setShowAttributionModal(false)} /> : null}
      <FullscreenButton className='mobile-navigation-footer-fullscreen' textClassName='hidden' />
      <div className='mobile-navigation-footer-right'>
        <button type='button' className='mobile-version-number-button' onClick={() => setShowAttributionModal(true)}>
          <p>
            Version: <strong>{VERSION}</strong> <span className='divider'>-</span> Mode: <strong>{renderMode}</strong>
          </p>
          <p>&copy; Growing Interactive Ltd 2007-2025</p>
        </button>
      </div>
    </div>
  );
};

export default MobileNavigationFooter;
