import React, { useContext } from 'react';
import { PlantingCalendarKeyHarvest, PlantingCalendarKeyIndoor, PlantingCalendarKeyOutdoor } from '@gi/planting-calendar';
import { updateFilters } from '@gi/filters';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import ColumnSortButton from '../../common/column-sort-buttons/column-sort-button';
import ColumnSortIcon from '../../common/column-sort-buttons/column-sort-icon';
import { DISPLAY_TYPE } from '../../constants';
import { SORT_ORDER } from '../../types/plant-list-types';

import './calendar-header-cell.scss';

const CalendarHeaderCell = () => {
  const { columns, plantGroupFilters, setPlantGroupFilters, varietyGroupFilters, setVarietyGroupFilters, areaGroupFilters, setAreaGroupFilters } =
    useContext(GlobalPlantListContext);

  if (!columns.calendar) {
    return null;
  }

  return (
    <th className='header-cell left-align-cell'>
      <div className='header-cell-content'>
        <div className='header-row'>
          <span className='header-cell-text'>Planting Calendar</span>
        </div>
        <div className='header-row'>
          <div className='planting-calendar-sort-key'>
            <div className='sort-key'>
              <PlantingCalendarKeyIndoor />
              <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Summary}>
                <ColumnSortIcon
                  upSortType={SORT_ORDER.SowStartAsc}
                  downSortType={SORT_ORDER.SowStartDesc}
                  currentSortType={plantGroupFilters.filters.sort.inputs.sortOrder}
                  setSortOrder={(sortOrder: SORT_ORDER) => {
                    setPlantGroupFilters(updateFilters(plantGroupFilters, { sort: { sortOrder } }));
                  }}
                />
              </ColumnSortButton>
              <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Variety}>
                <ColumnSortIcon
                  upSortType={SORT_ORDER.SowStartAsc}
                  downSortType={SORT_ORDER.SowStartDesc}
                  currentSortType={varietyGroupFilters.filters.sort.inputs.sortOrder}
                  setSortOrder={(sortOrder: SORT_ORDER) => {
                    setVarietyGroupFilters(updateFilters(varietyGroupFilters, { sort: { sortOrder } }));
                  }}
                />
              </ColumnSortButton>
              <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Area}>
                <ColumnSortIcon
                  upSortType={SORT_ORDER.SowStartAsc}
                  downSortType={SORT_ORDER.SowStartDesc}
                  currentSortType={areaGroupFilters.filters.sort.inputs.sortOrder}
                  setSortOrder={(sortOrder: SORT_ORDER) => {
                    setAreaGroupFilters(updateFilters(areaGroupFilters, { sort: { sortOrder } }));
                  }}
                />
              </ColumnSortButton>
            </div>

            <div className='sort-key'>
              <PlantingCalendarKeyOutdoor />
              <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Summary}>
                <ColumnSortIcon
                  upSortType={SORT_ORDER.PlantStartAsc}
                  downSortType={SORT_ORDER.PlantStartDesc}
                  currentSortType={plantGroupFilters.filters.sort.inputs.sortOrder}
                  setSortOrder={(sortOrder: SORT_ORDER) => {
                    setPlantGroupFilters(updateFilters(plantGroupFilters, { sort: { sortOrder } }));
                  }}
                />
              </ColumnSortButton>
              <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Variety}>
                <ColumnSortIcon
                  upSortType={SORT_ORDER.PlantStartAsc}
                  downSortType={SORT_ORDER.PlantStartDesc}
                  currentSortType={varietyGroupFilters.filters.sort.inputs.sortOrder}
                  setSortOrder={(sortOrder: SORT_ORDER) => {
                    setVarietyGroupFilters(updateFilters(varietyGroupFilters, { sort: { sortOrder } }));
                  }}
                />
              </ColumnSortButton>
              <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Area}>
                <ColumnSortIcon
                  upSortType={SORT_ORDER.PlantStartAsc}
                  downSortType={SORT_ORDER.PlantStartDesc}
                  currentSortType={areaGroupFilters.filters.sort.inputs.sortOrder}
                  setSortOrder={(sortOrder: SORT_ORDER) => {
                    setAreaGroupFilters(updateFilters(areaGroupFilters, { sort: { sortOrder } }));
                  }}
                />
              </ColumnSortButton>
            </div>

            <div className='sort-key'>
              <PlantingCalendarKeyHarvest />
              <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Summary}>
                <ColumnSortIcon
                  upSortType={SORT_ORDER.HarvestStartAsc}
                  downSortType={SORT_ORDER.HarvestStartDesc}
                  currentSortType={plantGroupFilters.filters.sort.inputs.sortOrder}
                  setSortOrder={(sortOrder: SORT_ORDER) => {
                    setPlantGroupFilters(updateFilters(plantGroupFilters, { sort: { sortOrder } }));
                  }}
                />
              </ColumnSortButton>
              <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Variety}>
                <ColumnSortIcon
                  upSortType={SORT_ORDER.HarvestStartAsc}
                  downSortType={SORT_ORDER.HarvestStartDesc}
                  currentSortType={varietyGroupFilters.filters.sort.inputs.sortOrder}
                  setSortOrder={(sortOrder: SORT_ORDER) => {
                    setVarietyGroupFilters(updateFilters(varietyGroupFilters, { sort: { sortOrder } }));
                  }}
                />
              </ColumnSortButton>
              <ColumnSortButton buttonDisplayType={DISPLAY_TYPE.Area}>
                <ColumnSortIcon
                  upSortType={SORT_ORDER.HarvestStartAsc}
                  downSortType={SORT_ORDER.HarvestStartDesc}
                  currentSortType={areaGroupFilters.filters.sort.inputs.sortOrder}
                  setSortOrder={(sortOrder: SORT_ORDER) => {
                    setAreaGroupFilters(updateFilters(areaGroupFilters, { sort: { sortOrder } }));
                  }}
                />
              </ColumnSortButton>
            </div>
          </div>
        </div>
      </div>
    </th>
  );
};

export default CalendarHeaderCell;
