import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PlanSetUtils } from '@gi/plan';
import { PlannerSettingsModalRenderer } from '@gi/react-planner-settings';
import { EditPlantNotesModalRenderer } from '@gi/edit-plant-notes-modal';
import { PlantInformationModalRenderer } from '@gi/plant-information-modal';
import { GardenItemType } from '@gi/constants';
import { PlanImageModalRenderer } from '@gi/react-plan-image';
import { ReminderModalRenderer } from '@gi/reminders';
import { OpenPlanModalRenderer } from '@gi/open-plan-modal';
import { PlanDataErrorModalRenderer } from '@gi/plan-data-error-modal';

import ModalTypes from '../modal-types';

import GardenObjectInfoModalRenderer from '../modal-renderers/garden-object-info-modal-renderer';

import NewPlanModalRenderer from '../modal-renderers/new-plan-modal-renderer';

import ConnectedPublishPlanModal from '../connected-modals/connected-publish-plan-modal';
import ConnectedEditPlantModal from '../connected-modals/connected-edit-plant-modal';
import ConnectedEditGardenObjectModal from '../connected-modals/connected-edit-garden-object-modal';
import ConnectedEditShapeModal from '../connected-modals/connected-edit-shape-modal';
import ConnectedEditTextModal from '../connected-modals/connected-edit-text-modal';
import ConnectedTransferPlanModalRenderer from '../connected-modals/connected-transfer-plan-modal-renderer';

class ModalRenderer extends PureComponent {
  getActivePlan() {
    if (this.props.activePlanID === null) {
      return null;
    }

    return PlanSetUtils.planSetGetPlan(this.props.plans, this.props.activePlanID);
  }

  renderEditItemModals() {
    const editItemData = this.props.editItemData;

    if (editItemData === null) {
      console.warn('No edit item data provided for edit item modal');
      return null;
    }

    if (!editItemData.itemID) {
      console.warn('No item ID available for editing a plan item');
      return null;
    }

    if (!editItemData.itemType) {
      console.warn('No item type available for editing a plan item');
      return null;
    }

    if (!editItemData.planID) {
      console.warn('No plan ID available for editing a plan item');
    }

    if (editItemData.planID !== this.props.activePlanID) {
      console.warn('Current item edit data is not for current active plan');
      return null;
    }

    const activePlan = this.getActivePlan();

    if (activePlan === null) {
      console.warn('Active plan unavailable');
      return null;
    }

    if (!activePlan.itemTypes[editItemData.itemID]) {
      console.warn('Active plan does not have editable item:', editItemData.itemID);
      return null;
    }

    if (editItemData.itemType === GardenItemType.Plant) {
      return <ConnectedEditPlantModal />;
    }

    if (editItemData.itemType === GardenItemType.GardenObject) {
      return <ConnectedEditGardenObjectModal />;
    }

    if (editItemData.itemType === GardenItemType.Shape) {
      return <ConnectedEditShapeModal />;
    }

    if (editItemData.itemType === GardenItemType.Text) {
      return <ConnectedEditTextModal />;
    }

    console.error('Invalid item type for edit modal');
    return null;
  }

  renderActiveModals() {
    switch (this.props.activeModal) {
      case null:
        return null;
      case ModalTypes.PUBLISH_PLAN_MODAL:
        return <ConnectedPublishPlanModal />;
      case ModalTypes.EDIT_ITEM:
        return this.renderEditItemModals();
      default:
        return null;
    }
  }

  render() {
    return (
      <>
        {this.renderActiveModals()}
        <OpenPlanModalRenderer />
        <NewPlanModalRenderer />
        <PlannerSettingsModalRenderer />
        <GardenObjectInfoModalRenderer />
        <PlantInformationModalRenderer />
        <ConnectedTransferPlanModalRenderer />
        <EditPlantNotesModalRenderer />
        <PlanImageModalRenderer />
        <ReminderModalRenderer />
        <PlanDataErrorModalRenderer />
      </>
    );
  }
}

ModalRenderer.propTypes = {
  activeModal: PropTypes.string,
  editItemData: PropTypes.shape({
    itemID: PropTypes.number.isRequired,
    itemType: PropTypes.string.isRequired,
    planID: PropTypes.number.isRequired,
  }),
  activePlanID: PropTypes.number,
  plans: PropTypes.any,
};

ModalRenderer.defaultProps = {
  activeModal: null,
  editItemData: null,
  activePlanID: null,
  plans: PlanSetUtils.createPlanSet(),
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    activeModal: state.modal.activeModal,
    editItemData: state.modal.editItemData,
    activePlanID: state.canvas.activePlanID,
    plans: state.canvas.plans,
  };
};

export default connect(mapStateToProps)(ModalRenderer);
