import React from 'react';

import ResourceContext from './resource-context';

export default function withResources(Component) {
  return function ResourcesComponent(props) {
    return (
      <ResourceContext.Consumer>
        {({
          countries,
          catalogue,
          supplierCatalogue,
          plantSearchService,
          gardenObjectSearchService,
          userCountry,
          userPlants,
          userGardenObjects,
          userGardenObjectGroups,
          userRegionalPlantingCalendar,
          useRegionalPlanting,
          userArtifactCode,
        }) => {
          return (
            <Component
              {...props}
              countries={countries}
              catalogue={catalogue}
              supplierCatalogue={supplierCatalogue}
              plantSearchService={plantSearchService}
              gardenObjectSearchService={gardenObjectSearchService}
              userCountry={userCountry}
              userPlants={userPlants}
              userGardenObjects={userGardenObjects}
              userGardenObjectGroups={userGardenObjectGroups}
              userRegionalPlantingCalendar={userRegionalPlantingCalendar}
              userArtifactCode={userArtifactCode}
              useRegionalPlanting={useRegionalPlanting}
            />
          );
        }}
      </ResourceContext.Consumer>
    );
  };
}
