import React from 'react';

import { InputContainer } from '@gi/form-responsive';
import { PlantListFilterInputs } from '@gi/plant-list';

import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';

import styles from '../common/menu-button.module.css';

const PlantListFilterControls = (): JSX.Element => {
  return (
    <ControlGroup>
      <ControlRow>
        <InputContainer className={styles.textInputContainer} inputPrefix={<i className='icon-search' />} inputPrefixInline>
          <PlantListFilterInputs.NameFilterInput inputOnly />
        </InputContainer>
      </ControlRow>
    </ControlGroup>
  );
};

export default PlantListFilterControls;
