import React, { useCallback } from 'react';

import NumericBlurInput from './numeric-blur-input';

import './length-input.scss';

interface iProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  valid?: boolean;
  inputClasses?: string;
  inline?: boolean;
  id?: string;
}

const PercentInput = ({ value, onChange, disabled = false, valid = true, inputClasses = '', inline = false, id }: iProps): JSX.Element => {
  let className = 'length-input-container';

  if (disabled) {
    className += ' disabled';
  }

  const handleOnChange = useCallback(
    (newValue: number) => {
      onChange(Math.min(Math.max(Math.round(newValue), 0), 100));
    },
    [onChange]
  );

  return (
    <NumericBlurInput
      id={id}
      disabled={disabled}
      inputMode='numeric'
      className={className}
      inputClassName={inputClasses}
      value={value}
      onChange={handleOnChange}
      size={84}
      inputSuffix='%'
      inputSuffixInline={inline}
      valid={valid}
    />
  );
};

export default PercentInput;
