import React, { useContext, useMemo } from 'react';
import { TutorialEditorContext } from '../tutorial-editor-context';

import styles from './json-input.module.css';

interface iProps {
  jsonString: string;
  setJsonString: (jsonString: string) => void;
}

const EditorJsonInput = ({ jsonString, setJsonString }: iProps): JSX.Element => {
  const { editingErrors } = useContext(TutorialEditorContext);

  const validJson = useMemo<boolean>(() => {
    if (jsonString === '') {
      return false;
    }

    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      return false;
    }
  }, [jsonString]);

  return (
    <div className={styles.editorJsonContainer}>
      <textarea
        className={`${styles.editorJsonInput} ${!validJson || (editingErrors !== null && editingErrors.length > 0) ? styles.editorJsonError : ''}`}
        value={jsonString}
        onChange={(e) => setJsonString(e.target.value)}
      />
    </div>
  );
};

export default EditorJsonInput;
