import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Application } from '@gi/garden-platform-navigation';

export type InitParamsState = {
  intercomAction: string | null;
  intercomApp: Application | null;
  intercomDesktopActionId: number | null;
  intercomMobileActionId: number | null;
};

const DEFAULT_STATE: InitParamsState = {
  intercomAction: null,
  intercomApp: null,
  intercomDesktopActionId: null,
  intercomMobileActionId: null,
};

/**
 * The InputParamsSlice contains data provided to WGP at startup which needs to be
 * known at a later point in the WGP lifecycle
 */
export const InitParamsSlice = createSlice({
  name: 'init-params',
  initialState: DEFAULT_STATE,
  reducers: {
    setInitParams: (state, action: PayloadAction<InitParamsState>) => {
      state.intercomAction = action.payload.intercomAction;
      state.intercomApp = action.payload.intercomApp;
      state.intercomDesktopActionId = action.payload.intercomDesktopActionId;
      state.intercomMobileActionId = action.payload.intercomMobileActionId;
    },
    clearIntercomInitParams: (state) => {
      state.intercomAction = null;
      state.intercomApp = null;
      state.intercomDesktopActionId = null;
      state.intercomMobileActionId = null;
    },
  },
});

export const initParamsReducer = InitParamsSlice.reducer;
export const InitParamsActionCreators = InitParamsSlice.actions;

interface RootState {
  initParams: InitParamsState;
}

export const InitParamsSelectors = {
  getInitParams: (state: RootState) => state.initParams,
};
