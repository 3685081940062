import {
  GardenObjectProduct,
  GardenObjectProductData,
  PlantProduct,
  PlantProductData,
  ProductType,
  ProductVariant,
  ShoppingListProducts,
} from '../shopping-product';
import { Store, StoreDetails } from '../store-details';
import { APIGardenObjectData, APIPlantData, APIProduct, APIShoppingList, APIStoreDetails, APIVariant } from './api-types';

function parsePlantVariantData(plantData: { plantCode: string; varietyMatches: string[] }): {
  plantCode: string;
  varietyMatches: string[];
} {
  return {
    plantCode: plantData.plantCode,
    varietyMatches: plantData.varietyMatches,
  };
}

function parseGardenObjectVariantData(gardenObjectData: { code: string }): { code: string } {
  return {
    code: gardenObjectData.code,
  };
}

function parseVariant<T, U>(variant: APIVariant<T>, dataParser: (a: T) => U): ProductVariant<U> {
  return {
    variantId: variant.variantId,
    name: variant.name,
    properties: dataParser(variant.properties),
    price: variant.price,
    image: variant.imageSrc,
    quantity: variant.quantity,
    recommendedQuantityMultiplier: Math.max(variant.recommendedQuantityMultiplier, 1), // Make sure minimum multiplier is 1
    unit: variant.unit,
  };
}

function parseVariants<T extends object, U extends object>(variants: APIVariant<T>[], dataParser: (a: T) => U): [number[], Record<number, ProductVariant<U>>] {
  const parsed: Record<number, ProductVariant<U>> = {};
  const variantIds: number[] = [];
  variants.forEach((variant) => {
    const parsedVariant = parseVariant(variant, dataParser);
    variantIds.push(parsedVariant.variantId);
    parsed[parsedVariant.variantId] = parsedVariant;
  });
  return [variantIds, parsed];
}

function parseProduct(product: APIProduct): PlantProduct | GardenObjectProduct | null {
  const parsedProduct: Omit<PlantProduct, 'type'> | Omit<GardenObjectProduct, 'type'> = {
    productId: product.productId,
    title: product.title,
    tags: product.tags,
    images: product.images,
    variantIds: [],
    variants: {},
    description: product.description,
  };

  // All products -should- have variants, but just in case
  // Without a variant we can't tell
  if (product.variants.length === 0) {
    return null;
  }

  if ('plantCode' in product.variants[0].properties) {
    const prod = parsedProduct as PlantProduct;
    const [ids, variants] = parseVariants<APIPlantData, PlantProductData>(product.variants as APIVariant<APIPlantData>[], parsePlantVariantData);
    prod.variantIds = ids;
    prod.variants = variants;
    prod.type = ProductType.Plant;
    return prod;
  }

  if ('code' in product.variants[0].properties) {
    const prod = parsedProduct as GardenObjectProduct;
    const [ids, variants] = parseVariants<APIGardenObjectData, GardenObjectProductData>(
      product.variants as APIVariant<APIGardenObjectData>[],
      parseGardenObjectVariantData
    );
    prod.variantIds = ids;
    prod.variants = variants;
    prod.type = ProductType.GardenObject;

    return prod;
  }

  // Unknown product type
  return null;
}

export function parseShoppingProducts(apiProducts: APIProduct[]): ShoppingListProducts {
  const products: ShoppingListProducts = {
    byProductId: {},
    byVariantId: {},
    variantsByCode: {},
    productsByCode: {},
    variantsByPlantCodeAndVariety: {},
  };

  for (let i = 0; i < apiProducts.length; i++) {
    const product = parseProduct(apiProducts[i]);

    if (product === null) {
      // eslint-disable-next-line no-continue
      continue;
    }

    products.byProductId[product.productId] = product;
    product.variantIds.forEach((variantId) => {
      const v = product.variants[variantId] as ProductVariant<PlantProductData> | ProductVariant<GardenObjectProductData>;
      products.byVariantId[v.variantId] = product.productId;
      if ('plantCode' in v.properties) {
        const { plantCode, varietyMatches } = v.properties;
        // Remove when varieties are properly set
        let _varietyMatches = varietyMatches;
        if (varietyMatches.length === 0) {
          _varietyMatches = ['-'];
        }

        // Add plant code products
        if (!products.productsByCode[plantCode]) {
          products.productsByCode[plantCode] = [];
        }

        if (!products.productsByCode[plantCode].includes(product.productId)) {
          products.productsByCode[plantCode].push(product.productId);
        }

        // Add plant code variants
        if (!products.variantsByCode[plantCode]) {
          products.variantsByCode[plantCode] = [];
        }
        products.variantsByCode[plantCode].push(v.variantId);
        if (!products.variantsByPlantCodeAndVariety[plantCode]) {
          products.variantsByPlantCodeAndVariety[plantCode] = {};
        }

        // Add varieties
        _varietyMatches.forEach((varietyMatch) => {
          if (!products.variantsByPlantCodeAndVariety[plantCode][varietyMatch]) {
            products.variantsByPlantCodeAndVariety[plantCode][varietyMatch] = v.variantId;
          }
        });
      } else if ('code' in v.properties) {
        const { code } = v.properties;
        if (!products.variantsByCode[code]) {
          products.variantsByCode[code] = [];
        }
        products.variantsByCode[code].push(v.variantId);

        if (!products.productsByCode[code]) {
          products.productsByCode[code] = [];
        }

        if (!products.productsByCode[code].includes(product.productId)) {
          products.productsByCode[code].push(product.productId);
        }
      } else {
        console.warn(`Unrecognised product ${variantId}`);
      }
    });
  }

  return products;
}

function parseStoreDetails(apiStoreDetails: APIStoreDetails): StoreDetails {
  return {
    storeName: apiStoreDetails.storeName,
    storePath: apiStoreDetails.storePath,
    currency: apiStoreDetails.currency,
  };
}

export function parseStore(apiShoppingList: APIShoppingList): Store {
  return {
    details: parseStoreDetails(apiShoppingList.productsMetaData),
    products: parseShoppingProducts(apiShoppingList.products),
  };
}
