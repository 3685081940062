import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { VerticalNavItem, VerticalNavSection } from '@gi/page-layout';
import { SupportPageState } from '@gi/app-help-types';
import { AppSupportSelectors, AppSupportActionCreators } from '@gi/app-help-slice';

interface iProps {
  page: SupportPageState;
  text: string;
  children?: ReactNode;
}

const PageNavigationLink = ({ page, text, children }: iProps): JSX.Element => {
  const activePage = useSelector(AppSupportSelectors.getActivePage);
  const dispatch = useDispatch();

  return (
    <VerticalNavSection
      topmost
      title={
        <VerticalNavItem topmost onClick={() => dispatch(AppSupportActionCreators.setPage(page))} active={activePage.page === page.page}>
          {text}
        </VerticalNavItem>
      }
    >
      {children}
    </VerticalNavSection>
  );
};

export default PageNavigationLink;
