import React from 'react';

import CheckableObjectiveIcon from '../icon/checkable-objective-icon';
import CheckboxObjectiveIcon from '../icon/checkbox-objective-icon';
import ObjectiveTutorialButton from './objective-tutorial-button';

import styles from './overview-styles.module.css';

interface iProps {
  title: string;
  description: string;
  icon?: string;
  completed?: boolean;
  minimised?: boolean;
  tutorialName?: string;
  onLaunchHelp?: () => void;
}

const ObjectiveOverviewCard = ({ title, description, icon, completed, minimised = false, tutorialName, onLaunchHelp }: iProps): JSX.Element => {
  return (
    <div className={`${styles.overviewCard} ${completed ? styles.completedOverviewCard : ''}`}>
      <div className={styles.overviewCardIcon}>
        {icon ? <CheckableObjectiveIcon size={40} src={icon} checked={completed} /> : <CheckboxObjectiveIcon size={40} checked={completed} />}
      </div>
      <div className={styles.overviewCardContent} hidden={minimised}>
        <p className={styles.overviewCardTitle}>{title}</p>
        <p className={styles.overviewCardDescription}>{description}</p>
        {tutorialName ? <ObjectiveTutorialButton tutorialName={tutorialName} onLaunchHelp={onLaunchHelp} /> : null}
      </div>
    </div>
  );
};

export default ObjectiveOverviewCard;
