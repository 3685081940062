import { GardenPlatformActionCreators, GardenPlatformSelectors } from '@gi/garden-platform-slice';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { whenYouTubeAPIReady } from '@gi/youtube-embed';

import './garden-planner-intro.scss';
import { AnalyticsActionCreators } from '@gi/analytics';
import { SessionSelectors } from '@gi/react-session';

const VIDEO_ID = 'EYWH8Z3e8cw';

const GardenPlannerIntro = () => {
  const user = useSelector(SessionSelectors.getUser);
  const showIntroVideo = useSelector(GardenPlatformSelectors.getShowIntroVideo);
  const dispatch = useDispatch();

  const playTimes = useRef<[number, number, number][]>([]);
  const [playerReady, setPlayerReady] = useState<boolean>(false);

  const duration = useRef<number>(-1);

  useEffect(() => {
    playTimes.current = [];
    if (!showIntroVideo) {
      return;
    }

    let player;

    function onPlayerStateChange(e) {
      if (duration.current === -1 && player.getDuration) {
        duration.current = player.getDuration();
      }

      if (e.data === 1) {
        // play
        playTimes.current.push([player.getCurrentTime(), -1, Date.now()]);
      } else if (e.data === 0 || e.data === 2) {
        // end/pause
        if (playTimes.current.length === 0) {
          console.warn('pause/end before play');
          return;
        }
        if (playTimes.current[playTimes.current.length - 1][2] === -1) {
          console.warn('multiple pause/end before play');
          return;
        }

        playTimes.current[playTimes.current.length - 1][1] = (Date.now() - playTimes.current[playTimes.current.length - 1][2]) / 1000;
        playTimes.current[playTimes.current.length - 1][2] = -1;
      }
    }

    function onPlayerReady() {
      setPlayerReady(true);
    }

    whenYouTubeAPIReady().then(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      player = new YT.Player('introduction-video', {
        videoId: VIDEO_ID,
        playerVars: {
          rel: 0,
        },
        events: {
          onStateChange: onPlayerStateChange,
          onReady: onPlayerReady,
        },
      });

      return () => {
        if (player) {
          player.destroy();
          player = null;
        }
      };
    });
  }, [showIntroVideo]);

  if (!showIntroVideo) {
    return null;
  }

  const onClose = () => {
    if (playTimes.current.length > 0) {
      if (playTimes.current[playTimes.current.length - 1][1] === -1) {
        playTimes.current[playTimes.current.length - 1][1] = (Date.now() - playTimes.current[playTimes.current.length - 1][2]) / 1000;
        playTimes.current[playTimes.current.length - 1][2] = -1;
      }
    }

    const playTimeResults: [number, number][] = playTimes.current
      .filter((playTime) => {
        return playTime[1] !== -1;
      })
      .map((playTime) => {
        return [playTime[0], playTime[1]];
      });

    if (user !== null) {
      dispatch(AnalyticsActionCreators.analyticsVideoEvent(VIDEO_ID, duration.current, playTimeResults));
    }

    dispatch(GardenPlatformActionCreators.setShowIntroVideo(false));
  };

  return (
    <div className='garden-planner-intro'>
      <div className='intro-video-content'>
        <div className='intro-video-close'>
          <button type='button' aria-label='Close' className='intro-close-button' onClick={onClose}>
            <i className='icon-cancel' />
          </button>
        </div>
        <div className='intro-video-title'>Garden Planner Quick-Start Tutorial</div>
        <div className='intro-video-container'>
          {!playerReady ? (
            <div className='intro-video-spinner'>
              <i className='icon-spinner animate-pulse spinner' />
            </div>
          ) : null}
          <div id='introduction-video' />
        </div>
        <div className='intro-video-controls'>
          <button type='button' className='button button-tutorial' onClick={onClose}>
            Close Video &amp; Start Planning
          </button>
        </div>
      </div>
    </div>
  );
};

export default GardenPlannerIntro;
