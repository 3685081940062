import React from 'react';

const FormatVarietyOptionLabel = (
  // eslint-disable-next-line react/prop-types
  { value, label, hasCustom, hasSupplier },
  { context, selectValue }
) => {
  // eslint-disable-line
  const style = {};

  if (selectValue[0] && selectValue[0].value === value && context === 'menu') {
    style.color = '#ffffff';
  }

  return (
    <div className='variety-select-option'>
      <div className='variety-select-label'>{label}</div>
      <div className='variety-select-icons'>
        {hasCustom ? <i className='icon-user-circle-o custom-icon' style={style} /> : null}
        {hasSupplier ? <i className='icon-book supplier-icon' style={style} /> : null}
      </div>
    </div>
  );
};

export default FormatVarietyOptionLabel;
