import React from 'react';

import { ConfirmLeaveContextResult } from './constants';

import ModalContent from '../modal-content';
import ModalHeader from '../header/modal-header';
import ModalCloseButton from '../header/modal-close-button';
import ModalHeaderTitle from '../header/modal-header-title';
import ModalHeaderContent from '../header/modal-header-content';
import ModalPaneContainer from '../modal-pane-container';
import ModalPane from '../modal-pane/modal-pane';
import ModalPaneSection from '../modal-pane/modal-pane-section';
import ModalFooter from '../modal-footer';
import ModalFooterButtons from '../modal-footer-buttons';
import ModalFooterButtonsSection from '../modal-footer-buttons-section';
import ModalWithoutContext from '../modal-without-context';

import './confirm-close-modal.scss';

interface iProps {
  closeModal: (result: ConfirmLeaveContextResult) => void;
  title: string;
  text: string;
  withoutSavingButtonText?: string;
  withSavingButtonText?: string;
  invalidMessage?: React.ReactNode;
}

const ConfirmCloseModal = ({
  closeModal,
  title,
  text,
  withoutSavingButtonText = 'Close Without Saving',
  withSavingButtonText = 'Save And Close',
  invalidMessage,
}: iProps): JSX.Element | null => {
  const invalidMessageBox = invalidMessage ? (
    <ModalPaneContainer>
      <ModalPane>
        <ModalPaneSection>
          <div className='invalid-message'>{invalidMessage}</div>
        </ModalPaneSection>
      </ModalPane>
    </ModalPaneContainer>
  ) : null;

  return (
    <ModalWithoutContext className='confirm-close-modal' closeRequest={() => closeModal(ConfirmLeaveContextResult.Cancel)}>
      <ModalContent>
        <ModalCloseButton onClick={() => closeModal(ConfirmLeaveContextResult.Cancel)} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>{title}</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            <ModalPaneSection>{text}</ModalPaneSection>
          </ModalPane>
        </ModalPaneContainer>
        {invalidMessageBox}
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={() => closeModal(ConfirmLeaveContextResult.CloseWithoutSaving)}>
                {withoutSavingButtonText}
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <button
                type='button'
                disabled={!!invalidMessage}
                className='button button-primary'
                onClick={() => closeModal(ConfirmLeaveContextResult.SaveAndClose)}
              >
                {withSavingButtonText}
              </button>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </ModalWithoutContext>
  );
};

export default ConfirmCloseModal;
