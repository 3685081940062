import { createFilters } from '@gi/filters';
import { PlantListAreaGroup } from '../../types/plant-list-types';
import sortFilter from './area-sort-filter';
import makePerennialsFilter from '../common/show-perennials-filter';
import hideInGroundAllFilter from './hide-in-ground-all-filter';
import { makePlantVarietySearchFilter } from '../plant-variety-search-filter';

export type PlantListAreaGroupFiltersType = {
  perennial: ReturnType<typeof makePerennialsFilter<PlantListAreaGroup>>;
  search: ReturnType<typeof makePlantVarietySearchFilter<PlantListAreaGroup>>;
  sort: typeof sortFilter;
  inGround: typeof hideInGroundAllFilter;
};

const INITIAL_AREA_FILTERS = createFilters<PlantListAreaGroup, PlantListAreaGroupFiltersType>({
  perennial: makePerennialsFilter<PlantListAreaGroup>(),
  search: makePlantVarietySearchFilter<PlantListAreaGroup>(
    (obj) => obj.plant.code,
    (obj) => obj.variety
  ),
  sort: sortFilter,
  inGround: hideInGroundAllFilter,
});

export default INITIAL_AREA_FILTERS;
