import {
  DisplayModeComponentContext,
  InspectableClassData,
  InspectableClassDataType,
  InspectableClassPropertyType,
  NodeComponent,
  NodeComponentEvent,
  hasEngine,
} from '@gi/core-renderer';
import { LayerDisplayModes } from '@gi/constants';

import CanvasLayers from '../canvas-layers';
import { getDisplayModeRulesFromLayerDisplayMode } from '../nodes/display-mode-utils';

/**
 * Canvas Layer Controller
 *  Controls functionality around the layer display mode system, including telling the canvas layers,
 *  and managing the selection system to prevent unselectable nodes form being slected.
 */
class CanvasLayersController extends NodeComponent {
  type = 'CanvasLayerController';

  #canvasLayers: CanvasLayers;
  #displayModeContext: DisplayModeComponentContext | null = null;

  #layerDisplayMode: LayerDisplayModes = LayerDisplayModes.ALL;
  get layerDisplayMode() {
    return this.#layerDisplayMode;
  }

  constructor(canvasLayers: CanvasLayers, layerDisplayMode: LayerDisplayModes = LayerDisplayModes.ALL) {
    super();

    this.#canvasLayers = canvasLayers;
    this.#layerDisplayMode = layerDisplayMode;

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #updateDisplayModeContext(layerDisplayMode: LayerDisplayModes) {
    if (this.#displayModeContext) {
      const { disabled, viewOnly } = getDisplayModeRulesFromLayerDisplayMode(layerDisplayMode);
      this.#displayModeContext.state.values.disabledFlags = disabled.mask;
      this.#displayModeContext.state.values.disabledFlagsComparisonMode = disabled.mode;
      this.#displayModeContext.state.values.viewOnlyFlags = viewOnly.mask;
      this.#displayModeContext.state.values.viewOnlyFlagsComparisonMode = viewOnly.mode;
    }
  }

  setLayerDisplayMode(layerDisplayMode: LayerDisplayModes) {
    if (this.layerDisplayMode === layerDisplayMode) {
      return;
    }
    this.#layerDisplayMode = layerDisplayMode;
    this.#updateDisplayModeContext(layerDisplayMode);
  }

  #onBind = () => {
    hasEngine(this);
    this.#displayModeContext = this.owner.getContext(DisplayModeComponentContext);
    this.#updateDisplayModeContext(this.layerDisplayMode);
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    this.#displayModeContext = null;
  };

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'layerDisplayMode',
      propertyType: InspectableClassPropertyType.String,
    },
  ];
}

export default CanvasLayersController;
