import SearchResult from './search-result';

class SearchResults<T> {
  readonly #resultsByCode: { [code: string]: SearchResult<T> } = {};
  #count: number = 0;

  constructor(results: SearchResult<T>[]) {
    results.forEach((result) => {
      // Store it as a by-code lookup
      this.#resultsByCode[result.code] = result;
    });
    // Calculate count from the final object, just in case duplicate keys reduce the total.
    this.#count = Object.keys(this.#resultsByCode).length;
  }

  getResult = (code: string): SearchResult<T> | undefined => {
    return this.#resultsByCode[code];
  };

  getResults = () => {
    return this.#resultsByCode;
  };

  get count() {
    return this.#count;
  }
}

export default SearchResults;
