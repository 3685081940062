import { UnitTypes, INCHES_IN_FOOT } from '@gi/constants';
import PropTypes from 'prop-types';

export type DistanceUnitsGroup = {
  minimumMagnification: number;
  unit: string;
  shortcode: string;
  decimals: string;
  spacing: number;
  unitMultiplier: number;
  divisions: number;
};

export type DistanceUnits = {
  type: string;
  name: string;
  getUnitString: (val: number) => string;
  getUnitStrings: (vals: number[]) => string[];
  unit: string;
  divisions: DistanceUnitsGroup[];
};

export function getDistanceDivisionAtMagnification(unit: DistanceUnits, magnification: number): DistanceUnitsGroup {
  if (magnification <= 0) {
    throw new Error('Magnification cannot be less than or equal to 0');
  }

  if (unit.divisions.length === 0) {
    throw new Error('Scale has no scales');
  }

  let index = 1;
  let division = unit.divisions[0];

  if (magnification < division.minimumMagnification) {
    throw new Error('Magnification is less than minimum magnification for this set of distance units');
  }

  while (index < unit.divisions.length && unit.divisions[index].minimumMagnification <= magnification) {
    if (unit.divisions[index - 1].minimumMagnification > unit.divisions[index].minimumMagnification) {
      throw new Error('Divisions are not incrementally ordered');
    }
    division = unit.divisions[index];
    index++;
  }

  return division;
}

export const metricDistanceUnits: DistanceUnits = {
  type: UnitTypes.METRIC,
  name: 'Metric',
  unit: 'Meters',
  getUnitString: (val: number): string => {
    if (val >= 100) {
      return `${(Math.round(val) / 100).toFixed(2)}m`;
    }

    return `${Math.round(val)}cm`;
  },
  getUnitStrings: (vals: number[]): string[] => {
    if (vals.reduce((acc, val) => acc || val >= 100, false)) {
      // Reduce to see if any values are 100cm or higher
      return vals.map((val) => `${(Math.round(val) / 100).toFixed(2)}m`);
    }

    return vals.map((val) => `${Math.round(val)}cm`);
  },
  divisions: [
    {
      minimumMagnification: 0,
      unit: 'meter',
      shortcode: 'm',
      decimals: '0',
      spacing: 5000,
      unitMultiplier: 50,
      divisions: 5,
    },
    {
      minimumMagnification: 0.03,
      unit: 'meter',
      shortcode: 'm',
      decimals: '0',
      spacing: 2000,
      unitMultiplier: 20,
      divisions: 4,
    },
    {
      minimumMagnification: 0.1,
      unit: 'meter',
      shortcode: 'm',
      decimals: '0',
      spacing: 1000,
      unitMultiplier: 10,
      divisions: 10,
    },
    {
      minimumMagnification: 0.25,
      unit: 'meter',
      shortcode: 'm',
      decimals: '0',
      spacing: 500,
      unitMultiplier: 5,
      divisions: 5,
    },
    {
      minimumMagnification: 0.5,
      unit: 'meter',
      shortcode: 'm',
      decimals: '0',
      spacing: 500,
      unitMultiplier: 5,
      divisions: 10,
    },
    {
      minimumMagnification: 0.99,
      unit: 'meter',
      shortcode: 'm',
      decimals: '0',
      spacing: 100,
      unitMultiplier: 1,
      divisions: 10,
    },
  ],
};

const getImperialUnitString = (val: number): string => {
  const inches = Math.round(val * 0.3937);
  const leftOverInches = inches % INCHES_IN_FOOT;
  const feet = Math.floor(inches / INCHES_IN_FOOT);

  if (feet === 0) {
    return `${inches}''`;
  }

  return `${feet}' ${leftOverInches}''`;
};

export const imperialDistanceUnits: DistanceUnits = {
  type: UnitTypes.IMPERIAL,
  name: 'Imperial',
  unit: 'Feet',
  getUnitString: getImperialUnitString,
  getUnitStrings: (vals) => vals.map(getImperialUnitString),
  divisions: [
    {
      minimumMagnification: 0,
      unit: 'foot',
      shortcode: "'",
      decimals: '0',
      spacing: 3048,
      unitMultiplier: 100,
      divisions: 10,
    },
    {
      minimumMagnification: 0.2,
      unit: 'foot',
      shortcode: "'",
      decimals: '0',
      spacing: 1524,
      unitMultiplier: 50,
      divisions: 10,
    },
    {
      minimumMagnification: 0.3,
      unit: 'foot',
      shortcode: "'",
      decimals: '0',
      spacing: 304.8,
      unitMultiplier: 10,
      divisions: 10,
    },
    {
      minimumMagnification: 1,
      unit: 'foot',
      shortcode: "'",
      decimals: '0',
      spacing: 30.48,
      unitMultiplier: 1,
      divisions: 2,
    },
    {
      minimumMagnification: 4,
      unit: 'foot',
      shortcode: "'",
      decimals: '0',
      spacing: 30.48,
      unitMultiplier: 1,
      divisions: 12,
    },
  ],
};

export function getDistanceUnitsFromUnitType(unitType: string): DistanceUnits {
  if (unitType === UnitTypes.METRIC) {
    return metricDistanceUnits;
  }

  if (unitType === UnitTypes.IMPERIAL) {
    return imperialDistanceUnits;
  }

  console.error('No distance unit found with code', unitType, 'using metric');
  return metricDistanceUnits;
}

export function getDistanceUnitsFromIsMetric(isMetric: boolean): DistanceUnits {
  if (isMetric) {
    return metricDistanceUnits;
  }

  return imperialDistanceUnits;
}

export const DistanceUnitsGroupShape = PropTypes.shape({
  minimumMagnification: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  shortcode: PropTypes.string.isRequired,
  decimals: PropTypes.string.isRequired,
  spacing: PropTypes.number.isRequired,
  unitMultiplier: PropTypes.number.isRequired,
  divisions: PropTypes.number.isRequired,
});

export const DistanceUnitsShape = PropTypes.shape({
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  getUnitString: PropTypes.func.isRequired,
  getUnitStrings: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  divisions: PropTypes.arrayOf(DistanceUnitsGroupShape),
});
