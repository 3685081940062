import React from 'react';

import './modal-pane.scss';

interface iProps {
  active?: boolean;
  visible?: boolean;
  children: React.ReactNode;
  emphasised?: boolean;
  style?: React.CSSProperties;
  className?: string;
}

const ModalPane = ({ active = true, visible = true, children, emphasised = false, style = {}, className = '' }: iProps) => {
  if (!visible) {
    return null;
  }

  let cover: null | React.ReactNode = null;

  if (!active) {
    cover = <div className='modal-pane-inactive-cover' />;
  }

  return (
    <section style={style} className={`${className} modal-pane ${active ? 'modal-pane-active' : ''} ${emphasised ? 'emphasised' : ''}`}>
      {children}
      {cover}
    </section>
  );
};

export default ModalPane;
