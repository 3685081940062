import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { SupportPageState } from '@gi/app-help-types';

type SupportState = {
  activePage: SupportPageState;
};

const initialState: SupportState = {
  activePage: { page: 'SUPPORT', subPage: 'MAIN' },
};

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setActivePage: (state, action: PayloadAction<SupportPageState>) => {
      state.activePage = action.payload;
      return state;
    },
  },
});

interface RootState {
  support: SupportState;
}

export const appSupportReducer = supportSlice.reducer;
export const AppSupportActions = { ...supportSlice.actions };
export const AppSupportSelectors = {
  getActivePage: (state: RootState) => state.support.activePage,
};
