import { lexographicSort } from '@gi/utils';

/**
 * Creates a key to be used as a unique ID for a given piece of guru content (video/plant/pest/article)
 *
 * The key is a combination of all guru params and the main slug used to identify it.
 */
export function createContentUUID(contentId: number, queryParams: Record<string, string>): string {
  const keys = Object.keys(queryParams).map((key) => key.toLowerCase());
  keys.sort(lexographicSort);
  const paramsString = keys.map((key) => `${key}=${queryParams[key]}`).join('&');
  return `${contentId}_${paramsString}`;
  // return digestMessage(keyString);
}

export function createContentSlugParams(contentId: number, queryParams: Record<string, string>): string {
  return `${contentId}?${new URLSearchParams(queryParams).toString()}`;
}
