/**
 * @typedef {number} PlantFamily
 */
/**
 * Enum for the different plant family types
 *
 * @readonly
 * @enum {PlantFamily}
 */
enum PlantFamilyType {
  MISCELLANEOUS = 0,
  BRASSICAS = 1,
  LEGUMES = 2,
  SOLANACEAE = 3,
  ALLIUM = 4,
  UMBELLIFERAE = 5,
  CUCURBITS = 6,
  CHENOPODIACEAE = 7,
}

export default PlantFamilyType;
