import React, { ReactNode } from 'react';
import { HelpButton } from '@gi/base-components';

import './form-field.scss';
import { convertSizeToCSS, HorizontalAlign, LayoutPreset, SizeType, VerticalAlign } from './types';
import FormLayout from './form-layout';
import FormSubtext, { FormSubtextType } from './form-subtext';

const LayoutTypes = ['column', 'row', 'row-reverse', 'blank'] as const;
type LayoutType = (typeof LayoutTypes)[number];

const LabelSizeTypes = ['default'] as const;
type LabelSizeType = (typeof LabelSizeTypes)[number];

export interface FormFieldOptions {
  layout?: LayoutType;
  labelAlignX?: HorizontalAlign;
  labelAlignY?: VerticalAlign;
  labelSize?: SizeType | LabelSizeType | number;
  inputSize?: SizeType | number;
}

export interface FormFieldOptionsPreset extends Partial<LayoutPreset<FormFieldOptions>> {}

interface iGeneralProps {
  label?: ReactNode;
  legend?: ReactNode;
  required?: boolean;
  children?: ReactNode;
  helpText?: ReactNode;
  warningText?: ReactNode;
  errorText?: ReactNode;
  leaveSpaceForError?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  noLabelPadding?: boolean;
  className?: string;
  layoutPreset?: FormFieldOptionsPreset;
  mobileLayout?: FormFieldOptions;
  desktopLayout?: FormFieldOptions;
  onHelpClick?: () => void;
}
interface iFakeLabelProps {
  fakeLabel: true;
}
interface iForProps {
  htmlFor: string;
}
type FormFieldProps = iGeneralProps & (iForProps | iFakeLabelProps);

/**
 * Creates a list of classes that will style the element.
 * @param preset The preset to start from
 * @param mobileOverrides Any settings to override the preset on mobile
 * @param desktopOverrides Any settings to override the preset on desktop
 * @returns A list of classes
 */
function getLayoutClasses(
  preset: iGeneralProps['layoutPreset'],
  mobileOverrides: iGeneralProps['mobileLayout'],
  desktopOverrides: iGeneralProps['desktopLayout']
) {
  const desktopLayout = desktopOverrides?.layout ?? preset?.desktop?.layout ?? 'row';
  const desktopLabelAlignX = desktopOverrides?.labelAlignX ?? preset?.desktop?.labelAlignX ?? 'default';
  const desktopLabelAlignY = desktopOverrides?.labelAlignY ?? preset?.desktop?.labelAlignY ?? 'top';
  const mobileLayout = mobileOverrides?.layout ?? preset?.mobile?.layout ?? 'column';
  const mobileLabelAlignX = mobileOverrides?.labelAlignX ?? preset?.mobile?.labelAlignX ?? 'default';
  const mobileLabelAlignY = mobileOverrides?.labelAlignY ?? preset?.mobile?.labelAlignY ?? 'top';
  return [
    `desktop-layout-${desktopLayout}`,
    `desktop-label-justify-${desktopLabelAlignX}`,
    `desktop-label-align-${desktopLabelAlignY}`,
    `mobile-layout-${mobileLayout}`,
    `mobile-label-justify-${mobileLabelAlignX}`,
    `mobile-label-align-${mobileLabelAlignY}`,
  ];
}

/**
 * Creates a list of styles that will style the element.
 * Should mainly be used for adding CSS variables.
 * @param preset The preset to start from
 * @param mobileOverrides Any settings to override the preset on mobile
 * @param desktopOverrides Any settings to override the preset on desktop
 * @returns A list of styles
 */
function getLayoutStyles(
  preset: iGeneralProps['layoutPreset'],
  mobileOverrides: iGeneralProps['mobileLayout'],
  desktopOverrides: iGeneralProps['desktopLayout']
) {
  const desktopLabelSize = desktopOverrides?.labelSize ?? preset?.desktop?.labelSize ?? 'default';
  const mobileLabelSize = mobileOverrides?.labelSize ?? preset?.mobile?.labelSize ?? 'auto';
  const desktopInputSize = desktopOverrides?.inputSize ?? preset?.desktop?.inputSize ?? 'auto';
  const mobileInputSize = mobileOverrides?.inputSize ?? preset?.mobile?.inputSize ?? 'auto';
  return {
    '--desktop-label-size': desktopLabelSize === 'default' ? '180px' : convertSizeToCSS(desktopLabelSize),
    '--mobile-label-size': mobileLabelSize === 'default' ? '180px' : convertSizeToCSS(mobileLabelSize),
    '--desktop-input-size': convertSizeToCSS(desktopInputSize),
    '--mobile-input-size': convertSizeToCSS(mobileInputSize),
  } as Record<string, string>;
}

const FormField = ({
  label,
  legend,
  required = false,
  children,
  helpText,
  warningText,
  errorText,
  leaveSpaceForError = false,
  invalid = false,
  disabled = false,
  noLabelPadding = false,
  className,
  layoutPreset,
  mobileLayout,
  desktopLayout,
  onHelpClick,
  ...remaining
}: FormFieldProps): JSX.Element => {
  const fakeLabel = !('htmlFor' in remaining);
  const classNames = ['rforms-field', ...getLayoutClasses(layoutPreset, mobileLayout, desktopLayout)];
  if (required) {
    classNames.push('rforms-field-required');
  }
  if (invalid) {
    classNames.push('rforms-field-invalid');
  }
  if (disabled) {
    classNames.push('rforms-field-disabled');
  }
  if (noLabelPadding) {
    classNames.push('rforms-field-no-label-padding');
  }
  if (className) {
    classNames.push(className);
  }

  const styles = getLayoutStyles(layoutPreset, mobileLayout, desktopLayout);

  const WrapperComponent = legend ? 'fieldset' : 'div';
  const LabelComponent = fakeLabel ? 'div' : 'label';
  const htmlFor = 'htmlFor' in remaining ? remaining.htmlFor : undefined;

  const helpButton = onHelpClick ? (
    <span className='rforms-field-helpbutton'>
      <HelpButton onClick={onHelpClick} />
    </span>
  ) : null;

  return (
    <WrapperComponent className={classNames.join(' ')} style={styles}>
      {legend && (
        <legend>
          {legend} {helpButton}
        </legend>
      )}
      {label && (
        <LabelComponent className='rforms-field-label-text' htmlFor={htmlFor}>
          {label}
        </LabelComponent>
      )}
      <FormLayout className='rforms-field-inputs'>{children}</FormLayout>
      {!legend && helpButton}
      {(errorText || leaveSpaceForError) && (
        <FormSubtext type={FormSubtextType.Error} className='rforms-field-errortext'>
          {errorText ?? '\u00A0'}
        </FormSubtext>
      )}
      {warningText && (
        <FormSubtext type={FormSubtextType.Warning} className='rforms-field-warningtext'>
          {warningText}
        </FormSubtext>
      )}
      {helpText && (
        <FormSubtext type={FormSubtextType.Help} className='rforms-field-helptext'>
          {helpText}
        </FormSubtext>
      )}
    </WrapperComponent>
  );
};

export default FormField;
