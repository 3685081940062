export const AnalyticsEventIdentifiers = {
  APP_START: 'open garden planner',
  CHAT_BUTTON_CLICKED: 'live chat window opened',
  OPEN_PLAN: 'open garden plan',
  PAGE_VIEWED: 'page viewed',
  EXPORTS: 'planner exports',
  PLANT_SEARCH_NO_RESULTS: 'plant search no results',
  GARDEN_OBJECT_SEARCH_NO_RESULTS: 'object search no results',
  DEVICE_DATA: 'update user device data',
  DISPLAY_MODE_CHANGE: 'user display mode changed',
  PLANNER_ACTIVITY: 'planner activity',
  OPEN_GURU: 'open garden guru',
  GURU_USAGE: 'guru activity',
  ONBOARDING_ACTIVITY: 'onboarding activity',
  LOGOUT: 'user logged out',
  SHOPPING: 'shopping',
} as const;

export const ExportEventTypes = {
  EXPORTED_PLAN: 'exported plan',
  EXPORTED_PLANT_LIST: 'exported plant list',
  EXPORTED_PARTS_LIST: 'exported parts list',
  EXPORTED_PLAN_IMAGE: 'generated image',
} as const;

export const PlannerActivityTypes = {
  SET_IN_GROUND_DATES: 'set in ground dates',
  SFG_MODE_CHANGED: 'sfg mode changed', //
  VIEW_COMPANIONS: 'view companions',
  FAVOURITE_PLANT: 'favourite plant',
  OPEN_EDIT_MODAL: 'open edit modal', //
  VARIETY_ADDED: 'added variety',
  VARIETY_CUSTOMIZED: 'customized variety',
  SET_VARIETY: 'set variety',
  VIEW_PLANT_INFO_MODAL: 'view plant info modal', //
  CHANGE_CROP_ROTATION_MODE: 'change crop rotation mode', //
  CHANGE_LAYER_MODE: 'change layer mode', //
  CHANGE_MONTH_VIEW: 'change month view', //
  SELECTION_TOOL: 'selection tool', //
  FOUND_PLANT: 'found plant',
  VIEW_HELP_MODAL: 'view help modal',
  ATTEMPTED_DRAG_TO_DRAW: 'attempted drag to draw',
  VIEW_LINK: 'view link',
  CHANGE_ITEM_LOCKED: 'change item locked',
  CHANGE_ITEM_Z_INDEX: 'change item z index',
} as const;

export const OnboardingActivityTypes = {
  MOBILE_INTRODUCTION_VIEWED: 'mobile introduction viewed',
  TUTORIAL_ENDED: 'tutorial ended',
  TUTORIAL_VIDEO_WATCHED: 'tutorial video watched',
} as const;
