import Plant from '@gi/plant';

import { SearchDisplayMode } from './search-result';
import { SearchService } from './search-service';

export class PlantSearchService extends SearchService<Plant> {
  constructor(itemList: Plant[], options?: Fuzzysort.Options) {
    super(
      itemList,
      ['name', 'otherName', 'scientificName', 'otherScientificNames', 'searchNames'],
      {
        name: {
          weight: 1,
          processBrackets: true,
          processBracketsWeightFactor: 0.25,
          includeForSingleChar: true,
        },
        otherName: {
          weight: 0.5,
          delimiter: ',',
          displayMode: SearchDisplayMode.Secondary,
        },
        scientificName: {
          weight: 0.8,
          displayMode: SearchDisplayMode.Secondary,
        },
        otherScientificNames: {
          weight: 0.6,
          delimiter: ',',
          displayMode: SearchDisplayMode.Secondary,
        },
        searchNames: {
          weight: 0.2,
          delimiter: ',',
          displayMode: SearchDisplayMode.Hidden,
        },
      },
      options
    );
  }
}
