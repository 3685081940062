import GardenObject, { GardenObjectUtils } from '@gi/garden-object';
import { PlanGardenObject } from '@gi/plan';

class PartListTableRowData {
  gardenObject: GardenObject;
  quantity: number;

  constructor(gardenObject: GardenObject) {
    this.gardenObject = gardenObject;
    this.quantity = 0;
  }

  addQuantity(planGardenObject: PlanGardenObject) {
    if (this.gardenObject.useLengthQuantity) {
      this.quantity += GardenObjectUtils.calculateLength(this.gardenObject, planGardenObject.start, planGardenObject.mid, planGardenObject.end);
      return;
    }

    if (this.gardenObject.useAreaQuantity) {
      this.quantity += GardenObjectUtils.calculateArea(this.gardenObject, planGardenObject.start, planGardenObject.end);
      return;
    }

    this.quantity += 1;
  }
}

export default PartListTableRowData;
