import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { PlanUtils } from '@gi/plan';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { PlannerTabTypes } from '@gi/constants';
import { ResourceContext } from '@gi/resource-provider';
import { MobileTopbarContainer, MobileTopbarContentWithButtons, MobileTopbarTitle, MobileTopbarSubtitle } from '@gi/mobile-garden-platform-nav';

import MobilePlanMenuDrawer from './mobile-plan-menu-drawer';
import MobilePlanMenuTabs from './mobile-plan-menu-tabs';
import SaveButton from './save-button';

import styles from './mobile-plan-menu.module.css';

const TabNameMapping = {
  [PlannerTabTypes.PLANNER]: 'Garden Planner',
  [PlannerTabTypes.PLANT_LIST]: 'Plant List',
  [PlannerTabTypes.PARTS_LIST]: 'Parts List',
  [PlannerTabTypes.NOTES]: 'Notes',
};

const MobilePlanMenu = (): JSX.Element => {
  const { userNorthernHemisphere } = useContext(ResourceContext);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  const [showDrawer, setShowDrawer] = useState(false);

  if (!activePlan) {
    return (
      <>
        <MobileTopbarContainer className={styles.topbar}>
          <MobileTopbarContentWithButtons>
            <div className={styles.container}>
              <button className={styles.dropdownButton} type='button' onClick={() => setShowDrawer(true)}>
                <div className={styles.dropdownContent}>
                  <MobileTopbarTitle>{TabNameMapping[PlannerTabTypes.PLANNER]}</MobileTopbarTitle>
                </div>
                <div className={styles.icon}>
                  <i className='icon-down-dir' />
                </div>
              </button>
            </div>
          </MobileTopbarContentWithButtons>
        </MobileTopbarContainer>
        <MobilePlanMenuDrawer open={showDrawer} onClose={() => setShowDrawer(false)} />
      </>
    );
  }

  return (
    <>
      <MobileTopbarContainer className={styles.topbar}>
        <MobileTopbarContentWithButtons>
          <div className={styles.container}>
            <button className={styles.dropdownButton} type='button' onClick={() => setShowDrawer(true)}>
              <div className={styles.dropdownContent}>
                <MobileTopbarTitle className={styles.planDetails}>
                  <span className={styles.planName}>{activePlan.name}</span>
                </MobileTopbarTitle>
                <MobileTopbarSubtitle>
                  <span className={styles.planYear}>{PlanUtils.getYearDisplayString(activePlan.year, userNorthernHemisphere)}</span>
                </MobileTopbarSubtitle>
              </div>
              <div className={styles.icon}>
                <i className='icon-down-dir' />
              </div>
            </button>
            <div className={styles.tabsInline}>
              <MobilePlanMenuTabs />
            </div>
            <div className={styles.menuSpacer} />
            <div className={styles.actions}>
              <SaveButton />
            </div>
          </div>
        </MobileTopbarContentWithButtons>
        <div className={styles.tabsBlock}>
          <MobilePlanMenuTabs />
        </div>
      </MobileTopbarContainer>
      <MobilePlanMenuDrawer open={showDrawer} onClose={() => setShowDrawer(false)} />
    </>
  );
};

export default MobilePlanMenu;
