import { Bounds, Node, ShapeComponent, TransformComponent, NodeEvent, TooltipComponentContext, InspectableClassData } from '@gi/core-renderer';
import { StateObserver, StateProperties } from '@gi/state';
import GenericHandleSetNode, { GenericHandleSetNodeEvent } from './generic-handle-set-node';

// Unique ID to give to the tooltip system for diables/freezes
const TOOLTIP_MANIPULATE_FLAG_ID = 'MULTI_HANDLE_MANIPULATE';
const TOOLTIP_HOVER_FLAG_ID = 'MULTI_HANDLE_HOVER';

/**
 * MultiHandleSetNode
 *  A handle set to encompass a set of nodes.
 *  This is the most basic set of handles, only allowing rotation.
 */
class MultiHandleSetNode extends GenericHandleSetNode {
  type = 'MultiHandleSetNode';

  #updater: StateObserver<any> | null = null;

  constructor(nodes: Node[]) {
    super({ allowManipulation: false, allowRotation: true });
    this.targets = nodes;

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onUnbind);
    this.eventBus.on(GenericHandleSetNodeEvent.Start, this.#onStart);
    this.eventBus.on(GenericHandleSetNodeEvent.End, this.#onEnd);
  }

  #onBind = () => {
    this.createWatcher([...this.targets]);
  };

  #onUnbind = () => {
    if (this.#updater) {
      this.#updater.destroy();
    }
  };

  #onStart = () => {
    const tooltipContext = this.tryGetContext(TooltipComponentContext);
    if (tooltipContext) {
      tooltipContext.disableTooltips(TOOLTIP_MANIPULATE_FLAG_ID);
    }
  };

  #onEnd = () => {
    const tooltipContext = this.tryGetContext(TooltipComponentContext);
    if (tooltipContext) {
      tooltipContext.enableTooltips(TOOLTIP_MANIPULATE_FLAG_ID);
    }
  };

  #baseOnHover = this.onHover;
  onHover = () => {
    const tooltipContext = this.tryGetContext(TooltipComponentContext);
    tooltipContext?.disableTooltips(TOOLTIP_HOVER_FLAG_ID);
    this.#baseOnHover();
  };

  #baseOnUnhover = this.onUnhover;
  onUnhover = () => {
    const tooltipContext = this.tryGetContext(TooltipComponentContext);
    tooltipContext?.enableTooltips(TOOLTIP_HOVER_FLAG_ID);
    this.#baseOnUnhover();
  };

  /**
   * Sets up the watcher needed to maintain the outline around all the nodes.
   * All nodes should have a shape and transform component, otherwise the outline cannot be calculated.
   * @param nodes The nodes to watch for changes
   */
  createWatcher(nodes: Node[]) {
    if (this.#updater) {
      this.#updater.destroy();
    }

    const properties: StateProperties<any>[] = [];

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      const transform = node.components.get(TransformComponent);
      const shape = node.components.get(ShapeComponent);

      if (!shape) {
        throw new Error('Handle set target node has no shape');
      }
      if (!transform) {
        throw new Error('Handle set target node has no transform');
      }

      properties.push(new StateProperties(shape.state, { properties: ['boundingBox'] }), new StateProperties(transform.state, { properties: ['position'] }));
    }

    this.#updater = new StateObserver(properties, false, () => {
      this.updateHandlesFromNodes(nodes);
    });
  }

  /**
   * Updates the handle position and outline based on the bounding boxes of the given nodes.
   * @param nodes The nodes to to calculate from
   */
  updateHandlesFromNodes(nodes: Node[]) {
    if (this.draggingHandle !== null) {
      return;
    }

    const newAABB: Bounds = {
      left: Infinity,
      right: -Infinity,
      top: Infinity,
      bottom: -Infinity,
    };

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      const transform = node.components.get(TransformComponent);
      const shape = node.components.get(ShapeComponent);

      if (!shape) {
        throw new Error('Handle set target node has no shape');
      }
      if (!transform) {
        throw new Error('Handle set target node has no transform');
      }

      newAABB.left = Math.min(newAABB.left, shape.state.values.boundingBox.left + transform.state.values.position.x);
      newAABB.right = Math.max(newAABB.right, shape.state.values.boundingBox.right + transform.state.values.position.x);
      newAABB.top = Math.min(newAABB.top, shape.state.values.boundingBox.top + transform.state.values.position.y);
      newAABB.bottom = Math.max(newAABB.bottom, shape.state.values.boundingBox.bottom + transform.state.values.position.y);
    }

    // Anti-disaster protection
    newAABB.left = newAABB.left === Infinity ? 0 : newAABB.left;
    newAABB.right = newAABB.right === -Infinity ? 0 : newAABB.right;
    newAABB.top = newAABB.top === Infinity ? 0 : newAABB.top;
    newAABB.bottom = newAABB.bottom === -Infinity ? 0 : newAABB.bottom;

    this.shape.state.values.boundingBox = newAABB;
    this.shape.state.values.points = [
      { x: newAABB.left, y: newAABB.top },
      { x: newAABB.right, y: newAABB.top },
      { x: newAABB.right, y: newAABB.bottom },
      { x: newAABB.left, y: newAABB.bottom },
    ];

    this.setFromCorners(
      { x: newAABB.left, y: newAABB.top },
      { x: newAABB.right, y: newAABB.top },
      { x: newAABB.right, y: newAABB.bottom },
      { x: newAABB.left, y: newAABB.bottom }
    );
  }

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default MultiHandleSetNode;
