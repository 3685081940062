import PropTypes from 'prop-types';

export type UserPlanSettings = {
  labelTextSize: number;
  varietyForDefaultLabel: boolean;
  showLabelOnNewPlants: boolean;
  showLabelOnNewSFGPlants: boolean;
  showPlantRoots: boolean;
  showGrid: boolean;
  autosave: boolean;
};

/**
 * @deprecated
 * Avoid using PropTypes
 */
export const UserPlanSettingsShape = PropTypes.shape({
  labelTextSize: PropTypes.number.isRequired,
  varietyForDefaultLabel: PropTypes.bool.isRequired,
  showLabelOnNewPlants: PropTypes.bool.isRequired,
  showLabelOnNewSFGPlants: PropTypes.bool.isRequired,
  showPlantRoots: PropTypes.bool.isRequired,
  showGrid: PropTypes.bool.isRequired,
  autosave: PropTypes.bool.isRequired,
});
