import React, { useContext, useMemo } from 'react';

import { AccordionContext } from './accordion-provider';

import styles from './accordion-styles.module.css';

interface iProps extends Omit<React.HTMLProps<HTMLDivElement>, 'children'> {
  accordionId: string;
}

const AccordionDropdownIcon = ({ accordionId, className, ...remainingProps }: iProps): JSX.Element => {
  const { isExpanded, unstyled } = useContext(AccordionContext);

  const expanded = useMemo(() => {
    return isExpanded(accordionId);
  }, [accordionId, isExpanded]);

  const classNameString = useMemo(() => {
    const classNames: string[] = [styles.accordionDropdownIconBase];
    if (!unstyled) {
      classNames.push(styles.accordionDropdownIcon);
    }
    if (className) {
      classNames.push(className);
    }
    return classNames.length > 0 ? classNames.join(' ') : undefined;
  }, [className, unstyled, expanded]);

  return (
    <div className={classNameString} data-expanded={expanded} {...remainingProps}>
      <i className='icon-down-open-1' />
    </div>
  );
};

export default AccordionDropdownIcon;
