import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SessionActionCreators, SessionSelectors } from '@gi/react-session';
import { RequestSelectors } from '@gi/react-requests';

type FavouritePlantValues = [isFavourite: boolean, setFavourite: (favourite: boolean) => void, saving: boolean, disabled: boolean];

/**
 * A React Hook for managing whether a given plant is favourited and whether a 'favourite' button
 * should be disabled (i.e. when a user is saving)
 */
export function useFavouritePlant(plantCode: string): FavouritePlantValues {
  const user = useSelector(SessionSelectors.getUser);
  const userSavingOrLoading = useSelector(RequestSelectors.userSaveOrLoad(user ? user.ID : -1));

  const dispatch = useDispatch();

  const [saving, setSaving] = useState<boolean>(false);

  const isFavourite = useMemo(() => {
    return user ? user.favouritePlants.has(plantCode) : false;
  }, [user, plantCode]);

  const setFavourite = useCallback(
    (_favourite: boolean) => {
      if (!user) {
        return;
      }

      if (isFavourite === _favourite) {
        return;
      }

      const newFavourites = new Set(Array.from(user.favouritePlants));
      if (isFavourite) {
        newFavourites.delete(plantCode);
      } else {
        newFavourites.add(plantCode);
      }

      setSaving(true);
      dispatch(SessionActionCreators.saveUserFavourites(user, newFavourites));
    },
    [user, isFavourite]
  );

  useEffect(() => {
    if (saving && !userSavingOrLoading) {
      setSaving(false);
    }
  }, [saving, userSavingOrLoading]);

  return [isFavourite, setFavourite, saving, userSavingOrLoading];
}
