import React, { ReactNode, useContext } from 'react';

import { Direction } from '@gi/constants';

import { SidebarContext } from './sidebar-context';

import styles from './sidebar-styles.module.css';

interface iProps {
  className?: string;
  children?: ReactNode;
}

const SidebarButtons = ({ className, children }: iProps): JSX.Element => {
  const { orientation, minimised } = useContext(SidebarContext);

  const classNames: string[] = [styles.buttons];
  classNames.push(orientation === Direction.LEFT ? styles.left : styles.right);
  if (!minimised) {
    classNames.push(styles.expanded);
  }
  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default SidebarButtons;
