import React from 'react';
import PropTypes from 'prop-types';

import './form.scss';

/**
 * @param {{
 *  className?: string,
 *  children?: import('react').ReactNode
 * }}
 */
const FormRowGroup = ({ className = '', children = null }) => {
  return <div className={`form-row-group ${className}`}>{children}</div>;
};

FormRowGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default FormRowGroup;
