import React, { useCallback, useContext, useMemo, useState } from 'react';

import { PlantTags } from '@gi/constants';
import { ResourceContext } from '@gi/resource-provider';
import { GuruPreviewPlant } from '@gi/app-guru-types';

import { GuruPlantFilters } from '../../context/types';
import ContentGridResultText from './content-grid-result-text';

export const useGuruPlantFilters = (id: string, plants: GuruPreviewPlant[]) => {
  const { plantSearchService } = useContext(ResourceContext);

  // Stores the filters. Could be stored in the store against the id in the future to make filters persistent.
  const [filters, setFilters] = useState<GuruPlantFilters>({
    tags: [],
    type: null,
    searchTerm: '',
  });

  // Sets the set of tags to filter by
  const setTags = useCallback((tags: PlantTags[]) => {
    setFilters((currentFilters) => ({ ...currentFilters, tags }));
  }, []);

  // Sets the type of plant to filter by
  const setType = useCallback((type: PlantTags | null) => {
    setFilters((currentFilters) => ({ ...currentFilters, type }));
  }, []);

  // Sets the search term to filter by
  const setSearchTerm = useCallback((searchTerm: string) => {
    setFilters((currentFilters) => ({ ...currentFilters, searchTerm }));
  }, []);

  // Adds a tag to filter by
  const addTag = useCallback((...tags: PlantTags[]) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      tags: [...currentFilters.tags, ...tags],
    }));
  }, []);

  // Removes a tag to filter by
  const removeTag = useCallback((...tags: PlantTags[]) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      tags: [...currentFilters.tags].filter((tag) => !tags.includes(tag)),
    }));
  }, []);

  // Toggles a tag to filter by (adds if not present, removes otherwise)
  const toggleTag = useCallback((tag: PlantTags) => {
    setFilters((currentFilters) => {
      const hasTag = currentFilters.tags.includes(tag);
      const newTags = hasTag ? [...currentFilters.tags].filter((t) => t !== tag) : [...currentFilters.tags, tag];
      return {
        ...currentFilters,
        tags: newTags,
      };
    });
  }, []);

  // Clears all filters
  const reset = useCallback(() => {
    setFilters({ tags: [], type: null, searchTerm: '' });
  }, []);

  // The search results from the text search
  const searchResults = useMemo(() => {
    return plantSearchService.search(filters.searchTerm);
  }, [filters.searchTerm, plantSearchService]);

  // A function to return search result text for the given plant
  const getSearchResultMatchText = useCallback(
    (plantCode: string) => {
      if (filters.searchTerm.length === 0) {
        return undefined;
      }

      const result = searchResults.getResult(plantCode);
      if (result === undefined) {
        return undefined;
      }

      return <ContentGridResultText result={result} primaryText={result?.item.name} />;
    },
    [searchResults, filters.searchTerm]
  );

  // All remaining plants that survived the filters
  const filteredPlants = useMemo(() => {
    if (filters.tags.length === 0 && filters.type === null && filters.searchTerm.length === 0) {
      return plants;
    }

    const finalTags = filters.type ? [...filters.tags, filters.type] : filters.tags;

    const filteredByTag = plants.filter(({ content }) => {
      if (content.plant === null) {
        return false;
      }
      return finalTags.every((tag) => content.plant.tags[tag]);
    });

    if (filters.searchTerm.length > 0) {
      return filteredByTag
        .filter((plant) => {
          return searchResults.getResult(plant.content.plantCode) !== undefined;
        })
        .sort((plantA, plantB) => {
          const scoreA = searchResults.getResult(plantA.content.plantCode)?.score ?? -Infinity;
          const scoreB = searchResults.getResult(plantB.content.plantCode)?.score ?? -Infinity;
          if (scoreA === scoreB) {
            return 0;
          }
          return scoreA > scoreB ? -1 : 1;
        });
    }

    return filteredByTag;
  }, [filters, plants, searchResults]);

  return useMemo(
    () => ({
      filteredPlants,
      filters,
      setTags,
      setType,
      setSearchTerm,
      searchResults,
      getSearchResultMatchText,
      addTag,
      removeTag,
      toggleTag,
      reset,
    }),
    [filteredPlants, filters, setTags, setType, setSearchTerm, searchResults, getSearchResultMatchText, addTag, removeTag, toggleTag, reset]
  );
};
