import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import './pdf-viewer.scss';

interface iProps {
  onClose: () => void;
  pdfData: Uint8Array;
}

const PDFViewer = ({ onClose, pdfData }: iProps): JSX.Element => {
  const blob = useRef(new Blob([pdfData], { type: 'application/pdf' }));
  const url = useRef(window.URL.createObjectURL(blob.current));

  useEffect(() => {
    return () => {
      if (url.current) {
        window.URL.revokeObjectURL(url.current);
      }
    };
  }, []);

  return createPortal(
    <div className='printer-iframe-background'>
      <div className='printer-iframe-container'>
        <div className='close-row'>
          <button aria-label='Close' type='button' className='button close-button' onClick={onClose}>
            <i className='icon-cancel' />
          </button>
        </div>
        <div className='printer-iframe-container-inner'>
          <iframe className='printer-iframe' title='Plan PDF' src={url.current} />
        </div>
      </div>
    </div>,
    document.getElementById('modal-root')!
  );
};

export default PDFViewer;
