import React, { useContext, useMemo } from 'react';

import { FadedScrollContainer } from '@gi/mobile-components';

import PlantListByArea from './plant-list-by-area';
import GlobalPlantListContext from '../global-provider/global-plant-list-context';
import { DISPLAY_TYPE } from '../constants';
import PlantListByVariety from './plant-list-by-variety';
import PlantListByPlant from './plant-list-by-plant';
import LocalPlantListContext from '../local-provider/local-plant-list-context';
import { TableViewMode } from '../types/plant-list-types';
import PlantListTable from '../table/plant-list-table';
import MobilePlantListFilters from './filters/mobile-plant-list-filters';

import './mobile-plant-list.scss';

const MobilePlantList = (): JSX.Element => {
  const { displayType, options } = useContext(GlobalPlantListContext);
  const { plantListData, setPlantTableElement } = useContext(LocalPlantListContext);

  const empty = useMemo(() => {
    return <div className='mobile-plant-list-empty'>Add plants to your plan to have them show up here.</div>;
  }, []);

  const content = useMemo(() => {
    if (!plantListData) {
      return <div className='mobile-plant-list-warning'>Loading plant list...</div>;
    }

    if (options.viewMode === TableViewMode.TABLE) {
      return (
        <FadedScrollContainer isHorizontal preventCustomScroll hideOverflowArrows>
          <PlantListTable />
        </FadedScrollContainer>
      );
    }

    if (displayType === DISPLAY_TYPE.Area) {
      if (plantListData.areas.length === 0) {
        return empty;
      }
      return <PlantListByArea />;
    }

    if (displayType === DISPLAY_TYPE.Variety) {
      if (plantListData.varieties.length === 0) {
        return empty;
      }
      return <PlantListByVariety />;
    }

    if (displayType === DISPLAY_TYPE.Summary) {
      if (plantListData.plants.length === 0) {
        return empty;
      }
      return <PlantListByPlant />;
    }

    return null;
  }, [displayType, options]);

  const classNames = ['mobile-plant-list-container'];
  if (options.printBackgroundColors) {
    classNames.push('print-background-colours');
  }
  if (options.viewMode === TableViewMode.TABLE) {
    classNames.push('table-view');
  }

  return (
    <div className='mobile-plant-list'>
      <MobilePlantListFilters />
      <div className={classNames.join(' ')} ref={setPlantTableElement}>
        {content}
      </div>
    </div>
  );
};

export default MobilePlantList;
