import { GuruContentPage, GuruDirectoryPage } from '@gi/app-guru-types/pages';
import { GuruDirectoryPageId, GuruPage, GuruPages } from '@gi/app-guru-types';
import { GuruContentType, GuruDisplayType, GuruPreviewItem, GuruSection, GuruSectionPreview } from '@gi/app-guru-types/types';
import { getDirectoryPageId } from '@gi/app-guru-types/directory-pages';
import Collection from '@gi/collection';
import { Pest } from '@gi/pest';
import Plant from '@gi/plant';
import { getGuruPageId } from '@gi/app-guru-common';

import { APIContent, APIPage, APIPages, APIPreviewItem, APIRelatedContent, APISection, SingleAPIPage } from './api-types';

function parseDisplayType(inputType: string): GuruDisplayType {
  if (inputType === 'Carousel') {
    return GuruDisplayType.Hero;
  }

  if (inputType === 'Grid') {
    return GuruDisplayType.Grid;
  }

  return GuruDisplayType.Row;
}

export function parseSectionPreview(section: APISection): GuruSectionPreview {
  return {
    id: section.navigation.refreshUrl,
    title: section.title,
    displayType: parseDisplayType(section.displayType), // 'row'/'single'/'grid' (single is a larger display type which shows a larger preview of a single item vs row
    displayOrder: section.displayOrder,
  };
}

export function parseDirectory(guruPages: GuruPages, apiPage: APIPage): GuruDirectoryPage {
  const id: GuruDirectoryPageId = getGuruPageId(guruPages, apiPage.id);
  const sections: Record<string, GuruSectionPreview> = {};
  const sectionIds: string[] = [];

  // Extract all the sections and their IDs
  for (let i = 0; i < apiPage.section.length; i++) {
    const apiSection = apiPage.section[i];
    const section = parseSectionPreview(apiSection);
    sectionIds.push(section.id);
    sections[section.id] = section;
  }

  // Pre-sort the list of section IDs based on the display order
  sectionIds.sort((sectionIdA, sectionIdB) => {
    const sectionA = sections[sectionIdA];
    const sectionB = sections[sectionIdB];
    return sectionA.displayOrder - sectionB.displayOrder;
  });

  return {
    backgroundImage: apiPage.backgroundImageUrl,
    foregroundColor: apiPage.foregroundColor, // Header foreground color
    title: apiPage.title,
    id,
    sectionIds,
    sections,
  };
}

function parsePreview(preview: APIPreviewItem, plants: Collection<Plant>, pests: Collection<Pest>): GuruPreviewItem {
  if (preview.media === 'Article') {
    if (preview.category === 'General') {
      return {
        type: GuruContentType.Article,
        tags: preview.tags,
        id: preview.url.split('/').pop()!,
        title: preview.title,
        description: preview.description,
        slug: preview.url.split('/').pop()!,
        thumbnailUrl: preview.imageUrl,
        secondaryThumbnailUrl: preview.secondaryImageUrl ? preview.secondaryImageUrl : null,
        content: {},
      };
    }

    if (preview.category === 'Pest') {
      return {
        type: GuruContentType.Pest,
        tags: preview.tags,
        id: preview.url.split('/').pop()!,
        title: preview.title,
        description: preview.description,
        slug: preview.url.split('/').pop()!,
        thumbnailUrl: preview.imageUrl,
        secondaryThumbnailUrl: preview.secondaryImageUrl ? preview.secondaryImageUrl : null,
        content: {
          pestCode: preview.code!,
          pest: pests.get(preview.code!)!,
        },
      };
    }

    if (preview.category === 'Plant') {
      // Video
      return {
        type: GuruContentType.Plant,
        tags: preview.tags,
        id: preview.url.split('/').pop()!,
        title: preview.title,
        description: preview.description,
        slug: preview.url.split('/').pop()!,
        thumbnailUrl: preview.imageUrl,
        secondaryThumbnailUrl: preview.secondaryImageUrl ? preview.secondaryImageUrl : null,
        content: {
          plantCode: preview.code!,
          plant: plants.get(preview.code!)!,
        },
      };
    }

    console.error('Unrecognised Category ', preview.category);
  }

  if (preview.media === 'Video') {
    // Video
    return {
      type: GuruContentType.Video,
      tags: preview.tags,
      id: preview.url.split('/').pop()!,
      title: preview.title,
      description: preview.description,
      slug: preview.url.split('/').pop()!,
      thumbnailUrl: preview.imageUrl,
      secondaryThumbnailUrl: preview.secondaryImageUrl ? preview.secondaryImageUrl : null,
      content: {},
    };
  }

  console.error('Unrecognised Media ', preview.media);

  return {
    type: GuruContentType.Article,
    tags: preview.tags,
    id: preview.url.split('/').pop()!,
    title: preview.title,
    slug: preview.url.split('/').pop()!,
    description: preview.description,
    thumbnailUrl: preview.imageUrl,
    secondaryThumbnailUrl: preview.secondaryImageUrl ? preview.secondaryImageUrl : null,
    content: {},
  };
}

export function parseContent(contentUUID: string, contentId: number, apiContent: APIContent): GuruContentPage {
  if (apiContent.media === 'Article') {
    if (apiContent.category === 'General') {
      return {
        contentId,
        contentUUID,
        backgroundImage: apiContent.imageUrl,
        foregroundColor: 0xffffffff,
        title: apiContent.title,
        tags: apiContent.tags,
        type: GuruContentType.Article,
        description: apiContent.description,
        bookmarked: apiContent.bookmarked,
        content: {
          leadImage: apiContent.imageUrl,
          article: apiContent.content,
          published: Date.parse(apiContent.publishedDate),
        },
        relatedContentUrl: apiContent.relatedContentUrl,
      };
    }

    if (apiContent.category === 'Pest') {
      return {
        contentId,
        contentUUID,
        backgroundImage: apiContent.imageUrl,
        foregroundColor: 0xffffffff,
        title: apiContent.title,
        tags: apiContent.tags,
        type: GuruContentType.Pest,
        description: apiContent.description,
        bookmarked: apiContent.bookmarked,
        content: {
          pestCode: apiContent.code,
        },
        relatedContentUrl: apiContent.relatedContentUrl,
      };
    }

    if (apiContent.category === 'Plant') {
      // Video
      return {
        contentId,
        contentUUID,
        backgroundImage: apiContent.imageUrl,
        foregroundColor: 0xffffffff,
        title: apiContent.title,
        tags: apiContent.tags,
        type: GuruContentType.Plant,
        description: apiContent.description,
        bookmarked: apiContent.bookmarked,
        content: {
          plantCode: apiContent.code,
        },
        relatedContentUrl: apiContent.relatedContentUrl,
      };
    }

    throw new Error(`Unrecognised Category ${apiContent.category}`);
  }

  if (apiContent.media === 'Video') {
    // Video
    return {
      contentId,
      contentUUID,
      backgroundImage: apiContent.imageUrl,
      foregroundColor: 0xffffffff,
      title: apiContent.title,
      tags: apiContent.tags,
      type: GuruContentType.Video,
      description: apiContent.description,
      bookmarked: apiContent.bookmarked,
      content: {
        content: apiContent.content,
        published: Date.parse(apiContent.publishedDate),
      },
      relatedContentUrl: apiContent.relatedContentUrl,
    };
  }

  throw new Error(`Unrecognised Category ${apiContent.category}`);
}

export function parseSection(section: APISection | APIRelatedContent, plants: Collection<Plant>, pests: Collection<Pest>): GuruSection {
  const items = section.contentItems.map((previewItem) => parsePreview(previewItem, plants, pests));
  const contentTypes = [...new Set(items.map((item) => item.type))];
  return {
    ...parseSectionPreview(section),
    items,
    contentTypes,
    navigation: {
      prevUrl: section.navigation.prevUrl,
      refreshUrl: section.navigation.refreshUrl,
      nextUrl: section.navigation.nextUrl,
    },
  };
}

function parsePage(page: SingleAPIPage): GuruPage | null {
  const pageId = getDirectoryPageId(page.name);

  if (pageId === null) {
    return null;
  }

  const idCandidate = page.url.split('/').pop()?.split('?')[0];
  if (!idCandidate) {
    throw new Error('Invalid ID');
  }

  const id = parseInt(idCandidate, 10);

  if (Number.isNaN(id)) {
    throw new Error('Invalid ID');
  }

  return {
    name: page.name,
    id,
    url: page.url,
    pageId,
  };
}

export function parsePages(apiPages: APIPages): GuruPages {
  const guruPages: GuruPages = {
    pages: [],
    pageIdMap: {},
    numericIdMap: {},
  } as unknown as GuruPages;

  apiPages.forEach((apiPage) => {
    const page = parsePage(apiPage);

    if (page === null) {
      console.warn(`Unrecognised page name ${apiPage.name}`);
      return;
    }

    if (guruPages.pageIdMap[page.pageId]) {
      throw new Error('Duplicate page name');
    }

    guruPages.pages.push(page);
    guruPages.pageIdMap[page.pageId] = page.id;
    guruPages.numericIdMap[page.id] = page.pageId;
  });

  // If the API sends more pages this will not throw as we're only adding pages
  // to the array which have been found in the pages
  if (guruPages.pages.length !== 7) {
    throw new Error('All required pages have not loaded');
  }

  return guruPages;
}
