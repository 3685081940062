import React from 'react';
import MatchDetailsContainer from './match-details/match-details-container';
import MatchDetailsIcon from './match-details/match-details-icon';
import MatchDetailsTitles from './match-details/match-details-titles';
import MatchDetailsTitle from './match-details/match-details-title';
import MatchDetailsSubtitle from './match-details/match-details-subtitle';
import MatchDetailsExtra from './match-details/match-details-extra';

interface iProps {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
  extraDetails: React.ReactNode;
}

const ListItemDetails = ({ icon, title, subtitle, extraDetails }: iProps): JSX.Element => {
  return (
    <MatchDetailsContainer>
      <MatchDetailsIcon>{icon}</MatchDetailsIcon>
      <MatchDetailsTitles>
        <MatchDetailsTitle>{title}</MatchDetailsTitle>
        <MatchDetailsSubtitle>{subtitle}</MatchDetailsSubtitle>
      </MatchDetailsTitles>
      <MatchDetailsExtra>{extraDetails}</MatchDetailsExtra>
    </MatchDetailsContainer>
  );
};

export default ListItemDetails;
