import { CropRotationModes, CropRotationViewTypes } from '@gi/constants';
import {
  InspectableClassAction,
  InspectableClassData,
  InspectableClassDataType,
  InspectableClassPropertyType,
  NodeComponent,
  bindState,
} from '@gi/core-renderer';
import { PlantFamilyTypes } from '@gi/plant-families';
import { State, StateDef } from '@gi/state';

import type CropRotationHistoryNode from './crop-rotation-history-node';

export enum CropRotationInteractionType {
  DRAG = 'DRAG', // When a plant is being dragged
  EDIT = 'EDIT', // When a plant is being manipulated (resized etc)
  DRAW = 'DRAW', // When a plant is being drawn
}

type CropRotationContextState = StateDef<{
  mode: CropRotationModes;
  viewType: CropRotationViewTypes;
  interactions: { [key in CropRotationInteractionType]?: PlantFamilyTypes[] };
  interactionNames: CropRotationInteractionType[];
}>;

class CropRotationContext extends NodeComponent {
  type = 'CropRotationContext';

  readonly state: State<CropRotationContextState>;

  #cropRotationHistories: CropRotationHistoryNode[] = [];

  constructor() {
    super();

    this.state = new State({
      mode: CropRotationModes.AUTOMATIC,
      viewType: CropRotationViewTypes.OFF,
      interactions: {},
      interactionNames: [],
    });
    bindState(this.state, this);

    this.state.addValidator(
      (state) => {
        state.values.viewType = this.#getViewType();
      },
      { properties: ['mode', 'interactions', 'interactionNames'] }
    );

    this.state.addWatcher(
      () => {
        this.#updateHistoryNodes();
      },
      { properties: ['viewType'] }
    );
  }

  setCropRotationMode(mode: CropRotationModes) {
    this.state.values.mode = mode;
  }

  /**
   * Registers a history node with the context.
   * Allows this context to control what crop rotation the node shows.
   */
  registerHistoryNode(node: CropRotationHistoryNode) {
    if (!this.#cropRotationHistories.includes(node)) {
      this.#cropRotationHistories.push(node);
      node.setViewMode(this.state.values.viewType);
    }
  }

  /**
   * Unregisters a history node with the context, usually before being removed.
   */
  unregisterHistoryNode(node: CropRotationHistoryNode) {
    const index = this.#cropRotationHistories.indexOf(node);
    if (index !== -1) {
      this.#cropRotationHistories.splice(index, 1);
    }
  }

  /**
   * Tells this context that an interaction is happening, and we should show the given plant family histories.
   * @param interactionType The interaction type asking for thje families to be shown
   * @param plantFamilies The list of plant families to show. Currently, will display if only 1 family is specified.
   */
  showFamilies(interactionType: CropRotationInteractionType, ...plantFamilies: PlantFamilyTypes[]) {
    const { interactions, interactionNames } = this.state.values;
    if (!interactions[interactionType]) {
      this.state.values.interactionNames = [...interactionNames, interactionType];
    }
    this.state.values.interactions = {
      ...interactions,
      [interactionType]: plantFamilies,
    };
  }

  /**
   * Tells this context that an interaction has ended, and we should stop showing whatever associated plant families we have.
   * @param interactionType The interaction type that's ending
   */
  stopShowing(interactionType: CropRotationInteractionType) {
    const { interactions, interactionNames } = this.state.values;
    if (interactions[interactionType]) {
      const filteredInteractions = { ...interactions };
      delete filteredInteractions[interactionType];
      this.state.values.interactions = filteredInteractions;
      this.state.values.interactionNames = interactionNames.filter((n) => n !== interactionType);
    }
  }

  /**
   * Returns the list of plant families from the most recently-started interaction.
   * In theory all interactions should have the same families, but just in case, this acts like a stack.
   */
  #getInteractionPlantFamilies(): PlantFamilyTypes[] {
    const { interactions, interactionNames } = this.state.values;
    if (interactionNames.length > 0) {
      return interactions[interactionNames[interactionNames.length - 1]]!;
    }
    return [];
  }

  /**
   * Returns the viewType that should be used, based on crop rotation mode and current plant families.
   */
  #getViewType(): CropRotationViewTypes {
    const { mode } = this.state.values;
    switch (mode) {
      case CropRotationModes.OFF:
        return CropRotationViewTypes.OFF;
      case CropRotationModes.AUTOMATIC:
        const families = this.#getInteractionPlantFamilies();
        if (families.length === 1) {
          // Check the plant family is a valid crop rotation view type
          if (Object.values(CropRotationViewTypes).includes(families[0] as any)) {
            return families[0] as CropRotationViewTypes;
          }
        }
        return CropRotationViewTypes.OFF;
      default:
        return mode;
    }
  }

  /**
   * Updates all the nodes owned by this context to match the current viewType.
   */
  #updateHistoryNodes() {
    const { viewType } = this.state.values;
    this.#cropRotationHistories.forEach((node) => {
      node.setViewMode(viewType);
    });
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
    ...Object.keys(CropRotationModes).map<InspectableClassAction<this>>((key) => {
      return {
        type: InspectableClassDataType.Action,
        displayName: `Mode: ${key}`,
        callback: () => {
          this.state.values.mode = CropRotationModes[key];
        },
      };
    }),
  ];
}

export default CropRotationContext;
