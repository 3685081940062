import { UserPlantVariety } from '@gi/user';
import { PlantTypes } from '@gi/constants';
import Plant from './plant';
import { PlantAreaCount, PlantSpacings } from './plant-types';

/**
 * Returns spacings for a given plant and optional UserPlantVariety combination
 */
export function getSpacings(plant: Plant, userPlantVariety: UserPlantVariety | null): PlantSpacings {
  return {
    spacing: userPlantVariety !== null && userPlantVariety.customSpacings ? userPlantVariety.spacing : plant.spacing,
    inRowSpacing: userPlantVariety !== null && userPlantVariety.customSpacings ? userPlantVariety.inRowSpacing : plant.inRowSpacing,
    rowSpacing: userPlantVariety !== null && userPlantVariety.customSpacings ? userPlantVariety.rowSpacing : plant.rowSpacing,
    sfgCount: plant.canBeSquareFootPlant
      ? userPlantVariety !== null && userPlantVariety.customSpacings
        ? userPlantVariety.squareFootPlantCount
        : plant.squareFootPlantCount
      : null,
  };
}

/**
 * Returns the square foot plant count for a given Plant and UserPlantVariety combination
 */
export function getSquareFootPlantCount(plant: Plant, userVariety: UserPlantVariety | null): number {
  if (userVariety === null || !userVariety.customSpacings) {
    return plant.squareFootPlantCount;
  }

  return userVariety.squareFootPlantCount;
}

/**
 * Returns true if the given dimensions are a single plant (if height is 0 and width is 0), else false
 */
export function isSingle(width: number, height: number): boolean {
  return height === 0 && width === 0;
}

/**
 * Returns true if the given dimensions could be a plant row (if height is 0 and width is not), else false
 */
export function isRow(width: number, height: number): boolean {
  return height === 0 && width !== 0;
}

/**
 * Returns the spacing between plants, spacing is different when planted in a row (height is 0)
 */
export function getSpacing(width: number, height: number, spacing: number, inRowSpacing: number): number {
  if (isRow(width, height)) {
    return inRowSpacing;
  }

  return spacing;
}

/**
 * returns the number of plants on the x and y axis of a plant instance of the given size
 */
export function getBlockPlantCount(width: number, height: number, spacing: number, inRowSpacing: number): Vector2 {
  const blockSpacing = getSpacing(width, height, spacing, inRowSpacing);
  const x = Math.floor(width / blockSpacing) + 1;
  const y = Math.floor(height / blockSpacing) + 1;

  return {
    x,
    y,
  };
}

/**
 * returns the number of plants on the x and y axis of a plant instance of the given size
 */
export function getPlantCount(
  isSquareFoot: boolean,
  squareFootPlantCount: null | number,
  width: number,
  height: number,
  spacing: number,
  inRowSpacing: number
): PlantAreaCount {
  if (isSquareFoot) {
    if (squareFootPlantCount === null) {
      console.error('Square foot plant but sfg plant count is null');
      return {
        x: 0,
        y: 0,
        total: 0,
      };
    }

    return {
      x: 0,
      y: 0,
      total: squareFootPlantCount,
    };
  }

  const counts = getBlockPlantCount(width, height, spacing, inRowSpacing);

  return {
    x: counts.x,
    y: counts.y,
    total: counts.x * counts.y,
  };
}

/**
 * Returns the correct x, y spacing for the given height and width
 */
export function getPlantRootSpacing(width: number, height: number, spacing: number, rowSpacing: number, inRowSpacing: number): Vector2 {
  if (isRow(width, height)) {
    return {
      x: inRowSpacing,
      y: rowSpacing,
    };
  }

  return {
    x: spacing,
    y: spacing,
  };
}

/**
 * Returns PLANT_BLOCK, PLANT_ROW or PLANT_SINGLE depending on the size of the provided width and height with the given spacings
 */
export function getType(width: number, height: number, spacing: number, inRowSpacing: number): string {
  let longerSide = height;
  let shorterSide = width;

  if (width >= height) {
    longerSide = width;
    shorterSide = height;
  }

  if (longerSide >= spacing && shorterSide >= spacing) {
    return PlantTypes.PLANT_BLOCK;
  }

  if (longerSide >= inRowSpacing) {
    return PlantTypes.PLANT_ROW;
  }

  return PlantTypes.PLANT_SINGLE;
}

const SCALE_MAX_AT_SIZE = 60;
const SCALE_MIN_AT_SIZE = 300;

const MAX_SCALE_INCREASE = 3;
const MIN_SCALE_INCREASE = 1;

const BASE_SCALE = 2;

/**
 * Scaling:
 *
 * Base scale is a simple scale modifier, for use in future when we have different texture quality levels (low, medium, high).
 * Normal base-scale should be changed to 1 (from 2) when we switch to each plant having their own texture scaling as
 * part of the plant-data.
 *
 * Additional automated scaling will be based on the size of the plant on the canvas, with smaller plants having a relatively higher scale
 * increase than larger plants. Mostly due to the fact that smaller plants can be scaled up more without having a significant impact on the
 * overall spritesheet size.
 *
 * Automated scaling will also be removed when all plants have their own individual scaling values
 *
 * All scales below are in addition to the (currently) 2* base scale above
 *
 * Automated scaling to go from 4* at 60px and under to 1* at 300px, scaling linearly between 60 and 600.
 * We will use the smallest dimension to find the appropriate value to scale by, it won't matter too much
 * for plants as they're mostly square.
 */
export function calculatePlantSpriteDimensions(plantDimensions: Dimensions): Dimensions {
  const inputSize = Math.min(plantDimensions.width, plantDimensions.height);

  const plantScaleIncrease =
    MAX_SCALE_INCREASE -
    Math.min(
      MAX_SCALE_INCREASE - MIN_SCALE_INCREASE,
      (Math.max(inputSize - SCALE_MAX_AT_SIZE, 0) / (SCALE_MIN_AT_SIZE - SCALE_MAX_AT_SIZE)) * (MAX_SCALE_INCREASE - MIN_SCALE_INCREASE)
    );

  return {
    width: Math.round(plantDimensions.width * BASE_SCALE * plantScaleIncrease),
    height: Math.round(plantDimensions.height * BASE_SCALE * plantScaleIncrease),
  };
}
