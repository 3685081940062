import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { User } from '@gi/user';
import { createSupportEmail } from '@gi/support-email';
import { browserSpecs } from '@gi/utils';
import { SupportRequestActionCreators } from '@gi/react-requests';

import type { SupportPageState } from '@gi/app-help-types';
import { AppSupportActions } from './support-slice';

/**
 * Sets the active page and subpage
 * @param page The page (and subpage) to make active
 */
export const setPage = (page: SupportPageState) => {
  return AppSupportActions.setActivePage(page);
};

type SupportEmailParams = {
  user: User;
  clientID: number;
  name?: string;
  message: string;
  formID?: string;
};

/**
 * Sends a support email back to us from the user.
 * @param user The user sending the email
 * @param name The given name of the suer
 * @param message The email contents
 * @param formID The ID of the form submitting the email
 */
export const submitSupportEmail = ({ user, clientID, name = '', message, formID = '' }: SupportEmailParams) => {
  const action: ThunkAction<void, any, unknown, AnyAction> = (dispatch) => {
    const supportEmail = createSupportEmail({
      clientID,
      name,
      emailAddress: user.email,
      message,
      platform: `WGP ${formID} - ${VERSION} - ${browserSpecs.name}, ${browserSpecs.version}`,
      userID: user.ID,
    });

    dispatch(SupportRequestActionCreators.submitSupportEmailRequest(supportEmail, formID));
  };
  return action;
};

/**
 * Clears a form after the support email has been sent
 * @param formID The ID of the form to clear
 */
export const clearSupportEmailRequest = (formID: string) => {
  const action: ThunkAction<void, any, unknown, AnyAction> = (dispatch) => {
    dispatch(SupportRequestActionCreators.clearSupportEmailRequest(formID));
  };
  return action;
};
