import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotesEditor, usePlanNotes } from '@gi/notes';
import { PlannerTabTypes, PlannerControlsTab } from '@gi/constants';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { OpenPlanStateUtils, PlannerActionCreators, PlannerSelectors } from '@gi/app-planner-slice';
import { SidebarContent, SidebarHeaderContent } from '@gi/planner-sidebar';

import styles from './notes-tab-content.module.css';

const SidebarNotesTabContent = (): JSX.Element => {
  const dispatch = useDispatch();
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const openPlanStates = useSelector(PlannerSelectors.getOpenPlanStates);

  const { notes, setNotes, syncNotes } = usePlanNotes(activePlan);

  const disableNotes = useMemo(() => {
    if (!activePlan) {
      return true;
    }
    if (!OpenPlanStateUtils.hasPlanState(openPlanStates, activePlan.id)) {
      return false;
    }
    return OpenPlanStateUtils.getPlanState(openPlanStates, activePlan.id).activePlannerTab === PlannerTabTypes.NOTES;
  }, [activePlan, openPlanStates]);

  const gotoNotesTab = useCallback(() => {
    if (activePlan) {
      dispatch(
        PlannerActionCreators.setActivePlannerTab({
          planID: activePlan.id,
          tabID: PlannerTabTypes.NOTES,
        })
      );
    }
  }, [activePlan]);

  return (
    <SidebarContent tabId={PlannerControlsTab.NOTES} className={styles.container}>
      <SidebarHeaderContent tabId={PlannerControlsTab.NOTES}>
        <h4>Notes</h4>
      </SidebarHeaderContent>
      <p className={styles.notesExplanation}>
        Use this space to store notes for your plan. You can also edit your notes in the{' '}
        <button type='button' className='button button-inline' onClick={gotoNotesTab}>
          Notes tab
        </button>
        .
      </p>
      <NotesEditor value={notes} onChange={setNotes} className={styles.notesEditor} disabled={disableNotes} onSyncRequest={syncNotes} />
    </SidebarContent>
  );
};

export default SidebarNotesTabContent;
