import { LoadingState } from '@gi/constants';

export class RevocableObjectURL {
  private getter: () => Promise<string>;

  #status: LoadingState = LoadingState.NONE;
  get status() {
    return this.#status;
  }

  #url: string | null = null;
  get url() {
    return this.#url;
  }

  constructor(getter: () => Promise<string>) {
    this.getter = getter;
  }

  getObjectURL(): Promise<string> {
    if (this.url !== null) {
      return new Promise((resolve) => {
        resolve(this.url!);
      });
    }
    return this.getter().then((url) => {
      this.#url = url;
      return url;
    });
  }

  revokeObjectURL() {
    if (this.url !== null) {
      URL.revokeObjectURL(this.url);
      this.#url = null;
    }
  }
}
