import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from './quantity-selector.module.css';

interface iProps {
  value: number;
  onChange: (value: number) => void;
}

const QuantityNumberInput = ({ value, onChange }: iProps): JSX.Element => {
  const [numValue, setNumValue] = useState<string>(value.toString());
  const [valid, setValid] = useState<boolean>(true);
  const [hasFocus, setHasFocus] = useState(false);

  useEffect(() => {
    if (!hasFocus) {
      setNumValue(value.toString());
    }
  }, [value, hasFocus]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNumValue(e.target.value);

    try {
      let parsedVal = parseFloat(e.target.value);

      if (!Number.isNaN(parsedVal)) {
        // Prevent negative and decimal numbers
        parsedVal = Math.max(Math.round(parsedVal), 0);
        onChange(parsedVal);
        setValid(true);
      } else {
        setValid(false);
      }
    } catch (err) {
      setValid(false);
    }
  };

  return (
    <input
      className={`${styles.numberInput} ${valid ? '' : styles.invalidNumberInput}`}
      type='number'
      value={numValue}
      onChange={onInputChange}
      onFocus={() => setHasFocus(true)}
      onBlur={() => {
        setNumValue(value.toString());
        setHasFocus(false);
      }}
    />
  );
};

export default QuantityNumberInput;
