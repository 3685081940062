import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import FormField, { FormSection } from '@gi/form-responsive';
import { useDebounce } from '@gi/react-utils';
import { PercentInput } from '@gi/length-input';

import { FieldPreset, FieldPresetLabelTop } from '../layout';
import { PlanBackgroundImageEditorContext } from '../../plan-background-image-editor-context';

import styles from '../styles.module.css';

export type PlanBackgroundSettings = {
  visible: boolean;
  opacity: number;
  maintainAspectRatio: boolean;
};

const BackgroundVisibilityForm = (): JSX.Element => {
  const firstRender = useRef<boolean>(true);

  const { settings, setSettings } = useContext(PlanBackgroundImageEditorContext);

  const [displayOpacity, setDisplayOpacity] = useState<number>(Math.round(settings.opacity * 100));
  const debouncedOpacity = useDebounce(displayOpacity, 500); // Debounce opacity because the Plan model shouldn't be given frequent updates

  const handleChange = useCallback(
    <K extends keyof PlanBackgroundSettings>(key: K, value: PlanBackgroundSettings[K]) => {
      setSettings({
        [key]: value,
      });
    },
    [setSettings]
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      handleChange('opacity', debouncedOpacity / 100);
    }
  }, [debouncedOpacity]);

  return (
    <FormSection heading='Visibility' padding={6} gap={6} className='form-section-background'>
      <FormField label='Hide' htmlFor='bg-settings:visible' layoutPreset={FieldPreset}>
        <input type='checkbox' id='bg-settings:visible' checked={!settings.visible} onChange={(e) => handleChange('visible', !e.target.checked)} />
      </FormField>
      <FormField label='Opacity' htmlFor='bg-settings:opacity' layoutPreset={FieldPresetLabelTop} disabled={!settings.visible}>
        <div className={styles.opacityInputContainer}>
          <PercentInput id='bg-settings:opacity' value={displayOpacity} onChange={(v) => setDisplayOpacity(v)} disabled={!settings.visible} />
          <input
            type='range'
            value={displayOpacity}
            onChange={(e) => setDisplayOpacity(parseInt(e.target.value, 10))}
            min={0}
            max={100}
            disabled={!settings.visible}
          />
        </div>
      </FormField>
    </FormSection>
  );
};

export default BackgroundVisibilityForm;
