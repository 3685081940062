import { config } from '@gi/config';
import { NetworkRequestDetails, NetworkRequestError, getRequestDetailsString } from './request-error';

// List of HTTP request methods. Unlikely to use half of them.
const HTTP_REQUEST_METHODS = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH', 'HEAD', 'OPTIONS', 'CONNECT', 'TRACE'] as const;
export type HTTPRequestMethod = (typeof HTTP_REQUEST_METHODS)[number];

/**
 * List of potential content types. This is clearly non-exhaustive, but rigidly defining them reduces
 * the change of mistakes, and allows us to define content transforms for body content a bit easier.
 */
const HTTP_CONTENT_TYPES = ['application/json', 'text/plain', 'application/x-www-form-urlencoded'] as const;
export type HTTPContentType = (typeof HTTP_CONTENT_TYPES)[number];

export enum RequestAuthMode {
  // No authorization information will be added to the request.
  None = 'None',
  // The bearer token from the user will be appended as an Authorization header to the request.
  Bearer = 'Bearer',
  // The user ID and ticket will be appended as ?u=userId&t=ticket to the query parameters of the request.
  Ticket = 'Ticket',
  // Same as Bearer & Ticket at the same time.
  TicketAndBearer = 'Both',
  // Use anonymous API key
  Anonymous = 'Anonymous',
}

export type Stringable =
  | string
  | {
      toString(): string;
    };

export type UserRequestAuthInfo = {
  id: number;
  ticket: string;
  token: string;
};

interface RequestBuilderOptions {
  includeRequestBodyInLogs: boolean;
  errorIfNotOk: boolean;
  keepAlive: boolean;
}

/**
 * Class for building a network request using the fetch API.
 */
class RequestBuilder {
  url: string;
  method: HTTPRequestMethod = 'GET';
  contentType: HTTPContentType = 'application/json';

  timeout: number | null = 60 * 1000; // 60s default timeout

  user: UserRequestAuthInfo | null = null;
  authMode: RequestAuthMode = RequestAuthMode.None;

  headers: Record<string, string> = {};
  parameters: URLSearchParams = new URLSearchParams();
  body: BodyInit | null;

  options: Partial<RequestBuilderOptions> = {};

  #startTime: number | null = null;
  #endTime: number | null = null;
  /** Returns the time taken for the request to finish, or the current duration of the request if running
   * (null if the request hasn't started) */
  get time() {
    if (this.#startTime !== null) {
      return (this.#endTime ?? Date.now()) - this.#startTime;
    }
    return null;
  }

  execute() {
    this.#startTime = Date.now();
    const body = this.getBody();
    const controller = this.timeout !== null && AbortController !== undefined ? new AbortController() : undefined;
    const timeout = controller && this.timeout !== null && this.timeout > 0 ? window.setTimeout(() => controller.abort(), this.timeout) : undefined;
    return fetch(this.getURL(), {
      method: this.method,
      signal: controller?.signal,
      headers: this.getHeaders(),
      keepalive: this.options.keepAlive ?? false,
      body,
    })
      .then((response) => {
        if (timeout !== undefined) {
          window.clearTimeout(timeout);
        }
        this.#endTime = Date.now();
        if (response.ok) {
          this.logStatusSuccess(response, body);
        } else if (this.options.errorIfNotOk) {
          throw this.makeError({ response, body });
        } else {
          this.logStatusError(response, body);
        }
        return response;
      })
      .catch((e) => {
        const timedOut = e instanceof DOMException && (e.name === 'AbortError' || controller?.signal.aborted);
        const error = e instanceof NetworkRequestError ? e : this.makeError({ body, error: e, timedOut });
        console.debug(error);
        throw error;
      });
  }

  constructor(url: string) {
    this.url = url;
  }

  /** Sets the HTTP Method to use for this request */
  setMethod(method: HTTPRequestMethod) {
    this.method = method;
    return this;
  }

  /** Sets the Content-Type header for this request */
  setContentType(contentType: HTTPContentType) {
    this.contentType = contentType;
    return this;
  }

  /** Sets the user auth info to use for this request. Will only be used if authMode !== NONE */
  setUser(user: UserRequestAuthInfo | null) {
    this.user = user;
    if (this.authMode === RequestAuthMode.None) {
      this.authMode = RequestAuthMode.TicketAndBearer;
    }
    return this;
  }

  /** Sets the auth mode to use for this request. See {@link RequestAuthMode} for the different modes */
  setAuthMode(authMode: RequestAuthMode) {
    this.authMode = authMode;
    return this;
  }

  /** Sets the timeout time for this request to be automatically cancelled (if possible). Set to null for no timeout. */
  setTimeout(timeout: number | null) {
    if (timeout !== null && timeout <= 0) {
      console.warn('Timeout should not be 0 or less. Defaulting to no timeout.');
      this.timeout = null;
    } else {
      this.timeout = timeout;
    }
    return this;
  }

  /** Sets extra options for this request, including logging options. */
  setOptions(options: Partial<RequestBuilderOptions>) {
    this.options = { ...this.options, ...options };
    return this;
  }

  /** Adds the given headers to the request */
  addHeaders(headers: Record<string, string>) {
    this.headers = {
      ...this.headers,
      ...headers,
    };
    return this;
  }

  /** Overwrites the headers for this request with the given headers. Auth headers may still be appended. */
  setHeaders(headers: Record<string, string> | null) {
    if (headers === null) {
      this.headers = {};
    } else {
      this.headers = headers;
    }
    return this;
  }

  /** Adds the given query parameters to the request URL */
  addParameters(parameters: Record<string, Stringable>) {
    const keys = Object.keys(parameters);
    for (let i = 0; i < keys.length; i++) {
      this.parameters.set(keys[i], parameters[keys[i]].toString());
    }
    return this;
  }

  /** Overwrites the query parameters for this request with the given parameters. Auth parameters may still be appended. */
  setParameters(parameters: Record<string, Stringable> | URLSearchParams | null) {
    if (parameters === null) {
      this.parameters = new URLSearchParams();
    } else if (parameters instanceof URLSearchParams) {
      this.parameters = parameters;
    } else {
      this.parameters = new URLSearchParams();
      const keys = Object.keys(parameters);
      for (let i = 0; i < keys.length; i++) {
        this.parameters.set(keys[i], parameters[keys[i]].toString());
      }
    }
    return this;
  }

  /** Sets the body of this request. Will be ignored for GET requests */
  setBody(body: BodyInit | null) {
    this.body = body;
    return this;
  }

  /** Returns the URL for this request, including appended query parameters and any auth parameters. */
  getURL() {
    const finalParameters = new URLSearchParams(this.parameters);

    if (this.authMode === RequestAuthMode.Ticket || this.authMode === RequestAuthMode.TicketAndBearer) {
      if (this.user === null) {
        throw new Error(`Tried to use auth mode ${this.authMode} without setting user info.`);
      }
      finalParameters.set('u', this.user.id.toString());
      finalParameters.set('t', this.user.ticket);
    }

    if (this.authMode === RequestAuthMode.Anonymous) {
      finalParameters.set('t', config.anonymousApiKey);
    }

    if (finalParameters.size > 0) {
      return `${this.url}?${finalParameters.toString()}`;
    }
    return this.url;
  }

  /** Returns the headers for this request, including nay auth headers */
  getHeaders() {
    const headers: Record<string, string> = {
      ...this.headers,
      'Content-Type': this.contentType,
    };

    if (this.authMode === RequestAuthMode.Bearer || this.authMode === RequestAuthMode.TicketAndBearer) {
      if (this.user === null) {
        throw new Error(`Tried to use auth mode ${this.authMode} without setting user info.`);
      }
      headers.Authorization = `Bearer ${this.user.token}`;
    }

    return headers;
  }

  /** Returns the body for this request, if applicable. undefined otherwise. */
  getBody() {
    if (this.method === 'GET' || this.body === null) {
      return undefined;
    }

    if (this.contentType === 'application/json' && typeof this.body === 'object') {
      return JSON.stringify(this.body);
    }

    return this.body;
  }

  private getRequestDetails({
    response,
    body,
    error,
    timedOut = false,
  }: Partial<{
    response: Response;
    body: BodyInit | null | undefined;
    error: Error;
    timedOut: boolean;
  }>): NetworkRequestDetails {
    const customProperties: Record<string, string> = {};
    if (this.options.includeRequestBodyInLogs) {
      customProperties.Body = body?.toString() ?? 'undefined';
    }
    return {
      method: this.method,
      url: this.getURL(),
      statusCode: response ? response.status : null,
      timedOut,
      duration: this.time ?? undefined,
      error,
      customProperties,
      response,
      offline: window.navigator.onLine !== undefined ? !window.navigator.onLine : undefined,
    };
  }

  /** Makes a NetworkRequestError, containing the given properties */
  makeError(
    details: Partial<{
      response: Response;
      body: BodyInit | null | undefined;
      error: Error;
      timedOut: boolean;
    }>
  ): NetworkRequestError {
    return new NetworkRequestError(this.getRequestDetails(details));
  }

  /** Logs a successful request (status code 2xx)  for debugging */
  protected logStatusSuccess(response: Response, body?: BodyInit) {
    const details = this.getRequestDetails({ response, body });
    console.debug(getRequestDetailsString({ ...details, title: '🌐✅ Network Request Succeeded:' }));
  }

  /** Logs an unsuccessful request (status code !2xx) for debugging */
  protected logStatusError(response: Response, body?: BodyInit | null) {
    console.debug(getRequestDetailsString(this.getRequestDetails({ response, body })));
  }

  /** Logs an error (request didn't complete) for debugging */
  protected logError(error: Error, body?: BodyInit | null) {
    console.debug(getRequestDetailsString(this.getRequestDetails({ error, body })));
  }
}

export default RequestBuilder;
