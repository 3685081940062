import { ClassOf } from '../../types';
import type NodeComponent from '../node-component';
import type Node from '../../node';

/**
 * Helper class to store a mapping from NodeComponent.constructor -> NodeComponent[].
 */
class NodeComponentCollection<T extends NodeComponent> implements Iterable<T> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  #items: Map<Function, T[]> = new Map();
  #parent: Node | null = null;
  #bound: boolean = false;

  has<U extends T>(item: ClassOf<U>, name?: string): boolean {
    const itemList = this.#items.get(item.constructor);
    if (itemList === undefined) {
      return false;
    }
    if (name) {
      return itemList.some((i) => i.name === name);
    }
    return itemList.length > 0;
  }

  get<U extends T>(item: ClassOf<U>, name?: string): U | null {
    const itemList = this.#items.get(item);
    if (itemList === undefined || itemList.length === 0) {
      return null;
    }
    if (name) {
      return itemList.find((i) => i.name === name) as U | null;
    }
    return itemList[0] as U;
  }

  add<U extends T>(item: U): U {
    if (!this.#items.get(item.constructor)) {
      this.#items.set(item.constructor, []);
    }
    const itemList = this.#items.get(item.constructor)!;
    if (itemList.indexOf(item) !== -1) {
      throw new Error(`Cannot add ${item.type} (${item.uuid}) to colleciton: Item is already part of this collection`);
    }
    itemList.push(item);
    if (this.parent) {
      item.setOwner(this.parent);
    }
    return item;
  }

  remove<U extends T>(item: U): void {
    const itemList = this.#items.get(item.constructor);
    if (itemList === undefined || itemList.length === 0) {
      throw new Error(`Cannot remove ${item.type} (${item.uuid}) from collection: Item is not part of this collection.`);
    }
    const index = itemList.indexOf(item);
    if (index === -1) {
      throw new Error(`Cannot remove ${item.type} (${item.uuid}) from collection: Item is not part of this collection.`);
    }
    itemList.splice(index, 1);
    item.removeComponent();
  }

  /** Returns the node that owns this collection */
  get parent(): Node | null {
    return this.#parent;
  }

  set parent(parent: Node | null) {
    this.#parent = parent;
    this.#items.forEach((itemList) => {
      itemList.forEach((item) => {
        item.removeComponent();
        if (parent) {
          item.setOwner(parent);
        }
      });
    });
  }

  /** Returns true if this collection is bound to an engine via its parent */
  get bound() {
    return this.#bound;
  }

  set bound(bound: boolean) {
    this.#bound = bound;
    this.#items.forEach((itemList) => {
      itemList.forEach((item) => {
        item.setBound(bound);
      });
    });
  }

  *[Symbol.iterator](): Iterator<T> {
    const keys = [...this.#items.keys()];
    for (let i = 0; i < keys.length; i++) {
      const itemList = this.#items.get(keys[i]);
      if (itemList !== undefined) {
        for (let j = 0; j < itemList.length; j++) {
          yield itemList[j];
        }
      }
    }
  }
}

export default NodeComponentCollection;
