import React, { ReactNode, useContext } from 'react';

import { FloatingArrow, FloatingPortal, useTransitionStyles } from '@floating-ui/react';

import { LocalContextMenuContext } from './context-menu';

import styles from './context-menu-styles.module.css';

interface iProps {
  className?: string;
  children?: ReactNode;
  transitionDuration?: number;
}

const ContextMenuContent = ({ className, children, transitionDuration = 0 }: iProps): JSX.Element | null => {
  const { context, refs, getContextMenuProps, contextMenuStyles } = useContext(LocalContextMenuContext);

  const { isMounted, styles: cssStyles } = useTransitionStyles(context, { duration: transitionDuration });

  if (!isMounted) {
    return null;
  }

  const classNames: string[] = [styles.contextMenu];
  if (className) {
    classNames.push(className);
  }

  return (
    <FloatingPortal>
      <div ref={refs.setFloating} {...getContextMenuProps({ className: classNames.join(' '), style: { ...contextMenuStyles, ...cssStyles } })}>
        <FloatingArrow className={styles.contextMenuArrow} ref={refs.setArrow} context={context} />
        <div className={styles.contextMenuContent}>{children}</div>
      </div>
    </FloatingPortal>
  );
};

export default ContextMenuContent;
