import React, { useContext, useMemo } from 'react';
import { ShoppingListProductsUtils, ShoppingList } from '@gi/products';
import { ResourceContext } from '@gi/resource-provider';

import { formatPrice } from '../../../utils';
import TotalsRow from './totals-row';

import styles from './totals.module.css';

interface iProps {
  shoppingList: ShoppingList;
}

const Totals = ({ shoppingList }: iProps): JSX.Element => {
  const { store } = useContext(ResourceContext);

  const plantsPrice = useMemo(() => {
    if (store === null) {
      return 0;
    }

    return shoppingList.plants.uuids
      .map((uuid) => shoppingList.plants.entriesByUuid[uuid])
      .filter((entry) => entry.inCart)
      .map((entry) => {
        const variant = ShoppingListProductsUtils.getVariant(store.products, entry.variantId);
        if (!variant) {
          return 0;
        }
        return variant.price * entry.cartQuantity;
      })
      .reduce((curr, next) => curr + next, 0);
  }, [shoppingList]);

  const gardenObjectsPrice = useMemo(() => {
    if (store === null) {
      return 0;
    }

    return shoppingList.gardenObjects.uuids
      .map((uuid) => shoppingList.gardenObjects.entriesByUuid[uuid])
      .filter((entry) => entry.inCart)
      .map((entry) => {
        const variant = ShoppingListProductsUtils.getVariant(store.products, entry.variantId);
        if (!variant) {
          return 0;
        }
        return variant.price * entry.cartQuantity;
      })
      .reduce((curr, next) => curr + next, 0);
  }, [shoppingList]);

  const total = plantsPrice + gardenObjectsPrice;

  return (
    <div className={styles.totals}>
      <TotalsRow title='Plants' price={plantsPrice} currency={store ? store.details.currency : 'USD'} />
      <TotalsRow title='Garden Objects' price={gardenObjectsPrice} currency={store ? store.details.currency : 'USD'} />
      <div className={`${styles.totalsRow} ${styles.subtotalRow}`}>
        <div>Subtotal</div>
        <div className={styles.price}>{formatPrice(total, store?.details.currency)}</div>
      </div>
      <div className={styles.priceDetails}>Additional Delivery Charges May Apply</div>
    </div>
  );
};

export default Totals;
