import { Store, Unsubscribe } from 'redux';

export type HandleStoreChangeListener<T> = (previousState: T, currentState: T, store: Store<T>) => void;

/**
 * A Wrapper class for creating a simple listener for a redux store
 *
 * @class StoreListener
 * @template T
 */
class StoreListener<T> {
  currentState: T;
  store: Store<T> | null;

  unsubscribe: null | Unsubscribe = null;
  handleChange: HandleStoreChangeListener<T>;

  constructor(handleChange: HandleStoreChangeListener<T>) {
    this.handleChange = handleChange;
  }

  storeHandleChange = () => {
    if (!this.store) {
      console.error('Store change event without store');
      return;
    }

    const previousState = this.currentState;
    this.currentState = this.store.getState();

    this.handleChange(previousState, this.currentState, this.store);
  };

  /**
   * Starts this listening to the store for changes
   *
   * @memberof LocalSettingsStoreSynchroniser
   */
  startListening(store: Store<T>) {
    if (!this.isListening()) {
      this.store = store;
      this.currentState = store.getState();
      this.unsubscribe = this.store.subscribe(this.storeHandleChange);
    }
  }

  /**
   * Stops listening to the store for changes
   *
   * @memberof LocalSettingsStoreSynchroniser
   */
  stopListening() {
    if (this.unsubscribe !== null) {
      this.store = null;
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }

  /**
   * Returns true if the store listener is currently subscribed, else false
   *
   * @returns {boolean}
   * @memberof LocalSettingsStoreSynchroniser
   */
  isListening() {
    return this.unsubscribe !== null;
  }
}

export default StoreListener;
