import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { config } from '@gi/config';

import LoadingPage from './loading-page';
import * as SessionSelectors from '../session-selectors';
import { SessionGatewayProps, SessionGatewayType } from './session-gateway-types';

const SessionGateway: SessionGatewayType = ({ children }: SessionGatewayProps) => {
  const user = useSelector(SessionSelectors.getUser);
  const session = useSelector(SessionSelectors.getSession);

  const { pathname, search, hash } = useLocation();

  if (user === null && session.restored && !session.restoring) {
    let url = `${config.basename}${pathname}`;
    if (search) {
      url += encodeURIComponent(search);
    }
    if (hash) {
      url += encodeURIComponent(hash);
    }
    window.location.href = `${config.session.loginRedirectURL}?redirect=${url}`;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return user === null ? <LoadingPage /> : <>{children}</>;
};

export default SessionGateway;
