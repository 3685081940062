export enum RenderMode {
  CANVAS = 'CANVAS',
  WEBGL_LEGACY = 'WEBGL_LEGACY',
  WEBGL_1 = 'WEBGL_1',
  WEBGL_2 = 'WEBGL_2',
  AUTO = 'AUTO',
}

export const RenderModeNames = {
  [RenderMode.CANVAS]: 'Canvas',
  [RenderMode.WEBGL_LEGACY]: 'WebGL',
  [RenderMode.WEBGL_1]: 'WebGL1',
  [RenderMode.WEBGL_2]: 'WebGL2',
  [RenderMode.AUTO]: 'Auto',
};
