import { createContext } from 'react';
import { PlantSearchService, GardenObjectSearchService, PestSearchService } from '@gi/search-service';
import Plant from '@gi/plant';
import GardenObject, { GardenObjectGroup } from '@gi/garden-object';

import Collection from '@gi/collection';
import { Country, CountryCollection } from '@gi/country';
import { PlantingCalendar, UserPlantingCalendars, UserRegionalPlantingCalendar } from '@gi/planting-calendar';
import { Catalogue, SupplierCatalogue, Suppliers } from '@gi/supplier';
import { DistanceUnits } from '@gi/units';
import { Pest } from '@gi/pest';
import { GuruPages } from '@gi/app-guru-types';
import { Store } from '@gi/products';

export type ResourceContextType = {
  userArtifactCode: string;
  useRegionalPlanting: boolean;
  userRegionalPlantingCalendar: UserRegionalPlantingCalendar | null;
  userPlants: Collection<Plant>;
  userGardenObjects: Collection<GardenObject>;
  userGardenObjectGroups: Collection<GardenObjectGroup>;
  userPests: Collection<Pest>;
  userCountry: Country | null;
  userPlantingCalendars: UserPlantingCalendars | null;
  userNorthernHemisphere: boolean;
  userTags: readonly string[];
  countries: CountryCollection;
  catalogue: Catalogue;
  suppliers: Suppliers;
  supplierCatalogue: SupplierCatalogue;
  plantSearchService: PlantSearchService;
  gardenObjectSearchService: GardenObjectSearchService;
  pestSearchService: PestSearchService;
  getPlantingCalendar: (plantCode: string, modifier: null | string, varietyName: string) => PlantingCalendar | null;
  getPlant: (plantCode: string) => Plant | null;
  getPest: (pestCode: string) => Pest | null;
  getGardenObject: (code: string) => GardenObject | null;
  getGardenObjectGroup: (code: string) => GardenObjectGroup | null;
  userDistanceUnits: DistanceUnits;
  guruPages: GuruPages | null;
  store: Store | null;
};

const ResourceContext = createContext<ResourceContextType>({} as ResourceContextType);

export default ResourceContext;
