import React from 'react';

import SmallPreview from '../small-preview';
import SmallPreviewTitle from '../small-preview-title';
import SmallPreviewContent from '../small-preview-content';
import SmallPreviewTags from '../small-preview-tags';
import PlaceholderImage from '../../placeholder/placeholder-image';
import PlaceholderText from '../../placeholder/placeholder-text';

interface iProps {
  className?: string;
}

const SmallPreviewLoading = ({ className = '' }: iProps): JSX.Element => {
  return (
    <SmallPreview className={className}>
      <SmallPreviewContent>
        <PlaceholderImage />
      </SmallPreviewContent>
      <SmallPreviewTitle>
        <PlaceholderText minWidth={0.5} maxWidth={0.9} />
      </SmallPreviewTitle>
      <SmallPreviewTags tags={[]} />
    </SmallPreview>
  );
};

export default SmallPreviewLoading;
