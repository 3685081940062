export enum GuruDirectoryPageId {
  RootDirectory = 'root-directory',
  PlantDirectory = 'plant-directory',
  PestDirectory = 'pest-directory',
  ArticleDirectory = 'article-directory',
  VideoDirectory = 'video-directory',
  BookmarkDirectory = 'bookmark-directory',
  SearchDirectory = 'search-directory',
}

export type GuruPage = {
  name: string;
  id: number;
  url: string;
  pageId: GuruDirectoryPageId;
};

/**
 * Provides a mapping for the API page Id to the internal GuruDirectoryPageID and back
 *
 * Keeps the parsed pages data it was constructed from but is currently not used
 */
export type GuruPages = {
  pages: GuruPage[];
  pageIdMap: Record<GuruDirectoryPageId, number>;
  numericIdMap: Record<number, GuruDirectoryPageId>;
};

/**
 * Maps the provided 'name' from the API to an internal GuruDirectoryPageId
 *
 * This needs to be kept updated if new pages are added or the identifier name
 * on the server changes.
 *
 * Richard Card manages this on the server as part of the Guru Database
 */
export const GuruPageNames: Record<string, GuruDirectoryPageId> = {
  guru: GuruDirectoryPageId.RootDirectory,
  articles: GuruDirectoryPageId.ArticleDirectory,
  videos: GuruDirectoryPageId.VideoDirectory,
  plants: GuruDirectoryPageId.PlantDirectory,
  pests: GuruDirectoryPageId.PestDirectory,
  bookmarks: GuruDirectoryPageId.BookmarkDirectory,
  search: GuruDirectoryPageId.SearchDirectory,
} as const;

export const GuruDirectoryPageIds = [
  GuruDirectoryPageId.RootDirectory,
  GuruDirectoryPageId.PlantDirectory,
  GuruDirectoryPageId.PestDirectory,
  GuruDirectoryPageId.ArticleDirectory,
  GuruDirectoryPageId.VideoDirectory,
  GuruDirectoryPageId.BookmarkDirectory,
  GuruDirectoryPageId.SearchDirectory,
] as const;

export function getDirectoryPageId(pageName: string): GuruDirectoryPageId | null {
  const lcPageName = pageName.toLowerCase();
  if (GuruPageNames[lcPageName]) {
    return GuruPageNames[lcPageName];
  }

  return null;
}
