import React from 'react';

import { SearchDisplayMode, SearchResult } from '@gi/search-service';

import styles from './content-grid-result-text.module.css';

interface iProps {
  result: SearchResult<any>;
  primaryText: string;
}

/**
 * Creates a block of text representing the search result (highlighting the matched text).
 */
const ContentGridResultText = ({ result, primaryText }: iProps): JSX.Element => {
  switch (result.displayMode) {
    case SearchDisplayMode.Primary:
      return <div className={styles.result}>{result.getHtml()}</div>;
    case SearchDisplayMode.Secondary:
      return (
        <div className={styles.result}>
          <span>{primaryText}</span>
          <span className={styles.secondary}>{result.getHtml()}</span>
        </div>
      );
    case SearchDisplayMode.Hidden:
    default:
      return <div className={styles.result}>{primaryText}</div>;
  }
};

export default ContentGridResultText;
