import React from 'react';

import styles from './small-preview-tags.module.css';

interface iProps {
  tags: string[];
}

const SmallPreviewTags = ({ tags }: iProps): JSX.Element => {
  return (
    <div className={styles.smallPreviewTags}>
      {tags.map((tag) => (
        <div key={tag} className={styles.smallPreviewTag}>
          {tag}
        </div>
      ))}
    </div>
  );
};

export default SmallPreviewTags;
