import React, { ReactNode, useContext } from 'react';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';

import { LocalMenuDropdownContext } from './menu-dropdown';

import styles from './menu-dropdown.module.css';

interface iProps {
  className?: string;
  children?: ReactNode;
  transitionDuration?: number;
}

const MenuDropdownContent = ({ className, children }: iProps): JSX.Element | null => {
  const { refs, open, getDropdownProps, dropdownStyles, context } = useContext(LocalMenuDropdownContext);

  if (!open) {
    return null;
  }

  const classNames: string[] = [styles.dropdownContent];
  if (className) {
    classNames.push(className);
  }

  return (
    <FloatingPortal>
      <FloatingFocusManager context={context} modal={false}>
        <div ref={refs.setFloating} {...getDropdownProps({ className: classNames.join(' '), style: dropdownStyles })}>
          {children}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  );
};

export default MenuDropdownContent;
