import React, { CSSProperties, ReactNode } from 'react';

import './selectable-button.scss';

interface iProps {
  active?: boolean;
  onClick: () => void;
  ariaLabel?: string;
  src: string;
  alt: string;
  text: ReactNode;
  iconScale?: number;
}

const SelectableButton = ({ active, onClick, ariaLabel, src, alt, text, iconScale = 1 }: iProps): JSX.Element => {
  const classNames = ['selectable-button-mobile'];
  if (active) {
    classNames.push('selected');
  }

  const styles = {
    '--icon-scale': `${iconScale * 100}%`,
  } as CSSProperties;

  return (
    <button type='button' aria-label={ariaLabel} className={classNames.join(' ')} style={styles} onClick={() => onClick()}>
      <span className='icon'>
        <img src={src} alt={alt} />
      </span>
      <span className='text'>{text}</span>
    </button>
  );
};

export default SelectableButton;
