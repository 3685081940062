import React from 'react';
import ReactSelect from 'react-select';

import { DeviceDisplayMode } from '@gi/constants';
import FormField, { FormSection, InputContainer, FormValuesField } from '@gi/form-responsive';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

interface iProps {
  deviceDisplayModeField: FormValuesField<DeviceDisplayMode>;
  setDeviceDisplayMode: (mode: DeviceDisplayMode) => void;
}

interface DeviceDisplayModeOption {
  value: DeviceDisplayMode;
  label: string;
}

const deviceDisplaytModeOptions: DeviceDisplayModeOption[] = [
  { value: DeviceDisplayMode.AUTO, label: 'Automatic' },
  { value: DeviceDisplayMode.SUGGEST, label: 'Automatic, ask before switching' },
  { value: DeviceDisplayMode.DESKTOP, label: 'Always Desktop' },
  { value: DeviceDisplayMode.MOBILE, label: 'Always Mobile' },
];

const deviceDisplayModeValues = {
  [DeviceDisplayMode.AUTO]: deviceDisplaytModeOptions[0],
  [DeviceDisplayMode.SUGGEST]: deviceDisplaytModeOptions[1],
  [DeviceDisplayMode.DESKTOP]: deviceDisplaytModeOptions[2],
  [DeviceDisplayMode.MOBILE]: deviceDisplaytModeOptions[3],
};

const DisplaySettings = ({ deviceDisplayModeField, setDeviceDisplayMode }: iProps): JSX.Element => {
  return (
    <FormSection heading='Device Display Settings' padding={12} gap={6} className='form-section-background'>
      <FormField
        label='Device Display Mode'
        helpText='This controls how the Garden Planner is displayed on your device. You can use this to force the Garden Planner to use mobile or desktop mode.'
        htmlFor='device-layout-mode-input'
        desktopLayout={{ labelSize: 135 }}
      >
        <InputContainer size='full'>
          <ReactSelect<DeviceDisplayModeOption>
            styles={DEFAULT_SELECT_STYLES}
            options={deviceDisplaytModeOptions}
            value={deviceDisplayModeValues[deviceDisplayModeField.value]}
            onChange={(selected) => {
              if (selected !== null) {
                setDeviceDisplayMode(selected.value);
              }
            }}
            isSearchable={false}
            menuPortalTarget={document.body}
            id='device-layout-mode-input'
          />
        </InputContainer>
      </FormField>
    </FormSection>
  );
};

export default DisplaySettings;
