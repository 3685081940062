import { Filters } from '@gi/filters';
import { createContext } from 'react';

import { DISPLAY_TYPE } from '../constants';
import INITIAL_AREA_FILTERS, { PlantListAreaGroupFiltersType } from '../filters/area-group-filters/area-group-filters';
import INITIAL_SUMMARY_FILTERS, { PlantListSummaryGroupFiltersType } from '../filters/summary-group-filters/summary-group-filters';
import INITIAL_VARIETY_FILTERS, { PlantListVarietyGroupFiltersType } from '../filters/variety-group-filters/variety-group-filters';
import { PlantListAreaGroup, PlantListOptions, PlantListSummaryGroup, PlantListVarietyGroup, PlantListColumns, TableViewMode } from '../types/plant-list-types';

export type GlobalPlantListContextType = {
  expandFilters: boolean;
  setExpandFilters: (expand: boolean) => void;
  columns: PlantListColumns;
  setColumnVisibility: (key: keyof PlantListColumns, value: boolean) => void;
  displayType: DISPLAY_TYPE;
  setDisplayType: (displayType: DISPLAY_TYPE) => void;
  options: PlantListOptions;
  setOptions: (options: Partial<PlantListOptions>) => void;
  plantGroupFilters: Filters<PlantListSummaryGroup, PlantListSummaryGroupFiltersType>;
  setPlantGroupFilters: (filters: Filters<PlantListSummaryGroup, PlantListSummaryGroupFiltersType>) => void;
  varietyGroupFilters: Filters<PlantListVarietyGroup, PlantListVarietyGroupFiltersType>;
  setVarietyGroupFilters: (filters: Filters<PlantListVarietyGroup, PlantListVarietyGroupFiltersType>) => void;
  areaGroupFilters: Filters<PlantListAreaGroup, PlantListAreaGroupFiltersType>;
  setAreaGroupFilters: (filters: Filters<PlantListAreaGroup, PlantListAreaGroupFiltersType>) => void;
  resetFilters: () => void;
};

const GlobalPlantListContext = createContext<GlobalPlantListContextType>({
  expandFilters: false,
  setExpandFilters: () => {},
  columns: {
    area: true,
    calendar: true,
    counts: true,
    notes: true,
    plantLabel: true,
    spacings: true,
    inGroundDates: true,
  },
  setColumnVisibility: () => {},
  displayType: DISPLAY_TYPE.Variety,
  setDisplayType: () => {},
  options: {
    textPlantingCalendars: false,
    showScientificName: false,
    expandPlantAreas: false,
    showPlantFamily: true,
    printBackgroundColors: false,
    viewMode: TableViewMode.AUTO,
  },
  setOptions: () => {},
  plantGroupFilters: INITIAL_SUMMARY_FILTERS,
  setPlantGroupFilters: () => {},
  varietyGroupFilters: INITIAL_VARIETY_FILTERS,
  setVarietyGroupFilters: () => {},
  areaGroupFilters: INITIAL_AREA_FILTERS,
  setAreaGroupFilters: () => {},
  resetFilters: () => {},
});

export default GlobalPlantListContext;
