import { State, StateDef } from '@gi/state';

// eslint-disable-next-line import/no-cycle
import SelectableComponent from './selectable-component';
import Node, { NodeEvent } from '../../node';
import NodeComponent, { NodeComponentEventActions } from '../../node-component/node-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import EventBus from '../../event-bus';
import Selection from './utils';
import { bindState } from '../../utils/state-utils';

export enum SelectableComponentContextEvent {
  OnSelectionChanged = 'OnSelectionChange',
  OnPreSelectionChanged = 'OnPreSelectionChanged',
}

export type SelectableComponentContextEventActions = NodeComponentEventActions & {
  [SelectableComponentContextEvent.OnSelectionChanged]: (selection: Node[], added: Node[], removed: Node[]) => void;
  [SelectableComponentContextEvent.OnPreSelectionChanged]: (preSelection: Node[], added: Node[], removed: Node[]) => void;
};

type SelectableComponentContextState = StateDef<
  object,
  [SelectableComponentContextEvent.OnSelectionChanged, SelectableComponentContextEvent.OnPreSelectionChanged]
>;

/**
 * The global state of SelectableComponent within the engine
 * Acts as a singleton for SelectableComponent on the engine.
 */
class SelectableComponentContext extends NodeComponent {
  type = 'SelectableComponentContext';

  readonly eventBus: EventBus<SelectableComponentContextEventActions> = new EventBus(this.eventBus);

  #selection: Selection<Node> = new Selection(
    (n) => n.uuid,
    (n) => this.canSelect(n)
  );
  #preSelection: Selection<Node> = new Selection(
    (n) => n.uuid,
    (n) => this.canSelect(n)
  );

  #filters: Record<string, (node: Node) => boolean> = {};
  #filterNames: string[] = [];
  #bypassFilters: boolean = false;

  #onDestroyHandlers: Record<string, () => void> = {};
  #state: State<SelectableComponentContextState>;

  constructor() {
    super();

    this.#state = new State({});
    bindState(this.#state, this);

    // Batch selection change events in line with the state system, to prevent unnecessary events
    this.#state.addUpdater(
      (state) => {
        if (state.changed.signals[SelectableComponentContextEvent.OnSelectionChanged]) {
          this.#emitSelectionChangeEvent();
        }
        if (state.changed.signals[SelectableComponentContextEvent.OnPreSelectionChanged]) {
          this.#emitPreSelectionChangeEvent();
        }
      },
      {
        signals: [SelectableComponentContextEvent.OnSelectionChanged, SelectableComponentContextEvent.OnPreSelectionChanged],
      }
    );
  }

  canSelect(node: Node): boolean {
    return this.#bypassFilters || this.#filterNames.every((name) => this.#filters[name](node));
  }

  isInSelection(node: Node) {
    return this.#selection.has(node);
  }

  addToSelection(...nodes: Node[]) {
    const added = this.#selection.add(...nodes);
    this.#commitSelectionChanges(added, []);
    this.#queueSelectionChangeEvent(added, []);
  }

  removeFromSelection(...nodes: Node[]) {
    const removed = this.#selection.remove(...nodes);
    this.#commitSelectionChanges([], removed);
    this.#queueSelectionChangeEvent([], removed);
  }

  toggleInSelection(...nodes: Node[]) {
    const diff = this.#selection.toggle(...nodes);
    this.#commitSelectionChanges(diff.added, diff.removed);
    this.#queueSelectionChangeEvent(diff.added, diff.removed);
  }

  setSelection(nodes: Node[]) {
    const diff = this.#selection.set(nodes);
    this.#commitSelectionChanges(diff.added, diff.removed);
    this.#queueSelectionChangeEvent(diff.added, diff.removed);
  }

  filterSelection(filterFunc: (node: Node) => boolean) {
    const filteredNodes = this.#selection.items.filter(filterFunc);
    this.setSelection(filteredNodes);
  }

  setFilter(name: string, filterFunc: (node: Node) => boolean) {
    this.#filters[name] = filterFunc;
    if (!this.#filterNames.includes(name)) {
      this.#filterNames.push(name);
    }
    this.filterSelection(filterFunc);
  }

  clearFilter(name: string) {
    delete this.#filters[name];
    this.#filterNames = this.#filterNames.filter((n) => n !== name);
  }

  clearSelection() {
    this.setSelection([]);
  }

  isInPreSelection(node: Node) {
    return this.#preSelection.has(node);
  }

  addToPreSelection(...nodes: Node[]) {
    const added = this.#preSelection.add(...nodes);
    this.#commitPreSelectionChanges(added, []);
    this.#queuePreSelectionChangeEvent(added, []);
  }

  removeFromPreSelection(...nodes: Node[]) {
    const removed = this.#preSelection.remove(...nodes);
    this.#commitPreSelectionChanges([], removed);
    this.#queuePreSelectionChangeEvent([], removed);
  }

  toggleInPreSelection(...nodes: Node[]) {
    const diff = this.#preSelection.toggle(...nodes);
    this.#commitPreSelectionChanges(diff.added, diff.removed);
    this.#queuePreSelectionChangeEvent(diff.added, diff.removed);
  }

  setPreSelection(nodes: Node[]) {
    const diff = this.#preSelection.set(nodes);
    this.#commitPreSelectionChanges(diff.added, diff.removed);
    this.#queuePreSelectionChangeEvent(diff.added, diff.removed);
  }

  clearPreSelection() {
    this.setPreSelection([]);
  }

  selectPreSelected(addToSelection: boolean = false) {
    const selectionDiff = addToSelection
      ? { added: this.#selection.add(...this.#preSelection.items), removed: [] }
      : this.#selection.set([...this.#preSelection.items]);
    const preSelectionDiff = this.#preSelection.set([]);

    this.#commitSelectionChanges(selectionDiff.added, selectionDiff.removed);
    this.#commitPreSelectionChanges(preSelectionDiff.added, preSelectionDiff.removed);

    this.#queueSelectionChangeEvent(selectionDiff.added, selectionDiff.removed);
    this.#queuePreSelectionChangeEvent(preSelectionDiff.added, preSelectionDiff.removed);
  }

  /**
   * Runs the given function, while disabling filters.
   * Use if you want to add a node to the selection that is currently being filtered out.
   * @param func The function to run while bypassing filters
   */
  bypassFilters(func: () => void) {
    try {
      this.#bypassFilters = true;
      func();
    } finally {
      this.#bypassFilters = false;
    }
  }

  /**
   * Re-runs the filter function on the selection, removing any nodes that no longer meet the criteria.
   */
  reapplyFilters() {
    const removed = this.#selection.filter();
    const preRemoved = this.#preSelection.filter();

    this.#commitSelectionChanges([], removed);
    this.#commitPreSelectionChanges([], preRemoved);

    this.#queueSelectionChangeEvent([], removed);
    this.#queuePreSelectionChangeEvent([], preRemoved);
  }

  #commitSelectionChanges(added: Node[], removed: Node[]) {
    if (added.length === 0 && removed.length === 0) {
      return;
    }

    for (let i = 0; i < Math.max(added.length, removed.length); i++) {
      if (added[i]) {
        const selection = added[i].components.get(SelectableComponent);
        selection?.setIsSelected(true);
        this.#onDestroyHandlers[added[i].uuid] =
          this.#onDestroyHandlers[added[i].uuid] ||
          (() => {
            this.removeFromSelection(added[i]);
          });
        added[i].eventBus.on(NodeEvent.Destroyed, this.#onDestroyHandlers[added[i].uuid]);
      }
      if (removed[i]) {
        const selection = removed[i].components.get(SelectableComponent);
        selection?.setIsSelected(false);
        const handler = this.#onDestroyHandlers[removed[i].uuid];
        if (handler) {
          removed[i].eventBus.off(NodeEvent.Destroyed, handler);
        }
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  #commitPreSelectionChanges(added: Node[], removed: Node[]) {
    if (added.length === 0 && removed.length === 0) {
      return;
    }

    for (let i = 0; i < Math.max(added.length, removed.length); i++) {
      if (added[i]) {
        const selection = added[i].components.get(SelectableComponent);
        selection?.setIsPreSelected(true);
      }
      if (removed[i]) {
        const selection = removed[i].components.get(SelectableComponent);
        selection?.setIsPreSelected(false);
      }
    }
  }

  #queueSelectionChangeEvent(added: Node[], removed: Node[]) {
    if (added.length === 0 && removed.length === 0) {
      return;
    }
    this.#state.triggerSignal(SelectableComponentContextEvent.OnSelectionChanged);
  }

  #queuePreSelectionChangeEvent(added: Node[], removed: Node[]) {
    if (added.length === 0 && removed.length === 0) {
      return;
    }
    this.#state.triggerSignal(SelectableComponentContextEvent.OnPreSelectionChanged);
  }

  /**
   * Skips waiting for the state system and emits selection change events now.
   * Cancels the signal in the state to prevent emitting duplicate events later
   */
  manualFlushUpdates() {
    if (this.#state.nextChanged.signals[SelectableComponentContextEvent.OnSelectionChanged]) {
      this.#state.cancelSignal(SelectableComponentContextEvent.OnSelectionChanged);
      this.#emitSelectionChangeEvent();
    }
    if (this.#state.nextChanged.signals[SelectableComponentContextEvent.OnPreSelectionChanged]) {
      this.#state.cancelSignal(SelectableComponentContextEvent.OnPreSelectionChanged);
      this.#emitPreSelectionChangeEvent();
    }
  }

  #emitSelectionChangeEvent() {
    const { added, removed } = this.#selection.getDiff();
    this.eventBus.emit(SelectableComponentContextEvent.OnSelectionChanged, [...this.#selection.items], added, removed);
  }

  #emitPreSelectionChangeEvent() {
    const { added, removed } = this.#preSelection.getDiff();
    this.eventBus.emit(SelectableComponentContextEvent.OnPreSelectionChanged, [...this.#preSelection.items], added, removed);
  }

  #allSelectableNodes: Node[] = [];
  get allSelectableNodes() {
    return this.#allSelectableNodes;
  }

  get selection() {
    return this.#selection.items;
  }

  get preSelection() {
    return this.#preSelection.items;
  }

  /**
   * Registers a node as selectable.
   *  This doesn't need to be done to make the node selectable, but allows the node to be selected
   *  by things like the area select tool.
   * @param node The node that is selectable
   */
  registerAsSelectable(node: Node) {
    if (!this.allSelectableNodes.includes(node)) {
      this.allSelectableNodes.push(node);
    }
  }

  /**
   * Unregisters the given node as being selectable.
   *  The node will still remain selectable as long as it has a SelectableComponent attached,
   *  but will no longer be selected things like the area select tool.
   * @param node The node that was registered previously as selectable
   */
  unregisterAsSelectable(node: Node) {
    const index = this.allSelectableNodes.indexOf(node);
    if (index !== -1) {
      this.allSelectableNodes.splice(index, 1);
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'selection',
      propertyType: InspectableClassPropertyType.Node,
    },
    {
      type: InspectableClassDataType.Action,
      displayName: 'Output to Console',
      callback: () => console.log(this.selection),
    },
  ];
}

export default SelectableComponentContext;
