import { Map, Record, List } from 'immutable';

const _JournalEntries = {
  byDate: new Map(),
  dates: new List(),
  byID: new Map(),
  IDs: new List(),
  byPlantCode: new Map(),
};

class JournalEntries extends Record(_JournalEntries) {
  /**
   * Returns an array of Entries for the given day
   *
   * @param {string} dateYMD
   * @returns {JournalEntry[]}
   * @memberof Journal
   */
  getEntriesForDate(dateYMD) {
    if (!this.hasDate(dateYMD)) {
      return [];
    }

    return this.byDate
      .get(dateYMD)
      .map((ID) => this.byID.get(ID))
      .toArray();
  }

  /**
   * Returns the number of entries in this collection
   *
   * @returns {number}
   * @memberof Journal
   */
  getCount() {
    return this.IDs.size;
  }

  /**
   * Returns true if a JournalEntry with the given ID is present in this collection, else false
   *
   * @param {number} ID
   * @returns {JournalEntries}
   * @memberof Journal
   */
  hasID(ID) {
    return this.IDs.contains(ID);
  }

  /**
   * Returns true if this set of entries has entries for the given plantCode
   *
   * @param {string} plantCode
   * @returns {boolean}
   * @memberof Journal
   */
  hasPlantCode(plantCode) {
    return this.byPlantCode.has(plantCode);
  }

  /**
   * Returns true if this set of entries has entries for the given day
   *
   * @param {string} dateYMD
   * @returns {boolean}
   * @memberof Journal
   */
  hasDate(dateYMD) {
    return this.byDate.has(dateYMD);
  }

  /**
   * Returns a new JournalEntries instance with this entry added
   *
   * @param {JournalEntry} entry
   * @returns {JournalEntries}
   * @memberof Journal
   */
  addEntry(entry) {
    let me = this;

    entry.relatedPlants.forEach((plantCode) => {
      me = me.setIn(['byPlantCode', entry.plantCode], (me.byPlantCode.get(plantCode) || new List()).push(entry.ID));
    });

    if (this.hasDate(entry.dateYMD)) {
      return this.setIn(['byDate', entry.dateYMD], this.byDate.get(entry.dateYMD).push(entry.ID))
        .setIn(['byID', entry.ID], entry)
        .set('IDs', this.IDs.push(entry.ID));
    }

    return me
      .setIn(['byDate', entry.dateYMD], new List([entry.ID]))
      .setIn(['byID', entry.ID], entry)
      .set('IDs', this.IDs.push(entry.ID))
      .set('dates', this.dates.push(entry.dateYMD));
  }

  /**
   * Removes an entry from the collection with the given ID
   *
   * @param {number} ID
   * @returns {JournalEntries}
   * @memberof Journal
   */
  removeEntry(ID) {
    if (!this.hasID(ID)) {
      console.warn("Asked to remove entry from collection but it wasn't present");
      return this;
    }

    const entry = this.byID.get(ID);
    const IDIndex = this.IDs.indexOf(ID);
    const countForDate = this.byDate.get(entry.dateYMD).size;

    if (countForDate <= 1) {
      const dateIndex = this.dates.indexOf(entry.dateYMD);
      // There's only one entry for this date so we need to remove this date too
      return this.set('IDs', this.IDs.delete(IDIndex))
        .set('byID', this.byID.delete(ID))
        .set('dates', this.dates.delete(dateIndex))
        .set('byDate', this.byDate.delete(entry.dateYMD));
    }

    const byDateIndex = this.byDate.get(entry.dateYMD).indexOf(entry.ID);

    return this.set('IDs', this.IDs.delete(IDIndex))
      .set('byID', this.byID.delete(ID))
      .setIn(['byDate', entry.dateYMD], this.byDate.get(entry.dateYMD).delete(byDateIndex));
  }

  /**
   * Returns a new JournalEntries collection with the entry added or updated if not already present
   *
   * @param {JournalEntry} entry
   * @returns {JournalEntries}
   * @memberof Journal
   */
  updateEntry(entry) {
    if (this.IDs.includes(entry.ID)) {
      // It's easiest to remove the old entry and add the new one
      // We can add some checks in future to see if they're the same
      return this.removeEntry(entry.ID).addEntry(entry);
    }

    return this.addEntry(entry);
  }

  /**
   * Returns an array of date YMD strings used in this set of entries
   *
   * @returns {string[]}
   * @memberof Journal
   */
  getDatesArray() {
    return this.dates.toJS();
  }
}

export default JournalEntries;
