import { batchActions } from 'redux-batched-actions';
import { Application, NavigationActionCreators } from '@gi/garden-platform-navigation';
import { SidebarActionCreators, SidebarPosition } from '@gi/garden-platform-sidebar';
import { CanvasActionCreators } from '@gi/react-garden-canvas';
import { OpenPlanModalActionCreators } from './open-plan-modal-slice';

// TODO - change sidebar position change to be a side-effect on the sidebar itself (i.e. the sidebar should watch for open plan changes)
// TODO - change setting active platform tab to also be a side-effect somewhere (possibly in platform, possibly in the main garden planner app module)

export const openPlanModalOpenPlan = (planID: number) => {
  return (dispatch) => {
    dispatch(
      batchActions([
        CanvasActionCreators.openPlan(planID),
        NavigationActionCreators.navigateTo({ application: Application.GardenPlanner }),
        SidebarActionCreators.setSidebarPosition(SidebarPosition.Minimised),
        OpenPlanModalActionCreators.close(),
      ])
    );
  };
};

export const openNewPlanModal = (activePlanID: number | null = null) => {
  return {
    type: 'OPEN_NEW_PLAN_MODAL',
    newPlanModalActivePlan: activePlanID,
  };
};
