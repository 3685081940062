import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ResourceContext } from '@gi/resource-provider';
import { getDistanceUnitsFromIsMetric } from '@gi/units';
import { CanvasSelectors } from '@gi/react-garden-canvas';

import LocalPlantListContext from '../local-provider/local-plant-list-context';
import PlantListByAreaCard from './plant-list-by-area-card';

const PlantListByArea = (): JSX.Element => {
  const { filteredAreaGroupData } = useContext(LocalPlantListContext);
  const { userDistanceUnits } = useContext(ResourceContext);

  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  const distanceUnits = activePlan ? getDistanceUnitsFromIsMetric(activePlan.plannerSettings.metric) : userDistanceUnits;

  return (
    <>
      {filteredAreaGroupData.map((plantListRowGroup) => (
        <PlantListByAreaCard key={plantListRowGroup.planPlant.id} plantListRowGroup={plantListRowGroup} distanceUnits={distanceUnits} />
      ))}
    </>
  );
};

export default PlantListByArea;
