import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import GardenObjectInfoModal from '@gi/garden-object-information-modal';
import { closeGardenObjectInfoModal } from '../modal-action-creators.ts';

/**
 * @param {{
 *  closeModal: () => void,
 *   gardenObject?: GardenObject | null
 * }}
 */
const GardenObjectInfoModalRenderer = ({ gardenObject = null, closeModal }) => {
  if (gardenObject === null) {
    return null;
  }

  return <GardenObjectInfoModal gardenObject={gardenObject} closeModal={closeModal} />;
};

GardenObjectInfoModalRenderer.propTypes = {
  closeModal: PropTypes.func.isRequired,
  gardenObject: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    gardenObject: state.modal.gardenObjectInfoModalGardenObject,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: closeGardenObjectInfoModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GardenObjectInfoModalRenderer);
