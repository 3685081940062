import React, { useContext, useState } from 'react';

import { HelpButton } from '@gi/base-components';
import { PlantSpacingHelpModal } from '@gi/help-modals';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

const SpacingsHeaderCell = () => {
  const { columns } = useContext(GlobalPlantListContext);
  const [showHelpModal, setShowHelpModal] = useState(false);

  if (!columns.spacings) {
    return null;
  }

  return (
    <th className='header-cell left-align-cell'>
      {showHelpModal ? <PlantSpacingHelpModal closeModal={() => setShowHelpModal(false)} /> : null}
      <div className='header-cell-content'>
        <div className='header-row'>
          Spacings <HelpButton onClick={() => setShowHelpModal(true)} />
        </div>
      </div>
    </th>
  );
};

export default SpacingsHeaderCell;
