import Quadtree, { QuadtreeItem } from 'quadtree-lib';

import { Geometry } from '@gi/math';
import Bitmask, { BitmaskType } from '@gi/bitmask';
import { State, StateDef, StateObserver, StateProperties } from '@gi/state';

import NodeComponent from '../../node-component/node-component';
import { Bounds, InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { bindState } from '../../utils/state-utils';
import ManipulatableComponentContext, { ManipulatableComponentContextState } from '../manipulatable/manipulatable-component-context';
import DraggableComponentContext, { DraggableComponentContextState } from '../draggable/draggable-component-context';
import type ShapeComponent from './shape-component';
import TransformComponent from '../transform/transform-component';
import { ShapeFlag } from './types';

export interface QuadtreeItemWithShape extends QuadtreeItem {
  shapeComponent: ShapeComponent;
}

export type ShapeComponentContextState = StateDef<
  {
    hasQuadtreeUpdated: boolean;
    hasShapeChanged: boolean;
  },
  [],
  {
    draggable: DraggableComponentContextState;
    manipulatable: ManipulatableComponentContextState;
  }
>;

const DEFAULT_STATE: ShapeComponentContextState['state'] = {
  hasQuadtreeUpdated: false,
  hasShapeChanged: false,
};

type ShapeComponentData = {
  component: ShapeComponent;
  watcher: StateObserver<any>;
  rect: QuadtreeItemWithShape;
};

class ShapeComponentContext extends NodeComponent {
  type = 'ShapeComponentContext';

  #MAX_ELEMENTS: number = 10;

  #components: ShapeComponent[] = [];
  #componentData: Record<string, ShapeComponentData> = {};

  #quadtree: Quadtree<QuadtreeItemWithShape>;

  readonly state: State<ShapeComponentContextState>;

  constructor() {
    super();

    this.#quadtree = new Quadtree({
      width: 2000,
      height: 2000,
      maxElements: this.#MAX_ELEMENTS,
    });

    this.state = new State({ ...DEFAULT_STATE });
    bindState(this.state, this);

    this.state.addUpdater(
      (state) => {
        const { hasShapeChanged, hasQuadtreeUpdated } = state.values;
        if (hasQuadtreeUpdated) {
          this.state.values.hasQuadtreeUpdated = false;
        }
        if (state.get('draggable', 'dragging') || state.get('manipulatable', 'manipulating')) {
          return;
        }
        if (hasShapeChanged) {
          this.state.values.hasShapeChanged = false;
        }
      },
      {
        properties: ['hasShapeChanged', 'hasQuadtreeUpdated'],
        otherStates: {
          draggable: { properties: ['dragging'] },
          manipulatable: { properties: ['manipulating'] },
        },
      }
    );

    this.state.addUpdater(
      (state) => {
        const isDragging = state.get('draggable', 'dragging');
        const isManipulating = state.get('manipulatable', 'manipulating');
        const { hasShapeChanged } = state.changed.properties;
        if (isDragging || isManipulating) {
          return;
        }
        if (hasShapeChanged) {
          state.values.hasQuadtreeUpdated = true;
        }
      },
      {
        properties: ['hasShapeChanged'],
        otherStates: {
          draggable: { properties: ['dragging'] },
          manipulatable: { properties: ['manipulating'] },
        },
      }
    );

    this.#connectStates();
  }

  /**
   * Registers the given visibility component to be considered in visibility calculations.
   * @param component The visibility component to register
   */
  register(component: ShapeComponent) {
    const rect = this.#getShapeProperties(component);

    this.#quadtree.push(rect);
    this.#components.push(component);
    this.#componentData[component.uuid] = {
      rect,
      component,
      watcher: this.#createShapeWatcher(component),
    };
  }

  /**
   * Unregisters the given visibility component from being considered in visibility calculations.
   * @param component The visibility component to unregister
   */
  unregister(component: ShapeComponent) {
    const index = this.#components.indexOf(component);
    if (index !== -1) {
      this.#components.splice(index, 1);
    }

    const { watcher, rect } = this.#componentData[component.uuid];
    this.#quadtree.remove(rect);
    watcher.destroy();

    delete this.#componentData[component.uuid];
  }

  /**
   * Sets the dimensions of the content, which will be used by the quadtree.
   * @param width The width of the quadtree
   * @param height The height of the quadtree
   */
  setContentSize(width: number, height: number) {
    this.#quadtree = new Quadtree({
      width,
      height,
      maxElements: this.#MAX_ELEMENTS,
    });
    const rects = Object.values(this.#componentData).map((entry) => entry.rect);
    this.#quadtree.pushAll(rects);
  }

  /**
   * Returns a list of shapes that are within/touching the given bounds.
   * Can be limited to certain types using a bitwise mask (e.g. `ShapeFlag.PLANT | ShapeFlag.GARDEN_OBJECT`)
   * @param bounds The bounds within which to find collisions
   * @param mask The mask to use to limit collisions
   * @returns All the shapes that pass the collision check
   */
  getCollisions(bounds: Bounds, mask: BitmaskType<ShapeFlag> = Bitmask.ALL.value) {
    const collisions = this.#quadtree
      .colliding({
        x: bounds.left,
        y: bounds.top,
        width: bounds.right - bounds.left,
        height: bounds.bottom - bounds.top,
      })
      .filter(({ shapeComponent }) => {
        return Bitmask.ContainsAny(shapeComponent.state.values.flags, mask);
      });
    return collisions;
  }

  // eslint-disable-next-line class-methods-use-this
  #getShapeProperties(shapeComponent: ShapeComponent): QuadtreeItemWithShape {
    const transformComponent = shapeComponent.owner?.components.get(TransformComponent);

    const position = transformComponent?.state.values.position ?? { x: 0, y: 0 };
    const { boundingBox } = shapeComponent.state.values;

    const min = Geometry.addPoint({ x: boundingBox.left, y: boundingBox.top }, position);
    const max = Geometry.addPoint({ x: boundingBox.right, y: boundingBox.bottom }, position);

    return {
      x: min.x,
      y: min.y,
      width: max.x - min.x,
      height: max.y - min.y,
      shapeComponent,
    };
  }

  /**
   * Creates a state watcher on the given component's owner's shape + transform.
   * @param shapeComponent The visibility component of the node
   * @returns The created state watcher, if created
   */
  #createShapeWatcher(shapeComponent: ShapeComponent) {
    const transformComponent = shapeComponent.owner?.components.get(TransformComponent);

    if (transformComponent) {
      return new StateObserver(
        [
          new StateProperties(transformComponent.state, { properties: ['position'] }),
          new StateProperties(shapeComponent.state, { properties: ['boundingBox'] }),
        ] as const,
        false,
        () => {
          const oldRect = this.#componentData[shapeComponent.uuid].rect;
          const newRect = this.#getShapeProperties(shapeComponent);

          this.#componentData[shapeComponent.uuid].rect = newRect;

          this.#quadtree.remove(oldRect);
          this.#quadtree.push(newRect);

          this.state.values.hasShapeChanged = true;
        },
        false
      );
    }

    return new StateObserver(
      [new StateProperties(shapeComponent.state, { properties: ['boundingBox'] })] as const,
      false,
      () => {
        const oldRect = this.#componentData[shapeComponent.uuid].rect;
        const newRect = this.#getShapeProperties(shapeComponent);

        this.#componentData[shapeComponent.uuid].rect = newRect;

        this.#quadtree.remove(oldRect);
        this.#quadtree.push(newRect);

        this.state.values.hasShapeChanged = true;
      },
      false
    );
  }

  /**
   * Connects dependant states, such as manipulation and dragging.
   * These are used to prevent re-calculating visibility during interactions.
   */
  #connectStates() {
    const manipulatable = this.owner?.getContext(ManipulatableComponentContext);
    if (manipulatable) {
      this.state.connectState('manipulatable', manipulatable.state);
    }

    const draggable = this.owner?.getContext(DraggableComponentContext);
    if (draggable) {
      this.state.connectState('draggable', draggable.state);
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default ShapeComponentContext;
