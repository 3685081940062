import React, { useContext, useEffect, useRef, useState } from 'react';

import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';

import ResourceContext from '@gi/resource-provider/source/components/resource-context';
import { AutumnPlantingFilterHelpModal } from '@gi/help-modals';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { Filters } from '@gi/filters';
import { useHelpModalEventHook } from '@gi/garden-platform-events';
import { SessionSelectors } from '@gi/react-session';
import { UserUtils } from '@gi/user';
import Plant from '@gi/plant';

import FilterRow from '../../filters/filter-row';

import TypeFilterView from './type-filter/type-filter-view';
import SearchFilterView from './search-filter/search-filter-view';
import FavouritesFilterView from './favourites-filter/favourites-filter-view';
import CompanionFilterView from './companion-filter/companion-filter-view';
import PlantingDatesFilterView from './planting-dates-filter/planting-dates-filter-view';
import CropFamilyFilterView from './crop-family-filter/crop-family-filter-view';
import SimpleCheckboxFilter from './common/simple-checkbox-filter';
import PerennialCheckboxFilter from './perennial-filter/perennial-checkbox-filter';

import { DrawingToolsContext } from '../../drawing-tools-context';
import { PUNCTUATION_REGEX } from '../../constants';

import './filter-inputs.scss';

const TIMEOUT_TIME = 150;

const getActiveExtraFiltersCount = (filters: Filters<Plant, any>, sfgMode: boolean, tropicalClimate: boolean) => {
  let activeExtraFilters = 0;

  activeExtraFilters += filters.filters.cropFamily.inputs.familyID !== null ? 1 : 0;
  activeExtraFilters += sfgMode && filters.filters.sfg.inputs.enabled ? 1 : 0;

  const plantingCalendarInputs = filters.filters.plantingDates.inputs;
  activeExtraFilters += plantingCalendarInputs.sowMonth !== null ? 1 : 0;
  activeExtraFilters += plantingCalendarInputs.plantMonth !== null ? 1 : 0;
  activeExtraFilters += plantingCalendarInputs.harvestMonth !== null ? 1 : 0;

  activeExtraFilters += filters.filters.easyToGrow.inputs.enabled ? 1 : 0;
  activeExtraFilters += filters.filters.frostTolerant.inputs.enabled ? 1 : 0;
  activeExtraFilters += filters.filters.partialShade.inputs.enabled ? 1 : 0;
  activeExtraFilters += filters.filters.autumn.inputs.enabled ? 1 : 0;

  activeExtraFilters += filters.filters.tropical.inputs.enabled !== tropicalClimate ? 1 : 0;

  return activeExtraFilters;
};

const PlantFilterInputs = (): JSX.Element => {
  const user = useSelector(SessionSelectors.getUser);
  const { plantSearchService } = useContext(ResourceContext);
  const { northernHemisphere, plantFilters, resetPlantFilters, updatePlantFilters, setPlantFilters } = useContext(DrawingToolsContext);
  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);

  const [searchTerm, setSearchTerm] = useState<string>(plantFilters.filters.search.inputs.searchTerm);
  const [lastCommittedSearchTerm, setLastCommittedSearchTerm] = useState<string>(plantFilters.filters.search.inputs.searchTerm);
  const [showExtraFilters, setShowExtraFilters] = useState<boolean>();
  const [showAutumnPlantsFilterModal, setShowAutumnPlantsFilterModal] = useState<boolean>(false);

  const searchTimeout = useRef<ReturnType<typeof setTimeout>>();

  const tropicalClimate = user ? UserUtils.isTropicalClimate(user) : true;

  useEffect(() => {
    // Check if the search term in the filters has updated without us asking to.
    // If so, update our copy of the search term to match (the filter was probably reset).
    const searchTermFromFilter = plantFilters.filters.search.inputs.searchTerm;
    if (searchTermFromFilter !== lastCommittedSearchTerm) {
      setSearchTerm(searchTermFromFilter);
      setLastCommittedSearchTerm(searchTermFromFilter);
    }
  }, [plantFilters.filters.search.inputs.searchTerm]);

  useEffect(() => {
    const searchUpdateTimeout = () => {
      setLastCommittedSearchTerm(searchTerm);
      updatePlantFilters({
        search: {
          searchTerm,
          searchResults: plantSearchService.search(searchTerm.replaceAll(PUNCTUATION_REGEX, '')),
        },
        sort: {
          enabled: searchTerm.length <= 1,
        },
      });
    };

    clearTimeout(searchTimeout.current!);
    searchTimeout.current = setTimeout(searchUpdateTimeout, TIMEOUT_TIME);

    return () => {
      clearTimeout(searchTimeout.current!);
    };
  }, [searchTerm]);

  useHelpModalEventHook('suitable for autumn planting', showAutumnPlantsFilterModal, 'drawing-tools');

  const toggleShowExtraFilters = () => {
    setShowExtraFilters(!showExtraFilters);
  };

  const renderExtraFilters = () => {
    return (
      <AnimateHeight height={showExtraFilters ? 'auto' : 0}>
        <div className='extra-filters'>
          {sfgMode ? (
            <FilterRow narrow>
              <SimpleCheckboxFilter
                filters={plantFilters}
                onFilterChange={setPlantFilters}
                filterKey='sfg'
                className='filter-row-multi-item'
                labelText='Only Show SFG Plants'
              />
            </FilterRow>
          ) : null}
          <FilterRow multiple narrow>
            <SimpleCheckboxFilter
              filters={plantFilters}
              onFilterChange={setPlantFilters}
              filterKey='easyToGrow'
              className='filter-row-multi-item'
              labelText='Easy to Grow'
            />
            <SimpleCheckboxFilter
              filters={plantFilters}
              onFilterChange={setPlantFilters}
              filterKey='frostTolerant'
              className='filter-row-multi-item'
              labelText='Frost Tolerant'
            />
          </FilterRow>
          <FilterRow narrow>
            <SimpleCheckboxFilter
              filters={plantFilters}
              onFilterChange={setPlantFilters}
              filterKey='partialShade'
              className='filter-row-multi-item'
              labelText='Partial Shade Tolerant'
            />
          </FilterRow>
          <FilterRow narrow>
            <SimpleCheckboxFilter
              filters={plantFilters}
              onFilterChange={setPlantFilters}
              filterKey='autumn'
              className='filter-row-multi-item'
              labelText='Suitable for Autumn Planting'
              onHelpClick={() => setShowAutumnPlantsFilterModal(true)}
            />
          </FilterRow>
          <FilterRow narrow>
            <SimpleCheckboxFilter
              filters={plantFilters}
              onFilterChange={setPlantFilters}
              filterKey='tropical'
              className='filter-row-multi-item'
              labelText={tropicalClimate ? 'Hide Tropicals' : 'Include Tropicals'}
              inverted={tropicalClimate}
            />
          </FilterRow>
          <CropFamilyFilterView />
          <PlantingDatesFilterView northernHemisphere={northernHemisphere} />
        </div>
      </AnimateHeight>
    );
  };

  const renderExpandFiltersButton = () => {
    let buttonContent = (
      <span>
        Show Less <i className='icon-angle-double-up' />
      </span>
    );

    if (!showExtraFilters) {
      const activeExtraFiltersCount = getActiveExtraFiltersCount(plantFilters, sfgMode, tropicalClimate);
      let activeText = '';
      if (activeExtraFiltersCount > 0) {
        activeText = `(${activeExtraFiltersCount}) `;
      }
      buttonContent = (
        <span>
          Show More {activeText}
          <i className='icon-angle-double-down' />
        </span>
      );
    }

    return (
      <button type='button' onClick={toggleShowExtraFilters} className='button button-borderless filters-title-button expand-filters-title-button'>
        {buttonContent}
      </button>
    );
  };

  const hideNameAndFaves = plantFilters.filters.companion.inputs.enabled && !showExtraFilters;

  return (
    <>
      <div className='filter-options'>
        <div className='filters-title-bar'>
          <h3 className='filters-title'>Filters</h3>
          <button type='button' onClick={resetPlantFilters} className='button button-borderless filters-title-button'>
            Reset
          </button>
          {renderExpandFiltersButton()}
        </div>
        <div className='filters-scrollpane'>
          {hideNameAndFaves ? null : <SearchFilterView onSearchTermChange={setSearchTerm} searchTerm={searchTerm} />}
          <TypeFilterView />
          <CompanionFilterView />
          {hideNameAndFaves ? null : (
            <FilterRow multiple narrow>
              <FavouritesFilterView />
              <PerennialCheckboxFilter />
            </FilterRow>
          )}
          {renderExtraFilters()}
        </div>
      </div>
      {showAutumnPlantsFilterModal ? <AutumnPlantingFilterHelpModal closeModal={() => setShowAutumnPlantsFilterModal(false)} /> : null}
    </>
  );
};

export default PlantFilterInputs;
