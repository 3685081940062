import React, { useContext } from 'react';

import { LocalPlantListContext } from '@gi/plant-list';

import usePlantListOptions from './use-plant-list-options';
import MenuDropdownOption from '../../common/dropdown/options/menu-dropdown-option';
import MenuDropdownContent from '../../common/dropdown/menu-dropdown-content';
import MenuDropdownSection from '../../common/dropdown/menu-dropdown-section';
import MenuDropdownSectionTitle from '../../common/dropdown/menu-dropdown-section-title';
import MenuDropdownCheckboxOption from '../../common/dropdown/options/menu-dropdown-checkbox-option';

/** Dropdown content for exporting the plant list */
const PlantListExportDropdownContent = (): JSX.Element => {
  const { createCSV, printPlantList } = useContext(LocalPlantListContext);
  const { setOption, getOption } = usePlantListOptions();

  return (
    <MenuDropdownContent>
      <MenuDropdownSection>
        <MenuDropdownSectionTitle>Print</MenuDropdownSectionTitle>
        <MenuDropdownOption icon='icon-print' onClick={printPlantList}>
          Print
        </MenuDropdownOption>
        <MenuDropdownCheckboxOption value={getOption('printBackgroundColors')} onChange={setOption('printBackgroundColors')}>
          Print Background Colours
        </MenuDropdownCheckboxOption>
      </MenuDropdownSection>
      <MenuDropdownSection>
        <MenuDropdownSectionTitle>Export</MenuDropdownSectionTitle>
        <MenuDropdownOption icon='icon-export' onClick={createCSV}>
          Export to CSV
        </MenuDropdownOption>
      </MenuDropdownSection>
    </MenuDropdownContent>
  );
};

export default PlantListExportDropdownContent;
