import React from 'react';

import YouTubeEmbed from '@gi/youtube-embed';
import { PageSection, PageSectionTitle } from '@gi/page-layout';
import { networkConfig } from '@gi/config';
import { useViewLinkEventHook } from '@gi/garden-platform-events';

import introVideoThumb from './intro-video-thumb.png';

const GardenPlannerAbout = (): JSX.Element => {
  const sendLinkViewedEvent = useViewLinkEventHook('app-help-garden-planner-about');

  return (
    <>
      <PageSection>
        <PageSectionTitle>
          <h2>About the Garden Planner</h2>
        </PageSectionTitle>
        <p>
          The Garden Planner is the perfect way to create plans of your growing area and organize your vegetable garden. The online software makes it easy to
          create plans regardless of the size or shape of your growing area. It enables you to calculate how many plants will fit into a space, change the
          design and keep track of plants on your garden layout, making succession planting and crop rotation much simpler. Whether you use raised beds,
          traditional row-based planting, or the Square Foot Gardening method, the Garden Planner has features to make planning and keeping track of your garden
          simple.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionTitle>
          <h2>Garden Planner FAQ & Help Pages</h2>
        </PageSectionTitle>
        <p>
          The Garden Planner FAQ and help pages can be found{' '}
          <a href={networkConfig.helpURL} target='_blank' rel='noreferrer' onClick={() => sendLinkViewedEvent('help-pages')}>
            here
          </a>
          .
        </p>
      </PageSection>
      <PageSection>
        <PageSectionTitle>
          <h2>Garden Planner Introduction Video</h2>
        </PageSectionTitle>
        <div>
          <YouTubeEmbed youTubeID='EYWH8Z3e8cw' thumbnail={introVideoThumb} />
        </div>
      </PageSection>
    </>
  );
};

export default GardenPlannerAbout;
