import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UserPlan } from '@gi/user';
import { SessionSelectors } from '@gi/react-session';
import { IntercomActionCreators } from '@gi/intercom';
import LoadingButton from '@gi/loading-button';

import { SavePlanResult } from '../planner-settings-action-creators';

import styles from './plan-settings-save-error.module.css';

interface PlanEntryProps {
  planId: number;
  plans: Record<string, UserPlan>;
  thisPlan?: boolean;
}

const PlanEntry = ({ planId, plans, thisPlan = false }: PlanEntryProps): JSX.Element | null => {
  if (!plans || !plans[planId]) {
    return null;
  }

  return (
    <div className={styles.plan}>
      <span className={styles.year}>{plans[planId].year}</span>
      <span className={styles.name}>{plans[planId].name}</span>
      {thisPlan ? <span className={styles.thisPlan}>(this plan)</span> : null}
    </div>
  );
};

interface iProps {
  loading?: boolean;
  savePlanResult: SavePlanResult;
  onRetry: () => void;
  onDismiss: () => void;
}

const PlanSettingsSaveError = ({ loading = false, savePlanResult, onRetry, onDismiss }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(SessionSelectors.getUser);

  const openIntercom = useCallback(() => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('plan-settings-save-error-modal'));
  }, []);

  return (
    <div className={styles.container}>
      <p>There was an issue while trying to save the following historical plans:</p>
      <div className={styles.planList}>
        {savePlanResult.savedPlanHistory.failedPlanIds.map((id) => (
          <PlanEntry planId={id} plans={user ? user.plans.map : {}} key={id} />
        ))}
        {/* {!savePlanResult.savedPlan ? <PlanEntry planId={planId} plans={user ? user.plans.map : {}} thisPlan /> : null} */}
      </div>
      <p>
        If the problem persists, you may need to open these plans and manually modify them separately, or{' '}
        <button className='button button-inline intercom-button' type='button' onClick={openIntercom}>
          contact support
        </button>
        .
      </p>
      <div className={styles.buttons}>
        <button type='button' className='button button-secondary' onClick={onDismiss}>
          Dismiss
        </button>
        <LoadingButton type='button' className='button button-primary' onClick={onRetry} loading={loading} disabled={loading}>
          Retry
        </LoadingButton>
      </div>
    </div>
  );
};

export default PlanSettingsSaveError;
