import React, { ReactNode } from 'react';

import './plant-list-card-cell.scss';

interface iProps {
  title: ReactNode;
  children?: ReactNode;
}

const PlantListCardCell = ({ title, children }: iProps): JSX.Element => {
  return (
    // <div className='plant-list-card-cell'>
    <>
      <span className='plant-list-card-cell-title'>{title}</span>
      <span className='plant-list-card-cell-body'>{children}</span>
    </>
    // </div>
  );
};

export default PlantListCardCell;
