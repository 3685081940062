import React, { ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LocalSettingsSelectors } from '@gi/local-settings';
import { DeviceDisplayMode, OrientationTypes } from '@gi/constants';

import { getBestDisplayMode, isLandscape } from './util';
import { DisplayModeActions, DisplayModeSelectors } from './display-mode-slice';

interface iProps {
  children?: ReactNode;
  forceDisplayMode?: DeviceDisplayMode.DESKTOP | DeviceDisplayMode.MOBILE;
}

function getDisplayMode(userSelectedDisplayMode: DeviceDisplayMode, forceDisplayMode?: DeviceDisplayMode.DESKTOP | DeviceDisplayMode.MOBILE) {
  if (forceDisplayMode !== undefined) {
    return forceDisplayMode;
  }
  if (userSelectedDisplayMode === DeviceDisplayMode.AUTO || userSelectedDisplayMode === DeviceDisplayMode.SUGGEST) {
    return getBestDisplayMode();
  }
  return userSelectedDisplayMode;
}

/**
 * Provides display-mode functionality.
 * Is responsible for keeping track of which display made should be used.
 */
const DisplayModeHandler = ({ children, forceDisplayMode }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const userSelectedDisplayMode = useSelector(LocalSettingsSelectors.getDeviceDisplayMode);
  const bestDeviceDisplayMode = useSelector(DisplayModeSelectors.getBestDisplayMode);
  const deviceOrientation = useSelector(DisplayModeSelectors.getDeviceOrientation);

  const onResizeCallback = useRef<() => void>(() => {});

  // Called whenever the window resizes. Refefine each render so we have access to the latest store info.
  onResizeCallback.current = () => {
    const newOrientation = isLandscape() ? OrientationTypes.LANDSCAPE : OrientationTypes.PORTRAIT;
    if (newOrientation !== deviceOrientation) {
      dispatch(DisplayModeActions.setDeviceOrientation(newOrientation));
    }

    const newBestDeviceDisplayMode = getBestDisplayMode();
    if (newBestDeviceDisplayMode !== bestDeviceDisplayMode) {
      dispatch(DisplayModeActions.setBestDisplayMode(newBestDeviceDisplayMode));
    }

    if (userSelectedDisplayMode === DeviceDisplayMode.AUTO) {
      dispatch(DisplayModeActions.setDisplayMode(getDisplayMode(userSelectedDisplayMode, forceDisplayMode)));
    }
  };

  // Update the device display mode every time it's changed.
  useEffect(() => {
    dispatch(DisplayModeActions.setDisplayMode(getDisplayMode(userSelectedDisplayMode, forceDisplayMode)));
  }, [userSelectedDisplayMode, forceDisplayMode]);

  // Set up onResize handler to switch display modes if desired
  useEffect(() => {
    const onResize = () => onResizeCallback.current();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return children ? <>{children}</> : null;
};

export default DisplayModeHandler;
