import React, { ReactNode, createContext, useCallback, useMemo, useState } from 'react';

type MenuMenuDropdownGroupContextType = {
  isInGroup: boolean;
  openDropdownId: string | null;
  setOpenDropdownId: (id: string, open: boolean) => void;
};

export const LocalMenuDropdownGroupContext = createContext<MenuMenuDropdownGroupContextType>({
  isInGroup: false,
  openDropdownId: null,
  setOpenDropdownId: () => {},
});

interface iProps {
  children?: ReactNode;
}

/** Keeps track of if any dropdowns are open within this group, allowing dropdowns to open instantly on hover */
const MenuDropdownGroup = ({ children }: iProps): JSX.Element => {
  const [openDropdownId, _setOpenDropdownId] = useState<string | null>(null);

  const setOpenDropdownId = useCallback((id: string, open: boolean) => {
    _setOpenDropdownId((currentOpenDropdown) => {
      if (open) {
        return id;
      }
      if (currentOpenDropdown === id) {
        return null;
      }
      return currentOpenDropdown;
    });
  }, []);

  const value = useMemo<MenuMenuDropdownGroupContextType>(
    () => ({
      isInGroup: true,
      openDropdownId,
      setOpenDropdownId,
    }),
    [openDropdownId]
  );

  return <LocalMenuDropdownGroupContext.Provider value={value}>{children}</LocalMenuDropdownGroupContext.Provider>;
};

export default MenuDropdownGroup;
