import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import PartList from '@gi/part-list';
import { PlanUtils } from '@gi/plan';
import { ResourceContext } from '@gi/resource-provider';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { getDistanceUnitsFromIsMetric } from '@gi/units';
import { SessionSelectors } from '@gi/react-session';
import { PlanHooks } from '@gi/react-utils';
import { PlannerCommandContext } from '@gi/app-planner-desktop';

import PlannerAppContainer from '../planner-app-container';
import PlannerAppScrollPane from '../planner-app-scroll-pane';

import styles from './mobile-parts-list.module.css';

const MobilePartsList = (): JSX.Element | null => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const user = useSelector(SessionSelectors.getUser);
  const { partsListRef } = useContext(PlannerCommandContext);
  const { userGardenObjects, userCountry, userNorthernHemisphere } = useContext(ResourceContext);

  if (!activePlan || !user || !userCountry) {
    return null;
  }

  const distanceUnits = getDistanceUnitsFromIsMetric(activePlan.plannerSettings.metric);

  return (
    <PlannerAppContainer>
      <PlannerAppScrollPane className={styles.mobilePartsList}>
        <PartList
          isMobile
          ref={partsListRef}
          gardenObjects={userGardenObjects}
          planGardenObjects={PlanHooks.usePlanGardenObjects(activePlan)}
          distanceUnits={distanceUnits}
          title={`Part List - ${activePlan.name} - ${PlanUtils.getYearDisplayString(activePlan.year, userNorthernHemisphere)}`}
          artifactCode={userCountry.artifactCode}
        />
      </PlannerAppScrollPane>
    </PlannerAppContainer>
  );
};

export default MobilePartsList;
