import { ColorSource, Container, Graphics } from 'pixi.js-new';

import {
  CameraNodeState,
  GraphicNode,
  InspectableClassData,
  InspectableClassDataType,
  InspectableClassPropertyType,
  NodeEvent,
  bindState,
  createActiveCameraConnector,
} from '@gi/core-renderer';
import { DistanceUnitsGroup } from '@gi/units';
import { State, StateDef } from '@gi/state';

import RulerNode, { RulerMode } from './ruler-node';
import { getDistanceUnitGroup } from './utils';

// The thickness of the rulers
const RULER_THICKNESS = 20;

// Can be used to override the way the rulers display. Default will use the current state.
export enum RulersMode {
  DEFAULT = 'DEFAULT', // Normal operation, will take settings from the state
  SHOW_ALL = 'SHOW_ALL', // Forces all 4 rulers to show, world-aligned
  HIDDEN = 'HIDDEN', // Force all 4 rulers to be hidden
}

type RulersNodeState = StateDef<
  {
    visible: boolean;
    mode: RulersMode;
    thickness: number;
    dimensions: Dimensions;
    worldAlign: boolean;
    showAll: boolean;
    metric: boolean;
    primaryColour: ColorSource;
    secondaryColour: ColorSource;
    backgroundColour: ColorSource;
    /** Internal: The top-left corner (start) of the plan, in screen-space coords */
    startPos: Vector2;
    /** Internal: The bottom-right corner (end) of the plan, in screen-space coords */
    endPos: Vector2;
  },
  [],
  {
    camera: CameraNodeState;
  }
>;

const DEFAULT_STATE: RulersNodeState['state'] = {
  visible: true,
  mode: RulersMode.DEFAULT,
  worldAlign: false,
  showAll: false,
  metric: true,
  thickness: RULER_THICKNESS,
  dimensions: { width: 0, height: 0 },
  startPos: { x: 0, y: 0 },
  endPos: { x: 0, y: 0 },
  primaryColour: '#000000FF',
  secondaryColour: '#D3D3D3FF',
  backgroundColour: '#EEEEEE',
};

class RulersNode extends GraphicNode {
  type = 'RulersNode';

  readonly state: State<RulersNodeState>;

  readonly topRuler: RulerNode;
  readonly leftRuler: RulerNode;
  readonly bottomRuler: RulerNode;
  readonly rightRuler: RulerNode;

  #backgroundLayer: Container = new Container();
  #secondaryLayer: Container = new Container();
  #primaryLayer: Container = new Container();
  #cornerGraphics: Graphics = new Graphics();

  #distanceUnitGroup: DistanceUnitsGroup = getDistanceUnitGroup(true, 1);

  constructor(initialState?: Partial<RulersNodeState['state']>) {
    super();

    this.state = new State({ ...DEFAULT_STATE, ...initialState });
    bindState(this.state, this);

    // Update our local distance unit groups if our distance units or magnification change.
    this.state.addUpdater(
      (state) => {
        const magnification = state.get('camera', 'magnification', 1);
        this.#distanceUnitGroup = getDistanceUnitGroup(state.values.metric, magnification);
      },
      {
        properties: ['metric'],
        otherStates: { camera: { properties: ['magnification'] } },
      }
    );

    const { thickness: initialThickness } = this.state.values;

    this.topRuler = new RulerNode(
      {
        mode: RulerMode.Top,
        thickness: initialThickness,
      },
      'Top Ruler'
    );
    this.topRuler.setLayerParents(this.#backgroundLayer, this.#secondaryLayer, this.#primaryLayer);
    this.state.addUpdater(this.getRulerUpdater(this.topRuler, false, false));

    this.leftRuler = new RulerNode(
      {
        mode: RulerMode.Left,
        thickness: initialThickness,
      },
      'Left Ruler'
    );
    this.leftRuler.transform.state.values.rotation = Math.PI / 2;
    this.leftRuler.setLayerParents(this.#backgroundLayer, this.#secondaryLayer, this.#primaryLayer);
    this.state.addUpdater(this.getRulerUpdater(this.leftRuler, true, false));

    this.bottomRuler = new RulerNode(
      {
        mode: RulerMode.Bottom,
        thickness: initialThickness,
      },
      'Bottom Ruler'
    );
    this.bottomRuler.setLayerParents(this.#backgroundLayer, this.#secondaryLayer, this.#primaryLayer);
    this.state.addUpdater(this.getRulerUpdater(this.bottomRuler, false, true));

    this.rightRuler = new RulerNode(
      {
        mode: RulerMode.Right,
        thickness: initialThickness,
      },
      'Right Ruler'
    );
    this.rightRuler.transform.state.values.rotation = Math.PI / 2;
    this.rightRuler.setLayerParents(this.#backgroundLayer, this.#secondaryLayer, this.#primaryLayer);
    this.state.addUpdater(this.getRulerUpdater(this.rightRuler, true, true));

    this.#cornerGraphics.eventMode = 'none';

    this.addChildren(this.topRuler, this.leftRuler, this.bottomRuler, this.rightRuler);

    this.ownGraphics.addChild(this.#backgroundLayer, this.#secondaryLayer, this.#primaryLayer, this.#cornerGraphics);

    this.state.addUpdater(() => this.updateCameraCalculations(), {
      otherStates: { camera: { properties: ['magnification', 'position', 'viewportSize'] } },
    });

    this.state.addWatcher(
      (state) => {
        const { worldAlign, mode, thickness, startPos, endPos } = state.values;
        const viewportSize = this.state.get('camera', 'viewportSize');

        if (worldAlign || mode === RulersMode.SHOW_ALL) {
          this.leftRuler.transform.state.values.position = { x: startPos.x + 1, y: 0 };
          this.rightRuler.transform.state.values.position = { x: endPos.x + thickness, y: 0 };
          this.topRuler.transform.state.values.position = { x: 0, y: startPos.y - thickness + 1 };
          this.bottomRuler.transform.state.values.position = { x: 0, y: endPos.y };
        } else if (state.changed.properties.worldAlign || state.changed.properties.mode || state.hasChanged('camera', 'viewportSize')) {
          // Only update the rulers if something relevant has changed, for performance
          this.topRuler.transform.state.values.position = { x: 0, y: 0 };
          this.leftRuler.transform.state.values.position = { x: thickness, y: 0 };
          if (viewportSize) {
            this.rightRuler.transform.state.values.position = { x: viewportSize.width, y: 0 };
            this.bottomRuler.transform.state.values.position = {
              x: 0,
              y: viewportSize.height - thickness,
            };
          } // TODO: If viewportSize unavailable, hide rulers?
        }
      },
      { properties: ['startPos', 'endPos', 'worldAlign', 'mode'] }
    );

    this.state.addWatcher(
      (state) => {
        const { worldAlign, mode, startPos } = state.values;

        if (worldAlign || mode === RulersMode.SHOW_ALL) {
          this.#cornerGraphics.position = { x: startPos.x, y: startPos.y };
        } else {
          this.#cornerGraphics.position = { x: 0, y: 0 };
        }
      },
      {
        properties: ['startPos', 'endPos', 'thickness', 'worldAlign', 'mode'],
        otherStates: {
          camera: { properties: ['magnification'] },
        },
      }
    );

    // Updater to fix the position of the left ruler based on thickness
    this.state.addUpdater(
      (state) => {
        this.leftRuler.transform.state.values.position = {
          x: state.currentValues.thickness,
          y: 0,
        };
      },
      { properties: ['thickness'] }
    );

    this.state.addWatcher(() => this.#redrawCorners(), {
      properties: ['backgroundColour', 'thickness', 'showAll', 'worldAlign', 'mode', 'dimensions', 'visible'],
      otherStates: {
        camera: { properties: ['magnification', 'viewportSize'] },
      },
    });

    createActiveCameraConnector(this, this.state, 'camera');

    this.eventBus.on(NodeEvent.Destroyed, this.#onDestroy);
  }

  #onDestroy = () => {
    this.#backgroundLayer.destroy();
    this.#cornerGraphics.destroy();
    this.#primaryLayer.destroy();
    this.#secondaryLayer.destroy();
  };

  #redrawCorners() {
    const { visible, backgroundColour, primaryColour, thickness, showAll, worldAlign, dimensions, mode } = this.state.values;
    const magnification = this.state.get('camera', 'magnification', 1);
    const viewportSize = this.state.get('camera', 'viewportSize');

    this.#cornerGraphics.clear();

    if (mode === RulersMode.HIDDEN || (!visible && mode !== RulersMode.SHOW_ALL)) {
      return;
    }

    if (worldAlign || mode === RulersMode.SHOW_ALL) {
      const scaledDimensions = {
        width: dimensions.width * magnification,
        height: dimensions.height * magnification,
      };

      // Draw primary border
      this.#cornerGraphics
        .beginFill(primaryColour)
        .drawRect(-thickness + 1, -thickness + 1, thickness, thickness)
        .drawRect(scaledDimensions.width, -thickness + 1, thickness, thickness)
        .drawRect(-thickness + 1, scaledDimensions.height, thickness, thickness)
        .drawRect(scaledDimensions.width, scaledDimensions.height, thickness, thickness)
        .endFill();

      // Draw background fill on top
      this.#cornerGraphics
        .beginFill(backgroundColour)
        .drawRect(-thickness + 1, -thickness + 1, thickness - 1, thickness - 1)
        .drawRect(scaledDimensions.width + 1, -thickness + 1, thickness - 1, thickness - 1)
        .drawRect(-thickness + 1, scaledDimensions.height + 1, thickness - 1, thickness - 1)
        .drawRect(scaledDimensions.width + 1, scaledDimensions.height + 1, thickness - 1, thickness - 1)
        .endFill();
    } else {
      this.#cornerGraphics.beginFill(backgroundColour).drawRect(0, 0, thickness - 1, thickness - 1);

      if (showAll && viewportSize) {
        this.#cornerGraphics
          .drawRect(viewportSize.width - (thickness - 1), 0, thickness - 1, -thickness - 1)
          .drawRect(0, viewportSize.height - (thickness - 1), thickness - 1, thickness - 1)
          .drawRect(viewportSize.width - (thickness - 1), viewportSize.height - (thickness - 1), thickness - 1, thickness - 1);
      }

      this.#cornerGraphics.endFill();
    }
  }

  updateCameraCalculations() {
    const cameraState = this.state.otherStates.camera;
    const camera = this.state.otherStates.camera?.owner;
    if (!cameraState || !camera) {
      return;
    }
    const { dimensions } = this.state.values;

    let startPos = camera.getScreenPos({ x: 0, y: 0 });
    let endPos = camera.getScreenPos({ x: dimensions.width, y: dimensions.height });

    if (this.state.values.worldAlign) {
      startPos = { x: Math.ceil(startPos.x), y: Math.ceil(startPos.y) };
      endPos = { x: Math.floor(endPos.x), y: Math.floor(endPos.y) };
    }

    this.state.values.startPos = startPos;
    this.state.values.endPos = endPos;
  }

  getRulerUpdater(ruler: RulerNode, isVertical: boolean, isExtra: boolean) {
    return () => {
      const { visible, dimensions, startPos, endPos, showAll, worldAlign, mode, backgroundColour, primaryColour, secondaryColour } = this.state.currentValues;

      const viewportSize = this.state.get('camera', 'viewportSize', { width: 0, height: 0 });

      let visibility = visible;
      if (mode === RulersMode.SHOW_ALL) {
        visibility = true;
      } else if (mode === RulersMode.HIDDEN) {
        visibility = false;
      } else if (isExtra) {
        visibility = (worldAlign || showAll) && visible;
      }
      ruler.state.values.visible = visibility;
      ruler.state.values.length = isVertical ? viewportSize.height : viewportSize.width;
      ruler.state.values.startAt = isVertical ? startPos.y : startPos.x;
      ruler.state.values.endAt = isVertical ? endPos.y : endPos.x;
      ruler.state.values.divisions = (isVertical ? dimensions.height : dimensions.width) / this.#distanceUnitGroup.spacing;
      ruler.state.values.subdivisions = this.#distanceUnitGroup.divisions;
      ruler.state.values.textIncrement = this.#distanceUnitGroup.unitMultiplier;
      ruler.state.values.textUnit = this.#distanceUnitGroup.shortcode;
      ruler.state.values.backgroundColour = backgroundColour;
      ruler.state.values.primaryColour = primaryColour;
      ruler.state.values.secondaryColour = secondaryColour;
    };
  }

  setMode(mode: RulersMode) {
    this.state.values.mode = mode;
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default RulersNode;
