import PlantFamily from './plant-family';

interface PlantFamilyData {
  id: number;
  name: string;
  scientificName: string;
  otherName: string;
  color: string;
  secondaryColor: string;
}

const plantFamilyFromObject = (obj: PlantFamilyData) => {
  const plantFamily: PlantFamily = {
    ID: obj.id,
    name: obj.name,
    scientificName: obj.scientificName,
    otherName: obj.otherName,
    color: obj.color,
    secondaryColor: obj.secondaryColor,
  };
  return plantFamily;
};

export default plantFamilyFromObject;
