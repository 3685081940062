import React, { useState } from 'react';
import { distanceConversion } from '@gi/conversion';
import { InputContainer } from '@gi/form-responsive';

import './length-input.scss';

const handleFocus = (event) => event.target.select();

const parseValue = (val: string, metric: boolean): number => {
  if (metric) {
    const floatVal = parseFloat(val);
    if (!Number.isNaN(floatVal)) {
      return floatVal / 10;
    }

    return Number.NaN;
  }

  return distanceConversion.cmFromInches(parseFloat(val));
};

interface iProps {
  metric: boolean;
  disabled?: boolean;
  inputClasses?: string;
  inline?: boolean;
  value: number; // Value is in cm
  onChange: (value: number) => void;
  valid: boolean;
  id?: string;
}

const DecimalInput = ({ metric, inline = false, disabled = false, inputClasses = '', value, onChange, valid, id }: iProps): JSX.Element => {
  const initialValue = metric ? value * 10 : distanceConversion.inchesFromCM(value);
  const [inputValue, setInputValue] = useState<string>(initialValue.toString());

  const onBlur = () => {
    onChange(parseValue(inputValue, metric));
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
    onChange(parseValue(e.target.value, metric));
  };

  const renderMetric = () => {
    let className = 'length-input-container';

    if (disabled) {
      className += ' disabled-length-input-container';
    }

    return (
      <InputContainer className={className} inputSuffix='mm' inputSuffixInline={inline} size={92}>
        <input
          id={id}
          disabled={disabled}
          type='number'
          className={`length-input length-input-meters ${valid ? '' : 'invalid'} ${inputClasses}`}
          value={inputValue}
          onChange={onInputChange}
          onFocus={handleFocus}
          onBlur={onBlur}
        />
      </InputContainer>
    );
  };

  const renderImperial = () => {
    let className = 'length-input-container';

    if (disabled) {
      className += ' disabled';
    }

    return (
      <InputContainer className={className} inputSuffix='inches' inputSuffixInline={inline} size={108}>
        <input
          id={id}
          disabled={disabled}
          type='number'
          className={`length-input ${valid ? '' : 'invalid'} ${inputClasses}`}
          value={inputValue}
          onChange={onInputChange}
          onFocus={handleFocus}
          onBlur={onBlur}
        />
      </InputContainer>
    );
  };

  if (metric) {
    return renderMetric();
  }

  return renderImperial();
};

export default DecimalInput;
