import React, { useContext } from 'react';
import { GuruDirectoryPageId } from '@gi/app-guru-types';
import { getComposedPageId } from '@gi/app-guru-common';

import DirectoryPageContainer from '../../components/directory-page/directory-page-container';
import DirectoryPageSections from '../../components/directory-page/directory-page-sections';
import DirectoryPageHeader from '../../components/directory-page/directory-page-header';
import BookmarksDirectoryInfo from './bookmarks-directory-info';
import DirectoryPageContentSection from '../../components/directory-page/directory-page-content-section';
import DirectoryPage from '../../components/directory-page/directory-page';
import { GardenGuruDataContext } from '../../context/garden-guru-data-context';

interface iProps {
  guruPageId: GuruDirectoryPageId;
}

const BookmarksDirectoryPage = ({ guruPageId }: iProps): JSX.Element => {
  const { guruPages } = useContext(GardenGuruDataContext);
  const composedPageId = getComposedPageId(guruPages, guruPageId);

  return (
    <DirectoryPageContainer>
      <DirectoryPageHeader composedPageId={composedPageId} />
      <DirectoryPage>
        <DirectoryPageContentSection>
          <BookmarksDirectoryInfo />
        </DirectoryPageContentSection>
        <DirectoryPageContentSection>
          <DirectoryPageSections composedPageId={composedPageId} />
        </DirectoryPageContentSection>
      </DirectoryPage>
    </DirectoryPageContainer>
  );
};

export default BookmarksDirectoryPage;
