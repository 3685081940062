import React from 'react';

import GuruButton from '../../buttons/guru-button';

import styles from './content-grid-no-results.module.css';

interface iProps {
  onResetClick?: () => void;
}

const ContentGridNoResults = ({ onResetClick }: iProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <p>No results found for your current filters</p>
      <p>Try removing some filters, or reset your filters.</p>
      <GuruButton onClick={onResetClick}>Reset Filters</GuruButton>
    </div>
  );
};

export default ContentGridNoResults;
