import { createListenerMiddleware } from '@reduxjs/toolkit';

import { LogActionTypes, LogMessageAction } from './log-action-creators';
import { logService } from './log-service';

export const logMiddleware = createListenerMiddleware();

// Listen for log action and send contents to the log service
logMiddleware.startListening({
  type: LogActionTypes.Log,
  effect: (action: LogMessageAction) => {
    logService.log([action.payload]).catch((e) => {
      /* Network service will already log this error, prevent re-firing it */
      console.error(`Failed to log to log service:`, e);
    });
  },
});
