import React, { useCallback, useMemo } from 'react';

import { LoadingState } from '@gi/constants';

import FormField, { FORM_FIELD_PRESETS, FormSection } from '@gi/form-responsive';
import { ImageEditorModalFormProps, InputAsyncImage, InputImage, InputRemoteImage } from '../types';
import { IMAGE_FILE_TYPES } from '../utils';
import ImageLoaded from './image-loaded';
import FileButton from '../components/file-button';
import useImage from '../hooks/use-image';

import styles from './styles.module.css';

interface iProps extends ImageEditorModalFormProps {
  image: InputImage | InputRemoteImage | InputAsyncImage;
  onImageChange: (image: File) => void;
}

const ImageSelected = ({ image, onImageChange, values, setValues }: iProps): JSX.Element => {
  const imageElement = useImage({ image });

  const onCropToggle = useCallback(() => {
    if (values.values.crop !== null) {
      setValues(values.setValue('crop', { value: null }));
    } else if (imageElement.status === LoadingState.SUCCESS) {
      const img = imageElement.value;
      setValues(values.setValue('crop', { value: { x: img.width / 2, y: img.height / 2, width: img.width, height: img.height } }));
    }
  }, [values, setValues, imageElement]);

  const placeholder = useMemo(() => {
    switch (imageElement.status) {
      case LoadingState.LOADING:
        return (
          <div className={styles.imagePlaceholder}>
            <i className='icon-spinner animate-pulse' />
          </div>
        );
      case LoadingState.ERROR:
        return (
          <div className={styles.imagePlaceholder}>
            <i className='icon-warning' /> Failed to load image
          </div>
        );
      default:
        return null;
    }
  }, [imageElement.status]);

  return (
    <div>
      {imageElement.status === LoadingState.SUCCESS ? <ImageLoaded image={imageElement.value} values={values} setValues={setValues} /> : null}
      {placeholder}
      <FormSection padding={12} gap={6} className='form-section-background'>
        <FormField
          label='Crop'
          htmlFor='image-editor:crop-toggle'
          layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
          disabled={imageElement.status !== LoadingState.SUCCESS}
        >
          <input
            type='checkbox'
            id='image-editor:crop-toggle'
            checked={values.values.crop !== null}
            onChange={onCropToggle}
            disabled={imageElement.status !== LoadingState.SUCCESS}
          />
        </FormField>
      </FormSection>
      <div className={styles.imageControls}>
        <FileButton className='button-secondary' onFileSelected={onImageChange} accept={IMAGE_FILE_TYPES}>
          <i className='icon-folder-open-empty' /> Change Image
        </FileButton>
      </div>
    </div>
  );
};

export default ImageSelected;
