import { batchActions } from 'redux-batched-actions';
import { RequestActionCreators } from '@gi/react-requests';
import { RequestsUtils } from '@gi/request';
import { ForecastActionCreators } from './forecast-slice';

export const LOAD_WEATHER_REQUEST_ID = 'LOAD_DASHBOARD_WEATHER';

export const loadForecast = () => {
  return (dispatch, getState, { services }) => {
    const state = getState();
    const date = new Date();
    const { user } = state.session;

    if (RequestsUtils.hasRequest(state.requests, LOAD_WEATHER_REQUEST_ID) && !RequestsUtils.requestFailed(state.requests, LOAD_WEATHER_REQUEST_ID)) {
      console.debug('Forecast already loading');
      return;
    }

    if (state.forecast.forecastLoaded && !state.forecast.forecastLoadFailed) {
      console.debug('Forecast already loaded');
      return;
    }

    dispatch(RequestActionCreators.requestStart(LOAD_WEATHER_REQUEST_ID));
    services.forecastService
      .getForecastsFromDate(date, 7, user.settings.location.latitude, user.settings.location.longitude)
      .then((weatherData) => {
        dispatch(batchActions([ForecastActionCreators.setForcast(weatherData), RequestActionCreators.requestComplete(LOAD_WEATHER_REQUEST_ID)]));
      })
      .catch((err) => {
        dispatch(batchActions([ForecastActionCreators.forecastFailed(), RequestActionCreators.requestFail(LOAD_WEATHER_REQUEST_ID, err)]));
      });
  };
};
