import { PlanText } from '@gi/plan';
import { MathUtils } from '@gi/math';
import { Swatch, SwatchTypes } from '@gi/palette';

export function getSelectedSwatchFromText(planText: PlanText): Swatch {
  return {
    type: SwatchTypes.COLOR,
    value: MathUtils.padHexString((planText.fill ?? 0).toString(16)),
  };
}
