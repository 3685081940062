import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { DrawingToolsContext, ShapeIcons, TextIcon } from '@gi/drawing-tools';
import { ResourceContext } from '@gi/resource-provider';

import './currently-drawing.scss';
import { PlannerIconWithFamily } from '@gi/planner-icon';
import { ShapeType } from '@gi/constants';
import GardenObjectIcon from './garden-objects/garden-object-icon';

interface InternalState {
  name: string;
  icon?: ReactNode;
}

interface iProps {
  isVertical?: boolean;
}

const CurrentlyDrawing = ({ isVertical }: iProps): JSX.Element => {
  const { selectedPlantCode, selectedGardenObjectCode, selectedShapeType, selectedShapeFill, textSelected, cancelDraw } = useContext(DrawingToolsContext);
  const { userPlants, userGardenObjects } = useContext(ResourceContext);
  const [lastKnownState, setLastKnownState] = useState<InternalState>({ name: '' });

  /**
   * Check if we're ready to draw
   */
  const isDrawing = useMemo(() => {
    return selectedPlantCode !== null || selectedGardenObjectCode !== null || selectedShapeType !== null || textSelected;
  }, [selectedPlantCode, selectedGardenObjectCode, selectedShapeType, textSelected]);

  /**
   * Any time the drawing tools state changes, see if the user is currently drawing
   * and cache that. This allows us to animate the menu off once drawing is complete.
   */
  useEffect(() => {
    // Are we drawing a plant?
    if (selectedPlantCode !== null) {
      const plant = userPlants?.get(selectedPlantCode);
      if (!plant) {
        setLastKnownState({ name: 'Unknown' });
        return;
      }
      setLastKnownState({
        name: plant.name,
        icon: <PlannerIconWithFamily code={plant.code} familyID={plant.familyID} />,
      });
      return;
    }
    // Are we drawing a garden object?
    if (selectedGardenObjectCode !== null) {
      const gardenObject = userGardenObjects?.get(selectedGardenObjectCode);
      if (!gardenObject) {
        setLastKnownState({ name: 'Unknown' });
        return;
      }
      setLastKnownState({
        name: gardenObject.name,
        icon: <GardenObjectIcon code={gardenObject.code} />,
      });
    }
    // Are we drawing a shape?
    if (selectedShapeType !== null) {
      switch (selectedShapeType) {
        case ShapeType.RECTANGLE:
          setLastKnownState({
            name: 'Rectangle',
            icon: (
              <span className='icon-shape'>
                <img src={selectedShapeFill ? ShapeIcons.rectFill : ShapeIcons.rect} alt='Rectangle' />
              </span>
            ),
          });
          return;
        case ShapeType.ELLIPSE:
          setLastKnownState({
            name: 'Ellipse',
            icon: (
              <span className='icon-shape'>
                <img src={selectedShapeFill ? ShapeIcons.ellipseFill : ShapeIcons.ellipse} alt='Ellipse' />
              </span>
            ),
          });
          return;
        case ShapeType.TRIANGLE:
          setLastKnownState({
            name: 'Triangle',
            icon: (
              <span className='icon-shape'>
                <img src={selectedShapeFill ? ShapeIcons.triangleFill : ShapeIcons.triangle} alt='Triangle' />
              </span>
            ),
          });
          return;
        case ShapeType.LINE:
          setLastKnownState({
            name: 'Line',
            icon: (
              <span className='icon-shape'>
                <img src={ShapeIcons.line} alt='Line' />
              </span>
            ),
          });
          return;
        default:
          setLastKnownState({
            name: 'Unknown',
          });
          return;
      }
    }
    // Are we drawing text?
    if (textSelected) {
      setLastKnownState({
        name: 'Text',
        icon: (
          <span className='icon-shape text'>
            <img src={TextIcon} alt='Text' />
          </span>
        ),
      });
    }
    // We're not drawing anything. Don't update the last known state.
  }, [selectedPlantCode, userPlants, selectedGardenObjectCode, userGardenObjects, selectedShapeType, selectedShapeFill, textSelected]);

  const classNames = ['currently-drawing'];
  if (isVertical) {
    classNames.push('vertical');
  }
  if (isDrawing) {
    classNames.push('open');
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='container'>
        <div className='icon'>{lastKnownState.icon}</div>
        <div className='content'>
          <span className='content-operation'>Drawing</span>
          <span className='content-operand'>{lastKnownState.name}</span>
        </div>
        <div className='actions'>
          <button type='button' onClick={cancelDraw}>
            <i className='icon-cancel' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CurrentlyDrawing;
