import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { GuruHeader, GuruHeaderSlice } from './types';

import GardenGuruContext from './garden-guru-context';
import EmptyLoadingHeader from '../header/header-components/empty-loading-header';

import background from './background.png';

export const DEFAULT_GURU_HEADER: GuruHeader = {
  id: 'default',
  background,
  content: <EmptyLoadingHeader />,
};

/**
 * Removes any properties whose values are undefined. Saves time later.
 */
function removeUndefined(slice: GuruHeaderSlice): GuruHeaderSlice {
  const filteredSlice = { ...slice };
  const keys = Object.keys(filteredSlice);
  for (let i = 0; i < keys.length; i++) {
    if (filteredSlice[keys[i]] === undefined) {
      delete filteredSlice[keys[i]];
    }
  }
  return filteredSlice;
}

interface iProps {
  children: ReactNode;
}

const GardenGuruProvider = ({ children }: iProps): JSX.Element => {
  const [headerSliceOrder, setHeaderSliceOrder] = useState<string[]>([]);
  const [headerSlices, setHeaderSlices] = useState<Record<string, GuruHeaderSlice>>({});

  const [header, setHeader] = useState<GuruHeader>(DEFAULT_GURU_HEADER);
  const lastBackground = useRef<string | number>(DEFAULT_GURU_HEADER.background);
  const scrollPane = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let headerProperties: Partial<GuruHeader> = {};
    for (let i = 0; i < headerSliceOrder.length; i++) {
      const slice = headerSlices[headerSliceOrder[i]];
      if (slice.content !== undefined) {
        headerProperties = {
          ...headerProperties,
          ...slice,
        };
      } else {
        // Throw out the ID property if no content is being set.
        // This allows background overrides to be set from within other headers.
        headerProperties = {
          ...headerProperties,
          ...slice,
          id: headerProperties.id,
        };
      }
    }
    setHeader({
      ...DEFAULT_GURU_HEADER,
      ...{ background: lastBackground.current },
      ...headerProperties,
    });
    if (headerProperties.background !== undefined) {
      lastBackground.current = headerProperties.background;
    }
  }, [headerSliceOrder, headerSlices]);

  const registerSlice = useCallback((slice: GuruHeaderSlice) => {
    setHeaderSlices((currentSlices) => ({
      ...currentSlices,
      [slice.id]: removeUndefined(slice),
    }));
    setHeaderSliceOrder((currentOrder) => {
      if (currentOrder.includes(slice.id)) {
        return currentOrder;
      }
      return [...currentOrder, slice.id];
    });
  }, []);

  const updateSlice = useCallback((slice: GuruHeaderSlice) => {
    setHeaderSlices((currentSlices) => ({
      ...currentSlices,
      [slice.id]: removeUndefined(slice),
    }));
  }, []);

  const unregisterSlice = useCallback((sliceId: string) => {
    setHeaderSliceOrder((currentOrder) => {
      return currentOrder.filter((i) => i !== sliceId);
    });
    setHeaderSlices((currentSlices) => {
      const newSlices = { ...currentSlices };
      delete newSlices[sliceId];
      return newSlices;
    });
  }, []);

  const scrollTop = useCallback(() => {
    if (scrollPane.current) {
      scrollPane.current.scrollTo(0, 0);
    }
  }, [scrollPane]);

  const value = useMemo(
    () => ({
      registerSlice,
      updateSlice,
      unregisterSlice,
      header,
      scrollPane,
      scrollTop,
    }),
    [registerSlice, updateSlice, unregisterSlice, header, scrollPane, scrollTop]
  );

  return <GardenGuruContext.Provider value={value}>{children}</GardenGuruContext.Provider>;
};

export default GardenGuruProvider;
