import React, { useCallback, useContext } from 'react';
import ReactSelect from 'react-select';

import { MAX_SHAPE_STROKE_WIDTH, ShapeType } from '@gi/constants';

import { SwatchTypes, presetPalettes } from '@gi/palette';
import { PickerButton } from '@gi/texture-picker';

import { DrawingToolsContext } from '../drawing-tools-context';
import DragToDrawButton from '../drag-to-draw/drag-to-draw-button';

import rect from './icons/rect.svg';
import rectFill from './icons/rect-fill.svg';
import ellipse from './icons/circle.svg';
import ellipseFill from './icons/circle-fill.svg';
import triangle from './icons/triangle.svg';
import triangleFill from './icons/triangle-fill.svg';
import line from './icons/line.svg';

import './shape-panel.scss';

const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '30px',
    minWidth: '134px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '3px',
  }),
  input: (provided) => ({
    ...provided,
    paddingBottom: '0',
    paddingTop: '0',
    margin: '0',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 5px',
    minHeight: '30px',
  }),
};

interface DropdownOption {
  value: number;
  label: string;
}

const strokeWidths: DropdownOption[] = [];

for (let i = 1; i <= MAX_SHAPE_STROKE_WIDTH; i++) {
  strokeWidths.push({ value: i, label: i.toString() });
}

const ShapeSelectionPlanel = () => {
  const {
    shapeStrokeWidth,
    setShapeStrokeWidth,
    shapeFillSwatch,
    setShapeFillSwatch,
    shapeStrokeSwatch,
    setShapeStrokeSwatch,
    selectedShapeType,
    selectedShapeFill,
    onShapeSelected,
    cancelDraw,
  } = useContext(DrawingToolsContext);

  const getStrokeColor = (): string => {
    if (shapeStrokeSwatch === null) {
      return '0000000';
    }

    return shapeStrokeSwatch.value;
  };

  const getStrokeWidth = (): number => {
    if (shapeStrokeWidth === null) {
      return 3;
    }

    return shapeStrokeWidth.value;
  };

  const getFillColor = (): string => {
    if (shapeFillSwatch === null || shapeFillSwatch.type !== SwatchTypes.COLOR) {
      return '000000';
    }

    return shapeFillSwatch.value;
  };

  const getFillTexture = (): null | string => {
    if (shapeFillSwatch === null || shapeFillSwatch.type !== SwatchTypes.IMAGE) {
      return null;
    }

    return shapeFillSwatch.value;
  };

  /**
   * If given a valid SHAPE_TYPE, fires props.onSelect with the provided shape and options
   *
   * @param {string} SHAPE_TYPE one of ShapeType
   * @param {boolean} isFilled whether the shape is filled
   * @param {object} [overrides] containing zero or more of strokeWidth, strokeColor, fillColor, fillTexture
   */
  const drawShape = (
    SHAPE_TYPE: ShapeType,
    isFilled: boolean,
    strokeWidth: number,
    strokeColor: string,
    fillColor: string,
    fillTexture: null | string,
    dragToDrawEvent?: PointerEvent
  ) => {
    if (!(SHAPE_TYPE in ShapeType)) {
      return;
    }

    onShapeSelected(SHAPE_TYPE, strokeWidth, strokeColor, isFilled ? fillColor : null, isFilled ? fillTexture : null, dragToDrawEvent);
  };

  /**
   * Cancels or draws the shape, depending on whether the shape is already selected or not.
   * @param {string} SHAPE_TYPE one of ShapeType
   * @param {boolean} [isFilled] whether the shape is filled
   */
  const toggleShape = (SHAPE_TYPE, isFilled = false) => {
    if (selectedShapeType === SHAPE_TYPE && !!selectedShapeFill === isFilled) {
      cancelDraw();
    } else {
      drawShape(SHAPE_TYPE, isFilled, getStrokeWidth(), getStrokeColor(), getFillColor(), getFillTexture());
    }
  };

  const onStrokeWidthChange = (e) => {
    setShapeStrokeWidth(e);
    if (selectedShapeType && selectedShapeType in ShapeType) {
      drawShape(selectedShapeType, selectedShapeFill ?? false, e.value, getStrokeColor(), getFillColor(), getFillTexture());
    }
  };

  const onStrokeSwatchChange = (e) => {
    setShapeStrokeSwatch(e);
    if (selectedShapeType && selectedShapeType in ShapeType) {
      drawShape(selectedShapeType, selectedShapeFill ?? false, getStrokeWidth(), e.value, getFillColor(), getFillTexture());
    }
  };

  const onFillSwatchChange = (e) => {
    setShapeFillSwatch(e);
    if (selectedShapeType && selectedShapeType in ShapeType) {
      drawShape(
        selectedShapeType,
        selectedShapeFill ?? false,
        getStrokeWidth(),
        getStrokeColor(),
        e.type === SwatchTypes.IMAGE ? '000000' : e.value,
        e.type === SwatchTypes.IMAGE ? e.value : null
      );
    }
  };

  const onDrawRect = () => {
    toggleShape(ShapeType.RECTANGLE);
  };

  const onDrawFillRect = () => {
    toggleShape(ShapeType.RECTANGLE, true);
  };

  const onDrawTriangle = () => {
    toggleShape(ShapeType.TRIANGLE);
  };

  const onDrawFillTriangle = () => {
    toggleShape(ShapeType.TRIANGLE, true);
  };

  const onDrawEllipse = () => {
    toggleShape(ShapeType.ELLIPSE);
  };

  const onDrawFillEllipse = () => {
    toggleShape(ShapeType.ELLIPSE, true);
  };

  const onDrawLine = () => {
    toggleShape(ShapeType.LINE);
  };

  const dragToDrawCallback = useCallback(
    (shapeType: ShapeType, isFilled: boolean = false) => {
      return (e: PointerEvent) => {
        drawShape(shapeType, isFilled, getStrokeWidth(), getStrokeColor(), getFillColor(), getFillTexture(), e);
      };
    },
    [drawShape]
  );

  const rectButtonClass = `shape-button ${selectedShapeType === 'RECTANGLE' && !selectedShapeFill ? 'selected' : ''}`;
  const triangleButtonClass = `shape-button ${selectedShapeType === 'TRIANGLE' && !selectedShapeFill ? 'selected' : ''}`;
  const ellipseButtonClass = `shape-button ${selectedShapeType === 'ELLIPSE' && !selectedShapeFill ? 'selected' : ''}`;
  const lineButtonClass = `shape-button ${selectedShapeType === 'LINE' && !selectedShapeFill ? 'selected' : ''}`;
  const fillRectButtonClass = `shape-button ${selectedShapeType === 'RECTANGLE' && selectedShapeFill ? 'selected' : ''}`;
  const fillTriangleButtonClass = `shape-button ${selectedShapeType === 'TRIANGLE' && selectedShapeFill ? 'selected' : ''}`;
  const fillEllipseButtonClass = `shape-button ${selectedShapeType === 'ELLIPSE' && selectedShapeFill ? 'selected' : ''}`;

  return (
    <div className='shape-panel drawing-tools-panel'>
      <header className='drawing-tools-panel-title'>
        <h1>Shapes</h1>
      </header>
      <div className='shape-inputs drawing-tools-panel-content scrollable-drawing-tools-panel-content'>
        <div className='shape-input-row'>
          <h4>Outline Shapes</h4>
        </div>
        <div className='shape-input-row'>
          <label>
            <span className='label-text'>Line Color</span>
            <PickerButton selectedSwatch={shapeStrokeSwatch} palettes={presetPalettes.colorPalettes} onSwatchSelect={onStrokeSwatchChange} />
          </label>
        </div>
        <div className='shape-input-row'>
          <label>
            <span className='label-text'>Line Width</span>
            <ReactSelect
              styles={SELECT_STYLES}
              className='shape-input-value'
              options={strokeWidths}
              value={shapeStrokeWidth}
              onChange={onStrokeWidthChange}
              isSearchable={false}
            />
          </label>
        </div>
        <div className='shape-input-row shape-select'>
          <div className='shape-draw-group'>
            {/* Outline Rectangle */}
            <div className={rectButtonClass}>
              <DragToDrawButton aria-label='Outline Rectangle' onClick={onDrawRect} onDrag={dragToDrawCallback(ShapeType.RECTANGLE, false)}>
                <img src={rect} alt='rectangle' />
              </DragToDrawButton>
            </div>
            {/* Outline Triangle */}
            <div className={triangleButtonClass}>
              <DragToDrawButton aria-label='Outline Triangle' onClick={onDrawTriangle} onDrag={dragToDrawCallback(ShapeType.TRIANGLE, false)}>
                <img src={triangle} alt='triangle' />
              </DragToDrawButton>
            </div>
            {/* Outline Ellipse */}
            <div className={ellipseButtonClass}>
              <DragToDrawButton aria-label='Outline Ellipse' onClick={onDrawEllipse} onDrag={dragToDrawCallback(ShapeType.ELLIPSE, false)}>
                <img src={ellipse} alt='ellipse' />
              </DragToDrawButton>
            </div>
            {/* Line */}
            <div className={lineButtonClass}>
              <DragToDrawButton aria-label='Line' onClick={onDrawLine} onDrag={dragToDrawCallback(ShapeType.LINE, false)}>
                <img src={line} alt='line' />
              </DragToDrawButton>
            </div>
          </div>
        </div>
        <div className='shape-input-row'>
          <h4>Filled Shapes</h4>
        </div>
        <div className='shape-input-row'>
          <label>
            <span className='label-text'>Fill Color/Texture</span>
            <PickerButton selectedSwatch={shapeFillSwatch} palettes={presetPalettes.withTexturesPalettes} onSwatchSelect={onFillSwatchChange} />
          </label>
        </div>
        <div className='shape-input-row shape-select'>
          <div className='shape-draw-group'>
            {/* Filled Rectangle */}
            <div className={fillRectButtonClass}>
              <DragToDrawButton aria-label='Fill Rectangle' onClick={onDrawFillRect} onDrag={dragToDrawCallback(ShapeType.RECTANGLE, true)}>
                <img src={rectFill} alt='fill rectangle' />
              </DragToDrawButton>
            </div>
            {/* Filled Triangle */}
            <div className={fillTriangleButtonClass}>
              <DragToDrawButton aria-label='Fill Triangle' onClick={onDrawFillTriangle} onDrag={dragToDrawCallback(ShapeType.TRIANGLE, true)}>
                <img src={triangleFill} alt='fill triangle' />
              </DragToDrawButton>
            </div>
            {/* Filled Ellipse */}
            <div className={fillEllipseButtonClass}>
              <DragToDrawButton aria-label='Fill Ellipse' onClick={onDrawFillEllipse} onDrag={dragToDrawCallback(ShapeType.ELLIPSE, true)}>
                <img src={ellipseFill} alt='fill ellipse' />
              </DragToDrawButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShapeSelectionPlanel;
