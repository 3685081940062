import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { CanvasSelectors } from '@gi/react-garden-canvas';
import { ResourceContext } from '@gi/resource-provider';
import { getDistanceUnitsFromIsMetric } from '@gi/units';

import LocalPlantListContext from '../local-provider/local-plant-list-context';
import SummaryTableRow from './summary-table-row';

const SummaryTableRows = (): JSX.Element | null => {
  const { filteredPlantGroupData } = useContext(LocalPlantListContext);
  const { userDistanceUnits } = useContext(ResourceContext);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  const distanceUnits = activePlan ? getDistanceUnitsFromIsMetric(activePlan.plannerSettings.metric) : userDistanceUnits;

  return (
    <>
      {filteredPlantGroupData.map((plantListRowGroup, index) => (
        <SummaryTableRow key={plantListRowGroup.plant.code} plantListRowGroup={plantListRowGroup} evenRow={index % 2 === 0} distanceUnits={distanceUnits} />
      ))}
    </>
  );
};

export default SummaryTableRows;
