import React from 'react';

import UserReferralCodeOverview from '../forms/user-referral-code-overview';

const ReferPage = (): JSX.Element => {
  return (
    <div className='settings-page'>
      <UserReferralCodeOverview />
    </div>
  );
};

export default ReferPage;
