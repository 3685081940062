import { Graphics } from 'pixi.js-new';

import { Geometry } from '@gi/math';
import { State, StateDef } from '@gi/state';

import { EngineEvent } from '../../engine';
import GraphicNode from '../../graphics-node';
import { NodeEvent, NodeEventActions } from '../../node';
import ContentRootContext from '../content-root/content-root-context';
import EventBus from '../../event-bus';
import { Bounds, InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { bindState } from '../../utils/state-utils';
import CameraContext from './camera-context';

/**
 * Rounds the given number to the nearest half-pixel (not nearest 0.5).
 * E.g. Between 0 and <1 rounds to 0.5, between 1 and <2 rounds to 1.5.
 * This mimmicks the old renderer behaviour, causing single-width strokes to appear clear,
 * but rectangles aligned with the grid to look blurry.
 */
const halfPixelRound = (number: number): number => {
  return Math.round(number - 0.5) + 0.5;
};

export enum LimitsMode {
  ContainCenter = 'ContainCenter', // The center of the camera must always be within the bounds
  ContainView = 'ContainView', // The edge of the camera's view must always be within the bounds
  None = 'None', // Don't constrain the camera to the bounds
}

export type CameraNodeState = StateDef<
  {
    position: Vector2;
    magnification: number;
    minMagnification: number;
    maxMagnification: number;
    viewportSize: Dimensions;
    limits: Partial<Bounds>;
    limitsMode: LimitsMode;
    limitsPadding: number;
    autoPan: boolean;
    autoPanIncrement: number; // Kinda gross, whenever this number is incremented, the auto-pan callback will get called.
    moving: boolean;
    roundPosition: boolean;
  },
  [],
  Record<string, never>,
  CameraNode
>;

const DEFAULT_STATE: CameraNodeState['state'] = {
  position: { x: 0, y: 0 },
  magnification: 1,
  minMagnification: 0.1,
  maxMagnification: 6,
  viewportSize: { width: 0, height: 0 },
  limits: {},
  limitsMode: LimitsMode.None,
  limitsPadding: 50,
  autoPan: true,
  autoPanIncrement: 0,
  moving: false,
  roundPosition: false,
};

export enum CameraNodeEvent {
  Update = 'Update',
}

export type CameraNodeEventActions = NodeEventActions & {
  [CameraNodeEvent.Update]: () => void;
};

class CameraNode extends GraphicNode {
  type = 'CameraNode';

  eventBus: EventBus<CameraNodeEventActions> = this.eventBus;

  readonly state: State<CameraNodeState>;

  #debugScale: number = 1;
  #debugGraphics: Graphics | null = null;

  MAGNIFICATION_SPEED: number = 500;
  MAGNIFICATION_MULTIPLIER: number = 2;

  AUTO_PAN_MARGIN: number = 0.1; // Percentage distance from the edge of the screen where auto-panning should start. (0=edge, 1=center of canvas)
  AUTO_PAN_SPEED: number = 500; // Maximum amount of units to move each second
  #autoPanScreenPosition: Vector2 | null = null;
  #autoPanLastTime: number = Date.now(); // TODO: Tick isn't run every frame, so deltaTime is the time between renders, which can be huge.
  #autoPanCallback: (() => void) | null = null;
  #trackedWorldPoints: { worldPosition: Vector2; callback: (screenPosition: Vector2) => void }[] = [];

  constructor(initialState: Partial<CameraNodeState['state']> = {}) {
    super();

    this.shouldProxyContent = true;

    this.state = new State<CameraNodeState>({
      ...DEFAULT_STATE,
      ...initialState,
    });
    bindState(this.state, this);

    this.state.addValidator(
      (state) => {
        state.values.magnification = Math.max(Math.min(state.values.maxMagnification, state.values.magnification), state.values.minMagnification);
        state.values.position = this.#constrainToLimits(state.values.position, true);
      },
      {
        properties: ['limits', 'limitsMode', 'limitsPadding', 'magnification', 'position', 'viewportSize', 'maxMagnification', 'minMagnification'],
      }
    );

    this.state.addWatcher(
      (state) => {
        this.#updateViewport();
        if (state.changed.properties.autoPanIncrement && this.#autoPanCallback) {
          this.#autoPanCallback();
        }
      },
      undefined,
      false
    );

    this.contexts.add(new CameraContext());

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onResize = (width: number, height: number) => {
    this.state.values.viewportSize = { width, height };
  };

  #onTick = () => {
    if (this.#autoPanScreenPosition !== null) {
      this.#scrollEdge();
    }
  };

  #onBind = () => {
    if (!this.engine) {
      return;
    }
    const context = this.getContext(ContentRootContext);
    context.registerCamera(this);
    this.#updateViewport();

    this.#onResize(this.engine.width, this.engine.height);
    this.engine.eventBus.on(EngineEvent.Resize, this.#onResize);
    this.engine.eventBus.on(EngineEvent.Tick, this.#onTick);
  };

  #onBeforeUnbind = () => {
    if (!this.engine) {
      return;
    }
    const context = this.getContext(ContentRootContext);
    context.unregisterCamera(this);
    this.engine.eventBus.off(EngineEvent.Resize, this.#onResize);
    this.engine.eventBus.off(EngineEvent.Tick, this.#onTick);
  };

  /**
   * Constrains the given position to be within the bounds of the camera.
   * @param pos The camera position
   * @returns The position, constrained to the bounds
   */
  #constrainToLimits(pos: Vector2, useVolatile: boolean = false) {
    const state = useVolatile ? this.state.values : this.state.currentValues;
    switch (state.limitsMode) {
      case LimitsMode.ContainCenter: {
        const minX = (state.limits?.left ?? -Infinity) + state.limitsPadding / state.magnification;
        const maxX = (state.limits?.right ?? Infinity) - state.limitsPadding / state.magnification;
        const minY = (state.limits?.top ?? -Infinity) + state.limitsPadding / state.magnification;
        const maxY = (state.limits?.bottom ?? Infinity) - state.limitsPadding / state.magnification;
        return {
          x: Math.max(minX, Math.min(maxX, pos.x)),
          y: Math.max(minY, Math.min(maxY, pos.y)),
        };
      }
      case LimitsMode.ContainView: {
        const minX = (state.limits?.left ?? -Infinity) - (state.viewportSize.width / 2 - state.limitsPadding) / state.magnification;
        const maxX = (state.limits?.right ?? Infinity) + (state.viewportSize.width / 2 - state.limitsPadding) / state.magnification;
        const minY = (state.limits?.top ?? -Infinity) - (state.viewportSize.height / 2 - state.limitsPadding) / state.magnification;
        const maxY = (state.limits?.bottom ?? Infinity) + (state.viewportSize.height / 2 - state.limitsPadding) / state.magnification;
        return {
          x: Math.max(minX, Math.min(maxX, pos.x)),
          y: Math.max(minY, Math.min(maxY, pos.y)),
        };
      }
      case LimitsMode.None:
      default: {
        return pos;
      }
    }
  }

  /**
   * Moves the camera
   * @param delta The delta to move by
   */
  move(delta: Vector2) {
    const state = this.state.values;
    state.position = {
      x: state.position.x + delta.x / state.magnification,
      y: state.position.y + delta.y / state.magnification,
    };
  }

  lookAt(position: Vector2) {
    const state = this.state.values;
    state.position = position;
  }

  /**
   * Zooms the camera
   * @param delta The delta to zoom by
   * @param localPos The center of the zoom. Defaults to the center of the screen.
   */
  alterZoom(delta: number, localPos?: Vector2) {
    const state = this.state.values;
    const _localPos = localPos || {
      x: state.viewportSize.width / 2,
      y: state.viewportSize.height / 2,
    };
    const posBefore = this.getWorldPos(_localPos, true);

    const newMagnification =
      this.MAGNIFICATION_MULTIPLIER **
      ((this.MAGNIFICATION_SPEED * (Math.log(state.magnification) / Math.log(this.MAGNIFICATION_MULTIPLIER)) - delta) / this.MAGNIFICATION_SPEED);
    state.magnification = Math.max(Math.min(state.maxMagnification, newMagnification), state.minMagnification);

    const posAfter = this.getWorldPos(_localPos, true);
    const posDelta = Geometry.getPointDelta(posBefore, posAfter);

    state.position = {
      x: state.position.x - posDelta.x,
      y: state.position.y - posDelta.y,
    };
  }

  /**
   * Zooms the camera, but by a percentage rather than a delta.
   * @param scale The percentage of the current magnification to move to
   * @param localPos The center of the zoom. Defaults to the center of the screen.
   */
  scaleZoom(scale: number, localPos?: Vector2) {
    const state = this.state.values;
    const _localPos = localPos || {
      x: state.viewportSize.width / 2,
      y: state.viewportSize.height / 2,
    };
    const posBefore = this.getWorldPos(_localPos, true);

    const newMagnification = state.magnification * scale;
    state.magnification = Math.max(Math.min(state.maxMagnification, newMagnification), state.minMagnification);

    const posAfter = this.getWorldPos(_localPos, true);
    const posDelta = Geometry.getPointDelta(posBefore, posAfter);

    state.position = {
      x: state.position.x - posDelta.x,
      y: state.position.y - posDelta.y,
    };
  }

  /**
   * Automaticaly pans the camera if the given screen position is close to the edge of the viewport.
   * @param screenPosition The current screen position of the drag, or false if the drag has ended
   * @param callback The callback to run every frame the camera moves.
   */
  autoPan(screenPosition: Vector2, callback: () => void);
  autoPan(screenPosition: false);
  autoPan(screenPosition: Vector2 | false, callback?: () => void) {
    if (!this.state.values.autoPan) {
      return;
    }
    if (this.#autoPanScreenPosition === null && screenPosition !== false) {
      this.#autoPanLastTime = Date.now();
    }
    this.#autoPanScreenPosition = screenPosition || null;
    this.#autoPanCallback = callback ?? null;
  }

  /**
   * Returns the bounds of the viewport, in world coordinates
   */
  get visibleBounds(): Bounds {
    const state = this.state.currentValues;
    const topLeft = this.getWorldPos({ x: 0, y: 0 });
    const bottomRight = this.getWorldPos({
      x: state.viewportSize.width,
      y: state.viewportSize.height,
    });
    return {
      top: topLeft.y,
      left: topLeft.x,
      right: bottomRight.x,
      bottom: bottomRight.y,
    };
  }

  /**
   * Converts a position in the world to a position on the screen.
   * @param worldPos The position to convert
   * @returns The equivelant screen position
   */
  getScreenPos(worldPos: Vector2, useVolatile: boolean = false): Vector2 {
    const state = useVolatile ? this.state.values : this.state.currentValues;
    const diff = Geometry.getPointDelta(state.position, worldPos);
    return {
      x: diff.x * state.magnification + state.viewportSize.width / 2,
      y: diff.y * state.magnification + state.viewportSize.height / 2,
    };
  }

  /**
   * Convertsd a position on the screen to a position in the world.
   * @param localPos The position to convert
   * @returns The equivelant world position
   */
  getWorldPos(localPos: Vector2, useVolatile: boolean = false): Vector2 {
    const state = useVolatile ? this.state.values : this.state.currentValues;
    const distFromCenter: Vector2 = {
      x: localPos.x - state.viewportSize.width / 2,
      y: localPos.y - state.viewportSize.height / 2,
    };
    return {
      x: state.position.x + distFromCenter.x / state.magnification,
      y: state.position.y + distFromCenter.y / state.magnification,
    };
  }

  /**
   * Converts a movement on the screen to a movement in the world.
   * @param screenDelta The della to convert
   * @returns The equivelant world delta
   */
  getWorldDelta(screenDelta: Vector2, useVolatile: boolean = false): Vector2 {
    const state = useVolatile ? this.state.values : this.state.currentValues;
    return {
      x: screenDelta.x / state.magnification,
      y: screenDelta.y / state.magnification,
    };
  }

  /**
   * Internally updates the camera graphics to maintain the scale of the HUD.
   */
  #updateViewport() {
    // const state = this.state.currentValues;
    // this.container.x = state.position.x;
    // this.container.y = state.position.y;
    // this.container.scale = { x: 1 / state.magnification, y: 1 / state.magnification };
    const context = this.tryGetContext(ContentRootContext);
    context?.onCameraUpdate(this);

    if (this.#trackedWorldPoints.length > 0) {
      this.#trackedWorldPoints.forEach(({ worldPosition, callback }) => {
        callback(this.getScreenPos(worldPosition));
      });
    }
  }

  /**
   * Pans the view if the cursor is near the side of the screen during a drag.
   */
  #scrollEdge() {
    const deltaTime = Date.now() - this.#autoPanLastTime;
    this.#autoPanLastTime = Date.now();

    const screenPos = this.#autoPanScreenPosition;
    const { viewportSize } = this.state.values;

    if (screenPos === null) {
      return;
    }

    const autoPanMarginX = (viewportSize.width / 2) * this.AUTO_PAN_MARGIN;
    const autoPanMarginY = (viewportSize.height / 2) * this.AUTO_PAN_MARGIN;

    const leftDist = Math.min(screenPos.x - autoPanMarginX, 0);
    const topDist = Math.min(screenPos.y - autoPanMarginY, 0);
    const rightDist = Math.max(screenPos.x - (viewportSize.width - autoPanMarginX), 0);
    const bottomDist = Math.max(screenPos.y - (viewportSize.height - autoPanMarginY), 0);

    const xVel = Math.min(Math.max(-autoPanMarginX, leftDist + rightDist), autoPanMarginX) / autoPanMarginX;
    const yVel = Math.min(Math.max(-autoPanMarginY, topDist + bottomDist), autoPanMarginY) / autoPanMarginY;

    if (xVel !== 0 || yVel !== 0) {
      this.move({
        x: xVel * this.AUTO_PAN_SPEED * (deltaTime / 1000),
        y: yVel * this.AUTO_PAN_SPEED * (deltaTime / 1000),
      });
      this.state.values.autoPanIncrement++;
    }
  }

  /**
   * Applies a transformation to the given node, such that the node appears where it should on the screen
   *  based on the camera's state.
   * @param node The node to move
   */
  applyTransformationToNode(node: GraphicNode) {
    const state = this.state.currentValues;
    const debugScale = this.#debugScale; // (Default: 1) Can be used to scale down (or up) the screen to check outside the view area
    const x = -state.position.x * (state.magnification * debugScale) + state.viewportSize.width / 2;
    const y = -state.position.y * (state.magnification * debugScale) + state.viewportSize.height / 2;
    // If roundPosition is on, round to the nearest pixel + 0.5. This mimmicks the old renderers behaviour, specifically for outputting images.
    node.getContainer().x = state.roundPosition ? halfPixelRound(x) : x;
    node.getContainer().y = state.roundPosition ? halfPixelRound(y) : y;
    node.getContainer().scale = {
      x: state.magnification * debugScale,
      y: state.magnification * debugScale,
    };
  }

  /**
   * Moves the camera such that the given position in the world lines up with the given position on the screen.
   * @param worldPos The world position to align
   * @param screenPos The position on the screen to move that world pos to
   * @param useVolatile Should the volatile state be used for calculations.
   */
  alignWorldPosWithScreenPos(worldPos: Vector2, screenPos: Vector2, useVolatile: boolean = true) {
    const state = useVolatile ? this.state.values : this.state.currentValues;
    const screenPosInWorld = this.getWorldPos(screenPos, useVolatile);
    const diff = Geometry.getPointDelta(screenPosInWorld, worldPos);
    this.state.values.position = {
      x: state.position.x + diff.x,
      y: state.position.y + diff.y,
    };
  }

  /**
   * Draws an outline of the viewport to the screen. For debug purposes
   */
  #drawViewportOutline() {
    const { viewportSize } = this.state.values;
    this.#debugGraphics = this.#debugGraphics || new Graphics();
    this.ownGraphics.addChild(this.#debugGraphics);
    this.#debugGraphics
      .clear()
      .lineStyle({ color: 'red', width: 10 })
      .drawRect(
        (viewportSize.width / 2) * (1 - this.#debugScale),
        (viewportSize.height / 2) * (1 - this.#debugScale),
        viewportSize.width * this.#debugScale,
        viewportSize.height * this.#debugScale
      );
  }

  /**
   * Tracks the given position in the world, returning the screen-position of the coordinate whenever the camer aupdates
   * @param worldPosition The position in the world to track
   * @param callback The callback to run when the camera updates
   */
  trackWorldPositionOnScreen(worldPosition: Vector2, callback: (screenPos: Vector2) => void) {
    this.#trackedWorldPoints.push({ worldPosition, callback });
  }

  /**
   * Removes a world position tracking callback
   * @param callback The callback to remove
   */
  untrackWorldPositionOnScreen(callback: (screenPos: Vector2) => void) {
    this.#trackedWorldPoints = this.#trackedWorldPoints.filter((entry) => entry.callback !== callback);
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
    {
      type: InspectableClassDataType.Action,
      displayName: 'Move to Origin',
      callback() {
        this.state.values.position = { x: 0, y: 0 };
        this.state.values.magnification = 1;
      },
    },
    {
      type: InspectableClassDataType.Action,
      displayName: 'Draw Viewport Outline',
      callback: () => this.#drawViewportOutline(),
    },
    {
      type: InspectableClassDataType.CustomProperty,
      displayName: 'Debug Scale',
      propertyType: InspectableClassPropertyType.Number,
      value: () => this.#debugScale,
    },
    {
      type: InspectableClassDataType.Action,
      displayName: '- Debug Scale',
      callback: () => {
        this.#debugScale -= 0.1;
        if (this.#debugGraphics) {
          this.#drawViewportOutline();
        }
      },
    },
    {
      type: InspectableClassDataType.Action,
      displayName: '+ Debug Scale',
      callback: () => {
        this.#debugScale += 0.1;
        if (this.#debugGraphics) {
          this.#drawViewportOutline();
        }
      },
    },
  ];
}

export default CameraNode;
