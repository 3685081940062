import React, { ReactNode, createContext, useMemo } from 'react';

import { GuruPages } from '@gi/app-guru-types';

type GardenGuruDataContextType = {
  guruPages: GuruPages;
};

export const GardenGuruDataContext = createContext<GardenGuruDataContextType>({} as GardenGuruDataContextType);

interface iProps {
  guruPages: GuruPages;
  children?: ReactNode;
}

/**
 * Context for providing the page data for the Garden Guru, as it's optional from the resource loader, but required
 *  by the rest of the Guru.
 *
 * This context is separate from the GardenGuruContext, as storing the data there caused infinite loops due to the
 *  header updating, causing all pages dependant on `guruPages` to re-render, causing the header to update and loop...
 */
export const GardenGuruDataProvider = ({ guruPages, children }: iProps): JSX.Element => {
  const value = useMemo(() => ({ guruPages }), [guruPages]);

  return <GardenGuruDataContext.Provider value={value}>{children}</GardenGuruDataContext.Provider>;
};
