import { DeviceDisplayMode } from '@gi/constants';
// import { IntercomCommandApp, IntercomCommandSelector, IntercomCommandSideEffect } from './shortcuts/intercom-shortcut-types';
import { INTERCOM_COMMAND_WHITELIST, IntercomCommand } from './constants';
import { IntercomCommandSideEffect } from './intercom-commands';
import { IntercomCommandSideEffectInstruction, IntercomShortcutApp, IntercomShortcutSelector } from './shortcuts/intercom-shortcut-types';

export function isValidIntercomCommand(command: string): boolean {
  return INTERCOM_COMMAND_WHITELIST.includes(command as IntercomCommand);
}

export function validateIntercomCommand(command: string | null): IntercomCommand | null {
  if (command === null) {
    return null;
  }

  if (INTERCOM_COMMAND_WHITELIST.includes(command as IntercomCommand)) {
    return command as IntercomCommand;
  }

  return null;
}

export function validateIntercomCommandId(commandId: any): number | null {
  if (typeof commandId !== 'number') {
    return null;
  }

  if (Number.isNaN(commandId)) {
    return null;
  }

  return commandId;
}

/**
 * Validates a string provided as an id for a given command id selector
 */
export function validateIntercomCommandSelector(selector?: string | null): IntercomShortcutSelector {
  if (!selector) {
    return IntercomShortcutSelector.Default;
  }

  if (IntercomShortcutSelector[selector]) {
    return IntercomShortcutSelector[selector];
  }

  console.warn(`Unrecognised intercom command selector ${selector}`);

  return IntercomShortcutSelector.Default;
}

/**
 * Validates a string provided as a display mode and returns a DeviceDisplayMode or null
 */
export function validateIntercomCommandDisplayMode(displayMode?: string): DeviceDisplayMode.DESKTOP | DeviceDisplayMode.MOBILE | null {
  if (!displayMode) {
    return null;
  }

  if (displayMode.toLowerCase() === DeviceDisplayMode.DESKTOP.toLowerCase()) {
    return DeviceDisplayMode.DESKTOP;
  }

  if (displayMode.toLowerCase() === DeviceDisplayMode.MOBILE.toLowerCase()) {
    return DeviceDisplayMode.MOBILE;
  }

  if (DeviceDisplayMode[displayMode]) {
    return DeviceDisplayMode[displayMode];
  }

  console.warn(`Unrecognised display mode ${displayMode}`);

  return DeviceDisplayMode.DESKTOP;
}

const commandAppOptions: IntercomShortcutApp[] = [
  IntercomShortcutApp.Overview,
  IntercomShortcutApp.Planner,
  IntercomShortcutApp.Journal,
  IntercomShortcutApp.Guru,
];

export function validateIntercomShortcutApp(input?: any): IntercomShortcutApp | null {
  if (!input || typeof input !== 'string') {
    return null;
  }

  for (let i = 0; i < commandAppOptions.length; i++) {
    if (input.toLowerCase() === commandAppOptions[i].toLowerCase()) {
      return commandAppOptions[i];
    }
  }

  return null;
}

const commandSideEffectOptions: IntercomCommandSideEffect[] = [
  IntercomCommandSideEffect.ResetPlanner,
  IntercomCommandSideEffect.LaunchMobileIntroduction,
  IntercomCommandSideEffect.StartTutorial,
];

export function validateIntercomSideEffect(input?: any): IntercomCommandSideEffectInstruction | null {
  if (!input) {
    return null;
  }

  if (typeof input === 'string') {
    const index = commandSideEffectOptions.indexOf(input as IntercomCommandSideEffect);
    if (index !== -1) {
      return { type: commandSideEffectOptions[index] };
    }
    return null;
  }

  if (typeof input === 'object') {
    const instruction = validateIntercomSideEffect(input.type);
    if (instruction) {
      instruction.arguments = input.arguments; // No argument validation, should be done where consumed
      return instruction;
    }
    return null;
  }

  return null;
}

export function validateIntercomSideEffects(input?: any): IntercomCommandSideEffectInstruction[] {
  if (!Array.isArray(input)) {
    return [];
  }

  return input.map(validateIntercomSideEffect).filter((sideEffect): sideEffect is IntercomCommandSideEffectInstruction => sideEffect !== null);
}
