import React from 'react';

interface iProps {
  children: React.ReactNode;
}

const ModalContent = ({ children }: iProps) => {
  return <div className='modal-content'>{children}</div>;
};

export default ModalContent;
