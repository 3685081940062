import React, { useContext } from 'react';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

const ShowAreaTableHeaderCell = (): JSX.Element | null => {
  const { options, setOptions, columns } = useContext(GlobalPlantListContext);

  const icon = options.expandPlantAreas ? 'icon-down-dir' : 'icon-right-dir';
  const ariaLabel = options.expandPlantAreas ? 'Hide Area Rows' : 'Show Area Rows';

  if (!columns.area) {
    return null;
  }

  return (
    <th className='no-print header-cell'>
      <div className='header-cell-content'>
        <div className='header-row'>
          <button
            className='button button-borderless show-area-button row-button'
            type='button'
            aria-label={ariaLabel}
            onClick={() => {
              setOptions({ expandPlantAreas: !options.expandPlantAreas });
            }}
          >
            <i className={icon} />
          </button>
        </div>
      </div>
    </th>
  );
};

export default ShowAreaTableHeaderCell;
