import { AsyncOperation } from '@gi/utils';

import { SimulatedPlanBackgroundImage, SimulatedPlanBackgroundImageEvents } from '../simulation/simulated-plan-background-image';
import BackgroundImageNode from './nodes/background/background-image-node';
import CanvasLayers from './canvas-layers';

class CanvasPlanBackgroundImage {
  destroyed = false;

  #canvasLayers: CanvasLayers;
  #simulatedPlanBackgroundImage: SimulatedPlanBackgroundImage;

  #backgroundImageNode: BackgroundImageNode;

  constructor(simulatedBackgroundImage: SimulatedPlanBackgroundImage, canvasLayers: CanvasLayers) {
    this.#canvasLayers = canvasLayers;
    this.#simulatedPlanBackgroundImage = simulatedBackgroundImage;

    this.#backgroundImageNode = new BackgroundImageNode({
      imageSrc: simulatedBackgroundImage.imageURL,
      position: simulatedBackgroundImage.position,
      dimensions: simulatedBackgroundImage.dimensions,
      rotation: simulatedBackgroundImage.rotation,
    });

    this.#simulatedPlanBackgroundImage.on(SimulatedPlanBackgroundImageEvents.DidUpdate, this.#onSimulatedBackgroundUpdate);
    this.#simulatedPlanBackgroundImage.on(SimulatedPlanBackgroundImageEvents.ImageStateDidUpdate, this.#onImageStateUpdate);

    this.#canvasLayers.backgroundLayer.addChildren(this.#backgroundImageNode);
  }

  #onSimulatedBackgroundUpdate = () => {
    this.#backgroundImageNode.setPosition(this.#simulatedPlanBackgroundImage.position);
    this.#backgroundImageNode.setDimensions(this.#simulatedPlanBackgroundImage.dimensions);
    this.#backgroundImageNode.setRotation(this.#simulatedPlanBackgroundImage.rotation);
  };

  #onImageStateUpdate = (image: AsyncOperation<string>) => {
    this.#backgroundImageNode.setImageScr(image);
  };

  destroy() {
    if (this.destroyed) {
      console.error('Attempted to destroy already destroyed CanvasPlanBackgroundImage');
      return;
    }
    this.destroyed = true;
    this.#backgroundImageNode.destroy();
  }
}

export default CanvasPlanBackgroundImage;
