import React, { ReactNode, useContext } from 'react';
import { useMergeRefs } from '@floating-ui/react';

import { LocalTooltipContext } from './tooltip';

interface iProps {
  children?: ReactNode;
  asWrapper?: boolean;
}

const TooltipTrigger = React.forwardRef<HTMLElement, iProps>(({ children, asWrapper = false }, propRef) => {
  const { getTriggerProps, refs, open } = useContext(LocalTooltipContext);

  // Still allow us to define a ref directly on the children without it getting overwritten
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([refs.setReference, propRef, childrenRef]);

  if (asWrapper) {
    return (
      <div
        {...getTriggerProps({
          ref,
          'data-state': open ? 'open' : 'closed',
        } as React.HTMLProps<Element>)}
      >
        {children}
      </div>
    );
  }

  // If possible, attach everything directly to the component so we don't have to pass props around
  if (React.isValidElement(children)) {
    return React.cloneElement(
      children,
      getTriggerProps({
        ref,
        ...children.props,
        'data-state': open ? 'open' : 'closed',
      } as React.HTMLProps<Element>)
    );
  }

  return children;
});
TooltipTrigger.displayName = 'TooltipTrigger';

export default TooltipTrigger;
