import React from 'react';

import styles from './sidebar-styles.module.css';

interface iProps {
  icon: string;
}

const SidebarButtonIcon = ({ icon }: iProps): JSX.Element => {
  return <i className={`${styles.buttonIcon} ${icon}`} />;
};

export default SidebarButtonIcon;
