import { useContext, useEffect, useMemo, useRef } from 'react';

import { GuruHeaderSlice } from '../context/types';
import GardenGuruContext from '../context/garden-guru-context';

interface iProps {
  background?: GuruHeaderSlice['background'];
  backgroundContent?: GuruHeaderSlice['backgroundContent'];
  children?: GuruHeaderSlice['content'];
  noContent?: boolean;
}

/**
 * Overwrites part or all of the guru header with the content given.
 * Only specifying some properties will just set those properties, allowing these headers to stack.
 */
const GuruHeaderContentPortal = ({ background, backgroundContent, children, noContent }: iProps): null => {
  const { registerSlice, updateSlice, unregisterSlice } = useContext(GardenGuruContext);

  const id = useRef<string>((Math.random() + 1).toString(36).substring(2)); // Rubbish replacement for UUIDs
  const isFirstRender = useRef<boolean>(true); // Don't run updateSlice on first render, there's no point

  const slice = useMemo<GuruHeaderSlice>(() => {
    return {
      id: id.current,
      background,
      backgroundContent,
      content: noContent ? null : children,
    };
  }, [background, backgroundContent, children, noContent]);

  useEffect(() => {
    registerSlice(slice);

    return () => {
      unregisterSlice(id.current);
    };
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      updateSlice(slice);
    }
  }, [slice]);

  return null;
};

export default GuruHeaderContentPortal;
