import React, { ChangeEvent, ReactNode, useRef } from 'react';

import { InputContainer } from '@gi/form-responsive';

import './searchbar.scss';

interface iProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  filterComponent?: ReactNode;
}

const Searchbar = ({ placeholder = 'Search...', value, onChange, filterComponent }: iProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const clear = () => {
    onChange('');
    if (ref.current) {
      ref.current.focus();
    }
  };

  return (
    <div className='searchbar-container'>
      <div className='searchbar'>
        <InputContainer size='full' ref={ref}>
          <input type='text' placeholder={placeholder} value={value} onChange={handleOnChange} />
        </InputContainer>
        <div className='searchbar-buttons'>
          {value.length > 0 ? (
            <button type='button' className='searchbar-clear' onClick={clear}>
              <i className='icon-cancel' />
            </button>
          ) : null}
        </div>
      </div>
      {filterComponent ? filterComponent : null}
    </div>
  );
};

export default Searchbar;
