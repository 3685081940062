import React, { useContext } from 'react';

import Plant from '@gi/plant';
import { DistanceUnits } from '@gi/units';

import GlobalPlantListContext from '../global-provider/global-plant-list-context';
import TableRow from '../common/table-row';
import { PlantListAreaGroup } from '../types/plant-list-types';
import CalendarCell from './cells/calendar-cell';
import CountsCell from './cells/counts-cell';
import FavouriteCell from './cells/favourite-cell';
import IconCell from './cells/icon-cell';
import InGroundDatesCell from './cells/in-ground-dates-cell';
import InformationCell from './cells/information-cell';
import PlantLabelCell from './cells/plant-label-cell';
import PlantNameCell from './cells/plant-name-cell';
import SpacingsCell from './cells/spacings-cell';

interface iProps {
  areaGroup: PlantListAreaGroup;
  plant: Plant;
  evenRow?: boolean;
  distanceUnits: DistanceUnits;
}

const AreaTableRow = ({ areaGroup, plant, evenRow = false, distanceUnits }: iProps) => {
  const tags = areaGroup.planPlant.isSquareFoot ? ['SFG'] : [];

  const { areaGroupFilters } = useContext(GlobalPlantListContext);

  const { plantSearchResults, varietySearchResults } = areaGroupFilters.filters.search.inputs;

  return (
    <TableRow className={`area-tr main-tr  ${evenRow ? 'tr-even' : 'tr-odd'}`}>
      <InformationCell plant={plant} />
      <FavouriteCell plant={plant} />
      <IconCell plantCode={plant.code} familyID={plant.familyID} />
      <PlantNameCell
        plant={plant}
        varietyName={areaGroup.planPlant.variety}
        tags={tags}
        showTags
        plantSearchResults={plantSearchResults}
        varietySearchResults={varietySearchResults}
      />
      <PlantLabelCell planPlant={areaGroup.planPlant} />
      <SpacingsCell spacings={areaGroup.spacing} countsByType={areaGroup.countsByType} distanceUnits={distanceUnits} />
      <CountsCell count={areaGroup.count} />
      <InGroundDatesCell planPlant={areaGroup.planPlant} />
      <CalendarCell plantingCalendar={areaGroup.plantingCalendar} />
    </TableRow>
  );
};

export default AreaTableRow;
