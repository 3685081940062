import React, { useContext } from 'react';

import { GuruVideoContentPage } from '@gi/app-guru-types/pages';
import { ResourceContext } from '@gi/resource-provider';

import PageContainer from '../page-container';
import GuruHeaderContentPortal from '../../header/guru-header-content-portal';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import RelatedContentSection from '../../components/related-content/related-content-section';
import PlaceholderBackground from '../../components/placeholder/placeholder-background';
import ControlsBanner from '../../components/controls-banner/controls-banner';
import BookmarkButton from '../../components/controls-banner/bookmark-button';
import ContentPageContentColumn from '../../components/content-page/content-page-content-column';
import GuruContentContainer from '../../components/content/guru-content-container';
import PublishDate from '../../components/content/publish-date';
import ControlsBannerSection from '../../components/controls-banner/controls-banner-section';

import styles from './video-content-display.module.css';

interface iProps {
  guruVideo: GuruVideoContentPage;
}

const VideoContentDisplay = ({ guruVideo }: iProps): JSX.Element => {
  const { userCountry } = useContext(ResourceContext);

  return (
    <PageContainer className={`${styles.videoContentDisplay}`}>
      <GuruHeaderContentPortal background={guruVideo.backgroundImage} noContent />
      <div className='guru-section-breadcrumbs'>
        <div className='guru-narrow-content-width'>
          <Breadcrumbs />
        </div>
      </div>
      <div className='guru-section'>
        <ContentPageContentColumn className='guru-content-width'>
          <GuruContentContainer>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={styles.videoContent}>
              <PlaceholderBackground className={styles.videoContainer} dangerouslySetInnerHTML={{ __html: guruVideo.content.content }} />
              <div className={styles.videoDetails}>
                <h1 className={styles.title}>{guruVideo.title}</h1>
                <div>{guruVideo.description}</div>
                <ControlsBanner>
                  <ControlsBannerSection>
                    <PublishDate published={guruVideo.content.published} format={userCountry?.longDateFormat} />
                  </ControlsBannerSection>
                  <ControlsBannerSection>
                    <BookmarkButton contentUUID={guruVideo.contentUUID} />
                  </ControlsBannerSection>
                </ControlsBanner>
              </div>
            </div>
          </GuruContentContainer>
        </ContentPageContentColumn>
      </div>
      <RelatedContentSection url={guruVideo.relatedContentUrl} outerClassName='guru-section-alt' innerClassName='guru-content-width guru-pad-content' />
    </PageContainer>
  );
};

export default VideoContentDisplay;
