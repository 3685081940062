import React, { useContext } from 'react';
import { DISPLAY_TYPE } from '../../constants';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

interface iProps {
  buttonDisplayType: DISPLAY_TYPE;
  children: React.ReactNode;
}

const ColumnSortButton = ({ children, buttonDisplayType }: iProps): JSX.Element | null => {
  const { displayType } = useContext(GlobalPlantListContext);

  if (displayType === buttonDisplayType) {
    // Has to be a fragment otherwise typescript doesn't like the type of 'children' bring returned
    // even though it's fine.
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
};

export default ColumnSortButton;
