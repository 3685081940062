import React from 'react';

import './dashboard-item.scss';

interface iProps {
  className?: string;
  children: React.ReactNode;
  title: React.ReactNode;
}

const DashboardItem = ({ className = '', children, title }: iProps): JSX.Element => {
  return (
    <div className={`dashboard-item ${className}`}>
      {title === '' ? null : <div className='dashboard-item-title'>{title}</div>}
      <div className='dashboard-item-content'>
        <div className='dashboard-item-scrollpane'>{children}</div>
      </div>
    </div>
  );
};

export default DashboardItem;
