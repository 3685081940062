import React, { ReactNode } from 'react';

import './banner.scss';

interface iProps {
  children: ReactNode;
  className?: string;
}

const BannerTitle = ({ children, className }: iProps): JSX.Element => {
  const classNames = ['banner-title'];
  if (className) {
    classNames.push(className);
  }

  return <p className={classNames.join(' ')}>{children}</p>;
};

export default BannerTitle;
