import { Store, Unsubscribe } from 'redux';

import { NotificationTypes, NotificationActionCreators } from '@gi/notifications';
import { RepeatingGraphicDisplayModes, RenderMode, DeviceDisplayMode, WheelModes, PlannerControlsTab } from '@gi/constants';

import LocalSettingsStorage from './local-settings-storage';
import * as LocalSettingsActionCreators from './local-settings-action-creators';
import { LocalSettings } from './local-settings-type';

// Defaults to load when values are not found in local storage
const LOCAL_STORAGE_DEFAULTS: Partial<LocalSettings> = {
  wheelMode: WheelModes.ZOOM,
  plantDisplayMode: RepeatingGraphicDisplayModes.BLOCK,
  plantSpriteCountLimit: 100,
  snapToGrid: false,
  showEditToolbar: true,
  showViewToolbar: true,
  showLayerToolbar: true,
  showMonthToolbar: true,
  showCropRotationToolbar: true,
  debugMode: false,
  sfgMode: false,
  showSFGHelpOnToggle: true,
  enableSnapOnSFGMode: false,
  touchMode: false,
  showTouchIntroductionModalOnStart: true,
  renderMode: RenderMode.AUTO,
  dontShowDrawingToolsDragWarning: false,
  deviceDisplayMode: DeviceDisplayMode.SUGGEST,
  hideIntercomIntroduction: false,
  hideTouchControlsTutorial: false,
  minimiseRightSidebar: false,
  rightSidebarActiveTab: PlannerControlsTab.OBJECTIVES,
  hideItemLockingHelpNotifications: false,
  hideTouchDragHelpNotifications: false,
  textQuality: 4,
};

/**
 * Returns the current user ID if present, else returns null.
 *
 * Will likely always return a user ID but on startup it may return null if the user isn't set yet,
 * or if in future there's a way to use the planner without being logged in
 *
 * @param {State} state current store state
 * @returns {number|null}
 */
const getUserIDFromStore = (state) => {
  if (!state || !state.session || !state.session.user) {
    return null;
  }

  return state.session.user.ID;
};

type StoreState = {
  localSettings: LocalSettings;
};

class LocalSettingsStoreListener {
  store: Store<StoreState>;
  currentState: StoreState;
  unsubscribe: Unsubscribe | null;
  userID: number | null;

  /**
   * Creates an instance of LocalSettingsStoreSynchroniser.
   */
  constructor(store: Store) {
    this.store = store;
    this.currentState = store.getState();
    this.unsubscribe = null;
    this.userID = getUserIDFromStore(this.currentState);

    // Do an initial load from local storage
    this.loadFromLocalStorage();
  }

  /**
   * Returns true if the store listener is currently subscribed, else false
   */
  isListening() {
    return this.unsubscribe !== null;
  }

  private getLocalStorageValuesWithDefaults() {
    return LocalSettingsStorage.getAll(this.userID, LOCAL_STORAGE_DEFAULTS);
  }

  private loadFromLocalStorage() {
    if (this.userID === null) {
      console.debug('Loading from local storage when user is null');
    }

    this.store.dispatch(LocalSettingsActionCreators.setLocalSettings(this.getLocalStorageValuesWithDefaults()));
  }

  /**
   * Starts this listening to the store for changes
   */
  startListening() {
    if (!this.isListening()) {
      this.unsubscribe = this.store.subscribe(this.handleChange);
    }
  }

  /**
   * Stops listening to the store for changes
   */
  stopListening() {
    if (this.isListening()) {
      this.unsubscribe?.();
      this.unsubscribe = null;
    }
  }

  /**
   * Updates the user ID and dispatches an action to update the local settings in the store
   */
  setUserID(userID: number | null) {
    const isListening = this.isListening();
    if (isListening) {
      // Stop listening so we don't listen to the change from our setLocalSettings action being fired
      this.stopListening();
    }

    // Load from local storage
    this.userID = userID;
    this.loadFromLocalStorage();

    if (isListening) {
      // Start listening, but only if we were listening before
      this.startListening();
    }
  }

  /**
   * Change listener for the store, when the store updates, if there was a change to localSettings
   * this also puts those changes in local storage
   */
  private handleChange = () => {
    const previousState = this.currentState;
    this.currentState = this.store.getState();

    const currentUserID = getUserIDFromStore(this.currentState);

    if (currentUserID !== getUserIDFromStore(previousState)) {
      // Treat user changes as a priority
      this.setUserID(currentUserID);
      return;
    }

    if (previousState.localSettings === this.currentState.localSettings) {
      // No change
      return;
    }

    if (!this.currentState.localSettings.initialised) {
      // Haven't yet initialised from local storage, so don't save this
      return;
    }

    if (!previousState.localSettings.initialised && this.currentState.localSettings.initialised) {
      // Has just initialised from local storage, so don't save this
      return;
    }

    if (this.userID === null) {
      // UserID is null, we can't save settings. Don't display an error notification, as we wont even try
      return;
    }

    // Sync changes to local storage, only if localSettings has changed in state and the userID hasn't changed
    const success = LocalSettingsStorage.setAll(this.userID, this.currentState.localSettings);

    if (!success) {
      // Error, dispatch an error
      this.store.dispatch(
        NotificationActionCreators.createDefaultNotification({
          title: 'Error Saving Local Settings',
          type: NotificationTypes.ERROR,
          icon: 'icon-attention-alt',
          canTimeout: false,
        })
      );
    }
  };
}

export default LocalSettingsStoreListener;
