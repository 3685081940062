import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ResourceContext } from '@gi/resource-provider';
import { SessionSelectors } from '@gi/react-session';
import { RequestSelectors } from '@gi/react-requests';
import CustomiseVarietiesModal from '@gi/customise-varieties-modal';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { getDistanceUnitsFromIsMetric } from '@gi/units';
import { RequestsUtils } from '@gi/request';

import { EditPlantContext } from '../context/edit-plant-context';

const CustomiseVarietiesModalRenderer = () => {
  const { editPlantValues, customisingVarieties, plant, closeCustomiseVarieties, onCustomiseVarietiesSave } = useContext(EditPlantContext);
  const { userPlants, supplierCatalogue, userDistanceUnits, userRegionalPlantingCalendar } = useContext(ResourceContext);
  const user = useSelector(SessionSelectors.getUser);
  const requests = useSelector(RequestSelectors.getRequests);
  const plan = useSelector(CanvasSelectors.getActivePlan);

  const distanceUnits = useMemo(() => {
    if (plan !== null) {
      return getDistanceUnitsFromIsMetric(plan.plannerSettings.metric);
    }
    return userDistanceUnits;
  }, [plan?.plannerSettings.metric, userDistanceUnits]);

  if (!customisingVarieties) {
    return null;
  }

  if (user === null) {
    return null;
  }

  return (
    <CustomiseVarietiesModal
      defaultVariety={editPlantValues.values.variety}
      plants={userPlants}
      plant={plant}
      closeModal={closeCustomiseVarieties}
      supplierCatalogue={supplierCatalogue}
      distanceUnits={distanceUnits}
      user={user}
      savingUser={RequestsUtils.requestInProgress(requests, `SAVE_USER_${user.ID}`)}
      onSave={onCustomiseVarietiesSave}
      userRegionalPlantingCalendar={userRegionalPlantingCalendar}
    />
  );
};

export default CustomiseVarietiesModalRenderer;
