import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { canvasBackgroundUsageCompatibility } from '@gi/core-renderer';
import { IntercomActionCreators } from '@gi/intercom';

import { ModalPane, ModalPaneContainer, ModalPaneSection, ModalPaneSectionContent, ModalPaneSectionHeader } from '@gi/modal';

import './progress-bar.scss';

interface iProps {
  planPublished: boolean;
}

const PublishPlanIntroduction = ({ planPublished }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const canvasImageDataWarning = useMemo(() => {
    // Update the check each time we load, in case the user changes their settings
    const supportsImageData = canvasBackgroundUsageCompatibility.check();
    if (supportsImageData) {
      return null;
    }
    return (
      <div className='publish-modal-warning'>
        <p>Your browser may be blocking certain canvas features needed to generate an image of your plan to publish.</p>
        <p>
          If the published plan appears broken or incorrect, check your browser privacy settings, or{' '}
          <button
            className='button button-inline intercom-button'
            type='button'
            onClick={() => dispatch(IntercomActionCreators.openIntercomWithAnalytics('publish-plan-modal-missing-features'))}
          >
            contact support
          </button>{' '}
          for assistance.
        </p>
      </div>
    );
  }, []);

  if (planPublished) {
    return (
      <ModalPaneContainer className='publish-introduction'>
        <ModalPane>
          <ModalPaneSection>
            <ModalPaneSectionHeader>Update Published Plan</ModalPaneSectionHeader>
            <ModalPaneSectionContent>
              <p>Re-publish your plan to update the publish page with any changes since your last publish.</p>
              {canvasImageDataWarning}
            </ModalPaneSectionContent>
          </ModalPaneSection>
        </ModalPane>
      </ModalPaneContainer>
    );
  }

  return (
    <ModalPaneContainer className='publish-introduction'>
      <ModalPane>
        <ModalPaneSection>
          <ModalPaneSectionHeader>Publish Plan</ModalPaneSectionHeader>
          <ModalPaneSectionContent>
            <p>By publishing your plan it becomes available on a web page which you can share for others to view.</p>
            <p>
              <i>Please Note: If you make changes to your plan you will need to re-publish it for the webpage to update.</i>
            </p>
            {canvasImageDataWarning}
          </ModalPaneSectionContent>
        </ModalPaneSection>
      </ModalPane>
    </ModalPaneContainer>
  );
};

export default PublishPlanIntroduction;
