export type ReminderData = {
  dateYMD: string;
  note: string;
  relatedPlants: string[];
  dismissed: boolean;
};

export type Reminder = ReminderData & {
  ID: number;
};

export const JOURNAL_DATE_FORMAT = 'YYYY-MM-DD';

export type Reminders = {
  byDate: Record<string, number[]>;
  dates: string[];
  byID: Record<string, Reminder>;
  IDs: number[];
};
