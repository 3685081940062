import React from 'react';

import './modal-header.scss';

interface iProps {
  children: React.ReactNode;
}

const ModalHeaderContent = ({ children }: iProps): JSX.Element => {
  return <div className='modal-header-content'>{children}</div>;
};

export default ModalHeaderContent;
