import React, { ReactNode } from 'react';

import { Bugsnag, BugsnagErrorBoundary } from '@gi/bugsnag';

import ErrorView from './error-view';

let ErrorBoundary: BugsnagErrorBoundary | null = null;

interface iProps {
  children: ReactNode;
}

/**
 * Error boundary that sits after most of the providers.
 *  This error screen has the ability to save open plans, as most errors captured here will be
 *  render errors that occur while using the app.
 */
const AppSuiteErrorBoundary = ({ children }: iProps): JSX.Element => {
  if (ErrorBoundary === null) {
    ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);
  }

  return <ErrorBoundary FallbackComponent={ErrorView}>{children}</ErrorBoundary>;
};

export default AppSuiteErrorBoundary;
