import React from 'react';

interface iProps {
  children: React.ReactNode;
  className?: string;
}

const ModalPaneSectionGroup = ({ children, className = '' }: iProps) => {
  return <div className={`modal-pane-section-group ${className}`}>{children}</div>;
};

export default ModalPaneSectionGroup;
