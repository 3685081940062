import React from 'react';
import GardenPlatform from '@gi/garden-platform';
import { addIntercomScript } from '@gi/intercom';
import config from '@gi/config/config';

import './app.scss';

// Add intercom script to page
addIntercomScript(config.intercom.id);

const App = (): JSX.Element => {
  return (
    <div className='web-garden-planner-container'>
      <GardenPlatform />
    </div>
  );
};

export default App;
