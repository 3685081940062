import { useState, useEffect } from 'react';

function noop() {}

// Hook
function useHover<T extends Element>(): [ref: T | null, setRef: (ref: T | null) => void, hovered: boolean] {
  const [value, setValue] = useState(false);
  const [ref, setRef] = useState<T | null>(null);

  const handleMouseEnter = () => setValue(true);
  const handleMouseLeave = () => setValue(false);

  useEffect(
    () => {
      if (ref) {
        ref.addEventListener('mouseenter', handleMouseEnter);
        ref.addEventListener('mouseleave', handleMouseLeave);

        return () => {
          ref.removeEventListener('mouseenter', handleMouseEnter);
          ref.removeEventListener('mouseleave', handleMouseLeave);
          // When the ref changes, we're no longer hovering this element, so reset to default.
          setValue(false);
        };
      }

      return noop;
    },
    [ref] // Recall only if ref changes
  );

  return [ref, setRef, value];
}

export default useHover;
