import React, { useContext } from 'react';
import ReactSelect from 'react-select';

import { Anchor, Drawer, DrawerContent, DrawerHeader } from '@gi/mobile-components';
import FormField, { FormFieldOptionsPreset, FormSection, InputContainer } from '@gi/form-responsive';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';
import { PickerButton } from '@gi/texture-picker';
import { presetPalettes, Swatch, SwatchTypes } from '@gi/palette';
import { DrawingToolsContext, ShapeIcons } from '@gi/drawing-tools';
import { ShapeType } from '@gi/constants';

import SelectableButton from '../selectable-button';
import SelectableButtonGroup from '../selectable-button-group';

import './shapes-drawer.scss';

// Styles for the small stroke width dropdown
const SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    minWidth: 'unset',
    width: '80px',
  }),
};

// Layout preset for all form fields so they line up
const FIELD_PRESET = (labelSize: number = 100): FormFieldOptionsPreset => ({
  desktop: {
    layout: 'row',
    labelSize,
  },
  mobile: {
    layout: 'row',
    labelSize,
  },
});

// Create stroke widths 1-20 for the user to choose from.
const strokeWidths = Array(20)
  .fill(0)
  .map((_, i) => ({ value: i + 1, label: `${i + 1}` }));

interface iProps {
  open: boolean;
  onClose: () => void;
  anchor?: Anchor;
}

const ShapesDrawer = ({ open, onClose, anchor = 'BOTTOM' }: iProps): JSX.Element => {
  const {
    shapeStrokeWidth,
    setShapeStrokeWidth,
    shapeFillSwatch,
    setShapeFillSwatch,
    shapeStrokeSwatch,
    setShapeStrokeSwatch,
    selectedShapeType,
    selectedShapeFill,
    onShapeSelected,
    cancelDraw,
  } = useContext(DrawingToolsContext);

  const getStrokeColor = (): string => {
    return shapeStrokeSwatch === null ? '0000000' : shapeStrokeSwatch.value;
  };

  const getStrokeWidth = (): number => {
    return shapeStrokeWidth === null ? 3 : shapeStrokeWidth.value;
  };

  const getFillColor = (): string => {
    return shapeFillSwatch === null || shapeFillSwatch.type !== SwatchTypes.COLOR ? '000000' : shapeFillSwatch.value;
  };

  const getFillTexture = (): null | string => {
    return shapeFillSwatch === null || shapeFillSwatch.type !== SwatchTypes.IMAGE ? null : shapeFillSwatch.value;
  };

  /**
   * Handles starting a draw of a shape
   * @param shapeType The shape type
   * @param isFilled Is the shape filles
   * @param strokeWidth Stroke width
   * @param strokeColor Stroke colour
   * @param fillColor Fill colour (null if using a texture)
   * @param fillTexture Fill texture (null if using a colour)
   */
  const drawShape = (shapeType: ShapeType, isFilled: boolean, strokeWidth: number, strokeColor: string, fillColor: string, fillTexture: null | string) => {
    if (!(shapeType in ShapeType)) {
      return;
    }

    onShapeSelected(shapeType, strokeWidth, strokeColor, isFilled ? fillColor : null, isFilled ? fillTexture : null);
  };

  /**
   * Handles starting a draw of the given shape, unless already selected, in which case the current draw is cancelled.
   * @param shapeType The shape to be drawn
   * @param isFilled Is the shape filled?
   */
  const toggleShape = (shapeType: ShapeType, isFilled: boolean = false) => {
    if (selectedShapeType === shapeType && !!selectedShapeFill === isFilled) {
      cancelDraw();
    } else {
      drawShape(shapeType, isFilled, getStrokeWidth(), getStrokeColor(), getFillColor(), getFillTexture());
    }
    onClose();
  };

  /**
   * Handles setting the stroke width and updating the current draw if applicable
   * @param option The chosen option
   */
  const onStrokeWidthChange = (option: { value: number; label: string }) => {
    setShapeStrokeWidth(option);
    if (selectedShapeType && selectedShapeType in ShapeType) {
      drawShape(selectedShapeType, selectedShapeFill ?? false, option.value, getStrokeColor(), getFillColor(), getFillTexture());
    }
  };

  /**
   * Handles setting the stroke colour and updating the current draw if applicable
   * @param swatch The chosen swatch
   */
  const onStrokeSwatchChange = (swatch: Swatch) => {
    setShapeStrokeSwatch(swatch);
    if (selectedShapeType && selectedShapeType in ShapeType) {
      drawShape(selectedShapeType, selectedShapeFill ?? false, getStrokeWidth(), swatch.value, getFillColor(), getFillTexture());
    }
  };

  /**
   * Handles setting the fill swatch and updating the current draw if applicable
   * @param swatch The chosen swatch
   */
  const onFillSwatchChange = (swatch: Swatch) => {
    setShapeFillSwatch(swatch);
    if (selectedShapeType && selectedShapeType in ShapeType) {
      drawShape(
        selectedShapeType,
        selectedShapeFill ?? false,
        getStrokeWidth(),
        getStrokeColor(),
        swatch.type === SwatchTypes.IMAGE ? '000000' : swatch.value,
        swatch.type === SwatchTypes.IMAGE ? swatch.value : null
      );
    }
  };

  /**
   * Returns true if the given shape is being drawn currently.
   * @param shape The shape the button activates
   * @param filled If the shape is filled
   * @returns A boolean
   */
  const isActive = (shape: ShapeType, filled?: boolean) => {
    return selectedShapeType === shape && filled === selectedShapeFill;
  };

  return (
    <Drawer open={open} anchor={anchor} onClose={onClose} width={500}>
      <DrawerHeader as='h2' onClose={onClose}>
        Add a Shape
      </DrawerHeader>
      <DrawerContent padX padY className='drawing-tools-shapes-mobile'>
        <FormSection padding={0} heading='Outline Shapes'>
          <FormField label='Line Width' htmlFor='strokeSize' layoutPreset={FIELD_PRESET(130)}>
            <InputContainer>
              <ReactSelect
                styles={SELECT_STYLES}
                isSearchable={false}
                id='strokeSize'
                options={strokeWidths}
                value={shapeStrokeWidth}
                onChange={onStrokeWidthChange}
                menuPlacement='auto'
                menuPortalTarget={document.body}
              />
            </InputContainer>
          </FormField>
          <FormField label='Line Color' htmlFor='strokeColor' layoutPreset={FIELD_PRESET(130)}>
            <PickerButton palettes={presetPalettes.colorPalettes} selectedSwatch={shapeStrokeSwatch} onSwatchSelect={onStrokeSwatchChange} />
          </FormField>
          <SelectableButtonGroup>
            <SelectableButton
              aria-label='Outline Rectangle'
              active={isActive(ShapeType.RECTANGLE, false)}
              onClick={() => toggleShape(ShapeType.RECTANGLE)}
              src={ShapeIcons.rect}
              alt='rectange'
              text='Add Rectangle'
            />
            <SelectableButton
              aria-label='Outline Triangle'
              active={isActive(ShapeType.TRIANGLE, false)}
              onClick={() => toggleShape(ShapeType.TRIANGLE)}
              src={ShapeIcons.triangle}
              alt='triangle'
              text='Add Triangle'
            />
            <SelectableButton
              aria-label='Outline Ellipse'
              active={isActive(ShapeType.ELLIPSE, false)}
              onClick={() => toggleShape(ShapeType.ELLIPSE)}
              src={ShapeIcons.ellipse}
              alt='ellipse'
              text='Add Ellipse'
            />
            <SelectableButton
              aria-label='Line'
              active={isActive(ShapeType.LINE, false)}
              onClick={() => toggleShape(ShapeType.LINE)}
              src={ShapeIcons.line}
              alt='line'
              text='Add Line'
            />
          </SelectableButtonGroup>
        </FormSection>
        <FormSection padding={0} heading='Filled Shapes'>
          <FormField label='Fill Color/Texture' htmlFor='strokeColor' layoutPreset={FIELD_PRESET(130)}>
            <PickerButton palettes={presetPalettes.withTexturesPalettes} selectedSwatch={shapeFillSwatch} onSwatchSelect={onFillSwatchChange} />
          </FormField>
          <SelectableButtonGroup>
            <SelectableButton
              aria-label='Outline Rectangle'
              active={isActive(ShapeType.RECTANGLE, true)}
              onClick={() => toggleShape(ShapeType.RECTANGLE, true)}
              src={ShapeIcons.rectFill}
              alt='rectange'
              text='Add Rectangle'
            />
            <SelectableButton
              aria-label='Outline Triangle'
              active={isActive(ShapeType.TRIANGLE, true)}
              onClick={() => toggleShape(ShapeType.TRIANGLE, true)}
              src={ShapeIcons.triangleFill}
              alt='triangle'
              text='Add Triangle'
            />
            <SelectableButton
              aria-label='Outline Ellipse'
              active={isActive(ShapeType.ELLIPSE, true)}
              onClick={() => toggleShape(ShapeType.ELLIPSE, true)}
              src={ShapeIcons.ellipseFill}
              alt='ellipse'
              text='Add Ellipse'
            />
          </SelectableButtonGroup>
        </FormSection>
      </DrawerContent>
    </Drawer>
  );
};

export default ShapesDrawer;
