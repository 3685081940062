import { BaseTexture, BatchRenderer, Color, ExtensionMetadata, ExtensionType, IBatchableElement, Texture, ViewableBuffer } from 'pixi.js-new';

import vertexShaderSrc from './shader.vert.glsl';
import fragmentShaderSrc from './shader.frag.glsl';
import ShadedSpriteShaderGenerator from './ShadedSpriteShaderGenerator';
import ShadedBatchGeometry from './ShadedBatchGeometry';

export const SHADED_SPRITE_RENDERER = 'ShadedSpriteRenderer';

interface IBatchableShadedElement extends IBatchableElement {
  _maskTexture: Texture;
  maskUvs: Float32Array;
  maskColour1Int: number;
  maskColour2Int: number;
  maskColour3Int: number;
  maskColour4Int: number;
}

/**
 * A Batch Renderer for rendering ShadedSprites.
 * Allows sprites to be re-coloured using a mask.
 * Sadly this has had to overwrite and extend of the original code a lot, rather than using `pixi-batch-render/er`.
 *  For some reason, that package experienced awful performace, and the documentation seemed inaccurate. (16/08/2023)
 */
class ShadedSpriteRenderer extends BatchRenderer {
  static extension: ExtensionMetadata = {
    name: SHADED_SPRITE_RENDERER,
    type: ExtensionType.RendererPlugin,
  };

  constructor(...args: ConstructorParameters<typeof BatchRenderer>) {
    super(...args);

    this.setShaderGenerator({
      vertex: vertexShaderSrc,
      fragment: fragmentShaderSrc,
    });

    this.geometryClass = ShadedBatchGeometry; // Use our custom geometry for this renderer.

    this.vertexSize += 1; // Add 1 for the mask texture ID
    this.vertexSize += 2; // Add 2 for the mask UVs
    this.vertexSize += 4; // Add 4 for the 4 colours
  }

  setShaderGenerator({
    vertex = vertexShaderSrc,
    fragment = fragmentShaderSrc,
  }: {
    vertex?: string;
    fragment?: string;
  } = {}): void {
    // Overwrite the shader generator to use our own. This gives us access to the mask texture.
    this.shaderGenerator = new ShadedSpriteShaderGenerator(vertex, fragment);
  }

  packInterleavedGeometry(element: IBatchableShadedElement, attributeBuffer: ViewableBuffer, indexBuffer: Uint16Array, aIndex: number, iIndex: number): void {
    /**
     * This is an almost 1-1 copy of the original function, but we also pack our extra texture, uvs
     *  and colours in here.
     */
    const { uint32View, float32View } = attributeBuffer;

    const packedVertices = aIndex / this.vertexSize;

    const { vertexData, uvs, maskUvs, indices, maskColour1Int, maskColour2Int, maskColour3Int, maskColour4Int } = element;

    const textureId = element._texture.baseTexture._batchLocation;
    const maskTextureId = (element._maskTexture ?? Texture.WHITE).baseTexture._batchLocation;

    const alpha = Math.min(element.worldAlpha, 1.0);
    const argb = Color.shared.setValue(element._tintRGB).toPremultiplied(alpha, (element._texture.baseTexture.alphaMode ?? 0) > 0);

    for (let i = 0; i < vertexData.length; i += 2) {
      float32View[aIndex++] = vertexData[i]; // Vertex position XY
      float32View[aIndex++] = vertexData[i + 1];
      float32View[aIndex++] = uvs[i]; // Base texture UVs
      float32View[aIndex++] = uvs[i + 1];
      float32View[aIndex++] = (maskUvs ?? uvs)[i]; // Mask UVs
      float32View[aIndex++] = (maskUvs ?? uvs)[i + 1];
      uint32View[aIndex++] = argb; // Tint/Alpha
      float32View[aIndex++] = textureId; // Base texture ID
      float32View[aIndex++] = maskTextureId; // Mask texture ID
      uint32View[aIndex++] = maskColour1Int ?? 0xffffff; // Mask colour 1
      uint32View[aIndex++] = maskColour2Int ?? 0xffffff; // Mask colour 2
      uint32View[aIndex++] = maskColour3Int ?? 0xffffff; // Mask colour 3
      uint32View[aIndex++] = maskColour4Int ?? 0xffffff; // Mask colour 4
    }

    for (let i = 0; i < indices.length; i++) {
      indexBuffer[iIndex++] = packedVertices + indices[i];
    }
  }

  buildTexturesAndDrawCalls(): void {
    /**
     * This is an almost 1-1 copy of the original function, but we also add our mask texture in here.
     * There's a lot of casting here, because PixiJS is built with `strictNullChecks: false`, so
     *  setting array values to null is fine, but it's not for our build settings.
     */
    const { _bufferedTextures, maxTextures } = this;
    const textures = _bufferedTextures as Array<BaseTexture | null>;
    const textureArrays = BatchRenderer._textureArrayPool;
    const { batch } = this.renderer;
    const boundTextures = this._tempBoundTextures as Array<BaseTexture | null>;
    const touch = this.renderer.textureGC.count;

    let TICK = ++BaseTexture._globalBatch;
    let countTexArrays = 0;
    let texArray = textureArrays[0];
    let start = 0;

    batch.copyBoundTextures(boundTextures as Array<BaseTexture>, maxTextures);

    for (let i = 0; i < this._bufferSize; ++i) {
      // Read in steps of 2, as each object adds 2 textures.
      const tex = textures[i * 2]!;
      const mask = textures[i * 2 + 1]!;

      textures[i * 2] = null;
      textures[i * 2 + 1] = null;
      if (tex._batchEnabled === TICK && mask._batchEnabled === TICK) {
        // eslint-disable-next-line no-continue
        continue;
      }

      if (texArray.count >= maxTextures) {
        batch.boundArray(texArray, boundTextures as Array<BaseTexture>, TICK, maxTextures);
        this.buildDrawCalls(texArray, start, i);
        start = i;
        texArray = textureArrays[++countTexArrays];
        ++TICK;
      }

      if (tex._batchEnabled !== TICK) {
        tex._batchEnabled = TICK;
        tex.touched = touch;
        texArray.elements[texArray.count++] = tex;
      }

      if (mask._batchEnabled !== TICK) {
        mask._batchEnabled = TICK;
        mask.touched = touch;
        texArray.elements[texArray.count++] = mask;
      }
    }

    if (texArray.count > 0) {
      batch.boundArray(texArray, boundTextures as Array<BaseTexture>, TICK, maxTextures);
      this.buildDrawCalls(texArray, start, this._bufferSize);
      ++countTexArrays;
      ++TICK;
    }

    // Clean-up
    for (let i = 0; i < boundTextures.length; i++) {
      boundTextures[i] = null;
    }
    BaseTexture._globalBatch = TICK;
  }

  render(element: IBatchableShadedElement): void {
    /**
     * This is an almost 1-1 copy of the original function, but we add our second texture to the list here.
     */
    if (!element._texture.valid) {
      return;
    }

    if (this._vertexCount + element.vertexData.length / 2 > this.size) {
      this.flush();
    }

    this._vertexCount += element.vertexData.length / 2;
    this._indexCount += element.indices.length;
    this._bufferedTextures[this._bufferSize * 2] = element._texture.baseTexture;
    this._bufferedTextures[this._bufferSize * 2 + 1] = (element._maskTexture ?? Texture.EMPTY).baseTexture;
    this._bufferedElements[this._bufferSize++] = element;
  }
}

export default ShadedSpriteRenderer;
