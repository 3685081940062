import React from 'react';

import { ImageEditorModalFormProps } from '../types';
import ImagePreview from '../components/image-preview/image-preview';

import styles from './styles.module.css';

interface iProps extends ImageEditorModalFormProps {
  image: HTMLImageElement;
}

const ImageLoaded = ({ image, values, setValues }: iProps): JSX.Element => {
  return (
    <div className={styles.imagePreview}>
      <ImagePreview image={image} values={values} setValues={setValues} />
    </div>
  );
};

export default ImageLoaded;
