import React from 'react';

import styles from './header.module.css';

interface iProps {
  className?: string;
  children: React.ReactNode;
}

const HeaderContainer = ({ className = '', children }: iProps) => {
  return <div className={`${styles.header} ${className} guru-content-width`}>{children}</div>;
};

export default HeaderContainer;
