import Quadtree, { QuadtreeItem } from 'quadtree-lib';

import Collider from './colliders/collider';

// The scale to render the debug minimap at.
const DEBUG_SCALE = 0.5;

/**
 * Creates a styled div with the given position and size, ready for appending.
 * @param x Left coordinate of the rectangle
 * @param y Top coordinate of the rectangle
 * @param width Width of the rectangle
 * @param height Height of the rectangle
 * @param outlineColor CSS color for the outline
 * @param fillColor CSS color for the fill. Defaults to transparent
 * @returns A styled div
 */
const createDebugRect = (x: number, y: number, width: number, height: number, outlineColor: string, fillColor: string = 'transparent') => {
  const box = document.createElement('div');
  box.style.position = 'absolute';
  box.style.top = `${y * DEBUG_SCALE}px`;
  box.style.left = `${x * DEBUG_SCALE}px`;
  box.style.width = `${width * DEBUG_SCALE}px`;
  box.style.height = `${height * DEBUG_SCALE}px`;
  box.style.backgroundColor = fillColor;
  box.style.boxSizing = 'border-box';
  box.style.border = `1px solid ${outlineColor}`;
  return box;
};

// Extend the quadtree item to support storing the collider it belongs to
interface QuadtreeCollider extends QuadtreeItem {
  collider: Collider;
}

class CollisionWorld {
  // Maximum elements in a quadtree segment before splitting.
  #MAX_ELEMENTS: number = 10;

  #width: number;
  get width() {
    return this.#width;
  }

  #height: number;
  get height() {
    return this.#height;
  }

  #quadtree: Quadtree<QuadtreeCollider>;
  #quadtreeColliders: Record<number, QuadtreeCollider> = {};

  // List of IDs of colliders that need to be updated
  #needsUpdate: Set<number> = new Set();
  // List of IDs of colliders that have had updates since the last recalculation.
  #hasUpdated: Set<number> = new Set();

  /**
   * Enabling draws a confusing minimap of the collision boxes from the world in an overlay for debugging.
   * See {@see CollisionWorld.#debugDraw} for colour information
   */
  #debug: boolean = false;
  #debugContainer: HTMLDivElement | null = null;
  #debugDrawTimeout: number | null = null;

  constructor(width: number, height: number) {
    this.#width = width;
    this.#height = height;
    this.#quadtreeColliders = [];
    this.#quadtree = new Quadtree({
      width: width <= 0 ? 1000 : width,
      height: height <= 0 ? 1000 : height,
      maxElements: this.#MAX_ELEMENTS,
    });

    if (this.#debug) {
      this.#debugContainer = document.createElement('div');
      this.#debugContainer.style.position = 'absolute';
      this.#debugContainer.style.top = '0';
      this.#debugContainer.style.left = '0';
      document.body.appendChild(this.#debugContainer);
    }
  }

  /**
   * Sets the width and height of the quadtree.
   * @param width The width of the world
   * @param height The height of the world
   */
  setSize(width: number, height: number) {
    this.#quadtree = new Quadtree({
      width: width <= 0 ? 1000 : width,
      height: height <= 0 ? 1000 : height,
      maxElements: this.#MAX_ELEMENTS,
    });
    const rects = Object.values(this.#quadtreeColliders);
    this.#quadtree.pushAll(rects);
  }

  // eslint-disable-next-line class-methods-use-this
  #getQuadtreeData(collider: Collider): QuadtreeCollider {
    const { min, max } = collider.getBoundingBox();
    return {
      x: min.x,
      y: min.y,
      width: max.x - min.x,
      height: max.y - min.y,
      collider,
    };
  }

  /**
   * Checks if the world has a collider with the given ID attached
   * @param id The id of the collider to find
   */
  hasCollider(id: number): boolean {
    return this.#quadtreeColliders[id] !== undefined;
  }

  /**
   * Returns the collider with the given ID if it's part of this world.
   * @param id The id of the collider to find
   * @returns The collider, or null if not found
   */
  getCollider(id: number): Collider | null {
    if (this.hasCollider(id)) {
      return this.#quadtreeColliders[id].collider;
    }
    return null;
  }

  /**
   * Internally remove a collider. Will not propagate the update to anything the collider is touching.
   */
  #removeCollider(colliderId: number) {
    const collider = this.#quadtreeColliders[colliderId];
    if (collider) {
      collider.collider.setWorld(null);
      this.#quadtree.remove(collider);
    }
    delete this.#quadtreeColliders[colliderId];
  }

  /**
   * Removes the given collider form this world.
   * @param collider The collider to remove
   */
  removeCollider(collider: Collider) {
    collider.contains.asArray().forEach((contained) => {
      contained.containedBy.remove(collider);
    });
    this.#removeCollider(collider.id);
    this.#deferredDebugDraw();
  }

  /**
   * Adds a collider to this world. Will be updated next time `update()` is called.
   * @param collider The collider to add
   */
  addCollider(collider: Collider) {
    this.#removeCollider(collider.id); // Remove it if it already exists
    this.#quadtreeColliders[collider.id] = this.#getQuadtreeData(collider);
    this.#quadtree.push(this.#quadtreeColliders[collider.id]);
    collider.setWorld(this);
    this.#needsUpdate.add(collider.id);
    this.#deferredDebugDraw();
  }

  /**
   * Updates the given collider.
   * Currently functions the same as `addCollider`, as that checks for duplicates.
   * @param collider The collider to update
   */
  updateCollider(collider: Collider) {
    this.addCollider(collider);
  }

  /**
   * Checks for collisions based on the bounding box of all colliders.
   * @param collider The collider to get the broad-phase collisions for
   * @returns A list of solliders that the given collider _could_ be colliding with
   */
  getCollisions(collider: Collider): Collider[] {
    const quadtreeEntry = this.#quadtreeColliders[collider.id] ?? this.#getQuadtreeData(collider);
    if (!quadtreeEntry) {
      return [];
    }

    const collisions = this.#quadtree
      .colliding({
        x: quadtreeEntry.x,
        y: quadtreeEntry.y,
        width: quadtreeEntry.width,
        height: quadtreeEntry.height,
      })
      .map((entry) => entry.collider);

    return collisions;
  }

  /**
   * Updates the collisions of everything that's been marked as needing an update.
   * Returns a list of IDs of the colliders that updated this update.
   */
  update() {
    this.#deferredDebugDraw();

    const colliders = Array.from(this.#needsUpdate);
    for (let i = 0; i < colliders.length; i++) {
      const collider = this.getCollider(colliders[i]);
      if (collider) {
        this.updateCollider(collider);
      }
    }

    console.debug(`🍎 Checking collisions for ${colliders.length} collider${colliders.length === 1 ? '' : 's'}`);
    for (let i = 0; i < colliders.length; i++) {
      const collider = this.getCollider(colliders[i]);
      if (collider) {
        collider.updateCollisions();
      }
    }

    const updates = Array.from(this.#hasUpdated);
    this.#hasUpdated.clear();
    this.#needsUpdate.clear();

    return updates;
  }

  /**
   * Tells this world that the given collider has updated in some way
   * This doesn't mean the collider will be re-processed next `update()`.
   * @param id The ID of the collider that has updated
   */
  notifyHasUpdated(id: number) {
    this.#hasUpdated.add(id);
    this.#deferredDebugDraw();
  }

  /**
   * Tells the world that the given collider needs to have its collisions recalculated.
   * This will be done next `update()` call.
   * @param id The ID of the collider that needs updating
   */
  notifyNeedsUpdate(id: number) {
    this.#needsUpdate.add(id);
  }

  /**
   * Internal func to redraw the debug minimap.
   * Do after a small delay to prevent spam updates and being slow.
   */
  #deferredDebugDraw() {
    if (!this.#debug) {
      return;
    }
    if (this.#debugDrawTimeout !== null) {
      window.clearTimeout(this.#debugDrawTimeout);
    }
    this.#debugDrawTimeout = window.setTimeout(() => {
      this.#debugDraw();
      this.#debugDrawTimeout = null;
    }, 500);
  }

  /**
   * Draws a debug minimap representation of the quadtree.
   * Green items are normal items
   * Red items are oversized items
   *  Most items will appear red, as oversized just means can't be moved into a smaller quadtree division
   */
  #debugDraw() {
    if (!this.#debugContainer) {
      return;
    }
    const debugContainer = this.#debugContainer;
    debugContainer.innerHTML = ''; // Anarchy - clear the div
    debugContainer.appendChild(createDebugRect(0, 0, this.#quadtree.width, this.#quadtree.height, '#000000', '#ffffff55'));
    debugContainer.style.pointerEvents = 'none';

    const drawQuadtree = (quadtree: Quadtree<QuadtreeCollider>) => {
      debugContainer.appendChild(createDebugRect(quadtree.x, quadtree.y, quadtree.width, quadtree.height, '#000000'));

      for (let i = 0; i < quadtree.contents.length; i++) {
        const item = quadtree.contents[i];
        debugContainer.appendChild(createDebugRect(item.x, item.y, item.width ?? 0, item.height ?? 0, '#00ff00', '#00ff0088'));
      }

      for (let i = 0; i < quadtree.oversized.length; i++) {
        const item = quadtree.oversized[i];
        debugContainer.appendChild(createDebugRect(item.x, item.y, item.width ?? 0, item.height ?? 0, '#ff0000', '#ff000088'));
      }

      const children = Object.keys(quadtree.children);
      for (let i = 0; i < children.length; i++) {
        const subQuadtree = quadtree.children[children[i]];
        if (subQuadtree && subQuadtree.tree) {
          drawQuadtree(subQuadtree.tree);
        }
      }
    };

    drawQuadtree(this.#quadtree);
  }
}

export default CollisionWorld;
