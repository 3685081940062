import React from 'react';
import { useSelector } from 'react-redux';

import ReactGardenCanvas, { GardenCanvasOverlay, GardenCanvasStateOverlay } from '@gi/react-garden-canvas';
import { useMobileNotificationPortal } from '@gi/notifications';
import { DesktopPlannerContextMenu } from '@gi/app-planner-desktop';
import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { OrientationTypes } from '@gi/constants';

import MobileDrawingTools from '../../garden-planner/drawing-tools/mobile-drawing-tools';
import MobilePlannerLauncher from './mobile-planner-launcher';
import OverlayToolbars from '../../garden-planner/toolbars/overlay-toolbars';
import EditorToolbar from '../../garden-planner/toolbars/editor-toolbar/editor-toolbar';

import './mobile-planner.scss';

const MobilePlanner = (): JSX.Element => {
  const notificationTarget = useMobileNotificationPortal<HTMLDivElement>();
  const deviceOrientation = useSelector(DisplayModeSelectors.getDeviceOrientation);

  const classNames = ['mobile-planner-content'];
  if (deviceOrientation === OrientationTypes.LANDSCAPE) {
    classNames.push('landscape');
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='mobile-planner-canvas'>
        <div className='mobile-planner-canvas-inner' ref={notificationTarget}>
          <ReactGardenCanvas />
          {/* Show canvas state like SFG mode, layer lock etc. */}
          <GardenCanvasStateOverlay />
          {/* Show any tutorial messages (disabled) */}
          {/* <CanvasTouchControlsTutorial /> */}
          {/* Show canvas loading/errors loading  */}
          <GardenCanvasOverlay />
          {/* Show any controls that display on top of the canvas (like view menu) */}
          <OverlayToolbars />
          {/* Show context menu if applicable */}
          <DesktopPlannerContextMenu />
        </div>
      </div>
      <div className='mobile-planner-toolbars'>
        <EditorToolbar />
      </div>
      <div className='mobile-planner-drawing-tools'>
        <MobileDrawingTools orientation={deviceOrientation} />
      </div>
      <MobilePlannerLauncher />
    </div>
  );
};

export default MobilePlanner;
