import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { InspectableClassData } from '../../types';
import { hasOwner } from '../../utils/asserts';
import InteractableComponent, { InteractableComponentCallbacks } from '../interactable/interactable-component';
import LongPressableComponentContext from './long-pressable-component-context';

/**
 * Long-Pressabe Component
 *  Handles long-press events via the InteractableComponent. Events can be generically listened
 *  for from within the context.
 */
class LongPressableComponent extends NodeComponent {
  type = 'LongPressableComponent';

  /**
   * Set to true to prevent long-presses being passed up to the context.
   */
  preventEmittingEvent: boolean = false;
  /**
   * Callback to run when the node is long-pressed.
   * By default, the event will be passed up to the context, where an event can be listened for.
   */
  onLongPress?: () => void = undefined;

  #cleanUpListeners: (() => void) | null;

  constructor() {
    super();

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasOwner(this);

    const interactableComponent = this.owner.components.get(InteractableComponent);

    if (!interactableComponent) {
      console.error('LongPressableComponent requires an InteractableComponent be attached to the Node');
      return;
    }

    interactableComponent.addListener('onLongPress', this.#onLongPress);

    this.#cleanUpListeners = () => {
      interactableComponent.removeListener('onLongPress', this.#onLongPress);
    };
  };

  #onBeforeUnbind = () => {
    this.#cleanUpListeners?.();
    this.#cleanUpListeners = null;
  };

  #onLongPress: InteractableComponentCallbacks['onLongPress'] = (event, interaction, controls) => {
    hasOwner(this);

    if (event.button !== 0) {
      return;
    }

    controls.stopPropagation();

    if (this.onLongPress) {
      this.onLongPress();
    }

    if (!this.preventEmittingEvent) {
      this.owner.getContext(LongPressableComponentContext).wasLongPressed(this.owner, event.worldPosition);
    }
  };

  inspectorData: InspectableClassData<this> = [];
}

export default LongPressableComponent;
