import EventBus from '../../event-bus';
import { AssetType, AssetTypeMapping } from '../../managers/assets/types';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import NodeComponent, { NodeComponentEvent, NodeComponentEventActions } from '../../node-component/node-component';

export enum AssetComponentEvents {
  Loaded = 'Ready',
  LoadError = 'LoadError',
}

export type AssetComponentEventsActions<T extends AssetType> = NodeComponentEventActions & {
  [AssetComponentEvents.Loaded]: (texture: AssetTypeMapping[T]) => void;
  [AssetComponentEvents.LoadError]: (error: Error) => void;
};

/**
 * Asset component
 * Loads a texture/asset, emitting an event when it's ready or errored.
 */
class AssetComponent<T extends AssetType> extends NodeComponent {
  type = 'AssetComponent';
  eventBus: EventBus<AssetComponentEventsActions<T>> = new EventBus(this.eventBus);

  #assetName: string | null;
  #assetType: T;
  #asset: AssetTypeMapping[T] | null = null;

  constructor(type: T, assetName?: string) {
    super();
    this.#assetType = type;
    this.assetName = assetName ?? null;

    this.eventBus.on(NodeComponentEvent.DidBind, () => {
      this.#tryLoadAsset();
    });

    this.eventBus.on(NodeComponentEvent.BeforeUnbind, () => {
      // We've lost the engine, and thus the texture technically. Should we kill the reference to the texture?
    });
  }

  get assetName() {
    return this.#assetName;
  }

  set assetName(name: string | null) {
    if (name === this.#assetName) {
      return;
    }
    this.#assetName = name;
    this.#tryLoadAsset();
  }

  get asset() {
    return this.#asset;
  }

  #tryLoadAsset() {
    if (this.assetName === null) {
      return;
    }
    const name = this.assetName;
    const type = this.#assetType;
    if (this.bound && this.owner?.engine) {
      this.owner.engine.assetManager
        .getAsset<T>(type, name, { waitIfUndefined: true, ignoreErrors: true })
        .then((asset) => {
          if (this.assetName === name) {
            this.#asset = asset;
            this.eventBus.emit(AssetComponentEvents.Loaded, asset);
            this.#triggerRerender();
          }
        })
        .catch((err) => {
          if (this.assetName === name) {
            this.eventBus.emit(AssetComponentEvents.LoadError, err);
            this.#triggerRerender();
          }
        });
    }
  }

  #triggerRerender() {
    if (this.owner && this.owner.engine) {
      this.owner.engine.flagHasUpdates();
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'asset',
      propertyType: InspectableClassPropertyType.Texture,
    },
  ];
}

export default AssetComponent;
