/* eslint-disable prefer-destructuring */
import { MathUtils } from '@gi/math';
import { NumberedPlantingCalendar } from '../planting-calendar-types';

export function parseLine(vals: number[]): boolean[] {
  const result: boolean[] = [];

  for (let i = 0; i < 12; i++) {
    result[2 * i] = vals[i] % 2 === 1;
    result[2 * i + 1] = (vals[i] % 10) - (vals[i] % 2) === 2;
  }

  return result;
}

export function parseSowPlantHarvest(months): [boolean[], boolean[], boolean[]] {
  const sow = parseLine(months);
  const plant = parseLine(months.map((m) => Math.floor(m / 10)));
  const harvest = parseLine(months.map((m) => Math.floor(m / 100)));

  return [sow, plant, harvest];
}

function unParse(sow: boolean[], plant: boolean[], harvest: boolean[]): NumberedPlantingCalendar {
  const sowPlantHarvest: Mutable<NumberedPlantingCalendar> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  for (let i = 0; i < sowPlantHarvest.length; i++) {
    sowPlantHarvest[i] =
      (sow[2 * i] ? 1 : 0) +
      (sow[2 * i + 1] ? 2 : 0) +
      (plant[2 * i] ? 10 : 0) +
      (plant[2 * i + 1] ? 20 : 0) +
      (harvest[2 * i] ? 100 : 0) +
      (harvest[2 * i + 1] ? 200 : 0);
  }
  return sowPlantHarvest;
}

export function regionalPlantingSeasonExtender(
  sowPlantHarvestIn: NumberedPlantingCalendar,
  lastFrostAdjust: number,
  firstFrostAdjust: number
): NumberedPlantingCalendar {
  // Adjusts regional planting dates when plant is under season extender
  // Splits dates at mid-January (Southern Hemisphere) or mid-July (Northern) and duplicates middle part
  const [sow, plant, harvest] = parseSowPlantHarvest(sowPlantHarvestIn);

  // Make sure they don't go below 0
  const lfHalfMonthsAdjust = Math.max(-MathUtils.toInt(lastFrostAdjust / 2), 0);
  const ffHalfMonthsAdjust = Math.max(MathUtils.toInt(firstFrostAdjust / 2), 0);

  // Define working array which is longer
  const sowShifted: boolean[] = []; // bool[24 + lfHalfMonthsAdjust + ffHalfMonthsAdjust];
  const plantShifted: boolean[] = []; // bool[24 + lfHalfMonthsAdjust + ffHalfMonthsAdjust];
  const harvestShifted: boolean[] = []; // bool[24 + lfHalfMonthsAdjust + ffHalfMonthsAdjust];
  const sowOut: boolean[] = []; // new bool[24];
  const plantOut: boolean[] = []; // new bool[24];
  const harvestOut: boolean[] = []; // new bool[24];

  // Duplicate middle section
  for (let i = 0; i < lfHalfMonthsAdjust; i++) {
    sowShifted[12 + lfHalfMonthsAdjust - i] = sow[12];
    plantShifted[12 + lfHalfMonthsAdjust - i] = plant[12];
    harvestShifted[12 + lfHalfMonthsAdjust - i] = harvest[12];
  }
  for (let i = 0; i < ffHalfMonthsAdjust; i++) {
    sowShifted[13 + lfHalfMonthsAdjust + i] = sow[13];
    plantShifted[13 + lfHalfMonthsAdjust + i] = plant[13];
    harvestShifted[13 + lfHalfMonthsAdjust + i] = harvest[13];
  }
  // Copy and shift existing dates
  for (let i = 0; i <= 12; i++) {
    sowShifted[i] = sow[i];
    plantShifted[i] = plant[i];
    harvestShifted[i] = harvest[i];
  }
  for (let i = 13; i < 24; i++) {
    sowShifted[i + lfHalfMonthsAdjust + ffHalfMonthsAdjust] = sow[i];
    plantShifted[i + lfHalfMonthsAdjust + ffHalfMonthsAdjust] = plant[i];
    harvestShifted[i + lfHalfMonthsAdjust + ffHalfMonthsAdjust] = harvest[i];
  }
  // Combine (wrap around) leto final array
  for (let i = 0; i < 24; i++) {
    sowOut[i] = sowShifted[i + lfHalfMonthsAdjust];
    plantOut[i] = plantShifted[i + lfHalfMonthsAdjust];
    harvestOut[i] = harvestShifted[i + lfHalfMonthsAdjust];
    if (i < ffHalfMonthsAdjust) {
      // Combine shifted end-of-year dates leto start-of-year
      sowOut[i] = sowOut[i] || sowShifted[24 + lfHalfMonthsAdjust + i];
      plantOut[i] = plantOut[i] || plantShifted[24 + lfHalfMonthsAdjust + i];
      harvestOut[i] = harvestOut[i] || harvestShifted[24 + lfHalfMonthsAdjust + i];
    }
    if (i >= 24 - lfHalfMonthsAdjust) {
      // Combine shifted start-of-year dates leto end-of-year
      sowOut[i] = sowOut[i] || sowShifted[i + lfHalfMonthsAdjust - 24];
      plantOut[i] = plantOut[i] || plantShifted[i + lfHalfMonthsAdjust - 24];
      harvestOut[i] = harvestOut[i] || harvestShifted[i + lfHalfMonthsAdjust - 24];
    }
  }

  return unParse(sowOut, plantOut, harvestOut);
}
