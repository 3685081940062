import { DEFAULT_TOOLTIPS, ParsedTooltips, TooltipContentDefinition, TooltipsFromAPI } from './types';

function parseTooltipReplacementString(replacementString: string): TooltipContentDefinition {
  const [command, parameter] = replacementString.split(':');

  switch (command.trim().toLowerCase()) {
    case 'key': {
      return [{ type: 'key', key: parameter.trim() }];
    }
    case 'parameter': {
      if (!parameter) {
        break;
      }
      const int = parseInt(parameter, 10);
      if (Number.isNaN(int)) {
        break;
      }
      return [{ type: 'parameter', parameter: int }];
    }
    default: {
      return [];
    }
  }

  return [];
}

export function parseTooltip(tooltipString: string): TooltipContentDefinition {
  const content: TooltipContentDefinition = [];

  if (tooltipString.length === 0) {
    return [];
  }

  const toReplace = /{{(.*?)}}/g.exec(tooltipString);
  if (!toReplace) {
    content.push({ type: 'string', content: tooltipString });
    return content;
  }

  if (toReplace.index !== 0) {
    content.push({ type: 'string', content: tooltipString.substring(0, toReplace.index) });
  }

  content.push(...parseTooltipReplacementString(toReplace[1]));

  const endOfReplace = toReplace.index + toReplace[0].length;

  if (endOfReplace >= tooltipString.length) {
    return content;
  }

  content.push(...parseTooltip(tooltipString.substring(endOfReplace)));

  return content;
}

export function parseTooltips(tooltipsFromAPI: TooltipsFromAPI = { tooltips: {} }): ParsedTooltips {
  const tooltipsToParse: TooltipsFromAPI['tooltips'] = {
    ...DEFAULT_TOOLTIPS,
    ...tooltipsFromAPI.tooltips,
  };

  const keys = Object.keys(tooltipsToParse);
  const tooltips: ParsedTooltips = {} as ParsedTooltips;

  for (let i = 0; i < keys.length; i++) {
    const tooltipContent = tooltipsToParse[keys[i]];
    tooltips[keys[i]] = parseTooltip(tooltipContent);
  }

  return tooltips;
}

export const DEFAULT_PARSED_TOOLTIPS: ParsedTooltips = parseTooltips();
