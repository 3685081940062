import React from 'react';

import networkConfig from '@gi/config/network-config';

interface iProps {
  plantCode?: string;
  filename?: string;
  className?: string;
}

const PlantIcon = ({ plantCode, filename, className }: iProps): JSX.Element => {
  const _className = `plant-icon ${className ? className : ''}`;

  if (plantCode) {
    return <img className={_className} src={`${networkConfig.assetBaseURL}plants/${plantCode}.svg`} alt={plantCode} />;
  }

  return <img className={_className} src={`${networkConfig.assetBaseURL}plants/${filename}`} alt={plantCode} />;
};

export default PlantIcon;
