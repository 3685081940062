import React, { ReactNode } from 'react';
import './vertical-nav-item.scss';

interface iProps {
  to?: string;
  onClick?: () => void;
  className?: string;
  children?: ReactNode;
  active?: boolean;
  topmost?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

const VerticalNavItem = ({ to, onClick, className, children, active, topmost = false, leftIcon, rightIcon }: iProps): JSX.Element => {
  const classNames = ['vertical-nav-item'];
  if (active) {
    classNames.push('active');
  }
  if (topmost) {
    classNames.push('topmost');
  }
  if (className) {
    classNames.push(className);
  }

  const itemBody = (
    <>
      {leftIcon && <span className='item-icon'>{leftIcon}</span>}
      <span className='item-content'>{children}</span>
      {rightIcon && <span className='item-icon'>{rightIcon}</span>}
    </>
  );

  if (to) {
    return (
      <a href={to} className={classNames.join(' ')}>
        {itemBody}
      </a>
    );
  }
  if (onClick) {
    return (
      <button type='button' onClick={onClick} className={classNames.join(' ')}>
        {itemBody}
      </button>
    );
  }
  return <div className={classNames.join(' ')}>{itemBody}</div>;
};

export default VerticalNavItem;
