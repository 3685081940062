import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalHeader,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalPane,
  ModalPaneContainer,
  ModalType,
} from '@gi/modal';

import * as PlannerSettingsActionCreators from '../planner-settings-action-creators';

interface iProps {
  closeModal: () => void;
}

const ResetDeviceSettingsModal = ({ closeModal }: iProps) => {
  const dispatch = useDispatch();

  const resetSettings = () => {
    dispatch(PlannerSettingsActionCreators.resetLocalSettings());
  };

  return (
    <Modal type={ModalType.Warning} closeRequest={closeModal}>
      <ModalContent>
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Reset Device Settings</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            <p>Are you sure you want to reset the device settings for this device?</p>
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={closeModal}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-warning' onClick={resetSettings}>
                Reset Device Settings
              </button>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ResetDeviceSettingsModal;
