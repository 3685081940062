import React from 'react';

interface iProps {
  ariaLabel?: string;
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}

const RoundButton = ({ onClick, ariaLabel = '', children, className = '' }: iProps): JSX.Element => {
  return (
    <button type='button' aria-label={ariaLabel} className={`button button-borderless ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default RoundButton;
