import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

import JournalEntry from '@gi/journal-days';
import ImageDisplayModal from '@gi/image-display-modal';
import { JournalUserImage } from '@gi/journal-user-image';

import './journal.scss';

const THUMBNAIL_TRANSFORM = {
  c: 'fill',
  w: 60,
};

const FIRST_IMAGE_TRANSFORM = {
  c: 'fill',
  w: 275,
};

const LARGE_IMAGE_TRANSFORM = {
  c: 'fill',
  w: 475,
};

const JournalPost = (props) => {
  const [imageInModal, setImageInModal] = useState('');

  const closeModal = () => {
    setImageInModal(false);
  };

  const renderImages = (images) => {
    if (!images.length) {
      return null;
    }

    return (
      <div className='journal-images'>
        {imageInModal ? (
          <ImageDisplayModal title='Journal Image' closeModal={closeModal}>
            <JournalUserImage src={imageInModal} transforms={LARGE_IMAGE_TRANSFORM} />
          </ImageDisplayModal>
        ) : null}
        <JournalUserImage
          className='journal-image'
          src={images[0].imageName}
          transforms={FIRST_IMAGE_TRANSFORM}
          onClick={() => {
            setImageInModal(images[0].imageName);
          }}
          alt=''
        />
        <div className='journal-image-thumbs'>
          {images.slice(1).map((image) => {
            return (
              <JournalUserImage
                key={image.imageName}
                transforms={THUMBNAIL_TRANSFORM}
                onClick={() => {
                  setImageInModal(image.imageName);
                }}
                className='journal-image-thumb'
                src={image.imageName}
                alt=''
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className='journal-entry'>
      {renderImages(props.journalEntry.images)}
      {props.journalEntry.note}
    </div>
  );
};

JournalPost.propTypes = {
  journalEntry: PropTypes.instanceOf(JournalEntry).isRequired,
};

export default JournalPost;
