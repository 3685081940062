import React from 'react';
import { useDispatch } from 'react-redux';

import { GuruActionCreators } from '@gi/app-guru-slice';
import { IntercomActionCreators } from '@gi/intercom';

import styles from './directory-page.module.css';
import GuruButton from '../buttons/guru-button';
import GuruSection from '../guru-section/guru-section';

interface iProps {
  composedPageId?: string;
}

const DirectoryPageSectionsError = ({ composedPageId }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const retry = () => {
    if (composedPageId) {
      dispatch(GuruActionCreators.loadPage([composedPageId, true]));
    }
  };

  const openIntercom = () => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('guru-directory-page-error'));
  };

  return (
    <GuruSection>
      <div className={styles.directoryPageError}>
        <h2>Failed to load</h2>
        <p>An error occurred while trying to load this page. Please check your internet connection and try again.</p>
        <p>
          If the problem persists, please{' '}
          <button className='button button-inline' type='button' onClick={openIntercom}>
            contact support
          </button>
          .
        </p>
        {composedPageId !== undefined ? <GuruButton onClick={retry}>Retry</GuruButton> : null}
      </div>
    </GuruSection>
  );
};

export default DirectoryPageSectionsError;
