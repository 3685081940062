import { distanceConversion } from '@gi/conversion';
import { Engine } from '@gi/core-renderer';

import { DimensionCount, PrintSettings } from './types';

const RULER_SIZE = 20;

/**
 * Calculates the usable width and height of the paper (accounting for margins)
 * @param printSettings The printing settings to use
 * @returns The usable width and height of the paper
 */
export const calculateUsablePaperSize = (printSettings: PrintSettings): Dimensions => {
  const result = {
    width: Math.floor(printSettings.paperSize.width - printSettings.margins.x * 2),
    height: Math.floor(printSettings.paperSize.height - printSettings.margins.y * 2),
  };
  return printSettings.orientation === 'landscape' ? { width: result.height, height: result.width } : result;
};

/**
 * Calculates how big a canvas needs to be to fill a page with the given print settings.
 * @param printSettings The print settings to use
 * @returns The width and height of the canvas in px
 */
export const calculateCanvasSize = (printSettings: PrintSettings): Dimensions => {
  const usablePaperSize = calculateUsablePaperSize(printSettings);

  // Convert dots/inch to dots/mm
  const dotsPerMM = distanceConversion.inchesFromCM(printSettings.dpi) / 10;
  const dotsAcross = Math.floor(usablePaperSize.width * dotsPerMM);
  const dotsDown = Math.floor(usablePaperSize.height * dotsPerMM);

  return {
    width: dotsAcross,
    height: dotsDown,
  };
};

/**
 * Calculates how many pages across and down will be used when printing
 * @param gardenCanvas The current garden canvas
 * @param printSettings The print settings to use
 * @returns The amount of pages down and across needed to print
 */
export const calculateTotalPages = (engine: Engine, gardenSize: Dimensions, printSettings: PrintSettings): DimensionCount => {
  // This is the width and height of the garden (effectively in CM)
  // const gardenSize: Dimensions = engine.camera.state.contentDimensions;

  // Use the canvas size, rather than the paper size, so that we can account for the
  // rulers on the edges of the image in pixel values.
  const canvasSize = calculateCanvasSize(printSettings);

  let scale: number;
  const drawingWidth = canvasSize.width * printSettings.fitPageCount - RULER_SIZE * 2;
  const drawingHeight = canvasSize.height * printSettings.fitPageCount - RULER_SIZE * 2;
  const widthScale = drawingWidth / gardenSize.width;
  const heightScale = drawingHeight / gardenSize.height;
  switch (printSettings.fitMode) {
    case 'across': {
      scale = widthScale;
      break;
    }
    case 'down': {
      scale = heightScale;
      break;
    }
    default: {
      scale = Math.min(widthScale, heightScale);
    }
  }

  return {
    across: Math.ceil((gardenSize.width * scale) / canvasSize.width),
    down: Math.ceil((gardenSize.height * scale) / canvasSize.height),
  };
};
