import React, { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { TutorialContext } from '@gi/tutorials';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';

import MobileIntroductionTutorialModal from './mobile-introduction-tutorial';

const MobileIntroductionTutorialRenderer = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { mobileIntroductionModalVisible, setMobileIntroductionModalVisible } = useContext(TutorialContext);

  const onClose = useCallback(
    (pageIndex: number, time: number) => {
      setMobileIntroductionModalVisible(false);
      dispatch(
        GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.CloseMobileIntroductionVideo, { pageIndex, secondsOpen: Math.round(time / 1000) })
      );
    },
    [setMobileIntroductionModalVisible]
  );

  if (!mobileIntroductionModalVisible) {
    return null;
  }

  return <MobileIntroductionTutorialModal onClose={({ pageIndex, time }) => onClose(pageIndex, time)} />;
};

export default MobileIntroductionTutorialRenderer;
