import React, { ReactNode, useContext } from 'react';

import { LoadingState } from '@gi/constants';
import { ResourceLoaderContext } from '@gi/resource-provider';

import GardenGuruLoadErrorScreen from './garden-guru-load-error-screen';
import { GardenGuruDataProvider } from './garden-guru-data-context';

interface iProps {
  children?: ReactNode;
}

const GardenGuruLoadGate = ({ children }: iProps): JSX.Element => {
  const { guruPages } = useContext(ResourceLoaderContext);

  if (guruPages.status !== LoadingState.SUCCESS) {
    return <GardenGuruLoadErrorScreen />;
  }

  return <GardenGuruDataProvider guruPages={guruPages.value}>{children}</GardenGuruDataProvider>;
};

export default GardenGuruLoadGate;
