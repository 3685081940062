// Recursively makes every part of an object recursive.
type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

// The inputs to a specific filter. Don't actually use this, just extend it.
type FilterInputs = Record<string, any>;

// A filter
export type Filter<T, TInputs extends FilterInputs = any> = Readonly<{
  name: string;
  inputs: TInputs;
  createListFilter: (inputs: TInputs, getInputs: () => any) => (list: T[]) => T[]; // TODO: Better type?
}>;

// A map of { "filter name": Filter }
type FiltersMapObject<T, TFilters extends Record<string, Filter<T>> = any> = {
  [K in keyof TFilters]: Filter<T, TFilters[K]>;
};

// The inputs extracted from a FiltersMap. Returns { "filter name": { "input name": inputType } }
type InputsFromFiltersMapObject<T, TFiltersMap extends FiltersMapObject<T>> = {
  [K in keyof TFiltersMap]: TFiltersMap[K] extends Filter<T, infer TInputs> ? TInputs : never;
};

// Stores all the filters, and a function to get all the inputs needed to run the filter.
export type Filters<T, TFilterMap extends FiltersMapObject<T>> = Readonly<{
  filters: TFilterMap;
  getInputs: () => InputsFromFiltersMapObject<T, TFilterMap>;
}>;

// Represents a partial update to a set of filters
export type FiltersUpdate<T, TFilterMap extends FiltersMapObject<T>> = DeepPartial<InputsFromFiltersMapObject<T, TFilterMap>>;

// Creates a filters object, using the map of filters passed in
export function createFilters<T, TFilterMap extends FiltersMapObject<T> = FiltersMapObject<T>>(filtersMapObject: TFilterMap): Filters<T, TFilterMap> {
  function getInputs(): InputsFromFiltersMapObject<T, TFilterMap> {
    const finalInputs = {};

    const keys = Object.keys(filtersMapObject);
    for (let i = 0; i < keys.length; i++) {
      if (typeof filtersMapObject[keys[i]].createListFilter === 'function') {
        finalInputs[keys[i]] = filtersMapObject[keys[i]].inputs;
      }
    }

    return finalInputs as InputsFromFiltersMapObject<T, TFilterMap>;
  }

  const filters: Filters<T, TFilterMap> = {
    filters: filtersMapObject,
    getInputs,
  };

  return filters;
}

/**
 * Creates an updated copy ofthe given filters, updating the set up inputs given.
 * @param inputFilters The filters object to update
 * @param updates A partial of all the inputs to update
 * @returns An updated copy of the filters
 */
export function updateFilters<T, TFilterMap extends FiltersMapObject<T>>(
  inputFilters: Filters<T, TFilterMap>,
  updates: FiltersUpdate<T, TFilterMap>
): Filters<T, TFilterMap> {
  const updatedFilters: TFilterMap = { ...inputFilters.filters };
  const updateKeys: (keyof TFilterMap)[] = Object.keys(updates) as (keyof TFilterMap)[];

  for (let i = 0; i < updateKeys.length; i++) {
    updatedFilters[updateKeys[i]] = {
      ...updatedFilters[updateKeys[i]],
      inputs: {
        ...updatedFilters[updateKeys[i]].inputs,
        ...updates[updateKeys[i]],
      },
    };
  }

  function getInputs(): InputsFromFiltersMapObject<T, TFilterMap> {
    const finalInputs = {};

    const keys = Object.keys(updatedFilters);
    for (let i = 0; i < keys.length; i++) {
      if (typeof updatedFilters[keys[i]].createListFilter === 'function') {
        finalInputs[keys[i]] = updatedFilters[keys[i]].inputs;
      }
    }

    return finalInputs as InputsFromFiltersMapObject<T, TFilterMap>;
  }

  const filters: Filters<T, TFilterMap> = {
    filters: updatedFilters,
    getInputs,
  };

  return filters;
}

/**
 * Returns a list of curried functions with the current input values injected into it
 */
function getListFilters<T>(filters: Filters<T, any>) {
  const listFilters: ((list: Readonly<T[]>) => T[])[] = [];

  const filterKeys = Object.keys(filters.filters);

  for (let i = 0; i < filterKeys.length; i++) {
    const filter = filters.filters[filterKeys[i]];
    listFilters.push(filter.createListFilter(filter.inputs, filters.getInputs));
  }

  return listFilters;
}

/**
 * Runs the current filters on the given list
 */
export function runListFilters<T>(filters: Filters<T, any>, inputList: Readonly<T[]>) {
  const listFilters = getListFilters(filters);

  let list = inputList;

  for (let i = 0; i < listFilters.length; i++) {
    // Iterate through each filter, passing the newly filtered list each time
    list = listFilters[i](list);
  }

  return list;
}
