import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import SubscriptionStatusTypes from './constants/subscription-status-types';

export type UserSubscription = {
  accountExpires: moment.Moment;
  emailConfirmed: boolean;
  // gardenPlannerSubscriptionAmount: number,
  // gardenPlannerSubscriptionCurrency: string,
  // gardenPlannerSubscriptionOverride: null,
  // gppAccountExpires: null,
  // gppSubscribed: null,
  hasUsedAndroidClient: boolean;
  hasUsedFlashClient: boolean;
  hasUsedJournal: boolean;
  hasUsedPlantingTimesApp: boolean;
  hasUsedPlantingTimesWidget: boolean;
  hasUsedWebClient: boolean;
  subscribed: boolean;
  recurringSubscription: boolean;
  subscriptionProduct: string;
  subscriptionState: string;
};

/**
 * Checks if the given subscription has time remaining.
 * @param userSubscription The UserSubscription object
 * @returns True if the user has time left on their subscription
 */
const hasTimeRemaining = (userSubscription: UserSubscription) => {
  // Add a grace period of a day
  return moment(userSubscription.accountExpires).add(1, 'day').diff(moment()) > 0;
};

/**
 * Checks if the user is currently in a free trial
 * @param userSubscription The UserSubscription
 * @returns True if the user has a free trial with time remaining
 */
const hasFreeTrial = (userSubscription: UserSubscription) => {
  if (userSubscription.subscribed) {
    return false;
  }

  return hasTimeRemaining(userSubscription);
};

/**
 * hecks if the user currently has an active subscription
 * @param userSubscription The UserSubscription object
 * @returns True if the user has a subscription with time remaining.
 */
const hasSubscription = (userSubscription: UserSubscription) => {
  if (!userSubscription.subscribed) {
    return false;
  }

  return hasTimeRemaining(userSubscription);
};

/**
 * Returns what type of subscription the user has, and if it's expired.
 * @param userSubscription The UserSubscription object
 * @returns The type of subscription the user has
 */
const getSubscriptionStatus = (userSubscription: UserSubscription) => {
  if (!userSubscription.subscribed) {
    if (!userSubscription.hasUsedFlashClient && !userSubscription.hasUsedWebClient) {
      // Pre free trial
      return SubscriptionStatusTypes.PRE_FREE_TRIAL;
    }

    if (hasTimeRemaining(userSubscription)) {
      // Free trial period
      return SubscriptionStatusTypes.FREE_TRIAL;
    }

    // Free trial ended
    return SubscriptionStatusTypes.FREE_TRIAL_ENDED;
  }

  if (hasTimeRemaining(userSubscription)) {
    // Free trial period
    return SubscriptionStatusTypes.SUBSCRIBED;
  }

  return SubscriptionStatusTypes.NO_SUBSCRIPTION;
};

/**
 * @deprecated
 * Avoid using PropTypes
 */
export const UserSubscriptionShape = PropTypes.shape({
  accountExpires: PropTypes.object.isRequired,
  emailConfirmed: PropTypes.bool.isRequired,
  hasUsedAndroidClient: PropTypes.bool.isRequired,
  hasUsedFlashClient: PropTypes.bool.isRequired,
  hasUsedJournal: PropTypes.bool.isRequired,
  hasUsedPlantingTimesApp: PropTypes.bool.isRequired,
  hasUsedPlantingTimesWidget: PropTypes.bool.isRequired,
  hasUsedWebClient: PropTypes.bool.isRequired,
  subscribed: PropTypes.bool.isRequired,
  recurringSubscription: PropTypes.bool.isRequired,
  subscriptionProduct: PropTypes.string.isRequired,
  subscriptionState: PropTypes.string.isRequired,
});

export const UserSubscriptionUtils = {
  hasTimeRemaining,
  hasFreeTrial,
  hasSubscription,
  getSubscriptionStatus,
};
