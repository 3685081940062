import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';

import { PlanBackgroundImageSrc, PlanBackgroundImageSrcCrop, PlanBackgroundImageUtils } from '@gi/plan';
import { RevocableObjectURL } from '@gi/utils';
import { networkConfig } from '@gi/config';

import { PlanImageUploadBody, PlanImageUploadResponse } from './types';
import { splitDataURL } from './utils';

export class PlanBackgroundImageService {
  private endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  private getBaseURL(planId): string {
    return `${this.endpoint}/PlanBackground/${planId}`;
  }

  uploadImage(planId: number, imageAsDataURL: string): Promise<PlanBackgroundImageSrc> {
    return new Promise((resolve, reject) => {
      const url = this.getBaseURL(planId);

      const dataUrlParts = splitDataURL(imageAsDataURL);
      if (dataUrlParts === null) {
        reject(new Error('Image as data URL appears to be in an unrecognised format'));
        return;
      }

      const data: PlanImageUploadBody = {
        fileBase64Encoded: dataUrlParts.data,
        fileName: new Date().toISOString().substring(0, 10),
        contentType: dataUrlParts.mimeType,
      };

      networkService
        .post<PlanImageUploadResponse>(url, {}, data, RequestAuthMode.Bearer, {
          timeout: 1000 * 60 * 5, // 5 minutes to upload, some people have terrible upload speeds
        })
        .then((response) => {
          resolve({ planId, id: response.body.filePath, crop: null });
        })
        .catch(networkErrorReporter('POST', 'plan-background-image'))
        .catch(reject);
    });
  }

  /** @deprecated To be removed */
  static getImage(planId: number, id: string, crop: PlanBackgroundImageSrcCrop | null = null): Promise<Blob> {
    return networkService
      .getBlob(PlanBackgroundImageUtils.getImageURL(id, crop), {}, RequestAuthMode.Bearer)
      .then((response) => response.body)
      .catch(networkErrorReporter('GET', 'plan-background-image'));
  }

  /** @deprecated To be removed */
  static createImageGetter(planId: number, id: string, crop: PlanBackgroundImageSrcCrop | null = null): RevocableObjectURL {
    return new RevocableObjectURL(() => this.getImage(planId, id, crop).then((blob) => URL.createObjectURL(blob)));
  }
}

export default new PlanBackgroundImageService(`${networkConfig.newApiEndpoint}/gardenplanner/api/v1.1/`);
