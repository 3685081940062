import React, { ReactNode } from 'react';
import { GuruContentType, GuruPreviewArticle, GuruPreviewItem, GuruPreviewPest, GuruPreviewPlant, GuruPreviewVideo } from '@gi/app-guru-types';

import SmallPreviewVideo from './small-preview-video';
import SmallPreviewArticle from './small-preview-article';
import SmallPreviewPest from './small-preview-pest';
import SmallPreviewPlant from './small-preview-plant';

interface iProps {
  item: GuruPreviewItem;
  children?: ReactNode;
}

const SmallPreviewItem = ({ item, children }: iProps): JSX.Element | null => {
  switch (item.type) {
    case GuruContentType.Video:
      return <SmallPreviewVideo previewVideo={item as GuruPreviewVideo}>{children}</SmallPreviewVideo>;
    case GuruContentType.Article:
      return <SmallPreviewArticle previewArticle={item as GuruPreviewArticle}>{children}</SmallPreviewArticle>;
    case GuruContentType.Pest:
      return <SmallPreviewPest previewPest={item as GuruPreviewPest}>{children}</SmallPreviewPest>;
    case GuruContentType.Plant:
      return <SmallPreviewPlant previewPlant={item as GuruPreviewPlant}>{children}</SmallPreviewPlant>;
    default:
      return null;
  }
};

export default SmallPreviewItem;
