import { Graphics } from 'pixi.js-new';

import { GraphicNode, InspectableClassAction, InspectableClassData, InspectableClassDataType, NodeEvent, bindState } from '@gi/core-renderer';
import { CropRotationViewTypes } from '@gi/constants';
import { State, StateDef } from '@gi/state';

import { drawRoots, drawSquareFootRoots } from '../plant/draw-utils';
import { SimulatedPlant } from '../../../simulation/simulated-plant';
import CropRotationContext from './crop-rotation-context';

type CropRotationHistoryNodeState = StateDef<{
  age: number;
  viewMode: CropRotationViewTypes;
  plantsHaveUpdated: boolean;
}>;

class CropRotationHistoryNode extends GraphicNode {
  type = 'CropRotationHistory';

  readonly state: State<CropRotationHistoryNodeState>;

  #plants: SimulatedPlant[];
  #plantsByFamily: Record<number, SimulatedPlant[]> = {};

  #graphicsPool: Graphics[] = [];

  constructor(age: number, plants: SimulatedPlant[]) {
    super();

    this.state = new State({
      age,
      viewMode: CropRotationViewTypes.OFF,
      plantsHaveUpdated: false,
    });
    bindState(this.state, this);

    this.setPlants(plants);

    this.state.addValidator(
      (state) => {
        state.values.plantsHaveUpdated = false;
      },
      { properties: ['plantsHaveUpdated'] }
    );

    this.state.addWatcher(() => {
      this.#redraw();
    });

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    this.tryGetContext(CropRotationContext)?.registerHistoryNode(this);
    this.#redraw();
  };

  #onBeforeUnbind = () => {
    this.tryGetContext(CropRotationContext)?.unregisterHistoryNode(this);
    this.#graphicsPool.forEach((graphic) => graphic.destroy());
    this.#graphicsPool = [];
  };

  /**
   * Sets the plants that are part of this history
   * @param plants The plants that are part of this history
   */
  setPlants(plants: SimulatedPlant[]) {
    this.#plants = plants;
    this.#plantsByFamily = {};

    for (let i = 0; i < plants.length; i++) {
      const plant = plants[i];
      if (plant && plant.plantFamily) {
        this.#plantsByFamily[plant.plantFamily.ID] = this.#plantsByFamily[plant.plantFamily.ID] ?? [];
        this.#plantsByFamily[plant.plantFamily.ID].push(plant);
      }
    }

    this.state.values.plantsHaveUpdated = true;
  }

  /**
   * Sets the "age" of this historical plan. Should be between 1-5
   * 1 = last year's plan.
   * @param age The age of this plan (1 = last year, 5 = 5 years ago)
   */
  setAge(age: number) {
    this.state.values.age = age;
  }

  /**
   * Sets which plant family this history will display
   * @param viewMode The view mode to use, determining which plant family is displayed
   */
  setViewMode(viewMode: CropRotationViewTypes) {
    this.state.values.viewMode = viewMode;
  }

  /**
   * Gets a graphics object to draw on, recycling unused graphics objects if possible
   * @param i The index of hte graphics object to get
   * @returns A graphics object
   */
  #getGraphics(i: number) {
    this.#graphicsPool[i] = this.#graphicsPool[i] ?? new Graphics();
    this.#graphicsPool[i].eventMode = 'none'; // Disable interactions
    this.ownGraphics.addChild(this.#graphicsPool[i]);
    return this.#graphicsPool[i];
  }

  /**
   * Redraws this node
   */
  #redraw() {
    if (!this.bound) {
      return;
    }

    const { viewMode, age } = this.state.values;
    if (viewMode === CropRotationViewTypes.OFF) {
      for (let i = 0; i < this.#graphicsPool.length; i++) {
        this.#graphicsPool[i].destroy();
      }
      this.#graphicsPool = [];
    }

    const plants = this.#plantsByFamily[viewMode];
    if (plants) {
      for (let i = 0; i < plants.length; i++) {
        const plant = plants[i];

        const graphics = this.#getGraphics(i);
        graphics.x = plant.rowStart.x;
        graphics.y = plant.rowStart.y;
        graphics.rotation = plant.rotation;

        // Draw
        if (!plant.isSquareFoot) {
          drawRoots({
            graphics,
            width: plant.width,
            height: plant.height,
            spacing: plant.spacings.spacing,
            inRowSpacing: plant.spacings.inRowSpacing,
            rowSpacing: plant.spacings.rowSpacing,
            colour: 0xbf2d2d,
            alpha: 0.6 - 0.1 * age,
          });
        } else {
          drawSquareFootRoots({
            graphics,
            colour: 0xbf2d2d,
            alpha: 0.6 - 0.1 * age,
          });
        }
      }
    }

    const unusedGraphics = this.#graphicsPool.splice(plants?.length ?? 0, this.#graphicsPool.length);
    for (let i = 0; i < unusedGraphics.length; i++) {
      unusedGraphics[i].destroy();
    }
  }

  inspectorData: InspectableClassData<this> = [
    ...Object.keys(CropRotationViewTypes).map<InspectableClassAction<this>>((key) => {
      return {
        type: InspectableClassDataType.Action,
        displayName: `Show ${key}`,
        callback: () => {
          this.setViewMode(CropRotationViewTypes[key]);
        },
      };
    }),
  ];
}

export default CropRotationHistoryNode;
