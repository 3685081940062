import React from 'react';

import { Pest } from '@gi/pest';

import HeaderContainer from '../../header/header-components/header-container';
import HeaderTitle from '../../header/header-components/header-title';
import HeaderSubtitle from '../../header/header-components/header-subtitle';

import styles from './pest-content-header.module.css';

interface iProps {
  title: string;
  pest: Pest;
}

const PestContentHeader = ({ title, pest }: iProps): JSX.Element => {
  return (
    <HeaderContainer>
      <HeaderTitle className={styles.pestHeaderTitle}>{title}</HeaderTitle>
      {pest.scientificName ? (
        <HeaderSubtitle>
          <div className={styles.scientificName}>{pest.scientificName}</div>
        </HeaderSubtitle>
      ) : null}
    </HeaderContainer>
  );
};

export default PestContentHeader;
