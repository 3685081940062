import React, { useContext } from 'react';
import { AppContext } from '@gi/app-provider';
import intercomIcon from '@gi/assets/common/intercom-icon.svg';
import { networkConfig } from '@gi/config';
import { useDispatch } from 'react-redux';
import { IntercomActionCreators } from '@gi/intercom';
import { useViewLinkEventHook } from '@gi/garden-platform-events';

import DashboardItem from '../../dashboard-item/dashboard-item';
import DashboardItemSection from '../../dashboard-item/dashboard-item-section';
import DashboardItemSectionTitle from '../../dashboard-item/dashboard-item-section-title';

import img1 from './1.jpg';
import img3 from './3.jpg';
import img4 from './4.jpg';
import img5 from './5.jpg';
import img7 from './7.jpg';
import img8 from './8.jpg';
import img9 from './9.jpg';
import img10 from './10.jpg';
import img12 from './12.jpg';

import './support-dashboard-item.scss';

const SupportDashboardItem = (): JSX.Element => {
  const dispatch = useDispatch();
  const { runtimeConfig } = useContext(AppContext);

  const sendLinkViewedEvent = useViewLinkEventHook('dashboard-support');

  const openIntercom = () => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('dashboard-support-section'));
  };

  return (
    <DashboardItem title='Support' className='support-dashboard-item'>
      <DashboardItemSection className='col-2'>
        <DashboardItemSectionTitle>Live Chat Support</DashboardItemSectionTitle>
        <div className='dashboard-block chat-support-block'>
          <div className='chat-intro'>Need help, or got a gardening question? Ask our friendly, experienced gardeners</div>
          <div>
            <button type='button' className='live-chat-button' onClick={openIntercom}>
              <img src={intercomIcon} alt='' className='intercom-icon' /> Live Chat
            </button>
          </div>
        </div>
        <div className='dashboard-block padded background live-chat-block'>
          <div className='live-chat-staff'>
            <div className='live-chat-icon'>
              <img src={img1} alt='' className='live-chat-icon-img' />
            </div>
            <div className='live-chat-icon'>
              <img src={img3} alt='' className='live-chat-icon-img' />
            </div>
            <div className='live-chat-icon'>
              <img src={img4} alt='' className='live-chat-icon-img' />
            </div>
            <div className='live-chat-icon'>
              <img src={img5} alt='' className='live-chat-icon-img' />
            </div>
            <div className='live-chat-icon'>
              <img src={img7} alt='' className='live-chat-icon-img' />
            </div>
            <div className='live-chat-icon'>
              <img src={img12} alt='' className='live-chat-icon-img' />
            </div>
            <div className='live-chat-icon'>
              <img src={img8} alt='' className='live-chat-icon-img' />
            </div>
            <div className='live-chat-icon'>
              <img src={img9} alt='' className='live-chat-icon-img' />
            </div>
            <div className='live-chat-icon'>
              <img src={img10} alt='' className='live-chat-icon-img' />
            </div>
          </div>
          <div>Our Garden Experts are available 7-days a week</div>
        </div>
      </DashboardItemSection>
      <DashboardItemSection className='col-2'>
        <DashboardItemSectionTitle>Other Support Resources</DashboardItemSectionTitle>
        <div className='dashboard-block'>
          <a className='' target='_blank' rel='noreferrer' href={networkConfig.helpURL} onClick={() => sendLinkViewedEvent('help-pages')}>
            Help &amp; Support Site
          </a>
        </div>
        <div className='dashboard-block'>
          <div>
            Email Support:{' '}
            <a href={`mailto:${runtimeConfig.supportEmail}`} target='_blank' rel='noreferrer' onClick={() => sendLinkViewedEvent('mailto-support')}>
              {runtimeConfig.supportEmail}
            </a>
          </div>
        </div>
      </DashboardItemSection>
    </DashboardItem>
  );
};

export default SupportDashboardItem;
