import FormField, { FORM_FIELD_PRESETS, FormValues } from '@gi/form-responsive';
import React from 'react';
import { LocalFilterValues } from './filter-types';

interface iCheckboxFilterProps {
  localFilters: FormValues<LocalFilterValues>;
  setFilterValue: <K extends keyof LocalFilterValues>(key: K, value: LocalFilterValues[K]) => void;
  filter: keyof LocalFilterValues;
  label: string;
  disabled?: boolean;
  inverted?: boolean;
  onHelpClick?: () => void;
}

const CheckboxFilter = ({ localFilters, setFilterValue, filter, label, disabled, inverted, onHelpClick }: iCheckboxFilterProps): JSX.Element => {
  const value = localFilters.values[filter] as boolean;
  return (
    <FormField label={label} htmlFor={filter} layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft} onHelpClick={onHelpClick} disabled={disabled}>
      <input
        id={filter}
        type='checkbox'
        checked={disabled ? false : inverted ? !value : value}
        onChange={(e) => setFilterValue(filter, inverted ? !e.target.checked : e.target.checked)}
        disabled={disabled}
      />
    </FormField>
  );
};

export default CheckboxFilter;
