/**
 * @typedef {string} PlantType
 */

/**
 * Enum for the different types of plant
 *
 * @readonly
 * @enum {PlantType}
 */
const PlantTypes = {
  PLANT_BLOCK: 'PLANT_BLOCK',
  PLANT_ROW: 'PLANT_ROW',
  PLANT_SINGLE: 'PLANT_SINGLE',
  PLANT_SQUARE_FOOT: 'PLANT_SQUARE_FOOT',
} as const;

export type PlantType = (typeof PlantTypes)[keyof typeof PlantTypes];

export default PlantTypes;
