import fuzzysort from 'fuzzysort';
import React, { ReactNode } from 'react';

import SearchResult, { SearchDisplayMode } from './search-result';
import SearchResults from './search-results';

interface SearchKeyOptions {
  weight?: number;
  order?: number;
  displayMode?: SearchDisplayMode;
  /**
   * If set, the field will be split into numerous fields, split by whatever the delimiter is.
   * e.g. For a comma-seperated list, set the delimiter to "," to
   */
  delimiter?: string;
  /**
   * When set, this key will be used for single character searches. If unset on every key, single
   * character searching won't be used, instead using fuzzy search for everything.
   */
  includeForSingleChar?: boolean;
  /**
   * If true, strings like "Basket (Hanging)" will be given a seperate entry, "Hanging Basket"
   */
  processBrackets?: boolean;
  processBracketsWeightFactor?: number;
  processBracketsDisplayMode?: SearchDisplayMode;
}

type SearchServiceOptions<T extends (string | number | symbol)[]> = {
  [K in T[number]]?: SearchKeyOptions;
};

interface SearchValueGroup {
  name: string;
  displayMode: SearchDisplayMode;
  weight: number;
  values: string[];
}

interface GeneratedSearchItem<T> {
  originalItem: T;
  generatedFields: {
    [key: string]: string;
  };
}

/*
 * Searchable items need a code so they can be returned as search results.
 * May be more flexible in the future to specify which field acts as the code.
 */
type RecordWithCode = Record<string, any> & { code: string };

const BRACKET_OPEN = ' (';
const BRACKET_CLOSE = ')';

/**
 * Gets a value from an object, or nested objects
 * e.g. "object.plant.name" will go to item['object']['plant']['name']
 * @param key The key to get. For nested object, split the key names using .
 * @param item The object to extract the value from
 * @returns The value at the given key, or undefined if not exists
 */
const getValueFromKey = (key: string | number | symbol | (string | number | symbol)[], item: any) => {
  const keys = Array.isArray(key) ? key : typeof key === 'string' ? key.split('.') : [key];
  if (typeof item !== 'object') {
    return undefined;
  }
  if (keys.length === 1) {
    return item[keys[0]];
  }
  return getValueFromKey(keys.slice(1), item[keys[0]]);
};

export class SearchService<T extends RecordWithCode> {
  #items: T[];
  #keys: (keyof T | string)[];
  #keyOptions: SearchServiceOptions<(keyof T)[]>;
  #threshold: number;
  #lowestOrder: number;

  #fuzzyOptions: Fuzzysort.KeysOptions<any>;

  #generatedItems: GeneratedSearchItem<T>[];
  #generatedKeys: string[];
  #generatedKeyOrders: Record<string, number>;
  #generatedKeyWeights: Record<string, number>;
  #generatedKeyMappings: Record<string, keyof T>;
  #generatedKeyDisplayModes: Record<string, SearchDisplayMode>;
  #singleCharKeys: string[];

  constructor(items: T[], keys: (keyof T | string)[], keyOptions: SearchServiceOptions<(keyof T | string)[]> = {}, fuzzyOptions?: Fuzzysort.Options) {
    this.#items = items;
    this.#keys = keys;
    this.#keyOptions = keyOptions;
    this.#threshold = -1000;
    this.#lowestOrder = keys.reduce<number>((value, key) => Math.min(value, keyOptions[key]?.order ?? 0), Infinity);

    this.#generatedItems = [];
    this.#generatedKeyOrders = {};
    this.#generatedKeyWeights = {};
    this.#generatedKeyMappings = {};
    this.#generatedKeyDisplayModes = {};

    const generatedKeysSet = new Set<string>();
    const singleCharKeysSet = new Set<string>();

    items.forEach((item) => {
      const finalObject: GeneratedSearchItem<T> = {
        originalItem: item,
        generatedFields: {},
      };
      keys.forEach((key) => {
        const {
          weight = 1,
          order = 0,
          displayMode = SearchDisplayMode.Primary,
          delimiter,
          includeForSingleChar,
          processBrackets = false,
          processBracketsWeightFactor = 0.2,
          processBracketsDisplayMode = SearchDisplayMode.Secondary,
        } = keyOptions[key] ?? {};

        /**
         * Convert our key's value to a list of strings.
         */
        const prop = getValueFromKey(key, item);
        if (!prop) {
          return;
        }
        const values: string[] = (Array.isArray(prop) ? (prop as Array<any>) : [prop]).map((value) => String(value));

        let groups: SearchValueGroup[] = [
          {
            values,
            displayMode,
            name: '',
            weight,
          },
        ];

        /**
         * If the delimiter option is set, break every string apart by that delimiter
         */
        if (delimiter) {
          groups = groups.map((group) => ({
            name: group.name,
            weight: group.weight,
            displayMode: group.displayMode,
            values: group.values.reduce((acc, value) => {
              return [...acc, ...value.split(delimiter).map((newVal) => newVal.trim())];
            }, []),
          }));
        }

        /**
         * If processBrackets is true, make another copy of the group definition, with brackets removed
         * and the bracket text put at the front.
         */
        if (processBrackets) {
          groups = groups.reduce((groupsAccumulator, group) => {
            return [
              ...groupsAccumulator,
              group,
              {
                // Make a new group, where all the values have had their brackets removed
                name: `${group.name}_brackets`,
                weight: group.weight * processBracketsWeightFactor,
                displayMode: processBracketsDisplayMode,
                values: group.values.reduce((acc, value) => {
                  const startIndex = value.indexOf(BRACKET_OPEN);
                  if (startIndex !== -1) {
                    const leftHand = value.substring(0, startIndex);
                    const remaining = value.substring(startIndex + BRACKET_OPEN.length);
                    const rightHand = remaining.substring(0, remaining.indexOf(BRACKET_CLOSE) ?? undefined);
                    return [...acc, `${rightHand} ${leftHand}`];
                  }
                  return [...acc];
                }, []),
              },
            ];
          }, []);
        }

        // We now have all the groups, flatten them out and commit them.
        groups.forEach((group) => {
          group.values.forEach((value, i) => {
            const generatedKey = `${String(key).replaceAll('.', '_')}${group.name}_${i}`;
            finalObject.generatedFields[generatedKey] = value;
            generatedKeysSet.add(generatedKey);
            this.#generatedKeyOrders[generatedKey] = order;
            this.#generatedKeyWeights[generatedKey] = group.weight;
            this.#generatedKeyMappings[generatedKey] = key;
            this.#generatedKeyDisplayModes[generatedKey] = group.displayMode;
            // Only allow single-character searching on primary fields to prevent confusion.
            if (includeForSingleChar && group.displayMode === SearchDisplayMode.Primary) {
              singleCharKeysSet.add(generatedKey);
            }
          });
        });
      });
      this.#generatedItems.push(finalObject);
    });

    this.#generatedKeys = Array.from(generatedKeysSet);
    this.#singleCharKeys = Array.from(singleCharKeysSet);
    this.#fuzzyOptions = {
      threshold: this.#threshold,
      scoreFn: (match) => this.scoreFunc(match),
      ...fuzzyOptions,
      keys: this.#generatedKeys.map((key) => `generatedFields.${key}`),
    };
  }

  /**
   * Returns the highest score based on weighting. Used to sort the list.
   * @param result The result containing all the matches for an item
   * @returns The best score out of all the matches
   */
  private scoreFunc(result: readonly Fuzzysort.KeyResult<any>[]) {
    // NOTE: Score is somewhere between 0 (exact match)
    // and -[massive number] (seemingly 7-digits, assume -Infinity to be safe)
    let maxScore = -Infinity;
    let maxOrder = -Infinity;
    this.#generatedKeys.forEach((key, i) => {
      if (result[i]) {
        const newScore = result[i].score / this.#generatedKeyWeights[key];
        if (newScore > maxScore) {
          maxScore = newScore;
          maxOrder = this.#generatedKeyOrders[key];
        }
      }
    });
    if (maxScore < this.#threshold) {
      return maxScore;
    }
    const order = maxOrder - this.#lowestOrder;
    return maxScore + order * -this.#threshold;
  }

  /**
   * Does a fuzzy search on the items, returning the matches as a sorted SearchResutls object
   * @param query The query to search for
   * @returns A SearchResults object
   */
  private doFuzzySearch(query: string) {
    const results = fuzzysort.go(query, this.#generatedItems, this.#fuzzyOptions);

    const mappedResults = results.reduce((acc, result) => {
      // Find the index of the result with the best score
      let bestIndex = -1;
      let bestScore = -Infinity;
      result.forEach((match, i) => {
        if (match && match !== null) {
          const newScore = match.score / this.#generatedKeyWeights[this.#generatedKeys[i]];
          if (bestIndex === -1) {
            bestIndex = i;
            bestScore = newScore;
          }
          if (newScore > bestScore) {
            bestIndex = i;
            bestScore = newScore;
          }
        }
      });
      // Check we have a match
      if (bestIndex === -1) {
        console.warn('Search result found with no matches:', result);
        return acc;
      }
      const matchedKey = this.#generatedKeys[bestIndex];
      const item = result.obj.originalItem;
      return [
        ...acc,
        new SearchResult({
          item,
          score: result.score,
          code: item.code,
          text: result[bestIndex].target,
          getHtml: () => SearchService.generateHighlightText(result[bestIndex]),
          matchedKey: this.#generatedKeyMappings[matchedKey],
          displayMode: this.#generatedKeyDisplayModes[matchedKey],
        }),
      ];
    }, []);

    return new SearchResults<T>(mappedResults);
  }

  /**
   * Searches only for items starting with the given charcter.
   * @param char The character the string should start with
   * @returns A SearchResults object
   */
  private doSingleCharSearch(char: string) {
    const mappedResults = this.#generatedItems.reduce((acc, item) => {
      // See if there's a key whose value starts with the given character
      const matchedKey = this.#singleCharKeys.find((key) => {
        const field = item.generatedFields[key];
        return field && field.toLowerCase().startsWith(char);
      });
      // If not, continue to next result...
      if (!matchedKey) {
        return acc;
      }
      // Otherwise, find the original key and display that as the result
      const originalKey = this.#generatedKeyMappings[matchedKey];
      return [
        ...acc,
        new SearchResult({
          item: item.originalItem,
          score: 0,
          code: item.originalItem.code,
          text: getValueFromKey(originalKey, item.originalItem),
          getHtml: () => getValueFromKey(originalKey, item.originalItem),
          matchedKey: originalKey,
        }),
      ];
    }, []);

    return new SearchResults<T>(mappedResults);
  }

  /**
   * Searches the set of items, returning a SearchResults object
   * @param query The term to search for
   * @returns A SearchResults object
   */
  search(query: string): SearchResults<T> {
    const finalQuery = query.trim().toLowerCase();

    if (query.length > 1 || this.#singleCharKeys.length === 0) {
      return this.doFuzzySearch(finalQuery);
    }

    return this.doSingleCharSearch(finalQuery);
  }

  /**
   * Returns how many items are in the original dataset to search
   */
  getItemCount() {
    return this.#items.length;
  }

  /**
   * Generates HTML to highlight the found text in a search result.
   * @param result The result to generate text for
   * @returns A span with the generated HTML within
   */
  static generateHighlightText(result: Fuzzysort.Result): ReactNode {
    // eslint-disable-next-line react/no-danger
    return <span dangerouslySetInnerHTML={{ __html: fuzzysort.highlight(result) ?? '' }} />;
  }
}
