import React, { ReactNode, useMemo } from 'react';

import { parseThemeVariables, RuntimeConfig } from '@gi/config';

import AppContext, { AppContextType } from './app-context';

interface iProps {
  runtimeConfig: RuntimeConfig;
  children: ReactNode;
}

/**
 * Provides app-related data, including runtime config.
 *
 * This should not be used on its own. Instead, use AppLoader.
 */
const AppProvider = ({ runtimeConfig, children }: iProps): JSX.Element => {
  /**
   * Probably unnecessary here, but memoize everything
   */
  const value = useMemo<AppContextType>(
    () => ({
      runtimeConfig,
    }),
    [runtimeConfig]
  );

  /**
   * Generate the theme variables nay time runtimeConfig is updated
   */
  const clientThemeCSS = useMemo(() => {
    return parseThemeVariables(runtimeConfig.theme);
  }, [runtimeConfig]);

  return (
    <AppContext.Provider value={value}>
      <style>{clientThemeCSS}</style>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
