import React from 'react';
import { useOutlet } from 'react-router-dom';

const DefaultRoot = (): JSX.Element => {
  const outlet = useOutlet();

  // TODO, try to get rid of fragment
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{outlet}</>;
};

export default DefaultRoot;
