import { Validator } from './types';

/**
 * String validators
 *  Any validators that relate to generic strings.
 */

/**
 * Creates a validator function to check if a string is empty
 */
export const notEmpty = (errorText: string = 'cannot be empty'): Validator<string> => {
  return (value) => {
    if (value.length === 0) {
      return [errorText];
    }
    return [];
  };
};

/**
 * Creates a validator function to check the minimum length of a string
 * @param min The minimum length of the string
 */
export const minLength = (min: number): Validator<string> => {
  return (value) => {
    if (value.length < min) {
      return min === 1 ? ['cannot be blank'] : [`cannot be shorter than ${min} characters`];
    }
    return [];
  };
};

/**
 * Creates a validator function to check the minimum length of a string
 * @param max The maximum length of the string
 */
export const maxLength = (max: number): Validator<string> => {
  return (value) => {
    if (value.length > max) {
      return [`cannot be longer than ${max} characters`];
    }
    return [];
  };
};

/**
 * Creates a validator function to check if an email is valid
 * (This just checks for [string]@[string].[string]
 */
export const isEmail = (): Validator<string> => {
  return (value) => {
    if (!/^\S+@\S+\.\S+$/.test(value)) {
      return ['invalid email address'];
    }
    return [];
  };
};

/**
 * Checks if the given string is a valid number
 */
export const isNumber = (): Validator<string> => {
  return (value) => {
    const error = ['invalid number'];
    if (value.length === 0) {
      return error;
    }
    if (Number.isNaN(Number(value))) {
      return error;
    }
    return [];
  };
};

/**
 * Creates a validator that checks if a string matches a regex
 * @param regex The regex to match against
 * @param errorMsg Optionally change the error message
 */
export const matchRegex = (regex: RegExp, errorMsg: string = 'incorrect format'): Validator<string> => {
  return (value) => {
    return regex.test(value) ? [] : [errorMsg];
  };
};
