import { networkErrorReporter, networkService } from '@gi/gi-network';
import { journalEntriesFromAPI, journalEntryFromAPI, journalEntryToAPI, journalFromAPI, weeklyTotalsFromDayEntryCounts } from '../parsers/journal-entry-parser';
import {
  attachGetJournalActiveYearsErrorClientMessage,
  attachGetJournalDaysErrorClientMessage,
  attachGetJournalDayEntryCountsErrorClientMessage,
  attachSaveJournalEntryErrorClientMessage,
  attachCreateJournalEntryErrorClientMessage,
  attachDeleteJournalEntryErrorClientMessage,
} from './journal-service-errors';

/**
 * Network service class for Journal
 *
 * @class JournalService
 */
class JournalService {
  constructor(endpoint) {
    this.endpoint = endpoint;
    console.debug(`Created JournalService with endpoint ${this.endpoint}`);
  }

  /**
   * Executes a network request to create a journal entry and returns a promise which resolves with the created entry
   * if successful and rejects with an error if it fails.
   *
   * @param {number} userID
   * @param {JournalEntry} entry
   * @returns {Promise}
   * @memberof JournalService
   */
  createEntry(userID, entry) {
    const path = `/journal/${userID}/journalEntries`;
    const params = {
      date: entry.dateYMD,
    };

    const url = `${this.endpoint}${path}`;

    return networkService
      .post(url, params, journalEntryToAPI(entry))
      .then((response) => {
        const responseEntry = response.body;
        // API doesn't return dateYMD so we have to add it back in.
        responseEntry.dateYMD = entry.dateYMD;
        return journalEntryFromAPI(responseEntry);
      })
      .catch(networkErrorReporter('POST', 'journal entry'))
      .catch((requestError) => {
        attachCreateJournalEntryErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Executes a network request to delete an existing entry. Returns a promise if successful,
   * else rejects with an error on failure.
   *
   * @param {number} userID
   * @param {Entry} entry
   * @return {Promise}
   */

  deleteJournalEntry(userID, entry) {
    const path = `/journal/${userID}/journalEntries/${entry.ID}`;

    const url = `${this.endpoint}${path}`;

    return networkService
      .delete(url)
      .catch((requestError) => {
        if (requestError.statusCode !== 400) {
          // The journal DELETE service issues a 400 on success.
          // So don't throw if it's a 400.
          attachDeleteJournalEntryErrorClientMessage(requestError);
          throw requestError;
        }
      })
      .catch(networkErrorReporter('DELETE', 'journal entry'));
  }

  /**
   * Executes a network request to update an existing entry. Returns a promise which resolves to the returned entry
   * if successful, else rejects with an error on failure.
   *
   * @param {number} userID
   * @param {JournalEntry} entry
   * @return {Promise}
   */
  saveJournalEntry(userID, entry) {
    const path = `/journal/${userID}/journalEntries/${entry.ID}`;

    const url = `${this.endpoint}${path}`;

    return networkService
      .put(url, {}, journalEntryToAPI(entry))
      .then((response) => {
        const responseEntry = response.body;
        // API doesn't return dateYMD so we have to add it back in.
        responseEntry.dateYMD = entry.dateYMD;
        return journalEntryFromAPI(responseEntry);
      })
      .catch(networkErrorReporter('PUT', 'journal entry'))
      .catch((requestError) => {
        attachSaveJournalEntryErrorClientMessage(requestError);
        throw requestError;
      });
  }

  getWeeklyTotals(userID, year, plantCode = '') {
    const params = {
      year,
      plantCode,
    };

    const url = `${this.endpoint}/journal/${userID}/reporting/dayentrycounts`;
    return networkService
      .get(url, params)
      .then((response) => weeklyTotalsFromDayEntryCounts(year, plantCode, response.body))
      .catch(networkErrorReporter('GET', 'journal weekly totals'))
      .catch((requestError) => {
        attachGetJournalDayEntryCountsErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Executes a network request to fetch entries for the provided user through the API. Returns a promise which resolves
   * with an instance of JournalEntries on success or rejects with an error.
   *
   * @param {number} userID
   * @param {number} year
   * @param {string} plantCode
   * @return {Promise}
   */
  getJournalByYearAndPlantCode(userID, year, plantCode) {
    const startDate = new Date(year, 0, 1).toISOString();
    const endDate = new Date(year + 1, 0, 1).toISOString();
    const params = {
      plantCode,
      startDate,
      endDate,
    };

    const url = `${this.endpoint}/journal/${userID}`;
    return networkService
      .get(url, params)
      .then((response) => {
        return journalFromAPI(response.body);
      })
      .catch(networkErrorReporter('GET', 'journal entries by year and plant code'))
      .catch((requestError) => {
        attachGetJournalDaysErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Executes a network request to fetch active years for the provided user and plantCode through the API.
   * Returns a promise which resolves with an array of active years on success or rejects with an error.
   *
   * @param {number} userID
   * @param {string} plantCode
   * @return {Promise}
   */
  getJournalActiveYears(userID, plantCode = null) {
    const params = {
      plantCode,
    };
    if (plantCode === null) {
      delete params.plantCode;
    }

    const url = `${this.endpoint}/journal/${userID}/reporting/years`;
    return networkService
      .get(url, params)
      .then((response) => {
        return response.body;
      })
      .catch(networkErrorReporter('GET', 'journal active years'))
      .catch((requestError) => {
        attachGetJournalActiveYearsErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Executes a network request to fetch entries for the provided user through the API. Returns a promise which resolves
   * with an instance of JournalEntries on success or rejects with an error.
   *
   * @param {number} userID
   * @param {Date} startDate
   * @param {Date} endDate
   * @return {Promise}
   */
  getJournalByDate(userID, startDate, endDate) {
    const params = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    const url = `${this.endpoint}/journal/${userID}`;
    return networkService
      .get(url, params)
      .then((response) => {
        return {
          journalEntries: journalEntriesFromAPI(response.body),
        };
      })
      .catch(networkErrorReporter('GET', 'journal entries by date'))
      .catch((requestError) => {
        attachGetJournalDaysErrorClientMessage(requestError);
        throw requestError;
      });
  }
}

export default JournalService;
