import { Geometry } from '@gi/math';
import { IMPERIAL_SNAP_DISTANCE, METRIC_SNAP_DISTANCE } from '@gi/constants';

export const SnapUtils = {
  snapPoint(point: Vector2, snapDistance: number): Vector2 {
    return Geometry.snapPoint(point, snapDistance);
  },

  getSnapDistanceFromIsMetric(isMetric: boolean): number {
    return isMetric ? METRIC_SNAP_DISTANCE : IMPERIAL_SNAP_DISTANCE;
  },
};

export const PlantSnapUtils = {
  snapTranslate(rowStart: Vector2, rowEnd: Vector2, snapDistance: number): { rowStart: Vector2; rowEnd: Vector2 } {
    const newRowStart = Geometry.snapPoint(rowStart, snapDistance);
    const delta = Geometry.getPointDelta(rowStart, newRowStart);
    const newRowEnd = Geometry.addPoint(rowEnd, delta);

    return { rowStart: newRowStart, rowEnd: newRowEnd };
  },

  snapTransform(rowStart: Vector2, rowEnd: Vector2, snapDistance: number): { rowStart: Vector2; rowEnd: Vector2 } {
    const newRowStart = Geometry.snapPoint(rowStart, snapDistance);
    const newRowEnd = Geometry.snapPoint(rowEnd, snapDistance);

    return { rowStart: newRowStart, rowEnd: newRowEnd };
  },
};

export const GardenObjectSnapUtils = {
  snapTranslate(start: Vector2, mid: Vector2 | null, end: Vector2, snapDistance: number): { start: Vector2; mid: Vector2 | null; end: Vector2 } {
    const newStart = Geometry.snapPoint(start, snapDistance);
    const delta = Geometry.getPointDelta(start, newStart);
    const newEnd = Geometry.addPoint(end, delta);

    let newMid: Vector2 | null = null;
    if (mid !== null) {
      newMid = Geometry.addPoint(mid, delta);
    }

    return { start: newStart, mid: newMid, end: newEnd };
  },

  snapTransform(start: Vector2, mid: Vector2 | null, end: Vector2, snapDistance: number): { start: Vector2; mid: Vector2 | null; end: Vector2 } {
    const newStart = Geometry.snapPoint(start, snapDistance);
    const newMid = Geometry.snapPoint(mid, snapDistance);
    const newEnd = Geometry.snapPoint(end, snapDistance);

    return { start: newStart, mid: newMid, end: newEnd };
  },
};

export const ShapeSnapUtils = {
  snapTranslate(point1: Vector2, point2: Vector2 | null, point3: Vector2, snapDistance: number): { point1: Vector2; point2: Vector2 | null; point3: Vector2 } {
    const newPoint1 = Geometry.snapPoint(point1, snapDistance);
    const delta = Geometry.getPointDelta(point1, newPoint1);
    const newPoint3 = Geometry.addPoint(point3, delta);

    let newPoint2: Vector2 | null = null;
    if (point2 !== null) {
      newPoint2 = Geometry.addPoint(point2, delta);
    }

    return { point1: newPoint1, point2: newPoint2, point3: newPoint3 };
  },

  snapTransform(point1: Vector2, point2: Vector2 | null, point3: Vector2, snapDistance: number): { point1: Vector2; point2: Vector2 | null; point3: Vector2 } {
    const newPoint1 = Geometry.snapPoint(point1, snapDistance);
    const newPoint2 = Geometry.snapPoint(point2, snapDistance);
    const newPoint3 = Geometry.snapPoint(point3, snapDistance);

    return { point1: newPoint1, point2: newPoint2, point3: newPoint3 };
  },
};

export const TextSnapUtils = {
  snapTranslate(start: Vector2, end: Vector2, snapDistance: number): { start: Vector2; end: Vector2 } {
    const newStart = Geometry.snapPoint(start, snapDistance);
    const delta = Geometry.getPointDelta(start, newStart);
    const newEnd = Geometry.addPoint(end, delta);

    return { start: newStart, end: newEnd };
  },

  snapTransform(start: Vector2, end: Vector2, snapDistance: number): { start: Vector2; end: Vector2 } {
    const newStart = Geometry.snapPoint(start, snapDistance);
    const newEnd = Geometry.snapPoint(end, snapDistance);

    return { start: newStart, end: newEnd };
  },
};
