import { RuntimeConfig } from './types';

/**
 * Default config options.
 * These should mostly be blank, but will be used as fallbacks if not set by the client-specific runtime config.
 */
const config: RuntimeConfig = {
  client: 'default',
  clientName: '',
  clientID: 0,
  shoppingEnabled: false,
  showDashboardArticles: true,
  catalogueFile: '',
  supplierFile: '',
  supportEmail: 'support@garden-planner.net',
  guidesURL: '',
  plantGuidesURL: '',
  pestGuidesURL: '',
  videoURL: '',
  WGPBugsnagKey: '',
  homeURL: '/',
  publishPlanURL: '',
  featureFlags: [],
  dataURLOverrides: {},
  theme: {
    'color-primary': '#1dc089',
    'color-primary-dark': '#009e68',
    'color-primary-content': '#fff',
    'color-secondary': '#009e68',
    'color-secondary-dark': '#008658',
    'color-background': '#f2f2f2',
    'color-background-light': '#f9f9f9',
    'color-background-dark': '#ccc',
    'color-text': '#333',
    'color-text-light': '#666',
    'color-text-extra-light': '#888',
    'color-text-accent': 'var(--color-primary)',
    'color-link': '#1f75a9',
    'color-link-hover': '#0bacc7',
    'color-link-active': '#0bacc7',
    'color-error': '#d05e5e',
    'color-error-alt': '#c32a2a',
    'color-error-text': '#fff',
    'color-error-text-alt': '#ecb0b0',
    'button-default-background': '#ddd',
    'button-default-background-hover': '#fff',
    'button-default-background-active': '#fff',
    'button-default-border': '#ddd',
    'button-default-border-hover': '#fff',
    'button-default-border-active': '#fff',
    'button-default-text-shadow': 'none',
    'button-default-color': '#333',
    'button-default-color-hover': '#333',
    'button-default-color-active': '#333',
    'button-primary-background': 'var(--color-primary)',
    'button-primary-background-hover': 'var(--color-primary-dark)',
    'button-primary-background-active': 'var(--color-primary-dark)',
    'button-primary-border': 'var(--color-primary)',
    'button-primary-border-hover': 'var(--color-primary-dark)',
    'button-primary-border-active': 'var(--color-primary-dark)',
    'button-primary-text-shadow': '1px 1px 0 #10474c47',
    'button-primary-color': 'var(--color-primary-content)',
    'button-primary-color-hover': 'var(--color-primary-content)',
    'button-primary-color-active': 'var(--color-primary-content)',
    'button-primary-light-background': '#fff',
    'button-primary-light-background-hover': 'var(--color-primary-dark)',
    'button-primary-light-background-active': 'var(--color-primary)',
    'button-primary-light-border': 'var(--color-primary-dark)',
    'button-primary-light-border-hover': 'var(--color-primary-dark)',
    'button-primary-light-border-active': 'var(--color-primary)',
    'button-primary-light-text-shadow': 'none',
    'button-primary-light-color': 'var(--color-primary-dark)',
    'button-primary-light-color-hover': 'var(--color-primary-content)',
    'button-primary-light-color-active': 'var(--color-primary-content)',
    'button-secondary-background': 'transparent',
    'button-secondary-background-hover': '#8f8f8f3b',
    'button-secondary-background-active': '#8f8f8f3b',
    'button-secondary-border': 'var(--color-text-light)',
    'button-secondary-border-hover': 'var(--color-text-light)',
    'button-secondary-border-active': 'var(--color-text-light)',
    'button-secondary-text-shadow': 'none',
    'button-secondary-color': 'var(--color-text-light)',
    'button-secondary-color-hover': 'var(--color-text-light)',
    'button-secondary-color-active': 'var(--color-text-light)',
    'button-disabled-background': '#ddd',
    'button-disabled-background-hover': '#ddd',
    'button-disabled-background-active': '#ddd',
    'button-disabled-border': '#bbb',
    'button-disabled-border-hover': '#bbb',
    'button-disabled-border-active': '#bbb',
    'button-disabled-text-shadow': 'none',
    'button-disabled-color': '#bbb',
    'button-disabled-color-hover': '#bbb',
    'button-disabled-color-active': '#bbb',
    'button-warning-background': 'var(--color-error)',
    'button-warning-background-hover': '#bb3737',
    'button-warning-background-active': '#a43030',
    'button-warning-border': 'var(--color-error)',
    'button-warning-border-hover': '#bb3737',
    'button-warning-border-active': '#a43030',
    'button-warning-text-shadow': 'none',
    'button-warning-color': '#fff',
    'button-warning-color-hover': '#fff',
    'button-warning-color-active': '#fff',
    'button-inline-color': 'var(--color-secondary)',
    'button-inline-hover': 'var(--color-secondary-dark)',
    'button-inline-active': 'var(--color-secondary-dark)',
    'loading-screen-background-color': '#f2f2f2',
    'sidebar-right-border': '0',
    'color-sidebar-icon-background': 'var(--color-primary)',
    'color-sidebar-icon': 'var(--color-primary-dark)',
    'color-sidebar-icon-active': 'var(--color-primary-content)',
    'color-sidebar-icon-hover': 'var(--color-primary-content)',
    'color-sidebar-background': 'var(--color-background-light)',
    'color-sidebar-active-background': 'var(--color-primary-content)',
    'color-sidebar-text': 'var(--color-text)',
    'color-sidebar-active-circle': 'var(--color-primary)',
    'color-sidebar-menu-burger': 'var(--color-primary-dark)',
    'color-sidebar-menu-burger-hover': '#fff',
    'color-sidebar-close': '#333',
    'color-sidebar-close-background': '#ffffff33',
    'color-sidebar-close-background-hover': '#ffffffaa',
    'sidebar-menu-burger-text-shadow': 'none',
    'logo-container-background-color': 'transparent',
    'logo-image-filter': 'none',
    'color-modal-header-background': 'var(--color-primary)',
    'color-modal-header': 'var(--color-primary-content)',
    'text-shadow-modal-header': '1px 1px 0 #176248',
    'inactive-tab-color': 'var(--color-primary-dark)',
    'modal-close-button-color': '#33333322',
    'modal-close-button-hover-color': '#33333366',
    'modal-close-button-active-color': '#33333399',
    'color-planner-header-tab-line': 'var(--color-primary-dark)',
    'color-planner-header-tab-line-active': 'var(--color-primary)',
    'color-planner-header-circle': 'var(--color-secondary)',
    'color-selection-list-icon': '#888',
    'color-selection-list-icon-active': 'var(--color-secondary)',
    'required-label-color': '#c32a2a',
    'plant-item-attribute-label-color': 'var(--color-secondary)',
    'expand-button-color': 'var(--color-secondary)',
    'dashboard-logo-background-color': 'transparent',
    'dashboard-logo-width': '280px',
  },
  backgroundImage: {
    src: '1.jpg',
    blurSrc: '1-blurred.jpg',
  },
  logo: {
    src: 'clients/logo.png',
    height: 53,
  },
};

export default config;
