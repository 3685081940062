import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {{
 *  active?: boolean
 * }}
 */
const DashboardIcon = ({ active = false }) => {
  return <i className={`icon-overview sidebar-icon ${active ? 'sidebar-icon-active' : ''}`} />;
};

DashboardIcon.propTypes = {
  active: PropTypes.bool,
};

export default DashboardIcon;
