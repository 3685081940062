import React, { useContext, useState } from 'react';

import { PlantUtils } from '@gi/plant';
import { HelpButton } from '@gi/base-components';
import { FormSection } from '@gi/form-responsive';
import { PlantSpacingHelpModal } from '@gi/help-modals';
import { useHelpModalEventHook } from '@gi/garden-platform-events';

import { EditPlantContext } from '../context/edit-plant-context';

import './plant-plant-spacing.scss';

const PlanPlantSpacing = (): JSX.Element => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const { plant, userPlantVariety, distanceUnits } = useContext(EditPlantContext);

  const spacings = PlantUtils.getSpacings(plant, userPlantVariety);
  const sfgPlantCount = PlantUtils.getSquareFootPlantCount(plant, userPlantVariety);

  useHelpModalEventHook('plant spacing', showHelpModal, 'edit-plant-modal');

  return (
    <FormSection
      padding={12}
      gap={6}
      className='form-section-background plan-plant-spacing'
      heading={
        <>
          Spacing <HelpButton onClick={() => setShowHelpModal(true)} />
        </>
      }
    >
      {showHelpModal ? <PlantSpacingHelpModal closeModal={() => setShowHelpModal(false)} /> : null}
      <p>
        <strong>Single Plants:</strong> {distanceUnits.getUnitString(spacings.spacing)} each way (minimum)
      </p>
      <p>
        <strong>Row Spacing:</strong> {distanceUnits.getUnitString(spacings.inRowSpacing)} with {distanceUnits.getUnitString(spacings.rowSpacing)} row gap
        (minimum)
      </p>
      {plant.canBeSquareFootPlant ? (
        <p>
          <strong>Square Foot Gardening:</strong> {sfgPlantCount} plant
          {sfgPlantCount > 1 ? 's' : ''} per square foot.
        </p>
      ) : null}
    </FormSection>
  );
};

export default PlanPlantSpacing;
