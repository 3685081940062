import React, { ReactNode } from 'react';

import BaseObjectiveIcon from './base-objective-icon';

import styles from './icon-styles.module.css';

interface iProps {
  src?: string;
  className?: string;
  children?: ReactNode;
  checked?: boolean;
  size?: number;
}

const CheckableObjectiveIcon = ({ src, className, children, checked, size }: iProps): JSX.Element => {
  return (
    <BaseObjectiveIcon className={className} src={src} size={size}>
      {checked ? (
        <span className={styles.checkmark}>
          <i className='icon-ok' />
        </span>
      ) : null}
      {children}
    </BaseObjectiveIcon>
  );
};

export default CheckableObjectiveIcon;
