import React, { useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CanvasActionCreators, CanvasSelectors } from '@gi/react-garden-canvas';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { RequestSelectors } from '@gi/react-requests';
import { LoadingState } from '@gi/constants';
import { SessionSelectors } from '@gi/react-session';
import { PlanSetUtils } from '@gi/plan';

import './unsaved-plan-entry.scss';

interface iProps {
  planID: number;
}

const UnsavedPlanEntry = ({ planID }: iProps): JSX.Element | null => {
  const requestKey = `SAVE_PLAN_${planID}`;

  const dispatch = useDispatch();

  const plans = useSelector(CanvasSelectors.getPlans);
  const unsavedPlans = useSelector(CanvasSelectors.getUnsavedPlans);
  const requests = useSelector(RequestSelectors.getRequests);
  const initialRequest = useRef(RequestsUtils.getRequest(requests, requestKey));
  const user = useSelector(SessionSelectors.getUser);

  const plan = useMemo(() => {
    return PlanSetUtils.planSetGetPlan(plans, planID);
  }, [planID, plans]);

  const requestStatus = useMemo(() => {
    const request = RequestsUtils.getRequest(requests, requestKey);
    if (!request || request === initialRequest.current) {
      return RequestStatus.PRE;
    }
    return request.status;
  }, [requests]);

  const status = useMemo(() => {
    if (!plan) {
      return LoadingState.NONE;
    }
    if (!PlanSetUtils.planSetHasPlan(unsavedPlans, planID)) {
      return LoadingState.SUCCESS;
    }
    switch (requestStatus) {
      case RequestStatus.IN_PROGRESS:
        return LoadingState.LOADING;
      case RequestStatus.COMPLETE:
        return LoadingState.SUCCESS;
      case RequestStatus.FAIL:
        return LoadingState.ERROR;
      default:
        return LoadingState.NONE;
    }
  }, [plan, unsavedPlans, requestStatus]);

  const savePlan = useCallback(() => {
    if (!user || !plan) {
      return;
    }
    dispatch(CanvasActionCreators.savePlan(plan));
  }, []);

  const rightComponent = useMemo(() => {
    if (status === LoadingState.SUCCESS) {
      return 'Saved';
    }
    if (status === LoadingState.LOADING) {
      return 'Saving...';
    }
    return 'Save';
  }, [status]);

  const icon = useMemo(() => {
    if (status === LoadingState.SUCCESS) {
      return 'icon-ok';
    }
    if (status === LoadingState.LOADING) {
      return 'icon-spinner animate-pulse';
    }
    return 'icon-floppy';
  }, [status]);

  if (!plan) {
    return null;
  }

  return (
    <button
      className={`unsaved-plan-entry ${status === LoadingState.SUCCESS ? 'success' : ''}`}
      key={plan.id}
      type='button'
      onClick={savePlan}
      disabled={status === LoadingState.SUCCESS || status === LoadingState.LOADING}
    >
      <div className='unsaved-plan-middle'>
        <span className='unsaved-plan-name'>{plan.name}</span>
        <span className='unsaved-plan-year'>{plan.year}</span>
      </div>
      <div className='unsaved-plan-right'>
        {rightComponent}
        <span className='unsaved-plan-icon'>
          <i className={icon} />
        </span>
      </div>
    </button>
  );
};

export default UnsavedPlanEntry;
