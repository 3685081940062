import { GardenItemType, ShapeType } from '@gi/constants';
import { HiddenFlag, InspectableClassData, InteractableComponentCallbacks, NodeEvent, PointerData } from '@gi/core-renderer';
import { Geometry } from '@gi/math';

import DrawTool, { DrawToolState, MIN_DRAW_DISTANCE } from './draw-tool';
import ShapeNode from '../shapes/shape-node';
import LineHandleSetNode from '../handles/line-handle-set-node';
import PolygonHandleSetNode from '../handles/polygon-handle-set-node';
import RectangleHandleSetNode from '../handles/rectangle-handle-set-node';
import { SimulatedShape } from '../../../simulation/simulated-shape';
import EditSimulatedShape from '../../../simulation/edit/edit-simulated-shape';
import CanvasInteractionInterface from '../../../canvas-interface/canvas-interaction-interface';
import GardenItemSelectionMiddleware from '../../components/garden-item-selection-middleware';
import CanvasLayers from '../../canvas-layers';
import RectangleShapeNode from '../shapes/rectangle-shape-node';
import { DRAWING_ITEM_ID } from '../../constants';
import EllipseShapeNode from '../shapes/ellipse-shape-node';
import LineShapeNode from '../shapes/line-shape-node';
import TriangleShapeNode from '../shapes/triangle-shape-node';
import { ShapeSnapUtils } from '../../../simulation/snap-utils';

const DEFAULT_POINTS: Record<ShapeType, { p1: Vector2; p2: Vector2 | null; p3: Vector2 }> = {
  [ShapeType.RECTANGLE]: {
    p1: { x: -20, y: -20 },
    p2: null,
    p3: { x: 20, y: 20 },
  },
  [ShapeType.ELLIPSE]: {
    p1: { x: -20, y: -20 },
    p2: null,
    p3: { x: 20, y: 20 },
  },
  [ShapeType.TRIANGLE]: Geometry.equilateralTrianglePoints({ x: 0, y: 0 }, 50),
  [ShapeType.LINE]: {
    p1: { x: -20, y: 0 },
    p2: null,
    p3: { x: 20, y: 0 },
  },
  /** Unused below */
  [ShapeType.PLAN_DIMENSIONS]: { p1: { x: 0, y: 0 }, p2: null, p3: { x: 0, y: 0 } },
  [ShapeType.FILL]: { p1: { x: 0, y: 0 }, p2: null, p3: { x: 0, y: 0 } },
};

export interface DrawShapeToolState extends DrawToolState {
  itemType: GardenItemType.Shape;
  subtype: ShapeType;
  filled: boolean;
}

class DrawShapeTool extends DrawTool<DrawShapeToolState> {
  type = 'DrawShapeTool';

  readonly shapeType: ShapeType;
  readonly fill: number | null;
  readonly stroke: number | null;
  readonly strokeWidth: number;
  readonly texture: string | null;

  private simulatedShape: SimulatedShape | null = null;
  private editSimulatedShape: EditSimulatedShape | null = null;
  private handles: LineHandleSetNode | PolygonHandleSetNode | RectangleHandleSetNode | null = null;
  private previewNode: RectangleShapeNode | EllipseShapeNode | TriangleShapeNode | LineShapeNode | null = null;

  constructor(
    shapeType: ShapeType,
    fill: number | null,
    stroke: number | null,
    strokeWidth: number,
    texture: string | null,
    interactionInterface: CanvasInteractionInterface,
    canvasLayers: CanvasLayers,
    dragToDrawEvent?: PointerEvent
  ) {
    super(
      interactionInterface,
      canvasLayers,
      {
        itemType: GardenItemType.Shape,
        subtype: shapeType,
        filled: fill !== null,
      },
      dragToDrawEvent
    );

    this.ignoreClick = true;

    this.shapeType = shapeType;
    this.fill = fill;
    this.stroke = stroke;
    this.strokeWidth = strokeWidth;
    this.texture = texture;

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    if (!this.isDragToDraw) {
      return;
    }

    switch (this.shapeType) {
      case ShapeType.RECTANGLE: {
        const { p1, p3 } = DEFAULT_POINTS[ShapeType.RECTANGLE];
        this.previewNode = new RectangleShapeNode(DRAWING_ITEM_ID, {
          point1: p1,
          point2: null,
          point3: p3,
          fill: this.fill,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth,
          texture: this.texture,
          rotation: 0,
          locked: false,
          zIndex: 0,
        });
        break;
      }

      case ShapeType.ELLIPSE: {
        const { p1, p3 } = DEFAULT_POINTS[ShapeType.ELLIPSE];
        this.previewNode = new EllipseShapeNode(DRAWING_ITEM_ID, {
          point1: p1,
          point2: null,
          point3: p3,
          fill: this.fill,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth,
          texture: this.texture,
          rotation: 0,
          locked: false,
          zIndex: 0,
        });
        break;
      }

      case ShapeType.TRIANGLE: {
        const { p1, p2, p3 } = DEFAULT_POINTS[ShapeType.TRIANGLE];
        this.previewNode = new TriangleShapeNode(DRAWING_ITEM_ID, {
          point1: p1,
          point2: p2!,
          point3: p3,
          fill: this.fill,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth,
          texture: this.texture,
          rotation: 0,
          locked: false,
          zIndex: 0,
        });
        break;
      }

      case ShapeType.LINE: {
        const { p1, p2, p3 } = DEFAULT_POINTS[ShapeType.LINE];
        this.previewNode = new LineShapeNode(DRAWING_ITEM_ID, {
          point1: p1,
          point2: p2,
          point3: p3,
          fill: this.fill,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth,
          texture: this.texture,
          rotation: 0,
          locked: false,
          zIndex: 0,
        });
        break;
      }
      default: {
        // Nothing
        console.error(`Couldn't create preview of unsupported shape type: ${this.shapeType}`);
      }
    }

    if (this.previewNode) {
      this.canvasLayers.drawingPreviewLayer.addChildren(this.previewNode);
      this.previewNode.visibility.addHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    }
  };

  #onBeforeUnbind = () => {
    if (this.previewNode) {
      this.previewNode.destroy();
    }
  };

  onDragStart: InteractableComponentCallbacks['onDragStart'] = (data, interaction, controls) => {
    // We only use the preview for drag-to-draw, which can't repeat, so destroy it here.
    if (this.previewNode) {
      this.previewNode.destroy();
      this.previewNode = null;
    }

    this.simulatedShape = this.interactionInterface.startDrawingShape(
      this.shapeType,
      data.worldPosition,
      this.fill,
      this.stroke,
      this.strokeWidth,
      this.texture
    );

    switch (this.shapeType) {
      case ShapeType.RECTANGLE:
      case ShapeType.ELLIPSE: {
        const handles = this.tryGetContext(GardenItemSelectionMiddleware)?.forceUpdateHandles();
        if (handles && handles instanceof RectangleHandleSetNode) {
          const [target] = handles.targets;
          if (target && target instanceof ShapeNode && target.id === this.simulatedShape.id) {
            this.handles = handles;
            this.handles.BR.callOnDragStart(data, interaction, controls);
          } else {
            console.error('Shape handles are for the wrong shape');
          }
        } else {
          console.error('Failed to find shape handles after drawing shape');
        }
        break;
      }

      case ShapeType.LINE: {
        const handles = this.tryGetContext(GardenItemSelectionMiddleware)?.forceUpdateHandles();
        if (handles && handles instanceof LineHandleSetNode) {
          const [target] = handles.targets;
          if (target && target instanceof ShapeNode && target.id === this.simulatedShape.id) {
            this.handles = handles;
            this.handles.P2.callOnDragStart(data, interaction, controls);
          } else {
            console.error('Shape handles are for the wrong shape');
          }
        } else {
          console.error('Failed to find shape handles after drawing shape');
        }
        break;
      }

      case ShapeType.TRIANGLE:
      default: {
        this.editSimulatedShape = new EditSimulatedShape(this.simulatedShape);
        this.editSimulatedShape.start();
      }
    }
  };

  onDragMove: InteractableComponentCallbacks['onDragMove'] = (data, interaction, controls) => {
    if (!this.simulatedShape) {
      return;
    }

    switch (this.shapeType) {
      case ShapeType.RECTANGLE:
      case ShapeType.ELLIPSE: {
        if (this.handles && this.handles instanceof RectangleHandleSetNode) {
          this.handles.BR.callOnDragMove(data, interaction, controls);
        } else {
          console.error('Failed to manipulate drawn shape: Rectangle handles missing');
        }
        break;
      }

      case ShapeType.LINE: {
        if (this.handles && this.handles instanceof LineHandleSetNode) {
          this.handles.P2.callOnDragMove(data, interaction, controls);
        } else {
          console.error('Failed to manipulate drawn shape: Line handles missing');
        }
        break;
      }

      case ShapeType.TRIANGLE:
      default: {
        this.editSimulatedShape?.translate(data.worldPositionTotalDelta);
      }
    }
  };

  onDragEnd: InteractableComponentCallbacks['onDragEnd'] = (data, interaction, controls) => {
    if (!this.simulatedShape) {
      return;
    }

    switch (this.shapeType) {
      case ShapeType.RECTANGLE:
      case ShapeType.ELLIPSE: {
        if (this.handles && this.handles instanceof RectangleHandleSetNode) {
          this.handles.BR.callOnDragEnd(data, interaction, controls);
        } else {
          console.error('Failed to manipulate drawn shape: Rectangle handles missing');
        }
        break;
      }

      case ShapeType.LINE: {
        if (this.handles && this.handles instanceof LineHandleSetNode) {
          this.handles.P2.callOnDragEnd(data, interaction, controls);
        } else {
          console.error('Failed to manipulate drawn shape: Line handles missing');
        }
        break;
      }

      case ShapeType.TRIANGLE:
      default: {
        this.editSimulatedShape?.translate(data.worldPositionTotalDelta);
        this.editSimulatedShape?.end();
      }
    }

    this.makeMinimumSize(this.simulatedShape);

    this.handles = null;
    this.simulatedShape = null;
    this.editSimulatedShape = null;

    this.interactionInterface.onDrawShape(this.shapeType, this.fill !== null || this.texture !== null, this.isDragToDraw ?? false);
    this.interactionInterface.pushUpdates();
  };

  // eslint-disable-next-line class-methods-use-this
  makeMinimumSize = (shape: SimulatedShape) => {
    const points = DEFAULT_POINTS[shape.shapeType];
    if (points !== null && Geometry.dist(shape.point1, shape.point3) < MIN_DRAW_DISTANCE) {
      shape.setPosition(
        Geometry.addPoint(shape.center, points.p1),
        points.p2 === null ? null : Geometry.addPoint(shape.center, points.p2),
        Geometry.addPoint(shape.center, points.p3),
        0
      );
    }
  };

  onPointerMove = (data: Pick<PointerData, 'worldPosition' | 'screenPosition'>) => {
    if (!this.previewNode) {
      return;
    }

    const { p1, p2, p3 } = DEFAULT_POINTS[this.shapeType];
    const point1 = Geometry.addPoint(data.worldPosition, p1);
    const point2 = p2 !== null ? Geometry.addPoint(data.worldPosition, p2) : null;
    const point3 = Geometry.addPoint(data.worldPosition, p3);

    if (this.snapToGrid) {
      const {
        point1: snappedPoint1,
        point2: snappedPoint2,
        point3: snappedPoint3,
      } = ShapeSnapUtils.snapTranslate(point1, point2, point3, this.snapToGridDistance);
      this.previewNode.state.values.point1 = snappedPoint1;
      this.previewNode.state.values.point2 = snappedPoint2;
      this.previewNode.state.values.point3 = snappedPoint3;
    } else {
      this.previewNode.state.values.point1 = point1;
      this.previewNode.state.values.point2 = point2;
      this.previewNode.state.values.point3 = point3;
    }

    if (this.previewNode.visibility.shouldHide && this.isDragToDraw) {
      this.previewNode.visibility.removeHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    }
  };

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default DrawShapeTool;
