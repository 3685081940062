import React from 'react';

import { LoadingState } from '@gi/constants';
import { Page, PageSection } from '@gi/page-layout';

import './app-loading-screen.scss';

interface iProps {
  loading: LoadingState;
  errors?: string[];
}

export const AppLoadingScreen = ({ loading, errors }: iProps): JSX.Element | null => {
  switch (loading) {
    case LoadingState.LOADING:
    case LoadingState.NONE: {
      return (
        <Page centerContent>
          <p>
            Loading the Garden Planner... <i className='icon-spinner animate-pulse' />
          </p>
        </Page>
      );
    }
    case LoadingState.ERROR: {
      return (
        <Page centerContent>
          <PageSection className='client-loading-screen-error'>
            {/* We can't show a logo here, as we haven't definitely loaded the runtime config */}
            <h1>Something went wrong!</h1>
            <p>
              Sorry, something went wrong when trying to load the Garden Planner. Please check your network connecting and try again. If the problem persists,
              please contact support.
            </p>
            <p>
              <u>
                <strong>Errors:</strong>
              </u>
            </p>
            {errors && (
              <ul>
                {errors.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            )}
          </PageSection>
        </Page>
      );
    }
    default: {
      return null;
    }
  }
};
