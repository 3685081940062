import { type Supplier } from './supplier';

export type Suppliers = {
  IDs: number[];
  byID: Record<number, Supplier>;
};

export class SuppliersUtils {
  static create(): Suppliers {
    return {
      IDs: [],
      byID: {},
    };
  }

  static add(suppliers: Suppliers, supplier: Supplier): Suppliers {
    return {
      IDs: [...suppliers.IDs, supplier.ID],
      byID: {
        ...suppliers.byID,
        [supplier.ID]: supplier,
      },
    };
  }

  static addMut(suppliers: Suppliers, supplier: Supplier): void {
    suppliers.IDs.push(supplier.ID);
    suppliers.byID[supplier.ID] = supplier;
  }

  static getSupplier(suppliers: Suppliers, supplierID) {
    return suppliers.byID[supplierID];
  }

  static asList(suppliers: Suppliers) {
    return suppliers.IDs.map((ID) => suppliers.byID[ID]);
  }

  static getCount(suppliers: Suppliers) {
    return suppliers.IDs.length;
  }
}
