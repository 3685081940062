import React, { useMemo } from 'react';

import FixedTutorialWindow from './fixed-tutorial-window';
import AttachedTutorialWindow from './attached-tutorial-window';
import TutorialDisplayContainer from './tutorial-display-container';
import { TutorialAttachment } from '../tutorial';

/**
 * Returns the first element that matches the given CSS selector, or null
 * if no element matches
 */
function getElm(selector: string) {
  const elms = document.querySelectorAll<HTMLElement>(selector);

  if (elms.length > 0) {
    return elms[0];
  }

  return null;
}

interface iProps {
  attachment?: TutorialAttachment;
  children: React.ReactNode;
}

const TutorialWindow = ({ children, attachment }: iProps): JSX.Element => {
  const element = useMemo(() => {
    if (!attachment) {
      return null;
    }

    return getElm(attachment.locator);
  }, [attachment]);

  if (!attachment || !element) {
    return (
      <TutorialDisplayContainer>
        <FixedTutorialWindow>{children}</FixedTutorialWindow>
      </TutorialDisplayContainer>
    );
  }

  if (attachment && !element) {
    console.error(`Invalid tutorial step attachment, no element found for locator ${attachment.locator}`);
  }

  return (
    <AttachedTutorialWindow target={element} attachment={attachment}>
      {children}
    </AttachedTutorialWindow>
  );
};

export default TutorialWindow;
