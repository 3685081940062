import { useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingState } from '@gi/constants';
import { AsyncOperation } from '@gi/utils';

import useAsRef from './use-as-ref';

interface AsyncResourceOptions<T> {
  loadOnStart?: boolean;
  initialValue?: T;
}

export type AsyncResource<TData, TError = Error> = {
  load: () => void;
  loadIfNeeded: () => void;
  /** If true, this operation has completed (successfully or not) at least once */
  hasAttempted: boolean;
} & AsyncOperation<TData, TError>;

const useAsyncResource = <TData, TError = Error>(
  getter: () => Promise<TData>,
  { loadOnStart = true, initialValue }: AsyncResourceOptions<TData> = {}
): AsyncResource<TData, TError> => {
  const getterRef = useAsRef(getter);
  const [hasAttempted, setHasAttempted] = useState<boolean>(false);
  const [resource, setResource] = useState<AsyncOperation<TData, TError>>(
    initialValue === undefined ? { status: LoadingState.NONE } : { status: LoadingState.SUCCESS, value: initialValue }
  );

  /**
   * Loads the resource using the getter
   */
  const load = useCallback(() => {
    setResource({ status: LoadingState.LOADING });
    getterRef
      .current()
      .then((value) => {
        setResource({ status: LoadingState.SUCCESS, value });
        setHasAttempted(true);
      })
      .catch((error) => {
        setResource({ status: LoadingState.ERROR, error });
        setHasAttempted(true);
      });
  }, []);

  /**
   * Loads the resource using the getter, only if the resource isn't loading or successfully loaded.
   */
  const loadIfNeeded = useCallback(() => {
    if (resource.status === LoadingState.NONE || resource.status === LoadingState.ERROR) {
      load();
    }
  }, [load, resource]);

  /**
   * Load the resource when we're created if enabled
   */
  useEffect(() => {
    if (loadOnStart && initialValue === undefined) {
      load();
    }
  }, []);

  const value = useMemo(
    () => ({
      ...resource,
      load,
      loadIfNeeded,
      hasAttempted,
    }),
    [resource, load, loadIfNeeded, hasAttempted]
  );

  return value;
};

export default useAsyncResource;
