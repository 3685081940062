import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Plant from '@gi/plant';

export type PlantInformationModalState = {
  plant: Plant | null;
  defaultTab: string | null;
};

const DEFAULT_STATE: PlantInformationModalState = {
  plant: null,
  defaultTab: null,
};

export interface OpenPlantInformationModalPayload {
  plant: Plant;
  defaultTab?: string | null;
}

export const plantInformationModalSlice = createSlice({
  name: 'plantInformationModal',
  initialState: DEFAULT_STATE,
  reducers: {
    openPlantInformationModal: (state, action: PayloadAction<OpenPlantInformationModalPayload>) => {
      state.plant = action.payload.plant;
      state.defaultTab = action.payload.defaultTab ? action.payload.defaultTab : null;
    },
    closePlantInformationModal: () => {
      return DEFAULT_STATE;
    },
  },
});

export const plantInformationModalReducer = plantInformationModalSlice.reducer;
export const PlantInformationActionCreators = plantInformationModalSlice.actions;

export type ReducerStateWithPlantInformation = {
  plantInformationModal: PlantInformationModalState;
};
