import { BaseShoppingListEntry } from './shopping-entry';

/**
 * A model representing a set of items of a particular type in a ShoppingList
 */
export type ShoppingListItems<T> = {
  uuids: string[];
  entriesByUuid: Record<string, BaseShoppingListEntry<T>>;
  productCounts: Record<number, Record<number, number>>;
};

export class ShoppingListItemsUtils {
  static create<T>(): ShoppingListItems<T> {
    return {
      uuids: [],
      entriesByUuid: {},
      productCounts: {},
    };
  }

  static setEntry<T>(listItems: ShoppingListItems<T>, entry: BaseShoppingListEntry<T>) {
    if (!listItems.entriesByUuid[entry.uuid]) {
      ShoppingListItemsUtils.addEntry(listItems, entry);
    } else {
      ShoppingListItemsUtils.updateEntry(listItems, entry);
    }
  }

  static addEntry<T>(listItems: ShoppingListItems<T>, entry: BaseShoppingListEntry<T>) {
    listItems.uuids.push(entry.uuid);
    listItems.entriesByUuid[entry.uuid] = entry;
    if (!listItems.productCounts[entry.productId]) {
      listItems.productCounts[entry.productId] = {};
    }
    if (!listItems.productCounts[entry.productId][entry.variantId]) {
      listItems.productCounts[entry.productId][entry.variantId] = 0;
    }

    listItems.productCounts[entry.productId][entry.variantId]++;
  }

  static updateEntry<T>(listItems: ShoppingListItems<T>, entry: BaseShoppingListEntry<T>) {
    ShoppingListItemsUtils.removeEntry(listItems, entry.uuid);
    ShoppingListItemsUtils.addEntry(listItems, entry);
  }

  static removeEntry<T>(listItems: ShoppingListItems<T>, uuid: string) {
    const entry = listItems.entriesByUuid[uuid];
    listItems.productCounts[entry.productId][entry.variantId]--;
    listItems.uuids = listItems.uuids.filter((_uuid) => _uuid !== uuid);
    delete listItems.entriesByUuid[uuid];
  }
}
