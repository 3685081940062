import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GuruPreviewItem, GuruSection } from '@gi/app-guru-types';
import { AsyncOperation } from '@gi/utils';
import { GuruActionCreators, GuruSelectors } from '@gi/app-guru-slice';
import { LoadingState } from '@gi/constants';

type DirectoryPageSectionContextType = {
  /** Sets how many items the consumer can currently see at once */
  setPageSize: (size: number) => void;
  /** Sets the index of the first item being viewed by the consumer. Enabled lookAhead */
  setCurrentItemIndex: (item: number) => void;
  /** Sets the maximum number of loading placeholders that will be on-screen */
  setMaxLoadingPlaceholders: (max: number | null) => void;
  /** Manually load the next section, if possible */
  loadNext: () => void;
  /** Is there a next section to load */
  hasNext: boolean;
  /** All items, including placeholders for loading that line up with the page size */
  allItems: AsyncOperation<GuruPreviewItem>[];
  /** Only the items that have definitely been loaded, no loading placeholders */
  loadedItems: AsyncOperation<GuruPreviewItem>[];
  /** True if the initial section is loading */
  loading: boolean;
};

export const DirectoryPageSectionContext = createContext<DirectoryPageSectionContextType>({} as DirectoryPageSectionContextType);

/**
 * Returns the next section ID from the given section ID
 *
 * @returns string of the next section Id, null if the next page doesn't exist or
 * undefined if the section containing the current Id has not yet loaded
 */
function getNextSectionId(sections: Record<string, AsyncOperation<GuruSection>>, sectionId: string): string | null | undefined {
  const section = sections[sectionId];

  if (!sections[sectionId] || section.status !== LoadingState.SUCCESS) {
    return undefined;
  }

  return section.value.navigation.nextUrl;
}

/**
 * Returns an array of all section ids (one per page) that we've started to load
 */
function getAvailableSectionIds(sections: Record<string, AsyncOperation<GuruSection>>, sectionId0: string): string[] {
  let currentSectionId = sectionId0;
  let nextId = getNextSectionId(sections, currentSectionId);

  const ids: string[] = [sectionId0];

  while (nextId !== null && nextId !== undefined && sections[nextId]) {
    ids.push(nextId);
    currentSectionId = nextId;
    nextId = getNextSectionId(sections, currentSectionId);
  }

  return ids;
}

interface iProps {
  children: React.ReactNode;
  sectionId0: string;
  lookAheadPages?: number;
}

export const DirectoryPageSectionProvider = ({ sectionId0, lookAheadPages = 0, children }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const sections = useSelector(GuruSelectors.getSections);
  const [pageSize, setPageSize] = useState<number>(1);
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
  const [maxLoadingPlaceholders, setMaxLoadingPlaceholders] = useState<number | null>(null);

  /** List of all available sections to display. Does not include the unloaded next page. */
  const availableSectionIds = useMemo(() => {
    return getAvailableSectionIds(sections, sectionId0);
  }, [sections, sectionId0]);

  /** The id of the next section to load. Will be null/undefined if we don't know it or it doesn't exist */
  const nextSectionId = useMemo(() => {
    return getNextSectionId(sections, availableSectionIds[availableSectionIds.length - 1]);
  }, [sections, availableSectionIds]);

  const hasNext = useMemo(() => typeof nextSectionId === 'string', [nextSectionId]);

  /** Loads the next section */
  const loadNext = useCallback(() => {
    console.log('LoadNext', nextSectionId);
    if (nextSectionId) {
      dispatch(GuruActionCreators.loadSection([nextSectionId, false]));
    }
  }, [nextSectionId]);

  /**
   * List of all the items that have definitely loaded.
   * As all sections before the last have to be SUCCESS for us to know about that section, this
   *  should always return the content of all the sections before the final section. Will also
   *  include the final section if it is also SUCCESS.
   */
  const loadedItems = useMemo(() => {
    const items: AsyncOperation<GuruPreviewItem>[] = [];
    for (let i = 0; i < availableSectionIds.length; i++) {
      const section = sections[availableSectionIds[i]];
      if (section && section.status === LoadingState.SUCCESS) {
        items.push(
          ...section.value.items.map<AsyncOperation<GuruPreviewItem>>((item) => ({
            status: LoadingState.SUCCESS,
            value: item,
          }))
        );
      }
    }
    return items;
  }, [availableSectionIds, sections]);

  /**
   * List of all the items, regardless of loading state.
   * Adds extra items for the final section if it is loading or errored.
   */
  const allItems = useMemo(() => {
    const items: AsyncOperation<GuruPreviewItem>[] = [...loadedItems];
    const finalSection = sections[availableSectionIds[availableSectionIds.length - 1]];
    if (!finalSection || finalSection.status === LoadingState.NONE || finalSection.status === LoadingState.LOADING) {
      for (let i = 0; i < Math.min(pageSize, maxLoadingPlaceholders ?? pageSize); i++) {
        items.push({ status: LoadingState.LOADING });
      }
    } else if (finalSection.status === LoadingState.ERROR) {
      items.push({ status: LoadingState.ERROR, error: finalSection.error });
    }
    // Don't add items from final section if status === SUCCESS, as it's already done in loadedItems.
    return items;
  }, [loadedItems, sections, availableSectionIds, pageSize]);

  /** Are we loading the first section */
  const loading = useMemo(() => {
    const section = sections[sectionId0];
    return !section || section.status === LoadingState.NONE || section.status === LoadingState.LOADING;
  }, [sectionId0, sections]);

  /** Load section 0 on creation if it hasn't started to be loaded yet */
  useEffect(() => {
    if (!sections[sectionId0]) {
      dispatch(GuruActionCreators.loadSection([sectionId0, false]));
    }
  }, []);

  /** Loads the next section of lookAhead is enabled, and the user is within lookAheadPages of the end of the list */
  useEffect(() => {
    if (!nextSectionId) {
      return;
    }
    if (currentItemIndex + pageSize > allItems.length - lookAheadPages * pageSize) {
      if (!sections[nextSectionId]) {
        dispatch(GuruActionCreators.loadSection([nextSectionId, false]));
      }
    }
  }, [currentItemIndex, nextSectionId, pageSize]);

  const value = useMemo<DirectoryPageSectionContextType>(
    () => ({
      allItems,
      loadedItems,
      loadNext,
      hasNext,
      setPageSize,
      setCurrentItemIndex,
      setMaxLoadingPlaceholders,
      loading,
    }),
    [allItems, loadedItems, loadNext, hasNext, setPageSize, setCurrentItemIndex, setMaxLoadingPlaceholders, loading]
  );

  return <DirectoryPageSectionContext.Provider value={value}>{children}</DirectoryPageSectionContext.Provider>;
};
