import deepmerge from 'deepmerge';

// @ts-expect-error: Import based on 'NETWORK_CONFIG' environment variable
import _networkConfig from './network-config.NETWORK_CONFIG.json'; // eslint-disable-line
import networkConfigDefaults from './network-config.defaults.json';

export type NetworkDataLocationConfig = {
  countries: string;
  countryRegions: string;
  pests: string;
  pestsDir: string;
  plants: string;
  plantsDir: string;
  gardenObjects: string;
  gardenObjectsDir: string;
  regionalPlantingDates: string;
  regionalPlantingDatesDir: string;
  catalogueData: string;
  supplierData: string;
  tutorials: string;
  objectives: string;
  tooltips: string;
  products: string;
};

export type NetworkConfig = {
  runtimeConfigURL: string;
  assetBaseURL: string;
  textureBaseURL: string;
  weatherIconsBaseURL: string;
  apiEndpoint: string;
  newApiEndpoint: string;
  intercomShortcuts: string;
  dataBaseURL: string;
  data: NetworkDataLocationConfig;
  plantingCalendarImageURL: string;
  pdfURL: string;
  journalEmbedURL: string;
  publishPlanUploadURL: string;
  thumbnailURL: string;
  helpURL: string;
  textureDefinitions: {
    au: string;
    gb: string;
    nl: string;
    us: string;
    za: string;
  };
  iconURLS: {
    au: string;
    gb: string;
    nl: string;
    us: string;
    za: string;
  };
  diagnosticPageLink: string;
  configs: string;
};

const overwriteMerge = (_, sourceArray) => sourceArray;

const networkConfig = deepmerge(networkConfigDefaults, _networkConfig, {
  arrayMerge: overwriteMerge,
}) as NetworkConfig;
export default networkConfig;
