import { getDistanceDivisionAtMagnification, imperialDistanceUnits, metricDistanceUnits } from '@gi/units';

/**
 * Returns the closest distance unit group based on metric/imperial measurements and magnification.
 * @param metric Use metric distance units
 * @returns A distance unit group
 */
export const getDistanceUnitGroup = (metric: boolean, magnification: number) => {
  const distanceUnits = metric ? metricDistanceUnits : imperialDistanceUnits;
  return getDistanceDivisionAtMagnification(distanceUnits, magnification);
};
