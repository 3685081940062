import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useHover, usePrevious } from '@gi/react-utils';
import CountdownTimer from '@gi/countdown-timer';

import { Notification, NotificationActionType, NotificationTypes } from '../notification';
import { startNotificationTimeout, stopNotificationTimeout, updateNotification } from '../notification-action-creators';

import './notification-display.scss';

const countdownColours: Record<NotificationTypes, string> = {
  [NotificationTypes.INFO]: '#00000022',
  [NotificationTypes.HELP]: '#00000022',
  [NotificationTypes.ERROR]: '#ffffff44',
  [NotificationTypes.SUCCESS]: '#ffffff44',
};

interface NotificationRightPaneProps {
  notification: Notification;
  isHovered: boolean;
  isMobile?: boolean;
}

const NotificationRightPane = ({ notification, isHovered, isMobile = false }: NotificationRightPaneProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const setNotVisible = () => {
    dispatch(
      updateNotification({
        ...notification,
        visible: false,
      })
    );
  };

  if (notification.inProgress && !notification.isTimingOut && !isHovered && !notification.canTimeout) {
    return (
      <div className='notification-display-close'>
        <div className='countdown-container'>
          <i className='icon-spinner animate-pulse' />
        </div>
      </div>
    );
  }

  if (!isMobile && !notification.isTimingOut && !isHovered && !notification.canTimeout) {
    return null;
  }

  if (notification.isTimingOut) {
    // Calculate the start time of the animation
    const animationStartAt = notification.timeoutTime ? notification.timeoutTime - notification.visibleDuration : Date.now();

    return (
      <div className='notification-display-close'>
        <div className='countdown-container'>
          <CountdownTimer
            radius={8}
            strokeWidth={4}
            strokeColor={countdownColours[notification.type] ?? '#ffffff44'}
            duration={notification.visibleDuration}
            animationStartAt={animationStartAt}
            animationCount={1}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='notification-display-close'>
      <button type='button' aria-label='Close' className='notification-display-close-button button button-borderless' onClick={() => setNotVisible()}>
        <i className='icon-cancel' />
      </button>
    </div>
  );
};

const notificationClasses = {
  [NotificationTypes.ERROR]: 'notification-error',
  [NotificationTypes.SUCCESS]: 'notification-success',
  [NotificationTypes.INFO]: 'notification-info',
  [NotificationTypes.HELP]: 'notification-help',
};

const notificationActionClasses = {
  [NotificationActionType.PRIMARY]: 'notification-action-primary',
  [NotificationActionType.SECONDARY]: 'notification-action-secondary',
  [NotificationActionType.HELP]: 'notification-action-help',
};

interface NotificationIconProps {
  icon?: string | null;
}

const NotificationIcon = ({ icon = null }: NotificationIconProps): JSX.Element | null => {
  if (icon === null) {
    return null;
  }

  return (
    <div className='notification-display-icon-container'>
      <i className={`notification-icon icon ${icon}`} />
    </div>
  );
};

interface NotificationDisplayProps {
  notification: Notification;
  isMobile?: boolean;
}

const NotificationDisplay = ({ notification, isMobile }: NotificationDisplayProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const [hoverRef, setHoverRef, isHovered] = useHover<HTMLDivElement>();
  const previous = usePrevious({ isHovered });

  /**
   * Effect for managing removal of a notification, not technically UI logic
   * but it's really easy for us to add it here
   */
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (notification.visibleDuration !== null && notification.visible && notification.canTimeout) {
      const remainingDuration = Math.max(0, (notification.timeoutTime ?? 0) - Date.now());

      timeout = setTimeout(() => {
        dispatch(
          updateNotification({
            ...notification,
            visible: false,
          })
        );
      }, remainingDuration);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [notification.timeoutTime, notification.visible, notification.canTimeout]);

  useEffect(() => {
    if (hoverRef && notification.type === NotificationTypes.HELP) {
      hoverRef.animate([{ outline: '2px solid rgba(63, 81, 181, 0.5)' }, { outline: '8px solid rgba(63, 81, 181, 0)' }], { duration: 500, iterations: 1 });
    }
  }, [notification.type, notification.timeoutTime]);

  /**
   * Effect for managing hover state
   */
  useEffect(() => {
    if (isHovered && !previous?.isHovered) {
      dispatch(stopNotificationTimeout(notification));
    } else if (!isHovered && previous?.isHovered && notification.canTimeout) {
      dispatch(startNotificationTimeout(notification, notification.visibleDuration));
    }
  }, [isHovered]);

  const actions = useMemo(() => {
    if (!notification.actions || notification.actions.length === 0) {
      return null;
    }
    return (
      <div className='notification-display-actions'>
        {notification.actions.map((action, i) => {
          const onClick = () => {
            if (action.action) {
              dispatch(action.action);
            }
            if (!action.noDismiss) {
              dispatch(updateNotification({ ...notification, visible: false }));
            }
          };
          return (
            <button
              type='button'
              className={`button button-inline ${notificationActionClasses[action.type ?? NotificationActionType.PRIMARY]}`}
              key={i}
              onClick={onClick}
            >
              {action.title}
            </button>
          );
        })}
      </div>
    );
  }, [notification.actions]);

  if (!notification.visible) {
    return null;
  }

  return (
    <div className={`notification-display ${notificationClasses[notification.type]} ${isMobile ? 'mobile' : ''}`} ref={setHoverRef}>
      <NotificationIcon icon={notification.icon} />
      <div className='notification-display-content'>
        <div className='notification-display-title'>{notification.title}</div>
        {actions}
      </div>
      <NotificationRightPane notification={notification} isHovered={isHovered} isMobile={isMobile} />
    </div>
  );
};

export default NotificationDisplay;
