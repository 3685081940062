import {
  Placement,
  UseFloatingReturn,
  UseInteractionsReturn,
  useFloating,
  flip,
  autoUpdate,
  useDismiss,
  useRole,
  useInteractions,
  useClick,
  useHover,
  useFocus,
  UseFloatingOptions,
} from '@floating-ui/react';
import React, { ReactNode, createContext, useCallback, useContext, useId, useMemo, useState } from 'react';

import { LocalMenuDropdownGroupContext } from './menu-dropdown-group';

type LocalMenuDropdownContextType = {
  open: boolean;
  setOpen: (open: boolean) => void;
  getReferenceProps: UseInteractionsReturn['getReferenceProps'];
  getDropdownProps: UseInteractionsReturn['getFloatingProps'];
  dropdownStyles: UseFloatingReturn['floatingStyles'];
  refs: UseFloatingReturn['refs'];
  context: UseFloatingReturn['context'];
};

export const LocalMenuDropdownContext = createContext<LocalMenuDropdownContextType>({} as LocalMenuDropdownContextType);

interface iProps {
  placement?: Placement;
  children?: ReactNode;
  disabled?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const MenuDropdown = ({ placement = 'bottom-start', children, disabled, open: externalIsOpen, onOpenChange: externalSetIsOpen }: iProps): JSX.Element => {
  const id = useId();
  const [internalIsOpen, setInternalIsOpen] = useState(false);

  const { isInGroup, openDropdownId, setOpenDropdownId } = useContext(LocalMenuDropdownGroupContext);

  const shouldBeOpen = isInGroup ? openDropdownId === id : (externalIsOpen ?? internalIsOpen);
  const open = disabled ? false : shouldBeOpen;
  const setOpen = useCallback<Required<UseFloatingOptions>['onOpenChange']>(
    (_open, event, reason) => {
      // Only use the 'hover' hook if another menu in the group is open and we're switching targets
      if (reason === 'hover' && (!_open || (_open && openDropdownId === null))) {
        return;
      }
      setOpenDropdownId(id, _open);
      (externalSetIsOpen ?? setInternalIsOpen)(_open);
    },
    [externalSetIsOpen, setOpenDropdownId, openDropdownId]
  );

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip() /* , shift() */],
  });

  const { context } = data;

  /** Make the dropdown menu appear whenever the button is clicked */
  const click = useClick(context, { stickIfOpen: false });

  /** Make the dropdown menu disappear if we stop hovering it while it's open */
  const hover = useHover(context);

  /** Keep the dropdown open if we have focus within it */
  const focus = useFocus(context);

  /** Make the dropdown menu disappear when the user presses ESC */
  const dismiss = useDismiss(context);

  /** ARIA properties */
  const role = useRole(context, { role: 'menu' });

  /** Merge all the interaction methods into 1 */
  const interactions = useInteractions([click, hover, focus, dismiss, role]);

  const value = useMemo<LocalMenuDropdownContextType>(
    () => ({
      open,
      setOpen,
      getReferenceProps: interactions.getReferenceProps,
      getDropdownProps: interactions.getFloatingProps,
      dropdownStyles: data.floatingStyles,
      context: data.context,
      refs: data.refs,
    }),
    [open, setOpen, interactions, data]
  );

  return <LocalMenuDropdownContext.Provider value={value}>{children}</LocalMenuDropdownContext.Provider>;
};

export default MenuDropdown;
