import { ColorSource, Container, Graphics } from 'pixi.js-new';

import {
  EngineEvent,
  GraphicNode,
  InspectableClassData,
  InspectableClassDataType,
  InspectableClassPropertyType,
  NodeEvent,
  PooledText,
  ReusableSpriteFactory,
  TextPoolComponent,
  TransformComponent,
  bindState,
  hasEngine,
} from '@gi/core-renderer';
import { State, StateDef } from '@gi/state';

export enum RulerMode {
  Top = 'Top',
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right',
}

export type RulerNodeState = StateDef<{
  visible: boolean;
  mode: RulerMode;
  length: number;
  thickness: number;
  startAt: number;
  endAt: number;
  divisions: number;
  subdivisions: number;
  subdivisionSize: number;
  primaryColour: ColorSource;
  secondaryColour: ColorSource;
  backgroundColour: ColorSource;
  textIncrement: number;
  textUnit: string;
}>;

const DEFAULT_STATE: RulerNodeState['state'] = {
  visible: true,
  mode: RulerMode.Top,
  length: 0,
  thickness: 20,
  startAt: 0,
  endAt: 0,
  divisions: 0,
  subdivisions: 10,
  subdivisionSize: 0.2,
  primaryColour: '#000000FF',
  secondaryColour: '#D3D3D3FF',
  backgroundColour: '#EEEEEE',
  textIncrement: 1,
  textUnit: 'm',
};

class RulerNode extends GraphicNode {
  type = 'RulerNode';

  readonly state: State<RulerNodeState>;

  readonly transform: TransformComponent;

  // #textComponent: TextComponent;
  // textNodes: TextContainer[] = [];
  // textNodeValues: Map<string, TextContainer> = new Map();

  #textPool: TextPoolComponent;
  #textNodes: Record<string, PooledText> = {};
  #usedTextStrings: string[] = [];

  #primarySpriteFactory: ReusableSpriteFactory;
  #secondarySpriteFactory: ReusableSpriteFactory;

  #backgroundLayer: Container = new Container();
  #secondaryLayer: Container = new Container();
  #primaryLayer: Container = new Container();

  #backgroundGraphic: Graphics = new Graphics();
  #secondaryEdgeGraphic: Graphics = new Graphics();
  #primaryEdgeGraphic: Graphics = new Graphics();

  constructor(state: Partial<RulerNodeState['state']>, name: string) {
    super();

    this.name = name;

    this.state = new State<RulerNodeState>({ ...DEFAULT_STATE, ...state });
    bindState(this.state, this);

    this.transform = this.components.add(new TransformComponent({ autoApply: false }));
    this.transform.state.addWatcher(this.#updateTransform, {
      properties: ['position', 'rotation', 'scale'],
    });

    this.#textPool = this.components.add(
      new TextPoolComponent({
        style: { fontFamily: ['Verdana'], fontSize: 12 }, // TODO Color
      })
    );
    this.#textNodes = {};

    this.#primarySpriteFactory = new ReusableSpriteFactory(this.#primaryLayer);
    this.#secondarySpriteFactory = new ReusableSpriteFactory(this.#secondaryLayer);

    this.#backgroundLayer.addChild(this.#backgroundGraphic);
    this.#secondaryLayer.addChild(this.#secondaryEdgeGraphic);
    this.#primaryLayer.addChild(this.#primaryEdgeGraphic);

    this.ownGraphics.interactiveChildren = false;
    this.ownGraphics.eventMode = 'none';
    this.ownGraphics.addChild(this.#backgroundLayer, this.#secondaryLayer, this.#primaryLayer);

    this.state.addWatcher(
      () => {
        this.#recolour();
        this.#createTextures();
      },
      { properties: ['backgroundColour', 'primaryColour', 'secondaryColour'] }
    );
    this.state.addWatcher(this.#update);

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
    this.eventBus.on(NodeEvent.Destroyed, this.#onDestroy);
  }

  #onBind = () => {
    hasEngine(this);
    this.#createTextures();
    this.#update();
    this.engine.eventBus.on(EngineEvent.RendererChanged, this.#createTextures);
    this.engine.eventBus.on(EngineEvent.RendererRestored, this.#createTextures);
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    this.engine.eventBus.off(EngineEvent.RendererChanged, this.#createTextures);
    this.engine.eventBus.off(EngineEvent.RendererRestored, this.#createTextures);
    this.#primarySpriteFactory.destroyAll();
    this.#secondarySpriteFactory.destroyAll();
    this.#textPool.destroyAllText();
    this.#textNodes = {};
    this.#usedTextStrings = [];
  };

  #onDestroy = () => {
    this.#backgroundLayer.destroy();
    this.#secondaryLayer.destroy();
    this.#primaryLayer.destroy();
    this.#backgroundGraphic.destroy();
    this.#secondaryEdgeGraphic.destroy();
    this.#primaryEdgeGraphic.destroy();
  };

  /**
   * Sets the parents for the different graphics objects making up this ruler.
   * @param backgroundParent The parent for the background layer
   * @param secondaryParent The parent for the secondary graphics layer
   * @param primaryParent The parent for the primary graphics layer
   */
  setLayerParents(backgroundParent: Container, secondaryParent: Container, primaryParent: Container) {
    this.#backgroundLayer.setParent(backgroundParent);
    this.#secondaryLayer.setParent(secondaryParent);
    this.#primaryLayer.setParent(primaryParent);
  }

  /**
   * Resets the parents of the graphicsf layers of this ruler, returning to their own parent.
   */
  clearLayerParents() {
    this.#backgroundLayer.setParent(this.ownGraphics);
    this.#secondaryLayer.setParent(this.ownGraphics);
    this.#primaryLayer.setParent(this.ownGraphics);
  }

  #recolour = () => {
    const { backgroundColour, primaryColour, secondaryColour } = this.state.currentValues;
    this.#backgroundGraphic.clear().beginFill(backgroundColour).drawRect(0, 0, 1, 1);
    this.#secondaryEdgeGraphic.clear().beginFill(secondaryColour).drawRect(0, 0, 1, 1);
    this.#primaryEdgeGraphic.clear().beginFill(primaryColour).drawRect(0, 0, 1, 1);
  };

  #createTextures = () => {
    if (!this.engine) {
      return;
    }

    const { thickness, primaryColour, secondaryColour } = this.state.currentValues;

    // We could just use 1 white texture and use the tint property, but that's ging to be less performant on canvas API
    const tick = new Graphics().beginFill(primaryColour).drawRect(0, 0, 1, thickness);
    this.engine.renderer.render(tick, { renderTexture: this.#primarySpriteFactory.renderTexture });

    tick.clear().beginFill(secondaryColour).drawRect(0, 0, 1, thickness);
    this.engine.renderer.render(tick, {
      renderTexture: this.#secondarySpriteFactory.renderTexture,
    });

    tick.destroy();
  };

  #getOrCreatePrimaryDivision(isSubdivision: boolean = false) {
    const { thickness, subdivisionSize } = this.state.currentValues;

    const sprite = this.#primarySpriteFactory.getSprite();
    sprite.scale = { x: 1, y: isSubdivision ? thickness * subdivisionSize : thickness };
    return sprite;
  }

  #getOrCreateSecondaryDivision(isSubdivision: boolean = false) {
    const { thickness, subdivisionSize } = this.state.currentValues;

    const sprite = this.#secondarySpriteFactory.getSprite();
    sprite.scale = { x: 1, y: isSubdivision ? thickness * subdivisionSize : thickness };
    return sprite;
  }

  #getOrCreateDivision(isSecondary: boolean, isSubdivision: boolean = false) {
    if (isSecondary) {
      return this.#getOrCreateSecondaryDivision(isSubdivision);
    }
    return this.#getOrCreatePrimaryDivision(isSubdivision);
  }

  #getTextNode(value: string): PooledText {
    if (!this.#textNodes[value]) {
      this.#textNodes[value] = this.#textPool.getPixiText(value);
      this.#primaryLayer.addChild(this.#textNodes[value].textComponent);
    }

    return this.#textNodes[value];
  }

  #freeTextNode(value: string) {
    if (!this.#textNodes[value]) {
      return;
    }

    this.#primaryLayer.removeChild(this.#textNodes[value].textComponent);
    this.#textPool.setTextFree(this.#textNodes[value].uuid);
    delete this.#textNodes[value];
  }

  /**
   * Redraws this ruler
   */
  #update = () => {
    if (!this.bound) {
      return;
    }

    const { mode, visible, subdivisionSize, length, thickness, startAt, endAt, divisions, subdivisions, textIncrement, textUnit } = this.state.currentValues;

    const flip = mode === RulerMode.Bottom || mode === RulerMode.Left;
    const isHorizontal = mode === RulerMode.Top || mode === RulerMode.Bottom;

    if (!visible) {
      this.#primaryLayer.visible = false;
      this.#secondaryLayer.visible = false;
      this.#backgroundLayer.visible = false;
      return;
    }
    this.#primaryLayer.visible = true;
    this.#secondaryLayer.visible = true;
    this.#backgroundLayer.visible = true;

    this.#primarySpriteFactory.beginReusing();
    this.#secondarySpriteFactory.beginReusing();

    // Re-draw the background
    this.#backgroundGraphic.scale = { x: length, y: thickness };
    this.#secondaryEdgeGraphic.position = { x: 0, y: flip ? 0 : thickness - 1 };
    this.#secondaryEdgeGraphic.scale = { x: length, y: 1 };
    this.#primaryEdgeGraphic.position = { x: startAt, y: flip ? 0 : thickness - 1 };
    this.#primaryEdgeGraphic.scale = { x: endAt - startAt, y: 1 };

    const originalLength = endAt - startAt;

    // The gap between each big division and small division
    const divisionGap = originalLength / divisions;
    const subdivisionGap = divisionGap / subdivisions;

    // The amount the of extra width needed to fit a whole number of divisions in
    const overflow = startAt < 0 ? -startAt % divisionGap : divisionGap - (startAt % divisionGap);

    // The amount of main (dark) divisions needed. Add 1 to include the final one for whole numbers.
    const neededMainDivisions = Math.floor(divisions + 1.000001);
    // The total amount of divisions (main and off-the-side) needed to cover the ruler
    let neededDivisions = ((length + overflow) / originalLength) * divisions;
    if (flip) {
      neededDivisions++;
    }
    // The amount of subdivisions in the final main division that should be dark
    const neededFinalSubdivisions = (divisions % 1) * subdivisions;

    const startIndex = Math.floor(-(startAt / divisionGap));

    const usedTextStrings: string[] = [];

    // const newTextNodes: TextContainer[] = [];
    // const newTextNodeValues: Map<string, TextContainer> = new Map();

    // Loop through all the main divisions
    for (let i = startIndex; i < neededDivisions + startIndex; i++) {
      const x = startAt + divisionGap * i;

      // Is this a main (dark) divsion
      const isMain = i >= 0 && i < neededMainDivisions;

      const tick = this.#getOrCreateDivision(!isMain, false);
      tick.position = { x: Math.floor(x), y: 0 };

      // if (i === neededMainDivisions - 1) {
      //   const endTick = this.getOrCreateDivision(false, false);
      //   endTick.position = { x: Math.floor(endAt), y: 0 };
      // }

      // Add all the subdivisions for this division
      for (let j = 1; j < subdivisions; j++) {
        const subX = Math.floor(x + subdivisionGap * j);
        const isSecondary = !isMain || (i >= neededMainDivisions - 1 && j > neededFinalSubdivisions);
        const subTick = this.#getOrCreateDivision(isSecondary, true);
        subTick.position = {
          x: subX,
          y: flip ? 0 : thickness * (1 - subdivisionSize),
        };
      }

      // Add text
      if (isMain) {
        const text = `${i * textIncrement}${textUnit}`;
        const { textComponent } = this.#getTextNode(text);
        if (isHorizontal) {
          textComponent.x = Math.round(x + 5);
          textComponent.y = flip ? thickness : 0;
          textComponent.anchor.set(0, flip ? 1 : 0);
        } else {
          textComponent.x = Math.round(x - 5);
          textComponent.y = flip ? thickness : 0;
          textComponent.anchor.set(0, flip ? 0 : 1);
        }
        textComponent.rotation = isHorizontal ? 0 : Math.PI;

        usedTextStrings.push(text);
        // newTextNodeValues.set(text, textComponent);
      }
    }

    for (let i = 0; i < this.#usedTextStrings.length; i++) {
      if (usedTextStrings.indexOf(this.#usedTextStrings[i]) === -1) {
        this.#freeTextNode(this.#usedTextStrings[i]);
      }
    }

    this.#usedTextStrings = usedTextStrings;

    this.#primarySpriteFactory.destroyUnused();
    this.#secondarySpriteFactory.destroyUnused();
  };

  #updateTransform = () => {
    const { position, rotation, scale } = this.transform.state.values;
    const scaleVector = typeof scale === 'number' ? { x: scale, y: scale } : scale;
    this.#backgroundLayer.position.set(position.x, position.y);
    this.#backgroundLayer.rotation = rotation;
    this.#backgroundLayer.scale.set(scaleVector.x, scaleVector.y);
    this.#secondaryLayer.position.set(position.x, position.y);
    this.#secondaryLayer.rotation = rotation;
    this.#secondaryLayer.scale.set(scaleVector.x, scaleVector.y);
    this.#primaryLayer.position.set(position.x, position.y);
    this.#primaryLayer.rotation = rotation;
    this.#primaryLayer.scale.set(scaleVector.x, scaleVector.y);
  };

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default RulerNode;
