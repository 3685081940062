import React from 'react';
import PropTypes from 'prop-types';
import PlannerIcon from '@gi/planner-icon';

import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalPaneContainer,
  ModalPane,
  ModalPaneSection,
  ModalPaneSectionContent,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalHeaderIcon,
} from '@gi/modal';

import './garden-object-information-modal.scss';

const GardenObjectInformationModal = ({ gardenObject, closeModal }) => {
  return (
    <Modal className='garden-object-info-modal' closeRequest={closeModal}>
      <ModalContent>
        <ModalCloseButton onClick={closeModal} />
        <ModalHeader>
          <ModalHeaderIcon>
            <PlannerIcon code={gardenObject.code} />
          </ModalHeaderIcon>
          <ModalHeaderContent>
            <ModalHeaderTitle>{gardenObject.name}</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane className='garden-object-info-pane'>
            <ModalPaneSection className='garden-object-image-section'>
              <div className='garden-object-image-container'>
                <img alt={gardenObject.name} src={gardenObject.imageURL} />
              </div>
            </ModalPaneSection>
            <ModalPaneSection>
              <ModalPaneSectionContent>
                <div
                  className='object-description'
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: gardenObject.descriptionHTML }}
                />
              </ModalPaneSectionContent>
            </ModalPaneSection>
          </ModalPane>
        </ModalPaneContainer>
      </ModalContent>
    </Modal>
  );
};

GardenObjectInformationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  gardenObject: PropTypes.object.isRequired,
};

export default GardenObjectInformationModal;
