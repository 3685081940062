import React, { useContext, useMemo } from 'react';
import { EditPlantNotesButton } from '@gi/edit-plant-notes-modal';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import LocalPlantListContext from '../../local-provider/local-plant-list-context';

import './notes-cell.scss';

interface iProps {
  plantNote: string;
  plantCode: string;
  varietyName: string;
}

const NotesCell = ({ plantNote, plantCode, varietyName }: iProps): JSX.Element | null => {
  const { columns } = useContext(GlobalPlantListContext);
  const { planID } = useContext(LocalPlantListContext);

  if (!columns.notes || planID === null) {
    return null;
  }

  const plantNoteParagraphs = useMemo(() => {
    return plantNote.split(/(?:\r\n|\r|\n)/g);
  }, [plantNote]);

  return (
    <td className='notes-td cell-with-label'>
      <div className='cell-label'>Plant Notes</div>
      <div className='cell-content'>
        <div className='plant-note-text'>
          <div className='plant-note-scroll-pane'>{plantNote.trim().length === 0 ? null : plantNoteParagraphs.map((p, i) => <p key={i}>{p}</p>)}</div>
        </div>
        <div className='edit-button-container'>
          <EditPlantNotesButton
            className='no-print'
            planID={planID}
            plantCode={plantCode}
            varietyName={varietyName}
            buttonText={plantNote.length === 0 ? 'Add' : 'Edit'}
          />
        </div>
      </div>
    </td>
  );
};

export default NotesCell;
