import React, { ReactNode, useContext, useMemo } from 'react';
import { FloatingArrow, FloatingPortal, useDelayGroup, useTransitionStyles } from '@floating-ui/react';

import { TooltipKey } from '../types';
import { LocalTooltipContext } from './tooltip';
import { TooltipContext } from '../tooltip-context';
import TooltipContentRenderer from './tooltip-content/tooltip-content-renderer';

import styles from './tooltip-content.module.css';

/** Time it takes for the tooltip to fade in */
const FADE_IN_TIME = 250;
const FADE_OUT_TIME = FADE_IN_TIME;

interface iProps {
  children?: ReactNode;
  className?: string;
  id?: TooltipKey; // | ({} & string); // <- Uncomment to allow generic strings as well
  parameters?: ReactNode[];
}

const TooltipContent = ({ children, className, id, parameters }: iProps): JSX.Element | null => {
  const { getTooltipContent } = useContext(TooltipContext);
  const { context, refs, getContentProps, contentStyles } = useContext(LocalTooltipContext);

  const { isInstantPhase, currentId } = useDelayGroup(context, { id: context.floatingId });
  const { isMounted, styles: cssStyles } = useTransitionStyles(context, {
    duration: isInstantPhase ? { open: 0, close: currentId === context.floatingId ? FADE_IN_TIME : 0 } : { open: FADE_IN_TIME, close: FADE_OUT_TIME },
    initial: {
      opacity: isInstantPhase ? 1 : 0,
    },
  });

  const content = useMemo(() => {
    if (!id) {
      return children;
    }
    const preDefinedContent = getTooltipContent(id);
    if (preDefinedContent === null) {
      return children;
    }
    return <TooltipContentRenderer content={preDefinedContent} parameters={parameters} />;
  }, [children, id, getTooltipContent, parameters]);

  if (!isMounted) return null;

  const classNames: string[] = [styles.tooltip];
  if (className) {
    classNames.push(className);
  }

  return (
    <FloatingPortal>
      <div
        ref={refs.setFloating}
        style={{
          ...contentStyles,
          ...cssStyles,
        }}
        {...getContentProps({ className: classNames.join(' ') })}
      >
        <FloatingArrow ref={refs.setArrow} context={context} />
        {content}
      </div>
    </FloatingPortal>
  );
};

export default TooltipContent;
