import React from 'react';
import { YoutubeButton } from '@gi/youtube-embed';
import { useDispatch } from 'react-redux';
import { GardenPlatformActionCreators } from '@gi/garden-platform-slice';
import { networkConfig } from '@gi/config';
import { IntercomActionCreators } from '@gi/intercom';
import { useViewLinkEventHook } from '@gi/garden-platform-events';

import introVideoThumb from './intro-video-thumb.jpg';

import styles from './intro-video.module.css';

const IntroVideo = (): JSX.Element => {
  const dispatch = useDispatch();

  const sendLinkViewedEvent = useViewLinkEventHook('dashboard-intro-video');

  const openIntercom = () => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('dashboard-introduction-video'));
  };

  return (
    <div>
      <YoutubeButton onClick={() => dispatch(GardenPlatformActionCreators.setShowIntroVideo(true))} thumbnailURL={introVideoThumb} />
      <div className={styles.helpDetails}>
        <div className={styles.helpDetailsTitle}>Need to know more?</div>
        <div>
          <a href={networkConfig.helpURL} target='_blank' rel='noreferrer' onClick={() => sendLinkViewedEvent('help-pages')}>
            Help & FAQ pages
          </a>
        </div>
        <div>
          Talk to our experts -{' '}
          <button type='button' onClick={openIntercom} className='button button-inline intercom-button'>
            Live Chat Support
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntroVideo;
