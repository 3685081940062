import React from 'react';

import styles from './guru-section.module.css';

interface iProps {
  backgroundStyle?: React.CSSProperties;
  backgroundCoverStyle?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
}

const GuruSection = ({ backgroundStyle, backgroundCoverStyle, children, className = '' }: iProps): JSX.Element => {
  return (
    <section style={backgroundStyle} className={`${styles.guruSection} ${className}`}>
      <div style={backgroundCoverStyle} className={styles.backgroundCover}>
        <div className={styles.container}>{children}</div>
      </div>
    </section>
  );
};

export default GuruSection;
