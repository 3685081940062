import React from 'react';

interface iProps {
  children: React.ReactNode;
}

const ModalPaneFooter = ({ children }: iProps) => {
  return <div className='modal-pane-footer'>{children}</div>;
};

export default ModalPaneFooter;
