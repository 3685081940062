import type { Supplier } from '../supplier';

/**
 * Parses a supplier from a suppliers json object and returns a Supplier
 *
 * @param {object} supplier
 * @returns {Supplier}
 */
function supplierFromAPI(supplier): Supplier {
  return {
    ID: supplier.supplierID,
    name: supplier.supplierName,
    countries: Object.freeze(supplier.countries),
    isDefaultSupplier: supplier.isDefaultSupplier,
  };
}

export default supplierFromAPI;
