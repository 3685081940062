import { RichTextDefinition } from '@gi/core-renderer';
import { Geometry, MathUtils } from '@gi/math';
import { DistanceUnits } from '@gi/units';

/**
 * Returns a string representing the rectangle and its size
 * @param dimensions The dimensions of the rectangle
 * @param distanceUnits The distance units for the plan
 * @returns A formatted string
 */
export const getRectTooltipText = (dimensions: Dimensions, distanceUnits: DistanceUnits): RichTextDefinition => {
  const units = distanceUnits.getUnitStrings([Math.abs(dimensions.width), Math.abs(dimensions.height)]);
  return [{ text: 'Rectangle', bold: true }, { text: `\n${units[0]} × ${units[1]}` }];
};

/**
 * Returns a string representing the ellipsis and its size
 * @param dimensions The dimensions of the rectangle
 * @param distanceUnits The distance units for the plan
 * @returns A formatted string
 */
export const getEllipseTooltipText = (dimensions: Dimensions, distanceUnits: DistanceUnits): RichTextDefinition => {
  const units = distanceUnits.getUnitStrings([Math.abs(dimensions.width), Math.abs(dimensions.height)]);
  return [{ text: 'Ellipse', bold: true }, { text: `\n${units[0]} × ${units[1]}` }];
};

/**
 * Returns a string representing the triangle and its dimensions
 * @param p1 The first point of the triangle
 * @param p2 THe seoncd point of the triangle
 * @param p3 The third point of the triangle
 * @param distanceUnits The distance units for the plan
 * @returns A formatted string
 */
export const getTriangleTooltipText = (p1: Vector2, p2: Vector2, p3: Vector2, distanceUnits: DistanceUnits): RichTextDefinition => {
  const d1 = Geometry.dist(p1, p2);
  const d2 = Geometry.dist(p2, p3);
  const d3 = Geometry.dist(p3, p1);
  const units = distanceUnits.getUnitStrings([d1, d2, d3]);
  return [{ text: 'Triangle', bold: true }, { text: `\n${units[0]} × ${units[1]} × ${units[2]}` }];
};

/**
 * Returns a string representing the line and its dimensions
 * @param p1 The start of the line
 * @param controlPoint The control point of the line
 * @param p2 The end of the line
 * @param distanceUnits The distance units for the plan
 * @returns A formatted string
 */
export const getLineTooltipText = (p1: Vector2, controlPoint: Vector2 | null, p2: Vector2, distanceUnits: DistanceUnits): RichTextDefinition => {
  let dist: number | null = null;
  let angle = '';

  if (controlPoint === null) {
    dist = Geometry.dist(p1, p2);
    angle = ` · ${((MathUtils.radToDeg(Geometry.angleBetweenPoints(p1, p2)) + 360) % 360).toFixed(0)}°`;
  } else {
    dist = Geometry.quadraticBezierLength(p1, controlPoint, p2);
  }
  return [{ text: 'Line', bold: true }, { text: `\n${distanceUnits.getUnitString(dist)}${angle}` }];
};
