import React, { useContext } from 'react';
import { PlanPlant } from '@gi/plan';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

interface iProps {
  planPlant: PlanPlant;
}

const PlantLabelCell = ({ planPlant }: iProps): JSX.Element | null => {
  const { columns } = useContext(GlobalPlantListContext);

  if (!columns.plantLabel) {
    return null;
  }

  return (
    <td className='plant-label-td cell-with-label'>
      <div className='cell-label'>Label</div>
      <div className='cell-content'>{planPlant.labelText}</div>
    </td>
  );
};

export default PlantLabelCell;
