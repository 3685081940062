import { Action, AnyAction } from 'redux';
import { batchActions } from 'redux-batched-actions';
import { ThunkAction } from 'redux-thunk';

import { PlannerControlsTab } from '@gi/constants';
import { NotificationTypes, NotificationActionCreators } from '@gi/notifications';

import LocalSettingsActionTypes from './local-settings-action-types';
import { LocalSettingsUpdate } from './local-settings-type';
import { DEFAULT_STATE } from './local-settings-reducer';
import {
  validateWheelMode,
  validatePlantDisplayMode,
  validatePlantSpriteCountLimit,
  validateBool,
  validateRenderMode,
  validateDevicePixelRatio,
  validateDeviceDisplayMode,
  validateCustomCursorScale,
  validatePlannerControlsTab,
  validateTextQuality,
} from './local-settings-validation';

export interface ISetLocalSettings extends Action<typeof LocalSettingsActionTypes.SET_LOCAL_SETTINGS> {
  settings: LocalSettingsUpdate;
}

export const setLocalSettings = (settings: LocalSettingsUpdate): ISetLocalSettings => {
  const validatedSettings: LocalSettingsUpdate = {};

  // Validate new settings
  if (settings.wheelMode !== undefined) {
    validatedSettings.wheelMode = validateWheelMode(settings.wheelMode);
  }

  if (settings.plantDisplayMode !== undefined) {
    validatedSettings.plantDisplayMode = validatePlantDisplayMode(settings.plantDisplayMode);
  }

  if (settings.plantSpriteCountLimit !== undefined) {
    validatedSettings.plantSpriteCountLimit = validatePlantSpriteCountLimit(settings.plantSpriteCountLimit);
  }

  if (settings.snapToGrid !== undefined) {
    validatedSettings.snapToGrid = validateBool(settings.snapToGrid);
  }

  if (settings.showEditToolbar !== undefined) {
    validatedSettings.showEditToolbar = validateBool(settings.showEditToolbar);
  }

  if (settings.showViewToolbar !== undefined) {
    validatedSettings.showViewToolbar = validateBool(settings.showViewToolbar);
  }

  if (settings.showLayerToolbar !== undefined) {
    validatedSettings.showLayerToolbar = validateBool(settings.showLayerToolbar);
  }

  if (settings.showMonthToolbar !== undefined) {
    validatedSettings.showMonthToolbar = validateBool(settings.showMonthToolbar);
  }

  if (settings.showCropRotationToolbar !== undefined) {
    validatedSettings.showCropRotationToolbar = validateBool(settings.showCropRotationToolbar);
  }

  if (settings.showHelpToolbar !== undefined) {
    validatedSettings.showHelpToolbar = validateBool(settings.showHelpToolbar);
  }

  if (settings.debugMode !== undefined) {
    validatedSettings.debugMode = validateBool(settings.debugMode);
  }

  if (settings.sfgMode !== undefined) {
    validatedSettings.sfgMode = validateBool(settings.sfgMode);
  }

  if (settings.showSFGHelpOnToggle !== undefined) {
    validatedSettings.showSFGHelpOnToggle = validateBool(settings.showSFGHelpOnToggle);
  }

  if (settings.enableSnapOnSFGMode !== undefined) {
    validatedSettings.enableSnapOnSFGMode = validateBool(settings.enableSnapOnSFGMode);
  }

  if (settings.touchMode !== undefined) {
    validatedSettings.touchMode = validateBool(settings.touchMode);
  }

  if (settings.showTouchIntroductionModalOnStart !== undefined) {
    validatedSettings.showTouchIntroductionModalOnStart = validateBool(settings.showTouchIntroductionModalOnStart);
  }

  if (settings.renderMode !== undefined) {
    validatedSettings.renderMode = validateRenderMode(settings.renderMode);
  }

  if (settings.customDevicePixelRatio !== undefined) {
    validatedSettings.customDevicePixelRatio = validateBool(settings.customDevicePixelRatio);
  }

  if (settings.devicePixelRatio !== undefined) {
    validatedSettings.devicePixelRatio = validateDevicePixelRatio(settings.devicePixelRatio);
  }

  if (settings.dontShowDrawingToolsDragWarning !== undefined) {
    validatedSettings.dontShowDrawingToolsDragWarning = validateBool(settings.dontShowDrawingToolsDragWarning);
  }

  if (settings.autoPan !== undefined) {
    validatedSettings.autoPan = validateBool(settings.autoPan);
  }

  if (settings.deviceDisplayMode !== undefined) {
    validatedSettings.deviceDisplayMode = validateDeviceDisplayMode(settings.deviceDisplayMode);
  }

  if (settings.hideIntercomIntroduction !== undefined) {
    validatedSettings.hideIntercomIntroduction = settings.hideIntercomIntroduction;
  }

  if (settings.hideTouchControlsTutorial !== undefined) {
    validatedSettings.hideTouchControlsTutorial = settings.hideTouchControlsTutorial;
  }

  if (settings.useCustomCursors !== undefined) {
    validatedSettings.useCustomCursors = validateBool(settings.useCustomCursors);
  }

  if (settings.customCursorScale !== undefined) {
    validatedSettings.customCursorScale = validateCustomCursorScale(settings.customCursorScale);
  }

  if (settings.hideTouchDragHelpNotifications !== undefined) {
    validatedSettings.hideTouchDragHelpNotifications = validateBool(settings.hideTouchDragHelpNotifications);
  }

  if (settings.touchDragRequiresSelection !== undefined) {
    validatedSettings.touchDragRequiresSelection = validateBool(settings.touchDragRequiresSelection);
  }

  if (settings.minimiseRightSidebar !== undefined) {
    validatedSettings.minimiseRightSidebar = validateBool(settings.minimiseRightSidebar);
  }

  if (settings.rightSidebarActiveTab !== undefined) {
    validatedSettings.rightSidebarActiveTab = validatePlannerControlsTab(settings.rightSidebarActiveTab);
  }

  if (settings.hideItemLockingHelpNotifications !== undefined) {
    validatedSettings.hideItemLockingHelpNotifications = validateBool(settings.hideItemLockingHelpNotifications);
  }

  if (settings.textQuality !== undefined) {
    validatedSettings.textQuality = validateTextQuality(settings.textQuality);
  }

  return {
    type: LocalSettingsActionTypes.SET_LOCAL_SETTINGS,
    settings: validatedSettings,
  };
};

export const saveLocalSettings =
  (settings: LocalSettingsUpdate): ThunkAction<void, any, unknown, AnyAction> =>
  (dispatch) => {
    dispatch(
      batchActions([
        setLocalSettings(settings),
        NotificationActionCreators.createDefaultNotification({
          title: 'Saved Device Settings',
          type: NotificationTypes.SUCCESS,
          icon: 'icon-floppy',
          canTimeout: true,
        }),
      ])
    );
  };

export const resetLocalSettings = (): ThunkAction<void, any, unknown, AnyAction> => (dispatch) => {
  dispatch(
    batchActions([
      setLocalSettings(DEFAULT_STATE),
      NotificationActionCreators.createDefaultNotification({
        title: 'Device Settings Reset',
        type: NotificationTypes.SUCCESS,
        icon: 'icon-floppy',
        canTimeout: true,
      }),
    ])
  );
};

export const setSFGMode = (sfgMode: boolean, snapToGrid: boolean = false) => {
  return setLocalSettings({ sfgMode, snapToGrid });
};

export const setSnapToGrid = (snapToGrid: boolean) => {
  return setLocalSettings({ snapToGrid });
};

export const setShowEditToolbar = (showEditToolbar: boolean) => {
  return setLocalSettings({ showEditToolbar });
};

export const setShowViewToolbar = (showViewToolbar: boolean) => {
  return setLocalSettings({ showViewToolbar });
};

export const setShowLayerToolbar = (showLayerToolbar: boolean) => {
  return setLocalSettings({ showLayerToolbar });
};

export const setShowMonthToolbar = (showMonthToolbar: boolean) => {
  return setLocalSettings({ showMonthToolbar });
};

export const setShowCropRotationToolbar = (showCropRotationToolbar: boolean) => {
  return setLocalSettings({ showCropRotationToolbar });
};

export const setTouchMode = (touchMode: boolean) => {
  return setLocalSettings({ touchMode });
};

export const showAllToolbars = () => {
  return setLocalSettings({
    showEditToolbar: true,
    showViewToolbar: true,
    showLayerToolbar: true,
    showMonthToolbar: true,
    showCropRotationToolbar: true,
  });
};

export const setShowTouchIntroductionModalOnStart = (showTouchIntroductionModalOnStart: boolean) => {
  return setLocalSettings({ showTouchIntroductionModalOnStart });
};

export const setDontShowDrawingToolsDragWarning = (dontShowDrawingToolsDragWarning: boolean) => {
  return setLocalSettings({ dontShowDrawingToolsDragWarning });
};

export const setShowHelpToolbar = (showHelpToolbar: boolean) => {
  return setLocalSettings({ showHelpToolbar });
};

export const setHideIntercomIntroduction = (hideIntercomIntroduction: boolean) => {
  return setLocalSettings({ hideIntercomIntroduction });
};

export const setHideTouchControlsTutorial = (hideTouchControlsTutorial: boolean) => {
  return setLocalSettings({ hideTouchControlsTutorial });
};

export const setHideTouchDragHelpNotifications = (hideTouchDragHelpNotifications: boolean) => {
  return setLocalSettings({ hideTouchDragHelpNotifications });
};

export const setTouchDragRequiresSelection = (touchDragRequiresSelection: boolean) => {
  return setLocalSettings({ touchDragRequiresSelection });
};

export const setMinimiseRightSidebar = (minimiseRightSidebar: boolean) => {
  return setLocalSettings({ minimiseRightSidebar });
};

export const setRightSidebarActiveTab = (rightSidebarActiveTab: PlannerControlsTab) => {
  return setLocalSettings({ rightSidebarActiveTab });
};

export const setHideItemLockingHelpNotifications = (hideItemLockingHelpNotifications: boolean) => {
  return setLocalSettings({ hideItemLockingHelpNotifications });
};

export const setTextQuality = (textQuality: number) => {
  return setLocalSettings({ textQuality });
};
