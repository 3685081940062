import React from 'react';

import { LoadingScreen } from '@gi/loading-screen';

import './login-page.scss';

const LoadingPage = () => {
  return (
    <LoadingScreen>
      <p>
        Loading... <i className='icon-spinner animate-pulse' />
      </p>
    </LoadingScreen>
  );
};

export default LoadingPage;
