import React, { useContext, useEffect, useMemo, useState } from 'react';

import { GuruPages } from '@gi/app-guru-types';
import { LoadingState } from '@gi/constants';
import { RequestError } from '@gi/gi-network';
import { AsyncResource } from '@gi/react-utils';
import { ResourceLoaderContext } from '@gi/resource-provider';

import PageContainer from '../pages/page-container';
import ContentPageLoading from '../components/content-page/content-page-loading';
import ContentPageError from '../components/content-page/content-page-error';

function getErrorMessage(resource: AsyncResource<GuruPages, Error | ClientError>): string {
  let msg = `An error occurred while trying to load the Garden Guru. Please check your internet connection and try again.`;
  if (resource.status === LoadingState.ERROR) {
    if (resource.error instanceof RequestError) {
      if (resource.error.statusCode === 503) {
        msg = `The Garden Guru is currently unavailable, likely for maintenance. Please try again later.`;
      }
    }
  }
  return msg;
}

const GardenGuruLoadErrorScreen = (): JSX.Element => {
  const { guruPages } = useContext(ResourceLoaderContext);

  const [errorMessage, setErrorMessage] = useState(getErrorMessage(guruPages));

  // Only update the error message when a new error comes through (prevent retry button changing the error message early)
  useEffect(() => {
    if (guruPages.status === LoadingState.ERROR) {
      setErrorMessage(getErrorMessage(guruPages));
    }
  }, [guruPages]);

  const isLoading = useMemo(() => guruPages.status === LoadingState.LOADING, [guruPages.status]);

  // Memoise the content, as setting the header causes a re-render in the parent, causing an infinite loop.
  return useMemo(() => {
    if (isLoading && !guruPages.hasAttempted) {
      return (
        <PageContainer>
          <ContentPageLoading />
        </PageContainer>
      );
    }

    return (
      <PageContainer>
        <ContentPageError retry={() => guruPages.load()} isLoading={isLoading} errorMessage={errorMessage} />
      </PageContainer>
    );
  }, [isLoading, guruPages, errorMessage]);
};

export default GardenGuruLoadErrorScreen;
