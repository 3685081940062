const AccountActionKeyGenerators = {
  saveUnitsSettings: (userID: number) => `SAVE_ACCOUNT_UNITS_SETTINGS_${userID}`,
  savePlanSettings: (userID: number) => `SAVE_ACCOUNT_PLAN_SETTINGS_${userID}`,
  savePassword: (userID: number) => `SAVE_ACCOUNT_PASSWORD_${userID}`,
  saveEmailSettings: (userID: number) => `SAVE_ACCOUNT_EMAIL_SETTINGS_${userID}`,
  saveLocationFrostDateSettings: (userID: number) => `SAVE_ACCOUNT_LOCATION_FROST_SETTINGS_${userID}`,
  disableAccount: (userID: number) => `DISABLE_ACCOUNT_${userID}`,
  enableAccount: (userID: number) => `ENABLE_ACCOUNT_${userID}`,
  verifyAccount: (userID: number) => `VERIFY_ACCOUNT_${userID}`,
  sendActivationEmail: (userID: number) => `SEND_ACTIVATION_EMAIL_${userID}`,
  lookupLocation: () => 'LOOKUP_ADDRESS',
};

export default AccountActionKeyGenerators;
