import React from 'react';

import styles from './small-preview-content.module.css';

interface iProps {
  children: React.ReactNode;
}

const SmallPreviewContent = ({ children }: iProps): JSX.Element => {
  return <div className={styles.smallPreviewContent}>{children}</div>;
};

export default SmallPreviewContent;
