import { Filter } from '@gi/filters';

import { PlantListVarietyGroup, SORT_ORDER } from '../../types/plant-list-types';
import { SortFunctions } from '../sort-functions';

type PlantNameFilterState = {
  sortOrder: SORT_ORDER;
};

const plantSortFilter: Filter<PlantListVarietyGroup, PlantNameFilterState> = {
  name: 'Variety Sort',
  inputs: {
    sortOrder: SORT_ORDER.PlantNameDesc,
  },
  createListFilter: ({ sortOrder }) => {
    return (plantListVarietyGroups) => {
      switch (sortOrder) {
        case SORT_ORDER.PlantNameDesc:
        case SORT_ORDER.PlantNameAsc:
        case SORT_ORDER.QuantityAsc:
        case SORT_ORDER.QuantityDesc:
        case SORT_ORDER.SowStartAsc:
        case SORT_ORDER.SowStartDesc:
        case SORT_ORDER.SowEndAsc:
        case SORT_ORDER.SowEndDesc:
        case SORT_ORDER.PlantStartAsc:
        case SORT_ORDER.PlantStartDesc:
        case SORT_ORDER.PlantEndAsc:
        case SORT_ORDER.PlantEndDesc:
        case SORT_ORDER.HarvestStartAsc:
        case SORT_ORDER.HarvestStartDesc:
        case SORT_ORDER.HarvestEndAsc:
        case SORT_ORDER.HarvestEndDesc:
          return SortFunctions[sortOrder](plantListVarietyGroups);
        default:
          // In-ground and plant label sort will fall back to this, as they're not applicable to this display mode
          return plantListVarietyGroups;
      }
    };
  },
};

export default plantSortFilter;
