import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { GardenPlatformEvent } from '@gi/garden-platform-events';

import { ObjectivesSelectors } from '../slice/objectives-slice';
import { Objective, ObjectiveCompletionData, ObjectiveCondition } from '../objective-types';

const useObjective = (id: string) => {
  const objectivesMap = useSelector(ObjectivesSelectors.getObjectives);
  const conditionsMap = useSelector(ObjectivesSelectors.getObjectiveConditions);
  const objectivesCompletionDataMap = useSelector(ObjectivesSelectors.getObjectiveCompletionData);

  /** Find the objective by ID */
  const objective = useMemo<Objective | null>(() => {
    return objectivesMap[id] ?? null;
  }, [objectivesMap, id]);

  /** Find the conditions for the objective from their condition IDs */
  const conditions = useMemo<ObjectiveCondition<GardenPlatformEvent>[]>(() => {
    if (!objective) {
      return [];
    }
    const foundConditions: ObjectiveCondition<GardenPlatformEvent>[] = [];
    objective.conditionIds.forEach((conditionId) => {
      const condition = conditionsMap[conditionId];
      if (condition) {
        foundConditions.push(condition);
      }
    });
    return foundConditions;
  }, [objective, conditionsMap]);

  /** Find if this objective has been completed */
  const completed = useMemo<false | ObjectiveCompletionData>(() => {
    if (!objective) {
      return false;
    }
    return objectivesCompletionDataMap[objective.id] ?? false;
  }, [objective, objectivesCompletionDataMap]);

  return useMemo(
    () => ({
      objective,
      conditions,
      completed,
    }),
    [objective, conditions, completed]
  );
};

export default useObjective;
