export const createLoadPlanRequestKey = (planID) => {
  return `LOAD_PLAN_${planID}`;
};

export const createSavePlanRequestKey = (planID) => {
  return `SAVE_PLAN_${planID}`;
};

export const createLoadUserRequestKey = (userID) => {
  return `LOAD_USER_${userID}`;
};

export const createSaveUserRequestKey = (userID) => {
  return `SAVE_USER_${userID}`;
};

export const createTransferPlanRequestKey = (planID) => {
  return `TRANSFER_PLAN_${planID}`;
};

export const createSupportEmailRequestKey = (formID) => {
  return `SUPPORT_EMAIL_${formID}`;
};
