import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { ResourceContext } from '@gi/resource-provider';

import { keepShoppingListUpdated } from './hooks';
import ShoppingListContent from './shopping-list-content/shopping-list-content';

const ShoppingListDisplay = (): JSX.Element | null => {
  const activePlan = useSelector(CanvasSelectors.getActivePlanID);
  const { store } = useContext(ResourceContext);

  keepShoppingListUpdated(activePlan);

  if (!activePlan || !store) {
    return null;
  }

  return <ShoppingListContent currency={store.details.currency} planId={activePlan} />;
};

export default ShoppingListDisplay;
