import { EventEmitter, ValidEventTypes } from 'eventemitter3';

export abstract class SimulatedItem<T extends ValidEventTypes> extends EventEmitter<T> {
  protected preventEmitUpdates: boolean = false;

  abstract emitUpdates(): void;

  startBatchUpdate() {
    this.preventEmitUpdates = true;
  }

  endBatchUpdate() {
    this.preventEmitUpdates = false;
    this.emitUpdates();
  }

  /**
   * Prevents emitting updates while the callback is run.
   * Will call emitUpdates after.
   * Can be used to bulk-update properties without emitting loads of events.
   * @param callback Callback used to set properties of this item.
   */
  doBatchUpdates(callback: () => void) {
    this.startBatchUpdate();
    try {
      callback();
    } finally {
      this.endBatchUpdate();
    }
  }
}
