import { DEFAULT_VARIETY } from '@gi/constants';
import { Catalogue } from './catalogue';
import { Suppliers } from './suppliers';
import { CatalogueVariety } from './catalogue-variety';

function lexographicSort(_a: string, _b: string): number {
  const a = _a === DEFAULT_VARIETY ? '-' : _a;
  const b = _b === DEFAULT_VARIETY ? '-' : _b;
  return a.localeCompare(b);
}

// Single Supplier
type SingleSuppliersByPlantCode = {
  plantCodes: string[];
  byPlantCode: Record<string, SingleSupplierVarieties>;
};

type SingleSupplierVarieties = {
  varietyNames: string[];
  byVarietyName: Record<string, CatalogueVariety>;
};

// All Suppliers
type GroupSuppliersByPlantCode = {
  plantCodes: string[];
  byPlantCode: Record<string, GroupSupplierVarieties>;
};

type GroupSupplierVarieties = {
  varietyNames: string[];
  byVarietyName: Record<string, CatalogueVariety[]>;
  varietyData: CatalogueVariety[];
};

type SuppliersBySupplierId = Record<number, SingleSuppliersByPlantCode>;

export type SupplierCatalogue = {
  allSuppliers: GroupSuppliersByPlantCode;
  bySupplier: SuppliersBySupplierId;
  supplierIDs: number[];
};

export class SupplierCatalogueUtils {
  static addCatalogueVariety(supplierCatalogue: SupplierCatalogue, catalogueVariety: CatalogueVariety): void {
    const { supplierID, plantCode, name } = catalogueVariety;

    if (!supplierCatalogue.allSuppliers.byPlantCode[plantCode]) {
      // New plant code
      supplierCatalogue.allSuppliers.byPlantCode[plantCode] = {
        byVarietyName: {},
        varietyData: [],
        varietyNames: [],
      };

      supplierCatalogue.allSuppliers.plantCodes.push(plantCode);
    }

    if (!supplierCatalogue.allSuppliers.byPlantCode[plantCode].byVarietyName[name]) {
      supplierCatalogue.allSuppliers.byPlantCode[plantCode].byVarietyName[name] = [];
      supplierCatalogue.allSuppliers.byPlantCode[plantCode].varietyNames.push(name);
    }

    supplierCatalogue.allSuppliers.byPlantCode[plantCode].varietyData.push(catalogueVariety);
    supplierCatalogue.allSuppliers.byPlantCode[plantCode].byVarietyName[name].push(catalogueVariety);

    if (!supplierCatalogue.bySupplier[supplierID]) {
      supplierCatalogue.bySupplier[supplierID] = {
        plantCodes: [],
        byPlantCode: {},
      };

      supplierCatalogue.supplierIDs.push(supplierID);
    }

    if (!supplierCatalogue.bySupplier[supplierID].byPlantCode[plantCode]) {
      supplierCatalogue.bySupplier[supplierID].byPlantCode[plantCode] = {
        byVarietyName: {},
        varietyNames: [],
      };
      supplierCatalogue.bySupplier[supplierID].plantCodes.push(plantCode);
    }

    if (!supplierCatalogue.bySupplier[supplierID].byPlantCode[plantCode].byVarietyName[name]) {
      supplierCatalogue.bySupplier[supplierID].byPlantCode[plantCode].byVarietyName[name] = catalogueVariety;
      supplierCatalogue.bySupplier[supplierID].byPlantCode[plantCode].varietyNames.push(name);
    } else {
      console.debug(`duplicate variety data found ${supplierID}, ${plantCode}, ${name}`);
    }
  }

  static sortVarieties(supplierCatalogue: SupplierCatalogue): void {
    for (let i = 0; i < supplierCatalogue.allSuppliers.plantCodes.length; i++) {
      supplierCatalogue.allSuppliers.byPlantCode[supplierCatalogue.allSuppliers.plantCodes[i]].varietyNames.sort(lexographicSort);
    }

    for (let j = 0; j < supplierCatalogue.supplierIDs.length; j++) {
      for (let i = 0; i < supplierCatalogue.bySupplier[supplierCatalogue.supplierIDs[j]].plantCodes.length; i++) {
        supplierCatalogue.bySupplier[supplierCatalogue.supplierIDs[j]].byPlantCode[
          supplierCatalogue.bySupplier[supplierCatalogue.supplierIDs[j]].plantCodes[i]
        ].varietyNames.sort(lexographicSort);
      }
    }
  }

  // static getAllSupplierData(supplierCatalogue: SupplierCatalogue, plantCode: string, varietyName: string): CatalogueVariety[] {
  //   if (supplierCatalogue.allSuppliers.byPlantCode[plantCode]) {
  //     if (supplierCatalogue.allSuppliers.byPlantCode[plantCode].byVarietyName[varietyName]) {
  //       return supplierCatalogue.allSuppliers.byPlantCode[plantCode].byVarietyName[varietyName];
  //     }
  //   }

  //   return [];
  // }

  static getAllVarietiesForPlant(supplierCatalogue: SupplierCatalogue, plantCode: string): string[] {
    if (supplierCatalogue.allSuppliers.byPlantCode[plantCode]) {
      return supplierCatalogue.allSuppliers.byPlantCode[plantCode].varietyNames;
    }

    return [];
  }

  /**
   * Returns true if the supplier has any varieties of the given plant in the current catalogue, else false
   *
   * @param {number} supplierID
   * @param {string} plantCode
   * @returns {boolean}
   * @memberof SupplierCatalogue
   */
  static supplierHasPlant(supplierCatalogue: SupplierCatalogue, supplierID: string, plantCode: string): boolean {
    if (!supplierCatalogue.bySupplier[supplierID]) {
      return false;
    }

    return !!supplierCatalogue.bySupplier[supplierID].byPlantCode[plantCode];
  }

  static hasCatalogueVariety(supplierCatalogue: SupplierCatalogue, supplierID: number, plantCode: string, variety: string): boolean {
    if (!supplierCatalogue.bySupplier[supplierID]) {
      return false;
    }

    if (!supplierCatalogue.bySupplier[supplierID].byPlantCode[plantCode]) {
      return false;
    }

    if (!supplierCatalogue.bySupplier[supplierID].byPlantCode[plantCode].byVarietyName[variety]) {
      return false;
    }

    return true;
  }

  /**
   * Returns the CatalogueVariety for the given supplier, plant and variety, returns
   * null if it's not present in this collection
   *
   * @param {number} supplierID
   * @param {string} plantCode
   * @param {string} variety
   * @returns {CatalogueVariety|null}
   * @memberof SupplierCatalogue
   */
  static getCatalogue(supplierCatalogue: SupplierCatalogue, supplierID: number, plantCode: string, variety: string): CatalogueVariety | null {
    if (!SupplierCatalogueUtils.hasCatalogueVariety(supplierCatalogue, supplierID, plantCode, variety)) {
      return null;
    }

    return supplierCatalogue.bySupplier[supplierID].byPlantCode[plantCode].byVarietyName[variety];
  }

  /**
   * Returns true if the given plant variety is present in this catalogue from any supplier
   *
   * @param {string} plantCode
   * @param {string} varietyName
   * @returns {boolean}
   * @memberof SupplierCatalogue
   */
  static hasInAnyCatalogue(supplierCatalogue: SupplierCatalogue, plantCode: string, varietyName: string): boolean {
    return !!(supplierCatalogue.allSuppliers.byPlantCode[plantCode] && supplierCatalogue.allSuppliers.byPlantCode[plantCode].byVarietyName[varietyName]);
  }

  /**
   * Returns a list of all catalogue entries for the given plant and variety
   *
   * @param {String} plantCode
   * @param {String} variety
   * @returns {CatalogueVariety[]}
   * @memberof SupplierCatalogue
   */
  static getAllCatalogues(supplierCatalogue: SupplierCatalogue, plantCode: string, variety: string): CatalogueVariety[] {
    const catalogues: CatalogueVariety[] = [];

    for (let i = 0; i < supplierCatalogue.supplierIDs.length; i++) {
      if (SupplierCatalogueUtils.hasCatalogueVariety(supplierCatalogue, supplierCatalogue.supplierIDs[i], plantCode, variety)) {
        catalogues.push(SupplierCatalogueUtils.getCatalogue(supplierCatalogue, supplierCatalogue.supplierIDs[i], plantCode, variety)!);
      }
    }

    return catalogues;
  }

  static init = (): SupplierCatalogue => {
    return {
      allSuppliers: {
        plantCodes: [],
        byPlantCode: {},
      },
      bySupplier: {},
      supplierIDs: [],
    };
  };

  static create = (catalogue: Catalogue, suppliers: Suppliers, countryCode: string): SupplierCatalogue => {
    console.debug('Creating supplier catalogue');
    const allowedSuppliers: number[] = [];

    for (let i = 0; i < suppliers.IDs.length; i++) {
      const supplier = suppliers.byID[suppliers.IDs[i]];
      if (supplier.countries.indexOf(countryCode) !== -1) {
        allowedSuppliers.push(supplier.ID);
      }
    }

    if (allowedSuppliers.length === 0) {
      console.warn('No variety suppliers found in catalogue for country code', countryCode);
    } else {
      console.debug(allowedSuppliers.length, 'suppliers found in catalogue for country code', countryCode);
    }

    const supplierCatalogue: SupplierCatalogue = SupplierCatalogueUtils.init();

    for (let i = 0; i < catalogue.varieties.length; i++) {
      const cv = catalogue.varieties[i];
      // Check this supplier is one of the allowed suppliers for this countryCode
      if (allowedSuppliers.indexOf(cv.supplierID) !== -1) {
        SupplierCatalogueUtils.addCatalogueVariety(supplierCatalogue, catalogue.varieties[i]);
      }
    }

    SupplierCatalogueUtils.sortVarieties(supplierCatalogue);

    return supplierCatalogue;
  };
}
