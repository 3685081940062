import React from 'react';

import './form.scss';

interface iProps {
  className?: string;
  children: React.ReactNode;
  required?: boolean;
  fakeLabel?: boolean;
}

const FormRowLabel = ({ className = '', children, required = false, fakeLabel = false }: iProps): JSX.Element => {
  if (required) {
    className += ' required';
  }

  if (fakeLabel) {
    return <div className={`form-row-label ${className}`}>{children}</div>;
  }

  return <label className={`form-row-label ${className}`}>{children}</label>;
};

export default FormRowLabel;
