import React, { ReactNode } from 'react';
import { GuruContentType, GuruPreviewVideo } from '@gi/app-guru-types';

import SmallPreview from '../small-preview';
import SmallPreviewTitle from '../small-preview-title';
import SmallPreviewContent from '../small-preview-content';
import SmallPreviewTag from '../small-preview-tag';

import styles from './small-preview-video.module.css';
import SmallPreviewLink from '../small-preview-link';

import LazyImage from '../../lazy-image/lazy-image';

interface iProps {
  previewVideo: GuruPreviewVideo;
  className?: string;
  children?: ReactNode;
}

const SmallPreviewVideo = ({ previewVideo, className = '', children }: iProps): JSX.Element => {
  return (
    <SmallPreview className={className}>
      <SmallPreviewLink type={GuruContentType.Video} slug={previewVideo.slug}>
        <SmallPreviewContent>
          <LazyImage className={styles.videoThumbnail} src={previewVideo.thumbnailUrl} alt='' />
          <SmallPreviewTag text='Video' color='#EB1A4D' />
        </SmallPreviewContent>
        <SmallPreviewTitle>{children !== undefined ? children : previewVideo.title}</SmallPreviewTitle>
      </SmallPreviewLink>
    </SmallPreview>
  );
};

export default SmallPreviewVideo;
