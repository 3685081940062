import { Filter } from '@gi/filters';
import Plant from '@gi/plant';

type CropFamilyFilterState = {
  familyID: null | number;
};

const cropFamilyFilter: Filter<Plant, CropFamilyFilterState> = {
  name: 'Crop Family',
  inputs: { familyID: null },
  createListFilter: ({ familyID }) => {
    return (plantList) => {
      if (familyID === null) {
        return plantList;
      }

      return plantList.filter((plant) => {
        return plant.familyID === familyID;
      });
    };
  },
};

export default cropFamilyFilter;
