import React from 'react';
import { GardenObjectShoppingEntry, PlantShoppingEntry, ProductVariant, ShoppingListProduct } from '@gi/products';

import QuantitySelector from './quantity/quantity-selector';
import VariantSelector from './variant/variant-selector';
import CartDetails from './cart-details/cart-details';
import ProductImages from './gallery/product-images';
import ProductSelectorContent from './products-selector/product-selector-content';

import styles from './components.module.css';
import ProductDescription from './product-description';

interface iProps {
  currency: string;
  product: ShoppingListProduct;
  variant: ProductVariant<unknown>;
  entry: PlantShoppingEntry | GardenObjectShoppingEntry;
  setEntry: (entry: PlantShoppingEntry | GardenObjectShoppingEntry) => void;
  productOptions: ShoppingListProduct[];
}

const ListItemProduct = ({ currency, product, variant, entry, setEntry, productOptions }: iProps): JSX.Element => {
  return (
    <div className={styles.listItemProductContainer}>
      <div className={styles.listItemProduct}>
        <ProductImages product={product} variant={variant} />
        <div className={styles.listItemProductDetails}>
          <ProductSelectorContent currency={currency} product={product} entry={entry} setEntry={setEntry} productOptions={productOptions} />
          <ProductDescription description={product.description} />
          <div className={styles.listItemProductConfig}>
            <VariantSelector
              attachToBody
              productId={entry.productId}
              selectedId={entry.variantId}
              selectedVariant={variant}
              setVariantId={(variantId: number) => {
                setEntry({
                  ...entry,
                  variantId,
                });
              }}
            />
            <div className={`${styles.collapseListItemDetailsColumn}`}>
              <QuantitySelector
                inputQuantity={entry.inputQuantity}
                quantity={entry.cartQuantity}
                variantQuantity={variant.quantity}
                recommendedQuantityMultiplier={variant.recommendedQuantityMultiplier}
                setQuantity={(quantity) => {
                  setEntry({
                    ...entry,
                    cartQuantity: quantity,
                  });
                }}
                unit={variant.unit}
              />
              <CartDetails
                currency={currency}
                inCart={entry.inCart}
                setInCart={(inCart) => {
                  setEntry({
                    ...entry,
                    inCart,
                  });
                }}
                quantity={entry.cartQuantity}
                price={variant.price}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItemProduct;
