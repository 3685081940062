import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SuggestedArticle from '../suggested-article';

export type SuggestedArticleSlice = {
  articles: SuggestedArticle[];
};

const DEFAULT_STATE: SuggestedArticleSlice = {
  articles: [],
};

export const suggestedArticlesSlice = createSlice({
  name: 'articles',
  initialState: DEFAULT_STATE,
  reducers: {
    setArticles: (state, action: PayloadAction<SuggestedArticle[]>) => {
      state.articles = action.payload;
    },
  },
});

export const suggestedArticlesReducer = suggestedArticlesSlice.reducer;
export const SuggestedArticlesActionCreators = suggestedArticlesSlice.actions;

interface RootState {
  suggestedArticles: SuggestedArticleSlice;
}

export const SuggestedArticlesSelectors = {
  getSuggestedArticles: (state: RootState) => state.suggestedArticles.articles,
};
