import { Container, Graphics } from 'pixi.js-new';

import {
  Bounds,
  GraphicNode,
  NodeEvent,
  TransformComponent,
  hasEngine,
  ReusableSpriteFactory,
  InspectableClassData,
  InspectableClassDataType,
  InspectableClassPropertyType,
  EngineEvent,
  bindState,
  CameraNodeState,
  createActiveCameraConnector,
} from '@gi/core-renderer';
import { MathUtils } from '@gi/math';
import { DistanceUnitsGroup } from '@gi/units';
import { State, StateDef } from '@gi/state';

import { getDistanceUnitGroup } from './utils';

type GridNodeState = StateDef<
  {
    visible: boolean;
    dimensions: Dimensions;
    primaryColour: string;
    secondaryColour: string;
    metric: boolean;
    /** Internal: The top-left corner of the grid, in screen-space coords */
    startPos: Vector2;
    /** Internal: The bottom-right corner of the grid, in screen-space coords */
    endPos: Vector2;
  },
  [],
  {
    camera: CameraNodeState;
  }
>;

const DEFAULT_STATE: GridNodeState['state'] = {
  visible: true,
  dimensions: { width: 0, height: 0 },
  primaryColour: '#00000026', // Alpha = 0.15
  secondaryColour: '#0000000D', // Alpha = 0.05
  metric: true,
  startPos: { x: 0, y: 0 },
  endPos: { x: 0, y: 0 },
};

class GridNode extends GraphicNode {
  type = 'GridNode';

  readonly state: State<GridNodeState>;

  container: Container;
  transform: TransformComponent;

  #primarySpriteFactory: ReusableSpriteFactory;
  #secondarySpriteFactory: ReusableSpriteFactory;

  #distanceUnitGroup: DistanceUnitsGroup = getDistanceUnitGroup(true, 1);

  #textureRendererId: string = '';

  constructor(initialState?: Partial<GridNodeState['state']>) {
    super();

    this.state = new State({ ...DEFAULT_STATE, ...initialState });
    bindState(this.state, this);

    // Update our local distance unit groups if our distance units or magnification change.
    this.state.addUpdater(
      (state) => {
        const magnification = this.state.get('camera', 'magnification', 1);
        this.#distanceUnitGroup = getDistanceUnitGroup(state.values.metric, magnification);
      },
      {
        properties: ['metric'],
        otherStates: { camera: { properties: ['magnification'] } },
      }
    );

    this.state.addUpdater(() => this.#updateCameraCalculations(), {
      otherStates: { camera: { properties: ['magnification', 'position', 'viewportSize'] } },
    });

    // Update to grid if anything in the state changes
    this.state.addWatcher(() => this.#update(), undefined, false);

    this.transform = this.components.add(new TransformComponent());

    this.container = new Container();
    this.ownGraphics.addChild(this.container);

    this.ownGraphics.interactiveChildren = false;
    this.ownGraphics.eventMode = 'none';

    this.#primarySpriteFactory = new ReusableSpriteFactory(this.container);
    this.#secondarySpriteFactory = new ReusableSpriteFactory(this.container);

    this.#distanceUnitGroup = getDistanceUnitGroup(this.state.values.metric, this.state.otherStates.camera?.values.magnification ?? 1);

    createActiveCameraConnector(this, this.state, 'camera');

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
    this.eventBus.on(NodeEvent.Destroyed, this.#onDestroy);
  }

  #onBind = () => {
    hasEngine(this);

    this.#createTextures();
    this.engine.eventBus.on(EngineEvent.RendererChanged, this.#createTextures);
    this.engine.eventBus.on(EngineEvent.RendererRestored, this.#createTextures);
    this.#update();
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    this.engine.eventBus.off(EngineEvent.RendererChanged, this.#createTextures);
    this.engine.eventBus.off(EngineEvent.RendererRestored, this.#createTextures);
    this.#primarySpriteFactory.destroyAll();
    this.#secondarySpriteFactory.destroyAll();
  };

  #onDestroy = () => {
    this.container.destroy();
  };

  #createTextures = () => {
    hasEngine(this);
    const { primaryColour, secondaryColour } = this.state.currentValues;

    // We could just use 1 white texture and use the tint property, but that's ging to be less performant on canvas API
    const tick = new Graphics().beginFill(primaryColour).drawRect(0, 0, 1, 1);
    this.engine.renderer.render(tick, { renderTexture: this.#primarySpriteFactory.renderTexture });

    tick.clear().beginFill(secondaryColour).drawRect(0, 0, 1, 1);
    this.engine.renderer.render(tick, {
      renderTexture: this.#secondarySpriteFactory.renderTexture,
    });

    tick.destroy();
  };

  #updateCameraCalculations() {
    const cameraState = this.state.otherStates.camera;
    const camera = cameraState?.owner;
    if (!cameraState || !camera) {
      return;
    }
    const { dimensions } = this.state.values;

    const startPos = camera.getScreenPos({ x: 0, y: 0 });
    const endPos = camera.getScreenPos({ x: dimensions.width, y: dimensions.height });

    this.state.values.startPos = startPos;
    this.state.values.endPos = endPos;

    // Account for camera position and magnification so we always appear as if we're attached to the camera
    this.transform.state.values.scale = 1 / cameraState.values.magnification;
    this.transform.state.values.position = camera.getWorldPos({ x: 0, y: 0 });
  }

  #update() {
    if (!this.bound) {
      return;
    }

    const { visible, dimensions, startPos, endPos } = this.state.values;

    const viewportSize = this.state.get('camera', 'viewportSize');

    if (!visible || !viewportSize) {
      this.ownGraphics.visible = false;
      return;
    }

    this.ownGraphics.visible = true;

    this.#primarySpriteFactory.beginReusing();
    this.#secondarySpriteFactory.beginReusing();

    const { width, height } = viewportSize;

    const xDivisions = dimensions.width / this.#distanceUnitGroup.spacing;
    const yDivisions = dimensions.height / this.#distanceUnitGroup.spacing;

    const xSubdivisions = this.#distanceUnitGroup.divisions;
    const ySubdivisions = this.#distanceUnitGroup.divisions;

    const bounds = {
      top: startPos.y,
      bottom: endPos.y,
      left: startPos.x,
      right: endPos.x,
    };

    const boundsWidth = bounds.right - bounds.left;
    const boundsHeight = bounds.bottom - bounds.top;

    // The bounds containing the grid, but constrained to the edges of the viewport
    const visibleBounds: Bounds = {
      top: MathUtils.clamp(bounds.top, 0, height),
      bottom: MathUtils.clamp(bounds.bottom, 0, height),
      left: MathUtils.clamp(bounds.left, 0, width),
      right: MathUtils.clamp(bounds.right, 0, width),
    };

    // The bounds width/height, but constrained to the edges of the viewport
    const visibleBoundsWidth = visibleBounds.right - visibleBounds.left;
    const visibleBoundsHeight = visibleBounds.bottom - visibleBounds.top;

    // The size of the gap between divisions and subdivisions
    const xDivisionGap = boundsWidth / xDivisions;
    const xSubdivisionGap = xDivisionGap / xSubdivisions;

    const yDivisionGap = boundsHeight / yDivisions;
    const ySubdivisionGap = yDivisionGap / ySubdivisions;

    // If the bounds are negative (off-screen), we want to start drawing as close to (0, 0) as possible.
    // This is the negative offset from (0, 0) to get the first lines to lign up with the grid.
    const xStartOffset = Math.min(bounds.left % xDivisionGap, 0);
    const yStartOffset = Math.min(bounds.top % yDivisionGap, 0);

    // How many large divisions we need. Account for the fact we don't need to draw what's off-screen.
    const xNeededDivisions = ((visibleBoundsWidth - xStartOffset) / boundsWidth) * xDivisions;
    const yNeededDivisions = ((visibleBoundsHeight - yStartOffset) / boundsHeight) * yDivisions;

    // Start drawing at the edge of the bounds, or as close to (0, 0) as possible if bounds start off-screen
    const xStart = Math.max(bounds.left, xStartOffset);
    const yStart = Math.max(bounds.top, yStartOffset);

    // Draw the vertical lines
    for (let i = 0; i < xNeededDivisions; i++) {
      const x = xStart + xDivisionGap * i;

      // this.graphics.beginFill(divisionColour);
      // this.graphics.drawRect(Math.floor(x), bounds.top, 1, boundsHeight);
      const primaryLine = this.#primarySpriteFactory.getSprite();
      primaryLine.position = { x: Math.floor(x), y: bounds.top };
      primaryLine.scale = { x: 1, y: boundsHeight };

      // this.graphics.beginFill(subdivisionColour);
      // Draw the subdivisions
      for (let j = 1; j < xSubdivisions; j++) {
        const subX = Math.floor(x + xSubdivisionGap * j);

        if (subX > bounds.right) {
          break;
        }

        // this.graphics.drawRect(subX, bounds.top, 1, boundsHeight);
        const secondaryLine = this.#secondarySpriteFactory.getSprite();
        secondaryLine.position = { x: subX, y: bounds.top };
        secondaryLine.scale = { x: 1, y: boundsHeight };
      }
    }

    // Draw the horizontal lines
    for (let i = 0; i < yNeededDivisions; i++) {
      const y = yStart + yDivisionGap * i;

      // this.graphics.beginFill(divisionColour);
      // this.graphics.drawRect(bounds.left, Math.floor(y), boundsWidth, 1);
      const primaryLine = this.#primarySpriteFactory.getSprite();
      primaryLine.position = { x: bounds.left, y: Math.floor(y) };
      primaryLine.scale = { x: boundsWidth, y: 1 };

      // this.graphics.beginFill(subdivisionColour);
      // Draw the subdivisions
      for (let j = 1; j < xSubdivisions; j++) {
        const subY = Math.floor(y + ySubdivisionGap * j);

        if (subY > bounds.bottom) {
          break;
        }

        // this.graphics.drawRect(bounds.left, subY, boundsWidth, 1);
        const secondaryLine = this.#secondarySpriteFactory.getSprite();
        secondaryLine.position = { x: bounds.left, y: subY };
        secondaryLine.scale = { x: boundsWidth, y: 1 };
      }
    }

    // Draw far-edges
    // this.graphics.beginFill(divisionColour);
    // this.graphics.drawRect(bounds.right, bounds.top, 1, boundsHeight);
    // this.graphics.drawRect(bounds.left, bounds.bottom, boundsWidth, 1);
    const rightEdge = this.#primarySpriteFactory.getSprite();
    rightEdge.position = { x: bounds.right, y: bounds.top };
    rightEdge.scale = { x: 1, y: boundsHeight };

    const topEdge = this.#primarySpriteFactory.getSprite();
    topEdge.position = { x: bounds.left, y: bounds.bottom };
    topEdge.scale = { x: boundsWidth, y: 1 };

    this.#primarySpriteFactory.destroyUnused();
    this.#secondarySpriteFactory.destroyUnused();
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default GridNode;
