import type { Request } from './request';
import { RequestStatus } from './request-status-types';

export type Requests = {
  requestsByKey: Record<string, Request>;
  requestKeys: string[];
};

interface RequestArgs {
  key: string;
  status?: RequestStatus;
  error?: Error | null;
  complete?: boolean;
}

export class RequestsUtils {
  static create(): Requests {
    return {
      requestsByKey: {},
      requestKeys: [],
    };
  }

  static hasRequest(requests: Requests, key: string) {
    if (key === undefined) {
      throw new Error('Key is not allowed to be undefined');
    }

    return Object.hasOwnProperty.call(requests.requestsByKey, key);
  }

  static getRequest(requests: Requests, key: string): Request | null {
    if (key === undefined) {
      throw new Error('Key is not allowed to be undefined');
    }

    if (!RequestsUtils.hasRequest(requests, key)) {
      return null;
    }

    return requests.requestsByKey[key];
  }

  static getStatus(requests: Requests, key: string): null | RequestStatus {
    if (key === undefined) {
      throw new Error('Key is not allowed to be undefined');
    }

    if (!RequestsUtils.hasRequest(requests, key)) {
      return null;
    }

    return RequestsUtils.getRequest(requests, key)!.status;
  }

  static getError(requests: Requests, key: string): null | Error {
    if (key === undefined) {
      throw new Error('Key is not allowed to be undefined');
    }

    if (!RequestsUtils.hasRequest(requests, key)) {
      return null;
    }

    return RequestsUtils.getRequest(requests, key)!.error;
  }

  static addRequest(requests: Requests, { key, status = RequestStatus.IN_PROGRESS, error = null, complete = false }: RequestArgs): Requests {
    if (key === undefined) {
      throw new Error('Key is not allowed to be undefined');
    }

    if (RequestsUtils.hasRequest(requests, key)) {
      return RequestsUtils.updateRequest(requests, { key, status, error, complete });
    }

    const requestsByKey = { ...requests.requestsByKey, [key]: { key, status, error, complete } };
    const requestKeys = [...requests.requestKeys, key];

    return {
      requestsByKey,
      requestKeys,
    };
  }

  static updateRequest(requests: Requests, { key, status = RequestStatus.IN_PROGRESS, error = null, complete = false }: RequestArgs): Requests {
    if (key === undefined) {
      throw new Error('Key is not allowed to be undefined');
    }

    if (!RequestsUtils.hasRequest(requests, key)) {
      console.warn('Attempted to update request which was not present', key);
      return RequestsUtils.addRequest(requests, { key, status, error, complete });
    }

    const requestsByKey = { ...requests.requestsByKey, [key]: { key, status, error, complete } };

    return {
      ...requests,
      requestsByKey,
    };
  }

  static removeRequest(requests: Requests, key: string): Requests {
    if (key === undefined) {
      throw new Error('Key is not allowed to be undefined');
    }

    if (!RequestsUtils.hasRequest(requests, key)) {
      console.debug('Requests asked to remove requests which was not present:', key);
      return requests;
    }

    const keyIndex = requests.requestKeys.indexOf(key);

    if (keyIndex === -1) {
      throw new Error('Key is present in request map but not in key list');
    }

    const requestsByKey = { ...requests.requestsByKey };
    delete requestsByKey[key];
    const requestKeys = [...requests.requestKeys];
    requestKeys.splice(keyIndex, 1);

    return {
      requestsByKey,
      requestKeys,
    };
  }

  static requestInProgress(requests: Requests, key: string): boolean {
    return RequestsUtils.getStatus(requests, key) === RequestStatus.IN_PROGRESS;
  }

  static requestCompleted(requests: Requests, key: string): boolean {
    return RequestsUtils.getStatus(requests, key) === RequestStatus.COMPLETE;
  }

  static requestFailed(requests: Requests, key: string): boolean {
    return RequestsUtils.getStatus(requests, key) === RequestStatus.FAIL;
  }
}

export default Requests;
