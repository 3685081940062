import React from 'react';
import Plant from '@gi/plant';
import PlannerIcon from '@gi/planner-icon';

import './companion-plant.scss';

interface iProps {
  plant: Plant;
  isMutual?: boolean;
}

const CompanionPlant = ({ plant, isMutual = false }: iProps): JSX.Element => {
  return (
    <span className={`companion-plant ${isMutual ? 'mutual-companion' : ''}`}>
      <PlannerIcon code={plant.code} halfSize className='plant-icon' />
      <span className='companion-text'>{plant.name}</span>
    </span>
  );
};

export default CompanionPlant;
