import React, { ChangeEvent, ReactNode, useCallback } from 'react';

import styles from './styles.module.css';

interface iProps {
  children?: ReactNode;
  className?: string;
  onFileSelected: (file: File) => void;
  accept?: string;
}

const FileButton = ({ children, className, onFileSelected, accept }: iProps): JSX.Element => {
  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length > 0) {
        onFileSelected(event.target.files[0]);
      }
    },
    [onFileSelected]
  );

  const classNames: string[] = [styles.fileButton, 'button'];
  if (className) {
    classNames.push(className);
  }

  return (
    <label className={classNames.join(' ')}>
      {children}
      <input type='file' onChange={handleFileChange} accept={accept} />
    </label>
  );
};

export default FileButton;
