import React, { CSSProperties, ReactNode } from 'react';

import './button-strip-group.scss';

interface iProps {
  children: ReactNode;
  className?: string;
  gap?: number;
  shouldCenter?: boolean;
}

const ButtonStripGroup = ({ children, className, gap = 0, shouldCenter = false }: iProps): JSX.Element => {
  const classNames = ['button-strip-group'];
  if (shouldCenter) {
    classNames.push('center');
  }
  if (className) {
    classNames.push(className);
  }

  const styles = {} as CSSProperties;
  if (gap !== undefined) {
    styles['--sub-gap'] = `${gap}px`;
  }

  return (
    <div className={classNames.join(' ')} style={styles}>
      {children}
    </div>
  );
};

export default ButtonStripGroup;
