import React from 'react';

import PlannerIcon from '@gi/planner-icon';

import './garden-object-icon.scss';

interface iProps {
  code: string;
}

const GardenObjectIcon = ({ code }: iProps): JSX.Element => {
  return (
    <span className='garden-object-icon-mobile'>
      <PlannerIcon code={code} />
    </span>
  );
};

export default GardenObjectIcon;
