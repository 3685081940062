import { GardenItemType } from '@gi/constants';
import { HiddenFlag, InspectableClassData, NodeEvent, PointerData, InteractableComponentCallbacks } from '@gi/core-renderer';
import { Geometry } from '@gi/math';

import DrawTool, { DrawToolState, MIN_DRAW_DISTANCE } from './draw-tool';
import RectangleHandleSetNode from '../handles/rectangle-handle-set-node';
import CanvasInteractionInterface from '../../../canvas-interface/canvas-interaction-interface';
import { SimulatedText } from '../../../simulation/simulated-text';
import GardenItemSelectionMiddleware from '../../components/garden-item-selection-middleware';
import TextNode from '../text/text-node';
import CanvasLayers from '../../canvas-layers';
import { DRAWING_ITEM_ID } from '../../constants';
import { TextSnapUtils } from '../../../simulation/snap-utils';

const DEFAULT_SIZE: Dimensions = { width: 100, height: 100 };

export interface DrawTextToolState extends DrawToolState {
  itemType: GardenItemType.Text;
  color: number;
}

class DrawTextTool extends DrawTool<DrawTextToolState> {
  type = 'DrawTextTool';

  readonly fontSize: number;
  readonly colour: number;

  private simulatedText: SimulatedText | null = null;
  private handles: RectangleHandleSetNode | null = null;
  private previewNode: TextNode | null = null;

  constructor(fontSize: number, color: number, interactionInterface: CanvasInteractionInterface, canvasLayers: CanvasLayers, dragToDrawEvent?: PointerEvent) {
    super(
      interactionInterface,
      canvasLayers,
      {
        itemType: GardenItemType.Text,
        color,
      },
      dragToDrawEvent
    );

    this.ignoreClick = true;

    this.fontSize = fontSize;
    this.colour = color;

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    if (!this.isDragToDraw) {
      return;
    }

    this.previewNode = new TextNode(DRAWING_ITEM_ID, {
      start: { x: -DEFAULT_SIZE.width / 2, y: -DEFAULT_SIZE.height / 2 },
      end: { x: DEFAULT_SIZE.width / 2, y: DEFAULT_SIZE.height / 2 },
      colour: this.colour,
      fontSize: this.fontSize,
      rotation: 0,
      text: '',
      locked: false,
      zIndex: 0,
    });

    this.canvasLayers.drawingPreviewLayer.addChildren(this.previewNode);
    this.previewNode.visibility.addHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
  };

  #onBeforeUnbind = () => {
    if (this.previewNode) {
      this.previewNode.destroy();
    }
  };

  onDragStart: InteractableComponentCallbacks['onDragStart'] = (data, interaction, controls) => {
    // We only use the preview for drag-to-draw, which can't repeat, so destroy it here.
    if (this.previewNode) {
      this.previewNode.destroy();
      this.previewNode = null;
    }

    this.simulatedText = this.interactionInterface.startDrawingText(data.worldPosition, this.fontSize, this.colour);

    const handles = this.tryGetContext(GardenItemSelectionMiddleware)?.forceUpdateHandles();
    if (handles && handles instanceof RectangleHandleSetNode) {
      const [target] = handles.targets;
      if (target && target instanceof TextNode && target.id === this.simulatedText.id) {
        this.handles = handles;
        this.handles.BR.callOnDragStart(data, interaction, controls);
      } else {
        console.error('Text handles are for the wrong item');
      }
    } else {
      console.error('Failed to find text handles after drawing text');
    }
  };

  onDragMove: InteractableComponentCallbacks['onDragMove'] = (data, interaction, controls) => {
    if (!this.simulatedText) {
      return;
    }

    if (this.handles) {
      this.handles.BR.callOnDragMove(data, interaction, controls);
    } else {
      console.error('Failed to manipulate drawn text: Handles missing');
    }
  };

  onDragEnd: InteractableComponentCallbacks['onDragEnd'] = (data, interaction, controls) => {
    if (!this.simulatedText) {
      return;
    }

    if (this.handles) {
      this.handles.BR.callOnDragEnd(data, interaction, controls);
    } else {
      console.error('Failed to manipulate drawn text: Handles missing');
    }

    this.makeMinimumSize(this.simulatedText);

    this.handles = null;
    this.simulatedText = null;

    this.interactionInterface.onDrawText(this.isDragToDraw ?? false);
    this.interactionInterface.pushUpdates();
  };

  // eslint-disable-next-line class-methods-use-this
  makeMinimumSize = (shape: SimulatedText) => {
    if (Geometry.dist(shape.start, shape.end) < MIN_DRAW_DISTANCE) {
      shape.setPosition(shape.center, DEFAULT_SIZE, 0);
    }
  };

  onPointerMove = (data: Pick<PointerData, 'worldPosition' | 'screenPosition'>) => {
    if (!this.previewNode) {
      return;
    }

    const start = Geometry.addPoint(data.worldPosition, {
      x: -DEFAULT_SIZE.width / 2,
      y: -DEFAULT_SIZE.height / 2,
    });
    const end = Geometry.addPoint(data.worldPosition, {
      x: DEFAULT_SIZE.width / 2,
      y: DEFAULT_SIZE.height / 2,
    });

    if (this.snapToGrid) {
      const { start: snappedStart, end: snappedEnd } = TextSnapUtils.snapTranslate(start, end, this.snapToGridDistance);
      this.previewNode.state.values.start = snappedStart;
      this.previewNode.state.values.end = snappedEnd;
    } else {
      this.previewNode.state.values.start = start;
      this.previewNode.state.values.end = end;
    }

    if (this.previewNode.visibility.shouldHide && this.isDragToDraw) {
      this.previewNode.visibility.removeHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    }
  };

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default DrawTextTool;
