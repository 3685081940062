import React from 'react';

import './crop-family-dropdown-option.scss';

interface iProps {
  label: null | string;
  scientificName: string;
  otherName: string;
  color: string;
  value: null | number;
}

const CropFamilyDropdownOption = (
  { label = null, scientificName = '', otherName = '', color = '', value = null }: iProps,
  { context, selectValue }
): JSX.Element => {
  if (label !== null) {
    return (
      <div className='crop-family-dropdown-option'>
        {context === 'menu' ? <div className='crop-family-indicator-container' /> : null}
        <div className='name'>{label}</div>
      </div>
    );
  }

  const selected = selectValue.length > 0 && selectValue[0].value === value;

  return (
    <div className={`crop-family-dropdown-option ${selected ? 'is-selected' : ''}`}>
      <div className='crop-family-indicator-container'>
        <span className='crop-family-indicator' style={{ backgroundColor: `#${color}` }} />
      </div>
      <div className='crop-family-labels'>
        <div className='family-scientific'>{scientificName}</div>
        {otherName.length > 0 && context === 'menu' ? <div className='family-other-name'>{otherName}</div> : null}
      </div>
    </div>
  );
};

export default CropFamilyDropdownOption;
