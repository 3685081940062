import { Geometry } from '@gi/math';
import { EventBus, HandleNode, HandleType, InspectableClassData, NodeEventActions, PointerDataWithDelta } from '@gi/core-renderer';

import BaseHandleSetNode from './base-handle-set-node';
import { MAX_VALID_ASPECT_RATIO, getRectScaleTransformHandlePositions, getRectScaleTransformHandlePositionsKeepAspect } from './handle-utils';

/**
 * Maintain aspect ratio will fall back to the default aspect ratio if the distance between
 * opposite handles is less than this value (prevent aspect ratio being invalid and breaking things)
 */
const MIN_HANDLE_DIST_MAINTAIN_ASPECT_RATIO = 3;

/** Returns a normal vector */
function getAspectRatioNormal(aspectRatio: number): Vector2 {
  if (aspectRatio > MAX_VALID_ASPECT_RATIO) {
    return { x: 1, y: 0 };
  }
  if (aspectRatio < 1 / MAX_VALID_ASPECT_RATIO) {
    return { x: 0, y: 1 };
  }
  return Geometry.normalizePoint({ x: aspectRatio, y: 1 });
}

export enum GenericHandleSetNodeEvent {
  Start = 'Start',
  Scale = 'Scale',
  Manipulate = 'Manipulate',
  Rotate = 'Rotate',
  End = 'End',
}

interface iManipulateData {
  topLeft: Vector2;
  topRight: Vector2;
  bottomLeft: Vector2;
  bottomRight: Vector2;
  center: Vector2;
  dimensions: Dimensions;
  rotation: number;
}

interface iRotateData {
  angle: number;
  origin: Vector2;
}

interface iScaleData {
  scale: Vector2;
  origin: Vector2;
  rotation: number;
}

export type GenericHandleSetNodeEventActions = NodeEventActions & {
  [GenericHandleSetNodeEvent.Start]: () => void;
  [GenericHandleSetNodeEvent.Scale]: (data: iScaleData) => void;
  [GenericHandleSetNodeEvent.Manipulate]: (data: iManipulateData) => void;
  [GenericHandleSetNodeEvent.Rotate]: (data: iRotateData) => void;
  [GenericHandleSetNodeEvent.End]: () => void;
};

type GenericHandleSetNodeInternalState = {
  rotation: number;
};

interface GenericHandleSetNodeOptions {
  allowManipulation: boolean;
  allowRotation: boolean;
  allowMaintainAspectRatio: boolean;
}

/**
 * GenericHandleSetNode
 *  A generic handle set, providing a rectangle of 4 corners, and a rotation handle.
 *  Both rotation and manipulation (corner dragging) can be disabled.
 */
class GenericHandleSetNode extends BaseHandleSetNode {
  type = 'MultiHandleSetNode';

  eventBus: EventBus<GenericHandleSetNodeEventActions> = new EventBus(this.eventBus);

  internalState: GenericHandleSetNodeInternalState;

  TL: HandleNode = new HandleNode('TopLeft');
  TR: HandleNode = new HandleNode('TopRight');
  BL: HandleNode = new HandleNode('BottomLeft');
  BR: HandleNode = new HandleNode('BottomRight');
  R: HandleNode = new HandleNode('Rotate', HandleType.CIRCLE);

  draggingHandle: HandleNode | null = null;

  #manipulateHandleStartPos: Vector2 | null = null;
  #manipulateAnchor: Vector2 | null = null;

  #rotateHandleStartPos: Vector2 | null = null;
  #rotateAnchor: Vector2 | null = null;
  #rotateStartPositions: Vector2[] | null = null;

  /** Allow holding SHIFT when dragging a corner to maintain aspect ratio */
  allowMaintainAspectRatio: boolean = true;

  /** Forces aspect ratio to be maintained */
  forceMaintainAspectRatio: boolean = false;

  /** Default aspect ratio of the handle-set to fall back to if distance between handles is too small */
  defaultAspectRatio: number = 1 / 1;

  constructor(options?: Partial<GenericHandleSetNodeOptions>) {
    super();

    this.internalState = {
      rotation: 0,
    };

    this.addHandles(this.TL, this.TR, this.BL, this.BR, this.R);

    this.TL.onDragStart = () => this.#startManipulate(this.TL, this.BR);
    this.TL.onDrag = (data) => {
      this.#onDragTL(data);
    };
    this.TL.onDragEnd = () => {
      this.#endManipulate();
    };

    this.TR.onDragStart = () => this.#startManipulate(this.TR, this.BL);
    this.TR.onDrag = (data) => {
      this.#onDragTR(data);
    };
    this.TR.onDragEnd = () => {
      this.#endManipulate();
    };

    this.BR.onDragStart = () => this.#startManipulate(this.BR, this.TL);
    this.BR.onDrag = (data) => {
      this.#onDragBR(data);
    };
    this.BR.onDragEnd = () => {
      this.#endManipulate();
    };

    this.BL.onDragStart = () => this.#startManipulate(this.BL, this.TR);
    this.BL.onDrag = (data) => {
      this.#onDragBL(data);
    };
    this.BL.onDragEnd = () => {
      this.#endManipulate();
    };

    this.R.onDragStart = () => this.#startRotate(this.R);
    this.R.onDrag = (data) => {
      this.#onDragR(data);
    };
    this.R.onDragEnd = () => {
      this.#endRotate();
    };

    if (options?.allowManipulation !== undefined) {
      this.setAllowManipulation(options.allowManipulation);
    }
    if (options?.allowRotation !== undefined) {
      this.setAllowRotation(options.allowRotation);
    }
    if (options?.allowMaintainAspectRatio !== undefined) {
      this.setAllowMaintainAspectRatio(options.allowMaintainAspectRatio);
    }
  }

  /**
   * Sets the position of the handles based off the input rectangle and rotation.
   * @param center The center of the handles
   * @param size The width and height of the handles
   * @param rotation The oration of the handles
   */
  setFromRectangle(center: Vector2, size: Dimensions, rotation: number) {
    const topLeft = { x: center.x - size.width / 2, y: center.y - size.height / 2 };
    const topRight = { x: center.x + size.width / 2, y: center.y - size.height / 2 };
    const bottomRight = { x: center.x + size.width / 2, y: center.y + size.height / 2 };
    const bottomLeft = { x: center.x - size.width / 2, y: center.y + size.height / 2 };
    this.#updateHandlesFromCorners(
      Geometry.rotateAroundPoint(topLeft, center, rotation),
      Geometry.rotateAroundPoint(topRight, center, rotation),
      Geometry.rotateAroundPoint(bottomRight, center, rotation),
      Geometry.rotateAroundPoint(bottomLeft, center, rotation)
    );
  }

  /**
   * Sets the position of the handles manually for each corner.
   * @param topLeft The top-left position of the shape
   * @param topRight The top-right position of the shape
   * @param bottomRight The bottom-right position of the shape
   * @param bottomLeft The bottom-left position of the shape
   */
  setFromCorners(topLeft: Vector2, topRight: Vector2, bottomRight: Vector2, bottomLeft: Vector2) {
    this.#updateHandlesFromCorners(topLeft, topRight, bottomRight, bottomLeft);
  }

  /**
   * Emits a `start` event
   */
  emitStart() {
    this.eventBus.emit(GenericHandleSetNodeEvent.Start);
  }

  /**
   * Emits an `end` event
   */
  emitEnd() {
    this.eventBus.emit(GenericHandleSetNodeEvent.End);
  }

  /**
   * Sets if the corner handles can be used to alter the size of the shape
   * @param canManipulate Can the handles be manipulated
   */
  setAllowManipulation(canManipulate: boolean) {
    this.TL.setVisibility(canManipulate);
    this.TR.setVisibility(canManipulate);
    this.BR.setVisibility(canManipulate);
    this.BL.setVisibility(canManipulate);
  }

  /**
   * Sets if the rotation handle will be shown.
   * @param canRotate Can the shape be rotated
   */
  setAllowRotation(canRotate: boolean) {
    this.R.setVisibility(canRotate);
  }

  /**
   * Enables/disables maintain aspect ratio when resizing and holding SHIFT.
   * @param canMaintainAspectRatio Can the aspect ratio be maintained
   */
  setAllowMaintainAspectRatio(canMaintainAspectRatio: boolean) {
    this.allowMaintainAspectRatio = canMaintainAspectRatio;
  }

  /**
   * Enables/disables forcing aspect ratio to be maintained when resizing (regardless of SHIFT).
   * @param forceMaintainAspectRatio Should aspect ratio be force-maintained
   */
  setForceMaintainAspectRatio(forceMaintainAspectRatio: boolean) {
    this.forceMaintainAspectRatio = forceMaintainAspectRatio;
  }

  #startManipulate(target: HandleNode, anchor: HandleNode) {
    this.draggingHandle = target;
    this.#manipulateHandleStartPos = target.getPosition();
    this.#manipulateAnchor = anchor.getPosition();
    if (Geometry.dist(this.#manipulateHandleStartPos, this.#manipulateAnchor) < MIN_HANDLE_DIST_MAINTAIN_ASPECT_RATIO) {
      this.#manipulateHandleStartPos = this.#fixHandleStartPos(target, anchor);
    }
    this.emitStart();
  }

  /**
   * Returns an altered handle start pos in order to give a valid aspect ratio when item is very small.
   */
  #fixHandleStartPos(handle: HandleNode, anchor: HandleNode) {
    // Normal assumes from top-left pointing to bottom-right corner.
    let normal = getAspectRatioNormal(this.defaultAspectRatio);
    switch (handle) {
      case this.TL: // Anchor is bottom-right
        normal = { x: -normal.x, y: -normal.y };
        break;
      case this.TR: // Anchor is bottom-left
        normal = { x: normal.x, y: -normal.y };
        break;
      case this.BL: // Anchor is top-right
        normal = { x: -normal.x, y: normal.y };
        break;
      case this.BR: // Anchor is top-left (default)
      default:
        normal = { x: normal.x, y: normal.y };
    }
    normal = Geometry.rotateAroundOrigin(normal, this.internalState.rotation);
    return Geometry.addPoint(anchor.getPosition(), Geometry.multiplyPoint(normal, MIN_HANDLE_DIST_MAINTAIN_ASPECT_RATIO));
  }

  #onManipulate(tlPosition: Vector2, trPosition: Vector2, brPosition: Vector2, blPosition: Vector2) {
    const width = Geometry.dist(tlPosition, trPosition);
    const height = Geometry.dist(tlPosition, blPosition);
    const center = Geometry.averagePoint(tlPosition, trPosition, brPosition, blPosition);
    const rotation = Geometry.angleBetweenPoints(tlPosition, trPosition);
    this.eventBus.emit(GenericHandleSetNodeEvent.Manipulate, {
      topLeft: tlPosition,
      topRight: trPosition,
      bottomLeft: blPosition,
      bottomRight: brPosition,
      center,
      dimensions: { width, height },
      rotation,
    });
  }

  #endManipulate() {
    this.draggingHandle = null;
    this.emitEnd();
  }

  #startRotate(target: HandleNode) {
    this.draggingHandle = target;
    this.#rotateHandleStartPos = target.getPosition();
    this.#rotateAnchor = Geometry.midpoint(
      Geometry.midpoint(this.TL.getPosition(), this.TR.getPosition()),
      Geometry.midpoint(this.BL.getPosition(), this.BR.getPosition())
    );
    this.#rotateStartPositions = [this.TL, this.TR, this.BR, this.BL].map((handle) => handle.getPosition());
    this.emitStart();
  }

  #onRotate(angle: number, origin: Vector2) {
    this.eventBus.emit(GenericHandleSetNodeEvent.Rotate, { angle, origin });
  }

  #endRotate() {
    this.draggingHandle = null;
    this.#rotateAnchor = null;
    this.#rotateStartPositions = null;
    this.emitEnd();
  }

  #onDragTL(pointerData: PointerDataWithDelta) {
    const TLStartPosition = pointerData.worldPosition;
    const BRPosition = this.BR.transform.state.values.position;

    const shouldMaintainAspect = this.forceMaintainAspectRatio || (pointerData.shiftKey && this.allowMaintainAspectRatio);
    const { rotation } = this.internalState;
    const {
      newAntiClockwisePosition: BLPosition,
      newClockwisePosition: TRPosition,
      newPosition: TLPosition,
    } = shouldMaintainAspect && this.#manipulateHandleStartPos
      ? getRectScaleTransformHandlePositionsKeepAspect(TLStartPosition, this.#manipulateHandleStartPos, BRPosition, rotation, true)
      : getRectScaleTransformHandlePositions(TLStartPosition, BRPosition, rotation, true);

    this.#updateHandlesFromCorners(TLPosition, TRPosition, BRPosition, BLPosition);
    this.#onManipulate(TLPosition, TRPosition, BRPosition, BLPosition);
  }

  #onDragTR(pointerData: PointerDataWithDelta) {
    const TRStartPosition = pointerData.worldPosition;
    const BLPosition = this.BL.transform.state.values.position;

    const shouldMaintainAspect = this.forceMaintainAspectRatio || (pointerData.shiftKey && this.allowMaintainAspectRatio);
    const { rotation } = this.internalState;
    const {
      newAntiClockwisePosition: TLPosition,
      newClockwisePosition: BRPosition,
      newPosition: TRPosition,
    } = shouldMaintainAspect && this.#manipulateHandleStartPos
      ? getRectScaleTransformHandlePositionsKeepAspect(TRStartPosition, this.#manipulateHandleStartPos, BLPosition, rotation)
      : getRectScaleTransformHandlePositions(TRStartPosition, BLPosition, rotation);

    this.#updateHandlesFromCorners(TLPosition, TRPosition, BRPosition, BLPosition);
    this.#onManipulate(TLPosition, TRPosition, BRPosition, BLPosition);
  }

  #onDragBR(pointerData: PointerDataWithDelta) {
    const BRStartPosition = pointerData.worldPosition;
    const TLPosition = this.TL.transform.state.values.position;

    const shouldMaintainAspect = this.forceMaintainAspectRatio || (pointerData.shiftKey && this.allowMaintainAspectRatio);
    const { rotation } = this.internalState;
    const {
      newAntiClockwisePosition: TRPosition,
      newClockwisePosition: BLPosition,
      newPosition: BRPosition,
    } = shouldMaintainAspect && this.#manipulateHandleStartPos
      ? getRectScaleTransformHandlePositionsKeepAspect(BRStartPosition, this.#manipulateHandleStartPos, TLPosition, rotation, true)
      : getRectScaleTransformHandlePositions(BRStartPosition, TLPosition, rotation, true);

    this.#updateHandlesFromCorners(TLPosition, TRPosition, BRPosition, BLPosition);
    this.#onManipulate(TLPosition, TRPosition, BRPosition, BLPosition);
  }

  #onDragBL(pointerData: PointerDataWithDelta) {
    const BLStartPosition = pointerData.worldPosition;
    const TRPosition = this.TR.transform.state.values.position;

    const shouldMaintainAspect = this.forceMaintainAspectRatio || (pointerData.shiftKey && this.allowMaintainAspectRatio);
    const { rotation } = this.internalState;
    const {
      newAntiClockwisePosition: BRPosition,
      newClockwisePosition: TLPosition,
      newPosition: BLPosition,
    } = shouldMaintainAspect && this.#manipulateHandleStartPos
      ? getRectScaleTransformHandlePositionsKeepAspect(BLStartPosition, this.#manipulateHandleStartPos, TRPosition, rotation)
      : getRectScaleTransformHandlePositions(BLStartPosition, TRPosition, rotation);

    this.#updateHandlesFromCorners(TLPosition, TRPosition, BRPosition, BLPosition);
    this.#onManipulate(TLPosition, TRPosition, BRPosition, BLPosition);
  }

  #onDragR(pointerData: PointerDataWithDelta) {
    if (!this.#rotateAnchor || !this.#rotateHandleStartPos || !this.#rotateStartPositions) {
      throw new Error('Required interaction data not set: rotateOrigin/rotateStartPos');
    }

    const snappedPosition = this.snapVector(pointerData.worldPosition, this.#rotateAnchor, pointerData.shiftKey);

    const newAngle = Geometry.angleBetweenPoints(this.#rotateAnchor, snappedPosition);
    const startAngle = Geometry.angleBetweenPoints(this.#rotateAnchor, this.#rotateHandleStartPos);
    const totalAngleChange = newAngle - startAngle;

    const TLPosition = Geometry.rotateAroundPoint(this.#rotateStartPositions[0], this.#rotateAnchor, totalAngleChange);
    const TRPosition = Geometry.rotateAroundPoint(this.#rotateStartPositions[1], this.#rotateAnchor, totalAngleChange);
    const BRPosition = Geometry.rotateAroundPoint(this.#rotateStartPositions[2], this.#rotateAnchor, totalAngleChange);
    const BLPosition = Geometry.rotateAroundPoint(this.#rotateStartPositions[3], this.#rotateAnchor, totalAngleChange);

    this.#updateHandlesFromCorners(TLPosition, TRPosition, BRPosition, BLPosition);
    this.#onManipulate(TLPosition, TRPosition, BRPosition, BLPosition);
    this.#onRotate(totalAngleChange, this.#rotateAnchor);
  }

  #updateHandlesFromCorners(topLeft: Vector2, topRight: Vector2, bottomRight: Vector2, bottomLeft: Vector2) {
    let rotation = Geometry.angleBetweenPoints(topLeft, topRight);
    if (Number.isNaN(rotation) || !Number.isFinite(rotation)) {
      rotation = 0; // If we ever hit this, we've probably been given invalid positions.
    }
    this.TL.setPosition(topLeft, rotation);
    this.TR.setPosition(topRight, rotation);
    this.BR.setPosition(bottomRight, rotation);
    this.BL.setPosition(bottomLeft, rotation);
    this.R.setPosition(Geometry.midpoint(topLeft, topRight));
    this.internalState.rotation = rotation;
    this.#updateOutline();
  }

  #updateOutline = () => {
    this.shape.state.values.points = [this.TL.getPosition(), this.TR.getPosition(), this.BR.getPosition(), this.BL.getPosition()];
  };

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default GenericHandleSetNode;
