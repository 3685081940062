/* eslint-disable class-methods-use-this */
import { DisplayObject, FederatedPointerEvent } from 'pixi.js-new';
import { Geometry } from '@gi/math';
import type CameraNode from '../../nodes/camera/camera-node';

// The minimum amount of pixels for a cursor to move before being considered a drag. Helps make clicking easier.
const DEFAULT_MIN_DRAG_DISTANCE = 3 * window.devicePixelRatio;

// The time for the mouse to be held down without dragging to be considered a click. (in ms).
const DEFAULT_CLICK_TIME = 400;

// The amount of time to long-press on something without moving to be considered a long-press. (in ms).
const DEFAULT_LONG_PRESS_TIME = 1000;

// The amount of time between the last mouse-up and the next mouse-up to consider a double-click. (in ms).
const DOUBLE_CLICK_SPEED = 400;

// Fallback pointer ID for mouse events. PixiJS uses 1. See @pixi/events -> EventSystem.ts
const MOUSE_POINTER_ID = 1;

interface BasePointerData {
  id: number;
  screenPosition: Vector2;
  worldPosition: Vector2;
  pointerType: string;
}

interface PointerKeyboardData {
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
}

interface ExtraPointerData {
  htmlTarget: HTMLElement | null;
  button: number;
}

interface PointerDeltaData {
  screenPositionDelta: Vector2;
  screenPositionTotalDelta: Vector2;
  worldPositionDelta: Vector2;
  worldPositionTotalDelta: Vector2;
}

interface PointerDeltas {
  primaryPointerDelta: PointerDeltaData;
  secondaryPointerDelta: PointerDeltaData | null;
}

export interface PointerData extends BasePointerData, PointerKeyboardData, ExtraPointerData {}
export interface PointerDataWithDelta extends PointerData, PointerDeltaData {}

export interface PointerClickData extends PointerData {
  /** Has this click come as a result of holding left-click without moving for `CLICK_DURATION` */
  timeElapsed: boolean;
}

interface BaseMultiTouchData {
  screenCenter: Vector2;
  screenGap: number;
  worldCenter: Vector2;
  worldGap: number;
  pointerType: 'touch';
}
interface MultiTouchDeltaData {
  screenCenterDelta: Vector2;
  screenCenterTotalDelta: Vector2;
  screenGapDelta: number;
  screenGapTotalDelta: number;
  worldCenterDelta: Vector2;
  worldCenterTotalDelta: Vector2;
  worldGapDelta: number;
  worldGapTotalDelta: number;
}

export interface MultiTouchData extends BaseMultiTouchData {}
export interface MultiTouchDataWithDelta extends BaseMultiTouchData, MultiTouchDeltaData {}

/**
 * Lifecycle of an interaction:
 *
 * - onStart: Always called at the very beginning, basically a setup callback.
 * - onClick: Called if the user presses and releases the pointer quickly and with miniimal movement.
 *   - ❌ Ends the interaction
 * - onDoubleClick: Same as above, but also requires the previous interaction to be very recent and have the same target. Also fired on long press.
 *   - ❌ Ends the interaction
 * - onDragStart: Called when the pointer moves enough to be considered a drag. This is why dragStart has deltas, as a drag may start a small distance from the original event
 *   - onDragmove: Continuation of onDragStart
 *   - onDragEnd: Called when the pointer is released or a multi-touch event is started
 * - onMultiTouchStart: Called when a second pointer touches the screen. Prevents any click/doubleClick/drag events.
 *   - onMultiTouchMove: Called when either pointer moves
 *   - onMultiTouchEnd: Called when either pointer is released
 *     - ❌ Ends the interaction
 * - onEnd: Always called at the very end, basically a teardown function.
 */

export interface PointerInteractionCallbacks {
  // Whenever an interaction generally starts. Probably shouldn't do anything here.
  onStart: (data: PointerData, interaction: PointerInteraction) => void;
  // Whenever a pointer event happens without triggering anything else.
  onClick: (data: PointerClickData, interaction: PointerInteraction) => void;
  // Whenever the same tagret is clicked 2 times in rapid succession.
  onDoubleClick: (data: PointerData, interaction: PointerInteraction) => void;
  // Whenever a target is touch-clicked without moving for an extended period of time.
  onLongPress: (data: PointerData, interaction: PointerInteraction) => void;
  // When a drag has started, usually because the pointer has moved enough
  onDragStart: (data: PointerDataWithDelta, interaction: PointerInteraction) => void;
  // When a drag containues.
  onDragMove: (data: PointerDataWithDelta, interaction: PointerInteraction) => void;
  // When a drag ends. This could be caused by a multi-touch start or pointerup.
  onDragEnd: (data: PointerDataWithDelta, interaction: PointerInteraction) => void;
  // When an interaction is manually cancelled during a drag. onDragEnd will not be called.
  onDragCancel: (data: PointerDataWithDelta, interaction: PointerInteraction) => void;
  // When a multi-touch event starts. Only called once per interaction.
  onMultiTouchStart: (data: MultiTouchData, interaction: PointerInteraction) => void;
  // When either of the touch inputs move.
  onMultiTouchMove: (data: MultiTouchDataWithDelta, interaction: PointerInteraction) => void;
  // When either of the touch inputs finish, ending the multi-touch.
  onMultiTouchEnd: (data: MultiTouchDataWithDelta, interaction: PointerInteraction) => void;
  // When an interaction is cancelled during a multi-touch. onMultiTouchEnd will not be called.
  onMultiTouchCancel: (data: MultiTouchDataWithDelta, interaction: PointerInteraction) => void;
  // When this interaction has completely finished (drag/multi-touch/click all completed).
  onEnd: (data: PointerDataWithDelta, interaction: PointerInteraction) => void;
  // TODO: onKeyChanged
}

enum PointerInteractionStatus {
  STARTED = 'STARTED',
  DRAG = 'DRAG',
  MULTI_TOUCH = 'MULTI_TOUCH',
  LONG_PRESS = 'LONG_PRESS',
  ENDED = 'ENDED',
}

export interface PointerInteractionRepeatInfo {
  count: number; // The amount of times this interaction has repeated
  lastAt: number; // The time of the last interaction
}

export interface PointerInteractionOptions {
  parent: HTMLElement;
  eventParent: DisplayObject;
  target: DisplayObject;
  camera: CameraNode;
  ignoreMultiTouch: boolean; // If set to true, second pointers won't trigger a multi-touch, they'll just be ignored
  ignoreLongPress: boolean; // If set to true, long-presses won't trigger a double-click event
  ignoreClick: boolean; // If set to true, pointerdowns will bypass click checks and go straight to a drag
  autoPanCamera: boolean; // If set to true, and drag events that go near the side of the canvas will pan the camera (if a camera is specified)
  repeatInfo: PointerInteractionRepeatInfo; // If the interaction manager believes this is a repeat of the previous interaction, this will be set
}

/**
 * Runs the given function after a set amount of "frames"
 * @param func The function to run
 * @param frames The amount of "frames" to wait. 0 = next frame
 */
const runAfterFrames = (func: () => void, frames: number) => {
  let framesLeft = frames;
  const loop = () => {
    if (framesLeft <= 0) {
      func();
    } else {
      framesLeft -= 1;
      requestAnimationFrame(loop);
    }
  };
  requestAnimationFrame(loop);
};

const getEmptyPointerDeltas = (): PointerDeltaData => {
  return {
    screenPositionDelta: { x: 0, y: 0 },
    screenPositionTotalDelta: { x: 0, y: 0 },
    worldPositionDelta: { x: 0, y: 0 },
    worldPositionTotalDelta: { x: 0, y: 0 },
  };
};

const getEmptyMultiTouchDeltas = (): MultiTouchDeltaData => {
  return {
    screenCenterDelta: { x: 0, y: 0 },
    screenCenterTotalDelta: { x: 0, y: 0 },
    screenGapDelta: 0,
    screenGapTotalDelta: 0,
    worldCenterDelta: { x: 0, y: 0 },
    worldCenterTotalDelta: { x: 0, y: 0 },
    worldGapDelta: 0,
    worldGapTotalDelta: 0,
  };
};

class PointerInteraction {
  #status: PointerInteractionStatus = PointerInteractionStatus.STARTED;
  #finalStatus: PointerInteractionStatus = PointerInteractionStatus.STARTED;
  #callbacks: Partial<PointerInteractionCallbacks>;

  #camera: CameraNode | null;
  #parent: HTMLElement | null;
  #eventParent: DisplayObject | null;
  #target: DisplayObject | null;
  #htmlTarget: HTMLElement | null;

  ignoreMultiTouch: boolean;
  ignoreLongPress: boolean;
  ignoreClick: boolean;
  #minDragDistance: number;
  #clickDuration: number;
  #longPressDuration: number;
  autoPanCamera: boolean;

  #repeatCount: number;
  #repeatLastAt: number | undefined;

  #startedAt: number = Date.now();
  #endedAt: number | null = null;
  #distanceTravelled: number = 0;
  #clickTimeout: number | null = null;
  #longPressTimeout: number | null = null;

  #keyboardKeys: PointerKeyboardData;

  #primaryPointer: BasePointerData;
  #primaryPointerStart: BasePointerData;
  #primaryPointerButton: number;
  #primaryPointerType: string;

  #secondaryPointer?: BasePointerData;
  #secondaryPointerStart?: BasePointerData;

  #multiTouch?: BaseMultiTouchData;
  #multiTouchStart?: BaseMultiTouchData;

  #removeEventListeners: () => void;

  constructor(event: PointerEvent, callbacks: Partial<PointerInteractionCallbacks>, options: Partial<PointerInteractionOptions> = {}) {
    this.#callbacks = callbacks;

    this.#parent = options.parent ?? null;
    this.#eventParent = options.eventParent ?? null;
    this.#target = options.target ?? null;
    this.#camera = options.camera ?? null;
    this.#htmlTarget = this.#getEventHTMLTarget(event);

    this.ignoreMultiTouch = options.ignoreMultiTouch ?? false;
    this.ignoreLongPress = options.ignoreLongPress ?? false;
    this.ignoreClick = options.ignoreClick ?? false;
    this.#minDragDistance = DEFAULT_MIN_DRAG_DISTANCE;
    this.#clickDuration = DEFAULT_CLICK_TIME;
    this.#longPressDuration = DEFAULT_LONG_PRESS_TIME;
    this.autoPanCamera = options.autoPanCamera ?? true;
    this.#repeatCount = options.repeatInfo?.count ?? 0;
    this.#repeatLastAt = options.repeatInfo?.lastAt;

    this.#updateKeyboardKeys(event);
    const standardisedPointerData = this.#getStandardisedPointerData(event);

    this.#primaryPointer = this.#getEventPointerData(standardisedPointerData);
    this.#primaryPointerStart = this.#primaryPointer;
    this.#primaryPointerButton = this.#getPointerButton(event);
    this.#primaryPointerType = standardisedPointerData.pointerType; // mouse/pen/touch

    if (this.#eventParent) {
      const eventParent = this.#eventParent;
      eventParent.on('pointermove', this.#onPointerMove);
      eventParent.on('globalpointermove', this.#onPointerMove);
      eventParent.on('pointerup', this.#onPointerUp);
      eventParent.on('pointerupoutside', this.#onPointerUp);
      eventParent.on('pointercancel', this.#onPointerCancel);

      // PixiJS hasn't implemented pointercancel, so we have to rely on native events for now.
      // PixiJS issue: https://github.com/pixijs/pixijs/issues/9538
      window.addEventListener('pointercancel', this.#onPointerCancel);

      this.#removeEventListeners = () => {
        eventParent.off('pointermove', this.#onPointerMove);
        eventParent.off('globalpointermove', this.#onPointerMove);
        eventParent.off('pointerup', this.#onPointerUp);
        eventParent.off('pointerupoutside', this.#onPointerUp);
        eventParent.off('pointercancel', this.#onPointerCancel);

        window.removeEventListener('pointercancel', this.#onPointerCancel);
      };
    } else {
      window.addEventListener('pointermove', this.#onPointerMove);
      window.addEventListener('pointerup', this.#onPointerUp);
      window.addEventListener('pointercancel', this.#onPointerCancel);

      this.#removeEventListeners = () => {
        window.removeEventListener('pointermove', this.#onPointerMove);
        window.removeEventListener('pointerup', this.#onPointerUp);
        window.removeEventListener('pointercancel', this.#onPointerCancel);
      };
    }

    this.#onStart(event);

    // Go straight into a drag if ignoreClick is on
    if (this.ignoreClick) {
      const deltas = this.#getPointerDeltas(this.#primaryPointerStart, this.#primaryPointer, this.#primaryPointerStart);
      this.#status = PointerInteractionStatus.DRAG;
      this.#onDragStart(deltas);
    } else {
      this.#clickTimeout = window.setTimeout(this.#onClickTimeElapsed, this.#clickDuration);
      this.#longPressTimeout = window.setTimeout(this.#onLongPressTimeElapsed, this.#longPressDuration);
    }
  }

  /**
   * Tracks a pointerdown event. Will start a multi-touch event if no secondary pointer is registered.
   * @param event The pointerdown event to track
   */
  trackPointerDown(event: PointerEvent) {
    if (this.#status === PointerInteractionStatus.ENDED) {
      return;
    }

    event.stopPropagation();

    this.#updateKeyboardKeys(event);
    const standardisedPointerData = this.#getStandardisedPointerData(event);

    if (standardisedPointerData.pointerId === this.#primaryPointer.id) {
      console.error('Primary pointer was pressed again during interaction. This should be impossible.');
      this.cancel();
      return;
    }

    if (standardisedPointerData.isPrimary) {
      console.error('A different primary pointer was pressed during interaction. This should be impossible.');
      /**
       * We could cancel the interaction here, as it's possible we're in a bugged state, as the
       *  primary pointer should be isPrimary, but we now have a second primary pointer with a
       *  different id.
       * This likely means we missed the initial primary pointer up event somehow. More testing
       *  needed however before I'd feel confident implementing this, as we may start cancelling
       *  legit events if we're mis-detecting isPrimary.
       */
    }

    if (this.#status === PointerInteractionStatus.LONG_PRESS) {
      return;
    }

    if (!this.ignoreMultiTouch && this.#status !== PointerInteractionStatus.MULTI_TOUCH) {
      if (this.#status === PointerInteractionStatus.DRAG) {
        // We're effectively cancelling the drag, so there's no deltas.
        this.#onDragEnd(this.#getPointerDeltas(this.#primaryPointer, this.#primaryPointer, this.#primaryPointerStart));
      }

      // Check the onDragEnd callback didn't cancel this interaction
      if ((this.#status as PointerInteractionStatus) === PointerInteractionStatus.ENDED) {
        return;
      }

      // Start a multi-touch event
      this.#secondaryPointer = this.#getEventPointerData(standardisedPointerData);
      this.#secondaryPointerStart = { ...this.#secondaryPointer };

      this.#multiTouch = this.#getMultiTouchData();
      this.#multiTouchStart = { ...this.#multiTouch };

      this.#status = PointerInteractionStatus.MULTI_TOUCH;

      this.#onMultiTouchStart();
    }
  }

  /**
   * Cancels this interaction, ending any drag/multi-touch
   * @param preventCallbacks Prevent any onDragEnd/onMultiTouchEnd callbacks being called.
   *  Recommend not using, as the interaction system guarantees onDragEnd/onMultiTouchEnd callbacks will run.
   */
  cancel(preventCallbacks: boolean = false) {
    if (this.#status === PointerInteractionStatus.ENDED) {
      return;
    }

    if (preventCallbacks) {
      if (this.#status === PointerInteractionStatus.DRAG) {
        const deltas = this.#getPointerDeltas(this.#primaryPointer, this.#primaryPointer, this.#primaryPointerStart);
        this.#onDragCancel(deltas);
      } else if (this.#status === PointerInteractionStatus.MULTI_TOUCH) {
        const deltas = this.#updateMultiTouch();
        this.#onMultiTouchCancel(deltas);
      }
    } else if (this.#status === PointerInteractionStatus.DRAG) {
      const deltas = this.#getPointerDeltas(this.#primaryPointer, this.#primaryPointer, this.#primaryPointerStart);
      this.#onDragEnd(deltas);
    } else if (this.#status === PointerInteractionStatus.MULTI_TOUCH) {
      const deltas = this.#updateMultiTouch();
      this.#onMultiTouchEnd(deltas);
    }

    this.#onEnd();
  }

  /* ------ POINTER UPDATERS ------ */

  /**
   * Updates the primary pointers position, returning its deltas and total deltas.
   * @param event The event to use to update the pointer
   * @returns A set of position deltas made by the pointer
   */
  #updatePrimaryPointer(event: PointerEvent | FederatedPointerEvent): PointerDeltaData {
    const oldData = { ...this.#primaryPointer };
    this.#primaryPointer = this.#getEventPointerData(event);
    this.#htmlTarget = this.#getEventHTMLTarget(event);
    return this.#getPointerDeltas(oldData, this.#primaryPointer, this.#primaryPointerStart);
  }

  /**
   * Updates the secondary pointers position, returning its deltas and total deltas.
   * @param event The event to use to update the pointer
   * @returns A set of position deltas made by the pointer
   */
  #updateSecondaryPointer(event: PointerEvent): PointerDeltaData {
    if (!this.#secondaryPointer || !this.#secondaryPointerStart) {
      throw new Error('Tried to update secondary pointer when no secondary pointer exists yet');
    }
    const oldData = { ...this.#secondaryPointer };
    this.#secondaryPointer = this.#getEventPointerData(event);
    return this.#getPointerDeltas(oldData, this.#secondaryPointer, this.#secondaryPointerStart);
  }

  /**
   * Updates the multi-touch data. Relies on primary and secondary pointer being up-to-date.
   * @returns The deltas from the last multi-touch and now
   */
  #updateMultiTouch(): MultiTouchDeltaData {
    if (!this.#onMultiTouchStart || !this.#secondaryPointer || !this.#multiTouch) {
      throw new Error('Tried to update multi-touch while not in multi-touch mode');
    }

    const oldData = { ...this.#multiTouch };
    this.#multiTouch = this.#getMultiTouchData();
    return this.#getMultiTouchDeltas(oldData, this.#multiTouch);
  }

  /**
   * Updates the primary and secondary pointers world positions (leaving screen position unchanged)
   * Call this if the camera moves without the pointer moving (thus world position updates)
   * @returns The world deltas of the 2 pointers.
   */
  #updatePointers(): PointerDeltas {
    const oldPrimaryData = { ...this.#primaryPointer };
    this.#primaryPointer = {
      id: oldPrimaryData.id,
      screenPosition: oldPrimaryData.screenPosition,
      worldPosition: this.toWorldPos(oldPrimaryData.screenPosition),
      pointerType: oldPrimaryData.pointerType,
    };

    const primaryPointerDelta = this.#getPointerDeltas(oldPrimaryData, this.#primaryPointer, this.#primaryPointerStart);

    if (!this.#secondaryPointer) {
      return {
        primaryPointerDelta,
        secondaryPointerDelta: null,
      };
    }

    const oldSecondaryData = { ...this.#secondaryPointer };
    this.#secondaryPointer = {
      id: oldSecondaryData.id,
      screenPosition: oldSecondaryData.screenPosition,
      worldPosition: this.toWorldPos(oldSecondaryData.screenPosition),
      pointerType: oldSecondaryData.pointerType,
    };

    const secondaryPointerDelta = this.#getPointerDeltas(oldPrimaryData, this.#primaryPointer, this.#primaryPointerStart);

    return {
      primaryPointerDelta,
      secondaryPointerDelta,
    };
  }

  /**
   * Called whenever a pointermove event happens. Calls relevant external callbacks.
   * @param event The pointermove event
   */
  #onPointerMove = (event: PointerEvent | FederatedPointerEvent) => {
    if (this.#status === PointerInteractionStatus.ENDED) {
      return;
    }

    this.#updateKeyboardKeys(event);

    if (this.#status === PointerInteractionStatus.MULTI_TOUCH) {
      if (event.pointerId === this.#primaryPointer.id) {
        this.#updatePrimaryPointer(event);
        const deltas = this.#updateMultiTouch();
        this.#onMultiTouchMove(deltas);
      } else if (event.pointerId === this.#secondaryPointer?.id) {
        this.#updateSecondaryPointer(event);
        const deltas = this.#updateMultiTouch();
        this.#onMultiTouchMove(deltas);
      }
    } else if (event.pointerId === this.#primaryPointer.id) {
      if (this.#status === PointerInteractionStatus.DRAG) {
        const deltas = this.#updatePrimaryPointer(event);
        this.#onDragMove(deltas);
        if (this.#camera && this.autoPanCamera) {
          this.#camera.autoPan(this.#primaryPointer.screenPosition, this.#onCameraMove);
        }
      } else if (this.#status === PointerInteractionStatus.LONG_PRESS) {
        this.#updatePrimaryPointer(event);
      } else {
        // Should we start a drag?
        const pos = this.toRelativePos({ x: event.clientX, y: event.clientY });
        this.#distanceTravelled += Geometry.dist(this.#primaryPointer.screenPosition, pos);
        const hasTravelledEnough = this.#distanceTravelled >= this.#minDragDistance;

        this.#updatePrimaryPointer(event);

        if (hasTravelledEnough) {
          const deltas = this.#getPointerDeltas(this.#primaryPointerStart, this.#primaryPointer, this.#primaryPointerStart);
          this.#status = PointerInteractionStatus.DRAG;
          this.#onDragStart(deltas);
        }
      }

      this.#checkForPointerUp(event);
    }
  };

  /**
   * Called whenever a pointerup/pointercancel event happens. Calls relevant external callbacks, and ends if all pointers are released.
   * @param event The pointerup or pointercancel event
   */
  #onPointerUp = (event: PointerEvent | FederatedPointerEvent) => {
    if (this.#status === PointerInteractionStatus.ENDED) {
      return;
    }

    this.#updateKeyboardKeys(event);

    if (this.#status === PointerInteractionStatus.MULTI_TOUCH) {
      if (event.pointerId !== this.#primaryPointer.id && event.pointerId !== this.#secondaryPointer?.id) {
        return;
      }

      const isPrimaryPointer = event.pointerId === this.#primaryPointer.id;

      let deltas: PointerDeltaData;
      if (isPrimaryPointer) {
        deltas = this.#updatePrimaryPointer(event);
      } else {
        deltas = this.#updateSecondaryPointer(event);
      }

      const multiTouchDeltas = this.#updateMultiTouch();
      this.#onMultiTouchEnd(multiTouchDeltas);
      this.#onEnd(deltas);

      // TODO: Maybe try recover to a drag? For now, just end.
      // if (isPrimaryPointer) {
      //   this.#primaryPointer = this.#secondaryPointer!;
      // }
      // this.#secondaryPointer = undefined;
    } else if (event.pointerId === this.#primaryPointer.id) {
      if (this.#camera && this.autoPanCamera) {
        // Ends all auto-pan logic
        this.#camera.autoPan(false);
      }

      const deltas = this.#updatePrimaryPointer(event);

      if (this.#status !== PointerInteractionStatus.LONG_PRESS) {
        if (this.#status === PointerInteractionStatus.DRAG) {
          this.#onDragEnd(deltas);
        } else if (this.#repeatLastAt && this.#repeatLastAt > Date.now() - DOUBLE_CLICK_SPEED) {
          this.#onDoubleClick(event);
        } else {
          this.#repeatCount = 0;
          this.#onClick(false, event);
        }
      }

      this.#onEnd(deltas);
    }
  };

  #onPointerCancel = this.#onPointerUp;

  /**
   * Called whenever the camera moves.
   * The camera moving will alter the world position of the interaction, without a drag firing or the screen position changing.
   */
  #onCameraMove = () => {
    if (this.#status === PointerInteractionStatus.ENDED) {
      return;
    }

    if (this.#status === PointerInteractionStatus.MULTI_TOUCH) {
      this.#updatePointers();
      const deltas = this.#updateMultiTouch();
      this.#onMultiTouchMove(deltas);
    } else if (this.#status === PointerInteractionStatus.DRAG) {
      const deltas = this.#updatePointers();
      this.#onDragMove(deltas.primaryPointerDelta);
    } else {
      this.#updatePointers(); // TODO: Maybe not needed?
    }
  };

  /**
   * Prevents a context menu from being created.
   * @param event The event
   */
  #onContextMenu = (event: MouseEvent) => {
    event.preventDefault();
  };

  /**
   * Called when the click duration has elapsed. Responsble for calling the onClick callback for
   *  when a user holds onto an item without dragging for an amount of time.
   */
  #onClickTimeElapsed = () => {
    this.#clickTimeout = null;
    if (this.#status === PointerInteractionStatus.STARTED) {
      this.#onClick(true);
    }
  };

  /**
   * Called when the long press duration has elapsed. Responsible for calling the long-press
   */
  #onLongPressTimeElapsed = () => {
    this.#longPressTimeout = null;
    if (this.#status === PointerInteractionStatus.STARTED && this.#primaryPointerType === 'touch') {
      if (this.#callbacks.onLongPress && !this.ignoreLongPress) {
        this.#status = PointerInteractionStatus.LONG_PRESS;
        this.#onLongPress();
      }
    }
  };

  /**
   * Extracts the HTML target from an event, or null if none found.
   * @param event The  pointer event to pull from
   * @returns A html target underneath the pointer event, or null if not HTML
   */
  #getEventHTMLTarget(event: PointerEvent | FederatedPointerEvent) {
    const target = event instanceof FederatedPointerEvent ? event.nativeEvent.target : event.target;
    return target instanceof HTMLElement ? target : null;
  }

  /* ------ POSITION HELPERS ------ */

  /**
   * Converts a position in the document to be relative to the top-left of the parent (canvas)
   * @param screenPos The screen position to convert
   * @returns A position relative to the top-left of the canvas (parent).
   */
  toRelativePos(screenPos: Vector2): Vector2 {
    if (!this.#parent) {
      return screenPos;
    }
    const bounds = this.#parent.getBoundingClientRect();
    return {
      x: screenPos.x - bounds.x,
      y: screenPos.y - bounds.y,
    };
  }

  /**
   * Convers a screen coordinate (relative to parent) to a world coordinate, using the camera associated with this interaction
   * @param relativePos The position to convert, relative to the top-left of the parent.
   * @returns A world coordinate underneath the given screen coordinate
   */
  toWorldPos(relativePos: Vector2): Vector2 {
    if (!this.#camera) {
      return relativePos;
    }
    return this.#camera.getWorldPos(relativePos);
  }

  /* ------ KEYBOARD HELPERS ------ */

  /**
   * Returns the "metadata" from the event, such as which keys are down.
   * @param event The event to pull data from
   * @returns Extra data about the event
   */
  #getKeyboardKeysDown(event: Pick<PointerEvent, 'altKey' | 'ctrlKey' | 'metaKey' | 'shiftKey'>): PointerKeyboardData {
    return {
      altKey: event.altKey,
      ctrlKey: event.ctrlKey,
      metaKey: event.metaKey,
      shiftKey: event.shiftKey,
    };
  }

  /**
   * Updates the internally tracked keys down
   * @param event The event to take the keys data from
   */
  #updateKeyboardKeys(event: Pick<PointerEvent, 'altKey' | 'ctrlKey' | 'metaKey' | 'shiftKey'>) {
    this.#keyboardKeys = this.#getKeyboardKeysDown(event);
  }

  /** ------ POINTER BUTTON TRACKING ------
   *  These help keep track of which buttons on the primary pointer are currently down.
   *  pointerup events are not reliably fired, so this allows us to fake them if it looks like
   *  we missed an event.
   */

  /**
   * Gets the button which was pressed during the given event.
   * This number is compatibile with the `MouseEvent.buttons` property, which naturally uses
   *  different numbers for MMB/RMB than `MouseEvent.button`...
   * @param event The event to get the button from
   * @returns The buttun number that was pressed. Matches the `event.buttons` spec.
   */
  #getPointerButton(event: PointerEvent) {
    if (event.button === 1) {
      return 2;
    }
    if (event.button === 2) {
      return 1;
    }
    return event.button;
  }

  /**
   * Checks if the given event has the button of the primary pointer still depressed.
   * If not, this will fire trigger a pointerup event.
   * @param event The event
   */
  #checkForPointerUp(event: PointerEvent) {
    // eslint-disable-next-line no-bitwise
    if (!(event.buttons & (2 ** this.#primaryPointerButton))) {
      console.warn(`Button [${this.#primaryPointerButton}] has been released without being captured, faking pointerUp event.`);
      this.#onPointerUp(event);
    }
  }

  /* ------ POINTER HELPERS ------ */

  /**
   * Returns a small set of useful data from the given event.
   * Modifies FederatedEvents if we're using native events to get the native event data.
   * @param event The event to potentially convert
   * @returns A subset of the useful data from the event
   */
  #getStandardisedPointerData(
    event: FederatedPointerEvent | PointerEvent
  ): Pick<PointerEvent, 'clientX' | 'clientY' | 'pointerId' | 'pointerType' | 'isPrimary'> {
    if (this.#eventParent !== null) {
      return event;
    }
    if (event instanceof FederatedPointerEvent) {
      const { nativeEvent } = event;
      if (nativeEvent instanceof Touch) {
        return {
          pointerType: 'touch',
          pointerId: nativeEvent.identifier,
          clientX: nativeEvent.clientX,
          clientY: nativeEvent.clientY,
          isPrimary: false, // Can't detect from a single touch
        };
      }
      if (nativeEvent instanceof TouchEvent) {
        return {
          pointerType: 'touch',
          pointerId: nativeEvent.changedTouches[0].identifier,
          clientX: nativeEvent.changedTouches[0].clientX,
          clientY: nativeEvent.changedTouches[0].clientY,
          isPrimary: nativeEvent.touches.length === 1, // Fake it, if there's only 1 touch, it's probably primary
        };
      }
      if (nativeEvent instanceof PointerEvent) {
        return nativeEvent;
      }
      return {
        pointerId: MOUSE_POINTER_ID,
        pointerType: 'mouse',
        clientX: nativeEvent.clientX,
        clientY: nativeEvent.clientY,
        isPrimary: true, // Is a mouse, should always be primary
      };
    }
    return event;
  }

  /**
   * Returns the base data from the event, such as pointer ID and position.
   * @param event The event to pull data from
   * @returns Basic data baout the poiner used in the event
   */
  #getEventPointerData(event: Pick<PointerEvent, 'clientX' | 'clientY' | 'pointerId' | 'pointerType'>): BasePointerData {
    const relativePos = this.toRelativePos({ x: event.clientX, y: event.clientY });
    const worldPos = this.toWorldPos(relativePos);

    return {
      id: event.pointerId ?? MOUSE_POINTER_ID,
      screenPosition: relativePos,
      worldPosition: worldPos,
      pointerType: event.pointerType ?? 'mouse',
    };
  }

  /**
   * Returns the position delats between 2 pointer positions.
   * @param oldPosition The old positions of the poitner
   * @param newPosition The new positions of the pointer
   * @param startPosition The original start position of the pointer
   * @returns The deltas of the 2 sets of positions.
   */
  #getPointerDeltas(oldPosition: BasePointerData, newPosition: BasePointerData, startPosition: BasePointerData): PointerDeltaData {
    return {
      screenPositionDelta: Geometry.getPointDelta(oldPosition.screenPosition, newPosition.screenPosition),
      screenPositionTotalDelta: Geometry.getPointDelta(startPosition.screenPosition, newPosition.screenPosition),
      worldPositionDelta: Geometry.getPointDelta(oldPosition.worldPosition, newPosition.worldPosition),
      worldPositionTotalDelta: Geometry.getPointDelta(startPosition.worldPosition, newPosition.worldPosition),
    };
  }

  /* ------ MULTI-TOUCH HELPERS ------ */

  #getMultiTouchData(): BaseMultiTouchData {
    if (!this.#secondaryPointer) {
      throw new Error('Cannot generate multi-touch data without a secondary pointer.');
    }
    return {
      screenCenter: Geometry.midpoint(this.#primaryPointer.screenPosition, this.#secondaryPointer.screenPosition),
      screenGap: Geometry.dist(this.#primaryPointer.screenPosition, this.#secondaryPointer.screenPosition),
      worldCenter: Geometry.midpoint(this.#primaryPointer.worldPosition, this.#secondaryPointer.worldPosition),
      worldGap: Geometry.dist(this.#primaryPointer.worldPosition, this.#secondaryPointer.worldPosition),
      pointerType: 'touch',
    };
  }

  #getMultiTouchDeltas(oldMultiTouch: BaseMultiTouchData, newMultiTouch: BaseMultiTouchData): MultiTouchDeltaData {
    if (!this.#multiTouchStart) {
      throw new Error('No MultiTouchStart data to calculate total deltas.');
    }
    return {
      screenCenterDelta: Geometry.getPointDelta(oldMultiTouch.screenCenter, newMultiTouch.screenCenter),
      screenCenterTotalDelta: Geometry.getPointDelta(this.#multiTouchStart.screenCenter, newMultiTouch.screenCenter),
      screenGapDelta: newMultiTouch.screenGap - oldMultiTouch.screenGap,
      screenGapTotalDelta: newMultiTouch.screenGap - this.#multiTouchStart.screenGap,
      worldCenterDelta: Geometry.getPointDelta(oldMultiTouch.worldCenter, newMultiTouch.worldCenter),
      worldCenterTotalDelta: Geometry.getPointDelta(this.#multiTouchStart.worldCenter, newMultiTouch.screenCenter),
      worldGapDelta: newMultiTouch.worldGap - oldMultiTouch.worldGap,
      worldGapTotalDelta: newMultiTouch.worldGap - this.#multiTouchStart.worldGap,
    };
  }

  /* ------ EXTERNAL DRAG CALLBACK HANDLERS ------ */

  #runDragCallback(callback: ((data: PointerDataWithDelta, interaction: PointerInteraction) => void) | undefined, deltas?: PointerDeltaData) {
    if (!callback) {
      return;
    }

    const finalDeltas: PointerDeltaData = deltas ?? getEmptyPointerDeltas();
    callback(
      {
        ...this.#primaryPointer,
        ...finalDeltas,
        ...this.#keyboardKeys,
        htmlTarget: this.#htmlTarget,
        button: this.#primaryPointerButton,
      },
      this
    );
  }

  #onDragStart(deltas?: PointerDeltaData) {
    this.#runDragCallback(this.#callbacks.onDragStart, deltas);
  }

  #onDragMove(deltas?: PointerDeltaData) {
    this.#runDragCallback(this.#callbacks.onDragMove, deltas);
  }

  #onDragEnd(deltas?: PointerDeltaData) {
    this.#runDragCallback(this.#callbacks.onDragEnd, deltas);
  }

  #onDragCancel(deltas?: PointerDeltaData) {
    this.#runDragCallback(this.#callbacks.onDragCancel, deltas);
  }

  /* ------ EXTERNAL MULTI-TOUCH CALLBACK HANDLERS ------ */

  #runMultiTouchCallback(callback?: (data: MultiTouchDataWithDelta, interaction: PointerInteraction) => void, deltas?: MultiTouchDeltaData) {
    if (!this.#secondaryPointer || !this.#multiTouch) {
      throw new Error('Tried to call multiTouchMove callback when required data is missing.');
    }

    if (!callback) {
      return;
    }

    const finalDeltas: MultiTouchDeltaData = deltas ?? getEmptyMultiTouchDeltas();
    callback(
      {
        ...this.#multiTouch,
        ...finalDeltas,
      },
      this
    );
  }

  #onMultiTouchStart() {
    if (!this.#secondaryPointer || !this.#multiTouch) {
      throw new Error('Tried to call multiTouchStart callback when required data is missing.');
    }

    if (!this.#callbacks.onMultiTouchStart) {
      return;
    }

    this.#callbacks.onMultiTouchStart(
      {
        ...this.#multiTouch,
      },
      this
    );
  }

  #onMultiTouchMove(deltas?: MultiTouchDeltaData) {
    this.#runMultiTouchCallback(this.#callbacks.onMultiTouchMove, deltas);
  }

  #onMultiTouchEnd(deltas?: MultiTouchDeltaData) {
    this.#runMultiTouchCallback(this.#callbacks.onMultiTouchEnd, deltas);
  }

  #onMultiTouchCancel(deltas?: MultiTouchDeltaData) {
    this.#runMultiTouchCallback(this.#callbacks.onMultiTouchCancel, deltas);
  }

  /* ------ EXTERNAL CLICK CALLBACK HANDLERS ------ */

  #onClick(timeElapsed: boolean, event?: PointerEvent) {
    if (!this.#callbacks.onClick) {
      return;
    }

    const keys = event ? this.#getKeyboardKeysDown(event) : this.#keyboardKeys;

    this.#callbacks.onClick(
      {
        ...this.#primaryPointer,
        ...keys,
        htmlTarget: this.#htmlTarget,
        button: this.#primaryPointerButton,
        timeElapsed,
      },
      this
    );
  }

  #onDoubleClick(event: PointerEvent) {
    if (!this.#callbacks.onDoubleClick) {
      return;
    }

    this.#callbacks.onDoubleClick(
      {
        ...this.#primaryPointer,
        ...this.#getKeyboardKeysDown(event),
        htmlTarget: this.#htmlTarget,
        button: this.#primaryPointerButton,
      },
      this
    );
  }

  #onLongPress() {
    if (!this.#callbacks.onLongPress) {
      return;
    }

    this.#callbacks.onLongPress(
      {
        ...this.#primaryPointer,
        ...this.#keyboardKeys,
        htmlTarget: this.#htmlTarget,
        button: this.#primaryPointerButton,
      },
      this
    );
  }

  /* ------ EXTERNAL LIFECYCLE CALLBACK HANDLERS ------ */

  #onStart(event: PointerEvent) {
    window.addEventListener('contextmenu', this.#onContextMenu);

    if (!this.#callbacks.onStart) {
      return;
    }

    this.#callbacks.onStart(
      {
        ...this.#primaryPointer,
        ...this.#getKeyboardKeysDown(event),
        htmlTarget: this.#htmlTarget,
        button: this.#primaryPointerButton,
      },
      this
    );
  }

  #onEnd(deltas?: PointerDeltaData) {
    this.#endedAt = Date.now();
    this.#finalStatus = this.#status;
    this.#status = PointerInteractionStatus.ENDED;

    runAfterFrames(() => {
      window.removeEventListener('contextmenu', this.#onContextMenu);
    }, 1);
    this.#removeEventListeners();

    if (this.#clickTimeout !== null) {
      window.clearTimeout(this.#clickTimeout);
    }
    if (this.#longPressTimeout !== null) {
      window.clearTimeout(this.#longPressTimeout);
    }

    if (!this.#callbacks.onEnd) {
      return;
    }

    const finalDeltas: PointerDeltaData = deltas ?? getEmptyPointerDeltas();
    this.#callbacks.onEnd(
      {
        ...this.#primaryPointer,
        ...finalDeltas,
        ...this.#keyboardKeys,
        htmlTarget: this.#htmlTarget,
        button: this.#primaryPointerButton,
      },
      this
    );
  }

  /* ------ GETTERS ------ */
  get hasEnded() {
    return this.#status === PointerInteractionStatus.ENDED;
  }
  get isDrag() {
    return this.#status === PointerInteractionStatus.DRAG;
  }
  get isMultiTouch() {
    return this.#status === PointerInteractionStatus.MULTI_TOUCH;
  }
  get target() {
    return this.#target;
  }
  get didDrag() {
    return this.#finalStatus === PointerInteractionStatus.DRAG;
  }
  get didMultiTouch() {
    return this.#finalStatus === PointerInteractionStatus.MULTI_TOUCH;
  }
  get repeatCount() {
    return this.#repeatCount;
  }
  get startedAt() {
    return this.#startedAt;
  }
  get endedAt() {
    return this.#endedAt;
  }
}

export default PointerInteraction;
