import { DEFAULT_PLANT_DISPLAY_MODE, DEFAULT_PLANT_SPRITE_DISPLAY_LIMIT, DeviceDisplayMode, PlannerControlsTab, RenderMode, WheelModes } from '@gi/constants';
import LocalSettingsActionTypes from './local-settings-action-types';
import { LocalSettings, LocalSettingsUpdate } from './local-settings-type';

export const DEFAULT_STATE: LocalSettings = {
  initialised: false,
  wheelMode: WheelModes.ZOOM,
  plantDisplayMode: DEFAULT_PLANT_DISPLAY_MODE,
  plantSpriteCountLimit: DEFAULT_PLANT_SPRITE_DISPLAY_LIMIT,
  snapToGrid: false,
  showEditToolbar: true,
  showViewToolbar: true,
  showLayerToolbar: true,
  showMonthToolbar: true,
  showCropRotationToolbar: true,
  showHelpToolbar: true,
  debugMode: false,
  sfgMode: false,
  showSFGHelpOnToggle: true,
  enableSnapOnSFGMode: false,
  touchMode: false,
  showTouchIntroductionModalOnStart: true,
  renderMode: RenderMode.AUTO,
  customDevicePixelRatio: false,
  devicePixelRatio: 1,
  dontShowDrawingToolsDragWarning: false,
  autoPan: true,
  deviceDisplayMode: DeviceDisplayMode.SUGGEST,
  hideIntercomIntroduction: false,
  hideTouchControlsTutorial: false,
  useCustomCursors: true,
  customCursorScale: 1,
  hideTouchDragHelpNotifications: false,
  touchDragRequiresSelection: true,
  minimiseRightSidebar: false,
  rightSidebarActiveTab: PlannerControlsTab.OBJECTIVES,
  hideItemLockingHelpNotifications: false,
  textQuality: 4,
};

const setLocalSettings = (state: LocalSettingsUpdate, action) => {
  return {
    ...state,
    ...action.settings,
    initialised: true,
  };
};

export default (state: LocalSettings = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LocalSettingsActionTypes.SET_LOCAL_SETTINGS:
      return setLocalSettings(state, action);
    default:
      return state;
  }
};
