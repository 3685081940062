import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import TimelineBar from './timeline-bar';

import './timeline.scss';

/**
 * @param {{
 *  barData: { values: number[], heading: string, subHeading: string }[],
 *  highlightedBar?: number,
 *  onBarSelect?: () => void,
 * }}
 */
const Timeline = ({ barData, highlightedBar = 0, onBarSelect = () => {} }) => {
  const [hoveredBar, setHoveredBar] = useState(null);

  const handleBarClick = (barNum) => {
    onBarSelect(barNum);
  };

  const isHighlighted = (barNum) => highlightedBar === barNum;
  const isAdjacentToHighlight = (barNum) => highlightedBar === barNum - 1 || highlightedBar === barNum + 1;
  const isAdjacentToHover = (barNum) => hoveredBar === barNum - 1 || hoveredBar === barNum + 1;

  return (
    <div className='timeline'>
      <div className='tl_chart'>
        {barData.map((individualBarData, barNum) => (
          <TimelineBar
            key={barNum}
            isHighlighted={isHighlighted(individualBarData.weekNo)}
            isAdjacentToHighlight={isAdjacentToHighlight(individualBarData.weekNo)}
            isAdjacentToHover={isAdjacentToHover(individualBarData.weekNo)}
            barData={individualBarData}
            onHover={() => setHoveredBar(individualBarData.weekNo)}
            onClick={() => handleBarClick(individualBarData)}
          />
        ))}
      </div>
    </div>
  );
};

Timeline.propTypes = {
  barData: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.arrayOf(PropTypes.number).isRequired,
      heading: PropTypes.string.isRequired,
      subHeading: PropTypes.string.isRequired,
    })
  ).isRequired,
  highlightedBar: PropTypes.number,
  onBarSelect: PropTypes.func,
};

export default Timeline;
