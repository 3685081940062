import { RequestError } from '@gi/gi-network';
import getUserErrorCodes from './get-user-error-codes';
import putUserErrorCodes from './put-user-error-codes';

/**
 * Attaches a client message to a network error from a GET user request via the 'clientMessage' property.
 * The client message is attached based on the response code of the error
 *
 * @param {NetworkError} responseError
 */
const attachGetUserErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && getUserErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = getUserErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown load user error - ${responseError.statusCode}`;
  }
};
/**
 * Attaches a client message to a network error from a PUT user request via the 'clientMessage' property.
 * The client message is attached based on the response code of the error
 *
 * @param {NetworkError} responseError
 */
const attachPutUserErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && putUserErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = putUserErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown save user error - ${responseError.statusCode}`;
  }
};

export { attachGetUserErrorClientMessage, attachPutUserErrorClientMessage };
