import { SpriteSourceType } from './constants';

import { NineSliceSpriteSource } from './nine-slice-sprite-source';
import { PathSpriteSource } from './path-sprite-source';
import { SpriteSource } from './sprite-source';

type SpriteTypes = {
  [SpriteSourceType.SINGLE]: SpriteSource;
  [SpriteSourceType.PATH]: PathSpriteSource;
  [SpriteSourceType.NINE_SLICE]: NineSliceSpriteSource;
};

export function assertSpriteType<T extends SpriteSourceType>(
  sprite: SpriteSource | PathSpriteSource | NineSliceSpriteSource,
  type: T
): asserts sprite is SpriteTypes[T] {
  if (sprite.type !== type) {
    throw new Error(`Unexpected Sprite Type. Expected ${type}, given ${sprite.type}`);
  }
}
