import React from 'react';
import PropTypes from 'prop-types';

import './form.scss';

/**
 * @param {{
 *  children?: import('react').ReactNode,
 *  className?: string
 * }}
 */
const FormRowWarningText = ({ children = null, className = '' }) => {
  return <div className={`form-row-warning-text ${className}`}>{children}</div>;
};

FormRowWarningText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default FormRowWarningText;
