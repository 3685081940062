import React, { useMemo } from 'react';
import { LeaveContextParams, useLeaveContextConfirmation } from './use-leave-context-confirmation';

export type ModalContextType = {
  close: () => void;
  attemptClose: () => void;
  setCloseContextParams: (params: LeaveContextParams | null) => void;
};

export const ModalContext = React.createContext<ModalContextType>({} as ModalContextType);

interface iProps {
  children: React.ReactNode;
  close: () => void;
}

export const ModalProvider = ({ children, close }: iProps): JSX.Element => {
  const { attemptLeave, modal, setLeaveContextParams } = useLeaveContextConfirmation();

  const attemptClose = () => {
    attemptLeave(close);
  };

  const value = useMemo(
    () => ({
      close,
      attemptClose,
      setCloseContextParams: setLeaveContextParams,
    }),
    [close, attemptClose, setLeaveContextParams]
  );

  return (
    <ModalContext.Provider value={value}>
      {modal}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
