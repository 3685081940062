import React, { Fragment, useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Country from '@gi/country';
import Plan, { PlanSetUtils, PlanUtils } from '@gi/plan';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { SessionSelectors } from '@gi/react-session';
import { ResourceContext } from '@gi/resource-provider';
import { RequestSelectors } from '@gi/react-requests';
import { CanvasActionCreators, CanvasSelectors, GardenCanvasContext } from '@gi/react-garden-canvas';
import { createFormValues } from '@gi/form-responsive';
import { StringValidators } from '@gi/validators';
import LoadingButton from '@gi/loading-button';

import {
  ModalPaneContainer,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalPane,
  ModalPaneSectionGroup,
  ModalPaneSection,
  ModalPaneSectionContent,
  ModalPaneSectionHeader,
} from '@gi/modal';

import PublishPlanIntroduction from './publish-plan-introduction';
import PublishPlanPageInformation from './publish-plan-page-information';
import PublishPlanOptions from './publish-plan-options';
import PublishPlanInfo from './publish-plan-info';
import { publishPlan, PublishPlanProperties } from '../publish-action-creators';

const MAXIMUM_PLAN_DIMENSIONS = 3050;

/**
 * Returns the name of the passed country, or an empty string if null is passed
 */
const getCountryName = (country: Country) => {
  if (country === null) {
    return '';
  }

  return country.name;
};

/**
 * Returns a string representing the size limit of publish plans
 * The returned measurements are approximate to allow for 100 feet (30.48m) when
 * the actual limit is 30.5m
 *
 * Uses the plans grid units (Metric/imperial)
 */
const getPlanSizeLimitString = (plan: Plan) => {
  if (plan.plannerSettings.metric) {
    return ' 30m';
  }

  return ' 100 feet';
};

interface iProps {
  plan: Plan;
  closeModal: () => void;
}

const PublishModalStage1 = ({ plan, closeModal }: iProps): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);
  const requests = useSelector(RequestSelectors.getRequests);
  const lastSavePlans = useSelector(CanvasSelectors.getLastSavePlans);
  const { userCountry } = useContext(ResourceContext);
  const { gardenCanvas } = useContext(GardenCanvasContext);
  const dispatch = useDispatch();

  const savingPlan = RequestsUtils.getStatus(requests, `SAVE_PLAN_${plan.id}`) === RequestStatus.IN_PROGRESS;

  const initialLocation = plan.publishLocation === '' && userCountry ? getCountryName(userCountry) : plan.publishLocation;

  const [formValues, setFormValues] = useState(
    createFormValues<PublishPlanProperties>({
      location: { value: initialLocation, validators: [StringValidators.notEmpty()] },
      description: { value: plan.publishDescription, validators: [StringValidators.notEmpty()] },
      publishMap: { value: plan.publishMap },
      findOnMap: { value: plan.publishFindOnMap },
      publishPlantList: { value: plan.publishPlantList },
      publishNotes: { value: plan.publishNotes },
      type: { value: plan.type },
      layout: { value: plan.layout },
      sun: { value: plan.sun },
      soil: { value: plan.soil },
    })
  );

  if (!user) {
    return null;
  }

  const setValue = <K extends keyof PublishPlanProperties>(key: K, value: PublishPlanProperties[K]) => {
    setFormValues((currentFormValues) => currentFormValues.setValue(key, { value }));
  };

  const onPublishPlan = () => {
    if (!gardenCanvas) {
      return;
    }
    dispatch(publishPlan(gardenCanvas, user, plan, formValues.values, 1000));
  };

  // Check if the given plan has any unsaved changes.
  const planHasUnsavedChanges = useMemo(() => {
    const lastSavePlan = PlanSetUtils.planSetGetPlan(lastSavePlans, plan.id);
    return lastSavePlan !== null && !PlanUtils.plansShallowEqual(plan, lastSavePlan);
  }, [lastSavePlans, plan]);

  // Save plan on mount if it has unsaved changes and isn't already saving
  useEffect(() => {
    if (planHasUnsavedChanges && !savingPlan) {
      dispatch(CanvasActionCreators.savePlan(plan));
    }
  }, []);

  const validDimesions = plan.width < MAXIMUM_PLAN_DIMENSIONS && plan.height < MAXIMUM_PLAN_DIMENSIONS;

  const content = useMemo(() => {
    if (validDimesions) {
      return (
        <>
          <PublishPlanIntroduction planPublished={plan.published} />
          <ModalPaneContainer className='publish-configuration'>
            <PublishPlanPageInformation
              location={formValues.values.location}
              setLocation={(value) => setValue('location', value)}
              description={formValues.values.description}
              setDescription={(value) => setValue('description', value)}
            />
            <PublishPlanOptions values={formValues} setValue={setValue} />
          </ModalPaneContainer>
        </>
      );
    }
    return (
      <ModalPaneContainer className='publish-not-possible'>
        <ModalPane>
          <ModalPaneSectionGroup>
            <ModalPaneSection>
              <ModalPaneSectionHeader>Publish Plan</ModalPaneSectionHeader>
              <ModalPaneSectionContent>
                <p>
                  This plan is too large to publish. Due to technical limitations it is not possible to publish plans with a width or height greater than
                  {getPlanSizeLimitString(plan)}.
                </p>
              </ModalPaneSectionContent>
            </ModalPaneSection>
          </ModalPaneSectionGroup>
        </ModalPane>
      </ModalPaneContainer>
    );
  }, [validDimesions, formValues, plan]);

  return (
    <>
      <PublishPlanInfo plan={plan} />
      {content}
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-secondary' onClick={closeModal}>
              Cancel
            </button>
          </ModalFooterButtonsSection>
          <ModalFooterButtonsSection>
            <LoadingButton
              loading={savingPlan}
              className='button button-primary'
              disabled={!formValues.isValid || !validDimesions || planHasUnsavedChanges}
              onClick={onPublishPlan}
            >
              Publish
            </LoadingButton>
          </ModalFooterButtonsSection>
        </ModalFooterButtons>
      </ModalFooter>
    </>
  );
};

export default PublishModalStage1;
