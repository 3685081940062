import { generatedColors, heritageColors, textures } from '@gi/swatch-data';
import Palettes from './palettes';
import objectToPalette from './parsers/object-to-palette';

const generatedColorPalette = objectToPalette('', 18, generatedColors);
const heritageColorPalette = objectToPalette('Heritage Colors', 12, heritageColors);
const texturePalette = objectToPalette('Solid Shape Fill Textures', 10, textures);

const withTexturesPalettes = new Palettes([generatedColorPalette, heritageColorPalette, texturePalette]);
const colorPalettes = new Palettes([generatedColorPalette, heritageColorPalette]);

export { generatedColorPalette, heritageColorPalette, texturePalette, withTexturesPalettes, colorPalettes };
