import React, { useContext } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import GardenGuruContext from '../../context/garden-guru-context';
import ParallaxBackground from '../../components/parallax-background/parallax-background';

import styles from './header-background.module.css';

const HeaderBackground = (): JSX.Element => {
  const { header } = useContext(GardenGuruContext);

  return (
    <TransitionGroup component='div' className={styles.backgroundContainer}>
      <CSSTransition
        key={header.background}
        timeout={500}
        classNames={{
          enter: styles.transitionEnter,
          enterActive: styles.transitionEnterActive,
          exit: styles.transitionLeave,
          exitActive: styles.transitionLeaveActive,
          exitDone: styles.transitionLeaveDone,
        }}
        onExit={(node) => {
          // On exit, store our current opacity so that it doesn't reset to 1 and flash.
          // This will still cause flashes when transitioning quickly, as the opacity of all the
          // background images will sum to < 1, meaning the background colour will flash through.
          node.style.setProperty('--opacity', window.getComputedStyle(node).opacity);
        }}
      >
        <ParallaxBackground
          className={styles.background}
          style={{
            backgroundImage: typeof header.background === 'string' ? `url('${header.background}')` : undefined,
            backgroundColor: typeof header.background === 'number' ? `#${header.background.toString(16).padStart(6, '0')}` : undefined,
          }}
        />
      </CSSTransition>
    </TransitionGroup>
  );
};

export default HeaderBackground;
