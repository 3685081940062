import React, { useCallback, useContext, useEffect, useState } from 'react';

import { DistanceUnits } from '@gi/units';
import { Geometry, MathUtils } from '@gi/math';
import { PlanText, PlanValidation } from '@gi/plan';
import { FormValues, createFormValues } from '@gi/form-responsive';

import {
  ModalPane,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalHeaderContent,
  ModalHeaderTitle,
  AttemptToLeaveCallback,
  ConfirmLeaveContextResult,
  ModalContext,
  ModalPaneContent,
} from '@gi/modal';

import { TextState } from './types';
import EditTextModalForm from './forms/edit-text-modal-form';
import { getSelectedSwatchFromText } from './utils';

interface iProps {
  planText: PlanText;
  distanceUnits: DistanceUnits;
  onComplete: (planText: PlanText) => void;
}

const EditTextModalContent = ({ planText, distanceUnits, onComplete }: iProps): JSX.Element => {
  const { setCloseContextParams, attemptClose, close } = useContext(ModalContext);

  const [formValues, setFormValues] = useState<FormValues<TextState>>(
    createFormValues({
      center: { value: Geometry.midpoint(planText.start, planText.end) },
      width: { value: Math.abs(planText.end.x - planText.start.x) },
      height: { value: Math.abs(planText.end.y - planText.start.y) },
      rotation: { value: Math.round(planText.rotation * (180 / Math.PI)) },
      selectedSwatch: { value: getSelectedSwatchFromText(planText) },
      fontSize: { value: planText.fontSize },
      text: { value: planText.text },
    })
  );

  /**
   * Submits the changes back outside the modal
   */
  const onSaveChanges = useCallback(() => {
    const validatedData = PlanValidation.validateText(
      formValues.values.center,
      formValues.values.width,
      formValues.values.height,
      MathUtils.degToRad(formValues.values.rotation),
      parseInt(formValues.values.selectedSwatch.value, 16),
      formValues.values.fontSize
    );

    const updatedPlanText = {
      ...planText,
      start: validatedData.start,
      end: validatedData.end,
      rotation: validatedData.rotation,
      fill: validatedData.color,
      text: formValues.values.text,
      fontSize: validatedData.fontSize,
    };

    onComplete(updatedPlanText);
  }, [onComplete, formValues, planText]);

  /**
   * Prevent navigating away with unsaved changes
   */
  useEffect(() => {
    const createLeaveCallback = (): AttemptToLeaveCallback => {
      return (cb) => {
        return cb(formValues.hasDifferences).then((result) => {
          if (result === ConfirmLeaveContextResult.SaveAndClose) {
            onSaveChanges();
          }

          return result !== ConfirmLeaveContextResult.Cancel;
        });
      };
    };

    const invalidMessage = formValues.isValid ? undefined : 'Some inputs have invalid values, please correct them before saving';

    setCloseContextParams({
      title: 'Save Changes?',
      text: 'Do you wish to save your changes to this Text?',
      callback: createLeaveCallback(),
      invalidMessage,
    });

    return () => {
      setCloseContextParams(null);
    };
  }, [formValues.hasDifferences, formValues.isValid, onSaveChanges, setCloseContextParams]);

  return (
    <ModalContent>
      <ModalCloseButton onClick={attemptClose} />
      <ModalHeader>
        <ModalHeaderContent>
          <ModalHeaderTitle>Edit Text</ModalHeaderTitle>
        </ModalHeaderContent>
      </ModalHeader>
      <ModalPaneContainer>
        <ModalPane>
          <ModalPaneContent>
            <EditTextModalForm values={formValues} setValues={setFormValues} distanceUnits={distanceUnits} />
          </ModalPaneContent>
        </ModalPane>
      </ModalPaneContainer>
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-secondary' onClick={close}>
              Cancel
            </button>
          </ModalFooterButtonsSection>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-primary' onClick={onSaveChanges}>
              Done
            </button>
          </ModalFooterButtonsSection>
        </ModalFooterButtons>
      </ModalFooter>
    </ModalContent>
  );
};

export default EditTextModalContent;
