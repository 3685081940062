import {
  NineSliceSpriteLocation,
  NineSliceSpriteSource,
  SpriteSource,
  createNineSliceSpriteSource,
  createPathSpriteSource,
  createSpriteSource,
} from '@gi/sprite-source';
import { LayerTypes, GardenObjectType } from '@gi/constants';

import { type GardenObject } from './garden-object';
import { type GardenObjectSegment } from './garden-object-segment';
import { type GardenObjectBlock } from './garden-object-block';
import { GardenObjectFromAPI, GardenObjectGroupFromAPI } from './types';
import { GardenObjectLayerType, GardenObjectScalingMode } from './enums';
import { GardenObjectGroup } from './garden-object-group';

function parseGardenObjectBlock(obj: GardenObjectFromAPI): GardenObjectBlock {
  if (obj.useLineBehaviour) {
    throw new Error('Attempting to create block garden object from path garden object data');
  }

  let sprite: NineSliceSpriteSource | SpriteSource | null = null;
  const textureName = obj.textureName ?? obj.objectCode;

  if (obj.useNineSlice) {
    sprite = createNineSliceSpriteSource({
      marginWidth: Math.round(obj.nineSliceMarginWidth),
      marginHeight: Math.round(obj.nineSliceMarginHeight),
      spriteWidth: Math.round(obj.nineSliceSpriteWidth),
      spriteHeight: Math.round(obj.nineSliceSpriteHeight),
      names: {
        [NineSliceSpriteLocation.M]: obj.nineSliceNoMiddle ? null : `${textureName}`,
        [NineSliceSpriteLocation.B]: `${textureName}_B`,
        [NineSliceSpriteLocation.BL]: `${textureName}_BL`,
        [NineSliceSpriteLocation.BR]: `${textureName}_BR`,
        [NineSliceSpriteLocation.L]: `${textureName}_L`,
        [NineSliceSpriteLocation.R]: `${textureName}_R`,
        [NineSliceSpriteLocation.T]: `${textureName}_T`,
        [NineSliceSpriteLocation.TR]: `${textureName}_TR`,
        [NineSliceSpriteLocation.TL]: `${textureName}_TL`,
      },
    });
  } else {
    sprite = createSpriteSource({
      name: `${textureName}`,
      width: Math.round(obj.initialWidth),
      height: Math.round(obj.initialHeight),
    });
  }

  const scalingMode =
    obj.presets !== undefined && obj.presets.length > 0
      ? GardenObjectScalingMode.PRESETS
      : obj.minHeight === 0 && obj.minWidth === 0 && obj.maxHeight === 0 && obj.maxWidth === 0
        ? GardenObjectScalingMode.FIXED
        : GardenObjectScalingMode.SCALING;

  const gardenObject: GardenObjectBlock = {
    type: GardenObjectType.BLOCK,
    canScale: obj.canScale || obj.useNineSlice,
    canRotate: obj.canRotate,
    constrainProportions: obj.constrainProportions,
    constrainHeight: obj.constrainHeight,
    constrainWidth: obj.constrainWidth,
    initialWidth: Math.round(obj.initialWidth),
    initialHeight: Math.round(obj.initialHeight),
    minWidth: Math.round(obj.minWidth),
    minHeight: Math.round(obj.minHeight),
    maxWidth: Math.round(obj.maxWidth),
    maxHeight: Math.round(obj.maxHeight),
    internalWidthMargin: obj.internalWidthMargin,
    internalHeightMargin: obj.internalHeightMargin,
    sprite,
    scalingMode,
    presets: obj.presets,
  };

  return gardenObject;
}

function parseGardenObjectPath(obj: GardenObjectFromAPI): GardenObjectSegment {
  if (!obj.useLineBehaviour) {
    throw new Error('Attempting to create path garden object from block garden object data');
  }

  const filenames: string[] = [];
  const textureName = obj.textureName ?? obj.objectCode;

  if (obj.noOfIcons === 1) {
    filenames[0] = textureName;
  } else {
    for (let i = 0; i < obj.noOfIcons; i++) {
      filenames[i] = `${textureName}_${i + 1}`;
    }
  }

  const sprite = createPathSpriteSource({
    names: [...filenames],
    height: Math.round(obj.initialHeight),
    width: Math.round(obj.initialWidth / obj.noOfIcons),
  });

  const gardenObject: GardenObjectSegment = {
    type: GardenObjectType.PATH,
    keepStraight: obj.keepStraight,
    initialWidth: Math.round(obj.initialWidth / obj.noOfIcons) * obj.noOfIcons,
    sprite,
  };

  return gardenObject;
}

/**
 * Covers a garden object from the API into a garden object
 * @param obj The object, as returned in JSON from the API
 * @returns A parsed garden object
 */
function gardenObjectFromAPI(obj: GardenObjectFromAPI): GardenObject {
  const shape = obj.useLineBehaviour ? parseGardenObjectPath(obj) : parseGardenObjectBlock(obj);

  let layer: GardenObjectLayerType = LayerTypes.LAYOUT;

  if (obj.isIrrigation) {
    layer = LayerTypes.IRRIGATION;
  } else if (obj.isStructure) {
    layer = LayerTypes.STRUCTURES;
  }

  const gardenObject: GardenObject = {
    code: obj.objectCode,
    name: obj.objectName,
    otherNames: obj.otherNames,
    countryCode: obj.countryCode,
    descriptionHTML: obj.descriptionHTML,
    imageURL: obj.imageURL,
    groupId: obj.objectGroup,
    shape,
    layerType: layer,
    useLengthQuantity: obj.useLengthQuantity,
    useAreaQuantity: obj.useAreaQuantity,
    showInPartsList: obj.includeInPartsList,
    plantModifier: obj.plantVarietyModifier === '' ? null : obj.plantVarietyModifier,
    sortName: obj.objectSortName ?? obj.objectName,
    shortName: obj.objectShortName,
    clientRestrictions: obj.clientRestrictions,
  };

  return gardenObject;
}

function gardenObjectGroupFromAPI(obj: GardenObjectGroupFromAPI): GardenObjectGroup {
  return {
    id: obj.id,
    displayName: obj.displayName,
    objectCodes: [],
    iconCode: obj.iconCode,
    variantText: obj.variantText,
  };
}

const GardenObjectParserUtils = {
  gardenObjectFromAPI,
  gardenObjectGroupFromAPI,
};

export default GardenObjectParserUtils;
