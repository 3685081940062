import {
  IRenderer,
  autoDetectRenderer,
  Container,
  RENDERER_TYPE,
  extensions,
  ENV,
  settings,
  ICanvas,
  detectCompressedTextures,
  IRenderingContext,
} from 'pixi.js-new';

import { RenderMode } from '@gi/constants';
import { StateManager } from '@gi/state';
import { avoidWebGL } from '@gi/browser';

import GraphicNode from './graphics-node';
import { PerformanceData as PerformanceDataType, TimeData } from './types';
import EventBus from './event-bus';
import AssetManager from './managers/assets/asset-manager';
import InteractionManager from './managers/interaction/interaction-manager';
import Node from './node';
import NodeComponent from './node-component/node-component';
import type ContentRootNode from './nodes/content-root/content-root-node';
import { TilingSpriteRenderer } from './extensions/tiling-sprite/TilingSpriteRenderer';
import ShadedSpriteRenderer from './extensions/shaded-sprite/ShadedSpriteRenderer';

/** We don't use any compressed texture formats, and this extension errors on texture load in some circumstances. */
extensions.remove(detectCompressedTextures);
extensions.add(TilingSpriteRenderer);
extensions.add(ShadedSpriteRenderer);

export enum EngineEvent {
  Resize = 'Resize',
  Tick = 'Tick',
  Draw = 'Draw',
  PerformanceData = 'PerformanceData',
  NodeAdded = 'NodeAdded',
  NodeRemoved = 'NodeRemoved',
  RendererChanged = 'RendererChanged',
  RendererRestored = 'RendererRestored',
}

export type EngineEventActions = {
  [EngineEvent.Resize]: (width: number, height: number) => void;
  [EngineEvent.Tick]: (timeData: TimeData) => void;
  [EngineEvent.Draw]: (timeData: TimeData) => void;
  [EngineEvent.PerformanceData]: (data: PerformanceDataType) => void;
  [EngineEvent.NodeAdded]: (node: Node) => void;
  [EngineEvent.NodeRemoved]: (node: Node) => void;
  [EngineEvent.RendererChanged]: (renderer: IRenderer) => void;
  [EngineEvent.RendererRestored]: (renderer: IRenderer) => void;
};

class Engine {
  #running: boolean = false;
  get running() {
    return this.#running;
  }

  #animating: boolean = false;
  get animating() {
    return this.#animating;
  }

  #renderMode: RenderMode | null;
  get renderMode() {
    return this.#renderMode;
  }

  // HTML Node which contains the canvas
  #container: HTMLElement | null = null;
  get container() {
    return this.#container;
  }

  #desiredWidth: number = 500;
  #desiredHeight: number = 500;

  #localTime: number = 0;
  #lastTick: number | null = null;

  #frameTimeFrameCount: number = 20;
  #frameTimeFrameIndex: number = 0;
  #frameTimes: number[] = [];

  readonly eventBus: EventBus<EngineEventActions> = new EventBus();

  readonly assetManager: AssetManager = new AssetManager();
  readonly interactionManager: InteractionManager = new InteractionManager();
  readonly stateManager: StateManager = new StateManager(() => this.flagHasUpdates());

  // Pixi.JS renderer
  #renderer: IRenderer;

  readonly root: GraphicNode;
  readonly rootGraphic: Container;

  #contentRoot: ContentRootNode | null;
  get contentRoot() {
    return this.#contentRoot;
  }

  nodeDirectory: Record<string, Node> = {};
  componentDirectory: Record<string, NodeComponent> = {};
  animationFrameRequested: boolean = false;

  constructor(renderMode: RenderMode = RenderMode.AUTO) {
    this.#renderer = this.#makePrimaryRenderer(this.#createRenderer(renderMode));

    this.root = new Node();
    this.root.makeRootNode(this);

    this.rootGraphic = new Container();
    this.rootGraphic.eventMode = 'static';
    this.rootGraphic.addChild(this.root.getContainer());

    this.interactionManager.rootDisplayObject = this.rootGraphic;

    this.#contentRoot = null;
  }

  /**
   * Creates a renderer using the given render mode.
   * @param renderMode The render mode for the renderer to use
   * @returns The created renderer.
   */
  #createRenderer(renderMode: RenderMode) {
    switch (renderMode) {
      case RenderMode.WEBGL_2:
        settings.PREFER_ENV = ENV.WEBGL2;
        break;
      case RenderMode.WEBGL_1:
        settings.PREFER_ENV = ENV.WEBGL;
        break;
      case RenderMode.WEBGL_LEGACY:
        settings.PREFER_ENV = ENV.WEBGL_LEGACY;
        break;
      default:
        settings.PREFER_ENV = ENV.WEBGL2;
    }

    return autoDetectRenderer({
      width: this.#desiredWidth,
      height: this.#desiredHeight,
      antialias: true,
      resolution: window.devicePixelRatio,
      autoDensity: true,
      background: 0xdddddd, // { r: Math.random() * 255, g: Math.random() * 255, b: Math.random() * 255 },
      forceCanvas: renderMode === RenderMode.CANVAS || (renderMode === RenderMode.AUTO && avoidWebGL),
    });
  }

  /**
   * Makes the given renderer the main renderer (the one the user interacts with)
   * @param renderer The renderer
   * @returns The same renderer
   */
  #makePrimaryRenderer(renderer: IRenderer<ICanvas>) {
    const view = renderer.view as HTMLCanvasElement;
    // Using display: block prevents the resize observer infinate-looping when inline inevitably produces mismatching heights
    view.style.display = 'block';

    this.interactionManager.container = view;

    renderer.on('resize', () => {
      this.eventBus.emit(EngineEvent.Resize, this.width, this.height);
    });

    renderer.runners.contextChange.add(this);

    this.#renderMode = this.#getRenderMode(renderer);
    this.#renderer = renderer;

    return renderer;
  }

  /**
   * Finds the render mode the given renderer is currently using.
   * @param renderer The renderer in use
   * @returns An enum for the render mode, or null if unknown.
   */
  // eslint-disable-next-line class-methods-use-this
  #getRenderMode(renderer: IRenderer): RenderMode | null {
    if (renderer.type === RENDERER_TYPE.CANVAS) {
      return RenderMode.CANVAS;
    }
    if (renderer.type === RENDERER_TYPE.WEBGL) {
      try {
        if ('gl' in renderer) {
          if (renderer.gl instanceof WebGLRenderingContext) {
            return RenderMode.WEBGL_1;
          }
          if (renderer.gl instanceof WebGL2RenderingContext) {
            return RenderMode.WEBGL_2;
          }
        }
        return RenderMode.WEBGL_LEGACY;
      } catch (e) {
        return RenderMode.WEBGL_LEGACY;
      }
    }
    return null;
  }

  /**
   * Sets the render mode of the primary renderer for the engine.
   * This will create a new renderer behind-the-scenes, which should seamlessly replace the old one.
   * @param renderMode The desired render mode to use
   */
  setRenderMode(renderMode: RenderMode) {
    if (this.container) {
      this.container.removeChild(this.renderer.view as HTMLCanvasElement);
    }

    this.renderer.destroy();
    this.renderer = this.#makePrimaryRenderer(this.#createRenderer(renderMode));

    if (this.container) {
      this.container.appendChild(this.renderer.view as HTMLCanvasElement);
    }
  }

  /**
   * Sets the element which should contain the canvas that's being rendered to.
   * @param container The HTML element
   */
  setContainer(container: HTMLElement | null): void {
    if (this.#container !== null) {
      try {
        this.#container.removeChild(this.renderer.view as HTMLCanvasElement);
        this.#container.oncontextmenu = null;
      } catch (e) {
        console.error('Error removing renderer view from child');
        console.error(e);
      }
    }

    this.#container = container;

    if (this.#container !== null) {
      this.#container.appendChild(this.renderer.view as HTMLCanvasElement);

      // Stop right click context menus from appearing in the renderer
      this.#container.oncontextmenu = (e) => {
        e.preventDefault();
      };
    }
  }

  /**
   * Sets the renderer for this engine.
   * Will emit an event so rednertextures can be regenerated
   *  (render textures seem to be bound to the renderer they were rendered with for whatever reason)
   * @param renderer The renderer to use
   */
  set renderer(renderer: IRenderer) {
    this.#renderer = renderer;
    this.eventBus.emit(EngineEvent.RendererChanged, renderer);
  }
  get renderer() {
    return this.#renderer;
  }

  get canvas(): HTMLCanvasElement {
    return this.#renderer.view as HTMLCanvasElement;
  }

  resize(width: number, height: number) {
    this.#desiredWidth = width;
    this.#desiredHeight = height;
    this.renderer.resize(width, height);
    this.#frame(); // Immediately render to prevent flicker (TODO: Should tick?)
  }

  get width() {
    return this.renderer.width / this.renderer.resolution;
  }

  get height() {
    return this.renderer.height / this.renderer.resolution;
  }

  getRoot(): GraphicNode {
    return this.root;
  }

  getContentRoot(): ContentRootNode | null {
    return this.contentRoot;
  }

  /**
   * Sets the content root node - the node that contains all the content for the renderer to render.
   * @param contentRoot The root node to display
   */
  setContentRoot<T extends ContentRootNode>(contentRoot: T | null) {
    if (this.#contentRoot) {
      this.#contentRoot.remove();
    }

    this.#contentRoot = contentRoot;

    if (this.#contentRoot) {
      this.root.addChildren(this.#contentRoot);
    }
  }

  /**
   * Converts a screen position to instead be relative to the top-left of the renderer's canvas.
   * @param pos The position to convert
   * @returns An adjusted position, relative to the top-left of the canvas
   */
  convertToLocalPos(pos: Vector2): Vector2 {
    const bounds = (this.renderer.view as HTMLCanvasElement).getBoundingClientRect();
    return {
      x: pos.x - bounds.x,
      y: pos.y - bounds.y,
    };
  }

  setRunning(running: boolean) {
    if (running === this.#running) {
      return;
    }

    this.#running = running;

    if (this.#running) {
      requestAnimationFrame(this.#frame);
    }
  }

  /**
   * Controls whether the engine renders should be called automatically via `requestAnimationFrame`
   *
   * If set to true, animating will start if running is also true
   */
  setAnimating(animate: boolean) {
    if (animate === this.#animating) {
      return;
    }

    this.#animating = animate;

    if (this.shouldAnimate()) {
      this.requestNextAnimationFrame();
    }
  }

  private shouldAnimate(): boolean {
    return this.#animating && this.#running;
  }

  /**
   * Requests an animation frame callback to run the `animationFrameLoop`
   *
   * Does not check if should animate before calling
   */
  private requestNextAnimationFrame() {
    requestAnimationFrame(this.animationFrameLoop);
  }

  /**
   * A single run of an animation frame
   *
   * Calls the frame method and then requests another call of
   * itself via `requestAnimationFrame` (through `requestNextAnimationFrame`)
   *
   * Will not call either if `shouldAnimate` returns false (i.e. animation or running is false)
   */
  private animationFrameLoop = () => {
    if (this.shouldAnimate()) {
      this.#frame();
      this.requestNextAnimationFrame();
    }
  };

  start(animate?: boolean) {
    this.setRunning(true);
    if (animate !== undefined) {
      this.setAnimating(animate);
    } else if (this.animating) {
      this.requestNextAnimationFrame();
    }
  }

  stop() {
    this.setRunning(false);
  }

  registerComponent<T extends NodeComponent<any>>(component: T) {
    this.componentDirectory[component.uuid] = component;
  }

  unregisterComponent<T extends NodeComponent<any>>(component: T) {
    delete this.componentDirectory[component.uuid];
  }

  getComponent<T extends NodeComponent>(uuid: string): T | null {
    const node = this.componentDirectory[uuid];
    return (node as T) ?? null;
  }

  registerNode(node: Node) {
    this.nodeDirectory[node.uuid] = node;
    this.eventBus.emit(EngineEvent.NodeAdded, node);
  }

  unregisterNode(node: Node) {
    delete this.nodeDirectory[node.uuid];
    this.eventBus.emit(EngineEvent.NodeRemoved, node);
  }

  getNode<T extends Node>(uuid: string): T | null {
    const node = this.nodeDirectory[uuid];
    return (node as T) ?? null;
  }

  nextFrame() {
    if (!this.#animating) {
      requestAnimationFrame(this.#frame);
    }
  }

  #frame = () => {
    // if (!this.#running) {
    //   return;
    // }

    this.animationFrameRequested = false;

    const now = Date.now();
    const accurateNow = performance.now();

    const deltaTime = this.#lastTick === null ? 0 : now - this.#lastTick;
    this.#localTime += deltaTime;
    this.#lastTick = now;

    this.#frameTimeFrameIndex = (this.#frameTimeFrameIndex + 1) % this.#frameTimeFrameCount;
    this.#frameTimes[this.#frameTimeFrameIndex] = deltaTime;

    const timeData: TimeData = {
      time: now,
      deltaTime,
      localTime: this.#localTime,
    };

    // Run updates
    this.eventBus.emit(EngineEvent.Tick, timeData);
    const postTick = performance.now();

    this.stateManager.update();
    const postState = performance.now();

    this.eventBus.emit(EngineEvent.Draw, timeData);

    // Render the scene
    this.renderer.render(this.rootGraphic);
    const postDraw = performance.now();

    const data: PerformanceDataType = {
      startAt: accurateNow,
      endAt: postDraw,
      drawDuration: postDraw - postState,
      stateDuration: postState - postTick,
      tickDuration: postTick - accurateNow,
      duration: postDraw - accurateNow,
    };

    this.eventBus.emit(EngineEvent.PerformanceData, data);
  };

  renderToCanvas(context: CanvasDrawImage, destX: number, destY: number);
  renderToCanvas(context: CanvasDrawImage, destX: number, destY: number, destWidth: number, destHeight: number);
  renderToCanvas(
    context: CanvasDrawImage,
    destX: number,
    destY: number,
    destWidth: number,
    destHeight: number,
    sourceX: number,
    sourceY: number,
    sourceWidth: number,
    sourceHeight: number
  );
  renderToCanvas(
    context: CanvasDrawImage,
    destX: number,
    destY: number,
    destWidth?: number,
    destHeight?: number,
    sourceX?: number,
    sourceY?: number,
    sourceWidth?: number,
    sourceHeight?: number
  ) {
    this.#frame();
    // Side-note: Why does the offical drawImage API randomly change the parameter order if you want to specify source coordinates?
    //  I'm not perpetuating this madness.
    if (destWidth !== undefined && destHeight !== undefined) {
      if (sourceX !== undefined && sourceY !== undefined && sourceWidth !== undefined && sourceHeight !== undefined) {
        context.drawImage(this.renderer.view as HTMLCanvasElement, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);
      } else {
        context.drawImage(this.renderer.view as HTMLCanvasElement, destX, destY, destWidth, destHeight);
      }
    } else {
      context.drawImage(this.renderer.view as HTMLCanvasElement, destX, destY);
    }
  }

  getFPS() {
    if (this.#frameTimes.length === 0) {
      return 0;
    }

    return 1000 / (this.#frameTimes.reduce((prev, curr) => prev + curr) / this.#frameTimes.length);
  }

  /**
   * Flags to the engine that something has updated, and a render is needed.
   * The render will happen at the next available animation frame.
   */
  flagHasUpdates() {
    if (!this.animationFrameRequested && !this.#animating && this.#running) {
      requestAnimationFrame(this.#frame);
      this.animationFrameRequested = true;
    }
  }

  /**
   * Callback for when the pixi renderer detects that the WebGLRenderingContext has changed (likely crashed and restored)
   * This function must be called `contextChange`, as we add the `Engine` instance to `this.#renderer.runners.contextChange`.
   * The `contextChange` runner will attempt to invoke any `contextChange` function on all objects that have been added to it.
   * It's acts like an event system, but apparently more performant
   * @param gl The new rendering context from the Renderer
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  contextChange = (_gl: IRenderingContext) => {
    // this.eventBus.emit(EngineEvent.RendererRestored, this.#renderer);
    this.flagHasUpdates();
  };
}

export default Engine;
