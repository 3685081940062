import React, { CSSProperties, MouseEvent, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import { Direction } from '@gi/constants';
import { DelayedUnmount, useSwipeDismiss } from '@gi/react-utils';

import './drawer.scss';

const Anchors = ['TOP', 'LEFT', 'BOTTOM', 'RIGHT'] as const;
export type Anchor = (typeof Anchors)[number];

const anchorToDirectionMapping: { [K in Anchor]: Direction } = {
  TOP: Direction.UP,
  BOTTOM: Direction.DOWN,
  LEFT: Direction.LEFT,
  RIGHT: Direction.RIGHT,
};

interface iProps {
  children: ReactNode;
  className?: string;
  open: boolean;
  onClose: () => void;
  anchor?: Anchor;
  width?: number;
  height?: number;
  padding?: number;
  borderRadius?: number;
  inline?: boolean;
  preventSwipeDismiss?: boolean;
}

const Drawer = ({
  children,
  className,
  open,
  onClose,
  anchor = 'BOTTOM',
  width,
  height,
  padding = 18,
  borderRadius = 12,
  inline = false,
  preventSwipeDismiss = false,
}: iProps): JSX.Element => {
  const { ref, cancel: cancelSwipe } = useSwipeDismiss(anchorToDirectionMapping[anchor], {
    onDismiss: () => {
      onClose();
    },
    disabled: preventSwipeDismiss,
  });

  const handleBackgroundClick = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      cancelSwipe();
      onClose();
    }
  };

  const classNames = ['drawer-background'];
  classNames.push(`anchor-${anchor.toLowerCase()}`);
  if (open) {
    classNames.push('open');
  }
  if (inline) {
    classNames.push('inline');
  }
  if (className) {
    classNames.push(className);
  }

  const styles = {
    '--drawer-width': width ? `${width}px` : undefined,
    '--drawer-height': height ? `${height}px` : undefined,
    '--drawer-padding': `${padding}px`,
    '--drawer-border-radius': `${borderRadius}px`,
  } as CSSProperties;

  const component = (
    <DelayedUnmount isMounted={open}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={classNames.join(' ')} style={styles} onClick={handleBackgroundClick}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div className='drawer-container' onClick={handleBackgroundClick}>
          <div className='drawer' ref={ref}>
            {children}
          </div>
        </div>
      </div>
    </DelayedUnmount>
  );

  if (inline) {
    return component;
  }

  return createPortal(component, document.getElementById('modal-root')!);
};

export default Drawer;
