import React, { forwardRef } from 'react';

import styles from './top-menu-button.module.css';

interface iProps extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
}

const TopMenuButton = forwardRef<HTMLButtonElement, iProps>(({ children, ...props }: iProps, ref): JSX.Element => {
  return (
    <button {...props} type='button' className={`${styles.topMenuButton} ${props.className}`} ref={ref}>
      {children}
    </button>
  );
});

TopMenuButton.displayName = 'TopMenuButton';

export default TopMenuButton;
