import { networkErrorReporter, networkService } from '@gi/gi-network';
import { templatePlanFromAPI } from '../parser';

import { attachGetTemplatePlansErrorClientMessage } from './template-plan-errors';
import { TemplatePlan, TemplatePlanFromAPI } from '../template-plan';

// import { TemplatePlan, TemplatePlanFromAPI } from '../template-plan';
// import exampleTemplateResponse from './example-template-resplonse.json';

class TemplatePlanService {
  endpoint: string = '';

  constructor({ endpoint }) {
    this.endpoint = endpoint;
  }

  /**
   * Resolves with an array of tmeplate plans sorted by their display index
   */
  loadTemplatePlans(timeout?: number): Promise<TemplatePlan[]> {
    const path = '/plantemplates';
    const url = `${this.endpoint}${path}`;

    return networkService
      .get<TemplatePlanFromAPI[]>(url, {}, undefined, { timeout })
      .then((response) => response.body.map(templatePlanFromAPI).sort((a: TemplatePlan, b: TemplatePlan) => a.displayIndex - b.displayIndex))
      .catch(networkErrorReporter('GET', 'template plans'))
      .catch((requestError) => {
        attachGetTemplatePlansErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Resolves with an array of tmeplate plans sorted by their display index
   */
  // eslint-disable-next-line class-methods-use-this
  // loadTemplatePlans(userID: number, postAuthTicket: string): Promise<TemplatePlan[]> {
  //   return new Promise((resolve) => {
  //     resolve(((exampleTemplateResponse as Array<TemplatePlanFromAPI>)).map(templatePlanFromAPI).sort((a: TemplatePlan, b: TemplatePlan) => (a.displayIndex - b.displayIndex)));
  //   });
  // }
}

export default TemplatePlanService;
