import Collection from '@gi/collection';
import GardenObject from '@gi/garden-object';
import { PlanGardenObject } from '@gi/plan';

import PartListGardenObjectData from './part-list-garden-object-data';

class PartListTableData {
  gardenObjects: PartListGardenObjectData[] = [];
  gardenObjectsByCode: Record<string, PartListGardenObjectData> = {};
  hasLengthItems: boolean = false;

  addPlanGardenObject(planGardenObject: PlanGardenObject, gardenObject: GardenObject) {
    if (!Object.hasOwnProperty.call(this.gardenObjectsByCode, gardenObject.code)) {
      const newPartListGardenObjectData = new PartListGardenObjectData(gardenObject);
      this.gardenObjects.push(newPartListGardenObjectData);
      this.gardenObjectsByCode[gardenObject.code] = newPartListGardenObjectData;
    }

    this.gardenObjectsByCode[gardenObject.code].addQuantity(planGardenObject);

    if (gardenObject.useLengthQuantity || gardenObject.useAreaQuantity) {
      this.hasLengthItems = true;
    }
  }

  count() {
    return this.gardenObjects.length;
  }

  static createFromGardenObjectList = (planGardenObjects: PlanGardenObject[], gardenObjectCollection: Collection<GardenObject> | null) => {
    const partListTableData = new PartListTableData();

    if (!gardenObjectCollection) {
      return partListTableData;
    }

    for (let i = 0; i < planGardenObjects.length; i++) {
      const planGardenObject = planGardenObjects[i];
      const gardenObject = gardenObjectCollection.get(planGardenObject.code!);

      if (gardenObject === null) {
        console.warn(`Garden Object ${planGardenObject.code} not found in garden object collection`);
      } else if (gardenObject.showInPartsList) {
        partListTableData.addPlanGardenObject(planGardenObject, gardenObject);
      }
    }

    return partListTableData;
  };
}

export default PartListTableData;
