import { useContext, useMemo } from 'react';

import Plant from '@gi/plant';
import { ResourceContext } from '@gi/resource-provider';
import { unique } from '@gi/utils';

interface iPlantCompanions {
  companionOfPlants: Plant[];
  companionPlants: Plant[];
  mutualBenefitPlants: Plant[];
}

/**
 * A React hook which returns a plants companions, grouped by their type
 */
export function useCompanions(plant: Plant): iPlantCompanions {
  const { getPlant, userPlants } = useContext(ResourceContext);

  const codesToUniqueSortedPlants = (codes) =>
    codes
      .filter(unique)
      .map((plantCode) => getPlant(plantCode))
      .filter((companionPlant) => companionPlant !== null)
      .sort((plantA, plantB) => plantA.name.localeCompare(plantB.name));

  const companionOfCodes: string[] = useMemo(() => {
    return userPlants.itemList.filter((p) => p.companionPlantCodes.indexOf(plant.code) > -1).map((p) => p.code);
  }, [plant, userPlants]);

  const mutualBenefitCodes: string[] = useMemo(() => {
    return plant.companionPlantCodes.filter((code) => companionOfCodes.includes(code));
  }, [plant, companionOfCodes]);

  const companionOfPlants: Plant[] = useMemo(() => {
    return codesToUniqueSortedPlants(companionOfCodes.filter((code) => !mutualBenefitCodes.includes(code)));
  }, [companionOfCodes, mutualBenefitCodes]);

  const companionPlants: Plant[] = useMemo(() => {
    return codesToUniqueSortedPlants(plant.companionPlantCodes.filter((code) => !mutualBenefitCodes.includes(code)));
  }, [plant, mutualBenefitCodes]);

  const mutualBenefitPlants: Plant[] = useMemo(() => {
    return codesToUniqueSortedPlants(mutualBenefitCodes);
  }, [mutualBenefitCodes]);

  return {
    companionOfPlants,
    companionPlants,
    mutualBenefitPlants,
  };
}
