import React from 'react';
import { PlannerTabTypes } from '@gi/constants';
import DesktopPlannerMenu from './desktop-planner-menu/desktop-planner-menu';
import DesktopPlantListMenu from './desktop-plant-list-menu/desktop-plant-list-menu';
import DesktopPartsListMenu from './desktop-parts-list-menu/desktop-parts-list-menu';
import DesktopNotesMenu from './desktop-notes-menu/desktop-notes-menu';
import DesktopShoppingMenu from './desktop-shopping-menu/desktop-shopping-menu';

interface iProps {
  activePlannerTab: PlannerTabTypes;
}

const DesktopMenu = ({ activePlannerTab }: iProps): JSX.Element | null => {
  switch (activePlannerTab) {
    case PlannerTabTypes.PLANNER:
      return <DesktopPlannerMenu />;
    case PlannerTabTypes.PLANT_LIST:
      return <DesktopPlantListMenu />;
    case PlannerTabTypes.PARTS_LIST:
      return <DesktopPartsListMenu />;
    case PlannerTabTypes.NOTES:
      return <DesktopNotesMenu />;
    case PlannerTabTypes.SHOPPING:
      return <DesktopShoppingMenu />;
    default:
      return null;
  }
};

export default DesktopMenu;
