import NodeComponent from '../../node-component/node-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import type CameraNode from './camera-node';

class CameraContext extends NodeComponent<CameraNode> {
  type = 'CameraContext';

  get camera(): CameraNode {
    return this.owner!;
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'camera',
      propertyType: InspectableClassPropertyType.Node,
    },
  ];
}

export default CameraContext;
