import { CropRotationModes, LayerDisplayModes } from '@gi/constants';

export type PlannerSettings = {
  cropRotationMode: CropRotationModes;
  layer: LayerDisplayModes;
  month: number | null;
  showRulers: boolean;
  showGrid: boolean;
  metric: boolean;
  showBackgroundImages: boolean;
  backgroundImageOpacity: number;
  maintainBackgroundImageAspectRatio: boolean;
};

export const DEFAULT_PLANNER_SETTINGS: Readonly<PlannerSettings> = {
  cropRotationMode: CropRotationModes.AUTOMATIC,
  layer: LayerDisplayModes.ALL,
  month: null,
  showRulers: true,
  showGrid: true,
  metric: true,
  showBackgroundImages: true,
  backgroundImageOpacity: 0.5,
  maintainBackgroundImageAspectRatio: true,
};
