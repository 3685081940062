import React, { forwardRef, useContext } from 'react';

import PlantListTable from './table/plant-list-table';

import GlobalPlantListContext from './global-provider/global-plant-list-context';
import { TableViewMode } from './types/plant-list-types';

import './plant-list.scss';

const PlantList = forwardRef<HTMLDivElement>((_, ref) => {
  const { options } = useContext(GlobalPlantListContext);

  let viewModeClass = 'view-mode-auto';
  if (options.viewMode === TableViewMode.GRID) {
    viewModeClass = 'view-mode-grid';
  } else if (options.viewMode === TableViewMode.TABLE) {
    viewModeClass = 'view-mode-table';
  }
  return (
    <div className={`plant-list-container ${viewModeClass}`}>
      <div className='plant-list-container-inner' ref={ref}>
        <PlantListTable />
      </div>
    </div>
  );
});
PlantList.displayName = 'PlantList';

export default PlantList;
