import React, { useContext } from 'react';
import { DrawingToolsContext } from '../drawing-tools-context';

import SFGHelpModal from './sfg-help-modal';

const SFGHelpModalRenderer = () => {
  const { sfgHelpModalOpen } = useContext(DrawingToolsContext);

  if (sfgHelpModalOpen) {
    return <SFGHelpModal />;
  }

  return null;
};

export default SFGHelpModalRenderer;
