import { PlanValidation } from '@gi/plan';
import { UserPlan } from '@gi/user';

import { Validator } from './types';

/**
 * Plan Validators
 *  Validators that check an aspect of a plan, like its size, or name.
 */

// Form validator for checking whether plan dimensions are within the given bounds
export const isValidDimension = (metricDimensions: boolean): Validator<number> => {
  return (value: number) => {
    if (!PlanValidation.validPlanDimension(value, metricDimensions)) {
      return metricDimensions ? ['Sizes must be between 1m and 300m.'] : ["Sizes must be between 3' 3'' and 1000'."];
    }
    return [];
  };
};

// Form validator for checking whether a plan name is unique for the given year
export const isUniquePlanName = (userPlanList: UserPlan[], year: number, activePlanID?: number): Validator<string> => {
  return (name: string) => {
    if (userPlanList.filter((userPlan) => userPlan.name === name && userPlan.year === year && userPlan.ID !== activePlanID).length > 0) {
      // Plan name is not unique
      return [`A plan with this name for ${year} already exists, plan names must be unique for each year.`];
    }

    return [];
  };
};

// Form validator for checking whether a user has reached the maximum number of plans per year
export const notReachedPlanLimit = (userPlanList: UserPlan[], maxPlansPerYear: number, activePlanID?: number): Validator<number> => {
  return (year: number) => {
    if (userPlanList.filter((userPlan) => userPlan.year === year && userPlan.ID !== activePlanID).length >= maxPlansPerYear) {
      // Already reached plan limit for this year
      return [
        `You have reached your limit of ${maxPlansPerYear} plans for the selected year. Please delete unwanted plans or contact us for details of special accounts.`,
      ];
    }

    return [];
  };
};
