/* eslint-disable max-classes-per-file */
import { StateProperties } from './state-properties';
import stateRegister from './state-register';
import { ReMap } from './state-types';

export type StateObserverCallback<TDeps extends Readonly<StateProperties<any>[]>> = (states: ReMap<TDeps, 'state'>) => void;

/**
 * Observes a set of states, running the callback whenever any of the specified properties change.
 */
export class StateObserver<TDeps extends Readonly<StateProperties<any>[]>> {
  readonly id: number;

  #dependencies: Readonly<TDeps>;
  get dependencies() {
    return this.#dependencies;
  }

  #isLate: boolean;
  /** When true, this observer acts as a watcher. Otherwise, it acts as an updater. */
  get isLate() {
    return this.#isLate;
  }

  #destroyed: boolean = false;
  get destroyed() {
    return this.#destroyed;
  }

  #callback: StateObserverCallback<TDeps>;

  constructor(dependencies: TDeps, isLate: boolean, callback: StateObserverCallback<TDeps>, runImmediately: boolean = true) {
    this.id = stateRegister.registerStateObserver(this);
    this.#dependencies = dependencies;
    this.#isLate = isLate;
    this.#callback = callback;
    this.selfRegister();
    if (runImmediately) {
      this.runCallback();
    }
  }

  onStateUpdate() {
    if (this.destroyed) {
      return;
    }
    // Check if any of our dependencies have seen the update
    let shouldRun = false;
    for (let i = 0; i < this.dependencies.length; i++) {
      const dependency = this.dependencies[i];
      if (dependency && dependency.hasChanged()) {
        shouldRun = true;
        break;
      }
    }
    if (shouldRun) {
      this.runCallback();
    }
  }

  private runCallback() {
    this.#callback(this.#dependencies.map((dependency) => dependency.state) as ReMap<TDeps, 'state'>);
  }

  destroy() {
    stateRegister.unregisterStateObserver(this);
    this.#dependencies.forEach((dependency) => dependency.state.unregisterDependentObserver(this));
    this.#destroyed = true;
  }

  /** Makes this observer register itself with all the states it's dependent on */
  private selfRegister() {
    for (let i = 0; i < this.#dependencies.length; i++) {
      this.#dependencies[i].state.registerDependantObserver(this);
    }
  }
}

/**
 * Utility class - Creates a StateObserver
 *
 * Recommend just using `new StateObserver(dependencies, false, callback)`;
 */
export class StateUpdater<TDeps extends Readonly<StateProperties<any>[]>> extends StateObserver<TDeps> {
  constructor(dependencies: TDeps, callback: StateObserverCallback<TDeps>, runImmediately: boolean = true) {
    super(dependencies, false, callback, runImmediately);
  }
}

/**
 * Utility class - Creates a StateObserver that runs late, acting as a watcher.
 *
 * Recommend just using `new StateObserver(dependencies, true, callback)`
 */
export class StateWatcher<TDeps extends Readonly<StateProperties<any>[]>> extends StateObserver<TDeps> {
  constructor(dependencies: TDeps, callback: StateObserverCallback<TDeps>, runImmediately: boolean = true) {
    super(dependencies, true, callback, runImmediately);
  }
}
