import React from 'react';

import { DefaultMobileTopbar } from '@gi/mobile-garden-platform-nav';
import AppGardenGuru from '@gi/app-guru';

const AppGuruMobile = (): JSX.Element => {
  return (
    <>
      <DefaultMobileTopbar title='Garden Guru' />
      <AppGardenGuru />
    </>
  );
};

export default AppGuruMobile;
