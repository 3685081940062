import { Validator } from './types';

/**
 * Number validators
 *  Any validators that relate to generic numbers
 */

/**
 * Creates a validator that ensures a number is a number and between min and max.
 * @param min The minimum possible value
 * @param max The maximum possible value
 * @returns A validator
 */
export const isBetween = (min: number, max: number): Validator<number> => {
  return (value) => {
    if (Number.isNaN(value)) {
      return [`Must be a number between ${min} and ${max}`];
    }
    if (value < min || value > max) {
      return [`Must be between ${min} and ${max}`];
    }
    return [];
  };
};

/**
 * Creates a validator that ensures a number is an integer and between min and max.
 * @param min The minimum possible value
 * @param max The maximum possible value
 * @returns A validator
 */
export const isIntBetween = (min: number, max: number): Validator<number> => {
  return (value) => {
    if (Number.isNaN(value)) {
      return [`Must be a number between ${min} and ${max}`];
    }
    if (value < min || value > max) {
      return [`Must be between ${min} and ${max}`];
    }
    if (!Number.isInteger(value)) {
      return ['Must be an integer'];
    }
    return [];
  };
};
