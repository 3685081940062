import React, { useContext } from 'react';

import { AppContext } from '@gi/app-provider';
import { ErrorScreen } from '@gi/loading-screen';
import { PageSection } from '@gi/page-layout';

import styles from './catch-all-error-view.module.scss';

const CatchAllErrorView = (): JSX.Element => {
  const { runtimeConfig } = useContext(AppContext);

  return (
    <ErrorScreen centerContent>
      <PageSection>
        <div className={styles.catchAllError}>
          <h1>Something went wrong!</h1>
          <p className={styles.errorInfoText}>
            Something went wrong which the Garden Planner was unable to handle, please refresh the page and{' '}
            <a href={`mailto:${runtimeConfig.supportEmail}?subject=Garden Planner Crash Report - ${VERSION}`} target='_blank' rel='noreferrer'>
              contact support
            </a>{' '}
            if you have recurring issues.
          </p>
        </div>
      </PageSection>
    </ErrorScreen>
  );
};

export default CatchAllErrorView;
