import React from 'react';
import { useSelector } from 'react-redux';

import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';
import GardenPlatformDesktop from '@gi/garden-platform-desktop';
import GardenPlatformMobile from '@gi/garden-platform-mobile';

const GardenPlatformContent = (): JSX.Element => {
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  if (deviceDisplayMode === DeviceDisplayMode.DESKTOP) {
    return <GardenPlatformDesktop />;
  }

  return <GardenPlatformMobile />;
};

export default GardenPlatformContent;
