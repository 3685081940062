import React from 'react';

import './planting-calendar-key.scss';

const PlantingCalendarKeyHarvest = () => {
  return (
    <span className='harvest-key planting-calendar-key'>
      <span className='color-box' />
      Harvest
    </span>
  );
};

export default PlantingCalendarKeyHarvest;
