import React from 'react';

import { HelpModal, ModalPaneSection } from '@gi/modal';

import HelpModalAction from './components/help-modal-action';

interface iProps {
  closeModal: () => void;
}

const LayerSelectorInfoModal = ({ closeModal }: iProps): JSX.Element => {
  return (
    <HelpModal headerText='Layers in the Garden Planner' closeModal={closeModal} style={{ maxWidth: 520 }}>
      <ModalPaneSection>
        <p>
          The Garden Planner automatically places items on your plan into <em>layers</em> depending on the type of item.
        </p>
        <p>The order of these layers, from top to bottom, is the following:</p>
        <ul>
          <li>
            <strong>Structures</strong> - Tall structures that could contain items underneath them.
          </li>
          <li>
            <strong>Plants</strong> - Includes all plants, SFG plants, and plant labels.
          </li>
          <li>
            <strong>Text</strong> - Custom text added to the plan (excluding plant labels).
          </li>
          <li>
            <strong>Irrigation</strong> - Any irrigation parts, such as dripline and hose parts.
          </li>
          <li>
            <strong>Layout</strong> - Raised beds, paths and custom shapes used to define the shape of your garden
          </li>
        </ul>
        <p>
          You can then use the layer selector to view and edit only the items in that layer. All other items will become semi-transparent and uneditable. This
          is particularly useful if you wish to edit plants that are under a season extender such as a greenhouse or row cover.
        </p>
        <p>The layer selector also has 3 special modes to help with editing:</p>
        <ul>
          <li>
            <HelpModalAction>
              <i className='icon-lock-open' /> Edit All
            </HelpModalAction>{' '}
            - Allows for editing all items at once (default editing mode).
          </li>
          <li>
            <HelpModalAction>
              <i className='icon-lock' /> View All
            </HelpModalAction>{' '}
            - Prevents editing any items on your plan (view-only mode).
          </li>
          <li>
            <HelpModalAction>Locked Items</HelpModalAction> - Shows only locked items from all layers.
          </li>
        </ul>
        <p>
          Use the <HelpModalAction>Locked Items</HelpModalAction> layer to access items you have locked using the{' '}
          <HelpModalAction>
            <i className='icon-lock' /> Lock
          </HelpModalAction>{' '}
          button. You can then unlock any desired items by selecting them on the plan and using the{' '}
          <HelpModalAction>
            <i className='icon-lock-open' /> Unlock
          </HelpModalAction>{' '}
          button.
        </p>
      </ModalPaneSection>
    </HelpModal>
  );
};

export default LayerSelectorInfoModal;
