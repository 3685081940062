import { networkConfig } from '@gi/config';
import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';
import { createLogMessage } from './types';

/**
 * Provides methods for sending log messages to the server
 */
export class LogService {
  endpoint: string;

  /**
   * Creates an instance of LogService.
   */
  constructor(endpoint) {
    this.endpoint = endpoint;
    console.debug(`Created log service with endpoint ${this.endpoint}`);
  }

  log(messages: string[]) {
    const path = '/logs/js';

    const url = `${this.endpoint}${path}`;

    const logs = messages.map((message: string) => {
      return createLogMessage(message, 'wgp-log', networkService.userAuth ? networkService.userAuth.id : -1, 1);
    });

    return networkService
      .post<null>(url, {}, logs, RequestAuthMode.Anonymous)
      .then((response) => {
        console.log(response);
      })
      .catch(networkErrorReporter('POST', 'log'));
  }
}

export const logService = new LogService(networkConfig.apiEndpoint);
