import React from 'react';

import GardenObject from '@gi/garden-object';
import { PrintablePlannerIcon } from '@gi/planner-icon';
import { DistanceUnits, metricDistanceUnits } from '@gi/units';

import PartListGardenObjectData from './data/part-list-garden-object-data';
import PartListTableHeaders from './part-list-table-headers';
import PartListTableData from './data/part-list-table-data';

import './part-list-table.scss';

interface iProps {
  distanceUnits?: DistanceUnits;
  partListTableData: PartListTableData;
  artifactCode?: string;
}

const PartListTable = ({ partListTableData, distanceUnits = metricDistanceUnits, artifactCode = 'gb' }: iProps): JSX.Element => {
  const getQuantity = (gardenObject: GardenObject, quantity: number) => {
    return gardenObject.useLengthQuantity ? `${distanceUnits.getUnitString(Math.round(quantity))} *` : quantity;
  };

  const makePartRow = (partListRowData: PartListGardenObjectData, i: number) => {
    return (
      <tr className='part-list-item' key={i}>
        <td className='part-icon-cell center-aligned-cell'>
          <PrintablePlannerIcon className='part-list-icon' artifactCode={artifactCode} code={partListRowData.gardenObject.code} />
        </td>
        <td className='part-name-cell left-aligned-cell'>
          <div className='part-name-container'>
            <span className='part-name-inner'>
              <span className='part-name'>{partListRowData.gardenObject.name}</span>
            </span>
          </div>
        </td>
        <td className='left-aligned-cell'>{getQuantity(partListRowData.gardenObject, partListRowData.quantity)}</td>
      </tr>
    );
  };

  const advice =
    partListTableData.count() === 0 ? (
      <p className='part-list-advice'>Add garden objects or irrigation to your plan and they will be listed in the Parts List</p>
    ) : null;

  // Only render table is there's content
  const table =
    partListTableData.count() > 0 ? (
      <table className='part-list-table print-friendly'>
        <PartListTableHeaders />
        <tbody>{partListTableData.gardenObjects.map(makePartRow)}</tbody>
      </table>
    ) : null;

  const lengthWarning = partListTableData.hasLengthItems ? (
    <p className='part-list-advice'>
      <strong>*</strong> Please note: It is usual to add approximately 10% to the quantity shown above when ordering items which are cut to length or purchased
      by area.
    </p>
  ) : null;

  return (
    <div className='part-list-table-container'>
      {table}
      {advice}
      {lengthWarning}
    </div>
  );
};

export default PartListTable;
