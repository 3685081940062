import { Filter } from '@gi/filters';
import Plant from '@gi/plant';
import { PlantTags } from '@gi/constants';

type AutumnFilterState = {
  enabled: boolean;
};

const suitableForAutumnFilter: Filter<Plant, AutumnFilterState> = {
  name: 'Suitable for Autumn Planting',
  inputs: { enabled: false },
  createListFilter: ({ enabled }) => {
    return (plantList) => {
      if (!enabled) {
        return plantList;
      }

      return plantList.filter((plant) => {
        return plant.hasTag(PlantTags.AUTUMN_PLANTING);
      });
    };
  },
};

export default suitableForAutumnFilter;
