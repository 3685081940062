import React, { useEffect, useState } from 'react';

import { FormLayout, FormRadioField, FormRadioFieldset, FormSection, FORM_LAYOUT_PRESETS, createFormValues } from '@gi/form-responsive';
import LoadingButton from '@gi/loading-button';
import { SessionSelectors } from '@gi/react-session';
import { useDispatch, useSelector } from 'react-redux';
import { UserUnitsSettings } from '@gi/user';
import { LoadingState } from '@gi/constants';

import { AppAccountSelectors, AppAccountActionCreators } from '@gi/app-account-slice';

const UserUnitsSettingsForm = (): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);

  if (!user) {
    console.warn('Attempting to render UserUnitsSettingsForm without a User');
    return null;
  }

  const isSavingUser = useSelector(AppAccountSelectors.getIsSavingUser);
  const saveRequest = useSelector(AppAccountSelectors.getSavingGeneralSettings);
  const isSubmitting = saveRequest.status === LoadingState.LOADING;
  const { settings } = user;
  const dispatch = useDispatch();

  const [manager, setManager] = useState(
    createFormValues<UserUnitsSettings>({
      metric: { value: settings.units.metric },
      metricTemperatureUnits: { value: settings.units.metricTemperatureUnits },
      metricWeightUnits: { value: settings.units.metricWeightUnits },
    })
  );

  const setField = <K extends keyof UserUnitsSettings>(setting: K, value: UserUnitsSettings[K]) => {
    setManager(manager.setValue(setting, { value }));
  };

  const submit = () => {
    console.debug('Submtting General Settings: ', manager.values);
    const { values } = manager;

    dispatch(
      AppAccountActionCreators.saveUserUnitsSettings(user, {
        metric: values.metric,
        metricTemperatureUnits: values.metricTemperatureUnits,
        metricWeightUnits: values.metricWeightUnits,
      })
    );
  };

  useEffect(() => {
    if (saveRequest.status === LoadingState.SUCCESS) {
      setManager(manager.reset(manager.values));
    }
  }, [saveRequest]);

  return (
    <form>
      <FormSection className='form-section-background'>
        <h2>General Settings</h2>
        <p>You can override these measurement settings for each plan when using the Garden Planner if required.</p>
        <FormRadioFieldset label='Weight Units' layout='under' fakeLabel>
          <FormRadioField label='Imperial (pounds, ounces)' htmlFor='weight-imperial'>
            <input
              type='radio'
              id='weight-imperial'
              checked={!manager.values.metricWeightUnits}
              onChange={() => setField('metricWeightUnits', false)}
              disabled={isSubmitting}
            />
          </FormRadioField>
          <FormRadioField label='Metric (kilograms, grams)' htmlFor='weight-metric'>
            <input
              type='radio'
              id='weight-metric'
              checked={manager.values.metricWeightUnits}
              onChange={() => setField('metricWeightUnits', true)}
              disabled={isSubmitting}
            />
          </FormRadioField>
        </FormRadioFieldset>
        <FormRadioFieldset label='Length/Distance Units' layout='under' fakeLabel>
          <FormRadioField label='Imperial (miles, yards)' htmlFor='length-imperial'>
            <input type='radio' id='length-imperial' checked={!manager.values.metric} onChange={() => setField('metric', false)} disabled={isSubmitting} />
          </FormRadioField>
          <FormRadioField label='Metric (kilometers, meters)' htmlFor='length-metric'>
            <input type='radio' id='length-metric' checked={manager.values.metric} onChange={() => setField('metric', true)} disabled={isSubmitting} />
          </FormRadioField>
        </FormRadioFieldset>
        <FormRadioFieldset label='Temperature Units' layout='under' fakeLabel>
          <FormRadioField label='Imperial (Fahrenheit)' htmlFor='temperature-imperial'>
            <input
              type='radio'
              id='temperature-imperial'
              checked={!manager.values.metricTemperatureUnits}
              onChange={() => setField('metricTemperatureUnits', false)}
              disabled={isSubmitting}
            />
          </FormRadioField>
          <FormRadioField label='Metric (Celsius)' htmlFor='temperature-metric'>
            <input
              type='radio'
              id='temperature-metric'
              checked={manager.values.metricTemperatureUnits}
              onChange={() => setField('metricTemperatureUnits', true)}
              disabled={isSubmitting}
            />
          </FormRadioField>
        </FormRadioFieldset>
        <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.ButtonRight}>
          <LoadingButton
            type='button'
            className='button button-primary'
            loading={isSubmitting}
            disabled={isSavingUser || !manager.hasDifferences}
            onClick={submit}
          >
            Save General Settings
          </LoadingButton>
        </FormLayout>
      </FormSection>
    </form>
  );
};

export default UserUnitsSettingsForm;
