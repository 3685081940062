import React, { ReactNode } from 'react';

import './form-tabs-section.scss';

interface iProps {
  children: ReactNode;
}

const FormTabsSection = ({ children }: iProps): JSX.Element => {
  return <div className='rforms-tabs-section'>{children}</div>;
};

export default FormTabsSection;
