import { DeviceDisplayMode } from '@gi/constants';
import { IntercomActionCreators, iIntercomCommandAction } from '../intercom-slice';
import {
  validateIntercomCommand,
  validateIntercomShortcutApp,
  validateIntercomCommandDisplayMode,
  validateIntercomCommandSelector,
  validateIntercomSideEffects,
} from '../intercom-utils';
import {
  InputIntercomShortcutInstruction,
  InputIntercomShortcutList,
  IntercomShortcutInstruction,
  IntercomShortcutDefinitions,
  IntercomShortcut,
  IntercomShortcutSelectorFunction,
  IntercomShortcutSelector,
} from './intercom-shortcut-types';

function parseIntercomCommandInstruction(inputInstruction: InputIntercomShortcutInstruction): IntercomShortcutInstruction | null {
  const command = validateIntercomCommand(inputInstruction.command);

  if (command === null) {
    return null;
  }

  return {
    command,
    id: inputInstruction.id,
    displayMode: validateIntercomCommandDisplayMode(inputInstruction.displayMode),
    selector: validateIntercomCommandSelector(inputInstruction.selector),
    sideEffects: validateIntercomSideEffects(inputInstruction.sideEffects),
  };
}

function parseIntercomCommand(input: InputIntercomShortcutList): IntercomShortcut {
  return {
    id: input.id,
    app: validateIntercomShortcutApp(input.app),
    instructions: input.instructions.map(parseIntercomCommandInstruction).filter((instruction) => instruction !== null) as IntercomShortcutInstruction[],
  };
}

function parseIntercomCommands(inputCommands: InputIntercomShortcutList[]): IntercomShortcut[] {
  return inputCommands.map(parseIntercomCommand);
}

function createIntercomCommandMap(commandList: IntercomShortcut[]): IntercomShortcutDefinitions {
  const commandMap: IntercomShortcutDefinitions = {};

  commandList.forEach((commands) => {
    if (!commandMap[commands.id]) {
      commandMap[commands.id] = commands;
    } else {
      console.error('Intercom command already in list');
    }
  });

  return commandMap;
}

export function createIntercomShortcutInstructionMap(inputCommands: InputIntercomShortcutList[]) {
  return createIntercomCommandMap(parseIntercomCommands(inputCommands));
}

/**
 * A selector for intercom command ids which always returns the first id provided (if an array) or the provided id
 */
const defaultCommandSelector: IntercomShortcutSelectorFunction = (intercomCommandId: number[] | number | null): number | null => {
  if (Array.isArray(intercomCommandId)) {
    if (intercomCommandId.length === 0) {
      return null;
    }

    return intercomCommandId[0];
  }

  return intercomCommandId;
};

/**
 * A Selector for intercom command ids which returns a given command id based on the number of provided command Ids and the users id
 * As long as the number of commands remains the same, the same command will always be given to the same user
 */
const userIdShortcutSelector: IntercomShortcutSelectorFunction = (intercomCommandId: number[] | number | null, userId: number): number | null => {
  if (Array.isArray(intercomCommandId)) {
    if (intercomCommandId.length === 0) {
      return null;
    }

    return intercomCommandId[userId % intercomCommandId.length];
  }

  console.warn('Intercom user ID selector used but only one action provided');
  return intercomCommandId;
};

const randomShortcutSelector: IntercomShortcutSelectorFunction = (intercomActionId: number[] | number | null): number | null => {
  if (Array.isArray(intercomActionId)) {
    if (intercomActionId.length === 0) {
      return null;
    }

    const index = Math.floor(Math.random() * intercomActionId.length);
    return intercomActionId[index];
  }

  return intercomActionId;
};

const IntercomShortcutSelectorMap: Record<IntercomShortcutSelector, IntercomShortcutSelectorFunction> = {
  [IntercomShortcutSelector.Default]: defaultCommandSelector,
  [IntercomShortcutSelector.UserId]: userIdShortcutSelector,
  [IntercomShortcutSelector.Random]: randomShortcutSelector,
};

function getIntercomSelector(selector: string): IntercomShortcutSelectorFunction {
  if (IntercomShortcutSelector[selector]) {
    return IntercomShortcutSelectorMap[selector];
  }

  console.warn(`Unknown selector ${selector}`);
  return defaultCommandSelector;
}

export function getIntercomShortcutFromType(shortcutType: string, shortcutMap: IntercomShortcutDefinitions): IntercomShortcut | null {
  if (shortcutMap[shortcutType]) {
    return shortcutMap[shortcutType];
  }

  console.warn(`Invalid intercom shortcut specified ${shortcutType}`);
  return null;
}

export function createIntercomCommandActionFromShortcut(
  shortcut: IntercomShortcut,
  userId: number,
  displayMode: DeviceDisplayMode.MOBILE | DeviceDisplayMode.DESKTOP
): iIntercomCommandAction | null {
  // Find matching instruction
  for (let i = 0; i < shortcut.instructions.length; i++) {
    const instruction = shortcut.instructions[i];
    // Match on display mode
    if (instruction.displayMode && instruction.displayMode !== displayMode) {
      // eslint-disable-next-line no-continue
      continue;
    }

    // Use the specified selector to get the command id
    const intercomCommandId = getIntercomSelector(instruction.selector)(instruction.id, userId);
    const intercomCommandType = validateIntercomCommand(instruction.command);
    const intercomCommandSideEffects = validateIntercomSideEffects(instruction.sideEffects);

    if (intercomCommandType !== null) {
      return IntercomActionCreators.createIntercomCommandAction(intercomCommandType, intercomCommandId, intercomCommandSideEffects);
    }
  }

  return null;
}
