import React from 'react';

import networkConfig from '@gi/config/network-config';

interface iProps {
  className?: string;
  code?: string;
  filename?: string;
}

const GardenObjectIcon = ({ className = '', code, filename }: iProps): JSX.Element => {
  const _className = `garden-object-icon ${className}`;

  if (code) {
    return <img className={_className} src={`${networkConfig.assetBaseURL}garden-objects/${code}.svg`} alt={code} />;
  }

  return <img className={_className} src={`${networkConfig.assetBaseURL}garden-objects/${filename}`} alt='Garden Object' />;
};

export default GardenObjectIcon;
