import { Pest as PestObj } from '@gi/pest';
import PlantObj from '@gi/plant';

export enum GuruContentType {
  Video = 'video',
  Plant = 'plant',
  Pest = 'pest',
  Article = 'article',
}

// Mostly data for how to display a given page header, this is used for both content
// pages and category pages
export type GuruPageHeader = {
  backgroundImage: string; // Header background image (blurred & darkened/lightened)
  foregroundColor: number | null; // Header foreground color
  leadImage?: string;
  headerContentOverlap?: number; // How the header should display
  title: string;
};

export type GuruPreviewCommon = {
  type: GuruContentType;
  tags: string[];
  id: string;
  title: string;
  description: string;
  slug: string;
  thumbnailUrl: string;
  secondaryThumbnailUrl: string | null;
};

export type GuruPreviewPlant = GuruPreviewCommon & {
  type: GuruContentType.Plant;
  content: {
    plantCode: string;
    plant: PlantObj;
  };
};

export type GuruPreviewPest = GuruPreviewCommon & {
  type: GuruContentType.Pest;
  content: {
    pestCode: string;
    pest: PestObj;
  };
};

export type GuruPreviewVideo = GuruPreviewCommon & {
  type: GuruContentType.Video;
  content: Record<string, never>;
};

export type GuruPreviewArticle = GuruPreviewCommon & {
  type: GuruContentType.Article;
  content: Record<string, never>;
};

export type GuruPreviewItem = GuruPreviewPlant | GuruPreviewPest | GuruPreviewArticle | GuruPreviewVideo;

export enum GuruDisplayType {
  Hero = 'hero',
  Single = 'single',
  Row = 'row',
  Grid = 'grid',
}

export type GuruDisplaySingle = {
  type: GuruDisplayType.Single;
};

export type GuruDisplayRow = {
  type: GuruDisplayType.Row;
};

export type GuruDisplayGrid = {
  type: GuruDisplayType.Grid;
};

export type GuruSectionPreview = {
  id: string;
  title: string;
  displayOrder: number;
  displayType: GuruDisplayType;
  backgroundImage?: string;
  foregroundColor?: number;
};

export type GuruSection = GuruSectionPreview & {
  items: GuruPreviewItem[];
  contentTypes: GuruContentType[];
  navigation: {
    prevUrl: null | string;
    refreshUrl: string;
    nextUrl: null | string;
  };
};
