import React, { useContext, useEffect, useState } from 'react';

import { FormRow } from '@gi/form';
import { useDebounce } from '@gi/react-utils';
import { updateFilters } from '@gi/filters';
import FormField, { InputContainer } from '@gi/form-responsive';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import LocalPlantListContext from '../../local-provider/local-plant-list-context';

interface iProps {
  inputOnly?: boolean;
}

const NameFilterInput = ({ inputOnly }: iProps): JSX.Element => {
  const { plantGroupFilters, setPlantGroupFilters, varietyGroupFilters, setVarietyGroupFilters, areaGroupFilters, setAreaGroupFilters } =
    useContext(GlobalPlantListContext);

  const [searchString, setSearchString] = useState(plantGroupFilters.filters.search.inputs.searchTerm);
  const debouncedSearchString = useDebounce(searchString, 500);

  const { plantListSearchService, plantVarietySearchService } = useContext(LocalPlantListContext);

  useEffect(() => {
    if (debouncedSearchString !== plantGroupFilters.filters.search.inputs.searchTerm) {
      const plantResults = plantListSearchService.search(debouncedSearchString);
      const varietyResults = plantVarietySearchService.search(debouncedSearchString);
      setPlantGroupFilters(
        updateFilters(plantGroupFilters, {
          search: {
            searchTerm: debouncedSearchString,
            plantSearchResults: plantResults,
          },
        })
      );
      setVarietyGroupFilters(
        updateFilters(varietyGroupFilters, {
          search: {
            searchTerm: debouncedSearchString,
            plantSearchResults: plantResults,
            varietySearchResults: varietyResults,
          },
        })
      );
      setAreaGroupFilters(
        updateFilters(areaGroupFilters, {
          search: {
            searchTerm: debouncedSearchString,
            plantSearchResults: plantResults,
            varietySearchResults: varietyResults,
          },
        })
      );
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    if (plantGroupFilters.filters.search.inputs.searchTerm === '' && searchString !== '') {
      setSearchString('');
    }
  }, [plantGroupFilters.filters.search.inputs.searchTerm]);

  if (inputOnly) {
    return <input value={searchString} onChange={(e) => setSearchString(e.target.value)} id='plant-filters:name' />;
  }

  return (
    <FormRow className='plant-list-column-filter' noLabel>
      <FormField label='Name' htmlFor='plant-filters:name' noLabelPadding desktopLayout={{ labelSize: 'auto' }}>
        <InputContainer>
          <input value={searchString} onChange={(e) => setSearchString(e.target.value)} id='plant-filters:name' />
        </InputContainer>
      </FormField>
    </FormRow>
  );
};

export default NameFilterInput;
