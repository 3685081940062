import React from 'react';
import Plant from '@gi/plant';
import ItemContentCell from '../../../components/item-content-table/item-content-cell';
import ItemContentRow from '../../../components/item-content-table/item-content-row';
import ItemContentCellHeader from '../../../components/item-content-table/item-content-cell-header';

interface iSquareFootProps {
  plant: Plant;
}

const PlantSquareFootSection = ({ plant }: iSquareFootProps): JSX.Element => {
  return (
    <ItemContentRow>
      <ItemContentCellHeader>Square Foot Planting</ItemContentCellHeader>
      <ItemContentCell>
        {plant.canBeSquareFootPlant ? (
          <p>
            {plant.squareFootPlantCount} plant{plant.squareFootPlantCount > 1 ? 's' : ''} per square foot.
          </p>
        ) : (
          <p>Not recommended for square foot gardening.</p>
        )}
      </ItemContentCell>
    </ItemContentRow>
  );
};

export default PlantSquareFootSection;
