// eslint-disable-next-line max-classes-per-file
import { Text, TextStyle } from 'pixi.js-new';
import { randomUUID } from '@gi/utils';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';

export type PooledText = {
  textComponent: Text;
  text: string;
  uuid: string;
  free: boolean;
  destroyed: boolean;
};

export type TextPoolOptions = {
  poolSoftLimit: number;
  style: Partial<TextStyle>;
};

const DEFAULT_TEXT_POOL_OPTIONS: TextPoolOptions = {
  poolSoftLimit: 10,
  style: {},
};

/**
 * Node component to manage pooling of Pixi Text objects
 *
 * Objects are marked as free/used and re-used when available, preferably using an existing text component
 * which has the same text as required
 */
class TextPoolComponent extends NodeComponent {
  type = 'TextPoolComponent';

  options: TextPoolOptions;

  pooledText: Record<string, PooledText> = {}; // uuid -> PooledText

  freePooledText: string[] = []; // uuid
  usedPooledText: string[] = []; // uuid
  pooledTextIds: string[] = []; // uuid

  /**
   * Free PooledText uuids group by their text value for caching
   */
  freePooledTextByValue: Record<string, string[]> = {}; // text string -> uuid

  constructor(options: Partial<TextPoolOptions> = {}) {
    super();

    this.options = {
      ...DEFAULT_TEXT_POOL_OPTIONS,
      ...options,
    };

    this.eventBus.on(NodeComponentEvent.Destroyed, this.#didDestroy);
  }

  #setTextFree(pooledText: PooledText): void {
    if (pooledText.free) {
      console.error('Attempted to free text which is already free');
      return;
    }

    pooledText.free = true;

    this.freePooledText.push(pooledText.uuid);
    this.usedPooledText.splice(this.usedPooledText.indexOf(pooledText.uuid), 1);
    this.#addTextToPooledTextCache(pooledText.text, pooledText.uuid);
  }

  #setTextUsed(pooledText: PooledText): void {
    if (!pooledText.free) {
      console.error('Attempted to use text which is already used');
      return;
    }

    pooledText.free = false;

    this.usedPooledText.push(pooledText.uuid);
    this.freePooledText.splice(this.freePooledText.indexOf(pooledText.uuid), 1);
    this.#removeTextFromPooledTextCache(pooledText.text, pooledText.uuid);
  }

  #addTextToPooledTextCache(text: string, uuid: string): void {
    if (!this.freePooledTextByValue[text]) {
      this.freePooledTextByValue[text] = [];
    }
    this.freePooledTextByValue[text].push(uuid);
  }

  /**
   * Removes a piece of text from the text cache
   */
  #removeTextFromPooledTextCache(text: string, uuid: string): void {
    if (!this.freePooledTextByValue[text] || this.freePooledTextByValue[text].length === 0) {
      console.error("Pooled text cache doesn't have required text value");
      return;
    }

    if (this.freePooledTextByValue[text].length === 1) {
      delete this.freePooledTextByValue[text];
    } else {
      this.freePooledTextByValue[text].splice(this.freePooledTextByValue[text].indexOf(uuid), 1);
    }
  }

  #createPooledText(text: string): PooledText {
    const uuid = randomUUID();

    const pooledText = {
      textComponent: new Text(text, this.options.style),
      text,
      uuid,
      free: false,
      destroyed: false,
    };

    this.pooledTextIds.push(uuid);
    this.usedPooledText.push(uuid);
    this.pooledText[uuid] = pooledText;

    return pooledText;
  }

  #destroyText(pooledText: PooledText): void {
    if (pooledText.destroyed) {
      console.error('Attempted to destroy already destroyed PooledText');
      return;
    }

    pooledText.destroyed = true;
    this.pooledTextIds.splice(this.pooledTextIds.indexOf(pooledText.uuid), 1);

    if (pooledText.free) {
      this.freePooledText.splice(this.freePooledText.indexOf(pooledText.uuid), 1);
      this.#removeTextFromPooledTextCache(pooledText.text, pooledText.uuid);
    } else {
      this.usedPooledText.splice(this.usedPooledText.indexOf(pooledText.uuid), 1);
    }

    pooledText.textComponent.destroy();
  }

  getPixiText(text: string, usePool: boolean = true): PooledText {
    if (usePool) {
      if (this.freePooledTextByValue[text] && this.freePooledTextByValue[text].length > 0) {
        const pooledText = this.pooledText[this.freePooledTextByValue[text][0]];
        this.#setTextUsed(pooledText);
        return pooledText;
      }

      // Return an existing pooled text if there is some free pooled text and we're over the poolSoftLimit
      if (this.freePooledText.length > 0 && this.pooledTextIds.length > this.options.poolSoftLimit) {
        const pooledText = this.pooledText[this.freePooledText[0]]; // Use the oldest pooled text, the most likely not to be needed again soon
        this.changeTextString(pooledText, text);
        this.#setTextUsed(pooledText);
        return pooledText;
      }
    }

    return this.#createPooledText(text);
  }

  changeTextString(pooledText: PooledText, newTextString: string): void {
    if (pooledText.free) {
      this.#removeTextFromPooledTextCache(pooledText.text, pooledText.uuid);
    }

    pooledText.text = newTextString;
    pooledText.textComponent.text = newTextString;

    if (pooledText.free) {
      this.#addTextToPooledTextCache(pooledText.text, pooledText.uuid);
    }
  }

  hasPooledText(uuid: string): boolean {
    return !!this.pooledText[uuid];
  }

  setTextFree(uuid: string): void {
    if (!this.hasPooledText(uuid)) {
      console.error("Attempted to free text by uuid which doesn't exist:", uuid);
      return;
    }

    this.#setTextFree(this.pooledText[uuid]);
  }

  setTextUsed(uuid: string): void {
    if (!this.hasPooledText(uuid)) {
      console.error("Attempted to use text by uuid which doesn't exist:", uuid);
      return;
    }

    this.#setTextUsed(this.pooledText[uuid]);
  }

  destroyText(uuid: string) {
    if (!this.hasPooledText(uuid)) {
      console.error("Attempted to use text by uuid which doesn't exist:", uuid);
      return;
    }

    this.#destroyText(this.pooledText[uuid]);
  }

  destroyAllText() {
    for (let i = 0; i < this.pooledTextIds.length; i++) {
      const text = this.pooledText[this.pooledTextIds[i]];
      if (text && !text.destroyed) {
        text.textComponent.destroy();
        text.destroyed = true;
        text.free = true;
      }
    }
    this.pooledText = {};
    this.pooledTextIds = [];
    this.freePooledText = [];
    this.freePooledTextByValue = {};
    this.usedPooledText = [];
  }

  #didDestroy = () => {
    for (let i = 0; i < this.pooledTextIds.length; i++) {
      const pooledText = this.pooledText[this.pooledTextIds[i]];
      pooledText.textComponent.destroy();
      pooledText.destroyed = true;
    }
  };

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.CustomProperty,
      displayName: 'Free Pooled Text Count',
      propertyType: InspectableClassPropertyType.String,
      value: () => this.freePooledText.length,
    },
    {
      type: InspectableClassDataType.CustomProperty,
      displayName: 'Used Pooled Text Count',
      propertyType: InspectableClassPropertyType.String,
      value: () => this.usedPooledText.length,
    },
    {
      type: InspectableClassDataType.CustomProperty,
      displayName: 'Total Pooled Text Count',
      propertyType: InspectableClassPropertyType.String,
      value: () => this.pooledTextIds.length,
    },
    {
      type: InspectableClassDataType.CustomProperty,
      displayName: 'Soft Limit',
      propertyType: InspectableClassPropertyType.String,
      value: () => this.pooledTextIds.length,
    },
  ];
}

export default TextPoolComponent;
