import React, { useContext } from 'react';

import { Modal, ModalHeader, ModalHeaderContent, ModalHeaderTitle, ModalContent, ModalPaneContainer, ModalPane, ModalCloseButton } from '@gi/modal';

import { ElementPrinterContext } from './element-printer-context';

import './element-printer-modal.scss';

const ElementPrinterModal = (): JSX.Element => {
  const { isPrinting, closePrintWindow } = useContext(ElementPrinterContext);

  const onClose = () => {
    // Don't allow user to accidentally close the modal when print is generating
    if (!isPrinting) {
      closePrintWindow();
    }
  };

  return (
    <Modal className='element-printer-modal' closeRequest={onClose}>
      <ModalContent>
        <ModalCloseButton onClick={closePrintWindow} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Print</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            {isPrinting ? (
              <div className='loading-message'>
                <span className='loading-message-content'>
                  Generating Printable Page <i className='icon-spinner animate-pulse' />
                </span>
              </div>
            ) : (
              <div className='print-button-container'>
                <p>Printable Page Generated</p>
                <div className='print-button-content'>
                  <button type='button' className='button button-secondary' onClick={closePrintWindow}>
                    Close
                  </button>
                  <button type='button' className='button button-primary' onClick={window.print}>
                    Print
                  </button>
                </div>
              </div>
            )}
          </ModalPane>
        </ModalPaneContainer>
      </ModalContent>
    </Modal>
  );
};

export default ElementPrinterModal;
