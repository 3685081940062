import { Store } from 'redux';

import { HandleStoreChangeListener, StoreListener } from '@gi/redux-utils';
import { LoadingState } from '@gi/constants';
import { AsyncOperation } from '@gi/utils';
import { GuruSection } from '@gi/app-guru-types/types';
import { SessionRootState } from '@gi/react-session';
import { createContentSlugParams } from '@gi/app-guru-common';
import { GuruService } from '@gi/app-guru-service';
import { ThunkServices } from '@gi/garden-platform-services';

import { GuruActionCreators, GuruRootState } from './garden-guru-slice';

/**
 * Initialises a request which loads a specific piece of Guru content
 *
 * Will not run a request if some properties/data is not available such as the content url data from the store
 */
function loadContent(currentState: GuruRootState & SessionRootState, store: Store<GuruRootState>, guruService: GuruService, contentUUID: string) {
  if (!currentState.guru.content[contentUUID]) {
    console.warn(`Attempted to load content but content ID does not point to required URL data: ${contentUUID}`);
    return;
  }

  const contentUrlProperties = currentState.guru.content[contentUUID];
  const slugParams = createContentSlugParams(contentUrlProperties.contentId, contentUrlProperties.queryParams);

  store.dispatch(GuruActionCreators.updateContent([contentUUID, { status: LoadingState.LOADING }]));
  guruService
    .getContent(slugParams, contentUrlProperties.contentUUID, contentUrlProperties.contentId)
    .then((content) => {
      store.dispatch(GuruActionCreators.updateContent([contentUUID, { status: LoadingState.SUCCESS, value: content }]));
    })
    .catch(() => {
      store.dispatch(GuruActionCreators.updateContent([contentUUID, { status: LoadingState.ERROR, error: new Error('Network Error') }]));
    });
}

function createLoaders(services: ThunkServices) {
  const { guruService } = services;
  const contentLoader = (previousState: GuruRootState, currentState: GuruRootState & SessionRootState, store: Store<GuruRootState>) => {
    // Create a list of required content IDs by loading all the keys of Guru content and checking
    // which ones have their Async status set to 'None'
    const requiredContentIds: string[] = [];
    const contentIds = Object.keys(currentState.guru.content);
    for (let i = 0; i < contentIds.length; i++) {
      const contentId = contentIds[i];
      if (
        currentState.guru.content[contentId].status === LoadingState.NONE &&
        (!previousState.guru.content[contentId] || previousState.guru.content[contentId].status !== LoadingState.NONE)
      ) {
        requiredContentIds.push(contentId);
      }
    }

    for (let i = 0; i < requiredContentIds.length; i++) {
      loadContent(currentState, store, guruService, requiredContentIds[i]);
    }
  };

  const directoryLoader = (previousState: GuruRootState, currentState: GuruRootState & SessionRootState, store: Store<GuruRootState>) => {
    const previousTokenAvailable = !!currentState.session.user?.authToken;

    const requiredComposedPageIds: string[] = [];
    const composedPageIds = Object.keys(currentState.guru.pages);
    for (let i = 0; i < composedPageIds.length; i++) {
      const composedPageId = composedPageIds[i];

      const requiresLoading = currentState.guru.pages[composedPageId].status === LoadingState.NONE;
      const didNotPreviouslyRequireLoading =
        !previousState.guru.pages[composedPageId] || previousState.guru.pages[composedPageId].status !== LoadingState.NONE || !previousTokenAvailable;

      if (requiresLoading && didNotPreviouslyRequireLoading) {
        requiredComposedPageIds.push(composedPageId);
      }
    }

    for (let i = 0; i < requiredComposedPageIds.length; i++) {
      const requiredPageId = requiredComposedPageIds[i];
      store.dispatch(GuruActionCreators.setPage([requiredComposedPageIds[i], { status: LoadingState.LOADING }]));

      guruService
        .getDirectoryPage(requiredPageId)
        .then((content) => {
          store.dispatch(GuruActionCreators.setPage([requiredPageId, { status: LoadingState.SUCCESS, value: content }]));
        })
        .catch(() => {
          store.dispatch(GuruActionCreators.setPage([requiredPageId, { status: LoadingState.ERROR, error: new Error('Network Error') }]));
        });
    }
  };

  const relatedContentSectionLoader = (previousState: GuruRootState, currentState: GuruRootState & SessionRootState, store: Store<GuruRootState>) => {
    const requiredSections: string[] = [];
    const urls = Object.keys(currentState.guru.relatedContentSections);
    for (let i = 0; i < urls.length; i++) {
      const url = urls[i];
      if (
        currentState.guru.relatedContentSections[url].status === LoadingState.NONE &&
        (!previousState.guru.relatedContentSections[url] || previousState.guru.relatedContentSections[url].status !== LoadingState.NONE)
      ) {
        requiredSections.push(url);
      }
    }

    for (let i = 0; i < requiredSections.length; i++) {
      const sectionId = requiredSections[i];
      store.dispatch(GuruActionCreators.setRelatedContentSection([requiredSections[i], { status: LoadingState.LOADING }]));
      guruService
        .getRelatedContent(sectionId)
        .then((content) => {
          store.dispatch(GuruActionCreators.setRelatedContentSection([sectionId, { status: LoadingState.SUCCESS, value: content }]));
        })
        .catch(() => {
          store.dispatch(GuruActionCreators.setRelatedContentSection([sectionId, { status: LoadingState.ERROR, error: new Error('Network Error') }]));
        });
    }
  };

  const sectionLoader = (previousState: GuruRootState, currentState: GuruRootState & SessionRootState, store: Store<GuruRootState>) => {
    const previousTokenAvailable = !!currentState.session.user?.authToken;

    const requiredSectionIds: string[] = [];

    // Find all preview items that have just transitioned to LoadingState.NONE state
    const sectionIds = Object.keys(currentState.guru.sections);
    for (let j = 0; j < sectionIds.length; j++) {
      const sectionId = sectionIds[j];

      const requiresLoading = currentState.guru.sections[sectionId].status === LoadingState.NONE;
      const didNotPreviouslyRequireLoading =
        !previousState.guru.sections ||
        !previousState.guru.sections[sectionId] ||
        previousState.guru.sections[sectionId].status !== LoadingState.NONE ||
        !previousTokenAvailable;

      if (requiresLoading && didNotPreviouslyRequireLoading) {
        requiredSectionIds.push(sectionId);
      }
    }

    if (requiredSectionIds.length === 0) {
      return;
    }

    // Set all found preview items to LoadingState.LOADING state
    store.dispatch(GuruActionCreators.setSections(requiredSectionIds.map((sectionId) => [sectionId, { status: LoadingState.LOADING }])));

    requiredSectionIds.forEach((sectionId) => {
      guruService
        .getSection(sectionId)
        .then((section) => {
          store.dispatch(GuruActionCreators.setSection([sectionId, { status: LoadingState.SUCCESS, value: section }]));
        })
        .catch((e) => {
          console.error(e);
          store.dispatch(GuruActionCreators.setSection([sectionId, { status: LoadingState.ERROR, error: new Error('Network Error') }]));
        });
    });
  };

  const sectionRequiresLoadingMarker = (previousState: GuruRootState, currentState: GuruRootState, store: Store<GuruRootState>) => {
    const requiredSectionIds: Set<string> = new Set();
    const keys = Object.keys(currentState.guru.pages);
    for (let i = 0; i < keys.length; i++) {
      const pageId = keys[i];
      const currentPage = currentState.guru.pages[pageId];
      const previousPage = previousState.guru.pages[pageId];
      if (currentPage.status === LoadingState.SUCCESS && (!previousPage || previousPage.status !== LoadingState.SUCCESS)) {
        currentPage.value.sectionIds.forEach((sectionId) => {
          requiredSectionIds.add(sectionId); // Use  Set to remove duplicates
        });
      }

      if (currentPage.status === LoadingState.SUCCESS && previousPage.status === LoadingState.SUCCESS) {
        if (currentPage.value.sectionIds !== previousPage.value.sectionIds) {
          currentPage.value.sectionIds
            .filter((sectionId) => !previousPage.value.sectionIds.includes(sectionId))
            .forEach((sectionId) => {
              requiredSectionIds.add(sectionId); // Use  Set to remove duplicates
            });
        }
      }
    }

    if (requiredSectionIds.size === 0) {
      return;
    }

    const newSectionData: [sectionId: string, sectionOperation: AsyncOperation<GuruSection>][] = [...requiredSectionIds].map((sectionId) => [
      sectionId,
      { status: LoadingState.NONE },
    ]);

    store.dispatch(GuruActionCreators.setSections(newSectionData));
  };

  return {
    contentLoader,
    directoryLoader,
    relatedContentSectionLoader,
    sectionLoader,
    sectionRequiresLoadingMarker,
  };
}

const createGuruStoreChangeHandler: (services: any) => HandleStoreChangeListener<GuruRootState> = (services: any) => {
  return (previousState: GuruRootState, currentState: GuruRootState & SessionRootState, store: Store<GuruRootState>) => {
    const { contentLoader, directoryLoader, relatedContentSectionLoader, sectionLoader, sectionRequiresLoadingMarker } = createLoaders(services);

    contentLoader(previousState, currentState, store);
    directoryLoader(previousState, currentState, store);
    sectionRequiresLoadingMarker(previousState, currentState, store);
    sectionLoader(previousState, currentState, store);
    relatedContentSectionLoader(previousState, currentState, store);
  };
};

export const createGuruStoreListener = (services: any): StoreListener<GuruRootState> => {
  return new StoreListener(createGuruStoreChangeHandler(services));
};
