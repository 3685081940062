import React from 'react';

import { webGardenPlannerUpdates } from '@gi/update-data';
import { PageSection, PageSectionTitle } from '@gi/page-layout';

import UpdateSection from './update-section';

import './update-history.scss';

const UpdateHistory = (): JSX.Element => {
  return (
    <PageSection>
      <PageSectionTitle>
        <h2>Update History</h2>
      </PageSectionTitle>
      <p>This page contains the recent history of updates to the Garden Planner and Garden Journal.</p>
      {webGardenPlannerUpdates.map((update, i) => (
        <UpdateSection key={i} updateData={update} />
      ))}
    </PageSection>
  );
};

export default UpdateHistory;
