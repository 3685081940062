import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useMergeRefs, autoUpdate, offset, shift, computePosition, arrow } from '@floating-ui/react';
import Arrow from './arrow';

import styles from './product-window-container.module.css';
import ProductWindowPortal from './product-window-portal';

const ARROW_OFFSET = 6;
const ARROW_WIDTH = 12;
const ARROW_HEIGHT = ARROW_OFFSET;

interface iProps {
  target: HTMLElement;
  children: React.ReactNode;
}

const ProductWindowContainer = ({ target, children }: iProps, ref: React.MutableRefObject<HTMLDivElement>): JSX.Element => {
  const [style, setStyle] = useState<React.CSSProperties>({});
  const [arrowStyle, setArrowStyle] = useState<React.CSSProperties>({});

  const windowRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergeRefs([windowRef, ref]);

  const arrowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (target === null || windowRef.current === null) {
      return () => {};
    }

    const updatePosition = () => {
      if (target === null || windowRef.current === null) {
        return;
      }

      computePosition(target, windowRef.current, {
        placement: 'bottom-start',
        middleware: [offset(6), shift({ padding: 6 }), arrow({ element: arrowRef.current })],
      }).then(({ x, y, placement, middlewareData }) => {
        const [_side, alignment] = placement.split('-');
        const side = _side as 'bottom' | 'top' | 'left' | 'right';
        const isAligned = alignment != null;

        setStyle({
          left: `${x}px`,
          top: `${y}px`,
        });

        if (arrowRef.current) {
          const arrowX = middlewareData.arrow!.x!;
          const arrowY = middlewareData.arrow!.y!;

          const unsetSides = {
            top: '',
            bottom: '',
            left: '',
            right: '',
          };
          const staticSide: 'bottom' | 'top' | 'left' | 'right' = (
            {
              top: 'bottom',
              right: 'left',
              bottom: 'top',
              left: 'right',
            } as const
          )[side];

          const isRefWider = target.offsetWidth > windowRef.current!.offsetWidth;
          const isRefTaller = target.offsetHeight > windowRef.current!.offsetHeight;
          const isVerticalSide = side === 'top' || side === 'bottom';
          const useStaticPositioning = isAligned && (isVerticalSide ? isRefWider : isRefTaller);

          const rotation = {
            top: '180deg',
            bottom: '0deg',
            left: '90deg',
            right: '-90deg',
          }[side];

          if (useStaticPositioning) {
            const crossSide = {
              'top-start': 'left',
              'top-end': 'right',
              'bottom-start': 'left',
              'bottom-end': 'right',
              'left-start': 'top',
              'left-end': 'bottom',
              'right-start': 'top',
              'right-end': 'bottom',
            }[placement];

            setArrowStyle({
              ...unsetSides,
              [staticSide]: `-${ARROW_OFFSET}px`,
              [crossSide]: 'min(15%, 15px)',
              transform: `rotate(${rotation})`,
            });
          } else {
            setArrowStyle({
              ...unsetSides,
              left: x != null ? `${arrowX}px` : '',
              top: y != null ? `${arrowY}px` : '',
              [staticSide]: `-${ARROW_OFFSET}px`,
              transform: `rotate(${rotation})`,
            });
          }
        }
      });
    };

    const cleanup = autoUpdate(target, windowRef.current, updatePosition);

    return () => {
      cleanup();
    };
  }, [target]);

  return (
    <ProductWindowPortal>
      <div className={`${styles.baseProductWindow} ${styles.attachedProductWindow}`} ref={mergedRef} style={style}>
        {children}
        <div ref={arrowRef} style={{ position: 'absolute', fontSize: 0, ...arrowStyle }}>
          <Arrow width={ARROW_WIDTH} height={ARROW_HEIGHT} fill='#fff' />
        </div>
      </div>
    </ProductWindowPortal>
  );
};

// TODO: Investigate why this causes type issues when set directly on ProductWindowContainer
export default forwardRef<HTMLDivElement, iProps>(ProductWindowContainer);
