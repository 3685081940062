import { createFilters } from '@gi/filters';
import { PlantListSummaryGroup } from '../../types/plant-list-types';
import makePerennialsFilter from '../common/show-perennials-filter';
import { makePlantSearchFilter } from '../plant-search-filter';
import sortfilter from './summary-sort-filter';

export type PlantListSummaryGroupFiltersType = {
  perennial: ReturnType<typeof makePerennialsFilter<PlantListSummaryGroup>>;
  search: ReturnType<typeof makePlantSearchFilter<PlantListSummaryGroup>>;
  sort: typeof sortfilter;
};

const INITIAL_PLANT_FILTERS = createFilters<PlantListSummaryGroup, PlantListSummaryGroupFiltersType>({
  perennial: makePerennialsFilter<PlantListSummaryGroup>(),
  search: makePlantSearchFilter<PlantListSummaryGroup>((obj) => obj.plant.code),
  sort: sortfilter,
});

export default INITIAL_PLANT_FILTERS;
