import React from 'react';

import styles from './hero-preview-components.module.css';

interface iProps {
  src: string;
  alt: string;
}

const HeroPreviewThumbnail = ({ src, alt }: iProps): JSX.Element => {
  return (
    <div className={styles.heroPreviewThumbnail}>
      <img src={src} alt={alt} />
    </div>
  );
};

export default HeroPreviewThumbnail;
