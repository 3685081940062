import Collection from '@gi/collection';
import GardenObject from '@gi/garden-object';
import Plan, { APIPlanDocument, PlanParserUtils, PlanUtils, createNewPlan } from '@gi/plan';
import Plant from '@gi/plant';

export type TemplatePlan = {
  planID: number;
  planName: string;
  planDescription: string;
  planIconURL: string;
  planPreviewURL: string;
  displayIndex: number;
  planDocument: APIPlanDocument;
  templateID: number;
};

export type TemplatePlanFromAPI = {
  planID: number;
  planName: string;
  planDescription: string;
  planIconURL: string;
  planPreviewURL: string;
  displayIndex: number;
  planDocument: APIPlanDocument;
  templateID: number;
};

export function createPlanFromTemplate(
  userID: number,
  planName: string,
  planYear: number,
  metric: boolean,
  templatePlan: TemplatePlan,
  plants: Collection<Plant>,
  gardenObjects: Collection<GardenObject>
): Plan {
  const planFromTemplate: Plan = PlanParserUtils.planFromAPI(templatePlan.planDocument);

  let newPlan = createNewPlan(userID, planName, planYear, planFromTemplate.width, planFromTemplate.height, metric);

  newPlan = PlanUtils.copyPlanPlants(planFromTemplate, newPlan, plants);
  newPlan = PlanUtils.copyPlanGardenObjects(planFromTemplate, newPlan, gardenObjects);
  newPlan = PlanUtils.copyPlanShapes(planFromTemplate, newPlan);
  newPlan = PlanUtils.copyPlanText(planFromTemplate, newPlan);

  return newPlan;
}
