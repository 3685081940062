export type PlanPlantNotes = {
  plantCodes: string[];
  varietiesByCode: Record<string, PlanPlantNotesVarieties>;
  count: number;
};

export type PlanPlantNotesVarieties = {
  plantCode: string;
  varieties: string[];
  byVariety: Record<string, PlanPlantNotesEntry>;
};

export type PlanPlantNotesEntry = {
  plantCode: string;
  variety: string;
  text: string;
};

export function getPlanNoteRecordIdKey(plantCode: string, variety: string): string {
  return `${plantCode}-${variety}`;
}

export function createEmptyPlantNotes(): PlanPlantNotes {
  return {
    plantCodes: [],
    varietiesByCode: {},
    count: 0,
  };
}

export function plantNotesHasPlant(notes: Readonly<PlanPlantNotes>, plantCode: string): boolean {
  return notes.plantCodes.includes(plantCode);
}

export function plantNotesHasPlantVariety(notes: Readonly<PlanPlantNotes>, plantCode: string, variety: string): boolean {
  if (!plantNotesHasPlant(notes, plantCode)) {
    return false;
  }

  return notes.varietiesByCode[plantCode].varieties.includes(variety);
}

export function getPlantNote(notes: Readonly<PlanPlantNotes>, plantCode: string, variety: string): string | null {
  if (!plantNotesHasPlantVariety(notes, plantCode, variety)) {
    return null;
  }

  return notes.varietiesByCode[plantCode].byVariety[variety].text;
}

export function getPlantNoteText(notes: Readonly<PlanPlantNotes>, plantCode: string, variety: string): string {
  const text = getPlantNote(notes, plantCode, variety);

  if (text !== null) {
    return text;
  }

  return '';
}

export function getPlantNoteVarieties(notes: Readonly<PlanPlantNotes>, plantCode: string): string[] {
  if (!plantNotesHasPlant(notes, plantCode)) {
    return [];
  }

  return notes.varietiesByCode[plantCode].varieties;
}

export function setPlantNote(notes: Readonly<PlanPlantNotes>, plantCode: string, variety: string, text: string): PlanPlantNotes {
  let updatedNotes = { ...notes };
  if (!plantNotesHasPlant(notes, plantCode)) {
    // Plant doesn't exist, create new plant entry
    updatedNotes = {
      ...updatedNotes,
      plantCodes: [...updatedNotes.plantCodes, plantCode],
      varietiesByCode: {
        ...updatedNotes.varietiesByCode,
        [plantCode]: {
          plantCode,
          varieties: [],
          byVariety: {},
        },
      },
    };
  }

  if (!plantNotesHasPlantVariety(notes, plantCode, variety)) {
    // Variety doesn't exist, create new variety entry
    updatedNotes = {
      ...updatedNotes,
      count: updatedNotes.count + 1, // Increase the count
      varietiesByCode: {
        ...updatedNotes.varietiesByCode,
        [plantCode]: {
          ...updatedNotes.varietiesByCode[plantCode],
          varieties: [...updatedNotes.varietiesByCode[plantCode].varieties, variety],
          byVariety: {
            ...updatedNotes.varietiesByCode[plantCode].byVariety,
            [variety]: {
              plantCode,
              variety,
              text,
            },
          },
        },
      },
    };
  } else {
    // All notes records already exist, just update the text value
    updatedNotes = {
      ...updatedNotes,
      varietiesByCode: {
        ...updatedNotes.varietiesByCode,
        [plantCode]: {
          ...updatedNotes.varietiesByCode[plantCode],
          byVariety: {
            ...updatedNotes.varietiesByCode[plantCode].byVariety,
            [variety]: {
              ...updatedNotes.varietiesByCode[plantCode].byVariety[variety],
              text,
            },
          },
        },
      },
    };
  }

  return updatedNotes;
}

export function removePlantNote(notes: Readonly<PlanPlantNotes>, plantCode: string, variety: string): PlanPlantNotes {
  if (!plantNotesHasPlantVariety(notes, plantCode, variety)) {
    console.error('Plant notes attempted to be removed from notes but not present');
    return notes;
  }

  let updatedNotes = { ...notes };

  // Create new PlanPlantNotesVarieties record with the variety removed
  const updatedVarieties: PlanPlantNotesVarieties = {
    ...updatedNotes.varietiesByCode[plantCode],
    varieties: updatedNotes.varietiesByCode[plantCode].varieties.filter((varName) => varName !== variety),
  };
  delete updatedVarieties.byVariety[variety];

  updatedNotes = {
    ...updatedNotes,
    count: updatedNotes.count - 1,
    varietiesByCode: {
      ...updatedNotes.varietiesByCode,
      [plantCode]: updatedVarieties,
    },
  };

  if (updatedNotes.varietiesByCode[plantCode].varieties.length === 0) {
    // No more varieties for this plant code, remove the record
    updatedNotes = {
      ...updatedNotes,
      plantCodes: updatedNotes.plantCodes.filter((code) => code !== plantCode),
    };
    delete updatedNotes.varietiesByCode[plantCode];
  }

  return updatedNotes;
}

/**
 * Iterates through all plant notes and removes empty ones, returning the updated plant notes
 */
export function removeEmptyNotes(planPlantNotes: Readonly<PlanPlantNotes>): PlanPlantNotes {
  let updatedPlanPlantNotes = { ...planPlantNotes };
  const notesToRemove: [string, string][] = [];

  for (let i = 0; i < planPlantNotes.plantCodes.length; i++) {
    const plantCode = planPlantNotes.plantCodes[i];
    const { varieties, byVariety } = planPlantNotes.varietiesByCode[plantCode];

    for (let j = 0; j < varieties.length; j++) {
      const varietyName = varieties[j];
      const { text } = byVariety[varietyName];

      if (text.trim() === '') {
        // Make a list of notes to remove, otherwise editing the notes in progress will
        // Affect the array we're iterating over
        notesToRemove.push([plantCode, varietyName]);
      }
    }
  }

  for (let i = 0; i < notesToRemove.length; i++) {
    updatedPlanPlantNotes = removePlantNote(updatedPlanPlantNotes, notesToRemove[i][0], notesToRemove[i][1]);
  }

  return updatedPlanPlantNotes;
}

// export function findOrphanedPlantNotes(plan: Plan): PlanPlantNotesEntry[] {
//   // TODO - return a list of plant notes which no longer have applicable items on the plan
//   return [];
// }
