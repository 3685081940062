import { NavigationActionCreators } from '@gi/garden-platform-navigation';

import { GardenPlatformEvent, MiddlewareEnhanceFunction } from '../types';
import { GardenPlatformEventsActionCreators } from '../garden-platform-events-middleware';

const enhanceMiddleware: MiddlewareEnhanceFunction = (middleware) => {
  // Emit event when user changes planner tab
  middleware.startListening({
    actionCreator: NavigationActionCreators.navigateTo,
    effect: (action, api) => {
      const { application } = action.payload;
      api.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.OpenApplication, { application }));
    },
  });
};

export default enhanceMiddleware;
