import { GardenItemType } from '@gi/constants/types/garden-item-type';
import { ModifierType } from '@gi/constants/types/modifier-type';
import { Plan } from './plan';
import { getFreshPlanItemId, getUpdatedZIndexLimits } from './plan-item-utils';

export type PlanPlant = {
  id: number;
  plantCode: string;
  rowStart: Vector2;
  rowEnd: Vector2;
  height: number;
  rotation: number;
  showLabel: boolean;
  labelOffset: Vector2;
  labelText: string;
  isSquareFoot: boolean;
  variety: string;
  inGroundStart: number;
  inGroundEnd: number;
  inGroundAll: boolean;
  modifiers: ModifierType[];
  locked: boolean;
  zIndex: number;
};

export function createNewPlanPlant(
  plantCode: string,
  rowStart: Vector2,
  rowEnd: Vector2,
  height: number,
  showLabel: boolean,
  labelOffset: Vector2,
  labelText: string,
  isSquareFoot: boolean,
  variety: string,
  inGroundStart: number,
  inGroundEnd: number,
  inGroundAll: boolean,
  modifiers: ModifierType[],
  locked: boolean,
  zIndex: number,
  id: number = -1
): PlanPlant {
  return {
    id, // Set initial ID to -1 so when it's added to a plan it's automatically set to the correct ID
    plantCode,
    rowStart,
    rowEnd,
    height,
    rotation: 0, // Rotation is not used in plants
    showLabel,
    labelOffset,
    labelText,
    isSquareFoot,
    variety,
    inGroundStart,
    inGroundEnd,
    inGroundAll,
    modifiers,
    locked,
    zIndex,
  };
}

/**
 * Immutably adds a new plant to a plan and returns the updated plan
 */
export function addPlantToPlan(plan: Plan, planPlant: PlanPlant): Plan {
  if (plan.itemTypes[planPlant.id]) {
    throw new Error('Attempting to add plant to plan which already has an item with that id');
  }

  const [planWithUpdatedMaxItemId, validatedId] = getFreshPlanItemId(plan, planPlant.id);

  const updatedPlant = {
    ...planPlant,
    id: validatedId,
  };

  const updatedPlan = {
    ...planWithUpdatedMaxItemId,
    ...getUpdatedZIndexLimits(plan, planPlant.zIndex),
    itemTypes: {
      ...planWithUpdatedMaxItemId.itemTypes,
      [validatedId]: GardenItemType.Plant,
    },
    plants: {
      ...planWithUpdatedMaxItemId.plants,
      [validatedId]: updatedPlant,
    },
    plantIds: [...planWithUpdatedMaxItemId.plantIds, validatedId],
  };

  return updatedPlan;
}

/**
 * Immutably removes a plant from a plan and returns the updated plan
 */
export function removePlantFromPlan(plan: Plan, planPlantId: number): Plan {
  if (!plan.itemTypes[planPlantId] || !plan.plants[planPlantId]) {
    console.error("Attempted to remove plant from plan which doesn't exist", planPlantId);
    return plan;
  }

  const newItemTypes = { ...plan.itemTypes };
  delete newItemTypes[planPlantId];

  const newPlants = { ...plan.plants };
  delete newPlants[planPlantId];

  const newPlantIds = [...plan.plantIds];
  newPlantIds.splice(newPlantIds.indexOf(planPlantId), 1);

  const updatedPlan = {
    ...plan,
    itemTypes: newItemTypes,
    plants: newPlants,
    plantIds: newPlantIds,
  };

  return updatedPlan;
}

/**
 * Immutably udpates a plan plant in a plan
 *
 * Note: this will cause issues if the plan is not already in the plan (with the same id)
 */
export function updatePlanPlant(plan: Plan, planPlant: PlanPlant): Plan {
  if (!plan.itemTypes[planPlant.id] || plan.itemTypes[planPlant.id] !== GardenItemType.Plant) {
    throw new Error("Attempting to updated a plant in a plan which doesn't have that plant already");
  }

  return {
    ...plan,
    ...getUpdatedZIndexLimits(plan, planPlant.zIndex),
    plants: {
      ...plan.plants,
      [planPlant.id]: planPlant,
    },
  };
}
