import React, { useContext } from 'react';
import ReactSelect from 'react-select';

import { presetPalettes } from '@gi/palette';
import { PickerButton } from '@gi/texture-picker';

import { DrawingToolsContext } from '../drawing-tools-context';
import DragToDrawButton from '../drag-to-draw/drag-to-draw-button';

import textIcon from '../icons/text-dark.svg';

import './text-panel.scss';

const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '30px',
    minWidth: '134px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '3px',
  }),
  input: (provided) => ({
    ...provided,
    paddingBottom: '0',
    paddingTop: '0',
    margin: '0',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 5px',
    minHeight: '30px',
  }),
};

const fontSizes = [
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 16, label: '16' },
  { value: 18, label: '18' },
  { value: 20, label: '20' },
  { value: 24, label: '24' },
  { value: 36, label: '36' },
];

const TextPanel = () => {
  const { textFontSize, setTextFontSize, textSwatch, setTextSwatch, textSelected, onTextSelected, cancelDraw } = useContext(DrawingToolsContext);

  const onTextSwatchChange = (e) => {
    setTextSwatch(e);
    if (textSelected) {
      onTextSelected(textFontSize.value, e.value);
    }
  };

  const onFontSizeChange = (e) => {
    setTextFontSize(e);
    if (textSelected) {
      onTextSelected(e.value, textSwatch === null ? '000000' : textSwatch.value);
    }
  };

  const onToggle = () => {
    if (textSelected) {
      cancelDraw();
    } else {
      onTextSelected(textFontSize.value, textSwatch === null ? '000000' : textSwatch.value);
    }
  };

  const onDrag = (e: PointerEvent) => {
    onTextSelected(textFontSize.value, textSwatch === null ? '000000' : textSwatch.value, e);
  };

  const buttonClassName = `text-button ${textSelected ? 'selected' : ''}`;
  return (
    <div className='text-panel drawing-tools-panel'>
      <header className='drawing-tools-panel-title'>
        <h1>Text</h1>
      </header>
      <div className='text-inputs drawing-tools-panel-content scrollable-drawing-tools-panel-content'>
        <div className='text-input-row'>
          <label>
            <span className='label-text'>Font Size</span>
            <ReactSelect
              styles={SELECT_STYLES}
              className='text-input-value'
              options={fontSizes}
              value={textFontSize}
              onChange={onFontSizeChange}
              isSearchable={false}
            />
          </label>
        </div>
        <div className='text-input-row'>
          <label>
            <span className='label-text'>Color</span>
            <PickerButton selectedSwatch={textSwatch} palettes={presetPalettes.colorPalettes} onSwatchSelect={onTextSwatchChange} />
          </label>
        </div>
        <div className='text-input-row text-button-row'>
          <DragToDrawButton className={buttonClassName} onClick={onToggle} onDrag={onDrag}>
            <img src={textIcon} alt='text' />
          </DragToDrawButton>
        </div>
      </div>
    </div>
  );
};

export default TextPanel;
