import React from 'react';

import styles from './menu-bar.module.css';

interface iProps {
  children: React.ReactNode;
}

const MenuBar = ({ children }: iProps): JSX.Element => {
  return <div className={styles.menuBar}>{children}</div>;
};

export default MenuBar;
