import React from 'react';
import styles from './quantity-selector.module.css';
import RecommendedQuantityTooltip from './recommended-quantity-tooltip';

enum QuantityTarget {
  Less = 'Less',
  Equal = 'Equal',
  More = 'More',
  Unknown = 'Unknown',
}

const QuantityContent = {
  [QuantityTarget.Equal]: (
    <div className={styles.quantityEqual}>
      <i className='icon-ok' />
      Recommended Quantity
    </div>
  ),
  [QuantityTarget.Less]: (
    <div className={styles.quantityLess}>
      <i className='icon-angle-down' />
      Less Than Recommended
    </div>
  ),
  [QuantityTarget.More]: (
    <div className={styles.quantityMore}>
      <i className='icon-ok' />
      More Than Recommended
    </div>
  ),
  [QuantityTarget.Unknown]: <div>Unable to Suggest Quantity</div>,
};

interface iProps {
  unit: string;
  variantQuantity: number;
  quantity: number;
  inputQuantity: number;
  recommendedQuantityMultiplier: number;
}

const RecommendedQuantityDescriptor = ({ unit, variantQuantity, quantity, inputQuantity, recommendedQuantityMultiplier }: iProps): null | JSX.Element => {
  if (recommendedQuantityMultiplier === 1 && variantQuantity === 1) {
    return null;
  }

  const purchaseMultiplier = quantity / Math.ceil((inputQuantity * recommendedQuantityMultiplier) / variantQuantity);

  let target: QuantityTarget;
  if (variantQuantity === 0) {
    target = QuantityTarget.Unknown;
  } else if (purchaseMultiplier === 1) {
    target = QuantityTarget.Equal;
  } else {
    target = purchaseMultiplier > 1 ? QuantityTarget.More : QuantityTarget.Less;
  }

  return (
    <div className={styles.quantityDetails}>
      {variantQuantity === 0 ? null : (
        <div className={styles.quantityTotals}>
          Total {unit}: {variantQuantity * quantity}
        </div>
      )}
      <div className={styles.quantityRecommended}>
        {QuantityContent[target]}
        <RecommendedQuantityTooltip
          unit={unit}
          variantQuantity={variantQuantity}
          inputQuantity={inputQuantity}
          recommendedQuantityMultiplier={recommendedQuantityMultiplier}
        />
      </div>
    </div>
  );
};

export default RecommendedQuantityDescriptor;
