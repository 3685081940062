import React, { ReactNode } from 'react';

import styles from './context-menu-styles.module.css';

interface iProps {
  className?: string;
  title?: string;
  children?: ReactNode;
}

const ContextMenuSection = ({ className, title, children }: iProps): JSX.Element => {
  const classNames: string[] = [styles.section];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      {title ? <span className={styles.sectionTitle}>{title}</span> : null}
      {children}
    </div>
  );
};

export default ContextMenuSection;
