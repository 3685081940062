import { UserPlantVariety, UserSeasonExtenderSettings } from '@gi/user';
import Plant from '@gi/plant';
import { DEFAULT_VARIETY } from '@gi/constants';

import { createPlantingCalendarFromNumbers, PlantingCalendar, EMPTY_PLANTING_CALENDAR } from './planting-calendar';
import { UserRegionalPlantingCalendar } from './regional-planting-calendar';
import { calculatePlantingCalendar, calculateRegionalPlantingCalendar, calculateSplitSeasonCalendar } from './calculators/planting-calendar-calculator';
import { NumberedPlantingCalendar } from './planting-calendar-types';

export type UserPlantingCalendars = Readonly<{
  base: { [plantCode: string]: PlantingCalendar }; // Default planting calendars with no season extenders
  modifiers: { [modifierType: string]: { [plantCode: string]: PlantingCalendar } }; // Planting calendars with each season extender applied
  custom: { [plantCode: string]: { [variety: string]: PlantingCalendar } }; // User set planting calendars
  search: { [plantCode: string]: PlantingCalendar };
}>;

export const EMPTY_USER_PLANTING_CALENDARS: UserPlantingCalendars = {
  base: {},
  modifiers: {},
  custom: {},
  search: {},
};

function customHasPlantAndVariety(
  customPlantingCalendars: { [plantCode: string]: { [variety: string]: PlantingCalendar } },
  plantCode: string,
  varietyName: string
): boolean {
  return !!(customPlantingCalendars[plantCode] && customPlantingCalendars[plantCode][varietyName]);
}

/**
 * Creates a map of plant Code -> PlantingCalendar, where the planting calendar is the users custom planting calendar if available
 */
function createSearchPlantingCalendars(
  base: { [plantCode: string]: PlantingCalendar },
  custom: { [plantCode: string]: { [variety: string]: PlantingCalendar } }
): Readonly<{ [plantCode: string]: PlantingCalendar }> {
  const searchPlantingCalendars: { [plantCode: string]: PlantingCalendar } = {};
  const basePlants = Object.keys(base);

  for (let i = 0; i < basePlants.length; i++) {
    searchPlantingCalendars[basePlants[i]] = customHasPlantAndVariety(custom, basePlants[i], DEFAULT_VARIETY)
      ? custom[basePlants[i]][DEFAULT_VARIETY]
      : base[basePlants[i]];
  }

  return searchPlantingCalendars;
}

/**
 * Creates a map of plantCode => PlantingCalendar from a users customised plant varieties
 */
function getCustomPlantingCalendars(userPlantVarieties: readonly UserPlantVariety[]): {
  [plantCode: string]: { [varietyName: string]: PlantingCalendar };
} {
  const custom: { [plantCode: string]: { [varietyName: string]: PlantingCalendar } } = {};
  for (let i = 0; i < userPlantVarieties.length; i++) {
    // Only create custom calendar for the user variety if it supposed to be used
    if (userPlantVarieties[i].customCalendar) {
      if (!custom[userPlantVarieties[i].plantCode]) {
        custom[userPlantVarieties[i].plantCode] = {};
      }

      custom[userPlantVarieties[i].plantCode][userPlantVarieties[i].name] = createPlantingCalendarFromNumbers(
        userPlantVarieties[i].plantingCalendar as unknown as NumberedPlantingCalendar,
        true,
        null
      );
    }
  }

  return custom;
}

function calculateRegionalPlantingCalendars(
  modifierTypes: readonly string[],
  plants: readonly Plant[],
  userSeasonExtenderSettings: Readonly<UserSeasonExtenderSettings>,
  regionalPlantingCalendars: Readonly<UserRegionalPlantingCalendar> | null,
  userPlantVarieties: readonly UserPlantVariety[]
): UserPlantingCalendars {
  const base: { [plantCode: string]: PlantingCalendar } = {};
  const modifiers: { [modifierType: string]: { [plantCode: string]: PlantingCalendar } } = {};
  const custom = getCustomPlantingCalendars(userPlantVarieties);

  if (regionalPlantingCalendars === null) {
    // This should happen but we should handle it nicely
    return {
      base,
      modifiers,
      custom,
      search: createSearchPlantingCalendars(base, custom),
    };
  }

  // Initialise modifiers structure
  for (let i = 0; i < modifierTypes.length; i++) {
    modifiers[modifierTypes[i]] = {};
  }

  // Go through each plant and calculate planting calendars for each one
  for (let i = 0; i < plants.length; i++) {
    const plant = plants[i];

    base[plant.code] = regionalPlantingCalendars[plant.code] ? regionalPlantingCalendars[plant.code].plantingCalendar : EMPTY_PLANTING_CALENDAR;

    for (let j = 0; j < modifierTypes.length; j++) {
      modifiers[modifierTypes[j]][plant.code] = calculateRegionalPlantingCalendar(
        plant.code,
        regionalPlantingCalendars,
        modifierTypes[j],
        userSeasonExtenderSettings
      );
    }
  }

  return {
    base,
    modifiers,
    custom,
    search: createSearchPlantingCalendars(base, custom),
  };
}

function calculateFrostBasedPlantingCalendars(
  modifierTypes: readonly string[],
  plants: readonly Plant[],
  userSeasonExtenderSettings: Readonly<UserSeasonExtenderSettings>,
  lastFrostDay: number,
  firstFrostDay: number,
  splitSeason: boolean,
  userPlantVarieties: readonly UserPlantVariety[]
): UserPlantingCalendars {
  const base: { [plantCode: string]: PlantingCalendar } = {};
  const modifiers: { [modifierType: string]: { [plantCode: string]: PlantingCalendar } } = {};
  const custom = getCustomPlantingCalendars(userPlantVarieties);

  // Initialise modifiers structure
  for (let i = 0; i < modifierTypes.length; i++) {
    modifiers[modifierTypes[i]] = {};
  }

  // Go through each plant and calculate planting calendars for each one
  for (let i = 0; i < plants.length; i++) {
    const plant = plants[i];

    if (splitSeason && plant.canSplitSeason) {
      base[plant.code] = calculateSplitSeasonCalendar(plant, lastFrostDay, firstFrostDay, null, userSeasonExtenderSettings);
    } else {
      base[plant.code] = calculatePlantingCalendar(plant, lastFrostDay, firstFrostDay, null, userSeasonExtenderSettings);
    }

    for (let j = 0; j < modifierTypes.length; j++) {
      if (splitSeason && plant.canSplitSeason) {
        modifiers[modifierTypes[j]][plant.code] = calculateSplitSeasonCalendar(
          plant,
          lastFrostDay,
          firstFrostDay,
          modifierTypes[j],
          userSeasonExtenderSettings
        );
      } else {
        modifiers[modifierTypes[j]][plant.code] = calculatePlantingCalendar(plant, lastFrostDay, firstFrostDay, modifierTypes[j], userSeasonExtenderSettings);
      }
    }
  }

  return {
    base,
    modifiers,
    custom,
    search: createSearchPlantingCalendars(base, custom),
  };
}

export const calculateUserPlantingCalendars = (
  useRegionalPlanting: boolean,
  regionalPlantingCalendars: null | UserRegionalPlantingCalendar,
  modifierTypes: Readonly<string[]>,
  plants: readonly Plant[],
  userSeasonExtenderSettings: UserSeasonExtenderSettings,
  lastFrostDay: number,
  firstFrostDay: number,
  splitSeason: boolean,
  userPlantVarieties: UserPlantVariety[]
): UserPlantingCalendars => {
  if (useRegionalPlanting) {
    return calculateRegionalPlantingCalendars(modifierTypes, plants, userSeasonExtenderSettings, regionalPlantingCalendars, userPlantVarieties);
  }

  return calculateFrostBasedPlantingCalendars(modifierTypes, plants, userSeasonExtenderSettings, lastFrostDay, firstFrostDay, splitSeason, userPlantVarieties);
};

export function createGetUserPlantingCalendar(userPlantingCalendars: null | UserPlantingCalendars) {
  return function getUserPlantingCalendar(plantCode: string, modifier: null | string, varietyName: string): PlantingCalendar {
    if (userPlantingCalendars === null) {
      return EMPTY_PLANTING_CALENDAR;
    }

    if (userPlantingCalendars.custom[plantCode] && userPlantingCalendars.custom[plantCode][varietyName]) {
      // If there's a custom calendar for this plant and variety, return that
      return userPlantingCalendars.custom[plantCode][varietyName];
    }

    if (modifier === null) {
      // No modifier, return base planting times
      if (userPlantingCalendars.base[plantCode]) {
        return userPlantingCalendars.base[plantCode];
      }
      return EMPTY_PLANTING_CALENDAR;
    }

    if (!userPlantingCalendars.modifiers[modifier]) {
      console.error('Unrecognised modifier: ', modifier);
      return EMPTY_PLANTING_CALENDAR;
    }

    if (userPlantingCalendars.modifiers[modifier][plantCode]) {
      // No planting calendar found
      return userPlantingCalendars.modifiers[modifier][plantCode];
    }

    return EMPTY_PLANTING_CALENDAR;
  };
}

/**
 * When a user changes their custom plant varieties we need to update the userPlantingCalendars custom calendars
 */
export function updateUserPlantingCalendars(userPlantingCalendars: UserPlantingCalendars, userPlantVarieties: UserPlantVariety[]): UserPlantingCalendars {
  console.debug('Updating user planting calendars');

  const custom = getCustomPlantingCalendars(userPlantVarieties);
  const search = createSearchPlantingCalendars(userPlantingCalendars.base, custom);
  return {
    ...userPlantingCalendars,
    custom,
    search,
  };
}
