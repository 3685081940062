import React from 'react';

import Modal from '../modal';
import ModalHeader from '../header/modal-header';
import ModalContent from '../modal-content';
import ModalPaneContainer from '../modal-pane-container';
import ModalPane from '../modal-pane/modal-pane';
import ModalCloseButton from '../header/modal-close-button';

interface iProps {
  children: React.ReactNode;
  className?: string;
  closeModal: () => void;
  style?: React.CSSProperties;
  headerText: string;
}

const HelpModal = ({ children, className = '', closeModal, style = {}, headerText }: iProps) => {
  return (
    <Modal className={`${className} help-modal`} closeRequest={closeModal} style={style}>
      <ModalContent>
        <ModalCloseButton onClick={closeModal} />
        <ModalHeader>{headerText}</ModalHeader>
        <ModalPaneContainer>
          <ModalPane>{children}</ModalPane>
        </ModalPaneContainer>
      </ModalContent>
    </Modal>
  );
};

export default HelpModal;
