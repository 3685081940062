import { LocalSettings } from './local-settings-type';

interface State {
  localSettings: LocalSettings;
}

export const getLocalSettings = (state: State) => state.localSettings;

export const getWheelMode = (state: State) => state.localSettings.wheelMode;
export const getPlantDisplayMode = (state: State) => state.localSettings.plantDisplayMode;
export const getPlantSpriteCountLimit = (state: State) => state.localSettings.plantSpriteCountLimit;
export const getSnapToGrid = (state: State) => state.localSettings.snapToGrid;
export const getShowEditToolbar = (state: State) => state.localSettings.showEditToolbar;
export const getShowViewToolbar = (state: State) => state.localSettings.showViewToolbar;
export const getShowLayerToolbar = (state: State) => state.localSettings.showLayerToolbar;
export const getShowMonthToolbar = (state: State) => state.localSettings.showMonthToolbar;
export const getShowCropRotationToolbar = (state: State) => state.localSettings.showCropRotationToolbar;
export const getDebugMode = (state: State) => state.localSettings.debugMode;
export const getInitialised = (state: State) => state.localSettings.initialised;
export const getSfgMode = (state: State) => state.localSettings.sfgMode;
export const getShowSFGHelpOnToggle = (state: State) => state.localSettings.showSFGHelpOnToggle;
export const getEnableSnapOnSFGMode = (state: State) => state.localSettings.enableSnapOnSFGMode;
export const getShowTouchIntroductionModalOnStart = (state: State) => state.localSettings.showTouchIntroductionModalOnStart;
export const getRenderMode = (state: State) => state.localSettings.renderMode;
export const getCustomDevicePixelRatio = (state: State) => state.localSettings.customDevicePixelRatio;
export const getDevicePixelRatio = (state: State) => state.localSettings.devicePixelRatio;
export const getDontShowDrawingToolsDragWarning = (state: State) => state.localSettings.dontShowDrawingToolsDragWarning;
export const getTouchMode = (state: State) => state.localSettings.touchMode;
export const getDeviceDisplayMode = (state: State) => state.localSettings.deviceDisplayMode;
export const getHideIntercomIntroduction = (state: State) => state.localSettings.hideIntercomIntroduction;
export const getHideTouchControlsTutorial = (state: State) => state.localSettings.hideTouchControlsTutorial;
export const getUseCustomCursors = (state: State) => state.localSettings.useCustomCursors;
export const getCustomCursorScale = (state: State) => state.localSettings.customCursorScale;
export const getHideTouchDragHelpNotifications = (state: State) => state.localSettings.hideTouchDragHelpNotifications;
export const getTouchDragRequiresSelection = (state: State) => state.localSettings.touchDragRequiresSelection;
export const getHideItemLockingHelpNotifications = (state: State) => state.localSettings.hideItemLockingHelpNotifications;
export const getMinimiseRightSidebar = (state: State) => state.localSettings.minimiseRightSidebar;
export const getRightSidebarActiveTab = (state: State) => state.localSettings.rightSidebarActiveTab;
export const getTextQuality = (state: State) => state.localSettings.textQuality;
