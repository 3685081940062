import { SpriteSourceType } from './constants';

export type SpriteSource = {
  type: SpriteSourceType.SINGLE;
  width: number;
  height: number;
  name: string;
};

export function createSpriteSource(spriteSource: Omit<SpriteSource, 'type'>): SpriteSource {
  return {
    ...spriteSource,
    type: SpriteSourceType.SINGLE,
  };
}
