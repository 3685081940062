import React from 'react';

import styles from './planner-content.module.css';

interface iProps {
  className?: string;
  children: React.ReactNode;
}

const PlannerContentContainer = ({ className, children }: iProps): JSX.Element => {
  return <div className={`${styles.plannerContent} ${className ? className : ''}`}>{children}</div>;
};

export default PlannerContentContainer;
