import Bitmask from '@gi/bitmask';
import { StateDef } from '@gi/state';
import { DisplayModeComponent, DisplayModeFlag, StatefulNode } from '@gi/core-renderer';

import { getDisplayModeBitmask } from './display-mode-utils';

export interface GardenItemNodeState {
  locked: boolean;
  zIndex: number;
}

/**
 * Base GardenItem node that most garden items should extend
 * Will handle locking and z-indexing automatically.
 */
class GardenItemNode<T extends StateDef<GardenItemNodeState>> extends StatefulNode<T> {
  readonly id: number;
  readonly displayMode: DisplayModeComponent;

  constructor(id: number, initialState: T['state'], layer) {
    super(initialState);

    this.id = id;
    this.zIndex = id;

    this.displayMode = this.components.add(
      new DisplayModeComponent({
        flags: getDisplayModeBitmask(layer, initialState.locked),
      })
    );

    this.state.addWatcher(
      (state) => {
        // Update the "locked" displaymode flag whenever locked status changes
        let newFlags = this.displayMode.state.values.flags;
        newFlags = Bitmask.Set(newFlags, state.values.locked, DisplayModeFlag.LOCKED);
        newFlags = Bitmask.Set(newFlags, !state.values.locked, DisplayModeFlag.UNLOCKED);
        this.displayMode.state.values.flags = newFlags;
      },
      { properties: ['locked'] }
    );

    this.state.addWatcher(
      (state) => {
        // Use the item zIndex as a zGroup, and itemId as zIndex, as we want to order by zIndex, then by itemId
        this.zGroup = state.values.zIndex;
      },
      { properties: ['zIndex'] }
    );
  }

  setLocked(locked: boolean) {
    this.state.values.locked = locked;
  }

  setZIndex(zIndex: number) {
    this.state.values.zIndex = zIndex;
  }
}

export default GardenItemNode;
