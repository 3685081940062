/* eslint-disable quote-props */
import { networkConfig } from '@gi/config';

const iconMap = {
  '00': '00',
  '01': '01',
  '02': '02',
  '03': '03',
  '04': '04',
  '05': '05',
  '06': '06',
  '07': '07',
  '08': '08',
  '09': '09',
  '10': '10',
  '11': '11',
  '12': '12',
  '13': '13',
  '14': '14',
  '15': '15',
  '16': '16',
  '17': '17',
  '18': '18',
  '19': '19',
  '20': '20',
  '21': '21',
  '22': '22',
  '23': '23',
  '24': '24',
  '25': '25',
  '26': '26',
  '27': '27',
  '28': '28',
  '29': '29',
  '30': '30',
  '31': '31',
  '32': '32',
  '33': '33',
  '34': '34',
  '35': '35',
  '36': '36',
  '37': '37',
  '38': '38',
  '39': '39',
  '40': '40',
  '41': '41',
  '42': '42',
  '43': '43',
  '44': '44',
  '45': '45',
  '46': '46',
  '47': '47',
  na: 'na',
  'partly-cloudy-day': '30',
  'partly-cloudy-night': '29',
  sleet: '07',
  snow: '14',
  wind: '24',
  rain: '12',
  'clear-day': '32',
  fog: '19',
  cloudy: '26',
  'clear-night': '31',
};

export function getForcastIconID(id: string): string {
  if (iconMap[id]) {
    return iconMap[id];
  }

  console.error('Invalid weather icon requested: ', id);
  return 'na';
}

export function getForcastIconURL(id: string): string {
  return `${networkConfig.weatherIconsBaseURL}${getForcastIconID(id)}.png`;
}
