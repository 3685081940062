import Plant, { PlantUtils, PlantAreaCount } from '@gi/plant';
import { UserPlantVariety } from '@gi/user';
import Collection from '@gi/collection';
import { dist } from '@gi/math/source/geometry';

import { PlanPlant } from './plan-plant';

export function getPlanPlantWidth(planPlant: PlanPlant): number {
  return dist(planPlant.rowStart, planPlant.rowEnd);
}

/**
 * Returns whether the given PlanPlant is a perennial by finding the plant in the plants collection
 */
export function isPerennial(planPlant: PlanPlant, plants: Collection<Plant>): boolean {
  const plant = plants.get(planPlant.plantCode);

  if (plant === null) {
    console.warn('Plan plant not found in collection when checking if perennial', planPlant.plantCode);
    return false;
  }

  return plant.perennial;
}

/**
 * Returns whether the given PlanPlant is over-winter
 *
 * Over winter is true if the plant is not 'inGroundAll' (i.e. in the ground the whole year, probably because in-ground dates have not been set)
 * and the in-ground start happens after the in-ground end (thus, end is the next year)
 */
export function isOverWinter(planPlant: PlanPlant): boolean {
  return !planPlant.inGroundAll && planPlant.inGroundStart > planPlant.inGroundEnd;
}

/**
 * Returns plant counts for a given PlanPlant
 */
export function getPlanPlantCount(planPlant: PlanPlant, plant: Plant, userPlantVariety: null | UserPlantVariety = null): PlantAreaCount {
  const spacings = PlantUtils.getSpacings(plant, userPlantVariety);

  const width = getPlanPlantWidth(planPlant);

  return PlantUtils.getPlantCount(planPlant.isSquareFoot, spacings.sfgCount, width, planPlant.height, spacings.spacing, spacings.inRowSpacing);
}
