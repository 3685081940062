import React from 'react';

import styles from './content-page.module.css';

interface iProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
}

const ContentPageContentColumn = ({ children, ...props }: iProps): JSX.Element => {
  return <div className={`${props.className ? props.className : ''} ${styles.contentPageContentColumn}`}>{children}</div>;
};

export default ContentPageContentColumn;
