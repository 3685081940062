/* eslint-disable no-restricted-syntax */
import React from 'react';
import moment, { Moment } from 'moment-timezone';

/**
 * Designed to supply as a callback to Array.filter to produce a unique array
 *
 * @param value the value of the current element
 * @param index the index of the current element
 * @param self the array being filtered
 */
export const unique = (value: any, index: number, self: any[]) => {
  return self.indexOf(value) === index;
};

/**
 * Takes an array of e.g. strings, elements, or components and joins them nicely with commas
 *  e.g. oxfordCommaJoin(['a', 'b', 'c']) // "a, b, and c"
 *       oxfordCommaJoin(['a', 'b'], 'or') // "a or b"
 *
 * @param arr array of elements to join
 * @param andOr A string to put before the last element, e.g. 'and' or 'or'. Default: 'and'
 */
export const oxfordCommaJoin = (arr: any[], andOr: string = 'and') => {
  if (arr.length === 1) {
    return arr[0];
  }
  if (arr.length === 2) {
    return (
      <>
        {arr[0]} {andOr} {arr[1]}
      </>
    );
  }
  return (
    <>
      {arr.slice(0, arr.length - 1).map((el) => (
        <>{el}, </>
      ))}{' '}
      {andOr} {arr[arr.length - 1]}
    </>
  );
};

export const upperFirst = (str: string) => {
  return `${str.substring(0, 1).toLocaleUpperCase()}${str.substring(1).toLocaleLowerCase()}`;
};

export const getWeekNumberFromDate = (date: string | number | Date, weekStartDay: number = 6) => {
  const currentDate = new Date(date);
  // Start at the first of Jan
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  // Modify the date so it begins on the closest weekStartDay
  startOfYear.setDate(startOfYear.getDate() + (weekStartDay - startOfYear.getDay()));
  // Convert to a weekNo.
  const numberOfDays = Math.floor((currentDate.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000));
  const weekNo = Math.ceil((currentDate.getDay() + 1 + numberOfDays) / 7);
  return weekNo;
};

export function setsEqual<T>(a: Set<T>, b: Set<T>): boolean {
  if (a.size !== b.size) {
    return false;
  }

  // TODO - review eslint errors around of loops
  for (const val of a) {
    if (!b.has(val)) {
      return false;
    }
  }

  return true;
}

export function getSetDiff<T>(a: Set<T>, b: Set<T>) {
  const results: { added: T[]; removed: T[] } = {
    added: [],
    removed: [],
  };

  for (const val of a) {
    if (!b.has(val)) {
      results.removed.push(val);
    }
  }

  for (const val of b) {
    if (!a.has(val)) {
      results.added.push(val);
    }
  }

  return results;
}

export function getArrayDiff<T>(a: T[], b: T[]) {
  const results: { added: T[]; removed: T[] } = {
    added: b.filter((x) => !a.includes(x)),
    removed: a.filter((x) => !b.includes(x)),
  };
  return results;
}

export const parseDate = (dateString) => {
  return moment.tz(dateString, 'GMT');
};

export const dateToAPI = (date: Moment) => {
  const str1 = date.valueOf();
  const str2 = date.format('ZZ');

  return `/Date(${str1}${str2})/`;
};

/**
 * Compares the properties provided by the properties array and returns true if they are equal on both objects,
 * else returns false.
 *
 * Only does a shallow comparison
 */
export function propertiesAreEqual<T extends Record<string, unknown>>(a: T, b: T, properties: (keyof T)[]) {
  for (let i = 0; i < properties.length; i++) {
    if (a[properties[i]] !== b[properties[i]]) {
      return false;
    }
  }

  return true;
}

/**
 * Converts a duration in ms to '?m ?s ?ms', where appropriate.
 * @param duration The duration, in ms
 * @returns A slightly prettier string representation
 */
export function getDurationText(duration: number): string {
  if (duration < 1000) {
    // <1s
    return `${duration}ms`;
  }
  if (duration < 1000 * 60) {
    // <1min
    const seconds = Math.floor(duration / 1000);
    const remaining = duration % 1000;
    return `${seconds}s ${getDurationText(remaining)}`;
  }
  // >= 1min
  const minutes = Math.floor(duration / (1000 * 60));
  const remaining = duration % (1000 * 60);
  return `${minutes}m ${getDurationText(remaining)}`;
}
