import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import Collection from '@gi/collection';
import { Pest } from '@gi/pest';
import Plant from '@gi/plant';
import { ResourceContext } from '@gi/resource-provider';
import { GuruPages } from '@gi/app-guru-types';
import { ThunkExtraArgs } from '@gi/garden-platform-services';

const SetGuruServicePlantData = (plants: Collection<Plant>): ThunkAction<void, any, ThunkExtraArgs, AnyAction> => {
  return (_dispatch, _getState, { services }) => {
    services.guruService.setPlantData(plants);
  };
};

const SetGuruServicePestData = (pests: Collection<Pest>): ThunkAction<void, any, ThunkExtraArgs, AnyAction> => {
  return (_dispatch, _getState, { services }) => {
    services.guruService.setPestData(pests);
  };
};

const SetGuruServicePageData = (pages: GuruPages): ThunkAction<void, any, ThunkExtraArgs, AnyAction> => {
  return (_dispatch, _getState, { services }) => {
    services.guruService.setPageData(pages);
  };
};

export const GuruServiceDataSynchroniser = (): null => {
  const { userPlants, userPests, guruPages } = useContext(ResourceContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(SetGuruServicePlantData(userPlants));
  }, [userPlants]);

  useEffect(() => {
    dispatch(SetGuruServicePestData(userPests));
  }, [userPests]);

  useEffect(() => {
    if (guruPages) {
      dispatch(SetGuruServicePageData(guruPages));
    }
  }, [guruPages]);

  return null;
};
