import { PlannerState } from './planner-slice';

type RootState = {
  planner: PlannerState;
};

export const getShowTouchIntroductionModal = (state: RootState) => state.planner.showTouchIntroductionModal;
export const getShowTouchDragHelpModal = (state: RootState) => state.planner.showTouchDragHelpModal;
export const getShowItemLockingHelpModal = (state: RootState) => state.planner.showItemLockingHelpModal;
export const getOpenPlanStates = (state: RootState) => state.planner.openPlanStates;
