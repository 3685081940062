import React, { useContext, useState } from 'react';

import Plant from '@gi/plant';
import { DistanceUnits } from '@gi/units';

import { PlantListVarietyGroup } from '../types/plant-list-types';
import TableRow from '../common/table-row';
import InformationCell from './cells/information-cell';
import FavouriteCell from './cells/favourite-cell';
import IconCell from './cells/icon-cell';
import SpacingsCell from './cells/spacings-cell';
import CalendarCell from './cells/calendar-cell';
import NotesCell from './cells/notes-cell';
import PlantNameCell from './cells/plant-name-cell';
import InsetAreaTableRows from './inset-area-table-rows';
import ShowAreaTableCell from './cells/show-area-table-cell';
import CountsCell from './cells/counts-cell';
import GlobalPlantListContext from '../global-provider/global-plant-list-context';

interface iProps {
  varietyGroup: PlantListVarietyGroup;
  plant: Plant;
  evenRow?: boolean;
  distanceUnits: DistanceUnits;
}

const VarietyTableRow = ({ varietyGroup, plant, evenRow = true, distanceUnits }: iProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const { varietyGroupFilters, columns } = useContext(GlobalPlantListContext);

  const { plantSearchResults, varietySearchResults } = varietyGroupFilters.filters.search.inputs;

  return (
    <>
      <TableRow className={`variety-tr main-tr ${evenRow ? 'tr-even' : 'tr-odd'}`}>
        <ShowAreaTableCell expanded={expanded} setExpanded={setExpanded} />
        <InformationCell plant={plant} />
        <FavouriteCell plant={plant} />
        <IconCell plantCode={plant.code} familyID={plant.familyID} />
        <PlantNameCell plant={plant} varietyName={varietyGroup.variety} plantSearchResults={plantSearchResults} varietySearchResults={varietySearchResults} />
        <SpacingsCell spacings={varietyGroup.spacing} countsByType={varietyGroup.countsByType} distanceUnits={distanceUnits} />
        <CountsCell count={varietyGroup.count} />
        <CalendarCell plantingCalendar={varietyGroup.plantingCalendar} />
        <NotesCell plantNote={varietyGroup.plantNote} plantCode={varietyGroup.plant.code} varietyName={varietyGroup.variety} />
      </TableRow>
      {expanded && columns.area ? <InsetAreaTableRows className={`${evenRow ? 'tr-even' : 'tr-odd'}`} areaGroups={varietyGroup.areas} /> : null}
    </>
  );
};

export default VarietyTableRow;
