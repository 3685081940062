import Plant, { PlantSpacings } from '@gi/plant';
import { UserPlantVariety } from '@gi/user';
import { PlanPlant } from '@gi/plan';
import { PlantingCalendar } from '@gi/planting-calendar';

export enum TableViewMode {
  AUTO = 'AUTO',
  GRID = 'GRID',
  TABLE = 'TABLE',
}

export interface PlantListColumns {
  spacings: boolean;
  counts: boolean;
  calendar: boolean;
  area: boolean;
  notes: boolean;
  plantLabel: boolean;
  inGroundDates: boolean;
}

export type ModifierCounts = {
  coldFrame: number;
  cloche: number;
  rowCover: number;
  greenhouse: number;
  heatedGreenhouse: number;
  polytunnel: number;
  heatedPolytunnel: number;
  none: number;
};

export type TypeCounts = {
  area: number;
  sfg: number;
};

export type PlantListData = {
  plants: PlantListSummaryGroup[];
  varieties: PlantListVarietyGroup[];
  areas: PlantListAreaGroup[];
};

export type PlantListSummaryGroup = {
  plant: Plant;
  plantName: string;
  spacing: PlantSpacings;
  count: number;
  countsByModifier: ModifierCounts;
  countsByType: TypeCounts;
  areas: PlantListAreaGroup[];
  plantNote: string;
  plantingCalendar: PlantingCalendar | null;
};

export type PlantListVarietyGroup = {
  plant: Plant;
  plantName: string;
  variety: string;
  userVariety: UserPlantVariety | null;
  modifier: string | null;
  spacing: PlantSpacings;
  count: number;
  countsByType: TypeCounts;
  areas: PlantListAreaGroup[];
  plantNote: string;
  plantingCalendar: PlantingCalendar | null;
};

export type PlantListAreaGroup = {
  plantName: string;
  plant: Plant;
  variety: string;
  planPlant: PlanPlant;
  spacing: PlantSpacings;
  count: number;
  countsByType: TypeCounts;
  modifier: null | string;
  plantingCalendar: PlantingCalendar | null;
};

export interface PlantListOptions {
  showScientificName: boolean;
  textPlantingCalendars: boolean;
  expandPlantAreas: boolean;
  showPlantFamily: boolean;
  printBackgroundColors: boolean;
  viewMode: TableViewMode;
}

export enum SORT_ORDER {
  PlantNameAsc = ' Plant Name Asc',
  PlantNameDesc = ' Plant Name Desc',
  InGroundStartAsc = ' In-Ground Start Asc',
  InGroundStartDesc = 'In-Ground Start Desc',
  InGroundEndAsc = 'In-Ground End Asc',
  InGroundEndDesc = 'In-Ground End Desc',
  PlantLabelAsc = 'Plant Label Asc',
  PlantLabelDesc = 'Plant Label Desc',
  QuantityAsc = 'Quantity Asc',
  QuantityDesc = 'Quantity Desc',
  SowStartAsc = 'Sow Asc',
  SowStartDesc = 'Sow Desc',
  SowEndAsc = 'Sow End Asc',
  SowEndDesc = 'Sow End Desc',
  PlantStartAsc = 'Plant Start Asc',
  PlantStartDesc = 'Plant Start Desc',
  PlantEndAsc = 'Plant End Asc',
  PlantEndDesc = 'Plant End Desc',
  HarvestStartAsc = 'Harvest Start Asc',
  HarvestStartDesc = 'Harvest Start Desc',
  HarvestEndAsc = 'Harvest End Asc',
  HarvestEndDesc = 'Harvest End Desc',
}

export const SortOrderLabels: { [key in SORT_ORDER]: string } = {
  [SORT_ORDER.PlantNameAsc]: ' Plant Name',
  [SORT_ORDER.PlantNameDesc]: ' Plant Name',
  [SORT_ORDER.InGroundStartAsc]: ' In-Ground Start',
  [SORT_ORDER.InGroundStartDesc]: 'In-Ground Start',
  [SORT_ORDER.InGroundEndAsc]: 'In-Ground End',
  [SORT_ORDER.InGroundEndDesc]: 'In-Ground End',
  [SORT_ORDER.PlantLabelAsc]: 'Plant Label',
  [SORT_ORDER.PlantLabelDesc]: 'Plant Label',
  [SORT_ORDER.QuantityAsc]: 'Quantity',
  [SORT_ORDER.QuantityDesc]: 'Quantity',
  [SORT_ORDER.SowStartAsc]: 'Sow Indoors',
  [SORT_ORDER.SowStartDesc]: 'Sow Indoors',
  [SORT_ORDER.SowEndAsc]: 'Sow Indoors End',
  [SORT_ORDER.SowEndDesc]: 'Sow Indoors End',
  [SORT_ORDER.PlantStartAsc]: 'Plant/Sow Outdoors',
  [SORT_ORDER.PlantStartDesc]: 'Plant/Sow Outdoors',
  [SORT_ORDER.PlantEndAsc]: 'Plant/Sow Outdoors End',
  [SORT_ORDER.PlantEndDesc]: 'Plant/Sow Outdoors End',
  [SORT_ORDER.HarvestStartAsc]: 'Harvest',
  [SORT_ORDER.HarvestStartDesc]: 'Harvest',
  [SORT_ORDER.HarvestEndAsc]: 'Harvest End',
  [SORT_ORDER.HarvestEndDesc]: 'Harvest End',
};
