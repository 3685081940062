import { DeviceDisplayMode } from '@gi/constants';

/**
 * The minimum screen width and height needed for desktop mode to be considered.
 * Below this width, with drawing tools open, there is barely any canvas usable.
 * Below this height, with all the toolbars on, the drawing tools begin to get cut.
 */
const MIN_DESKTOP_SIZE: Dimensions = {
  width: 800,
  height: 520,
};

/**
 * The minimum size a touchscreen device needs to be to be considered a tablet.
 * There seems to be a very convenient gap between iPhones and iPads at around this size.
 * Spreadsheet with iPhone and iPad screen sizes:
 *  https://growinginteractive-my.sharepoint.com/:x:/p/d_wells/EXtvBV18MxxPuABHmDj7i1wBfOSVhi7AGucaFsK_6k-9pw?e=gEahCF
 */
const MIN_TABLET_SIZE: Dimensions = {
  width: 500,
  height: 1000,
};

/**
 * Returns true if the browser window/device appears to be in landscape mode.
 */
export const isLandscape = () => {
  if (window.matchMedia) {
    return window.matchMedia('(orientation: landscape)').matches;
  }
  return window.outerWidth > window.outerHeight;
};

/**
 * Returns true if the device appears to have any form of touch input method
 */
export const hasTouchscreen = () => {
  if (window.matchMedia) {
    return window.matchMedia('(any-pointer: coarse)').matches;
  }
  return 'onstarttouch' in window || window.navigator.maxTouchPoints > 0;
};

/**
 * Returns true if the user has any accurate pointing device connected (like a mouse)
 */
export const hasMouse = () => {
  if (window.matchMedia) {
    return window.matchMedia('(any-pointer: fine)').matches;
  }
  // There's no other good way to detect this, so assume true
  return true;
};

/**
 * Returns true if the device appears to use touch as its primary input method.
 */
export const isTouchscreen = () => {
  if (window.matchMedia) {
    return window.matchMedia('(pointer: coarse)').matches;
  }
  return 'onstarttouch' in window || window.navigator.maxTouchPoints > 0;
};

/**
 * Returns true if the width and height of the window is big enough to accommodate desktop mode.
 * Attempt to use the matchMedia queries if possible, as they will correctly account for text scaling,
 *  as 1px of window != 1px of CSS if font size is turned up.
 */
export const isDesktopCapable = () => {
  if (window.matchMedia) {
    return window.matchMedia(`(min-width: ${MIN_DESKTOP_SIZE.width}px) and (min-height: ${MIN_DESKTOP_SIZE.height}px)`).matches;
  }
  return window.innerWidth >= MIN_DESKTOP_SIZE.width && window.innerHeight >= MIN_DESKTOP_SIZE.height;
};

/**
 * Returns true if this device appears to be larger than a phone.
 * This assumes the smallest dimension is the width, and the largest is the height,
 *  to prevent the display mode changing with orientation.
 */
export const isLargerThanPhone = () => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  return Math.min(screenWidth, screenHeight) > MIN_TABLET_SIZE.width && Math.max(screenWidth, screenHeight) > MIN_TABLET_SIZE.width;
};

/**
 * Works out the best display mode for the user's current device and screen size.
 */
export const getBestDisplayMode = () => {
  // If the user is on a touchscreen device, use mobile display.
  if (isTouchscreen()) {
    // If the screen is very big, still use desktop.
    if (isDesktopCapable() && isLargerThanPhone()) {
      return DeviceDisplayMode.DESKTOP;
    }
    return DeviceDisplayMode.MOBILE;
  }
  // If the user has a small screen, use mobile display.
  if (!isDesktopCapable()) {
    return DeviceDisplayMode.MOBILE;
  }
  // If the user isn't touchscreen and has a big screen.
  return DeviceDisplayMode.DESKTOP;
};

/**
 * Returns true if the user's screen is at least as wide as the px value given
 * @param px The desired screen width in px
 * @returns True if the screen is wide enough
 */
export const isMinWidth = (px: number): boolean => {
  if (window.matchMedia) {
    return window.matchMedia(`(min-width: ${px}px)`).matches;
  }
  return window.innerWidth >= MIN_DESKTOP_SIZE.width;
};

/**
 * Returns true if the user's screen is at least as tall as the px value given
 * @param px The desired screen height in px
 * @returns True if the screen is tall enough
 */
export const isMinHeight = (px: number): boolean => {
  if (window.matchMedia) {
    return window.matchMedia(`(min-height: ${px}px)`).matches;
  }
  return window.innerHeight >= MIN_DESKTOP_SIZE.width;
};
