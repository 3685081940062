import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';

import LoadingButton from '@gi/loading-button';

import {
  Modal,
  ModalPane,
  ModalPaneContent,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalHeaderContent,
  ModalHeaderTitle,
} from '@gi/modal';

import FormField, { createFormValues, FormSection, InputContainer } from '@gi/form-responsive';

import { JOURNAL_DATE_FORMAT, ReminderData } from './reminder';

import './reminder-modal.scss';

const MAX_JOURNAL_ENTRY_LIMIT = 8000;

export enum ReminderModalMode {
  Add = 'Add',
  Edit = 'Edit',
}

interface iProps {
  reminder: ReminderData;
  close: () => void;
  save: (reminder: ReminderData) => void;
  saving: boolean;
  mode: ReminderModalMode;
  longDateFormat: string;
}

type ReminderForm = {
  date: string;
  note: string;
  dismissed: boolean;
};

const ReminderModal = ({ reminder, close, save, saving, mode, longDateFormat }: iProps): JSX.Element => {
  const [reminderForm, setReminderform] = useState(
    createFormValues<ReminderForm>({
      date: { value: reminder.dateYMD },
      note: { value: reminder.note },
      dismissed: { value: reminder.dismissed },
    })
  );

  const title = mode === ReminderModalMode.Add ? 'Add Reminder' : 'Edit Reminder';
  const buttonText = mode === ReminderModalMode.Add ? 'Add' : 'Save';

  const onSetDate = (date: Date, e) => {
    // We need to stop propagation so that the wrapping label does not catch the click event and cause the picker
    // to re-open. See: https://github.com/Hacker0x01/react-datepicker/issues/1012
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    setReminderform(reminderForm.setValue('date', { value: moment(date).format(JOURNAL_DATE_FORMAT) }));
  };

  return (
    <Modal className='reminder-modal' closeRequest={close}>
      <ModalContent>
        <ModalCloseButton onClick={close} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>{title}</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            <ModalPaneContent>
              <FormSection padding={12} gap={6} margin={0} className='form-section-background'>
                <FormField label='Reminder Date' htmlFor='add-reminder:date' desktopLayout={{ labelSize: 120 }}>
                  <DatePicker
                    selected={moment(reminderForm.values.date).toDate()}
                    placeholderText='Choose a date'
                    onChange={onSetDate}
                    minDate={new Date()}
                    dateFormat={longDateFormat.replaceAll('D', 'd').replaceAll('Y', 'y')}
                    id='add-reminder:date'
                    // DatePicker uses Unicode tokens for format -> https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md
                  />
                </FormField>
                <InputContainer size='full' resize='both'>
                  <textarea
                    rows={11}
                    value={reminderForm.values.note}
                    onChange={(e) => {
                      setReminderform(reminderForm.setValue('note', { value: e.target.value }));
                    }}
                    className='reminder-text-input'
                    maxLength={MAX_JOURNAL_ENTRY_LIMIT}
                  />
                </InputContainer>
              </FormSection>
            </ModalPaneContent>
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={close}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <LoadingButton
                className='button button-primary'
                loading={saving}
                disabled={saving}
                onClick={() => {
                  save({
                    ...reminder,
                    note: reminderForm.values.note,
                    dateYMD: reminderForm.values.date,
                    dismissed: reminderForm.values.dismissed,
                  });
                }}
              >
                {buttonText}
              </LoadingButton>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReminderModal;
