import React, { useContext } from 'react';
import ElementPrinterModal from './element-printer-modal';
import { ElementPrinterContext } from './element-printer-context';

const ElementPrinterModalRender = (): JSX.Element | null => {
  const { printWindowOpen } = useContext(ElementPrinterContext);

  if (!printWindowOpen) {
    return null;
  }

  return <ElementPrinterModal />;
};

export default ElementPrinterModalRender;
