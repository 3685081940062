import { GardenItemType, PlantType } from '@gi/constants';
import { PlanValidation } from '@gi/plan';
import Plant, { PlantSpacings } from '@gi/plant';
import PlantFamily from '@gi/plant-family';
import { UserPlantVariety } from '@gi/user';
import { Geometry } from '@gi/math';

import { calculatePlantBounds } from './bounds-utils';
import { SimulatedGardenItem, SimulatedGardenItemClipboardData } from './simulated-garden-item';
import { PlantCount, getPlantCount, getPlantSpacing, getSpacings } from './plant-utils';
import BoxCollider from './collisions/colliders/box-collider';
import { CollisionCheckFlag, CollisionGroupFlag } from './collisions/colliders/collider';
// eslint-disable-next-line import/no-cycle
import { SimulatedGardenObject } from './simulated-garden-object';

export type SimulatedPlantClipboardData = {
  isSquareFoot: boolean;
  rowStart: Vector2;
  rowEnd: Vector2;
  height: number;
  plant: Plant;
  plantFamily: PlantFamily;
  variety: string;
  userPlantVariety: UserPlantVariety | null;
  labelText: string;
  labelOffset: Vector2;
  showLabel: boolean;
  inGroundStart: number;
  inGroundEnd: number;
  inGroundAll: boolean;
  locked: boolean;
  zIndex: number;
};

export class SimulatedPlant extends SimulatedGardenItem {
  readonly type = GardenItemType.Plant;

  readonly isSquareFoot: boolean;

  #rowStart: Vector2;
  get rowStart() {
    return this.#rowStart;
  }

  #rowEnd: Vector2;
  get rowEnd() {
    return this.#rowEnd;
  }

  #height: number;
  get height() {
    return this.#height;
  }

  #width: number;
  get width() {
    return this.#width;
  }

  #rotation: number;
  get rotation() {
    return this.#rotation;
  }

  #positionType: PlantType;
  get positionType() {
    return this.#positionType;
  }

  #bounds: [Vector2, Vector2, Vector2, Vector2];
  get bounds() {
    return this.#bounds;
  }

  #axisAlignedBounds: {
    topLeft: Vector2;
    bottomRight: Vector2;
  };
  get axisAlignedBounds() {
    return this.#axisAlignedBounds;
  }

  readonly plant: Plant;
  readonly plantFamily: PlantFamily;

  #variety: string;
  get variety() {
    return this.#variety;
  }

  #userPlantVariety: UserPlantVariety | null;
  get userPlantVariety() {
    return this.#userPlantVariety;
  }

  get coveredBy() {
    const coveredBy = this.collider.containedBy
      .asArray()
      .map((item) => item.owner)
      .filter((item): item is SimulatedGardenObject => item instanceof SimulatedGardenObject);
    return coveredBy;
  }

  // Current minimum spacings (from Plant or UserPlantVariety)
  // TODO: Consider whether we need actual spacings, when spacings are large
  // (for example with trees) the actual spacing between plants in a given width
  // may deviate significantly when compared to the minimum spacings
  #spacings: PlantSpacings;
  get spacings() {
    return this.#spacings;
  }

  #individualSpacings: Vector2;
  get individualSpacings() {
    return this.#individualSpacings;
  }

  #labelText: string;
  get labelText() {
    return this.#labelText;
  }

  #labelOffset: Vector2;
  get labelOffset() {
    return this.#labelOffset;
  }

  #showLabel: boolean;
  get showLabel() {
    return this.#showLabel;
  }

  #inGroundStart: number;
  get inGroundStart() {
    return this.#inGroundStart;
  }

  #inGroundEnd: number;
  get inGroundEnd() {
    return this.#inGroundEnd;
  }

  #inGroundAll: boolean;
  get inGroundAll() {
    return this.#inGroundAll;
  }

  #plantCount: PlantCount;
  get plantCount() {
    return this.#plantCount;
  }

  #collider: BoxCollider;
  get collider() {
    return this.#collider;
  }

  constructor(
    id: number,
    isSquareFoot: boolean,
    rowStart: Vector2,
    rowEnd: Vector2,
    height: number,
    plant: Plant,
    plantFamily: PlantFamily,
    variety: string,
    userPlantVariety: UserPlantVariety | null,
    labelText: string,
    labelOffset: Vector2,
    showLabel: boolean,
    inGroundStart: number,
    inGroundEnd: number,
    inGroundAll: boolean,
    locked: boolean,
    zIndex: number
  ) {
    super(id, locked, zIndex);
    this.isSquareFoot = isSquareFoot;
    this.plant = plant;
    this.plantFamily = plantFamily;
    this.#variety = variety;
    this.#userPlantVariety = userPlantVariety;

    this.#labelText = labelText;
    this.#labelOffset = labelOffset;
    this.#showLabel = showLabel;

    this.#inGroundStart = inGroundStart;
    this.#inGroundEnd = inGroundEnd;
    this.#inGroundAll = inGroundAll;

    // Initialise values which are updated later
    this.#rowStart = { x: 0, y: 0 };
    this.#rowEnd = { x: 0, y: 0 };
    this.#height = 0;
    this.#width = 0;
    this.#axisAlignedBounds = {
      topLeft: { x: 0, y: 0 },
      bottomRight: { x: 0, y: 0 },
    };

    this.#collider = new BoxCollider(this, Geometry.midpoint(this.rowStart, this.rowEnd), this.width, this.height, this.rotation);
    this.collider.collisionChecks.set(CollisionCheckFlag.CONTAINED_BY);
    this.collider.collisionGroup.set(CollisionGroupFlag.PLANT);
    this.collider.collisionTargets.set(CollisionGroupFlag.SEASON_EXTENDER);

    this.#updateSpacings();
    this.#setPositions(rowStart, rowEnd, height);
  }

  #setPositions(rowStart: Vector2, rowEnd: Vector2, height: number) {
    this.#rowStart.x = rowStart.x;
    this.#rowStart.y = rowStart.y;
    this.#rowEnd.x = rowEnd.x;
    this.#rowEnd.y = rowEnd.y;
    this.#height = height;
    this.#validatePositions();
    this.#updateBounds();
    this.#updateIndividualSpacings();
    this.#updatePlantCount();
    this.#updateCollider();
  }

  setPositions(rowStart: Vector2, rowEnd: Vector2, height: number) {
    this.#setPositions(rowStart, rowEnd, height);
    this.emitUpdates();
  }

  setVariety(variety: string) {
    this.#variety = variety;
    // Nothing needs to update with this data.
  }

  setUserPlantVariety(userPlantVariety: UserPlantVariety | null) {
    this.#userPlantVariety = userPlantVariety;

    this.#updateSpacings();
    // We need to validate positions as spacing changes could change the minimum valid height/width values
    this.#validatePositions();
    this.#updateBounds();
    this.#updateIndividualSpacings();
    this.#updatePlantCount();
    this.#updateCollider();
    this.emitUpdates();
  }

  setLabelText(text: string) {
    // TODO: string validation here, text length limit etc
    this.#labelText = text;
    this.emitUpdates();
  }

  setLabelOffset(offset: Vector2) {
    this.#labelOffset.x = Math.round(offset.x);
    this.#labelOffset.y = Math.round(offset.y);
    this.emitUpdates();
  }

  setShowLabel(show: boolean) {
    if (show === this.#showLabel) {
      return;
    }
    this.#showLabel = show;
    this.emitUpdates();
  }

  setInGround(inGroundStart: number, inGroundEnd: number, inGroundAll: boolean) {
    this.#inGroundStart = inGroundStart;
    this.#inGroundEnd = inGroundEnd;
    this.#inGroundAll = inGroundAll;
    this.emitUpdates();
  }

  isVisibleDuringMonth(month: number | null): boolean {
    if (month === null || this.inGroundAll) {
      return true;
    }

    if (this.inGroundStart <= this.inGroundEnd) {
      return month >= this.inGroundStart && month <= this.inGroundEnd;
    }

    return month >= this.inGroundStart;
  }

  getClipboardData(): SimulatedGardenItemClipboardData<GardenItemType.Plant, SimulatedPlantClipboardData> {
    return {
      type: GardenItemType.Plant,
      data: {
        isSquareFoot: this.isSquareFoot,
        rowStart: { ...this.rowStart },
        rowEnd: { ...this.rowEnd },
        height: this.height,
        plant: this.plant,
        plantFamily: this.plantFamily,
        variety: this.variety,
        userPlantVariety: this.userPlantVariety,
        labelText: this.labelText,
        labelOffset: this.labelOffset,
        showLabel: this.showLabel,
        inGroundStart: this.inGroundStart,
        inGroundEnd: this.inGroundEnd,
        inGroundAll: this.inGroundAll,
        locked: this.locked,
        zIndex: this.zIndex,
      },
    };
  }

  #validatePositions() {
    const { type, rowStart, rowEnd, height } = PlanValidation.validatePlant(
      this.isSquareFoot,
      this.rowStart,
      this.rowEnd,
      this.height,
      this.plant,
      this.userPlantVariety
    );

    this.#positionType = type;
    this.#rowStart = { ...rowStart };
    this.#rowEnd = { ...rowEnd };

    this.#height = height;
    this.#width = Geometry.dist(rowStart, rowEnd);
    this.#rotation = Geometry.angleBetweenPoints(rowStart, rowEnd);
  }

  #updateBounds() {
    this.#bounds = calculatePlantBounds(this.rowStart, this.rowEnd, this.#height, this.spacings, this.isSquareFoot);
    this.#axisAlignedBounds.topLeft.x = Math.min(this.#bounds[0].x, this.#bounds[1].x, this.#bounds[2].x, this.#bounds[3].x);
    this.#axisAlignedBounds.topLeft.y = Math.min(this.#bounds[0].y, this.#bounds[1].y, this.#bounds[2].y, this.#bounds[3].y);
    this.#axisAlignedBounds.bottomRight.x = Math.max(this.#bounds[0].x, this.#bounds[1].x, this.#bounds[2].x, this.#bounds[3].x);
    this.#axisAlignedBounds.bottomRight.y = Math.max(this.#bounds[0].y, this.#bounds[1].y, this.#bounds[2].y, this.#bounds[3].y);
  }

  #updateSpacings() {
    this.#spacings = getSpacings(this.plant, this.userPlantVariety);
    this.#updateCollider();
  }

  #updateIndividualSpacings() {
    this.#individualSpacings = getPlantSpacing(this.#width, this.#height, this.#spacings.spacing, this.#spacings.rowSpacing, this.#spacings.inRowSpacing);
  }

  #updatePlantCount() {
    this.#plantCount = getPlantCount(this.isSquareFoot, this.spacings.sfgCount, this.width, this.height, this.spacings.spacing, this.spacings.inRowSpacing);
  }

  #updateCollider() {
    let xPad = this.spacings.spacing;
    let yPad = this.spacings.spacing;
    if (this.width > 0 && this.height === 0) {
      xPad = this.spacings.inRowSpacing;
      yPad = this.spacings.rowSpacing;
    }
    // Collision boxes are positioned from their center. We need to calculate the center of the plant
    //  by accounting for the height and adding half of it to the center position.
    const normal = Geometry.normalizePoint(Geometry.getPointDelta(this.rowStart, this.rowEnd));
    const perpendicularNormal = { x: -normal.y, y: normal.x }; // This rotates the normal 90deg clockwise
    const heightOffset = Geometry.multiplyPoint(perpendicularNormal, this.height / 2);
    const center = Geometry.addPoint(Geometry.midpoint(this.rowStart, this.rowEnd), heightOffset);
    this.#collider.setPosition(center);
    this.#collider.setSize(this.width + xPad, this.height + yPad);
    this.#collider.setRotation(this.rotation);
  }
}
