const ERROR_CODES = {
  400: 'Bad request, please contact support',
  401: 'Bad request, you have made too many requests to our weather service, please contact support',
  403: 'Bad request, authentication error, please contact support',
};

/**
 * Converts a response code to an error message for the weather API.
 * @param responseCode The response code fomr the request
 * @returns A human-readable error message
 */
export const getErrorMessage = (responseCode: number) => {
  if (ERROR_CODES[responseCode]) {
    return ERROR_CODES[responseCode];
  }
  return `Unknown error (status code ${responseCode !== -1 ? responseCode : 'unknown'})`;
};
