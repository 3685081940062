import React, { useCallback, useRef } from 'react';

/**
 * Utility hook for duplicating a ref. Helpful if you want to get a ref to something within a component,
 *  but still allow the ref to be forwarded out of it as well.
 * @param duplicateTo The ref to output a duplicate value to
 * @param initialValue Initial value of the ref
 * @returns A new ref, and a ref setter function to pass to a component
 */
const useRefDuplicate = <T>(
  initialValue: T | null,
  duplicateTo: React.ForwardedRef<T> = null
): [ref: React.RefObject<T>, setRef: (value: T | null) => void] => {
  const ref = useRef<T | null>(initialValue);

  const setRef = useCallback(
    (value: T | null) => {
      ref.current = value;
      if (duplicateTo) {
        if ('current' in duplicateTo) {
          duplicateTo.current = value;
        } else {
          duplicateTo(value);
        }
      }
    },
    [duplicateTo]
  );

  return [ref, setRef];
};

export default useRefDuplicate;
