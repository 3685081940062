import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { MathUtils } from '@gi/math';
import { Direction } from '@gi/constants';
import { useOverflowDetector } from '@gi/react-utils';

import FadedScrollContainerButton from './faded-scroll-container-button';

import './faded-scroll-container.scss';

/** How much clicking one of the scroll arrows should scroll the element by default, as a percentage of its height */
const DEFAULT_BUTTON_SCROLL_AMOUNT = 0.8;

interface iProps {
  children?: ReactNode;
  /** className for the outer scroll container */
  className?: string;
  /** className for the inner scroll container that contains everything */
  innerClassName?: string;
  /** style for the outer scroll container */
  style?: CSSProperties;
  /** The colour to use for the fades. Can also be set in CSS using `--scroll-fade-colour` */
  fadeColour?: `#${string}`;
  /** Set to true if the scrolling should be horizontal */
  isHorizontal?: boolean;
  /** Hides the scrollbar */
  hideScrollbars?: boolean;
  /** Hides the arrows on the fades */
  hideOverflowArrows?: boolean;
  /** Prevents the custom horizontal scroll logic to allow scrolling with mousewheel */
  preventCustomScroll?: boolean;
  /** Prevent scroll events bubbling, preventing scrolling within this element also scrolling the page */
  captureScroll?: boolean;
  /** Distance from each end to consider the end still, to prevent false-positives. */
  tolerance?: number;
  /** Should calculating the overflows be paused? (use as if this is a `hidden` prop to save processing) */
  paused?: boolean;
  /** When true, the arrows on the scroll area will be clickable, and scroll the content */
  disableScrollButtons?: boolean;
  /** Optional class to apply to the arrow buttons */
  scrollButtonClassName?: string;
  /** How much pressing one of the scroll buttons should scroll the pane, as a percentage of its visible size */
  scrollButtonScrollAmount?: number;
  /** When true, the overflow will be updated whenever children change. Expensive, avoid if content is static */
  watchChildren?: boolean;
}

/**
 * A scroll container that will display fades at each end if there's further to scroll.
 * Defaults to vertical, but can be made horizontal as well (cannot be both, not enough pseudo-elements).
 */
const FadedScrollContainer = ({
  children,
  className,
  innerClassName,
  style,
  fadeColour,
  isHorizontal = false,
  hideScrollbars = false,
  hideOverflowArrows = false,
  preventCustomScroll = false,
  captureScroll = false,
  tolerance = 5,
  paused = false,
  disableScrollButtons = false,
  scrollButtonClassName,
  scrollButtonScrollAmount = DEFAULT_BUTTON_SCROLL_AMOUNT,
  watchChildren = false,
}: iProps): JSX.Element => {
  const { ref: setOverflowRef, overflows } = useOverflowDetector({ tolerance, paused, watchChildren });

  const [internalRef, setInternalRef] = useState<HTMLDivElement | null>(null);

  // We need to pass the ref to the detector, but also need a copy of it, so middle-man it here.
  const setRef = useCallback((element: HTMLDivElement | null) => {
    setOverflowRef(element);
    setInternalRef(element);
  }, []);

  // Set up mousewheel watcher if applicable.
  useEffect(() => {
    const f = (event: WheelEvent) => {
      if (event.deltaY && !event.deltaX && internalRef && isHorizontal && !event.shiftKey) {
        // Don't capture if we're at the end of the scroll or there's nothing to scroll
        const maxScrollWidth = Math.max(internalRef.scrollWidth - internalRef.clientWidth);

        // Don't capture wheel event if scrolling down and scroll area is already at the far-right
        // Don't capture wheel event if scrolling up and scroll area is already at the far-left
        if ((event.deltaY > 0 && internalRef.scrollLeft >= maxScrollWidth) || (event.deltaY < 0 && internalRef.scrollLeft <= 0)) {
          if (captureScroll) {
            event.preventDefault();
          }
          return;
        }

        event.preventDefault();
        internalRef.scrollBy({
          left: event.deltaY,
          // behavior: 'smooth' // I'd love to use smooth scrolling, but it doesn't work nicely with rapid scrollBy calls.
        });
      }
    };

    if (internalRef && isHorizontal && !preventCustomScroll) {
      internalRef.addEventListener('wheel', f);
      return () => {
        internalRef.removeEventListener('wheel', f);
      };
    }

    return () => {};
  }, [internalRef, isHorizontal, preventCustomScroll, captureScroll]);

  const scrollInDirection = useCallback(
    (direction: Direction) => {
      if (!internalRef) {
        return;
      }

      switch (direction) {
        case Direction.LEFT: {
          const max = internalRef.scrollWidth - internalRef.clientWidth;
          const target = internalRef.scrollLeft - internalRef.clientWidth * scrollButtonScrollAmount;
          const clampedTarget = MathUtils.clamp(target, 0, max);
          internalRef.scrollTo({
            left: clampedTarget,
            behavior: 'smooth',
          });
          break;
        }
        case Direction.RIGHT: {
          const max = internalRef.scrollWidth - internalRef.clientWidth;
          const target = internalRef.scrollLeft + internalRef.clientWidth * scrollButtonScrollAmount;
          const clampedTarget = MathUtils.clamp(target, 0, max);
          internalRef.scrollTo({
            left: clampedTarget,
            behavior: 'smooth',
          });
          break;
        }
        case Direction.UP: {
          const max = internalRef.scrollHeight - internalRef.clientHeight;
          const target = internalRef.scrollTop - internalRef.clientHeight * scrollButtonScrollAmount;
          const clampedTarget = MathUtils.clamp(target, 0, max);
          internalRef.scrollTo({
            top: clampedTarget,
            behavior: 'smooth',
          });
          break;
        }
        case Direction.DOWN: {
          const max = internalRef.scrollHeight - internalRef.clientHeight;
          const target = internalRef.scrollTop + internalRef.clientHeight * scrollButtonScrollAmount;
          const clampedTarget = MathUtils.clamp(target, 0, max);
          internalRef.scrollTo({
            top: clampedTarget,
            behavior: 'smooth',
          });
          break;
        }
        default: {
          break;
        }
      }
    },
    [internalRef, scrollButtonScrollAmount]
  );

  const buttons = useMemo(() => {
    if (isHorizontal) {
      return (
        <>
          <FadedScrollContainerButton
            direction={Direction.LEFT}
            hidden={!overflows.left}
            noArrow={hideOverflowArrows}
            noButton={disableScrollButtons}
            onClick={scrollInDirection}
            className={scrollButtonClassName}
          />
          <FadedScrollContainerButton
            direction={Direction.RIGHT}
            hidden={!overflows.right}
            noArrow={hideOverflowArrows}
            noButton={disableScrollButtons}
            onClick={scrollInDirection}
            className={scrollButtonClassName}
          />
        </>
      );
    }
    return (
      <>
        <FadedScrollContainerButton
          direction={Direction.UP}
          hidden={!overflows.top}
          noArrow={hideOverflowArrows}
          noButton={disableScrollButtons}
          onClick={scrollInDirection}
          className={scrollButtonClassName}
        />
        <FadedScrollContainerButton
          direction={Direction.DOWN}
          hidden={!overflows.bottom}
          noArrow={hideOverflowArrows}
          noButton={disableScrollButtons}
          onClick={scrollInDirection}
          className={scrollButtonClassName}
        />
      </>
    );
  }, [disableScrollButtons, isHorizontal, overflows, scrollInDirection, hideOverflowArrows, scrollButtonClassName]);

  const classNames: string[] = ['faded-scroll-container'];
  if (isHorizontal) {
    classNames.push('horizontal');
  }
  if (hideScrollbars) {
    classNames.push('hide-scrollbars');
  }
  if (className) {
    classNames.push(className);
  }

  const innerClassNames: string[] = ['faded-scroll-container-inner'];
  if (innerClassName) {
    innerClassNames.push(innerClassName);
  }

  const styles = useMemo(() => {
    const value = { ...style };
    if (fadeColour) {
      // Adjust for 3-character hex codes by converting to full-length
      const fullFadeColour =
        fadeColour.length === 3 ? `${fadeColour[0]}${fadeColour[0]}${fadeColour[1]}${fadeColour[1]}${fadeColour[2]}${fadeColour[2]}` : fadeColour;

      value['--scroll-fade-colour'] = fullFadeColour;
      value['--scroll-fade-transparent-colour'] = `${fullFadeColour.substring(0, 7)}00`;
    }
    return value;
  }, [style, fadeColour]);

  return (
    <div className={classNames.join(' ')} style={styles}>
      <div className={innerClassNames.join(' ')} ref={setRef}>
        {children}
      </div>
      {buttons}
    </div>
  );
};

export default FadedScrollContainer;
