import React, { useCallback } from 'react';

import { VectorInput } from '@gi/length-input';
import FormField, { FORM_FIELD_PRESETS } from '@gi/form-responsive';

import { EditShapeModalFormProps, ShapeState } from '../../types';

/**
 * Displays positional inputs for a triangle (point1, point2 and point3)
 */
const TrianglePropertiesFormSection = ({ values, setValues, distanceUnits }: EditShapeModalFormProps): JSX.Element => {
  const createSetter = useCallback(
    <K extends keyof ShapeState>(setting: K) =>
      (value: ShapeState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  return (
    <>
      <FormField label='Point 1' htmlFor='edit-shape:point1-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.point1} onChange={createSetter('point1')} distanceUnits={distanceUnits} id='edit-shape:point1' />
      </FormField>
      <FormField label='Point 2' htmlFor='edit-shape:point2-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.point2 ?? { x: 0, y: 0 }} onChange={createSetter('point2')} distanceUnits={distanceUnits} id='edit-shape:point2' />
      </FormField>
      <FormField label='Point 3' htmlFor='edit-shape:point3-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.point3} onChange={createSetter('point3')} distanceUnits={distanceUnits} id='edit-shape:point3' />
      </FormField>
    </>
  );
};

export default TrianglePropertiesFormSection;
