import React from 'react';
import { NavLink } from 'react-router-dom';
import { GuruPreviewVideo } from '@gi/app-guru-types';

import HeroPreviewThumbnail from '../components/hero-preview-thumbnail';
import HeroPreviewTitle from '../components/hero-preview-title';
import HeroPreviewDescription from '../components/hero-preview-description';
import {
  HeroPreviewContent,
  HeroPreviewDescriptionContainer,
  HeroPreviewLinkContainer,
  HeroPreviewThumbnailContainer,
} from '../components/hero-preview-content';

import styles from '../hero-preview.module.css';

interface iProps {
  previewVideo: GuruPreviewVideo;
  link: string;
}

const HeroPreviewVideo = ({ previewVideo, link }: iProps): JSX.Element => {
  return (
    <HeroPreviewContent>
      <HeroPreviewThumbnailContainer>
        <NavLink to={link}>
          <HeroPreviewThumbnail src={previewVideo.thumbnailUrl} alt={`${previewVideo.title} - thumbnail`} />
        </NavLink>
      </HeroPreviewThumbnailContainer>
      <HeroPreviewDescriptionContainer>
        <HeroPreviewTitle>{previewVideo.title}</HeroPreviewTitle>
        <HeroPreviewDescription>{previewVideo.description}</HeroPreviewDescription>
        <HeroPreviewLinkContainer>
          <NavLink className={styles.heroLink} to={link}>
            Watch Now
          </NavLink>
        </HeroPreviewLinkContainer>
      </HeroPreviewDescriptionContainer>
    </HeroPreviewContent>
  );
};

export default HeroPreviewVideo;
