import React from 'react';

import './status-icon.scss';

interface iProps {
  type: 'success' | 'warn';
}

const StatusIcon = ({ type }: iProps): JSX.Element => {
  if (type === 'success') {
    return <i className='icon-ok icon-colour-success' />;
  }
  return <i className='icon-attention-alt icon-colour-warn' />;
};

export default StatusIcon;
