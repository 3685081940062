import { NetworkError } from '@gi/errors';
import RequestError from './request-error';

const defaultNetworkErrorParser = (err: Error | RequestError) => {
  if ('timeout' in err) {
    return new NetworkError(null, 'Timeout Error', 'Timeout Error', err);
  }

  if (err instanceof RequestError) {
    return new NetworkError(err.statusCode, `Unknown ${err.statusCode} Error`, `Unknown ${err.statusCode} Error`, err);
  }

  return new NetworkError(null, 'Unknown Error', 'Unknown Error', err);
};

export default defaultNetworkErrorParser;
