import Collection from '@gi/collection';
import Plant from '@gi/plant';
import GardenObject from '@gi/garden-object';
import { UserPlantVarietySet } from '@gi/user';
import { Plan } from '@gi/plan';
import { SimulationFactory } from './simulation-factory';
import { PlanUpdateCallback, SyncedPlan } from './synced-plan';
import { SimulatedPlan } from '../simulation/simulated-plan';

export class SyncedPlans {
  #syncedPlans: Record<number, SyncedPlan>;

  simulationFactory: SimulationFactory;
  readonly updateCallback: PlanUpdateCallback;

  constructor(updateCallback: PlanUpdateCallback, plants: Collection<Plant>, gardenObjects: Collection<GardenObject>, userPlantVarieties: UserPlantVarietySet) {
    this.simulationFactory = new SimulationFactory(plants, gardenObjects, userPlantVarieties);

    this.#syncedPlans = {};
    this.updateCallback = updateCallback;
  }

  getOpenPlans(): number[] {
    return Object.keys(this.#syncedPlans).map((stringKey) => parseInt(stringKey, 10));
  }

  startPlanSimulation(plan: Plan, availableHistoricalPlans: Record<number, Plan> = {}): SimulatedPlan {
    this.#syncedPlans[plan.id] = this.#syncedPlans[plan.id] ?? new SyncedPlan(plan, availableHistoricalPlans, this.#planDidUpdate, this.simulationFactory);
    return this.#syncedPlans[plan.id].simulatedPlan;
  }

  hasSimulatedPlan(id: number): boolean {
    return !!this.#syncedPlans[id];
  }

  getSimulatedPlan(id: number): SimulatedPlan | null {
    if (!this.hasSimulatedPlan(id)) {
      return null;
    }

    return this.#syncedPlans[id].simulatedPlan;
  }

  /**
   * When a plan updates from a simulated plan, push out the updated plan through the callback function
   */
  #planDidUpdate = (plan: Plan) => {
    this.updateCallback(plan);
  };

  /**
   * Ends the simulation for the given planId, the simulation sync is stopped and
   * all references are removed
   */
  endPlanSimulation(planId: number): void {
    if (!this.hasSimulatedPlan(planId)) {
      console.warn(`Attempted to end sync of plan that wasn't synced ${planId}`);
      return;
    }

    this.#syncedPlans[planId].endSync();
    delete this.#syncedPlans[planId];
  }

  /**
   * Updates a simulation with the data from a plan with the same Id
   */
  updatePlan(plan: Plan): void {
    if (!this.hasSimulatedPlan(plan.id)) {
      console.warn(`Attempted to update simulation of plan that wasn't synced ${plan.id}`);
      return;
    }

    this.#syncedPlans[plan.id].updatePlan(plan);

    this.updateHistoricalPlan(plan);
  }

  /**
   * Checks all open plans for if they need this plan for their history.
   * If so, triggers an update on them.
   */
  updateHistoricalPlan(plan: Plan) {
    // Go through all open plans and update historical plans if in use
    const openPlanIds = this.getOpenPlans();
    for (let i = 0; i < openPlanIds.length; i++) {
      const syncedPlan = this.#syncedPlans[openPlanIds[i]];
      if (syncedPlan.plan.history.includes(plan.id)) {
        syncedPlan.onHistoricalPlanUpdate(plan);
      }
    }
  }

  updateUserPlantVarieties(userVarieties: UserPlantVarietySet) {
    const diff = this.simulationFactory.setUserPlantVarieties(userVarieties);

    const openPlanIds = this.getOpenPlans();
    for (let i = 0; i < openPlanIds.length; i++) {
      const syncedPlan = this.#syncedPlans[openPlanIds[i]];
      syncedPlan.updateUserPlantVarieties(diff);
    }
  }
}
