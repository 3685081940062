/* eslint-disable prefer-destructuring */
/**
 * Set of stand-alone geometric functions
 */

export type ClipperVector2 = {
  X: number;
  Y: number;
};

export type BezierSegment = {
  x: number;
  y: number;
  d: number;
};

export type CubicBezierPoint = {
  x: number;
  y: number;
  controlPoint1: Vector2;
  controlPoint2: Vector2;
};

/**
 * returns a delta between two points
 *
 * @param {Point} p1
 * @param {Point} p2
 * @return {Delta}
 */
export const getPointDelta = (p1: Vector2, p2: Vector2): Vector2 => {
  return {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  };
};

/**
 * returns a new point whihc is the supplied point rotated around the origin
 *
 * @param {Point} p
 * @param {Point} origin
 * @param {number} theta
 */
export const rotateAroundPoint = (p: Vector2, origin: Vector2, theta: number): Vector2 => {
  const cosTheta = Math.cos(theta);
  const sinTheta = Math.sin(theta);

  return {
    x: cosTheta * (p.x - origin.x) - sinTheta * (p.y - origin.y) + origin.x,
    y: sinTheta * (p.x - origin.x) + cosTheta * (p.y - origin.y) + origin.y,
  };
};

/**
 * returns a new point which is the supplied point rotated around the origin
 *
 * @param {Point} p
 * @param {number} theta
 */
export const rotateAroundOrigin = (p: Vector2, theta: number): Vector2 => {
  const cosTheta = Math.cos(theta);
  const sinTheta = Math.sin(theta);

  return {
    x: cosTheta * p.x - sinTheta * p.y,
    y: sinTheta * p.x + cosTheta * p.y,
  };
};

/**
 * Returns a new object with a the x and y points rounded
 *
 * @param {Object} point - point
 * @param {number} point.x - x position of point
 * @param {number} point.y - y position of point
 */
export const roundPoint = (point: Vector2): Vector2 => {
  return {
    x: Math.round(point.x),
    y: Math.round(point.y),
  };
};

export const addPoint = (p1: Vector2, p2: Vector2): Vector2 => {
  return {
    x: p1.x + p2.x,
    y: p1.y + p2.y,
  };
};

export const minusPoint = (p1: Vector2, p2: Vector2): Vector2 => {
  return {
    x: p1.x - p2.x,
    y: p1.y - p2.y,
  };
};

export const multiplyPoint = (point: Vector2, val: number): Vector2 => {
  return {
    x: point.x * val,
    y: point.y * val,
  };
};

export const dividePoint = (point: Vector2, val: number): Vector2 => {
  return {
    x: point.x / val,
    y: point.y / val,
  };
};

export const snapRotation = (point: Vector2, rotationOrigin: Vector2, snapInterval: number): Vector2 => {
  const p = minusPoint(rotationOrigin, point);
  const currentRotation = Math.atan2(p.x, p.y);
  const nearestSnapRotation = Math.round(currentRotation / snapInterval) * snapInterval;
  const relativeRotation = nearestSnapRotation - currentRotation;
  return rotateAroundPoint(point, rotationOrigin, -relativeRotation);
};

/**
 * Returns a rounded point or null, if null was passed
 *
 * @param {Point|null} point
 */
export const roundPossiblyNullPoint = (point: null | Vector2): null | Vector2 => {
  if (point === null) {
    return null;
  }

  return roundPoint(point);
};
export function snapPoint(point: Vector2, snapDistance: number): Vector2;
export function snapPoint(point: null | Vector2, snapDistance: number): null | Vector2;
export function snapPoint(point: null | Vector2, snapDistance: number): null | Vector2 {
  if (point === null) {
    return null;
  }

  return {
    x: Math.round(point.x / snapDistance) * snapDistance,
    y: Math.round(point.y / snapDistance) * snapDistance,
  };
}

const lengthSquared = (v: Vector2): number => {
  return v.x ** 2 + v.y ** 2;
};

export const length = (v: Vector2): number => {
  return Math.sqrt(lengthSquared(v));
};

/**
 * Returns the squared distance between two points
 *
 * @param {Object} v - first point
 * @param {number} v.x - x position of the first point
 * @param {number} v.y - y position of the first point
 * @param {Object} w - second point
 * @param {number} w.x - x position of the second point
 * @param {number} w.y - y position of the second point
 * @returns {number} - The squared distance between the two points
 */
export const distSquared = (v: Vector2, w: Vector2): number => {
  return (v.x - w.x) ** 2 + (v.y - w.y) ** 2;
};

/**
 * Returns the distance between two points
 *
 * @param {Object} v - first point
 * @param {number} v.x - x position of the first point
 * @param {number} v.y - y position of the first point
 * @param {Object} w - second point
 * @param {number} w.x - x position of the second point
 * @param {number} w.y - y position of the second point
 * @returns {number} - Distance between the two points
 */
export const dist = (v: Vector2, w: Vector2): number => {
  return Math.sqrt(distSquared(v, w));
};

/**
 * Returns the squared magnitude (or length) of the given vector
 * @param point The point
 * @returns The squared magnitude (length) of the vector
 */
export const magnitudeSquared = (point: Vector2): number => {
  return point.x ** 2 + point.y ** 2;
};

/**
 * Returns the magnitude (or length) of the given vector
 * @param point The point
 * @returns The magnitude (length) of the vector
 */
export const magnitude = (point: Vector2): number => {
  return Math.sqrt(magnitudeSquared(point));
};

/**
 * Normalizes the input vector, so that the magnitude/length of the vector is 1.
 * @param point The vector to normalize
 * @returns A normalized copy of the input vector
 */
export const normalizePoint = (point: Vector2): Vector2 => {
  const m = magnitude(point);
  if (m === 0) {
    return {
      x: 0,
      y: 0,
    };
  }
  return {
    x: point.x / m,
    y: point.y / m,
  };
};

export const interpolateCubicBezier = (start: Vector2, controlPoint1: Vector2, controlPoint2: Vector2, end: Vector2): ((t: number) => [number, number]) => {
  // 0 <= t <= 1
  return (t) => {
    return [
      (1 - t) ** 3 * start.x + 3 * (1 - t) ** 2 * t * controlPoint1.x + 3 * (1 - t) * t ** 2 * controlPoint2.x + t ** 3 * end.x,
      (1 - t) ** 3 * start.y + 3 * (1 - t) ** 2 * t * controlPoint1.y + 3 * (1 - t) * t ** 2 * controlPoint2.y + t ** 3 * end.y,
    ];
  };
};

export const interpolateQuadraticBezier = (start: Vector2, controlPoint: Vector2, end: Vector2): ((t: number) => [number, number]) => {
  // 0 <= t <= 1
  return (t) => {
    return [
      (1 - t) * (1 - t) * start.x + 2 * (1 - t) * t * controlPoint.x + t * t * end.x,
      (1 - t) * (1 - t) * start.y + 2 * (1 - t) * t * controlPoint.y + t * t * end.y,
    ];
  };
};

/**
 * Returns the square of the smallest distance between a point and a line
 *
 * @param {Point} point
 * @param {Point} lineStart - Start point of the line
 * @param {Point} lineEnd - End point of the line
 * @returns {number} - Squared distance between the point and the closet point on the segment
 */
const distToSegmentSquared = (point: Vector2, lineStart: Vector2, lineEnd: Vector2): number => {
  const l2 = distSquared(lineStart, lineEnd);

  if (l2 === 0) {
    return distSquared(point, lineStart);
  }

  const t = ((point.x - lineStart.x) * (lineEnd.x - lineStart.x) + (point.y - lineStart.y) * (lineEnd.y - lineStart.y)) / l2;

  if (t < 0) {
    return distSquared(point, lineStart);
  }

  if (t > 1) {
    return distSquared(point, lineEnd);
  }

  return distSquared(point, {
    x: lineStart.x + t * (lineEnd.x - lineStart.x),
    y: lineStart.y + t * (lineEnd.y - lineStart.y),
  });
};

/**
 * Returns the smallest distance between a segment and a point
 *
 * @param {Point} point
 * @param {Point} lineStart Start point of the line
 * @param {Point} lineEnd End point of the line
 * @returns {number} - Distance between the point and the closet point on the segment
 */
export const distToSegment = (point: Vector2, lineStart: Vector2, lineEnd: Vector2): number => {
  return Math.sqrt(distToSegmentSquared(point, lineStart, lineEnd));
};

/**
 * Returns the midpoint between two points
 *
 * @param {Point} p1
 * @param {Point} p2
 * @returns {Point} - Midpoint between the two points
 */
export const midpoint = (p1: Vector2, p2: Vector2) => {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
};

/**
 * Returns the midpoint between three points, point 2 can be null (taking into account possible control points)
 * in which cause it returns the mid point between p1 and p3
 *
 * @param {Point} p1
 * @param {Point|null} p2
 * @param {Point} p3
 */
export const midBetween3Points = (p1: Vector2, p2: Vector2, p3: Vector2): Vector2 => {
  if (p2 === null) {
    return midpoint(p1, p3);
  }

  return {
    x: (p1.x + p2.x + p3.x) / 3,
    y: (p1.y + p2.y + p3.y) / 3,
  };
};

/**
 * Returns the average of the given points. This can be used as the midpoint when given 2, 3 or 4 points.
 * @param points The points to average
 * @returns The average position of the given points.
 */
export const averagePoint = (...points: Vector2[]): Vector2 => {
  if (points.length === 0) {
    return { x: 0, y: 0 };
  }
  const sum = points.reduce((prev, cur) => addPoint(prev, cur), { x: 0, y: 0 });
  return {
    x: sum.x / points.length,
    y: sum.y / points.length,
  };
};

/**
 * Calculates the AABB containing the given points, returning the top-left and bottom-right positions as min and max.
 * @param points The point
 * @returns A top-left and bottom-right position containing the given points
 */
export const getBoundingBox = (...points: Vector2[]): { min: Vector2; max: Vector2; center: Vector2 } => {
  if (points.length === 0) {
    throw new Error('No points supplied');
  }
  const min = { ...points[0] };
  const max = { ...points[0] };
  for (let i = 1; i < points.length; i++) {
    const point = points[i];
    min.x = Math.min(min.x, point.x);
    min.y = Math.min(min.y, point.y);
    max.x = Math.max(max.x, point.x);
    max.y = Math.max(max.y, point.y);
  }

  const center: Vector2 = {
    x: min.x + (max.x - min.x) / 2,
    y: min.y + (max.y - min.y) / 2,
  };

  return { min, max, center };
};

/**
 * Returns the distance between two points on the x-axis
 *
 * @param {Point} p1
 * @param {Point} p2
 * @returns {number}
 */
export const getDX = (p1: Vector2, p2: Vector2): number => {
  return p2.x - p1.x;
};

/**
 * Returns the distance between two points along the y-axis
 *
 * @param {Point} p1
 * @param {Point} p2
 * @returns {number}
 */
export const getDY = (p1: Vector2, p2: Vector2): number => {
  return p2.y - p1.y;
};

/**
 * Returns the rotation from horizontal of a segment between two points
 *
 * @param {Point} p1
 * @param {Point} p2
 * @returns {number} - Segment rotation in radians
 */
export const segmentRotation = (p1: Vector2, p2: Vector2): number => {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;

  return Math.atan2(dy, dx);
};

/**
 * Returns an array of points along a bezier curve separating it into segments.
 * Each point also has a 'd' property indicating the total distance travelled on the approximated curve so far.
 *
 * Each point is an equal 't' value along the bezier curve
 *
 * @param {Point} start - Start point of the bezier curve
 * @param {Point} controlPoint - Control point of the bezier curve
 * @param {Point} end - End point of the bezier curve
 * @param {number} [segmentFactor = 25] - Arbitrary number controlling the amount of segments created. A lower number will result in more segments
 * @param {number} [maxSegments = 100] - Maximum number of segments to create
 * @returns {Array} - Array of points along the bezier curve, along with 'd' property to indicate distance so far
 */
export const approximateBezier = (
  start: Vector2,
  controlPoint: Vector2,
  end: Vector2,
  segmentFactor: number = 10,
  maxSegments: number = 100
): BezierSegment[] => {
  let divisionCount = 0;

  const distanceToControlPoint = distToSegment(controlPoint, start, end);

  divisionCount = Math.min(Math.round(distanceToControlPoint / segmentFactor), maxSegments) + 1;
  const tSpacing = 1 / divisionCount;

  const divisions: BezierSegment[] = [];

  let distanceSoFar = 0;
  let previousX = 0;
  let previousY = 0;

  for (let i = 0; i <= divisionCount; i++) {
    const t = tSpacing * i;

    if (i > 0) {
      previousX = divisions[i - 1].x;
      previousY = divisions[i - 1].y;
      distanceSoFar = divisions[i - 1].d;
    }

    // Calculate x and y for this position on the bezier curve
    const x = (1 - t) * (1 - t) * start.x + 2 * (1 - t) * t * controlPoint.x + t * t * end.x;
    const y = (1 - t) * (1 - t) * start.y + 2 * (1 - t) * t * controlPoint.y + t * t * end.y;
    let d = 0;
    if (i > 0) {
      d = distanceSoFar + Math.sqrt((x - previousX) * (x - previousX) + (y - previousY) * (y - previousY));
    }
    // Store distance from start at current point
    divisions[i] = { x, y, d };
  }

  return divisions;
};

/**
 * Returns the points (if any) where a segment crosses a circle
 *
 * @param {Point} circleCenter - Center of the circle
 * @param {number} radius - Radius of the circle
 * @param {Point} start - Start point of the segment
 * @param {Point} end - End point of the segment
 * @returns {Array} - An array of 0, 1 or two intersection points
 */
export const segmentCircleIntersection = (circleCenter: Vector2, radius: number, start: Vector2, end: Vector2): Vector2[] => {
  const dx = end.x - start.x;
  const dy = end.y - start.y;

  const ex = start.x - circleCenter.x;
  const ey = start.y - circleCenter.y;

  const b = (dx * ex + dy * ey) * -2;
  const c = 2 * (dx * dx + dy * dy);

  const d = Math.sqrt(b * b - 2 * c * (ex * ex + ey * ey - radius * radius));

  const results: Vector2[] = [];

  // No intersection
  if (Number.isNaN(d)) {
    return results;
  }

  // These represent the unit distance of point one and two on the line
  const u1 = (b - d) / c;
  const u2 = (b + d) / c;

  // Add point if on the line segment
  if (u1 <= 1 && u1 >= 0) {
    results.push({
      x: start.x + dx * u1,
      y: start.y + dy * u1,
    });
  }

  // Second add point if on the line segment
  if (u2 <= 1 && u2 >= 0) {
    results.push({
      x: start.x + dx * u2,
      y: start.y + dy * u2,
    });
  }

  return results;
};

/**
 * Returns an array of approximated points along a bezier curve which is split up into equal size segments
 *
 * The points are approximated as the bezier curve is converted to straight lines in order to speed up calculations. The accuracy of the approximation
 * can be increased by decreasing the segment factor.
 *
 * @param {number} segmentLength - Length of the segments to split the bezier curve into
 * @param {Point[]} approximation
 * @returns {Point[]} Array of points along the bezier curve, each point is 'segmentLength' distance away from the next, except the last point.
 */
export const bezierSegmentsWithApproximation = (segmentLength: number, approximation: Vector2[]): Vector2[] => {
  if (approximation.length < 2) {
    // The approximation should always contain at least two points (start and end)
    throw new Error('Error creating estimation segments for bezier');
  }

  const points: Vector2[] = [];

  let p = { x: approximation[0].x, y: approximation[0].y };

  let currentIndex = 0;
  let nextIndex = 1;

  points.push(p);

  while (nextIndex < approximation.length) {
    const nextApproximation = approximation[nextIndex];
    const distToNextPoint = dist(p, nextApproximation);

    if (nextIndex === approximation.length - 1) {
      // We're on the last bezier curve approximation segment, check the distance between our current point and the end of the approximation
      // if this is less than our segment length then the approximation end is the last point and we can return

      if (distToNextPoint <= segmentLength) {
        points.push({
          x: approximation[nextIndex].x,
          y: approximation[nextIndex].y,
        });

        nextIndex++;
        break;
      }
    }

    if (currentIndex === nextIndex - 1) {
      // We're on the same bezier approximation segment, as it's just a straight line no circle intersection is needed
      if (distToNextPoint > segmentLength) {
        // Next point lies between the current points
        const dx = (nextApproximation.x - p.x) / distToNextPoint;
        const dy = (nextApproximation.y - p.y) / distToNextPoint;

        const nextP = {
          x: p.x + dx * segmentLength,
          y: p.y + dy * segmentLength,
        };

        points.push(nextP);

        p = nextP;
      } else {
        // Next point doesn't lie between the current points, move onto next point
        nextIndex++;
      }
    } else {
      // We're on two different parts of the bezier curve approximation so we need to measure from point to line
      // To do this we use a circle of radius equal to the segment length of what we want and see if they cross
      const intersections = segmentCircleIntersection(p, segmentLength, approximation[nextIndex - 1], approximation[nextIndex]);

      if (intersections.length === 0) {
        // No intersections so our segment won't meet the approximated bezier here
        nextIndex++;
      } else {
        // Intersection of circle (representing our segment length) and the approximated bezier segment, that point is our nextP
        if (intersections.length === 2) {
          console.warn('Two bezier intersections, calculation may not be correct');
        }

        const nextP = intersections[0];

        points.push(nextP);

        p = nextP;

        currentIndex = nextIndex - 1;
      }
    }
  }

  return points;
};

/**
 * Returns an array of approximated points along a bezier curve which is split up into equal size segments
 *
 * The points are approximated as the bezier curve is converted to straight lines in order to speed up calculations. The accuracy of the approximation
 * can be increased by decreasing the segment factor.
 *
 * @param {Point} start - Start point of the bezier curve
 * @param {Point} controlPoint - Control point of the bezier curve
 * @param {Point} end - End point of the bezier curve
 * @param {number} segmentLength - Length of the segments to split the bezier curve into
 * @param {number} [segmentFactor = 25] - Arbitrary number controlling the amount of segments created in the bezier approximation.
 * A lower number will result in more segments and thus, a more accurate representation of the curve.
 * @param {number} [maxSegments = 100] - Maximum number of segments to split the bezier curve into, prevents very large curves from taking a long time to process.
 * @returns {Array} Array of points along the bezier curve, each point is 'segmentLength' distance away from the next, except the last point.
 */
export const bezierSegments = (
  start: Vector2,
  controlPoint: Vector2,
  end: Vector2,
  segmentLength: number,
  segmentFactor: number = 25,
  maxSegments: number = 100
): Vector2[] => {
  const approximation = approximateBezier(start, controlPoint, end, segmentFactor, maxSegments);
  return bezierSegmentsWithApproximation(segmentLength, approximation);
};

export const bezierSegmentsLength = (
  start: Vector2,
  controlPoint: null | Vector2,
  end: Vector2,
  segmentLength: number,
  segmentFactor: number = 25,
  maxSegments: number = 100
): number => {
  if (controlPoint === null) {
    return dist(start, end);
  }

  if (segmentLength < 5) {
    // If the length of each segment is less than 5 it probably means the represented
    // object is supposed to represent a tube. In which case we don't want to think
    // about representing it as lots of straight parts, it's just a bezier curve
    const approximatedBezier = approximateBezier(start, controlPoint, end, segmentFactor, maxSegments);
    return approximatedBezier[approximatedBezier.length - 1].d;
  }

  const segments = bezierSegments(start, controlPoint, end, segmentLength, segmentFactor, maxSegments);

  if (segments.length === 1) {
    return 0;
  }

  if (segments.length === 2) {
    return dist(segments[0], segments[1]);
  }

  return segmentLength * (segments.length - 2) + dist(segments[segments.length - 2], segments[segments.length - 1]);
};

export const getBezierSegmentsLengthFromSegments = (
  start: Vector2,
  controlPoint: Vector2,
  end: Vector2,
  segmentLength: number,
  approximatedBezier: BezierSegment[],
  segments: Vector2[]
): number => {
  if (controlPoint === null) {
    return dist(start, end);
  }

  if (segmentLength < 5) {
    // If the length of each segment is less than 5 it probably means the represented
    // object is supposed to represent a tube. In which case we don't want to think
    // about representing it as lots of straight parts, it's just a bezier curve
    return approximatedBezier[approximatedBezier.length - 1].d;
  }

  if (segments.length === 1) {
    return 0;
  }

  if (segments.length === 2) {
    return dist(segments[0], segments[1]);
  }

  return segmentLength * (segments.length - 2) + dist(segments[segments.length - 2], segments[segments.length - 1]);
};

interface BezierSegmentsAndLength {
  approximatedBezier: Vector2[];
  segments: Vector2[];
  length: number;
}

export const getBezierSegmentsAndLength = (
  start: Vector2,
  controlPoint: Vector2,
  end: Vector2,
  segmentLength: number,
  segmentFactor: number = 25,
  maxSegments: number = 100
): BezierSegmentsAndLength => {
  const approximatedBezier = approximateBezier(start, controlPoint, end, segmentFactor, maxSegments);
  const segments = bezierSegmentsWithApproximation(segmentLength, approximatedBezier);
  const _length = getBezierSegmentsLengthFromSegments(start, controlPoint, end, segmentLength, approximatedBezier, segments);
  return {
    approximatedBezier,
    segments,
    length: _length,
  };
};

/**
 * Turns an ellipse with given width and height into an approximated path of bezier curves
 *
 * @param {Number} width - Width of the ellipse
 * @param {Number} height - Height of the ellipse
 * @param {Number} segmentCount - Number of segments to convent the ellipse into
 */
export const ellipseToBezier = (w: number, h: number, segmentCount: number = 4): CubicBezierPoint[] => {
  const PI = Math.PI;
  const S = segmentCount;

  const W = w;
  const H = h;
  const R = Math.max(W / 2, H / 2); // Radius

  const D = (4 / 3) * Math.tan(PI / (2 * S)) * R;

  const points: CubicBezierPoint[] = [];

  for (let i = 0; i < S; i++) {
    // For each segment
    const theta = (2 * PI * i) / S;
    const x = R * Math.cos(theta);
    const y = R * Math.sin(theta);

    points[i] = {
      x,
      y,
      controlPoint1: {
        x: x - D * Math.sin(theta),
        y: y + D * Math.cos(theta),
      },
      controlPoint2: {
        x: x - -D * Math.sin(theta),
        y: y + -D * Math.cos(theta),
      },
    };
  }

  const xScalar = W / 2 / R;
  const yScalar = H / 2 / R;

  points.forEach((p) => {
    p.x *= xScalar;
    p.controlPoint1.x *= xScalar;
    p.controlPoint2.x *= xScalar;
    p.y *= yScalar;
    p.controlPoint1.y *= yScalar;
    p.controlPoint2.y *= yScalar;
  });

  // points.map(p => new CubicBezierPoint({ x: p.x, y: p.y }, p.controlPoint1, p.controlPoint2));

  return points;
};

export const angleBetweenPoints = (p1: Vector2, p2: Vector2): number => {
  return Math.atan2(p2.y - p1.y, p2.x - p1.x);
};

export const dotProduct = (v1: Vector2, v2: Vector2): number => {
  const angle = angleBetweenPoints(v1, v2);
  return length(v1) * length(v2) * Math.cos(angle);
};

/**
 * - v2 is clockwise from v1 when positive
 * - v2 is anticlockwise from v1 when negative
 * - v2 is collinear with v1 when 0
 */
export const crossProduct = (v1: Vector2, v2: Vector2): number => {
  return v1.x * v2.y - v1.y * v2.x;
};

// Cross products don't exist in 2D. Not sure what this was doing.
// export const crossProduct = (v1: Vector2, v2: Vector2): number => {
//   return (v1.x * v2.y) - (v1.y * v2.x);
// };

export const getVector = (p1: Vector2, p2: Vector2): Vector2 => {
  return {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  };
};

export const angle2 = (p1: Vector2, p2: Vector2): number => {
  const vec = getVector(p1, p2);
  return Math.atan2(vec.y, vec.x);
};

export const angle3 = (p1: Vector2, p2: Vector2, p3: Vector2): number => {
  const p2p1 = getVector(p2, p1);
  const p2p3 = getVector(p2, p3);

  return Math.atan2(p2p3.y, p2p3.x) - Math.atan2(p2p1.y, p2p1.x);
};

export const createPolylineOutline = (polyline: Vector2[], offset: number): number[] => {
  const side1: number[] = [];
  const side2: number[] = [];

  const aP1 = polyline[0];
  const aP2 = polyline[1];
  const avec = getVector(aP1, aP2);

  const aang = Math.atan2(avec.y, avec.x) - Math.PI / 2;

  side1.push(polyline[0].x + offset * Math.cos(aang)); // x
  side1.push(polyline[0].y + offset * Math.sin(aang)); // y

  side2.push(polyline[0].y + offset * Math.sin(aang - Math.PI)); // y
  side2.push(polyline[0].x + offset * Math.cos(aang - Math.PI)); // x

  for (let i = 0; i < polyline.length - 2; i++) {
    const P1 = polyline[i];
    const P2 = polyline[i + 1];
    const P3 = polyline[i + 2];

    const P2P3 = getVector(P2, P3);

    const theta = angle3(P1, P2, P3);

    const s2angle = Math.atan2(P2P3.y, P2P3.x);

    const h = offset / Math.sin(theta / 2);
    const hAngle = s2angle - theta / 2;

    side1.push(P2.x + h * Math.cos(hAngle)); // x
    side1.push(P2.y + h * Math.sin(hAngle)); // y
    side2.push(P2.y + h * Math.sin(hAngle - Math.PI)); // y
    side2.push(P2.x + h * Math.cos(hAngle - Math.PI)); // x
  }

  const bP1 = polyline[polyline.length - 2];
  const bP2 = polyline[polyline.length - 1];
  const bvec = getVector(bP1, bP2);

  const bang = Math.atan2(bvec.y, bvec.x) - Math.PI / 2;

  side1.push(polyline[polyline.length - 1].x + offset * Math.cos(bang)); // x
  side1.push(polyline[polyline.length - 1].y + offset * Math.sin(bang)); // y
  side2.push(polyline[polyline.length - 1].y + offset * Math.sin(bang - Math.PI)); // y
  side2.push(polyline[polyline.length - 1].x + offset * Math.cos(bang - Math.PI)); // x

  return side1.concat(side2.reverse());
};

export const updatePolylineOutline = (polyline: Vector2[], offset: number, outline: number[]): number[] => {
  const side1 = [];
  const side2 = [];

  const aP1 = polyline[0];
  const aP2 = polyline[1];
  const avec = getVector(aP1, aP2);

  const aang = Math.atan2(avec.y, avec.x) - Math.PI / 2;

  outline[outline.length - 2] = polyline[0].x + offset * Math.cos(aang - Math.PI);
  outline[outline.length - 1] = polyline[0].y + offset * Math.sin(aang - Math.PI);
  outline[0] = polyline[0].x + offset * Math.cos(aang);
  outline[1] = polyline[0].y + offset * Math.sin(aang);

  for (let i = 0; i < polyline.length - 2; i++) {
    const P1 = polyline[i];
    const P2 = polyline[i + 1];
    const P3 = polyline[i + 2];

    const P2P3 = getVector(P2, P3);

    const theta = angle3(P1, P2, P3);

    const s2angle = Math.atan2(P2P3.y, P2P3.x);

    const h = offset / Math.sin(theta / 2);
    const hAngle = s2angle - theta / 2;

    outline[(i + 1) * 2] = P2.x + h * Math.cos(hAngle);
    outline[(i + 1) * 2 + 1] = P2.y + h * Math.sin(hAngle);

    outline[outline.length - 4 - i * 2] = P2.x + h * Math.cos(hAngle - Math.PI);
    outline[outline.length - 4 - (i * 2 - 1)] = P2.y + h * Math.sin(hAngle - Math.PI);
  }

  const bP1 = polyline[polyline.length - 2];
  const bP2 = polyline[polyline.length - 1];
  const bvec = getVector(bP1, bP2);

  const bang = Math.atan2(bvec.y, bvec.x) - Math.PI / 2;

  outline[outline.length / 2 - 2] = polyline[polyline.length - 1].x + offset * Math.cos(bang);
  outline[outline.length / 2 - 1] = polyline[polyline.length - 1].y + offset * Math.sin(bang);

  outline[outline.length / 2 + 0] = polyline[polyline.length - 1].x + offset * Math.cos(bang - Math.PI);
  outline[outline.length / 2 + 1] = polyline[polyline.length - 1].y + offset * Math.sin(bang - Math.PI);

  return side1.concat(side2.reverse());
};

export const quadraticToPolyline = (p1: Vector2, controlPoint: null | Vector2, p2: Vector2, segments: number): Vector2[] => {
  let _controlPoint = controlPoint;

  if (_controlPoint === null) {
    _controlPoint = midpoint(p1, p2);
  }

  const points: Vector2[] = [];
  for (let i = 0; i <= segments; i++) {
    const t = (1 / segments) * i;
    points.push({
      x: (1 - t) * (1 - t) * p1.x + 2 * (1 - t) * t * _controlPoint.x + t * t * p2.x,
      y: (1 - t) * (1 - t) * p1.y + 2 * (1 - t) * t * _controlPoint.y + t * t * p2.y,
    });
  }
  return points;
};

// export const updateQuadraticFromPolyline = (p1: Vector2, controlPoint: null | Vector2, p2: Vector2, points: Vector2[]) => {
//   let _controlPoint = controlPoint;

//   if (_controlPoint === null) {
//     _controlPoint = midpoint(p1, p2);
//   }

//   for (let i = 0; i < points.length; i++) {
//     const t = (1 / (points.length - 1)) * i;
//     points[i] = {
//       x: ((1 - t) * (1 - t) * p1.x) + (2 * (1 - t) * t * _controlPoint.x) + (t * t * p2.x),
//       y: ((1 - t) * (1 - t) * p1.y) + (2 * (1 - t) * t * _controlPoint.y) + (t * t * p2.y),
//     };
//   }
//   return points;
// };

// export const convertPointPathToIntArray = (path: Vector2[]) => {
//   path.length *= 2;

//   for (let i = path.length - 1; i >= 0; i -= 2) {
//     // Go backwards through the array, assigning the values to x and y components of the points
//     const sourceIndex = ((i - 1) / 2);

//     path[i] = path[sourceIndex].y;
//     path[i - 1] = path[sourceIndex].x;
//   }
// };

export const convertClipperPointPathToIntArray = (path: ClipperVector2[]): number[] => {
  const nonVectorPath: number[] = [];
  const valueCount = path.length * 2;

  for (let i = valueCount - 1; i >= 0; i -= 2) {
    // Go backwards through the array, assigning the values to x and y components of the points
    const sourceIndex = (i - 1) / 2;

    nonVectorPath[i] = path[sourceIndex].Y;
    nonVectorPath[i - 1] = path[sourceIndex].X;
  }

  return nonVectorPath;
};

interface CubicBezierToPolylineAndPath {
  path: Vector2[];
  polyline: number[];
}

export const cubicBezierToPolylineAndPath = (bezierPoints: CubicBezierPoint[], segments: number): CubicBezierToPolylineAndPath => {
  const path: Vector2[] = [];
  const polyline: number[] = [];

  for (let i = 0; i < bezierPoints.length; i++) {
    const interp = interpolateCubicBezier(
      bezierPoints[i],
      bezierPoints[i].controlPoint1,
      bezierPoints[(i + 1) % bezierPoints.length].controlPoint2,
      bezierPoints[(i + 1) % bezierPoints.length]
    );

    for (let j = 0; j < segments; j++) {
      const t = (1 / (segments - 1)) * j;
      const p = interp(t);
      polyline.push(p[0]);
      polyline.push(p[1]);
      path.push({ x: p[0], y: p[1] });
    }
  }

  return { path, polyline };
};

export const updateCubicBezierPolylineAndPath = (bezierPoints: CubicBezierPoint[], segments: number, path: Vector2[], polyline: number[]): void => {
  if (polyline.length / 2 !== bezierPoints.length * segments || path.length !== bezierPoints.length * segments) {
    // If the number of segments/path points changes we could (probably will) get an invalid update
    console.error('Potentially invalid polyline update for this path/segments combination');
  }

  for (let i = 0; i < bezierPoints.length; i++) {
    const interp = interpolateCubicBezier(
      bezierPoints[i],
      bezierPoints[i].controlPoint1,
      bezierPoints[(i + 1) % bezierPoints.length].controlPoint2,
      bezierPoints[(i + 1) % bezierPoints.length]
    );

    for (let j = 0; j < segments; j++) {
      const t = (1 / (segments - 1)) * j;
      const p = interp(t);
      polyline[i * segments * 2 + j * 2] = p[0]; // x
      polyline[i * segments * 2 + j * 2 + 1] = p[1]; // y
      path[i * segments + j].x = p[0];
      path[i * segments + j].y = p[1];
    }
  }
};

export const updatePolylineAndPath = (polyline: number[], path: Vector2[], dimensions: Dimensions): void => {
  const bezierPoints = ellipseToBezier(dimensions.width, dimensions.height);
  updateCubicBezierPolylineAndPath(bezierPoints, 20, path, polyline);
};

interface PolylineAndPath {
  path: Vector2[];
  polyline: number[];
}

export const createPolylineAndPath = (dimensions: Dimensions): PolylineAndPath => {
  const bezierPoints = ellipseToBezier(dimensions.width, dimensions.height);
  return cubicBezierToPolylineAndPath(bezierPoints, 20);
};

const quadraticBezierToPolylineAndPath = (start: Vector2, controlPoint: Vector2, end: Vector2, segments: number): PolylineAndPath => {
  const path: Vector2[] = [];
  const polyline: number[] = [];

  const interp = interpolateQuadraticBezier(start, controlPoint, end);

  for (let j = 0; j < segments; j++) {
    const t = (1 / (segments - 1)) * j;
    const p = interp(t);
    polyline.push(p[0]);
    polyline.push(p[1]);
    path.push({ x: p[0], y: p[1] });
  }

  return { path, polyline };
};

const updateQuadraticBezierPolylineAndPath = (
  start: Vector2,
  controlPoint: Vector2,
  end: Vector2,
  segments: number,
  path: Vector2[],
  polyline: number[]
): void => {
  const interp = interpolateQuadraticBezier(start, controlPoint, end);

  for (let j = 0; j < segments; j++) {
    const t = (1 / (segments - 1)) * j;
    const p = interp(t);
    polyline[j * 2] = p[0]; // x
    polyline[j * 2 + 1] = p[1]; // y
    if (!path[j]) {
      path[j] = { x: 0, y: 0 };
    }
    path[j].x = p[0];
    path[j].y = p[1];
  }

  // Setting length will remove any excess values
  path.length = segments;
  polyline.length = segments * 2;
};

export const createPolylineAndPathFromLine = (start: Vector2, controlPoint: null | Vector2, end: Vector2): PolylineAndPath => {
  if (controlPoint === null) {
    const polyline: number[] = [];
    const path: Vector2[] = [];
    polyline[0] = start.x;
    polyline[1] = start.y;
    polyline[2] = end.x;
    polyline[3] = end.y;
    polyline.length = 4;
    path[0] = start;
    path[1] = end;
    path.length = 2;

    return { polyline, path };
  }

  return quadraticBezierToPolylineAndPath(start, controlPoint, end, 20);
};

export const updatePolylineAndPathFromLine = (start: Vector2, controlPoint: null | Vector2, end: Vector2, path: Vector2[], polyline: number[]): void => {
  if (controlPoint === null) {
    polyline[0] = start.x;
    polyline[1] = start.y;
    polyline[2] = end.x;
    polyline[3] = end.y;
    polyline.length = 4;
    path[0] = start;
    path[1] = end;
    path.length = 2;
  } else {
    updateQuadraticBezierPolylineAndPath(start, controlPoint, end, 20, path, polyline);
  }
};

export const quadraticBezierLength = (p1: Vector2, p2: Vector2, p3: Vector2): number => {
  const v1x = p2.x * 2;
  const v1y = p2.y * 2;
  const d = p1.x - v1x + p3.x;
  const d1 = p1.y - v1y + p3.y;
  const e = v1x - 2 * p1.x;
  const e1 = v1y - 2 * p1.y;
  let a = 4 * (d * d + d1 * d1);
  const b = 4 * (d * e + d1 * e1);
  let c = e * e + e1 * e1;
  let c1 = a + b + c;
  c1 = 2 * Math.sqrt(c1);
  const u = Math.sqrt(a);
  const a1 = 2 * a * u;
  const u1 = b / u;
  a = 4 * c * a - b * b;
  c = 2 * Math.sqrt(c);
  return (a1 * c1 + u * b * (c1 - c) + a * Math.log((2 * u + u1 + c1) / (u1 + c))) / (4 * a1);
};

const SQRT_OF_3 = Math.sqrt(3);

/**
 * Generates points for an equilateral triangle, centred around middle
 * @param middle The center of the triangle
 * @param sideLength The length of each side of the triangle
 * @returns 3 points
 */
export const equilateralTrianglePoints = (middle: Vector2, sideLength: number) => {
  return {
    p1: {
      x: middle.x,
      y: middle.y - (SQRT_OF_3 / 3) * sideLength,
    },
    p2: {
      x: middle.x - sideLength / 2,
      y: middle.y + (SQRT_OF_3 / 6) * sideLength,
    },
    p3: {
      x: middle.x + sideLength / 2,
      y: middle.y + (SQRT_OF_3 / 6) * sideLength,
    },
  };
};
