import React from 'react';
import { UIMatch, useMatches } from 'react-router-dom';
import Breadcrumb from './breadcrumb';

import styles from './breadcrumb.module.css';

export interface iRouterHandle {
  crumb?: () => JSX.Element;
}

const Breadcrumbs = (): JSX.Element => {
  const matches = useMatches() as UIMatch<unknown, iRouterHandle>[];
  const crumbs = matches.filter((match) => match.handle?.crumb).map((match, i) => <Breadcrumb key={i}>{match.handle.crumb!()}</Breadcrumb>);

  return <div className={styles.breadcrumbs}>{crumbs}</div>;
};

export default Breadcrumbs;
