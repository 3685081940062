import { GardenItemType } from '@gi/constants';
import { SimulatedItem } from './simulated-item';

export enum SimulatedGardenItemEvent {
  DidUpdate = 'DidUpdate',
  EffectsChanged = 'EffectsChanged',
}

export enum SimulatedGardenItemEffect {
  JustCreated = 'JustCreated',
  JustPasted = 'JustPasted',
}

export type SimulatedEventActions = {
  [SimulatedGardenItemEvent.DidUpdate]: (type: GardenItemType, id: number) => void;
  [SimulatedGardenItemEvent.EffectsChanged]: (added: SimulatedGardenItemEffect[], removed: SimulatedGardenItemEffect[]) => void;
};

export type SimulatedGardenItemClipboardData<TType extends GardenItemType = GardenItemType, TData = any> = {
  type: TType;
  data: TData;
};

export abstract class SimulatedGardenItem extends SimulatedItem<SimulatedEventActions> {
  readonly #id: number;
  readonly type: GardenItemType;
  readonly effects: Set<SimulatedGardenItemEffect> = new Set();

  #destroyed: boolean = false;
  get destroyed() {
    return this.#destroyed;
  }

  get id() {
    return this.#id;
  }

  #locked: boolean;
  get locked() {
    return this.#locked;
  }

  #zIndex: number;
  get zIndex() {
    return this.#zIndex;
  }

  constructor(id: number, locked: boolean, zIndex: number) {
    super();
    this.#id = id;
    this.#locked = locked;
    this.#zIndex = zIndex;
  }

  emitUpdates() {
    if (this.destroyed || this.preventEmitUpdates) {
      return;
    }
    this.emit(SimulatedGardenItemEvent.DidUpdate, this.type, this.#id);
  }

  setLocked(locked: boolean) {
    if (locked === this.locked) {
      return;
    }
    this.#locked = locked;
    this.emitUpdates();
  }

  setZIndex(zIndex: number) {
    if (zIndex === this.zIndex) {
      return;
    }
    this.#zIndex = zIndex;
    this.emitUpdates();
  }

  addEffect(...effects: SimulatedGardenItemEffect[]) {
    const addedEffects: SimulatedGardenItemEffect[] = [];

    effects.forEach((effect) => {
      if (!this.effects.has(effect)) {
        this.effects.add(effect);
        addedEffects.push(effect);
      }
    });

    if (addedEffects.length > 0) {
      this.emit(SimulatedGardenItemEvent.EffectsChanged, addedEffects, []);
    }
  }

  removeEffect(...effects: SimulatedGardenItemEffect[]) {
    const removedEffects: SimulatedGardenItemEffect[] = [];

    effects.forEach((effect) => {
      if (this.effects.has(effect)) {
        this.effects.delete(effect);
        removedEffects.push(effect);
      }
    });

    if (removedEffects.length > 0) {
      this.emit(SimulatedGardenItemEvent.EffectsChanged, [], removedEffects);
    }
  }

  /**
   * Prevents emitting updates while the callback is run.
   * Will call emitUpdates after.
   * Can be used to bulk-update properties without emitting loads of events.
   * @param callback Callback used to set properties of this item.
   */
  doBatchUpdates(callback: () => void) {
    this.startBatchUpdate();
    try {
      callback();
    } finally {
      this.endBatchUpdate();
    }
  }

  destroy() {
    this.removeAllListeners();
    this.#destroyed = true;
  }

  abstract getClipboardData(): SimulatedGardenItemClipboardData;
}
