/**
 * A generic set for containing a list of collisions.
 * Only main differences are the ability to set the content of the set,
 * and for a callback function to be called on update.
 */
class CollisionSet<T> {
  #getId: (item: T) => number;
  #collidingWith: Record<number, T> = {};

  /**
   * CReates a new set
   * @param getId A function to return the unique ID of the items contained in the set
   */
  constructor(getId: (item: T) => number) {
    this.#getId = getId;
  }

  /**
   * Adds an item to the set
   * @param item The item to add
   */
  add(item: T) {
    const id = this.#getId(item);
    if (!this.contains(id)) {
      this.#collidingWith[this.#getId(item)] = item;
      this.#onUpdate();
    }
  }

  /**
   * Removes an item from the set
   * @param item The item to remove
   */
  remove(item: T) {
    const id = this.#getId(item);
    if (this.contains(id)) {
      delete this.#collidingWith[this.#getId(item)];
      this.#onUpdate();
    }
  }

  /**
   * Sets the content of this set, returning a diff of the added and removed items
   * @param items The new content of the set
   * @returns A list of the added and removed item IDs
   */
  set(items: T[]) {
    const newCollidingWith: Record<number, T> = {};
    const added: T[] = [];
    const removed: Record<number, T> = { ...this.#collidingWith };
    for (let i = 0; i < items.length; i++) {
      const id = this.#getId(items[i]);
      newCollidingWith[id] = items[i];
      if (removed[id]) {
        delete removed[id];
      } else {
        added.push(items[i]);
      }
    }
    this.#collidingWith = newCollidingWith;
    this.#onUpdate();
    return {
      added,
      removed: Object.values(removed),
    };
  }

  /**
   * Returns true if the set has an item with the gvien ID.
   * @param id The id of the item to find
   * @returns qTrue if the set contains the given ID
   */
  contains(id: number) {
    return this.#collidingWith[id] !== undefined;
  }

  /**
   * Gets the item in the set under the given ID, or null if not found.
   * @param id The id of the item
   * @returns The item under that ID, or null if not found
   */
  getById(id: number): T | null {
    return this.#collidingWith[id] ?? null;
  }

  /**
   * Returns this set as an array
   * @returns An array
   */
  asArray(): T[] {
    return Object.values(this.#collidingWith);
  }

  /**
   * Optional callback function to be called whenever this set is modified in any way.
   */
  onUpdate: (() => void) | null = null;
  #onUpdate() {
    if (this.onUpdate) {
      this.onUpdate();
    }
  }
}

export default CollisionSet;
