import React from 'react';

import FormField, { FORM_FIELD_PRESETS } from '@gi/form-responsive';

interface iProps {
  label: string;
  id: string;
  value: boolean;
  setValue: (value: boolean) => void;
  disabled?: boolean;
}

const PlantListOptionFilter = ({ label, id, value, setValue, disabled = false }: iProps): JSX.Element | null => {
  if (disabled) {
    return null;
  }
  return (
    <FormField label={label} htmlFor={id} layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft} disabled={disabled}>
      <input type='checkbox' id={id} disabled={disabled} checked={value} onChange={(e) => setValue(e.target.checked)} />
    </FormField>
  );
};

export default PlantListOptionFilter;
