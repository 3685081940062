import React, { useMemo } from 'react';

import Modal, {
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalHeader,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalPane,
  ModalPaneContainer,
} from '@gi/modal';
import { LoadingState } from '@gi/constants';

interface iProps {
  title?: string;
  status?: LoadingState;
  onCancel?: () => void;
  onComplete?: () => void;
  onRetry?: () => void;
}

const ImageUploadModal = ({ title = 'Uploading Image', status = LoadingState.LOADING, onCancel, onComplete, onRetry }: iProps): JSX.Element => {
  const content = useMemo(() => {
    switch (status) {
      case LoadingState.NONE:
        return (
          <ModalPane>
            <p>Waiting to upload image</p>
          </ModalPane>
        );
      case LoadingState.SUCCESS:
        return (
          <ModalPane>
            <p>
              <i className='icon-ok' /> Image uploaded
            </p>
          </ModalPane>
        );
      case LoadingState.ERROR:
        return (
          <ModalPane>
            <p>
              <i className='icon-warning' /> Image failed to upload
            </p>
            {onRetry ? (
              <button type='button' className='button button-secondary' onClick={onRetry}>
                Retry
              </button>
            ) : null}
          </ModalPane>
        );
      case LoadingState.LOADING:
      default:
        return (
          <ModalPane>
            <p>
              <i className='icon-spinner animate-pulse' /> Uploading Image...
            </p>
          </ModalPane>
        );
    }
  }, [status, onRetry]);

  return (
    <Modal closeRequest={() => onCancel?.()}>
      <ModalContent>
        <ModalHeader>
          {onCancel ? <ModalCloseButton onClick={onCancel} /> : null}
          <ModalHeaderContent>
            <ModalHeaderTitle>{title}</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>{content}</ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              {onCancel ? (
                <button type='button' className='button button-secondary' onClick={onCancel}>
                  Cancel
                </button>
              ) : null}
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              {onComplete ? (
                <button type='button' className='button button-primary' disabled={status !== LoadingState.SUCCESS} onClick={onComplete}>
                  Done
                </button>
              ) : null}
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImageUploadModal;
