import React, { useCallback, useContext, useMemo, useState } from 'react';

import { PestTag, PestType } from '@gi/pest';
import { GuruPreviewPest } from '@gi/app-guru-types';
import { ResourceContext } from '@gi/resource-provider';
import { useDebounce } from '@gi/react-utils';

import { GuruPestFilters } from '../../context/types';
import ContentGridResultText from './content-grid-result-text';

export const useGuruPestFilters = (id: string, pests: GuruPreviewPest[]) => {
  const { pestSearchService } = useContext(ResourceContext);

  // Stores the filters. Could be stored in the store against the id in the future to make filters persistent.
  const [filters, setFilters] = useState<GuruPestFilters>({
    tags: [],
    type: PestType.Pest,
    searchTerm: '',
  });
  const debouncedSearchTerm = useDebounce(filters.searchTerm, 200);

  // Sets the set of tags to filter by
  const setTags = useCallback((tags: PestTag[]) => {
    setFilters((currentFilters) => ({ ...currentFilters, tags }));
  }, []);

  // Sets the type of pest to filter by
  const setType = useCallback((type: PestType) => {
    setFilters((currentFilters) => ({ ...currentFilters, type, tags: [] }));
  }, []);

  // Sets the search term to filter by
  const setSearchTerm = useCallback((searchTerm: string) => {
    setFilters((currentFilters) => ({ ...currentFilters, searchTerm }));
  }, []);

  // Adds a tag to filter by
  const addTag = useCallback((...tags: PestTag[]) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      tags: [...currentFilters.tags, ...tags],
    }));
  }, []);

  // Removes a tag to filter by
  const removeTag = useCallback((...tags: PestTag[]) => {
    setFilters((currentFilters) => ({
      ...currentFilters,
      tags: [...currentFilters.tags].filter((tag) => !tags.includes(tag)),
    }));
  }, []);

  // Toggles a tag to filter by (adds if not present, removes otherwise)
  const toggleTag = useCallback((tag: PestTag) => {
    setFilters((currentFilters) => {
      const hasTag = currentFilters.tags.includes(tag);
      const newTags = hasTag ? [...currentFilters.tags].filter((t) => t !== tag) : [...currentFilters.tags, tag];
      return {
        ...currentFilters,
        tags: newTags,
      };
    });
  }, []);

  // Clears all filters
  const reset = useCallback(() => {
    setFilters((currentFilters) => ({ type: currentFilters.type, tags: [], searchTerm: '' }));
  }, []);

  // The search results from the text search
  const searchResults = useMemo(() => {
    return pestSearchService.search(debouncedSearchTerm);
  }, [debouncedSearchTerm, pestSearchService]);

  // A function to return search result text for the given pest
  const getSearchResultMatchText = useCallback(
    (pestCode: string) => {
      if (debouncedSearchTerm.length === 0) {
        return undefined;
      }

      const result = searchResults.getResult(pestCode);
      if (result === undefined) {
        return undefined;
      }

      return <ContentGridResultText result={result} primaryText={result?.item.name} />;
    },
    [searchResults, debouncedSearchTerm]
  );

  // All remaining pests that survived the filters
  const filteredPests = useMemo(() => {
    const filteredByTag = pests.filter(({ content }) => {
      if (content.pest === null) {
        return false;
      }
      if (content.pest.type !== filters.type) {
        return false;
      }
      return filters.tags.every((tag) => content.pest.tags.includes(tag));
    });

    if (debouncedSearchTerm.length > 0) {
      return filteredByTag
        .filter((plant) => {
          return searchResults.getResult(plant.content.pestCode) !== undefined;
        })
        .sort((plantA, plantB) => {
          const scoreA = searchResults.getResult(plantA.content.pestCode)?.score ?? -Infinity;
          const scoreB = searchResults.getResult(plantB.content.pestCode)?.score ?? -Infinity;
          if (scoreA === scoreB) {
            return 0;
          }
          return scoreA > scoreB ? -1 : 1;
        });
    }

    return filteredByTag;
  }, [filters, pests, searchResults, debouncedSearchTerm]);

  return useMemo(
    () => ({
      filteredPests,
      filters,
      setTags,
      setType,
      setSearchTerm,
      searchResults,
      getSearchResultMatchText,
      addTag,
      removeTag,
      toggleTag,
      reset,
    }),
    [filteredPests, filters, setTags, setType, setSearchTerm, searchResults, getSearchResultMatchText, addTag, removeTag, toggleTag, reset]
  );
};
