import React, { useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import ReactSelect from 'react-select';
import { useSelector } from 'react-redux';

import { ModalPaneSectionContent } from '@gi/modal';
import { UserPlan, UserUtils } from '@gi/user';
import FormField, { FormSection, InputContainer } from '@gi/form-responsive';
import { FollowOnPlanOptions, PlanUtils } from '@gi/plan';
import { SessionSelectors } from '@gi/react-session';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';
import './follow-on-plan-input.scss';

// TODO: Move to modal defaults somewhere
const DEFAULT_MODAL_FORM_SECTION_PADDING = 12;
const DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT = { labelSize: 120 };

type UserPlanOption = {
  label: string;
  value: number | null;
};

interface iProps {
  sortedUserPlans: UserPlan[];
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  followOnPlan: null | number;
  setFollowOnPlan: (followOnPlan: null | number) => void;
  followOnPlanOptions: FollowOnPlanOptions;
  setFollowOnPlanOptions: (FollowOnPlanOptions: FollowOnPlanOptions) => void;
}

const FollowOnPlanInput = ({
  sortedUserPlans,
  enabled,
  setEnabled,
  followOnPlan,
  setFollowOnPlan,
  followOnPlanOptions,
  setFollowOnPlanOptions,
}: iProps): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);

  // Follow on plans are not available when the user has no other plans
  const notAvailable = sortedUserPlans.length === 0;

  // Check if the alert should be shown. Only show to users who have previous plans but none in current year.
  const shouldShowAlert: boolean = useMemo(() => {
    const currentYear = PlanUtils.calculateNewDefaultPlanYear(user ? UserUtils.isNorthernHemisphere(user) : true);
    const hasCurrentPlans = sortedUserPlans.find((plan) => plan.year === currentYear) !== undefined;
    const hasPreviousPlans = sortedUserPlans.find((plan) => plan.year < currentYear) !== undefined;
    return hasPreviousPlans && !hasCurrentPlans;
  }, [user, sortedUserPlans]);

  // Allow the user to dismiss the alert
  const [alertDismissed, setAlertDismissed] = useState(false);

  // Memoised list of user plans for dropdown
  const userPlanOptions: UserPlanOption[] = useMemo(() => {
    return sortedUserPlans.map((userPlan) => {
      return {
        label: `${userPlan.name}, ${userPlan.year}`,
        value: userPlan.ID,
      };
    });
  }, [sortedUserPlans]);

  const selectedUserPlanOption: UserPlanOption = useMemo(() => {
    for (let i = 0; i < userPlanOptions.length; i++) {
      if (userPlanOptions[i].value === followOnPlan) {
        return userPlanOptions[i];
      }
    }

    return { value: null, label: '-' };
  }, [userPlanOptions, followOnPlan]);

  // Update an individual FollowOnPlanOptions value
  const setFollowOnPlanOption = <K extends keyof FollowOnPlanOptions>(key: K, value: FollowOnPlanOptions[K]) => {
    setFollowOnPlanOptions({
      ...followOnPlanOptions,
      [key]: value,
    });
  };

  const style = {
    opacity: enabled ? 1 : 0.6,
  };

  return (
    <ModalPaneSectionContent className={`follow-on-plan-input ${notAvailable ? 'disabled' : ''}`}>
      <div className='follow-on-plan-toggle-container'>
        <AnimateHeight duration={500} height={shouldShowAlert && !alertDismissed && !enabled ? 'auto' : 0}>
          <div className='follow-on-plan-alert'>
            <button className='follow-on-plan-alert-dismiss' type='button' aria-label='Dismiss alert' onClick={() => setAlertDismissed(true)}>
              <i className='icon-cancel' />
            </button>
            <p>
              <strong>You have a plan from a previous year, do you want to make a follow-on plan?</strong>
            </p>
            <p>A follow-on plan will allow you to start from the same place this year.</p>
          </div>
        </AnimateHeight>
        <label>
          <span className='toggle-container'>
            <input
              type='checkbox'
              className='toggle-input'
              checked={enabled}
              onChange={(e) => {
                setEnabled(e.target.checked);
              }}
              disabled={notAvailable}
            />
            <span className='toggle' />
          </span>
          <div className='toggle-text'>
            <strong className='follow-on-plan-instructions'>Create Follow-on Plan</strong>
            <p className='follow-on-plan-instructions'>Create plan from a plan you already created for a previous year, copying plan content.</p>
            {notAvailable ? <p className='follow-on-plan-warning'>As you have no existing plans for previous years, please create a plan below.</p> : null}
          </div>
        </label>
      </div>
      <AnimateHeight duration={500} height={enabled ? 'auto' : 0}>
        <div style={style}>
          <FormSection className='follow-on-Plan-input-section' padding={DEFAULT_MODAL_FORM_SECTION_PADDING}>
            <FormField htmlFor='previous-plan' label='Previous Plan' desktopLayout={DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT}>
              <InputContainer size='full'>
                <ReactSelect<UserPlanOption>
                  styles={DEFAULT_SELECT_STYLES}
                  isDisabled={!enabled}
                  options={userPlanOptions}
                  value={selectedUserPlanOption}
                  onChange={(newValue) => {
                    if (newValue !== null) {
                      setFollowOnPlan(newValue.value);
                    }
                  }}
                  isSearchable={false}
                />
              </InputContainer>
            </FormField>
            <FormField label='Copy' fakeLabel desktopLayout={{ labelSize: 120, inputSize: 'full' }}>
              <div className='copy-details-pane'>
                <div className='copy-details-main-part'>
                  <div className='copy-details-row'>
                    <label className='copy-details-label'>
                      <input
                        type='checkbox'
                        disabled={!enabled}
                        checked={followOnPlanOptions.copyLayout}
                        onChange={(e) => {
                          setFollowOnPlanOption('copyLayout', e.target.checked);
                        }}
                      />
                      <span className='label-text'>Layout</span>
                    </label>
                    <label className='copy-details-label'>
                      <input
                        type='checkbox'
                        disabled={!enabled}
                        checked={followOnPlanOptions.copyText}
                        onChange={(e) => {
                          setFollowOnPlanOption('copyText', e.target.checked);
                        }}
                      />
                      <span className='label-text'>Text</span>
                    </label>
                  </div>
                  <div className='copy-details-row'>
                    <label className='copy-details-label'>
                      <input
                        type='checkbox'
                        disabled={!enabled}
                        checked={followOnPlanOptions.copyIrrigation}
                        onChange={(e) => {
                          setFollowOnPlanOption('copyIrrigation', e.target.checked);
                        }}
                      />
                      <span className='label-text'>Irrigation</span>
                    </label>
                    <label className='copy-details-label'>
                      <input
                        type='checkbox'
                        disabled={!enabled}
                        checked={followOnPlanOptions.copyStructures}
                        onChange={(e) => {
                          setFollowOnPlanOption('copyStructures', e.target.checked);
                        }}
                      />
                      <span className='label-text'>Structures</span>
                    </label>
                  </div>
                  <div className='copy-details-row'>
                    <label className='copy-details-label'>
                      <input
                        type='checkbox'
                        disabled={!enabled}
                        checked={followOnPlanOptions.copyNotes}
                        onChange={(e) => {
                          setFollowOnPlanOption('copyNotes', e.target.checked);
                        }}
                      />
                      <span className='label-text'>Plan Notes</span>
                    </label>
                  </div>
                </div>
                <div className='copy-details-part plants-check'>
                  <label className='copy-details-label'>
                    <input
                      type='checkbox'
                      disabled={!enabled}
                      checked={followOnPlanOptions.copyPlants}
                      onChange={(e) => {
                        setFollowOnPlanOption('copyPlants', e.target.checked);
                      }}
                    />
                    <span className='label-text'>Plants</span>
                  </label>
                </div>
                <div className='copy-details-part plants-radio'>
                  <div>
                    <label className={`copy-details-label ${followOnPlanOptions.copyPlants ? '' : 'copy-details-label-disabled'}`}>
                      <input
                        type='radio'
                        disabled={!enabled || !followOnPlanOptions.copyPlants}
                        checked={followOnPlanOptions.allPlants}
                        onChange={(e) => {
                          setFollowOnPlanOption('allPlants', e.target.checked);
                        }}
                      />
                      <span className='label-text'>All Plants</span>
                    </label>
                  </div>
                  <div>
                    <label className={`copy-details-label ${followOnPlanOptions.copyPlants ? '' : 'copy-details-label-disabled'}`}>
                      <input
                        type='radio'
                        disabled={!enabled || !followOnPlanOptions.copyPlants}
                        checked={!followOnPlanOptions.allPlants}
                        onChange={(e) => {
                          setFollowOnPlanOption('allPlants', !e.target.checked);
                        }}
                      />
                      <span className='label-text'>Perennials</span>
                    </label>
                  </div>
                </div>
              </div>
            </FormField>
          </FormSection>
        </div>
      </AnimateHeight>
    </ModalPaneSectionContent>
  );
};

export default FollowOnPlanInput;
