import React from 'react';

import './sidebar-item-title.scss';

interface iProps {
  children: React.ReactNode;
}

const SidebarItemTitleText = ({ children }: iProps) => {
  return <h3 className='item-title'>{children}</h3>;
};

export default SidebarItemTitleText;
