import { Geometry } from '@gi/math';
import { State, StateDef } from '@gi/state';
import { DEFAULT_PLANNER_SETTINGS } from '@gi/plan';
import { HandleDisplayMode, HandleSetNode, InspectableClassData, ManipulatableComponent, Node, NodeEvent, bindState } from '@gi/core-renderer';

import { SnapUtils } from '../../../simulation/snap-utils';
import SettingsContext, { SettingsContextState } from '../settings-context';
import PlanSettingsContext, { PlanSettingsContextState } from '../plan-settings-context';
import { DEFAULT_GARDEN_CANVAS_SETTINGS } from '../../../garden-canvas-settings';

type BaseHandleSetNodeState = StateDef<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  [],
  {
    settings: SettingsContextState;
    planSettings: PlanSettingsContextState;
  }
>;

/**
 * BaseHandleSetNode
 *  An extension of the default HandleSetNode
 *  Adds scaling and vector snapping functionality.
 *  This could go in the HandleSetNode class in the future, but currently PlanSettingsContext isn't
 *    part of core-renderer, so it can't.
 */
class BaseHandleSetNode extends HandleSetNode {
  get angleSnap() {
    return this.settingsState.get('settings', 'angleSnap', DEFAULT_GARDEN_CANVAS_SETTINGS.angleSnap);
  }
  get angleSnapMagnetism() {
    return this.settingsState.get('settings', 'angleSnapMagnetism', DEFAULT_GARDEN_CANVAS_SETTINGS.angleSnapMagnetism);
  }
  get snapToGrid() {
    return this.settingsState.get('settings', 'snapToGrid', DEFAULT_GARDEN_CANVAS_SETTINGS.snapToGrid);
  }
  get snapToGridDistance() {
    const metric = this.settingsState.get('planSettings', 'metric', DEFAULT_PLANNER_SETTINGS.metric);
    return SnapUtils.getSnapDistanceFromIsMetric(metric);
  }
  get touchMode() {
    return this.settingsState.get('settings', 'touchMode', DEFAULT_GARDEN_CANVAS_SETTINGS.touchMode);
  }

  settingsState: State<BaseHandleSetNodeState>;

  // The nodes the handle set is meant to be manipulating.
  // Will automatically apply handleHovered if nodes have a ManipulatableComponent.
  #targets: Readonly<Node[]> = [];
  get targets() {
    return this.#targets;
  }
  set targets(targets: Readonly<Node[]>) {
    this.#targets = targets;
  }

  constructor() {
    super();

    this.settingsState = new State({});
    bindState(this.settingsState, this);

    this.state.addUpdater(
      (state) => {
        for (let i = 0; i < this.targets.length; i++) {
          const manipulatable = this.targets[i].components.get(ManipulatableComponent);
          if (manipulatable) {
            manipulatable.state.values.hoveringHandles = state.values.handleHovered;
          }
        }
      },
      { properties: ['handleHovered'] }
    );

    this.settingsState.addWatcher(
      (state) => {
        const touchMode = state.get('settings', 'touchMode');
        if (touchMode !== undefined) {
          this.setHandleDisplayMode(touchMode ? HandleDisplayMode.TOUCH : HandleDisplayMode.MOUSE);
        }
      },
      { otherStates: { settings: { properties: ['touchMode'] } } }
    );

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    const settingsContext = this.getContext(SettingsContext);
    const planSettingsContext = this.getContext(PlanSettingsContext);
    this.settingsState.connectState('settings', settingsContext.state);
    this.settingsState.connectState('planSettings', planSettingsContext.state);
  };

  #onBeforeUnbind = () => {
    this.onUnhover();
    this.settingsState.disconnectState('settings');
    this.settingsState.disconnectState('planSettings');
  };

  /**
   * Snaps a vector to the nearest `angleSnap` angle.
   * Will use the `angleSnapMagnetism` if forceSnap is falsey
   * @param inputVector The vector to snap
   * @param origin The origin point to calculate the nagle from
   * @param forceSnap Should the snap be forced to happen
   * @returns The vector, snapped if appropriate, otherwise unchanged
   */
  snapVector(inputVector: Vector2, origin: Vector2, forceSnap?: boolean) {
    const angleInRadians = this.angleSnap * (Math.PI / 180);
    const magnetismInRadians = this.angleSnapMagnetism * (Math.PI / 180);

    const currentAngle = Geometry.angleBetweenPoints(origin, inputVector);
    const roundedAngle = Math.floor(currentAngle / angleInRadians + 0.5) * angleInRadians;
    const angleDiff = roundedAngle - currentAngle;

    if (!forceSnap && Math.abs(angleDiff) > magnetismInRadians) {
      return inputVector;
    }

    const dist = Geometry.dist(origin, inputVector);
    const newDist = dist / Math.cos(angleDiff);

    return {
      x: origin.x + Math.cos(roundedAngle) * newDist,
      y: origin.y + Math.sin(roundedAngle) * newDist,
    };
  }

  /**
   * Called whenever one of the handles is hovered. Will pass that info onto the handle targets.
   */
  onHover = () => {
    this.targets.forEach((target) => {
      const manipulatable = target.components.get(ManipulatableComponent);
      if (manipulatable) {
        manipulatable.setIsHoveringHandle(true);
      }
    });
  };

  /**
   * Called whenever one of the handles is unhovered. Will pass that info onto the handle targets.
   */
  onUnhover = () => {
    this.targets.forEach((target) => {
      const manipulatable = target.components.get(ManipulatableComponent);
      if (manipulatable) {
        manipulatable.setIsHoveringHandle(false);
      }
    });
  };

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default BaseHandleSetNode;
