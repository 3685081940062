import { Filter } from '@gi/filters';
import Plant from '@gi/plant';
import { PlantTags } from '@gi/constants';

type FrostTolerantFilterState = {
  enabled: boolean;
};

const frostTolerantFilter: Filter<Plant, FrostTolerantFilterState> = {
  name: 'Frost Tolerant',
  inputs: { enabled: false },
  createListFilter: ({ enabled }) => {
    return (plantList) => {
      if (!enabled) {
        return plantList;
      }

      return plantList.filter((plant) => {
        return plant.hasTag(PlantTags.FROST_TOLERANT);
      });
    };
  },
};

export default frostTolerantFilter;
