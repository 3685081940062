import { GardenItemType } from '@gi/constants';
import { Plan } from './plan';

export function getFreshPlanItemId(plan: Plan, id: number): [Plan, number] {
  const updatedPlan = { ...plan };
  let newMaxItemId = plan.maxItemId;

  if (id === -1) {
    // No given Id, increment maxItemId and assign it
    newMaxItemId += 1;
    updatedPlan.maxItemId = newMaxItemId;
    return [updatedPlan, newMaxItemId];
  }

  if (id > newMaxItemId) {
    // If the id of the item is higher than the maxItemId then we need to set the maxItemId to it
    newMaxItemId = id;
    updatedPlan.maxItemId = newMaxItemId;
    return [updatedPlan, newMaxItemId];
  }

  if (plan.itemTypes[id]) {
    // There's already an itemType assigned to this id so it's taken, if we've not messed up any other
    // part of the plan generation then this shouldn't happen but just incase we can re-assign the id as the new max
    newMaxItemId += 1;
    updatedPlan.maxItemId = newMaxItemId;
    return [updatedPlan, newMaxItemId];
  }

  // Nothing wrong with the Id or the plan
  return [plan, id];
}

export function getUpdatedZIndexLimits(plan: Plan, zIndex: number): { minZIndex: number; maxZIndex: number } {
  return {
    minZIndex: Math.min(plan.minZIndex, zIndex),
    maxZIndex: Math.max(plan.maxZIndex, zIndex),
  };
}

/**
 * Returns if any of the items with the given IDs are locked or unlocked.
 * @param plan The plan to check in
 * @param ids The item IDs to check
 * @returns If any of the items are locked or unlocked. At least one should be `true`.
 * Both being `true` implies a mix of locked and unlocked objects.
 */
export function getLockedStatus(plan: Plan, ids: number[]): { anyLocked: boolean; anyUnlocked: boolean } {
  const result = { anyLocked: false, anyUnlocked: false };

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const itemType = plan.itemTypes[id];
    if (!itemType) {
      // eslint-disable-next-line no-continue
      continue;
    }
    switch (itemType) {
      case GardenItemType.Plant:
        const plant = plan.plants[id];
        if (plant) {
          result.anyLocked ||= plant.locked;
          result.anyUnlocked ||= !plant.locked;
        }
        break;
      case GardenItemType.GardenObject:
        const gardenObject = plan.gardenObjects[id];
        if (gardenObject) {
          result.anyLocked ||= gardenObject.locked;
          result.anyUnlocked ||= !gardenObject.locked;
        }
        break;
      case GardenItemType.Shape:
        const shape = plan.shapes[id];
        if (shape) {
          result.anyLocked ||= shape.locked;
          result.anyUnlocked ||= !shape.locked;
        }
        break;
      case GardenItemType.Text:
        const text = plan.shapes[id];
        if (text) {
          result.anyLocked ||= text.locked;
          result.anyUnlocked ||= !text.locked;
        }
        break;
      default:
      // Ignore
    }

    // Don't bother continuing of we've found 1 of both type
    if (result.anyLocked && result.anyUnlocked) {
      return result;
    }
  }

  return result;
}

/**
 * Sets all the items with the given IDs to locked/unlocked, depending on `locked`
 * @param plan The plan to modify
 * @param ids The ids of the items to alter
 * @param locked Should these items be locked or not
 * @returns A copy of the plan with the items modified
 */
export function setLockedStatus(plan: Plan, ids: number[], locked: boolean): Plan {
  const newPlan: Plan = { ...plan };

  newPlan.plants = { ...newPlan.plants };
  newPlan.gardenObjects = { ...newPlan.gardenObjects };
  newPlan.shapes = { ...newPlan.shapes };
  newPlan.text = { ...newPlan.text };

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const type = plan.itemTypes[id];
    switch (type) {
      case GardenItemType.Plant:
        newPlan.plants[id] = {
          ...newPlan.plants[id],
          locked,
        };
        break;
      case GardenItemType.GardenObject:
        newPlan.gardenObjects[id] = {
          ...newPlan.gardenObjects[id],
          locked,
        };
        break;
      case GardenItemType.Shape:
        newPlan.shapes[id] = {
          ...newPlan.shapes[id],
          locked,
        };
        break;
      case GardenItemType.Text:
        newPlan.text[id] = {
          ...newPlan.text[id],
          locked,
        };
        break;
      default:
        console.warn(`No item with ID ${id} found in plan with ID ${plan.id}`);
    }
  }

  return newPlan;
}

/**
 * Returns the min and max zIndex of all the items given.
 * @param plan The plan to get the items from
 * @param ids The ids of the items to check
 * @returns A min and max zIndex across all the itmes given
 */
export function getZIndexStatus(plan: Plan, ids: number[]): { min: number; max: number } {
  const result = { min: 0, max: 0 };

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const itemType = plan.itemTypes[id];
    if (!itemType) {
      // eslint-disable-next-line no-continue
      continue;
    }
    switch (itemType) {
      case GardenItemType.Plant:
        const plant = plan.plants[id];
        if (plant) {
          result.min = Math.min(result.min, plant.zIndex);
          result.max = Math.max(result.max, plant.zIndex);
        }
        break;
      case GardenItemType.GardenObject:
        const gardenObject = plan.gardenObjects[id];
        if (gardenObject) {
          result.min = Math.min(result.min, gardenObject.zIndex);
          result.max = Math.max(result.max, gardenObject.zIndex);
        }
        break;
      case GardenItemType.Shape:
        const shape = plan.shapes[id];
        if (shape) {
          result.min = Math.min(result.min, shape.zIndex);
          result.max = Math.max(result.max, shape.zIndex);
        }
        break;
      case GardenItemType.Text:
        const text = plan.shapes[id];
        if (text) {
          result.min = Math.min(result.min, text.zIndex);
          result.max = Math.max(result.max, text.zIndex);
        }
        break;
      default:
      // Ignore
    }
  }

  return result;
}

/**
 * Sets the zIndex of the given items, returning an updated copy of the plan
 * @param plan The plan to update
 * @param ids The ids of the items to update
 * @param zIndex The zIndex value to use
 * @returns An updated copy of the plan, wth zIndexes updated
 */
export function setZIndex(plan: Plan, ids: number[], zIndex: number): Plan {
  const newPlan: Plan = { ...plan };

  newPlan.plants = { ...newPlan.plants };
  newPlan.gardenObjects = { ...newPlan.gardenObjects };
  newPlan.shapes = { ...newPlan.shapes };
  newPlan.text = { ...newPlan.text };

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const type = plan.itemTypes[id];
    switch (type) {
      case GardenItemType.Plant:
        newPlan.plants[id] = {
          ...newPlan.plants[id],
          zIndex,
        };
        break;
      case GardenItemType.GardenObject:
        newPlan.gardenObjects[id] = {
          ...newPlan.gardenObjects[id],
          zIndex,
        };
        break;
      case GardenItemType.Shape:
        newPlan.shapes[id] = {
          ...newPlan.shapes[id],
          zIndex,
        };
        break;
      case GardenItemType.Text:
        newPlan.text[id] = {
          ...newPlan.text[id],
          zIndex,
        };
        break;
      default:
        console.warn(`No item with ID ${id} found in plan with ID ${plan.id}`);
    }
  }

  const { minZIndex, maxZIndex } = getUpdatedZIndexLimits(newPlan, zIndex);
  newPlan.minZIndex = minZIndex;
  newPlan.maxZIndex = maxZIndex;

  return newPlan;
}
