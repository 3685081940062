import { Store } from 'redux';
import { PlanSetUtils, PlanUtils } from '@gi/plan';
import { savePlan } from '../redux-components/canvas-action-creators';
import { CanvasReducerState } from '../redux-components/canvas-reducer';
import { SavePlanType } from '../redux-components/canvas-action-creator-types';

const AUTOSAVE_TIME = 10 * 60 * 1000; // 10 minutes

const isAutosaveEnabled = (state) => {
  if (state.session.user === null) {
    return false;
  }

  return !!state.session.user.planSettings.autosave;
};

const isSavingAllowed = (state) => {
  return state.canvas.allowSave;
};

interface BaseStoreState {
  canvas: CanvasReducerState;
}

/**
 * Sets up autosaving functionality for garden plan plans
 *
 * Listens to the store for changes of the users auto-save setting which controls whether
 * 'startAutosaving' and 'stopAutosaving' are called based on this value
 */
const setupAutosaver = (store: Store<BaseStoreState>) => {
  let currentState = store.getState();

  let intervalID: ReturnType<typeof setInterval> | null = null;

  const runAutosave = () => {
    console.debug('%cChecking Autosave interval', 'background: #222; color: #bada55');
    // Go through each open plan and check for unsaved changes, if a plan has unsaved changes then dispatch save action for that plan
    const { openPlanIDs } = currentState.canvas;

    for (let i = 0; i < openPlanIDs.length; i++) {
      const openPlanID = openPlanIDs[i];

      const plan = PlanSetUtils.planSetGetPlan(currentState.canvas.plans, openPlanID);
      const lastSavedPlan = PlanSetUtils.planSetGetPlan(currentState.canvas.lastSavePlans, openPlanID);

      if (plan === null || lastSavedPlan === null) {
        // If either plan is null then something hasn't loaded yet or didn't load correctly, don't risk saving
        console.debug('Autosave plan check is null');
        // eslint-disable-next-line no-continue
        continue;
      }

      if (!PlanUtils.plansShallowEqual(plan, lastSavedPlan)) {
        console.debug(`%cAutosaving... ${plan.name}`, 'background: #222; color: #bada55');
        store.dispatch(savePlan(plan, SavePlanType.AUTOSAVE));
      } else {
        console.debug(`%cNo changes in ${plan.name}, not autosaving`, 'background: #222; color: #bada55');
      }
    }
  };

  const startAutosaving = () => {
    console.debug('%cStarting autosave monitor', 'background: #222; color: #bada55');

    if (intervalID !== null) {
      console.error('Asked to start autosaving when intervalID is not null');
      return;
    }

    intervalID = setInterval(runAutosave, AUTOSAVE_TIME);
  };

  const stopAutosaving = () => {
    console.debug('%c Stopping autosave monitor', 'background: #222; color: #bada55');

    if (intervalID === null) {
      console.warn('Asked to stop autosaving when intervalID is null');
      return;
    }

    clearInterval(intervalID);
    intervalID = null;
  };

  const handleChange = () => {
    const previousState = currentState;
    currentState = store.getState();

    if (previousState === currentState) {
      // No change
      return;
    }

    const previousAutosave = isAutosaveEnabled(previousState);
    const currentAutosave = isAutosaveEnabled(currentState);
    const savingAllowed = isSavingAllowed(currentState);

    if (previousAutosave === currentAutosave) {
      return;
    }

    if (currentAutosave && savingAllowed) {
      // Enable autosave
      startAutosaving();
    } else {
      // Disable autosave
      stopAutosaving();
    }
  };

  store.subscribe(handleChange);

  const autosaveEnabled = isAutosaveEnabled(currentState);

  if (autosaveEnabled) {
    // Enable autosave
    startAutosaving();
  }
};

export default setupAutosaver;
