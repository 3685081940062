const RecordTypes = {
  CREATE: 'C',
  UPDATE: 'A',
  DELETE: 'D',
};

type APIPlanItem = {
  action: string;
  objectID: number;
};

/**
 * Function for converting a list of items form the API from using Create, Update and Delete actions to a list of items which remain at the end
 *
 * The API uses a list of items identified by an object ID, be it plants, garden objects, shapes or text. Each of those items can be
 * duplicated multiple times, with different action types (CREATE, UPDATE and DELETE). These were created to deal with fills, so when
 * a fill shape is found, the plan can be reverted to the state it was at during the time of the fill (by following the chain of item creates, updates and deletes).
 *
 * The new Garden Planner doesn't care about the action types because it doesn't support fills. So this class manages creating a PlanItemList from the latest updates of
 * each item (and removing it, if it had the delete action).
 *
 * @param {APIItems[]} inputItems
 * @returns {APIItems[]}
 */
export const convertToParseList = <T extends APIPlanItem>(inputItems: T[]): T[] => {
  const items: T[] = [];
  const latest: Record<number, number> = {};
  const objectIDs: number[] = [];

  const add = (item: T) => {
    if (item.action === RecordTypes.CREATE) {
      // There should be no other objects with this items object ID already but we'll check anyway just in case
      if (objectIDs.includes(item.objectID)) {
        console.error(`Item already exists in item list ${item.objectID}`);
        return;
      }

      items.push(item);
      objectIDs.push(item.objectID);
      latest[item.objectID] = items.length - 1;
      return;
    }

    if (item.action === RecordTypes.UPDATE) {
      // There should be no other objects with this items object ID already but we'll check anyway just in case
      if (!objectIDs.includes(item.objectID)) {
        console.error(`Item doesn't exist in item list but update has been used ${item.objectID}`);
        return;
      }

      items.push(item);
      latest[item.objectID] = items.length - 1;

      return;
    }

    if (item.action === RecordTypes.DELETE) {
      // There should be other objects with this items object ID already but we'll check anyway just in case
      if (!objectIDs.includes(item.objectID)) {
        console.error(`Item doesn't exist in item list but delete has been used ${item.objectID}`);
        return;
      }

      const itemIndex = objectIDs.indexOf(item.objectID);

      items.push(item);
      objectIDs.splice(itemIndex, 1);
      delete latest[item.objectID];

      return;
    }

    throw new Error(`Unknown action type on new plan item ${item.action}`);
  };

  for (let i = 0; i < inputItems.length; i++) {
    add(inputItems[i]);
  }

  return objectIDs.map((objectID) => latest[objectID]).map((index) => items[index]);
};
