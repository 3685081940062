import React, { ReactNode, useContext, useMemo } from 'react';

import { PlanDataError } from '@gi/react-garden-canvas';
import { ResourceContext } from '@gi/resource-provider';
import { ShapeType } from '@gi/constants';

import styles from './plan-data-error-entry.module.css';

interface iProps {
  planDataError: PlanDataError;
}

function makeRow(type: string, id: string, item: string, count: number): ReactNode {
  return (
    <tr key={`${type}-${id}`}>
      <td>{type}</td>
      <td>{item}</td>
      <td>{count}</td>
    </tr>
  );
}

const PlanDataErrorEntry = ({ planDataError }: iProps): JSX.Element => {
  const { getPlant, getGardenObject } = useContext(ResourceContext);

  // Reduce the [plantID, properties[]][] to a Record<plantCode, count>;
  const plantGroups = useMemo(() => {
    const plants: Record<string, number> = {};
    planDataError.invalidProperties.plants.forEach((plant) => {
      const { plantCode } = planDataError.plan.plants[plant.id];
      plants[plantCode] = (plants[plantCode] ?? 0) + 1;
    });
    return plants;
  }, [planDataError]);

  // Reduce the [gardenObjectID, properties[]][] to a Record<gardenObjectCode, count>;
  const gardenObjectGroups = useMemo(() => {
    const gardenObjects: Record<string, number> = {};
    planDataError.invalidProperties.gardenObjects.forEach((gardenObject) => {
      const { code } = planDataError.plan.gardenObjects[gardenObject.id];
      gardenObjects[code] = (gardenObjects[code] ?? 0) + 1;
    });
    return gardenObjects;
  }, [planDataError]);

  // Reduce the [shapeID, properties[]][] to a Record<shapeType, count>;
  const shapeGroups = useMemo(() => {
    const shapes: Partial<Record<ShapeType, number>> = {};
    planDataError.invalidProperties.shapes.forEach((shape) => {
      const { type } = planDataError.plan.shapes[shape.id];
      shapes[type] = (shapes[type] ?? 0) + 1;
    });
    return shapes;
  }, [planDataError]);

  return (
    <div className={styles.plan}>
      <div className={styles.planStatus}>
        <span>
          {planDataError.plan.name} <span className={styles.year}>- {planDataError.plan.year}</span>
        </span>
        <span className={styles.dots} />
        {planDataError.uploaded ? (
          <span>
            <i className={`icon-ok ${styles.success}`} /> Plan Saved Successfully
          </span>
        ) : (
          <span>
            <i className={`icon-cancel ${styles.error}`} /> Plan Save Failed
          </span>
        )}
      </div>
      <p className={styles.tableTitle}>Items Corrected</p>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              <td>Type</td>
              <td>Item</td>
              <td>Quantity</td>
            </tr>
          </thead>
          <tbody>
            {Object.entries(plantGroups).map(([plantCode, count]) => {
              return makeRow('Plant', plantCode, getPlant(plantCode)?.name ?? 'Unknown Plant', count);
            })}
            {Object.entries(gardenObjectGroups).map(([gardenObjectCode, count]) => {
              return makeRow('Garden Object', gardenObjectCode, getGardenObject(gardenObjectCode)?.name ?? 'Unknown Garden Object', count);
            })}
            {Object.entries(shapeGroups).map(([shape, count]) => {
              return makeRow('Shape', shape, shape, count);
            })}
            {planDataError.invalidProperties.text.length > 0 ? makeRow('Text', 'Text', '-', planDataError.invalidProperties.text.length) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlanDataErrorEntry;
