import { NetworkDataLocationConfig } from '../network-config';

/**
 * These are all the allowed CSS variable names.
 * Every client should specify these within their "theme" object.
 */
export const ThemeVariableNames = [
  'color-primary',
  'color-primary-dark',
  'color-primary-content',
  'color-secondary',
  'color-secondary-dark',
  'color-background',
  'color-background-light',
  'color-background-dark',
  'color-text',
  'color-text-light',
  'color-text-extra-light',
  'color-text-accent',
  'color-link',
  'color-link-hover',
  'color-link-active',
  'color-error',
  'color-error-alt',
  'color-error-text',
  'color-error-text-alt',
  'button-default-background',
  'button-default-background-hover',
  'button-default-background-active',
  'button-default-border',
  'button-default-border-hover',
  'button-default-border-active',
  'button-default-text-shadow',
  'button-default-color',
  'button-default-color-hover',
  'button-default-color-active',
  'button-primary-background',
  'button-primary-background-hover',
  'button-primary-background-active',
  'button-primary-border',
  'button-primary-border-hover',
  'button-primary-border-active',
  'button-primary-text-shadow',
  'button-primary-color',
  'button-primary-color-hover',
  'button-primary-color-active',
  'button-primary-light-background',
  'button-primary-light-background-hover',
  'button-primary-light-background-active',
  'button-primary-light-border',
  'button-primary-light-border-hover',
  'button-primary-light-border-active',
  'button-primary-light-text-shadow',
  'button-primary-light-color',
  'button-primary-light-color-hover',
  'button-primary-light-color-active',
  'button-secondary-background',
  'button-secondary-background-hover',
  'button-secondary-background-active',
  'button-secondary-border',
  'button-secondary-border-hover',
  'button-secondary-border-active',
  'button-secondary-text-shadow',
  'button-secondary-color',
  'button-secondary-color-hover',
  'button-secondary-color-active',
  'button-disabled-background',
  'button-disabled-background-hover',
  'button-disabled-background-active',
  'button-disabled-border',
  'button-disabled-border-hover',
  'button-disabled-border-active',
  'button-disabled-text-shadow',
  'button-disabled-color',
  'button-disabled-color-hover',
  'button-disabled-color-active',
  'button-warning-background',
  'button-warning-background-hover',
  'button-warning-background-active',
  'button-warning-border',
  'button-warning-border-hover',
  'button-warning-border-active',
  'button-warning-text-shadow',
  'button-warning-color',
  'button-warning-color-hover',
  'button-warning-color-active',
  'button-inline-color',
  'button-inline-hover',
  'button-inline-active',
  'loading-screen-background-color',
  'sidebar-right-border',
  'color-sidebar-icon-background',
  'color-sidebar-icon',
  'color-sidebar-icon-active',
  'color-sidebar-icon-hover',
  'color-sidebar-background',
  'color-sidebar-active-background',
  'color-sidebar-text',
  'color-sidebar-active-circle',
  'color-sidebar-menu-burger',
  'color-sidebar-menu-burger-hover',
  'color-sidebar-close',
  'color-sidebar-close-background',
  'color-sidebar-close-background-hover',
  'sidebar-menu-burger-text-shadow',
  'logo-container-background-color',
  'logo-image-filter',
  'color-modal-header-background',
  'color-modal-header',
  'text-shadow-modal-header',
  'inactive-tab-color',
  'modal-close-button-color',
  'modal-close-button-hover-color',
  'modal-close-button-active-color',
  'color-planner-header-tab-line',
  'color-planner-header-tab-line-active',
  'color-planner-header-circle',
  'color-selection-list-icon',
  'color-selection-list-icon-active',
  'required-label-color',
  'plant-item-attribute-label-color',
  'expand-button-color',
  'dashboard-logo-background-color',
  'dashboard-logo-width',
] as const;

export type ThemeVariableName = (typeof ThemeVariableNames)[number];

export type RuntimeConfig = {
  client: string;
  clientName: string;
  clientID: number;
  shoppingEnabled: boolean;
  /** Should the Articles section be shown on the Dashboard */
  showDashboardArticles: boolean;
  catalogueFile: string;
  supplierFile: string;
  supportEmail: string;
  guidesURL: string;
  plantGuidesURL: string;
  pestGuidesURL: string;
  videoURL: string;
  WGPBugsnagKey: string;
  homeURL: string;
  publishPlanURL: string;
  partsListMessageHTML?: string;
  featureFlags: string[];
  dataURLOverrides: Partial<NetworkDataLocationConfig>;
  theme: { [key in ThemeVariableName]: string };
  backgroundImage: {
    src: string;
    blurSrc: string;
  };
  logo: {
    src: string;
    height: number | string;
  };
  loadingLogo?: {
    src?: string;
    height?: number | string;
  };
};
