import React from 'react';

import TableRow from '../common/table-row';

import InformationHeaderCell from './header-cells/information-header-cell';
import FavouriteHeaderCell from './header-cells/favourite-header-cell';
import IconHeaderCell from './header-cells/icon-header-cell';
import PlantNameHeaderCell from './header-cells/plant-name-header-cell';
import SpacingsHeaderCell from './header-cells/spacings-header-cell';
import CalendarHeaderCell from './header-cells/calendar-header-cell';
import CountsHeaderCell from './header-cells/counts-header-cell';
import ShowAreaTableHeaderCell from './header-cells/show-area-table-header-cell';

const SummaryTableHead = () => {
  return (
    <TableRow>
      <ShowAreaTableHeaderCell />
      <InformationHeaderCell />
      <FavouriteHeaderCell />
      <IconHeaderCell />
      <PlantNameHeaderCell />
      <SpacingsHeaderCell />
      <CountsHeaderCell />
      <CalendarHeaderCell />
    </TableRow>
  );
};

export default SummaryTableHead;
