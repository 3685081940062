import { Graphics, Text, TextMetrics, TextStyle } from 'pixi.js-new';

import {
  AssetComponent,
  AssetComponentEvents,
  AssetType,
  DoubleClickableComponent,
  DraggableComponent,
  HiddenFlag,
  HoverableComponent,
  InspectableClassData,
  InteractableComponent,
  LongPressableComponent,
  ManipulatableComponent,
  NodeEvent,
  OutlineComponent,
  RightClickableComponent,
  ScalingSpriteComponent,
  SelectableComponent,
  ShapeComponent,
  ShapeFlag,
  TooltipComponent,
  VisibilityComponent,
  bindToLifecycle,
} from '@gi/core-renderer';
import Plant from '@gi/plant';
import plantFamilies from '@gi/plant-families';
import Bitmask from '@gi/bitmask';
import { StateDef } from '@gi/state';
import { LayerTypes } from '@gi/constants';

import { drawSquareFootRoots } from './draw-utils';
import { createRectOutline } from '../utils';
import SettingsContext, { SettingsContextState } from '../settings-context';
import { getSquareFootPlantText } from './tooltip-utils';
import GardenItemNode, { GardenItemNodeState } from '../garden-item-node';

const ROOT_OPACITY = 0.5;

// 1 foot = 30.48cm
const SQUARE_FOOT_SIZE = 30.48;
const HALF_SQUARE_FOOT_SIZE = SQUARE_FOOT_SIZE / 2;

const SQUARE_FOOT_FONT_FAMILY = ['Verdana', 'Helvetica', 'Arial'];
const SQUARE_FOOT_FONT_SIZE = 14;
const SQUARE_FOOT_FONT_SCALE = 4;
const SQUARE_FOOT_LINE_HEIGHT = SQUARE_FOOT_FONT_SIZE;
const SQUARE_FOOT_FONT_COLOR = 0x000000;

const PLANT_COUNT_FONT_STYLE = new TextStyle({
  fontFamily: SQUARE_FOOT_FONT_FAMILY,
  fontWeight: '600',
  wordWrap: false,
  fontSize: SQUARE_FOOT_FONT_SIZE * SQUARE_FOOT_FONT_SCALE,
  fill: SQUARE_FOOT_FONT_COLOR,
  lineHeight: SQUARE_FOOT_LINE_HEIGHT,
});

/**
 * Scales dimensions so that they fit within a square of size `max`
 * @param width The width to scale
 * @param height The height to scale
 * @param max The maximum value for width/height
 * @returns Scaled dimensions
 */
const getScaledDimensions = (width: number, height: number, max: number): Dimensions => {
  const scale = Math.max(width, height) / max;
  return {
    width: width / scale,
    height: height / scale,
  };
};

type SFGPlantNodeState = StateDef<
  {
    position: Vector2;
    rotation: number;
    plantCount: number;
    variety: string;
    visible: boolean;
  } & GardenItemNodeState,
  [],
  {
    settings: SettingsContextState;
  }
>;

class SFGPlantNode extends GardenItemNode<SFGPlantNodeState> {
  type = 'SFGPlantNode';

  readonly plant: Plant;
  readonly sfg = true as const;

  #sprite: ScalingSpriteComponent;
  #tooltip: TooltipComponent;
  #outline: OutlineComponent;
  #shape: ShapeComponent;
  visibility: VisibilityComponent;

  #rootBackground: Graphics | null = null;
  #plantCountBackground: Graphics | null = null;
  #plantCountText: Text | null = null;

  constructor(id: number, plant: Plant, initialState: SFGPlantNodeState['state']) {
    super(id, initialState, LayerTypes.PLANTS);

    this.name = `${id} - ${plant.name} - ${plant.code}`;
    this.plant = plant;

    this.transform.state.values.position = this.state.values.position;
    this.state.addUpdater(
      (state) => {
        this.transform.state.values.position = state.values.position;
        this.transform.state.values.rotation = state.values.rotation;
      },
      { properties: ['position', 'rotation'] }
    );

    this.#sprite = this.components.add(new ScalingSpriteComponent());
    const dimensions = getScaledDimensions(this.plant.sprite.width, this.plant.sprite.height, SQUARE_FOOT_SIZE);
    this.#sprite.state.values.width = dimensions.width * 0.8;
    this.#sprite.state.values.height = dimensions.height * 0.8;

    const textureComponent = this.components.add(new AssetComponent(AssetType.TEXTURE, plant.sprite.name));
    textureComponent.eventBus.on(AssetComponentEvents.Loaded, (texture) => {
      this.#sprite.texture = texture;
    });

    this.components.add(new InteractableComponent());
    this.components.add(new HoverableComponent());
    this.components.add(new SelectableComponent());
    this.components.add(new ManipulatableComponent());
    this.components.add(new DraggableComponent());
    this.components.add(new DoubleClickableComponent());
    this.components.add(new RightClickableComponent());
    this.components.add(new LongPressableComponent());
    this.visibility = this.components.add(new VisibilityComponent());
    this.#tooltip = this.components.add(new TooltipComponent({ text: plant.name }));
    this.#shape = this.components.add(new ShapeComponent({ flags: Bitmask.Create(ShapeFlag.CULLABLE, ShapeFlag.PLANT) }));
    this.#outline = this.components.add(new OutlineComponent({ padding: OutlineComponent.PADDING_SMALL }));

    this.state.addWatcher(
      () => {
        this.#updateRoots();
      },
      { otherStates: { settings: { properties: ['showPlantRoots'] } } }
    );

    this.state.addWatcher(
      (state) => {
        this.visibility.setHiddenFlag(HiddenFlag.VISIBILITY, !state.values.visible);
      },
      { properties: ['visible'] }
    );

    this.state.addWatcher(
      () => {
        this.#updatePlantCount();
        this.#updateTooltip();
      },
      { properties: ['plantCount', 'variety'] }
    );

    this.#connectSettingsState();

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    this.#rootBackground = new Graphics();
    this.ownGraphics.addChild(this.#rootBackground);

    this.ownGraphics.addChild(this.#sprite.getContainer());

    this.#plantCountBackground = new Graphics();
    this.#plantCountBackground.position = { x: -HALF_SQUARE_FOOT_SIZE, y: -HALF_SQUARE_FOOT_SIZE };
    this.ownGraphics.addChild(this.#plantCountBackground);

    this.#plantCountText = new Text(this.state.values.plantCount, PLANT_COUNT_FONT_STYLE);
    this.#plantCountText.scale.set(1 / SQUARE_FOOT_FONT_SCALE);
    this.#plantCountBackground.addChild(this.#plantCountText);

    this.#updateRoots();
    this.#updatePlantCount();
    this.#updateShape();
  };

  #onBeforeUnbind = () => {
    this.#rootBackground?.destroy();
    this.#plantCountText?.destroy();
    this.#plantCountBackground?.destroy();
    this.#rootBackground = null;
    this.#plantCountBackground = null;
    this.#plantCountText = null;
  };

  #connectSettingsState = () => {
    bindToLifecycle(this, () => {
      const settings = this.getContext(SettingsContext);
      this.state.connectState('settings', settings.state);
    });
  };

  /**
   * Sets the position fof this plant
   * @param position The center position of this plant
   * @param rotation The rotation of this plant(unused currently)
   */
  setPosition(position: Vector2, rotation: number = 0) {
    this.state.values.position = position;
    this.state.values.rotation = rotation;
  }

  /**
   * Sets the amount of plants in the square foot
   * @param plantCount
   */
  setPlantCount(plantCount: number) {
    this.state.values.plantCount = plantCount;
  }

  /**
   * Sets the variety of the plant
   * @param variety The variety of the plant
   */
  setVariety(variety: string) {
    this.state.values.variety = variety;
  }

  /**
   * Draws the roots to the roots background graphic
   */
  #updateRoots() {
    if (!this.#rootBackground) {
      return;
    }

    const drawRoots = this.state.get('settings', 'showPlantRoots', true);

    if (drawRoots) {
      this.#rootBackground.visible = true;
      drawSquareFootRoots({
        graphics: this.#rootBackground,
        width: SQUARE_FOOT_SIZE,
        height: SQUARE_FOOT_SIZE,
        colour: plantFamilies.get(this.plant.familyID)!.secondaryColor,
        alpha: ROOT_OPACITY,
      });
    } else {
      this.#rootBackground.visible = false;
      this.#rootBackground.clear();
    }
  }

  /**
   * Draws the total amount of plants to the plant count graphics object.
   */
  #updatePlantCount() {
    const text = `${this.state.values.plantCount}`;

    if (this.#plantCountText) {
      this.#plantCountText.text = text;
    }

    if (this.#plantCountBackground) {
      const { width, height } = TextMetrics.measureText(text, PLANT_COUNT_FONT_STYLE);

      this.#plantCountBackground
        .clear()
        .beginFill(0xffffff, 0.75)
        .drawRect(0, 0, width / SQUARE_FOOT_FONT_SCALE, height / SQUARE_FOOT_FONT_SCALE)
        .endFill();
    }
  }

  /**
   * Updates the interanl shape of the plant
   */
  #updateShape() {
    this.#shape.setPoints(createRectOutline(SQUARE_FOOT_SIZE, SQUARE_FOOT_SIZE));
  }

  /**
   * Updates the tooltip text for this plant
   */
  #updateTooltip() {
    this.#tooltip.state.values.text = getSquareFootPlantText(this.plant.name, this.state.values.variety, this.state.values.plantCount);
  }

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default SFGPlantNode;
