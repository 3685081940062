import React from 'react';

import './styles.scss';

/**
 * Loading message for a Google Map
 */
const GoogleMapLoading = (): JSX.Element => {
  return (
    <div className='google-map-placeholder'>
      <i className='google-map-placeholder-icon icon-spinner animate-pulse' />
      <p className='google-map-placeholder-title'>Loading Map...</p>
    </div>
  );
};

export default GoogleMapLoading;
