import React from 'react';
import MenuBar from '../common/menu-bar';
import MenuBarGroup from '../common/menu-bar-group';

import ShoppingControls from './shopping-controls';

const DesktopShoppingMenu = (): JSX.Element => {
  return (
    <MenuBar>
      <MenuBarGroup title='Plan'>
        <ShoppingControls />
      </MenuBarGroup>
    </MenuBar>
  );
};

export default DesktopShoppingMenu;
