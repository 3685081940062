import React, { useContext } from 'react';

import { PlannerControlsTab } from '@gi/constants';
import { PlannerControlsContext } from '@gi/planner-controls';

import OverlayToolbarButton from '../../overlay-toolbar-button';

const ObjectivesDrawerToolbarButton = (): JSX.Element => {
  const { setActiveDrawer } = useContext(PlannerControlsContext);

  return (
    <OverlayToolbarButton onClick={() => setActiveDrawer(PlannerControlsTab.OBJECTIVES)}>
      <i className='icon-mortar-board' />
    </OverlayToolbarButton>
  );
};

export default ObjectivesDrawerToolbarButton;
