import React from 'react';
import { DEFAULT_USER_SEASON_EXTENDER_SETTINGS } from '@gi/user';
import { SeasonExtenderValues } from '@gi/common-types';

import { TextFormRow } from '@gi/form';
import { FormLayout, FormSection } from '@gi/form-responsive';

import SeasonExtenderSettingsRow from './season-extender-settings-row';

import './season-extender-settings.scss';

const createLastSeasonExtenderUpdater =
  (seasonExtenders: SeasonExtenderValues, extenderProperty: keyof SeasonExtenderValues, setSeasonExtenders: (value: SeasonExtenderValues) => void) =>
  (value: number) => {
    setSeasonExtenders({
      ...seasonExtenders,
      [extenderProperty]: -value,
    });
  };

const createFirstSeasonExtenderUpdater =
  (seasonExtenders: SeasonExtenderValues, extenderProperty: keyof SeasonExtenderValues, setSeasonExtenders: (value: SeasonExtenderValues) => void) =>
  (value: number) => {
    setSeasonExtenders({
      ...seasonExtenders,
      [extenderProperty]: value,
    });
  };

interface iProps {
  seasonExtenders: SeasonExtenderValues;
  setSeasonExtenders: (values: SeasonExtenderValues) => void;
}

const SeasonExtenderSettings = ({ seasonExtenders, setSeasonExtenders }: iProps): JSX.Element => {
  return (
    <FormSection padding={12} gap={6} heading='Season Extenders' className='form-section-background'>
      <TextFormRow>
        Season extenders affect the sowing, planting and harvesting times of plants on your plan by being placed over plants. They can be added to a plan from
        the garden objects tab in the drawing tools pane.
      </TextFormRow>
      <SeasonExtenderSettingsRow
        title='Cold Frame'
        lastExtenderValue={-seasonExtenders.coldFrameLastFrostAdjust}
        onLastExtenderValueChange={createLastSeasonExtenderUpdater(seasonExtenders, 'coldFrameLastFrostAdjust', setSeasonExtenders)}
        firstExtenderValue={seasonExtenders.coldFrameFirstFrostAdjust}
        onFirstExtenderValueChange={createFirstSeasonExtenderUpdater(seasonExtenders, 'coldFrameFirstFrostAdjust', setSeasonExtenders)}
      />
      <SeasonExtenderSettingsRow
        title='Cloche'
        lastExtenderValue={-seasonExtenders.clocheLastFrostAdjust}
        onLastExtenderValueChange={createLastSeasonExtenderUpdater(seasonExtenders, 'clocheLastFrostAdjust', setSeasonExtenders)}
        firstExtenderValue={seasonExtenders.clocheFirstFrostAdjust}
        onFirstExtenderValueChange={createFirstSeasonExtenderUpdater(seasonExtenders, 'clocheFirstFrostAdjust', setSeasonExtenders)}
      />
      <SeasonExtenderSettingsRow
        title='Row Cover/Row Cloche'
        lastExtenderValue={-seasonExtenders.rowCoverLastFrostAdjust}
        onLastExtenderValueChange={createLastSeasonExtenderUpdater(seasonExtenders, 'rowCoverLastFrostAdjust', setSeasonExtenders)}
        firstExtenderValue={seasonExtenders.rowCoverFirstFrostAdjust}
        onFirstExtenderValueChange={createFirstSeasonExtenderUpdater(seasonExtenders, 'rowCoverFirstFrostAdjust', setSeasonExtenders)}
      />
      <SeasonExtenderSettingsRow
        title='Greenhouse'
        lastExtenderValue={-seasonExtenders.greenhouseLastFrostAdjust}
        onLastExtenderValueChange={createLastSeasonExtenderUpdater(seasonExtenders, 'greenhouseLastFrostAdjust', setSeasonExtenders)}
        firstExtenderValue={seasonExtenders.greenhouseFirstFrostAdjust}
        onFirstExtenderValueChange={createFirstSeasonExtenderUpdater(seasonExtenders, 'greenhouseFirstFrostAdjust', setSeasonExtenders)}
      />
      <SeasonExtenderSettingsRow
        title='Heated Greenhouse'
        lastExtenderValue={-seasonExtenders.heatedGreenhouseLastFrostAdjust}
        onLastExtenderValueChange={createLastSeasonExtenderUpdater(seasonExtenders, 'heatedGreenhouseLastFrostAdjust', setSeasonExtenders)}
        firstExtenderValue={seasonExtenders.heatedGreenhouseFirstFrostAdjust}
        onFirstExtenderValueChange={createFirstSeasonExtenderUpdater(seasonExtenders, 'heatedGreenhouseFirstFrostAdjust', setSeasonExtenders)}
      />
      <SeasonExtenderSettingsRow
        title='Polytunnel/Hoop House'
        lastExtenderValue={-seasonExtenders.polytunnelLastFrostAdjust}
        onLastExtenderValueChange={createLastSeasonExtenderUpdater(seasonExtenders, 'polytunnelLastFrostAdjust', setSeasonExtenders)}
        firstExtenderValue={seasonExtenders.polytunnelFirstFrostAdjust}
        onFirstExtenderValueChange={createFirstSeasonExtenderUpdater(seasonExtenders, 'polytunnelFirstFrostAdjust', setSeasonExtenders)}
      />
      <SeasonExtenderSettingsRow
        title='Heated Polytunnel/Hoop House'
        lastExtenderValue={-seasonExtenders.heatedPolytunnelLastFrostAdjust}
        onLastExtenderValueChange={createLastSeasonExtenderUpdater(seasonExtenders, 'heatedPolytunnelLastFrostAdjust', setSeasonExtenders)}
        firstExtenderValue={seasonExtenders.heatedPolytunnelFirstFrostAdjust}
        onFirstExtenderValueChange={createFirstSeasonExtenderUpdater(seasonExtenders, 'heatedPolytunnelFirstFrostAdjust', setSeasonExtenders)}
      />
      <FormLayout desktopLayout={{ xAlign: 'right' }} mobileLayout={{ xAlign: 'stretch' }}>
        <button
          type='button'
          className='button button-secondary'
          onClick={() => {
            setSeasonExtenders(DEFAULT_USER_SEASON_EXTENDER_SETTINGS);
          }}
        >
          Reset Season Extenders to Defaults
        </button>
      </FormLayout>
    </FormSection>
  );
};

export default SeasonExtenderSettings;
