/**
 * Enum for the action types for the actions created to represent the events emitted from the garden canvas
 *
 * @readonly
 * @enum {string}
 */
const GardenCanvasEventActionTypes = {
  UPDATE_PLAN: 'UPDATE_PLAN_CANVAS_EVENT',
  UPDATE_INTERACTION_STATE: 'UPDATE_INTERACTION_STATE',
  UPDATE_SELECTED_NODES: 'UPDATE_SELECTED_NODES',
  UPDATE_CLIPBOARD_STATE: 'UPDATE_CLIPBOARD_STATE',
} as const;

export default GardenCanvasEventActionTypes;
