import React, { useContext } from 'react';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

const NotesHeaderCell = () => {
  const { columns } = useContext(GlobalPlantListContext);

  if (!columns.notes) {
    return null;
  }

  return (
    <th className='header-cell left-align-cell'>
      <div className='header-cell-content'>
        <div className='header-row'>Plant Notes</div>
      </div>
    </th>
  );
};

export default NotesHeaderCell;
