import React, { SVGAttributes } from 'react';

interface iProps {
  style?: React.CSSProperties;
  fill: SVGAttributes<SVGPolygonElement>['fill'];
  width: number;
  height: number;
}

const Arrow = ({ style, fill, width, height }: iProps): JSX.Element => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} style={{ ...style, display: 'block' }}>
      <polygon fill={fill} strokeWidth='0' points={`0,${height} ${width},${height} ${width / 2},0`} />
    </svg>
  );
};

export default Arrow;
