import getNewsErrorCodes from './get-news-error-codes';

const attachGetNewsErrorClientMessage = (responseError) => {
  if (getNewsErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = getNewsErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown load user error - ${responseError.statusCode}`;
  }
};

export { attachGetNewsErrorClientMessage };
