import { ColorSource, Container, Graphics, Matrix, Texture } from 'pixi.js-new';

import {
  NodeEvent,
  ScalingSpriteComponent,
  StatefulNode,
  AssetNameCollisionMode,
  AssetType,
  ShapeComponent,
  OutlineComponent,
  ManipulatableComponent,
  DraggableComponent,
  DoubleClickableComponent,
  RightClickableComponent,
  LongPressableComponent,
  InteractableComponent,
  HoverableComponent,
  SelectableComponent,
  DisplayModeComponent,
  DisplayModeFlag,
  CameraNodeState,
  createActiveCameraConnector,
} from '@gi/core-renderer';
import Bitmask from '@gi/bitmask';
import { StateDef } from '@gi/state';
import { LoadingState } from '@gi/constants';
import { AsyncOperation, getOverallLoadingState } from '@gi/utils';

import { CachedRectOutline } from '../outline-utils';
import { drawCorner, drawEdge } from './utils';
import PlanSettingsContext, { PlanSettingsContextState } from '../plan-settings-context';

/** Colour of the mask. Should match the plan background colour. This currently isn't defined globally */
const MASK_COLOUR: ColorSource = 0xdddddd;
/** Transparency of the mask while the background is being edited. */
const MASK_EDITING_TRANSPARENCY: number = 0.8;

export interface BackgroundImageNodeState {
  position: Vector2;
  dimensions: Dimensions;
  rotation: number;
  imageSrc: AsyncOperation<string>;
}

type BackgroundImageNodeInternalState = StateDef<
  BackgroundImageNodeState & {
    loading: LoadingState;
  },
  [],
  {
    planSettings: PlanSettingsContextState;
    camera: CameraNodeState;
  }
>;

class BackgroundImageNode extends StatefulNode<BackgroundImageNodeInternalState> {
  type = 'BackgroundImageShapeNode';

  readonly shape: ShapeComponent;
  readonly outline: OutlineComponent;
  readonly selectable: SelectableComponent;

  #scalingSprite: ScalingSpriteComponent;
  #contentContainer: Container | null = null;
  #mask: Graphics | null = null;
  #placeholderGraphics: Graphics | null = null;
  #cachedOutline?: CachedRectOutline;

  constructor(initialState: BackgroundImageNodeState) {
    super({
      ...initialState,
      loading: LoadingState.NONE,
    });

    // Add required components
    this.components.add(new InteractableComponent());
    this.components.add(new HoverableComponent());
    this.selectable = this.components.add(new SelectableComponent());
    this.components.add(new ManipulatableComponent());
    this.components.add(new DraggableComponent());
    this.components.add(new DoubleClickableComponent());
    this.components.add(new RightClickableComponent());
    this.components.add(new LongPressableComponent());
    this.components.add(new DisplayModeComponent({ flags: Bitmask.Create(DisplayModeFlag.LAYER_BACKGROUND) }));
    this.shape = this.components.add(new ShapeComponent({ flags: Bitmask.Create() }));
    this.outline = this.components.add(new OutlineComponent({ padding: 0 }));

    this.#scalingSprite = this.components.add(new ScalingSpriteComponent());

    this.transform.state.values.position = this.state.values.position;
    this.transform.state.values.rotation = this.state.values.rotation;

    // Update the transform position based on our input position
    this.state.addUpdater(
      (state) => {
        this.transform.state.values.position = state.values.position;
        this.transform.state.values.rotation = state.values.rotation;
      },
      { properties: ['position', 'rotation'] }
    );

    // Re-render whenever something relevant updates
    this.state.addWatcher(
      () => {
        this.#update();
      },
      {
        properties: ['position', 'dimensions', 'rotation', 'imageSrc', 'loading'],
        otherStates: {
          planSettings: {
            properties: ['backgroundImageOpacity', 'showBackgroundImages'],
          },
        },
      },
      false
    );

    // Keep the mask world-aligned to 0,0 by applying the inverse of the transform
    this.transform.state.addWatcher(
      (state) => {
        if (this.#mask) {
          const { position, rotation, scale } = state.values;
          const scaleVector = typeof scale === 'number' ? { x: scale, y: scale } : scale;

          // Temp matrix is a liar and effectively just a proxy for (new Matrix())...
          const matrix = Matrix.TEMP_MATRIX.translate(-position.x, -position.y)
            .rotate(-rotation)
            .scale(1 / scaleVector.x, 1 / scaleVector.y);

          this.#mask.transform.setFromMatrix(matrix);
        }
      },
      { properties: ['position', 'rotation'] }
    );

    // Update the mask overlay whenever something related to the camera or plan size changes
    this.state.addWatcher(
      () => {
        this.#updateMask();
      },
      {
        otherStates: {
          planSettings: { properties: ['planDimensions'] },
          camera: { properties: ['position', 'magnification', 'viewportSize'] },
        },
      }
    );

    // Reload the texture whenever the image source changes
    this.state.addWatcher(
      () => {
        this.#loadTexture();
      },
      { properties: ['imageSrc'] }
    );

    // Make the mask translucent whenever the background image can be edited
    this.selectable.state.addWatcher(
      () => {
        if (!this.#mask) {
          return;
        }
        if (this.selectable.isSelectable) {
          this.#mask.alpha = MASK_EDITING_TRANSPARENCY;
        } else {
          this.#mask.alpha = 1;
        }
      },
      { properties: ['unselectableFlags'] }
    );

    createActiveCameraConnector(this, this.state, 'camera');

    this.eventBus.on(NodeEvent.DidBind, this.#didBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#beforeUnbind);
  }

  #didBind = () => {
    this.#contentContainer = new Container();
    this.#contentContainer.addChild(this.#scalingSprite.getContainer());
    this.ownGraphics.addChild(this.#contentContainer);

    this.#mask = new Graphics();
    this.#mask.eventMode = 'none';
    this.ownGraphics.addChild(this.#mask);

    const planSettings = this.getContext(PlanSettingsContext);
    this.state.connectState('planSettings', planSettings.state);

    this.#loadTexture();
    this.#updateMask();
    this.#update();
  };

  #beforeUnbind = () => {
    if (this.#contentContainer) {
      this.#contentContainer.removeChild(this.#scalingSprite.getContainer());
      this.#contentContainer.destroy();
      this.#contentContainer = null;
    }
    if (this.#mask) {
      this.#mask.destroy();
      this.#mask = null;
    }
    this.#tearDownGraphics();

    this.state.disconnectState('planSettings');
  };

  /** Sets the URL of the image to use for the background. Can be set to loading if the URL isn't ready yet. */
  setImageScr(imageSrc: AsyncOperation<string>) {
    this.state.values.imageSrc = imageSrc;
  }

  /** Sets the center position of the background image */
  setPosition(position: Vector2) {
    this.state.values.position = position;
  }

  /** Sets the width and height of the background image */
  setDimensions(dimensions: Dimensions) {
    this.state.values.dimensions = dimensions;
  }

  /** Sets the rotation of the background image */
  setRotation(rotation: number) {
    this.state.values.rotation = rotation;
  }

  /** Sets up the graphics object for the placeholder/error graphics */
  #setUpGraphics() {
    if (!this.#placeholderGraphics) {
      this.#placeholderGraphics = new Graphics();
      if (this.#contentContainer) {
        this.#contentContainer.addChild(this.#placeholderGraphics);
      }
    }
    return this.#placeholderGraphics;
  }

  /** Destroys the placeholder/error graphics */
  #tearDownGraphics() {
    if (this.#placeholderGraphics) {
      this.#placeholderGraphics.destroy();
      this.#placeholderGraphics = null;
    }
  }

  /** Attempts to load the texture and apply it to the scaling sprite */
  #loadTexture() {
    this.state.values.loading = LoadingState.NONE;
    if (!this.engine) {
      return;
    }

    const { imageSrc } = this.state.values;
    if (imageSrc.status === LoadingState.SUCCESS) {
      this.state.values.loading = LoadingState.LOADING;
      this.engine.assetManager
        .loadAsset(AssetType.TEXTURE, imageSrc.value, imageSrc.value, AssetNameCollisionMode.SKIP)
        .then((texture) => {
          if (this.state.values.imageSrc === imageSrc) {
            // Make sure image src hasn't changed since promise started
            this.state.values.loading = LoadingState.SUCCESS;
            this.#scalingSprite.texture = texture as Texture;
          }
        })
        .catch(() => {
          if (this.state.values.imageSrc === imageSrc) {
            // Make sure image src hasn't changed since promise started
            this.state.values.loading = LoadingState.ERROR;
          }
        });
    }
  }

  /** Displays a placeholder/error graphic for when the texture hasn't loaded */
  #displayPlaceholder() {
    const graphics = this.#setUpGraphics();

    const { loading, dimensions, imageSrc } = this.state.values;
    const { width, height } = dimensions;

    const status = getOverallLoadingState([loading, imageSrc.status]);

    graphics
      .clear()
      .beginFill(0xeeeeee, 1)
      .drawRect(-width / 2, -height / 2, width, height)
      .endFill()
      .lineStyle(4, status === LoadingState.ERROR ? 0xee9999 : 0x999999, 1);
    drawCorner(graphics, width, height, 1, 1);
    drawCorner(graphics, width, height, 1, -1);
    drawCorner(graphics, width, height, -1, 1);
    drawCorner(graphics, width, height, -1, -1);
    drawEdge(graphics, width, height, -1, false);
    drawEdge(graphics, width, height, 1, false);
    drawEdge(graphics, width, height, -1, true);
    drawEdge(graphics, width, height, 1, true);
  }

  /** Updates the mask to cover up the image/placeholder, making it look masked to the plan */
  #updateMask() {
    if (!this.#mask) {
      return;
    }
    const planDimensions = this.state.get('planSettings', 'planDimensions');
    const camera = this.state.otherStates.camera?.owner;

    this.#mask.clear();

    if (!planDimensions || !camera) {
      return;
    }

    const { viewportSize } = camera.state.values;

    const topLeft = camera.getWorldPos({ x: 0, y: 0 });
    const bottomRight = camera.getWorldPos({ x: viewportSize.width, y: viewportSize.height });

    const left = Math.min(topLeft.x, 0);
    const top = Math.min(topLeft.y, 0);
    const bottom = Math.max(bottomRight.y, planDimensions.height);
    const right = Math.max(bottomRight.x, planDimensions.width);

    this.#mask
      .beginFill(MASK_COLOUR)
      .drawRect(left, top, right - left, -top)
      .drawRect(left, planDimensions.height, right - left, bottom - planDimensions.height)
      .drawRect(left, 0, -left, planDimensions.height)
      .drawRect(planDimensions.width, 0, right - planDimensions.width, planDimensions.height);
  }

  /** Updates the textures sprite that  */
  #updateSprite() {
    const { loading, dimensions, imageSrc } = this.state.values;
    const alpha = this.state.get('planSettings', 'backgroundImageOpacity');
    const show = this.state.get('planSettings', 'showBackgroundImages', true);

    if (!show) {
      this.ownGraphics.visible = false;
      return;
    }

    this.ownGraphics.visible = true;

    if (this.#contentContainer && alpha !== undefined) {
      this.#contentContainer.alpha = alpha;
    }

    if (loading !== LoadingState.SUCCESS || imageSrc.status !== LoadingState.SUCCESS) {
      this.#displayPlaceholder();
      this.#scalingSprite.getContainer().visible = false;
    } else {
      this.#tearDownGraphics();
      this.#scalingSprite.getContainer().visible = true;
      this.#scalingSprite.state.values.width = dimensions.width;
      this.#scalingSprite.state.values.height = dimensions.height;
    }
  }

  /** Updates the shape so the outline is correct */
  #updateShape() {
    const { width, height } = this.state.values.dimensions;

    if (!this.#cachedOutline) {
      this.#cachedOutline = new CachedRectOutline(width, height);
      this.shape.setPoints(this.#cachedOutline.path);
    } else if (this.#cachedOutline.update(width, height)) {
      this.shape.setPoints(this.#cachedOutline.path);
    }
  }

  /** Updates all graphics (except the mask) */
  #update() {
    this.#updateSprite();
    this.#updateShape();
  }
}

export default BackgroundImageNode;
