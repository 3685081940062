import { State, StateDef } from '@gi/state';
import NodeComponent from '../../node-component/node-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { bindState } from '../../utils/state-utils';

export type ManipulatableComponentState = StateDef<{
  manipulating: boolean;
  hoveringHandles: boolean;
}>;

const DEFAULT_STATE: ManipulatableComponentState['state'] = {
  manipulating: false,
  hoveringHandles: false,
};

/**
 * Manipulatable Component
 *  Similar to TransformableComponent in the old renderer. Renamed to avoid confusion with the TransformComponent.
 *  Tracks metadata such as if the node is being manipulated or if a handle is being hovered.
 */
class ManipulatableComponent extends NodeComponent {
  type = 'ManipulatableComponent';

  readonly state: State<ManipulatableComponentState>;

  constructor() {
    super();

    this.state = new State(DEFAULT_STATE);
    bindState(this.state, this);
  }

  /**
   * Sets whether the node is currently being manipulated (e.g. by a handle)
   * @param isManipulating Is the node being manipulated
   */
  setIsManipulating(isManipulating: boolean) {
    this.state.values.manipulating = isManipulating;
  }

  /**
   * Sets if a handle belonging to this node is being hovered
   * @param isHovering Is a handle being hovered
   */
  setIsHoveringHandle(isHovering: boolean) {
    this.state.values.hoveringHandles = isHovering;
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default ManipulatableComponent;
