import React from 'react';
import { useDispatch } from 'react-redux';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';
import intercomIcon from '@gi/assets/common/intercom-icon.svg';

import './intercom-button.scss';

const IntercomButton = () => {
  const dispatch = useDispatch();

  return (
    <button
      type='button'
      id='intercom-button'
      onClick={() => {
        dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.OpenIntercomWindow, { opener: 'desktop-statusbar' }));
      }}
    >
      <span className='intercom-text'>Live Chat</span>
      <img src={intercomIcon} alt='Live Chat' className='intercom-icon' />
    </button>
  );
};

export default IntercomButton;
