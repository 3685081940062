/**
 * Enum for the action types for the modal action creators
 *
 * @readonly
 * @enum {string}
 */
const ModalActionTypes = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  OPEN_PLANT_INFO_MODAL: 'OPEN_PLANT_INFO_MODAL',
  CLOSE_PLANT_INFO_MODAL: 'CLOSE_PLANT_INFO_MODAL',
  OPEN_GARDEN_OBJECT_INFO_MODAL: 'OPEN_GARDEN_OBJECT_INFO_MODAL',
  CLOSE_GARDEN_OBJECT_INFO_MODAL: 'CLOSE_GARDEN_OBJECT_INFO_MODAL',
  OPEN_NEW_PLAN_MODAL: 'OPEN_NEW_PLAN_MODAL',
  CLOSE_NEW_PLAN_MODAL: 'CLOSE_NEW_PLAN_MODAL',
};

export default ModalActionTypes;
