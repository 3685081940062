import React, { useMemo, useState } from 'react';
import { HelpButton } from '@gi/base-components';
import Plant from '@gi/plant';

interface iSectionProps {
  plant: Plant;
  property: string;
  title: string;
  helpModal?: ({ closeModal }: { closeModal: () => void }) => JSX.Element;
}

const PlantInformationSection = ({ plant, property, title, helpModal }: iSectionProps): JSX.Element | null => {
  const [showHelpModal, setShowHelpModal] = useState(false);

  if (plant.text[property] === '' || plant.text[property] === '-' || plant.text[property] === null) {
    return null;
  }

  const helpIcon = useMemo(() => {
    if (helpModal) {
      return <HelpButton onClick={() => setShowHelpModal(true)} />;
    }
    return null;
  }, [helpModal]);

  const modal = useMemo(() => {
    if (helpModal && showHelpModal) {
      const HelpModalComponent = helpModal;
      return <HelpModalComponent closeModal={() => setShowHelpModal(false)} />;
    }
    return null;
  }, [helpModal, showHelpModal]);

  return (
    <tr className='plan-table-row'>
      {modal}
      <th className='plan-table-row-title'>
        {helpIcon}
        {title}
      </th>
      <td>{plant.text[property]}</td>
    </tr>
  );
};

export default PlantInformationSection;
