import React from 'react';
import { useSelector } from 'react-redux';

import { Drawer, DrawerContent, DrawerHeader } from '@gi/mobile-components';
import PlanBackgroundImageEditor from '@gi/plan-background-image';
import { CanvasSelectors } from '@gi/react-garden-canvas';

import styles from './objectives-drawer.module.css';

interface iProps {
  open: boolean;
  onClose: () => void;
}

const PlanBackgroundImageDrawer = ({ open, onClose }: iProps): JSX.Element => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  return (
    <Drawer open={open} onClose={onClose} width={500} anchor='RIGHT'>
      <DrawerHeader as='h2' onClose={onClose}>
        Plan Background Image
      </DrawerHeader>
      <DrawerContent overflowHidden className={styles.content}>
        <PlanBackgroundImageEditor plan={activePlan} onViewPlan={onClose} />
      </DrawerContent>
    </Drawer>
  );
};

export default PlanBackgroundImageDrawer;
