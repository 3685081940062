import { Geometry, Matrix } from '@gi/math';
import { ShapeType } from '@gi/constants';
import { SimulatedShape } from '../simulated-shape';
import { EditSimulatedItem } from './edit-simulated-item';
import { ShapeSnapUtils } from '../snap-utils';

class EditSimulatedShape extends EditSimulatedItem<SimulatedShape> {
  point1: Vector2;
  point2: Vector2 | null;
  point3: Vector2;
  rotation: number;
  centerPos: Vector2;

  start(): void {
    this.point1 = { ...this.target.point1 };
    this.point2 = this.target.point2 !== null ? { ...this.target.point2 } : null;
    this.point3 = { ...this.target.point3 };
    this.rotation = this.target.rotation;
    this.centerPos = { ...this.target.center };
  }

  translate(positionOffset: Vector2): void {
    this.target.setPosition(
      Geometry.addPoint(this.point1, positionOffset),
      this.point2 !== null ? Geometry.addPoint(this.point2, positionOffset) : null,
      Geometry.addPoint(this.point3, positionOffset),
      this.rotation
    );
  }

  rotate(angle: number, pivot: Vector2, matrix: number[][]): void {
    if (this.target.shapeType === ShapeType.LINE || this.target.shapeType === ShapeType.TRIANGLE) {
      // Lines and triangles don't support rotation, so we need to bake it into the 3 points.
      const point1 = Matrix.multiplyVector(this.point1, matrix);
      const point2 = this.point2 !== null ? Matrix.multiplyVector(this.point2, matrix) : null;
      const point3 = Matrix.multiplyVector(this.point3, matrix);

      this.target.setPosition(point1, point2, point3, 0);
    } else {
      const center = Matrix.multiplyVector(this.centerPos, matrix);
      const point1 = Geometry.addPoint(Geometry.getPointDelta(this.centerPos, this.point1), center);
      const point2 = this.point2 !== null ? Geometry.addPoint(Geometry.getPointDelta(this.centerPos, this.point2), center) : null;
      const point3 = Geometry.addPoint(Geometry.getPointDelta(this.centerPos, this.point3), center);

      this.target.setPosition(point1, point2, point3, this.rotation + angle);
    }
  }

  manipulate(point1: Vector2, point2: Vector2 | null, point3: Vector2, rotation: number = 0) {
    this.target.setPosition(point1, point2, point3, rotation);
  }

  transform(center: Vector2, dimensions: Dimensions, rotation: number): void {
    const point1: Vector2 = {
      x: center.x - dimensions.width / 2,
      y: center.y - dimensions.height / 2,
    };
    const point3: Vector2 = {
      x: center.x + dimensions.width / 2,
      y: center.y + dimensions.height / 2,
    };
    this.target.setPosition(point1, null, point3, rotation);
  }

  snapTranslate(snapDistance: number): void {
    const { point1, point2, point3 } = ShapeSnapUtils.snapTranslate(this.target.point1, this.target.point2, this.target.point3, snapDistance);

    this.target.setPosition(point1, point2, point3, this.target.rotation);
  }

  snapTransform(snapDistance: number): void {
    const { point1, point2, point3 } = ShapeSnapUtils.snapTransform(this.target.point1, this.target.point2, this.target.point3, snapDistance);

    this.target.setPosition(point1, point2, point3, this.target.rotation);
  }

  // eslint-disable-next-line class-methods-use-this
  end(): void {
    // Do nothing
  }
}

export default EditSimulatedShape;
