import React from 'react';
import { GuruDirectoryPageId } from '@gi/app-guru-types';
import { directoryPageRoutes } from '@gi/app-guru-common';

import GuruLogo from './guru-logo';
import NavigationBarTab from './navigation-bar-tab';
import NavigationBarDropdown from './navigation-bar-dropdown';

import styles from './navigation-bar.module.css';

interface iTabProps {
  pageId: GuruDirectoryPageId;
}

const Tab = ({ pageId }: iTabProps): JSX.Element => {
  return <NavigationBarTab route={directoryPageRoutes[pageId]}>{directoryPageRoutes[pageId].desktopContent}</NavigationBarTab>;
};

const NavigationBar = (): JSX.Element => {
  return (
    <div className={`${styles.navigationBar} guru-content-width`}>
      <div className={styles.linksDesktop}>
        <div className={styles.linksDesktopGroup}>
          <Tab pageId={GuruDirectoryPageId.SearchDirectory} />
          <Tab pageId={GuruDirectoryPageId.VideoDirectory} />
          <Tab pageId={GuruDirectoryPageId.ArticleDirectory} />
        </div>
        <NavigationBarTab isLogo route={directoryPageRoutes[GuruDirectoryPageId.RootDirectory]}>
          <GuruLogo />
        </NavigationBarTab>
        <div className={styles.linksDesktopGroup}>
          <Tab pageId={GuruDirectoryPageId.PlantDirectory} />
          <Tab pageId={GuruDirectoryPageId.PestDirectory} />
          <Tab pageId={GuruDirectoryPageId.BookmarkDirectory} />
        </div>
      </div>
      <div className={styles.linksMobile}>
        <NavigationBarTab route={directoryPageRoutes[GuruDirectoryPageId.RootDirectory]}>
          <GuruLogo />
        </NavigationBarTab>
        <NavigationBarDropdown />
      </div>
    </div>
  );
};

export default NavigationBar;
