import React from 'react';

import styles from './guru-button.module.css';

export enum ButtonStyle {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
}

const ButtonStyleClasses = {
  [ButtonStyle.Primary]: styles.primary,
  [ButtonStyle.Secondary]: styles.secondary,
  [ButtonStyle.Tertiary]: styles.tertiary,
};

interface iProps {
  children: React.ReactNode;
  buttonStyle?: ButtonStyle;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const GuruButton = ({ children, className = '', buttonStyle = ButtonStyle.Primary, onClick, disabled }: iProps): JSX.Element => {
  return (
    <button type='button' className={`${styles.guruButton} ${className} ${ButtonStyleClasses[buttonStyle]}`} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default GuruButton;
