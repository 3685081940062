import type { Plan, FollowOnPlanOptions, PlanItem } from './source/plan';
import { createNewPlan } from './source/plan';

import type { PlanPlant } from './source/plan-plant';
import { createNewPlanPlant, updatePlanPlant, removePlantFromPlan, addPlantToPlan } from './source/plan-plant';
import type { PlanGardenObject } from './source/plan-garden-object';
import { createNewPlanGardenObject, updatePlanGardenObject, removeGardenObjectFromPlan, addGardenObjectToPlan } from './source/plan-garden-object';
import type { PlanShape } from './source/plan-shape';
import { createNewPlanShape, updatePlanShape, removeShapeFromPlan, addShapeToPlan } from './source/plan-shape';
import type { PlanText } from './source/plan-text';
import { createNewPlanText, updatePlanText, removeTextFromPlan, addTextToPlan } from './source/plan-text';
import type { PlanBackgroundImage, PlanBackgroundImageSrc, PlanBackgroundImageSrcCrop } from './source/plan-background-image';
import { PlanBackgroundImageUtils } from './source/plan-background-image';

import type { PlanSet } from './source/plan-set';
import {
  planSetHasPlan,
  planSetGetPlan,
  planSetAddPlan,
  planSetUpdatePlan,
  planSetRemovePlan,
  createPlanSet,
  planSetRemovePlanFromHistory,
  getPlanSetDiff,
} from './source/plan-set';

import { createPlanDiff } from './source/plan-differ';
import type { PlanDiff } from './source/plan-differ';

import type {
  APIPlanDocument,
  APIPlan,
  APIPlanPlant,
  APIPlanGardenObject,
  APIPlanShape,
  APIPlanText,
  APIPlanPlantListNote,
  CreatableAPIPlan,
} from './source/plan-api-types';

import PlanService from './source/service/plan-service';
import type { PlanPlantNotes, PlanPlantNotesEntry } from './source/plan-plant-notes';
import {
  getPlantNoteVarieties,
  getPlantNote,
  getPlantNoteText,
  setPlantNote,
  removePlantNote,
  removeEmptyNotes,
  plantNotesHasPlant,
} from './source/plan-plant-notes';

import type { PlannerSettings } from './source/planner-settings';

import {
  getPlanPlants,
  getPlanGardenObjects,
  getPlanShapes,
  getPlanText,
  MAX_PLAN_YEAR,
  getYearDisplayString,
  getYearOptions,
  calculateNewDefaultPlanYear,
  bothNullOrEqual,
  updatePlanFromServerResponse,
  removePlanIdFromHistory,
  plansShallowEqual,
  copyPlanPlants,
  copyPlanGardenObjects,
  copyPlanShapes,
  copyPlanText,
  createFollowOnPlan,
  condenseZIndexes,
} from './source/plan-utils';

import { planFromAPI, planToAPI } from './source/plan-parser';

import * as PlanPlantUtils from './source/plan-plant-utils';

import * as PlanValidation from './source/plan-validation';

import * as PlanItemUtils from './source/plan-item-utils';

import type { PlanUndoStack, PlanUndoStage } from './source/plan-undo';
import { createUndoStack, undo, redo, addStackUndoStage, planEqualsUndoStage } from './source/plan-undo';

import { DEFAULT_PLANNER_SETTINGS } from './source/planner-settings';
import PlanDataError from './source/service/plan-data-error';
import type { APIPlanDocumentInvalidProperty, APIPlanDocumentInvalidProperties } from './source/plan-validation';

import { PlanTransformUtils, PlanClockwiseRotationAmount } from './source/plan-transforms';

const PlanUndo = { createUndoStack, undo, redo, addStackUndoStage, planEqualsUndoStage };

const PlanUtils = {
  getPlanPlants,
  getPlanGardenObjects,
  getPlanShapes,
  getPlanText,
  createNewPlanPlant,
  updatePlanPlant,
  removePlantFromPlan,
  addPlantToPlan,
  createNewPlanGardenObject,
  updatePlanGardenObject,
  removeGardenObjectFromPlan,
  addGardenObjectToPlan,
  createNewPlanShape,
  updatePlanShape,
  removeShapeFromPlan,
  addShapeToPlan,
  createNewPlanText,
  updatePlanText,
  removeTextFromPlan,
  addTextToPlan,
  getYearDisplayString,
  getYearOptions,
  calculateNewDefaultPlanYear,
  bothNullOrEqual,
  updatePlanFromServerResponse,
  removePlanIdFromHistory,
  plansShallowEqual,
  copyPlanPlants,
  copyPlanGardenObjects,
  copyPlanShapes,
  copyPlanText,
  createFollowOnPlan,
  condenseZIndexes,
};

const PlantNotesUtils = {
  getPlantNoteVarieties,
  getPlantNote,
  getPlantNoteText,
  setPlantNote,
  removePlantNote,
  removeEmptyNotes,
  plantNotesHasPlant,
};

const PlanParserUtils = {
  planFromAPI,
  planToAPI,
};

const PlanSetUtils = {
  planSetHasPlan,
  planSetGetPlan,
  planSetAddPlan,
  planSetUpdatePlan,
  planSetRemovePlan,
  createPlanSet,
  planSetRemovePlanFromHistory,
  getPlanSetDiff,
};

export default Plan;

export {
  Plan,
  createNewPlan,
  FollowOnPlanOptions,
  getPlanPlants,
  getPlanGardenObjects,
  getPlanShapes,
  getPlanText,
  PlanItem,
  PlanPlant,
  PlanGardenObject,
  PlanShape,
  PlanText,
  PlanBackgroundImage,
  PlanBackgroundImageSrc,
  PlanBackgroundImageSrcCrop,
  PlanPlantNotes,
  PlanPlantNotesEntry,
  PlantNotesUtils,
  createPlanDiff,
  PlanUtils,
  PlanParserUtils,
  PlanSet,
  PlanSetUtils,
  PlanDiff,
  APIPlanDocument,
  APIPlan,
  APIPlanPlant,
  APIPlanGardenObject,
  APIPlanShape,
  APIPlanText,
  APIPlanPlantListNote,
  CreatableAPIPlan,
  PlanService,
  PlanPlantUtils,
  PlanValidation,
  PlanUndoStack,
  PlanUndoStage,
  PlanUndo,
  MAX_PLAN_YEAR,
  PlannerSettings,
  DEFAULT_PLANNER_SETTINGS,
  PlanDataError,
  APIPlanDocumentInvalidProperty,
  APIPlanDocumentInvalidProperties,
  PlanClockwiseRotationAmount,
  PlanTransformUtils,
  PlanItemUtils,
  PlanBackgroundImageUtils,
};
