import React from 'react';

import './planting-calendar-key.scss';

const PlantingCalendarKeyIndoor = () => {
  return (
    <span className='sow-key planting-calendar-key'>
      <span className='color-box' />
      Sow Indoors
    </span>
  );
};

export default PlantingCalendarKeyIndoor;
