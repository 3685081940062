import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlanUtils } from '@gi/plan';
import { UserUtils, UserPlanSetUtils, UserPlan } from '@gi/user';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { SessionSelectors } from '@gi/react-session';
import { ResourceContext } from '@gi/resource-provider';

import ClearableInput from '@gi/clearable-input';
import Modal, {
  ModalPane,
  ModalPaneContent,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalHeaderContent,
  ModalHeaderTitle,
} from '@gi/modal';

import './open-plan-modal.scss';
import { openNewPlanModal, openPlanModalOpenPlan } from './open-plan-modal-action-creators';
import { OpenPlanModalActionCreators } from './open-plan-modal-slice';

enum SortOrder {
  YEAR = 'YEAR',
  NAME = 'NAME',
  LAST_MODIFIED = 'LAST_MODIFIED',
}

const nextSortOrder = {};
nextSortOrder[SortOrder.YEAR] = SortOrder.NAME;
nextSortOrder[SortOrder.NAME] = SortOrder.LAST_MODIFIED;
nextSortOrder[SortOrder.LAST_MODIFIED] = SortOrder.YEAR;

const sortStrings = {};
sortStrings[SortOrder.YEAR] = 'Year';
sortStrings[SortOrder.NAME] = 'Name';
sortStrings[SortOrder.LAST_MODIFIED] = 'Edit';

const sortFunctions = {
  [SortOrder.YEAR]: (a: UserPlan, b: UserPlan) => {
    const yearDiff = b.year - a.year;

    if (yearDiff === 0) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }

    return yearDiff;
  },
  [SortOrder.NAME]: (a: UserPlan, b: UserPlan) => {
    const localeCompare = a.name.toLowerCase().localeCompare(b.name.toLowerCase());

    if (localeCompare === 0) {
      return b.year - a.year;
    }

    return localeCompare;
  },
  [SortOrder.LAST_MODIFIED]: (a: UserPlan, b: UserPlan) => {
    return -a.modified.diff(b.modified);
  },
};

const OpenPlanModal = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const openPlanIds = useSelector(CanvasSelectors.getOpenPlanIDs);
  const user = useSelector(SessionSelectors.getUser);
  const { userDistanceUnits } = useContext(ResourceContext);

  const [selectedPlanId, setSelectedPlanId] = useState<number | null>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.LAST_MODIFIED);
  const [nameFilter, setNameFilter] = useState<string>('');

  if (user === null) {
    // This is just a gate for TypeScript, it should never happen in practice but
    // if it does it will break the component due to dropping out before all the hooks are executed
    return null;
  }

  const filteredUserPlanList = useMemo(() => {
    if (nameFilter.trim() === '') {
      return [...user.plans.list];
    }

    return [...user.plans.list].filter((userPlan) => {
      return userPlan.name.toLowerCase().includes(nameFilter.toLowerCase());
    });
  }, [user.plans, nameFilter]);

  const sortedUserPlanList = useMemo(() => {
    return filteredUserPlanList.sort(sortFunctions[sortOrder]);
  }, [sortOrder, filteredUserPlanList]);

  useEffect(() => {
    if (sortedUserPlanList.length > 0 && selectedPlanId === null) {
      setSelectedPlanId(sortedUserPlanList[0].ID);
    }
  }, [selectedPlanId, sortedUserPlanList]);

  useEffect(() => {
    if (sortedUserPlanList.length > 0) {
      setSelectedPlanId(sortedUserPlanList[0].ID);
    }
  }, [sortOrder]);

  useEffect(() => {
    if (sortedUserPlanList.length === 0) {
      setSelectedPlanId(null);
      return;
    }

    if (sortedUserPlanList.filter((plan) => plan.ID === selectedPlanId).length === 0) {
      // currently selected plan is no longer in the list
      setSelectedPlanId(sortedUserPlanList[0].ID);
    }
  }, [sortedUserPlanList]);

  const onOpenPlan = () => {
    if (selectedPlanId !== null) {
      dispatch(openPlanModalOpenPlan(selectedPlanId));
    }
  };

  const onCloseModal = () => {
    dispatch(OpenPlanModalActionCreators.close());
  };

  const onSortOrderChange = () => {
    setSortOrder(nextSortOrder[sortOrder]);
  };

  const onOpenCreatePlanModal = () => {
    dispatch(openNewPlanModal());
  };

  const renderPlan = (userPlan: UserPlan) => {
    const open = openPlanIds.includes(userPlan.ID);
    return (
      <li key={userPlan.ID} className={`user-plan-list-item ${selectedPlanId === userPlan.ID ? 'active' : ''}`}>
        <button
          type='button'
          className='button button-borderless'
          onClick={() => {
            setSelectedPlanId(userPlan.ID);
          }}
        >
          {open ? <span className='open-plan-marker' /> : null}
          <span className='plan-name'>{userPlan.name}</span>
          <span className='plan-year'>{PlanUtils.getYearDisplayString(userPlan.year, UserUtils.isNorthernHemisphere(user))}</span>
        </button>
      </li>
    );
  };

  const renderSelectedPlan = () => {
    if (selectedPlanId === null) {
      return (
        <div className='no-selected-plan'>
          <p>No Plan selected</p>
        </div>
      );
    }

    if (!UserPlanSetUtils.hasPlan(user.plans, selectedPlanId)) {
      return (
        <div className='no-selected-plan'>
          <p>Invalid Plan selected</p>
        </div>
      );
    }

    const userPlan = UserPlanSetUtils.getPlan(user.plans, selectedPlanId);

    if (userPlan === null) {
      return null;
    }

    return (
      <div className='selected-plan'>
        <h4 className='selected-plan-name'>{userPlan.name}</h4>
        <div className='selected-plan-description'>
          <p>
            <span className='selected-plan-property'>Year</span> -&nbsp;
            <span className='selected-plan-value'>{PlanUtils.getYearDisplayString(userPlan.year, UserUtils.isNorthernHemisphere(user))}</span>
          </p>
          <p>
            <span className='selected-plan-property'>Width</span> -{' '}
            <span className='selected-plan-value'>{userDistanceUnits.getUnitString(userPlan.width)}</span>
          </p>
          <p>
            <span className='selected-plan-property'>Height</span> -{' '}
            <span className='selected-plan-value'>{userDistanceUnits.getUnitString(userPlan.height)}</span>
          </p>
          <p>
            <span className='selected-plan-property'>Created</span> - <span className='selected-plan-value'>{userPlan.created.fromNow()}</span>
          </p>
          <p>
            <span className='selected-plan-property'>Last Modified</span> - <span className='selected-plan-value'>{userPlan.modified.fromNow()}</span>
          </p>
        </div>
      </div>
    );
  };

  const sortButton = (
    <button type='button' className='button button-borderless plan-sort-button' onClick={onSortOrderChange}>
      {sortStrings[sortOrder]} <i className='sort-icon icon-sort-alt-up' />
    </button>
  );

  const renderWithNoPlans = (hasPlans = false) => {
    let text = "You don't have any plans yet!";

    if (hasPlans) {
      text = 'All of your plans are already open';
    }

    return (
      <ModalContent>
        <ModalCloseButton onClick={onCloseModal} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Open Plan</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            <ModalPaneContent>
              <p className='no-plans-text'>{text}</p>
            </ModalPaneContent>
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={onCloseModal}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-primary' onClick={onOpenCreatePlanModal}>
                Create New Plan
              </button>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    );
  };

  const renderWithPlans = () => {
    return (
      <ModalContent>
        <ModalCloseButton onClick={onCloseModal} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Open Plan</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane className='open-plan-modal-pane'>
            <ModalPaneContent>
              <div className='plan-list-container'>
                <div className='plan-list-filter-container'>
                  <div className='plan-list-filter'>
                    <div className='filter-section-label'>Filter</div>
                    <label className='filter-name-label'>
                      <span className='label-text'>Name</span>
                      <ClearableInput value={nameFilter} onChange={setNameFilter} />
                    </label>
                  </div>
                  <div className='plan-list-sort'>
                    <div className='filter-section-label'>Sort By</div>
                    {sortButton}
                  </div>
                </div>
                <div className='plan-list'>
                  <ul>{sortedUserPlanList.map(renderPlan)}</ul>
                </div>
              </div>
            </ModalPaneContent>
          </ModalPane>
          <ModalPane className='open-plan-modal-pane'>
            <ModalPaneContent>
              <div className='selected-plan-details'>{renderSelectedPlan()}</div>
            </ModalPaneContent>
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={onCloseModal}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <button type='button' disabled={selectedPlanId === null} className='button button-primary' onClick={onOpenPlan}>
                Open Plan
              </button>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    );
  };

  return (
    <Modal className='open-plan-modal' closeRequest={onCloseModal}>
      {UserPlanSetUtils.planCount(user.plans) > 0 ? renderWithPlans() : renderWithNoPlans()}
    </Modal>
  );
};

export default OpenPlanModal;
