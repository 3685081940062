import { LoadingState } from '@gi/constants';

import type AssetManager from './asset-manager';
import {
  AssetGroupAsyncAssetStatus,
  AssetGroupErrorCallback,
  AssetGroupProgressCallback,
  AssetGroupSuccessCallback,
  AssetNameCollisionMode,
  AssetType,
} from './types';

type AssetToLoad = {
  type: AssetType;
  src: string;
  name: string;
};

/**
 * Asset Group
 * This can be used to load numerous assets at once.
 * Progress of each asset can be individually tracked.
 */
export class AssetGroup {
  #assetToLoad: AssetToLoad[] = [];
  #onProgress?: AssetGroupProgressCallback;
  #onSuccess?: AssetGroupSuccessCallback;
  #onError?: AssetGroupErrorCallback;

  #loadingAssetsMap: Record<string, AssetGroupAsyncAssetStatus> = {};
  #loadingAssets: AssetGroupAsyncAssetStatus[] = [];
  get loadingAssets() {
    return this.#loadingAssets;
  }

  #totalProgress: number = 0;
  get progress() {
    return this.#totalProgress;
  }

  #status: LoadingState = LoadingState.NONE;
  get status() {
    return this.#status;
  }

  #error?: Error;
  get error() {
    return this.#error;
  }

  /**
   * Adds an asset to load.
   * @param type The type of asset to load
   * @param name The name to store the asset as
   * @param src The URL of the asset
   */
  add(type: AssetType, name: string, src: string): this {
    this.#assetToLoad.push({ type, name, src });
    return this;
  }

  #onLoadProgress(name: string, percent: number) {
    const operation = this.#loadingAssetsMap[name];
    if (percent === 1) {
      operation.status = LoadingState.SUCCESS;
    } else if (operation.status === LoadingState.LOADING) {
      operation.progress = percent;
    }
    const sum = this.#loadingAssets.reduce((total, op) => {
      let progress = 0;
      if (op.status === LoadingState.SUCCESS) {
        progress = 1;
      }
      if (op.status === LoadingState.LOADING) {
        progress = op.progress;
      }
      return total + progress;
    }, 0);
    this.#totalProgress = sum / this.#loadingAssets.length;
    if (this.#onProgress) {
      this.#onProgress(this.#totalProgress, this.#loadingAssetsMap);
    }
  }

  #loadProgressCallback(name: string) {
    return (percent: number) => {
      this.#onLoadProgress(name, percent);
    };
  }

  /**
   * Sets the callback to run whenever loading progress is made
   * @param callback Callback to run on load progress
   */
  onProgress(callback: AssetGroupProgressCallback) {
    this.#onProgress = callback;
    return this;
  }

  /**
   * Sets the callback to run when loading completes.
   * @param callback Callback to run on load success
   */
  onSuccess(callback: AssetGroupSuccessCallback) {
    this.#onSuccess = callback;
    return this;
  }

  /**
   * Sets the callback to run when loading fails.
   * @param callback Callback to run on load failuer
   */
  onError(callback: AssetGroupErrorCallback) {
    this.#onError = callback;
    return this;
  }

  /**
   * Loads all the assets.
   * Will error if any of the assets fail to load, or pass when all successfully load.
   * @returns A promise. On resolve, the assets are loaded. On error, one or more assets have failed.
   */
  _load(assetManager: AssetManager, assetNameCollisionMode: AssetNameCollisionMode = AssetNameCollisionMode.ERROR) {
    this.#status = LoadingState.LOADING;
    this.#loadingAssetsMap = {};
    this.#loadingAssets = [];
    const promises = this.#assetToLoad.map((assetDef) => {
      this.#loadingAssetsMap[assetDef.name] = {
        status: LoadingState.LOADING,
        progress: 0,
        name: assetDef.name,
      };
      this.#loadingAssets.push(this.#loadingAssetsMap[assetDef.name]);

      return assetManager
        .loadAsset(assetDef.type, assetDef.name, assetDef.src, assetNameCollisionMode, this.#loadProgressCallback(assetDef.name))
        .then((result) => {
          this.#onLoadProgress(assetDef.name, 1);
          return result;
        })
        .catch((error) => {
          const operation = this.#loadingAssetsMap[assetDef.name];
          operation.status = LoadingState.ERROR;
          if (operation.status === LoadingState.ERROR) {
            operation.error = error;
          }
          throw error;
        });
    });
    this.#onProgress?.(0, this.#loadingAssetsMap);
    return new Promise<void>((resolve, reject) => {
      Promise.all(promises)
        .then(() => {
          this.#status = LoadingState.SUCCESS;
          if (this.#onSuccess) {
            this.#onSuccess();
          }
          resolve();
        })
        .catch((error) => {
          this.#status = LoadingState.ERROR;
          this.#error = error;
          if (this.#onError) {
            this.#onError(error, this.#loadingAssetsMap);
          }
          reject(error);
        });
    });
  }
}
