import { createSelector } from 'reselect';
import { LoadingState, MAX_LOAD_PLAN_RETRIES } from '@gi/constants';
import Plan, { PlanSetUtils, PlanUtils } from '@gi/plan';
import { CanvasReducerState } from './canvas-reducer';

interface ICanvasSelectorState {
  canvas: CanvasReducerState;
}

export const getActivePlanID = (state: ICanvasSelectorState) => state.canvas.activePlanID;
export const getPlans = (state: ICanvasSelectorState) => state.canvas.plans;
export const getOpenPlanIDs = (state: ICanvasSelectorState) => state.canvas.openPlanIDs;
export const getLoadedPlans = (state: ICanvasSelectorState) => state.canvas.loadedPlans;
export const getLastSavePlans = (state: ICanvasSelectorState) => state.canvas.lastSavePlans;
export const getFailedToLoadPlans = (state: ICanvasSelectorState) => state.canvas.failedToLoadPlans;
export const getInteractionState = (state: ICanvasSelectorState) => state.canvas.interactionState;
export const getSelectedItems = (state: ICanvasSelectorState) => state.canvas.selectedItems;
export const getUndoStacks = (state: ICanvasSelectorState) => state.canvas.undoStacks;
export const getHasClipboard = (state: ICanvasSelectorState) => state.canvas.hasClipboard;

export const getTexturesStatus = (state: ICanvasSelectorState) => state.canvas.texturesStatus;
export const getTexturesLoading = (state: ICanvasSelectorState) => state.canvas.texturesStatus.status === LoadingState.LOADING;
export const getTexturesLoaded = (state: ICanvasSelectorState) => state.canvas.texturesStatus.status === LoadingState.SUCCESS;
export const getTexturesLoadError = (state: ICanvasSelectorState) =>
  state.canvas.texturesStatus.status === LoadingState.ERROR ? state.canvas.texturesStatus.error : null;

export const getConfirmClosePlans = (state: ICanvasSelectorState) => state.canvas.confirmClosePlans;

export const getPlanDataErrors = (state: ICanvasSelectorState) => state.canvas.planDataErrors;

export const getContextMenuWorldPosition = (state: ICanvasSelectorState) => state.canvas.showContextMenuAt;

export const getFailedToLoadPlanIDs = createSelector([getFailedToLoadPlans], (failedToLoadPlans) => {
  return Object.keys(failedToLoadPlans).map((id) => Number(id));
});

export const getActivePlan = createSelector([getActivePlanID, getPlans], (activePlanID, plans) => {
  if (activePlanID === null) {
    return null;
  }

  return PlanSetUtils.planSetGetPlan(plans, activePlanID);
});

export const getActiveLoadedPlan = createSelector([getActivePlanID, getLoadedPlans], (activePlanID, loadedPlans) => {
  if (activePlanID === null) {
    return null;
  }

  return PlanSetUtils.planSetGetPlan(loadedPlans, activePlanID);
});

export const getActiveLastSavePlan = createSelector([getActivePlanID, getLastSavePlans], (activePlanID, lastSavedPlans) => {
  if (activePlanID === null) {
    return null;
  }

  return PlanSetUtils.planSetGetPlan(lastSavedPlans, activePlanID);
});

export const getMaxRetryFailedToLoadPlanIDs = createSelector([getFailedToLoadPlans, getFailedToLoadPlanIDs], (failedToLoadPlans, failedToLoadPlanIDs) => {
  const maxRetryFailPlans: number[] = [];
  for (let i = 0; i < failedToLoadPlanIDs.length; i++) {
    if (failedToLoadPlans[failedToLoadPlanIDs[i]] >= MAX_LOAD_PLAN_RETRIES) {
      maxRetryFailPlans.push(failedToLoadPlanIDs[i]);
    }
  }
  return maxRetryFailPlans;
});

export const getHasUnsavedChanges = createSelector([getActivePlan, getActiveLastSavePlan], (activePlan, activeLastSavePlan) => {
  if (activePlan === null) {
    return false;
  }

  if (activeLastSavePlan === null) {
    return true;
  }

  return !PlanUtils.plansShallowEqual(activePlan, activeLastSavePlan);
});

export const getUnsavedPlans = createSelector([getPlans, getOpenPlanIDs, getLastSavePlans], (plans, openPlanIDs, lastSavePlans) => {
  const plansWithUnsavedChanges: Plan[] = [];

  openPlanIDs.forEach((planID) => {
    const plan = PlanSetUtils.planSetGetPlan(plans, planID);
    const lastSavePlan = PlanSetUtils.planSetGetPlan(lastSavePlans, planID);

    if (plan !== null && lastSavePlan !== null) {
      if (!PlanUtils.plansShallowEqual(plan, lastSavePlan)) {
        plansWithUnsavedChanges.push(plan);
      }
    }
  });

  return PlanSetUtils.createPlanSet(plansWithUnsavedChanges);
});
