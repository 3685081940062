import LoadingButton from '@gi/loading-button';
import React from 'react';
import useFavouritePlant from '@gi/use-favourite-plant';

import styles from './controls-banner.module.css';

interface iProps {
  plantCode: string;
}

const FavouriteButton = ({ plantCode }: iProps): JSX.Element | null => {
  const [isFavourite, setFavourite, saving, userSavingOrLoading] = useFavouritePlant(plantCode);

  return (
    <LoadingButton
      type='button'
      className={styles.controlsButton}
      onClick={() => {
        setFavourite(!isFavourite);
      }}
      loading={saving}
      disabled={userSavingOrLoading}
      buttonIcon={isFavourite ? 'icon-star' : 'icon-star-empty'}
      alwaysShowIcon
      iconContainerClassName={isFavourite ? styles.favouriteStar : styles.favouriteStarOutline}
    >
      {isFavourite ? 'In Favorites' : 'Favorite'}
    </LoadingButton>
  );

  return null;
};

export default FavouriteButton;
