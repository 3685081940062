import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GuruActionCreators, GuruSelectors } from '@gi/app-guru-slice';
import { LoadingState } from '@gi/constants';

import SmallPreviewRow from '../small-preview/small-preview-row/small-preview-row';

interface iProps {
  url: string;
  outerClassName?: string;
  innerClassName?: string;
}

const RelatedContentSection = ({ url, outerClassName, innerClassName }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const relatedContent = useSelector(GuruSelectors.getRelatedContentSections);

  const relatedContentSection = useMemo(() => {
    if (url) {
      return relatedContent[url];
    }
    return null;
  }, [url, relatedContent]);

  useEffect(() => {
    if (!relatedContentSection || relatedContentSection.status === LoadingState.NONE) {
      // The related content hasn't yet been requested for loading
      if (url) {
        dispatch(GuruActionCreators.loadRelatedContentSection([url, false]));
      }
    }
  }, [relatedContentSection, url]);

  if (!url) {
    return null;
  }

  if (!relatedContentSection || relatedContentSection.status === LoadingState.NONE || relatedContentSection.status === LoadingState.LOADING) {
    return null;
  }

  if (relatedContentSection.status === LoadingState.SUCCESS && relatedContentSection.value.items.length > 0) {
    return (
      <div className={outerClassName}>
        <div className={innerClassName}>
          <SmallPreviewRow
            hideWhenEmpty
            removeSection
            title={relatedContentSection.value.title}
            items={relatedContentSection.value.items}
            minItemHeight={120}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default RelatedContentSection;
