import React, { useMemo } from 'react';

import { Direction } from '@gi/constants';

import styles from './faded-scroll-container-button.module.css';

const directionClassNames: Record<Direction, string> = {
  [Direction.UP]: styles.top,
  [Direction.DOWN]: styles.bottom,
  [Direction.LEFT]: styles.left,
  [Direction.RIGHT]: styles.right,
};

const directionIconClassNames: Record<Direction, string> = {
  [Direction.UP]: 'icon-angle-up',
  [Direction.DOWN]: 'icon-angle-down',
  [Direction.LEFT]: 'icon-angle-left',
  [Direction.RIGHT]: 'icon-angle-right',
};

interface iProps {
  direction: Direction;
  hidden?: boolean;
  noButton?: boolean;
  noArrow?: boolean;
  onClick?: (direction: Direction) => void;
  className?: string;
}

const FadedScrollContainerButton = ({ direction, hidden, onClick, noButton = false, noArrow = false, className }: iProps): JSX.Element => {
  const innerContent = useMemo(() => {
    if (noButton && noArrow) {
      return null;
    }

    const icon = <i className={directionIconClassNames[direction]} />;

    if (noButton) {
      return <div className={`${styles.button} ${styles.noClick}`}>{icon}</div>;
    }

    return (
      <button type='button' className={styles.button} onClick={() => onClick?.(direction)}>
        {noArrow ? null : icon}
      </button>
    );
  }, [noButton, noArrow, direction, onClick]);

  const classNames: string[] = [styles.container, directionClassNames[direction]];
  if (hidden) {
    classNames.push(styles.hidden);
  }
  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{innerContent}</div>;
};

export default FadedScrollContainerButton;
