import React from 'react';

import PrintablePlannerIcon from './printable-planner-icon';

import './printable-planner-icon-with-family.scss';

interface iProps {
  className?: string;
  iconClassName?: string;
  artifactCode: string;
  code: string;
  familyID: number;
}

const PlannerIconWithFamily = ({ className = '', iconClassName = '', artifactCode, code, familyID }: iProps): JSX.Element => {
  return (
    <span className={`${className} printable-planner-icon-with-family printable-planner-icon-family-${familyID}`}>
      <PrintablePlannerIcon artifactCode={artifactCode} code={code} className={iconClassName} />
    </span>
  );
};

export default PlannerIconWithFamily;
