import React from 'react';
import { GuruPreviewPlant } from '@gi/app-guru-types';
import { NavLink } from 'react-router-dom';

import HeroPreviewThumbnail from '../components/hero-preview-thumbnail';
import HeroPreviewTitle from '../components/hero-preview-title';
import HeroPreviewDescription from '../components/hero-preview-description';
import {
  HeroPreviewContent,
  HeroPreviewDescriptionContainer,
  HeroPreviewLinkContainer,
  HeroPreviewThumbnailContainer,
} from '../components/hero-preview-content';

import styles from '../hero-preview.module.css';

interface iProps {
  previewPlant: GuruPreviewPlant;
  link: string;
}

const HeroPreviewPlant = ({ previewPlant, link }: iProps): JSX.Element => {
  return (
    <HeroPreviewContent>
      <HeroPreviewThumbnailContainer>
        <NavLink to={link}>
          <HeroPreviewThumbnail src={previewPlant.thumbnailUrl} alt={`${previewPlant.title} - thumbnail`} />
        </NavLink>
      </HeroPreviewThumbnailContainer>
      <HeroPreviewDescriptionContainer>
        <HeroPreviewTitle>{previewPlant.title}</HeroPreviewTitle>
        <HeroPreviewDescription>{previewPlant.description}</HeroPreviewDescription>
        <HeroPreviewLinkContainer>
          <NavLink className={styles.heroLink} to={link}>
            View Plant Guide
          </NavLink>
        </HeroPreviewLinkContainer>
      </HeroPreviewDescriptionContainer>
    </HeroPreviewContent>
  );
};

export default HeroPreviewPlant;
