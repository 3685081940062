import React from 'react';

import Modal, {
  ModalPane,
  ModalPaneSection,
  ModalPaneContent,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalHeaderContent,
  ModalHeaderTitle,
} from '@gi/modal';

import styles from './image-display-modal.module.css';

interface iProps {
  children: React.ReactNode;
  title: string;
  closeModal: () => void;
}

const ImageDisplayModal = ({ children, title, closeModal }: iProps): JSX.Element => (
  <Modal closeRequest={closeModal} className={styles.imageDisplayModal}>
    <ModalContent>
      <ModalCloseButton onClick={closeModal} />
      <ModalHeader>
        <ModalHeaderContent>
          <ModalHeaderTitle>{title}</ModalHeaderTitle>
        </ModalHeaderContent>
      </ModalHeader>
      <ModalPaneContainer>
        <ModalPane className='open-plan-modal-pane'>
          <ModalPaneContent>
            <ModalPaneSection>{children}</ModalPaneSection>
          </ModalPaneContent>
        </ModalPane>
      </ModalPaneContainer>
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButtonsSection>
            <button
              type='button'
              className='button button-secondary'
              onClick={() => {
                closeModal();
              }}
            >
              Close
            </button>
          </ModalFooterButtonsSection>
        </ModalFooterButtons>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default ImageDisplayModal;
