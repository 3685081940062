import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { InspectableClassData } from '../../types';
import { hasOwner } from '../../utils/asserts';
import InteractableComponent, { InteractableComponentCallbacks } from '../interactable/interactable-component';
import SelectableComponent from '../selectable/selectable-component';
import SelectableComponentContext from '../selectable/selectable-component-context';
import RightClickableComponentContext from './right-clickable-component-context';

interface RightClickableComponentOptions {
  /** Should right-clicking this node select it if not already selected? */
  modifySelection: boolean;
}

/**
 * Right-clickable Component
 *  Handles right-click events via the InteractableComponent. Events can be generically listened
 *  for from within the context.
 */
class RightClickableComponent extends NodeComponent {
  type = 'RightClickableComponent';

  #options: RightClickableComponentOptions;

  /**
   * Set to true to prevent right-clicks being passed up to the context.
   */
  preventEmittingEvent: boolean = false;
  /**
   * Callback to run when the node is right-clicked.
   * By default, the event will be passed up to the context, where an event can be listened for.
   */
  onRightClick?: () => void = undefined;

  #cleanUpListeners: (() => void) | null;

  constructor(options?: Partial<RightClickableComponentOptions>) {
    super();

    this.#options = {
      modifySelection: options?.modifySelection ?? true,
    };

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasOwner(this);
    const interactableComponent = this.owner.components.get(InteractableComponent);

    if (!interactableComponent) {
      console.error('RightClickableComponent requires an InteractableComponent be attached to the Node');
      return;
    }

    interactableComponent.addListener('onClick', this.#onClick);

    this.#cleanUpListeners = () => {
      interactableComponent.removeListener('onClick', this.#onClick);
    };
  };

  #onBeforeUnbind = () => {
    this.#cleanUpListeners?.();
    this.#cleanUpListeners = null;
  };

  #onClick: InteractableComponentCallbacks['onClick'] = (event, interaction, controls) => {
    hasOwner(this);

    if (event.button !== 1) {
      return;
    }

    controls.stopPropagation();

    if (this.#options.modifySelection) {
      const selectable = this.owner.components.get(SelectableComponent);
      const context = this.owner.tryGetContext(SelectableComponentContext);
      if (context) {
        if (selectable && selectable.isSelectable) {
          if (!context.isInSelection(this.owner)) {
            if (event.shiftKey) {
              context.addToSelection(this.owner);
            } else {
              context.setSelection([this.owner]);
            }
          }
        } else {
          context.clearSelection();
        }
        context.manualFlushUpdates();
      }
    }

    if (this.onRightClick) {
      this.onRightClick();
    }

    if (!this.preventEmittingEvent) {
      this.owner.getContext(RightClickableComponentContext).wasRightClicked(this.owner, event.worldPosition);
    }
  };

  inspectorData: InspectableClassData<this> = [];
}

export default RightClickableComponent;
