import React, { ChangeEvent, ComponentProps, HTMLInputTypeAttribute } from 'react';

import './clearable-input.scss';

interface iProps extends Omit<ComponentProps<'input'>, 'onChange'> {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
}

const ClearableInput = ({ value, onChange, className = '', placeholder = '', type = 'text', ...otherProps }: iProps): JSX.Element => {
  const onClearPress = () => {
    onChange('');
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={`clearable-input ${className}`}>
      <input type={type} placeholder={placeholder} value={value} onChange={onInputChange} {...otherProps} />
      <span className='clearable-input-clear'>
        <button type='button' aria-label='Clear Text' onClick={onClearPress}>
          <i className='icon-cancel' />
        </button>
      </span>
    </div>
  );
};

export default ClearableInput;
