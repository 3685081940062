import React, { ReactNode, useContext, useMemo } from 'react';

import styles from './sidebar-styles.module.css';
import { SidebarContext } from './sidebar-context';

type iProps = {
  tabId: string;
  className?: string;
  children?: ReactNode;
} & {
  [key: `data-${string}`]: unknown;
};

const SidebarContent = ({ tabId, className, children, ...dataAttributes }: iProps): JSX.Element | null => {
  const { activeTab, minimised } = useContext(SidebarContext);

  const isActive = useMemo(() => activeTab === tabId && !minimised, [activeTab, tabId, minimised]);

  const classNames: string[] = [styles.content];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')} data-active={isActive} hidden={!isActive} aria-hidden={!isActive} {...dataAttributes}>
      {children}
    </div>
  );
};

export default SidebarContent;
