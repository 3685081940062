import { ContentUtils } from '@gi/app-guru-common';
import React, { useMemo } from 'react';

import styles from './publish-date.module.css';

interface iProps {
  published: number;
  format?: string;
}

const PublishDate = ({ published, format = 'Do MMMM YYYY' }: iProps): JSX.Element => {
  const dateStr = useMemo(() => {
    return ContentUtils.getFormattedDate(published, format);
  }, [published, format]);

  return (
    <span className={styles.publishDate}>
      Published: <strong>{dateStr}</strong>
    </span>
  );
};

export default PublishDate;
