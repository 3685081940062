import React from 'react';

import ContextMenuButton from './context-menu-button';
import ContextMenuSection from './context-menu-section';
import { ContextMenuOptionGroup } from './types';

/**
 * Creates a list of grouped options for a context menu
 * @param groups The list of groups to make options for
 * @param afterOnClick Optional callback to call after an option is clicked. Can be used to close the context menu.
 * @returns A list of elements
 */
export function makeContextMenuOptions(groups: ContextMenuOptionGroup[], afterOnClick?: () => void): JSX.Element[] {
  return groups.map((group, i) => (
    <ContextMenuSection title={group.title} key={i}>
      {group.options.map((option, j) => (
        <ContextMenuButton
          icon={option.icon}
          disabled={option.command === null}
          onClick={() => {
            option.command?.();
            afterOnClick?.();
          }}
          key={j}
        >
          {option.title}
        </ContextMenuButton>
      ))}
    </ContextMenuSection>
  ));
}
