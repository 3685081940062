import { InteractionStateType } from '@gi/constants';
import {
  GraphicNode,
  InspectableClassData,
  InspectableClassDataType,
  InspectableClassPropertyType,
  InteractionManagerState,
  NodeEvent,
  bindState,
} from '@gi/core-renderer';
import { State, StateDef } from '@gi/state';

export interface ToolState extends InteractionManagerState {
  type: InteractionStateType;
}

/**
 * Tool Node
 *
 * Other tool nodes should extend this in order to work properly.
 * When created, this node will automatically register itself with the tool context for updates.
 */
abstract class ToolNode<T extends ToolState> extends GraphicNode {
  type = 'ToolNode';

  readonly externalState: State<StateDef<T>>;

  constructor(initialState: T) {
    super();

    this.externalState = new State(initialState);
    bindState(this.externalState, this);

    this.externalState.addWatcher((state) => {
      this.engine?.interactionManager.setInteractionState({ ...state.values });
    });

    this.eventBus.on(NodeEvent.DidBind, () => {
      this.engine?.interactionManager.setInteractionState({ ...this.externalState.values });
    });

    this.eventBus.on(NodeEvent.BeforeUnbind, () => {
      this.engine?.interactionManager.clearInteractionState();
    });

    this.eventBus.on(NodeEvent.DidUnbind, () => {
      this.destroy();
    });
  }

  /**
   * Method to cancel using this tool.
   */
  abstract cancel(): void;

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'externalState',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default ToolNode;
