import React, { useEffect } from 'react';

import { useAsRef } from '@gi/react-utils';

interface iProps {
  value: string;
  onChange: (value: string) => void;
  onSyncRequest?: () => void;
  disabled?: boolean;
  className?: string;
}

/** Super basic notes editor component, in case we ever want to expand functionality in the future */
const NotesEditor = ({ value, onChange, disabled, className, onSyncRequest }: iProps): JSX.Element => {
  const onSyncRequestRef = useAsRef(onSyncRequest);

  /** Make a final sync request when this component unmounts */
  useEffect(() => {
    return () => {
      onSyncRequestRef.current?.();
    };
  }, []);

  return <textarea value={value} onChange={(e) => onChange(e.target.value)} className={className} disabled={disabled} onBlur={onSyncRequest} />;
};

export default NotesEditor;
