import React from 'react';

import styles from '../tutorial-display.module.css';

interface iProps {
  src: string;
  alt?: string;
}

const ImageContent = ({ src, alt }: iProps): JSX.Element => {
  return (
    <div className={styles.tutorialImageContent}>
      <img src={src} alt={alt ? alt : ''} />
    </div>
  );
};

export default ImageContent;
