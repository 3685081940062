import React, { useState } from 'react';

import styles from './menu-bar.module.css';

interface iProps {
  children: React.ReactNode;
  title?: string;
  collapsible?: boolean;
  onHelp?: () => void;
}

const MenuBarGroup = ({ children, title: groupTitle, collapsible = false, onHelp }: iProps): JSX.Element => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <div className={styles.menuBarGroup}>
      <div className={styles.menuBarGroupTitle} style={collapsed ? { flexDirection: 'column-reverse' } : {}}>
        <div style={collapsed ? { writingMode: 'vertical-rl' } : {}}>{groupTitle}</div>
        <div className={styles.collapseContainer}>
          {collapsible ? (
            <button
              type='button'
              className={styles.menuBarGroupButton}
              onClick={() => {
                setCollapsed((_collapsed) => !_collapsed);
              }}
            >
              <i className={collapsed ? 'icon-angle-double-right' : 'icon-angle-double-left'} />
            </button>
          ) : null}
          {onHelp ? (
            <button type='button' className={styles.menuBarGroupButton} onClick={onHelp} style={collapsed ? { display: 'none' } : {}}>
              <i className='icon-help-circled' />
            </button>
          ) : null}
        </div>
      </div>
      {collapsed ? null : <div>{children}</div>}
    </div>
  );
};

export default MenuBarGroup;
