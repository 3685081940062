import React, { ReactNode, createContext, useCallback, useMemo, useState } from 'react';

export type AccordionContextType = {
  setExpanded: (id: string, expanded: boolean) => void;
  isExpanded: (id: string) => boolean;
  animationDuration: number | undefined;
  unstyled: boolean;
};

export const AccordionContext = createContext<AccordionContextType>({} as AccordionContextType);

export interface AccordionProviderProps {
  singleItem?: boolean;
  initiallyExpandedIds?: string[];
  keepExpanded?: boolean;
  animationDuration?: number;
  unstyled?: boolean;
  children?: ReactNode;
  expandedIds?: string[];
  setExpandedIds?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const AccordionProvider = ({
  singleItem = false,
  initiallyExpandedIds = [],
  keepExpanded = false,
  animationDuration,
  unstyled = true,
  children,
  expandedIds,
  setExpandedIds,
}: AccordionProviderProps): JSX.Element => {
  const [internalExpandedIds, internalSetExpandedIds] = useState<string[]>(singleItem ? initiallyExpandedIds.slice(0, 1) : initiallyExpandedIds);

  const setExpanded = useCallback(
    (id: string, expanded: boolean) => {
      // Use the provided setter function if available, otherwise fall back to own internal state
      const setterFunc = setExpandedIds ?? internalSetExpandedIds;

      setterFunc((currentlyExpandedIds) => {
        const index = currentlyExpandedIds.indexOf(id);
        const currentlyExpanded = index !== -1;
        if (currentlyExpanded === expanded) {
          return currentlyExpandedIds;
        }

        // Single item mode
        if (singleItem) {
          if (expanded) {
            return [id];
          }
          if (keepExpanded) {
            // Don't collapse in keepExpanded mode
            return currentlyExpandedIds;
          }
          return [];
        }

        // Multi item mode
        if (expanded) {
          return [...currentlyExpandedIds, id];
        }
        if (keepExpanded && currentlyExpandedIds.length === 1) {
          // Don't collapse if we're the only id left in keepExpanded mode
          return currentlyExpandedIds;
        }
        const copy = [...currentlyExpandedIds];
        copy.splice(index, 1);
        return copy;
      });
    },
    [singleItem, keepExpanded]
  );

  const isExpanded = useCallback(
    (id: string): boolean => {
      // Use provided list of IDs if available, otherwise fall back to own internal state
      const idList = expandedIds ?? internalExpandedIds;
      return idList.indexOf(id) !== -1;
    },
    [expandedIds, internalExpandedIds]
  );

  const value = useMemo(
    () => ({
      setExpanded,
      isExpanded,
      animationDuration,
      unstyled,
    }),
    [setExpanded, isExpanded, animationDuration, unstyled]
  );

  return <AccordionContext.Provider value={value}>{children}</AccordionContext.Provider>;
};
