import { useCallback, useContext, useEffect, useState } from 'react';

import { EngineEvent } from '@gi/core-renderer';
import { RenderModeNames } from '@gi/constants';

import { GardenCanvasContext } from '../garden-canvas-context/garden-canvas-context';

/**
 * A react hook which just returns a string/null representing the renderer mode of the Garden Canvas
 */
const useRenderMode = () => {
  const { gardenCanvas } = useContext(GardenCanvasContext);

  const [renderMode, setRenderMode] = useState<string>('None');

  const getRenderMode = useCallback(() => {
    if (gardenCanvas !== null) {
      if (gardenCanvas.engine.renderMode !== null) {
        return RenderModeNames[gardenCanvas.engine.renderMode];
      }
      return 'Unknown';
    }

    return 'None';
  }, [gardenCanvas]);

  const updateRenderMode = useCallback(() => {
    setRenderMode(getRenderMode());
  }, [getRenderMode]);

  useEffect(() => {
    if (gardenCanvas) {
      updateRenderMode();
      gardenCanvas.engine.eventBus.on(EngineEvent.RendererChanged, updateRenderMode);

      return () => {
        gardenCanvas.engine.eventBus.off(EngineEvent.RendererChanged, updateRenderMode);
      };
    }

    return () => {};
  }, [updateRenderMode]);

  return renderMode;
};

export default useRenderMode;
