import PropTypes from 'prop-types';

import Plan from '@gi/plan';

import { UserPlan, UserPlanUtils } from './user-plan';

/**
 * Stores a list of UserPlans, with a mapping of [planID]: UserPlan.
 */
export type UserPlanSet = {
  list: UserPlan[];
  map: Record<string, UserPlan>;
};

/**
 * Creates a UserPlanSet containing all the UserPlans given
 * @param userPlans The UserPlans
 * @returns A UserPlanSet
 */
const create = (userPlans: UserPlan[]) => {
  const list = [...userPlans];
  const map = list.reduce((newMap, plan) => {
    return {
      ...newMap,
      [plan.ID]: plan,
    };
  }, {});

  return {
    list,
    map,
  };
};

/**
 * Returns the amount of plans in the given UserPlanSet
 * @param userPlanSet The UserPlanSet
 * @returns The amount of plans in the set
 */
const planCount = (userPlanSet: UserPlanSet) => {
  return userPlanSet.list.length;
};

/**
 * Returns true if the UserPlanSet contains a plan with the given ID.
 * @param userPlanSet The UserPlanSet to check
 * @param id The ID of the plan to find
 * @returns True if found
 */
const hasPlan = (userPlanSet: UserPlanSet, id: UserPlan['ID']) => {
  return Object.prototype.hasOwnProperty.call(userPlanSet.map, id);
};

/**
 * Adds a UserPlan to the given UserPlanSet
 * @param userPlanSet The UserPlanSet to add to
 * @param userPlan The userPlan to add
 * @returns An updated UserPlanSet containing the new UserPlan
 */
const addUserPlan = (userPlanSet: UserPlanSet, userPlan: UserPlan) => {
  if (hasPlan(userPlanSet, userPlan.ID)) {
    throw new Error('Attempted to add plan which is already in set');
  }

  const newList = [...userPlanSet.list, userPlan];
  const newMap = { ...userPlanSet.map };
  newMap[userPlan.ID] = userPlan;

  return {
    list: newList,
    map: newMap,
  };
};

// getCount() {
//   return this.list.size;
// }

// asList() {
//   return this.list;
// }

/**
 * Gets a plan by ID from the set.
 * @param userPlanSet The UserPlanSet
 * @param planID The ID of the plan to get
 * @returns The plan, if found. Otherwise null.
 */
const getPlan = (userPlanSet: UserPlanSet, planID: number) => {
  if (hasPlan(userPlanSet, planID)) {
    return userPlanSet.map[planID];
  }

  return null;
};

/**
 * Updates a user plan within the UserPlanSet
 * @param userPlanSet The UserPlanSet to update
 * @param updatedUserPlan The updated UserPlan
 * @returns An new, updated UserPlanSet
 */
const updateUserPlan = (userPlanSet: UserPlanSet, updatedUserPlan: UserPlan) => {
  if (!hasPlan(userPlanSet, updatedUserPlan.ID)) {
    throw new Error("Attempted to update user plan which doesn't exist in set");
  }

  const oldUserPlan = userPlanSet.map[updatedUserPlan.ID];
  const oldIndex = userPlanSet.list.indexOf(oldUserPlan);

  const newMap = { ...userPlanSet.map };
  newMap[updatedUserPlan.ID] = updatedUserPlan;
  const newList = [...userPlanSet.list];
  newList[oldIndex] = updatedUserPlan;

  return {
    list: newList,
    map: newMap,
  };
};

/**
 * Returns a new UserPlanSet in which the provided plan has been turned into a UserPlan and updated/added
 *  to this UserPlanSet
 * @param userPlanSet The UserPlanSet
 * @param plan The plan to update in the set
 * @returns An updated UserPlanSet
 */
const updateUserPlansFromPlan = (userPlanSet: UserPlanSet, plan: Plan) => {
  // Create new UserPlan and then either add it or update the existing plan
  const updatedUserPlan = UserPlanUtils.createFromPlan(plan);

  if (!hasPlan(userPlanSet, plan.id)) {
    return addUserPlan(userPlanSet, updatedUserPlan);
  }

  return updateUserPlan(userPlanSet, updatedUserPlan);
};

/**
 * Returns a new UserPlanSet with the UserPlan with the given planID removed
 *  If no plan is found it logs a warning and returns itself
 * @param userPlanSet The UserPlanSet
 * @param planID The ID of the plan to remove
 * @returns An updated UserPlanSet
 */
const removeUserPlanFromPlans = (userPlanSet: UserPlanSet, planID: number) => {
  if (!hasPlan(userPlanSet, planID)) {
    console.warn("Attempted to remove UserPlan from UserPlanSet but it wasn't present:", planID);
    return this;
  }

  const newMap = { ...userPlanSet.map };
  delete newMap[planID];

  const newList = userPlanSet.list.filter((val) => val.ID !== planID);

  return {
    list: newList,
    map: newMap,
  };
};

/**
 * @deprecated
 * Avoid using PropTypes
 */
export const UserPlanSetShape = PropTypes.shape({
  map: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
});

export const UserPlanSetUtils = {
  create,
  planCount,
  hasPlan,
  addUserPlan,
  getPlan,
  updateUserPlan,
  updateUserPlansFromPlan,
  removeUserPlanFromPlans,
};

export default UserPlanSet;
