import React from 'react';

import styles from './hero-preview-components.module.css';

interface iProps {
  children: React.ReactNode;
}

export const HeroPreviewContent = ({ children }: iProps): JSX.Element => {
  return <div className={styles.heroPreviewContent}>{children}</div>;
};

export const HeroPreviewThumbnailContainer = ({ children }: iProps): JSX.Element => {
  return <div className={styles.heroPreviewThumbnailContainer}>{children}</div>;
};

export const HeroPreviewDescriptionContainer = ({ children }: iProps): JSX.Element => {
  return <div className={styles.heroPreviewDescriptionContainer}>{children}</div>;
};

export const HeroPreviewLinkContainer = ({ children }: iProps): JSX.Element => {
  return <div className={styles.heroPreviewLinkContainer}>{children}</div>;
};
