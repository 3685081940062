import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { GardenCanvas } from '@gi/plan-simulation';
import { ResourceContext } from '@gi/resource-provider';
import { SessionSelectors } from '@gi/react-session';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { DisplayModeSelectors } from '@gi/display-mode-provider';

import GardenCanvasController from '../garden-canvas-controller/garden-canvas-controller';

type GardenCanvasContextType = {
  gardenCanvas: null | GardenCanvas;
  createInstance: () => void;
  destroyInstance: () => void;
};

export const GardenCanvasContext = createContext<GardenCanvasContextType>({} as GardenCanvasContextType);

interface iProps {
  children: ReactNode;
  gardenCanvasController: GardenCanvasController;
}

const GardenCanvasProvider = ({ children, gardenCanvasController }: iProps): JSX.Element => {
  const [gardenCanvas, setGardenCanvas] = useState<GardenCanvas | null>(null);

  const user = useSelector(SessionSelectors.getUser);
  const localSettings = useSelector(LocalSettingsSelectors.getLocalSettings);
  const touchMode = useSelector(DisplayModeSelectors.getTouchMode);
  const { userPlants, userGardenObjects, userArtifactCode } = useContext(ResourceContext);

  const createInstance = useCallback(() => {
    if (!gardenCanvasController.hasInstance() && user) {
      setGardenCanvas(gardenCanvasController.createInstance(userPlants, userGardenObjects, user, localSettings, touchMode, userArtifactCode));
    }
  }, [gardenCanvasController, userPlants, userGardenObjects, user, localSettings, touchMode, userArtifactCode]);

  const destroyInstance = useCallback(() => {
    if (gardenCanvasController.hasInstance()) {
      gardenCanvasController.destroyInstance();
      setGardenCanvas(null);
    }
  }, [gardenCanvasController]);

  useEffect(() => {
    if (gardenCanvas) {
      gardenCanvas.setSettings({ touchMode });
    }
  }, [touchMode]);

  const value = useMemo<GardenCanvasContextType>(
    () => ({
      gardenCanvas,
      createInstance,
      destroyInstance,
    }),
    [gardenCanvas, createInstance, destroyInstance]
  );

  return <GardenCanvasContext.Provider value={value}>{children}</GardenCanvasContext.Provider>;
};

export default GardenCanvasProvider;
