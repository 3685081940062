import Node from '../../node';
import EventBus from '../../event-bus';
import { InspectableClassData } from '../../types';
import NodeComponent, { NodeComponentEventActions } from '../../node-component/node-component';

export enum RightClickEvent {
  OnRightClick = 'OnRightClick',
}

type RightClickEventActions = {
  [RightClickEvent.OnRightClick]: (node: Node, worldPosition: Vector2) => void;
};

/**
 * Right-clickable component context
 *  Handles firing events when any node is right-clicked.
 */
class RightClickableComponentContext extends NodeComponent {
  type = 'RightClickableComponentContext';

  readonly eventBus: EventBus<NodeComponentEventActions & RightClickEventActions>;

  constructor() {
    super();

    this.eventBus = new EventBus(this.eventBus);
  }

  wasRightClicked<T extends Node>(node: T, worldPosition: Vector2) {
    this.eventBus.emit(RightClickEvent.OnRightClick, node, worldPosition);
  }

  inspectorData: InspectableClassData<this> = [];
}

export default RightClickableComponentContext;
