import React, { ChangeEventHandler, ReactNode, useCallback, useEffect, useState } from 'react';
import { InputContainer } from '@gi/form-responsive';
import { useDebounce } from '@gi/react-utils';

import './length-input.scss';

const AUTO_UPDATE_TIME = 4000;

function inputToNumber(input: string): number {
  // This line might be pointless? Apple Scribble allows spaces to be entered
  // but they don't actually make it this far meaning we can't correct for it.
  // WE just get empty input back
  const spacelessInput = input.replace(/\s+/g, '');
  const num = parseFloat(spacelessInput);
  if (Number.isNaN(num)) {
    return 0;
  }
  return num;
}

interface iProps {
  id?: string;
  value: number;
  onChange: (value: number) => void;
  valid?: boolean;
  inputClassName?: string;
  className?: string;
  inputSuffix: ReactNode;
  inputSuffixInline?: boolean;
  size: number;
  disabled?: boolean;
  autoUpdate?: boolean;
  inputMode?: 'numeric' | 'decimal';
}

const NumericBlurInput = ({
  id,
  value,
  onChange,
  valid,
  inputClassName = '',
  className = '',
  inputSuffix,
  inputSuffixInline = false,
  size,
  disabled,
  autoUpdate = true,
  inputMode = 'decimal',
}: iProps): JSX.Element => {
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [displayValue, setDisplayValue] = useState<string>(value.toString());
  const debouncedDisplayValue = useDebounce(displayValue, AUTO_UPDATE_TIME);

  useEffect(() => {
    // Update the display value to match input value on blur or whenever value changes if not focussed.
    if (!hasFocus) {
      setDisplayValue(value.toString());
    }
  }, [value, hasFocus]);

  useEffect(() => {
    // If the display value has stayed the same for a while, set it to match our input value now instead of waiting for onBlur.
    if (autoUpdate && debouncedDisplayValue !== value.toString()) {
      setDisplayValue(value.toString());
    }
  }, [debouncedDisplayValue]);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange(inputToNumber(event.target.value));
      if (hasFocus) {
        // Should always be true
        setDisplayValue(event.target.value);
      }
    },
    [onChange, hasFocus]
  );

  return (
    <InputContainer className={className} inputSuffix={inputSuffix} inputSuffixInline={inputSuffixInline} size={size}>
      <input
        id={id}
        disabled={disabled}
        type='number'
        inputMode={inputMode}
        pattern={inputMode === 'numeric' ? '\\d*' : '\\d*(\\.\\d*)?'}
        className={`length-input ${valid ? '' : 'invalid'} ${inputClassName}`}
        value={displayValue}
        onChange={handleInputChange}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
      />
    </InputContainer>
  );
};

export default NumericBlurInput;
