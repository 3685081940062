import React, { ReactNode, useContext } from 'react';

import { ButtonStrip } from '@gi/mobile-components';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import { DISPLAY_TYPE } from '../../constants';

import './mobile-plant-list-display-type-filter.scss';

interface iDisplayTypeButtonProps {
  displayType: DISPLAY_TYPE;
  activeDisplayType: DISPLAY_TYPE;
  setDisplayType: (displayType: DISPLAY_TYPE) => void;
  children: ReactNode;
}

const DisplayTypeButton = ({ displayType, activeDisplayType, setDisplayType, children }: iDisplayTypeButtonProps): JSX.Element => {
  return (
    <button
      className={`button button-mobile ${displayType === activeDisplayType ? 'active' : undefined}`}
      type='button'
      onClick={() => setDisplayType(displayType)}
    >
      {children}
    </button>
  );
};

const MobilePlantListDisplayTypeFilter = (): JSX.Element => {
  const { displayType, setDisplayType } = useContext(GlobalPlantListContext);

  return (
    <ButtonStrip className='mobile-plant-list-display-type' gap={6} endPadding={0} hideScrollbar>
      <DisplayTypeButton displayType={DISPLAY_TYPE.Variety} activeDisplayType={displayType} setDisplayType={setDisplayType}>
        Default
      </DisplayTypeButton>
      <DisplayTypeButton displayType={DISPLAY_TYPE.Summary} activeDisplayType={displayType} setDisplayType={setDisplayType}>
        Summary
      </DisplayTypeButton>
      <DisplayTypeButton displayType={DISPLAY_TYPE.Area} activeDisplayType={displayType} setDisplayType={setDisplayType}>
        Individual Plantings
      </DisplayTypeButton>
    </ButtonStrip>
  );
};

export default MobilePlantListDisplayTypeFilter;
