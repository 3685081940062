import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NavigationActionCreators, NavigationSelectors } from './navigation-slice';
import { getAbsoluteApplicationLink } from './navigation-context';

/**
 * React component to manage redirects as we can't do it directly in actions without a lot of extra work
 * involving browser history: https://stackoverflow.com/questions/69871987/react-router-v6-navigate-outside-of-components
 *
 * This needs to be placed in the tree somewhere under the navigation component
 */
const Navigator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateTo = useSelector(NavigationSelectors.getNavigateTo);

  useEffect(() => {
    if (navigateTo !== null) {
      navigate(getAbsoluteApplicationLink(navigateTo));
      dispatch(NavigationActionCreators.clearNavigateTo());
    }
  }, [navigateTo]);

  return null;
};

export default Navigator;
