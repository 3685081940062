import React from 'react';

import styles from './header.module.css';

interface iProps {
  className?: string;
  children: React.ReactNode;
}

const HeaderSubtitle = ({ className = '', children }: iProps) => {
  return <h2 className={`${styles.subtitle} ${className}`}>{children}</h2>;
};

export default HeaderSubtitle;
