import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Plant from '@gi/plant';
import { DEFAULT_VARIETY } from '@gi/constants';
import { EditPlantNotesActions } from '@gi/edit-plant-notes-modal';

import LocalPlantListContext from '../../local-provider/local-plant-list-context';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

import './plant-list-card-notes.scss';

interface iProps {
  plantNote: string;
  plant: Plant;
  varietyName: string;
}

const PlantListCardNotes = ({ plantNote, plant, varietyName }: iProps): JSX.Element | null => {
  const { planID } = useContext(LocalPlantListContext);
  const { columns } = useContext(GlobalPlantListContext);

  const dispatch = useDispatch();

  const plantNoteParagraphs = useMemo(() => {
    if (plantNote.trim().length === 0) {
      return null;
    }
    return plantNote.split(/(?:\r\n|\r|\n)/g).map((note, i) => {
      return <p key={i}>{note}</p>;
    });
  }, [plantNote]);

  const editNote = useCallback(() => {
    if (!planID) {
      return;
    }
    dispatch(EditPlantNotesActions.open({ planID, plantCode: plant.code, varietyName }));
  }, [planID, plant, varietyName]);

  const button = useMemo(() => {
    if (planID === null) {
      return null;
    }
    if (plantNote.length > 0) {
      return null;
    }
    return (
      <span className='plant-list-card-notes-empty'>
        <span>{'No notes for '}</span>
        <span>
          {plant.name} - {varietyName === DEFAULT_VARIETY ? 'Default Variety' : varietyName}
        </span>
      </span>
    );
  }, [planID, plantNote, varietyName]);

  const editButton = useMemo(() => {
    const text = plantNote.length > 0 ? 'Edit' : 'Add';
    return (
      <button type='button' className='edit-button' onClick={editNote}>
        {text} <i className='icon-edit' />
      </button>
    );
  }, [plantNote, editNote]);

  if (!planID || !columns.notes) {
    return null;
  }

  return (
    <div className='plant-list-card-notes'>
      <div className='plant-list-card-notes-topbar'>
        <span className='title'>Notes</span>
        {editButton}
      </div>
      <div className='plant-list-card-notes-content'>
        {plantNoteParagraphs}
        {button}
      </div>
    </div>
  );
};

export default PlantListCardNotes;
