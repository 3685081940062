import { GuruContentType, GuruPreviewItem } from '@gi/app-guru-types';
import React from 'react';
import HeroPreviewArticle from './items/hero-preview-article';
import HeroPreviewPest from './items/hero-preview-pest';
import HeroPreviewPlant from './items/hero-preview-plant';
import HeroPreviewVideo from './items/hero-preview-video';

interface iProps {
  previewItem: GuruPreviewItem;
  link: string;
}

const HeroPreviewItem = ({ previewItem, link }: iProps): JSX.Element | null => {
  switch (previewItem.type) {
    case GuruContentType.Article:
      return <HeroPreviewArticle previewArticle={previewItem} link={link} />;
    case GuruContentType.Plant:
      return <HeroPreviewPlant previewPlant={previewItem} link={link} />;
    case GuruContentType.Pest:
      return <HeroPreviewPest previewPest={previewItem} link={link} />;
    case GuruContentType.Video:
      return <HeroPreviewVideo previewVideo={previewItem} link={link} />;
    default:
      return null;
  }
};

export default HeroPreviewItem;
