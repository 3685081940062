import React from 'react';
import moment from 'moment-timezone';
import SuggestedArticle from '../suggested-article';

import './suggested-article-display.scss';

const RenderLoading = (): JSX.Element => {
  return (
    <li className='suggested-article-loading'>
      <div className='ph-avatar' />
      <div className='ph-row'>
        <div className='ph-col-12 big' />
        <div className='ph-col-8 ' />
      </div>
    </li>
  );
};

interface iProps {
  article?: SuggestedArticle;
}

const SuggestedArticleDisplay = ({ article }: iProps): JSX.Element => {
  if (!article) {
    return <RenderLoading />;
  }

  return (
    <li className='suggested-article'>
      <a rel='noopener noreferrer' target='_blank' href={article.contentURL}>
        <div className='article-image-container'>
          <div className='article-image' style={{ backgroundImage: `url(${article.keyImageURL}` }} />
        </div>
        <div className='article-details'>
          <p className='article-title'>{article.title}</p>
          <p className='article-data'>
            {moment(article.date).format('Do MMMM, YYYY')} &bull; {article.author}
          </p>
        </div>
      </a>
    </li>
  );
};

export default SuggestedArticleDisplay;
