import { batchActions } from 'redux-batched-actions';
import { CanvasActionCreators } from '@gi/react-garden-canvas';
import GardenObject from '@gi/garden-object';
import Plan from '@gi/plan';

import ModalActionTypes from './modal-action-types';
import ModalTypes from './modal-types';

export const openPublishPlanModal = () => {
  return {
    type: ModalActionTypes.OPEN_MODAL,
    modalType: ModalTypes.PUBLISH_PLAN_MODAL,
  };
};

export const closePlantInfoModal = () => {
  return {
    type: ModalActionTypes.CLOSE_PLANT_INFO_MODAL,
  };
};

export const openGardenObjectInfoModal = (gardenObject: GardenObject) => {
  return {
    type: ModalActionTypes.OPEN_GARDEN_OBJECT_INFO_MODAL,
    gardenObject,
  };
};

export const closeGardenObjectInfoModal = () => {
  return {
    type: ModalActionTypes.CLOSE_GARDEN_OBJECT_INFO_MODAL,
  };
};

export const openNewPlanModal = (activePlanID: number | null = null) => {
  return {
    type: ModalActionTypes.OPEN_NEW_PLAN_MODAL,
    newPlanModalActivePlan: activePlanID,
  };
};

export const closeNewPlanModal = () => {
  return {
    type: ModalActionTypes.CLOSE_NEW_PLAN_MODAL,
  };
};

export const closeModal = () => {
  return {
    type: ModalActionTypes.CLOSE_MODAL,
  };
};

export const editItemModalComplete = (plan: Plan) => {
  return (dispatch) => {
    dispatch(batchActions([CanvasActionCreators.updatePlan(plan), closeModal()]));
  };
};
