import { createContext } from 'react';

import { GuruHeader, GuruHeaderSlice } from './types';

export type GardenGuruContextType = {
  registerSlice: (slice: GuruHeaderSlice) => void;
  updateSlice: (slice: GuruHeaderSlice) => void;
  unregisterSlice: (sliceId: string) => void;
  header: GuruHeader;
  scrollPane: React.MutableRefObject<HTMLDivElement | null>;
  scrollTop: () => void;
};

const GardenGuruContext = createContext<GardenGuruContextType>({} as GardenGuruContextType);

export default GardenGuruContext;
