import React from 'react';

import styles from './product-gallery.module.css';

interface iProps {
  src: string;
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
}

const ProductImage = ({ src, onLoad }: iProps): JSX.Element => {
  return (
    <div className={styles.productImage}>
      <img src={src} alt='' onLoad={onLoad} />
    </div>
  );
};

export default ProductImage;
