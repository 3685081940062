import { produce } from 'immer';
import moment from 'moment-timezone';

import { JOURNAL_DATE_FORMAT, Reminder, ReminderData, Reminders } from './reminder';

export function createReminderData(): ReminderData {
  return {
    dateYMD: moment().format(JOURNAL_DATE_FORMAT),
    note: '',
    relatedPlants: [],
    dismissed: false,
  };
}

export function createInitialReminders(): Reminders {
  return {
    byDate: {},
    dates: [],
    byID: {},
    IDs: [],
  };
}

/**
 * Returns true if a reminder with the given ID is present in this collection, else false
 */
export function hasID(reminders: Reminders, ID: number): boolean {
  return reminders.IDs.includes(ID);
}

/**
 * Returns true if this set of reminders has entries for the given day
 */
export function hasDate(reminders: Reminders, dateYMD: string): boolean {
  return !!reminders.byDate[dateYMD];
}

/**
 * Returns an array of Reminders for the given day
 */
export function getRemindersForDate(reminders: Reminders, dateYMD: string): Reminder[] {
  if (!hasDate(reminders, dateYMD)) {
    return [];
  }

  return reminders.byDate[dateYMD].map((ID) => reminders.byID[ID]);
}

export function getNotDismissedReminderCountForDate(reminders: Reminders, dateYMD: string): number {
  if (!hasDate(reminders, dateYMD)) {
    return 0;
  }

  return reminders.byDate[dateYMD].map((ID) => reminders.byID[ID]).filter((reminder) => !reminder.dismissed).length;
}

export function getNotDismissedCount(reminders: Reminders) {
  return reminders.IDs.map((ID) => reminders.byID[ID]).filter((reminder) => !reminder.dismissed).length;
}

export function getCount(reminders: Reminders) {
  return reminders.IDs.length;
}

/**
 * Returns a new Reminders instance with this reminder added
 */
export function addReminders(reminders: Reminders, reminderList: Reminder[]): Reminders {
  return produce(reminders, (draft) => {
    for (let i = 0; i < reminderList.length; i++) {
      const reminder = reminderList[i];

      draft.IDs.push(reminder.ID);
      if (!draft.byDate[reminder.dateYMD]) {
        draft.byDate[reminder.dateYMD] = [];
        draft.dates.push(reminder.dateYMD);
      }
      draft.byDate[reminder.dateYMD].push(reminder.ID);
      draft.byID[reminder.ID] = reminder;
    }
  });
}

export function mutableAddReminders(reminders: Reminders, reminderList: Reminder[]): void {
  for (let i = 0; i < reminderList.length; i++) {
    const reminder = reminderList[i];

    reminders.IDs.push(reminder.ID);
    if (!reminders.byDate[reminder.dateYMD]) {
      reminders.byDate[reminder.dateYMD] = [];
      reminders.dates.push(reminder.dateYMD);
    }
    reminders.byDate[reminder.dateYMD].push(reminder.ID);
    reminders.byID[reminder.ID] = reminder;
  }
}

export function removeReminder(reminders: Reminders, ID: number): Reminders {
  if (!reminders.byID[ID]) {
    console.warn('Asked to remove reminder which does not exist');
    return reminders;
  }

  return produce(reminders, (draft) => {
    const reminder = draft.byID[ID];
    delete draft.byID[ID];

    draft.IDs.splice(draft.IDs.indexOf(ID), 1);

    draft.byDate[reminder.dateYMD].splice(draft.byDate[reminder.dateYMD].indexOf(ID), 1);

    if (draft.byDate[reminder.dateYMD].length === 0) {
      // All reminders for this date have been removed
      delete draft.byDate[reminder.dateYMD];
      draft.dates.splice(draft.dates.indexOf(reminder.dateYMD), 1);
    }
  });
}

export function mutableRemoveReminder(reminders: Reminders, ID: number): void {
  if (!reminders.byID[ID]) {
    console.warn('Asked to remove reminder which does not exist');
    return;
  }

  const reminder = reminders.byID[ID];
  delete reminders.byID[ID];

  reminders.IDs.splice(reminders.IDs.indexOf(ID), 1);

  reminders.byDate[reminder.dateYMD].splice(reminders.byDate[reminder.dateYMD].indexOf(ID), 1);

  if (reminders.byDate[reminder.dateYMD].length === 0) {
    // All reminders for this date have been removed
    delete reminders.byDate[reminder.dateYMD];
    reminders.dates.splice(reminders.dates.indexOf(reminder.dateYMD), 1);
  }
}

/**
 * Returns a new Reminders collection with the reminder added or updated if not already present
 */
export function updateReminder(reminders: Reminders, reminder: Reminder): Reminders {
  let updatedReminders = reminders;

  if (reminders.IDs.includes(reminder.ID)) {
    updatedReminders = removeReminder(reminders, reminder.ID);
  }

  return addReminders(updatedReminders, [reminder]);
}
