import React, { useEffect, useState } from 'react';
import Plant from '@gi/plant';
import { useDispatch, useSelector } from 'react-redux';
import { SessionActionCreators, SessionSelectors } from '@gi/react-session';
import { RequestSelectors } from '@gi/react-requests';

import './favourite-cell.scss';

interface iProps {
  plant: Plant;
}

const FavouriteCell = ({ plant }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const user = useSelector(SessionSelectors.getUser);

  const userSavingOrLoading = useSelector(RequestSelectors.userSaveOrLoad(user ? user.ID : -1));
  const isFavourite = user !== null && user.favouritePlants.has(plant.code);

  // Used to set a spinning effect when the favourite button is clicked without setting
  // All other buttons spinning
  const [spinning, setSpinning] = useState<boolean>(false);
  useEffect(() => {
    if (spinning && !userSavingOrLoading) {
      setSpinning(false);
    }
  }, [spinning, userSavingOrLoading]);

  const onClick = () => {
    if (user === null) {
      return;
    }

    const newFavourites = new Set(Array.from(user.favouritePlants));

    if (isFavourite) {
      newFavourites.delete(plant.code);
    } else {
      newFavourites.add(plant.code);
    }

    dispatch(SessionActionCreators.saveUserFavourites(user, newFavourites));
    setSpinning(true);
  };

  const iconClassName = `${spinning ? 'icon-spinner animate-pulse is-highlighted' : isFavourite ? 'icon-star is-highlighted' : 'icon-star-empty'} ${userSavingOrLoading ? 'disabled' : ''}`;

  return (
    <td className='favourite-td center-align-cell top-cell'>
      <button type='button' disabled={userSavingOrLoading} className='row-button plant-list-favourite-button' onClick={onClick}>
        <i className={iconClassName} />
      </button>
    </td>
  );
};

export default FavouriteCell;
