import { Action, createSlice, PayloadAction, ThunkAction } from '@reduxjs/toolkit';
import { LoadingState } from '@gi/constants';
import { AsyncOperation } from '@gi/utils';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';
import { IntercomCommandSideEffectInstruction, IntercomShortcutDefinitions } from './shortcuts/intercom-shortcut-types';
import { IntercomCommand, INTERCOM_ACTION } from './constants';

export type IntercomState = {
  queuedShortcuts: string[]; // Array of intercom commands to run when commands are loaded
  shortcutDefinitions: AsyncOperation<IntercomShortcutDefinitions>;
};

const DEFAULT_STATE: IntercomState = {
  queuedShortcuts: [],
  shortcutDefinitions: { status: LoadingState.NONE },
};

export const intercomSlice = createSlice({
  name: 'intercom',
  initialState: DEFAULT_STATE,
  reducers: {
    removeQueuedShortcuts: (state, action: PayloadAction<string[]>) => {
      if (action.payload.length === 0) {
        return;
      }

      state.queuedShortcuts = state.queuedShortcuts.filter((cmd) => !action.payload.includes(cmd));
    },
    queueIntercomShortcuts: (state, action: PayloadAction<string[]>) => {
      if (action.payload.length === 0) {
        return;
      }

      state.queuedShortcuts = [...state.queuedShortcuts, ...action.payload].filter((item) => item !== '');
    },
    setIntercomShortcutDefinitions: (state, action: PayloadAction<AsyncOperation<IntercomShortcutDefinitions>>) => {
      state.shortcutDefinitions = action.payload;
    },
  },
});

export interface iIntercomCommandAction {
  type: typeof INTERCOM_ACTION;
  payload: {
    command: IntercomCommand;
    id: number | null;
    sideEffects: IntercomCommandSideEffectInstruction[];
  };
}

export const intercomReducer = intercomSlice.reducer;
export const IntercomActionCreators = {
  ...intercomSlice.actions,
  createIntercomCommandAction: (
    command: IntercomCommand,
    id: number | null = null,
    sideEffects: IntercomCommandSideEffectInstruction[] = []
  ): iIntercomCommandAction => {
    return {
      type: INTERCOM_ACTION,
      payload: {
        command,
        id,
        sideEffects,
      },
    };
  },
  openIntercomWithAnalytics: (opener: string, command: IntercomCommand = 'show'): ThunkAction<void, any, never, Action<any>> => {
    return (dispatch) => {
      dispatch({
        type: INTERCOM_ACTION,
        payload: {
          command,
          id: null,
          sideEffects: [],
        },
      } as iIntercomCommandAction);
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.OpenIntercomWindow, { opener }));
    };
  },
};

export interface iIntercomRootState {
  intercom: IntercomState;
}

export const IntercomSelectors = {
  getQueuedShortcuts: (state: iIntercomRootState) => state.intercom.queuedShortcuts,
  getShortcutDefinitions: (state: iIntercomRootState) => state.intercom.shortcutDefinitions,
};
