export enum ModifierType {
  ColdFrame = 'COLD-FRAME',
  Cloche = 'CLOCHE',
  RowCover = 'ROW-COVER',
  Greenhouse = 'GREENHOUSE',
  HeatedGreenhouse = 'HEATED-GREENHOUSE',
  Polytunnel = 'POLYTUNNEL',
  HeatedPolytunnel = 'HEATED-POLYTUNNEL',
}

export const ModifierNames = {
  [ModifierType.ColdFrame]: 'Cold Frame',
  [ModifierType.Cloche]: 'Cloche',
  [ModifierType.RowCover]: 'Row Cover',
  [ModifierType.Greenhouse]: 'Greenhouse',
  [ModifierType.HeatedGreenhouse]: 'Heated Greenhouse',
  [ModifierType.Polytunnel]: 'Polytunnel',
  [ModifierType.HeatedPolytunnel]: 'Heated Polytunnel',
} as const;

export const ModifierTypeList = [
  ModifierType.ColdFrame,
  ModifierType.Cloche,
  ModifierType.RowCover,
  ModifierType.Greenhouse,
  ModifierType.HeatedGreenhouse,
  ModifierType.Polytunnel,
  ModifierType.HeatedPolytunnel,
] as const;
