import Plan, { PlanPlant, PlanPlantNotes, PlanPlantUtils, getPlanPlants, PlantNotesUtils } from '@gi/plan';
import Plant, { PlantUtils } from '@gi/plant';
import Collection from '@gi/collection';
import { UserPlantVarietySet, UserPlantVarietySetUtils } from '@gi/user';
import { DEFAULT_VARIETY, ModifierType } from '@gi/constants';

import { getModifierFromList, PlantingCalendar } from '@gi/planting-calendar';

import { ModifierCounts, PlantListAreaGroup, PlantListData, PlantListSummaryGroup, PlantListVarietyGroup } from '../types/plant-list-types';

function getModifierKey(planPlant: PlanPlant): string {
  const modifier = getModifierFromList(planPlant.modifiers);
  if (modifier === null) {
    return 'NONE';
  }

  return modifier;
}

function getTextFromPlantNotes(plantNotes: PlanPlantNotes, plantCode: string, variety: string): string {
  const plantNote = PlantNotesUtils.getPlantNote(plantNotes, plantCode, variety);

  if (plantNote === null) {
    return '';
  }

  return plantNote;
}

function createModifierCounts(): ModifierCounts {
  return {
    coldFrame: 0,
    cloche: 0,
    rowCover: 0,
    greenhouse: 0,
    heatedGreenhouse: 0,
    polytunnel: 0,
    heatedPolytunnel: 0,
    none: 0,
  };
}

// TODO: Change ModifierType to enum, change type to 'ModifierType | null'
function updateModifierCounts(modifierCounts: ModifierCounts, count: number, type: string | null) {
  if (type === ModifierType.ColdFrame) {
    modifierCounts.coldFrame += count;
  } else if (type === ModifierType.Cloche) {
    modifierCounts.cloche += count;
  } else if (type === ModifierType.RowCover) {
    modifierCounts.rowCover += count;
  } else if (type === ModifierType.Greenhouse) {
    modifierCounts.greenhouse += count;
  } else if (type === ModifierType.HeatedGreenhouse) {
    modifierCounts.heatedGreenhouse += count;
  } else if (type === ModifierType.Polytunnel) {
    modifierCounts.polytunnel += count;
  } else if (type === ModifierType.HeatedPolytunnel) {
    modifierCounts.heatedPolytunnel += count;
  } else {
    // Has to be null
    modifierCounts.none += count;
  }
}

export function createPlantListData(
  plan: Plan,
  plants: Collection<Plant>,
  userPlantVarieties: UserPlantVarietySet,
  getPlantingCalendar: (plantCode: string, modifier: null | string, varietyName: string) => PlantingCalendar | null
): PlantListData {
  console.debug('Creating plant list data');
  const summaryGroupData: { [plantCode: string]: PlantListSummaryGroup } = {};
  const varietyGroupData: {
    [plantCode: string]: { [variety: string]: { [modifierKey: string]: PlantListVarietyGroup } };
  } = {};
  const areaGroupData: PlantListAreaGroup[] = [];

  const planPlants: PlanPlant[] = getPlanPlants(plan);
  for (let i = 0; i < planPlants.length; i++) {
    const planPlant = planPlants[i];
    const plant = plants.get(planPlant.plantCode);

    if (plant !== null) {
      const defaultUserVariety = UserPlantVarietySetUtils.getByPlantCodeAndName(userPlantVarieties, plant.code, DEFAULT_VARIETY);
      const userPlantVariety = UserPlantVarietySetUtils.getByPlantCodeAndName(userPlantVarieties, plant.code, planPlant.variety);

      // Update group-data counts
      const modifierKey = getModifierKey(planPlant);
      const modifier = getModifierFromList(planPlant.modifiers);
      const counts = PlanPlantUtils.getPlanPlantCount(planPlant, plant, userPlantVariety);

      // Create new PlantRowListRowData for the given plant code if it doesn't exiset
      if (!summaryGroupData[plant.code]) {
        summaryGroupData[plant.code] = {
          plantName: plant.name,
          plant,
          spacing: PlantUtils.getSpacings(plant, defaultUserVariety),
          count: 0,
          countsByModifier: createModifierCounts(),
          countsByType: {
            area: 0,
            sfg: 0,
          },
          // varietyGroups: {},
          areas: [],
          plantNote: getTextFromPlantNotes(plan.plantNotes, plant.code, DEFAULT_VARIETY),
          plantingCalendar: getPlantingCalendar(plant.code, null, DEFAULT_VARIETY),
        };

        varietyGroupData[plant.code] = {};
      }

      if (!varietyGroupData[plant.code][planPlant.variety]) {
        varietyGroupData[plant.code][planPlant.variety] = {};
      }

      if (!varietyGroupData[plant.code][planPlant.variety][modifierKey]) {
        varietyGroupData[plant.code][planPlant.variety][modifierKey] = {
          modifier,
          plant,
          plantName: plant.name,
          variety: planPlant.variety,
          userVariety: userPlantVariety,
          spacing: PlantUtils.getSpacings(plant, userPlantVariety),
          count: 0,
          countsByType: {
            area: 0,
            sfg: 0,
          },
          areas: [],
          plantNote: getTextFromPlantNotes(plan.plantNotes, plant.code, planPlant.variety),
          plantingCalendar: getPlantingCalendar(plant.code, modifier, planPlant.variety),
        };
      }

      summaryGroupData[plant.code].count += counts.total;
      varietyGroupData[plant.code][planPlant.variety][modifierKey].count += counts.total;

      updateModifierCounts(summaryGroupData[plant.code].countsByModifier, counts.total, modifierKey);

      if (planPlant.isSquareFoot) {
        summaryGroupData[plant.code].countsByType.sfg += counts.total;
        varietyGroupData[plant.code][planPlant.variety][modifierKey].countsByType.sfg += counts.total;
      } else {
        summaryGroupData[plant.code].countsByType.area += counts.total;
        varietyGroupData[plant.code][planPlant.variety][modifierKey].countsByType.area += counts.total;
      }

      const areaData = {
        plant,
        plantName: plant.name,
        variety: planPlant.variety,
        planPlant,
        count: counts.total,
        countsByType: {
          area: planPlant.isSquareFoot ? 0 : counts.total,
          sfg: planPlant.isSquareFoot ? counts.total : 0,
        },
        spacing: PlantUtils.getSpacings(plant, userPlantVariety),
        modifier,
        plantingCalendar: getPlantingCalendar(plant.code, modifier, planPlant.variety),
      };

      summaryGroupData[plant.code].areas.push(areaData);
      varietyGroupData[plant.code][planPlant.variety][modifierKey].areas.push(areaData);
      areaGroupData.push(areaData);
    } else {
      console.debug('Plant not found in data: ', planPlants[i].plantCode);
    }
  }

  return {
    plants: Object.values(summaryGroupData),
    varieties: Object.values(varietyGroupData).flatMap((vgd) => Object.values(vgd).flatMap((vgdi) => Object.values(vgdi))),
    areas: areaGroupData,
  };
}
