import React, { useContext } from 'react';

import { PlannerControlsTab } from '@gi/constants';

import { PlannerControlsContext } from '../planner-controls-context';
import ObjectivesDrawer from './drawers/objectives-drawer';
import PlanBackgroundImageDrawer from './drawers/plan-background-image-drawer';

const MobilePlannerControls = (): JSX.Element => {
  const { activeDrawer, setActiveDrawer } = useContext(PlannerControlsContext);

  // Add other drawers here as-needed.

  return (
    <>
      <ObjectivesDrawer open={activeDrawer === PlannerControlsTab.OBJECTIVES} onClose={() => setActiveDrawer(null)} />
      <PlanBackgroundImageDrawer open={activeDrawer === PlannerControlsTab.BACKGROUND_IMAGE} onClose={() => setActiveDrawer(null)} />
    </>
  );
};

export default MobilePlannerControls;
