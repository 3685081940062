import React, { useCallback, useMemo, useRef, useState } from 'react';

import Modal, {
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalHeader,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalPane,
  ModalPaneContainer,
} from '@gi/modal';

import styles from './mobile-introduction-tutorial.module.css';

enum PageType {
  Intro,
  Video,
  Outro,
}

type PageDefinition =
  | {
      type: PageType.Intro | PageType.Outro;
    }
  | {
      type: PageType.Video;
      videoId: number;
    };

const PAGES: PageDefinition[] = [
  { type: PageType.Intro },
  { type: PageType.Video, videoId: 917515841 },
  { type: PageType.Video, videoId: 917858124 },
  { type: PageType.Video, videoId: 917857951 },
  { type: PageType.Video, videoId: 894043442 },
  { type: PageType.Outro },
];

interface iProps {
  onClose: ({ pageIndex, time }: { pageIndex: number; time: number }) => void;
}

const MobileIntroductionTutorialModal = ({ onClose }: iProps): JSX.Element => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const startTime = useRef(Date.now());

  const callOnClose = useCallback(() => {
    onClose({ pageIndex, time: Date.now() - startTime.current });
  }, [onClose, pageIndex]);

  const previous = useCallback(() => {
    if (pageIndex <= 0) {
      return;
    }
    setPageIndex(pageIndex - 1);
  }, [pageIndex]);

  const next = useCallback(() => {
    if (pageIndex >= PAGES.length - 1) {
      return;
    }
    setPageIndex(pageIndex + 1);
  }, [pageIndex]);

  const content = useMemo(() => {
    const page = PAGES[pageIndex];
    if (!page) {
      return null;
    }
    switch (page.type) {
      case PageType.Intro:
        return (
          <div className={styles.tutorialTextContainer}>
            <div className={styles.tutorialText}>
              <h3>Welcome to the Garden Planner</h3>
              <p>These short videos will show you how to use and make the most of the Garden Planner on your device.</p>
              <p>You can always access these videos again from the &quot;Help&quot; dropdown in the top-left of the screen.</p>
            </div>
          </div>
        );
      case PageType.Outro:
        return (
          <div className={styles.tutorialTextContainer}>
            <div className={styles.tutorialText}>
              <h3>You&apos;re ready to start planning!</h3>
              <p>
                If you need any help, or have any further questions, chat with our gardening experts by clicking the &quot;Help&quot; button in the top-left.
              </p>
              <p>You can also access these videos again from the top-left.</p>
            </div>
          </div>
        );
      case PageType.Video:
        return (
          <div className={styles.videoContainer}>
            <div className={styles.videoContainerInner}>
              <iframe
                src={`https://player.vimeo.com/video/${page.videoId}?badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479`}
                frameBorder='0'
                allow='autoplay, fullscreen, picture-in-picture'
                title='How to use the Garden Planner'
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: ' 100%' }}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [pageIndex]);

  const previousButton = useMemo(() => {
    if (pageIndex === 0) {
      return (
        <button type='button' className='button button-secondary' onClick={callOnClose}>
          Skip
        </button>
      );
    }
    return (
      <button type='button' className='button button-secondary' onClick={previous}>
        <i className='icon icon-angle-left' /> Back
      </button>
    );
  }, [pageIndex, previous]);

  const nextButton = useMemo(() => {
    if (pageIndex >= PAGES.length - 1) {
      return (
        <button type='button' className='button button-primary' onClick={callOnClose}>
          Done
        </button>
      );
    }
    return (
      <button type='button' className='button button-primary' onClick={next}>
        Next <i className='icon icon-angle-right' />
      </button>
    );
  }, [pageIndex, next, onClose]);

  return (
    <Modal className={styles.modal} style={{ margin: 0 }} closeRequest={() => {}} /* Don't close on background click */>
      <ModalContent>
        <ModalCloseButton onClick={callOnClose} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Introduction</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPane className={styles.modalPane}>
          <ModalPaneContainer className={styles.modalPanelContainer}>{content}</ModalPaneContainer>
        </ModalPane>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>{previousButton}</ModalFooterButtonsSection>
            <span className={styles.progressCount}>
              Page {pageIndex + 1}/{PAGES.length}
            </span>
            <ModalFooterButtonsSection>{nextButton}</ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MobileIntroductionTutorialModal;
