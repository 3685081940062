import React from 'react';

interface iProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
}

const ModalPaneContainer = ({ style = {}, children, className = '' }: iProps) => {
  return (
    <div style={style} className={`modal-pane-container ${className}`}>
      {children}
    </div>
  );
};

export default ModalPaneContainer;
