import EventBus from '../../event-bus';
import { AssetType, AssetTypeMapping } from '../../managers/assets/types';
import NodeComponent, { NodeComponentEvent, NodeComponentEventActions } from '../../node-component/node-component';
import { InspectableClassData } from '../../types';

export enum AssetsComponentEvents {
  Loaded = 'Ready',
  LoadError = 'LoadError',
}

export type AssetsComponentEventsActions<T extends AssetType> = NodeComponentEventActions & {
  [AssetsComponentEvents.Loaded]: (assets: AssetTypeMapping[T][], assetDict: Record<string, AssetTypeMapping[T]>) => void;
  [AssetsComponentEvents.LoadError]: (error: Error) => void;
};

class AssetsComponent<T extends AssetType> extends NodeComponent {
  type = 'AssetsComponent';
  eventBus: EventBus<AssetsComponentEventsActions<T>> = new EventBus(this.eventBus);

  #assetNames: string[] = [];
  #assetType: T;
  #assetsDict: Record<string, AssetTypeMapping[T]> = {};
  #assets: AssetTypeMapping[T][] = [];

  #cancelCallback?: (() => void) | null = null;

  constructor(type: T, assetNames: string[] = []) {
    super();
    this.#assetType = type;
    this.#assetNames = assetNames;

    this.eventBus.on(NodeComponentEvent.DidBind, () => {
      this.#tryLoadAssets();
    });
  }

  get assets() {
    return this.#assets;
  }

  loadAssets(assetNames: string[]) {
    this.#assetNames = assetNames;
    this.#tryLoadAssets();
  }

  getAsset(assetName: string): AssetTypeMapping[T] | null {
    if (this.#assetsDict[assetName]) {
      return this.#assetsDict[assetName];
    }
    return null;
  }

  #tryLoadAssets() {
    // Cancel any ongoing loading
    if (this.#cancelCallback) {
      this.#cancelCallback();
    }

    // Fail if we have no way of loading assets
    if (!this.bound || !this.owner?.engine || this.#assetNames.length === 0) {
      return;
    }
    const { engine } = this.owner;

    // Set up cancel callback
    let cancelled = false;
    this.#cancelCallback = () => {
      cancelled = true;
    };
    this.#assetsDict = {};
    this.#assets = [];

    // Set up all the promises
    const promises = this.#assetNames.map(
      (assetName) =>
        new Promise<AssetTypeMapping[T]>((resolve, reject) => {
          engine.assetManager
            .getAsset<T>(this.#assetType, assetName, { waitIfUndefined: true, ignoreErrors: true })
            .then((asset) => {
              if (cancelled) {
                return;
              }
              this.#assetsDict[assetName] = asset;
              resolve(asset);
            })
            .catch((err) => {
              reject(err);
            });
        })
    );

    // Run all the promises
    Promise.all(promises)
      .then((assets) => {
        if (cancelled) {
          return;
        }
        this.#assets = assets;
        this.eventBus.emit(AssetsComponentEvents.Loaded, assets, this.#assetsDict);
        this.#triggerRerender();
      })
      .catch((err) => {
        this.eventBus.emit(AssetsComponentEvents.LoadError, err);
        this.#triggerRerender();
      });
  }

  #triggerRerender() {
    if (this.owner && this.owner.engine) {
      this.owner.engine.flagHasUpdates();
    }
  }

  inspectorData: InspectableClassData<this> = [];
}

export default AssetsComponent;
