import React, { useContext, useMemo } from 'react';

import { PlanClockwiseRotationAmount } from '@gi/plan';

import { PlanSize } from '../utils';
import { PlanDiagramSVGContext } from '../plan-diagram-svg';

import styles from './svg-element-styles.module.css';

interface iProps {
  plan: PlanSize;
  rotation: PlanClockwiseRotationAmount | false;
}

const DirectionIndicatorSVGElement = ({ plan, rotation }: iProps): JSX.Element => {
  const { scale, offset } = useContext(PlanDiagramSVGContext);

  const transform = useMemo(() => {
    const minX = plan.x * scale + offset.x;
    const maxX = (plan.x + plan.width) * scale + offset.x;
    const midX = minX + (maxX - minX) / 2;

    const minY = plan.y * scale + offset.y;
    const maxY = (plan.y + plan.height) * scale + offset.y;
    const midY = minY + (maxY - minY) / 2;

    switch (rotation) {
      case PlanClockwiseRotationAmount.Rotate90:
        return `translate(${maxX}, ${midY}), rotate(90)`;
      case PlanClockwiseRotationAmount.Rotate180:
        return `translate(${midX}, ${maxY}), rotate(180)`;
      case PlanClockwiseRotationAmount.Rotate270:
        return `translate(${minX}, ${midY}), rotate(270)`;
      case false:
      default:
        return `translate(${midX}, ${minY})`;
    }
  }, [plan, rotation, scale, offset]);

  return (
    <g transform={transform} className={styles.directionIndicatorContainer}>
      <path d='M 0 0 L 10 10 L -10 10 L 0 0' className={styles.directionIndicator} />
      <text x={0} y={12} className={styles.directionIndicatorText}>
        TOP
      </text>
    </g>
  );
};

export default DirectionIndicatorSVGElement;
