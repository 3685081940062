import { LoadingState } from '@gi/constants';
import { GardenPlatformEvent, GardenPlatformEventParameters } from '@gi/garden-platform-events';

// Unique name that won't clash with any other objective group name
export const ADVANCED_ACCORDION_ID = '!advanced-dropdown!';

//
// API TYPES
//

export type ObjectiveConditionFromAPI = {
  eventName: string;
  quantityRequired: number;
  parameters?: Record<string, any>;
};

export type ObjectiveFromAPI = {
  id: string;
  title: string;
  description: string;
  icon?: string;
  tutorialName?: string;
  conditions: ObjectiveConditionFromAPI[];
};

export type CompletedObjectiveFromAPI = {
  id: string;
  date: string; // ISO 8601 string format
};

export type ObjectiveGroupFromAPI = {
  id: string;
  title: string;
  description: string;
  icon?: string;
  objectiveIds: string[];
  isAdvanced?: boolean;
};

export type ObjectiveSettingsFromAPI = {
  showOverallProgress: boolean;
  overallProgressTitle?: string;
  overallProgressDescription?: string;
  showHelpSection: boolean;
  showHelpSectionLiveChatButton?: boolean;
  showHelpSectionVideoGuideButton?: boolean;
  videoGuideButtonText?: string;
  videoGuideButtonTutorialName?: string;
  helpSectionTitle?: string;
  helpSectionDescription?: string;
};

export type ObjectiveDataFromAPI = {
  objectives: ObjectiveFromAPI[];
  groups: ObjectiveGroupFromAPI[];
  settings: ObjectiveSettingsFromAPI;
};

//
// PARSED TYPES
//

export type Objective = {
  id: string;
  title: string;
  description: string;
  icon?: string;
  tutorialName?: string;
  conditionIds: string[];
};

export type ObjectiveCondition<T extends GardenPlatformEvent> = {
  id: string;
  objectiveId: string;
  eventName: T;
  parameters?: Partial<GardenPlatformEventParameters[T]>;
  quantityRequired: number;
  quantityTracked: number;
  completed: boolean;
};

export type ObjectiveCompletionData = {
  id: string;
  date: number;
  syncState: LoadingState;
};

export type ObjectiveGroup = {
  id: string;
  title: string;
  description: string;
  icon?: string;
  objectiveIds: string[];
  isAdvanced?: boolean;
};

export type ObjectiveSettings = {
  showOverallProgress: boolean;
  overallProgressTitle?: string;
  overallProgressDescription?: string;
  showHelpSection: boolean;
  showHelpSectionLiveChatButton?: boolean;
  showHelpSectionVideoGuideButton?: boolean;
  videoGuideButtonText?: string;
  videoGuideButtonTutorialName?: string;
  helpSectionTitle?: string;
  helpSectionDescription?: string;
};
