import React from 'react';
import networkConfig from '@gi/config/network-config';

interface iProps {
  className?: string;
  code: string;
  artifactCode: string;
}

const PrintablePlannerIcon = ({ className = '', code, artifactCode }: iProps): JSX.Element => {
  return <img alt='' src={`${networkConfig.iconURLS[artifactCode]}${code}-icon.png`} className={`${className} printable-planner-icon`} />;
};

export default PrintablePlannerIcon;
