import React, { ReactNode, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useTutorial } from '@gi/tutorial';
import { IntercomActionCreators } from '@gi/intercom';

import styles from './overview-styles.module.css';

interface iProps {
  children?: ReactNode;
  title?: string;
  description?: string;
  hideChatButton?: boolean;
  hideVideoGuideButton?: boolean;
  videoGuideButtonText?: string;
  videoGuideTutorialName?: string;
  onLaunchHelp?: () => void;
}

const ObjectiveHelpCard = ({
  children,
  title,
  description,
  hideChatButton = false,
  hideVideoGuideButton = false,
  videoGuideButtonText = 'Video Guide',
  videoGuideTutorialName,
  onLaunchHelp,
}: iProps): JSX.Element => {
  const tutorial = useTutorial({ tutorialName: videoGuideTutorialName });

  const dispatch = useDispatch();

  const openChat = useCallback(() => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('objectives'));
    onLaunchHelp?.();
  }, [onLaunchHelp]);

  const openVideoGuide = useCallback(() => {
    if (tutorial && !tutorial.isRunning) {
      tutorial.start();
    }
    onLaunchHelp?.();
  }, [onLaunchHelp, tutorial]);

  return (
    <div className={styles.helpCard}>
      {title ? (
        <h5>
          <i className='icon-mortar-board' /> {title}
        </h5>
      ) : null}
      {description ? <p>{description}</p> : null}
      {children}
      <div className={styles.helpCardButtons}>
        {hideChatButton ? null : (
          <button type='button' className={styles.openChatButton} onClick={openChat}>
            <i className='icon-chat-bubble' /> Live Chat
          </button>
        )}
        {hideVideoGuideButton || !tutorial.tutorial ? null : (
          <button type='button' className={styles.openChatButton} onClick={openVideoGuide} disabled={tutorial.isRunning}>
            <i className='icon-play' /> {videoGuideButtonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default ObjectiveHelpCard;
