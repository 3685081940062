import { SimulatedHistoricalPlanEvent, SimulatedHistoricalPlan } from '../simulation/simulated-historical-plan';
import CanvasItem from './canvas-item';
import CanvasLayers from './canvas-layers';
import CropRotationHistoryNode from './nodes/crop-rotation/crop-rotation-history-node';

class CanvasHistoricalPlan extends CanvasItem {
  readonly #cropRotationNode: CropRotationHistoryNode;
  readonly #simulatedHistoricalPlan: SimulatedHistoricalPlan;
  readonly #canvasLayers: CanvasLayers;

  constructor(simulatedHistoricalPlan: SimulatedHistoricalPlan, canvasLayers: CanvasLayers) {
    super();

    this.#simulatedHistoricalPlan = simulatedHistoricalPlan;
    this.#canvasLayers = canvasLayers;

    this.#cropRotationNode = new CropRotationHistoryNode(
      simulatedHistoricalPlan.age,
      simulatedHistoricalPlan.plantIds.map((id) => simulatedHistoricalPlan.plants[id])
    );

    this.nodes.push(this.#cropRotationNode);

    this.#canvasLayers.cropRotationHistoryLayer.addChildren(this.#cropRotationNode);

    this.#simulatedHistoricalPlan.on(SimulatedHistoricalPlanEvent.DidUpdate, this.#historicalPlanUpdated);
  }

  #historicalPlanUpdated = () => {
    this.#cropRotationNode.setAge(this.#simulatedHistoricalPlan.age);
    this.#cropRotationNode.setPlants(this.#simulatedHistoricalPlan.plantIds.map((id) => this.#simulatedHistoricalPlan.plants[id]));
  };
}

export default CanvasHistoricalPlan;
