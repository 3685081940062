import React from 'react';
import { TutorialParseError } from '../tutorial-editor-types';

import styles from './json-input.module.css';

interface iProps {
  err: TutorialParseError;
}

const JSONInputError = ({ err }: iProps): JSX.Element => {
  return (
    <div className={styles.editorError}>
      <div className={styles.editorErrorMessage}>{err.message}</div>
      {err.path ? <div className={styles.editorErrorPath}>{err.path}</div> : null}
    </div>
  );
};

export default JSONInputError;
