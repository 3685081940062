import React, { useState, useEffect, Fragment, useContext } from 'react';
import { useDispatch } from 'react-redux';

import Plan from '@gi/plan';
import { networkConfig } from '@gi/config';
import { AppContext } from '@gi/app-provider';

import {
  ModalPane,
  ModalPaneSectionGroup,
  ModalPaneContainer,
  ModalPaneSection,
  ModalPaneSectionContent,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
} from '@gi/modal';

import PublishPlanSharePlansWarning from './publish-plan-share-plans-warning';
import PublishPlanStages from '../publish-plan-stages';
import { sharePlans } from '../publish-action-creators';

interface iProps {
  stage: PublishPlanStages;
  plan: Plan;
  closeModal: () => void;
}

const PublishModalStage3 = ({ stage, plan, closeModal }: iProps): JSX.Element => {
  const { runtimeConfig } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const onSharePlans = () => {
    dispatch(sharePlans());
  };

  useEffect(() => {
    let timer;

    if (stage === PublishPlanStages.COMPLETE) {
      timer = setTimeout(() => setLoading(false), 2000);
    }

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      clearTimeout(timer);
    };
  }, [stage]);

  return (
    <>
      <ModalPaneContainer className='publish-plan-stage-3'>
        <ModalPane>
          <ModalPaneSectionGroup>
            <ModalPaneSection>
              <ModalPaneSectionContent>
                <div className='publish-plan-complete'>Publish Plan Complete</div>
                <div className='published-plan-output'>
                  <div className='published-plan-text'>
                    <p>
                      Your plan is available on the following webpage:{' '}
                      <a
                        target='published_plan'
                        rel='noreferrer'
                        href={`${runtimeConfig.publishPlanURL}${plan.id}`}
                      >{`${runtimeConfig.publishPlanURL}${plan.id}`}</a>
                    </p>
                    <p>Click the link to open the page in a new browser window. You can then share it on social media or email it to others.</p>
                  </div>
                  <div className='published-plan-thumbnail'>
                    {loading ? (
                      <div className='publish-thumbnail-loader'>
                        <i className='icon-spinner animate-pulse' />
                      </div>
                    ) : (
                      <a target='published_plan' rel='noreferrer' href={`${runtimeConfig.publishPlanURL}${plan.id}`}>
                        <img src={`${networkConfig.thumbnailURL}${plan.id}.jpg?time=${new Date().getTime()}`} alt='' />
                      </a>
                    )}
                  </div>
                </div>
              </ModalPaneSectionContent>
            </ModalPaneSection>
            <PublishPlanSharePlansWarning onSharePlans={onSharePlans} />
          </ModalPaneSectionGroup>
        </ModalPane>
      </ModalPaneContainer>
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-primary' onClick={closeModal}>
              Close &amp; Continue
            </button>
          </ModalFooterButtonsSection>
        </ModalFooterButtons>
      </ModalFooter>
    </>
  );
};

export default PublishModalStage3;
