import React from 'react';
import RoundButton from './round-button';

interface iProps {
  onClick: () => void;
  className?: string;
}

const HelpButton = ({ onClick, className }: iProps): JSX.Element => {
  return (
    <RoundButton className={className} ariaLabel='Help' onClick={onClick}>
      <i className='icon-help-circled' />
    </RoundButton>
  );
};

export default HelpButton;
