import { Geometry } from '@gi/math';
import { ShapeCollisionCheckFunction } from './types';

/**
 * Checks which side of a line the given point falls on.
 * - clockwise ("right") side if 1
 * - anticlockwise ("left") side if -1
 * - on the line if 0
 */
function checkSideOfLine(point: Vector2, lineStart: Vector2, lineEnd: Vector2): number {
  return Math.sign(Geometry.crossProduct(Geometry.getPointDelta(lineStart, lineEnd), Geometry.getPointDelta(lineStart, point)));
}

/** Do the 2 numbers have opposite signs. (e.g. a > 0 & b < 0 etc.) */
function areOppositeSigns(numberA: number, numberB: number): boolean {
  return (numberA < 0 && numberB > 0) || (numberA > 0 && numberB < 0);
}

/** Do the 2 lines intersect? */
function checkLineIntersection(lineAStart: Vector2, lineAEnd: Vector2, lineBStart: Vector2, lineBEnd: Vector2): boolean {
  const lineBStartSide = Geometry.crossProduct(Geometry.getPointDelta(lineAStart, lineAEnd), Geometry.getPointDelta(lineAStart, lineBStart));
  const lineBEndSide = Geometry.crossProduct(Geometry.getPointDelta(lineAStart, lineAEnd), Geometry.getPointDelta(lineAStart, lineBEnd));
  const lineAStartSide = Geometry.crossProduct(Geometry.getPointDelta(lineBStart, lineBEnd), Geometry.getPointDelta(lineBStart, lineAStart));
  const lineAEndSide = Geometry.crossProduct(Geometry.getPointDelta(lineBStart, lineBEnd), Geometry.getPointDelta(lineBStart, lineAEnd));

  if (areOppositeSigns(lineBStartSide, lineBEndSide) && areOppositeSigns(lineAStartSide, lineAEndSide)) {
    return true;
  }

  // TODO: Edge cases for parallel lines? Doesn't matter too much for our use-case
  return false;
}

/** Is the given point contained within the box formed by the min and max */
function containsPoint(point: Vector2, min: Vector2, max: Vector2): boolean {
  return point.x >= min.x && point.x <= max.x && point.y >= min.y && point.y <= max.y;
}

/**
 * Use only the shape bounding box for collision checks. As this is checked before this function is called, no need to check again, return true.
 */
const BoundingBox: ShapeCollisionCheckFunction = () => true;

/**
 * Assume the shape is a convex hull, with points in clockwise order. Accurately check if AABB touches any part of the shape
 */
const ConvexHull: ShapeCollisionCheckFunction = (min, max, { points, position }) => {
  // Not a polygon
  if (points.length <= 2) {
    return false;
  }
  // Likely to be more efficient to apply the transformation to the AAB
  const tl: Vector2 = Geometry.minusPoint(min, position);
  const tr: Vector2 = Geometry.minusPoint({ x: max.x, y: min.y }, position);
  const bl: Vector2 = Geometry.minusPoint({ x: min.x, y: max.y }, position);
  const br: Vector2 = Geometry.minusPoint(max, position);
  // Loop through all edges making up the polygon
  for (let n = 0; n <= points.length - 1; n++) {
    const p1 = points[n];
    const p2 = points[(n + 1) % points.length];

    const result = checkSideOfLine(tl, p1, p2) + checkSideOfLine(tr, p1, p2) + checkSideOfLine(bl, p1, p2) + checkSideOfLine(br, p1, p2);
    if (result === -4) {
      // All 4 points fall on the same side of the line. -4 falls outside using our point winding.
      return false;
    }
  }
  return true;
};

/**
 * Assume the shape is a convex hull, with points in a clockwise order. Also assumes the shape is hollow, with an inner thickness of borderSize.
 * Accurately check if the AABB touches and part of the shape, and is not fully enclosed by the hollow part of the shape.
 */
const HollowConvexHull =
  (borderSize: number): ShapeCollisionCheckFunction =>
  (min, max, shape) => {
    const { points, position } = shape;
    // Not a polygon
    if (points.length <= 2) {
      return false;
    }
    // First check that we're actually colliding
    if (!ConvexHull(min, max, shape)) {
      return false;
    }
    // We know the AABB is colliding with this shape at this point, now determine if the shape fully contains the AABB.
    // If it does, we can fail the collision check, as this shape is hollow.
    // Account for border size by shrinking the AABB.
    const tl: Vector2 = Geometry.addPoint(Geometry.minusPoint(min, position), { x: -borderSize, y: -borderSize });
    const tr: Vector2 = Geometry.addPoint(Geometry.minusPoint({ x: max.x, y: min.y }, position), { x: borderSize, y: -borderSize });
    const bl: Vector2 = Geometry.addPoint(Geometry.minusPoint({ x: min.x, y: max.y }, position), { x: -borderSize, y: borderSize });
    const br: Vector2 = Geometry.addPoint(Geometry.minusPoint(max, position), { x: borderSize, y: borderSize });
    // Loop through all edges making up the polygon
    for (let n = 0; n <= points.length - 1; n++) {
      const p1 = points[n];
      const p2 = points[(n + 1) % points.length];

      const result = checkSideOfLine(tl, p1, p2) + checkSideOfLine(tr, p1, p2) + checkSideOfLine(bl, p1, p2) + checkSideOfLine(br, p1, p2);
      if (result !== 4) {
        // All 4 points are not on the same side of the line, so we can't possibly fully contain the box. Definitely colliding.
        return true;
      }
    }
    return false;
  };

/**
 * Assume the shape is a path, with points from start to end. Assume the path has a uniform thickness.
 * Accurately check if the AABB touches the path.
 * This isn't a perfect representation of our paths, as it grows the ends of the path by the thickness as well.
 */
const Path =
  (thickness: number): ShapeCollisionCheckFunction =>
  (min, max, shape) => {
    const { points, position } = shape;
    // Can't make a path with 0/1 points
    if (points.length < 2) {
      return false;
    }
    // Transform the AABB to be relative to the points, and grow it by the thickness of the path.
    // This gives a good enough representation of the path without having to build a bunch of convex hulls.
    const tl: Vector2 = Geometry.addPoint(Geometry.minusPoint(min, position), { x: -thickness, y: -thickness });
    const tr: Vector2 = Geometry.addPoint(Geometry.minusPoint({ x: max.x, y: min.y }, position), { x: thickness, y: -thickness });
    const bl: Vector2 = Geometry.addPoint(Geometry.minusPoint({ x: min.x, y: max.y }, position), { x: -thickness, y: thickness });
    const br: Vector2 = Geometry.addPoint(Geometry.minusPoint(max, position), { x: thickness, y: thickness });
    // Loop through all segments of the line
    for (let n = 0; n < points.length - 1; n++) {
      const p1 = points[n];
      const p2 = points[n + 1];
      // If any of the points of the path are within the AABB, we're definitely colliding
      if (containsPoint(p1, tl, br) || containsPoint(p2, tl, br)) {
        return true;
      }
      // If a section of the path collides with the edge of the AABB, we're colliding
      if (
        checkLineIntersection(p1, p2, tl, tr) ||
        checkLineIntersection(p1, p2, tr, br) ||
        checkLineIntersection(p1, p2, br, bl) ||
        checkLineIntersection(p1, p2, bl, tl)
      ) {
        return true;
      }
    }
    return false;
  };

export const ShapeCollisionCheckFunctions = {
  BoundingBox,
  ConvexHull,
  HollowConvexHull,
  Path,
};
