import React from 'react';
import MenuBar from '../common/menu-bar';
import MenuBarGroup from '../common/menu-bar-group';

import PartsListControls from './parts-list-controls';

const DesktopPartsListMenu = (): JSX.Element => {
  return (
    <MenuBar>
      <MenuBarGroup title='Parts List'>
        <PartsListControls />
      </MenuBarGroup>
    </MenuBar>
  );
};

export default DesktopPartsListMenu;
