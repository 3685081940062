import { Plan, PlanSet, PlanUndoStack, PlanSetUtils, PlanUndo, PlanUtils, APIPlanDocumentInvalidProperties } from '@gi/plan';
import { TransferPlanActionTypes } from '@gi/react-transfer-plan';
import appConfig from '@gi/config/app-config';
import {
  CanvasInteractionGroup,
  DrawGardenObjectToolState,
  DrawPlantToolState,
  DrawShapeToolState,
  DrawTextToolState,
  SerialisedCanvasInteractionGroup,
} from '@gi/plan-simulation';
import { ExtendedAsyncOperation } from '@gi/utils';
import { LoadingState } from '@gi/constants';

import CanvasActionTypes from './canvas-action-types';
import GardenCanvasEventActionTypes from './garden-canvas-event-action-types';

import * as CanvasInteractionStates from './canvas-interaction-states';

export type CanvasInteractionState =
  | CanvasInteractionStates.Null
  | DrawGardenObjectToolState
  | DrawPlantToolState
  | DrawShapeToolState
  | DrawTextToolState
  | CanvasInteractionStates.Resize
  | CanvasInteractionStates.Other;

export type iPlanDataError = {
  id: number;
  uploaded: boolean;
  plan: Plan;
  invalidProperties: APIPlanDocumentInvalidProperties;
};

export type TexturesAsyncOperation = ExtendedAsyncOperation<void, Error, { progress: number }>;

export type CanvasReducerState = {
  allowSave: boolean;
  activePlanID: null | number;
  openPlanIDs: number[]; // [number] {planID}
  loadingPlanIDs: number[]; // [number] {planID}
  plans: PlanSet; // Plan set with all latest updated data
  loadedPlans: PlanSet; // Plan set with plan data which was last loaded from a server
  lastSavePlans: PlanSet;
  failedToLoadPlans: Record<number, number>; // Map<number, number> {planID -> retryCount}
  interactionState: CanvasInteractionState;
  undoStacks: Record<number, PlanUndoStack>; // Map<number, UndoStack>
  selectedItems: SerialisedCanvasInteractionGroup;
  texturesStatus: TexturesAsyncOperation;
  hasClipboard: boolean;
  confirmClosePlans: number[];
  planDataErrors: iPlanDataError[];
  showContextMenuAt: false | Vector2; // false to not show, Vector2 to display the context menu at that world position on the plan
};

const INITIAL_STATE: CanvasReducerState = {
  allowSave: appConfig.allowPlanSaving,
  activePlanID: null, // number {planID}
  openPlanIDs: [], // [number] {planID}
  loadingPlanIDs: [], // [number] {planID}
  plans: PlanSetUtils.createPlanSet(), // Plan set with all latest updated data
  loadedPlans: PlanSetUtils.createPlanSet(), // Plan set with plan data which was last loaded from a server
  lastSavePlans: PlanSetUtils.createPlanSet(),
  failedToLoadPlans: {}, // Map<number, number> {planID -> retryCount}
  interactionState: { type: null },
  undoStacks: {}, // Map<number, UndoStack>
  selectedItems: new CanvasInteractionGroup().serialise(),
  texturesStatus: { status: LoadingState.NONE },
  hasClipboard: false,
  confirmClosePlans: [],
  planDataErrors: [],
  showContextMenuAt: false,
};

const undo = (state: CanvasReducerState) => {
  if (state.activePlanID === null) {
    console.warn('Attempted to undo but active plan ID is null');
    return state;
  }

  if (!PlanSetUtils.planSetHasPlan(state.plans, state.activePlanID)) {
    console.warn('Attempted to undo but active plan is not present');
    return state;
  }

  if (!state.undoStacks[state.activePlanID]) {
    console.warn("Attempted to undo but there's no undo stack history for the plan ID", state.activePlanID);
    return state;
  }

  const undoStack = state.undoStacks[state.activePlanID];
  const activePlan = PlanSetUtils.planSetGetPlan(state.plans, state.activePlanID);

  const [updatedPlan, updatedUndoStack] = PlanUndo.undo(activePlan!, undoStack);

  const undoStacks = {
    ...state.undoStacks,
    [state.activePlanID]: updatedUndoStack,
  };

  return {
    ...state,
    plans: PlanSetUtils.planSetUpdatePlan(state.plans, updatedPlan),
    undoStacks,
  };
};

const redo = (state: CanvasReducerState) => {
  if (state.activePlanID === null) {
    console.warn('Attempted to redo but active plan ID is null');
    return state;
  }

  if (!PlanSetUtils.planSetHasPlan(state.plans, state.activePlanID)) {
    console.warn('Attempted to redo but active plan is not present');
    return state;
  }

  if (!state.undoStacks[state.activePlanID]) {
    console.warn("Attempted to redo but there's no undo stack history for the plan ID", state.activePlanID);
    return state;
  }

  const undoStack = state.undoStacks[state.activePlanID];
  const activePlan = PlanSetUtils.planSetGetPlan(state.plans, state.activePlanID);

  if (activePlan === null) {
    console.warn('Attempted to redo but active plan ID is null');
    return state;
  }

  const [updatedPlan, updatedUndoStack] = PlanUndo.redo(activePlan, undoStack);

  const undoStacks = {
    ...state.undoStacks,
    [state.activePlanID]: updatedUndoStack,
  };

  return {
    ...state,
    plans: PlanSetUtils.planSetUpdatePlan(state.plans, updatedPlan),
    undoStacks,
  };
};

const addUndoStageForPlan = (undoStacks: Record<number, PlanUndoStack>, plan: Plan) => {
  const newUndoStacks = { ...undoStacks };

  if (!newUndoStacks[plan.id]) {
    console.warn('Adding undo stages for plan with no previous undo stage history');
    newUndoStacks[plan.id] = PlanUndo.createUndoStack(plan);
  } else {
    newUndoStacks[plan.id] = PlanUndo.addStackUndoStage(newUndoStacks[plan.id], plan);
  }

  return newUndoStacks;
};

const clearUndoStack = (state: CanvasReducerState, action: { type: CanvasActionTypes.CLEAR_UNDO_STACK; planId: number }): CanvasReducerState => {
  if (state.undoStacks[action.planId]) {
    return {
      ...state,
      undoStacks: {
        ...state.undoStacks,
        [action.planId]: { position: 0, stages: [] },
      },
    };
  }

  return state;
};

const createPlanSuccess = (state: CanvasReducerState, action): CanvasReducerState => {
  // Figure out why this didn't create TS errors
  // const undoStacks: Record<number, PlanUndoStack> = PlanUndo.createUndoStack(action.plan);

  const undoStacks = {
    ...state.undoStacks,
    [action.plan.id]: PlanUndo.createUndoStack(action.plan),
  };

  // Add plan to both plans and loaded plans, and add plan ID to open plan IDs
  return {
    ...state,
    activePlanID: action.plan.id,
    openPlanIDs: [...state.openPlanIDs, action.plan.id],
    plans: PlanSetUtils.planSetUpdatePlan(state.plans, action.plan),
    loadedPlans: PlanSetUtils.planSetUpdatePlan(state.loadedPlans, action.plan),
    lastSavePlans: PlanSetUtils.planSetUpdatePlan(state.lastSavePlans, action.plan),
    undoStacks,
  };
};

const setNewActivePlan = (state: CanvasReducerState, planRemovedIndex = 0) => {
  if (state.openPlanIDs.length === 0) {
    return {
      ...state,
      activePlanID: null,
    };
  }

  if (planRemovedIndex <= 0) {
    return {
      ...state,
      activePlanID: state.openPlanIDs[0],
    };
  }

  if (planRemovedIndex >= state.openPlanIDs.length) {
    return {
      ...state,
      activePlanID: state.openPlanIDs[state.openPlanIDs.length - 1],
    };
  }

  return {
    ...state,
    activePlanID: state.openPlanIDs[planRemovedIndex],
  };
};

const updateInteractionState = (state: CanvasReducerState, { interactionState }) => {
  return {
    ...state,
    interactionState,
  };
};

const updateClipboardState = (state: CanvasReducerState, { hasClipboard }) => {
  return {
    ...state,
    hasClipboard,
  };
};

const planLoadFailed = (state: CanvasReducerState, action) => {
  const failedToLoadPlans = { ...state.failedToLoadPlans };

  if (!failedToLoadPlans[action.planID]) {
    failedToLoadPlans[action.planID] = 0;
  }

  failedToLoadPlans[action.planID] += 1;

  return {
    ...state,
    failedToLoadPlans,
  };
};

const clearPlanLoadFailures = (state: CanvasReducerState, action: { type: CanvasActionTypes.CLEAR_PLAN_LOAD_FAILURES; planID: number; reduceBy: number }) => {
  const failedToLoadPlans = { ...state.failedToLoadPlans };

  if (failedToLoadPlans[action.planID] !== undefined) {
    const amount = Math.max(0, failedToLoadPlans[action.planID] - action.reduceBy);
    if (amount > 0) {
      failedToLoadPlans[action.planID] = amount;
    } else {
      delete failedToLoadPlans[action.planID];
    }
  }

  return {
    ...state,
    failedToLoadPlans,
  };
};

const removePlans = (state: CanvasReducerState, action) => {
  if (action.planIDs.length === 0) {
    return state;
  }

  let { plans, loadedPlans, lastSavePlans, openPlanIDs, activePlanID } = state;
  const undoStacks = { ...state.undoStacks };

  for (let i = 0; i < action.planIDs.length; i++) {
    plans = PlanSetUtils.planSetRemovePlan(plans, action.planIDs[i]);
    loadedPlans = PlanSetUtils.planSetRemovePlan(loadedPlans, action.planIDs[i]);
    lastSavePlans = PlanSetUtils.planSetRemovePlan(lastSavePlans, action.planIDs[i]);
    openPlanIDs = openPlanIDs.filter((id) => id !== action.planIDs[i]);
    delete undoStacks[action.planIDs[i]];
    if (activePlanID === action.planIDs[i]) {
      activePlanID = openPlanIDs[0] ?? null;
    }
  }

  return {
    ...state,
    plans,
    loadedPlans,
    lastSavePlans,
    undoStacks,
    openPlanIDs,
    activePlanID,
  };
};

const closePlan = (state: CanvasReducerState, planId: number) => {
  const openPlanIDs = [...state.openPlanIDs];
  const index = openPlanIDs.indexOf(planId);
  openPlanIDs.splice(index, 1);

  if (index > -1) {
    const newState = { ...state, openPlanIDs };
    if (newState.activePlanID === planId) {
      return setNewActivePlan(newState, index);
    }

    return newState;
  }

  return state;
};

const addPlan = (state: CanvasReducerState, action: { type: string; plan: Plan }) => {
  if (PlanSetUtils.planSetHasPlan(state.plans, action.plan.id)) {
    // Plan already present
    throw new Error('Attempted to add plan which is already loaded');
  }

  const plans = PlanSetUtils.planSetAddPlan(state.plans, action.plan);
  const loadedPlans = PlanSetUtils.planSetAddPlan(state.loadedPlans, action.plan);
  const lastSavePlans = PlanSetUtils.planSetAddPlan(state.lastSavePlans, action.plan);
  const undoStacks = addUndoStageForPlan(state.undoStacks, action.plan);

  // Remove the plan from failed to load plans if loading was successful
  const failedToLoadPlans = { ...state.failedToLoadPlans };
  if (state.failedToLoadPlans[action.plan.id]) {
    delete failedToLoadPlans[action.plan.id];
  }

  return {
    ...state,
    plans,
    loadedPlans,
    lastSavePlans,
    failedToLoadPlans,
    undoStacks,
  };
};

const updatePlan = (state: CanvasReducerState, action: { type: string; plan: Plan; skipNewUndoStack?: boolean }) => {
  const plans = PlanSetUtils.planSetUpdatePlan(state.plans, action.plan);

  if (state.openPlanIDs.indexOf(action.plan.id) === -1) {
    console.warn('Updated plan data but plan is not open');
  }

  // Update undo stages
  const undoStacks = action.skipNewUndoStack ? state.undoStacks : addUndoStageForPlan(state.undoStacks, action.plan);

  return {
    ...state,
    plans,
    undoStacks,
  };
};

const planLoadEnd = (state: CanvasReducerState, action) => {
  // const updatedPlanState = updatePlan(state, action);

  const loadingPlanIDs = [...state.loadingPlanIDs];
  const index = loadingPlanIDs.indexOf(action.planID);
  loadingPlanIDs.splice(index, 1);

  if (index > -1) {
    return {
      ...state,
      loadingPlanIDs,
    };
  }

  return state;
};

const savePlanSuccess = (state: CanvasReducerState, action: { type: string; plan: Plan; responsePlan: Plan }) => {
  if (!PlanSetUtils.planSetHasPlan(state.plans, action.plan.id)) {
    console.error(`Successfully saved plan but it is not present in open plans, plan id: ${action.plan.id}`);
    return state;
  }

  const currentPlan = PlanSetUtils.planSetGetPlan(state.plans, action.plan.id);
  let updatedPlan: Plan;
  let updatedSavedPlan: Plan;

  if (currentPlan === null) {
    updatedPlan = action.responsePlan;
    updatedSavedPlan = action.responsePlan;
  } else {
    updatedPlan = PlanUtils.updatePlanFromServerResponse(currentPlan, action.responsePlan);
    updatedSavedPlan = PlanUtils.updatePlanFromServerResponse(action.plan, action.responsePlan);
  }

  if (currentPlan === action.plan) {
    // No changes have been made since the plan was saved
    console.debug('Saved plan has no changes since request was sent');
  } else {
    // Changes have been made since the save went out
    console.debug('Saved plan has changes since request was sent');
  }

  return {
    ...state,
    plans: PlanSetUtils.planSetUpdatePlan(state.plans, updatedPlan),
    loadedPlans: PlanSetUtils.planSetUpdatePlan(state.loadedPlans, action.responsePlan),
    lastSavePlans: PlanSetUtils.planSetUpdatePlan(state.lastSavePlans, updatedSavedPlan),
  };
};

const deletePlanSuccess = (state: CanvasReducerState, action) => {
  // Remove plan from any open plans planHistory, it should be removed from everywhere else when
  // the plan loader finds that it is not required anywhere

  return {
    ...state,
    plans: PlanSetUtils.planSetRemovePlanFromHistory(state.plans, action.planID),
    loadedPlans: PlanSetUtils.planSetRemovePlanFromHistory(state.loadedPlans, action.planID),
    lastSavePlans: PlanSetUtils.planSetRemovePlanFromHistory(state.lastSavePlans, action.planID),
  };
};

const openPlan = (state: CanvasReducerState, action) => {
  if (state.openPlanIDs.includes(action.planID)) {
    // Plan already open
    return state;
  }

  return {
    ...state,
    openPlanIDs: [...state.openPlanIDs, action.planID],
  };
};

const showContextMenu = (state: CanvasReducerState, action: { worldPosition: Vector2 }): CanvasReducerState => {
  return {
    ...state,
    showContextMenuAt: action.worldPosition,
  };
};

const hideContextMenu = (state: CanvasReducerState): CanvasReducerState => {
  return {
    ...state,
    showContextMenuAt: false,
  };
};

const canvasReducer = (state: CanvasReducerState = INITIAL_STATE, action: any): CanvasReducerState => {
  switch (action.type) {
    case CanvasActionTypes.OPEN_PLAN:
      return openPlan(state, action);
    case CanvasActionTypes.CLOSE_PLAN:
      return closePlan(state, action.planID);
    case GardenCanvasEventActionTypes.UPDATE_PLAN:
      return updatePlan(state, action);
    case CanvasActionTypes.UPDATE_PLAN:
      return updatePlan(state, action);
    case CanvasActionTypes.ADD_PLAN:
      return addPlan(state, action);
    case CanvasActionTypes.PLANS_LOAD_START:
      return {
        ...state,
        loadingPlanIDs: [...state.loadingPlanIDs, ...action.planIDs],
      };
    case CanvasActionTypes.PLAN_LOAD_END:
      return planLoadEnd(state, action);
    case CanvasActionTypes.REMOVE_PLANS:
      return removePlans(state, action);
    case CanvasActionTypes.PLAN_LOAD_FAILED:
      return planLoadFailed(state, action);
    case CanvasActionTypes.CLEAR_PLAN_LOAD_FAILURES:
      return clearPlanLoadFailures(state, action);
    case CanvasActionTypes.SET_ACTIVE_PLAN:
      return {
        ...state,
        activePlanID: action.planID,
      };
    case GardenCanvasEventActionTypes.UPDATE_INTERACTION_STATE:
      return updateInteractionState(state, action);
    case GardenCanvasEventActionTypes.UPDATE_CLIPBOARD_STATE:
      return updateClipboardState(state, action);
    case CanvasActionTypes.CREATE_PLAN_SUCCESS:
      return createPlanSuccess(state, action);
    case CanvasActionTypes.SAVE_PLAN_SUCCESS:
      return savePlanSuccess(state, action);
    case GardenCanvasEventActionTypes.UPDATE_SELECTED_NODES:
      return {
        ...state,
        selectedItems: action.selectedNodes,
      };
    case CanvasActionTypes.UNDO:
      return undo(state);
    case CanvasActionTypes.REDO:
      return redo(state);
    case CanvasActionTypes.CLEAR_UNDO_STACK:
      return clearUndoStack(state, action);
    case CanvasActionTypes.DELETE_PLAN_SUCCESS:
      return deletePlanSuccess(state, action);
    case TransferPlanActionTypes.TRANSFER_PLAN_SUCCESS:
      if (action.copy) {
        return state;
      }

      // Apply close plan state mutator, then delete plan success mutator
      return deletePlanSuccess(closePlan(state, action.planID), action);
    case CanvasActionTypes.SET_TEXTURE_STATUS:
      return {
        ...state,
        texturesStatus: action.texturesStatus,
      };
    case CanvasActionTypes.ADD_TO_CLOSE_CONFIRMATION:
      return {
        ...state,
        confirmClosePlans: [...state.confirmClosePlans, action.planID],
      };
    case CanvasActionTypes.REMOVE_FROM_CLOSE_CONFIRMATION:
      return {
        ...state,
        confirmClosePlans: state.confirmClosePlans.filter((planID) => planID !== action.planID),
      };
    case CanvasActionTypes.PLAN_DATA_ERROR:
      return {
        ...state,
        planDataErrors: [...state.planDataErrors, action.failedPlanSave],
      };
    case CanvasActionTypes.CLEAR_PLAN_DATA_ERRORS:
      return {
        ...state,
        planDataErrors: [],
      };
    case CanvasActionTypes.SHOW_CONTEXT_MENU:
      return showContextMenu(state, action);
    case CanvasActionTypes.HIDE_CONTEXT_MENU:
      return hideContextMenu(state);
    default:
      return state;
  }
};

export default canvasReducer;
