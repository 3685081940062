import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { LoadingState } from '@gi/constants';
import { AsyncOperation } from '@gi/utils';

import Engine from '../../engine';

import styles from './engine-debugger-loader.module.css';

const OPEN_DEBUGGER_EVENT = 'opendebugger';
const CLOSE_DEBUGGER_EVENT = 'closedebugger';

declare global {
  interface WGPGlobal {
    enableEngineDebugger: () => void;
    disableEngineDebugger: () => void;
  }
}

window.WGP = window.WGP ?? {};
window.WGP = {
  ...window.WGP,
  enableEngineDebugger: () => window.dispatchEvent(new Event(OPEN_DEBUGGER_EVENT)),
  disableEngineDebugger: () => window.dispatchEvent(new Event(CLOSE_DEBUGGER_EVENT)),
};

const importDebuggerProvider = () => import('./engine-debugger-provider');
const importDebugger = () => import('./engine-debugger');

interface iProps {
  children: ReactNode;
  engine: Engine | null;
}

/**
 * This lazy-loads the engine debugger and provider, as they won't be needed by the average user.
 * This should help keep the entry bundle smaller, keeping load times quicker.
 */
const EngineDebuggerLoader = ({ children, engine }: iProps): JSX.Element => {
  const [debuggerEnabled, setDebuggerEnabled] = useState(false);
  const [debuggerProviderModule, setDebuggerProviderModule] = useState<AsyncOperation<Awaited<ReturnType<typeof importDebuggerProvider>>>>({
    status: LoadingState.NONE,
  });
  const [debuggerModule, setDebuggerModule] = useState<AsyncOperation<Awaited<ReturnType<typeof importDebugger>>>>({ status: LoadingState.NONE });

  useEffect(() => {
    const onEnableDebugger = () => setDebuggerEnabled(true);
    const onDisableDebugger = () => setDebuggerEnabled(false);
    window.addEventListener(OPEN_DEBUGGER_EVENT, onEnableDebugger);
    window.addEventListener(CLOSE_DEBUGGER_EVENT, onDisableDebugger);

    return () => {
      window.removeEventListener(OPEN_DEBUGGER_EVENT, onEnableDebugger);
      window.removeEventListener(CLOSE_DEBUGGER_EVENT, onDisableDebugger);
    };
  }, []);

  const loadDebuggerProvider = useCallback(() => {
    if (debuggerProviderModule.status === LoadingState.NONE || debuggerProviderModule.status === LoadingState.ERROR) {
      setDebuggerProviderModule({ status: LoadingState.LOADING });
      importDebuggerProvider()
        .then((module) => {
          setDebuggerProviderModule({ status: LoadingState.SUCCESS, value: module });
        })
        .catch((error) => {
          setDebuggerProviderModule({ status: LoadingState.ERROR, error });
        });
    }
  }, [debuggerProviderModule]);

  const loadDebugger = useCallback(() => {
    if (debuggerModule.status === LoadingState.NONE || debuggerModule.status === LoadingState.ERROR) {
      setDebuggerModule({ status: LoadingState.LOADING });
      importDebugger()
        .then((module) => {
          setDebuggerModule({ status: LoadingState.SUCCESS, value: module });
        })
        .catch((error) => {
          setDebuggerModule({ status: LoadingState.ERROR, error });
        });
    }
  }, [debuggerModule]);

  useEffect(() => {
    if (debuggerEnabled) {
      loadDebuggerProvider();
      loadDebugger();
    }
  }, [debuggerEnabled]);

  const loadStatusOverlay = useMemo(() => {
    if (!debuggerEnabled) {
      return null;
    }
    if (debuggerEnabled && debuggerProviderModule.status === LoadingState.SUCCESS && debuggerModule.status === LoadingState.SUCCESS) {
      return null;
    }

    const getIcon = (status: LoadingState) => {
      switch (status) {
        case LoadingState.SUCCESS:
          return <i className='icon-ok' />;
        case LoadingState.ERROR:
          return <i className='icon-cancel' />;
        case LoadingState.LOADING:
          return <i className='icon-spinner animate-pulse' />;
        case LoadingState.NONE:
        default:
          return null;
      }
    };

    const getText = (name: string, operation: AsyncOperation<any>) => {
      switch (operation.status) {
        case LoadingState.SUCCESS:
          return <>Loaded {name}</>;
        case LoadingState.ERROR:
          return (
            <>
              Failed to load {name}
              <span className={styles.smallError}>
                {operation.error.name} - {operation.error.message}
              </span>
            </>
          );
        case LoadingState.LOADING:
          return <>Loading {name}</>;
        case LoadingState.NONE:
        default:
          return <>Idle {name}</>;
      }
    };

    return (
      <div className={styles.loadStatusOverlay}>
        <p>
          {getIcon(debuggerModule.status)} {getText('EngineDebugger', debuggerModule)}
        </p>
        <p>
          {getIcon(debuggerProviderModule.status)} {getText('EngineDebuggerProvider', debuggerProviderModule)}
        </p>
      </div>
    );
  }, [debuggerEnabled, debuggerModule, debuggerProviderModule]);

  if (debuggerEnabled && debuggerProviderModule.status === LoadingState.SUCCESS && debuggerModule.status === LoadingState.SUCCESS) {
    return (
      <debuggerProviderModule.value.EngineDebuggerProvider engine={engine}>
        {children}
        <debuggerModule.value.default overlay />
      </debuggerProviderModule.value.EngineDebuggerProvider>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return (
    <>
      {children}
      {loadStatusOverlay}
    </>
  );
};

export default EngineDebuggerLoader;
