import React, { ReactNode } from 'react';

import MobileTopbarContentWithButtons from './mobile-topbar-content-with-buttons';
import MobileTopbarContainer from './mobile-topbar-container';
import MobileTopbarSubtitle from './mobile-topbar-subtitle';
import MobileTopbarTitle from './mobile-topbar-title';

import styles from './default-mobile-topbar.module.css';

interface iProps {
  title: ReactNode;
  subtitle?: ReactNode;
}

const DefaultMobileTopbar = ({ title, subtitle }: iProps): JSX.Element => {
  return (
    <MobileTopbarContainer>
      <MobileTopbarContentWithButtons>
        <div className={styles.content}>
          <MobileTopbarTitle>{title}</MobileTopbarTitle>
          {subtitle ? <MobileTopbarSubtitle>{subtitle}</MobileTopbarSubtitle> : null}
        </div>
      </MobileTopbarContentWithButtons>
    </MobileTopbarContainer>
  );
};

export default DefaultMobileTopbar;
