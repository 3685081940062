import React from 'react';

import './planner-icon.scss';

interface iProps {
  className?: string;
  code: string;
  halfSize?: boolean;
  quarterSize?: boolean;
}

const PlannerIcon = ({ className = '', code, halfSize = false, quarterSize = false }: iProps): JSX.Element => {
  return <span className={`${className} planner-icon planner-icon-${code} ${halfSize ? 'half-size' : ''} ${quarterSize ? 'quarter-size' : ''}`} />;
};

export default PlannerIcon;
