import { Graphics } from 'pixi.js-new';

import {
  DoubleClickableComponent,
  DraggableComponent,
  HoverableComponent,
  InteractableComponent,
  LongPressableComponent,
  ManipulatableComponent,
  NodeEvent,
  OutlineComponent,
  RightClickableComponent,
  SelectableComponent,
  ShapeComponent,
  ShapeFlag,
  TooltipComponent,
  VisibilityComponent,
} from '@gi/core-renderer';
import Bitmask from '@gi/bitmask';
import { Geometry } from '@gi/math';
import { StateDef } from '@gi/state';
import { LayerTypes, ShapeType } from '@gi/constants';

import SettingsContext, { SettingsContextState } from '../settings-context';
import PlanSettingsContext, { PlanSettingsContextState } from '../plan-settings-context';
import GardenItemNode, { GardenItemNodeState } from '../garden-item-node';

// State is expandable via generic to allow for forcing point2 to be required.
// eslint-disable-next-line @typescript-eslint/ban-types
export type ShapeNodeState<T extends Record<string, any> = {}> = StateDef<
  {
    point1: Vector2;
    point2: Vector2 | null;
    point3: Vector2;
    rotation: number;
    fill: number | null;
    texture: string | null;
    stroke: number | null;
    strokeWidth: number;
  } & GardenItemNodeState &
    T,
  [],
  {
    settings: SettingsContextState;
    planSettings: PlanSettingsContextState;
  }
>;

/**
 * Generic Shape Node
 *  This node should be extended for each of the differnt shape types.
 *  This node doesn't render anything by default. Instead, this acts as an interface.
 */
class ShapeNode<
  // eslint-disable-next-line @typescript-eslint/ban-types
  T extends Record<string, any> = {},
  TSubType extends ShapeType = ShapeType,
> extends GardenItemNode<ShapeNodeState<T>> {
  type = 'ShapeNode';

  readonly subtype: TSubType;

  readonly shape: ShapeComponent;
  readonly outline: OutlineComponent;
  readonly tooltip: TooltipComponent;
  readonly visibility: VisibilityComponent;

  protected planSettings: PlanSettingsContext | null = null;

  graphics: Graphics | null = null;

  constructor(id: number, subtype: TSubType, initialState: ShapeNodeState<T>['state']) {
    super(id, initialState, LayerTypes.LAYOUT);

    this.subtype = subtype;
    this.name = `${id} - ${subtype}`;

    // Add required components
    this.components.add(new InteractableComponent());
    this.components.add(new HoverableComponent());
    this.components.add(new SelectableComponent());
    this.components.add(new ManipulatableComponent());
    this.components.add(new DraggableComponent());
    this.components.add(new DoubleClickableComponent());
    this.components.add(new RightClickableComponent());
    this.components.add(new LongPressableComponent());
    this.shape = this.components.add(new ShapeComponent({ flags: Bitmask.Create(ShapeFlag.CULLABLE) }));
    this.outline = this.components.add(new OutlineComponent({ padding: 4 }));
    this.tooltip = this.components.add(new TooltipComponent());
    this.visibility = this.components.add(new VisibilityComponent());

    // Update our transform to always match the center point of the shape
    this.state.addUpdater(
      (state) => {
        if (state.values.point2) {
          this.transform.state.values.position = Geometry.getBoundingBox(state.values.point1, state.values.point2, state.values.point3).center;
        } else {
          this.transform.state.values.position = Geometry.midpoint(state.values.point1, state.values.point3);
        }
        this.transform.state.values.rotation = state.values.rotation;
      },
      { properties: ['point1', 'point2', 'point3', 'rotation'] }
    );

    // Update the outline thickness whenever touch mode or the stroke changes.
    this.state.addUpdater(
      (state) => {
        const touchMode = state.otherStates.settings?.values.touchMode ?? false;
        const padding = touchMode ? OutlineComponent.PADDING_LARGE : OutlineComponent.PADDING_SMALL;
        this.outline.state.values.padding = state.values.stroke !== null ? state.values.strokeWidth / 2 + padding : padding;
      },
      {
        properties: ['stroke', 'strokeWidth'],
        otherStates: { settings: { properties: ['touchMode'] } },
      }
    );

    // Update tooltip whenever any dependencies change
    this.state.addWatcher(
      () => {
        this.updateTooltip();
      },
      {
        properties: ['point1', 'point2', 'point3'],
        otherStates: { planSettings: { properties: ['metric'] } },
      }
    );

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    this.graphics = new Graphics();
    this.ownGraphics.addChild(this.graphics);

    this.planSettings = this.tryGetContext(PlanSettingsContext);
    if (this.planSettings) {
      this.state.connectState('planSettings', this.planSettings.state);
    }

    const settings = this.getContext(SettingsContext);
    this.state.connectState('settings', settings.state);
  };

  #onBeforeUnbind = () => {
    if (this.graphics) {
      this.graphics.destroy();
      this.graphics = null;
    }
  };

  /**
   * Calculates and returns the dimensions of the shape.
   *  If point2 is null, it will be the bounding box of point1 and point3.
   *  If point2 is not null, it will be the bounding box contianing all 3 points.
   * @returns The dimensions of the shape
   */
  getDimensions(): Dimensions {
    const { point1, point2, point3 } = this.state.values;

    // If we have 3 points, find the bounding box and use it as the size
    if (point2 !== null) {
      const { min, max } = Geometry.getBoundingBox(point1, point2, point3);
      return {
        width: Math.abs(max.x - min.x),
        height: Math.abs(max.y - min.y),
      };
    }

    // We're only 2 points, find the bounding box of the line created
    const diff = Geometry.getPointDelta(point1, point3);
    return {
      width: Math.abs(diff.x),
      height: Math.abs(diff.y),
    };
  }

  /**
   * Calculates and returns the center of the shape.
   *  If point2 is null, it will be the midpoint of point1 and point3.
   *  If point2 is not null, it will be the center of the bounding box containing all 3 points.
   * @returns The center of the shape
   */
  getCenter(): Vector2 {
    const { point1, point2, point3 } = this.state.values;

    if (point2 !== null) {
      return Geometry.getBoundingBox(point1, point2, point3).center;
    }

    return Geometry.midpoint(point1, point3);
  }

  /**
   * Sets the points and rotation of the shape.
   * @param point1 The first point. The start point for rectangle-like shapes and lines.
   * @param point2 The second point. Null for rectangle-like shapes. Used as the control point for lines.
   * @param point3 The third point. The end point for rectangle-like shapes and lines.
   * @param rotation The rotation of the shape
   */
  setPoints(point1: Vector2, point2: Vector2 | null, point3: Vector2, rotation: number) {
    this.state.values.point1 = point1;
    this.state.values.point2 = point2;
    this.state.values.point3 = point3;
    this.state.values.rotation = rotation;
  }

  /**
   * Sets the style of the shape.
   * @param fill The fill colour of the shape. Null creates an outline if no texture given.
   * @param texture Optional texture to use to fill the shape.
   * @param stroke The stroke colour of the shape. Only used if no fill present.
   * @param strokeWidth The width of the stroke. Only used if no fill present.
   */
  setStyle(fill: number | null, texture: string | null, stroke: number | null, strokeWidth: number) {
    this.state.values.fill = fill;
    this.state.values.texture = texture;
    this.state.values.stroke = stroke;
    this.state.values.strokeWidth = strokeWidth;
  }

  /**
   * Updates the text of the tooltip for this shape.
   * Should be overwritten in derived classes to give more accurate tooltip information.
   */
  protected updateTooltip() {
    this.tooltip.state.values.text = 'Shape';
  }
}

export default ShapeNode;
