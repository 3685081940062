import React, { useRef, useState, useEffect } from 'react';

import { Palettes, Swatch } from '@gi/palette';
import './picker-button.scss';
import TexturePicker from './texture-picker';
import SwatchDisplay from './display-swatch';
import TexturePickerPortal from './texture-picker-portal';

interface iProps {
  onSwatchSelect: (swatch: Swatch) => void;
  selectedSwatch: null | Swatch;
  palettes: Palettes;
}

const PickerButton = ({ onSwatchSelect, selectedSwatch, palettes }: iProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const button = useRef<HTMLButtonElement>(null);
  const picker = useRef<HTMLDivElement>(null);

  /**
   * Closes the picker when user clicks outside the picker.
   *
   * @param {Object} evt - the click event.
   */
  const handleDocClick = (evt) => {
    if (picker.current === undefined || button.current === undefined) {
      return;
    }

    const clicked = evt.target;

    if (
      clicked !== button.current &&
      button.current !== null &&
      !button.current.contains(clicked) &&
      clicked !== picker.current &&
      picker.current !== null &&
      !picker.current.contains(clicked)
    ) {
      setOpen(false);
    }
  };

  /**
   * Closes the picker if the ESC key is pressed
   *
   * @param {Object} evt - the keyboard event.
   */
  const handleKeyDown = (evt) => {
    if (open && evt.keyCode === 27) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const addListeners = () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleDocClick);
    };

    const removeListeners = () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleDocClick);
    };

    if (open) {
      addListeners();
    } else {
      removeListeners();
    }

    return () => {
      removeListeners();
    };
  }, [open]);

  const handleSwatchSelect = (swatch: Swatch) => {
    setOpen(false);
    onSwatchSelect(swatch);
  };

  const onLiveColourChange = (swatch: Swatch) => {
    onSwatchSelect(swatch);
  };

  const onClosePicker = () => {
    setOpen(false);
  };

  const getSelectedSwatch = () => {
    if (selectedSwatch === null) {
      return null;
    }

    if (!palettes.hasSwatch(selectedSwatch.type, selectedSwatch.value)) {
      return selectedSwatch;
    }

    return palettes.getSwatch(selectedSwatch.type, selectedSwatch.value);
  };

  /**
   * Closes the picker if open, opens the picker if closed.
   */
  const toggleOpen = () => {
    requestAnimationFrame(() => {
      setOpen(!open);
    });
  };

  const renderPicker = (): JSX.Element | null => {
    if (!open) {
      return null;
    }

    if (button.current === undefined || button.current === null) {
      return null;
    }

    const buttonRect = button.current.getBoundingClientRect();

    return (
      <TexturePickerPortal>
        <div ref={picker}>
          <TexturePicker
            buttonRect={buttonRect}
            onClosePicker={onClosePicker}
            onLiveColourChange={onLiveColourChange}
            onSwatchSelect={handleSwatchSelect}
            selected={selectedSwatch}
            palettes={palettes}
          />
        </div>
      </TexturePickerPortal>
    );
  };

  return (
    <>
      <button type='button' aria-label='Select Color' ref={button} className='picker-button' onClick={toggleOpen}>
        <SwatchDisplay swatch={getSelectedSwatch()} />
      </button>
      {renderPicker()}
    </>
  );
};

export default PickerButton;
