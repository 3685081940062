import Plant from '@gi/plant';

import { SearchService } from './search-service';

export type SearchablePlantVariety = {
  code: string;
  name: string;
  plant: Plant;
};

export class PlantVarietySearchService extends SearchService<SearchablePlantVariety> {
  constructor(itemList: SearchablePlantVariety[], options?: Fuzzysort.Options) {
    super(
      itemList,
      ['name'],
      {
        name: {
          weight: 1,
        },
      },
      options
    );
  }
}
