import { errorReporterInstance } from '@gi/errors';
import { LocalSettings } from './local-settings-type';

const SETTINGS_STORAGE_KEY = 'gplocal';

/**
 * Class with static helper methods for accessing local storage. Could easily be done
 * as functions to but wrapping it up in a class seems like a sensible way of adding
 * an appropriate namespace
 *
 * @class LocalSettingsStorage
 */
class LocalSettingsStorage {
  static getKey(userID: number | null) {
    if (userID === null) {
      return `${SETTINGS_STORAGE_KEY}`;
    }

    return `${SETTINGS_STORAGE_KEY}_${userID}`;
  }

  static getAll(userID, defaults) {
    const key = LocalSettingsStorage.getKey(userID);

    let result = {};

    try {
      const item = localStorage.getItem(key);
      if (!item) {
        return defaults;
      }
      result = JSON.parse(item);
      result = { ...defaults, ...result };
      return result;
    } catch (e) {
      console.error('Error loading settings from local storage');
      console.error(e);
      if (errorReporterInstance) {
        errorReporterInstance.notify(e);
      }

      return defaults;
    }
  }

  static setAll(userID: number, settings: LocalSettings) {
    console.debug('Settings setting', userID);
    const key = LocalSettingsStorage.getKey(userID);

    try {
      localStorage.setItem(key, JSON.stringify(settings));
      return true;
    } catch (e) {
      console.error('Error saving settings to local storage');
      console.error(e);
      if (errorReporterInstance) {
        errorReporterInstance.notify(e);
      }
      return false;
    }
  }
}

export default LocalSettingsStorage;
