import { GardenItemType, ModifierType } from '@gi/constants';
import Plan, { PlanGardenObject, PlanPlant, PlanShape, PlanText, PlanUtils } from '@gi/plan';

import { SimulatedPlan } from '../simulation/simulated-plan';
import { SimulatedPlant } from '../simulation/simulated-plant';
import { SimulatedText } from '../simulation/simulated-text';
import { SimulatedShape } from '../simulation/simulated-shape';
import { SimulatedGardenObject } from '../simulation/simulated-garden-object';

function vectorsEqual(v1: Vector2, v2: Vector2) {
  return v1.x === v2.x && v1.y === v2.y;
}

function possiblyNullVectorsEqual(v1: Vector2 | null, v2: Vector2 | null) {
  if (v1 === null && v2 === null) {
    return true;
  }
  if (v1 === null || v2 === null) {
    return false;
  }
  return vectorsEqual(v1, v2);
}

function getModifiersFromObjects(gardenObjects: SimulatedGardenObject[]): ModifierType[] {
  const modifiers = gardenObjects.map((object) => object.gardenObject.plantModifier).filter((modifier): modifier is ModifierType => modifier !== null);

  return [...new Set(modifiers)];
}

/* ---- PLANTS ---- */

export function updatePlanPlant(simulatedPlant: SimulatedPlant, planPlant: PlanPlant): PlanPlant {
  let updatedPlanPlant = { ...planPlant };

  if (!vectorsEqual(simulatedPlant.rowStart, planPlant.rowStart)) {
    updatedPlanPlant = {
      ...updatedPlanPlant,
      rowStart: { ...simulatedPlant.rowStart },
    };
  }

  if (!vectorsEqual(simulatedPlant.rowEnd, planPlant.rowEnd)) {
    updatedPlanPlant = {
      ...updatedPlanPlant,
      rowEnd: { ...simulatedPlant.rowEnd },
    };
  }

  if (simulatedPlant.height !== planPlant.height) {
    updatedPlanPlant = {
      ...updatedPlanPlant,
      height: simulatedPlant.height,
    };
  }

  if (!vectorsEqual(simulatedPlant.labelOffset, planPlant.labelOffset)) {
    updatedPlanPlant = {
      ...updatedPlanPlant,
      labelOffset: { ...simulatedPlant.labelOffset },
    };
  }

  if (simulatedPlant.labelText !== planPlant.labelText) {
    updatedPlanPlant = {
      ...updatedPlanPlant,
      labelText: simulatedPlant.labelText,
    };
  }

  if (simulatedPlant.showLabel !== planPlant.showLabel) {
    updatedPlanPlant = {
      ...updatedPlanPlant,
      showLabel: simulatedPlant.showLabel,
    };
  }

  if (simulatedPlant.inGroundStart !== planPlant.inGroundStart) {
    updatedPlanPlant = {
      ...updatedPlanPlant,
      inGroundStart: simulatedPlant.inGroundStart,
    };
  }

  if (simulatedPlant.inGroundEnd !== planPlant.inGroundEnd) {
    updatedPlanPlant = {
      ...updatedPlanPlant,
      inGroundEnd: simulatedPlant.inGroundEnd,
    };
  }

  if (simulatedPlant.inGroundAll !== planPlant.inGroundAll) {
    updatedPlanPlant = {
      ...updatedPlanPlant,
      inGroundAll: simulatedPlant.inGroundAll,
    };
  }

  // TODO: Is there a point to doing array isEqual here?
  updatedPlanPlant.modifiers = getModifiersFromObjects(simulatedPlant.coveredBy);

  return updatedPlanPlant;
}

export function planPlantFromSimulatedPlant(simulatedPlant: SimulatedPlant): PlanPlant {
  return PlanUtils.createNewPlanPlant(
    simulatedPlant.plant.code,
    { ...simulatedPlant.rowStart },
    { ...simulatedPlant.rowEnd },
    simulatedPlant.height,
    simulatedPlant.showLabel,
    { ...simulatedPlant.labelOffset },
    simulatedPlant.labelText,
    simulatedPlant.isSquareFoot,
    simulatedPlant.variety,
    simulatedPlant.inGroundStart,
    simulatedPlant.inGroundEnd,
    simulatedPlant.inGroundAll,
    getModifiersFromObjects(simulatedPlant.coveredBy),
    simulatedPlant.locked,
    simulatedPlant.zIndex,
    simulatedPlant.id
  );
}

/* ---- GARDEN OBJECTS ---- */

export function updatePlanGardenObject(simulatedGardenObject: SimulatedGardenObject, planGardenObject: PlanGardenObject): PlanGardenObject {
  let updatedPlanGardenObject = { ...planGardenObject };

  if (!vectorsEqual(simulatedGardenObject.start, planGardenObject.start)) {
    updatedPlanGardenObject = {
      ...updatedPlanGardenObject,
      start: { ...simulatedGardenObject.start },
    };
  }

  if (!possiblyNullVectorsEqual(simulatedGardenObject.mid, planGardenObject.mid)) {
    updatedPlanGardenObject = {
      ...updatedPlanGardenObject,
      mid: simulatedGardenObject.mid === null ? null : { ...simulatedGardenObject.mid },
    };
  }

  if (!vectorsEqual(simulatedGardenObject.end, planGardenObject.end)) {
    updatedPlanGardenObject = {
      ...updatedPlanGardenObject,
      end: { ...simulatedGardenObject.end },
    };
  }

  if (simulatedGardenObject.rotation !== planGardenObject.rotation) {
    updatedPlanGardenObject = {
      ...updatedPlanGardenObject,
      rotation: simulatedGardenObject.rotation,
    };
  }

  return updatedPlanGardenObject;
}

export function planGardenObjectFromSimulatedGardenObject(simulatedGardenObject: SimulatedGardenObject): PlanGardenObject {
  return PlanUtils.createNewPlanGardenObject(
    simulatedGardenObject.gardenObject.code,
    simulatedGardenObject.start,
    simulatedGardenObject.mid,
    simulatedGardenObject.end,
    simulatedGardenObject.rotation,
    simulatedGardenObject.locked,
    simulatedGardenObject.zIndex,
    simulatedGardenObject.id
  );
}

/* ---- SHAPES ---- */

export function updatePlanShape(simulatedShape: SimulatedShape, planShape: PlanShape): PlanShape {
  let updatedPlanShape = { ...planShape };

  if (!vectorsEqual(simulatedShape.point1, planShape.point1)) {
    updatedPlanShape = {
      ...updatedPlanShape,
      point1: { ...simulatedShape.point1 },
    };
  }

  if (!possiblyNullVectorsEqual(simulatedShape.point2, planShape.point2)) {
    updatedPlanShape = {
      ...updatedPlanShape,
      point2: simulatedShape.point2 === null ? null : { ...simulatedShape.point2 },
    };
  }

  if (!vectorsEqual(simulatedShape.point3, planShape.point3)) {
    updatedPlanShape = {
      ...updatedPlanShape,
      point3: { ...simulatedShape.point3 },
    };
  }

  if (simulatedShape.rotation !== planShape.rotation) {
    updatedPlanShape = {
      ...updatedPlanShape,
      rotation: simulatedShape.rotation,
    };
  }

  // TODO: Finish this

  return updatedPlanShape;
}

export function planShapeFromSimulatedShape(simulatedShape: SimulatedShape): PlanShape {
  return PlanUtils.createNewPlanShape(
    simulatedShape.shapeType,
    simulatedShape.point1,
    simulatedShape.point2,
    simulatedShape.point3,
    simulatedShape.rotation,
    simulatedShape.fill,
    simulatedShape.texture,
    simulatedShape.stroke,
    simulatedShape.strokeWidth,
    simulatedShape.closed,
    simulatedShape.locked,
    simulatedShape.zIndex,
    simulatedShape.id
  );
}

/* ---- TEXT ---- */

export function updatePlanText(simulatedText: SimulatedText, planText: PlanText) {
  let updatedPlanText = { ...planText };

  if (!vectorsEqual(simulatedText.start, planText.start)) {
    updatedPlanText = {
      ...updatedPlanText,
      start: { ...simulatedText.start },
    };
  }

  if (!vectorsEqual(simulatedText.end, planText.end)) {
    updatedPlanText = {
      ...updatedPlanText,
      end: { ...simulatedText.end },
    };
  }

  if (simulatedText.rotation !== planText.rotation) {
    updatedPlanText = {
      ...updatedPlanText,
      rotation: simulatedText.rotation,
    };
  }

  if (simulatedText.text !== planText.text) {
    updatedPlanText = {
      ...updatedPlanText,
      text: simulatedText.text,
    };
  }

  if (simulatedText.colour !== planText.fill) {
    updatedPlanText = {
      ...updatedPlanText,
      fill: simulatedText.colour,
    };
  }

  if (simulatedText.fontSize !== planText.fontSize) {
    updatedPlanText = {
      ...updatedPlanText,
      fontSize: simulatedText.fontSize,
    };
  }

  return updatedPlanText;
}

export function planTextFromSimulatedText(simulatedText: SimulatedText): PlanText {
  return PlanUtils.createNewPlanText(
    simulatedText.text,
    simulatedText.fontSize,
    simulatedText.colour,
    simulatedText.start,
    simulatedText.end,
    simulatedText.rotation,
    simulatedText.locked,
    simulatedText.zIndex,
    simulatedText.id
  );
}

/* ---- THE PLAN ---- */

export function updatePlanPropertiesFromSimulated(plan: Plan, simulatedPlan: SimulatedPlan): Plan {
  let updatedPlan = plan;

  if (plan.width !== simulatedPlan.width) {
    updatedPlan = {
      ...updatedPlan,
      width: simulatedPlan.width,
    };
  }

  if (plan.height !== simulatedPlan.height) {
    updatedPlan = {
      ...updatedPlan,
      height: simulatedPlan.height,
    };
  }

  return updatedPlan;
}

export function updateBackgroundImageFromSimulated(plan: Plan, simulatedPlan: SimulatedPlan): Plan {
  let updatedPlan = plan;

  if (plan.backgroundImage && !simulatedPlan.backgroundImage) {
    updatedPlan = { ...plan };
    delete updatedPlan.backgroundImage;
  } else if (simulatedPlan.backgroundImage && simulatedPlan.backgroundImage.imageSrc) {
    updatedPlan = {
      ...plan,
      backgroundImage: {
        src: simulatedPlan.backgroundImage.imageSrc,
        position: simulatedPlan.backgroundImage.position,
        dimensions: simulatedPlan.backgroundImage.dimensions,
        rotation: simulatedPlan.backgroundImage.rotation,
      },
    };
  }

  return updatedPlan;
}

export function updateAddedItemsFromSimulated(plan: Plan, addedItemIds: number[], simulatedPlan: SimulatedPlan): Plan {
  let updatedPlan = plan;

  for (let i = 0; i < addedItemIds.length; i++) {
    const id = addedItemIds[i];

    if (updatedPlan.itemTypes[id]) {
      console.error(`Attempting to add an item to a plan which is already there - Plan: ${plan.id} Item: ${id}`);
      // eslint-disable-next-line no-continue
      continue;
    }

    const type = simulatedPlan.itemTypes[id];

    switch (type) {
      case GardenItemType.Plant:
        updatedPlan = {
          ...updatedPlan,
          plants: {
            ...updatedPlan.plants,
            [id]: planPlantFromSimulatedPlant(simulatedPlan.plants[id]),
          },
          plantIds: [...updatedPlan.plantIds, id],
        };
        break;
      case GardenItemType.GardenObject:
        updatedPlan = {
          ...updatedPlan,
          gardenObjects: {
            ...updatedPlan.gardenObjects,
            [id]: planGardenObjectFromSimulatedGardenObject(simulatedPlan.gardenObjects[id]),
          },
          gardenObjectIds: [...updatedPlan.gardenObjectIds, id],
        };
        break;
      case GardenItemType.Shape:
        updatedPlan = {
          ...updatedPlan,
          shapes: {
            ...updatedPlan.shapes,
            [id]: planShapeFromSimulatedShape(simulatedPlan.shapes[id]),
          },
          shapeIds: [...updatedPlan.shapeIds, id],
        };
        break;
      case GardenItemType.Text:
        updatedPlan = {
          ...updatedPlan,
          text: {
            ...updatedPlan.text,
            [id]: planTextFromSimulatedText(simulatedPlan.text[id]),
          },
          textIds: [...updatedPlan.textIds, id],
        };
        break;
      default:
        console.error(`Garden type not found ${type}`);
    }

    updatedPlan = {
      ...updatedPlan,
      itemTypes: {
        ...updatedPlan.itemTypes,
        [addedItemIds[i]]: type,
      },
    };
  }

  return updatedPlan;
}

export function updateUpdatedItemsFromSimulated(plan: Plan, updatedItems: number[], simulatedPlan: SimulatedPlan): Plan {
  let updatedPlan = plan;

  for (let i = 0; i < updatedItems.length; i++) {
    const id = updatedItems[i];

    if (!updatedPlan.itemTypes[id]) {
      console.error(`Attempting to update an item to a plan which is not present - Plan: ${plan.id} Item: ${id}`);
      // eslint-disable-next-line no-continue
      continue;
    }

    if (!simulatedPlan.itemTypes[id]) {
      console.error(`Asked to update item from plan but item not present in simulation - Plan: ${updatedPlan.id}, Item: ${id}`);
      // eslint-disable-next-line no-continue
      continue;
    }

    const type = simulatedPlan.itemTypes[id];

    switch (type) {
      case GardenItemType.Plant:
        const simulatedPlant = simulatedPlan.plants[id];

        if (!simulatedPlant) {
          console.error(`Simulation update from plant but SimulatedPlant with given Id not found ${id}`);
          // eslint-disable-next-line no-continue
          continue;
        }

        const planPlant = updatedPlan.plants[id];

        if (!planPlant) {
          console.error(`Simulation update from plant but PlanPlant with given Id not found ${id}`);
          // eslint-disable-next-line no-continue
          continue;
        }

        const updatedPlanPlant = updatePlanPlant(simulatedPlant, planPlant);

        updatedPlan = {
          ...updatedPlan,
          plants: {
            ...updatedPlan.plants,
            [updatedPlanPlant.id]: updatedPlanPlant,
          },
        };
        break;
      case GardenItemType.GardenObject:
        const simulatedGardenObject = simulatedPlan.gardenObjects[id];

        if (!simulatedGardenObject) {
          console.error(`Simulation update from garden object but SimulatedGardenObject with given Id not found ${id}`);
          // eslint-disable-next-line no-continue
          continue;
        }

        const planGardenObject = updatedPlan.gardenObjects[id];

        if (!planGardenObject) {
          console.error(`Simulation update from garden object but PlanGardenObject with given Id not found ${id}`);
          // eslint-disable-next-line no-continue
          continue;
        }

        const updatedPlanGardenObject = updatePlanGardenObject(simulatedGardenObject, planGardenObject);

        updatedPlan = {
          ...updatedPlan,
          gardenObjects: {
            ...updatedPlan.gardenObjects,
            [updatedPlanGardenObject.id]: updatedPlanGardenObject,
          },
        };
        break;
      case GardenItemType.Shape:
        const simulatedShape = simulatedPlan.shapes[id];

        if (!simulatedShape) {
          console.error(`Simulation update from text but SimulatedShape with given Id not found ${id}`);
          // eslint-disable-next-line no-continue
          continue;
        }

        const planShape = updatedPlan.shapes[id];

        if (!planShape) {
          console.error(`Simulation update from shape but PlanShape with given Id not found ${id}`);
          // eslint-disable-next-line no-continue
          continue;
        }

        const updatedPlanShape = updatePlanShape(simulatedShape, planShape);

        updatedPlan = {
          ...updatedPlan,
          shapes: {
            ...updatedPlan.shapes,
            [updatedPlanShape.id]: updatedPlanShape,
          },
        };
        break;
      case GardenItemType.Text:
        const simulatedText = simulatedPlan.text[id];

        if (!simulatedText) {
          console.error(`Simulation update from text but SimulatedText with given Id not found ${id}`);
          // eslint-disable-next-line no-continue
          continue;
        }

        const planText = updatedPlan.text[id];

        if (!planText) {
          console.error(`Simulation update from text but Plantext with given Id not found ${id}`);
          // eslint-disable-next-line no-continue
          continue;
        }

        const updatedPlanText = updatePlanText(simulatedText, planText);

        updatedPlan = {
          ...updatedPlan,
          text: {
            ...updatedPlan.text,
            [updatedPlanText.id]: updatedPlanText,
          },
        };
        break;
      default:
        console.error(`Garden type not found ${type}`);
    }
  }

  return updatedPlan;
}

export function updateRemovedItemsFromSimulated(plan: Plan, removedItems: number[], simulatedPlan: SimulatedPlan): Plan {
  let updatedPlan = plan;

  for (let i = 0; i < removedItems.length; i++) {
    const id = removedItems[i];

    if (!updatedPlan.itemTypes[id]) {
      console.error(`Attempting to remove an item to a plan which is not present - Plan: ${updatedPlan.id} Item: ${id}`);
      // eslint-disable-next-line no-continue
      continue;
    }

    if (simulatedPlan.itemTypes[id]) {
      console.error(`Asked to remove item from plan but is still present in simulation - Plan: ${updatedPlan.id}, Item: ${id}`);
      // eslint-disable-next-line no-continue
      continue;
    }

    const type = updatedPlan.itemTypes[id];

    switch (type) {
      case GardenItemType.Plant:
        const newPlants = { ...updatedPlan.plants };
        delete newPlants[id];

        const newPlantIds = [...updatedPlan.plantIds];
        newPlantIds.splice(newPlantIds.indexOf(id), 1);

        updatedPlan = {
          ...updatedPlan,
          plants: newPlants,
          plantIds: newPlantIds,
        };
        break;
      case GardenItemType.GardenObject:
        const newGardenObjects = { ...updatedPlan.gardenObjects };
        delete newGardenObjects[id];

        const newGardenObjectIds = [...updatedPlan.gardenObjectIds];
        newGardenObjectIds.splice(newGardenObjectIds.indexOf(id), 1);

        updatedPlan = {
          ...updatedPlan,
          gardenObjects: newGardenObjects,
          gardenObjectIds: newGardenObjectIds,
        };
        break;
      case GardenItemType.Shape:
        const newShapes = { ...updatedPlan.shapes };
        delete newShapes[id];

        const newShapeIds = [...updatedPlan.shapeIds];
        newShapeIds.splice(newShapeIds.indexOf(id), 1);

        updatedPlan = {
          ...updatedPlan,
          shapes: newShapes,
          shapeIds: newShapeIds,
        };
        break;
      case GardenItemType.Text:
        const newText = { ...updatedPlan.text };
        delete newText[id];

        const newTextIds = [...updatedPlan.textIds];
        newTextIds.splice(newTextIds.indexOf(id), 1);

        updatedPlan = {
          ...updatedPlan,
          text: newText,
          textIds: newTextIds,
        };
        break;
      default:
        console.error(`Garden type not found ${type}`);
    }

    const itemTypes = { ...updatedPlan.itemTypes };
    delete itemTypes[id];

    updatedPlan = {
      ...updatedPlan,
      itemTypes,
    };
  }

  return updatedPlan;
}
