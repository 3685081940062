import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';

import { config } from '@gi/config';

import GoogleMapLoading from './google-map-loading';
import GoogleMapError from './google-map-error';

interface LoadedMapProps {
  cameraPos: google.maps.LatLngLiteral;
  cameraZoom: number;
  children?: ReactNode;
  preventFullscreen?: boolean;
  /**
   * This will unrestrict the north/south borders of the map, allowing for more zoom out.
   */
  unrestrictMap?: boolean;
  /**
   * Adds touch-events: none to the container, preventing touch events bubbling.
   * May be needed if the map is in a fixed position container with overflow scrollbars.
   */
  captureTouchEvents?: boolean;
  onCameraChange?: (center: google.maps.LatLngLiteral, zoom: number) => void;
}

export interface LoadedMapHandles {
  /**
   * Sets the center of the map
   * @deprecated Use cameraPos prop
   * @param center The latitude and longitude
   */
  setCameraPos: (center: google.maps.LatLngLiteral) => void;
  /**
   * Sets the zoom of the map
   * @deprecated Use cameraZoom prop
   * @param zoom The zoom level
   */
  setCameraZoom: (zoom: number) => void;
  /**
   * Sets the center and zoom of the map
   * @deprecated Use cameraPos/cameraZoom props
   * @param center The coordinates for the center of the screen
   * @param zoom The zoom level
   */
  setCamera: (center: google.maps.LatLngLiteral, zoom: number) => void;
  /**
   * Attempts to convert an address to a location on the map
   * @param address The address to locate
   * @returns A list of possible locations, if any
   */
  geocode: (address: string) => Promise<google.maps.GeocoderResult[]>;
  geocoder: google.maps.Geocoder | undefined;
}

/**
 * Main Google Map, once the Google Maps API has loaded
 */
const LoadedMap = forwardRef<LoadedMapHandles, LoadedMapProps>(
  ({ cameraPos, cameraZoom, preventFullscreen = false, unrestrictMap = false, captureTouchEvents = false, children, onCameraChange }, ref): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const [geocoder, setGeocoder] = useState<google.maps.Geocoder>();
    const [eventListeners, setEventListeners] = useState<google.maps.MapsEventListener[]>([]);
    const [dragging, setDragging] = useState(false);

    const lastPos = useRef<google.maps.LatLngLiteral>(cameraPos);

    /**
     * Attach a map to the containing div once on startup.
     */
    useEffect(() => {
      if (containerRef.current && !map) {
        setMap(
          new google.maps.Map(containerRef.current, {
            center: cameraPos,
            zoom: cameraZoom,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: !preventFullscreen,
            restriction: unrestrictMap
              ? undefined
              : {
                  latLngBounds: {
                    east: Infinity,
                    north: 85,
                    south: -85,
                    west: -Infinity,
                  },
                  strictBounds: true,
                },
          })
        );
        setGeocoder(new google.maps.Geocoder());
      }
    }, [containerRef, map]);

    useEffect(() => {
      if (map) {
        const curCenter = map.getCenter();
        /**
         * This horrible set of checks does:
         * 1. Prevent updating the map while user is dragging (let Google handle things)
         * 2. Prevent setting the position to where we already are, which would kill the camera's velocity
         * 3. Prevent setting the position to our last known location, as it's probably a mistake
         */
        const isCurrentPosition = curCenter && curCenter.lat() === cameraPos.lat && curCenter.lng() === cameraPos.lng;
        const isLastPosition = cameraPos.lat === lastPos.current.lat && cameraPos.lng === lastPos.current.lng;

        if (dragging || isCurrentPosition || isLastPosition) {
          lastPos.current = cameraPos;
          return;
        }

        map.panTo(cameraPos);
        lastPos.current = cameraPos;
      }
    }, [cameraPos]);

    useEffect(() => {
      if (map) {
        if (!dragging) {
          map.setZoom(cameraZoom);
        }
      }
    }, [cameraZoom]);

    /**
     * Attach event listeners to the map whenever it's available/changes (should never change)
     */
    useEffect(() => {
      if (map) {
        // Remove existing listeners
        eventListeners.forEach((listener) => google.maps.event.removeListener(listener));

        // Event listener for when the zoom level changes
        const zoomChangedListener = map.addListener('zoom_changed', () => {
          const newZoom = map.getZoom();
          const newCenter = map.getCenter();
          if (newZoom && newCenter && onCameraChange) {
            onCameraChange(newCenter.toJSON(), newZoom);
          }
        });

        const updateCamera = () => {
          window.setTimeout(() => {
            const newZoom = map.getZoom();
            const newCenter = map.getCenter();
            if (newZoom && newCenter && onCameraChange) {
              onCameraChange(newCenter.toJSON(), newZoom);
            }
          }, 0);
        };

        // Event listener for when marker position changes
        // We can't use position_changed, as that also fires when we manually set the position
        const dragStartEventListener = map.addListener('dragstart', () => {
          setDragging(true);
          updateCamera();
        });
        const dragEventListener = map.addListener('drag', updateCamera);
        const dragEndEventListener = map.addListener('dragend', () => {
          setDragging(false);
          updateCamera();
        });

        // Store the event listeners to be destroyed later
        setEventListeners([zoomChangedListener, dragStartEventListener, dragEventListener, dragEndEventListener]);
      }
    }, [map]);

    /**
     * Set up external functions
     */
    useImperativeHandle(
      ref,
      () => ({
        setCameraPos(center: google.maps.LatLngLiteral) {
          if (map) {
            map.setCenter(center);
          }
        },
        setCameraZoom(zoom: number) {
          if (map) {
            map.setZoom(zoom);
          }
        },
        setCamera(center: google.maps.LatLngLiteral, zoom: number) {
          if (map) {
            map.panTo(center);
            map.setZoom(zoom);
          }
        },
        async geocode(address: string) {
          if (geocoder) {
            return (await geocoder.geocode({ address })).results;
          }
          throw new Error('Geocoder service not available');
        },
        geocoder,
      }),
      [map]
    );

    return (
      <div
        ref={containerRef}
        style={{
          width: '100%',
          height: '100%',
          touchAction: captureTouchEvents ? 'none' : undefined,
        }}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child as any, { map });
          }
          return child;
        })}
      </div>
    );
  }
);
LoadedMap.displayName = 'LoadedMap';

/**
 * Google Map element with loading/error states
 */
const GoogleMap = forwardRef<LoadedMapHandles, LoadedMapProps>(({ children, ...loadedMapProps }, ref): JSX.Element => {
  /**
   * Handles which component to render depending on the state of the map
   * @param state The state the map is currently in
   */
  const handleRender = (state: Status) => {
    switch (state) {
      case Status.LOADING:
        return <GoogleMapLoading />;
      case Status.FAILURE:
        return <GoogleMapError />;
      default:
        return (
          <LoadedMap ref={ref} {...loadedMapProps}>
            {children}
          </LoadedMap>
        );
    }
  };

  return <Wrapper apiKey={config.googleApiKey} render={handleRender} />;
});
GoogleMap.displayName = 'GoogleMap';

export type GoogleMapElement = LoadedMapHandles;

export default GoogleMap;
