/**
 * Stores information about time on the current frame.
 */
export type TimeData = {
  time: number; // The PCs current time
  deltaTime: number; // The time since the last frame
  localTime: number; // The total time elapsed by the engine since being started.
};

/**
 * Represents information about how long a frame took to produce.
 */
export type PerformanceData = {
  startAt: number;
  endAt: number;
  duration: number;
  tickDuration: number;
  stateDuration: number;
  drawDuration: number;
};

/**
 * Type to represent a class (not an instance of a class, the actual underlying class/constructor itself)
 */
export type ClassOf<T> = new (...args: any[]) => T;

/**
 * Types for providing metadata/scema for inspectable properties of classes.
 */
export enum InspectableClassPropertyType {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Vector2 = 'Vector2',
  Node = 'Node',
  Texture = 'Texture',
  State = 'State',
}

export enum InspectableClassDataType {
  Property = 'Property',
  CustomProperty = 'CustomProperty',
  Action = 'Action',
}

export type InspectableClassProperty<T> = {
  type: InspectableClassDataType.Property;
  property: keyof T;
  displayName?: string;
  propertyType: InspectableClassPropertyType;
};

export type InspectableClassCustomProperty = {
  type: InspectableClassDataType.CustomProperty;
  displayName: string;
  propertyType: InspectableClassPropertyType;
  value: () => any; // TODO
};

export type InspectableClassAction<T> = {
  type: InspectableClassDataType.Action;
  displayName: string;
  icon?: string;
  callback: (this: T) => void;
};

export type InspectableClassData<T> = (InspectableClassProperty<T> | InspectableClassCustomProperty | InspectableClassAction<T>)[];

// export type InspectableClassData<T> = {
//   properties?: InspectableClassProperty<T>[];
//   custom?: InspectableClassCustomProperty[]
//   actions?: InspectableClassAction<T>[];
// };

export interface Bounds {
  top: number;
  bottom: number;
  left: number;
  right: number;
}
