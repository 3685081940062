import { AnyAction, PayloadAction, ThunkAction, createSlice } from '@reduxjs/toolkit';

import { LoadingState } from '@gi/constants';
import { ThunkExtraArgs } from '@gi/garden-platform-services';
import { AsyncOperation } from '@gi/utils';
import { GardenPlatformEvent, isGardenPlatformEventAction } from '@gi/garden-platform-events';

import {
  CompletedObjectiveFromAPI,
  ObjectiveDataFromAPI,
  Objective,
  ObjectiveCompletionData,
  ObjectiveCondition,
  ObjectiveGroup,
  ObjectiveSettings,
} from '../objective-types';
import {
  checkEventParameters,
  completedObjectiveToAPI,
  convertCompletedObjectiveFromAPI,
  convertObjectivesFromAPI,
  getObjectiveUpdateTuples,
} from '../objective-utils';

export type ObjectivesState = {
  objectivesLoadState: LoadingState;
  completedObjectivesLoadState: LoadingState;
  objectivesMap: Record<string, Objective>;
  conditionsMap: Record<string, ObjectiveCondition<GardenPlatformEvent>>;
  conditionEventMap: Partial<Record<GardenPlatformEvent, string[]>>;
  groupsMap: Record<string, ObjectiveGroup>;
  groupsOrder: string[];
  completedObjectivesMap: Record<string, ObjectiveCompletionData>;
  settings: ObjectiveSettings;
  expandedGroups: string[];
};

const initialState: ObjectivesState = {
  objectivesLoadState: LoadingState.NONE,
  completedObjectivesLoadState: LoadingState.NONE,
  objectivesMap: {},
  conditionsMap: {},
  conditionEventMap: {},
  groupsMap: {},
  groupsOrder: [],
  completedObjectivesMap: {},
  settings: {
    showHelpSection: false,
    showOverallProgress: false,
  },
  expandedGroups: [],
};

const objectivesSlice = createSlice({
  name: 'objectives',
  initialState,
  reducers: {
    /** Used to initially load in the objectives JSON. */
    setObjectives(state, action: PayloadAction<AsyncOperation<ObjectiveDataFromAPI>>) {
      state.objectivesLoadState = action.payload.status;
      if (action.payload.status === LoadingState.SUCCESS) {
        const data = convertObjectivesFromAPI(action.payload.value);
        state.objectivesMap = data.objectivesMap;
        state.conditionsMap = data.conditionsMap;
        state.conditionEventMap = data.conditionEventMap;
        state.groupsMap = data.groupsMap;
        state.groupsOrder = data.groupsOrder;
        state.settings = data.settings;
      }
    },
    /** Used to initially load in the completed objectives from the server. All completed objectives loaded here will be marked as synced */
    setCompletedObjectives(state, action: PayloadAction<AsyncOperation<CompletedObjectiveFromAPI[]>>) {
      state.completedObjectivesLoadState = action.payload.status;
      if (action.payload.status === LoadingState.SUCCESS) {
        const completedObjectives = action.payload.value.map(convertCompletedObjectiveFromAPI);
        completedObjectives.forEach((completedObjective) => {
          state.completedObjectivesMap[completedObjective.id] = completedObjective;
        });
      }
    },
    /** Used internally to set if a completed objective has been successfully synced to the server */
    setCompletedObjectiveSyncState(state, action: PayloadAction<{ id: string; status: LoadingState }[]>) {
      for (let i = 0; i < action.payload.length; i++) {
        const { id, status } = action.payload[i];
        const objective = state.completedObjectivesMap[id];
        if (!objective) {
          return;
        }
        objective.syncState = status;
      }
    },
    /** Sets which groups in the accordion are expanded */
    setExpandedGroups(state, action: PayloadAction<string[]>) {
      state.expandedGroups = action.payload;
    },
  },
  extraReducers(builder) {
    /** Watch for garden platform events and attempt to mark objective conditions as completed */
    builder.addMatcher(isGardenPlatformEventAction, (state, action) => {
      if (!state.objectivesLoadState) {
        return;
      }
      const conditionIds = state.conditionEventMap[action.payload.eventName];
      if (!conditionIds) {
        return;
      }
      conditionIds.forEach((conditionId) => {
        const condition = state.conditionsMap[conditionId];
        // Skip completed conditions (or somehow missing conditions)
        if (!condition || condition.completed) {
          return;
        }

        // Check that the event matches the required parameters (if defined)
        if (!checkEventParameters(action.payload.parameters, condition.parameters)) {
          return;
        }
        condition.quantityTracked += 1;
        if (condition.quantityTracked < condition.quantityRequired) {
          return;
        }

        // This condition has just been completed.
        // Mark it complete and check if the objective is complete overall
        condition.completed = true;

        if (state.completedObjectivesMap[condition.objectiveId]) {
          return; // Objective has already been completed
        }

        const objective = state.objectivesMap[condition.objectiveId];
        if (!objective) {
          return; // Objective is missing somehow, skip
        }

        const isCompleted = objective.conditionIds.every((otherConditionId) => {
          const otherCondition = state.conditionsMap[otherConditionId];
          return otherCondition ? otherCondition.completed : false;
        });
        if (isCompleted) {
          state.completedObjectivesMap[objective.id] = {
            id: objective.id,
            date: Date.now(),
            syncState: LoadingState.NONE,
          };
        }
      });
    });
  },
});

export const objectivesReducer = objectivesSlice.reducer;

export const ObjectivesActionCreators = {
  ...objectivesSlice.actions,
  /**
   * Attempts to save the list of given completed objectives back to the server
   * @param objectives The objectives to save
   * @returns A thunk action to dispatch
   */
  saveCompletedObjectives: (objectives: ObjectiveCompletionData[]): ThunkAction<void, ObjectivesRootState, ThunkExtraArgs, AnyAction> => {
    return (dispatch, getState, { services }) => {
      const ids = objectives.map(({ id }) => id);
      dispatch(ObjectivesActionCreators.setCompletedObjectiveSyncState(getObjectiveUpdateTuples(ids, LoadingState.LOADING)));

      services.objectivesService
        .postCompletedObjectives(objectives.map(completedObjectiveToAPI))
        .then(() => {
          dispatch(ObjectivesActionCreators.setCompletedObjectiveSyncState(getObjectiveUpdateTuples(ids, LoadingState.SUCCESS)));
        })
        .catch(() => {
          dispatch(ObjectivesActionCreators.setCompletedObjectiveSyncState(getObjectiveUpdateTuples(ids, LoadingState.ERROR)));
        });
    };
  },
  /**
   * Attempts to save any completed objectives that have a sync state of NONE/ERROR.
   * @returns A thunk action to dispatch
   */
  saveUnsavedCompletedObjectives: (): ThunkAction<void, ObjectivesRootState, ThunkExtraArgs, AnyAction> => {
    return (dispatch, getState) => {
      const state = getState();
      const { completedObjectivesMap } = state.objectives;

      const completedObjectiveIds = Object.keys(completedObjectivesMap);
      const idsToSync = completedObjectiveIds.filter(
        (id) => completedObjectivesMap[id].syncState === LoadingState.NONE || completedObjectivesMap[id].syncState === LoadingState.ERROR
      );

      if (idsToSync.length === 0) {
        return;
      }

      const toSync = idsToSync.map((id) => completedObjectivesMap[id]);
      dispatch(ObjectivesActionCreators.saveCompletedObjectives(toSync));
    };
  },
  /**
   * Attempts to load the user's completed objectives from the API. Will skip if already loading.
   * @returns A thunk action to dispatch
   */
  loadCompletedObjectives: (): ThunkAction<void, ObjectivesRootState, ThunkExtraArgs, AnyAction> => {
    return (dispatch, getState, { services }) => {
      const state = getState();
      if (state.objectives.completedObjectivesLoadState === LoadingState.LOADING) {
        return;
      }
      dispatch(ObjectivesActionCreators.setCompletedObjectives({ status: LoadingState.LOADING }));
      services.objectivesService
        .getCompletedObjectives()
        .then((completedObjectives) => {
          dispatch(ObjectivesActionCreators.setCompletedObjectives({ status: LoadingState.SUCCESS, value: completedObjectives }));
        })
        .catch((error) => {
          dispatch(ObjectivesActionCreators.setCompletedObjectives({ status: LoadingState.ERROR, error }));
        });
    };
  },
};

export interface ObjectivesRootState {
  objectives: ObjectivesState;
}

export const ObjectivesSelectors = {
  getObjectives: (state: ObjectivesRootState) => state.objectives.objectivesMap,
  getObjectiveConditions: (state: ObjectivesRootState) => state.objectives.conditionsMap,
  getObjectiveCompletionData: (state: ObjectivesRootState) => state.objectives.completedObjectivesMap,
  getExpandedGroups: (state: ObjectivesRootState) => state.objectives.expandedGroups,
  getGroups: (state: ObjectivesRootState) => state.objectives.groupsMap,
  getGroupsOrder: (state: ObjectivesRootState) => state.objectives.groupsOrder,
  getSettings: (state: ObjectivesRootState) => state.objectives.settings,
  getObjectivesLoadState: (state: ObjectivesRootState) => state.objectives.objectivesLoadState,
  getCompletedObjectivesLoadState: (state: ObjectivesRootState) => state.objectives.completedObjectivesLoadState,
};
