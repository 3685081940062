import { State, StateDef } from '@gi/state';
import NodeComponent from '../../node-component/node-component';
import { bindState } from '../../utils/state-utils';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import TextContainer, { TextContainerOptions } from './text-container';
import { CameraNodeState } from '../../nodes/camera/camera-node';
import { createToggleableActiveCameraConnector } from '../../utils/camera-utils';

// The minimum fontSize to downscale to.
// 1.5 seems to be soft enough to avoid artifacting at all zoom levels.
const MINIMUM_MIPMAP_SIZE = 1.5;

export type TextComponentState = Omit<TextContainerOptions, 'mipmapLevel' | 'maxMipmapLevel'> & {
  // The magnification level at which the input fontSize/scale was designed for.
  baseCameraMagnification: number;
  useMipmaps: boolean;
};

export type TextComponentInternalState = StateDef<
  TextComponentState & {
    mipmapLevel: number;
    maxMipmapLevels: number;
  },
  [],
  {
    camera: CameraNodeState;
  }
>;

const DEFAULT_STATE: TextComponentInternalState['state'] = {
  fontFamily: ['Verdana', 'Helvetica', 'Arial'],
  fontSize: 10,
  fontScale: 4,
  color: 0x000000,
  maxMipmapLevels: 0,
  baseCameraMagnification: 4,
  mipmapLevel: 0,
  useMipmaps: false,
  style: {},
};

/**
 * Calculates the floored amount of times the input number would need to be halved to reach the target.
 * @param from The starting number
 * @param to The target number
 * @returns The amount of halvings required
 */
const getRequiredHalvings = (from: number, to: number = 1) => {
  const clampedMagnification = Math.min(to, from);
  const level = Math.floor(-(Math.log(clampedMagnification / from) / Math.LN2));
  return level; // - (level % 2);
};

/**
 * Text Component (v4)
 *  Capable of creating multiple instances of a piece of text, all using the same style.
 *  Can produce mipmapped versions of the text to be used at different zoom levels.
 */
class TextComponent extends NodeComponent {
  type = 'TextComponent';

  readonly state: State<TextComponentInternalState>;

  #textContainers: TextContainer[] = [];

  constructor(initialState: Partial<TextComponentState>) {
    super();

    this.state = new State({ ...DEFAULT_STATE, ...initialState });
    bindState(this.state, this);

    const cameraConnector = createToggleableActiveCameraConnector(this as NodeComponent, this.state, 'camera', this.state.values.useMipmaps);

    // Calculate the maximum amount of mipmap levels.
    this.state.addValidator(
      (state) => {
        if (state.values.useMipmaps) {
          state.values.maxMipmapLevels = getRequiredHalvings(state.values.fontSize, MINIMUM_MIPMAP_SIZE);
        } else {
          state.values.maxMipmapLevels = 0;
        }
        if (state.changed.properties.useMipmaps) {
          if (state.values.useMipmaps) {
            cameraConnector.enable();
          } else {
            cameraConnector.disable();
          }
        }
      },
      {
        properties: ['fontSize', 'useMipmaps'],
      }
    );

    // Calculate the desired mipmap level wenever the camera's zoom level changes.
    /**
     * TODO: Automatically calculating this should probably be togglable by a settings.
     *  In the future, PlantLabels should probably use a context to calculate and share this value.
     *  As it stands, each plant label will recalculate this each frame, which does involve a `Math.log` call
     */
    this.state.addUpdater(
      (state) => {
        const magnification = state.get('camera', 'magnification', 1);
        state.values.mipmapLevel = getRequiredHalvings(state.values.baseCameraMagnification, magnification);
      },
      { otherStates: { camera: { properties: ['magnification'] } } }
    );

    // Whenever any options update, let all the text containers know.
    // This should be a watcher really, but some calculations use these values, and waiting for a watcher causes race conditions.
    this.state.addUpdater(() => this.#updateTextContainerOptions(), {
      properties: ['color', 'fontFamily', 'fontScale', 'fontSize', 'style', 'maxMipmapLevels'],
    });

    // We also need to re-render the mipmaps. This sadly cannot be done on render, it needs to be done before, otherwise pixi doesn't update the transform
    this.state.addWatcher(() => this.#updateTextContainerMipmaps(), {
      properties: ['color', 'fontFamily', 'fontScale', 'fontSize', 'style', 'maxMipmapLevels'],
    });

    // Update all the texts mipmap levels whenever our internal mipmap level is recalculated.
    this.state.addWatcher(() => this.#updateTextContainerMipmapLevels(), {
      properties: ['mipmapLevel'],
    });
  }

  /**
   * Creates a new instance of text, using the current style settings.
   * To destroy the text, call `destroy()` on the created TextContainer.
   * @param text The text to create
   * @returns A new text container (wraps the text itself, so it can be updated without needing the parent to care)
   */
  createText(text: string): TextContainer {
    const textContainer = new TextContainer(
      text,
      {
        ...this.#getTextContainerOptions(),
        mipmapLevel: this.state.values.mipmapLevel,
      },
      this.onTextDestroyed
    );
    this.#textContainers.push(textContainer);
    return textContainer;
  }

  #getTextContainerOptions(): Omit<TextContainerOptions, 'mipmapLevel'> {
    return {
      color: this.state.values.color,
      fontFamily: this.state.values.fontFamily,
      fontScale: this.state.values.fontScale,
      fontSize: this.state.values.fontSize,
      maxMipmapLevel: this.state.values.maxMipmapLevels,
      style: this.state.values.style,
    };
  }

  #updateTextContainerOptions = () => {
    const options = this.#getTextContainerOptions();
    for (let i = 0; i < this.#textContainers.length; i++) {
      this.#textContainers[i].setOptions(options);
    }
  };

  #updateTextContainerMipmaps = () => {
    for (let i = 0; i < this.#textContainers.length; i++) {
      this.#textContainers[i].checkMipmaps();
    }
  };

  #updateTextContainerMipmapLevels = () => {
    for (let i = 0; i < this.#textContainers.length; i++) {
      this.#textContainers[i].mipmapLevel = this.state.values.mipmapLevel;
    }
  };

  protected onTextDestroyed = (textContainer: TextContainer) => {
    const id = this.#textContainers.indexOf(textContainer);
    if (id !== -1) {
      this.#textContainers.splice(id, 1);
    }
  };

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default TextComponent;
