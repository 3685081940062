import React from 'react';

interface iProps {
  content: string;
}

const BaseContent = ({ content }: iProps): JSX.Element => {
  return <div>{content}</div>;
};

export default BaseContent;
