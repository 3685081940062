import { InspectableClassData, KeybindComponent, Node } from '@gi/core-renderer';

import GardenItemSelectionMiddleware from '../../components/garden-item-selection-middleware';

class KeybindsNode extends Node {
  type = 'KeybindsNode';

  readonly deleteKeybind: KeybindComponent;

  constructor() {
    super();
    // Not sure why this one keybind is handled here, but all others are handled by `planner-menu-bar`
    // TODO: Move keybinds out of planner-menu-bar and into own component (as currently keybinds don't exist in mobile mode)
    this.deleteKeybind = this.components.add(new KeybindComponent([['delete', 'backspace']], true));
    this.deleteKeybind.onKeyDown = this.#onDeleteKeybindPressed;
  }

  #onDeleteKeybindPressed = () => {
    const context = this.tryGetContext(GardenItemSelectionMiddleware);
    context?.deleteSelection();
  };

  inspectorData: InspectableClassData<this> = [];
}

export default KeybindsNode;
