import { State } from '@gi/state';
import Node, { NodeEvent } from '../node';
import NodeComponent, { NodeComponentEvent } from '../node-component/node-component';
import { hasEngine } from './asserts';

/**
 * Binds this state to a node or component.
 * This will automatically connect the state to the node's engine's state manager.
 * NOTE: This action is irreversible currently
 * @param owner The owner of this state
 */
export function bindState<TOwner extends Node>(state: State<any>, owner: TOwner);
export function bindState<TOwner extends NodeComponent>(state: State<any>, owner: TOwner);
export function bindState(state: State<any>, owner: Node | NodeComponent) {
  state.owner = owner;

  const isNode = owner instanceof Node;

  const onBind = () => {
    if (isNode) {
      hasEngine(owner);
      state.manager = owner.engine.stateManager;
    } else {
      hasEngine(owner);
      state.manager = owner.owner.engine.stateManager;
    }
  };

  const beforeUnbind = () => {
    state.manager = null;
  };

  const destroy = () => {
    state.destroy();
  };

  // Check if the owner is already bound, and if so, bind this state to the engine state manager.
  if (isNode ? owner.engine : owner.owner?.engine) {
    onBind();
  }

  // Set up event listeners/destructors
  if (isNode) {
    owner.eventBus.on(NodeEvent.DidBind, onBind);
    owner.eventBus.on(NodeEvent.BeforeUnbind, beforeUnbind);
    owner.eventBus.on(NodeEvent.Destroyed, destroy);
  } else {
    owner.eventBus.on(NodeComponentEvent.DidBind, onBind);
    owner.eventBus.on(NodeComponentEvent.BeforeUnbind, beforeUnbind);
    owner.eventBus.on(NodeComponentEvent.Destroyed, destroy);
  }
}

/**
 * Utility function to bind a state watcher/updater to the lifecycle of a node/node component.
 * Will call the callback every time the owner binds to the engine, and automatically destroy the
 *  returned watcher/updater when the owner unbinds.
 * @param owner The owner of the state watcher/updater
 * @param callback The callback to create the watcher/updater. Called on bind.
 */
export function bindToLifecycle<TOwner extends Node>(owner: TOwner, callback: () => void | (() => void));
export function bindToLifecycle<TOwner extends NodeComponent>(owner: TOwner, callback: () => void | (() => void));
export function bindToLifecycle(owner: Node | NodeComponent, callback: () => void | (() => void)) {
  const isNode = owner instanceof Node;

  let thing: (() => void) | void;

  const onBind = () => {
    if (thing) {
      thing();
    }
    thing = callback();
  };

  const onBeforeUnbind = () => {
    if (thing) {
      thing();
    }
    thing = undefined;
  };

  if (owner.bound) {
    onBind();
  }

  // Set up event listeners
  if (isNode) {
    owner.eventBus.on(NodeEvent.DidBind, onBind);
    owner.eventBus.on(NodeEvent.BeforeUnbind, onBeforeUnbind);
    owner.eventBus.on(NodeEvent.Destroyed, onBeforeUnbind);
  } else {
    owner.eventBus.on(NodeComponentEvent.DidBind, onBind);
    owner.eventBus.on(NodeComponentEvent.BeforeUnbind, onBeforeUnbind);
    owner.eventBus.on(NodeComponentEvent.Destroyed, onBeforeUnbind);
  }
}
