import React, { ReactNode } from 'react';

import './mobile-nav-sublink.scss';

interface iProps {
  title: ReactNode;
  onClick: () => void;
  icon: string | ReactNode;
  parentActive?: boolean;
  className?: string;
}

const MobileNavigationSubLink = ({ title, onClick, icon, parentActive, className }: iProps): JSX.Element => {
  const classNames = ['mobile-navigation-sub-link'];
  if (parentActive) {
    classNames.push('parent-active');
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <li className={classNames.join(' ')}>
      <button type='button' onClick={onClick}>
        <span className='button-inner'>
          <span className='icon'>{typeof icon === 'string' ? <i className={icon} /> : icon}</span>
          <div className='text'>
            <span className='title'>{title}</span>
          </div>
        </span>
      </button>
    </li>
  );
};

export default MobileNavigationSubLink;
