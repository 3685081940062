import RequestBuilder, { HTTPRequestMethod, RequestAuthMode, Stringable } from './request-builder';
import { NetworkRequestError } from './request-error';

// Default auth mode to use for requests.
const DEFAULT_AUTH_MODE = RequestAuthMode.TicketAndBearer;
// Default timeout time (in ms) for requests
const DEFAULT_TIMEOUT = 60 * 1000;

type UserAuthInfo = {
  id: number;
  ticket: string;
  token: string;
};

type SimplifiedResponse<T> = {
  status: number;
  statusText: string;
  success: true;
  body: T;
  response: Response;
};

type RequestOptions = {
  /** Time (in ms) until the request is cancelled due to timeout */
  timeout?: number | null;
  /** Should the body of the request be printed in any console logs (for debugging) */
  includeRequestBodyInLogs?: boolean;
  /**
   * Should the request be marked as `keepalive`. Use for requests sent just before navigating away.
   * Requests are capped at 64kb. FireFox unsupported.
   * See {@link https://developer.mozilla.org/en-US/docs/Web/API/RequestInit#keepalive MDN Docs}
   */
  keepAlive?: boolean;
};

type BasicRequestOptions = Pick<RequestOptions, 'timeout'>;

/**
 * url-encodes the data for posting back to the server.
 *
 * Boolean values are converted to TRUE/FALSE, as this is what the old system did.
 * @param data The date to convert
 * @returns A url-encoded representation of the data
 */
const getFormData = (data: string | Record<string, Stringable>): string => {
  if (typeof data === 'string') {
    return data;
  }
  const params = new URLSearchParams();
  const keys = Object.keys(data);
  for (let i = 0; i < keys.length; i++) {
    const value =
      typeof data[keys[i]] === 'boolean'
        ? data[keys[i]].toString().toUpperCase() // Convert booleans to UPPERCASE
        : data[keys[i]].toString();

    params.set(keys[i], value);
  }
  return params.toString();
};

class NetworkService {
  #user: UserAuthInfo | null = null;

  /** Returns the current user auth information. Avoid using where possible. */
  get userAuth() {
    return this.#user;
  }

  /**
   * Sets the user auth information globally.
   *
   * This information will be used for all authenticated network requests until overwritten.
   * @param user The user auth info to use, or null for no user
   */
  setUser(user: UserAuthInfo | null) {
    this.#user = user;
  }

  /**
   * Makes a simple GET request, returning the response body as parsed JSON.
   * If the response is not OK, a RequestError will be thrown.
   * Mimics old `get` function.
   * @param url The URL to GET
   * @param queryParameters Any query parameters to append to the end of the URL
   * @param authMode Should any authentication be added to the request?
   * @returns The parsed response body. Errors if response not OK.
   */
  get<T>(
    url: string,
    queryParameters: Record<string, Stringable> | URLSearchParams = {},
    authMode: RequestAuthMode = DEFAULT_AUTH_MODE,
    { timeout = DEFAULT_TIMEOUT }: BasicRequestOptions | undefined = {}
  ): Promise<SimplifiedResponse<T>> {
    const request = new RequestBuilder(url)
      .setMethod('GET')
      .setTimeout(timeout)
      .setParameters(queryParameters)
      .setContentType('application/json')
      .setUser(this.#user)
      .setAuthMode(authMode)
      .setOptions({ errorIfNotOk: true });

    return request.execute().then((response) => NetworkService.parseResponseJSON<T>(response, request));
  }

  /**
   * Makes a simple GET request, returning the response body as parsed as a Blob.
   * If the response is not OK, a RequestError will be thrown.
   * Mimics old `get` function.
   * @param url The URL to GET
   * @param queryParameters Any query parameters to append to the end of the URL
   * @param authMode Should any authentication be added to the request?
   * @returns The parsed response body. Errors if response not OK.
   */
  getBlob(
    url: string,
    queryParameters: Record<string, Stringable> | URLSearchParams = {},
    authMode: RequestAuthMode = DEFAULT_AUTH_MODE
  ): Promise<SimplifiedResponse<Blob>> {
    const request = new RequestBuilder(url)
      .setMethod('GET')
      .setParameters(queryParameters)
      .setUser(this.#user)
      .setAuthMode(authMode)
      .setOptions({ errorIfNotOk: true });

    return request.execute().then((response) => NetworkService.parseResponseBlob(response, request));
  }

  protected requestWithBody<T>(
    method: Extract<HTTPRequestMethod, 'POST' | 'PUT'>,
    url: string,
    queryParameters: Record<string, Stringable> | URLSearchParams,
    data: any,
    authMode: RequestAuthMode = DEFAULT_AUTH_MODE,
    { timeout = DEFAULT_TIMEOUT, includeRequestBodyInLogs = false, keepAlive = false }: RequestOptions | undefined = {}
  ): Promise<SimplifiedResponse<T>> {
    const request = new RequestBuilder(url)
      .setMethod(method)
      .setTimeout(timeout)
      .setParameters(queryParameters)
      .setContentType('application/json')
      .setUser(this.#user)
      .setBody(JSON.stringify(data))
      .setAuthMode(authMode)
      .setOptions({ errorIfNotOk: true, includeRequestBodyInLogs, keepAlive });

    return request.execute().then((response) => NetworkService.parseResponseJSON<T>(response, request));
  }

  /**
   * Makes a simple POST request, returning the response body as parsed JSON.
   * If the response is not OK, a RequestError will be thrown.
   * Mimics old `post` function.
   * @param url The URL to POST to
   * @param queryParameters Any query parameters to append to the end of the URL
   * @param data The data to append to the body of the request. Will be JSON.stringify-d
   * @param authMode Should any authentication be added to the request?
   * @returns The parsed response body. Errors if response not OK.
   */
  post<T>(
    url: string,
    queryParameters: Record<string, Stringable> | URLSearchParams = {},
    data: any,
    authMode: RequestAuthMode = DEFAULT_AUTH_MODE,
    { timeout = DEFAULT_TIMEOUT, includeRequestBodyInLogs = false, keepAlive = false }: RequestOptions | undefined = {}
  ): Promise<SimplifiedResponse<T>> {
    return this.requestWithBody('POST', url, queryParameters, data, authMode, {
      timeout,
      includeRequestBodyInLogs,
      keepAlive,
    });
  }

  /**
   * Makes a simple PUT request, returning the response body as parsed JSON.
   * If the response is not OK, a RequestError will be thrown.
   * Mimics old `put` function.
   * @param url The URL to PUT to
   * @param queryParameters Any query parameters to append to the end of the URL
   * @param data The data to append to the body of the request. Will be JSON.stringify-d
   * @param authMode Should any authentication be added to the request?
   * @returns The parsed response body. Errors if response not OK.
   */
  put<T>(
    url: string,
    queryParameters: Record<string, Stringable> | URLSearchParams = {},
    data: any,
    authMode: RequestAuthMode = DEFAULT_AUTH_MODE,
    { timeout = DEFAULT_TIMEOUT, includeRequestBodyInLogs = false, keepAlive = false }: RequestOptions | undefined = {}
  ): Promise<SimplifiedResponse<T>> {
    return this.requestWithBody('PUT', url, queryParameters, data, authMode, {
      timeout,
      includeRequestBodyInLogs,
      keepAlive,
    });
  }

  /**
   * Makes a simple DELETE request. Assumes the response will have no content (204)
   * If the response is not OK, a RequestError will be thrown.
   * Mimics old `delete` function.
   * @param url The URL to DELETE
   * @param queryParameters Any query parameters to append to the end of the URL
   * @param authMode Should any authentication be added to the request?
   * @returns En empty response. Errors if response not OK.
   */
  delete(
    url: string,
    queryParameters: Record<string, Stringable> | URLSearchParams = {},
    authMode: RequestAuthMode = DEFAULT_AUTH_MODE,
    { timeout = DEFAULT_TIMEOUT, includeRequestBodyInLogs = false, keepAlive = false }: RequestOptions | undefined = {}
  ): Promise<SimplifiedResponse<undefined>> {
    const request = new RequestBuilder(url)
      .setMethod('DELETE')
      .setTimeout(timeout)
      .setParameters(queryParameters)
      .setContentType('application/json')
      .setUser(this.#user)
      .setAuthMode(authMode)
      .setOptions({ errorIfNotOk: true, includeRequestBodyInLogs, keepAlive });

    return request.execute().then((response) => {
      return new Promise((resolve, reject) => {
        if (response.ok) {
          resolve({
            status: response.status,
            statusText: response.statusText,
            success: true,
            body: undefined,
            response,
          });
        } else {
          reject(
            new NetworkRequestError({
              method: request.method,
              url: request.getURL(),
              statusCode: response.status,
              duration: request.time ?? undefined,
              response,
            })
          );
        }
      });
    });
  }

  /**
   * Makes a simple POST request, but encodes the data as url-encoded form data.
   * If the response is not OK, a RequestError will be thrown.
   * Mimics old `post` function.
   * @param url The URL to POST to
   * @param queryParameters Any query parameters to append to the end of the URL
   * @param data The data to append to the body of the request. Will be url-encoded
   * @param authMode Should any authentication be added to the request?
   * @returns The response body as a string. Errors if response not OK.
   */
  postFormData(
    url: string,
    queryParameters: Record<string, Stringable> | URLSearchParams,
    data: string | Record<string, Stringable>,
    authMode: RequestAuthMode = DEFAULT_AUTH_MODE,
    { timeout = DEFAULT_TIMEOUT, includeRequestBodyInLogs = false, keepAlive = false }: RequestOptions | undefined = {}
  ) {
    const request = new RequestBuilder(url)
      .setMethod('POST')
      .setTimeout(timeout)
      .setParameters(queryParameters)
      .setContentType('application/x-www-form-urlencoded')
      .setUser(this.#user)
      .setAuthMode(authMode)
      .setBody(getFormData(data))
      .setOptions({ errorIfNotOk: true, includeRequestBodyInLogs, keepAlive });

    return request.execute().then((response) => NetworkService.parseResponseText(response, request));
  }

  /**
   * Creates a new RequestBuilder, with the user and default auth mode already set.
   * @param url The URL to make the request to
   * @returns A new RequestBuilder
   */
  builder(url: string) {
    return new RequestBuilder(url).setUser(this.#user).setAuthMode(DEFAULT_AUTH_MODE).setContentType('application/json');
  }

  /**
   * Handles converting AbortErrors to NetworkRequestErrors
   *
   * In semi-rare cases, a response will be received, but the request will be aborted before the
   *  body is read. This leads to AbortErrors slipping through and not getting properly converted to
   *  NetworkRequestErrors.
   * @param response The response from the request
   * @param builder The builder that generated the request
   * @returns An error handler to convert AbortErrors to NetworkRequestErrors
   */
  protected static handleTimeoutErrors(response: Response, builder: RequestBuilder): (error: Error) => void {
    return (error: Error) => {
      const timedOut = error instanceof DOMException && error.name === 'AbortError';
      if (timedOut) {
        throw builder.makeError({ response, error, timedOut });
      }
      throw error;
    };
  }

  /**
   * Mimics how old request system handled responses that expected JSON back.
   *
   * If the response is OK, the body will be converted from JSON and returned.
   *
   * If the response is not OK, a new RequestError will be thrown, containing the status of the request.
   */
  protected static parseResponseJSON<T>(response: Response, builder: RequestBuilder): Promise<SimplifiedResponse<T>> {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        response
          .json()
          .then((json) => {
            resolve({
              status: response.status,
              statusText: response.statusText,
              success: true,
              body: json,
              response,
            });
          })
          .catch(this.handleTimeoutErrors(response, builder))
          .catch((error) => {
            // JSON parsing error
            reject(error);
          });
      } else {
        reject(builder.makeError({ response }));
      }
    });
  }

  /**
   * Mimics how old request system handled responses that expected text back.
   *
   * If the response is OK, the body will be read and returned.
   *
   * If the response is not OK, a new RequestError will be thrown, containing the status of the request.
   */
  protected static parseResponseText(response: Response, builder: RequestBuilder): Promise<SimplifiedResponse<string>> {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        response
          .text()
          .then((text) => {
            resolve({
              status: response.status,
              statusText: response.statusText,
              success: true,
              body: text,
              response,
            });
          })
          .catch(this.handleTimeoutErrors(response, builder))
          .catch((e) => {
            // Not sure if .text() can really error, but...
            reject(e);
          });
      } else {
        reject(builder.makeError({ response }));
      }
    });
  }

  /**
   * Mimics how old request system handled responses that expected blobs back.
   *
   * If the response is OK, the body will be read and returned.
   *
   * If the response is not OK, a new RequestError will be thrown, containing the status of the request.
   */
  protected static parseResponseBlob(response: Response, builder: RequestBuilder): Promise<SimplifiedResponse<Blob>> {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        response
          .blob()
          .then((blob) => {
            resolve({
              status: response.status,
              statusText: response.statusText,
              success: true,
              body: blob,
              response,
            });
          })
          .catch(this.handleTimeoutErrors(response, builder))
          .catch(reject);
      } else {
        reject(builder.makeError({ response }));
      }
    });
  }
}

const networkService = new NetworkService();

export default networkService;
