import React from 'react';

import styles from './tutorial-display.module.css';

interface iProps extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
  children;
}

const TutorialButton = ({ children, className = '', ...props }: iProps): JSX.Element => {
  return (
    <button {...props} type='button' className={`${styles.tutorialButton} ${className}`}>
      {children}
    </button>
  );
};

export default TutorialButton;
