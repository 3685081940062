import { Grid } from 'react-virtualized';

/**
 * Eases the given input
 *
 * {@link https://easings.net/}
 * @param x 0-1 to ease
 * @returns An eased version of 0-1
 */
function easeInOutCubic(x: number): number {
  return x < 0.5 ? 4 * x * x * x : 1 - (-2 * x + 2) ** 3 / 2;
}

/**
 * Smooth scrolls a react virtualised grid horizontally.
 * @param scrollLeft The new scrollLeft position
 * @param scrollPane The scrollpane to scroll
 * @param duration How long the animation should take (in ms)
 */
export function smoothScrollTo(scrollLeft: number, scrollPane: Grid, duration: number) {
  const startAt = Date.now();
  const startPos = scrollPane.state.scrollLeft;

  const loop = () => {
    const percentage = Math.min(1, Math.max(0, (Date.now() - startAt) / duration));
    const easedPercentage = easeInOutCubic(percentage);
    const newPos = startPos + (scrollLeft - startPos) * easedPercentage;
    scrollPane.scrollToPosition({ scrollLeft: newPos, scrollTop: 0 });

    if (percentage < 1) {
      requestAnimationFrame(loop);
    }
  };

  loop();
}
