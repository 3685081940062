import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';
import { config as generalConfig, networkConfig } from '@gi/config';
import NetworkRequestError from '@gi/gi-network/source/request-error';
import type { WeatherStation } from '../types';
import { getErrorMessage } from './weather-station-error-codes';
import { WeatherStationAPIResponse } from '../types';
import { fromObject } from '../utils';

interface WeatherStationServiceConfig {
  anonymousApiKey: string;
  endpoint: string;
}

export class WeatherStationService {
  config: WeatherStationServiceConfig;

  constructor(config: WeatherStationServiceConfig) {
    this.config = config;
  }

  /**
   * Returns the weather stations within range of the given coordinates
   * @param latitude
   * @param longitude
   * @returns A weather station collection.
   */
  getWeatherStations(latitude: number, longitude: number): Promise<WeatherStation[]> {
    const params = {
      lat: latitude,
      long: longitude,
      t: this.config.anonymousApiKey,
    };

    const url = `${this.config.endpoint}/weather-stations`;

    return networkService
      .get(url, params, RequestAuthMode.None)
      .then((response) => {
        const stations: WeatherStation[] = (response.body as WeatherStationAPIResponse).map((json) => fromObject(json));
        return stations;
      })
      .catch(networkErrorReporter('GET', 'weather stations'))
      .catch((err) => {
        if (err instanceof NetworkRequestError) {
          err.clientMessage = getErrorMessage(err.statusCode ?? -1);
        }
        throw err;
      });
  }
}

const weatherStationService = new WeatherStationService({
  endpoint: networkConfig.apiEndpoint,
  anonymousApiKey: generalConfig.anonymousApiKey,
});

export default weatherStationService;
