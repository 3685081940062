import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { CanvasSelectors } from '@gi/react-garden-canvas';
import EditTextModal from '@gi/edit-text-modal';

import { closeModal, editItemModalComplete } from '../modal-action-creators.ts';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    plan: CanvasSelectors.getActivePlan(state),
    textItemID: state.modal.editItemData.itemID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal,
      editItemModalComplete,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditTextModal);
