import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormRowWarningText } from '@gi/form';
import { UserPlantVarietySetShape, UserPlantVarietySetUtils } from '@gi/user';
import { DefaultModal, ModalFooterButtons, ModalFooterButtonsSection } from '@gi/modal';
import Plant from '@gi/plant';
import { SupplierCatalogueUtils } from '@gi/supplier';
import LoadingButton from '@gi/loading-button';
import FormField, { InputContainer } from '@gi/form-responsive';

const MAX_VARIETY_NAME_LENGTH = 30;

class AddVarietyModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      variety: '',
    };
  }

  onVarietyChange = (e) => {
    this.setState({
      variety: e.target.value,
    });
  };

  onDone = () => {
    this.props.onDone(this.state.variety.trim());
  };

  render() {
    const empty = this.state.variety.trim().length === 0;
    const alreadyPresent =
      UserPlantVarietySetUtils.hasVariety(this.props.userPlantVarieties, this.props.plant.code, this.state.variety.trim(), true) ||
      SupplierCatalogueUtils.hasInAnyCatalogue(this.props.supplierCatalogue, this.props.plant.code, this.state.variety.trim());

    const className = alreadyPresent ? 'form-row-warning' : '';
    const warning = alreadyPresent ? <FormRowWarningText>A variety with that name already exists for {this.props.plant.name}</FormRowWarningText> : null;

    let headerText = `Add ${this.props.plant.name} Variety`;
    if (this.props.title !== null) {
      headerText = this.props.title;
    }

    let buttonText = 'Add';
    if (this.props.buttonText !== null) {
      buttonText = this.props.buttonText;
    }

    const footer = (
      <ModalFooterButtons>
        <ModalFooterButtonsSection>
          <button type='button' className='button button-secondary' disabled={this.props.savingUser} onClick={this.props.closeModal}>
            Cancel
          </button>
        </ModalFooterButtonsSection>
        <ModalFooterButtonsSection>
          <LoadingButton loading={this.props.savingUser} className='button button-primary' disabled={alreadyPresent || empty} onClick={this.onDone}>
            {buttonText}
          </LoadingButton>
        </ModalFooterButtonsSection>
      </ModalFooterButtons>
    );

    return (
      <DefaultModal
        headerText={headerText}
        modalFooterContent={footer}
        oncloseRequest={() => {
          if (!this.props.savingUser) {
            this.props.closeModal();
          }
        }}
      >
        <FormField
          label='Variety Name'
          className={className}
          htmlFor='add-variety:name'
          invalid={alreadyPresent}
          desktopLayout={{ labelSize: 120 }}
          errorText={alreadyPresent ? 'A variety with that name already exists' : null}
        >
          <InputContainer>
            <input type='text' maxLength={MAX_VARIETY_NAME_LENGTH} value={this.state.variety} onChange={this.onVarietyChange} id='add-variety:name' />
          </InputContainer>
        </FormField>
        {warning}
      </DefaultModal>
    );
  }
}

AddVarietyModal.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string,
  plant: PropTypes.instanceOf(Plant).isRequired,
  closeModal: PropTypes.func.isRequired,
  userPlantVarieties: UserPlantVarietySetShape.isRequired,
  onDone: PropTypes.func.isRequired,
  supplierCatalogue: PropTypes.object.isRequired,
  savingUser: PropTypes.bool,
};

AddVarietyModal.defaultProps = {
  savingUser: false,
  title: null,
  buttonText: null,
};

export default AddVarietyModal;
