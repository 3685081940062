import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { OpenPlanStateUtils, PlannerSelectors } from '@gi/app-planner-slice';
import { PlannerTabTypes } from '@gi/constants';
import { LocalPlantListProvider } from '@gi/plant-list';
import { ItemLockingHelpModalRenderer } from '@gi/help-modals';
import { TouchDragHelpModalRenderer, TouchIntroductionModalRenderer } from '@gi/touch-introduction-modal';

import DesktopPlannerControlBar from './desktop-planner-control-bar';
import DesktopPlannerContent from './desktop-planner-content/desktop-planner-content';
import { PlannerCommandProvider } from './planner-command-provider';

import styles from './app-planner-desktop.module.css';

const AppPlannerDesktop = (): JSX.Element => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const activePlanId = useSelector(CanvasSelectors.getActivePlanID);
  const openPlanStates = useSelector(PlannerSelectors.getOpenPlanStates);

  const activePlannerTab = useMemo<PlannerTabTypes>(() => {
    if (activePlan === null) {
      return PlannerTabTypes.PLANNER;
    }

    if (!OpenPlanStateUtils.hasPlanState(openPlanStates, activePlan.id)) {
      console.warn('No active planner tab available from OpenPlanStates', activePlan.id);
      return PlannerTabTypes.PLANNER;
    }

    return OpenPlanStateUtils.getPlanState(openPlanStates, activePlan.id).activePlannerTab;
  }, [activePlan?.id, openPlanStates]);

  return (
    <div className={styles.plannerContainer}>
      <LocalPlantListProvider plan={activePlan}>
        <PlannerCommandProvider>
          <DesktopPlannerControlBar activePlannerTab={activePlannerTab} activePlan={activePlan} activePlanId={activePlanId} />
          <DesktopPlannerContent activePlannerTab={activePlannerTab} />
        </PlannerCommandProvider>
      </LocalPlantListProvider>
      {/* Touch controls help modals */}
      <TouchIntroductionModalRenderer />
      <TouchDragHelpModalRenderer />
      <ItemLockingHelpModalRenderer />
    </div>
  );
};

export default AppPlannerDesktop;
