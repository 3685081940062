import { Catalogue } from '../catalogue';
import catalogueVarietyParser from './catalogue-variety-parser';

/**
 * Parses a catalogue from a catalogue json object and returns a Catalogue with varieties
 *
 * @param {object} catalogue
 * @returns {Catalogue}
 */
function catalogueFromAPI(catalogue): Catalogue {
  try {
    return {
      varieties: catalogue.catalogueData.map(catalogueVarietyParser),
    };
  } catch (err) {
    console.error('Error parsing rcatalogue data');
    console.log(err);
    throw err;
  }
}

export default catalogueFromAPI;
