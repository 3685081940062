import React, { useEffect, useState } from 'react';

import { metricDistanceUnits, imperialDistanceUnits } from '@gi/units';
import type { DistanceUnits } from '@gi/units';
import { CM_IN_FOOT, INCHES_IN_FOOT } from '@gi/constants';
import { FormLayout, FORM_LAYOUT_PRESETS } from '@gi/form-responsive';

import NumericBlurInput from './numeric-blur-input';

import './length-input.scss';

const feetFromCM = (cm: number) => {
  const feet = cm / CM_IN_FOOT;
  const inches = Math.round(feet * INCHES_IN_FOOT);
  return Math.floor(inches / INCHES_IN_FOOT);
};

const inchesFromCM = (cm: number) => {
  const feet = cm / CM_IN_FOOT;
  const flooredFeet = feetFromCM(cm);
  return Math.round((feet - flooredFeet) * INCHES_IN_FOOT);
};

const totalInchesFromCM = (cm: number) => {
  const feet = cm / CM_IN_FOOT;
  return Math.round(feet * INCHES_IN_FOOT);
};

const metersFromCM = (cm: number) => {
  return Math.round(cm) / 100;
};

const cmFromCM = (cm: number) => {
  return Math.round(cm);
};

const cmFromFeetAndInches = (feet: number, inches: number) => {
  return Math.round((feet + inches / INCHES_IN_FOOT) * CM_IN_FOOT);
};

const cmFromMeters = (meters: number) => {
  return Math.round(meters * 100);
};

interface iProps {
  value: number;
  onChange: (cm: number) => void;
  distanceUnits: DistanceUnits;
  disabled?: boolean;
  showMinor?: boolean;
  valid?: boolean;
  inputClasses?: string;
  inline?: boolean;
  id?: string;
}

const LengthInput = ({
  value,
  onChange,
  distanceUnits,
  disabled = false,
  showMinor = false,
  valid = true,
  inputClasses = '',
  inline = false,
  id,
}: iProps): JSX.Element => {
  const [cm, setCm] = useState(cmFromCM(value));
  const [meters, setMeters] = useState(metersFromCM(value));
  const [feet, setFeet] = useState(feetFromCM(value));
  const [inches, setInches] = useState(inchesFromCM(value));
  const [totalInches, setTotalInches] = useState(totalInchesFromCM(value));

  useEffect(() => {
    setCm(cmFromCM(value));
    setMeters(metersFromCM(value));
    setFeet(feetFromCM(value));
    setInches(inchesFromCM(value));
    setTotalInches(totalInchesFromCM(value));
  }, [value]);

  const onMetersChange = (newMeters: number) => {
    onChange(cmFromMeters(newMeters));
  };

  const onFeetChange = (newFeet: number) => {
    onChange(cmFromFeetAndInches(newFeet, inches));
  };

  const onInchesChange = (newInches: number) => {
    onChange(cmFromFeetAndInches(feet, newInches));
  };

  const onTotalInchesChange = (newInches: number) => {
    onChange(cmFromFeetAndInches(0, newInches));
  };

  const onCMChange = (newCM: number) => {
    onChange(cmFromCM(newCM));
  };

  const cmInput = () => {
    let className = 'length-input-container';

    if (disabled) {
      className += ' disabled';
    }

    return (
      <NumericBlurInput
        id={id}
        value={cm}
        disabled={disabled}
        onChange={onCMChange}
        valid={valid}
        inputSuffix='cm'
        size={92}
        inputClassName={inputClasses}
        className={className}
        inputSuffixInline={inline}
      />
    );
  };

  const inchInput = () => {
    let className = 'length-input-container';

    if (disabled) {
      className += ' disabled';
    }

    return (
      <NumericBlurInput
        id={id}
        value={totalInches}
        disabled={disabled}
        onChange={onTotalInchesChange}
        valid={valid}
        inputSuffix='inches'
        size={92}
        inputClassName={inputClasses}
        className={className}
        inputSuffixInline={inline}
      />
    );
  };

  const metricInput = () => {
    let className = 'length-input-container';

    if (disabled) {
      className += ' disabled';
    }

    return (
      <NumericBlurInput
        id={id}
        value={meters}
        disabled={disabled}
        onChange={onMetersChange}
        valid={valid}
        inputSuffix='m'
        size={92}
        inputClassName={inputClasses}
        className={className}
        inputSuffixInline={inline}
      />
    );
  };

  const imperialInput = () => {
    let className = 'length-input-container';

    if (disabled) {
      className += ' disabled';
    }

    return (
      <FormLayout className={className} layoutPreset={FORM_LAYOUT_PRESETS.Row}>
        <NumericBlurInput
          id={id}
          value={feet}
          disabled={disabled}
          onChange={onFeetChange}
          valid={valid}
          inputSuffix='feet'
          size={92}
          inputClassName={inputClasses}
          inputSuffixInline={inline}
        />
        <NumericBlurInput
          value={inches}
          disabled={disabled}
          onChange={onInchesChange}
          valid={valid}
          inputSuffix='inches'
          size={88}
          inputClassName={inputClasses}
          inputSuffixInline={inline}
        />
      </FormLayout>
    );
  };

  if (distanceUnits === metricDistanceUnits) {
    if (showMinor) {
      return cmInput();
    }
    return metricInput();
  }

  if (distanceUnits === imperialDistanceUnits) {
    if (showMinor) {
      return inchInput();
    }
    return imperialInput();
  }

  console.error('Invalid distance units provided');
  if (showMinor) {
    return cmInput();
  }
  return metricInput();
};

export default LengthInput;
