import React, { ReactNode, createContext, useCallback, useEffect, useMemo, useRef } from 'react';

import ResizeObserverPolyfill from '@gi/resize-observer-ponyfill';

import Engine from '../engine';
import EngineDebuggerLoader from './debugger/engine-debugger-loader';

export type EngineContextType = {
  engine: Engine | null;
};

export const EngineContext = createContext<EngineContextType>({} as EngineContextType);

interface iProps {
  children: ReactNode;
  container: HTMLElement | null;
  engine?: Engine;
}

const EngineProvider = ({ children, container, engine }: iProps): JSX.Element => {
  const resizeObserver = useRef<ResizeObserver>();

  /**
   * When the container resizes, pass that info onto the engine
   */
  const onResize = useCallback<ResizeObserverCallback>(
    (entries) => {
      if (engine) {
        engine.resize(entries[0].contentRect.width, entries[0].contentRect.height);
      }
    },
    [engine]
  );

  /**
   * Update the container every time either the container or the engine updates
   */
  useEffect(() => {
    resizeObserver.current?.disconnect();
    resizeObserver.current = new ResizeObserverPolyfill(onResize);
    if (container && engine) {
      engine.setContainer(container);
      container.focus();
      resizeObserver.current.observe(container);
    }
  }, [container, engine]);

  const value = useMemo<EngineContextType>(
    () => ({
      engine: engine ? engine : null,
    }),
    [engine]
  );

  return (
    <EngineContext.Provider value={value}>
      <EngineDebuggerLoader engine={engine ?? null}>{children}</EngineDebuggerLoader>
    </EngineContext.Provider>
  );
};

export default EngineProvider;
