import React, { CSSProperties } from 'react';
import { renderToString } from 'react-dom/server';

import { networkConfig } from '@gi/config';
import { IS_MAC, ShapeType } from '@gi/constants';

/**
 * Windows arrow cursor SVG.
 * Taken from Windows files. Modified to be the correct way up, cus Windows stores them pointing down?
 * Also those are the actual colours set by Microsoft. Who knows why we need the blue channel to be off by 1.
 */
const WINDOWS_CURSOR = (
  <polygon
    style={{ fill: 'rgb(255, 255, 254)', stroke: 'rgb(0, 0, 1)', strokeMiterlimit: 10 }}
    points='11.973 12.746 7.038 12.746 9.065 16.869 6.12 18.362 3.86 13.728 0.5 17.004 0.5 1.212'
  />
);

/**
 * Mac arrow cursor SVG.
 */
const MAC_CURSOR = (
  <>
    <path d='M 6.137 18.066 L 8 17.063 L 9.615 16.224 L 7.047 11.408 L 11.379 11.408 L 0 0 L 0 16.015 L 3.316 12.794 L 6.137 18.066 Z' fill='#fff' />
    <path d='M 6.42 16.593 L 8.185 15.652 L 5.41 10.45 L 9.014 10.45 L 0.989 2.407 L 0.989 13.595 L 3.519 11.153 L 6.42 16.593 Z' fill='#000' />
  </>
);

const CURSOR = IS_MAC ? MAC_CURSOR : WINDOWS_CURSOR;

/**
 * Plus icon SVG. Style differently depending on OS.
 */
const PLUS_ICON = (
  <>
    <path d='M -2 -5 H 2 V -2 H 5 V 2 H 2 V 5 H -2 V 2 H -5 V -2 H -2 Z' style={{ fill: IS_MAC ? '#fff' : '#000', paintOrder: 'fill' }} />
    <path d='M -1 -4 H 1 V -1 H 4 V 1 H 1 V 4 H -1 V 1 H -4 V -1 H -1 Z' style={{ fill: IS_MAC ? '#000' : '#fff', paintOrder: 'fill' }} />
  </>
);

/**
 * Asterisk icon SVG. Style differently depending on OS.
 */
// const ASTERISK_ICON = (
//   <path
//     d='M 5.41 0.028 L 1.904 0.636 L 4.361 3.202 L 1.601 5.134 L 0 2.016 L -1.601 5.134 L -4.361 3.202 L -1.904 0.636 L -5.41 0.028 L -4.361 -3.146 L -1.187 -1.6 L -1.711 -5.133 L 1.684 -5.133 L 1.187 -1.6 L 4.361 -3.146 L 5.41 0.028 Z'
//     style={{
//       fill: (IS_MAC) ? '#000' : '#fff',
//       stroke: (IS_MAC) ? '#fff' : '#000',
//       strokeWidth: 1,
//       paintOrder: 'fill'
//     }}
//   />
// );

/**
 * Repeat icon SVG. Style differently depending on OS.
 */
const REPEAT_ICON = (
  <path
    d='M 0 5.5 A 5.5 5.5 90 1 1 5.5 0 L 8 0 L 4 4 L 0 0 L 2.5 0 A 2.5 2.5 90 1 0 0 2.5 Z'
    style={{
      fill: IS_MAC ? '#000' : '#fff',
      stroke: IS_MAC ? '#fff' : '#000',
      strokeWidth: 1,
      paintOrder: 'fill',
    }}
  />
);

// Repeat icon paths
// Unused: At this scale, they're not readable at all.
// M 0 11 A 11 11 90 1 1 11 0 L 16 0 L 8 8 L 0 0 L 5 0 A 5 5 90 1 0 0 5 Z
// M 0 5.5 A 5.5 5.5 90 1 1 5.5 0 L 8 0 L 4 4 L 0 0 L 2.5 0 A 2.5 2.5 90 1 0 0 2.5 Z

/**
 * Loads an icon as a data URL.
 * @param countryCode The user's country code
 * @param itemCode The unique item code of the item
 * @returns A string representing the icon
 */
export const loadIcon = (countryCode: string, itemCode: string) => {
  return new Promise<string>((resolve, reject) => {
    const url = `${networkConfig.iconURLS[countryCode]}${itemCode}-icon.png`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Response not OK');
        }

        // Rather than (read as image) -> (draw to canvas) -> (convert to data URL),
        // we can load it as a blob and read it directly as a data URL, saving time.
        return response.blob();
      })
      .then((blob) => {
        const fileReader = new FileReader();

        fileReader.onload = () => {
          if (fileReader.result === null) {
            throw new Error('FileReader failed to produce a result from given Blob');
          }
          if (fileReader.result instanceof ArrayBuffer) {
            throw new Error('FileReader produced an ArrayBuffer, expected a string');
          }
          resolve(fileReader.result);
        };

        fileReader.readAsDataURL(blob);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Encodes an SVG to work in a data URL.
 * Chrome is fine with doing this as a Base64 image, but Firefox isn't...
 * Using encodeURIComponent also doesn't seem to work in FireFox.
 *
 * Adapted from {@link https://gist.github.com/jennyknuth/222825e315d45a738ed9d6e04c7a88d0 "How to transform an SVG into a data URI"}
 * @param svgString The SVG (as a string)
 * @returns The SVG (as a string) (but also encoded)
 */
const encodeSvg = (svgString: string): string => {
  return svgString
    .replace(/"/g, "'")
    .replace(/%/g, '%25')
    .replace(/#/g, '%23')
    .replace(/{/g, '%7B')
    .replace(/}/g, '%7D')
    .replace(/</g, '%3C')
    .replace(/>/g, '%3E')

    .replace(/\s+/g, ' ')
    .replace(/&/g, '%26')
    .replace('|', '%7C')
    .replace('[', '%5B')
    .replace(']', '%5D')
    .replace('^', '%5E')
    .replace('`', '%60')
    .replace(';', '%3B')
    .replace('?', '%3F')
    .replace(':', '%3A')
    .replace('@', '%40')
    .replace('=', '%3D');
};

/**
 * Converts the user-set cursor scale to a scale-factor.
 * Turns out Windows, with a cursor scale of 2, generates a cursor 1.5x bigger.
 * This mimics that _interesting_ decision, so the cursor scales match between our settings and Windows.
 * @param cursorScale The user-set cursor scale
 * @param dpr The DPR of the browser. Only set if using Safari.
 * @returns A scale factor to scale the SVGs by
 */
const getScaleFactor = (cursorScale: number, dpr: number = 1): number => {
  return (1 + (cursorScale - 1) * 0.5) * dpr;
};

/**
 * Creates an SVG cursor to represent adding something.
 * Used when the icon hasn't loaded yet.
 * @param cursorScale The scale of the cursor (max 4)
 * @param dpr The DPR of the browser. Only set if using Safari.
 * @param repeat If the repeat icon should be shown
 * @returns An SVG in DataURL format
 */
export const makeEmptyItemCursor = (cursorScale: number, dpr: number = 1, repeat: boolean = false) => {
  const scaleFactor = getScaleFactor(cursorScale, dpr);

  const svg = (
    <svg viewBox='0 0 32 36' xmlns='http://www.w3.org/2000/svg' width={32 * scaleFactor} height={36 * scaleFactor}>
      <g transform='translate(17, 18)'>{PLUS_ICON}</g>
      {repeat ? <g transform='translate(6.5, 27.5) rotate(-45)'>{REPEAT_ICON}</g> : null}
      {CURSOR}
    </svg>
  );

  const svgAsBase64 = encodeSvg(renderToString(svg));
  return `data:image/svg+xml,${svgAsBase64}`;
};

/**
 * Creates an SVG cursor to represent adding an item.
 * @param cursorScale The scale of the cursor (max 4)
 * @param dpr The DPR of the browser. Only set if using Safari.
 * @param imageDataUrl The icon image, as a DataURL
 * @param borderColour The border colour around the icon
 * @param repeat If the repeat icon should be shown
 * @returns An SVG in DataURL format
 */
export const makeItemCursor = (cursorScale: number, dpr: number = 1, imageDataUrl: string, borderColour: string = '#dddddd', repeat: boolean = false) => {
  const scaleFactor = getScaleFactor(cursorScale, dpr);

  const svg = (
    <svg viewBox='0 0 32 52' xmlns='http://www.w3.org/2000/svg' width={32 * scaleFactor} height={52 * scaleFactor}>
      <ellipse style={{ fill: borderColour }} cx='14.5' cy='33.5' rx='13.5' ry='13.5' />
      <ellipse style={{ fill: 'rgb(255, 255, 255)' }} cx='14.5' cy='33.5' rx='11.5' ry='11.5' />
      <image x='3' y='22' width='23' height='23' href={imageDataUrl} />
      <g transform='translate(24, 24)'>{PLUS_ICON}</g>
      {repeat ? <g transform='translate(23, 44) rotate(-45)'>{REPEAT_ICON}</g> : null}
      {CURSOR}
    </svg>
  );

  const svgAsBase64 = encodeSvg(renderToString(svg));
  return `data:image/svg+xml,${svgAsBase64}`;
};

/**
 * Creates an SVG cursor to represent adding a shape.
 * @param cursorScale The scale of the cursor (max 4)
 * @param dpr The DPR of the browser. Only set if using Safari.
 * @param shape The shape the user is trying to draw
 * @param filled If the shape will be filled or not
 * @param repeat If the repeat icon should be shown
 * @returns An SVG in DataURL format
 */
export const makeShapeCursor = (cursorScale: number, dpr: number = 1, shape: ShapeType | 'TEXT', filled: boolean, repeat: boolean = false) => {
  const scaleFactor = getScaleFactor(cursorScale, dpr);
  const style: CSSProperties = filled ? { fill: '#000000' } : { stroke: '#000000', strokeWidth: 1, fill: 'none' };

  let icon: JSX.Element | null;
  switch (shape) {
    case ShapeType.ELLIPSE:
      icon = <ellipse style={style} cx='15' cy='20' rx='6.5' ry='6.5' />;
      break;
    case ShapeType.RECTANGLE:
      icon = <rect style={style} x='8.5' y='15.5' width='13' height='9' />;
      break;
    case ShapeType.TRIANGLE:
      icon = <path style={style} d='M 15 13.75 L 20.75 25.5 L 9.25 25.5 L 14.75 13.75 L 15 13.75 Z' />;
      break;
    case ShapeType.LINE:
      icon = <path style={{ stroke: '#000000', strokeWidth: 1 }} d='M 9 26 L 21 14' />;
      break;
    case 'TEXT':
      // eslint-disable-next-line max-len
      icon = (
        <path
          style={style}
          // eslint-disable-next-line max-len
          d='M 20.721 24.174 L 20.113 24.174 L 16.717 14.763 C 16.603 14.427 16.287 14.2 15.93 14.2 L 14.705 14.2 C 14.35 14.2 14.033 14.427 13.919 14.763 L 10.523 24.174 L 9.916 24.174 C 9.686 24.174 9.5 24.36 9.5 24.59 L 9.5 25.421 C 9.5 25.65 9.686 25.836 9.916 25.836 L 13.24 25.836 C 13.47 25.836 13.656 25.65 13.656 25.421 L 13.656 24.59 C 13.656 24.36 13.47 24.174 13.24 24.174 L 12.732 24.174 L 13.337 22.511 L 17.3 22.511 L 17.905 24.174 L 17.396 24.174 C 17.166 24.174 16.98 24.36 16.98 24.59 L 16.98 25.421 C 16.98 25.65 17.166 25.836 17.396 25.836 L 20.721 25.836 C 20.95 25.836 21.136 25.65 21.136 25.421 L 21.136 24.59 C 21.136 24.36 20.95 24.174 20.721 24.174 Z M 14.093 20.434 L 15.318 17.07 L 16.542 20.434 L 14.093 20.434 Z'
        />
      );
      break;
    default:
      icon = null;
  }

  const svg = (
    <svg viewBox='0 0 32 52' xmlns='http://www.w3.org/2000/svg' width={32 * scaleFactor} height={52 * scaleFactor}>
      <g transform='translate(-2, 15)'>
        <rect style={{ stroke: '#dddddd', strokeWidth: 2, fill: 'rgb(255, 255, 255)' }} x='4' y='9' width='22' height='22' rx='3' ry='3' />
        {icon}
        <g transform='translate(26, 9)'>{PLUS_ICON}</g>
        {repeat ? <g transform='translate(26.5, 31) rotate(-45)'>{REPEAT_ICON}</g> : null}
      </g>
      {CURSOR}
    </svg>
  );

  const svgAsBase64 = encodeSvg(renderToString(svg));
  return `data:image/svg+xml,${svgAsBase64}`;
};
