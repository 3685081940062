import { networkConfig, NetworkDataLocationConfig } from '@gi/config';
import { Collection } from '@gi/collection';
import Plant, { plantFromObject } from '@gi/plant';
import { GardenObject, GardenObjectAPIResponse, GardenObjectGroup, GardenObjectParserUtils } from '@gi/garden-object';
import { countryFromObject, CountryCollection, CountryAPIResponse } from '@gi/country';
import CountryRegion, { CountryRegionAPIResponse, CountryRegionUtils } from '@gi/country-region';
import { parseRegionalPlantingCalendarsFromAPI } from '@gi/planting-calendar';
import { CatalogueService, SupplierService } from '@gi/supplier';
import { RequestAuthMode, RequestError, defaultNetworkErrorParser, networkErrorReporter, networkService } from '@gi/gi-network';
import TemplatePlan, { TemplatePlanService } from '@gi/template-plan';
import { loadSpritesheet } from '@gi/styles/source/spritesheets/spritesheet-loader';
import { Pest, pestFromObject } from '@gi/pest';
import { GuruPages } from '@gi/app-guru-types';
import { guruService } from '@gi/garden-platform-services';
import type { Tutorials } from '@gi/tutorial';
import { TutorialService } from '@gi/tutorial';
import { ObjectiveDataFromAPI, ObjectivesService } from '@gi/objectives';
import { ParsedTooltips, TooltipService } from '@gi/tooltip';
import { ProductService, Store } from '@gi/products';

// Time until request times out for loading resources
const TIMEOUT_DURATION = 1000 * 60 * 4; // 4 minute timeout time on resources.

const createErrorHandler = (type: string) => {
  return (err: Error | RequestError) => {
    if (err instanceof RequestError) {
      console.log(`Error loading ${type}: ${err.statusCode} ${err.statusCodeText}`);
    } else {
      console.log(`Error loading ${type}: ${err}`);
    }
    throw defaultNetworkErrorParser(err);
  };
};

const countryDataParser = (countryJSON: CountryAPIResponse) => {
  try {
    const countries = new CountryCollection();
    countryJSON.countries.forEach((country) => countries.add(countryFromObject(country)));

    return countries;
  } catch (err) {
    console.error('Error parsing country data');
    console.log(err);
    throw err;
  }
};

export const loadCountryData = (networkDataConfig: NetworkDataLocationConfig): Promise<CountryCollection> => {
  return networkService
    .get<CountryAPIResponse>(networkDataConfig.countries, {}, RequestAuthMode.None, {
      timeout: TIMEOUT_DURATION,
    })
    .then((countryJSON) => {
      return countryDataParser(countryJSON.body);
    })
    .catch(networkErrorReporter('GET', 'countries'))
    .catch(createErrorHandler('Country Data'));
};

export const countryRegionDataParser = (regionJSON: CountryRegionAPIResponse) => {
  try {
    const countryRegions = new Collection<Collection<CountryRegion>>();
    regionJSON.regions.forEach((region) => {
      if (!countryRegions.has(region.countryCode)) {
        countryRegions.add(new Collection<CountryRegion>(), region.countryCode);
      }
      const regions = countryRegions.get(region.countryCode);
      if (regions) {
        regions.add(CountryRegionUtils.fromObject(region), region.regionID.toString());
      }
    });

    return countryRegions;
  } catch (e) {
    console.error('Error parsing country region data');
    console.log(e);
    throw e;
  }
};

export const loadCountryRegionData = (networkDataConfig: NetworkDataLocationConfig): Promise<ReturnType<typeof countryRegionDataParser>> => {
  return networkService
    .get<CountryRegionAPIResponse>(networkDataConfig.countryRegions, {}, RequestAuthMode.None, {
      timeout: TIMEOUT_DURATION,
    })
    .then((regionJSON) => {
      return countryRegionDataParser(regionJSON.body);
    })
    .catch(networkErrorReporter('GET', 'country regions'))
    .catch(createErrorHandler('Country Region Data'));
};

const pestDataParser = (pestJSON) => {
  try {
    const pests = new Collection<Pest>();

    pestJSON.pests.forEach((pestObj) => {
      const pest = pestFromObject(pestObj);
      pests.add(pest, pest.code);
    });

    return pests;
  } catch (err) {
    console.error('Error parsing pest data');
    console.log(err);
    throw err;
  }
};

export const loadPestData = (networkDataConfig: NetworkDataLocationConfig, countryCode: string): Promise<ReturnType<typeof pestDataParser>> => {
  return networkService
    .get<unknown>(`${networkDataConfig.pestsDir}pests-${countryCode}.json`, {}, RequestAuthMode.None, { timeout: TIMEOUT_DURATION })
    .then((pestJSON) => {
      return pestDataParser(pestJSON.body);
    })
    .catch(networkErrorReporter('GET', 'pests'))
    .catch(createErrorHandler('Pest Data'));
};

const plantDataParser = (plantJSON) => {
  try {
    const plants = new Collection<Plant>();

    plantJSON.plants.forEach((plantObj) => {
      const plant = plantFromObject(plantObj);
      plants.add(plant, plant.code);
    });

    return plants;
  } catch (err) {
    console.error('Error parsing plant data');
    console.log(err);
    throw err;
  }
};

export const loadPlantData = (networkDataConfig: NetworkDataLocationConfig, countryCode: string): Promise<ReturnType<typeof plantDataParser>> => {
  return networkService
    .get<unknown>(`${networkDataConfig.plantsDir}plants-${countryCode}.json`, {}, RequestAuthMode.None, { timeout: TIMEOUT_DURATION })
    .then((plantJSON) => {
      return plantDataParser(plantJSON.body);
    })
    .catch(networkErrorReporter('GET', 'plants'))
    .catch(createErrorHandler('Plant Data'));
};

export interface ParsedGardenObjectsData {
  gardenObjects: Collection<GardenObject>;
  gardenObjectGroups: Collection<GardenObjectGroup>;
}

const parseGardenObjectData = (gardenObjectJSON: GardenObjectAPIResponse): ParsedGardenObjectsData => {
  try {
    const gardenObjects = new Collection<GardenObject>();
    const gardenObjectGroups = new Collection<GardenObjectGroup>();

    gardenObjectJSON.gardenObjectGroups?.forEach((gardenObjectGroupObj) => {
      const group = GardenObjectParserUtils.gardenObjectGroupFromAPI(gardenObjectGroupObj);
      gardenObjectGroups.add(group, group.id);
    });

    gardenObjectJSON.gardenObjects.forEach((gardenObjectObj) => {
      const gardenObject = GardenObjectParserUtils.gardenObjectFromAPI(gardenObjectObj);
      gardenObjects.add(gardenObject, gardenObject.code);

      if (gardenObject.groupId) {
        const group = gardenObjectGroups.get(gardenObject.groupId);
        if (group) {
          group.objectCodes.push(gardenObject.code);
        }
      }
    });

    return { gardenObjects, gardenObjectGroups };
  } catch (err) {
    console.error('Error parsing garden object data');
    console.log(err);
    throw err;
  }
};

export const loadGardenObjectData = (networkDataConfig: NetworkDataLocationConfig, countryCode: string): Promise<ParsedGardenObjectsData> => {
  return networkService
    .get<GardenObjectAPIResponse>(`${networkDataConfig.gardenObjectsDir}garden-objects-${countryCode}.json`, {}, RequestAuthMode.None, {
      timeout: TIMEOUT_DURATION,
    })
    .then((gardenObjectJSON) => {
      return parseGardenObjectData(gardenObjectJSON.body);
    })
    .catch(networkErrorReporter('GET', 'garden objects'))
    .catch(createErrorHandler('Garden Objects'));
};

const parseRegionalPlantingCalendarsData = (plantingDatesJSON, countryCode, regionID) => {
  try {
    return parseRegionalPlantingCalendarsFromAPI(plantingDatesJSON, countryCode, regionID);
  } catch (err) {
    console.error('Error parsing regional planting data');
    console.log(err);
    throw err;
  }
};

export const loadRegionalPlantingDatesData = (
  networkDataConfig: NetworkDataLocationConfig,
  countryCode: string,
  regionID: number
): Promise<ReturnType<typeof parseRegionalPlantingCalendarsData>> => {
  return networkService
    .get<unknown>(`${networkDataConfig.regionalPlantingDatesDir}${countryCode}-${regionID}-planting-dates.json`, {}, RequestAuthMode.None, {
      timeout: TIMEOUT_DURATION,
    })
    .then((plantingDatesJSON) => {
      return parseRegionalPlantingCalendarsData(plantingDatesJSON.body, countryCode, regionID);
    })
    .catch(networkErrorReporter('GET', 'regional planting dates'))
    .catch(createErrorHandler('Regional Planting Dates'));
};

/**
 * Gets the catalogue file from the API
 * @param {string} catalogueFile The catalogue file to load
 * @returns The catalogue
 */
export const loadCatalogueData = (networkDataConfig: NetworkDataLocationConfig, catalogueFile: string) => {
  const service = new CatalogueService({
    endpoint: `${networkDataConfig.catalogueData}/${catalogueFile}`,
    timeoutDuration: TIMEOUT_DURATION,
  });
  return service.getCatalogue();
};

/**
 * Gets the supplier file from the API
 * @param {string} catalogueFile The supplier file to load
 * @returns The suppliers
 */
export const loadSuppliersData = (networkDataConfig: NetworkDataLocationConfig, supplierFile: string) => {
  const service = new SupplierService({
    endpoint: `${networkDataConfig.supplierData}/${supplierFile}`,
    timeoutDuration: TIMEOUT_DURATION,
  });
  return service.getSuppliers();
};

const templatePlanService = new TemplatePlanService({
  endpoint: networkConfig.apiEndpoint,
});

export const loadTemplatePlans = (): Promise<TemplatePlan[]> => {
  return templatePlanService.loadTemplatePlans(TIMEOUT_DURATION);
};

export type SpritesheetURLs = {
  spritesheet: string;
  spritesheet2x: string;
};

/**
 * Load the spritesheets, by loading their webpack URLs and assigning them to new Images
 */
export const loadSpritesheets = async (countryCode: string): Promise<SpritesheetURLs> => {
  const spritesheets = await loadSpritesheet(countryCode);
  const spritesheetUrls = await Promise.all([spritesheets.default.spritesheet, spritesheets.default.spritesheet2x]);
  // Pre-load the spritesheets
  // Currently doesn't work, as the webpack URLs in JS and CSS don't match
  spritesheetUrls.forEach((url) => {
    const img = new Image();
    img.src = url.default;
  });
  return {
    spritesheet: spritesheetUrls[0].default,
    spritesheet2x: spritesheetUrls[1].default,
  };
};

export const loadGuruPages = async (): Promise<GuruPages> => {
  const pages = await guruService.getPages();
  return pages;
};

export const loadTutorials = async (networkDataConfig: NetworkDataLocationConfig): Promise<Tutorials> => {
  const tutorialService = new TutorialService(networkDataConfig.tutorials);
  return tutorialService.getTutorials();
};

export const loadObjectives = (networkDataConfig: NetworkDataLocationConfig): Promise<ObjectiveDataFromAPI> => {
  const objectivesService = new ObjectivesService(networkDataConfig.objectives, networkConfig.newApiEndpoint);
  return objectivesService.getObjectives();
};

export const loadTooltips = (networkDataConfig: NetworkDataLocationConfig): Promise<ParsedTooltips> => {
  const tooltipService = new TooltipService(networkDataConfig.tooltips);
  return tooltipService.getTooltipContent();
};

export const loadProducts = (networkDataConfig: NetworkDataLocationConfig, clientId: number): Promise<Store> => {
  const productService = new ProductService(networkDataConfig.products, clientId);
  return productService.getProducts();
};
