import React, { useCallback, useMemo } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';

import Plan from '@gi/plan';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { SessionSelectors } from '@gi/react-session';
import { UserPlan, UserPlanUtils } from '@gi/user';
import FormField, { FORM_FIELD_PRESETS, FormValues } from '@gi/form-responsive';

import styles from './plan-dimensions-editor-historical-section.module.css';

function areSameSize(planA: Plan | UserPlan, planB: Plan | UserPlan): boolean {
  return planA.width === planB.width && planA.height === planB.height;
}

interface PlanEntryProps {
  historicalPlan: UserPlan;
  plan: Plan;
}

const PlanEntry = ({ plan, historicalPlan }: PlanEntryProps): JSX.Element | null => {
  const canBeResized = areSameSize(plan, historicalPlan);
  const iconClassName = canBeResized ? `icon-ok ${styles.icon}` : `icon-warning ${styles.icon} ${styles.error}`;

  return (
    <div className={styles.plan}>
      <span className={styles.year}>{historicalPlan.year}</span>
      <span className={styles.name}>{historicalPlan.name}</span>
      <i className={iconClassName} />
    </div>
  );
};

interface FormValuesType {
  resizeHistoricalPlans: boolean;
  planHistory: number[];
}

interface iProps {
  plan: Plan;
  values: FormValues<FormValuesType>;
  setValue: <K extends keyof FormValuesType>(key: K, value: FormValuesType[K]) => void;
  disabled?: boolean;
}

const PlanDimensionsEditorHistoricalSection = ({ plan, values, setValue, disabled = false }: iProps): JSX.Element | null => {
  const plans = useSelector(CanvasSelectors.getPlans);
  const user = useSelector(SessionSelectors.getUser);

  const { resizeHistoricalPlans, planHistory } = values.values;

  const getPlan = useCallback(
    (planId: number): UserPlan | null => {
      // Use the full plan if we can, as more likely to be up-to-date
      const fullPlan = plans.plans[planId];
      if (fullPlan) {
        return UserPlanUtils.createFromPlan(fullPlan);
      }
      // Fall back to user plans if we haven't loaded this plan yet
      const userPlan = user?.plans.map[planId];
      if (userPlan) {
        return userPlan;
      }
      // Invalid plan ID given
      return null;
    },
    [plans, user]
  );

  const displayWarning = useMemo(() => {
    return planHistory.map((id) => getPlan(id)).some((historicalPlan) => (!historicalPlan ? false : !areSameSize(plan, historicalPlan)));
  }, [planHistory]);

  if (planHistory.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      {/* Toggle button */}
      <FormField
        label='Apply change to historical plans'
        htmlFor='plan-dimensions:apply-to-history'
        layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
        disabled={disabled}
      >
        <input
          id='plan-dimensions:apply-to-history'
          type='checkbox'
          checked={resizeHistoricalPlans}
          onChange={(e) => setValue('resizeHistoricalPlans', e.target.checked)}
          disabled={disabled}
        />
      </FormField>
      <AnimateHeight duration={200} height={resizeHistoricalPlans ? 'auto' : 0} className={styles.expandSection}>
        {/* List of plans */}
        <div className={styles.planListContainer}>
          {planHistory.map((historicalPlanId) => {
            const historicalPlan = getPlan(historicalPlanId);
            if (!historicalPlan) {
              return null;
            }
            return <PlanEntry plan={plan} historicalPlan={historicalPlan} key={historicalPlanId} />;
          })}
        </div>
        {/* Warning message for plans that will be ignored */}
        {displayWarning ? (
          <div className={styles.dangerMessage}>
            <p>
              <i className={`icon-warning ${styles.error}`} /> Historical plans marked with a warning triangle will not be modified, as they are a different
              size to the current plan. Please modify these plans manually.
            </p>
          </div>
        ) : null}
        {/* Warning message about undo history */}
        <div className={styles.warningMessage}>
          <p>
            <i className={`icon-warning ${styles.warning}`} /> Applying changes to historical plans will remove your undo history for this plan and all
            historical plans currently open.
          </p>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default PlanDimensionsEditorHistoricalSection;
