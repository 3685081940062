import { Palette } from '../palette';
import SwatchTypes from '../swatch-types';

/**
 * Creates a new palette
 *
 * @param {String} name
 * @param {number} swatchesPerRow
 * @param {[[Object]]} swatchGroups
 * @returns {Palette}
 */
const objectToPalette = (name: string, swatchesPerRow: number, swatchGroups): Palette => ({
  name,
  swatchesPerRow,
  swatches: swatchGroups.map((swatchGroup) =>
    swatchGroup.map((swatch) => {
      return {
        value: swatch.value,
        label: swatch.label,
        src: swatch.src,
        type: swatch.type === 'color' ? SwatchTypes.COLOR : SwatchTypes.IMAGE,
      };
    })
  ),
});

export default objectToPalette;
