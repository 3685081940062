import React from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';

import { MONTH_SELECT_STYLES } from '@gi/styles/react-select-styles';

interface MonthDropdownOption {
  label: string;
  value: number | null;
}

const MONTHS: MonthDropdownOption[] = [
  { label: 'Any', value: null },
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];

const SOUTHERN_HEMISPHERE_MONTHS: MonthDropdownOption[] = [
  { label: 'Any', value: null },
  { label: 'July', value: 0 },
  { label: 'August', value: 1 },
  { label: 'September', value: 2 },
  { label: 'October', value: 3 },
  { label: 'November', value: 4 },
  { label: 'December', value: 5 },
  { label: 'January', value: 6 },
  { label: 'February', value: 7 },
  { label: 'March', value: 8 },
  { label: 'April', value: 9 },
  { label: 'May', value: 10 },
  { label: 'June', value: 11 },
];

const getMonthValue = (val: number | null, northernHemisphere: boolean) => {
  if (!northernHemisphere) {
    if (val === null) {
      return SOUTHERN_HEMISPHERE_MONTHS[0];
    }
    return SOUTHERN_HEMISPHERE_MONTHS[val + 1];
  }

  if (val === null) {
    return MONTHS[0];
  }
  return MONTHS[val + 1];
};

interface iProps {
  northernHemisphere: boolean;
  value: number | null;
  onChange: (value: number | null) => void;
  menuPlacement?: MenuPlacement;
  id?: string;
}

const MonthDropdown = ({ northernHemisphere, value, onChange, menuPlacement, id }: iProps): JSX.Element => {
  const handleOnChange = (option: MonthDropdownOption) => {
    onChange(option.value);
  };

  return (
    <ReactSelect<MonthDropdownOption>
      styles={MONTH_SELECT_STYLES}
      options={northernHemisphere ? MONTHS : SOUTHERN_HEMISPHERE_MONTHS}
      value={getMonthValue(value, northernHemisphere)}
      onChange={handleOnChange}
      menuPortalTarget={document.body}
      menuPlacement={menuPlacement}
      inputId={id}
      isSearchable={false}
    />
  );
};

export default MonthDropdown;
