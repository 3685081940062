import React, { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingState } from '@gi/constants';
import { getOverallLoadingState } from '@gi/utils';

import * as CanvasSelectors from '../redux-components/canvas-selectors';
import * as CanvasActionCreators from '../redux-components/canvas-action-creators';
import GardenCanvasLoadingEntry from './garden-canvas-loading-entry';
import { GardenCanvasContext } from '../garden-canvas-context/garden-canvas-context';

import styles from './garden-canvas-loading-screen.module.css';

const GardenCanvasLoadingScreen = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const { gardenCanvas } = useContext(GardenCanvasContext);

  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const activePlanID = useSelector(CanvasSelectors.getActivePlanID);
  const failedToLoadPlans = useSelector(CanvasSelectors.getMaxRetryFailedToLoadPlanIDs);
  const texturesStatus = useSelector(CanvasSelectors.getTexturesStatus);

  const planStatus = useMemo(() => {
    if (activePlan !== null) {
      return LoadingState.SUCCESS;
    }
    if (activePlanID !== null && failedToLoadPlans.includes(activePlanID)) {
      return LoadingState.ERROR;
    }
    if (activePlanID !== null) {
      return LoadingState.LOADING;
    }
    return LoadingState.NONE;
  }, [activePlan, activePlanID, failedToLoadPlans]);

  const retryPlanLoad = useMemo(() => {
    if (planStatus !== LoadingState.ERROR || activePlanID === null) {
      return undefined;
    }
    // Remove 1 failure from the failed-to-load tally so the plan loader can retry once more
    return () => dispatch(CanvasActionCreators.clearPlanLoadFailures(activePlanID, 1));
  }, [planStatus, activePlanID]);

  const overallStatus = useMemo(() => {
    return getOverallLoadingState([planStatus, texturesStatus.status]);
  }, [planStatus, texturesStatus.status]);

  const heading = useMemo(() => {
    if (overallStatus === LoadingState.ERROR) {
      return <h2>An Unexpected Error Occurred</h2>;
    }
    return <h2>Loading</h2>;
  }, [overallStatus]);

  const errorText = useMemo(() => {
    if (overallStatus !== LoadingState.ERROR) {
      return null;
    }
    return (
      <div className={styles.errorText}>
        <p>Please reload the garden planner and try again.</p>
        <p>
          If this problem persists please{' '}
          <a href='/contact.apsx' target='_blank'>
            contact support
          </a>{' '}
          and we&apos;ll get back to you as soon as possible.
        </p>
      </div>
    );
  }, [overallStatus]);

  if (overallStatus === LoadingState.SUCCESS) {
    return null;
  }

  return (
    <div className={styles.loadingScreenContainer}>
      <div className={styles.loadingScreen}>
        {heading}
        <div className={styles.entries}>
          <GardenCanvasLoadingEntry label='Plan' status={planStatus} retry={retryPlanLoad} />
          <GardenCanvasLoadingEntry
            label='Icons'
            status={texturesStatus.status}
            progress={texturesStatus.status === LoadingState.LOADING ? texturesStatus.progress : undefined}
            retry={() => gardenCanvas?.retryLoadAssets()}
          />
        </div>
        {errorText}
      </div>
    </div>
  );
};

export default GardenCanvasLoadingScreen;
