import React, { useContext } from 'react';

import { Sidebar } from '@gi/planner-sidebar';

import { DrawingToolsContext } from './drawing-tools-context';
import DrawingToolsHeader from './drawing-tools-header';
import DrawingToolsTabs from './drawing-tools-tabs';
import DrawingToolsPanels from './drawings-tools-panels';

import './drawing-tools.scss';

const DrawingTools = () => {
  const { open, activeTab, setActiveTab, setOpen } = useContext(DrawingToolsContext);

  return (
    <Sidebar
      initiallyActiveTab={activeTab}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      minimised={!open}
      setMinimised={(minimised) => setOpen(!minimised)}
      className='drawing-tools'
    >
      <DrawingToolsHeader />
      <DrawingToolsTabs />
      <DrawingToolsPanels />
    </Sidebar>
  );
};

export default DrawingTools;
