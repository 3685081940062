/**
 * Helper class to keep track of an async event.
 * This task can be cancelled, and can have it's onComplete callback modified.
 */
export class AsyncTask<T> {
  #id: string | null;
  #cancelled: boolean = false;
  #completed: boolean = false;
  #onComplete: ((result: T) => void) | undefined = undefined;
  #result?: T;

  constructor(id: string | null = null) {
    this.#id = id;
  }
  /**
   * ID for identifying this task.
   */
  get id() {
    return this.#id;
  }
  /**
   * Cancels this task.
   * onComplete will not be cancelled, even if the task completes.
   */
  cancel() {
    this.#cancelled = true;
  }
  /**
   * Returns true if this task has been cancelled.
   */
  get cancelled() {
    return this.#cancelled;
  }
  /**
   * Completes this task.
   * If this task hasn't been cancelled, onComplete will be called.
   * @param result The result of the async process.
   */
  complete(result: T) {
    this.#completed = true;
    this.#result = result;
    if (!this.cancelled && this.#onComplete) {
      this.#onComplete(result);
    }
  }
  /**
   * Returns the value of the async process. Will be set if completed is true.
   */
  get result() {
    return this.#result;
  }
  /**
   * Returns true if the async process has finished successfully.
   */
  get completed() {
    return this.#completed;
  }
  /**
   * Sets the callback function to call when this task completes.
   */
  set onComplete(fn: (result: T) => void) {
    this.#onComplete = fn;
  }
}
