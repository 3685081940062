import React, { Ref, forwardRef } from 'react';
import { ShoppingListProduct } from '@gi/products';

import styles from './products-selector.module.css';

interface iProps {
  children: React.ReactNode;
  productOptions: ShoppingListProduct[];
  productSelectorOpen: boolean;
  setProductSelectorOpen: (open: boolean) => void;
}

const ProductSelectorButton = forwardRef<HTMLElement, iProps>(({ children, productOptions, productSelectorOpen, setProductSelectorOpen }, ref): JSX.Element => {
  const optionsCount = productOptions.length - 1;

  if (optionsCount === 0) {
    return (
      <div className={styles.listItem} ref={ref as Ref<HTMLDivElement>}>
        {children}
      </div>
    );
  }

  return (
    <button
      type='button'
      className={`${styles.listItemButton} ${styles.listItem}`}
      onClick={() => {
        setProductSelectorOpen(!productSelectorOpen);
      }}
      ref={ref as Ref<HTMLButtonElement>}
    >
      {children}
    </button>
  );
});
ProductSelectorButton.displayName = 'ProductSelectorButton';

export default ProductSelectorButton;
