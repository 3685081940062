import { Geometry } from '@gi/math';
import GardenObject from '@gi/garden-object';
import { GardenItemType, GardenObjectType } from '@gi/constants';
import { HiddenFlag, InspectableClassData, InteractableComponentCallbacks, NodeEvent, PointerData } from '@gi/core-renderer';

import CanvasLayers from '../../canvas-layers';
import GardenObjectNode from '../garden-objects/garden-object-node';
import LineHandleSetNode from '../handles/line-handle-set-node';
import { DRAWING_ITEM_ID } from '../../constants';
import BlockGardenObjectNode from '../garden-objects/block-garden-object';
import { GardenObjectSnapUtils } from '../../../simulation/snap-utils';
import { SimulatedGardenObject } from '../../../simulation/simulated-garden-object';
import EditSimulatedGardenObject from '../../../simulation/edit/edit-simulated-garden-object';
import CanvasInteractionInterface from '../../../canvas-interface/canvas-interaction-interface';
import DrawTool, { DrawToolState, MIN_DRAW_DISTANCE } from './draw-tool';
import GardenItemSelectionMiddleware from '../../components/garden-item-selection-middleware';
import PathGardenObjectNode from '../garden-objects/path-garden-object-node';

export interface DrawGardenObjectToolState extends DrawToolState {
  itemType: GardenItemType.GardenObject;
  gardenObjectCode: string;
  path: boolean;
}

class DrawGardenObjectTool extends DrawTool<DrawGardenObjectToolState> {
  type = 'DrawGardenObjectTool';

  readonly gardenObject: GardenObject;

  private simulatedGardenObject: SimulatedGardenObject | null = null;
  private editSimulatedGardenObject: EditSimulatedGardenObject | null = null;
  private handles: LineHandleSetNode | null = null;

  private previewNode: BlockGardenObjectNode | PathGardenObjectNode | null = null;
  private startOffset: Vector2 = { x: 0, y: 0 };
  private endOffset: Vector2 = { x: 0, y: 0 };

  constructor(gardenObject: GardenObject, interactionInterface: CanvasInteractionInterface, canvasLayers: CanvasLayers, dragToDrawEvent?: PointerEvent) {
    super(
      interactionInterface,
      canvasLayers,
      {
        itemType: GardenItemType.GardenObject,
        gardenObjectCode: gardenObject.code,
        path: gardenObject.shape.type === GardenObjectType.PATH,
      },
      dragToDrawEvent
    );

    this.gardenObject = gardenObject;

    this.ignoreClick = true;

    if (this.gardenObject.shape.type === GardenObjectType.BLOCK) {
      if (this.gardenObject.shape.canScale) {
        this.startOffset = {
          x: -this.gardenObject.shape.initialWidth / 2,
          y: -this.gardenObject.shape.initialHeight / 2,
        };
        this.endOffset = {
          x: this.gardenObject.shape.initialWidth / 2,
          y: this.gardenObject.shape.initialHeight / 2,
        };
      }
    } else {
      const width = this.gardenObject.shape.initialWidth;
      this.startOffset = { x: -width / 2, y: 0 };
      this.endOffset = { x: width / 2, y: 0 };
    }

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    if (this.gardenObject.shape.type === GardenObjectType.BLOCK) {
      this.previewNode = new BlockGardenObjectNode(DRAWING_ITEM_ID, this.gardenObject, {
        start: {
          x: -this.gardenObject.shape.initialWidth / 2,
          y: -this.gardenObject.shape.initialHeight / 2,
        },
        mid: null,
        end: {
          x: this.gardenObject.shape.initialWidth / 2,
          y: this.gardenObject.shape.initialHeight / 2,
        },
        rotation: 0,
        locked: false,
        zIndex: 0,
      });
      this.canvasLayers.drawingPreviewLayer.addChildren(this.previewNode);
      this.previewNode.visibility.addHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    } else if (this.gardenObject.shape.type === GardenObjectType.PATH && this.isDragToDraw) {
      this.previewNode = new PathGardenObjectNode(DRAWING_ITEM_ID, this.gardenObject, {
        start: this.startOffset,
        mid: null,
        end: this.endOffset,
        rotation: 0,
        locked: false,
        zIndex: 0,
      });
      this.canvasLayers.drawingPreviewLayer.addChildren(this.previewNode);
      this.previewNode.visibility.addHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    }
  };

  #onBeforeUnbind = () => {
    if (this.previewNode) {
      this.previewNode.destroy();
    }
  };

  onDragStart: InteractableComponentCallbacks['onDragStart'] = (data, interaction, controls) => {
    if (this.previewNode) {
      this.previewNode.visibility.addHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    }

    this.simulatedGardenObject = this.interactionInterface.startDrawingGardenObject(this.gardenObject, data.worldPosition);

    if (this.gardenObject.shape.type === GardenObjectType.PATH) {
      const handles = this.tryGetContext(GardenItemSelectionMiddleware)?.forceUpdateHandles();

      if (handles && handles instanceof LineHandleSetNode) {
        const [target] = handles.targets;

        if (target && target instanceof GardenObjectNode && target.id === this.simulatedGardenObject.id) {
          this.handles = handles;
          this.handles.P2.callOnDragStart(data, interaction, controls);
        } else {
          console.error('Garden object path handles are for the wrong path');
        }
      } else {
        console.error('Failed to find garden object path handles after drawing path');
      }
    } else {
      this.editSimulatedGardenObject = new EditSimulatedGardenObject(this.simulatedGardenObject);
      if (this.snapToGrid) {
        this.editSimulatedGardenObject.snapTranslate(this.snapToGridDistance);
      }
      this.editSimulatedGardenObject.start();
    }
  };

  onDragMove: InteractableComponentCallbacks['onDragMove'] = (data, interaction, controls) => {
    if (this.gardenObject.shape.type === GardenObjectType.PATH) {
      if (this.handles) {
        this.handles.P2.callOnDragMove(data, interaction, controls);
      } else {
        console.error('Failed to manipulate drawn garden object: Path handles missing');
      }
    } else {
      this.editSimulatedGardenObject?.translate(data.worldPositionTotalDelta);
      if (this.snapToGrid) {
        this.editSimulatedGardenObject?.snapTranslate(this.snapToGridDistance);
      }
    }
  };

  onDragEnd: InteractableComponentCallbacks['onDragEnd'] = (data, interaction, controls) => {
    if (this.gardenObject.shape.type === GardenObjectType.PATH) {
      if (this.handles) {
        this.handles.P2.callOnDragEnd(data, interaction, controls);
      } else {
        console.error('Failed to manipulate drawn garden object: Path handles missing');
      }
      if (this.simulatedGardenObject) {
        // If the final path is tiny, the user probably clicked, so default to a usable size
        const dist = Geometry.dist(this.simulatedGardenObject.start, this.simulatedGardenObject.end);
        if (dist < MIN_DRAW_DISTANCE) {
          this.simulatedGardenObject.setPosition(
            Geometry.addPoint(this.simulatedGardenObject.center, this.startOffset),
            null,
            Geometry.addPoint(this.simulatedGardenObject.center, this.endOffset),
            0
          );
        }
      }
    } else {
      this.editSimulatedGardenObject?.translate(data.worldPositionTotalDelta);
      if (this.snapToGrid) {
        this.editSimulatedGardenObject?.snapTranslate(this.snapToGridDistance);
      }
      this.editSimulatedGardenObject?.end();
    }

    this.handles = null;
    this.simulatedGardenObject = null;
    this.editSimulatedGardenObject = null;

    this.interactionInterface.onDrawGardenObject(this.gardenObject, this.isDragToDraw ?? false);
    this.interactionInterface.pushUpdates();

    if (this.previewNode) {
      if (this.isDragToDraw && this.previewNode instanceof PathGardenObjectNode) {
        this.previewNode.destroy();
        this.previewNode = null;
      } else {
        this.previewNode.visibility.removeHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
      }
    }
  };

  onPointerMove = (data: Pick<PointerData, 'worldPosition' | 'screenPosition'>) => {
    if (!this.previewNode) {
      return;
    }

    const start = Geometry.addPoint(data.worldPosition, this.startOffset);
    const end = Geometry.addPoint(data.worldPosition, this.endOffset);

    if (this.snapToGrid) {
      const { start: snappedStart, mid: snappedMid, end: snappedEnd } = GardenObjectSnapUtils.snapTranslate(start, null, end, this.snapToGridDistance);
      this.previewNode.state.values.start = snappedStart;
      this.previewNode.state.values.mid = snappedMid;
      this.previewNode.state.values.end = snappedEnd;
    } else {
      this.previewNode.state.values.start = start;
      this.previewNode.state.values.mid = null;
      this.previewNode.state.values.end = end;
    }

    if (this.previewNode.visibility.shouldHide && (this.isDragToDraw || !this.interaction.isActive())) {
      this.previewNode.visibility.removeHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    }
  };

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default DrawGardenObjectTool;
