import { FederatedWheelEvent } from 'pixi.js-new';

import { WheelModes, WheelModesType } from '@gi/constants';

import GraphicNode from '../../graphics-node';
import { NodeEvent } from '../../node';
import { hasEngine, hasParent } from '../../utils/asserts';
import ContentRootContext from './content-root-context';
import SelectableComponentContext from '../../node-components/selectable/selectable-component-context';
import { InteractionManagerEvent } from '../../managers/interaction/interaction-manager';
import InteractableComponent, { InteractableComponentCallbacks } from '../../node-components/interactable/interactable-component';
import HoverableComponentContext from '../../node-components/hoverable/hoverable-component-context';

class ContentRootNode extends GraphicNode {
  type = 'ContentRootNode';

  #interaction: InteractableComponent;
  #contentRootContext: ContentRootContext | null = null;

  wheelMode: WheelModesType = WheelModes.ZOOM;

  constructor() {
    super();

    this.contexts.add(new ContentRootContext());

    this.#interaction = this.components.add(new InteractableComponent());
    this.#interaction.addListener('onDragStart', this.#onDragStart);
    this.#interaction.addListener('onDragMove', this.#onDragMove);
    this.#interaction.addListener('onDragEnd', this.#onDragEnd);
    this.#interaction.addListener('onMultiTouchStart', this.#onMultiTouchStart);
    this.#interaction.addListener('onMultiTouchMove', this.#onMultiTouchMove);
    this.#interaction.addListener('onMultiTouchEnd', this.#onMultiTouchEnd);
    this.#interaction.addListener('onClick', this.#onClick);

    const onWheel = (e: FederatedWheelEvent) => this.#onWheel(e);

    this.eventBus.on(NodeEvent.DidAttach, () => {
      hasParent(this);
      // Capture all pointer events that make it to us
      this.ownGraphics.eventMode = 'static';
      this.ownGraphics.hitArea = { contains: () => true };
    });

    this.eventBus.on(NodeEvent.DidBind, () => {
      this.engine?.interactionManager.eventBus.on(InteractionManagerEvent.Wheel, onWheel);
      this.#contentRootContext = this.getContext(ContentRootContext);
    });

    this.eventBus.on(NodeEvent.BeforeUnbind, () => {
      this.engine?.interactionManager.eventBus.off(InteractionManagerEvent.Wheel, onWheel);
      this.#contentRootContext = null;
    });

    this.eventBus.on(NodeEvent.BeforeDetach, () => {
      // Capture all pointer events that make it to us
      this.ownGraphics.eventMode = 'auto';
      this.ownGraphics.hitArea = null;
    });
  }

  #onDragStart: InteractableComponentCallbacks['onDragStart'] = (...params) => {
    this.context?.onDragStart(...params);
  };

  #onDragMove: InteractableComponentCallbacks['onDragMove'] = (...params) => {
    this.context?.onDragMove(...params);
  };

  #onDragEnd: InteractableComponentCallbacks['onDragEnd'] = (...params) => {
    this.context?.onDragEnd(...params);
  };

  #onMultiTouchStart: InteractableComponentCallbacks['onMultiTouchStart'] = (...params) => {
    this.context?.onMultiTouchStart(...params);
  };

  #onMultiTouchMove: InteractableComponentCallbacks['onMultiTouchMove'] = (...params) => {
    this.context?.onMultiTouchMove(...params);
  };

  #onMultiTouchEnd: InteractableComponentCallbacks['onMultiTouchEnd'] = (...params) => {
    this.context?.onMultiTouchEnd(...params);
  };

  #onClick: InteractableComponentCallbacks['onClick'] = (event) => {
    if (!event.shiftKey) {
      const selectionContext = this.tryGetContext(SelectableComponentContext);
      selectionContext?.setSelection([]);
      const hoverContext = this.tryGetContext(HoverableComponentContext);
      hoverContext?.unhover();
    }
  };

  #onWheel(e: FederatedWheelEvent) {
    hasEngine(this);
    switch (this.wheelMode) {
      case WheelModes.ZOOM:
        this.#contentRootContext?.zoomCamera(e.deltaY, this.engine.convertToLocalPos({ x: e.clientX, y: e.clientY }));
        break;
      case WheelModes.PAN:
        // TODO
        break;
      case WheelModes.DISABLED:
      default:
        // Do nothing
        break;
    }
  }

  get context() {
    return this.#contentRootContext;
  }

  get isPrinting() {
    const { context } = this;
    return context ? context.state.values.isPrinting : false;
  }
}

export default ContentRootNode;
