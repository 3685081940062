import React, { useContext } from 'react';
import { GuruDirectoryPageId } from '@gi/app-guru-types';
import { getComposedPageId } from '@gi/app-guru-common';

import DirectoryPageSections from '../../components/directory-page/directory-page-sections';
import DirectoryPageContainer from '../../components/directory-page/directory-page-container';
import HeaderHeroBanner from './home-directory-banner';
import DirectoryPageHeader from '../../components/directory-page/directory-page-header';
import HeaderHero from '../../header/header-components/header-hero';
import HeaderContainer from '../../header/header-components/header-container';
import DirectoryPage from '../../components/directory-page/directory-page';
import { GardenGuruDataContext } from '../../context/garden-guru-data-context';

import styles from './home-directory-page.module.css';

interface iProps {
  guruPageId: GuruDirectoryPageId;
}

const HomeDirectoryPage = ({ guruPageId }: iProps): JSX.Element => {
  const { guruPages } = useContext(GardenGuruDataContext);
  const composedPageId = getComposedPageId(guruPages, guruPageId);

  return (
    <DirectoryPageContainer>
      <DirectoryPageHeader composedPageId={composedPageId}>
        <HeaderContainer className={styles.guruHeader}>
          <HeaderHero composedPageId={composedPageId} />
        </HeaderContainer>
      </DirectoryPageHeader>
      <HeaderHeroBanner />
      <DirectoryPage>
        <DirectoryPageSections composedPageId={composedPageId} />
      </DirectoryPage>
    </DirectoryPageContainer>
  );
};

export default HomeDirectoryPage;
