import { batchActions } from 'redux-batched-actions';
import { generateNotificationID, NotificationActionCreators, NotificationTypes } from '@gi/notifications';

import * as RequestActionCreators from '../request-action-creators';

import { createSupportEmailRequestKey } from './request-key-creators';

export const submitSupportEmailStart = (notificationID) => ({
  title: 'Submitting Support Email',
  icon: 'icon-floppy',
  ID: notificationID,
  type: NotificationTypes.INFO,
  inProgress: true,
});
export const submitSupportEmailSuccessUpdate = {
  title: 'Support Email Sent',
  icon: 'icon-ok',
  type: NotificationTypes.SUCCESS,
  canTimeout: true,
};
export const submitSupportEmailFailUpdate = {
  title: 'Error Submitting Support Email',
  icon: 'icon-attention-alt',
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
};

export const submitSupportEmailRequest = (supportEmail, formID = '') => {
  return (dispatch, setState, { services }) => {
    const notificationID = generateNotificationID();
    const requestID = createSupportEmailRequestKey(formID);

    dispatch(
      batchActions([
        RequestActionCreators.requestStart(requestID),
        NotificationActionCreators.createDefaultNotification(submitSupportEmailStart(notificationID)),
      ])
    );

    // const supportEmail = new SupportEmail({
    //   name,
    //   emailAddress: user.email,
    //   message,
    //   platform: `WGP ${formID} - ${VERSION} - ${browserSpecs.name}, ${browserSpecs.version}`,
    // });

    return services.supportService
      .sendSupportEmail(supportEmail)
      .then(() => {
        dispatch(
          batchActions([
            RequestActionCreators.requestComplete(requestID),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: submitSupportEmailSuccessUpdate,
            }),
          ])
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          batchActions([
            RequestActionCreators.requestFail(requestID, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: submitSupportEmailFailUpdate,
            }),
          ])
        );

        throw err;
      });
  };
};

export const clearSupportEmailRequest = (formID) => {
  return RequestActionCreators.clearRequest(createSupportEmailRequestKey(formID));
};
