const SessionActionTypes = {
  SET_USER: 'SESSION/SET_USER',
  LOGOUT: 'SESSION/LOGOUT',
  START_RESTORE: 'SESSION/START_RESTORE',
  RESTORE: 'SESSION/RESTORE',
  START_SAVE_USER: 'SESSION/START_SAVE_USER',
  END_SAVE_USER: 'SESSION/END_SAVE_USER',
  UPDATE_USER_FROM_SAVE: 'SESSION/UPDATE_USER_FROM_SAVE',
  RESTORE_FAILED: 'SESSION/RESTORE_FAILED',
} as const;

export default SessionActionTypes;
