import React, { forwardRef } from 'react';
import { Tooltip, TooltipContent, TooltipKey, TooltipTrigger } from '@gi/tooltip';

import styles from './menu-button.module.css';

interface iProps extends React.HTMLProps<HTMLButtonElement> {
  active?: boolean;
  helpButton?: boolean;
  selectButton?: boolean;
  tooltipKey?: TooltipKey;
  disabled?: boolean;
}

const MenuButton = forwardRef<HTMLButtonElement, iProps>(
  ({ children, active = false, helpButton = false, selectButton = false, disabled, tooltipKey, ...props }, ref): JSX.Element => {
    const button = (
      <button
        {...props}
        className={`${styles.menuButton} ${props.className} ${helpButton ? styles.helpMenuButton : ''} ${selectButton ? styles.selectMenuButton : ''} ${active ? styles.activeMenuButton : ''}`}
        type='button'
        disabled={disabled}
        ref={ref}
      >
        {children}
      </button>
    );

    if (tooltipKey) {
      return (
        <Tooltip disabled={disabled} placement='bottom'>
          <TooltipTrigger>{button}</TooltipTrigger>
          <TooltipContent id={tooltipKey} />
        </Tooltip>
      );
    }

    return button;
  }
);
MenuButton.displayName = 'MenuButton';

export default MenuButton;
