import { Filter } from '@gi/filters';
import Plant from '@gi/plant';
import { SearchResults } from '@gi/search-service';

type PlantSearchFilterState = {
  searchTerm: string;
  plantSearchResults: SearchResults<Plant>;
};

/**
 * Creates a filter that matches against a list of search results.
 * Same logic as drawing tools search, but without sorting.
 */
export const makePlantSearchFilter = <T>(getCode: (object: T) => string) => {
  const searchFilter: Filter<T, PlantSearchFilterState> = {
    name: 'Search',
    inputs: {
      searchTerm: '',
      plantSearchResults: new SearchResults([]),
    },
    createListFilter:
      ({ searchTerm, plantSearchResults }) =>
      (objectList) => {
        // Return early if no results or empty query
        if (searchTerm.trim() === '') {
          return objectList;
        }
        if (plantSearchResults.count === 0) {
          return [];
        }
        // Filter against the subset we've been passed to allow us to combine with other filters
        return objectList.filter((object) => {
          return plantSearchResults.getResult(getCode(object)) !== undefined;
        });
      },
  };
  return searchFilter;
};
