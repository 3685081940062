import React, { useContext } from 'react';
import { TutorialContext } from '../tutorial-context';
import TutorialDisplay from './tutorial-display';

const TutorialDisplayRenderer = () => {
  const { runningTutorial, stopTutorial, next, previous } = useContext(TutorialContext);

  if (!runningTutorial) {
    return null;
  }

  return <TutorialDisplay runningTutorial={runningTutorial} stopTutorial={stopTutorial} next={next} previous={previous} />;
};

export default TutorialDisplayRenderer;
