import React from 'react';

import { PlannerTabTypes } from '@gi/constants';

import MobilePlannerControls from './mobile-planner-controls';
import MobilePlantListControls from './mobile-plant-list-controls';
import MobilePartListControls from './mobile-part-list-controls';
import MobileNotesControls from './mobile-notes-controls';

interface iProps {
  activePlannerTab: PlannerTabTypes;
  callAndClose: (callback: null | (() => void)) => () => void;
}

/**
 * Mobile topbar drawer controls for plans that are currently active
 */
const MobileActivePlanControls = ({ activePlannerTab, callAndClose }: iProps): JSX.Element | null => {
  switch (activePlannerTab) {
    case PlannerTabTypes.PLANNER:
      return <MobilePlannerControls callAndClose={callAndClose} />;
    case PlannerTabTypes.PLANT_LIST:
      return <MobilePlantListControls callAndClose={callAndClose} />;
    case PlannerTabTypes.PARTS_LIST:
      return <MobilePartListControls callAndClose={callAndClose} />;
    case PlannerTabTypes.NOTES:
      return <MobileNotesControls callAndClose={callAndClose} />;
    default:
      return null;
  }
};

export default MobileActivePlanControls;
