import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal, {
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalCloseButton,
  ModalPaneContainer,
  ModalPane,
  ModalPaneSection,
  ModalPaneSectionContent,
  ModalHeaderContent,
  ModalHeaderTitle,
} from '@gi/modal';
import LoadingButton from '@gi/loading-button';

import { FormRow, FormRowGroup, FormRowGroupContent } from '@gi/form';

import './transfer-plan-modal.scss';

/**
 * @param {{
 *  closeModal: () => void,
 *  transferPlan: () => void,
 *  errorMessage?: string | null,
 *  inProgress?: boolean,
 *  planID: number,
 * }}
 */
const TransferPlanModal = ({ planID, closeModal, transferPlan, inProgress = false, errorMessage = null }) => {
  const [copy, setCopy] = useState(true);
  const [email, setEmail] = useState('');

  const validEmail = /\S+@\S+\.\S+/.test(email);

  const onTransfer = () => {
    transferPlan(planID, email, copy);
  };

  return (
    <Modal className='transfer-plan-modal' closeRequest={closeModal}>
      <ModalContent>
        <ModalCloseButton onClick={closeModal} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Copy or Transfer Plan from Master Account</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane emphasised>
            <ModalPaneSection>
              <ModalPaneSectionContent>
                <p style={{ margin: 0 }}>The recipient must refresh the Garden Planner page to see the new plan.</p>
              </ModalPaneSectionContent>
            </ModalPaneSection>
          </ModalPane>
          <ModalPane>
            <ModalPaneSection>
              <ModalPaneSectionContent>
                <FormRow className='auto-label' label={"Recipient's Email"} required>
                  <input
                    disabled={inProgress}
                    placeholder='example@domain.com'
                    name='transfer-email'
                    type='email'
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </FormRow>
                <FormRowGroup>
                  <FormRowGroupContent>
                    <FormRow className='auto-label' style={{ textAlign: 'right' }} label={"Copy to Recipient's Account"}>
                      <input
                        disabled={inProgress}
                        type='radio'
                        checked={copy}
                        onChange={(e) => {
                          setCopy(e.target.checked);
                        }}
                      />
                    </FormRow>
                    <FormRow className='auto-label' label={"Transfer Plan to Recipient's Account"}>
                      <input
                        disabled={inProgress}
                        type='radio'
                        checked={!copy}
                        onChange={(e) => {
                          setCopy(!e.target.checked);
                        }}
                      />
                    </FormRow>
                  </FormRowGroupContent>
                </FormRowGroup>
              </ModalPaneSectionContent>
            </ModalPaneSection>
          </ModalPane>
          {errorMessage === null ? null : (
            <ModalPane>
              <ModalPaneSection>
                <div className='transfer-plan-error'>
                  <i className='icon-attention-alt' /> {errorMessage}
                </div>
              </ModalPaneSection>
            </ModalPane>
          )}
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' disabled={inProgress} className='button button-secondary' onClick={closeModal}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <LoadingButton disabled={!validEmail} loading={inProgress} className='button button-primary' onClick={onTransfer}>
                {copy ? 'Copy' : 'Transfer'}
              </LoadingButton>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

TransferPlanModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  transferPlan: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  inProgress: PropTypes.bool,
  planID: PropTypes.number.isRequired,
};

export default TransferPlanModal;
