import { AnyStateDef, State, StateObserver } from '@gi/state';
import Node, { NodeEvent } from '../node';
import NodeComponent, { NodeComponentEvent } from '../node-component/node-component';
import { CameraNodeState } from '../nodes/camera/camera-node';
import ContentRootContext from '../nodes/content-root/content-root-context';

// Type to find the keys of `otherState` that map to the given target type
type OtherStateNamesOfType<T extends AnyStateDef, TTarget> = {
  [K in keyof T['otherStates']]: T['otherStates'][K] extends TTarget ? K : never;
}[keyof T['otherStates']];

/**
 * Utility function to connect the active camera to the given state.
 *  This relies on the lifecycle of the owner, so connects the camera state on bind, and disconnects it beforeUnbind.
 * @param owner The owner of the camera watcher (node/node component)
 * @param state The state the camera should be attached to
 * @param name The name of the otherState slot to use
 */
export function createActiveCameraConnector<T extends Node | NodeComponent, TStateDef extends AnyStateDef>(
  owner: T,
  state: State<TStateDef>,
  name: OtherStateNamesOfType<TStateDef, CameraNodeState>
) {
  let observer: StateObserver<any> | null = null;

  const onBind = () => {
    const context = owner instanceof Node ? owner.getContext(ContentRootContext) : owner.owner!.getContext(ContentRootContext);

    observer = context.state.addUpdater(
      (viewportState) => {
        const { activeCamera } = viewportState.otherStates;
        if (activeCamera) {
          state.connectState(name, activeCamera as any); // TODO: `as any` is gross. Work out why this type hates me.
        } else {
          state.disconnectState(name);
        }
      },
      {
        signals: ['cameraChange'],
      }
    );
  };

  const beforeUnbind = () => {
    if (observer) {
      observer.destroy();
    }
  };

  if (owner.bound) {
    onBind();
  }

  if (owner instanceof Node) {
    owner.eventBus.on(NodeEvent.DidBind, onBind);
    owner.eventBus.on(NodeEvent.BeforeUnbind, beforeUnbind);
  } else {
    owner.eventBus.on(NodeComponentEvent.DidBind, onBind);
    owner.eventBus.on(NodeComponentEvent.BeforeUnbind, beforeUnbind);
  }
}

/**
 * Utility function to connect the active camera to the given state.
 *  This relies on the lifecycle of the owner, so connects the camera state on bind, and disconnects it beforeUnbind.
 * Can be enabled and disabled using the returned functions
 * @param owner The owner of the camera watcher (node/node component)
 * @param state The state the camera should be attached to
 * @param name The name of the otherState slot to use
 */
export function createToggleableActiveCameraConnector<T extends Node | NodeComponent, TStateDef extends AnyStateDef>(
  owner: T,
  state: State<TStateDef>,
  name: OtherStateNamesOfType<TStateDef, CameraNodeState>,
  initiallyEnabled: boolean = true
) {
  let enabled: boolean = false;
  let observer: StateObserver<any> | null = null;

  /** Callback for when the parent binds - start watching for camera changes and bind it in */
  const onBind = () => {
    const context = owner instanceof Node ? owner.getContext(ContentRootContext) : owner.owner!.getContext(ContentRootContext);

    if (observer) {
      observer.destroy();
    }

    observer = context.state.addUpdater(
      (viewportState) => {
        const { activeCamera } = viewportState.otherStates;
        if (activeCamera) {
          state.connectState(name, activeCamera as any); // TODO: `as any` is gross. Work out why this type hates me.
        } else {
          state.disconnectState(name);
        }
      },
      {
        signals: ['cameraChange'],
      }
    );
  };

  /** Callback for when the parent unbinds - stop watching for camera changes */
  const beforeUnbind = () => {
    if (observer) {
      observer.destroy();
      observer = null;
    }
  };

  /** Start watching for possible camera changes */
  const onEnable = () => {
    if (enabled) {
      return;
    }
    enabled = true;

    if (owner.bound) {
      onBind();
    }

    if (owner instanceof Node) {
      owner.eventBus.on(NodeEvent.DidBind, onBind);
      owner.eventBus.on(NodeEvent.BeforeUnbind, beforeUnbind);
    } else {
      owner.eventBus.on(NodeComponentEvent.DidBind, onBind);
      owner.eventBus.on(NodeComponentEvent.BeforeUnbind, beforeUnbind);
    }
  };

  /** Stop watching for possible camera changes */
  const onDisable = () => {
    if (!enabled) {
      return;
    }
    enabled = false;

    if (observer) {
      observer.destroy();
    }
    state.disconnectState(name);

    if (owner instanceof Node) {
      owner.eventBus.off(NodeEvent.DidBind, onBind);
      owner.eventBus.off(NodeEvent.BeforeUnbind, beforeUnbind);
    } else {
      owner.eventBus.off(NodeComponentEvent.DidBind, onBind);
      owner.eventBus.off(NodeComponentEvent.BeforeUnbind, beforeUnbind);
    }
  };

  if (initiallyEnabled) {
    onEnable();
  }

  return {
    enable: onEnable,
    disable: onDisable,
    isEnabled: () => enabled,
  };
}
