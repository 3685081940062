import React from 'react';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { useSelector } from 'react-redux';

import LayerSelector from './layer-selector';
import ControlGroup from '../../common/control-group';
import ControlRow from '../../common/control-row';

const LayerControls = (): JSX.Element => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  return (
    <ControlGroup>
      <ControlRow>
        <LayerSelector attachToBody disabled={activePlan === null} />
      </ControlRow>
    </ControlGroup>
  );
};

export default LayerControls;
