import React from 'react';
import DashboardItem from '../../dashboard-item/dashboard-item';
import DashboardItemSection from '../../dashboard-item/dashboard-item-section';
import IntroVideo from './video-section/intro-video';
import DashboardItemSectionTitle from '../../dashboard-item/dashboard-item-section-title';
import RecentPlansSection from './recent-plans/recent-plans-section';
import GetStartedSection from './get-started/get-started-section';

import './garden-planner-dashboard-item.scss';

const GardenPlannerDashboardItem = (): JSX.Element => {
  return (
    <DashboardItem title='Garden Planner' className='garden-planner-dashboard-item'>
      <DashboardItemSection className='col-3'>
        <DashboardItemSectionTitle>Plan Your Garden</DashboardItemSectionTitle>
        <GetStartedSection />
      </DashboardItemSection>
      <DashboardItemSection className='col-3'>
        <DashboardItemSectionTitle>Recent Plans</DashboardItemSectionTitle>
        <RecentPlansSection />
      </DashboardItemSection>
      <DashboardItemSection className='col-3'>
        <DashboardItemSectionTitle>Introduction Video</DashboardItemSectionTitle>
        <IntroVideo />
      </DashboardItemSection>
    </DashboardItem>
  );
};

export default GardenPlannerDashboardItem;
