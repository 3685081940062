import React, { useCallback } from 'react';

import { useTutorial } from '@gi/tutorial';

import styles from './overview-styles.module.css';

interface iProps {
  tutorialName: string;
  onLaunchHelp?: () => void;
}

const ObjectiveTutorialButton = ({ tutorialName, onLaunchHelp }: iProps): JSX.Element | null => {
  const { tutorial, isRunning, start } = useTutorial({ tutorialName });

  const onClick = useCallback(() => {
    start();
    onLaunchHelp?.();
  }, [start, onLaunchHelp]);

  if (tutorial === null) {
    return null;
  }

  return (
    <button type='button' className={styles.startTutorialButton} disabled={isRunning} onClick={onClick}>
      {isRunning ? 'Viewing Tutorial' : 'Start Tutorial'}
    </button>
  );
};

export default ObjectiveTutorialButton;
