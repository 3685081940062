import { GardenItemType, GardenObjectType } from '@gi/constants';
import { PlanValidation } from '@gi/plan';
import GardenObject, { GardenObjectScalingMode } from '@gi/garden-object';
import { Geometry } from '@gi/math';

import { SpriteSourceType } from '@gi/sprite-source';
import { SimulatedGardenItem, SimulatedGardenItemClipboardData } from './simulated-garden-item';
import BoxCollider from './collisions/colliders/box-collider';
import { CollisionCheckFlag, CollisionGroupFlag } from './collisions/colliders/collider';
// eslint-disable-next-line import/no-cycle
import { SimulatedPlant } from './simulated-plant';

export type SimulatedGardenObjectClipboardData = {
  start: Vector2;
  mid: Vector2 | null;
  end: Vector2;
  rotation: number;
  gardenObject: GardenObject;
  locked: boolean;
  zIndex: number;
};

export class SimulatedGardenObject extends SimulatedGardenItem {
  readonly type = GardenItemType.GardenObject;

  #start: Vector2;
  get start() {
    return this.#start;
  }

  #mid: Vector2 | null;
  get mid() {
    return this.#mid;
  }

  #end: Vector2;
  get end() {
    return this.#end;
  }

  #rotation: number;
  get rotation() {
    return this.#rotation;
  }

  #gardenObject: GardenObject;
  get gardenObject() {
    return this.#gardenObject;
  }

  get coveredPlants() {
    return this.collider.contains
      .asArray()
      .map((item) => item.owner)
      .filter((item): item is SimulatedPlant => item instanceof SimulatedPlant);
  }

  get center(): Vector2 {
    // TODO: If Line...

    // Else:
    return Geometry.midpoint(this.start, this.end);
  }

  get dimensions(): Dimensions {
    const { shape } = this.gardenObject;

    if (shape.type === GardenObjectType.PATH) {
      return {
        width: Geometry.dist(this.start, this.end),
        height: shape.sprite.height,
      };
    }

    if (shape.scalingMode === GardenObjectScalingMode.FIXED) {
      return {
        width: shape.initialWidth,
        height: shape.initialHeight,
      };
    }

    return {
      width: Math.abs(this.end.x - this.start.x),
      height: Math.abs(this.end.y - this.start.y),
    };
  }

  #collider: BoxCollider;
  get collider() {
    return this.#collider;
  }

  constructor(id: number, start: Vector2, mid: Vector2 | null, end: Vector2, rotation: number, gardenObject: GardenObject, locked: boolean, zIndex: number) {
    super(id, locked, zIndex);
    this.#start = start;
    this.#mid = mid;
    this.#end = end;
    this.#rotation = rotation;
    this.#gardenObject = gardenObject;

    const isSeasonExtender = gardenObject.plantModifier !== null;

    this.#collider = new BoxCollider(this, Geometry.midpoint(this.start, this.end), this.dimensions.width, this.dimensions.height, this.rotation);
    this.#collider.collisionChecks.clear();
    this.#collider.collisionGroup.set(CollisionGroupFlag.GARDEN_OBJECT);
    this.#collider.collisionTargets.clear();
    if (isSeasonExtender) {
      this.#collider.collisionChecks.add(CollisionCheckFlag.CONTAINS);
      this.#collider.collisionGroup.add(CollisionGroupFlag.SEASON_EXTENDER);
      this.#collider.collisionTargets.add(CollisionGroupFlag.PLANT);
    }

    this.#validate();
  }

  #setPosition(start: Vector2, mid: Vector2 | null, end: Vector2, rotation: number) {
    this.#start = start;
    this.#mid = mid;
    this.#end = end;
    this.#rotation = rotation;
    this.#validate();
  }

  setPosition(point1: Vector2, point2: Vector2 | null, point3: Vector2, rotation: number) {
    this.#setPosition(point1, point2, point3, rotation);
    this.emitUpdates();
  }

  getClipboardData(): SimulatedGardenItemClipboardData<GardenItemType.GardenObject, SimulatedGardenObjectClipboardData> {
    return {
      type: GardenItemType.GardenObject,
      data: {
        start: { ...this.start },
        mid: this.mid === null ? null : { ...this.mid },
        end: { ...this.end },
        rotation: this.rotation,
        gardenObject: this.gardenObject,
        locked: this.locked,
        zIndex: this.zIndex,
      },
    };
  }

  #validate() {
    const { dimensions } = this;
    const { start, end, mid, rotation } = PlanValidation.validateGardenObjectProperties(
      this.start,
      this.mid,
      this.end,
      Geometry.midpoint(this.start, this.end),
      this.rotation,
      dimensions.width,
      dimensions.height,
      this.mid !== null,
      this.gardenObject
    );

    this.#start = start;
    this.#mid = mid;
    this.#end = end;
    this.#rotation = rotation;

    this.#updateCollider();
  }

  #updateCollider() {
    const { sprite } = this.gardenObject.shape;
    this.#collider.setPosition(Geometry.midpoint(this.start, this.end));
    this.#collider.setSize(this.dimensions.width, this.dimensions.height);
    if (sprite.type === SpriteSourceType.PATH) {
      this.#collider.setRotation(Geometry.angleBetweenPoints(this.start, this.end));
    } else {
      this.#collider.setRotation(this.rotation);
    }
  }
}
