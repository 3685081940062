export enum ImageFitMode {
  CUSTOM = 'CUSTOM',
  FILL = 'FILL',
  CONTAIN = 'CONTAIN',
  COVER = 'COVER',
}

export function getImageDimensionsForFitMode(containerDimensions: Dimensions, imageFitMode: ImageFitMode, imageAspectRatio: number): Dimensions {
  switch (imageFitMode) {
    case ImageFitMode.CONTAIN: {
      const containerAspectRatio = containerDimensions.width / containerDimensions.height;
      if (imageAspectRatio > containerAspectRatio) {
        return {
          width: containerDimensions.width,
          height: containerDimensions.width / imageAspectRatio,
        };
      }
      return {
        width: containerDimensions.height * imageAspectRatio,
        height: containerDimensions.height,
      };
    }

    case ImageFitMode.COVER: {
      const containerAspectRatio = containerDimensions.width / containerDimensions.height;
      if (imageAspectRatio > containerAspectRatio) {
        return {
          width: containerDimensions.height * imageAspectRatio,
          height: containerDimensions.height,
        };
      }
      return {
        width: containerDimensions.width,
        height: containerDimensions.width / imageAspectRatio,
      };
    }

    case ImageFitMode.FILL:
    default: {
      return { width: containerDimensions.width, height: containerDimensions.height };
    }
  }
}

const FitModesToCheck: ImageFitMode[] = [ImageFitMode.CONTAIN, ImageFitMode.FILL, ImageFitMode.COVER];

export function getImageFitMode(containerDimensions: Dimensions, imageAspectRatio: number, imageDimensions: Dimensions): ImageFitMode {
  for (let i = 0; i < FitModesToCheck.length; i++) {
    const dimensions = getImageDimensionsForFitMode(containerDimensions, FitModesToCheck[i], imageAspectRatio);
    if (dimensions.width === imageDimensions.width && dimensions.height === imageDimensions.height) {
      return FitModesToCheck[i];
    }
  }
  return ImageFitMode.CUSTOM;
}
