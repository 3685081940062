import React from 'react';
import { useDispatch } from 'react-redux';

import { ReminderActionCreators } from '@gi/reminders';
import { Application, getAbsoluteApplicationLink, useNavigate } from '@gi/garden-platform-navigation';

import './get-started-section.scss';

const GetStartedSection = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className='get-started-section'>
      <div className='journal-fold dashboard-block'>
        <div className='fold-column'>
          <button type='button' className='dashboard-button planner-dashboard-button' onClick={() => navigate(getAbsoluteApplicationLink(Application.Journal))}>
            Open Your Journal
          </button>
        </div>
        <div className='fold-column'>
          <p>Create new entries and track your garden&apos;s progress</p>
        </div>
      </div>
      <div className='journal-fold dashboard-block'>
        <div className='fold-column'>
          <button type='button' className='dashboard-button planner-dashboard-button' onClick={() => dispatch(ReminderActionCreators.openAddReminder())}>
            Create A Reminder
          </button>
        </div>
        <div className='fold-column'>
          <p>Never forget another garden task</p>
        </div>
      </div>
    </div>
  );
};

export default GetStartedSection;
