import { Geometry, Matrix } from '@gi/math';
import { EditSimulatedItem } from './edit-simulated-item';
import { SnapUtils } from '../snap-utils';
import { SimulatedPlanBackgroundImage } from '../simulated-plan-background-image';

class EditSimulatedBackgroundImage extends EditSimulatedItem<SimulatedPlanBackgroundImage> {
  position: Vector2;
  dimensions: Dimensions;
  rotation: number;

  start(): void {
    this.position = { ...this.target.position };
    this.dimensions = { ...this.target.dimensions };
    this.rotation = this.target.rotation;
  }

  translate(positionOffset: Vector2) {
    this.target.setPosition(Geometry.addPoint(this.position, positionOffset));
  }

  rotate(angle: number, pivot: Vector2, matrix: number[][]): void {
    const center = Matrix.multiplyVector(this.position, matrix);

    this.target.setPosition(center);
    this.target.setRotation(this.rotation + angle);
  }

  transform(center: Vector2, dimensions: Dimensions, rotation: number): void {
    this.target.setPosition(center);
    this.target.setDimensions(dimensions);
    this.target.setRotation(rotation);
  }

  snapTranslate(snapDistance: number): void {
    const position = SnapUtils.snapPoint(this.target.position, snapDistance);

    this.target.setPosition(position);
  }

  snapTransform(snapDistance: number): void {
    const position = SnapUtils.snapPoint(this.target.position, snapDistance);

    this.target.setPosition(position);
  }

  // eslint-disable-next-line class-methods-use-this
  end(): void {
    // Do nothing
  }
}

export default EditSimulatedBackgroundImage;
