import React, { useContext } from 'react';
import { TutorialContext } from '@gi/tutorial';
import { TutorialEditor } from '@gi/tutorial-editor';

const TutorialEditorDesktop = (): JSX.Element | null => {
  const { showEditor } = useContext(TutorialContext);

  if (!showEditor) {
    return null;
  }

  return <TutorialEditor />;
};

export default TutorialEditorDesktop;
