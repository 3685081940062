import { GardenItemType } from '@gi/constants';
import {
  DoubleClickEvent,
  DoubleClickableComponentContext,
  InspectableClassData,
  LongPressEvent,
  LongPressableComponentContext,
  Node,
  NodeComponent,
  NodeComponentEvent,
  RightClickEvent,
  RightClickableComponentContext,
  hasEngine,
} from '@gi/core-renderer';

import TextNode from '../nodes/text/text-node';
import PlantNode from '../nodes/plant/plant-node';
import ShapeNode from '../nodes/shapes/shape-node';
import SFGPlantNode from '../nodes/plant/sfg-plant-node';
import PlantLabelNode from '../nodes/plant/plant-label-node';
import GardenObjectNode from '../nodes/garden-objects/garden-object-node';
import CanvasInteractionInterface from '../../canvas-interface/canvas-interaction-interface';

class GardenItemClickMiddleware extends NodeComponent {
  type = 'GardenItemEditMiddleware';
  interactionInterface: CanvasInteractionInterface;

  #doubleClickContext: DoubleClickableComponentContext | null = null;
  #rightClickContext: RightClickableComponentContext | null = null;
  #longPressContext: LongPressableComponentContext | null = null;

  constructor(canvasInteractionInterface: CanvasInteractionInterface) {
    super();
    this.interactionInterface = canvasInteractionInterface;

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);

    this.#doubleClickContext = this.owner.contexts.get(DoubleClickableComponentContext);
    this.#rightClickContext = this.owner.contexts.get(RightClickableComponentContext);
    this.#longPressContext = this.owner.contexts.get(LongPressableComponentContext);

    if (!this.#doubleClickContext) {
      throw new Error('GardenItemEditMiddleware must be attached to a node with a DoubleClickableComponentContext');
    }
    if (!this.#rightClickContext) {
      throw new Error('GardenItemEditMiddleware must be attached to a node with a RightClickableComponentContext');
    }
    if (!this.#longPressContext) {
      throw new Error('GardenItemEditMiddleware must be attached to a node with a DoubleClickableComponentContext');
    }

    this.#doubleClickContext.eventBus.on(DoubleClickEvent.OnDoubleClick, this.#onDoubleClick);
    this.#rightClickContext.eventBus.on(RightClickEvent.OnRightClick, this.#onRightClick);
    this.#longPressContext.eventBus.on(LongPressEvent.OnLongPress, this.#onRightClick);
  };

  #onBeforeUnbind = () => {
    if (this.#doubleClickContext) {
      this.#doubleClickContext.eventBus.off(DoubleClickEvent.OnDoubleClick, this.#onDoubleClick);
    }
    if (this.#rightClickContext) {
      this.#rightClickContext.eventBus.off(RightClickEvent.OnRightClick, this.#onRightClick);
    }
    if (this.#longPressContext) {
      this.#longPressContext.eventBus.off(LongPressEvent.OnLongPress, this.#onRightClick);
    }
    this.#doubleClickContext = null;
    this.#longPressContext = null;
  };

  #onDoubleClick = (node: Node) => {
    if (node instanceof PlantNode || node instanceof SFGPlantNode || node instanceof PlantLabelNode) {
      this.interactionInterface.editItem(GardenItemType.Plant, node.id);
    } else if (node instanceof GardenObjectNode) {
      this.interactionInterface.editItem(GardenItemType.GardenObject, node.id);
    } else if (node instanceof ShapeNode) {
      this.interactionInterface.editItem(GardenItemType.Shape, node.id);
    } else if (node instanceof TextNode) {
      this.interactionInterface.editItem(GardenItemType.Text, node.id);
    }
  };

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  #onRightClick = (node: Node, worldPosition: Vector2) => {
    // console.log('Context menu Open', worldPosition);
    this.interactionInterface.showContextMenu(worldPosition);
  };

  // For now, do the same as the double-click action.
  #onLongPress = this.#onDoubleClick;

  inspectorData: InspectableClassData<this> = [];
}

export default GardenItemClickMiddleware;
