import React, { useContext } from 'react';
import LengthInput from '@gi/length-input';
import FormField, { FormSection, FORM_FIELD_PRESETS } from '@gi/form-responsive';
import { PlanValidation } from '@gi/plan';
import { PlantTypes } from '@gi/constants';
import { VectorInputRow } from '@gi/form-responsive-components';

import { EditPlantContext } from '../context/edit-plant-context';

const SinglePlantInputs = () => {
  const { editPlantValues, setEditPlantValue, distanceUnits } = useContext(EditPlantContext);

  return (
    <FormField fakeLabel label='Plant Position' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
      <VectorInputRow
        value={editPlantValues.values.rowStart}
        onChange={(vec) => setEditPlantValue('rowStart', vec)}
        distanceUnits={distanceUnits}
        disabled={false}
        id='single-plant-input'
      />
    </FormField>
  );
};

const PlantRowInputs = () => {
  const { editPlantValues, setEditPlantValue, distanceUnits } = useContext(EditPlantContext);
  return (
    <>
      <FormField fakeLabel label='First Plant' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInputRow
          value={editPlantValues.values.rowStart}
          onChange={(vec) => setEditPlantValue('rowStart', vec)}
          distanceUnits={distanceUnits}
          id='plant-row-start-input'
        />
      </FormField>
      <FormField fakeLabel label='Last Plant' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInputRow
          value={editPlantValues.values.rowEnd}
          onChange={(vec) => setEditPlantValue('rowEnd', vec)}
          distanceUnits={distanceUnits}
          id='plant-row-end-input'
        />
      </FormField>
    </>
  );
};

const PlantBlockInputs = () => {
  const { editPlantValues, setEditPlantValue, distanceUnits } = useContext(EditPlantContext);

  return (
    <>
      <FormField fakeLabel label='First Plant' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInputRow
          value={editPlantValues.values.rowStart}
          onChange={(vec) => setEditPlantValue('rowStart', vec)}
          distanceUnits={distanceUnits}
          id='plant-row-start-input'
        />
      </FormField>
      <FormField fakeLabel label='Last Plant' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInputRow
          value={editPlantValues.values.rowEnd}
          onChange={(vec) => setEditPlantValue('rowEnd', vec)}
          distanceUnits={distanceUnits}
          id='plant-row-end-input'
        />
      </FormField>
      <FormField label='Height' htmlFor='edit-block-height' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <LengthInput
          id='edit-block-height'
          value={editPlantValues.values.height}
          onChange={(height) => setEditPlantValue('height', height)}
          distanceUnits={distanceUnits}
        />
      </FormField>
    </>
  );
};

const PositionInputs = (): JSX.Element | null => {
  const { plant, planPlant, userPlantVariety } = useContext(EditPlantContext);

  const validatedPlantData = PlanValidation.validatePlant(
    planPlant.isSquareFoot,
    planPlant.rowStart,
    planPlant.rowEnd,
    planPlant.height,
    plant,
    userPlantVariety
  );

  let content: JSX.Element | null = null;

  switch (validatedPlantData.type) {
    case PlantTypes.PLANT_SINGLE:
    case PlantTypes.PLANT_SQUARE_FOOT:
      content = <SinglePlantInputs />;
      break;
    case PlantTypes.PLANT_ROW:
      content = <PlantRowInputs />;
      break;
    case PlantTypes.PLANT_BLOCK:
      content = <PlantBlockInputs />;
      break;
    default:
      content = null;
  }

  return (
    <FormSection padding={12} gap={6} className='form-section-background' heading='Position'>
      {content}
    </FormSection>
  );
};

export default PositionInputs;
