import { PlanPlant } from '@gi/plan';
import { PlantingCalendar } from '@gi/planting-calendar';
import { PlantListAreaGroup, PlantListSummaryGroup, PlantListVarietyGroup, SORT_ORDER } from '../types/plant-list-types';

/** All of the available plant group types (display modes) */
type AnyPlantListGroup = PlantListVarietyGroup | PlantListAreaGroup | PlantListSummaryGroup;

type InGroundKey = keyof Pick<PlanPlant, 'inGroundStart' | 'inGroundEnd'>;
/** Returns the specified in-ground time, accounting for if `inGroundAll` is set */
function getInGroundValue(plant: PlanPlant, field: InGroundKey): number {
  return plant.inGroundAll ? 12 : plant[field];
}

/** Returns the duration (in months) that the plant is in the ground for */
function getInGroundDuration(plant: PlanPlant): number {
  if (plant.inGroundAll) {
    return 12;
  }
  if (plant.inGroundStart > plant.inGroundEnd) {
    return 12 - (plant.inGroundStart - plant.inGroundEnd);
  }

  return plant.inGroundEnd - plant.inGroundStart;
}

type PlantingCalendarKey = keyof Pick<PlantingCalendar, 'earliestHarvest' | 'earliestPlant' | 'earliestSow' | 'latestHarvest' | 'latestPlant' | 'latestSow'>;
/** Returns the specified planting calendar time, accounting for missing calendar values */
function getPlantingCalendarValue(calendar: PlantingCalendar | null, field: PlantingCalendarKey): number {
  if (calendar === null || calendar[field] === null) {
    return -1; // -1 seems to be the fallback value for the planting calendar. Match it here.
  }
  return calendar[field] as number; // Definitely a number, null check above is being odd
}

/** Returns the duration (in half-months) of the specified calendar phase for a planting calendar */
function getPlantingCalendarDuration(calendar: PlantingCalendar | null, field: 'sow' | 'plant' | 'harvest'): number {
  if (calendar === null) {
    return 0;
  }

  let earliestFieldName: PlantingCalendarKey;
  let latestFieldName: PlantingCalendarKey;

  switch (field) {
    case 'sow':
      earliestFieldName = 'earliestSow';
      latestFieldName = 'latestSow';
      break;
    case 'plant':
      earliestFieldName = 'earliestPlant';
      latestFieldName = 'latestPlant';
      break;
    case 'harvest':
      earliestFieldName = 'earliestHarvest';
      latestFieldName = 'latestHarvest';
      break;
    default: {
      // Error
      return 0;
    }
  }

  const startAt = getPlantingCalendarValue(calendar, earliestFieldName);
  const endAt = getPlantingCalendarValue(calendar, latestFieldName);

  if (startAt > endAt) {
    // Max duration is 23, as values run from 0-23
    return 23 - (startAt - endAt);
  }

  return endAt - startAt;
}

type Comparator<T> = (a: T, b: T, ascending: boolean) => number;

/** Returns a comparator function for ordering numbers. Uses the getter function to get the number to compare on each item */
function compareNumber<T>(getter: (item: T) => number): Comparator<T> {
  return (a, b, ascending) => {
    const result = getter(a) - getter(b);
    return ascending ? -result : result;
  };
}

/** Returns a comparator function for ordering strings. Uses the getter function to get the string to compare on each item */
function compareString<T>(getter: (item: T) => string): Comparator<T> {
  return (a, b, ascending) => {
    const result = getter(a).localeCompare(getter(b));
    return ascending ? -result : result;
  };
}

/** Returns a comparator function for ordering booleans. Uses the getter function to get the string to compare on each item */
function compareBooleans<T>(getter: (item: T) => boolean, falseFirst: boolean = false, ignoreAscending: boolean = false): Comparator<T> {
  return (a, b, ascending) => {
    const aResult = getter(a) ? 1 : 0;
    const bResult = getter(b) ? 1 : 0;
    const result = falseFirst ? bResult - aResult : aResult - bResult;
    return ascending && !ignoreAscending ? -result : result;
  };
}

/** Compares 2 plant names for sort order. Falls back to variety & modifier data in case of a tie if the data exists */
function comparePlantNames<T extends AnyPlantListGroup>(a: T, b: T, ascending: boolean): number {
  const _a = ascending ? b : a;
  const _b = ascending ? a : b;

  let sortVal = _a.plantName.localeCompare(_b.plantName);

  if (sortVal !== 0 || !('variety' in _a && 'variety' in _b)) {
    return sortVal;
  }

  sortVal = _a.variety.localeCompare(_b.variety);

  if (sortVal !== 0) {
    return sortVal;
  }

  if (_a.modifier === _b.modifier) {
    return 0;
  }

  if (_a.modifier === null) {
    return -1;
  }

  if (_b.modifier === null) {
    return 1;
  }

  return _a.modifier.localeCompare(_b.modifier);
}

/**
 * Returns a sorted copy of an array, using the comparator functions to determine the order.
 * @param items The items to sort
 * @param ascending Should the items be sorted in ascending order?
 * @param comparators A list of comparator functions to run.
 * Later comparators will only be used if the previous comparator returned a tie
 * @returns A sorted copy of the input items
 */
function sort<T>(items: T[], ascending: boolean, ...comparators: Comparator<T>[]): T[] {
  return [...items].sort((a, b) => {
    let comparatorIndex = 0;
    let result: number = comparators[comparatorIndex](a, b, ascending);

    while (result === 0 && comparatorIndex < comparators.length - 1) {
      comparatorIndex++;
      result = comparators[comparatorIndex](a, b, ascending);
    }

    return result;
  });
}

export const SortFunctions = {
  // Sort by plant name
  [SORT_ORDER.PlantNameAsc]: <T extends AnyPlantListGroup>(items: T[]): T[] => sort(items, true, comparePlantNames),
  [SORT_ORDER.PlantNameDesc]: <T extends AnyPlantListGroup>(items: T[]): T[] => sort(items, false, comparePlantNames),

  // Sort by plant label - only applicable to PlantListAreaGroup
  [SORT_ORDER.PlantLabelAsc]: (items: PlantListAreaGroup[]) =>
    sort(
      items,
      true,
      compareString((i) => i.planPlant.labelText)
    ),
  [SORT_ORDER.PlantLabelDesc]: (items: PlantListAreaGroup[]) =>
    sort(
      items,
      false,
      compareString((i) => i.planPlant.labelText)
    ),

  // Sort by plant quantity
  [SORT_ORDER.QuantityAsc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      true,
      compareNumber((i) => i.count)
    ),
  [SORT_ORDER.QuantityDesc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      false,
      compareNumber((i) => i.count)
    ),

  // Sort by in-ground start dates
  [SORT_ORDER.InGroundStartAsc]: (items: PlantListAreaGroup[]) =>
    sort(
      items,
      true,
      compareNumber((i) => getInGroundValue(i.planPlant, 'inGroundStart')),
      compareNumber((i) => getInGroundDuration(i.planPlant))
    ),
  [SORT_ORDER.InGroundStartDesc]: (items: PlantListAreaGroup[]) =>
    sort(
      items,
      false,
      compareNumber((i) => getInGroundValue(i.planPlant, 'inGroundStart')),
      compareNumber((i) => getInGroundDuration(i.planPlant))
    ),

  // Sort by in-ground end dates
  [SORT_ORDER.InGroundEndAsc]: (items: PlantListAreaGroup[]) =>
    sort(
      items,
      true,
      compareNumber((i) => getInGroundValue(i.planPlant, 'inGroundEnd')),
      compareNumber((i) => getInGroundDuration(i.planPlant))
    ),
  [SORT_ORDER.InGroundEndDesc]: (items: PlantListAreaGroup[]) =>
    sort(
      items,
      false,
      compareNumber((i) => getInGroundValue(i.planPlant, 'inGroundEnd')),
      compareNumber((i) => getInGroundDuration(i.planPlant))
    ),

  // Sort by planting calendar - sow indoors start date
  [SORT_ORDER.SowStartAsc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      true,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestSow') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestSow')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'sow'))
    ),
  [SORT_ORDER.SowStartDesc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      false,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestSow') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestSow')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'sow'))
    ),

  // Sort by planting calendar - sow indoors end date
  [SORT_ORDER.SowEndAsc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      true,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestSow') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestSow')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'sow'))
    ),
  [SORT_ORDER.SowEndDesc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      false,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestSow') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestSow')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'sow'))
    ),

  // Sort by planting calendar - plant outdoors start date
  [SORT_ORDER.PlantStartAsc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      true,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestPlant') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestPlant')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'plant'))
    ),
  [SORT_ORDER.PlantStartDesc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      false,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestPlant') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestPlant')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'plant'))
    ),

  // Sort by planting calendar - plant outdoors end date
  [SORT_ORDER.PlantEndAsc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      true,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestPlant') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestPlant')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'plant'))
    ),
  [SORT_ORDER.PlantEndDesc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      false,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestPlant') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestPlant')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'plant'))
    ),

  // Sort by planting calendar - harvest start date
  [SORT_ORDER.HarvestStartAsc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      true,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestHarvest') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestHarvest')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'harvest'))
    ),
  [SORT_ORDER.HarvestStartDesc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      false,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestHarvest') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'earliestHarvest')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'harvest'))
    ),

  // Sort by planting calendar - harvest end date
  [SORT_ORDER.HarvestEndAsc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      true,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestHarvest') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestHarvest')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'harvest'))
    ),
  [SORT_ORDER.HarvestEndDesc]: <T extends AnyPlantListGroup>(items: T[]): T[] =>
    sort(
      items,
      false,
      compareBooleans((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestHarvest') === -1, false, true),
      compareNumber((i) => getPlantingCalendarValue(i.plantingCalendar, 'latestHarvest')),
      compareNumber((i) => getPlantingCalendarDuration(i.plantingCalendar, 'harvest'))
    ),
} satisfies Record<SORT_ORDER, (items: any[]) => any[]>;
