class Collection<T> {
  itemList: T[];
  items: { [code: string]: T };
  codes: string[];

  constructor() {
    this.items = {};
    this.itemList = [];
    this.codes = [];
  }

  add(item: T, code: string) {
    this.items[code] = item;
    this.itemList.push(item);
    this.codes.push(code);
  }

  has(code: string): boolean {
    if (!this.items[code]) {
      return false;
    }

    return true;
  }

  get(code: string): T | null {
    if (!this.has(code)) {
      return null;
    }

    return this.items[code];
  }

  get size() {
    return Object.keys(this.items).length;
  }

  asArray(): Readonly<T[]> {
    // TODO: Change this to 'return this.itemList;'
    return this.itemList.slice(0);
  }

  /**
   * Return the interal read-only list, no slice so it's fast and making it readonly seems fine?
   */
  getList(): readonly Readonly<T>[] {
    return this.itemList;
  }
}

export default Collection;
