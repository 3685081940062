import { ReactNode, createContext } from 'react';

import { Direction } from '@gi/constants';

export type SidebarContextType = {
  minimised: boolean;
  setMinimised: (minimised: boolean) => void;
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
  toggleTab: (activeTab: string) => void;
  registerHeaderContent: (tabId: string | null, content: ReactNode) => void;
  unregisterHeaderContent: (tabId: string | null) => void;
  headerContent: ReactNode | null;
  orientation: Direction.LEFT | Direction.RIGHT;
};

export const SidebarContext = createContext<SidebarContextType>({} as SidebarContextType);
