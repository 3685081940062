export const lexographicSort = (a: string, b: string): number => {
  return a.localeCompare(b);
};

interface iWithId {
  ID: number;
}

export const sortByID = (a: iWithId, b: iWithId): number => {
  return a.ID - b.ID;
};
