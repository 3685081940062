/**
 * Enum for the types of units
 *
 * @readonly
 * @enum {UnitType}
 */
const UnitTypes = {
  METRIC: 'METRIC',
  IMPERIAL: 'IMPERIAL',
} as const;

export type UnitType = (typeof UnitTypes)[keyof typeof UnitTypes];

export default UnitTypes;
