import { State, StateDef } from '@gi/state';

import NodeComponent from '../../node-component/node-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { bindState } from '../../utils/state-utils';

// Allows specifying either a full StateDef, or just a type for the state properties (old method)
type StatefulContextStateDef<T extends StateDef<any> | Record<string, any>> = T extends StateDef<any> ? T : StateDef<T>;

abstract class StatefulContext<T extends StateDef<any> | Record<string, any>> extends NodeComponent {
  type = 'StatefulContext';

  readonly state: State<StatefulContextStateDef<T>>;

  constructor(initialState: StatefulContextStateDef<T>['state']) {
    super();

    this.state = new State<StatefulContextStateDef<T>>(initialState);
    bindState(this.state, this);
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default StatefulContext;
