/**
 * Enum for the different stages of publishing a plan
 */
enum PublishPlanStage {
  NOT_IN_PROGRESS = 'NOT_IN_PROGRESS',
  GENERATING_CANVAS = 'GENERATING_CANVAS',
  CONVERTING_TO_IMAGE = 'CONVERTING_TO_IMAGE',
  GENERATING_PREVIEW_IMAGE = 'GENERATING_PREVIEW_IMAGE',
  GENERATING_THUMBNAIL_IMAGE = 'GENERATING_THUMBNAIL_IMAGE',
  UPLOADING = 'UPLOADING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
}

export default PublishPlanStage;
