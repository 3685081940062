import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactCrop, { PercentCrop, PixelCrop, type Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { LoadingState } from '@gi/constants';

import { ImageCrop, ImageEditorModalFormProps } from '../../types';

import styles from './styles.module.css';

interface iProps extends ImageEditorModalFormProps {
  image: HTMLImageElement;
}

const ImagePreview = ({ image, values, setValues }: iProps): JSX.Element => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState<LoadingState>(LoadingState.NONE);
  const { crop } = values.values;

  const _crop = useMemo<Crop | undefined>(() => {
    // Convert absolute crop to percentage crop so the crop still works if the image gets scaled down by the UI
    if (crop) {
      return {
        x: ((crop.x - crop.width / 2) / image.width) * 100,
        y: ((crop.y - crop.height / 2) / image.height) * 100,
        width: (crop.width / image.width) * 100,
        height: (crop.height / image.height) * 100,
        unit: '%',
      };
    }
    return undefined;
  }, [crop]);

  const handleCropChange = useCallback(
    (newCrop: PixelCrop, percentCrop: PercentCrop) => {
      // Convert percentage crop to absolute crop, as PixelCrop doesn't account for image scale caused by the UI
      const width = Math.round((percentCrop.width / 100) * image.width);
      const height = Math.round((percentCrop.height / 100) * image.height);

      const value: ImageCrop = {
        x: ((percentCrop.x + percentCrop.width / 2) / 100) * image.width,
        y: ((percentCrop.y + percentCrop.height / 2) / 100) * image.height,
        width,
        height,
      };

      setValues(values.setValue('crop', { value }));
    },
    [values, setValues]
  );

  useEffect(() => {
    if (imageRef.current) {
      setLoaded(LoadingState.LOADING);
      imageRef.current.src = image.src;
    }
  }, [image]);

  return (
    <div className={styles.container}>
      <ReactCrop crop={loaded === LoadingState.SUCCESS ? _crop : undefined} onChange={handleCropChange}>
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img
          ref={imageRef}
          onLoad={() => setLoaded(LoadingState.SUCCESS)}
          onError={() => setLoaded(LoadingState.ERROR)}
          alt='Preview of cropped image'
          data-loaded={loaded === LoadingState.SUCCESS}
        />
      </ReactCrop>
    </div>
  );
};

export default ImagePreview;
