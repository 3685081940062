import React, { ChangeEvent, useCallback } from 'react';

import { Geometry } from '@gi/math';
import { VectorInput } from '@gi/length-input';
import FormField, { FormSection, FORM_FIELD_PRESETS } from '@gi/form-responsive';

import { EditGardenObjectModalFormProps, GardenObjectState } from '../types';

const EditGardenObjectModalFormPath = ({ values, setValues, distanceUnits }: EditGardenObjectModalFormProps): JSX.Element => {
  const createSetter = useCallback(
    <K extends keyof GardenObjectState>(setting: K) =>
      (value: GardenObjectState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  const onCurvedChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      setValues(
        values.setValues(['curved', { value: checked }], ['mid', { value: checked ? Geometry.midpoint(values.values.start, values.values.end) : null }])
      );
    },
    [values, setValues]
  );

  return (
    <FormSection heading='Position &amp; Dimensions' padding={12} gap={6} className='form-section-background'>
      <FormField label='Start' htmlFor='edit-garden-object:start-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.start} onChange={createSetter('start')} distanceUnits={distanceUnits} id='edit-garden-object:start' />
      </FormField>
      <FormField label='End' htmlFor='edit-garden-object:end-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.end} onChange={createSetter('end')} distanceUnits={distanceUnits} id='edit-garden-object:end' />
      </FormField>
      <FormField label='Curved' htmlFor='edit-garden-object:curved' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <input type='checkbox' checked={values.values.curved} onChange={onCurvedChange} id='edit-garden-object:curved' />
      </FormField>
      <FormField label='Curve Point' htmlFor='edit-garden-object:mid-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector} disabled={!values.values.curved}>
        <VectorInput
          value={values.values.mid ?? { x: 0, y: 0 }}
          onChange={createSetter('mid')}
          distanceUnits={distanceUnits}
          id='edit-garden-object:mid'
          disabled={!values.values.curved}
        />
      </FormField>
    </FormSection>
  );
};

export default EditGardenObjectModalFormPath;
