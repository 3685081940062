import { FederatedPointerEvent } from 'pixi.js-new';
import PointerInteraction, { PointerInteractionCallbacks, PointerInteractionOptions } from '../../managers/interaction/interaction';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import InteractionContext from './interaction-context';
import { hasEngine } from '../../utils/asserts';
import ContentRootContext from '../../nodes/content-root/content-root-context';
import { InspectableClassData } from '../../types';

export type PointerEventHandler = (event: PointerEvent) => void;

interface InteractableComponentCallbackControls {
  /** Stop this pointer event from bubbling to parent nodes */
  stopPropagation: () => void;
}

interface BaseInteractableComponentCallbacks {
  onPointerEnter: (event: PointerEvent) => void;
  onPointerLeave: (event: PointerEvent) => void;
}

// Go through all of the pointer interaction callbacks and add an extra argument to the end to allow `stopPropagation`
// Don't do this for pointerEnter/leave as we don't other bubbling these
export type InteractableComponentCallbacks = BaseInteractableComponentCallbacks & {
  [K in keyof PointerInteractionCallbacks]: (
    ...args: [...Parameters<PointerInteractionCallbacks[K]>, controls: InteractableComponentCallbackControls]
  ) => ReturnType<PointerInteractionCallbacks[K]>;
};

interface InteractableComponentOptions {
  /** Should the screen auto-scroll if a drag goes near the edge of the screen */
  shouldAutoPan: boolean;
  /** Should the pointerdown event be immediately upgraded to a drag, ignoring clicks */
  ignoreClick: boolean;
  /** Should long presses be ignored */
  ignoreLongPress: boolean;
  /** Should multi-touch gestures be ignored. Generally don't use if you want to allow event bubbling */
  ignoreMultiTouch: boolean;
}

/** Names of all the actual event listeners */
type WatchedEvents = 'pointerDown' | 'pointerEnter' | 'pointerLeave';

/** List of all event names that relate to pointer interaction system events. These events will support propagation. */
const PointerInteractionEvents = [
  'onClick',
  'onDoubleClick',
  'onLongPress',
  'onDragStart',
  'onDragMove',
  'onDragEnd',
  'onDragCancel',
  'onMultiTouchStart',
  'onMultiTouchMove',
  'onMultiTouchEnd',
  'onMultiTouchCancel',
  'onStart',
  'onEnd',
] as const satisfies (keyof PointerInteractionCallbacks)[];

type EventListeners = {
  [K in keyof InteractableComponentCallbacks]: { listener: InteractableComponentCallbacks[K]; priority: number }[];
};

function appendEventListeners<K extends keyof EventListeners>(allListeners: Partial<EventListeners>, type: K, listeners: EventListeners[K]) {
  allListeners[type] ??= [];
  allListeners[type]?.push(...listeners);
}

function setInteractionCallback<K extends keyof PointerInteractionCallbacks>(
  allCallbacks: Partial<PointerInteractionCallbacks>,
  type: K,
  listener: PointerInteractionCallbacks[K]
) {
  allCallbacks[type] = listener;
}

/**
 * This component allows for event listeners to be added to listen for clicks/drags/hover.s
 * It acts as a middle-man between the PointerInteraction system and the core renderer nodes/components.
 * The main purpose is to allow numerous callbacks for the same event, and to bubble events to other nodes
 *  higher up in the hierarchy.
 */
class InteractableComponent extends NodeComponent {
  type = 'InteractableComponent2';

  #interaction: PointerInteraction | null = null;
  #interactionContext: InteractionContext | null = null;

  #options: InteractableComponentOptions;

  #eventListenersNeeded: Set<WatchedEvents> = new Set();
  #eventListenerDestructors: Partial<Record<WatchedEvents, () => void>> = {};

  #eventListeners: Partial<EventListeners> = {};
  get eventListeners(): Readonly<Partial<EventListeners>> {
    return this.#eventListeners;
  }

  constructor(options?: Partial<InteractableComponentOptions>) {
    super();

    this.#options = {
      shouldAutoPan: options?.shouldAutoPan ?? true,
      ignoreClick: options?.ignoreClick ?? false,
      ignoreLongPress: options?.ignoreLongPress ?? false,
      ignoreMultiTouch: options?.ignoreMultiTouch ?? false,
    };

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  /**
   * Sets an option for any interaction started by this component.
   * Will also apply the option to any active pointer interaction owned by this component.
   * @param option The option to set
   * @param value The value to use
   */
  setOption<K extends keyof InteractableComponentOptions>(option: K, value: InteractableComponentOptions[K]) {
    this.#options[option] = value;
    if (this.#interaction) {
      switch (option) {
        case 'ignoreClick':
          this.#interaction.ignoreClick = value;
          break;
        case 'shouldAutoPan':
          this.#interaction.autoPanCamera = value;
          break;
        case 'ignoreLongPress':
          this.#interaction.ignoreLongPress = value;
          break;
        case 'ignoreMultiTouch':
          this.#interaction.ignoreMultiTouch = value;
          break;
        default:
        // Exhausted
      }
    }
  }

  #onBind = () => {
    hasEngine(this);
    this.#interactionContext = this.owner.tryGetContext(InteractionContext);
    this.#setUpListeners();
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    this.#interaction?.cancel(true);
    this.#interactionContext = null;
    this.#tearDownListeners();
  };

  /**
   * Adds a listener to the given event.
   * @param type The event name
   * @param listener The callback to run
   * @param priority (optional) The priority of this callback. Higher priority will be run first. Default 0
   */
  addListener<K extends keyof InteractableComponentCallbacks>(type: K, listener: InteractableComponentCallbacks[K], priority: number = 0) {
    // eslint-disable-next-line no-multi-assign
    const eventListeners: EventListeners[K] = (this.#eventListeners[type] ??= []);
    const insertAt = eventListeners.findIndex((eventListener) => eventListener.priority < priority);
    if (insertAt !== -1) {
      eventListeners.splice(insertAt, 0, { listener, priority });
    } else {
      eventListeners.push({ listener, priority });
    }
    this.#setUpListener(type);
  }

  /**
   * Removes a listener from the given event
   * @param type The event name
   * @param listener The callback to remove
   */
  removeListener<K extends keyof InteractableComponentCallbacks>(type: K, listener: InteractableComponentCallbacks[K]) {
    const eventListeners = this.#eventListeners[type];
    if (eventListeners && eventListeners.length > 0) {
      const index = eventListeners.findIndex((eventListener) => eventListener.listener === listener);
      if (index !== -1) {
        eventListeners.splice(index, 1);
      }
    }
  }

  #updatePointerType = (pointerType: string) => {
    if (this.#interactionContext) {
      this.#interactionContext.setPointerType(pointerType);
    }
  };

  /** Recursively goes up the node hierarchy and gets all the interaction callbacks from each node */
  #getCallbacksFromParents() {
    const allEventListeners: Partial<EventListeners> = {};

    let target = this.owner;
    while (target !== null) {
      const interactable = target.components.get(InteractableComponent);

      if (interactable) {
        const { eventListeners } = interactable;
        for (let i = 0; i < PointerInteractionEvents.length; i++) {
          const listeners = eventListeners[PointerInteractionEvents[i]];
          if (listeners) {
            appendEventListeners(allEventListeners, PointerInteractionEvents[i], listeners);
          }
        }
      }

      target = target.parent;
    }

    return allEventListeners;
  }

  /** Wraps the given list of callbacks to it can be handed off to the pointer interaction system, adding the `stopPropagation` controls */
  #wrapCallback<T extends { pointerType: string }>(
    callbacks: ((event: T, interaction: PointerInteraction, controls: InteractableComponentCallbackControls) => void)[]
  ): (event: T, interaction: PointerInteraction) => void {
    return (event, interaction) => {
      this.#updatePointerType(event.pointerType);

      let propagationStopped: boolean = false;
      const controls: InteractableComponentCallbackControls = {
        stopPropagation: () => {
          propagationStopped = true;
        },
      };

      for (let i = 0; i < callbacks.length; i++) {
        callbacks[i](event, interaction, controls);
        if (propagationStopped) {
          break;
        }
      }
    };
  }

  /** Wraps all the event listener callbacks so they can be handed off to the pointer interaction system */
  #wrapCallbacks(eventListeners: Partial<EventListeners>) {
    const wrappedEventListeners: Partial<PointerInteractionCallbacks> = {};

    for (let i = 0; i < PointerInteractionEvents.length; i++) {
      const listeners = eventListeners[PointerInteractionEvents[i]];
      if (listeners) {
        setInteractionCallback(
          wrappedEventListeners,
          PointerInteractionEvents[i],
          this.#wrapCallback(listeners.map((eventListener) => eventListener.listener))
        );
      }
    }

    return wrappedEventListeners;
  }

  handlePointerDown = (event: PointerEvent | FederatedPointerEvent) => {
    if (!this.owner || !this.owner.engine) {
      return; // Can't start interaction without access to interaction manager on engine
    }

    const callbacks = this.#getCallbacksFromParents();
    const wrappedCallbacks = this.#wrapCallbacks(callbacks);
    const options = this.#getInteractionOptions();

    this.#interaction = this.owner.engine.interactionManager.startInteraction(event, wrappedCallbacks, options);
  };

  #handlePointerEnter = (event: FederatedPointerEvent) => {
    // For some reason, an extra mouseover event is being fired after a mouseout caused by the mouse leaving the canvas.
    // Until I can figure out why, we should check the event originated from the engine canvas, as otherwise
    // it means we're hovering a UI element, and don't want to also be hovering the canvas.
    if (event.nativeEvent.target !== this.owner?.engine?.canvas) {
      return;
    }
    this.#updatePointerType(event.pointerType);
    if (this.#eventListeners.onPointerEnter) {
      this.#eventListeners.onPointerEnter.forEach(({ listener }) => listener(event));
    }
  };

  #handlePointerLeave = (event: FederatedPointerEvent) => {
    this.#updatePointerType(event.pointerType);
    if (this.#eventListeners.onPointerLeave) {
      this.#eventListeners.onPointerLeave.forEach(({ listener }) => listener(event));
    }
  };

  #getInteractionOptions(): Partial<PointerInteractionOptions> {
    const camera = this.owner?.tryGetContext(ContentRootContext)?.activeCamera;
    return {
      camera: camera ?? undefined,
      autoPanCamera: this.#options.shouldAutoPan,
      ignoreClick: this.#options.ignoreClick,
      ignoreLongPress: this.#options.ignoreLongPress,
      ignoreMultiTouch: this.#options.ignoreMultiTouch,
      target: this.owner?.getContainer(),
    };
  }

  /** Set up any native event listeners that are needed, but have yet to be set up */
  #setUpListeners() {
    if (!this.owner) {
      return;
    }

    this.#eventListenersNeeded.forEach((eventName) => {
      switch (eventName) {
        case 'pointerDown':
          this.#setUpPointerDownListener();
          break;
        case 'pointerEnter':
          this.#setUpPointerEnterListener();
          break;
        case 'pointerLeave':
          this.#setUpPointerLeaveListener();
          break;
        default:
        // Nothing
      }
    });

    this.#eventListenersNeeded.clear();
  }

  #tearDownListeners() {
    Object.values(this.#eventListenerDestructors).forEach((destructor) => destructor());
    this.#eventListenerDestructors = {};
  }

  /** Sets up the native event listener for the given event type */
  #setUpListener<K extends keyof InteractableComponentCallbacks>(type: K) {
    switch (type) {
      case 'onClick':
      case 'onDoubleClick':
      case 'onLongPress':
      case 'onDragStart':
      case 'onDragMove':
      case 'onDragEnd':
      case 'onDragCancel':
      case 'onMultiTouchStart':
      case 'onMultiTouchMove':
      case 'onMultiTouchEnd':
      case 'onMultiTouchCancel':
      case 'onEnd': {
        this.#setUpPointerDownListener();
        break;
      }
      case 'onPointerEnter': {
        this.#setUpPointerEnterListener();
        break;
      }
      case 'onPointerLeave': {
        this.#setUpPointerLeaveListener();
        break;
      }
      default: {
        // Do nothing, exhausted all options
      }
    }
  }

  #setUpPointerDownListener() {
    if (!this.owner) {
      this.#eventListenersNeeded.add('pointerDown');
      return;
    }
    if (this.#eventListenerDestructors.pointerDown) {
      return;
    }

    const container = this.owner.getContainer();
    container.eventMode = 'static';

    container.on('pointerdown', this.handlePointerDown);
    this.#eventListenerDestructors.pointerDown = () => {
      container.off('pointerdown', this.handlePointerDown);
    };
  }

  #setUpPointerEnterListener() {
    if (!this.owner) {
      this.#eventListenersNeeded.add('pointerEnter');
      return;
    }
    if (this.#eventListenerDestructors.pointerEnter) {
      return;
    }

    const container = this.owner.getContainer();
    container.eventMode = 'static';

    container.on('mouseover', this.#handlePointerEnter);
    this.#eventListenerDestructors.pointerEnter = () => {
      container.off('mouseover', this.#handlePointerEnter);
    };
  }

  #setUpPointerLeaveListener() {
    if (!this.owner) {
      this.#eventListenersNeeded.add('pointerLeave');
      return;
    }
    if (this.#eventListenerDestructors.pointerLeave) {
      return;
    }

    const container = this.owner.getContainer();
    container.eventMode = 'static';

    container.on('mouseout', this.#handlePointerLeave);
    this.#eventListenerDestructors.pointerLeave = () => {
      container.off('mouseout', this.#handlePointerLeave);
    };
  }

  /** Is there an active interaction going on originating from this component? */
  isActive() {
    return this.#interaction !== null && !this.#interaction.hasEnded;
  }

  /** Cancels the current interaction coming from this component, if any */
  cancel() {
    if (this.#interaction) {
      this.#interaction.cancel();
    }
  }

  inspectorData: InspectableClassData<this> = [];
}

export default InteractableComponent;
