import React, { useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';

import NotificationDisplayContainer from '../notification-display-container';
import MobileNotificationContext from './notification-context';

/**
 * This component renders notifications in-place, unless a notification portal is active.
 * Then it will render them in the notification portal.
 * This allows components to teleport notifications to more convenient places, such as to avoid a bottom-bar.
 */
const MobileNotifcationContainer = (): JSX.Element => {
  const { currentPortal } = useContext(MobileNotificationContext);

  const notificationComponent = useMemo(() => <NotificationDisplayContainer isMobile />, []);

  if (currentPortal) {
    return createPortal(notificationComponent, currentPortal);
  }

  return notificationComponent;
};

export default MobileNotifcationContainer;
