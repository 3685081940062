import Plant from '@gi/plant';
import GardenObject from '@gi/garden-object';
import { EventBus } from '@gi/core-renderer';
import { GardenItemType, ItemNodeType, ShapeType } from '@gi/constants';

import EditSimulatedGardenObject from '../simulation/edit/edit-simulated-garden-object';
import EditSimulatedPlant from '../simulation/edit/edit-simulated-plant';
import EditSimulatedShape from '../simulation/edit/edit-simulated-shape';
import EditSimulatedText from '../simulation/edit/edit-simulated-text';
import { SimulatedPlan } from '../simulation/simulated-plan';
import { getEditGroup } from './utils';
import { SimulationFactory } from '../synced-plan/simulation-factory';
import { SimulatedGardenItemEffect } from '../simulation/simulated-garden-item';
import CanvasInteractionGroup from './canvas-interaction-group';
import EditSimulatedBackgroundImage from '../simulation/edit/edit-simulated-background-image';

export enum CanvasInteractionInterfaceEvent {
  OnEditItem = 'OnEditItem',
  ShowTouchDragHelp = 'ShowTouchDragHelp',
  HideTouchDragHelp = 'HideTouchDragHelp',
  OnDrawPlant = 'DrawPlant',
  OnDrawGardenObject = 'DrawGardenObject',
  OnDrawShape = 'DrawShape',
  OnDrawText = 'DrawText',
  OnContextMenu = 'OnContextMenu',
}

export type CanvasInteractionInterfaceEventActions = {
  [CanvasInteractionInterfaceEvent.OnEditItem]: (itemType: GardenItemType, itemId: number, planId: number) => void;
  [CanvasInteractionInterfaceEvent.ShowTouchDragHelp]: (itemType: ItemNodeType, itemId: number, planId: number) => void;
  [CanvasInteractionInterfaceEvent.HideTouchDragHelp]: () => void;
  [CanvasInteractionInterfaceEvent.OnDrawPlant]: (plant: Plant, sfg: boolean, isDragToDraw: boolean) => void;
  [CanvasInteractionInterfaceEvent.OnDrawGardenObject]: (gardenObject: GardenObject, isDragToDraw: boolean) => void;
  [CanvasInteractionInterfaceEvent.OnDrawShape]: (shapeType: ShapeType, filled: boolean, isDragToDraw: boolean) => void;
  [CanvasInteractionInterfaceEvent.OnDrawText]: (isDragToDraw: boolean) => void;
  [CanvasInteractionInterfaceEvent.OnContextMenu]: (worldPosition: Vector2) => void;
};

class CanvasInteractionInterface extends EventBus<CanvasInteractionInterfaceEventActions> {
  #simulatedPlan: SimulatedPlan;
  #simulationFactory: SimulationFactory;

  selection: CanvasInteractionGroup = new CanvasInteractionGroup();

  constructor(simulatedPlan: SimulatedPlan, simulationFactory: SimulationFactory) {
    super();

    this.#simulatedPlan = simulatedPlan;
    this.#simulationFactory = simulationFactory;
  }

  removeItem(itemId: number) {
    this.#simulatedPlan.removeItem(itemId);
  }

  setShowPlantLabel(plantId: number, showLabel: boolean) {
    const plant = this.#simulatedPlan.plants[plantId];
    if (!plant) {
      throw new Error(`Cannot ${showLabel ? 'show' : 'hide'} label of plant with ID ${plantId}: Plant not found in the simulation`);
    }
    plant.setShowLabel(showLabel);
  }

  startEdit(targets: CanvasInteractionGroup) {
    const editGroup = getEditGroup(targets, this.#simulatedPlan);
    return editGroup;
  }

  /**
   * Returns an edit object for the given plant.
   * @param plantID The ID of the plant to edit
   * @returns An edit object to allow editing a plant
   */
  startEditPlant(plantID: number) {
    if (!this.#simulatedPlan.plants[plantID]) {
      throw new Error(`Cannot edit plant with ID ${plantID}: Plant not found in the simulation`);
    }
    return new EditSimulatedPlant(this.#simulatedPlan.plants[plantID]);
  }

  /**
   * Returns an edit object for the given SFG plant.
   * @param plantID The ID of the plant to edit
   * @returns An edit object to allow editing a plant
   */
  startEditSFGPlant(plantID: number) {
    if (!this.#simulatedPlan.plants[plantID]) {
      throw new Error(`Cannot edit plant with ID ${plantID}: Plant not found in the simulation`);
    }
    return new EditSimulatedPlant(this.#simulatedPlan.plants[plantID]);
  }

  startEditGardenObject(gardenObjectId: number) {
    if (!this.#simulatedPlan.gardenObjects[gardenObjectId]) {
      throw new Error(`Cannot edit garden object with ID ${gardenObjectId}: Garden Object not found in the simulation`);
    }
    return new EditSimulatedGardenObject(this.#simulatedPlan.gardenObjects[gardenObjectId]);
  }

  startEditShape(shapeID: number) {
    if (!this.#simulatedPlan.shapes[shapeID]) {
      throw new Error(`Cannot edit shape with ID ${shapeID}: Shape not found in the simulation`);
    }
    return new EditSimulatedShape(this.#simulatedPlan.shapes[shapeID]);
  }

  startEditText(textID: number) {
    if (!this.#simulatedPlan.text[textID]) {
      throw new Error(`Cannot edit text with ID ${textID}: Text not found in the simulation`);
    }
    return new EditSimulatedText(this.#simulatedPlan.text[textID]);
  }

  startEditBackgroundImage() {
    if (!this.#simulatedPlan.backgroundImage) {
      throw new Error('Cannot edit background image: Background image not found in the simulation');
    }
    return new EditSimulatedBackgroundImage(this.#simulatedPlan.backgroundImage);
  }

  endEdit() {
    this.pushUpdates();
  }

  startDrawingPlant(plant: Plant, isSquareFoot: boolean, position: Vector2, showLabel: boolean) {
    const simulatedPlant = this.#simulationFactory.createSimulatedPlantFromDraw(
      this.#simulatedPlan.nextItemId,
      plant,
      isSquareFoot,
      position,
      position,
      showLabel
    );
    simulatedPlant.addEffect(SimulatedGardenItemEffect.JustCreated);
    this.#simulatedPlan.addPlant(simulatedPlant);
    return simulatedPlant;
  }

  startDrawingGardenObject(gardenObject: GardenObject, position: Vector2) {
    const simulatedGardenObject = this.#simulationFactory.createSimulatedGardenObjectFromDraw(this.#simulatedPlan.nextItemId, gardenObject, position);
    simulatedGardenObject.addEffect(SimulatedGardenItemEffect.JustCreated);
    this.#simulatedPlan.addGardenObject(simulatedGardenObject);
    return simulatedGardenObject;
  }

  startDrawingShape(shapeType: ShapeType, position: Vector2, fill: number | null, stroke: number | null, strokeWidth: number, texture: string | null) {
    const simulatedShape = this.#simulationFactory.createSimulatedShapeFromDraw(
      this.#simulatedPlan.nextItemId,
      shapeType,
      position,
      fill,
      stroke,
      strokeWidth,
      texture
    );
    simulatedShape.addEffect(SimulatedGardenItemEffect.JustCreated);
    this.#simulatedPlan.addShape(simulatedShape);
    return simulatedShape;
  }

  startDrawingText(position: Vector2, fontSize: number, color: number) {
    const simulatedText = this.#simulationFactory.createSimulatedTextFromDraw(this.#simulatedPlan.nextItemId, position, fontSize, color);
    simulatedText.addEffect(SimulatedGardenItemEffect.JustCreated);
    this.#simulatedPlan.addText(simulatedText);
    return simulatedText;
  }

  onDrawPlant(...params: Parameters<CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.OnDrawPlant]>) {
    this.emit(CanvasInteractionInterfaceEvent.OnDrawPlant, ...params);
  }

  onDrawGardenObject(...params: Parameters<CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.OnDrawGardenObject]>) {
    this.emit(CanvasInteractionInterfaceEvent.OnDrawGardenObject, ...params);
  }

  onDrawShape(...params: Parameters<CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.OnDrawShape]>) {
    this.emit(CanvasInteractionInterfaceEvent.OnDrawShape, ...params);
  }

  onDrawText(...params: Parameters<CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.OnDrawText]>) {
    this.emit(CanvasInteractionInterfaceEvent.OnDrawText, ...params);
  }

  editItem(itemType: GardenItemType, itemId: number) {
    this.emit(CanvasInteractionInterfaceEvent.OnEditItem, itemType, itemId, this.#simulatedPlan.id);
  }

  showContextMenu(worldPosition: Vector2) {
    this.emit(CanvasInteractionInterfaceEvent.OnContextMenu, worldPosition);
  }

  showTouchDragHelp(itemType: ItemNodeType, itemId: number) {
    this.emit(CanvasInteractionInterfaceEvent.ShowTouchDragHelp, itemType, itemId, this.#simulatedPlan.id);
  }

  hideTouchDragHelp() {
    this.emit(CanvasInteractionInterfaceEvent.HideTouchDragHelp);
  }

  updateCollisions() {
    this.#simulatedPlan.updateCollisions();
  }

  pushUpdates() {
    this.updateCollisions();
    this.#simulatedPlan.signalPushUpdates();
  }
}

export default CanvasInteractionInterface;

// on CanvasPlan
