import EventEmitter from 'eventemitter3';

const ANY_EVENT = Symbol('ANY_EVENT');

/**
 * Generic Events Emitter system
 *
 * @example
 * enum Event {
 *  Event1 = 'Event1',
 *  Event2 = 'Event2,
 * }
 *
 * type EventParams = {
 *  [Event.Event1]: { name: string },
 *  [Event.Event2]: { random: number, toggle: boolean },
 * }
 *
 * const eventsSystem = new EventsSystem<Event, EventParams>();
 * eventsSystem.addListener(Event.Event2, ({ name }) => {
 *  console.log(`Event2 with name: ${name}`);
 * })
 * eventsSystem.fireEvent(Event.Event2, { name: 'Bob' });
 */
export class EventsSystem<TKey extends string, TParams extends Record<string, Record<string, any>>> {
  private eventEmitter: EventEmitter = new EventEmitter();

  fireEvent<T extends TKey>(eventName: T, parameters: TParams[T]) {
    this.eventEmitter.emit(eventName, parameters);
    this.eventEmitter.emit(ANY_EVENT, { eventName, parameters });
  }

  addListener<T extends TKey>(eventName: T, callback: (parameters: TParams[T]) => void) {
    this.eventEmitter.addListener(eventName, callback);
  }

  removeListener<T extends TKey>(eventName: T, callback: (parameters: TParams[T]) => void) {
    this.eventEmitter.removeListener(eventName, callback);
  }

  /**
   * Adds a listener for ALL events fired by this system.
   *
   * Callback contains the eventName and parameters.
   *
   * To get the correct parameters type from the event, use the `isEvent` typeguard.
   *
   * @example
   * eventsSystem.addGenericListener((event) => {
   *  if (eventsSystem.isEvent(Event.EventName, event)) {
   *   // event.parameters is now correctly typed within here
   *  }
   * })
   */
  addGenericListener(callback: <T extends TKey>(event: { eventName: T; parameters: TParams[T] }) => void) {
    this.eventEmitter.addListener(ANY_EVENT, callback);
  }

  removeGenericListener(callback: <T extends TKey>(event: { eventName: T; parameters: TParams[T] }) => void) {
    this.eventEmitter.removeListener(ANY_EVENT, callback);
  }

  /**
   * Type guard for checking if a generic event is a specific event.
   *
   * Forces the `parameters` property to be correctly typed.
   * @param eventName The name of the desired event
   * @param event The event details
   * @returns True if the event matches the eventName
   */
  // eslint-disable-next-line class-methods-use-this
  isEvent<T extends TKey>(eventName: T, event: { eventName: TKey; parameters: TParams[TKey] }): event is { eventName: T; parameters: TParams[T] } {
    return event.eventName === eventName;
  }
}
