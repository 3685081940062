import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import AnimateHeight from 'react-animate-height';

import { CanvasActionCreators, CanvasSelectors } from '@gi/react-garden-canvas';
import { RequestSelectors } from '@gi/react-requests';
import { RequestStatus, RequestsUtils } from '@gi/request';
import LoadingButton from '@gi/loading-button';

import UnsavedPlanEntry from './unsaved-plan-entry';

import './unsaved-plans-warning.scss';

const isSavingUnsavedPlan = createSelector([CanvasSelectors.getUnsavedPlans, RequestSelectors.getRequests], (unsavedPlans, requests) => {
  return unsavedPlans.planIds.some((planID) => {
    const status = RequestsUtils.getStatus(requests, `SAVE_PLAN_${planID}`);
    return status === RequestStatus.IN_PROGRESS;
  });
});

const UnsavedPlansWarning = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const unsavedPlans = useSelector(CanvasSelectors.getUnsavedPlans);
  const initialUnsavedPlans = useMemo(() => {
    return unsavedPlans;
  }, []);
  const savingPlan = useSelector(isSavingUnsavedPlan);

  const [hasClicked, setHasClicked] = useState(false);

  const hasUnsaved = useMemo(() => unsavedPlans.planIds.length > 0, [unsavedPlans]);

  const saveAll = () => {
    setHasClicked(true);
    dispatch(CanvasActionCreators.saveOpenPlans());
  };

  if (initialUnsavedPlans.planIds.length === 0) {
    return null;
  }

  const buttonComponent = useMemo(() => {
    return (
      <LoadingButton
        className='button button-primary'
        buttonIcon={hasUnsaved ? 'icon-floppy' : 'icon-ok'}
        type='button'
        loading={savingPlan && hasClicked}
        disabled={savingPlan || !hasUnsaved}
        onClick={saveAll}
      >
        {hasUnsaved ? 'Save All' : 'Saved'}
      </LoadingButton>
    );
  }, [hasUnsaved, savingPlan]);

  const headingComponent = useMemo(() => {
    if (hasUnsaved) {
      return <strong>You have unsaved changes in the following plans:</strong>;
    }
    return (
      <strong>
        <i className='icon-ok' /> All plans saved
      </strong>
    );
  }, [hasUnsaved]);

  const classNames: string[] = ['unsaved-plans-warning'];
  if (!hasUnsaved) {
    classNames.push('success');
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='unsaved-plans-warning-inner'>
        <p>{headingComponent}</p>
        <AnimateHeight className='unsaved-plans-animator' height={unsavedPlans.planIds.length > 0 ? 'auto' : 0} duration={250}>
          <div className='unsaved-plan-list'>
            {initialUnsavedPlans.planIds.map((planId) => {
              return <UnsavedPlanEntry key={planId} planID={planId} />;
            })}
          </div>
          {buttonComponent}
        </AnimateHeight>
      </div>
    </div>
  );
};

export default UnsavedPlansWarning;
