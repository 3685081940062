import React from 'react';

interface iProps {
  children: React.ReactNode;
  className?: string;
}

const TableRow = ({ children, className = '' }: iProps): JSX.Element => {
  return <tr className={`table-row ${className}`}>{children}</tr>;
};

export default TableRow;
