import { Filter } from '@gi/filters';
import Plant from '@gi/plant';

type CompanionFilterState = {
  enabled: boolean;
  onlyHelped: boolean;
  onlyHelper: boolean;
  plantCodes: Set<string>;
  companionPlantCodes: Set<string>;
};

const companionFilter: Filter<Plant, CompanionFilterState> = {
  name: 'Companion',
  inputs: {
    enabled: false,
    onlyHelped: false,
    onlyHelper: false,
    plantCodes: new Set(),
    companionPlantCodes: new Set(),
  },
  createListFilter: ({ enabled, onlyHelped, onlyHelper, plantCodes, companionPlantCodes }) => {
    return (plantList) => {
      if (!enabled) {
        return plantList;
      }

      return plantList.filter((candidate) => {
        const matchesHelped = companionPlantCodes.has(candidate.code);
        const matchesHelper = candidate.companionPlantCodes.some((candidateCompanion) => plantCodes.has(candidateCompanion));
        if (onlyHelped && onlyHelper) {
          return matchesHelped && matchesHelper; // mutuals only
        }
        return (
          (!onlyHelped && !onlyHelper && (matchesHelped || matchesHelper)) || // simply enabled, any match
          (onlyHelped && matchesHelped) || // only helped
          (onlyHelper && matchesHelper)
        ); // only helpers
      });
    };
  },
};

export default companionFilter;
