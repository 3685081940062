import ItemTypes from './types/item-types';
import GardenObjectType from './types/garden-object-types';
import GardenObjectSubtypes from './types/garden-object-subtypes';
import PlantTypes, { PlantType } from './types/plant-types';
import PlantTags from './types/plant-tags';
import ShapeType from './types/shape-type';
import LayerDisplayModes from './types/layer-display-modes';
import LayerTypes from './types/layer-types';
import type { LayerType } from './types/layer-types';
import UnitTypes, { UnitType } from './types/unit-types';
import CropRotationModes from './types/crop-rotation-modes';
import CropRotationViewTypes from './types/crop-rotation-view-types';
import { ModifierType, ModifierNames } from './types/modifier-type';
import PlantingCalendarRows from './types/planting-calendar-rows';
import ItemNodeType from './types/item-node-types';
import { RepeatingGraphicDisplayModes, RepeatingGraphicDisplayModesType } from './types/plant-display-modes';
import SelectableNodeGroups from './types/selectable-node-groups';
import LoadingState, { isLoadingStateSuccess } from './types/loading-types';
import { PlannerTabTypes } from './types/planner-tab-types';
import { PlannerControlsTab } from './types/planner-controls-tab-types';

import { RenderMode, RenderModeNames } from './types/render-modes';

import GardenTypes from './types/garden-types';
import SoilTypes from './types/soil-types';
import SunTypes from './types/sun-types';
import LayoutTypes from './types/layout-types';

import AppTypes from './types/app-types';
import DeviceDisplayMode from './types/device-display-modes';
import OrientationTypes from './types/orientation-types';
import Side from './types/side-type';
import Direction from './types/direction-type';

export { WheelModes } from './types/wheel-modes';
export type { WheelModesType } from './types/wheel-modes';

export { GardenItemType } from './types/garden-item-type';
export { InteractionStateType } from './types/interaction-types';

export { Anchor, AnchorValues, anchorAsVector } from './types/anchor-type';

export const PERENNIAL = 'PERENNIAL';
export const SEASON_EXTENDERS = 'SEASON_EXTENDERS';

export const IS_WEBKIT = navigator.vendor === 'Apple Computer, Inc.';

export const IS_IOS = (() => {
  const iosQuirkPresent = () => {
    const audio = new Audio();

    audio.volume = 0.5;
    return audio.volume === 1; // volume cannot be changed from "1" on iOS 12 and below
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAppleDevice = navigator.userAgent.includes('Macintosh');
  const isTouchScreen = navigator.maxTouchPoints >= 1; // true for iOS 13 (and hopefully beyond)

  return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
})();

// https://stackoverflow.com/a/31732310
export const IS_SAFARI =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const IS_MAC = navigator.platform.toLowerCase().indexOf('mac') >= 0;

/**
 * A rough check to see if a browser supports blob arrayBuffers.
 * Older iOS devices do not. This is needed for plan printing.
 */
export const SUPPORTS_ARRAY_BUFFER = (() => {
  try {
    return Blob !== undefined && Blob.prototype !== undefined && Blob.prototype.arrayBuffer !== undefined && typeof Blob.prototype.arrayBuffer === 'function';
  } catch (e) {
    return false;
  }
})();

/**
 * https://stackoverflow.com/a/4819886
 * Typescript doesn't like navigator.msMaxTouchPoints but we still likely have to use it
 *
 * @returns {boolean}
 */
export const IS_TOUCH_DEVICE =
  'ontouchstart' in window ||
  navigator.maxTouchPoints > 0 ||
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  navigator.msMaxTouchPoints > 0;

// Keyword for device-pixel-ratio setting of 'automatic', i.e. use window.devicePixelRatio
export const AUTOMATIC_DPR = 'AUTO';
// Max and min custom device-pixel-ratios
export const MAX_DPR = 4;
export const MIN_DPR = 0.5;

// Minimum and maximum custom cursor scale.
export const MIN_CURSOR_SCALE = 1;
export const MAX_CURSOR_SCALE = 3;

// 1 foot = 30.48cm
export const CM_IN_FOOT = 30.48;
export const INCHES_IN_FOOT = 12;

export const PDFImageTypes = {
  JPG: 'JPG',
  PNG: 'PNG',
};

const METRIC_SNAP_DISTANCE = 10;
const IMPERIAL_SNAP_DISTANCE = 15.24; // 6 Inches

// Max int used in the FGP
const FGP_MAX = 32767;

// Default user variety key
const DEFAULT_VARIETY = '-';

// Min/Max plan sizes
const MAX_PLAN_CM_WHEN_METRIC = 30000;
const MIN_PLAN_CM_WHEN_METRIC = 100;
const MIN_PLAN_CM_WHEN_IMPERIAL = 99; // 3'3 (=99.06cm)
const MAX_PLAN_CM_WHEN_IMPERIAL = 30480; // = 1000'

// Document version which forces an overwrite
const OVERWRITE_DOCUMENT_VERSION = 'e69992785b3514e5203857180b063c7f';

const MAX_PLANT_SPRITE_DISPLAY_LIMIT = 10000;
const MIN_PLANT_SPRITE_DISPLAY_LIMIT = 4;
const DEFAULT_PLANT_SPRITE_DISPLAY_LIMIT = 100;
const DEFAULT_PLANT_DISPLAY_MODE = RepeatingGraphicDisplayModes.BLOCK;

const MAX_LOAD_PLAN_RETRIES = 3;

// Maximum stroke wiudth for a shape
export const MAX_SHAPE_STROKE_WIDTH = 64;

// export enum SORT_ORDERS {
//   Ascending = 'Ascending',
//   Descending = 'Descending',
// }

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const SOUTHERN_HEMISPHERE_MONTHS = [
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
];

export {
  METRIC_SNAP_DISTANCE,
  IMPERIAL_SNAP_DISTANCE,
  FGP_MAX,
  DEFAULT_VARIETY,
  MAX_PLAN_CM_WHEN_METRIC,
  MIN_PLAN_CM_WHEN_METRIC,
  MIN_PLAN_CM_WHEN_IMPERIAL,
  MAX_PLAN_CM_WHEN_IMPERIAL,
  OVERWRITE_DOCUMENT_VERSION,
  MAX_PLANT_SPRITE_DISPLAY_LIMIT,
  MIN_PLANT_SPRITE_DISPLAY_LIMIT,
  DEFAULT_PLANT_SPRITE_DISPLAY_LIMIT,
  DEFAULT_PLANT_DISPLAY_MODE,
  MAX_LOAD_PLAN_RETRIES,
  ItemTypes,
  GardenObjectType,
  GardenObjectSubtypes,
  PlantTypes,
  PlantTags,
  LayerTypes,
  LayerDisplayModes,
  UnitTypes,
  CropRotationModes,
  CropRotationViewTypes,
  ModifierType,
  ModifierNames,
  PlantingCalendarRows,
  ItemNodeType,
  RepeatingGraphicDisplayModes,
  SelectableNodeGroups,
  GardenTypes,
  SoilTypes,
  SunTypes,
  LayoutTypes,
  AppTypes,
  RenderMode,
  RenderModeNames,
  LoadingState,
  isLoadingStateSuccess,
  DeviceDisplayMode,
  OrientationTypes,
  PlannerTabTypes,
  ShapeType,
  Side,
  Direction,
  PlannerControlsTab,
};

export type { UnitType, RepeatingGraphicDisplayModesType, PlantType, LayerType };
