import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification } from './notification';

export type NotificationStoreState = {
  notificationIDs: number[];
  notificationsByID: Record<number, Notification>;
};

interface UpdateNotificationPayload {
  notificationID: number;
  update: Partial<Notification>;
}

const initialState: NotificationStoreState = {
  notificationIDs: [],
  notificationsByID: {},
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateNotificationById: (state, action: PayloadAction<UpdateNotificationPayload>) => {
      const notification = { ...state.notificationsByID[action.payload.notificationID] };

      if (!notification) {
        console.warn('Attempted to update notification type on notification not present in reducer');
        return state;
      }

      const { update } = action.payload;

      const updatedNotification = {
        ...notification,
        ...update,
      };

      if (update.canTimeout !== undefined) {
        if (update.canTimeout) {
          updatedNotification.isTimingOut = true;
          updatedNotification.timeoutTime = Date.now() + updatedNotification.visibleDuration;
        } else {
          updatedNotification.isTimingOut = false;
          updatedNotification.timeoutTime = null;
        }
      }

      return {
        ...state,
        notificationsByID: {
          ...state.notificationsByID,
          [action.payload.notificationID]: { ...updatedNotification },
        },
      };
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      return {
        ...state,
        notificationIDs: [...state.notificationIDs.filter((id) => id !== action.payload.ID), action.payload.ID],
        notificationsByID: {
          ...state.notificationsByID,
          [action.payload.ID]: action.payload,
        },
      };
    },
    updateNotification: (state, action: PayloadAction<Notification>) => {
      return {
        ...state,
        notificationsByID: {
          ...state.notificationsByID,
          [action.payload.ID]: action.payload,
        },
      };
    },
    removeNotification: (state, action: PayloadAction<number>) => {
      const index = state.notificationIDs.indexOf(action.payload);

      if (index === -1) {
        console.warn(`No notification with ID ${index} found to remove.`);
        return state;
      }

      const notificationsByID = { ...state.notificationsByID };
      delete notificationsByID[action.payload];

      return {
        ...state,
        notificationIDs: [...state.notificationIDs.filter((id) => id !== action.payload)],
        notificationsByID,
      };
    },
  },
});

export interface NotificationStoreRootState {
  notifications: NotificationStoreState;
}

export const notificationReducer = notificationSlice.reducer;
export const notificationActions = notificationSlice.actions;
