import { networkErrorReporter, networkService } from '@gi/gi-network';
import { remindersFromAPI, reminderFromAPI, reminderToAPI, ReminderAPILoadObject } from './reminder-parser';
import {
  attachGetRemindersErrorClientMessage,
  attachSaveReminderErrorClientMessage,
  attachCreateReminderErrorClientMessage,
  attachDeleteReminderErrorClientMessage,
} from './reminder-service-errors';
import { Reminder, ReminderData } from '../reminder';

/**
 * Network service class for reminders
 *
 * @class ReminderService
 */
class ReminderService {
  endpoint: string;

  /**
   * Creates an instance of ReminderService.
   *
   * @param {object} options
   * @property {string} endpoint
   * @memberof ReminderService
   */
  constructor(endpoint: string) {
    this.endpoint = endpoint;
    console.debug(`Created reminder service with endpoint ${this.endpoint}`);
  }

  /**
   * Executes a network request to create a reminder and returns a promise which resolves with the created reminder
   * if successful and rejects with an error if it fails.
   *
   * @param {number} userID
   * @param {string} postAuthTicket
   * @param {Reminder} reminder
   * @returns {Promise}
   * @memberof ReminderService
   */
  createReminder(userID: number, reminder: ReminderData): Promise<Reminder> {
    const path = `/journal/${userID}/journalEntries`;
    const params = {
      date: reminder.dateYMD,
    };

    const url = `${this.endpoint}${path}`;

    return networkService
      .post<ReminderAPILoadObject>(url, params, reminderToAPI(reminder))
      .then((response) => {
        const responseReminder = response.body;
        // API doesn't return dateYMD so we have to add it back in.
        responseReminder.dateYMD = reminder.dateYMD;
        return reminderFromAPI(responseReminder);
      })
      .catch(networkErrorReporter('POST', 'reminder'))
      .catch((requestError) => {
        attachCreateReminderErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Executes a network request to delete an existing reminder. Returns a promise if successful,
   * else rejects with an error on failure.
   *
   * @param {number} userID
   * @param {string} postAuthTicket
   * @param {Reminder} reminder
   * @return {Promise}
   */

  deleteReminder(userID: number, reminder: Reminder): Promise<unknown> {
    const path = `/journal/${userID}/journalEntries/${reminder.ID}`;
    const url = `${this.endpoint}${path}`;

    return networkService
      .delete(url)
      .catch((requestError) => {
        if (requestError.statusCode !== 400) {
          // The journal DELETE service issues a 400 on success.
          // So don't throw if it's a 400.
          attachDeleteReminderErrorClientMessage(requestError);
          throw requestError;
        }
      })
      .catch(networkErrorReporter('DELETE', 'reminder'));
  }

  /**
   * Executes a network request to update an existing reminder. Returns a promise which resolves to the returned reminder
   * if successful, else rejects with an error on failure.
   *
   * @param {number} userID
   * @param {string} postAuthTicket
   * @param {Reminder} reminder
   * @return {Promise}
   */
  saveReminder(userID: number, reminder: Reminder): Promise<Reminder> {
    const path = `/journal/${userID}/journalEntries/${reminder.ID}`;
    const url = `${this.endpoint}${path}`;

    return networkService
      .put<ReminderAPILoadObject>(url, {}, reminderToAPI(reminder))
      .then((response) => {
        const responseReminder = response.body;
        // API doesn't return dateYMD so we have to add it back in.
        responseReminder.dateYMD = reminder.dateYMD;
        return reminderFromAPI(responseReminder);
      })
      .catch(networkErrorReporter('PUT', 'reminder'))
      .catch((requestError) => {
        attachSaveReminderErrorClientMessage(requestError);
        throw requestError;
      });
  }

  /**
   * Executes a network request to fetch reminders for the provided user through the API. Returns a promise which resolves
   * with an instance of Reminders on success or rejects with an error.
   *
   * @param {number} userID
   * @param {string} postAuthTicket
   * @param {Date} startDate
   * @param {Date} endDate
   * @return {Promise}
   */
  getReminders(userID: number, startDate: Date, endDate: Date): Promise<Reminder[]> {
    const params = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };

    const url = `${this.endpoint}/journal/${userID}`;
    return networkService
      .get(url, params)
      .then((response) => remindersFromAPI(response.body))
      .catch(networkErrorReporter('GET', 'reminders'))
      .catch((requestError) => {
        attachGetRemindersErrorClientMessage(requestError);
        throw requestError;
      });
  }
}

export default ReminderService;
