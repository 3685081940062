// export const PRE = 'PRE';
// export const IN_PROGRESS = 'IN_PROGRESS';
// export const FAIL = 'FAIL';
// export const COMPLETE = 'COMPLETE';

export enum RequestStatus {
  PRE = 'PRE',
  IN_PROGRESS = 'IN_PROGRESS',
  FAIL = 'FAIL',
  COMPLETE = 'COMPLETE',
}
