import React, { useContext } from 'react';
import ReactSelect, { SingleValue } from 'react-select';

import FormField, { InputContainer } from '@gi/form-responsive';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import { TableViewMode } from '../../types/plant-list-types';

type Option = {
  value: TableViewMode;
  label: string;
};

const displayModeOptions = [
  { value: TableViewMode.AUTO, label: 'Automatic' },
  { value: TableViewMode.TABLE, label: 'Always as Table' },
  { value: TableViewMode.GRID, label: 'Always as List' },
];

// Typescript forces us to declare the initial object in the correct state but we still reduce over the displayModeOptions to make sure it's correct
const displayModeOptionsByValue: { [key in TableViewMode]: Option } = displayModeOptions.reduce(
  (prev, curr) => {
    prev[curr.value] = curr;
    return prev;
  },
  {
    [TableViewMode.AUTO]: displayModeOptions[0],
    [TableViewMode.TABLE]: displayModeOptions[1],
    [TableViewMode.GRID]: displayModeOptions[2],
  }
);

const ViewModeOption = () => {
  const { options, setOptions } = useContext(GlobalPlantListContext);
  const value = displayModeOptionsByValue[options.viewMode];

  const onSelect = (newDisplayMode: SingleValue<Option>) => {
    if (newDisplayMode) {
      setOptions({ viewMode: newDisplayMode.value });
    }
  };

  return (
    <FormField label='View Mode' htmlFor='plant-filters:view-mode' desktopLayout={{ labelSize: 120 }}>
      <InputContainer size='full'>
        <ReactSelect
          className='select-input'
          styles={DEFAULT_SELECT_STYLES}
          options={displayModeOptions}
          value={value}
          onChange={onSelect}
          isSearchable={false}
          menuPortalTarget={document.body}
          menuPlacement='auto'
          inputId='plant-filters:view-mode'
        />
      </InputContainer>
    </FormField>
  );
};

export default ViewModeOption;
