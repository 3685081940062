import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AutoSizer, Grid, Index, GridCellRenderer, ScrollParams } from 'react-virtualized';

import { LoadingState } from '@gi/constants';

import SmallPreviewItem from '../items/small-preview-item';
import SmallPreviewLoading from '../items/small-preview-loading';
import { LazyImageProvider } from '../../lazy-image/lazy-image-context';
import { DirectoryPageSectionContext } from '../../directory-page/directory-page-section-provider';
import { smoothScrollTo } from './utils';

import styles from './virtualized-small-preview-row-content.module.css';

/** The amount to round horizontal scrolls by (0-1) to account for imprecision and browser pixel rounding. */
const IMPRECISION_CORRECTION = 0.05;

/** Amount of time for the left/right scroll animation to take */
const SCROLL_ANIMATION_TIME = 500;

enum ColumnType {
  /** Start and end padding of the row */
  PADDING = 'PADDING',
  /** Gap between items of the row */
  GAP = 'GAP',
  /** Items in the row */
  ITEM = 'ITEM',
}

interface iProps {
  /** Minimum height of an item on-screen. Prevents scaling down items too much */
  minItemHeight?: number;
  /** The aspect ratio of the image content of the items to display (w/h) */
  itemAspectRatio?: number;
  /** The height of the text under the image content (in px) */
  itemTextHeight?: number;
  /** Gap between items in the scroller */
  gap?: number;
  /** Padding on the left/right of the first and last items */
  padding?: number;
}

const PaginatedSmallPreviewRow = ({ minItemHeight = 150, itemAspectRatio = 16 / 9, itemTextHeight = 52, gap = 18, padding = 12 }: iProps): JSX.Element => {
  const gridRef = useRef<Grid>(null);
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(window.innerWidth);
  const [currentItem, setCurrentItem] = useState<number>(0);

  const { hasNext, loadNext, setPageSize, setCurrentItemIndex, allItems } = useContext(DirectoryPageSectionContext);

  useEffect(() => {
    const callback = () => {
      setContainerWidth(ref?.clientWidth ?? window.innerWidth);
    };
    callback();
    if (ref) {
      window.addEventListener('resize', callback);

      return () => {
        window.removeEventListener('resize', callback);
      };
    }
    return () => {};
  }, [ref]);

  /** Calculate the total usable visible width for the scroll area, so we can calc how many items we can display */
  const usableWidth = useMemo(() => {
    return containerWidth - padding * 2;
  }, [padding, containerWidth]);

  const maxVisibleItems = useMemo(() => {
    // TODO: Gap isn't accounted for in this >:/
    const count = Math.max(Math.floor(usableWidth / (minItemHeight * itemAspectRatio)), 1);
    if (count === 1) {
      return 1.2;
    }
    return count;
  }, [minItemHeight, itemAspectRatio, usableWidth]);

  /** Tell the context how many items we're displaying */
  useEffect(() => {
    setPageSize(maxVisibleItems);
  }, [maxVisibleItems]);

  /** Tell the context the index of the left-most visible item so it can lookAhead if needed */
  useEffect(() => {
    setCurrentItemIndex(currentItem);
  }, [currentItem]);

  /** Calculate the width of each individual item based on usable width */
  const itemWidth = useMemo(() => {
    return (usableWidth - (maxVisibleItems - 1) * gap) / maxVisibleItems;
  }, [maxVisibleItems, usableWidth, gap]);

  /** On scroll, handle working out what item we're now looking at */
  const onScroll = useCallback(
    ({ scrollLeft }: ScrollParams) => {
      let probableTarget = scrollLeft / (itemWidth + gap);
      // Round if close to whole number to avoid imprecision errors.
      if (probableTarget % 1 < IMPRECISION_CORRECTION || probableTarget % 1 > 1 - IMPRECISION_CORRECTION) {
        probableTarget = Math.round(probableTarget);
      }
      setCurrentItem(probableTarget);
    },
    [itemWidth, gap, padding]
  );

  /** Should the previous arrow button (◄) be shown */
  const showPreviousButton = useMemo(() => {
    return currentItem > IMPRECISION_CORRECTION;
  }, [currentItem]);

  /** Should the next arrow button (►) be shown */
  const showNextButton = useMemo(() => {
    return currentItem < allItems.length - maxVisibleItems - IMPRECISION_CORRECTION || hasNext;
  }, [currentItem, allItems, maxVisibleItems, hasNext]);

  /** Scrolls to the next set of items, and loads the next set as a fallback if lookAhead = 0 */
  const next = useCallback(() => {
    const nextItemNumber = Math.min(Math.floor(currentItem + maxVisibleItems), !hasNext ? allItems.length - maxVisibleItems : Infinity);
    if (nextItemNumber > allItems.length - maxVisibleItems) {
      if (hasNext) {
        loadNext();
        if (gridRef.current) {
          smoothScrollTo(nextItemNumber * (itemWidth + gap), gridRef.current, SCROLL_ANIMATION_TIME);
        }
      }
    } else if (gridRef.current) {
      smoothScrollTo(nextItemNumber * (itemWidth + gap), gridRef.current, SCROLL_ANIMATION_TIME);
    }
  }, [currentItem, maxVisibleItems, itemWidth, gap, padding, allItems, hasNext, loadNext]);

  /** Scrolls to the previous set of items */
  const previous = useCallback(() => {
    const previousItemNumber = Math.max(Math.ceil(currentItem - maxVisibleItems), 0);
    if (gridRef.current) {
      smoothScrollTo(previousItemNumber * (itemWidth + gap), gridRef.current, SCROLL_ANIMATION_TIME);
    }
  }, [currentItem, maxVisibleItems, itemWidth, gap, padding]);

  const cssVariables = useMemo<CSSProperties>(() => {
    return {
      '--items': allItems.length,
      '--gap': `${gap}px`,
      '--padding': `${padding}px`,
      '--item-width': `${itemWidth}px`,
      '--item-text-height': `${itemTextHeight}px`,
      '--aspect-ratio': `${itemAspectRatio}`,
    } as CSSProperties;
  }, [allItems, padding, gap, itemWidth, itemTextHeight, itemAspectRatio]);

  /**
   * Column count for react virtualised
   * Every item needs some form of padding before and after it, hence 2n+1 columns to display
   */
  const columnCount = useMemo(() => {
    return 2 * allItems.length + 1;
  }, [allItems]);

  /**
   * Returns the column type for react virtualised based on id
   * Evens are always items, odds are gaps unless they're the first/last, then they're padding
   */
  const getColumnType = useCallback(
    (index: number) => {
      if (index === 0 || index === columnCount - 1) {
        return ColumnType.PADDING;
      }
      return index % 2 === 0 ? ColumnType.GAP : ColumnType.ITEM;
    },
    [columnCount]
  );

  /** Returns the calculated width of a column based on its type */
  const getColumnWidth = useCallback(
    ({ index }: Index): number => {
      const columnType = getColumnType(index);
      switch (columnType) {
        case ColumnType.PADDING:
          return padding;
        case ColumnType.ITEM:
          return itemWidth;
        case ColumnType.GAP:
          return gap;
        default:
          throw new Error('Unknown column type');
      }
    },
    [getColumnType, gap, itemWidth, padding]
  );

  /** Whenever how the column width is calculated changes, we have to tell react virtualised */
  useEffect(() => {
    gridRef.current?.recomputeGridSize();
  }, [padding, itemWidth, gap]);

  const cellRenderer = useCallback<GridCellRenderer>(
    ({ columnIndex, key, style }) => {
      const columnType = getColumnType(columnIndex);
      switch (columnType) {
        case ColumnType.PADDING:
          return <span className={styles.padder} key={key} style={style} />;
        case ColumnType.GAP:
          return <span className={styles.gap} key={key} style={style} />;
        case ColumnType.ITEM: {
          const item = allItems[(columnIndex - 1) / 2];
          let content = <p>Empty</p>;
          if (item) {
            switch (item.status) {
              case LoadingState.SUCCESS:
                content = <SmallPreviewItem item={item.value} />;
                break;
              case LoadingState.NONE:
              case LoadingState.LOADING:
              case LoadingState.ERROR: /** TODO: Error case */
              default:
                content = <SmallPreviewLoading />;
            }
          }
          return (
            <div className={styles.item} key={key} style={style}>
              {content}
            </div>
          );
        }
        default:
          return null;
      }
    },
    [allItems]
  );

  return (
    <LazyImageProvider containerRef={scrollRef}>
      <div className={styles.previewRowContainer} style={cssVariables} ref={setRef}>
        <button className={`${styles.cycleButton} ${styles.previous}`} type='button' disabled={!showPreviousButton} onClick={previous}>
          <i className='icon-left-dir' />
        </button>
        <div className={styles.previewRow} ref={setScrollRef}>
          <AutoSizer>
            {({ width, height }) => (
              <div>
                <Grid
                  className={styles.virtualizedScroll}
                  ref={gridRef}
                  width={width}
                  height={height}
                  rowCount={1}
                  rowHeight={height}
                  columnCount={columnCount}
                  columnWidth={getColumnWidth}
                  overscanColumnCount={2} /* Over-render by 2 to always render the next item */
                  cellRenderer={cellRenderer}
                  onScroll={onScroll}
                />
              </div>
            )}
          </AutoSizer>
        </div>
        <button className={`${styles.cycleButton} ${styles.next}`} type='button' disabled={!showNextButton} onClick={next}>
          <i className='icon-right-dir' />
        </button>
      </div>
    </LazyImageProvider>
  );
};

export default PaginatedSmallPreviewRow;
