import React from 'react';
import { NavLink } from 'react-router-dom';
import { directoryPageRoutes } from '@gi/app-guru-common';
import { GuruDirectoryPageId } from '@gi/app-guru-types';

import SupportBanner from '../../components/feedback/support-banner';
import DefaultHeader from '../../header/header-components/default-header';
import PageContainer from '../page-container';
import GuruHeaderContentPortal from '../../header/guru-header-content-portal';
import { DEFAULT_GURU_HEADER } from '../../context/garden-guru-provider';

import styles from './error-page.module.css';

const Error404Page = (): JSX.Element => {
  return (
    <PageContainer className={styles.errorPage}>
      <GuruHeaderContentPortal background={DEFAULT_GURU_HEADER.background}>
        <DefaultHeader>Error - 404</DefaultHeader>
      </GuruHeaderContentPortal>
      <div className='guru-narrow-content-width'>
        <h2>Unable to find content</h2>
        <div className={styles.returnHomeLink}>
          <NavLink to={directoryPageRoutes[GuruDirectoryPageId.RootDirectory].route} className=''>
            Return to Garden Guru Home
          </NavLink>
        </div>
        <div className={styles.supportBannerContainer}>
          <SupportBanner heading='Let us know' text='Contact support, we can help you find what you need' buttonText='Contact Support' />
        </div>
      </div>
    </PageContainer>
  );
};

export default Error404Page;
