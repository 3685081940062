import React from 'react';

interface iProps {
  active: boolean;
}

const GardenGuruIcon = ({ active }: iProps) => {
  return <i className={`icon-guru sidebar-icon ${active ? 'sidebar-icon-active' : ''}`} />;
};

export default GardenGuruIcon;
