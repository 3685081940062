import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { UserUtils } from '@gi/user';
import { SessionSelectors } from '@gi/react-session';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { NotesEditor, NotesPrintView, usePlanNotes } from '@gi/notes';

import PlannerContentContainer from './planner-content-container';
import { PlannerCommandContext } from '../planner-command-provider';

import styles from './planner-content.module.css';

interface iProps {
  children: React.ReactNode;
}

const NotesContent = ({ children }: iProps): JSX.Element | null => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const user = useSelector(SessionSelectors.getUser);

  const { notesRef } = useContext(PlannerCommandContext);
  const { notes, setNotes, syncNotes } = usePlanNotes(activePlan);

  if (!activePlan || !user) {
    return null;
  } // Should never be hit

  const isNorthernHemisphere = UserUtils.isNorthernHemisphere(user);

  return (
    <PlannerContentContainer className={styles.notesContainer}>
      <div className={styles.contentScrollPane}>
        <div className={styles.plannerNotes}>
          <p>Use this space to store notes for your plan</p>
          <NotesEditor value={notes} onChange={setNotes} className={styles.plannerNotesEditor} onSyncRequest={syncNotes} />
        </div>
        <NotesPrintView ref={notesRef as React.MutableRefObject<HTMLDivElement>} plan={activePlan} isNorthernHemisphere={isNorthernHemisphere} notes={notes} />
      </div>
      {children}
    </PlannerContentContainer>
  );
};

export default NotesContent;
