import { DEFAULT_VARIETY } from '@gi/constants';
import { CatalogueVariety } from '../catalogue-variety';

/**
 * Parses a catalogue variety from an api json object and returns a new CatalogueVariety
 *
 * @param {object} catalogueVariety
 * @returns {CatalogueVariety}
 */
function catalogueVarietyFromAPI(catalogueVariety): CatalogueVariety {
  let name = DEFAULT_VARIETY;

  if (catalogueVariety.varietyName !== null) {
    name = catalogueVariety.varietyName;
  }

  return {
    name,
    plantCode: catalogueVariety.plantCode,
    supplierID: catalogueVariety.supplierID,
  };
}

export default catalogueVarietyFromAPI;
