import React from 'react';
import Plant from '@gi/plant';

import HeaderSubtitle from '../../header/header-components/header-subtitle';

import styles from './plant-content-header.module.css';

interface iProps {
  plant: Plant;
}

const PlantContentHeaderSubtitle = ({ plant }: iProps): JSX.Element | null => {
  if (plant.scientificName === '') {
    return null;
  }

  if (plant.otherScientificNames === '') {
    return (
      <HeaderSubtitle>
        <div className={styles.scientificName}>{plant.scientificName}</div>
      </HeaderSubtitle>
    );
  }

  return (
    <HeaderSubtitle className={styles.subtitle}>
      <div className={styles.scientificName}>{plant.scientificName}</div>
      <div className={styles.scientificOtherNames}>{plant.otherScientificNames}</div>
    </HeaderSubtitle>
  );
};

export default PlantContentHeaderSubtitle;
