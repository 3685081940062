export class StateLogger {
  static disabled: boolean = true;

  private static depth = 0;

  private static runWithPrefix(func: (...data: any[]) => void, ...[first, ...remaining]: any[]) {
    const opacity = Math.max(0.25, 1 - 0.2 * this.depth);
    if (first && typeof first === 'string' && first.length <= 2) {
      func(
        `%c State V5 %c %c ${first} `,
        `background: linear-gradient(90deg, rgba(131,58,180,${opacity}) 0%, rgba(253,29,29,${opacity}) 50%, rgba(252,176,69,${opacity}) 100%); border-radius: 3px;`,
        'background: none;',
        'background: #333; border-radius: 3px; border: 1px solid #555;',
        ...remaining
      );
    } else {
      func(
        '%c State V5 ',
        `background: linear-gradient(90deg, rgba(131,58,180,${opacity}) 0%, rgba(253,29,29,${opacity}) 50%, rgba(252,176,69,${opacity}) 100%); border-radius: 3px;`,
        first,
        ...remaining
      );
    }
  }

  static log(...data: any[]) {
    if (this.disabled) {
      return;
    }
    this.runWithPrefix(console.debug, ...data);
  }

  static group(...data: any[]) {
    if (this.disabled) {
      return;
    }
    this.runWithPrefix(console.group, ...data);
    this.depth++;
  }

  static groupEnd() {
    if (this.disabled) {
      return;
    }
    console.groupEnd();
    this.depth--;
  }
}
