import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { CanvasSelectors } from '@gi/react-garden-canvas';
import { ResourceContext } from '@gi/resource-provider';
import { getDistanceUnitsFromIsMetric } from '@gi/units';

import LocalPlantListContext from '../local-provider/local-plant-list-context';
import VarietyTableRow from './variety-table-row';

const VarietyTableRows = (): JSX.Element | null => {
  const { filteredVarietyGroupData } = useContext(LocalPlantListContext);
  const { userDistanceUnits } = useContext(ResourceContext);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const distanceUnits = activePlan ? getDistanceUnitsFromIsMetric(activePlan.plannerSettings.metric) : userDistanceUnits;

  return (
    <>
      {filteredVarietyGroupData.map((varietyGroup, index) => (
        <VarietyTableRow
          key={`${varietyGroup.plant.code}_${varietyGroup.variety}_${varietyGroup.modifier === null ? '' : varietyGroup.modifier}`}
          varietyGroup={varietyGroup}
          plant={varietyGroup.plant}
          evenRow={index % 2 === 0}
          distanceUnits={distanceUnits}
        />
      ))}
    </>
  );
};

export default VarietyTableRows;
