import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Reminder, Reminders } from './reminder';
import * as ReminderUtils from './reminder-utils';

export type ReminderState = {
  reminders: Reminders;
  editingReminder: null | Reminder;
  addingReminder: boolean;
};

const DEFAULT_STATE: ReminderState = {
  reminders: ReminderUtils.createInitialReminders(),
  editingReminder: null,
  addingReminder: false,
};

export const reminderSlice = createSlice({
  name: 'reminders',
  initialState: DEFAULT_STATE,
  reducers: {
    addReminder: (state: ReminderState, action: PayloadAction<Reminder>) => {
      state.reminders = ReminderUtils.addReminders(state.reminders, [action.payload]);
    },
    removeReminder: (state: ReminderState, action: PayloadAction<number>) => {
      state.reminders = ReminderUtils.removeReminder(state.reminders, action.payload);
    },
    updateReminder: (state: ReminderState, action: PayloadAction<Reminder>) => {
      state.reminders = ReminderUtils.updateReminder(state.reminders, action.payload);
    },
    setReminders: (state: ReminderState, action: PayloadAction<Reminders>) => {
      state.reminders = action.payload;
    },
    openEditReminder: (state: ReminderState, action: PayloadAction<Reminder>) => {
      state.editingReminder = action.payload;
    },
    closeEditReminder: (state: ReminderState) => {
      state.editingReminder = null;
    },
    openAddReminder: (state: ReminderState) => {
      state.addingReminder = true;
    },
    closeAddReminder: (state: ReminderState) => {
      state.addingReminder = false;
    },
  },
});

export const reminderReducer = reminderSlice.reducer;
export const ReminderActionCreators = reminderSlice.actions;

interface RootState {
  reminders: ReminderState;
}

export const ReminderSelectors = {
  getReminderState: (state: RootState) => state.reminders,
  getReminders: (state: RootState) => state.reminders.reminders,
};
