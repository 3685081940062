// The max time cloned images have to load. Just in case an event is dropped somewhere.
const IMAGE_LOAD_TIMEOUT = 2000; // ms

/**
 * Prints the given element by cloning the element and hiding the rest of the page content.
 * @param element The element to print
 * @returns A clean-up function. Call this once the user has finished printing.
 */
export async function printElement(element: HTMLElement) {
  const printContainer = document.getElementById('hidden-frame-container');
  if (!printContainer) {
    throw new Error('Failed to find container for printing');
  }

  // Clear out the print area of anything left over
  Array.from(printContainer.children).forEach((child) => {
    child.remove();
  });

  // Copy the element over to the print area
  const elementCopy = element.cloneNode(true) as HTMLElement;
  await Promise.any([
    /* Wait for all images to load. All images should already be available,
     * but on some browsers they load async and take a few frames to appear.
     */
    Promise.all(
      Array.from(elementCopy.getElementsByTagName('img')).map((image) => {
        return new Promise<void>((resolve) => {
          const complete = () => {
            resolve();
          };
          image.onload = complete;
          image.onerror = complete;
        });
      })
    ),
    // Fallback, resolve after a timeout in case we miss an image load somehow.
    new Promise((resolve) => {
      window.setTimeout(resolve, IMAGE_LOAD_TIMEOUT);
    }),
  ]);
  printContainer.appendChild(elementCopy);

  // Attempt to print
  document.body.classList.add('printing');
  window.print();
  /* In the ideal world, we would clean up here, as `window.print()` is meant to be blocking.
   * However, on iOS and mobiles, it isn't. We also can't use the `afterprint` event, as that is fired after
   * the print preview is generated, not when the print dialogue is closed.
   */

  // Clean-up function
  return () => {
    elementCopy.remove();
    document.body.classList.remove('printing');
  };
}

// function copyStyle(destDocument: Document, element: HTMLElement) {
//   return new Promise((resolve, reject) => {
//     const node = destDocument.importNode(element, true);
//     node.onload = () => resolve(node);
//     node.onerror = () => reject(node);
//     destDocument.getElementsByTagName('head')[0].appendChild(node);
//   });
// }

// function copyStyles(inputDoc: Document, outputDoc: Document) {
//   // Get all style elements and copy them to the new document
//   const stylesheets = Array.from(inputDoc.querySelectorAll<HTMLStyleElement>('style, link[rel="stylesheet"]'));
//   return Promise.allSettled(stylesheets.map((stylesheet) => copyStyle(outputDoc, stylesheet)));
// }

// export function createPrintElement(element: HTMLElement, printTimeoutTime: number = 3500) {
//   return new Promise((resolve, reject) => {

//     const frame = document.createElement('iframe');
//     const frameContainer = document.getElementById('hidden-frame-container');

//     if (!frameContainer) {
//       reject(new Error('Failed to find frame container to attach to'));
//       return;
//     }

//     const tearDown = () => {
//       frame.remove();
//     };

//     frame.onload = async () => {
//       const destDocument = frame.contentDocument;

//       if (!destDocument) {
//         reject(new Error('Failed to get content document of iFrame'));
//         return;
//       }

//       await copyStyles(document, destDocument);

//       const newNode = destDocument.importNode(element, true);
//       destDocument.body.appendChild(newNode);

//       setTimeout(() => {
//         resolve({ tearDown, frame });
//       }, printTimeoutTime);
//     };

//     frameContainer.appendChild(frame);
//   });
// }

// export function printElement(element, printTimeoutTime = 3500) {
//   return createPrintElement(element, printTimeoutTime).then(({ frame, tearDown }) => {
//     requestAnimationFrame(() => {
//       frame.contentWindow.print();
//       setTimeout(tearDown, 2000);
//     });
//   });
// }
