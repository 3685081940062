import React from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';
import { PlantTags, PERENNIAL } from '@gi/constants';

const SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    minWidth: '150px',
  }),
};

const options = [
  { value: null, label: 'All Plants' },
  { value: PlantTags.VEGETABLE, label: 'Vegetables' },
  { value: PlantTags.FRUIT, label: 'Fruits & Nuts' },
  { value: PlantTags.HERB, label: 'Herbs' },
  { value: PlantTags.FLOWER, label: 'Flowers' },
  { value: PlantTags.GREEN_MANURE, label: 'Green Manures' },
  { value: PERENNIAL, label: 'Perennials' },
];

const getOptionFromValue = (val: string | null) => {
  switch (val) {
    case null:
      return options[0];
    case PlantTags.VEGETABLE:
      return options[1];
    case PlantTags.FRUIT:
      return options[2];
    case PlantTags.HERB:
      return options[3];
    case PlantTags.FLOWER:
      return options[4];
    case PlantTags.GREEN_MANURE:
      return options[5];
    case PERENNIAL:
      return options[6];
    default:
      console.warn('Unrecognised value passed in type filter');
      return options[0];
  }
};

interface iProps {
  type: string | null;
  onTypeChange: (type: string | null) => void;
  menuPlacement?: MenuPlacement;
  id?: string;
}

const TypeFilterDropdown = ({ type = null, onTypeChange, menuPlacement, id }: iProps) => {
  const onChange = (opt) => {
    onTypeChange(opt.value);
  };

  return (
    <ReactSelect
      styles={SELECT_STYLES}
      options={options}
      value={getOptionFromValue(type)}
      onChange={onChange}
      menuPortalTarget={document.body}
      menuPlacement={menuPlacement}
      inputId={id}
      isSearchable={false}
    />
  );
};

export default TypeFilterDropdown;
