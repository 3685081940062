import { createSelector } from 'reselect';
import { NotificationStoreRootState } from './notification-slice';

/**
 * TODO - we don't really know here that the notifications reducer is going to
 * be under 'notifications'
 *
 * To be a proper external library we either need to pass in another state when we use these selectors,
 *
 * ```
 * ConnectedNotificationDisplayContainer.propTypes = {
 *   notifications: PropTypes.arrayOf(PropTypes.instanceOf(Notification)).isRequired,
 * };
 *
 * const mapStateToProps = (state) => {
 *   return {
 *     notifications: NotificationSelectors.getNotifications(state.notifications), // <-----
 *   };
 * };
 * ```
 *
 * --- OR ---
 *
 * When we create these selector functions below we pass it the reducer name from the root state (What is done currently)
 *
 * ```
 * const setup = (reducerName) => {
 *   const getNotificationsByID = (state) => state[reducerName].notificationsByID;
 *   const getNotificationIDs = (state) => state[reducerName].notificationIDs;
 * }
 * ```
 * This could be handled any number of ways, including a class with methods.
 *
 *
 * Not really sure which of these solutions would be better in the long run
 */

export const getNotificationsByID = (state: NotificationStoreRootState) => state.notifications.notificationsByID;

export const getNotificationIDs = (state: NotificationStoreRootState) => state.notifications.notificationIDs;

export const getNotifications = createSelector([getNotificationsByID, getNotificationIDs], (notificationsByID, notificationIDs) => {
  return notificationIDs.map((ID) => notificationsByID[ID]);
});
