import SuggestedArticle from '../suggested-article';

export type SuggestedArticleAPIObject = {
  author: string;
  articleDate: string;
  authorCountryCode: string;
  contentURL: string;
  description: string;
  isArticle: boolean;
  isPestGuide: boolean;
  isPlantGrowGuide: boolean;
  isVideo: boolean;
  keyImageAlt: string;
  keyImageURL: string;
  keywords: string;
  priority: number;
  priorityLog: string;
  title: string;
};

const parseWCFDate = (dateString: string): Date => {
  // WCF serialises DateTime objects in JSON like so: /Date(1406847600000+0100)\
  // See http://msdn.microsoft.com/en-us/library/bb412170%28v=vs.110%29.aspx
  // for WCF date format explanation (DateTime Wire Format section).

  // See also https://github.com/moment/moment/issues/1943 for short discussion about how to parse the +0100 part
  // (tldr: safe to ignore if working only with UTC times)

  // You can check that this function works by checking the /xml/ version against the /json/ version of the API data.
  // Because in XML, WCF serialises DateTimes as ISO strings. So just parse the json version with this
  // function and check against the XML version to make sure our parsed date matches what the server has.
  // (it does).

  // This code is pinched directly from momentjs (https://github.com/moment/moment/blob/develop/moment.js)
  const aspNetJsonRegex = /^\/?Date\((\-?\d+)/i; // eslint-disable-line no-useless-escape
  const results = aspNetJsonRegex.exec(dateString);

  if (results === null) {
    throw new Error('Invalid date format provided');
  }

  return new Date(+results[1]);
};

export const suggestedArticlesFromAPI = (articleArray: SuggestedArticleAPIObject[]): SuggestedArticle[] =>
  articleArray.map((articleObject) => ({
    author: articleObject.author,
    date: parseWCFDate(articleObject.articleDate),
    authorCountryCode: articleObject.authorCountryCode,
    contentURL: articleObject.contentURL,
    description: articleObject.description,
    isArticle: articleObject.isArticle,
    isPestGuide: articleObject.isPestGuide,
    isPlantGrowGuide: articleObject.isPlantGrowGuide,
    isVideo: articleObject.isVideo,
    keyImageAlt: articleObject.keyImageAlt,
    keyImageURL: articleObject.keyImageURL,
    keywords: articleObject.keywords.split(',').map((keyword) => keyword.trim().toLowerCase()),
    priority: articleObject.priority,
    priorityLog: articleObject.priorityLog,
    title: articleObject.title,
  }));
