import React from 'react';

import NumberedPlantingCalendarDisplay from './numbered-planting-calendar-display';
import { PlantingCalendar } from '../planting-calendar';
import EmptyPlantingCalendarDisplay from './empty-planting-calendar-display';

interface iProps {
  calendarBarURL: string;
  startMonth: number;
  plantingCalendar: PlantingCalendar | null;
  compact?: boolean;
  highlightOnHover?: boolean;
  artifactCode: string;
  noMargin?: boolean;
}

const PlantingCalendarDisplay = ({
  plantingCalendar,
  calendarBarURL,
  startMonth,
  compact = false,
  highlightOnHover = true,
  artifactCode,
  noMargin,
}: iProps): JSX.Element => {
  if (plantingCalendar === null) {
    return <EmptyPlantingCalendarDisplay />;
  }

  return (
    <NumberedPlantingCalendarDisplay
      calendarBarURL={calendarBarURL}
      startMonth={startMonth}
      numberedCalendar={plantingCalendar.numbered}
      compact={compact}
      highlightOnHover={highlightOnHover}
      isCustom={plantingCalendar.isCustom}
      modifier={plantingCalendar.modifier}
      artifactCode={artifactCode}
      noMargin={noMargin}
    />
  );
};

export default PlantingCalendarDisplay;
