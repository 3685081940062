import React, { useContext } from 'react';

import { PrintablePlannerIcon, PrintablePlannerIconWithFamily } from '@gi/planner-icon';
import { ResourceContext } from '@gi/resource-provider';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

interface iProps {
  plantCode: string;
  familyID: number;
}

const IconCell = ({ plantCode, familyID }: iProps): JSX.Element | null => {
  const { userArtifactCode } = useContext(ResourceContext);
  const { options } = useContext(GlobalPlantListContext);

  return (
    <td className='icon-td center-align-cell top-cell'>
      {options.showPlantFamily ? (
        <PrintablePlannerIconWithFamily iconClassName='plant-list-icon' code={plantCode} artifactCode={userArtifactCode} familyID={familyID} />
      ) : (
        <PrintablePlannerIcon className='plant-list-icon' code={plantCode} artifactCode={userArtifactCode} />
      )}
    </td>
  );
};

export default IconCell;
