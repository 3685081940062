import RequestActionTypes from '@gi/react-requests/source/request-action-types';
import { getIsSavingUser } from '@gi/react-session/source/session-selectors';
import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncOperation, EmptyAsyncOperation } from '@gi/utils';
import { LoadingState } from '@gi/constants';

import { FrostDateSettings } from './services/frost-dates-service';
import { GoogleLocation } from './services/google-location-service';
import { AppAccountPageName, UserReferralCode } from './types';

type CustomAsyncOperation<T> = AsyncOperation<T> & { value?: T };

/**
 * Matches only actions that are failed SAVE_USER requests
 * @param action The store action
 * @returns True if the action is a failed SAVE_USER request
 */
const hasFailedToSaveUser = (action: AnyAction) => {
  if (action && action.type) {
    if (action.type === RequestActionTypes.REQUEST_FAIL) {
      if (action.key && typeof action.key === 'string' && action.key.startsWith('SAVE_USER')) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Matches only actions that are successful SAVE_USER requests
 * @param action The store action
 * @returns True if the action is a successful SAVE_USER request
 */
const hasSuccesfullySavedUser = (action: AnyAction) => {
  if (action && action.type) {
    if (action.type === RequestActionTypes.REQUEST_COMPLETE) {
      if (action.key && typeof action.key === 'string' && action.key.startsWith('SAVE_USER')) {
        return true;
      }
    }
  }
  return false;
};

type AppAccountState = {
  activePage: AppAccountPageName;
  addressLookup: CustomAsyncOperation<GoogleLocation>;
  savingLocationFrostDates: CustomAsyncOperation<{
    frostSettings: FrostDateSettings;
    shouldReload: boolean;
  }>;
  savingEmailSettings: CustomAsyncOperation<{
    shouldRedirect: boolean;
  }>;
  savingGeneralSettings: EmptyAsyncOperation;
  savingPlanSettings: EmptyAsyncOperation;
  changingPassword: EmptyAsyncOperation;
  referralCode: AsyncOperation<UserReferralCode | null>;
};

const initialState: AppAccountState = {
  activePage: 'account',
  addressLookup: { status: LoadingState.NONE },
  savingLocationFrostDates: { status: LoadingState.NONE },
  savingEmailSettings: { status: LoadingState.NONE },
  savingGeneralSettings: { status: LoadingState.NONE },
  savingPlanSettings: { status: LoadingState.NONE },
  changingPassword: { status: LoadingState.NONE },
  referralCode: { status: LoadingState.NONE },
};

const appAccountSlice = createSlice({
  name: 'appAccount',
  initialState,
  reducers: {
    setActivePage: (state, action: PayloadAction<AppAccountState['activePage']>) => {
      state.activePage = action.payload;
      return state;
    },
    setAddressLookup: (state, action: PayloadAction<AppAccountState['addressLookup']>) => {
      state.addressLookup = action.payload;
      return state;
    },
    setSavingLocationFrostDates: (state, action: PayloadAction<AppAccountState['savingLocationFrostDates']>) => {
      state.savingLocationFrostDates = action.payload;
      return state;
    },
    setSavingEmailSettings: (state, action: PayloadAction<AppAccountState['savingEmailSettings']>) => {
      state.savingEmailSettings = action.payload;
      return state;
    },
    setSavingGeneralSettings: (state, action: PayloadAction<AppAccountState['savingGeneralSettings']>) => {
      state.savingGeneralSettings = action.payload;
      return state;
    },
    setSavingPlanSettings: (state, action: PayloadAction<AppAccountState['savingPlanSettings']>) => {
      state.savingPlanSettings = action.payload;
      return state;
    },
    setChangingPassword: (state, action: PayloadAction<AppAccountState['changingPassword']>) => {
      state.changingPassword = action.payload;
      return state;
    },
    setReferralCode: (state, action: PayloadAction<AppAccountState['referralCode']>) => {
      state.referralCode = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(hasSuccesfullySavedUser, (state) => {
      // Check if we're trying to save the user's frost dates
      if (state.savingLocationFrostDates.status === LoadingState.LOADING) {
        state.savingLocationFrostDates = {
          status: LoadingState.SUCCESS,
          value: state.savingLocationFrostDates.value!,
        };
        if (state.savingLocationFrostDates.value.shouldReload) {
          window.location.reload();
        }
      }
      // Check if we're trying to change the user's email
      if (state.savingEmailSettings.status === LoadingState.LOADING) {
        state.savingEmailSettings = {
          status: LoadingState.SUCCESS,
          value: state.savingEmailSettings.value!,
        };
        if (state.savingEmailSettings.value.shouldRedirect) {
          window.location.href = '/account';
        }
      }
      // End any other running requests as successful
      if (state.savingGeneralSettings.status === LoadingState.LOADING) {
        state.savingGeneralSettings = { status: LoadingState.SUCCESS };
      }
      if (state.savingPlanSettings.status === LoadingState.LOADING) {
        state.savingPlanSettings = { status: LoadingState.SUCCESS };
      }
      if (state.changingPassword.status === LoadingState.LOADING) {
        state.changingPassword = { status: LoadingState.SUCCESS };
      }
      return state;
    });
    builder.addMatcher(hasFailedToSaveUser, (state, action) => {
      // Mark any running requests as failed
      if (state.savingLocationFrostDates.status === LoadingState.LOADING) {
        state.savingLocationFrostDates = { status: LoadingState.ERROR, error: action.error };
      }
      if (state.savingEmailSettings.status === LoadingState.LOADING) {
        state.savingEmailSettings = { status: LoadingState.ERROR, error: action.error };
      }
      if (state.savingGeneralSettings.status === LoadingState.LOADING) {
        state.savingGeneralSettings = { status: LoadingState.ERROR, error: action.error };
      }
      if (state.savingPlanSettings.status === LoadingState.LOADING) {
        state.savingPlanSettings = { status: LoadingState.ERROR, error: action.error };
      }
      if (state.changingPassword.status === LoadingState.LOADING) {
        state.changingPassword = { status: LoadingState.ERROR, error: action.error };
      }
      return state;
    });
  },
});

export const appAccountReducer = appAccountSlice.reducer;
export const AppAccountActions = appAccountSlice.actions;

interface RootState {
  appAccount: AppAccountState;
}

const AppAccountBaseSelectors = {
  getActivePage: (state: RootState) => state.appAccount.activePage,
  getAddressLookup: (state: RootState) => state.appAccount.addressLookup,
  getSavingLocationFrostDates: (state: RootState) => state.appAccount.savingLocationFrostDates,
  getSavingEmailSettings: (state: RootState) => state.appAccount.savingEmailSettings,
  getSavingGeneralSettings: (state: RootState) => state.appAccount.savingGeneralSettings,
  getSavingPlanSettings: (state: RootState) => state.appAccount.savingPlanSettings,
  getChangingPassword: (state: RootState) => state.appAccount.changingPassword,
  getReferralCode: (state: RootState) => state.appAccount.referralCode,
};

export const AppAccountSelectors = {
  ...AppAccountBaseSelectors,
  getIsSavingUser,
};
