import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LocalSettingsSelectors } from '@gi/local-settings';
import {
  DeviceDisplayMode,
  MAX_DPR,
  MAX_PLANT_SPRITE_DISPLAY_LIMIT,
  MIN_DPR,
  MIN_PLANT_SPRITE_DISPLAY_LIMIT,
  RepeatingGraphicDisplayModesType,
  WheelModesType,
  WheelModes,
  RenderMode,
} from '@gi/constants';
import { createFormValues } from '@gi/form-responsive';
import { NumberValidators } from '@gi/validators';

import {
  ModalPaneContainer,
  ModalPane,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalPaneSectionHeader,
  ModalPaneSectionContent,
  ModalPaneSection,
  ModalContext,
  ConfirmLeaveContextResult,
  ModalTabContext,
  AttemptToLeaveCallback,
} from '@gi/modal';

import InteractionSettings from './interaction-settings';
import GraphicsSettings from './graphics-settings';
import ModalSettings from './modal-settings';
import AdvancedSettings from './advanced-settings';
import ResetDeviceSettingsModal from './reset-device-settings-modal';
import { saveLocalSettings } from '../planner-settings-action-creators';
import DisplaySettings from './display-settings';
import CursorSettings from './cursor-settings';
import QualitySettings from './quality-settings';

type DeviceSettings = {
  wheelMode: WheelModesType;
  plantDisplayMode: RepeatingGraphicDisplayModesType;
  plantSpriteCountLimit: number;
  snapToGrid: boolean;
  enableSnapOnSFGMode: boolean;
  touchMode: boolean;
  showTouchIntroductionModalOnStart: boolean;
  renderMode: RenderMode;
  customDevicePixelRatio: boolean;
  devicePixelRatio: number;
  autoPan: boolean;
  deviceDisplayMode: DeviceDisplayMode;
  useCustomCursors: boolean;
  customCursorScale: number;
  showSFGHelpOnToggle: boolean;
  touchDragRequiresSelection: boolean;
  textQuality: number;
};

const DeviceSettingsTab = ({}) => {
  const localSettings = useSelector(LocalSettingsSelectors.getLocalSettings);

  const [deviceSettings, setDeviceSettings] = useState(
    createFormValues<DeviceSettings>({
      wheelMode: { value: localSettings.wheelMode },
      plantDisplayMode: { value: localSettings.plantDisplayMode },
      plantSpriteCountLimit: {
        value: localSettings.plantSpriteCountLimit,
        validators: [NumberValidators.isIntBetween(MIN_PLANT_SPRITE_DISPLAY_LIMIT, MAX_PLANT_SPRITE_DISPLAY_LIMIT)],
      },
      snapToGrid: { value: localSettings.snapToGrid },
      enableSnapOnSFGMode: { value: localSettings.enableSnapOnSFGMode },
      touchMode: { value: localSettings.touchMode },
      showTouchIntroductionModalOnStart: { value: localSettings.showTouchIntroductionModalOnStart },
      renderMode: { value: localSettings.renderMode },
      customDevicePixelRatio: { value: localSettings.customDevicePixelRatio },
      devicePixelRatio: {
        value: localSettings.devicePixelRatio,
        validators: [NumberValidators.isBetween(MIN_DPR, MAX_DPR)],
      },
      autoPan: { value: localSettings.autoPan },
      deviceDisplayMode: { value: localSettings.deviceDisplayMode },
      useCustomCursors: { value: localSettings.useCustomCursors },
      customCursorScale: { value: localSettings.customCursorScale },
      showSFGHelpOnToggle: { value: localSettings.showSFGHelpOnToggle },
      touchDragRequiresSelection: { value: localSettings.touchDragRequiresSelection },
      textQuality: { value: localSettings.textQuality },
    })
  );

  const createSetter =
    <K extends keyof DeviceSettings>(setting: K) =>
    (value: DeviceSettings[K]) => {
      setDeviceSettings(deviceSettings.setValue(setting, { value }));
    };

  const [showResetSettingsModal, setShowResetSettingsModal] = useState(false);

  const dispatch = useDispatch();

  const onSaveChanges = useCallback(
    (close = true) => {
      dispatch(
        saveLocalSettings(
          {
            wheelMode: deviceSettings.values.wheelMode,
            plantDisplayMode: deviceSettings.values.plantDisplayMode,
            plantSpriteCountLimit: deviceSettings.values.plantSpriteCountLimit,
            snapToGrid: deviceSettings.values.snapToGrid,
            enableSnapOnSFGMode: deviceSettings.values.enableSnapOnSFGMode,
            touchMode: deviceSettings.values.touchMode,
            showTouchIntroductionModalOnStart: deviceSettings.values.showTouchIntroductionModalOnStart,
            renderMode: deviceSettings.values.renderMode,
            customDevicePixelRatio: deviceSettings.values.customDevicePixelRatio,
            devicePixelRatio: deviceSettings.values.devicePixelRatio,
            autoPan: deviceSettings.values.autoPan,
            deviceDisplayMode: deviceSettings.values.deviceDisplayMode,
            useCustomCursors: deviceSettings.values.useCustomCursors,
            customCursorScale: deviceSettings.values.customCursorScale,
            showSFGHelpOnToggle: deviceSettings.values.showSFGHelpOnToggle,
            touchDragRequiresSelection: deviceSettings.values.touchDragRequiresSelection,
            textQuality: deviceSettings.values.textQuality,
          },
          close
        )
      );
      // Uncomment below if changing renderMode should require a full WGP refresh to take effect.
      // if (deviceSettings.fields.renderMode.isDifferent) {
      //   dispatch(openRenderModeChangedModal());
      // }
    },
    [saveLocalSettings, deviceSettings]
  );

  const setMouseWheelZoom = (mouseWheelZoomEnabled: boolean) => {
    setDeviceSettings(
      deviceSettings.setValue('wheelMode', {
        value: mouseWheelZoomEnabled ? WheelModes.ZOOM : WheelModes.DISABLED,
      })
    );
  };

  const mouseWheelZoomEnabled = deviceSettings.values.wheelMode === WheelModes.ZOOM;

  const { setCloseContextParams, attemptClose } = useContext(ModalContext);
  const { setLeaveContextParams } = useContext(ModalTabContext);

  useEffect(() => {
    const createLeaveCallback = (closeOnSave: boolean): AttemptToLeaveCallback => {
      return (cb) => {
        return cb(deviceSettings.hasBeenEdited).then((result) => {
          if (result === ConfirmLeaveContextResult.SaveAndClose) {
            onSaveChanges(closeOnSave);
          }

          return result !== ConfirmLeaveContextResult.Cancel;
        });
      };
    };

    const invalidMessage = deviceSettings.isValid ? undefined : 'Some inputs have invalid values, please correct them before saving';

    setCloseContextParams({
      title: 'Close Without Saving',
      text: 'Closing without saving device settings will lose any changes you have made',
      callback: createLeaveCallback(true),
      invalidMessage,
    });

    setLeaveContextParams({
      title: 'Leave Without Saving',
      text: 'Leave without saving device settings will lose any changes you have made',
      callback: createLeaveCallback(false),
      withoutSavingButtonText: 'Leave Without Saving',
      withSavingButtonText: 'Save and Leave',
      invalidMessage,
    });

    return () => {
      setCloseContextParams(null);
      setLeaveContextParams(null);
    };
  }, [deviceSettings.hasBeenEdited, onSaveChanges]);

  return (
    <>
      {showResetSettingsModal && <ResetDeviceSettingsModal closeModal={() => setShowResetSettingsModal(false)} />}
      <ModalPaneContainer>
        <ModalPane>
          <ModalPaneSection>
            <ModalPaneSectionHeader>Device Settings</ModalPaneSectionHeader>
            <ModalPaneSectionContent>
              <p className='settings-introduction'>These settings are local to this computer or device.</p>
            </ModalPaneSectionContent>
          </ModalPaneSection>
          <ModalPaneSection className='modal-form-section'>
            <ModalPaneSectionHeader>Reset</ModalPaneSectionHeader>
            <button className='button button-warning' type='button' onClick={() => setShowResetSettingsModal(true)}>
              Reset All Device Settings
            </button>
          </ModalPaneSection>
          <InteractionSettings
            enableMouseWheelZooming={mouseWheelZoomEnabled}
            setEnableMouseWheelZooming={setMouseWheelZoom}
            snapToGrid={deviceSettings.fields.snapToGrid}
            setSnapToGrid={createSetter('snapToGrid')}
            enableSnapOnSFGMode={deviceSettings.fields.enableSnapOnSFGMode}
            setEnableSnapOnSFGMode={createSetter('enableSnapOnSFGMode')}
            touchMode={deviceSettings.fields.touchMode}
            setTouchMode={createSetter('touchMode')}
            autoPan={deviceSettings.fields.autoPan}
            setAutoPan={createSetter('autoPan')}
            touchDragRequiresSelection={deviceSettings.fields.touchDragRequiresSelection}
            setTouchDragRequiresSelection={createSetter('touchDragRequiresSelection')}
          />
          <CursorSettings
            useCustomCursor={deviceSettings.fields.useCustomCursors}
            customCursorScale={deviceSettings.fields.customCursorScale}
            setUseCustomCursor={createSetter('useCustomCursors')}
            setCustomCursorScale={createSetter('customCursorScale')}
          />
          <GraphicsSettings
            plantDisplayMode={deviceSettings.fields.plantDisplayMode}
            plantSpriteCountLimit={deviceSettings.fields.plantSpriteCountLimit}
            setPlantDisplayMode={createSetter('plantDisplayMode')}
            setPlantSpriteCountLimit={createSetter('plantSpriteCountLimit')}
          />
          <QualitySettings textQuality={deviceSettings.fields.textQuality} setTextQuality={createSetter('textQuality')} />
          <ModalSettings
            showTouchIntroductionModalOnStart={deviceSettings.fields.showTouchIntroductionModalOnStart}
            setShowIntroductionModalOnStart={createSetter('showTouchIntroductionModalOnStart')}
            showSFGHelpOnToggle={deviceSettings.fields.showSFGHelpOnToggle}
            setShowSFGHelpOnToggle={createSetter('showSFGHelpOnToggle')}
          />
          <DisplaySettings deviceDisplayModeField={deviceSettings.fields.deviceDisplayMode} setDeviceDisplayMode={createSetter('deviceDisplayMode')} />
          <AdvancedSettings
            renderMode={deviceSettings.fields.renderMode}
            setRenderMode={createSetter('renderMode')}
            customDevicePixelRatio={deviceSettings.fields.customDevicePixelRatio}
            setCustomDevicePixelRatio={createSetter('customDevicePixelRatio')}
            devicePixelRatio={deviceSettings.fields.devicePixelRatio}
            setDevicePixelRatio={createSetter('devicePixelRatio')}
          />
        </ModalPane>
      </ModalPaneContainer>
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-secondary' onClick={attemptClose}>
              Cancel
            </button>
          </ModalFooterButtonsSection>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-primary' onClick={() => onSaveChanges()}>
              Save Device Settings
            </button>
          </ModalFooterButtonsSection>
        </ModalFooterButtons>
      </ModalFooter>
    </>
  );
};

export default DeviceSettingsTab;
