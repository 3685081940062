import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GuruDirectoryPageId } from '@gi/app-guru-types';
import { getComposedPageId } from '@gi/app-guru-common';
import { GuruActionCreators } from '@gi/app-guru-slice';
import { LoadingState } from '@gi/constants';

import DirectoryPageContainer from '../../components/directory-page/directory-page-container';
import DirectoryPageSections from '../../components/directory-page/directory-page-sections';
import DirectoryPageHeader from '../../components/directory-page/directory-page-header';
import DirectoryPageContentSection from '../../components/directory-page/directory-page-content-section';
import DirectoryPage from '../../components/directory-page/directory-page';
import GuruSection from '../../components/guru-section/guru-section';
import { useDirectoryPage } from '../../components/directory-page/directory-page-hooks';
import NoSearchResults from './no-search-results';
import SearchInput from './search-input';
import { GardenGuruDataContext } from '../../context/garden-guru-data-context';

import styles from './search-directory-page.module.css';

const QUERY_SEARCH_PARAM = 'q';

interface iProps {
  guruPageId: GuruDirectoryPageId;
}

const SearchDirectoryPage = ({ guruPageId }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { guruPages } = useContext(GardenGuruDataContext);
  const composedPageId = getComposedPageId(guruPages, guruPageId, searchParams.toString());
  const [page] = useDirectoryPage(composedPageId);

  const [searchString, setSearchString] = useState('');

  /**
   * Sets search input to be the search query parameter
   */
  useEffect(() => {
    if (searchParams.has(QUERY_SEARCH_PARAM)) {
      const querySearchString = searchParams.get(QUERY_SEARCH_PARAM);
      if (querySearchString) {
        setSearchString(querySearchString);
      }
    }
  }, []);

  /**
   * Sets the query param for search and adds it to the browser history
   */
  const setParamString = useCallback((query: string) => {
    const newSearchParams = new URLSearchParams();
    newSearchParams.set(QUERY_SEARCH_PARAM, query);
    window.history.pushState({}, '', `${window.location.pathname}?${newSearchParams.toString()}`);
  }, []);

  const doSearch = useCallback(() => {
    setParamString(searchString);
    dispatch(GuruActionCreators.doSearch([composedPageId, searchString]));
  }, [composedPageId, searchString]);

  // Use a crude method to get the current search string
  const currentSearch = useMemo(() => {
    if (page && page.status === LoadingState.SUCCESS) {
      if (page.value.sectionIds.length > 0) {
        const parts = page.value.sectionIds[0].split('?');
        if (parts.length > 1) {
          const params = new URLSearchParams(parts[1]);
          if (params.has(QUERY_SEARCH_PARAM)) {
            return params.get(QUERY_SEARCH_PARAM);
          }
        }
      }
    }

    return null;
  }, [page]);

  // A component to display when the search result section is empty
  const emptySectionComponent = useMemo(() => {
    if (!currentSearch) {
      return <NoSearchResults>Search our Videos, Articles and Guides</NoSearchResults>;
    }

    return <NoSearchResults>No search results for: {currentSearch}</NoSearchResults>;
  }, [currentSearch]);

  return (
    <DirectoryPageContainer>
      <DirectoryPageHeader composedPageId={composedPageId} />
      <DirectoryPage>
        <DirectoryPageContentSection>
          <GuruSection>
            <div className={styles.searchInputContainer}>
              <SearchInput searchString={searchString} setSearchString={setSearchString} doSearch={doSearch} />
            </div>
          </GuruSection>
        </DirectoryPageContentSection>
        <DirectoryPageContentSection>
          <DirectoryPageSections showLoadingTitle={false} composedPageId={composedPageId} emptySectionComponent={emptySectionComponent} />
        </DirectoryPageContentSection>
      </DirectoryPage>
    </DirectoryPageContainer>
  );
};

export default SearchDirectoryPage;
