import { CM_IN_FOOT, INCHES_IN_FOOT } from '@gi/constants';

export const metresPerSecondToMPH = (mps: number): number => {
  return mps * 60 * 60 * 0.0006213712;
};

export const metresPerSecondToKPH = (mps: number): number => {
  return mps * 60 * 60 * 0.001;
};

export const metersPerSecondToString = (mps: number, metric: boolean): string => {
  if (metric) {
    return `${metresPerSecondToKPH(mps).toFixed(1)}kph`;
  }

  return `${metresPerSecondToMPH(mps).toFixed(1)}mph`;
};

export const cmFromInches = (inches: number): number => {
  return (inches / INCHES_IN_FOOT) * CM_IN_FOOT;
};

export const inchesFromCM = (cm: number): number => {
  return (cm / CM_IN_FOOT) * INCHES_IN_FOOT;
};
