import getJournalDayErrorCodes from './get-journal-day-error-codes';
import saveJournalEntryErrorCodes from './save-entry-error-codes';
import getJournalDayEntryCountsErrorCodes from './get-journal-counts-error-codes';
import createJournalEntryErrorCodes from './create-journal-entry-error-codes';
import deleteJournalEntryErrorCodes from './delete-journal-entry-error-codes';
import getJournalActiveYearsErrorCodes from './get-journal-active-years-error-codes';

/**
 * Attaches a client message to a Network error from a deleteReminder request based on the status code.
 * Attaches a default message when there isn't a specific message for the given status code.
 *
 * @param {NetworkError} responseError
 */
export const attachDeleteJournalEntryErrorClientMessage = (responseError) => {
  if (deleteJournalEntryErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = deleteJournalEntryErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when deleting journal entry - ${responseError.statusCode}`;
  }
};

/**
 * Attaches a client message to a Network error from a getJournalActiveYears request based on the status code.
 * Attaches a default message when there isn't a specific message for the given status code.
 *
 * @param {NetworkError} responseError
 */
export const attachGetJournalActiveYearsErrorClientMessage = (responseError) => {
  if (getJournalActiveYearsErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = getJournalActiveYearsErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when loading journal active years - ${responseError.statusCode}`;
  }
};

/**
 * Attaches a client message to a Network error from a getReminders request based on the status code. Attaches a default message
 * when there isn't a specific message for the given status code.
 *
 * @param {NetworkError} responseError
 */
export const attachGetJournalDaysErrorClientMessage = (responseError) => {
  if (getJournalDayErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = getJournalDayErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when loading journal days - ${responseError.statusCode}`;
  }
};

/**
 * Attaches a client message to a Network error from a getReminders request based on the status code. Attaches a default message
 * when there isn't a specific message for the given status code.
 *
 * @param {NetworkError} responseError
 */
export const attachGetJournalCountsErrorClientMessage = (responseError) => {
  if (getJournalDayEntryCountsErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = getJournalDayEntryCountsErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when loading journal day entry counts - ${responseError.statusCode}`;
  }
};
/**
 * Attaches a client message to a Network error from a saveReminder request based on the status code. Attaches a default message
 * when there isn't a specific message for the given status code.
 *
 * @param {NetworkError} responseError
 */
export const attachSaveJournalEntryErrorClientMessage = (responseError) => {
  if (saveJournalEntryErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = saveJournalEntryErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when saving JournalEntry - ${responseError.statusCode}`;
  }
};

/**
 * Attaches a client message to a Network error from a createReminder request based on the status code. Attaches a default message
 * when there isn't a specific message for the given status code.
 *
 * @param {NetworkError} responseError
 */
export const attachCreateJournalEntryErrorClientMessage = (responseError) => {
  if (createJournalEntryErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = createJournalEntryErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when creating JournalEntry - ${responseError.statusCode}`;
  }
};

/**
 * Attaches a client message to a Network error from a dayEntryCounts request based on the status code. Attaches a default message
 * when there isn't a specific message for the given status code.
 *
 * @param {NetworkError} responseError
 */
export const attachGetJournalDayEntryCountsErrorClientMessage = (responseError) => {
  if (createJournalEntryErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = createJournalEntryErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown error when getting Day Entry Counts - ${responseError.statusCode}`;
  }
};
