import { IS_IOS } from '@gi/constants';
import bowser from './bowser';

/**
 * Avoid using WebGL on iOS and iPadOS, as on large plans this can lead to page refreshes due to long draw calls.
 * - 18/09/2024: ~~Disabled due to insanely bad performance using Pixi v7 in canvas mode on older devices. Further investigation needed.~~
 * - 23/09/2024: Bad performance is caused by the use of ImageBitmap in the Assets system of Pixi V7. Bug affects iOS 15 and 16 only by the looks.
 * Disable `preferCreateImageBitmap` in the Assets loader to avoid the issue.
 */
export const avoidWebGL =
  IS_IOS ||
  bowser.satisfies({
    mobile: {
      safari: '<16.0',
    },
  });
