import { parseDate, dateToAPI } from '@gi/utils';
import { User } from '../user';
import { UserSubscription } from '../user-subscription';

/**
 * Takes a user objects from the API and returns a UserSubscription object
 * @param {object} apiObj
 * @returns {UserSubscription}
 */
const userSubscriptionFromAPI = (apiObj): UserSubscription => {
  return {
    accountExpires: parseDate(apiObj.user.accountExpires),
    emailConfirmed: apiObj.user.emailConfirmed,
    hasUsedAndroidClient: apiObj.user.hasUsedAndroidClient,
    hasUsedFlashClient: apiObj.user.hasUsedFlashClient,
    hasUsedJournal: apiObj.user.hasUsedJournal,
    hasUsedPlantingTimesApp: apiObj.user.hasUsedPlantingTimesApp,
    hasUsedPlantingTimesWidget: apiObj.user.hasUsedPlantingTimesWidget,
    hasUsedWebClient: apiObj.user.hasUsedWebClient,
    subscribed: apiObj.user.subscribed,
    recurringSubscription: apiObj.user.recurringSubscription,
    subscriptionProduct: apiObj.user.subscriptionProduct,
    subscriptionState: apiObj.user.subscriptionState,
  };
};

/**
 * Takes a User an returns properties subscription properties which should be merged into a user API object
 */
const userSubscriptionToAPI = (user: User) => {
  return {
    accountExpires: user.subscription.accountExpires === null ? null : dateToAPI(user.subscription.accountExpires),
    emailConfirmed: user.subscription.emailConfirmed,
    hasUsedAndroidClient: user.subscription.hasUsedAndroidClient,
    hasUsedFlashClient: user.subscription.hasUsedFlashClient,
    hasUsedJournal: user.subscription.hasUsedJournal,
    hasUsedPlantingTimesApp: user.subscription.hasUsedPlantingTimesApp,
    hasUsedPlantingTimesWidget: user.subscription.hasUsedPlantingTimesWidget,
    hasUsedWebClient: user.subscription.hasUsedWebClient,
    subscribed: user.subscription.subscribed,
    recurringSubscription: user.subscription.recurringSubscription,
    subscriptionProduct: user.subscription.subscriptionProduct,
    subscriptionState: user.subscription.subscriptionState,
  };
};

export { userSubscriptionFromAPI, userSubscriptionToAPI };
