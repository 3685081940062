import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CanvasActionCreators, CanvasSelectors, GardenCanvasContext } from '@gi/react-garden-canvas';
import { ModalActionCreators } from '@gi/garden-platform-modal-renderer';
import { OpenPlanModalActionCreators } from '@gi/open-plan-modal';
import { TransferPlanActionCreators } from '@gi/react-transfer-plan';
import { PlanImageActionCreators } from '@gi/react-plan-image';
import { SessionSelectors } from '@gi/react-session';
import { PlanItemUtils, PlanUtils } from '@gi/plan';
import { GardenCanvasClipboardControllerEvent, SerialisedCanvasInteractionGroupUtils, SimulatedGardenItemClipboardData } from '@gi/plan-simulation';
import { CropRotationModes, InteractionStateType, LayerDisplayModes, PlannerControlsTab } from '@gi/constants';
import { networkConfig } from '@gi/config';
import { GardenPlatformActionCreators } from '@gi/garden-platform-slice';
import { IntercomActionCreators } from '@gi/intercom';
import { ElementPrinterContext } from '@gi/element-printer';
import { AnalyticsActionCreators, AnalyticsEventIdentifiers, ExportEventTypes } from '@gi/analytics';
import { LocalPlantListContext } from '@gi/plant-list';
import { PrintPlanContext } from '@gi/plan-printing';
import { PlannerSettingsActionCreators } from '@gi/react-planner-settings';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { RequestSelectors } from '@gi/react-requests';
import { useKeyboardShortcut } from '@gi/keyboard-shortcut';
import { DrawingToolsContext } from '@gi/drawing-tools';
import { PlannerControlsContext } from '@gi/planner-controls';
import { ContextMenuOptionGroup } from '@gi/context-menu';
import { NotificationActionCreators, NotificationActionType, NotificationTypes } from '@gi/notifications';
import { PlannerActionCreators } from '@gi/app-planner-slice';
import { LocalSettingsActionCreators, LocalSettingsSelectors } from '@gi/local-settings';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';

const CANVAS_ELEMENT_ID = 'canvas-container';

/** Permanent ID for help notification, so we never have more than 1. Negative numbers are never used automatically. */
const LOCK_HELP_NOTIFICATION_ID = -Math.round(1 + Math.random() * 10000);

type PlannerCommandContextType = {
  newPlan: () => void;
  openPlan: () => void;
  setActivePlan: (planId: number) => void;
  closePlan: (planId: number) => void;
  savePlan: null | (() => void);
  savingPlan: boolean;
  hasUnsavedChanges: boolean;
  transferPlan: null | (() => void);
  publishPlan: null | (() => void);
  generatePlanImage: null | (() => void);
  undo: null | (() => void);
  redo: null | (() => void);
  startSelect: null | (() => void);
  selectionInProgress: boolean;
  cancelSelect: null | (() => void);
  edit: null | (() => void);
  toggleGrid: null | (() => void);
  toggleRulers: null | (() => void);
  cut: null | (() => void);
  copy: null | (() => void);
  paste: null | (() => void);
  deleteNodes: null | (() => void);
  zoomIn: null | (() => void);
  zoomOut: null | (() => void);
  month: null | number;
  setMonth: null | ((month: number | null) => void);
  layer: null | LayerDisplayModes;
  setLayer: null | ((layer: LayerDisplayModes) => void);
  hasPlanHistory: boolean;
  cropRotation: null | CropRotationModes;
  setCropRotation: null | ((cropRotation: CropRotationModes) => void);
  openVideoGuide: () => void;
  startTour: null | (() => void);
  openLiveChat: (opener: string) => void;
  openHelpPages: () => void;
  isPrinting: boolean;
  printPlan: null | (() => void);
  printPlantList: () => void;
  printPartsList: () => void;
  partsListRef: React.MutableRefObject<HTMLElement | null>;
  printNotes: () => void;
  notesRef: React.MutableRefObject<HTMLElement | null>;
  openSettings: () => void;
  viewCompanions: null | (() => void);
  openLearnToUse: () => void;
  lockNodes: null | (() => void);
  unlockNodes: null | (() => void);
  sendToBack: null | (() => void);
  bringToFront: null | (() => void);
  resetZIndex: null | (() => void);
  getContextMenuOptions: () => null | ContextMenuOptionGroup[];
  focusCanvas: () => void;
};

export const PlannerCommandContext = React.createContext<PlannerCommandContextType>({} as PlannerCommandContextType);

interface iProps {
  children: React.ReactNode;
}

export const PlannerCommandProvider = ({ children }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const activeLastSavePlan = useSelector(CanvasSelectors.getActiveLastSavePlan);
  const selectedItems = useSelector(CanvasSelectors.getSelectedItems);
  const activePlanId = activePlan ? activePlan.id : null;
  const undoStacks = useSelector(CanvasSelectors.getUndoStacks);
  const { gardenCanvas } = useContext(GardenCanvasContext);
  const user = useSelector(SessionSelectors.getUser);
  const interactionState = useSelector(CanvasSelectors.getInteractionState);
  const { printElement, isPrinting } = useContext(ElementPrinterContext);
  const { printPlantList } = useContext(LocalPlantListContext);
  const { openPrintModal } = useContext(PrintPlanContext);
  const { toggleTab, openTab } = useContext(PlannerControlsContext);
  const { viewCompanions: _viewCompanions, selectedPlants } = useContext(DrawingToolsContext);
  const requests = useSelector(RequestSelectors.getRequests);
  const hideItemLockingHelpNotifications = useSelector(LocalSettingsSelectors.getHideItemLockingHelpNotifications);

  const [hasClipboardItems, setHasClipboardItems] = useState<boolean>(gardenCanvas ? gardenCanvas.clipboardController.hasClipboard : false);

  const selectionEmpty = selectedItems.all.length === 0;
  const selectionSingleItem = selectedItems.all.length === 1;

  const currentUndoStack = useMemo(() => {
    return activePlanId ? undoStacks[activePlanId] : null;
  }, [activePlanId, undoStacks]);

  const newPlan = useCallback(() => {
    dispatch(ModalActionCreators.openNewPlanModal(activePlanId));
  }, [activePlanId]);

  const openPlan = useCallback(() => {
    dispatch(OpenPlanModalActionCreators.open());
  }, []);

  const setActivePlan = useCallback((planId: number) => {
    dispatch(CanvasActionCreators.setActivePlan(planId));
  }, []);

  const closePlan = useCallback((planId: number) => {
    dispatch(CanvasActionCreators.attemptClosePlan(planId));
  }, []);

  const hasUnsavedChanges = useMemo(() => {
    if (activePlan !== null && activeLastSavePlan !== null) {
      return !PlanUtils.plansShallowEqual(activePlan, activeLastSavePlan);
    }
    return false;
  }, [activePlan, activeLastSavePlan]);

  const savingPlan = useMemo(() => {
    if (activePlan !== null) {
      return RequestsUtils.getStatus(requests, `SAVE_PLAN_${activePlan.id}`) === RequestStatus.IN_PROGRESS;
    }

    return false;
  }, [activePlan, requests]);

  const savePlan = useMemo(() => {
    if (user === null || activePlan === null) {
      return null;
    }

    return () => {
      if (user === null || activePlan === null) {
        return;
      }
      if (hasUnsavedChanges) {
        dispatch(CanvasActionCreators.savePlan(activePlan));
      } else {
        dispatch(CanvasActionCreators.noChangesToSave());
      }
    };
  }, [hasUnsavedChanges, user, activePlan]);

  // Callback for keyboard events which can't be null
  const savePlanCallback = useCallback(() => {
    if (savePlan) savePlan();
  }, [savePlan]);

  const transferPlan = useMemo(() => {
    if (!activePlan) {
      return null;
    }

    return () => {
      dispatch(TransferPlanActionCreators.openTransferPlanModal());
    };
  }, [activePlan === null]);

  const publishPlan = useMemo(() => {
    if (!activePlan) {
      return null;
    }

    return () => {
      dispatch(ModalActionCreators.openPublishPlanModal());
    };
  }, [activePlan === null]);

  const generatePlanImage = useMemo(() => {
    if (!activePlan) {
      return null;
    }
    return () => {
      dispatch(PlanImageActionCreators.setModalOpen({ open: true }));
    };
  }, [activePlan === null]);

  const selectionInProgress = useMemo(() => {
    return interactionState.type === InteractionStateType.SELECTION_BOX;
  }, [interactionState.type]);

  const startSelect = useMemo(() => {
    if (!activePlan || !gardenCanvas || selectionInProgress) {
      return null;
    }

    return () => {
      if (gardenCanvas) {
        gardenCanvas.startSelection();
      }
    };
  }, [interactionState.type, activePlan === null, gardenCanvas]);

  const cancelSelect = useMemo(() => {
    if (!activePlan || !gardenCanvas || !selectionInProgress) {
      return null;
    }

    return () => {
      if (gardenCanvas) {
        gardenCanvas.cancelSelection();
      }
    };
  }, [interactionState.type, activePlan === null, gardenCanvas]);

  const viewCompanions = useMemo(() => {
    if (!activePlan || !gardenCanvas || selectedPlants.length === 0) {
      return null;
    }

    return () => {
      _viewCompanions();
    };
  }, [activePlan === null, gardenCanvas, selectedPlants, _viewCompanions]);

  const undo = useMemo(() => {
    if (!activePlan) {
      return null;
    }

    if (currentUndoStack === null) {
      return null;
    }

    if (!(currentUndoStack.position < currentUndoStack.stages.length - 1)) {
      return null;
    }

    return () => {
      dispatch(CanvasActionCreators.undo());
    };
  }, [activePlan === null, currentUndoStack]);

  // Callback for keyboard events which can't be null
  const undoCallback = useCallback(() => {
    if (undo) undo();
  }, [undo]);

  const redo = useMemo(() => {
    if (!activePlan) {
      return null;
    }

    if (currentUndoStack === null) {
      return null;
    }

    if (currentUndoStack.position === 0) {
      return null;
    }

    return () => {
      dispatch(CanvasActionCreators.redo());
    };
  }, [activePlan === null, currentUndoStack]);

  // Callback for keyboard events which can't be null
  const redoCallback = useCallback(() => {
    if (redo) redo();
  }, [redo]);

  const cut = useMemo(() => {
    if (!activePlan || !gardenCanvas || selectionEmpty) {
      return null;
    }

    return () => {
      if (gardenCanvas) {
        gardenCanvas.cut();
      }
    };
  }, [selectionEmpty, activePlan === null, gardenCanvas]);

  // Callback for keyboard events which can't be null
  const cutCallback = useCallback(() => {
    if (cut) cut();
  }, [cut]);

  const copy = useMemo(() => {
    if (!activePlan || !gardenCanvas || selectionEmpty) {
      return null;
    }

    return () => {
      if (gardenCanvas) {
        gardenCanvas.copy();
      }
    };
  }, [selectionEmpty, activePlan === null, gardenCanvas]);

  // Callback for keyboard events which can't be null
  const copyCallback = useCallback(() => {
    if (copy) copy();
  }, [copy]);

  const paste = useMemo(() => {
    if (!activePlan || !gardenCanvas || !hasClipboardItems) {
      return null;
    }

    return () => {
      if (gardenCanvas) {
        gardenCanvas.paste();
      }
    };
  }, [hasClipboardItems, activePlan === null, gardenCanvas]);

  // Callback for keyboard events which can't be null
  const pasteCallback = useCallback(() => {
    if (paste) paste();
  }, [paste]);

  const deleteNodes = useMemo(() => {
    if (!activePlan || !gardenCanvas || selectionEmpty) {
      return null;
    }

    return () => {
      if (gardenCanvas !== null) {
        gardenCanvas.deleteSelectedNodes();
      }
    };
  }, [selectionEmpty, activePlan === null, gardenCanvas]);

  const selectionLockStatus = useMemo(() => {
    if (!activePlan || selectionEmpty) {
      return null;
    }
    return PlanItemUtils.getLockedStatus(
      activePlan,
      selectedItems.all.map(({ id }) => id)
    );
  }, [activePlan, selectedItems, selectionEmpty]);

  const tryShowItemLockingHelpToast = useCallback(
    (locked: boolean, quantity: number) => {
      if (!hideItemLockingHelpNotifications) {
        dispatch(
          NotificationActionCreators.createNotification({
            title: `${locked ? 'Locked' : 'Unlocked'} ${quantity} ${quantity === 1 ? 'item' : 'items'}.`,
            icon: 'icon-help-circled',
            type: NotificationTypes.HELP,
            ID: LOCK_HELP_NOTIFICATION_ID,
            canTimeout: true,
            visibleDuration: 10000, // 10secs
            actions: [
              {
                title: 'Show Help',
                type: NotificationActionType.HELP,
                action: PlannerActionCreators.setShowItemLockingHelpModal(true),
              },
              {
                title: "Don't Show Again",
                type: NotificationActionType.HELP,
                action: LocalSettingsActionCreators.setHideItemLockingHelpNotifications(true),
              },
            ],
          })
        );
      }
    },
    [hideItemLockingHelpNotifications]
  );

  const setLockedOnNodes = useMemo(() => {
    if (!activePlan || !gardenCanvas) {
      return null;
    }

    return (locked: boolean) => {
      dispatch(
        CanvasActionCreators.updatePlan(
          PlanItemUtils.setLockedStatus(
            activePlan,
            selectedItems.all.map(({ id }) => id),
            locked
          )
        )
      );
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ChangeItemLocked, { locked }));
      tryShowItemLockingHelpToast(locked, selectedItems.all.length);
    };
  }, [selectedItems, activePlan, gardenCanvas, tryShowItemLockingHelpToast]);

  const lockNodes = useMemo(() => {
    if (!setLockedOnNodes || !selectionLockStatus?.anyUnlocked) {
      return null;
    }
    return () => setLockedOnNodes(true);
  }, [setLockedOnNodes, selectionLockStatus]);

  const unlockNodes = useMemo(() => {
    if (!setLockedOnNodes || !selectionLockStatus?.anyLocked) {
      return null;
    }
    return () => setLockedOnNodes(false);
  }, [setLockedOnNodes, selectionLockStatus]);

  const selectionZIndexStatus = useMemo(() => {
    if (!activePlan || selectionEmpty) {
      return null;
    }
    return PlanItemUtils.getZIndexStatus(
      activePlan,
      selectedItems.all.map(({ id }) => id)
    );
  }, [activePlan, selectedItems, selectionEmpty]);

  const setZIndexOnNodes = useMemo(() => {
    if (!activePlan || !gardenCanvas || selectionEmpty) {
      return null;
    }

    return (zIndex: number) => {
      dispatch(
        CanvasActionCreators.updatePlan(
          PlanItemUtils.setZIndex(
            activePlan,
            selectedItems.all.map(({ id }) => id),
            zIndex
          )
        )
      );
    };
  }, [selectedItems, activePlan, gardenCanvas]);

  const sendToBack = useMemo(() => {
    if (!setZIndexOnNodes || !activePlan) {
      return null;
    }

    return () => {
      const newZIndex = activePlan.minZIndex - 1;
      setZIndexOnNodes(newZIndex);
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ChangeItemZIndex, { zIndex: newZIndex, operation: 'send to back' }));
      gardenCanvas?.clearSelection();
    };
  }, [setZIndexOnNodes, activePlan]);

  const bringToFront = useMemo(() => {
    if (!setZIndexOnNodes || !activePlan) {
      return null;
    }

    return () => {
      const newZIndex = activePlan.maxZIndex + 1;
      setZIndexOnNodes(newZIndex);
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ChangeItemZIndex, { zIndex: newZIndex, operation: 'bring to front' }));
      gardenCanvas?.clearSelection();
    };
  }, [setZIndexOnNodes, activePlan]);

  const resetZIndex = useMemo(() => {
    if (!setZIndexOnNodes || !activePlan) {
      return null;
    }

    if (selectionZIndexStatus && selectionZIndexStatus.min === 0 && selectionZIndexStatus.max === 0) {
      return null;
    }

    return () => {
      setZIndexOnNodes(0);
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ChangeItemZIndex, { zIndex: 0, operation: 'reset' }));
      gardenCanvas?.clearSelection();
    };
  }, [setZIndexOnNodes, activePlan]);

  const zoomIn = useMemo(() => {
    if (!activePlan || !gardenCanvas) {
      return null;
    }

    return () => {
      if (gardenCanvas) {
        gardenCanvas.increaseZoom();
      }
    };
  }, [activePlan === null, gardenCanvas]);

  const zoomInCallback = useCallback(() => {
    if (zoomIn) zoomIn();
  }, [zoomIn]);

  const zoomOut = useMemo(() => {
    if (!activePlan || !gardenCanvas) {
      return null;
    }

    return () => {
      if (gardenCanvas) {
        gardenCanvas.decreaseZoom();
      }
    };
  }, [activePlan === null, gardenCanvas]);

  const zoomOutCallback = useCallback(() => {
    if (zoomOut) zoomOut();
  }, [zoomOut]);

  const toggleGrid = useMemo(() => {
    if (!activePlan) {
      return null;
    }

    return () => {
      if (activePlan) {
        dispatch(CanvasActionCreators.setGridVisibility(activePlan, !activePlan.plannerSettings.showGrid));
      }
    };
  }, [activePlan]);

  const toggleRulers = useMemo(() => {
    if (!activePlan) {
      return null;
    }

    return () => {
      if (activePlan) {
        dispatch(CanvasActionCreators.setRulerVisibility(activePlan, !activePlan.plannerSettings.showRulers));
      }
    };
  }, [activePlan]);

  const edit = useMemo(() => {
    if (!activePlan || !selectionSingleItem) {
      return null;
    }

    return () => {
      if (activePlan && selectionSingleItem) {
        const item = SerialisedCanvasInteractionGroupUtils.getItem(selectedItems);
        if (item) {
          dispatch(
            CanvasActionCreators.editItem({
              itemID: item.id,
              itemType: item.itemType,
              planID: activePlan.id,
            })
          );
        }
      }
    };
  }, [activePlan, selectedItems, selectionSingleItem]);

  const editCallback = useCallback(() => {
    if (edit) edit();
  }, [edit]);

  const month = useMemo(() => (activePlan === null ? null : activePlan.plannerSettings.month), [activePlan]);

  const setMonth = useMemo(() => {
    if (!activePlan) {
      return null;
    }

    return (newMonth: null | number) => {
      if (activePlan) {
        dispatch(CanvasActionCreators.setMonth(activePlan, newMonth));
      }
    };
  }, [activePlan]);

  const layer = useMemo(() => (activePlan === null ? null : activePlan.plannerSettings.layer), [activePlan]);

  const setLayer = useMemo(() => {
    if (!activePlan) {
      return null;
    }

    return (newLayer: LayerDisplayModes) => {
      dispatch(CanvasActionCreators.setLayer(activePlan, newLayer));
    };
  }, [activePlan]);

  const cropRotation = useMemo(() => (activePlan === null ? null : activePlan.plannerSettings.cropRotationMode), [activePlan]);

  const setCropRotation = useMemo(() => {
    if (!activePlan) {
      return null;
    }

    return (newCropRotation: CropRotationModes) => {
      if (activePlan) {
        dispatch(CanvasActionCreators.setCropRotationMode(activePlan, newCropRotation));
      }
    };
  }, [activePlan]);

  const hasPlanHistory = useMemo(() => {
    if (activePlan === null) {
      // No plan available, don't render crop rotation options
      return false;
    }

    return activePlan.history.length !== 0;
  }, [activePlan]);

  const openVideoGuide = useCallback(() => {
    dispatch(GardenPlatformActionCreators.setShowIntroVideo(true));
  }, []);

  const startTour = useMemo(() => {
    if (!activePlan) {
      return null;
    }

    return () => {
      dispatch(IntercomActionCreators.queueIntercomShortcuts(['garden-planner-introduction']));
    };
  }, [activePlan === null]);

  const openLiveChat = useCallback((opener: string) => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics(opener));
  }, []);

  const openHelpPages = useCallback(() => {
    window.open(networkConfig.helpURL, '_blank')?.focus();
  }, []);

  const openLearnToUse = useCallback(() => {
    toggleTab(PlannerControlsTab.OBJECTIVES);
  }, [toggleTab]);

  // Element references for printing
  const partsListRef = useRef<HTMLElement | null>(null);
  const notesRef = useRef<HTMLElement | null>(null);

  const printPlan = activePlan ? openPrintModal : null;

  const printPartsList = useCallback(() => {
    if (partsListRef.current) {
      printElement(partsListRef.current);
    }
    dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.EXPORTS, ExportEventTypes.EXPORTED_PARTS_LIST));
  }, []);

  const printNotes = useCallback(() => {
    if (notesRef.current) {
      printElement(notesRef.current);
    }
  }, []);

  const openSettings = useCallback(() => {
    dispatch(PlannerSettingsActionCreators.openPlannerSettings());
  }, []);

  const editPlanBackground = useCallback(() => {
    openTab(PlannerControlsTab.BACKGROUND_IMAGE);
    if (activePlan && activePlan.backgroundImage) {
      dispatch(CanvasActionCreators.setLayer(activePlan, LayerDisplayModes.BACKGROUND_IMAGES));
    }
  }, [openTab, activePlan]);

  const focusCanvas = useCallback(() => {
    document.getElementById(CANVAS_ELEMENT_ID)?.focus();
  }, []);

  /** Wraps a callback, returning a function that calls the callback, then focuses the canvas (or null if callback is null) */
  const executeThenFocus = useCallback(
    (toExecute: (() => void) | null): (() => void) | null => {
      if (toExecute) {
        return () => {
          toExecute();
          focusCanvas();
        };
      }
      return null;
    },
    [focusCanvas]
  );

  const getContextMenuOptions = useCallback<() => ContextMenuOptionGroup[] | null>(() => {
    if (!activePlan) {
      return null;
    }

    const clipboardGroupOptions: ContextMenuOptionGroup = {
      title: 'Clipboard',
      options: [
        {
          title: 'Cut',
          command: executeThenFocus(cut),
          icon: 'icon-scissors',
        },
        {
          title: 'Copy',
          command: executeThenFocus(copy),
          icon: 'icon-docs',
        },
        {
          title: 'Paste',
          command: executeThenFocus(paste),
          icon: 'icon-paste',
        },
      ],
    };

    if (selectionEmpty) {
      return [
        clipboardGroupOptions,
        {
          title: 'Background',
          options: [
            {
              title: activePlan.backgroundImage ? 'Edit Image' : 'Add Image',
              command: editPlanBackground,
              icon: 'icon-picture',
            },
          ],
        },
      ];
    }

    const editGroupOptions: ContextMenuOptionGroup = {
      title: 'Edit',
      options: [
        {
          title: 'Edit',
          command: edit,
          icon: 'icon-edit',
        },
        {
          title: 'Delete',
          command: executeThenFocus(deleteNodes),
          icon: 'icon-trash',
        },
      ],
    };

    const showLock = lockNodes !== null || unlockNodes === null;
    const layoutGroupOptions: ContextMenuOptionGroup = {
      title: 'Layout',
      options: [
        {
          title: showLock ? 'Lock' : 'Unlock',
          command: executeThenFocus(showLock ? lockNodes : unlockNodes),
          icon: showLock ? 'icon-lock' : 'icon-lock-open',
        },
        {
          title: 'Bring to Front',
          command: executeThenFocus(bringToFront),
          icon: 'icon-order-front',
        },
        {
          title: 'Send to Back',
          command: executeThenFocus(sendToBack),
          icon: 'icon-order-back',
        },
      ],
    };

    return [editGroupOptions, clipboardGroupOptions, layoutGroupOptions];
  }, [activePlan, selectedItems, cut, copy, paste, edit, deleteNodes, lockNodes, unlockNodes, sendToBack, bringToFront]);

  // Makes sure the canvas has focus
  const canvasFocusCheck = useCallback(() => !!(document.activeElement && document.activeElement.id === CANVAS_ELEMENT_ID), []);

  // Listen for clipboard changes and update hasClipboardItems
  useEffect(() => {
    if (gardenCanvas) {
      const callback = (data: SimulatedGardenItemClipboardData[]) => {
        setHasClipboardItems(data && data.length > 0);
      };

      gardenCanvas.clipboardController.on(GardenCanvasClipboardControllerEvent.ClipboardUpdate, callback);
      return () => {
        gardenCanvas.clipboardController.off(GardenCanvasClipboardControllerEvent.ClipboardUpdate, callback);
      };
    }

    return () => {};
  }, [gardenCanvas]);

  useKeyboardShortcut(newPlan, 'g', true);
  useKeyboardShortcut(openPlan, 'i', true);
  useKeyboardShortcut(savePlanCallback, 's', true);
  useKeyboardShortcut(cutCallback, 'x', true, false, canvasFocusCheck);
  useKeyboardShortcut(copyCallback, 'c', true, false, canvasFocusCheck);
  useKeyboardShortcut(pasteCallback, 'v', true, false, canvasFocusCheck);
  useKeyboardShortcut(undoCallback, 'z', true, false, canvasFocusCheck);
  useKeyboardShortcut(redoCallback, 'y', true, false, canvasFocusCheck);
  useKeyboardShortcut(zoomInCallback, ']', true, false, canvasFocusCheck);
  useKeyboardShortcut(zoomOutCallback, '[', true, false, canvasFocusCheck);
  useKeyboardShortcut(editCallback, 'e', true, false, canvasFocusCheck);

  const value = useMemo<PlannerCommandContextType>(
    () => ({
      newPlan,
      openPlan,
      savePlan,
      hasUnsavedChanges,
      savingPlan,
      publishPlan,
      transferPlan,
      generatePlanImage,
      selectionInProgress,
      startSelect,
      cancelSelect,
      viewCompanions,
      undo,
      redo,
      cut,
      copy,
      paste,
      deleteNodes,
      zoomIn,
      zoomOut,
      toggleGrid,
      toggleRulers,
      edit,
      month,
      setMonth,
      layer,
      setLayer,
      cropRotation,
      setCropRotation,
      hasPlanHistory,
      openVideoGuide,
      startTour,
      openLiveChat,
      openHelpPages,
      openLearnToUse,
      partsListRef,
      notesRef,
      isPrinting,
      printPlan,
      printPlantList,
      printPartsList,
      printNotes,
      openSettings,
      setActivePlan,
      closePlan,
      lockNodes,
      unlockNodes,
      sendToBack,
      bringToFront,
      resetZIndex,
      getContextMenuOptions,
      focusCanvas,
    }),
    [
      newPlan,
      openPlan,
      savePlan,
      savingPlan,
      hasUnsavedChanges,
      publishPlan,
      transferPlan,
      generatePlanImage,
      selectionInProgress,
      startSelect,
      cancelSelect,
      viewCompanions,
      undo,
      redo,
      cut,
      copy,
      paste,
      deleteNodes,
      zoomIn,
      zoomOut,
      toggleGrid,
      toggleRulers,
      edit,
      month,
      setMonth,
      layer,
      setLayer,
      cropRotation,
      setCropRotation,
      hasPlanHistory,
      openVideoGuide,
      startTour,
      openLiveChat,
      openHelpPages,
      openLearnToUse,
      partsListRef,
      notesRef,
      isPrinting,
      printPlan,
      printPlantList,
      printPartsList,
      printNotes,
      openSettings,
      setActivePlan,
      closePlan,
      lockNodes,
      unlockNodes,
      sendToBack,
      bringToFront,
      resetZIndex,
      getContextMenuOptions,
    ]
  );

  return <PlannerCommandContext.Provider value={value}>{children}</PlannerCommandContext.Provider>;
};
