import React, { useContext } from 'react';
import { updateFilters } from '@gi/filters';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import PlantListOptionFilter from './plant-list-option-filter';

const ShowPerennialsOption = () => {
  const { plantGroupFilters, setPlantGroupFilters, varietyGroupFilters, setVarietyGroupFilters, areaGroupFilters, setAreaGroupFilters } =
    useContext(GlobalPlantListContext);

  function set(showPerennials: boolean) {
    setPlantGroupFilters(updateFilters(plantGroupFilters, { perennial: { showPerennials } }));
    setVarietyGroupFilters(updateFilters(varietyGroupFilters, { perennial: { showPerennials } }));
    setAreaGroupFilters(updateFilters(areaGroupFilters, { perennial: { showPerennials } }));
  }

  return (
    <PlantListOptionFilter
      label='Show Perennials'
      id='plant-list-option:show-perennials'
      value={plantGroupFilters.filters.perennial.inputs.showPerennials}
      setValue={(checked) => {
        set(checked);
      }}
    />
  );
};

export default ShowPerennialsOption;
