import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Application } from './navigation-context';

export type NavigationState = {
  navigateTo: Application | null;
};

const DEFAULT_STATE: NavigationState = {
  navigateTo: null,
};

export interface NavigateToPayload {
  application: Application;
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState: DEFAULT_STATE,
  reducers: {
    navigateTo: (state, action: PayloadAction<NavigateToPayload>) => {
      state.navigateTo = action.payload.application;
    },
    clearNavigateTo: (state) => {
      state.navigateTo = null;
    },
  },
});

export const navigationReducer = navigationSlice.reducer;
export const NavigationActionCreators = navigationSlice.actions;

type RootState = {
  navigation: NavigationState;
};

export const NavigationSelectors = {
  getNavigateTo: (state: RootState) => state.navigation.navigateTo,
};
