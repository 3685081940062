import { Pest } from '@gi/pest';

import { SearchService } from './search-service';
import { SearchDisplayMode } from './search-result';

export class PestSearchService extends SearchService<Pest> {
  constructor(itemList: Pest[], options?: Fuzzysort.Options) {
    super(
      itemList,
      ['name', 'otherName', 'autoCompleteNames', 'scientificName'],
      {
        name: {
          weight: 1,
          processBrackets: true,
          processBracketsWeightFactor: 0.25,
          includeForSingleChar: true,
        },
        otherName: {
          weight: 0.5,
          delimiter: ',',
          displayMode: SearchDisplayMode.Secondary,
        },
        scientificName: {
          weight: 0.8,
          displayMode: SearchDisplayMode.Secondary,
        },
        autoCompleteNames: {
          weight: 0.6,
          delimiter: ',',
          displayMode: SearchDisplayMode.Secondary,
        },
      },
      options
    );
  }
}
