import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import FormField, {
  createFormValues,
  FormCheckboxField,
  FormLayout,
  FormSection,
  FormTabButton,
  FormTabContent,
  FormTabs,
  FormTabsSection,
  FORM_LAYOUT_PRESETS,
} from '@gi/form-responsive';
import { SessionSelectors } from '@gi/react-session';
import { ResourceContext, ResourceLoaderContext } from '@gi/resource-provider';
import Country from '@gi/country';
import CountryRegion, { CountryRegionUtils } from '@gi/country-region';
import LoadingButton from '@gi/loading-button';
import { Validators } from '@gi/validators';
import { LoadingState } from '@gi/constants';
import { AppAccountSelectors, AppAccountActionCreators } from '@gi/app-account-slice';
import type { UserLocationFrostDatesSettings } from '@gi/app-account-types';
import { DisplayableError } from '@gi/app-account-types';

import InfoBanner from '../components/info-banner';
import UserLocationForm from './user-location-form';
import RegionalPlantingForm from './regional-planting-form';
import ConfirmChangeCountryModal from './modals/confirm-country-change-modal';

import 'react-datepicker/dist/react-datepicker.css';

const DAYS_BETWEEN_JAN_1_JULY_1 = moment().month(7).date(1).dayOfYear() - moment().month(1).date(1).dayOfYear();

type UserLocationFormState = UserLocationFrostDatesSettings & {
  postcode: string;
  autoFrostDates: boolean;
};

interface NotificationData {
  type: 'success' | 'fail';
  content: string;
}

function getFrostDate(day: number | null, northernHemisphere: boolean) {
  if (day === null) {
    return null;
  }
  const result = northernHemisphere
    ? moment().dayOfYear(day + 1) // Moment's dayOfYear is 1-366
    : moment().dayOfYear((day + 1 + DAYS_BETWEEN_JAN_1_JULY_1) % 365);
  // If we're in the southern hemisphere, and we're in the first half of the year, add a year.
  // This makes the date range continuous -> July 1st (year) to June 30 (year+1),
  // rather than wrapping back around after December 31st
  if (!northernHemisphere && result.month() <= 5) {
    result.add(1, 'year');
  }
  return result;
}

const UserLocationFrostDatesForm = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const user = useSelector(SessionSelectors.getUser);
  const { userCountry } = useContext(ResourceContext);
  const { countryRegions } = useContext(ResourceLoaderContext);

  if (!user) {
    console.warn('Attempting to render UserLocationFrostDatesForm without a User');
    return null;
  }
  if (!userCountry) {
    console.warn('Attempting to render UserLocationFrostDatesForm without a User country');
    return null;
  }

  // Load the regions data if we haven't already.
  // This isn't loaded by default, as it's not critical.
  useEffect(() => {
    countryRegions.loadIfNeeded();
  }, []);

  const isSavingUser = useSelector(AppAccountSelectors.getIsSavingUser);
  const savingLocationFrostDates = useSelector(AppAccountSelectors.getSavingLocationFrostDates);
  const [showModal, setShowModal] = useState(false);

  // If the lat/long are both 0, the location is unset, so default to the country location to try be helpful.
  const initialLatitude =
    user.settings.location.latitude === 0 && user.settings.location.longitude === 0 ? userCountry.latitude : user.settings.location.latitude;
  const initialLongitude =
    user.settings.location.latitude === 0 && user.settings.location.longitude === 0 ? userCountry.longitude : user.settings.location.longitude;

  const [manager, setManager] = useState(
    createFormValues<UserLocationFormState>({
      country: { value: userCountry },
      region: {
        value: null,
        validators: userCountry.useRegionalPlantData ? [Validators.isNotNull()] : [],
      },
      postcode: { value: '' },
      longitude: { value: initialLongitude },
      latitude: { value: initialLatitude },
      autoFrostDates: { value: true },
      noFrost: { value: user.settings.frostDates.noFrost },
      lastFrost: { value: user.settings.frostDates.last },
      firstFrost: { value: user.settings.frostDates.first },
      splitSeason: { value: user.settings.location.splitSeason },
    })
  );

  const setField = <K extends keyof UserLocationFormState>(setting: K, value: UserLocationFormState[K]) => {
    setManager(manager.setValue(setting, { value }));
  };

  const setCountry = (country: Country) => {
    setManager(
      manager.setValues(
        ['country', { value: country }],
        ['region', { value: null, validators: country.useRegionalPlantData ? [Validators.isNotNull()] : [] }],
        ['postcode', { value: '' }],
        ['firstFrost', { value: country.defaultFirstFrostDay }],
        ['lastFrost', { value: country.defaultLastFrostDay }],
        ['noFrost', { value: false }],
        ['splitSeason', { value: false }],
        ['latitude', { value: country.latitude, hasBeenEdited: false }],
        ['longitude', { value: country.longitude, hasBeenEdited: false }]
      )
    );
  };

  const setPosition = (latitude: number, longitude: number) => {
    setManager(manager.setValues(['latitude', { value: latitude }], ['longitude', { value: longitude }]));
  };

  // When our postcode changes, attempt to find which region we're part of.
  const setPostcode = (postcode: string) => {
    let region: CountryRegion | null = null;
    if (countryRegions.status === LoadingState.SUCCESS) {
      region = CountryRegionUtils.findRegionByPostcode(countryRegions.value, manager.values.country.code, parseInt(postcode, 10));
    }
    setManager(manager.setValues(['postcode', { value: postcode }], ['region', { value: region }]));
  };

  // When the search address changes, attempt to match a postcode and update the regional planting form.
  const handleAddressChange = (address: string) => {
    if (manager.values.country.useRegionalPlantData) {
      const extracted = new RegExp(manager.values.country.regionalPostcodeValidationRegExpr).exec(address);
      if (extracted !== null && extracted.length > 0 && extracted[0] !== '') {
        setPostcode(extracted[0]);
      }
    }
  };

  const hasChangedLocation = manager.fields.country.hasBeenEdited || manager.fields.longitude.hasBeenEdited || manager.fields.latitude.hasBeenEdited;

  // This is how this is checked in ACM.
  const hasSetLocation =
    (manager.values.latitude !== manager.values.country.latitude || manager.values.longitude !== manager.values.country.longitude) &&
    manager.values.longitude !== 0 &&
    manager.values.latitude !== 0;

  const manualFirstFrostDate = getFrostDate(manager.values.firstFrost, manager.values.country.northernHemisphere);
  const manualLastFrostDate = getFrostDate(manager.values.lastFrost, manager.values.country.northernHemisphere);
  const currentFirstFrostDate = getFrostDate(user.settings.frostDates.first, userCountry.northernHemisphere);
  const currentLastFrostDate = getFrostDate(user.settings.frostDates.last, userCountry.northernHemisphere);

  const setManualLastFrostDate = (date: Date | null) => {
    if (date === null) {
      setField('lastFrost', manager.values.country.defaultLastFrostDay);
      return;
    }
    const momentDate = moment(date);
    let day = manager.values.country.northernHemisphere
      ? momentDate.dayOfYear() - 1 // Moment's dayOfYear is 1-366
      : (momentDate.dayOfYear() - 1 - DAYS_BETWEEN_JAN_1_JULY_1 + 365) % 365;
    if (momentDate.isLeapYear() && momentDate.month() >= 2) {
      day -= 1;
    }
    setField('lastFrost', day);
  };

  const setManualFirstFrostDate = (date: Date | null) => {
    if (date === null) {
      setField('firstFrost', manager.values.country.defaultFirstFrostDay);
      return;
    }
    const momentDate = moment(date);
    let day = manager.values.country.northernHemisphere
      ? momentDate.dayOfYear() - 1 // Moment's dayOfYear is 1-366
      : (momentDate.dayOfYear() - 1 - DAYS_BETWEEN_JAN_1_JULY_1 + 365) % 365;
    if (momentDate.isLeapYear() && momentDate.month() >= 2) {
      day -= 1;
    }
    setField('firstFrost', day);
  };

  // Upon a successful save, reset the form.
  useEffect(() => {
    if (savingLocationFrostDates.status === LoadingState.SUCCESS) {
      setShowModal(false);
      setManager(
        createFormValues<UserLocationFormState>({
          country: { value: manager.values.country },
          region: {
            value: manager.values.region,
            validators: userCountry.useRegionalPlantData ? [Validators.isNotNull()] : [],
          },
          firstFrost: { value: savingLocationFrostDates.value.frostSettings.firstFrostDay },
          lastFrost: { value: savingLocationFrostDates.value.frostSettings.lastFrostDay },
          noFrost: { value: savingLocationFrostDates.value.frostSettings.noFrost },
          splitSeason: { value: savingLocationFrostDates.value.frostSettings.splitSeason },
          latitude: { value: manager.values.latitude },
          longitude: { value: manager.values.longitude },
          autoFrostDates: { value: manager.values.autoFrostDates },
          postcode: { value: manager.values.postcode },
        })
      );
    } else if (savingLocationFrostDates.status === LoadingState.ERROR) {
      setShowModal(false);
    }
  }, [savingLocationFrostDates]);

  // Update the notification whenever savingLocationFrostDates updates
  const notification = useMemo<NotificationData | null>(() => {
    if (savingLocationFrostDates.status === LoadingState.SUCCESS) {
      if (savingLocationFrostDates.value.frostSettings.mode === 'defaults') {
        return {
          type: 'success',
          content:
            'Location saved. We have set your frost dates to the default dates for your country. For more accurate planting dates, we recommend finding out the frost dates for your local area and updating them in the Manual Frost Dates tab.',
        };
      }
      if (savingLocationFrostDates.value.frostSettings.mode === 'manual') {
        return {
          type: 'success',
          content: 'Your location and frost dates have been set',
        };
      }
      if (savingLocationFrostDates.value.frostSettings.mode === 'regional') {
        return null; // Notification is handled by regional planting form
      }
      const distance = user.settings.units.metric
        ? `${savingLocationFrostDates.value.frostSettings.weatherStationDistance.toFixed(1)} km`
        : `${(savingLocationFrostDates.value.frostSettings.weatherStationDistance / 1.60934).toFixed(1)} miles`;
      return {
        type: 'success',
        content: `Location saved, frost dates have been set from your nearest weather station ${savingLocationFrostDates.value.frostSettings.weatherStationName}, ${distance} away`,
      };
    }
    if (savingLocationFrostDates.status === LoadingState.ERROR) {
      const errorText =
        savingLocationFrostDates.error instanceof DisplayableError
          ? ` (${savingLocationFrostDates.error.message})`
          : '. Please check your connection and try again.';
      return {
        type: 'fail',
        content: `Failed to save location & frost dates${errorText}`,
      };
    }
    return null;
  }, [savingLocationFrostDates]);

  const submit = () => {
    dispatch(AppAccountActionCreators.saveLocationFrostDateSettings(user, manager.values, manager.values.autoFrostDates));
  };

  const trySubmit = () => {
    if (manager.fields.country.isDifferent) {
      setShowModal(true);
    } else {
      submit();
    }
  };

  const autoFrostDatesForm = (() => {
    const lastFrost = hasChangedLocation ? 'Will be updated on save' : (currentLastFrostDate?.format(userCountry?.dateFormat) ?? 'No date set');
    const firstFrost = hasChangedLocation ? 'Will be updated on save' : (currentFirstFrostDate?.format(userCountry?.dateFormat) ?? 'No date set');
    return (
      <>
        <InfoBanner>
          {manager.values.country.canLookupFrostDates ? (
            <p>Your frost dates will be set automatically when you save. If you need to change them, please use the Manual Frost Dates tab.</p>
          ) : (
            <p>
              Estimated frost dates will be set automatically when you save, however, we do not have accurate frost date information for your country. We
              recommend finding accurate frost dates for your area and filling them in manually.
            </p>
          )}
          {!manager.values.country.northernHemisphere && (
            <p>As you live in the Southern Hemisphere, your growing season is assumed to run from 1 July to 30 June the following year.</p>
          )}
        </InfoBanner>
        {user.settings.frostDates.noFrost ? (
          <p>You have no frost in your area</p>
        ) : (
          <>
            <p>Last Frost - {lastFrost}</p>
            <p>First Frost - {firstFrost}</p>
          </>
        )}
        {user.settings.location.splitSeason && <p>You have a split season</p>}
      </>
    );
  })();

  const manualFrostDatesForm = (
    <>
      <InfoBanner>
        <p>If you need to adjust the Frost Dates to match the climate in your area, please do so here and then save them.</p>
        {!manager.values.country.northernHemisphere && (
          <p>As you live in the Southern Hemisphere, your growing season is assumed to run from 1 July to 30 June the following year.</p>
        )}
      </InfoBanner>
      <FormCheckboxField layout='reverse' label='My area does not experience frost.' htmlFor='no-frost'>
        <input type='checkbox' id='no-frost' checked={manager.values.noFrost} onChange={(e) => setField('noFrost', e.target.checked)} />
      </FormCheckboxField>
      {!manager.values.noFrost && (
        <>
          <FormField label='Last Frost' htmlFor='last-frost'>
            <DatePicker
              id='last-frost'
              selected={manualLastFrostDate?.toDate()}
              dateFormat={userCountry?.dateFormat.replaceAll('D', 'd').replaceAll('Y', 'y')}
              minDate={getFrostDate(0, manager.values.country?.northernHemisphere)?.toDate()}
              maxDate={manualFirstFrostDate?.toDate()}
              onChange={(date) => setManualLastFrostDate(date)}
            />
          </FormField>
          <FormField label='First Frost' htmlFor='first-frost'>
            <DatePicker
              id='first-frost'
              selected={manualFirstFrostDate?.toDate()}
              dateFormat={userCountry?.dateFormat.replaceAll('D', 'd').replaceAll('Y', 'y')}
              minDate={manualLastFrostDate?.toDate()}
              maxDate={getFrostDate(364, manager.values.country?.northernHemisphere)?.toDate()}
              onChange={(date) => setManualFirstFrostDate(date)}
            />
          </FormField>
        </>
      )}
      <FormCheckboxField
        layout='reverse'
        label='Split Growing Season'
        htmlFor='split-season'
        helpText='Use this option if you have summers which are too hot for cool season crops to grow'
      >
        <input type='checkbox' id='split-season' checked={manager.values.splitSeason} onChange={(e) => setField('splitSeason', e.target.checked)} />
      </FormCheckboxField>
    </>
  );

  return (
    <form autoComplete='off'>
      {showModal && (
        <ConfirmChangeCountryModal
          country={manager.values.country.name ?? 'Unknown'}
          onCancel={() => setShowModal(false)}
          onConfirm={() => submit()}
          loading={savingLocationFrostDates.status === LoadingState.LOADING}
        />
      )}
      <FormSection className='form-section-background'>
        <UserLocationForm
          values={{
            country: manager.values.country,
            longitude: manager.values.longitude,
            latitude: manager.values.latitude,
          }}
          onCountryChange={setCountry}
          onPositionChange={setPosition}
          onAddressChange={handleAddressChange}
          mustSetLocation={!hasSetLocation}
        />
      </FormSection>
      <FormSection className='form-section-background'>
        {manager.values.country.useRegionalPlantData ? (
          <RegionalPlantingForm
            postcode={manager.values.postcode}
            isValid={manager.values.region !== null}
            onChange={setPostcode}
            hasRegionSet={user.settings.location.regionID !== 0}
            hasChangedLocation={hasChangedLocation}
          />
        ) : (
          <>
            <h2>Frost Dates</h2>
            {/* Frost Dates */}
            <FormTabsSection>
              <FormTabs>
                <FormTabButton onClick={() => setField('autoFrostDates', true)} active={manager.values.autoFrostDates}>
                  Automatic Frost Dates
                </FormTabButton>
                <FormTabButton onClick={() => setField('autoFrostDates', false)} active={!manager.values.autoFrostDates}>
                  Manual Frost Dates
                </FormTabButton>
              </FormTabs>
              <FormTabContent active expandIntoPadding>
                <FormSection padding={12}>{manager.values.autoFrostDates ? autoFrostDatesForm : manualFrostDatesForm}</FormSection>
              </FormTabContent>
            </FormTabsSection>
          </>
        )}
        {notification && <InfoBanner type={notification.type}>{notification.content}</InfoBanner>}
        <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.ButtonRight}>
          <LoadingButton
            type='button'
            className='button button-primary'
            loading={savingLocationFrostDates.status === LoadingState.LOADING}
            disabled={!hasSetLocation || !manager.isValid || isSavingUser || savingLocationFrostDates.status === LoadingState.LOADING}
            onClick={trySubmit}
          >
            Save Location & Frost Dates
          </LoadingButton>
        </FormLayout>
      </FormSection>
    </form>
  );
};

export default UserLocationFrostDatesForm;
