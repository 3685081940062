import React, { ReactNode } from 'react';
import './page-content.scss';

interface iProps {
  children?: ReactNode;
  className?: string;
}

const PageContent = ({ children, className }: iProps): JSX.Element => {
  const classNames = ['page-content'];
  if (className) {
    classNames.push(className);
  }
  return <div className={classNames.join(' ')}>{children}</div>;
};

export default PageContent;
