import React from 'react';

import OriginalFullscreenButton from '@gi/fullscreen-button';

const FullscreenButton = (): JSX.Element => {
  return (
    <div className='status-bar-section status-bar-buttons'>
      <OriginalFullscreenButton className='status-bar-button' />
    </div>
  );
};

export default FullscreenButton;
