import Bitmask from '@gi/bitmask';

import Node from '../../node';
import { InspectableClassData } from '../../types';
import NodeComponent from '../../node-component/node-component';
// eslint-disable-next-line import/no-cycle
import HoverableComponent from './hoverable-component';
import InteractionContext, { InteractionMode } from '../interactable/interaction-context';
import { bindToLifecycle } from '../../utils/state-utils';

export enum HoverFreezeFlag {
  DRAGGING = 1,
  MANIPULATING = 2,
}

/**
 * Hoverable Component Context
 * Allows nodes to be hovered.
 * This also helps prevent ghost hovers, if a pointerout event isn't fired for whatever reason.
 */
class HoverableComponentContext extends NodeComponent {
  type = 'HoverableComponentContext';

  #hoveredNode: Node | null = null;
  #nextHoveredNode: Node | null = null;
  #hoverFrozenFlags: Bitmask<HoverFreezeFlag> = new Bitmask();
  #hoversDisabled: boolean = false;

  constructor() {
    super();

    bindToLifecycle(this, () => {
      const interactionContext = this.owner?.tryGetContext(InteractionContext);
      if (!interactionContext) {
        return () => {};
      }

      const watcher = interactionContext.state.addWatcher(
        (state) => {
          this.setHoversDisabled(state.values.interactionMode !== InteractionMode.MOUSE);
        },
        { properties: ['interactionMode'] }
      );

      return () => watcher.destroy();
    });
  }

  /** Returns the currently hovered node, on null if none */
  get hoveredNode() {
    return this.#hoveredNode;
  }

  /** Returns true if hovers are currently frozen. Hover updates will not be propagated when true */
  get hoverFrozen() {
    return this.#hoverFrozenFlags.containsAny(Bitmask.ALL);
  }

  /**
   * Checks if the given node is currently being hovered.
   * @param node The node to check
   */
  isHovered(node: Node) {
    return this.#hoveredNode === node;
  }

  /**
   * Hovers the given node, ensuring any previously-hovered nodes get un-hovered.
   * @param node The node being hovered
   */
  hover(node: Node) {
    if (this.hoverFrozen) {
      this.#nextHoveredNode = node;
      return;
    }

    if (this.isHovered(node)) {
      return;
    }
    this.unhover();
    this.#hoveredNode = node;
    this.sendToNode(node, true);
  }

  /**
   * Unhovers whichever node is currently hovered
   * If `node` is passed in, then it will only unhover if the given node is hovered
   */
  unhover(node?: Node) {
    if (this.hoverFrozen) {
      if (node) {
        if (this.#nextHoveredNode === node) {
          this.#nextHoveredNode = null;
        }
      } else {
        this.#nextHoveredNode = null;
      }
      return;
    }

    if (node) {
      this.sendToNode(node, false);
      if (this.#hoveredNode === node) {
        this.#hoveredNode = null;
      }
    } else {
      if (this.#hoveredNode) {
        this.sendToNode(this.#hoveredNode, false);
      }
      this.#hoveredNode = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  sendToNode(node: Node, hovered: boolean) {
    // If hovers are disabled (e.g. touch), don't send new hovers to the nodes.
    if (this.#hoversDisabled && hovered) {
      return;
    }

    const component = node.components.get(HoverableComponent);
    if (component) {
      component.setHovered(hovered);
    }
  }

  /**
   * Adds a reason to freeze hovers.
   *
   * If there are any freeze flags, hovers will not update until all are removed.
   * @param flag The flag to set on
   */
  addFreezeFlag(flag: HoverFreezeFlag) {
    const wasFrozen = this.hoverFrozen;
    this.#hoverFrozenFlags.add(flag);
    // If we've just frozen, set the next hovered node to be the current node,
    // so we can track if the hover changes while we're frozen.
    if (!wasFrozen && this.hoverFrozen) {
      this.#nextHoveredNode = this.#hoveredNode;
    }
  }

  /**
   * Removes a reason to freeze hovers.
   *
   * If there are no freeze flags, hovers will be resumed.
   * @param flag The flag to set on
   */
  removeFreezeFlag(flag: HoverFreezeFlag) {
    const wasFrozen = this.hoverFrozen;
    this.#hoverFrozenFlags.remove(flag);
    // If we've just unfrozen, hover the next hovered node.
    // This should help prevent hover being out of sync after a freeze.
    if (wasFrozen && !this.hoverFrozen) {
      if (this.#nextHoveredNode === null) {
        this.unhover();
      } else {
        this.hover(this.#nextHoveredNode);
      }
      this.#nextHoveredNode = null;
    }
  }

  /**
   * Sets a reason to freeze hovers as either on or off
   *
   * If there are any freeze flags, hovers will be frozen. If there are none, they will resume.
   * @param flag The flag to set on or off
   * @param active If the flag should be on or off
   */
  setFreezeFlag(flag: HoverFreezeFlag, active: boolean) {
    if (active) {
      this.addFreezeFlag(flag);
    } else {
      this.removeFreezeFlag(flag);
    }
  }

  setHoversDisabled(disabled: boolean) {
    if (this.#hoversDisabled === disabled) {
      return;
    }
    this.#hoversDisabled = disabled;
    if (disabled) {
      this.unhover();
    }
  }

  inspectorData: InspectableClassData<this> = [];
}

export default HoverableComponentContext;
