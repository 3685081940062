/**
 * How close to the colour we attempted to draw does the output canvas need to be (per channel)
 * FireFox seems to introduce slight random noise across all channels, so we can't just check for
 *  pure red/green/blue...
 */
const COLOR_ACCURACY_TOLERANCE: number = 10;

/**
 * Checks if pixel data from getImageData matches an expected colour.
 * @param toCheck The array containing the pixel's colour
 * @param expected The expected colour [r, g, b, a], 0-255
 * @param tolerance The absolute difference allowed between the given and expected value, per channel
 * @returns True if the colours look to be the same
 */
function checkIsColor(toCheck: Uint8ClampedArray, expected: [number, number, number, number], tolerance: number = COLOR_ACCURACY_TOLERANCE): boolean {
  if (toCheck.length !== 4) {
    return false;
  }
  return (
    Math.abs(toCheck[0] - expected[0]) <= tolerance &&
    Math.abs(toCheck[1] - expected[1]) <= tolerance &&
    Math.abs(toCheck[2] - expected[2]) <= tolerance &&
    Math.abs(toCheck[3] - expected[3]) <= tolerance
  );
}

/**
 * Utility class to check and track if the browser supports background image reads.
 */
class CanvasBackgroundUsageCompatibility {
  #supported: boolean | null = null;

  /** Does the browser appear to support background canvas usage. Uses cached value */
  get supported(): boolean {
    if (this.#supported === null) {
      this.#supported = this.#check();
    }
    return this.#supported;
  }

  /** Check if the browser appears to support background canvas usage. Result is cached in `supported` */
  check(): boolean {
    this.#supported = this.#check();
    return this.#supported;
  }

  // eslint-disable-next-line class-methods-use-this
  #check(): boolean {
    let canvas: HTMLCanvasElement | undefined;

    try {
      canvas = document.createElement('canvas');
      canvas.width = 9; // Should be power of 3 to maintain reading a single pixel later
      canvas.height = 3; // Should be odd to maintain reading a single pixel later

      const context = canvas.getContext('2d', { willReadFrequently: true });

      // We couldn't make a 2d context, maybe canvas itself is unsupported, or maybe blocked.
      if (context === null) {
        return false;
      }

      const canvasThirdWidth = canvas.width / 3;

      // Draw a |RED|GREEN|BLUE| test pattern horizontally across the canvas.
      context.fillStyle = '#ff0000';
      context.fillRect(0, 0, canvasThirdWidth, canvas.height);
      context.fillStyle = '#00ff00';
      context.fillRect(canvasThirdWidth, 0, canvasThirdWidth, canvas.height);
      context.fillStyle = '#0000ff';
      context.fillRect(canvasThirdWidth * 2, 0, canvasThirdWidth, canvas.height);

      // Attempt to read
      const imageDataR = context.getImageData(Math.floor(canvasThirdWidth * 0.5), Math.floor(canvas.height / 2), 1, 1);
      const imageDataG = context.getImageData(Math.floor(canvasThirdWidth * 1.5), Math.floor(canvas.height / 2), 1, 1);
      const imageDataB = context.getImageData(Math.floor(canvasThirdWidth * 2.5), Math.floor(canvas.height / 2), 1, 1);

      return (
        checkIsColor(imageDataR.data, [255, 0, 0, 255]) && checkIsColor(imageDataG.data, [0, 255, 0, 255]) && checkIsColor(imageDataB.data, [0, 0, 255, 255])
      );
    } finally {
      canvas?.remove();
    }
  }
}

const canvasBackgroundUsageCompatibility = new CanvasBackgroundUsageCompatibility();

export default canvasBackgroundUsageCompatibility;
