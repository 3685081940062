import React, { ReactNode, createContext, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlannerControlsTab } from '@gi/constants';
import { LocalSettingsActionCreators, LocalSettingsSelectors } from '@gi/local-settings';
import { DisplayModeUtils } from '@gi/display-mode-provider';

const MIN_SCREEN_WIDTH: number = 1400;

interface PlannerControlsContextType {
  activeTab: PlannerControlsTab;
  setActiveTab: (activeTab: PlannerControlsTab) => void;
  minimised: boolean;
  setMinimised: (minimised: boolean) => void;
  openTab: (activeTab: PlannerControlsTab) => void;
  toggleTab: (activeTab: PlannerControlsTab) => void;
  activeDrawer: PlannerControlsTab | null;
  setActiveDrawer: (activeDrawer: PlannerControlsTab | null) => void;
}

export const PlannerControlsContext = createContext<PlannerControlsContextType>({} as PlannerControlsContextType);

interface iProps {
  children?: ReactNode;
}

const PlannerControlsProvider = ({ children }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const minimisedSettings = useSelector(LocalSettingsSelectors.getMinimiseRightSidebar);
  const activeTab = useSelector(LocalSettingsSelectors.getRightSidebarActiveTab);
  const initiallyWideEnough = useRef(DisplayModeUtils.isMinWidth(MIN_SCREEN_WIDTH));

  const [minimised, _setMinimised] = useState<boolean>(minimisedSettings || !initiallyWideEnough.current);
  const [activeDrawer, setActiveDrawer] = useState<PlannerControlsTab | null>(null);

  const setMinimised = useCallback((nowMinimised) => {
    _setMinimised(nowMinimised);
    dispatch(LocalSettingsActionCreators.setMinimiseRightSidebar(nowMinimised));
  }, []);

  const setActiveTab = useCallback((newActiveTab: PlannerControlsTab) => {
    dispatch(LocalSettingsActionCreators.setRightSidebarActiveTab(newActiveTab));
  }, []);

  const openTab = useCallback((tab: PlannerControlsTab) => {
    setActiveTab(tab);
    setMinimised(false);
  }, []);

  const toggleTab = useCallback(
    (tab: PlannerControlsTab) => {
      if (activeTab === tab && !minimised) {
        setMinimised(true);
      } else {
        openTab(tab);
      }
    },
    [activeTab, minimised, openTab]
  );

  const value = useMemo<PlannerControlsContextType>(
    () => ({
      activeTab,
      setActiveTab,
      minimised,
      setMinimised,
      openTab,
      toggleTab,
      activeDrawer,
      setActiveDrawer,
    }),
    [activeTab, minimised, toggleTab, activeDrawer]
  );

  return <PlannerControlsContext.Provider value={value}>{children}</PlannerControlsContext.Provider>;
};

export default PlannerControlsProvider;
