import deepmerge from 'deepmerge';

// @ts-expect-error: Import based on 'BUILD_ENV' environment variable
import _config from './config.BUILD_ENV.json'; // eslint-disable-line
import configDefaults from './config.defaults.json';

export type Config = {
  basename: string;
  bugsnag: {
    releaseStage: string;
    apiKey: string;
    pdfBugsnagKey: string;
  };
  enabledReduxDevTools: boolean;
  googleApiKey: string;
  anonymousApiKey: string;
  intercom: {
    id: string;
    plannerProductTour: {
      desktop: number;
      mobile: number;
    };
  };
  session: {
    loginRedirectURL: string;
  };
};

const overwriteMerge = (_, sourceArray) => sourceArray;

const config = deepmerge(configDefaults, _config, { arrayMerge: overwriteMerge }) as Config;
export default config;
