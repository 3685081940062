import React from 'react';

interface iProps {
  children: React.ReactNode;
  className?: string;
}

const ModalPaneSectionContent = ({ children, className = '' }: iProps) => {
  return <div className={`modal-pane-section-content ${className}`}>{children}</div>;
};

export default ModalPaneSectionContent;
