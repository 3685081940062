/**
 * Enum for the types of publish actions
 */
enum PublishActionType {
  SET_STAGE = 'PUBLISH/SET_STAGE',
  SET_PROGRESS = 'PUBLISH/SET_PROGRESS',
  SET_ERROR_REASON = 'PUBLISH/SET_ERROR_REASON',
  PUBLISH_FAILURE = 'PUBLISH/PUBLISH_FAILURE',
  PUBLISH_SUCCESS = 'PUBLISH/PUBLISH_SUCCESS',
}

export default PublishActionType;
