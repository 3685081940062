import EventEmitter from 'eventemitter3';
import { SimulatedPlant } from './simulated-plant';

export enum SimulatedHistoricalPlanEvent {
  DidUpdate = 'DidUpdate',
}

type SimulatedHistoricalPlanEventActions = {
  [SimulatedHistoricalPlanEvent.DidUpdate]: () => void;
};

export class SimulatedHistoricalPlan extends EventEmitter<SimulatedHistoricalPlanEventActions> {
  #id: number;
  get id() {
    return this.#id;
  }

  #age: number;
  get age() {
    return this.#age;
  }

  #plants: Record<number, SimulatedPlant>;
  get plants(): Readonly<Record<number, SimulatedPlant>> {
    return this.#plants;
  }

  #plantIds: number[];
  get plantIds() {
    return this.#plantIds;
  }

  constructor(id: number, age: number) {
    super();

    this.#id = id;
    this.#age = age;

    this.#plants = {};
    this.#plantIds = [];
  }

  /**
   * Sets the "age" of the historical plan (e.g. how many years ago in the history it is)
   * @param age The age/stage of the historical plan
   */
  setAge(age: number) {
    this.#age = age;
  }

  /**
   * Adds a plant to the historical plan
   */
  addPlant(plant: SimulatedPlant) {
    this.#plants[plant.id] = plant;
    this.#plantIds.push(plant.id);
  }

  /**
   * Removes a plant from the historical plan
   * @param plantId The id of the plant to remove
   */
  removePlant(plantId: number) {
    if (this.#plants[plantId]) {
      delete this.#plants[plantId];

      const index = this.plantIds.indexOf(plantId);
      if (index === -1) {
        throw new Error(`Plant ID not found ${plantId}`);
      }
      this.plantIds.splice(index, 1);
    }
  }

  /**
   * Updates a plant by id within this historical plan
   * @param plantId The id of the plant to update
   * @param rowStart The start position of the plant
   * @param rowEnd The end position of the plant
   * @param height The height of the block of plants. 0 for a row/single
   */
  updatePlant(plantId: number, rowStart: Vector2, rowEnd: Vector2, height: number) {
    if (!this.#plants[plantId]) {
      console.error(`Cannot update plant that isn't part of the history simulation (plantID ${plantId})`);
      return;
    }
    const simulatedPlant = this.#plants[plantId];
    simulatedPlant.setPositions(rowStart, rowEnd, height);
  }

  /**
   * Emits a DidUpdate event from this plan. Call when finished adding/removing/updating plants
   */
  emitUpdate() {
    this.emit(SimulatedHistoricalPlanEvent.DidUpdate);
  }
}
