import React, { useCallback, useEffect, useRef } from 'react';
import ReactSelect from 'react-select';

import { PickerButton } from '@gi/texture-picker';
import { presetPalettes } from '@gi/palette';
import LengthInput, { DegreesInput, VectorInput } from '@gi/length-input';
import FormField, { FORM_FIELD_PRESETS, FormSection, InputContainer } from '@gi/form-responsive';

import { SMALL_SELECT_STYLES } from '@gi/styles/react-select-styles';

import { DropdownOption, EditTextModalFormProps, TextState } from '../types';

import styles from './edit-text-modal-form.module.css';

const fontSizes: DropdownOption[] = [
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 16, label: '16' },
  { value: 18, label: '18' },
  { value: 20, label: '20' },
  { value: 24, label: '24' },
  { value: 36, label: '36' },
];

const EditTextModalForm = ({ values, setValues, distanceUnits }: EditTextModalFormProps): JSX.Element => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const createSetter = useCallback(
    <K extends keyof TextState>(setting: K) =>
      (value: TextState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  /**
   * Auto-focus the textarea. `autoFocus` doesn't seem to work.
   */
  useEffect(() => {
    // Unfortunately, adding a small delay seems to be the only way to get the textarea to properly auto-focus
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  }, []);

  return (
    <>
      {/* Text content section */}
      <FormSection heading='Text' padding={0} gap={6}>
        <InputContainer size='full' ref={ref}>
          <textarea rows={5} value={values.values.text} onChange={(e) => createSetter('text')(e.target.value)} className={styles.textArea} />
        </InputContainer>
      </FormSection>
      {/* Text Style section */}
      <FormSection heading='Text Style' padding={12} gap={6} className='form-section-background'>
        <FormField label='Color' fakeLabel layoutPreset={FORM_FIELD_PRESETS.EditModal}>
          <PickerButton selectedSwatch={values.values.selectedSwatch} palettes={presetPalettes.colorPalettes} onSwatchSelect={createSetter('selectedSwatch')} />
        </FormField>
        <FormField label='Font Size' htmlFor='edit-text:font-size' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
          <InputContainer>
            <ReactSelect<DropdownOption>
              styles={SMALL_SELECT_STYLES}
              options={fontSizes}
              value={fontSizes.find((option) => option.value === values.values.fontSize)}
              onChange={(option) => {
                if (option) {
                  createSetter('fontSize')(option.value);
                }
              }}
              menuPortalTarget={document.body}
              isSearchable={false}
              inputId='edit-text:font-size'
            />
          </InputContainer>
        </FormField>
      </FormSection>
      {/* Position & Dimensions section */}
      <FormSection heading='Position &amp; Dimensions' padding={12} gap={6} className='form-section-background'>
        <FormField label='Center' htmlFor='edit-text:center-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
          <VectorInput value={values.values.center} onChange={createSetter('center')} distanceUnits={distanceUnits} id='edit-text:center' />
        </FormField>
        <FormField label='Width' htmlFor='edit-text:width' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
          <LengthInput value={values.values.width} onChange={createSetter('width')} distanceUnits={distanceUnits} id='edit-text:width' />
        </FormField>
        <FormField label='Height' htmlFor='edit-text:height' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
          <LengthInput value={values.values.height} onChange={createSetter('height')} distanceUnits={distanceUnits} id='edit-text:height' />
        </FormField>
        <FormField label='Rotation' htmlFor='edit-text:rotation' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
          <DegreesInput value={values.values.rotation} onChange={createSetter('rotation')} id='edit-text:rotation' />
        </FormField>
      </FormSection>
    </>
  );
};

export default EditTextModalForm;
