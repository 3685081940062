import { useEffect } from 'react';

export const SpritesheetCountryCodes = ['au', 'gb', 'nl', 'us', 'za'] as const;
export type SpritesheetCountryCode = (typeof SpritesheetCountryCodes)[number];

type SpritesheetImport = Promise<{ default: string }>;

/**
 * Imports the CSS needed to make the spritesheets work for plant/garden object icons
 * @param countryCode The user's countryCode
 * @returns Imports for the actual spritesheet images.
 */
export const loadSpritesheet = (
  countryCode: SpritesheetCountryCode | string
): Promise<{ default: { spritesheet: SpritesheetImport; spritesheet2x: SpritesheetImport } }> => {
  switch (countryCode) {
    case 'au':
      console.debug('Loading AU Spritesheet');
      return import('./au/spritesheet-au');
    case 'gb':
      console.debug('Loading GB Spritesheet');
      return import('./gb/spritesheet-gb');
    case 'nl':
      console.debug('Loading NL Spritesheet');
      return import('./nl/spritesheet-nl');
    case 'za':
      console.debug('Loading ZA Spritesheet');
      return import('./za/spritesheet-za');
    case 'us':
    default:
      console.debug('Loading US Spritesheet');
      return import('./us/spritesheet-us');
    // TODO - Have spritesheet with all as default?
  }
};

interface iProps {
  countryCode?: SpritesheetCountryCode | string;
}

/**
 * Loads the spritesheets and CSS for plant and garden object icons.
 * @deprecated Provided by ResourceLoader. Only use if ResourceLoader is not needed.
 */
const SpritesheetLoader = ({ countryCode = 'us' }: iProps): null => {
  useEffect(() => {
    loadSpritesheet(countryCode);
  }, [countryCode]);

  return null;
};

export default SpritesheetLoader;
