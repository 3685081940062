import { Plan, PlanUtils } from '@gi/plan';
import { useMemo } from 'react';

/**
 * Small memo hooks for caching an array of plan items
 */
export function usePlanPlants(plan: Plan) {
  return useMemo(() => PlanUtils.getPlanPlants(plan), [plan.plants]);
}

export function usePlanGardenObjects(plan: Plan) {
  return useMemo(() => PlanUtils.getPlanGardenObjects(plan), [plan.gardenObjects]);
}

export function usePlanShapes(plan: Plan) {
  return useMemo(() => PlanUtils.getPlanShapes(plan), [plan.shapes]);
}

export function usePlanText(plan: Plan) {
  return useMemo(() => PlanUtils.getPlanText(plan), [plan.text]);
}
