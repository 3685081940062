import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Forecast from '../forecast';

export type ForecastSlice = {
  forecast: Forecast[];
  forecastLoaded: boolean;
  forecastLoadFailed: boolean;
};

const DEFAULT_STATE: ForecastSlice = {
  forecast: [],
  forecastLoaded: false,
  forecastLoadFailed: false,
};

export const forecastSlice = createSlice({
  name: 'forecast',
  initialState: DEFAULT_STATE,
  reducers: {
    setForcast: (state, action: PayloadAction<Forecast[]>) => {
      state.forecastLoaded = true;
      state.forecast = action.payload;
      state.forecastLoadFailed = false;
    },
    forecastFailed: (state) => {
      state.forecastLoaded = true;
      state.forecastLoadFailed = true;
    },
  },
});

export const forecastReducer = forecastSlice.reducer;
export const ForecastActionCreators = forecastSlice.actions;

interface RootState {
  forecast: ForecastSlice;
}

export const ForecastSelectors = {
  getForecast: (state: RootState) => state.forecast.forecast,
  getForecastLoadFailed: (state: RootState) => state.forecast.forecastLoadFailed,
};
