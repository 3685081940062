import React from 'react';
import { Transition } from 'react-transition-group';

import SidebarPosition from '../sidebar-position';

import './sidebar-item.scss';

interface iSidebarItemGroupProps {
  active?: boolean;
  className?: string;
  children: React.ReactNode;
}

const SidebarItemGroup = ({ active = false, className = '', children }: iSidebarItemGroupProps) => {
  return <div className={`${className} sidebar-item-group ${active ? 'active' : ''}`}>{children}</div>;
};

interface iSidebarItemProps {
  active?: boolean;
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const SidebarItem = ({ active = false, className = '', children, onClick }: iSidebarItemProps) => {
  const content = children;

  if (onClick) {
    return (
      <div role='button' onClick={onClick} className={`${className} sidebar-item sidebar-item-button ${active ? 'active' : ''}`}>
        {content}
      </div>
    );
  }

  return <div className={`${className} sidebar-item ${active ? 'active' : ''}`}>{content}</div>;
};

interface iSidebarPaneContentProps {
  small?: boolean;
  className?: string;
  children: React.ReactNode;
}

const SidebarPaneContent = ({ children, small = false, className = '' }: iSidebarPaneContentProps) => {
  return <div className={`${className} sidebar-pane-content ${small ? 'sidebar-pane-content-small' : ''}`}>{children}</div>;
};

const sidebarContentStyle = {
  transition: 'width 500ms cubic-bezier(0, 1.07, 0.58, 1) 0s, max-width 500ms cubic-bezier(0, 1.07, 0.58, 1) 0s, box-shadow 500ms',
};

const sidebarContentTransitionStyles = {
  default: { width: '0', maxWidth: '0' },
  entering: { width: '300px', maxWidth: '300px' },
  entered: { width: '300px', maxWidth: '300px' },
  exiting: { width: '0', maxWidth: '0' },
  exited: { width: '0', maxWidth: '0' },
};

interface iSidebarItemContentProps {
  sidebarState: SidebarPosition;
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const SidebarItemContent = ({ sidebarState, className = '', children, style = {} }: iSidebarItemContentProps) => {
  return (
    <Transition in={sidebarState === SidebarPosition.Open} timeout={500}>
      {(state) => (
        <div
          className='sidebar-item-content'
          style={{
            ...sidebarContentStyle,
            ...sidebarContentTransitionStyles[state],
          }}
        >
          <div style={style} className={`${className} sidebar-item-content-inner`}>
            {children}
          </div>
        </div>
      )}
    </Transition>
  );
};

const fullWidthSidebarContentTransitionStyles = {
  default: { width: '64px', maxWidth: '64px' },
  entering: { width: '364px', maxWidth: '364px' },
  entered: { width: '364px', maxWidth: '364px' },
  exiting: { width: '64px', maxWidth: '64px' },
  exited: { width: '64px', maxWidth: '64px' },
};

interface iFullwidthSidebarItemContentProps {
  sidebarState: SidebarPosition;
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const FullwidthSidebarItemContent = ({ sidebarState, className = '', children, style = {} }: iFullwidthSidebarItemContentProps) => {
  return (
    <Transition in={sidebarState === SidebarPosition.Open} timeout={500}>
      {(state) => (
        <div
          className='fullwidth-sidebar-item-content'
          style={{
            ...sidebarContentStyle,
            ...fullWidthSidebarContentTransitionStyles[state],
          }}
        >
          <div style={style} className={`${className} fullwidth-sidebar-item-content-inner`}>
            {children}
          </div>
        </div>
      )}
    </Transition>
  );
};

export { SidebarItemGroup, SidebarItem, SidebarItemContent, SidebarPaneContent, FullwidthSidebarItemContent };
