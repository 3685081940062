import React from 'react';

import { PlannerTabTypes } from '@gi/constants';
import Plan from '@gi/plan';
import { FeatureGate } from '@gi/features';

import DesktopPlannerTab from './desktop-planner-tab';

import styles from './desktop-planner-tabs.module.css';

interface iProps {
  activePlannerTab: PlannerTabTypes;
  activePlan: Plan | null;
}

const DesktopPlannerTabs = ({ activePlannerTab, activePlan }: iProps): JSX.Element => {
  return (
    <div className={styles.desktopPlannerTabs}>
      <DesktopPlannerTab active={activePlannerTab === PlannerTabTypes.PLANNER} tab={PlannerTabTypes.PLANNER} activePlan={activePlan}>
        Plan
      </DesktopPlannerTab>
      <DesktopPlannerTab active={activePlannerTab === PlannerTabTypes.PLANT_LIST} tab={PlannerTabTypes.PLANT_LIST} activePlan={activePlan}>
        Plant List
      </DesktopPlannerTab>
      <DesktopPlannerTab active={activePlannerTab === PlannerTabTypes.PARTS_LIST} tab={PlannerTabTypes.PARTS_LIST} activePlan={activePlan}>
        Parts List
      </DesktopPlannerTab>
      <FeatureGate featureId='SHOPPING'>
        <DesktopPlannerTab active={activePlannerTab === PlannerTabTypes.SHOPPING} tab={PlannerTabTypes.SHOPPING} activePlan={activePlan}>
          Shopping
        </DesktopPlannerTab>
      </FeatureGate>
      <DesktopPlannerTab active={activePlannerTab === PlannerTabTypes.NOTES} tab={PlannerTabTypes.NOTES} activePlan={activePlan}>
        Notes
      </DesktopPlannerTab>
    </div>
  );
};

export default DesktopPlannerTabs;
