import { Geometry } from '@gi/math';
import { EventBus, HandleNode, HandleType, InspectableClassData, NodeEvent, PointerDataWithDelta, bindToLifecycle } from '@gi/core-renderer';
import { PlantType, PlantTypes } from '@gi/constants';

import GenericHandleSetNode, { GenericHandleSetNodeEvent, GenericHandleSetNodeEventActions } from './generic-handle-set-node';
import PlantNode from '../plant/plant-node';

// The minimum distance between rowStart and rowEnd where rotation will be calculated.
// Below this distance, rotation will be set to 0 as to not look weird.
const MIN_ROW_DISTANCE = 2;

const getType = (width: number, height: number, spacing: number, inRowSpacing: number): PlantType => {
  let longerSide: number;
  let shorterSide: number;

  if (width >= height) {
    longerSide = width;
    shorterSide = height;
  } else {
    longerSide = height;
    shorterSide = width;
  }

  if (longerSide >= spacing && shorterSide >= spacing) {
    return PlantTypes.PLANT_BLOCK;
  }

  if (longerSide >= inRowSpacing) {
    return PlantTypes.PLANT_ROW;
  }

  return PlantTypes.PLANT_SINGLE;
};

const rotate = (point: Vector2, pivot: Vector2, angleCos: number, angleSin: number): Vector2 => {
  return {
    x: angleCos * (point.x - pivot.x) + angleSin * (point.y - pivot.y) + pivot.x,
    y: angleCos * (point.y - pivot.y) - angleSin * (point.x - pivot.x) + pivot.y,
  };
};

interface PlantPosition {
  rowStart: Vector2;
  rowEnd: Vector2;
  height: number;
}

type PlantHandlesInternalState = {
  rowStart: Vector2;
  rowEnd: Vector2;
  /** The height of the plant before any manipulation. Used to maintain height when shrinking out/back into a block */
  initialHeight: number;
  height: number;
  spacing: number;
  rowSpacing: number;
  inRowSpacing: number;
  rotation: number;
};

export enum PlantHandleSetNodeEvent {
  Transform = 'Transform',
}

type PlantHandleSetNodeEventActions = GenericHandleSetNodeEventActions & {
  [PlantHandleSetNodeEvent.Transform]: (rowStart: Vector2, rowEnd: Vector2, height: number) => void;
};

/**
 * PlantHandleSetNode
 *  Handles for plants, including rowStart and rowEnd positions.
 */
class PlantHandleSetNode extends GenericHandleSetNode {
  type = 'PlantHandleSetNode';

  eventBus: EventBus<PlantHandleSetNodeEventActions> = new EventBus(this.eventBus);
  internalState: PlantHandlesInternalState;

  RS: HandleNode = new HandleNode('RowStart', HandleType.ROUNDED);
  RE: HandleNode = new HandleNode('RowEnd', HandleType.ROUNDED);

  constructor(plant: PlantNode) {
    super({ allowRotation: false, allowMaintainAspectRatio: false });

    this.targets = [plant];

    this.internalState = {
      rowStart: plant.state.values.rowStart,
      rowEnd: plant.state.values.rowEnd,
      initialHeight: plant.state.values.height,
      height: plant.state.values.height,
      spacing: plant.state.values.spacing,
      rowSpacing: plant.state.values.rowSpacing,
      inRowSpacing: plant.state.values.inRowSpacing,
      rotation: plant.state.values.rotation,
    };

    this.addHandles(this.RS, this.RE);

    this.RS.onDragStart = () => this.#startRowMove(this.RS);
    this.RS.onDrag = (data) => this.#onDragRS(data);
    this.RS.onDragEnd = () => this.#endRowMove();

    this.RE.onDragStart = () => this.#startRowMove(this.RE);
    this.RE.onDrag = (data) => this.#onDragRE(data);
    this.RE.onDragEnd = () => this.#endRowMove();

    this.eventBus.on(GenericHandleSetNodeEvent.Manipulate, ({ topLeft: tl, topRight: tr, bottomRight: br, bottomLeft: bl }) => {
      const newPositions = this.updateHandlesFromCorners(tl, tr, br, bl);
      this.eventBus.emit(PlantHandleSetNodeEvent.Transform, newPositions.rowStart, newPositions.rowEnd, newPositions.height);
    });

    plant.eventBus.on(NodeEvent.Destroyed, () => this.destroy());

    bindToLifecycle(this, () => {
      const watcher = plant.state.addWatcher(
        (state) => {
          if (this.draggingHandle === null) {
            this.internalState.spacing = state.values.spacing;
            this.internalState.rowSpacing = state.values.rowSpacing;
            this.internalState.inRowSpacing = state.values.inRowSpacing;
            this.internalState.initialHeight = state.values.height;
            this.updateHandlesFromRows(state.values.rowStart, state.values.rowEnd, state.values.height);
          }
        },
        { properties: ['rowStart', 'rowEnd', 'height', 'spacing', 'rowSpacing', 'inRowSpacing'] }
      );
      return () => watcher.destroy();
    });
  }

  #startRowMove(handle: HandleNode) {
    this.draggingHandle = handle;
    this.emitStart();
  }

  #endRowMove() {
    this.draggingHandle = null;
    this.emitEnd();
  }

  #onDragRS(data: PointerDataWithDelta) {
    const snappedPosition = this.snapVector(data.worldPosition, this.RE.getPosition(), data.shiftKey);
    const newPositions = this.updateHandlesFromRows(snappedPosition, this.RE.getPosition(), this.internalState.initialHeight, this.RS);
    this.eventBus.emit(PlantHandleSetNodeEvent.Transform, newPositions.rowStart, newPositions.rowEnd, newPositions.height);
  }

  #onDragRE(data: PointerDataWithDelta) {
    const snappedPosition = this.snapVector(data.worldPosition, this.RS.getPosition(), data.shiftKey);
    const newPositions = this.updateHandlesFromRows(this.RS.getPosition(), snappedPosition, this.internalState.initialHeight, this.RE);
    this.eventBus.emit(PlantHandleSetNodeEvent.Transform, newPositions.rowStart, newPositions.rowEnd, newPositions.height);
  }

  /**
   * Updates the handles based on the new plant positions.
   * @param rowStart The new rowStart position
   * @param rowEnd The new rowEnd position
   * @param height The height of the block, if applicable
   * @param movingHandle The handle being dragged. If defined, the plant will anchor based on the other handle.
   */
  updateHandlesFromRows(rowStart: Vector2, rowEnd: Vector2, height: number = this.internalState.height, movingHandle?: HandleNode): PlantPosition {
    this.internalState.height = height;

    const rowAngle = Geometry.dist(rowStart, rowEnd) > MIN_ROW_DISTANCE ? Geometry.angleBetweenPoints(rowStart, rowEnd) : 0; // Default to 0 if gap too small to make sense
    const rowAngleSin = Math.sin(-rowAngle);
    const rowAngleCos = Math.cos(-rowAngle);

    let xSpacing: number = this.internalState.spacing;
    let ySpacing: number = this.internalState.spacing;

    const width = Geometry.dist(rowStart, rowEnd);

    const type = getType(width, height, this.internalState.spacing, this.internalState.inRowSpacing);

    let rowStartPosition: Vector2 = rowStart;
    let rowEndPosition: Vector2 = rowEnd;
    let adjustedHeight: number = height;

    if (type === PlantTypes.PLANT_ROW) {
      adjustedHeight = 0;
      if (width > height) {
        xSpacing = this.internalState.inRowSpacing;
        ySpacing = this.internalState.rowSpacing;
      } else {
        this.internalState.height = 0;
        if (movingHandle === this.RS) {
          rowStartPosition = { ...rowEnd };
        } else if (movingHandle === this.RE) {
          rowEndPosition = { ...rowStart };
        } else {
          // No primary handle provided, set both points to be the start point?
          rowStartPosition = { ...rowStart };
          rowEndPosition = { ...rowEnd };
        }
      }
    }

    if (type === PlantTypes.PLANT_SINGLE) {
      adjustedHeight = 0;
      // Single plant
      if (movingHandle === this.RS) {
        rowStartPosition = { ...rowEnd };
      } else if (movingHandle === this.RE) {
        rowEndPosition = { ...rowStart };
      } else {
        // No primary handle provided, set both points to be the start point?
        rowStartPosition = { ...rowStart };
        rowEndPosition = { ...rowStart };
      }
    }

    this.internalState.rotation = rowAngle;
    this.internalState.rowStart = rowStartPosition;
    this.internalState.rowEnd = rowEndPosition;
    this.internalState.height = adjustedHeight;

    this.RS.setPosition(movingHandle === this.RS ? rowStart : rowStartPosition);
    this.RE.setPosition(movingHandle === this.RE ? rowEnd : rowEndPosition);

    const topLeft = rotate(
      {
        x: this.internalState.rowStart.x - xSpacing / 2,
        y: this.internalState.rowStart.y - ySpacing / 2,
      },
      this.internalState.rowStart,
      rowAngleCos,
      rowAngleSin
    );

    const topRight = rotate(
      {
        x: this.internalState.rowEnd.x + xSpacing / 2,
        y: this.internalState.rowEnd.y - ySpacing / 2,
      },
      this.internalState.rowEnd,
      rowAngleCos,
      rowAngleSin
    );

    const bottomRight = rotate(
      {
        x: this.internalState.rowEnd.x + xSpacing / 2,
        y: this.internalState.rowEnd.y + ySpacing / 2 + adjustedHeight,
      },
      this.internalState.rowEnd,
      rowAngleCos,
      rowAngleSin
    );

    const bottomLeft = rotate(
      {
        x: this.internalState.rowStart.x - xSpacing / 2,
        y: this.internalState.rowStart.y + ySpacing / 2 + adjustedHeight,
      },
      this.internalState.rowStart,
      rowAngleCos,
      rowAngleSin
    );

    this.setFromCorners(topLeft, topRight, bottomRight, bottomLeft);

    return {
      rowStart: rowStartPosition,
      rowEnd: rowEndPosition,
      height: adjustedHeight,
    };
  }

  /**
   * Sets the 4 handle positions manually, and calculates the new rowStart/end positions
   * @param TLPosition The top-left position
   * @param TRPosition The top-right position
   * @param BRPosition The bottom-right position
   * @param BLPosition The bottom-left position
   */
  updateHandlesFromCorners(TLPosition: Vector2, TRPosition: Vector2, BRPosition: Vector2, BLPosition: Vector2): PlantPosition {
    const rowAngle = Geometry.angleBetweenPoints(TLPosition, TRPosition);
    const rowAngleSin = Math.sin(-rowAngle);
    const rowAngleCos = Math.cos(-rowAngle);

    const width = Geometry.dist(TLPosition, TRPosition) - this.internalState.spacing;
    const height = Geometry.dist(TRPosition, BRPosition) - this.internalState.spacing;

    const type = getType(width, height, this.internalState.spacing, this.internalState.inRowSpacing);

    this.setFromCorners(TLPosition, TRPosition, BRPosition, BLPosition);

    this.internalState.height = height;
    this.internalState.rotation = Geometry.angleBetweenPoints(TLPosition, TRPosition);

    if (type === PlantTypes.PLANT_SINGLE) {
      const pos = Geometry.midpoint(TRPosition, BLPosition);
      this.RS.setPosition(pos);
      this.RE.setPosition(pos);

      this.internalState.height = 0;
      this.internalState.rowStart = pos;
      this.internalState.rowEnd = pos;

      this.#updateOutline();

      return {
        rowStart: pos,
        rowEnd: pos,
        height: 0,
      };
    }

    if (type === PlantTypes.PLANT_ROW) {
      this.internalState.height = 0;

      // Which 2 points are by the rowStart position
      const start1 = width > height ? TLPosition : TLPosition;
      const start2 = width > height ? BLPosition : TRPosition;
      // Which 2 points are by the rowEnd position
      const end1 = width > height ? TRPosition : BLPosition;
      const end2 = width > height ? BRPosition : BRPosition;

      const startSideMidpoint = Geometry.midpoint(start1, start2);
      const endSideMidpoint = Geometry.midpoint(end1, end2);

      const dist = this.internalState.inRowSpacing / 2;

      const AC = Geometry.angleBetweenPoints(start1, start2);
      const AB = Geometry.angleBetweenPoints(start1, end1);
      const distSinAC = dist * Math.sin(AC);
      const distCosAC = dist * Math.cos(AC);
      const sinACAB = Math.sin(AC - AB);
      const cosACAB = Math.cos(AC - AB);
      const y = distCosAC * sinACAB + distCosAC * cosACAB;
      const x = distSinAC * sinACAB + distCosAC * cosACAB;

      const rowStartPos: Vector2 = { x: startSideMidpoint.x + x, y: startSideMidpoint.y - y };
      const rowEndPos: Vector2 = { x: endSideMidpoint.x - x, y: endSideMidpoint.y + y };

      this.RS.setPosition(rowStartPos);
      this.RE.setPosition(rowEndPos);

      this.internalState.rowStart = rowStartPos;
      this.internalState.rowEnd = rowEndPos;

      this.#updateOutline();

      return {
        rowStart: rowStartPos,
        rowEnd: rowEndPos,
        height: 0,
      };
    }

    const hypotenuse = this.internalState.spacing / 2;
    const angleToTR = Geometry.angleBetweenPoints(TLPosition, TRPosition);
    const angleToBL = Geometry.angleBetweenPoints(TLPosition, BLPosition);
    const theta = angleToTR - angleToBL;
    const sinTheta = Math.sin(theta);
    const opposite = sinTheta * hypotenuse;

    const rowStartPos =
      sinTheta < 0
        ? rotate({ x: TLPosition.x - opposite, y: TLPosition.y - opposite }, TLPosition, rowAngleCos, rowAngleSin)
        : rotate({ x: TRPosition.x - opposite, y: TRPosition.y - opposite }, TRPosition, rowAngleCos, rowAngleSin);

    const rowEndPos =
      sinTheta < 0
        ? rotate({ x: TRPosition.x + opposite, y: TRPosition.y - opposite }, TRPosition, rowAngleCos, rowAngleSin)
        : rotate({ x: TLPosition.x + opposite, y: TLPosition.y - opposite }, TLPosition, rowAngleCos, rowAngleSin);

    this.RS.setPosition(rowStartPos);
    this.RE.setPosition(rowEndPos);

    this.internalState.rowStart = rowStartPos;
    this.internalState.rowEnd = rowEndPos;

    this.#updateOutline();

    return {
      rowStart: rowStartPos,
      rowEnd: rowEndPos,
      height,
    };
  }

  #updateOutline = () => {
    this.shape.state.values.points = [this.TL.getPosition(), this.TR.getPosition(), this.BR.getPosition(), this.BL.getPosition()];
  };

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default PlantHandleSetNode;
