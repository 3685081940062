import React, { useContext } from 'react';

import { Anchor, Drawer, DrawerContent, DrawerHeader, DrawerSticky } from '@gi/mobile-components';
import { FormLayout, FormSection } from '@gi/form-responsive';

import NameFilterInput from '../../filters/inputs/name-filter-input';
import ShowPerennialsOption from '../../filters/inputs/show-perennials-option';
import ShowPlantFamilyOption from '../../filters/inputs/show-plant-family-option';
import ShowScientificNameOption from '../../filters/inputs/show-scientific-name-option';
import TextPlantingCalendarOption from '../../filters/inputs/text-planting-calendar-option';
import HideInGroundAllFilterInput from '../../filters/inputs/hide-in-ground-all-filter-input';
import PlantListColumnFilter from '../../filters/inputs/plant-list-column-filter';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import { DISPLAY_TYPE } from '../../constants';
import { PlantListColumns } from '../../types/plant-list-types';
import LocalPlantListContext from '../../local-provider/local-plant-list-context';
import PrintBackgroundColorsOption from '../../filters/inputs/print-background-colors-option';
import ViewModeOption from '../../filters/inputs/view-mode-option';

import './mobile-plant-list-filters-drawer.scss';

interface iColumnFilterProps {
  field: keyof PlantListColumns;
  availablility?: DISPLAY_TYPE[];
}

const ColumnFilterFilter = ({ field, availablility = [DISPLAY_TYPE.Area, DISPLAY_TYPE.Summary, DISPLAY_TYPE.Variety] }: iColumnFilterProps): JSX.Element => {
  const { displayType, columns, setColumnVisibility } = useContext(GlobalPlantListContext);

  return (
    <PlantListColumnFilter
      disabled={!availablility.includes(displayType)}
      field={field}
      visible={columns[field]}
      setVisible={(visible: boolean) => {
        setColumnVisibility(field, visible);
      }}
    />
  );
};

interface iProps {
  open: boolean;
  onClose: () => void;
  anchor?: Anchor;
}

const MobilePlantListFiltersDrawer = ({ open, anchor = 'RIGHT', onClose }: iProps): JSX.Element => {
  const { createCSV, printPlantList, isPrinting } = useContext(LocalPlantListContext);
  const { options, setOptions, displayType, resetFilters } = useContext(GlobalPlantListContext);

  const toggleShowDetails = () => {
    setOptions({
      ...options,
      expandPlantAreas: !options.expandPlantAreas,
    });
  };

  const expandButtonClassNames = ['button', 'button-secondary', 'plant-list-filters-expand-all'];
  if (options.expandPlantAreas) {
    expandButtonClassNames.push('flipped');
  }
  if (displayType === DISPLAY_TYPE.Area) {
    expandButtonClassNames.push('hidden');
  }

  return (
    <Drawer open={open} anchor={anchor} onClose={onClose} width={500}>
      <DrawerHeader as='h2' onClose={onClose}>
        Filters & Options
      </DrawerHeader>
      <DrawerContent padX padY>
        <FormSection heading='Filter' padding={0} gap={0}>
          <NameFilterInput />
          <ShowPerennialsOption />
        </FormSection>
        <FormSection heading='Options' padding={0} gap={0}>
          <ShowPlantFamilyOption />
          <ShowScientificNameOption />
          <TextPlantingCalendarOption />
          <HideInGroundAllFilterInput />
        </FormSection>
        <FormSection heading='Show/Hide Info' padding={0} gap={0}>
          <ColumnFilterFilter field='plantLabel' availablility={[DISPLAY_TYPE.Area]} />
          <ColumnFilterFilter field='counts' />
          <ColumnFilterFilter field='spacings' />
          <ColumnFilterFilter field='inGroundDates' availablility={[DISPLAY_TYPE.Area]} />
          <ColumnFilterFilter field='calendar' />
          <ColumnFilterFilter field='notes' availablility={[DISPLAY_TYPE.Variety]} />
          <ColumnFilterFilter field='area' availablility={[DISPLAY_TYPE.Variety, DISPLAY_TYPE.Summary]} />
        </FormSection>
        <FormSection heading='Export' padding={0} gap={0}>
          <button type='button' className='button button-secondary' onClick={createCSV}>
            Export to CSV
          </button>
        </FormSection>
        <FormSection heading='Print' padding={0} gap={0}>
          <button type='button' className='button button-secondary' onClick={printPlantList} disabled={isPrinting}>
            Print
          </button>
          <PrintBackgroundColorsOption />
        </FormSection>
        <FormSection heading='View' padding={0} gap={0}>
          <ViewModeOption />
          <button type='button' className={expandButtonClassNames.join(' ')} onClick={toggleShowDetails}>
            {/* <i className='icon-angle-double-down' /> */}
            {options.expandPlantAreas ? 'Hide All Details Sections' : 'Expand All Details Sections'}
          </button>
        </FormSection>
      </DrawerContent>
      <DrawerSticky position='BOTTOM' padX padY border>
        <FormLayout desktopLayout={{ layout: 'row', xAlign: 'stretch' }} mobileLayout={{ layout: 'row', xAlign: 'stretch' }}>
          <button className='button button-secondary' type='button' onClick={resetFilters}>
            Reset
          </button>
          <button className='button button-primary' type='button' onClick={onClose}>
            Done
          </button>
        </FormLayout>
      </DrawerSticky>
    </Drawer>
  );
};

export default MobilePlantListFiltersDrawer;
