import React, { ReactNode } from 'react';
import { GuruContentType, GuruPreviewPest } from '@gi/app-guru-types';

import SmallPreviewContent from '../small-preview-content';
import SmallPreviewTitle from '../small-preview-title';
import SmallPreview from '../small-preview';
import SmallPreviewTag from '../small-preview-tag';

import styles from './small-preview-pest.module.css';
import SmallPreviewLink from '../small-preview-link';

import LazyImage from '../../lazy-image/lazy-image';

interface iProps {
  previewPest: GuruPreviewPest;
  className?: string;
  children?: ReactNode;
}

const SmallPreviewPest = ({ previewPest, className = '', children }: iProps): JSX.Element | null => {
  return (
    <SmallPreview className={className}>
      <SmallPreviewLink type={GuruContentType.Pest} slug={previewPest.slug}>
        <SmallPreviewContent>
          <LazyImage className={styles.pestImage} src={previewPest.thumbnailUrl} alt='' />
          <SmallPreviewTag text='Pest Guide' color='#EBB82A' />
        </SmallPreviewContent>
        <SmallPreviewTitle>{children !== undefined ? children : previewPest.title}</SmallPreviewTitle>
      </SmallPreviewLink>
    </SmallPreview>
  );
};

export default SmallPreviewPest;
