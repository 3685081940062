import React from 'react';

import OverlayToolbar, { ToolbarAnchor, ToolbarOrientation } from '../overlay-toolbar';
import PlannerSettingsToolbarButton from './buttons/planner-settings-toolbar-button';
import ObjectivesDrawerToolbarButton from './buttons/objectives-drawer-toolbar-button';

const TopRightOverlayToolbar = (): JSX.Element => {
  return (
    <OverlayToolbar anchor={ToolbarAnchor.TOP_RIGHT} orientation={ToolbarOrientation.VERTICAL}>
      <PlannerSettingsToolbarButton />
      <ObjectivesDrawerToolbarButton />
    </OverlayToolbar>
  );
};

export default TopRightOverlayToolbar;
