import { Bounds } from '../../types';

export enum ShapeFlag {
  /* This shape should be considered for visibility checks. */
  CULLABLE = 1,
  /* This shape is for a tool/piece of UI, like handles */
  UI = 2,
  /* This shape is a plant */
  PLANT = 3,
  /* This shape is a garden object. */
  GARDEN_OBJECT = 4,
  /** This shape is a season extender. May also contain the GARDEN_OBJECT flag */
  SEASON_EXTENDER = 5,
}

export type ShapeCollisionCheckFunction = (min: Vector2, max: Vector2, shape: { points: Vector2[]; boundingBox: Bounds; position: Vector2 }) => boolean;
