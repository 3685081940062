import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PlanImageState = {
  open: boolean;
};

const DEFAULT_STATE: PlanImageState = {
  open: false,
};

export interface SetPlanImageOpen {
  open: boolean;
}

export const planImageSlice = createSlice({
  name: 'planImage',
  initialState: DEFAULT_STATE,
  reducers: {
    setModalOpen: (state, action: PayloadAction<SetPlanImageOpen>) => {
      state.open = action.payload.open;
    },
  },
});

export const planImageReducer = planImageSlice.reducer;
export const PlanImageActionCreators = planImageSlice.actions;

interface RootState {
  planImage: PlanImageState;
}

export const PlanImageSelectors = {
  getOpen: (state: RootState) => state.planImage.open,
};
