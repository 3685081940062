import moment from 'moment-timezone';

/**
 * Returns a UserPlan when given a plan from the plans property of a user from an API response
 *
 * @param {object} userPlanObject
 * @returns {UserPlan}
 */
const userPlanFromAPI = (userPlanObject) => {
  const modifiedDate = userPlanObject.modified !== '' ? userPlanObject.modified : userPlanObject.created;

  return {
    name: userPlanObject.planName,
    description: userPlanObject.planDescription,
    ID: parseInt(userPlanObject.planID, 10),
    height: userPlanObject.canvasHeight,
    width: userPlanObject.canvasWidth,
    modified: moment.tz(modifiedDate, 'DD/MM/YYYY HH:mm:ss', 'Europe/London'),
    created: moment.tz(userPlanObject.created, 'DD/MM/YYYY HH:mm:ss', 'Europe/London'),
    year: userPlanObject.planYear + 2000,
    misc: {
      created: userPlanObject.created,
      deleted: userPlanObject.deleted,
      documentVersion: userPlanObject.documentVersion,
      findOnMap: userPlanObject.findOnMap,
      grid: userPlanObject.grid,
      historyID1: userPlanObject.historyID1,
      historyID2: userPlanObject.historyID2,
      historyID3: userPlanObject.historyID3,
      historyID4: userPlanObject.historyID4,
      historyID5: userPlanObject.historyID5,
      lastSaveDevice: userPlanObject.lastSaveDevice,
      metric: userPlanObject.metric,
      notes: userPlanObject.notes,
      orientation: userPlanObject.orientation,
      orientationSet: userPlanObject.orientationSet,
      planLayout: userPlanObject.planLayout,
      planLocation: userPlanObject.planLocation,
      planPublished: userPlanObject.planPublished,
      planSoil: userPlanObject.planSoil,
      planSun: userPlanObject.planSun,
      planType: userPlanObject.planType,
      planYear: userPlanObject.planYear,
      publishMap: userPlanObject.publishMap,
      publishNotes: userPlanObject.publishNotes,
      publishPlantList: userPlanObject.publishPlantList,
      shared: userPlanObject.shared,
      showGhosts: userPlanObject.showGhosts,
      userID: userPlanObject.userID,
    },
  };
};

export { userPlanFromAPI };
