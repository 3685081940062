import { User, DEFAULT_USER_SEASON_EXTENDER_SETTINGS, UserSeasonExtenderSettings, UserPlantVariety } from '@gi/user';
import Plant from '@gi/plant';
import { ModifierType } from '@gi/constants/types/modifier-type';

import {
  PlantingCalendar,
  createPlantingCalendarFromHalfMonthDateRanges,
  createPlantingCalendarFromNumbers,
  createEmptyPlantingCalendar,
} from '../planting-calendar';
import { UserRegionalPlantingCalendar } from '../regional-planting-calendar';

import { regionalPlantingSeasonExtender } from './regional-season-extenders';
import { NumberedPlantingCalendar } from '../planting-calendar-types';
import { createCalendarRange, CalendarRange } from '../calendar-range';

const MAX_SOW_PLANT_OVERLAP = 2;
const WEEKS_IN_YEAR = 52;

/**
 * Returns a week number from a day of the year, will not return values less than 0 or more than 51
 *
 * @param {number} day
 * @returns {number}
 */
const weekFromDay = (day: number): number => {
  const week = Math.floor(day / 7);

  if (week < 0) {
    return 0;
  }

  if (week > 51) {
    return 51;
  }

  return week;
};

/**
 * Gets the number of halfmonths between two halfmonths
 * Taking into account year-wraps
 * i.e. duration between HM 22 and 2 is 4.
 *
 * @param {number} start
 * @param {number} end
 * @returns {number}
 */
const getDurationBetweenHalfMonths = (start: number, end: number): number => {
  let length = end - start;
  if (length < 0) {
    length += 24;
  }
  return length;
};

/**
 * For a given week in the year, returns a half month number
 *
 * TODO: Document whether weeks are 0/1 based
 * Half-months are 1 based i.e. the first half of Jan is half-month 1.
 *
 * There's some fudging of numbers to account for how the original Garden Planner converts a week into
 * a half month.
 *
 * @param {number} week
 * @param {boolean} mod - whether to allow the value to be greater than 24
 * @returns {number}
 */
const weekToHalfMonth = (week: number, mod: boolean = true): number => {
  // Magic. Got it.
  // If you want to know how we got this, ask Howard.
  // But some things are better left unknown.
  let halfMonth = Math.floor(((week - 4) / 52) * 24) + 4;

  if (mod) {
    halfMonth %= 24;
  }

  if (halfMonth <= 0) {
    // halfMonth can't be less than -23, because of the % 24 above.
    // But it can be less than zero, in which case it's a year ago
    // So add a year on to bring it into the current year.
    halfMonth += 24;
  }

  return halfMonth;
};

/**
 * Returns a modifier from a set of modifiers or null if the set is empty.
 * The purpose of this is to always return modifiers with the same priority,
 * so if a plant has multiple modifiers, it will always use the same one to calculate frost dates.
 */
export const getModifierFromList = (modifiers: ModifierType[]): null | string => {
  if (modifiers.length === 0) {
    // No modifier
    return null;
  }

  if (modifiers.includes(ModifierType.HeatedPolytunnel)) {
    return ModifierType.HeatedPolytunnel;
  }

  if (modifiers.includes(ModifierType.Polytunnel)) {
    return ModifierType.Polytunnel;
  }

  if (modifiers.includes(ModifierType.HeatedGreenhouse)) {
    return ModifierType.HeatedGreenhouse;
  }

  if (modifiers.includes(ModifierType.Greenhouse)) {
    return ModifierType.Greenhouse;
  }

  if (modifiers.includes(ModifierType.RowCover)) {
    return ModifierType.RowCover;
  }

  if (modifiers.includes(ModifierType.Cloche)) {
    return ModifierType.Cloche;
  }

  if (modifiers.includes(ModifierType.ColdFrame)) {
    return ModifierType.ColdFrame;
  }

  return null;
};

const getLastFrostModifier = (modifier: null | string, userSeasonExtenderSettings: UserSeasonExtenderSettings): number => {
  if (modifier === null) {
    // No modifier
    return 0;
  }

  if (modifier === ModifierType.HeatedPolytunnel) {
    return userSeasonExtenderSettings.heatedPolytunnelLastFrostAdjust;
  }

  if (modifier === ModifierType.Polytunnel) {
    return userSeasonExtenderSettings.polytunnelLastFrostAdjust;
  }

  if (modifier === ModifierType.HeatedGreenhouse) {
    return userSeasonExtenderSettings.heatedGreenhouseLastFrostAdjust;
  }

  if (modifier === ModifierType.Greenhouse) {
    return userSeasonExtenderSettings.greenhouseLastFrostAdjust;
  }

  if (modifier === ModifierType.RowCover) {
    return userSeasonExtenderSettings.rowCoverLastFrostAdjust;
  }

  if (modifier === ModifierType.Cloche) {
    return userSeasonExtenderSettings.clocheLastFrostAdjust;
  }

  if (modifier === ModifierType.ColdFrame) {
    return userSeasonExtenderSettings.coldFrameLastFrostAdjust;
  }

  return 0;
};

const getFirstFrostModifier = (modifier: null | string, userSeasonExtenderSettings: UserSeasonExtenderSettings): number => {
  if (modifier === null) {
    // No modifier
    return 0;
  }

  if (modifier === ModifierType.HeatedPolytunnel) {
    return userSeasonExtenderSettings.heatedPolytunnelFirstFrostAdjust;
  }

  if (modifier === ModifierType.Polytunnel) {
    return userSeasonExtenderSettings.polytunnelFirstFrostAdjust;
  }

  if (modifier === ModifierType.HeatedGreenhouse) {
    return userSeasonExtenderSettings.heatedGreenhouseFirstFrostAdjust;
  }

  if (modifier === ModifierType.Greenhouse) {
    return userSeasonExtenderSettings.greenhouseFirstFrostAdjust;
  }

  if (modifier === ModifierType.RowCover) {
    return userSeasonExtenderSettings.rowCoverFirstFrostAdjust;
  }

  if (modifier === ModifierType.Cloche) {
    return userSeasonExtenderSettings.clocheFirstFrostAdjust;
  }

  if (modifier === ModifierType.ColdFrame) {
    return userSeasonExtenderSettings.coldFrameFirstFrostAdjust;
  }

  return 0;
};

export const calculateRegionalPlantingCalendar = (
  plantCode: string,
  userRegionalPlantingData: UserRegionalPlantingCalendar,
  modifier: null | string,
  userSeasonExtenderSettings: UserSeasonExtenderSettings
): PlantingCalendar => {
  if (!userRegionalPlantingData[plantCode]) {
    console.warn('No regional planting data found for', plantCode);
    return createEmptyPlantingCalendar();
  }

  if (modifier === null) {
    return userRegionalPlantingData[plantCode].plantingCalendar;
  }

  const lastFrostAdjust = getLastFrostModifier(modifier, userSeasonExtenderSettings);
  const firstFrostAdjust = getFirstFrostModifier(modifier, userSeasonExtenderSettings);

  return createPlantingCalendarFromNumbers(
    regionalPlantingSeasonExtender(userRegionalPlantingData[plantCode].plantingCalendar.numbered, lastFrostAdjust, firstFrostAdjust),
    false,
    modifier
  );
};

const calculateRegionalPlantingNumbers = (
  plantCode: string,
  userRegionalPlantingData: UserRegionalPlantingCalendar,
  modifier: null | string = null,
  userSeasonExtenderSettings: UserSeasonExtenderSettings
): NumberedPlantingCalendar => {
  if (!userRegionalPlantingData[plantCode]) {
    console.warn('No regional planting data found for', plantCode);
    return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  }

  if (modifier === null) {
    return userRegionalPlantingData[plantCode].plantingCalendar.numbered;
  }

  const lastFrostAdjust = getLastFrostModifier(modifier, userSeasonExtenderSettings);
  const firstFrostAdjust = getFirstFrostModifier(modifier, userSeasonExtenderSettings);

  return regionalPlantingSeasonExtender(userRegionalPlantingData[plantCode].plantingCalendar.numbered, lastFrostAdjust, firstFrostAdjust);
};

/**
 * Calcualtes a planting times for a given plant with the provided last frost and first frost days from a users settings
 */
export const calculatePlantingCalendar = (
  plant: Plant,
  lastFrostDay: number,
  firstFrostDay: number,
  modifier: null | string = null,
  userSeasonExtenderSettings: UserSeasonExtenderSettings = DEFAULT_USER_SEASON_EXTENDER_SETTINGS
): PlantingCalendar => {
  const lastFrostModifier = getLastFrostModifier(modifier, userSeasonExtenderSettings);
  const firstFrostModifier = getFirstFrostModifier(modifier, userSeasonExtenderSettings);
  const lastFrostWeek = weekFromDay(lastFrostDay) + lastFrostModifier;
  const firstFrostWeek = weekFromDay(firstFrostDay) + firstFrostModifier;

  let sowStart = 0;
  let sowEnd = 0;
  const plantStart: number = lastFrostWeek + plant.plantRelativeLastFrost;
  let plantEnd = 0;

  let harvestStart = 0;
  const harvestEnd: number = firstFrostWeek + plant.harvestRelativeToFirstFrost;

  plantEnd = harvestEnd - plant.timeToMaturity;
  let lastPlantingTimeAdjust = 0;

  if (lastFrostWeek > 16) {
    // Used to enable later planting in short season areas
    lastPlantingTimeAdjust = Math.floor((lastFrostWeek - 16) / 2);
  }

  if (plant.sowStartIndoors !== null) {
    sowStart = plantStart + plant.sowStartIndoors;
    sowEnd = lastFrostWeek + plant.lastSowRelativeToLastFrost;
    harvestStart = sowStart + plant.timeToMaturity;

    if (sowEnd > plantStart + MAX_SOW_PLANT_OVERLAP) {
      // Checks there is not too much sow - plant overlap
      sowEnd = plantStart + MAX_SOW_PLANT_OVERLAP;
    }
  }

  if (plant.sowStartIndoors === null || sowStart >= sowEnd) {
    // Either don't sow indoors or not enough time to do so
    harvestStart = plantStart + plant.timeToMaturity;
  }

  if (plantEnd > plant.lastPlantingTime + lastPlantingTimeAdjust) {
    // I think this is just saying if the calculated end is greater
    // than it can be for this plant, set it to the max it can be
    plantEnd = plant.lastPlantingTime + lastPlantingTimeAdjust;
  }

  if (harvestStart >= harvestEnd || plantStart >= plantEnd) {
    // Not enough time to grow crop
    return createEmptyPlantingCalendar();
  }

  // Convert the start and end week numbers into half-months
  let sowStartHM = 0;
  let plantStartHM = 0;
  let harvestStartHM = 0;

  let sowLength = 0;
  let plantLength = 0;
  let harvestLength = 0;

  if (sowEnd - sowStart >= WEEKS_IN_YEAR) {
    // Check for special case where the duration is greater than the number of weeks in a year
    sowStartHM = 1;
    sowLength = 24;
  } else {
    sowStartHM = weekToHalfMonth(sowStart, true);
    sowLength = getDurationBetweenHalfMonths(weekToHalfMonth(sowStart, false), weekToHalfMonth(sowEnd, false));

    // If it's 24 half months then the start should be 1 as it's all year round
    if (sowLength >= 24) {
      sowLength = 24;
      sowStartHM = 1;
    }
  }

  if (plantEnd - plantStart >= WEEKS_IN_YEAR) {
    plantStartHM = 1;
    plantLength = 24;
  } else {
    plantStartHM = weekToHalfMonth(plantStart, true);
    plantLength = getDurationBetweenHalfMonths(weekToHalfMonth(plantStart, false), weekToHalfMonth(plantEnd, false));

    // If it's 24 half months then the start should be 1 as it's all year round
    if (plantLength >= 24) {
      plantLength = 24;
      plantStartHM = 1;
    }
  }

  if (harvestEnd - harvestStart >= WEEKS_IN_YEAR) {
    harvestStartHM = 1;
    harvestLength = 24;
  } else {
    harvestStartHM = weekToHalfMonth(harvestStart, true);
    harvestLength = getDurationBetweenHalfMonths(weekToHalfMonth(harvestStart, false), weekToHalfMonth(harvestEnd, false));

    // If it's 24 half months then the start should be 1 as it's all year round
    if (harvestLength >= 24) {
      harvestLength = 24;
      harvestStartHM = 1;
    }
  }

  const sows: Mutable<CalendarRange>[] = [];
  const plants: Mutable<CalendarRange>[] = [];
  const harvests: Mutable<CalendarRange>[] = [];

  if (sowLength > 0) {
    sows.push(createCalendarRange(sowStartHM, sowLength));
  }

  if (plantLength > 0) {
    plants.push(createCalendarRange(plantStartHM, plantLength));
  }

  if (harvestLength > 0) {
    harvests.push(createCalendarRange(harvestStartHM, harvestLength));
  }

  return createPlantingCalendarFromHalfMonthDateRanges(sows, plants, harvests, false, modifier);
};

export const calculateSplitSeasonCalendar = (
  plant: Plant,
  lastFrostDay: number,
  firstFrostDay: number,
  modifier: null | string = null,
  userSeasonExtenderSettings: UserSeasonExtenderSettings = DEFAULT_USER_SEASON_EXTENDER_SETTINGS
): PlantingCalendar => {
  const lastFrostModifier = getLastFrostModifier(modifier, userSeasonExtenderSettings);
  const firstFrostModifier = getFirstFrostModifier(modifier, userSeasonExtenderSettings);
  const lastFrostWeek = weekFromDay(lastFrostDay) + lastFrostModifier;
  const firstFrostWeek = weekFromDay(firstFrostDay) + firstFrostModifier;

  let sowStart = 0;
  let sowEnd = 0;

  let plantStart = 0;
  let plantEnd = 0;
  let harvestStart = 0;
  let harvestEnd = 0;

  if (plant.sow1Start !== null && plant.sow1End !== null) {
    sowStart = lastFrostWeek + plant.sow1Start;
    sowEnd = lastFrostWeek + plant.sow1End;
  }

  if (plant.plant1Start !== null && plant.plant1End !== null && plant.harvest1Start !== null && plant.harvest1End !== null) {
    plantStart = lastFrostWeek + plant.plant1Start;
    plantEnd = lastFrostWeek + plant.plant1End;
    harvestStart = lastFrostWeek + plant.harvest1Start;
    harvestEnd = lastFrostWeek + plant.harvest1End;
  }

  if (plant.plant2Start !== null && plant.harvest1Start !== null && plant.harvest1Start !== plant.harvest1End && plant.harvest1End !== null) {
    // Allows for planting relative to fall frost but harvest relative to spring frost e.g. bulbs
    harvestStart = lastFrostWeek + plant.harvest1Start;
    harvestEnd = lastFrostWeek + plant.harvest1End;
  }

  const sowStartHM = weekToHalfMonth(sowStart);
  const plantStartHM = weekToHalfMonth(plantStart);
  const harvestStartHM = weekToHalfMonth(harvestStart);

  const sowLength = getDurationBetweenHalfMonths(weekToHalfMonth(sowStart, false), weekToHalfMonth(sowEnd, false));
  const plantLength = getDurationBetweenHalfMonths(weekToHalfMonth(plantStart, false), weekToHalfMonth(plantEnd, false));
  const harvestLength = getDurationBetweenHalfMonths(weekToHalfMonth(harvestStart, false), weekToHalfMonth(harvestEnd, false));

  let sow2Start = 0;
  let sow2End = 0;
  let plant2Start = 0;
  let plant2End = 0;
  let harvest2Start = 0;
  let harvest2End = 0;

  if (plant.sow2Start !== null && plant.sow2End !== null) {
    sow2Start = firstFrostWeek + plant.sow2Start;
    sow2End = firstFrostWeek + plant.sow2End;
  }

  if (plant.plant2Start !== null && plant.plant2End !== null && plant.harvest2Start !== null && plant.harvest2End !== null) {
    plant2Start = firstFrostWeek + plant.plant2Start;
    plant2End = firstFrostWeek + plant.plant2End;
    harvest2Start = firstFrostWeek + plant.harvest2Start;
    harvest2End = firstFrostWeek + plant.harvest2End;
  }

  if (plant.plant1Start !== null && plant.harvest2Start !== null && plant.harvest2Start !== plant.harvest2End && plant.harvest2End !== null) {
    // Allows for planting relative to spring frost but harvest relative to fall frost e.g. fruit trees [NEW 2017!]
    harvest2Start = firstFrostWeek + plant.harvest2Start;
    harvest2End = firstFrostWeek + plant.harvest2End;
  }

  const sow2StartHM = weekToHalfMonth(sow2Start);
  const plant2StartHM = weekToHalfMonth(plant2Start);
  const harvest2StartHM = weekToHalfMonth(harvest2Start);

  const sow2Length = getDurationBetweenHalfMonths(weekToHalfMonth(sow2Start, false), weekToHalfMonth(sow2End, false));
  const plant2Length = getDurationBetweenHalfMonths(weekToHalfMonth(plant2Start, false), weekToHalfMonth(plant2End, false));
  const harvest2Length = getDurationBetweenHalfMonths(weekToHalfMonth(harvest2Start, false), weekToHalfMonth(harvest2End, false));

  const sows: Mutable<CalendarRange>[] = [];
  const plants: Mutable<CalendarRange>[] = [];
  const harvests: Mutable<CalendarRange>[] = [];

  if (sowLength > 0) {
    sows.push(createCalendarRange(sowStartHM, sowLength));
  }
  if (sow2Length > 0) {
    sows.push(createCalendarRange(sow2StartHM, sow2Length));
  }

  if (plantLength > 0) {
    plants.push(createCalendarRange(plantStartHM, plantLength));
  }

  if (plant2Length > 0) {
    plants.push(createCalendarRange(plant2StartHM, plant2Length));
  }

  if (harvestLength > 0) {
    harvests.push(createCalendarRange(harvestStartHM, harvestLength));
  }

  if (harvest2Length > 0) {
    harvests.push(createCalendarRange(harvest2StartHM, harvest2Length));
  }

  return createPlantingCalendarFromHalfMonthDateRanges(sows, plants, harvests, false, modifier);
};

export const calculateUsersPlantingCalendar = (
  plant: Plant,
  user: User,
  userRegionalPlantingCalendar: UserRegionalPlantingCalendar | null
): PlantingCalendar => {
  const { splitSeason } = user.settings.location;
  const firstFrostDay = user.settings.frostDates.first;
  const lastFrostDay = user.settings.frostDates.last;

  if (userRegionalPlantingCalendar && userRegionalPlantingCalendar[plant.code]) {
    // Second, regional planting dates get priority
    return userRegionalPlantingCalendar[plant.code].plantingCalendar;
  }

  if (splitSeason && plant.canSplitSeason) {
    return calculateSplitSeasonCalendar(plant, lastFrostDay, firstFrostDay);
  }

  return calculatePlantingCalendar(plant, lastFrostDay, firstFrostDay);
};

export const calculateExplicitPlantingCalendar = (
  plant: Plant,
  userPlantVariety: null | UserPlantVariety,
  lastFrostDay: number,
  firstFrostDay: number,
  splitSeason: boolean,
  modifier: string | null = null,
  userSeasonExtenderSettings: UserSeasonExtenderSettings = DEFAULT_USER_SEASON_EXTENDER_SETTINGS,
  userRegionalPlantingCalendar: UserRegionalPlantingCalendar | null = null
): PlantingCalendar => {
  if (userPlantVariety && userPlantVariety.customCalendar) {
    // First custom calendars and user plant varieties should get priority
    return createPlantingCalendarFromNumbers(userPlantVariety.plantingCalendar as unknown as NumberedPlantingCalendar, true, modifier); // TODO: Resolve type issue with UserPlantVariety
  }

  if (userRegionalPlantingCalendar && userRegionalPlantingCalendar[plant.code]) {
    // Second, regional planting dates get priority
    return calculateRegionalPlantingCalendar(plant.code, userRegionalPlantingCalendar, modifier, userSeasonExtenderSettings);
  }

  if (splitSeason && plant.canSplitSeason) {
    // Third, split season planting
    return calculateSplitSeasonCalendar(plant, lastFrostDay, firstFrostDay, modifier, userSeasonExtenderSettings);
  }
  // Fourth, normal planting calendar
  return calculatePlantingCalendar(plant, lastFrostDay, firstFrostDay, modifier, userSeasonExtenderSettings);
};

/**
 *  Returns planting time numbers, in the form [0, 0, 0, 0, 10, 20, 30, 33, 333, 0, 0, 0]
 */
export const calculateExplicitPlantingNumbers = (
  plant: Plant,
  userPlantVariety: null | UserPlantVariety,
  lastFrostDay: number,
  firstFrostDay: number,
  splitSeason: boolean,
  modifier: string | null = null,
  userSeasonExtenderSettings: UserSeasonExtenderSettings = DEFAULT_USER_SEASON_EXTENDER_SETTINGS,
  userRegionalPlantingCalendar: UserRegionalPlantingCalendar | null = null
): NumberedPlantingCalendar => {
  if (userPlantVariety && userPlantVariety.customCalendar) {
    // First custom calendars and user plant varieties should get priority
    return userPlantVariety.plantingCalendar as unknown as NumberedPlantingCalendar; // TODO: Resolve type issue with UserPlantVariety
  }

  if (userRegionalPlantingCalendar) {
    // Second, regional planting dates get priority
    return calculateRegionalPlantingNumbers(plant.code, userRegionalPlantingCalendar, modifier, userSeasonExtenderSettings);
  }

  if (splitSeason && plant.canSplitSeason) {
    // Third, split season planting
    return calculateSplitSeasonCalendar(plant, lastFrostDay, firstFrostDay, modifier, userSeasonExtenderSettings).numbered;
  }

  // Fourth, normal planting calendar
  return calculatePlantingCalendar(plant, lastFrostDay, firstFrostDay, modifier, userSeasonExtenderSettings).numbered;
};
