import React from 'react';
import GardenPlatformCore from '@gi/garden-platform-core';
import { NavigationProvider, Navigator } from '@gi/garden-platform-navigation';

import GardenPlannerIntro from './garden-planner-intro/garden-planner-intro';
import GardenPlatformContent from './garden-platform-content';

import './garden-platform.scss';

const GardenPlatform = (): JSX.Element => {
  return (
    <NavigationProvider>
      <GardenPlatformCore>
        <Navigator />
        <GardenPlannerIntro />
        <GardenPlatformContent />
      </GardenPlatformCore>
    </NavigationProvider>
  );
};

export default GardenPlatform;
