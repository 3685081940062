import React, { useContext } from 'react';

import { GuruPlantContentPage } from '@gi/app-guru-types/pages';
import { ResourceContext } from '@gi/resource-provider';

import PageContainer from '../page-container';

import PlantInformationSection from './display/plant-information-section';
import PlantOtherNamesSection from './display/plant-other-names-section';
import PlantPerennialSection from './display/plant-perennial-section';
import PlantSpacingSection from './display/plant-spacing-section';
import PlantSquareFootSection from './display/plant-square-foot-section';
import PlantingCalendarSection from './display/planting-calendar-section';
import PlantCompanionsSection from './display/plant-companions-section';
import ItemContentTable from '../../components/item-content-table/item-content-table';
import PlantContentHeader from './plant-content-header';
import Gallery from '../../components/gallery/gallery';
import Breadcrumbs from '../../components/breadcrumbs/breadcrumbs';
import RelatedContentSection from '../../components/related-content/related-content-section';
import GuruHeaderContentPortal from '../../header/guru-header-content-portal';

import ControlsBanner from '../../components/controls-banner/controls-banner';
import FavouriteButton from '../../components/controls-banner/favourite-button';
import BookmarkButton from '../../components/controls-banner/bookmark-button';

import styles from './plant-content-display.module.css';
import ContentPageContentColumn from '../../components/content-page/content-page-content-column';
import GuruContentContainer from '../../components/content/guru-content-container';
import ControlsBannerSection from '../../components/controls-banner/controls-banner-section';

interface iProps {
  guruPlant: GuruPlantContentPage;
}

const PlantContentDisplay = ({ guruPlant }: iProps): JSX.Element | null => {
  const { getPlant } = useContext(ResourceContext);
  const plant = getPlant(guruPlant.content.plantCode);

  if (plant === null) {
    // TODO - redirect? 404? Break?
    return null;
  }

  return (
    <PageContainer className={`${styles.plantContentDisplay}`}>
      <GuruHeaderContentPortal background={guruPlant.backgroundImage}>
        <PlantContentHeader plant={plant} />
      </GuruHeaderContentPortal>
      <div className='guru-section-breadcrumbs'>
        <div className='guru-narrow-content-width'>
          <Breadcrumbs />
        </div>
      </div>
      <div className='guru-section'>
        <GuruContentContainer>
          <ContentPageContentColumn className='guru-narrow-content-width'>
            {/* <LeadImage className='' alt='' src={plant.images.landscape} /> */}
            <ControlsBanner>
              <ControlsBannerSection>
                <FavouriteButton plantCode={guruPlant.content.plantCode} />
              </ControlsBannerSection>
              <ControlsBannerSection>
                <BookmarkButton contentUUID={guruPlant.contentUUID} />
              </ControlsBannerSection>
            </ControlsBanner>
            <ItemContentTable>
              <PlantOtherNamesSection plant={plant} />
              <PlantInformationSection plant={plant} property='soil' title='Soil' />
              <PlantInformationSection plant={plant} property='position' title='Position' />
              <PlantInformationSection plant={plant} property='frostTolerant' title='Frost Tolerant' />
              <PlantPerennialSection plant={plant} />
              <PlantInformationSection plant={plant} property='feeding' title='Feeding' />
              <PlantInformationSection plant={plant} property='sowPlant' title='Sow and Plant' />
              <PlantSpacingSection plant={plant} />
              <PlantSquareFootSection plant={plant} />
              <PlantCompanionsSection plant={plant} />
              <PlantInformationSection plant={plant} property='companions' title='Companion Notes' />
              <PlantInformationSection plant={plant} property='notes' title='Notes' />
              <PlantInformationSection plant={plant} property='harvesting' title='Harvesting' />
              <PlantInformationSection plant={plant} property='troubleshooting' title='Troubleshooting' />
              <PlantingCalendarSection plant={plant} />
            </ItemContentTable>
          </ContentPageContentColumn>
        </GuruContentContainer>
      </div>
      <div className='guru-section-alt'>
        <div className='guru-content-width guru-pad-content'>
          <Gallery
            items={[
              {
                content: plant.images.portrait,
                thumbnailSrc: plant.images.portrait,
                alt: plant.name,
              },
              {
                content: plant.images.landscape,
                thumbnailSrc: plant.images.landscape,
                alt: plant.name,
              },
            ]}
          />
        </div>
      </div>
      <RelatedContentSection url={guruPlant.relatedContentUrl} outerClassName='guru-section' innerClassName='guru-narrow-content-width guru-pad-content' />
    </PageContainer>
  );
};

export default PlantContentDisplay;
