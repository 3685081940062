import React, { useCallback, useMemo } from 'react';

import Modal from '@gi/modal';
import Plan, { PlanText, PlanUtils } from '@gi/plan';
import { getDistanceUnitsFromIsMetric } from '@gi/units';

import EditTextModalContent from './edit-text-modal-content';

interface iProps {
  plan: Plan;
  textItemID: number;
  editItemModalComplete: (updatedPlan: Plan) => void;
  closeModal: () => void;
}

const EditTextModal = ({ plan, textItemID, editItemModalComplete, closeModal }: iProps): JSX.Element | null => {
  /**
   * Find the text in the plan. This *can* return null, but it really shouldn't if our props are correct.
   */
  const planText = useMemo<PlanText | null>(() => {
    return plan.text[textItemID] ?? null;
  }, [plan, textItemID]);

  /**
   * Get the user's distance units
   */
  const distanceUnits = useMemo(() => {
    return getDistanceUnitsFromIsMetric(plan.plannerSettings.metric);
  }, [plan]);

  /**
   * Update the plan on complete and send the updated plan back out.
   */
  const onComplete = useCallback(
    (updatedPlanText: PlanText) => {
      editItemModalComplete(PlanUtils.updatePlanText(plan, updatedPlanText));
    },
    [plan]
  );

  /**
   * Error if we don't have a valid PlanText
   */
  if (!planText) {
    console.error(`Tried to open EditTextModal without a valid PlanText. ID ${textItemID}, PlanID: ${plan.id}`);
    return null;
  }

  return (
    <Modal closeRequest={closeModal} style={{ minWidth: 'min(480px, 100%)' }}>
      <EditTextModalContent distanceUnits={distanceUnits} planText={planText} onComplete={onComplete} />
    </Modal>
  );
};

export default EditTextModal;
