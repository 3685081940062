/* eslint-disable no-bitwise */

const MAX_FLAG_NUMBER = 30;

/**
 * Slight hack to allow type inference
 * This allows us to store the type of the flags that make up the bitmask alongside the value of the bitmask.
 * __bitmask should never be accessed, and is always undefined, but it allows TS to infer the flags that make up the bitmask value.
 */
declare const __bitmask: unique symbol;

/** Flat value of a bitmask. This functions as a branded type to allow TS to infer the flag types */
export type BitmaskType<T extends number> = number & { [__bitmask]?: T };

/** Comparison functions for bitmasks */
export enum BitmaskComparisonMode {
  /** Are the 2 masks exactly equal */
  Equals = 'Equals',
  /** Does the first mask contain any overlap with the second */
  ContainsAny = 'ContainsAny',
  /** Does the first mask contain full overlap with the second (can include extra) */
  ContainsAll = 'ContainsAll',
  /** Not of Equals */
  NotEquals = 'NotEquals',
  /** Not of ContainsAny */
  NotContainsAny = 'NotContainsAny',
  /** Not of ContainsAll */
  NotContainsAll = 'NotContainsAll',
}

class Bitmask<T extends number = number> {
  static ALL = Bitmask.From(2 ** MAX_FLAG_NUMBER - 1);
  static NONE = Bitmask.From(0);

  #mask: BitmaskType<T> = 0;

  /** The numeric value of this bitmask */
  get mask() {
    return this.#mask;
  }
  protected set mask(mask: number) {
    this.#mask = mask;
  }
  get value() {
    return this.#mask;
  }

  /**
   * Creates a new bitmask. Defaults to NONE if no values given.
   * @param initialValue The initial flags to set
   */
  constructor(...flags: T[]) {
    this.mask = 0;
    this.set(...flags);
  }

  /**
   * Sets the value of this mask to only contain the given flags
   * @param flags The flags to set
   */
  set(...flags: T[]): this {
    this.mask = Bitmask.Create(...flags);
    return this;
  }

  /**
   * Clears the value of this mask, setting all flags to 0.
   */
  clear(): this {
    this.mask = 0;
    return this;
  }

  /**
   * Adds the given flags to this mask
   * @param flags The flag(s) to add to this mask
   */
  add(...flags: T[]): this {
    this.mask = Bitmask.Add(this.mask, ...flags);
    return this;
  }

  /**
   * Removes the given flags from this mask
   * @param flags The flag(s) to remove from this mask
   */
  remove(...flags: T[]): this {
    this.mask = Bitmask.Remove(this.mask, ...flags);
    return this;
  }

  /**
   * Toggles the given flags, setting 0s to 1s and 1s to 0s.
   * @param flags The flag(s) to toggle from this mask
   */
  toggle(...flags: T[]): this {
    this.mask = Bitmask.Toggle(this.mask, ...flags);
    return this;
  }

  /**
   * Checks if this mask is the same as another mask
   * @param otherMask The other mask to check
   * @returns True of the 2 masks are identical
   */
  equals(otherMask: Bitmask<T>): boolean {
    return this.mask === otherMask.mask;
  }

  /**
   * Checks if this mask contains all of the other mask
   * @param otherMask The mask to check for
   */
  contains(otherMask: Bitmask<T>): boolean;
  /**
   * Checks if this mask contains all of the given flags
   * @param flags The flags to check for
   */
  contains(...flags: T[]): boolean;
  contains(otherMaskOrFlag: Bitmask<T> | T, ...remainingFlags: T[]): boolean {
    if (otherMaskOrFlag instanceof Bitmask) {
      return Bitmask.Contains(this.#mask, otherMaskOrFlag.mask);
    }
    const newOtherMask = Bitmask.Create(otherMaskOrFlag, ...remainingFlags);
    return Bitmask.Contains(this.mask, newOtherMask);
  }

  /**
   * Checks if this mask contains any of the other mask
   * @param otherMask The mask to check for
   */
  containsAny(otherMask: Bitmask<T>): boolean;
  /**
   * Checks if this mask contains any of the given flags
   * @param flags The flags to check for
   */
  containsAny(...flags: T[]): boolean;
  containsAny(otherMaskOrFlag: Bitmask<T> | T, ...remainingFlags: T[]): boolean {
    if (otherMaskOrFlag instanceof Bitmask) {
      return Bitmask.ContainsAny(this.mask, otherMaskOrFlag.mask);
    }
    const newOtherMask = Bitmask.Create(otherMaskOrFlag, ...remainingFlags);
    return Bitmask.ContainsAny(this.mask, newOtherMask);
  }

  /**
   * Creates a copy of this bitmask, with the same mask value.
   * @returns A new bitmask
   */
  copy(): Bitmask<T> {
    return Bitmask.From(this);
  }

  toString() {
    let bitString: string = '';
    for (let i = 1; i <= MAX_FLAG_NUMBER; i++) {
      const isPresent = Bitmask.Contains(this.mask, Bitmask.toBit(i));
      if (i % 8 === 1 && i !== 1) {
        bitString += ' ';
      }
      bitString += isPresent ? '1' : '0';
    }
    return `(${this.mask}) |${bitString}|`;
  }

  /**
   * Creates a new mask, containing all of the given flags.
   * @param flags The flags to include in the new mask
   * @returns A new mask
   */
  static Create<T extends number>(...flags: T[]): BitmaskType<T> {
    return Bitmask.Add(0, ...flags);
  }

  /**
   * Adds the given flags to the mask, returning a new mask
   * @param mask The mask to add flags to
   * @param flags The flags to add
   * @returns A new mask, with the given flags added
   */
  static Add<T extends number>(mask: BitmaskType<T>, ...flags: T[]): BitmaskType<T> {
    for (let i = 0; i < flags.length; i++) {
      mask |= Bitmask.toBit(flags[i]);
    }
    return mask;
  }

  /**
   * Removes the given flags from the mask, returning a new mask
   * @param mask The mask to remove flags from
   * @param flags The flags to remove
   * @returns A new mask, with the given flags removed
   */
  static Remove<T extends number>(mask: BitmaskType<T>, ...flags: T[]): BitmaskType<T> {
    for (let i = 0; i < flags.length; i++) {
      mask &= ~Bitmask.toBit(flags[i]);
    }
    return mask;
  }

  /**
   * Toggles the given flags, adding to the mask if not present, or removing if present.
   * @param mask The mask to toggle flags in
   * @param flags The flags to toggle
   * @returns A new mask, with the given flags toggled on/off
   */
  static Toggle<T extends number>(mask: BitmaskType<T>, ...flags: T[]): BitmaskType<T> {
    for (let i = 0; i < flags.length; i++) {
      mask ^= Bitmask.toBit(flags[i]);
    }
    return mask;
  }

  /**
   * Sets the given flags as on/off, depending on the enabled argument
   * @param mask The mask to set flags on
   * @param enabled Whether the flags should be turned on or off
   * @param flags The flags to set
   * @returns A new mask, with the given flags set on/off
   */
  static Set<T extends number>(mask: BitmaskType<T>, enabled: boolean, ...flags: T[]): BitmaskType<T> {
    if (enabled) {
      return Bitmask.Add(mask, ...flags);
    }
    return Bitmask.Remove(mask, ...flags);
  }

  /**
   * Inverts a bitmask
   * @param mask The mask to invert
   * @returns An inverted copy of the mask
   */
  static Invert<T extends number>(mask: BitmaskType<T>): BitmaskType<T> {
    return mask ^ Bitmask.ALL.value;
  }

  /**
   * Checks if the given mask contains all the given flags
   * @param mask The mask to check
   * @param flags The flags to find in the mask
   * @returns True if all flags are found
   */
  static ContainsFlag<T extends number>(mask: BitmaskType<T>, ...flags: T[]): boolean {
    return Bitmask.Contains(mask, Bitmask.Create(...flags));
  }

  /**
   * Checks if the given mask contains the other mask.
   * @param mask The mask to check in
   * @param searchFor The flags to search for (as a bitmask)
   * @returns True if mask contains ALL of searchFor, false if any are missing
   */
  static Contains<T extends number>(mask: BitmaskType<T>, searchFor: BitmaskType<T> | number): boolean {
    return (mask & searchFor) === searchFor;
  }

  /**
   * Checks if the given mask contains any of the other mask (any overlap)
   * @param mask The mask to check in
   * @param searchFor The flags to search for (as a bitmask)
   * @returns True if mask contains ANY of searchFor, false if all are missing
   */
  static ContainsAny<T extends number>(mask: BitmaskType<T>, searchFor: BitmaskType<T> | number): boolean {
    return (mask & searchFor) > 0;
  }

  /**
   * Converts a flag to its bitwise representation (2^flag)
   *
   * Performs checks to make sure the flag is within acceptable range.
   * @param flag The flag to convert
   * @returns 2 exponentialized by flag
   */
  static toBit(flag: number) {
    if (!Number.isInteger(flag)) {
      throw new Error('Bitwise flag must be an integer');
    }
    if (flag < 0 || flag > MAX_FLAG_NUMBER) {
      // JavaScript bitwise uses 32-bit, so anything greater will cause weird results.
      throw new Error(`Bitwise flag must be between 0-${MAX_FLAG_NUMBER} (${flag} given)`);
    }
    return 2 ** flag;
  }

  /**
   * Creates a new bitmask, with the value of mask being the given number or bitmask
   *
   * No transformations are done on the mask.
   * @param mask The numeric value of the mask
   * @returns A new bitmask
   */
  static From<T extends number = number>(mask: Bitmask<T> | number): Bitmask<T> {
    const bitmask = new Bitmask<T>();
    bitmask.mask = mask instanceof Bitmask ? mask.mask : mask;
    return bitmask;
  }

  /**
   * Compares a bitmask against another bitmask, using the comparison method given
   * @param mask The mask to check
   * @param searchFor What to look for in the mask (as a bitmask)
   * @param comparison What comparison mode to use
   * @returns True if the comparison passes, false if not
   */
  static Compare<T extends number = number>(mask: BitmaskType<T>, searchFor: BitmaskType<T> | number, comparison: BitmaskComparisonMode): boolean {
    switch (comparison) {
      case BitmaskComparisonMode.Equals:
        return mask === searchFor;
      case BitmaskComparisonMode.ContainsAny:
        return Bitmask.ContainsAny(mask, searchFor);
      case BitmaskComparisonMode.ContainsAll:
        return Bitmask.Contains(mask, searchFor);
      case BitmaskComparisonMode.NotEquals:
        return mask !== searchFor;
      case BitmaskComparisonMode.NotContainsAny:
        return !Bitmask.ContainsAny(mask, searchFor);
      case BitmaskComparisonMode.NotContainsAll:
        return !Bitmask.Contains(mask, searchFor);
      default:
        console.error(`Unknown bitmask comparison mode: ${comparison}`);
        return false;
    }
  }
}

export default Bitmask;
