import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ElementPrinterContext } from '@gi/element-printer';
import { runListFilters } from '@gi/filters';
import Plan from '@gi/plan';
import { collectionNotNull, ResourceContext } from '@gi/resource-provider';
import { SessionSelectors } from '@gi/react-session';
import { DEFAULT_VARIETY } from '@gi/constants';
import { PlantSearchService, PlantVarietySearchService } from '@gi/search-service';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';

import LocalPlantListContext, { LocalPlantListContextType } from './local-plant-list-context';
import { createPlantListData } from '../data/create-plant-list-data';
import { PlantListAreaGroup, PlantListData, PlantListSummaryGroup, PlantListVarietyGroup } from '../types/plant-list-types';
import GlobalPlantListContext from '../global-provider/global-plant-list-context';
import { DISPLAY_TYPE } from '../constants';
import { createAreaCSVString, createSummaryCSVString, createVarietyCSVString } from '../csv/create-csv-strings';

function download(filename, text) {
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(text)}`);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

interface iProps {
  children: React.ReactNode;
  plan: Plan | null;
}

const LocalPlantListProvider = ({ children, plan }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [plantTableElement, setPlantTableElement] = useState<HTMLElement | null>(null);
  const { userPlants, getPlantingCalendar, userDistanceUnits } = useContext(ResourceContext);
  const notNullPlants = collectionNotNull(userPlants);
  const { plantGroupFilters, varietyGroupFilters, areaGroupFilters, options, columns, displayType } = useContext(GlobalPlantListContext);

  const user = useSelector(SessionSelectors.getUser);
  const northernHemisphere = useSelector(SessionSelectors.getIsNorthernHemisphere);

  const { printElement, isPrinting } = useContext(ElementPrinterContext);

  const plantListData = useMemo<PlantListData | null>(() => {
    if (user === null || plan === null) {
      return null;
    }

    return createPlantListData(plan, notNullPlants, user.plantVarieties, getPlantingCalendar);
  }, [plan, user]);

  const plantListSearchService = useMemo<PlantSearchService>(() => {
    return new PlantSearchService(plantListData?.plants.map((item) => item.plant) ?? []);
  }, [plantListData]);

  const plantVarietySearchService = useMemo<PlantVarietySearchService>(() => {
    return new PlantVarietySearchService(
      plantListData?.varieties
        .filter((item) => item.variety !== DEFAULT_VARIETY)
        .map((item) => {
          return {
            name: item.variety,
            plant: item.plant,
            code: `${item.plant.code}-${item.variety}`,
          };
        }) ?? []
    );
  }, [plantListData]);

  const filteredPlantGroupData = useMemo<readonly PlantListSummaryGroup[]>(() => {
    if (plantListData === null) {
      return [];
    }

    return runListFilters(plantGroupFilters, plantListData.plants);
  }, [plantGroupFilters, plantListData]);

  const filteredVarietyGroupData = useMemo<readonly PlantListVarietyGroup[]>(() => {
    if (plantListData === null) {
      return [];
    }

    return runListFilters(varietyGroupFilters, plantListData.varieties);
  }, [varietyGroupFilters, plantListData]);

  const filteredAreaGroupData = useMemo<readonly PlantListAreaGroup[]>(() => {
    if (plantListData === null) {
      return [];
    }

    return runListFilters(areaGroupFilters, plantListData.areas);
  }, [areaGroupFilters, plantListData]);

  const printPlantList = useCallback(() => {
    if (plantTableElement !== null) {
      printElement(plantTableElement);
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ExportPlantsList, { planId: plan?.id ?? -1 }));
    }
  }, [printElement, plantTableElement, plan?.id]);

  const planID = plan === null ? null : plan.id;

  const createCSV = useCallback(() => {
    let csvString = '';
    if (displayType === DISPLAY_TYPE.Area) {
      csvString = createAreaCSVString(filteredAreaGroupData, userDistanceUnits, columns, options, northernHemisphere);
    } else if (displayType === DISPLAY_TYPE.Variety) {
      csvString = createVarietyCSVString(filteredVarietyGroupData, userDistanceUnits, columns, options, northernHemisphere);
    } else {
      csvString = createSummaryCSVString(filteredPlantGroupData, userDistanceUnits, columns, options, northernHemisphere);
    }

    download('plant-list.csv', csvString);
  }, [displayType, filteredAreaGroupData, filteredVarietyGroupData, filteredPlantGroupData, userDistanceUnits, columns, options]);

  const value = useMemo<LocalPlantListContextType>(() => {
    return {
      planID,
      showFilters,
      setShowFilters,
      plantListData,
      filteredPlantGroupData,
      filteredVarietyGroupData,
      filteredAreaGroupData,
      setPlantTableElement,
      printPlantList,
      createCSV,
      isPrinting,
      plantListSearchService,
      plantVarietySearchService,
    };
  }, [
    planID,
    showFilters,
    setShowFilters,
    plantListData,
    filteredPlantGroupData,
    filteredVarietyGroupData,
    filteredAreaGroupData,
    printPlantList,
    createCSV,
    isPrinting,
    plantTableElement,
    plantListSearchService,
    plantVarietySearchService,
  ]);

  return <LocalPlantListContext.Provider value={value}>{children}</LocalPlantListContext.Provider>;
};

export default LocalPlantListProvider;
