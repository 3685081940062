import React, { useEffect, useMemo, useState } from 'react';

import { LoadingState } from '@gi/constants';
import { LoadingScreen, LoadingScreenAsyncResource, LoadingScreenErrorMessage } from '@gi/loading-screen';

import { LoadingScreenResource, getOverallLoadingScreenResourceState } from './utilities';

interface iProps {
  resources: LoadingScreenResource[];
}

const ResourceLoaderLoadingScreen = ({ resources }: iProps): JSX.Element => {
  const [hasErrored, setHasErrored] = useState(false);

  /**
   * Once we've errored once, always display the error message.
   * This prevents the error message flashing if someone retries to load a resource.
   */
  useEffect(() => {
    if (getOverallLoadingScreenResourceState(resources) === LoadingState.ERROR) {
      setHasErrored(true);
    }
  }, [resources]);

  /**
   * Displays as a warning if any of the critical resources failed to load
   */
  const loadingExplanation = useMemo(() => {
    if (!hasErrored) {
      return <p>Loading required resources...</p>;
    }

    return (
      <LoadingScreenErrorMessage>
        <p>Failed to load some required data. Please check your internet connection.</p>
        <p>
          If you have any ad-blockers installed, please disable them for this site and reload the Garden Planner. (We don&apos;t show ads but some ad-blockers
          incorrectly block the Garden Planner).
        </p>
        <p>
          If this does not resolve the issue please{' '}
          <a href='/contact.aspx' target='_blank' rel='noreferrer'>
            contact us
          </a>
          .
        </p>
      </LoadingScreenErrorMessage>
    );
  }, [hasErrored]);

  return (
    <LoadingScreen>
      {loadingExplanation}
      {resources.map((resource) => (
        <LoadingScreenAsyncResource resource={resource.resource} label={resource.label} key={resource.label} />
      ))}
    </LoadingScreen>
  );
};

export default ResourceLoaderLoadingScreen;
