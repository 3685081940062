import { AnyAction } from 'redux';
import { DEFAULT_VISIBLE_DURATION } from './notification-constants';

/**
 * Enum for the different notification types
 */
export enum NotificationTypes {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  HELP = 'HELP',
}

/**
 * Enum for different notification action types. Changes button styling
 */
export enum NotificationActionType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  HELP = 'HELP',
}

export type NotificationAction = {
  title: string;
  action?: AnyAction;
  noDismiss?: boolean;
  type?: NotificationActionType;
};

export type Notification = {
  ID: number;
  title: string;
  text: string;
  type: NotificationTypes;
  visible: boolean;
  visibleDuration: number;
  timeoutTime: number | null;
  icon: string | null;
  canTimeout: boolean;
  isTimingOut: boolean;
  creationTime: number;
  templateData: any;
  inProgress: boolean;
  actions?: NotificationAction[];
};

export const DEFAULT_NOTIFICATION_OPTIONS: Omit<Notification, 'ID'> = {
  title: '',
  text: '',
  type: NotificationTypes.SUCCESS,
  visible: true,
  visibleDuration: DEFAULT_VISIBLE_DURATION,
  timeoutTime: null,
  icon: null,
  canTimeout: true,
  isTimingOut: true,
  creationTime: 0,
  templateData: {},
  inProgress: false,
};

export default Notification;
