import { createListenerMiddleware } from '@reduxjs/toolkit';

import { DisplayModeState } from '@gi/display-mode-provider';
import { SessionReducerState } from '@gi/react-session';
import { LocalSettings } from '@gi/local-settings';
import { CanvasReducerState } from '@gi/react-garden-canvas/source/redux-components/canvas-reducer';

import { AnalyticsActions } from './analytics-slice';
import { sendEventAnalytics } from './event-analytics';
import { sendVideoAnalytics } from './video-analytics';
import { getDeviceAnalyticsData } from './device-analytics';

interface MiddlewareRootState {
  displayMode: DisplayModeState;
  session: SessionReducerState;
  localSettings: LocalSettings;
  canvas: CanvasReducerState;
}

// As the analytics system needs access to other slices, it needs to be a middleware.
const _analyticsMiddleware = createListenerMiddleware<MiddlewareRootState>();

// Listen for analytics events and post the data to the server, injecting any extra information.
_analyticsMiddleware.startListening({
  actionCreator: AnalyticsActions.analyticsEvent,
  effect: (event, api) => {
    const state = api.getState();
    const { user } = state.session;

    if (!user) {
      console.error('🧮 Attempted to send analytics event with no user.');
      return;
    }

    let data = event.payload.eventData;
    if (event.payload.extraData) {
      if (typeof data === 'string') {
        console.error('🧮 Cannot include extra information with a string event payload.');
      } else {
        if (event.payload.extraData.displayMode) {
          data = {
            ...data,
            displayMode: state.displayMode.deviceDisplayMode,
            suggestedDisplayMode: state.displayMode.bestDeviceDisplayMode,
          };
        }
        if (event.payload.extraData.version) {
          data = {
            ...data,
            version: VERSION,
          };
        }
        if (event.payload.extraData.deviceData) {
          data = {
            ...data,
            ...getDeviceAnalyticsData(),
          };
        }
      }
    }

    const eventDataJson = data !== undefined ? JSON.stringify(data) : undefined;

    console.debug(`🧮 Send Analytics Event
\t- Event: '${event.payload.eventName}'
\t- Event Data: ${eventDataJson}${
      event.payload.extraData?.displayMode
        ? `}
\t- Display: ${state.displayMode.deviceDisplayMode} (recommended: ${state.displayMode.bestDeviceDisplayMode})`
        : ''
    }`);

    sendEventAnalytics(user, event.payload.eventName, eventDataJson).catch(() => {
      /* Do nothing, error is already reported by network service */
    });
  },
});

// Listen for video analytics events and post the data to the server
_analyticsMiddleware.startListening({
  actionCreator: AnalyticsActions.analyticsVideoEvent,
  effect: (event, api) => {
    const state = api.getState();
    const { user } = state.session;

    if (!user) {
      console.error('🧮 Attempted to send analytics event with no user.');
      return;
    }

    console.debug(`🧮 Send Video Analytics Event
\t- Video: ${event.payload.videoName}
\t- Video Length: ${event.payload.videoLength}
\t- Video Watches: (${event.payload.videoWatches.length} entries)`);

    sendVideoAnalytics(user, event.payload).catch(() => {
      /* Do nothing, error is already reported by network service */
    });
  },
});

export const analyticsMiddleware = _analyticsMiddleware.middleware;
