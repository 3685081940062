import { Map, Record, List } from 'immutable';

const _JournalActions = {
  byDate: new Map(),
  dates: new List(),
  byID: new Map(),
  IDs: new List(),
  byActionType: new Map(),
  actionTypes: new List(),
};

class JournalActions extends Record(_JournalActions) {
  /**
   * Returns an array of Actions for the given day
   *
   * @param {string} dateYMD
   * @returns {journalAction[]}
   * @memberof Journal
   */
  getActionsForDate(dateYMD) {
    if (!this.hasDate(dateYMD)) {
      return [];
    }

    return this.byDate
      .get(dateYMD)
      .map((ID) => this.byID.get(ID))
      .toArray();
  }

  getActionsForType(actionType) {
    if (!this.hasActionType(actionType)) {
      return [];
    }

    return this.byActionType
      .get(actionType)
      .map((ID) => this.byID.get(ID))
      .toArray();
  }

  /**
   * Returns the number of actions in this collection
   *
   * @returns {number}
   * @memberof Journal
   */
  getCount() {
    return this.IDs.size;
  }

  /**
   * Returns true if a JournalActions with the given ID is present in this collection, else false
   *
   * @param {number} ID
   * @returns {boolean}
   * @memberof Journal
   */
  hasID(ID) {
    return this.IDs.contains(ID);
  }

  /**
   * Returns true if this set of actions has actions for the given day
   *
   * @param {string} dateYMD
   * @returns {boolean}
   * @memberof Journal
   */
  hasDate(dateYMD) {
    return this.byDate.has(dateYMD);
  }

  hasActionType(actionType) {
    return this.byActionType.has(actionType);
  }

  /**
   * Returns a new JournalActions instance with this action added
   *
   * @param {JournalAction} action
   * @returns {JournalActions}
   * @memberof Journal
   */
  addAction(action) {
    let byDate;
    let byActionType;
    let me = this;
    if (me.hasDate(action.dateYMD)) {
      byDate = me.byDate.get(action.dateYMD).push(action.ID);
    } else {
      byDate = new List([action.ID]);
      me = me.set('dates', me.dates.push(action.dateYMD));
    }

    if (me.hasActionType(action.actionType)) {
      byActionType = me.byActionType.get(action.actionType).push(action.ID);
    } else {
      byActionType = new List([action.ID]);
      me = me.set('actionTypes', me.actionTypes.push(action.actionType));
    }

    return me
      .setIn(['byActionType', action.actionType], byActionType)
      .setIn(['byDate', action.dateYMD], byDate)
      .setIn(['byID', action.ID], action)
      .set('IDs', me.IDs.push(action.ID));
  }

  /**
   * Removes an action from the collection with the given ID
   *
   * @param {number} ID
   * @returns {JournalActions}
   * @memberof Journal
   */
  removeAction(ID) {
    if (!this.hasID(ID)) {
      console.warn("Asked to remove action from collection but it wasn't present");
      return this;
    }

    const action = this.byID.get(ID);
    const IDIndex = this.IDs.indexOf(ID);
    const countForDate = this.byDate.get(action.dateYMD).size;

    if (countForDate <= 1) {
      const dateIndex = this.dates.indexOf(action.dateYMD);
      // There's only one action for this date so we need to remove this date too
      return this.set('IDs', this.IDs.delete(IDIndex))
        .set('byID', this.byID.delete(ID))
        .set('dates', this.dates.delete(dateIndex))
        .set('byDate', this.byDate.delete(action.dateYMD));
    }

    const byDateIndex = this.byDate.get(action.dateYMD).indexOf(action.ID);

    return this.set('IDs', this.IDs.delete(IDIndex))
      .set('byID', this.byID.delete(ID))
      .setIn(['byDate', action.dateYMD], this.byDate.get(action.dateYMD).delete(byDateIndex));
  }

  /**
   * Returns a new JournalActions collection with the action added or updated if not already present
   *
   * @param object action
   * @returns {JournalActions}
   * @memberof Journal
   */
  updateAction(action) {
    if (this.IDs.includes(action.ID)) {
      // It's easiest to remove the old action and add the new one
      // We can add some checks in future to see if they're the same
      return this.removeAction(action.ID).addAction(action);
    }

    return this.addAction(action);
  }

  /**
   * Returns an array of date YMD strings used in this set of actions
   *
   * @returns {string[]}
   * @memberof Journal
   */
  getDatesArray() {
    return this.dates.toJS();
  }

  /**
   * Returns an array of action types used in this set of actions
   *
   * @returns {string[]}
   * @memberof Journal
   */
  getActionTypesArray() {
    return this.actionTypes.toJS();
  }
}

export default JournalActions;
