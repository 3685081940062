import React, { useState } from 'react';

import { Tooltip, TooltipContent, TooltipTrigger } from '@gi/tooltip';
import { FormSubtext, FormSubtextType } from '@gi/form-responsive';

import './find-location.scss';

interface iProps {
  onLocationFound: (latitude: number, longitude: number) => void;
}

const FindLocation = ({ onLocationFound }: iProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getLocation = () => {
    setErrorMessage(null);
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          onLocationFound(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          if (error.PERMISSION_DENIED) {
            setErrorMessage('We need permission to use your location');
          } else if (error.POSITION_UNAVAILABLE) {
            setErrorMessage('Unable to find your location');
          } else {
            setErrorMessage('Unable to find your location');
          }
        }
      );
    } catch (e) {
      setErrorMessage('Error. Your browser may not support location services.');
    }
  };

  return (
    <div className='find-location-container'>
      <div>
        <button className='button button-primary find-location-button' type='button' onClick={getLocation}>
          Find Location
        </button>
        <Tooltip placement='top'>
          <TooltipTrigger>
            <button className='button button-inline find-location-hint' type='button'>
              What&apos;s this?
            </button>
          </TooltipTrigger>
          <TooltipContent>
            Uses your device to find your location - accuracy depends on the computer or device you are using (requires browser permission)
          </TooltipContent>
        </Tooltip>
      </div>
      {errorMessage && (
        <FormSubtext type={FormSubtextType.Error} className='find-location-error-text'>
          <i className='icon-attention-alt' />
          {errorMessage}
        </FormSubtext>
      )}
    </div>
  );
};

export default FindLocation;
