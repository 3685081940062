import React, { ReactNode, useMemo } from 'react';

import ProgressObjectiveIcon from '../icon/progress-objective-icon';

import styles from './overview-styles.module.css';

interface iProps {
  title: string;
  description: string;
  icon?: string;
  objectiveCount?: number;
  completedObjectiveCount?: number;
  minimised?: boolean;
  children?: ReactNode;
}

const ObjectiveGroupOverviewCard = ({
  title,
  description,
  icon,
  objectiveCount,
  completedObjectiveCount,
  minimised = false,
  children,
}: iProps): JSX.Element => {
  const percent = useMemo(() => {
    if (objectiveCount !== undefined && completedObjectiveCount !== undefined) {
      return completedObjectiveCount / objectiveCount;
    }
    return 0;
  }, [objectiveCount, completedObjectiveCount]);

  const label = useMemo(() => {
    if (objectiveCount !== undefined && completedObjectiveCount !== undefined) {
      return `${completedObjectiveCount}/${objectiveCount}`;
    }
    return undefined;
  }, [objectiveCount, completedObjectiveCount]);

  const allObjectivesCompleted = completedObjectiveCount === objectiveCount;

  return (
    <div className={`${styles.overviewCard} ${styles.objectiveGroupCard} ${allObjectivesCompleted ? styles.completedGroupOverviewCard : ''}`}>
      <div className={styles.overviewCardIcon}>
        <ProgressObjectiveIcon src={icon} percent={percent} label={label} />
      </div>
      <div className={styles.overviewCardContent} hidden={minimised}>
        <p className={styles.overviewCardTitle}>{title}</p>
        <p className={styles.overviewCardDescription}>{description}</p>
      </div>
      {children}
    </div>
  );
};

export default ObjectiveGroupOverviewCard;
