import React, { ReactNode } from 'react';

import './loading-screen-error-message.scss';

interface iProps {
  children: ReactNode;
  className?: string;
}

const LoadingSrceenErrorMessage = ({ children, className }: iProps): JSX.Element => {
  const classNames = ['loading-screen-error-message'];
  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default LoadingSrceenErrorMessage;
