import { createSpriteSource } from '@gi/sprite-source';
import Plant from './plant';

// TODO: App API plant object type
const plantFromObject = (obj: any): Plant => {
  const tags = {};

  for (let i = 0; i < obj.tags.length; i++) {
    tags[obj.tags[i]] = true;
  }

  return new Plant({
    ID: obj.plantID,
    code: obj.plantCode,
    name: obj.plantName,
    otherName: obj.otherName,
    countryCode: obj.countryCode,
    spacing: obj.spacing,
    inRowSpacing: obj.inRowSpacing,
    rowSpacing: obj.rowSpacing,
    familyID: obj.family,
    scientificName: obj.latinName,
    otherScientificNames: obj.otherLatinNames,
    searchNames: obj.searchNames,
    sprite: createSpriteSource({
      name: obj.sprite.filename.substring(0, obj.sprite.filename.length - 4),
      width: obj.sprite.width,
      height: obj.sprite.height,
    }),
    icon: {
      name: obj.sprite.filename.substring(0, obj.sprite.filename.length - 4),
      filename: obj.sprite.filename,
      width: obj.sprite.width,
      height: obj.sprite.height,
    },
    companionPlantCodes: obj.companionPlantCodes,
    canBeSquareFootPlant: obj.canBeSquareFootPlant,
    squareFootPlantCount: obj.squareFootNoOfPlants,
    plantRelativeLastFrost: obj.prlf,
    sowStartIndoors: obj.ssi === 99 ? null : obj.ssi,
    lastSowRelativeToLastFrost: obj.lssrlf,
    lastPlantingTime: obj.lpt,
    timeToMaturity: obj.ttm,
    harvestRelativeToFirstFrost: obj.hrff,
    canSplitSeason: obj.canSplitSeason,
    sow1Start: obj.s1start === 99 ? null : obj.s1start,
    sow1End: obj.s1end === 99 ? null : obj.s1end,
    plant1Start: obj.p1start === 99 ? null : obj.p1start,
    plant1End: obj.p1end === 99 ? null : obj.p1end,
    harvest1Start: obj.h1start === 99 ? null : obj.h1start,
    harvest1End: obj.h1end === 99 ? null : obj.h1end,
    sow2Start: obj.s2start === 99 ? null : obj.s2start,
    sow2End: obj.s2end === 99 ? null : obj.s2end,
    plant2Start: obj.p2start === 99 ? null : obj.p2start,
    plant2End: obj.p2end === 99 ? null : obj.p2end,
    harvest2Start: obj.h2start === 99 ? null : obj.h2start,
    harvest2End: obj.h2end === 99 ? null : obj.h2end,
    perennial: obj.perennial,
    text: {
      soil: obj.soil,
      position: obj.position,
      sowPlant: obj.sowPlant,
      frostTolerant: obj.frostTolerant,
      feeding: obj.feeding,
      notes: obj.notes,
      harvesting: obj.harvesting,
      companions: obj.companions,
      troubleshooting: obj.troubleshooting,
    },
    images: {
      portrait: obj.imageURLPortrait,
      landscape: obj.imageURLLandscape,
    },
    tags,
    isNew: obj.isNew,
  });
};

export default plantFromObject;
