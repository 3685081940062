import React, { useMemo, useState } from 'react';

import { FadedScrollContainer } from '@gi/mobile-components';

import GalleryThumbnail from './gallery-thumbnail';
import { GalleryItem } from './gallery-types';
import ImageCaption from '../image/image-caption';

import styles from './gallery.module.css';

interface iProps {
  items: GalleryItem[];
}

const Gallery = ({ items }: iProps): JSX.Element => {
  const [activeItemId, setActiveItemId] = useState<number>(0);

  const activeItem = useMemo(() => {
    if (items.length === 0) {
      return null;
    }
    if (activeItemId > items.length) {
      return items[items.length - 1];
    }
    if (activeItemId < 0) {
      return items[0];
    }
    return items[activeItemId];
  }, [items, activeItemId]);

  return (
    <div className={styles.gallery}>
      {/* Controls to step left/right through the gallery */}
      <div className={styles.galleryControls}>
        <button onClick={() => setActiveItemId(activeItemId - 1)} disabled={activeItemId <= 0} type='button'>
          <i className='icon-angle-left' />
        </button>
        <span>
          {activeItemId + 1} / {items.length}
        </span>
        <button onClick={() => setActiveItemId(activeItemId + 1)} disabled={activeItemId >= items.length - 1} type='button'>
          <i className='icon-angle-right' />
        </button>
      </div>

      {/* Thumbnails */}
      <FadedScrollContainer isHorizontal fadeColour='#e8e8e8'>
        <div className={styles.galleryThumbnails}>
          {items.map((item, i) => (
            <GalleryThumbnail key={i} item={item} active={activeItemId === i} onClick={() => setActiveItemId(i)} />
          ))}
        </div>
      </FadedScrollContainer>

      {/* Main view of the selected content */}
      <div className={styles.galleryView}>
        {activeItem ? (
          <figure>
            {typeof activeItem.content === 'string' ? <img src={activeItem.content} alt={activeItem.alt ?? activeItem.caption ?? ''} /> : activeItem.content}
            <ImageCaption caption={activeItem.caption} attribution={activeItem.attribution} source={activeItem.source} sourceUrl={activeItem.sourceUrl} />
          </figure>
        ) : null}
      </div>
    </div>
  );
};

export default Gallery;
