import React from 'react';

import styles from './tutorial-editor.module.css';

import TutorialSelectionList from './tutorial-selection-list/tutorial-selection-list';
import EditorControlsTop from './editor-controls-top';
import EditorControlsBottom from './editor-controls-bottom';

interface iProps {
  className?: string;
}

const EditorOverview = ({ className = '' }: iProps): JSX.Element => {
  return (
    <div className={`${className} ${styles.editorPane}`}>
      <EditorControlsTop />
      <TutorialSelectionList />
      <EditorControlsBottom />
    </div>
  );
};

export default EditorOverview;
