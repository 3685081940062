import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import AddVarietyModal from '@gi/add-variety-modal';
import { RequestSelectors } from '@gi/react-requests';
import { ResourceContext } from '@gi/resource-provider';
import { SessionSelectors } from '@gi/react-session';

import { EditPlantContext } from '../context/edit-plant-context';

const AddVarietyModalRenderer = (): JSX.Element | null => {
  const { addingVariety, closeAddVariety, plant, onAddVariety } = useContext(EditPlantContext);

  const user = useSelector(SessionSelectors.getUser);
  if (user === null) {
    return null;
  }

  const savingUser = useSelector(RequestSelectors.userSaveOrLoad(user.ID));
  const { supplierCatalogue } = useContext(ResourceContext);

  if (!addingVariety) {
    return null;
  }

  return (
    <AddVarietyModal
      closeModal={closeAddVariety}
      plant={plant}
      userPlantVarieties={user.plantVarieties}
      onDone={onAddVariety}
      savingUser={savingUser}
      supplierCatalogue={supplierCatalogue}
    />
  );
};

export default AddVarietyModalRenderer;
