import React from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';
import plantFamilies, { PlantFamilyTypes } from '@gi/plant-families';

import CropFamilyDropdownOption from './crop-family-dropdown-option';

const SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  option: (provided) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, {}),
    padding: '3px 12px',
    display: 'flex',
    alignItems: 'center',
  }),
};

const createOption = (plantFamilyID, plantFamily) => {
  return {
    label: null,
    value: plantFamilyID,
    scientificName: plantFamily.scientificName,
    otherName: plantFamily.otherName,
    color: plantFamily.color,
  };
};

const options = [
  { label: 'All', value: null, className: 'family-off' },
  createOption(PlantFamilyTypes.BRASSICAS, plantFamilies.get(PlantFamilyTypes.BRASSICAS)),
  createOption(PlantFamilyTypes.LEGUMES, plantFamilies.get(PlantFamilyTypes.LEGUMES)),
  createOption(PlantFamilyTypes.SOLANACEAE, plantFamilies.get(PlantFamilyTypes.SOLANACEAE)),
  createOption(PlantFamilyTypes.ALLIUM, plantFamilies.get(PlantFamilyTypes.ALLIUM)),
  createOption(PlantFamilyTypes.UMBELLIFERAE, plantFamilies.get(PlantFamilyTypes.UMBELLIFERAE)),
  createOption(PlantFamilyTypes.CUCURBITS, plantFamilies.get(PlantFamilyTypes.CUCURBITS)),
  createOption(PlantFamilyTypes.CHENOPODIACEAE, plantFamilies.get(PlantFamilyTypes.CHENOPODIACEAE)),
  createOption(PlantFamilyTypes.MISCELLANEOUS, plantFamilies.get(PlantFamilyTypes.MISCELLANEOUS)),
];

const getOptionFromValue = (val) => {
  return options.find((opt) => opt.value === val);
};

interface iProps {
  selectedFamilyID?: null | number;
  onSelectedFamilyChange: (familyID: number) => void;
  menuPlacement?: MenuPlacement;
  id?: string;
}

const CropFamilyDropdown = ({ selectedFamilyID = null, onSelectedFamilyChange, menuPlacement, id }: iProps): JSX.Element => {
  const onChange = (opt) => {
    onSelectedFamilyChange(opt.value);
  };

  return (
    <ReactSelect
      styles={SELECT_STYLES}
      options={options}
      value={getOptionFromValue(selectedFamilyID)}
      onChange={onChange}
      menuPortalTarget={document.body}
      menuPlacement={menuPlacement}
      inputId={id}
      formatOptionLabel={CropFamilyDropdownOption}
      isSearchable={false}
    />
  );
};

export default CropFamilyDropdown;
