import React, { ReactNode } from 'react';

import './drawer-header.scss';

const HeaderTypes = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'] as const;
type HeaderType = (typeof HeaderTypes)[number];

interface iProps {
  children: ReactNode;
  noBorder?: boolean;
  className?: string;
  onClose: () => void;
  as?: HeaderType;
}

const DrawerHeader = ({ children, noBorder = false, className, onClose, as: As }: iProps): JSX.Element => {
  const classNames = ['drawer-header'];
  if (noBorder) {
    classNames.push('no-border');
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      <div className='drawer-header-content'>{As ? <As>{children}</As> : children}</div>
      <div className='drawer-header-close'>
        <button type='button' aria-label='Close menu' onClick={onClose}>
          <i className='icon-cancel' />
        </button>
      </div>
    </div>
  );
};

export default DrawerHeader;
