import { Container, Text, TextStyle, TextStyleAlign } from 'pixi.js-new';
import { RichTextDefinition, RichTextPartDefinition } from './types';
import RichTextStyleCache from './rich-text-style-cache';

function convertToRichTextPartDefinition(text: string | RichTextPartDefinition): RichTextPartDefinition {
  if (typeof text === 'string') {
    return { text };
  }
  return text;
}

/**
 * Custom pixi component to display "rich" text (currently only supports bold, but can be expanded.)
 */
class RichText extends Container {
  #text: string | RichTextDefinition | undefined;
  get text() {
    return this.#text;
  }
  set text(text: string | RichTextDefinition | undefined) {
    if (text === this.#text) {
      return;
    }
    this.#text = text;
    this.#generate();
  }

  #textStyle: TextStyle;
  get textStyle() {
    return this.#textStyle;
  }
  set textStyle(textStyle: TextStyle) {
    if (this.#textStyle === textStyle) {
      return;
    }
    this.#textStyle = textStyle;
    this.#textStyleCache = new RichTextStyleCache(textStyle);
    this.#generate();
  }

  #textAlign: Exclude<TextStyleAlign, 'justify'> = 'left';
  get textAlign() {
    return this.#textAlign;
  }
  set textAlign(textAlign: Exclude<TextStyleAlign, 'justify'>) {
    if (textAlign === this.#textAlign) {
      return;
    }
    this.#textAlign = textAlign;
    this.#generate();
  }

  #textStyleCache: RichTextStyleCache;
  #textElements: Text[] = [];

  constructor(baseTextStyle: TextStyle, text?: string | RichTextDefinition) {
    super();
    this.#text = text;
    this.#textStyle = baseTextStyle;
    this.#textStyleCache = new RichTextStyleCache(baseTextStyle);

    if (this.#text !== undefined) {
      this.#generate();
    }
  }

  #getOrCreateTextElement(id: number, text?: string, textStyle?: TextStyle) {
    if (this.#textElements[id]) {
      if (text !== undefined) {
        this.#textElements[id].text = text;
      }
      if (textStyle !== undefined) {
        this.#textElements[id].style = textStyle;
      }
      return this.#textElements[id];
    }
    const textElement = new Text(text, textStyle);
    this.#textElements.push(textElement);
    this.addChild(textElement);
    return textElement;
  }

  #generate() {
    // Go through and generate all the pieces of text for each line
    const textLines: Text[][] = [[]];
    let currentTextElem: number = 0;

    const asArray = Array.isArray(this.text) ? this.text : typeof this.text === 'string' ? [this.text] : [];

    asArray.forEach((part) => {
      const definition = convertToRichTextPartDefinition(part);
      const lines = definition.text.split('\n');
      lines.forEach((string, i) => {
        if (i !== 0) {
          textLines.push([]);
          if (string === '') {
            return;
          }
        }
        const textLine = textLines[textLines.length - 1];
        const style = this.#textStyleCache.getStyle(definition);
        const text = this.#getOrCreateTextElement(currentTextElem, string, style);
        textLine.push(text);
        currentTextElem++;
      });
    });

    // Arrange all the text now that we have each part
    const textLineWidths = textLines.map((textLine) => textLine.reduce((curWidth, text) => curWidth + text.width, 0));
    const textLineHeights = textLines.map((textLine) => textLine.reduce((curHeight, text) => Math.max(curHeight, text.height), 0));
    const maxWidth = textLineWidths.reduce((curMaxWidth, lineWidth) => Math.max(lineWidth, curMaxWidth), 0);
    const leftOffsetAmount = this.#textAlign === 'left' ? 0 : this.#textAlign === 'center' ? 0.5 : 1;

    let currentY: number = 0;

    textLines.forEach((textLine, i) => {
      let currentX = (maxWidth - textLineWidths[i]) * leftOffsetAmount;
      textLine.forEach((text) => {
        text.position = { x: currentX, y: currentY };
        currentX += text.width;
      });
      currentY += textLineHeights[i];
    });

    // Clean up unused text elements
    const textToDestroy = this.#textElements.splice(currentTextElem, this.#textElements.length - currentTextElem);
    textToDestroy.forEach((text) => text.destroy());
  }
}

export default RichText;
