import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DeviceDisplayMode } from '@gi/constants';
import { DisplayModeSelectors } from '@gi/display-mode-provider';

import TutorialUtils from './tutorial-utils';
import { TutorialContext } from './tutorial-context';

interface UseTutorialProps {
  tutorialId?: string;
  tutorialName?: string;
  displayMode?: DeviceDisplayMode.DESKTOP | DeviceDisplayMode.MOBILE;
}

export const useTutorial = (props: UseTutorialProps) => {
  const displayMode = useSelector(DisplayModeSelectors.getDisplayMode);
  const { tutorials, runningTutorial, startTutorial } = useContext(TutorialContext);

  const tutorial = useMemo(() => {
    if (tutorials === null) {
      return null;
    }
    // Search by ID if ID is specified
    if (props.tutorialId) {
      const index = TutorialUtils.getTutorialIndex(tutorials, props.tutorialId);
      if (index === -1) {
        return null;
      }
      return tutorials.tutorials[index];
    }
    // Search by name a displayMode if name is specified
    if (props.tutorialName) {
      return TutorialUtils.getNamedTutorial(tutorials, props.tutorialName, props.displayMode ?? displayMode);
    }
    // No way to match
    return null;
  }, [tutorials, props]);

  const start = useCallback(() => {
    if (tutorial) {
      startTutorial(tutorial);
    }
  }, [startTutorial, tutorial]);

  const isRunning = useMemo(() => {
    if (tutorial === null || runningTutorial === null) {
      return false;
    }
    return runningTutorial.tutorial === tutorial;
  }, [tutorial, runningTutorial]);

  return useMemo(
    () => ({
      tutorial,
      start,
      isRunning,
    }),
    [tutorial, start, isRunning]
  );
};
