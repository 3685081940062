import React, { useContext } from 'react';
import { updateFilters } from '@gi/filters';

import { DrawingToolsContext } from '../../../drawing-tools-context';

const FavouritesFilterView = (): JSX.Element => {
  const { plantFilters, setPlantFilters, favouritePlants } = useContext(DrawingToolsContext);
  const favouritesFilterDisabled = !plantFilters.filters.favourites.inputs.enabled && favouritePlants.size === 0;

  const onChange = (e) => {
    setPlantFilters(
      updateFilters(plantFilters, {
        favourites: {
          enabled: e.target.checked,
          favouritePlants,
        },
      })
    );
  };

  return (
    <label className='filter-row-label filter-row-checkbox filter-row-multi-item'>
      <span className='filter-row-label-content'>
        <input
          disabled={favouritesFilterDisabled}
          className='filter-box'
          type='checkbox'
          onChange={onChange}
          checked={plantFilters.filters.favourites.inputs.enabled}
        />
      </span>
      <span className='filter-row-label-text'>Favorites</span>
    </label>
  );
};

export default FavouritesFilterView;
