import Collection from '@gi/collection';
import { LoadingState } from '@gi/constants';
import { EMPTY_USER_PLANTING_CALENDARS, UserPlantingCalendars, UserRegionalPlantingCalendar } from '@gi/planting-calendar';
import { AsyncResource } from '@gi/react-utils';
import { getOverallLoadingState } from '@gi/utils';

/**
 * Returns a collection which isn't null
 */
export function collectionNotNull<T>(collection: Collection<T> | null): Collection<T> {
  if (collection === null) {
    return new Collection<T>();
  }

  return collection;
}

/**
 * Takes a userPlantingCalendar provided by the resource provider (which could be null) and returns something which couldn't be null
 */
export function userPlantingCalendarsNotNull(userPlantingCalendar: UserPlantingCalendars | null): UserPlantingCalendars {
  if (userPlantingCalendar === null) {
    return EMPTY_USER_PLANTING_CALENDARS;
  }

  return userPlantingCalendar;
}

/**
 * Returns UserRegionalPlantingCalendar or null, from RegionalPlantingDates potentially provided to the resource provider
 */
export function getUserRegionalPlantingDatesFromProvided(
  providedRegionalPlantingDates: null | {
    [countryCode: string]: { [regionID: number]: UserRegionalPlantingCalendar };
  },
  artifactCode: string,
  userRegionID: null | number
): null | UserRegionalPlantingCalendar {
  if (providedRegionalPlantingDates === null || userRegionID === null) {
    return null;
  }

  if (providedRegionalPlantingDates[artifactCode] && providedRegionalPlantingDates[artifactCode][userRegionID]) {
    return providedRegionalPlantingDates[artifactCode][userRegionID];
  }

  console.warn('Regional planting dates provided to resource provider but current users artifactCode/regionID not found in Data', artifactCode, userRegionID);

  return null;
}

/** Represents a resource the loading screen cares about */
export interface LoadingScreenResource {
  resource: AsyncResource<any, any>;
  label: string;
  /**
   * Set to true to require this resource to finish loading, but don't care if it's successful or not.
   * Use for things like the guru data, which should be loaded if possible before proceeding, but not get stuck if it fails.
   */
  optional?: boolean;
}

/**
 * Converts the loading status of a list of items to a single value.
 * See documentation of getOverallLoadingState for details.
 * This also treats ERROR as SUCCESS for resources marked as `optional`
 * @param resources The list of resources to check
 * @returns A single loading state, representing the most-problematic loading-state of them all.
 */
export function getOverallLoadingScreenResourceState(resources: LoadingScreenResource[]): LoadingState {
  return getOverallLoadingState(
    resources.map((resource) => {
      if (resource.optional) {
        // Optional resources may be retried later. If they've been attempted once, treat as SUCCESS to prevent showing the loading screen on retry
        if (resource.resource.hasAttempted) {
          return LoadingState.SUCCESS;
        }
        // Optional resources pass if they're a success or error, so treat errors as success here
        return resource.resource.status === LoadingState.ERROR ? LoadingState.SUCCESS : resource.resource.status;
      }
      return resource.resource.status;
    })
  );
}
