import React, { useContext } from 'react';
import { SidebarContent } from '@gi/planner-sidebar';
import { TAB_CATEGORIES } from './constants';
import { DrawingToolsContext } from './drawing-tools-context';
import GardenObjectPanel from './garden-objects/garden-object-panel';
import PlantPanel from './plants/plant-panel';
import ShapePanel from './shapes/shape-panel';
import TextPanel from './text/text-panel';

const DrawingToolsPanels = () => {
  const { activeTab } = useContext(DrawingToolsContext);

  if (activeTab !== null && !(activeTab in TAB_CATEGORIES)) {
    console.error('Active tab panel reached invalid state', activeTab);
  }

  return (
    <>
      <SidebarContent tabId={TAB_CATEGORIES.PLANTS} className='drawing-tools-content'>
        <PlantPanel />
      </SidebarContent>
      <SidebarContent tabId={TAB_CATEGORIES.GARDEN_OBJECTS} className='drawing-tools-content'>
        <GardenObjectPanel />
      </SidebarContent>
      <SidebarContent tabId={TAB_CATEGORIES.SHAPES} className='drawing-tools-content'>
        <ShapePanel />
      </SidebarContent>
      <SidebarContent tabId={TAB_CATEGORIES.TEXT} className='drawing-tools-content'>
        <TextPanel />
      </SidebarContent>
    </>
  );
};

export default DrawingToolsPanels;
