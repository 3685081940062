import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type MobileNavState = {
  open: boolean;
  helpDropdownOpen: boolean;
};

const initialState: MobileNavState = {
  open: false,
  helpDropdownOpen: false,
};

const mobileNavSlice = createSlice({
  name: 'mobileNav',
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<MobileNavState['open']>) => {
      state.open = action.payload;
      return state;
    },
    setHelpDropdownOpen: (state, action: PayloadAction<MobileNavState['helpDropdownOpen']>) => {
      state.helpDropdownOpen = action.payload;
      return state;
    },
  },
});

interface RootState {
  mobileNav: MobileNavState;
}

export const mobileNavReducer = mobileNavSlice.reducer;
export const MobileNavActionCreators = mobileNavSlice.actions;
export const MobileNavSelectors = {
  getIsOpen: (state: RootState) => state.mobileNav.open,
  getIsHelpDropdownOpen: (state: RootState) => state.mobileNav.helpDropdownOpen,
};
