import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { MONTHS } from '@gi/constants';
import { PlanPlant } from '@gi/plan';
import { SessionSelectors } from '@gi/react-session';

import PlantListCardCell from './plant-list-card-cell';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

import './plant-list-card-in-ground-dates.scss';

export function getInGroundString(startMonth: number, endMonth: number, northernHemisphere: boolean): JSX.Element {
  const adjustedStartMonth = northernHemisphere ? startMonth : (startMonth + 6) % 12;
  const adjustedEndMonth = northernHemisphere ? endMonth : (endMonth + 6) % 12;

  return (
    <span className='plant-list-card-in-ground-dates-string'>
      <span className='start'>
        {MONTHS[adjustedStartMonth]}
        <i className='icon-right' />
      </span>
      <span className='end'>
        {MONTHS[adjustedEndMonth]}
        {adjustedEndMonth < adjustedStartMonth ? <span className='end-following-year'>(following Year)</span> : null}
      </span>
    </span>
  );
}

interface iProps {
  planPlant: PlanPlant;
}

const PlantListCardInGroundDates = ({ planPlant }: iProps): JSX.Element | null => {
  const northernHemisphere = useSelector(SessionSelectors.getIsNorthernHemisphere);

  const { columns } = useContext(GlobalPlantListContext);

  if (!columns.inGroundDates) {
    return null;
  }

  return (
    <PlantListCardCell title='In-Ground'>
      {planPlant.inGroundAll ? 'All Year Round' : getInGroundString(planPlant.inGroundStart, planPlant.inGroundEnd, northernHemisphere)}
    </PlantListCardCell>
  );
};

export default PlantListCardInGroundDates;
