import React from 'react';
import moment from 'moment-timezone';

import { Reminder, Reminders, ReminderUtils } from '@gi/reminders';
import { getDayName, sortByID } from '@gi/utils';

import DashboardReminderTile from './dashboard-reminder-tile';

import './dashboard-reminder-day.scss';

const getDismissedFilter = (showDismissed) => {
  return (reminder) => {
    if (showDismissed) {
      return true;
    }

    return !reminder.dismissed;
  };
};

interface iProps {
  date: moment.Moment;
  dateYMD: string;
  reminders: Reminders;
  showDismissed: boolean;
  restore: (reminder: Reminder) => void;
  dismiss: (reminder: Reminder) => void;
  edit: (reminder: Reminder) => void;
}

const DashboardReminderDay = ({ date, dateYMD, reminders, showDismissed, restore, dismiss, edit }: iProps): JSX.Element | null => {
  if (ReminderUtils.getNotDismissedReminderCountForDate(reminders, dateYMD) === 0 && !showDismissed) {
    return null;
  }

  return (
    <div className='dashboard-reminder-day'>
      <div>
        <strong>{getDayName(date, 'dddd')}</strong>, {date.format('Do MMMM')}
      </div>
      <div className='dashboard-reminders-list'>
        {ReminderUtils.getRemindersForDate(reminders, dateYMD)
          .filter(getDismissedFilter(showDismissed))
          .sort(sortByID)
          .map((reminder) => (
            <DashboardReminderTile
              restore={() => restore(reminder)}
              dismiss={() => dismiss(reminder)}
              edit={() => edit(reminder)}
              reminder={reminder}
              key={reminder.ID}
            />
          ))}
      </div>
    </div>
  );
};

export default DashboardReminderDay;
