import Plant from '@gi/plant';
import { DEFAULT_VARIETY, GardenItemType } from '@gi/constants';
import { PlantFamilyTypes } from '@gi/plant-families';
import { HiddenFlag, InspectableClassData, InteractableComponentCallbacks, NodeEvent, PointerData } from '@gi/core-renderer';

import DrawTool, { DrawToolState } from './draw-tool';
import PlantNode from '../plant/plant-node';
import CanvasLayers from '../../canvas-layers';
import SFGPlantNode from '../plant/sfg-plant-node';
import { SimulatedPlant } from '../../../simulation/simulated-plant';
import PlantHandleSetNode from '../handles/plant-handle-set-node';
import CanvasInteractionInterface from '../../../canvas-interface/canvas-interaction-interface';
import GardenItemSelectionMiddleware from '../../components/garden-item-selection-middleware';
import CropRotationContext, { CropRotationInteractionType } from '../crop-rotation/crop-rotation-context';
import { DRAWING_ITEM_ID } from '../../constants';
import SettingsContext from '../settings-context';

export interface DrawPlantToolState extends DrawToolState {
  itemType: GardenItemType.Plant;
  plantCode: string;
  sfg: boolean;
  plantFamily: number;
}

class DrawPlantTool extends DrawTool<DrawPlantToolState> {
  type = 'DrawPlantTool';

  readonly plant: Plant;
  readonly isSquareFoot: boolean;

  private simulatedPlant: SimulatedPlant | null = null;
  private handles: PlantHandleSetNode | null = null;

  private previewNode: PlantNode | SFGPlantNode | null = null;

  constructor(
    plant: Plant,
    isSquareFoot: boolean,
    interactionInterface: CanvasInteractionInterface,
    canvasLayers: CanvasLayers,
    dragToDrawEvent?: PointerEvent
  ) {
    super(
      interactionInterface,
      canvasLayers,
      {
        itemType: GardenItemType.Plant,
        plantCode: plant.code,
        sfg: isSquareFoot,
        plantFamily: plant.familyID,
      },
      dragToDrawEvent
    );

    this.plant = plant;
    this.isSquareFoot = isSquareFoot;

    this.ignoreClick = true;

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    if (this.isSquareFoot) {
      this.previewNode = new SFGPlantNode(DRAWING_ITEM_ID, this.plant, {
        position: { x: 0, y: 0 },
        plantCount: this.plant.squareFootPlantCount,
        rotation: 0,
        variety: DEFAULT_VARIETY,
        visible: true,
        locked: false,
        zIndex: 0,
      });
    } else {
      this.previewNode = new PlantNode(DRAWING_ITEM_ID, this.plant, {
        rowStart: { x: 0, y: 0 },
        rowEnd: { x: 0, y: 0 },
        width: 0,
        height: 0,
        rotation: 0,
        spacing: this.plant.spacings.spacing,
        rowSpacing: this.plant.spacings.rowSpacing,
        inRowSpacing: this.plant.spacings.inRowSpacing,
        plantCount: { x: 1, y: 1, total: 1 },
        variety: DEFAULT_VARIETY,
        visible: true,
        locked: false,
        zIndex: 0,
      });
    }
    this.canvasLayers.drawingPreviewLayer.addChildren(this.previewNode);
    this.previewNode.visibility.addHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    this.tryGetContext(CropRotationContext)?.showFamilies(CropRotationInteractionType.DRAW, this.plant.familyID as PlantFamilyTypes);
  };

  #onBeforeUnbind = () => {
    if (this.previewNode) {
      this.previewNode.destroy();
    }
    this.tryGetContext(CropRotationContext)?.stopShowing(CropRotationInteractionType.DRAW);
  };

  onDragStart: InteractableComponentCallbacks['onDragStart'] = (data, interaction, controls) => {
    if (this.previewNode) {
      this.previewNode.visibility.addHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    }

    const settings = this.tryGetContext(SettingsContext);
    const showLabel = this.isSquareFoot ? settings?.state.values.showLabelOnNewSFGPlants : settings?.state.values.showLabelOnNewPlants;
    const startPos = this.snapPointIfNeeded(data.worldPosition);

    this.simulatedPlant = this.interactionInterface.startDrawingPlant(this.plant, this.isSquareFoot, startPos, showLabel ?? true);

    if (!this.isSquareFoot) {
      const handles = this.tryGetContext(GardenItemSelectionMiddleware)?.forceUpdateHandles();

      if (handles && handles instanceof PlantHandleSetNode) {
        const [target] = handles.targets;

        if (target && target instanceof PlantNode && target.id === this.simulatedPlant.id) {
          this.handles = handles;
          this.handles.RE.callOnDragStart(data, interaction, controls);
        } else {
          console.error('Plant handles are for the wrong plant');
        }
      } else {
        console.error('Failed to find plant handles after drawing plant');
      }
    }
  };

  onDragMove: InteractableComponentCallbacks['onDragMove'] = (data, interaction, controls) => {
    if (this.isSquareFoot) {
      const position = this.snapPointIfNeeded(data.worldPosition);
      this.simulatedPlant?.setPositions(position, position, 0);
    } else if (this.handles) {
      this.handles.RE.callOnDragMove(data, interaction, controls);
    } else {
      console.error('Failed to manipulate drawn plant: Handles missing');
    }
  };

  onDragEnd: InteractableComponentCallbacks['onDragEnd'] = (data, interaction, controls) => {
    if (this.isSquareFoot) {
      const position = this.snapPointIfNeeded(data.worldPosition);
      this.simulatedPlant?.setPositions(position, position, 0);
    } else if (this.handles) {
      this.handles.RE.callOnDragEnd(data, interaction, controls);
    } else {
      console.error('Failed to manipulate drawn plant: Handles missing');
    }

    this.handles = null;
    this.simulatedPlant = null;

    this.interactionInterface.onDrawPlant(this.plant, this.isSquareFoot, this.isDragToDraw ?? false);
    this.interactionInterface.pushUpdates();

    if (this.previewNode) {
      this.previewNode.visibility.removeHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    }
  };

  onPointerMove = (data: Pick<PointerData, 'worldPosition' | 'screenPosition'>) => {
    if (!this.previewNode) {
      return;
    }

    const position = this.snapPointIfNeeded(data.worldPosition);

    if (this.previewNode instanceof PlantNode) {
      this.previewNode.setPositions(position, position, 0, 0, 0);
    } else {
      this.previewNode.setPosition(position, 0);
    }

    if (this.previewNode.visibility.shouldHide && (this.isDragToDraw || !this.interaction.isActive())) {
      this.previewNode.visibility.removeHiddenFlag(HiddenFlag.DRAWING_PREVIEW);
    }
  };

  inspectorData: InspectableClassData<this> = [...this.inspectorData];
}

export default DrawPlantTool;
