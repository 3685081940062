import { type Plan } from '../plan';
import { type PlanPlant } from '../plan-plant';
import { type PlanGardenObject } from '../plan-garden-object';
import { type PlanShape } from '../plan-shape';
import { type PlanText } from '../plan-text';

/**
 * Type all options fields should be stored as.
 * Should look like `"v${schemeVersionInt}|${data}"`, e.g. `"v1|{"locked":true}"`
 */
export type StringifiedOptionsField = `v${number}|${string}`;

/** Type for the data stored in the `options` field of a plan */
export type PlanOptions = {
  bgSrcPlanId?: number;
  bgSrcId?: string;
  bgPosX?: number;
  bgPosY?: number;
  bgWidth?: number;
  bgHeight?: number;
  bgRotation?: number;
  bgShow?: boolean;
  bgAlpha?: number;
  bgCropX?: number;
  bgCropY?: number;
  bgCropW?: number;
  bgCropH?: number;
  bgMaintainAspect?: boolean;
};

/** Common data stored for all garden items in the `options` field */
export type PlanGardenItemOptions = {
  locked: boolean;
};

export type PlanPlantOptions = PlanGardenItemOptions;
export type PlanGardenObjectOptions = PlanGardenItemOptions;
export type PlanShapeOptions = PlanGardenItemOptions;
export type PlanTextOptions = PlanGardenItemOptions;

export const DEFAULT_PLAN_OPTIONS: PlanOptions = {
  bgShow: true,
  bgAlpha: 0.5,
  bgMaintainAspect: true,
  // TODO
};

export const DEFAULT_PLAN_PLANT_OPTIONS: PlanPlantOptions = {
  locked: false,
};

export const DEFAULT_PLAN_GARDEN_OBJECT_OPTIONS: PlanGardenObjectOptions = {
  locked: false,
};

export const DEFAULT_PLAN_SHAPE_OPTIONS: PlanShapeOptions = {
  locked: false,
};

export const DEFAULT_PLAN_TEXT_OPTIONS: PlanTextOptions = {
  locked: false,
};

export interface OptionsFieldParser {
  version: number;
  parsePlanOptions(optionsString: string): PlanOptions;
  parsePlantOptions(optionsString: string): PlanPlantOptions;
  parseGardenObjectOptions(optionsString: string): PlanGardenObjectOptions;
  parseShapeOptions(optionsString: string): PlanShapeOptions;
  parseTextOptions(optionsString: string): PlanTextOptions;
  stringifyPlanOptions(plan: Plan): StringifiedOptionsField;
  stringifyPlantOptions(planPlant: PlanPlant): StringifiedOptionsField;
  stringifyGardenObjectOptions(planGardenObject: PlanGardenObject): StringifiedOptionsField;
  stringifyShapeOptions(planShape: PlanShape): StringifiedOptionsField;
  stringifyTextOptions(planText: PlanText): StringifiedOptionsField;
}

/**
 * Formats a string ready to be stored in the `options` field of an item, prepending the schema version
 * @param value The value to store in the string
 * @returns A string
 */
export function prependSchemaVersion(version: number, value: string): StringifiedOptionsField {
  return `v${version}|${value}`;
}

/**
 * Regex for the format the options field should be stored in
 * This regex looks for
 * v${number}|${content}
 * e.g. "v1|{locked:true}"
 */
export const OptionsFormatRegex = /^v(\d+)\|([\s\S]*)$/;
