import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import ReactSelect from 'react-select';

import { Anchor } from '@gi/constants';
import LengthInput from '@gi/length-input';
import { AnchorInput } from '@gi/anchor-input';
import Plan, { PlanClockwiseRotationAmount } from '@gi/plan';
import { imperialDistanceUnits, metricDistanceUnits } from '@gi/units';
import FormField, { FORM_FIELD_PRESETS, FORM_LAYOUT_PRESETS, FormLayout, FormSection, FormValues, InputContainer } from '@gi/form-responsive';

import DEFAULT_SELECT_STYLES from '@gi/styles/react-select-styles';

import PlanDimensionsDiffPreview from './plan-preview/plan-dimensions-diff-preview';
import PlanDimensionsEditorHistoricalSection from './plan-dimensions-editor-historical-section';

import styles from './plan-dimensions-editor.module.css';

interface ReactSelectOption<T> {
  label: string;
  value: T;
}

const ROTATION_OPTIONS: ReactSelectOption<PlanClockwiseRotationAmount | false>[] = [
  { value: false, label: 'No rotation' },
  { value: PlanClockwiseRotationAmount.Rotate90, label: '90° Clockwise' },
  { value: PlanClockwiseRotationAmount.Rotate180, label: '180° Clockwise' },
  { value: PlanClockwiseRotationAmount.Rotate270, label: '90° Counter clockwise' },
];

function getDimensionLimitText(isMetric: boolean) {
  if (isMetric) {
    return 'Sizes must be between 1m and 300m.';
  }

  return "Sizes must be between 3' 3'' and 1000'.";
}

interface PlanDimensionsFormValues {
  useMetric: boolean;
  width: number;
  height: number;
  anchor: Anchor;
  rotation: false | PlanClockwiseRotationAmount;
  resizeHistoricalPlans: boolean;
  planHistory: number[];
}

interface iProps {
  plan: Plan;
  values: FormValues<PlanDimensionsFormValues>;
  setValue: <K extends keyof PlanDimensionsFormValues>(key: K, value: PlanDimensionsFormValues[K]) => void;
  disabled?: boolean;
}

const PlanDimensionsEditor = ({ values, setValue, plan, disabled = false }: iProps): JSX.Element => {
  const { useMetric, width, height, anchor, rotation } = values.values;

  const [showAdvanced, setShowAdvanced] = useState(false);

  const distanceUnits = useMetric ? metricDistanceUnits : imperialDistanceUnits;

  return (
    <FormSection heading='Units & Dimensions' padding={12} gap={6} className='form-section-background'>
      <div className={styles.topFormContent}>
        <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.FormSectionContent} desktopLayout={{ size: 'auto' }}>
          {/* Length Units radio buttons */}
          <FormField fakeLabel label='Length Units' layoutPreset={FORM_FIELD_PRESETS.EditModal} desktopLayout={{ labelAlignY: 'center' }}>
            <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.RadioStack}>
              <FormField label='Metric' htmlFor='plan-dimensions:units-metric' layoutPreset={FORM_FIELD_PRESETS.Radio} disabled={disabled}>
                <input
                  id='plan-dimensions:units-metric'
                  type='radio'
                  value='metric'
                  checked={useMetric}
                  disabled={disabled}
                  onChange={(e) => {
                    setValue('useMetric', e.target.checked);
                  }}
                />
              </FormField>
              <FormField label='Imperial' htmlFor='plan-dimensions:units-imperial' layoutPreset={FORM_FIELD_PRESETS.Radio} disabled={disabled}>
                <input
                  id='plan-dimensions:units-imperial'
                  type='radio'
                  value='imperial'
                  checked={!useMetric}
                  disabled={disabled}
                  onChange={(e) => {
                    setValue('useMetric', !e.target.checked);
                  }}
                />
              </FormField>
            </FormLayout>
          </FormField>
          {/* Width entry */}
          <FormField
            label='Width'
            htmlFor='plan-dimensions:width'
            invalid={!values.fields.width.valid}
            layoutPreset={FORM_FIELD_PRESETS.EditModal}
            disabled={disabled}
          >
            <LengthInput
              value={width}
              onChange={(val) => setValue('width', val)}
              distanceUnits={distanceUnits}
              id='plan-dimensions:width'
              disabled={disabled}
            />
          </FormField>
          {/* Height entry */}
          <FormField
            label='Height'
            htmlFor='plan-dimensions:height'
            invalid={!values.fields.height.valid}
            errorText={!values.fields.width.valid || !values.fields.height.valid ? getDimensionLimitText(useMetric) : null}
            helpText={values.fields.width.valid && values.fields.height.valid ? getDimensionLimitText(useMetric) : null}
            layoutPreset={FORM_FIELD_PRESETS.EditModal}
            disabled={disabled}
          >
            <LengthInput
              value={height}
              onChange={(val) => setValue('height', val)}
              distanceUnits={distanceUnits}
              id='plan-dimensions:height'
              disabled={disabled}
            />
          </FormField>
        </FormLayout>
        {/* Plan diff view */}
        <div className={styles.diagramContainer}>
          <PlanDimensionsDiffPreview
            initialWidth={values.fields.width.initialValue}
            initialHeight={values.fields.height.initialValue}
            width={width}
            height={height}
            anchor={anchor}
            rotation={rotation}
            distanceUnits={distanceUnits}
            displayWidth={170}
            displayHeight={170}
          />
        </div>
      </div>
      <div className={styles.advancedContainer}>
        {/* Advanced Options toggle */}
        <FormField label='Show Advanced Options' htmlFor='plan-dimensions:show-advanced' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft} disabled={disabled}>
          <input
            id='plan-dimensions:show-advanced'
            type='checkbox'
            checked={showAdvanced}
            onChange={(e) => setShowAdvanced(e.target.checked)}
            disabled={disabled}
          />
        </FormField>
        {/* Advanced Options */}
        <AnimateHeight duration={200} height={showAdvanced ? 'auto' : 0}>
          <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.FormSectionContent} className={styles.advancedInner}>
            {/* Anchor input */}
            <FormField
              label='Anchor'
              fakeLabel
              helpText='Changes where the width and height change is applied from'
              layoutPreset={FORM_FIELD_PRESETS.EditModal}
              disabled={disabled}
            >
              <AnchorInput value={anchor} setValue={(value) => setValue('anchor', value)} disabled={disabled} />
            </FormField>
            {/* Rotation input */}
            <FormField label='Rotation' htmlFor='plan-dimensions:rotation' layoutPreset={FORM_FIELD_PRESETS.EditModal} disabled={disabled}>
              <InputContainer size='full'>
                <ReactSelect<ReactSelectOption<PlanClockwiseRotationAmount | false>>
                  styles={DEFAULT_SELECT_STYLES}
                  options={ROTATION_OPTIONS}
                  value={ROTATION_OPTIONS.find((option) => option.value === rotation)}
                  onChange={(val) => {
                    if (val) {
                      setValue('rotation', val.value);
                    }
                  }}
                  isSearchable={false}
                  inputId='plan-dimensions:rotation'
                  isDisabled={disabled}
                />
              </InputContainer>
            </FormField>
            <PlanDimensionsEditorHistoricalSection plan={plan} values={values} setValue={setValue} disabled={disabled} />
          </FormLayout>
        </AnimateHeight>
      </div>
    </FormSection>
  );
};

export default PlanDimensionsEditor;
