import { PlantFamilyTypes } from '@gi/plant-families';

/**
 * @typedef {PlantFamily|string} CropRotationViewType
 */
/**
 * Enum for the different possible viewing types for crop rotation, from 'OFF' for nothing, through all the plant families excluding miscellaneous
 *
 * @readonly
 * @enum {CropRotationViewType}
 */
const CropRotationViewTypes = {
  OFF: 'OFF',
  BRASSICAS: PlantFamilyTypes.BRASSICAS,
  LEGUMES: PlantFamilyTypes.LEGUMES,
  SOLANACEAE: PlantFamilyTypes.SOLANACEAE,
  ALLIUM: PlantFamilyTypes.ALLIUM,
  UMBELLIFERAE: PlantFamilyTypes.UMBELLIFERAE,
  CUCURBITS: PlantFamilyTypes.CUCURBITS,
  CHENOPODIACEAE: PlantFamilyTypes.CHENOPODIACEAE,
} as const;

type CropRotationViewTypes = (typeof CropRotationViewTypes)[keyof typeof CropRotationViewTypes];

export default CropRotationViewTypes;
