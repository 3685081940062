import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { GardenPlatformEvent } from '../types';
import { GardenPlatformEventsActionCreators } from '../garden-platform-events-middleware';

/**
 * Helper hook to dispatch an analytics event when a link is clicked.
 * @param opener The name of the element that opened the link
 * @returns A function that dispatches an analytics event when called
 */
const useViewLinkEventHook = (opener: string) => {
  const dispatch = useDispatch();

  const callback = useCallback(
    /**
     * Sends an event for a link being clicked on
     * @param name The name of the link that was opened. Avoid using the URL if possible.
     * @param openerOverride Override the `opener` property.
     */
    (name: string, openerOverride?: string) => {
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ViewLink, { name, opener: openerOverride ?? opener }));
    },
    [opener]
  );

  return callback;
};

export default useViewLinkEventHook;
