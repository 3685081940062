import React, { FocusEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';

import FormField, { FORM_FIELD_PRESETS, FormLayout, FormSection, InputContainer, FORM_LAYOUT_PRESETS } from '@gi/form-responsive';
import { SessionSelectors } from '@gi/react-session';
import { UserSubscriptionUtils } from '@gi/user';
import { AppAccountActionCreators, AppAccountSelectors } from '@gi/app-account-slice';
import { LoadingState } from '@gi/constants';
import { NotificationActionCreators, NotificationTypes } from '@gi/notifications';
import { useResettingState } from '@gi/react-utils';

import styles from './user-referral-code-overview.module.css';

const UserReferralCodeOverview = (): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector(SessionSelectors.getUser);
  const referralCode = useSelector(AppAccountSelectors.getReferralCode);

  const [referralTitle, setReferralTitle] = useState('Garden Planner');
  const [referralText, setReferralText] = useState("Here's the Garden Planner I use, with a 25% off discount:");
  const [showShareSection, setShowShareSection] = useState(false);
  const [copyButtonText, setCopyButtonText] = useResettingState('Copy', 2000);

  const canShare = !!navigator && !!navigator.share;

  const isSubscribed = useMemo(() => {
    if (!user) {
      return false;
    }
    return UserSubscriptionUtils.hasSubscription(user.subscription);
  }, [user]);

  // Load the referral URL on first render if it isn't loading already
  useEffect(() => {
    if (isSubscribed && referralCode.status === LoadingState.NONE) {
      dispatch(AppAccountActionCreators.loadUserReferralCode());
    }
  }, [referralCode, isSubscribed]);

  // Create a real URL out of the url part of the response and the current host
  const referralUrl = useMemo(() => {
    if (referralCode.status === LoadingState.SUCCESS && referralCode.value) {
      return `https://${window.location.host}/${referralCode.value.url}`;
    }
    return '';
  }, [referralCode]);

  // Update the referral body text whenever the referral URL updates.
  useEffect(() => {
    if (referralUrl !== '') {
      setReferralText(`Here's the Garden Planner I use, with a 25% off discount:\n${referralUrl}`);
    } else {
      setReferralText("Here's the Garden Planner I use, with a 25% off discount:");
    }
  }, [referralUrl]);

  // Will try copy the referral URL to clipboard
  const copyToClipboard = useCallback(
    (showError = false) => {
      try {
        if (!navigator.clipboard) {
          throw new Error('Clipboard unavailable');
        }
        navigator.clipboard.writeText(referralUrl);
        setCopyButtonText('Copied!');
      } catch (error) {
        if (showError) {
          setCopyButtonText('Failed to copy');
          dispatch(
            NotificationActionCreators.createNotification({
              type: NotificationTypes.ERROR,
              icon: 'icon-warning',
              title: 'Could not copy to clipboard',
              canTimeout: true,
            })
          );
        }
      }
    },
    [referralUrl]
  );

  // Tries to open the share dialog
  const share = useCallback(() => {
    try {
      if (!navigator || !navigator.share) {
        throw new Error('Share unavailable');
      }
      const data: ShareData = {
        title: referralTitle,
        text: referralText,
        url: referralUrl,
      };
      if (!navigator.canShare(data)) {
        throw new Error('Cannot share');
      }
      navigator.share(data);
    } catch (e) {
      dispatch(
        NotificationActionCreators.createNotification({
          type: NotificationTypes.ERROR,
          icon: 'icon-warning',
          title: 'Could not open Share menu',
          canTimeout: true,
        })
      );
    }
  }, [referralTitle, referralText, referralUrl]);

  // Selects all the text in the element and copies the URL to clipboard
  const onInputFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (e.currentTarget) {
        e.currentTarget.select();
      }
      copyToClipboard(false);
    },
    [copyToClipboard]
  );

  const renderLoading = useCallback(() => {
    return (
      <p>
        <i className='icon-spinner animate-pulse' /> Loading...
      </p>
    );
  }, []);

  const renderError = useCallback(() => {
    const error = referralCode.status === LoadingState.ERROR ? referralCode.error : 'Unknown Error';
    return (
      <>
        <p>There was an error while retrieving your friends and family link.</p>
        <p>{error.toString()}</p>
        <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.ButtonRight}>
          <button type='button' className='button button-primary' onClick={() => dispatch(AppAccountActionCreators.loadUserReferralCode())}>
            Retry
          </button>
        </FormLayout>
      </>
    );
  }, [referralCode]);

  const renderSuccess = useCallback(() => {
    return (
      <>
        <FormField layoutPreset={FORM_FIELD_PRESETS.Column} fakeLabel>
          <InputContainer size='full'>
            <input type='text' readOnly onFocus={onInputFocus} value={referralUrl} />
          </InputContainer>
        </FormField>
        <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.ButtonRight}>
          {!showShareSection && canShare ? (
            <button type='button' className='button button-primary' onClick={() => setShowShareSection(true)}>
              <i className='icon-share' /> Share...
            </button>
          ) : null}
          <button type='button' className='button button-primary' onClick={() => copyToClipboard(true)}>
            <i className='icon-docs' /> {copyButtonText}
          </button>
        </FormLayout>
        {canShare ? (
          <AnimateHeight duration={200} height={showShareSection ? 'auto' : 0} contentClassName='animate-height-content'>
            <FormSection padding={0}>
              <h3>Share Message</h3>
              <FormField layoutPreset={FORM_FIELD_PRESETS.Column} fakeLabel>
                <InputContainer size='full'>
                  <input type='text' className='form-input' value={referralTitle} onChange={(e) => setReferralTitle(e.target.value)} />
                </InputContainer>
              </FormField>
              <FormField layoutPreset={FORM_FIELD_PRESETS.Column} fakeLabel>
                <InputContainer size='full'>
                  <textarea className='form-input' rows={5} value={referralText} onChange={(e) => setReferralText(e.target.value)} />
                </InputContainer>
              </FormField>
              <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.ButtonRight}>
                <button type='button' className={`button button-inline ${styles.buttonInline}`} onClick={() => setShowShareSection(false)}>
                  Done
                </button>
                <button type='button' className='button button-primary' onClick={() => share()}>
                  <i className='icon-share' /> Share
                </button>
              </FormLayout>
            </FormSection>
          </AnimateHeight>
        ) : null}
      </>
    );
  }, [onInputFocus, referralUrl, referralCode, showShareSection, copyToClipboard, copyButtonText, referralTitle, referralText, share]);

  const content = useMemo(() => {
    if (!isSubscribed) {
      return null;
    }
    if (referralCode.status === LoadingState.LOADING) {
      return renderLoading();
    }
    if (referralCode.status === LoadingState.ERROR) {
      return renderError();
    }
    return renderSuccess();
  }, [isSubscribed, referralCode, renderLoading, renderError, renderSuccess]);

  const explanation = useMemo(() => {
    if (!isSubscribed) {
      return (
        <>
          <p>
            If you subscribe to the Garden Planner, you can invite a Friend or Family member, and they will receive a 25% discount on their first year
            subscription.
          </p>
          <p>This feature is only available to active subscribers of the Garden Planner.</p>
        </>
      );
    }

    return (
      <>
        <p>You can invite a friend or family member, and they will receive a 25% discount on their subscription for the first year.</p>
        <p>Just send them this link, and the discount will be automatically applied after they create an account.</p>
      </>
    );
  }, [isSubscribed]);

  return (
    <FormSection className='form-section-background'>
      <h2>Friends & Family Discount</h2>
      {explanation}
      {content}
    </FormSection>
  );
};

export default UserReferralCodeOverview;
