import { Edit } from './edit';

class EditGroup<T extends Edit[] = Edit[]> extends Edit {
  readonly targets: Edit[];

  constructor(targets?: T) {
    super();

    this.targets = targets ?? [];
  }

  start(): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].start();
    }
  }

  translate(positionOffset: Vector2): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].translate(positionOffset, Edit.getTranslationMatrix(positionOffset));
    }
  }

  rotate(angleOffset: number, pivot: Vector2): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].rotate(angleOffset, pivot, Edit.getRotationMatrix(angleOffset, pivot));
    }
  }

  // scale(scaleFactor: Vector2, origin: Vector2, angle: number): void {
  //   for (let i = 0; i < this.targets.length; i++) {
  //     this.targets[i].scale(scaleFactor, origin, angle);
  //   }
  // }

  end(): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].end();
    }
  }

  snapTranslate(snapDistance: number): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].snapTranslate(snapDistance);
    }
  }

  add<U extends Edit>(target: U): void {
    if (!this.targets.includes(target)) {
      this.targets.push(target);
    }
  }

  remove<U extends Edit>(target: U): void {
    const index = this.targets.indexOf(target);
    if (index !== -1) {
      this.targets.splice(index, 1);
    }
  }

  startBatch(): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].startBatch();
    }
  }

  endBatch(): void {
    for (let i = 0; i < this.targets.length; i++) {
      this.targets[i].endBatch();
    }
  }
}

export default EditGroup;
