import TransferPlanActionTypes from './transfer-plan-action-types';

const INITIAL_STATE = {
  open: false,
  inProgress: false,
  errorMessage: null,
};

const transferPlanReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TransferPlanActionTypes.OPEN_TRANSFER_PLAN_MODAL:
      return {
        ...state,
        open: true,
        inProgress: false,
        errorMessage: null,
      };
    case TransferPlanActionTypes.CLOSE_TRANSFER_PLAN_MODAL:
      return {
        ...state,
        open: false,
      };
    case TransferPlanActionTypes.TRANSFER_PLAN_START:
      return {
        ...state,
        inProgress: true,
        errorMessage: null,
      };
    case TransferPlanActionTypes.TRANSFER_PLAN_SUCCESS:
      return {
        ...state,
        inProgress: false,
        errorMessage: null,
        open: false,
      };
    case TransferPlanActionTypes.TRANSFER_PLAN_FAIL:
      return {
        ...state,
        inProgress: false,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default transferPlanReducer;
