import React, { useCallback } from 'react';

import LengthInput from '@gi/length-input';
import FormField, { FORM_FIELD_PRESETS } from '@gi/form-responsive';

import { EditGardenObjectModalFormProps, GardenObjectState } from '../types';

function offsetAndClamp(value: number, offset: number): number {
  return Math.max(0, value - offset);
}

interface iProps extends EditGardenObjectModalFormProps {
  internalDimensionsMargin: Dimensions;
}

const InnerOuterDimensionsFormPart = ({ values, setValues, distanceUnits, internalDimensionsMargin }: iProps): JSX.Element => {
  const createSetter = useCallback(
    <K extends keyof GardenObjectState>(setting: K) =>
      (value: GardenObjectState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  const createSetterWithOffset = useCallback(
    <K extends keyof Pick<GardenObjectState, 'width' | 'height'>>(setting: K, offset: number) =>
      (value: GardenObjectState[K]) => {
        setValues(values.setValue(setting, { value: value + offset }));
      },
    [values, setValues]
  );

  return (
    <>
      <div>
        <FormField label='Inside Width' htmlFor='edit-garden-object:inner-width' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
          <LengthInput
            value={offsetAndClamp(values.values.width, internalDimensionsMargin.width)}
            onChange={createSetterWithOffset('width', internalDimensionsMargin.width)}
            distanceUnits={distanceUnits}
            id='edit-garden-object:inner-width'
          />
        </FormField>
        <FormField label='Inside Height' htmlFor='edit-garden-object:inner-height' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
          <LengthInput
            value={offsetAndClamp(values.values.height, internalDimensionsMargin.height)}
            onChange={createSetterWithOffset('height', internalDimensionsMargin.height)}
            distanceUnits={distanceUnits}
            id='edit-garden-object:inner-height'
          />
        </FormField>
      </div>
      <div>
        <FormField label='Outside Width' htmlFor='edit-garden-object:width' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
          <LengthInput value={values.values.width} onChange={createSetter('width')} distanceUnits={distanceUnits} id='edit-garden-object:width' />
        </FormField>
        <FormField label='Outside Height' htmlFor='edit-garden-object:height' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
          <LengthInput value={values.values.height} onChange={createSetter('height')} distanceUnits={distanceUnits} id='edit-garden-object:height' />
        </FormField>
      </div>
    </>
  );
};

export default InnerOuterDimensionsFormPart;
