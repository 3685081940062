import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAsRef } from '@gi/react-utils';
import { AsyncOperation } from '@gi/utils';
import { ResourceLoaderContext } from '@gi/resource-provider';

import { ObjectiveDataFromAPI } from './objective-types';
import { ObjectivesActionCreators, ObjectivesSelectors } from './slice/objectives-slice';

export type ObjectivesContextType = {
  /** List of expanded group IDs. Used for accordions. */
  expandedGroups: string[];
  /** Function to set the list of expanded group IDs. Used for accordions. */
  setExpandedGroups: React.Dispatch<React.SetStateAction<string[]>>;
};

export const ObjectivesContext = createContext<ObjectivesContextType>({} as ObjectivesContextType);

interface iProps {
  children: ReactNode;
}

export const ObjectivesProvider = ({ children }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const { objectives } = useContext(ResourceLoaderContext);
  const expandedGroups = useSelector(ObjectivesSelectors.getExpandedGroups);
  const expandedGroupsRef = useAsRef(expandedGroups);

  // Handle initialising everything when data loads
  useEffect(() => {
    dispatch(ObjectivesActionCreators.setObjectives(objectives as AsyncOperation<ObjectiveDataFromAPI>));
  }, [objectives.status]);

  const setExpandedGroups = useCallback((getter: (currentExpandedGroups: string[]) => string[]) => {
    const newExpandedGroups = getter(expandedGroupsRef.current);
    dispatch(ObjectivesActionCreators.setExpandedGroups(newExpandedGroups));
  }, []);

  // Load completed objectives on first launch (maybe should be done somewhere else)
  useEffect(() => {
    dispatch(ObjectivesActionCreators.loadCompletedObjectives());
  }, []);

  const value = useMemo<ObjectivesContextType>(
    () => ({
      expandedGroups,
      setExpandedGroups,
    }),
    [expandedGroups, setExpandedGroups]
  );

  return <ObjectivesContext.Provider value={value}>{children}</ObjectivesContext.Provider>;
};
