/**
 * @typedef {string} LayerDisplayMode
 */
/**
 * Enum for the different layer display modes the GardenPlanner can have
 *
 * @readonly
 * @enum {LayerType}
 */
const LayerDisplayModes = {
  ALL: 'ALL',
  VIEW_ONLY: 'VIEW_ONLY',
  PLANTS: 'PLANTS',
  STRUCTURES: 'STRUCTURES',
  IRRIGATION: 'IRRIGATION',
  TEXT: 'TEXT',
  LAYOUT: 'LAYOUT',
  LOCKED_ITEMS: 'LOCKED_ITEMS',
  BACKGROUND_IMAGES: 'BACKGROUND_IMAGES',
} as const;

type LayerDisplayModes = (typeof LayerDisplayModes)[keyof typeof LayerDisplayModes];

export default LayerDisplayModes;
