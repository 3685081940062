import React from 'react';

import { DefaultMobileTopbar } from '@gi/mobile-garden-platform-nav';
import AppHelpDesktop from '@gi/app-help-desktop';

const AppHelpMobile = (): JSX.Element => {
  return (
    <>
      <DefaultMobileTopbar title='Help & Support' />
      <AppHelpDesktop />
    </>
  );
};

export default AppHelpMobile;
