import { RequestError } from '@gi/gi-network';

import createPlanErrorCodes from './error-codes/create-plan-error-codes.json';
import loadPlanErrorCodes from './error-codes/load-plan-error-codes.json';
import savePlanErrorCodes from './error-codes/save-plan-error-codes.json';
import deletePlanErrorCodes from './error-codes/delete-plan-error-codes.json';
import publishPlanErrorCodes from './error-codes/publish-plan-error-codes.json';
import transferPlanErrorCodes from './error-codes/transfer-plan-error-codes.json';

const attachLoadPlanErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && loadPlanErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = loadPlanErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown load plan error - ${responseError.statusCode}`;
  }
};

const attachSavePlanErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && savePlanErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = savePlanErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown save plan error - ${responseError.statusCode}`;
  }
};

const attachCreatePlanErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && createPlanErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = createPlanErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown create plan error - ${responseError.statusCode}`;
  }
};

const attachDeletePlanErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && deletePlanErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = deletePlanErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown delete plan error - ${responseError.statusCode}`;
  }
};

const attachPublishPlanErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && publishPlanErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = publishPlanErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown publish plan error - ${responseError.statusCode}`;
  }
};

const attachTransferPlanErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && transferPlanErrorCodes[responseError.statusCode]) {
    responseError.clientMessage = transferPlanErrorCodes[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown transfer plan error - ${responseError.statusCode}`;
  }
};

export {
  attachLoadPlanErrorClientMessage,
  attachSavePlanErrorClientMessage,
  attachCreatePlanErrorClientMessage,
  attachDeletePlanErrorClientMessage,
  attachPublishPlanErrorClientMessage,
  attachTransferPlanErrorClientMessage,
};
