import JournalEntry from '../journal-entry';
import JournalEntries from '../journal-entries';
import JournalActions from '../journal-actions';

const weekStartDay = 6;

const zeroLPad = (number, length, zero = '0') => {
  const zeroes = [];
  zeroes.length = length + 1;
  const zeroesString = zeroes.join(zero);
  const padded = `${zeroesString}${number}`;
  return padded.substring(Math.max(padded.length - length, number.toString().length));
};

function modifyEntryImageURLs(entries) {
  entries.forEach((entry) => {
    entry.journalEntries.forEach((journalEntry) => {
      journalEntry.journalEntryImages.forEach((journalEntryImage) => {
        try {
          journalEntryImage.imageName = journalEntryImage.imageName.split('/').pop();
        } catch (e) {
          console.error('Failed to convert journal image URL');
          console.error(e);
        }
      });
    });
  });
}

const time = {
  ymd: (d, sep = '-') => {
    return [d.getFullYear(), zeroLPad(d.getMonth() + 1, 2), zeroLPad(d.getDate(), 2)].join(sep);
  },
  weekStart: (date, day = 1) => {
    date = new Date(date);

    if (Number.isNaN(date)) {
      // Invalid date. Avoid infinite loop by borking out.
      return undefined;
    }

    // Loop backwards until we find the right day
    while (date.getDay() !== day) {
      date.setDate(date.getDate() - 1);
    }
    // Done.
    return date;
  },
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
};

const weeklyTotalsFromDayEntryCounts = (year, plantCode, dayEntryCounts) => {
  const barchartData = [];
  const dailyTotals = {};

  // Convert totals into a YMD-keyed object
  dayEntryCounts.forEach((total) => {
    // By adding dayNo offset to the 1st of Jan.
    const date = new Date(year, 0, 1);
    date.setDate(total.dayNo);
    const ymd = time.ymd(date);
    dailyTotals[ymd] = {};
    dailyTotals[ymd][plantCode] = {};
    dailyTotals[ymd][plantCode].plantingEntries = parseInt(total.plantingEntryCount, 10) || 0;
    dailyTotals[ymd][plantCode].careEntries = parseInt(total.careEntryCount, 10) || 0;
    dailyTotals[ymd][plantCode].harvestingEntries = parseInt(total.harvestingEntryCount, 10) || 0;
    dailyTotals[ymd][plantCode].wateringEntries = parseInt(total.wateringEntryCount, 10) || 0;
    dailyTotals[ymd][plantCode].entries = parseInt(total.journalEntryCount, 10) || 0;
  });

  const day = time.weekStart(new Date(year, 0, 1), weekStartDay);

  // Add up totals for every week
  for (let i = 0; i <= 52; i += 1) {
    const weekBeginYmd = time.ymd(day);
    const weeklyData = {
      ymd: weekBeginYmd,
      values: {
        planting: 0,
        care: 0,
        harvesting: 0,
        watering: 0,
        entries: 0,
      },
      weekNo: i,
      dateCaption: `${time.shortMonths[day.getMonth()].toUpperCase()} ${day.getDate()}`,
    };

    if (dailyTotals[weekBeginYmd]) {
      // Add up each day's totals
      for (let j = 0; j < 7; j += 1) {
        const ymd = time.ymd(day);
        if (dailyTotals[ymd] && dailyTotals[ymd][plantCode]) {
          weeklyData.values.entries += dailyTotals[ymd][plantCode].entries;
          weeklyData.values.planting += dailyTotals[ymd][plantCode].plantingEntries;
          weeklyData.values.watering += dailyTotals[ymd][plantCode].wateringEntries;
          weeklyData.values.care += dailyTotals[ymd][plantCode].careEntries;
          weeklyData.values.harvesting += dailyTotals[ymd][plantCode].harvestingEntries;
        }
        day.setDate(day.getDate() + 1);
      }
    } else {
      console.error(`Missing totals data for ${weekBeginYmd}`, dailyTotals);
    }

    // Add it to barchartData
    barchartData.push(weeklyData);
  }

  return barchartData;
};

const journalEntryFromAPI = (entryObject) =>
  new JournalEntry({
    ID: entryObject.journalEntryID,
    note: entryObject.note,
    dateYMD: entryObject.dateYMD,
    relatedPlants: entryObject.journalEntryPlants,
    images: entryObject.journalEntryImages,
    dismissed: !!entryObject.taskCompleted,
  });

const journalEntriesFromAPI = (journalData) => {
  let journalEntries = new JournalEntries();

  // Go through each journal entry and modify existing journal image urls and
  // remove the old directory path
  modifyEntryImageURLs(journalData);

  for (let i = 0; i < journalData.length; i++) {
    const entryList = journalData[i].journalEntries.map((entry) => journalEntryFromAPI({ dateYMD: journalData[i].dateYMD, ...entry }));

    for (let j = 0; j < entryList.length; j++) {
      journalEntries = journalEntries.addEntry(entryList[j]);
    }
  }

  return journalEntries;
};

const journalEntryToAPI = (journalEntry) => {
  return {
    journalEntryID: journalEntry.ID || undefined,
    note: journalEntry.note,
    dateYMD: journalEntry.dateYMD,
    relatedPlants: [],
    isTask: journalEntry.isTask,
    taskCompleted: journalEntry.dismissed,
  };
};

const journalActionFromAPI = (actionType, dateYMD, action) => {
  let actionTypeForID = actionType;
  if (actionType === 'CARE') {
    actionTypeForID = action.actionPerformed;
  }
  return {
    ID: `${dateYMD}-${actionTypeForID}-${action.plantCode}-${action.varietyName}-${action.supplementName}`,
    actionType,
    dateYMD,
    ...action,
  };
};

const journalActionsFromAPI = (journalData) => {
  let journalActions = new JournalActions();
  journalData.forEach((data) => {
    const dateYMD = data.dateYMD;
    const entryList = [
      ...data.plantingEntries.map((entry) => journalActionFromAPI('PLANTING', dateYMD, entry)),
      ...data.wateringEntries.map((entry) => journalActionFromAPI('WATERING', dateYMD, entry)),
      ...data.harvestingEntries.map((entry) => journalActionFromAPI('HARVESTING', dateYMD, entry)),
      ...data.careEntries.map((entry) => journalActionFromAPI('CARE', dateYMD, entry)),
    ];

    entryList.forEach((entry) => {
      journalActions = journalActions.addAction(entry);
    });
  });
  return journalActions;
};

const journalFromAPI = (journalData) => {
  return {
    journalEntries: journalEntriesFromAPI(journalData),
    journalActions: journalActionsFromAPI(journalData),
  };
};

export { journalFromAPI, weeklyTotalsFromDayEntryCounts, journalEntryToAPI, journalEntryFromAPI, journalEntriesFromAPI };
