import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { batchActions } from 'redux-batched-actions';

import { ThunkExtraArgs } from '@gi/garden-platform-services';
import { DefaultNotificationOptions, generateNotificationID, NotificationActionCreators, NotificationTypes } from '@gi/notifications';
import { RequestActionCreators } from '@gi/react-requests';
import { GuruActionCreators } from './garden-guru-slice';

export const createSetBookmarkRequestKey = (contentId: number) => {
  return `SET_BOOKMARK_${contentId}`;
};

// Notifications
const savingBookmarkStart = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Updating Bookmarks',
  icon: 'icon-floppy',
  ID: notificationID,
  type: NotificationTypes.INFO,
  inProgress: true,
});
const savingBookmarkSuccessUpdate: DefaultNotificationOptions = {
  title: 'Bookmarks Saved',
  icon: 'icon-ok',
  type: NotificationTypes.SUCCESS,
  canTimeout: true,
};
const savingBookmarkFailUpdate: DefaultNotificationOptions = {
  title: 'Error Saving Bookmarks',
  icon: 'icon-attention-alt',
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
};

export function setBookmark(contentId: number, bookmark: boolean) {
  const notificationID = generateNotificationID();
  const requestKey = createSetBookmarkRequestKey(contentId);

  const action: ThunkAction<void, any, ThunkExtraArgs, AnyAction> = (dispatch, _getState, { services }) => {
    dispatch(
      batchActions([RequestActionCreators.requestStart(requestKey), NotificationActionCreators.createDefaultNotification(savingBookmarkStart(notificationID))])
    );

    services.guruService
      .setBookmark(contentId, bookmark)
      .then((response: string | null) => {
        dispatch(
          batchActions([
            RequestActionCreators.requestComplete(requestKey),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: savingBookmarkSuccessUpdate,
            }),
            GuruActionCreators.setBookmark([contentId, response]),
          ])
        );
      })
      .catch((err: unknown) => {
        if (err instanceof Error) {
          console.error(err);

          dispatch(
            batchActions([
              RequestActionCreators.requestFail(requestKey, err),
              NotificationActionCreators.updateNotificationByID({
                notificationID,
                update: savingBookmarkFailUpdate,
              }),
            ])
          );
        } else {
          console.error('Unknown error occurred');
        }
      });
  };

  return action;
}
