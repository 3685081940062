import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { SessionSelectors } from '@gi/react-session';
import { ResourceContext } from '@gi/resource-provider';
import { UserSubscriptionUtils } from '@gi/user';

import DashboardItem from '../../dashboard-item/dashboard-item';
import DashboardItemSection from '../../dashboard-item/dashboard-item-section';
import DashboardItemSectionTitle from '../../dashboard-item/dashboard-item-section-title';

import './account-dashboard-item.scss';

const AccountDashboardItem = (): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);
  const { userCountry } = useContext(ResourceContext);

  if (user === null) {
    return null;
  }

  const subscriptionText = (() => {
    if (!user.subscription.subscribed) {
      return UserSubscriptionUtils.hasFreeTrial(user.subscription) ? 'Free Trial' : 'Your free trial has ended';
    }
    if (UserSubscriptionUtils.hasSubscription(user.subscription)) {
      return `Active Subscription${!user.subscription.recurringSubscription ? ' (not recurring)' : ''}`;
    }
    return 'Subscription Expired';
  })();

  const timeRemainingText = (() => {
    const { accountExpires } = user.subscription;

    const days = accountExpires.diff(moment(), 'days');
    const date = accountExpires.format(userCountry?.longDateFormat ?? 'Do MMMM YYYY').trim();

    return UserSubscriptionUtils.hasSubscription(user.subscription) || UserSubscriptionUtils.hasFreeTrial(user.subscription) ? (
      <div>
        <span className='account-label'>Time Remaining:</span>
        <span className='account-status'>{`${days} day${days === 1 ? '' : 's'} (${date})`}</span>
      </div>
    ) : null;
  })();

  return (
    <DashboardItem title='Account' className='account-dashboard-item'>
      <DashboardItemSection>
        <DashboardItemSectionTitle>Subscription Details</DashboardItemSectionTitle>
        <div className='dashboard-block'>
          <div>
            <span className='account-label'>Account Status:</span>
            <span className='account-status'>{subscriptionText}</span>
          </div>
          <div>{timeRemainingText}</div>
          <div>
            <a href='/account/settings/subscription' className='button dashboard-link-button' target='_blank' rel='noreferrer'>
              {user.subscription.recurringSubscription ? 'Manage Subscription' : 'Add Subscription'}
            </a>
          </div>
        </div>
      </DashboardItemSection>
    </DashboardItem>
  );
};

export default AccountDashboardItem;
