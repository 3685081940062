import { Record } from 'immutable';
import { midpoint, angleBetweenPoints } from '@gi/math/source/geometry';

import SpriteSource, { createSpriteSource } from '@gi/sprite-source';

type PlantType = {
  ID: number;
  code: string;
  name: string;
  otherName: string;
  scientificName: string;
  otherScientificNames: string;
  searchNames: string;
  countryCode: string;
  spacing: number;
  inRowSpacing: number;
  rowSpacing: number;
  familyID: number;
  sprite: SpriteSource;
  icon: {
    name: string;
    filename: string;
    width: number;
    height: number;
  };
  companionPlantCodes: string[];
  canBeSquareFootPlant: true;
  squareFootPlantCount: number;
  plantRelativeLastFrost: number;
  sowStartIndoors: number | null;
  lastSowRelativeToLastFrost: number;
  lastPlantingTime: number;
  timeToMaturity: number;
  harvestRelativeToFirstFrost: number;
  canSplitSeason: boolean;
  sow1Start: number | null;
  sow1End: number | null;
  plant1Start: number | null;
  plant1End: number | null;
  harvest1Start: number | null;
  harvest1End: number | null;
  sow2Start: number | null;
  sow2End: number | null;
  plant2Start: number | null;
  plant2End: number | null;
  harvest2Start: number | null;
  harvest2End: number | null;
  perennial: boolean;
  text: {
    soil: string;
    position: string;
    sowPlant: string;
    frostTolerant: string;
    feeding: string;
    notes: string;
    harvesting: string;
    companions: string;
    troubleshooting: string;
  };
  images: { landscape: string; portrait: string };
  tags: { [key: string]: boolean }; // TODO: Change to string[]
  isNew: boolean;
};

const _Plant: PlantType = {
  ID: 0,
  code: '',
  name: '',
  otherName: '',
  scientificName: '',
  otherScientificNames: '',
  searchNames: '',
  countryCode: '',
  spacing: 0,
  inRowSpacing: 0,
  rowSpacing: 0,
  familyID: 0,
  sprite: createSpriteSource({ height: 0, width: 0, name: '' }),
  icon: {
    name: '',
    filename: '',
    width: 0,
    height: 0,
  },
  companionPlantCodes: [],
  canBeSquareFootPlant: true,
  squareFootPlantCount: 0,
  plantRelativeLastFrost: 0,
  sowStartIndoors: 0,
  lastSowRelativeToLastFrost: 0,
  lastPlantingTime: 0,
  timeToMaturity: 0,
  harvestRelativeToFirstFrost: 0,
  canSplitSeason: false,
  sow1Start: 0,
  sow1End: 0,
  plant1Start: 0,
  plant1End: 0,
  harvest1Start: 0,
  harvest1End: 0,
  sow2Start: 0,
  sow2End: 0,
  plant2Start: 0,
  plant2End: 0,
  harvest2Start: 0,
  harvest2End: 0,
  perennial: false,
  text: {
    soil: '',
    position: '',
    sowPlant: '',
    frostTolerant: '',
    feeding: '',
    notes: '',
    harvesting: '',
    companions: '',
    troubleshooting: '',
  },
  images: { landscape: '', portrait: '' },
  tags: {},
  isNew: false,
};

class Plant extends Record(_Plant) {
  get spacings() {
    return {
      spacing: this.spacing,
      rowSpacing: this.rowSpacing,
      inRowSpacing: this.inRowSpacing,
    };
  }

  /**
   * Returns the spacing between plants, spacing is different when planted in a row (height is 0)
   *
   * @param {Number} width
   * @param {Number} height
   */
  getSpacing(width, height) {
    if (Plant.isRow(width, height)) {
      return this.inRowSpacing;
    }

    return this.spacing;
  }

  /**
   * Returns true if the plant has the given tag
   *
   * @param {String} tag
   * @returns {boolean}
   * @memberof Plant
   */
  hasTag(tag) {
    return !!this.tags[tag];
  }

  /**
   * @param {number} width
   * @param {number} height
   */
  static isSingle = (width, height) => {
    return height === 0 && width === 0;
  };

  /**
   * @param {number} width
   * @param {number} height
   */
  static isRow = (width, height) => {
    return height === 0 && width !== 0;
  };

  /**
   * @param {Point} rowStart
   * @param {Point} rowEnd
   * @param {number} height
   */
  static calculateCenterOfPlant = (rowStart, rowEnd, height) => {
    const mid = midpoint(rowStart, rowEnd);
    const rowAngle = angleBetweenPoints(rowStart, rowEnd);
    return {
      x: mid.x + Math.cos(rowAngle + Math.PI / 2) * (height / 2),
      y: mid.y + Math.sin(rowAngle + Math.PI / 2) * (height / 2),
    };
  };
}

export default Plant;
