import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { ModalActionCreators } from '@gi/garden-platform-modal-renderer';
import GardenObject, { GardenObjectScalingMode } from '@gi/garden-object';
import PlannerIcon from '@gi/planner-icon';
import { SearchDisplayMode, SearchResult } from '@gi/search-service';
import { Tooltip, TooltipContent, TooltipKey, TooltipTrigger } from '@gi/tooltip';
import { GardenObjectType } from '@gi/constants';
import { ResourceContext } from '@gi/resource-provider';
import { Store } from '@gi/products';

import { DrawingToolsContext } from '../drawing-tools-context';
import DragToDrawButton from '../drag-to-draw/drag-to-draw-button';

const renderSeasonExtenderIcon = (gardenObject: GardenObject) => {
  if (gardenObject.plantModifier === null) {
    return null;
  }

  return <div className='extra-icon'>Season Extender</div>;
};

const renderPurchasableIcon = (store: Store | null, object: GardenObject) => {
  if (store && store.products.productsByCode[object.code]) {
    return (
      <div className='extra-icon'>
        <i className='icon-shopping-cart' />
      </div>
    );
  }

  return null;
};

const renderPresetsIcon = (gardenObject: GardenObject) => {
  if (gardenObject.shape.type === GardenObjectType.BLOCK && gardenObject.shape.scalingMode === GardenObjectScalingMode.PRESETS) {
    if (gardenObject.shape.presets && gardenObject.shape.presets.length > 0) {
      return <div className='extra-icon'>{gardenObject.shape.presets.length} Preset Sizes</div>;
    }
  }

  return null;
};

const renderName = (gardenObject: GardenObject) => {
  return (
    <div className='item-name'>
      <span className='item-name-primary'>{gardenObject.shortName ?? gardenObject.name}</span>
    </div>
  );
};

const renderSearchedName = (gardenObject: GardenObject, searchResult: SearchResult<GardenObject>) => {
  switch (searchResult.displayMode) {
    case SearchDisplayMode.Primary: {
      return (
        <div className='item-name'>
          <span className='item-name-primary'>{searchResult.getHtml()}</span>
        </div>
      );
    }
    case SearchDisplayMode.Secondary: {
      return (
        <div className='item-name'>
          <span className='item-name-primary'>{gardenObject.name}</span>
          <span className='item-name-secondary'>
            <em title={searchResult.getText()}>{searchResult.getHtml()}</em>
          </span>
        </div>
      );
    }
    default: {
      return (
        <div className='item-name'>
          <span className='item-name-primary'>{gardenObject.name}</span>
        </div>
      );
    }
  }
};

interface iProps {
  gardenObject: GardenObject;
  isInGroup?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  style?: React.CSSProperties;
}

const GardenObjectListItem = ({ gardenObject, isInGroup = false, isFirst = false, isLast = false, style = {} }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedGardenObjectCode, gardenObjectFilters, onGardenObjectSelected, cancelDraw } = useContext(DrawingToolsContext);
  const { store } = useContext(ResourceContext);

  const { searchResults } = gardenObjectFilters.filters.search.inputs;
  const searchResult = searchResults.getResult(gardenObject.code);

  const isSelected = selectedGardenObjectCode === gardenObject.code;

  const handleSelect = () => {
    if (isSelected) {
      cancelDraw();
    } else {
      onGardenObjectSelected(gardenObject);
    }
  };

  const handleDragStart = (e: PointerEvent) => {
    onGardenObjectSelected(gardenObject, e);
  };

  const handleInfoRequest = () => {
    dispatch(ModalActionCreators.openGardenObjectInfoModal(gardenObject));
  };

  const classNames: string[] = ['selectable-option'];
  if (isInGroup) {
    classNames.push('is-in-group');
  }
  if (isFirst) {
    classNames.push('is-first');
  }
  if (isLast) {
    classNames.push('is-last');
  }
  if (isSelected) {
    classNames.push('selected');
  }

  return (
    <li className={classNames.join(' ')} style={style} data-code={gardenObject.code} data-intercom-identifier='selectable-garden-object'>
      <div className='selectable-option-subs'>
        <Tooltip placement='right' delayOpen={500}>
          <TooltipTrigger>
            <button
              type='button'
              className='selectable-option-info'
              onClick={handleInfoRequest}
              data-tooltip-id={`drawing-tools-object-info:${gardenObject.code}`}
            >
              <i className='icon-info-circled' />
            </button>
          </TooltipTrigger>
          <TooltipContent id={TooltipKey.GardenObjectInformation} />
        </Tooltip>
      </div>
      <DragToDrawButton
        className='selectable-option-item'
        onClick={handleSelect}
        onDrag={handleDragStart}
        data-intercom-identifier='selectable-garden-object-button'
      >
        <div className='icon-container'>
          <div className=' selectable-garden-object-icon'>
            <PlannerIcon code={gardenObject.code} />
          </div>
        </div>
        {searchResult ? renderSearchedName(gardenObject, searchResult) : renderName(gardenObject)}
        <div className='extra-icon-container'>
          {renderSeasonExtenderIcon(gardenObject)}
          {renderPresetsIcon(gardenObject)}
          {renderPurchasableIcon(store, gardenObject)}
        </div>
      </DragToDrawButton>
    </li>
  );
};

export default GardenObjectListItem;
