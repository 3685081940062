import React, { useMemo } from 'react';

import { Anchor, anchorAsVector } from '@gi/constants';
import { PlanClockwiseRotationAmount } from '@gi/plan';
import { DistanceUnits } from '@gi/units';

import PlanDiagramSVG from './svg/plan-diagram-svg';
import { PlanDiagramSVGElements } from './svg/elements';
import { PlanSize, getBoundingBoxOfPlans } from './svg/utils';

interface iProps {
  initialWidth: number;
  initialHeight: number;
  width: number;
  height: number;
  rotation: false | PlanClockwiseRotationAmount;
  anchor: Anchor;
  distanceUnits: DistanceUnits;
  displayWidth?: number;
  displayHeight?: number;
}

const PlanDimensionsDiffPreview = ({
  initialWidth,
  initialHeight,
  width,
  height,
  rotation,
  anchor,
  distanceUnits,
  displayWidth,
  displayHeight,
}: iProps): JSX.Element => {
  const isHalfRotate = useMemo(() => {
    return rotation === PlanClockwiseRotationAmount.Rotate90 || rotation === PlanClockwiseRotationAmount.Rotate270;
  }, [rotation]);

  const originalPlan = useMemo<PlanSize>(() => {
    if (isHalfRotate) {
      return {
        x: 0,
        y: 0,
        width: initialHeight,
        height: initialWidth,
      };
    }
    return {
      x: 0,
      y: 0,
      width: initialWidth,
      height: initialHeight,
    };
  }, [rotation, initialWidth, initialHeight, isHalfRotate]);

  const newPlan = useMemo<PlanSize>(() => {
    const direction = anchorAsVector(anchor);
    if (isHalfRotate) {
      const tempX = direction.x;
      direction.x = direction.y;
      direction.y = tempX;
    }
    const offsetX = (-(direction.x + 1) / 2) * (width - initialWidth);
    const offsetY = (-(direction.y + 1) / 2) * (height - initialHeight);
    if (isHalfRotate) {
      return {
        width: height,
        height: width,
        x: offsetY,
        y: offsetX,
      };
    }
    return {
      width,
      height,
      x: offsetX,
      y: offsetY,
    };
  }, [width, height, initialWidth, initialHeight, anchor, rotation, isHalfRotate]);

  const bounds = useMemo(() => getBoundingBoxOfPlans(originalPlan, newPlan), [originalPlan, newPlan]);

  return (
    <PlanDiagramSVG bounds={bounds} displayWidth={displayWidth} displayHeight={displayHeight}>
      <PlanDiagramSVGElements.AddedArea originalPlan={originalPlan} newPlan={newPlan} />
      <PlanDiagramSVGElements.RemovedArea originalPlan={originalPlan} newPlan={newPlan} />
      <PlanDiagramSVGElements.UnchangedArea originalPlan={originalPlan} newPlan={newPlan} />
      <PlanDiagramSVGElements.DirectionIndicator plan={originalPlan} rotation={rotation} />
      <PlanDiagramSVGElements.PlanOutline plan={originalPlan} variant='dashed' />
      <PlanDiagramSVGElements.PlanOutline plan={newPlan} variant='solid' showDistances distanceUnits={distanceUnits} />
    </PlanDiagramSVG>
  );
};

export default PlanDimensionsDiffPreview;
