import React, { useContext, useState } from 'react';

import { updateFilters } from '@gi/filters';
import { CropRotationHelpModal } from '@gi/help-modals';
import { useHelpModalEventHook } from '@gi/garden-platform-events';

import FilterRowGroup from '../../../filters/filter-row-group';
import FilterRowGroupTitle from '../../../filters/filter-row-group-title';
import FilterRow from '../../../filters/filter-row';
import CropFamilyDropdown from './crop-family-dropdown';
import { DrawingToolsContext } from '../../../drawing-tools-context';

const CropFamilyFilterView = (): JSX.Element => {
  const { plantFilters, setPlantFilters } = useContext(DrawingToolsContext);

  const [modalOpen, setModalOpen] = useState(false);

  useHelpModalEventHook('crop rotation', modalOpen, 'drawing-tools');

  const onChange = (familyID) => {
    setPlantFilters(updateFilters(plantFilters, { cropFamily: { familyID } }));
  };

  return (
    <>
      {modalOpen ? (
        <CropRotationHelpModal
          closeModal={() => {
            setModalOpen(false);
          }}
        />
      ) : null}
      <FilterRowGroup>
        <FilterRowGroupTitle>Crop Rotation Family</FilterRowGroupTitle>
        <FilterRow
          onHelp={() => {
            setModalOpen(true);
          }}
        >
          <CropFamilyDropdown selectedFamilyID={plantFilters.filters.cropFamily.inputs.familyID} onSelectedFamilyChange={onChange} />
        </FilterRow>
      </FilterRowGroup>
    </>
  );
};

export default CropFamilyFilterView;
