import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import { batchActions } from 'redux-batched-actions';
import isEqual from 'lodash/isEqual';

import { DeviceDisplayMode, LoadingState } from '@gi/constants';
import { SessionSelectors } from '@gi/react-session';
import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { TutorialContext } from '@gi/tutorials';
import { notEmpty } from '@gi/utils';
import { useDebounce } from '@gi/react-utils';
import { ResourceContext } from '@gi/resource-provider';
import { config } from '@gi/config';
import { AppContext } from '@gi/app-provider';
import { InitParamsActionCreators, InitParamsSelectors } from '@gi/init-params';
import { Application } from '@gi/garden-platform-navigation';
import { FeaturesContext } from '@gi/features';

import IntercomService from './intercom-service';
import { iIntercomCommandAction, IntercomActionCreators, IntercomSelectors } from './intercom-slice';
import { createIntercomCommandActionFromShortcut, getIntercomShortcutFromType } from './shortcuts/intercom-shortcuts';
import { IntercomShortcutApp, IntercomShortcutAppPathMatch } from './shortcuts/intercom-shortcut-types';
import { getIntercomUpdate, iIntercomUpdate, initialiseIntercom, updateIntercom } from './intercom';
import { validateIntercomCommand } from './intercom-utils';

const appID = config.intercom.id;

const intercomService = new IntercomService();

function getActivePath(pathname: string): IntercomShortcutApp | null {
  if (matchPath(IntercomShortcutAppPathMatch[IntercomShortcutApp.Overview], pathname)) {
    return IntercomShortcutApp.Overview;
  }
  if (matchPath(IntercomShortcutAppPathMatch[IntercomShortcutApp.Planner], pathname)) {
    return IntercomShortcutApp.Planner;
  }
  if (matchPath(IntercomShortcutAppPathMatch[IntercomShortcutApp.Journal], pathname)) {
    return IntercomShortcutApp.Journal;
  }
  if (matchPath(IntercomShortcutAppPathMatch[IntercomShortcutApp.Guru], pathname)) {
    return IntercomShortcutApp.Guru;
  }

  return null;
}

/**
 * IntercomController
 *
 * Initialises Intercom when all required data is loaded
 *
 * Updates intercom when updated data is detected
 *
 * Runs the initial Intercom commands provided via GET parameters when the app is loaded
 * (Can also be specified by LocalStorage)
 * Note:- to be removed in near future when we no longer have a use for it
 *
 * Continually looks at the queue of intercom shortcuts waiting to be processed
 * and runs each one when conditions are met
 */
const IntercomController = (): null => {
  const dispatch = useDispatch();

  const { userCountry } = useContext(ResourceContext);
  const { runtimeConfig } = useContext(AppContext);

  const queue = useSelector(IntercomSelectors.getQueuedShortcuts);
  const shortcutDefinitions = useSelector(IntercomSelectors.getShortcutDefinitions);
  const userId = useSelector(SessionSelectors.getUserID);
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);
  const texturesLoaded = useSelector(CanvasSelectors.getTexturesLoaded);
  const user = useSelector(SessionSelectors.getUser);
  const { intercomAction, intercomApp, intercomDesktopActionId, intercomMobileActionId } = useSelector(InitParamsSelectors.getInitParams);
  const { featureIds } = useContext(FeaturesContext);

  const [initialised, setInitialised] = useState(false);
  const [previousIntercomUpdateData, setPreviousIntercomUpdateData] = useState<iIntercomUpdate | null>(null);

  // Debounce the user so we don't call update too often
  const debouncedUser = useDebounce(user, 2000);
  // Also debounce the display mode, just in case
  const debouncedDisplayMode = useDebounce(deviceDisplayMode, 2000);

  const { pathname } = useLocation();
  const activePath = useMemo(() => getActivePath(pathname), [pathname]);

  const { touchControlsTutorialVisible, intercomHelpButtonTutorialVisible, touchModeIntroductionModalVisible } = useContext(TutorialContext);

  const activeApp = useMemo(() => {
    if (activePath === IntercomShortcutApp.Planner) {
      if (texturesLoaded && !intercomHelpButtonTutorialVisible && !touchControlsTutorialVisible && !touchModeIntroductionModalVisible) {
        return IntercomShortcutApp.Planner;
      }
      // Planner is active but not yet loaded
      return null;
    }

    // Add other required loading checks here for other apps

    return activePath;
  }, [texturesLoaded, intercomHelpButtonTutorialVisible, touchControlsTutorialVisible, touchModeIntroductionModalVisible, activePath]);

  /**
   * Set initial intercom properties
   */
  useEffect(() => {
    if (!initialised && user !== null && userCountry !== null) {
      initialiseIntercom(appID, user, userCountry, runtimeConfig, deviceDisplayMode, featureIds.join(', '));
      setInitialised(true);
      setPreviousIntercomUpdateData(getIntercomUpdate(user, userCountry, deviceDisplayMode));
    }
  }, [user, userCountry]);

  /**
   * Check for intercom updates
   */
  useEffect(() => {
    if (initialised && debouncedUser !== null && userCountry !== null) {
      const intercomUpdateData = getIntercomUpdate(debouncedUser, userCountry, debouncedDisplayMode);

      if (!isEqual(intercomUpdateData, previousIntercomUpdateData)) {
        setPreviousIntercomUpdateData(intercomUpdateData);
        updateIntercom(intercomUpdateData);
      }
    }
  }, [debouncedUser, userCountry, debouncedDisplayMode]);

  /**
   * Run intercom startup command
   *
   * TODO: In future remove this when we no longer need it
   */
  useEffect(() => {
    if (initialised && intercomApp === Application.GardenPlanner && activeApp === IntercomShortcutApp.Planner) {
      if (texturesLoaded && !intercomHelpButtonTutorialVisible && !touchControlsTutorialVisible && !touchModeIntroductionModalVisible) {
        const command = validateIntercomCommand(intercomAction);

        if (command !== null) {
          const actionId = deviceDisplayMode === DeviceDisplayMode.DESKTOP ? intercomDesktopActionId : intercomMobileActionId;
          dispatch(batchActions([IntercomActionCreators.createIntercomCommandAction(command, actionId), InitParamsActionCreators.clearIntercomInitParams()]));
        }
      }
    }
  }, [
    initialised,
    texturesLoaded,
    intercomAction,
    intercomApp,
    intercomDesktopActionId,
    intercomMobileActionId,
    intercomHelpButtonTutorialVisible,
    touchControlsTutorialVisible,
    touchModeIntroductionModalVisible,
  ]);

  /**
   * Loads the initial intercom shortcut definitions and sets it in the intercom reducer
   */
  useEffect(() => {
    if (shortcutDefinitions.status === LoadingState.NONE && user !== null) {
      dispatch(IntercomActionCreators.setIntercomShortcutDefinitions({ status: LoadingState.LOADING }));
      intercomService
        .loadIntercomConfig()
        .then((intercomCommands) => {
          console.debug('Intercom definitions loaded');
          dispatch(
            IntercomActionCreators.setIntercomShortcutDefinitions({
              status: LoadingState.SUCCESS,
              value: intercomCommands,
            })
          );
        })
        .catch((e) => {
          console.error('Failed to load intercom commands');
          console.error(e);
          dispatch(
            IntercomActionCreators.setIntercomShortcutDefinitions({
              status: LoadingState.ERROR,
              error: e,
            })
          );
        });
    }
  }, [user === null, shortcutDefinitions]);

  /**
   * Runs any queued intercom shortcuts that are currently valid
   */
  useEffect(() => {
    if (userId === null) {
      return;
    }

    if (shortcutDefinitions.status !== LoadingState.SUCCESS) {
      // Command definitions not yet loaded
      return;
    }

    const shortcuts = queue
      .map((queuedItem) => getIntercomShortcutFromType(queuedItem, shortcutDefinitions.value))
      .filter(notEmpty)
      .filter((shortcut) => {
        return !shortcut.app || shortcut.app === activeApp;
      });

    const shortcutsToExecute = shortcuts
      .map((shortcut) => createIntercomCommandActionFromShortcut(shortcut, userId, deviceDisplayMode))
      .filter((command) => command !== null) as iIntercomCommandAction[];

    dispatch(batchActions([...shortcutsToExecute, IntercomActionCreators.removeQueuedShortcuts(shortcuts.map((shortcut) => shortcut.id))]));
  }, [queue, activeApp, shortcutDefinitions]);

  return null;
};

export default IntercomController;
