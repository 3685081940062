import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {{
 *  active?: boolean
 * }}
 */
const AccountIcon = ({ active = false }) => {
  return <i className={`icon-user-circle-o sidebar-icon ${active ? 'sidebar-icon-active' : ''}`} />;
};

AccountIcon.propTypes = {
  active: PropTypes.bool,
};

export default AccountIcon;
