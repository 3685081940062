export enum TooltipKey {
  Settings = 'Settings',
  NewPlan = 'NewPlan',
  OpenPlan = 'OpenPlan',
  SavePlan = 'SavePlan',
  PrintPlan = 'Print/PDF',
  PrintPlantList = 'PrintPlantList',
  PrintPartsList = 'PrintPartsList',
  PrintNotes = 'PrintNotes',
  PublishPlan = 'PublishPlan',
  PlanImage = 'PlanImage',
  TransferPlan = 'TransferPlan',
  Undo = 'Undo',
  Redo = 'Redo',
  Cut = 'Cut',
  Copy = 'Copy',
  Paste = 'Paste',
  Delete = 'Delete',
  SelectionTool = 'SelectionTool',
  EditItem = 'EditItem',
  ZoomIn = 'ZoomIn',
  ZoomOut = 'ZoomOut',
  LockItems = 'LockItems',
  UnlockItems = 'UnlockItems',
  BringToFront = 'BringToFront',
  SendToBack = 'SendToBack',
  ResetZIndex = 'ResetZIndex',
  ToggleGrid = 'ToggleGrid',
  ToggleRulers = 'ToggleRulers',
  OpenSidebar = 'OpenSidebar',
  SidebarOverview = 'SidebarOverview',
  SidebarGardenPlanner = 'SidebarGardenPlanner',
  SidebarJournal = 'SidebarJournal',
  SidebarGuru = 'SidebarGuru',
  SidebarHelp = 'SidebarHelp',
  SidebarAccount = 'SidebarAccount',
  CloseSidebar = 'CloseSidebar',
  FavouritePlant = 'FavouritePlant',
  UnfavouritePlant = 'UnfavouritePlant',
  PlantInformation = 'PlantInformation',
  GardenObjectInformation = 'GardenObjectInformation',
  DrawingToolsPlants = 'DrawingToolsPlants',
  DrawingToolsGardenObjects = 'DrawingToolsGardenObjects',
  DrawingToolsShapes = 'DrawingToolsShapes',
  DrawingToolsText = 'DrawingToolsText',
  PlantListDisplayVariety = 'PlantListDisplayVariety',
  PlantListDisplaySummary = 'PlantListDisplaySummary',
  PlantListDisplayArea = 'PlantListDisplayArea',
  RightSidebarObjectives = 'LearnToUse',
  RightSidebarNotes = 'Notes',
  RightSidebarBackgroundImage = 'PlanBackgroundImage',
}

export const DEFAULT_TOOLTIPS: Record<TooltipKey, string> = {
  [TooltipKey.Settings]: 'Settings',
  [TooltipKey.NewPlan]: 'New Plan {{key:ctrl}} + {{key:g}}',
  [TooltipKey.OpenPlan]: 'Open Plan {{key:ctrl}} + {{key:i}}',
  [TooltipKey.SavePlan]: 'Save Plan {{key:ctrl}} + {{key:s}}',
  [TooltipKey.PrintPlan]: 'Print/PDF',
  [TooltipKey.PrintPlantList]: 'Print',
  [TooltipKey.PrintPartsList]: 'Print',
  [TooltipKey.PrintNotes]: 'Print',
  [TooltipKey.PublishPlan]: 'Publish',
  [TooltipKey.TransferPlan]: 'Transfer',
  [TooltipKey.PlanImage]: 'Image',
  [TooltipKey.Undo]: 'Undo {{key:ctrl}} + {{key:z}}',
  [TooltipKey.Redo]: 'Redo {{key:ctrl}} + {{key:y}}',
  [TooltipKey.Cut]: 'Cut {{key:ctrl}} + {{key:x}}',
  [TooltipKey.Copy]: 'Copy {{key:ctrl}} + {{key:c}}',
  [TooltipKey.Paste]: 'Paste {{key:ctrl}} + {{key:v}}',
  [TooltipKey.Delete]: 'Delete {{key:del}}',
  [TooltipKey.SelectionTool]: 'Selection Tool',
  [TooltipKey.EditItem]: 'Edit Item {{key:ctrl}} + {{key:e}}',
  [TooltipKey.ZoomIn]: 'Zoom In {{key:ctrl}} + {{key:]}}',
  [TooltipKey.ZoomOut]: 'Zoom Out {{key:ctrl}} + {{key:[}}',
  [TooltipKey.LockItems]: 'Lock Selected Items',
  [TooltipKey.UnlockItems]: 'Unlock Selected Items',
  [TooltipKey.BringToFront]: 'Bring to Front',
  [TooltipKey.SendToBack]: 'Send to Back',
  [TooltipKey.ResetZIndex]: 'Reset Ordering',
  [TooltipKey.ToggleGrid]: 'Toggle Grid',
  [TooltipKey.ToggleRulers]: 'Toggle Rulers',
  [TooltipKey.OpenSidebar]: 'Open Sidebar',
  [TooltipKey.CloseSidebar]: 'Close Sidebar',
  // TODO: Maybe use ApplicationNames as default for these (adds another dep though)
  [TooltipKey.SidebarOverview]: 'Overview',
  [TooltipKey.SidebarGardenPlanner]: 'Garden Planner',
  [TooltipKey.SidebarJournal]: 'Garden Journal',
  [TooltipKey.SidebarGuru]: 'Garden Guru',
  [TooltipKey.SidebarHelp]: 'Help & Support',
  [TooltipKey.SidebarAccount]: 'Account',
  // END TODO
  [TooltipKey.FavouritePlant]: 'Favorite {{parameter:1}}',
  [TooltipKey.UnfavouritePlant]: 'Remove from Favorites',
  [TooltipKey.PlantInformation]: 'Plant Information',
  [TooltipKey.GardenObjectInformation]: 'More Information',
  [TooltipKey.DrawingToolsPlants]: 'Add a Plant',
  [TooltipKey.DrawingToolsGardenObjects]: 'Add a Garden Object',
  [TooltipKey.DrawingToolsShapes]: 'Add a Shape',
  [TooltipKey.DrawingToolsText]: 'Add Text',
  [TooltipKey.PlantListDisplayVariety]: 'Groups rows by plant variety',
  [TooltipKey.PlantListDisplaySummary]: 'Groups rows by plant',
  [TooltipKey.PlantListDisplayArea]: 'Groups rows by each individual planting',
  [TooltipKey.RightSidebarObjectives]: 'Learn to Use',
  [TooltipKey.RightSidebarNotes]: 'Notes',
  [TooltipKey.RightSidebarBackgroundImage]: 'Plan Background Image',
};

export type TooltipsFromAPI = { tooltips: Record<string, string> };

type TooltipContentDefinitionType<TType extends string, TData extends Record<string, any>> = { type: TType } & TData;

/** Represents a basic string in a tooltip */
export type TooltipStringContentDefinition = TooltipContentDefinitionType<'string', { content: string }>;
/** Represents a single key (used for displaying keybinds) */
export type TooltipKeyContentDefinition = TooltipContentDefinitionType<'key', { key: string }>;
/** Represents a parameter that will be programmatically replaced when displayed */
export type TooltipParameterContentDefinition = TooltipContentDefinitionType<'parameter', { parameter: number }>;

export type TooltipContentDefinition = (TooltipStringContentDefinition | TooltipKeyContentDefinition | TooltipParameterContentDefinition)[];

export type ParsedTooltips = Record<TooltipKey | string, TooltipContentDefinition>;

export type TooltipDelay =
  | number
  | Partial<{
      open: number;
      close: number;
    }>;

export function mergeDelays(delay1: TooltipDelay, delay2: TooltipDelay): TooltipDelay {
  return {
    open: (typeof delay1 === 'number' ? delay1 : delay1.open) ?? (typeof delay2 === 'number' ? delay2 : delay2.open),
    close: (typeof delay1 === 'number' ? delay1 : delay1.close) ?? (typeof delay2 === 'number' ? delay2 : delay2.close),
  };
}
