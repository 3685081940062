import type { State } from './state';
import { AnyStateDef, OtherStatePropertiesDef, OtherStatePropertiesDefAsArray, StatePropertiesDef } from './state-types';

export class StateProperties<TState extends AnyStateDef> {
  #state: State<TState>;
  get state() {
    return this.#state;
  }

  #properties: (keyof TState['state'])[];
  /** Returns the property names specified to watch on the attached state */
  get properties(): Readonly<(keyof TState['state'])[]> {
    return this.#properties;
  }

  #signals: TState['signals'][number][];
  /** Returns the signal names specified to watch on the attached state */
  get signals(): Readonly<TState['signals'][number][]> {
    return this.#signals;
  }

  #otherStates: OtherStatePropertiesDef<TState['otherStates']>;
  /** Returns an object containing all the other state properties and signals to watch */
  get otherStates(): Readonly<OtherStatePropertiesDef<TState['otherStates']>> {
    return this.#otherStates;
  }

  #otherStatesAsArray: OtherStatePropertiesDefAsArray<TState['otherStates']>;
  /** Returns otherStates, but as an array that's more efficient to work with in the update loop */
  get otherStatesAsArray() {
    return this.#otherStatesAsArray;
  }

  /** Is this watching for ANY change on the state */
  #isAny: boolean;
  get any() {
    return this.#isAny;
  }

  /** We have to use custom logic for validators, as they're not batched */
  isValidator: boolean = false;

  constructor(state: State<TState>, { properties = [], signals = [], otherStates = {} }: StatePropertiesDef<TState>) {
    this.#state = state;
    this.#properties = properties;
    this.#signals = signals;
    this.#otherStates = otherStates;
    this.#otherStatesAsArray = Object.keys(this.#otherStates).map((key) => ({
      name: key,
      properties: otherStates[key]?.properties,
      signals: otherStates[key]?.signals,
    }));
    this.#isAny = properties.length === 0 && signals.length === 0 && Object.keys(otherStates).length === 0;
  }

  #getHasUpdated<T extends State<any>>(state: T): { hasUpdated: boolean; changed: T['changed'] | T['nextChanged'] } {
    if (!this.isValidator) {
      return { hasUpdated: state.hasUpdated, changed: state.changed };
    }
    /**
     * Silly logic for validators:
     *  As validators aren't batched, when checking otherStates, some may have been committed before this one,
     *    thus making some show as having been updated, but others haven't.
     *  We need to check if the state has already been committed with hasUpdated, and if not, we use the
     *    hasUpdatesWaiting flag to see if it will be committed this batch.
     */
    return {
      hasUpdated: state.hasUpdated || state.hasUpdatesWaiting,
      changed: state.hasUpdated ? state.changed : state.nextChanged,
    };
  }

  /** Have any of the properties we're watching on the state changed this frame */
  hasPropertyChanged(): boolean {
    const { hasUpdated, changed } = this.#getHasUpdated(this.state);
    if (!hasUpdated) {
      return false;
    }
    return this.properties.some((property) => changed.properties[property]);
  }

  /** Have any of the signals we're watching on the state been fired this frame */
  hasSignalTriggered(): boolean {
    const { hasUpdated, changed } = this.#getHasUpdated(this.state);
    if (!hasUpdated) {
      return false;
    }
    return this.signals.some((property) => changed.signals[property]);
  }

  /** Have any of the other properties or signals on the other states we're watching changed this frame */
  hasOtherStatesChanged(): boolean {
    const { hasUpdated, changed } = this.#getHasUpdated(this.state);
    return Object.keys(this.otherStates).some((otherStateName) => {
      const otherState = this.otherStates[otherStateName];
      const otherStateProperties = otherState?.properties;
      const otherStateSignals = otherState?.signals;
      if (hasUpdated && changed.otherStates[otherStateName]) {
        return true;
      }
      const state = this.state.otherStates[otherStateName];
      if (!state) {
        return false;
      }
      const { hasUpdated: otherStateHasUpdates, changed: otherStateChanged } = this.#getHasUpdated(state);
      if (!otherStateHasUpdates) {
        return false;
      }
      return (
        (otherStateProperties ? otherStateProperties.some((property) => otherStateChanged.properties[property]) : false) ||
        (otherStateSignals ? otherStateSignals.some((signal) => otherStateChanged.signals[signal]) : false)
      );
    });
  }

  /** Has anything we're watching changed this frame */
  hasChanged() {
    const { hasUpdated } = this.#getHasUpdated(this.state);
    return (hasUpdated && this.#isAny) || this.hasPropertyChanged() || this.hasSignalTriggered() || this.hasOtherStatesChanged();
  }
}
