import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { SessionSelectors } from '@gi/react-session';

/**
 * A React Hook for determining whether a plant with a given plant code is favourited by the user
 *
 * Use this hook when only the favourite status is required and setting is not
 */
export function useIsFavouritePlant(plantCode: string): boolean {
  const user = useSelector(SessionSelectors.getUser);

  const isFavourite = useMemo(() => {
    return user ? user.favouritePlants.has(plantCode) : false;
  }, [user, plantCode]);

  return isFavourite;
}
