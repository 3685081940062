import React, { useContext } from 'react';

import { PlannerCommandContext } from '@gi/app-planner-desktop';

import MobilePlanMenuButton from '../mobile-plan-menu-button';
import MobileSharedActiveControls from './mobile-shared-active-controls';

interface iProps {
  callAndClose: (callback: null | (() => void)) => () => void;
}

const MobilePlannerControls = ({ callAndClose }: iProps): JSX.Element => {
  const { printPlan, publishPlan, generatePlanImage } = useContext(PlannerCommandContext);

  return (
    <>
      <MobileSharedActiveControls callAndClose={callAndClose} />
      <MobilePlanMenuButton leftIcon='icon-print' onClick={callAndClose(printPlan)}>
        Print Plan
      </MobilePlanMenuButton>
      <MobilePlanMenuButton leftIcon='icon-publish' onClick={callAndClose(publishPlan)}>
        Publish Plan
      </MobilePlanMenuButton>
      <MobilePlanMenuButton leftIcon='icon-picture' onClick={callAndClose(generatePlanImage)}>
        Generate Image
      </MobilePlanMenuButton>
    </>
  );
};

export default MobilePlannerControls;
