import React from 'react';
import { ShoppingListProduct } from '@gi/products';

import styles from './products-selector.module.css';
import { formatPrice } from '../../../utils';

interface iProps {
  currency: string;
  product: ShoppingListProduct;
  onClick: (productId: number, variantId: number) => void;
}

const ProductsItem = ({ currency, product, onClick }: iProps): JSX.Element => {
  // Create a price-range string if the product has multiple options
  const priceRange = product.variantIds
    .map((variantId) => product.variants[variantId])
    .reduce((prev, curr) => ({ min: Math.min(prev.min, curr.price), max: Math.max(prev.max, curr.price) }), { min: Number.MAX_VALUE, max: Number.MIN_VALUE });

  let priceString;
  if (priceRange.min === priceRange.max) {
    priceString = formatPrice(priceRange.min, currency);
  } else {
    priceString = `${formatPrice(priceRange.min, currency)} - ${formatPrice(priceRange.max, currency)}`;
  }

  return (
    <div className={styles.productsItem}>
      <button
        type='button'
        className={styles.itemButton}
        onClick={() => {
          onClick(product.productId, product.variantIds[0]);
        }}
      >
        <div className={styles.itemImageContainer}>
          <img src={product.images[0]} alt='' />
        </div>
        <div className={styles.itemTitle}>{product.title}</div>
        <div>
          <div className={styles.itemOptions}>{priceString}</div>
        </div>
      </button>
    </div>
  );
};

export default ProductsItem;
