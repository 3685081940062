import React, { useEffect, useState } from 'react';
import { ProductVariant, ShoppingListProduct } from '@gi/products';
import ProductGallery from './product-gallery';
import GalleryModal from './gallery-modal';

import styles from './product-gallery.module.css';

interface iProps {
  product: ShoppingListProduct;
  variant: ProductVariant<unknown>;
}

const ProductImages = ({ product, variant }: iProps): JSX.Element => {
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);

  useEffect(() => {
    if (variant.image) {
      const index = product.images.indexOf(variant.image);
      if (index !== -1) {
        setGalleryIndex(index);
      }
    }
  }, [variant]);

  return (
    <div className={styles.productImages}>
      {product.images.length > 0 ? (
        <ProductGallery
          className={styles.previewGallery}
          galleryImageUrls={product.images}
          currentImageIndex={galleryIndex}
          setCurrentImageIndex={setGalleryIndex}
          magnify={() => setShowGalleryModal(true)}
        />
      ) : null}
      {showGalleryModal ? (
        <GalleryModal galleryImageUrls={product.images} defaultImageIndex={galleryIndex} closeModal={() => setShowGalleryModal(false)} />
      ) : null}
    </div>
  );
};

export default ProductImages;
