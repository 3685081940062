import React, { useContext } from 'react';

import { AppContext } from '@gi/app-provider';
import { networkConfig } from '@gi/config';

import LogoContainer from './logo-container';
import SidebarState from '../../sidebar-position';

interface iProps {
  sidebarState: SidebarState;
  closeSidebar: () => void;
  openSidebar: () => void;
}

const NavbarLogo = ({ sidebarState, openSidebar, closeSidebar }: iProps) => {
  const { runtimeConfig } = useContext(AppContext);

  const src = `${networkConfig.assetBaseURL}${runtimeConfig.logo.src}`;

  return (
    <LogoContainer className={runtimeConfig.client} sidebarState={sidebarState} openSidebar={openSidebar} closeSidebar={closeSidebar}>
      <img className='logo-image' src={src} style={{ height: runtimeConfig.logo.height }} alt='logo' />
    </LogoContainer>
  );
};

export default NavbarLogo;
