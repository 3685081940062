/**
 * @typedef {number} SoilType
 */
/**
 * Enum for the different soil types
 *
 * @readonly
 * @enum {SoilType}
 */
const SoilTypes = {
  DEFAULT: 0,
  LIGHT: 1,
  HEAVY: 2,
  GOOD: 3,
  POOR: 4,
  OTHER: 5,
};

export default SoilTypes;
