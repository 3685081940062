import React from 'react';
import { useSelector } from 'react-redux';

import { Column, Page, PageContent, PageHeading, PageRow, PageHeadingTitle, PageHeadingSubtitle } from '@gi/page-layout';
import { SessionSelectors } from '@gi/react-session';

import { AppAccountSelectors } from '@gi/app-account-slice';
import AccountPageNavigation from './account-page-navigation';
import GeneralSettingsPage from './pages/general-settings-page';
import YourAccountPage from './pages/your-account-page';
import EmailSettingsPage from './pages/email-settings-page';
import SubscriptionOverviewPage from './pages/subscription-overview-page';
import LocationFrostDatesPage from './pages/location-frost-dates-page';
import ReferPage from './pages/refer-page';
import EducationalAccountBadge from './components/educational-account-badge';

const AppAccountDesktop = (): JSX.Element => {
  const activePage = useSelector(AppAccountSelectors.getActivePage);
  const user = useSelector(SessionSelectors.getUser)!; // User will be defined by here

  let pageContent = <p>Loading...</p>;
  switch (activePage) {
    case 'subscriptions':
      pageContent = <SubscriptionOverviewPage />;
      break;
    case 'settings':
      pageContent = <GeneralSettingsPage />;
      break;
    case 'location':
      pageContent = <LocationFrostDatesPage />;
      break;
    case 'emails':
      pageContent = <EmailSettingsPage />;
      break;
    case 'account':
      pageContent = <YourAccountPage />;
      break;
    case 'refer':
      pageContent = <ReferPage />;
      break;
    default:
      pageContent = <p>Unknown Route...</p>;
  }

  return (
    <Page>
      <PageHeading matchNavbarHeight noBackground>
        <PageHeadingTitle>
          <h1>Your Account</h1>
        </PageHeadingTitle>
        {user.misc.educationalAccount ? (
          <PageHeadingSubtitle>
            <EducationalAccountBadge /> {user?.email}
          </PageHeadingSubtitle>
        ) : (
          <PageHeadingSubtitle>{user?.email}</PageHeadingSubtitle>
        )}
      </PageHeading>
      <PageContent>
        <PageRow>
          <Column desktopSize={3}>
            <AccountPageNavigation />
          </Column>
          <Column desktopSize={9} className='page-pad-x page-pad-y'>
            {pageContent}
          </Column>
        </PageRow>
      </PageContent>
    </Page>
  );
};

export default AppAccountDesktop;
