import { DEFAULT_PLANNER_SETTINGS } from '@gi/plan';
import {
  DragEvent,
  DraggableComponentContext,
  InspectableClassData,
  Node,
  NodeComponent,
  NodeComponentEvent,
  OnDragCallback,
  PointerDataWithDelta,
  hasEngine,
  bindState,
} from '@gi/core-renderer';
import { State, StateDef } from '@gi/state';
import { ItemNodeType } from '@gi/constants';

import EditGroup from '../../simulation/edit/edit-group';
import SettingsContext, { SettingsContextState } from '../nodes/settings-context';
import CanvasInteractionGroup from '../../canvas-interface/canvas-interaction-group';
import CanvasInteractionInterface from '../../canvas-interface/canvas-interaction-interface';
import CropRotationContext, { CropRotationInteractionType } from '../nodes/crop-rotation/crop-rotation-context';
import { DEFAULT_GARDEN_CANVAS_SETTINGS } from '../../garden-canvas-settings';
import PlanSettingsContext, { PlanSettingsContextState } from '../nodes/plan-settings-context';
import { SnapUtils } from '../../simulation/snap-utils';
import PlantNode from '../nodes/plant/plant-node';
import SFGPlantNode from '../nodes/plant/sfg-plant-node';
import PlantLabelNode from '../nodes/plant/plant-label-node';
import GardenObjectNode from '../nodes/garden-objects/garden-object-node';
import ShapeNode from '../nodes/shapes/shape-node';
import TextNode from '../nodes/text/text-node';

type GardenItemDragMiddlewareState = StateDef<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  [],
  {
    settings: SettingsContextState;
    planSettings: PlanSettingsContextState;
  }
>;

class GardenItemDragMiddleware extends NodeComponent {
  type = 'GardenItemDragMiddleware';
  interactionInterface: CanvasInteractionInterface;

  #dragContext: DraggableComponentContext | null = null;
  #editGroup: EditGroup | null = null;
  #cropRotationContext: CropRotationContext | null = null;

  settingsState: State<GardenItemDragMiddlewareState>;

  get snapToGrid() {
    return this.settingsState.get('settings', 'snapToGrid', DEFAULT_GARDEN_CANVAS_SETTINGS.snapToGrid);
  }
  get snapToGridDistance() {
    const metric = this.settingsState.get('planSettings', 'metric', DEFAULT_PLANNER_SETTINGS.metric);
    return SnapUtils.getSnapDistanceFromIsMetric(metric);
  }

  constructor(canvasInteractionInterface: CanvasInteractionInterface) {
    super();
    this.interactionInterface = canvasInteractionInterface;

    this.settingsState = new State({});
    bindState(this.settingsState, this);

    // Enable/disable touch mode requires selection based on local settings.
    this.settingsState.addUpdater(
      (state) => {
        const value = state.get('settings', 'touchDragRequiresSelection');
        if (this.#dragContext && value !== undefined) {
          this.#dragContext.touchDragRequiresSelection = value;
        }
      },
      { otherStates: { settings: { properties: ['touchDragRequiresSelection'] } } }
    );

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);
    const settingsContext = this.owner.getContext(SettingsContext);
    const planSettingsContext = this.owner.getContext(PlanSettingsContext);
    this.settingsState.connectState('settings', settingsContext.state);
    this.settingsState.connectState('planSettings', planSettingsContext.state);

    this.#dragContext = this.owner.contexts.get(DraggableComponentContext);
    if (!this.#dragContext) {
      throw new Error('GardenItemDragMiddleware must be attached to a node with a DragComponentContext');
    }
    this.#setupDragMiddleware(this.#dragContext);
    this.#cropRotationContext = this.owner?.tryGetContext(CropRotationContext);
  };

  #onBeforeUnbind = () => {
    if (this.#dragContext) {
      this.#teardownDragMiddleware(this.#dragContext);
    }
    this.#cropRotationContext = null;
  };

  #setupDragMiddleware(context: DraggableComponentContext) {
    context.eventBus.on(DragEvent.OnDragStart, this.#onDragStart);
    context.eventBus.on(DragEvent.OnDragMove, this.#onDragMove);
    context.eventBus.on(DragEvent.OnDragEnd, this.#onDragEnd);
    context.eventBus.on(DragEvent.ShowTouchDragHelp, this.#onShowTouchDragHelp);
  }

  #teardownDragMiddleware(context: DraggableComponentContext) {
    context.eventBus.off(DragEvent.OnDragStart, this.#onDragStart);
    context.eventBus.off(DragEvent.OnDragMove, this.#onDragMove);
    context.eventBus.off(DragEvent.OnDragEnd, this.#onDragEnd);
    context.eventBus.off(DragEvent.ShowTouchDragHelp, this.#onShowTouchDragHelp);
  }

  #onDragStart: OnDragCallback = (targets: Node[], data: PointerDataWithDelta) => {
    // Hide the help notification if the user has worked out how to drag something
    this.#hideTouchDragHelp();

    const finalTargets = new CanvasInteractionGroup(targets);
    const finalPlantFamilies = finalTargets.getPlantFamilies();

    this.#cropRotationContext?.showFamilies(CropRotationInteractionType.DRAG, ...finalPlantFamilies);

    this.#editGroup = this.interactionInterface.startEdit(finalTargets);
    this.#editGroup.doBatchUpdate(() => {
      // If we're using snap-to-grid, snap before we start, this way the start positions are all
      // aligned to the grid, so everything moves at once.
      if (this.snapToGrid) {
        this.#editGroup?.snapTranslate(this.snapToGridDistance);
      }
      this.#editGroup?.start();
      this.#editGroup?.translate(data.worldPositionTotalDelta);
      // Re-snap to grid after applying the translate
      if (this.snapToGrid) {
        this.#editGroup?.snapTranslate(this.snapToGridDistance);
      }
    });
  };

  #onDragMove: OnDragCallback = (_, data: PointerDataWithDelta) => {
    this.#editGroup?.doBatchUpdate(() => {
      this.#editGroup?.translate(data.worldPositionTotalDelta);
      if (this.snapToGrid) {
        this.#editGroup?.snapTranslate(this.snapToGridDistance);
      }
    });
  };

  #onDragEnd: OnDragCallback = (_, data: PointerDataWithDelta) => {
    this.#editGroup?.doBatchUpdate(() => {
      this.#editGroup?.translate(data.worldPositionTotalDelta);
      if (this.snapToGrid) {
        this.#editGroup?.snapTranslate(this.snapToGridDistance);
      }
      this.#editGroup?.end();
    });
    this.interactionInterface.endEdit();

    this.#cropRotationContext?.stopShowing(CropRotationInteractionType.DRAG);
  };

  #onShowTouchDragHelp = (node: Node) => {
    if (node instanceof PlantNode) {
      this.interactionInterface.showTouchDragHelp(ItemNodeType.Plant, node.id);
    } else if (node instanceof SFGPlantNode) {
      this.interactionInterface.showTouchDragHelp(ItemNodeType.SquareFootPlant, node.id);
    } else if (node instanceof PlantLabelNode) {
      this.interactionInterface.showTouchDragHelp(ItemNodeType.PlantLabel, node.id);
    } else if (node instanceof GardenObjectNode) {
      this.interactionInterface.showTouchDragHelp(ItemNodeType.GardenObject, node.id);
    } else if (node instanceof ShapeNode) {
      this.interactionInterface.showTouchDragHelp(ItemNodeType.Shape, node.id);
    } else if (node instanceof TextNode) {
      this.interactionInterface.showTouchDragHelp(ItemNodeType.Text, node.id);
    }
  };

  #hideTouchDragHelp = () => {
    this.interactionInterface.hideTouchDragHelp();
  };

  inspectorData: InspectableClassData<this> = [];
}

export default GardenItemDragMiddleware;
