import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Plant from '@gi/plant';
import { UserShape } from '@gi/user';
import { ModalPaneContainer, ModalPane, ModalPaneContent, ModalTabContent, ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';
import { getWeekNumberFromDate, lexographicSort, oxfordCommaJoin } from '@gi/utils';
import { DEFAULT_VARIETY } from '@gi/constants';

import {
  JournalWeeks,
  Timeline,
  ActionSummary,
  ACTION_TYPES,
  loadJournalWeeklyTotals as _loadJournalWeeklyTotals,
  loadJournalActiveYears as _loadJournalActiveYears,
  loadJournal as _loadJournal,
} from '@gi/journal';
import { JournalActions } from '@gi/journal-days';
import { weightConversion } from '@gi/conversion';
import YearPicker from './year-picker';

const scaleTotals = (barData, scaleFullHeight) => {
  // Store the sum of values for each column
  const sums = [];
  barData.forEach((column, index) => {
    let sum = 0;
    column.values.forEach((value) => {
      sum += value;
    });
    sums[index] = sum;
  });

  let scaleRatio = 100;
  if (!scaleFullHeight) {
    // Scale according to max (or 1 if all the sums are zero)
    const maxSum = Math.max(...sums) || 1;
    scaleRatio = 100 / maxSum;
  }

  // Scale all the values
  barData.forEach((column, index) => {
    if (scaleFullHeight && sums[index] !== 0) {
      // Make every column full-height
      scaleRatio = 100 / sums[index];
    }

    column.values.forEach((value, index2) => {
      // Adjust the values by that ratio
      column.values[index2] *= scaleRatio;
    });
  });

  return barData;
};

const FILTER_TYPES = {
  PLANTING: {
    icon: 'icon-planting',
    filterName: 'PLANTING',
    displayName: 'Planting',
    actionKey: 'planting',
  },
  WATERING: {
    icon: 'icon-watering',
    filterName: 'WATERING',
    displayName: 'Watering',
    actionKey: 'watering',
  },
  CARE: {
    icon: 'icon-care',
    filterName: 'CARE',
    displayName: 'Care',
    actionKey: 'care',
  },
  HARVESTING: {
    icon: 'icon-harvesting',
    filterName: 'HARVESTING',
    displayName: 'Harvesting',
    actionKey: 'harvesting',
  },
  ENTRIES: {
    icon: 'icon-edit',
    filterName: 'ENTRIES',
    displayName: 'Notes & Photos',
    actionKey: 'entries',
  },
};

const sortVarieties = (varietyA, varietyB) => {
  if (varietyA === DEFAULT_VARIETY) {
    return 1;
  }
  if (varietyB === DEFAULT_VARIETY) {
    return -1;
  }
  return lexographicSort(varietyA, varietyB);
};

const TIMELINE_DATA_ORDER = ['ENTRIES', 'PLANTING', 'WATERING', 'CARE', 'HARVESTING'];

/**
 * @param {{
 *  backToTop: () => void,
 *  user: User,
 *  plant: Plant,
 *  loadJournalWeeklyTotals: () => void,
 *  loadJournalActiveYears: () => void,
 *  loadJournal: () => void,
 *  journal: any
 * }}
 */
const PlantJournalTab = ({ backToTop, user, plant, loadJournalWeeklyTotals, loadJournalActiveYears, loadJournal, journal = {} }) => {
  const startDate = new Date();

  // Default to last year in the first 6 months
  const [year, setYear] = useState(startDate.getMonth() < 6 ? startDate.getFullYear() - 1 : startDate.getFullYear());
  const [expandVarieties, setExpandVarieties] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [selectedWeek, setSelectedWeek] = useState(getWeekNumberFromDate(new Date()));

  useEffect(() => {
    loadJournalWeeklyTotals(year, plant.code);
    loadJournal(year, plant.code);
  }, [year, plant]);

  useEffect(() => {
    loadJournalActiveYears(plant.code);
  }, [plant]);

  const getFilteredEntries = () => {
    const journalYear = journal[year];
    const numActiveFilters = Object.keys(activeFilters).length;
    const shouldIncludeEntries = numActiveFilters === 0 || activeFilters.ENTRIES;
    return shouldIncludeEntries ? journalYear.journalEntries[plant.code] : undefined;
  };

  const getFilteredActions = () => {
    const journalYear = journal[year];
    const numActiveFilters = Object.keys(activeFilters).length;
    if (numActiveFilters === 0) {
      return journalYear.journalActions[plant.code];
    }

    let journalActions = new JournalActions();
    Object.keys(activeFilters).forEach((filterName) => {
      journalYear.journalActions[plant.code].getActionsForType(filterName).forEach((action) => {
        journalActions = journalActions.addAction(action);
      });
    });

    return journalActions;
  };

  const getAllVarieties = () => {
    const vars = {};
    const actionsForThisPlant = journal[year].journalActions[plant.code];
    actionsForThisPlant.IDs.toArray().forEach((id) => {
      vars[actionsForThisPlant.byID.get(id).varietyName] = true;
    });

    return Object.keys(vars)
      .sort(sortVarieties)
      .map((varietyName) => {
        return varietyName === DEFAULT_VARIETY ? 'an unspecified variety' : varietyName;
      });
  };

  const getHarvestingActions = () => {
    const journalYear = journal[year];

    let journalActions = new JournalActions();
    journalYear.journalActions[plant.code].getActionsForType(FILTER_TYPES.HARVESTING.filterName).forEach((action) => {
      journalActions = journalActions.addAction(action);
    });

    return journalActions.IDs.toArray().map((id) => journalActions.byID.get(id));
  };

  const filterTotals = (totals) => {
    const numActiveFilters = Object.keys(activeFilters).length;
    return TIMELINE_DATA_ORDER.map((filterName) => {
      const filterActionKey = FILTER_TYPES[filterName].actionKey;
      return numActiveFilters === 0 || activeFilters[filterName] ? totals[filterActionKey] : 0;
    });
  };

  const toggleFilter = (filterName) => {
    const newActiveFilters = {
      ...activeFilters,
    };
    if (!newActiveFilters[filterName]) {
      newActiveFilters[filterName] = true;
    } else {
      delete newActiveFilters[filterName];
    }
    setActiveFilters(newActiveFilters);
  };

  const numVarietiesToShow = 3;
  const renderYearlyActivitySummary = () => {
    const allVarietiesUsed = getAllVarieties();
    const varietiesToShow = expandVarieties ? allVarietiesUsed : allVarietiesUsed.slice(0, numVarietiesToShow);
    const harvestingActions = getHarvestingActions();

    if (allVarietiesUsed.length === 0 && harvestingActions.length === 0) {
      return null;
    }

    const summedHarvestingActionsByVariety = {};

    harvestingActions.forEach((action) => {
      const key = `${action.plantCode} - ${action.varietyName}`;

      if (!summedHarvestingActionsByVariety[key]) {
        summedHarvestingActionsByVariety[key] = {
          actionType: action.actionType,
          plantCode: action.plantCode,
          varietyName: action.varietyName,
          quantity: 0,
          weight: 0,
        };
      }

      summedHarvestingActionsByVariety[key].quantity += action.quantity;
      summedHarvestingActionsByVariety[key].weight += user.settings.units.metricWeightUnits
        ? action.weight
        : weightConversion.lbsToGrams(weightConversion.gramsToLbs(action.weight));
      // This is pretty horrible, converting to lbs and back again before adding.
      // This is done because imperial weights are displayed to 2 d.p, but if the weights are summed
      //  without accounting for this, the total can be bigger/smaller than it looks like it should be.
      //  e.g. 1.024 displays as 1.02. So 1.024 + 1.024 = 1.048, which displays as 1.05, but 1.02 + 1.02 != 1.05
    });

    const summedSortedHarvestingActionsByVariety = Object.values(summedHarvestingActionsByVariety).sort((a, b) => sortVarieties(a.varietyName, b.varietyName));

    return (
      <ModalPaneSection className='journal-information-section journal-summary-section'>
        <ModalPaneSectionContent>
          <div className='journal-yearly-summary'>
            {expandVarieties || allVarietiesUsed.length > 0 ? (
              <>
                <i className='icon-journal journal-summary-icon' />
                {allVarietiesUsed.length} Varieties Grown
                {allVarietiesUsed.length > numVarietiesToShow ? ', including' : '.'} {oxfordCommaJoin(varietiesToShow)}.
                {allVarietiesUsed.length <= numVarietiesToShow ? null : (
                  <span role='button' className='button button-inline' onClick={() => setExpandVarieties(!expandVarieties)}>
                    {expandVarieties ? ' See Less' : ` See More (+${allVarietiesUsed.length - numVarietiesToShow})`}.
                  </span>
                )}
              </>
            ) : null}
            <ActionSummary expandable expandIntoTable user={user} actions={summedSortedHarvestingActionsByVariety} actionType={ACTION_TYPES.HARVESTING} />
          </div>
        </ModalPaneSectionContent>
      </ModalPaneSection>
    );
  };

  const renderActivityFilters = () => {
    const anyFiltersActive = Object.keys(activeFilters).length > 0;
    return (
      <div className='timeline-header'>
        <div>
          Week by week breakdown for {plant.name} in {year}
        </div>
        <div className='filter-toggles'>
          Activity Filters:
          {Object.entries(FILTER_TYPES).map(([filterName, filter]) => {
            const filterOn = !anyFiltersActive ? true : activeFilters[filterName];
            const className = `filter-toggle ${filterOn ? ' active' : ''}`;
            return (
              <div
                key={filterName}
                className={className}
                role='button'
                title={`Toggle ${FILTER_TYPES[filterName].displayName}`}
                onClick={() => toggleFilter(filterName)}
              >
                <i className={`filter-icon ${filter.icon}`} />
              </div>
            );
          })}
          <div
            className={`filter-toggle ${anyFiltersActive ? 'active' : ''}`}
            role='button'
            title='Reset filters to show all activity'
            onClick={() => {
              if (anyFiltersActive) {
                setActiveFilters({});
              }
            }}
          >
            <i className='filter-icon icon-ccw' />
          </div>
        </div>
      </div>
    );
  };

  const renderJournalContent = () => {
    const journalYear = journal[year];
    if (
      !journalYear ||
      !journalYear.weeklyTotalsByPlantCode ||
      !journalYear.weeklyTotalsByPlantCode[plant.code] ||
      !journalYear.journalEntries ||
      !journalYear.journalEntries[plant.code] ||
      !journalYear.journalActions ||
      !journalYear.journalActions[plant.code]
    ) {
      return (
        <ModalPaneSection className='journal-information-section'>
          <ModalPaneSectionContent>
            Loading {year} Journal History for {plant.name} <i className='icon-spinner animate-pulse' />
          </ModalPaneSectionContent>
        </ModalPaneSection>
      );
    }

    const noJournalHistory = journalYear.journalEntries[plant.code].IDs.size === 0 && journalYear.journalActions[plant.code].IDs.size === 0;

    const numActiveFilters = Object.keys(activeFilters).length;
    const allFiltersActive = numActiveFilters === 0 || numActiveFilters === Object.keys(FILTER_TYPES).length;

    const filteredBarData = journalYear.weeklyTotalsByPlantCode[plant.code].map((weeklyTotal) => {
      return {
        weekNo: weeklyTotal.weekNo,
        ymd: weeklyTotal.ymd,
        heading: 'week of',
        subHeading: weeklyTotal.dateCaption,
        values: filterTotals(weeklyTotal.values),
      };
    });

    const scaledFilteredBarData = scaleTotals(filteredBarData, allFiltersActive);
    const activeYears = (journal[plant.code] || {}).activeYears || [];

    return (
      <>
        <ModalPaneSection className='journal-information-section'>
          <ModalPaneSectionContent>
            <div className='journal-header'>
              <strong>
                Your {year} Journal History for {plant.name}
              </strong>
              <YearPicker year={year} activeYears={activeYears} onPickYear={(newYear) => setYear(newYear)} />
            </div>
          </ModalPaneSectionContent>
        </ModalPaneSection>
        {renderYearlyActivitySummary()}
        {noJournalHistory ? (
          <ModalPaneSection className='journal-information-section'>
            <ModalPaneSectionContent>
              <div className='no-journal-history'>You did not record anything in your Garden Journal for this plant in {year}.</div>
            </ModalPaneSectionContent>
          </ModalPaneSection>
        ) : (
          <ModalPaneSection className='journal-information-section'>
            <ModalPaneSectionContent>
              {renderActivityFilters()}
              <Timeline
                plantCode={plant.code}
                barData={scaledFilteredBarData}
                highlightedBar={selectedWeek}
                onBarSelect={(barData) => {
                  setSelectedWeek(barData.weekNo);
                }}
              />
              <JournalWeeks selectedWeek={selectedWeek} user={user} journalEntries={getFilteredEntries()} journalActions={getFilteredActions()} />
              <button type='button' className='button button-inline' onClick={() => backToTop()}>
                Back to top
              </button>
            </ModalPaneSectionContent>
          </ModalPaneSection>
        )}
      </>
    );
  };

  return (
    <ModalTabContent tabID='plant-journal'>
      <ModalPaneContainer>
        <ModalPane className='plant-information-pane'>
          <ModalPaneContent>{renderJournalContent()}</ModalPaneContent>
        </ModalPane>
      </ModalPaneContainer>
    </ModalTabContent>
  );
};

PlantJournalTab.propTypes = {
  backToTop: PropTypes.func.isRequired,
  user: UserShape.isRequired,
  plant: PropTypes.instanceOf(Plant).isRequired,
  loadJournalWeeklyTotals: PropTypes.func.isRequired,
  loadJournalActiveYears: PropTypes.func.isRequired,
  loadJournal: PropTypes.func.isRequired,
  journal: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    journal: state.journal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loadJournalWeeklyTotals: _loadJournalWeeklyTotals,
      loadJournalActiveYears: _loadJournalActiveYears,
      loadJournal: _loadJournal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PlantJournalTab);
