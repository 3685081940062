import React from 'react';
import FormField from '../form-field';
import { FORM_FIELD_PRESETS } from '../form-field-presets';
import FormLayout, { FormLayoutOptionsPreset } from '../form-layout';
import { FORM_LAYOUT_PRESETS } from '../form-layout-presets';
import { DOmit } from '../types';

const FieldsetLayouts = ['default', 'inline', 'under'] as const;
type FieldsetLayout = (typeof FieldsetLayouts)[number];

type iProps = DOmit<Parameters<typeof FormField>[0], 'layoutPreset'> & {
  layout?: FieldsetLayout;
};

const presetMapping: { [key in FieldsetLayout]: FormLayoutOptionsPreset } = {
  default: FORM_LAYOUT_PRESETS.RadioStack,
  inline: FORM_LAYOUT_PRESETS.RadioInline,
  under: FORM_LAYOUT_PRESETS.RadioStack,
};

/**
 * A form field that holds a fieldset of radio buttons.
 * For a single radio button, use FormRadioField.
 */
const FormRadioFieldset = ({ legend, layout = 'default', children, ...remainingProps }: iProps): JSX.Element => {
  const fieldLayout = legend || layout === 'under' ? FORM_FIELD_PRESETS.Column : undefined;
  return (
    <FormField layoutPreset={fieldLayout} legend={legend} {...remainingProps}>
      <FormLayout layoutPreset={presetMapping[layout]}>{children}</FormLayout>
    </FormField>
  );
};

export default FormRadioFieldset;
