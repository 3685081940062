import React from 'react';
import AnimateHeight from 'react-animate-height';
import { useDispatch, useSelector } from 'react-redux';

import Plan from '@gi/plan';

import Modal, { ModalHeader, ModalContent, ModalCloseButton, ModalHeaderContent, ModalHeaderTitle } from '@gi/modal';

import PublishModalStage1 from './publish-modal-stage-1';
import PublishModalStage2 from './publish-modal-stage-2';
import PublishModalStage3 from './publish-modal-stage-3';
import PublishModalStageError from './publish-modal-stage-error';
import PublishPlanStage from '../publish-plan-stages';
import * as PublishSelectors from '../publish-selectors';

import './publish-plan-modal.scss';
import { resetPublishPlan } from '../publish-action-creators';

/**
 * Turns the current publish stage into the modal stage
 *
 * The modal has three stages, data input -> loading -> publish complete phase
 *
 * @param {PublishPlanStage} stage
 * @returns {number} 1 - 4
 */
const getModalStageFromPublishStage = (stage: PublishPlanStage) => {
  if (stage === PublishPlanStage.NOT_IN_PROGRESS) {
    return 1;
  }

  if (stage === PublishPlanStage.COMPLETE) {
    return 3;
  }

  if (stage === PublishPlanStage.FAILED) {
    return 4;
  }

  return 2;
};

interface iProps {
  closeModal: () => void;
  plan: Plan;
}

const PublishPlanModal = ({ closeModal, plan }: iProps): JSX.Element | null => {
  const stage = useSelector(PublishSelectors.getStage);
  const progress = useSelector(PublishSelectors.getProgress);
  const errorReason = useSelector(PublishSelectors.getErrorReason);
  const dispatch = useDispatch();

  const modalStage = getModalStageFromPublishStage(stage);

  const onCloseModal = () => {
    if (modalStage === 2) {
      return;
    }

    dispatch(resetPublishPlan());
    closeModal();
  };

  return (
    <Modal className='publish-plan-modal' closeRequest={onCloseModal}>
      <ModalContent>
        {modalStage === 2 ? null : <ModalCloseButton onClick={onCloseModal} />}
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Publish Plan</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <AnimateHeight duration={200} height={modalStage === 1 ? 'auto' : 0}>
          <PublishModalStage1 closeModal={onCloseModal} plan={plan} />
        </AnimateHeight>
        <AnimateHeight duration={200} height={modalStage === 2 ? 'auto' : 0}>
          <PublishModalStage2 stage={stage} progress={progress} />
        </AnimateHeight>
        <AnimateHeight duration={200} height={modalStage === 3 ? 'auto' : 0}>
          <PublishModalStage3 plan={plan} stage={stage} closeModal={onCloseModal} />
        </AnimateHeight>
        <AnimateHeight duration={200} height={modalStage === 4 ? 'auto' : 0}>
          <PublishModalStageError errorReason={errorReason ?? undefined} />
        </AnimateHeight>
      </ModalContent>
    </Modal>
  );
};

export default PublishPlanModal;
