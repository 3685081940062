import Plan from '@gi/plan';
import moment, { Moment } from 'moment-timezone';

/**
 * Gives an overview of a plan owned by a user
 */
export type UserPlan = {
  name: string;
  description?: string; // Currently unused
  ID: number;
  width: number;
  height: number;
  modified: Moment;
  created: Moment;
  year: number;
  misc: Record<string, any>;
};

/**
 * Creates a User Plan from a Plan
 * @param plan The plan to create from
 * @returns A User plan
 */
const createFromPlan = (plan: Plan): UserPlan => {
  return {
    name: plan.name,
    description: plan.publishDescription,
    ID: plan.id,
    height: plan.height,
    width: plan.width,
    modified: plan.modified === null ? moment.unix(plan.created!) : moment.unix(plan.modified!),
    created: moment.unix(plan.created!),
    year: plan.year,
    misc: {},
  };
};

/**
 * Sorts two plans by year, then lexographically
 * @param a The first plan
 * @param b The second plan to comapre
 * @returns If plan a should be above or below plan b
 */
export const defaultSort = (a: UserPlan, b: UserPlan) => {
  if (a.year === b.year) {
    return a.name.localeCompare(b.name);
  }

  return b.year - a.year;
};

export const UserPlanUtils = {
  createFromPlan,
  defaultSort,
};
