import React, { useMemo } from 'react';
import Plan from '@gi/plan';

import PlanBackgroundImageEditorContent from './plan-background-image-editor-content';
import { PlanBackgroundImageEditorProvider } from './plan-background-image-editor-context';

import styles from './styles.module.css';

interface iProps {
  plan: Plan | null;
  onViewPlan?: () => void;
  className?: string;
}

const PlanBackgroundImageEditor = ({ plan, onViewPlan, className }: iProps): JSX.Element => {
  const classNames = useMemo(() => {
    const _classNames: string[] = [styles.container];
    if (className) {
      _classNames.push(className);
    }
    return _classNames.join(' ');
  }, [className]);

  return (
    <div className={classNames}>
      <p className={styles.explanation}>
        If you have a high-quality aerial image or landscape plan of your garden, you can upload it here to use as reference for planning your garden. Google
        Maps screenshots are not detailed enough.
      </p>
      {plan ? (
        <PlanBackgroundImageEditorProvider plan={plan}>
          <PlanBackgroundImageEditorContent onViewPlan={onViewPlan} />
        </PlanBackgroundImageEditorProvider>
      ) : (
        <button type='button' className='button button-primary' disabled>
          Add Background Image
        </button>
      )}
    </div>
  );
};

export default PlanBackgroundImageEditor;
