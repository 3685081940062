import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';

export type KeyboardShortcut = {
  key: string;
  shift?: boolean;
  control?: boolean;
  extraConditions?: (e: KeyboardEvent) => boolean;
  callback: () => void;
};

type KeyboardShortcuts = Record<number, KeyboardShortcut>;

type KeyboardShortcutContextType = {
  registerShortcut: (shortcut: KeyboardShortcut) => number;
  removeShortcut: (shortcutId: number) => void;
};

export const KeyboardShortcutContext = createContext<KeyboardShortcutContextType>({} as KeyboardShortcutContextType);

interface iProps {
  children: React.ReactNode;
}

export const KeyboardShortcutProvider = ({ children }: iProps) => {
  const shortcutId = useRef(0);
  const [shortcuts, setShortcuts] = useState<KeyboardShortcuts>({});

  const shortcutIds = useMemo(() => {
    return Object.keys(shortcuts);
  }, [shortcuts]);

  const getShortcutId = useCallback(() => {
    return shortcutId.current++;
  }, [shortcutId]);

  const removeShortcut = useCallback((_shortcutId: number) => {
    setShortcuts((currentShortcuts) => {
      const newShortcuts = {
        ...currentShortcuts,
      };
      delete newShortcuts[_shortcutId];
      return newShortcuts;
    });
  }, []);

  const registerShortcut = useCallback((shortcut: KeyboardShortcut) => {
    const id = getShortcutId();

    setShortcuts((currentShortcuts) => ({
      ...currentShortcuts,
      [id]: shortcut,
    }));

    return id;
  }, []);

  const onKeydown = useCallback(
    (e: KeyboardEvent) => {
      // `key` is undefined on some browsers/devices...
      if (!e || !e.key || typeof e.key !== 'string') {
        return;
      }

      // Don't check for triggers when shift or control is pressed
      if (e.key === 'Control' || e.key === 'Shift') {
        return;
      }

      const controlDown = e.ctrlKey || e.metaKey;
      const shiftDown = e.shiftKey;
      const key = e.key.toLowerCase();

      for (let i = 0; i < shortcutIds.length; i++) {
        const shortcut = shortcuts[shortcutIds[i]] as KeyboardShortcut;

        if (!shortcut) {
          console.warn(`Shortcut not found: ${shortcutIds[i]}`);
          return;
        }

        if (!shortcut.control || (controlDown && shortcut.control)) {
          if (!shortcut.shift || (shiftDown && shortcut.shift)) {
            if (key === shortcut.key) {
              if (!shortcut.extraConditions || (shortcut.extraConditions && shortcut.extraConditions(e))) {
                e.preventDefault();
                e.stopPropagation();
                shortcut.callback();
                // Only trigger one shortcut per click, may change this functionality later
                return;
              }
            }
          }
        }
      }
    },
    [shortcuts, shortcutIds]
  );

  // Setup keybinds
  useEffect(() => {
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [onKeydown]);

  const value = useMemo<KeyboardShortcutContextType>(() => ({ registerShortcut, removeShortcut }), []);

  return <KeyboardShortcutContext.Provider value={value}>{children}</KeyboardShortcutContext.Provider>;
};
