import React from 'react';
import Modal from '../modal';
import ModalHeader from '../header/modal-header';
import ModalHeaderContent from '../header/modal-header-content';
import ModalHeaderTitle from '../header/modal-header-title';
import ModalFooter from '../modal-footer';
import ModalContent from '../modal-content';
import ModalPaneContainer from '../modal-pane-container';
import ModalPane from '../modal-pane/modal-pane';
import ModalCloseButton from '../header/modal-close-button';

interface iProps {
  className?: string;
  headerText: string;
  children: React.ReactNode;
  modalFooterContent: React.ReactNode;
  oncloseRequest: () => void;
}

const DefaultModal = ({ children, className = '', headerText, modalFooterContent, oncloseRequest }: iProps) => {
  return (
    <Modal className={className} closeRequest={oncloseRequest}>
      <ModalContent>
        <ModalCloseButton onClick={oncloseRequest} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>{headerText}</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>{children}</ModalPane>
        </ModalPaneContainer>
        <ModalFooter>{modalFooterContent}</ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DefaultModal;
