import { Graphics } from 'pixi.js-new';
import { NodeEvent, ShapeComponentContext, StatefulNode } from '@gi/core-renderer';
import { State, StateDef } from '@gi/state';

type PlanNodeState = {
  width: number;
  height: number;
};

class PlanNode extends StatefulNode<PlanNodeState> {
  type = 'PlanNode';

  backgroundGraphic: Graphics | null;

  constructor(width: number, height: number) {
    super({
      width,
      height,
    });

    this.state.addWatcher((state) => {
      this.drawBackground(state);
    });

    this.eventBus.on(NodeEvent.DidBind, this.#didBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#beforeUnbind);
  }

  drawBackground(state: State<StateDef<PlanNodeState>>) {
    if (this.backgroundGraphic) {
      this.backgroundGraphic.clear();
      this.backgroundGraphic.beginFill(0xffffff, 1);
      this.backgroundGraphic.drawRect(0, 0, state.values.width, state.values.height);
      this.backgroundGraphic.endFill();
    }
  }

  #didBind = () => {
    this.backgroundGraphic = new Graphics();
    this.drawBackground(this.state);
    this.ownGraphics.addChild(this.backgroundGraphic);

    this.tryGetContext(ShapeComponentContext)?.setContentSize(this.state.values.width, this.state.values.height);
  };

  #beforeUnbind = () => {
    if (this.backgroundGraphic) {
      this.backgroundGraphic.destroy();
      this.backgroundGraphic = null;
    }
  };
}

export default PlanNode;
