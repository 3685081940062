import React, { useMemo, useState } from 'react';
import ReactSelect from 'react-select';
import { useSelector } from 'react-redux';

import { PlanUtils } from '@gi/plan';
import { UserPlanSetUtils, UserUtils } from '@gi/user';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';
import { SessionSelectors } from '@gi/react-session';

import './plan-history-editor.scss';

const MAXIMUM_PLAN_HISTORY_COUNT = 5;

interface DropdownOption {
  label: string;
  value: number;
}

interface iProps {
  currentPlanID: number;
  onChange: (plans: number[]) => void;
  planHistory: number[];
  disabled: boolean;
}

const PlanHistoryEditor = ({ currentPlanID, onChange, planHistory, disabled }: iProps): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);
  const [selectedPlan, setSelectedPlan] = useState<DropdownOption | null>(null);

  if (!user) {
    return null;
  }

  const userPlans = user.plans;
  const isNorthernHemisphere = UserUtils.isNorthernHemisphere(user);

  /**
   * Generate the dropdown options of all the user's available plans.
   */
  const userPlanOptions = useMemo(() => {
    const planList = [...userPlans.list].sort((a, b) => {
      if (a.year === b.year) {
        return a.name.localeCompare(b.name);
      }

      return b.year - a.year;
    });

    const options: DropdownOption[] = [];

    for (let i = 0; i < planList.length; i++) {
      // Check the plan isn't already in the history and that the plan isn't the current plan
      if (!planHistory.includes(planList[i].ID) && planList[i].ID !== currentPlanID) {
        // Add a selection list option for it
        options.push({
          label: `${PlanUtils.getYearDisplayString(planList[i].year, isNorthernHemisphere)} ${planList[i].name}`,
          value: planList[i].ID,
        });
      }
    }

    // We've re-generated the dropdown options, select the first option so our selection is still valid.
    setSelectedPlan(options[0] ?? null);

    return options;
  }, [planHistory, userPlans, currentPlanID, isNorthernHemisphere]);

  const onAdd = () => {
    if (!selectedPlan) {
      return;
    }
    const newPlanHistory = [...planHistory, selectedPlan.value];

    onChange(newPlanHistory);
  };

  const onDropdownChange = (option: DropdownOption) => {
    setSelectedPlan(option);
  };

  const remove = (index: number) => {
    onChange([...planHistory.slice(0, Math.max(0, index)), ...planHistory.slice(index + 1, planHistory.length)]);
  };

  const moveDownIndex = (index: number) => {
    onChange([...planHistory.slice(0, Math.max(0, index)), planHistory[index + 1], planHistory[index], ...planHistory.slice(index + 2, planHistory.length)]);
  };

  const moveUpIndex = (index: number) => {
    onChange([
      ...planHistory.slice(0, Math.max(0, index - 1)),
      planHistory[index],
      planHistory[index - 1],
      ...planHistory.slice(index + 1, planHistory.length),
    ]);
  };

  const renderPlanHistory = (planID: number, index: number) => {
    if (planID === null) {
      return null;
    }

    if (!UserPlanSetUtils.hasPlan(userPlans, planID)) {
      console.warn('Plan has plan in history but plan not present');
      return null;
    }

    const userPlan = UserPlanSetUtils.getPlan(userPlans, planID);

    if (!userPlan) {
      return null;
    }

    const moveUpDisabled = index === 0;
    const moveDownDisabled = index === planHistory.length - 1;

    let moveUp = () => {};
    let moveDown = () => {};

    if (!moveUpDisabled) {
      moveUp = () => {
        moveUpIndex(index);
      };
    }

    if (!moveDownDisabled) {
      moveDown = () => {
        moveDownIndex(index);
      };
    }

    const onDelete = () => {
      remove(index);
    };

    return (
      <div className='plan-history-row' key={planID}>
        <span className='plan-history-controls plan-history-left-controls'>
          <button
            type='button'
            disabled={moveUpDisabled || disabled}
            className='plan-history-button plan-history-move-up'
            onClick={moveUp}
            aria-label='Make Plan More Recent'
          >
            <i className='icon-up-open' />
          </button>
          <button
            type='button'
            disabled={moveDownDisabled || disabled}
            className='plan-history-button plan-history-move-down'
            onClick={moveDown}
            aria-label='Make Plan Older'
          >
            <i className='icon-down-open' />
          </button>
        </span>
        <span className='plan-history-year'>{PlanUtils.getYearDisplayString(userPlan.year, isNorthernHemisphere)}</span>
        <span className='plan-history-name'>{userPlan.name}</span>
        <span className='plan-history-controls plan-history-right-controls'>
          <button
            type='button'
            className='plan-history-button plan-history-delete'
            onClick={onDelete}
            aria-label='Remove Plan From History'
            disabled={disabled}
          >
            <i className='icon-cancel' />
          </button>
        </span>
      </div>
    );
  };

  const canAdd = planHistory.length < MAXIMUM_PLAN_HISTORY_COUNT;

  let inputErrorMessage: string | null = null;

  if (userPlanOptions.length === 0) {
    inputErrorMessage = 'No plans available to add';
  } else if (!canAdd) {
    inputErrorMessage = 'Maximum number of plans in history';
  }

  return (
    <div className='plan-history-editor'>
      <p>
        Plan history is used to track crop rotation. Add old plans to this plan&apos;s history to see crop rotation indicators on your plan. When you create a
        follow-on plan the plan history will automatically be generated, but you can also retroactively create and add older plans to this list.
      </p>
      <div className='plan-history-row-container'>
        <div className='key-top key'>
          <span className='key-content'>Most Recent</span>
        </div>
        <div className='key-middle key'>
          <span className='key-content'>&#8942;</span>
        </div>
        <div className='key-bottom key'>
          <span className='key-content'> Oldest</span>
        </div>
        <div className='plan-history-editor-rows'>{planHistory.map(renderPlanHistory)}</div>
      </div>
      <div className='plan-history-editor-input'>
        <div className='input-select'>
          <ReactSelect
            isDisabled={userPlanOptions.length === 0 || !canAdd || disabled}
            styles={DEFAULT_SELECT_STYLES}
            options={userPlanOptions}
            value={selectedPlan}
            onChange={onDropdownChange}
            menuPortalTarget={document.body}
            isSearchable={false}
          />
        </div>
        <div className='input-button'>
          <button
            type='button'
            disabled={userPlanOptions.length === 0 || !canAdd || disabled}
            className='button button-primary plan-history-add-button'
            onClick={onAdd}
          >
            Add To History
          </button>
        </div>
        <div className='input-error'>
          <p>{inputErrorMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default PlanHistoryEditor;
