import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Plan from '@gi/plan';
import { RequestSelectors } from '@gi/react-requests';
import { SessionSelectors } from '@gi/react-session';
import { RequestsUtils } from '@gi/request';

import styles from './plan-settings-save-progress-bar.module.css';

interface iProps {
  plan: Plan;
  planHistory: number[];
  resizeHistoricalPlans: boolean;
}

const PlanSettingsSaveProgressBar = ({ plan, planHistory, resizeHistoricalPlans }: iProps): JSX.Element | null => {
  const requests = useSelector(RequestSelectors.getRequests);
  const user = useSelector(SessionSelectors.getUser);

  const planSaving = RequestsUtils.requestInProgress(requests, `SAVE_PLAN_${plan.id}`);
  const planResizing = RequestsUtils.requestInProgress(requests, `RESIZE_PLAN_HISTORY_${plan.id}`);

  const getPlanString = useCallback(
    (id: number, i: number) => {
      if (user?.plans.map[id]) {
        return `${user?.plans.map[id].name} - ${user?.plans.map[id].year}`;
      }
      return `Historical Plan #${i + 1}`;
    },
    [user]
  );

  const progress = useMemo<{ progress: number; max: number; text?: string }>(() => {
    if (!planSaving && !planResizing) {
      return { progress: 1, max: 1 };
    }
    // Nothing to track, 0 progress if not finished, otherwise 1
    if (!resizeHistoricalPlans) {
      return { progress: planSaving ? 0 : 1, max: 1, text: 'Saving Plan...' };
    }
    // If the plan is saving, assume the historical plans have been completed
    if (planSaving) {
      return { progress: planHistory.length, max: planHistory.length + 1, text: 'Saving Plan...' };
    }
    // Attempt to find the historical plan that is currently being changed
    let foundId = 0;
    for (let i = 0; i < planHistory.length; i++) {
      // Plan history is modified in reverse order, so check in reverse
      if (RequestsUtils.requestInProgress(requests, `RESIZE_HISTORICAL_PLAN_${planHistory[planHistory.length - 1 - i]}`)) {
        foundId = i;
        break;
      }
    }
    return { progress: foundId, max: planHistory.length + 1, text: `Saving ${getPlanString(planHistory[foundId], foundId)}...` };
  }, [planSaving, planResizing, resizeHistoricalPlans, planHistory, requests, getPlanString]);

  if (!resizeHistoricalPlans || (!planSaving && !planResizing)) {
    return null;
  }

  const clampedPercent = Math.min(Math.max((progress.progress / progress.max) * 100, 0), 100);

  return (
    <div className={`${styles.container} form-section-background`}>
      <div className={styles.progressBar}>
        <span className={styles.progressBarInner} style={{ width: `${clampedPercent}%` }} />
        <span className={styles.progressBarPercent} style={{ minWidth: `${clampedPercent}%` }}>
          <span>{Math.floor(clampedPercent)}%</span>
        </span>
      </div>
      {progress.text ? (
        <p>
          <i className='icon-spinner animate-pulse' /> {progress.text}
        </p>
      ) : null}
    </div>
  );
};

export default PlanSettingsSaveProgressBar;
