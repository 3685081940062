import React, { useContext } from 'react';
import { ModalPaneSection, ModalPaneSectionHeader, HelpModal } from '@gi/modal';

import { DrawingToolsContext } from '../drawing-tools-context';

const SearchHelpModal = () => {
  const { setSearchHelpModalOpen } = useContext(DrawingToolsContext);
  return (
    <HelpModal
      headerText='Searching for plants and objects'
      closeModal={() => {
        setSearchHelpModalOpen(false);
      }}
      style={{ maxWidth: 480 }}
    >
      <ModalPaneSection>
        <ModalPaneSectionHeader>Plant Search</ModalPaneSectionHeader>
        <p>
          Our plant search takes into account common alternative names and minor mis-spellings. This makes it easy to search for &quot;Beans&quot;, &quot;Fava
          Beans&quot; or &quot;Broad Beans&quot; and still find the beans you&apos;re looking for. You can also type just the first letter and see just plants
          beginning with that letter.
        </p>
        <p>
          Plant search also works with scientific names, such as <em>Vicia faba</em>.
        </p>
      </ModalPaneSection>
      <ModalPaneSection>
        <ModalPaneSectionHeader>Object Search</ModalPaneSectionHeader>
        <p> The same is true for object search, helping you out with minor spelling mistakes and different word-ordering.</p>
      </ModalPaneSection>
    </HelpModal>
  );
};

export default SearchHelpModal;
