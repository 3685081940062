import React, { useContext } from 'react';

import { PlanSize } from '../utils';
import { PlanDiagramSVGContext } from '../plan-diagram-svg';

import styles from './svg-element-styles.module.css';

interface iProps {
  originalPlan: PlanSize;
  newPlan: PlanSize;
}

const UnchangedAreaSVGElement = ({ originalPlan, newPlan }: iProps): JSX.Element => {
  const { scale, offset } = useContext(PlanDiagramSVGContext);

  const xMin = Math.max(originalPlan.x, newPlan.x) * scale + offset.x;
  const xMax = Math.min(originalPlan.x + originalPlan.width, newPlan.x + newPlan.width) * scale + offset.x;

  const yMin = Math.max(originalPlan.y, newPlan.y) * scale + offset.y;
  const yMax = Math.min(originalPlan.y + originalPlan.height, newPlan.y + newPlan.height) * scale + offset.y;

  return <rect x={xMin} y={yMin} width={xMax - xMin} height={yMax - yMin} className={styles.planUnchangedArea} />;
};

export default UnchangedAreaSVGElement;
