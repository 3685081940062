import React, { useContext, useState } from 'react';
import { Tutorial, TutorialContext } from '@gi/tutorial';

import ControlGroup from '../../common/control-group';
import ControlRow from '../../common/control-row';
import TutorialDropdown from './tutorial-dropdown';
import MenuButton from '../../common/menu-button';

const TemporaryTutorialControls = (): JSX.Element => {
  const { startTutorial, tutorials } = useContext(TutorialContext);
  const [tutorial, setTutorial] = useState<null | Tutorial>(tutorials === null ? null : tutorials.tutorials[0]);

  return (
    <ControlGroup>
      <ControlRow>
        <TutorialDropdown tutorial={tutorial} setTutorial={setTutorial} attachToBody />
        <MenuButton
          onClick={() => {
            if (tutorial) {
              startTutorial(tutorial);
            }
          }}
        >
          Start
        </MenuButton>
      </ControlRow>
    </ControlGroup>
  );
};

export default TemporaryTutorialControls;
