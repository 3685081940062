import { createContext } from 'react';

import GardenObject from '@gi/garden-object';
import Plant from '@gi/plant';
import { Swatch } from '@gi/palette';
import { Filters, FiltersUpdate } from '@gi/filters';
import { ShapeType } from '@gi/constants';

import { PlantFiltersType } from './plants/filters/create-plant-filters';
import { GardenObjectFiltersType } from './garden-objects/filters/create-garden-object-filters';
import { DrawingToolsGardenObjectListEntry } from './utils';

export type DrawingToolsContextType = {
  searchHelpModalOpen: boolean;
  setSearchHelpModalOpen: (open: boolean) => void;
  sfgHelpModalOpen: boolean;
  setSfgHelpModalOpen: (open: boolean) => void;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  cancelDraw: () => void;
  textFontSize: { value: number; label: string };
  setTextFontSize: (size: { value: number; label: string }) => void;
  textSwatch: null | Swatch;
  setTextSwatch: (swatch: Swatch) => void;
  textSelected: boolean;
  onTextSelected: (fontSize: number, color: string, dragEvent?: PointerEvent) => void;
  shapeStrokeWidth: { value: number; label: string };
  setShapeStrokeWidth: ({ value, label }: { value: number; label: string }) => void;
  shapeFillSwatch: null | Swatch;
  setShapeFillSwatch: (swatch: Swatch) => void;
  shapeStrokeSwatch: null | Swatch;
  setShapeStrokeSwatch: (swatch: Swatch) => void;
  selectedShapeType: null | ShapeType;
  selectedShapeFill: null | boolean;
  toggleGardenObjectGroup: (groupId: string) => void;
  onShapeSelected: (
    shapeType: ShapeType,
    strokeWidth: number,
    strokeColor: string,
    fillColor: null | string,
    fillTexture: null | string,
    dragEvent?: PointerEvent
  ) => void;
  onGardenObjectSelected: (gardenObject: GardenObject, dragEvent?: PointerEvent) => void;
  selectedGardenObjectCode: null | string;
  gardenObjectFilters: Filters<GardenObject, GardenObjectFiltersType>;
  setGardenObjectFilters: (filters: Filters<GardenObject, GardenObjectFiltersType>) => void;
  updateGardenObjectFilters: (filtersUpdate: FiltersUpdate<GardenObject, GardenObjectFiltersType>) => void;
  resetGardenObjectFilters: () => void;
  filteredGardenObjects: Readonly<GardenObject[]>;
  groupedFilteredGardenObjects: Readonly<DrawingToolsGardenObjectListEntry[]>;
  favouritePlants: Set<string>;
  toggleFavouritePlant: (plant: Plant) => void;
  filteredPlants: Readonly<Plant[]>;
  resetPlantFilters: () => void;
  plantFilters: Filters<Plant, PlantFiltersType>;
  setPlantFilters: (filters: Filters<Plant, PlantFiltersType>) => void;
  updatePlantFilters: (filtersUpdate: FiltersUpdate<Plant, PlantFiltersType>) => void;
  selectedPlants: Plant[];
  northernHemisphere: boolean;
  selectedPlantCode: null | string;
  onPlantSelected: (plant: Plant, dragEvent?: PointerEvent) => void;
  userLoading: boolean;
  isDragToDraw: boolean;
  viewCompanions: (plants?: Plant[]) => void;
};

export const DrawingToolsContext = createContext<DrawingToolsContextType>({} as DrawingToolsContextType);
