import React, { CSSProperties, ReactNode, useEffect, useMemo, useState } from 'react';

import { LazyImageProvider } from '../lazy-image/lazy-image-context';
import SmallPreviewLoading from '../small-preview/items/small-preview-loading';

import styles from './content-grid-inner.module.css';

interface iProps {
  minItemHeight?: number;
  itemAspectRatio?: number;
  gap?: number;
  children?: ReactNode;
  loading?: boolean;
}

const ContentGridInner = ({ minItemHeight = 150, itemAspectRatio = 9 / 16, gap = 18, loading = false, children }: iProps): JSX.Element => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const callback = () => {
      setContainerWidth(ref?.clientWidth ?? window.innerWidth);
    };
    callback();
    if (ref) {
      window.addEventListener('resize', callback);

      return () => {
        window.removeEventListener('resize', callback);
      };
    }
    return () => {};
  }, [ref]);

  const maxVisibleItems = useMemo(() => {
    return Math.round(Math.max(Math.floor(containerWidth / (minItemHeight / itemAspectRatio)), 2));
  }, [minItemHeight, itemAspectRatio, containerWidth]);

  const loadingPlaceholders = useMemo(() => {
    if (!loading) {
      return null;
    }

    const elements: JSX.Element[] = [];
    for (let i = 0; i < maxVisibleItems; i++) {
      elements.push(<SmallPreviewLoading key={i} />);
    }
    return elements;
  }, [loading, maxVisibleItems]);

  const cssVariables = useMemo<CSSProperties>(() => {
    return {
      '--gap': `${gap}px`,
      '--columns': maxVisibleItems,
    } as CSSProperties;
  }, [maxVisibleItems, gap]);

  return (
    <LazyImageProvider containerRef={ref}>
      <div className={styles.gridContent} ref={setRef} style={cssVariables}>
        {loading ? loadingPlaceholders : children}
      </div>
    </LazyImageProvider>
  );
};

export default ContentGridInner;
