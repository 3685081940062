import React from 'react';
import PropTypes from 'prop-types';
import FormRowLabel from './form-row-label.tsx';
import './form.scss';

/**
 * @param {{
 *  className?: string,
 *  label?: string | null,
 *  children?: import('react').ReactNode | null,
 *  disabled?: boolean,
 *  stretch?: boolean,
 *  warningText?: string | null,
 *  helpText?: string | null,
 *  required?: boolean,
 *  autoLabelWidth?: boolean,
 *  valid?: boolean,
 *  noLabel?: boolean,
 *  fakeLabel?: boolean,
 * }}
 */
const FormRow = ({
  className = '',
  label = null,
  children = null,
  disabled = false,
  stretch = false,
  warningText = null,
  required = false,
  helpText = null,
  autoLabelWidth = false,
  valid = true,
  noLabel = false,
  fakeLabel = false,
}) => {
  return (
    <div
      className={`form-row-new ${className} ${disabled ? 'disabled-form-row' : ''} ${stretch ? 'form-row-new-stretch' : ''}${valid ? '' : ' invalid-form-row'}`}
    >
      <FormRowLabel fakeLabel={fakeLabel} required={required} className={`${autoLabelWidth ? 'auto-label' : ''}`}>
        {noLabel ? null : <span className='form-row-label-text'>{label}</span>}
        <span className='form-row-input'>
          <span className='form-row-input-input'>{children}</span>
          <span className='form-row-input-info'>
            {warningText ? <span className='warning-text'>{warningText}</span> : null}
            {helpText ? <span className='help-text'>{helpText}</span> : null}
          </span>
        </span>
      </FormRowLabel>
    </div>
  );
};

FormRow.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  stretch: PropTypes.bool,
  warningText: PropTypes.string,
  helpText: PropTypes.string,
  required: PropTypes.bool,
  autoLabelWidth: PropTypes.bool,
  valid: PropTypes.bool,
  noLabel: PropTypes.bool,
  fakeLabel: PropTypes.bool,
};

export default FormRow;
