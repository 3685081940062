import { Matrix } from '@gi/math';

export abstract class Edit {
  /**
   * Lets the edit know it's about to start, and should save values for later use.
   */
  abstract start(): void;

  /**
   * Translates by the given amount.
   * @param positionOffset The total offset from when `start` was called.
   * @param matrix A matrix representing the transformation
   */
  abstract translate(positionOffset: Vector2, matrix: number[][]): void;

  /**
   * Rotates by the given amount.
   * @param angle The total angle offset from when `start` was called.
   * @param pivot The pivot around which the rotation should be made.
   * @param matrix A matrix representing the transformation.
   */
  abstract rotate(angle: number, pivot: Vector2, matrix: number[][]): void;

  /**
   * Lets the edit know its ending. Probably useless.
   */
  abstract end(): void;

  /**
   * Rounds the edited object to the grid.
   * @param snapDistance The distance on the grid to round to
   */
  abstract snapTranslate(snapDistance: number): void;

  /**
   * Starts a batch edit. May be used to suppress update events until endBatch is called.
   */
  abstract startBatch(): void;

  /**
   * Ends the batch edit. May cause updates to be emitted.
   */
  abstract endBatch(): void;

  /**
   * Calls startBatch before calling the callback, then calls endBatch on complete/error.
   * @param batch Callback function to do all the edits in
   */
  doBatchUpdate(batch: () => void): void {
    this.startBatch();
    try {
      batch();
    } finally {
      this.endBatch();
    }
  }

  /**
   * Creates a transformation matrix to rotate about the given pivot.
   * @param angleOffset The angle to rotate by
   * @param pivot The pivot to rotate around
   * @returns A matrix representing the transformation
   */
  static getRotationMatrix(angleOffset: number, pivot: Vector2) {
    const translationMatrix = Matrix.createTranslationMatrix(-pivot.x, -pivot.y);
    const rotationMatrix = Matrix.createRotationMatrix(angleOffset);
    const reverseTranslationMatrix = Matrix.createTranslationMatrix(pivot.x, pivot.y);

    return Matrix.multiplyAll([reverseTranslationMatrix, rotationMatrix, translationMatrix]);
  }

  /**
   * Creates a transformation matrix to translate by the given amount
   * @param positionOffset The vector to translate by
   * @returns A matrix representing the transformation
   */
  static getTranslationMatrix(positionOffset: Vector2) {
    return Matrix.createTranslationMatrix(positionOffset.x, positionOffset.y);
  }
}
