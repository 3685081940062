/* eslint-disable max-classes-per-file */

import { Geometry } from '@gi/math';
import { createEllipseOutline, createLineOutline, createPathOutline, createPlantOutline, createRectOutline, createTriangleOutline } from './utils';

function vectorsEqual(v1: Vector2, v2: Vector2) {
  return v1.x === v2.x && v1.y === v2.y;
}

function possiblyNullVectorsEqual(v1: Vector2 | null, v2: Vector2 | null) {
  if (v1 === null && v2 === null) {
    return true;
  }
  if (v1 === null || v2 === null) {
    return false;
  }
  return vectorsEqual(v1, v2);
}

/**
 * What is this:
 *  These helper classes store an outline of a particular shape.
 *  The outline will only update if the inputs changing would actually change the outline.
 *  For example, a triangle being dragged would have all 3 of its points moved, but the outline
 *    shouldn't update, as the shape of the triangle hasn't changed.
 *  Ideally, the item should only attempt to update its outline/shape when it knows it has
 *    meaningfully changed, but that adds more complexity to the state.
 *  Changes to the state should make getting rid of these classes much easier, but need proper
 *    planning as would lead to a rewrite of the state system. (25/01/2024)
 */

abstract class CachedOutline {
  path: Vector2[] = [];
}

/**
 * Caches the outline of a plant, only updating if the outline should change.
 */
export class CachedPlantOutline extends CachedOutline {
  protected cachedWidth: number;
  protected cachedHeight: number;
  protected cachedSpacing: number;
  protected cachedRowSpacing: number;
  protected cachedInRowSpacing: number;

  constructor(width: number, height: number, spacing: number, rowSpacing: number, inRowSpacing: number) {
    super();
    this.updateCache(width, height, spacing, rowSpacing, inRowSpacing);
    this.updatePath();
  }

  /**
   * Updates the path of the outline, only if the inputs change the outline.
   * @returns True if the path was changed, false otherwise
   */
  update(width: number, height: number, spacing: number, rowSpacing: number, inRowSpacing: number): boolean {
    if (
      width !== this.cachedWidth ||
      height !== this.cachedHeight ||
      spacing !== this.cachedSpacing ||
      rowSpacing !== this.cachedRowSpacing ||
      inRowSpacing !== this.cachedInRowSpacing
    ) {
      this.updateCache(width, height, spacing, rowSpacing, inRowSpacing);
      this.updatePath();
      return true;
    }
    return false;
  }

  protected updateCache(width: number, height: number, spacing: number, rowSpacing: number, inRowSpacing: number) {
    this.cachedWidth = width;
    this.cachedHeight = height;
    this.cachedSpacing = spacing;
    this.cachedRowSpacing = rowSpacing;
    this.cachedInRowSpacing = inRowSpacing;
  }

  protected updatePath() {
    this.path = createPlantOutline(this.cachedWidth, this.cachedHeight, {
      spacing: this.cachedSpacing,
      rowSpacing: this.cachedRowSpacing,
      inRowSpacing: this.cachedInRowSpacing,
    });
  }
}

/**
 * Caches the outline of a rectangle, only updating if the outline should change.
 */
export class CachedRectOutline extends CachedOutline {
  protected cachedWidth: number;
  protected cachedHeight: number;

  constructor(width: number, height: number) {
    super();
    this.updateCache(width, height);
    this.updatePath();
  }

  /**
   * Updates the path of the outline, only if the inputs change the outline.
   * @returns True if the path was changed, false otherwise
   */
  update(width: number, height: number): boolean {
    if (width !== this.cachedWidth || height !== this.cachedHeight) {
      this.updateCache(width, height);
      this.updatePath();
      return true;
    }
    return false;
  }

  protected updateCache(width: number, height: number) {
    this.cachedWidth = width;
    this.cachedHeight = height;
  }

  protected updatePath() {
    this.path = createRectOutline(this.cachedWidth, this.cachedHeight);
  }
}

export class CachedEllipseOutline extends CachedRectOutline {
  protected updatePath() {
    this.path = createEllipseOutline(this.cachedWidth, this.cachedHeight);
  }
}

/**
 * Creates a triangle outline, only updating when needed.
 * Works on the principle that 2 triangles are identical if A->B and A->C are the same for both.
 * Triangle outlines are already centered about the center of the triangle, so the outline doesn't
 *  change if the relative vectors between points are the same between triangles.
 */
export class CachedTriangleOutline extends CachedOutline {
  protected cachedPointA: Vector2;
  protected cachedPointB: Vector2;
  protected cachedPointC: Vector2;
  protected cachedAB: Vector2;
  protected cachedAC: Vector2;

  constructor(point1: Vector2, point2: Vector2, point3: Vector2) {
    super();
    this.updateCache(point1, point2, point3);
    this.updatePath();
  }

  /**
   * Updates the path of the outline, only if the inputs change the outline.
   * @returns True if the path was changed, false otherwise
   */
  update(point1: Vector2, point2: Vector2, point3: Vector2) {
    const newAB = Geometry.getPointDelta(point1, point2);
    const newAC = Geometry.getPointDelta(point1, point3);
    if (!vectorsEqual(newAB, this.cachedAB) || !vectorsEqual(newAC, this.cachedAC)) {
      this.updateCache(point1, point2, point3);
      this.updatePath();
      return true;
    }
    return false;
  }

  protected updateCache(point1: Vector2, point2: Vector2, point3: Vector2) {
    this.cachedPointA = point1;
    this.cachedPointB = point2;
    this.cachedPointC = point3;
    this.cachedAB = Geometry.getPointDelta(point1, point2);
    this.cachedAC = Geometry.getPointDelta(point1, point3);
  }

  protected updatePath() {
    this.path = createTriangleOutline(this.cachedPointA, this.cachedPointB, this.cachedPointC);
  }
}

/**
 * Caches the outline of a line, only updating if the outline should change.
 * Works on the same assumptions as CachedTriangleOutline
 */
export class CachedLineOutline extends CachedOutline {
  protected cachedStart: Vector2;
  protected cachedEnd: Vector2;
  protected cachedControl: Vector2 | null;
  protected cachedSteps: number | undefined;
  protected cachedAB: Vector2;
  protected cachedAC: Vector2 | null;

  constructor(start: Vector2, end: Vector2, controlPoint: Vector2 | null, steps?: number) {
    super();
    this.updateCache(start, end, controlPoint, steps);
    this.updatePath();
  }

  /**
   * Updates the path of the outline, only if the inputs change the outline.
   * @returns True if the path was changed, false otherwise
   */
  update(start: Vector2, end: Vector2, controlPoint: Vector2 | null, steps?: number) {
    const newAB = Geometry.getPointDelta(start, end);
    const newAC = controlPoint !== null ? Geometry.getPointDelta(start, controlPoint) : null;
    if (!vectorsEqual(newAB, this.cachedAB) || !possiblyNullVectorsEqual(newAC, this.cachedAC) || steps !== this.cachedSteps) {
      this.updateCache(start, end, controlPoint, steps);
      this.updatePath();
      return true;
    }
    return false;
  }

  protected updateCache(start: Vector2, end: Vector2, controlPoint: Vector2 | null, steps?: number) {
    this.cachedStart = start;
    this.cachedEnd = end;
    this.cachedControl = controlPoint;
    this.cachedSteps = steps;
    this.cachedAB = Geometry.getPointDelta(start, end);
    this.cachedAC = controlPoint !== null ? Geometry.getPointDelta(start, controlPoint) : null;
  }

  protected updatePath() {
    this.path = createLineOutline(this.cachedStart, this.cachedEnd, this.cachedControl, this.cachedSteps);
  }
}

/**
 * Caches the outline of a path, only updating if the outline should change.
 * Works on the same assumptions as CachedTriangleOutline
 */
export class CachedPathOutline extends CachedOutline {
  protected cachedStart: Vector2;
  protected cachedEnd: Vector2;
  protected cachedControl: Vector2 | null;
  protected cachedSegmentSize: number;
  protected cachedAB: Vector2;
  protected cachedAC: Vector2 | null;

  constructor(start: Vector2, end: Vector2, controlPoint: Vector2 | null, segmentSize: number) {
    super();
    this.updateCache(start, end, controlPoint, segmentSize);
    this.updatePath();
  }

  /**
   * Updates the path of the outline, only if the inputs change the outline.
   * @returns True if the path was changed, false otherwise
   */
  update(start: Vector2, end: Vector2, controlPoint: Vector2 | null, segmentSize: number) {
    const newAB = Geometry.getPointDelta(start, end);
    const newAC = controlPoint !== null ? Geometry.getPointDelta(start, controlPoint) : null;
    if (!vectorsEqual(newAB, this.cachedAB) || !possiblyNullVectorsEqual(newAC, this.cachedAC) || segmentSize !== this.cachedSegmentSize) {
      this.updateCache(start, end, controlPoint, segmentSize);
      this.updatePath();
      return true;
    }
    return false;
  }

  protected updateCache(start: Vector2, end: Vector2, controlPoint: Vector2 | null, segmentSize: number) {
    this.cachedStart = start;
    this.cachedEnd = end;
    this.cachedControl = controlPoint;
    this.cachedSegmentSize = segmentSize;
    this.cachedAB = Geometry.getPointDelta(start, end);
    this.cachedAC = controlPoint !== null ? Geometry.getPointDelta(start, controlPoint) : null;
  }

  protected updatePath() {
    this.path = createPathOutline(this.cachedStart, this.cachedEnd, this.cachedControl, this.cachedSegmentSize);
  }
}
