import React, { useMemo } from 'react';
import { HelpModal, ModalPaneSection, ModalPaneSectionHeader } from '@gi/modal';
import plantFamilies from '@gi/plant-families';
import PlantFamilyHelpRow from './plant-family-row';

interface iProps {
  closeModal: () => void;
}

const CropRotationHelpModal = ({ closeModal }: iProps) => {
  const plantFamilyHelp = useMemo(() => {
    return [...plantFamilies].map((plantFamily) => <PlantFamilyHelpRow plantFamily={plantFamily} key={plantFamily.ID} />);
  }, []);

  return (
    <HelpModal className='crop-rotation-help-modal' headerText='Crop Rotation within the Garden Planner' closeModal={closeModal} style={{ maxWidth: 520 }}>
      <ModalPaneSection>
        <p>Crop families are indicated in the Garden Planner by the colored area behind each plant.</p>
        <p>
          When you create next year&apos;s plan, first open the previous year&apos;s plan, click the New Plan button and select the Follow-on Plan option. By
          creating follow-on plans, the Garden Planner automatically links up to five seasons&apos; plans and shows red crop rotation warnings to help you avoid
          growing plants from the same crop family in the same area in the following year, which can result in a build-up of soil-borne pests and diseases.
        </p>
        <p>
          When you select a plant in your follow-on plan, areas of the plan will flash red to warn you of where you grew plants from that family on last
          year&apos;s plan. This makes it easy to find the best place to grow without having to remember which crop family each plant belongs to.
        </p>
        <p>
          More information about crop rotation can be found in{' '}
          <a target='_blank' rel='noreferrer' href='/guides/easy-crop-rotation-using-the-colors-of-the-rainbow/'>
            this guide
          </a>
          .
        </p>
      </ModalPaneSection>
      <ModalPaneSection>
        <ModalPaneSectionHeader>Crop Families</ModalPaneSectionHeader>
        {plantFamilyHelp}
      </ModalPaneSection>
    </HelpModal>
  );
};

export default CropRotationHelpModal;
