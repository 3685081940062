import { Validator } from './types';

/**
 * Generic validators
 *  These validators should be extremely generic.
 */

/**
 * Checks if the given parameter is not null
 */
export const isNotNull = <T = any>(errorMessage: string = 'cannot be null'): Validator<T> => {
  return (value) => {
    return value === null ? [errorMessage] : [];
  };
};

/**
 * Ensures the field being validated doesn't equal the given thing.
 * @param toCompare The thing the other thing must not equal
 * @param errorText Optionally change the errorText
 */
export const isNotEqual = <T>(toCompare: T, errorText: string = `must not equal ${toCompare}`): Validator<T> => {
  return (value: T) => {
    return value === toCompare ? [errorText] : [];
  };
};
