/**
 * Enum for the types of canvas actions
 */
enum CanvasActionTypes {
  SET_CONTAINER = 'CANVAS/SET_CONTAINER',
  OPEN_PLAN = 'CANVAS/OPEN_PLAN',
  CLOSE_PLAN = 'CANVAS/CLOSE_PLAN',
  SET_ACTIVE_PLAN = 'CANVAS/SET_ACTIVE_PLAN',
  PLANS_LOAD_START = 'CANVAS/PLANS_LOAD_START',
  PLAN_LOAD_END = 'CANVAS/PLAN_LOAD_END',
  PLAN_LOAD_FAILED = 'CANVAS/PLAN_LOAD_FAILED',
  UPDATE_PLAN = 'CANVAS/UPDATE_PLAN',
  UPDATE_PLAN_FROM_API = 'CANVAS/UPDATE_PLAN_FROM_API',
  REMOVE_PLANS = 'CANVAS/REMOVE_PLANS',
  CREATE_PLAN_SUCCESS = 'CANVAS/CREATE_PLAN_SUCCESS',
  SAVE_PLAN_SUCCESS = 'CANVAS/SAVE_PLAN_SUCCESS',
  DELETE_PLAN_SUCCESS = 'CANVAS/DELETE_PLAN_SUCCESS',
  UNDO = 'CANVAS/UNDO',
  REDO = 'CANVAS/REDO',
  CLEAR_UNDO_STACK = 'CANVAS/CLEAR_UNDO_STACK',
  ADD_PLAN = 'CANVAS/ADD_PLAN',
  EDIT_ITEM = 'CANVAS/EDIT_ITEM',
  SHOW_CONTEXT_MENU = 'CANVAS/SHOW_CONTEXT_MENU',
  HIDE_CONTEXT_MENU = 'CANVAS/HIDE_CONTEXT_MENU',
  SET_TEXTURE_STATUS = 'CANVAS/SET_TEXTURE_STATUS',
  ADD_TO_CLOSE_CONFIRMATION = 'CANVAS/ADD_TO_CLOSE_CONFIRMATION',
  REMOVE_FROM_CLOSE_CONFIRMATION = 'CANVAS/REMOVE_FROM_CLOSE_CONFIRMATION',
  PLAN_DATA_ERROR = 'CANVAS/PLAN_DATA_ERROR',
  CLEAR_PLAN_DATA_ERRORS = 'CANVAS/CLEAR_PLAN_DATA_ERRORS',
  CLEAR_PLAN_LOAD_FAILURES = 'CANVAS/CLEAR_PLAN_LOAD_FAILURES',
}

export default CanvasActionTypes;
