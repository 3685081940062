import moment from 'moment';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { SessionSelectors } from '@gi/react-session';
import { FormSection } from '@gi/form-responsive';
import { ResourceContext } from '@gi/resource-provider';

import './settings-pages.scss';
import { UserSubscriptionUtils } from '@gi/user';

const SubscriptionOverviewPage = (): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);
  const { userCountry } = useContext(ResourceContext);

  if (!user) {
    console.warn('Attempting to render SubscriptionOverviewPage without a User');
    return null;
  }

  const subscriptionText = (() => {
    if (!user.subscription.subscribed) {
      return UserSubscriptionUtils.hasFreeTrial(user.subscription) ? 'Free Trial' : 'Your free trial has ended';
    }
    if (UserSubscriptionUtils.hasSubscription(user.subscription)) {
      return `Active Subscription${!user.subscription.recurringSubscription ? ' (not recurring)' : ''};`;
    }
    return 'Subscription Expired';
  })();

  const timeRemainingText = (() => {
    const { accountExpires } = user.subscription;

    const days = accountExpires.diff(moment(), 'days');
    const date = accountExpires.format(userCountry?.longDateFormat ?? 'Do MMMM YYYY').trim();

    return UserSubscriptionUtils.hasSubscription(user.subscription) || UserSubscriptionUtils.hasFreeTrial(user.subscription) ? (
      <p>
        <span className='subscription-label'>Time Remaining:</span> {`${days} day${days === 1 ? '' : 's'} (${date})`}
      </p>
    ) : null;
  })();

  return (
    <div className='settings-page'>
      <FormSection className='form-section-background'>
        <h2>Subscription Details</h2>
        <p>
          <span className='subscription-label'>Account Status:</span> {subscriptionText}
        </p>
        {timeRemainingText}
      </FormSection>
      <FormSection padding={12}>
        <p>
          You can manage your subscription and purchase Gift Certificates on the{' '}
          <a target='_blank' rel='noreferrer' href='/account/settings/subscription/'>
            Manage Subscriptions page
          </a>
          .
        </p>
      </FormSection>
    </div>
  );
};

export default SubscriptionOverviewPage;
