import React, { ChangeEvent, useCallback } from 'react';

import { PickerButton } from '@gi/texture-picker';
import { SwatchTypes, presetPalettes } from '@gi/palette';
import FormField, { FORM_FIELD_PRESETS } from '@gi/form-responsive';

import { EditShapeModalFormProps, ShapeState } from '../../types';

/**
 * Displays a "Filled?" checkbox, and a fill swatch picker if checked.
 */
const FillPropertiesFormSection = ({ values, setValues }: Omit<EditShapeModalFormProps, 'distanceUnits'>): JSX.Element => {
  const createSetter = useCallback(
    <K extends keyof ShapeState>(setting: K) =>
      (value: ShapeState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  const onFilledChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let { selectedSwatch } = values.values;
      const { checked } = event.target;
      // If switching to outline shape and current swatch is a texture, default back to a colour.
      if (!checked && selectedSwatch.type === SwatchTypes.IMAGE) {
        selectedSwatch = { value: '000000', type: SwatchTypes.COLOR };
      }
      setValues(values.setValues(['filled', { value: checked }], ['selectedSwatch', { value: selectedSwatch }]));
    },
    [values, setValues]
  );

  return (
    <>
      <FormField label='Filled' htmlFor='edit-shape:filled' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <input type='checkbox' checked={values.values.filled} onChange={onFilledChange} id='edit-shape:filled' />
      </FormField>
      {values.values.filled ? (
        <FormField label='Fill' fakeLabel layoutPreset={FORM_FIELD_PRESETS.EditModal}>
          <PickerButton
            selectedSwatch={values.values.selectedSwatch}
            palettes={presetPalettes.withTexturesPalettes}
            onSwatchSelect={createSetter('selectedSwatch')}
          />
        </FormField>
      ) : null}
    </>
  );
};

export default FillPropertiesFormSection;
