import React from 'react';
import { GuruSection } from '@gi/app-guru-types/types';

import SmallPreviewRow from '../small-preview/small-preview-row/small-preview-row';
import EmptyPreviewRow from '../empty-preview/empty-preview-row';
import { DirectoryPageSectionProvider } from './directory-page-section-provider';
import VirtualizedSmallPreviewRow from '../small-preview/virtualized-small-preview/virtualized-small-preview-row';

interface iProps {
  sectionId: string;
  section: GuruSection;
  showEmptySectionTitle?: boolean;
  emptySectionComponent?: React.ReactNode;
  usePaginatedRows?: boolean;
}

/**
 * Simple component which renders the correct PreviewRow depending on whether
 * pagination is to be used
 */
const DirectoryPageSectionRow = ({ sectionId, section, showEmptySectionTitle, emptySectionComponent, usePaginatedRows = true }: iProps): JSX.Element | null => {
  if (section.items.length === 0 && emptySectionComponent) {
    return <EmptyPreviewRow title={showEmptySectionTitle ? section.title : null}>{emptySectionComponent}</EmptyPreviewRow>;
  }

  if (!usePaginatedRows) {
    return <SmallPreviewRow title={section.title} items={section.items} hideWhenEmpty />;
  }

  return (
    <DirectoryPageSectionProvider sectionId0={sectionId} lookAheadPages={1}>
      <VirtualizedSmallPreviewRow title={section.title} hideWhenEmpty />
    </DirectoryPageSectionProvider>
  );
};

export default DirectoryPageSectionRow;
