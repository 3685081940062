/**
 * Forces the given value to be an integer between min and max.
 * @param input The page count to round
 * @param min The minimum possible value
 * @param max The maximum possible value
 * @returns The value clamped between min and max
 */
export const forceIntBetween = (input: number, min: number, max: number) => {
  if (Number.isNaN(input)) {
    return min;
  }
  return Math.min(Math.max(Math.round(input), min), max);
};
