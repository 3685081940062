import React from 'react';
import type { RouteObject } from 'react-router-dom';
import Help from '@gi/app-help';
import { Application, ApplicationLinks } from '@gi/garden-platform-navigation';

const appHelpRoutes: RouteObject = {
  path: ApplicationLinks[Application.Help],
  element: <Help />,
};

export default appHelpRoutes;
