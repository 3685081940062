import React from 'react';
import { useSelector } from 'react-redux';
import { SessionSelectors } from '@gi/react-session';
import { PlanPlant } from '@gi/plan';
import { getInGroundString } from './in-ground-dates-cell';

interface iProps {
  planPlant: PlanPlant;
}

const InsetInGroundDatesCell = ({ planPlant }: iProps): JSX.Element | null => {
  const northernHemisphere = useSelector(SessionSelectors.getIsNorthernHemisphere);

  return (
    <td className='inset-td inset-in-ground-td'>
      {planPlant.inGroundAll ? 'All Year Round' : getInGroundString(planPlant.inGroundStart, planPlant.inGroundEnd, northernHemisphere)}
    </td>
  );
};

export default InsetInGroundDatesCell;
