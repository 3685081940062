import React, { ReactNode } from 'react';
import { GuruContentType, GuruPreviewPlant } from '@gi/app-guru-types';

import SmallPreviewContent from '../small-preview-content';
import SmallPreviewTitle from '../small-preview-title';
import SmallPreview from '../small-preview';
import SmallPreviewTag from '../small-preview-tag';
import SmallPreviewLink from '../small-preview-link';

import LazyImage from '../../lazy-image/lazy-image';

import styles from './small-preview-plant.module.css';

interface iProps {
  previewPlant: GuruPreviewPlant;
  className?: string;
  children?: ReactNode;
}

const SmallPreviewPlant = ({ previewPlant, className = '', children }: iProps): JSX.Element | null => {
  return (
    <SmallPreview className={className}>
      <SmallPreviewLink type={GuruContentType.Plant} slug={previewPlant.slug}>
        <SmallPreviewContent>
          <LazyImage className={styles.plantImage} src={previewPlant.thumbnailUrl} alt='' />
          <SmallPreviewTag text='Plant Guide' color='#4166EB' />
        </SmallPreviewContent>
        <SmallPreviewTitle>{children !== undefined ? children : previewPlant.title}</SmallPreviewTitle>
      </SmallPreviewLink>
    </SmallPreview>
  );
};

export default SmallPreviewPlant;
