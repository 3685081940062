import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { autoUpdate, offset, shift, computePosition, arrow, Placement, useMergeRefs } from '@floating-ui/react';

import styles from './menu-styles.module.css';
import Arrow from './arrow';

export type TutorialAttachment = {
  locator: string; // document.querySelectorAll
  placement: Placement; // top, left, bottom, right ?+ start/end, 'top', 'top-start', 'top-end', etc..
};

const ARROW_OFFSET = 6;
const ARROW_WIDTH = 12;
const ARROW_HEIGHT = ARROW_OFFSET;

const DEFAULT_ATTACHMENT = 'right-start';

// TODO - create a generic attached window, this is a copy of attached-tutorial-window

interface iProps {
  attachment: TutorialAttachment;
  target: HTMLElement;
  children: React.ReactNode;
}

const DesktopDropdownWindow = forwardRef<HTMLDivElement, iProps>(({ attachment, target, children }, refProp): JSX.Element => {
  const [style, setStyle] = useState<React.CSSProperties>({});
  const [arrowStyle, setArrowStyle] = useState<React.CSSProperties>({});

  const tutorialWindowRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  const mergedRef = useMergeRefs([tutorialWindowRef, refProp]);

  useEffect(() => {
    if (target === null || tutorialWindowRef.current === null) {
      return () => {};
    }

    const updatePosition = () => {
      if (target === null || tutorialWindowRef.current === null) {
        return;
      }

      computePosition(target, tutorialWindowRef.current, {
        placement: attachment.placement ? attachment.placement : DEFAULT_ATTACHMENT,
        middleware: [offset(6), shift({ padding: 6 }), arrow({ element: arrowRef.current })],
      }).then(({ x, y, placement, middlewareData }) => {
        const [_side, alignment] = placement.split('-');
        const side = _side as 'bottom' | 'top' | 'left' | 'right';
        const isAligned = alignment != null;

        setStyle({
          left: `${x}px`,
          top: `${y}px`,
        });

        if (arrowRef.current) {
          const arrowX = middlewareData.arrow!.x!;
          const arrowY = middlewareData.arrow!.y!;

          const unsetSides = {
            top: '',
            bottom: '',
            left: '',
            right: '',
          };
          const staticSide: 'bottom' | 'top' | 'left' | 'right' = (
            {
              top: 'bottom',
              right: 'left',
              bottom: 'top',
              left: 'right',
            } as const
          )[side];

          const isRefWider = target.offsetWidth > tutorialWindowRef.current!.offsetWidth;
          const isRefTaller = target.offsetHeight > tutorialWindowRef.current!.offsetHeight;
          const isVerticalSide = side === 'top' || side === 'bottom';
          const useStaticPositioning = isAligned && (isVerticalSide ? isRefWider : isRefTaller);

          const rotation = {
            top: '180deg',
            bottom: '0deg',
            left: '90deg',
            right: '-90deg',
          }[side];

          if (useStaticPositioning) {
            const crossSide = {
              'top-start': 'left',
              'top-end': 'right',
              'bottom-start': 'left',
              'bottom-end': 'right',
              'left-start': 'top',
              'left-end': 'bottom',
              'right-start': 'top',
              'right-end': 'bottom',
            }[placement];

            setArrowStyle({
              ...unsetSides,
              [staticSide]: `${ARROW_OFFSET}px`,
              [crossSide]: 'min(15%, 15px)',
              transform: `rotate(${rotation})`,
            });
          } else {
            setArrowStyle({
              ...unsetSides,
              left: x != null ? `${arrowX}px` : '',
              top: y != null ? `${arrowY}px` : '',
              [staticSide]: `-${ARROW_OFFSET}px`,
              transform: `rotate(${rotation})`,
            });
          }
        }
      });
    };

    const cleanup = autoUpdate(target, tutorialWindowRef.current, updatePosition);

    return () => {
      cleanup();
    };
  }, [target]);

  return (
    <div className={`${styles.desktopDropdownWindow}`} ref={mergedRef} style={style}>
      {children}
      <div ref={arrowRef} style={{ position: 'absolute', fontSize: 0, ...arrowStyle }}>
        <Arrow width={ARROW_WIDTH} height={ARROW_HEIGHT} fill='#f2f2f2' />
      </div>
    </div>
  );
});
DesktopDropdownWindow.displayName = 'DesktopDropdownWindow';

export default DesktopDropdownWindow;
