import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { withResources } from '@gi/resource-provider';
import { CanvasSelectors } from '@gi/react-garden-canvas';

import EditPlantModal from '@gi/edit-plant-modal';
import { SessionActionCreators } from '@gi/react-session';
import { PlantInformationActionCreators } from '@gi/plant-information-modal';

import { closeModal, editItemModalComplete } from '../modal-action-creators.ts';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    plan: CanvasSelectors.getActivePlan(state),
    plantItemID: state.modal.editItemData.itemID,
    varietyForDefaultLabel: state.session.user.planSettings.varietyForDefaultLabel,
    user: state.session.user,
    requests: state.requests,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal,
      editItemModalComplete,
      saveUserPlantVarietes: SessionActionCreators.saveUserPlantVarietes,
      openPlantInformationModal: PlantInformationActionCreators.openPlantInformationModal,
    },
    dispatch
  );
};

export default withResources(connect(mapStateToProps, mapDispatchToProps)(EditPlantModal));
