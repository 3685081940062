import { useEffect, useRef, useState } from 'react';

/**
 * Throttles a value, meaning it can only update every [delay]ms.
 * @param value The value to throttle
 * @param delay The delay between updates
 * @returns The value, but throttled
 */
const useThrottle = <T>(value: T, delay: number = 500): T => {
  const lastUpdate = useRef(Date.now());
  const [throttledValue, setThrottledValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        if (Date.now() > lastUpdate.current + delay) {
          setThrottledValue(value);
          lastUpdate.current = Date.now();
        }
      },
      delay - (Date.now() - lastUpdate.current)
    );

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return throttledValue;
};

export default useThrottle;
