import React from 'react';

import styles from './hero-preview-components.module.css';

interface iProps {
  index: number;
  setIndex: (i: number) => void;
  active: boolean;
}

const HeroPreviewPip = ({ index, setIndex, active }: iProps) => {
  return <button type='button' className={`${styles.pip} ${active ? styles.activePip : ''}`} onClick={() => setIndex(index)} />;
};

export default HeroPreviewPip;
