import GardenObject from '@gi/garden-object';

import { SearchDisplayMode } from './search-result';
import { SearchService } from './search-service';

export class GardenObjectSearchService extends SearchService<GardenObject> {
  constructor(itemList: GardenObject[], options?: Fuzzysort.Options) {
    super(
      itemList,
      ['name', 'otherNames'],
      {
        name: {
          weight: 1,
          processBrackets: true,
          processBracketsWeightFactor: 0.25,
        },
        otherName: {
          weight: 0.5,
          delimiter: ',',
          displayMode: SearchDisplayMode.Secondary,
        },
      },
      options
    );
  }
}
