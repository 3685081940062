import React from 'react';

import { SidebarHeader, SidebarHeaderContent, SidebarHeaderToggleButton } from '@gi/planner-sidebar';

const DrawingToolsHeader = (): JSX.Element => {
  return (
    <SidebarHeader>
      <SidebarHeaderContent>
        <h4>Drawing Tools</h4>
      </SidebarHeaderContent>
      <SidebarHeaderToggleButton />
    </SidebarHeader>
  );
};

export default DrawingToolsHeader;
