import { Graphics, Sprite, Texture } from 'pixi.js-new';

import { State, StateDef } from '@gi/state';

import GraphicNode from '../../graphics-node';
import TransformComponent from '../../node-components/transform/transform-component';
import { InspectableClassData } from '../../types';
import InteractableComponent, { InteractableComponentCallbacks } from '../../node-components/interactable/interactable-component';
import { PointerData, PointerDataWithDelta } from '../../managers/interaction/interaction';
import ManipulatableComponentContext from '../../node-components/manipulatable/manipulatable-component-context';
import HoverableComponent from '../../node-components/hoverable/hoverable-component';
import HoverableComponentContext from '../../node-components/hoverable/hoverable-component-context';

import squareHandleSrc from './square-handle.png';
import roundHandleSrc from './round-handle.png';
import { CameraNodeState } from '../camera/camera-node';
import { bindState } from '../../utils/state-utils';
import { createActiveCameraConnector } from '../../utils/camera-utils';

// The scale of the sprites used in touch mode.
// 0.6 = old core renderer size (handle size = 3, sprite scale = 0.2 ∴ overall scale = 0.6)
const TOUCH_MODE_SPRITE_SCALE = 0.6;

export enum HandleType {
  SQUARE = 'SQUARE',
  ROUNDED = 'ROUNDED',
  CIRCLE = 'CIRCLE',
}

export enum HandleDisplayMode {
  MOUSE = 'DESKTOP',
  TOUCH = 'MOBILE',
}

const squareHandle = Texture.from(squareHandleSrc);
const roundHandle = Texture.from(roundHandleSrc);

const HandleTypeSpriteMapping = {
  [HandleType.SQUARE]: squareHandle,
  [HandleType.ROUNDED]: roundHandle,
  [HandleType.CIRCLE]: roundHandle,
};

type HandleNodeState = StateDef<
  {
    scale: number;
    displayMode: HandleDisplayMode;
  },
  [],
  {
    camera: CameraNodeState;
  }
>;

const DEFAULT_STATE: HandleNodeState['state'] = {
  scale: 1,
  displayMode: HandleDisplayMode.MOUSE,
};

/**
 * Handle Node
 *  Basic node that has drag callbacks for use in a handle set. Will automatically scale with camera zoom.
 */
class HandleNode extends GraphicNode {
  type = 'HandleNode';

  readonly handleType: HandleType;

  index: number = 0;

  #graphics: Graphics = new Graphics();
  #sprite: Sprite | null = null;

  readonly transform: TransformComponent;
  readonly hoverable: HoverableComponent;
  #interaction: InteractableComponent;
  #manipulatableContext: ManipulatableComponentContext | null = null;

  #dragging: boolean = false;

  onDragStart: ((data: PointerDataWithDelta) => void) | null = null;
  onDragMove: ((data: PointerDataWithDelta) => void) | null = null;
  onDragEnd: ((data: PointerDataWithDelta) => void) | null = null;
  // This is a utility callback. It's called whenever onDragStart/Move/End is called.
  // Do not do movement effects in this if also doing them in onDragStart/End, as they will apply twice.
  onDrag: ((data: PointerDataWithDelta) => void) | null = null;
  onDoubleClick: ((data: PointerData) => void) | null = null;

  state: State<HandleNodeState>;

  constructor(name: string, type: HandleType = HandleType.SQUARE, position?: Vector2) {
    super();

    this.state = new State(DEFAULT_STATE);
    bindState(this.state, this);

    this.name = name;
    this.handleType = type;

    this.transform = this.components.add(new TransformComponent());
    if (position) {
      this.transform.state.values.position = position;
    }

    this.#interaction = this.components.add(new InteractableComponent({ ignoreLongPress: true }));
    this.#interaction.addListener('onDragStart', this.callOnDragStart);
    this.#interaction.addListener('onDragMove', this.callOnDragMove);
    this.#interaction.addListener('onDragEnd', this.callOnDragEnd);
    this.#interaction.addListener('onDragCancel', this.callOnDragCancel);
    this.#interaction.addListener('onDoubleClick', this.callOnDoubleClick);
    // Prevent clicks and long-presses propagating
    this.#interaction.addListener('onClick', this.preventClickThrough);
    this.#interaction.addListener('onLongPress', this.preventClickThrough);

    this.hoverable = this.components.add(new HoverableComponent());

    this.getOwnGraphics().addChild(this.#graphics);

    this.state.addWatcher(() => this.#draw(), {
      properties: ['displayMode', 'scale'],
      otherStates: {
        camera: { properties: ['magnification'] },
      },
    });

    createActiveCameraConnector(this, this.state, 'camera');

    this.#draw();
  }

  /**
   * Updates the position (and rotation if applicable) of the handle.
   * @param pos The position of the handle
   * @param rotation The rotation of the handle. If omitted, rotation will not be updated.
   */
  setPosition(pos: Vector2, rotation?: number) {
    this.transform.state.values.position = pos;
    if (rotation !== undefined) {
      this.transform.state.values.rotation = rotation;
    }
  }

  /**
   * Returns the handle's position (in world space)
   * @returns The position of the handle
   */
  getPosition() {
    return this.transform.state.values.position;
  }

  /**
   * Sets the rotation of the handle.
   * @param rotation The rotation of the handle
   */
  setRotation(rotation: number) {
    this.transform.state.values.rotation = rotation;
  }

  /**
   * Returns the rotation of this handle (not the parent)
   */
  getRotation() {
    return this.transform.state.values.rotation;
  }

  /**
   * Sets the visibility of this handle.
   * @param visible Is visible?
   */
  setVisibility(visible: boolean) {
    this.getOwnGraphics().visible = visible;
  }

  /**
   * Sets the scale of the handle
   * @param scale The scale (1 = 100%)
   */
  setScale(scale: number) {
    this.state.values.scale = scale;
  }

  /**
   * Sets the display mode of this handle (or its appearance)
   * @param displayMode The display mode
   */
  setDisplayMode(displayMode: HandleDisplayMode) {
    this.state.values.displayMode = displayMode;
  }

  callOnDragStart: InteractableComponentCallbacks['onDragStart'] = (data, interaction, controls) => {
    if (data.button !== 0) {
      return;
    }
    controls.stopPropagation();
    this.#dragging = true;
    this.#manipulatableContext = this.tryGetContext(ManipulatableComponentContext);
    if (this.#manipulatableContext) {
      this.#manipulatableContext.startManipulating(this);
    }
    if (data.pointerType !== 'touch') {
      this.tryGetContext(HoverableComponentContext)?.hover(this);
    }
    if (this.onDragStart) {
      this.onDragStart(data);
    }
    if (this.onDrag) {
      this.onDrag(data);
    }
  };

  callOnDragMove: InteractableComponentCallbacks['onDragMove'] = (data, interaction, controls) => {
    if (!this.#dragging) {
      return;
    }
    controls.stopPropagation();
    if (this.onDragMove) {
      this.onDragMove(data);
    }
    if (this.onDrag) {
      this.onDrag(data);
    }
  };

  callOnDragEnd: InteractableComponentCallbacks['onDragEnd'] = (data, interaction, controls) => {
    if (!this.#dragging) {
      return;
    }
    controls.stopPropagation();
    this.#dragging = false;
    if (this.onDrag) {
      this.onDrag(data);
    }
    if (this.onDragEnd) {
      this.onDragEnd(data);
    }
    if (this.#manipulatableContext) {
      this.#manipulatableContext.endManipulating();
      this.#manipulatableContext = null;
    }
  };

  callOnDragCancel: InteractableComponentCallbacks['onDragCancel'] = (data, interaction, controls) => {
    if (!this.#dragging) {
      return;
    }
    controls.stopPropagation();
    this.#dragging = false;
    if (this.#manipulatableContext) {
      this.#manipulatableContext.endManipulating();
      this.#manipulatableContext = null;
    }
  };

  callOnDoubleClick: InteractableComponentCallbacks['onDoubleClick'] = (data) => {
    if (this.onDoubleClick) {
      this.onDoubleClick(data);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  preventClickThrough: InteractableComponentCallbacks['onClick'] = (data, interaction, controls) => {
    if (data.button === 0) {
      controls.stopPropagation();
    }
  };

  /**
   * Redraws this handle.
   */
  #draw() {
    const { displayMode } = this.state.values;
    switch (displayMode) {
      case HandleDisplayMode.TOUCH:
        this.#drawMobile();
        return;
      case HandleDisplayMode.MOUSE:
      default:
        this.#drawDesktop();
    }
  }

  #drawDesktop() {
    if (this.#sprite) {
      this.#sprite.destroy();
      this.#sprite = null;
    }

    const { scale } = this.state.values;
    const cameraMagnification = this.state.get('camera', 'magnification', 1);

    const finalScale = scale * (1 / cameraMagnification);
    const size = 5 * finalScale;
    this.#graphics.clear();
    this.#graphics.beginFill(0xffffff);
    this.#graphics.lineStyle({ color: 0x000000, width: 1 * finalScale });
    switch (this.handleType) {
      case HandleType.CIRCLE:
        this.#graphics.drawCircle(0, 0, size);
        break;
      case HandleType.ROUNDED:
        this.#graphics.drawRoundedRect(-size, -size, size * 2, size * 2, 3 * finalScale);
        break;
      case HandleType.SQUARE:
      default:
        this.#graphics.drawRect(-size, -size, size * 2, size * 2);
    }
    this.#graphics.endFill();
  }

  #drawMobile() {
    if (!this.#sprite) {
      this.#sprite = new Sprite(HandleTypeSpriteMapping[this.handleType]);
      this.getOwnGraphics().addChild(this.#sprite);
    }

    const { scale } = this.state.values;
    const cameraMagnification = this.state.get('camera', 'magnification', 1);

    this.#sprite.texture = HandleTypeSpriteMapping[this.handleType];
    this.#sprite.width = this.#sprite.texture.width;
    this.#sprite.height = this.#sprite.texture.height;
    this.#sprite.x = 0;
    this.#sprite.y = 0;
    this.#sprite.anchor.set(0.5, 0.5);
    this.#sprite.scale.x = scale * (1 / cameraMagnification) * TOUCH_MODE_SPRITE_SCALE;
    this.#sprite.scale.y = scale * (1 / cameraMagnification) * TOUCH_MODE_SPRITE_SCALE;

    this.#graphics.clear();
  }

  inspectorData: InspectableClassData<this> = [];
}

export default HandleNode;
