import { Graphics } from 'pixi.js-new';

import {
  DraggableComponent,
  HoverableComponent,
  InteractableComponent,
  NodeEvent,
  OutlineComponent,
  SelectableComponent,
  TextComponent,
  TextContainer,
  ShapeComponent,
  SelectableComponentContext,
  VisibilityComponent,
  ShapeFlag,
  DoubleClickableComponent,
  HiddenFlag,
  bindToLifecycle,
  RightClickableComponent,
} from '@gi/core-renderer';
import Bitmask from '@gi/bitmask';
import { Geometry } from '@gi/math';
import { StateDef, StateObserver, StateProperties } from '@gi/state';
import { LayerTypes } from '@gi/constants';

import { createRectOutline } from '../utils';
import SettingsContext, { SettingsContextState } from '../settings-context';
import GardenItemNode, { GardenItemNodeState } from '../garden-item-node';

const LABEL_PADDING: Vector2 = { x: 4, y: 4 };
const LABEL_BACKGROUND_COLOUR = 0xffffff;
const LABEL_BACKGROUND_ALPHA = 0.75;
const LABEL_TEXT_COLOUR = 0x000000;

// (fallback if settings unavailable) The factor to increase the font size by, to increase perceived resolution.
const DEFAULT_LABEL_FONT_SCALE = 4;

type PlantLabelNodeState = StateDef<
  {
    labelOffset: Vector2;
    plantCenter: Vector2;
    labelText: string;
    showLabel: boolean;
    visible: boolean;
  } & GardenItemNodeState,
  [],
  {
    settings: SettingsContextState;
  }
>;

class PlantLabelNode extends GardenItemNode<PlantLabelNodeState> {
  type = 'PlantLabelNode';

  #background: Graphics | null = null;
  #text: TextContainer | null = null;

  #textComponent: TextComponent;
  #outline: OutlineComponent;
  #shape: ShapeComponent;
  #selectableContext: SelectableComponentContext | null = null;
  #visibility: VisibilityComponent;

  constructor(plantId: number, initialState: PlantLabelNodeState['state']) {
    super(plantId, initialState, LayerTypes.PLANT_LABELS);

    this.name = `${plantId} - ${this.state.values.labelText}`;

    this.transform.state.values.position = this.state.values.labelOffset;

    this.components.add(new InteractableComponent());
    this.components.add(new HoverableComponent());
    this.components.add(new SelectableComponent());
    this.components.add(new DraggableComponent());
    this.components.add(new DoubleClickableComponent());
    this.components.add(new RightClickableComponent());
    this.#visibility = this.components.add(new VisibilityComponent());
    this.#shape = this.components.add(new ShapeComponent({ flags: Bitmask.Create(ShapeFlag.CULLABLE) }));
    this.#outline = this.components.add(new OutlineComponent());
    this.#outline.customDrawFunction = this.#drawOutline;

    this.state.addUpdater(
      (state) => {
        this.transform.state.values.position = Geometry.addPoint(state.values.plantCenter, state.values.labelOffset);
      },
      { properties: ['plantCenter', 'labelOffset'] }
    );

    this.state.addUpdater(
      (state) => {
        this.#visibility.setHiddenFlag(HiddenFlag.PLANT_LABEL, !state.values.showLabel);
      },
      { properties: ['showLabel'] }
    );

    this.state.addUpdater(
      (state) => {
        this.#visibility.setHiddenFlag(HiddenFlag.VISIBILITY, !state.values.visible);
      },
      { properties: ['visible'] }
    );

    this.state.addUpdater(
      (state) => {
        const labelTextSize = state.get('settings', 'labelTextSize');
        if (labelTextSize !== undefined) {
          this.#textComponent.state.values.fontSize = labelTextSize;
        }
        const textQuality = state.get('settings', 'textQuality');
        if (textQuality !== undefined) {
          this.#textComponent.state.values.fontScale = textQuality;
        }
      },
      { otherStates: { settings: { properties: ['labelTextSize', 'textQuality'] } } }
    );

    this.state.addWatcher(
      (state) => {
        if (this.#text) {
          this.#text.text = state.values.labelText;
        }
      },
      { properties: ['labelText'] }
    );

    this.#textComponent = this.components.add(
      new TextComponent({
        fontSize: 12, // Gets loaded from the Settings context later
        fontScale: this.state.get('settings', 'textQuality', DEFAULT_LABEL_FONT_SCALE),
        fontFamily: ['Verdana', 'Helvetica', 'Arial'],
        color: LABEL_TEXT_COLOUR,
        baseCameraMagnification: 4, // The above settings are for 4x magnification level.
        useMipmaps: false,
      })
    );

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);

    this.#setupWatchers();
  }

  #onBind = () => {
    this.#background = new Graphics();
    this.ownGraphics.addChild(this.#background);

    this.#text = this.#textComponent.createText(this.state.values.labelText);
    this.#text.anchor = { x: 0.5, y: 0.5 };
    this.ownGraphics.addChild(this.#text);

    this.#selectableContext = this.tryGetContext(SelectableComponentContext);

    const settings = this.getContext(SettingsContext);
    this.state.connectState('settings', settings.state);
  };

  #onBeforeUnbind = () => {
    this.#background?.destroy();
    this.#background = null;
    this.#text?.destroy();
    this.#text = null;
    this.#selectableContext = null;
  };

  #setupWatchers = () => {
    bindToLifecycle(this, () => {
      const watcher = new StateObserver(
        [
          new StateProperties(this.#textComponent.state, {
            properties: ['fontFamily', 'fontSize'],
          }),
          new StateProperties(this.state, { properties: ['labelText'] }),
        ] as const,
        true,
        () => {
          this.#updateBackground();
          this.#updateShape();
        }
      );
      return () => watcher.destroy();
    });
  };

  /**
   * Sets the center position of the parent plant
   * @param center The center position of the parent plant
   */
  setPlantCenter(center: Vector2) {
    this.state.values.plantCenter = center;
  }

  /**
   * Sets the text this label displays
   * @param labelText The text to show
   */
  setLabelText(labelText: string) {
    this.state.values.labelText = labelText;
    this.name = `${this.state.values.labelText} - ${this.id}`;
  }

  /**
   * Sets the position of this label relative to the plant center.
   * @param labelOffset The relative offset from the center of the plant
   */
  setLabelOffset(labelOffset: Vector2) {
    this.state.values.labelOffset = { ...labelOffset };
  }

  /**
   * Sets if this label should be visible.
   * @param showLabel Should the label be shown
   */
  setShowLabel(showLabel: boolean) {
    this.state.values.showLabel = showLabel;
  }

  /**
   * Updtaes the background to fill the size of the text.
   */
  #updateBackground() {
    if (!this.#background || !this.#text) {
      return;
    }
    this.#background
      .clear()
      .beginFill(LABEL_BACKGROUND_COLOUR, LABEL_BACKGROUND_ALPHA)
      .drawRoundedRect(
        -this.#text.textWidth / 2 - LABEL_PADDING.x,
        -this.#text.textHeight / 2 - LABEL_PADDING.y,
        this.#text.textWidth + LABEL_PADDING.x * 2,
        this.#text.textHeight + LABEL_PADDING.y * 2,
        3
      )
      .endFill();
  }

  /**
   * Updates the internal shape of this label
   */
  #updateShape() {
    if (!this.#text) {
      return;
    }
    this.#shape.setPoints(createRectOutline(this.#text.width + LABEL_PADDING.x * 2, this.#text.height + LABEL_PADDING.y * 2));
  }

  #drawOutline: OutlineComponent['customDrawFunction'] = (graphics, state, internalState) => {
    if (!this.#text) {
      return;
    }
    const { padding, thickness } = state.values;
    const { backgroundColour, colour } = internalState.values;
    const cameraMagnification = internalState.get('camera', 'magnification', 1);

    const width = this.#text.width + LABEL_PADDING.x * 2 + padding * 2;
    const height = this.#text.height + LABEL_PADDING.y * 2 + padding * 2;
    graphics
      .clear()
      .beginFill(backgroundColour)
      .lineStyle({ width: thickness / cameraMagnification, color: colour })
      .drawRoundedRect(-width / 2, -height / 2, width, height, 3)
      .endFill();
  };
}

export default PlantLabelNode;
