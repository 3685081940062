import { Assets, BitmapFont, LoadParserName, MIPMAP_MODES, Spritesheet, Texture } from 'pixi.js-new';

import { LoadingState } from '@gi/constants';
import { networkConfig } from '@gi/config';
import { avoidImageBitmap } from '@gi/browser';
import { RequestAuthMode, networkService } from '@gi/gi-network';

import { AssetLoadCallback, AssetLoadProgressCallback, AssetNameCollisionMode, AssetType, AssetTypeMapping, AsyncAsset, DefinitionsJSON } from './types';
import type { AssetGroup } from './asset-group';
import { AssetLoadError } from './asset-load-error';

const AssetTypeClassMapping = {
  [AssetType.TEXTURE]: Texture,
  [AssetType.SPRITESHEET]: Spritesheet,
  [AssetType.BUNDLE]: Object,
  [AssetType.BITMAP_FONT]: BitmapFont,
} as const;

class AssetManager {
  #assetLoader = Assets; // AssetsClass = new AssetsClass();
  #assets: Record<string, AsyncAsset<AssetType>> = {};
  #assetLoadCallbacks: Record<string, AssetLoadCallback<AssetType>[]> = {};

  constructor() {
    this.#assetLoader.setPreferences({
      preferCreateImageBitmap: !avoidImageBitmap,
    });
  }

  /**
   *
   * @param name The name of the asset
   * @returns True if an asset with that name exists
   */
  hasAsset(name: string) {
    return this.#assets[name] !== undefined;
  }

  #onAssetLoaded<T extends AssetType>(type: T, name: string, asset: AssetTypeMapping[T]) {
    this.#assets[name] = {
      ...this.#assets[name],
      type,
      status: LoadingState.SUCCESS,
      value: asset,
    };

    if (type === AssetType.SPRITESHEET && asset instanceof Spritesheet) {
      if (this.#assets[name].mipmaps === false) {
        asset.baseTexture.mipmap = MIPMAP_MODES.OFF;
      }
    } else if (type === AssetType.TEXTURE && asset instanceof Texture) {
      if (this.#assets[name].mipmaps === false) {
        asset.baseTexture.mipmap = MIPMAP_MODES.OFF;
      }
    }

    const callbacks = this.#assetLoadCallbacks[name];
    if (callbacks) {
      callbacks.forEach((callback) => callback.onLoad(asset));
      delete this.#assetLoadCallbacks[name];
    }
  }

  #onAssetLoadError<T extends AssetType>(type: T, name: string, error: Error) {
    this.#assets[name] = {
      ...this.#assets[name],
      type,
      status: LoadingState.ERROR,
      error,
    };

    const callbacks = this.#assetLoadCallbacks[name];
    if (callbacks) {
      const ignoredCallbacks: AssetLoadCallback<AssetType>[] = [];

      callbacks.forEach((callback) => {
        if (callback.ignoreErrors) {
          ignoredCallbacks.push(callback);
        } else {
          callback.onError(error);
        }
      });

      if (ignoredCallbacks.length === 0) {
        delete this.#assetLoadCallbacks[name];
      } else {
        this.#assetLoadCallbacks[name] = ignoredCallbacks;
      }
    }
  }

  /**
   *
   * @param type The type of asset to load
   * @param name The name of the asset
   * @param url The src of the asset
   * @param assetNameCollisionMode How should assets with the same name as existing ones be handled?
   * @param onProgress Optional callback for when loading pprogress is made. Only works with bundles.
   * @returns A promise. Resolves when the asset is loaded.
   */
  loadAsset<T extends AssetType>(
    type: T,
    name: string,
    url: string,
    assetNameCollisionMode: AssetNameCollisionMode = AssetNameCollisionMode.ERROR,
    onProgress?: AssetLoadProgressCallback
  ) {
    if (type === AssetType.BUNDLE) {
      return this.loadFromDefinitionsFile(name, url, undefined, assetNameCollisionMode, onProgress);
    }

    return new Promise<AssetTypeMapping[T]>((resolve, reject) => {
      switch (assetNameCollisionMode) {
        case AssetNameCollisionMode.ERROR:
          if (this.hasAsset(name)) {
            reject(new Error(`Failed to load asset '${name}': Name already in use.`));
            return;
          }
          break;
        case AssetNameCollisionMode.SKIP:
          if (this.hasAsset(name) && this.#assets[name].status !== LoadingState.ERROR) {
            this.getAsset(type, name, { waitIfUndefined: true })
              .then((result) => resolve(result))
              .catch((e) => reject(e));
            return;
          }
          break;
        case AssetNameCollisionMode.REPLACE:
        default:
          break;
      }

      const startTime = Date.now();

      this.#assets[name] = {
        name,
        type,
        status: LoadingState.LOADING,
        src: url,
      };

      let src: string | { src: string; loadParser: LoadParserName } = url;
      if (type === AssetType.TEXTURE) {
        // Force use of the texture loader if we've been told its a texture. Fixes remote URLs with no file extension.
        src = {
          src: url,
          loadParser: 'loadTextures',
        };
      }

      this.#assetLoader
        .load(src, onProgress)
        .then((asset) => {
          if (!(asset instanceof AssetTypeClassMapping[type])) {
            throw new Error(
              `Failed to load asset '${name}': Asset is of wrong type. Expected '${AssetTypeClassMapping[type].constructor.name}', but got '${asset}`
            );
          }

          this.#onAssetLoaded(type, name, asset as AssetTypeMapping[T]);
          resolve(asset as AssetTypeMapping[T]);
        })
        .catch((error) => {
          this.#onAssetLoadError(type, name, error);
          reject(new AssetLoadError(type, name, url, error, Date.now() - startTime));
        });
    });
  }

  /**
   * Gets an asset. Done as a promise so assets that are loading work nicely.
   * @param type The type of asset to get
   * @param name The name of the asset
   * @param options Options to control how undefined/errored assets are handled
   * @returns A promise. Resolves with the desired asset when available.
   */
  getAsset<T extends AssetType>(type: T, name: string, options?: Partial<{ waitIfUndefined: boolean; ignoreErrors: boolean }>): Promise<AssetTypeMapping[T]> {
    return new Promise<AssetTypeMapping[T]>((resolve, reject) => {
      const waitForAsset = () => {
        this.#assetLoadCallbacks[name] = this.#assetLoadCallbacks[name] || [];
        this.#assetLoadCallbacks[name].push({
          onLoad: resolve,
          onError: reject,
          ignoreErrors: options?.ignoreErrors ?? false,
        } as AssetLoadCallback<T>);
      };

      const asset = this.#assets[name];

      if (asset === undefined) {
        if (options?.waitIfUndefined === true) {
          waitForAsset();
          return;
        }
        reject(new Error(`Failed to get asset '${name}': Asset does not exist.`));
        return;
      }

      if (type !== asset.type) {
        reject(new Error(`Failed to get asset '${name}': Asset is not of type '${type}'.`));
        return;
      }

      if (asset.status === LoadingState.SUCCESS) {
        resolve(asset.value as AssetTypeMapping[T]);
        return;
      }

      waitForAsset();
    });
  }

  /**
   * Adds an asset directly to the manager.
   * @param type The type of asset
   * @param name The name to use for the asset
   * @param asset The asset
   */
  addAsset<T extends AssetType>(type: T, name: string, asset: AssetTypeMapping[T]) {
    this.#assets[name] = {
      name,
      type,
      status: LoadingState.SUCCESS,
      value: asset,
    };

    const callbacks = this.#assetLoadCallbacks[name];
    if (callbacks) {
      callbacks.forEach((callback) => callback.onLoad(asset));
      delete this.#assetLoadCallbacks[name];
    }
  }

  /**
   * Destroys an asset, freeing up the asset name and memory.
   * @param name The name of the asset to destroy
   */
  destroyAsset(name: string) {
    const asset = this.#assets[name];

    if (asset === undefined) {
      throw new Error(`Failed to unload asset '${name}': Asset does not exist.`);
    }

    if (asset.status !== LoadingState.SUCCESS) {
      throw new Error(`Failed to unload asset '${name}': Asset has not finished loading.`);
    }

    switch (asset.type) {
      case AssetType.TEXTURE: {
        (asset.value as AssetTypeMapping[AssetType.TEXTURE]).destroy();
        break;
      }
      case AssetType.SPRITESHEET: {
        (asset.value as AssetTypeMapping[AssetType.SPRITESHEET]).destroy();
        break;
      }
      default: {
        // Do nothing
      }
    }

    delete this.#assets[name];
  }

  /**
   * Loads a bundle of assets from a URL. Used to load the texture definition fles for the garden planner.
   * @param bundleName The name to use for the bundle
   * @param url The src of he bundle
   * @param textureBaseUrl Optionally overwite the URL used for sub-assets.
   * @param assetNameCollisionMode How should assets with the same name as existing ones be handled?
   * @param onProgress Optional callback for when progress is made
   * @returns
   */
  loadFromDefinitionsFile(
    bundleName: string,
    url: string,
    textureBaseUrl: string = networkConfig.textureBaseURL,
    assetNameCollisionMode: AssetNameCollisionMode = AssetNameCollisionMode.ERROR,
    onProgress?: AssetLoadProgressCallback
  ) {
    return new Promise((resolve, reject) => {
      switch (assetNameCollisionMode) {
        case AssetNameCollisionMode.ERROR:
          if (this.hasAsset(bundleName)) {
            reject(new Error(`Failed to load bundle '${bundleName}': Name already in use.`));
            return;
          }
          break;
        case AssetNameCollisionMode.SKIP:
          if (this.hasAsset(bundleName) && this.#assets[bundleName].status !== LoadingState.ERROR) {
            this.getAsset(AssetType.BUNDLE, bundleName, { waitIfUndefined: true })
              .then((result) => resolve(result))
              .catch((e) => reject(e));
            return;
          }
          break;
        case AssetNameCollisionMode.REPLACE:
        default:
          break;
      }

      this.#assets[bundleName] = {
        name: bundleName,
        type: AssetType.BUNDLE,
        status: LoadingState.LOADING,
        src: url,
      };

      networkService
        .get<DefinitionsJSON>(url, {}, RequestAuthMode.None, { timeout: 5 * 60 * 1000 }) // 5m timeout
        .then(async (response) => {
          const json = response.body;
          const assetNames: string[] = [];

          // Store references to all the new textures
          Object.keys(json.textures).forEach((textureName) => {
            const textureDef = json.textures[textureName];

            // Direct images will also be defined by the resources list, so do that then.
            if (textureDef.type === 'image') {
              return;
            }

            this.#assets[textureDef.name] = {
              name: textureDef.name,
              type: AssetType.TEXTURE,
              status: LoadingState.LOADING,
            };
            assetNames.push(textureDef.name);
          });

          // Textures is the list of textures the resources will produce
          const resourcesToLoad: Record<string, string> = {};
          json.resources.forEach((resourceDef) => {
            if (resourceDef.type !== 'image' && resourceDef.type !== 'atlas') {
              return;
            }

            resourcesToLoad[resourceDef.name] = `${textureBaseUrl}${resourceDef.location}`;

            this.#assets[resourceDef.name] = {
              name: resourceDef.name,
              type: resourceDef.type === 'image' ? AssetType.TEXTURE : AssetType.SPRITESHEET,
              status: LoadingState.LOADING,
              mipmaps: resourceDef.mipmaps,
            };
            assetNames.push(resourceDef.name);
          });

          const startAt = Date.now();

          this.#assetLoader.addBundle(bundleName, resourcesToLoad);
          this.#assetLoader
            .loadBundle(bundleName, onProgress)
            .then((assets) => {
              assetNames.forEach((assetName) => {
                const asset = this.#assets[assetName];
                this.#onAssetLoaded(asset.type, assetName, this.#assetLoader.get(assetName));
              });
              this.#onAssetLoaded(AssetType.BUNDLE, bundleName, assets);
              resolve(assets);
            })
            .catch((error) => {
              assetNames.forEach((assetName) => {
                const asset = this.#assets[assetName];
                this.#onAssetLoadError(asset.type, assetName, error);
              });
              this.#onAssetLoadError(AssetType.BUNDLE, bundleName, error);
              reject(new AssetLoadError(AssetType.BUNDLE, bundleName, url, error, Date.now() - startAt));
            });
        })
        .catch((error) => {
          this.#onAssetLoadError(AssetType.BUNDLE, bundleName, error);
          reject(error);
        });
    });
  }

  loadGroup(group: AssetGroup, assetNameCollisionMode: AssetNameCollisionMode = AssetNameCollisionMode.ERROR) {
    return group._load(this, assetNameCollisionMode);
  }
}

export default AssetManager;
