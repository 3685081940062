import React, { useContext } from 'react';
import { TooltipKey } from '@gi/tooltip';

import { PlannerCommandContext } from '../../../planner-command-provider';
import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';
import ControlButtonGroup from '../common/control-button-group';
import MenuButton from '../common/menu-button';
import MenuIcon from '../common/menu-icon';

const EditControls = (): JSX.Element => {
  const { undo, redo, selectionInProgress, startSelect, cancelSelect, edit, cut, copy, paste, deleteNodes, zoomIn, zoomOut } =
    useContext(PlannerCommandContext);

  return (
    <ControlGroup>
      <ControlRow>
        <ControlButtonGroup>
          <MenuButton
            id='select'
            disabled={!startSelect && !cancelSelect}
            active={selectionInProgress}
            onClick={() => {
              if (startSelect) {
                startSelect();
              } else if (cancelSelect) {
                cancelSelect();
              }
            }}
            tooltipKey={TooltipKey.SelectionTool}
          >
            <MenuIcon icon='icon-selection-box' />
          </MenuButton>
          <MenuButton
            id='edit'
            disabled={!edit}
            onClick={() => {
              if (edit) edit();
            }}
            tooltipKey={TooltipKey.EditItem}
          >
            <MenuIcon icon='icon-edit' />
          </MenuButton>
        </ControlButtonGroup>
        <ControlButtonGroup>
          <MenuButton
            id='undo'
            disabled={undo === null}
            onClick={() => {
              if (undo) undo();
            }}
            tooltipKey={TooltipKey.Undo}
          >
            <MenuIcon icon='icon-ccw' />
          </MenuButton>
          <MenuButton
            id='redo'
            disabled={redo === null}
            onClick={() => {
              if (redo) redo();
            }}
            tooltipKey={TooltipKey.Redo}
          >
            <MenuIcon icon='icon-cw' />
          </MenuButton>
        </ControlButtonGroup>
        <ControlButtonGroup>
          <MenuButton
            id='cut'
            disabled={!cut}
            onClick={() => {
              if (cut) cut();
            }}
            tooltipKey={TooltipKey.Cut}
          >
            <MenuIcon icon='icon-scissors' />
          </MenuButton>
          <MenuButton
            id='copy'
            disabled={!copy}
            onClick={() => {
              if (copy) copy();
            }}
            tooltipKey={TooltipKey.Copy}
          >
            <MenuIcon icon='icon-docs' />
          </MenuButton>
          <MenuButton
            id='paste'
            disabled={!paste}
            onClick={() => {
              if (paste) paste();
            }}
            tooltipKey={TooltipKey.Paste}
          >
            <MenuIcon icon='icon-paste' />
          </MenuButton>
        </ControlButtonGroup>
        <ControlButtonGroup>
          <MenuButton
            id='delete'
            disabled={!deleteNodes}
            onClick={() => {
              if (deleteNodes) deleteNodes();
            }}
            tooltipKey={TooltipKey.Delete}
          >
            <MenuIcon icon='icon-trash' />
          </MenuButton>
        </ControlButtonGroup>
        <ControlButtonGroup>
          <MenuButton
            id='zoom-in'
            disabled={!zoomIn}
            onClick={() => {
              if (zoomIn) zoomIn();
            }}
            tooltipKey={TooltipKey.ZoomIn}
          >
            <MenuIcon icon='icon-zoom-in' />
          </MenuButton>
          <MenuButton
            id='zoom-out'
            disabled={!zoomOut}
            onClick={() => {
              if (zoomOut) zoomOut();
            }}
            tooltipKey={TooltipKey.ZoomOut}
          >
            <MenuIcon icon='icon-zoom-out' />
          </MenuButton>
        </ControlButtonGroup>
      </ControlRow>
    </ControlGroup>
  );
};

export default EditControls;
