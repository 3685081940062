import React, { useContext, useState } from 'react';
import { ResourceContext } from '@gi/resource-provider';
import { unique } from '@gi/utils';
import { FormSection } from '@gi/form-responsive';
import { CompanionIcon } from '@gi/plant-icon';
import PlannerIcon from '@gi/planner-icon';

import { EditPlantContext } from '../context/edit-plant-context';
import CompanionPlant from './companion-plant';

const PlanPlantCompanions = (): JSX.Element | null => {
  const { plant } = useContext(EditPlantContext);
  const { userPlants } = useContext(ResourceContext);

  if (userPlants === null) {
    return null;
  }

  const [expandCompanions, setExpandCompanions] = useState(false);
  let companions = plant.companionPlantCodes
    .filter(unique)
    .map((plantCode) => userPlants.get(plantCode))
    .filter((companionPlant) => companionPlant !== null)
    .sort((plantA, plantB) => plantA!.name.localeCompare(plantB!.name))
    .map((companionPlant) => <CompanionPlant key={companionPlant!.code} plant={companionPlant!} />);

  const showExtend = companions.length > 3 && !expandCompanions;
  const showContract = companions.length > 3 && expandCompanions;

  const extraCompanionCount = companions.length - 3;

  if (!expandCompanions) {
    companions = companions.slice(0, 3);
  }

  if (companions.length === 0) {
    return null;
  }

  return (
    <FormSection
      padding={12}
      gap={6}
      className='form-section-background'
      heading={
        <>
          Companions
          <CompanionIcon isHelper caption={`Companion Planting: These plants help ${plant.name}`} />
          <PlannerIcon code={plant.code} className='plant-icon companions-section-planticon' halfSize />
        </>
      }
    >
      <div>{companions}</div>
      {showExtend ? (
        <button
          type='button'
          className='button button-inline'
          onClick={() => {
            setExpandCompanions(true);
          }}
        >
          Show All Companions (+{extraCompanionCount})
        </button>
      ) : null}
      {showContract ? (
        <button
          type='button'
          className='button button-inline'
          onClick={() => {
            setExpandCompanions(false);
          }}
        >
          Show Fewer Companions
        </button>
      ) : null}
    </FormSection>
  );
};

export default PlanPlantCompanions;
