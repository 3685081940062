import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ShapeType } from '@gi/constants';
import { SwatchTypes } from '@gi/palette';
import { DistanceUnits } from '@gi/units';
import { Geometry, MathUtils } from '@gi/math';
import { PlanShape, PlanValidation } from '@gi/plan';
import { FormValues, createFormValues } from '@gi/form-responsive';

import {
  ModalPane,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalHeaderSubtitle,
  AttemptToLeaveCallback,
  ConfirmLeaveContextResult,
  ModalContext,
  ModalPaneContent,
} from '@gi/modal';

import { ShapeState } from './types';
import EditShapeModalFormLine from './forms/edit-shape-modal-form-line';
import EditShapeModalFormTriangle from './forms/edit-shape-modal-form-triangle';
import EditShapeModalFormRectangle from './forms/edit-shape-modal-form-rectangle';
import { getSelectedSwatchFromShape } from './utils';

const shapeTypeNames = {
  [ShapeType.RECTANGLE]: 'Rectangle',
  [ShapeType.ELLIPSE]: 'Ellipse',
  [ShapeType.TRIANGLE]: 'Triangle',
  [ShapeType.LINE]: 'Line',
};

interface iProps {
  planShape: PlanShape;
  distanceUnits: DistanceUnits;
  onComplete: (planShape: PlanShape) => void;
}

const EditShapeModalContent = ({ planShape, distanceUnits, onComplete }: iProps): JSX.Element => {
  const { setCloseContextParams, attemptClose, close } = useContext(ModalContext);

  const [formValues, setFormValues] = useState<FormValues<ShapeState>>(
    createFormValues({
      point1: { value: planShape.point1 },
      point2: { value: planShape.point2 },
      point3: { value: planShape.point3 },
      center: { value: Geometry.midpoint(planShape.point1, planShape.point3) },
      curved: { value: planShape.point2 !== null },
      width: { value: Math.abs(planShape.point3.x - planShape.point1.x) },
      height: { value: Math.abs(planShape.point3.y - planShape.point1.y) },
      rotation: { value: Math.round(planShape.rotation * (180 / Math.PI)) },
      filled: { value: planShape.fill !== null },
      strokeWidth: { value: planShape.strokeWidth },
      selectedSwatch: { value: getSelectedSwatchFromShape(planShape) },
    })
  );

  /**
   * Submits the changes back outside the modal
   */
  const onSaveChanges = useCallback(() => {
    const validatedData = PlanValidation.validateShape(
      planShape.type,
      formValues.values.point1,
      formValues.values.point2,
      formValues.values.point3,
      formValues.values.center,
      MathUtils.degToRad(formValues.values.rotation),
      formValues.values.width,
      formValues.values.height,
      formValues.values.curved,
      formValues.values.filled,
      formValues.values.selectedSwatch.type === SwatchTypes.COLOR ? parseInt(formValues.values.selectedSwatch.value, 16) : 0x000000,
      formValues.values.selectedSwatch.type === SwatchTypes.IMAGE && formValues.values.filled ? formValues.values.selectedSwatch.value : null,
      formValues.values.strokeWidth
    );

    const updatedPlanShape = {
      ...planShape,
      point1: validatedData.point1,
      point2: validatedData.point2,
      point3: validatedData.point3,
      rotation: validatedData.rotation,
      fill: validatedData.fill,
      texture: validatedData.texture,
      stroke: validatedData.stroke,
      strokeWidth: validatedData.strokeWidth,
    };

    onComplete(updatedPlanShape);
  }, [onComplete, formValues, planShape]);

  /**
   * Prevent navigating away with unsaved changes
   */
  useEffect(() => {
    const createLeaveCallback = (): AttemptToLeaveCallback => {
      return (cb) => {
        return cb(formValues.hasDifferences).then((result) => {
          if (result === ConfirmLeaveContextResult.SaveAndClose) {
            onSaveChanges();
          }

          return result !== ConfirmLeaveContextResult.Cancel;
        });
      };
    };

    const invalidMessage = formValues.isValid ? undefined : 'Some inputs have invalid values, please correct them before saving';

    setCloseContextParams({
      title: 'Save Changes?',
      text: 'Do you wish to save your changes to this Shape?',
      callback: createLeaveCallback(),
      invalidMessage,
    });

    return () => {
      setCloseContextParams(null);
    };
  }, [formValues.hasDifferences, formValues.isValid, onSaveChanges, setCloseContextParams]);

  const formContent = useMemo(() => {
    let FormContent = EditShapeModalFormRectangle;
    switch (planShape.type) {
      case ShapeType.RECTANGLE:
      case ShapeType.ELLIPSE:
        FormContent = EditShapeModalFormRectangle;
        break;
      case ShapeType.TRIANGLE:
        FormContent = EditShapeModalFormTriangle;
        break;
      case ShapeType.LINE:
        FormContent = EditShapeModalFormLine;
        break;
      default:
        console.error(`Unknown shape type: ${planShape.type}`);
    }
    return <FormContent values={formValues} setValues={setFormValues} distanceUnits={distanceUnits} />;
  }, [formValues, setFormValues, distanceUnits, planShape]);

  return (
    <ModalContent>
      <ModalCloseButton onClick={attemptClose} />
      <ModalHeader>
        <ModalHeaderContent>
          <ModalHeaderTitle>Edit Shape</ModalHeaderTitle>
          <ModalHeaderSubtitle>{shapeTypeNames[planShape.type]}</ModalHeaderSubtitle>
        </ModalHeaderContent>
      </ModalHeader>
      <ModalPaneContainer>
        <ModalPane>
          <ModalPaneContent>{formContent}</ModalPaneContent>
        </ModalPane>
      </ModalPaneContainer>
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-secondary' onClick={close}>
              Cancel
            </button>
          </ModalFooterButtonsSection>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-primary' onClick={onSaveChanges}>
              Done
            </button>
          </ModalFooterButtonsSection>
        </ModalFooterButtons>
      </ModalFooter>
    </ModalContent>
  );
};

export default EditShapeModalContent;
