/**
 * Enum for the different types of source types
 */
export enum SpriteSourceType {
  NINE_SLICE = 'NINE_SLICE',
  SINGLE = 'SINGLE',
  PATH = 'PATH',
}

/**
 * Enum for the different nine slice sprite
 */
export enum NineSliceSpriteLocation {
  M = 'M',
  B = 'B',
  BL = 'BL',
  BR = 'BR',
  L = 'L',
  R = 'R',
  T = 'T',
  TL = 'TL',
  TR = 'TR',
}

export const NineSliceSpriteLocationList = [
  NineSliceSpriteLocation.M,
  NineSliceSpriteLocation.B,
  NineSliceSpriteLocation.BL,
  NineSliceSpriteLocation.BR,
  NineSliceSpriteLocation.L,
  NineSliceSpriteLocation.R,
  NineSliceSpriteLocation.T,
  NineSliceSpriteLocation.TL,
  NineSliceSpriteLocation.TR,
] as const;
