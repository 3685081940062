import React from 'react';
import DashboardItem from '../../dashboard-item/dashboard-item';
import DashboardItemSection from '../../dashboard-item/dashboard-item-section';
import DashboardItemSectionTitle from '../../dashboard-item/dashboard-item-section-title';
import GetStartedSection from './get-started/get-started-section';
import UpcomingRemindersSection from './upcoming-reminders/upcoming-reminders-section';

const JournalDashboardItem = (): JSX.Element => {
  return (
    <DashboardItem title='Garden Journal'>
      <DashboardItemSection className='col-3'>
        <DashboardItemSectionTitle>Track Your Garden</DashboardItemSectionTitle>
        <GetStartedSection />
      </DashboardItemSection>
      <DashboardItemSection className='col-3 col-span-2'>
        <UpcomingRemindersSection />
      </DashboardItemSection>
    </DashboardItem>
  );
};

export default JournalDashboardItem;
