import React from 'react';

import styles from './small-preview-tag.module.css';

interface iProps {
  text: string;
  color: string;
}

const SmallPreviewTag = ({ text, color }: iProps): JSX.Element => {
  return (
    <div className={styles.smallPreviewTag}>
      <div className={styles.typeIndicator} style={{ backgroundColor: color }} />
      <div className={styles.typeIndicatorText}>{text}</div>
    </div>
  );
};

export default SmallPreviewTag;
