import React, { useContext, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import type { Height } from 'react-animate-height';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import ResizeObserverPolyfill from '@gi/resize-observer-ponyfill';

import NavigationBar from './navigation/navigation-bar';
import HeaderBackground from './header-components/header-background';
import GardenGuruContext from '../context/garden-guru-context';

import styles from './guru-header.module.css';
import headerAnimationStyles from './header-animation.module.css';

const GuruHeader = (): JSX.Element => {
  const { header } = useContext(GardenGuruContext);
  const [height, setHeight] = useState<Height>('auto');

  const animateHeightRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animateHeightRef.current) {
      const resizeObserver = new ResizeObserverPolyfill(() => {
        if (animateHeightRef.current) {
          setHeight(animateHeightRef.current.clientHeight);
        }
      });

      resizeObserver.observe(animateHeightRef.current);

      return () => resizeObserver.disconnect();
    }

    return () => {};
  }, []);

  return (
    <div className={styles.headerInner}>
      <HeaderBackground />
      <div className={styles.navigationContainer}>
        <NavigationBar />
      </div>
      <AnimateHeight height={height} className={styles.headerSizeWrapper} contentClassName={styles.headerOuter} contentRef={animateHeightRef}>
        {header.content === null ? null : (
          <div className={styles.headerContentContainer}>
            <SwitchTransition mode='out-in'>
              <CSSTransition
                key={header.id}
                timeout={500}
                classNames={{
                  enter: headerAnimationStyles.transitionEnter,
                  enterActive: headerAnimationStyles.transitionEnterActive,
                  exit: headerAnimationStyles.transitionExit,
                  exitActive: headerAnimationStyles.transitionExitActive,
                }}
              >
                <div key={header.id} className={`${styles.transitionContainer} ${headerAnimationStyles.transition}`}>
                  {header.content}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        )}
      </AnimateHeight>
    </div>
  );
};

export default GuruHeader;
