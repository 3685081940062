import { Geometry } from '@gi/math';
import { ClipperOffset, JoinType, EndType, JS } from 'clipper-lib';

// All clipper values are integers so we need to correctly scale everything up so no data is lost.
const SCALE_FACTOR = 1000;
const clipperOffset = new ClipperOffset(4.0, 0.25);

export const createOutlinePath = (path: Vector2[], offset: number = 20, closedPolygon: boolean = true) => {
  // ClipperLib offset documentation
  // https://sourceforge.net/p/jsclipper/wiki/documentation/#clipperlibclipperoffset

  const inputPath = path.map((p) => {
    return {
      X: p.x,
      Y: p.y,
    };
  });

  if (offset === 0) {
    return Geometry.convertClipperPointPathToIntArray(inputPath);
  }

  JS.ScaleUpPath(inputPath, SCALE_FACTOR);

  clipperOffset.Clear();
  if (closedPolygon) {
    clipperOffset.AddPath(inputPath, JoinType.jtMiter, EndType.etClosedPolygon);
  } else {
    clipperOffset.AddPath(inputPath, JoinType.jtMiter, EndType.etOpenButt);
  }

  const result = [];

  clipperOffset.Execute(result, offset * SCALE_FACTOR);
  JS.ScaleDownPaths(result, SCALE_FACTOR);

  // Geometry.convertClipperPointPathToIntArray(result);
  const actualResult = Geometry.convertClipperPointPathToIntArray(result.length > 0 ? result[0] : []);

  return actualResult;
};
