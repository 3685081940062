import React from 'react';
import { PropTypes } from 'prop-types';
import moment from 'moment-timezone';

import { sortByID } from '@gi/utils';
import { UserShape } from '@gi/user';
import JournalEntry, { JournalActionShapes } from '@gi/journal-days';

import JournalPost from './journal-post';
import { ActionSummary, ACTION_TYPES } from './action-summary';

import './journal.scss';

const JournalDay = (props) => {
  const mo = moment(props.dateYMD);

  return (
    <div key={props.dateYMD} className='journal-day'>
      <div className='journal-day-header'>
        <div className='journal-which-day'>
          {mo.format('dddd')} <strong>{mo.format('MMMM Do')}</strong>
        </div>
      </div>
      <div className='action-summaries'>
        {Object.keys(ACTION_TYPES).map((actionType) => {
          const actionsForThisType = props.journalActions.filter((action) => action.actionType === actionType);
          return <ActionSummary user={props.user} key={actionType} actionType={actionType} actions={actionsForThisType} />;
        })}
      </div>
      <div className='journal-entries'>
        {props.journalEntries.sort(sortByID).map((entry) => (
          <JournalPost key={entry} journalEntry={entry} />
        ))}
      </div>
    </div>
  );
};

JournalDay.propTypes = {
  user: UserShape.isRequired,
  dateYMD: PropTypes.string.isRequired,
  journalActions: JournalActionShapes.JournalActionsShape.isRequired,
  journalEntries: PropTypes.arrayOf(PropTypes.instanceOf(JournalEntry)).isRequired,
};

export default JournalDay;
