import { ShapeType } from '@gi/constants';
import { GardenItemType } from '@gi/constants/types/garden-item-type';
import type { Plan } from './plan';
import { getFreshPlanItemId, getUpdatedZIndexLimits } from './plan-item-utils';

export type PlanShape = {
  id: number;
  type: ShapeType;
  point1: Vector2;
  point2: Vector2 | null;
  point3: Vector2;
  rotation: number;
  fill: number | null;
  texture: string | null;
  stroke: number | null;
  strokeWidth: number;
  closed: boolean;
  locked: boolean;
  zIndex: number;
};

export function createNewPlanShape(
  type: ShapeType,
  point1: Vector2,
  point2: Vector2 | null,
  point3: Vector2,
  rotation: number,
  fill: number | null,
  texture: string | null,
  stroke: number | null,
  strokeWidth: number,
  closed: boolean,
  locked: boolean,
  zIndex: number,
  id: number = -1
): PlanShape {
  return {
    id,
    type,
    point1,
    point2,
    point3,
    rotation,
    fill,
    texture,
    stroke,
    strokeWidth,
    closed,
    locked,
    zIndex,
  };
}

/**
 * Immutably adds a shape to a plan and returns the updated plan
 */
export function addShapeToPlan(plan: Plan, planShape: PlanShape): Plan {
  if (plan.itemTypes[planShape.id]) {
    throw new Error('Attempting to add shape to plan which already has an item with that id');
  }

  const [planWithUpdatedMaxItemId, validatedId] = getFreshPlanItemId(plan, planShape.id);

  const updatedShape = {
    ...planShape,
    id: validatedId,
  };

  const updatedPlan: Plan = {
    ...planWithUpdatedMaxItemId,
    ...getUpdatedZIndexLimits(plan, planShape.zIndex),
    itemTypes: {
      ...planWithUpdatedMaxItemId.itemTypes,
      [validatedId]: GardenItemType.Shape,
    },
    shapes: {
      ...planWithUpdatedMaxItemId.shapes,
      [validatedId]: updatedShape,
    },
    shapeIds: [...planWithUpdatedMaxItemId.shapeIds, validatedId],
  };

  return updatedPlan;
}

/**
 * Immutably removes a shape from a plan and returns the updated plan
 */
export function removeShapeFromPlan(plan: Plan, planShapeId: number): Plan {
  if (!plan.itemTypes[planShapeId] || !plan.shapes[planShapeId]) {
    console.error("Attempted to remove shape from plan which doesn't exist", planShapeId);
    return plan;
  }

  const newItemTypes = { ...plan.itemTypes };
  delete newItemTypes[planShapeId];

  const newShapes = { ...plan.shapes };
  delete newShapes[planShapeId];

  const newShapeIds = [...plan.shapeIds];
  newShapeIds.splice(newShapeIds.indexOf(planShapeId), 1);

  const updatedPlan: Plan = {
    ...plan,
    itemTypes: newItemTypes,
    shapes: newShapes,
    shapeIds: newShapeIds,
  };

  return updatedPlan;
}

export function updatePlanShape(plan: Plan, planShape: PlanShape): Plan {
  if (!plan.itemTypes[planShape.id] || plan.itemTypes[planShape.id] !== GardenItemType.Shape) {
    throw new Error("Attempting to updated a shape in a plan which doesn't have that shape already");
  }

  return {
    ...plan,
    ...getUpdatedZIndexLimits(plan, planShape.zIndex),
    shapes: {
      ...plan.shapes,
      [planShape.id]: planShape,
    },
  };
}
