import React, { useMemo, useState } from 'react';

import GlobalPlantListContext, { GlobalPlantListContextType } from './global-plant-list-context';
import { PlantListOptions, PlantListColumns, TableViewMode } from '../types/plant-list-types';
import { DISPLAY_TYPE } from '../constants';
import INITIAL_SUMMARY_FILTERS from '../filters/summary-group-filters/summary-group-filters';
import INITIAL_VARIETY_FILTERS from '../filters/variety-group-filters/variety-group-filters';
import INITIAL_AREA_FILTERS from '../filters/area-group-filters/area-group-filters';

interface iProps {
  children: React.ReactNode;
}

const DEFAULT_OPTIONS: PlantListOptions = {
  textPlantingCalendars: false,
  showScientificName: false,
  expandPlantAreas: false,
  showPlantFamily: true,
  printBackgroundColors: false,
  viewMode: TableViewMode.AUTO,
};

const DEFAULT_COLUMNS: PlantListColumns = {
  area: true,
  calendar: true,
  counts: true,
  notes: true,
  plantLabel: true,
  spacings: true,
  inGroundDates: true,
};

const GlobalPlantListProvider = ({ children }: iProps): JSX.Element => {
  const [expandFilters, setExpandFilters] = useState<boolean>(false);
  const [displayType, setDisplayType] = useState<DISPLAY_TYPE>(DISPLAY_TYPE.Variety);
  const [options, setOptions] = useState<PlantListOptions>(DEFAULT_OPTIONS);

  const [plantGroupFilters, _setPlantGroupFilters] = useState(INITIAL_SUMMARY_FILTERS);
  const [varietyGroupFilters, _setVarietyGroupFilters] = useState(INITIAL_VARIETY_FILTERS);
  const [areaGroupFilters, _setAreaGroupFilters] = useState(INITIAL_AREA_FILTERS);

  const [columns, setColumns] = useState<PlantListColumns>(DEFAULT_COLUMNS);

  const resetFilters = () => {
    _setPlantGroupFilters(INITIAL_SUMMARY_FILTERS);
    _setVarietyGroupFilters(INITIAL_VARIETY_FILTERS);
    _setAreaGroupFilters(INITIAL_AREA_FILTERS);
    setOptions(DEFAULT_OPTIONS);
    setDisplayType(DISPLAY_TYPE.Variety);
    setColumns(DEFAULT_COLUMNS);
  };

  function setOptionsPartial(opts: Partial<PlantListOptions>): void {
    setOptions({
      ...options,
      ...opts,
    });
  }

  const setColumnVisibility = (key: keyof PlantListColumns, value: boolean) => {
    setColumns({
      ...columns,
      [key]: value,
    });
  };

  const value = useMemo<GlobalPlantListContextType>(() => {
    return {
      expandFilters,
      setExpandFilters,
      columns,
      setColumnVisibility,
      displayType,
      setDisplayType,
      options,
      setOptions: setOptionsPartial,
      plantGroupFilters,
      setPlantGroupFilters: _setPlantGroupFilters,
      varietyGroupFilters,
      setVarietyGroupFilters: _setVarietyGroupFilters,
      areaGroupFilters,
      setAreaGroupFilters: _setAreaGroupFilters,
      resetFilters,
    };
  }, [expandFilters, displayType, columns, options, plantGroupFilters, varietyGroupFilters, areaGroupFilters]);

  return <GlobalPlantListContext.Provider value={value}>{children}</GlobalPlantListContext.Provider>;
};

export default GlobalPlantListProvider;
