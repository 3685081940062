import React from 'react';
import { Checkbox } from '@gi/base-components';

import ShoppingListButton from '../buttons/shopping-list-button';

import styles from './cart-details.module.css';
import { formatPrice } from '../../../utils';
import ListItemDetailsColumn from '../list-item-details-column';

interface iProps {
  inCart: boolean;
  setInCart: (inCart: boolean) => void;
  quantity: number;
  price: number;
  currency: string;
}

const CartDetails = ({ inCart, setInCart, quantity, price, currency }: iProps): JSX.Element => {
  const buttonText = inCart ? '"In Cart"' : '"Add"';
  const buttonHoverText = inCart ? '"Remove"' : '"Add"';

  const style = {
    '--button-text': buttonText,
    '--button-hover-text': buttonHoverText,
  } as React.CSSProperties;

  return (
    <ListItemDetailsColumn className={styles.cartDetails} style={style}>
      <div className={styles.cartDetailsPrice}>
        <div className={styles.priceDetails}>
          {quantity} &times; {formatPrice(price, currency)}
        </div>
        <div className={styles.priceTotal}>{formatPrice(quantity * price, currency)}</div>
      </div>
      <ShoppingListButton
        className={`${styles.cartDetailsButton} ${inCart ? styles.checkedCartDetailsButton : ''}`}
        type='button'
        onClick={() => setInCart(!inCart)}
      >
        <span className={styles.buttonText} />
        <Checkbox checked={inCart} boxColor='#56b965' />
      </ShoppingListButton>
    </ListItemDetailsColumn>
  );
};

export default CartDetails;
