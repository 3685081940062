import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import SupportForm from '@gi/support-form';
import { AppContext } from '@gi/app-provider';
import { PageSection, PageSectionTitle } from '@gi/page-layout';
import { networkConfig } from '@gi/config';
import { IntercomActionCreators } from '@gi/intercom';
import { useViewLinkEventHook } from '@gi/garden-platform-events';

const SupportSupport = (): JSX.Element => {
  const dispatch = useDispatch();
  const { runtimeConfig } = useContext(AppContext);

  const sendLinkViewedEvent = useViewLinkEventHook('app-help-support-page');

  const openIntercom = () => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('help-area'));
  };

  return (
    <>
      <PageSection>
        <PageSectionTitle>
          <h2>Support</h2>
        </PageSectionTitle>
        <p>
          To get an answer to your question quickly please use our{' '}
          <button type='button' onClick={openIntercom} className='button button-inline intercom-button'>
            Live Chat
          </button>
        </p>
        <p>
          The Garden Planner & Garden Journal FAQ and help pages can be found{' '}
          <a href={networkConfig.helpURL} target='_blank' rel='noreferrer' onClick={() => sendLinkViewedEvent('help-pages')}>
            here
          </a>
          . If you are unable to find an answer to your question there, please fill in the form below - we aim to respond within 24 hours.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionTitle>
          <h2>Contact Support</h2>
        </PageSectionTitle>
        <SupportForm formID='Help Page' />
      </PageSection>
      <PageSection>
        You can also contact us through email:{' '}
        <a href={`mailto:${runtimeConfig.supportEmail}`} target='_blank' rel='noreferrer' onClick={() => sendLinkViewedEvent('mailto-support')}>
          {runtimeConfig.supportEmail}
        </a>
      </PageSection>
    </>
  );
};

export default SupportSupport;
