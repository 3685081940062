import React from 'react';

import { DefaultMobileTopbar } from '@gi/mobile-garden-platform-nav';
import AppDashboardDesktop from '@gi/app-dashboard-desktop';

const MobileDashboard = (): JSX.Element => {
  return (
    <>
      <DefaultMobileTopbar title='Overview' />
      <AppDashboardDesktop />
    </>
  );
};

export default MobileDashboard;
