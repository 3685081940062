import React from 'react';
import ReactSelect from 'react-select';

import { ModalPaneSection, ModalPaneSectionHeader } from '@gi/modal';
import FormField, { FORM_FIELD_PRESETS, FormLayout, FormValuesField, unresponsivePreset } from '@gi/form-responsive';
import { MAX_CURSOR_SCALE, MIN_CURSOR_SCALE } from '@gi/constants';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

interface CursorScaleOption {
  value: number;
  label: string;
}

const cursorScaleOptions: CursorScaleOption[] = [];
for (let i: number = MIN_CURSOR_SCALE; i <= MAX_CURSOR_SCALE; i++) {
  cursorScaleOptions.push({
    value: i,
    label: `${i}`,
  });
}

interface iProps {
  useCustomCursor: FormValuesField<boolean>;
  customCursorScale: FormValuesField<number>;
  setUseCustomCursor: (enabled: boolean) => void;
  setCustomCursorScale: (scale: number) => void;
}

const CursorSettings = ({ useCustomCursor, customCursorScale, setUseCustomCursor, setCustomCursorScale }: iProps) => {
  return (
    <ModalPaneSection className='modal-form-section'>
      <ModalPaneSectionHeader>Cursor</ModalPaneSectionHeader>
      <FormLayout layoutPreset={unresponsivePreset({ layout: 'column', gap: 12 })}>
        <FormField
          label='Use custom cursors'
          helpText='Custom cursors appear when drawing on the Garden Planner, and display the item you are drawing next to your cursor.'
          htmlFor='cursor-enable'
          layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
        >
          <input type='checkbox' id='cursor-enable' checked={useCustomCursor.value} onChange={(e) => setUseCustomCursor(e.target.checked)} />
        </FormField>
        <FormField label='Custom Cursor Scale' desktopLayout={{ labelSize: 'auto' }} htmlFor='cursor-scale'>
          <ReactSelect<CursorScaleOption>
            options={cursorScaleOptions}
            inputId='cursor-scale'
            value={cursorScaleOptions.find((option) => option.value === customCursorScale.value)}
            onChange={(scale) => setCustomCursorScale(scale?.value ?? 1)}
            styles={DEFAULT_SELECT_STYLES}
            isSearchable={false}
          />
        </FormField>
      </FormLayout>
    </ModalPaneSection>
  );
};

export default CursorSettings;
