import React, { ReactNode } from 'react';

import styles from './help-modal-action.module.css';

interface iProps {
  children?: ReactNode;
}

/** Highlights a piece of text as if it was an action the user should do */
const HelpModalAction = ({ children }: iProps): JSX.Element => {
  return <span className={styles.action}>{children}</span>;
};

export default HelpModalAction;
