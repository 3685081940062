import React from 'react';

import { HelpModal, ModalPaneSection } from '@gi/modal';

interface iProps {
  closeModal: () => void;
}

const AutumnPlantingFilterHelpModal = ({ closeModal }: iProps): JSX.Element => {
  return (
    <HelpModal headerText='Suitable for Autumn Planting' closeModal={closeModal} style={{ maxWidth: 520 }}>
      <ModalPaneSection>
        <p>
          The &ldquo;Suitable for Autumn Planting&rdquo; filter displays plants that will grow well with decreasing day length and temperature, given the right
          climate and growing conditions. These plants may not necessarily be plantable in Autumn in your area.
        </p>
        <p>
          To view plants suitable for planting in Autumn in your area, use the <strong>&ldquo;Planting Times - Can plant in&rdquo;</strong> dropdown filter and
          select a month. However, keep in mind that using season extenders like a cold frame or greenhouse may enable you to grow a wider range of plants than
          the month filter shows.
        </p>
      </ModalPaneSection>
    </HelpModal>
  );
};

export default AutumnPlantingFilterHelpModal;
