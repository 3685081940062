import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CanvasSelectors } from '@gi/react-garden-canvas';

import OpenPlanListItem from './open-plan-list-item';
import { PlanWithStatus, usePlanStatusGetter } from '../../utils/use-plan-status';

import styles from './open-plan-list.module.css';

interface iProps {
  closeMenu: () => void;
}

const OpenPlanList = ({ closeMenu }: iProps): JSX.Element => {
  const activePlanId = useSelector(CanvasSelectors.getActivePlanID);
  const openPlans = useSelector(CanvasSelectors.getOpenPlanIDs);

  const getPlanAndStatus = usePlanStatusGetter();

  const notNullOpenPlans = useMemo(() => {
    return openPlans.map((planId) => getPlanAndStatus(planId)).filter((plan): plan is PlanWithStatus => plan !== null);
  }, [openPlans, getPlanAndStatus]);

  return (
    <div className={styles.openPlanList}>
      <h2>Open Plans</h2>
      {notNullOpenPlans.map(({ plan, status }) => (
        <OpenPlanListItem key={plan.ID} plan={plan} closeMenu={closeMenu} active={plan.ID === activePlanId} status={status} />
      ))}
      {notNullOpenPlans.length <= 1 ? <div className={styles.emptyListMessage}>No other open plans</div> : null}
    </div>
  );
};

export default OpenPlanList;
