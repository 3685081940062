import React from 'react';

import './status-bar.scss';

interface iStatusBarElement {
  children: React.ReactNode;
  className?: string;
}

export const StatusBar = ({ children, className = '' }: iStatusBarElement): JSX.Element => {
  return <div className={`status-bar ${className}`}>{children}</div>;
};

export const StatusBarRightContainer = ({ children, className = '' }: iStatusBarElement): JSX.Element => {
  return <div className={`status-bar-right ${className}`}>{children}</div>;
};

export const StatusBarLeftContainer = ({ children, className = '' }: iStatusBarElement): JSX.Element => {
  return <div className={`status-bar-left ${className}`}>{children}</div>;
};

export const StatusBarSection = ({ children, className = '' }: iStatusBarElement): JSX.Element => {
  return <div className={`status-bar-section ${className}`}>{children}</div>;
};
