import { SessionSelectors } from '@gi/react-session';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'redux';

import { processGetParams } from './process-init-params';

interface iProps {
  store: Store;
}

/**
 * Simple component which, when the user is first loaded,
 * calls 'processGetParams' with the store from props
 */
const InitParamHandler = ({ store }: iProps): null => {
  const user = useSelector(SessionSelectors.getUser);
  const userIsNull = user === null;

  useEffect(() => {
    if (!userIsNull) {
      processGetParams(store, user.ID);
    }
  }, [userIsNull]);
  return null;
};

export default InitParamHandler;
