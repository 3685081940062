import React, { ReactNode } from 'react';

import Plan, { PlanUtils } from '@gi/plan';
import { UserPlan } from '@gi/user';
import { LoadingState } from '@gi/constants';

import styles from './mobile-plan-menu-plan-options.module.css';

interface iProps {
  plan: Plan | UserPlan;
  onClose: () => void;
  northernHemisphere: boolean;
  isActive?: boolean;
  loading?: LoadingState;
  children?: ReactNode;
}

/** Section containing the plan name/year at the top, with a close button to the right */
const MobilePlanMenuPlanOptions = ({ plan, isActive = false, onClose, northernHemisphere, loading = LoadingState.SUCCESS, children }: iProps): JSX.Element => {
  return (
    <div className={styles.container}>
      {isActive ? <span className={styles.activeBadge}>Current Plan</span> : null}
      <div className={styles.header}>
        <div className={styles.headerTop}>
          <p className={styles.planDetails}>
            <span className={styles.planName}>{plan.name}</span>
            <span className={styles.planYear}>{PlanUtils.getYearDisplayString(plan.year, northernHemisphere)}</span>
          </p>
          <button type='button' className={styles.closeButton} onClick={onClose}>
            <i className='icon-cancel' />
          </button>
        </div>
        <div className={styles.headerBottom}>
          {loading === LoadingState.LOADING ? (
            <span className={styles.loading}>
              <i className='icon-spinner animate-pulse' /> Loading Plan...
            </span>
          ) : null}
          {loading === LoadingState.ERROR ? (
            <span className={styles.error}>
              <i className='icon-warning' /> Plan failed to load
            </span>
          ) : null}
        </div>
      </div>
      {children ? <div className={styles.content}>{children}</div> : null}
    </div>
  );
};

export default MobilePlanMenuPlanOptions;
