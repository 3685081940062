import React from 'react';

import './print-progress.scss';

interface iProps {
  progress: number;
  text?: string;
}

const PrintProgress = ({ progress, text }: iProps): JSX.Element => {
  const clampedPercent = Math.min(Math.max(progress * 100, 0), 100);
  return (
    <div className='print-progress'>
      <div className='print-progress-bar'>
        <span className='print-progress-bar-inner' style={{ width: `${clampedPercent}%` }} />
        <span className='print-progress-bar-percent' style={{ minWidth: `${clampedPercent}%` }}>
          <span>{Math.floor(clampedPercent)}%</span>
        </span>
      </div>
      {text ? <p>{text}</p> : null}
    </div>
  );
};

export default PrintProgress;
