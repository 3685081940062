import { Graphics } from 'pixi.js-new';

import { Geometry, Matrix } from '@gi/math';
import Bitmask, { BitmaskType } from '@gi/bitmask';
import { State, StateDef, StateObserver } from '@gi/state';

import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { Bounds, InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { bindState } from '../../utils/state-utils';
import TransformComponent, { TransformComponentState } from '../transform/transform-component';
import { hasEngine } from '../../utils/asserts';
import { ShapeCollisionCheckFunction, ShapeFlag } from './types';
import ShapeComponentContext from './shape-component-context';
import { ShapeCollisionCheckFunctions } from './shape-collision-funcitons';

export type ShapeComponentState = StateDef<
  {
    autoBoundingBox: boolean;
    boundingBox: Bounds;
    points: Vector2[];
    closed: boolean;
    debugGraphics: boolean;
    flags: BitmaskType<ShapeFlag>;
  },
  [],
  {
    transform: TransformComponentState;
  }
>;

const DEFAULT_STATE: ShapeComponentState['state'] = {
  autoBoundingBox: true,
  boundingBox: { top: 0, left: 0, bottom: 0, right: 0 },
  points: [],
  closed: true,
  debugGraphics: false,
  flags: Bitmask.Create(ShapeFlag.UI),
};

// const isCounterClockwise = (a: Vector2, b: Vector2, c: Vector2): boolean => {
//   return (c.y - a.y) * (b.x - a.x) > (b.y - a.y) * (c.x - a.x);
// };

// const doLinesIntersect = (aStart: Vector2, aEnd: Vector2, bStart: Vector2, bEnd: Vector2): boolean => {
//   return isCounterClockwise(aStart, bStart, bEnd) !== isCounterClockwise(aEnd, bStart, bEnd)
//     && isCounterClockwise(aStart, aEnd, bStart) !== isCounterClockwise(aStart, bStart, bEnd);
// };

class ShapeComponent extends NodeComponent {
  type = 'ShapeComponent';

  readonly state: State<ShapeComponentState>;

  rotationMatrix: number[][] = Matrix.IDENTITY;
  #cachedRotatedPoints: Vector2[] | null = null;

  #debugGraphics: Graphics = new Graphics();
  #stateUpdater: StateObserver<any> | null = null;
  #context: ShapeComponentContext | null = null;

  collisionCheckFunction: ShapeCollisionCheckFunction = ShapeCollisionCheckFunctions.BoundingBox;

  constructor(initialState: Partial<ShapeComponentState['state']> = {}) {
    super();

    this.state = new State({
      ...DEFAULT_STATE,
      ...initialState,
    });
    bindState(this.state, this);

    this.state.addValidator(
      (state) => {
        if (state.changed.properties.points) {
          this.#cachedRotatedPoints = null;
        }
        if (state.values.autoBoundingBox) {
          this.#regenerateBoundingBox();
        }
      },
      { properties: ['autoBoundingBox', 'points'] }
    );

    this.state.addUpdater(
      (state) => {
        if (state.hasChanged('transform', 'rotation')) {
          this.#cachedRotatedPoints = null;
          const rotation = state.get('transform', 'rotation');
          if (rotation !== undefined) {
            this.rotationMatrix = Matrix.createRotationMatrix(rotation);
          }
        }
        if (state.values.autoBoundingBox) {
          this.#regenerateBoundingBox();
        }
      },
      {
        properties: ['autoBoundingBox', 'points'],
        otherStates: { transform: { properties: ['rotation'] } },
      }
    );

    this.state.addWatcher(() => {
      this.#drawDebugGraphics();
    }, undefined);

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);

    this.owner.getOwnGraphics().addChild(this.#debugGraphics);

    const transformComponent = this.owner.components.get(TransformComponent);

    if (transformComponent) {
      this.state.connectState('transform', transformComponent.state);
    }

    this.owner.getContext(ShapeComponentContext).register(this as ShapeComponent);
  };

  #onBeforeUnbind = () => {
    hasEngine(this);

    if (this.#stateUpdater) {
      this.#stateUpdater.destroy();
      this.#stateUpdater = null;
    }

    this.owner.getContext(ShapeComponentContext).unregister(this as ShapeComponent);
  };

  /**
   * Sets the outline of this shape.
   * @param points The points defining the edge of this shape
   * @param rotation The rotation of the shape
   */
  setPoints(points: Vector2[], rotation?: number, rotationOrigin?: Vector2) {
    // Rotate all the points if a rotation is passed in.
    if (rotation !== undefined) {
      const origin = rotationOrigin ?? { x: 0, y: 0 };
      const translationMatrix = Matrix.createTranslationMatrix(-origin.x, -origin.y);
      const rotationMatrix = Matrix.createRotationMatrix(rotation);
      const reverseTranslationMatrix = Matrix.createTranslationMatrix(origin.x, origin.y);
      const matrix = Matrix.multiplyAll([reverseTranslationMatrix, rotationMatrix, translationMatrix]);

      this.state.values.points = points.map((point) => Matrix.multiplyVector(point, matrix));
    } else {
      this.state.values.points = points;
    }
  }

  /**
   * Automatically generate the bounds of this shape any time `points` are changed
   */
  autoBoundingBox() {
    this.state.values.autoBoundingBox = true;
  }

  /**
   * Manually set the bounding box for this shape
   * @param bounds The bounds of the AABB
   */
  manualBoundingBox(bounds: Bounds) {
    this.state.values.autoBoundingBox = false;
    this.state.values.boundingBox = bounds;
  }

  /**
   * More accurate hit detection to check if an AABB touches a shape.
   * This can be expensive, don't use for rough checks like in visibility calculations.
   * @param min The top-left coordinate of the area to test
   * @param max The bottom-right coordinate of the area to test
   * @returns True if the given area touches the shape
   */
  isTouchingArea(min: Vector2, max: Vector2) {
    const position = this.state.get('transform', 'position', { x: 0, y: 0 });
    const { boundingBox, points } = this.state.values;
    const { left, right, top, bottom } = boundingBox;
    const adjustedMin = Geometry.minusPoint(min, position);
    const adjustedMax = Geometry.minusPoint(max, position);
    // If there's no shape, give up
    if (points.length === 0) {
      return false;
    }
    // If the AABB doesn't overlap, fail
    if (left > adjustedMax.x || right < adjustedMin.x || top > adjustedMax.y || bottom < adjustedMin.y) {
      return false;
    }
    // If the AABB is fully enclosed within the min/max, skip custom checks and pass
    if (left > adjustedMin.x && right < adjustedMax.x && top > adjustedMin.y && bottom < adjustedMax.y) {
      return true;
    }
    // We're on the edge of the min/max, custom check if available.
    return this.collisionCheckFunction(min, max, { points: this.#getRotatedPoints(), boundingBox, position });
  }

  /**
   * Internally recalculates the bounding-box
   */
  #regenerateBoundingBox() {
    const points = this.#getRotatedPoints();

    if (points.length === 0) {
      this.state.values.boundingBox = {
        top: -Infinity,
        left: -Infinity,
        bottom: Infinity,
        right: Infinity,
      };
    } else {
      const { min, max } = Geometry.getBoundingBox(...points);
      this.state.values.boundingBox = {
        top: min.y,
        left: min.x,
        bottom: max.y,
        right: max.x,
      };
    }
  }

  /**
   * Gets the points of the shape, rotated by the current transform component, if available
   * @returns A list of points, rotated
   */
  #getRotatedPoints() {
    if (this.#cachedRotatedPoints !== null) {
      return this.#cachedRotatedPoints;
    }
    const { points } = this.state.values;
    if (this.state.get('transform', 'rotation') === 0) {
      return points;
    }

    this.#cachedRotatedPoints = this.state.values.points.map((point) => Matrix.multiplyVector(point, this.rotationMatrix));
    return this.#cachedRotatedPoints;
  }

  /**
   * Draws a bounding box outline around the shape, for debugging
   */
  #drawDebugGraphics() {
    if (!this.#debugGraphics) {
      return;
    }
    const { boundingBox, debugGraphics } = this.state.values;

    if (!debugGraphics) {
      this.#debugGraphics.visible = false;
      return;
    }

    this.#debugGraphics.visible = true;

    const transform = this.owner?.components.get(TransformComponent);
    this.#debugGraphics.rotation = -(transform?.state.values.rotation ?? 0);

    this.#debugGraphics
      .clear()
      .lineStyle({ color: 0x0000ff, width: 1 })
      .drawRect(boundingBox.left, boundingBox.top, boundingBox.right - boundingBox.left, boundingBox.bottom - boundingBox.top);
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default ShapeComponent;
