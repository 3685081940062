import { RequestAuthMode, defaultNetworkErrorParser, networkErrorReporter, networkService } from '@gi/gi-network';

import catalogueParser from '../parsers/catalogue-parser';
import { Catalogue } from '../catalogue';

const TIMEOUT_DURATION = 1000 * 60 * 4; // 4 minutes

class CatalogueService {
  endpoint: string;
  timeoutDuration: number;

  constructor({ endpoint, timeoutDuration = TIMEOUT_DURATION }) {
    this.endpoint = endpoint;
    this.timeoutDuration = timeoutDuration;

    console.debug(`Created catalogue service with endpoint ${this.endpoint}`);
  }

  getCatalogue(): Promise<Catalogue> {
    return networkService
      .get(this.endpoint, {}, RequestAuthMode.None, { timeout: this.timeoutDuration })
      .then((catalogueJSON) => {
        return catalogueParser(catalogueJSON.body);
      })
      .catch(networkErrorReporter('GET', 'catalogue'))
      .catch((err) => {
        console.log('Error loading catalogue data');
        console.log(err);
        throw defaultNetworkErrorParser(err);
      });
  }
}

export default CatalogueService;
