import React, { useCallback, useState } from 'react';
import Modal, { ModalContent, ModalPane, ModalPaneContainer, ModalPaneContent } from '@gi/modal';
import { useKeyboardShortcut } from '@gi/keyboard-shortcut';
import ProductGallery from './product-gallery';

import styles from './product-gallery.module.css';

interface iProps {
  galleryImageUrls: string[];
  defaultImageIndex: number;
  closeModal: () => void;
}

const GalleryModal = ({ galleryImageUrls, defaultImageIndex, closeModal }: iProps): JSX.Element | null => {
  const [index, setIndex] = useState(defaultImageIndex);

  const next = useCallback(() => {
    setIndex((_index) => (_index + 1) % galleryImageUrls.length);
  }, [galleryImageUrls.length]);
  const prev = useCallback(() => {
    setIndex((_index) => (_index + galleryImageUrls.length - 1) % galleryImageUrls.length);
  }, [galleryImageUrls.length]);

  useKeyboardShortcut(next, 'arrowright');
  useKeyboardShortcut(prev, 'arrowleft');

  return (
    <Modal closeRequest={closeModal}>
      <ModalContent>
        <ModalPaneContainer>
          <ModalPane>
            <ModalPaneContent>
              <ProductGallery className={styles.modalGallery} galleryImageUrls={galleryImageUrls} currentImageIndex={index} setCurrentImageIndex={setIndex} />
            </ModalPaneContent>
          </ModalPane>
        </ModalPaneContainer>
      </ModalContent>
    </Modal>
  );
};

export default GalleryModal;
