import { Record } from 'immutable';

const _PreAuthTicket = {
  ticket: '',
};

class PreAuthTicket extends Record(_PreAuthTicket) {
  toString() {
    return this.ticket;
  }
}

export default PreAuthTicket;
