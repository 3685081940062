import React from 'react';

import ConfirmModalWithUnsavedPlans from '@gi/confirm-modal-with-unsaved-plans';

interface iProps {
  country: string;
  onCancel: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

const ConfirmChangeCountryModal = ({ country, onCancel, onConfirm, loading }: iProps): JSX.Element => {
  return (
    <ConfirmModalWithUnsavedPlans title='Change Country' onCancel={onCancel} onConfirm={onConfirm} confirmButtonText='Change Country' loading={loading}>
      <p>
        You are about to change your country to <strong>{country}</strong>.
      </p>
      <p>Changing countries requires the Garden Planner to reload.</p>
      <p>Do you wish to continue?</p>
    </ConfirmModalWithUnsavedPlans>
  );
};

export default ConfirmChangeCountryModal;
