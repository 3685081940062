import React from 'react';

import { HelpModal, ModalPaneHeader, ModalPaneSection } from '@gi/modal';

import plantSpacingImage from './plant-spacing-placeholder.png';

import './plant-spacing-help-modal.scss';

interface iProps {
  closeModal: () => void;
}

const PlantSpacingHelpModal = ({ closeModal }: iProps) => {
  return (
    <HelpModal className='plant-spacing-help-modal' headerText='Plant Spacing' closeModal={closeModal} style={{ maxWidth: 520 }}>
      <ModalPaneSection>
        <ModalPaneHeader>Single Plant Spacing</ModalPaneHeader>
        <p>
          Single plants and plants grown in blocks have one spacing setting. When plants are grown in blocks, they are spaced the same distance apart in all
          directions. This distance is shown as <span className='single-plant-highlight'>red arrows</span> on the diagram.
        </p>
      </ModalPaneSection>
      <ModalPaneSection>
        <ModalPaneHeader>Row Spacing</ModalPaneHeader>
        <p>
          When planting in rows, plants can often be planted closer together in the row, but with a wider space between rows. By doing this, the plants get the
          same area of ground for their roots (by spreading out between rows), while also making it easier for the gardener to get in-between the rows for
          weeding and harvesting. The distance between plants in a row is shown as <span className='row-plant-highlight'>blue arrows</span>, with the distance
          between each row (the row gap) shown as <span className='row-gap-highlight'>pink arrows</span>.
        </p>
      </ModalPaneSection>
      <ModalPaneSection>
        <div className='help-img-container'>
          <img src={plantSpacingImage} alt='Diagram illustrating different plant spacings' />
        </div>
      </ModalPaneSection>
    </HelpModal>
  );
};

export default PlantSpacingHelpModal;
