import React from 'react';

import Plant from '@gi/plant';
import PlannerIcon from '@gi/planner-icon';

import styles from './companion-plant-tag.module.css';

interface iProps {
  plant: Plant;
  isMutual?: boolean;
}

const CompanionPlantTag = ({ plant, isMutual = false }: iProps): JSX.Element => {
  return (
    <span className={`${styles.companionPlant} ${isMutual ? styles.mutualCompanion : ''}`}>
      <PlannerIcon code={plant.code} halfSize className={styles.plantIcon} />
      <span className={styles.companionText}>{plant.name}</span>
    </span>
  );
};

export default CompanionPlantTag;
