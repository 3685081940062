import React from 'react';
import { Plant } from '@gi/plant';
import PlantIcon from './plant-icon';

import './plant-icon-with-family.scss';

interface iProps {
  plant: Plant;
}

const PlantIconWithFamily = ({ plant }: iProps): JSX.Element => {
  const className = `plant-icon-with-family selection-icon-family-${plant.familyID}`;
  return (
    <div className={className}>
      <PlantIcon filename={plant.icon.filename} />
    </div>
  );
};

export default PlantIconWithFamily;
