import React from 'react';

import { LoadingScreen, LoadingScreenErrorMessage } from '@gi/loading-screen';

const RegionZeroErrorScreen = () => {
  return (
    <LoadingScreen>
      <LoadingScreenErrorMessage>
        <h2>User Settings Error</h2>
        <p>
          Your planting region has been incorrectly set, please set your location and find your planting region by adding a postal code in{' '}
          <a href='/account/settings/location-frost-dates' target='_blank'>
            your location settings
          </a>{' '}
          and then <a href={window.location.href}>refresh the Garden Planner</a>.
        </p>
        <p>
          Please <a href='/contact.aspx'>contact support</a> if you have any issues
        </p>
      </LoadingScreenErrorMessage>
    </LoadingScreen>
  );
};

export default RegionZeroErrorScreen;
