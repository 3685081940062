import { FormValues } from '@gi/form-responsive';
import { RevocableObjectURL } from '@gi/utils';

export type ImageCrop = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type ImageManipulationData = {
  crop: ImageCrop | null;
};

export type InputImage = {
  image: HTMLImageElement;
};

export type InputRemoteImage = {
  imageSrc: string;
};

export type InputAsyncImage = {
  objectURL: RevocableObjectURL;
};

export type OutputImage = InputImage & ImageManipulationData;

export type OutputRemoteImage = InputRemoteImage & ImageManipulationData;

export type OutputAsyncImage = InputAsyncImage & ImageManipulationData;

export function isImageDef(input: any): input is InputImage {
  return 'image' in input && input.image instanceof HTMLImageElement;
}
export function isImageSrcDef(input: any): input is InputRemoteImage {
  return 'imageSrc' in input && typeof input.imageSrc === 'string';
}
export function isAsyncImageDef(input: any): input is InputAsyncImage {
  return 'objectURL' in input && typeof input.objectURL === 'object' && input.objectURL instanceof RevocableObjectURL;
}

export interface ImageEditorModalFormState {
  image: InputImage | InputRemoteImage | InputAsyncImage | null;
  crop: ImageCrop | null;
}

export interface ImageEditorModalFormProps {
  values: FormValues<ImageEditorModalFormState>;
  setValues: (values: FormValues<ImageEditorModalFormState>) => void;
}
