import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { createVideoAnalytics } from './video-analytics';

const ANALYTICS_EVENT = 'analytics/EVENT';
const ANALYTICS_VIDEO_EVENT = 'analytics/VIDEO_EVENT';

// Prepares an analytics event before being dispatched to the store
const prepareEvent = (eventName: string, eventData?: string | object, extraData?: Partial<{ displayMode: boolean; version: boolean; deviceData: boolean }>) => {
  return {
    payload: {
      eventName,
      eventData,
      extraData,
    },
  };
};

// Prepares a video analytics event before being dispatched to the store
const prepareVideoEvent = (videoName: string, videoLength: number, videoWatches: [number, number][]) => {
  return {
    payload: createVideoAnalytics(videoName, videoLength, videoWatches),
  };
};

export type AnalyticsReducerState = {
  disabled: boolean;
};

const initialState: AnalyticsReducerState = {
  disabled: false,
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.disabled = action.payload;
    },
  },
});

interface RootState {
  analytics: AnalyticsReducerState;
}

export const analyticsReducer = analyticsSlice.reducer;

export const AnalyticsActions = {
  ...analyticsSlice.actions,
  analyticsEvent: createAction(ANALYTICS_EVENT, prepareEvent),
  analyticsVideoEvent: createAction(ANALYTICS_VIDEO_EVENT, prepareVideoEvent),
};

export const AnalyticsSelectors = {
  // Returns true if the analytics middleware is disabled
  isDisabled: (state: RootState) => state.analytics.disabled,
};
