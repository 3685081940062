import Collection from '@gi/collection';
import Plant from '@gi/plant';
import GardenObject from '@gi/garden-object';
import { LayerType, LayerTypes } from '@gi/constants';

import { FollowOnPlanOptions, Plan } from './plan';
import { isOverWinter, isPerennial } from './plan-plant-utils';
import { PlanPlant, addPlantToPlan } from './plan-plant';
import { gardenObjectIsType } from './plan-garden-object-utils';
import { PlanGardenObject, addGardenObjectToPlan } from './plan-garden-object';
import { PlanShape, addShapeToPlan } from './plan-shape';
import { addTextToPlan, PlanText } from './plan-text';

const MAX_PLAN_HISTORY_COUNT = 5;

const MIN_YEAR = 2010;
const MAX_YEARS_AHEAD = 5;

const NEW_SEASON_MONTH_NORTHERN_HEMISPHERE = 10; // November
const NEW_SEASON_MONTH_SOUTHERN_HEMISPHERE = 4; // May

const CURRENT_MONTH = new Date().getMonth();
const CURRENT_YEAR = new Date().getFullYear();

export const MAX_PLAN_YEAR = CURRENT_YEAR + MAX_YEARS_AHEAD;

/**
 * Returns all the PlanPlant in a plan as an array
 */
export function getPlanPlants(plan: Plan): PlanPlant[] {
  return plan.plantIds.map((plantId) => plan.plants[plantId]);
}

/**
 * Returns all the PlanGardenObject in a plan as an array
 */
export function getPlanGardenObjects(plan: Plan): PlanGardenObject[] {
  return plan.gardenObjectIds.map((gardenObjectId) => plan.gardenObjects[gardenObjectId]);
}

/**
 * Returns all the PlanShape in a plan as an array
 */
export function getPlanShapes(plan: Plan): PlanShape[] {
  return plan.shapeIds.map((shapeId) => plan.shapes[shapeId]);
}

/**
 * Returns all the PlanText in a plan as an array
 */
export function getPlanText(plan: Plan): PlanText[] {
  return plan.textIds.map((textId) => plan.text[textId]);
}

/**
 * Returns the display string for the given plan year
 *
 * A plan for 2020 displays 2020 in the northern hemisphere and 2020/21 for the southern hemisphere
 */
export function getYearDisplayString(year: number, northernHemisphere: boolean): string {
  if (northernHemisphere) {
    return year.toString();
  }

  const nextYearString = (year + 1).toString();
  return `${year}/${nextYearString[2]}${nextYearString[3]}`;
}

/**
 * A function which returns year options for a plan year dropdown selection input
 */
export function getYearOptions(northernHemisphere: boolean) {
  const options: { label: string; value: number }[] = [];

  for (let i = MIN_YEAR; i <= CURRENT_YEAR + MAX_YEARS_AHEAD; i++) {
    options.push({ label: getYearDisplayString(i, northernHemisphere), value: i });
  }

  return options.reverse();
}

/**
 * Returns the default plan year based on the current month and year
 */
export function calculateNewDefaultPlanYear(northernHemisphere: boolean): number {
  if (northernHemisphere) {
    return CURRENT_YEAR + (CURRENT_MONTH >= NEW_SEASON_MONTH_NORTHERN_HEMISPHERE ? 1 : 0);
  }

  return CURRENT_YEAR - 1 + (CURRENT_MONTH >= NEW_SEASON_MONTH_SOUTHERN_HEMISPHERE ? 1 : 0);
}

/**
 * Checks if 2 vectors are equal, or both null.
 */
export function bothNullOrEqual(point1: Vector2 | null, point2: Vector2 | null): boolean {
  if (point1 === null && point2 === null) {
    return true;
  }

  if (point1 === null || point2 === null) {
    return false;
  }

  return point1.x === point2.x && point1.y === point2.y;
}

/**
 * Returns a new plan which is this plan updated from a response from the API
 *
 * We're using the assumption that the API only changes the readOnly properties modified date, lastSaveDevice and documentVersion;
 * itemRecordIds are updated so new itemRecordIds can be set (so new items won't incorrectly get assigned new record Ids each time)
 * and plant notes are updated because new notes will have new RecordIds and plant notes aren't checked as part of undo/redo history
 * so they can be kept inside the plantNotes themselves (unlike plants, garden objects, shapes and text)
 *
 * This means our immutable plan item collections will not be changed and their references will be kept, stopping
 * unnecessary updates elsewhere
 */
export function updatePlanFromServerResponse(plan: Plan, responsePlan: Plan): Plan {
  return {
    ...plan,
    documentVersion: responsePlan.documentVersion,
    modified: responsePlan.modified,
    lastSaveDevice: responsePlan.lastSaveDevice,
    itemRecordIds: responsePlan.itemRecordIds,
    plantNotes: responsePlan.plantNotes,
  };
}

export function removePlanIdFromHistory(plan: Plan, planId: number): Plan {
  return {
    ...plan,
    history: plan.history.filter((historyId) => historyId !== planId),
  };
}

export function plansShallowEqual(plan: Plan, lastSavedPlan: Plan): boolean {
  if (plan.id !== lastSavedPlan.id) {
    throw new Error(`Comparing unsaved changes on different plans ${plan.id} - ${lastSavedPlan.id}`);
  }

  // TODO: Stare at very carefully until sure it works (Add unit tests)
  return (
    plan.name === lastSavedPlan.name &&
    plan.year === lastSavedPlan.year &&
    plan.shared === lastSavedPlan.shared &&
    plan.plannerSettings.metric === lastSavedPlan.plannerSettings.metric &&
    plan.plannerSettings.showGrid === lastSavedPlan.plannerSettings.showGrid &&
    plan.width === lastSavedPlan.width &&
    plan.height === lastSavedPlan.height &&
    plan.orientationSet === lastSavedPlan.orientationSet &&
    plan.orientation === lastSavedPlan.orientation &&
    plan.history === lastSavedPlan.history &&
    plan.templatePlanId === lastSavedPlan.templatePlanId &&
    plan.notes === lastSavedPlan.notes &&
    plan.published === lastSavedPlan.published &&
    plan.publishLocation === lastSavedPlan.publishLocation &&
    plan.publishDescription === lastSavedPlan.publishDescription &&
    plan.publishMap === lastSavedPlan.publishMap &&
    plan.publishFindOnMap === lastSavedPlan.publishFindOnMap &&
    plan.publishPlantList === lastSavedPlan.publishPlantList &&
    plan.publishNotes === lastSavedPlan.publishNotes &&
    plan.type === lastSavedPlan.type &&
    plan.layout === lastSavedPlan.layout &&
    plan.sun === lastSavedPlan.sun &&
    plan.soil === lastSavedPlan.soil &&
    plan.plants === lastSavedPlan.plants &&
    plan.gardenObjects === lastSavedPlan.gardenObjects &&
    plan.shapes === lastSavedPlan.shapes &&
    plan.text === lastSavedPlan.text &&
    plan.plantNotes === lastSavedPlan.plantNotes &&
    plan.backgroundImage === lastSavedPlan.backgroundImage &&
    plan.plannerSettings.showBackgroundImages === lastSavedPlan.plannerSettings.showBackgroundImages &&
    plan.plannerSettings.backgroundImageOpacity === lastSavedPlan.plannerSettings.backgroundImageOpacity &&
    plan.plannerSettings.maintainBackgroundImageAspectRatio === lastSavedPlan.plannerSettings.maintainBackgroundImageAspectRatio
  );
}

type CopyPlantsOptions = {
  onlyPerennials: boolean;
  rollOverInGroundDates: boolean;
};

const COPY_PLANTS_OPTIONS_DEFAULTS = {
  onlyPerennials: false,
  rollOverInGroundDates: false,
};

export function copyPlanPlants(
  sourcePlan: Plan,
  destPlan: Plan,
  plants: Collection<Plant>,
  copyPlantsOptions: CopyPlantsOptions = COPY_PLANTS_OPTIONS_DEFAULTS
): Plan {
  let plantsToAdd = getPlanPlants(sourcePlan);

  // Make sure to only copy plants that are available in the users plant collection
  plantsToAdd = plantsToAdd.filter((planPlant) => plants.has(planPlant.plantCode));

  if (copyPlantsOptions.onlyPerennials) {
    plantsToAdd = plantsToAdd.filter((planPlant) => isPerennial(planPlant, plants) || isOverWinter(planPlant));
  }

  let updatedPlan = destPlan;

  plantsToAdd.forEach((planPlant) => {
    const plantCopy = structuredClone(planPlant);
    // When copying for a follow-on plan we want to roll over the in-ground dates to what they would be for the next year
    if (
      copyPlantsOptions.rollOverInGroundDates &&
      plantCopy.inGroundEnd !== 11 &&
      plantCopy.inGroundStart !== 0 &&
      plantCopy.inGroundStart > plantCopy.inGroundEnd
    ) {
      plantCopy.inGroundStart = 0;
    }

    updatedPlan = addPlantToPlan(updatedPlan, plantCopy);
  });

  return updatedPlan;
}

export function copyGardenObjectsOfType(sourcePlan: Plan, destPlan: Plan, gardenObjects: Collection<GardenObject>, type: LayerType) {
  const gardenObjectsToAdd = getPlanGardenObjects(sourcePlan)
    .filter((planGardenObject) => gardenObjects.has(planGardenObject.code))
    .filter((planGardenObject) => gardenObjectIsType(planGardenObject, gardenObjects, type));

  let updatedPlan = destPlan;

  gardenObjectsToAdd.forEach((planPlant) => {
    updatedPlan = addGardenObjectToPlan(updatedPlan, structuredClone(planPlant));
  });

  return updatedPlan;
}

type CopyGardenObjectsOptions = {
  copyLayout: boolean;
  copyIrrigation: boolean;
  copyStructures: boolean;
};

const DEFAULT_COPY_GARDEN_OBJECTS_OPTIONS = {
  copyLayout: true,
  copyIrrigation: true,
  copyStructures: true,
};

/**
 * Copies `PlanGardenObject`s of the types specified in the options from the source plan to the dest plan
 *
 * Uses `structuredClone` to create a deep copy of each `PlanGardenObject`
 */
export function copyPlanGardenObjects(
  sourcePlan: Plan,
  destPlan: Plan,
  gardenObjects: Collection<GardenObject>,
  options: CopyGardenObjectsOptions = DEFAULT_COPY_GARDEN_OBJECTS_OPTIONS
) {
  let updatedPlan = destPlan;

  if (options.copyLayout) {
    updatedPlan = copyGardenObjectsOfType(sourcePlan, updatedPlan, gardenObjects, LayerTypes.LAYOUT);
  }

  if (options.copyIrrigation) {
    updatedPlan = copyGardenObjectsOfType(sourcePlan, updatedPlan, gardenObjects, LayerTypes.IRRIGATION);
  }

  if (options.copyStructures) {
    updatedPlan = copyGardenObjectsOfType(sourcePlan, updatedPlan, gardenObjects, LayerTypes.STRUCTURES);
  }

  return updatedPlan;
}

/**
 * Copies all `PlanShape`s from the source plant to the dest plan, uses `structuredClone` to create a
 * deep copy of each `PlanShape`
 */
export function copyPlanShapes(sourcePlan: Plan, destPlan: Plan) {
  let updatedPlan = destPlan;

  getPlanShapes(sourcePlan).forEach((planShape) => {
    updatedPlan = addShapeToPlan(updatedPlan, structuredClone(planShape));
  });

  return updatedPlan;
}

/**
 * Copies all `PlanText` from the source plant to the dest plan, uses `structuredClone` to create a
 * deep copy of each `PlanText`
 */
export function copyPlanText(sourcePlan: Plan, destPlan: Plan) {
  let updatedPlan = destPlan;

  getPlanText(sourcePlan).forEach((planShape) => {
    updatedPlan = addTextToPlan(updatedPlan, structuredClone(planShape));
  });

  return updatedPlan;
}

/**
 * Creates a follow on plan from the source plan. A destination plan is used with properties already set (name, year, width, height, units)
 */
export function createFollowOnPlan(
  sourcePlan: Plan,
  destinationPlan: Plan,
  plants: Collection<Plant>,
  gardenObjects: Collection<GardenObject>,
  options: FollowOnPlanOptions
) {
  let updatedPlan = destinationPlan;

  updatedPlan = copyPlanGardenObjects(sourcePlan, updatedPlan, gardenObjects, options);

  if (options.copyStructures) {
    // Shapes are counted under 'structures' for follow on plans
    updatedPlan = copyPlanShapes(sourcePlan, updatedPlan);
  }

  if (options.copyText) {
    updatedPlan = copyPlanText(sourcePlan, updatedPlan);
  }

  if (options.copyPlants) {
    updatedPlan = copyPlanPlants(sourcePlan, updatedPlan, plants, {
      onlyPerennials: !options.allPlants,
      rollOverInGroundDates: true, // We want to roll over planting dates when creating follow-on plants
    });
  }

  if (options.copyNotes) {
    updatedPlan.notes = sourcePlan.notes;
  }

  updatedPlan.history = [sourcePlan.id, ...sourcePlan.history];
  // Restrict plan history to 5 items
  if (updatedPlan.history.length > MAX_PLAN_HISTORY_COUNT) {
    updatedPlan.history.length = MAX_PLAN_HISTORY_COUNT;
  }
  return updatedPlan;
}

/**
 * Condenses the z-indexes of the given plan so they're contiguous.
 * @param plan The plan to condense
 * @returns A copy of the plan, or the original plan if no changes were made
 */
export function condenseZIndexes(plan: Plan): Plan {
  const zIndexes: Set<number> = new Set([0]);

  plan.plantIds.forEach((plantId) => {
    zIndexes.add(plan.plants[plantId].zIndex);
  });
  plan.gardenObjectIds.forEach((gardenObjectId) => {
    zIndexes.add(plan.gardenObjects[gardenObjectId].zIndex);
  });
  plan.shapeIds.forEach((shapeId) => {
    zIndexes.add(plan.shapes[shapeId].zIndex);
  });
  plan.textIds.forEach((textId) => {
    zIndexes.add(plan.text[textId].zIndex);
  });

  // Check if the z-indexes are already contiguous, and don't modify
  const zIndexArray = [...zIndexes].sort((a, b) => a - b);
  if (zIndexArray[zIndexArray.length - 1] - zIndexArray[0] === zIndexArray.length - 1) {
    return plan;
  }

  const zeroIndex = zIndexArray.indexOf(0);
  const zIndexMap: Record<number, number> = {};
  zIndexArray.forEach((zIndex, index) => {
    zIndexMap[zIndex] = index - zeroIndex;
  });

  const newPlan = { ...plan };
  newPlan.plants = { ...plan.plants };
  newPlan.gardenObjects = { ...plan.gardenObjects };
  newPlan.shapes = { ...plan.shapes };
  newPlan.text = { ...plan.text };

  newPlan.plantIds.forEach((plantId) => {
    const oldZIndex = newPlan.plants[plantId].zIndex;
    const newZIndex = zIndexMap[oldZIndex];
    if (newZIndex !== oldZIndex) {
      newPlan.plants[plantId] = {
        ...newPlan.plants[plantId],
        zIndex: newZIndex,
      };
    }
  });

  newPlan.gardenObjectIds.forEach((gardenObjectId) => {
    const oldZIndex = newPlan.gardenObjects[gardenObjectId].zIndex;
    const newZIndex = zIndexMap[oldZIndex];
    if (newZIndex !== oldZIndex) {
      newPlan.gardenObjects[gardenObjectId] = {
        ...newPlan.gardenObjects[gardenObjectId],
        zIndex: newZIndex,
      };
    }
  });

  newPlan.shapeIds.forEach((shapeId) => {
    const oldZIndex = newPlan.shapes[shapeId].zIndex;
    const newZIndex = zIndexMap[oldZIndex];
    if (newZIndex !== oldZIndex) {
      newPlan.shapes[shapeId] = {
        ...newPlan.shapes[shapeId],
        zIndex: newZIndex,
      };
    }
  });

  newPlan.textIds.forEach((textId) => {
    const oldZIndex = plan.text[textId].zIndex;
    const newZIndex = zIndexMap[oldZIndex];
    if (newZIndex !== oldZIndex) {
      newPlan.text[textId] = {
        ...newPlan.text[textId],
        zIndex: newZIndex,
      };
    }
  });

  // eslint-disable-next-line prefer-destructuring
  newPlan.minZIndex = zIndexArray[0];
  newPlan.maxZIndex = zIndexArray[zIndexArray.length - 1];

  return newPlan;
}
