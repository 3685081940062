import { PDFDocument } from 'pdf-lib';

import { Engine, getImageGeneratorContext } from '@gi/core-renderer';
import { mmToDPT } from '@gi/units';
import { RulersMode } from '@gi/plan-simulation';

import { calculateCanvasSize, calculateTotalPages } from './utils';
import { CurrentPlanData, ImageFormat, PrintSettings } from './types';

/**
 * This is the size of the "small" window that is used for rendering to.
 * Anything beyond 3,000 x 3,000 can lead to buggy outputs on Chrome.
 */
const CANVAS_SEGMENT_SIZE: Dimensions = {
  width: 2000,
  height: 2000,
};

/**
 * Generates a blob image of the given canvas.
 * @param canvas The canvas to convert
 * @param format The image format (JPG, PNG).
 * @param quality The quality of the image (lower = more compression)
 * @returns A blob of the canvas
 */
const getCanvasAsBlob = (canvas: HTMLCanvasElement, format: ImageFormat = 'image/png', quality: number = 0.95): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob === null) {
          reject(new Error('Failed to create blob'));
        } else {
          resolve(blob);
        }
      },
      format,
      quality
    );
  });
};

/**
 * Generates a PDF of the current open plan, using the print settings provided.
 * @param engine The render engine to use
 * @param printSettings The print settings to use
 * @param onProgress Optional callback function for whenever progress is made
 * @returns A PDF ready to view or download.
 */
export const generatePDF = async (
  engine: Engine,
  { simulatedPlan, canvasPlan }: CurrentPlanData,
  printSettings: PrintSettings,
  onProgress: (percent: number, task?: string) => void = () => {}
) => {
  const gardenSize = simulatedPlan.dimensions;
  const pages = calculateTotalPages(engine, gardenSize, printSettings);
  const canvasSize = calculateCanvasSize(printSettings);

  const rulerSize = canvasPlan.rulersNode.state.values.thickness;
  const showBackgroundImage = canvasPlan.planSettingsContext.state.values.showBackgroundImages;

  // Create the PDF document. The lib uses DPT print values for positioning/sizing
  const doc = await PDFDocument.create();
  const docPaperSize = {
    width: mmToDPT(printSettings.paperSize.width),
    height: mmToDPT(printSettings.paperSize.height),
  };
  const docPaperMargin = {
    x: mmToDPT(printSettings.margins.x),
    y: mmToDPT(printSettings.margins.y),
  };

  onProgress(0, 'Starting...');

  const { generateImage, cleanUp } = getImageGeneratorContext(engine, {
    canvasWidth: CANVAS_SEGMENT_SIZE.width,
    canvasHeight: CANVAS_SEGMENT_SIZE.height,
    magnification: 1,
    resolution: 1,
  });

  try {
    canvasPlan.rulersNode.setMode(RulersMode.SHOW_ALL);

    if (printSettings.showBackgroundImage !== undefined) {
      canvasPlan.planSettingsContext.state.values.showBackgroundImages = printSettings.showBackgroundImage;
    }

    const magnification = Math.min(
      (canvasSize.width * pages.across - rulerSize * 2) / gardenSize.width,
      (canvasSize.height * pages.down - rulerSize * 2) / gardenSize.height
    );

    /**
     * Generates an image for the given page at X across Y down.
     * @param engine The engine to use
     * @param x The page X coordinate
     * @param y Page page Y coordinate
     * @param pages The total amount of pages across/down
     * @param canvasSize The width/height of the page canvas
     * @param printSettings The print settings to use
     * @returns An image blob
     */
    const generatePageImage = async (x: number, y: number) => {
      // Store a list of canvases and contexts to garbage collect at the end
      const canvasGarbage: HTMLCanvasElement[] = [];
      const contextGarbage: CanvasRenderingContext2D[] = [];
      try {
        const adjustedRulerSize = rulerSize / magnification;

        const renderWidth = gardenSize.width + adjustedRulerSize * 2;
        const renderHeight = gardenSize.height + adjustedRulerSize * 2;
        const canvasToGardenScale = Math.max(renderWidth / (canvasSize.width * pages.across), renderHeight / (canvasSize.height * pages.down));
        const startX = -adjustedRulerSize + canvasToGardenScale * canvasSize.width * x + 1 / magnification;
        const startY = -adjustedRulerSize + canvasToGardenScale * canvasSize.height * y + 1 / magnification;
        const endX = startX + canvasToGardenScale * canvasSize.width;
        const endY = startY + canvasToGardenScale * canvasSize.height;

        // Create a canvas that we'll stitch all the smaller screenshots together onto.
        const stitch = generateImage({
          frame: { left: startX, right: endX, top: startY, bottom: endY },
          magnification,
        });
        canvasGarbage.push(stitch);
        stitch.style.display = 'none';

        const stitchContext = stitch.getContext('2d');
        if (!stitchContext) {
          throw new Error('Failed to get 2D context');
        }
        contextGarbage.push(stitchContext);

        // Calculate the coordinates of the top-left of the garden on the canvas (in px)
        const gardenStartOnCanvas: Vector2 = {
          x: (-startX - adjustedRulerSize) / canvasToGardenScale,
          y: (-startY - adjustedRulerSize) / canvasToGardenScale,
        };

        // Calculate the coordinates of the bottom-right of the garden on the canvas (in px)
        const gardenEndOnCanvas: Vector2 = {
          x: (renderWidth - startX - adjustedRulerSize) / canvasToGardenScale,
          y: (renderHeight - startY - adjustedRulerSize) / canvasToGardenScale,
        };

        // Clear anything that isn't within the garden
        stitchContext.fillStyle = '#FFFFFF';
        stitchContext.fillRect(0, 0, gardenStartOnCanvas.x, stitch.height);
        stitchContext.fillRect(gardenEndOnCanvas.x, 0, stitch.width, stitch.height);
        stitchContext.fillRect(0, 0, stitch.width, gardenStartOnCanvas.y);
        stitchContext.fillRect(0, gardenEndOnCanvas.y, stitch.width, stitch.height);

        // Rotate the image if we're printing in landscape mode
        let finalImage = stitch;
        if (printSettings.orientation === 'landscape') {
          const rotated = document.createElement('canvas');
          canvasGarbage.push(rotated);
          rotated.width = stitch.height;
          rotated.height = stitch.width;

          const rotatedContext = rotated.getContext('2d');
          if (!rotatedContext) {
            throw new Error('Failed to get context');
          }
          contextGarbage.push(rotatedContext);

          rotatedContext.save();
          rotatedContext.translate(rotated.width / 2, rotated.height / 2);
          rotatedContext.rotate((90 * Math.PI) / 180);
          rotatedContext.drawImage(stitch, -stitch.width / 2, -stitch.height / 2);
          rotatedContext.restore();

          finalImage = rotated;
        }

        const blob = await getCanvasAsBlob(finalImage, printSettings.imageFormat, printSettings.imageQuality);
        return blob;
      } finally {
        // Clear any canvases to potentially clear memory.
        contextGarbage.forEach((context) => {
          context.setTransform(1, 0, 0, 1, 0, 0);
          context.clearRect(0, 0, context.canvas.width, context.canvas.height);
        });
        // Remove canvases to prevent memory leak
        canvasGarbage.forEach((canvas) => {
          canvas.remove();
        });
      }
    };

    const totalPages = pages.across * pages.down;
    /* eslint no-await-in-loop: "off" */
    for (let x = 0; x < pages.across; x++) {
      for (let y = 0; y < pages.down; y++) {
        const pageNumber = x * pages.down + y;
        onProgress(pageNumber / totalPages, `Generating page ${pageNumber + 1}/${totalPages}...`);
        const blob = await generatePageImage(x, y);

        const imageFromBlob =
          printSettings.imageFormat === 'image/jpeg' ? await doc.embedJpg(await blob.arrayBuffer()) : await doc.embedPng(await blob.arrayBuffer());

        const page = doc.addPage([docPaperSize.width, docPaperSize.height]);
        page.drawImage(imageFromBlob, {
          x: docPaperMargin.x,
          y: docPaperMargin.y,
          width: docPaperSize.width - docPaperMargin.x * 2,
          height: docPaperSize.height - docPaperMargin.y * 2,
        });
      }
    }

    onProgress(0.995, 'Saving PDF...');

    // Save and return the finished PDF.
    // Artificial delay here to allow React to handle the onProgress above first,
    // as the PDF saving operation ties up the main thread, preventing re-renders.
    return new Promise<Uint8Array>((resolve, reject) => {
      window.setTimeout(() => {
        doc
          .save()
          .then((savedDoc) => {
            onProgress(1, 'Finished');
            resolve(savedDoc);
          })
          .catch((e) => reject(e));
      }, 0.1);
    });
  } finally {
    canvasPlan.rulersNode.setMode(RulersMode.DEFAULT);
    canvasPlan.planSettingsContext.state.values.showBackgroundImages = showBackgroundImage;
    cleanUp();
  }
};
