import React, { ReactNode } from 'react';

import { TooltipParameterContentDefinition } from '../../types';

interface iProps {
  content: TooltipParameterContentDefinition;
  parameters?: ReactNode[];
}

const TooltipParameterContent = ({ content, parameters = [] }: iProps): JSX.Element | null => {
  const index = content.parameter - 1;

  if (index < 0 || index >= parameters.length) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{parameters[index]}</>;
};

export default TooltipParameterContent;
