import React, { useContext } from 'react';
import { PlannerCommandContext } from '../../planner-command-provider';

import styles from './open-plan-list.module.css';

interface iProps {
  closeMenu: () => void;
}

const PlanListControls = ({ closeMenu }: iProps): JSX.Element => {
  const { newPlan, openPlan } = useContext(PlannerCommandContext);

  return (
    <div className={styles.planListControls}>
      <button
        type='button'
        className={styles.openPlanListButton}
        onClick={() => {
          openPlan();
          closeMenu();
        }}
      >
        Open Plan
      </button>
      <button
        type='button'
        className={styles.openPlanListButton}
        onClick={() => {
          newPlan();
          closeMenu();
        }}
      >
        Create Plan
      </button>
    </div>
  );
};

export default PlanListControls;
