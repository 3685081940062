import React from 'react';

interface iProps {
  children: React.ReactNode;
  className?: string;
}

const TableBody = ({ children, className = '' }: iProps): JSX.Element => {
  return <tbody className={`table-body ${className}`}>{children}</tbody>;
};

export default TableBody;
