import React, { CSSProperties, useContext, useEffect, useRef } from 'react';

import styles from './parallax-background.module.css';
import GardenGuruContext from '../../context/garden-guru-context';

interface iProps {
  className?: string;
  style?: CSSProperties;
}

const ParallaxBackground = ({ className = '', style = {} }: iProps): JSX.Element | null => {
  const background = useRef<HTMLDivElement>(null);
  const backgroundPosition = useRef<number>(0);
  const { scrollPane } = useContext(GardenGuruContext);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollPane.current) {
        backgroundPosition.current = Math.max(scrollPane.current.scrollTop / 2, 0);
        // Set it manually rather than relying on state and re-render, for speed.
        if (background.current) {
          background.current.style.backgroundPosition = `50% calc(50% + ${backgroundPosition.current}px)`;
        }
      }
    };
    handleScroll();

    if (scrollPane.current) {
      const bgController = scrollPane.current;
      bgController.addEventListener('scroll', handleScroll);

      return () => {
        bgController.removeEventListener('scroll', handleScroll);
      };
    }

    return () => {};
  }, []);

  return (
    <div
      ref={background}
      className={`${styles.parallaxBackground} ${className}`}
      style={{
        ...style,
        backgroundPosition: `50% calc(50% + ${backgroundPosition.current}px)`,
      }}
    />
  );
};

export default ParallaxBackground;
