import React, { ReactNode } from 'react';

import './info-banner.scss';

interface iProps {
  children?: ReactNode;
  type?: 'info' | 'warning' | 'success' | 'fail';
  className?: string;
}

const InfoBanner = ({ children, type = 'info', className }: iProps): JSX.Element => {
  const classNames = ['info-banner', type];
  if (className) {
    classNames.push(className);
  }
  return (
    <div className={classNames.join(' ')}>
      <div className='info-banner-icon'>
        <span className='info-banner-icon-wrapper'>
          <i className={type === 'success' ? 'icon-ok' : 'icon-attention-alt'} />
        </span>
      </div>
      <div className='info-banner-content'>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default InfoBanner;
