import React from 'react';

import { MAX_PLANT_SPRITE_DISPLAY_LIMIT, MIN_PLANT_SPRITE_DISPLAY_LIMIT, RepeatingGraphicDisplayModes } from '@gi/constants';
import { TextFormRow } from '@gi/form';
import FormField, { FormRadioField, FormRadioFieldset, FormSection, FormValuesField, InputContainer } from '@gi/form-responsive';

interface IProps {
  plantDisplayMode: FormValuesField<number>;
  plantSpriteCountLimit: FormValuesField<number>;
  setPlantDisplayMode: (mode: number) => void;
  setPlantSpriteCountLimit: (plantCount: number | '') => void;
}

function parsePlantValue(val: string, min: number, max: number): number | '' {
  try {
    const parsedVal = parseInt(val, 10);
    if (Number.isNaN(parsedVal)) {
      return '';
    }
    return Math.max(Math.min(parsedVal, max), min);
  } catch (e) {
    return '';
  }
}

const GraphicsSettings = ({ plantDisplayMode, plantSpriteCountLimit, setPlantDisplayMode, setPlantSpriteCountLimit }: IProps): JSX.Element => {
  const onPlantDisplayModeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlantDisplayMode(parseInt(e.target.value, 10));
  };

  return (
    <FormSection heading='Plant Icons' padding={12} gap={6} className='form-section-background'>
      <TextFormRow>
        <p>This controls where plant icons display on plants in your plan and how many can be displayed, showing more plants may make plans load slower.</p>
        <p>
          Limiting the number of plants per area will significantly help slower and older devices display large plans with many plants. A value of 100 is
          default, with 50-500 being recommended.
        </p>
      </TextFormRow>
      <FormRadioFieldset legend='Display Plant Icons' fakeLabel>
        <FormRadioField label='Corners' htmlFor='graphics-settings:icon-pos-1'>
          <input
            type='radio'
            value={RepeatingGraphicDisplayModes.CORNER}
            onChange={onPlantDisplayModeChange}
            checked={plantDisplayMode.value === RepeatingGraphicDisplayModes.CORNER}
            id='graphics-settings:icon-pos-1'
          />
        </FormRadioField>
        <FormRadioField label='Corners &amp; Edges' htmlFor='graphics-settings:icon-pos-2'>
          <input
            type='radio'
            value={RepeatingGraphicDisplayModes.EDGE}
            onChange={onPlantDisplayModeChange}
            checked={plantDisplayMode.value === RepeatingGraphicDisplayModes.EDGE}
            id='graphics-settings:icon-pos-2'
          />
        </FormRadioField>
        <FormRadioField label='Corners, Edges &amp; Inside' htmlFor='graphics-settings:icon-pos-3'>
          <input
            type='radio'
            value={RepeatingGraphicDisplayModes.BLOCK}
            onChange={onPlantDisplayModeChange}
            checked={plantDisplayMode.value === RepeatingGraphicDisplayModes.BLOCK}
            id='graphics-settings:icon-pos-3'
          />
        </FormRadioField>
      </FormRadioFieldset>
      <FormField
        label='Maximum number of icons per Area'
        helpText='Must be between 4 and 10,000 (50 - 500 recommended)'
        invalid={!plantSpriteCountLimit.valid}
        desktopLayout={{ labelSize: 'auto' }}
        htmlFor='graphics-settings:sprite-count'
        errorText={plantSpriteCountLimit.errors}
      >
        <InputContainer>
          <input
            type='number'
            min={MIN_PLANT_SPRITE_DISPLAY_LIMIT}
            max={MAX_PLANT_SPRITE_DISPLAY_LIMIT}
            step='1'
            pattern='[0-9]'
            value={plantSpriteCountLimit.value}
            onChange={(e) => {
              setPlantSpriteCountLimit(parsePlantValue(e.target.value, 0, 10000));
            }}
            id='graphics-settings:sprite-count'
          />
        </InputContainer>
      </FormField>
    </FormSection>
  );
};

export default GraphicsSettings;
