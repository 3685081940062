import React, { useContext } from 'react';
import { FeaturesContext } from './features-context';

interface iProps {
  children: React.ReactNode;
  featureId: string;
}

const FeatureGate = ({ children, featureId }: iProps): null | React.ReactNode => {
  const { featureEnabled } = useContext(FeaturesContext);

  if (!featureEnabled(featureId)) {
    return null;
  }

  return children;
};

export default FeatureGate;
