export const IMAGE_FILE_TYPES = 'image/png, image/jpeg';

export function loadRemoteImage(imageSrc: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject(new Error('Failed to load imaeg'));
    };
    img.src = imageSrc;
  });
}
