import React, { ReactNode, useMemo } from 'react';

import styles from './styles.module.css';

interface iProps {
  children?: ReactNode;
  orientation: 'top' | 'bottom';
  isLinked?: boolean;
  onLinkClick?: () => void;
  buttonDisabled?: boolean;
}

const LinkedFormLabel = ({ children, orientation, isLinked, onLinkClick, buttonDisabled }: iProps): JSX.Element => {
  const button = useMemo(() => {
    if (!onLinkClick) {
      return null;
    }
    return (
      <button type='button' className={styles.linkButton} onClick={onLinkClick} disabled={buttonDisabled}>
        {isLinked ? '🔗' : ' '} {/* TODO: Replace with link/unlink icons */}
      </button>
    );
  }, [isLinked, onLinkClick, buttonDisabled]);

  const classNames = useMemo(() => {
    const _classNames: string[] = [styles.linkedFormLabel];
    _classNames.push(orientation === 'top' ? styles.upper : styles.lower);
    return _classNames.join(' ');
  }, [orientation]);

  return (
    <span className={classNames}>
      {children}
      {button}
    </span>
  );
};

export default LinkedFormLabel;
