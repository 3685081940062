import React, { useMemo } from 'react';
import { ModifierNames } from '@gi/constants';

import { CalendarRangeStrings } from '../calendar-range';
import { getPlantingCalendarStrings, PlantingCalendar, plantingCalendarIsEmpty } from '../planting-calendar';

import './planting-calendar-display.scss';

interface iTextPlantingTimeProps {
  monthRangeText: CalendarRangeStrings;
}

const TextPlantingTime = ({ monthRangeText }: iTextPlantingTimeProps): JSX.Element => {
  if (monthRangeText.allYearRound) {
    return <span className='planting-date-range'>All year round</span>;
  }

  return (
    <span className='planting-date-range'>
      <span className='planting-range-start'>{monthRangeText.start}</span>
      &nbsp;→&nbsp;
      <span className='planting-range-end'>{monthRangeText.end}</span>
    </span>
  );
};

interface iTextPlantingCalendarProps {
  plantingCalendar: PlantingCalendar;
  halfMonthOffset: number;
}

const TextPlantingCalendar = ({ plantingCalendar, halfMonthOffset }: iTextPlantingCalendarProps): JSX.Element => {
  const textPlantingCalendar = getPlantingCalendarStrings(plantingCalendar, halfMonthOffset);

  let sowElm: JSX.Element | null = null;
  let plantElm: JSX.Element | null = null;
  let harvestElm: JSX.Element | null = null;
  let emptyElm: JSX.Element | null = null;

  if (plantingCalendarIsEmpty(plantingCalendar)) {
    emptyElm = <p className='planting-calendar-text'>No available planting times</p>;
  }

  if (textPlantingCalendar.sow.length > 0) {
    sowElm = (
      <p className='planting-calendar-text'>
        <strong>Sow: </strong>
        {textPlantingCalendar.sow.map((monthRangeText, i) => [i > 0 && ', ', <TextPlantingTime key={i} monthRangeText={monthRangeText} />])}
      </p>
    );
  }

  if (textPlantingCalendar.plant.length > 0) {
    plantElm = (
      <p className='planting-calendar-text'>
        <strong>Plant: </strong>
        {textPlantingCalendar.plant.map((monthRangeText, i) => [i > 0 && ', ', <TextPlantingTime key={i} monthRangeText={monthRangeText} />])}
      </p>
    );
  }

  if (textPlantingCalendar.harvest.length > 0) {
    harvestElm = (
      <p className='planting-calendar-text'>
        <strong>Harvest: </strong>
        {textPlantingCalendar.harvest.map((monthRangeText, i) => [i > 0 && ', ', <TextPlantingTime key={i} monthRangeText={monthRangeText} />])}
      </p>
    );
  }

  const modifierComponent = useMemo(() => {
    if (plantingCalendar.isCustom) {
      return <div className='calendar-modifier-text'>[Custom]</div>;
    }

    if (plantingCalendar.modifier === null) {
      return null;
    }

    return <div className='calendar-modifier-text'>[{ModifierNames[plantingCalendar.modifier]}]</div>;
  }, [plantingCalendar.modifier, plantingCalendar.isCustom]);

  return (
    <div className='text-planting-calendar'>
      {emptyElm}
      {sowElm}
      {plantElm}
      {harvestElm}
      {modifierComponent}
    </div>
  );
};

export default TextPlantingCalendar;
