import { Store } from 'redux';

import GardenCanvasController from '../garden-canvas-controller/garden-canvas-controller';

/**
 * A very crude way of giving focus to the garden canvas container when a modal is closed,
 * this should be removed if we move away from having the canvas require focus for some keyboard shortcuts to be executed, see menu-bar.js in @gi/garden-plan-editor
 */
const canvasFocuser = (store: Store<{ modal: any }>, gardenCanvasController: GardenCanvasController) => {
  // TODO: Add ModalReducer type if modal package is ever moved to TS. ^
  let currentState = store.getState();

  const handleChange = () => {
    const previousState = currentState;
    currentState = store.getState();

    if (previousState === currentState) {
      // No change
      return;
    }

    if (currentState.modal.activeModal === null && previousState.modal.activeModal !== currentState.modal.activeModal) {
      // Modal has closed
      const { gardenCanvas } = gardenCanvasController.getInstance();
      if (gardenCanvas !== null) {
        gardenCanvas.focusContainer();
      }
    }
  };

  store.subscribe(handleChange);
};

export default canvasFocuser;
