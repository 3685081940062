/**
 * @typedef {number} LayoutType
 */
/**
 * Enum for the different layout types
 *
 * @readonly
 * @enum {LayoutType}
 */
const LayoutTypes = {
  DEFAULT: 0,
  TRADITIONAL: 1,
  RAISED_BEDS: 2,
  SFG: 3,
  CONTAINER: 4,
  POTAGER: 5,
  FIELD: 6,
  OTHER: 7,
};

export default LayoutTypes;
