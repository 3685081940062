import React from 'react';

import FormField, { FormLayout, InputContainer, unresponsivePreset } from '@gi/form-responsive';
import LoadingButton from '@gi/loading-button';

import './address-lookup.scss';

interface iProps {
  value: string;
  onChange: (value: string) => void;
  onSearch: () => void;
  loading?: boolean;
  errorText?: string;
}

const AddressLookup = ({ value, loading, errorText, onChange, onSearch }: iProps): JSX.Element => {
  return (
    <FormField label='Address Lookup' desktopLayout={{ inputSize: 'full' }} htmlFor='address-lookup' errorText={errorText} invalid={errorText !== undefined}>
      <FormLayout layoutPreset={unresponsivePreset({ layout: 'row', size: 'full' })}>
        <InputContainer size='full'>
          <input
            type='text'
            id='address-lookup'
            disabled={loading}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch();
              }
            }}
          />
        </InputContainer>
        <LoadingButton className='button button-primary address-lookup-button' loading={loading} disabled={loading} onClick={() => onSearch()}>
          Search
        </LoadingButton>
      </FormLayout>
    </FormField>
  );
};

export default AddressLookup;
