import React, { useContext, useMemo } from 'react';

import { GuruContentType } from '@gi/app-guru-types';
import { LoadingState } from '@gi/constants';

import { DirectoryPageSectionContext } from '../../directory-page/directory-page-section-provider';
import SmallPreviewLoading from '../../small-preview/items/small-preview-loading';
import PaginatedContentGridInner from './paginated-content-grid-inner';
import SmallPreviewItem from '../../small-preview/items/small-preview-item';
import GuruSection from '../../guru-section/guru-section';
import GuruSectionTitle from '../../guru-section/guru-section-title';

interface iProps {
  title: string;
  showLoadingTitle?: boolean;
  minItemHeight?: number;
  itemAspectRatio?: number;
  gap?: number;
  contentTypes?: GuruContentType[];
  hideWhenEmpty?: boolean;
}

const PaginatedContentGrid = ({
  title,
  showLoadingTitle = true,
  minItemHeight = 150,
  itemAspectRatio = 16 / 9,
  gap = 18,
  contentTypes,
  hideWhenEmpty = false,
}: iProps): JSX.Element | null => {
  const { allItems, setPageSize, setCurrentItemIndex, setMaxLoadingPlaceholders, loadNext, hasNext, loading } = useContext(DirectoryPageSectionContext);

  const content = useMemo(() => {
    return (
      <PaginatedContentGridInner
        minItemHeight={minItemHeight}
        itemAspectRatio={itemAspectRatio}
        gap={gap}
        setPageSize={setPageSize}
        setCurrentItemIndex={setCurrentItemIndex}
        setMaxLoadingPlaceholders={setMaxLoadingPlaceholders}
        loadNext={hasNext ? loadNext : undefined}
        allItems={allItems.map((item, i) => {
          switch (item.status) {
            case LoadingState.SUCCESS:
              return <SmallPreviewItem item={item.value} key={i} />;
            case LoadingState.NONE:
            case LoadingState.LOADING:
            case LoadingState.ERROR: /** TODO: Error case */
            default:
              return <SmallPreviewLoading key={i} />;
          }
        })}
      />
    );
  }, [loading, minItemHeight, itemAspectRatio, gap, allItems, contentTypes]);

  if (hideWhenEmpty && !loading && allItems.length === 0) {
    return null;
  }

  return (
    <GuruSection>
      {(loading && showLoadingTitle) || !loading ? <GuruSectionTitle>{title}</GuruSectionTitle> : null}
      {content}
    </GuruSection>
  );
};

export default PaginatedContentGrid;
