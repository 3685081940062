import { DrawingToolsContext } from './source/drawing-tools-context';
import DrawingToolsProvider from './source/drawing-tools-provider';
import DrawingTools from './source/drawing-tools';
import { DragToDrawProvider, DragToDrawContext } from './source/drag-to-draw/drag-to-draw-context';
import * as PlantFilterComponents from './source/plants/filters';
import * as GardenObjectFilterComponents from './source/garden-objects/filters';
import * as ShapeIcons from './source/shapes/icons';
import TextIcon from './source/icons/text-dark.svg';
import createPlantFilters from './source/plants/filters/create-plant-filters';
import PlantListNoResults from './source/plants/plant-list-no-results';
import { TAB_CATEGORIES, PUNCTUATION_REGEX } from './source/constants';

const PlantListComponents = {
  NoResults: PlantListNoResults,
};

export {
  DrawingTools,
  DrawingToolsContext,
  DrawingToolsProvider,
  DragToDrawContext,
  DragToDrawProvider,
  PlantFilterComponents,
  GardenObjectFilterComponents,
  ShapeIcons,
  TextIcon,
  createPlantFilters,
  PlantListComponents,
  TAB_CATEGORIES,
  PUNCTUATION_REGEX,
};
