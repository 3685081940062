import React, { useMemo } from 'react';

import { GuruContentType, GuruPreviewItem, GuruPreviewPest, GuruPreviewPlant } from '@gi/app-guru-types';

import GuruSection from '../guru-section/guru-section';
import GuruSectionTitle from '../guru-section/guru-section-title';
import SmallPreviewItem from '../small-preview/items/small-preview-item';
import ContentGridInner from './content-grid-inner';
import ContentPlantGrid from './grids/content-plant-grid';
import ContentPestGrid from './grids/content-pest-grid';

interface iProps {
  title: string;
  showLoadingTitle?: boolean;
  items?: GuruPreviewItem[];
  minItemHeight?: number;
  itemAspectRatio?: number;
  gap?: number;
  loading?: boolean;
  contentTypes?: GuruContentType[];
}

const ContentGrid = ({
  title,
  showLoadingTitle = true,
  items = [],
  minItemHeight = 150,
  itemAspectRatio = 9 / 16,
  gap = 18,
  loading = false,
  contentTypes,
}: iProps): JSX.Element => {
  const content = useMemo(() => {
    if (loading) {
      return <ContentGridInner minItemHeight={minItemHeight} itemAspectRatio={itemAspectRatio} gap={gap} loading={loading} />;
    }

    if (contentTypes && contentTypes.length === 1) {
      if (contentTypes[0] === GuruContentType.Plant) {
        return (
          <ContentPlantGrid id='placeholder' gap={gap} itemAspectRatio={itemAspectRatio} minItemHeight={minItemHeight} items={items as GuruPreviewPlant[]} />
        );
      }
      if (contentTypes[0] === GuruContentType.Pest) {
        return (
          <ContentPestGrid id='placeholder' gap={gap} itemAspectRatio={itemAspectRatio} minItemHeight={minItemHeight} items={items as GuruPreviewPest[]} />
        );
      }
    }

    return (
      <ContentGridInner minItemHeight={minItemHeight} itemAspectRatio={itemAspectRatio} gap={gap} loading={loading}>
        {items.map((item) => (
          <SmallPreviewItem key={item.id} item={item} />
        ))}
      </ContentGridInner>
    );
  }, [loading, minItemHeight, itemAspectRatio, gap, items, contentTypes]);

  return (
    <GuruSection>
      {(loading && showLoadingTitle) || !loading ? <GuruSectionTitle>{title}</GuruSectionTitle> : null}
      {content}
    </GuruSection>
  );
};

export default ContentGrid;
