import { DEFAULT_VARIETY } from '@gi/constants';
import Plant from '@gi/plant';

enum PlantLabelMode {
  PlantName = 'PlantName',
  VarietyName = 'VarietyName',
  PlantAndVarietyName = 'PlantAndVarietyName',
  Custom = 'Custom',
}

/** Returns a formatted plant label using the given label mode */
function getPlantLabel(mode: Exclude<PlantLabelMode, PlantLabelMode.Custom>, plant: Plant, variety: string): string {
  switch (mode) {
    case PlantLabelMode.PlantName:
      return plant.name;
    case PlantLabelMode.VarietyName:
      return variety === DEFAULT_VARIETY ? plant.name : variety;
    case PlantLabelMode.PlantAndVarietyName:
      return `${plant.name}, ${variety}`;
    default:
      console.error(`Unknown plant label mode: ${mode}`);
      return plant.name;
  }
}

/** Returns the label mode the given label appears to be created from */
function getPlantLabelMode(label: string, plant: Plant, variety: string): PlantLabelMode {
  switch (label) {
    case getPlantLabel(PlantLabelMode.PlantName, plant, variety):
      return PlantLabelMode.PlantName;
    case getPlantLabel(PlantLabelMode.VarietyName, plant, variety):
      return PlantLabelMode.VarietyName;
    case getPlantLabel(PlantLabelMode.PlantAndVarietyName, plant, variety):
      return PlantLabelMode.PlantAndVarietyName;
    default:
      return PlantLabelMode.Custom;
  }
}

/** Returns the next label mode in the cycle, defaulting to PlantName if custom */
function getNextPlantLabelMode(currentMode: PlantLabelMode): Exclude<PlantLabelMode, PlantLabelMode.Custom> {
  switch (currentMode) {
    case PlantLabelMode.PlantName:
      return PlantLabelMode.VarietyName;
    case PlantLabelMode.VarietyName:
      return PlantLabelMode.PlantAndVarietyName;
    case PlantLabelMode.PlantAndVarietyName:
      return PlantLabelMode.PlantName;
    default:
      return PlantLabelMode.PlantName;
  }
}

/** Returns the next sample label, based on the given current one. Defaults to PlantName if unknown */
export function getNextPlantLabel(currentLabel: string, plant: Plant, variety: string): string {
  const currentMode = getPlantLabelMode(currentLabel, plant, variety);
  const nextMode = getNextPlantLabelMode(currentMode);
  return getPlantLabel(nextMode, plant, variety);
}

/** Returns an updated plant label. Assumes variety has changed, and will try and match the mode of the previous label. */
export function updatePlantLabel(currentLabel: string, useVarietyAsDefault: boolean, plant: Plant, oldVariety: string, newVariety: string): string {
  const mode = getPlantLabelMode(currentLabel, plant, oldVariety);
  // Don't overwrite custom labels
  if (mode === PlantLabelMode.Custom) {
    return currentLabel;
  }
  // Use the variety name if the user is switching off of the default variety and has varietyForDefaultLabel enabled
  if (mode === PlantLabelMode.PlantName && oldVariety === DEFAULT_VARIETY && useVarietyAsDefault) {
    return getPlantLabel(PlantLabelMode.VarietyName, plant, newVariety);
  }
  // Default
  return getPlantLabel(mode, plant, newVariety);
}
