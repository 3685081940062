import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal, ModalType } from '@gi/modal';

const CustomiseVarietiesConfirmCloseModal = (props) => {
  return (
    <ConfirmModal
      type={ModalType.Warning}
      confirmButtonText='Close Without Saving'
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      headerText='Close Without Saving'
    >
      <p>Are you sure you want to close without saving? Any changes you have made will be lost.</p>
    </ConfirmModal>
  );
};

CustomiseVarietiesConfirmCloseModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default CustomiseVarietiesConfirmCloseModal;
