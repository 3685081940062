import React from 'react';
import { useSelector } from 'react-redux';

import { SessionSelectors } from '@gi/react-session';
import { DEFAULT_VARIETY } from '@gi/constants';

import { PlantListAreaGroup } from '../../../types/plant-list-types';
import { getInGroundString } from '../plant-list-card-in-ground-dates';

import './plant-list-card-individual-details-entry.scss';

interface iProps {
  areaGroup: PlantListAreaGroup;
  showVariety?: boolean;
}

const PlantListCardIndividualDetailsEntry = ({ areaGroup, showVariety }: iProps): JSX.Element => {
  const northernHemisphere = useSelector(SessionSelectors.getIsNorthernHemisphere);

  return (
    <>
      {showVariety ? <span>{areaGroup.variety === DEFAULT_VARIETY ? 'Default Variety' : areaGroup.variety}</span> : null}
      <span>
        {areaGroup.planPlant.isSquareFoot ? <span className='plant-list-card-details-sfg-tag'>SFG</span> : null} {areaGroup.planPlant.labelText}
      </span>
      <span>{areaGroup.count}</span>
      <span>
        {areaGroup.planPlant.inGroundAll
          ? 'All Year Round'
          : getInGroundString(areaGroup.planPlant.inGroundStart, areaGroup.planPlant.inGroundEnd, northernHemisphere)}
      </span>
    </>
  );
};

export default PlantListCardIndividualDetailsEntry;
