import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import Plant from '@gi/plant';
import { SupplierCatalogueUtils } from '@gi/supplier';
import { UserPlantVarietySetShape, UserPlantVarietySetUtils } from '@gi/user';
import { lexographicSort } from '@gi/utils';
import { ResourceContext } from '@gi/resource-provider';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles.ts';

import { ModalPaneContainer, ModalPane, ModalPaneContent, ModalTabContent, ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';

import FormField, { InputContainer } from '@gi/form-responsive';

import { DEFAULT_VARIETY } from '@gi/constants';

const FilterTypes = {
  ALL: 'ALL',
  USER: 'USER',
  SUPPLIER: 'SUPPLIER',
};

const getFilterOption = (supplier) => {
  return { type: FilterTypes.SUPPLIER, value: supplier.ID, label: `${supplier.name}` };
};

const lexographicSupplierSort = (a, b) => {
  return lexographicSort(a.name, b.name);
};

// const renderVarietySupplier = (varietySupplierData, i) => {
//   return (
//     <Fragment key={varietySupplierData.supplierID}>
//       {i > 0 && ', '}
//       <span className='supplier-name'>
//         {suppliers.getSupplier(varietySupplierData.supplierID).name}
//       </span>
//     </>
//   );
// };

const renderVariety = ({ varietyName, isCustom }) => {
  return (
    <span key={varietyName} className={`variety-name ${isCustom ? 'custom-variety' : ''}`}>
      {varietyName === DEFAULT_VARIETY ? '- (Default Variety)' : varietyName}
    </span>
  );
};

const renderNoSuppliers = (userPlantVarieties) => {
  if (userPlantVarieties === null) {
    return <div className='no-suppliers-message'>We don&apos;t have any variety availability from seed suppliers for your country.</div>;
  }
  if (userPlantVarieties.size === 0) {
    return (
      <div className='no-suppliers-message'>
        We don&apos;t have any variety availability from seed suppliers for your country and you have no custom varieties. You can create custom varieties when
        editing a plant.
      </div>
    );
  }

  return (
    <div className='no-suppliers-message'>
      We don&apos;t have any variety availability from seed suppliers for your country, all shown varieties are your custom varities.
    </div>
  );
};

/**
 * @param {{
 *  plant: Plant,
 *  supplierCatalogue: SupplierCatalogue,
 *  userPlantVarieties?: import('@gi/user').UserPlantVarietySet | null
 * }}
 */
const PlantVarietiesTab = ({ plant, supplierCatalogue, userPlantVarieties = null }) => {
  const { suppliers } = useContext(ResourceContext);

  const sourceFilterOptions = useMemo(() => {
    return [
      {
        type: FilterTypes.USER,
        value: FilterTypes.USER,
        label: 'Your Customized Varieties',
        disabled: userPlantVarieties === null || UserPlantVarietySetUtils.getByPlantCode(userPlantVarieties, plant.code).length === 0,
      },
      {
        type: FilterTypes.ALL,
        value: FilterTypes.ALL,
        label: 'All Sources',
      },
      ...suppliers.IDs.map((id) => suppliers.byID[id])
        .filter((supplier) => SupplierCatalogueUtils.supplierHasPlant(supplierCatalogue, supplier.ID, plant.code))
        .sort(lexographicSupplierSort)
        .map(getFilterOption),
    ];
  }, [plant, supplierCatalogue, userPlantVarieties]);

  const [sourceFilter, setSourceFilter] = useState(sourceFilterOptions[1]);

  const varietyFilter = useMemo(() => {
    console.debug(`Filtering Plant Varieties tab by: ${sourceFilter.type}`);
    if (sourceFilter.type === FilterTypes.ALL) {
      return () => true;
    }

    if (sourceFilter.type === FilterTypes.USER) {
      return (varietyName) => UserPlantVarietySetUtils.hasVariety(userPlantVarieties, plant.code, varietyName);
    }

    return (varietyName) => {
      return SupplierCatalogueUtils.hasCatalogueVariety(supplierCatalogue, sourceFilter.value, plant.code, varietyName);
    };
  }, [sourceFilter, plant, supplierCatalogue, userPlantVarieties]);

  const plantVarietyList = useMemo(() => {
    const varietyNameSet = new Set([
      ...SupplierCatalogueUtils.getAllVarietiesForPlant(supplierCatalogue, plant.code),
      ...(userPlantVarieties === null
        ? []
        : UserPlantVarietySetUtils.getByPlantCode(userPlantVarieties, plant.code).map((userPlantVariety) => userPlantVariety.name)),
    ]);

    return [...varietyNameSet].filter(varietyFilter).sort(lexographicSort);
  }, [varietyFilter, plant, supplierCatalogue, userPlantVarieties]);

  const allVarietyData = plantVarietyList.map((varietyName) => {
    return {
      varietyName,
      isCustom: UserPlantVarietySetUtils.hasVariety(userPlantVarieties, plant.code, varietyName),
    };
  });

  const noSuppliers = supplierCatalogue.supplierIDs.length === 0;
  return (
    <ModalTabContent className='plant-varieties-tab' tabID='plant-varieties'>
      <ModalPaneContainer>
        <ModalPane className='plant-information-pane'>
          <ModalPaneContent>
            <ModalPaneSection className='plant-information-section'>
              <ModalPaneSectionContent>
                {noSuppliers ? (
                  renderNoSuppliers(userPlantVarieties)
                ) : (
                  <FormField label='Show Varieties From' htmlFor='supplier-dropdown' desktopLayout={{ labelSize: 160 }}>
                    <InputContainer>
                      <ReactSelect
                        styles={DEFAULT_SELECT_STYLES}
                        options={sourceFilterOptions}
                        value={sourceFilter}
                        onChange={setSourceFilter}
                        menuPortalTarget={document.body}
                        isOptionDisabled={(option) => option.disabled}
                        isSearchable={false}
                        inputId='supplier-dropdown'
                      />
                    </InputContainer>
                  </FormField>
                )}
              </ModalPaneSectionContent>
              <ModalPaneSectionContent>
                <div className='variety-list'>{allVarietyData.map(renderVariety)}</div>
              </ModalPaneSectionContent>
            </ModalPaneSection>
          </ModalPaneContent>
        </ModalPane>
      </ModalPaneContainer>
    </ModalTabContent>
  );
};

PlantVarietiesTab.propTypes = {
  plant: PropTypes.instanceOf(Plant).isRequired,
  supplierCatalogue: PropTypes.object.isRequired,
  userPlantVarieties: UserPlantVarietySetShape,
};

export default PlantVarietiesTab;
