import React, { useContext } from 'react';
import { AutoSizer } from 'react-virtualized';

import PlantListItem from './plant-list-item';
import { DrawingToolsContext } from '../drawing-tools-context';
import PlantListNoResults from './plant-list-no-results';
import DragToDrawControlledList from '../drag-to-draw/drag-to-draw-controlled-list';

const PlantList = () => {
  const { filteredPlants } = useContext(DrawingToolsContext);

  const rowRenderer = ({ key, index, style }) => {
    const plant = filteredPlants[index];
    // const searchResult = this.props.searchResults.getResult(plant.code);
    return <PlantListItem key={key} style={style} plant={plant} />;
  };

  const renderEmpty = () => {
    return (
      <div className='empty-drawing-tools-list-warning'>
        <PlantListNoResults />
      </div>
    );
  };

  const renderList = () => {
    return (
      <AutoSizer>
        {({ width, height }) => (
          <ol className='drawing-tools-item-list'>
            <DragToDrawControlledList
              scrollToIndex={0}
              width={width}
              height={height}
              rowCount={filteredPlants.length}
              rowHeight={60}
              rowRenderer={rowRenderer}
              // This component doesn't use these props but we supply them in order to force a render on change
              // selectedPlantCode={this.props.selectedPlantCode}
              // searchResults={this.props.searchResults}
              // pendingFavouritePlants={this.props.pendingFavouritePlants}
              // favouritePlants={this.props.favouritePlants}
              dependantOnFilteredPlants={filteredPlants}
            />
          </ol>
        )}
      </AutoSizer>
    );
  };

  return <div className='item-list-container'>{filteredPlants.length === 0 ? renderEmpty() : renderList()}</div>;
};

export default PlantList;
