import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DisplayModeSelectors } from '@gi/display-mode-provider';
import FormField, { FORM_FIELD_PRESETS, createFormValues } from '@gi/form-responsive';
import { LocalSettingsActionCreators, LocalSettingsSelectors } from '@gi/local-settings';
import Modal, {
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalHeader,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalPane,
  ModalPaneContainer,
  ModalPaneContent,
} from '@gi/modal';

import InteractionModePreview from './interaction-mode-preview';

import styles from './touch-drag-help-modal.module.css';

type TouchDragHelpModalFormState = {
  touchDragRequiresSelection: boolean;
  hideTouchDragHelpNotifications: boolean;
};

interface iProps {
  onClose: () => void;
}

const TouchDragHelpModal = ({ onClose }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const touchMode = useSelector(DisplayModeSelectors.getTouchMode);
  const touchDragRequiresSelection = useSelector(LocalSettingsSelectors.getTouchDragRequiresSelection);
  const hideTouchDragHelpNotifications = useSelector(LocalSettingsSelectors.getHideTouchDragHelpNotifications);

  const [formValues, setFormValues] = useState(
    createFormValues<TouchDragHelpModalFormState>({
      hideTouchDragHelpNotifications: { value: hideTouchDragHelpNotifications },
      touchDragRequiresSelection: { value: touchDragRequiresSelection },
    })
  );

  const handleClose = useCallback(() => {
    if (formValues.values.touchDragRequiresSelection !== touchDragRequiresSelection) {
      dispatch(LocalSettingsActionCreators.setTouchDragRequiresSelection(formValues.values.touchDragRequiresSelection));
    }
    if (formValues.values.hideTouchDragHelpNotifications !== hideTouchDragHelpNotifications) {
      dispatch(LocalSettingsActionCreators.setHideTouchDragHelpNotifications(formValues.values.touchDragRequiresSelection));
    }
    onClose();
  }, [formValues, touchDragRequiresSelection, hideTouchDragHelpNotifications, onClose]);

  return (
    <Modal closeRequest={handleClose}>
      <ModalContent>
        <ModalCloseButton onClick={handleClose} />
        {/* Header */}
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Dragging items using Touch</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        {/* Content */}
        <ModalPaneContainer className={styles.modal}>
          <ModalPane className={styles.modalContent}>
            <ModalPaneContent>
              <div className={styles.preview} aria-label='Preview of the Garden Planner using Touch Drag' role='presentation'>
                <InteractionModePreview touchMode={touchMode} useFinger className={styles.previewAnimation} />
              </div>
              <p>To drag items when using touch, you must first select the item you want to drag by tapping on it.</p>
              <p>Then, when the item is selected (indicated by the blue outline), you can drag any part of the item to move it.</p>
              <p>You can also hold onto the item for 1 second, then start dragging when the outline appears.</p>
              <p>This can be changed at any time by enabling/disabling &quot;Touch-drag requires selection&quot; in settings.</p>
              <FormField
                htmlFor='touch-drag-help:touch-drag-requires-selection'
                label='Touch-drag requires selection'
                layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
              >
                <input
                  type='checkbox'
                  id='touch-drag-help:touch-drag-requires-selection'
                  checked={formValues.values.touchDragRequiresSelection}
                  onChange={(e) => setFormValues(formValues.setValue('touchDragRequiresSelection', { value: e.target.checked }))}
                />
              </FormField>
              <p className={styles.settingsPath}>
                <span>
                  <i className='icon-cog' /> Settings
                </span>
                <i className='icon-right-dir' />
                <span>Device</span>
                <i className='icon-right-dir' />
                <span>Interaction</span>
                <i className='icon-right-dir' />
                <span>Touch-drag requires selection</span>
              </p>
            </ModalPaneContent>
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <FormField htmlFor='touch-drag-help:hide-notification' label="Don't show again" layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
              <input
                type='checkbox'
                id='touch-drag-help:hide-notification'
                checked={formValues.values.hideTouchDragHelpNotifications}
                onChange={(e) =>
                  setFormValues(
                    formValues.setValue('hideTouchDragHelpNotifications', {
                      value: e.target.checked,
                    })
                  )
                }
              />
            </FormField>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-primary' onClick={handleClose}>
                Dismiss
              </button>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TouchDragHelpModal;
