import { GardenItemType, GardenObjectType, ItemNodeType, ShapeType } from '@gi/constants';
import { PlantFamilyTypes } from '@gi/plant-families';

import PlantNode from '../canvas-plan/nodes/plant/plant-node';
import SFGPlantNode from '../canvas-plan/nodes/plant/sfg-plant-node';
import GardenObjectNode from '../canvas-plan/nodes/garden-objects/garden-object-node';
import ShapeNode from '../canvas-plan/nodes/shapes/shape-node';
import TextNode from '../canvas-plan/nodes/text/text-node';
import PlantLabelNode from '../canvas-plan/nodes/plant/plant-label-node';

/**
 * NOTE: Probably best to keep these types as basic as possible, as they'll be committed to redux.
 * Only add things that may be useful for something looking at the selection to need to know
 *  at a glance without needing to look up the node. Positions are unlikely to be useful to
 *  something reading the state from redux.
 */

/** Basic identifying information common to all nodes */
export interface SerialisedGardenItemNode<TType extends ItemNodeType = ItemNodeType, TItemType extends GardenItemType = GardenItemType> {
  id: number;
  type: TType;
  itemType: TItemType;
}

/** Basic identifying information about a plant node */
export interface SerialisedPlantNode extends SerialisedGardenItemNode<ItemNodeType.Plant, GardenItemType.Plant> {
  sfg: boolean;
  plantCode: string;
  plantFamily: PlantFamilyTypes;
}

/** Basic identifying information about a plant label node */
export interface SerialisedPlantLabelNode extends SerialisedGardenItemNode<ItemNodeType.PlantLabel, GardenItemType.Plant> {}

/** Basic identifying information about a garden object node */
export interface SerialisedGardenObjectNode extends SerialisedGardenItemNode<ItemNodeType.GardenObject, GardenItemType.GardenObject> {
  subtype: GardenObjectType;
  code: string;
}

/** Basic identifying information about a shape node */
export interface SerialisedShapeNode extends SerialisedGardenItemNode<ItemNodeType.Shape, GardenItemType.Shape> {
  subtype: ShapeType;
}

/** Basic identifying information about a text node */
export interface SerialisedTextNode extends SerialisedGardenItemNode<ItemNodeType.Text, GardenItemType.Text> {}

/**
 * A serialised version of the CanvasInteractionGroup.
 * Contains information about all the nodes in the group (selection).
 */
export interface SerialisedCanvasInteractionGroup {
  plants: SerialisedPlantNode[];
  plantLabels: SerialisedPlantLabelNode[];
  gardenObjects: SerialisedGardenObjectNode[];
  shapes: SerialisedShapeNode[];
  text: SerialisedTextNode[];
  backgroundImage: boolean;
  all: SerialisedGardenItemNode[];
}

/** Utilities for converting nodes into serialised versions that can be stored in redux */
export const NodeSerialisationUtils = {
  /**
   * Returns basic serialisable data about the given plant node.
   * @param plantNode The plant node to convert
   * @returns Basic identifying information
   */
  serialisePlantNode(plantNode: PlantNode | SFGPlantNode): SerialisedPlantNode {
    return {
      type: ItemNodeType.Plant,
      itemType: GardenItemType.Plant,
      id: plantNode.id,
      plantCode: plantNode.plant.code,
      plantFamily: plantNode.plant.familyID,
      sfg: plantNode.sfg,
    };
  },

  /**
   * Returns basic serialisable data about the given plant label node.
   * @param plantLabelNode The plant label node to convert
   * @returns Basic identifying information
   */
  serialisePlantLabelNode(plantLabelNode: PlantLabelNode): SerialisedPlantLabelNode {
    return {
      type: ItemNodeType.PlantLabel,
      itemType: GardenItemType.Plant,
      id: plantLabelNode.id,
    };
  },

  /**
   * Returns basic serialisable data about the given garden object node.
   * @param gardenObjectNode The garden object node to convert
   * @returns Basic identifying information
   */
  serialiseGardenObjectNode(gardenObjectNode: GardenObjectNode): SerialisedGardenObjectNode {
    return {
      type: ItemNodeType.GardenObject,
      itemType: GardenItemType.GardenObject,
      id: gardenObjectNode.id,
      code: gardenObjectNode.gardenObject.code,
      subtype: gardenObjectNode.gardenObject.shape.type,
    };
  },

  /**
   * Returns basic serialisable data about the given shape node.
   * @param shapeNode The shape node to convert
   * @returns Basic identifying information
   */
  serialiseShapeNode(shapeNode: ShapeNode): SerialisedShapeNode {
    return {
      type: ItemNodeType.Shape,
      itemType: GardenItemType.Shape,
      id: shapeNode.id,
      subtype: shapeNode.subtype,
    };
  },

  /**
   * Returns basic serialisable data about the given text node.
   * @param textNode The text node to convert
   * @returns Basic identifying information
   */
  serialiseTextNode(textNode: TextNode): SerialisedTextNode {
    return {
      type: ItemNodeType.Text,
      itemType: GardenItemType.Text,
      id: textNode.id,
    };
  },
};

/** Utilities for querying the SerialisedCanvasInteractionGroup easier */
export const SerialisedCanvasInteractionGroupUtils = {
  /**
   * Returns true if there are no items in the group.
   * @param group The serialised group
   * @returns True if there is nothing in the group
   */
  isEmpty(group: SerialisedCanvasInteractionGroup): boolean {
    return group.all.length === 0;
  },

  /**
   * Returns true if there is exactly 1 item in the group
   * @param group The serialised group
   * @returns True if there is 1 item in the group
   */
  isSingleItem(group: SerialisedCanvasInteractionGroup): boolean {
    return group.all.length === 1;
  },

  /**
   * Returns the first item in the group, or null if empty.
   * @param group The serialised group
   * @returns The first item in the group, or null
   */
  getItem(group: SerialisedCanvasInteractionGroup): SerialisedGardenItemNode | null {
    return group.all[0] ?? null;
  },

  /**
   * If the selection is a single plant item, returns that plant. Otherwise returns null.
   * @param group The serialised group
   * @returns The single plant node, or null if empty/>1
   */
  getPlantNode(group: SerialisedCanvasInteractionGroup): SerialisedPlantNode | null {
    if (!SerialisedCanvasInteractionGroupUtils.isSingleItem(group)) {
      return null;
    }
    if (group.plants.length > 0) {
      return group.plants[0];
    }
    return null;
  },

  /**
   * Returns a list of the unique plant codes across all the plants in the selection (excludes labels)
   * @param group The serialised group
   * @returns A list of plant codes
   */
  getPlantCodes(group: SerialisedCanvasInteractionGroup): string[] {
    const plantCodes: Set<string> = new Set();
    for (let i = 0; i < group.plants.length; i++) {
      plantCodes.add(group.plants[i].plantCode);
    }
    return Array.from(plantCodes);
  },

  /**
   * Returns a list of the unique plant families across all the plants in the selection (excludes labels)
   * @param group The serialised group
   * @returns A list of unique plant families
   */
  getPlantFamilies(group: SerialisedCanvasInteractionGroup): PlantFamilyTypes[] {
    const plantFamilies: Set<PlantFamilyTypes> = new Set();
    for (let i = 0; i < group.plants.length; i++) {
      plantFamilies.add(group.plants[i].plantFamily);
    }
    return Array.from(plantFamilies);
  },
};
