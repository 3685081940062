import { Suppliers, SuppliersUtils } from '../suppliers';
import supplierParser from './supplier-parser';

/**
 * Parses the object from a suppliers json file and returns a Suppliers object
 *
 * @param {object} suppliersObj
 * @returns {Suppliers}
 */
function suppliersFromAPI(suppliersObj): Suppliers {
  const suppliers = SuppliersUtils.create();

  for (let i = 0; i < suppliersObj.suppliers.length; i++) {
    SuppliersUtils.addMut(suppliers, supplierParser(suppliersObj.suppliers[i]));
  }

  return suppliers;
}

export default suppliersFromAPI;
