import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import NewPlanModal from '@gi/new-plan-modal';

import { withResources } from '@gi/resource-provider';

import { closeNewPlanModal } from '../modal-action-creators.ts';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeNewPlanModal,
    },
    dispatch
  );
};

export default withResources(connect(undefined, mapDispatchToProps)(NewPlanModal));
