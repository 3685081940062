import React from 'react';
import ReactSelect from 'react-select';

import { ModalPaneSection, ModalPaneSectionHeader } from '@gi/modal';
import FormField, { FormLayout, FormValuesField, unresponsivePreset } from '@gi/form-responsive';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

interface TextQualityOption {
  value: number;
  label: string;
}

const textQualityOptions: TextQualityOption[] = [
  {
    value: 1,
    label: 'Low',
  },
  {
    value: 2,
    label: 'Medium',
  },
  {
    value: 4,
    label: 'High',
  },
];

interface iProps {
  textQuality: FormValuesField<number>;
  setTextQuality: (textQuality: number) => void;
}

const QualitySettings = ({ textQuality, setTextQuality }: iProps) => {
  return (
    <ModalPaneSection className='modal-form-section'>
      <ModalPaneSectionHeader>Quality</ModalPaneSectionHeader>
      <FormLayout layoutPreset={unresponsivePreset({ layout: 'column', gap: 12 })}>
        <FormField
          label='Text Quality'
          helpText='This controls the quality of text and labels on your plan. Lower quality may improve stability and performance on some devices, but will cause text to appear less crisp.'
          desktopLayout={{ labelSize: 'auto' }}
          htmlFor='text-quality'
        >
          <ReactSelect<TextQualityOption>
            options={textQualityOptions}
            inputId='text-quality'
            value={textQualityOptions.find((option) => option.value === textQuality.value)}
            onChange={(scale) => setTextQuality(scale?.value ?? 4)}
            styles={DEFAULT_SELECT_STYLES}
            isSearchable={false}
          />
        </FormField>
      </FormLayout>
    </ModalPaneSection>
  );
};

export default QualitySettings;
