import { CanvasActionTypes } from '@gi/react-garden-canvas';
import { TransferPlanActionTypes } from '@gi/react-transfer-plan';
import { User, UserUtils } from '@gi/user';
import networkService from '@gi/gi-network/source/network-service';
import SessionActionTypes from './session-action-types';

export type SessionReducerState = {
  user: null | User;
  savingUser: null | User;
  restoring: boolean;
  restored: boolean;
  loginErrors: string[];
  restoreFailed: boolean;
};

const INITIAL_STATE = {
  user: null,
  savingUser: null,
  restoring: false,
  restored: false,
  loginErrors: [],
  restoreFailed: false,
};

/**
 * When a user is returned to the API it only has the fields set we sent,
 * as the plans property is sent as an empty array (as it's read-only)
 * we do not get it back, therefore we need to add back user-plans from our currently
 * stored user
 *
 * @param {User} currentUser
 * @param {User} updatedUser
 * @returns {User}
 */
function mergeUserFromSave(currentUser: null | User, updatedUser: User): User {
  if (currentUser === null) {
    return updatedUser;
  }

  if (currentUser.ID === updatedUser.ID && currentUser.ID !== null) {
    return { ...updatedUser, plans: currentUser.plans };
  }

  console.warn('Attempted to merge user after save but users were different');
  return updatedUser;
}

const sessionReducer = (state: SessionReducerState = INITIAL_STATE, action) => {
  switch (action.type) {
    case SessionActionTypes.RESTORE_FAILED:
      return {
        ...state,
        restoreFailed: true,
        restored: true,
        restoring: false,
        user: null,
      };
    case SessionActionTypes.UPDATE_USER_FROM_SAVE:
      return {
        ...state,
        user: mergeUserFromSave(state.user, action.user),
        savingUser: null,
      };
    case SessionActionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
        savingUser: null,
      };
    case SessionActionTypes.START_SAVE_USER:
      return {
        ...state,
        savingUser: action.user,
      };
    case SessionActionTypes.END_SAVE_USER:
      return {
        ...state,
        savingUser: null,
      };
    case SessionActionTypes.START_RESTORE:
      return {
        ...state,
        restoring: true,
      };
    case SessionActionTypes.RESTORE:
      return {
        ...state,
        user: action.user,
        restored: true,
        restoring: false,
        restoreFailed: false,
      };
    case SessionActionTypes.LOGOUT:
      return {
        ...state,
        user: null,
      };
    case CanvasActionTypes.CREATE_PLAN_SUCCESS:
      return {
        ...state,
        user: state.user === null ? null : UserUtils.updateUserPlansFromPlan(state.user, action.plan),
      };
    case CanvasActionTypes.SAVE_PLAN_SUCCESS:
    case CanvasActionTypes.UPDATE_PLAN:
      return {
        ...state,
        user: state.user === null ? null : UserUtils.updateUserPlansFromPlan(state.user, action.plan),
      };
    case CanvasActionTypes.DELETE_PLAN_SUCCESS:
      return {
        ...state,
        user: state.user === null ? null : UserUtils.removeUserPlanFromPlans(state.user, action.planID),
      };
    case TransferPlanActionTypes.TRANSFER_PLAN_SUCCESS:
      if (action.copy) {
        return state;
      }

      return {
        ...state,
        user: state.user === null ? null : UserUtils.removeUserPlanFromPlans(state.user, action.planID),
      };
    default:
      return state;
  }
};

const sessionReducerWithMiddleware = (state: SessionReducerState = INITIAL_STATE, action) => {
  const newState = sessionReducer(state, action);
  networkService.setUser(
    state.user !== null
      ? {
          id: state.user.ID,
          ticket: state.user.postAuthTicket,
          token: state.user.authToken,
        }
      : null
  );
  return newState;
};

export default sessionReducerWithMiddleware;
