import React from 'react';
import { PlanPlant } from '@gi/plan';

interface iProps {
  planPlant: PlanPlant;
}

const InsetPlantLabelCell = ({ planPlant }: iProps): JSX.Element | null => {
  return (
    <td className='inset-td inset-plant-label-td'>
      {planPlant.isSquareFoot ? <span className='sfg-tag'>SFG</span> : null} {planPlant.labelText}
    </td>
  );
};

export default InsetPlantLabelCell;
