import React from 'react';
import { NavLink } from 'react-router-dom';

import { DirectoryPageRoute } from '@gi/app-guru-common';

import styles from './navigation-bar.module.css';

interface iProps {
  route: DirectoryPageRoute;
  isLogo?: boolean;
  children: React.ReactNode;
}

const NavigationBarTab = ({ route, children, isLogo }: iProps): JSX.Element => {
  return (
    <div className={`${styles.navigationTab} ${isLogo ? styles.sizeAuto : ''}`}>
      <NavLink to={route.route} className={({ isActive }) => `${styles.navigationTabLink} ${isActive && !isLogo ? styles.activeNavigationTabLink : ''}`}>
        {children}
      </NavLink>
    </div>
  );
};

export default NavigationBarTab;
