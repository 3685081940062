import { StateProperties } from './state-properties';
import type { State } from './state';
import type { AnyStateDef, StatePropertiesDef } from './state-types';

export type StateValidatorCallback<TState extends AnyStateDef> = (state: State<TState>) => void;

/**
 * Basic callback to run whenever certain properties change to keep them valid.
 * Cannot watch otherStates.
 */
export class StateValidator<TState extends AnyStateDef> {
  #state: State<TState>;
  get state() {
    return this.#state;
  }

  #properties: StateProperties<TState>;
  get properties() {
    return this.#properties;
  }

  #callback: StateValidatorCallback<TState>;

  constructor(
    state: State<TState>,
    callback: StateValidatorCallback<TState>,
    properties?: Omit<StatePropertiesDef<TState>, 'otherStates'>,
    runImmediately: boolean = true
  ) {
    this.#state = state;
    this.#properties = new StateProperties(state, properties ?? {});
    this.#properties.isValidator = true;
    this.#callback = callback;
    if (runImmediately) {
      this.runCallback();
    }
  }

  onStateUpdate() {
    if (this.#properties.hasChanged()) {
      this.runCallback();
    }
  }

  private runCallback() {
    this.#callback(this.#state);
  }
}
