import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { GuruContentType, GuruDirectoryPageId, GuruDirectoryPageIds, GuruPages } from '@gi/app-guru-types';
import { Application, getAbsoluteApplicationLink } from '@gi/garden-platform-navigation';

export function createPagePath(slug: string) {
  return `/${slug}`;
}

export function createPageRoute(slug: string) {
  return `${getAbsoluteApplicationLink(Application.Guru)}${createPagePath(slug)}`;
}

// Directory Pages

export type DirectoryPageRoute = {
  id: GuruDirectoryPageId;
  slug: string;
  desktopContent: string | JSX.Element;
  mobileContent: string | JSX.Element;
  path: string;
  route: string;
};

function createDirectoryPageRoute(
  id: GuruDirectoryPageId,
  slug: string,
  desktopContent: string | JSX.Element,
  mobileContent: string | JSX.Element
): DirectoryPageRoute {
  return {
    id,
    slug,
    desktopContent,
    mobileContent,
    path: createPagePath(slug),
    route: createPageRoute(slug),
  };
}

export const directoryPageRoutes: Record<GuruDirectoryPageId, DirectoryPageRoute> = {
  [GuruDirectoryPageId.RootDirectory]: createDirectoryPageRoute(GuruDirectoryPageId.RootDirectory, 'home', 'Home', 'Home'),
  [GuruDirectoryPageId.PlantDirectory]: createDirectoryPageRoute(GuruDirectoryPageId.PlantDirectory, 'plants', 'Plants', 'Plants'),
  [GuruDirectoryPageId.PestDirectory]: createDirectoryPageRoute(GuruDirectoryPageId.PestDirectory, 'pests', 'Pests', 'Pests'),
  [GuruDirectoryPageId.ArticleDirectory]: createDirectoryPageRoute(GuruDirectoryPageId.ArticleDirectory, 'articles', 'Articles', 'Articles'),
  [GuruDirectoryPageId.VideoDirectory]: createDirectoryPageRoute(GuruDirectoryPageId.VideoDirectory, 'videos', 'Videos', 'Videos'),
  [GuruDirectoryPageId.BookmarkDirectory]: createDirectoryPageRoute(
    GuruDirectoryPageId.BookmarkDirectory,
    'bookmarks',
    <i className='icon-bookmark' />,
    'Bookmarks'
  ),
  [GuruDirectoryPageId.SearchDirectory]: createDirectoryPageRoute(GuruDirectoryPageId.SearchDirectory, 'search', <i className='icon-search' />, 'Search'),
};

export const ContentTypeDirectoryMap: Record<GuruContentType, GuruDirectoryPageId> = {
  [GuruContentType.Article]: GuruDirectoryPageId.ArticleDirectory,
  [GuruContentType.Plant]: GuruDirectoryPageId.PlantDirectory,
  [GuruContentType.Pest]: GuruDirectoryPageId.PestDirectory,
  [GuruContentType.Video]: GuruDirectoryPageId.VideoDirectory,
};

export function createDirectoryPagePath(directoryPageId: GuruDirectoryPageId) {
  return `${directoryPageRoutes[directoryPageId].slug}`;
}

export function createDirectoryPageURL(directoryPageId: GuruDirectoryPageId) {
  return `${getAbsoluteApplicationLink(Application.Guru)}/${createDirectoryPagePath(directoryPageId)}`;
}

// Content Pages

export function createContentSublink(contentId: string) {
  return `/${contentId}`;
}

export function createContentPath(typeId: GuruContentType, contentId: string) {
  return `${createDirectoryPagePath(ContentTypeDirectoryMap[typeId])}${createContentSublink(contentId)}`;
}

export function createContentURL(typeId: GuruContentType, contentId: string) {
  return `${createDirectoryPageURL(ContentTypeDirectoryMap[typeId])}${createContentSublink(contentId)}`;
}

/**
 * React hook to return the currently active directory within
 */
export const useActiveDirectory = (): null | GuruDirectoryPageId => {
  const location = useLocation();

  for (let i = 0; i < GuruDirectoryPageIds.length; i++) {
    if (matchPath(`${createDirectoryPageURL(GuruDirectoryPageIds[i])}/*`, location.pathname)) {
      return GuruDirectoryPageIds[i];
    }
  }

  return null;
};

/**
 * Uses the provided GuruPages to return an internal GuruDirectoryPageId from the given API-provided
 * page id
 *
 * @param guruPages Page definition data loaded from the Guru API
 * @param apiPageId API-provided numeric page id
 * @returns {GuruDirectoryPageId}
 */
export function getGuruPageId(guruPages: GuruPages, apiPageId: number): GuruDirectoryPageId {
  if (guruPages.numericIdMap[apiPageId]) {
    return guruPages.numericIdMap[apiPageId];
  }

  throw new Error(`Guru page Id not found: ${apiPageId}`);
}

/**
 * Uses the provided GuruPages to return an API-provided page id from the Given GuruDirectoryPageId
 *
 * @param guruPages Page definition data loaded from the Guru API
 * @param pageId GuruDirectoryPageId
 * @returns {number} API-provided page id
 */
export function getAPIPageId(guruPages: GuruPages, pageId: GuruDirectoryPageId): number {
  if (guruPages.pageIdMap[pageId]) {
    return guruPages.pageIdMap[pageId];
  }

  throw new Error(`Guru API page id not found: ${pageId}`);
}

/**
 * A composedPageId consists of the page id and query parameters, it exists in the same form as
 * the end of a URL
 *
 * This function creates a composedPageId from an internal GuruDirectoryPageId and the provided param string
 * for the page
 *
 * @param guruPages Page definition data loaded from the Guru API
 * @param pageId GuruDirectoryPageId representing a directory page of the Garden Guru
 * @param paramString string of url params in their correct format such as `q=carrot&c=123456`
 * @returns {string} composedPageId
 */
export function getComposedPageId(guruPages: GuruPages, pageId: GuruDirectoryPageId, paramString: string = ''): string {
  const apiPageId = getAPIPageId(guruPages, pageId);

  if (!paramString) {
    return apiPageId.toString();
  }

  return `${apiPageId}?${paramString}`;
}

/**
 * Extracts the API-provided id from the composedPageId and converts it to an internal GuruDirectoryPageId
 *
 * @param guruPages Page definition data loaded from the Guru API
 * @param composedPageId string containing API-provided page id and query parameters as a URL
 * @returns {GuruDirectoryPageId}
 */
export function getPageIdFromComposedPageId(guruPages: GuruPages, composedPageId: string): GuruDirectoryPageId {
  const apiPageId = parseInt(composedPageId.split('?')[0], 10);

  if (Number.isNaN(apiPageId)) {
    throw new Error(`Unable to parse composed page Id: ${composedPageId}`);
  }

  return getGuruPageId(guruPages, apiPageId);
}
