import { CameraNode, ContentRootNode, StatefulContextComponent } from '@gi/core-renderer';
import { StateDef } from '@gi/state';

import { DEFAULT_GARDEN_CANVAS_SETTINGS, type GardenCanvasSettings } from '../../garden-canvas-settings';

export type SettingsContextState = StateDef<GardenCanvasSettings>;

class SettingsContext extends StatefulContextComponent<SettingsContextState> {
  type = 'SettingsContext';

  readonly camera: CameraNode;
  readonly contentRoot: ContentRootNode;

  constructor(contentRoot: ContentRootNode, cameraNode: CameraNode, initialValue: GardenCanvasSettings = DEFAULT_GARDEN_CANVAS_SETTINGS) {
    super(initialValue);

    this.camera = cameraNode;
    this.contentRoot = contentRoot;

    this.state.addUpdater(
      (state) => {
        this.camera.state.values.autoPan = state.values.autoPan;
      },
      { properties: ['autoPan'] }
    );

    this.state.addWatcher(
      (state) => {
        this.contentRoot.wheelMode = state.values.wheelMode;
      },
      { properties: ['wheelMode'] }
    );
  }
}

export default SettingsContext;
