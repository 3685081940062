import React, { useContext } from 'react';

import { PlanPlant } from '@gi/plan';

import PlantListCardCell from './plant-list-card-cell';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

interface iProps {
  planPlant: PlanPlant;
}

const PlantListCardLabel = ({ planPlant }: iProps): JSX.Element | null => {
  const { columns } = useContext(GlobalPlantListContext);

  if (!columns.plantLabel) {
    return null;
  }

  return <PlantListCardCell title='Label'>{planPlant.labelText}</PlantListCardCell>;
};

export default PlantListCardLabel;
