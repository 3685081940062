import React from 'react';

interface iProps {
  children: React.ReactNode;
}

const ModalPaneSectionHeader = ({ children }: iProps) => {
  return <h4 className='modal-pane-section-header'>{children}</h4>;
};

export default ModalPaneSectionHeader;
