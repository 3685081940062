import React from 'react';
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';

import { Application, ApplicationLinks } from '@gi/garden-platform-navigation';
import AccountRoutes from '@gi/app-account-routes';
import JournalRoutes from '@gi/app-journal-routes';
import HelpRoutes from '@gi/app-help-routes';
import DashboardRoutes from '@gi/app-dashboard-routes';
import PlannerRoutes from '@gi/app-planner-routes';
import appGuruRoutes from '@gi/app-guru-routes';
import { config } from '@gi/config';

import GardenPlatformRoot from './garden-platform-root';

// Polyfill Promise.allSettled
// Library code isn't polyfilled by babel.
// Seems to be used by @reduxjs/toolkit, and causes issues when changing routes on old browsers
// eslint-disable-next-line
polyfills: Promise.allSettled;

const router = createBrowserRouter(
  [
    {
      path: '/*',
      element: <GardenPlatformRoot />,
      children: [
        {
          path: '*',
          element: <Navigate to={ApplicationLinks[Application.Overview]} replace />,
        },
        AccountRoutes,
        JournalRoutes,
        HelpRoutes,
        DashboardRoutes,
        PlannerRoutes,
        appGuruRoutes,
      ],
    },
  ],
  { basename: config.basename }
);

const GardenPlatform = (): JSX.Element => {
  return <RouterProvider router={router} />;
};

export default GardenPlatform;
