import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';
import { newsFromAPI } from '../parsers/news-parser';
import { attachGetNewsErrorClientMessage } from './news-service-errors';

class NewsService {
  constructor({ endpoint }) {
    this.endpoint = endpoint;
    console.debug(`Created news service with endpoint ${this.endpoint}`);
  }

  /**
   * @param {ClientID} date
   * @return {Promise}
   */
  getNews(clientID) {
    const nonAuthTicket = 'c635499c-50f8-4af5-9efc-0d0bad1ecc44';

    const params = {
      t: nonAuthTicket,
    };

    const url = `${this.endpoint}/news/${clientID}`;
    return networkService
      .get(url, params, RequestAuthMode.None)
      .then((response) => {
        return response.body.map((newsObj) =>
          newsFromAPI({
            text: newsObj.newsText,
            colour: newsObj.textColour,
            url: newsObj.url,
          })
        );
      })
      .catch(networkErrorReporter('GET', 'news'))
      .catch((requestError) => {
        attachGetNewsErrorClientMessage(requestError);
        throw requestError;
      });
  }
}

export default NewsService;
