import React, { useContext } from 'react';

import { TooltipKey } from '@gi/tooltip';

import { PlannerCommandContext } from '../../../planner-command-provider';
import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';
import ControlButtonGroup from '../common/control-button-group';
import MenuButton from '../common/menu-button';
import SaveMenuButton from '../common/save-menu-button';

const PlantListControls = (): JSX.Element => {
  const { newPlan, openPlan, printPlantList } = useContext(PlannerCommandContext);
  return (
    <ControlGroup>
      <ControlRow>
        <ControlButtonGroup>
          <MenuButton id='new-plan' onClick={newPlan} tooltipKey={TooltipKey.NewPlan}>
            <i className='icon-doc' />
          </MenuButton>
          <MenuButton id='open-plan' onClick={openPlan} tooltipKey={TooltipKey.OpenPlan}>
            <i className='icon-folder-open-empty' />
          </MenuButton>
          <SaveMenuButton />
        </ControlButtonGroup>
        <ControlButtonGroup>
          <MenuButton id='print-plan' onClick={printPlantList} tooltipKey={TooltipKey.PrintPlantList}>
            <i className='icon-print' />
          </MenuButton>
        </ControlButtonGroup>
      </ControlRow>
    </ControlGroup>
  );
};

export default PlantListControls;
