import React from 'react';
import CheckboxEmpty from './checkbox-empty';

import styles from './checkbox.module.css';

interface iProps {
  checked: boolean;
  className?: string;
  boxColor?: string;
}

const Checkbox = ({ checked, className = '', boxColor = '#333' }: iProps): JSX.Element => {
  return (
    <div className={`${className} ${styles.checkbox}`}>
      <CheckboxEmpty borderColor={boxColor} />
      {checked ? (
        <span className={styles.checkboxCheck}>
          <i className='icon-ok' />
        </span>
      ) : null}
    </div>
  );
};

export default Checkbox;
