import { LoadingState } from '@gi/constants';
import { AsyncOperation, RevocableObjectURL } from '@gi/utils';
import { PlanBackgroundImageSrc, PlanBackgroundImageUtils } from '@gi/plan';

import { SimulatedItem } from './simulated-item';

export enum SimulatedPlanBackgroundImageEvents {
  DidUpdate = 'DidUpdate',
  ImageStateDidUpdate = 'ImageStateDidUpdate',
}

export type SimulatedPlanBackgroundImageEventActions = {
  [SimulatedPlanBackgroundImageEvents.DidUpdate]: () => void;
  [SimulatedPlanBackgroundImageEvents.ImageStateDidUpdate]: (image: AsyncOperation<string>) => void;
};

export class SimulatedPlanBackgroundImage extends SimulatedItem<SimulatedPlanBackgroundImageEventActions> {
  #enabled: boolean;
  get enabled() {
    return this.#enabled;
  }

  #position: Vector2;
  get position() {
    return this.#position;
  }

  #dimensions: Dimensions;
  get dimensions(): Dimensions {
    return this.#dimensions;
  }

  get width() {
    return this.#dimensions.width;
  }

  get height() {
    return this.#dimensions.height;
  }

  #rotation: number;
  get rotation() {
    return this.#rotation;
  }

  #imageSrc: PlanBackgroundImageSrc | null;
  get imageSrc() {
    return this.#imageSrc;
  }

  #imageURL: AsyncOperation<string> = { status: LoadingState.NONE };
  get imageURL() {
    return this.#imageURL;
  }

  #imageObjectURL: RevocableObjectURL | null = null;

  constructor(src: PlanBackgroundImageSrc | null, position: Vector2, dimensions: Dimensions, rotation: number) {
    super();

    this.#enabled = true;
    this.#imageSrc = src;
    this.#position = position;
    this.#dimensions = dimensions;
    this.#rotation = rotation;

    this.#loadImage();
    // this.#validate
  }

  setEnabled(enabled: boolean) {
    this.#enabled = enabled;
    this.emitUpdates();
  }

  setPosition(position: Vector2) {
    this.#position = position;
    this.emitUpdates();
  }

  setDimensions(dimensions: Dimensions) {
    this.#dimensions = dimensions;
    this.emitUpdates();
  }

  setRotation(rotation: number) {
    this.#rotation = rotation;
    this.emitUpdates();
  }

  setImageSrc(src: PlanBackgroundImageSrc | null) {
    if (this.#imageSrc === src) {
      return;
    }
    this.#imageSrc = src;
    this.#loadImage();
    this.emitUpdates();
  }

  #setImageURL(imageURL: AsyncOperation<string>) {
    this.#imageURL = imageURL;
    this.emit(SimulatedPlanBackgroundImageEvents.ImageStateDidUpdate, imageURL);
  }

  #loadImage() {
    if (this.#imageObjectURL) {
      this.#imageObjectURL.revokeObjectURL();
      this.#imageObjectURL = null;
    }

    const { imageSrc } = this;
    if (imageSrc === null) {
      this.#setImageURL({ status: LoadingState.NONE });
      return;
    }

    this.#setImageURL({ status: LoadingState.SUCCESS, value: PlanBackgroundImageUtils.getImageURL(imageSrc.id, imageSrc.crop) });
  }

  emitUpdates(): void {
    if (!this.preventEmitUpdates) {
      this.emit(SimulatedPlanBackgroundImageEvents.DidUpdate);
    }
  }
}
