import React from 'react';
import PlantFamily from '@gi/plant-family';

import './plant-family-row.scss';

interface iProps {
  plantFamily: PlantFamily;
}

const PlantFamilyHelpRow = ({ plantFamily }: iProps) => {
  const style = {
    '--color': `#${plantFamily.color}`,
    '--secondary-color': `#${plantFamily.secondaryColor}`,
  } as React.CSSProperties;

  const otherName = plantFamily.otherName ? ` (${plantFamily.otherName})` : null;

  return (
    <p className='plant-family-row' style={style}>
      <i className='plant-family-icon' />
      <span>
        <strong>{plantFamily.scientificName}</strong>
        {otherName}
      </span>
    </p>
  );
};

export default PlantFamilyHelpRow;
