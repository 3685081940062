import React from 'react';
import { LoadingState } from '@gi/constants';

export type AsyncOperationSuccess<T> = {
  status: LoadingState.SUCCESS;
  value: T;
};

type AsyncOperationError<T = Error> = {
  status: LoadingState.ERROR;
  error: T;
};

export type EmptyAsyncOperation =
  | AsyncOperationError
  | {
      status: LoadingState.NONE | LoadingState.LOADING | LoadingState.SUCCESS;
    };

export type AsyncOperation<TData = undefined, TError = Error> =
  | AsyncOperationSuccess<TData>
  | AsyncOperationError<TError>
  | {
      status: LoadingState.NONE | LoadingState.LOADING;
    };

export type ExtendedAsyncOperation<
  T = undefined,
  TError = Error,
  LoadingProperties = Record<string, unknown>,
  SuccessProperties = Record<string, unknown>,
  ErrorProperties = Record<string, unknown>,
  NoneProperties = Record<string, unknown>,
> =
  | (AsyncOperationSuccess<T> & SuccessProperties)
  | (AsyncOperationError<TError> & ErrorProperties)
  | ({ status: LoadingState.LOADING } & LoadingProperties)
  | ({ status: LoadingState.NONE } & NoneProperties);

/**
 * Reduces the loading states of all the given operations down to a single loading state.
 *  Priority = ERROR -> LOADING -> NONE -> SUCCESS
 *  Only returns SUCCESS if ALL resources are successful.
 * @param loadingStates The list of operations to reduce down
 * @returns A single loading state, representing the most-problematic loading-state of them all.
 */
export const getOverallLoadingState = (loadingStates: LoadingState[]): LoadingState => {
  return loadingStates.reduce<LoadingState>((prev, loadingState) => {
    if (loadingState === LoadingState.ERROR || prev === LoadingState.ERROR) {
      return LoadingState.ERROR;
    }
    if (loadingState === LoadingState.LOADING || prev === LoadingState.LOADING) {
      return LoadingState.LOADING;
    }
    if (loadingState === LoadingState.NONE || prev === LoadingState.NONE) {
      return LoadingState.NONE;
    }
    return LoadingState.SUCCESS;
  }, LoadingState.SUCCESS);
};

/**
 * Returns an icon representing the loading state
 * @param status The loading status
 * @returns An icon
 */
export const getLoadingStateIcon = (status: LoadingState): JSX.Element => {
  switch (status) {
    case LoadingState.LOADING:
      return <i className='icon-spinner animate-pulse' />;
    case LoadingState.SUCCESS:
      return <i className='icon-ok' />;
    case LoadingState.ERROR:
      return <i className='icon-attention-circled' />;
    case LoadingState.NONE:
    default:
      return <>...</>;
  }
};
