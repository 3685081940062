/* eslint-disable max-len */
import React from 'react';

const PlayButton = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.1'
      width='256'
      height='256'
      viewBox='0 0 256 256'
      xmlSpace='preserve'
      className='yt-play'
    >
      <g transform='translate(128 128) scale(0.72 0.72)'>
        <g transform='translate(-175.05 -175.05000000000004) scale(3.89 3.89)'>
          <path
            className='play-button-svg'
            d='M 88.119 23.338 c -1.035 -3.872 -4.085 -6.922 -7.957 -7.957 C 73.144 13.5 45 13.5 45 13.5 s -28.144 0 -35.162 1.881 c -3.872 1.035 -6.922 4.085 -7.957 7.957 C 0 30.356 0 45 0 45 s 0 14.644 1.881 21.662 c 1.035 3.872 4.085 6.922 7.957 7.957 C 16.856 76.5 45 76.5 45 76.5 s 28.144 0 35.162 -1.881 c 3.872 -1.035 6.922 -4.085 7.957 -7.957 C 90 59.644 90 45 90 45 S 90 30.356 88.119 23.338 z'
            transform=' matrix(1 0 0 1 0 0) '
            strokeLinecap='round'
          />
          <polygon points='36,58.5 59.38,45 36,31.5 ' style={{ fill: '#ffffff' }} transform='matrix(1 0 0 1 0 0)' />
        </g>
      </g>
    </svg>
  );
};

export default PlayButton;
