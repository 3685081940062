import React, { useContext, useMemo } from 'react';

import Plant from '@gi/plant';
import { DEFAULT_VARIETY } from '@gi/constants';
import { ResourceContext } from '@gi/resource-provider';
import { PrintablePlannerIcon, PrintablePlannerIconWithFamily } from '@gi/planner-icon';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import FavouriteButton from './favourite-button';
import InformationButton from './information-button';

import './plant-list-card-header.scss';

interface iProps {
  plant: Plant;
  isSFG?: boolean;
  varietyName?: string;
}

const PlantListCardHeader = ({ plant, isSFG, varietyName }: iProps): JSX.Element => {
  const { userArtifactCode } = useContext(ResourceContext);
  const { options } = useContext(GlobalPlantListContext);

  const icon = useMemo(() => {
    return options.showPlantFamily ? (
      <PrintablePlannerIconWithFamily iconClassName='plant-list-icon' code={plant.code} artifactCode={userArtifactCode} familyID={plant.familyID} />
    ) : (
      <PrintablePlannerIcon className='plant-list-icon' code={plant.code} artifactCode={userArtifactCode} />
    );
  }, [options, userArtifactCode]);

  const sfgTag = useMemo(() => {
    if (!isSFG) {
      return null;
    }
    return <span className='sfg-tag'>SFG</span>;
  }, [isSFG]);

  return (
    <div className='plant-list-card-header'>
      <div className='plant-list-card-header-icon'>{icon}</div>
      <div className='plant-list-card-header-content'>
        <span className='plant-name'>
          {sfgTag} {plant.name}
        </span>
        {options.showScientificName ? <span className='plant-scientific-name'>{plant.scientificName}</span> : null}
        {varietyName !== undefined ? <span className='plant-variety'>{varietyName === DEFAULT_VARIETY ? 'Default Variety' : varietyName}</span> : null}
      </div>
      <div className='plant-list-card-header-buttons'>
        <InformationButton plant={plant} />
        <FavouriteButton plant={plant} />
      </div>
    </div>
  );
};

export default PlantListCardHeader;
