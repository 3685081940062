import React from 'react';
import styles from './components.module.css';

interface iProps {
  children: React.ReactNode;
}

const SectionHeader = ({ children }: iProps): JSX.Element => {
  return <div className={styles.sectionHeader}>{children}</div>;
};

export default SectionHeader;
