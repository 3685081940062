import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@gi/modal';

import * as PlannerSettingsSelectors from './planner-settings-selectors';
import * as PlannerSettingsActionCreators from './planner-settings-action-creators';
import PlannerSettingsModalContent from './planner-settings-modal-content';
import RenderModeChangedModal from './device-settings/modals/render-mode-changed-modal';

import './planner-settings-modal.scss';

interface iProps {
  closeModal: () => void;
}

const PlannerSettingsModal = ({ closeModal }: iProps): JSX.Element | null => {
  return (
    <Modal className='planner-settings-modal top-attached' closeRequest={closeModal}>
      <PlannerSettingsModalContent />
    </Modal>
  );
};

const PlannerSettingsModalRenderer = (): JSX.Element | null => {
  const isOpen = useSelector(PlannerSettingsSelectors.isPlannerSettingsOpen);
  const isRenderModeModalOpen = useSelector(PlannerSettingsSelectors.isRenderModeChangeModalOpen);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(PlannerSettingsActionCreators.closePlannerSettings());
  };

  const closeRenderModeModal = () => {
    dispatch(PlannerSettingsActionCreators.closeRenderModeChangedModal());
  };

  return (
    <>
      {isOpen ? <PlannerSettingsModal closeModal={closeModal} /> : null}
      {isRenderModeModalOpen ? <RenderModeChangedModal onCancel={closeRenderModeModal} /> : null}
    </>
  );
};

export default PlannerSettingsModalRenderer;
