import React, { useContext } from 'react';

import EditBackgroundPropertiesForm from './forms/modify-background-form';
import CreateBackgroundPropertiesForm from './forms/create-background-form';
import { PlanBackgroundImageEditorContext } from './plan-background-image-editor-context';

interface iProps {
  onViewPlan?: () => void;
}

const PlanBackgroundImageEditorContent = ({ onViewPlan }: iProps): JSX.Element => {
  const { backgroundImage } = useContext(PlanBackgroundImageEditorContext);

  if (!backgroundImage) {
    return <CreateBackgroundPropertiesForm />;
  }

  return <EditBackgroundPropertiesForm backgroundImage={backgroundImage} onViewPlan={onViewPlan} />;
};

export default PlanBackgroundImageEditorContent;
