import React, { useContext } from 'react';

import Modal from '@gi/modal';

import { EditPlantContext } from './context/edit-plant-context';
import AddVarietyModalRenderer from './edit-section/add-variety-modal-renderer';
import CustomiseVarietiesModalRenderer from './edit-section/customise-varieties-modal-renderer';
import EditPlantModalContentInner from './edit-plant-modal-content-inner';

import './edit-plant-modal.scss';

const EditPlantModalContent = (): JSX.Element | null => {
  const { closeModal } = useContext(EditPlantContext);

  return (
    <>
      <AddVarietyModalRenderer />
      <CustomiseVarietiesModalRenderer />
      <Modal closeRequest={closeModal} className='edit-plant-modal'>
        <EditPlantModalContentInner />
      </Modal>
    </>
  );
};

export default EditPlantModalContent;
