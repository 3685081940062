import React, { useCallback } from 'react';

import LengthInput, { DegreesInput, VectorInput } from '@gi/length-input';
import FormField, { FormSection, FORM_FIELD_PRESETS } from '@gi/form-responsive';

import { EditGardenObjectModalFormProps, GardenObjectState } from '../types';
import InnerOuterDimensionsFormPart from './inner-outer-dimensions-form-part';

interface iProps extends EditGardenObjectModalFormProps {
  internalDimensionsMargin?: Dimensions | null;
}

const EditGardenObjectModalFormScalable = ({ values, setValues, distanceUnits, internalDimensionsMargin }: iProps): JSX.Element => {
  const createSetter = useCallback(
    <K extends keyof GardenObjectState>(setting: K) =>
      (value: GardenObjectState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  return (
    <FormSection heading='Position &amp; Dimensions' padding={12} gap={6} className='form-section-background'>
      <FormField label='Center' htmlFor='edit-garden-object:center-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.center} onChange={createSetter('center')} distanceUnits={distanceUnits} id='edit-garden-object:center' />
      </FormField>
      {internalDimensionsMargin ? (
        <InnerOuterDimensionsFormPart values={values} setValues={setValues} distanceUnits={distanceUnits} internalDimensionsMargin={internalDimensionsMargin} />
      ) : (
        <>
          <FormField label='Width' htmlFor='edit-garden-object:width' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
            <LengthInput value={values.values.width} onChange={createSetter('width')} distanceUnits={distanceUnits} id='edit-garden-object:width' />
          </FormField>
          <FormField label='Height' htmlFor='edit-garden-object:height' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
            <LengthInput value={values.values.height} onChange={createSetter('height')} distanceUnits={distanceUnits} id='edit-garden-object:height' />
          </FormField>
        </>
      )}
      <FormField label='Rotation' htmlFor='edit-garden-object:rotation' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <DegreesInput value={values.values.rotation} onChange={createSetter('rotation')} id='edit-garden-object:rotation' />
      </FormField>
    </FormSection>
  );
};

export default EditGardenObjectModalFormScalable;
