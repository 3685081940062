import React, { useCallback } from 'react';

import { DegreesInput, VectorInput } from '@gi/length-input';
import FormField, { FormSection, FORM_FIELD_PRESETS } from '@gi/form-responsive';

import { EditGardenObjectModalFormProps, GardenObjectState } from '../types';

const EditGardenObjectModalFormFixedSize = ({ values, setValues, distanceUnits }: EditGardenObjectModalFormProps): JSX.Element => {
  const createSetter = useCallback(
    <K extends keyof GardenObjectState>(setting: K) =>
      (value: GardenObjectState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  return (
    <FormSection heading='Position &amp; Rotation' padding={12} gap={6} className='form-section-background'>
      <FormField label='Center' htmlFor='edit-garden-object:center-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.center} onChange={createSetter('center')} distanceUnits={distanceUnits} id='edit-garden-object:center' />
      </FormField>
      <FormField label='Rotation' htmlFor='edit-garden-object:rotation' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <DegreesInput value={values.values.rotation} onChange={createSetter('rotation')} id='edit-garden-object:rotation' />
      </FormField>
    </FormSection>
  );
};

export default EditGardenObjectModalFormFixedSize;
