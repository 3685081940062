import React, { useContext } from 'react';

import PlantListCardCell from './plant-list-card-cell';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

interface iProps {
  count: number;
}

const PlantListCardCount = ({ count }: iProps): JSX.Element | null => {
  const { columns } = useContext(GlobalPlantListContext);

  if (!columns.counts) {
    return null;
  }

  return <PlantListCardCell title='Quantity'>{count.toLocaleString()}</PlantListCardCell>;
};

export default PlantListCardCount;
