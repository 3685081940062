import React, { useCallback, useState } from 'react';

import Modal, {
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalHeader,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalPane,
  ModalPaneContainer,
} from '@gi/modal';
import { createFormValues } from '@gi/form-responsive';
import { Validators } from '@gi/validators';

import {
  ImageEditorModalFormState,
  ImageManipulationData,
  InputAsyncImage,
  InputImage,
  InputRemoteImage,
  OutputAsyncImage,
  OutputImage,
  OutputRemoteImage,
} from './types';
import NoImageSelected from './parts/no-image-selected';
import ImageSelected from './parts/image-selected';

interface iProps {
  title?: string;
  image?: InputImage | InputRemoteImage | InputAsyncImage;
  manipulationData?: ImageManipulationData;
  onCancel: () => void;
  onComplete: (image: OutputImage | OutputRemoteImage | OutputAsyncImage) => void;
}

const ImageEditorModal = ({ title = 'Select an Image', image, manipulationData, onCancel, onComplete }: iProps): JSX.Element => {
  const [values, setValues] = useState(
    createFormValues<ImageEditorModalFormState>({
      image: { value: image ? image : null, validators: [Validators.isNotNull()] },
      crop: { value: manipulationData?.crop ?? null },
    })
  );

  const onFileSelected = useCallback((file: File) => {
    const url = URL.createObjectURL(file);
    const img = new Image();
    img.src = url;
    setValues((currentValues) => currentValues.setValues(['image', { value: { image: img } }], ['crop', { value: null }]));
  }, []);

  const onCompleteClicked = useCallback(() => {
    const { image: outputImage, crop } = values.values;
    if (outputImage) {
      const validCrop = crop !== null && crop.width > 0 && crop.height > 0;
      onComplete({
        ...outputImage,
        crop: validCrop ? crop : null,
      });
    }
  }, [values, onComplete]);

  const { image: selectedImage } = values.values;

  return (
    <Modal closeRequest={onCancel}>
      <ModalContent>
        <ModalHeader>
          <ModalCloseButton onClick={onCancel} />
          <ModalHeaderContent>
            <ModalHeaderTitle>{title}</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            {selectedImage === null ? (
              <NoImageSelected onImageChange={onFileSelected} />
            ) : (
              <ImageSelected image={selectedImage} onImageChange={onFileSelected} values={values} setValues={setValues} />
            )}
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={onCancel}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-primary' disabled={!values.isValid} onClick={onCompleteClicked}>
                Save
              </button>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ImageEditorModal;
