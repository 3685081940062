import { Record } from 'immutable';

const _News = {
  text: '',
  colour: '',
  url: '',
};

class News extends Record(_News) {}

export default News;

export { News };
