import React from 'react';
import Plant from '@gi/plant';

import ItemContentRow from '../../../components/item-content-table/item-content-row';
import ItemContentCell from '../../../components/item-content-table/item-content-cell';
import ItemContentCellHeader from '../../../components/item-content-table/item-content-cell-header';

interface iProps {
  plant: Plant;
  property: string;
  title: string;
}

const PlantInformationSection = ({ plant, property, title }: iProps): JSX.Element | null => {
  if (plant.text[property] === '' || plant.text[property] === '-' || plant.text[property] === null) {
    return null;
  }

  return (
    <ItemContentRow>
      <ItemContentCellHeader>{title}</ItemContentCellHeader>
      <ItemContentCell>
        <p>{plant.text[property]}</p>
      </ItemContentCell>
    </ItemContentRow>
  );
};

export default PlantInformationSection;
