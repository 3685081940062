import React from 'react';
import networkConfig from '@gi/config/network-config';
import { Swatch, SwatchTypes } from '@gi/palette';

interface iProps {
  swatch: Swatch;
  onClick?: (swatch: Swatch) => void;
  onHighlight?: (swatch: Swatch) => void;
  highlighted?: boolean;
  selected?: boolean;
  swatchesPerRow?: number;
}

const TextureSwatch = ({
  swatch,
  onClick = () => {},
  onHighlight = () => {},
  highlighted = false,
  selected = false,
  swatchesPerRow = 18,
}: iProps): JSX.Element => {
  let classes = 'texture-swatch';

  if (highlighted) {
    classes += ' highlighted';
  }

  if (selected) {
    classes += ' selected';
  }

  const styles: React.CSSProperties = {
    width: `${100 / swatchesPerRow}%`,
  };

  if (swatch.type === SwatchTypes.COLOR) {
    styles.backgroundColor = `#${swatch.value}`;
  } else {
    styles.backgroundImage = `url(${networkConfig.assetBaseURL}${swatch.src})`;
  }

  const handleClick = () => {
    onClick(swatch);
  };

  const handleHover = () => {
    onHighlight(swatch);
  };

  const renderLabel = (): null | JSX.Element => {
    if (!highlighted || !swatch.label) {
      return null;
    }

    return <div className='tooltip'>{swatch.label}</div>;
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div role='button' className={classes} style={styles} onMouseOver={handleHover} onFocus={handleHover} onClick={handleClick} tabIndex={0}>
      <div className='swatch-inner' />
      {renderLabel()}
    </div>
  );
};

export default TextureSwatch;
