import React, { useContext, useState } from 'react';
import ReactSelect from 'react-select';
import AnimateHeight from 'react-animate-height';
import { ResourceContext } from '@gi/resource-provider';
import FormField, { FormLayout, FormSection, InputContainer } from '@gi/form-responsive';

import { EditPlantContext } from '../context/edit-plant-context';

const IN_GROUND_SELECT_STYLES = {
  container: (provided) => ({
    ...provided,
    flex: '1 1 auto',
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '34px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  input: (provided) => ({
    ...provided,
    paddingBottom: '0',
    paddingTop: '0',
    margin: '0',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
};

const MONTHS = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 },
];

const SOUTHERN_HEMISPHERE_MONTHS = [
  { label: 'January', value: 6 },
  { label: 'February', value: 7 },
  { label: 'March', value: 8 },
  { label: 'April', value: 9 },
  { label: 'May', value: 10 },
  { label: 'June', value: 11 },
  { label: 'July', value: 0 },
  { label: 'August', value: 1 },
  { label: 'September', value: 2 },
  { label: 'October', value: 3 },
  { label: 'November', value: 4 },
  { label: 'December', value: 5 },
];

/**
 * Return the month option for the given value, if northern hemisphere, values are offset by 6 months
 * @param {number} val
 * @param {boolean} northernHemisphere
 */
function getMonthFromValue(val: number, northernHemisphere: boolean): { label: string; value: number } {
  if (northernHemisphere) {
    return MONTHS[val];
  }

  return SOUTHERN_HEMISPHERE_MONTHS[(val + 6) % 12];
}

const EditInGroundDates = (): JSX.Element => {
  const [inGroundHelpOpen, setInGroundHelpOpen] = useState(false);
  const { editPlantValues, setEditPlantValue } = useContext(EditPlantContext);
  const { userNorthernHemisphere } = useContext(ResourceContext);

  return (
    <FormSection padding={12} gap={6} className='form-section-background' heading='In-Ground Dates'>
      <FormField
        label='Specify in-ground dates'
        htmlFor='in-ground-input'
        desktopLayout={{ labelSize: 'auto' }}
        mobileLayout={{ labelSize: 'auto', layout: 'row' }}
        onHelpClick={() => {
          setInGroundHelpOpen(!inGroundHelpOpen);
        }}
      >
        <input
          id='in-ground-input'
          type='checkbox'
          className='in-ground-input'
          onChange={(e) => {
            setEditPlantValue('inGroundAll', !e.target.checked);
          }}
          checked={!editPlantValues.values.inGroundAll}
        />
      </FormField>
      <div>
        <AnimateHeight duration={500} height={inGroundHelpOpen ? 'auto' : 0}>
          <div style={{ overflow: 'hidden' }} className='help-text'>
            Specifying in-ground dates allows you to re-plant the same section of your plan with a second crop later in the year; it does not affect shown
            sowing, planting and harvesting times.
          </div>
        </AnimateHeight>
      </div>
      <FormLayout desktopLayout={{ layout: 'row', xAlign: 'stretch' }} mobileLayout={{ layout: 'column' }}>
        <FormField
          label='From'
          htmlFor='in-ground-from'
          desktopLayout={{ labelSize: 'auto', inputSize: 'full' }}
          mobileLayout={{ labelSize: 60, layout: 'row', inputSize: 160 }}
        >
          <InputContainer size='full'>
            <ReactSelect
              id='in-ground-from'
              isDisabled={editPlantValues.values.inGroundAll}
              styles={IN_GROUND_SELECT_STYLES}
              options={userNorthernHemisphere ? MONTHS : SOUTHERN_HEMISPHERE_MONTHS}
              value={getMonthFromValue(editPlantValues.values.inGroundStart, userNorthernHemisphere)}
              onChange={(val) => {
                if (val !== null) {
                  setEditPlantValue('inGroundStart', val.value);
                }
              }}
              menuPortalTarget={document.body}
            />
          </InputContainer>
        </FormField>
        <FormField
          label='To'
          htmlFor='in-ground-to'
          desktopLayout={{ labelSize: 'auto', inputSize: 'full' }}
          mobileLayout={{ labelSize: 60, layout: 'row', inputSize: 160 }}
        >
          <InputContainer size='full'>
            <ReactSelect
              id='in-ground-to'
              isDisabled={editPlantValues.values.inGroundAll}
              styles={IN_GROUND_SELECT_STYLES}
              options={userNorthernHemisphere ? MONTHS : SOUTHERN_HEMISPHERE_MONTHS}
              value={getMonthFromValue(editPlantValues.values.inGroundEnd, userNorthernHemisphere)}
              onChange={(val) => {
                if (val !== null) {
                  setEditPlantValue('inGroundEnd', val.value);
                }
              }}
              menuPortalTarget={document.body}
            />
          </InputContainer>
        </FormField>
      </FormLayout>
    </FormSection>
  );
};

export default EditInGroundDates;
