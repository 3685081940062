import { Graphics } from 'pixi.js-new';

const PLACEHOLDER_EDGE_INSET = 20;
const PLACEHOLDER_CORNER_LENGTH = 20;
const PLACEHOLDER_CORNER_EDGE_GAP = 20;

export function drawCorner(graphics: Graphics, width: number, height: number, xAlign: 1 | -1, yAlign: 1 | -1) {
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  graphics
    .moveTo((halfWidth - PLACEHOLDER_EDGE_INSET) * xAlign, (halfHeight - PLACEHOLDER_EDGE_INSET - PLACEHOLDER_CORNER_LENGTH) * yAlign)
    .lineTo((halfWidth - PLACEHOLDER_EDGE_INSET) * xAlign, (halfHeight - PLACEHOLDER_EDGE_INSET) * yAlign)
    .lineTo((halfWidth - PLACEHOLDER_EDGE_INSET - PLACEHOLDER_CORNER_LENGTH) * xAlign, (halfHeight - PLACEHOLDER_EDGE_INSET) * yAlign);
}

export function drawEdge(graphics: Graphics, width: number, height: number, align: 1 | -1, isVertical: boolean) {
  const fullLength = isVertical ? height : width;
  const edgePadding = PLACEHOLDER_EDGE_INSET;
  const cornerPadding = edgePadding + PLACEHOLDER_CORNER_LENGTH + PLACEHOLDER_CORNER_EDGE_GAP;
  const length = fullLength - cornerPadding * 2;
  if (length <= 0) {
    return;
  }
  const startX = (-width / 2 + (isVertical ? edgePadding : cornerPadding)) * align;
  const startY = (-height / 2 + (isVertical ? cornerPadding : edgePadding)) * align;
  const endX = isVertical ? startX : -startX;
  const endY = isVertical ? -startY : startY;
  graphics.moveTo(startX, startY).lineTo(endX, endY);
}
