import { createSelector } from 'reselect';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { RequestSelectors } from '@gi/react-requests';
import { SessionReducerState } from './session-reducer';

export interface SessionRootState {
  session: SessionReducerState;
}

export const getUser = (state: SessionRootState) => state.session.user;
export const getUserID = (state: SessionRootState) => (state.session.user === null ? null : state.session.user.ID);

export const getIsSavingUser = createSelector([getUserID, RequestSelectors.getRequests], (userID, requests) => {
  return RequestsUtils.getStatus(requests, `SAVE_USER_${userID}`) === RequestStatus.IN_PROGRESS;
});

export const getIsNorthernHemisphere = (state: SessionRootState) =>
  state.session.user === null ? false : state.session.user.settings.location.northernHemisphere;

export const getSession = (state: SessionRootState) => state.session;
