import Collection from '@gi/collection';
import Plant from '@gi/plant';
import GardenObject from '@gi/garden-object';
import { AssetGroup, AssetType, Engine, EventBus, Node, SelectableComponentContextEvent } from '@gi/core-renderer';
import { UserPlantVarietySet } from '@gi/user';
import Plan, { PlannerSettings } from '@gi/plan';
import { errorReporterInstance } from '@gi/errors';

import { GardenItemType, ItemNodeType, ShapeType } from '@gi/constants';
import { AssetNameCollisionMode } from '@gi/core-renderer/source/managers/assets/types';
import { SyncedPlans } from './synced-plan/synced-plans';
import CanvasPlans from './canvas-plan/canvas-plans';
import { CanvasPlan } from './canvas-plan/canvas-plan';
import { SimulatedPlan } from './simulation/simulated-plan';
import { ToolContextEvent } from './canvas-plan/nodes/tools/tool-context';
import { ToolState } from './canvas-plan/nodes/tools/tool-node';
import SelectionBoxToolNode from './canvas-plan/nodes/tools/selection-box-tool-node';
import DrawPlantTool from './canvas-plan/nodes/tools/draw-plant-tool';
import DrawGardenObjectTool from './canvas-plan/nodes/tools/draw-garden-object-tool';
import DrawShapeTool from './canvas-plan/nodes/tools/draw-shape-tool';
import DrawTextTool from './canvas-plan/nodes/tools/draw-text-tool';
import CanvasInteractionGroup from './canvas-interface/canvas-interaction-group';
import GardenCanvasClipboardController from './garden-canvas-clipboard-controller';
import { DEFAULT_GARDEN_CANVAS_SETTINGS, GardenCanvasSettings } from './garden-canvas-settings';
import { CanvasInteractionInterfaceEvent, CanvasInteractionInterfaceEventActions } from './canvas-interface/canvas-interaction-interface';

// How much pressing zoom in/out should alter the zoom of the plan.
const ZOOM_BY_AMOUNT = 200;

export enum GardenCanvasEvent {
  ASSETS_STATE_CHANGE = 'ASSETS_STATE_CHANGE',
  PLAN_UPDATE = 'PLAN_UPDATE',
  SELECTION_CHANGE = 'SELECTION_CHANGE',
  EDIT_ITEM = 'EDIT_ITEM',
  CONTEXT_MENU = 'CONTEXT_MENU',
  SHOW_TOUCH_DRAG_HELP = 'SHOW_TOUCH_DRAG_HELP',
  HIDE_TOUCH_DRAG_HELP = 'HIDE_TOUCH_DRAG_HELP',
  ON_DRAW_PLANT = 'ON_DRAW_PLANT',
  ON_DRAW_GARDEN_OBJECT = 'ON_DRAW_GARDEN_OBJECT',
  ON_DRAW_SHAPE = 'ON_DRAW_SHAPE',
  ON_DRAW_TEXT = 'ON_DRAW_TEXT',
}

export type GardenCanvasEventActions = {
  [GardenCanvasEvent.ASSETS_STATE_CHANGE]: (assetGroup: AssetGroup) => void;
  [GardenCanvasEvent.PLAN_UPDATE]: (plan: Plan) => void;
  [GardenCanvasEvent.SELECTION_CHANGE]: (nodes: CanvasInteractionGroup) => void;
  [GardenCanvasEvent.EDIT_ITEM]: CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.OnEditItem];
  [GardenCanvasEvent.CONTEXT_MENU]: CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.OnContextMenu];
  [GardenCanvasEvent.SHOW_TOUCH_DRAG_HELP]: CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.ShowTouchDragHelp];
  [GardenCanvasEvent.HIDE_TOUCH_DRAG_HELP]: CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.HideTouchDragHelp];
  [GardenCanvasEvent.ON_DRAW_PLANT]: CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.OnDrawPlant];
  [GardenCanvasEvent.ON_DRAW_GARDEN_OBJECT]: CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.OnDrawGardenObject];
  [GardenCanvasEvent.ON_DRAW_SHAPE]: CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.OnDrawShape];
  [GardenCanvasEvent.ON_DRAW_TEXT]: CanvasInteractionInterfaceEventActions[CanvasInteractionInterfaceEvent.OnDrawText];
};

class GardenCanvas extends EventBus<GardenCanvasEventActions> {
  readonly canvasPlans: CanvasPlans;
  readonly syncedPlans: SyncedPlans;
  readonly clipboardController: GardenCanvasClipboardController;

  readonly engine: Engine;

  readonly #EMPTY_PLAN: CanvasPlan;

  activePlanId: number | null = null;

  #settings: GardenCanvasSettings = { ...DEFAULT_GARDEN_CANVAS_SETTINGS };

  #onPlanUpdateCallback: (plan: Plan) => void;
  #onToolUpdateCallback: (status: ToolState | null) => void;

  readonly assets: AssetGroup;

  constructor(
    plants: Collection<Plant>,
    gardenObjects: Collection<GardenObject>,
    userPlantVarieties: UserPlantVarietySet,
    onPlanUpdate: (plan: Plan) => void,
    onToolUpdate: (state: ToolState | null) => void,
    settings: Partial<GardenCanvasSettings> = {}
  ) {
    super();

    this.#onPlanUpdateCallback = onPlanUpdate;
    this.#onToolUpdateCallback = onToolUpdate;
    this.#settings = { ...this.#settings, ...settings };

    this.syncedPlans = new SyncedPlans(this.#onPlanUpdate, plants, gardenObjects, userPlantVarieties);
    this.canvasPlans = new CanvasPlans(this.syncedPlans.simulationFactory);

    this.clipboardController = new GardenCanvasClipboardController(this.syncedPlans.simulationFactory);

    this.engine = new Engine(this.#settings.renderMode);
    this.engine.start(false);

    this.assets = new AssetGroup();
    this.#loadAssets();

    this.#EMPTY_PLAN = new CanvasPlan(new SimulatedPlan(-1, 0, 0), this.syncedPlans.simulationFactory);
  }

  /**
   * Returns plan IDs of all currently open plans
   */
  getOpenPlans(): number[] {
    return this.syncedPlans.getOpenPlans();
  }

  /**
   * Checks if a pla nwith the given ID is currently open and being syncronised
   * @param id The id of the plan
   * @returns True if the plan is open and being syncronised
   */
  isPlanOpen(id: number) {
    return this.getOpenPlans().includes(id);
  }

  /**
   * Private event handler to pass plan updates from SyncedPlans to the callback outside of the GardenCanvas
   */
  #onPlanUpdate = (plan: Plan) => {
    this.#onPlanUpdateCallback(plan);
    this.emit(GardenCanvasEvent.PLAN_UPDATE, plan);
  };

  /**
   * Opens a plan in the GardenCanvas
   *
   * Will create a new SyncedPlan which handles the PlanSimulation and CanvasPlan from the created
   * PlanSimulation
   */
  openPlan(plan: Plan, availableHistoricalPlans: Record<number, Plan>) {
    const simulatedPlan = this.syncedPlans.startPlanSimulation(plan, availableHistoricalPlans);
    const canvasPlan = this.canvasPlans.openCanvasPlan(simulatedPlan);
    canvasPlan.setSettings(this.#settings);

    canvasPlan.interactionInterface.on(CanvasInteractionInterfaceEvent.OnEditItem, this.#onEditItem);
    canvasPlan.interactionInterface.on(CanvasInteractionInterfaceEvent.OnContextMenu, this.#onContextMenu);
    canvasPlan.interactionInterface.on(CanvasInteractionInterfaceEvent.ShowTouchDragHelp, this.#onShowTouchDragHelp);
    canvasPlan.interactionInterface.on(CanvasInteractionInterfaceEvent.HideTouchDragHelp, this.#onHideTouchDragHelp);
    canvasPlan.interactionInterface.on(CanvasInteractionInterfaceEvent.OnDrawPlant, this.#onDrawPlant);
    canvasPlan.interactionInterface.on(CanvasInteractionInterfaceEvent.OnDrawGardenObject, this.#onDrawGardenObject);
    canvasPlan.interactionInterface.on(CanvasInteractionInterfaceEvent.OnDrawShape, this.#onDrawShape);
    canvasPlan.interactionInterface.on(CanvasInteractionInterfaceEvent.OnDrawText, this.#onDrawText);
    canvasPlan.toolContext.eventBus.on(ToolContextEvent.ToolUpdate, this.#onToolUpdateCallback);
    canvasPlan.selectionContext.eventBus.on(SelectableComponentContextEvent.OnSelectionChanged, this.#onSelectionChange);

    this.syncedPlans.updateHistoricalPlan(plan);
    if (plan.id === this.activePlanId) {
      this.#updateActivePlan();
    }
  }

  /**
   * Passes the updated plan into the SyncedPlans which will manage updating the simulated plan with the
   * new data
   */
  updatePlan(plan: Plan) {
    this.syncedPlans.updatePlan(plan);
  }

  /**
   * Closes a plan by ending the plan simulation in SyncedPlans and closing the plan
   * in the CanvasPlans which will destroy the plan nodes and all children
   */
  closePlan(id: number) {
    console.debug('Garden canvas closing plan', id);

    const canvasPlan = this.canvasPlans.getCanvasPlan(id);
    if (canvasPlan) {
      canvasPlan.interactionInterface.off(CanvasInteractionInterfaceEvent.OnEditItem, this.#onEditItem);
      canvasPlan.interactionInterface.off(CanvasInteractionInterfaceEvent.OnContextMenu, this.#onContextMenu);
      canvasPlan.interactionInterface.off(CanvasInteractionInterfaceEvent.ShowTouchDragHelp, this.#onShowTouchDragHelp);
      canvasPlan.interactionInterface.off(CanvasInteractionInterfaceEvent.HideTouchDragHelp, this.#onHideTouchDragHelp);
      canvasPlan.interactionInterface.off(CanvasInteractionInterfaceEvent.OnDrawPlant, this.#onDrawPlant);
      canvasPlan.interactionInterface.off(CanvasInteractionInterfaceEvent.OnDrawGardenObject, this.#onDrawGardenObject);
      canvasPlan.interactionInterface.off(CanvasInteractionInterfaceEvent.OnDrawShape, this.#onDrawShape);
      canvasPlan.interactionInterface.off(CanvasInteractionInterfaceEvent.OnDrawText, this.#onDrawText);
      canvasPlan.toolContext.eventBus.off(ToolContextEvent.ToolUpdate, this.#onToolUpdateCallback);
      canvasPlan.selectionContext.eventBus.off(SelectableComponentContextEvent.OnSelectionChanged, this.#onSelectionChange);
    }

    this.syncedPlans.endPlanSimulation(id);
    this.canvasPlans.closePlan(id);

    this.#autoSetActivePlan();
  }

  setActivePlan(id: number | null) {
    this.activePlanId = id;
    this.#updateActivePlan();
  }

  /**
   * Loads a historical plan into the garden canvas.
   * This will get passed to any plans that need it as part of their history.
   */
  updateHistoricalPlan(plan: Plan) {
    this.syncedPlans.updateHistoricalPlan(plan);
  }

  /**
   * Returns the currently active CanvasPlan, if any.
   */
  #getActivePlan(): CanvasPlan | null {
    if (this.activePlanId === null) {
      return null;
    }

    if (!this.canvasPlans.hasCanvasPlan(this.activePlanId)) {
      return null;
    }

    return this.canvasPlans.getCanvasPlan(this.activePlanId);
  }

  /**
   * Returns the currently active plan. Null if there's no active plan.
   */
  getActivePlan(): null | { id: number; canvasPlan: CanvasPlan; simulatedPlan: SimulatedPlan } {
    if (this.activePlanId === null) {
      return null;
    }

    if (!this.canvasPlans.hasCanvasPlan(this.activePlanId) || !this.syncedPlans.hasSimulatedPlan(this.activePlanId)) {
      return null;
    }

    return {
      id: this.activePlanId,
      canvasPlan: this.canvasPlans.getCanvasPlan(this.activePlanId),
      simulatedPlan: this.syncedPlans.getSimulatedPlan(this.activePlanId)!,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  #autoSetActivePlan() {
    // TODO
    const openPlans = this.getOpenPlans();

    if (openPlans.length === 0) {
      this.setActivePlan(null);
    } else {
      this.setActivePlan(openPlans[0]);
    }
  }

  setContainer(container: HTMLElement | null): void {
    this.engine.setContainer(container);
  }

  resize(width: number, height: number) {
    this.engine.resize(width, height);
  }

  /**
   * Updates the root node of the engine based on the current activePlanId
   */
  #updateActivePlan() {
    if (this.activePlanId === null) {
      // Active plan is not set so check content root node is not set
      if (this.engine.getContentRoot() !== null) {
        this.engine.setContentRoot(null);
        this.#onSelectionChange([]);
      }

      return;
    }

    if (!this.canvasPlans.hasCanvasPlan(this.activePlanId)) {
      // console.error('Active plan Id is not available as canvas plan');
      this.engine.setContentRoot(null);
      this.#onSelectionChange([]);
      return;
    }

    const activePlan = this.canvasPlans.getCanvasPlan(this.activePlanId);
    if (this.canvasPlans.getCanvasPlan(this.activePlanId).planRootNode !== this.engine.contentRoot) {
      this.engine.setContentRoot(activePlan.planRootNode);
      this.#onSelectionChange([...activePlan.selectionContext.selection]);
    }
  }

  #loadAssets() {
    this.assets.add(AssetType.BUNDLE, 'base-textures', this.#settings.textureDefinitionsURL);

    this.assets.onProgress(() => {
      this.emit(GardenCanvasEvent.ASSETS_STATE_CHANGE, this.assets);
    });
    this.assets.onError((error) => {
      console.error(`📦❌ Error when trying to load assets:\n${error}`);
      this.emit(GardenCanvasEvent.ASSETS_STATE_CHANGE, this.assets);

      errorReporterInstance.notify(error, (event) => {
        // TODO: Fix these types when the error reporter supports exposing the bugsnag types.
        if (event) {
          (event as any).addMetadata('network', {
            tag: 'textures',
          });
        }
      });
    });
    this.assets.onSuccess(() => {
      console.debug('📦✅ Successfully loaded all assets');
      this.emit(GardenCanvasEvent.ASSETS_STATE_CHANGE, this.assets);
    });

    this.engine.assetManager.loadGroup(this.assets).catch(() => {
      /* Error is handled above, do nothing here. */
    });
  }

  retryLoadAssets() {
    this.engine.assetManager.loadGroup(this.assets, AssetNameCollisionMode.SKIP).catch(() => {
      /* Error is handled elsewhere, do nothing here. */
    });
  }

  /**
   * Updates the general settings for the core renderer/garden canvas. Applies to all plans.
   */
  setSettings(settings: Partial<GardenCanvasSettings>) {
    this.canvasPlans
      .getOpenPlans()
      .map((id) => this.canvasPlans.getCanvasPlan(id))
      .forEach((canvasPlan) => {
        canvasPlan.setSettings(settings);
      });
    const renderModeChanged = settings.renderMode && settings.renderMode !== this.#settings.renderMode;
    Object.assign(this.#settings, settings);
    if (renderModeChanged) {
      this.engine.setRenderMode(this.#settings.renderMode);
    }
  }

  /**
   * Updates the plan-specific settings for the currently-active plan.
   */
  setPlanSettings(settings: Partial<PlannerSettings>) {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }
    activePlan.setPlanSettings(settings);
  }

  setUserPlantVarieties(userVarieties: UserPlantVarietySet) {
    this.syncedPlans.updateUserPlantVarieties(userVarieties);
  }

  #onSelectionChange = (selection: Node[]) => {
    this.emit(GardenCanvasEvent.SELECTION_CHANGE, new CanvasInteractionGroup(selection));
  };

  #onEditItem = (itemType: GardenItemType, itemId: number, planId: number) => {
    this.emit(GardenCanvasEvent.EDIT_ITEM, itemType, itemId, planId);
  };

  #onContextMenu = (worldPosition: Vector2) => {
    this.emit(GardenCanvasEvent.CONTEXT_MENU, worldPosition);
  };

  #onShowTouchDragHelp = (itemType: ItemNodeType, itemId: number, planId: number) => {
    this.emit(GardenCanvasEvent.SHOW_TOUCH_DRAG_HELP, itemType, itemId, planId);
  };

  #onHideTouchDragHelp = () => {
    this.emit(GardenCanvasEvent.HIDE_TOUCH_DRAG_HELP);
  };

  #onDrawPlant = (...params: Parameters<GardenCanvasEventActions[GardenCanvasEvent.ON_DRAW_PLANT]>) => {
    this.emit(GardenCanvasEvent.ON_DRAW_PLANT, ...params);
  };

  #onDrawGardenObject = (...params: Parameters<GardenCanvasEventActions[GardenCanvasEvent.ON_DRAW_GARDEN_OBJECT]>) => {
    this.emit(GardenCanvasEvent.ON_DRAW_GARDEN_OBJECT, ...params);
  };

  #onDrawShape = (...params: Parameters<GardenCanvasEventActions[GardenCanvasEvent.ON_DRAW_SHAPE]>) => {
    this.emit(GardenCanvasEvent.ON_DRAW_SHAPE, ...params);
  };

  #onDrawText = (...params: Parameters<GardenCanvasEventActions[GardenCanvasEvent.ON_DRAW_TEXT]>) => {
    this.emit(GardenCanvasEvent.ON_DRAW_TEXT, ...params);
  };

  startBoxSelect() {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }

    activePlan.toolContext.setActiveTool(new SelectionBoxToolNode());
  }

  startDrawingPlant(plant: Plant, isSquareFoot: boolean, dragToDrawEvent?: PointerEvent) {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }

    activePlan.toolContext.setActiveTool(new DrawPlantTool(plant, isSquareFoot, activePlan.interactionInterface, activePlan.canvasLayers, dragToDrawEvent));
    if (dragToDrawEvent) {
      this.focusContainer();
    }
  }

  startDrawingGardenObject(gardenObject: GardenObject, dragToDrawEvent?: PointerEvent) {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }

    activePlan.toolContext.setActiveTool(new DrawGardenObjectTool(gardenObject, activePlan.interactionInterface, activePlan.canvasLayers, dragToDrawEvent));
    if (dragToDrawEvent) {
      this.focusContainer();
    }
  }

  startDrawingShape(
    shapeType: ShapeType,
    fill: number | null,
    stroke: number | null,
    strokeWidth: number,
    texture: string | null,
    dragToDrawEvent?: PointerEvent
  ) {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }

    activePlan.toolContext.setActiveTool(
      new DrawShapeTool(shapeType, fill, stroke, strokeWidth, texture, activePlan.interactionInterface, activePlan.canvasLayers, dragToDrawEvent)
    );
    if (dragToDrawEvent) {
      this.focusContainer();
    }
  }

  startDrawingText(fontSize: number, color: number, dragToDrawEvent?: PointerEvent) {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }

    activePlan.toolContext.setActiveTool(new DrawTextTool(fontSize, color, activePlan.interactionInterface, activePlan.canvasLayers, dragToDrawEvent));
    if (dragToDrawEvent) {
      this.focusContainer();
    }
  }

  deleteSelectedNodes = () => {
    const plan = this.#getActivePlan();
    if (!plan) {
      return;
    }

    plan.selectionMiddleware.deleteSelection();
  };

  increaseZoom = () => {
    const plan = this.#getActivePlan();
    if (!plan) {
      return;
    }
    plan.camera.alterZoom(-ZOOM_BY_AMOUNT);
  };

  decreaseZoom = () => {
    const plan = this.#getActivePlan();
    if (!plan) {
      return;
    }
    plan.camera.alterZoom(ZOOM_BY_AMOUNT);
  };

  resetZoom = () => {
    const plan = this.#getActivePlan();
    if (!plan) {
      return;
    }
    plan.camera.state.values.magnification = 1;
  };

  cancelInteraction() {
    const plan = this.#getActivePlan();
    if (!plan) {
      return;
    }
    plan.toolContext.cancelActiveTool();
  }

  cut = () => {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }

    this.clipboardController.cut(activePlan);
  };

  copy = () => {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }

    this.clipboardController.copy(activePlan);
  };

  paste = () => {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }

    this.clipboardController.paste(activePlan);
  };

  focusContainer = () => {
    if (this.engine.container) {
      this.engine.container.focus();
    }
  };

  startSelection = () => {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }

    activePlan.toolContext.setActiveTool(new SelectionBoxToolNode());
  };

  cancelSelection = () => {
    this.cancelInteraction();
  };

  clearSelection = () => {
    const activePlan = this.#getActivePlan();
    if (!activePlan) {
      return;
    }

    activePlan.selectionContext.clearSelection();
  };

  // eslint-disable-next-line class-methods-use-this
  getImageGenerator = () => {
    console.warn('Not Implemented');
    // TODO: Is this used anywhere? I've never seen this.
  };
}

export default GardenCanvas;
