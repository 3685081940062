import React, { useContext, useMemo } from 'react';
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height';

import { AccordionContext } from './accordion-provider';

import styles from './accordion-styles.module.css';

interface iProps extends Omit<AnimateHeightProps, 'height'> {
  accordionId: string;
}

const AccordionContent = ({ accordionId, children, duration, className, contentClassName, ...remainingProps }: iProps) => {
  const { isExpanded, animationDuration, unstyled } = useContext(AccordionContext);

  const expanded = useMemo(() => {
    return isExpanded(accordionId);
  }, [accordionId, isExpanded]);

  const classNameString = useMemo(() => {
    const classNames: string[] = [];
    if (!unstyled) {
      classNames.push(styles.accordionContent);
    }
    if (className) {
      classNames.push(className);
    }
    return classNames.length > 0 ? classNames.join(' ') : undefined;
  }, [className, unstyled]);

  const innerClassNameString = useMemo(() => {
    const classNames: string[] = [];
    if (!unstyled) {
      classNames.push(styles.accordionContentInner);
    }
    if (contentClassName) {
      classNames.push(contentClassName);
    }
    return classNames.length > 0 ? classNames.join(' ') : undefined;
  }, [contentClassName, unstyled]);

  return (
    <AnimateHeight
      height={expanded ? 'auto' : 0}
      duration={duration ?? animationDuration}
      className={classNameString}
      contentClassName={innerClassNameString}
      {...remainingProps}
    >
      {children}
    </AnimateHeight>
  );
};

export default AccordionContent;
