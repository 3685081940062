import Bitmask from '@gi/bitmask';
import { State, StateDef, StateObserver } from '@gi/state';

import GraphicNode from '../../graphics-node';
import OutlineComponent from '../../node-components/outline/outline-component';
import ShapeComponent from '../../node-components/shape/shape-component';
import { ShapeFlag } from '../../node-components/shape/types';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { bindState, bindToLifecycle } from '../../utils/state-utils';
import ContentRootContext, { ViewportContextState } from '../content-root/content-root-context';
import HandleNode, { HandleDisplayMode } from './handle-node';

export type HandleSetNodeState = StateDef<
  {
    // Is true when any of the handles of this handle set are being hovered.
    handleHovered: boolean;
    // Internal state to track which handles are being hovered, to avoid hover/unhover race conditions.
    _hoveredHandleNames: string[];
  },
  [],
  {
    viewport: ViewportContextState;
  }
>;

/**
 * Handle Set Node
 *  Contains a set of handles to be used to manipulate a subject.
 *  Has basic utilities for adding/removing handles, and running callbacks whenever a handle is hovered.
 */
class HandleSetNode extends GraphicNode {
  type = 'handleSetNode';

  handles: Record<string, HandleNode> = {};
  handleList: HandleNode[] = [];
  handleHoverUpdaters: Record<string, StateObserver<any>> = {};

  state: State<HandleSetNodeState>;
  shape: ShapeComponent;
  outline: OutlineComponent;

  #handleScale: number = 1;
  get handleScale() {
    return this.#handleScale;
  }

  #handleDisplayMode: HandleDisplayMode = HandleDisplayMode.MOUSE;
  get handleDisplayMode() {
    return this.#handleDisplayMode;
  }

  constructor(handles: HandleNode[] = []) {
    super();

    this.state = new State({
      handleHovered: false,
      _hoveredHandleNames: [],
    });
    bindState(this.state, this);

    this.state.addValidator(
      (state) => {
        state.values.handleHovered = state.values._hoveredHandleNames.length > 0;
      },
      { properties: ['_hoveredHandleNames'] }
    );

    this.shape = this.components.add(new ShapeComponent({ flags: Bitmask.Create(ShapeFlag.UI) }));
    this.outline = this.components.add(
      new OutlineComponent({
        visible: true,
        colour: '#55555555',
        backgroundColour: '#55555510',
        thickness: 1,
        generateHitbox: false,
        disablePointerEvents: true,
      })
    );

    this.addHandles(...handles);

    this.state.addWatcher(
      (state) => {
        this.visible = !(state.otherStates.viewport?.values.isPrinting ?? false);
      },
      { otherStates: { viewport: { properties: ['isPrinting'] } } }
    );

    bindToLifecycle(this, () => {
      const contentRoot = this.tryGetContext(ContentRootContext);
      if (!contentRoot) {
        return () => {};
      }

      this.state.connectState('viewport', contentRoot.state);

      return () => {
        this.state.disconnectState('viewport');
      };
    });
  }

  /**
   * Adds the given handles to this set. Should all have unique names.
   * @param handles The handles to add
   */
  addHandles(...handles: HandleNode[]) {
    for (let i = 0; i < handles.length; i++) {
      const handle = handles[i];

      if (handles[handle.name]) {
        throw new Error('Added a handle with the same name as an existing handle.');
      }

      handle.setScale(this.handleScale);
      handle.setDisplayMode(this.handleDisplayMode);

      this.addChildren(handle);

      this.handles[handle.name] = handle;
      this.handleList.push(handle);
      this.handleHoverUpdaters[handle.name] = handle.hoverable.state.addUpdater(
        (handleState) => {
          // When this handle is hovered, add it to the list. When it's not, remove it.
          if (handleState.values.hovered) {
            if (!this.state.values._hoveredHandleNames.includes(handle.name)) {
              this.state.values._hoveredHandleNames = [...this.state.values._hoveredHandleNames, handle.name];
            }
          } else {
            const index = this.state.values._hoveredHandleNames.indexOf(handle.name);
            if (index !== -1) {
              const newHoveredHandles = [...this.state.values._hoveredHandleNames];
              newHoveredHandles.splice(index, 1);
              this.state.values._hoveredHandleNames = newHoveredHandles;
            }
          }
        },
        { properties: ['hovered'] }
      );
    }
  }

  /**
   * Removes the given handles from this set.
   * @param handles The handles to remove
   */
  removeHandles(...handles: HandleNode[]) {
    for (let i = 0; i < handles.length; i++) {
      const handle = handles[i];

      if (!handles[handle.name]) {
        console.warn("Tried to remove handle that isn't part of handleset");
      }

      handle.onDragStart = null;
      handle.onDragMove = null;
      handle.onDragEnd = null;

      this.removeChildren(handle);
      this.handleHoverUpdaters[handle.name].destroy();

      delete this.handles[handle.name];
      delete this.handleHoverUpdaters[handle.name];
      const index = this.handleList.indexOf(handle);
      if (index !== -1) {
        this.handleList.splice(index, 1);
      }
    }
  }

  /**
   * Sets the handle scale. May be used to make handles bigger for touch.
   * @param scale The scale factor
   */
  setHandleScale(scale: number) {
    this.#handleScale = scale;
    for (let i = 0; i < this.handleList.length; i++) {
      this.handleList[i].setScale(scale);
    }
  }

  /**
   * Sets the handle display mode. Can be used to cange how the handles look on touch.
   * @param displayMode The display mode for all handl;es to use.
   */
  setHandleDisplayMode(displayMode: HandleDisplayMode) {
    if (displayMode === this.#handleDisplayMode) {
      return;
    }
    this.#handleDisplayMode = displayMode;
    for (let i = 0; i < this.handleList.length; i++) {
      this.handleList[i].setDisplayMode(displayMode);
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default HandleSetNode;
