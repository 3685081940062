import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal, ModalType } from '@gi/modal';

const ResetVarietyModal = (props) => {
  return (
    <ConfirmModal type={ModalType.Warning} onConfirm={props.onConfirm} onCancel={props.onCancel} headerText={`Reset Variety ${props.varietyName}?`}>
      Reseting the variety will clear any custom spacing and sow, plant harvest times; do you want to continue?
    </ConfirmModal>
  );
};

ResetVarietyModal.propTypes = {
  varietyName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ResetVarietyModal;
