import React, { useContext } from 'react';
import { ResourceContext } from '@gi/resource-provider';
import { GardenObjectIcon } from '@gi/plant-icon';
import { GardenObjectUtils } from '@gi/garden-object';

import ListItemDetails from '../components/list-item-details';
import ListItem from '../components/list-item';
import ListItemProduct from '../components/list-item-product';
import { useGardenObjectShoppingEntry, useGardenObjectProducts, useProduct } from '../../hooks';

import styles from '../plants/plant-entry.module.css';

interface iProps {
  currency: string;
  planId: number;
  uuid: string;
}

const GardenObjectEntryDisplay = ({ currency, planId, uuid }: iProps): JSX.Element | null => {
  const [entry, setEntry] = useGardenObjectShoppingEntry(planId, uuid);
  const { getGardenObject } = useContext(ResourceContext);
  const gardenObject = entry ? getGardenObject(entry.matchData.code) : null;
  const product = entry ? useProduct(entry.productId) : null;
  const filename = gardenObject ? `${GardenObjectUtils.getDisplayImageName(gardenObject)}.svg` : null;
  const icon = filename ? <GardenObjectIcon filename={filename} className={styles.displayIcon} /> : null;

  const products = useGardenObjectProducts(gardenObject ? gardenObject.code : '');

  if (!product || !gardenObject || !entry) {
    return null;
  }

  const variant = entry ? product.variants[entry.variantId] : null;

  if (!variant) {
    return null;
  }

  return (
    <ListItem>
      <ListItemDetails icon={icon} title={gardenObject?.name} subtitle='' extraDetails={<div>Quantity: {entry.inputQuantity}</div>} />
      <ListItemProduct currency={currency} variant={variant} product={product} entry={entry} setEntry={setEntry} productOptions={products} />
    </ListItem>
  );
};

export default GardenObjectEntryDisplay;
