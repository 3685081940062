/**
 * @typedef {string} SelectableNodeGroup
 */
/**
 * Enum for the different item types on a garden plan
 *
 * @readonly
 * @enum {SelectableNodeGroup}
 */
const SelectableNodeGroups = {
  PLANT: 'PLANT',
  LAYOUT: 'LAYOUT',
  IRRIGATION: 'IRRIGATION',
  STRUCTURES: 'STRUCTURES',
  TEXT: 'TEXT',
  LABEL: 'LABEL',
};

export default SelectableNodeGroups;
