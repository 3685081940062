import React, { useCallback, useMemo } from 'react';

import Modal from '@gi/modal';
import Plan, { PlanShape, PlanUtils } from '@gi/plan';
import { getDistanceUnitsFromIsMetric } from '@gi/units';

import EditShapeModalContent from './edit-shape-modal-content';

interface iProps {
  plan: Plan;
  shapeItemID: number;
  editItemModalComplete: (updatedPlan: Plan) => void;
  closeModal: () => void;
}

const EditShapeModal = ({ plan, shapeItemID, editItemModalComplete, closeModal }: iProps): JSX.Element | null => {
  /**
   * Find the shape in the plan. This *can* return null, but it really shouldn't if our props are correct.
   */
  const planShape = useMemo<PlanShape | null>(() => {
    return plan.shapes[shapeItemID] ?? null;
  }, [plan, shapeItemID]);

  /**
   * Get the user's distance units
   */
  const distanceUnits = useMemo(() => {
    return getDistanceUnitsFromIsMetric(plan.plannerSettings.metric);
  }, [plan]);

  /**
   * Update the plan on complete and send the updated plan back out.
   */
  const onComplete = useCallback(
    (updatedPlanShape: PlanShape) => {
      editItemModalComplete(PlanUtils.updatePlanShape(plan, updatedPlanShape));
    },
    [plan]
  );

  /**
   * Error if we don't have a valid PlanShape
   */
  if (!planShape) {
    console.error(`Tried to open EditShapeModal without a valid PlanShape. ID ${shapeItemID}, PlanID: ${plan.id}`);
    return null;
  }

  return (
    <Modal closeRequest={closeModal}>
      <EditShapeModalContent distanceUnits={distanceUnits} planShape={planShape} onComplete={onComplete} />
    </Modal>
  );
};

export default EditShapeModal;
