import { AnyAction } from 'redux';
import PlannerSettingsActionTypes from './planner-settings-action-types';

export enum SettingsTabType {
  PlanSettings = 'PlanSettings',
  ProfileSettings = 'ProfileSettings',
  DeviceSettings = 'DeviceSettings',
}

export type PlannerSettingsState = {
  plannerSettingsOpen: boolean;
  renderModeChangeModalOpen: boolean;
  defaultTab: null | SettingsTabType;
};

export const DEFAULT_STATE: PlannerSettingsState = {
  plannerSettingsOpen: false,
  renderModeChangeModalOpen: false,
  defaultTab: null,
};

export default (state: PlannerSettingsState = DEFAULT_STATE, action: AnyAction) => {
  switch (action.type) {
    case PlannerSettingsActionTypes.OPEN_PLANNER_SETTINGS:
      return {
        ...state,
        plannerSettingsOpen: true,
        defaultTab: action.defaultTab ? action.defaultTab : null,
      };
    case PlannerSettingsActionTypes.CLOSE_PLANNER_SETTINGS:
      return {
        ...state,
        plannerSettingsOpen: false,
        defaultTab: null,
      };
    case PlannerSettingsActionTypes.OPEN_RENDER_MODE_CHANGE_MODAL:
      return {
        ...state,
        renderModeChangeModalOpen: true,
      };
    case PlannerSettingsActionTypes.CLOSE_RENDER_MODE_CHANGE_MODAL:
      return {
        ...state,
        renderModeChangeModalOpen: false,
      };
    default:
      return state;
  }
};
