import React, { useContext, useMemo } from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';
import DEFAULT_SELECT_STYLES from '@gi/styles/react-select-styles';
import { ProductVariant } from '@gi/products';
import { ResourceContext } from '@gi/resource-provider';

import { useProduct } from '../../../hooks';
import SectionHeader from '../section-header';
import { formatPrice } from '../../../utils';

import styles from './variant-selector.module.css';
import ListItemDetailsColumn from '../list-item-details-column';

type VariantOption = {
  value: number | null;
  price: number;
  label: string;
  quantity: number;
  unit: string;
};

const createLabelFormatter = (currency: string) => {
  const LabelFormat = ({ label, price, quantity, unit }: Omit<VariantOption, 'value'>): JSX.Element | string => {
    if (quantity <= 1) {
      return (
        <div className={styles.variantLabel}>
          <span className={styles.nameLabel}>{label}</span>
          <span className={styles.priceLabel}>{formatPrice(price, currency)}</span>
        </div>
      );
    }

    return (
      <div className={styles.variantLabel}>
        <span className={styles.nameLabel}>{label}</span>
        <span className={styles.priceLabel}>
          <span className={styles.priceText}>{formatPrice(price, currency)}</span>
          <span className={styles.quantityText}>
            {quantity} {unit}
          </span>
        </span>
      </div>
    );
  };

  return LabelFormat;
};

export const VARIANT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    borderRadius: '3px',
    minHeight: '36px',
    borderColor: '#dddddd',
  }),
  dropdownIndicator: (provided) => ({
    ...DEFAULT_SELECT_STYLES.dropdownIndicator(provided),
    padding: '3px',
  }),
  valueContainer: (provided) => ({
    ...DEFAULT_SELECT_STYLES.valueContainer(provided),
    padding: '0 5px',
    minHeight: '30px',
  }),
};

interface iProps {
  productId: number;
  setVariantId: (variant: number) => void;
  selectedId?: number; // Variant ID
  selectedVariant: ProductVariant<unknown>;
  disabled?: boolean;
  menuPlacement?: MenuPlacement;
  attachToBody?: boolean;
  id?: string;
}

const VariantSelector = ({
  productId,
  setVariantId,
  selectedId,
  selectedVariant,
  disabled = false,
  menuPlacement,
  attachToBody = false,
  id,
}: iProps): JSX.Element => {
  const { store } = useContext(ResourceContext);
  const product = useProduct(productId);

  const options: VariantOption[] = useMemo(() => {
    if (product === null) {
      return [];
    }

    if (product.variantIds.length < 2) {
      return [];
    }

    return product.variantIds.map((variantId) => ({
      label: product.variants[variantId].name,
      value: product.variants[variantId].variantId,
      price: product.variants[variantId].price,
      quantity: product.variants[variantId].quantity,
      unit: product.variants[variantId].unit,
    }));
  }, [product]);

  const selectedOption = useMemo(() => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === selectedId) {
        return options[i];
      }
    }

    return {
      label: 'No Options',
      price: 0,
      value: null,
      quantity: 0,
      unit: '',
    };
  }, [options, selectedId]);

  const noOptions = product ? product?.variantIds.length < 2 : true;

  const labelFormatter = useMemo(() => {
    return createLabelFormatter(store ? store.details.currency : 'USD');
  }, [store?.details.currency]);

  const priceComponent =
    selectedVariant.quantity === 0 ? (
      <div className={styles.noOption}>{formatPrice(selectedVariant.price, store?.details.currency)}</div>
    ) : (
      <div className={styles.noOption}>
        {formatPrice(selectedVariant.price, store?.details.currency)} ({selectedVariant.quantity} {selectedVariant.unit})
      </div>
    );

  return (
    <ListItemDetailsColumn className={styles.variantSelector}>
      <SectionHeader>{noOptions ? 'Unit Price' : 'Options'}</SectionHeader>
      <div>
        {noOptions ? (
          priceComponent
        ) : (
          <ReactSelect<VariantOption>
            isDisabled={disabled || noOptions}
            styles={VARIANT_STYLES}
            options={options}
            value={selectedOption}
            formatOptionLabel={labelFormatter}
            onChange={(e) => {
              if (e !== null && e.value !== null) {
                setVariantId(e.value);
              }
            }}
            menuPortalTarget={attachToBody ? document.body : undefined}
            menuPlacement={menuPlacement}
            inputId={id}
            isSearchable={false}
          />
        )}
      </div>
    </ListItemDetailsColumn>
  );
};

export default VariantSelector;
