import { State, StateDef } from '@gi/state';
import NodeComponent from '../../node-component/node-component';
import { InspectableClassData } from '../../types';
import { bindState } from '../../utils/state-utils';

export enum InteractionMode {
  MOUSE = 'MOUSE',
  PEN = 'PEN',
  TOUCH = 'TOUCH',
}

type InteractionContextState = StateDef<{
  pointerType: string;
  interactionMode: InteractionMode;
}>;

const DEFAULT_STATE: InteractionContextState['state'] = {
  pointerType: 'pointer',
  interactionMode: InteractionMode.MOUSE,
};

class InteractionContext extends NodeComponent {
  type = 'InteractionContext';

  state: State<InteractionContextState>;

  constructor() {
    super();

    this.state = new State(DEFAULT_STATE);
    bindState(this.state, this);
  }

  setPointerType(pointerType: string) {
    let interactionMode: InteractionMode = InteractionMode.MOUSE;
    switch (pointerType) {
      case 'pen':
        interactionMode = InteractionMode.PEN;
        break;
      case 'touch':
        interactionMode = InteractionMode.TOUCH;
        break;
      case 'mouse':
      default:
        interactionMode = InteractionMode.MOUSE;
    }
    this.state.values.pointerType = pointerType;
    this.state.values.interactionMode = interactionMode;
  }

  inspectorData: InspectableClassData<this> = [];
}

export default InteractionContext;
