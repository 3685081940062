import React, { useContext } from 'react';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import PlantListOptionFilter from './plant-list-option-filter';

const TextPlantingCalendarOption = () => {
  const { options, setOptions } = useContext(GlobalPlantListContext);

  return (
    <PlantListOptionFilter
      label='Use Text Planting Calendars'
      id='plant-list-option:text-calendar'
      value={options.textPlantingCalendars}
      setValue={(checked) => {
        setOptions({ textPlantingCalendars: checked });
      }}
    />
  );
};

export default TextPlantingCalendarOption;
