import React, { ReactNode } from 'react';

import './form-section.scss';

interface iProps {
  heading?: ReactNode;
  subheading?: ReactNode;
  className?: string;
  children?: ReactNode;
  gap?: number;
  padding?: number;
  margin?: number;
}

const FormSection = ({ heading, subheading, className, children, gap = 12, padding = 24, margin = 15 }: iProps): JSX.Element => {
  const classNames = ['rforms-section'];
  if (className) {
    classNames.push(className);
  }

  const styles: Record<string, string> = {
    '--gap': `${gap}px`,
    '--padding': `${padding}px`,
    '--margin': `${margin}px`,
  };

  return (
    <div className={classNames.join(' ')} style={styles}>
      {heading && <h4 className='rforms-section-header'>{heading}</h4>}
      {subheading && <p className='rforms-section-subheader'>{subheading}</p>}
      {children}
    </div>
  );
};

export default FormSection;
