import React from 'react';
import networkConfig from '@gi/config/network-config/index.ts';

import Iframe from './utils/iframe';
import './journal.scss';

const Journal = () => {
  return <Iframe src={networkConfig.journalEmbedURL} />;
};

export default Journal;
