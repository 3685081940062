import React, { useCallback, useContext } from 'react';

import FilterRowGroup from '../../../filters/filter-row-group';
import FilterRowGroupTitle from '../../../filters/filter-row-group-title';
import FilterRow from '../../../filters/filter-row';
import { DrawingToolsContext } from '../../../drawing-tools-context';
import MonthDropdown from './month-dropdown';

import './planting-dates-filter-view.scss';

interface iProps {
  northernHemisphere: boolean;
}

const PlantingDatesFilterView = ({ northernHemisphere }: iProps): JSX.Element => {
  const { plantFilters, updatePlantFilters } = useContext(DrawingToolsContext);

  const { sowMonth, plantMonth, harvestMonth } = plantFilters.filters.plantingDates.inputs;

  const onSowMonthChange = useCallback(
    (opt: number | null) => {
      updatePlantFilters({ plantingDates: { sowMonth: opt } });
    },
    [updatePlantFilters]
  );

  const onPlantMonthChange = useCallback(
    (opt: number | null) => {
      updatePlantFilters({ plantingDates: { plantMonth: opt } });
    },
    [updatePlantFilters]
  );

  const onHarvestMonthChange = useCallback(
    (opt: number | null) => {
      updatePlantFilters({ plantingDates: { harvestMonth: opt } });
    },
    [updatePlantFilters]
  );

  return (
    <FilterRowGroup className='planting-dates-filter-view'>
      <FilterRowGroupTitle>Planting Times</FilterRowGroupTitle>
      <FilterRow>
        <label className='filter-row-label'>
          <span className='filter-row-label-text'>
            <span className='planting-calendar-indicator' style={{ backgroundColor: '#372bd6' }} /> Can sow in
          </span>
          <span className='filter-row-label-content'>
            <MonthDropdown value={sowMonth} northernHemisphere={northernHemisphere} onChange={onSowMonthChange} />
          </span>
        </label>
      </FilterRow>
      <FilterRow>
        <label className='filter-row-label'>
          <span className='filter-row-label-text'>
            <span className='planting-calendar-indicator' style={{ backgroundColor: '#0a870d' }} /> Can plant in
          </span>
          <span className='filter-row-label-content'>
            <MonthDropdown value={plantMonth} northernHemisphere={northernHemisphere} onChange={onPlantMonthChange} />
          </span>
        </label>
      </FilterRow>
      <FilterRow>
        <label className='filter-row-label'>
          <span className='filter-row-label-text'>
            <span className='planting-calendar-indicator' style={{ backgroundColor: '#e17a16' }} /> Can harvest in
          </span>
          <span className='filter-row-label-content'>
            <MonthDropdown value={harvestMonth} northernHemisphere={northernHemisphere} onChange={onHarvestMonthChange} />
          </span>
        </label>
      </FilterRow>
    </FilterRowGroup>
  );
};

export default PlantingDatesFilterView;
