import React from 'react';

import { TableViewMode } from '@gi/plant-list';

import MenuDropdownContent from '../../common/dropdown/menu-dropdown-content';
import MenuDropdownCheckboxOption from '../../common/dropdown/options/menu-dropdown-checkbox-option';
import MenuDropdownSectionTitle from '../../common/dropdown/menu-dropdown-section-title';
import usePlantListOptions from './use-plant-list-options';
import usePlantListFilters from './use-plant-list-filters';
import MenuDropdownSection from '../../common/dropdown/menu-dropdown-section';
import MenuDropdownRadioOption from '../../common/dropdown/options/menu-dropdown-radio-option';

/** Dropdown content for changing display options for the plant list */
const PlantListDisplayDropdownContent = (): JSX.Element => {
  const { activeFilters, updateAllFilters } = usePlantListFilters();
  const { setOption, getOption, columnEnabled, setColumnEnabled, isColumnRelevant } = usePlantListOptions();

  return (
    <MenuDropdownContent>
      {/* Display options */}
      <MenuDropdownSection>
        <MenuDropdownSectionTitle>Options</MenuDropdownSectionTitle>
        <MenuDropdownCheckboxOption
          value={activeFilters.filters.perennial.inputs.showPerennials}
          onChange={(enabled) => updateAllFilters({ perennial: { showPerennials: enabled } })}
        >
          Show Perennials
        </MenuDropdownCheckboxOption>
        <MenuDropdownCheckboxOption value={getOption('showPlantFamily')} onChange={setOption('showPlantFamily')}>
          Show Plant Family
        </MenuDropdownCheckboxOption>
        <MenuDropdownCheckboxOption value={getOption('showScientificName')} onChange={setOption('showScientificName')}>
          Show Scientific Name
        </MenuDropdownCheckboxOption>
        <MenuDropdownCheckboxOption value={getOption('textPlantingCalendars')} onChange={setOption('textPlantingCalendars')}>
          Use Text Planting Calendars
        </MenuDropdownCheckboxOption>
      </MenuDropdownSection>
      {/* Column options */}
      <MenuDropdownSection>
        <MenuDropdownSectionTitle>Show/Hide Columns</MenuDropdownSectionTitle>
        <MenuDropdownCheckboxOption value={columnEnabled('plantLabel')} onChange={setColumnEnabled('plantLabel')} hidden={!isColumnRelevant('plantLabel')}>
          Plant Label
        </MenuDropdownCheckboxOption>
        <MenuDropdownCheckboxOption value={columnEnabled('counts')} onChange={setColumnEnabled('counts')} hidden={!isColumnRelevant('counts')}>
          Quantity
        </MenuDropdownCheckboxOption>
        <MenuDropdownCheckboxOption value={columnEnabled('spacings')} onChange={setColumnEnabled('spacings')} hidden={!isColumnRelevant('spacings')}>
          Spacings
        </MenuDropdownCheckboxOption>
        <MenuDropdownCheckboxOption
          value={columnEnabled('inGroundDates')}
          onChange={setColumnEnabled('inGroundDates')}
          hidden={!isColumnRelevant('inGroundDates')}
        >
          In-Ground Dates
        </MenuDropdownCheckboxOption>
        <MenuDropdownCheckboxOption value={columnEnabled('calendar')} onChange={setColumnEnabled('calendar')} hidden={!isColumnRelevant('calendar')}>
          Calendar
        </MenuDropdownCheckboxOption>
        <MenuDropdownCheckboxOption value={columnEnabled('notes')} onChange={setColumnEnabled('notes')} hidden={!isColumnRelevant('notes')}>
          Plant Notes
        </MenuDropdownCheckboxOption>
      </MenuDropdownSection>
      {/* Display mode */}
      <MenuDropdownSection>
        <MenuDropdownSectionTitle>View Mode</MenuDropdownSectionTitle>
        <MenuDropdownRadioOption value={getOption('viewMode') === TableViewMode.AUTO} onChange={() => setOption('viewMode')(TableViewMode.AUTO)}>
          Automatic
        </MenuDropdownRadioOption>
        <MenuDropdownRadioOption value={getOption('viewMode') === TableViewMode.TABLE} onChange={() => setOption('viewMode')(TableViewMode.TABLE)}>
          Always as Table
        </MenuDropdownRadioOption>
        <MenuDropdownRadioOption value={getOption('viewMode') === TableViewMode.GRID} onChange={() => setOption('viewMode')(TableViewMode.GRID)}>
          Always as List
        </MenuDropdownRadioOption>
      </MenuDropdownSection>
    </MenuDropdownContent>
  );
};

export default PlantListDisplayDropdownContent;
