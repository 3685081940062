/**
 * Main model which stores all products available in a store
 */
export type ShoppingListProducts = {
  byProductId: Record<number, PlantProduct | GardenObjectProduct>; // ProductId => Product
  byVariantId: Record<number, number>; // VariantId => ProductId
  variantsByCode: Record<string, number[]>; // PlantCode/GardenObjectCode => VariantId[]
  productsByCode: Record<string, number[]>; // PlantCode/GardenObjectCode => ProductId[]
  variantsByPlantCodeAndVariety: Record<string, Record<string, number>>; // PlantCode => Variety => VariantId
};

export enum ProductType {
  Plant = 'Plant',
  GardenObject = 'GardenObject',
}

/**
 * Model which contains all non-generic details for a product
 */
export type ProductBase<T> = {
  type: string;
  productId: number;
  title: string;
  tags: string[]; // Array of tags, revised from API products
  images: string[]; // Array of URLs
  variantIds: number[];
  variants: Record<number, ProductVariant<T>>;
  description: string;
};

/**
 * Model which contains all non-generic details for a variant
 */
export type ProductVariant<T> = {
  variantId: number;
  name: string; // (title)
  properties: T; // Custom properties for a given product type
  price: number; // Int, in cents, no decimals
  image: string; // URL
  quantity: number;
  recommendedQuantityMultiplier: number;
  unit: string;
};

export type PlantProductData = {
  plantCode: string;
  varietyMatches: string[];
};

export type GardenObjectProductData = {
  code: string;
};

export type PlantProduct = ProductBase<PlantProductData> & { type: ProductType.Plant };
export type GardenObjectProduct = ProductBase<GardenObjectProductData> & { type: ProductType.GardenObject };

export type ShoppingListProduct = PlantProduct | GardenObjectProduct;

export class ShoppingListProductsUtils {
  static getVariant<T>(products: ShoppingListProducts, variantId: number): ProductVariant<T> {
    const productId = products.byVariantId[variantId];
    const product = products.byProductId[productId];
    return product.variants[variantId] as ProductVariant<T>;
  }
}
