import Node from '../../node';
import EventBus from '../../event-bus';
import { InspectableClassData } from '../../types';
import NodeComponent, { NodeComponentEventActions } from '../../node-component/node-component';

export enum DoubleClickEvent {
  OnDoubleClick = 'OnDoubleClick',
}

type DoubleClickEventActions = {
  [DoubleClickEvent.OnDoubleClick]: (node: Node) => void;
};

/**
 * Double-clickable component context
 *  Handles firing events when any node is double-clicked.
 */
class DoubleClickableComponentContext extends NodeComponent {
  type = 'DoubleClickableComponentContext';

  readonly eventBus: EventBus<NodeComponentEventActions & DoubleClickEventActions>;

  constructor() {
    super();

    this.eventBus = new EventBus(this.eventBus);
  }

  wasDoubleClicked<T extends Node>(node: T) {
    this.eventBus.emit(DoubleClickEvent.OnDoubleClick, node);
  }

  inspectorData: InspectableClassData<this> = [];
}

export default DoubleClickableComponentContext;
