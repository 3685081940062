import React from 'react';
import { Provider } from 'react-redux';

import { attachStoreListeners as attachCanvasStoreListeners, GardenCanvasController, GardenCanvasProvider } from '@gi/react-garden-canvas';

import { ConnectedResourceLoader } from '@gi/resource-provider';
import { SubscriptionGateway, SessionGateway, SessionRefresher } from '@gi/react-session';
import { PrintPlanProvider } from '@gi/plan-printing';
import { ModalRenderer } from '@gi/garden-platform-modal-renderer';
import { DrawingToolsProvider, DragToDrawProvider } from '@gi/drawing-tools';
import { GlobalPlantListProvider } from '@gi/plant-list';
import { ElementPrinterProvider } from '@gi/element-printer';
import { IntercomController, IntercomMiddlewareComponent } from '@gi/intercom';
import { InitParamHandler } from '@gi/init-params';
import { AppLoader } from '@gi/app-provider';
import { DisplayModeHandler } from '@gi/display-mode-provider';
import { FlagRestartDetector, FlagRestartDisplay } from '@gi/flag-restart';
import { TooltipProvider } from '@gi/tooltip';
import { TutorialProvider } from '@gi/tutorials';
import { GuruServiceDataSynchroniser } from '@gi/app-guru/source/service-synchroniser/guru-service-synchroniser';
import { AnalyticsController } from '@gi/analytics';
import { services } from '@gi/garden-platform-services';
import { FeaturesProvider } from '@gi/features';
import { TutorialProvider as NewTutoralProvider, TutorialDisplayRenderer } from '@gi/tutorial';
import { ObjectivesProvider } from '@gi/objectives';
import { KeyboardShortcutProvider } from '@gi/keyboard-shortcut';
import { GardenPlatformEventsProvider } from '@gi/garden-platform-events';
import { ShoppingListUpdater } from '@gi/shopping-list';
import { PlannerControlsProvider } from '@gi/planner-controls';

import attachStoreListeners from './store/attach-store-listeners';
import BugsnagController from './bugsnag/bugsnag-controller';
import AppSuiteErrorBoundary from './bugsnag/app-suite-error-boundary';

import store from './store/store';

const gardenCanvasController = new GardenCanvasController();
attachCanvasStoreListeners(store, services, gardenCanvasController);
attachStoreListeners(store, services);

interface iProps {
  children: React.ReactNode;
}

const GardenPlatformCore = ({ children }: iProps): JSX.Element => {
  return (
    <Provider store={store}>
      <KeyboardShortcutProvider>
        <GardenPlatformEventsProvider eventsSystem={services.eventsSystem}>
          <AppLoader>
            <FeaturesProvider>
              <NewTutoralProvider>
                <TutorialDisplayRenderer />
                <FlagRestartDisplay />
                <SessionGateway>
                  <SessionRefresher />
                  <SubscriptionGateway>
                    <BugsnagController>
                      <DisplayModeHandler />
                      <AnalyticsController disabled={false} />
                      <TooltipProvider>
                        <ElementPrinterProvider>
                          <ConnectedResourceLoader>
                            <GardenCanvasProvider gardenCanvasController={gardenCanvasController}>
                              <ObjectivesProvider>
                                <PrintPlanProvider>
                                  <AppSuiteErrorBoundary>
                                    <TutorialProvider>
                                      <InitParamHandler store={store} />
                                      <IntercomController />
                                      <DrawingToolsProvider>
                                        <DragToDrawProvider>
                                          <PlannerControlsProvider>
                                            <GlobalPlantListProvider>
                                              <ShoppingListUpdater />
                                              <GuruServiceDataSynchroniser />
                                              <ModalRenderer />
                                              <IntercomMiddlewareComponent />
                                              <FlagRestartDetector />
                                              <FlagRestartDisplay />
                                              {children}
                                            </GlobalPlantListProvider>
                                          </PlannerControlsProvider>
                                        </DragToDrawProvider>
                                      </DrawingToolsProvider>
                                    </TutorialProvider>
                                  </AppSuiteErrorBoundary>
                                </PrintPlanProvider>
                              </ObjectivesProvider>
                            </GardenCanvasProvider>
                          </ConnectedResourceLoader>
                        </ElementPrinterProvider>
                      </TooltipProvider>
                    </BugsnagController>
                  </SubscriptionGateway>
                </SessionGateway>
              </NewTutoralProvider>
            </FeaturesProvider>
          </AppLoader>
        </GardenPlatformEventsProvider>
      </KeyboardShortcutProvider>
    </Provider>
  );
};

export default GardenPlatformCore;
