import { Filter } from '@gi/filters';
import Plant from '@gi/plant';

type FavouritesFilterState = {
  enabled: boolean;
  favouritePlants: Set<string>;
};

const favouritesFilter: Filter<Plant, FavouritesFilterState> = {
  name: 'Favourites',
  inputs: { enabled: false, favouritePlants: new Set() },
  createListFilter: ({ enabled, favouritePlants }) => {
    return (plantList) => {
      if (!enabled) {
        return plantList;
      }

      // Filter list to only those favouritePlant codes
      return plantList.filter((plant) => favouritePlants.has(plant.code));
    };
  },
};

export default favouritesFilter;
