import React, { useContext } from 'react';

import { networkConfig } from '@gi/config';
import { AppContext } from '@gi/app-provider';

const LoadingLogo = (): JSX.Element | null => {
  const { runtimeConfig } = useContext(AppContext);

  // Just in case we try use the loading logo outsode of the AppContext, prevent errors.
  if (!runtimeConfig) {
    return null;
  }

  const src = `${networkConfig.assetBaseURL}${runtimeConfig.loadingLogo?.src ?? runtimeConfig.logo.src}`;

  return (
    <div className='loading-logo'>
      <img src={src} alt={runtimeConfig.clientName} style={{ height: runtimeConfig.loadingLogo?.height ?? runtimeConfig.logo.height }} />
    </div>
  );
};

export default LoadingLogo;
