import React, { forwardRef } from 'react';
import DesktopDropdownWindow from './desktop-dropdown-window';
import DesktopPlannerDropdownPortal from './desktop-planner-dropdown-portal';
import OpenPlanList from './open-plan-list/open-plan-list';

import PlanListControls from './open-plan-list/plan-list-controls';

interface iProps {
  target: HTMLElement;
  closeMenu: () => void;
}

const DesktopPlannerMenuDropdown = forwardRef<HTMLDivElement, iProps>(({ target, closeMenu }, refProp) => {
  return (
    <DesktopPlannerDropdownPortal>
      <DesktopDropdownWindow
        attachment={{
          locator: '',
          placement: 'bottom-start',
        }}
        target={target}
        ref={refProp}
      >
        <OpenPlanList closeMenu={closeMenu} />
        <PlanListControls closeMenu={closeMenu} />
      </DesktopDropdownWindow>
    </DesktopPlannerDropdownPortal>
  );
});
DesktopPlannerMenuDropdown.displayName = 'DesktopPlannerMenuDropdown';

export default DesktopPlannerMenuDropdown;
