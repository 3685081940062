import React, { useContext } from 'react';
import { useOutlet } from 'react-router-dom';
import { AppContext } from '@gi/app-provider';

import { networkConfig } from '@gi/config';
import { Sidebar, ContentContainer } from '@gi/garden-platform-sidebar';
import { Banners } from '@gi/banners';
import { NotificationDisplayContainer } from '@gi/notifications';
import { FeatureGate } from '@gi/features';

import PlatformStatusBar from './status-bar/platform-status-bar';
import TutorialEditorDesktop from './tutorial-editor/tutorial-editor-desktop';

import styles from './garden-platform-desktop.module.css';

const DesktopGardenPlatform = () => {
  const { runtimeConfig } = useContext(AppContext);
  const style = {
    backgroundImage: `url(${networkConfig.assetBaseURL}header-images/${runtimeConfig.backgroundImage.blurSrc})`,
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    overflow: 'hidden',
  };

  const outlet = useOutlet();

  return (
    <div className={styles.gardenPlatformDesktop} style={style}>
      <Sidebar />
      <ContentContainer>
        <Banners />
        <div className={styles.contentContainer}>
          {outlet}
          <NotificationDisplayContainer />
          <FeatureGate featureId='TUTORIAL_EDITOR'>
            <TutorialEditorDesktop />
          </FeatureGate>
        </div>
        <PlatformStatusBar />
      </ContentContainer>
    </div>
  );
};

export default DesktopGardenPlatform;
