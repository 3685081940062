import { Country } from './country';
import { CountryFromAPI } from './types';

const countryFromObject = (obj: CountryFromAPI): Country => {
  return {
    code: obj.countryCode,
    name: obj.countryName,
    dateFormat: obj.dateFormat,
    longDateFormat: obj.longDateFormat,
    artifactCode: obj.plantDataCountryCode,
    defaultArtifactCode: obj.defaultArtifactCode,
    availableArtifactCodes: obj.availableArtifactCodes,
    useRegionalPlantData: obj.useRegionalPlantData,
    northernHemisphere: obj.northernHemisphere,
    canLookupFrostDates: obj.canLookupFrostDates,
    defaultLastFrostDay: obj.defaultLastFrostDay,
    defaultFirstFrostDay: obj.defaultFirstFrostDay,
    latitude: obj.latitude,
    longitude: obj.longitude,
    googleMapsZoomLevel: obj.googleMapsZoomLevel,
    automaticSplitSeason: obj.automaticSplitSeason,
    regionalPostcodeValidationRegExpr: obj.regionalPostcodeValidationRegExpr,
  };
};

export default countryFromObject;
