import { networkConfig } from '@gi/config';
import { networkErrorReporter, networkService, RequestAuthMode } from '@gi/gi-network';
import { InputIntercomShortcutList } from './shortcuts/intercom-shortcut-types';
import { createIntercomShortcutInstructionMap } from './shortcuts/intercom-shortcuts';

class IntercomService {
  // eslint-disable-next-line class-methods-use-this
  loadIntercomConfig() {
    return networkService
      .get<InputIntercomShortcutList[]>(networkConfig.intercomShortcuts, {}, RequestAuthMode.Bearer)
      .then((response) => {
        return createIntercomShortcutInstructionMap(response.body);
      })
      .catch(networkErrorReporter('GET', 'intercom config'));
  }
}

export default IntercomService;
