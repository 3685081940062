import React, { ReactNode } from 'react';

import styles from './menu-dropdown-option.module.css';

interface iProps {
  icon?: string;
  children?: ReactNode;
  onClick?: () => void;
}

const MenuDropdownOption = ({ children, icon, onClick }: iProps): JSX.Element => {
  return (
    <button type='button' className={styles.dropdownOption} onClick={onClick}>
      <span className={styles.icon}>{icon ? <i className={icon} /> : null}</span>
      <span className={styles.content}>{children}</span>
    </button>
  );
};

export default MenuDropdownOption;
