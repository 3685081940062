import { randomUUID } from '@gi/utils';
import { ProductType, ShoppingListProducts, ShoppingListProductsUtils } from './shopping-product';
import { GardenObjectEntry, GardenObjectShoppingEntry, PlantEntry, PlantShoppingEntry, ShoppingEntryUtils, ShoppingListAnalytics } from './shopping-entry';
import { ShoppingListItems, ShoppingListItemsUtils } from './shopping-list-items';

export type ShoppingList = {
  planId: number;
  plants: ShoppingListItems<PlantEntry>;
  gardenObjects: ShoppingListItems<GardenObjectEntry>;
};

export class ShoppingListUtils {
  static create(planId: number): ShoppingList {
    return {
      planId,
      plants: ShoppingListItemsUtils.create(),
      gardenObjects: ShoppingListItemsUtils.create(),
    };
  }

  static setEntry(shoppingList: ShoppingList, entry: PlantShoppingEntry | GardenObjectShoppingEntry) {
    if (entry.type === ProductType.Plant) {
      ShoppingListItemsUtils.setEntry(shoppingList.plants, entry as PlantShoppingEntry);
    } else if (entry.type === ProductType.GardenObject) {
      ShoppingListItemsUtils.setEntry(shoppingList.gardenObjects, entry as GardenObjectShoppingEntry);
    }
  }

  static clearEntry(shoppingList: ShoppingList, uuid: string) {
    if (shoppingList.plants.entriesByUuid[uuid]) {
      ShoppingListItemsUtils.removeEntry(shoppingList.plants, uuid);
    }

    if (shoppingList.gardenObjects.entriesByUuid[uuid]) {
      ShoppingListItemsUtils.removeEntry(shoppingList.gardenObjects, uuid);
    }
  }

  /**
   * Splits a plant entry with multiple varieties by removing a single variety into a new ShoppingListEntry
   */
  static splitPlantEntry(shoppingList: ShoppingList, uuid: string, variety: string, shoppingListProducts: ShoppingListProducts) {
    const originalEntry = shoppingList.plants.entriesByUuid[uuid];

    const varietiesData = originalEntry.matchData.varieties.filter((varietyData) => varietyData.name === variety);
    const updatedVarietiesData = originalEntry.matchData.varieties.filter((varietyData) => varietyData.name !== variety);

    if (varietiesData.length === 0) {
      return;
    }

    if (varietiesData.length !== 1) {
      throw new Error('Invalid plant entry');
    }

    const varietyData = varietiesData[0];

    const newEntry: PlantShoppingEntry = {
      ...originalEntry,
      uuid: randomUUID(),
      inputQuantity: varietyData.count,
      matchData: {
        plantCode: originalEntry.matchData.plantCode,
        varieties: [{ ...varietyData }],
      },
    };

    const updatedEntry: PlantShoppingEntry = {
      ...originalEntry,
      inputQuantity: originalEntry.inputQuantity - varietyData.count,
      matchData: {
        plantCode: originalEntry.matchData.plantCode,
        varieties: updatedVarietiesData,
      },
    };

    const variant1 = ShoppingListProductsUtils.getVariant(shoppingListProducts, newEntry.variantId);
    ShoppingEntryUtils.setRecommendedQuantity(newEntry, variant1);
    const variant2 = ShoppingListProductsUtils.getVariant(shoppingListProducts, updatedEntry.variantId);
    ShoppingEntryUtils.setRecommendedQuantity(updatedEntry, variant2);

    ShoppingListUtils.setEntry(shoppingList, newEntry);
    ShoppingListUtils.setEntry(shoppingList, updatedEntry);
  }

  /**
   * Merges ShoppingListEntry's with the same product/variants into a single Entry
   */
  static mergeDuplicatePlantEntries(shoppingList: ShoppingList, productId: number, variantId: number, shoppingListProducts: ShoppingListProducts) {
    const entries: PlantShoppingEntry[] = shoppingList.plants.uuids
      .map((uuid) => shoppingList.plants.entriesByUuid[uuid])
      .filter((entry) => entry.productId === productId && entry.variantId === variantId);

    if (entries.length <= 1) {
      return;
    }

    // Create merged entry
    const mergedEntry = entries.reduce(
      (prev, curr) => {
        curr.matchData.varieties.forEach((variety) => {
          ShoppingEntryUtils.addVarietyToPlantEntry(prev, variety.name, variety.count);
        });

        return prev;
      },
      ShoppingEntryUtils.createPlantEntry(entries[0].productId, entries[0].variantId, entries[0].matchData.plantCode)
    );

    // Removed merged entries
    for (let i = 0; i < entries.length; i++) {
      ShoppingListUtils.clearEntry(shoppingList, entries[i].uuid);
    }

    const variant1 = ShoppingListProductsUtils.getVariant(shoppingListProducts, mergedEntry.variantId);
    ShoppingEntryUtils.setRecommendedQuantity(mergedEntry, variant1);
    ShoppingListUtils.setEntry(shoppingList, mergedEntry);
  }

  static getAnalytics(shoppingList: ShoppingList): ShoppingListAnalytics {
    const result: ShoppingListAnalytics = [];

    shoppingList.gardenObjects.uuids.forEach((uuid) => {
      result.push(ShoppingEntryUtils.getAnalytics(shoppingList.gardenObjects.entriesByUuid[uuid]));
    });

    shoppingList.plants.uuids.forEach((uuid) => {
      result.push(ShoppingEntryUtils.getAnalytics(shoppingList.plants.entriesByUuid[uuid]));
    });

    return result;
  }
}
