import React, { ReactNode } from 'react';

import MobileTopbarMenuButton from './mobile-topbar-menu-button';
import MobileTopbarIntercomButton from './mobile-topbar-intercom-button';

import styles from './mobile-topbar-content-with-buttons.module.css';

interface iProps {
  className?: string;
  children?: ReactNode;
}

/**
 * Supplies the menu hamburger and intercom buttons, and places children to the side
 * Use for custom topbars that don't follow the Title/Subtitle format
 * Otherwise use DefaultMobileTopbar
 */
const MobileTopbarContentWithButtons = ({ className, children }: iProps): JSX.Element => {
  const classNames = [styles.container];
  if (className) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      <div className={styles.buttons}>
        <MobileTopbarMenuButton />
        <MobileTopbarIntercomButton />
      </div>
      {children}
    </div>
  );
};

export default MobileTopbarContentWithButtons;
