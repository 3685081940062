import React, { useContext, useEffect, useState } from 'react';

import { ResourceContext, ResourceLoaderContext } from '@gi/resource-provider';
import TemplatePlan from '@gi/template-plan';
import FormField, { FormLayout, FormRadioField, FormRadioFieldset, FormSection, FormValues, InputContainer } from '@gi/form-responsive';
import { ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';
import { User, UserUtils } from '@gi/user';
import PlannerIcon from '@gi/planner-icon';
import Plant from '@gi/plant';
import { LoadingState } from '@gi/constants';
import { Tooltip, TooltipContent, TooltipTrigger } from '@gi/tooltip';

import { TemplatePlanPreviewModal } from './template-plan-preview-modal';
import { PlanYearInput, UNITS_INPUT_LAYOUT, YearOption } from './common';

import './template-plan-tab.scss';
// TODO: Move to modal defaults somewhere
const DEFAULT_MODAL_FORM_SECTION_PADDING = 12;
const DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT = { labelSize: 120 };

export type TemplatePlanFormValues = {
  name: string;
  year: number;
  metricDimensions: boolean;
  selectedTemplatePlan: null | TemplatePlan;
};

function getTemplatePlanPlantCounts(templatePlan: TemplatePlan): string[] {
  return [...new Set(templatePlan.planDocument.planPlants.map((plant) => plant.plantCode))];
}

interface iTemplatePlanPlantProps {
  plant: Plant;
}

const TemplatePlanPlant = ({ plant }: iTemplatePlanPlantProps): JSX.Element => {
  return (
    <Tooltip placement='bottom'>
      <TooltipTrigger>
        <div className='template-plant-icon'>
          <PlannerIcon code={plant.code} halfSize />
        </div>
      </TooltipTrigger>
      <TooltipContent>{plant.name}</TooltipContent>
    </Tooltip>
  );
};

interface iTemplatePlanPlantsProps {
  templatePlan: TemplatePlan;
}

const TemplatePlanPlants = ({ templatePlan }: iTemplatePlanPlantsProps): JSX.Element => {
  const plants = getTemplatePlanPlantCounts(templatePlan);
  const { userPlants } = useContext(ResourceContext);

  // Filter agains user plants as some of the template plans have plants which don't exist in
  // the given country
  // TODO: Remove extra plants from these plans
  return (
    <div className='template-plan-plants'>
      {plants
        .map((plantCode) => userPlants?.get(plantCode))
        .filter((plant) => plant !== null && plant !== undefined)
        .map((plant: Plant) => (
          <TemplatePlanPlant key={plant.code} plant={plant} />
        ))}
    </div>
  );
};

interface iTemplatePlanProps {
  templatePlan: TemplatePlan;
  select: (templatePlan: TemplatePlan | null) => void;
  setTemplatePlanPreivew: (templatePlan: TemplatePlan) => void;
  selected?: boolean;
}

const TemplatePlanListItem = ({ templatePlan, select, setTemplatePlanPreivew, selected = false }: iTemplatePlanProps): JSX.Element => {
  return (
    <div className='template-plan'>
      <div className='template-plan-display'>
        <div className='plan-information'>
          <div className='plan-name'>{templatePlan.planName}</div>
          <div className='plan-description'>{templatePlan.planDescription}</div>
          <div className='plan-plants'>
            <TemplatePlanPlants templatePlan={templatePlan} />
          </div>
        </div>
        <div className='plan-preview-image'>
          <button
            type='button'
            className='plan-preview-button'
            onClick={() => {
              setTemplatePlanPreivew(templatePlan);
            }}
          >
            <img src={templatePlan.planIconURL} alt={`${templatePlan.planName} preview`} />
            <span className='preview-icon-container'>
              <span className='preview-icon'>
                <i className='icon-zoom-in' />
              </span>
            </span>
          </button>
        </div>
      </div>
      <div className='buttons'>
        <div className='buttons-left' />
        <div className='buttons-right'>
          <button
            type='button'
            className={`button button-${selected ? 'secondary' : 'primary'} button-small`}
            onClick={() => {
              select(selected ? null : templatePlan);
            }}
          >
            {selected ? 'Unselect' : 'Select'}
          </button>
        </div>
      </div>
    </div>
  );
};

const LoadingTemplatePlans = () => {
  return (
    <div className='loading-template-plans'>
      <ModalPaneSection>
        <ModalPaneSectionContent>
          Loading <i className='icon-spinner animate-pulse' />;
        </ModalPaneSectionContent>
      </ModalPaneSection>
    </div>
  );
};

const EmptyTemplatePlans = () => {
  return (
    <div className='empty-template-plans'>
      <ModalPaneSection>
        <ModalPaneSectionContent>There are no template plans available for your area.</ModalPaneSectionContent>
      </ModalPaneSection>
    </div>
  );
};

interface iTemplatePlanTabProps {
  templatePlanFormValues: FormValues<TemplatePlanFormValues>;
  setTemplatePlanFormValues: (formValues: FormValues<TemplatePlanFormValues>) => void;
  user: User;
}

const TemplatePlanTab = ({ templatePlanFormValues, setTemplatePlanFormValues, user }: iTemplatePlanTabProps): JSX.Element => {
  const { name, year, metricDimensions, selectedTemplatePlan } = templatePlanFormValues.values;

  const { templatePlans } = useContext(ResourceLoaderContext);
  const [templatePlanPreivew, setTemplatePlanPreivew] = useState<TemplatePlan | null>(null);

  useEffect(() => {
    templatePlans.loadIfNeeded();
  }, []);

  const setSelectedTemplatePlan = (newSelectedTemplatePlan: TemplatePlan) => {
    setTemplatePlanFormValues(
      templatePlanFormValues.setValues(
        ['selectedTemplatePlan', { value: newSelectedTemplatePlan }],
        ['name', { value: newSelectedTemplatePlan === null ? '' : newSelectedTemplatePlan.planName }]
      )
    );
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplatePlanFormValues(templatePlanFormValues.setValue('name', { value: e.target.value }));
  };

  const onYearChange = (option: YearOption) => {
    setTemplatePlanFormValues(templatePlanFormValues.setValue('year', { value: option.value }));
  };

  // Handles changing metric/imperial
  const onDimensionUnitsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemplatePlanFormValues(templatePlanFormValues.setValue('metricDimensions', { value: e.target.value === 'metric' }));
  };

  let content: JSX.Element | null = null;

  if (selectedTemplatePlan !== null) {
    content = (
      <div className='selected-template-plan-container'>
        <ModalPaneSection>
          <ModalPaneSectionContent>
            <TemplatePlanListItem
              selected
              templatePlan={selectedTemplatePlan}
              select={setSelectedTemplatePlan}
              setTemplatePlanPreivew={setTemplatePlanPreivew}
            />
          </ModalPaneSectionContent>
        </ModalPaneSection>
        <ModalPaneSection>
          <ModalPaneSectionContent>
            <FormSection className='form-section-background' padding={DEFAULT_MODAL_FORM_SECTION_PADDING}>
              <FormField
                required
                htmlFor='template-name'
                label='Name'
                errorText={templatePlanFormValues.hasBeenEdited ? templatePlanFormValues.fields.name.errors.join(', ') : ''}
                invalid={!templatePlanFormValues.fields.name.valid}
                desktopLayout={DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT}
              >
                <InputContainer>
                  <input id='template-name' type='text' value={name} onChange={onNameChange} maxLength={100} minLength={1} />
                </InputContainer>
              </FormField>
              <PlanYearInput
                errors={templatePlanFormValues.fields.year.errors}
                northernHemisphere={UserUtils.isNorthernHemisphere(user)}
                year={year}
                onYearChange={onYearChange}
              />
              <FormRadioFieldset label='Length Units' layout='inline' fakeLabel desktopLayout={DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT}>
                <FormLayout layoutPreset={UNITS_INPUT_LAYOUT}>
                  <FormRadioField htmlFor='metric-template' label='Metric'>
                    <input id='metric-template' type='radio' value='metric' checked={metricDimensions} onChange={onDimensionUnitsChange} />
                  </FormRadioField>
                  <FormRadioField htmlFor='imperial-template' label='Imperial'>
                    <input id='imperial-template' type='radio' value='imperial' checked={!metricDimensions} onChange={onDimensionUnitsChange} />
                  </FormRadioField>
                </FormLayout>
              </FormRadioFieldset>
            </FormSection>
          </ModalPaneSectionContent>
        </ModalPaneSection>
      </div>
    );
  } else if (templatePlans.status === LoadingState.LOADING) {
    content = <LoadingTemplatePlans />;
  } else if (templatePlans.status === LoadingState.SUCCESS) {
    if (templatePlans.value.length === 0) {
      content = <EmptyTemplatePlans />;
    } else {
      content = (
        <div className='template-plan-list'>
          <ModalPaneSection>
            <ModalPaneSectionContent>
              {templatePlans.value.map((templatePlan) => (
                <TemplatePlanListItem
                  key={templatePlan.templateID}
                  templatePlan={templatePlan}
                  select={setSelectedTemplatePlan}
                  setTemplatePlanPreivew={setTemplatePlanPreivew}
                />
              ))}
            </ModalPaneSectionContent>
          </ModalPaneSection>
        </div>
      );
    }
  } else {
    // This probably shouldn't happen, may indicate something went wrong loading the template plans
    // We could possibly add a 'retry' button here to attempt to load the plans again
    content = <LoadingTemplatePlans />;
  }

  return (
    <div className='template-plan-tab'>
      <p>Start with one of our pre&#8209;planned gardens and tweak it to meet your needs.</p>
      {templatePlanPreivew ? (
        <TemplatePlanPreviewModal
          templatePlan={templatePlanPreivew}
          onClose={() => {
            setTemplatePlanPreivew(null);
          }}
        />
      ) : null}
      {content}
    </div>
  );
};

export default TemplatePlanTab;
