import React from 'react';
import { useSelector } from 'react-redux';

import Modal, { ModalContent, ModalHeader, ModalHeaderContent, ModalHeaderTitle, ModalPane, ModalPaneContainer, ModalPaneContent } from '@gi/modal';
import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';

import InteractionModePreview from './interaction-mode-preview';

import styles from './touch-introduction-modal.module.scss';

interface iProps {
  closeModal: (touchMode: boolean) => void;
}

const TouchIntroductionModal = ({ closeModal }: iProps): JSX.Element => {
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  const optionsContainerClassNames: string[] = [styles.optionsContainer];
  if (deviceDisplayMode === DeviceDisplayMode.MOBILE) {
    optionsContainerClassNames.push(styles.mobile);
  }

  return (
    <Modal closeRequest={() => {}}>
      <ModalContent>
        {/* Header */}
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>How do you use the Garden Planner?</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        {/* Content */}
        <ModalPaneContainer className={styles.modal}>
          <ModalPane className={styles.modalContent}>
            <ModalPaneContent>
              <div className={optionsContainerClassNames.join(' ')}>
                {/* Pointer Mode Option */}
                <div className={styles.option}>
                  <div className={styles.optionPreview} aria-label='Preview of the Garden Planner without Touch Mode' role='presentation'>
                    <InteractionModePreview />
                  </div>
                  <button type='button' className={`new-button new-button-primary ${styles.optionButton}`} onClick={() => closeModal(false)}>
                    I&apos;m using a mouse, trackpad or pencil <i className='icon-angle-right' />
                  </button>
                  <p className={styles.optionDescription}>Handles and outlines will have their default appearance.</p>
                </div>
                {/* Touch Mode Option */}
                <div className={styles.option}>
                  <div className={styles.optionPreview} aria-label='Preview of the Garden Planner with Touch Mode' role='presentation'>
                    <InteractionModePreview touchMode />
                  </div>
                  <button type='button' className={`new-button new-button-primary ${styles.optionButton}`} onClick={() => closeModal(true)}>
                    I&apos;m using a touchscreen <i className='icon-angle-right' />
                  </button>
                  <p className={styles.optionDescription}>Handles and outlines will be bigger.</p>
                </div>
              </div>
              {/* How to change touch mode */}
              <div className={styles.settingsText}>
                <p>You can change this at any time by enabling/disabling Touch Mode in settings.</p>
                <span className={styles.settingsPath}>
                  <span>
                    <i className='icon-cog' /> Settings
                  </span>
                  <i className='icon-right-dir' />
                  <span>Device</span>
                  <i className='icon-right-dir' />
                  <span>Interaction</span>
                  <i className='icon-right-dir' />
                  <span>Touch Mode</span>
                </span>
              </div>
            </ModalPaneContent>
          </ModalPane>
        </ModalPaneContainer>
      </ModalContent>
    </Modal>
  );
};

export default TouchIntroductionModal;
