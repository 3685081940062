import { Filter } from '@gi/filters';
import Plant from '@gi/plant';

type SFGFilterState = {
  enabled: boolean;
  sfgModeEnabled: boolean;
};

const sfgFilter: Filter<Plant, SFGFilterState> = {
  name: 'SFG Filter',
  inputs: { enabled: false, sfgModeEnabled: false },
  createListFilter: ({ enabled, sfgModeEnabled }) => {
    return (plantList) => {
      if (!sfgModeEnabled || !enabled) {
        return plantList;
      }

      return plantList.filter((plant) => {
        return plant.canBeSquareFootPlant;
      });
    };
  },
};

export default sfgFilter;
