import { planBackgroundImageService } from '@gi/plan-background-image';

import forecastService from './source/forecast-service';
import guruService from './source/guru-service';
import journalService from './source/journal-service';
import newsService from './source/news-service';
import planService from './source/plan-service';
import reminderService from './source/reminder-service';
import suggestedArticleService from './source/suggested-article-service';
import supportService from './source/support-service';
import userService from './source/user-service';
import objectivesService from './source/objectives-service';
import eventsSystem from './source/events-system';
import tooltipService from './source/tooltip-service';

import type { ThunkExtraArgs, ThunkServices } from './source/types';

const services: ThunkServices = {
  forecastService,
  guruService,
  journalService,
  newsService,
  planService,
  reminderService,
  suggestedArticleService,
  supportService,
  userService,
  objectivesService,
  eventsSystem,
  tooltipService,
  planBackgroundImageService,
};

export {
  forecastService,
  guruService,
  journalService,
  newsService,
  planService,
  reminderService,
  suggestedArticleService,
  supportService,
  userService,
  objectivesService,
  ThunkExtraArgs,
  ThunkServices,
  services,
  eventsSystem,
  tooltipService,
  planBackgroundImageService,
};
