/**
 * Converts a mm value to DPT point value
 * DPT Points: https://en.wikipedia.org/wiki/Point_(typography)
 *
 * @param mm value in mm
 * @returns value in DPT
 */
export function mmToDPT(mm: number): number {
  return mm * 2.834467;
}
