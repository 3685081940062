import { Filter } from '@gi/filters';
import GardenObject from '@gi/garden-object';
import { SearchResults } from '@gi/search-service';

type GardenObjectSearchFilterState = {
  searchTerm: string;
  searchResults: SearchResults<GardenObject>;
};

const gardenObjectSearchFilter: Filter<GardenObject, GardenObjectSearchFilterState> = {
  name: 'Object Search',
  inputs: { searchTerm: '', searchResults: new SearchResults([]) },
  createListFilter:
    ({ searchTerm, searchResults }) =>
    (objectList) => {
      const results = searchResults.getResults();

      // Return early if no results or empty query
      if (searchTerm.trim() === '') {
        return objectList;
      }
      if (Object.keys(results).length === 0) {
        return [];
      }

      // Filter against the subset we've been passed to allow us to combine with other filters
      // Also re-sort by search position.
      return objectList
        .filter((obj) => results[obj.code])
        .sort((objA, objB) => {
          const scoreA = results[objA.code].score;
          const scoreB = results[objB.code].score;
          if (scoreA === scoreB) {
            return 0;
          }
          return scoreA > scoreB ? -1 : 1;
        });
    },
};

export default gardenObjectSearchFilter;
