import React, { useMemo } from 'react';
import { GuruContentType } from '@gi/app-guru-types';
import { NavLink } from 'react-router-dom';
import { createContentURL } from '@gi/app-guru-common';

interface iProps {
  type: GuruContentType;
  slug: string;
  children: React.ReactNode;
}

const SmallPreviewLink = ({ type, slug, children }: iProps) => {
  const link = useMemo(() => {
    return createContentURL(type, slug);
  }, [type, slug]);

  return <NavLink to={link}>{children}</NavLink>;
};

export default SmallPreviewLink;
