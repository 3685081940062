import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuggestedArticle, SuggestedArticles, SuggestedArticlesNetworkActionCreators } from '@gi/suggested-articles';
import DashboardItem from '../../dashboard-item/dashboard-item';
import DashboardItemSection from '../../dashboard-item/dashboard-item-section';

import './article-dashboard-item.scss';

const ArticleDashboardItem = (): JSX.Element => {
  const dispatch = useDispatch();

  const articles: null | SuggestedArticle[] = useSelector((state: any) => state.suggestedArticles.articles);

  useEffect(() => {
    dispatch(SuggestedArticlesNetworkActionCreators.loadSuggestedArticles(6));
  }, []);

  const [leftArticles, rightArticles] = useMemo(() => {
    return [articles === null ? new Array(3).fill(null) : articles.slice(0, 3), articles === null ? new Array(3).fill(null) : articles.slice(3, 6)];
  }, [articles]);

  return (
    <DashboardItem title='Articles' className='article-list'>
      <DashboardItemSection>
        <SuggestedArticles articles={leftArticles} />
      </DashboardItemSection>
      <DashboardItemSection>
        <SuggestedArticles articles={rightArticles} />
      </DashboardItemSection>
    </DashboardItem>
  );
};

export default ArticleDashboardItem;
