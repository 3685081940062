import Country from '@gi/country';
import CountryRegion from '@gi/country-region';

const AppAccountPageNames = ['subscriptions', 'settings', 'location', 'emails', 'account'] as const;
export type AppAccountPageName = (typeof AppAccountPageNames)[number];

export type UserPasswordChange = {
  password: string;
  repeatPassword: string;
};

export type UserEmailSettings = {
  firstName: string;
  email: string;
  reminderEmails: boolean;
  includePerennialReminders: boolean;
  tutorialEmails: boolean;
  offerEmails: boolean;
};

export type UserLocationFrostDatesSettings = {
  country: Country;
  region: CountryRegion | null;
  latitude: number;
  longitude: number;
  noFrost: boolean;
  lastFrost: number | null;
  firstFrost: number | null;
  splitSeason: boolean;
};

/**
 * Same as an error, but indicates that its message should be displayed to the user.
 * Check if (error instanceof DisplayableError), then display error.message
 */
export class DisplayableError extends Error {}
