let initiatedAPI = false;
let completedInitiation = false;
const onReadyCallbacks: (() => void)[] = [];

export const startYouTubeApi = () => {
  if (initiatedAPI) {
    // API has already been created
    return;
  }

  initiatedAPI = true;

  // Add required script to header
  const tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  const head = document.getElementsByTagName('head')[0];
  if (head) {
    head.appendChild(tag);
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.onYouTubeIframeAPIReady = () => {
    completedInitiation = true;
    onReadyCallbacks.forEach((cb) => {
      cb();
    });
  };
};

export const whenYouTubeAPIReady = (): Promise<void> => {
  if (!initiatedAPI) {
    startYouTubeApi();
  }

  return new Promise((resolve) => {
    if (completedInitiation) {
      resolve();
      return;
    }

    onReadyCallbacks.push(() => {
      resolve();
    });
  });
};
