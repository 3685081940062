import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { networkConfig } from '@gi/config';
import { UserUtils } from '@gi/user';
import { PlantingCalendarDisplay, PlantingCalendarKey, getModifierFromList } from '@gi/planting-calendar';
import { FormSection } from '@gi/form-responsive';

import { ResourceContext } from '@gi/resource-provider';
import { SessionSelectors } from '@gi/react-session';

import { EditPlantContext } from '../context/edit-plant-context';

import './plan-plant-calendar.scss';

const PlanPlantInformation = (): JSX.Element | null => {
  const { plant, planPlant, editPlantValues } = useContext(EditPlantContext);
  const user = useSelector(SessionSelectors.getUser);

  if (user === null) {
    return null;
  }

  const modifier = getModifierFromList(planPlant.modifiers);

  const { getPlantingCalendar, userArtifactCode } = useContext(ResourceContext);
  const plantingCalendar = getPlantingCalendar(plant.code, modifier, editPlantValues.values.variety);

  return (
    <FormSection padding={12} gap={6} className='form-section-background plan-plant-calendar' heading='Planting Times'>
      <PlantingCalendarDisplay
        plantingCalendar={plantingCalendar}
        startMonth={UserUtils.isNorthernHemisphere(user) ? 1 : 7}
        calendarBarURL={networkConfig.plantingCalendarImageURL}
        compact
        artifactCode={userArtifactCode === null ? 'us' : userArtifactCode}
      />
      <PlantingCalendarKey />
    </FormSection>
  );
};

export default PlanPlantInformation;
