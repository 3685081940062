import { DependencyList, useEffect, useRef } from 'react';

/**
 * Creates a ref to the given item. Will automatically update whenever item does.
 * Can be used as a reference to something dynamic from within a callback that can't re-render.
 * @param item The item to store a ref of
 * @param dependencies The dependencies that will cause this ref to recalculate. Defaults to changing whenever item does.
 * @returns A ref, which will always point to the latest version of item
 */
const useAsRef = <T>(item: T, dependencies: DependencyList = [item]) => {
  const ref = useRef<T>(item);

  useEffect(() => {
    ref.current = item;
  }, dependencies);

  return ref;
};

export default useAsRef;
