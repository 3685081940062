import React from 'react';

import {
  Modal,
  ModalPane,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalHeaderContent,
  ModalHeaderTitle,
} from '@gi/modal';

import './close-plan-modal.scss';

interface iProps {
  closeModal: () => void;
  onConfirm: () => void;
}

const CloseTabModal = ({ closeModal, onConfirm }: iProps): JSX.Element => {
  return (
    <Modal className='close-tab-modal modal-warning' closeRequest={closeModal}>
      <ModalContent>
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Close Plan Without Saving</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>Your plan has unsaved changes, do you want to close it?</ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={closeModal}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-warning' onClick={onConfirm}>
                Close without Saving
              </button>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CloseTabModal;
