const KGS_IN_1LB = 0.45359237; // Code comments say the journal uses 0.454, but it seems to use the more accurate 0.45359237

/**
 * Rounds a number to the given amount of decimal places.
 * May not be 100% perfect due to floating-point, but should be good enough to prevent cumulative imprecision.
 */
const roundToDecimal = (number: number, decimals: number): number => {
  const factor = 10 ** decimals;
  return Math.round((number + Number.EPSILON) * factor) / factor;
};

/**
 * Converts grams to pounds and ounces
 * NOTE: This conversion is copied from another part of the garden planner journal code.
 * @param grams The weight in grams
 * @returns An imperial weight
 */
export const gramsToLbs = (grams: number): ImperialWeight => {
  const isNegative = grams < 0;
  const positiveGrams = Math.abs(grams);
  // via https://stackoverflow.com/a/825508
  const kilos = positiveGrams / 1000;
  const nearExact = kilos / KGS_IN_1LB;
  const pounds = Math.floor(nearExact);
  const ounces = roundToDecimal((nearExact - pounds) * 16, 2);
  return {
    pounds: isNegative ? -pounds : pounds,
    ounces: isNegative ? -ounces : ounces,
  };
};

/**
 * Converts pounds and oz to grams
 * @param imperialWeight The pounds and ounces to convert
 * @returns A weight in KG
 */
export const lbsToGrams = (imperialWeight: ImperialWeight) => {
  return (imperialWeight.pounds + imperialWeight.ounces / 16) * KGS_IN_1LB * 1000;
};

/**
 * Formats the given weight in grams as a string
 */
export const formatWeightMetric = (grams: number): string => {
  return `${Math.round(grams)}g`;
};

/**
 * Formats the given imperial weight as a string
 */
export const formatWeightImperial = (imperialWeight: ImperialWeight): string => {
  let oz = Number.isInteger(imperialWeight.ounces) ? imperialWeight.ounces : imperialWeight.ounces.toFixed(2);
  oz = `${oz}oz`;
  if (imperialWeight.pounds === 0) {
    return oz;
  }
  return `${imperialWeight.pounds}lb ${imperialWeight.ounces > 0 ? oz : ''}`.trim();
};

/**
 * Formats the given weight as a string. Accepts imperial or metric units.
 */
export const formatWeight = (weight: number | ImperialWeight): string => {
  if (typeof weight === 'number') {
    return formatWeightMetric(weight);
  }
  return formatWeightImperial(weight);
};

/**
 * Returns a formatted string representing the weight.
 * @param weight The weight to format (in grams)
 * @param metric If the format should be metric or not
 * @returns A formatted string representing the weight
 */
export const getWeightString = (weight: number, metric: boolean) => {
  if (metric) {
    return formatWeightMetric(weight);
  }

  const imp = gramsToLbs(weight);
  return formatWeightImperial(imp);
};

/**
 * Get the total of a set of metric weights
 */
const getTotalWeightMetric = (weights: number[]): number => {
  return weights.reduce((total, weight) => total + weight, 0);
};

/**
 * Get the total of a set of metric weights, converting them to imperial and then totalling.
 */
const getTotalWeightImperial = (weights: number[]): ImperialWeight => {
  const reduced = weights.reduce(
    (total, weight) => {
      const asImperial = gramsToLbs(weight);
      return {
        pounds: total.pounds + asImperial.pounds,
        ounces: total.ounces + asImperial.ounces,
      };
    },
    { pounds: 0, ounces: 0 }
  );
  return {
    pounds: reduced.pounds + Math.floor(reduced.ounces / 16),
    ounces: reduced.ounces % 16,
  };
};

/**
 * Sums a list of weights together.
 * If metric is false, the weights are converted to imperial FIRST, then summed.
 * This prevents differences between expected and total output due to rounding at each step.
 * @param weights The weights (in metric grams) to sum
 * @param metric If the output should be in metric.
 */
export function getTotalWeight(weights: number[], metric: true): number;
export function getTotalWeight(weights: number[], metric: false): ImperialWeight;
export function getTotalWeight(weights: number[], metric: boolean): number | ImperialWeight {
  if (metric) {
    return getTotalWeightMetric(weights);
  }
  return getTotalWeightImperial(weights);
}

/**
 * Checks if the given weight is positive. Accepts metric or imperial weights.
 */
export const isWeightPositive = (weight: number | ImperialWeight): boolean => {
  if (typeof weight === 'number') {
    return weight > 0;
  }
  return weight.pounds > 0 || weight.ounces > 0;
};
