import React from 'react';

import './planting-calendar-key.scss';

const PlantingCalendarKeyOutdoor = () => {
  return (
    <span className='plant-key planting-calendar-key'>
      <span className='color-box' />
      Sow/Plant Outdoors
    </span>
  );
};

export default PlantingCalendarKeyOutdoor;
