import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { TooltipKey } from '@gi/tooltip';

import { PlannerCommandContext } from '../../../planner-command-provider';
import MenuButton from './menu-button';
import MenuIcon from './menu-icon';

import style from './menu-button.module.css';

const SaveMenuButton = (): JSX.Element => {
  const { savePlan, savingPlan, hasUnsavedChanges } = useContext(PlannerCommandContext);
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  return (
    <MenuButton
      id='save-plan'
      className={style.saveMenuButton}
      disabled={savePlan === null || savingPlan}
      onClick={() => {
        if (savePlan) savePlan();
      }}
      tooltipKey={TooltipKey.SavePlan}
    >
      <MenuIcon icon='icon-floppy' loading={savingPlan} overlayIcon={hasUnsavedChanges || activePlan === null ? null : 'icon-ok'} />
    </MenuButton>
  );
};

export default SaveMenuButton;
