import React, { Fragment, forwardRef, useMemo } from 'react';

import Plan, { PlanUtils } from '@gi/plan';

import styles from './notes-styles.module.css';

interface iProps {
  plan: Plan;
  isNorthernHemisphere: boolean;
  notes?: string;
  className?: string;
  alwaysShow?: boolean;
}

const NotesPrintView = forwardRef<HTMLDivElement, iProps>(({ plan, isNorthernHemisphere, notes, className, alwaysShow = false }, ref): JSX.Element => {
  const printableNotes = useMemo(() => {
    return (notes ?? plan.notes).split('\n').map((lineText, key) => (
      <Fragment key={key}>
        {lineText}
        <br />
      </Fragment>
    ));
  }, [plan.notes, notes]);

  const classNames: string[] = [styles.notesPrintView];
  if (alwaysShow) {
    classNames.push(styles.show);
  }
  if (className) {
    classNames.push(className);
  }

  return (
    <div ref={ref} className={classNames.join(' ')}>
      <h2>
        {plan.name} - {PlanUtils.getYearDisplayString(plan.year, isNorthernHemisphere)}
      </h2>
      {printableNotes}
    </div>
  );
});
NotesPrintView.displayName = 'NotesPrintView';

export default NotesPrintView;
