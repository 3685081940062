import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import ReactSelect from 'react-select';

import networkConfig from '@gi/config/network-config/index.ts';
import { TextFormRow } from '@gi/form';
import { UserPlantVarietySetShape, UserPlantVarietyUtils } from '@gi/user';
import Collection from '@gi/collection';
import LengthInput from '@gi/length-input';
import { DistanceUnitsShape, metricDistanceUnits } from '@gi/units';
import { PlantingCalendarDisplay, createPlantingCalendarFromNumbers } from '@gi/planting-calendar';
import { DEFAULT_VARIETY, DeviceDisplayMode } from '@gi/constants';
import Plant from '@gi/plant';
import { AppContext } from '@gi/app-provider';
import FormField, { FORM_FIELD_PRESETS, FormSubtext, FormSection, InputContainer } from '@gi/form-responsive';

import Modal, {
  ModalPane,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalPaneSection,
  ModalPaneSectionContent,
  ModalHeaderContent,
  ModalHeaderTitle,
} from '@gi/modal';

import './import-variety-settings-modal.scss';

const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '34px',
    minWidth: 'unset',
    maxWidth: '100%',
    width: '100%',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px',
  }),
  input: (provided) => ({
    ...provided,
    paddingBottom: '0',
    paddingTop: '0',
    margin: '0',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  singleValue: (provided) => ({
    ...provided,
    width: '100%',
    paddingRight: '8px',
  }),
};

const SPACING_FIELD_PRESET = {
  desktop: {
    labelSize: 160,
  },
};

const createUserPlantVarietyOption = (userPlantVariety, plant) => {
  return {
    plant,
    userPlantVariety,
    varietyName: userPlantVariety.name,
    plantName: plant.name,
    value: { varietyName: userPlantVariety.name, code: plant.code },
  };
};

const sortPlantVarietyOptionList = (a, b) => {
  if (a.plant.code === b.plant.code) {
    return a.userPlantVariety.name.localeCompare(b.userPlantVariety.name);
  }

  return a.plant.name.localeCompare(b.plant.name);
};

const sortPlantOptionList = (a, b) => {
  if (a.value === null) {
    return -1;
  }

  if (b.value === null) {
    return 1;
  }

  return a.label.localeCompare(b.label);
};

const FormatVarietyOptionLabel = ({ varietyName, plantName, isCurrentPlant }) => {
  let variety = varietyName;

  if (varietyName === DEFAULT_VARIETY) {
    variety = (
      <>
        - <span className='default-variety-selector'>(Default)</span>
      </>
    );
  }

  return (
    <div className={`import-variety-select-option ${isCurrentPlant ? 'current-select-option' : ''}`}>
      <div className='variety-select-variety'>{variety}</div>
      <div className='variety-select-plant'>{plantName}</div>
    </div>
  );
};

FormatVarietyOptionLabel.propTypes = {
  varietyName: PropTypes.string.isRequired,
  plantName: PropTypes.string.isRequired,
  isCurrentPlant: PropTypes.bool.isRequired,
};

const FormatPlantOptionLabel = ({ value, label, isCurrentPlant }) => {
  return <div className={`import-plant-select-option ${value === null || isCurrentPlant ? 'all-plants' : ''}`}>{label}</div>;
};

FormatPlantOptionLabel.propTypes = {
  value: PropTypes.oneOf([PropTypes.string, null]).isRequired,
  label: PropTypes.string.isRequired,
  isCurrentPlant: PropTypes.bool.isRequired,
};

class ImportVarietySettingsModal extends PureComponent {
  constructor(props) {
    super(props);

    const plantOptions = this.getUserPlantVarietyPlantOptions(props.userPlantVarieties, props.plants, props.currentVarietyName, props.plant);
    const plantIndex = this.getUserPlantVarietiesOptionIndex(plantOptions, props.plant);
    const options = this.getUserPlantVarietiesOptions(props.userPlantVarieties, props.plants, props.currentVarietyName, props.plant, plantOptions[plantIndex]);

    const variety = options.length > 0 ? options[0] : null;

    const copyPlantingCalendar = variety === null ? false : variety.userPlantVariety.customCalendar;
    const copySpacing = variety === null ? false : variety.userPlantVariety.customSpacings;

    this.state = {
      variety,
      copyPlantingCalendar,
      copySpacing,
      plantFilter: plantOptions[plantIndex],
    };
  }

  onCopySpacingChange = (e) => {
    this.setState({
      copySpacing: e.target.checked,
    });
  };

  onCopyPlantingCalendarChange = (e) => {
    this.setState({
      copyPlantingCalendar: e.target.checked,
    });
  };

  onVarietyChange = (obj) => {
    this.setState({
      variety: obj,
      copyPlantingCalendar: obj.userPlantVariety.customCalendar,
      copySpacing: obj.userPlantVariety.customSpacings,
    });
  };

  onPlantChange = (obj) => {
    const options = this.getUserPlantVarietiesOptions(this.props.userPlantVarieties, this.props.plants, this.props.currentVarietyName, this.props.plant, obj);

    const variety = options.length > 0 ? options[0] : null;
    const copyPlantingCalendar = variety === null ? false : variety.userPlantVariety.customCalendar;
    const copySpacing = variety === null ? false : variety.userPlantVariety.customSpacings;

    this.setState({
      plantFilter: obj,
      variety,
      copyPlantingCalendar,
      copySpacing,
    });
  };

  onImport = () => {
    this.props.onImport({
      sourcePlant: this.props.plants.get(this.state.variety.userPlantVariety.plantCode),
      variety: this.state.variety.userPlantVariety,
      copyPlantingCalendar: this.state.copyPlantingCalendar,
      copySpacing: this.state.copySpacing,
    });
  };

  /**
   * Returns a list of options for a variety selection list
   *
   * @param {UserPlantVarietySet} userPlantVarieties
   * @param {Collection} plants
   * @param {String} currentVarietyName the current user plant variety being edited
   * @param {Object} plantFilter the currently selected plant filter object, it's value property will
   * either by a plant code or 'null' to signify all plants
   * @memberof ImportVarietySettingsModal
   */
  getUserPlantVarietiesOptions = memoizeOne((userPlantVarieties, plants, currentVarietyName, plant, plantFilter) => {
    const varietiesList = userPlantVarieties.userPlantVarieties;

    const optionList = [];

    for (let i = 0; i < varietiesList.length; i++) {
      const userPlantVariety = varietiesList[i];

      // Make sure that the current variety is not added to the list, and varieties with both
      // custom spacing and custom planting times don't show
      const isCurrentVariety = currentVarietyName === userPlantVariety.name && plant.code === userPlantVariety.plantCode;
      if (!isCurrentVariety && !UserPlantVarietyUtils.isDisabled(userPlantVariety)) {
        // If only the calendar is available and not empty to be want to use it
        if (!(!userPlantVariety.customSpacings && userPlantVariety.customCalendar && UserPlantVarietyUtils.hasEmptyCalendar(userPlantVariety))) {
          // Filter out varieties from plants not in the filter (null is all plants)
          if (plantFilter.value === null || plantFilter.value === userPlantVariety.plantCode) {
            // We only want to show things which actually have properties to copy
            if (plants.has(userPlantVariety.plantCode)) {
              const varietyPlant = plants.get(userPlantVariety.plantCode);
              optionList.push(createUserPlantVarietyOption(userPlantVariety, varietyPlant));
            } else {
              console.warn('Custom plant variety not available', userPlantVariety.plantCode);
            }
          }
        }
      }
    }

    return optionList.sort(sortPlantVarietyOptionList);
  });

  /**
   * Returns a list of plant options for the ReactSelect component for filtering the varieties by plant
   *
   * @param {UserPlantVarietySet} userPlantVarieties
   * @param {Collection} plants
   * @param {UserPlantVariety|null} currentVariety the current user plant variety being edited
   * @memberof ImportVarietySettingsModal
   */
  getUserPlantVarietyPlantOptions = memoizeOne((userPlantVarieties, plants, currentVarietyName, plant) => {
    const varietiesList = userPlantVarieties.userPlantVarieties;

    const plantList = [];

    for (let i = 0; i < varietiesList.length; i++) {
      const userPlantVariety = varietiesList[i];

      const isCurrentVariety = currentVarietyName === userPlantVariety.name && plant.code === userPlantVariety.plantCode;
      if (!isCurrentVariety && !UserPlantVarietyUtils.isDisabled(userPlantVariety)) {
        // We only want to show things which actually have properties to copy
        if (!(!userPlantVariety.customSpacings && userPlantVariety.customCalendar && UserPlantVarietyUtils.hasEmptyCalendar(userPlantVariety))) {
          if (plants.has(userPlantVariety.plantCode)) {
            const varietyPlant = plants.get(userPlantVariety.plantCode);
            plantList.push(varietyPlant);
          } else {
            console.warn('Custom plant variety not available', userPlantVariety.plantCode);
          }
        }
      }
    }

    const uniquePlantSet = new Set(plantList);
    const uniquePlantList = [...uniquePlantSet];

    const options = [
      {
        label: 'All plants',
        value: null,
        isCurrentPlant: false,
      },
    ];

    for (let i = 0; i < uniquePlantList.length; i++) {
      const varietyPlant = uniquePlantList[i];
      options.push({
        label: varietyPlant.name,
        value: varietyPlant.code,
        isCurrentPlant: varietyPlant === plant,
      });
    }

    return options.sort(sortPlantOptionList);
  });

  /**
   * Returns the index for which the plant selection list should show by default
   * (the currently selected plant)
   *
   * @param {Object[]} options list of options to be provided to ReactSelect
   * @param {Plant} plant plant being used by the modal
   * @memberof ImportVarietySettingsModal
   */
  getUserPlantVarietiesOptionIndex = memoizeOne((options, plant) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === plant.code) {
        return i;
      }
    }

    return 0;
  });

  renderEmptyContent() {
    return (
      <ModalPane emphasised>
        <ModalPaneSection>
          <ModalPaneSectionContent>
            <TextFormRow>You have no available custom plant varieties to import from!</TextFormRow>
          </ModalPaneSectionContent>
        </ModalPaneSection>
      </ModalPane>
    );
  }

  renderCopySquareFootPlantCount(currentUserPlantVariety, customSpacings) {
    if (!this.props.plant.canBeSquareFootPlant) {
      return null;
    }

    const plantToCopyFrom = this.props.plants.get(currentUserPlantVariety.plantCode);

    let sfgWarning;
    let sfgCount = currentUserPlantVariety.squareFootPlantCount;

    if (!customSpacings) {
      sfgCount = '';
    } else if (!plantToCopyFrom.canBeSquareFootPlant) {
      sfgWarning = <span className='form-row-item invalid-text-inline'>{plantToCopyFrom.name} doesn&apos;t support SFG so count will not be imported</span>;
      sfgCount = '';
    }

    return (
      <FormField
        label='Square Foot Plant Count'
        htmlFor='sfg-plant-count'
        layoutPreset={SPACING_FIELD_PRESET}
        desktopLayout={{ inputSize: 64 }}
        mobileLayout={{ inputSize: 64 }}
        disabled={!customSpacings}
        errorText={sfgWarning}
      >
        <InputContainer>
          <input type='number' className='input-small inline' disabled={true} value={sfgCount} onChange={() => {}} />
        </InputContainer>
      </FormField>
    );
  }

  renderContent() {
    const availableVarieties = this.getUserPlantVarietiesOptions(
      this.props.userPlantVarieties,
      this.props.plants,
      this.props.currentVarietyName,
      this.props.plant,
      this.state.plantFilter
    );

    if (availableVarieties.length === 0) {
      return this.renderEmptyContent();
    }

    const availablePlants = this.getUserPlantVarietyPlantOptions(
      this.props.userPlantVarieties,
      this.props.plants,
      this.props.currentVarietyName,
      this.props.plant
    );

    const currentUserPlantVariety = this.state.variety !== null ? this.state.variety.userPlantVariety : null;

    const plantingCalendarNumbers =
      currentUserPlantVariety && currentUserPlantVariety.customCalendar ? currentUserPlantVariety.plantingCalendar : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const plantingCalendar = createPlantingCalendarFromNumbers(plantingCalendarNumbers);

    const customCalendar = currentUserPlantVariety !== null ? currentUserPlantVariety.customCalendar : false;
    const customSpacings = currentUserPlantVariety !== null ? currentUserPlantVariety.customSpacings : false;

    return (
      <>
        <ModalPane emphasised>
          <ModalPaneSection>
            <ModalPaneSectionContent>
              <TextFormRow>
                Copy custom spacings and/or planting calendars from another user-defined variety into <strong>{this.props.currentVarietyName}</strong>. You can
                only import from a variety that you have already set these values for.
              </TextFormRow>
              <FormSection padding={0} margin={0}>
                <FormField label='Filter By Plant' htmlFor='import-variety:filter-by-plant-dropdown' desktopLayout={{ inputSize: 220, labelSize: 160 }}>
                  <InputContainer>
                    <ReactSelect
                      styles={SELECT_STYLES}
                      options={availablePlants}
                      value={this.state.plantFilter}
                      onChange={this.onPlantChange}
                      menuPortalTarget={document.body}
                      formatOptionLabel={FormatPlantOptionLabel}
                      isSearchable={false}
                      inputId='import-variety:filter-by-plant-dropdown'
                    />
                  </InputContainer>
                </FormField>
                <FormField label='Variety to Copy From' htmlFor='import-variety:variety-dropdown' desktopLayout={{ inputSize: 220, labelSize: 160 }}>
                  <InputContainer>
                    <ReactSelect
                      styles={SELECT_STYLES}
                      options={availableVarieties}
                      value={this.state.variety}
                      onChange={this.onVarietyChange}
                      menuPortalTarget={document.body}
                      formatOptionLabel={FormatVarietyOptionLabel}
                      isSearchable={false}
                      inputId='import-variety:variety-dropdown'
                    />
                  </InputContainer>
                </FormField>
              </FormSection>
            </ModalPaneSectionContent>
          </ModalPaneSection>
        </ModalPane>
        <ModalPane>
          <ModalPaneSection>
            <ModalPaneSectionContent>
              <FormSection padding={0} margin={0} gap={9}>
                <FormField
                  label={<span className={`${customSpacings ? '' : 'unavailable-label'}`}>Copy Spacing</span>}
                  htmlFor='import-variety:copy-spacing'
                  layoutPreset={FORM_FIELD_PRESETS.CheckboxLong}
                >
                  <input
                    disabled={!customSpacings}
                    type='checkbox'
                    onChange={this.onCopySpacingChange}
                    checked={this.state.copySpacing}
                    id='import-variety:copy-spacing'
                  />
                </FormField>
                {!customSpacings ? <FormSubtext>No custom spacings on this variety</FormSubtext> : null}
                <FormField
                  label='Between plants'
                  htmlFor='import-variety:spacing-between-plants'
                  layoutPreset={SPACING_FIELD_PRESET}
                  disabled={!customSpacings}
                >
                  <LengthInput
                    inline
                    disabled
                    value={!customSpacings ? '' : currentUserPlantVariety.spacing}
                    onChange={() => {}}
                    distanceUnits={this.props.distanceUnits}
                    showMinor
                    id='import-variety:spacing-between-plants'
                  />
                </FormField>
                <FormField
                  label='Between plants in rows'
                  htmlFor='import-variety:spacing-between-plants-in-row'
                  layoutPreset={SPACING_FIELD_PRESET}
                  disabled={!customSpacings}
                >
                  <LengthInput
                    inline
                    disabled
                    value={!customSpacings ? '' : currentUserPlantVariety.inRowSpacing}
                    onChange={() => {}}
                    distanceUnits={this.props.distanceUnits}
                    showMinor
                    id='import-variety:spacing-between-plants-in-row'
                  />
                </FormField>
                <FormField label='Between rows' htmlFor='import-variety:spacing-between-rows' layoutPreset={SPACING_FIELD_PRESET} disabled={!customSpacings}>
                  <LengthInput
                    inline
                    disabled
                    value={!customSpacings ? '' : currentUserPlantVariety.rowSpacing}
                    onChange={() => {}}
                    distanceUnits={this.props.distanceUnits}
                    showMinor
                    id='import-variety:spacing-between-rows'
                  />
                </FormField>
                {this.renderCopySquareFootPlantCount(currentUserPlantVariety, customSpacings)}
              </FormSection>
            </ModalPaneSectionContent>
          </ModalPaneSection>
          <ModalPaneSection>
            <ModalPaneSectionContent>
              <FormSection padding={0} margin={0} gap={9}>
                <FormField
                  label={<span className={`${customCalendar ? '' : 'unavailable-label'}`}>Copy Sow, Plant and Harvest Dates</span>}
                  htmlFor='import-variety:copy-calendar'
                  layoutPreset={FORM_FIELD_PRESETS.CheckboxLong}
                >
                  <input
                    disabled={!customCalendar}
                    type='checkbox'
                    onChange={this.onCopyPlantingCalendarChange}
                    checked={this.state.copyPlantingCalendar}
                    id='import-variety:copy-calendar'
                  />
                </FormField>
                {!customCalendar ? <FormSubtext>No Custom Dates on this variety</FormSubtext> : null}
                <div className='scroll-x'>
                  <PlantingCalendarDisplay
                    plantingCalendar={plantingCalendar}
                    calendarBarURL={networkConfig.plantingCalendarImageURL}
                    startMonth={this.props.calendarStartMonth}
                    compact={this.context.deviceDisplayMode === DeviceDisplayMode.MOBILE}
                  />
                </div>
              </FormSection>
            </ModalPaneSectionContent>
          </ModalPaneSection>
        </ModalPane>
      </>
    );
  }

  render() {
    return (
      <Modal className='import-variety-settings-modal' closeRequest={this.props.closeModal}>
        <ModalContent>
          <ModalCloseButton onClick={this.props.closeModal} />
          <ModalHeader>
            <ModalHeaderContent>
              <ModalHeaderTitle>Copy Spacing/Planting Calendar into {this.props.currentVarietyName}</ModalHeaderTitle>
            </ModalHeaderContent>
          </ModalHeader>
          <ModalPaneContainer>{this.renderContent()}</ModalPaneContainer>
          <ModalFooter>
            <ModalFooterButtons>
              <ModalFooterButtonsSection>
                <button type='button' className='button button-secondary' onClick={this.props.closeModal}>
                  Cancel
                </button>
              </ModalFooterButtonsSection>
              <ModalFooterButtonsSection>
                <button
                  type='button'
                  className='button button-primary'
                  disabled={!this.state.copySpacing && !this.state.copyPlantingCalendar}
                  onClick={this.onImport}
                >
                  Import Properties
                </button>
              </ModalFooterButtonsSection>
            </ModalFooterButtons>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

ImportVarietySettingsModal.propTypes = {
  plant: PropTypes.instanceOf(Plant).isRequired,
  plants: PropTypes.instanceOf(Collection).isRequired,
  userPlantVarieties: UserPlantVarietySetShape.isRequired,
  closeModal: PropTypes.func.isRequired,
  distanceUnits: DistanceUnitsShape,
  currentVarietyName: PropTypes.string.isRequired,
  onImport: PropTypes.func.isRequired,
  calendarStartMonth: PropTypes.number,
};

ImportVarietySettingsModal.defaultProps = {
  distanceUnits: metricDistanceUnits,
  calendarStartMonth: 1,
};

ImportVarietySettingsModal.contextType = AppContext;

export default ImportVarietySettingsModal;
