import React, { ReactNode } from 'react';

import './plant-list-card.scss';

interface iProps {
  children?: ReactNode;
}

const PlantListCard = ({ children }: iProps): JSX.Element => {
  return <div className='plant-list-card'>{children}</div>;
};

export default PlantListCard;
