import React, { useContext } from 'react';
import { FormLayout, FormSection } from '@gi/form-responsive';
import { EditPlantContext } from '../context/edit-plant-context';

import './plan-plant-notes.scss';

const PlanPlantNotes = (): JSX.Element => {
  const { plantNotesText, setPlantNotesText } = useContext(EditPlantContext);

  return (
    <FormSection padding={12} gap={6} className='form-section-background plan-plant-notes' heading='Plant Notes'>
      <FormLayout>
        <textarea
          rows={6}
          className='plan-plant-notes-textarea'
          value={plantNotesText}
          onChange={(e) => {
            setPlantNotesText(e.target.value);
          }}
        />
      </FormLayout>
      <FormLayout>
        <p className='plan-plant-notes-text'>
          Plant notes are shared by all plants of this type and variety on this plan. They can also be viewed and printed in the Plant List.
        </p>
      </FormLayout>
    </FormSection>
  );
};

export default PlanPlantNotes;
