import React from 'react';
import PropTypes from 'prop-types';

import TransferPlanModal from './transfer-plan-modal';

/**
 * @param {{
 *  open: boolean,
 *  closeModal: () => void,
 *  transferPlan: () => void,
 *  errorMessage?: string | null,
 *  inProgress?: boolean,
 *  planID?: number | null,
 * }}
 */
const TransferPlanModalRenderer = ({ open, planID = null, closeModal, transferPlan, inProgress = false, errorMessage = null }) => {
  if (!open) {
    return null;
  }

  if (planID === null) {
    // No active plan to transfer
    return null;
  }

  return <TransferPlanModal planID={planID} closeModal={closeModal} transferPlan={transferPlan} inProgress={inProgress} errorMessage={errorMessage} />;
};

TransferPlanModalRenderer.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  transferPlan: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  inProgress: PropTypes.bool,
  planID: PropTypes.number,
};

export default TransferPlanModalRenderer;
