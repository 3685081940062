import React, { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';

import { User, UserSeasonExtenderSettings } from '@gi/user';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { RequestSelectors } from '@gi/react-requests';
import { SessionSelectors } from '@gi/react-session';
import { ResourceContext } from '@gi/resource-provider';

import {
  ModalPaneContainer,
  ModalPane,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalPaneSectionHeader,
  ModalPaneSectionContent,
  ModalPaneSection,
  ModalContext,
  ConfirmLeaveContextResult,
  ModalTabContext,
  AttemptToLeaveCallback,
} from '@gi/modal';
import { FormSection, FormField, FormLayout, InputContainer, FORM_FIELD_PRESETS, FORM_LAYOUT_PRESETS, FormValues, createFormValues } from '@gi/form-responsive';
import LoadingButton from '@gi/loading-button';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import SeasonExtenderSettings from './season-extender-settings';
import { saveProfileSettings } from '../planner-settings-action-creators';
import ResourceLanguageSettings from './resource-language-settings';

const SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    minWidth: '100px',
  }),
};

const labelFontSizes = [
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '18', value: 18 },
  { label: '20', value: 20 },
  { label: '24', value: 24 },
  { label: '28', value: 28 },
  { label: '36', value: 36 },
  { label: '48', value: 48 },
];

const getLabelFontSizeOptionValue = (value: number) => {
  for (let i = 0; i < labelFontSizes.length; i++) {
    if (labelFontSizes[i].value === value) {
      return labelFontSizes[i];
    }
  }

  return { value, label: value.toString() };
};

export type ProfileSettings = {
  labelTextSize: number;
  showLabelOnNewPlants: boolean;
  showLabelOnNewSFGPlants: boolean;
  varietyForDefaultLabel: boolean;
  showPlantRoots: boolean;
  autosave: boolean;
  seasonExtenderSettings: UserSeasonExtenderSettings;
  userArtifactCode: string;
};

interface iProps {
  user: User;
}

const ProfileSettingsTab = ({ user }: iProps): JSX.Element => {
  const requests = useSelector(RequestSelectors.getRequests);
  const dispatch = useDispatch();

  const { setCloseContextParams, attemptClose } = useContext(ModalContext);
  const { setLeaveContextParams } = useContext(ModalTabContext);
  const { userArtifactCode } = useContext(ResourceContext);

  const userSaving = useMemo(() => {
    return RequestsUtils.getStatus(requests, `SAVE_USER_${user.ID}`) === RequestStatus.IN_PROGRESS;
  }, [user.ID, requests]);

  const [profileSettings, setProfileSettings] = useState<FormValues<ProfileSettings>>(
    createFormValues({
      labelTextSize: { value: user.planSettings.labelTextSize },
      showLabelOnNewPlants: { value: user.planSettings.showLabelOnNewPlants },
      showLabelOnNewSFGPlants: { value: user.planSettings.showLabelOnNewSFGPlants },
      varietyForDefaultLabel: { value: user.planSettings.varietyForDefaultLabel },
      showPlantRoots: { value: user.planSettings.showPlantRoots },
      autosave: { value: user.planSettings.autosave },
      seasonExtenderSettings: { value: user.settings.seasonExtenders },
      userArtifactCode: { value: userArtifactCode }, // Do not use user.settings.data.userArtifactCode, might be null
    })
  );

  const setValue = <K extends keyof ProfileSettings>(key: K, value: ProfileSettings[K]) => {
    setProfileSettings((currentFormValues) => currentFormValues.setValue(key, { value }));
  };

  const textSizeValue = useMemo(() => {
    return getLabelFontSizeOptionValue(profileSettings.values.labelTextSize);
  }, [profileSettings.values.labelTextSize]);

  const onSaveProfileSettings = useCallback(
    (closeOnSave: boolean) => {
      dispatch(
        saveProfileSettings(
          {
            user,
            showLabelOnNewPlants: profileSettings.values.showLabelOnNewPlants,
            showLabelOnNewSFGPlants: profileSettings.values.showLabelOnNewSFGPlants,
            showPlantRoots: profileSettings.values.showPlantRoots,
            varietyForDefaultLabel: profileSettings.values.varietyForDefaultLabel,
            labelTextSize: profileSettings.values.labelTextSize,
            autosave: profileSettings.values.autosave,
            seasonExtenderSettings: profileSettings.values.seasonExtenderSettings,
            userArtifactCode: profileSettings.values.userArtifactCode,
          },
          closeOnSave
        )
      );
    },
    [profileSettings, saveProfileSettings]
  );

  useEffect(() => {
    const createLeaveCallback = (closeOnSave: boolean): AttemptToLeaveCallback => {
      return (cb) => {
        return cb(profileSettings.hasBeenEdited).then((result) => {
          if (result === ConfirmLeaveContextResult.SaveAndClose) {
            onSaveProfileSettings(closeOnSave);
          }

          return result !== ConfirmLeaveContextResult.Cancel;
        });
      };
    };

    const invalidMessage = profileSettings.isValid ? undefined : 'Some inputs have invalid values, please correct them before saving';

    setCloseContextParams({
      title: 'Close Without Saving',
      text: 'Closing without saving profile settings will lose any changes you have made',
      callback: createLeaveCallback(true),
      invalidMessage,
    });

    setLeaveContextParams({
      title: 'Leave Without Saving',
      text: 'Leaving without saving profile settings will lose any changes you have made',
      callback: createLeaveCallback(false),
      withoutSavingButtonText: 'Leave Without Saving',
      withSavingButtonText: 'Save and Leave',
      invalidMessage,
    });

    return () => {
      setCloseContextParams(null);
      setLeaveContextParams(null);
    };
  }, [profileSettings.hasBeenEdited, onSaveProfileSettings]);

  return (
    <>
      <ModalPaneContainer>
        <ModalPane>
          <ModalPaneSection>
            <ModalPaneSectionHeader>Profile Settings</ModalPaneSectionHeader>
            <ModalPaneSectionContent>
              <p className='settings-introduction'>These settings apply to all plans on your account.</p>
            </ModalPaneSectionContent>
          </ModalPaneSection>
          <FormSection heading='Plants' padding={12} gap={6} className='form-section-background'>
            <FormField label='Label Font Size' htmlFor='profile-settings:label-font-size' desktopLayout={{ labelSize: 120, inputSize: 100 }}>
              <InputContainer size='full'>
                <ReactSelect
                  styles={SELECT_STYLES}
                  options={labelFontSizes}
                  value={textSizeValue}
                  onChange={(opt) => {
                    if (opt !== null) {
                      setValue('labelTextSize', opt.value);
                    }
                  }}
                  isSearchable={false}
                  inputId='profile-settings:label-font-size'
                />
              </InputContainer>
            </FormField>
            <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.CheckboxStack}>
              <FormField label='Add Labels to New Plants' htmlFor='profile-settings:label-new-plants' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
                <input
                  type='checkbox'
                  checked={profileSettings.values.showLabelOnNewPlants}
                  onChange={(e) => setValue('showLabelOnNewPlants', e.target.checked)}
                  id='profile-settings:label-new-plants'
                />
              </FormField>
              <FormField label='Add Labels to New SFG Plants' htmlFor='profile-settings:label-new-sfg-plants' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
                <input
                  type='checkbox'
                  checked={profileSettings.values.showLabelOnNewSFGPlants}
                  onChange={(e) => setValue('showLabelOnNewSFGPlants', e.target.checked)}
                  id='profile-settings:label-new-sfg-plants'
                />
              </FormField>
              <FormField label='Use Plant Variety as Default Label' htmlFor='profile-settings:label-use-variety' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
                <input
                  type='checkbox'
                  checked={profileSettings.values.varietyForDefaultLabel}
                  onChange={(e) => setValue('varietyForDefaultLabel', e.target.checked)}
                  id='profile-settings:label-use-variety'
                />
              </FormField>
              <FormField
                label='Show Colored Plant Backgrounds'
                htmlFor='profile-settings:coloured-plant-backgrounds'
                layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
              >
                <input
                  type='checkbox'
                  checked={profileSettings.values.showPlantRoots}
                  onChange={(e) => setValue('showPlantRoots', e.target.checked)}
                  id='profile-settings:coloured-plant-backgrounds'
                />
              </FormField>
            </FormLayout>
          </FormSection>
          <ResourceLanguageSettings profileSettings={profileSettings} setProfileSettings={setProfileSettings} />
          <FormSection heading='Saving' padding={12} gap={6} className='form-section-background'>
            <FormField label='Autosave (every 10 minutes)' htmlFor='profile-settings:autosave' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
              <input
                type='checkbox'
                checked={profileSettings.values.autosave}
                onChange={(e) => setValue('autosave', e.target.checked)}
                id='profile-settings:autosave'
              />
            </FormField>
          </FormSection>
          <SeasonExtenderSettings
            seasonExtenders={profileSettings.values.seasonExtenderSettings}
            setSeasonExtenders={(seasonExtenderSettings) => setValue('seasonExtenderSettings', seasonExtenderSettings)}
          />
        </ModalPane>
      </ModalPaneContainer>
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-secondary' onClick={attemptClose}>
              Cancel
            </button>
          </ModalFooterButtonsSection>
          <ModalFooterButtonsSection>
            <LoadingButton loading={userSaving} disabled={userSaving} className='button-primary' onClick={() => onSaveProfileSettings(true)}>
              Save Profile Settings
            </LoadingButton>
          </ModalFooterButtonsSection>
        </ModalFooterButtons>
      </ModalFooter>
    </>
  );
};

const ProfileSettingsTabRenderer = (props: Omit<iProps, 'user'>): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);

  if (!user) {
    return null;
  }

  return <ProfileSettingsTab user={user} {...props} />;
};

export default ProfileSettingsTabRenderer;
