import { Filter } from '@gi/filters';
import { SEASON_EXTENDERS } from '@gi/constants';
import GardenObject from '@gi/garden-object';

type GardenObjectTypeFilterState = {
  type: string | null;
};

const gardenObjectTypeFilter: Filter<GardenObject, GardenObjectTypeFilterState> = {
  name: 'Object Type',
  inputs: { type: null },
  createListFilter: ({ type }) => {
    return (objectList) => {
      if (type === null) {
        return objectList;
      }

      if (type === SEASON_EXTENDERS) {
        return objectList.filter((obj) => obj.plantModifier !== null);
      }

      return objectList.filter((obj) => obj.layerType === type);
    };
  },
};

export default gardenObjectTypeFilter;
