import { DEFAULT_VARIETY } from '@gi/constants';
import { UserPlantVariety, DEFAULT_USER_PLANT_VARIETY } from '../user-plant-variety';
import { UserPlantVarietySet, UserPlantVarietySetUtils } from '../user-plant-variety-set';
import { UserVarietyFromAPI } from './user-api-types';

/**
 * Returns an immutable UserPlantVariety created from the passed object from a user object returned from the API's 'userVarieties' property
 * @param userPlantVarietyObject
 */
export function userPlantVarietyFromAPI(userPlantVarietyObject: any): UserPlantVariety {
  let name = DEFAULT_VARIETY;

  if (userPlantVarietyObject.varietyName !== '-') {
    name = userPlantVarietyObject.varietyName;
  }

  return {
    ...DEFAULT_USER_PLANT_VARIETY,
    plantCode: userPlantVarietyObject.plantCode,
    name,
    customSpacings: userPlantVarietyObject.customSpacings,
    spacing: userPlantVarietyObject.spacing,
    inRowSpacing: userPlantVarietyObject.inRowSpacing,
    rowSpacing: userPlantVarietyObject.rowSpacing,
    squareFootPlantCount: userPlantVarietyObject.noOfSquareFootPlants,
    customCalendar: userPlantVarietyObject.customCalendar,
    recordID: userPlantVarietyObject.recordID,
    catalogueItems: userPlantVarietyObject.catalogueItems,
    plantingCalendar: [
      userPlantVarietyObject.month1,
      userPlantVarietyObject.month2,
      userPlantVarietyObject.month3,
      userPlantVarietyObject.month4,
      userPlantVarietyObject.month5,
      userPlantVarietyObject.month6,
      userPlantVarietyObject.month7,
      userPlantVarietyObject.month8,
      userPlantVarietyObject.month9,
      userPlantVarietyObject.month10,
      userPlantVarietyObject.month11,
      userPlantVarietyObject.month12,
    ],
  };
}

/**
 * Converts a UserPlantVariety to an object with properties matching the GrowVeg API so the userVariety can be saved
 * @param userPlantVariety
 */
export function userPlantVarietyToAPI(userPlantVariety: UserPlantVariety) {
  let varietyName = '-';

  if (userPlantVariety.name !== DEFAULT_VARIETY) {
    varietyName = userPlantVariety.name;
  }

  return {
    catalogueItems: userPlantVariety.catalogueItems,
    customCalendar: userPlantVariety.customCalendar,
    customSpacings: userPlantVariety.customSpacings,
    inRowSpacing: userPlantVariety.inRowSpacing,
    month1: userPlantVariety.plantingCalendar[0],
    month2: userPlantVariety.plantingCalendar[1],
    month3: userPlantVariety.plantingCalendar[2],
    month4: userPlantVariety.plantingCalendar[3],
    month5: userPlantVariety.plantingCalendar[4],
    month6: userPlantVariety.plantingCalendar[5],
    month7: userPlantVariety.plantingCalendar[6],
    month8: userPlantVariety.plantingCalendar[7],
    month9: userPlantVariety.plantingCalendar[8],
    month10: userPlantVariety.plantingCalendar[9],
    month11: userPlantVariety.plantingCalendar[10],
    month12: userPlantVariety.plantingCalendar[11],
    noOfSquareFootPlants: userPlantVariety.squareFootPlantCount,
    plantCode: userPlantVariety.plantCode,
    recordID: userPlantVariety.recordID,
    rowSpacing: userPlantVariety.rowSpacing,
    spacing: userPlantVariety.spacing,
    varietyName,
  };
}

export function userPlantVarietySetFromAPI(apiUserVarieties: UserVarietyFromAPI[] | null): UserPlantVarietySet {
  if (apiUserVarieties === null) {
    return UserPlantVarietySetUtils.create([]);
  }

  return UserPlantVarietySetUtils.create(apiUserVarieties.map(userPlantVarietyFromAPI));
}
