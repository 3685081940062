import { createContext } from 'react';
import { matchPath } from 'react-router-dom';

export enum Application {
  Overview = 'Overview',
  GardenPlanner = 'GardenPlanner',
  Journal = 'Journal',
  Guru = 'Guru',
  Help = 'Help',
  Account = 'Account',
}

export const ApplicationNames = {
  [Application.Overview]: 'Overview',
  [Application.GardenPlanner]: 'Garden Planner',
  [Application.Journal]: 'Garden Journal',
  [Application.Guru]: 'Garden Guru',
  [Application.Help]: 'Help & Support',
  [Application.Account]: 'Account',
};

export const Applications = [Application.Overview, Application.GardenPlanner, Application.Journal, Application.Guru, Application.Help, Application.Account];

export const ApplicationLinks = {
  [Application.Overview]: 'overview',
  [Application.GardenPlanner]: 'garden-planner',
  [Application.Journal]: 'journal',
  [Application.Guru]: 'garden-guru',
  [Application.Help]: 'help',
  [Application.Account]: 'account',
};

export function getRelativeApplicationLink(application: Application) {
  return ApplicationLinks[application];
}

export function getAbsoluteApplicationLink(application: Application) {
  return `/${ApplicationLinks[application]}`;
}

export function getActiveApplicationLink(pathname: string) {
  for (let i = 0; i < Applications.length; i++) {
    const application = Applications[i];

    if (matchPath(`${getAbsoluteApplicationLink(application)}/*`, pathname)) {
      return application;
    }
  }

  return null;
}

export type NavigationContextType = {
  activeApplication: Application | null;
  openApplication: (application: Application) => void;
};

export const NavigationContext = createContext<NavigationContextType>({} as NavigationContextType); // Defaults will be set by the provider
