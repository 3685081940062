import React from 'react';

import Plan, { PlanUtils } from '@gi/plan';
import { UserUtils, User } from '@gi/user';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalContent,
  ModalPaneContainer,
  ModalPane,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalType,
  ModalHeaderContent,
  ModalHeaderTitle,
} from '@gi/modal';
import LoadingButton from '@gi/loading-button';

import './delete-plan-modal.scss';

interface iProps {
  plan: Plan;
  user: User;
  onDeletePlan: (plan: Plan) => void;
  deleting?: boolean;
  closeModal: () => void;
}

const DeletePlanModal = ({ plan, user, onDeletePlan, deleting, closeModal }: iProps): JSX.Element => {
  return (
    <Modal className='delete-plan-modal' type={ModalType.Warning} closeRequest={closeModal}>
      <ModalContent>
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Delete Plan</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            <p>
              You are about to permanently delete plan <strong>{plan.name}</strong> -{' '}
              {PlanUtils.getYearDisplayString(plan.year, UserUtils.isNorthernHemisphere(user))}
            </p>
            <p>Warning: this cannot be undone!</p>
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={closeModal}>
                Cancel
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <LoadingButton
                buttonIcon='icon-trash'
                className='button-warning'
                onClick={() => {
                  onDeletePlan(plan);
                }}
                loading={deleting}
                disabled={deleting}
              >
                Confirm Deletion
              </LoadingButton>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeletePlanModal;
