import React from 'react';

interface iProps {
  content: string;
}

const HTMLContent = ({ content }: iProps): JSX.Element => {
  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default HTMLContent;
