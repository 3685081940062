/**
 * Validation function for session data.
 *
 * returns false if any properties are missing, null or the incorrect type; else returns true.
 *
 * @param {object} sessionData
 * @returns {boolean} validity
 */
const validSessionData = (sessionData) => {
  if (sessionData === null) {
    return true;
  }

  if (!sessionData) {
    return false;
  }

  if (sessionData.userID === null || sessionData.userID === undefined || typeof sessionData.userID !== 'number') {
    return false;
  }

  if (sessionData.email === null || sessionData.email === undefined || typeof sessionData.email !== 'string') {
    return false;
  }

  if (sessionData.postAuthTicket === null || sessionData.postAuthTicket === undefined || typeof sessionData.postAuthTicket !== 'string') {
    return false;
  }

  return true;
};

/**
 * SharedSession Class
 *
 * Uses local storage to store session data and pass out changes.
 *
 * Storage format is:
 * {
 *   userID: 0,
 *   email: '',
 *   postAuthTicket: '',
 * }
 *
 * Or null will be returned if a user is not logged in
 */
class LocalSession {
  constructor(sessionDataKey = 'session.data') {
    this.sessionDataKey = sessionDataKey;
    this.changeHandlers = [];
    this._storageChangeHandler = this._storageChangeHandler.bind(this);

    this.addStorageListener();
    const sessionData = this._get();

    if (!validSessionData(sessionData)) {
      console.debug('Invalid session data in local storage upon initialisation');
      this._clear(); // Delete any existing session data
    }
  }

  /**
   * Called when local storage changes from other sources (Maybe only other tabs? TODO: check what can trigger this event)
   * @param {Event} e - Session Change event, e.key is equal to the local storage key
   */
  _storageChangeHandler(e) {
    if (e.key === this.sessionDataKey) {
      console.debug('Session local storage changed');
      this._callChangeHandlers(this._get());
    }
  }

  _callChangeHandlers(...args) {
    console.log('Calling change handles', this.changeHandlers.length);
    this.lastChangeArgs = args;
    for (let i = 0; i < this.changeHandlers.length; i++) {
      this.changeHandlers[i].apply(this, args);
    }
  }

  // Internal method to set session data
  _set(sessionData) {
    if (!validSessionData(sessionData)) {
      throw new Error('Invalid session data');
    }

    if (this._compare(sessionData)) {
      // New session data matches what's already saved, don't do anything
      return;
    }

    localStorage.setItem(this.sessionDataKey, JSON.stringify(sessionData));
    this._callChangeHandlers(sessionData);
  }

  _clear() {
    localStorage.setItem(this.sessionDataKey, 'null');
    this._callChangeHandlers(null);
  }

  // Internal method to parse session data
  _get() {
    return JSON.parse(localStorage.getItem(this.sessionDataKey));
  }

  /**
   * Returns whether the session data matches what's currently in local storage
   *
   * @param {object} sessionData
   * @return {boolean} - True if the session data provided matches what's currently in local storage
   */
  _compare(sessionData) {
    const stringified = JSON.stringify(sessionData);
    return stringified === localStorage.getItem(this.sessionDataKey);
  }

  addStorageListener() {
    // Add storage listener and check for changes to our session data
    // Note: this only fires when data is changed in another tab/window
    // your application should handle it's own login/logout changes
    window.addEventListener('storage', this._storageChangeHandler, false);
    console.debug('Added local storage listener for sessions');
  }

  removeStorageListener() {
    window.removeEventListener('storage', this._storageChangeHandler);
    console.debug('Removed local storage listener for sessions');
  }

  /**
   * Adds an onChange listener
   *
   * @param {function} fn - onChange handler
   */
  onChange(fn) {
    this.changeHandlers.push(fn);
  }

  offChange(fn) {
    console.log('local session off change');
    const index = this.changeHandlers.indexOf(fn);

    if (index === -1) {
      console.warn("Attempted to remove onChange handler which wasn't added");
    } else {
      this.changeHandlers.splice(index, 1);
    }
  }

  /**
   * Call on a successful login to save the email, userID and post authentication ticket
   *
   * @param {string} email - Email address of user, only used for display and not used for logging in,
   * there is no verification on this value
   * @param {number} userID - User ID of user, used for restoring session
   * @param {string} postAuthTicket - Post authentication ticket of user, used for restoring the local session
   * @throws {Error} - If invalid session data is provided
   */
  login(email, userID, postAuthTicket) {
    console.debug('Session storage login');
    const sessionData = {
      userID,
      email,
      postAuthTicket,
    };

    this._set(sessionData);
  }

  /**
   * Removes session data from local storage and replaces it with null
   * Note: Does not remove the local storage key
   */
  logout() {
    console.debug('Session storage logout');
    this._clear();
  }

  /**
   * Fetches the current session data, may call a logout if the session data was malformed (and as such, may emit an onChange event)
   * @returns {object} - Current session data or null
   */
  get() {
    const sessionData = this._get();
    if (!validSessionData(sessionData)) {
      this.logout();
      return null;
    }
    return sessionData;
  }
}

export default LocalSession;
