import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Direction } from '@gi/constants';

const SCROLL_TOLERANCE = 5; // Maximum amount of px offset before something is considered not at the top/bottom of its scroll.

const VELOCITY_HISTORY_COUNT = 20; // Maximum amount of touch events to track
const VELOCITY_HISTORY_AGE = 200; // Maximum time to track inputs over (in ms)
const VELOCITY_MINIMUM = 4; // Minimum amount of velocity to consider a "flick"

const DISMISS_PERCENTAGE = 0.5; // Minimum percentage of the element to be dragged off-screen before being classed as dismissed.

const MINIMUM_DISTANCE = 5; // Minimum amount of movement before deciding if it's a touch-move.

enum SwipeDismissState {
  NONE,
  STARTED,
  REJECTED,
}

interface UseSwipeDismissOptions {
  onDismiss?: () => void;
  disabled?: boolean;
}

const useSwipeDismiss = <T extends HTMLElement>(direction: Direction, { onDismiss = () => {}, disabled = false }: UseSwipeDismissOptions) => {
  const [currentRef, setCurrentRef] = useState<T | null>(null);
  const currentTouchId = useRef<number | null>(null);
  const currentTouchStartPos = useRef<Vector2>({ x: 0, y: 0 });
  const currentTouchEventState = useRef<SwipeDismissState>(SwipeDismissState.NONE);
  const elemTransition = useRef<string>('');
  const prevTouchPoints = useRef<{ position: Vector2; velocity: Vector2; time: number }[]>([]);

  const ref = useCallback((element: T | null) => {
    setCurrentRef(element);
  }, []);

  /**
   * Returns true if the dismiss direction is on the x-axis.
   */
  const isHorizontal = useMemo(() => {
    return [Direction.LEFT, Direction.RIGHT].includes(direction);
  }, [direction]);

  /**
   * Returns true if the dismiss direction is either top or left.
   */
  const isMinAnchored = useMemo(() => {
    return [Direction.LEFT, Direction.UP].includes(direction);
  }, [direction]);

  /**
   * Adds a touch position to the history, to track velocity.
   */
  const addPosition = useCallback(
    (posX: number, posY: number) => {
      const velocity =
        prevTouchPoints.current.length > 0
          ? {
              x: posX - prevTouchPoints.current[0].position.x,
              y: posY - prevTouchPoints.current[0].position.y,
            }
          : { x: 0, y: 0 };

      prevTouchPoints.current = [
        {
          velocity,
          position: { x: posX, y: posY },
          time: Date.now(),
        },
        ...prevTouchPoints.current.slice(0, VELOCITY_HISTORY_COUNT - 1),
      ];
    },
    [currentRef]
  );

  /**
   * Offsets the currentRef element to where the user has dragged to.
   */
  const moveElemTo = useCallback(
    (curX: number, curY: number) => {
      if (currentRef === null) {
        return;
      }

      // Calculate how far the user has moved from the start
      const offsetX = curX - currentTouchStartPos.current.x;
      const offsetY = curY - currentTouchStartPos.current.y;

      const offset = isHorizontal ? offsetX : offsetY;

      const translate = isHorizontal ? 'translateX' : 'translateY';

      const clamp = isMinAnchored ? Math.min : Math.max;

      const transform = `${translate}(${clamp(offset, 0)}px)`;
      currentRef.style.transform = transform;
    },
    [currentRef, isHorizontal, isMinAnchored]
  );

  /**
   * Moves the currentRef to its starting point by removing the transform.
   */
  const moveElemToSart = useCallback(() => {
    if (currentRef === null) {
      return;
    }

    currentRef.style.transform = '';
  }, [currentRef]);

  /**
   * Offsets the currentRef by the maximum amount, to where it appears dismissed.
   */
  const moveElemToEnd = useCallback(() => {
    if (currentRef === null) {
      return;
    }
    switch (direction) {
      case Direction.LEFT:
        currentRef.style.transform = 'translateX(-100%)';
        return;
      case Direction.RIGHT:
        currentRef.style.transform = 'translateX(100%)';
        return;
      case Direction.UP:
        currentRef.style.transform = 'translateY(-100%)';
        return;
      case Direction.DOWN:
        currentRef.style.transform = 'translateY(100%)';
        return;
      default:
        throw new Error('Invalid direction specified');
    }
  }, [currentRef, direction]);

  /**
   * Finds a touch from a touch list based on its identifier
   */
  const findTouchByIdentifier = useCallback((touches: TouchList, id: number) => {
    return [...touches].find((touch) => touch.identifier === id);
  }, []);

  /**
   * Checks that the average velocity is mostly in the correct direction
   */
  const checkSwipeAxisCorrect = useCallback(
    (averageVelocity: Vector2) => {
      return isHorizontal ? Math.abs(averageVelocity.x) > Math.abs(averageVelocity.y) : Math.abs(averageVelocity.y) > Math.abs(averageVelocity.x);
    },
    [currentRef, isHorizontal]
  );

  /**
   * Checks that the swipe has enough velocity to not be classed as not a swipe
   */
  const checkSwipeVelocityIsEnough = useCallback(
    (averageVelocity: Vector2) => {
      return isHorizontal ? Math.abs(averageVelocity.x) > VELOCITY_MINIMUM : Math.abs(averageVelocity.y) > VELOCITY_MINIMUM;
    },
    [currentRef, isHorizontal]
  );

  /**
   * Checks the swipe is going in the direction of dismissal
   */
  const checkSwipeDirectionCorrect = useCallback(
    (averageVelocity: Vector2) => {
      switch (direction) {
        case Direction.LEFT:
          return averageVelocity.x < 0;
        case Direction.RIGHT:
          return averageVelocity.x > 0;
        case Direction.UP:
          return averageVelocity.y < 0;
        case Direction.DOWN:
          return averageVelocity.y > 0;
        default:
          throw new Error('Invalid direction specified');
      }
    },
    [currentRef, direction]
  );

  /**
   * Returns true if the element has been dragged DISMISS_PERCENTAGE% off the screen.
   */
  const checkDismissedEnough = useCallback(
    (curX: number, curY: number) => {
      if (currentRef === null) {
        return false;
      }

      // Calculate how far the user has moved from the start
      const offsetX = curX - currentTouchStartPos.current.x;
      const offsetY = curY - currentTouchStartPos.current.y;

      const offset = isHorizontal ? offsetX : offsetY;

      const negativeMod = isMinAnchored ? -1 : 1;

      const size = isHorizontal ? currentRef.clientWidth : currentRef.clientHeight;

      return offset * negativeMod > size * DISMISS_PERCENTAGE;
    },
    [currentRef, isHorizontal, isMinAnchored]
  );

  /**
   * Checks if the element has unfinished scroll in the dismiss direction.
   * e.g. Checks that, if swiping up to dismiss, all scrollbars are at the top, so the user isn't trying to scroll.
   */
  const checkForUnfinishedScroll = useCallback(
    (element: HTMLElement | null) => {
      let target: HTMLElement | null = element;

      while (target !== null && target !== document.body) {
        const scrollAmount = isHorizontal ? target.scrollLeft : target.scrollTop;

        const computedStyle = window.getComputedStyle(target);
        const overflowStyle = isHorizontal ? computedStyle.overflowX : computedStyle.overflowY;

        // Check if the element is even scrollable.
        // There doesn't seem to be a nice way of doing this...
        if (['auto', 'scroll'].includes(overflowStyle) || ['auto', 'scroll'].includes(computedStyle.overflow)) {
          if (isMinAnchored) {
            const max =
              direction === Direction.LEFT ? Math.max(target.scrollWidth - target.clientWidth, 0) : Math.max(target.scrollHeight - target.clientHeight, 0);
            if (scrollAmount < max - SCROLL_TOLERANCE) {
              return true;
            }
          } else if (scrollAmount > SCROLL_TOLERANCE) {
            return true;
          }
        }

        if (target === currentRef) {
          return false;
        }

        target = target.parentElement;
      }

      return false;
    },
    [currentRef, isHorizontal]
  );

  /**
   * Handles what happens when a touch event starts
   */
  const onTouchStart = useCallback(
    (event: TouchEvent) => {
      // Do nothing if we're already tracking a touch event
      if (currentTouchId.current !== null) {
        return;
      }
      if (disabled) {
        return;
      }

      // Do nothing if the user might be scrolling
      if (checkForUnfinishedScroll(event.target instanceof HTMLElement ? event.target : null)) {
        return;
      }

      // Initialise everything we need to track this event
      const touch = event.targetTouches[0];
      currentTouchId.current = touch.identifier;
      currentTouchStartPos.current = { x: touch.clientX, y: touch.clientY };
      currentTouchEventState.current = SwipeDismissState.NONE;

      prevTouchPoints.current = [];
      addPosition(touch.clientX, touch.clientY);

      // Remove transitions during the drag, as they seem to be buggy.
      if (currentRef === null) {
        return;
      }
      currentRef.style.transition = 'unset';
    },
    [currentRef, disabled, checkForUnfinishedScroll, addPosition]
  );

  /**
   * Handles what happens when a touch event continues
   */
  const onTouchMove = useCallback(
    (event: TouchEvent) => {
      // Do nothing if there's no touch event being tracked
      if (currentTouchId.current === null) {
        return;
      }

      // Do nothing if we can't find the touch we're trying to track
      const touch = findTouchByIdentifier(event.changedTouches, currentTouchId.current);
      if (!touch) {
        return;
      }

      // Keep a record of the position for later
      addPosition(touch.clientX, touch.clientY);

      if (currentRef === null) {
        return;
      }

      // Act differently based on what
      switch (currentTouchEventState.current) {
        case SwipeDismissState.STARTED:
          // Offset the menu by the total drag distance.
          moveElemTo(touch.clientX, touch.clientY);
          break;
        case SwipeDismissState.NONE:
          // Check if we've moved enough to start, and commit one way or another if possible.
          const offsetX = Math.abs(touch.clientX - currentTouchStartPos.current.x);
          const offsetY = Math.abs(touch.clientY - currentTouchStartPos.current.y);

          if (offsetX > MINIMUM_DISTANCE || offsetY > MINIMUM_DISTANCE) {
            currentTouchEventState.current = isHorizontal
              ? offsetX > offsetY
                ? SwipeDismissState.STARTED
                : SwipeDismissState.REJECTED
              : offsetY > offsetX
                ? SwipeDismissState.STARTED
                : SwipeDismissState.REJECTED;
          }

          break;
        case SwipeDismissState.REJECTED:
        default:
        // Do nothing...
      }
    },
    [currentRef, findTouchByIdentifier, addPosition, moveElemTo, isHorizontal]
  );

  /**
   * Handles what happens when a touch event ends
   */
  const onTouchEnd = useCallback(
    (event: TouchEvent) => {
      // Do nothing if there's no touch event being tracked
      if (currentTouchId.current === null) {
        return;
      }

      // Do nothing if we can't find the touch we're trying to track
      const touch = findTouchByIdentifier(event.changedTouches, currentTouchId.current);
      if (!touch) {
        return;
      }

      addPosition(touch.clientX, touch.clientY);

      if (currentRef === null) {
        return;
      }

      currentRef.style.transition = elemTransition.current;

      // Reset if the touch event ends before the touch event is registered as a swipe
      if (currentTouchEventState.current !== SwipeDismissState.STARTED) {
        currentTouchId.current = null;
        return;
      }

      // Calculate the average swipe velocity based on all tracked points that aren't stale.
      const averageVelocity: Vector2 = prevTouchPoints.current
        .filter((value) => value.time >= Date.now() - VELOCITY_HISTORY_AGE)
        .reduce(
          (acc, value, i, arr) => ({
            x: acc.x + value.velocity.x / arr.length,
            y: acc.y + value.velocity.y / arr.length,
          }),
          { x: 0, y: 0 }
        );

      if (!checkSwipeAxisCorrect(averageVelocity) || !checkSwipeVelocityIsEnough(averageVelocity)) {
        // We're either swiping on the wrong axis, or barely swiping, so cancel based on distance from start.
        if (checkDismissedEnough(touch.clientX, touch.clientY)) {
          moveElemToEnd();
          onDismiss();
        } else {
          moveElemToSart();
        }
      } else if (checkSwipeDirectionCorrect(averageVelocity)) {
        // We're swiping in the dismiss direction, so dismiss the element.
        moveElemToEnd();
        onDismiss();
      } else {
        // We're swiping in the opposite of the dismiss direction, keep the element visible.
        moveElemToSart();
      }

      // We're ready to start again
      currentTouchId.current = null;
    },
    [
      currentRef,
      findTouchByIdentifier,
      addPosition,
      checkSwipeAxisCorrect,
      checkSwipeVelocityIsEnough,
      checkDismissedEnough,
      moveElemToSart,
      moveElemTo,
      moveElemToEnd,
      onDismiss,
    ]
  );

  /**
   * Handles what happens when a tocuh event is cancelled.
   */
  const onTouchCancel = useCallback(
    (event: TouchEvent) => {
      onTouchEnd(event);
    },
    [currentRef, onTouchEnd]
  );

  /**
   * Cancels any current swipe that's ongoing.
   */
  const cancel = useCallback(() => {
    if (currentRef === null) {
      return;
    }
    currentRef.style.transform = '';
    currentTouchId.current = null;
  }, [currentRef]);

  /**
   * Re-set-up all the event listeners if the element ref changes
   */
  useEffect(() => {
    if (currentRef === null) {
      return () => {};
    }

    elemTransition.current = currentRef.style.transition;

    currentRef.addEventListener('touchstart', onTouchStart);
    currentRef.addEventListener('touchmove', onTouchMove);
    currentRef.addEventListener('touchend', onTouchEnd);
    currentRef.addEventListener('touchcancel', onTouchCancel);

    return () => {
      currentRef.style.transition = elemTransition.current;
      currentRef.removeEventListener('touchstart', onTouchStart);
      currentRef.removeEventListener('touchmove', onTouchMove);
      currentRef.removeEventListener('touchend', onTouchEnd);
      currentRef.removeEventListener('touchcancel', onTouchCancel);
    };
  }, [currentRef, onTouchStart, onTouchMove, onTouchEnd, onTouchCancel]);

  return { ref, cancel };
};

export default useSwipeDismiss;
