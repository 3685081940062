import React from 'react';

import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';
import FormField, { FORM_FIELD_PRESETS, FormLayout, FormSection, FormValuesField, FORM_LAYOUT_PRESETS } from '@gi/form-responsive';
import { useSelector } from 'react-redux';

interface iProps {
  enableMouseWheelZooming: boolean;
  setEnableMouseWheelZooming: (value: boolean) => void;
  snapToGrid: FormValuesField<boolean>;
  setSnapToGrid: (value: boolean) => void;
  enableSnapOnSFGMode: FormValuesField<boolean>;
  setEnableSnapOnSFGMode: (value: boolean) => void;
  touchMode: FormValuesField<boolean>;
  setTouchMode: (value: boolean) => void;
  autoPan: FormValuesField<boolean>;
  setAutoPan: (value: boolean) => void;
  touchDragRequiresSelection: FormValuesField<boolean>;
  setTouchDragRequiresSelection: (value: boolean) => void;
}

const InteractionSettings = ({
  enableMouseWheelZooming,
  setEnableMouseWheelZooming,
  snapToGrid,
  setSnapToGrid,
  enableSnapOnSFGMode,
  setEnableSnapOnSFGMode,
  touchMode,
  setTouchMode,
  autoPan,
  setAutoPan,
  touchDragRequiresSelection,
  setTouchDragRequiresSelection,
}: iProps): JSX.Element => {
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  const forceTouchMode = deviceDisplayMode === DeviceDisplayMode.MOBILE;

  return (
    <FormSection heading='Interaction' padding={12} gap={6} className='form-section-background'>
      <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.CheckboxStack}>
        <FormField
          label='Touch Mode'
          helpText='Touch Mode makes handles bigger, touch controls are not disabled when not in touch mode'
          errorText={forceTouchMode ? 'Touch Mode is always enabled in Mobile mode' : undefined}
          htmlFor='interaction-settings:touch-mode'
          layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
          disabled={forceTouchMode}
        >
          <input
            type='checkbox'
            checked={forceTouchMode ? true : touchMode.value}
            onChange={(e) => {
              setTouchMode(e.target.checked);
            }}
            disabled={forceTouchMode}
            id='interaction-settings:touch-mode'
          />
        </FormField>
        <FormField label='Enable mouse-wheel zooming' htmlFor='interaction-settings:mwheel-zoom' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
          <input
            type='checkbox'
            checked={enableMouseWheelZooming}
            onChange={(e) => {
              setEnableMouseWheelZooming(e.target.checked);
            }}
            id='interaction-settings:mwheel-zoom'
          />
        </FormField>
        <FormField label='Snap to Grid' htmlFor='interaction-settings:snap-to-grid' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
          <input
            type='checkbox'
            checked={snapToGrid.value}
            onChange={(e) => {
              setSnapToGrid(e.target.checked);
            }}
            id='interaction-settings:snap-to-grid'
          />
        </FormField>
        <FormField
          label="Automatically enable 'Snap to Grid' when entering SFG mode and disable 'Snap to Grid' when exiting SFG mode"
          htmlFor='interaction-settings:sfg-auto-snap-to-grid'
          layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
        >
          <input
            type='checkbox'
            checked={enableSnapOnSFGMode.value}
            onChange={(e) => {
              setEnableSnapOnSFGMode(e.target.checked);
            }}
            id='interaction-settings:sfg-auto-snap-to-grid'
          />
        </FormField>
        <FormField
          label='Automatically pan the camera when dragging an item near the edges of the planner'
          htmlFor='interaction-settings:auto-pan'
          layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
        >
          <input
            type='checkbox'
            checked={autoPan.value}
            onChange={(e) => {
              setAutoPan(e.target.checked);
            }}
            id='interaction-settings:auto-pan'
          />
        </FormField>
        <FormField
          label='Touch-drag requires selection'
          helpText='Items must be selected before being draggable when using touch. Disable to make touch work the same as a mouse.'
          htmlFor='interaction-settings:touch-drag'
          layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}
        >
          <input
            type='checkbox'
            checked={touchDragRequiresSelection.value}
            onChange={(e) => {
              setTouchDragRequiresSelection(e.target.checked);
            }}
            id='interaction-settings:touch-drag'
          />
        </FormField>
      </FormLayout>
    </FormSection>
  );
};

export default InteractionSettings;
