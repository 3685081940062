import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Modal, {
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalHeader,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalPane,
  ModalPaneContainer,
  ModalType,
} from '@gi/modal';
import { PlanDataError } from '@gi/react-garden-canvas';
import { IntercomActionCreators } from '@gi/intercom';

import PlanDataErrorEntry from './plan-data-error-entry';

import styles from './plan-data-error-modal.module.css';

interface iProps {
  onClose: () => void;
  planDataErrors: PlanDataError[];
}

const PlanDataErrorModal = ({ onClose, planDataErrors }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const planText = useMemo(() => (planDataErrors.length === 1 ? 'plan' : 'plans'), [planDataErrors]);

  const openChat = useCallback(() => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('plan-data-error-modal'));
  }, []);

  return (
    <Modal closeRequest={onClose} type={ModalType.Default} style={{ maxWidth: 'min(625px, 100%)' }}>
      <ModalContent>
        <ModalCloseButton onClick={onClose} />
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>Plan saved with modifications</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>
            <p>
              Your {planText} saved successfully, however, some properties of items in your {planText} were invalid and had to be reset to 0.
            </p>
            <div className={styles.errorsContainer}>
              {planDataErrors.map((error) => {
                return <PlanDataErrorEntry planDataError={error} key={error.plan.id} />;
              })}
            </div>
            <p>
              We are actively looking into this issue. If you would like to provide any information about what you were doing when this happened, or anything
              that appeared incorrect on your plan before saving, please{' '}
              <button type='button' className='button button-inline intercom-button' onClick={openChat}>
                contact support
              </button>{' '}
              to let us know.
            </p>
          </ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-intercom' onClick={openChat}>
                Open Live Chat
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={onClose}>
                Dismiss
              </button>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PlanDataErrorModal;
