import React from 'react';
import { useSelector } from 'react-redux';
import { CanvasSelectors } from '@gi/react-garden-canvas';

import MonthSelector from './month-selector';
import ControlGroup from '../../common/control-group';
import ControlRow from '../../common/control-row';

const TimelineControls = (): JSX.Element => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  return (
    <ControlGroup>
      <ControlRow>
        <MonthSelector disabled={activePlan === null} attachToBody />
      </ControlRow>
    </ControlGroup>
  );
};

export default TimelineControls;
