import { Filter } from '@gi/filters';
import Plant from '@gi/plant';
import { UserPlantingCalendars } from '@gi/planting-calendar';

type PlantingDatesFilterState = {
  enabled: boolean;
  userPlantingCalendars: null | UserPlantingCalendars;
  sowMonth: null | number;
  plantMonth: null | number;
  harvestMonth: null | number;
};

const plantingDatesFilter: Filter<Plant, PlantingDatesFilterState> = {
  name: 'Planting Dates',
  inputs: {
    enabled: true,
    userPlantingCalendars: null,
    sowMonth: null,
    plantMonth: null,
    harvestMonth: null,
  },
  createListFilter: ({ enabled, userPlantingCalendars, sowMonth, plantMonth, harvestMonth }) => {
    const filterFunctions: ((plant: Plant) => boolean)[] = [];

    if (sowMonth !== null) {
      filterFunctions.push((plant) => {
        if (userPlantingCalendars === null) {
          return false;
        }

        return userPlantingCalendars.search[plant.code] && userPlantingCalendars.search[plant.code].availability.sow[sowMonth];
      });
    }

    if (plantMonth !== null) {
      filterFunctions.push((plant) => {
        if (userPlantingCalendars === null) {
          return false;
        }
        return userPlantingCalendars.search[plant.code] && userPlantingCalendars.search[plant.code].availability.plant[plantMonth];
      });
    }

    if (harvestMonth !== null) {
      filterFunctions.push((plant) => {
        if (userPlantingCalendars === null) {
          return false;
        }
        return userPlantingCalendars.search[plant.code] && userPlantingCalendars.search[plant.code].availability.harvest[harvestMonth];
      });
    }

    return (inputPlantList) => {
      if (!enabled) {
        return inputPlantList;
      }

      if (filterFunctions.length === 0) {
        return inputPlantList;
      }

      return inputPlantList.filter((plant) => {
        for (let i = 0; i < filterFunctions.length; i++) {
          if (!filterFunctions[i](plant)) {
            return false; // If any of the filters fail, don't include the plant
          }
        }

        return true;
      });
    };
  },
};

export default plantingDatesFilter;
