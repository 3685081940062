import React from 'react';
import { useSelector } from 'react-redux';

import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';

import AppJournalDesktop from '@gi/app-journal-desktop';
import AppJournalMobile from '@gi/app-journal-mobile';

const AppJournal = (): JSX.Element => {
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  if (deviceDisplayMode === DeviceDisplayMode.DESKTOP) {
    return <AppJournalDesktop />;
  }

  return <AppJournalMobile />;
};

export default AppJournal;
