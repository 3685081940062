import React from 'react';

interface iProps {
  count: number;
}

const InsetCountsCell = ({ count }: iProps): JSX.Element | null => {
  return <td className='inset-td counts-td'>{count.toLocaleString()}</td>;
};

export default InsetCountsCell;
