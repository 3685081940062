import { Store } from 'redux';

import { GardenCanvasClipboardControllerEvent } from '@gi/plan-simulation';

import { updateClipboardState } from '../redux-components/garden-canvas-event-action-creators';
import GardenCanvasController, { GardenCanvasControllerEvent } from '../garden-canvas-controller/garden-canvas-controller';

const setupClipboardEventsHandler = (store: Store, gardenCanvasController: GardenCanvasController) => {
  let { gardenCanvas } = gardenCanvasController.getInstance();

  const dispatchClipboardState = () => {
    if (!gardenCanvas) {
      return;
    }
    const { hasClipboard } = gardenCanvas.clipboardController;
    store.dispatch(updateClipboardState(hasClipboard));
  };

  const onClipboardUpdate = () => {
    dispatchClipboardState();
  };

  const setup = () => {
    dispatchClipboardState();

    // Subscribe to changes
    gardenCanvas?.clipboardController.on(GardenCanvasClipboardControllerEvent.ClipboardUpdate, onClipboardUpdate);
  };

  const teardown = () => {
    // Unsubscribe from changes
    gardenCanvas?.clipboardController.off(GardenCanvasClipboardControllerEvent.ClipboardUpdate, onClipboardUpdate);
  };

  const onGardenCanvasControllerStateUpdate = () => {
    if (gardenCanvas !== null) {
      teardown();
    }

    // See: https://hacks.mozilla.org/2015/05/es6-in-depth-destructuring/ for why the parenthese are needed
    ({ gardenCanvas } = gardenCanvasController.getInstance());

    if (gardenCanvas !== null) {
      setup();
    }
  };

  // Note: we cannot remove this event as we don't have an easy way to have a reference to the event callback
  // once this setup function exits, so we must make sure `gardenCanvasController.destroy()` is called to remove
  // all listeners
  gardenCanvasController.on(GardenCanvasControllerEvent.STATE_CHANGE, onGardenCanvasControllerStateUpdate);

  if (gardenCanvas !== null) {
    // Set initial values, this will probably never be called as garden cavnas will likely be null when this store listener is added
    dispatchClipboardState();
  }
};

export default setupClipboardEventsHandler;
