import React, { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Engine } from '@gi/core-renderer';
import { IS_IOS, LoadingState } from '@gi/constants';
import { CanvasActionCreators } from '@gi/react-garden-canvas';

import PrintPlanContext, { PrintPlanContextType } from './print-context';
import PDFViewer from '../pdf-viewer/pdf-viewer';
import PrintModal from '../print-modal/print-modal';
import { generatePDF as _generatePDF } from '../../generate-pdf';
import { AsyncPrintOperation, CurrentPlanData, OnPDFCompleteOption, PrintSettings } from '../../types';

interface iProps {
  children: ReactNode;
}

const PrintPlanProvider = ({ children }: iProps): JSX.Element => {
  const [pdf, setPdf] = useState<AsyncPrintOperation>({ status: LoadingState.NONE });
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const isRecentPdf = useRef(false);
  const dispatch = useDispatch();

  const downloadPDF = useCallback((pdfData: Uint8Array, fileName: string = 'Garden Plan') => {
    const blob = new Blob([pdfData], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.target = '_blank';
    link.download = `${fileName}.pdf` ?? 'garden.pdf';
    link.click();
  }, []);

  const openPDF = useCallback(
    (pdfData: Uint8Array) => {
      if (!IS_IOS) {
        setPdfViewerOpen(true);
        return;
      }

      const windowReference = window.open();
      if (!windowReference) {
        return;
      }

      // @ts-expect-error TS thinks URL doesn't exist on Window
      const url = windowReference.URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
      windowReference.location.href = url;
      windowReference.onbeforeunload = () => {
        // @ts-expect-error TS thinks URL doesn't exist on Window
        return windowReference.URL.revokeObjectURL(url);
      };
    },
    [setPdfViewerOpen, IS_IOS]
  );

  const downloadCurrentPDF = useCallback(
    (fileName?: string) => {
      if (pdf.status !== LoadingState.SUCCESS) {
        throw new Error('No current PDF to download');
      }
      downloadPDF(pdf.value, fileName);
    },
    [pdf]
  );

  const viewCurrentPDF = useCallback(() => {
    if (pdf.status !== LoadingState.SUCCESS) {
      throw new Error('No current PDF to view');
    }
    openPDF(pdf.value);
  }, [pdf]);

  const generatePDF = useCallback<PrintPlanContextType['generatePDF']>(
    (engine: Engine, plan: CurrentPlanData, printSettings: PrintSettings, onComplete: OnPDFCompleteOption = 'NONE', fileName?: string) => {
      if (pdf.status === LoadingState.LOADING) {
        throw new Error('Already generating PDF');
      }

      setPdf({ status: LoadingState.LOADING, progress: 0 });

      return _generatePDF(engine, plan, printSettings, (progress, message) => {
        setPdf({ status: LoadingState.LOADING, progress, message });
      })
        .then((finalPdf) => {
          setPdf({ status: LoadingState.SUCCESS, value: finalPdf });
          isRecentPdf.current = true;
          switch (onComplete) {
            case 'DOWNLOAD':
              downloadPDF(finalPdf, fileName);
              break;
            case 'VIEW':
              openPDF(finalPdf);
              break;
            default:
            // Do nothing
          }
          return finalPdf;
        })
        .catch((error) => {
          setPdf({ status: LoadingState.ERROR, error });
          throw error;
        });
    },
    [pdf]
  );

  const openPrintModal = useCallback(() => {
    dispatch(CanvasActionCreators.saveOpenPlans());
    setPrintModalOpen(true);
    if (pdf.status === LoadingState.SUCCESS) {
      setPdf({ status: LoadingState.NONE });
    }
  }, [setPrintModalOpen, pdf]);

  const closePrintModal = useCallback(() => {
    setPrintModalOpen(false);
  }, [setPrintModalOpen]);

  const closePdfViewer = useCallback(() => {
    setPdfViewerOpen(false);
  }, [setPdfViewerOpen]);

  const value = useMemo<PrintPlanContextType>(
    () => ({
      generatePDF,
      pdf,
      openPrintModal,
      closePrintModal,
      closePdfViewer,
      downloadCurrentPDF,
      viewCurrentPDF,
    }),
    [generatePDF, pdf, openPrintModal, closePrintModal, closePdfViewer, downloadCurrentPDF, viewCurrentPDF]
  );

  return (
    <PrintPlanContext.Provider value={value}>
      {printModalOpen ? <PrintModal onClose={() => setPrintModalOpen(false)} /> : null}
      {pdfViewerOpen && pdf.status === LoadingState.SUCCESS ? <PDFViewer pdfData={pdf.value} onClose={() => setPdfViewerOpen(false)} /> : null}
      {children}
    </PrintPlanContext.Provider>
  );
};

export default PrintPlanProvider;
