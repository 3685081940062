import Bugsnag, { Client, OnErrorCallback } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import deepmerge from 'deepmerge';

import { Config, RuntimeConfig } from '@gi/config';

import { NonErrorError } from './non-error-error';

declare global {
  interface Error {
    __reportedToBugsnag?: boolean;
  }
}

type GIBugsnagOptions = {
  appVersion: string;
  notifyReleaseStages: string[];
  apiKey: string;
};

const DEFAULT_OPTS: Partial<GIBugsnagOptions> = {
  appVersion: VERSION,
  notifyReleaseStages: ['test', 'production'],
};

const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

class GIBugsnag {
  client: Client;

  constructor(_config: Config, _opts: Partial<GIBugsnagOptions>) {
    const opts = deepmerge(DEFAULT_OPTS, _opts, { arrayMerge: overwriteMerge });

    this.client = Bugsnag.start({
      appVersion: opts.appVersion,
      plugins: [new BugsnagPluginReact()],
      enabledReleaseStages: opts.notifyReleaseStages,
      apiKey: opts.apiKey ?? _config.bugsnag.apiKey,
      releaseStage: _config.bugsnag.releaseStage,
      autoDetectErrors: true,
    });

    this.client.addOnError(this.onError);

    console.debug('Initialised Logger', _config.bugsnag.releaseStage);
  }

  notify(...args: Parameters<Client['notify']>) {
    this.client.notify(...args);
  }

  setUser(id?: string, email?: string) {
    this.client.setUser(id, email);
    console.log('Logger setting user', id);
  }

  setClient(runtimeConfig: RuntimeConfig) {
    this.client.addMetadata('Client', {
      client: runtimeConfig.client,
      clientId: runtimeConfig.clientID,
      clientName: runtimeConfig.clientName,
    });
    console.log('Logger setting client', runtimeConfig.clientName);
  }

  getPlugin(...args: Parameters<Client['getPlugin']>) {
    return this.client.getPlugin(...args);
  }

  onError: OnErrorCallback = (event) => {
    const isError = event.originalError && event.originalError instanceof Error;

    // Bugsnag doesn't like non-errors
    // Here we'll wrap the non-error in an error to see if we get a better stacktrace. Unlikely...
    if (!isError) {
      console.error(`Bugsnag received a non-error: ${event.originalError}`);
      this.client.notify(new NonErrorError(event.originalError));
      return true; // Still send the non-error in case it has better information.
    }

    // Check if we've marked the error as already being reported.
    // This prevents things like network request errors getting sent twice, as they're reported by
    //  both the network request system and the redux request system.
    if (isError && event.originalError.__reportedToBugsnag === true) {
      console.warn('Skipping sending error - Error has already been sent.\n', event.originalError);
      return false;
    }

    if (isError) {
      // Mark the error as reported to prevent duplication.
      // Wrap in a try-catch, just in case. Also mark as not enumerable to minimise chance of breaking things.
      try {
        Object.defineProperty(event.originalError, '__reportedToBugsnag', {
          enumerable: false,
          configurable: true,
          writable: true,
          value: true,
        });
      } catch {
        // We tried.
      }
    }

    return true;
  };
}

export default GIBugsnag;
