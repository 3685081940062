import React, { useContext, useMemo } from 'react';
import {
  EmptyPlantingCalendarDisplay,
  PlantingCalendar,
  PlantingCalendarDisplay,
  plantingCalendarIsEmpty,
  TextPlantingCalendarDisplay,
} from '@gi/planting-calendar';
import networkConfig from '@gi/config/network-config';
import { ResourceContext } from '@gi/resource-provider';
import { useSelector } from 'react-redux';
import { SessionSelectors } from '@gi/react-session';
import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

interface iProps {
  plantingCalendar: PlantingCalendar | null;
}

const CalendarCell = ({ plantingCalendar }: iProps): JSX.Element | null => {
  const { columns, options } = useContext(GlobalPlantListContext);
  const { userArtifactCode } = useContext(ResourceContext);
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);
  const northernHemisphere = useSelector(SessionSelectors.getIsNorthernHemisphere);

  const calendarStartMonth = northernHemisphere ? 1 : 7;

  const calendarComponent = useMemo(() => {
    if (plantingCalendar === null || (plantingCalendarIsEmpty(plantingCalendar) && !plantingCalendar.isCustom)) {
      return <EmptyPlantingCalendarDisplay />;
    }

    if (options.textPlantingCalendars) {
      const halfMonthOffset = (calendarStartMonth - 1) * 2;
      return <TextPlantingCalendarDisplay plantingCalendar={plantingCalendar} halfMonthOffset={halfMonthOffset} />;
    }

    return (
      <PlantingCalendarDisplay
        plantingCalendar={plantingCalendar}
        startMonth={calendarStartMonth}
        calendarBarURL={networkConfig.plantingCalendarImageURL}
        highlightOnHover
        artifactCode={userArtifactCode === null ? 'us' : userArtifactCode}
        compact={deviceDisplayMode === DeviceDisplayMode.MOBILE}
      />
    );
  }, [plantingCalendar, options.textPlantingCalendars, calendarStartMonth]);

  if (!columns.calendar) {
    return null;
  }

  return (
    <td className='calendar-td cell-with-label'>
      <div className='cell-label'>Calendar</div>
      <div className='cell-content scroll-x'>{calendarComponent}</div>
    </td>
  );
};

export default CalendarCell;
