import React from 'react';

import UserLocationFrostDatesForm from '../forms/user-location-frost-dates-form';

import './settings-pages.scss';

const LocationFrostDatesPage = (): JSX.Element => {
  return (
    <div className='settings-page'>
      <UserLocationFrostDatesForm />
    </div>
  );
};

export default LocationFrostDatesPage;
