import { Store } from 'redux';

import { SessionReducerState } from '@gi/react-session';
import { LocalSettings } from '@gi/local-settings';
import { GardenCanvasSettings } from '@gi/plan-simulation';

import GardenCanvasController, { GardenCanvasControllerEvent } from '../garden-canvas-controller/garden-canvas-controller';

/**
 * Don't sync touchMode from the store, instead use the value from the DisplayMode context.
 *  That needs to be done from somewhere in React.
 */

const LocalSettingsToSync: (keyof LocalSettings)[] = [
  'autoPan',
  'plantDisplayMode',
  'plantSpriteCountLimit',
  'renderMode',
  'sfgMode',
  'snapToGrid',
  'wheelMode',
  'touchDragRequiresSelection',
  'textQuality',
];

type StoreSlice = {
  session: SessionReducerState;
  localSettings: LocalSettings;
};

const settingsSynchroniser = (store: Store<StoreSlice>, gardenCanvasController: GardenCanvasController) => {
  let currentState = store.getState();
  let { gardenCanvas } = gardenCanvasController.getInstance();

  const handleChange = () => {
    const previousState = currentState;
    currentState = store.getState();

    if (currentState.session.user === null) {
      // Current user has just logged out, nothing to synchronise to planner
      return;
    }

    ({ gardenCanvas } = gardenCanvasController.getInstance());
    if (gardenCanvas === null) {
      return;
    }

    if (previousState.session.user?.plantVarieties !== currentState.session.user.plantVarieties) {
      gardenCanvas.setUserPlantVarieties(currentState.session.user.plantVarieties);
    }

    if (previousState.session.user?.planSettings !== currentState.session.user.planSettings) {
      gardenCanvas.setSettings(currentState.session.user.planSettings);
    }

    if (previousState.localSettings !== currentState.localSettings) {
      const prevLocSettings = previousState.localSettings;
      const currLocSettings = currentState.localSettings;

      const changedSettings: Partial<GardenCanvasSettings> = {};

      for (let i = 0; i < LocalSettingsToSync.length; i++) {
        if (prevLocSettings[LocalSettingsToSync[i]] !== currLocSettings[LocalSettingsToSync[i]]) {
          changedSettings[LocalSettingsToSync[i]] = currLocSettings[LocalSettingsToSync[i]];
        }
      }

      gardenCanvas.setSettings(changedSettings);
    }
  };

  // When the garden canvas controller updates the Garden Planner instance, we want to set all the current settings
  const onGardenCanvasControllerStateUpdate = () => {
    ({ gardenCanvas } = gardenCanvasController.getInstance());

    if (gardenCanvas !== null) {
      if (currentState.session.user) {
        gardenCanvas.setSettings({
          // angleSnap: 45,
          // angleSnapMagnetism: 2,
          ...currentState.session.user.planSettings,
          autoPan: currentState.localSettings.autoPan,
          plantDisplayMode: currentState.localSettings.plantDisplayMode,
          plantSpriteCountLimit: currentState.localSettings.plantSpriteCountLimit,
          renderMode: currentState.localSettings.renderMode,
          sfgMode: currentState.localSettings.sfgMode,
          snapToGrid: currentState.localSettings.snapToGrid,
          wheelMode: currentState.localSettings.wheelMode,
          touchDragRequiresSelection: currentState.localSettings.touchDragRequiresSelection,
          textQuality: currentState.localSettings.textQuality,
        });
      }
    }
  };

  gardenCanvasController.on(GardenCanvasControllerEvent.STATE_CHANGE, onGardenCanvasControllerStateUpdate);

  onGardenCanvasControllerStateUpdate();

  store.subscribe(handleChange);
};

export default settingsSynchroniser;
