import { ReactNode } from 'react';

/**
 * Determines how a search result should be displayed.
 */
export enum SearchDisplayMode {
  /**
   * This field is a primary field, like the main name of a search entry
   */
  Primary,
  /**
   * This field is a secondary field, like an alternative name or misspelling.
   * Should be displayed alongside the main name, not replacing it.
   */
  Secondary,
  /**
   * This field shouldn't be dipslayed to the user, instead use whatever the main field is.
   */
  Hidden,
}

interface SearchResultProperties<T> {
  item: T;
  code: string;
  score: number;
  getHtml: () => ReactNode;
  text: string;
  matchedKey: string | number | symbol;
  displayMode?: SearchDisplayMode;
}

class SearchResult<T> {
  item: T;
  code: string = '';
  // eslint-disable-next-line class-methods-use-this
  getHtml: () => ReactNode = () => '';
  // eslint-disable-next-line class-methods-use-this
  getText: () => string = () => '';
  matchedKey: string | number | symbol = '';
  score: number = 0;
  displayMode: SearchDisplayMode;

  constructor(result: SearchResultProperties<T>) {
    this.item = result.item;
    this.code = result.code;
    this.score = result.score;
    this.getHtml = result.getHtml;
    this.getText = () => result.text;
    this.matchedKey = result.matchedKey;
    this.displayMode = result.displayMode ?? SearchDisplayMode.Primary;
  }
}

export default SearchResult;
