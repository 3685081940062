import React, { MouseEvent, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { SessionSelectors } from '@gi/react-session';
import { Direction } from '@gi/constants';
import IntercomIcon from '@gi/intercom-icon';
import { usePrevious, useSwipeDismiss, DelayedUnmount } from '@gi/react-utils';
import { Application, NavigationContext, getAbsoluteApplicationLink } from '@gi/garden-platform-navigation';
import { IntercomActionCreators } from '@gi/intercom';

import MobileNavigationLink from './mobile-nav-link';
import MobileNavigationLinkGroup from './mobile-nav-link-group';
import MobileNavigationLogo from './mobile-nav-logo';
import MobileNavigationSubLink from './mobile-nav-sublink';
import MobileNavigationSubLinkGroup from './mobile-nav-sublink-group';
import MobileNavigationFooter from './mobile-nav-footer';
import { MobileNavSelectors, MobileNavActionCreators } from '../../store/mobile-nav-slice';

import './mobile-nav.scss';

const MobileNavigation = (): JSX.Element => {
  const navigate = useNavigate();
  const user = useSelector(SessionSelectors.getUser);
  const isMounted = useSelector(MobileNavSelectors.getIsOpen);
  const dispatch = useDispatch();
  const { activeApplication } = useContext(NavigationContext);

  const { pathname } = useLocation();

  const { ref, cancel: cancelSwipe } = useSwipeDismiss(Direction.LEFT, {
    onDismiss: () => {
      dispatch(MobileNavActionCreators.setIsOpen(false));
    },
  });

  /**
   * Handles closing the nav menu
   */
  const closeMenu = () => {
    cancelSwipe();
    dispatch(MobileNavActionCreators.setIsOpen(false));
  };

  /**
   * Handles closing the menu if the user clicks the background
   */
  const handleBackgroundClick = (e: MouseEvent) => {
    if (e.target === e.currentTarget) {
      closeMenu();
    }
  };

  /**
   * Opens Intercom
   */
  const openLiveChat = () => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('mobile-nav'));
    dispatch(MobileNavActionCreators.setIsOpen(false));
  };

  const previousPathname = usePrevious(pathname);
  useEffect(() => {
    // Close the nav bar when we change URL
    if (previousPathname && pathname !== previousPathname) {
      dispatch(MobileNavActionCreators.setIsOpen(false));
    }
  }, [pathname]);

  const classNames = ['mobile-navigation-container'];
  if (isMounted) {
    classNames.push('open');
  }

  return (
    <DelayedUnmount isMounted={isMounted} unmountDelay={200}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={classNames.join(' ')} onClick={handleBackgroundClick}>
        <nav className='mobile-navigation' ref={ref}>
          <MobileNavigationLogo />
          <div className='mobile-navigation-links-container'>
            <MobileNavigationLinkGroup>
              <MobileNavigationLink
                icon='icon-overview'
                title='Overview'
                active={activeApplication === Application.Overview}
                onClick={() => {
                  navigate(getAbsoluteApplicationLink(Application.Overview));
                  dispatch(MobileNavActionCreators.setIsOpen(false));
                }}
              />
              <MobileNavigationLink
                icon='icon-garden-planner'
                title='Garden Planner'
                active={activeApplication === Application.GardenPlanner}
                onClick={() => {
                  navigate(getAbsoluteApplicationLink(Application.GardenPlanner));
                  dispatch(MobileNavActionCreators.setIsOpen(false));
                }}
              />
              <MobileNavigationLink
                icon='icon-journal'
                title='Garden Journal'
                active={activeApplication === Application.Journal}
                onClick={() => {
                  navigate(getAbsoluteApplicationLink(Application.Journal));
                  dispatch(MobileNavActionCreators.setIsOpen(false));
                }}
              />
              <MobileNavigationLink
                icon='icon-guru'
                title='Garden Guru'
                active={activeApplication === Application.Guru}
                onClick={() => {
                  navigate(getAbsoluteApplicationLink(Application.Guru));
                  dispatch(MobileNavActionCreators.setIsOpen(false));
                }}
              />
            </MobileNavigationLinkGroup>
            <MobileNavigationLinkGroup toBottom>
              <MobileNavigationSubLinkGroup>
                <MobileNavigationLink
                  icon='icon-help-circled'
                  title='Help & Support'
                  active={activeApplication === Application.Help}
                  onClick={() => {
                    navigate(getAbsoluteApplicationLink(Application.Help));
                    dispatch(MobileNavActionCreators.setIsOpen(false));
                  }}
                />
                <MobileNavigationSubLink
                  icon={<IntercomIcon noBackground />}
                  title='Live Chat'
                  parentActive={activeApplication === Application.Help}
                  onClick={() => {
                    openLiveChat();
                    dispatch(MobileNavActionCreators.setIsOpen(false));
                  }}
                  className='intercom-split'
                />
              </MobileNavigationSubLinkGroup>
              <MobileNavigationLink
                icon='icon-user-circle-o'
                title='Account'
                subtitle={user?.email}
                active={activeApplication === Application.Account}
                onClick={() => {
                  navigate(getAbsoluteApplicationLink(Application.Account));
                  dispatch(MobileNavActionCreators.setIsOpen(false));
                }}
              />
            </MobileNavigationLinkGroup>
            <MobileNavigationFooter />
          </div>
        </nav>
      </div>
    </DelayedUnmount>
  );
};

export default MobileNavigation;
