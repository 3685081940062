import React from 'react';

import styles from './bookmarks-directory.module.css';

const BookmarksDirectoryInfo = (): JSX.Element | null => {
  return (
    <div className='guru-content-width'>
      <div className={styles.bookmarksDirectoryInfo}>
        Garden Guru bookmarks allow you to save content for later reference; any bookmarks you save will be displayed below.
      </div>
    </div>
  );
};

export default BookmarksDirectoryInfo;
