import React, { ReactNode } from 'react';

import './selectable-button-group.scss';

interface iProps {
  children: ReactNode;
}

const SelectableButtonGroup = ({ children }: iProps): JSX.Element => {
  return <div className='selectable-button-group-mobile'>{children}</div>;
};

export default SelectableButtonGroup;
