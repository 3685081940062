import React from 'react';

import './modal-help-button.scss';

interface iProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const ModalHelp = ({ onClick }: iProps) => {
  return (
    <div className='modal-help-button'>
      <button type='button' aria-label='Help' className='button' onClick={onClick}>
        <i className='icon-help-circled' />
      </button>
    </div>
  );
};

export default ModalHelp;
