import JournalActionTypes from './journal-action-types';

const DEFAULT_STATE = {};

const journalReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case JournalActionTypes.LOAD_JOURNAL_ACTIVE_YEARS_SUCCESS:
      return {
        ...state,
        [action.plantCode]: {
          activeYears: action.activeYears,
        },
      };
    case JournalActionTypes.LOAD_JOURNAL_SUCCESS:
      return {
        ...state,
        [action.year]: {
          ...state[action.year],
          journalEntries: {
            ...(state[action.year] || {}).journalEntries,
            [action.plantCode]: action.journalEntries,
          },
          journalActions: {
            ...(state[action.year] || {}).journalActions,
            [action.plantCode]: action.journalActions,
          },
        },
      };
    case JournalActionTypes.LOAD_JOURNAL_DAY_ENTRY_COUNTS_SUCCESS:
      return {
        ...state,
        [action.year]: {
          ...state[action.year],
          weeklyTotalsByPlantCode: {
            ...(state[action.year] || {}).weeklyTotalsByPlantCode,
            [action.plantCode]: action.weeklyTotals,
          },
        },
      };
    default:
      return state;
  }
};

export default journalReducer;
