import React, { useEffect, useMemo, useRef, useState } from 'react';

import './countdown-timer.scss';

let counterID = 0;

const generateCountdownUID = () => {
  return counterID++;
};

interface iProps {
  radius?: number;
  strokeWidth?: number;
  strokeColor?: string;
  duration?: number;
  animationCount?: number | string;
  animationStartAt?: number;
}

const CountdownTimer = ({
  radius = 16,
  strokeWidth = 4,
  strokeColor = '#ffffff44',
  duration = 5000,
  animationCount = 'infinite',
  animationStartAt = Date.now(),
}: iProps): JSX.Element => {
  const [countdownInstanceID, setCountdownInstanceID] = useState(generateCountdownUID());
  const isFirstRender = useRef(true);

  /**
   * If animationStartAt has changed, we're probably re-rendering, and want to restart the animation again at the new start point.
   * To do this. we need to create a new animation, otherwise the animationDelay will be based on when the old animation was initialised.
   */
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setCountdownInstanceID(generateCountdownUID());
    }
  }, [animationStartAt]);

  const diameter = useMemo(() => radius * 2, [radius]);
  const circleRadius = useMemo(() => (diameter - strokeWidth) / 2, [diameter, strokeWidth]);

  const style = useMemo(
    () => ({
      strokeWidth,
      stroke: strokeColor,
      strokeDasharray: (diameter - strokeWidth) * Math.PI,
      animationName: `countdown${countdownInstanceID}`,
      animationDuration: `${duration}ms`,
      animationDelay: `${animationStartAt - Date.now()}ms`,
      animationTimingFunction: 'linear',
      animationIterationCount: animationCount,
      animationFillMode: 'forwards',
      animationDirection: 'normal',
    }),
    [strokeWidth, strokeColor, diameter, countdownInstanceID, duration, animationStartAt, animationCount]
  );

  return (
    <div className='countdown-timer' style={{ width: diameter, height: diameter }}>
      <style>
        {`@keyframes countdown${countdownInstanceID} {
          from {
            stroke-dashoffset: 0px;
          }
          to {
            stroke-dashoffset: ${style.strokeDasharray}px;
          }
        }`}
      </style>
      <svg style={{ width: diameter, height: diameter }}>
        <circle r={circleRadius} cx={diameter / 2} cy={diameter / 2} style={style} />
      </svg>
    </div>
  );
};

export default CountdownTimer;
