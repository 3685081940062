import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Plan from '@gi/plan';
import { GardenCanvasContext } from '@gi/react-garden-canvas';
import { GardenCanvasEvent } from '@gi/plan-simulation';
import { FeaturesContext } from '@gi/features';
import { ShoppingActionCreators } from './slice/shopping-slice';

/**
 * A component which takes events from the GardenCanvas and watches for updated plans
 * allowing the shopping list to keep an updated list of plans for which it has out-of-date shopping lists for
 *
 * Shopping lists are only updated when used, so we use this system to make sure we don't update
 * shopping lists each time they are used, just after an update has been made
 *
 * This system also checks that the shopping feature is enabled, otherwise this system is not used
 */
const ShoppingListUpdater = (): null => {
  const dispatch = useDispatch();
  const { gardenCanvas } = useContext(GardenCanvasContext);
  const { featureEnabled } = useContext(FeaturesContext);
  useEffect(() => {
    if (gardenCanvas && featureEnabled('SHOPPING')) {
      const updateCallback = (plan: Plan) => {
        // When a plan is updated, set the list as not upToDate
        // We could ignore changes which do not affect plant and garden object counts but it is significantly easier to
        // update on all changes
        dispatch(ShoppingActionCreators.setListUpToDate({ planId: plan.id, upToDate: false }));
      };

      gardenCanvas.on(GardenCanvasEvent.PLAN_UPDATE, updateCallback);

      return () => {
        gardenCanvas.off(GardenCanvasEvent.PLAN_UPDATE, updateCallback);
      };
    }

    return () => {};
  }, [gardenCanvas]);

  return null;
};

export default ShoppingListUpdater;
