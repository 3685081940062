import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Drawer, DrawerContent, DrawerHeader, DrawerSticky } from '@gi/mobile-components';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { LoadingState } from '@gi/constants';
import { ResourceContext } from '@gi/resource-provider';
import { PlannerCommandContext, usePlanStatusGetter } from '@gi/app-planner-desktop';

import useActivePlannerTab from '../../../util/useActivePlannerTab';
import MobilePlanMenuButton from './mobile-plan-menu-button';
import MobileActivePlanControls from './controls/mobile-active-plan-controls';
import MobilePlanMenuPlanOptions from './mobile-plan-menu-plan-options';
import MobileInactivePlanControls from './controls/mobile-inactive-plan-controls';

import styles from './mobile-plan-menu-drawer.module.css';

interface iProps {
  open: boolean;
  onClose: () => void;
}

const MobilePlanMenuDrawer = ({ open, onClose }: iProps): JSX.Element => {
  const { openPlan, newPlan, closePlan } = useContext(PlannerCommandContext);

  const { userNorthernHemisphere } = useContext(ResourceContext);

  const activePlanId = useSelector(CanvasSelectors.getActivePlanID);
  const openPlans = useSelector(CanvasSelectors.getOpenPlanIDs);

  const activePlannerTab = useActivePlannerTab();
  const getPlanStatus = usePlanStatusGetter();
  const activePlanStatus = useMemo(() => (activePlanId === null ? null : getPlanStatus(activePlanId)), [getPlanStatus, activePlanId]);

  const callAndClose = useCallback(
    (callback: null | (() => void)) => {
      return () => {
        if (callback) {
          callback();
          onClose();
        }
      };
    },
    [onClose]
  );

  /** Section at the top showing the active plan, and appropriate actions for the current active tab */
  const activePlanSection = useMemo(() => {
    if (activePlanId === null || activePlanStatus === null) {
      return null;
    }

    return (
      <MobilePlanMenuPlanOptions
        plan={activePlanStatus.plan}
        northernHemisphere={userNorthernHemisphere}
        isActive
        onClose={() => closePlan(activePlanStatus.plan.ID)}
        loading={activePlanStatus.status}
      >
        {activePlanStatus.status === LoadingState.SUCCESS ? <MobileActivePlanControls activePlannerTab={activePlannerTab} callAndClose={callAndClose} /> : null}
      </MobilePlanMenuPlanOptions>
    );
  }, [activePlanStatus, activePlanId, userNorthernHemisphere, closePlan, callAndClose, activePlannerTab]);

  /** List of all the remaining plans that are currently open */
  const openPlanList = useMemo(() => {
    return openPlans.map((planId) => {
      if (activePlanId === planId) {
        return null;
      }

      const planStatus = getPlanStatus(planId);
      if (!planStatus) {
        return null;
      }

      return (
        <MobilePlanMenuPlanOptions
          plan={planStatus.plan}
          northernHemisphere={userNorthernHemisphere}
          onClose={() => closePlan(planId)}
          loading={planStatus.status}
          key={planId}
        >
          <MobileInactivePlanControls planId={planId} callAndClose={callAndClose} />
        </MobilePlanMenuPlanOptions>
      );
    });
  }, [openPlans, activePlanId, getPlanStatus, userNorthernHemisphere, closePlan, callAndClose]);

  return (
    <Drawer open={open} onClose={onClose} anchor='TOP' width={500}>
      <DrawerHeader onClose={onClose} as='h2'>
        {activePlanId !== null ? 'Open Plans' : 'Open a Plan'}
      </DrawerHeader>
      {openPlans.length > 0 ? (
        <DrawerContent padX padY className={styles.planList}>
          {activePlanSection}
          {openPlanList}
        </DrawerContent>
      ) : null}
      <DrawerSticky padX padY position='BOTTOM' border={openPlans.length > 0} className={styles.bottomButtons}>
        <MobilePlanMenuButton primary onClick={callAndClose(openPlan)}>
          Open Plan
        </MobilePlanMenuButton>
        <MobilePlanMenuButton primary onClick={callAndClose(newPlan)}>
          Create New Plan
        </MobilePlanMenuButton>
      </DrawerSticky>
    </Drawer>
  );
};

export default MobilePlanMenuDrawer;
