import React from 'react';

import styles from './hero-preview-components.module.css';

interface iProps {
  children: React.ReactNode;
}

const HeroPreviewTitle = ({ children }: iProps): JSX.Element => {
  return <div className={styles.heroPreviewTitle}>{children}</div>;
};

export default HeroPreviewTitle;
