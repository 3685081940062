import React from 'react';
import type { RouteObject } from 'react-router-dom';
import Dashboard from '@gi/app-dashboard';
import { Application, ApplicationLinks } from '@gi/garden-platform-navigation';

const appDashboardRoutes: RouteObject = {
  path: ApplicationLinks[Application.Overview],
  element: <Dashboard />,
};

export default appDashboardRoutes;
