/**
 * Takes a value of currency and returns a string representing the correct amount of currency,
 * including the currency symbol
 *
 * Examples
 * 12, 'USD' -> $12.00
 * 12.00001, 'USD' -> $12.00
 * 25.5, 'USD' -> $25.50
 * 100, 'JPY' -> ¥100
 * 100.5, 'JPY' -> ¥101
 */
export function formatPrice(val: number, currency: string = 'USD') {
  const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency });
  return priceFormatter.format(val);
}
