import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlanImageActionCreators, PlanImageSelectors } from './plan-image-slice';
import PlanImageModal from './plan-image-modal';

const PlanImageModalRenderer = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const open = useSelector(PlanImageSelectors.getOpen);

  if (!open) {
    return null;
  }

  return <PlanImageModal onClose={() => dispatch(PlanImageActionCreators.setModalOpen({ open: false }))} />;
};

export default PlanImageModalRenderer;
