import React, { ReactNode, createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Direction, InteractionStateType } from '@gi/constants';
import { CanvasSelectors, GardenCanvasContext } from '@gi/react-garden-canvas';
import { useAsRef } from '@gi/react-utils';

/** Minimum amount of movement before deciding if it's a touch-move. */
const MINIMUM_DISTANCE = 5;

/** Delay before a non-moving press is considered a long-press, and start a touch drag */
// const LONG_PRESS_TIMEOUT = 500;

/** Base PointerID to use for fake pointer events. Not strictly necessary, but reduces chance of
 *  clashes, and prevents touches with id 0 getting cancelled by pixi's phantom pointermove events. */
const POINTER_ID_BASE = 10000;

enum DragToDrawInteractionState {
  /** Events have started to be tracked, but no decision on intent has been reached */
  NONE,
  /** Events are being tracked, and it looks like the user is trying to drag */
  DRAG,
  /** Events are being tracked, but it looks like the user is touch-scrolling or dragging the wrong way */
  REJECTED,
  /** Events have finished being tracked. The user has released the initial pointer/touch */
  FINISHED,
}

/**
 * Converts a vector to a Direction enum
 * @param vector The direction vector to convert
 * @returns A direction, or null if vector has no length
 */
function getDirection(vector: Vector2): Direction | null {
  if (Math.abs(vector.x) >= Math.abs(vector.y)) {
    switch (Math.sign(vector.x)) {
      case -1:
        return Direction.LEFT;
      case 1:
        return Direction.RIGHT;
      default:
        return null;
    }
  } else {
    switch (Math.sign(vector.y)) {
      case -1:
        return Direction.UP;
      case 1:
        return Direction.DOWN;
      default:
        return null;
    }
  }
}

/**
 * Finds a touch in the TouchList that has the given identifier
 * @param touches The list of touches to search
 * @param id The touch ID to find
 * @returns The touch from the list, or null if not found
 */
function findTouchByIdentifier(touches: TouchList, id: number) {
  return [...touches].find((touch) => touch.identifier === id) ?? null;
}

/**
 * Creates a new PointerEvent, representing the given TouchEvent and Touch.
 * @param touchEvent The touch event to use
 * @param touch The touch from the TouchEvent to use
 * @param type The pointer event type to create
 * @returns A new PointerEvent, mimicking the data from the supplied Touch.
 */
function convertTouchToPointerEvent(touchEvent: TouchEvent, touch: Touch, type: 'pointerdown' | 'pointermove' | 'pointerup' | 'pointercancel') {
  const proxiedEvent = new Proxy(touchEvent, {
    get(_, property) {
      switch (property as keyof PointerEventInit) {
        case 'width':
          return touch.radiusX;
        case 'height':
          return touch.radiusY;
        case 'isPrimary':
          return true;
        case 'pointerId':
          return POINTER_ID_BASE + touch.identifier;
        case 'pointerType':
          return 'touch'; // Could be pencil/styles, but the touch API doesn't tell us?
        case 'pressure':
          return touch.force;
        case 'clientX':
          return touch.clientX;
        case 'clientY':
          return touch.clientY;
        case 'button':
          return 0;
        case 'buttons':
          return 1;
        case 'screenX':
          return touch.screenX;
        case 'screenY':
          return touch.screenY;
        default:
          return touchEvent[property];
      }
    },
  });
  const event = new PointerEvent(type, proxiedEvent);
  return event;
}

type DragToDrawContextType = {
  isDragToDraw: boolean;
  startMonitoringPointer: (target: HTMLElement, event: PointerEvent, onDragStart: (event: PointerEvent) => void) => void;
  startMonitoringTouch: (target: HTMLElement, event: TouchEvent, onDragStart: (event: PointerEvent) => void) => void;
};

export const DragToDrawContext = createContext<DragToDrawContextType>({} as DragToDrawContextType);

interface iProps {
  children: ReactNode;
}

/**
 * Handles drag-to-draw functionality within the drawing tools.
 *
 * README:
 *  This was done as a context, rather than on each button, to reduce the change of things getting
 *  stuck. In the event a button somehow gets stuck in a drag-to-draw, it will be automatically
 *  fixed the next time a user goes to click/drag another button.
 *
 * Known issue:
 *  If Pixi detects the browser as not supporting touch events on start-up, drag-to-draw on touch
 *   will get cancelled. I believe this is because Pixi fires a ghost 'pointermove' event the next
 *   frame after no pointer movement. This 'pointermove' event has no button/buttons on it, so our
 *   interaction system detects it as a 'pointerup' and ends the interaction.
 *  To counter this, I've added an offset to the fake pointer events pointerId property, preventing
 *   it ever clashing with pointerId 0, which is used by the ghost pointerMove event.
 */
export const DragToDrawProvider = ({ children }: iProps): JSX.Element => {
  const interactionState = useSelector(CanvasSelectors.getInteractionState);
  const { gardenCanvas } = useContext(GardenCanvasContext);

  /** Store a clean-up function for removing listeners and resetting anything else */
  const cleanUp = useRef<(() => void) | null>(null);

  /** Returns the canvas element from the GardenCanvas */
  const getCanvas = useAsRef(() => {
    if (!gardenCanvas) {
      return null;
    }
    return gardenCanvas.engine.canvas;
  }, [gardenCanvas]);

  /**
   * Starts monitoring the given PointerEvent to look for drags.
   * Will call onDragStart when a drag is detected.
   */
  const startMonitoringPointer = useCallback((target: HTMLElement, startEvent: PointerEvent, onDragStart: (event: PointerEvent) => void) => {
    // Most other pointer types also fire touch events, which we prioritise, so reject non-mouse here
    if (startEvent.pointerType !== 'mouse' || startEvent.button !== 0) {
      return;
    }

    cleanUp.current?.();

    const canvas = getCanvas.current();
    if (!canvas) {
      return;
    }

    let state: DragToDrawInteractionState = DragToDrawInteractionState.NONE;

    const onPointerMove = (event: PointerEvent) => {
      if (event.pointerId !== startEvent.pointerId) {
        return;
      }

      if (state === DragToDrawInteractionState.REJECTED) {
        return;
      }

      if (state === DragToDrawInteractionState.NONE) {
        // Check if we've moved enough to start. As this is a mouse event, allow dragging in all directions
        const dragOffsetX = event.clientX - startEvent.clientX;
        const dragOffsetY = event.clientY - startEvent.clientY;

        if (Math.abs(dragOffsetX) > MINIMUM_DISTANCE || Math.abs(dragOffsetY) > MINIMUM_DISTANCE) {
          state = DragToDrawInteractionState.DRAG;
          onDragStart(startEvent);
          // Setting pointer-events to none prevents the native "click" event from being fired at the end.
          target.style.pointerEvents = 'none';

          // Re-dispatch this event onto the canvas so that it has the most up-to-date pointer position
          canvas.dispatchEvent(new PointerEvent('pointermove', event));
          canvas.setPointerCapture(event.pointerId); // pointercapture means we don't have to manually forward the events to the canvas
        }
      } else if (state === DragToDrawInteractionState.DRAG) {
        // Pointer capture should be forwarding the event to the canvas already.
        // We could check hasPointerCapture, and manually forward the event if not, but we should be fine.

        // Check for missed pointerup event
        // eslint-disable-next-line no-bitwise
        if ((event.buttons & 1) !== 1) {
          // Button has been released, abort
          state = DragToDrawInteractionState.FINISHED;
          cleanUp.current?.();
          cleanUp.current = null;
        }
      }
    };

    const onPointerUp = (event: PointerEvent) => {
      if (event.pointerId !== startEvent.pointerId || event.button !== 0) {
        return;
      }

      state = DragToDrawInteractionState.FINISHED;
      cleanUp.current?.();
      cleanUp.current = null;
    };

    const onContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    window.addEventListener('pointermove', onPointerMove);
    // pointerup/cancel probably won't work during the drag, as the canvas has pointercapture, but just-in-case
    window.addEventListener('pointerup', onPointerUp);
    window.addEventListener('pointercancel', onPointerUp);
    // We capture the cursor on the canvas when we start dragging. Use the capturelost event to signal the end of the drag and clean up.
    canvas.addEventListener('lostpointercapture', onPointerUp);
    // Prevent context menus from interfering by accident
    window.addEventListener('contextmenu', onContextMenu);

    cleanUp.current = () => {
      window.removeEventListener('pointermove', onPointerMove);
      window.removeEventListener('pointerup', onPointerUp);
      window.removeEventListener('pointercancel', onPointerUp);
      canvas.removeEventListener('lostpointercapture', onPointerUp);
      window.removeEventListener('contextmenu', onContextMenu);
      target.style.removeProperty('pointer-events');
    };
  }, []);

  /**
   * Starts monitoring the given TouchEvent to look for drags.
   * We can't just use PointerEvents unfortunately, as PointerEvents stop firing when a browser
   *  starts doing a built-in behaviour, such as touch-scrolling.
   * If we want to maintain touch-scrolling support, we have to use the touch API, as this continues to fire.
   */
  const startMonitoringTouch = useCallback((target: HTMLElement, startEvent: TouchEvent, onDragStart: (event: PointerEvent) => void) => {
    cleanUp.current?.();

    const startTouch = startEvent.changedTouches[0];
    let state: DragToDrawInteractionState = DragToDrawInteractionState.NONE;

    const onTouchMove = (event: TouchEvent) => {
      const targetTouch = findTouchByIdentifier(event.changedTouches, startTouch.identifier);
      if (targetTouch === null) {
        return;
      }

      if (state === DragToDrawInteractionState.REJECTED) {
        return;
      }

      if (state === DragToDrawInteractionState.NONE) {
        // Check if we've moved enough to start. Make sure we're also dragging towards the canvas (right)
        const dragOffsetX = targetTouch.clientX - startTouch.clientX;
        const dragOffsetY = targetTouch.clientY - startTouch.clientY;

        if (Math.abs(dragOffsetX) > MINIMUM_DISTANCE || Math.abs(dragOffsetY) > MINIMUM_DISTANCE) {
          const direction = getDirection({ x: dragOffsetX, y: dragOffsetY });
          if (direction === Direction.RIGHT) {
            // Dragging in right direction, start duplicating events to the canvas
            state = DragToDrawInteractionState.DRAG;
            onDragStart(convertTouchToPointerEvent(startEvent, startTouch, 'pointerdown'));

            if (event.cancelable) {
              event.preventDefault();
            }

            const canvas = getCanvas.current();
            if (!canvas) {
              return;
            }
            canvas.dispatchEvent(convertTouchToPointerEvent(event, targetTouch, 'pointermove'));
          } else {
            // Dragging in wrong direction, ignore and fail
            state = DragToDrawInteractionState.REJECTED;
          }
        }
      } else if (state === DragToDrawInteractionState.DRAG) {
        if (event.cancelable) {
          event.preventDefault();
        }

        // Re-dispatch this event onto the canvas so that it has the most up-to-date info
        const canvas = getCanvas.current();
        if (!canvas) {
          return;
        }
        canvas.dispatchEvent(convertTouchToPointerEvent(event, targetTouch, 'pointermove'));
      }
    };

    const onTouchEnd = (event: TouchEvent) => {
      const targetTouch = findTouchByIdentifier(event.changedTouches, startTouch.identifier);
      if (targetTouch === null) {
        return;
      }

      if (state === DragToDrawInteractionState.DRAG) {
        if (event.cancelable) {
          event.preventDefault();
        }

        const canvas = getCanvas.current();
        if (canvas) {
          canvas.dispatchEvent(convertTouchToPointerEvent(event, targetTouch, 'pointerup'));
        }
      }

      if (state !== DragToDrawInteractionState.FINISHED) {
        state = DragToDrawInteractionState.FINISHED;
        cleanUp.current?.();
        cleanUp.current = null;
      }
    };

    target.addEventListener('touchmove', onTouchMove);
    target.addEventListener('touchend', onTouchEnd);
    target.addEventListener('touchcancel', onTouchEnd);

    cleanUp.current = () => {
      target.removeEventListener('touchmove', onTouchMove);
      target.removeEventListener('touchend', onTouchEnd);
      target.removeEventListener('touchcancel', onTouchEnd);
    };
  }, []);

  const isDragToDraw = useMemo(() => {
    return interactionState.type === InteractionStateType.ITEM_DRAW && !!interactionState.isDragToDraw;
  }, [interactionState]);

  const value = useMemo<DragToDrawContextType>(
    () => ({
      isDragToDraw,
      startMonitoringPointer,
      startMonitoringTouch,
    }),
    [isDragToDraw, startMonitoringPointer, startMonitoringTouch]
  );

  return <DragToDrawContext.Provider value={value}>{children}</DragToDrawContext.Provider>;
};
