import { RefCallback, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ResizeObserverPolyfill from '@gi/resize-observer-ponyfill';

interface OverflowDetectorOptions {
  /** How close to each end is considered the end (in px). A few px leeway prevents false-positives. */
  tolerance: number;
  /** Will pause the overflow detector. Use if the thing being detected on isn't currently showing. */
  paused?: boolean;
  /** Watches for changes in children and recalculates overflow. Likely expensive. Only use for overflows with regularly-changing children */
  watchChildren?: boolean;
}

type OverflowDirections = {
  top: boolean;
  bottom: boolean;
  left: boolean;
  right: boolean;
};

/**
 * Detects if there's overflow in any direction of the element passed to the ref.
 * Example: If the element has a vertical scrollbar, and is scrolled half-way down, both top and bottom will be true.
 */
const useOverflowDetector = <T extends HTMLElement>(options: OverflowDetectorOptions): { ref: RefCallback<T>; overflows: OverflowDirections } => {
  // Reference to the element to detect scroll on.
  const elementRef = useRef<T | null>(null);
  // Store the options in a ref so we don't have to re-define the functions whenever an option changes.
  const optionsRef = useRef<OverflowDetectorOptions>(options);
  // Store if we skip a recalculation, this way we can skip doing it when we unpause if unnecessary
  const needsRecalc = useRef<boolean>(false);
  // Output
  const [overflowLeft, setOverflowLeft] = useState(false);
  const [overflowRight, setOverflowRight] = useState(false);
  const [overflowTop, setOverflowTop] = useState(false);
  const [overflowBottom, setOverflowBottom] = useState(false);

  // Recalculates if the scroll box is overflowing at all
  const recalculateOverflows = useCallback(() => {
    const element = elementRef.current;
    const opts = optionsRef.current;
    if (!element || opts.paused) {
      needsRecalc.current = true;
      return;
    }

    const maxScrollWidth = Math.max(element.scrollWidth - element.clientWidth);
    const maxScrollHeight = Math.max(element.scrollHeight - element.clientHeight);
    const xScrollAmount = element.scrollLeft;
    const yScrollAmount = element.scrollTop;

    setOverflowTop(yScrollAmount > opts.tolerance);
    setOverflowBottom(yScrollAmount < maxScrollHeight - options.tolerance);
    setOverflowLeft(xScrollAmount > opts.tolerance);
    setOverflowRight(xScrollAmount < maxScrollWidth - options.tolerance);
    needsRecalc.current = false;
  }, []);

  // Use a single ResizeObserver, no need to keep re-creating this in case on memory leaks.
  const resizeObserverRef = useRef<ResizeObserver>(new ResizeObserverPolyfill(recalculateOverflows));
  const mutationObserverRef = useRef<MutationObserver>(new MutationObserver(recalculateOverflows));

  // Updates the resizeObserver + mutationObserver to watch the latest version of the element
  const updateObservers = useCallback(() => {
    const resizeObserver = resizeObserverRef.current;
    const mutationObserver = mutationObserverRef.current;

    resizeObserver.disconnect();
    mutationObserver.disconnect();

    const element = elementRef.current;
    if (element) {
      resizeObserver.observe(element);
      if (optionsRef.current.watchChildren) {
        mutationObserver.observe(element, { childList: true, subtree: true });
      }
    }
  }, []);

  // Recalculate the overflows if any of the options change.
  useEffect(() => {
    const oldOptions = optionsRef.current;
    optionsRef.current = options;

    if (!options.paused && needsRecalc.current) {
      if (oldOptions.tolerance !== options.tolerance || oldOptions.paused !== options.paused) {
        recalculateOverflows();
      }
    }

    if (options.watchChildren !== oldOptions.watchChildren) {
      updateObservers();
    }
  }, [options]);

  // Fake ref, so we can run things when the ref is set/changed
  const ref = useCallback((element: T | null) => {
    if (elementRef.current !== element) {
      if (elementRef.current) {
        elementRef.current.removeEventListener('scroll', recalculateOverflows);
      }
      elementRef.current = element;
      elementRef.current?.addEventListener('scroll', recalculateOverflows);
      updateObservers();
    }
  }, []);

  // Only re-generate the overflows object when one changes, to prevent re-renders
  const overflows = useMemo(() => {
    return {
      top: overflowTop,
      left: overflowLeft,
      bottom: overflowBottom,
      right: overflowRight,
    };
  }, [overflowTop, overflowLeft, overflowBottom, overflowRight]);

  return { ref, overflows };
};

export default useOverflowDetector;
