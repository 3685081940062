import React, { useCallback } from 'react';

import FormField, { FORM_FIELD_PRESETS } from '@gi/form-responsive';
import LengthInput, { DegreesInput, VectorInput } from '@gi/length-input';

import { EditShapeModalFormProps, ShapeState } from '../../types';

/**
 * Displays position/size inputs for a rectangle-like shape (like a rectangle or ellipse).
 * Includes center, width/height and rotation
 */
const PositionPropertiesFormSection = ({ values, setValues, distanceUnits }: EditShapeModalFormProps): JSX.Element => {
  const createSetter = useCallback(
    <K extends keyof ShapeState>(setting: K) =>
      (value: ShapeState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  return (
    <>
      <FormField label='Center' htmlFor='edit-shape:center-x' layoutPreset={FORM_FIELD_PRESETS.EditModalVector}>
        <VectorInput value={values.values.center} onChange={createSetter('center')} distanceUnits={distanceUnits} id='edit-shape:center' />
      </FormField>
      <FormField label='Width' htmlFor='edit-shape:width' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <LengthInput value={values.values.width} onChange={createSetter('width')} distanceUnits={distanceUnits} id='edit-shape:width' />
      </FormField>
      <FormField label='Height' htmlFor='edit-shape:height' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <LengthInput value={values.values.height} onChange={createSetter('height')} distanceUnits={distanceUnits} id='edit-shape:height' />
      </FormField>
      <FormField label='Rotation' htmlFor='edit-shape:rotation' layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <DegreesInput value={values.values.rotation} onChange={createSetter('rotation')} id='edit-shape:rotation' />
      </FormField>
    </>
  );
};

export default PositionPropertiesFormSection;
