import React from 'react';
import ForecastDay from './forecast-day';

import './forecast-display.scss';
import Forecast from '../forecast';

interface iProps {
  forecasts: (Forecast | null)[];
  metric: boolean;
}

const ForecastDisplay = ({ forecasts, metric }: iProps): JSX.Element => {
  return (
    <div className='forecast-display'>
      {forecasts.map((forecast, i) => (
        <ForecastDay key={forecast === null ? i : forecast.datetime.toISOString()} metric={metric} forecast={forecast} />
      ))}
    </div>
  );
};

export default ForecastDisplay;
