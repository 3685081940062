import React, { useMemo } from 'react';
import ReactSelect from 'react-select';

import FormField, { FormFieldOptionsPreset, FormLayout, FormLayoutOptionsPreset, InputContainer } from '@gi/form-responsive';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import './season-extender-settings-row.scss';

const SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    minWidth: 'unset',
  }),
};

const weekOptions = [
  { label: '0 Weeks', value: 0 },
  { label: '1 Week', value: 1 },
  { label: '2 Weeks', value: 2 },
  { label: '3 Weeks', value: 3 },
  { label: '4 Weeks', value: 4 },
  { label: '5 Weeks', value: 5 },
  { label: '6 Weeks', value: 6 },
  { label: '7 Weeks', value: 7 },
  { label: '8 Weeks', value: 8 },
  { label: '9 Weeks', value: 9 },
  { label: '10 Weeks', value: 10 },
  { label: '11 Weeks', value: 11 },
  { label: '12 Weeks', value: 12 },
];

// Layout for the main row, containing the left bold text
const FIELD_LAYOUT: FormFieldOptionsPreset = {
  desktop: {
    labelSize: 190,
    labelAlignX: 'right',
    labelAlignY: 'center',
    inputSize: 'full',
  },
};

const SUBFIELD_LAYOUT: FormFieldOptionsPreset = {
  desktop: {
    labelSize: 250,
    inputSize: 140,
  },
  mobile: {
    inputSize: 'full',
  },
};

const FIELD_CONTENT_LAYOUT: FormLayoutOptionsPreset = {
  desktop: {
    layout: 'column',
    xAlign: 'right',
    size: 'full',
    gap: 3,
  },
  mobile: {
    layout: 'column',
    xAlign: 'stretch',
    size: 'full',
    gap: 3,
  },
};

const getWeekOptionValue = (value: number) => {
  for (let i = 0; i < weekOptions.length; i++) {
    if (weekOptions[i].value === value) {
      return weekOptions[i];
    }
  }

  return { value, label: `${value} Weeks` };
};

interface iProps {
  title: string;
  lastExtenderValue: number;
  onLastExtenderValueChange: (value: number) => void;
  firstExtenderValue: number;
  onFirstExtenderValueChange: (value: number) => void;
}

const SeasonExtenderSettingsRow = ({
  title,
  lastExtenderValue,
  onLastExtenderValueChange,
  firstExtenderValue,
  onFirstExtenderValueChange,
}: iProps): JSX.Element => {
  const ids = useMemo(() => {
    const titleToId = title
      .toLowerCase()
      .replaceAll(/[/\s]/g, '-')
      .replaceAll(/[^a-z-]/g, '');
    return {
      lastFrostId: `season-ext-settings:${titleToId}-last`,
      firstFrostId: `season-ext-settings:${titleToId}-first`,
    };
  }, [title]);

  return (
    <FormField label={<span className='title'>{title}</span>} fakeLabel layoutPreset={FIELD_LAYOUT} className='season-extender-setting'>
      <FormLayout layoutPreset={FIELD_CONTENT_LAYOUT}>
        <FormField label='Brings effective last frost date earlier by' htmlFor={ids.lastFrostId} layoutPreset={SUBFIELD_LAYOUT}>
          <InputContainer size='full'>
            <ReactSelect
              styles={SELECT_STYLES}
              options={weekOptions}
              value={getWeekOptionValue(lastExtenderValue)}
              onChange={(opt) => {
                if (opt) {
                  onLastExtenderValueChange(opt.value);
                }
              }}
              menuPortalTarget={document.body}
              isSearchable={false}
              inputId={ids.lastFrostId}
            />
          </InputContainer>
        </FormField>
        <FormField label='Brings effective first frost date later by' htmlFor={ids.firstFrostId} layoutPreset={SUBFIELD_LAYOUT}>
          <InputContainer size='full'>
            <ReactSelect
              styles={SELECT_STYLES}
              options={weekOptions}
              value={getWeekOptionValue(firstExtenderValue)}
              onChange={(opt) => {
                if (opt) {
                  onFirstExtenderValueChange(opt.value);
                }
              }}
              menuPortalTarget={document.body}
              isSearchable={false}
              inputId={ids.firstFrostId}
            />
          </InputContainer>
        </FormField>
      </FormLayout>
    </FormField>
  );
};

export default SeasonExtenderSettingsRow;
