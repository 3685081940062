import React, { useState, useMemo, useEffect, ReactNode, useCallback } from 'react';

import Plant from '@gi/plant';
import GardenObject, { GardenObjectGroup } from '@gi/garden-object';
import Collection from '@gi/collection';
import { ModifierTypeList } from '@gi/constants/types/modifier-type';
import { User, UserUtils } from '@gi/user';
import { CountryCollection, getCountryData } from '@gi/country';
import { Catalogue, Suppliers, SupplierCatalogueUtils } from '@gi/supplier';
import { imperialDistanceUnits, metricDistanceUnits } from '@gi/units';
import { PlantSearchService, GardenObjectSearchService, PestSearchService } from '@gi/search-service';
import { Store } from '@gi/products';
import {
  calculateUserPlantingCalendars,
  createGetUserPlantingCalendar,
  updateUserPlantingCalendars,
  UserPlantingCalendars,
  UserRegionalPlantingCalendar,
} from '@gi/planting-calendar';
import { Pest } from '@gi/pest';
import { GuruPages } from '@gi/app-guru-types';

import ResourceContext, { ResourceContextType } from './resource-context';
import getUserCountry from './utils/get-user-country';

interface iProps {
  user: User;
  userArtifactCode: string;
  children: ReactNode;
  pests: Collection<Pest>;
  plants: Collection<Plant>;
  gardenObjects: Collection<GardenObject>;
  gardenObjectGroups: Collection<GardenObjectGroup>;
  countries: CountryCollection;
  regionalPlantingCalendar: UserRegionalPlantingCalendar | null;
  catalogue: Catalogue;
  suppliers: Suppliers;
  guruPages: GuruPages | null;
  store: Store | null;
}

/**
 * The ResourceProvider is the container for all static data required by the garden planner which is loaded upfront
 * - Plants
 * - Garden Objects
 * - Regional Planting Data
 * - Plant Catalogue and Suppliers
 * - Countries
 *
 * It also provides access to some generic user-specific values
 * - User Country
 * - artifactCode
 */
const ResourceProvider = ({
  user,
  userArtifactCode,
  children,
  pests,
  plants,
  gardenObjects,
  gardenObjectGroups,
  countries,
  regionalPlantingCalendar,
  catalogue,
  suppliers,
  guruPages,
  store,
}: iProps) => {
  const { useRegionalPlanting } = getCountryData(user.countryCode);
  const [userPlantingCalendars, setUserPlantingCalendars] = useState<null | UserPlantingCalendars>(null);

  const userPlantVarieties = useMemo(() => user.plantVarieties.userPlantVarieties, [user]);

  /**
   * Search service for plants
   */
  const plantSearchService = useMemo(() => {
    return new PlantSearchService(plants.itemList);
  }, [plants]);

  /**
   * Search service for garden objects
   */
  const gardenObjectSearchService = useMemo(() => {
    return new GardenObjectSearchService(gardenObjects.itemList);
  }, [gardenObjects]);

  /**
   * Search service for pests
   */
  const pestSearchService = useMemo(() => {
    return new PestSearchService(pests.itemList);
  }, [pests]);

  /**
   * Gets the user's country based on their country code
   */
  const userCountry = useMemo(() => {
    return getUserCountry(user.countryCode, countries);
  }, [user.countryCode, countries]);

  /**
   * Creates the supplier-specific catalogue
   */
  const supplierCatalogue = useMemo(() => {
    return SupplierCatalogueUtils.create(catalogue, suppliers, user.countryCode);
  }, [catalogue, suppliers, user.countryCode]);

  /**
   * Finds a plant from its unique code
   */
  const getPlant = useCallback(
    (plantCode: string) => {
      return plants.get(plantCode);
    },
    [plants]
  );

  /**
   * Finds a garden object from its unique code
   */
  const getGardenObject = useCallback(
    (code: string) => {
      return gardenObjects.get(code);
    },
    [gardenObjects]
  );

  /**
   * Finds a garden object group from its unique id
   */
  const getGardenObjectGroup = useCallback(
    (code: string) => {
      return gardenObjectGroups.get(code);
    },
    [gardenObjectGroups]
  );

  /**
   * Finds a pest from its unique code
   */
  const getPest = useCallback(
    (code: string) => {
      return pests.get(code);
    },
    [pests]
  );

  /**
   * Update user planting calendars when properties which it depends on change
   */
  useEffect(() => {
    if (useRegionalPlanting && regionalPlantingCalendar === null) {
      return;
    }

    const _userPlantingCalendars = calculateUserPlantingCalendars(
      useRegionalPlanting,
      regionalPlantingCalendar,
      ModifierTypeList,
      plants.asArray(),
      user.settings.seasonExtenders,
      user.settings.frostDates.last,
      user.settings.frostDates.first,
      user.settings.location.splitSeason,
      user.plantVarieties.userPlantVarieties
    );

    setUserPlantingCalendars(_userPlantingCalendars);
  }, [plants, useRegionalPlanting, regionalPlantingCalendar, user]);

  /**
   * Update user planting calendar when their varieties change
   */
  useEffect(() => {
    if (userPlantingCalendars !== null) {
      // User plant varieties have changed and we have already set out userPlantingCalendars, so we need to update the userPlantingCalendars with new values
      setUserPlantingCalendars(updateUserPlantingCalendars(userPlantingCalendars, userPlantVarieties));
    }
  }, [userPlantVarieties]);

  const value = useMemo<ResourceContextType>(
    () => ({
      // Async Resources
      userPests: pests,
      userPlants: plants,
      userGardenObjects: gardenObjects,
      userGardenObjectGroups: gardenObjectGroups,
      countries,
      userRegionalPlantingCalendar: regionalPlantingCalendar,
      catalogue,
      suppliers,
      // Generated Data
      supplierCatalogue,
      // Search services
      plantSearchService,
      gardenObjectSearchService,
      pestSearchService,
      // Data from the user object
      userCountry, // TODO: Make not-nullable?
      userArtifactCode,
      userPlantingCalendars,
      userDistanceUnits: user.settings.units.metric ? metricDistanceUnits : imperialDistanceUnits,
      userNorthernHemisphere: UserUtils.isNorthernHemisphere(user),
      useRegionalPlanting,
      // Additional functions
      getPlantingCalendar: createGetUserPlantingCalendar(userPlantingCalendars),
      getPlant,
      getPest,
      getGardenObject,
      getGardenObjectGroup,
      userTags: user.tags,
      // Guru specific route data
      guruPages,
      // Shopping Products
      store,
    }),
    [
      // Async Resources
      plants,
      gardenObjects,
      gardenObjectGroups,
      countries,
      regionalPlantingCalendar,
      catalogue,
      suppliers,
      // Generated Data
      supplierCatalogue,
      // Search services
      plantSearchService,
      gardenObjectSearchService,
      pestSearchService,
      // Data from the user object
      user,
      userCountry,
      userArtifactCode,
      userPlantingCalendars,
      useRegionalPlanting,
      // Additional functions
      getPlant,
      getGardenObject,
      getGardenObjectGroup,
      getPest,
      guruPages,
      store,
    ]
  );

  return <ResourceContext.Provider value={value}>{children}</ResourceContext.Provider>;
};

export default ResourceProvider;
