import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Plant from '@gi/plant';
import { PlantShoppingEntry } from '@gi/products';

import { usePlantProducts, useProduct } from '../../hooks';

import ListItem from '../components/list-item';
import ListItemProduct from '../components/list-item-product';
import PlantTitleRow from './plant-title-row';
import { ShoppingActionCreators } from '../../slice/shopping-slice';

interface iProps {
  currency: string;
  planId: number;
  entry: PlantShoppingEntry;
  plant: Plant;
}

const PlantEntryDisplay = ({ currency, planId, entry, plant }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const product = useProduct(entry.productId);
  const products = usePlantProducts(plant.code);

  const setEntry = useCallback(
    (_entry: PlantShoppingEntry) => {
      dispatch(ShoppingActionCreators.setEntry({ planId, entry: _entry }));
    },
    [planId]
  );

  if (!product) {
    return null;
  }

  const variant = product.variants[entry.variantId];

  if (!variant) {
    return null;
  }

  return (
    <ListItem>
      <PlantTitleRow planId={planId} uuid={entry.uuid} />
      <ListItemProduct currency={currency} variant={variant} product={product} entry={entry} setEntry={setEntry} productOptions={products} />
    </ListItem>
  );
};

export default PlantEntryDisplay;
