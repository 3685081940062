import React, { Context, ReactNode, createContext, useMemo } from 'react';
import { EventsSystem } from './events-system';

type EventsContextType<TKey extends string, TParams extends Record<string, Record<string, any>>> = {
  eventsSystem: EventsSystem<TKey, TParams>;
};

export function createEventsContext<TKey extends string, TParams extends Record<string, Record<string, any>>>() {
  return createContext<EventsContextType<TKey, TParams>>({} as EventsContextType<TKey, TParams>);
}

export function createEventsProvider<TKey extends string, TParams extends Record<string, Record<string, any>>>(
  context: Context<EventsContextType<TKey, TParams>>,
  displayName: string = 'EventsProvider'
) {
  interface iProps {
    eventsSystem: EventsSystem<TKey, TParams>;
    children: ReactNode;
  }

  const EventsProvider = ({ eventsSystem, children }: iProps): JSX.Element => {
    const value = useMemo(
      () => ({
        eventsSystem,
      }),
      [eventsSystem]
    );

    return <context.Provider value={value}>{children}</context.Provider>;
  };
  EventsProvider.displayName = displayName;

  return EventsProvider;
}
