type LogMessage = {
  userID: number;
  clientTime: string;
  level: number;
  category: string;
  message: string;
};

export function createLogMessage(message: string, category: string, userId: number, level: number = 0): LogMessage {
  return {
    userID: userId,
    clientTime: new Date().toLocaleString(),
    level,
    category,
    message,
  };
}
