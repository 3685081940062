import { Plan } from './plan';
import { removePlanIdFromHistory } from './plan-utils';

export type PlanSet = {
  planIds: number[];
  plans: Record<number, Plan>;
};

export function planSetHasPlan(planSet: PlanSet, planId: number): boolean {
  return planSet.planIds.includes(planId);
}

export function planSetGetPlan(planSet: PlanSet, planId: number): Plan | null {
  return planSetHasPlan(planSet, planId) ? planSet.plans[planId] : null;
}

export function planSetAddPlan(planSet: PlanSet, plan: Plan): PlanSet {
  if (planSetHasPlan(planSet, plan.id)) {
    console.error(`Attempted to add plan to PlanSet which already has a plan with the id ${plan.id}`);
    return planSet;
  }

  return {
    ...planSet,
    planIds: [...planSet.planIds, plan.id],
    plans: {
      ...planSet.plans,
      [plan.id]: plan,
    },
  };
}

export function planSetUpdatePlan(planSet: PlanSet, plan: Plan): PlanSet {
  if (!planSetHasPlan(planSet, plan.id)) {
    console.warn("Attempted to update plan in plan set which wasn't present, adding plan to set instead");
    return planSetAddPlan(planSet, plan);
  }

  return {
    ...planSet,
    plans: {
      ...planSet.plans,
      [plan.id]: plan,
    },
  };
}

export function planSetRemovePlan(planSet: PlanSet, planId: number): PlanSet {
  if (!planSetHasPlan(planSet, planId)) {
    console.error(`Attempted to remove plan from PlanSet which doesn't have a plan with the id ${planId}`);
    return planSet;
  }

  const newPlanSet = {
    ...planSet,
  };

  const planIds = newPlanSet.planIds.filter((id) => id !== planId);
  const plans = { ...planSet.plans };
  delete plans[planId];

  return {
    planIds,
    plans,
  };
}

export function createPlanSet(initialPlans: Plan[] = []): PlanSet {
  return initialPlans.reduce<PlanSet>((prev, curr) => planSetAddPlan(prev, curr), {
    planIds: [],
    plans: {},
  });
}

export function planSetRemovePlanFromHistory(planSet: PlanSet, historyPlanId: number): PlanSet {
  const updatedPlans = {
    ...planSet.plans,
  };

  planSet.planIds.forEach((planId) => {
    if (updatedPlans[planId].history.includes(historyPlanId)) {
      updatedPlans[planId] = removePlanIdFromHistory(updatedPlans[planId], historyPlanId);
    }
  });

  return {
    ...planSet,
    plans: updatedPlans,
  };
}

/**
 * Returns an object containing added, removed and updated plan IDs when comparing the provided plan sets
 *
 * If a plan is not present in the first plan set, but is in the second, it is 'added'
 * If a plan is present in the first plan set, but is not in the second, it is 'removed'
 * If a plan is present in both plan sets but its not equal, it is 'updated'
 */
export function getPlanSetDiff(curr: PlanSet, comp: PlanSet) {
  const addedPlans: Plan[] = [];
  const removedPlans: Plan[] = [];
  const updatedPlans: Plan[] = [];

  for (let i = 0; i < curr.planIds.length; i++) {
    const id = curr.planIds[i];
    if (id === undefined) {
      break;
    }
    const plan = planSetGetPlan(curr, id);
    if (plan === null) {
      break;
    }

    if (!planSetHasPlan(comp, id)) {
      // Previous plan list did not have this plan
      addedPlans.push(plan);
    } else {
      const prevPlan = planSetGetPlan(comp, id);
      if (prevPlan !== plan) {
        // Plan has been updated
        updatedPlans.push(plan);
      }
    }
  }

  for (let i = 0; i < comp.planIds.length; i++) {
    const id = comp.planIds[i];

    const plan = planSetGetPlan(comp, id);
    if (plan === null) {
      break;
    }

    if (!planSetHasPlan(curr, id)) {
      // Current plan list does not have this plan
      removedPlans.push(plan);
    }
  }

  return {
    added: addedPlans,
    updated: updatedPlans,
    removed: removedPlans,
  };
}
