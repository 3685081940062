import React from 'react';
import { PlannerTabTypes } from '@gi/constants';
import Plan from '@gi/plan';
import { FadedScrollContainer } from '@gi/mobile-components';

import DesktopPlanTitle from './desktop-planner-controls/desktop-plan-title/desktop-plan-title';
import DesktopPlannerTabs from './desktop-planner-controls/desktop-planner-tabs/desktop-planner-tabs';
import DesktopPlanActions from './desktop-planner-controls/desktop-plan-actions/desktop-plan-actions';
import DesktopMenu from './desktop-planner-controls/desktop-menu/desktop-menu';

import styles from './app-planner-desktop.module.css';

interface iProps {
  activePlannerTab: PlannerTabTypes;
  activePlan: Plan | null;
  activePlanId: number | null;
}

const DesktopPlannerControlBar = ({ activePlannerTab, activePlan, activePlanId }: iProps): JSX.Element => {
  return (
    <FadedScrollContainer isHorizontal hideScrollbars fadeColour='#fafafa' watchChildren>
      <div className={styles.plannerControlBar}>
        <div className={styles.topBar}>
          <div className={styles.topBarInner}>
            <DesktopPlanTitle activePlanId={activePlanId} />
            <DesktopPlanActions />
            <DesktopPlannerTabs activePlannerTab={activePlannerTab} activePlan={activePlan} />
          </div>
        </div>
        <div className={styles.bottomBar}>
          <DesktopMenu activePlannerTab={activePlannerTab} />
        </div>
      </div>
    </FadedScrollContainer>
  );
};

export default DesktopPlannerControlBar;
