import PropTypes from 'prop-types';

export type Paper = {
  width: number;
  height: number;
  name: string;
  code: string;
};

export const PaperShape = PropTypes.shape({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
});

export const A4: Paper = {
  width: 210, // Width in mm
  height: 297, // Height in mm
  name: 'A4',
  code: 'A4',
};

export const A3: Paper = {
  width: 297, // Width in mm
  height: 420, // Height in mm
  name: 'A3',
  code: 'A3',
};

export const A2: Paper = {
  width: 420, // Width in mm
  height: 594, // Height in mm
  name: 'A2',
  code: 'A2',
};

export const A1: Paper = {
  width: 594, // Width in mm
  height: 841, // Height in mm
  name: 'A1',
  code: 'A1',
};

export const A0: Paper = {
  width: 841, // Width in mm
  height: 1189, // Height in mm
  name: 'A0',
  code: 'A0',
};

export const Letter: Paper = {
  width: 216, // Width in mm (Actually 215.9)
  height: 279, // Height in mm (Actually 279.4)
  name: 'Letter',
  code: 'LETTER',
};

export const GovernmentLetter: Paper = {
  width: 216, // Width in mm (Actually 215.9)
  height: 279, // Height in mm (Actually 279.4)
  name: 'Government Letter',
  code: 'GOVERNMENT_LETTER',
};

export const Legal: Paper = {
  width: 216,
  height: 356,
  name: 'Legal',
  code: 'LEGAL',
};

export const JuniorLegal: Paper = {
  width: 127,
  height: 203,
  name: 'Junior Legal',
  code: 'JUNIOR_LEGAL',
};
