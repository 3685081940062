import { TemplatePlanFromAPI, TemplatePlan } from './template-plan';

export function templatePlanFromAPI(templateAPIObject: TemplatePlanFromAPI): TemplatePlan {
  return {
    planID: templateAPIObject.planID,
    planName: templateAPIObject.planName,
    planDescription: templateAPIObject.planDescription,
    planIconURL: templateAPIObject.planIconURL,
    planPreviewURL: templateAPIObject.planPreviewURL,
    displayIndex: templateAPIObject.displayIndex,
    planDocument: templateAPIObject.planDocument,
    templateID: templateAPIObject.templateID,
  };
}
