import { Store } from 'redux';

import { CanvasInteractionGroup, GardenCanvasEvent } from '@gi/plan-simulation';

import { updateSelectedNodes } from '../redux-components/garden-canvas-event-action-creators';
import GardenCanvasController, { GardenCanvasControllerEvent } from '../garden-canvas-controller/garden-canvas-controller';

const setupSelectedNodesEventHandler = (store: Store, gardenCanvasController: GardenCanvasController) => {
  let { gardenCanvas } = gardenCanvasController.getInstance();

  const onSelectedNodesUpdate = (selection: CanvasInteractionGroup) => {
    store.dispatch(updateSelectedNodes(selection.serialise()));
  };

  const setup = () => {
    // Subscribe to changes
    if (gardenCanvas) {
      gardenCanvas.on(GardenCanvasEvent.SELECTION_CHANGE, onSelectedNodesUpdate);
    }
  };

  const teardown = () => {
    // Unsubscribe from changes
    if (gardenCanvas) {
      gardenCanvas.off(GardenCanvasEvent.SELECTION_CHANGE, onSelectedNodesUpdate);
    }
  };

  const onGardenCanvasControllerStateUpdate = () => {
    if (gardenCanvas !== null) {
      teardown();
    }

    // See: https://hacks.mozilla.org/2015/05/es6-in-depth-destructuring/ for why the parenthese are needed
    ({ gardenCanvas } = gardenCanvasController.getInstance());

    if (gardenCanvas !== null) {
      setup();
    }
  };

  // Note: we cannot remove this event as we don't have an easy way to have a reference to the event callback
  // once this setup function exits, so we must make sure `gardenCanvasController.destroy()` is called to remove
  // all listeners
  gardenCanvasController.on(GardenCanvasControllerEvent.STATE_CHANGE, onGardenCanvasControllerStateUpdate);

  if (gardenCanvas !== null && gardenCanvas.activePlanId !== null) {
    // Set initial values, this will probably never be called as garden cavnas will likely be null when this store listener is added
    const activePlan = gardenCanvas.canvasPlans.getCanvasPlan(gardenCanvas.activePlanId);
    if (activePlan) {
      onSelectedNodesUpdate(new CanvasInteractionGroup([...activePlan.selectionContext.selection]));
    }
  }
};

export default setupSelectedNodesEventHandler;
