import React, { useCallback, useMemo } from 'react';

import styles from './pagination.module.css';

interface iProps {
  loadMore?: () => void;
  current: number;
  setCurrent: (current: number) => void;
  max: number;
  showNumberButtons?: boolean;
}

const Pagination = ({ loadMore, current, setCurrent, max, showNumberButtons }: iProps): JSX.Element => {
  const middleContent = useMemo(() => {
    if (!showNumberButtons || max === undefined) {
      return (current + 1).toString();
    }

    const items: JSX.Element[] = [];
    for (let i = 0; i < max; i++) {
      items.push(
        <button key={i} type='button' className={styles.button} disabled={i === current} onClick={() => setCurrent(i)}>
          {i + 1}
        </button>
      );
    }
    return items;
  }, [current, max, showNumberButtons]);

  const previous = useCallback(() => {
    if (current > 0) {
      setCurrent(current - 1);
    }
  }, [current, setCurrent]);

  const next = useCallback(() => {
    if (current >= max - 1) {
      if (loadMore) {
        loadMore();
        setCurrent(current + 1);
      }
    } else {
      setCurrent(current + 1);
    }
  }, [current, max, loadMore]);

  const first = useCallback(() => {
    setCurrent(0);
  }, [current, max, loadMore]);

  return (
    <div className={styles.paginationRow}>
      <div className={styles.container}>
        <span>Pages</span>
        <button type='button' className={styles.endButton} onClick={first} disabled={current === 0}>
          <i className='icon-angle-double-left' />
        </button>
        <button type='button' className={styles.endButton} onClick={previous} disabled={current === 0}>
          <i className='icon-angle-left' />
        </button>
        <div className={styles.middle}>{middleContent}</div>
        <button type='button' className={styles.endButton} onClick={next} disabled={current >= max - 1 && loadMore === undefined}>
          <i className='icon-angle-right' />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
