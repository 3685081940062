import { RequestError } from '@gi/gi-network';
import templatePlanErrorMessages from './get-template-plans-error-messages.json';

/**
 * Attaches a client message to a network error from a GET user request via the 'clientMessage' property.
 * The client message is attached based on the response code of the error
 *
 * @param {NetworkError} responseError
 */
export const attachGetTemplatePlansErrorClientMessage = (responseError: RequestError) => {
  if (responseError.statusCode && templatePlanErrorMessages[responseError.statusCode]) {
    responseError.clientMessage = templatePlanErrorMessages[responseError.statusCode];
  } else {
    responseError.clientMessage = `Unknown load template error - ${responseError.statusCode}`;
  }
};
