import { State, StateDef } from '@gi/state';
import GraphicNode from '../../graphics-node';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { hasEngine } from '../../utils/asserts';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
// eslint-disable-next-line import/no-cycle
import DraggableComponentContext from './draggable-component-context';
import InteractableComponent from '../interactable/interactable-component';
import { bindState } from '../../utils/state-utils';

export type DraggableComponentState = StateDef<{
  dragging: boolean;
}>;

const DEFAULT_STATE: DraggableComponentState['state'] = {
  dragging: false,
};

/**
 * Draggable Component
 * Allows the node to be dragged.
 * Currently requires a TransformComponent
 */
class DraggableComponent extends NodeComponent<GraphicNode> {
  type = 'DraggableComponent';

  readonly state: State<DraggableComponentState>;

  #cleanUpListeners: (() => void) | null;

  constructor() {
    super();

    this.state = new State({ ...DEFAULT_STATE });
    bindState(this.state, this);

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);

    // Capture all pointer events that make it to us
    this.owner.getContainer().eventMode = 'static';

    const interactableComponent = this.owner.components.get(InteractableComponent);

    if (!interactableComponent) {
      console.error('DraggableComponent requires an InteractableComponent be attached to the Node');
      return;
    }

    const draggableContext = this.owner.getContext(DraggableComponentContext);
    const callbacks = draggableContext.getDragCallbacks(this.owner);
    interactableComponent.addListener('onDragStart', callbacks.onDragStart);
    interactableComponent.addListener('onDragMove', callbacks.onDragMove);
    interactableComponent.addListener('onDragEnd', callbacks.onDragEnd);
    interactableComponent.addListener('onDragCancel', callbacks.onDragCancel);

    this.#cleanUpListeners = () => {
      interactableComponent.removeListener('onDragStart', callbacks.onDragStart);
      interactableComponent.removeListener('onDragMove', callbacks.onDragMove);
      interactableComponent.removeListener('onDragEnd', callbacks.onDragEnd);
      interactableComponent.removeListener('onDragCancel', callbacks.onDragCancel);
    };
  };

  #onBeforeUnbind = () => {
    hasEngine(this);

    // Capture all pointer events that make it to us
    this.owner.getContainer().eventMode = 'auto';

    this.#cleanUpListeners?.();
    this.#cleanUpListeners = null;
  };

  /**
   * Callback for when a drag of this node starts
   */
  onStart() {
    this.state.values.dragging = true;
  }

  /**
   * Callback for when a drag of this node ends
   */
  onEnd() {
    this.state.values.dragging = false;
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default DraggableComponent;
