import { TextStyle } from 'pixi.js-new';
import { RichTextPartDefinition } from './types';

/**
 * Basic class for caching generated TextStyles from the RichText system
 */
class RichTextStyleCache {
  #baseTextStyle: TextStyle;
  #textStyles: Record<string, TextStyle> = {};

  constructor(baseTextStyle: TextStyle) {
    this.#baseTextStyle = baseTextStyle;
  }

  /**
   * Generates a new TextStyle with the appropriate styles applied for the given text
   * @param part The part definition with the style options
   * @returns A new TextStyle for the given piece of text
   */
  #makeStyle(part: RichTextPartDefinition): TextStyle {
    const style = this.#baseTextStyle.clone();

    if (part.bold) {
      style.fontWeight = 'bold';
    }

    return style;
  }

  /**
   * Returns a style for the given text, caching it if it hasn't been created already
   * @param part The RichTextPartDefinition to get a style for
   * @returns A style for the text
   */
  getStyle(part: RichTextPartDefinition): TextStyle {
    const key = RichTextStyleCache.getStyleKey(part);

    if (key === '') {
      return this.#baseTextStyle;
    }

    if (this.#textStyles[key] !== undefined) {
      return this.#textStyles[key];
    }

    this.#textStyles[key] = this.#makeStyle(part);
    return this.#textStyles[key];
  }

  /**
   * Returns a string that uniquely identifies the TextStyle needed for the given text
   * '' is used for the default style.
   * @param part The RichTextPartDefinition to generate a key for
   * @returns A string
   */
  static getStyleKey(part: RichTextPartDefinition): string {
    let key: string = '';

    if (part.bold) {
      key += 'B';
    }

    return key;
  }
}

export default RichTextStyleCache;
