import { batchActions } from 'redux-batched-actions';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import { LocalSettings, LocalSettingsActionCreators } from '@gi/local-settings';
import { CanvasActionCreators, ResizeHistoricalPlansResult, RetryPlanUploadResult, SavePlanType } from '@gi/react-garden-canvas';
import { SessionActionCreators } from '@gi/react-session';
import Plan, { PlanClockwiseRotationAmount, PlanTransformUtils } from '@gi/plan';
import { User } from '@gi/user';
import { Anchor, UnitType } from '@gi/constants';
import { SeasonExtenderValues } from '@gi/common-types';
import Collection from '@gi/collection';
import GardenObject from '@gi/garden-object';

import { RequestActionCreators } from '@gi/react-requests';
import PlannerSettingsActionTypes from './planner-settings-action-types';
import { SettingsTabType } from './planner-settings-reducer';

export const openPlannerSettings = (defaultTab: SettingsTabType | null = null) => {
  return {
    type: PlannerSettingsActionTypes.OPEN_PLANNER_SETTINGS,
    defaultTab,
  };
};

export const closePlannerSettings = () => {
  return {
    type: PlannerSettingsActionTypes.CLOSE_PLANNER_SETTINGS,
  };
};

export const deletePlan = (plan: Plan) => {
  return (dispatch) => {
    dispatch(CanvasActionCreators.deletePlan(plan))
      .then(() => {
        dispatch(closePlannerSettings());
      })
      .catch(() => {
        // TODO - Display error
      });
  };
};

interface iSavePlanSettingsData {
  user: User;
  plan: Plan;
  name: string;
  year: number;
  gridUnits: UnitType;
  dimensions: {
    width: number;
    height: number;
    anchor: Anchor;
    rotation: PlanClockwiseRotationAmount | false;
  };
  planHistory: number[];
  resizeHistoricalPlans: boolean;
  gardenObjects: Collection<GardenObject>;
  showGrid: boolean;
  showRulers: boolean;
}

export interface SavePlanResult {
  savedPlan: boolean;
  savedPlanHistory: ResizeHistoricalPlansResult;
}

export const savePlanSettings = (
  {
    user,
    plan,
    name,
    year,
    dimensions: { width, height, anchor, rotation },
    gridUnits,
    planHistory,
    gardenObjects,
    resizeHistoricalPlans,
    showGrid,
    showRulers,
  }: iSavePlanSettingsData,
  closeOnSave = true
): ThunkAction<Promise<SavePlanResult>, any, any, AnyAction> => {
  return (dispatch) => {
    return new Promise<SavePlanResult>((resolve) => {
      let savedPlanHistory: SavePlanResult['savedPlanHistory'] = {
        successfulPlanIds: [],
        skippedPlanIds: [],
        failedPlanIds: [],
        failedPlans: {},
      };

      /**
       * Final function to update the main plan being edited.
       * @param clearUndoStack
       */
      const updatePlan = (clearUndoStack: boolean) => {
        const updatedPlan = PlanTransformUtils.rotateAndResizePlan(plan, width, height, anchor, rotation, gardenObjects);
        dispatch(
          CanvasActionCreators.updatePlanSettings(
            user,
            updatedPlan,
            name,
            year,
            updatedPlan.width,
            updatedPlan.height,
            gridUnits,
            planHistory,
            showGrid,
            showRulers,
            clearUndoStack
          )
        )
          .then((responsePlan) => {
            if (closeOnSave && savedPlanHistory.failedPlanIds.length === 0) {
              dispatch(closePlannerSettings());
            }
            resolve({ savedPlan: responsePlan !== null, savedPlanHistory });
          })
          .catch(() => {
            resolve({ savedPlan: false, savedPlanHistory });
          });
      };

      if (resizeHistoricalPlans) {
        dispatch(RequestActionCreators.requestStart(`RESIZE_PLAN_HISTORY_${plan.id}`));
        dispatch(
          CanvasActionCreators.resizeHistoricalPlans(
            planHistory,
            { width: plan.width, height: plan.height },
            {
              width,
              height,
              rotation,
              anchor,
            },
            gardenObjects
          )
        ).then((planIds) => {
          dispatch(RequestActionCreators.requestComplete(`RESIZE_PLAN_HISTORY_${plan.id}`));
          savedPlanHistory = planIds;

          updatePlan(true);
        });
      } else {
        updatePlan(false);
      }
    });
  };
};

export const retrySaveHistoricalPlans = (
  planId: number,
  historicalPlans: Plan[],
  closeOnSave: boolean = true
): ThunkAction<Promise<RetryPlanUploadResult>, any, any, AnyAction> => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(RequestActionCreators.requestStart(`RETRY_RESIZE_PLAN_HISTORY_${planId}`));
      dispatch(CanvasActionCreators.retryPlanUpload(historicalPlans, true, SavePlanType.UPDATE_HISTORIC))
        .then((result) => {
          dispatch(RequestActionCreators.requestComplete(`RETRY_RESIZE_PLAN_HISTORY_${planId}`));
          if (closeOnSave && result.failedPlanIds.length === 0) {
            dispatch(closePlannerSettings());
          }
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
};

interface iSaveProfileSettingsData {
  user: User;
  showLabelOnNewPlants: boolean;
  showLabelOnNewSFGPlants: boolean;
  showPlantRoots: boolean;
  varietyForDefaultLabel: boolean;
  labelTextSize: number;
  autosave: boolean;
  seasonExtenderSettings: SeasonExtenderValues;
  userArtifactCode: string | null;
}

export const saveProfileSettings = (
  {
    user,
    showLabelOnNewPlants,
    showLabelOnNewSFGPlants,
    showPlantRoots,
    varietyForDefaultLabel,
    labelTextSize,
    autosave,
    seasonExtenderSettings,
    userArtifactCode,
  }: iSaveProfileSettingsData,
  closeOnSave = true
) => {
  const updatedUser: User = {
    ...user,
    planSettings: {
      ...user.planSettings,
      showLabelOnNewPlants,
      showLabelOnNewSFGPlants,
      showPlantRoots,
      varietyForDefaultLabel,
      labelTextSize,
      autosave,
    },
    settings: {
      ...user.settings,
      seasonExtenders: { ...seasonExtenderSettings },
      data: {
        ...user.settings.data,
        userArtifactCode,
      },
    },
  };

  return (dispatch) => {
    dispatch(SessionActionCreators.saveUser(updatedUser))
      .then(() => {
        if (closeOnSave) {
          dispatch(closePlannerSettings());
        }
      })
      .catch(() => {
        // TODO, handle save plan errors
      });
  };
};

// All settings passed to saveLocalSettings get validated down the line, so they could be anything
export const saveLocalSettings = (localSettings: Partial<LocalSettings>, close = true) => {
  return (dispatch) => {
    if (close) {
      dispatch(batchActions([LocalSettingsActionCreators.saveLocalSettings(localSettings), closePlannerSettings()]));
    } else {
      dispatch(LocalSettingsActionCreators.saveLocalSettings(localSettings));
    }
  };
};

export const resetLocalSettings = () => {
  return (dispatch) => {
    dispatch(batchActions([LocalSettingsActionCreators.resetLocalSettings(), closePlannerSettings()]));
  };
};

export const openRenderModeChangedModal = () => {
  return {
    type: PlannerSettingsActionTypes.OPEN_RENDER_MODE_CHANGE_MODAL,
  };
};

export const closeRenderModeChangedModal = () => {
  return {
    type: PlannerSettingsActionTypes.CLOSE_RENDER_MODE_CHANGE_MODAL,
  };
};
