import { RequestError, RequestAuthMode, networkService, networkErrorReporter } from '@gi/gi-network';
import { GuruDirectoryPage, GuruContentPage } from '@gi/app-guru-types/pages';
import { GuruSection } from '@gi/app-guru-types/types';
import Collection from '@gi/collection';
import Plant from '@gi/plant';
import { Pest } from '@gi/pest';
import { GuruPages } from '@gi/app-guru-types';

import { parseContent, parseDirectory, parsePages, parseSection } from './app-guru-parser';
import { APIContent, APIPage, APIPages, APIRelatedContent, APISection } from './api-types';

class GuruService {
  private endpoint: string;

  /**
   * Loaded collections of plants/pests which are referenced
   * in guru plants/pests when loading
   */
  private plants: Collection<Plant> | null = null;
  private pests: Collection<Pest> | null = null;

  /**
   * Contains id/name data for guru pages so they can be correctly mapped and loaded
   * when parsing
   */
  private pages: GuruPages | null = null;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
    console.debug(`Created guru service with endpoint ${this.endpoint}`);
  }

  setPlantData(plants: Collection<Plant>) {
    this.plants = plants;
  }

  setPestData(pests: Collection<Pest>) {
    this.pests = pests;
  }

  setPageData(pages: GuruPages) {
    this.pages = pages;
  }

  setBookmark(contentId: number, bookmark: boolean): Promise<string | null> {
    if (bookmark) {
      return this.addBookmark(contentId);
    }

    return this.removeBookmark(contentId);
  }

  addBookmark(contentId: number): Promise<string> {
    const endPoint = `Content/${contentId}/Bookmark`;
    const url = `${this.endpoint}${endPoint}`;
    return networkService
      .post<string>(url, {}, undefined, RequestAuthMode.Bearer)
      .then((response) => {
        return response.body;
      })
      .catch(networkErrorReporter('POST', 'guru bookmark'))
      .catch((err: RequestError) => {
        console.error(err);
        // TODO: Attach error
        throw err;
      });
  }

  removeBookmark(contentId: number): Promise<null> {
    const endPoint = `Content/${contentId}/Bookmark`;
    const url = `${this.endpoint}${endPoint}`;
    return networkService
      .delete(url, {}, RequestAuthMode.Bearer)
      .then(() => {
        return null;
      })
      .catch(networkErrorReporter('DELETE', 'guru bookmark'))
      .catch((err: RequestError) => {
        console.error(err);
        // TODO: Attach error
        throw err;
      });
  }

  getDirectoryPage(apiPageId: string): Promise<GuruDirectoryPage> {
    if (this.pages === null) {
      throw new Error('Not loaded required pages resources');
    }

    const endPoint = `Pages/${apiPageId}`;
    const url = `${this.endpoint}${endPoint}`;
    return networkService
      .get<APIPage>(url, {}, RequestAuthMode.Bearer)
      .then((response) => parseDirectory(this.pages!, response.body))
      .catch(networkErrorReporter('GET', 'guru directory page'))
      .catch((err: RequestError) => {
        console.error(err);
        // TODO: Attach error
        throw err;
      });
  }

  getContent(contentSlug: string, contentUUID: string, contentId: number): Promise<GuruContentPage> {
    const endPoint = `Content/${contentSlug}`;
    const url = `${this.endpoint}${endPoint}`;
    return networkService
      .get<APIContent>(url, {}, RequestAuthMode.Bearer)
      .then((response) => parseContent(contentUUID, contentId, response.body))
      .catch(networkErrorReporter('GET', 'guru content'))
      .catch((err: RequestError) => {
        // TODO: Attach error
        console.error(err);
        throw err;
      });
  }

  getSection(sectionId: string): Promise<GuruSection> {
    if (this.plants === null || this.pests === null) {
      throw new Error('Not loaded required plant and pest resources');
    }

    const url = `${this.endpoint}${sectionId}`;
    return networkService
      .get<APISection>(url, {}, RequestAuthMode.Bearer)
      .then((response) => parseSection(response.body, this.plants!, this.pests!))
      .catch(networkErrorReporter('GET', 'guru section'))
      .catch((err: RequestError) => {
        // TODO: Attach error
        console.error(err);
        throw err;
      });
  }

  getRelatedContent(relatedContentId: string): Promise<GuruSection> {
    if (this.plants === null || this.pests === null) {
      throw new Error('Not loaded required plant and pest resources');
    }

    const url = `${this.endpoint}${relatedContentId}`;
    return networkService
      .get<APIRelatedContent>(url, {}, RequestAuthMode.Bearer)
      .then((response) => parseSection(response.body, this.plants!, this.pests!))
      .catch(networkErrorReporter('GET', 'guru section'))
      .catch((err: RequestError) => {
        // TODO: Attach error
        console.error(err);
        throw err;
      });
  }

  getPages(): Promise<GuruPages> {
    const url = `${this.endpoint}/pages`;
    return networkService
      .get<APIPages>(url, {}, RequestAuthMode.Bearer)
      .then((response) => parsePages(response.body))
      .catch(networkErrorReporter('GET', 'guru pages'))
      .catch((err: RequestError) => {
        // TODO: Attach error
        console.error(err);
        throw err;
      });
  }
}

export default GuruService;
