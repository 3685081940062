import { Container, Sprite, Texture } from 'pixi.js-new';

import { State, StateDef } from '@gi/state';
import { RepeatingGraphicDisplayModes, RepeatingGraphicDisplayModesType } from '@gi/constants';

import GraphicNode from '../../graphics-node';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { bindState } from '../../utils/state-utils';

export type RepeatingSpriteComponentState = StateDef<{
  width: number;
  height: number;
  rotation: number;
  spritesAcross: number;
  spritesDown: number;
  spriteRotation: number;
  spriteWidth: number;
  spriteHeight: number;
  maximumSprites: number;
  spriteDisplayMode: RepeatingGraphicDisplayModesType;
}>;

const DEFAULT_STATE: RepeatingSpriteComponentState['state'] = {
  width: 100,
  height: 100,
  rotation: 0,
  spritesAcross: 4,
  spritesDown: 4,
  spriteRotation: 0,
  spriteWidth: 50,
  spriteHeight: 50,
  maximumSprites: 1000,
  spriteDisplayMode: RepeatingGraphicDisplayModes.BLOCK,
};

/**
 * Repeating Sprite component
 * Draws a bunch of sprites over the given area.
 */
class RepeatingSpriteComponent extends NodeComponent<GraphicNode> {
  type = 'RepeatingSpriteComponent';

  state: State<RepeatingSpriteComponentState>;

  #sprites: Sprite[] = [];
  #container: Container;
  #texture: Texture | null = null;
  #maskTexture: Texture | null = null;

  constructor(state?: Partial<RepeatingSpriteComponentState['state']>) {
    super();

    this.#container = new Container();
    this.#container.interactiveChildren = false;
    this.#sprites = [];

    this.state = new State<RepeatingSpriteComponentState>({
      ...DEFAULT_STATE,
      ...state,
    });
    bindState(this.state, this);
    this.state.addWatcher(() => this.#update(), undefined, false);

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
    this.eventBus.on(NodeComponentEvent.Destroyed, this.#onDestroy);
  }

  #onBind = () => {
    this.#update();
  };

  #onBeforeUnbind = () => {
    this.#sprites.forEach((sprite) => {
      sprite.destroy();
    });
    this.#sprites = [];
  };

  #onDestroy = () => {
    this.#container.destroy();
  };

  getContainer() {
    return this.#container;
  }

  set texture(texture: Texture) {
    this.#texture = texture;
    this.#sprites.forEach((sprite) => {
      sprite.texture = texture;
    });
  }

  set maskTexture(texture: Texture) {
    this.#maskTexture = texture;
    // this.#sprites.forEach((sprite) => {
    //   sprite.maskTexture = texture;
    // });
  }

  // Returns the sprite at the given index, or creates it if not exists
  #getOrCreateSprite(index: number) {
    if (this.#sprites.length > index) {
      return this.#sprites[index];
    }
    const sprite = new Sprite(this.#texture ?? undefined);
    // if (this.#maskTexture) {
    //   sprite.maskTexture = this.#maskTexture;
    // }
    this.#sprites[index] = sprite;
    this.#container.addChild(sprite);
    return sprite;
  }

  // Updates the given sprites position, anchor, size and rotation.
  #updateSprite(sprite: Sprite, x: number, y: number) {
    const { spriteWidth, spriteHeight, spriteRotation } = this.state.values;
    sprite.x = x;
    sprite.y = y;
    sprite.anchor.x = 0.5;
    sprite.anchor.y = 0.5;
    sprite.width = spriteWidth;
    sprite.height = spriteHeight;
    sprite.rotation = spriteRotation;
  }

  // Draws the sprites in a block
  #drawBlock(spacing: Vector2) {
    const { spritesAcross, spritesDown } = this.state.values;

    for (let y = 0; y < spritesDown; y++) {
      for (let x = 0; x < spritesAcross; x++) {
        const index = y * spritesAcross + x;
        const sprite = this.#getOrCreateSprite(index);
        this.#updateSprite(sprite, x * spacing.x, y * spacing.y);
      }
    }

    return spritesDown * spritesAcross;
  }

  // Returns the amount of sprites needed to complete the edge of the block
  #getEdgeSpiteCount() {
    const { spritesAcross, spritesDown } = this.state.values;
    return spritesAcross === 1 ? spritesDown : spritesDown === 1 ? spritesAcross : spritesAcross * 2 + (spritesDown - 2) * 2;
  }

  // Converts a sprite index to an x and y coordinate
  #getEdgePosition(index: number): Vector2 {
    const { spritesAcross, spritesDown } = this.state.values;
    // Top row
    if (index < spritesAcross) {
      return { x: index, y: 0 };
    }
    // Middle rows
    if (index < spritesAcross + Math.max(spritesDown - 2, 0) * Math.min(spritesAcross, 2)) {
      return {
        x: ((index - spritesAcross) % 2) * (spritesAcross - 1),
        y: 1 + Math.floor((index - spritesAcross) / Math.min(spritesAcross, 2)),
      };
    }
    // Bottom row
    return { x: index % spritesAcross, y: spritesDown - 1 };
  }

  // Draws just the outline of sprites
  #drawOutline(spacing: Vector2) {
    const edgeSpriteCount = this.#getEdgeSpiteCount();

    for (let index = 0; index < edgeSpriteCount; index++) {
      const pos = this.#getEdgePosition(index);
      const sprite = this.#getOrCreateSprite(index);
      this.#updateSprite(sprite, pos.x * spacing.x, pos.y * spacing.y);
    }

    return edgeSpriteCount;
  }

  // Returns the amount of sprites needed to draw just the corners of the block
  #getCornerSpriteCount() {
    const { spritesAcross, spritesDown } = this.state.values;
    if (spritesAcross === 1 && spritesDown === 1) {
      return 1;
    }
    if (spritesAcross === 1 || spritesDown === 1) {
      return 2;
    }
    return 4;
  }

  // Converts a sprite index to an x and y coordinate
  #getCornerSpritePosition(index: number): Vector2 {
    const { spritesAcross, spritesDown } = this.state.values;
    if (spritesAcross === 1) {
      return { x: 0, y: index * (spritesDown - 1) };
    }
    if (spritesDown === 1) {
      return { x: index * (spritesAcross - 1), y: 0 };
    }
    return {
      x: (index % 2) * (spritesAcross - 1),
      y: Math.floor(index / 2) * (spritesDown - 1),
    };
  }

  // Draws just the corner sprites
  #drawCorners(spacing: Vector2) {
    const cornerSpriteCount = this.#getCornerSpriteCount();

    for (let index = 0; index < cornerSpriteCount; index++) {
      const pos = this.#getCornerSpritePosition(index);
      const sprite = this.#getOrCreateSprite(index);
      this.#updateSprite(sprite, pos.x * spacing.x, pos.y * spacing.y);
    }

    return cornerSpriteCount;
  }

  // Updates the render
  #update() {
    if (!this.bound) {
      return;
    }

    const { spritesAcross, spritesDown, width, height, rotation, maximumSprites, spriteDisplayMode } = this.state.values;

    this.#container.rotation = rotation;

    // Work out the space between each sprite
    const spriteSpacing: Vector2 = {
      x: spritesAcross > 1 ? width / (spritesAcross - 1) : 0,
      y: spritesDown > 1 ? height / (spritesDown - 1) : 0,
    };

    // Work out how many sprites are needed for each mode
    const totalSprites = spritesAcross * spritesDown;
    const edgeSprites = this.#getEdgeSpiteCount();

    // Work out what the highest-possible draw method is, given the amount of sprites.
    const displayMethod =
      totalSprites <= maximumSprites && spriteDisplayMode === RepeatingGraphicDisplayModes.BLOCK
        ? RepeatingGraphicDisplayModes.BLOCK
        : edgeSprites <= maximumSprites && (spriteDisplayMode === RepeatingGraphicDisplayModes.BLOCK || spriteDisplayMode === RepeatingGraphicDisplayModes.EDGE)
          ? RepeatingGraphicDisplayModes.EDGE
          : RepeatingGraphicDisplayModes.CORNER;

    // Create/re-organise thew sprites into the desired configuration
    let usedSprites: number;
    switch (displayMethod) {
      case RepeatingGraphicDisplayModes.BLOCK:
        usedSprites = this.#drawBlock(spriteSpacing);
        break;
      case RepeatingGraphicDisplayModes.EDGE:
        usedSprites = this.#drawOutline(spriteSpacing);
        break;
      case RepeatingGraphicDisplayModes.CORNER:
      default:
        usedSprites = this.#drawCorners(spriteSpacing);
        break;
    }

    // Murder any unused sprites
    if (this.#sprites.length > usedSprites) {
      const unusedSprites = this.#sprites.splice(usedSprites, this.#sprites.length - usedSprites);
      unusedSprites.forEach((sprite) => {
        sprite.destroy();
      });
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default RepeatingSpriteComponent;
