import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';

import {
  ModalPaneContainer,
  ModalPane,
  ModalFooter,
  ModalFooterButtons,
  ModalFooterButtonsSection,
  ModalPaneSection,
  ModalPaneSectionHeader,
  ModalPaneSectionContent,
  ModalContext,
  ModalTabContext,
  AttemptToLeaveCallback,
  ConfirmLeaveContextResult,
} from '@gi/modal';

import FormField, { createFormValues, FormFieldOptionsPreset, FormSection, InputContainer } from '@gi/form-responsive';
import { Anchor, UnitTypes } from '@gi/constants';
import { User, UserUtils } from '@gi/user';
import { Plan, PlanClockwiseRotationAmount, PlanUtils } from '@gi/plan';
import LoadingButton from '@gi/loading-button';
import { RequestSelectors } from '@gi/react-requests';
import { SessionSelectors } from '@gi/react-session';
import { CanvasSelectors } from '@gi/react-garden-canvas';
import { ResourceContext } from '@gi/resource-provider';
import { PlanValidators, StringValidators } from '@gi/validators';
import { RequestsUtils } from '@gi/request';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import PlanHistoryEditor from './plan-history-editor';
import DeletePlanModalRenderer from './delete-plan-modal-renderer';
import { retrySaveHistoricalPlans, SavePlanResult, savePlanSettings } from '../planner-settings-action-creators';
import PlanDimensionsEditor from './plan-dimensions-editor';
import PlanSettingsSaveError from './plan-settings-save-error';
import PlanSettingsSaveProgressBar from './plan-settings-save-progress-bar';

const FORM_FIELD_LAYOUT: FormFieldOptionsPreset = {
  desktop: {
    labelSize: 120,
  },
};

interface PlanSettingsValues {
  planName: string;
  year: number;
  useMetric: boolean;
  width: number;
  height: number;
  anchor: Anchor;
  rotation: PlanClockwiseRotationAmount | false;
  showGrid: boolean;
  showRulers: boolean;
  planHistory: number[];
  resizeHistoricalPlans: boolean;
}

interface iProps {
  user: User;
  plan: Plan;
}

const PlanSettingsTab = ({ user, plan }: iProps): JSX.Element => {
  const dispatch = useDispatch();
  const requests = useSelector(RequestSelectors.getRequests);

  const { userGardenObjects } = useContext(ResourceContext);

  const { setCloseContextParams, attemptClose } = useContext(ModalContext);
  const { setLeaveContextParams } = useContext(ModalTabContext);

  const [deletePlanModalOpen, setDeletePlanModalOpen] = useState(false);
  const [savePlanResult, setSavePlanResult] = useState<SavePlanResult | null>(null);

  const [planSettings, setPlanSettings] = useState(
    createFormValues<PlanSettingsValues>({
      planName: {
        value: plan.name,
        validators: [StringValidators.minLength(1), StringValidators.maxLength(200), PlanValidators.isUniquePlanName(user.plans.list, plan.year, plan.id)],
      },
      year: {
        value: plan.year,
        validators: [PlanValidators.notReachedPlanLimit(user.plans.list, user.settings.maxPlansPerYear, plan.id)],
      },
      useMetric: { value: plan.plannerSettings.metric },
      width: {
        value: plan.width,
        validators: [PlanValidators.isValidDimension(user.settings.units.metric)],
      },
      height: {
        value: plan.height,
        validators: [PlanValidators.isValidDimension(user.settings.units.metric)],
      },
      anchor: {
        value: Anchor.TopLeft,
      },
      rotation: {
        value: false,
      },
      showGrid: {
        value: plan.plannerSettings.showGrid,
      },
      showRulers: {
        value: plan.plannerSettings.showRulers,
      },
      planHistory: { value: [...plan.history] },
      resizeHistoricalPlans: { value: false },
    })
  );

  const setValue = <K extends keyof PlanSettingsValues>(key: K, value: PlanSettingsValues[K]) => {
    setPlanSettings(planSettings.setValue(key, { value }));
  };

  const { planName, year, useMetric, width, height, planHistory, anchor, rotation, resizeHistoricalPlans, showGrid, showRulers } = planSettings.values;
  const valid = planSettings.isValid;

  const planSaving = RequestsUtils.requestInProgress(requests, `SAVE_PLAN_${plan.id}`);
  const planResizing = RequestsUtils.requestInProgress(requests, `RESIZE_PLAN_HISTORY_${plan.id}`);
  const retryPlanResizing = RequestsUtils.requestInProgress(requests, `RETRY_RESIZE_PLAN_HISTORY_${plan.id}`);

  const loading = planSaving || planResizing || retryPlanResizing;

  // Form should be disabled if we
  const formDisabled = useMemo(() => {
    const saveError = savePlanResult !== null && (savePlanResult.savedPlan === false || savePlanResult.savedPlanHistory.failedPlanIds.length > 0);
    return loading || saveError;
  }, [loading, savePlanResult]);

  // Update validators
  useEffect(() => {
    setPlanSettings((newBlankPlanFormValues) =>
      newBlankPlanFormValues.setValues([
        'planName',
        {
          validators: [StringValidators.minLength(1), StringValidators.maxLength(200), PlanValidators.isUniquePlanName(user.plans.list, year, plan.id)],
        },
      ])
    );
  }, [user, year, plan]);

  useEffect(() => {
    setPlanSettings((newBlankPlanFormValues) =>
      newBlankPlanFormValues.setValues([
        'year',
        {
          validators: [PlanValidators.notReachedPlanLimit(user.plans.list, user.settings.maxPlansPerYear, plan.id)],
        },
      ])
    );
  }, [user, year, plan]);

  useEffect(() => {
    setPlanSettings((newBlankPlanFormValues) =>
      newBlankPlanFormValues.setValues(
        ['width', { validators: [PlanValidators.isValidDimension(useMetric)] }],
        ['height', { validators: [PlanValidators.isValidDimension(useMetric)] }]
      )
    );
  }, [useMetric]);

  const onSaveChanges = useCallback(
    (close: boolean) => {
      if (!valid) {
        return;
      }

      dispatch(
        savePlanSettings(
          {
            user,
            plan,
            name: planName,
            year,
            dimensions: {
              width,
              height,
              anchor,
              rotation,
            },
            gridUnits: useMetric ? UnitTypes.METRIC : UnitTypes.IMPERIAL,
            planHistory,
            gardenObjects: userGardenObjects,
            resizeHistoricalPlans,
            showGrid,
            showRulers,
          },
          close
        )
      ).then((result) => {
        setSavePlanResult(result);
      });
    },
    [planSettings, savePlanSettings, userGardenObjects]
  );

  const onRetrySaveChanges = useCallback(
    (close: boolean) => {
      if (savePlanResult === null || savePlanResult.savedPlanHistory.failedPlanIds.length === 0) {
        return;
      }

      const plans = savePlanResult.savedPlanHistory.failedPlanIds
        .map((planId) => savePlanResult.savedPlanHistory.failedPlans[planId])
        .filter((failedPlan): failedPlan is Plan => failedPlan !== undefined);

      if (plans.length === 0) {
        return;
      }

      dispatch(retrySaveHistoricalPlans(plan.id, plans, close)).then((result) => {
        if (result.failedPlanIds.length === 0) {
          setSavePlanResult(null);
        } else {
          // We failed again, update the failed status with the newply failed plans
          setSavePlanResult({
            ...savePlanResult,
            savedPlanHistory: {
              ...savePlanResult.savedPlanHistory,
              failedPlanIds: result.failedPlanIds,
              failedPlans: {
                ...savePlanResult.savedPlanHistory.failedPlans,
                ...result.failedPlans,
              },
              successfulPlanIds: [...savePlanResult.savedPlanHistory.successfulPlanIds, ...result.successfulPlanIds],
            },
          });
        }
      });
    },
    [savePlanResult]
  );

  useEffect(() => {
    const createLeaveCallback = (closeOnSave: boolean): AttemptToLeaveCallback => {
      return (cb) => {
        return cb(planSettings.hasBeenEdited).then((result) => {
          if (result === ConfirmLeaveContextResult.SaveAndClose) {
            onSaveChanges(closeOnSave);
          }

          return result !== ConfirmLeaveContextResult.Cancel;
        });
      };
    };

    const invalidMessage = planSettings.isValid ? undefined : 'Some inputs have invalid values, please correct them before saving';

    setCloseContextParams({
      title: 'Close Without Saving',
      text: 'Closing without saving plan settings will lose any changes you have made',
      callback: createLeaveCallback(true),
      invalidMessage,
    });

    setLeaveContextParams({
      title: 'Leave Without Saving',
      text: 'Leaving without saving plan settings will lose any changes you have made',
      callback: createLeaveCallback(false),
      withoutSavingButtonText: 'Leave Without Saving',
      withSavingButtonText: 'Save and Leave',
      invalidMessage,
    });

    return () => {
      setCloseContextParams(null);
      setLeaveContextParams(null);
    };
  }, [planSettings.hasBeenEdited, onSaveChanges]);

  const selectedYear = { value: year, label: year.toString() };

  return (
    <>
      <DeletePlanModalRenderer
        modalOpen={deletePlanModalOpen}
        closeModal={() => {
          setDeletePlanModalOpen(false);
        }}
        plan={plan}
        user={user}
      />
      <ModalPaneContainer>
        <ModalPane>
          {/* Header */}
          <ModalPaneSection>
            <ModalPaneSectionHeader>Plan Settings</ModalPaneSectionHeader>
            <ModalPaneSectionContent>
              <p className='settings-introduction'>These settings only apply to the current plan.</p>
            </ModalPaneSectionContent>
          </ModalPaneSection>
          {/* General Section */}
          <FormSection heading='General' padding={12} gap={6} className='form-section-background'>
            {/* Plan Name */}
            <FormField
              label='Plan Name'
              htmlFor='plan-settings:plan-name'
              invalid={!planSettings.fields.planName.valid}
              errorText={planSettings.fields.planName.errors.join(', ')}
              disabled={formDisabled}
              layoutPreset={FORM_FIELD_LAYOUT}
            >
              <InputContainer>
                <input
                  type='text'
                  value={planName}
                  onChange={(e) => {
                    setValue('planName', e.target.value);
                  }}
                  id='plan-settings:plan-name'
                  disabled={formDisabled}
                />
              </InputContainer>
            </FormField>
            {/* Plan Year */}
            <FormField
              label='Plan Year'
              htmlFor='plan-settings:plan-year'
              invalid={!planSettings.fields.year.valid}
              errorText={planSettings.fields.year.errors.join(', ')}
              disabled={formDisabled}
              layoutPreset={FORM_FIELD_LAYOUT}
            >
              <InputContainer size='full'>
                <ReactSelect
                  className='select-input'
                  styles={DEFAULT_SELECT_STYLES}
                  options={PlanUtils.getYearOptions(UserUtils.isNorthernHemisphere(user))}
                  value={selectedYear}
                  onChange={(val) => {
                    if (val) {
                      setValue('year', val.value);
                    }
                  }}
                  isSearchable={false}
                  inputId='plan-settings:plan-year'
                  isDisabled={formDisabled}
                />
              </InputContainer>
            </FormField>
          </FormSection>
          <FormSection heading='Display' padding={12} gap={6} className='form-section-background'>
            <FormField
              label='Show Grid'
              htmlFor='plan-settings:show-grid'
              invalid={!planSettings.fields.showGrid.valid}
              errorText={planSettings.fields.showGrid.errors.join(', ')}
              disabled={formDisabled}
              layoutPreset={FORM_FIELD_LAYOUT}
            >
              <input
                id='plan-settings:show-grid'
                type='checkbox'
                checked={showGrid}
                onChange={(e) => setValue('showGrid', e.target.checked)}
                disabled={formDisabled}
              />
            </FormField>
            <FormField
              label='Show Rulers'
              htmlFor='plan-settings:show-rulers'
              invalid={!planSettings.fields.showRulers.valid}
              errorText={planSettings.fields.showRulers.errors.join(', ')}
              disabled={formDisabled}
              layoutPreset={FORM_FIELD_LAYOUT}
            >
              <input
                id='plan-settings:show-rulers'
                type='checkbox'
                checked={showRulers}
                onChange={(e) => setValue('showRulers', e.target.checked)}
                disabled={formDisabled}
              />
            </FormField>
          </FormSection>
          {/* Units & Dimensions Section */}
          <PlanDimensionsEditor values={planSettings} setValue={setValue} plan={plan} disabled={formDisabled} />
          {/* Delete plan Section */}
          <ModalPaneSection className='modal-form-section'>
            <ModalPaneSectionHeader>Delete</ModalPaneSectionHeader>
            <ModalPaneSectionContent>
              <button
                type='button'
                className='button button-warning'
                disabled={formDisabled}
                onClick={() => {
                  setDeletePlanModalOpen(true);
                }}
              >
                Delete This Plan
              </button>
            </ModalPaneSectionContent>
          </ModalPaneSection>
          {/* Plan History Section */}
          <ModalPaneSection className='modal-form-section'>
            <ModalPaneSectionHeader>Plan History</ModalPaneSectionHeader>
            <ModalPaneSectionContent>
              <PlanHistoryEditor currentPlanID={plan.id} planHistory={planHistory} onChange={(val) => setValue('planHistory', val)} disabled={formDisabled} />
            </ModalPaneSectionContent>
          </ModalPaneSection>
          {/* Progress section */}
          <PlanSettingsSaveProgressBar plan={plan} planHistory={planHistory} resizeHistoricalPlans={resizeHistoricalPlans} />
          {/* Error display */}
          {savePlanResult !== null ? (
            <PlanSettingsSaveError
              loading={loading}
              savePlanResult={savePlanResult}
              onRetry={() => onRetrySaveChanges(true)}
              onDismiss={() => {
                setSavePlanResult(null);
                attemptClose();
              }}
            />
          ) : null}
        </ModalPane>
      </ModalPaneContainer>
      {/* Footer */}
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-secondary' onClick={attemptClose}>
              Cancel
            </button>
          </ModalFooterButtonsSection>
          <ModalFooterButtonsSection>
            <LoadingButton className='button button-primary' disabled={!valid || formDisabled} onClick={() => onSaveChanges(true)} loading={loading}>
              Save Plan Settings
            </LoadingButton>
          </ModalFooterButtonsSection>
        </ModalFooterButtons>
      </ModalFooter>
    </>
  );
};

const PlanSettingsTabRenderer = (props: Omit<iProps, 'user' | 'plan'>): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);
  const plan = useSelector(CanvasSelectors.getActivePlan);

  if (!user || !plan) {
    return null;
  }

  return <PlanSettingsTab user={user} plan={plan} {...props} />;
};

export default PlanSettingsTabRenderer;
