import { State, StateDef } from '@gi/state';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { hasEngine } from '../../utils/asserts';
import { bindState } from '../../utils/state-utils';
import HoverableComponent, { HoverableComponentState } from '../hoverable/hoverable-component';
import ManipulatableComponent, { ManipulatableComponentState } from '../manipulatable/manipulatable-component';
// eslint-disable-next-line import/no-cycle
import TooltipComponentContext from './tooltip-component-context';
import { RichTextDefinition } from '../../pixi-components/rich-text/types';

// How long it takes before a tooltip will show.
const MOUSEOVER_TIMEOUT_DURATION = 500;

export type TooltipComponentState = StateDef<
  {
    text: string | RichTextDefinition;
    offset: Vector2;
  },
  [],
  {
    hoverable: HoverableComponentState;
    manipulatable: ManipulatableComponentState;
  }
>;

const DEFAULT_STATE: TooltipComponentState['state'] = {
  text: '',
  offset: { x: 0, y: 0 },
};

enum TooltipStatus {
  HIDDEN = 'HIDDEN',
  WAITING_TO_SHOW = 'WAITING_TO_SHOW',
  VISIBLE = 'VISIBLE',
}

/**
 * Tooltip Component
 *  Stores information about the tooltip this node should show when hovered.
 *  Requires a HoverableComponent to be attached to the owner.
 */
class TooltipComponent extends NodeComponent {
  type = 'TooltipComponent';

  readonly state: State<TooltipComponentState>;

  #status: TooltipStatus = TooltipStatus.HIDDEN;
  #timeout: number | null = null;
  #eventListenerDestructor: (() => void) | null = null;

  constructor(initialState: Partial<TooltipComponentState['state']> = {}) {
    super();

    this.state = new State<TooltipComponentState>({
      ...DEFAULT_STATE,
      ...initialState,
    });
    bindState(this.state, this);

    this.state.addWatcher(() => this.#update(), {
      otherStates: {
        hoverable: { properties: ['hovered'] },
        manipulatable: { properties: ['hoveringHandles', 'manipulating'] },
      },
    });

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);

    const hoverable = this.owner.components.get(HoverableComponent);
    if (!hoverable) {
      throw new Error('Tooltip added to node with no HoverableComponent');
    }
    this.state.connectState('hoverable', hoverable.state);

    const manipulatable = this.owner.components.get(ManipulatableComponent);
    if (manipulatable) {
      this.state.connectState('manipulatable', manipulatable.state);
    }
  };

  #onBeforeUnbind = () => {
    this.owner?.tryGetContext(TooltipComponentContext)?.hideTooltip(this);
  };

  /**
   * Called on state change. Handles showing/hiding the tooltip based on hover and other states.
   */
  #update() {
    const context = this.owner?.tryGetContext(TooltipComponentContext);
    if (!context) {
      return;
    }

    const hovered = this.state.get('hoverable', 'hovered');
    const hoveringHandles = this.state.get('manipulatable', 'hoveringHandles');
    const manipulating = this.state.get('manipulatable', 'manipulating');

    if (manipulating && this.#status !== TooltipStatus.VISIBLE) {
      // If manipulating, instantly show the tooltip
      context.showTooltip(this);
      this.#status = TooltipStatus.VISIBLE;
    } else if ((hovered || hoveringHandles) && this.#status === TooltipStatus.HIDDEN) {
      // If we're hovering and the tooltip is currently hidden, start the timeout to show
      this.#timeout = window.setTimeout(() => {
        context.showTooltip(this);
        this.#status = TooltipStatus.VISIBLE;
      }, MOUSEOVER_TIMEOUT_DURATION);
      this.#status = TooltipStatus.WAITING_TO_SHOW;
    } else if (!hovered && !hoveringHandles && !manipulating && this.#status !== TooltipStatus.HIDDEN) {
      // If we're not hovering or manipulating, but not currently hidden, hide
      if (this.#status === TooltipStatus.VISIBLE) {
        context.hideTooltip(this);
      } else if (this.#timeout !== null) {
        window.clearTimeout(this.#timeout);
        this.#timeout = null;
      }
      this.#status = TooltipStatus.HIDDEN;
    }
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default TooltipComponent;
