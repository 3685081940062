import { IntercomActionCreators } from '@gi/intercom';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import styles from './feedback-banner.module.css';

interface iProps {
  heading: string;
  text: string;
  buttonText: string;
}

const SupportBanner = ({ heading, text, buttonText }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const openChat = useCallback(() => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('guru-feedback-banner', 'showNewMessage'));
  }, []);

  return (
    <div className={styles.feedbackBanner}>
      <div className={styles.feedbackText}>
        <div>
          <p className={styles.heading}>{heading}</p>
          <p>{text}</p>
        </div>
        <button type='button' className='button' onClick={openChat}>
          {buttonText}
        </button>
      </div>
      <div className={styles.feedbackLogo}>
        <i className='icon-guru' />
      </div>
    </div>
  );
};

export default SupportBanner;
