import { ThunkAction } from '@reduxjs/toolkit';

import { UserRequestActionCreators } from '@gi/react-requests';
import { User, UserPlantVarietySet } from '@gi/user';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators } from '@gi/garden-platform-events';

import SessionActionTypes from './session-action-types';

export const setUser = (user: User | null) => {
  return {
    type: SessionActionTypes.SET_USER,
    user,
  };
};

const sessionRestoreFailed = () => {
  return {
    type: SessionActionTypes.RESTORE_FAILED,
  };
};

const updateUserFromSave = (user: User) => {
  return {
    type: SessionActionTypes.UPDATE_USER_FROM_SAVE,
    user,
  };
};

const restore = (user: User | null) => {
  return {
    type: SessionActionTypes.RESTORE,
    user,
  };
};

const startRestore = () => {
  return {
    type: SessionActionTypes.START_RESTORE,
  };
};

const startSaveUser = (user: User) => {
  return {
    type: SessionActionTypes.START_SAVE_USER,
    user,
  };
};

const endSaveUser = () => {
  return {
    type: SessionActionTypes.END_SAVE_USER,
  };
};

export const loadUser = (userID: number, postAuthTicket: string) => {
  return (dispatch) => {
    return dispatch(UserRequestActionCreators.loadUser(userID, postAuthTicket))
      .then((user) => {
        return dispatch(setUser(user));
      })
      .catch(() => {});
  };
};

/**
 * Returns a thunk that saves the user
 * @param user The updated user object to save
 * @returns A thunk
 */
export const saveUser = (user: User) => {
  return (dispatch) => {
    dispatch(startSaveUser);
    return dispatch(UserRequestActionCreators.saveUser(user))
      .then((savedUser) => {
        return dispatch(updateUserFromSave(savedUser));
      })
      .catch(() => {})
      .finally(() => {
        dispatch(endSaveUser());
      });
  };
};

export const updateUser = (user: User | null) => {
  return (dispatch, getState) => {
    const currentUser = getState().session.user;

    if (user === null) {
      console.error('Attempted to update user but was null');
      return;
    }

    if (user.ID !== currentUser.ID) {
      console.error("Attempted to update user but users didn't have same ID");
      return;
    }

    dispatch(setUser(user));
  };
};

export const restoreSessionData = (sessionData: Record<string, any> | null) => {
  return (dispatch) => {
    if (sessionData === null) {
      // Null session data, user is not logged in
      dispatch(restore(null)); // Restore null means we're restoring no session
      return;
    }

    if (Object.hasOwnProperty.call(sessionData, 'userID') && Object.hasOwnProperty.call(sessionData, 'postAuthTicket')) {
      // Valid session data provided
      dispatch(startRestore());

      dispatch(UserRequestActionCreators.loadUser(sessionData.userID, sessionData.postAuthTicket))
        .then((user) => {
          return dispatch(restore(user));
        })
        .catch(() => {
          console.error('user restore failed');
          return dispatch(sessionRestoreFailed());
        });
    } else {
      throw new Error('Invalid session data from initial restore');
    }
  };
};

export const login = (email: string, password: string) => {
  return (dispatch) => {
    dispatch(startRestore());
    dispatch(UserRequestActionCreators.loginUser(email, password))
      .then((user) => {
        return dispatch(restore(user));
      })
      .catch(() => {
        console.error('login failed');
      });
  };
};

export const logout = (): ThunkAction<void, any, any, any> => {
  return (dispatch) => {
    // Manually fire this event, as otherwise analytics can't run before the redirect from logging out.
    dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.Logout, {}));
    dispatch({ type: SessionActionTypes.LOGOUT });
  };
};

export const saveUserPlantVarietes = (user: User, userPlantVarieties: UserPlantVarietySet) => {
  return saveUser({ ...user, plantVarieties: userPlantVarieties });
};

export const saveUserFavourites = (user: User, userFavouritePlants: Set<string>) => {
  return saveUser({ ...user, favouritePlants: userFavouritePlants });
};

export const favouritePlant = (user: User, plantCode: string) => {
  return saveUser({ ...user, favouritePlants: new Set([...user.favouritePlants, plantCode]) });
};

export const unfavouritePlant = (user: User, plantCode: string) => {
  const newFavourites = new Set([...user.favouritePlants]);
  newFavourites.delete(plantCode);

  return saveUser({ ...user, favouritePlants: newFavourites });
};

export const toggleFavouritePlant = (user: User, plantCode: string) => {
  if (user.favouritePlants.has(plantCode)) {
    return unfavouritePlant(user, plantCode);
  }

  return favouritePlant(user, plantCode);
};
