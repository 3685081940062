import { batchActions } from 'redux-batched-actions';
import { RequestActionCreators } from '@gi/react-requests';
import { RequestsUtils } from '@gi/request';

import { SuggestedArticlesActionCreators } from './suggested-articles-slice';

const LOAD_SUGGESTED_ARTICLES = 'LOAD_SUGGESTED_ARTICLES';
const DEFAULT__ARTICLE_COUNT = 5;
const MAX_ARTICLE_COUNT = 15;

export const loadSuggestedArticles = (count = DEFAULT__ARTICLE_COUNT) => {
  return (dispatch, getState, { services }) => {
    const state = getState();
    const date = new Date();
    const { user } = state.session;

    if (RequestsUtils.hasRequest(state.requests, LOAD_SUGGESTED_ARTICLES)) {
      return;
    }

    dispatch(RequestActionCreators.requestStart(LOAD_SUGGESTED_ARTICLES));
    services.suggestedArticleService
      .getArticles(user, date, Math.min(MAX_ARTICLE_COUNT, count))
      .then((articles) => {
        dispatch(batchActions([SuggestedArticlesActionCreators.setArticles(articles), RequestActionCreators.requestComplete(LOAD_SUGGESTED_ARTICLES)]));
      })
      .catch((err) => {
        dispatch(RequestActionCreators.requestFail(LOAD_SUGGESTED_ARTICLES, err));
      });
  };
};
