/**
 * @typedef {string} ModalType
 */
/**
 * Enum for the different modal types
 *
 * @readonly
 * @enum {ModalType}
 */

enum ModalType {
  Default = 'Default',
  Warning = 'Warning',
  Error = 'Error',
}

export default ModalType;
