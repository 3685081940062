import React, { useContext } from 'react';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

interface iProps {
  count: number;
}

const CountsCell = ({ count }: iProps): JSX.Element | null => {
  const { columns } = useContext(GlobalPlantListContext);

  if (!columns.counts) {
    return null;
  }

  return (
    <td className='counts-td cell-with-label'>
      <div className='cell-label'>Quantity</div>
      <div className='cell-content'>{count.toLocaleString()}</div>
    </td>
  );
};

export default CountsCell;
