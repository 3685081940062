import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { DrawingToolsContext, PUNCTUATION_REGEX } from '@gi/drawing-tools';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { ResourceContext } from '@gi/resource-provider';
import { Anchor } from '@gi/mobile-components';
import { SessionSelectors } from '@gi/react-session';
import { UserUtils } from '@gi/user';

import SearchbarWithFilters from '../searchbar-with-filters';
import PlantsFiltersDrawer from './plants-filters-drawer';
import { getActiveFilterCount } from './utils';

const TIMEOUT_TIME = 150;

interface iProps {
  anchor?: Anchor;
}

const PlantsDrawerSearchbar = ({ anchor = 'BOTTOM' }: iProps): JSX.Element => {
  const { plantSearchService } = useContext(ResourceContext);
  const { plantFilters, updatePlantFilters } = useContext(DrawingToolsContext);
  const user = useSelector(SessionSelectors.getUser);
  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);

  const [searchTerm, setSearchTerm] = useState<string>(plantFilters.filters.search.inputs.searchTerm);
  const [lastCommittedSearchTerm, setLastCommittedSearchTerm] = useState<string>(plantFilters.filters.search.inputs.searchTerm);
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);

  const searchTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    // Check if the search term in the filters has updated without us asking to.
    // If so, update our copy of the search term to match (the filter was probably reset).
    const searchTermFromFilter = plantFilters.filters.search.inputs.searchTerm;
    if (searchTermFromFilter !== lastCommittedSearchTerm) {
      setSearchTerm(searchTermFromFilter);
      setLastCommittedSearchTerm(searchTermFromFilter);
    }
  }, [plantFilters.filters.search.inputs.searchTerm]);

  useEffect(() => {
    const searchUpdateTimeout = () => {
      setLastCommittedSearchTerm(searchTerm);
      updatePlantFilters({
        search: {
          searchTerm,
          searchResults: plantSearchService.search(searchTerm.replaceAll(PUNCTUATION_REGEX, '')),
        },
        sort: {
          enabled: searchTerm.length <= 1,
        },
      });
    };

    clearTimeout(searchTimeout.current!);
    searchTimeout.current = setTimeout(searchUpdateTimeout, TIMEOUT_TIME);

    return () => {
      clearTimeout(searchTimeout.current!);
    };
  }, [searchTerm]);

  return (
    <>
      <SearchbarWithFilters
        value={searchTerm}
        onChange={(value) => setSearchTerm(value)}
        onFiltersClick={() => setShowFiltersDrawer(true)}
        activeFilterCount={getActiveFilterCount(plantFilters, sfgMode, user ? UserUtils.isTropicalClimate(user) : true)}
      />
      <PlantsFiltersDrawer open={showFiltersDrawer} onClose={() => setShowFiltersDrawer(false)} anchor={anchor} />
    </>
  );
};

export default PlantsDrawerSearchbar;
