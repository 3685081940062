import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CanvasSelectors } from '@gi/react-garden-canvas';
import { PlannerTabTypes } from '@gi/constants';
import { FadedScrollContainer } from '@gi/mobile-components';
import { PlannerActionCreators } from '@gi/app-planner-slice';
import { FeatureGate } from '@gi/features';

import useActivePlannerTab from '../../../util/useActivePlannerTab';

import styles from './mobile-plan-menu-tabs.module.css';

const TabNameMapping = {
  [PlannerTabTypes.PLANNER]: 'Plan',
  [PlannerTabTypes.PLANT_LIST]: 'Plant List',
  [PlannerTabTypes.PARTS_LIST]: 'Parts List',
  [PlannerTabTypes.SHOPPING]: 'Shopping',
  [PlannerTabTypes.NOTES]: 'Notes',
};

interface iTabProps {
  tab: PlannerTabTypes;
  activeTab: PlannerTabTypes;
  setActiveTab: (tab: PlannerTabTypes) => void;
}

const MobilePlanMenuTab = ({ tab, activeTab, setActiveTab }: iTabProps): JSX.Element => {
  const classNames: string[] = [styles.tabButton];
  if (tab === activeTab) {
    classNames.push(styles.active);
  }

  return (
    <button className={classNames.join(' ')} type='button' onClick={() => setActiveTab(tab)}>
      {TabNameMapping[tab]}
    </button>
  );
};

const MobilePlanMenuTabs = (): JSX.Element | null => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const activePlannerTab = useActivePlannerTab();
  const dispatch = useDispatch();

  const setActiveTab = useCallback(
    (activeTab: PlannerTabTypes) => {
      if (activePlan) {
        dispatch(PlannerActionCreators.setActivePlannerTab({ planID: activePlan.id, tabID: activeTab }));
      }
    },
    [activePlan]
  );

  if (!activePlan) {
    return null;
  }

  return (
    <div className={styles.container}>
      <FadedScrollContainer isHorizontal hideScrollbars>
        <div className={styles.scrollList}>
          <MobilePlanMenuTab tab={PlannerTabTypes.PLANNER} activeTab={activePlannerTab} setActiveTab={setActiveTab} />
          <MobilePlanMenuTab tab={PlannerTabTypes.PLANT_LIST} activeTab={activePlannerTab} setActiveTab={setActiveTab} />
          <MobilePlanMenuTab tab={PlannerTabTypes.PARTS_LIST} activeTab={activePlannerTab} setActiveTab={setActiveTab} />
          <FeatureGate featureId='SHOPPING'>
            <MobilePlanMenuTab tab={PlannerTabTypes.SHOPPING} activeTab={activePlannerTab} setActiveTab={setActiveTab} />
          </FeatureGate>
          <MobilePlanMenuTab tab={PlannerTabTypes.NOTES} activeTab={activePlannerTab} setActiveTab={setActiveTab} />
        </div>
      </FadedScrollContainer>
    </div>
  );
};

export default MobilePlanMenuTabs;
