import React from 'react';

import styles from './guru-logo.module.css';

const GuruLogo = (): JSX.Element => {
  return (
    <div className={styles.guruLogo}>
      <div className={styles.logoContainer}>
        <i className={`icon-guru ${styles.logoIcon}`} />
      </div>
      <div className={styles.logoText}>
        <div>Garden Guru</div>
      </div>
    </div>
  );
};

export default GuruLogo;
