import { batchActions } from 'redux-batched-actions';
import { generateNotificationID, NotificationActionCreators, PremadeNotifications } from '@gi/notifications';

import * as RequestActionCreators from '../request-action-creators';

import { createLoadPlanRequestKey, createSavePlanRequestKey } from './request-key-creators';

export const loadPlan = (planID, planService) => {
  return (dispatch, getState) => {
    const notificationID = generateNotificationID();
    const requestKey = createLoadPlanRequestKey(planID);

    const user = getState().session.user;

    if (user === null) {
      // fail
      dispatch(NotificationActionCreators.createDefaultNotification(PremadeNotifications.loadingPlanFailStart(notificationID)));
      return new Promise((resolve, reject) => {
        reject(new Error('Unauthorised'));
      }); // TODO - better error
    }

    dispatch(
      batchActions([
        RequestActionCreators.requestStart(requestKey),
        NotificationActionCreators.createDefaultNotification(PremadeNotifications.loadingPlanStart(notificationID)),
      ])
    );

    return planService
      .loadPlan(planID)
      .then((savedUser) => {
        dispatch(
          batchActions([
            RequestActionCreators.requestComplete(requestKey),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: PremadeNotifications.loadingPlanSuccessUpdate,
            }),
          ])
        );

        return savedUser;
      })
      .catch((err) => {
        console.error(err);
        if (err && err.clientMessage) {
          console.error(err.clientMessage);
        }

        dispatch(
          batchActions([
            RequestActionCreators.requestFail(requestKey, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: PremadeNotifications.loadingPlanFailUpdate,
            }),
          ])
        );

        throw err;
      });
  };
};

export const savePlan = (plan) => {
  return (dispatch, getState, { services }) => {
    const notificationID = generateNotificationID();
    const requestKey = createSavePlanRequestKey(plan.id);
    const user = getState().session.user;

    if (user === null) {
      // fail
      dispatch(NotificationActionCreators.createDefaultNotification(PremadeNotifications.savePlanFailStart(notificationID)));
      return new Promise((resolve, reject) => {
        reject(new Error('Unauthorised'));
      }); // TODO - better error
    }

    dispatch(
      batchActions([
        RequestActionCreators.requestStart(requestKey),
        NotificationActionCreators.createDefaultNotification(PremadeNotifications.savePlanStart(notificationID)),
      ])
    );

    return services.userService
      .savePlan(plan)
      .then((savedUser) => {
        dispatch(
          batchActions([
            RequestActionCreators.requestComplete(requestKey),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: PremadeNotifications.savePlanSuccessUpdate,
            }),
          ])
        );

        return savedUser;
      })
      .catch((err) => {
        console.error(err);
        if (err && err.clientMessage) {
          console.error(err.clientMessage);
        }

        dispatch(
          batchActions([
            RequestActionCreators.requestFail(requestKey, err),
            NotificationActionCreators.updateNotificationByID({
              notificationID,
              update: PremadeNotifications.savingProfileFailUpdate,
            }),
          ])
        );

        throw err;
      });
  };
};

// TODO - create plan
