import React from 'react';
import PlannerIcon from './planner-icon';

import './planner-icon-with-family.scss';

interface iProps {
  className?: string;
  code: string;
  familyID: number;
}

const PlannerIconWithFamily = ({ className = '', code, familyID }: iProps): JSX.Element => {
  return (
    <span className={`${className} planner-icon-with-family planner-icon-family-${familyID}`}>
      <PlannerIcon code={code} />
    </span>
  );
};

export default PlannerIconWithFamily;
