import networkConfig from '../network-config';

/**
 * TEST MODE
 * During test, we should load the config normally, as if it was production.
 * We also allow the client to be overridden in the query parameter with a client Id `?clientId=`,
 *  to make testing easier.
 */
const params = new URLSearchParams(window.location.search);
const clientId = params.get('clientId') ?? null;
const path = clientId === null ? networkConfig.runtimeConfigURL : `${networkConfig.runtimeConfigURL}?clientid=${clientId}`;

// const configHostnameMap = {
//   'test.growveg.com': fileName,
//   'dev.growveg.com': fileName,
//   'test-almanac.growveg.com': 'config.almanac.json',
//   'test-charlesdowding.growveg.com': 'config.charlesdowding.json',
//   'test-motherearthnews.growveg.com': 'config.men.json',
//   'test-selfsufficientme.growveg.com': 'config.selfsufficientme.json',
//   'test-teamgrow.growveg.com': 'config.teamgrow.json',
//   'test-territorialseed.growveg.com': 'config.territorialseed.json',
// };

function getConfigFilename(): string {
  // if (configHostnameMap[window.location.hostname]) {
  //   return configHostnameMap[window.location.hostname];
  // }

  return path;

  console.warn(`Invalid hostname found when looking for config: ${window.location.hostname}`);
  return 'config.json';
}

export default getConfigFilename;
