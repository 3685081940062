import { createSlice } from '@reduxjs/toolkit';

export type OpenPlanModalSlice = {
  open: boolean;
};

const DEFAULT_STATE: OpenPlanModalSlice = {
  open: false,
};

export const openPlanModalSlice = createSlice({
  name: 'open-plan-modal',
  initialState: DEFAULT_STATE,
  reducers: {
    open: (state) => {
      state.open = true;
    },
    close: (state) => {
      state.open = false;
    },
  },
});

export const openPlanModalReducer = openPlanModalSlice.reducer;
export const OpenPlanModalActionCreators = openPlanModalSlice.actions;

interface RootState {
  openPlanModal: OpenPlanModalSlice;
}

export const OpenPlanModalSelectors = {
  getOpenPlanModal: (state: RootState) => state.openPlanModal,
};
