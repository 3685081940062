import React, { useContext } from 'react';

import { LocalMenuDropdownContext } from './menu-dropdown';
import MenuButton from '../menu-button';

/** Menu button to trigger a dropdown */
const MenuDropdownButton = (...[{ children, ...remainingProps }]: Parameters<typeof MenuButton>): JSX.Element => {
  const { refs, getReferenceProps } = useContext(LocalMenuDropdownContext);

  return (
    <MenuButton ref={refs.setReference} {...getReferenceProps(remainingProps)}>
      {children}
    </MenuButton>
  );
};

export default MenuDropdownButton;
