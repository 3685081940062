import { Filter } from '@gi/filters';

import { PlantListAreaGroup } from '../../types/plant-list-types';

type HideInGroundAllFilterState = {
  enabled: boolean;
};

const hideInGroundAllFilter: Filter<PlantListAreaGroup, HideInGroundAllFilterState> = {
  name: 'Plant/Variety Name',
  inputs: {
    enabled: false,
  },
  createListFilter: ({ enabled }) => {
    return (plantListAreaGroups) => {
      if (!enabled) {
        return plantListAreaGroups;
      }

      return plantListAreaGroups.filter((plag) => !plag.planPlant.inGroundAll);
    };
  },
};

export default hideInGroundAllFilter;
