import React from 'react';
import HeroPreviewPip from './hero-preview-pip';

import styles from './hero-preview-components.module.css';

interface iProps {
  index: number;
  setIndex: (i: number) => void;
  count: number;
}

const Pips = ({ index, setIndex, count }: iProps) => {
  const pips: React.ReactNode[] = [];

  for (let i = 0; i < count; i++) {
    pips.push(<HeroPreviewPip key={i} index={i} active={index === i} setIndex={setIndex} />);
  }

  return (
    <div className={styles.pips}>
      <button type='button' className={styles.pipTrackButton} onClick={() => setIndex(index > 0 ? index - 1 : index + count - 1)}>
        <i className='icon-angle-left' />
      </button>
      {pips}
      <button type='button' className={styles.pipTrackButton} onClick={() => setIndex(index + 1)}>
        <i className='icon-angle-right' />
      </button>
    </div>
  );
};

export default Pips;
