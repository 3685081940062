import React from 'react';

import './sidebar-item-title.scss';

interface iProps {
  small?: boolean;
  children: React.ReactNode;
}

const SidebarItemTitle = ({ small = false, children }: iProps) => {
  return <div className={`sidebar-item-title ${small ? 'sidebar-item-title-small' : ''}`}>{children}</div>;
};

export default SidebarItemTitle;
