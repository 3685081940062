import React, { ReactNode } from 'react';
import './page.scss';

interface iProps {
  children: ReactNode;
  className?: string;
  centerContent?: boolean;
}

const Page = ({ children, className, centerContent }: iProps): JSX.Element => {
  const classNames = ['page'];
  if (centerContent) {
    classNames.push('center-content');
  }
  if (className) {
    classNames.push(className);
  }
  return <main className={classNames.join(' ')}>{children}</main>;
};

export default Page;
