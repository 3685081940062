import moment, { Moment } from 'moment-timezone';

/**
 * Gets a human-readable day name
 * @param {time} timestamp
 * @param {string} [defaultFormat=dddd] moment format string to use if day is not yesterday, today, or tomorrow
 * @returns {string} 'yesterday', 'today', 'tomorrow', or else formatted by defaultFormat
 */
export function getDayName(timestamp: Moment, defaultFormat: string = 'dddd'): string {
  const mo = moment(timestamp);
  const dayRelativeToToday = mo.startOf('day').diff(moment().startOf('day'), 'days');

  const relativeDayName = {
    '-1': 'Yesterday',
    0: 'Today',
    1: 'Tomorrow',
  };

  return relativeDayName[dayRelativeToToday] || mo.format(defaultFormat);
}
