import React, { ReactNode } from 'react';
import { GuruPreviewItem } from '@gi/app-guru-types';

import GuruSection from '../../guru-section/guru-section';
import GuruSectionTitle from '../../guru-section/guru-section-title';
import SmallPreviewRowMobile from './small-preview-row-mobile';

import styles from './small-preview-row.module.css';

interface iProps {
  title: ReactNode;
  items?: GuruPreviewItem[];
  minItemHeight?: number; // Needs default? (default is 150 within SmallPreviewRowMobile. undefined here uses that default)
  itemAspectRatio?: number;
  gap?: number;
  loading?: boolean;
  hideWhenEmpty?: boolean; // Will hide there are no items to show (when loading and when a response has empty content)
  className?: string;
  removeSection?: boolean;
}

const SmallPreviewRow = ({
  items,
  title,
  minItemHeight,
  itemAspectRatio,
  gap,
  loading = false,
  hideWhenEmpty = false,
  className,
  removeSection,
}: iProps): JSX.Element | null => {
  if (hideWhenEmpty && !loading) {
    if (!items || items.length === 0) {
      return null;
    }
  }

  const content = (
    <>
      <GuruSectionTitle>{title}</GuruSectionTitle>
      <SmallPreviewRowMobile items={items} gap={gap} itemAspectRatio={itemAspectRatio} minItemHeight={minItemHeight} loading={loading} />
    </>
  );

  return removeSection ? <div className={styles.smallPreviewRowContainer}>{content}</div> : <GuruSection className={className}>{content}</GuruSection>;
};

export default SmallPreviewRow;
