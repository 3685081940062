import { useEffect, useState } from 'react';

interface iProps extends google.maps.MarkerOptions {
  onPositionChanged?: (position: google.maps.LatLngLiteral) => void;
}

/**
 * A marker to go on a Google Map
 */
const GoogleMapMarker = ({ onPositionChanged, ...markerOptions }: iProps): null => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [eventListeners, setEventListeners] = useState<google.maps.MapsEventListener[]>([]);

  /**
   * Create the marker on startup
   */
  useEffect(() => {
    if (!marker) {
      setMarker(
        new google.maps.Marker({
          cursor: 'move',
        })
      );
    }

    // Remove marker on desroy
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  /**
   * Attach event listeners to the map marker whenever it's available/changes (should never change)
   */
  useEffect(() => {
    if (marker) {
      // Remove existing listeners
      eventListeners.forEach((listener) => google.maps.event.removeListener(listener));

      const updatePos = () => {
        const pos = marker.getPosition();
        if (pos && onPositionChanged) {
          onPositionChanged(pos.toJSON());
        }
      };

      // Event listener for when marker position changes
      // We can't use position_changed, as that also fires when we manually set the position
      const dragStartEventListener = marker.addListener('dragstart', updatePos);
      const dragEventListener = marker.addListener('drag', updatePos);
      const dragEndEventListener = marker.addListener('dragend', updatePos);

      // Store the new event listeners
      setEventListeners([dragStartEventListener, dragEventListener, dragEndEventListener]);
    }
  }, [marker]);

  /**
   * Send the options to the marker whenever they update
   */
  useEffect(() => {
    if (marker) {
      marker.setOptions(markerOptions);
    }
  }, [marker, markerOptions]);

  return null;
};

export default GoogleMapMarker;
