import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PublishPlanModal } from '@gi/react-plan-publisher';
import { withGardenCanvas, CanvasSelectors } from '@gi/react-garden-canvas';
import { withResources } from '@gi/resource-provider';

import { closeModal } from '../modal-action-creators.ts';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    plan: CanvasSelectors.getActivePlan(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withResources(withGardenCanvas(PublishPlanModal)));
