import React from 'react';

import styles from './planner-app-scroll-pane.module.css';

interface iProps {
  className?: string;
  children: React.ReactNode;
}

const PlannerAppScrollPane = ({ className, children }: iProps): JSX.Element => {
  const classNames = [styles.plannerAppScrollPane];
  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default PlannerAppScrollPane;
