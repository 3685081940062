import React, { useContext } from 'react';
import { ResourceContext } from '@gi/resource-provider';

import styles from './shopping-list-content.module.css';

const ShoppingListIntroduction = (): JSX.Element | null => {
  const { store } = useContext(ResourceContext);

  if (!store) {
    return null;
  }

  return (
    <div className={styles.highlightedText}>
      The Shopping tool helps you buy seeds and garden objects from the items on your plan from the {store.details.storeName}. You can add the items below to
      your cart, customize them as required and then continue to the store website to review your cart and complete the transaction.
    </div>
  );
};

export default ShoppingListIntroduction;
