import moment from 'moment-timezone';
import Forecast from '../forecast';

export type ForecastAPIDayObject = {
  date: string;
  summary: string;
  icon: string;
  moonPhase: number;
  precipProbability: number;
  temperatureMin: number;
  temperatureMax: number;
  time: number;
  windSpeed: number;
  windBearing: number;
};

export type ForecastAPIObject = {
  currently: {
    summary: string;
    icon: string;
    precipProbability: number;
    temperature: number;
  };
  daily: {
    data: ForecastAPIDayObject[];
  };
};

const forecastFromAPI = (forecastObject: ForecastAPIObject): Forecast => {
  const source = forecastObject.daily.data[0];
  return {
    windSpeed: source.windSpeed,
    windBearing: source.windBearing,
    datetime: moment(source.time * 1000),
    summary: source.summary,
    icon: source.icon,
    moonPhase: source.moonPhase,
    maxTemp: source.temperatureMax,
    minTemp: source.temperatureMin,
  };
};

export { forecastFromAPI };
