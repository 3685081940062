import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CanvasActionCreators, CanvasSelectors } from '@gi/react-garden-canvas';
import { RequestSelectors, RequestUtils } from '@gi/react-requests';
import { PlanSetUtils } from '@gi/plan';

import EditPlantNotesModal from './edit-plant-notes-modal';
import { EditPlantNotesActions, EditPlantNotesSelectors } from './edit-plant-notes-slice';

/**
 * Controls whether the edit plant notes modal should be rendered. Handles saving of plant notes once they're editing
 * and closing the modal once saved.
 *
 * TODO: Error handling
 */
const EditPlantNotesModalRenderer = () => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState<boolean>(false);
  const { open, planID, plantCode, varietyName } = useSelector(EditPlantNotesSelectors.getState);
  const requests = useSelector(RequestSelectors.getRequests);
  const plans = useSelector(CanvasSelectors.getPlans);

  const planBusy = planID === null ? false : RequestUtils.getPlanIsBusy(requests, planID);

  useEffect(() => {
    // Close modal when saving is done
    if (saving && !planBusy) {
      setSaving(false);
      dispatch(EditPlantNotesActions.close());
    }
  }, [saving, planBusy]);

  const plan = useMemo(() => {
    if (planID === null) {
      return null;
    }

    return PlanSetUtils.planSetGetPlan(plans, planID);
  }, [plans, planID]);

  if (!open) {
    return null;
  }

  if (plan === null || plantCode === null || varietyName === null) {
    // Open is true but some data is null
    console.error('Plant notes edit modal should be open');
    return null;
  }

  const onSave = (updatedPlan) => {
    setSaving(true);
    dispatch(CanvasActionCreators.updatePlan(updatedPlan));
    dispatch(CanvasActionCreators.savePlan(updatedPlan));
  };

  return (
    <EditPlantNotesModal
      plan={plan}
      plantCode={plantCode}
      varietyName={varietyName}
      closeModal={() => {
        dispatch(EditPlantNotesActions.close());
      }}
      onSave={onSave}
      loading={planBusy}
    />
  );
};

export default EditPlantNotesModalRenderer;
