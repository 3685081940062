import React from 'react';

import { PlanBackgroundImage } from '@gi/plan';

import ImagePreviewForm from './parts/image-preview-form';
import BackgroundPropertiesForm from './parts/background-properties-form';
import BackgroundVisibilityForm from './parts/background-visibility-form';

import styles from './styles.module.css';

interface iProps {
  backgroundImage: PlanBackgroundImage;
  onViewPlan?: () => void;
}

const EditBackgroundPropertiesForm = ({ backgroundImage, onViewPlan }: iProps): JSX.Element => {
  return (
    <div className={styles.container}>
      {/* Image Preview */}
      <ImagePreviewForm backgroundImage={backgroundImage} />
      {/* Visibility/opacity settings */}
      <BackgroundVisibilityForm />
      {/* Background properties form */}
      <BackgroundPropertiesForm backgroundImage={backgroundImage} onViewPlan={onViewPlan} />
    </div>
  );
};

export default EditBackgroundPropertiesForm;
