import React from 'react';

interface iProps {
  className?: string;
  children: React.ReactNode;
}

const DashboardItemSectionTitle = ({ children, className = '' }: iProps): JSX.Element => {
  return <div className={`dashboard-item-section-title ${className}`}>{children}</div>;
};

export default DashboardItemSectionTitle;
