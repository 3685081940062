import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LayerDisplayModes } from '@gi/constants';
import { CanvasActionCreators, CanvasSelectors } from '@gi/react-garden-canvas';

import OverlayToolbarButton from '../../overlay-toolbar-button';

import './lock-toolbar-button.scss';

const LockToolbarButton = (): JSX.Element => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);
  const lastSelectedLayer = useRef<LayerDisplayModes>(LayerDisplayModes.ALL);
  const ref = useRef<HTMLSpanElement>(null);
  const dispatch = useDispatch();

  /**
   * Returns true if layers are currently locked
   */
  const isLocked = useMemo(() => {
    if (!activePlan) {
      return false;
    }
    return activePlan.plannerSettings.layer === LayerDisplayModes.VIEW_ONLY;
  }, [activePlan]);

  /**
   * Store the last-used layer so we can switch back to it after unlocking
   */
  useEffect(() => {
    if (!activePlan) {
      return;
    }
    if (activePlan.plannerSettings.layer !== LayerDisplayModes.VIEW_ONLY) {
      lastSelectedLayer.current = activePlan.plannerSettings.layer;
    }
  }, [activePlan]);

  /**
   * Toggles layer lock on/off
   */
  const toggleLock = useCallback(() => {
    if (!activePlan) {
      return;
    }
    if (activePlan.plannerSettings.layer !== LayerDisplayModes.VIEW_ONLY) {
      dispatch(CanvasActionCreators.setLayer(activePlan, LayerDisplayModes.VIEW_ONLY));
    } else {
      dispatch(CanvasActionCreators.setLayer(activePlan, lastSelectedLayer.current));
    }
  }, [activePlan]);

  /**
   * Calcualtes how wide the Layers Locked text should be so it can animate
   */
  const lockTextWidth = useMemo(() => {
    if (!isLocked) {
      return '0';
    }
    if (!ref.current) {
      return 'auto';
    }
    return ref.current.offsetWidth;
  }, [isLocked]);

  const buttonClassNames = ['lock-toolbar-button'];
  if (isLocked) {
    buttonClassNames.push('locked');
  }

  return (
    <OverlayToolbarButton onClick={toggleLock} className={buttonClassNames.join(' ')}>
      <i className={isLocked ? 'icon-lock' : 'icon-lock-open'} />
      <span className='locked-text' style={{ width: lockTextWidth }}>
        <span ref={ref}>Editing Locked</span>
      </span>
    </OverlayToolbarButton>
  );
};

export default LockToolbarButton;
