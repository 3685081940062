import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlannerActionCreators, PlannerSelectors } from '@gi/app-planner-slice';

import ItemLockingHelpModal from './item-locking-help-modal';

const ItemLockingHelpModalRenderer = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const showModal = useSelector(PlannerSelectors.getShowItemLockingHelpModal);

  const onItemLockingHelpModalClose = () => {
    dispatch(PlannerActionCreators.setShowItemLockingHelpModal(false));
  };

  if (!showModal) {
    return null;
  }

  return <ItemLockingHelpModal closeModal={onItemLockingHelpModalClose} />;
};

export default ItemLockingHelpModalRenderer;
