import React from 'react';

interface iProps {
  children: React.ReactNode;
}

const ModalFooterButtonsSection = ({ children }: iProps) => {
  return <div className='modal-footer-buttons-section'>{children}</div>;
};

export default ModalFooterButtonsSection;
