/**
 * @typedef {string} SubscriptionStatusType
 */

/**
 * Enum for the different subscription status values a user can have
 *
 * @readonly
 * @enum {SubscriptionStatusType}
 */
const SubscriptionStatusTypes = {
  PRE_FREE_TRIAL: 'PRE_FREE_TRIAL',
  FREE_TRIAL: 'FREE_TRIAL',
  FREE_TRIAL_ENDED: 'FREE_TRIAL_ENDED',
  SUBSCRIBED: 'SUBSCRIBED',
  NO_SUBSCRIPTION: 'NO_SUBSCRIPTION',
};

export default SubscriptionStatusTypes;
