import { State, StateDef } from '@gi/state';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import GraphicNode from '../../graphics-node';
import { Bounds, InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import ContentRootContext from '../../nodes/content-root/content-root-context';
import { bindState } from '../../utils/state-utils';

export type TransformComponentState = StateDef<{
  position: Vector2;
  scale: Vector2 | number;
  rotation: number;
  width: number;
  height: number;
  offset: Vector2;
  autoApply: boolean;
}>;

/**
 * Transform Component
 * Stores transformation information about a node.
 * Applies to the container of graphics node, if a graphics node is passed in.
 */
class TransformComponent extends NodeComponent {
  type = 'TransformComponent';

  readonly state: State<TransformComponentState>;

  // True if a state update occurs but was unable to apply the changes to the owner's container.
  // This means the container's state and our state are likely out of sync, so we need to update
  //  everything at earliest convenience.
  #needsUpdate: boolean = false;

  constructor(initialState: Partial<TransformComponentState['state']> = {}) {
    super();

    this.state = new State<TransformComponentState>({
      position: { x: 0, y: 0 },
      scale: { x: 1, y: 1 },
      rotation: 0,
      width: 0,
      height: 0,
      offset: { x: 0, y: 0 },
      autoApply: true,
      ...initialState,
    });
    bindState(this.state, this);

    this.state.addWatcher(
      (state) => {
        const { position, autoApply } = state.currentValues;
        if (!autoApply) {
          return;
        }
        if (this.owner instanceof GraphicNode && !this.owner.destroyed) {
          this.owner.getContainer().x = position.x;
          this.owner.getContainer().y = position.y;
          this.#checkNeedsUpdate();
        } else {
          this.#needsUpdate = true;
        }
      },
      { properties: ['position', 'autoApply'] }
    );

    this.state.addWatcher(
      (state) => {
        const { scale, autoApply } = state.currentValues;
        const finalScale = typeof scale === 'number' ? { x: scale, y: scale } : scale;
        if (!autoApply) {
          return;
        }
        if (this.owner instanceof GraphicNode && !this.owner.destroyed) {
          this.owner.getContainer().scale = finalScale;
          this.#checkNeedsUpdate();
        } else {
          this.#needsUpdate = true;
        }
      },
      { properties: ['scale', 'autoApply'] }
    );

    this.state.addWatcher(
      (state) => {
        const { rotation, autoApply } = state.currentValues;
        if (!autoApply) {
          return;
        }
        if (this.owner instanceof GraphicNode && !this.owner.destroyed) {
          this.#checkNeedsUpdate();
          this.owner.getContainer().rotation = rotation;
        } else {
          this.#needsUpdate = true;
        }
      },
      { properties: ['rotation', 'autoApply'] }
    );

    this.eventBus.on(NodeComponentEvent.DidBind, () => this.#checkNeedsUpdate());
  }

  #checkNeedsUpdate() {
    if (!this.#needsUpdate) {
      return;
    }
    const { position, scale, rotation, autoApply } = this.state.currentValues;
    const finalScale = typeof scale === 'number' ? { x: scale, y: scale } : scale;
    if (autoApply && this.owner instanceof GraphicNode && !this.owner.destroyed) {
      this.owner.getContainer().x = position.x;
      this.owner.getContainer().y = position.y;
      this.owner.getContainer().scale = finalScale;
      this.owner.getContainer().rotation = rotation;
      this.#needsUpdate = false;
    }
  }

  get localBounds(): Bounds {
    return {
      top: this.state.currentValues.position.y - this.state.currentValues.offset.y,
      left: this.state.currentValues.position.x - this.state.currentValues.offset.x,
      bottom: this.state.currentValues.position.y - this.state.currentValues.offset.y + this.state.currentValues.height,
      right: this.state.currentValues.position.x - this.state.currentValues.offset.x + this.state.currentValues.width,
    };
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
    {
      type: InspectableClassDataType.Action,
      displayName: 'Look At',
      callback: () => {
        if (!this.owner) {
          return;
        }

        const camera = this.owner.getContext(ContentRootContext).activeCamera;
        if (!camera) {
          return;
        }

        camera.lookAt(this.state.values.position);
      },
    },
  ];
}

export default TransformComponent;
