import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Plan from '@gi/plan';
import { useDebounce } from '@gi/react-utils';
import { CanvasActionCreators } from '@gi/react-garden-canvas';

interface UsePlanNotesOptions {
  plan: Plan | null;
  noAutoSync?: boolean;
}

interface UsePlanNotesReturns {
  notes: string;
  setNotes: (notes: string) => void;
  syncNotes: () => void;
}

const usePlanNotes = (plan: Plan | null, options: Partial<UsePlanNotesOptions> = {}) => {
  const dispatch = useDispatch();
  const needsSync = useRef<boolean>(false);
  const [internalValue, setInternalValue] = useState<string>(plan?.notes ?? '');

  /** Debounce notes so we wait for a period of at least 300ms before sending changes back to the plan model */
  const debouncedInternalValue = useDebounce(internalValue, 300);

  const onChange = useCallback((value: string) => {
    needsSync.current = true;
    setInternalValue(value);
  }, []);

  /** Function to manually sync the current value to the plan notes */
  const syncNotes = useCallback(() => {
    if (plan === null) {
      return;
    }
    if (internalValue !== plan.notes) {
      dispatch(
        CanvasActionCreators.updatePlan({
          ...plan,
          notes: internalValue,
        })
      );
      needsSync.current = false;
    }
  }, [plan?.id, plan?.notes, internalValue]);

  /** Sync notes changes back to the plan (debounced for the sake of the store) */
  useEffect(() => {
    if (plan === null || options.noAutoSync) {
      return;
    }
    if (internalValue !== plan.notes && needsSync.current) {
      dispatch(
        CanvasActionCreators.updatePlan({
          ...plan,
          notes: internalValue,
        })
      );
      needsSync.current = false;
    }
  }, [debouncedInternalValue, options.noAutoSync]);

  /** Cancel any pending syncs if the plan ID changes (we've switched plans somehow, notes are now out-of-date) */
  useEffect(() => {
    needsSync.current = false;
  }, [plan?.id]);

  /** Update the notes from the plan if they update externally and we're not awaiting a sync */
  useEffect(() => {
    if (!needsSync.current) {
      setInternalValue(plan?.notes ?? '');
    }
  }, [plan?.notes]);

  return useMemo<UsePlanNotesReturns>(
    () => ({
      notes: internalValue,
      setNotes: onChange,
      syncNotes,
    }),
    [internalValue, syncNotes]
  );
};

export default usePlanNotes;
