import Plant from '@gi/plant';
import React from 'react';
import { CompanionPlant } from '@gi/edit-plant-modal';
import { CompanionIcon } from '@gi/plant-icon';
import PlannerIcon from '@gi/planner-icon';

export const COMPANION_TYPES = {
  HELPED: 0,
  HELPS: 1,
  MUTUAL: 2,
};

interface iCompanionsSectionProps {
  companionPlants: Plant[];
  companionType: number;
  plant: Plant;
}

const PlantCompanionsSection = ({ companionPlants, companionType, plant }: iCompanionsSectionProps): JSX.Element | null => {
  if (companionPlants.length === 0) {
    return null;
  }

  const companions = companionPlants.map((companionPlant) => <CompanionPlant key={companionPlant.code} plant={companionPlant} />);

  const isHelper = companionType === COMPANION_TYPES.MUTUAL || companionType === COMPANION_TYPES.HELPED;
  const isHelped = companionType === COMPANION_TYPES.MUTUAL || companionType === COMPANION_TYPES.HELPS;

  let title = '';
  if (companionType === COMPANION_TYPES.MUTUAL) {
    title = 'Mutually beneficial';
  } else if (companionType === COMPANION_TYPES.HELPED) {
    title = 'Benefit this plant';
  } else if (companionType === COMPANION_TYPES.HELPS) {
    title = 'This plant benefits';
  }

  return (
    <tr className='plan-table-row'>
      <th className='plan-table-row-title companion-title'>{title}</th>
      <td className='companion-display-cell'>
        <div className='companion-display'>
          <div className='companions-section'>{companions}</div>
          <div className='companions-section-icon'>
            <CompanionIcon isHelper={isHelper} isHelped={isHelped} caption={`Companion Planting: ${title}`} />
            <PlannerIcon code={plant.code} className='plant-icon' />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PlantCompanionsSection;
