import {
  DEFAULT_PLAN_GARDEN_OBJECT_OPTIONS,
  DEFAULT_PLAN_OPTIONS,
  DEFAULT_PLAN_PLANT_OPTIONS,
  DEFAULT_PLAN_SHAPE_OPTIONS,
  DEFAULT_PLAN_TEXT_OPTIONS,
  OptionsFieldParser,
  OptionsFormatRegex,
} from './plan-options-parser';
import { PlanOptionsParserV1 } from './plan-options-parser-v1';

/** Object containing all our parsers, mapped from version number -> parser */
const OptionsFieldParsers: Record<number, OptionsFieldParser> = {
  [PlanOptionsParserV1.version]: PlanOptionsParserV1,
};

/** Version number of the latest parser. Will be used for stringify functions */
const LATEST_PARSER = PlanOptionsParserV1.version;

/**
 * Extracts the version number and content from an unparsed `options` string. Returns null if the format is unrecognised.
 * @param optionsString The options string form the API
 * @returns The version number and content from the string, or null if invalid format
 */
export function getOptionsFieldVersion(optionsString: string): { version: number; content: string } | null {
  const result = OptionsFormatRegex.exec(optionsString);
  if (result === null) {
    return null;
  }
  return {
    version: parseInt(result[1], 10),
    content: result[2],
  };
}

/**
 * Returns a parser and content for the given options string. Returns null if  if the format/version is invalid
 * @param optionsString The options string from the API
 * @returns A parser and content , or null
 */
export function getOptionsFieldParser(optionsString: string): { parser: OptionsFieldParser; content: string } | null {
  const result = getOptionsFieldVersion(optionsString);
  if (!result || OptionsFieldParsers[result.version] === undefined) {
    return null;
  }
  return {
    parser: OptionsFieldParsers[result.version],
    content: result.content,
  };
}

/** The main parser, acts as a proxy to make sure options are parsed by the correct parser version */
export const PlanOptionsParser: OptionsFieldParser = {
  version: LATEST_PARSER,
  parsePlanOptions: (optionsString) => {
    const result = getOptionsFieldParser(optionsString);
    return result ? result.parser.parsePlanOptions(result.content) : DEFAULT_PLAN_OPTIONS;
  },
  parsePlantOptions: (optionsString) => {
    const result = getOptionsFieldParser(optionsString);
    return result ? result.parser.parsePlantOptions(result.content) : DEFAULT_PLAN_PLANT_OPTIONS;
  },
  parseGardenObjectOptions: (optionsString) => {
    const result = getOptionsFieldParser(optionsString);
    return result ? result.parser.parseGardenObjectOptions(result.content) : DEFAULT_PLAN_GARDEN_OBJECT_OPTIONS;
  },
  parseShapeOptions: (optionsString) => {
    const result = getOptionsFieldParser(optionsString);
    return result ? result.parser.parseShapeOptions(result.content) : DEFAULT_PLAN_SHAPE_OPTIONS;
  },
  parseTextOptions: (optionsString) => {
    const result = getOptionsFieldParser(optionsString);
    return result ? result.parser.parseTextOptions(result.content) : DEFAULT_PLAN_TEXT_OPTIONS;
  },
  stringifyPlanOptions: OptionsFieldParsers[LATEST_PARSER].stringifyPlanOptions,
  stringifyPlantOptions: OptionsFieldParsers[LATEST_PARSER].stringifyPlantOptions,
  stringifyGardenObjectOptions: OptionsFieldParsers[LATEST_PARSER].stringifyGardenObjectOptions,
  stringifyShapeOptions: OptionsFieldParsers[LATEST_PARSER].stringifyShapeOptions,
  stringifyTextOptions: OptionsFieldParsers[LATEST_PARSER].stringifyTextOptions,
};
