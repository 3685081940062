const degToRad = (degrees: number): number => {
  return degrees / (180 / Math.PI);
};

const radToDeg = (radians: number): number => {
  return radians * (180 / Math.PI);
};

/**
 * Function to turn a float to an int, used to mimic behaviour of C# typecasting a float to an integer
 * https://stackoverflow.com/questions/13292148/casting-a-double-as-an-int-does-it-round-or-just-strip-digits
 *
 * @param {number} float
 * @returns {number}
 */
function toInt(float: number): number {
  return float - (float % 1);
}

const padHexString = (num: string): string => {
  const s = `000000${num}`;
  return s.substring(s.length - 6).toUpperCase();
};

const isWithin = (val: number, comparitor: number, dist: number): boolean => {
  return Math.abs(val - comparitor) < dist;
};

// Clamp number between two values with the following line:
function clamp(num: number, min: number, max: number): number {
  return Math.min(Math.max(num, min), max);
}

export { degToRad, radToDeg, padHexString, isWithin, toInt, clamp };
