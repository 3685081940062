import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import PlannerIcon from '@gi/planner-icon';
import GardenObject, { GardenObjectBlock, GardenObjectScalingMode } from '@gi/garden-object';
import { DistanceUnits } from '@gi/units';
import { Geometry, MathUtils } from '@gi/math';
import { FormValues, createFormValues } from '@gi/form-responsive';
import { PlanGardenObject, PlanValidation } from '@gi/plan';
import { GardenObjectType } from '@gi/constants';

import {
  ModalPane,
  ModalHeader,
  ModalPaneContainer,
  ModalFooter,
  ModalContent,
  ModalFooterButtonsSection,
  ModalFooterButtons,
  ModalCloseButton,
  ModalHeaderContent,
  ModalHeaderTitle,
  ModalHeaderSubtitle,
  ModalHeaderIcon,
  AttemptToLeaveCallback,
  ConfirmLeaveContextResult,
  ModalContext,
} from '@gi/modal';

import { GardenObjectState } from './types';
import EditGardenObjectModalFormScalable from './forms/edit-garden-object-modal-form-scalable';
import EditGardenObjectModalFormPath from './forms/edit-garden-object-modal-form-path';
import EditGardenObjectModalFormFixedSize from './forms/edit-garden-object-modal-form-fixed-size';
import EditGardenObjectModalFormPresets from './forms/edit-garden-object-modal-form-presets';

function getInternalDimensionsMargin(shape: GardenObjectBlock): Dimensions | null {
  if (shape.internalHeightMargin === 0 && shape.internalHeightMargin === 0) {
    return null;
  }
  return { width: shape.internalWidthMargin, height: shape.internalHeightMargin };
}

interface iProps {
  gardenObject: GardenObject;
  planGardenObject: PlanGardenObject;
  distanceUnits: DistanceUnits;
  onComplete: (planGardenObject: PlanGardenObject) => void;
}

const EditGardenObjectModalContent = ({ gardenObject, planGardenObject, distanceUnits, onComplete }: iProps): JSX.Element => {
  const { setCloseContextParams, attemptClose, close } = useContext(ModalContext);

  const [formValues, setFormValues] = useState<FormValues<GardenObjectState>>(
    createFormValues({
      start: { value: planGardenObject.start },
      mid: { value: planGardenObject.mid },
      end: { value: planGardenObject.end },
      center: { value: Geometry.midpoint(planGardenObject.start, planGardenObject.end) },
      rotation: { value: Math.round(planGardenObject.rotation * (180 / Math.PI)) },
      width: { value: planGardenObject.end.x - planGardenObject.start.x },
      height: { value: planGardenObject.end.y - planGardenObject.start.y },
      curved: { value: planGardenObject.mid !== null },
    })
  );

  /**
   * Submits the changes back outside the modal
   */
  const onSaveChanges = useCallback(() => {
    const { start, mid, end, rotation } = PlanValidation.validateGardenObjectProperties(
      formValues.values.start,
      formValues.values.mid,
      formValues.values.end,
      formValues.values.center,
      MathUtils.degToRad(formValues.values.rotation),
      formValues.values.width,
      formValues.values.height,
      formValues.values.curved,
      gardenObject
    );

    const updatedPlanGardenObject = {
      ...planGardenObject,
      start,
      mid,
      end,
      rotation,
    };

    onComplete(updatedPlanGardenObject);
  }, [onComplete, formValues, gardenObject, planGardenObject]);

  /**
   * Prevent navigating away with unsaved changes
   */
  useEffect(() => {
    const createLeaveCallback = (): AttemptToLeaveCallback => {
      return (cb) => {
        return cb(formValues.hasDifferences).then((result) => {
          if (result === ConfirmLeaveContextResult.SaveAndClose) {
            onSaveChanges();
          }

          return result !== ConfirmLeaveContextResult.Cancel;
        });
      };
    };

    const invalidMessage = formValues.isValid ? undefined : 'Some inputs have invalid values, please correct them before saving';

    setCloseContextParams({
      title: 'Save Changes?',
      text: 'Do you wish to save your changes to this Garden Object?',
      callback: createLeaveCallback(),
      invalidMessage,
    });

    return () => {
      setCloseContextParams(null);
    };
  }, [formValues.hasDifferences, formValues.isValid, onSaveChanges, setCloseContextParams]);

  const formContent = useMemo(() => {
    // Paths
    if (gardenObject.shape.type === GardenObjectType.PATH) {
      return <EditGardenObjectModalFormPath values={formValues} setValues={setFormValues} distanceUnits={distanceUnits} />;
    }
    // Fixed-size block garden objects
    if (gardenObject.shape.scalingMode === GardenObjectScalingMode.FIXED) {
      return <EditGardenObjectModalFormFixedSize values={formValues} setValues={setFormValues} distanceUnits={distanceUnits} />;
    }
    // Garden objects with presets
    if (gardenObject.shape.scalingMode === GardenObjectScalingMode.PRESETS && gardenObject.shape.presets) {
      return (
        <EditGardenObjectModalFormPresets values={formValues} setValues={setFormValues} distanceUnits={distanceUnits} presets={gardenObject.shape.presets} />
      );
    }
    // All remaining block garden objects
    const internalMargin = getInternalDimensionsMargin(gardenObject.shape);
    return (
      <EditGardenObjectModalFormScalable
        values={formValues}
        setValues={setFormValues}
        distanceUnits={distanceUnits}
        internalDimensionsMargin={internalMargin}
      />
    );
  }, [gardenObject, formValues, setFormValues, distanceUnits]);

  return (
    <ModalContent>
      <ModalCloseButton onClick={attemptClose} />
      <ModalHeader>
        <ModalHeaderIcon>
          <PlannerIcon code={gardenObject.code} />
        </ModalHeaderIcon>
        <ModalHeaderContent>
          <ModalHeaderTitle>Edit Garden Object</ModalHeaderTitle>
          <ModalHeaderSubtitle>{gardenObject.name}</ModalHeaderSubtitle>
        </ModalHeaderContent>
      </ModalHeader>
      <ModalPaneContainer>
        <ModalPane className='edit-garden-object-edit-pane'>{formContent}</ModalPane>
      </ModalPaneContainer>
      <ModalFooter>
        <ModalFooterButtons>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-secondary' onClick={close}>
              Cancel
            </button>
          </ModalFooterButtonsSection>
          <ModalFooterButtonsSection>
            <button type='button' className='button button-primary' onClick={onSaveChanges}>
              Done
            </button>
          </ModalFooterButtonsSection>
        </ModalFooterButtons>
      </ModalFooter>
    </ModalContent>
  );
};

export default EditGardenObjectModalContent;
