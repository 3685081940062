import React from 'react';

import styles from './small-preview.module.css';

interface iProps {
  children: React.ReactNode;
  className?: string;
}

const SmallPreview = ({ children, className = '' }: iProps): JSX.Element => {
  return <div className={`${styles.smallPreview} ${className}`}>{children}</div>;
};

export default SmallPreview;
