/* eslint-disable max-len */
import { User, UserPlanSetUtils, UserUtils } from '@gi/user';
import { RuntimeConfig } from '@gi/config';
import Country from '@gi/country';
import { DeviceDisplayMode } from '@gi/constants';

/**
 * Adds intercom script to the page
 */
export function addIntercomScript(appID: string): void {
  const scriptElm = document.createElement('script');

  const script = `
  var APP_ID = "${appID}";
  
  window.intercomSettings = {
    api_base: 'https://api-iam.eu.intercom.io',
  };

  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
  scriptElm.innerHTML = script;
  const head = document.getElementsByTagName('head')[0];
  if (head) {
    head.appendChild(scriptElm);
  }
}

// Intercom properties which won't be updated once they are set
interface iIntercomBoot {
  custom_launcher_selector: string;
  hide_default_launcher: boolean;
  app_id: string;
  email: string;
  user_id: number;
  user_hash: string;
  'Account Created': number;
  'Client ID': number;
  'Account Expires': number;
  Hostname: string;
  'Client Name': string;
  'WGP Version': string;
  'Feature Flags': string;
}

// Intercom properties which may be updated through general use of the app
export interface iIntercomUpdate {
  name: string;
  'No Of Plans': number;
  'Country Name': string;
  'Country Code': string;
  'Recurring Subscription': boolean;
  'Subscription Product': string;
  'Subscription State': string;
  Subscribed: boolean | null;
  'Email Confirmed': boolean;
  'Account Enabled': boolean;
  'Location Set': boolean;
  'Device Display Mode': DeviceDisplayMode;
}

export function initialiseIntercom(
  appID: string,
  user: User,
  country: Country,
  runtimeConfig: RuntimeConfig,
  displayMode: DeviceDisplayMode,
  featureFlags: string
): void {
  if (!window.Intercom) {
    console.warn('Intercom not available');
    return;
  }

  const intercomBootData: iIntercomBoot & iIntercomUpdate = {
    custom_launcher_selector: '#intercom-button',
    hide_default_launcher: true,
    app_id: appID,
    name: user.firstName,
    email: user.email,
    user_id: user.ID,
    user_hash: user.hash,
    'Account Created': Math.round(user.accountCreated.unix()),
    'Client ID': runtimeConfig.clientID,
    'No Of Plans': UserPlanSetUtils.planCount(user.plans),
    'Email Confirmed': user.emailConfirmed,
    'Account Enabled': user.accountEnabled,
    Subscribed: user.subscription.subscribed,
    'Account Expires': Math.round(user.subscription.accountExpires.unix()),
    'Country Code': user.countryCode,
    'Location Set': UserUtils.hasLocation(user),
    'Recurring Subscription': user.subscription.recurringSubscription,
    'Subscription Product': user.subscription.subscriptionProduct,
    'Subscription State': user.subscription.subscriptionState,
    'Country Name': country.name,
    Hostname: window.location.hostname,
    'Client Name': runtimeConfig.clientName,
    'WGP Version': VERSION,
    'Device Display Mode': displayMode,
    'Feature Flags': featureFlags,
  };

  window.Intercom('boot', intercomBootData);
}

export function getIntercomUpdate(user: User, country: Country, displayMode: DeviceDisplayMode): iIntercomUpdate {
  return {
    name: user.firstName,
    'No Of Plans': UserPlanSetUtils.planCount(user.plans),
    'Email Confirmed': user.emailConfirmed,
    'Account Enabled': user.accountEnabled,
    Subscribed: user.subscription.subscribed,
    'Country Code': user.countryCode,
    'Location Set': UserUtils.hasLocation(user),
    'Recurring Subscription': user.subscription.recurringSubscription,
    'Subscription Product': user.subscription.subscriptionProduct,
    'Subscription State': user.subscription.subscriptionState,
    'Country Name': country.name,
    'Device Display Mode': displayMode,
  };
}

export function updateIntercom(intercomUpdate: iIntercomUpdate): void {
  if (!window.Intercom) {
    console.warn('Intercom not available');
    return;
  }
  window.Intercom('update', intercomUpdate);
}
