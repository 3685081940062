import bugsnagInstance from '@gi/bugsnag-instance';
import type { RuntimeConfig } from '@gi/config';
import type { iErrorReporter } from './i-error-reporter';

class ErrorReporter implements iErrorReporter {
  // eslint-disable-next-line class-methods-use-this
  notify(message: string | Error, callback?: (event: unknown) => void) {
    bugsnagInstance.notify(message, callback);
  }

  // eslint-disable-next-line class-methods-use-this
  setClient(runtimeConfig: RuntimeConfig) {
    bugsnagInstance.setClient(runtimeConfig);
    console.log('Logger setting client', runtimeConfig.clientName);
  }
}

export default ErrorReporter;
