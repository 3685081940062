import React, { useCallback } from 'react';
import ReactSelect from 'react-select';

import { PickerButton } from '@gi/texture-picker';
import { presetPalettes } from '@gi/palette';
import { MAX_SHAPE_STROKE_WIDTH } from '@gi/constants';
import FormField, { FORM_FIELD_PRESETS, FormLayout, InputContainer } from '@gi/form-responsive';

import { SMALL_SELECT_STYLES } from '@gi/styles/react-select-styles';

import { DropdownOption, EditShapeModalFormProps, ShapeState } from '../../types';

import styles from './edit-shape-modal-stroke-properties-section.module.css';

const strokeWidths: DropdownOption[] = [];
for (let i = 1; i <= MAX_SHAPE_STROKE_WIDTH; i++) {
  strokeWidths.push({ value: i, label: i.toString() });
}

/**
 * Displays stroke colour and thickness inputs
 */
const StrokePropertiesFormSection = ({ values, setValues }: Omit<EditShapeModalFormProps, 'distanceUnits'>): JSX.Element => {
  const createSetter = useCallback(
    <K extends keyof ShapeState>(setting: K) =>
      (value: ShapeState[K]) => {
        setValues(values.setValue(setting, { value }));
      },
    [values, setValues]
  );

  return (
    <>
      <FormField label='Line Color' fakeLabel layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <PickerButton selectedSwatch={values.values.selectedSwatch} palettes={presetPalettes.colorPalettes} onSwatchSelect={createSetter('selectedSwatch')} />
      </FormField>
      <FormField label='Line Thickness' fakeLabel layoutPreset={FORM_FIELD_PRESETS.EditModal}>
        <FormLayout desktopLayout={{ layout: 'row', gap: 6 }} mobileLayout={{ layout: 'row', gap: 6 }}>
          <InputContainer>
            <ReactSelect<DropdownOption>
              styles={SMALL_SELECT_STYLES}
              options={strokeWidths}
              value={strokeWidths.find((option) => option.value === values.values.strokeWidth)}
              onChange={(option) => {
                if (option) {
                  createSetter('strokeWidth')(option.value);
                }
              }}
              menuPortalTarget={document.body}
              isSearchable={false}
            />
          </InputContainer>
          <div className={styles.strokePreview}>
            <span style={{ height: values.values.strokeWidth }} />
          </div>
        </FormLayout>
      </FormField>
    </>
  );
};

export default StrokePropertiesFormSection;
