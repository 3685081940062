import { RequestAuthMode, networkErrorReporter, networkService } from '@gi/gi-network';

import { parseTooltips } from './parse-tooltips';
import { ParsedTooltips, TooltipsFromAPI } from './types';

class TooltipService {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  async getTooltipContent(): Promise<ParsedTooltips> {
    const url = this.endpoint;

    return networkService
      .get<TooltipsFromAPI>(url, {}, RequestAuthMode.None)
      .then((response) => parseTooltips(response.body))
      .catch(networkErrorReporter('GET', 'tooltips'));
  }
}

export default TooltipService;
