import { HandleStoreChangeListener, StoreListener } from '@gi/redux-utils';

import { LoadingState } from '@gi/constants';

import { ObjectivesActionCreators, ObjectivesRootState } from './objectives-slice';
import { ADVANCED_ACCORDION_ID } from '../objective-types';

const createObjectivesStoreChangeHandler = (): HandleStoreChangeListener<ObjectivesRootState> => {
  return (previousState, currentState, store) => {
    const syncNewlyCompletedObjectives = () => {
      if (previousState.objectives.completedObjectivesMap !== currentState.objectives.completedObjectivesMap) {
        const { completedObjectivesMap } = currentState.objectives;
        const completedObjectiveIds = Object.keys(completedObjectivesMap);

        const idsToSync = completedObjectiveIds.filter((id) => completedObjectivesMap[id].syncState === LoadingState.NONE);
        if (idsToSync.length === 0) {
          return;
        }

        const toSync = idsToSync.map((id) => completedObjectivesMap[id]);
        store.dispatch(ObjectivesActionCreators.saveCompletedObjectives(toSync));
      }
    };

    const expandMostRelevantGroup = () => {
      const { objectivesLoadState, completedObjectivesLoadState, groupsOrder, groupsMap, completedObjectivesMap } = currentState.objectives;
      const old = previousState.objectives;

      if (objectivesLoadState === LoadingState.SUCCESS && completedObjectivesLoadState === LoadingState.SUCCESS) {
        if (old.objectivesLoadState !== LoadingState.SUCCESS || old.completedObjectivesLoadState !== LoadingState.SUCCESS) {
          // Both objectives and completed objectives are now loaded, work out which group to expand
          for (let i = 0; i < groupsOrder.length; i++) {
            const group = groupsMap[groupsOrder[i]];
            if (!group) {
              // eslint-disable-next-line no-continue
              continue;
            }
            const hasIncomplete = group.objectiveIds.some((id) => completedObjectivesMap[id] === undefined);
            if (hasIncomplete) {
              const groupIds = [group.id];
              if (group.isAdvanced) {
                groupIds.push(ADVANCED_ACCORDION_ID);
              }
              store.dispatch(ObjectivesActionCreators.setExpandedGroups(groupIds));
              return;
            }
          }
        }
      }
    };

    syncNewlyCompletedObjectives();
    expandMostRelevantGroup();
  };
};

export const createObjectivesStoreListener = () => {
  return new StoreListener(createObjectivesStoreChangeHandler());
};
