/* eslint-disable max-len */
import React, { CSSProperties } from 'react';

import './intercom-icon.scss';

interface iProps {
  useCurrentColour?: boolean;
  noBackground?: boolean;
  size?: string;
  backgroundPadding?: string;
}

const IntercomIcon = ({ useCurrentColour, noBackground, size = '1em', backgroundPadding = '0.8em' }: iProps): JSX.Element => {
  const colour = useCurrentColour ? 'currentcolor' : 'white';

  const classNames = ['intercom-icon-round'];
  if (noBackground) {
    classNames.push('no-background');
  }

  return (
    <span className={classNames.join(' ')} style={{ fontSize: size, '--background-padding': backgroundPadding } as CSSProperties}>
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 104 117'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        xmlSpace='preserve'
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: 1.5,
        }}
      >
        <g id='Artboard1' transform='matrix(0.880844,0,0,0.956802,-792.3,-458.386)'>
          <rect x='899.478' y='479.081' width='117.35' height='121.478' style={{ fill: 'none' }} />
          <g transform='matrix(1.13528,0,0,1.04515,897.768,477.148)'>
            <path
              d='M14.473,2.535C14.473,2.535 86.793,2.436 92.184,2.578C97.576,2.719 104.609,5.697 104.358,14.825C104.108,23.953 104.235,117.435 104.235,117.435L73.025,104.504L12.612,104.504C12.612,104.504 2.585,104.631 2.334,92.587C2.084,80.544 2.229,19.755 2.229,14.429C2.229,12.81 3.193,2.916 14.473,2.535ZM17.301,74.662C18.654,73.082 20.669,74.361 20.669,74.361C20.669,74.361 32.527,83.846 52.656,83.846C72.784,83.846 84.073,75.332 85.678,74.601C86.298,74.318 88.428,73.388 89.497,75.188C90.934,77.607 89.606,79.046 89.245,79.3C87.701,80.385 74.809,89.917 52.656,89.758C31.641,89.608 19.049,80.991 17.716,78.898C16.457,76.925 16.236,75.907 17.301,74.662Z'
              style={{ fill: colour, stroke: colour, strokeWidth: '1px' }}
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default IntercomIcon;
