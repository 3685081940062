import { GardenItemType, ShapeType } from '@gi/constants';
import { PlanValidation } from '@gi/plan';
import { Geometry } from '@gi/math';

import { SimulatedGardenItem, SimulatedGardenItemClipboardData } from './simulated-garden-item';

export type SimulatedShapeClipboardData = {
  shapeType: ShapeType;
  point1: Vector2;
  point2: Vector2 | null;
  point3: Vector2;
  rotation: number;
  fill: number | null;
  stroke: number | null;
  strokeWidth: number;
  texture: string | null;
  closed: boolean;
  locked: boolean;
  zIndex: number;
};

export class SimulatedShape extends SimulatedGardenItem {
  readonly type = GardenItemType.Shape;

  #shapeType: ShapeType;
  get shapeType() {
    return this.#shapeType;
  }

  #point1: Vector2;
  get point1() {
    return this.#point1;
  }

  #point2: Vector2 | null; // Point2 only used by triangles and lines
  get point2() {
    if (this.shapeType === ShapeType.TRIANGLE || this.shapeType === ShapeType.LINE) {
      return this.#point2;
    }
    return null;
  }

  #point3: Vector2;
  get point3() {
    return this.#point3;
  }

  #rotation: number;
  get rotation() {
    return this.#rotation;
  }

  #fill: number | null; // TODO: Type?
  get fill() {
    return this.#fill;
  }

  #stroke: number | null; // TODO: Type?
  get stroke() {
    return this.#stroke;
  }

  #strokeWidth: number;
  get strokeWidth() {
    return this.#strokeWidth;
  }

  #texture: string | null; // TODO: Type?
  get texture() {
    return this.#texture;
  }

  #closed: boolean;
  get closed() {
    return this.#closed;
  }

  get center(): Vector2 {
    if (this.shapeType === ShapeType.TRIANGLE) {
      return Geometry.midBetween3Points(this.point1, this.point2!, this.point3);
    }
    return Geometry.midpoint(this.point1, this.point3);
  }

  get dimensions(): Dimensions {
    if (this.shapeType === ShapeType.TRIANGLE) {
      const { min, max } = Geometry.getBoundingBox(this.point1, this.point2!, this.point3);
      return {
        width: max.x - min.x,
        height: max.y - min.y,
      };
    }
    return {
      width: Math.abs(this.point3.x - this.point1.x),
      height: Math.abs(this.point3.y - this.point1.y),
    };
  }

  constructor(
    id: number,
    shapeType: ShapeType,
    point1: Vector2,
    point2: Vector2 | null,
    point3: Vector2,
    rotation: number,
    fill: number | null,
    stroke: number | null,
    strokeWidth: number,
    texture: string | null,
    closed: boolean,
    locked: boolean,
    zIndex: number
  ) {
    super(id, locked, zIndex);
    this.#shapeType = shapeType;
    this.#point1 = point1;
    this.#point2 = point2;
    this.#point3 = point3;
    this.#rotation = rotation;
    this.#fill = fill;
    this.#stroke = stroke;
    this.#strokeWidth = strokeWidth;
    this.#texture = texture;
    this.#closed = closed;

    this.#validate();
  }

  #setStyle(fill: number | string | null, stroke: number | null, strokeWidth: number) {
    this.#fill = typeof fill === 'number' ? fill : null;
    this.#texture = typeof fill === 'string' ? fill : null;
    this.#stroke = stroke;
    this.#strokeWidth = strokeWidth;
    this.#validate();
  }

  setStyle(fill: number | string | null, stroke: number | null, strokeWidth: number) {
    this.#setStyle(fill, stroke, strokeWidth);
    this.emitUpdates();
  }

  #setPosition(point1: Vector2, point2: Vector2 | null, point3: Vector2, rotation: number) {
    this.#point1 = point1;
    this.#point2 = point2;
    this.#point3 = point3;
    this.#rotation = rotation;
    this.#validate();
  }

  setPosition(point1: Vector2, point2: Vector2 | null, point3: Vector2, rotation: number) {
    this.#setPosition(point1, point2, point3, rotation);
    this.emitUpdates();
  }

  getClipboardData(): SimulatedGardenItemClipboardData<GardenItemType.Shape, SimulatedShapeClipboardData> {
    return {
      type: GardenItemType.Shape,
      data: {
        shapeType: this.shapeType,
        point1: { ...this.point1 },
        point2: this.point2 === null ? null : { ...this.point2 },
        point3: { ...this.point3 },
        rotation: this.rotation,
        fill: this.fill,
        stroke: this.stroke,
        strokeWidth: this.strokeWidth,
        texture: this.texture,
        closed: this.closed,
        locked: this.locked,
        zIndex: this.zIndex,
      },
    };
  }

  #validate() {
    const { dimensions } = this;
    const { point1, point2, point3, rotation, fill, stroke, strokeWidth, texture } = PlanValidation.validateShape(
      this.shapeType,
      this.point1,
      this.point2,
      this.point3,
      this.center,
      this.rotation,
      dimensions.width,
      dimensions.height,
      this.point2 !== null,
      this.fill !== null,
      this.fill !== null ? this.fill : this.stroke!,
      this.texture,
      this.strokeWidth
    );

    this.#point1 = point1;
    this.#point2 = point2;
    this.#point3 = point3;
    this.#rotation = rotation;
    this.#fill = fill;
    this.#stroke = stroke;
    this.#strokeWidth = strokeWidth;
    this.#texture = texture;
  }
}
