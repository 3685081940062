import React from 'react';

import Modal from '../modal';
import ModalHeader from '../header/modal-header';
import ModalHeaderContent from '../header/modal-header-content';
import ModalHeaderTitle from '../header/modal-header-title';
import ModalFooter from '../modal-footer';
import ModalContent from '../modal-content';
import ModalPaneContainer from '../modal-pane-container';
import ModalPane from '../modal-pane/modal-pane';
import ModalFooterButtons from '../modal-footer-buttons';
import ModalFooterButtonsSection from '../modal-footer-buttons-section';
import ModalType from '../modal-types';

import './confirm-modal.scss';

interface iProps {
  type: ModalType;
  headerText: string;
  children: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  className?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

const ConfirmModal = ({
  children,
  headerText,
  type,
  onCancel,
  onConfirm,
  className = '',
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
}: iProps): JSX.Element => {
  return (
    <Modal className={`confirm-modal ${className}`} type={type} closeRequest={onCancel}>
      <ModalContent>
        <ModalHeader>
          <ModalHeaderContent>
            <ModalHeaderTitle>{headerText}</ModalHeaderTitle>
          </ModalHeaderContent>
        </ModalHeader>
        <ModalPaneContainer>
          <ModalPane>{children}</ModalPane>
        </ModalPaneContainer>
        <ModalFooter>
          <ModalFooterButtons>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-secondary' onClick={onCancel}>
                {cancelButtonText}
              </button>
            </ModalFooterButtonsSection>
            <ModalFooterButtonsSection>
              <button type='button' className='button button-primary' onClick={onConfirm}>
                {confirmButtonText}
              </button>
            </ModalFooterButtonsSection>
          </ModalFooterButtons>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
