import {
  HandleSetNode,
  InspectableClassData,
  Node,
  NodeComponent,
  NodeComponentEvent,
  SelectableComponentContext,
  SelectableComponentContextEvent,
  hasEngine,
} from '@gi/core-renderer';
import { LayerTypes, LayerType } from '@gi/constants';
import { PlantFamilyTypes } from '@gi/plant-families';

import { Edit } from '../../simulation/edit/edit';
import MultiHandleSetNode from '../nodes/handles/multi-handle-set-node';
import { GenericHandleSetNodeEvent } from '../nodes/handles/generic-handle-set-node';
import PlantHandleSetNode, { PlantHandleSetNodeEvent } from '../nodes/handles/plant-handle-set-node';
import CanvasInteractionInterface from '../../canvas-interface/canvas-interaction-interface';
import PlantNode from '../nodes/plant/plant-node';
import TextNode from '../nodes/text/text-node';
import RectangleHandleSetNode from '../nodes/handles/rectangle-handle-set-node';
import ShapeNode from '../nodes/shapes/shape-node';
import PolygonHandleSetNode, { PolygonHandleSetNodeEvent } from '../nodes/handles/polygon-handle-set-node';
import RectangleShapeNode from '../nodes/shapes/rectangle-shape-node';
import EllipseShapeNode from '../nodes/shapes/ellipse-shape-node';
import TriangleShapeNode from '../nodes/shapes/triangle-shape-node';
import LineShapeNode from '../nodes/shapes/line-shape-node';
import LineHandleSetNode, { LineHandleSetNodeEvent } from '../nodes/handles/line-handle-set-node';
import BlockGardenObjectNode from '../nodes/garden-objects/block-garden-object';
import GardenObjectNode from '../nodes/garden-objects/garden-object-node';
import PathGardenObjectNode from '../nodes/garden-objects/path-garden-object-node';
import CanvasLayers from '../canvas-layers';
import SFGPlantNode from '../nodes/plant/sfg-plant-node';
import PlantLabelNode from '../nodes/plant/plant-label-node';
import CropRotationContext, { CropRotationInteractionType } from '../nodes/crop-rotation/crop-rotation-context';
import CanvasInteractionGroup from '../../canvas-interface/canvas-interaction-group';
import BackgroundImageNode from '../nodes/background/background-image-node';

class GardenItemSelectionMiddleware extends NodeComponent {
  type = 'GardenObjectSelectionMiddleware';

  readonly interactionInterface: CanvasInteractionInterface;
  readonly canvasLayers: CanvasLayers;

  #selectionContext: SelectableComponentContext | null = null;
  #selectionTargets: CanvasInteractionGroup = new CanvasInteractionGroup();
  #destroyCurrentHandle: (() => void) | null = null;

  #currentHandles: HandleSetNode | null = null;
  get currentHandles() {
    return this.#currentHandles;
  }

  constructor(canvasInteractionInterface: CanvasInteractionInterface, canvasLayers: CanvasLayers) {
    super();
    this.interactionInterface = canvasInteractionInterface;
    this.canvasLayers = canvasLayers;

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  forceUpdateHandles() {
    // Selection changes are batched. This forces an update now, which will emit a selection change event,
    //  updating the current handles.
    this.#selectionContext?.manualFlushUpdates();
    return this.#currentHandles;
  }

  deleteSelection() {
    if (this.#selectionTargets.totalItems === 0) {
      return;
    }

    const { plants, sfgPlants, plantLabels, gardenObjects, shapes, text } = this.#selectionTargets;
    const plantIds: number[] = [];

    const selectionContext = this.owner?.contexts.get(SelectableComponentContext);
    if (selectionContext) {
      selectionContext.removeFromSelection(...plants, ...sfgPlants, ...plantLabels, ...gardenObjects, ...shapes, ...text);
    }

    for (let i = 0; i < plants.length; i++) {
      const plant = plants[i];
      plantIds.push(plant.id);
      this.interactionInterface.removeItem(plant.id);
    }

    for (let i = 0; i < sfgPlants.length; i++) {
      const plant = sfgPlants[i];
      plantIds.push(plant.id);
      this.interactionInterface.removeItem(plant.id);
    }

    for (let i = 0; i < plantLabels.length; i++) {
      const plantLabel = plantLabels[i];
      if (!plantIds.includes(plantLabel.id)) {
        this.interactionInterface.setShowPlantLabel(plantLabel.id, false);
      }
    }

    for (let i = 0; i < gardenObjects.length; i++) {
      this.interactionInterface.removeItem(gardenObjects[i].id);
    }

    for (let i = 0; i < shapes.length; i++) {
      this.interactionInterface.removeItem(shapes[i].id);
    }

    for (let i = 0; i < text.length; i++) {
      this.interactionInterface.removeItem(text[i].id);
    }

    this.interactionInterface.pushUpdates();
  }

  #onBind = () => {
    hasEngine(this);
    this.#selectionContext = this.owner.contexts.get(SelectableComponentContext);
    if (!this.#selectionContext) {
      throw new Error('GardenObjectSelectionMiddleware must be attached to a node with a SelectableComponentContext');
    }
    this.#selectionContext.eventBus.on(SelectableComponentContextEvent.OnSelectionChanged, this.#onSelectionChange);
  };

  #onBeforeUnbind = () => {
    this.#selectionContext?.eventBus.off(SelectableComponentContextEvent.OnSelectionChanged, this.#onSelectionChange);
    this.#selectionContext = null;
  };

  /**
   * Handler for when the selection changes.
   * @param nodes All the nodes in the active selection
   * @param added All the nodes that were added this update
   * @param removed All the nodes that were removed this update
   */
  #onSelectionChange = (nodes: Node[], added: Node[], removed: Node[]) => {
    const finalTargets = new CanvasInteractionGroup(nodes);
    this.#selectionTargets = finalTargets;
    this.#updateLayer(removed, false);
    this.#updateLayer(added, true);
    this.#updateHandles();
  };

  /**
   * Returns the layer the given node should be on.
   * TODO: Maybe this should be stored in a component, or extension on a base class.
   * @param node The node to get the layer from
   * @returns The layer type for the node
   */
  // eslint-disable-next-line class-methods-use-this
  #getLayerType(node: Node): LayerType {
    if (node instanceof PlantNode || node instanceof SFGPlantNode) {
      return LayerTypes.PLANTS;
    }
    if (node instanceof PlantLabelNode) {
      return LayerTypes.PLANT_LABELS;
    }
    if (node instanceof GardenObjectNode) {
      return node.gardenObject.layerType;
    }
    if (node instanceof ShapeNode) {
      return LayerTypes.LAYOUT;
    }
    if (node instanceof TextNode) {
      return LayerTypes.TEXT;
    }
    if (node instanceof BackgroundImageNode) {
      return LayerTypes.BACKGROUND;
    }
    throw new Error('Could not get layer for given node: Unexpected node type');
  }

  /**
   * Moves the given nodes to their appropriate layer, based on if they're selected.
   * @param nodes The nodes to update the layers of
   * @param selected If the node is currently selected or not
   */
  #updateLayer(nodes: Node[], selected: boolean) {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node.destroyed) {
        // eslint-disable-next-line no-continue
        continue;
      }
      const layerType = this.#getLayerType(node);
      const layer = this.canvasLayers.getLayer(layerType, selected);
      if (node.parent !== layer) {
        layer.unsafeAddChildren(node);
      }
    }
  }

  /**
   * Starts editing a plant. Creates the appropriate handles and callbacks.
   * @param plantNode The plantNode to edit
   */
  #startEditPlant(plantNode: PlantNode | SFGPlantNode) {
    if (plantNode instanceof SFGPlantNode) {
      return;
    }

    const edit = this.interactionInterface.startEditPlant(plantNode.id);
    const handleSet = new PlantHandleSetNode(plantNode);

    // On any interaction start
    handleSet.eventBus.on(GenericHandleSetNodeEvent.Start, () => {
      edit.start();
      this.owner?.tryGetContext(CropRotationContext)?.showFamilies(CropRotationInteractionType.EDIT, plantNode.plant.familyID as PlantFamilyTypes);
    });

    // On transform handle drag (corner or row start/end point)
    handleSet.eventBus.on(PlantHandleSetNodeEvent.Transform, (newRowStart, newRowEnd, newHeight) => {
      edit.doBatchUpdate(() => {
        edit.transform(newRowStart, newRowEnd, newHeight);
        if (handleSet.snapToGrid) {
          edit.snapTransform(handleSet.snapToGridDistance);
        }
      });
    });

    // On rotate handle dragged
    handleSet.eventBus.on(GenericHandleSetNodeEvent.Rotate, ({ angle, origin }) => {
      edit.rotate(angle, origin, Edit.getRotationMatrix(angle, origin));
    });

    // On any interaction end
    handleSet.eventBus.on(GenericHandleSetNodeEvent.End, () => {
      edit.end();
      this.interactionInterface.endEdit();
      this.owner?.tryGetContext(CropRotationContext)?.stopShowing(CropRotationInteractionType.EDIT);
    });

    this.owner?.addChildren(handleSet);
    this.#destroyCurrentHandle = () => handleSet.destroy();
    this.#currentHandles = handleSet as HandleSetNode;
  }

  /**
   * Starts editing a garden object. Creates the appropriate handles and callbacks.
   * @param gardenObjectNode The gardenObjectNode to edit
   */
  #startEditGardenObject(gardenObjectNode: GardenObjectNode) {
    const edit = this.interactionInterface.startEditGardenObject(gardenObjectNode.id);

    if (gardenObjectNode instanceof BlockGardenObjectNode) {
      const handleSet = new RectangleHandleSetNode();
      handleSet.attachToGardenObject(gardenObjectNode as GardenObjectNode);

      // On any interaction start
      handleSet.eventBus.on(GenericHandleSetNodeEvent.Start, () => {
        edit.start();
      });

      // On any corner handle drag
      handleSet.eventBus.on(GenericHandleSetNodeEvent.Manipulate, ({ center, dimensions, rotation }) => {
        edit.doBatchUpdate(() => {
          edit.transform(center, dimensions, rotation);
          if (handleSet.snapToGrid) {
            edit.snapTransform(handleSet.snapToGridDistance);
          }
        });
      });

      // On rotation handle drag
      handleSet.eventBus.on(GenericHandleSetNodeEvent.Rotate, ({ angle, origin }) => {
        edit.rotate(angle, origin, Edit.getRotationMatrix(angle, origin));
      });

      // On any interaction ending
      handleSet.eventBus.on(GenericHandleSetNodeEvent.End, () => {
        edit.end();
        this.interactionInterface.endEdit();
      });

      this.owner?.addChildren(handleSet);
      this.#destroyCurrentHandle = () => handleSet.destroy();
      this.#currentHandles = handleSet as HandleSetNode;
    } else if (gardenObjectNode instanceof PathGardenObjectNode) {
      const handleSet = new LineHandleSetNode();
      handleSet.attachToGardenObject(gardenObjectNode);

      // On interaction start
      handleSet.eventBus.on(LineHandleSetNodeEvent.Start, () => {
        edit.start();
      });

      // On start/mid/end point being dragged
      handleSet.eventBus.on(LineHandleSetNodeEvent.Manipulate, ({ start, end, controlPoint }) => {
        edit.doBatchUpdate(() => {
          edit.manipulate(start, controlPoint, end, 0);
          if (handleSet.snapToGrid) {
            edit.snapTransform(handleSet.snapToGridDistance);
          }
        });
      });

      // On interaction end
      handleSet.eventBus.on(LineHandleSetNodeEvent.End, () => {
        edit.end();
        this.interactionInterface.endEdit();
      });

      this.owner?.addChildren(handleSet);
      this.#destroyCurrentHandle = () => handleSet.destroy();
      this.#currentHandles = handleSet as HandleSetNode;
    }
  }

  /**
   * Starts editing text. Creates the appropriate handles and callbacks.
   * @param textNode The textNode to edit
   */
  #startEditText(textNode: TextNode) {
    const edit = this.interactionInterface.startEditText(textNode.id);
    const handleSet = new RectangleHandleSetNode();
    handleSet.attachToText(textNode);

    // On any interaction start
    handleSet.eventBus.on(GenericHandleSetNodeEvent.Start, () => {
      edit.start();
    });

    // On corner handle being dragged
    handleSet.eventBus.on(GenericHandleSetNodeEvent.Manipulate, ({ center, dimensions, rotation }) => {
      edit.doBatchUpdate(() => {
        edit.transform(center, dimensions, rotation);
        if (handleSet.snapToGrid) {
          edit.snapTransform(handleSet.snapToGridDistance);
        }
      });
    });

    // On rotation handle being dragged
    handleSet.eventBus.on(GenericHandleSetNodeEvent.Rotate, ({ angle, origin }) => {
      edit.rotate(angle, origin, Edit.getRotationMatrix(angle, origin));
    });

    // On any interaction ending
    handleSet.eventBus.on(GenericHandleSetNodeEvent.End, () => {
      edit.end();
      this.interactionInterface.endEdit();
    });

    this.owner?.addChildren(handleSet);
    this.#destroyCurrentHandle = () => handleSet.destroy();
    this.#currentHandles = handleSet as HandleSetNode;
  }

  /**
   * Starts editing a shape. Creates the appropriate handles and callbacks.
   * @param shapeNode The shapeNode to edit
   */
  #startEditShape(shapeNode: ShapeNode) {
    const edit = this.interactionInterface.startEditShape(shapeNode.id);

    if (shapeNode instanceof RectangleShapeNode || shapeNode instanceof EllipseShapeNode) {
      // Rectangles and ellipses use generic rectangular handles.
      const handleSet = new RectangleHandleSetNode();
      handleSet.attachToShape(shapeNode as ShapeNode);

      // On any interaction ending
      handleSet.eventBus.on(GenericHandleSetNodeEvent.Start, () => {
        edit.start();
      });

      // On corner handle dragged
      handleSet.eventBus.on(GenericHandleSetNodeEvent.Manipulate, ({ center, dimensions, rotation }) => {
        edit.doBatchUpdate(() => {
          edit.transform(center, dimensions, rotation);
          if (handleSet.snapToGrid) {
            edit.snapTransform(handleSet.snapToGridDistance);
          }
        });
      });

      // On rotation handle dragged
      handleSet.eventBus.on(GenericHandleSetNodeEvent.Rotate, ({ angle, origin }) => {
        edit.rotate(angle, origin, Edit.getRotationMatrix(angle, origin));
      });

      // On any interaction end
      handleSet.eventBus.on(GenericHandleSetNodeEvent.End, () => {
        edit.end();
        this.interactionInterface.endEdit();
      });

      this.owner?.addChildren(handleSet);
      this.#destroyCurrentHandle = () => handleSet.destroy();
      this.#currentHandles = handleSet as HandleSetNode;
    } else if (shapeNode instanceof TriangleShapeNode) {
      // Triangles use a custom polygon handle set with 3 points.
      const handleSet = new PolygonHandleSetNode(3);
      handleSet.attachToTriangle(shapeNode);

      // On interaction start
      handleSet.eventBus.on(PolygonHandleSetNodeEvent.Start, () => {
        edit.start();
      });

      // On any point of the triangle being dragged
      handleSet.eventBus.on(PolygonHandleSetNodeEvent.Manipulate, ({ points }) => {
        edit.doBatchUpdate(() => {
          edit.manipulate(points[0], points[1], points[2], 0);
          if (handleSet.snapToGrid) {
            edit.snapTransform(handleSet.snapToGridDistance);
          }
        });
      });

      // On interaction end
      handleSet.eventBus.on(PolygonHandleSetNodeEvent.End, () => {
        edit.end();
        this.interactionInterface.endEdit();
      });

      this.owner?.addChildren(handleSet);
      this.#destroyCurrentHandle = () => handleSet.destroy();
      this.#currentHandles = handleSet as HandleSetNode;
    } else if (shapeNode instanceof LineShapeNode) {
      // Lines use a line handle set
      const handleSet = new LineHandleSetNode();
      handleSet.attachToLine(shapeNode);

      // On interaction start
      handleSet.eventBus.on(LineHandleSetNodeEvent.Start, () => {
        edit.start();
      });

      // On start/end/control point being dragged
      handleSet.eventBus.on(LineHandleSetNodeEvent.Manipulate, ({ start, end, controlPoint }) => {
        edit.doBatchUpdate(() => {
          edit.manipulate(start, controlPoint, end, 0);
          if (handleSet.snapToGrid) {
            edit.snapTransform(handleSet.snapToGridDistance);
          }
        });
      });

      // On interaction end
      handleSet.eventBus.on(LineHandleSetNodeEvent.End, () => {
        edit.end();
        this.interactionInterface.endEdit();
      });

      this.owner?.addChildren(handleSet);
      this.#destroyCurrentHandle = () => handleSet.destroy();
      this.#currentHandles = handleSet as HandleSetNode;
    }
  }

  /**
   * Starts editing the background image. Creates the appropriate handles and callbacks.
   * @param backgroundImageNode The BackgroundImageNode to edit
   */
  #startEditBackgroundImage(backgroundImageNode: BackgroundImageNode) {
    const edit = this.interactionInterface.startEditBackgroundImage();
    const handleSet = new RectangleHandleSetNode();
    handleSet.attachToBackgroundImage(backgroundImageNode);

    // On any interaction start
    handleSet.eventBus.on(GenericHandleSetNodeEvent.Start, () => {
      edit.start();
    });

    // On corner handle being dragged
    handleSet.eventBus.on(GenericHandleSetNodeEvent.Manipulate, ({ center, dimensions, rotation }) => {
      edit.doBatchUpdate(() => {
        edit.transform(center, dimensions, rotation);
        if (handleSet.snapToGrid) {
          edit.snapTransform(handleSet.snapToGridDistance);
        }
      });
    });

    // On rotation handle being dragged
    handleSet.eventBus.on(GenericHandleSetNodeEvent.Rotate, ({ angle, origin }) => {
      edit.rotate(angle, origin, Edit.getRotationMatrix(angle, origin));
    });

    // On any interaction ending
    handleSet.eventBus.on(GenericHandleSetNodeEvent.End, () => {
      edit.end();
      this.interactionInterface.endEdit();
    });

    this.owner?.addChildren(handleSet);
    this.#destroyCurrentHandle = () => handleSet.destroy();
    this.#currentHandles = handleSet as HandleSetNode;
  }

  /**
   * Starts editing a group of items. Creates 1 massive handleset to allow rotation.
   * @param interactionGroup The interactionGroup continaing all the items to edit
   */
  #startEditGroup(interactionGroup: CanvasInteractionGroup) {
    // There's numerous things selected, create a single multi-select for them all.
    const handleSet = new MultiHandleSetNode([
      ...interactionGroup.plants,
      ...interactionGroup.sfgPlants,
      ...interactionGroup.plantLabels,
      ...interactionGroup.gardenObjects,
      ...interactionGroup.shapes,
      ...interactionGroup.text,
      ...(interactionGroup.backgroundImage ? [interactionGroup.backgroundImage] : []),
    ]);
    const editGroup = this.interactionInterface.startEdit(interactionGroup);

    // On interaction start
    handleSet.eventBus.on(GenericHandleSetNodeEvent.Start, () => {
      editGroup.start();
      this.owner?.tryGetContext(CropRotationContext)?.showFamilies(CropRotationInteractionType.EDIT, ...interactionGroup.getPlantFamilies());
    });

    // On rotation handle dragged
    handleSet.eventBus.on(GenericHandleSetNodeEvent.Rotate, ({ angle, origin }) => {
      editGroup.rotate(angle, origin);
    });

    // On interaction end
    handleSet.eventBus.on(GenericHandleSetNodeEvent.End, () => {
      editGroup.end();
      this.interactionInterface.endEdit();
      this.owner?.tryGetContext(CropRotationContext)?.stopShowing(CropRotationInteractionType.EDIT);
    });

    this.owner?.addChildren(handleSet);
    this.#destroyCurrentHandle = () => handleSet.destroy();
    this.#currentHandles = handleSet as HandleSetNode;
  }

  /**
   * Updates the handles, destoying the old ones and creating new ones.
   */
  #updateHandles() {
    if (this.#destroyCurrentHandle) {
      this.#destroyCurrentHandle();
      this.#destroyCurrentHandle = null;
      this.#currentHandles = null;
    }

    if (this.#selectionTargets.totalItems === 1) {
      // There's only 1 thing selected, work out what it is and create handles for it.
      if (this.#selectionTargets.plants.length === 1) {
        const plantNode = this.#selectionTargets.plants[0];
        this.#startEditPlant(plantNode);
      } else if (this.#selectionTargets.gardenObjects.length === 1) {
        const gardenObjectNode = this.#selectionTargets.gardenObjects[0];
        this.#startEditGardenObject(gardenObjectNode);
      } else if (this.#selectionTargets.text.length === 1) {
        const textNode = this.#selectionTargets.text[0];
        this.#startEditText(textNode);
      } else if (this.#selectionTargets.shapes.length === 1) {
        const shapeNode = this.#selectionTargets.shapes[0];
        this.#startEditShape(shapeNode);
      } else if (this.#selectionTargets.backgroundImage !== null) {
        this.#startEditBackgroundImage(this.#selectionTargets.backgroundImage);
      }
    } else if (this.#selectionTargets.totalItems > 1) {
      this.#startEditGroup(this.#selectionTargets);
    }
  }

  inspectorData: InspectableClassData<this> = [];
}

export default GardenItemSelectionMiddleware;
