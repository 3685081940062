import React, { useCallback } from 'react';

import NumericBlurInput from './numeric-blur-input';

import './length-input.scss';

interface iProps {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  valid?: boolean;
  inputClasses?: string;
  inline?: boolean;
  id?: string;
  decimals?: number;
}

const DegreesInput = ({ value, onChange, disabled = false, valid = true, inputClasses = '', inline = false, id, decimals = 0 }: iProps): JSX.Element => {
  let className = 'length-input-container';

  if (disabled) {
    className += ' disabled';
  }

  const handleOnChange = useCallback(
    (newValue: number) => {
      let rotation = newValue;

      if (Number.isNaN(rotation)) {
        rotation = 0;
      }

      if (rotation < -360) {
        rotation = -360;
      }

      if (rotation > 360) {
        rotation = 360;
      }

      let val = 0;

      try {
        val = Math.round(rotation * 10 ** decimals) / 10 ** decimals;
      } finally {
        onChange(val);
      }
    },
    [onChange, decimals]
  );

  return (
    <NumericBlurInput
      id={id}
      disabled={disabled}
      inputMode={decimals > 0 ? 'decimal' : 'numeric'}
      className={className}
      inputClassName={inputClasses}
      value={value}
      onChange={handleOnChange}
      size={84}
      inputSuffix={<>&deg;</>}
      inputSuffixInline={inline}
      valid={valid}
    />
  );
};

export default DegreesInput;
