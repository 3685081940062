import React, { useContext } from 'react';
import ModalTabContext from './modal-tab-context';

interface iProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  tabID: string;
}

const ModalTab = ({ children = null, style = {}, tabID }: iProps): JSX.Element => {
  const { activeTab, setActiveTab } = useContext(ModalTabContext);
  const active = activeTab === tabID;

  return (
    <div className={`modal-tab ${active ? 'modal-tab-active' : ''}`} style={style}>
      <button
        type='button'
        className='modal-tab-button'
        onClick={() => {
          setActiveTab(tabID);
        }}
      >
        {children}
      </button>
    </div>
  );
};

export default ModalTab;
