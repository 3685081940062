import React, { useState } from 'react';
import { networkConfig } from '@gi/config';
import { useNavigate } from 'react-router-dom';

import SearchInput from '../search/search-input';

import styles from './home-directory-page.module.css';

const HomeDirectoryBanner = (): JSX.Element | null => {
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();
  const doSearch = () => {
    // TODO - Build search query URL after we load page data from the API
    navigate(`/garden-guru/search?q=${searchString}`);
  };

  return (
    <div className={styles.banner}>
      <div className={styles.bannerImageContainer}>
        <img className={styles.bannerBackgroundImage} src={`${networkConfig.assetBaseURL}/guru-icon2.svg`} alt='' />
      </div>
      <div className={styles.searchInputContainer}>
        <SearchInput searchString={searchString} setSearchString={setSearchString} doSearch={doSearch} />
      </div>
      <div className={`${styles.bannerInner} guru-content-width`}>
        Videos, Guides and Articles
        <br />
        Specific to Your Garden, Location and Season
      </div>
    </div>
  );
};

export default HomeDirectoryBanner;
