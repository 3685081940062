import React from 'react';

import { ModalPaneSection, ModalPaneSectionHeader } from '@gi/modal';

import HelpModalAction from '../components/help-modal-action';

interface iProps {
  hideHeader?: boolean;
}

const ItemLockingHelp = ({ hideHeader }: iProps): JSX.Element => {
  return (
    <ModalPaneSection>
      {hideHeader ? null : <ModalPaneSectionHeader>Locking Items</ModalPaneSectionHeader>}
      <p>
        The{' '}
        <HelpModalAction>
          <i className='icon-lock' /> Lock
        </HelpModalAction>{' '}
        button will disable editing on the selected items. This will prevent the item from being clicked on or dragged. Locking items can be useful for large
        static items that you don&apos;t want to accidentally move, like grass, patios or trees.
      </p>
      <p>
        To unlock an item, you must first switch layer using the layer dropdown to show &quot;Locked Items&quot;. You can then select the items you want to
        unlock, and press the{' '}
        <HelpModalAction>
          <i className='icon-lock' /> Unlock
        </HelpModalAction>{' '}
        button.
      </p>
    </ModalPaneSection>
  );
};

export default ItemLockingHelp;
