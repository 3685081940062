import { ColorSource, Graphics } from 'pixi.js-new';

// 1 foot = 30.48cm
const SQUARE_FOOT_SIZE = 30.48;

type DrawRootsProperties = {
  graphics: Graphics;
  width: number;
  height: number;
  spacing: number;
  inRowSpacing: number;
  rowSpacing: number;
  colour: ColorSource;
  alpha: number;
};

/**
 * Draws roots to the given graphics object
 * @param params The parameters needed for drawing roots
 * @param params.graphics The graphics object to daw to
 * @param params.width The width of the roots
 * @param params.height The height of the roots
 * @param params.spacing The plant spacing
 * @param params.inRowSpacing The plant inRowSpacing
 * @param params.rowSpacing The plant rowSpacing
 * @param params.colour The colour to draw the roots
 * @param params.alpha The alpha of the roots
 */
export const drawRoots = ({ graphics, width, height, spacing, inRowSpacing, rowSpacing, colour, alpha }: DrawRootsProperties) => {
  graphics.clear();
  graphics.beginFill(colour, alpha);

  // Single plant
  if (width === 0 && height === 0) {
    graphics.drawCircle(0, 0, spacing / 2);
    graphics.endFill();
    return;
  }

  // Row
  if (height === 0) {
    graphics.drawRoundedRect(-(inRowSpacing / 2), -(rowSpacing / 2), width + inRowSpacing, rowSpacing, Math.min(rowSpacing, inRowSpacing) / 2);
    graphics.endFill();
    return;
  }

  // Block of plants
  graphics.drawRoundedRect(-(spacing / 2), -(spacing / 2), width + spacing, height + spacing, spacing / 2);
  graphics.endFill();
};

type DrawSquareFootRootsProperties = {
  graphics: Graphics;
  width?: number;
  height?: number;
  colour: ColorSource;
  alpha?: number;
};

/**
 * Draws square foot roots to the given graphics object
 * @param params The parameters needed for drawing roots
 * @param params.graphics The graphics object to daw to
 * @param params.width The width of the roots
 * @param params.height The height of the roots
 * @param params.colour The colour to draw the roots
 * @param params.alpha The alpha of the roots
 */
export const drawSquareFootRoots = ({ graphics, width = SQUARE_FOOT_SIZE, height = SQUARE_FOOT_SIZE, colour, alpha = 0.75 }: DrawSquareFootRootsProperties) => {
  graphics.clear();
  graphics.beginFill(colour, alpha);
  graphics.drawRect(-width / 2, -height / 2, width, height);
  graphics.endFill();
};
