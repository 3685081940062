import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import styles from './tutorial-video.module.css';
import { TutorialContext } from '../../tutorial-context';

interface iProps {
  video: string;
  autoplay?: boolean;
  mimeType?: string;
  tutorialUuid: string;
  tutorialStepIndex: number;
  tutorialContentIndex: number;
  ref?: string;
}

const TutorialVideo = ({ video, autoplay, mimeType, tutorialStepIndex, tutorialContentIndex, ref, tutorialUuid }: iProps): JSX.Element => {
  const { sendVideoAnalytics } = useContext(TutorialContext);
  const videoRef = useRef<HTMLVideoElement>(null);
  const progressRef = useRef<HTMLButtonElement>(null);

  const [videoState, setVideoState] = useState<'playing' | 'paused' | 'finished'>('paused');
  const [progress, setProgress] = useState(0);
  const [max, setMax] = useState(0);

  const watchTime = useRef(0);
  const startTime = useRef<number | null>(null);
  const playCount = useRef<number>(0);

  useEffect(() => {
    if (videoRef.current) {
      const onPause = () => {
        setVideoState('paused');
        if (startTime.current !== null) {
          watchTime.current += (Date.now() - startTime.current) / 1000;
          startTime.current = null;
        }
      };

      const onPlay = () => {
        setVideoState('playing');
        startTime.current = Date.now();
        playCount.current++;
      };

      const onEnded = () => {
        setVideoState('finished');
        if (startTime.current !== null) {
          watchTime.current += (Date.now() - startTime.current) / 1000;
          startTime.current = null;
        }
      };

      const onLoadedMetadata = () => {
        if (videoRef.current) {
          setMax(videoRef.current.duration);
        }
      };

      const onTimeUpdate = () => {
        if (videoRef.current) {
          setProgress(videoRef.current.currentTime);
        }
      };

      videoRef.current.addEventListener('pause', onPause);
      videoRef.current.addEventListener('play', onPlay);
      videoRef.current.addEventListener('ended', onEnded);
      videoRef.current.addEventListener('loadedmetadata', onLoadedMetadata);
      videoRef.current.addEventListener('timeupdate', onTimeUpdate);

      if (autoplay) {
        videoRef.current.play();
      }

      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener('pause', onPause);
          videoRef.current.removeEventListener('play', onPlay);
          videoRef.current.removeEventListener('ended', onEnded);
          videoRef.current.removeEventListener('loadedmetadata', onLoadedMetadata);
          videoRef.current.removeEventListener('timeupdate', onTimeUpdate);
        }

        if (startTime.current !== null) {
          watchTime.current += (Date.now() - startTime.current) / 1000;
          startTime.current = null;
        }

        sendVideoAnalytics({
          tutorialUuid,
          stepIndex: tutorialStepIndex,
          contentIndex: tutorialContentIndex,
          ref,
          playCount: playCount.current,
          totalPlayTime: watchTime.current,
        });
      };
    }
    return () => {};
  }, []);

  const onProgressClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (progressRef.current && videoRef.current) {
        // Set the progress to be the relative fraction of the button click position (containing the progress bar)
        const bounds = progressRef.current.getBoundingClientRect();
        const pos = e.pageX - bounds.left;
        const prog = (pos / bounds.width) * max;
        videoRef.current.currentTime = prog;
      }
    },
    [max]
  );

  let playIcon: string;

  switch (videoState) {
    case 'playing':
      playIcon = 'icon-pause';
      break;
    case 'paused':
      playIcon = 'icon-play';
      break;
    case 'finished':
      playIcon = 'icon-ccw';
      break;
    default:
      playIcon = 'icon-play';
  }

  const onPlayPause = useCallback(() => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, []);

  return (
    <figure className={styles.videoContainer}>
      <button type='button' onClick={onPlayPause} className={`${styles.videoButton} ${videoState === 'playing' ? styles.playing : ''}`}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video ref={videoRef} className={styles.videoPlayer}>
          <source src={video} type={mimeType} />
        </video>
        <div className={styles.videoIcon}>
          <i className={`icon ${playIcon}`} />
        </div>
      </button>
      <div className={styles.videoControls}>
        <div className={styles.progressControl}>
          <button type='button' onClick={onProgressClick} className={styles.videoProgressButton} ref={progressRef}>
            <progress max={max} value={progress} className={styles.videoProgress} />
          </button>
        </div>
      </div>
    </figure>
  );
};

export default TutorialVideo;
