import { AnyAction, Dispatch } from 'redux';

import plantFamilies from '@gi/plant-families';
import { Application } from '@gi/garden-platform-navigation';
import { CropRotationModes, LayerDisplayModes, PlannerTabTypes } from '@gi/constants';
import { GardenPlatformEvent, GardenPlatformEventParameters } from '@gi/garden-platform-events';

import { AnalyticsEventIdentifiers, ExportEventTypes, OnboardingActivityTypes, PlannerActivityTypes } from './analytics-event-identifiers';
import { AnalyticsActions as AnalyticsActionCreators } from './analytics-slice';

type EventListener<T extends GardenPlatformEvent> = { eventName: T; callback: (parameters: GardenPlatformEventParameters[T]) => void };

function createListener<T extends GardenPlatformEvent>(eventName: T, callback: (parameters: GardenPlatformEventParameters[T]) => void): EventListener<T> {
  return { eventName, callback };
}

const tabIDsToPageType = {
  [PlannerTabTypes.PLANNER]: 'planner',
  [PlannerTabTypes.PLANT_LIST]: 'plant list',
  [PlannerTabTypes.PARTS_LIST]: 'parts list',
  [PlannerTabTypes.NOTES]: 'plan notes',
};

// Returns analytics event data which matches a given tab being selected
function getPageViewTypeFromTabID(tabID: PlannerTabTypes): string {
  return tabIDsToPageType[tabID];
}

export function createEventListenerCallbacks(dispatch: Dispatch<AnyAction>): EventListener<GardenPlatformEvent>[] {
  return [
    // OPEN_GURU
    createListener(GardenPlatformEvent.OpenApplication, ({ application }) => {
      if (application === Application.Guru) {
        dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.OPEN_GURU));
      }
    }),

    // EXPORTED_PARTS_LIST
    createListener(GardenPlatformEvent.ExportPartsList, () => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.EXPORTS, ExportEventTypes.EXPORTED_PARTS_LIST));
    }),

    // VIEW_COMPANIONS
    createListener(GardenPlatformEvent.ViewCompanions, ({ plantCodes }) => {
      if (plantCodes.length > 0) {
        dispatch(
          AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
            type: PlannerActivityTypes.VIEW_COMPANIONS,
            plantCodes: plantCodes.join(','),
          })
        );
      }
    }),

    // FAVOURITE_PLANT
    createListener(GardenPlatformEvent.ToggleFavouritePlant, ({ plantCode, isFavourite }) => {
      if (isFavourite) {
        dispatch(
          AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
            type: PlannerActivityTypes.FAVOURITE_PLANT,
            plantCode,
          })
        );
      }
    }),

    // PLANT_SEARCH_NO_RESULTS
    createListener(GardenPlatformEvent.SearchPlantsNoResults, ({ searchTerm }) => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANT_SEARCH_NO_RESULTS, searchTerm));
    }),

    // GARDEN_OBJECT_SEARCH_NO_RESULTS
    createListener(GardenPlatformEvent.SearchGardenObjectsNoResults, ({ searchTerm }) => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.GARDEN_OBJECT_SEARCH_NO_RESULTS, searchTerm));
    }),

    // FOUND_PLANT
    createListener(GardenPlatformEvent.ChosePlantToDraw, ({ plantCode, filters }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.FOUND_PLANT,
          plantCode,
          filters,
        })
      );
    }),

    // EXPORTED_PARTS_LIST
    createListener(GardenPlatformEvent.ExportPlantsList, () => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.EXPORTS, ExportEventTypes.EXPORTED_PLANT_LIST));
    }),

    // SET_IN_GROUND_DATES
    createListener(GardenPlatformEvent.SetPlantInGroundDate, ({ plantCode, planId }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.SET_IN_GROUND_DATES,
          plantCode,
          planId,
        })
      );
    }),

    // SET_VARIETY
    createListener(GardenPlatformEvent.SetPlantVariety, ({ plantCode, planId, varietyName }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.SET_VARIETY,
          varietyName,
          plantCode,
          planId,
        })
      );
    }),

    // VARIETY_CUSTOMIZED
    createListener(GardenPlatformEvent.ModifyPlantVariety, ({ plantCode, planId, varietyName }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.VARIETY_CUSTOMIZED,
          varietyName,
          plantCode,
          planId,
        })
      );
    }),

    // VARIETY_ADDED
    createListener(GardenPlatformEvent.AddPlantVariety, ({ plantCode, planId, varietyName }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.VARIETY_ADDED,
          varietyName,
          plantCode,
          planId,
        })
      );
    }),

    // MOBILE_INTRODUCTION_VIEWED
    createListener(GardenPlatformEvent.CloseMobileIntroductionVideo, ({ pageIndex, secondsOpen }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.ONBOARDING_ACTIVITY, {
          type: OnboardingActivityTypes.MOBILE_INTRODUCTION_VIEWED,
          pageIndex,
          secondsOpen,
        })
      );
    }),

    // PAGE_VIEWED
    createListener(GardenPlatformEvent.OpenPlannerTab, ({ tab }) => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PAGE_VIEWED, getPageViewTypeFromTabID(tab)));
    }),

    // PAGE_VIEWED
    createListener(GardenPlatformEvent.OpenPlannerSettings, () => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PAGE_VIEWED, 'plan settings'));
    }),

    // SFG_MODE_CHANGED
    createListener(GardenPlatformEvent.ChangeSFGMode, ({ enabled }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.SFG_MODE_CHANGED,
          sfgMode: enabled ? 'true' : 'false',
        })
      );
    }),

    // OPEN_EDIT_MODAL
    createListener(GardenPlatformEvent.ViewEditGardenItemModal, ({ itemType }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.OPEN_EDIT_MODAL,
          itemType,
        })
      );
    }),

    // VIEW_PLANT_INFO_MODAL
    createListener(GardenPlatformEvent.ViewPlantInformationModal, ({ tab, justOpened }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.VIEW_PLANT_INFO_MODAL,
          tab: tab ?? 'plant-information',
          justOpened,
        })
      );
    }),

    // CHANGE_CROP_ROTATION_MODE
    createListener(GardenPlatformEvent.ChangeCropRotationMode, ({ mode }) => {
      if (mode === CropRotationModes.AUTOMATIC) {
        return;
      }

      let formattedCropRotationMode: number | string = mode;

      if (typeof mode === 'number') {
        const family = plantFamilies.get(mode);
        if (family) {
          formattedCropRotationMode = family.scientificName; // Scientific name is the same as name, but excludes the (extra text)
        }
      }

      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.CHANGE_CROP_ROTATION_MODE,
          value: formattedCropRotationMode,
        })
      );
    }),

    // CHANGE_LAYER_MODE
    createListener(GardenPlatformEvent.ChangeLayerMode, ({ mode }) => {
      if (mode === LayerDisplayModes.ALL) {
        return;
      }
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.CHANGE_LAYER_MODE,
          value: mode,
        })
      );
    }),

    // CHANGE_MONTH_VIEW
    createListener(GardenPlatformEvent.ChangeMonthView, ({ month }) => {
      if (month !== null) {
        dispatch(
          AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
            type: PlannerActivityTypes.CHANGE_MONTH_VIEW,
            value: month,
          })
        );
      }
    }),

    // SELECTION_TOOL
    createListener(GardenPlatformEvent.UseSelectionTool, () => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.SELECTION_TOOL,
        })
      );
    }),

    // CHAT_BUTTON_CLICKED
    createListener(GardenPlatformEvent.OpenIntercomWindow, ({ opener }) => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.CHAT_BUTTON_CLICKED, opener));
    }),

    // EXPORTED_PLAN
    createListener(GardenPlatformEvent.ExportPlan, () => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.EXPORTS, ExportEventTypes.EXPORTED_PLAN));
    }),

    // EXPORTED_PLAN_IMAGE
    createListener(GardenPlatformEvent.ExportPlanImage, () => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.EXPORTS, ExportEventTypes.EXPORTED_PLAN_IMAGE));
    }),

    // OPEN_PLAN
    createListener(GardenPlatformEvent.OpenPlan, ({ planId }) => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.OPEN_PLAN, planId.toString()));
    }),

    // LOGOUT
    createListener(GardenPlatformEvent.Logout, () => {
      dispatch(AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.LOGOUT));
    }),

    // VIEW_HELP_MODAL
    createListener(GardenPlatformEvent.ViewHelpModal, ({ modal, opener }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.VIEW_HELP_MODAL,
          modal,
          opener,
        })
      );
    }),

    // VIEW EXTERNAL LINK
    createListener(GardenPlatformEvent.ViewLink, ({ name, opener }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.VIEW_LINK,
          name,
          opener,
        })
      );
    }),

    // END TUTORIAL
    createListener(GardenPlatformEvent.EndTutorial, ({ tutorialId, tutorialName, tutorialSteps, completed, endedAtStep, maxStep }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.ONBOARDING_ACTIVITY, {
          type: OnboardingActivityTypes.TUTORIAL_ENDED,
          tutorialId,
          tutorialName,
          tutorialSteps,
          completed,
          endedAtStep,
          maxStep,
        })
      );
    }),

    // Watch tutorial video
    createListener(GardenPlatformEvent.WatchTutorialVideo, ({ ref, tutorialUuid, stepIndex, contentIndex, playCount, totalPlayTime }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.ONBOARDING_ACTIVITY, {
          type: OnboardingActivityTypes.TUTORIAL_VIDEO_WATCHED,
          ref,
          tutorialUuid,
          stepIndex,
          contentIndex,
          playCount,
          totalPlayTime,
        })
      );
    }),

    // LOCK ITEM
    createListener(GardenPlatformEvent.ChangeItemLocked, ({ locked }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.CHANGE_ITEM_LOCKED,
          locked,
        })
      );
    }),

    // REORDER ITEM
    createListener(GardenPlatformEvent.ChangeItemZIndex, ({ zIndex, operation }) => {
      dispatch(
        AnalyticsActionCreators.analyticsEvent(AnalyticsEventIdentifiers.PLANNER_ACTIVITY, {
          type: PlannerActivityTypes.CHANGE_ITEM_Z_INDEX,
          operation,
          zIndex,
        })
      );
    }),
  ];
}
