import React from 'react';
import { PlantList } from '@gi/plant-list';
import PlannerContentContainer from './planner-content-container';

import styles from './planner-content.module.css';

interface iProps {
  children: React.ReactNode;
}
const PlantListContent = ({ children }: iProps): JSX.Element => {
  return (
    <PlannerContentContainer className={styles.plantListContainer}>
      <div className={styles.contentScrollPane}>
        <PlantList />
      </div>
      {children}
    </PlannerContentContainer>
  );
};

export default PlantListContent;
