import React, { useMemo } from 'react';
import ReactSelect from 'react-select';

import FormField, { InputContainer, FormLayoutOptionsPreset } from '@gi/form-responsive';
import { PlanUtils } from '@gi/plan';
import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

// TODO: Move to modal defaults somewhere
export const DEFAULT_MODAL_FORM_SECTION_PADDING = 12;
export const DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT = { labelSize: 120 };

export const UNITS_INPUT_LAYOUT: FormLayoutOptionsPreset = {
  desktop: {
    layout: 'row',
    gap: 18,
  },
  mobile: {
    layout: 'row',
    gap: 18,
  },
};

export type YearOption = {
  label: string;
  value: number;
};

interface iPlanYearInputProps {
  errors: string[];
  northernHemisphere: boolean;
  year: number;
  onYearChange: (option: YearOption) => void;
}

export const PlanYearInput = ({ errors, northernHemisphere, year, onYearChange }: iPlanYearInputProps): JSX.Element => {
  // Year options for ReactSelect
  const yearOptions: YearOption[] = useMemo(() => {
    return PlanUtils.getYearOptions(northernHemisphere);
  }, [northernHemisphere]);

  const selectedYearOption: YearOption = useMemo(() => {
    for (let i = 0; i < yearOptions.length; i++) {
      if (yearOptions[i].value === year) {
        return yearOptions[i];
      }
    }

    return { value: year, label: PlanUtils.getYearDisplayString(year, northernHemisphere) };
  }, [northernHemisphere, yearOptions, year]);

  return (
    <FormField htmlFor='plan-year' label='Year' errorText={errors.join(', ')} desktopLayout={DEFAULT_MODAL_FORM_ROW_DESKTOP_LAYOUT}>
      <InputContainer>
        <ReactSelect<YearOption>
          id='plan-year'
          className='select-input'
          maxMenuHeight={200}
          styles={DEFAULT_SELECT_STYLES}
          options={yearOptions}
          value={selectedYearOption}
          onChange={onYearChange}
          isSearchable={false}
          menuPortalTarget={document.body}
        />
      </InputContainer>
    </FormField>
  );
};
