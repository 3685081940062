import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { GuruContentPage } from '@gi/app-guru-types/pages';
import { AsyncOperation } from '@gi/utils';
import { GuruActionCreators, GuruSelectors } from '@gi/app-guru-slice/source/garden-guru-slice';
import { GuruContentType } from '@gi/app-guru-types';
import { LoadingState } from '@gi/constants';
import { createContentIdentifier } from '@gi/app-guru-common';

import ContentPageLoading from './content-page-loading';
import ArticleContentDisplay from '../../pages/articles/article-content-display';
import PestContestDisplay from '../../pages/pests/pest-content-display';
import PlantContentDisplay from '../../pages/plants/plant-content-display';
import VideoContentDisplay from '../../pages/videos/video-content-display';
import FeedbackBanner from '../feedback/feedback-banner';

import ContentPageError from './content-page-error';

import styles from './content-page.module.css';

const ContentPage = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const { slug: strContentId } = useParams();
  const [searchParams] = useSearchParams();

  if (!strContentId) {
    // TODO: redirect to directory?
    return null;
  }

  const contentId = parseInt(strContentId, 10);

  if (Number.isNaN(contentId)) {
    // TODO: redirect to directory?
    return null;
  }

  const searchParamObj = Object.fromEntries(searchParams);
  const contentUUID = createContentIdentifier(contentId, searchParamObj);

  const content = useSelector(GuruSelectors.getContent);
  const page = content[contentUUID] as AsyncOperation<GuruContentPage> | undefined;

  const load = useCallback(
    (force: boolean) => {
      if (page === undefined || force) {
        // This page hasn't yet been requested for loading
        dispatch(GuruActionCreators.loadContent([contentUUID, contentId, searchParamObj, force]));
      }
    },
    [page, contentUUID, contentId, searchParamObj]
  );

  const retry = useCallback(() => {
    load(true);
  }, [load]);

  useEffect(() => {
    load(false);
  }, [page]);

  if (!page || page.status === LoadingState.NONE || page.status === LoadingState.LOADING) {
    return <ContentPageLoading />;
  }

  if (page.status === LoadingState.ERROR) {
    return <ContentPageError retry={retry} />;
  }

  if (page.status === LoadingState.SUCCESS) {
    // Check content type matches contentPageId, otherwise redirect to correct page?

    let contentElement: JSX.Element;
    switch (page.value.type) {
      case GuruContentType.Article:
        contentElement = <ArticleContentDisplay guruArticle={page.value} />;
        break;
      case GuruContentType.Pest:
        contentElement = <PestContestDisplay guruPest={page.value} />;
        break;
      case GuruContentType.Plant:
        contentElement = <PlantContentDisplay guruPlant={page.value} />;
        break;
      case GuruContentType.Video:
        contentElement = <VideoContentDisplay guruVideo={page.value} />;
        break;
      default:
        contentElement = <p>Unknown content type</p>;
    }

    return (
      <>
        {contentElement}
        <div className={`guru-content-width ${styles.feedbackSection}`}>
          <FeedbackBanner />
        </div>
      </>
    );
  }

  return null;
};

export default ContentPage;
