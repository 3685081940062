import PropTypes from 'prop-types';

export const JournalPlantingActionShape = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  dateYMD: PropTypes.string.isRequired,
  dayID: PropTypes.number.isRequired,
  plantCode: PropTypes.string.isRequired,
  varietyName: PropTypes.string.isRequired,
  isPlant: PropTypes.bool.isRequired,
  noOfPlants: PropTypes.number.isRequired,
});

export const JournalWateringActionShape = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  dateYMD: PropTypes.string.isRequired,
  dayID: PropTypes.number.isRequired,
  plantCode: PropTypes.string.isRequired,
  varietyName: PropTypes.string.isRequired,
  supplementAdded: PropTypes.bool.isRequired,
  supplementName: PropTypes.string.isRequired,
});

export const JournalHarvestingActionShape = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  dateYMD: PropTypes.string.isRequired,
  dayID: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  plantCode: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  unitName: PropTypes.string.isRequired,
  varietyName: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
});

export const JournalCareActionShape = PropTypes.shape({
  ID: PropTypes.string.isRequired,
  actionType: PropTypes.string.isRequired,
  dateYMD: PropTypes.string.isRequired,
  dayID: PropTypes.number.isRequired,
  actionPerformed: PropTypes.string.isRequired,
  plantCode: PropTypes.string.isRequired,
  supplementAdded: PropTypes.bool.isRequired,
  supplementName: PropTypes.string.isRequired,
  varietyName: PropTypes.string.isRequired,
});

export const HarvestSummaryShape = PropTypes.shape({
  actionType: PropTypes.string.isRequired,
  plantCode: PropTypes.string.isRequired,
  varietyName: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  weight: PropTypes.number.isRequired,
});

export const JournalActionsShape = PropTypes.arrayOf(
  PropTypes.oneOfType([JournalPlantingActionShape, JournalWateringActionShape, JournalHarvestingActionShape, JournalCareActionShape, HarvestSummaryShape])
);
