import React from 'react';
import { useDispatch } from 'react-redux';

import { IntercomActionCreators } from '@gi/intercom';

import GuruButton from '../buttons/guru-button';
import GuruSection from '../guru-section/guru-section';
import DefaultHeader from '../../header/header-components/default-header';
import GuruHeaderContentPortal from '../../header/guru-header-content-portal';
import { DEFAULT_GURU_HEADER } from '../../context/garden-guru-provider';

import styles from './content-page.module.css';

const DEFAULT_ERROR_MESSAGE = 'An error occurred while trying to load this page. Please check your internet connection and try again.';

interface iProps {
  noUpdateHeader?: boolean;
  retry?: () => void;
  isLoading?: boolean;
  errorMessage?: string;
}

const ContentPageError = ({ noUpdateHeader = false, errorMessage = DEFAULT_ERROR_MESSAGE, retry, isLoading }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  const openIntercom = () => {
    dispatch(IntercomActionCreators.openIntercomWithAnalytics('guru-content-page-error'));
  };

  return (
    <GuruSection>
      {!noUpdateHeader ? (
        <GuruHeaderContentPortal background={DEFAULT_GURU_HEADER.background}>
          <DefaultHeader>Error</DefaultHeader>
        </GuruHeaderContentPortal>
      ) : null}
      <div className={styles.contentPageError}>
        <h2>Failed to load</h2>
        <p>{errorMessage}</p>
        <p>
          If the problem persists, please{' '}
          <button className='button button-inline' type='button' onClick={openIntercom}>
            contact support
          </button>
          .
        </p>
        {retry !== undefined ? (
          <GuruButton onClick={retry} className={styles.retryButton} disabled={isLoading}>
            {isLoading ? <i className='icon-spinner animate-pulse' /> : null} Retry
          </GuruButton>
        ) : null}
      </div>
    </GuruSection>
  );
};

export default ContentPageError;
