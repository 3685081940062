import { DefaultNotificationOptions, NotificationTypes } from '@gi/notifications';

export const createReminderStart = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Creating Reminder',
  icon: 'icon-spinner animate-pulse',
  ID: notificationID,
  type: NotificationTypes.INFO,
  inProgress: true,
});

export const createReminderSuccess: DefaultNotificationOptions = {
  title: 'Reminder Created',
  icon: 'icon-ok',
  type: NotificationTypes.SUCCESS,
  canTimeout: true,
};

export const createReminderFail: DefaultNotificationOptions = {
  title: 'Error Creating Reminder',
  icon: 'icon-attention-alt',
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
};

export const updateReminderStart = (notificationID: number): DefaultNotificationOptions => ({
  title: 'Saving Reminder',
  icon: 'icon-spinner animate-pulse',
  ID: notificationID,
  type: NotificationTypes.INFO,
  inProgress: true,
});

export const updateReminderSuccess: DefaultNotificationOptions = {
  title: 'Reminder Saved',
  icon: 'icon-ok',
  type: NotificationTypes.SUCCESS,
  canTimeout: true,
};

export const updateReminderFail: DefaultNotificationOptions = {
  title: 'Error Saving Reminder',
  icon: 'icon-attention-alt',
  type: NotificationTypes.ERROR,
  canTimeout: false,
  inProgress: false,
};
