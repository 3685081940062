import { Graphics, TextStyle } from 'pixi.js-new';

import { Geometry } from '@gi/math';
import { State, StateDef } from '@gi/state';

import GraphicNode from '../../graphics-node';
import Node, { NodeEvent } from '../../node';
// eslint-disable-next-line import/no-cycle
import TooltipComponent, { TooltipComponentState } from './tooltip-component';
import TransformComponent, { TransformComponentState } from '../transform/transform-component';
import ShapeComponent, { ShapeComponentState } from '../shape/shape-component';
import { ViewportContextState } from '../../nodes/content-root/content-root-context';
import { CameraNodeState } from '../../nodes/camera/camera-node';
import { bindState } from '../../utils/state-utils';
import { createActiveCameraConnector } from '../../utils/camera-utils';
import PointerTrackerComponent, { PointerTrackerComponentState } from '../interactable/pointer-tracker-component';
import RichText from '../../pixi-components/rich-text/rich-text';

const LABEL_COLOR = 0xfeffd4;
const LABEL_TEXT_COLOR = 0x333333;
const LABEL_ALPHA = 0.85;
const LABEL_BORDER_COLOR = 0xd9c89a;
const LABEL_BORDER_ALPHA = 0.85;
const LABEL_BORDER_WIDTH = 1;

const FONT_SIZE = 12;
const FONT_SCALE = 2;

const PADDING_X = 4;
const PADDING_Y = 2;

const CURSOR_OFFSET_X = 0;
const CURSOR_OFFSET_Y = 30;

type TooltipNodeState = StateDef<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  [],
  {
    viewport: ViewportContextState;
    camera: CameraNodeState;
    transform: TransformComponentState;
    tooltip: TooltipComponentState;
    shape: ShapeComponentState;
    pointerTracker: PointerTrackerComponentState;
  }
>;

const DEFAULT_TEXT_STYLE = new TextStyle({
  fontFamily: ['Verdana', 'Helvetica', 'Arial'],
  fontSize: FONT_SIZE * FONT_SCALE,
  fill: LABEL_TEXT_COLOR,
  align: 'center',
});

/**
 * TooltipNode
 * This doesn't really need to be a node, but it was the easiest way to get around zIndex stuff.
 */
class TooltipNode extends GraphicNode {
  type = 'TooltipNode';

  readonly transform: TransformComponent;
  readonly pointerTracker: PointerTrackerComponent;

  #owner: Node | null = null;
  get owner() {
    return this.#owner;
  }

  #anchor: 'top' | 'center' = 'center';

  #graphics: Graphics | null = null;
  #richerText: RichText | null = null;

  state: State<TooltipNodeState>;

  constructor() {
    super();

    this.state = new State({});
    bindState(this.state, this);

    this.zIndex = 9001;

    this.transform = this.components.add(new TransformComponent());
    this.pointerTracker = this.components.add(new PointerTrackerComponent());
    this.state.connectState('pointerTracker', this.pointerTracker.state);

    this.eventBus.on(NodeEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeEvent.BeforeUnbind, this.#onBeforeUnbind);

    // Update visibility whenever we're printing
    this.state.addWatcher(
      (state) => {
        const isPrinting = state.get('viewport', 'isPrinting');
        if (isPrinting !== undefined) {
          this.visible = !isPrinting;
        }
      },
      { otherStates: { viewport: { properties: ['isPrinting'] } } }
    );

    // Update scale whenever camera magnification updates
    this.state.addWatcher(
      (state) => {
        const magnification = state.get('camera', 'magnification');
        if (magnification !== undefined) {
          this.#graphics?.scale.set(1 / magnification);
        }
      },
      { otherStates: { camera: { properties: ['magnification'] } } }
    );

    this.state.addUpdater(
      (state) => {
        if (!state.otherStates.transform || !state.otherStates.tooltip) {
          return; // Required states are missing...
        }

        let finalPos: Vector2;
        const worldPos = state.get('pointerTracker', 'worldPosition');

        if (worldPos) {
          // If worldPos is available, attach the tooltip to the cursor
          const magnification = state.get('camera', 'magnification', 1)!;
          finalPos = Geometry.addPoint(worldPos, {
            x: CURSOR_OFFSET_X / magnification,
            y: CURSOR_OFFSET_Y / magnification,
          });
          this.#anchor = 'top';
        } else {
          // Fall back to attaching the tooltip to the object
          let shapeOffset: Vector2 = { x: 0, y: 0 };
          if (state.otherStates.shape) {
            const points = this.state.get('shape', 'points')!;
            const rotation = this.state.get('transform', 'rotation')!;
            shapeOffset = Geometry.getBoundingBox(...points).center;
            shapeOffset = Geometry.rotateAroundOrigin(shapeOffset, rotation);
          }

          const transformPos = state.get('transform', 'position')!;
          const tooltipOffset = state.get('tooltip', 'offset')!;

          finalPos = Geometry.addPoint(Geometry.addPoint(transformPos, shapeOffset), tooltipOffset);
          this.#anchor = 'center';
        }

        this.transform.state.values.position = finalPos;
        this.#reposition(this.#anchor);
      },
      {
        otherStates: {
          tooltip: { properties: ['offset'] },
          transform: { properties: ['position'] },
          shape: { properties: ['points'] },
          pointerTracker: { properties: ['worldPosition'] },
          camera: { properties: ['magnification'] },
        },
      }
    );

    this.state.addWatcher(
      () => {
        this.#draw();
      },
      { otherStates: { tooltip: { properties: ['text'] } } }
    );

    createActiveCameraConnector(this, this.state, 'camera');
  }

  #onBind = () => {
    this.#graphics = new Graphics();
    this.#graphics.eventMode = 'none';
    this.ownGraphics.addChild(this.#graphics);

    this.#richerText = new RichText(DEFAULT_TEXT_STYLE);
    this.#richerText.textAlign = 'center';
    this.#richerText.scale.set(1 / FONT_SCALE);
    this.#richerText.position = {
      x: -this.#richerText.width / 2,
      y: -this.#richerText.height / 2,
    };
    this.#graphics.addChild(this.#richerText);
  };

  #onBeforeUnbind = () => {
    this.#richerText?.destroy();
    this.#richerText = null;
    this.#graphics?.destroy();
    this.#graphics = null;
  };

  /**
   * Attaches this tooltip to the given node.
   * The given node should have a TooltipComponent attached to it
   * @param node The node to attach to.
   */
  attachTo(node: Node) {
    if (this.#owner !== node) {
      this.#owner = node;
      this.#draw();
      this.#setupStateListener();
    }
  }

  /**
   * Detaches this tooltip from the given node, only if it is currently attached to that node.
   * @param node The node to detach from
   */
  detachFrom(node: Node) {
    if (this.#owner === node) {
      this.detach();
    }
  }

  /**
   * Detaches from the current owner, if any.
   */
  detach() {
    if (this.#owner !== null) {
      this.#owner = null;
      this.#draw();
      this.state.disconnectState('transform');
      this.state.disconnectState('tooltip');
      this.state.disconnectState('shape');
    }
  }

  /**
   * Sets up the listeners on the owner, for changes in position or tooltip state.
   */
  #setupStateListener() {
    if (!this.#owner) {
      return;
    }

    const transform = this.#owner.components.get(TransformComponent);
    if (!transform) {
      throw new Error('Cannot create tooltip watcher: Tooltip is attached to a node with no TransformComponent.');
    }
    this.state.connectState('transform', transform.state);

    const tooltip = this.#owner.components.get(TooltipComponent);
    if (!tooltip) {
      throw new Error('Cannot create tooltip watcher: Tooltip is attached to a node with no TooltipComponent.');
    }
    this.state.connectState('tooltip', tooltip.state);

    const shape = this.#owner.components.get(ShapeComponent);
    if (shape) {
      this.state.connectState('shape', shape.state);
    }
  }

  /**
   * Updates the graphics of this tooltip
   */
  #draw() {
    if (!this.#graphics || !this.#richerText) {
      return;
    }

    if (this.#owner) {
      this.#graphics.visible = true;
      this.#graphics.clear();

      const text = this.state.get('tooltip', 'text');

      if (text === undefined) {
        return;
      }

      this.#richerText.text = text;
      this.#richerText.position = { x: PADDING_X, y: PADDING_Y };

      const totalWidth = this.#richerText.width + PADDING_X * 2;
      const totalHeight = this.#richerText.height + PADDING_Y * 2;

      this.#graphics
        .beginFill(LABEL_COLOR, LABEL_ALPHA)
        .lineStyle({
          color: LABEL_BORDER_COLOR,
          alpha: LABEL_BORDER_ALPHA,
          width: LABEL_BORDER_WIDTH,
        })
        .drawRoundedRect(0, 0, totalWidth, totalHeight, 3)
        .endFill();

      this.#reposition(this.#anchor);
    } else {
      this.#graphics.visible = false;
      this.#graphics.clear();
    }
  }

  #reposition(anchor: 'top' | 'center') {
    if (!this.#graphics) {
      return;
    }
    if (anchor === 'top') {
      this.#graphics.pivot = {
        x: this.#graphics.width / 2 / this.#graphics.scale.x,
        y: 0,
      };
    } else {
      this.#graphics.pivot = {
        x: this.#graphics.width / 2 / this.#graphics.scale.x,
        y: this.#graphics.height / 2 / this.#graphics.scale.y,
      };
    }
  }
}

export default TooltipNode;
