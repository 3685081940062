import React from 'react';

import { ModalPane, ModalPaneSectionGroup, ModalPaneContainer, ModalPaneSection, ModalPaneSectionContent } from '@gi/modal';

import PublishPlanStages from '../publish-plan-stages';
import ProgressBar from './progress-bar';

const stageStrings = {
  [PublishPlanStages.GENERATING_CANVAS]: 'Generating plan',
  [PublishPlanStages.CONVERTING_TO_IMAGE]: 'Generating plan',
  [PublishPlanStages.GENERATING_PREVIEW_IMAGE]: 'Converting to preview',
  [PublishPlanStages.GENERATING_THUMBNAIL_IMAGE]: 'Generating plan',
  [PublishPlanStages.UPLOADING]: 'Uploading',
};

interface iProps {
  stage: PublishPlanStages;
  progress: number;
}

const PublishModalStage2 = ({ stage, progress }: iProps): JSX.Element => {
  return (
    <ModalPaneContainer className='publish-plan-stage-2'>
      <ModalPane>
        <ModalPaneSectionGroup>
          <ModalPaneSection>
            <ModalPaneSectionContent>
              <p>Publishing Plan, this may take a minute or two, please wait.</p>
            </ModalPaneSectionContent>
            <ModalPaneSectionContent>
              <div className='publish-progress-container'>
                <div className='publish-progress-stage'>
                  {stageStrings[stage]} <i className='icon-spinner animate-pulse' />
                </div>
                <div className='publish-progress-bar'>
                  <ProgressBar progress={progress} />
                </div>
              </div>
            </ModalPaneSectionContent>
          </ModalPaneSection>
        </ModalPaneSectionGroup>
      </ModalPane>
    </ModalPaneContainer>
  );
};

export default PublishModalStage2;
