import React, { forwardRef, useContext, useMemo } from 'react';
import { List, ListProps } from 'react-virtualized';

import { useRefDuplicate } from '@gi/react-utils';

import { DragToDrawContext } from './drag-to-draw-context';

import styles from './drag-to-draw-controlled-list.module.css';

/**
 * ListProps has a generic `[key: string]: any` catch-all prop-type for updates, but this confuses
 *  React.forwardRef into thinking the prop type is empty, as it tries to omit `ref` from the
 *  props, but omits the entire type.
 * To get around this, we pre-remove the `[key: string]: any` part of the ListProps so forwardRef
 *  gives the correct prop types.
 * As we want to maintain the ability to define arbitrary props for update purposes, we re-add the
 *  functionality, but using a template literal type to avoid potential clashes with `ref` and
 *  breaking again.
 * Now, any extra props should be defined as `dependantOn(PropName)={prop}`
 */
type NoStringIndex<T> = { [K in keyof T as string extends K ? never : K]: T[K] };
type KnownListProps = NoStringIndex<ListProps> & { [key: `dependantOn${string}`]: any };

/**
 * react-virtualized list, but disables scrolling when a drag-to-draw operation is ongoing.
 */
const DragToDrawControlledList = forwardRef<List, KnownListProps>(({ className, ...remainingProps }, _ref) => {
  const [ref, setRef] = useRefDuplicate<List>(null, _ref);
  const { isDragToDraw } = useContext(DragToDrawContext);

  // Abuse of a useMemo, but it's the only one that runs _before_ re-render, so is the only place we can get accurate offsetWidth.
  // This adds internal padding so that the items don't jump when the scrollbar gets hidden.
  useMemo(() => {
    const list = ref.current;
    if (!list || !list.Grid) {
      return;
    }
    // More grossness. Grid has a private reference to the scrollContainer that we can use.
    const scrollContainer = (list.Grid as any)._scrollingContainer;
    if (!scrollContainer || !(scrollContainer instanceof HTMLElement)) {
      return;
    }

    if (isDragToDraw) {
      const scrollbarSize = scrollContainer.offsetWidth - scrollContainer.clientWidth;
      scrollContainer.style.paddingRight = `${scrollbarSize}px`;
    } else {
      scrollContainer.style.removeProperty('padding-right');
    }
  }, [isDragToDraw]);

  const classNames = useMemo(() => {
    let joinedClassNames = className;
    if (isDragToDraw) {
      if (joinedClassNames) {
        joinedClassNames += ` ${styles.scrollPaused}`;
      } else {
        joinedClassNames = styles.scrollPaused;
      }
    }
    return joinedClassNames;
  }, [className, isDragToDraw]);

  return <List ref={setRef} className={classNames} {...remainingProps} />;
});
DragToDrawControlledList.displayName = 'DragToDrawControlledList';

export default DragToDrawControlledList;
