import { Palette, Swatch } from './palette';

class Palettes {
  byTypeAndValue: { [key: string]: { [key: string]: Swatch } };
  paletteList: Palette[];

  constructor(palettes) {
    this.byTypeAndValue = {};
    this.paletteList = [];

    for (let i = 0; i < palettes.length; i++) {
      this.addPalette(palettes[i]);
    }
  }

  addSwatch(swatch: Swatch): void {
    const typeMap = this.byTypeAndValue[swatch.type];

    if (!typeMap) {
      this.byTypeAndValue[swatch.type] = {};
    }

    this.byTypeAndValue[swatch.type][swatch.value] = swatch;
  }

  hasSwatch(type: string, value: string): boolean {
    return !!(this.byTypeAndValue[type] && this.byTypeAndValue[type][value]);
  }

  getSwatch(type: string, value: string): null | Swatch {
    if (!this.hasSwatch(type, value)) {
      return null;
    }

    return this.byTypeAndValue[type][value];
  }

  addPalette(palette: Palette): void {
    this.paletteList.push(palette);

    for (let i = 0; i < palette.swatches.length; i++) {
      for (let j = 0; j < palette.swatches[i].length; j++) {
        this.addSwatch(palette.swatches[i][j]);
      }
    }
  }

  getPalettes(): Palette[] {
    return [...this.paletteList];
  }
}

export default Palettes;
