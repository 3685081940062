import React, { ReactNode } from 'react';

import { LoadingState } from '@gi/constants';

import { useDirectoryPage } from './directory-page-hooks';
import GuruHeaderContentPortal from '../../header/guru-header-content-portal';
import EmptyLoadingHeader from '../../header/header-components/empty-loading-header';
import DefaultHeader from '../../header/header-components/default-header';
import { DEFAULT_GURU_HEADER } from '../../context/garden-guru-provider';

interface iProps {
  composedPageId: string;
  children?: ReactNode;
}

const DirectoryPageHeader = ({ composedPageId, children }: iProps): JSX.Element | null => {
  const [page] = useDirectoryPage(composedPageId);

  if (!page || page.status === LoadingState.NONE || page.status === LoadingState.LOADING) {
    return (
      <GuruHeaderContentPortal>
        <EmptyLoadingHeader />
      </GuruHeaderContentPortal>
    );
  }

  if (page.status === LoadingState.ERROR) {
    return (
      <GuruHeaderContentPortal background={DEFAULT_GURU_HEADER.background}>
        <DefaultHeader>Error</DefaultHeader>
      </GuruHeaderContentPortal>
    );
  }

  if (page.status === LoadingState.SUCCESS) {
    return (
      <GuruHeaderContentPortal background={page.value.backgroundImage}>
        {children ? children : <DefaultHeader>{page.value.title}</DefaultHeader>}
      </GuruHeaderContentPortal>
    );
  }

  return null; // Unreachable in theory.
};

export default DirectoryPageHeader;
