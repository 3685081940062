/**
 * @typedef {string} PlantingCalendarRow
 */

/**
 * Enum for the different types of planting calendar row
 *
 * @readonly
 * @enum {PlantingCalendarRow}
 */
const PlantingCalendarRows = {
  SOW: 'SOW',
  PLANT: 'PLANT',
  HARVEST: 'HARVEST',
};

export default PlantingCalendarRows;
