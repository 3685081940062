import { Plan } from '../plan';
import { APIPlanDocumentInvalidProperties, APIPlanDocumentInvalidProperty } from '../plan-validation';

function formatItemErrorString<T>(type: string, id: number, invalidProperties: APIPlanDocumentInvalidProperty<T>[]): string {
  return ` - ${type} ${id}: ${invalidProperties.map(({ property, previousValue }) => `${String(property)} (${previousValue})`).join(', ')}`;
}

function formatErrorString(uploaded: boolean, plan: Plan, invalidProperties: APIPlanDocumentInvalidProperties): string {
  const invalidItems = [
    invalidProperties.plants.map((plant) => formatItemErrorString('plant', plant.id, plant.properties)),
    invalidProperties.gardenObjects.map((gardenObject) => formatItemErrorString('garden object', gardenObject.id, gardenObject.properties)),
    invalidProperties.shapes.map((shape) => formatItemErrorString('shape', shape.id, shape.properties)),
    invalidProperties.text.map((text) => formatItemErrorString('text', text.id, text.properties)),
  ]
    .flat()
    .join('\n');
  return `Plan ID: ${plan.id} (${uploaded ? 'uploaded' : 'upload failed'})\n${invalidItems}`;
}

class PlanDataError extends Error {
  uploaded: boolean;
  plan: Plan;
  invalidProperties: APIPlanDocumentInvalidProperties;

  constructor(uploaded: boolean, plan: Plan, invalidProperties: APIPlanDocumentInvalidProperties) {
    super(`API Plan has invalid fields\n${formatErrorString(uploaded, plan, invalidProperties)}`);
    this.uploaded = uploaded;
    this.plan = plan;
    this.invalidProperties = invalidProperties;
  }
}

export default PlanDataError;
