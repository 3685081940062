import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Reminder, Reminders, ReminderNetworkActionCreators, ReminderSelectors, ReminderActionCreators, ReminderUtils } from '@gi/reminders';
import { lexographicSort } from '@gi/utils';
import { RequestSelectors } from '@gi/react-requests';
import { RequestStatus, RequestsUtils } from '@gi/request';
import LoadingButton from '@gi/loading-button';

import DashboardItemSectionTitle from '../../../dashboard-item/dashboard-item-section-title';
import DashboardReminderDay from './dashboard-reminder-day';

import './upcoming-reminders-section.scss';

const MS_IN_A_DAY = 864e5;
// How many days into the past to load reminders
const DAYS_INTO_PAST = 14;
// How many days into the future to load reminders
const DAYS_INTO_FUTURE = 14;

const EmptyRemindersMessage = (): JSX.Element => {
  return (
    <div className='empty-reminders'>
      <div className='empty-reminders-inner'>You don&apos;t have any upcoming reminders for the next {DAYS_INTO_FUTURE} days</div>
    </div>
  );
};

const LoadingRemindersMessage = (): JSX.Element => {
  return (
    <div className='empty-reminders'>
      <div className='empty-reminders-inner'>
        <i className='icon-spinner animate-pulse' /> Loading reminders...
      </div>
    </div>
  );
};

const LoadingRemindersFailedMessage = ({ onRetry }: { onRetry: () => void }): JSX.Element => {
  return (
    <div className='empty-reminders'>
      <div className='empty-reminders-inner'>Failed to load reminders</div>
      <LoadingButton className='empty-reminders-retry' onClick={onRetry}>
        Retry
      </LoadingButton>
    </div>
  );
};

const UpcomingRemindersSection = (): JSX.Element => {
  const dispatch = useDispatch();
  const requests = useSelector(RequestSelectors.getRequests);
  const requestStatus = RequestsUtils.getStatus(requests, ReminderNetworkActionCreators.ReminderRequestNames.LoadDashboardReminders());

  const reminders: Reminders = useSelector(ReminderSelectors.getReminders);
  const [showDismissed, setShowDismissed] = useState(false);

  // Load reminders, starting from DAYS_IN_PAST up to DAYS_IN_FUTURE
  const loadReminders = useCallback(() => {
    dispatch(ReminderNetworkActionCreators.loadReminders(new Date(Date.now() - DAYS_INTO_PAST * MS_IN_A_DAY), DAYS_INTO_PAST + DAYS_INTO_FUTURE));
  }, [dispatch]);

  useEffect(() => {
    loadReminders();
  }, []);

  const sortedDates = [...reminders.dates];
  sortedDates.sort(lexographicSort);

  // Show a message if there are no reminders to show
  const messageComponent = useMemo<JSX.Element | null>(() => {
    if (requestStatus === RequestStatus.IN_PROGRESS) {
      return <LoadingRemindersMessage />;
    }
    if (requestStatus === RequestStatus.FAIL) {
      return <LoadingRemindersFailedMessage onRetry={loadReminders} />;
    }
    const showEmptyMessage = showDismissed ? ReminderUtils.getCount(reminders) === 0 : ReminderUtils.getNotDismissedCount(reminders) === 0;
    if (showEmptyMessage) {
      return <EmptyRemindersMessage />;
    }
    return null;
  }, [requestStatus, showDismissed, reminders]);

  return (
    <>
      <DashboardItemSectionTitle className='upcoming-reminders-title'>
        <span>Upcoming Reminders</span>
        <label>
          Show Dismissed <input type='checkbox' checked={showDismissed} onChange={(e) => setShowDismissed(e.target.checked)} />
        </label>
      </DashboardItemSectionTitle>
      <div className='upcoming-reminders-section'>
        <div className='reminders-scrollpane reminders-list'>
          {messageComponent}
          {sortedDates.map((dateYMD: string) => {
            const date = moment(dateYMD);
            return (
              <DashboardReminderDay
                key={dateYMD}
                date={date}
                dateYMD={dateYMD}
                reminders={reminders}
                showDismissed={showDismissed}
                restore={(reminder: Reminder) => {
                  dispatch(ReminderNetworkActionCreators.updateReminder({ ...reminder, dismissed: false }));
                }}
                dismiss={(reminder: Reminder) => {
                  dispatch(ReminderNetworkActionCreators.updateReminder({ ...reminder, dismissed: true }));
                }}
                edit={(reminder: Reminder) => dispatch(ReminderActionCreators.openEditReminder(reminder))}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default UpcomingRemindersSection;
