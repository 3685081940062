export enum PestType {
  Pest = 'Pest',
  Beneficial = 'Beneficial',
  Disease = 'Disease',
}

export enum PestTag {
  Pest = 'pest',
  Disease = 'disease',
  Beneficial = 'beneficial',
  SoilBorne = 'soil-borne',
  AffectsRoots = 'affects-roots',
  AffectsLeaves = 'affects-leaves',
  AffectsFlowers = 'affects-flowers',
  CommonInGreenhouse = 'common-in-greenhouses',
  AffectsFruit = 'affects-fruit',
  AffectsStems = 'affects-stems',
  AffectsSeeds = 'affects-seeds',
  Pollinator = 'pollinator',
  Predator = 'predator',
  AffectsSeedlings = 'affects-seedlings',
  Composter = 'composter',
  AffectsBark = 'affects-bark',
}

export type PestImageData = {
  imageUrl: string;
  caption?: string;
  attribution?: string;
  source?: string;
  sourceUrl?: string;
};

export type Pest = {
  id: number;
  code: string;
  name: string;
  otherName: string;
  autoCompleteNames: string[];
  slug: string;
  scientificName: string;
  countryCode: string;
  type: PestType;
  hostPlantFamilies: number[];
  synonymCodes: string[];
  hostCropCodes: string[];
  activityStartRelativeToLastFrost: null | number;
  activityEndRelativeToFirstFrost: null | number;
  highRiskStartRelativeToLastFrost: null | number;
  highRisKEndRelativeToLastFrost: null | number;
  highRiskStartRelativeToFirstFrost: null | number;
  highRisKEndRelativeToFirstFrost: null | number;
  tags: PestTag[];
  images: PestImageData[];
  text: {
    location: string;
    hostPlantsWild: string;
    hostPlantsGarden: string;
    hostCrops: string;
    description: string;
    damage: string | null;
    prevention: string | null;
    managing: string | null;
    benefits: string | null;
    habitat: string | null;
    attracting: string | null;
    tips: string | null;
  };
};
