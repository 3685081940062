import React, { HTMLAttributes, ReactNode, useContext } from 'react';

import { DragToDrawContext } from './drag-to-draw-context';

import styles from './drag-to-draw-button.module.css';

interface iProps extends Pick<HTMLAttributes<HTMLButtonElement>, 'className' | 'style'> {
  children: ReactNode;
  onDrag: (event: PointerEvent) => void;
  onClick: () => void;
}

/**
 * A button that when dragged, registers the drag with the DragToDraw context to start tracking
 *  the drag and forwarding pointer events if necessary. Supports drags with both pointer and touch.
 */
const DragToDrawButton = ({ children, onDrag, onClick, className, ...buttonProps }: iProps): JSX.Element => {
  const { startMonitoringPointer, startMonitoringTouch } = useContext(DragToDrawContext);

  return (
    <button
      type='button'
      onPointerDown={(e) => startMonitoringPointer(e.currentTarget, e.nativeEvent, onDrag)}
      onTouchStart={(e) => startMonitoringTouch(e.currentTarget, e.nativeEvent, onDrag)}
      onClick={onClick}
      className={className ? `${className} ${styles.button}` : styles.button}
      {...buttonProps}
    >
      {children}
    </button>
  );
};

export default DragToDrawButton;
