import React, { useContext } from 'react';
import { DrawingToolsContext } from '../drawing-tools-context';

import SearchHelpModal from './search-help-modal';

const SearchHelpModalRenderer = () => {
  const { searchHelpModalOpen } = useContext(DrawingToolsContext);

  if (searchHelpModalOpen) {
    return <SearchHelpModal />;
  }

  return null;
};

export default SearchHelpModalRenderer;
