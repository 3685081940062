import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Anchor, Drawer, DrawerContent, DrawerHeader, DrawerSticky } from '@gi/mobile-components';
import FormField, { createFormValues, FormLayout, FormSection, InputContainer } from '@gi/form-responsive';
import { createPlantFilters, DrawingToolsContext, PlantFilterComponents } from '@gi/drawing-tools';
import { updateFilters } from '@gi/filters';
import { FormFieldOptionsPreset } from '@gi/form-responsive/source/form-field';
import { LocalSettingsSelectors } from '@gi/local-settings';
import { PERENNIAL } from '@gi/constants';
import { ResourceContext, userPlantingCalendarsNotNull } from '@gi/resource-provider';
import { SessionSelectors } from '@gi/react-session';
import { UserUtils } from '@gi/user';
import { AutumnPlantingFilterHelpModal } from '@gi/help-modals';

import CompanionTypeDropdown, { CompanionRelationType } from './companion-type-dropdown';
import { convertCompanionFilterToEnum } from './utils';

import './plants-filters-drawer.scss';
import { LocalFilterValues } from './filter-types';
import CheckboxFilter from './checkbox-filter';

const PLANTING_TIMES_PRESET: FormFieldOptionsPreset = {
  desktop: {
    layout: 'row',
    labelSize: 150,
    inputSize: 'full',
  },
  mobile: {
    layout: 'row',
    labelSize: 150,
    inputSize: 'full',
  },
};

interface iProps {
  open: boolean;
  onClose: () => void;
  anchor?: Anchor;
}

const PlantsFiltersDrawer = ({ open, onClose, anchor = 'BOTTOM' }: iProps): JSX.Element => {
  const user = useSelector(SessionSelectors.getUser);
  const { plantFilters, northernHemisphere, setPlantFilters, favouritePlants, resetPlantFilters, filteredPlants } = useContext(DrawingToolsContext);
  const { userPlantingCalendars } = useContext(ResourceContext);
  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);
  const notNullUserPlantingCalendars = userPlantingCalendarsNotNull(userPlantingCalendars);

  const [showAutumnPlantsFilterModal, setShowAutumnPlantsFilterModal] = useState(false);

  const companionsMode = plantFilters.filters.companion?.inputs?.enabled;
  const tropicalClimate = user ? UserUtils.isTropicalClimate(user) : true;

  const createFilterFormValues = () => {
    return createFormValues<LocalFilterValues>({
      type: { value: plantFilters.filters.type.inputs.type },
      favourites: { value: plantFilters.filters.favourites.inputs.enabled },
      perennial: { value: !plantFilters.filters.perennial.inputs.enabled },
      sfg: { value: plantFilters.filters.sfg.inputs.enabled },
      easyToGrow: { value: plantFilters.filters.easyToGrow.inputs.enabled },
      frostTolerant: { value: plantFilters.filters.frostTolerant.inputs.enabled },
      partialShade: { value: plantFilters.filters.partialShade.inputs.enabled },
      autumn: { value: plantFilters.filters.autumn.inputs.enabled },
      cropFamily: { value: plantFilters.filters.cropFamily.inputs.familyID },
      plantingDates: {
        value: {
          sowMonth: plantFilters.filters.plantingDates.inputs.sowMonth,
          plantMonth: plantFilters.filters.plantingDates.inputs.plantMonth,
          harvestMonth: plantFilters.filters.plantingDates.inputs.harvestMonth,
        },
      },
      companionType: { value: convertCompanionFilterToEnum(plantFilters.filters.companion.inputs) },
      tropical: { value: plantFilters.filters.tropical.inputs.enabled },
    });
  };

  const [localFilters, setLocalFilters] = useState(createFilterFormValues());

  const applyFilters = (values: Readonly<LocalFilterValues>) => {
    setPlantFilters(
      updateFilters(plantFilters, {
        type: { type: values.type },
        favourites: { enabled: values.favourites, favouritePlants },
        perennial: { enabled: !values.perennial },
        sfg: { enabled: values.sfg },
        easyToGrow: { enabled: values.easyToGrow },
        frostTolerant: { enabled: values.frostTolerant },
        partialShade: { enabled: values.partialShade },
        autumn: { enabled: values.autumn },
        cropFamily: { familyID: values.cropFamily },
        plantingDates: values.plantingDates,
        companion: {
          onlyHelper: values.companionType === CompanionRelationType.ONLY_HELPED || values.companionType === CompanionRelationType.ONLY_MUTUAL,
          onlyHelped: values.companionType === CompanionRelationType.ONLY_HELPER || values.companionType === CompanionRelationType.ONLY_MUTUAL,
        },
        tropical: { enabled: values.tropical },
      })
    );
  };

  const setFilterValue = <K extends keyof LocalFilterValues>(key: K, value: LocalFilterValues[K]) => {
    const newValues = localFilters.setValue(key, { value });
    setLocalFilters(newValues);
    applyFilters(newValues.values);
  };

  /**
   * Resets the filters
   */
  const resetFilters = () => {
    if (companionsMode) {
      setPlantFilters(
        updateFilters(createPlantFilters(sfgMode, notNullUserPlantingCalendars, tropicalClimate), {
          companion: {
            ...plantFilters.filters.companion.inputs,
            onlyHelper: false,
            onlyHelped: false,
          },
        })
      );
    } else {
      resetPlantFilters();
    }
  };

  /**
   * Reload the filters every time we open, and if the plantFilters update while we're open
   */
  useEffect(() => {
    if (open) {
      setLocalFilters(createFilterFormValues());
    }
  }, [plantFilters, open]);

  const { type, cropFamily, plantingDates, companionType } = localFilters.values;

  return (
    <>
      <Drawer open={open} onClose={onClose} anchor={anchor} width={500}>
        <DrawerHeader onClose={onClose} as='h2'>
          {companionsMode ? 'Filter Companions by ' : 'Filter Plants by '}
          <span className='plants-filters-drawer-header-subtext'> ({filteredPlants.length} results)</span>
        </DrawerHeader>
        <DrawerContent padX padY>
          <FormSection padding={0}>
            {/* All checkbox filters */}
            <FormLayout mobileLayout={{ layout: 'column', gap: 0 }} desktopLayout={{ layout: 'column', gap: 0 }}>
              <CheckboxFilter filter='favourites' label='Favorites' localFilters={localFilters} setFilterValue={setFilterValue} />
              <CheckboxFilter
                filter='perennial'
                label='Hide Perennials'
                disabled={type === PERENNIAL}
                localFilters={localFilters}
                setFilterValue={setFilterValue}
              />
              {sfgMode ? <CheckboxFilter filter='sfg' label='Only Show SFG Plants' localFilters={localFilters} setFilterValue={setFilterValue} /> : null}
              <CheckboxFilter filter='easyToGrow' label='Easy to Grow' localFilters={localFilters} setFilterValue={setFilterValue} />
              <CheckboxFilter filter='frostTolerant' label='Frost Tolerant' localFilters={localFilters} setFilterValue={setFilterValue} />
              <CheckboxFilter filter='partialShade' label='Partial Shade Tolerant' localFilters={localFilters} setFilterValue={setFilterValue} />
              <CheckboxFilter
                filter='autumn'
                label='Suitable for Autumn Planting'
                onHelpClick={() => setShowAutumnPlantsFilterModal(true)}
                localFilters={localFilters}
                setFilterValue={setFilterValue}
              />
              <CheckboxFilter
                filter='tropical'
                label={tropicalClimate ? 'Hide Tropicals' : 'Include Tropicals'}
                inverted={tropicalClimate}
                localFilters={localFilters}
                setFilterValue={setFilterValue}
              />
            </FormLayout>
            {/* Companions filter */}
            {companionsMode ? (
              <FormField label='Companions' htmlFor='companionType' desktopLayout={{ inputSize: 'full' }}>
                <InputContainer size='full'>
                  <CompanionTypeDropdown
                    value={companionType}
                    onChange={(value) => setFilterValue('companionType', value)}
                    menuPlacement='auto'
                    id='companionType'
                  />
                </InputContainer>
              </FormField>
            ) : null}
            {/* Plant Type filter */}
            <FormField label='Type' htmlFor='type' desktopLayout={{ inputSize: 'full' }}>
              <InputContainer size='full'>
                <PlantFilterComponents.TypeFilterDropdown type={type} onTypeChange={(t) => setFilterValue('type', t)} menuPlacement='auto' id='type' />
              </InputContainer>
            </FormField>
            {/* Crop rotation family filter */}
            <FormField label='Crop Rotation Family' htmlFor='cropFamily' desktopLayout={{ inputSize: 'full' }}>
              <InputContainer size='full'>
                <PlantFilterComponents.CropFamilyDropdown
                  selectedFamilyID={cropFamily}
                  onSelectedFamilyChange={(family) => setFilterValue('cropFamily', family)}
                  menuPlacement='auto'
                  id='cropFamily'
                />
              </InputContainer>
            </FormField>
            {/* Planting times filters */}
            <p style={{ marginTop: 6 }}>
              <b>Planting Times</b>
            </p>
            <FormField label={<span className='plants-filters-drawer-sow-in'>Can sow in</span>} htmlFor='sowMonth' layoutPreset={PLANTING_TIMES_PRESET}>
              <InputContainer size='full'>
                <PlantFilterComponents.MonthDropdown
                  value={plantingDates.sowMonth}
                  northernHemisphere={northernHemisphere}
                  onChange={(value) => setFilterValue('plantingDates', { ...plantingDates, sowMonth: value })}
                  menuPlacement='auto'
                  id='sowMonth'
                />
              </InputContainer>
            </FormField>
            <FormField label={<span className='plants-filters-drawer-plant-in'>Can plant in</span>} htmlFor='plantMonth' layoutPreset={PLANTING_TIMES_PRESET}>
              <InputContainer size='full'>
                <PlantFilterComponents.MonthDropdown
                  value={plantingDates.plantMonth}
                  northernHemisphere={northernHemisphere}
                  onChange={(value) => setFilterValue('plantingDates', { ...plantingDates, plantMonth: value })}
                  menuPlacement='auto'
                  id='plantMonth'
                />
              </InputContainer>
            </FormField>
            <FormField
              label={<span className='plants-filters-drawer-harvest-in'>Can harvest in</span>}
              htmlFor='harvestMonth'
              layoutPreset={PLANTING_TIMES_PRESET}
            >
              <InputContainer size='full'>
                <PlantFilterComponents.MonthDropdown
                  value={plantingDates.harvestMonth}
                  northernHemisphere={northernHemisphere}
                  onChange={(value) => setFilterValue('plantingDates', { ...plantingDates, harvestMonth: value })}
                  menuPlacement='auto'
                  id='harvestMonth'
                />
              </InputContainer>
            </FormField>
          </FormSection>
        </DrawerContent>
        <DrawerSticky position='BOTTOM' padX padY border>
          <FormLayout desktopLayout={{ layout: 'row', xAlign: 'stretch' }} mobileLayout={{ layout: 'row', xAlign: 'stretch' }}>
            <button className='button button-secondary' type='button' onClick={resetFilters}>
              Reset
            </button>
            <button className='button button-primary' type='button' onClick={onClose}>
              Done
            </button>
          </FormLayout>
        </DrawerSticky>
      </Drawer>
      {showAutumnPlantsFilterModal ? <AutumnPlantingFilterHelpModal closeModal={() => setShowAutumnPlantsFilterModal(false)} /> : null}
    </>
  );
};

export default PlantsFiltersDrawer;
