import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactSelect, { FormatOptionLabelMeta } from 'react-select';

import { LoadingState } from '@gi/constants';
import { PlanBackgroundImage, PlanBackgroundImageUtils } from '@gi/plan';
import { InputRemoteImage, useImage } from '@gi/image-editor';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

import { ImageFitMode, getImageDimensionsForFitMode, getImageFitMode } from './plan-background-image-fit-utils';

import styles from './styles.module.css';

const SELECT_STYLES = {
  ...DEFAULT_SELECT_STYLES,
  control: (provided, state) => ({
    ...DEFAULT_SELECT_STYLES.control(provided, state),
    minWidth: 'unset',
  }),
};

interface DropdownOption {
  value: ImageFitMode;
  title: string;
  description?: string;
}

const Options: DropdownOption[] = [
  {
    value: ImageFitMode.CUSTOM,
    title: 'None',
    // description: 'Background image can be scaled to any size and aspect ratio',
  },
  {
    value: ImageFitMode.FILL,
    title: 'Fill',
    // description: 'Background image will be scaled to match the width/height of the plan, ignoring aspect ratio',
  },
  {
    value: ImageFitMode.CONTAIN,
    title: 'Contain',
    // description: 'Background image will be scaled to be contained with width/height of the plan while maintaining aspect ratio',
  },
  {
    value: ImageFitMode.COVER,
    title: 'Cover',
    // description: 'Background image will be scaled to cover the width/height of the plan while maintaining aspect ratio',
  },
];

function getDropdownOptionLabel(option: DropdownOption, { context, selectValue }: FormatOptionLabelMeta<DropdownOption>): JSX.Element {
  if (context === 'value') {
    return <span>{option.title}</span>;
  }
  const active = selectValue.includes(option);
  return (
    <div className={styles.dropdownOption} data-active={active}>
      <span>{option.title}</span>
      {option.description ? <span>{option.description}</span> : null}
    </div>
  );
}

interface iProps {
  backgroundImage: PlanBackgroundImage;
  planDimensions: Dimensions;
  onChange: (backgroundImage: PlanBackgroundImage) => void;
  id?: string;
  disabled?: boolean;
}

const PlanBackgroundImageFitDropdown = ({ backgroundImage, onChange, planDimensions, id, disabled }: iProps): JSX.Element => {
  const imageGetter = useMemo<InputRemoteImage>(() => {
    return { imageSrc: PlanBackgroundImageUtils.getImageURL(backgroundImage.src.id, backgroundImage.src.crop) };
  }, [backgroundImage?.src]);

  const image = useImage({ image: imageGetter });

  const [hasSelectedCustom, setHasSelectedCustom] = useState<boolean>(false);

  useEffect(() => {
    setHasSelectedCustom(false);
  }, [backgroundImage, planDimensions]);

  const selectedOption = useMemo(() => {
    const isCentred = backgroundImage.position.x === planDimensions.width / 2 && backgroundImage.position.y === planDimensions.height / 2;
    const isNotRotated = backgroundImage.rotation === 0;

    if (hasSelectedCustom || !isCentred || !isNotRotated || image.status !== LoadingState.SUCCESS) {
      return Options.find((option) => option.value === ImageFitMode.CUSTOM);
    }
    const mode = getImageFitMode(planDimensions, image.value.width / image.value.height, backgroundImage.dimensions);
    return Options.find((option) => option.value === mode);
  }, [planDimensions, backgroundImage, hasSelectedCustom, image]);

  const handleOnChange = useCallback(
    (option: DropdownOption) => {
      if (option.value === ImageFitMode.CUSTOM) {
        if (!hasSelectedCustom) {
          setHasSelectedCustom(true);
        }
        return;
      }

      if (image.status !== LoadingState.SUCCESS) {
        return;
      }

      const dimensions = getImageDimensionsForFitMode(planDimensions, option.value, image.value.width / image.value.height);

      onChange({
        rotation: 0,
        dimensions,
        position: { x: planDimensions.width / 2, y: planDimensions.height / 2 },
        src: backgroundImage.src,
      });
    },
    [onChange, planDimensions, image, backgroundImage, hasSelectedCustom]
  );

  return (
    <ReactSelect<DropdownOption>
      styles={SELECT_STYLES}
      options={Options}
      value={selectedOption}
      onChange={handleOnChange}
      formatOptionLabel={getDropdownOptionLabel}
      menuPortalTarget={document.body}
      menuPlacement='auto'
      inputId={id}
      isSearchable={false}
      isDisabled={disabled || image.status !== LoadingState.SUCCESS}
    />
  );
};

export default PlanBackgroundImageFitDropdown;
