import { Container } from 'pixi.js-new';
import { randomUUID } from '@gi/utils';
import type Engine from './engine';
import EventBus from './event-bus';
import type NodeComponent from './node-component/node-component';
import NodeComponentMap from './node-component/collection/node-component-map';
import NodeComponentCollection from './node-component/collection/node-component-collection';
import { ClassOf, InspectableClassData, TimeData } from './types';

/**
 * On Parent Set
 * - OnAttach (node has a parent)
 * - OnBind (node has an engine from the parent)
 *  - All children OnBind
 *
 * On Parent Changed
 * - OnBeforeUnbind
 *  - All children OnBeforeUnbind
 * - OnUnbind
 *  - All children OnUnbind
 * - OnBeforeDeach
 * - OnDetach
 * - Same as above "On Parent Set"
 *
 * OnDestroy
 * - Same as above, without "On Parent Set"
 * - OnDestroyed
 */

export enum NodeEvent {
  DidAttach = 'DidAttach',
  DidDetach = 'DidDetach',
  BeforeDetach = 'BeforeDetach',
  DidBind = 'DidBind',
  DidUnbind = 'DidUnbind',
  BeforeUnbind = 'BeforeUnbind',
  ChildrenChanged = 'ChildrenChanged',
  Tick = 'Tick',
  Destroyed = ' BeforeDestroy',
}

export type NodeEventActions = {
  [NodeEvent.BeforeDetach]: () => void;
  [NodeEvent.DidDetach]: () => void;
  [NodeEvent.DidAttach]: () => void;
  [NodeEvent.BeforeUnbind]: () => void;
  [NodeEvent.DidUnbind]: () => void;
  [NodeEvent.DidBind]: () => void;
  [NodeEvent.ChildrenChanged]: (added: Node[], removed: Node[]) => void;
  [NodeEvent.Tick]: (timeData: TimeData) => void;
  [NodeEvent.Destroyed]: () => void;
};

class Node {
  readonly type: string = 'Node';
  readonly uuid: string = randomUUID();
  name: string = '';

  readonly eventBus: EventBus<NodeEventActions> = new EventBus();

  #parent: Node | null = null;
  #engine: Engine | null = null;
  #children: Node[] = [];
  #destroyed: boolean = false;
  #zGroup: number = 0;
  #zIndex: number = 0;

  #components: NodeComponentCollection<NodeComponent> = new NodeComponentCollection();
  #contexts: NodeComponentMap<NodeComponent> = new NodeComponentMap();

  readonly #container: Container;
  readonly ownGraphics: Container;
  readonly #childGraphics: Container; // Contains the graphics of all child nodes
  /** Contains all graphics when `shouldProxyContent` is enabled.
   *  Acts the same as `#container`, but can be manually assigned as a child of a pixi object */
  #containerProxy: Container | null = null;

  #visible: boolean = true;
  get visible() {
    return this.#visible;
  }
  set visible(visible: boolean) {
    if (this.#visible === visible) {
      return;
    }
    this.#visible = visible;
    this.#container.visible = visible;
    if (this.#containerProxy) {
      this.#containerProxy.visible = visible;
    }
  }

  constructor() {
    this.#components.parent = this;
    this.#contexts.parent = this;

    this.#container = new Container();
    this.ownGraphics = new Container();
    this.#childGraphics = new Container();
    this.#container.addChild(this.ownGraphics, this.#childGraphics);
  }

  /**
   * Returns the container which contains this nodes child node containers and it's own graphicContainer
   *
   * Children should not be added to this directly
   *
   * // TODO: Pass this.#container into the transform component when it's constructed, rather than having the transform component fetch it itself
   */
  getContainer(): Container {
    return this.#containerProxy ?? this.#container;
  }

  // Returns the container for all graphics belonging directly to this node.
  // NOTE: Own graphics always appear BEHIND the graphics of the node's children.
  // If we ever need ownGraphics to appear on-top, we should add a new foreground graphics layer and append it after `#childGraphics`.
  getOwnGraphics(): Container {
    return this.ownGraphics;
  }

  // Returns the child nodes of this node
  get children() {
    return this.#children;
  }

  get components() {
    return this.#components;
  }

  get contexts() {
    return this.#contexts;
  }

  // Returns true if this node has a parent.
  get attached() {
    return this.parent !== null;
  }

  // Returns true if this node is in a tree attached to an engine.
  get bound() {
    return this.#engine !== null;
  }

  // Returns the engine this node is associated with (if any)
  get engine() {
    return this.#engine;
  }

  // The parent node this node is attached to
  get parent() {
    return this.#parent;
  }

  // Returns true if this node has been destroyed. No not attempt to use this node if true.
  get destroyed() {
    return this.#destroyed;
  }

  /** Works like a layer index. Nodes are sorted by `zGroup`, then `zIndex` within that group. */
  get zGroup() {
    return this.#zGroup;
  }
  set zGroup(zGroup: number) {
    if (zGroup === this.zGroup) {
      return;
    }
    this.#zGroup = zGroup;
    if (this.parent) {
      this.parent.#onChildrenZIndexChanged([this]);
    }
  }

  /** Depth sort order. Larger numbers display towards the top. Used to sort against nodes with the same `zGroup`. */
  get zIndex() {
    return this.#zIndex;
  }
  set zIndex(zIndex: number) {
    if (zIndex === this.zIndex) {
      return;
    }
    this.#zIndex = zIndex;
    this.#container.zIndex = zIndex;
    if (this.#containerProxy) {
      this.#containerProxy.zIndex = zIndex;
    }
    if (this.parent) {
      this.parent.#onChildrenZIndexChanged([this]);
    }
  }

  /**
   * Use this option if you want to manually assign the container content to a different Pixi child.
   *  For example, moving the content of a camera node to attach to the engine root node instead of its parent.
   *
   * Replaces the old `autoAttachContainer`, as `autoAttachContainer = true` is now the only behaviour.
   *
   * Nodes auto-sort themselves based on z-index. The position of the node in the node hierarchy determines
   *  the position of the pixi container in the pixi hierarchy. This means there must always be a 1-1 mapping
   *  from Node to #container. This means #container must never have its parent manually set externally.
   *
   * If you want to manually set the parent, setting `shouldProxyContent` to `true` will move all the graphics
   *  of this node onto a proxy container, (automatically returned with `this.getContainer()`). You can then
   *  manually set the parent of the returned container.
   *
   * Recommend setting this in the constructor once and not editing again.
   */
  get shouldProxyContent() {
    return this.#containerProxy !== null;
  }
  set shouldProxyContent(shouldProxy: boolean) {
    if (shouldProxy && this.#containerProxy === null) {
      // Enable the proxy, move all the graphics containers onto the proxy container.
      this.#containerProxy = new Container();
      this.#containerProxy.addChild(...this.#container.children);
    } else if (!shouldProxy && this.#containerProxy !== null) {
      // Disable the proxy, move all the graphics containers back to the root container
      this.#container.addChild(...this.#containerProxy.children);
      this.#containerProxy.destroy();
      this.#containerProxy = null;
    }
  }

  // Called when this component is about to be detatched from a node (is this ever going to happen?)
  #onBeforeDetach() {
    this.eventBus.emit(NodeEvent.BeforeDetach);
  }

  // Called when this component is detatched from a node (is this ever going to happen?)
  #onDetach() {
    this.eventBus.emit(NodeEvent.DidDetach);
  }

  // Called when this component is attached to a node (again, maybe not necessary if the above can't happen)
  #onAttach() {
    this.eventBus.emit(NodeEvent.DidAttach);
  }

  // Called just before the parent node unbinds from the engine.
  #onBeforeUnbind() {
    this.eventBus.emit(NodeEvent.BeforeUnbind);
    this.components.bound = false;
    this.contexts.bound = false;
  }

  // Called when the parent node binds to an engine.
  #onBind() {
    this.engine?.registerNode(this);
    this.components.bound = true;
    this.contexts.bound = true;
    this.eventBus.emit(NodeEvent.DidBind);
  }

  // Called when the parent node unbinds from the engine.
  #onUnbind() {
    this.engine?.unregisterNode(this);
    this.eventBus.emit(NodeEvent.DidUnbind);
  }

  // Runs all events associated with attaching to a new parent
  #doAttach(parent: Node) {
    if (this.attached) {
      return;
    }
    this.#parent = parent;
    this.#onAttach();

    if (parent.engine) {
      this.#doBind(parent.engine);
    }
  }

  // Runs all events asociated with changing parent when we had a valid parent in the past.
  #doDetach() {
    if (this.parent === null) {
      return;
    }
    this.#doUnbind();
    this.#onBeforeDetach();
    this.#parent = null;
    this.#onDetach();
  }

  // Runs all events associated with changing engine when we had a valid engine in the past
  #doUnbind() {
    if (this.bound === false) {
      return;
    }
    this.#onBeforeUnbind();
    this.children.forEach((child) => child.#doUnbind());
    this.#engine = null;
    this.#onUnbind();
  }

  // Runs all events associated with gianing a new engine
  #doBind(engine: Engine) {
    if (this.bound === true) {
      return;
    }
    this.#engine = engine;
    this.#onBind();
    this.children.forEach((child) => {
      child.#doBind(engine);
    });
  }

  // Returns the index the child should use based off of its z-index
  #getChildIndex(node: Node) {
    let insertAt: number = 0;
    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      if (child === node) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (child.zGroup > node.zGroup) {
        break;
      }
      if (child.zGroup === node.zGroup && child.zIndex > node.zIndex) {
        break;
      }
      insertAt++;
    }
    return insertAt;
  }

  // Called when a childs z-index changes.
  #onChildrenZIndexChanged(nodes: Node[]) {
    nodes.forEach((node) => {
      const currentIndex = this.children.indexOf(node);
      const insertAt = this.#getChildIndex(node);
      if (currentIndex !== insertAt) {
        this.children.splice(currentIndex, 1);
        this.children.splice(insertAt, 0, node);
        this.#childGraphics.setChildIndex(node.#container, insertAt);
      }
    });
  }

  // Called when new children are added to this node
  #onChildrenAdded(nodes: Node[]) {
    this.eventBus.emit(NodeEvent.ChildrenChanged, nodes, []);
    this.requestRender();
  }

  // Called when children are removed from this node
  #onChildrenRemoved(nodes: Node[]) {
    nodes.forEach((node) => {
      this.#childGraphics.removeChild(node.#container);
    });
    this.eventBus.emit(NodeEvent.ChildrenChanged, [], nodes);
    this.requestRender();
  }

  /**
   * Adds the given nodes as children to this node
   * @param nodes The node(s) to add to this node
   */
  addChildren(...nodes: Node[]) {
    nodes.forEach((node) => {
      if (node.parent !== null) {
        node.remove();
      }
      const insertAt = this.#getChildIndex(node);
      this.children.splice(insertAt, 0, node);
      this.#childGraphics.addChildAt(node.#container, insertAt);

      node.#doAttach(this);
    });
    this.#onChildrenAdded(nodes);
  }

  /**
   * Removes the given node(s) from this node
   * @param nodes The node(s) to remove
   */
  removeChildren(...nodes: Node[]) {
    nodes.forEach((node) => {
      if (node.parent !== this) {
        throw new Error("Tried to remove child node that wasn't attached to this node");
      }
      const index = this.children.indexOf(node);
      if (index === -1) {
        throw new Error("Tried to remove child node that wasn't attached to this node");
      }
      this.children.splice(index, 1);
      node.#doDetach();
    });
    this.#onChildrenRemoved(nodes);
  }

  /**
   * Adds nodes as children to this node, but skips unbinding/detaching/attaching/binding of the nodes.
   * This is currently only being used for selection changes, as that requires changing the parent of everythign in a selection.
   * This is taking ages with 100s of plants when using box select, as things get destroyed and re-created again pointlessly.
   * TODO: Better handling of nodes changing parents with the same engine.
   * @param nodes The nodes to add as children
   */
  unsafeAddChildren(...nodes: Node[]) {
    nodes.forEach((node) => {
      if (node.parent !== this) {
        if (node.parent) {
          const index = node.parent.children.indexOf(node);
          if (index !== -1) {
            node.parent.children.splice(index, 1);
          }
        }
        node.#parent = this;
        const insertAt = this.#getChildIndex(node);
        this.children.splice(insertAt, 0, node);
        this.#childGraphics.addChildAt(node.#container, insertAt);
      }
    });
    this.#onChildrenAdded(nodes);
  }

  /**
   * Returns the closest instance of the given context in the node tree.
   * Will error if the context doesn't exist in the tree.
   * @param context The context type to find
   * @returns The nearest instance of the context
   */
  getContext<T extends NodeComponent>(context: ClassOf<T>): T {
    const result = this.contexts.get(context);
    if (result !== null) {
      return result;
    }
    if (this.parent) {
      return this.parent.getContext(context);
    }
    throw new Error(`Couldn't find '${context.prototype.constructor.name}' context in node tree from node ${this.uuid}`);
  }

  /**
   * Attempts to return the closest instance of the given context in the node tree.
   * If none is found, will return null instead of erroring.
   * @param context The context type to find
   * @returns The nearest instance of the context, or null if none exists
   */
  tryGetContext<T extends NodeComponent>(context: ClassOf<T>): T | null {
    try {
      return this.getContext(context);
    } catch (e) {
      console.warn(e);
      return null;
    }
  }

  /**
   * Makes this node a root node.
   * Should only be called internally.
   * @param engine The engine this node is attached to
   */
  makeRootNode(engine: Engine) {
    this.#doBind(engine);
  }

  /**
   * Flags to the engine that this node has updated and a re-render is required
   */
  requestRender() {
    if (this.engine) {
      this.engine.flagHasUpdates();
    }
  }

  /**
   * Removes this node from its parent, loowing it to be added to a different node.
   */
  remove() {
    this.parent?.removeChildren(this);
  }

  /**
   * Destroys this node, removing it from the parent tree.
   */
  destroy() {
    if (this.destroyed) {
      return;
    }

    this.children.forEach((child) => {
      child.destroy();
    });

    this.#destroyed = true;

    this.remove();
    this.#container.destroy();
    this.ownGraphics.destroy();
    this.#childGraphics.destroy();
    this.#containerProxy?.destroy();
    this.#containerProxy = null;

    [...this.components].forEach((component) => component.destroy());
    [...this.contexts].forEach((context) => context.destroy());

    this.eventBus.emit(NodeEvent.Destroyed);
  }

  readonly inspectorData: InspectableClassData<any> = [];
}

export default Node;
