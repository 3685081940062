import NewTag from '@gi/garden-platform-sidebar/source/components/new-tag';
import React, { ReactNode } from 'react';

import './mobile-nav-link.scss';

interface iProps {
  title: ReactNode;
  subtitle?: ReactNode;
  onClick: () => void;
  icon: string | ReactNode;
  active?: boolean;
  isNew?: boolean;
}

const MobileNavigationLink = ({ icon, title, subtitle, onClick, active, isNew = false }: iProps): JSX.Element => {
  const classNames = ['mobile-navigation-link'];
  if (active) {
    classNames.push('active');
  }

  return (
    <li className={classNames.join(' ')}>
      <button type='button' onClick={onClick}>
        {isNew ? <NewTag /> : null}
        <span className='icon'>{typeof icon === 'string' ? <i className={icon} /> : icon}</span>
        <div className='text'>
          <span className='title'>{title}</span>
          {subtitle ? <span className='subtitle'>{subtitle}</span> : null}
        </div>
      </button>
    </li>
  );
};

export default MobileNavigationLink;
