import React from 'react';

import styles from './educational-account-badge.module.css';

const EducationalAccountBadge = (): JSX.Element => {
  return (
    <span className={styles.badge} title='Educational Account'>
      EDU
    </span>
  );
};

export default EducationalAccountBadge;
