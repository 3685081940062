import { Pest } from './pest';

export function pestFromObject(obj: any): Pest {
  return {
    id: obj.id,
    code: obj.code,
    name: obj.name,
    otherName: obj.otherName,
    autoCompleteNames: obj.autoCompleteNames,
    slug: obj.slug,
    scientificName: obj.scientificName,
    countryCode: obj.countryCode,
    type: obj.type,
    hostPlantFamilies: obj.hostPlantFamilies,
    synonymCodes: obj.synonymCodes,
    hostCropCodes: obj.hostCropCodes,
    activityStartRelativeToLastFrost: obj.activityStartRelativeToLastFrost,
    activityEndRelativeToFirstFrost: obj.activityEndRelativeToFirstFrost,
    highRiskStartRelativeToLastFrost: obj.highRiskStartRelativeToLastFrost,
    highRisKEndRelativeToLastFrost: obj.highRiskEndRelativeToLastFrost,
    highRiskStartRelativeToFirstFrost: obj.highRiskStartRelativeToFirstFrost,
    highRisKEndRelativeToFirstFrost: obj.highRiskEndRelativeToFirstFrost,
    tags: obj.tags,
    images: obj.images,
    text: {
      location: obj.location,
      hostPlantsWild: obj.hostPlantsWild,
      hostPlantsGarden: obj.hostPlantsGarden,
      hostCrops: obj.hostCrops,
      description: obj.description,
      damage: obj.damage,
      prevention: obj.prevention,
      managing: obj.managing,
      benefits: obj.benefits,
      habitat: obj.habitat,
      attracting: obj.attracting,
      tips: obj.tips,
    },
  };
}
