import React, { ReactNode } from 'react';

import './mobile-nav-sublink-group.scss';

interface iProps {
  children: ReactNode;
}

const MobileNavigationSubLinkGroup = ({ children }: iProps): JSX.Element => {
  return (
    <li>
      <ul className='mobile-navigation-sublinks'>{children}</ul>
    </li>
  );
};

export default MobileNavigationSubLinkGroup;
