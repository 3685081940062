import React, { useCallback, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { directoryPageRoutes, useActiveDirectory } from '@gi/app-guru-common';
import { GuruDirectoryPageId } from '@gi/app-guru-types';
import { useOnClickOutside, DelayedUnmount } from '@gi/react-utils';

import styles from './navigation-bar-dropdown.module.css';

const NavigationBarDropdown = (): JSX.Element => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const activeDirectory = useActiveDirectory();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useOnClickOutside<HTMLElement>([buttonRef, dropdownRef], () => setIsOpen(false));

  const createLink = useCallback((pageId: GuruDirectoryPageId) => {
    const route = directoryPageRoutes[pageId];

    return (
      <NavLink
        key={route.id}
        to={route.route}
        className={({ isActive }) => `${styles.dropdownOption} ${isActive ? styles.active : ''}`}
        onClick={() => {
          setIsOpen(false);
        }}
      >
        {route.mobileContent}
      </NavLink>
    );
  }, []);

  const buttonStyles: string[] = [styles.button];
  if (isOpen || (activeDirectory !== null && activeDirectory !== GuruDirectoryPageId.RootDirectory)) {
    buttonStyles.push(styles.active);
  }

  return (
    <div className={styles.outerContainer}>
      <div className={`${styles.container} ${isOpen ? styles.open : ''}`}>
        <button type='button' className={buttonStyles.join(' ')} onClick={() => setIsOpen(!isOpen)} ref={buttonRef}>
          {activeDirectory ? directoryPageRoutes[activeDirectory].desktopContent : 'Home'} <i className='icon-down-dir' />
        </button>
        <DelayedUnmount isMounted={isOpen} unmountDelay={200}>
          <div className={styles.dropdown} ref={dropdownRef}>
            {createLink(GuruDirectoryPageId.RootDirectory)}
            {createLink(GuruDirectoryPageId.ArticleDirectory)}
            {createLink(GuruDirectoryPageId.VideoDirectory)}
            {createLink(GuruDirectoryPageId.PlantDirectory)}
            {createLink(GuruDirectoryPageId.PestDirectory)}
            {createLink(GuruDirectoryPageId.BookmarkDirectory)}
            {createLink(GuruDirectoryPageId.SearchDirectory)}
          </div>
        </DelayedUnmount>
      </div>
    </div>
  );
};

export default NavigationBarDropdown;
