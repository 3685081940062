import { randomUUID } from '@gi/utils';
import EventBus from '../event-bus';
import Node from '../node';
import { InspectableClassData } from '../types';

export enum NodeComponentEvent {
  DidAttach = 'DidAttach',
  DidDetach = 'DidDetach',
  BeforeDetach = 'BeforeDetach',
  DidBind = 'DidBind',
  DidUnbind = 'DidUnbind',
  BeforeUnbind = 'BeforeUnbind',
  Destroyed = 'Destroyed',
}

export type NodeComponentEventActions = {
  [NodeComponentEvent.BeforeDetach]: () => void;
  [NodeComponentEvent.DidDetach]: () => void;
  [NodeComponentEvent.DidAttach]: () => void;
  [NodeComponentEvent.BeforeUnbind]: () => void;
  [NodeComponentEvent.DidUnbind]: () => void;
  [NodeComponentEvent.DidBind]: () => void;
  [NodeComponentEvent.Destroyed]: () => void;
};

/**
 * A class for something that should always be attached to a node.
 * This is the base class for NodeComponent and NodeComponentContext, as they share a lot of functionality.
 */
abstract class NodeComponent<TParent extends Node = Node> {
  // Random identifier for this component
  readonly uuid: string = randomUUID();
  // The type of this component, effectively it's class name.
  abstract readonly type: string;
  // An ID to identify this components job.
  id: string = '';
  // A unique name for this component. This should be unique per-instance, helpful for debugging.
  name: string = '';

  /**
   * Example use of the above properties:
   * uuid: XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
   * type: 'HandleComponent'
   * id: 'TL' (to identify this as the Top-Left Handle)
   * name: 'TR Handle, PlantNode AUB - 10' (descriptive name for debugging)
   */

  #owner: TParent | null = null;
  #bound: boolean = false;
  #destroyed: boolean = false;

  readonly eventBus: EventBus<NodeComponentEventActions> = new EventBus();

  // Returns true if this node has a parent.
  get attached() {
    return this.owner !== null;
  }

  // Returns true if this component is attached to a bound node.
  get bound() {
    return this.#bound; // Could probably return `this.parent.bound`
  }

  setBound(bound: boolean) {
    if (bound) {
      this.#doBind();
    } else {
      this.#doUnbind();
    }
  }

  // Gets the node this component belongs to.
  get owner(): TParent | null {
    return this.#owner;
  }

  /**
   * Sets the owner of this node (or which node this component is attached to)
   * @param owner The node that owns this component
   */
  setOwner(owner: TParent) {
    this.#doAttach(owner);
  }

  /**
   * Clears this components owner
   */
  removeComponent() {
    this.#doDetach();
  }

  /**
   * Has this component been destroyed, never to be used again?
   */
  get destroyed() {
    return this.#destroyed;
  }

  /**
   * Destroys this component, preventing it from emitting events.
   */
  destroy() {
    if (this.destroyed) {
      return;
    }

    this.removeComponent();

    this.#destroyed = true;
    this.eventBus.emit(NodeComponentEvent.Destroyed);

    // TODO: Remove from components list if owner isn't destroyed
  }

  // Called when this component is about to be detached from a node (is this ever going to happen?)
  #onBeforeDetach() {
    this.eventBus.emit(NodeComponentEvent.BeforeDetach);
  }

  // Called when this component is detached from a node (is this ever going to happen?)
  #onDetach() {
    this.eventBus.emit(NodeComponentEvent.DidDetach);
  }

  // Called when this component is attached to a node (again, maybe not necessary if the above can't happen)
  #onAttach() {
    this.eventBus.emit(NodeComponentEvent.DidAttach);
  }

  // Called just before the parent node unbinds from the engine.
  #onBeforeUnbind() {
    this.eventBus.emit(NodeComponentEvent.BeforeUnbind);
  }

  // Called when the parent node unbinds from the engine.
  #onUnbind() {
    this.owner?.engine?.unregisterComponent(this);
    this.eventBus.emit(NodeComponentEvent.DidUnbind);
  }

  // Called when the parent node binds to an engine.
  #onBind() {
    this.owner?.engine?.registerComponent(this);
    this.eventBus.emit(NodeComponentEvent.DidBind);
  }

  // Runs all events associated with gainaing a new parent
  #doAttach(owner: TParent) {
    if (this.attached) {
      return;
    }
    this.#owner = owner;
    this.#onAttach();

    if (owner.engine) {
      this.#doBind();
    }
  }

  // Runs all events associated with changing parent when we had a valid parent in the past.
  #doDetach() {
    if (this.owner === null) {
      return;
    }
    this.#doUnbind();
    this.#onBeforeDetach();
    this.#owner = null;
    this.#onDetach();
  }

  // Runs all events associated with changing engine when we had a valid engine in the past
  #doUnbind() {
    if (this.bound === false) {
      return;
    }
    this.#onBeforeUnbind();
    this.#bound = false;
    this.#onUnbind();
  }

  // Runs all events associated with gianing a new engine
  #doBind() {
    if (this.bound === true) {
      return;
    }
    this.#bound = true;
    this.#onBind();
  }

  // Overwrite to define what information should be displayed in the inspector
  abstract readonly inspectorData: InspectableClassData<this>;
}

export default NodeComponent;
