import { Requests, RequestStatus, RequestsUtils } from '@gi/request';
import RequestActionTypes from './request-action-types';

const DEFAULT_STATE = RequestsUtils.create();

const requestsReducer = (requests: Requests = DEFAULT_STATE, action) => {
  switch (action.type) {
    case RequestActionTypes.START_REQUEST:
      return RequestsUtils.addRequest(requests, { key: action.key, status: RequestStatus.IN_PROGRESS });
    case RequestActionTypes.REQUEST_COMPLETE:
      return RequestsUtils.updateRequest(requests, {
        key: action.key,
        status: RequestStatus.COMPLETE,
        error: null,
      });
    case RequestActionTypes.REQUEST_FAIL:
      return RequestsUtils.updateRequest(requests, {
        key: action.key,
        status: RequestStatus.FAIL,
        error: action.error,
      });
    case RequestActionTypes.CLEAR_REQUEST:
      return RequestsUtils.removeRequest(requests, action.key);
    default:
      return requests;
  }
};

export default requestsReducer;
