import { IS_IOS } from '@gi/constants';
import bowser from './bowser';

/**
 * ImageBitmap is used by the Pixi assets system to store texture data. This implementation appears to have terrible
 *  performance on iOS 15 and 16 when using canvas mode. Avoid using ImageBitmap on all of iOS so we can use canvas mode.
 */
export const avoidImageBitmap =
  IS_IOS ||
  bowser.satisfies({
    mobile: {
      safari: '<17.0',
    },
  });
