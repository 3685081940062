// This is taken from https://rosettacode.org/wiki/Matrix_multiplication#JavaScript

// zipWith :: (a -> b -> c) -> [a] -> [b] -> [c]
const zipWith = (f: (xs: number, ys: number) => number, xs: number[], ys: number[]) => {
  if (xs.length !== ys.length) {
    throw new Error('Array lengths must be the same');
  }

  return xs.map((x, i) => f(x, ys[i]));
};

// transpose :: [[a]] -> [[a]]
const transpose = (xs: number[][]): number[][] => xs[0].map((_, iCol) => xs.map((row) => row[iCol]));

// sum :: (Num a) => [a] -> a
const sum = (xs: number[]): number => xs.reduce((a, x) => a + x, 0);

// product :: Num a => a -> a -> a
const product = (a: number, b: number): number => a * b;

// dotProduct :: Num a => [[a]] -> [[a]] -> [[a]]
const dotProduct = (xs: number[], ys: number[]) => sum(zipWith(product, xs, ys));

// multiply :: Num a => [[a]] -> [[a]] -> [[a]]
export const multiply = (a: number[][], b: number[][]): number[][] => {
  const bCols = transpose(b);
  return a.map((aRow) => bCols.map((bCol) => dotProduct(aRow, bCol)));
};

export const multiplyAll = (matrices: number[][][]): number[][] => {
  return matrices.reduce((a, b) => {
    return multiply(a, b);
  });
};

export const createTranslationMatrix = (dx: number, dy: number): number[][] => {
  return [
    [1, 0, dx],
    [0, 1, dy],
    [0, 0, 1],
  ];
};

export const createRotationMatrix = (theta: number): number[][] => {
  return [
    [Math.cos(theta), -Math.sin(theta), 0],
    [Math.sin(theta), Math.cos(theta), 0],
    [0, 0, 1],
  ];
};

export const createScalingMatrix = (sx: number, sy: number): number[][] => {
  return [
    [sx, 0, 0],
    [0, sy, 0],
    [0, 0, 1],
  ];
};

export const createIdentityMatrix = (): number[][] => {
  return [
    [1, 0, 0],
    [0, 1, 0],
    [0, 0, 1],
  ];
};

export const createPositionMatrix = (x: number, y: number): number[][] => {
  return [[x], [y], [1]];
};

export const add = (m1: number[][], m2: number[][]): number[][] => {
  if (m1.length !== m2.length || m1[0].length !== m2[0].length) {
    throw new Error('Matricies must be same size for addition');
  }

  const res: number[][] = new Array(m1.length).fill(0).map(() => []);

  for (let i = 0; i < m1.length; i++) {
    for (let j = 0; j < m1[i].length; j++) {
      res[i][j] = m1[i][j] + m2[i][j];
    }
  }

  return res;
};

export const multiplyVector = (vector: Vector2, matrix: number[][]): Vector2 => {
  const result = multiply(matrix, createPositionMatrix(vector.x, vector.y));
  return {
    x: result[0][0],
    y: result[1][0],
  };
};

export const IDENTITY = createIdentityMatrix();
