import React, { useMemo } from 'react';
import ReactSelect, { MenuPlacement } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import { CropRotationModes } from '@gi/constants';

import * as CanvasActionCreators from '../../redux-components/canvas-action-creators';
import * as CanvasSelectors from '../../redux-components/canvas-selectors';
import { getTopbarSelectStyles } from '../select-styles';

import './crop-rotation-selector.scss';

const BASE_SELECT_STYLES = getTopbarSelectStyles(152);

const SELECT_STYLES = {
  ...BASE_SELECT_STYLES,
  singleValue: (provided) => ({
    ...provided,
    width: '100%',
    paddingRight: '8px',
  }),
};

interface iFormatCropRotationOptionLabel {
  label: string;
  className: string;
}

const FormatCropRotationOptionLabel = ({ label, className }: iFormatCropRotationOptionLabel): JSX.Element => {
  let indicator: JSX.Element | null = <div className='family-indicator' />;
  if (className === 'family-off' || className === 'family-auto') {
    indicator = null;
  }

  return (
    <div className={`crop-rotation-dropdown-option ${className}`}>
      <div className='family-name'>{label}</div>
      {indicator}
    </div>
  );
};

const options = [
  { label: 'Off', value: CropRotationModes.OFF, className: 'family-off' },
  { label: 'Automatic', value: CropRotationModes.AUTOMATIC, className: 'family-auto' },
  { label: 'Brassicas', value: CropRotationModes.BRASSICAS, className: 'family-brassicas' },
  { label: 'Legumes', value: CropRotationModes.LEGUMES, className: 'family-legumes' },
  { label: 'Solanaceae', value: CropRotationModes.SOLANACEAE, className: 'family-solanaceae' },
  { label: 'Allium', value: CropRotationModes.ALLIUM, className: 'family-allium' },
  {
    label: 'Umbellliferae',
    value: CropRotationModes.UMBELLIFERAE,
    className: 'family-umbelliferae',
  },
  { label: 'Cucurbits', value: CropRotationModes.CUCURBITS, className: 'family-cucurbits' },
  {
    label: 'Chenopodiaceae',
    value: CropRotationModes.CHENOPODIACEAE,
    className: 'family-chenopodiaceae',
  },
];

const getLabelForValue = (value: CropRotationModes) => {
  switch (value) {
    case CropRotationModes.OFF:
      return 'Off';
    case CropRotationModes.AUTOMATIC:
      return 'Automatic';
    case CropRotationModes.BRASSICAS:
      return 'Brassicas';
    case CropRotationModes.LEGUMES:
      return 'Legumes';
    case CropRotationModes.SOLANACEAE:
      return 'Solanaceae';
    case CropRotationModes.ALLIUM:
      return 'Allium';
    case CropRotationModes.UMBELLIFERAE:
      return 'Umbellliferae';
    case CropRotationModes.CUCURBITS:
      return 'Cucurbits';
    case CropRotationModes.CHENOPODIACEAE:
      return 'Chenopodiaceae';
    default:
      return '';
  }
};

const getClassForValue = (value: CropRotationModes) => {
  switch (value) {
    case CropRotationModes.OFF:
      return 'family-off';
    case CropRotationModes.AUTOMATIC:
      return 'family-auto';
    case CropRotationModes.BRASSICAS:
      return 'family-brassicas';
    case CropRotationModes.LEGUMES:
      return 'family-legumes';
    case CropRotationModes.SOLANACEAE:
      return 'family-solanaceae';
    case CropRotationModes.ALLIUM:
      return 'family-allium';
    case CropRotationModes.UMBELLIFERAE:
      return 'family-umbellliferae';
    case CropRotationModes.CUCURBITS:
      return 'family-cucurbits';
    case CropRotationModes.CHENOPODIACEAE:
      return 'family-chenopodiaceae';
    default:
      return '';
  }
};

interface iProps {
  hideLabel?: boolean;
  menuPlacement?: MenuPlacement;
  attachToBody?: boolean;
  id?: string;
}

const CropRotationSelector = ({ hideLabel, menuPlacement, attachToBody = false, id }: iProps): JSX.Element | null => {
  const plan = useSelector(CanvasSelectors.getActivePlan);

  const dispatch = useDispatch();

  const onReset = () => {
    if (plan) {
      dispatch(CanvasActionCreators.setCropRotationMode(plan, CropRotationModes.AUTOMATIC));
    }
  };

  const setCropRotationMode = (e) => {
    if (plan) {
      dispatch(CanvasActionCreators.setCropRotationMode(plan, e.value));
    }
  };

  const cropRotationMode = useMemo(() => {
    if (plan === null) {
      return {
        label: getLabelForValue(options[0].value),
        value: options[0].value,
        className: getClassForValue(options[0].value),
      };
    }

    if (plan.plannerSettings.cropRotationMode) {
      return {
        label: getLabelForValue(plan.plannerSettings.cropRotationMode),
        value: plan.plannerSettings.cropRotationMode,
        className: getClassForValue(plan.plannerSettings.cropRotationMode),
      };
    }

    return {
      label: getLabelForValue(options[0].value),
      value: options[0].value,
      className: getClassForValue(options[0].value),
    };
  }, [plan]);

  const renderReset = () => {
    if (!plan || !plan.plannerSettings.cropRotationMode || plan.plannerSettings.cropRotationMode === CropRotationModes.AUTOMATIC) {
      return null;
    }

    return (
      <span>
        <button type='button' className='button button-borderless crop-rotation-reset' onClick={onReset}>
          Reset
        </button>
      </span>
    );
  };

  if (plan === null) {
    // No plan available, don't render crop rotation options
    return null;
  }

  if (plan.history.length === 0) {
    // No plan history, don't render crop rotation options
    return null;
  }

  const selectComponent = (
    <ReactSelect
      styles={SELECT_STYLES}
      options={options}
      value={cropRotationMode}
      onChange={setCropRotationMode}
      formatOptionLabel={FormatCropRotationOptionLabel}
      menuPortalTarget={attachToBody ? document.body : undefined}
      menuPlacement={menuPlacement}
      inputId={id}
      isSearchable={false}
    />
  );

  if (hideLabel) {
    return selectComponent;
  }

  return (
    <div className='crop-rotation-input'>
      <span>Crop Rotation </span>
      <div>{selectComponent}</div>
      {renderReset()}
    </div>
  );
};

export default CropRotationSelector;
