import React, { ReactNode } from 'react';
import { GuruContentType, GuruPreviewArticle } from '@gi/app-guru-types';

import SmallPreview from '../small-preview';
import SmallPreviewTitle from '../small-preview-title';
import SmallPreviewContent from '../small-preview-content';
import SmallPreviewTag from '../small-preview-tag';
import SmallPreviewLink from '../small-preview-link';

import LazyImage from '../../lazy-image/lazy-image';

interface iProps {
  previewArticle: GuruPreviewArticle;
  className?: string;
  children?: ReactNode;
}

const SmallPreviewArticle = ({ previewArticle, className = '', children }: iProps): JSX.Element => {
  return (
    <SmallPreview className={className}>
      <SmallPreviewLink type={GuruContentType.Article} slug={previewArticle.slug}>
        <SmallPreviewContent>
          <LazyImage src={previewArticle.thumbnailUrl} alt={previewArticle.title} />
          <SmallPreviewTag text='Article' color='#17EB36' />
        </SmallPreviewContent>
        <SmallPreviewTitle>{children !== undefined ? children : previewArticle.title}</SmallPreviewTitle>
      </SmallPreviewLink>
    </SmallPreview>
  );
};

export default SmallPreviewArticle;
