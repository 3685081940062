import React, { useContext } from 'react';

import ModalType from './modal-types';
import ModalWithoutContext from './modal-without-context';
import { ModalContext } from './modal-context/modal-context';

interface iProps {
  children: React.ReactNode;
  type?: ModalType;
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Passes the 'attemptClose' function to the close request of the ModalWithoutContext
 *
 * This cannot be done in the normal Modal component as that component provides the context
 * so it has to be done in a separate inner component.
 */
const ModalCloseHandler = ({ type = ModalType.Default, className = '', children, style = {} }: iProps): JSX.Element => {
  const { attemptClose } = useContext(ModalContext);

  return (
    <ModalWithoutContext type={type} className={className} closeRequest={attemptClose} style={style}>
      {children}
    </ModalWithoutContext>
  );
};

export default ModalCloseHandler;
