import React, { useContext } from 'react';

import { PlannerCommandContext } from '@gi/app-planner-desktop';

import MobilePlanMenuButton from '../mobile-plan-menu-button';

interface iProps {
  planId: number;
  callAndClose: (callback: null | (() => void)) => () => void;
}

/**
 * Mobile topbar drawer controls for plans that are not currently active
 */
const MobileInactivePlanControls = ({ planId, callAndClose }: iProps): JSX.Element | null => {
  const { setActivePlan } = useContext(PlannerCommandContext);

  return (
    <MobilePlanMenuButton leftIcon='icon-angle-right' onClick={callAndClose(() => setActivePlan(planId))}>
      Switch to Plan
    </MobilePlanMenuButton>
  );
};

export default MobileInactivePlanControls;
