import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { Direction } from '@gi/constants';

import { SidebarContext, SidebarContextType } from './sidebar-context';

export interface SidebarProviderProps {
  initiallyActiveTab: string;
  initiallyMinimised?: boolean;
  activeTab?: string;
  setActiveTab?: (activeTab: string) => void;
  minimised?: boolean;
  setMinimised?: (minimised: boolean) => void;
  orientation?: Direction.LEFT | Direction.RIGHT;
  children?: ReactNode;
}

const SidebarProvider = ({
  initiallyActiveTab,
  initiallyMinimised,
  activeTab: activeTabProp,
  setActiveTab: setActiveTabProp,
  minimised: minimisedProp,
  setMinimised: setMinimisedProp,
  orientation = Direction.LEFT,
  children,
}: SidebarProviderProps): JSX.Element => {
  const [internalActiveTab, setInternalActiveTab] = useState<string>(initiallyActiveTab);
  const [internalMinimised, setInternalMinimised] = useState<boolean>(initiallyMinimised ?? false);
  const [headerContent, setHeaderContent] = useState<Record<string, ReactNode>>({});
  const [fallbackHeaderContent, setFallbackHeaderContent] = useState<ReactNode | null>(null);

  const setActiveTab = useCallback(
    (newActiveTab: string) => {
      if (setActiveTabProp) {
        setActiveTabProp(newActiveTab);
      }
      setInternalActiveTab(newActiveTab);
    },
    [setActiveTabProp]
  );

  const activeTab = useMemo(() => {
    return activeTabProp ?? internalActiveTab;
  }, [activeTabProp, internalActiveTab]);

  const setMinimised = useCallback(
    (newMinimised: boolean) => {
      if (setMinimisedProp) {
        setMinimisedProp(newMinimised);
      }
      setInternalMinimised(newMinimised);
    },
    [setActiveTabProp]
  );

  const minimised = useMemo(() => {
    return minimisedProp ?? internalMinimised;
  }, [minimisedProp, internalMinimised]);

  const toggleTab = useCallback(
    (tabId: string) => {
      if (activeTab === tabId) {
        setMinimised(!minimised);
      } else {
        setActiveTab(tabId);
        if (minimised) {
          setMinimised(!minimised);
        }
      }
    },
    [setMinimised, setActiveTab, activeTab, minimised]
  );

  const registerHeaderContent = useCallback((tabId: string | null, content: ReactNode) => {
    if (tabId === null) {
      setFallbackHeaderContent(content);
    } else {
      setHeaderContent((currentHeaderContent) => ({
        ...currentHeaderContent,
        [tabId]: content,
      }));
    }
  }, []);

  const unregisterHeaderContent = useCallback((tabId: string | null) => {
    if (tabId === null) {
      setFallbackHeaderContent(null);
    } else {
      setHeaderContent((currentHeaderContent) => {
        const newHeaderContent = { ...currentHeaderContent };
        delete newHeaderContent[tabId];
        return newHeaderContent;
      });
    }
  }, []);

  const activeHeader = useMemo(() => {
    return headerContent[activeTab] ?? fallbackHeaderContent;
  }, [headerContent, activeTab, fallbackHeaderContent]);

  const value = useMemo<SidebarContextType>(
    () => ({
      activeTab,
      setActiveTab,
      minimised,
      setMinimised,
      toggleTab,
      orientation,
      registerHeaderContent,
      unregisterHeaderContent,
      headerContent: activeHeader,
    }),
    [setActiveTab, activeTab, minimised, setMinimised, toggleTab, orientation, activeHeader]
  );
  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};

export default SidebarProvider;
