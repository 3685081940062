import React from 'react';

import { ModalPane, ModalPaneSectionGroup, ModalPaneContainer, ModalPaneSection, ModalPaneSectionContent, ModalPaneSectionHeader } from '@gi/modal';

interface iProps {
  errorReason?: string;
}

const PublishModalStageError = ({ errorReason = 'Unknown reason' }: iProps): JSX.Element => {
  return (
    <ModalPaneContainer className='publish-plan-error'>
      <ModalPane>
        <ModalPaneSectionGroup>
          <ModalPaneSection>
            <ModalPaneSectionHeader>Error Publishing Plan</ModalPaneSectionHeader>
            <ModalPaneSectionContent>
              <div className='published-plan-error-text'>
                <p>
                  Publish plan failed: <strong>{errorReason}</strong>
                </p>
                <p>
                  Restarting your browser or PC/Mac/Device and trying again may resolve the issue, if not please get in touch with support and we&apos;ll look
                  into the issue.
                </p>
              </div>
            </ModalPaneSectionContent>
          </ModalPaneSection>
        </ModalPaneSectionGroup>
      </ModalPane>
    </ModalPaneContainer>
  );
};

export default PublishModalStageError;
