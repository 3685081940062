import React from 'react';

import { Anchor } from '@gi/constants';

import styles from './anchor-input.module.css';

interface AnchorInputButtonProps {
  value: Anchor;
  selectedValue: Anchor;
  onClick: (anchor: Anchor) => void;
  disabled?: boolean;
}

const AnchorInputButton = ({ value, selectedValue, onClick, disabled = false }: AnchorInputButtonProps): JSX.Element => {
  const classNames: string[] = [styles.button];
  if (value === selectedValue) {
    classNames.push(styles.active);
  }

  return <button type='button' className={classNames.join(' ')} title='Set anchor position to' onClick={() => onClick(value)} disabled={disabled} />;
};

interface iProps {
  value: Anchor;
  setValue: (anchor: Anchor) => void;
  disabled?: boolean;
}

const AnchorInput = ({ value, setValue, disabled = false }: iProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <AnchorInputButton value={Anchor.TopLeft} selectedValue={value} onClick={setValue} disabled={disabled} />
      <AnchorInputButton value={Anchor.Top} selectedValue={value} onClick={setValue} disabled={disabled} />
      <AnchorInputButton value={Anchor.TopRight} selectedValue={value} onClick={setValue} disabled={disabled} />
      <AnchorInputButton value={Anchor.Left} selectedValue={value} onClick={setValue} disabled={disabled} />
      <AnchorInputButton value={Anchor.Center} selectedValue={value} onClick={setValue} disabled={disabled} />
      <AnchorInputButton value={Anchor.Right} selectedValue={value} onClick={setValue} disabled={disabled} />
      <AnchorInputButton value={Anchor.BottomLeft} selectedValue={value} onClick={setValue} disabled={disabled} />
      <AnchorInputButton value={Anchor.Bottom} selectedValue={value} onClick={setValue} disabled={disabled} />
      <AnchorInputButton value={Anchor.BottomRight} selectedValue={value} onClick={setValue} disabled={disabled} />
    </div>
  );
};

export default AnchorInput;
