import { createFilters, updateFilters } from '@gi/filters';
import GardenObject, { GardenObjectGroup } from '@gi/garden-object';
import Collection from '@gi/collection';

import gardenObjectSearchFilter from './garden-object-search-filter/garden-object-search-filter';
import gardenObjectSortFilter from './sort-filter';
import gardenObjectTypeFilter from './garden-object-type-filter/garden-object-type-filter';
import gardenObjectClientRestrictionFilter from './client-restriction-filter';

const GardenObjectFiltersType = {
  search: gardenObjectSearchFilter,
  sort: gardenObjectSortFilter,
  type: gardenObjectTypeFilter,
  clientRestrictions: gardenObjectClientRestrictionFilter,
} as const;
export type GardenObjectFiltersType = typeof GardenObjectFiltersType;

const INITIAL_GARDEN_OBJECT_FILTERS = createFilters<GardenObject, GardenObjectFiltersType>({
  search: gardenObjectSearchFilter,
  sort: gardenObjectSortFilter,
  type: gardenObjectTypeFilter,
  clientRestrictions: gardenObjectClientRestrictionFilter,
});

const createGardenObjectFilters = (gardenObjectGroups: Collection<GardenObjectGroup>, clientId: number) => {
  return updateFilters<GardenObject, GardenObjectFiltersType>(INITIAL_GARDEN_OBJECT_FILTERS, {
    sort: {
      groups: gardenObjectGroups,
    },
    clientRestrictions: {
      clientId,
    },
  });
};

export default createGardenObjectFilters;
