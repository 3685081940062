import { SimulatedItem } from '../simulated-item';
import { Edit } from './edit';

export abstract class EditSimulatedItem<T extends SimulatedItem<any>> extends Edit {
  readonly target: T;

  constructor(target: T) {
    super();

    this.target = target;
  }

  startBatch(): void {
    this.target.startBatchUpdate();
  }

  endBatch(): void {
    this.target.endBatchUpdate();
  }
}
