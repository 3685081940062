import React, { useContext } from 'react';
import { HelpModal, ModalPaneSection } from '@gi/modal';

import sfgImage from './square-foot-mode.png';

import { DrawingToolsContext } from '../drawing-tools-context';

import './sfg-help-modal.scss';

const SFGHelpModal = () => {
  const { setSfgHelpModalOpen } = useContext(DrawingToolsContext);

  return (
    <HelpModal
      className='sfg-help-modal'
      headerText='Square Foot Gardening'
      closeModal={() => {
        setSfgHelpModalOpen(false);
      }}
      style={{ maxWidth: 520 }}
    >
      <ModalPaneSection>
        <p>
          Square Foot Gardening (SFG) is a method of growing plants in deep raised beds, divided into squares 1ft x 1ft in size. Each square has a different
          vegetable sown in it with between 1 and 16 plants per square.
        </p>
        <p>
          Plants without the &apos;SFG&apos; tag in their label in the drawing tools plant list are not available as square foot plants and will be added to the
          plan as normal plant areas.
        </p>
        <p>
          Turning on the Square Foot Gardening mode will enable you to easily arrange 1ft squares of plants on your plan so that you can follow the SFG method.
          Each square displays the number of plants at the top left. (Because SFG does not use row planting you will not be able to drag out rows of SFG
          plants.)
        </p>
        <p>
          <strong>Tip:</strong> Hold down the Ctrl key on your keyboard to add more than one square of plants. Alternatively, add one plant, set the variety and
          then use Copy and Paste.
        </p>
        <div className='sfg-image'>
          <img src={sfgImage} alt='square-foot-gardening-example' />
        </div>
        <p>
          Further details on the SFG method are available from{' '}
          <a rel='noopener noreferrer' target='_blank' href='https://squarefootgardening.org'>
            https://squarefootgardening.org
          </a>
        </p>
      </ModalPaneSection>
    </HelpModal>
  );
};

export default SFGHelpModal;
