import React from 'react';
import { useSelector } from 'react-redux';

import { CanvasSelectors } from '@gi/react-garden-canvas';
import { PlannerControlsTab } from '@gi/constants';
import PlanBackgroundImageEditor from '@gi/plan-background-image';
import { SidebarContent, SidebarHeaderContent } from '@gi/planner-sidebar';

import styles from './styles.module.css';

const BackgroundImageTabContent = (): JSX.Element => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  return (
    <SidebarContent tabId={PlannerControlsTab.BACKGROUND_IMAGE} className={styles.background}>
      <SidebarHeaderContent tabId={PlannerControlsTab.BACKGROUND_IMAGE}>
        <h4>Plan Background Image</h4>
      </SidebarHeaderContent>
      <PlanBackgroundImageEditor plan={activePlan} />
    </SidebarContent>
  );
};

export default BackgroundImageTabContent;
