import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createFormValues, FormCheckboxField, FormLayout, FormSection, FORM_LAYOUT_PRESETS } from '@gi/form-responsive';
import LoadingButton from '@gi/loading-button';
import { SessionSelectors } from '@gi/react-session';
import { UserGeneralPlanSettings, UserSettings } from '@gi/user';
import { LoadingState } from '@gi/constants';

import { AppAccountSelectors, AppAccountActionCreators } from '@gi/app-account-slice';

const UserPlanSettingsForm = (): JSX.Element | null => {
  const user = useSelector(SessionSelectors.getUser);

  if (!user) {
    console.warn('Attempting to render UserPlanSettingsForm without a User');
    return null;
  }

  const isSavingUser = useSelector(AppAccountSelectors.getIsSavingUser);
  const saveRequest = useSelector(AppAccountSelectors.getSavingPlanSettings);
  const isSubmitting = saveRequest.status === LoadingState.LOADING;
  const settings = user ? (user.settings as UserSettings) : null;
  const dispatch = useDispatch();

  const [manager, setManager] = useState(
    createFormValues<UserGeneralPlanSettings>({
      plansPublic: { value: settings?.plansPublic ?? false },
    })
  );

  const setField = <K extends keyof UserGeneralPlanSettings>(setting: K, value: UserGeneralPlanSettings[K]) => {
    setManager(manager.setValue(setting, { value }));
  };

  const submit = () => {
    console.debug('Submtting Plan Settings: ', manager.values);
    const { values } = manager;

    dispatch(
      AppAccountActionCreators.saveUserPlanSettings(user, {
        plansPublic: values.plansPublic,
      })
    );
  };

  useEffect(() => {
    if (saveRequest.status === LoadingState.SUCCESS) {
      setManager(manager.reset(manager.values));
    }
  }, [saveRequest]);

  return (
    <form>
      <FormSection className='form-section-background'>
        <h2>Plan Settings</h2>
        <FormCheckboxField label='Make my plans visible to everyone' layout='long' htmlFor='plan-visibility'>
          <input
            type='checkbox'
            id='plan-visibility'
            checked={manager.values.plansPublic}
            onChange={(e) => setField('plansPublic', e.target.checked)}
            disabled={isSubmitting}
          />
        </FormCheckboxField>
        <FormLayout layoutPreset={FORM_LAYOUT_PRESETS.ButtonRight}>
          <LoadingButton
            type='button'
            className='button button-primary'
            loading={isSubmitting}
            disabled={isSavingUser || !manager.hasDifferences}
            onClick={submit}
          >
            Save Plan Settings
          </LoadingButton>
        </FormLayout>
      </FormSection>
    </form>
  );
};

export default UserPlanSettingsForm;
