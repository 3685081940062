import React, { useContext } from 'react';

import { GlobalPlantListContext } from '@gi/plant-list';

import ControlGroup from '../common/control-group';
import ControlRow from '../common/control-row';
import ControlButtonGroup from '../common/control-button-group';
import MenuDropdown from '../common/dropdown/menu-dropdown';
import MenuDropdownButton from '../common/dropdown/menu-dropdown-button';
import MenuDropdownGroup from '../common/dropdown/menu-dropdown-group';
import PlantListDisplayDropdownContent from './dropdowns/plant-list-display-dropdown-content';
import PlantListExportDropdownContent from './dropdowns/plant-list-export-dropdown-content';
import MenuButton from '../common/menu-button';

const PlantListDropdownControls = (): JSX.Element => {
  const { resetFilters } = useContext(GlobalPlantListContext);

  return (
    <ControlGroup>
      <ControlRow>
        <ControlButtonGroup>
          <MenuDropdownGroup>
            <MenuDropdown>
              <MenuDropdownButton>Display</MenuDropdownButton>
              <PlantListDisplayDropdownContent />
            </MenuDropdown>
            <MenuDropdown>
              <MenuDropdownButton>Export</MenuDropdownButton>
              <PlantListExportDropdownContent />
            </MenuDropdown>
          </MenuDropdownGroup>
        </ControlButtonGroup>
        <MenuButton onClick={resetFilters}>Reset All Options</MenuButton>
      </ControlRow>
    </ControlGroup>
  );
};

export default PlantListDropdownControls;
