import { DEFAULT_VARIETY } from '@gi/constants';
import { RichTextDefinition } from '@gi/core-renderer';
import { PlantSpacings } from '@gi/plant';
import { DistanceUnits } from '@gi/units';

/**
 * Returns a formatted string representing the name of the plant, including variety if relevant.
 * @param plantName The name of the plant
 * @param varietyName The variety of the plant
 * @returns A formatted string representing the name of the plant
 */
const getPlantName = (plantName: string, varietyName: string = DEFAULT_VARIETY): string => {
  if (varietyName === '' || varietyName === DEFAULT_VARIETY) {
    return plantName;
  }

  return `${plantName} - ${varietyName}`;
};

/**
 * Returns a formatted string with the plant name and plant count for the row
 * @param plantName The name of the plant
 * @param varietyName The variety of the plant
 * @param xPlantCount Horizontal plant count
 * @param width Width of the block
 * @param distanceUnits The plans distance units for measurements
 * @param spacings The spacings between plants
 * @returns A formatted string
 */
const getRowPlantCountText = (
  plantName: string,
  varietyName: string,
  xPlantCount: number,
  width: number,
  distanceUnits: DistanceUnits,
  spacings: PlantSpacings
): RichTextDefinition => {
  return [
    { text: `Row of ${getPlantName(plantName, varietyName)}`, bold: true },
    { text: `\n${xPlantCount} plants - ${distanceUnits.getUnitString(width + spacings.inRowSpacing)}` },
  ];
};

/**
 * Returns a formatted string with the plant name fr a single plant
 * @param plantName The name of the plant
 * @param varietyName The variety of the plant
 * @returns A formatted string
 */
const getSinglePlantCountText = (plantName: string, varietyName: string): RichTextDefinition => {
  return [{ text: `Single ${getPlantName(plantName, varietyName)} plant`, bold: true }];
};

/**
 * Returns a formatted string with the plant name and plant count for the block
 * @param plantName The name of the plant
 * @param varietyName The variety of the plant
 * @param xPlantCount Horizontal plant count
 * @param yPlantCount Vertical plant count
 * @param width Width of the block
 * @param height Height of the block
 * @param distanceUnits The plans distance units for measurements
 * @param spacings The spacings between plants
 * @returns A formatted string
 */
const getBlockPlantCountText = (
  plantName: string,
  varietyName: string,
  xPlantCount: number,
  yPlantCount: number,
  width: number,
  height: number,
  distanceUnits: DistanceUnits,
  spacings: PlantSpacings
): RichTextDefinition => {
  return [
    { text: `Block of ${getPlantName(plantName, varietyName)}`, bold: true },
    { text: `\n${xPlantCount} × ${yPlantCount} plants (total: ${xPlantCount * yPlantCount})` },
    { text: `\n${distanceUnits.getUnitString(width + spacings.spacing)} × ${distanceUnits.getUnitString(height + spacings.spacing)}` },
  ];
};

/**
 * Returns a formatted string with the plant name and plant count, formatted depending on single/row/block.
 * @param plantName The name of the plant
 * @param varietyName The variety of the plant
 * @param xPlantCount Horizontal plant count
 * @param yPlantCount Vertical plant count
 * @param width Width of the block
 * @param height Height of the block
 * @param distanceUnits The plans distance units for measurements
 * @param spacings The spacings between plants
 * @returns A formatted string
 */
export const getPlantCountText = (
  plantName: string,
  varietyName: string,
  xPlantCount: number,
  yPlantCount: number,
  width: number,
  height: number,
  distanceUnits: DistanceUnits,
  spacings: PlantSpacings
): RichTextDefinition => {
  if (width === 0 && height === 0) {
    return getSinglePlantCountText(plantName, varietyName);
  }
  if (height === 0) {
    return getRowPlantCountText(plantName, varietyName, xPlantCount, width, distanceUnits, spacings);
  }
  return getBlockPlantCountText(plantName, varietyName, xPlantCount, yPlantCount, width, height, distanceUnits, spacings);
};

/**
 * Returns a formatted string with the plant name and plant count for use with SFG plant nodes.
 * @param plantName The name of the plant
 * @param varietyName The variety of the plant
 * @param plantCount The SFG plant count
 * @returns A formatted string
 */
export const getSquareFootPlantText = (plantName: string, varietyName: string, plantCount: number): RichTextDefinition => {
  return [{ text: `Square Foot of ${getPlantName(plantName, varietyName)}`, bold: true }, { text: `\n${plantCount} plant${plantCount > 1 ? 's' : ''}` }];
};
