/**
 * Represents a direction, likely to move a UI element.
 * If more directions are needed (e.g. diagonals), a new enum should be created.
 *  This is specifically constrained to 1 axis at a time.
 */
enum Direction {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  UP = 'UP',
  DOWN = 'DOWN',
}

export default Direction;
