/* eslint-disable no-continue */
import { State } from './state';
import stateRegister from './state-register';
import { StateLogger } from './utils/console';

export class StateManager {
  #statesWithUpdates: Set<number> = new Set();

  /**
   * The number of times update() can call itself before presuming it's hit an infinite loop.
   * Keep this low, as each full update cycle runs watchers, which are usually expensive.
   * Looping more than a few times probably implies bad logic, with watchers updating states.
   */
  MAX_FULL_UPDATES: number = 10;
  /**
   * The number of times to allow updaters to update other states.
   * State updates get batched and committed, then all related updaters are run to update other states.
   * The newly updated states are batched and committed, and the cycle continues.
   * This is a failsafe incase of infinite loop, e.g. stateA.property -[updater1]-> stateB.property -[updater2]-> stateA.property -[loop]->...
   */
  MAX_UPDATER_DEPTH: number = 20;

  #currentOperationId: number = 1; // Start at 1 just to avoid anything being initialized to 0 and running
  /** Self-incrementing operation number. Allows states to check if they've updated this frame. */
  get currentOperationId() {
    return this.#currentOperationId;
  }

  #isProcessing: boolean = false;
  /** Is the state manager currently processing updates. Prevents emitting updates when true. */
  get isProcessing() {
    return this.#isProcessing;
  }

  #hasUpdatesCallback?: () => void;

  constructor(hasUpdatesCallback?: () => void) {
    this.#hasUpdatesCallback = hasUpdatesCallback;
  }

  /**
   * Registers the given state with the state manager.
   * No longer strictly necessary, could just call notifyStateHasUpdates for new states.
   * @param state The state to register
   */
  registerState<T extends State<any>>(state: T) {
    state.manager = this;
    if (state.hasUpdatesWaiting) {
      this.notifyStateHasUpdates(state);
    }
  }

  /**
   * Internal: Tells the state manager that the given state has an update waiting.
   * @param state The state that has an update
   */
  notifyStateHasUpdates<T extends State<any>>(state: T) {
    StateLogger.log('👋', 'State has updates');
    this.#statesWithUpdates.add(state.id);
    if (!this.#isProcessing) {
      this.#hasUpdatesCallback?.();
    }
  }

  /**
   * Commits all pending state updates, and recursively runs all relevant updates and watchers until
   *  no updates are left.
   */
  update() {
    this.#update();
  }

  /**
   * Flushes state updates, and recursively runs observers until all updates are finished.
   * @param iteration The amount of times this function has already been called this frame. Increment each time.
   */
  #update(iteration: number = 0) {
    if (iteration >= this.MAX_FULL_UPDATES) {
      StateLogger.log(
        '⛔',
        `Update recursed ${this.MAX_FULL_UPDATES} times, aborting due to potential infinite loop.
Check for state watchers causing unnecessary updates.`
      );
    }

    StateLogger.group('▶️', 'Starting state update cycle');

    if (this.#statesWithUpdates.size === 0) {
      StateLogger.groupEnd();
      return;
    }

    this.#isProcessing = true;

    const updatedStates: Set<number> = new Set();
    const lateStateObserversToRun: Set<number> = new Set();

    let update = 0;
    const updateLoop = () => {
      update++;
      if (update > this.MAX_UPDATER_DEPTH) {
        StateLogger.log('⛔', `Updater loop hit ${this.MAX_UPDATER_DEPTH} iterations deep, aborting due to potential infinite loop`);
        return;
      }
      StateLogger.group('🔁', `Running Update Loop #${update}`);
      StateLogger.log(`Updating ${this.#statesWithUpdates.size} states`);
      const stateObserversToRun: Set<number> = new Set();

      // Commit all the states that need updating
      this.#statesWithUpdates.forEach((stateId) => {
        const state = stateRegister.getState(stateId);
        /**
         * Skip states that don't currently belong to this manager
         * These states have likely disconnected since registering their update, and will automatically
         *  try again when they get reconnected, so wait until then. Otherwise, observers won't be run.
         */
        if (!state || state.manager !== this) {
          return;
        }

        updatedStates.add(state.id);
        state.commit();

        state.dependentObservers.forEach((observerId) => {
          stateObserversToRun.add(observerId);
        });
        state.dependentLateObserver.forEach((observerId) => {
          lateStateObserversToRun.add(observerId);
        });
      });

      this.#statesWithUpdates.clear();

      StateLogger.log(`Potentially running ${stateObserversToRun.size} observers`);

      // Run all the observers that need running
      stateObserversToRun.forEach((observerId) => {
        const observer = stateRegister.getStateObserver(observerId);
        if (observer) {
          observer.onStateUpdate();
        }
      });

      // TODO: Maybe loop over states with updates and finish commit for this frame?

      StateLogger.groupEnd();

      // Start the loop again if there's more states with updates
      if (this.#statesWithUpdates.size > 0) {
        this.#currentOperationId++;
        updateLoop();
      }
    };

    updateLoop();

    this.#currentOperationId++;

    StateLogger.log('⏹️', `Finally committing ${updatedStates.size} states`);
    // All states have now been committed, updaters have run and there's nothing left to do.
    // Now commit the final batch and run all the late observers
    updatedStates.forEach((stateId) => {
      const state = stateRegister.getState(stateId);
      if (state) {
        state.commitBatch();
      }
    });

    StateLogger.log(`Potentially running ${lateStateObserversToRun.size} late state observers`);

    lateStateObserversToRun.forEach((lateObserverId) => {
      const observer = stateRegister.getStateObserver(lateObserverId);
      if (observer) {
        observer.onStateUpdate();
      }
    });

    this.#currentOperationId++;
    this.#isProcessing = false;

    StateLogger.groupEnd();

    if (this.#statesWithUpdates.size > 0) {
      StateLogger.log('⚠️', 'Re-running update loop, as states updated during watchers...');
      this.#update(iteration + 1);
    }
  }
}
