import React from 'react';

import AppGuruRootPane from './app-guru-root-pane';
import GardenGuruProvider from './context/garden-guru-provider';
import ScrollTop from './scroll-top';

import styles from './app-garden-guru.module.css';
import './app-garden-guru.css';

const AppGardenGuru = () => {
  return (
    <div className={`app-garden-guru ${styles.appGardenGuru}`}>
      <GardenGuruProvider>
        <ScrollTop />
        <AppGuruRootPane />
      </GardenGuruProvider>
    </div>
  );
};

export default AppGardenGuru;
