import { Filter } from '@gi/filters';
import Plant from '@gi/plant';
import { SearchResults, SearchablePlantVariety } from '@gi/search-service';

type PlantVarietyFilterState = {
  searchTerm: string;
  plantSearchResults: SearchResults<Plant>;
  varietySearchResults: SearchResults<SearchablePlantVariety>;
};

/**
 * Creates a filter that matches against a list of search results.
 * Same logic as drawing tools search, but without sorting.
 */
export const makePlantVarietySearchFilter = <T>(getPlantCode: (object: T) => string, getVarietyCode: (object: T) => string) => {
  const searchFilter: Filter<T, PlantVarietyFilterState> = {
    name: 'Search',
    inputs: {
      searchTerm: '',
      plantSearchResults: new SearchResults([]),
      varietySearchResults: new SearchResults([]),
    },
    createListFilter:
      ({ searchTerm, plantSearchResults, varietySearchResults }) =>
      (objectList) => {
        // Return early if no results or empty query
        if (searchTerm.trim() === '') {
          return objectList;
        }
        if (plantSearchResults.count === 0 && varietySearchResults.count === 0) {
          return [];
        }
        // Filter against the subset we've been passed to allow us to combine with other filters
        return objectList.filter((object) => {
          return (
            plantSearchResults.getResult(getPlantCode(object)) !== undefined ||
            varietySearchResults.getResult(`${getPlantCode(object)}-${getVarietyCode(object)}`) !== undefined
          );
        });
      },
  };
  return searchFilter;
};
