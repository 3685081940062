import React, { useContext } from 'react';
import { TutorialContext } from '@gi/tutorial';

import ControlGroup from '../../common/control-group';
import ControlRow from '../../common/control-row';
import MenuButton from '../../common/menu-button';
import ControlButtonGroup from '../../common/control-button-group';

const TemporaryTutorialEditorControls = (): JSX.Element => {
  const { showEditor, setShowEditor } = useContext(TutorialContext);
  return (
    <ControlGroup>
      <ControlRow>
        <ControlButtonGroup>
          <MenuButton id='new-plan' onClick={() => setShowEditor(!showEditor)}>
            Tutorial Editor
          </MenuButton>
        </ControlButtonGroup>
      </ControlRow>
    </ControlGroup>
  );
};

export default TemporaryTutorialEditorControls;
