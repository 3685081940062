import { Validator } from '@gi/validators';

export type FormValues<T> = Readonly<{
  fields: ReadonlyFormValuesFields<T>;
  setValue: <K extends keyof T>(key: K, properties: Partial<FormValuesFieldWritables<T[K]>>) => FormValues<T>;
  setValues: (...tuples: FormValuesFieldUpdateTuple<T>[]) => FormValues<T>;
  reset: (values?: Partial<T>) => FormValues<T>;
  hasDifferences: boolean;
  hasBeenEdited: boolean;
  isValid: boolean;
  values: Readonly<T>;
}>;

// Contains all the fields for the form
type FormValuesFields<T> = {
  [K in keyof T]: FormValuesField<T[K]>;
};

// Readonly version of FormValuesFields<T>
type ReadonlyFormValuesFields<T> = Readonly<{
  [K in keyof T]: Readonly<FormValuesField<T[K]>>;
}>;

// Properties for each field that MUST be passed in during the form manager construction.
type FormValuesFieldRequired<U> = {
  value: U;
};

// Properties for each field that could also be passed in during form manager construction.
type FormValuesFieldOptionals<U> = {
  initialValue: U;
  valid: boolean;
  validators: Validator<U>[];
  disabled: boolean;
  hasBeenEdited: boolean;
};

// Properties for each field that can be set using setValue
type FormValuesFieldWritables<U> = Partial<FormValuesFieldRequired<U> & FormValuesFieldOptionals<U>>;

// Computed properties for each field. Read only.
type FormValuesFieldComputed = {
  errors: string[];
  isDifferent: boolean;
};

// Each field will have all required, optional and computed properties set
export type FormValuesField<U> = FormValuesFieldRequired<U> & FormValuesFieldOptionals<U> & FormValuesFieldComputed;

// Initial properties for a field.
export type InitialFormValuesField<U> = FormValuesFieldRequired<U> & Partial<FormValuesFieldOptionals<U> & FormValuesFieldComputed>;

// Initial properteis for each field.
export type InitialFormValuesFields<T> = {
  [K in keyof T]: InitialFormValuesField<T[K]>;
};

// Tuple type for updating multiple fields
export type FormValuesFieldUpdateTuple<T> = [keyof T, Partial<FormValuesFieldWritables<T[keyof T]>>];

/**
 * Generates all the default optional and computed properties for a field.
 * Made as a function so generic types can be used.
 * @param initialValue The initial value to use
 * @returns A set of default properties for a field
 */
function getDefaultFieldValues<U>(initialValue: U) {
  const properties: FormValuesFieldOptionals<U> & FormValuesFieldComputed = {
    valid: true,
    validators: [],
    disabled: false,
    errors: [],
    hasBeenEdited: false,
    isDifferent: false,
    initialValue,
  };
  return properties;
}

/**
 * Creates the fields for a form manager
 * @param initialValues The initial values for each field
 * @returns An object containing metadata about each field.
 */
function createFormValuesFields<T>(initialValues: InitialFormValuesFields<T>): FormValuesFields<T> {
  const keys = Object.keys(initialValues) as Array<keyof T>;
  const output = keys.reduce((fields, key) => {
    const properties = initialValues[key];

    fields[key] = {
      ...getDefaultFieldValues<T[typeof key]>(initialValues[key].value),
      ...properties,
    };

    // Validate only if "valid" hasn't been manually set.
    if (!Object.hasOwnProperty.call(properties, 'valid')) {
      fields[key].errors = fields[key].validators.flatMap((validator) => validator(fields[key].value));
      fields[key].valid = fields[key].errors.length === 0;
    }

    // Check if the field value is differnet from the initial, incase initialised with `value` and `initialValue` being different
    fields[key].isDifferent = fields[key].value !== fields[key].initialValue;

    return fields;
  }, {} as FormValuesFields<T>);

  return output as FormValuesFields<T>;
}

/* eslint-disable @typescript-eslint/no-use-before-define */
/**
 * Creates a form manager to manage form logic.
 * @param initialValues The initial values to use
 * @returns A form manager data structure
 */
export function createFormValues<T>(initialValues: InitialFormValuesFields<T>): FormValues<T> {
  let fields: FormValuesFields<T> = createFormValuesFields(initialValues);

  // Returns the field names of the form
  const getKeys = (): Array<keyof T> => Object.keys(fields) as Array<keyof T>;

  // Returns the values in the form, in the same format as initially given.
  const getValues = (): T => {
    const keys = getKeys();
    return keys.reduce((values, key) => {
      values[key] = fields[key].value;
      return values;
    }, {} as T);
  };

  // Returns true if any field is different from its initial value and isn't disabled
  const hasDifferences = (): boolean => {
    return getKeys().some((key) => fields[key].isDifferent && !fields[key].disabled);
  };

  // Returns true if any field has been edited and isn't disabled
  const hasBeenEdited = (): boolean => {
    return getKeys().some((key) => fields[key].hasBeenEdited && !fields[key].disabled);
  };

  // Returns true if every enabled field is valid
  const isValid = (): boolean => {
    return !getKeys().some((key) => !fields[key].valid && !fields[key].disabled);
  };

  /**
   * Updates just the fields
   * @param key The field name
   * @param properties The properties to update
   * @returns Updated fields
   */
  const updateField = <K extends keyof T>(key: K, properties: Partial<FormValuesFieldWritables<T[K]>>): FormValuesFields<T> => {
    // It seems object destructuring causes this object to always think it's valid.
    // Make sure this object is correct, as TypeScript won't help here.
    fields = {
      ...fields,
      [key]: {
        ...fields[key],
        ...properties,
      },
    };

    // Check if the field has been edited
    if (Object.hasOwnProperty.call(properties, 'value') && !Object.hasOwnProperty.call(properties, 'hasBeenEdited')) {
      fields[key].hasBeenEdited = true;
    }

    // Run validators (as long as the valid state isn't being manually set)
    if (!Object.hasOwnProperty.call(properties, 'valid')) {
      fields[key].errors = fields[key].validators.flatMap((validator) => validator(fields[key].value));
      fields[key].valid = fields[key].errors.length === 0;
    }

    // Check if the field value is differnet from the initial.
    fields[key].isDifferent = fields[key].value !== fields[key].initialValue;

    return fields;
  };

  /**
   * Updates the given properties for the specified field.
   * @param key The field name
   * @param properties The properties to update
   * @returns An updated form manager
   */
  const setValue: FormValues<T>['setValue'] = (key, properties) => {
    fields = updateField(key, properties);

    return {
      fields,
      setValue,
      setValues,
      reset,
      hasDifferences: hasDifferences(),
      hasBeenEdited: hasBeenEdited(),
      isValid: isValid(),
      values: getValues(),
    };
  };

  /**
   * Updates the given fields with the given properties.
   * @param tuples The set of tuples of fields to update
   * @returns An updated form manager
   */
  const setValues: FormValues<T>['setValues'] = (...tuples) => {
    tuples.forEach(([key, properties]) => {
      fields = updateField(key, properties);
    });

    return {
      fields,
      setValue,
      setValues,
      reset,
      hasDifferences: hasDifferences(),
      hasBeenEdited: hasBeenEdited(),
      isValid: isValid(),
      values: getValues(),
    };
  };

  /**
   * Resets the form manager to it's initial state.
   * @param values Optional: The new initial values of any fields.
   * @returns A reset form manager
   */
  const reset: FormValues<T>['reset'] = (values: Partial<T> = {}) => {
    initialValues = { ...initialValues };
    const keys = Object.keys(values) as Array<keyof T>;
    keys.forEach((key) => {
      initialValues[key].value = values[key]!;
      initialValues[key].initialValue = values[key]!;
    });

    fields = createFormValuesFields(initialValues);

    return {
      fields,
      setValue,
      setValues,
      reset,
      hasDifferences: hasDifferences(),
      hasBeenEdited: hasBeenEdited(),
      isValid: isValid(),
      values: getValues(),
    };
  };

  return {
    fields,
    setValue,
    setValues,
    reset,
    // We still need to calculate these on start, as they may have been set in the initial value.
    hasDifferences: hasDifferences(),
    hasBeenEdited: hasBeenEdited(),
    isValid: isValid(),
    values: getValues(),
  };
}
