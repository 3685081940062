import React from 'react';
import { useSelector } from 'react-redux';
import { GuruSelectors } from '@gi/app-guru-slice';
import { LoadingState } from '@gi/constants';
import { GuruDisplayType } from '@gi/app-guru-types';
import { GuruSectionPreview } from '@gi/app-guru-types/types';

import SmallPreviewRow from '../small-preview/small-preview-row/small-preview-row';
import ContentGrid from '../content-grid/content-grid';
import EmptyPreviewRow from '../empty-preview/empty-preview-row';
import SectionError from '../section-error/section-error';

import DirectoryPageSectionRow from './directory-page-section-row';
import DirectoryPageSectionGrid from './directory-page-section-grid';

interface iProps {
  sectionId: string;
  preview?: GuruSectionPreview;
  showLoadingTitle?: boolean;
  showEmptySectionTitle?: boolean;
  emptySectionComponent?: React.ReactNode;
  usePaginatedGrids?: boolean;
  usePaginatedRows?: boolean;
}

const DirectoryPageSection = ({
  sectionId,
  preview,
  showEmptySectionTitle,
  emptySectionComponent,
  showLoadingTitle = true,
  usePaginatedGrids = true,
  usePaginatedRows = true,
}: iProps): JSX.Element | null => {
  const sections = useSelector(GuruSelectors.getSections);

  if (!sections[sectionId] || !sections[sectionId]) {
    return null;
  }

  const sectionOperation = sections[sectionId];

  if (sectionOperation.status === LoadingState.ERROR) {
    let title: string = '';
    if (preview) {
      title = preview.title;
    }
    return <SectionError title={title} sectionId={sectionId} />;
  }

  if (sectionOperation.status !== LoadingState.SUCCESS) {
    if (preview) {
      switch (preview.displayType) {
        case GuruDisplayType.Row:
          return <SmallPreviewRow title={preview.title} loading />; // TODO: Also return paginated version in loading state?
        case GuruDisplayType.Grid:
          return <ContentGrid title={preview.title} loading showLoadingTitle={showLoadingTitle} />; // TODO: Also return paginated version in loading state?
        default:
        // Do nothing, fall back to default below
      }
    }
    return null;
  }

  const section = sectionOperation.value;

  if (section.items.length === 0 && emptySectionComponent) {
    return <EmptyPreviewRow title={showEmptySectionTitle ? section.title : null}>{emptySectionComponent}</EmptyPreviewRow>;
  }

  switch (section.displayType) {
    case GuruDisplayType.Row:
      return <DirectoryPageSectionRow sectionId={sectionId} section={section} usePaginatedRows={usePaginatedRows} />;
    case GuruDisplayType.Grid:
      return <DirectoryPageSectionGrid sectionId={sectionId} section={section} usePaginatedGrids={usePaginatedGrids} />;
    default:
      console.error(`Unknown section type ${section.displayType}`);
      return null;
  }
};

export default DirectoryPageSection;
