import React, { useContext } from 'react';

import { PlantSpacings } from '@gi/plant';
import { DistanceUnits } from '@gi/units';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import { TypeCounts } from '../../types/plant-list-types';

interface iProps {
  spacings: PlantSpacings;
  countsByType: TypeCounts;
  distanceUnits: DistanceUnits;
}

const SpacingsCell = ({ spacings, countsByType, distanceUnits }: iProps): JSX.Element | null => {
  const { columns } = useContext(GlobalPlantListContext);

  if (!columns.spacings) {
    return null;
  }

  const singleString = (
    <>
      Single: <span className='highlight-string'>{distanceUnits.getUnitString(spacings.spacing)}</span>
    </>
  );
  const inRowString = (
    <>
      In-Row: <span className='highlight-string'>{distanceUnits.getUnitString(spacings.inRowSpacing)}</span>, row gap{' '}
      <span className='highlight-string'>{distanceUnits.getUnitString(spacings.rowSpacing)}</span>
    </>
  );
  const sfgString =
    spacings.sfgCount === null ? null : (
      <>
        SFG: <span className='highlight-string'>{spacings.sfgCount}</span> plants/sqft
      </>
    );

  return (
    <td className='spacings-td cell-with-label'>
      <div className='cell-label'>Spacings</div>
      <div className='cell-content'>
        {countsByType.area > 0 ? <p>{singleString}</p> : null}
        {countsByType.area > 0 ? <p>{inRowString}</p> : null}
        {spacings.sfgCount === null ? null : countsByType.sfg > 0 ? <p>{sfgString}</p> : null}
      </div>
    </td>
  );
};

export default SpacingsCell;
