import Country from '@gi/country';
import CountryRegion from '@gi/country-region';
import { weatherStationService, WeatherStationUtils } from '@gi/weather-station';

const DAYS_IN_30_WEEKS = 7 * 30;

const NO_FROST_FIRST_FROST = 364;
const NO_FROST_LAST_FROST = 15;

interface CommonFrostDateSettings {
  frostDatesSet: boolean;
  firstFrostDay: number | null;
  lastFrostDay: number | null;
  noFrost: boolean;
  splitSeason: boolean;
}

interface CountryDefaultFrostDateSettings extends CommonFrostDateSettings {
  mode: 'defaults';
}

interface AutomaticFrostDateSettings extends CommonFrostDateSettings {
  mode: 'automatic';
  weatherStationName: string;
  weatherStationDistance: number;
}

interface RegionalFrostDateSettings extends CommonFrostDateSettings {
  mode: 'regional';
  weatherStationName?: string;
  weatherStationDistance?: number;
  regionID: number;
}

interface ManualFrostDateSettings extends CommonFrostDateSettings {
  mode: 'manual';
}

export type FrostDateSettings = CountryDefaultFrostDateSettings | AutomaticFrostDateSettings | RegionalFrostDateSettings | ManualFrostDateSettings;

class FrostDates {
  /**
   * Checks if the given frost dates are a valid pair.
   * @param firstFrost The first frost date
   * @param lastFrost The last frost date
   * @returns True if the frost dates are valid, otherwise false.
   */
  static validateFrostDates(firstFrost: number, lastFrost: number) {
    if (!Number.isInteger(firstFrost) || !Number.isInteger(lastFrost)) {
      return false;
    }
    if (firstFrost < 0 || lastFrost < 0) {
      return false;
    }
    if (firstFrost > 366 || lastFrost > 366) {
      return false;
    }
    if (firstFrost < lastFrost) {
      return false;
    }
    return true;
  }

  /**
   * Gets the default frost settings for the given country.
   * @param country The country
   * @returns A set of user settings
   */
  static getCountryDefaults(country: Country, splitSeason: boolean = false): CountryDefaultFrostDateSettings {
    return {
      mode: 'defaults',
      frostDatesSet: true,
      firstFrostDay: country.defaultFirstFrostDay,
      lastFrostDay: country.defaultLastFrostDay,
      noFrost: false,
      splitSeason,
    };
  }

  /**
   * Generates frost settings manually.
   * @param noFrost If the area experiences frost
   * @param firstFrost The first frost day
   * @param lastFrost The last frost day
   * @param splitSeason If the season should be split
   * @returns A set of user settings
   */
  static getManualFrostSettings(noFrost: boolean, firstFrost: number, lastFrost: number, splitSeason: boolean): ManualFrostDateSettings {
    const finalFirstFrost = noFrost ? NO_FROST_FIRST_FROST : firstFrost;
    const finalLastFrost = noFrost ? NO_FROST_LAST_FROST : lastFrost;

    const valid = this.validateFrostDates(finalFirstFrost, finalLastFrost);
    if (!valid) {
      throw new Error('Invalid frost dates');
    }

    return {
      mode: 'manual',
      frostDatesSet: true,
      firstFrostDay: finalFirstFrost === 366 ? 365 : finalFirstFrost,
      lastFrostDay: finalLastFrost,
      noFrost,
      splitSeason,
    };
  }

  /**
   * Generates frost settings automatically based on country and location.
   * Falls back to country defaults if no local weather stations can be found.
   * @param country The user's country
   * @param latitude The user's location latitude
   * @param longitude The user's location longitude
   * @returns A set of user settings
   */
  static async getAutomaticFrostSettings(country: Country, latitude: number, longitude: number): Promise<FrostDateSettings> {
    let settings: FrostDateSettings = this.getCountryDefaults(country, country.automaticSplitSeason);

    const stations = await weatherStationService.getWeatherStations(latitude, longitude);
    if (stations.length > 0) {
      const closest = stations.sort((a, b) => {
        const distA = WeatherStationUtils.getDistance(a, latitude, longitude);
        const distB = WeatherStationUtils.getDistance(b, latitude, longitude);
        return distA - distB;
      })[0];

      const firstFrostDay = closest.noFrost ? NO_FROST_FIRST_FROST : closest.firstFrost;
      const lastFrostDay = closest.noFrost ? NO_FROST_LAST_FROST : closest.lastFrost;
      settings = {
        mode: 'automatic',
        frostDatesSet: true,
        firstFrostDay,
        lastFrostDay,
        noFrost: closest.noFrost,
        splitSeason: country.automaticSplitSeason && firstFrostDay - lastFrostDay > DAYS_IN_30_WEEKS,
        weatherStationName: closest.stationName,
        weatherStationDistance: WeatherStationUtils.getDistance(closest, latitude, longitude),
      };
    }

    return settings;
  }

  /**
   * Generates regional frost settings.
   * With regional planting, frost dates are ignored, so getting them automatically could be removed from this.
   * @param country The user's country
   * @param latitude The user's location latitude
   * @param longitude The user's location longitude
   * @param region The uer's region
   * @returns A set of user settings
   */
  static async getRegionalFrostSettings(country: Country, latitude: number, longitude: number, region: CountryRegion): Promise<RegionalFrostDateSettings> {
    const settings = await this.getAutomaticFrostSettings(country, latitude, longitude);

    return {
      ...settings,
      mode: 'regional',
      regionID: region.regionID,
    };
  }
}

export default FrostDates;
