import { createContext } from 'react';
import { PlantSearchService, PlantVarietySearchService } from '@gi/search-service';
import { PlantListAreaGroup, PlantListData, PlantListSummaryGroup, PlantListVarietyGroup } from '../types/plant-list-types';

export type LocalPlantListContextType = {
  planID: null | number;
  showFilters: boolean;
  setShowFilters: (showFilters: boolean) => void;
  plantListData: PlantListData | null;
  filteredPlantGroupData: readonly PlantListSummaryGroup[];
  filteredVarietyGroupData: readonly PlantListVarietyGroup[];
  filteredAreaGroupData: readonly PlantListAreaGroup[];
  setPlantTableElement: (plantTable: HTMLElement | null) => void;
  printPlantList: () => void;
  createCSV: () => void;
  isPrinting: boolean;
  plantListSearchService: PlantSearchService;
  plantVarietySearchService: PlantVarietySearchService;
};

const LocalPlantListContext = createContext<LocalPlantListContextType>({
  planID: null,
  showFilters: false,
  setShowFilters: () => {},
  plantListData: null,
  filteredPlantGroupData: [],
  filteredVarietyGroupData: [],
  filteredAreaGroupData: [],
  setPlantTableElement: () => {},
  printPlantList: () => {},
  createCSV: () => {},
  isPrinting: false,
  plantListSearchService: new PlantSearchService([]),
  plantVarietySearchService: new PlantVarietySearchService([]),
});

export default LocalPlantListContext;
