import React from 'react';

import './client-message.scss';

interface iProps {
  messageHTML: string;
}

const ClientMessage = ({ messageHTML }: iProps): JSX.Element => {
  return (
    <div className='client-message'>
      {/* <div className='client-message-icon'>
        <i className='icon-info-circled' />
      </div> */}
      {/* eslint-disable-next-line react/no-danger */}
      <div className='client-message-content' dangerouslySetInnerHTML={{ __html: messageHTML }} />
    </div>
  );
};

export default ClientMessage;
