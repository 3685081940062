import React, { useState, useEffect, useContext } from 'react';

import AnimateHeight from 'react-animate-height';

import { collectionNotNull, ResourceContext } from '@gi/resource-provider';
import { lexographicSort, oxfordCommaJoin, getArrayDiff } from '@gi/utils';
import { useHelpModalEventHook } from '@gi/garden-platform-events';
import { CompanionIcon } from '@gi/plant-icon';
import { updateFilters } from '@gi/filters';

import FilterRow from '../../../filters/filter-row';
import CompanionModal from './companion-modal';

import { DrawingToolsContext } from '../../../drawing-tools-context';

import './companion-filter-view.scss';

const CompanionFilterView = (): JSX.Element => {
  const { userPlants } = useContext(ResourceContext);
  const notNullPlants = collectionNotNull(userPlants);
  const { plantFilters, setPlantFilters, selectedPlants, viewCompanions } = useContext(DrawingToolsContext);
  const [companionModalOpen, setCompanionModalOpen] = useState(false);
  const [selectedPlantsHaveChanged, setSelectedPlantsHaveChanged] = useState(false);

  const filterInputs = plantFilters.filters.companion.inputs;

  useHelpModalEventHook('companion planting', companionModalOpen, 'drawing-tools');

  useEffect(() => {
    const changedSelection = getArrayDiff(
      [...filterInputs.plantCodes],
      selectedPlants.map((plant) => plant.code)
    );
    if (selectedPlants.length && (changedSelection.removed.length || changedSelection.added.length)) {
      setSelectedPlantsHaveChanged(true);
    } else {
      setSelectedPlantsHaveChanged(false);
    }
  }, [selectedPlants, filterInputs]);

  const onClear = () => {
    setPlantFilters(updateFilters(plantFilters, { companion: { enabled: false } }));
  };

  const renderButtons = () => {
    const showButtonDisabled = selectedPlants.length === 0;

    return (
      <FilterRow
        onHelp={() => {
          setCompanionModalOpen(true);
        }}
        className='companion-filter-row'
      >
        <div className='filter-row-label filter-row-label-small'>
          <span className='filter-row-label-content'>
            {filterInputs.enabled ? (
              <button type='button' className='button button-secondary companion-button' onClick={onClear}>
                Close Companions <CompanionIcon />
              </button>
            ) : (
              <button
                type='button'
                className='button button-secondary companion-button'
                title='Show companion plants to those selected'
                disabled={showButtonDisabled}
                onClick={() => viewCompanions()}
              >
                Show Companions <CompanionIcon disabled={showButtonDisabled} />
              </button>
            )}
          </span>
        </div>
      </FilterRow>
    );
  };

  const renderListOfPlants = (plantCodes: Set<string>) => {
    const pNames = [...plantCodes]
      .map((code) => notNullPlants.get(code))
      .filter((item) => item !== null)
      .map((plant) => plant!.name.toLocaleLowerCase())
      .sort(lexographicSort);
    return oxfordCommaJoin(pNames, 'or');
  };

  const renderSelectedCompanionPlants = () => {
    // Note: this is regardless of whether companion filter is enabled, as it's wrapped in AnimateHeight.
    // Otherwise when you disable, the contents disappear before the height animates up.
    return (
      <>
        {selectedPlantsHaveChanged ? (
          <FilterRow narrow>
            Selected plants have changed.{' '}
            <button type='button' className='button button-inline update-companions' onClick={() => viewCompanions()}>
              Update?
            </button>
          </FilterRow>
        ) : (
          ''
        )}
        <FilterRow narrow>Showing companions for {renderListOfPlants(filterInputs.plantCodes)}.</FilterRow>
      </>
    );
  };

  const setCompanionFilterInputs = (inputs) => {
    setPlantFilters(updateFilters(plantFilters, { companion: inputs }));
  };

  let nameOrThem = 'any of them';
  if (filterInputs.plantCodes.size === 1) {
    const plant = notNullPlants.get([...filterInputs.plantCodes][0]);
    if (plant !== null) {
      nameOrThem = plant.name.toLocaleLowerCase();
    } else {
      console.warn("Couldn't find plant", filterInputs.plantCodes[0]);
    }
  }

  const companionRelationFilters = [
    {
      label: `Only plants that help ${nameOrThem}`,
      inputsEnabled: {
        onlyHelped: true,
      },
      inputsDisabled: {
        onlyHelped: false,
      },
    },
    {
      label: `Only plants helped by ${nameOrThem}`,
      inputsEnabled: {
        onlyHelper: true,
      },
      inputsDisabled: {
        onlyHelper: false,
      },
    },
  ];

  return (
    <>
      {companionModalOpen ? (
        <CompanionModal
          closeModal={() => {
            setCompanionModalOpen(false);
          }}
        />
      ) : null}
      {renderButtons()}
      <AnimateHeight className='companion-filter-options' duration={200} height={filterInputs.enabled ? 'auto' : 0}>
        {renderSelectedCompanionPlants()}
        {companionRelationFilters.map(({ label, inputsEnabled, inputsDisabled }) => {
          const isEnabled = Object.entries(inputsEnabled).every(([key, value]) => {
            return filterInputs[key] === value;
          });
          return (
            <FilterRow narrow key={label}>
              <label className='filter-row-label filter-row-checkbox'>
                <span className='filter-row-label-content'>
                  <input
                    className='filter-box'
                    type='checkbox'
                    onChange={() => setCompanionFilterInputs(isEnabled ? inputsDisabled : inputsEnabled)}
                    checked={isEnabled}
                  />
                </span>
                <span className='filter-row-label-text'>{label}</span>
              </label>
            </FilterRow>
          );
        })}
      </AnimateHeight>
    </>
  );
};

export default CompanionFilterView;
