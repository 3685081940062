import React, { ReactNode } from 'react';

import './form-subtext.scss';

export enum FormSubtextType {
  Info = 'Info',
  Help = 'Help',
  Warning = 'Warning',
  Error = 'Error',
}

const subtextTypeClasses: { [k in keyof typeof FormSubtextType]: string } = {
  [FormSubtextType.Info]: 'rforms-infotext',
  [FormSubtextType.Help]: 'rforms-helptext',
  [FormSubtextType.Warning]: 'rforms-warningtext',
  [FormSubtextType.Error]: 'rforms-errortext',
};

interface iProps {
  type?: FormSubtextType;
  children?: ReactNode;
  className?: string;
}

/**
 * Small errortext to be displayed on a form
 * @param props The props for this component
 * @returns Some helptext
 */
const FormSubtext = ({ type = FormSubtextType.Info, children, className }: iProps): JSX.Element => {
  const classNames = [subtextTypeClasses[type]];
  if (className) {
    classNames.push(className);
  }
  return <span className={classNames.join(' ')}>{children}</span>;
};

export default FormSubtext;
