import React, { forwardRef, useContext } from 'react';

import { AppContext } from '@gi/app-provider';
import { Collection } from '@gi/collection';
import { metricDistanceUnits, DistanceUnits } from '@gi/units';
import GardenObject from '@gi/garden-object';
import { PlanGardenObject } from '@gi/plan';

import PartListTableData from './part-list-table/data/part-list-table-data';
import PartListTable from './part-list-table/part-list-table';
import ClientMessage from './client-message';

import './part-list.scss';

interface iProps {
  gardenObjects: Collection<GardenObject> | null; // Kept nullable to preserve compatibility with resourceLoader
  planGardenObjects: PlanGardenObject[];
  distanceUnits: DistanceUnits;
  title: string;
  artifactCode: string;
  isMobile?: boolean;
}

// The ref is used to allow the list to be printed in certain parts of the app.

const PartList = forwardRef<HTMLElement, iProps>(
  ({ gardenObjects, planGardenObjects, distanceUnits = metricDistanceUnits, title = 'Part List', artifactCode = 'gb', isMobile = false }, ref): JSX.Element => {
    const partListTableData = PartListTableData.createFromGardenObjectList(planGardenObjects, gardenObjects);

    const { runtimeConfig } = useContext(AppContext);

    const classNames: string[] = ['part-list'];
    if (isMobile) {
      classNames.push('mobile');
    }

    return (
      <div ref={ref as React.ForwardedRef<HTMLDivElement>} className={classNames.join(' ')}>
        <div className='part-list-title-bar'>
          <div className='part-list-title'>
            <h3>{title}</h3>
          </div>
        </div>
        <p className='part-list-summary'>
          The Parts List provides a summary of the Garden Object items on your plan to make it easy to work out what you need to make or buy for your garden.
        </p>
        <PartListTable partListTableData={partListTableData} distanceUnits={distanceUnits} artifactCode={artifactCode} />
        {runtimeConfig.partsListMessageHTML ? <ClientMessage messageHTML={runtimeConfig.partsListMessageHTML} /> : null}
      </div>
    );
  }
);
PartList.displayName = 'PartList';

export default PartList;
