function tryJSONStringify(object: any) {
  try {
    return JSON.stringify(object);
  } catch (e) {
    return object;
  }
}

/**
 * Error class for wrapping non-errors that are reported to the error reporter
 */
export class NonErrorError extends Error {
  value: any;

  constructor(nonError?: any) {
    super(`A non-error was handed to the error reporter instance:
${tryJSONStringify(nonError)}`);

    this.value = nonError;
  }
}
