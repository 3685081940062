import { createCalendarRangeFromMonths, getCalendarRangeStrings, CalendarRange, halfMonthInRange, CalendarRangeStrings } from './calendar-range';
import MonthType from './month-type';

import { NumberedPlantingCalendar, MonthTypesCalendar, AvailabilityPlantingCalendar } from './planting-calendar-types';

export type PlantingCalendar = Readonly<{
  sow: CalendarRange[];
  plant: CalendarRange[];
  harvest: CalendarRange[];
  numbered: NumberedPlantingCalendar;
  availability: AvailabilityPlantingCalendar;
  isCustom: boolean;
  modifier: null | string;
  earliestSow: number | null;
  earliestPlant: number | null;
  earliestHarvest: number | null;
  latestSow: number | null;
  latestPlant: number | null;
  latestHarvest: number | null;
}>;

/**
 * Creates AvailabilityCalendar for sow, plant and harvest from a NumberedPlantingCalendar
 */
const convertPlantingNumbersToAvailability = (plantingTimeNumbers: NumberedPlantingCalendar): AvailabilityPlantingCalendar => {
  const availability: { sow: boolean[]; plant: boolean[]; harvest: boolean[] } = {
    sow: [],
    plant: [],
    harvest: [],
  };

  for (let j = 0; j < 12; j++) {
    availability.sow[j] = plantingTimeNumbers[j] % 10 > 0;
    availability.plant[j] = Math.floor(plantingTimeNumbers[j] / 10) % 10 > 0;
    availability.harvest[j] = Math.floor(plantingTimeNumbers[j] / 100) % 10 > 0;
  }

  // Force type as we know it's correct
  return availability as unknown as AvailabilityPlantingCalendar;
};

/**
 * Creates an array of 12 numbers, between 1 and 3 digits containing values 0-3
 *
 * The values represent the sowing, planting and harvesting times, we then map the number to an image to create a planting calendar
 * for a user.
 *
 * The left-most digit represents harvest, following by planting and then sowing. Where leading 0's are removed, except in the case of just '0'.
 * The digits have the following meanings
 *
 * 0 - Nothing in this month
 * 1 - Active in the first half of the month
 * 2 - Active in the last half of the month
 * 3 - Active the whole month
 *
 * So 320 = full month of harvest, last half month of planting and nothing in the month for sowing
 */
export function getNumberedPlantingCalendar(
  sowDateRange: CalendarRange[],
  plantDateRange: CalendarRange[],
  harvestDateRange: CalendarRange[]
): NumberedPlantingCalendar {
  const sowPlantHarvest: Mutable<NumberedPlantingCalendar> = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  for (let i = 1; i < 25; i++) {
    const firstHalf = (i - 1) % 2 === 0;
    const monthNumber = Math.floor((i - 1) / 2);

    if (firstHalf) {
      for (let j = 0; j < sowDateRange.length; j++) {
        if (halfMonthInRange(sowDateRange[j], i)) {
          sowPlantHarvest[monthNumber] += 1;
          break;
        }
      }

      for (let j = 0; j < plantDateRange.length; j++) {
        if (halfMonthInRange(plantDateRange[j], i)) {
          sowPlantHarvest[monthNumber] += 10;
          break;
        }
      }

      for (let j = 0; j < harvestDateRange.length; j++) {
        if (halfMonthInRange(harvestDateRange[j], i)) {
          sowPlantHarvest[monthNumber] += 100;
          break;
        }
      }
    } else {
      for (let j = 0; j < sowDateRange.length; j++) {
        if (halfMonthInRange(sowDateRange[j], i)) {
          sowPlantHarvest[monthNumber] += 2;
          break;
        }
      }

      for (let j = 0; j < plantDateRange.length; j++) {
        if (halfMonthInRange(plantDateRange[j], i)) {
          sowPlantHarvest[monthNumber] += 20;
          break;
        }
      }

      for (let j = 0; j < harvestDateRange.length; j++) {
        if (halfMonthInRange(harvestDateRange[j], i)) {
          sowPlantHarvest[monthNumber] += 200;
          break;
        }
      }
    }
  }

  return sowPlantHarvest;
}

/**
 * Returns an object containing text representations of a given sow-planting date range
 */
export function getPlantingCalendarStrings(
  plantingCalendar: PlantingCalendar,
  offset: number
): { sow: CalendarRangeStrings[]; plant: CalendarRangeStrings[]; harvest: CalendarRangeStrings[] } {
  return {
    sow: plantingCalendar.sow.map((r) => getCalendarRangeStrings(r, offset)),
    plant: plantingCalendar.plant.map((r) => getCalendarRangeStrings(r, offset)),
    harvest: plantingCalendar.harvest.map((r) => getCalendarRangeStrings(r, offset)),
  };
}

/**
 * Returns true if there's no data available for these planting times
 */
export function plantingCalendarIsEmpty(plantingCalendar: PlantingCalendar): boolean {
  return plantingCalendar.sow.length === 0 && plantingCalendar.plant.length === 0 && plantingCalendar.harvest.length === 0;
}

/**
 * Takes a 1 - 3 digit planting time number and returns the sowing value from it (the first digit)
 */
export function getSowingVal(num: number): MonthType {
  return num % 10;
}

/**
 * Takes a 1 - 3 digit planting time number and returns the planting value from it (the second digit)
 */
export function getPlantingVal(num): MonthType {
  return Math.floor(num / 10) % 10;
}

/**
 * Takes a 1 - 3 digit planting time number and returns the harvest value from it (the third digit)
 */
export function getHarvestVal(num): MonthType {
  return Math.floor(num / 100) % 10;
}

function getEarliestAndLatestValues(
  numberedCalendar: NumberedPlantingCalendar
): [number | null, number | null, number | null, number | null, number | null, number | null] {
  let earliestSow: number | null = null;
  let latestSow: number | null = null;
  let earliestPlant: number | null = null;
  let latestPlant: number | null = null;
  let earliestHarvest: number | null = null;
  let latestHarvest: number | null = null;

  let hasEmptySow = false;
  let hasEmptyPlant = false;
  let hasEmptyHarvest = false;
  let hasSow = false;
  let hasPlant = false;
  let hasHarvest = false;

  for (let i = 0; i < 12; i++) {
    const val = numberedCalendar[i];
    const previousVal = numberedCalendar[(i + 1) % 12];

    const sowingVal = getSowingVal(val);
    const plantingVal = getPlantingVal(val);
    const harvestVal = getHarvestVal(val);

    const nextSowingVal = getSowingVal(previousVal);
    const nextPlantingVal = getPlantingVal(previousVal);
    const nextHarvestVal = getHarvestVal(previousVal);

    if (sowingVal !== 0) {
      hasSow = true;
    }

    if (plantingVal !== 0) {
      hasPlant = true;
    }

    if (harvestVal !== 0) {
      hasHarvest = true;
    }

    if (sowingVal === 0 || sowingVal === 2) {
      hasEmptySow = true;
    }

    if (plantingVal === 0 || plantingVal === 2) {
      hasEmptyPlant = true;
    }

    if (harvestVal === 0 || harvestVal === 2) {
      hasEmptyHarvest = true;
    }

    if (earliestSow === null && sowingVal !== 0 && hasEmptySow) {
      if (sowingVal === 1 || sowingVal === 3) {
        earliestSow = i * 2;
      } else {
        // === 2
        earliestSow = i * 2 + 1;
      }
    }

    if (earliestPlant === null && plantingVal !== 0 && hasEmptyPlant) {
      if (plantingVal === 1 || plantingVal === 3) {
        earliestPlant = i * 2;
      } else {
        // === 2
        earliestPlant = i * 2 + 1;
      }
    }

    if (earliestHarvest === null && harvestVal !== 0 && hasEmptyHarvest) {
      if (plantingVal === 1 || harvestVal === 3) {
        earliestHarvest = i * 2;
      } else {
        // === 2
        earliestHarvest = i * 2 + 1;
      }
    }

    if (nextSowingVal === 0 || nextSowingVal === 2) {
      if (sowingVal === 2 || sowingVal === 3) {
        latestSow = i * 2 + 1;
      } else if (sowingVal === 1) {
        latestSow = i * 2;
      }
    }

    if (nextPlantingVal === 0 || nextPlantingVal === 2) {
      if (plantingVal === 2 || plantingVal === 3) {
        latestPlant = i * 2 + 1;
      } else if (plantingVal === 1) {
        latestPlant = i * 2;
      }
    }

    if (nextHarvestVal === 0 || nextHarvestVal === 2) {
      if (harvestVal === 2 || harvestVal === 3) {
        latestHarvest = i * 2 + 1;
      } else if (harvestVal === 1) {
        latestHarvest = i * 2;
      }
    }

    if (sowingVal === 1) {
      hasEmptySow = true;
    }

    if (plantingVal === 1) {
      hasEmptyPlant = true;
    }

    if (harvestVal === 1) {
      hasEmptyHarvest = true;
    }
  }

  if (earliestSow === null) {
    earliestSow = 0;
  }

  if (earliestPlant === null) {
    earliestPlant = 0;
  }

  if (earliestHarvest === null) {
    earliestHarvest = 0;
  }

  if (!hasSow) {
    earliestSow = -1;
    latestSow = -1;
  }

  if (!hasPlant) {
    earliestPlant = -1;
    latestPlant = -1;
  }

  if (!hasHarvest) {
    earliestHarvest = -1;
    latestHarvest = -1;
  }

  return [earliestSow, latestSow, earliestPlant, latestPlant, earliestHarvest, latestHarvest];
}

/**
 * Creates an instance of planting times from month numbers
 */
export function createPlantingCalendarFromNumbers(
  numberedCalendar: NumberedPlantingCalendar,
  isCustom: boolean = false,
  modifier: null | string = null
): PlantingCalendar {
  if (numberedCalendar.length !== 12) {
    throw new Error('There must be 12 planting time numbers to create planting dates');
  }

  const sowingVals: MonthTypesCalendar = numberedCalendar.map(getSowingVal) as unknown as MonthTypesCalendar;
  const plantingVals: MonthTypesCalendar = numberedCalendar.map(getPlantingVal) as unknown as MonthTypesCalendar;
  const harvestingVals: MonthTypesCalendar = numberedCalendar.map(getHarvestVal) as unknown as MonthTypesCalendar;

  const [earliestSow, latestSow, earliestPlant, latestPlant, earliestHarvest, latestHarvest] = getEarliestAndLatestValues(numberedCalendar);

  return {
    sow: createCalendarRangeFromMonths(sowingVals),
    plant: createCalendarRangeFromMonths(plantingVals),
    harvest: createCalendarRangeFromMonths(harvestingVals),
    numbered: numberedCalendar,
    availability: convertPlantingNumbersToAvailability(numberedCalendar),
    isCustom,
    modifier,
    earliestSow,
    latestSow,
    earliestPlant,
    latestPlant,
    earliestHarvest,
    latestHarvest,
  };
}

/**
 * Creates a planting calendar from sowing, planting and harvesting date ranges, adding a calculated numbered planting calendar from the provided date ranges
 */
export function createPlantingCalendarFromHalfMonthDateRanges(
  sowDateRange: CalendarRange[],
  plantDateRange: CalendarRange[],
  harvestDateRange: CalendarRange[],
  isCustom: boolean = false,
  modifier: null | string = null
): PlantingCalendar {
  const numberedCalendar = getNumberedPlantingCalendar(sowDateRange, plantDateRange, harvestDateRange);

  const [earliestSow, latestSow, earliestPlant, latestPlant, earliestHarvest, latestHarvest] = getEarliestAndLatestValues(numberedCalendar);

  return {
    sow: sowDateRange,
    plant: plantDateRange,
    harvest: harvestDateRange,
    numbered: numberedCalendar,
    availability: convertPlantingNumbersToAvailability(numberedCalendar),
    isCustom,
    modifier,
    earliestSow,
    latestSow,
    earliestPlant,
    latestPlant,
    earliestHarvest,
    latestHarvest,
  };
}

/**
 * Creates an empty planting calendar for convinience
 *
 * TODO: Consider using a constant empty planting calendar for when needed as it's immutable
 */
export function createEmptyPlantingCalendar(): PlantingCalendar {
  return {
    sow: [],
    plant: [],
    harvest: [],
    numbered: [
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
      MonthType.EMPTY_MONTH,
    ],
    availability: {
      sow: [false, false, false, false, false, false, false, false, false, false, false, false],
      plant: [false, false, false, false, false, false, false, false, false, false, false, false],
      harvest: [false, false, false, false, false, false, false, false, false, false, false, false],
    },
    isCustom: false,
    modifier: null,
    earliestSow: null,
    latestSow: null,
    earliestPlant: null,
    latestPlant: null,
    earliestHarvest: null,
    latestHarvest: null,
  };
}

export const EMPTY_PLANTING_CALENDAR = createEmptyPlantingCalendar();
