import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type EditPlantNotesState = {
  open: boolean;
  planID: number | null;
  plantCode: string | null;
  varietyName: string | null;
};

const DEFAULT_STATE: EditPlantNotesState = {
  open: false,
  planID: null,
  plantCode: null,
  varietyName: null,
};

export interface OpenPlantNotesModalPayload {
  planID: number;
  plantCode: string;
  varietyName: string;
}

export const editPlantNotesSlice = createSlice({
  name: 'editPlantNotes',
  initialState: DEFAULT_STATE,
  reducers: {
    open: (state, action: PayloadAction<OpenPlantNotesModalPayload>) => {
      state.open = true;
      state.planID = action.payload.planID;
      state.plantCode = action.payload.plantCode;
      state.varietyName = action.payload.varietyName;
    },
    close: (state) => {
      state.open = false;
      state.planID = null;
      state.plantCode = null;
      state.varietyName = null;
    },
  },
});

type RootState = {
  editPlantNotes: EditPlantNotesState;
};

export const editPlantNotesReducer = editPlantNotesSlice.reducer;
export const EditPlantNotesActions = editPlantNotesSlice.actions;

export const EditPlantNotesSelectors = {
  getState: (state: RootState) => state.editPlantNotes,
};
