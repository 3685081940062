import React, { ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CanvasSelectors } from '@gi/react-garden-canvas';

import './overlay-toolbar.scss';

export enum ToolbarAnchor {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
}

export enum ToolbarOrientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

interface iProps {
  children: ReactNode;
  anchor: ToolbarAnchor;
  orientation: ToolbarOrientation;
}

const OverlayToolbar = ({ children, anchor, orientation }: iProps): JSX.Element => {
  const activePlan = useSelector(CanvasSelectors.getActivePlan);

  const hasRulers = useMemo(() => {
    if (!activePlan) {
      return true;
    }
    return activePlan.plannerSettings.showRulers;
  }, [activePlan]);

  const classNames = ['mobile-overlay-toolbar'];
  classNames.push(`anchor-${anchor}`);
  classNames.push(`orientation-${orientation}`);
  if (hasRulers) {
    classNames.push('has-rulers');
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default OverlayToolbar;
