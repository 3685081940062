import {
  ContentRootNode,
  DisplayModeComponentContext,
  DoubleClickableComponentContext,
  DraggableComponentContext,
  HoverableComponentContext,
  InspectableClassData,
  InteractionContext,
  LongPressableComponent,
  LongPressableComponentContext,
  ManipulatableComponentContext,
  RightClickableComponent,
  RightClickableComponentContext,
  SelectableComponentContext,
  ShapeComponentContext,
  TooltipComponentContext,
  VisibilityComponentContext,
} from '@gi/core-renderer';

class PlanRootNode extends ContentRootNode {
  type = 'PlanRootNode';

  readonly selectionContext: SelectableComponentContext;

  constructor() {
    super();

    this.selectionContext = this.contexts.add(new SelectableComponentContext());
    this.contexts.add(new HoverableComponentContext());
    this.contexts.add(new DraggableComponentContext());
    this.contexts.add(new ManipulatableComponentContext());
    this.contexts.add(new TooltipComponentContext());
    this.contexts.add(new ShapeComponentContext());
    this.contexts.add(new VisibilityComponentContext());
    this.contexts.add(new DoubleClickableComponentContext());
    this.contexts.add(new RightClickableComponentContext());
    this.contexts.add(new LongPressableComponentContext());
    this.contexts.add(new InteractionContext());
    this.contexts.add(new DisplayModeComponentContext());

    this.components.add(new RightClickableComponent());
    this.components.add(new LongPressableComponent());
  }

  inspectorData: InspectableClassData<this> = [];
}

export default PlanRootNode;
