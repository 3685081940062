import React, { ReactNode } from 'react';

interface iProps {
  children: ReactNode;
  className?: string;
}

const BannerContent = ({ children, className }: iProps): JSX.Element => {
  const classNames = ['banner-content'];
  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default BannerContent;
