/**
 * Enum for the different item types on a garden plan
 */
enum ItemNodeType {
  Plant = 'PLANT',
  SquareFootPlant = 'SQUARE_FOOT_PLANT',
  PlantLabel = 'PLANT_LABEL',
  GardenObject = 'GARDEN_OBJECT',
  Shape = 'SHAPE',
  Text = 'TEXT',
}

export default ItemNodeType;
