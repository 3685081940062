import React from 'react';
import { useDispatch } from 'react-redux';
import { EditPlantNotesActions } from './edit-plant-notes-slice';

import './edit-plant-notes-button.scss';

interface iProps {
  planID: number;
  plantCode: string;
  varietyName: string;
  className?: string;
  buttonText?: string;
}

/**
 * Button available for use when edit plant notes is needed for opening
 * Uses icon class 'icon-edit' for the button
 */
const EditPlantNotesButton = ({ planID, plantCode, varietyName, className = '', buttonText = '' }: iProps): JSX.Element => {
  const dispatch = useDispatch();

  return (
    <button
      type='button'
      className={`edit-plant-notes-button ${className}`}
      onClick={() => {
        dispatch(EditPlantNotesActions.open({ planID, plantCode, varietyName }));
      }}
    >
      <span className='icon-container'>
        <i className='icon-edit' />
      </span>
      <span className='text-container'>{buttonText}</span>
    </button>
  );
};

export default EditPlantNotesButton;
