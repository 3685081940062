import React, { useContext } from 'react';

import { TooltipKey } from '@gi/tooltip';
import { GlobalPlantListContext, DISPLAY_TYPE } from '@gi/plant-list';

import MenuButton from '../common/menu-button';
import ControlRow from '../common/control-row';
import ControlGroup from '../common/control-group';
import ControlButtonGroup from '../common/control-button-group';

const PlantListDisplayControls = (): JSX.Element => {
  const { displayType, setDisplayType } = useContext(GlobalPlantListContext);

  return (
    <ControlGroup>
      <ControlRow>
        <ControlButtonGroup>
          <MenuButton
            id='new-plan'
            selectButton
            active={displayType === DISPLAY_TYPE.Variety}
            onClick={() => setDisplayType(DISPLAY_TYPE.Variety)}
            tooltipKey={TooltipKey.PlantListDisplayVariety}
          >
            Default
          </MenuButton>
          <MenuButton
            id='new-plan'
            selectButton
            active={displayType === DISPLAY_TYPE.Summary}
            onClick={() => setDisplayType(DISPLAY_TYPE.Summary)}
            tooltipKey={TooltipKey.PlantListDisplaySummary}
          >
            Summary
          </MenuButton>
          <MenuButton
            id='new-plan'
            selectButton
            active={displayType === DISPLAY_TYPE.Area}
            onClick={() => setDisplayType(DISPLAY_TYPE.Area)}
            tooltipKey={TooltipKey.PlantListDisplayArea}
          >
            Individual Plantings
          </MenuButton>
        </ControlButtonGroup>
      </ControlRow>
    </ControlGroup>
  );
};

export default PlantListDisplayControls;
