import React from 'react';

import { AccordionButton, AccordionContent, AccordionDropdownIcon } from '@gi/accordion';

import useObjective from '../hooks/use-objective';
import useObjectiveGroup from '../hooks/use-objective-group';
import ObjectiveOverviewCard from './cards/objective-overview-card';
import ObjectiveGroupOverviewCard from './cards/objective-group-overview-card';

import clipboardSVG from '../assets/icons/clipboard-lines.svg';

import styles from './objectives-display.module.css';

interface iObjectiveCardProps {
  objectiveId: string;
  minimised: boolean;
  onLaunchHelp?: () => void;
}

const ObjectiveCard = ({ objectiveId, minimised, onLaunchHelp }: iObjectiveCardProps): JSX.Element | null => {
  const { objective, completed } = useObjective(objectiveId);

  if (!objective) {
    return null;
  }

  return (
    <ObjectiveOverviewCard
      title={objective.title}
      description={objective.description}
      icon={objective.icon}
      completed={completed !== false}
      tutorialName={objective.tutorialName}
      minimised={minimised}
      key={objective.id}
      onLaunchHelp={onLaunchHelp}
    />
  );
};

interface iProps {
  groupId: string;
  minimised?: boolean;
  onLaunchHelp?: () => void;
}

/**
 * Displays a single objective group, with all objectives listed underneath
 */
const ObjectiveGroupDisplay = ({ groupId, minimised = false, onLaunchHelp }: iProps): JSX.Element => {
  const { group, completedObjectiveIds } = useObjectiveGroup(groupId);

  return (
    <div className={styles.objectiveGroupDisplay}>
      <AccordionButton accordionId={group.id} className={styles.accordionButton}>
        <ObjectiveGroupOverviewCard
          title={group.title}
          description={group.description}
          icon={group.icon ?? clipboardSVG}
          objectiveCount={group.objectiveIds.length}
          completedObjectiveCount={completedObjectiveIds.length}
          minimised={minimised}
        >
          <AccordionDropdownIcon accordionId={group.id} className={styles.accordionDropdownIcon} />
        </ObjectiveGroupOverviewCard>
      </AccordionButton>
      <AccordionContent accordionId={group.id} className={styles.objectiveList} contentClassName={styles.objectiveListInner}>
        {group.objectiveIds.map((objectiveId) => (
          <ObjectiveCard objectiveId={objectiveId} minimised={minimised} onLaunchHelp={onLaunchHelp} key={objectiveId} />
        ))}
      </AccordionContent>
    </div>
  );
};

export default ObjectiveGroupDisplay;
