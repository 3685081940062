import React, { useContext } from 'react';
import GlobalPlantListContext from '../../global-provider/global-plant-list-context';
import PlantListOptionFilter from './plant-list-option-filter';

const PrintBackgroundColorsOption = () => {
  const { options, setOptions } = useContext(GlobalPlantListContext);

  return (
    <PlantListOptionFilter
      label='Print Background Colors'
      id='plant-list-option:print-bg-colours'
      value={options.printBackgroundColors}
      setValue={(checked) => {
        setOptions({ printBackgroundColors: checked });
      }}
    />
  );
};

export default PrintBackgroundColorsOption;
