import { GardenItemType } from '@gi/constants/types/garden-item-type';

import type { PlanPlant } from './plan-plant';
import type { PlanText } from './plan-text';
import type { PlanShape } from './plan-shape';
import type { PlanGardenObject } from './plan-garden-object';
import type { PlanBackgroundImage } from './plan-background-image';
import { createEmptyPlantNotes, type PlanPlantNotes } from './plan-plant-notes';
import { PlannerSettings, DEFAULT_PLANNER_SETTINGS } from './planner-settings';

export type PlanItem = PlanPlant | PlanGardenObject | PlanShape | PlanText;

export type Plan = {
  id: number;
  userId: number;
  name: string;
  year: number;
  created: null | number;
  modified: null | number;
  deleted: null | number;
  documentVersion: string;
  lastSaveDevice: string;
  shared: boolean;
  width: number;
  height: number;
  orientationSet: boolean;
  orientation: number;
  history: number[];
  templatePlanId: number;
  notes: string;
  published: boolean;
  publishLocation: string;
  publishDescription: string;
  publishMap: boolean;
  publishFindOnMap: boolean;
  publishPlantList: boolean;
  publishNotes: boolean;
  type: number;
  layout: number;
  sun: number;
  soil: number;

  plannerSettings: PlannerSettings;

  maxItemId: number;
  itemTypes: Record<number, GardenItemType>;
  itemRecordIds: Record<number, number>;

  plants: Record<number, PlanPlant>;
  plantIds: number[];

  gardenObjects: Record<number, PlanGardenObject>;
  gardenObjectIds: number[];

  shapes: Record<number, PlanShape>;
  shapeIds: number[];

  text: Record<number, PlanText>;
  textIds: number[];

  plantNotes: PlanPlantNotes;
  plantNotesRecordIds: Record<string, number>;

  minZIndex: number;
  maxZIndex: number;

  backgroundImage?: PlanBackgroundImage;
};

const DEFAULT_PLAN_PROPERTIES: Plan = {
  id: 0,
  userId: 0,
  name: '',
  year: 2000,
  created: null,
  modified: null,
  deleted: null,
  documentVersion: '',
  lastSaveDevice: '',
  shared: false,
  width: 0,
  height: 0,
  orientationSet: false,
  orientation: 0,
  history: [],
  templatePlanId: 0,
  notes: '',
  published: false,
  publishLocation: '',
  publishDescription: '',
  publishMap: false,
  publishFindOnMap: false,
  publishPlantList: false,
  publishNotes: false,
  type: 0,
  layout: 0,
  sun: 0,
  soil: 0,
  plannerSettings: { ...DEFAULT_PLANNER_SETTINGS },
  maxItemId: 0,
  itemTypes: {},
  itemRecordIds: {},
  plants: {},
  plantIds: [],
  gardenObjects: {},
  gardenObjectIds: [],
  shapes: {},
  shapeIds: [],
  text: {},
  textIds: [],
  plantNotes: createEmptyPlantNotes(),
  plantNotesRecordIds: {},
  minZIndex: 0,
  maxZIndex: 0,
};

export function createNewPlan(userId: number, name: string, year: number, width: number, height: number, metric: boolean): Plan {
  return {
    ...DEFAULT_PLAN_PROPERTIES,
    id: 0,
    userId,
    name,
    year,
    width,
    height,
    plannerSettings: {
      ...DEFAULT_PLANNER_SETTINGS,
      metric,
    },
  };
}

export type FollowOnPlanOptions = {
  copyLayout: boolean;
  copyStructures: boolean;
  copyIrrigation: boolean;
  copyText: boolean;
  copyPlants: boolean;
  allPlants: boolean;
  copyNotes: boolean;
};
