import { Geometry } from '@gi/math';
import { CM_IN_FOOT, ShapeType, GardenObjectType } from '@gi/constants';
import { PlantSpacings } from '@gi/plant';

const HALF_SQUARE_FOOT_SIZE = CM_IN_FOOT / 2;

/**
 * Returns the bounds when given a start, end and rotation
 */
export function calculateBlockBounds(start: Vector2, end: Vector2, rotation: number): [Vector2, Vector2, Vector2, Vector2] {
  const center = Geometry.midpoint(start, end);
  const dx = Geometry.getDX(start, end) / 2;
  const dy = Geometry.getDY(start, end) / 2;

  const p1 = Geometry.rotateAroundPoint({ x: center.x - dx, y: center.y - dy }, center, rotation);
  const p2 = Geometry.rotateAroundPoint({ x: center.x + dx, y: center.y - dy }, center, rotation);
  const p3 = Geometry.rotateAroundPoint({ x: center.x + dx, y: center.y + dy }, center, rotation);
  const p4 = Geometry.rotateAroundPoint({ x: center.x - dx, y: center.y + dy }, center, rotation);

  return [p1, p2, p3, p4];
}

/**
 * Calculates the bounds of an SFG plant
 */
export function calculateSFGBounds(position: Vector2): [Vector2, Vector2, Vector2, Vector2] {
  return [
    { x: position.x - HALF_SQUARE_FOOT_SIZE, y: position.y - HALF_SQUARE_FOOT_SIZE },
    { x: position.x + HALF_SQUARE_FOOT_SIZE, y: position.y - HALF_SQUARE_FOOT_SIZE },
    { x: position.x + HALF_SQUARE_FOOT_SIZE, y: position.y + HALF_SQUARE_FOOT_SIZE },
    { x: position.x - HALF_SQUARE_FOOT_SIZE, y: position.y + HALF_SQUARE_FOOT_SIZE },
  ];
}

/**
 * Calculates the bounds of a plant block
 */
export function calculateBlockPlantBounds(rowStart: Vector2, rowEnd: Vector2, height: number, spacings: PlantSpacings): [Vector2, Vector2, Vector2, Vector2] {
  const halfSpacing = spacings.spacing / 2;

  const rowAngle = Geometry.angleBetweenPoints(rowStart, rowEnd);

  // Calculate corner positions
  const p1 = Geometry.rotateAroundPoint(
    {
      x: rowStart.x - halfSpacing,
      y: rowStart.y - halfSpacing,
    },
    rowStart,
    rowAngle
  );
  const p2 = Geometry.rotateAroundPoint(
    {
      x: rowEnd.x + halfSpacing,
      y: rowEnd.y - halfSpacing,
    },
    rowEnd,
    rowAngle
  );
  const p3 = Geometry.rotateAroundPoint(
    {
      x: rowEnd.x + halfSpacing,
      y: rowEnd.y + halfSpacing + height,
    },
    rowEnd,
    rowAngle
  );
  const p4 = Geometry.rotateAroundPoint(
    {
      x: rowStart.x - halfSpacing,
      y: rowStart.y + halfSpacing + height,
    },
    rowStart,
    rowAngle
  );

  return [p1, p2, p3, p4];
}

/**
 * Updates the bounds of a plant block
 */
export function updateBlockPlantBounds(
  rowStart: Vector2,
  rowEnd: Vector2,
  height: number,
  spacings: PlantSpacings,
  bounds: [Vector2, Vector2, Vector2, Vector2]
): void {
  const halfSpacing = spacings.spacing / 2;

  const rowAngle = Geometry.angleBetweenPoints(rowStart, rowEnd);

  bounds[0] = Geometry.rotateAroundPoint({ x: rowStart.x - halfSpacing, y: rowStart.y - halfSpacing }, rowStart, rowAngle);
  bounds[1] = Geometry.rotateAroundPoint({ x: rowEnd.x + halfSpacing, y: rowEnd.y - halfSpacing }, rowStart, rowAngle);
  bounds[2] = Geometry.rotateAroundPoint({ x: rowEnd.x + halfSpacing, y: rowEnd.y + halfSpacing }, rowStart, rowAngle);
  bounds[3] = Geometry.rotateAroundPoint({ x: rowStart.x - halfSpacing, y: rowStart.y + halfSpacing }, rowStart, rowAngle);
}

/**
 * Calculates the bounds of a plant row
 */
export function calculateRowPlantBounds(rowStart: Vector2, rowEnd: Vector2, spacings: PlantSpacings): [Vector2, Vector2, Vector2, Vector2] {
  const halfRowSpacing = spacings.rowSpacing / 2;
  const halfInRowSpacing = spacings.inRowSpacing / 2;

  const rowAngle = Geometry.angleBetweenPoints(rowStart, rowEnd);

  // Calculate corner positions
  const p1 = Geometry.rotateAroundPoint(
    {
      x: rowStart.x - halfInRowSpacing,
      y: rowStart.y - halfRowSpacing,
    },
    rowStart,
    rowAngle
  );
  const p2 = Geometry.rotateAroundPoint(
    {
      x: rowEnd.x + halfInRowSpacing,
      y: rowEnd.y - halfRowSpacing,
    },
    rowEnd,
    rowAngle
  );
  const p3 = Geometry.rotateAroundPoint(
    {
      x: rowEnd.x + halfInRowSpacing,
      y: rowEnd.y + halfRowSpacing,
    },
    rowEnd,
    rowAngle
  );
  const p4 = Geometry.rotateAroundPoint(
    {
      x: rowStart.x - halfInRowSpacing,
      y: rowStart.y + halfRowSpacing,
    },
    rowStart,
    rowAngle
  );

  return [p1, p2, p3, p4];
}

export function updateRowPlantBounds(rowStart: Vector2, rowEnd: Vector2, spacings: PlantSpacings, bounds: [Vector2, Vector2, Vector2, Vector2]): void {
  const halfRowSpacing = spacings.rowSpacing / 2;
  const halfInRowSpacing = spacings.inRowSpacing / 2;

  const rowAngle = Geometry.angleBetweenPoints(rowStart, rowEnd);

  bounds[0] = Geometry.rotateAroundPoint({ x: rowStart.x - halfInRowSpacing, y: rowStart.y - halfRowSpacing }, rowStart, rowAngle);
  bounds[1] = Geometry.rotateAroundPoint({ x: rowEnd.x + halfInRowSpacing, y: rowEnd.y - halfRowSpacing }, rowStart, rowAngle);
  bounds[2] = Geometry.rotateAroundPoint({ x: rowEnd.x + halfInRowSpacing, y: rowEnd.y + halfRowSpacing }, rowStart, rowAngle);
  bounds[3] = Geometry.rotateAroundPoint({ x: rowStart.x - halfInRowSpacing, y: rowStart.y + halfRowSpacing }, rowStart, rowAngle);
}

/**
 * Calculates the bounds of a single plant
 */
export function calculateSinglePlantBounds(position: Vector2, spacings: PlantSpacings): [Vector2, Vector2, Vector2, Vector2] {
  const halfSpacing = spacings.spacing / 2;

  return [
    { x: position.x - halfSpacing, y: position.y - halfSpacing },
    { x: position.x + halfSpacing, y: position.y - halfSpacing },
    { x: position.x + halfSpacing, y: position.y + halfSpacing },
    { x: position.x - halfSpacing, y: position.y + halfSpacing },
  ];
}

/**
 * Calculates the bounds of a plant when given the properties
 */
export function calculatePlantBounds(
  rowStart: Vector2,
  rowEnd: Vector2,
  height: number,
  spacings: PlantSpacings,
  isSFG: boolean
): [Vector2, Vector2, Vector2, Vector2] {
  if (isSFG) {
    return calculateSFGBounds(rowStart);
  }

  if (height === 0) {
    if (rowStart.x === rowEnd.x && rowStart.y === rowEnd.y) {
      return calculateSinglePlantBounds(rowStart, spacings);
    }

    return calculateRowPlantBounds(rowStart, rowEnd, spacings);
  }

  return calculateBlockPlantBounds(rowStart, rowEnd, height, spacings);
}

/**
 * Returns the bounds for the given path
 */
export function calculatePathBounds(start: Vector2, mid: Vector2 | null, end: Vector2) {
  if (mid === null) {
    return [
      { x: start.x, y: start.y },
      { x: end.x, y: end.y },
    ];
  }

  return [
    { x: start.x, y: start.y },
    { x: mid.x, y: mid.y },
    { x: end.x, y: end.y },
  ];
}

/**
 * Calculates the bounds for a given shape
 */
export function calculateShapeBounds(point1: Vector2, point2: Vector2, point3: Vector2, rotation, type: string): Vector2[] {
  if (type === ShapeType.RECTANGLE || type === ShapeType.ELLIPSE) {
    return calculateBlockBounds(point1, point3, rotation);
  }

  if (type === ShapeType.TRIANGLE) {
    return [
      { x: point1.x, y: point1.y },
      { x: point2.x, y: point2.y },
      { x: point3.x, y: point3.y },
    ];
  }

  // Type is Line
  return calculatePathBounds(point1, point2, point3);
}

export function calculateGardenObjectBounds(start: Vector2, mid: Vector2 | null, end: Vector2, rotation: number, type: string): Vector2[] {
  if (type === GardenObjectType.BLOCK) {
    // Block garden object
    return calculateBlockBounds(start, end, rotation);
  }

  // Path garden object
  return calculatePathBounds(start, mid, end);
}

/**
 * Returns a new set of bounds with all the points moved by the given delta. Used to update the points
 * when moving without having to re-compute the bounds
 */
export function moveBounds(bounds: Vector2[], delta: Vector2): Vector2[] {
  return bounds.map((p) => ({ x: p.x + delta.x, y: p.y + delta.y }));
}
