import { useCallback, useContext, useEffect, useRef } from 'react';

import MobileNotificationContext from './notification-context';

/**
 * Creates a ref that can be attached to an element to make it a notification portal target.
 * @example
 * const Component = () => {
 *   const notificationRef = useNotificationRef<HTMLDivElement>();
 *   return (<div ref={notificationRef}>Content</div>);
 * }
 */
const useMobileNotificationPortal = <T extends HTMLElement>() => {
  const { registerNotificationPortal } = useContext(MobileNotificationContext);

  const clearFunction = useRef<(() => void) | null>(null);

  /**
   * This is similar to creating a useRef, but it will also react to being made null.
   */
  const ref = useCallback((element: T | null) => {
    if (clearFunction.current) {
      clearFunction.current();
    }
    if (element) {
      clearFunction.current = registerNotificationPortal(element);
    }
  }, []);

  /**
   * When we're unmounted, unregister the element from the notification system.
   */
  useEffect(() => {
    return () => {
      if (clearFunction.current) {
        clearFunction.current();
      }
    };
  }, []);

  return ref;
};

export default useMobileNotificationPortal;
