import React, { useCallback, useContext, useMemo } from 'react';

import Modal from '@gi/modal';
import { ResourceContext } from '@gi/resource-provider';
import { getDistanceUnitsFromIsMetric } from '@gi/units';
import Plan, { PlanGardenObject, PlanUtils } from '@gi/plan';

import EditGardenObjectModalContent from './edit-garden-object-modal-content';

interface iProps {
  plan: Plan;
  gardenObjectItemID: number;
  editItemModalComplete: (updatedPlan: Plan) => void;
  closeModal: () => void;
}

const EditGardenObjectModal = ({ plan, gardenObjectItemID, editItemModalComplete, closeModal }: iProps): JSX.Element | null => {
  const { userGardenObjects } = useContext(ResourceContext);

  /**
   * Find the garden object in the plan. This *can* return null, but it really shouldn't if our props are correct.
   */
  const planGardenObject = useMemo<PlanGardenObject | null>(() => {
    return plan.gardenObjects[gardenObjectItemID] ?? null;
  }, [plan, gardenObjectItemID]);

  /**
   * Find the corresponding garden object, used to validate properties.
   */
  const gardenObject = useMemo(() => {
    return userGardenObjects && planGardenObject ? userGardenObjects.get(planGardenObject.code) : null;
  }, [userGardenObjects, planGardenObject]);

  /**
   * Get the user's distance units
   */
  const distanceUnits = useMemo(() => {
    return getDistanceUnitsFromIsMetric(plan.plannerSettings.metric);
  }, [plan]);

  /**
   * Update the plan on complete and send the updated plan back out.
   */
  const onComplete = useCallback(
    (updatedPlanGardenObject: PlanGardenObject) => {
      editItemModalComplete(PlanUtils.updatePlanGardenObject(plan, updatedPlanGardenObject));
    },
    [plan]
  );

  /**
   * Error if we don't have a valid PlanGardenObject (or GardenObject)
   */
  if (!gardenObject || !planGardenObject) {
    if (!planGardenObject) {
      console.error(`Tried to open EditGardenObjectModal without a valid PlanGardenObject. ID ${gardenObjectItemID}, PlanID: ${plan.id}`);
    } else {
      console.error(`Tried to open EditGardenObjectModal without a valid GardenObject. Garden Object Code ${planGardenObject.code}`);
    }
    return null;
  }

  return (
    <Modal closeRequest={closeModal} style={{ minWidth: 'min(340px, 100%)' }}>
      <EditGardenObjectModalContent distanceUnits={distanceUnits} gardenObject={gardenObject} planGardenObject={planGardenObject} onComplete={onComplete} />
    </Modal>
  );
};

export default EditGardenObjectModal;
