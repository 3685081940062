import React from 'react';

interface iProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  indent?: boolean;
}

const ModalPaneFooter = ({ children, style = {}, indent = false }: iProps) => {
  return (
    <div style={style} className={`modal-pane-content ${indent ? 'modal-pane-content-ident' : ''}`}>
      {children}
    </div>
  );
};

export default ModalPaneFooter;
