import React, { useContext } from 'react';
import PlantIcon from '@gi/plant-icon';
import { ResourceContext } from '@gi/resource-provider';

import PlantVarietiesSelector from './plant-varieties-selector';
import { usePlantShoppingEntry, useProduct, useVariant } from '../../hooks';

import MatchDetailsContainer from '../components/match-details/match-details-container';
import MatchDetailsIcon from '../components/match-details/match-details-icon';
import MatchDetailsTitles from '../components/match-details/match-details-titles';
import MatchDetailsTitle from '../components/match-details/match-details-title';
import MatchDetailsSubtitle from '../components/match-details/match-details-subtitle';
import MatchDetailsExtra from '../components/match-details/match-details-extra';

import styles from './plant-entry.module.css';

interface iProps {
  planId: number;
  uuid: string;
}

const PlantTitleRow = ({ planId, uuid }: iProps): JSX.Element | null => {
  const { getPlant } = useContext(ResourceContext);
  const [entry] = usePlantShoppingEntry(planId, uuid);
  const product = entry ? useProduct(entry.productId) : null;
  const variant = entry ? useVariant(entry.variantId) : null;

  const plant = entry ? getPlant(entry.matchData.plantCode) : null;

  const icon = entry ? <PlantIcon plantCode={entry.matchData.plantCode} className={styles.displayIcon} /> : null;

  if (entry === null || product === null || plant === null || variant === null) {
    return null;
  }

  return (
    <MatchDetailsContainer>
      <MatchDetailsIcon>{icon}</MatchDetailsIcon>
      <MatchDetailsTitles>
        <MatchDetailsTitle>{plant?.name}</MatchDetailsTitle>
        <MatchDetailsSubtitle>
          <PlantVarietiesSelector planId={planId} uuid={uuid} />
        </MatchDetailsSubtitle>
      </MatchDetailsTitles>
      <MatchDetailsExtra>
        <div>Quantity: {entry.inputQuantity}</div>
      </MatchDetailsExtra>
    </MatchDetailsContainer>
  );
};

export default PlantTitleRow;
