import React, { useCallback, useMemo } from 'react';
import useFavouritePlant from '@gi/use-favourite-plant';

import Plant from '@gi/plant';

import './styles.scss';
import './favourite-button.scss';

interface iProps {
  plant: Plant;
}

const FavouriteButton = ({ plant }: iProps): JSX.Element => {
  const [isFavourite, setFavourite, saving, userSavingOrLoading] = useFavouritePlant(plant.code);

  const toggleFavourite = useCallback(() => {
    setFavourite(!isFavourite);
  }, [isFavourite, setFavourite]);

  const icon = useMemo(() => {
    if (saving) {
      return <i className='icon-spinner animate-pulse' />;
    }

    const char = isFavourite ? '\uE800' : '\uE801'; // E800 = icon-start, E801 = icon-star-empty (Fontello characters)
    return (
      <svg viewBox='0 0 50 50' className={isFavourite ? 'favourite' : undefined}>
        <text x='50%' y='50%' dominantBaseline='central' textAnchor='middle'>
          {char}
        </text>
      </svg>
    );
  }, [isFavourite, saving]);

  return (
    <button className='plant-list-card-button favourite-button' type='button' onClick={toggleFavourite} disabled={saving || userSavingOrLoading}>
      {icon}
    </button>
  );
};

export default FavouriteButton;
