import React, { useContext, useMemo } from 'react';

import { ButtonStrip, ButtonStripGroup, IconButton } from '@gi/mobile-components';
import { PlannerCommandContext } from '@gi/app-planner-desktop';

import CurrentlyDrawing from '../../drawing-tools/currently-drawing';

import './editor-toolbar.scss';

const EditorToolbar = (): JSX.Element => {
  const {
    undo,
    redo,
    cut,
    copy,
    paste,
    deleteNodes,
    edit,
    viewCompanions,
    startSelect,
    selectionInProgress,
    cancelSelect,
    zoomIn,
    zoomOut,
    lockNodes,
    unlockNodes,
    bringToFront,
    sendToBack,
  } = useContext(PlannerCommandContext);

  // Show lock icon by default. Only show unlock if unlock is available and lock isn't.
  const showLock = useMemo(() => {
    return lockNodes !== null || unlockNodes === null;
  }, [lockNodes, unlockNodes]);

  return (
    <div className='mobile-editor-toolbar'>
      <CurrentlyDrawing />
      <ButtonStrip hideScrollbar showDividers gap={6} endPadding={6} shouldCenter>
        <ButtonStripGroup gap={0}>
          <IconButton icon='icon-ccw' onClick={() => undo?.()} disabled={undo === null}>
            Undo
          </IconButton>
          <IconButton icon='icon-cw' onClick={() => redo?.()} disabled={redo === null}>
            Redo
          </IconButton>
        </ButtonStripGroup>
        <ButtonStripGroup gap={0}>
          <IconButton icon='icon-scissors' onClick={() => cut?.()} disabled={cut === null}>
            Cut
          </IconButton>
          <IconButton icon='icon-docs' onClick={() => copy?.()} disabled={copy === null}>
            Copy
          </IconButton>
          <IconButton icon='icon-paste' onClick={() => paste?.()} disabled={paste === null}>
            Paste
          </IconButton>
        </ButtonStripGroup>
        <ButtonStripGroup gap={0}>
          <IconButton icon='icon-trash' onClick={() => deleteNodes?.()} disabled={deleteNodes === null}>
            Delete
          </IconButton>
          <IconButton icon='icon-edit' onClick={() => edit?.()} disabled={edit === null}>
            Edit
          </IconButton>
          <IconButton icon='icon-heart' onClick={() => viewCompanions?.()} disabled={viewCompanions === null}>
            Companions
          </IconButton>
        </ButtonStripGroup>
        <ButtonStripGroup gap={0}>
          <IconButton
            icon={showLock ? 'icon-lock' : 'icon-lock-open'}
            onClick={() => {
              if (lockNodes) {
                lockNodes();
              } else if (unlockNodes) {
                unlockNodes();
              }
            }}
            disabled={lockNodes === null && unlockNodes === null}
          >
            {showLock ? 'Lock' : 'Unlock'}
          </IconButton>
          <IconButton icon='icon-order-back' onClick={() => sendToBack?.()} disabled={sendToBack === null}>
            Send to <br />
            Back
          </IconButton>
          <IconButton icon='icon-order-front' onClick={() => bringToFront?.()} disabled={bringToFront === null}>
            Bring to <br />
            Front
          </IconButton>
        </ButtonStripGroup>
        <ButtonStripGroup gap={0}>
          <IconButton
            icon='icon-selection-box'
            onClick={() => {
              if (startSelect) {
                startSelect();
              } else if (cancelSelect) {
                cancelSelect();
              }
            }}
            active={selectionInProgress}
            disabled={!startSelect && !cancelSelect}
          >
            Selection
          </IconButton>
        </ButtonStripGroup>
        <ButtonStripGroup gap={0}>
          <IconButton icon='icon-zoom-out' onClick={() => zoomOut?.()}>
            Zoom Out
          </IconButton>
          <IconButton icon='icon-zoom-in' onClick={() => zoomIn?.()}>
            Zoom In
          </IconButton>
        </ButtonStripGroup>
      </ButtonStrip>
    </div>
  );
};

export default EditorToolbar;
