import { useCallback, useRef, useState } from 'react';

/**
 * Similar to `useState`, but the value will reset to initialValue after timeout each time it's updated.
 * @param initialValue The initialValue to fall back to each time.
 * @param timeout The time after which the value goes back to initialValue.
 */
const useResettingState = <T>(initialValue: T, timeout = 1000): [T, (newValue: T) => void] => {
  const [value, setValue] = useState(initialValue);
  const timeoutId = useRef<number | null>(null);

  const updateValue = useCallback((newValue) => {
    setValue(newValue);
    if (timeoutId.current !== null) {
      window.clearTimeout(timeoutId.current);
    }
    timeoutId.current = window.setTimeout(() => {
      setValue(initialValue);
    }, timeout);
  }, []);

  return [value, updateValue];
};

export default useResettingState;
