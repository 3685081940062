import React from 'react';

import './modal-header.scss';

interface iProps {
  children: React.ReactNode;
}

const ModalHeaderIcon = ({ children }: iProps): JSX.Element => {
  return <div className='modal-header-icon'>{children}</div>;
};

export default ModalHeaderIcon;
