import React, { ReactNode } from 'react';

import GuruSection from '../guru-section/guru-section';
import GuruSectionTitle from '../guru-section/guru-section-title';

interface iProps {
  title: ReactNode | null;
  children: React.ReactNode;
  className?: string;
}

const EmptyPreviewRow = ({ title, children, className }: iProps): JSX.Element | null => {
  return (
    <GuruSection className={className}>
      {title !== null ? <GuruSectionTitle>{title}</GuruSectionTitle> : null}
      <div>{children}</div>
    </GuruSection>
  );
};

export default EmptyPreviewRow;
