import React, { MutableRefObject, ReactNode, createContext, useContext, useMemo, useRef, useState } from 'react';
import {
  Placement,
  autoUpdate,
  useFloating,
  offset,
  flip,
  shift,
  useDelayGroup,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  UseFloatingReturn,
  UseInteractionsReturn,
  arrow,
} from '@floating-ui/react';

import { mergeDelays } from '../types';
import { TooltipContext } from '../tooltip-context';
import { useLongTouch } from './use-long-touch';

type LocalTooltipContextType = {
  open: boolean;
  setOpen: (open: boolean) => void;
  getTriggerProps: UseInteractionsReturn['getReferenceProps'];
  getContentProps: UseInteractionsReturn['getFloatingProps'];
  contentStyles: UseFloatingReturn['floatingStyles'];
  refs: UseFloatingReturn['refs'] & {
    arrow: MutableRefObject<SVGSVGElement | null>;
    setArrow: (arrow: SVGSVGElement | null) => void;
  };
  context: UseFloatingReturn['context'];
};

export const LocalTooltipContext = createContext<LocalTooltipContextType>({} as LocalTooltipContextType);

interface iProps {
  children?: ReactNode;
  placement?: Placement;
  initiallyOpen?: boolean;
  disabled?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  delayOpen?: number;
  delayClose?: number;
}

export const Tooltip = ({
  children,
  placement = 'bottom',
  initiallyOpen = false,
  disabled = false,
  open: externalIsOpen,
  onOpenChange: externalSetIsOpen,
  delayOpen,
  delayClose,
}: iProps): JSX.Element => {
  const { showTooltips } = useContext(TooltipContext);

  const [internalIsOpen, setInternalIsOpen] = useState(initiallyOpen);
  const [arrowElem, setArrowElem] = useState<SVGSVGElement | null>(null);
  const arrowRef = useRef<SVGSVGElement | null>(null);

  const open = disabled || !showTooltips ? false : (externalIsOpen ?? internalIsOpen);
  const setOpen = externalSetIsOpen ?? setInternalIsOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(12), flip(), shift(), arrow({ padding: 3, element: arrowElem })],
  });

  const { context } = data;

  const { delay } = useDelayGroup(context);

  const finalDelay = useMemo(() => mergeDelays({ open: delayOpen, close: delayClose }, delay), [delayOpen, delayClose, delay]);

  /** Make the tooltip accessible by hover */
  const hover = useHover(context, {
    move: false,
    enabled: externalIsOpen === undefined,
    mouseOnly: true,
    delay: finalDelay,
  });

  /** Make the tooltip accessible by focus via tab */
  const focus = useFocus(context, {
    enabled: externalIsOpen === undefined,
  });

  /** Make the tooltip accessible by long-pressing the element */
  const longTouch = useLongTouch(context, {
    enabled: externalIsOpen === undefined,
  });

  /** Make the tooltip disappear when the user presses ESC */
  const dismiss = useDismiss(context);

  /** ARIA properties */
  const role = useRole(context, { role: 'tooltip' });

  /** Marge all the interaction methods into 1 */
  const interactions = useInteractions([hover, focus, longTouch, dismiss, role]);

  /** Add support for defining the arrow ref externally */
  const refs = useMemo<LocalTooltipContextType['refs']>(
    () => ({
      ...data.refs,
      arrow: arrowRef,
      setArrow: (_arrow) => {
        setArrowElem(_arrow);
        arrowRef.current = _arrow;
      },
    }),
    [data.refs, arrowRef]
  );

  const value = useMemo<LocalTooltipContextType>(
    () => ({
      open,
      setOpen,
      getTriggerProps: interactions.getReferenceProps,
      getContentProps: interactions.getFloatingProps,
      contentStyles: data.floatingStyles,
      context: data.context,
      refs,
    }),
    [open, setOpen, interactions, data]
  );

  return <LocalTooltipContext.Provider value={value}>{children}</LocalTooltipContext.Provider>;
};
