import React from 'react';
import { Pest } from '@gi/pest';

import ItemContentRow from '../../components/item-content-table/item-content-row';
import ItemContentCell from '../../components/item-content-table/item-content-cell';
import ItemContentCellHeader from '../../components/item-content-table/item-content-cell-header';

interface iProps {
  pest: Pest;
  property: string;
  title: string;
}

const PestInformationSection = ({ pest, property, title }: iProps): JSX.Element | null => {
  if (pest.text[property] === '' || pest.text[property] === '-' || pest.text[property] === null) {
    return null;
  }

  return (
    <ItemContentRow>
      <ItemContentCellHeader>{title}</ItemContentCellHeader>
      <ItemContentCell>
        <p>{pest.text[property]}</p>
      </ItemContentCell>
    </ItemContentRow>
  );
};

export default PestInformationSection;
