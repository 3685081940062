import React, { ReactNode } from 'react';
import './page-heading.scss';

interface iProps {
  children?: ReactNode;
  className?: string;
  matchNavbarHeight?: boolean;
  noBackground?: boolean;
}

const PageHeading = ({ children, className, matchNavbarHeight = false, noBackground = false }: iProps): JSX.Element => {
  const classNames = ['page-heading'];
  if (matchNavbarHeight) {
    classNames.push('match-navbar-height');
  }
  if (noBackground) {
    classNames.push('no-background');
  }
  if (className) {
    classNames.push(className);
  }
  return (
    <header className={classNames.join(' ')}>
      <div className='page-heading-content'>{children}</div>
    </header>
  );
};

export default PageHeading;
