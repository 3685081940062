import React from 'react';

import styles from './match-details.module.css';

interface iProps {
  children: React.ReactNode;
}

const MatchDetailsExtra = ({ children }: iProps): JSX.Element => {
  return <div className={styles.matchDetailsExtra}>{children}</div>;
};

export default MatchDetailsExtra;
