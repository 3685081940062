import MonthType from './month-type';
import { MonthTypesCalendar } from './planting-calendar-types';

const firstHalfMonthStrings: { [key: number]: string } = {};
firstHalfMonthStrings[1] = 'January';
firstHalfMonthStrings[2] = 'Mid January';
firstHalfMonthStrings[3] = 'February';
firstHalfMonthStrings[4] = 'Mid February';
firstHalfMonthStrings[5] = 'March';
firstHalfMonthStrings[6] = 'Mid March';
firstHalfMonthStrings[7] = 'April';
firstHalfMonthStrings[8] = 'Mid April';
firstHalfMonthStrings[9] = 'May';
firstHalfMonthStrings[10] = 'Mid May';
firstHalfMonthStrings[11] = 'June';
firstHalfMonthStrings[12] = 'Mid June';
firstHalfMonthStrings[13] = 'July';
firstHalfMonthStrings[14] = 'Mid July';
firstHalfMonthStrings[15] = 'August';
firstHalfMonthStrings[16] = 'Mid August';
firstHalfMonthStrings[17] = 'September';
firstHalfMonthStrings[18] = 'Mid September';
firstHalfMonthStrings[19] = 'October';
firstHalfMonthStrings[20] = 'Mid October';
firstHalfMonthStrings[21] = 'November';
firstHalfMonthStrings[22] = 'Mid November';
firstHalfMonthStrings[23] = 'December';
firstHalfMonthStrings[24] = 'Mid December';

const lastHalfMonthStrings: { [key: number]: string } = {};
lastHalfMonthStrings[1] = 'end of December';
lastHalfMonthStrings[2] = 'mid January';
lastHalfMonthStrings[3] = 'end of January';
lastHalfMonthStrings[4] = 'mid February';
lastHalfMonthStrings[5] = 'end of February';
lastHalfMonthStrings[6] = 'mid March';
lastHalfMonthStrings[7] = 'end of March';
lastHalfMonthStrings[8] = 'mid April';
lastHalfMonthStrings[9] = 'end of April';
lastHalfMonthStrings[10] = 'mid May';
lastHalfMonthStrings[11] = 'end of May';
lastHalfMonthStrings[12] = 'mid June';
lastHalfMonthStrings[13] = 'end of June';
lastHalfMonthStrings[14] = 'mid July';
lastHalfMonthStrings[15] = 'end of July';
lastHalfMonthStrings[16] = 'mid August';
lastHalfMonthStrings[17] = 'end of August';
lastHalfMonthStrings[18] = 'mid September';
lastHalfMonthStrings[19] = 'end of September';
lastHalfMonthStrings[20] = 'mid October';
lastHalfMonthStrings[21] = 'end of October';
lastHalfMonthStrings[22] = 'mid November';
lastHalfMonthStrings[23] = 'end of November';
lastHalfMonthStrings[24] = 'mid December';

export type CalendarRange = Readonly<{
  startHalfMonth: number;
  length: number;
  endHalfMonth: number;
}>;

export type CalendarRangeStrings = Readonly<{
  allYearRound: boolean;
  start: string;
  end: string;
}>;

export function getCalendarRangeEnd(startHalfMonth: number, length: number): number {
  return ((startHalfMonth + length - 1) % 24) + 1;
}

export function getCalendarRangeStrings(dateRange: CalendarRange, offset: number): CalendarRangeStrings {
  const startHalfMonthIndex = ((dateRange.startHalfMonth - 1 + offset) % 24) + 1;
  const endHalfMonthIndex = ((dateRange.endHalfMonth - 1 + offset) % 24) + 1;

  return {
    allYearRound: dateRange.length >= 24,
    start: firstHalfMonthStrings[startHalfMonthIndex],
    end: lastHalfMonthStrings[endHalfMonthIndex],
  };
}

export function halfMonthInRange(dateRange: CalendarRange, halfMonth: number): boolean {
  if (dateRange.length === 0) {
    return false;
  }

  if (dateRange.startHalfMonth < dateRange.endHalfMonth) {
    return halfMonth >= dateRange.startHalfMonth && halfMonth < dateRange.endHalfMonth;
  }

  return halfMonth < dateRange.endHalfMonth || halfMonth >= dateRange.startHalfMonth;
}

export function calendarRangeToString(dateRange: CalendarRange, offset: number = 0): string {
  const strings = getCalendarRangeStrings(dateRange, offset);
  return `${strings.start} -> ${strings.end}`; // TODO: Try unicode arrows or fontello fonts for arrows
}

export function createCalendarRange(start, length): CalendarRange {
  return { startHalfMonth: start, length, endHalfMonth: getCalendarRangeEnd(start, length) };
}

export function createCalendarRangeFromMonths(months: MonthTypesCalendar): CalendarRange[] {
  if (months.length !== 12) {
    throw new Error('Month array should contain 12 months');
  }

  let currentLength = 0;
  let total = 0;

  const dates: { start: number; length: number }[] = [];

  for (let i = 0; i < months.length; i++) {
    const val = months[i];
    total += val;

    if (val === MonthType.EMPTY_MONTH) {
      if (currentLength > 0) {
        dates.push({ start: i * 2 + 1 - currentLength, length: currentLength });
      }
      currentLength = 0;
    } else if (val === MonthType.START_MONTH) {
      currentLength += 1;
      dates.push({ start: i * 2 + 2 - currentLength, length: currentLength });
      currentLength = 0;
    } else if (val === MonthType.END_MONTH) {
      if (currentLength > 0) {
        dates.push({ start: i * 2 + 1 - currentLength, length: currentLength });
      }
      currentLength = 1;
    } else if (val === MonthType.FULL_MONTH) {
      currentLength += 2;
    } else {
      throw new Error('Invalid month value');
    }
  }

  if (currentLength > 0) {
    if (dates.length > 0 && dates[0].start === 1) {
      dates[0] = { start: 25 - currentLength, length: currentLength + dates[0].length };
    } else {
      dates.push({ start: 25 - currentLength, length: currentLength });
    }
  }

  if (total === 0) {
    return [];
  }

  return dates.map((d) => ({
    startHalfMonth: d.start,
    length: d.length,
    endHalfMonth: getCalendarRangeEnd(d.start, d.length),
  }));
}

export function createEmptyCalendarRange(): CalendarRange {
  return {
    startHalfMonth: 0,
    length: 0,
    endHalfMonth: 0,
  };
}
