import React, { createContext, ReactNode, useCallback, useMemo, useState } from 'react';

import { DEFAULT_PARSED_TOOLTIPS } from './parse-tooltips';
import { ParsedTooltips, TooltipContentDefinition, TooltipKey } from './types';

export type TooltipContextType = {
  showTooltips: boolean;
  setShowTooltips: (showTooltips: boolean) => void;
  getTooltipContent: (id: TooltipKey) => TooltipContentDefinition | null;
  setTooltipContent: (tooltipContent: ParsedTooltips) => void;
};

export const TooltipContext = createContext<TooltipContextType>({} as TooltipContextType);

interface iProps {
  children: ReactNode;
}

/**
 * Tooltip Provider
 *  Responsible for giving the tooltips a place to live at the edge of the app,
 *  so that they can escape z-index issues.
 */
export const TooltipProvider = ({ children }: iProps): JSX.Element => {
  const [tooltipContent, setTooltipContent] = useState<ParsedTooltips>(DEFAULT_PARSED_TOOLTIPS);
  const [showTooltips, setShowTooltips] = useState<boolean>(true);

  const getTooltipContent = useCallback(
    (id: TooltipKey): TooltipContentDefinition | null => {
      if (tooltipContent && tooltipContent[id] !== undefined) {
        return tooltipContent[id];
      }
      return null;
    },
    [tooltipContent]
  );

  const value = useMemo(
    () => ({
      showTooltips,
      setShowTooltips,
      getTooltipContent,
      setTooltipContent,
    }),
    [showTooltips, getTooltipContent]
  );

  return <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>;
};
