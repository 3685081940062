import React, { useRef } from 'react';
import { distanceConversion } from '@gi/conversion';

interface iProps {
  bearing: number;
  windspeed: number | null;
  metric: boolean;
  color?: string;
}

const WIDTH = 50;
const HEIGHT = 50;
const ORIGIN_X = 25;
const ORIGIN_Y = 25;
const RADIUS = 12;
const LINE_LENGTH = 4;

let idTicker = 0;
function getUid(): number {
  return idTicker++;
}

const WindIcon = ({ bearing, windspeed, metric, color = '#333333' }: iProps): JSX.Element => {
  // random uuid might not be supported well enough yet
  // const uuid = useRef(crypto.randomUUID());
  const uuid = useRef(getUid());

  const convertedBearing = (bearing + 90) * (Math.PI / 180);

  const lineCoords = {
    x1: ORIGIN_X + RADIUS * Math.cos(convertedBearing),
    y1: ORIGIN_Y + RADIUS * Math.sin(convertedBearing),
    x2: ORIGIN_X + (RADIUS + LINE_LENGTH) * Math.cos(convertedBearing),
    y2: ORIGIN_Y + (RADIUS + LINE_LENGTH) * Math.sin(convertedBearing),
  };

  const convertedWindSpeed =
    windspeed === null ? 0 : Math.round(metric ? distanceConversion.metresPerSecondToKPH(windspeed) : distanceConversion.metresPerSecondToMPH(windspeed));

  const { x1, y1, x2, y2 } = lineCoords;
  const markerID = `windArrowMarker${uuid.current}`;

  return (
    <div title='Wind speed and direction' style={{ width: WIDTH, height: HEIGHT }}>
      <svg width={WIDTH} height={HEIGHT}>
        <defs>
          {windspeed === null ? null : (
            <marker id={markerID} orient='auto' markerWidth='10' markerHeight='10' refX='0.1' refY='2'>
              <path d='M0,0 L0,4 L4,2 z' fill={color} />
            </marker>
          )}
        </defs>
        <circle cx={ORIGIN_X} cy={ORIGIN_Y} r={RADIUS} stroke={color} strokeWidth='2' fill='none' />
        <text x='50%' y='53%' fontSize='12px' fill={color} textAnchor='middle' dominantBaseline='middle'>
          {convertedWindSpeed}
        </text>
        {convertedWindSpeed > 0 ? <line markerEnd={`url(#${markerID})`} x1={x1} y1={y1} x2={x2} y2={y2} strokeWidth='2' stroke={color} /> : ''}
      </svg>
    </div>
  );
};

export default WindIcon;
