import React from 'react';
import networkConfig from '@gi/config/network-config';

import { PlantingCalendar, PlantingCalendarDisplay, PlantingCalendarKey } from '@gi/planting-calendar';
import { ModalPaneSection, ModalPaneSectionContent, ModalPaneSectionHeader } from '@gi/modal';

interface iPlantingCalendarProps {
  plantingCalendar: PlantingCalendar | null;
  isNorthernHemisphere: boolean;
  artifactCode: string;
}

const PlantingCalendarSection = ({ plantingCalendar, isNorthernHemisphere, artifactCode }: iPlantingCalendarProps): JSX.Element => {
  return (
    <ModalPaneSection className='plant-information-section'>
      <ModalPaneSectionHeader>
        Planting Times <PlantingCalendarKey />
      </ModalPaneSectionHeader>
      <ModalPaneSectionContent>
        <PlantingCalendarDisplay
          plantingCalendar={plantingCalendar}
          startMonth={isNorthernHemisphere ? 1 : 7}
          calendarBarURL={networkConfig.plantingCalendarImageURL}
          artifactCode={artifactCode}
        />
      </ModalPaneSectionContent>
    </ModalPaneSection>
  );
};

export default PlantingCalendarSection;
