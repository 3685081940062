import React, { useContext } from 'react';

import { DEFAULT_VARIETY } from '@gi/constants';
import Plant from '@gi/plant';
import { SearchResults, SearchDisplayMode, SearchablePlantVariety } from '@gi/search-service';

import GlobalPlantListContext from '../../global-provider/global-plant-list-context';

interface iProps {
  plant: Plant;
  varietyName?: string;
  showVariety?: boolean;
  showTags?: boolean;
  tags?: string[];
  plantSearchResults?: SearchResults<Plant>;
  varietySearchResults?: SearchResults<SearchablePlantVariety>;
}

const PlantNameCell = ({
  plant,
  varietyName = '',
  showVariety = true,
  tags = [],
  showTags = false,
  plantSearchResults = new SearchResults([]),
  varietySearchResults = new SearchResults([]),
}: iProps): JSX.Element | null => {
  const { options } = useContext(GlobalPlantListContext);

  const varietyNameDisplayString = varietyName === DEFAULT_VARIETY ? '- (default variety)' : varietyName;

  const matchedPlant = plantSearchResults.getResult(plant.code);
  const matchedVariety = varietySearchResults.getResult(`${plant.code}-${varietyName}`);

  /**
   * If the search matched on plant name directly (primary), display the match in place of the plant name.
   */
  const plantName =
    matchedPlant && matchedPlant.matchedKey === 'name' && matchedPlant.displayMode === SearchDisplayMode.Primary ? matchedPlant.getHtml() : plant.name;

  /**
   * If the search matched on otherName (or a secondary name), display the match to the user as a seperate field.
   */
  const otherName =
    matchedPlant &&
    ['name', 'otherName', 'searchNames'].includes(String(matchedPlant.matchedKey)) &&
    matchedPlant.displayMode === SearchDisplayMode.Secondary ? (
      <div className='plant-other-name-string'>{matchedPlant.getHtml()}</div>
    ) : null;

  /**
   * If the search matched on scientific name, forcibly show it. Otherwise, leave it to user preference.
   */
  const scientificName =
    matchedPlant && matchedPlant.matchedKey === 'scientificName' && matchedPlant.displayMode !== SearchDisplayMode.Hidden ? (
      <div className='plant-scientific-name-string'>{matchedPlant.getHtml()}</div>
    ) : options.showScientificName ? (
      <div className='plant-scientific-name-string'>{plant.scientificName}</div>
    ) : null;

  /**
   * If we should show varieties, check if we matched it in the search and highlight it, otherwise display normally.
   */
  const variety = !showVariety ? null : matchedVariety && matchedVariety.matchedKey === 'name' && matchedVariety.displayMode === SearchDisplayMode.Primary ? (
    <div className='plant-variety-string'>{matchedVariety.getHtml()}</div>
  ) : (
    <div className='plant-variety-string'>{varietyNameDisplayString}</div>
  );

  return (
    <td className='plant-name-td top-cell'>
      <div className='plant-name-string'>{plantName}</div>
      {otherName}
      {scientificName}
      {variety}
      {showTags ? (
        <div className='plant-tags'>
          {tags.map((tag) => (
            <span key={tag} className='plant-tag'>
              {tag}
            </span>
          ))}
        </div>
      ) : null}
    </td>
  );
};

export default PlantNameCell;
