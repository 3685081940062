import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CanvasActionCreators, CanvasSelectors } from '@gi/react-garden-canvas';
import PlanDataErrorModal from './plan-data-error-modal';

const PlanDataErrorModalRenderer = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const planDataErrors = useSelector(CanvasSelectors.getPlanDataErrors);

  const onClose = useCallback(() => {
    dispatch(CanvasActionCreators.clearPlanDataErrors());
  }, []);

  if (!planDataErrors || planDataErrors.length === 0) {
    return null;
  }

  return <PlanDataErrorModal onClose={onClose} planDataErrors={planDataErrors} />;
};

export default PlanDataErrorModalRenderer;
