import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Plan from '@gi/plan';
import { User } from '@gi/user';
import DeletePlanModal from '@gi/delete-plan-modal';
import { RequestSelectors } from '@gi/react-requests';
import { RequestStatus, RequestsUtils } from '@gi/request';

import { deletePlan } from '../planner-settings-action-creators';

interface iProps {
  modalOpen: boolean;
  closeModal: () => void;
  plan: Plan;
  user: User;
}

const DeletePlanModalRenderer = ({ modalOpen, closeModal, user, plan }: iProps): JSX.Element | null => {
  const requests = useSelector(RequestSelectors.getRequests);
  const dispatch = useDispatch();

  if (!modalOpen || !plan) {
    return null;
  }

  const planDeleting = RequestsUtils.getStatus(requests, `DELETE_PLAN_${plan.id}`) === RequestStatus.IN_PROGRESS;

  const _deletePlan = () => {
    dispatch(deletePlan(plan));
  };

  return <DeletePlanModal plan={plan} user={user} onDeletePlan={_deletePlan} deleting={planDeleting} closeModal={closeModal} />;
};

export default DeletePlanModalRenderer;
