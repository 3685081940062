import { State, StateDef } from '@gi/state';

import GraphicNode from './graphics-node';
import TransformComponent from './node-components/transform/transform-component';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from './types';
import { bindState } from './utils/state-utils';

// Allow state to be a StateDef, or a basic type which will be used as the properties of a new StateDef
type StatefulNodeStateDef<T extends StateDef<any> | Record<string, any>> = T extends StateDef<any> ? T : StateDef<T>;

/**
 * Simple extension class to remove some boilerplate from nodes with state
 *
 * TODO: Better name now it has transform component, DefaultNode?
 */

class StatefulNode<T extends StateDef<any> | Record<string, any>> extends GraphicNode {
  readonly state: State<StatefulNodeStateDef<T>>;
  readonly transform: TransformComponent;

  constructor(defaultState: StatefulNodeStateDef<T>['state']) {
    super();

    this.state = new State<StatefulNodeStateDef<T>>(defaultState);
    bindState(this.state, this);

    this.transform = this.components.add(new TransformComponent());
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default StatefulNode;
