import { createFilters } from '@gi/filters';
import { PlantListVarietyGroup } from '../../types/plant-list-types';
import sortfilter from './variety-sort-filter';
import makePerennialsFilter from '../common/show-perennials-filter';
import { makePlantVarietySearchFilter } from '../plant-variety-search-filter';

export type PlantListVarietyGroupFiltersType = {
  perennial: ReturnType<typeof makePerennialsFilter<PlantListVarietyGroup>>;
  search: ReturnType<typeof makePlantVarietySearchFilter<PlantListVarietyGroup>>;
  sort: typeof sortfilter;
};

const INITIAL_VARIETY_FILTERS = createFilters<PlantListVarietyGroup, PlantListVarietyGroupFiltersType>({
  perennial: makePerennialsFilter<PlantListVarietyGroup>(),
  search: makePlantVarietySearchFilter<PlantListVarietyGroup>(
    (obj) => obj.plant.code,
    (obj) => obj.variety
  ),
  sort: sortfilter,
});

export default INITIAL_VARIETY_FILTERS;
