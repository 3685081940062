import React from 'react';
import ReactSelect from 'react-select';

import { GardenTypes, LayoutTypes, SunTypes, SoilTypes } from '@gi/constants';

import FormField, { FORM_FIELD_PRESETS, FormFieldOptionsPreset, FormSection, FormValues, InputContainer } from '@gi/form-responsive';

import { ModalPane, ModalPaneSection } from '@gi/modal';

import { PublishPlanProperties } from '../publish-action-creators';

const SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '34px',
    minWidth: 'unset',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '6px',
  }),
  input: (provided) => ({
    ...provided,
    paddingBottom: '0',
    paddingTop: '0',
    margin: '0',
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0 8px',
  }),
};

const DROPDOWN_PRESET: FormFieldOptionsPreset = {
  desktop: {
    labelSize: 120,
    inputSize: 'full',
  },
};

interface DropdownOption {
  label: string;
  value: number;
}

const gardenTypeStrings = {
  [GardenTypes.DEFAULT]: '-',
  [GardenTypes.BACK_GARDEN]: 'Backyard / back garden',
  [GardenTypes.FRONT_GARDEN]: 'Frontyard / front garden',
  [GardenTypes.HOME_GARDEN]: 'Home garden',
  [GardenTypes.COMMUNITY_GARDEN]: 'Community garden',
  [GardenTypes.ALLOTMENT]: 'Allotment',
  [GardenTypes.CSA]: 'CSA (Community Supported Agriculture)',
  [GardenTypes.WORK_GARDEN]: 'Work garden',
  [GardenTypes.FARM]: 'Farm / smallholding',
  [GardenTypes.OTHER]: 'Other',
  [GardenTypes.SCHOOL]: 'School / College Garden',
};

const gardenTypeOptions: DropdownOption[] = [];
for (let i = 0; i < 11; i++) {
  gardenTypeOptions.push({ value: i, label: gardenTypeStrings[i] });
}

const layoutStrings = {
  [LayoutTypes.DEFAULT]: '-',
  [LayoutTypes.TRADITIONAL]: 'Traditional layout - rows etc',
  [LayoutTypes.RAISED_BEDS]: 'Raised Beds',
  [LayoutTypes.SFG]: 'Square Foot Garden',
  [LayoutTypes.CONTAINER]: 'Container / Patio garden',
  [LayoutTypes.POTAGER]: 'Potager / Country garden',
  [LayoutTypes.FIELD]: 'Field',
  [LayoutTypes.OTHER]: 'Other layout',
};

const layoutOptions: DropdownOption[] = [];
for (let i = 0; i < 8; i++) {
  layoutOptions.push({ value: i, label: layoutStrings[i] });
}

const sunStrings = {
  [SunTypes.DEFAULT]: '-',
  [SunTypes.SUNNY]: 'Sunny',
  [SunTypes.PARTIAL_SHADE]: 'Partial Shade',
  [SunTypes.SHADY]: 'Shady',
};

const sunOptions: DropdownOption[] = [];
for (let i = 0; i < 4; i++) {
  sunOptions.push({ value: i, label: sunStrings[i] });
}

const soilStrings = {
  [SoilTypes.DEFAULT]: '-',
  [SoilTypes.LIGHT]: 'Light / Sandy soil',
  [SoilTypes.HEAVY]: 'Heavy / Clay soil',
  [SoilTypes.GOOD]: 'Good soil',
  [SoilTypes.POOR]: 'Poor soil',
  [SoilTypes.OTHER]: 'Other soil',
};

const soilOptions: DropdownOption[] = [];
for (let i = 0; i < 6; i++) {
  soilOptions.push({ value: i, label: soilStrings[i] });
}

type LocalPublishPlanProperties = Pick<
  PublishPlanProperties,
  'findOnMap' | 'publishMap' | 'publishPlantList' | 'publishNotes' | 'type' | 'layout' | 'sun' | 'soil'
>;

interface iProps {
  values: FormValues<LocalPublishPlanProperties>;
  setValue: <K extends keyof LocalPublishPlanProperties>(key: K, value: LocalPublishPlanProperties[K]) => void;
}

const PublishPlanOptions = ({ values, setValue }: iProps): JSX.Element => {
  const { findOnMap, publishMap, publishPlantList, publishNotes, type, layout, sun, soil } = values.values;

  return (
    <ModalPane>
      <ModalPaneSection>
        <FormSection padding={0} gap={0} margin={0} heading='Options'>
          <FormField label='Allow others to find my garden on a map' htmlFor='publish-plan:find-on-map' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
            <input
              type='checkbox'
              checked={findOnMap}
              onChange={(e) => {
                setValue('findOnMap', e.target.checked);
              }}
              id='publish-plan:find-on-map'
            />
          </FormField>
          <FormField label='Include map and location marker' htmlFor='publish-plan:publish-map' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
            <input
              type='checkbox'
              checked={publishMap}
              onChange={(e) => {
                setValue('publishMap', e.target.checked);
              }}
              id='publish-plan:publish-map'
            />
          </FormField>
          <FormField label='Include Plant List' htmlFor='publish-plan:publish-plant-list' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
            <input
              type='checkbox'
              checked={publishPlantList}
              onChange={(e) => {
                setValue('publishPlantList', e.target.checked);
              }}
              id='publish-plan:publish-plant-list'
            />
          </FormField>
          <FormField label='Include Notes' htmlFor='publish-plan:publish-notes' layoutPreset={FORM_FIELD_PRESETS.CheckboxLeft}>
            <input
              type='checkbox'
              checked={publishNotes}
              onChange={(e) => {
                setValue('publishNotes', e.target.checked);
              }}
              id='publish-plan:publish-notes'
            />
          </FormField>
        </FormSection>
      </ModalPaneSection>
      <ModalPaneSection>
        <FormSection padding={0} gap={9} margin={0} heading='Type of Garden'>
          <FormField label='Type of Garden' htmlFor='publish-plan:garden-type' layoutPreset={DROPDOWN_PRESET}>
            <InputContainer size='full'>
              <ReactSelect<DropdownOption>
                styles={SELECT_STYLES}
                options={gardenTypeOptions}
                value={gardenTypeOptions[type]}
                onChange={(option) => {
                  if (option) {
                    setValue('type', option.value);
                  }
                }}
                menuPortalTarget={document.body}
                menuPlacement='auto'
                isSearchable={false}
                inputId='publish-plan:garden-type'
              />
            </InputContainer>
          </FormField>
          <FormField label='Planting Layout' htmlFor='publish-plan:layout' layoutPreset={DROPDOWN_PRESET}>
            <InputContainer size='full'>
              <ReactSelect<DropdownOption>
                styles={SELECT_STYLES}
                options={layoutOptions}
                value={layoutOptions[layout]}
                onChange={(option) => {
                  if (option) {
                    setValue('layout', option.value);
                  }
                }}
                menuPortalTarget={document.body}
                menuPlacement='auto'
                isSearchable={false}
                inputId='publish-plan:layout'
              />
            </InputContainer>
          </FormField>
          <FormField label='Sun or Shade' htmlFor='publish-plan:sun' layoutPreset={DROPDOWN_PRESET}>
            <InputContainer size='full'>
              <ReactSelect<DropdownOption>
                styles={SELECT_STYLES}
                options={sunOptions}
                value={sunOptions[sun]}
                onChange={(option) => {
                  if (option) {
                    setValue('sun', option.value);
                  }
                }}
                menuPortalTarget={document.body}
                menuPlacement='auto'
                isSearchable={false}
                inputId='publish-plan:sun'
              />
            </InputContainer>
          </FormField>
          <FormField label='Soil Type' htmlFor='publish-plan:soil' layoutPreset={DROPDOWN_PRESET}>
            <InputContainer size='full'>
              <ReactSelect<DropdownOption>
                styles={SELECT_STYLES}
                options={soilOptions}
                value={soilOptions[soil]}
                onChange={(option) => {
                  if (option) {
                    setValue('soil', option.value);
                  }
                }}
                menuPortalTarget={document.body}
                menuPlacement='auto'
                isSearchable={false}
                inputId='publish-plan:soil'
              />
            </InputContainer>
          </FormField>
        </FormSection>
      </ModalPaneSection>
    </ModalPane>
  );
};

export default PublishPlanOptions;
