import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { useThrottle } from '@gi/react-utils';
import { GardenPlatformEventsContext } from '@gi/garden-platform-events';

import { AnalyticsActions } from './analytics-slice';
import { AnalyticsEventIdentifiers } from './analytics-event-identifiers';
import { createEventListenerCallbacks } from './analytics-event-listeners';

// Throttle time for display mode switches (in ms). Prevents spamming events endpoint if someone's resizing window.
const DISPLAY_MODE_THROTTLE_TIME = 10_000; // 10s

interface iProps {
  children?: ReactNode;
  disabled?: boolean;
}

const AnalyticsController = ({ children, disabled }: iProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const { eventsSystem } = useContext(GardenPlatformEventsContext);

  const hasInitialised = useRef<boolean>(false);
  const displayMode = useSelector(DisplayModeSelectors.getDisplayMode);
  const throttledDisplayMode = useThrottle(displayMode, DISPLAY_MODE_THROTTLE_TIME);

  /**
   * Update the store with our current `disabled` state.
   */
  useEffect(() => {
    if (disabled !== undefined) {
      dispatch(AnalyticsActions.setDisabled(disabled));
    }
  }, [disabled]);

  /**
   * Set up event listeners
   */
  useEffect(() => {
    if (disabled) {
      return () => {};
    }

    const eventListeners = createEventListenerCallbacks(dispatch);

    for (let i = 0; i < eventListeners.length; i++) {
      const { eventName, callback } = eventListeners[i];
      eventsSystem.addListener(eventName, callback);
    }

    return () => {
      for (let i = 0; i < eventListeners.length; i++) {
        const { eventName, callback } = eventListeners[i];
        eventsSystem.removeListener(eventName, callback);
      }
    };
  }, [disabled]);

  /**
   * Watch for the throttled display mode changing and send display mode change event.
   * Don't run the first time, as this isn't a display mode change.
   */
  useEffect(() => {
    if (hasInitialised.current) {
      dispatch(AnalyticsActions.analyticsEvent(AnalyticsEventIdentifiers.DISPLAY_MODE_CHANGE, {}, { displayMode: true, deviceData: true }));
    } else {
      hasInitialised.current = true;
    }
  }, [throttledDisplayMode]);

  /**
   * When the app starts, send initial information.
   */
  useEffect(() => {
    dispatch(AnalyticsActions.analyticsEvent(AnalyticsEventIdentifiers.APP_START, {}, { displayMode: true, version: true }));
    dispatch(AnalyticsActions.analyticsEvent(AnalyticsEventIdentifiers.DEVICE_DATA, {}, { displayMode: true, deviceData: true }));
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return children ? <>{children}</> : null;
};

export default AnalyticsController;
