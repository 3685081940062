import { PlantFamilyTypes } from '@gi/plant-families';

/**
 * Enum for the different crop rotation view types
 *
 * @readonly
 * @enum {CropRotationMode}
 */
const CropRotationModes = {
  OFF: 'OFF',
  AUTOMATIC: 'AUTOMATIC',
  BRASSICAS: PlantFamilyTypes.BRASSICAS,
  LEGUMES: PlantFamilyTypes.LEGUMES,
  SOLANACEAE: PlantFamilyTypes.SOLANACEAE,
  ALLIUM: PlantFamilyTypes.ALLIUM,
  UMBELLIFERAE: PlantFamilyTypes.UMBELLIFERAE,
  CUCURBITS: PlantFamilyTypes.CUCURBITS,
  CHENOPODIACEAE: PlantFamilyTypes.CHENOPODIACEAE,
} as const;

type CropRotationModes = (typeof CropRotationModes)[keyof typeof CropRotationModes];

export default CropRotationModes;
