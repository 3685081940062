import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';

import { avoidWebGL } from '@gi/browser';
import { RenderMode, AUTOMATIC_DPR, MIN_DPR, MAX_DPR } from '@gi/constants';
import FormField, { FormRadioField, FormRadioFieldset, FormSection, InputContainer, FormValuesField } from '@gi/form-responsive';

import { DEFAULT_SELECT_STYLES } from '@gi/styles/react-select-styles';

/** Set to false to disable render mode dropdown. Set to allowWebGL to restore disabling on iOS old behaviour  */
const ENABLE_RENDER_MODE_SELECT = true;

interface IProps {
  renderMode: FormValuesField<RenderMode>;
  setRenderMode: (mode: string) => void;
  customDevicePixelRatio: FormValuesField<boolean>;
  setCustomDevicePixelRatio: (customDevicePixelRatio: boolean) => void;
  devicePixelRatio: FormValuesField<number>;
  setDevicePixelRatio: (devicePixelRatio: number) => void;
}

interface RenderModeOption {
  value: RenderMode;
  label: string;
}

const renderModeOptions: RenderModeOption[] = [
  { value: RenderMode.AUTO, label: 'Automatic (Recommended)' },
  { value: RenderMode.WEBGL_2, label: 'WebGL 2' },
  { value: RenderMode.WEBGL_1, label: 'WebGL 1' },
  { value: RenderMode.WEBGL_LEGACY, label: 'WebGL Legacy' },
  { value: RenderMode.CANVAS, label: 'Canvas' },
];

const renderModeValues = {
  [RenderMode.AUTO]: renderModeOptions[0],
  [RenderMode.WEBGL_2]: renderModeOptions[1],
  [RenderMode.WEBGL_1]: renderModeOptions[2],
  [RenderMode.WEBGL_LEGACY]: renderModeOptions[3],
  [RenderMode.CANVAS]: renderModeOptions[4],
};

const AdvancedSettings = ({
  renderMode,
  setRenderMode,
  customDevicePixelRatio,
  setCustomDevicePixelRatio,
  devicePixelRatio,
  setDevicePixelRatio,
}: IProps): JSX.Element => {
  const [devicePixelRatioVal, setDevicePixelRatioVal] = useState<string>(devicePixelRatio.value.toString());

  const updateOnBlur = () => {
    let parsedVal: number;
    try {
      parsedVal = parseFloat(devicePixelRatioVal);
    } catch (e) {
      parsedVal = 1;
    }

    if (Number.isNaN(parsedVal)) {
      parsedVal = 1;
    }

    setDevicePixelRatio(parsedVal);
  };

  useEffect(() => {
    setDevicePixelRatioVal(devicePixelRatio.value.toString());
  }, [devicePixelRatio.value]);

  return (
    <FormSection heading='Advanced Settings' padding={12} gap={6} className='form-section-background'>
      <FormField
        label='Render Mode'
        helpText={
          avoidWebGL
            ? 'This device will automatically use Canvas render mode to maximise compatibility. WebGL may offer better performance, but is less stable.'
            : undefined
        }
        htmlFor='advanced-settings:render-mode'
        desktopLayout={{ labelSize: 135 }}
      >
        {ENABLE_RENDER_MODE_SELECT ? (
          <ReactSelect<RenderModeOption>
            isDisabled={!ENABLE_RENDER_MODE_SELECT}
            styles={DEFAULT_SELECT_STYLES}
            options={renderModeOptions}
            value={renderModeValues[renderMode.value]}
            onChange={(selected) => {
              if (selected !== null) {
                setRenderMode(selected.value);
              }
            }}
            isSearchable={false}
            menuPortalTarget={document.body}
          />
        ) : (
          <p className='safari-warning-text'>Different render modes are not available for your device.</p>
        )}
      </FormField>
      <FormRadioFieldset legend='Device Pixel Ratio' helpText='The garden planner page must be refreshed for this change to take effect' fakeLabel>
        <FormRadioField label='Automatic (Recommended)' htmlFor='advanced-settings:dpr-auto'>
          <input
            type='radio'
            value={AUTOMATIC_DPR}
            onChange={(e) => {
              setCustomDevicePixelRatio(!e.target.checked);
            }}
            checked={!customDevicePixelRatio.value}
            id='advanced-settings:dpr-auto'
          />
        </FormRadioField>
        <FormRadioField
          label={
            <FormField
              label={`Custom (${MIN_DPR} - ${MAX_DPR})`}
              fakeLabel
              desktopLayout={{ labelSize: 'auto', inputSize: 80 }}
              mobileLayout={{ inputSize: 80 }}
              invalid={!devicePixelRatio.valid}
              errorText={devicePixelRatio.errors}
            >
              <InputContainer>
                <input
                  min={MIN_DPR}
                  max={MAX_DPR}
                  step={0.5}
                  disabled={!customDevicePixelRatio.value}
                  type='number'
                  value={devicePixelRatioVal}
                  onChange={(e) => {
                    setDevicePixelRatioVal(e.target.value);
                  }}
                  onBlur={updateOnBlur}
                />
              </InputContainer>
            </FormField>
          }
          htmlFor='advanced-settings:dpr-custom'
          noLabelPadding
        >
          <input
            type='radio'
            value={AUTOMATIC_DPR}
            onChange={(e) => {
              setCustomDevicePixelRatio(e.target.checked);
            }}
            checked={customDevicePixelRatio.value}
            id='advanced-settings:dpr-custom'
          />
        </FormRadioField>
      </FormRadioFieldset>
    </FormSection>
  );
};

export default AdvancedSettings;
