import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import networkConfig from '@gi/config/network-config';
import { AppContext } from '@gi/app-provider';
import { UserSubscriptionUtils } from '@gi/user';

import { getUser } from '../session-selectors';

import './subscription-gateway.scss';

interface iProps {
  children: React.ReactNode;
}

const SubscriptionGateway = ({ children }: iProps): JSX.Element => {
  const user = useSelector(getUser);
  const { runtimeConfig } = useContext(AppContext);

  if (user === null) {
    return (
      <div
        className='subscription-gateway'
        style={{
          backgroundImage: `url(${networkConfig.assetBaseURL}header-images/${runtimeConfig.backgroundImage.blurSrc})`,
        }}
      >
        <div className='subscription-gateway-inner'>
          <div>
            <p>Loading Profile</p>
          </div>
        </div>
      </div>
    );
  }

  if (!user.accountEnabled) {
    return (
      <div
        className='subscription-gateway'
        style={{
          backgroundImage: `url(${networkConfig.assetBaseURL}header-images/${runtimeConfig.backgroundImage.blurSrc})`,
        }}
      >
        <div className='subscription-gateway-inner'>
          <div>
            <h1>Account Disabled</h1>
            <p>
              Your account has been disabled, please{' '}
              <a className='inline-link' href={`mailto:${runtimeConfig.supportEmail}?subject=Account Disabled ${user.ID}`} target='_blank' rel='noreferrer'>
                contact support
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (!user.subscription.emailConfirmed) {
    return (
      <div
        className='subscription-gateway'
        style={{
          backgroundImage: `url(${networkConfig.assetBaseURL}header-images/${runtimeConfig.backgroundImage.blurSrc})`,
        }}
      >
        <div className='subscription-gateway-inner'>
          <div>
            <h1>Email Confirmation Required</h1>
            <p>Please confirm your email address on the account pages before continuing.</p>
            <p>
              <a className='link-button' href='/account/'>
                Your Account
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (!UserSubscriptionUtils.hasSubscription(user.subscription) && !UserSubscriptionUtils.hasFreeTrial(user.subscription)) {
    return (
      <div
        className='subscription-gateway'
        style={{
          backgroundImage: `url(${networkConfig.assetBaseURL}header-images/${runtimeConfig.backgroundImage.blurSrc})`,
        }}
      >
        <div className='subscription-gateway-inner'>
          <div>
            <h1>Subscription Required</h1>
            <p>
              A Garden Planner subscription is required to use the Garden Planner &amp; Application Suite. You can update your subscription on the subscription
              settings page in your account.
            </p>
            <p>
              <a className='link-button' href='/account/settings/subscription'>
                Subscription Settings
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  // Return a fragment to make the types match, unfortunately a TS/React bug
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default SubscriptionGateway;
