import { Container, IDestroyOptions, ITextStyle, Text, TextMetrics, TextStyle, TextStyleFill, Transform } from 'pixi.js-new';

export type TextContainerOptions = {
  fontFamily: string | string[];
  fontSize: number;
  fontScale: number;
  style?: Partial<Omit<ITextStyle, 'fill' | 'fontFamily' | 'fontSize'>>;
  color: TextStyleFill;
  mipmapLevel: number;
  maxMipmapLevel: number;
};

class TextContainer extends Container {
  #onDestroy: ((textContainer: TextContainer) => void) | undefined;

  #text: string;
  #options: Omit<TextContainerOptions, 'mipmapLevel'>;
  #mipmapLevel: number = 0;
  #mipmapLevels: Text[] = [];
  #mipmapsDirty: boolean = true;
  #textDimensions: Dimensions | null = null;

  #sharedTransform: Transform = new Transform();

  constructor(text: string, options: TextContainerOptions, onDestroy?: (textContainer: TextContainer) => void) {
    super();

    this.#text = text;
    this.#options = options;
    this.#mipmapLevel = options.mipmapLevel;
    this.#onDestroy = onDestroy;

    this.checkMipmaps();
  }

  set text(text: string) {
    if (this.#text === text) {
      return;
    }
    this.#text = text;
    this.#textDimensions = null;
    for (let i = 0; i < this.#mipmapLevels.length; i++) {
      this.#mipmapLevels[i].text = text;
    }
  }
  get text() {
    return this.#text;
  }

  #anchor: Vector2 = { x: 0, y: 0 };
  set anchor(anchor: Vector2) {
    this.#anchor = anchor;
    for (let i = 0; i < this.#mipmapLevels.length; i++) {
      this.#mipmapLevels[i].anchor.set(anchor.x, anchor.y);
    }
  }
  get anchor() {
    return this.#anchor;
  }

  set mipmapLevel(level: number) {
    if (this.#mipmapLevel === level) {
      return;
    }

    const currentText = this.#getText();
    const newText = this.#getText(level);

    this.#mipmapLevel = level;

    if (currentText === newText) {
      return;
    }

    currentText.renderable = false;
    newText.renderable = true;
  }
  get mipmapLevel() {
    return this.#mipmapLevel;
  }

  // The width of the text using the current font settings
  get textWidth() {
    if (this.#textDimensions === null) {
      this.#textDimensions = this.#getTextDimensions();
    }
    return this.#textDimensions.width;
  }
  // The height of the text using the current font settings
  get textHeight() {
    if (this.#textDimensions === null) {
      this.#textDimensions = this.#getTextDimensions();
    }
    return this.#textDimensions.height;
  }

  /**
   * Sets the options for this text container, to define font styles and mipmap options.
   */
  setOptions(options: Omit<TextContainerOptions, 'mipmapLevel'>) {
    this.#options = options;
    this.#textDimensions = null;
    this.#mipmapsDirty = true;
  }

  /**
   * Destroys this text container. Also tells the connected TextComponent.
   */
  destroy(options?: boolean | IDestroyOptions | undefined) {
    super.destroy(options);
    this.#mipmapLevels.forEach((text) => text.destroy());
    this.#mipmapLevels = [];
    if (this.#onDestroy) {
      this.#onDestroy(this);
    }
  }

  #getText(mipmapLevel: number = this.#mipmapLevel) {
    const level = Math.min(mipmapLevel, this.#mipmapLevels.length - 1);
    return this.#mipmapLevels[level];
  }

  #getTextStyle(mipmapFontScale: number = 1) {
    const { fontFamily, fontSize, fontScale, color, style = {} } = this.#options;
    const { wordWrapWidth, lineHeight, ...remainingStyles } = style;

    return {
      ...remainingStyles,
      wordWrapWidth: wordWrapWidth !== undefined ? wordWrapWidth * fontScale * mipmapFontScale : undefined,
      lineHeight: lineHeight !== undefined ? lineHeight * fontScale * mipmapFontScale : undefined,
      fontSize: fontSize * fontScale * mipmapFontScale,
      fontFamily,
      fill: color,
    };
  }

  checkMipmaps() {
    if (this.#mipmapsDirty) {
      this.#updateMipmaps(this.#options.maxMipmapLevel);
      this.#mipmapsDirty = false;
    }
  }

  #updateMipmaps(mipmapLevels: number) {
    const currentText = this.#getText();

    for (let i = 0; i <= mipmapLevels; i++) {
      let text: Text = this.#mipmapLevels[i];

      if (!text) {
        text = new Text(this.#text);
        text.anchor.set(this.anchor.x, this.anchor.y);
        text.renderable = false;
        this.addChild(text);
        this.#mipmapLevels.push(text);
      }

      const scale = 1 / 2 ** i;
      text.style = this.#getTextStyle(scale);
      text.scale.set(1 / (this.#options.fontScale * scale));
    }

    if (this.#mipmapLevels.length > mipmapLevels + 1) {
      const deleteCount = this.#mipmapLevels.length - (mipmapLevels + 1);
      const unused = this.#mipmapLevels.splice(mipmapLevels + 1, deleteCount);
      for (let i = 0; i < unused.length; i++) {
        unused[i].destroy();
      }
    }

    const newText = this.#getText();
    if (currentText !== newText) {
      if (currentText && !currentText.destroyed) {
        currentText.renderable = false;
      }
      newText.renderable = true;
    }
  }

  #getTextDimensions(): Dimensions {
    const style = new TextStyle(this.#getTextStyle(1));
    const result = TextMetrics.measureText(this.#text, style);
    return {
      width: result.width / this.#options.fontScale,
      height: result.height / this.#options.fontScale,
    };
  }
}

export default TextContainer;
