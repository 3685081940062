import { State, StateDef } from '@gi/state';
import Node from '../../node';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import NodeComponent, { NodeComponentEventActions } from '../../node-component/node-component';
import { InteractableComponentCallbacks } from '../interactable/interactable-component';
// eslint-disable-next-line import/no-cycle
import DraggableComponent from './draggable-component';
import SelectableComponentContext from '../selectable/selectable-component-context';
import { PointerDataWithDelta } from '../../managers/interaction/interaction';
import HoverableComponentContext, { HoverFreezeFlag } from '../hoverable/hoverable-component-context';
import { bindState } from '../../utils/state-utils';
import EventBus from '../../event-bus';

/** If the user tries (unsuccessfully) to touch-drag the same item within the time, an event will be emitted. */
const TOUCH_DRAG_HELP_TIMEOUT = 2000;

export type OnDragCallback = (targets: Node[], data: PointerDataWithDelta) => void;

export enum DragEvent {
  OnDragStart = 'OnDragStart',
  OnDragMove = 'OnDragMove',
  OnDragEnd = 'OnDragEnd',
  ShowTouchDragHelp = 'ShowTouchDragHelp',
}

type DragEventActions = {
  [DragEvent.OnDragStart]: OnDragCallback;
  [DragEvent.OnDragMove]: OnDragCallback;
  [DragEvent.OnDragEnd]: OnDragCallback;
  [DragEvent.ShowTouchDragHelp]: (node: Node) => void;
};

export type DraggableComponentContextState = StateDef<{
  dragging: boolean;
}>;

type IgnoredDrag = {
  nodeUUID: string;
  ignoredAt: number;
};

/**
 * Draggable Component context
 * Handles dragging of nodes
 */
class DraggableComponentContext extends NodeComponent {
  type = 'DraggableComponentContext';

  #targets: Node[] = [];

  readonly state: State<DraggableComponentContextState>;
  readonly eventBus: EventBus<NodeComponentEventActions & DragEventActions> = new EventBus(this.eventBus);

  /** Set to false to make touch drags behave the same as mouse */
  touchDragRequiresSelection: boolean = true;

  #hoverContext: HoverableComponentContext | null = null;

  constructor() {
    super();

    this.state = new State({ dragging: false });
    bindState(this.state, this);
  }

  get #dragging() {
    return this.#targets.length > 0;
  }

  get targets() {
    return this.#targets;
  }

  #lastIgnoredDrag: IgnoredDrag | null = null;

  /**
   * Creates a set of interaction callbacks that allow the given node to be dragged.
   * @param node The target node of the drag
   * @returns A set of pointer interaction callbacks
   */
  getDragCallbacks(node: Node): Pick<InteractableComponentCallbacks, 'onDragStart' | 'onDragMove' | 'onDragEnd' | 'onDragCancel'> {
    return {
      onDragStart: this.#onDragStart(node),
      onDragMove: this.#onDragMove,
      onDragEnd: this.#onDragEnd,
      onDragCancel: this.#onDragCancel,
    };
  }

  /**
   * Internally handles when a drag starts
   * @param data The event data
   */
  #onDragStart = (node: Node): InteractableComponentCallbacks['onDragStart'] => {
    return (data, interaction, controls) => {
      if (!this.owner) {
        return;
      }

      if (data.button !== 0) {
        return;
      }

      const selectionContext = this.owner.getContext(SelectableComponentContext);
      if (data.pointerType !== 'touch' || !this.touchDragRequiresSelection) {
        if (data.shiftKey) {
          selectionContext.addToSelection(node);
        } else if (!selectionContext.isInSelection(node)) {
          selectionContext.setSelection([node]);
        }
        this.#targets = [...selectionContext.selection];
      } else if (selectionContext.isInSelection(node)) {
        this.#targets = [...selectionContext.selection];
      } else {
        // If it's a touch, and the dragged node isn't currently selected, drag the plan instead.
        if (this.#lastIgnoredDrag && this.#lastIgnoredDrag.nodeUUID === node.uuid && Date.now() - this.#lastIgnoredDrag.ignoredAt <= TOUCH_DRAG_HELP_TIMEOUT) {
          // Emit help event if the user is trying to drag the same node again.
          this.eventBus.emit(DragEvent.ShowTouchDragHelp, node);
        }

        this.#lastIgnoredDrag = {
          ignoredAt: Date.now(),
          nodeUUID: node.uuid,
        };
        return; // Ignore the drag event
      }

      controls.stopPropagation();
      this.state.values.dragging = true;

      this.#targets.forEach((target) => {
        const dragComponent = target.components.get(DraggableComponent);
        if (dragComponent) {
          dragComponent.onStart();
        }
      });

      this.#hoverContext = this.owner?.tryGetContext(HoverableComponentContext) ?? null;
      if (this.#hoverContext) {
        this.#hoverContext.hover(node);
        this.#hoverContext.addFreezeFlag(HoverFreezeFlag.DRAGGING);
      }

      // Run external onStart callback
      this.eventBus.emit(DragEvent.OnDragStart, this.#targets, data);
    };
  };

  /**
   * Internally handles when a drag continues
   * @param data The event data
   */
  #onDragMove: InteractableComponentCallbacks['onDragMove'] = (data, interaction, controls) => {
    if (!this.#dragging || !this.owner) {
      return;
    }
    controls.stopPropagation();
    this.eventBus.emit(DragEvent.OnDragMove, this.#targets, data);
  };

  /**
   * Internally handles when the drag ends
   * @param data The event data
   */
  #onDragEnd: InteractableComponentCallbacks['onDragEnd'] = (data, interaction, controls) => {
    if (!this.#dragging || !this.owner) {
      return;
    }

    controls.stopPropagation();
    this.eventBus.emit(DragEvent.OnDragEnd, this.#targets, data);

    if (this.#hoverContext) {
      this.#hoverContext.removeFreezeFlag(HoverFreezeFlag.DRAGGING);
      this.#hoverContext = null;
    }

    this.targets.forEach((node) => {
      const dragComponent = node.components.get(DraggableComponent);

      if (dragComponent) {
        dragComponent.onEnd();
      }
    });

    this.#targets = [];

    this.state.values.dragging = false;
  };

  /**
   * Internally handles when the drag is cancelled
   * @param data The event data
   */
  #onDragCancel: InteractableComponentCallbacks['onDragEnd'] = (data, interaction, controls) => {
    if (!this.#dragging || !this.owner) {
      return;
    }

    controls.stopPropagation();

    if (this.#hoverContext) {
      this.#hoverContext.removeFreezeFlag(HoverFreezeFlag.DRAGGING);
      this.#hoverContext = null;
    }

    this.targets.forEach((node) => {
      const dragComponent = node.components.get(DraggableComponent);

      if (dragComponent) {
        dragComponent.onEnd();
      }
    });

    this.#targets = [];

    this.state.values.dragging = false;
  };

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default DraggableComponentContext;
