import { iErrorReporter } from './i-error-reporter';

const errorReporterReduxMiddleware = (errorReporter: iErrorReporter) => {
  return () => {
    return (next) => {
      return (action) => {
        try {
          return next(action);
        } catch (err) {
          if (errorReporter) {
            errorReporter.notify(err, (event) => {
              if (event) {
                event.addMetadata('action', action.type);
              }
            });
          } else {
            console.warn('Error caught in action but no logger found');
          }

          console.error(err);
          return err;
        }
      };
    };
  };
};

export default errorReporterReduxMiddleware;
