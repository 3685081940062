import { State, StateDef } from '@gi/state';

import GraphicNode from '../../graphics-node';
import type CameraNode from '../camera/camera-node';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import { bindState } from '../../utils/state-utils';
import { hasEngine } from '../../utils/asserts';
import { type InteractableComponentCallbacks } from '../../node-components/interactable/interactable-component';
import type { CameraNodeState } from '../camera/camera-node';

export type ViewportContextState = StateDef<
  {
    isPrinting: boolean;
  },
  ['cameraListUpdate', 'cameraChangeRequest', 'cameraChange'],
  {
    activeCamera: CameraNodeState;
  }
>;

const DEFAULT_STATE: ViewportContextState['state'] = {
  isPrinting: false,
};

/**
 * Viewport context
 * Should be put on the "world" node, like the plan.
 * handles passing events to the camera to move around. Also handles moving the node container to
 * reflect the camera state.
 */
class ContentRootContext extends NodeComponent<GraphicNode> {
  type = 'ViewportContext';

  readonly state: State<ViewportContextState>;

  #cameraList: CameraNode[] = [];
  #activeCamera: CameraNode | null = null;
  #desiredActiveCamera: CameraNode | null = null;

  constructor() {
    super();

    this.state = new State(DEFAULT_STATE);
    bindState(this.state, this);
    this.state.addUpdater(this.#updateViewport, { signals: ['cameraChange'] });
    this.state.addUpdater(this.#updateActiveCamera, { signals: ['cameraChangeRequest'] });

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);
    if (this.#activeCamera) {
      this.owner.engine.rootGraphic.addChild(this.#activeCamera.getContainer());
    }
    this.#updateViewport();
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    if (this.#activeCamera) {
      this.owner.engine.rootGraphic.removeChild(this.#activeCamera.getContainer());
    }
  };

  get activeCamera() {
    return this.#activeCamera;
  }

  setActiveCamera(activeCamera: CameraNode | null) {
    if (this.#desiredActiveCamera === activeCamera) {
      return;
    }
    if (activeCamera) {
      this.registerCamera(activeCamera);
    }
    this.#desiredActiveCamera = activeCamera;
    this.state.triggerSignal('cameraChangeRequest');
  }

  registerCamera(camera: CameraNode) {
    const signals: ViewportContextState['signals'][number][] = [];
    if (this.#cameraList.indexOf(camera) === -1) {
      this.#cameraList.push(camera);
      signals.push('cameraListUpdate');
    }
    if (!this.#desiredActiveCamera) {
      this.#desiredActiveCamera = camera;
      signals.push('cameraChangeRequest');
    }
    this.state.triggerSignal(...signals);
  }

  unregisterCamera(camera: CameraNode) {
    const signals: ViewportContextState['signals'][number][] = [];
    const index = this.#cameraList.indexOf(camera);
    if (index !== -1) {
      this.#cameraList.splice(index, 1);
      signals.push('cameraListUpdate');

      if (this.#activeCamera === camera || this.#desiredActiveCamera === camera) {
        this.#desiredActiveCamera = this.#cameraList[0] ?? null;
        signals.push('cameraChangeRequest');
      }

      this.state.triggerSignal(...signals);
    }
  }

  onCameraUpdate(camera: CameraNode) {
    if (camera === this.#activeCamera) {
      this.#updateViewport();
    }
  }

  moveCamera(delta: Vector2) {
    if (this.#activeCamera) {
      this.#activeCamera.move(delta);
    }
  }

  zoomCamera(delta: number, anchor: Vector2) {
    if (this.#activeCamera) {
      this.#activeCamera.alterZoom(delta, anchor);
    }
  }

  scaleCameraZoom(scale: number, anchor: Vector2) {
    if (this.#activeCamera) {
      this.#activeCamera.scaleZoom(scale, anchor);
    }
  }

  onDragStart: InteractableComponentCallbacks['onDragStart'] = ({ screenPositionDelta }, interaction) => {
    interaction.autoPanCamera = false;
    // Prevent children getting mouse events while dragging for performance
    if (this.owner) {
      this.owner.getOwnGraphics().interactiveChildren = false;
    }
    this.moveCamera({ x: -screenPositionDelta.x, y: -screenPositionDelta.y });
  };

  onDragMove: InteractableComponentCallbacks['onDragMove'] = ({ screenPositionDelta }) => {
    this.moveCamera({ x: -screenPositionDelta.x, y: -screenPositionDelta.y });
  };

  onDragEnd: InteractableComponentCallbacks['onDragEnd'] = ({ screenPositionDelta }) => {
    this.moveCamera({ x: -screenPositionDelta.x, y: -screenPositionDelta.y });
    if (this.owner) {
      this.owner.getOwnGraphics().interactiveChildren = true;
    }
  };

  onMultiTouchStart: InteractableComponentCallbacks['onMultiTouchStart'] = (event, interaction) => {
    interaction.autoPanCamera = false;
    if (this.#activeCamera) {
      this.#activeCamera.state.values.moving = true;
    }
    if (this.owner) {
      this.owner.getOwnGraphics().interactiveChildren = false;
    }
  };

  onMultiTouchMove: InteractableComponentCallbacks['onMultiTouchMove'] = ({ screenGap, screenGapDelta, screenCenter, screenCenterDelta }) => {
    const scale = 1 + screenGapDelta / screenGap;
    this.scaleCameraZoom(scale, screenCenter);
    this.moveCamera({ x: -screenCenterDelta.x, y: -screenCenterDelta.y });
  };

  onMultiTouchEnd: InteractableComponentCallbacks['onMultiTouchEnd'] = (...args) => {
    this.onMultiTouchMove(...args);
    if (this.#activeCamera) {
      this.#activeCamera.state.values.moving = false;
    }
    if (this.owner) {
      this.owner.getOwnGraphics().interactiveChildren = false;
    }
  };

  #updateViewport = () => {
    // TODO: Go up the transform stack and work out the offset from viewport to the node before the camera.
    if (this.#activeCamera && this.owner) {
      this.#activeCamera.applyTransformationToNode(this.owner);
    }
  };

  #updateActiveCamera = () => {
    if (this.#activeCamera === this.#desiredActiveCamera) {
      return;
    }
    const engine = this.owner?.engine;
    if (!engine) {
      console.error('Tried to update active camera while not bound.');
      return;
    }
    if (this.#activeCamera) {
      engine.rootGraphic.removeChild(this.#activeCamera.getContainer());
    }
    if (this.#desiredActiveCamera) {
      if (!this.#desiredActiveCamera.shouldProxyContent) {
        console.warn('New active camera does not have `shouldProxyContent` enabled, which is required. See `shouldProxyContent` for more info.');
        this.#desiredActiveCamera.shouldProxyContent = true;
      }
      engine.rootGraphic.addChild(this.#desiredActiveCamera.getContainer());
    }
    this.#activeCamera = this.#desiredActiveCamera;
    if (this.#activeCamera) {
      this.state.connectState('activeCamera', this.#activeCamera.state);
    } else {
      this.state.disconnectState('activeCamera');
    }
    this.state.triggerSignal('cameraChange');
  };

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default ContentRootContext;
