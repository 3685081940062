import React, { useState } from 'react';
import YoutubeButton from './youtube-button';

import './youtube-embed.scss';

interface iProps {
  youTubeID: string;
  title?: string;
  allowFullscreen?: boolean;
  iframeID?: string;
  thumbnail?: null | string;
}

interface ExtraProps {
  allow: string;
  allowFullScreen?: boolean;
  mozallowfullscreen?: string;
  msallowfullscreen?: string;
  oallowfullscreen?: string;
  webkitallowfullscreen?: string;
  frameBorder?: string;
  id?: string;
}

const YouTubeEmbed = ({ youTubeID, title = '', allowFullscreen = true, iframeID = '', thumbnail = null }: iProps) => {
  const extraProps: ExtraProps = {
    allow: 'autoplay',
  };

  const [played, setPlayed] = useState<boolean>(thumbnail === null);

  if (allowFullscreen) {
    extraProps.allowFullScreen = true;
    extraProps.mozallowfullscreen = 'mozallowfullscreen';
    extraProps.msallowfullscreen = 'msallowfullscreen';
    extraProps.oallowfullscreen = 'oallowfullscreen';
    extraProps.webkitallowfullscreen = 'webkitallowfullscreen';
    extraProps.frameBorder = '0';
  }

  if (iframeID !== '') {
    extraProps.id = iframeID;
  }

  const autoplay = thumbnail !== null;
  const thumbnailURL = thumbnail === null ? '' : thumbnail;

  return (
    <div className='yt-embed video'>
      <div className='yt-embed-content'>
        {played ? (
          <iframe
            id={iframeID}
            {...extraProps}
            title={title}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            src={`https://www.youtube.com/embed/${youTubeID}?rel=0${autoplay ? '&autoplay=1' : ''}`}
            frameBorder='0'
          />
        ) : (
          <YoutubeButton
            onClick={() => {
              setPlayed(true);
            }}
            thumbnailURL={thumbnailURL}
          />
        )}
      </div>
    </div>
  );
};

export default YouTubeEmbed;
