import Collection from '@gi/collection';
import GardenObject from '@gi/garden-object';
import { LayerType } from '@gi/constants/types/layer-types';

import { PlanGardenObject } from './plan-garden-object';

export function gardenObjectIsType(planGardenObject: PlanGardenObject, gardenObjects: Collection<GardenObject>, type: LayerType): boolean {
  const go = gardenObjects.get(planGardenObject.code);

  if (go === null) {
    console.warn('Plan garden object not found in collection when checking if is of type', type, planGardenObject.code);
    return false;
  }

  return go.layerType === type;
}
