import React, { useCallback, useContext, useEffect, useState } from 'react';

import { EngineProvider } from '@gi/core-renderer';

import { GardenCanvasContext } from './garden-canvas-context/garden-canvas-context';
import PlanCloseModalRenderer from './plan-close-confirmation/plan-close-modal-renderer';
import GardenCanvasSVGCursor from './garden-canvas-cursor/garden-canvas-svg-cursor';

import styles from './react-garden-canvas.module.css';

interface iProps {
  className?: string;
}

const ReactGardenCanvas = ({ className }: iProps): JSX.Element => {
  const { gardenCanvas, createInstance } = useContext(GardenCanvasContext);

  const [gardenCanvasContainer, setGardenCanvasContainer] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (gardenCanvas === null) {
      createInstance();
    }
  }, []);

  /**
   * On pointer down, focus the canvas container. Focus is normally only gained on click, which
   *  means draws that drag out a row don't give focus, causing keybinds to not work until you
   *  explicitly click the canvas.
   */
  const handlePointerDownFocus = useCallback(() => {
    if (gardenCanvasContainer) {
      gardenCanvasContainer.focus();
    }
  }, [gardenCanvasContainer]);

  if (gardenCanvas === null) {
    return (
      <div className={styles.loadingContainer}>
        <span>
          Garden Canvas Loading <i className='icon-spinner animate-pulse' />
        </span>
      </div>
    );
  }

  // TODO: Add tabIndexd and autoFocus
  return (
    <EngineProvider engine={gardenCanvas.engine} container={gardenCanvasContainer}>
      <div
        id='canvas-container'
        /* eslint-disable-next-line */
        tabIndex={0}
        ref={setGardenCanvasContainer}
        className={`${styles.canvasContainer} ${className ? className : ''}`}
        onPointerDown={handlePointerDownFocus}
      >
        {/* Overlays here */}
        <PlanCloseModalRenderer />
        <GardenCanvasSVGCursor container={gardenCanvasContainer} />
      </div>
    </EngineProvider>
  );
};

export default ReactGardenCanvas;
