import { parseDate, dateToAPI } from '@gi/utils';
import { errorReporterInstance } from '@gi/errors';

import { User } from '../user';
import { userPlanFromAPI } from './user-plan-parser';
import { userPlantVarietySetFromAPI, userPlantVarietyToAPI } from './user-plant-variety-parser';
import { userSubscriptionFromAPI, userSubscriptionToAPI } from './user-subscription-parser';
import { UserPlanSetUtils } from '../user-plan-set';
import { UserFromAPI } from './user-api-types';
import { UserVarietiesNullError } from '../errors/user-varieties-null-error';

/**
 * Takes a user object returned from the API and return an immutable User
 *
 * @param {object} userObject
 * @returns {User}
 */
const userFromAPI = (userObject: UserFromAPI): User => {
  if (userObject.userVarieties === null) {
    errorReporterInstance.notify(new UserVarietiesNullError());
  }
  return {
    authToken: userObject.authToken,
    ID: userObject.user.userID,
    hash: userObject.user.intercomHash,
    email: userObject.user.email,
    emailConfirmed: userObject.user.emailConfirmed,
    password: userObject.user.password,
    isTemporaryPassword: userObject.user.isTemporaryPassword,
    firstName: userObject.user.firstName,
    accountCreated: parseDate(userObject.user.accountCreated),
    lastLogin: parseDate(userObject.user.lastLogin),
    accountEnabled: userObject.user.accountEnabled,
    postAuthTicket: userObject.user.ticket,
    countryCode: userObject.user.countryCode,
    isMasterAccount: userObject.user.isMasterAccount,
    settings: {
      location: {
        northernHemisphere: userObject.user.northernHemisphere,
        latitude: userObject.user.latitude,
        longitude: userObject.user.longitude,
        regionID: userObject.user.regionID,
        splitSeason: userObject.user.splitSeason,
      },
      frostDates: {
        last: userObject.user.lastFrostDay,
        first: userObject.user.firstFrostDay,
        set: userObject.user.frostDatesSet,
        noFrost: userObject.user.noFrost,
      },
      units: {
        metric: userObject.user.metric,
        metricTemperatureUnits: userObject.user.metricTemperatureUnits,
        metricWeightUnits: userObject.user.metricWeightUnits,
      },
      maxPlansPerYear: userObject.user.maxPlansPerYear,
      plansPublic: userObject.user.plansPublic,
      seasonExtenders: {
        coldFrameFirstFrostAdjust: userObject.user.ffAdjustCF,
        coldFrameLastFrostAdjust: userObject.user.lfAdjustCF,
        clocheFirstFrostAdjust: userObject.user.ffAdjustCL,
        clocheLastFrostAdjust: userObject.user.lfAdjustCL,
        rowCoverFirstFrostAdjust: userObject.user.ffAdjustRC,
        rowCoverLastFrostAdjust: userObject.user.lfAdjustRC,
        greenhouseFirstFrostAdjust: userObject.user.ffAdjustGH,
        greenhouseLastFrostAdjust: userObject.user.lfAdjustGH,
        heatedGreenhouseFirstFrostAdjust: userObject.user.ffAdjustHGH,
        heatedGreenhouseLastFrostAdjust: userObject.user.lfAdjustHGH,
        polytunnelFirstFrostAdjust: userObject.user.ffAdjustPT,
        polytunnelLastFrostAdjust: userObject.user.lfAdjustPT,
        heatedPolytunnelFirstFrostAdjust: userObject.user.ffAdjustHPT,
        heatedPolytunnelLastFrostAdjust: userObject.user.lfAdjustHPT,
      },
      data: {
        articlesCountryCode: userObject.user.countrySettings.articlesCountryCode,
        pestArtifactCode: userObject.user.countrySettings.pestDataCountryCode,
        pestPredictionCountryCode: userObject.user.countrySettings.pestPredictionCountryCode,
        userArtifactCode: userObject.user.countrySettings.plantDataCountryCode,
      },
    },
    plans: UserPlanSetUtils.create(userObject.usersPlans.map(userPlanFromAPI)),
    plantVarieties: userPlantVarietySetFromAPI(userObject.userVarieties),
    planSettings: {
      labelTextSize: userObject.user.labelSize,
      varietyForDefaultLabel: userObject.user.varietyForDefaultLabel,
      showLabelOnNewPlants: userObject.user.showLabel,
      showLabelOnNewSFGPlants: userObject.user.showLabelSFG,
      showPlantRoots: userObject.user.showPlantRoots,
      showGrid: userObject.user.grid,
      autosave: userObject.user.autoSave,
    },
    subscription: userSubscriptionFromAPI(userObject),
    tags: userObject.user.tags.split(' '),
    favouritePlants: userObject.user.favourites.length ? new Set(userObject.user.favourites.toUpperCase().split(' ')) : new Set(),

    misc: {
      flags: userObject.user.flags,
      guruEnrolled: userObject.user.guruEnrolled,
      guruQuestionsShown: userObject.user.guruQuestionsShown,
      affiliateID: userObject.user.affiliateID,
      androidAccount: userObject.user.androidAccount,
      appReminderEmailPlants: userObject.user.appReminderEmailPlants,
      browserString: userObject.user.browserString,
      clientDefinedOption: userObject.user.clientDefinedOption,
      clientID: userObject.user.clientID,
      clientTicket: userObject.user.clientTicket,
      customerSupportNotes: userObject.user.customerSupportNotes,
      documentVersion: userObject.user.documentVersion,
      educationalAccount: userObject.user.educationalAccount,
      endPlants: userObject.user.endPlants,
      flashAccount: userObject.user.flashAccount,
      grid: userObject.user.grid,
      hasRespondedToSurvey: userObject.user.hasRespondedToSurvey,
      hasUsediPadClient: userObject.user.hasUsediPadClient,
      hasUsediPhoneClient: userObject.user.hasUsediPhoneClient,
      iOSAccount: userObject.user.iOSAccount,
      iOSLoggingLevel: userObject.user.iOSLoggingLevel,
      includePerennialReminders: userObject.user.includePerennialReminders,
      ipAddress: userObject.user.ipAddress,
      languageCode: userObject.user.languageCode,
      lastIntroEmail: userObject.user.lastIntroEmail,
      lastPlanSaved1: userObject.user.lastPlanSaved1,
      lastPlanSaved2: userObject.user.lastPlanSaved2,
      lastPlanSaved3: userObject.user.lastPlanSaved3,
      lastPlanSaved4: userObject.user.lastPlanSaved4,
      lastPlanSaved5: userObject.user.lastPlanSaved5,
      lastSync: userObject.user.lastSync,
      lastTutorialID: userObject.user.lastTutorialID,
      loggingLevel: userObject.user.loggingLevel,
      masterAccountID: userObject.user.masterAccountID,
      modified: userObject.user.modified,
      noOfWeatherStationLookups: userObject.user.noOfWeatherStationLookups,
      offerEmails: userObject.user.offerEmails,
      plantingTimesAppAccount: userObject.user.plantingTimesAppAccount,
      plantingTimesWidgetAccount: userObject.user.plantingTimesWidgetAccount,
      publicTicket: userObject.user.publicTicket,
      reminderEmailPlants: userObject.user.reminderEmailPlants,
      reminderEmails: userObject.user.reminderEmails,
      showWebCommunications: userObject.user.showWebCommunications,
      snapToGrid: userObject.user.snapToGrid,
      squareFootMode: userObject.user.squareFootMode,
      tabSave: userObject.user.tabSave,
      tempPassword: userObject.user.tempPassword,
      termsAccepted: userObject.user.termsAccepted,
      tutorialEmails: userObject.user.tutorialEmails,
      username: userObject.user.username,
      webAccount: userObject.user.webAccount,
      userPlants: userObject.userPlants,
      fastspringAccount: userObject.user.fastspringAccount,
      fastspringSubscriptionCancelledDate: userObject.user.fastspringSubscriptionCancelledDate,
      fastspringSubscriptionID: userObject.user.fastspringSubscriptionID,
      nextBillingAmount: userObject.user.nextBillingAmount,
      nextBillingDate: userObject.user.nextBillingDate,
      subscriptionPaymentMethod: userObject.user.subscriptionPaymentMethod,
    },
  };
};

/**
 * Returns an object which can be sent to the GI API
 *
 * Commented out parameters are not required
 */
const userToAPI = (user: User) => {
  return {
    user: {
      documentVersion: 'e69992785b3514e5203857180b063c7f', // This document version forces an overwrite
      userID: user.ID,
      intercomHash: user.hash,
      countryCode: user.countryCode,
      email: user.email,
      // emailConfirmed: user.emailConfirmed, // Done as part of usersubscription
      password: user.password,
      isTemporaryPassword: user.isTemporaryPassword,
      firstName: user.firstName,
      accountCreated: dateToAPI(user.accountCreated),
      lastLogin: dateToAPI(user.lastLogin),
      accountEnabled: user.accountEnabled,
      ticket: user.postAuthTicket,
      isMasterAccount: user.isMasterAccount,
      latitude: user.settings.location.latitude,
      longitude: user.settings.location.longitude,
      northernHemisphere: user.settings.location.northernHemisphere,
      splitSeason: user.settings.location.splitSeason,
      firstFrostDay: user.settings.frostDates.first,
      lastFrostDay: user.settings.frostDates.last,
      frostDatesSet: user.settings.frostDates.set,
      noFrost: user.settings.frostDates.noFrost,
      metric: user.settings.units.metric,
      metricTemperatureUnits: user.settings.units.metricTemperatureUnits,
      metricWeightUnits: user.settings.units.metricWeightUnits,
      favourites: Array.from(user.favouritePlants).join(' ').toUpperCase(),
      // User settings
      maxPlansPerYear: user.settings.maxPlansPerYear,
      plansPublic: user.settings.plansPublic,
      // User Plan Settings
      labelSize: user.planSettings.labelTextSize,
      showLabel: user.planSettings.showLabelOnNewPlants,
      showLabelSFG: user.planSettings.showLabelOnNewSFGPlants,
      showPlantRoots: user.planSettings.showPlantRoots,
      varietyForDefaultLabel: user.planSettings.varietyForDefaultLabel,
      grid: user.planSettings.showGrid,
      autoSave: user.planSettings.autosave,
      // User Season Extenders
      ffAdjustCF: user.settings.seasonExtenders.coldFrameFirstFrostAdjust,
      lfAdjustCF: user.settings.seasonExtenders.coldFrameLastFrostAdjust,
      ffAdjustCL: user.settings.seasonExtenders.clocheFirstFrostAdjust,
      lfAdjustCL: user.settings.seasonExtenders.clocheLastFrostAdjust,
      ffAdjustRC: user.settings.seasonExtenders.rowCoverFirstFrostAdjust,
      lfAdjustRC: user.settings.seasonExtenders.rowCoverLastFrostAdjust,
      ffAdjustGH: user.settings.seasonExtenders.greenhouseFirstFrostAdjust,
      lfAdjustGH: user.settings.seasonExtenders.greenhouseLastFrostAdjust,
      ffAdjustHGH: user.settings.seasonExtenders.heatedGreenhouseFirstFrostAdjust,
      lfAdjustHGH: user.settings.seasonExtenders.heatedGreenhouseLastFrostAdjust,
      ffAdjustPT: user.settings.seasonExtenders.polytunnelFirstFrostAdjust,
      lfAdjustPT: user.settings.seasonExtenders.polytunnelLastFrostAdjust,
      ffAdjustHPT: user.settings.seasonExtenders.heatedPolytunnelFirstFrostAdjust,
      lfAdjustHPT: user.settings.seasonExtenders.heatedPolytunnelLastFrostAdjust,
      // Subscription settings
      ...userSubscriptionToAPI(user),
      // Tags
      tags: Object.freeze(user.tags.join(' ')),
      // Misc settings below
      flags: user.misc.flags,
      guruEnrolled: user.misc.guruEnrolled,
      guruQuestionsShown: user.misc.guruQuestionsShown,
      affiliateID: user.misc.affiliateID,
      androidAccount: user.misc.androidAccount,
      appReminderEmailPlants: user.misc.appReminderEmailPlants,
      browserString: user.misc.browserString,
      clientDefinedOption: user.misc.clientDefinedOption,
      clientID: user.misc.clientID,
      clientTicket: user.misc.clientTicket,
      customerSupportNotes: user.misc.customerSupportNotes,
      educationalAccount: user.misc.educationalAccount,
      endPlants: user.misc.endPlants,
      flashAccount: user.misc.flashAccount,
      hasRespondedToSurvey: user.misc.hasRespondedToSurvey,
      hasUsediPadClient: user.misc.hasUsediPadClient,
      hasUsediPhoneClient: user.misc.hasUsediPhoneClient,
      iOSAccount: user.misc.iOSAccount,
      iOSLoggingLevel: user.misc.iOSLoggingLevel,
      includePerennialReminders: user.misc.includePerennialReminders,
      ipAddress: user.misc.ipAddress,
      languageCode: user.misc.languageCode,
      lastIntroEmail: user.misc.lastIntroEmail,
      lastPlanSaved1: user.misc.lastPlanSaved1,
      lastPlanSaved2: user.misc.lastPlanSaved2,
      lastPlanSaved3: user.misc.lastPlanSaved3,
      lastPlanSaved4: user.misc.lastPlanSaved4,
      lastPlanSaved5: user.misc.lastPlanSaved5,
      lastSync: user.misc.lastSync,
      lastTutorialID: user.misc.lastTutorialID,
      loggingLevel: user.misc.loggingLevel,
      masterAccountID: user.misc.masterAccountID,
      modified: user.misc.modified,
      noOfWeatherStationLookups: user.misc.noOfWeatherStationLookups,
      offerEmails: user.misc.offerEmails,
      plantingTimesAppAccount: user.misc.plantingTimesAppAccount,
      plantingTimesWidgetAccount: user.misc.plantingTimesWidgetAccount,
      publicTicket: user.misc.publicTicket,
      regionID: user.settings.location.regionID,
      reminderEmailPlants: user.misc.reminderEmailPlants,
      reminderEmails: user.misc.reminderEmails,
      showWebCommunications: user.misc.showWebCommunications,
      snapToGrid: user.misc.snapToGrid,
      squareFootMode: user.misc.squareFootMode,
      tabSave: user.misc.tabSave,
      tempPassword: user.misc.tempPassword,
      termsAccepted: user.misc.termsAccepted,
      tutorialEmails: user.misc.tutorialEmails,
      username: user.misc.username,
      webAccount: user.misc.webAccount,
      fastspringAccount: user.misc.fastspringAccount,
      fastspringSubscriptionCancelledDate: user.misc.fastspringSubscriptionCancelledDate,
      fastspringSubscriptionID: user.misc.fastspringSubscriptionID,
      nextBillingAmount: user.misc.nextBillingAmount,
      nextBillingDate: user.misc.nextBillingDate,
      subscriptionPaymentMethod: user.misc.subscriptionPaymentMethod,
      countrySettings: {
        articlesCountryCode: user.settings.data.articlesCountryCode,
        pestDataCountryCode: user.settings.data.pestArtifactCode,
        pestPredictionCountryCode: user.settings.data.pestPredictionCountryCode,
        plantDataCountryCode: user.settings.data.userArtifactCode,
      },
    },
    usersPlans: [],
    userPlants: user.misc.userPlants,
    userVarieties: user.plantVarieties.userPlantVarieties.map(userPlantVarietyToAPI),
  };
};

export { userFromAPI, userToAPI };
