import { useContext, useEffect } from 'react';
import { KeyboardShortcutContext } from './keyboard-shortcut-provider';

export default function useKeyboardShortcut(
  callback: () => void,
  key: string,
  control?: boolean,
  shift?: boolean,
  extraConditions?: (e: KeyboardEvent) => boolean
) {
  const { registerShortcut, removeShortcut } = useContext(KeyboardShortcutContext);

  useEffect(() => {
    const id = registerShortcut({ callback, key, control, shift, extraConditions });
    return () => {
      removeShortcut(id);
    };
  }, [callback, key, control, shift]);
}
