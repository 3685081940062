/**
 * Represents possible loading states (e.g. the status of an API call)
 */
enum LoadingState {
  NONE = 'NONE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

/**
 * Asserts the given state is a success. Useful for ensuring an async operation is in SUCCESS state.
 * @param state The state to check
 */
export function isLoadingStateSuccess(state: LoadingState): asserts state is LoadingState.SUCCESS {
  if (state !== LoadingState.SUCCESS) {
    throw new Error('State is not SUCCESS');
  }
}

export default LoadingState;
