import { DisplayModeUtils } from '@gi/display-mode-provider';

export type DeviceData = {
  devicePixelRatio: number;
  screenWidth: number;
  screenHeight: number;
  browserWidth: number;
  browserHeight: number;
  landscape: boolean;
  hasMouse: boolean;
  hasTouch: boolean;
  isDesktopCapable: boolean;
  platform: string;
};

/**
 * Returns information about the user's current browser (screen size, input capabilities etc)
 */
export function getDeviceAnalyticsData(): DeviceData {
  const deviceData: DeviceData = {
    devicePixelRatio: window.devicePixelRatio,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    browserWidth: window.innerWidth,
    browserHeight: window.innerHeight,
    landscape: DisplayModeUtils.isLandscape(),
    hasMouse: DisplayModeUtils.hasMouse(),
    hasTouch: DisplayModeUtils.hasTouchscreen(),
    isDesktopCapable: DisplayModeUtils.isDesktopCapable(),
    platform: navigator.platform ?? 'Unknown',
  };

  return deviceData;
}
