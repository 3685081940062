import React from 'react';
import FormField, { FormFieldOptionsPreset } from '../form-field';
import { FORM_FIELD_PRESETS } from '../form-field-presets';
import { DOmit } from '../types';

const CheckboxLayouts = ['default', 'long', 'reverse'] as const;
type CheckboxLayout = (typeof CheckboxLayouts)[number];

type iProps = DOmit<Parameters<typeof FormField>[0], 'layoutPreset'> & {
  layout?: CheckboxLayout;
};

const presetMapping: { [key in CheckboxLayout]: FormFieldOptionsPreset } = {
  default: FORM_FIELD_PRESETS.Checkbox,
  long: FORM_FIELD_PRESETS.CheckboxLong,
  reverse: FORM_FIELD_PRESETS.CheckboxLeft,
};

/**
 * A form field designed for a checkbox.
 */
const FormCheckboxField = ({ layout = 'default', ...remainingProps }: iProps): JSX.Element => {
  return <FormField layoutPreset={presetMapping[layout]} {...remainingProps} />;
};

export default FormCheckboxField;
