import { InteractionStateType } from '@gi/constants';
import { PlannerActionCreators } from '@gi/app-planner-slice';
import { LocalSettingsActionTypes } from '@gi/local-settings';
import { PlannerSettingsActionTypes } from '@gi/react-planner-settings';
import { PlantInformationActionCreators } from '@gi/plant-information-modal';
import { CanvasActionTypes, GardenCanvasEventActionTypes, OpenPlanActionType } from '@gi/react-garden-canvas';

import { openPlannerSettings } from '@gi/react-planner-settings/source/planner-settings-action-creators';
import { setLocalSettings } from '@gi/local-settings/source/local-settings-action-creators';
import { editItem, updatePlan } from '@gi/react-garden-canvas/source/redux-components/canvas-action-creators';
import { updateInteractionState } from '@gi/react-garden-canvas/source/redux-components/garden-canvas-event-action-creators';

import { GardenPlatformEvent, MiddlewareEnhanceFunction } from '../types';
import { GardenPlatformEventsActionCreators } from '../garden-platform-events-middleware';

const enhanceMiddleware: MiddlewareEnhanceFunction = (middleware) => {
  middleware.startListening({
    type: CanvasActionTypes.OPEN_PLAN,
    effect: (_action, api) => {
      const action = _action as OpenPlanActionType;
      api.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.OpenPlan, { planId: action.planID }));
    },
  });

  // Emit event when user changes planner tab
  middleware.startListening({
    actionCreator: PlannerActionCreators.setActivePlannerTab,
    effect: (action, api) => {
      const { tabID, planID } = action.payload;
      api.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.OpenPlannerTab, { tab: tabID, planId: planID }));
    },
  });

  // Emit event when user opens settings modal
  middleware.startListening({
    type: PlannerSettingsActionTypes.OPEN_PLANNER_SETTINGS,
    effect: (_action, api) => {
      const action = _action as ReturnType<typeof openPlannerSettings>;
      api.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.OpenPlannerSettings, { tab: action.defaultTab ?? undefined }));
    },
  });

  // Detect when SFG mode changes and emit an event
  let localSettingsInitialized = false;
  middleware.startListening({
    type: LocalSettingsActionTypes.SET_LOCAL_SETTINGS,
    effect: (_action, api) => {
      const action = _action as ReturnType<typeof setLocalSettings>;
      const state = api.getState();
      const originalState = api.getOriginalState();
      // Don't track if the local settings aren't already initialized.
      if (!state.localSettings.initialised) {
        return;
      }
      // Local settings get set to initialized straight away, before the user loads.
      // This means they're effectively loaded twice, once on app start, and then again once the user is set in the session.
      // We need to keep track of our own version of initialized, as we only want to track changes after everything has definitely been loaded.
      if (!localSettingsInitialized) {
        if (state.session.user !== null) {
          localSettingsInitialized = true;
        }
        return;
      }

      const { sfgMode } = action.settings;
      if (sfgMode !== undefined && sfgMode !== originalState.localSettings.sfgMode) {
        api.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ChangeSFGMode, { enabled: sfgMode }));
      }
    },
  });

  // Detect changes in the active plan to check for specific features being used.
  // All changes get simplified down to an UPDATE_PLAN call
  middleware.startListening({
    type: CanvasActionTypes.UPDATE_PLAN,
    effect: (_action, api) => {
      const action = _action as ReturnType<typeof updatePlan>;
      const { plan } = action;
      const { cropRotationMode, layer, month } = plan.plannerSettings;

      // The payload contains the full new plan, not just the changes, so we need to compare against he current state.
      const oldPlan = api.getOriginalState().canvas.plans.plans[plan.id];
      if (oldPlan === undefined) {
        return;
      }

      // Check if crop rotation mode was changed to something other than default
      if (cropRotationMode !== oldPlan.plannerSettings.cropRotationMode) {
        // Unformatted mode. If sending to analytics, convert numbers to plat families.
        api.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ChangeCropRotationMode, { mode: cropRotationMode }));
      }

      // Check if layer mode was changed to something other than the default
      if (layer !== oldPlan.plannerSettings.layer) {
        api.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ChangeLayerMode, { mode: layer }));
      }

      // Check if month view was changed to something other than default
      if (month !== oldPlan.plannerSettings.month) {
        api.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ChangeMonthView, { month }));
      }
    },
  });

  // Emit event whenever someone starts using the selection box tool
  middleware.startListening({
    type: GardenCanvasEventActionTypes.UPDATE_INTERACTION_STATE,
    effect: (_action, api) => {
      const action = _action as ReturnType<typeof updateInteractionState>;
      if (action.interactionState.type === InteractionStateType.SELECTION_BOX) {
        api.dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.UseSelectionTool, {}));
      }
    },
  });

  // Fire event when edit modal opens
  middleware.startListening({
    type: CanvasActionTypes.EDIT_ITEM,
    effect: (_action, api) => {
      const action = _action as ReturnType<typeof editItem>;
      api.dispatch(
        GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ViewEditGardenItemModal, {
          itemType: action.itemType,
          itemId: action.itemID,
          planId: action.planID,
        })
      );
    },
  });

  // Detect plant information modal being opened. Include which tab is being opened to, for journal history.
  middleware.startListening({
    actionCreator: PlantInformationActionCreators.openPlantInformationModal,
    effect: (action, api) => {
      api.dispatch(
        GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ViewPlantInformationModal, {
          tab: action.payload.defaultTab ?? 'plant-information',
          plantCode: action.payload.plant.code,
          justOpened: true,
        })
      );
    },
  });
};

export default enhanceMiddleware;
