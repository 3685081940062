import { Reminder, ReminderData } from '../reminder';

/**
 * TODO: When we add better Journal types, add better parsing types for these objects
 *
 * Even better would probably be to consider removing reminders from other Journal entries
 */
type ReminderAPIObjectData = {
  note: string;
  dateYMD: string;
  relatedPlants: string[];
  isTask: boolean;
  taskCompleted: boolean;
  journalEntryImages: string[];
  journalEntryPlants: string[];
  journalEntryPests: string[];
};

type ReminderAPISaveObject = ReminderAPIObjectData & {
  journalEntryID?: number;
};

export type ReminderAPILoadObject = ReminderAPIObjectData & {
  journalEntryID: number;
};

const reminderFromAPI = (reminderObject: ReminderAPILoadObject): Reminder => {
  return {
    ID: reminderObject.journalEntryID,
    note: reminderObject.note,
    dateYMD: reminderObject.dateYMD,
    relatedPlants: [],
    dismissed: !!reminderObject.taskCompleted,
  };
};

const remindersFromAPI = (journalData): Reminder[] => {
  const reminders: Reminder[] = [];

  for (let i = 0; i < journalData.length; i++) {
    const entries = journalData[i].journalEntries;

    const reminderList: Reminder[] = entries.filter((entry) => entry.isTask).map((entry) => reminderFromAPI({ dateYMD: journalData[i].dateYMD, ...entry }));

    reminders.push(...reminderList);
  }

  return reminders;
};

const reminderToAPI = (reminder: Reminder | ReminderData): ReminderAPISaveObject => {
  return {
    journalEntryID: 'ID' in reminder ? reminder.ID : undefined,
    note: reminder.note,
    dateYMD: reminder.dateYMD,
    relatedPlants: [],
    isTask: true,
    taskCompleted: reminder.dismissed,
    journalEntryImages: [],
    journalEntryPlants: [],
    journalEntryPests: [],
  };
};

export { reminderToAPI, reminderFromAPI, remindersFromAPI };
