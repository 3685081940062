import { DeviceDisplayMode } from '@gi/constants';
import { Application, getAbsoluteApplicationLink } from '@gi/garden-platform-navigation';
import { IntercomCommand } from '../constants';
import { IntercomCommandSideEffect } from '../intercom-commands';

// From API/Serialized input
export type InputIntercomShortcutInstruction = {
  command: string; // String intercom command
  id: number[] | number | null; // number or list of numbers for intercom ids to run, id is selected base on selector function specified
  displayMode?: string; // Mobile/desktop - which display modes are valid for this
  selector?: string; // Function which decides which intercom id to run based on the users userId
  sideEffects?: (string | { type: string; arguments: any })[] | null; // list of side effects to run when intercom command is run
};

export type InputIntercomShortcutList = {
  id: string; // Shortcut identifier
  app?: string; // Shortcut will not be run until given app is open
  instructions: InputIntercomShortcutInstruction[];
};

export type IntercomCommandSideEffectInstruction = {
  type: IntercomCommandSideEffect;
  arguments?: any;
};

// Parsed
export type IntercomShortcutInstruction = {
  command: IntercomCommand;
  id: number[] | number | null;
  displayMode: DeviceDisplayMode.MOBILE | DeviceDisplayMode.DESKTOP | null;
  selector: IntercomShortcutSelector;
  sideEffects: IntercomCommandSideEffectInstruction[] | null;
};

export type IntercomShortcut = {
  id: string;
  app: IntercomShortcutApp | null;
  instructions: IntercomShortcutInstruction[];
};

export type IntercomShortcutDefinitions = Record<string, IntercomShortcut>;

export type IntercomShortcutSelectorFunction = (intercomActionId: number[] | number | null, userId: number) => number | null;

export enum IntercomShortcutSelector {
  Default = 'Default',
  UserId = 'UserId',
  Random = 'Random',
}

export enum IntercomShortcutApp {
  Overview = 'Overview',
  Planner = 'Planner',
  Journal = 'Journal',
  Guru = 'Guru',
}

export const IntercomShortcutAppPathMatch: Record<IntercomShortcutApp, string> = {
  [IntercomShortcutApp.Overview]: `${getAbsoluteApplicationLink(Application.Overview)}/*`,
  [IntercomShortcutApp.Planner]: `${getAbsoluteApplicationLink(Application.GardenPlanner)}/*`,
  [IntercomShortcutApp.Journal]: `${getAbsoluteApplicationLink(Application.Journal)}/*`,
  [IntercomShortcutApp.Guru]: `${getAbsoluteApplicationLink(Application.Guru)}/*`,
};
