import React, { CSSProperties, ReactNode, useEffect, useMemo, useState } from 'react';

import { LazyImageProvider } from '../../lazy-image/lazy-image-context';
import Pagination from '../../pagination/pagination';

import styles from './paginated-content-grid-inner.module.css';

interface iProps {
  minItemHeight?: number;
  itemAspectRatio?: number;
  gap?: number;
  rows?: number;
  setPageSize?: (pageSize: number) => void;
  setCurrentItemIndex?: (itemIndex: number) => void;
  setMaxLoadingPlaceholders?: (max: number | null) => void;
  loadNext?: () => void;
  allItems: ReactNode[];
}

const PaginatedContentGridInner = ({
  minItemHeight = 150,
  itemAspectRatio = 9 / 16,
  gap = 18,
  rows = 5,
  setPageSize,
  setCurrentItemIndex,
  setMaxLoadingPlaceholders,
  loadNext,
  allItems,
}: iProps): JSX.Element => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(window.innerWidth);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const callback = () => {
      setContainerWidth(ref?.clientWidth ?? window.innerWidth);
    };
    callback();
    if (ref) {
      window.addEventListener('resize', callback);

      return () => {
        window.removeEventListener('resize', callback);
      };
    }
    return () => {};
  }, [ref]);

  const itemsPerRow = useMemo(() => {
    return Math.max(Math.floor(containerWidth / (minItemHeight * itemAspectRatio)), 2);
  }, [minItemHeight, itemAspectRatio, containerWidth]);

  const itemsPerPage = useMemo(() => {
    return itemsPerRow * rows;
  }, [itemsPerRow, rows]);

  useEffect(() => {
    setMaxLoadingPlaceholders?.(itemsPerRow);
  }, [itemsPerRow, setMaxLoadingPlaceholders]);

  useEffect(() => {
    setPageSize?.(itemsPerPage);
  }, [itemsPerPage, setPageSize]);

  useEffect(() => {
    setCurrentItemIndex?.(currentPage * itemsPerPage);
  }, [currentPage, itemsPerPage, setCurrentItemIndex]);

  /** Prevent being on a page that doesn't exist if the amount of items changes */
  useEffect(() => {
    if (currentPage * itemsPerPage > allItems.length) {
      setCurrentPage(Math.floor(allItems.length / itemsPerPage));
    }
  }, [itemsPerPage, allItems]);

  const cssVariables = useMemo<CSSProperties>(() => {
    return {
      '--gap': `${gap}px`,
      '--columns': itemsPerRow,
    } as CSSProperties;
  }, [itemsPerRow, gap]);

  const items = useMemo(() => {
    return allItems.slice(itemsPerPage * currentPage, itemsPerPage * (currentPage + 1));
  }, [allItems, itemsPerPage, currentPage]);

  const pages = useMemo(() => {
    return Math.ceil(allItems.length / itemsPerPage);
  }, [allItems, itemsPerPage]);

  return (
    <LazyImageProvider containerRef={ref}>
      <div className={styles.gridContent} ref={setRef} style={cssVariables}>
        {items}
      </div>
      {pages > 1 || loadNext ? (
        <Pagination current={currentPage} setCurrent={setCurrentPage} max={Math.ceil(allItems.length / (itemsPerRow * rows))} loadMore={loadNext} />
      ) : null}
    </LazyImageProvider>
  );
};

export default PaginatedContentGridInner;
