import { useCallback, useEffect, useMemo, useRef } from 'react';
import { ElementProps, FloatingRootContext, OpenChangeReason } from '@floating-ui/react';

import { Geometry } from '@gi/math';

const userSelectCSSProperties = [
  'webkitTouchCallout',
  'webkitUserCallout',
  'webkitUserSelect',
  'userSelect',
  'webkitUserDrag',
  'webkitUserModify',
  'webkitHighlight',
] as const;

type UserSelectCSSProperties = Record<(typeof userSelectCSSProperties)[number], string>;

function preventUserSelect(element: HTMLElement): UserSelectCSSProperties {
  const oldProperties: UserSelectCSSProperties = {} as UserSelectCSSProperties;
  // Double loop to get all the properties first, then change them, as modifying some properties also
  //  modifies others, so we end up reading the modified value instead of the original
  userSelectCSSProperties.forEach((property) => {
    oldProperties[property] = element.style[property];
  });
  userSelectCSSProperties.forEach((property) => {
    element.style[property] = 'none';
  });
  return oldProperties;
}

function enableUserSelect(element: HTMLElement, properties: UserSelectCSSProperties): void {
  userSelectCSSProperties.forEach((property) => {
    element.style[property] = properties[property];
  });
}

interface TrackingData {
  identifier: number;
  startPos: Vector2;
  openTimeout: number;
  cssProperties: UserSelectCSSProperties;
}

interface UseLongTouchProps {
  enabled?: boolean;
  delayOpen?: number;
  delayClose?: number;
}

export const useLongTouch = (context: FloatingRootContext, props: UseLongTouchProps) => {
  const { onOpenChange, events } = context;
  const { enabled = true, delayOpen = 500, delayClose = 2000 } = props;

  const trackingData = useRef<TrackingData | null>(null);
  const closeDelayTimeout = useRef<number | null>(null);
  const openFromTouch = useRef<boolean>(false);
  const openFromOther = useRef<boolean>(false);

  const clearTrackingData = useCallback(() => {
    if (trackingData.current) {
      window.clearTimeout(trackingData.current.openTimeout);
      trackingData.current = null;
    }
  }, []);

  const clearCloseDelayTimeout = useCallback(() => {
    if (closeDelayTimeout.current !== null) {
      window.clearTimeout(closeDelayTimeout.current);
      closeDelayTimeout.current = null;
    }
  }, []);

  useEffect(() => {
    // If the tooltip opens for any other reason, track that so we don't close it
    const handleEvent = ({ open, reason }: { open: boolean; reason?: OpenChangeReason }) => {
      if (open && reason !== ('long-touch' as OpenChangeReason) && reason !== undefined) {
        openFromOther.current = true;
      }
    };

    events.on('openchange', handleEvent);

    return () => {
      events.off('openchange', handleEvent);
    };
  }, [events]);

  const reference: ElementProps['reference'] = useMemo(
    () => ({
      // Use touch events instead of pointer events, as pointer events get consumed by scroll actions so are unreliable
      onTouchStart(event) {
        if (trackingData.current) {
          return;
        }
        // If we're starting a new solo touch, set up for showing the tooltip
        if (event.touches.length === 1 && event.changedTouches[0]) {
          const touch = event.changedTouches[0];
          const timeout = window.setTimeout(() => {
            clearCloseDelayTimeout();
            openFromTouch.current = true;
            openFromOther.current = false;
            onOpenChange(true, undefined, 'long-touch' as OpenChangeReason);
          }, delayOpen);

          trackingData.current = {
            identifier: touch.identifier,
            startPos: { x: touch.clientX, y: touch.clientY },
            openTimeout: timeout,
            cssProperties: preventUserSelect(event.currentTarget as HTMLElement),
          };
        }
      },

      onTouchMove(event) {
        if (!trackingData.current || openFromTouch.current) {
          return;
        }

        // Check if our touchpoint has moved too far away form the starting position and cancel if so (likely scroll)
        const touch = Array.from(event.changedTouches).find(({ identifier }) => identifier === trackingData.current?.identifier);
        if (!touch) {
          return;
        }

        if (Geometry.dist(trackingData.current.startPos, { x: touch.clientX, y: touch.clientY }) > 5) {
          clearTrackingData();
        }
      },

      onTouchEnd(event) {
        if (!trackingData.current) {
          return;
        }

        const touch = Array.from(event.changedTouches).find(({ identifier }) => identifier === trackingData.current?.identifier);
        if (!touch) {
          return;
        }

        // Re-enable selection on the element
        enableUserSelect(event.currentTarget as HTMLElement, trackingData.current.cssProperties);

        // If the tooltip is currently open, close it after a delay. Otherwise clean up immediately.
        if (openFromTouch.current) {
          clearTrackingData();
          clearCloseDelayTimeout();

          closeDelayTimeout.current = window.setTimeout(() => {
            if (openFromTouch.current) {
              openFromTouch.current = false;
              if (!openFromOther.current) {
                onOpenChange(false, undefined, 'long-touch' as OpenChangeReason);
              }
            }
          }, delayClose);
        } else {
          clearTrackingData();
        }
      },

      onContextMenu(event) {
        // Make sure we're working with a long-press, not a right-click
        if (event.nativeEvent && event.nativeEvent instanceof PointerEvent && event.nativeEvent.pointerType === 'touch') {
          event.preventDefault();

          if (openFromTouch.current) {
            return;
          }

          // Open the tooltip
          openFromTouch.current = true;
          openFromOther.current = false;
          onOpenChange(true, undefined, 'long-touch' as OpenChangeReason);

          // Close the tooltip automatically after a delay if there's no touch tracking going on
          if (!trackingData.current) {
            closeDelayTimeout.current = window.setTimeout(() => {
              if (openFromTouch.current) {
                openFromTouch.current = false;
                if (!openFromOther.current) {
                  onOpenChange(false, undefined, 'long-touch' as OpenChangeReason);
                }
              }
              clearTrackingData();
            }, delayClose);
          }
        }
      },
    }),
    [delayOpen, delayClose]
  );

  return useMemo(() => (enabled ? { reference } : {}), [enabled, reference]);
};
