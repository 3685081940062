import React, { ReactNode } from 'react';
import './page-heading-title.scss';

interface iProps {
  className?: string;
  children?: ReactNode;
}

const PageHeadingTitle = ({ className, children }: iProps): JSX.Element => {
  const classNames = ['page-heading-title'];
  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default PageHeadingTitle;
