import React, { ReactNode } from 'react';
import './page-section.scss';

interface iProps {
  className?: string;
  children?: ReactNode;
}

const PageSection = ({ className, children }: iProps): JSX.Element => {
  const classNames = ['page-section'];
  if (className) {
    classNames.push(className);
  }
  return <div className={classNames.join(' ')}>{children}</div>;
};

export default PageSection;
