import React from 'react';
import ClearableInput from '@gi/clearable-input';

import GuruButton from '../../components/buttons/guru-button';

import styles from './search-directory-page.module.css';

interface iProps {
  searchString: string;
  setSearchString: (searchString: string) => void;
  doSearch: () => void;
}

const SearchInput = ({ searchString, setSearchString, doSearch }: iProps): JSX.Element => {
  // Use enter key to perform search
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      doSearch();
    }
  };

  return (
    <div className={styles.searchInputInner}>
      <ClearableInput className={styles.textInput} onKeyDown={onKeyDownHandler} onChange={(value) => setSearchString(value)} value={searchString} />
      <GuruButton className={styles.searchButton} onClick={doSearch} disabled={searchString.trim().length === 0}>
        Search
      </GuruButton>
    </div>
  );
};

export default SearchInput;
