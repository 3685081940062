import React from 'react';
import { GuruSection } from '@gi/app-guru-types/types';

import ContentGrid from '../content-grid/content-grid';
import EmptyPreviewRow from '../empty-preview/empty-preview-row';
import { DirectoryPageSectionProvider } from './directory-page-section-provider';
import PaginatedContentGrid from '../content-grid/paginated-content-grid/paginated-content-grid';

interface iProps {
  sectionId: string;
  section: GuruSection;
  showEmptySectionTitle?: boolean;
  emptySectionComponent?: React.ReactNode;
  usePaginatedGrids?: boolean;
}

const DirectoryPageSectionGrid = ({
  sectionId,
  section,
  showEmptySectionTitle,
  emptySectionComponent,
  usePaginatedGrids = true,
}: iProps): JSX.Element | null => {
  if (section.items.length === 0 && emptySectionComponent) {
    return <EmptyPreviewRow title={showEmptySectionTitle ? section.title : null}>{emptySectionComponent}</EmptyPreviewRow>;
  }

  if (!usePaginatedGrids) {
    return <ContentGrid contentTypes={section.contentTypes} title={section.title} items={section.items} />;
  }

  return (
    <DirectoryPageSectionProvider sectionId0={sectionId} lookAheadPages={0}>
      <PaginatedContentGrid title={section.title} hideWhenEmpty contentTypes={section.contentTypes} />
    </DirectoryPageSectionProvider>
  );
};

export default DirectoryPageSectionGrid;
