type SelectionDifference<T> = {
  added: T[];
  removed: T[];
};

class Selection<T> {
  #selection: T[];
  #selectionMap: Record<string | number | symbol, T>;
  #lastSelection: T[];
  #getId: (item: T) => string | number | symbol;
  #canSelect: (item: T) => boolean;

  get items(): Readonly<T[]> {
    return this.#selection;
  }

  /**
   * A selection class, used to keep track of a set of items which are part of a selection.
   * @param getId A function to return a unique ID from the item, to use for indexing.
   */
  constructor(getId: (item: T) => string | number | symbol, getCanSelect: (item: T) => boolean = () => true) {
    this.#selection = [];
    this.#selectionMap = {};
    this.#lastSelection = [];
    this.#getId = getId;
    this.#canSelect = getCanSelect;
  }

  /**
   * Checks if the given item is in this selection
   * @param item The item to check
   * @returns True if the item is in the selection
   */
  has(item: T): boolean {
    return this.#selectionMap[this.#getId(item)] !== undefined;
  }

  /**
   * Adds an item to the selection
   * @param item The item to add
   * @returns A list of the items that were successfully added
   */
  add(...items: T[]): T[] {
    const added: T[] = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (!this.has(item) && this.#canSelect(item)) {
        this.#selection.push(item);
        this.#selectionMap[this.#getId(item)] = item;
        added.push(item);
      }
    }

    return added;
  }

  /**
   * Removes an item from this selection
   * @param item The item to remove
   * @returns A list of the items that were successfully removed
   */
  remove(...items: T[]): T[] {
    const removed: T[] = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (this.has(item)) {
        const id = this.#selection.indexOf(item);
        this.#selection.splice(id, 1);
        delete this.#selectionMap[this.#getId(item)];
        removed.push(item);
      }
    }

    return removed;
  }

  /**
   * Toggles the selection state on all the given items, adding itmes not in the selection and removing items in the selection.
   * @param items The items to toggle selection on
   * @returns All the items that were added to and rmeoved from the selection
   */
  toggle(...items: T[]): SelectionDifference<T> {
    const added: T[] = [];
    const removed: T[] = [];

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (this.has(item)) {
        removed.push(...this.remove(item));
      } else if (this.#canSelect(item)) {
        added.push(...this.add(item));
      }
    }

    return { added, removed };
  }

  /**
   * Sets the selection to contain only the given items. Returns the items that were added and removed.
   * @param items The items to select
   * @returns The items that were added and removed from the selection
   */
  set(items: T[]): SelectionDifference<T> {
    const { added, removed } = this.#getDifference(this.#selection, items);

    const finalAdded: T[] = [];

    for (let i = 0; i < Math.max(added.length, removed.length); i++) {
      if (added[i] && this.#canSelect(added[i])) {
        finalAdded.push(...this.add(added[i]));
      }
      if (removed[i]) {
        this.remove(removed[i]);
      }
    }

    return { added: finalAdded, removed };
  }

  /**
   * Filters the selection, using the given function to determine if the item can be selected
   * @param canSelectFunc A function to return true for each node that can be selected.
   *  Omit to use the already-defined canSelect function
   * @returns A list of removed items
   */
  filter(canSelectFunc: (item: T) => boolean = this.#canSelect): T[] {
    const toRemove: T[] = [];

    for (let i = 0; i < this.#selection.length; i++) {
      if (!canSelectFunc(this.#selection[i])) {
        toRemove.push(this.#selection[i]);
      }
    }

    return this.remove(...toRemove);
  }

  /**
   * Stores the current selection, and returns the diff between the current selection and the
   *  selection at the point this was last called.
   * @returns The difference between the current selection and the previous
   */
  getDiff(): SelectionDifference<T> {
    const diff = this.#getDifference(this.#lastSelection, this.#selection);
    this.#lastSelection = [...this.#selection];
    return diff;
  }

  /**
   * Returns the difference between 2 lists, finding which nodes were added and removed to convert `oldList` to `newList`.
   * @param oldList The old list
   * @param newList The new list
   * @returns The added and removed items when going from `oldList` to `newList`
   */
  // eslint-disable-next-line class-methods-use-this
  #getDifference(oldList: T[], newList: T[]): SelectionDifference<T> {
    const added = newList.filter((item) => !oldList.includes(item));
    const removed = oldList.filter((item) => !newList.includes(item));
    return { added, removed };
  }
}

export default Selection;
