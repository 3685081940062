import { State, StateDef } from '@gi/state';
import GraphicNode from '../../graphics-node';
import { InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';
import { hasOwner } from '../../utils/asserts';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
// eslint-disable-next-line import/no-cycle
import HoverableComponentContext from './hoverable-component-context';
import InteractableComponent from '../interactable/interactable-component';
import { bindState } from '../../utils/state-utils';

export type HoverableComponentState = StateDef<{
  hovered: boolean;
}>;

const DEFAULT_STATE: HoverableComponentState['state'] = {
  hovered: false,
};

/**
 * Hoverable Component
 * Allows this node to be hovered.
 */
class HoverableComponent extends NodeComponent<GraphicNode> {
  type = 'HoverableComponent';

  readonly state: State<HoverableComponentState>;

  #interactableComponent: InteractableComponent | null = null;

  constructor() {
    super();

    this.state = new State<HoverableComponentState>(DEFAULT_STATE);
    bindState(this.state, this);

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasOwner(this);
    this.#interactableComponent = this.owner.components.get(InteractableComponent);

    if (!this.#interactableComponent) {
      console.error('HoverableComponent requires an InteractableComponent be attached to the Node');
      return;
    }

    this.#interactableComponent.addListener('onPointerEnter', this.#hover);
    this.#interactableComponent.addListener('onPointerLeave', this.#unhover);
  };

  #onBeforeUnbind = () => {
    if (!this.#interactableComponent) {
      console.error('HoverableComponent requires an InteractableComponent be attached to the Node');
      return;
    }

    this.#interactableComponent.removeListener('onPointerEnter', this.#hover);
    this.#interactableComponent.removeListener('onPointerLeave', this.#unhover);
    this.#interactableComponent = null;
  };

  /**
   * Internally handles when this node is hovered
   */
  #hover = (event: PointerEvent) => {
    hasOwner(this);
    if (event && event.pointerType === 'touch') {
      return;
    } // Don't hover if it come from a touch event
    this.owner.getContext(HoverableComponentContext).hover(this.owner);
  };

  /**
   * Internally handles when this node is unhovered
   */
  #unhover = () => {
    hasOwner(this);
    this.owner.getContext(HoverableComponentContext).unhover(this.owner);
  };

  /**
   * If this node is currently being hovered, this will tell the context to unhover it.
   */
  forceUnhover() {
    if (this.state.values.hovered && this.owner) {
      this.owner.getContext(HoverableComponentContext).unhover(this.owner);
    }
  }

  /**
   * Sets the hover state of this component
   * @param shouldHover Is this component being hovered
   */
  setHovered(shouldHover: boolean) {
    this.state.values.hovered = shouldHover;
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default HoverableComponent;
