import { State, StateDef } from '@gi/state';

import { CameraNodeState } from '../../nodes/camera/camera-node';
import { hasEngine } from '../../utils/asserts';
import ShapeComponent from '../shape/shape-component';
import type VisibilityComponent from './visibility-component';
import { createActiveCameraConnector } from '../../utils/camera-utils';
import { bindState } from '../../utils/state-utils';
import NodeComponent, { NodeComponentEvent } from '../../node-component/node-component';
import ShapeComponentContext, { ShapeComponentContextState } from '../shape/shape-component-context';
import { Bounds, InspectableClassData, InspectableClassDataType, InspectableClassPropertyType } from '../../types';

export type VisibilityComponentContextState = StateDef<
  object,
  ['cameraMoved', 'quadtreeUpdated'],
  {
    shape: ShapeComponentContextState;
    camera: CameraNodeState;
  }
>;

class VisibilityComponentContext extends NodeComponent {
  type = 'VisibilityComponentyContext';

  #BOUNDS_MARGIN_PERCENT: number = 0.25;

  #components: VisibilityComponent[] = [];

  #visibleItems: string[] = [];

  #shapeContext: ShapeComponentContext | null = null;
  #shapeVisibilityMapping: Record<string, VisibilityComponent> = {};

  #cameraLastBounds: Bounds = {
    left: -Infinity,
    right: Infinity,
    top: -Infinity,
    bottom: Infinity,
  };

  readonly state: State<VisibilityComponentContextState>;

  constructor() {
    super();

    this.state = new State({});
    bindState(this.state, this);

    this.state.addUpdater(
      (state) => {
        state.triggerSignal('quadtreeUpdated');
      },
      {
        otherStates: { shape: { properties: ['hasQuadtreeUpdated'] } },
      }
    );

    this.state.addUpdater(
      (state) => {
        state.triggerSignal('cameraMoved');
      },
      {
        otherStates: { camera: { properties: ['position', 'magnification', 'viewportSize'] } },
      }
    );

    this.state.addWatcher(
      (state) => {
        const { cameraMoved, quadtreeUpdated } = state.changed.signals;
        // There properties get auto-reset to false each frame, but if they've changed, it means we need to recalculate.
        if (cameraMoved || quadtreeUpdated) {
          this.#recalculateVisibility(quadtreeUpdated);
        }
      },
      {
        signals: ['cameraMoved', 'quadtreeUpdated'],
      }
    );

    createActiveCameraConnector(this as NodeComponent, this.state, 'camera');

    this.eventBus.on(NodeComponentEvent.DidBind, this.#onBind);
    this.eventBus.on(NodeComponentEvent.BeforeUnbind, this.#onBeforeUnbind);
  }

  #onBind = () => {
    hasEngine(this);
    this.#shapeContext = this.owner.tryGetContext(ShapeComponentContext);

    if (!this.#shapeContext) {
      throw new Error('VisibilityComponentContext needs a ShapeComponentContext to manage visibility...');
    }

    this.state.connectState('shape', this.#shapeContext.state);
  };

  #onBeforeUnbind = () => {
    hasEngine(this);
    this.#shapeContext = null;
  };

  /**
   * Registers the given visibility component to be considered in visibility calculations.
   * @param component The visibility component to register
   */
  register(component: VisibilityComponent) {
    const shapeComponent = component.owner?.components.get(ShapeComponent);

    if (!shapeComponent) {
      throw new Error('Cannot register visibility component, as component owner has no ShapeComponent attached.');
    }

    if (component.state.values.isInViewport) {
      this.#visibleItems.push(shapeComponent.uuid);
    }

    this.#components.push(component);
    this.#shapeVisibilityMapping[shapeComponent.uuid] = component;
  }

  /**
   * Unregisters the given visibility component from being considered in visibility calculations.
   * @param component The visibility component to unregister
   */
  unregister(component: VisibilityComponent) {
    const index = this.#components.indexOf(component);
    if (index !== -1) {
      this.#components.splice(index, 1);
    }

    const shapeComponent = component.owner?.components.get(ShapeComponent);
    if (shapeComponent) {
      delete this.#shapeVisibilityMapping[component.uuid];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  #scaleBounds(bounds: Bounds, percent: number): Bounds {
    const width = bounds.right - bounds.left;
    const height = bounds.bottom - bounds.top;
    const xMargin = width === Infinity ? 0 : width * (percent - 1);
    const yMargin = height === Infinity ? 0 : height * (percent - 1);
    return {
      top: bounds.top - yMargin,
      bottom: bounds.bottom + yMargin,
      left: bounds.left - xMargin,
      right: bounds.right + xMargin,
    };
  }

  #hasMovedEnough(bounds: Bounds) {
    const oldBounds = this.#cameraLastBounds;
    const outerBounds = this.#scaleBounds(oldBounds, 1 + this.#BOUNDS_MARGIN_PERCENT);
    const innerBounds = this.#scaleBounds(oldBounds, 1 - this.#BOUNDS_MARGIN_PERCENT);

    return (
      bounds.left < outerBounds.left ||
      bounds.right > outerBounds.right ||
      bounds.top < outerBounds.top ||
      bounds.bottom > outerBounds.bottom ||
      bounds.left > innerBounds.left ||
      bounds.right < innerBounds.right ||
      bounds.top > innerBounds.top ||
      bounds.bottom < innerBounds.bottom
    );
  }

  #recalculateVisibility(force: boolean = false) {
    const camera = this.state.otherStates.camera?.owner;
    if (!camera || !this.#shapeContext) {
      return;
    }

    const { visibleBounds } = camera;

    if (!this.#hasMovedEnough(visibleBounds) && !force) {
      return;
    }

    const expandedBounds = this.#scaleBounds(visibleBounds, 1 + this.#BOUNDS_MARGIN_PERCENT);

    const visibleItems = this.#shapeContext.getCollisions(expandedBounds);
    const visibleItemUUIDs = visibleItems.map((item) => item.shapeComponent.uuid);

    for (let i = 0; i < this.#visibleItems.length; i++) {
      const shapeUUID = this.#visibleItems[i];
      if (!visibleItemUUIDs.includes(shapeUUID)) {
        const visibilityComponent = this.#shapeVisibilityMapping[shapeUUID];
        if (visibilityComponent) {
          visibilityComponent.state.values.isInViewport = false;
        }
      }
    }

    for (let i = 0; i < visibleItemUUIDs.length; i++) {
      const shapeUUID = visibleItemUUIDs[i];
      const visibilityComponent = this.#shapeVisibilityMapping[shapeUUID];
      if (visibilityComponent) {
        visibilityComponent.state.values.isInViewport = true;
      }
    }

    this.#visibleItems = visibleItemUUIDs;
    this.#cameraLastBounds = visibleBounds;
  }

  inspectorData: InspectableClassData<this> = [
    {
      type: InspectableClassDataType.Property,
      property: 'state',
      propertyType: InspectableClassPropertyType.State,
    },
  ];
}

export default VisibilityComponentContext;
