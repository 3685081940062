import React from 'react';
import { useSelector } from 'react-redux';

import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';

import AppPlannerDesktop from '@gi/app-planner-desktop';
import AppPlannerMobile from '@gi/app-planner-mobile';

const AppPlanner = (): JSX.Element => {
  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);

  if (deviceDisplayMode === DeviceDisplayMode.DESKTOP) {
    return <AppPlannerDesktop />;
  }

  return <AppPlannerMobile />;
};
export default AppPlanner;
