import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlannerActionCreators, PlannerSelectors } from '@gi/app-planner-slice';

import TouchDragHelpModal from './touch-drag-help-modal';

const TouchDragHelpModalRenderer = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const showModal = useSelector(PlannerSelectors.getShowTouchDragHelpModal);

  const onTouchIntroductionModalClose = () => {
    dispatch(PlannerActionCreators.setShowTouchDragHelpModal(false));
  };

  if (!showModal) {
    return null;
  }

  return <TouchDragHelpModal onClose={onTouchIntroductionModalClose} />;
};

export default TouchDragHelpModalRenderer;
