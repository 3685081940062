import type { WeatherStation, WeatherStationFromAPI } from './types';

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

/**
 * Returns the distance to a point on the earth from this weather station (in km).
 * {@link http://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula StackOverflow Source}.
 * Imported from ACM.
 * @param latitude
 * @param longitude
 * @returns The geographic distance in km
 */
export const getDistance = (weatherStation: WeatherStation, latitude: number, longitude: number) => {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(latitude - weatherStation.latitude); // deg2rad below
  const dLon = deg2rad(longitude - weatherStation.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(weatherStation.latitude)) * Math.cos(deg2rad(latitude)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

/**
 * Converts an object from the API to a WeatherStation
 * @param obj The object from the API
 * @returns A WeatherStation
 */
export const fromObject = (obj: WeatherStationFromAPI): WeatherStation => {
  return {
    stationNo: obj.stationNo,
    stationName: obj.stationName,
    latitude: obj.latitude,
    longitude: obj.longitude,
    lastFrost: obj.LF,
    firstFrost: obj.FF,
    noFrost: obj.noFrost,
  };
};
