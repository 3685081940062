import React from 'react';

import styles from './context-menu-styles.module.css';

interface iProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon?: string;
  disabled?: boolean;
}

const ContextMenuButton = ({ icon, className, children, ...remainingProps }: iProps): JSX.Element => {
  const classNames: string[] = [styles.button];
  if (className) {
    classNames.push(className);
  }

  return (
    <button className={classNames.join(' ')} type='button' {...remainingProps}>
      <span className={styles.buttonIcon}>{icon ? <i className={icon} /> : null}</span>
      <span className={styles.buttonContent}>{children}</span>
    </button>
  );
};

export default ContextMenuButton;
