import React from 'react';

import styles from './header.module.css';

interface iProps {
  className?: string;
  children: React.ReactNode;
}

const HeaderTitle = ({ className = '', children }: iProps) => {
  return <h1 className={`${styles.title} ${className}`}>{children}</h1>;
};

export default HeaderTitle;
