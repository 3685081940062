import React, { useContext } from 'react';

import { TutorialEditorContext } from '../tutorial-editor-context';
import JSONInputError from './json-input-error';

import styles from './json-input.module.css';

const JSONInputErrors = (): JSX.Element | null => {
  const { editingErrors } = useContext(TutorialEditorContext);

  if (editingErrors === null) {
    return null;
  }

  return (
    <div className={styles.editorErrors}>
      <div className={styles.editorErrorsScrollPane}>
        {editingErrors.map((err, i) => (
          <JSONInputError key={i} err={err} />
        ))}
      </div>
    </div>
  );
};

export default JSONInputErrors;
