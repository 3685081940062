import React from 'react';

import MenuBar from '../common/menu-bar';
import MenuBarGroup from '../common/menu-bar-group';
import PlantListControls from './plant-list-controls';
import PlantListDisplayControls from './plant-list-display-controls';
import PlantListSortControls from './plant-list-sort-controls';
import PlantListFilterControls from './plant-list-filter-controls';
import PlantListDropdownControls from './plant-list-dropdown-controls';

const DesktopPlantListMenu = (): JSX.Element => {
  return (
    <MenuBar>
      <MenuBarGroup title='Plant List'>
        <PlantListControls />
      </MenuBarGroup>
      <MenuBarGroup title='Display'>
        <PlantListDisplayControls />
      </MenuBarGroup>
      <MenuBarGroup title='Sort'>
        <PlantListSortControls />
      </MenuBarGroup>
      <MenuBarGroup title='Filter'>
        <PlantListFilterControls />
      </MenuBarGroup>
      <MenuBarGroup title='More'>
        <PlantListDropdownControls />
      </MenuBarGroup>
    </MenuBar>
  );
};

export default DesktopPlantListMenu;
