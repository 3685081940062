import React, { ReactNode, createContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { LocalSettingsSelectors } from '@gi/local-settings';
import { DisplayModeSelectors } from '@gi/display-mode-provider';
import { DeviceDisplayMode } from '@gi/constants';
import { PlannerSelectors } from '@gi/app-planner-slice';

/**
 * README
 *  This is a temporary provider until a proper tutorial/help system can be implemented.
 *  This context is needed because Intercom needs a way to know which tutorials are visible before
 *    we attempt to launch a product tour.
 *  I want there to be a single source-of-truth for if a modal/tutorial is being rendered, which
 *    this is meant to provide.
 *  This context would be better suited to reducers/selectors, but tutorials are conditionally
 *    rendered based on device display mode, which isn't available in the store currently.
 *  I think it may be possible to move the device display mode to the store without issue, but that
 *    should probably be done when we have more time (2023/12/11)
 */

type TutorialContextType = {
  touchControlsTutorialVisible: boolean;
  intercomHelpButtonTutorialVisible: boolean;
  touchModeIntroductionModalVisible: boolean;
  mobileIntroductionModalVisible: boolean;
  setMobileIntroductionModalVisible: (visible: boolean) => void;
};

const TutorialContext = createContext<TutorialContextType>({} as TutorialContextType);

interface iProps {
  children: ReactNode;
}

export const TutorialProvider = ({ children }: iProps): JSX.Element => {
  // const supportsTouch = DisplayModeUtils.hasTouchscreen();

  const deviceDisplayMode = useSelector(DisplayModeSelectors.getDisplayMode);
  const hideTouchControlsTutorial = useSelector(LocalSettingsSelectors.getHideTouchControlsTutorial);
  const hideIntercomIntroduction = useSelector(LocalSettingsSelectors.getHideIntercomIntroduction);
  const [showTouchIntroductionModalOnStart] = useState(useSelector(LocalSettingsSelectors.getShowTouchIntroductionModalOnStart));
  const showTouchIntroductionModal = useSelector(PlannerSelectors.getShowTouchIntroductionModal);

  const [showMobileIntroductionModal, setMobileIntroductionModalVisible] = useState(false);

  const touchControlsTutorialVisible = useMemo(
    () => false, // deviceDisplayMode === DeviceDisplayMode.MOBILE && supportsTouch && !hideTouchControlsTutorial,
    [deviceDisplayMode, hideTouchControlsTutorial]
  );

  const intercomHelpButtonTutorialVisible = useMemo(
    () => deviceDisplayMode === DeviceDisplayMode.MOBILE && !hideIntercomIntroduction,
    [deviceDisplayMode, hideIntercomIntroduction]
  );

  const touchModeIntroductionModalVisible = useMemo(
    () => deviceDisplayMode === DeviceDisplayMode.DESKTOP && showTouchIntroductionModal && showTouchIntroductionModalOnStart,
    [deviceDisplayMode, showTouchIntroductionModal, showTouchIntroductionModalOnStart]
  );

  const mobileIntroductionModalVisible = useMemo(
    () => deviceDisplayMode === DeviceDisplayMode.MOBILE && showMobileIntroductionModal,
    [deviceDisplayMode, showMobileIntroductionModal]
  );

  const value = useMemo(
    () => ({
      touchControlsTutorialVisible,
      intercomHelpButtonTutorialVisible,
      touchModeIntroductionModalVisible,
      mobileIntroductionModalVisible,
      setMobileIntroductionModalVisible,
    }),
    [
      touchControlsTutorialVisible,
      intercomHelpButtonTutorialVisible,
      touchModeIntroductionModalVisible,
      mobileIntroductionModalVisible,
      setMobileIntroductionModalVisible,
    ]
  );

  return <TutorialContext.Provider value={value}>{children}</TutorialContext.Provider>;
};

export default TutorialContext;
