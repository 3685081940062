import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LocalSettingsSelectors } from '@gi/local-settings';
import { InteractionStateType, GardenItemType, ShapeType } from '@gi/constants';
import { CanvasInteractionState, CanvasSelectors, GardenCanvasContext } from '@gi/react-garden-canvas';
import { Swatch, SwatchTypes } from '@gi/palette';
import { Filters, FiltersUpdate, runListFilters, updateFilters } from '@gi/filters';
import { ResourceContext, userPlantingCalendarsNotNull, collectionNotNull } from '@gi/resource-provider';
import { SessionActionCreators, SessionSelectors } from '@gi/react-session';
import { RequestKeyCreators, RequestSelectors } from '@gi/react-requests';
import { RequestStatus, RequestsUtils } from '@gi/request';
import { UserUtils } from '@gi/user';
import { setsEqual } from '@gi/utils';
import { AppContext } from '@gi/app-provider';

import Plant from '@gi/plant';
import GardenObject from '@gi/garden-object';
import { usePrevious } from '@gi/react-utils';

import { SerialisedCanvasInteractionGroupUtils } from '@gi/plan-simulation';
import { GardenPlatformEvent, GardenPlatformEventsActionCreators, useHelpModalEventHook } from '@gi/garden-platform-events';

import { DrawingToolsContext, DrawingToolsContextType } from './drawing-tools-context';
import { TAB_CATEGORIES } from './constants';
import SearchHelpModalRenderer from './modals/search-help-modal-renderer';

import {
  DrawingToolsGardenObjectListEntry,
  DrawingToolsListGardenObjectGroupEntry,
  DrawingToolsListGardenObjectGroupItemEntry,
  colorFromString,
} from './utils';
import createGardenObjectFilters, { GardenObjectFiltersType } from './garden-objects/filters/create-garden-object-filters';
import createPlantFilters, { PlantFiltersType } from './plants/filters/create-plant-filters';
import SFGHelpModalRenderer from './modals/sfg-help-modal-renderer';
import { getPlantFilterInputDiff } from './plants/filters/plant-filter-utils';

interface IProps {
  children: React.ReactNode;
}

const getDrawingPlantCode = (interactionState: CanvasInteractionState) => {
  if (interactionState.type !== InteractionStateType.ITEM_DRAW || interactionState.itemType !== GardenItemType.Plant) {
    return null;
  }

  return interactionState.plantCode;
};

const getDrawingGardenObjectCode = (interactionState: CanvasInteractionState) => {
  if (interactionState.type !== InteractionStateType.ITEM_DRAW || interactionState.itemType !== GardenItemType.GardenObject) {
    return null;
  }

  return interactionState.gardenObjectCode;
};

const getSelectedShapeType = (interactionState: CanvasInteractionState) => {
  if (interactionState.type !== InteractionStateType.ITEM_DRAW || interactionState.itemType !== GardenItemType.Shape) {
    return null;
  }

  return interactionState.subtype;
};

const getSelectedShapeFill = (interactionState: CanvasInteractionState) => {
  if (interactionState.type !== InteractionStateType.ITEM_DRAW || interactionState.itemType !== GardenItemType.Shape) {
    return null;
  }

  return interactionState.filled;
};

const getIsDragToDraw = (interactionState: CanvasInteractionState) => {
  if (interactionState.type !== InteractionStateType.ITEM_DRAW) {
    return false;
  }

  return interactionState.isDragToDraw ?? false;
};

const getSelectedText = (interactionState: CanvasInteractionState) => {
  return interactionState.type === InteractionStateType.ITEM_DRAW && interactionState.itemType === GardenItemType.Text;
};

const DrawingToolsProvider = ({ children }: IProps): JSX.Element => {
  const dispatch = useDispatch();

  const user = useSelector(SessionSelectors.getUser);

  const { runtimeConfig } = useContext(AppContext);
  const { userPlants, userGardenObjects, userGardenObjectGroups, userPlantingCalendars } = useContext(ResourceContext);
  const { gardenCanvas } = useContext(GardenCanvasContext);

  const notNullUserPlantingCalendars = useMemo(() => userPlantingCalendarsNotNull(userPlantingCalendars), [userPlantingCalendars]);
  const notNullUserPlants = useMemo(() => collectionNotNull(userPlants), [userPlants]);
  const notNullUserGardenObjects = useMemo(() => collectionNotNull(userGardenObjects), [userGardenObjects]);

  const showSFGHelpOnToggle = useSelector(LocalSettingsSelectors.getShowSFGHelpOnToggle);
  const sfgMode = useSelector(LocalSettingsSelectors.getSfgMode);
  const previousSFGMode = usePrevious(sfgMode);

  const defaultPlantFilters = useMemo(() => {
    return createPlantFilters(sfgMode, notNullUserPlantingCalendars, user ? UserUtils.isTropicalClimate(user) : true);
  }, [sfgMode, notNullUserPlantingCalendars, user]);

  const [activeTab, setActiveTab] = useState<string>(TAB_CATEGORIES.PLANTS);
  const [open, setOpen] = useState<boolean>(true);
  const [searchHelpModalOpen, setSearchHelpModalOpen] = useState<boolean>(false);
  const [sfgHelpModalOpen, setSfgHelpModalOpen] = useState<boolean>(false);
  const [hasToggledSFGHelpModal, setHasToggledSFGHelpModal] = useState<boolean>(false);
  const [textFontSize, setTextFontSize] = useState<{ value: number; label: string }>({
    value: 16,
    label: '16',
  });
  const [textSwatch, setTextSwatch] = useState<Swatch>({
    value: '000000',
    type: SwatchTypes.COLOR,
  });
  const [shapeStrokeWidth, setShapeStrokeWidth] = useState<{ value: number; label: string }>({
    value: 3,
    label: '3',
  });
  const [shapeFillSwatch, setShapeFillSwatch] = useState<Swatch>({
    value: '663300',
    type: SwatchTypes.COLOR,
  });
  const [shapeStrokeSwatch, setShapeStrokeSwatch] = useState<Swatch>({
    value: '663300',
    type: SwatchTypes.COLOR,
  });
  const [gardenObjectFilters, _setGardenObjectFilters] = useState<Filters<GardenObject, GardenObjectFiltersType>>(
    createGardenObjectFilters(userGardenObjectGroups, runtimeConfig.clientID)
  );
  const [plantFilters, _setPlantFilters] = useState<Filters<Plant, PlantFiltersType>>(defaultPlantFilters);

  const [expandedGardenObjectGroups, setExpandedGardenObjectGroups] = useState<Record<string, boolean>>({});

  useHelpModalEventHook('sfg mode', sfgHelpModalOpen, 'drawing-tools');
  useHelpModalEventHook('drawing tools search', searchHelpModalOpen, 'drawing-tools');

  const lastFailedPlantSearchTerm = useRef<string>('');
  const lastFailedGardenObjectSearchTerm = useRef<string>('');

  if (previousSFGMode === false && sfgMode) {
    if (!hasToggledSFGHelpModal && showSFGHelpOnToggle && !sfgHelpModalOpen) {
      setSfgHelpModalOpen(true);
      setHasToggledSFGHelpModal(true);
    }
  }

  // Check if the companions filter has been turned on, and send an analytics event
  const runPlantCompanionFilterAnalytics = useCallback((previousFilters: Filters<Plant, PlantFiltersType>, newFilters: Filters<Plant, PlantFiltersType>) => {
    const oldCompanions = previousFilters.filters.companion.inputs.plantCodes;
    const newCompanions = newFilters.filters.companion.inputs.plantCodes;

    if (newCompanions.size > 0 && !setsEqual(oldCompanions, newCompanions)) {
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ViewCompanions, { plantCodes: Array.from(newCompanions) }));
    }
  }, []);

  const setPlantFilters = useCallback(
    (newPlantFilters: Filters<Plant, PlantFiltersType>) => {
      runPlantCompanionFilterAnalytics(plantFilters, newPlantFilters);
      _setPlantFilters(newPlantFilters);
    },
    [plantFilters]
  );

  const updatePlantFilters = useCallback((filtersUpdate: FiltersUpdate<Plant, PlantFiltersType>) => {
    _setPlantFilters((currentFilters) => {
      const newFilters = updateFilters(currentFilters, filtersUpdate);
      runPlantCompanionFilterAnalytics(currentFilters, newFilters);
      return newFilters;
    });
  }, []);

  const setGardenObjectFilters = useCallback((filters: Filters<GardenObject, GardenObjectFiltersType>) => {
    _setGardenObjectFilters(filters);
  }, []);

  const updateGardenObjectFilters = useCallback((filtersUpdate: FiltersUpdate<GardenObject, GardenObjectFiltersType>) => {
    _setGardenObjectFilters((currentFilters) => {
      return updateFilters(currentFilters, filtersUpdate);
    });
  }, []);

  const selectedItems = useSelector(CanvasSelectors.getSelectedItems);
  const requests = useSelector(RequestSelectors.getRequests);

  const favouritePlants = useMemo(() => (user === null ? new Set<string>() : user.favouritePlants), [user]);

  const userGardenObjectList = useMemo(() => {
    if (notNullUserGardenObjects === null) {
      return [];
    }
    return notNullUserGardenObjects.asArray();
  }, [notNullUserGardenObjects]);

  const userPlantList = useMemo(() => {
    if (notNullUserPlants === null) {
      return [];
    }
    return notNullUserPlants.asArray();
  }, [notNullUserPlants]);

  const cancelDraw = useCallback(() => {
    if (gardenCanvas) {
      gardenCanvas.cancelInteraction();
    }
  }, [gardenCanvas]);

  const interactionState = useSelector(CanvasSelectors.getInteractionState);

  const textSelected = useMemo(() => getSelectedText(interactionState), [interactionState]);
  const selectedShapeType = useMemo(() => getSelectedShapeType(interactionState), [interactionState]);
  const selectedShapeFill = useMemo(() => getSelectedShapeFill(interactionState), [interactionState]);
  const selectedGardenObjectCode = useMemo(() => getDrawingGardenObjectCode(interactionState), [interactionState]);
  const selectedPlantCode = useMemo(() => getDrawingPlantCode(interactionState), [interactionState]);
  const isDragToDraw = useMemo(() => getIsDragToDraw(interactionState), [interactionState]);

  const onTextSelected = useCallback(
    (fontSize: number, color: string, dragEvent?: PointerEvent) => {
      if (gardenCanvas) {
        gardenCanvas.startDrawingText(fontSize, colorFromString(color)!, dragEvent);
      }
    },
    [gardenCanvas]
  );

  const onShapeSelected = useCallback(
    (shape: string, strokeWidth: number, strokeColor: string, fillColor: null | string, texture: null | string, dragEvent?: PointerEvent) => {
      if (gardenCanvas) {
        gardenCanvas.startDrawingShape(shape as ShapeType, colorFromString(fillColor), colorFromString(strokeColor), strokeWidth, texture, dragEvent);
      }
    },
    [gardenCanvas]
  );

  const onGardenObjectSelected = useCallback(
    (gardenObject: GardenObject, dragEvent?: PointerEvent) => {
      if (gardenCanvas) {
        gardenCanvas.startDrawingGardenObject(gardenObject, dragEvent);
      }
    },
    [gardenCanvas]
  );

  const toggleGardenObjectGroup = useCallback(
    (groupId: string) => {
      const group = userGardenObjectGroups.get(groupId);
      if (!group) {
        return;
      }

      if (expandedGardenObjectGroups[groupId]) {
        setExpandedGardenObjectGroups({ ...expandedGardenObjectGroups, [groupId]: false });
        // Cancel draw if the currently selected item was part of the minimised group to avoid confusion
        if (selectedGardenObjectCode !== null && group.objectCodes.includes(selectedGardenObjectCode)) {
          cancelDraw();
        }
      } else {
        setExpandedGardenObjectGroups({ ...expandedGardenObjectGroups, [groupId]: true });
      }
    },
    [expandedGardenObjectGroups, userGardenObjectGroups, selectedGardenObjectCode, cancelDraw]
  );

  const resetGardenObjectFilters = useCallback(() => {
    setGardenObjectFilters(createGardenObjectFilters(userGardenObjectGroups, runtimeConfig.clientID));
  }, [setGardenObjectFilters, userGardenObjectGroups, runtimeConfig.clientID]);

  const filteredGardenObjects = useMemo(() => {
    return runListFilters(gardenObjectFilters, userGardenObjectList);
  }, [userGardenObjectList, gardenObjectFilters]);

  const groupedFilteredGardenObjects = useMemo(() => {
    const isSearch = false; // gardenObjectFilters.filters.search.inputs.searchTerm.trim() !== '';
    const items: DrawingToolsGardenObjectListEntry[] = [];
    const groups: Record<string, DrawingToolsListGardenObjectGroupEntry> = {};

    for (let i = 0; i < filteredGardenObjects.length; i++) {
      const gardenObject = filteredGardenObjects[i];

      if (gardenObject.groupId !== undefined) {
        const group = userGardenObjectGroups.get(gardenObject.groupId);
        if (groups[gardenObject.groupId]) {
          // Item is part of a group, and group has already been created. Ad this item to that group
          groups[gardenObject.groupId].gardenObjects.push(gardenObject);
        } else if (group) {
          // Item is part of a group, and this is the first instance of that group. Create the group and add this item to it.
          groups[gardenObject.groupId] = {
            type: 'group',
            group,
            expanded: expandedGardenObjectGroups[group.id] === true,
            gardenObjects: [gardenObject],
          };
          items.push(groups[gardenObject.groupId]);
        } else {
          // Group couldn't be found, just insert the item
          items.push({
            type: 'garden-object',
            gardenObject,
          });
        }
      } else {
        // Item is not part of a group, display normally
        items.push({
          type: 'garden-object',
          gardenObject,
        });
      }
    }

    // Expand the groups into a flat list, omitting items from collapsed groups.
    return items.flatMap<DrawingToolsGardenObjectListEntry>((item) => {
      if (item.type === 'group' && (item.expanded || isSearch)) {
        return [
          item,
          ...item.gardenObjects.map<DrawingToolsListGardenObjectGroupItemEntry>((object, index, { length }) => ({
            type: 'grouped-garden-object',
            gardenObject: object,
            isFirst: index === 0,
            isLast: index === length - 1,
          })),
        ];
      }
      return item;
    });
  }, [filteredGardenObjects, userGardenObjectGroups, expandedGardenObjectGroups, gardenObjectFilters]);

  const toggleFavouritePlant = useCallback(
    (plant: Plant): void => {
      if (user === null) {
        return;
      }

      const newFavouritePlants = new Set(favouritePlants);
      const alreadyFavourite = newFavouritePlants.has(plant.code);
      if (alreadyFavourite) {
        newFavouritePlants.delete(plant.code);
      } else {
        newFavouritePlants.add(plant.code);
      }
      dispatch(
        GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ToggleFavouritePlant, {
          plantCode: plant.code,
          isFavourite: !alreadyFavourite,
        })
      );
      dispatch(SessionActionCreators.saveUserFavourites(user, newFavouritePlants));
    },
    [user, favouritePlants]
  );

  const selectedPlants = useMemo(() => {
    // Create list of selected plants, use a set to remove duplicates
    if (notNullUserPlants === null) {
      return [];
    }

    return SerialisedCanvasInteractionGroupUtils.getPlantCodes(selectedItems)
      .map((code) => notNullUserPlants.get(code))
      .filter<Plant>((item): item is Plant => item !== null);
  }, [notNullUserPlants, selectedItems]);

  const northernHemisphere = useMemo(() => {
    return user === null ? true : UserUtils.isNorthernHemisphere(user);
  }, [user]);

  const resetPlantFilters = useCallback(() => {
    setPlantFilters(defaultPlantFilters);
  }, [defaultPlantFilters, setPlantFilters]);

  const viewCompanions = useCallback(
    (plants: Plant[] = selectedPlants) => {
      if (plants.length === 0) {
        return;
      }

      const plantCodes = new Set(plants.map((plant) => plant.code));
      const companionPlantCodes = new Set(plants.map((plant) => plant.companionPlantCodes).flat());

      setPlantFilters(
        updateFilters(defaultPlantFilters, {
          companion: {
            enabled: true,
            plantCodes,
            companionPlantCodes,
          },
        })
      );
    },
    [defaultPlantFilters, selectedPlants]
  );

  const filteredPlants = useMemo(() => {
    return runListFilters(plantFilters, userPlantList);
  }, [userPlantList, plantFilters]);

  useEffect(() => {
    if (filteredPlants.length === 0) {
      const { searchTerm } = plantFilters.filters.search.inputs;
      const previousSearchTerm = lastFailedPlantSearchTerm.current;
      lastFailedPlantSearchTerm.current = searchTerm;

      // There is no search term, the user has got no results from other filters.
      if (!searchTerm || searchTerm.trim() === '') {
        return;
      }

      // The search term hasn't changed (likely other filters changed).
      if (searchTerm === previousSearchTerm) {
        return;
      }

      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.SearchPlantsNoResults, { searchTerm }));
    }
  }, [filteredPlants]);

  useEffect(() => {
    if (filteredGardenObjects.length === 0) {
      const { searchTerm } = gardenObjectFilters.filters.search.inputs;
      const previousSearchTerm = lastFailedGardenObjectSearchTerm.current;
      lastFailedGardenObjectSearchTerm.current = searchTerm;

      // There is no search term, the user has got no results from other filters.
      if (!searchTerm || searchTerm.trim() === '') {
        return;
      }

      // The search term hasn't changed (likely other filters changed).
      if (searchTerm === previousSearchTerm) {
        return;
      }

      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.SearchGardenObjectsNoResults, { searchTerm }));
    }
  }, [filteredGardenObjects]);

  const onPlantSelected = useCallback(
    (plant: Plant, dragEvent?: PointerEvent) => {
      if (gardenCanvas) {
        const ifSFG = sfgMode && plant.canBeSquareFootPlant;
        gardenCanvas.startDrawingPlant(plant, ifSFG, dragEvent);
      }

      const filtersDiff = getPlantFilterInputDiff(plantFilters, defaultPlantFilters, true);
      if (Object.keys(filtersDiff).length === 0) {
        return;
      }

      dispatch(
        GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ChosePlantToDraw, {
          plantCode: plant.code,
          filters: filtersDiff,
        })
      );
    },
    [gardenCanvas, sfgMode, plantFilters, defaultPlantFilters]
  );

  useEffect(() => {
    // Update plant filters with calculated planting date values
    if (plantFilters.filters.plantingDates.inputs.userPlantingCalendars !== notNullUserPlantingCalendars) {
      // memoized function has returned a new set of available planting dates so our filter needs to be updated
      updatePlantFilters({
        plantingDates: { userPlantingCalendars: notNullUserPlantingCalendars },
      });
    }
  }, [notNullUserPlantingCalendars]);

  useEffect(() => {
    // Update SFG filter with current SFG mode
    if (sfgMode !== plantFilters.filters.sfg.inputs.sfgModeEnabled) {
      updatePlantFilters({ sfg: { sfgModeEnabled: sfgMode, enabled: false } });
    }

    // Update currently drawn plant if SFG mode changes
    if (selectedPlantCode) {
      // SFG mode changed while a plant was selected; reselect it with the correct SFG mode now
      if (notNullUserPlants !== null) {
        const plant = notNullUserPlants.get(selectedPlantCode);
        if (plant !== null) {
          onPlantSelected(plant);
        }
      }
    }
  }, [sfgMode]);

  const userLoading = useMemo(() => {
    if (user === null) {
      return false;
    }
    return (
      RequestsUtils.getStatus(requests, RequestKeyCreators.createSaveUserRequestKey(user.ID)) === RequestStatus.IN_PROGRESS ||
      RequestsUtils.getStatus(requests, RequestKeyCreators.createLoadUserRequestKey(user.ID)) === RequestStatus.IN_PROGRESS
    );
  }, [user, requests]);

  const value = useMemo<DrawingToolsContextType>(() => {
    return {
      searchHelpModalOpen,
      setSearchHelpModalOpen,
      sfgHelpModalOpen,
      setSfgHelpModalOpen,
      activeTab,
      setActiveTab,
      open,
      setOpen,
      cancelDraw,
      textFontSize,
      setTextFontSize,
      textSwatch,
      setTextSwatch,
      textSelected,
      onTextSelected,
      shapeStrokeWidth,
      setShapeStrokeWidth,
      shapeFillSwatch,
      setShapeFillSwatch,
      shapeStrokeSwatch,
      setShapeStrokeSwatch,
      selectedShapeType,
      selectedShapeFill,
      onShapeSelected,
      selectedGardenObjectCode,
      onGardenObjectSelected,
      gardenObjectFilters,
      setGardenObjectFilters,
      updateGardenObjectFilters,
      resetGardenObjectFilters,
      filteredGardenObjects,
      groupedFilteredGardenObjects,
      favouritePlants,
      toggleFavouritePlant,
      filteredPlants,
      plantFilters,
      resetPlantFilters,
      setPlantFilters,
      updatePlantFilters,
      selectedPlants,
      selectedPlantCode,
      northernHemisphere,
      onPlantSelected,
      userLoading,
      isDragToDraw,
      toggleGardenObjectGroup,
      viewCompanions,
    };
  }, [
    searchHelpModalOpen,
    setSearchHelpModalOpen,
    sfgHelpModalOpen,
    setSfgHelpModalOpen,
    activeTab,
    setActiveTab,
    open,
    setOpen,
    cancelDraw,
    textFontSize,
    setTextFontSize,
    textSwatch,
    setTextSwatch,
    textSelected,
    onTextSelected,
    shapeStrokeWidth,
    setShapeStrokeWidth,
    shapeFillSwatch,
    setShapeFillSwatch,
    shapeStrokeSwatch,
    setShapeStrokeSwatch,
    selectedShapeType,
    selectedShapeFill,
    onShapeSelected,
    selectedGardenObjectCode,
    onGardenObjectSelected,
    gardenObjectFilters,
    setGardenObjectFilters,
    updateGardenObjectFilters,
    resetGardenObjectFilters,
    filteredGardenObjects,
    groupedFilteredGardenObjects,
    favouritePlants,
    toggleFavouritePlant,
    filteredPlants,
    plantFilters,
    resetPlantFilters,
    setPlantFilters,
    updatePlantFilters,
    selectedPlants,
    selectedPlantCode,
    northernHemisphere,
    onPlantSelected,
    userLoading,
    isDragToDraw,
    viewCompanions,
  ]);

  return (
    <DrawingToolsContext.Provider value={value}>
      {children}
      <SearchHelpModalRenderer />
      <SFGHelpModalRenderer />
    </DrawingToolsContext.Provider>
  );
};

export default DrawingToolsProvider;
