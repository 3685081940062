import type { Store } from 'redux';
import { LoadingState } from '@gi/constants';
import type { CanvasReducerState } from '../redux-components/canvas-reducer';
import type GardenCanvasController from '../garden-canvas-controller/garden-canvas-controller';

interface BaseStoreState {
  canvas: CanvasReducerState;
}

/**
 * Adds a listener to the provided redux store which listens for changes to the `planner.plans`, `planner.loadedPlans` and `planner.activePlanID`
 * values and keeps them synchronised with the gardenCanvasController
 */
const planOpener = (store: Store<BaseStoreState>, gardenCanvasController: GardenCanvasController) => {
  let currentState = store.getState();

  const handleChange = () => {
    const previousState = currentState;
    currentState = store.getState();

    if (previousState === currentState) {
      // No change
      return;
    }

    // TODO: Disabled for now until texture loading is implemented into the state
    // if (!currentState.canvas.texturesLoaded) {
    //   // textures aren't loaded, don't do anything
    //   return;
    // }

    let texturesJustLoaded = false;
    if (previousState.canvas.texturesStatus.status !== LoadingState.SUCCESS && currentState.canvas.texturesStatus.status === LoadingState.SUCCESS) {
      // If the textures have not yet loaded, don't do anything
      texturesJustLoaded = true;
    }

    if (texturesJustLoaded || previousState.canvas.plans !== currentState.canvas.plans) {
      // Plans have updated
      gardenCanvasController.updateInstancePlanData(currentState.canvas.plans);
    }

    if (texturesJustLoaded || previousState.canvas.loadedPlans !== currentState.canvas.loadedPlans) {
      // Loaded plans have updated
      gardenCanvasController.updateInstanceLoadedPlanData(currentState.canvas.loadedPlans);
    }

    if (texturesJustLoaded || previousState.canvas.openPlanIDs !== currentState.canvas.openPlanIDs) {
      gardenCanvasController.updateInstanceOpenPlanIDs(currentState.canvas.openPlanIDs);
    }

    if (texturesJustLoaded || previousState.canvas.activePlanID !== currentState.canvas.activePlanID) {
      // Active plan ID has changed
      gardenCanvasController.setInstanceActivePlanID(currentState.canvas.activePlanID);
    }
  };

  // Set initial values
  gardenCanvasController.updateInstancePlanData(currentState.canvas.plans);
  gardenCanvasController.updateInstanceLoadedPlanData(currentState.canvas.loadedPlans);
  gardenCanvasController.setInstanceActivePlanID(currentState.canvas.activePlanID);
  gardenCanvasController.updateInstanceOpenPlanIDs(currentState.canvas.openPlanIDs);

  store.subscribe(handleChange);
};

export default planOpener;
