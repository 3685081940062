import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { GardenPlatformEvent } from '../types';
import { GardenPlatformEventsActionCreators } from '../garden-platform-events-middleware';

/**
 * Helper react hook for triggering an event whenever a help modal is opened
 * @param modalName The analytics name of the help modal
 * @param isOpen Is the modal open? Will send event when changed to true.
 * @param opener Optional name of the element that opened the modal
 */
const useHelpModalEventHook = (modalName: string, isOpen: boolean, opener: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(GardenPlatformEventsActionCreators.fireEvent(GardenPlatformEvent.ViewHelpModal, { modal: modalName, opener }));
    }
  }, [isOpen]);
};

export default useHelpModalEventHook;
