import { AssetComponent, AssetComponentEvents, AssetType, NodeEvent, ShapeCollisionCheckFunctions, TilingSpriteComponent } from '@gi/core-renderer';
import { ShapeType } from '@gi/constants';

import { metricDistanceUnits } from '@gi/units';
import ShapeNode, { ShapeNodeState } from './shape-node';
import { getRectTooltipText } from './tooltip-utils';
import { CachedRectOutline } from '../outline-utils';

// eslint-disable-next-line @typescript-eslint/ban-types
class RectangleShapeNode extends ShapeNode<{}, ShapeType.RECTANGLE> {
  type = 'RectangleShapeNode';

  #sprite: TilingSpriteComponent;
  #texture: AssetComponent<AssetType.TEXTURE>;

  #cachedOutline?: CachedRectOutline;

  constructor(id: number, state: ShapeNodeState['state']) {
    super(id, ShapeType.RECTANGLE, state);

    this.state.addWatcher(
      () => {
        this.#update();
      },
      { properties: ['point1', 'point3', 'fill', 'stroke', 'strokeWidth'] },
      false
    );

    this.#sprite = this.components.add(new TilingSpriteComponent());

    this.#texture = this.components.add(new AssetComponent(AssetType.TEXTURE));
    this.#texture.eventBus.on(AssetComponentEvents.Loaded, (asset) => {
      this.#sprite.texture = asset;
    });

    this.state.addWatcher(
      (_state) => {
        this.#texture.assetName = _state.values.texture;
      },
      { properties: ['texture'] }
    );

    this.state.addWatcher(
      (_state) => {
        this.shape.collisionCheckFunction =
          _state.values.stroke !== null
            ? ShapeCollisionCheckFunctions.HollowConvexHull(_state.values.strokeWidth / 2)
            : ShapeCollisionCheckFunctions.ConvexHull;
      },
      { properties: ['stroke', 'strokeWidth'] }
    );

    this.eventBus.on(NodeEvent.DidBind, this.#didBind);
  }

  #didBind = () => {
    if (this.state.values.texture !== null) {
      this.#texture.assetName = this.state.values.texture;
    }
    this.#update();
  };

  setPosition(center: Vector2, dimensions: Dimensions, rotation: number) {
    this.state.values.point1 = {
      x: center.x - dimensions.width / 2,
      y: center.y - dimensions.height / 2,
    };
    this.state.values.point3 = {
      x: center.x + dimensions.width / 2,
      y: center.y + dimensions.height / 2,
    };
    this.state.values.rotation = rotation;
  }

  #updateSprite() {
    const { texture } = this.state.values;
    const { width, height } = this.getDimensions();
    this.#sprite.visible = texture !== null;
    this.#sprite.width = width;
    this.#sprite.height = height;
  }

  #updateRectangle() {
    if (!this.graphics) {
      return;
    }

    if (this.state.values.texture !== null) {
      this.graphics.visible = false;
      return;
    }

    this.graphics.visible = true;

    const dimensions = this.getDimensions();
    const { fill, stroke, strokeWidth } = this.state.values;

    if (fill !== null) {
      this.graphics
        .clear()
        .beginFill(fill, 1)
        .lineStyle(0)
        .drawRect(-dimensions.width / 2, -dimensions.height / 2, dimensions.width, dimensions.height)
        .endFill();
    } else {
      this.graphics
        .clear()
        .beginFill(0x000000, 0)
        .lineStyle(strokeWidth, stroke ?? 0x000000, 1)
        .drawRect(-dimensions.width / 2, -dimensions.height / 2, dimensions.width, dimensions.height)
        .endFill();
    }
  }

  #updateShape() {
    const { width, height } = this.getDimensions();
    const { stroke } = this.state.values;

    if (!this.#cachedOutline) {
      this.#cachedOutline = new CachedRectOutline(width, height);
      this.shape.setPoints(this.#cachedOutline.path);
    } else if (this.#cachedOutline.update(width, height)) {
      this.shape.setPoints(this.#cachedOutline.path);
    }

    this.outline.state.values.hollow = stroke !== null;
  }

  #update() {
    this.#updateRectangle();
    this.#updateSprite();
    this.#updateShape();
  }

  protected updateTooltip(): void {
    const distanceUnits = this.planSettings?.getDistanceUnits() ?? metricDistanceUnits;
    this.tooltip.state.values.text = getRectTooltipText(this.getDimensions(), distanceUnits);
  }
}

export default RectangleShapeNode;
