/**
 * @typedef {string} ItemType
 */
/**
 * Enum for the different item types on a garden plan
 *
 * @readonly
 * @enum {ItemType}
 */
const ItemTypes = {
  PLANT: 'PLANT',
  GARDEN_OBJECT: 'GARDEN_OBJECT',
  SHAPE: 'SHAPE',
  TEXT: 'TEXT',
  LABEL: 'LABEL',
};

export default ItemTypes;
