import { networkConfig } from '@gi/config';
import { RenderMode, RepeatingGraphicDisplayModes, RepeatingGraphicDisplayModesType, WheelModes, WheelModesType } from '@gi/constants';
import { UserPlanSettings } from '@gi/user';

export type GardenCanvasSettings = Omit<UserPlanSettings, 'autosave' | 'showGrid'> & {
  // These are a subset of LocalSettings. These could be picked form LocalSettings, but I don't
  //  think this package should be reliant on @gi/local-settings
  autoPan: boolean;
  plantDisplayMode: RepeatingGraphicDisplayModesType;
  plantSpriteCountLimit: number;
  renderMode: RenderMode;
  sfgMode: boolean;
  snapToGrid: boolean;
  touchMode: boolean;
  wheelMode: WheelModesType;
  angleSnap: number;
  angleSnapMagnetism: number;
  textureDefinitionsURL: string;
  touchDragRequiresSelection: boolean;
  textQuality: number;
};

export const DEFAULT_GARDEN_CANVAS_SETTINGS: GardenCanvasSettings = {
  labelTextSize: 12,
  showLabelOnNewPlants: true,
  showLabelOnNewSFGPlants: true,
  showPlantRoots: true,
  varietyForDefaultLabel: false,
  autoPan: true,
  plantDisplayMode: RepeatingGraphicDisplayModes.BLOCK,
  plantSpriteCountLimit: 100,
  renderMode: RenderMode.AUTO,
  sfgMode: false,
  snapToGrid: false,
  touchMode: false,
  wheelMode: WheelModes.ZOOM,
  angleSnap: 45,
  angleSnapMagnetism: 2,
  textureDefinitionsURL: networkConfig.textureDefinitions.us,
  touchDragRequiresSelection: true,
  textQuality: 4,
};
